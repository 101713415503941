import React from "react";
import { Helmet } from "react-helmet";

const GetResponse = (): JSX.Element => {
	return (
		<Helmet>
			<script type="text/javascript">
				{`
            (function(m, o, n, t, e, r, _){
              m['__GetResponseAnalyticsObject'] = e;
              m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};
              r = o.createElement(n);
              _ = o.getElementsByTagName(n)[0];
              r.async = 1;
              r.src = t;
              r.setAttribute('crossorigin', 'use-credentials');
              _.parentNode.insertBefore(r, _);
            })(window, document, 'script', 'https://ga.getresponse.com/script/9b184312-12e0-41c4-bea6-f8f94ad1ff01/ga.js', 'GrTracking');
          `}
			</script>
		</Helmet>
	);
};

export default GetResponse;
