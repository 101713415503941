import React, { FC, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as PreviewArrowSVG } from "../../../../../../../../../../images/svg/preview-arrow.svg";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import LastTransactionsTable from "../../../../../../components/Transaction/LastTransactionsTable";

interface Props {
	client: ClientApi;
}

const ClientLastTransactionsCard: FC<Props> = ({ client }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [showTransaction, setShowTransaction] = useState<number | null>(null);

	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.client.field.client_last_transaction_card.title")}</h5>
				<LastTransactionsTable
					showTransaction={showTransaction}
					setShowTransaction={setShowTransaction}
					transactions={client.transactions}
					rowActions={(transaction) => (
						<Button variant={"btn btn-light"} onClick={() => setShowTransaction(transaction.id)}>
							<PreviewArrowSVG />
						</Button>
					)}
				/>
				<Link
					to={`/${organization.id}/sales/transactions?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}client|e=${client.id}`
					)}`}
					target="_blank"
					className={"btn btn-add card-action"}
				>
					{t("common.action.show_more", { ns: "lib" })}
				</Link>
			</Card.Body>
		</Card>
	);
};

export default ClientLastTransactionsCard;
