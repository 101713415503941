import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import { PaymentStatus } from "../../../../../services/Api/Public/types";
import { OrderIdContext } from "../contexts/OrderIdContext";
import { ActionRow } from "../services/types";
import { downloadEReceipt, handleEReceiptError } from "../utils";

export const useOrderActions = (
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	paymentStatus: PaymentStatus,
	orderNumber: string
): { actionsRows: ActionRow[]; isEmailModalOpen: boolean; hideEmailModal: () => void } => {
	const { t } = useTranslation();
	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	const [isDownloadEReceiptRequestOngoing, setIsDownloadEReceiptRequestOngoing] = useState(false);
	const { addFlash } = useFlash();
	const { orderId } = useContext(OrderIdContext);

	const actionsRows: ActionRow[] = [
		{
			actions: [
				{
					text: t("modules.e_order.action.download_e_receipt.title"),
					onClick: async () => {
						setIsDownloadEReceiptRequestOngoing(true);

						try {
							const filename = `${t("modules.e_order.field.filename.title")} #${orderNumber}.json`;

							await downloadEReceipt(filename, orderId);
						} catch (e) {
							handleEReceiptError(e, addFlash);
						}

						setIsDownloadEReceiptRequestOngoing(false);
					},
					isLoading: isDownloadEReceiptRequestOngoing,
					"data-testid": "e-order-order-actions-section-download-receipt-button",
				},
				{
					text: t("modules.e_order.action.send_email.title"),
					onClick: () => setIsEmailModalOpen(true),
					"data-testid": "e-order-order-actions-section-send-email-button",
				},
			],
		},
	];

	// the code stays commented so the translation key is not deleted and also because it will get uncommented once Pay functionality starts working
	// if (paymentStatus === PaymentStatus.OPEN) {
	// 	actionsRows.unshift({
	// 		actions: [
	// 			{
	// 				text: t("modules.e_order.action.pay.title"),
	// 				isPrimary: true,
	// 				onClick: () => console.log("Pay"),
	// 				"data-testid": "e-order-order-actions-section-pay-button",
	// 			},
	// 		],
	// 	});
	// }

	return {
		actionsRows,
		isEmailModalOpen,
		hideEmailModal: () => setIsEmailModalOpen(false),
	};
};
