import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as ArrowRightSVG } from "../../../../../images/svg/dashboard/arrow-right.svg";
import { ReactComponent as DropdownSVG } from "../../../../../images/svg/dropdown.svg";
import {
	ChartDataRangeType,
	ChartType,
	DashboardCardConfig,
	chartDataRangeOptions,
} from "../../../services/charts/types";
import useDashboardContext from "../../../services/useDashboardContext";
import { parsePredefinedRangeToPreviousPeriodRange } from "../../../utils/reportConfigUtils";

interface Props {
	config: DashboardCardConfig;
	updateChartRangeType: (rangeType: ChartDataRangeType) => void;
}

type ChartDropdownOption = {
	type: ChartDataRangeType;
	name: string;
};

type ToggleProps = {
	children?: React.ReactNode;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {};
};

const ReportItemWrapperFooter = (props: Props): JSX.Element => {
	const { chartType, chartDataRangeType, link } = props.config;
	const { updateChartRangeType } = props;
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const { state } = useDashboardContext();
	const dropdownOptions = chartDataRangeOptions.map((type) => {
		return {
			name: t(`go_report.chart.aggregates.${type}`, { ns: "lib" }),
			type,
		} as ChartDropdownOption;
	});
	const [dropdownIsActive, setDropdownIsActive] = useState<boolean>(false);
	const isPredefinedFilter = !state.filtersConfig.selectedFilter.dateRange.includes(FILTER_VALUE_SEPARATOR);
	const dateFilter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}date_range|${isPredefinedFilter ? "btd" : "bt"}=${
		state.filtersConfig.selectedFilter.dateRange
	}${FILTER_SEPARATOR}compare_date_range|bt=${parsePredefinedRangeToPreviousPeriodRange(
		state.filtersConfig.selectedFilter.dateRange,
		true
	)}`;

	const updateReportLocalStorageDate = () => {
		window.localStorage.setItem(
			"go_report.dashboard_filters.date_range",
			`bt=${state.filtersConfig.selectedFilter.dateRange}`
		);
		window.localStorage.setItem("go_report.dashboard_chart_type", chartDataRangeType);
	};

	const getGroupsString = () => {
		if (link.includes("payments")) {
			return "PAYMENT_METHOD";
		}
		if (link.includes("orders")) {
			return "ORDER_TYPE";
		}
		if (link.includes("products")) {
			return "PRODUCT";
		}
		if (link.includes("categories")) {
			return "PRODUCT_CATEGORY";
		}
		return "NONE";
	};

	const getGroupReportType = () => {
		const { type } = props.config;

		switch (type) {
			case "sales_advanced_reports":
			case "sales_reports":
				return "orders";
			case "category_reports":
				return "categories";
			case "payment_reports":
				return "payments";
			case "product_reports":
				return "products";
		}
	};

	const getLinkOnSeeMoreClick = () => {
		if (organization?.id)
			return `/${organization.id}/${link}?f=${btoa(
				unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${dateFilter}`))
			)}&groups=${getGroupsString()}`;

		const organizationIdsFilter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${dateFilter}${FILTER_SEPARATOR}organization_ids|e=${state?.filtersConfig?.selectedFilter?.organizationIds}`;
		return `/group_reports/${getGroupReportType()}?f=${btoa(unescape(encodeURIComponent(organizationIdsFilter)))}`;
	};

	return (
		<div className={"d-flex mt-3"}>
			{chartType !== ChartType.BOX &&
				chartType !== ChartType.PROGRESS_BAR &&
				chartType !== ChartType.HORIZONTAL_BAR && (
					<Dropdown onToggle={() => setDropdownIsActive((prevState) => !prevState)}>
						<Dropdown.Toggle as={CustomToggle}>
							{t(`go_report.chart.aggregates.${chartDataRangeType}`, { ns: "lib" })}
							<DropdownSVG
								className={`${dropdownIsActive ? "triangle-rotate-active" : ""} dropdown-svg`}
							/>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{dropdownOptions.map((item) => {
								return (
									<Dropdown.Item
										active={chartDataRangeType === item.type}
										key={item.type}
										onClick={() => updateChartRangeType(item.type)}
									>
										{item.name}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				)}
			<Link
				onClick={() => updateReportLocalStorageDate()}
				to={getLinkOnSeeMoreClick()}
				className={"ms-auto font-weight-bold"}
			>
				{t("go_report.action.see_more.title")}
				<ArrowRightSVG className="ms-1" />
			</Link>
		</div>
	);
};
export default ReportItemWrapperFooter;

const CustomToggle = React.forwardRef<HTMLElement, ToggleProps>(({ children, onClick }, ref) => {
	return (
		<span style={{ cursor: "pointer" }} onClick={onClick} ref={ref}>
			{children}
		</span>
	);
});
CustomToggle.displayName = "CustomToggle";
