import { DashboardApi } from "../../../../services/Api/Organization/types";

export enum DashboardFetchActions {
	fetchProductReports = "product_reports",
	fetchCategoryReports = "category_reports",
	fetchAllData = "all",
	fetchSalesReports = "sales_reports",
	fetchPaymentReports = "payment_reports",
	fetchSalesAdvancedReports = "sales_advanced_reports",
}

export const dashboardDataReducer = (
	state: DashboardApi | undefined,
	action: Record<string, any>
): DashboardApi | undefined => {
	switch (action.type) {
		case DashboardFetchActions.fetchAllData:
			return action.payload.shouldOverrideExistingData
				? undefined
				: {
						...state,
						...action.payload.data,
				  };
		case DashboardFetchActions.fetchProductReports:
			return {
				...state,
				product_reports: action.data,
			};
		case DashboardFetchActions.fetchCategoryReports:
			return {
				...state,
				category_reports: action.data,
			};
		case DashboardFetchActions.fetchSalesReports:
			return {
				...state,
				sales_reports: action.data,
			};
		case DashboardFetchActions.fetchPaymentReports:
			return {
				...state,
				payment_reports: action.data,
			};
		case DashboardFetchActions.fetchSalesAdvancedReports:
			return {
				...state,
				sales_advanced_reports: action.data,
			};
		default:
			return state;
	}
};
