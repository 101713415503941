import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import WebhookPreview from "go-component/components/Webhook/WebhookPreview";
import { WebhookContext } from "go-component/components/Webhook/services/context";
import { WebhookApi } from "go-component/components/Webhook/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";

interface MatchParams {
	webhook_id: string;
}

const OrganizationLogsWebhooksPreviewPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [webhook, setWebhook] = useState<WebhookApi | undefined>();
	const webhookService = React.useContext(WebhookContext);
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	const { webhook_id } = useParams<MatchParams>();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchWebhook();
	}, []);

	const onFetchWebhook = async () => {
		try {
			const params: Record<string, any> = {};
			params.include = "requests";
			const res = await webhookService.api().get(webhook_id, params);
			handleChangeTabTitle(`${res.data.data.name} | ${t("modules.log.header.webhook.title")}`);
			setWebhook(res.data.data);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!webhook) {
		return <LoadingContainer />;
	}

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/logs/webhooks/${webhook.id}/edit`,
			variant: "light",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/logs/webhooks/${webhook.id}/edit`,
		},
	];

	return (
		<div className="content">
			<div className={"content-preview"}>
				<MobileActions actions={mobileActions} />
				<Header
					buttons={isMobile ? [] : buttons}
					title={`${t("modules.log.header.webhook.title")} ${webhook.name}`}
				/>
				<WebhookPreview webhook={webhook} getRequestItemUrl={() => `/${organization.id}/logs/webhooks`} />
			</div>
		</div>
	);
};
export default OrganizationLogsWebhooksPreviewPage;
