import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { Button, Collapse, Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { delay } from "go-core";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import EmptyList, { EmptyListConfigAction } from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import PdfGenerationOptionsModal from "go-core/components/PdfGenerationOptionsModal";
import { PdfOrientationType } from "go-core/types";
import List from "go-list/core";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import { listFiltersDecode } from "go-list/core/components/Filter/services/decoder";
import { ListConfigFilter, ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { listDataParamsDefault } from "go-list/list/services/decoder";
import {
	decodeSegmentValue,
	getSelectedSegmentForListConfig,
	getSortedFilters,
	segmentToParams,
} from "go-list/list/services/segment-service";
import { GoListSegmentType } from "go-list/list/services/types";
import Segment from "go-segment/components";
import { SegmentContext } from "go-segment/context";
import { SegmentApi } from "go-segment/services/types";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
	ItemTranslationItemNameFormApi,
	ItemTranslationListFormProps,
	MenuPriceListFlatApi,
	SpreadsheetPriceListFormProps,
} from "../../../../../../../../services/Api/Organization/types";
import VirtualTableColumns from "./VirtualTableColumns";
import {
	VirtualTableAdditionalColumn,
	VirtualTableColumn,
	VirtualTableListSelectedSort,
	VirtualTableSortingModeEnum,
	VirtualTableWrapperHandle,
} from "./types";
import { getVisibleVirtualTableColumns, readVirtualTableSegmentFromUrl } from "./utils";

interface Props {
	defaultColumns: VirtualTableColumn[];
	additionalColumns: VirtualTableAdditionalColumn[];
	allPossibleDefaultColumns: VirtualTableColumn[];
	segmentType: string;
	filters: ListConfigFilter[];
	items: (MenuPriceListFlatApi | ItemTranslationItemNameFormApi | ItemFormApi)[];
	form: formType;
	emptyListActions?: EmptyListConfigAction[];
	emptyListDescription: string;
	children: React.ReactNode;
	handleSubmit: (e?: React.BaseSyntheticEvent<Record<string, any>, any, any> | undefined) => Promise<void>;
	sortings: VirtualTableListSelectedSort[];
	setSortings: (sortings: VirtualTableListSelectedSort[]) => void;
	initialItems: (MenuPriceListFlatApi | ItemTranslationItemNameFormApi | ItemFormApi)[];
	handleGeneratePdf: (orientation?: PdfOrientationType, fontSize?: string) => void;
	handleGenerateCsv: () => void;
	exportConfig: ExportConfig;
}

type formType =
	| UseFormReturn<SpreadsheetPriceListFormProps>
	| UseFormReturn<ItemTranslationListFormProps>
	| UseFormReturn<ItemGroupGroupEditingFormProps>;

const VirtualTableWrapper = forwardRef<VirtualTableWrapperHandle, Props>(
	(
		{
			additionalColumns,
			defaultColumns,
			segmentType,
			filters,
			items,
			allPossibleDefaultColumns,
			emptyListActions,
			emptyListDescription,
			children,
			handleSubmit,
			sortings,
			setSortings,
			initialItems,
			handleGeneratePdf,
			handleGenerateCsv,
			form,
			exportConfig,
		}: Props,
		ref
	) => {
		const { t } = useTranslation();
		const defaultSegment = {
			id: "all",
			slug: "all",
			name: `${t("common.word.all", { ns: "lib" })}`,
			columns: [
				...defaultColumns,
				...additionalColumns.map((additionalColumn) => additionalColumn.columns).flat(),
			].map((column) => column.id),
		};
		const [segments, setSegments] = useState<GoListSegmentType[]>([defaultSegment]);
		const [selectedSegment, setSelectedSegment] = useState<string>(
			readVirtualTableSegmentFromUrl() || defaultSegment.slug
		);
		const [openFilters, setOpenFilters] = useState<boolean>(true);
		const [selectedFilters, setSelectedFilters] = useState<ListSelectedFilter[]>(
			listFiltersDecode(new URLSearchParams(window.location.search)?.get("f") || "", true)
		);
		const [segmentModal, setSegmentModal] = useState<boolean>(false);
		const [segment, setSegment] = useState<GoListSegmentType>(defaultSegment);
		const [segmentErrors, setSegmentErrors] = useState<ApiError[]>([]);
		const [selectedColumns, setSelectedColumns] = useState<string[]>(
			[...defaultColumns, ...additionalColumns.map((additionalColumn) => additionalColumn.columns).flat()].map(
				(column) => column.id
			)
		);
		const segmentContext = useContext(SegmentContext);
		const firstSearch = useRef(true);
		const [search, setSearch] = useState("");
		const [loading, setLoading] = useState<boolean>(false);
		const firstUpdate = useRef(true);
		const clearFilters = useRef(true);
		const clearSortings = useRef(true);
		const areSegmentsFetched = useRef(false);
		const history = useHistory();
		const [searchParams, setSearchParams] = useState<URLSearchParams | undefined>(undefined);
		const { setError } = form;
		const { addFlash, addSuccessFlash } = useFlash();
		const [showPdfGenerationOptionsModal, setShowPdfGenerationOptionsModal] = useState(false);

		useImperativeHandle(ref, () => ({
			sortings,
			search,
			selectedFilters,
			visibleDefaultColumns: getVisibleVirtualTableColumns(allPossibleDefaultColumns, selectedColumns),
			selectedColumns,
		}));

		useEffect(() => {
			const f = new URLSearchParams(window.location.search)?.get("f");
			if (f) clearFilters.current = false;
		}, []);

		useEffect(() => {
			setSearchParams(new URLSearchParams(window.location.search) as URLSearchParams);
		}, []);

		useEffect(() => {
			setSortings(parseColumnsSortFromUrl());
		}, []);

		useEffect(() => {
			const searchFromUrl = new URLSearchParams(window.location.search)?.get("search");
			if (searchFromUrl) {
				firstSearch.current = false;
				setSearch(searchFromUrl);
			}
		}, []);

		useEffect(() => {
			(async () => {
				let allSegments: GoListSegmentType[] = [];
				if (firstUpdate.current) {
					allSegments = await getSegments();
					setSegment(allSegments.find((s) => s.slug === selectedSegment) as GoListSegmentType);
					if (!readVirtualTableSegmentFromUrl()) {
						const parsedSegments: SegmentApi[] = allSegments.map((segment, index) => ({
							default: segment.default || false,
							slug: segment.slug,
							position: segment.position || index,
							name: segment.name,
							id: Number(segment.id),
							list_name: "",
							status: "",
							value: segment.value_v2 || "",
							resource_type: "",
							value_v2: segment.value_v2 || "",
						}));
						setSelectedSegment(getSelectedSegmentForListConfig(parsedSegments, "all") || "all");
					}
				}

				if (selectedSegment === "all") {
					const columnsFromUrl = new URLSearchParams(window.location.search)?.get("c");
					if (sortings) {
						if (clearSortings.current) {
							setSortings([]);
						} else setSortings(parseColumnsSortFromUrl());
					} else setSortings([]);
					if (selectedFilters) {
						if (clearFilters.current) {
							setSelectedFilters([]);
						} else {
							const decodedFilters = listFiltersDecode(
								new URLSearchParams(window.location.search)?.get("f") || "",
								true
							);
							setSelectedFilters(decodedFilters);
						}
					} else {
						setSelectedFilters([]);
					}
					if (columnsFromUrl) {
						const parsedAdditionalColumnsFromUrl = additionalColumns
							.map((additionalColumn) => additionalColumn.columns)
							.flat()
							.filter((additionalColumn) => columnsFromUrl.split(",").includes(additionalColumn.name))
							.map((x) => x.id);
						setSelectedColumns([...columnsFromUrl.split(","), ...parsedAdditionalColumnsFromUrl]);
					} else {
						setSelectedColumns([
							...defaultColumns.map((col) => col.id),
							...additionalColumns
								.map((additionalColumn) => additionalColumn.columns)
								.flat()
								.map((col) => col.id),
						]);
					}
				}
				if (!areSegmentsFetched.current) areSegmentsFetched.current = true;
			})();
		}, [searchParams]);

		useEffect(() => {
			if (selectedSegment) {
				const newSegment = segments.find((singleSegment) => singleSegment.slug === selectedSegment);
				if (newSegment) setSegment(newSegment);
			}
		}, [selectedSegment]);

		useEffect(() => {
			if (selectedSegment !== "all") {
				const decodedSegment = decodeSegmentValue(segment, segment?.value_v2 || "");
				decodedSegment?.columns ? setSelectedColumns([...decodedSegment.columns]) : setSelectedColumns([]);
				decodedSegment?.filters ? setSelectedFilters([...decodedSegment.filters]) : setSelectedFilters([]);
				decodedSegment?.sort ? setSortings(parseSortings(decodedSegment.sort)) : setSortings([]);
			} else if (selectedSegment === "all") {
				const columnsFromUrl = new URLSearchParams(window.location.search).get("c");
				if (columnsFromUrl && firstUpdate.current) {
					const parsedAdditionalColumnsFromUrl = additionalColumns
						.map((additionalColumn) => additionalColumn.columns)
						.flat()
						.filter((additionalColumn) => columnsFromUrl.split(",").includes(additionalColumn.name))
						.map((x) => x.id);
					setSelectedColumns([...columnsFromUrl.split(","), ...parsedAdditionalColumnsFromUrl]);
				} else {
					setSelectedColumns([
						...defaultColumns.map((col) => col.id),
						...additionalColumns
							.map((additionalColumn) => additionalColumn.columns)
							.flat()
							.map((col) => col.id),
					]);
				}
				setSelectedFilters([]);
				setSortings([]);
			}
		}, [segment.id]);

		const parseSortings = (sortingsToBeParsed: string[]): VirtualTableListSelectedSort[] => {
			return sortingsToBeParsed?.map((s) => {
				const isDesc = s.includes("-");
				const columnName = isDesc ? s.split("-")[1] : s;
				return {
					columnName,
					sortingMode: isDesc ? VirtualTableSortingModeEnum.DESC : VirtualTableSortingModeEnum.ASC,
				};
			});
		};

		const parseColumnsSortToUrl = () => {
			return sortings
				.filter((s) => s.sortingMode)
				.map((s) => {
					if (s.sortingMode === VirtualTableSortingModeEnum.ASC) return s.columnName;
					if (s.sortingMode === VirtualTableSortingModeEnum.DESC) return s.sortingMode + s.columnName;
					return undefined;
				}) as string[];
		};

		const parseColumnsSortFromUrl = () => {
			const sortFromUrl = new URLSearchParams(window.location.search).get("sort");
			if (sortFromUrl) {
				if (clearSortings.current) clearSortings.current = false;
				return sortFromUrl.split(",").map((s) => {
					if (s.includes(VirtualTableSortingModeEnum.DESC))
						return {
							columnName: s.split(VirtualTableSortingModeEnum.DESC)[1],
							sortingMode: VirtualTableSortingModeEnum.DESC,
						};
					if (s)
						return {
							columnName: s,
							sortingMode: VirtualTableSortingModeEnum.ASC,
						};
					return undefined;
				}) as VirtualTableListSelectedSort[];
			}
			return [];
		};

		useEffect(() => {
			if (firstUpdate.current) {
				firstUpdate.current = false;
			} else {
				history.push({
					search: `?${new URLSearchParams(
						listDataParamsDefault({
							filters: selectedFilters,
							search,
							sort: parseColumnsSortToUrl(),
							columns: [
								...selectedColumns.filter((col) =>
									allPossibleDefaultColumns.map((defaultCol) => defaultCol.id).includes(col)
								),
								...selectedColumns.filter((col) =>
									additionalColumns
										.map((additionalCol) => additionalCol.columns.map((column) => column.id))
										.flat()
										.includes(col)
								),
							],
							selectedSegment: selectedSegment || "all",
						})
					).toString()}`,
				});
			}
		}, [
			search,
			selectedFilters.map((selectedFilter) => `${selectedFilter.filterId}|${selectedFilter.value}`).join(","),
			selectedColumns.join(","),
			selectedSegment,
			sortings.join(","),
		]);

		const encodeSortings = (sortingsToBeEncoded: VirtualTableListSelectedSort[]): string[] => {
			return sortingsToBeEncoded?.map((s) => {
				return `${s.sortingMode === VirtualTableSortingModeEnum.DESC ? "-" : ""}${s.columnName}`;
			});
		};

		const onChangeSegment = (newSegment: GoListSegmentType) => {
			setSelectedSegment(newSegment.slug);
		};

		const onSaveSegment = async (segmentToBeSaved: GoListSegmentType) => {
			try {
				return await saveSegment(segmentToBeSaved);
			} catch (e) {
				return handleException(e);
			}
		};

		const saveSegment = async (segmentToBeSaved: GoListSegmentType) => {
			const newSegment = {
				...segmentToBeSaved,
				filters: selectedFilters,
				columns: selectedColumns,
				sort: encodeSortings(sortings),
				default: segmentToBeSaved.default !== undefined ? segmentToBeSaved.default : false,
				position: segmentToBeSaved.position !== undefined ? segmentToBeSaved.position : segments.length - 1,
			};
			try {
				const res = await segmentContext.save(segmentType, segmentType, segmentToParams(newSegment));
				await getSegments();
				setSegmentErrors([]);
				if (!segments.map((s) => s.slug).includes(res.data.data.slug)) {
					setSelectedSegment(res.data.data.slug);
				}
				setSegment(res.data.data);
				setSegmentModal(false);
			} catch (e) {
				setSegmentErrors(handleException(e));
			}
		};

		const getSegments = async () => {
			const res = await segmentContext.get(segmentType, segmentType);
			const newSegments = [
				...new Map(
					[
						...segments,
						...res.segments.map(
							(segment) =>
								({
									id: segment.id.toString(),
									name: segment.name,
									resource_type: segment.resource_type,
									list_name: segment.list_name,
									value_v2: segment.value_v2,
									slug: segment.slug,
									status: segment.status,
									editable: true,
									position: segment.position,
									default: segment.default,
								} as GoListSegmentType)
						),
					].map((item) => [item.slug, item])
				).values(),
			];
			setSegments([...newSegments]);
			return [...newSegments];
		};

		const onCreateSegment = async (name: string) => {
			const params = {
				name,
				filters: selectedFilters,
				columns: selectedColumns,
				sort: encodeSortings(sortings),
				position: segments.length - 1,
				default: false,
			} as GoListSegmentType;
			try {
				await saveSegment(params);
				await getSegments();
			} catch (e) {
				return handleException(e);
			}
		};

		const onChangeFilters = (newFilters: ListSelectedFilter[]) => {
			setSelectedFilters(
				[...newFilters].flat().map((filter) => {
					delete filter.resources;
					return filter;
				})
			);
		};

		const searchFilter = async (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
			const value = e.currentTarget.value;
			firstSearch.current = false;
			setSearch(value);
			await delay(10);
		};

		const keyDownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter") {
				await searchFilter(event);
			}
		};

		const onChangeColumn = (newSelectedColumns: string[]) => {
			if (JSON.stringify(newSelectedColumns) !== JSON.stringify(selectedColumns)) {
				setSelectedColumns([...newSelectedColumns]);
			}
		};

		const drawListContent = () => {
			if (initialItems.length === 0)
				return <EmptyList title={t("lib:go_core.empty_list_data.title")} actions={emptyListActions} />;
			if (items.length === 0)
				return <EmptyList title={t("lib:go_core.empty_data.title")} description={emptyListDescription} />;
			return children;
		};

		const onSubmit = async () => {
			setLoading(true);
			try {
				await handleSubmit();
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} catch (err) {
				handleError.form(err, setError, addFlash);
			}
			setLoading(false);
		};

		const onPdfExport = (orientation?: PdfOrientationType, fontSize?: string) => {
			const newExportConfig = {
				...exportConfig,
			};

			if (newExportConfig.pdfOrientation) {
				newExportConfig.pdfOrientation =
					newExportConfig.pdfOrientation === "ASK"
						? orientation || "LANDSCAPE"
						: newExportConfig.pdfOrientation;
			}

			if (fontSize) {
				newExportConfig.pdfFontSize = fontSize;
			}

			handleGeneratePdf(newExportConfig.pdfOrientation, newExportConfig.pdfFontSize);
		};

		const handlePdfExport = (orientation?: PdfOrientationType, fontSize?: string) => {
			if (exportConfig.pdfOrientation === "ASK" || exportConfig?.pdfFontSize === "0") {
				setShowPdfGenerationOptionsModal(true);
				return;
			}

			onPdfExport(orientation, fontSize);
		};

		const isSegmentChanged = () => {
			if (segment) {
				const stateFilters = selectedFilters?.length === 0 ? undefined : selectedFilters;
				const segmentFilters = segment?.filters?.length === 0 ? undefined : segment.filters;
				const sortedStateFilters = getSortedFilters(stateFilters);
				const sortedSegmentFilters = getSortedFilters(segmentFilters);

				if (JSON.stringify(sortedStateFilters) !== JSON.stringify(sortedSegmentFilters)) {
					return true;
				}

				if (Array.isArray(selectedColumns)) {
					const segmentColumns = segment?.columns?.join(",");
					const stateColumns = selectedColumns.join(",");

					if (segmentColumns && stateColumns !== segmentColumns) return true;
				}

				const stateSort = sortings?.length === 0 ? undefined : sortings?.join(",");
				const segmentSort = segment?.sort?.length === 0 ? undefined : segment?.sort?.join(",");
				if (segmentSort !== stateSort) {
					return true;
				}

				return false;
			}
			if (selectedFilters.length !== 0) {
				return true;
			}

			return false;
		};

		if (!areSegmentsFetched.current) return <LoadingContainer />;

		return (
			<>
				{showPdfGenerationOptionsModal && (
					<PdfGenerationOptionsModal
						show={showPdfGenerationOptionsModal}
						onHide={() => setShowPdfGenerationOptionsModal(false)}
						onSubmit={(orientation: PdfOrientationType, fontSize: string) =>
							onPdfExport(orientation, fontSize)
						}
						defaultValues={{
							fontSize: exportConfig?.pdfFontSize,
							orientation:
								exportConfig?.pdfOrientation === "ASK" ? undefined : exportConfig?.pdfOrientation,
						}}
					/>
				)}
				<Form className="virtual-table-wrapper">
					<div className="virtual-table-wrapper-container">
						<div className="list-actions">
							{segments && (
								<Segment.List
									selected={selectedSegment || "all"}
									segments={segments}
									onChange={onChangeSegment}
								/>
							)}
							<Button
								variant={classNames("", { primary: openFilters })}
								onClick={() => setOpenFilters(!openFilters)}
								aria-controls="filter-list-collapse"
								className="filter-list-collapse"
								aria-expanded={openFilters}
							>
								{selectedFilters && selectedFilters.length > 0
									? `${selectedFilters.length} ${t("common.word.filters", { ns: "lib" })}`
									: `${t("common.word.filters", { ns: "lib" })}`}
							</Button>
							{isSegmentChanged() && <Segment.Save onClick={() => setSegmentModal(true)} />}
							{segment && segmentModal && (
								<Segment.SaveModal
									segment={segment}
									errors={segmentErrors}
									onSave={onSaveSegment}
									onCreate={onCreateSegment}
									onHide={() => setSegmentModal(false)}
								/>
							)}
						</div>
						{filters && (
							<Collapse in={openFilters}>
								<div id="filter-list-collapse" className="filters-list">
									<List.Filters
										filters={filters}
										selectedFilters={[...selectedFilters]}
										onChange={onChangeFilters}
									/>
								</div>
							</Collapse>
						)}
						<div className="list-search">
							<div className="list-search-input">
								<input
									type={"search"}
									className={"form-control"}
									placeholder={t("lib:common.word.search")}
									onBlur={searchFilter}
									onKeyDown={keyDownHandler}
									defaultValue={search}
								/>
							</div>
							<div className="list-search-columns">
								<List.Actions
									onCsvExport={handleGenerateCsv}
									onPdfExport={handlePdfExport}
									state={{
										search: "",
									}}
								/>
								<VirtualTableColumns
									additionalColumns={additionalColumns}
									onChange={onChangeColumn}
									selectedColumns={[...selectedColumns]}
									defaultColumns={allPossibleDefaultColumns}
								/>
							</div>
						</div>
						<div className="list-content">{drawListContent()}</div>
					</div>
					<div className="form-footer mt-5">
						<Form.Group className="form-group">
							<ButtonLoading loading={loading} variant="primary" type="button" onClick={onSubmit}>
								{t("common.action.save", { ns: "lib" })}
							</ButtonLoading>
						</Form.Group>
					</div>
				</Form>
			</>
		);
	}
);
VirtualTableWrapper.displayName = "VirtualTableWrapper";
export default VirtualTableWrapper;
