import React, { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MenuPriceListApi } from "../../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	isShown: boolean;
	onHide: () => void;
	priceLists: MenuPriceListApi[];
	applyPriceToSelected: (selectedPriceLists: MenuPriceListApi[], itemIndex: number) => void;
	itemIndex: number | undefined;
}

const PriceListsModal: FC<Props> = ({ isShown, onHide, priceLists, applyPriceToSelected, itemIndex }) => {
	const { t } = useTranslation();
	const [selectedPriceLists, setSelectedPriceLists] = useState<MenuPriceListApi[]>([]);

	const selectPriceList = (priceList: MenuPriceListApi) => {
		const index = selectedPriceLists.findIndex((x) => x.id === priceList.id);
		if (index > -1) {
			const newPriceLists = [...selectedPriceLists];
			newPriceLists.splice(index, 1);
			setSelectedPriceLists([...newPriceLists]);
		} else setSelectedPriceLists([...selectedPriceLists, priceList]);
	};

	const getCheckboxesValue = (priceList: MenuPriceListApi) => {
		return selectedPriceLists.includes(priceList);
	};

	const toggleSelectAll = () => {
		if (selectedPriceLists.length === priceLists.length) setSelectedPriceLists([]);
		else setSelectedPriceLists([...priceLists]);
	};

	const drawPriceLists = () => {
		return priceLists.map((priceList, index) => (
			<Form.Check
				key={priceList.name}
				id={`price-list-${index}`}
				className="mt-2"
				type="switch"
				onClick={() => selectPriceList(priceList)}
				checked={selectedPriceLists.length === priceLists.length || getCheckboxesValue(priceList)}
				label={priceList.name}
			/>
		));
	};

	return (
		<Modal show={isShown} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.group_edit.header.price_lists_modal.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Check
					id="toggle-select-all-price-lists"
					type="switch"
					onClick={() => toggleSelectAll()}
					checked={priceLists.length === selectedPriceLists.length}
					label={t("common.word.select_all")}
				/>
				{drawPriceLists()}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={() => {
						if (itemIndex !== undefined) {
							applyPriceToSelected(selectedPriceLists, itemIndex);
							setSelectedPriceLists([]);
						}
					}}
				>
					{t("common.action.apply", { ns: "lib" })}
				</Button>
				<Button
					variant="light"
					onClick={() => {
						onHide();
						setSelectedPriceLists([]);
					}}
				>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PriceListsModal;
