import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { ListConfigFilter, ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { ReportConfigColumn } from "../../core/services/types";
import { GoReportSegmentType, ReportConfig, ReportParamsType } from "./types";

export const selectActiveSegment = (state: ReportParamsType): GoReportSegmentType | undefined => {
	if (!state.segments || !state.selectedSegment) {
		return undefined;
	}
	return state.segments?.filter((segment) => segment.slug === state.selectedSegment)[0];
};

export const selectFilters = (config: ReportConfig): ListConfigFilter[] => {
	let newFilters = config.filters ? config.filters : [];
	newFilters = [
		...newFilters,
		...(config.fields
			? config.fields.map((field) => {
					return {
						id: field.id,
						name: field.name,
						type: field.type,
						options: field.options,
						resources: field.resources,
						listOptions: field.listOptions,
						filterByListOptionValue: field.filterByListOptionValue,
					} as ListConfigFilter;
			  })
			: []),
	];
	return newFilters;
};

export const selectGroups = (config: ReportConfig): Record<string, any>[] => {
	let newGroups = config.groups ? config.groups : [];

	if (!config.skipCustomFieldGroups) {
		newGroups = [
			...newGroups,
			...(config.fields
				? config.fields.map((field) => {
						return {
							id: field.id.toUpperCase(),
							name: field.name,
							resources: field?.resources,
						};
				  })
				: []),
		];
	}
	return newGroups;
};

export const selectColumns = (config: ReportConfig, selectedColumns: string[]): ReportConfigColumn[] => {
	const columnsFromConfig = config.fields;
	columnsFromConfig?.sort((a: ReportConfigColumn, b: ReportConfigColumn): number => {
		const first = a.id;
		const second = b.id;
		if (
			selectedColumns.indexOf(first) > selectedColumns.indexOf(second) ||
			selectedColumns.indexOf(first) === -1 ||
			selectedColumns.indexOf(second) === -1
		) {
			return 1;
		}
		return -1;
	});

	let newColumns = config.columns ? config.columns : [];
	newColumns = [
		...newColumns,
		...(columnsFromConfig
			? columnsFromConfig.map((field) => {
					return {
						id: field.id,
						name: field.name,
						render: field.render,
					} as ReportConfigColumn;
			  })
			: []),
	];
	return newColumns;
};

export const updateFilterValuesForSelectedFilters = (
	filters: ListSelectedFilter[],
	filterValues?: any[]
): ListSelectedFilter[] => {
	filters.forEach((filter) => {
		if (filter.value) {
			if (filter.valueInfo === undefined) {
				const values = filter.value.split(FILTER_VALUE_SEPARATOR).map((val) => parseInt(val));
				const valuesForFilter = filterValues?.filter(
					(filterValue) =>
						filterValue.field_name === filter.filterId ||
						filterValue.field_name?.toLowerCase() === filter.filterId?.replaceAll("_", "")?.toLowerCase()
				);
				const valueInfos = {} as any;
				values.forEach((value) => {
					const valueInfo = valuesForFilter?.filter(
						(filterValue) => filterValue.id.toString() === value.toString()
					)[0];
					if (valueInfo !== undefined) {
						valueInfos[`${valueInfo.id}`] = valueInfo.value;
					}
				});
				filter.valueInfo = valueInfos;
			}
		}
	});
	return filters;
};

export const selectSelectedFilters = (state: ReportParamsType, filterValues?: any): ListSelectedFilter[] => {
	if (state.filters !== undefined) {
		return updateFilterValuesForSelectedFilters(state.filters, filterValues);
	}
	const activeSegment = selectActiveSegment(state);
	if (activeSegment && activeSegment.filters) {
		return updateFilterValuesForSelectedFilters(activeSegment.filters, filterValues);
	}

	return [];
};

export const selectSelectedColumns = (state: ReportParamsType): Array<string> => {
	if (state.columns !== undefined) {
		return state.columns;
	}
	return [];
};

export const selectSelectedSort = (state: ReportParamsType): string => {
	if (state.sort !== undefined) {
		return state.sort;
	}
	return "";
};

export const selectShowPercent = (state: ReportParamsType): boolean => {
	return Boolean(state.showPercent && state.showPercent);
};
