import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as HomeSVG } from "../../../../../../../../../../images/svg/bills/home.svg";
import { ReactComponent as MessageSVG } from "../../../../../../../../../../images/svg/bills/message.svg";
import { ReactComponent as PhoneSVG } from "../../../../../../../../../../images/svg/bills/phone.svg";
import { EmployeeApi } from "../../../../../../../../../../services/Api/Organization/types";
import { AddressApi } from "../../../../../../../../../../services/Api/types";

export interface Props {
	employee: EmployeeApi;
}

const ContactCard: FC<Props> = ({ employee }) => {
	const address = {
		build_nr: employee.address_build_nr,
		flat_nr: employee.address_flat_nr,
		street: employee.address_street,
		zip_code: employee.address_zip_code,
		country: employee.address_country,
		city: employee.address_city,
	} as AddressApi;
	const addressEmpty = Object.values(address).every((val) => val === undefined || val === "");
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.employee.field.contact.title")}</h5>
				<div className="card-body-item">
					<HomeSVG />
					<div className="item-info">
						{addressEmpty ? (
							<Link to={`/${organization.id}/employees/${employee.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						) : (
							<FormatAddress address={address} />
						)}
					</div>
				</div>
				<div className="card-body-item">
					<MessageSVG />
					<div className="item-info">
						{employee.contact_email ? (
							<span>{employee.contact_email}</span>
						) : (
							<Link to={`/${organization.id}/employees/${employee.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						)}
					</div>
				</div>
				<div className="card-body-item">
					<PhoneSVG />
					<div className="item-info">
						{employee.contact_phone_number ? (
							<span>{employee.contact_phone_number}</span>
						) : (
							<Link to={`/${organization.id}/employees/${employee.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						)}
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};
export default ContactCard;
