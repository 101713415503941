import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormSelect } from "go-form";
import { FormDirty } from "go-form/components/FormDirty";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { OrderSearchSelectApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";

interface FormProps {
	orderId: number | string;
	number: string;
}

const InvoiceToOrderForm = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const form = useForm<FormProps>();
	const { formState, control, watch, handleSubmit } = form;
	const { t } = useTranslation();
	const [order, setOrder] = useState<OrderSearchSelectApi | undefined>();

	const onSubmit = handleSubmit((data: FormProps) => {
		history.push({
			pathname: `/${organization.id}/invoices/new`,
			state: {
				order: {
					id: data.orderId,
					closed_at: order?.closed_at,
					contact: order?.contact,
					number: order?.label,
				},
			},
		});
	});

	const searchOrder = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"status|e": `CLOSED${FILTER_SEPARATOR}invoice|u`,
		};
		return api.organization().getOrdersSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	return (
		<FormDirty formState={formState} onSubmit={onSubmit}>
			<div className="mb-4">
				<FormSelect
					loadOptions={searchOrder}
					control={control}
					isClearable={false}
					name="orderId"
					getOptionLabel={(opt) => opt.label}
					getOptionValue={(opt) => opt.id}
					onChange={(value, obj) => setOrder(obj)}
				/>
			</div>
			<Button onClick={onSubmit} type="submit" disabled={!watch("orderId")}>
				{t("modules.invoice.action.create_invoice.title")}
			</Button>
		</FormDirty>
	);
};

export default InvoiceToOrderForm;
