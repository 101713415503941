import React, { useState } from "react";
import { generateUUID } from "../../services/services";
import { CatalogReportCombo, TemplateInstanceReportCombo, TemplateReportCombo } from "../Template/types";

interface Props {
	catalog: CatalogReportCombo[];
	template: TemplateReportCombo;
	onUpdate: (template: TemplateReportCombo) => void;
	onEdit: (templateItem: TemplateInstanceReportCombo) => void;
}

const CatalogTemplatesReportComboComponent = (props: Props) => {
	const catalogTemplates = props.catalog;
	const template = props.template;
	const [page, setPage] = useState("choose");
	const filteredCatalogTemplates = catalogTemplates;

	const addTemplate = (catalog: CatalogReportCombo) => {
		if (template.templates.some((x) => x.template_id === catalog.id)) return;
		const newTemplates = template?.templates ? [...template?.templates] : [];
		newTemplates.push({
			id: generateUUID(),
			template_id: catalog.id,
			name: catalog.name,
			data: catalog.data,
		} as TemplateInstanceReportCombo);
		const newTemplate = template
			? {
					...template,
					templates: newTemplates,
			  }
			: ({} as TemplateReportCombo);
		props.onUpdate(newTemplate);
	};

	const removeTemplateByCatalog = (catalog: CatalogReportCombo) => {
		if (!template.templates.some((x) => x.template_id === catalog.id)) return;
		let newTemplates = template?.templates ? [...template?.templates] : [];
		newTemplates = newTemplates.filter((x) => x.template_id !== catalog.id);
		const newTemplate = template
			? {
					...template,
					templates: newTemplates,
			  }
			: ({} as TemplateReportCombo);
		props.onUpdate(newTemplate);
	};

	const removeTemplate = (templateId: string) => {
		if (!template.templates.some((x) => x.id === templateId)) return;
		let newTemplates = template?.templates ? [...template?.templates] : [];
		newTemplates = newTemplates.filter((x) => x.id !== templateId);
		const newTemplate = template
			? {
					...template,
					templates: newTemplates,
			  }
			: ({} as TemplateReportCombo);
		props.onUpdate(newTemplate);
	};

	return (
		<>
			<div>
				<ul className="nav nav-underline mb-4">
					<li className="nav-item">
						<a
							className={`nav-link${page === "choose" ? " active" : ""}`}
							onClick={() => setPage("choose")}
							href="#"
						>
							Szablony
						</a>
					</li>
					<li className="nav-item">
						<a
							className={`nav-link${page === "selected" ? " active" : ""}`}
							onClick={() => setPage("selected")}
							href="#"
						>
							Wybrane ({template.templates.length})
						</a>
					</li>
				</ul>
				<div className={"row row-cols-1"}>
					{page === "selected" ? (
						<>
							{template.templates.map((templateItem) => {
								const catalogTemplate = catalogTemplates.filter(
									(x) => x.id === templateItem.template_id
								)[0];
								return (
									<div key={templateItem.id}>
										<div className={`card border-primary mb-2`}>
											<div className="card-body">
												<h5 className="card-title">{templateItem.name}</h5>
												<p className="card-text">{templateItem.description}</p>
												<button
													className="btn btn-default"
													onClick={() => props.onEdit(templateItem)}
												>
													Edytuj
												</button>
												{` `}
												<button
													className="btn btn-danger"
													onClick={() => removeTemplate(templateItem.id)}
												>
													Usuń
												</button>
												{catalogTemplate ? (
													<>
														<hr />
														<div>
															Szablon: {catalogTemplate.name} <br />
															<small>{catalogTemplate.description}</small>
														</div>
													</>
												) : (
													<>
														<hr />
														<span className="badge text-bg-danger">Własny szablon</span>
													</>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</>
					) : (
						<>
							{filteredCatalogTemplates.map((catalogTemplate) => {
								const selected = template.templates.some((x) => x.template_id === catalogTemplate.id);
								return (
									<div key={catalogTemplate.id}>
										<div
											className={`card mb-2 ${selected ? " border-primary" : ""}`}
											onClick={() => addTemplate(catalogTemplate)}
										>
											<div className="card-body">
												<h5 className="card-title">{catalogTemplate.name}</h5>
												<p className="card-text">{catalogTemplate.description}</p>
												{selected ? (
													<button
														className="btn btn-danger"
														onClick={() => removeTemplateByCatalog(catalogTemplate)}
													>
														Usuń
													</button>
												) : (
													<button
														className="btn btn-primary"
														onClick={() => removeTemplateByCatalog(catalogTemplate)}
													>
														Dodaj
													</button>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default CatalogTemplatesReportComboComponent;
