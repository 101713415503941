import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import FormatResourceStatus from "../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { InconsistentReference } from "../../../../../../components/Common/InconsistentReferenceTooltip/InconsistentReference";
import RenderPaymentMethod from "../../../../../../components/Common/Renderers/RenderPaymentMethod";
import { TransactionModel } from "../../../../../../services/Api/Organization/types";
import TransactionPreview from "../../pages/Sales/pages/Transactions/components/TransactionPreview";

interface Props {
	transactions: TransactionModel[];
	rowActions?: (item: TransactionModel, index: number) => JSX.Element;
	tableBodyChildren?: JSX.Element;
	showTransaction: number | null;
	setShowTransaction: (value: number | null) => void;
	fetchOrder?: () => void;
}

interface DrawPaymentMethodWrapperProps {
	children: ReactNode;
	id?: number;
	setShowTransaction: (showTransaction: number) => void;
}

const DrawPaymentMethodWrapper = ({ children, id, setShowTransaction }: DrawPaymentMethodWrapperProps) => {
	if (id)
		return (
			<a href="#" onClick={() => setShowTransaction(id)}>
				{children}
			</a>
		);
	return <>{children}</>;
};

const LastTransactionsTable = ({
	transactions,
	rowActions,
	tableBodyChildren,
	showTransaction,
	setShowTransaction,
	fetchOrder,
}: Props): JSX.Element => {
	const { t } = useTranslation();

	const hasAtLeastOneTransactionCtx = () => {
		return !!transactions.find((transaction) => transaction?.contexts?.find((f) => f.type && f.number));
	};

	return (
		<>
			{transactions.length > 0 ? (
				<div className="table-responsive">
					<table className="table table-preview">
						<thead>
							<tr>
								<th>{t("common.word.payment_method")}</th>
								<th>{t("lib:common.word.date")}</th>
								{hasAtLeastOneTransactionCtx() && (
									<th>{t("modules.transaction.field.documents.title")}</th>
								)}
								<th className="text-end">{t("common.word.amount")}</th>
								{rowActions && <th className="w-1" />}
							</tr>
						</thead>
						<tbody>
							{transactions.map((transaction, index) => {
								return (
									<tr key={`transaction-${index}`}>
										<td>
											<div className="d-flex flex-wrap">
												<div className="d-flex flex-column">
													<DrawPaymentMethodWrapper
														id={transaction?.id}
														setShowTransaction={setShowTransaction}
													>
														<RenderPaymentMethod
															id={transaction.payment_method.id}
															name={transaction.payment_method?.name}
															type={transaction?.payment_method?.receipt_type}
														/>
													</DrawPaymentMethodWrapper>
													{transaction.id && (
														<small style={{ marginLeft: "20px" }}>#{transaction.id}</small>
													)}
												</div>
												{transaction.inconsistent_reference_id && (
													<div>
														<InconsistentReference />
													</div>
												)}
											</div>
										</td>
										<td>{FormatDate(transaction.paid_at)}</td>
										{hasAtLeastOneTransactionCtx() && (
											<td>
												<div className="d-flex flex-column">
													{transaction.contexts.map((ctx, ctxIndex) =>
														ctx.type === "INVOICE" ? (
															<span key={ctxIndex}>
																{t(`enums.transactions.context_type.${ctx.type}`)}{" "}
																{ctx.number}
															</span>
														) : (
															<span key={ctxIndex}>
																{t(`enums.transactions.context_type.${ctx.type}`)} #
																{ctx.number}
															</span>
														)
													)}
												</div>
											</td>
										)}
										<td className={"nowrap"}>
											<div className="d-flex justify-content-end align-items-center">
												<div className="me-3 w-100">
													<FormatResourceStatus status={transaction.status} />
												</div>
												<div className="d-flex flex-column text-end">
													{transaction.status === "REMOVED" ? (
														<>
															<span>
																{FormatMoney({ ...transaction.amount, amount: 0 })}
															</span>
															<small style={{ textDecoration: "line-through" }}>
																{FormatMoney(transaction.amount)}
															</small>
														</>
													) : (
														<>
															<span>{FormatMoney(transaction.tender_amount)}</span>
															{transaction?.amount?.amount !==
																transaction?.tender_amount?.amount && (
																<small className="text-muted">
																	{FormatMoney(transaction.amount)}
																</small>
															)}
														</>
													)}
													<small className="text-muted">
														{t(`enums.transactions.context_type.${transaction.type}`)}
													</small>
												</div>
											</div>
										</td>
										{rowActions && <td>{rowActions(transaction, index)}</td>}
									</tr>
								);
							})}
							{tableBodyChildren}
						</tbody>
					</table>
				</div>
			) : (
				<EmptyList title={t("modules.transaction.field.empty_list.title")} />
			)}
			{showTransaction && (
				<TransactionPreview
					id={Number(showTransaction)}
					canChangePaymentMethod
					styles={{ position: "fixed" }}
					handleSuccessAction={fetchOrder}
					onClose={() => setShowTransaction(null)}
				/>
			)}
		</>
	);
};

export default LastTransactionsTable;

// t("enums.transactions.context_type.ORDER");
// t("enums.transactions.context_type.PAY_OUT");
// t("enums.transactions.context_type.PAY_IN");
// t("enums.transactions.context_type.CANCELLATION");
// t("enums.transactions.context_type.CHANGE");
// t("enums.transactions.context_type.INVOICE");
// t("enums.transactions.context_type.REPORT_PAID");
// t("enums.transactions.context_type.CORRECTION");
// t("enums.transactions.context_type.ADVANCE");
// t("enums.transactions.context_type.PROFORMA");
// t("enums.transactions.context_type.INVOICE_ORDER");
// t("enums.transactions.context_type.INVOICE_ORDER_REPORT_PAID");
// t("enums.transactions.context_type.ORDER_REPORT_PAID");
// t("enums.transactions.context_type.INVOICE_REPORT_PAID");
