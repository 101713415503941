import React from "react";
import { AxiosResponse } from "axios";
import { TFunction } from "react-i18next";
import { ItemResponse, ListResponse } from "go-core/api/types";
import { JobApi } from "./types";

export interface JobContextProps {
	all: (params?: Record<string, any>, options?: Record<string, any>) => Promise<AxiosResponse<ListResponse<JobApi>>>;
	get: (id: string, params?: Record<string, any>) => Promise<AxiosResponse<ItemResponse<JobApi>>>;
	cancel: (id: string) => Promise<AxiosResponse>;
	getNameOptions: () => Promise<string[]>;
}

export class JobService {
	_api: JobContextProps;
	_nameTranslationPrefix: string;
	_t: TFunction;

	constructor(api: JobContextProps, nameTranslationPrefix: string, t: TFunction) {
		this._api = api;
		this._nameTranslationPrefix = nameTranslationPrefix;
		this._t = t;
	}

	api(): JobContextProps {
		return this._api;
	}

	getNameTitle(id: string): string {
		const translation = this._t(`${this._nameTranslationPrefix}.${id.toUpperCase()}`);
		return translation.includes(".") ? id : translation;
	}
}

export const JobContext = React.createContext<JobService>({} as JobService);
