import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationListFormProps,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../../components/VirtualTable/types";
import TranslationsVirtualTableHeaderActionsRow from "./TranslationsVirtualTableHeaderActionsRow";
import TranslationsVirtualTableHeaderDefaultColumns from "./TranslationsVirtualTableHeaderDefaultColumns";
import TranslationsVirtualTableHeaderEmptySearchColumnsRow from "./TranslationsVirtualTableHeaderEmptySearchColumnsRow";
import TranslationsVirtualTableHeaderLocalesColumns from "./TranslationsVirtualTableHeaderLocalesColumns";
import TranslationsVirtualTableHeaderSearchColumnsRow from "./TranslationsVirtualTableHeaderSearchColumnsRow";

interface Props {
	maxZindex: number;
	visibleDefaultColumns: VirtualTableColumn[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	getHeaderStyles: (columnId: string) => { width: number };
	sortings: VirtualTableListSelectedSort[];
	visibleLocalesColumns: ItemTranslationItemNameTranslationFormApi[];
	setCategorySearch: (search: string) => void;
	setItemGroupSearch: (search: string) => void;
	setItemFullNameSearch: (search: string) => void;
	setDescriptionSearch: (search: string) => void;
	setPrintingNameSearch: (search: string) => void;
	setNameSearch: (search: string) => void;
	categorySearch: string | undefined;
	itemGroupSearch: string | undefined;
	itemFullNameSearch: string | undefined;
	descriptionSearch: string | undefined;
	nameSearch: string | undefined;
	printingNameSearch: string | undefined;
	form: UseFormReturn<ItemTranslationListFormProps>;
	items: ItemTranslationItemNameFormApi[];
}

const TranslationsVirtualTableHeader = ({
	maxZindex,
	visibleDefaultColumns,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	getHeaderStyles,
	sortings,
	visibleLocalesColumns,
	form,
	items,
	descriptionSearch,
	setDescriptionSearch,
	setPrintingNameSearch,
	setNameSearch,
	setCategorySearch,
	setItemGroupSearch,
	printingNameSearch,
	itemFullNameSearch,
	nameSearch,
	itemGroupSearch,
	setItemFullNameSearch,
	categorySearch,
}: Props) => {
	return (
		<thead
			style={{
				position: "sticky",
				zIndex: maxZindex + 1,
			}}
		>
			<tr className="headers">
				<TranslationsVirtualTableHeaderDefaultColumns
					visibleDefaultColumns={visibleDefaultColumns}
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					maxZindex={maxZindex}
					getHeaderStyles={getHeaderStyles}
					sortings={sortings}
				/>
				<TranslationsVirtualTableHeaderLocalesColumns
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					getHeaderStyles={getHeaderStyles}
					columns={visibleLocalesColumns}
				/>
			</tr>
			<tr className="additional-header-row">
				<TranslationsVirtualTableHeaderSearchColumnsRow
					categorySearch={categorySearch}
					setCategorySearch={setCategorySearch}
					itemFullNameSearch={itemFullNameSearch}
					setItemGroupSearch={setItemGroupSearch}
					itemGroupSearch={itemGroupSearch}
					setItemFullNameSearch={setItemFullNameSearch}
					visibleDefaultColumns={visibleDefaultColumns}
					maxZindex={maxZindex}
					descriptionSearch={descriptionSearch}
					nameSearch={nameSearch}
					setDescriptionSearch={setDescriptionSearch}
					setNameSearch={setNameSearch}
					setPrintingNameSearch={setPrintingNameSearch}
					printingNameSearch={printingNameSearch}
				/>
				<TranslationsVirtualTableHeaderEmptySearchColumnsRow columns={visibleLocalesColumns} />
			</tr>
			<TranslationsVirtualTableHeaderActionsRow
				visibleLocalesColumns={visibleLocalesColumns}
				visibleDefaultColumns={visibleDefaultColumns}
				form={form}
				items={items}
				maxZIndex={maxZindex}
			/>
		</thead>
	);
};

export default TranslationsVirtualTableHeader;
