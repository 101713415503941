import { Range, RangeWithKey } from "react-date-range";
import { MoneyApi } from "go-core/api/types";
import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import {
	isPredefinedRange,
	manipulateHours,
	parsePredefinedRangeToDefaultString,
	parsePredefinedRangeToRange,
	parseRangeToDefaultString,
} from "go-list/utils/daterangeutils";
import { SubReportApi } from "go-report/core/services/types";
import { ChartDataRangeType } from "../services/charts/types";
import { DashboardReportFilter } from "../services/filters/types";

const mockedDateRange = "2022-07-01 00:00:00 - 2022-07-31 23:59:59";

export const parsePredefinedRangeToPreviousPeriodRange = (
	predefinedRange: string,
	dateToString?: boolean
): string | Range => {
	let currentDateRange: RangeWithKey | Range = {};

	if (isPredefinedRange(predefinedRange)) {
		const predefinedRangeObj = parsePredefinedRangeToRange(predefinedRange);
		if (predefinedRangeObj) currentDateRange = predefinedRangeObj;
	} else {
		const [startDateString, endDateString] = predefinedRange.split(FILTER_VALUE_SEPARATOR);
		const startDate = formatStringToDate(startDateString);
		const endDate = formatStringToDate(endDateString);

		currentDateRange = { startDate, endDate };
	}

	const { startDate, endDate } = currentDateRange;

	if (!startDate || !endDate) return "Invalid date range";

	const currentPeriodHourChange = manipulateHours(startDate, endDate);

	if (currentPeriodHourChange !== 0) endDate.setTime(endDate.getTime() + currentPeriodHourChange);

	const rangeDifferenceInMilliseconds = endDate.getTime() - startDate.getTime() + 1000;

	const previousPeriodStartDate = new Date(startDate.getTime() - rangeDifferenceInMilliseconds);
	const previousPeriodHourChange = manipulateHours(previousPeriodStartDate, startDate);

	if (previousPeriodHourChange !== 0)
		previousPeriodStartDate.setTime(previousPeriodStartDate.getTime() + previousPeriodHourChange);

	const previousPeriodDateRange = { startDate: previousPeriodStartDate, endDate: startDate };

	return dateToString ? parseRangeToDefaultString(previousPeriodDateRange) : previousPeriodDateRange;
};

const processFilter = (config: DashboardReportFilter): string => {
	let dateFromFilter = config.filtersConfig.selectedFilter.dateRange;
	dateFromFilter = dateFromFilter.replace(",", FILTER_VALUE_SEPARATOR);
	let organizationIds = config.filtersConfig.selectedFilter.organizationIds;
	const date = isPredefinedRange(dateFromFilter)
		? parsePredefinedRangeToDefaultString(dateFromFilter)
		: dateFromFilter;
	const previousDateRange = parsePredefinedRangeToPreviousPeriodRange(dateFromFilter, true);

	let filter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}date_range|bt=${date}${FILTER_SEPARATOR}compare_date_range|bt=${previousDateRange}${FILTER_SEPARATOR}type=${config.type}${FILTER_SEPARATOR}enable=true${FILTER_SEPARATOR}groups|e=${config.defaultGroup}${FILTER_VALUE_SEPARATOR}${config.chartType}${FILTER_SEPARATOR}time_start=00:00${FILTER_SEPARATOR}time_end=23:59`;
	if (config.include) {
		filter += `${FILTER_SEPARATOR}include|e=${config.include}`;
	}
	if (organizationIds || organizationIds === "") {
		organizationIds = organizationIds.replaceAll(",", FILTER_VALUE_SEPARATOR);
		filter += `${FILTER_SEPARATOR}organization_id|e=${organizationIds}`;
	} else {
		filter += `${FILTER_SEPARATOR}organization_id|e=${config.organizationId}`;
	}
	return btoa(unescape(encodeURIComponent(filter)));
};

const processDashboardReportRequestParams = (config: DashboardReportFilter): Record<string, any> => {
	const newParams: Record<string, any> = { f: processFilter(config) };

	if (config.size) newParams.size = config.size;

	return newParams;
};

const renderAggregateValue = (aggregate: number | MoneyApi, currency: string): number | string => {
	if (typeof aggregate === "number") return aggregate;

	if (!aggregate) {
		return FormatMoney({ amount: 0, currency });
	}

	return FormatMoney({
		amount: aggregate.amount || 0,
		currency,
	});
};

const getDashboardChartType = (report?: SubReportApi): ChartDataRangeType => {
	return report?.sub_report[0]?.group_by_type as ChartDataRangeType;
};

export { processDashboardReportRequestParams, renderAggregateValue, getDashboardChartType, mockedDateRange };
