import { buildI18n } from "go-core/utils/i18nUtils";

const version = "1.0.1";
const cdnUrlTranslation = null;
// "https://delivery.localazy.com/_a793429708366517157342253a03/_e0/f64c54e100c1b01eb7dc6ee7ff86d079c644acfd";
const cdns = {
	translation: cdnUrlTranslation,
};
const i18n = buildI18n(version, cdns, undefined, false, ["pl", "en", "de"]);
export default i18n;
