import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { mapBarcodesToObj } from "./ItemModalForm";

interface Props {
	form: UseFormReturn<any>;
	barcodes: string[];
	prefix: string;
	errors?: Record<string, any>;
}

const BarcodesFormSelect = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
		setValue,
	} = props.form;
	const [selectedBarcodes, setSelectedBarcodes] = useState(
		props.barcodes ? mapBarcodesToObj(props.barcodes.filter((barcode) => barcode) || []) : []
	);
	const loadBarcodes = () => {
		return selectedBarcodes;
	};

	useEffect(() => {
		onChangeBarcode(props.barcodes);
	}, [props.barcodes]);

	const onCreateBarcode = (evt: any) => {
		let arr = selectedBarcodes?.map((x) => x.value);
		if (!arr) {
			arr = [];
		}
		const newSelectedBarcodes = selectedBarcodes;
		newSelectedBarcodes?.push({
			value: evt,
			label: evt,
		});
		if (newSelectedBarcodes) {
			setSelectedBarcodes([...newSelectedBarcodes]);
			setValue(`${props.prefix}barcodes`, newSelectedBarcodes);
		}
	};

	const onChangeBarcode = (barcodes: string[]) => {
		const newBarcodes = mapBarcodesToObj(barcodes);
		if (newBarcodes) {
			setSelectedBarcodes([...newBarcodes]);
			setValue(`${props.prefix}barcodes`, [...newBarcodes]);
		}
	};
	return (
		<FormSelectGroup
			label={t("modules.item_group.field.barcodes.title")}
			isMulti={true}
			name={`${props.prefix}barcodes`}
			errors={errors}
			onCreateOption={onCreateBarcode}
			defaultValue={selectedBarcodes}
			onChange={onChangeBarcode}
			loadOptions={loadBarcodes}
			getOptionLabel={(option) => option.label}
			getOptionValue={(option) => option.label}
			control={control}
			data-testid={`${props.prefix}barcodes`}
		/>
	);
};
export default BarcodesFormSelect;
