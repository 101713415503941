import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import AppsNavigation from "./components/AppsNavigation";
import OrganizationAppsAllPage from "./pages/All";
import OrganizationAppsIndexPage from "./pages/Index";
import { OrganizationAppsItemsPage } from "./pages/Item";
import OrganizationAppsLinkingPage from "./pages/Linking";
import OrganizationAppsToLinkPage from "./pages/ToLink";

const OrganizationAppsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="page-container">
			<AppsNavigation />
			<Switch>
				<div className="content">
					<Route path={`${props.match.url}/active`} component={OrganizationAppsIndexPage} />
					<Route path={`${props.match.url}/all`} component={OrganizationAppsAllPage} />
					<Route path={`${props.match.url}/linking`} component={OrganizationAppsLinkingPage} />
					<Route path={`${props.match.url}/to_link`} component={OrganizationAppsToLinkPage} />
					<Route path={`${props.match.url}/items`} component={OrganizationAppsItemsPage} />
				</div>
			</Switch>
		</div>
	);
};
export default OrganizationAppsPage;
