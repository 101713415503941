import React, { FC, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import EmptyData from "go-core/components/EmptyData";
import { Loading } from "go-core/components/Loading";
import { apiSecurity } from "go-security/services/Api/api";
import { selectIsAuthenticated } from "go-security/services/session/selectors";
import { OrganizationSecurityApi } from "../services/Api/typesSecurity";
import RegisterCompanyForm from "./RegisterCompanyForm";
import RegisterOrganizationForm from "./RegisterOrganizationForm";
import RegisterUserForm from "./RegisterUserForm";
import { RequiredFieldsInformation } from "./RequiredFieldsInformation/RequiredFieldsInformation";

enum FormType {
	EXTERNAL_ORGANIZATION,
	NEW_ORGANIZATION,
	INITIAL,
}

interface Props {
	appName: string;
	baseUrl: string;
}

const RegisterForm: FC<Props> = ({ appName, baseUrl }) => {
	const { t } = useTranslation();
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const [formType, setFormType] = useState<FormType | undefined>(undefined);
	const history = useHistory();
	const searchParams = new URLSearchParams(window.location.search);
	const companyId = searchParams.get("company_id");
	const [externalOrganizations, setExternalOrganizations] = useState<OrganizationSecurityApi[] | undefined>([]);
	const { addFlash } = useFlash();

	useEffect(() => {
		if (companyId) {
			return;
		}

		const params: Record<string, string> = {};
		params.include = "organization";
		if (isAuthenticated) {
			apiSecurity
				.user()
				.getNotInstalledExternalOrganizations(params)
				.then((res: AxiosResponse) => {
					const responseOrganizations: OrganizationSecurityApi[] | undefined = res?.data?.data;
					if (responseOrganizations?.length === 0) {
						setFormType(FormType.NEW_ORGANIZATION);
					} else {
						setFormType(FormType.INITIAL);
					}
					setExternalOrganizations(responseOrganizations);
				})
				.catch((err: Exception | unknown) => {
					handleError.alert(err, addFlash);
				});
		} else {
			setFormType(FormType.NEW_ORGANIZATION);
		}
	}, [companyId]);

	const changeFormType = (formType: FormType) => {
		setFormType(formType);
	};
	const backToInitialPage = () => {
		setFormType(FormType.INITIAL);
		history.replace({
			search: "",
		});
	};
	const setCompanyId = (companyId: string) => {
		history.replace({
			search: `?company_id=${companyId}`,
		});
	};
	const renderBack = () => {
		return (
			<div className="mb-3">
				<a
					href=""
					onClick={(e) => {
						e.preventDefault();
						backToInitialPage();
					}}
				>
					<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7.22222 13L1 7M1 7L7.22222 1M1 7L17 7"
							stroke="#1A86E0"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					{t("lib:common.action.back")}
				</a>
			</div>
		);
	};
	if (companyId) {
		return (
			<>
				{renderBack()}
				<RegisterCompanyForm appName={appName} companyId={companyId} baseUrl={baseUrl} />
			</>
		);
	}

	if (formType === undefined) {
		return <Loading title={t("lib:go_security.register.action.downloading_data_in_progress")} />;
	}
	if (isAuthenticated) {
		return (
			<>
				{formType === FormType.INITIAL ? (
					<div className="select-go-accounts-organizations">
						<Button variant="primary" onClick={() => setFormType(FormType.EXTERNAL_ORGANIZATION)}>
							{t("lib:go_security.register.form.external_organization.placeholder")}
						</Button>
						<div className="horizontal-row">
							<div className="horizontal-text">{t("lib:common.word.or")}</div>
						</div>
						<Button variant="primary" onClick={() => changeFormType(FormType.NEW_ORGANIZATION)}>
							{t("lib:go_security.register.form.actions.create_new_organization")}
						</Button>
					</div>
				) : formType === FormType.NEW_ORGANIZATION ? (
					<>
						{renderBack()}
						<RegisterOrganizationForm baseUrl={baseUrl} />
					</>
				) : formType === FormType.EXTERNAL_ORGANIZATION ? (
					<>
						{renderBack()}
						{externalOrganizations && externalOrganizations.length > 0 ? (
							<div className="select-go-accounts-organizations">
								{externalOrganizations.map((organization: OrganizationSecurityApi, index: number) => {
									return (
										<div key={index} className="external-organization-box">
											<div className="title">
												<Form.Check
													label={organization.name}
													className="form-check"
													type={"radio"}
													id={`${organization.company_id}_${index}`}
													name={`${organization.company_id}_${index}`}
													onChange={() => setCompanyId(organization.company_id)}
													checked={companyId === organization.company_id}
												/>
											</div>
										</div>
									);
								})}
							</div>
						) : (
							<EmptyData title={t("lib:go_security.register.form.external_organization.empty")} />
						)}
					</>
				) : (
					<>{renderBack()}</>
				)}
			</>
		);
	}

	return (
		<>
			<div className="mb-3">
				<h2>
					<span style={{ color: "#1A86E0" }}>
						{t("lib:go_security.register.header.title.create_account")}&nbsp;
					</span>
					{t("lib:go_security.register.header.title.for_free")}
				</h2>
				<RequiredFieldsInformation />
			</div>
			<RegisterUserForm baseUrl={baseUrl} />
		</>
	);
};

export default RegisterForm;
