import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { orderTimeOptions } from "../../services/orderFilters";
import { LiveOrderFilterOption, LiveOrdersFilters } from "../../services/types";
import { inputCustomStyles } from "./utils";

interface Props {
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
}

const LiveOrdersTimeFilter: FC<Props> = ({ innerFilters, setInnerFilters }) => {
	const onChange = (e: LiveOrderFilterOption | null) => {
		if (e) {
			setInnerFilters({
				...innerFilters,
				time: e,
			});
		}
	};
	const { t } = useTranslation();
	return (
		<>
			<Form.Label>{t("modules.live_order.action.show_orders_from.title")}</Form.Label>
			<Select
				styles={inputCustomStyles}
				className="form-select"
				value={innerFilters.time}
				defaultValue={innerFilters.time}
				onChange={onChange}
				getOptionLabel={(opt) => opt.label}
				getOptionValue={(opt) => opt.id}
				options={orderTimeOptions(t)}
			/>
		</>
	);
};

export default LiveOrdersTimeFilter;
