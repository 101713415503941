export enum SaleDeletionType {
	UNSET = "",
	SALE_PERMANENT_DELETION = "SALE_PERMANENT_DELETION",
	NON_PERMANENT_DELETION = "NON_PERMANENT_DELETION",
}

export enum SaleNonPermanentDeletionType {
	UNSET = "",
	SALE_POS_REPORT_DELETION = "SALE_POS_REPORT_DELETION",
	SALE_DATE_RANGE_DELETION = "SALE_DATE_RANGE_DELETION",
}

export interface ClearSaleFormProps {
	SALE_PERMANENT_DELETION: SaleDeletionType.SALE_PERMANENT_DELETION;
	SALE_POS_REPORT_DELETION: SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION;
	SALE_DATE_RANGE_DELETION: SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION;
}
