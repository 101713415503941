import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { ApplicationApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<ApplicationApi>;
}

const MewsPreInstallationForm: FC<Props> = ({ form }) => {
	const {
		register,
		formState: { errors },
	} = form;
	const { t } = useTranslation();

	return (
		<FormInput
			register={register}
			errors={errors}
			name="settings.accesstoken"
			label={t("modules.app.field.access_token.title")}
		/>
	);
};

export default MewsPreInstallationForm;
