import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";
import NumberFormat, { SyntheticInputEvent } from "react-number-format";
import { useCustomValidation } from "../hooks";
import { CustomValidationConfig } from "../services/types";
import { hasErrors, isConstraintARequiredConstraint } from "../services/utils";
import { FormErrorMessage } from "./FormErrorMessage";

interface NumberProps {
	label?: string;
	errors: any;
	name: string;
	suffix?: string;
	onChange?: (newVal?: number) => void;
	onBlur?: (newVal?: number) => void;
	allowNegative?: boolean;
	decimalScale?: number;
	format?: string;
	disabled?: boolean;
	control?: Control<any>;
	defaultValue?: string | number | null;
	placeholder?: string;
	valuePlaceholder?: string;
	help?: string;
	firstInput?: boolean;
	tabIndex?: string;
	"data-testid"?: string;
	allowEmpty?: boolean;
	customValidationConfig?: CustomValidationConfig;
	isPhoneNumber?: boolean;
	maxPermittedAmountOfDigits?: number;
}

const getNumberInputParsedValue = (value: string | number | null | undefined) => {
	if (value === undefined || value === null) return "";
	return value;
};

export const FormNumberInput = (props: NumberProps): JSX.Element => {
	const {
		onChange: onChangeInput,
		onBlur: onBlurInput,
		label,
		suffix,
		errors,
		name,
		control,
		allowNegative,
		format,
		decimalScale,
		defaultValue,
		disabled,
		placeholder,
		valuePlaceholder,
		help,
		tabIndex,
		"data-testid": dataTestId,
		allowEmpty,
		customValidationConfig,
		maxPermittedAmountOfDigits,
	} = props;
	const [currentValue, setCurrentValue] = useState<string | number | undefined | null>(defaultValue);

	const renderInput = (onChange: any, renderProps: any) => {
		setCurrentValue(renderProps.value);

		return (
			<NumberFormat
				{...renderProps}
				value={getNumberInputParsedValue(renderProps.value)}
				data-testid={dataTestId}
				placeholder={placeholderValue}
				disabled={disabled}
				tabIndex={tabIndex}
				format={format ? format : undefined}
				allowNegative={allowNegative ? allowNegative : false}
				decimalScale={decimalScale === undefined ? 2 : decimalScale}
				getInputRef={(el: any) => (el && renderProps.value === null ? (el.value = null) : null)}
				allowedDecimalSeparators={[".", ","]}
				className={classNames("form-control", { "is-invalid": hasErrors(errors, name) })}
				suffix={suffix === "" ? undefined : ` ${suffix}`}
				onBlur={(e: SyntheticInputEvent) => {
					if (onBlurInput) {
						if (allowEmpty) onBlurInput(e.target.value !== "" ? Number(e.target.value) : undefined);
						else onBlurInput(Number(e.target.value) || 0);
					}
				}}
				onValueChange={(values, sourceInfo) => {
					const inputValue = values.value === "" ? null : values.value;
					const newVal = props.isPhoneNumber
						? getNumberInputParsedValue(values.value)
						: inputValue === "."
						? "0."
						: getNumberInputParsedValue(values.floatValue);

					if (sourceInfo.event !== null && sourceInfo.source !== "prop") {
						onChange(newVal !== undefined ? newVal : null);
						if (onChangeInput && newVal) {
							const val = typeof newVal === "number" ? newVal : parseFloat(newVal);
							onChangeInput(val);
						} else if (onChangeInput) {
							onChangeInput(undefined);
						}
					} else if (onChangeInput) {
						onChange(newVal);
					}

					setCurrentValue(newVal);
				}}
				isAllowed={(values) => {
					const { floatValue } = values;
					if (maxPermittedAmountOfDigits && floatValue !== undefined) {
						const floatValueAsString = floatValue.toString();
						let numberIntegerPartAsString = floatValueAsString;

						if (floatValueAsString.includes("."))
							numberIntegerPartAsString = numberIntegerPartAsString.split(".")[0];

						return numberIntegerPartAsString.length <= maxPermittedAmountOfDigits;
					}
					return true;
				}}
			/>
		);
	};

	const isFieldRequired = useMemo(
		() => customValidationConfig?.types.some(({ constraint }) => isConstraintARequiredConstraint(constraint)),
		[customValidationConfig?.types]
	);

	useCustomValidation({ name, value: currentValue, customValidationConfig });

	const placeholderValue = placeholder && props.firstInput && isFieldRequired ? `${placeholder} *` : placeholder;

	return (
		<Form.Group className="form-group" controlId={name}>
			{!props.firstInput && label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
			<Controller
				control={control}
				name={name}
				defaultValue={defaultValue}
				render={({ field: { onChange, ...renderProps } }) =>
					valuePlaceholder ? (
						<div className={`${errors && "is-invalid"} d-flex align-items-center`}>
							{renderInput(onChange, renderProps)}
							{renderProps.value ? (
								<small className={"input-value-placeholder"}>{valuePlaceholder}</small>
							) : null}
						</div>
					) : (
						renderInput(onChange, renderProps)
					)
				}
			/>
			{help && <Form.Text muted>{help}</Form.Text>}
			{props.firstInput && label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
			<FormErrorMessage errors={errors} name={name} />
		</Form.Group>
	);
};

export default FormNumberInput;
