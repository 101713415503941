import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TickSVG } from "../../../../../../../../../../../../images/svg/tick.svg";
import { DeliveryEmployeeApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../../../services/context";
import {
	calculateEmployeeDistance,
	getEmployeeOrders,
	getOrganizationCoords,
	hasEmployeeCoords,
	hasEmployeeOrders,
} from "../../../../../../services/orderDelivery";
import LiveOrderDeliveryEmployeeAvatar from "../../../../components/LiveOrderDeliveryEmployeeAvatar";

interface Props {
	employee: DeliveryEmployeeApi;
	setSelectedEmployeeId: (id: string | undefined) => void;
	selectedEmployeeId: string | undefined;
}

const LiveOrdersAssignDeliveryEmployeeModalOption = ({
	employee,
	selectedEmployeeId,
	setSelectedEmployeeId,
}: Props): JSX.Element => {
	const { orders, organizationAddress } = useContext(OrdersContext);
	const { t } = useTranslation();
	const organizationCoords = getOrganizationCoords(organizationAddress);
	const isSelected = selectedEmployeeId === employee.source.id.toString();

	const toggleOnClick = () => {
		if (selectedEmployeeId === employee.source.id.toString()) {
			setSelectedEmployeeId(undefined);
		} else {
			setSelectedEmployeeId(employee.source.id.toString());
		}
	};

	return (
		<div
			className={`assign-delivery-employee-modal-option ${
				isSelected ? "assign-delivery-employee-modal-option--selected" : ""
			}`}
			onClick={toggleOnClick}
		>
			<LiveOrderDeliveryEmployeeAvatar employee={employee} />
			<div className="user-info text-truncate">
				<div className="d-flex w-100 justify-content-between text-truncate">
					<h5
						className="mb-0 text-primary text-truncate"
						title={employee?.employee?.name || employee.source.name}
					>
						{employee?.employee?.name || employee.source.name}
					</h5>
					{isSelected && <TickSVG className="ms-2 live-orders-green-icon" />}
				</div>
				<div className="d-flex w-100 justify-content-between text-truncate">
					{hasEmployeeOrders(orders, employee) ? (
						<span
							className="text-truncate"
							title={`${t("modules.live_order.field.orders_amount.title")}: ${
								getEmployeeOrders(orders, employee).length
							}`}
						>
							{t("modules.live_order.field.orders_amount.title")}:
							<span className="text-primary text-truncate">{` ${
								getEmployeeOrders(orders, employee).length
							}`}</span>
						</span>
					) : (
						<span
							className="text-truncate without-orders"
							title={t("modules.live_order.field.without_orders.title")}
						>
							{t("modules.live_order.field.without_orders.title")}
						</span>
					)}
					{hasEmployeeCoords(employee) && (
						<span className="text-muted">
							{calculateEmployeeDistance(employee, organizationCoords, t)?.distance}{" "}
							{calculateEmployeeDistance(employee, organizationCoords, t)?.measure}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default LiveOrdersAssignDeliveryEmployeeModalOption;
