import { MutableRefObject } from "react";
import { TFunction } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import FormatDate, { getMonthName } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { getChartType, getReportFilter } from "go-report/reports/services/report-service";
import { apiReport } from "../../services/Api/Report/apiReport";

const getPaidAtRenderValue = (paidAt: string, t: TFunction) => {
	if (paidAt === "empty") return t("modules.transaction.field.no_assigned_date.title");
	return paidAt;
};

export class ReportTransactionsUtils {
	static getGroups = (t: TFunction) => [
		{
			id: "TRANSACTION_TYPE",
			name: t("common.word.type", { ns: "lib" }),
		},
		{
			id: "PAYMENT_OUTFLOW_TYPE",
			name: t("modules.payment_method.field.payment_outflow_type.title"),
		},
		{
			id: "PAID_AT",
			name: t("modules.transaction.field.paid_at.title"),
		},
		{
			id: "PAID_AT_YEAR",
			name: t("components.reports.groups.PAID_AT_YEAR"),
		},
		{
			id: "PAID_AT_MONTH",
			name: t("components.reports.groups.PAID_AT_MONTH"),
		},
		{
			id: "PAID_AT_DAY_OF_MONTH",
			name: t("components.reports.groups.PAID_AT_DAY_OF_MONTH"),
		},
		{
			id: "PAID_AT_HOUR_OF_DAY",
			name: t("components.reports.groups.PAID_AT_HOUR_OF_DAY"),
		},
		{
			id: "PAID_AT_MINUTE_OF_HOUR",
			name: t("components.reports.groups.PAID_AT_MINUTE_OF_HOUR"),
		},
		{
			id: "CREATED_AT",
			name: t("common.word.created_at", { ns: "lib" }),
		},
		{
			id: "CREATED_AT_YEAR",
			name: t("modules.transaction.field.crated_at_year.title"),
		},
		{
			id: "CREATED_AT_MONTH",
			name: t("components.reports.groups.CREATED_AT_MONTH"),
		},
		{
			id: "CREATED_AT_DAY_OF_MONTH",
			name: t("components.reports.groups.CREATED_AT_DAY_OF_MONTH"),
		},
		{
			id: "CREATED_AT_HOUR_OF_DAY",
			name: t("components.reports.groups.CREATED_AT_HOUR_OF_DAY"),
		},
		{
			id: "CREATED_AT_MINUTE_OF_HOUR",
			name: t("components.reports.groups.CREATED_AT_MINUTE_OF_HOUR"),
		},
		{
			id: "TRANSACTION_NUMBER",
			name: t("modules.transaction.field.transaction_number.title"),
		},
		{
			id: "TRANSACTION_CONTEXT_DESCRIPTION",
			name: t("common.word.description", { ns: "lib" }),
		},
		{
			id: "PAYMENT_METHOD",
			name: t("common.word.payment_method"),
		},
		{
			id: "TRANSACTION_STATUS",
			name: t("common.word.status", { ns: "lib" }),
		},
		{
			id: "TRANSACTION_CONTEXT_TYPE",
			name: t("components.reports.groups.TRANSACTION_CONTEXT_TYPE"),
		},
		{
			id: "TRANSACTION_CONTEXT_NUMBER",
			name: t("components.reports.groups.TRANSACTION_CONTEXT_NUMBER"),
		},
	];

	static getGroupsInfo = (t: TFunction) => {
		return {
			PAYMENT_OUTFLOW_TYPE: (groupByValue: Record<string, any>) => {
				let paymentOutflowType = groupByValue.name;
				if (paymentOutflowType === "") return "-";
				if (paymentOutflowType === "RW / WZ") paymentOutflowType = "RW_WZ";
				return t(`enums.payment_methods.payment_outflow_types.${paymentOutflowType}`);
			},
			TRANSACTION_TYPE: (groupByValue: Record<string, any>) => {
				return groupByValue.name === "" ? "" : t(`enums.transactions.context_type.${groupByValue.name}`);
			},
			CREATED_AT: (groupByValue: Record<string, any>) => {
				return groupByValue.name === "empty"
					? t("modules.transaction.field.no_assigned_date.title")
					: FormatDate(new Date(Number(groupByValue.name)), undefined, undefined, {
							hours: "none",
							minutes: "none",
					  });
			},
			TRANSACTION_STATUS: (groupByValue: Record<string, any>) => {
				return t(`enums.common.status.${groupByValue.name}`, { ns: ["lib", "translation"] });
			},
			TRANSACTION_CONTEXT_TYPE: (groupByValue: Record<string, any>) => {
				let transactionContextType = groupByValue.name;

				if (transactionContextType === "INVOICE / ORDER") transactionContextType = "INVOICE_ORDER";
				else if (transactionContextType === "INVOICE / ORDER / REPORT_PAID")
					transactionContextType = "INVOICE_ORDER_REPORT_PAID";
				else if (transactionContextType === "ORDER / REPORT_PAID") transactionContextType = "ORDER_REPORT_PAID";
				else if (transactionContextType === "INVOICE / REPORT_PAID")
					transactionContextType = "INVOICE_REPORT_PAID";

				return transactionContextType === ""
					? ""
					: t(`enums.transactions.context_type.${transactionContextType}`);
			},
			PAID_AT: (groupByValue: Record<string, any>) => {
				if (groupByValue.name === "empty") return t("modules.transaction.field.no_assigned_date.title");
				return FormatDate(new Date(Number(groupByValue.name)), undefined, undefined, {
					hours: "none",
					minutes: "none",
				});
			},
			PAID_AT_YEAR: (groupByValue: Record<string, any>) => getPaidAtRenderValue(groupByValue.name, t),
			PAID_AT_MONTH: (groupByValue: Record<string, any>) => {
				if (groupByValue.name === "empty") return t("modules.transaction.field.no_assigned_date.title");
				return getMonthName(groupByValue.name, t);
			},
			PAID_AT_DAY_OF_MONTH: (groupByValue: Record<string, any>) => getPaidAtRenderValue(groupByValue.name, t),
			PAID_AT_HOUR_OF_DAY: (groupByValue: Record<string, any>) => getPaidAtRenderValue(groupByValue.name, t),
			PAID_AT_MINUTE_OF_HOUR: (groupByValue: Record<string, any>) => getPaidAtRenderValue(groupByValue.name, t),
			CREATED_AT_MONTH: (groupByValue: Record<string, any>) => {
				if (groupByValue.name === "empty") return t("modules.transaction.field.no_assigned_date.title");
				return getMonthName(groupByValue.name, t);
			},
			CREATED_AT_DAY_OF_MONTH: (groupByValue: Record<string, any>) => getPaidAtRenderValue(groupByValue.name, t),
			CREATED_AT_HOUR_OF_DAY: (groupByValue: Record<string, any>) => getPaidAtRenderValue(groupByValue.name, t),
			CREATED_AT_MINUTE_OF_HOUR: (groupByValue: Record<string, any>) =>
				getPaidAtRenderValue(groupByValue.name, t),
			PAYMENT_METHOD: (groupByValue: Record<string, any>) => {
				if (!groupByValue.name || groupByValue.name === "empty") return t("common.word.unpaid_female");
				return groupByValue.name;
			},
		};
	};

	static getChartInfo = (t: TFunction) => {
		return {
			title: t("modules.transaction.field.chart_title.title"),
			lineTitle: t("common.word.total_money"),
			aggregate: "total_money",
			type: getChartType(),
			parseGroup: (group: string) => {
				if (group === "HOUR_OF_DAY") return "CREATED_AT_HOUR_OF_DAY";
				if (group === "DAY_OF_MONTH") return "CREATED_AT_DAY_OF_MONTH";
				if (group === "DATE") return "CREATED_AT_DATE";
				if (group === "DAY_OF_WEEK") return "CREATED_AT_DAY_OF_WEEK";
				return group;
			},
		};
	};

	static getColumns = (t: TFunction) => [
		{
			id: "total_money",
			name: t("common.word.total_money"),
			render: (item: MoneyApi) => FormatMoney(item),
		},
		{
			id: "transaction_count",
			name: t("modules.transaction.field.transaction_count.title"),
		},
	];

	static getFilters = (t: TFunction, filtersParams: MutableRefObject<string>, type: string) => [
		{
			id: "transaction_type",
			name: t("common.word.type", { ns: "lib" }),
			type: "list",
			listOptions: {
				PAY_IN: t("enums.transactions.context_type.PAY_IN"),
				PAY_OUT: t("enums.transactions.context_type.PAY_OUT"),
				CANCELLATION: t("enums.transactions.context_type.CANCELLATION"),
				CHANGE: t("enums.transactions.context_type.CHANGE"),
			},
		},
		{
			id: "paid_at",
			name: t("modules.transaction.field.paid_at.title"),
			type: "date",
		},
		{
			id: "date_range",
			name: t("common.word.created_at", { ns: "lib" }),
			type: "date",
			isRemovable: false,
		},
		{
			id: "transaction_number",
			name: t("modules.transaction.field.transaction_number.title"),
			type: "label_search_select",
			source: {
				request: (queryString?: string, params?: Record<string, any>) =>
					getReportFilter(
						apiReport.getReportFilter,
						"TRANSACTION_NUMBER",
						queryString,
						filtersParams.current,
						type,
						undefined,
						params
					),
			},
		},
		{
			id: "transaction_context_description",
			name: t("common.word.description", { ns: "lib" }),
			type: "label_search_select",
			source: {
				request: (queryString?: string, params?: Record<string, any>) =>
					getReportFilter(
						apiReport.getReportFilter,
						"TRANSACTION_CONTEXT_DESCRIPTION",
						queryString,
						filtersParams.current,
						type,
						undefined,
						params
					),
			},
		},
		{
			id: "payment_method",
			name: t("common.word.payment_method"),
			type: "label_search_select",
			source: {
				request: (queryString?: string, params?: Record<string, any>) =>
					getReportFilter(
						apiReport.getReportFilter,
						"PAYMENT_METHOD",
						queryString,
						filtersParams.current,
						type,
						undefined,
						params
					),
			},
			conditionCustomLabelConfig: [{ condition: "u", label: t("common.word.unpaid_female") }],
		},
		{
			id: "payment_outflow_type",
			name: t("modules.payment_method.field.payment_outflow_type.title"),
			type: "list",
			listOptions: {
				RW: t("enums.payment_methods.payment_outflow_types.RW"),
				WZ: t("enums.payment_methods.payment_outflow_types.WZ"),
			},
		},
		{
			id: "transaction_status",
			name: t("common.word.status", { ns: "lib" }),
			type: "list",
			listOptions: {
				NEW: t("enums.common.status.NEW"),
				SUCCESS: t("enums.common.status.SUCCESS", { ns: "lib" }),
				REMOVED: t("enums.common.status.REMOVED"),
				ERROR: t("enums.common.status.ERROR", { ns: "lib" }),
			},
		},
		{
			id: "transaction_context_type",
			name: t("modules.transaction.field.transaction_context_type.title"),
			type: "list",
			listOptions: {
				ORDER: t("enums.transactions.context_type.ORDER"),
				INVOICE: t("enums.transactions.context_type.INVOICE"),
				REPORT_PAID: t("enums.transactions.context_type.REPORT_PAID"),
			},
		},
		{
			id: "transaction_context_number",
			name: t("modules.transaction.field.transaction_context_number.title"),
			type: "label_search_select",
			source: {
				request: (queryString?: string, params?: Record<string, any>) =>
					getReportFilter(
						apiReport.getReportFilter,
						"TRANSACTION_CONTEXT_NUMBER",
						queryString,
						filtersParams.current,
						type,
						undefined,
						params
					),
			},
		},
	];
}
