import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FormProps } from "react-bootstrap/Form";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "go-core/components/useWindowSize";
import RouteHistory from "go-core/utils/routeHistory";
import { ButtonLoading } from "./ButtonLoading";
import { FormErrorMessageAlert } from "./FormErrorMessageAlert";

interface Props extends FormProps {
	isDirty?: boolean;
	isSubmitting?: boolean;
	errors?: any;
	loading?: boolean;
	buttonSubmitCustom?: JSX.Element;
	backPath?: string;
	formState?: FormState<any>;
}

export const FormDirty: BsPrefixRefForwardingComponent<"form", Props> = (props: Props): JSX.Element => {
	const { isDirty, loading, formState, backPath, ...newProps } = props;
	const isMobile = useWindowSize().isMobile;
	const isLoading = !!loading;
	const { t } = useTranslation();
	const history = useHistory();
	const formRef = useRef<HTMLDivElement>(null);
	const debounceRef = useRef<number>(0);
	const isInViewPort = () => {
		if (!document.getElementsByClassName("content")[0]) {
			return true;
		}

		const nodeClass = isMobile ? "page-container" : "content";
		const elementNodeClass = document.getElementsByClassName(`${nodeClass}`)[0];
		if (!elementNodeClass) return true;

		const headerHeight = document?.querySelector(".content-header")?.clientHeight || 0;
		const footerHeight = document?.querySelector(".form-footer")?.clientHeight || 0;

		return (formRef?.current?.clientHeight || 0) + footerHeight <= elementNodeClass.clientHeight - headerHeight;
	};
	const [inViewPort, setInViewPort] = useState(isInViewPort());
	const goBack = () => {
		if (backPath && backPath === "RELOAD") {
			RouteHistory.reload(history);
			return;
		}
		RouteHistory.goBack(history, backPath);
	};

	useEffect(() => {
		debounceRef.current = window.setTimeout(() => {
			setInViewPort(isInViewPort());
		}, 10);

		return () => window.clearTimeout(debounceRef.current);
	}, [window.innerHeight]);

	const isFormValueDirty =
		isDirty !== undefined ? isDirty : formState?.isDirty && Object.keys(formState?.dirtyFields).length > 0;
	return (
		<Form {...newProps} ref={formRef}>
			<Prompt message={t("lib:go_form.form_dirty.message")} when={isFormValueDirty && !formState?.isSubmitting} />
			<FormErrorMessageAlert errors={props.errors} name="all" />
			{newProps.children}
			{props.loading !== undefined && (
				<>
					{inViewPort ? (
						<Form.Group className="form-group">
							{props.buttonSubmitCustom ? (
								props.buttonSubmitCustom
							) : (
								<ButtonLoading
									loading={isLoading}
									variant="primary"
									type="submit"
									data-testid="form-save-button"
								>
									{t("lib:common.action.save")}
								</ButtonLoading>
							)}
							{isFormValueDirty && (
								<Button
									variant="light"
									type="button"
									onClick={() => goBack()}
									data-testid="form-cancel-button"
								>
									{t("lib:common.action.cancel")}
								</Button>
							)}
						</Form.Group>
					) : (
						<div className="form-footer">
							<Form.Group className="form-group">
								{props.buttonSubmitCustom ? (
									props.buttonSubmitCustom
								) : (
									<ButtonLoading
										loading={isLoading}
										variant="primary"
										type="submit"
										data-testid="form-save-button"
									>
										{t("lib:common.action.save")}
									</ButtonLoading>
								)}
								{isFormValueDirty && (
									<Button
										variant="light"
										type="button"
										onClick={() => goBack()}
										data-testid="form-cancel-button"
									>
										{t("lib:common.action.cancel")}
									</Button>
								)}
							</Form.Group>
						</div>
					)}
				</>
			)}
		</Form>
	);
};
