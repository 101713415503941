import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReportLastUpdateInfo } from "../../../../../../../components/Common/ReportLastUpdateInfo/ReportLastUpdateInfo";

interface Props {
	setShowRebuildReportModal: (value: boolean) => void;
	setShowDepartmentReportModal: (value: boolean) => void;
	reportLastUpdatedAt: string | undefined;
	isSupport?: string;
	isAdmin: boolean;
}

const ReportsMobileActions = ({
	setShowRebuildReportModal,
	setShowDepartmentReportModal,
	reportLastUpdatedAt,
	isAdmin,
	isSupport,
}: Props) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const location = useLocation();

	const actions: MobileActionProps[] = [
		{
			title: t("modules.report.action.rebuild.title"),
			action: () => setShowRebuildReportModal(true),
			hide: !(isSupport || isAdmin),
		},
		{
			title: t("modules.report.field.department_report.title"),
			action: () => setShowDepartmentReportModal(true),
		},
		{
			title: t("modules.report.field.combo_report.title"),
			path: `/${organization.id}/reports/combo`,
			hide: location.pathname.includes("combo"),
		},
	];

	return (
		<MobileActions actions={actions}>
			<ReportLastUpdateInfo lastUpdateDate={reportLastUpdatedAt} />
		</MobileActions>
	);
};

export default ReportsMobileActions;
