import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AlertRedSVG } from "../../../../images/svg/dashboard/alert-red.svg";
import { ReactComponent as AlertYellowSVG } from "../../../../images/svg/dashboard/alert-yellow.svg";
import { ReactComponent as PreviewArrowSVG } from "../../../../images/svg/preview-arrow.svg";

interface Props {
	status: string;
	title: string;
	link: string | undefined;
	isModalItem: boolean;
}

const DashboardAlertItem: FC<Props> = ({ status, title, link, isModalItem }) => {
	const drawAlertIcon = () => {
		if (status === "WARNING") return <AlertYellowSVG className="me-1 alert-svg" />;
		if (status === "DANGER") return <AlertRedSVG className="me-1 alert-svg" />;
	};

	return (
		<div>
			<div className={`d-flex ${isModalItem ? "dashboard-alert-modal-item" : "dashboard-alert-item"}`}>
				{drawAlertIcon()}
				{link ? (
					<Link to={link} title={title} rel="noreferrer" target="_blank">
						{title}
					</Link>
				) : (
					<h5 className="mb-0" title={title}>
						{title}
					</h5>
				)}
				<PreviewArrowSVG className="text-end my-auto ms-1" />
			</div>
			<hr />
		</div>
	);
};

export default DashboardAlertItem;
