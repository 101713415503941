import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import { OrganizationLogsReportOrderSyncIndexPage } from "./pages/Index";

export const OrganizationLogsReportOrderSyncPage = (props: RouteComponentProps): JSX.Element => {
	return (
		<Switch>
			<Route path={props.match.url} component={OrganizationLogsReportOrderSyncIndexPage} />
			<Page404 />
		</Switch>
	);
};
