import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { OrganizationsContextProvier } from "go-app/utils/OrganizationsContext";
import { BrowserTabProvider } from "go-core/components/BrowserTab/BrowserTab";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import AppIndexSecurityComponent from "go-security/components/App";
import { useAuth } from "go-security/services/auth";
import ForcingPhoneNumberModal from "../../components/ForcingPhoneNumberModal/ForcingPhoneNumberModal";
import Navigation from "../../components/Navigation";
import { api } from "../../services/Api/api";
import { ConfigContext, ConfigService } from "../../services/Config/context";
import { ConfigApi } from "../../services/Config/types";
import { getApiURL } from "../../utils/envutil";
import EnterApplicationPage from "./pages/EnterApplication";
import OrganizationPage from "./pages/Organization";
import UserPage from "./pages/User";

const AppPage: React.FC<RouteComponentProps> = ({ match }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | undefined>(undefined);
	const auth = useAuth(getApiURL());
	const [config, setConfig] = useState<ConfigApi>();

	useEffect(() => {
		init();
	}, []);

	const fetchConfig = async () => {
		try {
			const config = await api.getConfig();
			setConfig(config);
		} catch (err) {
			setError(t("lib:constraints.unknown_error"));
		}
	};

	const checkLogin = async () => {
		try {
			await auth.checkLogin();
		} catch (err) {
			setError(t("lib:constraints.unknown_error"));
		}
	};

	const init = async () => {
		await Promise.all([checkLogin(), fetchConfig()]);
		setLoading(false);
	};

	if (loading) {
		return <LoadingContainer />;
	}

	if (error) {
		return (
			<EmptyList
				title={error}
				actions={[
					{
						name: t("lib:common.action.refresh"),
						click: () => {
							window.location.reload();
						},
					},
				]}
			/>
		);
	}

	if (config === undefined) {
		return (
			<EmptyList
				title={t("modules.app_page.field.empty_list.title")}
				actions={[
					{
						name: t("lib:common.action.refresh"),
						click: () => {
							window.location.reload();
						},
					},
				]}
			/>
		);
	}

	const configService = new ConfigService(config);
	const renderNavigation = () => {
		return <Navigation />;
	};

	return (
		<OrganizationsContextProvier>
			<BrowserTabProvider>
				<ConfigContext.Provider value={configService}>
					<AppIndexSecurityComponent
						navigation={renderNavigation}
						goaccounts_url={configService.getGoAccountsUrl()}
						baseUrl={getApiURL()}
						roles={["ROLE_ADMIN", "ROLE_SUPPORT"]}
					>
						<Switch>
							<Route
								path={[
									"/profile",
									"/dashboard",
									"/group_reports",
									"/combo_reports",
									"/invoices",
									"/segments",
									"/migration",
									"/admin",
									"/jobs",
								]}
								component={UserPage}
							/>
							<Route
								path={[`${match.url}enter_application`, `${match.url}enter_organization`]}
								component={EnterApplicationPage}
							/>
							<Route path={`${match.url}:organizationId`} component={OrganizationPage} />
							<Route path={"/"} component={UserPage} />
						</Switch>
						<ForcingPhoneNumberModal />
					</AppIndexSecurityComponent>
				</ConfigContext.Provider>
			</BrowserTabProvider>
		</OrganizationsContextProvier>
	);
};

export default AppPage;
