import React from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { delay } from "go-core";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDateRangePicker } from "go-form/components/FormDateRangePicker";
import { ReactComponent as RemoveSVG } from "../../../images/svg/remove.svg";
import { AvailabilityApi, AvailabilityDateItem } from "../../../services/types";

interface Props {
	form: UseFormReturn<AvailabilityApi>;
	placeholder?: string;
}

export const parseDatesToRange = (availability: AvailabilityApi): AvailabilityDateItem[] | undefined => {
	return availability.dates?.map((x: AvailabilityDateItem) => {
		return {
			id: x.id,
			date_range: `${x.date_from?.replace("T", " ")} - ${x.date_to?.replace("T", " ")}`,
		};
	});
};

export const DatesTableForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
	} = props.form;
	const { fields, remove, append } = useFieldArray({
		control,
		name: "dates",
		keyName: "key",
	});
	const isMobile = useWindowSize().isMobile;

	const appendDate = async () => {
		append({ date_range: null });
		await delay(200);
		document.getElementById(`dropdown-daterange-dates.${fields.length}.date_range`)?.click();
	};

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className={"table table-form"}>
					<thead>
						<tr>
							<th className="action" />
							<th>{t("lib:common.word.date")}</th>
							<th className="action" />
						</tr>
					</thead>
					<tbody>
						{fields.map((item, index) => {
							return (
								<tr key={fields[index].key}>
									<td className="action" />
									<td>
										<FormDateRangePicker
											errors={errors}
											name={`dates.${index}.date_range`}
											placeholder={props.placeholder}
											control={control}
										/>
									</td>

									<td className="action" style={{ verticalAlign: "middle" }}>
										<RemoveSVG className="icon" onClick={() => remove(index)} />
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<Button variant="add" type="button" onClick={() => appendDate()}>{`+ ${t("common.action.add", {
				ns: "lib",
			})}`}</Button>
		</>
	);
};
