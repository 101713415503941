import React, { useCallback, useMemo } from "react";
import { TooltipItem } from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { ReportApi } from "go-report/core/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { generateChartData } from "../../services/charts/chartService";
import { AggregateOption, ChartDataRangeType } from "../../services/charts/types";
import useDashboardContext from "../../services/useDashboardContext";
import { chartDataSetConfig, defaultChartConfig } from "../../utils/chartConfigUtils";

interface Props {
	data: ReportApi;
	aggregate: AggregateOption;
	chartDataRangeType: ChartDataRangeType;
	selectedFilters: ListSelectedFilter[];
}

const DashboardReportSalesAdvancedChart = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const { data, aggregate, chartDataRangeType, selectedFilters } = props;
	const { state } = useDashboardContext();
	const dataSetConfig = {
		...chartDataSetConfig,
		fill: true,
		pointBorderColor: "#0085E9",
		pointBackgroundColor: "#0085E9",
		borderColor: "#0085E9",
		backgroundColor: "#C1E2FD",
	};
	const chartData = useCallback(() => {
		return generateChartData(data, chartDataRangeType, aggregate, selectedFilters, dataSetConfig);
	}, [data.reports, chartDataRangeType, aggregate]);

	const chartConfig = {
		...defaultChartConfig,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (ctx: TooltipItem<"line">) => {
						aggregate.includes("money") && (ctx.formattedValue = `${ctx.formattedValue} ${currency}`);
						return ctx.formattedValue;
					},
				},
			},
		},
	};
	const renderChartLines = useMemo(() => {
		return <Line data={chartData} options={chartConfig} />;
	}, [data.reports, aggregate, state?.data]);

	return <div className={"chart"}>{renderChartLines}</div>;
};
export default DashboardReportSalesAdvancedChart;
