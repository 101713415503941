import { ConfigReportComboApi } from "go-report-combo/services/types";

export const REPORT_test_custom_fields = {
	id: "REPORT_test_custom_fields",
	name: "Custom fields test",
	data: [
		{
			id: "PRODUCT",
			resource: "BILL",
			filter: `type=order_itemçorganization_id|e={organization_ids__ORGANIZATION}`,
			groups: ["NONE", "PRODUCT_CATEGORY", `{sales_group__GROUP}`],
			columns: [
				{
					id: "monthly",
					filter: "date_range|bt=previous_12_months",
				},
			],
		},
	],
	rows: [
		{
			id: "SALES_CF",
			name: "CF",
			data_id: "PRODUCT",
			group: "{sales_group__GROUP}",
			aggregate: "total_money",
		},
	],
	columns: [
		{
			id: "monthly",
			data_column_id: "monthly",
			name: "Sprzedaż",
		},
	],
} as ConfigReportComboApi;
