import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { TagApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	tag: TagApi;
}

const TagForm = (props: Props): JSX.Element => {
	const form = useForm<TagApi>({
		defaultValues: { ...props.tag, priority: props.tag.priority?.toString() },
	});
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const {
		control,
		register,
		setError,
		formState: { errors },
		reset,
		formState,
		handleSubmit,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const onSubmit = handleSubmit(async (data: TagApi) => {
		setLoading(true);
		try {
			if (data.id) {
				const res = await api.organization().updateTag(data);
				setLoading(false);
				reset(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createTag(data);
				history.push(`/${organization.id}/settings/tags/${res.id}`);
				setLoading(false);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const resourceTypes = [
		{
			label: t("enums.tags.resource_types.MENU_IMAGE"),
			value: "MENU_IMAGE",
		},
	];

	const priorityOptions = [
		{
			label: t("modules.tag.field.priority_0.title"),
			value: "0",
		},
		{
			label: t("modules.tag.field.priority_1.title"),
			value: "1",
		},
		{
			label: t("modules.tag.field.priority_2.title"),
			value: "2",
		},
	];

	return (
		<FormDirty formState={formState} loading={loading} onSubmit={onSubmit}>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
				<FormSelectGroup
					label={t("modules.tag.field.priority.title")}
					control={control}
					options={priorityOptions}
					name="priority"
					errors={errors}
					data-testid="priority"
				/>
				<FormSelectGroup
					isMulti={true}
					label={t("modules.tag.field.resource_type.title")}
					name="resource_types"
					errors={errors}
					loadOptions={() => resourceTypes}
					getOptionLabel={(option) =>
						option.label ? option.label : t(`enums.tags.resource_types.${option}`)
					}
					getOptionValue={(option) => (option.value ? option.value : option)}
					defaultValue={props.tag.resource_types}
					control={control}
					data-testid="resource_types"
				/>
			</fieldset>
		</FormDirty>
	);
};
export default TagForm;
