import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationMenuMenusIndexPage from "./Index/index";

const OrganizationMenuMenusPage: FC<RouteComponentProps> = (props) => {
	return (
		<>
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/`} component={OrganizationMenuMenusIndexPage} />
				</Switch>
			</div>
		</>
	);
};
export default OrganizationMenuMenusPage;
