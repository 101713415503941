import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { apiSecurity } from "go-security/services/Api/api";
import { OrganizationRoleApi } from "go-security/services/Api/typesSecurity";
import SecurityPermissionsTableComponent from "./PermissionsTable";
import SecurityRoleModalFormComponent from "./RoleModalForm";
import { Permission, PermissionTab, PermissionTabConfig } from "./service/types";

interface Props {
	permissionTabConfigs: PermissionTabConfig[];
}

const SecurityPermissionComponent = ({ permissionTabConfigs }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [permissions, setPermissions] = useState<string[]>([]);
	const [roles, setRoles] = useState<OrganizationRoleApi[]>([]);
	const [loading, setLoading] = useState(true);
	const [isDirty, setIsDirty] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const [modal, setModal] = useState(false);
	const [selectedRole, setSelectedRole] = useState<OrganizationRoleApi | undefined>(undefined);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		Promise.all([
			apiSecurity.organization().getPermissions(),
			apiSecurity.organization().getRoles({ include: "permissions", size: 100 }),
		])
			.then(([permissions, roles]) => {
				setPermissions(permissions.data.data);
				setRoles(roles.data.data);
				setLoading(false);
				handleChangeTabTitle(t("lib:go_component.permission.header.title"));
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	const getParsedPermissionsForTab = (
		permissionTabConfig: PermissionTabConfig,
		permissions: string[]
	): Permission[] => {
		return permissions.map((permission) => {
			const label = t(`${permissionTabConfig.permissionLabelTranslationKey}.${permission}`).includes(
				permissionTabConfig.permissionLabelTranslationKey
			)
				? ""
				: t(`${permissionTabConfig.permissionLabelTranslationKey}.${permission}`);
			const value = t(`${permissionTabConfig.permissionValueTranslationKey}.${permission}`);
			return {
				value,
				label,
				id: permission,
			};
		});
	};

	const getParsedPermissionTabs = (): PermissionTab[] => {
		if (permissionTabConfigs.length === 0) return [];
		if (permissionTabConfigs.length === 1) {
			const { title, description, id } = permissionTabConfigs[0];
			return [
				{
					id,
					title,
					description,
					permissions: getParsedPermissionsForTab(permissionTabConfigs[0], permissions),
				},
			];
		}
		return permissionTabConfigs.map((permissionTabConfig) => {
			const permissionsForTab = permissionTabConfig?.getPermissions
				? permissionTabConfig.getPermissions(permissions)
				: permissions.filter((permission) => permission.includes(permissionTabConfig.id.toUpperCase()));
			const { title, description, id } = permissionTabConfig;
			return {
				id,
				title,
				description,
				permissions: getParsedPermissionsForTab(permissionTabConfig, permissionsForTab),
			};
		});
	};

	const handleSave = async (data: any) => {
		setFormLoading(true);
		try {
			await apiSecurity.organization().savePermissions(data);
			setIsDirty(false);
			setFormLoading(false);
			addSuccessFlash(t("lib:common.flash.saved"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setFormLoading(false);
	};

	const onChange = () => {
		setIsDirty(true);
	};

	const showModalRole = (role: OrganizationRoleApi) => {
		setSelectedRole(role);
		setModal(true);
	};

	const handleSaveRole = (organizationRole: OrganizationRoleApi) => {
		const permissions = roles.find((f) => f.uid === organizationRole.uid)?.permissions;
		if (selectedRole?.uid) {
			setRoles([
				...roles.map((f) =>
					f.uid === organizationRole.uid
						? { ...organizationRole, permissions: permissions ? permissions : [] }
						: f
				),
			]);
		} else {
			setRoles(
				[...roles, { ...organizationRole, permissions: permissions ? permissions : [] }].sort((a, b) =>
					a.name.localeCompare(b.name)
				)
			);
		}
		setSelectedRole(undefined);
		setModal(false);
	};

	const handleRemoveRole = (role: OrganizationRoleApi) => {
		setSelectedRole(undefined);
		setRoles([...roles.filter((f) => f.uid !== role.uid)]);
		setModal(false);
	};
	const newRole = () => {
		setSelectedRole(undefined);
		setModal(true);
	};

	return (
		<>
			{!isMobile && (
				<div className="content-header">
					<h1>{t("lib:go_component.permission.header.title")}</h1>
				</div>
			)}
			{loading ? (
				<LoadingContainer />
			) : (
				<SecurityPermissionsTableComponent
					formLoading={formLoading}
					onChange={onChange}
					isDirty={isDirty}
					permissionTabs={getParsedPermissionTabs()}
					roles={roles}
					handleSave={handleSave}
					handleUpdateRole={showModalRole}
					handleCreateRole={newRole}
				/>
			)}
			{modal && (
				<SecurityRoleModalFormComponent
					handleRemove={handleRemoveRole}
					role={selectedRole !== undefined ? selectedRole : ({} as OrganizationRoleApi)}
					onHide={() => setModal(false)}
					handleSave={handleSaveRole}
				/>
			)}
		</>
	);
};
export default SecurityPermissionComponent;
