import React, { FC, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";

interface Props {
	loading: boolean;
	handleClearVenue: (password: string) => void;
}

const ClearVenue: FC<Props> = ({ loading, handleClearVenue }) => {
	const { t } = useTranslation();
	const [password, setPassword] = useState("");
	return (
		<>
			<Alert variant="warning mt-2 mb-2">{t("modules.clear_data.field.permanent_clear_data_info.title")}</Alert>
			<Form.Group className="form-group">
				<Form.Label>{t("common.word.password", { ns: "lib" })}</Form.Label>
				<Form.Control type={"password"} onChange={(event) => setPassword(event.target.value)} />
			</Form.Group>

			<ButtonLoading loading={loading} variant="primary" onClick={() => handleClearVenue(password)}>
				{t("modules.clear_data.field.clear_all_data.title")}
			</ButtonLoading>
		</>
	);
};
export default ClearVenue;
