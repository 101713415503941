import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { WebhookContext } from "./services/context";
import { WebhookApi } from "./services/types";

interface Props {
	webhook: WebhookApi;
	onSuccess: (webhook: WebhookApi) => void;
}

const WebhookForm = (props: Props): JSX.Element => {
	const form = useForm<WebhookApi>({
		criteriaMode: "all",
		defaultValues: props.webhook,
	});
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const {
		register,
		setError,
		handleSubmit,
		formState,
		control,
		formState: { errors },
		reset,
	} = form;
	const { addSuccessFlash, addFlash } = useFlash();
	const webhookService = React.useContext(WebhookContext);

	const fetchResourceTypes = async (search: string, options?: Record<string, any>) => {
		const res = await webhookService.api().getResourceTypeOptions(search, options);
		return res
			.map((item) => ({
				value: item,
				label: t(`${webhookService._resourceTypeTranslationPrefix}.${item}`),
			}))
			.filter((item) => item.label.toLowerCase().includes(search?.toLowerCase()));
	};

	const onSubmit = handleSubmit(async (data: WebhookApi) => {
		data.id = props.webhook.id;
		setLoading(true);
		try {
			if (!data.id) {
				const res = await webhookService.api().create(data);
				addSuccessFlash(t("lib:common.flash.saved"));
				props.onSuccess(res.data.data);
			} else {
				const res = await webhookService.api().update(data);
				addSuccessFlash(t("lib:common.flash.saved"));
				reset(res.data.data);
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<FormDirty onSubmit={onSubmit} formState={formState} loading={loading}>
			<fieldset className={"form-group"}>
				<h5>{t("lib:common.word.basic_data")}</h5>
				<FormInput label={t("lib:common.word.name")} name="name" errors={errors} register={register} />
				<FormInput
					label={t("lib:go_component.webhook.form.url.label")}
					name="url"
					errors={errors}
					register={register}
				/>
				<FormSelectGroup
					label={t("lib:go_component.webhook.form.resource_type.label")}
					name="resource_type"
					errors={errors}
					loadOptions={fetchResourceTypes}
					defaultValue={
						props.webhook.resource_type && {
							label: t(`${webhookService._resourceTypeTranslationPrefix}.${props.webhook.resource_type}`),
							value: props.webhook.resource_type,
						}
					}
					control={control}
					data-testid="resource_type"
				/>
			</fieldset>
		</FormDirty>
	);
};
export default WebhookForm;
