import React, { FC } from "react";

interface BlankLayoutProps {
	children: React.ReactNode;
}

const BlankLayout: FC<BlankLayoutProps> = (props) => {
	return <>{props.children}</>;
};

export default BlankLayout;
