import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmptyList from "go-core/components/EmptyList";
import { formatDateTimeToString } from "go-core/components/Formatters/FormatDate";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import EntityStatus from "go-list/core/components/Table/components/EntityStatus";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as CashSVG } from "../../../../images/svg/dashboard/cash.svg";
import { ReactComponent as ChairSVG } from "../../../../images/svg/dashboard/chair.svg";
import { ReactComponent as PeopleSVG } from "../../../../images/svg/dashboard/people.svg";
import { ReactComponent as ReceiptSVG } from "../../../../images/svg/dashboard/receipt.svg";
import useDashboardContext from "../../services/useDashboardContext";
import DashboardLiveItem from "./DashboardLiveItem";

interface Props {
	loading: boolean;
}

const DashboardLive = ({ loading }: Props): JSX.Element => {
	const { state } = useDashboardContext();
	const { current, comparison } = state?.data?.reports || {};
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const renderLiveReports = useMemo(() => {
		const dateNow = new Date();
		const date = new Date();
		date.setHours(date.getHours() - 1);
		const lastHourClosedAtFilter = `closed_at|bt=${formatDateTimeToString(
			date
		)}${FILTER_VALUE_SEPARATOR}${formatDateTimeToString(dateNow)}`;
		const infiniteHourFilter = `date_range|bt=${formatDateTimeToString(
			new Date(2015, 0, 1, 0, 0, 0)
		)}${FILTER_VALUE_SEPARATOR}${formatDateTimeToString(new Date())}`;
		const openOrdersFilter = btoa(
			unescape(
				encodeURIComponent(
					`${NEW_WAY_TO_ENCODING_FILTER_SIGN}order_status|e=OPENED${FILTER_SEPARATOR}${infiniteHourFilter}`
				)
			)
		);
		const closedOrdersFilter = btoa(
			unescape(
				encodeURIComponent(
					`${NEW_WAY_TO_ENCODING_FILTER_SIGN}order_status|e=CLOSED${FILTER_SEPARATOR}${infiniteHourFilter}${FILTER_SEPARATOR}${lastHourClosedAtFilter}`
				)
			)
		);

		if (!current || !comparison) return <EmptyList />;

		return (
			<>
				{current.guest_count !== undefined && comparison.guest_count !== undefined && (
					<DashboardLiveItem
						title={t("modules.dashboard.field.guest_count.title")}
						currentValue={current.guest_count}
						icon={<ChairSVG />}
						comparedValue={comparison.guest_count}
					/>
				)}
				{current.employee_count !== undefined && comparison.employee_count !== undefined && (
					<DashboardLiveItem
						title={t("modules.dashboard.field.employee_count.title")}
						currentValue={current.employee_count}
						icon={<PeopleSVG />}
						comparedValue={comparison.employee_count}
						link={`/${organization.id}/employees/online`}
					/>
				)}
				{current.order_count !== undefined && comparison.order_count !== undefined && (
					<DashboardLiveItem
						title={t("modules.dashboard.field.order_count.title")}
						currentValue={current.order_count}
						icon={<ReceiptSVG />}
						comparedValue={comparison.order_count}
						link={`/${organization.id}/reports/orders?f=${closedOrdersFilter}`}
					/>
				)}
				{current.average_order_amount !== undefined && comparison.average_order_amount !== undefined && (
					<DashboardLiveItem
						title={t("modules.dashboard.field.average_money.title")}
						currentValue={current.average_order_amount}
						icon={<CashSVG />}
						comparedValue={comparison.average_order_amount}
						link={`/${organization.id}/reports/orders?f=${closedOrdersFilter}`}
					/>
				)}
				{current.average_per_guest_order_amount !== undefined &&
					comparison.average_per_guest_order_amount !== undefined && (
						<DashboardLiveItem
							title={t("modules.dashboard.field.extended.average_per_guest_money.title")}
							currentValue={current.average_per_guest_order_amount}
							icon={<CashSVG />}
							comparedValue={comparison.average_per_guest_order_amount}
							link={`/${organization.id}/reports/guests?f=${closedOrdersFilter}`}
						/>
					)}
				{current.open_order_count !== undefined && comparison.open_order_count !== undefined && (
					<DashboardLiveItem
						title={t("modules.dashboard.field.open_order_count.title")}
						currentValue={current.open_order_count}
						icon={<ReceiptSVG />}
						comparedValue={comparison.open_order_count}
						link={`/${organization.id}/reports/orders?f=${openOrdersFilter}`}
					/>
				)}
				{current.open_order_amount !== undefined && comparison.open_order_amount !== undefined && (
					<DashboardLiveItem
						title={t("modules.dashboard.field.open_order_amount.title")}
						currentValue={current.open_order_amount}
						icon={<CashSVG />}
						comparedValue={comparison.open_order_amount}
						link={`/${organization.id}/reports/orders?f=${openOrdersFilter}`}
					/>
				)}
			</>
		);
	}, [state?.data?.reports]);

	if (loading || !current || !comparison) return <></>;

	return (
		<Card className="dashboard-live-card mb-2">
			<Card.Body>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<h5 className={"mb-0"}>
						{t("modules.dashboard.field.dashboard_live.title")}
						<EntityStatus status="ENABLED" />
					</h5>
					{/* <div className='dashboard-live-card-actions'>
                        <SettingsDropdownSVG/>
                    </div> */}
				</div>
				{renderLiveReports}
			</Card.Body>
		</Card>
	);
};
export default DashboardLive;
