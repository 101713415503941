import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { parseApplicationSettings, parseApplicationSettingsValues } from "../../../utils/utils";
import { AppState } from "../AppModal";

interface Props {
	isShown: boolean;
	onHide: () => void;
	appState: AppState;
}

const WoltDriveInstallModal: FC<Props> = ({ isShown, onHide, appState }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings || {}),
			},
		},
	});
	const {
		formState,
		handleSubmit,
		register,
		formState: { errors },
		setError,
	} = form;
	const [loading, setLoading] = useState<boolean>(false);
	const { addSuccessFlash, addFlash } = useFlash();
	const history = useHistory();
	const organization = useSelector(selectOrganization);

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};

		try {
			if (appState.app.id) {
				await api.organization().updateApp(newData, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				onHide();
			} else {
				const res = await api.organization().installApps("WOLT_DRIVE", newData, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				history.push(`/${organization.id}/apps/active/${res.id}`);
			}
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal onHide={onHide} show={isShown}>
			<FormDirty formState={formState} key="install-hotellion-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t("modules.app.field.install.title")} {t("enums.apps.providers.WOLT_DRIVE")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						name="settings.venue_id"
						errors={errors}
						register={register}
						label={t("modules.app.field.venue_id.title")}
					/>
					<FormInput
						name="settings.merchant_id"
						errors={errors}
						register={register}
						label={t("modules.app.field.merchant_id.title")}
					/>
					<FormInput
						name="settings.api_key"
						errors={errors}
						register={register}
						label={t("modules.app.field.api_key.title")}
					/>
					<FormInput
						name="settings.support_url"
						errors={errors}
						register={register}
						label={t("modules.app.field.support_url.title")}
					/>
					<FormInput
						name="settings.support_email"
						errors={errors}
						register={register}
						label={t("modules.app.field.support_email.title")}
					/>
					<FormInput
						name="settings.support_phone_number"
						errors={errors}
						register={register}
						label={t("modules.app.field.support_phone_number.title")}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.install", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default WoltDriveInstallModal;
