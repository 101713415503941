import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownArrowSVG } from "../../../images/down-arrow.svg";
import { ReactComponent as UpArrowSVG } from "../../../images/up-arrow.svg";
import { ReportApi, ReportConfigColumn, SubReportApi } from "../../../services/types";
import {
	getAggregatePercentValue,
	getAggregateValue,
	renderAggregateComparePercentValue,
	renderAggregateValue,
	renderGroupByName,
} from "../../../services/utils";

interface Props {
	columns: ReportConfigColumn[];
	initialData: ReportApi;
	data: ReportApi;
	aggregatePrefix?: string;
	comparable?: boolean;
	showPercent: boolean;
	groupsInfo?: Record<string, any>;
}

const TableGroup = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [renderAmount, setRenderAmount] = useState(30);

	useEffect(() => {
		handleResize();
	}, []);

	const handleScroll = (e: any) => {
		if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1) {
			if (amount === renderAmount) {
				setRenderAmount((renderAmount) => renderAmount + 20);
			}
		}
	};

	const handleResize = () => {
		if (window.innerHeight > window.screen.availHeight) {
			setRenderAmount((renderAmount) => renderAmount + 20);
			setTimeout(() => {
				handleResize();
			}, 100);
		}
	};

	const buildSummaryCell = (
		child: SubReportApi,
		index: number,
		summaryItem?: SubReportApi,
		comparable?: boolean,
		showIcon?: boolean
	) => {
		const aggregatePrefix = props.aggregatePrefix;
		return props.columns.map((col, index_) => {
			let percentValue = "";
			if (summaryItem) {
				percentValue = getAggregatePercentValue(
					col,
					summaryItem,
					child.aggregate[`${aggregatePrefix}`],
					aggregatePrefix,
					true
				);
			}
			return (
				<td key={`aggr_${index}_${index_}`}>
					{comparable ? (
						<>
							{props.showPercent && (
								<strong>{renderAggregateComparePercentValue(col, child, aggregatePrefix)}%</strong>
							)}
							{Number(getAggregateValue(col, child.aggregate, aggregatePrefix)) >
								Number(getAggregateValue(col, child.compare_aggregate, aggregatePrefix)) &&
								showIcon && <UpArrowSVG />}
							{Number(getAggregateValue(col, child.aggregate, aggregatePrefix)) <
								Number(getAggregateValue(col, child.compare_aggregate, aggregatePrefix)) &&
								showIcon && <DownArrowSVG />}
							<br />
							{renderAggregateValue(col, child.aggregate, aggregatePrefix)}
							<small> vs {renderAggregateValue(col, child.compare_aggregate, aggregatePrefix)}</small>
						</>
					) : (
						<strong>
							{renderAggregateValue(col, child.aggregate, aggregatePrefix)}
							{props.showPercent && (
								<small>
									{summaryItem ? (
										<>{percentValue}</>
									) : (
										<>{t("lib:go_report.word.percent_from_all")}</>
									)}
								</small>
							)}
						</strong>
					)}
				</td>
			);
		});
	};
	const buildGroupLevel = (child: SubReportApi) => {
		return props.initialData.reports?.[0].sub_report.map((itemm, parentIndex1) => {
			let aggregateItem: any = null;
			const prefix = `${itemm.group_by_type}_${itemm.group_by_value.name}`;
			const aggregatePrefix = props.aggregatePrefix;
			if (child.aggregate[prefix]) {
				aggregateItem = child.aggregate[prefix][`${aggregatePrefix}`];
			}
			return props.columns.map((col, index) => {
				const percentValue = getAggregatePercentValue(col, itemm, aggregateItem, aggregatePrefix, true);
				if (aggregateItem === null || aggregateItem === undefined) {
					return <td key={index}>~ {props.showPercent && <small> {percentValue}</small>}</td>;
				}
				return (
					<td key={`parent_${parentIndex1}_${index}`}>
						{props.comparable ? (
							<>
								{props.showPercent && (
									<strong>
										{renderAggregateComparePercentValue(col, child, aggregatePrefix, prefix)}%
									</strong>
								)}
								<br />
								{renderAggregateValue(col, child.aggregate, aggregatePrefix)}
								<small>
									{" "}
									vs {renderAggregateValue(col, child.compare_aggregate[prefix], aggregatePrefix)}
								</small>
							</>
						) : (
							<>
								{renderAggregateValue(col, aggregateItem, undefined)}
								{props.showPercent && <small>{percentValue}</small>}
							</>
						)}
					</td>
				);
			});
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, true);
		window.addEventListener("resize", handleResize, true);
		return () => {
			window.removeEventListener("scroll", handleScroll, true);
			window.removeEventListener("resize", handleResize, true);
		};
	});

	let amount = 0;
	return (
		<tbody>
			{
				<tr key={"summary"}>
					<td>
						<strong>{t("lib:common.word.summary")}</strong>
					</td>
					{props.initialData?.reports?.[0]?.sub_report?.map((item, index) => {
						return buildSummaryCell(item, index, undefined, props.comparable, true);
					})}
					{props.data?.reports.map((item, index) => {
						return buildSummaryCell(item, index, undefined, props.comparable, true);
					})}
				</tr>
			}
			{props.data?.reports.map((item, parentIndex) => {
				++amount;
				return (
					<React.Fragment key={""}>
						{item.sub_report.map((child, index) => {
							++amount;
							if (amount > renderAmount) {
								return null;
							}
							return (
								<React.Fragment key={`aggr_${index}`}>
									<tr>
										<td>
											{props.groupsInfo &&
											Object.prototype.hasOwnProperty.call(
												props.groupsInfo,
												`${child.group_by_type}`
											) ? (
												<>{props.groupsInfo[`${child.group_by_type}`](child.group_by_value)}</>
											) : (
												<>{renderGroupByName(child.group_by_value.name)}</>
											)}
										</td>
										{buildGroupLevel(child)}
										{buildSummaryCell(child, index, item, props.comparable)}
									</tr>
									{child.sub_report.map((child2, index) => {
										++amount;
										if (amount > renderAmount) {
											return null;
										}
										return (
											<React.Fragment key={`aggr_1_${index}`}>
												<tr>
													<td style={{ paddingLeft: "30px" }}>
														{props.groupsInfo &&
														Object.prototype.hasOwnProperty.call(
															props.groupsInfo,
															`${child2.group_by_type}`
														) ? (
															<>
																{props.groupsInfo[`${child2.group_by_type}`](
																	child2.group_by_value
																)}
															</>
														) : (
															<>{renderGroupByName(child2.group_by_value.name)}</>
														)}
													</td>
													{buildGroupLevel(child2)}
													{buildSummaryCell(child2, index, item, props.comparable)}
												</tr>
												{child2.sub_report.map((child3, index) => {
													++amount;
													if (amount > renderAmount) {
														return null;
													}
													return (
														<React.Fragment key={`aggr_2_${index}`}>
															<tr>
																<td style={{ paddingLeft: "60px" }}>
																	{props.groupsInfo &&
																	Object.prototype.hasOwnProperty.call(
																		props.groupsInfo,
																		`${child3.group_by_type}`
																	) ? (
																		<>
																			{props.groupsInfo[
																				`${child3.group_by_type}`
																			](child3.group_by_value)}
																		</>
																	) : (
																		<>
																			{renderGroupByName(
																				child3.group_by_value.name
																			)}
																		</>
																	)}
																</td>
																{buildGroupLevel(child3)}
																{buildSummaryCell(
																	child3,
																	index,
																	item,
																	props.comparable
																)}
															</tr>
															{child3.sub_report.map((child4, index) => {
																++amount;
																if (amount > renderAmount) {
																	return null;
																}
																return (
																	<React.Fragment key={`aggr_3_${index}`}>
																		<tr>
																			<td style={{ paddingLeft: "90px" }}>
																				{props.groupsInfo &&
																				Object.prototype.hasOwnProperty.call(
																					props.groupsInfo,
																					`${child4.group_by_type}`
																				) ? (
																					<>
																						{props.groupsInfo[
																							`${child4.group_by_type}`
																						](child4.group_by_value)}
																					</>
																				) : (
																					<>
																						{renderGroupByName(
																							child4.group_by_value.name
																						)}
																					</>
																				)}
																			</td>
																			{buildGroupLevel(child4)}
																			{buildSummaryCell(
																				child4,
																				index,
																				item,
																				props.comparable
																			)}
																		</tr>
																		{child4.sub_report.map((child5, index) => {
																			++amount;
																			if (amount > renderAmount) {
																				return null;
																			}
																			return (
																				<React.Fragment key={`aggr_4_${index}`}>
																					<tr>
																						<td
																							style={{
																								paddingLeft: "120px",
																							}}
																						>
																							{props.groupsInfo &&
																							Object.prototype.hasOwnProperty.call(
																								props.groupsInfo,
																								`${child5.group_by_type}`
																							) ? (
																								<>
																									{props.groupsInfo[
																										`${child5.group_by_type}`
																									](
																										child5.group_by_value
																									)}
																								</>
																							) : (
																								<>
																									{renderGroupByName(
																										child5
																											.group_by_value
																											.name
																									)}
																								</>
																							)}
																						</td>
																						{buildGroupLevel(child5)}
																						{buildSummaryCell(
																							child5,
																							index,
																							item,
																							props.comparable
																						)}
																					</tr>
																					{child5.sub_report.map(
																						(child6, index) => {
																							++amount;
																							if (amount > renderAmount) {
																								return null;
																							}
																							return (
																								<React.Fragment
																									key={`aggr_5_${index}`}
																								>
																									<tr>
																										<td
																											style={{
																												paddingLeft:
																													"150px",
																											}}
																										>
																											{props.groupsInfo &&
																											Object.prototype.hasOwnProperty.call(
																												props.groupsInfo,
																												`${child6.group_by_type}`
																											) ? (
																												<>
																													{props.groupsInfo[
																														`${child6.group_by_type}`
																													](
																														child6.group_by_value
																													)}
																												</>
																											) : (
																												<>
																													{renderGroupByName(
																														child6
																															.group_by_value
																															.name
																													)}
																												</>
																											)}
																										</td>
																										{buildGroupLevel(
																											child6
																										)}
																										{buildSummaryCell(
																											child6,
																											index,
																											item,
																											props.comparable
																										)}
																									</tr>
																									{child6.sub_report.map(
																										(
																											child7,
																											index
																										) => {
																											++amount;
																											if (
																												amount >
																												renderAmount
																											) {
																												return null;
																											}
																											return (
																												<React.Fragment
																													key={`aggr_6_${index}`}
																												>
																													<tr>
																														<td
																															style={{
																																paddingLeft:
																																	"180px",
																															}}
																														>
																															{props.groupsInfo &&
																															Object.prototype.hasOwnProperty.call(
																																props.groupsInfo,
																																`${child7.group_by_type}`
																															) ? (
																																<>
																																	{props.groupsInfo[
																																		`${child7.group_by_type}`
																																	](
																																		child7.group_by_value
																																	)}
																																</>
																															) : (
																																<>
																																	{renderGroupByName(
																																		child7
																																			.group_by_value
																																			.name
																																	)}
																																</>
																															)}
																														</td>
																														{buildGroupLevel(
																															child7
																														)}
																														{buildSummaryCell(
																															child7,
																															index,
																															item,
																															props.comparable
																														)}
																													</tr>
																												</React.Fragment>
																											);
																										}
																									)}
																								</React.Fragment>
																							);
																						}
																					)}
																				</React.Fragment>
																			);
																		})}
																	</React.Fragment>
																);
															})}
														</React.Fragment>
													);
												})}
											</React.Fragment>
										);
									})}
								</React.Fragment>
							);
						})}
					</React.Fragment>
				);
			})}
		</tbody>
	);
};
export default TableGroup;
