import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	InconsistentReference,
	InconsistentReferenceTitle,
} from "../../../../../../../../../components/Common/InconsistentReferenceTooltip/InconsistentReference";
import { ReactComponent as InvoiceSVG } from "../../../../../../../../../images/svg/bills/invoice.svg";
import { InvoiceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { printInvoicePdf } from "../../../../Invoices/services/exportInvoice";

interface Props {
	id: number;
	invoiceId: string;
	inconsistent_reference_id: boolean;
}

const TransactionInvoiceCard = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [invoice, setInvoice] = useState<undefined | InvoiceApi>(undefined);
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();

	useEffect(() => {
		onFetchInvoice();
	}, []);

	const onFetchInvoice = async () => {
		const invoiceParams: Record<string, any> = {
			include: "items,orders,summary",
		};
		try {
			const res = await api.organization().getInvoice(props.id, invoiceParams);
			setInvoice(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!invoice) {
		return <LoadingContainer />;
	}
	return (
		<>
			<Card>
				<Card.Body>
					{
						<>
							<InconsistentReferenceTitle
								titleText={`${t(`enums.invoices.document_type.${invoice.document_type}`)} ${
									props.invoiceId
								}`}
								inconsistentReferenceId={props.inconsistent_reference_id}
							/>
							<div className={"table-responsive mb-3"}>
								<table className="table table-preview">
									<thead>
										<tr>
											<th className={"w-60"}>{t("modules.transaction.field.name.title")}</th>
											<th className={"w-20"}>{t("common.word.quantity")}</th>
											<th className={"w-20"}>{t("common.word.value")}</th>
										</tr>
									</thead>
									<tbody>
										{invoice.items?.map((item, index) => {
											return (
												<tr key={index} className={"bordered"}>
													<td>{item.name}</td>
													<td>x{item.quantity}</td>
													<td style={{ whiteSpace: "nowrap" }}>
														{FormatMoney(item.price_sum)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							<div className="table-summary" style={{ color: "#6C757D" }}>
								<span className="summary-label mb-1">{t("common.word.amount_net")}</span>
								<span>{FormatMoney(invoice.summary?.price_sum_net)}</span>
							</div>
							<div className="table-summary mb-4">
								<span className="summary-label">
									<strong>{t("modules.transaction.field.total_price.title")}</strong>
								</span>
								<span>
									<strong>{FormatMoney(invoice.summary?.price_sum_gross)}</strong>
								</span>
							</div>
						</>
					}
					<div className={"d-flex flex-column"} style={{ marginLeft: "-8px", marginRight: "-8px" }}>
						<div className="d-flex justify-content-center">
							<div className={"col-md-6"}>
								<Button variant={"add btn-block"} onClick={() => printInvoicePdf(invoice)}>
									{t("common.action.generate_print")}
								</Button>
							</div>
							<div className={"col-md-6"}>
								<Link
									to={`/${organization.id}/invoices/${invoice.id}`}
									className={"btn btn-add btn-block"}
								>
									{t("common.action.show_invoice")}
								</Link>
							</div>
						</div>
					</div>
				</Card.Body>
			</Card>
			{invoice.orders && invoice.orders.length > 0 && (
				<Card>
					<Card.Body>
						<h5>{t("modules.transaction.field.orders.title")}</h5>
						{invoice.orders.map((order, index) => {
							return (
								<div key={index} className={"card-body-item"}>
									<InvoiceSVG />
									<div className="item-info">
										<Link
											target={"_blank"}
											rel={"noreferrer"}
											to={`/${organization.id}/sales/orders/${order.order_id}`}
										>
											#{order.order_id}
										</Link>
									</div>
								</div>
							);
						})}
					</Card.Body>
				</Card>
			)}
		</>
	);
};
export default TransactionInvoiceCard;

// t("modules.transaction.field.proforma.title");
// t("modules.transaction.field.correction.title");
// t("common.word.invoice");
// t("modules.transaction.field.advance.title");
