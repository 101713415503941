import { ListConfigColumn } from "../../Column/services/types";

export const getSortedColumns = (
	allColumns: ListConfigColumn[],
	columnsIdsInCorrectOrder: string[]
): ListConfigColumn[] => {
	const sortedColumns = [...allColumns].sort((a, b) => {
		const indexOfA = columnsIdsInCorrectOrder.indexOf(a.id);
		const indexOfB = columnsIdsInCorrectOrder.indexOf(b.id);
		return indexOfA === indexOfB ? 0 : indexOfA > indexOfB ? 1 : -1;
	});

	return sortedColumns;
};
