import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ApiError } from "go-core/api/types";
import { useDynamicTableHeight } from "go-list/core/components/Table/hooks";

interface Props {
	errors: ApiError[] | undefined;
	databaseColumnName: Record<string, string>;
	constraints: Record<string, string>;
	importPageRef?: React.RefObject<HTMLDivElement | null>;
}

const ImportErrors = ({ errors, databaseColumnName, constraints, importPageRef }: Props): JSX.Element => {
	const { t } = useTranslation();
	const mainDivRef = useRef<HTMLDivElement>(null);
	const footerAndAlertsHeight = 380;
	const { mainDivStyles } = useDynamicTableHeight(mainDivRef, footerAndAlertsHeight, importPageRef);

	return (
		<div className="table-wrapper">
			<div className="table-list" ref={mainDivRef} style={mainDivStyles}>
				<table className="table table-preview">
					<thead>
						<tr>
							<th>{t("go_component.import_csv.field.csv_column.title", { ns: "lib" })}</th>
							<th>{t("go_component.import_csv.field.line.title", { ns: "lib" })}</th>
							<th>{t("go_component.import_csv.field.database_column.title", { ns: "lib" })}</th>
							<th>{t("go_component.import_csv.field.error.title", { ns: "lib" })}</th>
						</tr>
					</thead>
					<tbody>
						{(errors || []).map((err: any, index: number) => {
							return (
								<tr key={index}>
									<td>{err.field}</td>
									<td>{err.line}</td>
									<td>
										{err.code.includes("custom_field")
											? err.domain_field_name
											: databaseColumnName[err.domain_field_name]}
									</td>
									<td>
										{constraints[err.code]}: {err.message}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ImportErrors;
