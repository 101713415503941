import React, { useState } from "react";
import { Dropdown, Nav, NavItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import { ReactComponent as ApplicationsSVG } from "go-component/images/svg/applications.svg";
import { appIcon } from "go-core/components/Apps/AppIcon";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { redirectToApplication } from "go-core/utils/utils";
import handleError from "../../services/errors";
import { GoSuiteApp, MenuAppConfig, WebApp } from "../../services/types";
import { getDuplicateApps, getRidOfDuplicateApps } from "../../utils";
import { useOrganizations } from "../../utils/OrganizationsContext";

interface Props {
	apps: GoSuiteApp[];
	link_show_all: string;
	company_id?: string;
	fetch_installed_apps: () => Promise<string[]>;
	organization_name?: string;
	organization: Record<string, any>;
	fetchMenuApplication?: (companyId: string, params?: Record<string, any>) => Record<string, any>;
	menuAppConfig: MenuAppConfig;
	navigationOpen: boolean;
}

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:enum.apps.GoPOS.header");
	t("lib:enum.apps.GoOrder.header");
	t("lib:enum.apps.GoStock.header");
	t("lib:enum.apps.GoAccounts.header");
	t("lib:enum.apps.GoCRM.header");
	t("lib:common.action.show_less");
};

const getOldVersionAppBasedOnConfig = (
	webApp: WebApp,
	oldVersionAppsCustomRedirectURL?: Record<string, string>
): string => {
	if (oldVersionAppsCustomRedirectURL) {
		const appOldVersionURLArray = Object.entries(oldVersionAppsCustomRedirectURL ?? {}).find(
			([appName]) => appName === webApp
		);
		return appOldVersionURLArray ? appOldVersionURLArray[1] : "";
	}

	return "";
};

const NavigationApplicationsComponent = (props: Props) => {
	const {
		menuAppConfig: { webApp, oldVersionAppsCustomRedirectURL, userEnteredLocation, oldVersionAppsTimestamp },
	} = props;
	const { organizations } = useOrganizations();
	const [organizationFromRequest, setOrganizationFromRequest] = useState<Record<string, any> | undefined>(undefined);
	const createdAtPropertyInOrganization = Object.keys(props.organization)?.includes("created_at");
	const shouldOneApplicationRedirectOnlyToOldVersion =
		getDuplicateApps(props.apps).includes(webApp) && userEnteredLocation;
	const appsWithProperVersion = getRidOfDuplicateApps(
		props.apps,
		organizationFromRequest ?? props.organization,
		oldVersionAppsTimestamp,
		true,
		userEnteredLocation,
		webApp,
		organizations
	);
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const { isSmallBreakpoint } = useWindowSize();
	const dropdownDropPlacement = props.navigationOpen && isSmallBreakpoint ? "up-centered" : "end";

	const getApplicationNameText = (application: GoSuiteApp): string => {
		let appNameSuffix = "";
		if (shouldOneApplicationRedirectOnlyToOldVersion && application.id.toUpperCase() === webApp)
			appNameSuffix += ` - ${t("common.word.old_version", { ns: "lib" })}`;

		return `${application.name}${appNameSuffix}`;
	};

	const getApplicationDescription = (application: GoSuiteApp) => {
		return (
			<>
				<span className={"svg"}>{appIcon(application.id)}</span>
				<span className={"title text-wrap"}>{getApplicationNameText(application)}</span>
				{application.header ? (
					<span className={`header header-${application.id}`}>{application.header}</span>
				) : (
					<span className={`header header-${application.id}`}>
						{t(`lib:enum.apps.${application.id}.header`)}
					</span>
				)}
			</>
		);
	};

	const toggleHandler = async () => {
		try {
			if (
				!createdAtPropertyInOrganization &&
				props.fetchMenuApplication &&
				!organizationFromRequest &&
				userEnteredLocation &&
				props.company_id
			) {
				const res = await props.fetchMenuApplication(props.company_id);
				setOrganizationFromRequest(res);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<Dropdown as={NavItem} drop={dropdownDropPlacement} onToggle={toggleHandler}>
			<Dropdown.Toggle as={Nav.Link}>
				<div className="nav-link-svg">
					<ApplicationsSVG />
				</div>
				<span>{t("lib:go_component.navigation.applications.header")}</span>
			</Dropdown.Toggle>
			<Dropdown.Menu className="navigation-applications">
				{
					<div className="navigation-applications-cards">
						{!createdAtPropertyInOrganization && !organizationFromRequest && userEnteredLocation ? (
							<LoadingContainer />
						) : (
							appsWithProperVersion.map((app) => {
								const redirectURL =
									shouldOneApplicationRedirectOnlyToOldVersion && app.id.toUpperCase() === webApp
										? getOldVersionAppBasedOnConfig(webApp, oldVersionAppsCustomRedirectURL)
										: redirectToApplication(app.url, props.company_id, undefined, app.old);

								return (
									<Dropdown.Item target="_blank" rel="noreferrer" href={redirectURL} key={app.id}>
										{getApplicationDescription(app)}
									</Dropdown.Item>
								);
							})
						)}
					</div>
				}
				{props.organization_name && (
					<>
						<Dropdown.Divider />
						<Dropdown.Item className={"footer"} as={Link} to={props.link_show_all}>
							{t("lib:common.action.show_more")}
						</Dropdown.Item>
					</>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};
export default NavigationApplicationsComponent;
