import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import ClientForm from "../../components/ClientForm";

const OrganizationClientsIndexNewPage = (): JSX.Element => {
	const [client, setClient] = useState<ClientApi>();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		setClient({} as ClientApi);
		handleChangeTabTitle(t("modules.client.header.new.title"));
	}, []);

	return (
		<>
			<Header title={t("modules.client.header.new.title")} />
			{client ? <ClientForm client={client} setClient={setClient} /> : <LoadingContainer />}
		</>
	);
};
export default OrganizationClientsIndexNewPage;
