import React, { useEffect, useState } from "react";
import { LoadingContainer } from "go-core/components/Loading";
import { CatalogReportCombo, TemplateInstanceReportCombo, TemplateReportCombo } from "../Template/types";
import CatalogTemplateEditReportComboComponent from "./CatalogTemplateEditReportComboComponent";
import CatalogTemplatesReportComboComponent from "./CatalogTemplatesReportComboComponent";

interface Props {
	catalog: CatalogReportCombo[];
	template: TemplateReportCombo;
	onChange: (template: TemplateReportCombo) => void;
}

const CatalogReportComboComponent = (props: Props) => {
	const [loading, setLoading] = useState(true);
	const [templateItem, setTemplateItem] = useState<TemplateInstanceReportCombo | undefined>(undefined);

	useEffect(() => {
		setLoading(false);
	}, []);

	if (loading) {
		return <LoadingContainer />;
	}

	const updateTemplate = (newTemplate: TemplateReportCombo) => {
		props.onChange(newTemplate);
	};

	const updateTemplateItem = (newTemplateItem: TemplateInstanceReportCombo) => {
		const templates = [...props.template.templates];
		const index = templates.findIndex((template) => template.id === newTemplateItem.id);
		if (index !== -1) {
			templates[index] = newTemplateItem;
		}
		props.onChange({ ...props.template, templates });
	};

	return (
		<>
			{templateItem && (
				<CatalogTemplateEditReportComboComponent
					templateItem={templateItem}
					onBack={() => setTemplateItem(undefined)}
					onUpdate={updateTemplateItem}
				/>
			)}
			<div style={templateItem ? { display: "none" } : {}}>
				<CatalogTemplatesReportComboComponent
					template={props.template}
					catalog={props.catalog}
					onEdit={(templateItem) => setTemplateItem(templateItem)}
					onUpdate={(template) => updateTemplate(template)}
				/>
			</div>
		</>
	);
};

export default CatalogReportComboComponent;
