import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { parseStringToList } from "../../../utils/utils";
import { AppState } from "../AppModal";

interface Props {
	form: UseFormReturn<ApplicationApi>;
	appState: AppState;
}

const MultiLocationFormSettings = (props: Props): JSX.Element => {
	const {
		formState: { errors },
		register,
		watch,
		setValue,
		control,
		getValues,
	} = props.form;
	const { t } = useTranslation();
	const settings = watch("settings");
	const [updatedConfig, setUpdatedConfig] = useState<Record<string, any> | undefined>(props.appState.config);
	const [showActivePriceLists, setShowActivePriceLists] = useState(settings.active_price_list?.length > 0);
	const updateCheckboxes = (
		properties: Array<
			| "settings.auto_create_imported_menu_price_lists"
			| "settings.update_imported_menu_price_lists"
			| "settings.update_imported_availabilities"
			| "settings.auto_create_imported_availabilities"
			| "settings.update_imported_menus"
			| "settings.auto_create_imported_menus"
			| "settings.auto_create_imported_items"
			| "settings.update_imported_items"
			| "settings.auto_create_imported_categories"
			| "settings.update_imported_categories"
			| "settings.auto_create_imported_modifier_groups"
			| "settings.update_imported_modifier_groups"
			| "settings.auto_create_imported_menu_discounts"
			| "settings.update_imported_menu_discounts"
			| "settings.import_employees"
			| "settings.auto_create_imported_employees"
			| "settings.update_imported_employees"
			| "settings.import_venue_roles"
			| "settings.auto_create_imported_venue_roles"
			| "settings.update_imported_venue_roles"
			| "settings.import_payment_methods"
			| "settings.auto_create_imported_payment_methods"
			| "settings.update_imported_payment_methods"
			| "settings.import_custom_field_templates"
			| "settings.auto_create_imported_custom_field_templates"
			| "settings.update_imported_custom_field_templates"
			| "settings.import_segments"
			| "settings.auto_create_imported_segments"
			| "settings.update_imported_segments"
			| "settings.import_printout_templates"
			| "settings.auto_create_imported_printout_templates"
			| "settings.update_imported_printout_templates"
		>
	) => {
		properties.forEach((prop) => {
			setValue(`${prop}`, false);
		});
	};

	const searchPriceLists = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPriceListsSearchSelect(search, params, getValues().settings.organization_id, {
			cancelToken: options?.token,
		});
	};

	const defaultPriceLists = parseStringToList(
		props.appState.app.settings,
		updatedConfig || {},
		"active_price_list",
		"active_menu_price_lists"
	)?.map((terminal) => ({
		label: terminal?.name,
		id: terminal?.id,
	}));

	const updateConfig = (prop: string, obj?: Record<string, any>[]) => {
		obj = (obj || []).map((o: Record<string, any>) => {
			return {
				name: o.name,
				id: o.id,
			};
		});
		const cnf = { ...updatedConfig };
		if (prop === "active_menu_price_lists") {
			cnf.active_menu_price_lists = obj;
		}
		setUpdatedConfig(cnf);
	};

	return (
		<>
			<FormCheck
				register={register}
				type={"switch"}
				name={"settings.auto_link_by_name"}
				errors={errors}
				label={t("modules.app.action.auto_link_by_name.title")}
			/>
			<Form.Group className="form-group">
				<Form.Check
					id={"settings.active_price_list"}
					checked={showActivePriceLists}
					type={"switch"}
					onClick={() => {
						setShowActivePriceLists(!showActivePriceLists);
						if (showActivePriceLists) {
							setValue("settings.active_price_list", []);
						}
					}}
					label={t("modules.app.action.active_menu_price_lists.title")}
				/>
				<Form.Text muted>{t("modules.app.action.active_menu_price_lists.helptext.description")}</Form.Text>
				{showActivePriceLists && (
					<>
						<FormSelectGroup
							label={t("modules.app.field.price_lists.title")}
							isMulti={true}
							onChange={(_, fullObj) => updateConfig("active_menu_price_lists", fullObj)}
							name="settings.active_price_list"
							errors={errors}
							defaultValue={defaultPriceLists}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							loadOptions={searchPriceLists}
							control={control}
							data-testid="settings.active_price_list"
						/>
						<FormCheck
							type={"switch"}
							errors={errors}
							name={"settings.delete_data_not_related_to_price_list"}
							label={t("modules.app.field.delete_data_not_related_to_price_list.title")}
							register={register}
						/>
					</>
				)}
			</Form.Group>
			{!showActivePriceLists && (
				<>
					<FormCheck
						onClick={() =>
							updateCheckboxes([
								"settings.auto_create_imported_menu_price_lists",
								"settings.update_imported_menu_price_lists",
							])
						}
						type={"switch"}
						errors={errors}
						name={"settings.import_menu_price_lists"}
						label={t("modules.app.action.import_menu_price_lists.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_menu_price_lists")}
						type={"switch"}
						errors={errors}
						name={"settings.auto_create_imported_menu_price_lists"}
						label={t("modules.app.action.auto_create_imported_menu_price_lists.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_menu_price_lists")}
						type={"switch"}
						errors={errors}
						name={"settings.update_imported_menu_price_lists"}
						label={t("modules.app.action.update_imported_menu_price_lists.title")}
						register={register}
					/>

					<FormCheck
						onClick={() =>
							updateCheckboxes(["settings.auto_create_imported_items", "settings.update_imported_items"])
						}
						type={"switch"}
						className={"mt-4"}
						errors={errors}
						name={"settings.import_items"}
						label={t("modules.app.action.import_items.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_items")}
						type={"switch"}
						errors={errors}
						name={"settings.auto_create_imported_items"}
						label={t("modules.app.action.auto_create_imported_items.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_items")}
						type={"switch"}
						errors={errors}
						name={"settings.update_imported_items"}
						label={t("modules.app.action.update_imported_items.title")}
						register={register}
					/>
					<FormCheck
						onClick={() =>
							updateCheckboxes([
								"settings.auto_create_imported_categories",
								"settings.update_imported_categories",
							])
						}
						className={"mt-4"}
						type={"switch"}
						errors={errors}
						name={"settings.import_categories"}
						label={t("modules.app.action.import_categories.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_categories")}
						type={"switch"}
						errors={errors}
						name={"settings.auto_create_imported_categories"}
						label={t("modules.app.action.auto_create_imported_categories.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_categories")}
						type={"switch"}
						errors={errors}
						name={"settings.update_imported_categories"}
						label={t("modules.app.action.update_imported_categories.title")}
						register={register}
					/>
					<FormCheck
						onClick={() =>
							updateCheckboxes([
								"settings.auto_create_imported_modifier_groups",
								"settings.update_imported_modifier_groups",
							])
						}
						className={"mt-4"}
						type={"switch"}
						errors={errors}
						name={"settings.import_modifier_groups"}
						label={t("modules.app.action.import_modifier_groups.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_modifier_groups")}
						type={"switch"}
						errors={errors}
						name={"settings.auto_create_imported_modifier_groups"}
						label={t("modules.app.action.auto_create_modifier_groups.title")}
						register={register}
					/>
					<FormCheck
						disabled={!watch("settings.import_modifier_groups")}
						type={"switch"}
						errors={errors}
						name={"settings.update_imported_modifier_groups"}
						label={t("modules.app.action.update_imported_modifier_groups.title")}
						register={register}
					/>
				</>
			)}
			<FormCheck
				onClick={() =>
					updateCheckboxes([
						"settings.auto_create_imported_menu_discounts",
						"settings.update_imported_menu_discounts",
					])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_menu_discounts"}
				label={t("modules.app.action.import_menu_discounts.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_menu_discounts")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_menu_discounts"}
				label={t("modules.app.action.auto_create_imported_menu_discounts.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_menu_discounts")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_menu_discounts"}
				label={t("modules.app.action.update_imported_menu_discounts.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes(["settings.auto_create_imported_menus", "settings.update_imported_menus"])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_menus"}
				label={t("modules.app.action.import_menus.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_menus")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_menus"}
				label={t("modules.app.action.auto_create_imported_menus.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_menus")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_menus"}
				label={t("modules.app.action.update_imported_menus.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes([
						"settings.auto_create_imported_availabilities",
						"settings.update_imported_availabilities",
					])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_availabilities"}
				label={t("modules.app.action.import_availabilities.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_availabilities")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_availabilities"}
				label={t("modules.app.action.auto_create_imported_availabilities.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_availabilities")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_availabilities"}
				label={t("modules.app.action.update_imported_availabilities.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes(["settings.auto_create_imported_employees", "settings.update_imported_employees"])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_employees"}
				label={t("modules.app.action.import_employees.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_employees")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_employees"}
				label={t("modules.app.action.auto_create_imported_employees.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_employees")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_employees"}
				label={t("modules.app.action.update_imported_employees.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes([
						"settings.auto_create_imported_venue_roles",
						"settings.update_imported_venue_roles",
					])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_venue_roles"}
				label={t("modules.app.action.import_venue_roles.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_venue_roles")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_venue_roles"}
				label={t("modules.app.action.auto_create_imported_venue_roles.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_venue_roles")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_venue_roles"}
				label={t("modules.app.action.update_imported_venue_roles.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes([
						"settings.auto_create_imported_payment_methods",
						"settings.update_imported_payment_methods",
					])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_payment_methods"}
				label={t("modules.app.action.import_payment_methods.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_payment_methods")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_payment_methods"}
				label={t("modules.app.action.auto_create_imported_payment_methods.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_payment_methods")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_payment_methods"}
				label={t("modules.app.action.update_imported_payment_methods.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes([
						"settings.auto_create_imported_custom_field_templates",
						"settings.update_imported_custom_field_templates",
					])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_custom_field_templates"}
				label={t("modules.app.action.import_custom_field_templates.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_custom_field_templates")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_custom_field_templates"}
				label={t("modules.app.action.auto_create_imported_custom_field_templates.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_custom_field_templates")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_custom_field_templates"}
				label={t("modules.app.action.update_imported_custom_field_templates.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes(["settings.auto_create_imported_segments", "settings.update_imported_segments"])
				}
				className={"mt-4"}
				type={"switch"}
				errors={errors}
				name={"settings.import_segments"}
				label={t("modules.app.action.import_segments.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_segments")}
				type={"switch"}
				errors={errors}
				name={"settings.auto_create_imported_segments"}
				label={t("modules.app.action.auto_create_imported_segments.title")}
				register={register}
			/>
			<FormCheck
				disabled={!watch("settings.import_segments")}
				type={"switch"}
				errors={errors}
				name={"settings.update_imported_segments"}
				label={t("modules.app.action.update_imported_segments.title")}
				register={register}
			/>
			<FormCheck
				onClick={() =>
					updateCheckboxes([
						"settings.auto_create_imported_printout_templates",
						"settings.update_imported_printout_templates",
					])
				}
				type="switch"
				errors={errors}
				name="settings.import_printout_templates"
				label={t("modules.app.action.import_printout_templates.title")}
				register={register}
			/>
			<FormCheck
				type="switch"
				errors={errors}
				name="settings.auto_create_imported_printout_templates"
				label={t("modules.app.action.auto_create_imported_printout_templates.title")}
				register={register}
				disabled={!watch("settings.import_printout_templates")}
			/>
			<FormCheck
				type="switch"
				errors={errors}
				name="settings.update_imported_printout_templates"
				label={t("modules.app.action.update_imported_printout_templates.title")}
				register={register}
				disabled={!watch("settings.import_printout_templates")}
			/>
		</>
	);
};
export default MultiLocationFormSettings;
