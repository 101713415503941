import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { PrintoutTemplateApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import PrintoutTemplateForm from "../../components/PrintoutTemplateForm";

interface MatchParams {
	printout_id: string;
}

const OrganizationSettingsPrintoutTemplatesEditPage = (): JSX.Element => {
	const [printoutTemplate, setPrintoutTemplate] = useState<PrintoutTemplateApi | undefined>();
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const history = useHistory();
	const { printout_id } = useParams<MatchParams>();
	const organization = useSelector(selectOrganization);
	const params: Record<string, string> = { include: "entities, entities.entity" };
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchPrintoutTemplateSettings();
	}, []);

	const onFetchPrintoutTemplateSettings = async () => {
		try {
			const res = await api.organization().getPrintoutTemplateSettings(Number(printout_id), params);
			setPrintoutTemplate(res);
			handleChangeTabTitle(`${res.name || ""} | ${t("modules.printout_template.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!printoutTemplate) {
		return <LoadingContainer />;
	}

	const removePrintoutTemplate = async (printoutTemplate: PrintoutTemplateApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removePrintoutTemplate(printoutTemplate.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/settings/printout_templates`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restorePrintoutTemplate = async (printoutTemplate: PrintoutTemplateApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().restorePrintoutTemplate(printoutTemplate.id, params);
			addSuccessFlash(t("lib:common.flash.completed"));
			setPrintoutTemplate(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const renderTitle = () => (
		<>
			{t("modules.printout_template.header.title")} "{printoutTemplate.name}"
			<FormatResourceStatus status={printoutTemplate.status} />
		</>
	);

	const buttons: ButtonProps[] = [];
	if (printoutTemplate.status !== "DELETED")
		buttons.push({
			action: () => removePrintoutTemplate(printoutTemplate),
			title: t("common.action.remove", { ns: "lib" }),
			dropdown: true,
		});
	if (printoutTemplate.status === "DELETED")
		buttons.push({
			action: () => restorePrintoutTemplate(printoutTemplate),
			title: t("common.action.activate", { ns: "lib" }),
			dropdown: true,
		});
	const mobileActions: MobileActionProps[] = [
		{
			action: () => removePrintoutTemplate(printoutTemplate),
			title: t("common.action.remove", { ns: "lib" }),
			hide: printoutTemplate.status === "DELETED",
		},
		{
			action: () => restorePrintoutTemplate(printoutTemplate),
			title: t("common.action.activate", { ns: "lib" }),
			hide: printoutTemplate.status !== "DELETED",
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<PrintoutTemplateForm printoutTemplate={printoutTemplate} />
		</>
	);
};

export default OrganizationSettingsPrintoutTemplatesEditPage;
