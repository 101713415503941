import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { PaymentMethodApi } from "../../../../../../../../../../services/Api/Organization/types";
import PaymentMethodForm from "../../components/PaymentMethodForm";

const OrganizationSettingsPaymentMethodsNewPage = (): JSX.Element => {
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethodApi | undefined>();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.payment_method.header.new.title"));
		setPaymentMethod({
			outflow_type: "WZ",
			receipt_type: "CARD",
			print_receipt: true,
		} as PaymentMethodApi);
	}, []);

	if (paymentMethod === undefined) {
		return <></>;
	}
	return (
		<>
			<Header title={t("modules.payment_method.header.new.title")} />
			<PaymentMethodForm paymentMethod={paymentMethod} />
		</>
	);
};

export default OrganizationSettingsPaymentMethodsNewPage;
