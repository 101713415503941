import React, { useState } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { DiscountApi } from "../../../../../../../../../../services/Api/Organization/types";
import DiscountFormConditionsTable from "./DiscountFormConditionsTable";

interface Props {
	form: UseFormReturn<DiscountApi>;
	setAreConditionsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	areConditionsVisible: boolean;
}

const DiscountFormConditions = ({ form, setAreConditionsVisible, areConditionsVisible }: Props) => {
	const { t } = useTranslation();
	const { control, setValue } = form;
	const { fields, append, remove } = useFieldArray({
		control,
		name: "conditions",
		keyName: "key",
	});
	const [conditionsTableVisible] = useState(fields.length > 0);

	return (
		<LimitComponentVisibility
			defaultVisible={conditionsTableVisible}
			handleChange={(value) => value === false && setValue("conditions", [], { shouldDirty: true })}
			id="conditions"
			legendText={t("modules.discount.field.conditions.title")}
			helpText={t("modules.discount.field.conditions.help_text")}
			isVisibleControl={areConditionsVisible}
			setIsVisibleControl={setAreConditionsVisible}
			unlimitedContent={
				<DiscountFormConditionsTable form={form} fields={fields} append={append} remove={remove} />
			}
		/>
	);
};

export default DiscountFormConditions;
