import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasErrorsApi } from "go-core";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { FormErrorMessageApi } from "go-form/components/FormErrorMessage";
import { apiSecurity } from "go-security/services/Api/api";

interface Props {
	onLinkSent: () => void;
}

const LostPasswordForm: FC<Props> = (props) => {
	const { t } = useTranslation();
	const [formLoading, setFormLoading] = useState(false);
	const [email, setEmail] = useState("");
	const { addFlash } = useFlash();
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const handleChange = () => (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const [errors, setErrors] = useState<ApiError[]>([]);

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		setFormLoading(true);
		apiSecurity
			.forgotPassword(email)
			.then(() => {
				setFormLoading(false);
				setShowSuccessMessage(true);
				props.onLinkSent();
			})
			.catch((e) => {
				setFormLoading(false);
				const exceptionErrors = handleException(e.response?.data ? e.response?.data : e);
				const alertErrors = exceptionErrors.filter((f) => f.field === "all");
				if (alertErrors.length > 0) {
					addFlash({
						type: "danger",
						msg: alertErrors,
					});
				}
				setErrors(exceptionErrors);
			});
	};

	if (showSuccessMessage) {
		return (
			<>
				<h5 style={{ textAlign: "center" }}>
					{t("lib:go_security.lost_password.form.actions.remind_password_sent")}
				</h5>
				<Link to="/login" className="btn btn-primary-light">
					{t("lib:go_security.lost_password.form.actions.back_to_login")}
				</Link>
			</>
		);
	}

	return (
		<>
			<h3>{t("lib:go_security.lost_password.form.actions.do_not_remember_password")}</h3>
			<small>{t("lib:go_security.lost_password.form.actions.remind_password_instructions")}</small>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Form.Group className="form-group">
					<Form.Control
						disabled={formLoading}
						id="email"
						isInvalid={hasErrorsApi(errors, "email")}
						onChange={handleChange()}
						type="email"
						value={email}
						placeholder={t("lib:go_security.lost_password.form.email.placeholder")}
					/>
					<Form.Label htmlFor="login" sm={3}>
						{t("lib:go_security.lost_password.form.email.label")}
					</Form.Label>
					<FormErrorMessageApi errors={errors} field="email" />
				</Form.Group>
				<ButtonLoading loading={formLoading} type="submit" color="primary">
					{t("lib:go_security.lost_password.form.actions.reset_password")}
				</ButtonLoading>
			</Form>
		</>
	);
};

export default LostPasswordForm;
