import { TFunction } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { getDaysOfMonth, getHoursOfDay, getReportFilter } from "go-report/reports/services/report-service";
import { apiReport } from "../../services/Api/Report/apiReport";
import ReportShareUtils from "./ReportShareUtils";

const type = "order";

class ReportOrdersUtils {
	static getSelectedColumns = (selectedColumnType: string) => {
		const columnsFromLocalStorage = window.localStorage
			.getItem(`go_report.${selectedColumnType}.columns`)
			?.split(",");
		if (columnsFromLocalStorage) {
			return columnsFromLocalStorage;
		}
		return [
			"total_money",
			"net_total_money",
			"discount_money",
			"sub_total_money",
			"transaction_count",
			"average_money",
			"net_profit_money",
			"net_production_money",
		];
	};

	static getFilters = (t: TFunction, filtersParams?: string) => {
		return [
			{
				id: "order_number",
				name: t("common.word.order_number"),
				type: "text",
				options: ["e", "c"],
			},
			{
				id: "date_range",
				name: t("modules.item_group_report.field.date_range.title"),
				type: "date",
				options: ["bt"],
				isRemovable: false,
			},
			{
				id: "compare_date_range",
				name: t("modules.item_group_report.field.compare_date_range.title"),
				type: "date",
				options: ["bt"],
				isRemovable: true,
				visible: false,
			},
			{
				id: "created_at",
				name: t("modules.item_group_report.field.created_at.title"),
				type: "date",
			},
			{
				id: "closed_at",
				name: t("modules.item_group_report.field.closed_at.title"),
				type: "date",
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"EMPLOYEE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"TERMINAL",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "point_of_sale",
				name: t("common.word.point_of_sale"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"POINT_OF_SALE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"PAYMENT_METHOD",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
				conditionCustomLabelConfig: [{ condition: "u", label: t("common.word.unpaid_female") }],
			},
			{
				id: "client",
				name: t("common.word.client"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CLIENT",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "client_group",
				name: t("modules.item_group_report.field.client_groups.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CLIENT_GROUP",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "day_of_week",
				name: t("common.word.day_of_week"),
				type: "list",
				options: {
					1: t("enums.days.MONDAY", { ns: "lib" }),
					2: t("enums.days.TUESDAY", { ns: "lib" }),
					3: t("enums.days.WEDNESDAY", { ns: "lib" }),
					4: t("enums.days.THURSDAY", { ns: "lib" }),
					5: t("enums.days.FRIDAY", { ns: "lib" }),
					6: t("enums.days.SATURDAY", { ns: "lib" }),
					7: t("enums.days.SUNDAY", { ns: "lib" }),
				},
			},
			{
				id: "day_of_month",
				name: t("common.word.day_of_month"),
				type: "list",
				options: getDaysOfMonth(),
			},
			{
				id: "hour_of_day",
				name: t("modules.item_group_report.field.hours_of_day.title"),
				type: "list",
				options: getHoursOfDay(),
			},
			{
				id: "discount",
				name: t("common.word.discount"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"DISCOUNT",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
					translations: [
						{
							key: "FIXED_RATE",
							label: t("enums.discounts.type.FIXED_RATE"),
						},
						{
							key: "FIXED_PRICE",
							label: t("enums.discounts.type.FIXED_PRICE"),
						},
						{
							key: "AMOUNT",
							label: t("enums.discounts.type.AMOUNT"),
						},
						{
							key: "FUNCTION",
							label: (key: string) => ReportShareUtils.mapDiscountNames(key, t),
						},
					],
				},
			},
			{
				id: "discount_range",
				name: t("common.word.discount_value"),
				type: "number",
			},
			{
				id: "comment",
				name: t("common.word.comment"),
				type: "text",
			},
			{
				id: "room",
				name: t("modules.item_group_report.field.rooms.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ROOM",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "source",
				name: t("modules.item_group_report.field.source.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"SOURCE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "table",
				name: t("common.word.table"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"TABLE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "driver",
				name: t("modules.item_group_report.field.drivers.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"DRIVER",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "order_status",
				name: t("lib:common.word.status"),
				type: "list",
				options: {
					OPENED: t("enums.common.status.OPEN"),
					CLOSED: t("enums.common.status.CLOSED"),
					EXTERNAL: t("enums.common.status.EXTERNAL"),
					VOIDED: t("enums.common.status.VOIDED"),
					REMOVED: t("enums.common.status.REMOVED"),
				},
			},
			{
				id: "order_type",
				name: t("modules.item_group_report.field.order_types.title"),
				type: "list",
				options: {
					DINE_IN: t("enums.orders.types.DINE_IN"),
					DELIVERY: t("enums.orders.types.DELIVERY"),
					ROOM_SERVICE: t("enums.orders.types.ROOM_SERVICE"),
					PICK_UP: t("enums.orders.types.PICK_UP"),
				},
			},
			{
				id: "execution_at",
				name: t("modules.item_group_report.field.execution_at.title"),
				type: "date",
			},
			{
				id: "city",
				name: t("lib:common.address.city"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CITY",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "street",
				name: t("lib:common.address.street"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"STREET",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "build_nr",
				name: t("common.address.build_nr", { ns: "lib" }),
				type: "text",
			},
			{
				id: "delivery_zone",
				name: t("modules.report_bill.field.delivery_zone.title"),
				type: "text",
			},
		];
	};

	static getColumns = (t: TFunction) => {
		return [
			{
				id: "total_money",
				name: t("common.word.total_money"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "net_total_money",
				name: t("common.word.net_total_money"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "discount_money",
				name: t("common.word.discount_value"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "sub_total_money",
				name: t("modules.report_bill.field.sub_total_money.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "transaction_count",
				name: t("modules.report_bill.field.transaction_count.title"),
			},
			{
				id: "average_money",
				name: t("modules.report_bill.field.average_money.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "net_profit_money",
				name: t("common.word.net_profit_money"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "net_production_money",
				name: t("common.word.cost"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
		];
	};

	static getGroups = (t: TFunction) => {
		return [
			{
				id: "ORDER_NUMBER",
				name: t("components.reports.groups.ORDER_NUMBER"),
			},
			{
				id: "EMPLOYEE",
				name: t("components.reports.groups.EMPLOYEE"),
			},
			{
				id: "PAYMENT_METHOD",
				name: t("common.word.payment_method"),
			},
			{
				id: "POINT_OF_SALE",
				name: t("components.reports.groups.POINT_OF_SALE"),
			},
			{
				id: "TERMINAL",
				name: t("components.reports.groups.TERMINAL"),
			},
			{
				id: "DISCOUNT",
				name: t("components.reports.groups.DISCOUNT"),
			},
			{
				id: "ROOM",
				name: t("components.reports.groups.ROOM"),
			},
			{
				id: "TABLE",
				name: t("components.reports.groups.TABLE"),
			},
			{
				id: "COMMENT",
				name: t("components.reports.groups.COMMENT"),
			},
			{
				id: "DRIVER",
				name: t("components.reports.groups.DRIVER"),
			},
			{
				id: "CLIENT",
				name: t("components.reports.groups.CLIENT"),
			},
			{
				id: "CLIENT_GROUP",
				name: t("components.reports.groups.CLIENT_GROUP"),
			},
			{
				id: "NUMBER_OF_GUESTS",
				name: t("components.reports.groups.NUMBER_OF_GUESTS"),
			},
			{
				id: "ORDER_STATUS",
				name: t("components.reports.groups.ORDER_STATUS"),
			},
			{
				id: "ORDER_TYPE",
				name: t("components.reports.groups.ORDER_TYPE"),
			},
			{
				id: "SOURCE",
				name: t("lib:common.word.source"),
			},
			{
				id: "YEAR",
				name: t("components.reports.groups.YEAR"),
			},
			{
				id: "MONTH",
				name: t("components.reports.groups.MONTH"),
			},
			{
				id: "DAY_OF_MONTH",
				name: t("components.reports.groups.DAY_OF_MONTH"),
			},
			{
				id: "DAY_OF_WEEK",
				name: t("components.reports.groups.DAY_OF_WEEK"),
			},
			{
				id: "REPORT_DATE_YEAR",
				name: t("components.reports.groups.REPORT_DATE_YEAR"),
			},
			{
				id: "REPORT_DATE_MONTH",
				name: t("components.reports.groups.REPORT_DATE_MONTH"),
			},
			{
				id: "REPORT_DATE_DAY_OF_MONTH",
				name: t("components.reports.groups.REPORT_DATE_DAY_OF_MONTH"),
			},
			{
				id: "REPORT_DATE_DAY_OF_WEEK",
				name: t("components.reports.groups.REPORT_DATE_DAY_OF_WEEK"),
			},
			{
				id: "REPORT_DATE_HOUR_OF_DAY",
				name: t("components.reports.groups.REPORT_DATE_HOUR_OF_DAY"),
			},
			{
				id: "HOUR_OF_DAY",
				name: t("components.reports.groups.HOUR_OF_DAY"),
			},
			{
				id: "MINUTE_OF_HOUR",
				name: t("components.reports.groups.MINUTE_OF_HOUR"),
			},
			{
				id: "EXECUTION_AT_YEAR",
				name: t("components.reports.groups.EXECUTION_AT_YEAR"),
			},
			{
				id: "EXECUTION_AT_MONTH",
				name: t("components.reports.groups.EXECUTION_AT_MONTH"),
			},
			{
				id: "EXECUTION_AT_DAY_OF_MONTH",
				name: t("components.reports.groups.EXECUTION_AT_DAY_OF_MONTH"),
			},
			{
				id: "EXECUTION_AT_DAY_OF_WEEK",
				name: t("components.reports.groups.EXECUTION_AT_DAY_OF_WEEK"),
			},
			{
				id: "EXECUTION_AT_HOUR_OF_DAY",
				name: t("components.reports.groups.EXECUTION_AT_HOUR_OF_DAY"),
			},
			{
				id: "EXECUTION_AT_MINUTE_OF_HOUR",
				name: t("components.reports.groups.EXECUTION_AT_MINUTE_OF_HOUR"),
			},
			{
				id: "CREATED_AT_DATE",
				name: t("components.reports.groups.CREATED_AT_DATE"),
			},
			{
				id: "CREATED_AT_YEAR",
				name: t("components.reports.groups.CREATED_AT_YEAR"),
			},
			{
				id: "CREATED_AT_MONTH",
				name: t("components.reports.groups.CREATED_AT_MONTH"),
			},
			{
				id: "CREATED_AT_DAY_OF_MONTH",
				name: t("components.reports.groups.CREATED_AT_DAY_OF_MONTH"),
			},
			{
				id: "CREATED_AT_DAY_OF_WEEK",
				name: t("components.reports.groups.CREATED_AT_DAY_OF_WEEK"),
			},
			{
				id: "CREATED_AT_HOUR_OF_DAY",
				name: t("components.reports.groups.CREATED_AT_HOUR_OF_DAY"),
			},
			{
				id: "CREATED_AT_MINUTE_OF_HOUR",
				name: t("components.reports.groups.CREATED_AT_MINUTE_OF_HOUR"),
			},
			{
				id: "CREATED_AT_SECOND_OF_MINUTE",
				name: t("components.reports.groups.CREATED_AT_SECOND_OF_MINUTE"),
			},
			{
				id: "STREET",
				name: t("common.address.street", { ns: "lib" }),
			},
			{
				id: "CITY",
				name: t("common.address.city", { ns: "lib" }),
			},
			{
				id: "BUILD_NR",
				name: t("common.address.build_nr", { ns: "lib" }),
			},
			{
				id: "DELIVERY_ZONE",
				name: t("modules.report_bill.field.delivery_zone.title"),
			},
		];
	};
}

export default ReportOrdersUtils;
