import { ImageApi, ImageLinkApi } from "../Api/typesSecurity";

export enum UserActionTypes {
	SET = "@@user/SET",
	CLEAR = "@@user/CLEAR",
	ADD_ORGANIZATION = "@@user/ADD_ORGANIZATION",
	SET_ORGANIZATIONS = "@@user/SET_ORGANIZATIONS",
	UPDATE_ORGANIZATION = "@@user/UPDATE_ORGANIZATION",
	REMOVE_ORGANIZATION = "@@user/REMOVE_ORGANIZATION",
}

export type UserOrganizationState = {
	id: number;
	name: string;
	alias: string;
	company_name: string;
	company_id: string;
	status: string;
	applications?: any[];
	accept_rules: boolean;
	accept_privacy: boolean;
	accept_terms: boolean;
	category: string;
	description: string;
	tax_id_no: string;
	languages: string[];
	currency: string;
};

export type UserState = {
	id?: number | undefined;
	userId?: string | undefined;
	name?: string | undefined;
	email?: string | undefined;
	roles?: string[] | undefined;
	organizations?: UserOrganizationState[];
	partners_organizations?: UserOrganizationState[];
	admin?: boolean;
	verified?: boolean;
	accept_terms?: boolean;
	type?: string;
	accept_marketing_content?: boolean;
	avatar_link?: ImageLinkApi;
	avatar?: ImageApi;
	more?: Record<string, any>;
	partners?: string[];
};
