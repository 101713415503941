import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { apiOrganization } from "../../../../../../../../services/Api/Organization/apiOrganization";
import { ItemApi, ItemSuspensionApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	isShown: boolean;
	onHide: () => void;
	product: ItemApi;
	productIndex: number;
	handleBlockProduct: (index: number, product: ItemApi) => void;
}

const LiveOrdersBlockProductModal = ({
	isShown,
	onHide,
	product,
	productIndex,
	handleBlockProduct,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const form = useForm<ItemSuspensionApi>({
		criteriaMode: "all",
	});
	const {
		formState,
		formState: { errors },
		handleSubmit,
		setError,
		control,
		register,
	} = form;

	const onSubmit = handleSubmit(async (data: ItemSuspensionApi) => {
		setLoading(true);
		try {
			const updatedProduct = await apiOrganization.blockItem(product.id, data, {
				include: "category,item_group",
			});
			handleBlockProduct(productIndex, updatedProduct);
			onHide();
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={isShown} onHide={onHide}>
			<FormDirty formState={formState}>
				<Modal.Header closeButton>
					<Modal.Title>
						{`${t("modules.live_order.field.block_product.title")} "${product.name}"`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormDatePicker
						errors={errors}
						control={control}
						name="until"
						label={t("modules.live_order.field.until.title")}
					/>
					<FormInput
						name="reason"
						errors={errors}
						register={register}
						label={t("modules.live_order.field.reason.title")}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default LiveOrdersBlockProductModal;
