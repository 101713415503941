import React from "react";
import { AxiosResponse } from "axios";
import { TFunction } from "react-i18next";
import { ItemResponse, ListResponse } from "go-core/api/types";
import { MessageEventApi, MessageEventType, MessageResourceType } from "./types";

export interface MessageEventContextProps {
	all: (
		params?: Record<string, any>,
		options?: Record<string, any>
	) => Promise<AxiosResponse<ListResponse<MessageEventApi>>>;
	get: (id: string, params?: Record<string, any>) => Promise<AxiosResponse<ItemResponse<MessageEventApi>>>;
	getTypeOptions: () => Promise<string[]>;
}

export class MessageEventService {
	_resources: MessageResourceType[];
	_api: MessageEventContextProps;
	_typeTranslationPrefix: string;
	_t: TFunction;

	constructor(
		api: MessageEventContextProps,
		resources: MessageResourceType[],
		typeTranslationPrefix: string,
		t: TFunction
	) {
		this._api = api;
		this._resources = resources;
		this._typeTranslationPrefix = typeTranslationPrefix;
		this._t = t;
	}

	api(): MessageEventContextProps {
		return this._api;
	}

	resources(): MessageResourceType[] {
		return this._resources;
	}

	getResourceUrl(id: string, entityId: string | number): string | undefined {
		const type = this._resources.filter((x) => x.id === id)[0];
		if (!type?.link) return undefined;
		return `${type.link.startsWith("/") ? "" : "/"}${type.link}${type.link.endsWith("/") ? "" : "/"}${entityId}`;
	}

	getResourceTitle(id: string): string | undefined {
		const type = this._resources.filter((x) => x.id === id)[0];
		if (!type) return undefined;
		return type.title;
	}

	getMessageEventTypeTitle(messageEventType: string): string {
		const translation = this._t(`${this._typeTranslationPrefix}.${messageEventType.toUpperCase()}`);
		return translation.includes(".") ? messageEventType : translation;
	}
}

export const MessageEventContext = React.createContext<MessageEventService>({} as MessageEventService);
