import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatBoolean from "go-core/components/Formatters/FormatBoolean";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectUser } from "go-security/services/users/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ReactComponent as AlertSVG } from "../../../../../../../images/svg/alert.svg";
import { InvoiceApi } from "../../../../../../../services/Api/Organization/types";
import { apiReport } from "../../../../../../../services/Api/Report/apiReport";
import { api } from "../../../../../../../services/Api/api";
import ReportShareUtils from "../../../../../../../utils/reports/ReportShareUtils";
import SendInvoiceByEmailModal from "../../../../Organization/pages/Invoices/components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import {
	exportEInvoice,
	exportInvoicePdf,
	printInvoicePdf,
} from "../../../../Organization/pages/Invoices/services/exportInvoice";

interface ListState {
	resource?: Record<string, any>;
	setOrganizationsIds: (ids: number[]) => void;
}

const getOrganizationIdsEncodedInURL = (): string | null => {
	const currentURLParams = new URLSearchParams(location.search);
	const encodedFParam = currentURLParams.get("f");

	if (encodedFParam !== null) {
		const fParamDecoded = decodeURIComponent(escape(atob(encodedFParam)));
		const URLParamsAsArray = fParamDecoded.split(FILTER_SEPARATOR);
		const organizationIdsFilter = URLParamsAsArray.find((param) => param.includes("organization|e="));
		if (!organizationIdsFilter) return null;
		const indexOfFilterValuePart = organizationIdsFilter.indexOf("=");
		const selectedOrganizationIds = organizationIdsFilter.substring(indexOfFilterValuePart + 1);
		return selectedOrganizationIds;
	}
	return null;
};

const getLabelSearchSelectFilterValue = (fieldName: string, type: string, queryString?: string): string => {
	const organizationIds = getOrganizationIdsEncodedInURL();
	let filter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}type=${type}${FILTER_SEPARATOR}query=${queryString}${FILTER_SEPARATOR}field_name=${fieldName}`;
	if (organizationIds) {
		filter += `${FILTER_SEPARATOR}organization_id|e=${organizationIds}`;
	}

	return btoa(unescape(encodeURIComponent(`${filter}`)));
};

const listName = "INVOICE_GROUP";
const resourceType = "INVOICE_GROUP";

const List: FC<ListState> = ({ resource, setOrganizationsIds }) => {
	const user = useSelector(selectUser);
	const [items, setItems] = useState<InvoiceApi[]>([]);
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const { addSuccessFlash, addFlash } = useFlash();
	const confirmation = useConfirmation();
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const [invoiceToSendByEmail, setInvoiceToSendByEmail] = useState<InvoiceApi | undefined>(undefined);
	const [params, setParams] = useState<Record<string, any>>({});

	if (!resource) return null;
	const data = resource.read();

	const hasAtLeastOneIncompatibleTaxIdNo = (invoice: InvoiceApi) => {
		if (invoice.orders.length > 0) return invoice.orders.some((order) => order.tax_id_no_mismatch);
	};

	const removeInvoice = async (invoice: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoice.id, invoice.organization.id);
			const indexOfInvoiceInList = items.findIndex((item) => item.id === invoice.id);
			const newItems = [...items];
			newItems.splice(indexOfInvoiceInList, 1);
			setItems(newItems);

			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const getOrganizationIds = (filter: string) => {
		if (!filter) return [];
		const decodedFParts = atob(filter)?.split(FILTER_SEPARATOR);
		const orgIds = decodedFParts?.find((part) => part.includes("organization"))?.split("organization|e=");
		if (orgIds && orgIds[1] && orgIds[1] !== "u" && orgIds[1] !== "a") {
			return orgIds[1].split(FILTER_VALUE_SEPARATOR).map((item) => parseInt(item));
		}
		return [];
	};

	const getPreviewLink = (item: InvoiceApi) => {
		if (item.document_type === "INVOICE") {
			return `/${item.organization.id}/invoices/${item.id}`;
		}
		// if (item.document_type === "CORRECTION") {
		// 	return `/${item.organization.id}/invoices/${
		// 		item.linked_invoices.find((i) => i.document_type === "INVOICE" || i.document_type === "ADVANCE")?.id
		// 	}/corrections?correction_id=${item.id}`;
		// }
		if (item.document_type === "ADVANCE") {
			return `/${item.organization.id}/invoices/advances/${item.id}`;
		}

		return "";
	};

	let config = {
		fields: [
			{
				id: "organization",
				name: t("common.word.location", { ns: "lib" }),
				render: (item: InvoiceApi) => <Link to={getPreviewLink(item)}>{item?.organization?.name}</Link>,
				renderExport: (item: InvoiceApi) => item?.organization?.name,
				type: "list",
				listOptions: ReportShareUtils.getOrganizations(user.organizations),
				sortAlphabetically: true,
				hasDefaultOptions: false,
			},
			{
				id: "issued_at",
				name: t("modules.invoice.field.date_verb.title"),
				render: (item: InvoiceApi) => {
					return (
						<Link to={getPreviewLink(item)}>
							{FormatDate(item.dates?.issued_at, undefined, false, {
								year: "numeric",
								day: "2-digit",
								month: "2-digit",
							})}
						</Link>
					);
				},
				renderExport: (item: InvoiceApi) =>
					FormatDate(item.dates?.issued_at, undefined, false, {
						year: "numeric",
						day: "2-digit",
						month: "2-digit",
					}),
				type: "date",
			},
			{
				id: "document",
				name: t("modules.invoice.field.document.title"),
				render: (item: InvoiceApi) => {
					return (
						<Link to={getPreviewLink(item)}>{`${t(`enums.invoices.document_type.${item.document_type}`)} ${
							item.number
						}`}</Link>
					);
				},
				renderExport: (item: InvoiceApi) =>
					`${t(`enums.invoices.document_type.${item.document_type}`)} ${item.number}`,
			},
			{
				id: "document_type",
				name: t("modules.invoice.field.document_type.title"),
				render: (item: InvoiceApi) => `${t(`enums.invoices.document_type.${item.document_type}`)}`,
			},
			{
				id: "client",
				name: t("modules.invoice.field.client.title"),
				render: (item: InvoiceApi) => {
					if (item.recipient?.name) {
						return (
							<div className="d-flex flex-column">
								<div style={{ overflowY: "hidden", maxWidth: "200px", textWrap: "wrap" }}>
									{item.recipient.name}
								</div>
								<div className="d-flex align-items-center">
									<small className="text-muted">{item.recipient.tax_id_no}</small>
									{hasAtLeastOneIncompatibleTaxIdNo(item) && (
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip id={`tooltip-${item.id}`}>
													{t("modules.transaction.constraints.incompatible_tax_id_no")}
												</Tooltip>
											}
										>
											{({ ref, ...triggerHandler }) => (
												<AlertSVG className={"ms-1"} ref={ref} {...triggerHandler} />
											)}
										</OverlayTrigger>
									)}
								</div>
							</div>
						);
					}
				},
				renderExport: (item: InvoiceApi) => {
					return item.recipient.name;
				},
			},
			{
				id: "orders",
				name: t("modules.transaction.field.orders.title"),
				render: (item: InvoiceApi) => {
					return (
						<div className="d-flex flex-column">
							{item.orders?.map((order) => (
								<span key={`order_${order.number}`}>{order.number}</span>
							))}
						</div>
					);
				},
				renderExport: (item: InvoiceApi) => {
					return item.orders?.map((order) => order.number).join(", ");
				},
				disableSorting: true,
			},
			{
				id: "price_sum_net",
				name: t("common.word.amount_net"),
				render: (item: InvoiceApi) => {
					return FormatMoney(item.summary?.price_sum_net);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "price_sum_gross",
				name: t("common.word.amount_gross"),
				render: (item: InvoiceApi) => {
					return FormatMoney(item.summary?.price_sum_gross);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "sold_at",
				name: t("modules.invoice.field.sold_at.title"),
				render: (item: InvoiceApi) => {
					return FormatDate(item.dates?.sold_at, undefined, false, {
						year: "numeric",
						day: "2-digit",
						month: "2-digit",
					});
				},
				type: "date",
			},
			{
				id: "payment_due_date",
				name: t("modules.invoice.field.payment_due_date.title"),
				render: (item: InvoiceApi) => {
					return FormatDate(item.dates?.payment_due_date, undefined, false, {
						year: "numeric",
						day: "2-digit",
						month: "2-digit",
					});
				},
				type: "date",
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				disableSorting: true,
				render: (item: InvoiceApi) =>
					item.payments?.map((payment) => payment?.transaction?.payment_method_name).join(", "),
			},
			{
				id: "paid",
				name: t("modules.invoice.field.paid.list.title"),
				render: (item: InvoiceApi) => <FormatBoolean value={item.paid} />,
				renderExport: (item: InvoiceApi) => {
					return `${item.paid ? t("modules.invoice.field.paid.csv.title") : t("lib:common.word.no")}`;
				},
				type: "boolean",
			},
		],
		filters: [
			{
				id: "gross_amount",
				type: "number",
				name: t("common.word.amount_gross"),
			},
			{
				id: "net_amount",
				type: "number",
				name: t("common.word.amount_net"),
			},
			{
				id: "document_type",
				type: "list",
				name: t("modules.invoice.field.document_type.title"),
				listOptions: {
					INVOICE: t("enums.invoices.document_type.INVOICE"),
					CORRECTION: t("enums.invoices.document_type.CORRECTION"),
					ADVANCE: t("enums.invoices.document_type.ADVANCE"),
					PROFORMA: t("enums.invoices.document_type.PROFORMA"),
				},
				hasDefaultOptions: false,
			},
			{
				id: "recipient_name",
				type: "label_search_select",
				name: t("modules.invoice.field.recipient_name.title"),
				source: {
					request: (queryString?: string, requestParams?: Record<string, any>) => {
						const parsedFilterValues = getLabelSearchSelectFilterValue(
							"RECIPIENT_NAME",
							"invoice",
							queryString
						);
						return apiReport.getReportFilter(parsedFilterValues, {
							...requestParams,
						});
					},
				},
			},
			{
				id: "payment_method_name",
				type: "label_search_select",
				name: t("common.word.payment_method"),
				source: {
					request: (queryString?: string, requestParams?: Record<string, any>) => {
						const parsedFilterValues = getLabelSearchSelectFilterValue(
							"PAYMENT_METHOD",
							"invoice",
							queryString
						);
						return apiReport.getReportFilter(parsedFilterValues, {
							...requestParams,
						});
					},
				},
			},
			{
				id: "order_number",
				type: "label_search_select",
				name: t("common.word.order_number"),
				source: {
					request: (queryString?: string, requestParams?: Record<string, any>) => {
						const parsedFilterValues = getLabelSearchSelectFilterValue(
							"ORDER_NUMBER",
							"invoice",
							queryString
						);
						return apiReport.getReportFilter(parsedFilterValues, {
							...requestParams,
						});
					},
				},
			},
		],
		actions: [
			{
				name: `${t("lib:common.action.preview")}`,
				link: (item: InvoiceApi) => {
					return getPreviewLink(item);
				},
			},
			{
				name: t("common.action.export_pdf", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					exportInvoicePdf(item, t, undefined, item.organization.id);
				},
			},
			{
				name: t("common.action.print", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					printInvoicePdf(item, item.organization.id);
				},
			},
			{
				name: t("modules.invoice.field.e_invoice.title"),
				click: (item: InvoiceApi) => {
					exportEInvoice(item, t, item.organization.id);
				},
				visible: (item: InvoiceApi) => item.document_type !== "ADVANCE",
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					removeInvoice(item);
				},
			},
			{
				name: t("modules.invoice.action.send_invoice_by_e-mail.title"),
				click: (item: InvoiceApi) => {
					setShowSendInvoiceByEmailModal(true);
					setInvoiceToSendByEmail(item);
				},
			},
		],
		customPageSizes: ["20", "100", "1000"],
		selectedColumns: [
			"organization",
			"issued_at",
			"document",
			"document_type",
			"client",
			"orders",
			"price_sum_net",
			"price_sum_gross",
			"sold_at",
			"payment_due_date",
			"payment_method",
			"paid",
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setOrganizationsIds(getOrganizationIds(fetchParams.f));
			fetchParams.include =
				"summary,recipient,recipient_id,receiver,orders,payments,payments.transaction,organization";
			setParams(fetchParams);
			return api.getInvoices(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.invoice.field.export_config.title"),
			filename: t("modules.invoice.header.title"),
		},
		numberOfStickyColumnsAtTheStart: 3,
		doesIdColumnRedirectToPreviewPage: true,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{invoiceToSendByEmail && (
				<SendInvoiceByEmailModal
					externalOrganization={invoiceToSendByEmail.organization.id}
					isShown={showSendInvoiceByEmailModal}
					onHide={() => {
						setShowSendInvoiceByEmailModal(false);
						setInvoiceToSendByEmail(undefined);
					}}
					invoice={invoiceToSendByEmail}
				/>
			)}
			<ListData data={items} config={config} onFetch={setItems} />
		</>
	);
};

const UserInvoicesIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const [organizationsIds, setOrganizationsIds] = useState<number[]>([]);
	const organizationIdsAsString = JSON.stringify(organizationsIds);
	const customFieldResourceTypes = [
		{
			type: "INVOICE",
			name: t(`enums.custom_fields.resources.INVOICE`),
		},
	];
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.header.title"));
		const params: Record<string, any> = {};
		if (organizationsIds && organizationsIds.length > 0) {
			params.organization = organizationsIds.join(FILTER_VALUE_SEPARATOR);
		}
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes, params)));
	}, [organizationIdsAsString]);

	return (
		<>
			{!isMobile && <Header title={t("modules.invoice.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} setOrganizationsIds={setOrganizationsIds} />
			</Suspense>
		</>
	);
};

export default UserInvoicesIndexPage;
