import React, { FC, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmptyList from "go-core/components/EmptyList";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import GroupInvoiceModal from "../GroupInvoiceModal/GroupInvoiceModal";

interface Props {
	invoice: InvoiceApi;
	handleUpdateInvoice?: (data: InvoiceApi) => void;
}

const InvoicePositionsCard: FC<Props> = ({ invoice, handleUpdateInvoice }) => {
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();
	const nestedCellStyles = {
		borderTop: "none",
		verticalAlign: "top",
		paddingTop: 0,
		paddingBottom: "20px",
	};

	const labelStyles = {
		fontWeight: 500,
		fontSize: "12px",
		color: "#6C757D",
		marginBottom: "16px",
		maxWidth: "60px",
	};

	const verticalTop = {
		verticalAlign: "top",
		paddingTop: "24px",
	};
	const organization = useSelector(selectOrganization);
	const paidMoney = invoice.payments
		.filter((f) => f.paid)
		.reduce((acc, val) => acc + (val.price_paid?.amount ? val.price_paid?.amount : 0), 0);
	const unPaidMoney = invoice.summary.price_sum_gross.amount
		? invoice.summary.price_sum_gross?.amount - paidMoney
		: 0;
	const currency = organization?.currency || "";

	return (
		<Card>
			<Card.Body>
				<div className={"action-header"}>
					<h5>{t("modules.invoice.field.positions.title")}</h5>
					{invoice.document_type === "INVOICE" && (
						<Button variant={"add"} onClick={() => setShowModal(true)}>
							{t("common.action.group")}
						</Button>
					)}
				</div>
				{invoice.items && invoice.items.length > 0 ? (
					<div className={"table-responsive"}>
						<table className={"table table-preview"}>
							<thead>
								<tr>
									<th style={{ width: "30%" }}>{t("modules.invoice.field.name.title")}</th>
									<th style={{ width: "5%" }}>{t("modules.invoice.field.pkwiu.title")}</th>
									<th style={{ width: "5%" }}>{t("modules.invoice.field.gtu.title")}</th>
									<th className="w-10">
										{`${
											invoice.type === "GROSS"
												? t("modules.invoice.field.unit_price_gross.title")
												: t("modules.invoice.field.unit_price_net.title")
										} ${t("modules.invoice.field.before_discount.title")}`}
									</th>
									<th className="w-10">
										{invoice.type === "GROSS"
											? t("modules.invoice.field.unit_price_gross.title")
											: t("modules.invoice.field.unit_price_net.title")}
									</th>
									<th className={"w-1"}>{t("modules.invoice.field.tax_value.title")}</th>
									<th>{t("common.word.quantity")}</th>
									<th>{t("modules.invoice.field.measure.title")}</th>
									<th className={"w-1"}>
										{invoice.type === "GROSS" ? (
											<>
												{t("common.word.value")}
												<br /> {t("modules.invoice.field.gross.title")}
											</>
										) : (
											<>
												{t("common.word.value")}
												<br /> {t("modules.invoice.field.net.title")}
											</>
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{invoice.items.map((item, index) => {
									return (
										<tr key={index}>
											<td>{item.name}</td>
											<td>{item.pkwiu}</td>
											<td>{item.gtu}</td>
											<td>{FormatMoney(item?.sub_unit_price)}</td>
											<td>{FormatMoney(item.price)}</td>
											<td>{item.tax?.name}</td>
											<td>{item.quantity}</td>
											<td>{item.volume}</td>
											<td>{FormatMoney(item.price_sum)}</td>
										</tr>
									);
								})}
								<tr className={"summary"}>
									<td colSpan={4} style={verticalTop}>
										<div className={"d-flex flex-column"}>
											<span style={{ marginBottom: "30px" }}>
												<strong>{t("common.word.summary", { ns: "lib" })}</strong>
											</span>
											<span>{t("modules.invoice.field.summary_taxes.title")}</span>
										</div>
									</td>
									<td style={verticalTop}>
										<div className={"d-flex flex-column"}>
											<span style={labelStyles}>
												{t("common.word.value")}
												<br /> {t("modules.invoice.field.net.title")}
											</span>
											{invoice.summary.taxes.map((tax, index) => {
												return (
													<div key={index} className={"mb-1 nowrap"}>
														{FormatMoney(tax.price_net)}
													</div>
												);
											})}
										</div>
									</td>
									<td style={verticalTop}>
										<span style={labelStyles}>{t("modules.invoice.field.tax_value.title")}</span>
										{invoice.summary.taxes.map((tax, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{tax.tax_name}
												</div>
											);
										})}
									</td>
									<td style={verticalTop} className={"w-1"}>
										<span style={{ ...labelStyles, maxWidth: "40px" }}>
											{t("modules.invoice.field.tax_amount.title")}
										</span>
										{invoice.summary.taxes.map((tax, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{FormatMoney(tax.price_tax)}
												</div>
											);
										})}
									</td>
									<td />
									<td style={verticalTop}>
										<span style={{ ...labelStyles, maxWidth: "100%", marginLeft: "5px" }}>
											{t("common.word.value")}
											<br /> {t("modules.invoice.field.gross.title")}
										</span>
										{invoice.summary.taxes.map((tax, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{FormatMoney(tax.price_gross)}
												</div>
											);
										})}
									</td>
								</tr>
								<tr>
									<td className={"nowrap"} style={nestedCellStyles} colSpan={4}>
										<strong>{t("common.word.altogether")}</strong>
									</td>
									<td className={"nowrap"} style={nestedCellStyles}>
										<strong>{FormatMoney(invoice.summary.price_sum_net)}</strong>
									</td>
									<td className={"nowrap"} style={nestedCellStyles} />
									<td className={"nowrap"} style={nestedCellStyles}>
										<strong>{FormatMoney(invoice.summary.price_sum_tax)}</strong>
									</td>
									<td style={nestedCellStyles} />
									<td className={"nowrap"} style={nestedCellStyles}>
										<strong>{FormatMoney(invoice.summary.price_sum_gross)}</strong>
									</td>
								</tr>
								<tr>
									<td colSpan={8} className={"pb-0"} style={{ paddingTop: "20px" }}>
										<div className={"d-flex flex-column"}>
											<span className={"mb-1"}>
												{t("modules.invoice.field.comment.title")}:{" "}
												<span className={"text-muted"}>
													{invoice.comment ? invoice.comment : t("common.word.lack")}
												</span>
											</span>
											<span className={"mb-1 nowrap"}>
												{t("modules.invoice.field.paid.title")}
											</span>
											<span className={"mb-1 nowrap"}>
												{t("modules.invoice.field.to_pay.title")}
											</span>
										</div>
									</td>
									<td className={"pb-0"} style={{ paddingTop: "20px" }}>
										<div className={"d-flex flex-column"}>
											<span className={"mb-1"}>&nbsp;</span>
											<span className={"mb-1 nowrap"}>
												{invoice.payments.length === 0
													? FormatMoney({
															amount: 0,
															currency,
													  })
													: FormatMoney({
															amount: paidMoney,
															currency,
													  })}
											</span>
											<span className={"mb-1 nowrap"}>
												{invoice.payments.length === 0
													? FormatMoney(invoice.summary.price_sum_gross)
													: FormatMoney({
															amount: unPaidMoney,
															currency,
													  })}
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				) : (
					<EmptyList description={t("modules.invoice.field.empty.title")} />
				)}
			</Card.Body>
			{showModal && (
				<GroupInvoiceModal
					isShown={showModal}
					onHide={() => setShowModal(false)}
					invoice={invoice}
					handleUpdateInvoice={handleUpdateInvoice}
				/>
			)}
		</Card>
	);
};
export default InvoicePositionsCard;
