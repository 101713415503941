import { AxiosResponse } from "axios";
import { TFunction } from "react-i18next";
import { InvoiceApi, OrderInvoiceApi } from "../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../services/Api/api";

const downloadInvoiceFileToPc = async (downloadData: () => Promise<AxiosResponse<any, any>>, fileName: string) => {
	const res = await downloadData();
	const url = window.URL.createObjectURL(new Blob([res.data]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
};

export const exportInvoicePdf = async (
	invoice: OrderInvoiceApi | InvoiceApi,
	t: TFunction,
	params?: Record<string, any>,
	externalOrg?: number
): Promise<void> => {
	const downloadData = () => api.organization().exportOrderInvoice(invoice.id, externalOrg, params);
	const fileName = `${t(`enums.invoices.document_type.${invoice.document_type}`)}_${invoice.number}.pdf`;
	await downloadInvoiceFileToPc(downloadData, fileName);
};

export const generateInvoicePreviewPdfLink = async (
	invoice: OrderInvoiceApi | InvoiceApi,
	externalOrg?: number
): Promise<string> => {
	const res = await api.organization().exportOrderInvoicePreview(invoice.id, externalOrg);
	return window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
};

export const exportInvoicesPdf = async (
	invoices: OrderInvoiceApi[] | InvoiceApi[],
	t: TFunction,
	params?: Record<string, any>
): Promise<void> => {
	const requestParams = {
		id: invoices.map((item) => item.id).join(","),
		include: params?.include,
	};
	const downloadData = () => api.organization().downloadInvoices(requestParams);
	const fileName = invoices.length > 1 ? `Faktury.zip` : `Faktura_${invoices[0].number}.pdf`;
	await downloadInvoiceFileToPc(downloadData, fileName);
};

export const printInvoicePdf = async (invoice: OrderInvoiceApi | InvoiceApi, externalOrg?: number): Promise<void> => {
	const res = await api.organization().exportOrderInvoice(invoice.id, externalOrg);
	const fileURL = URL.createObjectURL(res.data);
	const a: HTMLAnchorElement = document.createElement("a");
	a.href = fileURL;
	a.target = "_blank";
	document.body.appendChild(a);
	a.click();
};

export const exportEInvoice = async (invoice: InvoiceApi, t: TFunction, externalOrg?: number): Promise<void> => {
	const downloadData = () => api.organization().exportEInvoice(invoice.id, externalOrg);
	const fileName = `${t(`common.word.e-invoice`)}_${invoice.number}.xml`;
	await downloadInvoiceFileToPc(downloadData, fileName);
};
