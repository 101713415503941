import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import JobPreview from "go-component/components/Job/JobPreview";
import { JobContext } from "go-component/components/Job/services/context";
import { JobApi } from "go-component/components/Job/services/types";
import { camelToSnakeCase } from "go-component/utils/componentUtils";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";

interface MatchParams {
	job_id: string;
}

const OrganizationLogsJobsEditPage = (): JSX.Element => {
	const [job, setJob] = useState<JobApi | undefined>();
	const jobService = React.useContext(JobContext);
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { job_id } = useParams<MatchParams>();
	const { t } = useTranslation();

	useEffect(() => {
		onFetchJob();
	}, []);

	const onFetchJob = async () => {
		try {
			const params: Record<string, any> = {};
			params.include = "settings,steps,steps.settings";
			const res = await jobService.api().get(job_id, params);
			setJob(res.data.data);
			handleChangeTabTitle(`${res.data.data.name} | ${t("lib:go_component.job.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!job) {
		return <LoadingContainer />;
	}

	return <JobPreview job={job} />;
};

export default OrganizationLogsJobsEditPage;
