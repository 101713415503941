import React, { useRef, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import { SaveAndAddButtons, TranslationType } from "go-core/components/SaveAndAddButtons";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SaveAndAddModal } from "../../../../../../../../../components/Common/SaveAndAddItem";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	ClientApi,
	ClientGroupApi,
	SaveAndAddActionType,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../services/Api/types";
import ClientGroupModalForm from "../../../../../components/ModalForms/ClientGroupModalForm";

interface Props {
	client: ClientApi;
	setClient: (client: ClientApi) => void;
}

const formEmptyValues = {
	name: "",
	tax_id_no: "",
	card_code: "",
	card_number: "",
	address_street: "",
	address_build_nr: "",
	address_flat_nr: "",
	address_zip_code: "",
	address_city: "",
	address_country: "",
	contact_email: "",
	contact_other: "",
	nin_no: "",
	id_card_number: "",
	id_card_issuing_authority: "",
	avatar: undefined,
	avatar_link: undefined,
	birthday: undefined,
	client_group_id: undefined,
	card_expires_at: undefined,
	color: undefined,
	contact_phone_number: undefined,
	id_card_issued_at: undefined,
	label: undefined,
};

const ClientForm = (props: Props): JSX.Element => {
	const [showClientGroupModal, setShowClientGroupModal] = useState(false);
	const [saveAndAddModalActive, setSaveAndAddModalActive] = useState<ClientApi | undefined>(undefined);
	const [searchingClient, setSearchingClient] = useState(false);
	const [newAvatar, setNewAvatar] = useState<string | undefined>(props.client.avatar_link?.default_link);
	const [loading, setLoading] = useState(false);
	const [saveAndAddLoading, setSaveAndAddLoading] = useState(false);
	const [newClientGroup, setNewClientGroup] = useState<ClientGroupApi>();
	const [clientGroup, setClientGroup] = useState<SearchSelectApi>({
		label: props.client?.client_group?.name ?? "",
		id: props.client?.client_group?.id ?? "",
	});
	const saveAndAddMode = useRef(false);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<ClientApi>({
		criteriaMode: "all",
		defaultValues: props.client,
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
		reset,
		setValue,
		formState,
		getValues,
		clearErrors,
		watch,
	} = form;

	const onSubmit = handleSubmit(async (data: ClientApi) => {
		saveAndAddMode.current ? setSaveAndAddLoading(true) : setLoading(true);
		data.id = props.client.id;
		if (getValues("avatar.image_data") || !newAvatar) {
			data.avatar_link = undefined;
		}
		try {
			if (data.id) {
				const params: Record<string, any> = {};
				params.include = "client_group";
				const res = await api.organization().updateClient(data, params);
				props.client.avatar_link = res.avatar_link;
				if (saveAndAddMode.current) {
					history.push(`/${organization.id}/clients/new`);
				}
				reset({
					...res,
					contact_phone_number: res?.contact_phone_number ? res.contact_phone_number : "",
				});
				props.setClient({
					...res,
					contact_phone_number: res?.contact_phone_number ? res.contact_phone_number : "",
				});
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createClient(data);
				if (saveAndAddMode.current) {
					setSaveAndAddModalActive(res);
				} else history.push(`/${organization.id}/clients/${res.id}/edit`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setLoading(false);
			setSaveAndAddLoading(false);
			saveAndAddMode.current = false;
		}
	});

	const handleImageChange = (imageBase: string | undefined) => {
		setNewAvatar(imageBase);
	};
	const searchGroups = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientGroupsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onClientGroupCreate = (inputValue: string) => {
		setNewClientGroup({ name: inputValue } as ClientGroupApi);
		setShowClientGroupModal(true);
	};

	const handleClientGroupSave = (clientGroup: ClientGroupApi) => {
		setValue("client_group_id", clientGroup.id);
		setShowClientGroupModal(false);
		setClientGroup({
			label: clientGroup.name,
			id: clientGroup.id,
		});
	};

	const searchClient = async () => {
		const taxIdNo = getValues("tax_id_no");
		setSearchingClient(true);
		try {
			const res = await api.organization().getClientDataByTaxIdNo(taxIdNo);
			setValue("address_city", res.address_city);
			setValue("address_build_nr", res.address_build_nr);
			setValue("name", res.name);
			setValue("address_country", res.address_country);
			setValue("address_street", res.address_street);
			setValue("address_zip_code", res.address_zip_code);
			setValue("address_flat_nr", res.address_flat_nr);
			setSearchingClient(false);
			clearErrors("tax_id_no");
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setSearchingClient(false);
	};

	const onHideSaveAndAddModal = () => {
		saveAndAddMode.current = false;
		setSaveAndAddLoading(false);
		setSaveAndAddModalActive(undefined);
	};

	const saveAndAddItemHandler = () => {
		saveAndAddMode.current = true;
		onSubmit();
	};

	const onSaveAndAdd = async (action: SaveAndAddActionType, itemId?: string) => {
		if (action === SaveAndAddActionType.ADD) {
			reset({ ...formEmptyValues, avatar_link: watch("avatar_link") ? undefined : {} });
		} else {
			if (!itemId) return;
			try {
				const params: Record<string, any> = { include: "client_group" };
				const res = await apiOrganization.getClient(Number(itemId), params);
				reset({ ...formEmptyValues, avatar_link: res.avatar_link });
				reset({
					...props.client,
					...res,
					name: `${t("common.word.copy")} ${res.name}`,
					card_number: "",
					card_code: "",
					contact_email: "",
					contact_phone_number: undefined,
					address_street: "",
					address_build_nr: "",
					address_flat_nr: "",
					address_zip_code: "",
					address_city: "",
					address_country: "",
					card_expires_at: undefined,
					contact_other: "",
					nin_no: "",
					birthday: undefined,
					id_card_number: "",
					id_card_issued_at: undefined,
					id_card_issuing_authority: "",
				});
				setClientGroup({ id: Number(res.client_group?.id), label: res.client_group?.name || "" });
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
		setSaveAndAddLoading(false);
		setSaveAndAddModalActive(undefined);
	};

	return (
		<FormDirty
			key="client-form"
			formState={formState}
			loading={loading}
			noValidate
			onSubmit={onSubmit}
			buttonSubmitCustom={
				<SaveAndAddButtons
					saveLoading={loading}
					saveAndAddLoading={saveAndAddLoading}
					onSaveAndAddClick={saveAndAddItemHandler}
					translationType={TranslationType.MALE}
				/>
			}
		>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<div className="row">
					<div className="col-md-10">
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.word.name", { ns: "lib" })}
									register={register}
									name="name"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<InputGroup className={"form-input-group"}>
									<FormInput
										name="tax_id_no"
										label={t("common.word.tax_id_no.title", { ns: "lib" })}
										register={register}
										errors={errors}
									/>
									<div className="input-group-append">
										<ButtonLoading
											loading={searchingClient}
											style={{ height: "31px", border: "1px solid #ced4da" }}
											onClick={() => searchClient()}
											variant={"primary"}
										>
											{t("common.word.tax_id_no.search", { ns: "lib" })}
										</ButtonLoading>
									</div>
								</InputGroup>
							</div>
							<div className="col-md-6">
								<FormSelectGroup
									label={t("modules.client.field.client_group.title")}
									name="client_group_id"
									errors={errors}
									getOptionLabel={(option) => option.label}
									path={`/${organization.id}/clients/client-groups/`}
									onCreateOption={onClientGroupCreate}
									onChange={(_, fullObj) => setClientGroup(fullObj)}
									getOptionValue={(option) => option.id}
									defaultValue={{
										label: clientGroup?.label,
										id: clientGroup?.id,
									}}
									loadOptions={searchGroups}
									control={control}
									data-testid="client_group_id"
								/>
								{showClientGroupModal && (
									<ClientGroupModalForm
										clientGroup={newClientGroup}
										handleClose={() => setShowClientGroupModal(false)}
										handleSave={handleClientGroupSave}
									/>
								)}
							</div>
						</div>
					</div>
					<div className={"col-md-2"} style={{ minWidth: "150px" }}>
						<ImageColorForm
							form={form}
							handleSave={handleImageChange}
							prefix="avatar"
							mode="IMAGE"
							placeholder="/graphics/user-avatar.svg"
							data={props.client}
							resourceLink={props.client.avatar_link || watch("avatar_link")}
							resourceData={props.client.avatar || newAvatar}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.card")}</h5>
				<div className="row">
					<div className="col-md-10">
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("modules.client.field.card_code.title")}
									register={register}
									name="card_code"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("modules.client.field.card_number.title")}
									register={register}
									name="card_number"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormDatePicker
									label={t("modules.client.field.card_expiry_date.title")}
									control={control}
									name="card_expires_at"
									errors={errors}
									allowResetDate
								/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.address", { ns: "lib" })}</h5>
				<div className="row">
					<div className="col-md-6">
						<FormInput
							label={t("common.address.street", { ns: "lib" })}
							register={register}
							name="address_street"
							errors={errors}
						/>
					</div>
					<div className="col-md-3">
						<FormInput
							label={t("common.address.build_nr", { ns: "lib" })}
							register={register}
							name="address_build_nr"
							errors={errors}
						/>
					</div>
					<div className="col-md-3">
						<FormInput
							label={t("common.address.flat_nr", { ns: "lib" })}
							register={register}
							name="address_flat_nr"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.address.zip_code", { ns: "lib" })}
							register={register}
							name="address_zip_code"
							errors={errors}
						/>
					</div>

					<div className="col-md-6">
						<FormInput
							label={t("common.address.city", { ns: "lib" })}
							register={register}
							name="address_city"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.address.country", { ns: "lib" })}
							register={register}
							name="address_country"
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("modules.client.field.contact.title")}</h5>
				<div className="row">
					<div className="col-md-6">
						<FormNumberInput
							label={t("common.contact.phone.label")}
							control={control}
							name="contact_phone_number"
							suffix=""
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.word.email", { ns: "lib" })}
							register={register}
							name="contact_email"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.contact.others.label")}
							register={register}
							name="contact_other"
							as={"textarea"}
							rows={3}
							style={{ minHeight: "calc(2 * 1px + 2 * 6px + 50.391px)" }}
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("modules.client.field.personal_data.title")}</h5>
				<div className="row">
					<div className="col-md-6">
						<FormInput
							label={t("modules.client.field.nin_no_value.title")}
							register={register}
							name="nin_no"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormDatePicker
							label={t("modules.client.field.birth_date.title")}
							control={control}
							name="birthday"
							timePicker={false}
							errors={errors}
							allowResetDate
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("modules.client.field.id_card_number.title")}
							register={register}
							name="id_card_number"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormDatePicker
							label={t("modules.client.field.id_card_date_of_issue.title")}
							timePicker={false}
							control={control}
							name="id_card_issued_at"
							errors={errors}
							allowResetDate
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("modules.client.field.id_card_issuing_authority.title")}
							register={register}
							name="id_card_issuing_authority"
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			{saveAndAddModalActive && (
				<SaveAndAddModal
					onHide={onHideSaveAndAddModal}
					onSaveAndAdd={onSaveAndAdd}
					onFetchItems={api.organization().getClientsSearchSelect}
					itemValue={saveAndAddModalActive}
					translations={{
						copyItemLabel: t("modules.client.field.copy_client.title"),
						copyItemDescription: t("modules.client.field.copy_client.description.title"),
						newItemLabel: t("modules.client.field.new_client.title"),
						newItemDescription: t("modules.client.field.new_client.description.title"),
						chooseItem: t("modules.client.action.choose_client.title"),
						copyItemHeader: t("modules.client.action.create_client.title"),
					}}
				/>
			)}
		</FormDirty>
	);
};
export default ClientForm;

// t("constraints.contact_not_exists");
