import { MoneyApi } from "go-core/api/types";
import { ReactComponent as DropdownGreen } from "../../../../images/svg/dashboard/dropdown-green.svg";
import { ReactComponent as DropdownRed } from "../../../../images/svg/dashboard/dropdown-red.svg";

interface Props {
	currentPeriodValue: number | MoneyApi;
	lastPeriodValue: number | MoneyApi;
}

const getValueAsNumber = (value: number | MoneyApi) => {
	if (typeof value === "number") {
		return value;
	}

	return value?.amount || 0;
};

export const AggregateValuePercentageChange = ({ currentPeriodValue, lastPeriodValue }: Props): JSX.Element | null => {
	const fractionalNumerator = getValueAsNumber(currentPeriodValue);
	const denominator = getValueAsNumber(lastPeriodValue);

	const valueChangeRatio = denominator === 0 ? 0 : Math.round((fractionalNumerator / denominator) * 100 * 100) / 100;

	const percentageValueChange = valueChangeRatio === 0 ? 0 : Math.round((valueChangeRatio - 100) * 100) / 100;

	const percentageValueSpanClasses = `percentage-value ${percentageValueChange > 0 ? "text-success" : "text-danger"}`;

	if (percentageValueChange === 0) return null;

	return (
		<div className="d-flex align-items-center">
			{percentageValueChange > 0 ? <DropdownGreen /> : <DropdownRed />}
			<span className={percentageValueSpanClasses}>{`${percentageValueChange}%`}</span>
		</div>
	);
};
