import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import CustomFieldsForm from "go-app/components/CustomFields/CustomFieldsForm";
import { CustomValidationError } from "go-form/services/types";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<InvoiceApi>;
	invoice: InvoiceApi;
	customFieldsConfig: CustomFieldTemplateApi[];
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
}

const InvoiceFormAdvanced: FC<Props> = ({ form, invoice, customFieldsConfig, setErrors }) => {
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
	} = form;
	return (
		<fieldset className={"form-group"}>
			<h5>{t("common.word.advanced", { ns: "lib" })}</h5>
			<FormInput
				label={t("modules.invoice.field.comment.title")}
				register={register}
				name="comment"
				errors={errors}
			/>
			<CustomFieldsForm
				form={form}
				customFields={invoice.custom_fields}
				customFieldsConfig={customFieldsConfig}
				setErrors={setErrors}
			/>
		</fieldset>
	);
};
export default InvoiceFormAdvanced;
