import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import FormatResourceStatusLabel from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import { getComponentStatusClassName } from "go-component/Common/FormatStatus";
import { OrderFiscalizationStatus } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	status: OrderFiscalizationStatus;
	isEReceipt: boolean;
}

const getStatusTranslationKey = (status: OrderFiscalizationStatus, isEReceipt: boolean, t: TFunction) => {
	if (isEReceipt) {
		switch (status) {
			case OrderFiscalizationStatus.FISCALIZED:
				return t("enums.orders.fiscalization.ereceipt.FISCALIZED");
			case OrderFiscalizationStatus.FISCALIZED_RECEIVED:
				return t("enums.orders.fiscalization.ereceipt.FISCALIZED_RECEIVED");
			case OrderFiscalizationStatus.NOT_FISCALIZED:
				return t("enums.orders.fiscalization.NOT_FISCALIZED");
			case OrderFiscalizationStatus.UNKNOWN:
				return t("enums.orders.fiscalization.UNKNOWN");
			case OrderFiscalizationStatus.ERROR:
				return t("enums.orders.fiscalization.ERROR");
		}
	} else {
		switch (status) {
			case OrderFiscalizationStatus.FISCALIZED:
				return t("enums.orders.fiscalization.FISCALIZED");
			case OrderFiscalizationStatus.FISCALIZED_RECEIVED:
				return t("enums.orders.fiscalization.FISCALIZED_RECEIVED");
			case OrderFiscalizationStatus.NOT_FISCALIZED:
				return t("enums.orders.fiscalization.NOT_FISCALIZED");
			case OrderFiscalizationStatus.UNKNOWN:
				return t("enums.orders.fiscalization.UNKNOWN");
			case OrderFiscalizationStatus.ERROR:
				return t("enums.orders.fiscalization.ERROR");
		}
	}
};

const getStatusClassName = (status: string, isEReceipt: boolean) => {
	const labelClass = getComponentStatusClassName(status);
	if (labelClass) return labelClass;

	if (isEReceipt) {
		switch (status) {
			case OrderFiscalizationStatus.FISCALIZED_RECEIVED:
				return "success";
			case OrderFiscalizationStatus.FISCALIZED:
				return "warning";
			case OrderFiscalizationStatus.NOT_FISCALIZED:
				return "blue";
			case OrderFiscalizationStatus.UNKNOWN:
				return "secondary";
			default:
				return "primary";
		}
	} else {
		switch (status) {
			case OrderFiscalizationStatus.FISCALIZED_RECEIVED:
			case OrderFiscalizationStatus.FISCALIZED:
				return "success";
			case OrderFiscalizationStatus.NOT_FISCALIZED:
				return "blue";
			case OrderFiscalizationStatus.UNKNOWN:
				return "secondary";
			default:
				return "primary";
		}
	}
};

const FiscalizationStatus = ({ status, isEReceipt }: Props): JSX.Element => {
	const { t } = useTranslation();
	const className = getStatusClassName(status, isEReceipt);
	const translation = t(getStatusTranslationKey(status, isEReceipt, t), { ns: ["translation", "lib"] });

	return <FormatResourceStatusLabel status={status} className={className} translation={translation} />;
};

export default FiscalizationStatus;
