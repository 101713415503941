import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import EmptyList from "go-core/components/EmptyList";
import FormatBoolean, { convertBoolean } from "go-core/components/Formatters/FormatBoolean";
import FormatDate, { FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { StornReportApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import DepartmentReportModal from "../../components/DepartmentReportModal";
import { RebuildReportModal } from "../../components/RebuildReportModal";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "STORNO_REPORT";
const resourceType = "STORNO_REPORT";

const List: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const [params, setParams] = useState<Record<string, any>>({});
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<StornReportApi[]>([]);
	const segmentContext = useContext(SegmentContext);
	const [summaryData, setSummaryData] = useState<Record<string, any>>({});
	const [showDepartmentReportModal, setShowDepartmentReportModal] = useState(false);
	const [showRebuildReportModal, setShowRebuildReportModal] = useState(false);
	const mobileActions: MobileActionProps[] = [
		{
			title: t("modules.report.action.rebuild.title"),
			action: () => setShowRebuildReportModal(true),
		},
		{
			title: t("modules.report.field.department_report.title"),
			action: () => setShowDepartmentReportModal(true),
		},
	];

	if (!resource) return null;
	const data = resource.read();
	let config = {
		fields: [
			{
				id: "created_at",
				name: t("modules.storno_report.field.created_at.title"),
				type: "date" as FilterType,
				render: (item: StornReportApi) => {
					return FormatDate(item.created_at);
				},
			},
			{
				id: "storno_at",
				name: t("modules.storno_report.field.storno_at.title"),
				type: "date" as FilterType,
				render: (item: StornReportApi) => {
					return FormatDate(item.storno_at);
				},
			},
			{
				id: "number",
				name: t("common.word.order_number"),
				type: "text" as FilterType,
				options: ["e", "c"],
				render: (item: StornReportApi) => {
					return (
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/sales/orders/${item.order_id}`}
						>
							#{item.order_number}
						</Link>
					);
				},
				renderExport: (item: StornReportApi) => {
					return `#${item.order_number}`;
				},
			},
			{
				id: "item",
				name: t("common.word.item_group"),
				type: "search_select" as FilterType,
				render: (item: StornReportApi) => {
					return item.item_group_id ? (
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/menu/item_groups/${item.item_group_id}`}
						>
							{item.item_name}
						</Link>
					) : (
						item.item_name
					);
				},
				renderExport: (item: StornReportApi) => {
					return item.item_name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select" as FilterType,
				render: (item: StornReportApi) => {
					return (
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/employees/${item.employee_id}`}
						>
							{item.employee_name}
						</Link>
					);
				},
				renderExport: (item: StornReportApi) => {
					return item.employee_name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "saved",
				name: t("modules.storno_report.field.saved.title"),
				type: "boolean" as FilterType,
				render: (item: StornReportApi) => <FormatBoolean value={item.saved} />,
				renderExport: (item: StornReportApi) => convertBoolean(item.saved, t),
			},
			{
				id: "comment",
				name: t("modules.storno_report.field.comment.title"),
				type: "text" as FilterType,
			},
			{
				id: "total_price",
				name: t("common.word.amount"),
				type: "number" as FilterType,
				render: (item: StornReportApi) => {
					return FormatMoney(item.total_price);
				},
			} as ListConfigField,
		],
		filters: [
			{
				id: "report",
				name: t("common.word.pos_report"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
					render: (item: StornReportApi) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at
						)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "order_status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					OPENED: t("enums.common.status.OPENED"),
					CLOSED: t("enums.common.status.CLOSED"),
					EXTERNAL: t("enums.common.status.EXTERNAL"),
					VOIDED: t("enums.common.status.VOIDED"),
					REMOVED: t("enums.common.status.REMOVED"),
				},
			},
			{
				id: "point_of_sale",
				name: t("common.word.point_of_sale"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPointsOfSaleSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTerminalsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "room",
				name: t("modules.storno_report.field.room.title"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getRoomsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "table",
				name: t("common.word.table"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTablesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
					render: (item: StornReportApi) => {
						return `${item.label}${
							item.room_name &&
							`(${item.room_name}, ${t("modules.room.field.number_of_seats.title")}: ${
								item.number_of_seats
							})`
						}`;
					},
				} as ListFilterSource,
			},
			{
				id: "category",
				name: t("common.word.item_group_category"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getCategoriesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
		],
		selectedColumns: ["created_at", "storno_at", "number", "item", "employee", "saved", "comment", "total_price"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
		],
		selectedSorts: ["-created_at"],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.storno_report.header.title"),
			filename: t("modules.storno_report.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: async (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "role,workplace";
			setParams(fetchParams);
			const res = await api.organization().getStornReport(fetchParams, { cancelToken: sourceToken?.token });
			setSummaryData({
				quantity: res.data.data.quantity,
				total_price: res.data.data.total_price,
			});
			return res.data.data.items;
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			<DepartmentReportModal
				show={showDepartmentReportModal}
				onHide={() => setShowDepartmentReportModal(false)}
			/>
			<RebuildReportModal show={showRebuildReportModal} onHide={() => setShowRebuildReportModal(false)} />
			<ListData
				data={items}
				config={config}
				customTableRow={
					<tr>
						<td colSpan={params?.c?.split(",").length - 1}>
							<strong>
								{t("common.word.summary", { ns: "lib" })} {t("common.word.for")}{" "}
								{t("modules.storno_report.field.positions.title", { count: summaryData?.quantity })}:{" "}
							</strong>
						</td>
						<td>
							<strong>{FormatMoney(summaryData?.total_price)}</strong>
						</td>
					</tr>
				}
				emptyList={
					<EmptyList
						title={t("modules.storno_report.header.title")}
						description={t("modules.storno_report.field.empty_list_description.title")}
					/>
				}
				onFetch={(fetchItems: StornReportApi[]) => setItems(fetchItems)}
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationReportsStornPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<List resource={resource} />
		</Suspense>
	);
};
export default OrganizationReportsStornPage;
