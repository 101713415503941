import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationError } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	errors: ApplicationError[];
}

const RenderAppErrors = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const { errors } = props;
	const organization = useSelector(selectOrganization);

	return (
		<Alert variant={"danger"}>
			<div className={"d-flex flex-column"}>
				{errors.map((err, index) => {
					const translation = err.duplicate_value
						? `${t(`constraints.${err.code}`, { value: err.duplicate_value })}`
						: `${t(`constraints.${err.code}`)}`;
					return (
						<span key={index}>
							{translation}
							{err.app_link_id && (
								<a
									href={`/${organization.id}/apps/linking?f=${btoa(
										unescape(
											encodeURIComponent(
												`${NEW_WAY_TO_ENCODING_FILTER_SIGN}id|e=${err.app_link_id}`
											)
										)
									)}`}
									target={"_blank"}
									rel="noreferrer"
								>
									{t("common.action.goto", { ns: "lib" })}
								</a>
							)}
						</span>
					);
				})}
			</div>
		</Alert>
	);
};
export default RenderAppErrors;
