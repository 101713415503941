import { useRef } from "react";

interface Props {
	position: number;
	onDragOver: (data: React.DragEvent<HTMLDivElement>) => void;
	onDragStart: (data: { position: number }) => void;
	onDrop: (data: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
	children: JSX.Element;
}

const SortablePageItem = (props: Props): JSX.Element => {
	const longPressTimeout = useRef<any>();
	const isPressed = useRef(false);

	const handleTouchStart = () => {
		longPressTimeout.current = setTimeout(() => {
			isPressed.current = true;
			setScrollPos();
		}, 300);
	};

	const handleTouchEnd = () => {
		isPressed.current = false;
		if (longPressTimeout.current) clearTimeout(longPressTimeout.current);
		clearScrollPos();
	};

	const setScrollPos = () => {
		const pageContainer = document.querySelector<HTMLElement>(".page-container");

		if (pageContainer) {
			pageContainer.style.overflow = "hidden";
		}

		const content = document.querySelector<HTMLElement>(".content");

		if (content) {
			content.style.overflow = "hidden";
		}
	};

	const clearScrollPos = () => {
		const pageContainer = document.querySelector<HTMLElement>(".page-container");

		if (pageContainer) {
			pageContainer.style.overflow = "auto";
		}

		const content = document.querySelector<HTMLElement>(".content");

		if (content) {
			content.style.overflow = "";
		}
	};

	return (
		<div
			draggable={true}
			onDragStart={(evt: React.DragEvent<HTMLDivElement>) => {
				evt.dataTransfer.effectAllowed = "move";
				props.onDragStart({ position: props.position });
			}}
			onDragOver={(evt: React.DragEvent<HTMLDivElement>) => {
				props.onDragOver(evt);
			}}
			onDragEnd={(evt: React.DragEvent<HTMLDivElement>) => {
				props.onDrop(evt);
			}}
			onTouchMove={(evt) => {
				if (isPressed.current) {
					const touchOverPointX = evt.changedTouches[0].pageX;
					const touchOverPointY = evt.changedTouches[0].pageY;
					const target = document.elementFromPoint(touchOverPointX, touchOverPointY);
					const event = document.createEvent("Event");
					event.initEvent("dragover", true, true);
					target?.dispatchEvent(event);
				} else {
					handleTouchEnd();
				}
			}}
			onTouchStart={() => {
				handleTouchStart();
				props.onDragStart({ position: props.position });
			}}
			onTouchEnd={(evt: React.TouchEvent<HTMLDivElement>) => {
				handleTouchEnd();
				props.onDrop(evt);
			}}
		>
			{props.children}
		</div>
	);
};
export default SortablePageItem;
