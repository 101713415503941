import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { WorkplaceApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import WorkplaceModalForm from "../../components/WorkplaceModalForm";

interface ListState {
	resource?: Record<string, any>;
	setWorkplace: (workplace: WorkplaceApi | undefined) => void;
	workplace: WorkplaceApi | undefined;
	mobileActions: MobileActionProps[];
}

const listName = "WORKPLACE";
const resourceType = "WORKPLACE";

const List: FC<ListState> = ({ resource, setWorkplace, workplace, mobileActions }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const [items, setItems] = useState<WorkplaceApi[]>([]);
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const handleRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRestoreWorkplace = async (item: WorkplaceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			await api.organization().restoreWorkplace(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			await handleRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRemoveWorkplace = async (item: WorkplaceApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeWorkplace(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			await handleRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const handleSave = async () => {
		setWorkplace(undefined);
		await handleRefresh();
	};

	const handleUpdateWorkplace = (workplaceToUpdate: WorkplaceApi) => {
		setWorkplace(workplaceToUpdate);
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text",
				render: (item: WorkplaceApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<RenderLimitedText
								minWidth={200}
								onClick={() => handleUpdateWorkplace(item)}
								textStyleIsLink
							>
								{item.name}
							</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: WorkplaceApi) => item.name,
			},
			{
				id: "type",
				name: t("common.word.type", { ns: "lib" }),
				type: "list",
				options: {
					NONE: t("enums.workplaces.type.NONE"),
					DRIVER: t("enums.workplaces.type.DRIVER"),
				},
				render: (data: WorkplaceApi) => {
					return t(`enums.workplaces.type.${data.type}`);
				},
				renderExport: (data: WorkplaceApi) => {
					return t(`enums.workplaces.type.${data.type}`);
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: WorkplaceApi) => handleUpdateWorkplace(item),
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: WorkplaceApi) => onRemoveWorkplace(item),
				visible: (item: WorkplaceApi) => item.status === "ENABLED",
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: WorkplaceApi) => onRestoreWorkplace(item),
				visible: (item: WorkplaceApi) => item.status === "DELETED",
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		exportConfig: {
			title: t("modules.employee_workplace.field.export_config.title"),
			filename: t("modules.employee_workplace.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedColumns: ["name", "type"],
		segments: [
			{
				id: "all",
				name: `${t("common.word.all", { ns: "lib" })}`,
				slug: "all",
			},
			{
				id: "deleted",
				name: `${t("go_list.filters.deleted", { ns: "lib" })}`,
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e",
					},
				],
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "all";
			setParams(params);
			return api.organization().getWorkplaces(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			<ListData
				data={items}
				config={config}
				emptyList={{ addAction: () => setWorkplace({ type: "NONE" } as WorkplaceApi) }}
				onFetch={(fetchItems: WorkplaceApi[]) => {
					setItems(fetchItems);
				}}
				mobileActions={mobileActions}
			/>
			{workplace && (
				<WorkplaceModalForm
					workplace={workplace}
					onHide={() => setWorkplace(undefined)}
					handleSave={handleSave}
				/>
			)}
		</>
	);
};

const OrganizationEmployeesWorkplacesIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const [workplace, setWorkplace] = useState<WorkplaceApi | undefined>(undefined);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.employee_workplace.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
			action: () => setWorkplace({ type: "NONE" } as WorkplaceApi),
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setWorkplace({ type: "NONE" } as WorkplaceApi),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.employee_workplace.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					setWorkplace={setWorkplace}
					workplace={workplace}
					mobileActions={mobileActions}
				/>
			</Suspense>
		</>
	);
};

export default OrganizationEmployeesWorkplacesIndexPage;
