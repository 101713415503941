import axios, { AxiosInstance } from "axios";
import { handleResponse } from "go-core/api/handleResponse";
import { MyCookies } from "go-core/utils/cookies";
import { AuthCredentialsService } from "../AuthCredentialsService";
import AccessTokenSessionService from "../accessTokenSessionService";
import SessionIdService from "../sessionIdService";

export const refreshTokenApi = axios.create({
	withCredentials: true,
});

refreshTokenApi.interceptors.request.use(
	(config) => {
		return config;
	},
	undefined,
	{ synchronous: true }
);

const handleTokenRefresh = () => {
	return new Promise((resolve) => {
		const credentials = AuthCredentialsService.get();
		const sessionId = MyCookies.get("session_id");
		refreshTokenApi
			.post(
				`/oauth/token?session_id=${sessionId}&grant_type=session_id&client_id=${credentials.clientId}&client_secret=${credentials.secretId}`
			)
			.then(({ data }) => {
				const tokenData = {
					idToken: data.access_token,
					expiresAt: data.expires_in,
				};
				AccessTokenSessionService.update(data.access_token);
				resolve(tokenData);
			})
			.catch(() => {
				SessionIdService.remove();
				AccessTokenSessionService.remove();
				window.location.reload();
			});
	});
};

export default (axiosClient: AxiosInstance): void => {
	let isRefreshing = false;
	let failedQueue: Array<any> = [];

	const processQueue = (error: any, token = null) => {
		failedQueue.forEach((prom) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token);
			}
		});

		failedQueue = [];
	};

	axiosClient.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			const originalRequest = error.config;
			if (originalRequest === undefined) {
				return Promise.reject(error);
			}
			if (error.response?.status === 401 && !originalRequest._retry) {
				if (isRefreshing) {
					return new Promise((resolve, reject) => {
						failedQueue.push({ resolve, reject });
					})
						.then((token) => {
							originalRequest.headers.Authorization = `Bearer ${token}`;
							return axios(originalRequest);
						})
						.catch((err) => {
							return err;
						});
				}

				originalRequest._retry = true;
				isRefreshing = true;

				return new Promise((resolve, reject) => {
					handleTokenRefresh()
						.then((res: any) => {
							// restSecurityApi.defaults.headers.common['Authorization'] = 'Bearer ' + res.idToken;
							originalRequest.headers.Authorization = `Bearer ${res.idToken}`;
							processQueue(null, res.idToken);
							resolve(axios(originalRequest));
						})
						.catch((err) => {
							processQueue(err, null);
							reject(err);
						})
						.then(() => {
							isRefreshing = false;
						});
				}).catch((err) => {
					return handleResponse(err.response);
				});
			}
			return handleResponse(error.response);
		}
	);
};
