import { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationInvoicesAdvancesPage from "./Advances/pages";
import OrganizationInvoicesCorrectionsPage from "./Corrections/pages";
import OrganizationInvoicesIndexEditPage from "./Edit";
import OrganizationInvoicesIndexIndexPage from "./Index/index";
import OrganizationInvoicesIndexNewPage from "./New";
import OrganizationInvoicesIndexNewToOrderPage from "./NewToOrder";
import OrganizationInvoicesIndexPreviewPage from "./Preview";

const OrganizationInvoicesIndexPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route exact={true} path={`${props.match.url}`} component={OrganizationInvoicesIndexIndexPage} />
			<Route path={`${props.match.url}/new`} exact={true} component={OrganizationInvoicesIndexNewPage} />
			<Route
				path={`${props.match.url}/new_to_order`}
				exact={true}
				component={OrganizationInvoicesIndexNewToOrderPage}
			/>
			<Route path={`${props.match.url}/advances`} component={OrganizationInvoicesAdvancesPage} />
			<Route exact path={`${props.match.url}/:invoice_id`} component={OrganizationInvoicesIndexPreviewPage} />
			<Route path={`${props.match.url}/:invoice_id/edit`} component={OrganizationInvoicesIndexEditPage} />
			<Route
				path={`${props.match.url}/:invoice_id/corrections`}
				component={OrganizationInvoicesCorrectionsPage}
			/>
		</Switch>
	);
};
export default OrganizationInvoicesIndexPage;
