import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	type: string;
	className?: string;
}

const RenderTransactionType: FC<Props> = ({ type, className }) => {
	let labelClass = "";
	const { t } = useTranslation();
	switch (type) {
		case "PAY_IN":
			labelClass = "bg-success";
			break;
		case "PAY_OUT":
			labelClass = "bg-primary";
			break;
		case "CHANGE":
			labelClass = "bg-warning text-dark";
			break;
		case "CANCELLATION":
			labelClass = "bg-danger";
			break;
		default:
			labelClass = "bg-primary";
			break;
	}

	return (
		<span className={`badge ${labelClass} ${className ? className : ""}`}>
			{t(`enums.transactions.context_type.${type}`)}
		</span>
	);
};

export default RenderTransactionType;
