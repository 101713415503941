import React from "react";
import { ClientDetailsList } from "go-component/components/ClientDetails/pages/Index";
import { adminApi } from "go-security/services/Api/adminApi";

export const AdminClientDetailsIndexPage = (): JSX.Element => {
	const {
		getClientDetails,
		addClientDetail,
		editClientDetail,
		deleteClientDetail,
		getClientScopes,
		getClientGrantTypes,
	} = adminApi;

	const requests = {
		getClientDetails,
		addClientDetail,
		editClientDetail,
		deleteClientDetail,
		getClientScopes,
		getClientGrantTypes,
	};

	return <ClientDetailsList requests={requests} />;
};
