import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationMenuPriceListsPredicatesIndexPage from "./pages/Index";

const OrganizationMenuPriceListsPredicatesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"content"}>
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationMenuPriceListsPredicatesIndexPage} />
			</Switch>
		</div>
	);
};

export default OrganizationMenuPriceListsPredicatesPage;
