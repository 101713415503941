import React, { JSX, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationNameListResponseApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import TranslationsVirtualTableForm from "./components/TranslationsVirtualTableForm";

const OrganizationMenuItemGroupsTranslationPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [items, setItems] = useState<ItemTranslationItemNameFormApi[] | undefined>();
	const [locales, setLocales] = useState<ItemTranslationItemNameTranslationFormApi[] | undefined>();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.item_translation.header.title"));
		handleFetch();
	}, []);

	const handleFetch = async () => {
		try {
			const params: Record<string, any> = { page: "0", size: "0" };
			const res: ItemTranslationNameListResponseApi = await api.organization().getItemGroupsTranslations(params);
			setItems(
				res.items.map((item, index) => ({
					...item,
					item_full_name: item.name,
					name:
						item.name === item.item_group_name
							? item.name
							: item.name.replace(item.item_group_name, "").trim(),
					index,
				}))
			);
			setLocales(
				res.locales.map((locale, index) => ({
					id: locale,
					index,
					name: t(`lib:enums.languages.${locale}`),
				}))
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<>
			<div className="virtual-table-wrapper-container-header">
				<h1>{t("modules.item_translation.header.title")}</h1>
			</div>
			{!items || !locales ? (
				<LoadingContainer />
			) : (
				<TranslationsVirtualTableForm
					items={items}
					locales={locales}
					handleUpdate={(res) => setItems([...res])}
				/>
			)}
		</>
	);
};

export default OrganizationMenuItemGroupsTranslationPage;
