import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "go-core/components/Loading";

export interface SelectedItemsSummaryProps {
	selectedItems: string[];
	areAllSelected: boolean;
	setAreAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
	allItemsCount?: number;
	canManageMultiActionsForAllItems?: boolean;
}

export const SelectedItemsSummary = ({
	selectedItems,
	areAllSelected,
	setAreAllSelected,
	allItemsCount,
	canManageMultiActionsForAllItems,
}: SelectedItemsSummaryProps): JSX.Element => {
	const { t } = useTranslation();

	useEffect(() => {
		if (allItemsCount === undefined) {
			window.dispatchEvent(new Event("RequestTotalItemsCountEvent"));
		}
	}, [allItemsCount]);

	let summaryText: string;
	let actionText: string;

	if (areAllSelected) {
		actionText = t("lib:go_list.selected_items_summary.action_text.all_selected");
		summaryText = t("lib:go_list.selected_items_summary.summary_text.all_selected");

		if (allItemsCount !== undefined) {
			summaryText = `${summaryText} (${allItemsCount})`;
		}
	} else {
		actionText = t("lib:go_list.selected_items_summary.action_text.not_all_selected");
		summaryText = `${selectedItems.length} `;

		if (selectedItems.length === 1) {
			summaryText += t("lib:go_list.selected_items_summary.summary_text.not_all_selected_one");
		} else if (selectedItems.length < 5) {
			summaryText += t("lib:go_list.selected_items_summary.summary_text.not_all_selected_less_than_5");
		} else {
			summaryText += t("lib:go_list.selected_items_summary.summary_text.not_all_selected");
		}

		if (allItemsCount !== undefined) {
			actionText = `${actionText} ${allItemsCount}`;
		}
	}

	if (canManageMultiActionsForAllItems && allItemsCount === undefined) {
		return <Loading />;
	}

	return (
		<>
			<span>{summaryText}. </span>
			{canManageMultiActionsForAllItems && (
				<>
					<button
						type="button"
						className="btn-with-link-styling"
						onClick={() => setAreAllSelected((state) => !state)}
					>
						{actionText}
					</button>
					{"."}
				</>
			)}
		</>
	);
};
