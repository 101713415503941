import { FC } from "react";
import { TFunction, useTranslation } from "react-i18next";

interface FormatBooleanProps {
	value: boolean;
}

export const convertBoolean = (value: boolean, t: TFunction): string => {
	return value ? t("lib:common.word.yes") : t("lib:common.word.no");
};

const FormatBoolean: FC<FormatBooleanProps> = ({ value }) => {
	const { t } = useTranslation();
	return <>{convertBoolean(value, t)}</>;
};
export default FormatBoolean;
