import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import FormatResourceStatus from "../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ItemAppLinkApi } from "../../../../../../../services/Api/Organization/types";

export const RenderLimitedContent = ({ item }: { item: ItemAppLinkApi }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const { t } = useTranslation();
	const wrapperStyles: CSSProperties = isExpanded
		? { height: "100%", overflowY: "visible" }
		: { height: "37px", overflowY: "hidden" };
	const toggleExpandedTranslation = isExpanded
		? t("common.action.less", { ns: "lib" })
		: t("common.action.more", { ns: "lib" });

	const toggleExpandContent = () => {
		setIsExpanded((expanded) => !expanded);
	};

	return (
		<>
			<div style={wrapperStyles}>
				{item.apps.map((app) => {
					let backgroundColor = "rgb";
					if (app.app.status === "ENABLED") backgroundColor += "(196, 227, 191)";
					else if (app.app.status === "ERROR") backgroundColor += "(242, 203, 208)";
					else backgroundColor += "(162, 213, 255)";
					return (
						<div
							key={app.app.id}
							className="py-2 d-flex align-items-Center justify-content-end"
							style={{ backgroundColor }}
						>
							<span className="d-flex align-items-center">
								{`${app.app.name}
                    (${t(`enums.apps.providers.${app.app.provider}`)}) ${
									app.app_link?.external_name ?? t("modules.app_link.field.unlinked.title")
								}
                    `}
								<div className="mx-2">
									<FormatResourceStatus status={app.app.status} />
								</div>
							</span>
						</div>
					);
				})}
			</div>
			{item.apps.length > 1 && (
				<button onClick={toggleExpandContent} className="btn-with-link-styling">
					[{toggleExpandedTranslation}]
				</button>
			)}
		</>
	);
};
