import React, { useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormCheck, FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import { SaveAndAddButtons, TranslationType } from "go-core/components/SaveAndAddButtons";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import ErrorDuplicateName from "../../../../../../../../../components/Common/Errors/ErrorDuplicateName";
import {
	CategoryApi,
	EntityPointOfSaleApi,
	PointOfSaleApi,
	TranslationApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import EntityPointsOfSaleForm, { mapPointsOfSaleToForm } from "../../../components/EntityPointsOfSaleForm";
import ImageLinks from "../../../components/ImageLinks";

interface Props {
	category: CategoryApi;
	pointsOfSale: PointOfSaleApi[];
	passTranslations?: (itemGroupTranslations: TranslationApi[]) => void;
	categoryTranslations?: TranslationApi[];
	createNew?: () => void;
}

const CategoryForm = (props: Props): JSX.Element => {
	const [newAvatar, setNewAvatar] = useState<string | undefined>(props.category.image_link?.default_link);
	const [saveLoading, setSaveLoading] = useState(false);
	const [saveAndAddLoading, setSaveAndAddLoading] = useState(false);
	const [showImageLinks, setShowImageLinks] = useState(
		props.category.image_links && props.category.image_links?.length > 0
	);
	const [advancedPointsOfSaleView, setAdvancedPointsOfSaleView] = useState(
		props.category.points_of_sale?.filter((point) => point.visibility !== "HIDDEN").length > 0
	);
	const [advancedDirectionView, setAdvancedDirectionView] = useState(
		props.category.points_of_sale?.length === 0 && !!props.category.direction
	);
	const { addFlash, addSuccessFlash } = useFlash();
	const saveAndAddMode = useRef<boolean>(false);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const form = useForm<CategoryApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.category,
			points_of_sale: mapPointsOfSaleToForm(props.pointsOfSale, props.category.points_of_sale),
		},
	});
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		watch,
		formState,
		reset,
		setError,
		setValue,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	const translatePointsOfSaleFromBoolToString = (data: CategoryApi) => {
		data.points_of_sale = data.points_of_sale?.map((pos: EntityPointOfSaleApi) => {
			if (pos.visibility === true || pos.visibility === "VISIBLE") {
				pos.visibility = "VISIBLE";
			} else {
				pos.visibility = "HIDDEN";
			}
			return pos;
		});
	};

	const onSubmit = handleSubmit(async (data: CategoryApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		saveAndAddMode.current ? setSaveAndAddLoading(true) : setSaveLoading(true);
		const params: Record<string, any> = {};
		data.translations = data.translations?.filter(
			(f) => f.name?.length > 0 || (f.description && f.description?.length > 0)
		);

		if (advancedDirectionView || !advancedPointsOfSaleView) {
			data.points_of_sale = [];
		} else if (advancedPointsOfSaleView) translatePointsOfSaleFromBoolToString(data);
		params.include =
			"image_links,points_of_sale,points_of_sale.direction,direction,points_of_sale.point_of_sale,translations";
		if (getValues("image.image_data") || !newAvatar) {
			data.image_link = undefined;
		}
		if (!getValues("color")) {
			data.color = undefined;
		}
		data.id = props.category.id;
		try {
			if (data.id) {
				const res = await api.organization().updateCategory(data, params);
				if (saveAndAddMode.current) history.push(`/${organization.id}/menu/categories/new`);
				props.category.image_link = res.image_link;
				reset({
					...res,
					points_of_sale: mapPointsOfSaleToForm(props.pointsOfSale, res.points_of_sale),
				});
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createCategory(data);

				if (saveAndAddMode.current && props.createNew) props.createNew();
				else history.push(`/${organization.id}/menu/categories/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setSaveLoading(false);
			setSaveAndAddLoading(false);
		}
	});

	const handleImageChange = (imageBase: string | undefined) => {
		setNewAvatar(imageBase);
	};

	const changeDefaultDirectionView = () => {
		unstable_batchedUpdates(() => {
			setAdvancedDirectionView(!advancedDirectionView);
			setAdvancedPointsOfSaleView(false);
			if (advancedDirectionView) {
				setValue("direction_id", undefined, { shouldDirty: true });
			}
		});
	};

	const changeAdvancedPosesView = () => {
		unstable_batchedUpdates(() => {
			setAdvancedPointsOfSaleView(!advancedPointsOfSaleView);
			setAdvancedDirectionView(false);
			if (!advancedPointsOfSaleView) {
				setValue("points_of_sale", mapPointsOfSaleToForm(props.pointsOfSale, props.category.points_of_sale), {
					shouldDirty: true,
				});
			} else {
				setValue("points_of_sale", [], { shouldDirty: true });
			}
		});
		setValue("direction_id", undefined, { shouldDirty: true });
	};

	const onCreateNextCategory = async () => {
		saveAndAddMode.current = true;
		onSubmit();
	};

	useEffect(() => {
		props.categoryTranslations?.forEach((translation, index) => {
			const prefix = `translations.${index}`;
			registerObject(register, prefix, ["locale", "name", "description"]);
			setValue(`translations.${index}`, translation);
		});
	}, [props.categoryTranslations]);

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<FormDirty
			formState={formState}
			loading={saveLoading}
			key="category-form"
			noValidate
			onSubmit={onSubmit}
			buttonSubmitCustom={
				<SaveAndAddButtons
					saveLoading={saveLoading}
					saveAndAddLoading={saveAndAddLoading}
					onSaveAndAddClick={onCreateNextCategory}
					translationType={TranslationType.FEMALE}
				/>
			}
		>
			<fieldset className="form-group no-gutters">
				<div className="row">
					<div className="col-md-10">
						<div className={"row"}>
							<div className={"col-md-6"}>
								<FormInput
									label={t("lib:common.word.name")}
									register={register}
									name="name"
									errors={errors}
									customValidationConfig={nameValidationConfig}
								/>
								<ErrorDuplicateName resource_type={"CATEGORY"} errors={errors} />
							</div>
							<div className={"col-md-6"}>
								<FormInput
									as={"textarea"}
									rows={3}
									label={t("lib:common.word.description")}
									register={register}
									name="description"
									errors={errors}
									style={{ minHeight: "calc(2 * 1px + 2 * 6px + 50.391px)" }}
								/>
							</div>
						</div>
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.category.field.discountable.title")}
							name="discountable"
							errors={errors}
						/>
					</div>
					<div className={"col-md-2"} style={{ minWidth: "150px" }}>
						<ImageColorForm
							form={form}
							handleSave={handleImageChange}
							prefix="image"
							mode="IMAGE_COLOR"
							data={props.category}
							resourceLink={props.category.image_link}
							resourceData={props.category.image}
							entityName={watch("name")}
						/>
						{!showImageLinks &&
							(watch("image_links")?.length === 0 || watch("image_links") === undefined) && (
								<div className={"action-ref text-center"} onClick={() => setShowImageLinks(true)}>
									{t("modules.item_group.action.add_more_images.title")}
								</div>
							)}
					</div>
				</div>
				{showImageLinks && <ImageLinks handleCloseModal={() => setShowImageLinks(false)} form={form} />}
			</fieldset>
			<EntityPointsOfSaleForm
				defaultDirection={props.category.direction}
				changeDefaultDirectionView={changeDefaultDirectionView}
				changeAdvancedPosesView={changeAdvancedPosesView}
				showDefaultDirection={true}
				advancedPosesView={advancedPointsOfSaleView}
				form={form}
				advancedDirectionView={advancedDirectionView}
				pointsOfSale={props.pointsOfSale}
				entityPointsOfSale={props.category.points_of_sale}
				entityType="category"
			/>
		</FormDirty>
	);
};
export default CategoryForm;
