import React, { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationSalesOrdersEditPage from "./pages/Edit";
import OrganizationSalesOrdersIndexPage from "./pages/Index";
import OrganizationSalesOrdersPreviewPage from "./pages/Preview";

const OrganizationSalesBillsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/`} exact component={OrganizationSalesOrdersIndexPage} />
				<Route path={`${props.match.url}/:orderId`} exact component={OrganizationSalesOrdersPreviewPage} />
				<Route path={`${props.match.url}/:orderId/edit`} component={OrganizationSalesOrdersEditPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSalesBillsPage;
