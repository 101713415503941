import React, { FC, useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { listColumnSort } from "go-list/core/components/Column/utils";
import { ReactComponent as SortableHandleSvg } from "go-report/core/images/sortable-handle.svg";
import { ReactComponent as ColumnSVG } from "../../images/column.svg";
import { ReportConfigColumn } from "../../services/types";

interface Props {
	columns: ReportConfigColumn[];
	selectedColumns: Array<string>;
	onChange: (selectedColumns: Array<string>, sortedColumns: Array<string>) => void;
	showPercent: boolean;
	onShowPercentChange: () => void;
}

const ReportColumn: FC<Props> = (props) => {
	const selectedColumns = props.selectedColumns;
	const [sortedColumns, setSortedColumns] = useState(() => {
		const selectedColumnsObjs: ReportConfigColumn[] = [];
		const unselectedColumns: ReportConfigColumn[] = [];

		props.columns.forEach((column) => {
			if (selectedColumns.find((selectedColumn) => selectedColumn === column.id))
				selectedColumnsObjs.push(column);
			else unselectedColumns.push(column);
		});

		selectedColumnsObjs.sort((a, b) => {
			return selectedColumns.indexOf(a.id) - selectedColumns.indexOf(b.id);
		});

		return [...selectedColumnsObjs, ...unselectedColumns];
	});
	const ref = useRef<HTMLDivElement>(null);
	const [isComponentVisible, setIsComponentVisible] = useState(false);
	const { t } = useTranslation();

	const triggerComponentVisibility = () => {
		setIsComponentVisible((isVisible) => !isVisible);
	};

	const handleClickOutside = (event?: MouseEvent) => {
		if (!event) return;

		if (ref.current && !ref.current?.contains(event.target as Node)) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	const onChangeSelected = (value: string) => {
		const newSelectedColumns = [...selectedColumns];
		if (selectedColumns.includes(value)) {
			const columnIndex = selectedColumns.indexOf(value);
			newSelectedColumns.splice(columnIndex, 1);
		} else {
			const offset = sortedColumns
				.map((x) => x.id)
				.filter((_, index) => index < sortedColumns.map((x) => x.id).indexOf(value))
				.filter((id) => !newSelectedColumns.includes(id)).length;
			newSelectedColumns.splice(sortedColumns.map((x) => x.id).indexOf(value) - offset, 0, value);
		}
		props.onChange(
			newSelectedColumns,
			sortedColumns.map((x) => x.id)
		);
	};
	const onSortedList = (list: ReportConfigColumn[]) => {
		setSortedColumns(list);
		props.onChange(
			listColumnSort(
				selectedColumns,
				list.map((i) => i.id)
			),
			list.map((x) => x.id)
		);
	};

	const onShowPercent = () => {
		props.onShowPercentChange();
	};

	const onInputClick = (value: string, evt?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		evt?.stopPropagation();
		onChangeSelected(value);
	};

	return (
		<>
			<Dropdown ref={ref} show={isComponentVisible} className="columns-dropdown" as={ButtonGroup}>
				<Dropdown.Toggle onClick={triggerComponentVisibility} variant="light">
					<ColumnSVG />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<div className="filters-search-columns">
						<ReactSortable list={sortedColumns} setList={(newState) => onSortedList(newState)}>
							{sortedColumns.map((column) => {
								return (
									<Dropdown.Item
										id={column.id}
										key={column.id}
										onClick={(evt) => onInputClick(column.id, evt)}
									>
										<div className="dropdown-draggable-item">
											<SortableHandleSvg />
											<Form.Check
												type="checkbox"
												label={column.name}
												checked={selectedColumns.includes(column.id)}
												onClick={(evt) => onInputClick(column.id, evt)}
											/>
										</div>
									</Dropdown.Item>
								);
							})}
						</ReactSortable>
						<Form.Group key="showPercent" controlId="showPercent" className="form-group dropdown-item mb-0">
							<Form.Check
								type="checkbox"
								label={t("lib:go_report.table.columns.show_percent")}
								className="dropdown-item-align-with-draggable"
								checked={props.showPercent}
								onChange={onShowPercent}
							/>
						</Form.Group>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};
export default ReportColumn;
