import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { apiOrganization } from "../../../../../../../../../../../services/Api/Organization/apiOrganization";
import { TerminalApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface FormProps {
	diagnostics_enabled: boolean;
}

interface Props {
	isShown: boolean;
	onHide: () => void;
	terminal: TerminalApi;
	handleOnSuccess: (terminal: TerminalApi) => void;
}

const TerminalAdministerModal: FC<Props> = ({ isShown, onHide, terminal, handleOnSuccess }) => {
	const { t } = useTranslation();
	const form = useForm<FormProps>({
		criteriaMode: "all",
		defaultValues: {
			diagnostics_enabled: terminal.settings.find((setting) => setting.name === "DIAGNOSTICS")?.setup === "true",
		},
	});
	const params: Record<string, any> = { include: "diagnoses,logs,settings" };
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState<boolean>(false);

	const {
		handleSubmit,
		formState,
		register,
		formState: { errors },
		setError,
	} = form;

	const onSubmit = handleSubmit(async (data: FormProps) => {
		setLoading(true);
		try {
			const res = await apiOrganization.manageTerminal(terminal.id, data, params);
			handleOnSuccess(res);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.alert(err, addFlash);
			handleError.form(err, setError);
		}
		setLoading(false);
	});

	return (
		<Modal show={isShown} onHide={onHide}>
			<FormDirty formState={formState} onSubmit={onSubmit} key="terminal-administer-modal-form">
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.terminal.action.administer.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormCheck
						type="switch"
						register={register}
						label={t("modules.terminal.action.diagnostics_enabled.title")}
						name="diagnostics_enabled"
						errors={errors}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default TerminalAdministerModal;
