import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { addPolyfills } from "go-core/utils/polyfills";
import App from "./App";
import configureStore from "./configureStore";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import "./styles.scss";

addPolyfills();

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(initialState);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
