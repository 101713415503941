import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationListFormProps,
} from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	show: boolean;
	onHide: () => void;
	form: UseFormReturn<ItemTranslationListFormProps>;
	item: ItemTranslationItemNameFormApi;
	locale?: ItemTranslationItemNameTranslationFormApi;
}

const ChangeTranslationDescriptionModal = ({ show, onHide, item, form, locale }: Props) => {
	const { t } = useTranslation();
	const {
		setValue,
		formState: { errors },
		register,
	} = form;
	const [newDescription, setNewDescription] = useState("");

	const onSubmit = () => {
		if (locale) {
			setValue(`items.${item.index}.translations.${locale.index}.description`, newDescription);
		} else {
			setValue(`items.${item.index}.description`, newDescription);
		}
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide} className="change-translation-description-modal">
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.item_translation.header.change_description.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormInput
					name={
						locale
							? `items.${item.index}.translations.${locale.index}.description`
							: `items.${item.index}.description`
					}
					onChange={(e) => setNewDescription(e.target.value)}
					errors={errors}
					as="textarea"
					register={register}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onSubmit} type="submit">
					{t("lib:common.action.apply")}
				</Button>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ChangeTranslationDescriptionModal;
