import React from "react";
import { Helmet } from "react-helmet";
import { getGoogleAnalyticsId } from "../../utils/envutil";

const GoogleAnalytics = (): JSX.Element => {
	const googleAnalyticsId = getGoogleAnalyticsId();

	if (!googleAnalyticsId) return <></>;

	return (
		<Helmet>
			<script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
			<script>
				{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${googleAnalyticsId}');
                `}
			</script>
		</Helmet>
	);
};

export default GoogleAnalytics;
