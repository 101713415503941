import React from "react";
import { Redirect } from "react-router";
import useFlash from "go-alert/AlertMessage";
import { DiagnoseElementApi, DiagnosticsRequests } from "../../services/types";
import { DiagnosticsComponent } from "./components/DiagosticsComponent";

type Props = DiagnosticsRequests & {
	organization: Record<string, any>;
	user: Record<string, any>;
	diagnosticsTranslations?: Record<string, string>;
	renderDiagnseElementLink: (diagnoseElement: DiagnoseElementApi, id: string, title: string) => JSX.Element;
	diagnoseElementTitleKeyFormat: string;
};

export const DiagnosticsPage = (props: Props): JSX.Element => {
	const { organization, user } = props;
	const { addFlash } = useFlash();

	if (!user.admin && !organization.support) {
		addFlash({
			type: "danger",
			msg: "access_forbidden",
		});
		return <Redirect to={`/${organization.id}`} />;
	}

	return (
		<div className="page-container">
			<div className="content">
				<div className="content-preview">
					<DiagnosticsComponent {...props} />
				</div>
			</div>
		</div>
	);
};
