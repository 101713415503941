import { AxiosInstance } from "axios";
import queryString from "query-string";
import AccessTokenSessionService from "../accessTokenSessionService";

export default (axiosClient: AxiosInstance): void => {
	axiosClient.interceptors.request.use((config) => {
		config.paramsSerializer = (params) => queryString.stringify(params, { arrayFormat: "bracket" });
		return config;
	});
	axiosClient.interceptors.request.use((config) => {
		const token = AccessTokenSessionService.get();
		if (config.headers !== undefined && token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	});
	axiosClient.interceptors.response.use(
		(config) => {
			return config;
		},
		(error) => {
			if (!error.request || !error.response) {
				return Promise.reject(error);
			}
			if (
				error.request.responseType === "blob" &&
				error.response.data instanceof Blob &&
				error.response.data.type &&
				error.response.data.type.toLowerCase().indexOf("json") !== -1
			) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						if (typeof reader.result === "string") {
							error.response.data = JSON.parse(reader.result);
						}
						resolve(Promise.reject(error));
					};

					reader.onerror = () => {
						reject(error);
					};

					reader.readAsText(error.response.data);
				});
			}
			return Promise.reject(error);
		}
	);
};
