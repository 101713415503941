import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { FormatDuration } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import {
	ExternalListSelectedFilter,
	FilterType,
	ListFilterSource,
} from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { WorkTimeApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "ONLINE_EMPLOYEE";
const resourceType = "ONLINE_EMPLOYEE";

const List: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const [items, setItems] = useState<WorkTimeApi[]>([]);
	const organization = useSelector(selectOrganization);
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onCheckout = async (item: WorkTimeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			await api.organization().worktimeCheckout(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			setItems(items.filter((dataItem) => dataItem.id !== item.id));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	let config = {
		externalSelectedFilters: [
			{
				id: "online",
				filterType: "boolean" as FilterType,
				value: "true",
				condition: "true",
				visible: false,
			} as ExternalListSelectedFilter,
		],
		fields: [
			{
				id: "start_at",
				name: t("modules.employee.field.start_at.title"),
				type: "date" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatDate(item.start_at);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatDate(item.start_at);
				},
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select" as FilterType,
				render: (item: WorkTimeApi) => {
					return <Link to={`/${organization.id}/employees/${item.employee_id}`}>{item.employee?.name}</Link>;
				},
				renderExport: (item: WorkTimeApi) => {
					return `${item.employee?.name}`;
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "source",
				name: t("modules.employee.field.source.title"),
				type: "list" as FilterType,
				options: {
					POS: t("enums.employees_online.source.POS"),
					WEB: t("enums.employees_online.source.WEB"),
				},
				render: (item: WorkTimeApi) => {
					return t(`enums.employees_online.source.${item.source}`);
				},
				renderExport: (item: WorkTimeApi) => {
					return t(`enums.employees_online.source.${item.source}`);
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "workplace",
				name: t("modules.employee.field.workplace.title"),
				type: "search_select" as FilterType,
				render: (item: WorkTimeApi) => {
					return item.workplace?.name;
				},
				renderExport: (item: WorkTimeApi) => {
					return item.workplace?.name;
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getWorkplacesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "hourly_rate",
				name: t("modules.employee.field.hourly_rate.title"),
				type: "number" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatMoney(item.hourly_rate);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatMoney(item.hourly_rate);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "duration_in_minutes",
				name: t("common.word.work_duration"),
				render: (item: WorkTimeApi) => FormatDuration(item.duration_in_minutes, t, true),
				renderCsv: (item: WorkTimeApi) =>
					`${Math.floor(item.duration_in_minutes / 60)}.${Number(
						((item.duration_in_minutes % 60) / 60).toFixed(2).slice(2, 4)
					)}`,
			},
			{
				id: "to_pay",
				name: t("modules.employee.field.wage.title"),
				type: "number" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatMoney(item.to_pay);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatMoney(item.to_pay);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			} as ListConfigField,
		],
		actions: [
			{
				name: t("common.action.check_out"),
				click: (item: WorkTimeApi) => {
					onCheckout(item);
				},
				visible: (item: WorkTimeApi) => {
					return !item.end_at;
				},
			},
		],
		filters: [
			{
				id: "duration_in_hours",
				name: t("modules.employee.field.duration_in_hours.title"),
				type: "number" as FilterType,
			},
		],
		selectedColumns: [
			"start_at",
			"employee",
			"workplace",
			"source",
			"hourly_rate",
			"duration_in_minutes",
			"to_pay",
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all_male"),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.employee.field.export_config_online.title"),
			filename: t("modules.employee.field.export_config_online_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "employee,workplace,custom_fields";
			return api.organization().getWorkTimes(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			onFetch={(fetchItems: WorkTimeApi[]) => {
				setItems(fetchItems);
			}}
		/>
	);
};

const OrganizationEmployeesOnlinePage = (): JSX.Element => {
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;
	const [resource, setResource] = useState<Record<string, any> | undefined>();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "EMPLOYEE",
			name: t(`enums.custom_fields.resources.EMPLOYEE`),
		},
	];
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.employee.header.online.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.employee.header.online.title")} />}
			<Suspense fallback={<></>}>
				<List resource={resource} />
			</Suspense>
		</div>
	);
};
export default OrganizationEmployeesOnlinePage;
