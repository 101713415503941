import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CircleInfoSVG } from "../../../../../../../images/svg/info.svg";

const ChangeTransactionPaymentMethodInfo = () => {
	const { t } = useTranslation();

	return (
		<div className="change-transaction-payment-method-info-box">
			<div className="d-flex">
				<CircleInfoSVG className="info-icon" />
				<span>
					{`${t("modules.transaction.field.changing_transaction_info.title")}: `}
					<strong>{`${t("modules.transaction.field.negative_transaction.title")} `}</strong>
					{t("modules.transaction.field.or.title")}
					<strong>{` ${t("modules.transaction.field.positive_transaction.title")}`}</strong>.
				</span>
			</div>
		</div>
	);
};

export default ChangeTransactionPaymentMethodInfo;
