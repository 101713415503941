import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationSettingsTaxIndexPage from "./pages/Index";

const OrganizationSettingsTaxPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route path={`${props.match.url}/`} exact={true} component={OrganizationSettingsTaxIndexPage} />
		</Switch>
	);
};

export default OrganizationSettingsTaxPage;
