import { AxiosResponse, CancelTokenSource } from "axios";
import { TFunction } from "react-i18next";
import { filterParseDateBetween, filterParseDateRange } from "go-core/components/Formatters/FormatDate";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { ListConfigFilter } from "go-list/core/components/Filter/services/types";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ReportConfig } from "./types";

interface LastMonthFilterBetweenConfig {
	getDateFromLocalStorage?: boolean;
	includeBoundaryDays?: boolean;
}

const getDefaultFilter = () => {
	return `date_range|${getLastMonthFilterBetween()}`;
};

const decodeGroups = (groupString: string, groupColumn?: string, skipGroupColumn?: boolean) => {
	let groups: string[];
	if (groupString) {
		groups = groupString.split(",");
	} else {
		groups = [];
	}
	if (!skipGroupColumn) {
		if (!groupColumn) {
			groups.unshift("NONE");
		} else {
			groups.unshift(groupColumn);
			groups.unshift("NONE");
		}
	}
	const uniqueGroupsSet = new Set(groups);
	groups = Array.from(uniqueGroupsSet);
	return `groups|e=${groups.join(FILTER_VALUE_SEPARATOR)}`;
};

const processFilter = (params: string) => {
	if (params === undefined) {
		return getDefaultFilter();
	}
	const decoded = decodeURIComponent(escape(atob(params)));
	if (decoded.startsWith(NEW_WAY_TO_ENCODING_FILTER_SIGN)) return decoded.substring(1);
	return decoded;
};

export const getReport = (
	fetch: (params: Record<string, any>, options?: Record<string, any>) => Promise<AxiosResponse>,
	listParams: any,
	orgId?: number | number[],
	filtersConfig?: ListConfigFilter[],
	prefix?: string,
	cancelToken?: CancelTokenSource,
	skipGroupColumn?: boolean
): Promise<AxiosResponse> => {
	const decoded = processFilter(listParams.f);
	const groups = decodeGroups(listParams.groups, listParams.group_column, skipGroupColumn);
	const groupColumns = listParams.group_column ? `${FILTER_SEPARATOR}group_column=${listParams.group_column}` : "";
	const organizationId = Array.isArray(orgId) ? orgId.map((x) => x).join(FILTER_VALUE_SEPARATOR) : orgId;
	const typeFilter = prefix ? `${FILTER_SEPARATOR}type=${prefix}` : "";
	const testFilter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${decoded}${FILTER_SEPARATOR}aggregate_histogram=SUMMARY${typeFilter}${FILTER_SEPARATOR}enable=true${FILTER_SEPARATOR}${groups}${groupColumns}${FILTER_SEPARATOR}time_start=${
		getTimes(listParams.f)[0][0]
	}${FILTER_SEPARATOR}time_end=${getTimes(listParams.f)[0][1]}${FILTER_SEPARATOR}organization_id|e=${organizationId}`;
	const params = {
		f: btoa(unescape(encodeURIComponent(`${testFilter}`))),
		size: 10000,
	};
	return fetch(params, { cancelToken: cancelToken?.token });
};

const getTimes = (filterValue: string) => {
	let newFilterValue = filterValue;
	if (filterValue === undefined) {
		newFilterValue = btoa(getDefaultFilter());
	}
	const fullDecoded = decodeURIComponent(escape(atob(newFilterValue)));
	const filters = fullDecoded.split(FILTER_SEPARATOR);
	return filters
		.filter((filter) => filter.includes("date_range"))
		.map((filter) => {
			const fSplit = filter.split("=")[1];
			let fVal;
			if (fSplit.includes(FILTER_VALUE_SEPARATOR)) {
				fVal = fSplit.split(FILTER_VALUE_SEPARATOR);
				return fVal.map((filterNewValue) => filterNewValue.split(" ")[1].slice(0, -3));
			}
			return ["00:00", "23:59"];
		});
};

export const getChartReport = (
	fetch: (params: Record<string, any>, options?: Record<string, any>) => Promise<AxiosResponse>,
	listParams: any,
	defaultGroup: string[] | string,
	orgId?: number | number[],
	filtersConfig?: ListConfigFilter[],
	prefix?: string,
	cancelToken?: CancelTokenSource,
	size?: number
): Promise<AxiosResponse> => {
	const decoded = processFilter(listParams.f);
	const parsedGroups = Array.isArray(defaultGroup) ? defaultGroup.join(FILTER_VALUE_SEPARATOR) : defaultGroup;
	const groups = `groups|e=${parsedGroups}${FILTER_VALUE_SEPARATOR}${listParams.chart_type || ""}`;
	const groupColumns = listParams.group_column ? `${FILTER_SEPARATOR}group_column|e=${listParams.group_column}` : "";
	const organizationId = Array.isArray(orgId) ? orgId.map((x) => x).join(FILTER_VALUE_SEPARATOR) : orgId;
	const typeFilter = prefix ? `${FILTER_SEPARATOR}type=${prefix}` : "";
	const testFilter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${decoded}${FILTER_SEPARATOR}aggregate_histogram=SUMMARY${typeFilter}${FILTER_SEPARATOR}enable=true${FILTER_SEPARATOR}${groups}${groupColumns}${FILTER_SEPARATOR}time_start=${
		getTimes(listParams.f)[0][0]
	}${FILTER_SEPARATOR}time_end=${getTimes(listParams.f)[0][1]}${FILTER_SEPARATOR}organization_id|e=${organizationId}`;
	const params = {
		f: btoa(unescape(encodeURIComponent(`${testFilter}`))),
		size: size ? size : 10000,
	};
	return fetch(params, { cancelToken: cancelToken?.token });
};

export const getReportFilter = (
	fetch: (filter: string, params?: Record<string, any>, options?: Record<string, any>) => Promise<AxiosResponse>,
	fieldName: string,
	t?: string,
	f?: string,
	type?: string,
	cancelToken?: CancelTokenSource,
	params?: Record<string, any>
): Promise<AxiosResponse> => {
	const decoded = f ? processFilter(f) : getLastMonthFilterBetween();
	const date =
		decoded
			?.split(FILTER_SEPARATOR)
			.filter((item) => item.includes("date_range"))[0]
			?.split("date_range|bt=")[1] || "";
	const orgIds = window.localStorage.getItem("go_report.filters.organization_ids");
	const filter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}date_range|bt=${date}${FILTER_SEPARATOR}type=${type}${FILTER_SEPARATOR}query=${t}${FILTER_SEPARATOR}field_name=${fieldName}${FILTER_SEPARATOR}organization_id|e=${orgIds}`;
	return fetch(btoa(unescape(encodeURIComponent(`${filter}`))), { ...params, cancelToken: cancelToken?.token });
};

export const reportParamsConverter = (params: Record<string, any>): Record<string, any> => {
	let groups = params.groups;
	let groupColumn = params.group_column;
	let filterEncoded = params.f;
	let sort = params.sort;
	if (groups) {
		delete params.groups;
	} else {
		groups = "";
	}
	if (params.group_column) {
		delete params.group_column;
	} else {
		groupColumn = "";
	}
	if (!filterEncoded) {
		filterEncoded = "";
	}

	if (!sort) sort = "";

	let decoded = decodeURIComponent(escape(atob(filterEncoded)));
	if (decoded[0] === NEW_WAY_TO_ENCODING_FILTER_SIGN) {
		decoded = decoded.substring(1);
	}

	const queryString = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}groups=${groups}${FILTER_SEPARATOR}group_column=${groupColumn}${FILTER_SEPARATOR}sort=${sort}${FILTER_SEPARATOR}${decoded}`;
	params.f = btoa(unescape(encodeURIComponent(queryString)));
	return params;
};

export const getDaysOfMonth = (): Record<string, number> => {
	const obj: Record<string, number> = {};
	for (let i: number = 1; i < 31; ++i) {
		obj[`${i}`] = i;
	}
	return obj;
};

export const getHoursOfDay = (): Record<string, string> => {
	const obj: Record<string, string> = {};
	for (let i: number = 0; i < 24; ++i) {
		obj[`${i}`] = `${i}:00 - ${i + 1}:00`;
	}
	return obj;
};

export const getOrganizationIds = (orgIds?: string): string | undefined => {
	const localStorage = window.localStorage;
	const dateFromStorage = localStorage.getItem("go_report.filters.organization_ids");
	if (dateFromStorage) {
		return dateFromStorage;
	}
	return orgIds;
};

export const getLastMonthRange = (): string => {
	const localStorage = window.localStorage;
	const dateFromStorage = localStorage.getItem("go_report.filters.date_range");
	if (dateFromStorage) {
		return dateFromStorage;
	}
	const firstDate = new Date();
	const lastDate = new Date();
	lastDate.setHours(5);
	lastDate.setMinutes(0);
	lastDate.setSeconds(0);
	firstDate.setHours(7);
	firstDate.setMinutes(0);
	firstDate.setSeconds(0);
	firstDate.setMonth(firstDate.getMonth() - 1);
	return filterParseDateRange(firstDate, lastDate);
};

export const getLastMonthFilterBetween = (config: LastMonthFilterBetweenConfig = {}): string => {
	const { getDateFromLocalStorage, includeBoundaryDays } = config;
	const localStorage = window.localStorage;
	const dateFromStorage = localStorage.getItem("go_report.filters.date_range");

	if (dateFromStorage && getDateFromLocalStorage) {
		return dateFromStorage;
	}

	const firstDate = new Date();
	const lastDate = new Date();

	if (includeBoundaryDays) {
		firstDate.setHours(0);
		firstDate.setMinutes(0);
		firstDate.setSeconds(0);
		lastDate.setHours(23);
		lastDate.setMinutes(59);
		lastDate.setSeconds(59);
	} else {
		firstDate.setHours(7);
		firstDate.setMinutes(0);
		firstDate.setSeconds(0);
		lastDate.setHours(5);
		lastDate.setMinutes(0);
		lastDate.setSeconds(0);
	}

	firstDate.setTime(firstDate.getTime() - 30 * 24 * 60 * 60 * 1000);
	return `bt=${filterParseDateBetween(firstDate, lastDate)}`;
};

export const getChartType = (key: string = "chart_type", chartTypeKey: string = "DAY_OF_WEEK"): string => {
	const localStorage = window.localStorage;
	const typeFromStorage = localStorage.getItem(`go_report.${key}`);
	if (typeFromStorage) return typeFromStorage;
	return chartTypeKey;
};

export const getShowPercentageInitialState = (config: ReportConfig) => {
	const showPercentageFromLocalStorage = localStorage.getItem(`go_report.${config.reportConfigId}.columns`);

	if (showPercentageFromLocalStorage) {
		const userDeselectedShowPercentage = showPercentageFromLocalStorage.includes("-show_percentage");
		const userSelectedShowPercentage = showPercentageFromLocalStorage.includes("show_percentage");
		if (userDeselectedShowPercentage) return false;
		if (userSelectedShowPercentage) return true;
	}

	return config.showPercent || false;
};

export const getReportCustomFieldsResources = (
	customFields: CustomFieldTemplateApi[],
	customFieldsDesiredResourceTypes: string[],
	t: TFunction
) => {
	const customFieldsWithoutResourceTypes = customFields.filter((field) => field.resources.length === 0);
	const customFieldsWithResourceTypes = customFields.filter((field) => field.resources.length > 0);

	const newCFsWithResourceTypes = customFieldsWithoutResourceTypes.flatMap((customField) =>
		customFieldsDesiredResourceTypes.map((desiredCustomFieldResourceType) => {
			return {
				...customField,
				resources: [
					{
						resource: desiredCustomFieldResourceType,
						name: t(`enums.custom_fields.resources.${desiredCustomFieldResourceType}`),
					},
				],
			};
		})
	);

	return [...customFieldsWithResourceTypes, ...newCFsWithResourceTypes];
};

export const parseOrganizationIds = (organizations: Record<string, any>[]) => {
	const savedOrganizationIds = getOrganizationIds();
	return savedOrganizationIds
		? savedOrganizationIds.split(FILTER_VALUE_SEPARATOR).map((id) => Number(id))
		: organizations.map(({ id }) => id);
};

export const getOrganizationIdsFromReportFetchRequest = (f: string) => {
	const decodedFParts = decodeURIComponent(escape(atob(f)))?.split(FILTER_SEPARATOR);
	if (decodedFParts[0] === NEW_WAY_TO_ENCODING_FILTER_SIGN) decodedFParts?.shift();

	const orgIds = decodedFParts?.find((part) => part.includes("organization_ids"))?.split("organization_ids|e=");
	if (orgIds && orgIds[1] && orgIds[1] !== "u" && orgIds[1] !== "a") {
		window.localStorage.setItem("go_report.filters.organization_ids", orgIds[1]);
		return orgIds[1].split(FILTER_VALUE_SEPARATOR).map((item) => parseInt(item));
	}
	return [];
};
