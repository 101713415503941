import React, { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { AvailabilityApi } from "go-component/services/types";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { MenuPriceListApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	priceList: MenuPriceListApi;
}

const PriceListForm: FC<Props> = ({ priceList }) => {
	const form = useForm<MenuPriceListApi>({
		criteriaMode: "all",
		defaultValues: priceList,
	});

	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setError,
		setValue,
		control,
		reset,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const [defaultAvailability, setDefaultAvailability] = useState<AvailabilityApi | undefined>(
		priceList?.availability
	);

	const onSubmit = handleSubmit(async (data: MenuPriceListApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		const params: Record<string, any> = { include: "availability" };
		try {
			setLoading(true);
			if (data.id) {
				const res = await api.organization().updatePriceList(data, params);
				setLoading(false);
				reset(res);
				setDefaultAvailability(res?.availability || undefined);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createPriceList(data, params);
				history.push(`/${organization.id}/menu/price_lists/${res.id}`);
				setLoading(false);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	useEffect(() => {
		registerObject(register, "availability", ["id", "name"]);
		setValue("availability", priceList?.availability);
	}, []);

	const onCreateAvailability = async (name: string) => {
		const availability = {
			name,
		} as AvailabilityApi;
		try {
			const res = await api.organization().createAvailability(availability);
			setDefaultAvailability(res);
			registerObject(register, `availability_id`, ["name", "id"]);
			setValue("availability_id", res.id, { shouldDirty: true });
			setValue("availability", {
				id: res.id,
				name: res.name,
				status: res.status,
			} as AvailabilityApi);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const searchAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch: form.watch },
		}),
		[setErrors, form.watch]
	) satisfies CustomValidationConfig;

	return (
		<FormDirty formState={formState} loading={loading} key="price-list-form" noValidate onSubmit={onSubmit}>
			<fieldset className="form-group no-gutters">
				<div className="row">
					<div className={"col-md-6"}>
						<FormInput
							name="name"
							register={register}
							label={t("lib:common.word.name")}
							errors={errors}
							customValidationConfig={nameValidationConfig}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormSelectGroup
							label={t("common.word.availability")}
							path={`/${organization.id}/settings/availabilities/`}
							name="availability_id"
							errors={errors}
							onCreateOption={onCreateAvailability}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							defaultValue={{
								label: defaultAvailability?.name,
								id: defaultAvailability?.id,
							}}
							loadOptions={searchAvailabilities}
							control={control}
							data-testid="availability_id"
						/>
					</div>
				</div>
			</fieldset>
		</FormDirty>
	);
};

export default PriceListForm;
