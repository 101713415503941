import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { replacePredefinedRanges } from "./services/comboDateUtils";
import { ReportComboContext } from "./services/context";
import { comboFlat, mapOldFilterToNew } from "./services/services";
import {
	CompareFormatReportComboApi,
	ConfigReportComboApi,
	DataColumnConfigReportComboApi,
	DataConfigReportComboApi,
	FlatReportComboApi,
	ReportResponseReportComboApi,
	ResponseReportComboApi,
	ResultDataFlatReportComboApi,
	ResultFlatReportComboApi,
	RowConfigReportComboApi,
	ValidationReportComboException,
} from "./services/types";
import ReportComboUtils from "./services/utils";

interface Props {
	config: ConfigReportComboApi;
	date?: Date;
}

const parseConfig = (kpiConfig: ConfigReportComboApi, date: Date): ConfigReportComboApi => {
	const newKpiConfig = JSON.parse(JSON.stringify(kpiConfig)) as ConfigReportComboApi;
	for (const kpiConfigData of newKpiConfig.data) {
		for (const kpiConfigDataColumn of kpiConfigData.columns) {
			kpiConfigDataColumn.filter = replacePredefinedRanges(kpiConfigDataColumn.filter, date);
		}
	}
	for (const kpiConfigColumn of newKpiConfig.columns) {
		if (kpiConfigColumn.values_dynamic) {
			const newValues = ReportComboUtils.getDynamicValues(kpiConfigColumn.values_dynamic);
			if (newValues) {
				kpiConfigColumn.values = newValues;
			}
		}
	}
	return newKpiConfig;
};

const ReportCombo = (props: Props) => {
	const [data, setData] = useState<ResponseReportComboApi>();
	const [loading, setLoading] = useState(true);
	const date = props.date ? props.date : new Date();
	const kpiConfig = parseConfig(props.config, date);
	const reportComboService = React.useContext(ReportComboContext);
	const cfgJson = JSON.stringify(props.config);
	const { addFlash } = useFlash();

	useEffect(() => {
		fetch();
	}, [cfgJson]);

	const fetchCompare = async (
		dataColumnConfig: DataColumnConfigReportComboApi,
		dataConfig: DataConfigReportComboApi
	) => {
		if (!dataColumnConfig.compare) return;

		const dataColumnCompareConfig = dataColumnConfig.compare;
		let newFilter = dataConfig.filter;
		let groups = ["NONE"];
		dataColumnCompareConfig.filter = replacePredefinedRanges(dataColumnCompareConfig.filter, date);

		if (dataColumnCompareConfig.filter) {
			newFilter += `${FILTER_SEPARATOR}${dataColumnCompareConfig.filter}`;
		}

		if (dataColumnConfig?.group && dataColumnConfig.group !== "NONE") {
			groups.push(dataColumnConfig.group);
		}
		groups.push(...dataConfig.groups.filter((x) => x !== "NONE"));

		if (groups.length === 0) groups = ["NONE"];

		const params = {
			f: btoa(unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${newFilter}`))),
			groups: groups.join(FILTER_VALUE_SEPARATOR),
		};

		try {
			const res = await reportComboService
				.api()
				.get(dataConfig.resource, dataConfig.id, dataColumnConfig.id, params);
			return res.data ? res.data : undefined;
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const fetch = async () => {
		setLoading(true);
		const reports: ReportResponseReportComboApi[] = [];
		for (const dataConfig of kpiConfig.data) {
			const filter = dataConfig.filter;
			for (const dataColumnConfig of dataConfig.columns) {
				let newFilter = filter;
				dataColumnConfig.filter = replacePredefinedRanges(dataColumnConfig.filter, date);
				if (dataColumnConfig.filter) {
					newFilter += `${FILTER_SEPARATOR}${dataColumnConfig.filter}`;
				}
				let groups = ["NONE"];
				if (dataColumnConfig?.group && dataColumnConfig.group !== "NONE") {
					groups.push(dataColumnConfig.group);
				}
				groups.push(...dataConfig.groups.filter((x) => x !== "NONE"));
				if (groups.length === 0) groups = ["NONE"];

				const params = {
					f: btoa(
						unescape(
							encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${newFilter}`)
						)
					),
					groups: groups.join(FILTER_VALUE_SEPARATOR),
				};

				try {
					const res = await reportComboService
						.api()
						.get(dataConfig.resource, dataConfig.id, dataColumnConfig.id, params);
					if (res) {
						if (dataColumnConfig.compare) {
							const compareResponse = await fetchCompare(dataColumnConfig, dataConfig);
							if (compareResponse) {
								res.compare_data = compareResponse;
							}
						}
						reports.push(res);
					}
				} catch (err) {
					handleError.alert(err, addFlash);
				}
			}
		}

		setData({
			id: kpiConfig.id,
			name: kpiConfig.name,
			reports,
		});
		setLoading(false);
	};

	const getUrl = (reportCfg: DataConfigReportComboApi, columnId?: string, aggregates?: string[]) => {
		if (!reportCfg) return undefined;
		const path = reportComboService.getPath(reportCfg.resource, reportCfg.filter);
		if (!path) return undefined;

		let filterString = reportCfg.filter;
		let groups = [] as string[];
		const configReportColumn = reportCfg.columns.filter((x) => x.id === columnId)[0];
		if (configReportColumn?.group && configReportColumn.group !== "NONE") {
			groups.push(encodeURIComponent(configReportColumn.group));
		}
		groups.push(...reportCfg.groups.filter((x) => x !== "NONE"));
		if (groups.length === 0) groups = ["NONE"];

		if (configReportColumn.filter) {
			filterString += `ç${configReportColumn.filter}`;
		}

		const filter = mapOldFilterToNew(filterString);
		let queryParams = `?f=${btoa(unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${filter}`)))}`;

		queryParams += `&groups=${encodeURIComponent(`${groups.join("¥")}`)}`;

		if (aggregates) {
			queryParams += `&c=${aggregates.join(",")}`;
		}

		return `${path}${queryParams}`;
	};

	const renderCompareValue = (value: string, compareValue: string, format: CompareFormatReportComboApi) => {
		if (format === "VALUE") return ReportComboUtils.renderValueObj(compareValue);

		if (format === "PERCENT") {
			// Convert the string values to numbers
			const numericValue = ReportComboUtils.getValueFromValueObj(value);
			const numericCompareValue = ReportComboUtils.getValueFromValueObj(compareValue);

			// Check if the numericCompareValue is zero to avoid division by zero
			if (numericCompareValue === 0) {
				return "~ %"; // or "N/A %" depending on what you prefer to show in this case
			}

			// Perform the division and multiply by 100 to get a percentage
			const percentage = (numericValue / numericCompareValue) * 100;

			// Return the result formatted as a percentage string
			return `${percentage.toFixed(0)} %`;
		}

		return compareValue;
	};

	const renderValue = (dataId: string, columnId: string, kpiData: ResultDataFlatReportComboApi) => {
		let url: string | undefined = undefined;
		if (dataId) {
			const reportCfg = kpiConfig.data.filter((x) => x.id === dataId)[0];
			const columnCfg = kpiConfig.columns.filter((column) => column.id === columnId)[0];
			const keys = Object.keys(kpiData.aggregate);
			url = getUrl(reportCfg, ReportComboUtils.getDataColumnId(columnCfg), keys);
		}
		return (
			<>
				{url ? (
					<Link to={url}>{ReportComboUtils.renderValueObj(kpiData.value)}</Link>
				) : (
					<>{ReportComboUtils.renderValueObj(kpiData.value)}</>
				)}
			</>
		);
	};

	const renderSubReports = (configRow: RowConfigReportComboApi, result: ResultFlatReportComboApi, id: string) => {
		if (!result.sub_report || result.sub_report.length <= 0) return null;

		return (
			<React.Fragment key={`${result.data_id}`}>
				{renderSingleRow(configRow, result, id)}
				{result.sub_report.map((subReport) => {
					const idSubReport = `${`${id}-${subReport.name}`}`;
					return (
						<React.Fragment key={idSubReport}>
							{renderSingleRow(configRow, subReport, idSubReport, 1)}
						</React.Fragment>
					);
				})}
			</React.Fragment>
		);
	};

	const renderSingleRow = (
		configRow: RowConfigReportComboApi,
		result: ResultFlatReportComboApi,
		id: string,
		level?: number
	) => {
		return (
			<>
				<tr>
					<td style={{ paddingLeft: level ? 25 * level : "inherit" }}>{result.name}</td>
					{result.data.map((kpiData) => {
						return (
							<td key={`${id}-${kpiData.id}`}>
								{renderValue(result.data_id, kpiData.column_id, kpiData)}
							</td>
						);
					})}
				</tr>
				{renderCompare(configRow, result.data, id, level)}
			</>
		);
	};

	const renderCompare = (
		configRow: RowConfigReportComboApi,
		subReport: ResultDataFlatReportComboApi[],
		id: string,
		level?: number
	) => {
		if (!configRow.compare) return null;

		return (
			<>
				{configRow.compare.map((compareItem) => {
					return (
						<tr key={`data-compare-${compareItem.name}`}>
							<td style={{ paddingLeft: level ? 25 * (level + 1) : 25 }}>{compareItem.name}</td>
							{subReport.map((kpiData) => {
								if (!kpiData.compare) return null;
								const compareValue = kpiData.compare.filter((x) => x.compare_id === compareItem.id)[0];
								return (
									<td key={`${id}-${kpiData.id}-compare`}>
										{renderCompareValue(kpiData.value, compareValue.value, compareItem.format)}
									</td>
								);
							})}
						</tr>
					);
				})}
			</>
		);
	};

	const renderLoading = () => {
		return (
			<Card className={"churn-card mb-4"} style={{ minHeight: 300 }}>
				<Card.Body>
					<LoadingContainer />
				</Card.Body>
			</Card>
		);
	};

	if (!kpiConfig) return <>ERROR</>;
	if (!data) return renderLoading();
	if (loading) return renderLoading();

	let results: FlatReportComboApi | undefined = undefined;

	try {
		results = comboFlat(data, kpiConfig);
	} catch (err: any) {
		if (err instanceof ValidationReportComboException) {
			return <>ERROR: {err.message || err.toString()}</>;
		}
		throw err;
	}

	return (
		<Card className={"churn-card mb-4"}>
			<Card.Body>
				<div className="mb-1 d-flex align-items-center">
					<h5 className="mb-0">{kpiConfig.name}</h5>
				</div>
				<span className="text-muted">{kpiConfig.description}</span>
				<div>
					<Table>
						<thead>
							<tr>
								<th>Nazwa</th>
								{kpiConfig.columns?.map((column) => {
									if (!column.values) {
										return <th key={`${column.id}-Summary`}>{column.name}</th>;
									}
									return (
										<React.Fragment key={`${column.id}-values`}>
											{column.values.map((columnValue) => {
												return <th key={`${column.id}-${columnValue.id}`}>{columnValue.id}</th>;
											})}
										</React.Fragment>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{results?.results?.map((result) => {
								const configRow = kpiConfig.rows.filter((x) => x.id === result.row_id)[0];
								const id = `${`data-${result.row_id}`}`;
								return (
									<React.Fragment key={`${id}`}>
										{result.sub_report && result.sub_report.length > 0
											? renderSubReports(configRow, result, id)
											: renderSingleRow(configRow, result, id)}
									</React.Fragment>
								);
							})}
						</tbody>
					</Table>
				</div>
			</Card.Body>
		</Card>
	);
};

export default ReportCombo;
