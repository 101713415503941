import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { FormCheck, FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { AvailabilityApi } from "go-component/services/types";
import FormatBoolean from "go-core/components/Formatters/FormatBoolean";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import Tag from "../../../../../../../../../../components/Common/Tag/Tag";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../images/svg/sortable-handle.svg";
import { ItemApi, ItemGroupApi, ModifierGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { getOrderTypes } from "../../utils";
import { getWeightedVariantOptions } from "../ItemGroupForm";
import BarcodesFormSelect from "./BarcodesFormSelect";
import ItemModalForm from "./ItemModalForm";

interface Props {
	form: UseFormReturn<ItemGroupApi>;
	itemModifierGroups: any[];
	modifierGroups: ModifierGroupApi[];
	errors?: Record<string, any>[];
	setFormApiErrors?: (errors: Record<string, any>[]) => void;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<ItemGroupApi>[]>>;
}

const ItemsList = (props: Props): JSX.Element => {
	const [showModal, setShowModal] = useState<number | null | undefined>();
	const {
		formState: { errors },
		control,
		register,
		watch,
		getValues,
		setValue,
		clearErrors,
	} = props.form;
	const isSortingRef = useRef(false);
	const { addFlash } = useFlash();
	const [firstAvailability, setFirstAvailability] = useState<any | undefined>({
		...getValues().items[0]?.availability,
	});
	const { fields, remove, append, replace } = useFieldArray({
		control,
		name: "items",
		keyName: "key",
	});
	const watchedItems = watch("items");
	const isMobile = useWindowSize().isMobile;
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const currency = organization?.currency ?? "PLN";
	const displayProductWeight =
		watch("items.0.weighted_type") === "WEIGHTED" || watch("items.0.weighted_type") === "WEIGHTED_AFTER";

	const handleSave = (item: ItemApi, index: number | null) => {
		if (item.price.amount === null) {
			item.price.amount = 0;
		}
		if (index === 0) {
			setFirstAvailability(item.availability);
		}
		if (index !== null) {
			clearErrors(`items.${index}.barcodes`);
			clearErrors(`items.${index}.stock_info.default_cost`);
			clearErrors(`items.${index}.stock_info.stock_amount`);
			item.position = index;
			setValue(`items.${index}`, { ...item }, { shouldDirty: true });
		} else {
			if (fields.length === 1) {
				registerItem(0, { ...watchedItems[0], name: "" } as ItemApi);
			}
			item.id = Math.random();
			append(item);
			registerItem(fields.length, item);
		}
		setShowModal(undefined);
	};

	useEffect(() => {
		if (getValues().items[0]?.availability_id === -1) {
			setValue("items.0.availability_id", undefined);
			if (firstAvailability) {
				document.getElementById("advanced_items_view")?.click();
			}
			setFirstAvailability(undefined);
		}
	}, [getValues().items[0]?.availability_id]);

	useEffect(() => {
		if (fields.length === 1) {
			if (fields[0].price) {
				if (Object.keys(fields[0].price).length === 0) {
					setValue(`items.0.price.amount`, undefined);
				}
			}
		}
	}, [fields]);

	const searchAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const registerItem = (index: number, item: ItemApi) => {
		const fieldPrefix = `items.${index}`;
		item.position = index;
		registerObject(register, fieldPrefix, [
			"sku",
			"name",
			"position",
			"discountable",
			"weighted_type",
			"availability",
			"availability_id",
			"open_price",
			"stock_info",
		]);
		registerObject(register, `${fieldPrefix}.price`, ["amount"]);
		registerObject(register, `${fieldPrefix}.stock_info`, ["default_cost", "stock_amount"]);
		registerObject(register, `${fieldPrefix}.availability`, ["name", "id"]);
		registerObject(register, `${fieldPrefix}.stock_info.default_cost`, ["amount"]);
		registerObject(register, `${fieldPrefix}.modifier_groups`, []);
		let newItemModifierGroups = props.itemModifierGroups;
		let assignAllModifierGroups = true;
		watchedItems.forEach((singleItem) => {
			if (singleItem.modifier_groups?.length !== props.itemModifierGroups.length) {
				assignAllModifierGroups = false;
				newItemModifierGroups.forEach((mod) => {
					if (!singleItem.modifier_groups.map((x) => x.modifier_group_id).includes(mod.modifier_group_id)) {
						newItemModifierGroups = newItemModifierGroups.filter(
							(f) => f.modifier_group_id !== mod.modifier_group_id
						);
					}
				});
			}
		});
		if (assignAllModifierGroups && index > 0) {
			item.modifier_groups = watchedItems[0].modifier_groups;
		} else {
			item.modifier_groups = newItemModifierGroups;
		}
		setValue(`items.${index}`, item);
	};

	const onRemoveItem = (index: number) => {
		if (fields.length === 2) {
			isSortingRef.current = true;
			if (index === 1) {
				setValue(`items.0.name`, fields[0].name);
			}
			const field = JSON.parse(JSON.stringify(watchedItems[index === 0 ? 1 : 0]));
			if (!field.availability_id) {
				setFirstAvailability(undefined);
			} else setFirstAvailability({ ...field.availability } as AvailabilityApi);
			const schemaField = {
				availability: null,
				availability_id: null,
				stock_info: null,
				...field,
			};
			replace([schemaField, schemaField]);
		}
		remove(index);
	};

	const updatePositions = (i: ItemApi[]) => {
		let items = [...i];
		if (!isSortingRef.current) return;
		items = items.filter((f) => !!f);
		isSortingRef.current = false;
		items.forEach((item, index) => {
			item.position = index;
			if (Object?.keys(item.price)?.length === 0) {
				item.price = {
					amount: null,
					currency,
				};
			}
			setValue(`items.${index}`, item);
		});
		replace(items);
		if (props.setFormApiErrors) {
			let newErrors = props.errors ? [...props.errors] : [];
			newErrors = newErrors.filter((err) => !err?.field?.includes("sku") && !err?.field?.includes("barcodes"));
			props.setFormApiErrors([...newErrors]);
			clearErrors(`items`);
		}
	};

	const onChangeAvailability = (id: any, obj: any) => {
		if (obj !== null) {
			setFirstAvailability({ id: obj.id, name: obj.label } as AvailabilityApi);
			registerObject(register, `items.0.availability`, ["name", "id"]);
			setValue("items.0.availability_id", obj.id);
			setValue("items.0.availability", { id: obj.id, name: obj.label } as AvailabilityApi);
		} else {
			setFirstAvailability(undefined);
			setValue("items.0.availability_id", undefined);
			setValue("items.0.availability", undefined);
		}
	};

	const onCreateAvailability = async (name: string) => {
		const availability = {
			name,
		} as AvailabilityApi;
		try {
			const res = await api.organization().createAvailability(availability);
			setFirstAvailability(res);
			registerObject(register, `items.0.availability`, ["name", "id"]);
			setValue("items.0.availability_id", res.id);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const priceValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors: props.setErrors,
		}),
		[props.setErrors]
	) satisfies CustomValidationConfig;

	return (
		<fieldset className="form-group">
			{fields.length > 1 && <h5>{t("modules.item_group.field.items.title")}</h5>}
			{fields.length === 1 ? (
				<div className="row">
					<div className="col-md">
						<LimitComponentVisibility
							classNames={"mb-3"}
							id="advanced_items_view"
							defaultVisible={
								!fields[0].discountable ||
								fields[0].open_price ||
								fields[0].weighted_type !== "DISABLED" ||
								fields[0]?.availability !== undefined ||
								fields[0]?.stock_info?.default_cost?.amount !== undefined ||
								fields[0]?.barcodes?.length > 0 ||
								fields[0].stock_info?.stock_amount !== undefined ||
								Boolean(fields[0].suspended) ||
								fields[0].order_types?.length > 0
							}
							helpText={`${t("modules.item_group.field.limit_visibility.helptext.description")}`}
							limitedContent={
								<>
									<div className="row mb-1">
										<div className="col-md-4">
											<FormMoneyInput
												control={control}
												currency={fields[0]?.price?.currency || currency}
												label={t("modules.item_group.field.price.title")}
												defaultValue={fields[0].price?.amount}
												name={`items.0.price.amount`}
												allowNegative={watch("type") === "PACKAGE"}
												errors={errors}
												customValidationConfig={priceValidationConfig}
											/>
										</div>
										<div className="col-md-4">
											<FormInput
												register={register}
												label={t("modules.item_group.field.sku.title")}
												defaultValue={fields[0].sku}
												name={`items.0.sku`}
												errors={errors}
											/>
										</div>
									</div>
									{watchedItems?.length === 1 &&
										props.errors &&
										props.errors.length > 0 &&
										props.errors.find((f) => f?.field?.includes(`items[0].name`)) && (
											<div className="invalid-feedback ms-0" style={{ display: "inline" }}>
												{t("constraints.item_with_name_exists")}
											</div>
										)}
								</>
							}
							unlimitedContent={
								<>
									<div className="row">
										{organization.more?.stock_tracking ? (
											<>
												<div className="col-md-4">
													<div className="form-group">
														<FormSelectGroup
															label={t("common.word.availability")}
															path={`/${organization.id}/settings/availabilities/`}
															name="items.0.availability_id"
															errors={errors}
															onChange={onChangeAvailability}
															onCreateOption={onCreateAvailability}
															getOptionLabel={(option) => option.label}
															getOptionValue={(option) => option.id}
															defaultValue={{
																label: firstAvailability?.name,
																id: firstAvailability?.id,
															}}
															loadOptions={searchAvailabilities}
															control={control}
															data-testid="items.0.availability_id"
														/>
													</div>
												</div>
												<div className="col-md-4">
													<FormMoneyInput
														control={control}
														currency={
															fields[0].stock_info?.default_cost?.currency || currency
														}
														label={t("common.word.cost")}
														defaultValue={fields[0].stock_info?.default_cost?.amount}
														name={`items.0.stock_info.default_cost.amount`}
														errors={errors}
														help={
															fields[0]?.stock_info?.external_cost
																? `${t(
																		"modules.item_group.field.external_cost.title"
																  )}: ${FormatMoney(
																		fields[0].stock_info.external_cost
																  )}`
																: ""
														}
													/>
												</div>
												<div className="col-md-4">
													<FormNumberInput
														control={control}
														name={`items.0.stock_info.stock_amount`}
														suffix={""}
														decimalScale={5}
														allowNegative={true}
														errors={errors}
														label={t("modules.item_group.field.stock_amount.title")}
														defaultValue={fields[0].stock_info?.stock_amount}
													/>
												</div>
											</>
										) : (
											<>
												<div className="col-md-6">
													<div className="form-group">
														<FormSelectGroup
															label={t("common.word.availability")}
															path={`/${organization.id}/settings/availabilities/`}
															name="items.0.availability_id"
															errors={errors}
															onChange={onChangeAvailability}
															onCreateOption={onCreateAvailability}
															getOptionLabel={(option) => option.label}
															getOptionValue={(option) => option.id}
															defaultValue={{
																label: firstAvailability?.name,
																id: firstAvailability?.id,
															}}
															loadOptions={searchAvailabilities}
															control={control}
															data-testid="items.0.availability_id"
														/>
													</div>
												</div>
												<div className="col-md-6">
													<FormMoneyInput
														control={control}
														currency={
															fields[0].stock_info?.default_cost?.currency || currency
														}
														label={t("common.word.cost")}
														defaultValue={fields[0].stock_info?.default_cost?.amount}
														name={`items.0.stock_info.default_cost.amount`}
														errors={errors}
													/>
												</div>
											</>
										)}
									</div>
									<div className="row mt-0 row-cols-md-3 align-items-center">
										<div className="col-md-6">
											<FormSelectGroup
												type="switch"
												label={t("modules.item_group.field.weighted.title")}
												control={control}
												isClearable={false}
												name="items.0.weighted_type"
												defaultValue={fields[0].weighted_type}
												options={getWeightedVariantOptions(t)}
												errors={errors}
												data-testid="items.0.weighted_type"
											/>
										</div>
										<div className="col-md-2" style={{ marginTop: "20px" }}>
											<FormCheck
												type="switch"
												label={t("modules.item_group.field.discountable.title")}
												register={register}
												name={`items.0.discountable`}
												defaultChecked={fields[0].discountable}
												errors={errors}
											/>
										</div>
										<div className="col-md-2" style={{ marginTop: "20px" }}>
											<FormCheck
												type="switch"
												label={t("modules.item_group.field.open_price.title")}
												register={register}
												name={`items.0.open_price`}
												defaultChecked={fields[0].open_price}
												errors={errors}
											/>
										</div>
									</div>
									{displayProductWeight && (
										<div className="row mt-0">
											<div className="col-md-6">
												<FormInput
													name="items.0.weight"
													errors={errors}
													register={register}
													label={t("modules.item_group.field.weight.title")}
												/>
											</div>
										</div>
									)}
									<div className="row">
										<div className="col-md-6">
											<BarcodesFormSelect
												form={props.form}
												prefix="items.0."
												errors={errors}
												barcodes={fields[0].barcodes}
											/>
										</div>
										<div className="col-md-6">
											<FormSelectGroup
												control={control}
												label={t("modules.item_group.field.order_type.title")}
												loadOptions={() => getOrderTypes(t)}
												name="items.0.order_types"
												errors={errors}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
												defaultValue={fields[0].order_types?.map((orderType) => {
													return {
														id: orderType,
														label: t(`enums.orders.types.${orderType}`),
													};
												})}
												isMulti
												data-testid="items.0.order_types"
											/>
										</div>
									</div>
									{props.errors &&
										props.errors.length > 0 &&
										props.errors.find((f) =>
											f?.field?.includes(`items[${fields[0].position}].barcodes`)
										) && (
											<div className="invalid-feedback ms-0" style={{ display: "inline" }}>
												{t("constraints.duplicate_barcode", {
													value: props.errors?.find((f) =>
														f.field.includes(`items[${fields[0].position}].barcodes`)
													)?.barcode,
												})}
											</div>
										)}
									<div className="row align-items-center row-cols-md-3">
										<div className="col-md-2">
											<FormCheck
												type="switch"
												register={register}
												style={{ marginTop: watch("items.0.suspended") ? "16px" : "0" }}
												label={t("modules.item_group.field.suspension.title")}
												name="items.0.suspended"
												errors={errors}
											/>
										</div>
										{watch("items.0.suspended") && (
											<>
												<div className="col-md-5">
													<FormDatePicker
														errors={errors}
														control={control}
														name="items.0.suspension.until"
														label={t("modules.item_group.field.untill.title")}
													/>
												</div>
												<div className="col-md-5">
													<FormInput
														name="items.0.suspension.reason"
														errors={errors}
														register={register}
														label={t("modules.item_group.field.reason.title")}
													/>
												</div>
											</>
										)}
									</div>
								</>
							}
						></LimitComponentVisibility>
					</div>
				</div>
			) : (
				<>
					{isMobile ? (
						<div className="div-table">
							<div className="div-table-header"></div>
							<ReactSortable
								handle=".sortable-handler"
								style={{ display: "contents" }}
								list={watchedItems}
								onUpdate={() => (isSortingRef.current = true)}
								setList={(newFields) => updatePositions(newFields)}
							>
								{watchedItems.map((item, index) => {
									const stockAmountHasError = Boolean(
										props.errors?.find(
											(error) => error.field === `items[${index}].stock_info.stock_amount`
										)
									);
									const defaultCostHasError = Boolean(
										props.errors?.find(
											(error) => error.field === `items[${index}].stock_info.default_cost.amount`
										)
									);
									return (
										<div key={item.id} className={"div-table-body"}>
											<div className={"div-table-row"}>
												<div className="div-table-cell action">
													<SortableHandleSvg className="sortable-handler" />
												</div>
												<div className="div-table-cell input-cell">
													<div className="d-flex">
														<div className="w-100">
															<FormInput
																register={register}
																name={`items.${index}.name`}
																defaultValue={item.name}
																errors={errors}
															/>
														</div>
														<FormMoneyInput
															control={control}
															currency={item?.price?.currency || currency}
															allowNegative={watch("type") === "PACKAGE"}
															name={`items.${index}.price.amount`}
															errors={errors}
															customValidationConfig={priceValidationConfig}
														/>
													</div>
												</div>
												<div className="div-table-cell action">
													<RemoveSVG className={"icon"} onClick={() => onRemoveItem(index)} />
												</div>
											</div>
											<div className={"div-table-row"}>
												<div className="div-table-cell action"></div>
												<div className="div-table-cell" style={{ borderTop: "none" }}>
													{item.sku && (
														<>
															<div className="d-flex">
																<div className="item-advanced-table-label">
																	{`${t("modules.item_group.field.sku.title")}:`}
																</div>
																<div className="item-advanced-table-info">
																	{item.sku}
																</div>
															</div>
															{props.errors &&
																props.errors.length > 0 &&
																props.errors.find(
																	(f) => f.field === `items[${item.position}].sku`
																) && (
																	<div
																		className="invalid-feedback ms-0"
																		style={{ display: "inline" }}
																	>
																		{t("constraints.entity_with_name_exists")}
																	</div>
																)}
														</>
													)}
													{item.stock_info?.default_cost &&
														item.stock_info?.default_cost?.amount !== null &&
														item.stock_info?.default_cost?.amount !== undefined &&
														item.stock_info?.default_cost?.amount >= 0 && (
															<div className="d-flex">
																<div className="item-advanced-table-label">
																	{`${t("modules.item_group.field.cost.title")}:`}
																</div>
																<div
																	className={`item-advanced-table-info ${
																		stockAmountHasError && "text-danger"
																	}`}
																>
																	{FormatMoney({
																		amount: item.stock_info?.default_cost.amount,
																		currency:
																			item.stock_info?.default_cost?.currency ||
																			organization.currency ||
																			"",
																	})}
																</div>
															</div>
														)}
													{item.stock_info?.stock_amount &&
														item.stock_info?.stock_amount >= 0 && (
															<div className="d-flex">
																<div className="item-advanced-table-label">
																	{`${t(
																		"modules.item_group.field.stock_amount.title"
																	)}:`}
																</div>
																<div
																	className={`item-advanced-table-info ${
																		defaultCostHasError && "text-danger"
																	}`}
																>
																	{item.stock_info?.stock_amount}
																</div>
															</div>
														)}
													{item.availability_id && (
														<div className="d-flex">
															<div className="item-advanced-table-label">
																{t("common.word.availability")}:
															</div>
															<div className="item-advanced-table-info">
																<Link
																	target={"_blank"}
																	rel={"noreferrer"}
																	to={`/${organization.id}/settings/availabilities/${item.availability_id}`}
																>
																	{index === 0 && firstAvailability
																		? firstAvailability.name
																		: item.availability?.name}
																</Link>
															</div>
														</div>
													)}
													{item.description && (
														<div className="d-flex">
															<div className="item-advanced-table-label">
																{t("lib:common.word.description")}:
															</div>
															<div className="item-advanced-table-info">
																{item.description}
															</div>
														</div>
													)}
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("modules.item_group.field.discountable.title")}:
														</div>
														<div className="item-advanced-table-info">
															<FormatBoolean value={item.discountable} />
														</div>
													</div>
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("modules.item_group.field.weighted.title")}:
														</div>
														<div className="item-advanced-table-info">
															{t(`enums.item_groups.weighted.${item.weighted_type}`)}
														</div>
													</div>
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("modules.item_group.field.suspension.title")}:
														</div>
														<div className="item-advanced-table-info">
															{t(`common.word.${item.suspended ? "yes" : "no"}`, {
																ns: "lib",
															})}
														</div>
													</div>
													{item.order_types?.length > 0 && (
														<div className="d-flex">
															<div className="item-advanced-table-label">
																{t("modules.item_group.field.order_type.title")}:
															</div>
															<div className="item-advanced-table-info">
																{item.order_types
																	.map((orderType) =>
																		t(`enums.orders.types.${orderType}`)
																	)
																	.join(", ")}
															</div>
														</div>
													)}
													{item.barcodes?.length > 0 && (
														<>
															<div className="d-flex">
																<div className="item-advanced-table-label">
																	{t("modules.item_group.field.barcodes.title")}:
																</div>
																<div className="item-advanced-table-info">
																	{item.barcodes?.map(
																		(barcode: any, barcodeIndex) => {
																			return (
																				<Tag key={barcodeIndex}>
																					{barcode.value
																						? barcode.value
																						: barcode}
																				</Tag>
																			);
																		}
																	)}
																</div>
															</div>
															{props.errors &&
																props.errors.length > 0 &&
																props.errors.find((f) =>
																	f?.field?.includes(
																		`items[${item.position}].barcodes`
																	)
																) && (
																	<div
																		className="invalid-feedback ms-0"
																		style={{ display: "inline" }}
																	>
																		{t("constraints.duplicate_barcode", {
																			value: props.errors?.find((f) =>
																				f.field.includes(
																					`items[${item.position}].barcodes`
																				)
																			)?.barcode,
																		})}
																	</div>
																)}
														</>
													)}
													{showModal === index && (
														<ItemModalForm
															errors={props.errors}
															item={
																index === 0
																	? {
																			...item,
																			name: watch("name"),
																	  }
																	: item
															}
															allowNegativePrice={watch("type") === "PACKAGE"}
															handleSave={(newItem) => handleSave(newItem, index)}
															handleClose={() => setShowModal(undefined)}
														/>
													)}
													<Button
														variant="add"
														style={{
															display: "block",
															marginTop: "8px",
															width: `calc(100% + 16px)`,
															marginLeft: "-8px",
														}}
														onClick={() => setShowModal(index)}
													>
														{t("common.action.adjust")}...
													</Button>
												</div>
												<div className="div-table-cell action"></div>
											</div>
										</div>
									);
								})}
							</ReactSortable>
						</div>
					) : (
						<table className="table table-form advanced">
							<thead>
								<tr>
									<th className="action" />
									<th className="w-25">{t("lib:common.word.name")}</th>
									<th>{t("modules.item_group.field.price.title")}</th>
									<th className="w-50">{t("lib:common.word.description")}</th>
									<th className="action" />
								</tr>
							</thead>
							<ReactSortable
								handle=".sortable-handler"
								tag="tbody"
								list={watchedItems}
								onUpdate={() => (isSortingRef.current = true)}
								setList={(newFields) => updatePositions(newFields)}
							>
								{watchedItems.map((item, index) => {
									const stockAmountHasError = Boolean(
										props.errors?.find(
											(error) => error.field === `items[${index}].stock_info.stock_amount`
										)
									);
									const defaultCostHasError = Boolean(
										props.errors?.find(
											(error) => error.field === `items[${index}].stock_info.default_cost.amount`
										)
									);
									return (
										<tr key={fields[index].key}>
											{showModal === index && (
												<ItemModalForm
													errors={props.errors}
													allowNegativePrice={watch("type") === "PACKAGE"}
													itemGroupName={getValues("name")}
													item={{
														...item,
														...(index === 0 &&
															firstAvailability &&
															item.availability?.id !== item.availability_id && {
																availability: firstAvailability,
															}),
														name:
															item.name === getValues("name")
																? t("common.word.default")
																: item.name,
													}}
													handleSave={(newItem) => handleSave(newItem, index)}
													handleClose={() => setShowModal(undefined)}
												/>
											)}
											<td className="action">
												<SortableHandleSvg
													style={{ marginTop: "10px" }}
													className="sortable-handler"
												/>
											</td>
											<td style={{ paddingLeft: "6px" }}>
												<FormInput
													register={register}
													name={`items.${index}.name`}
													placeholder={t("common.word.default")}
													defaultValue={
														item.name === getValues().name || item.name.length === 0
															? undefined
															: item.name
													}
													errors={errors}
												/>
												{props.errors &&
													props.errors.length > 0 &&
													props.errors.find((f) =>
														f?.field?.includes(`items[${item.position}].name`)
													) && (
														<div
															className="invalid-feedback ms-0"
															style={{ display: "inline" }}
														>
															{t("constraints.entity_with_name_exists")}
														</div>
													)}
											</td>
											<td>
												<FormMoneyInput
													control={control}
													currency={item?.price?.currency || currency}
													defaultValue={item.price?.amount}
													name={`items.${index}.price.amount`}
													allowNegative={watch("type") === "PACKAGE"}
													errors={errors}
													customValidationConfig={priceValidationConfig}
												/>
											</td>
											<td className="variant-info">
												<span className="float-end">
													<div className="action-ref" onClick={() => setShowModal(index)}>
														{t("common.action.adjust")}...
													</div>
												</span>
												{item.sku && (
													<>
														<div className="d-flex">
															<div className="item-advanced-table-label">
																{`${t("modules.item_group.field.sku.title")}:`}
															</div>
															<div className="item-advanced-table-info">{item.sku}</div>
														</div>
														{props.errors &&
															props.errors.length > 0 &&
															props.errors.find(
																(f) => f.field === `items[${item.position}].sku`
															) && (
																<div
																	className="invalid-feedback ms-0"
																	style={{ display: "inline" }}
																>
																	{t("constraints.entity_with_name_exists")}
																</div>
															)}
													</>
												)}
												{item.stock_info?.default_cost &&
													item.stock_info?.default_cost?.amount !== null &&
													item.stock_info?.default_cost?.amount !== undefined &&
													item.stock_info?.default_cost?.amount >= 0 && (
														<div className="d-flex">
															<div className="item-advanced-table-label">
																{`${t("common.word.cost")}:`}
															</div>
															<div
																className={`item-advanced-table-info ${
																	defaultCostHasError && "text-danger"
																}`}
															>
																{FormatMoney({
																	amount: item.stock_info?.default_cost.amount,
																	currency:
																		item.stock_info?.default_cost?.currency ||
																		organization.currency ||
																		"",
																})}
															</div>
														</div>
													)}
												{!!item.stock_info && "stock_amount" in item.stock_info && (
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("modules.item_group.field.stock_amount.title")}
														</div>
														<div
															className={`item-advanced-table-info ${
																stockAmountHasError && "text-danger"
															}`}
														>
															{item.stock_info?.stock_amount}
														</div>
													</div>
												)}
												{item.availability_id && (
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("common.word.availability")}:
														</div>
														<div className="item-advanced-table-info">
															<Link
																target={"_blank"}
																rel={"noreferrer"}
																to={`/${organization.id}/settings/availabilities/${item.availability_id}`}
															>
																{item.availability?.name
																	? item.availability?.name
																	: firstAvailability?.name}
															</Link>
														</div>
													</div>
												)}
												{item.description && (
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("lib:common.word.description")}:
														</div>
														<div className="item-advanced-table-info">
															{item.description}
														</div>
													</div>
												)}
												<div className="d-flex">
													<div className="item-advanced-table-label">
														{t("modules.item_group.field.discountable.title")}:
													</div>
													<div className="item-advanced-table-info">
														<FormatBoolean value={item.discountable} />
													</div>
												</div>
												<div className="d-flex">
													<div className="item-advanced-table-label">
														{t("modules.item_group.field.weighted.title")}:
													</div>
													<div className="item-advanced-table-info">
														{t(`enums.item_groups.weighted.${item.weighted_type}`)}
													</div>
												</div>
												<div className="d-flex">
													<div className="item-advanced-table-label">
														{t("modules.item_group.field.suspension.title")}:
													</div>
													<div className="item-advanced-table-info">
														{t(`common.word.${item.suspended ? "yes" : "no"}`, {
															ns: "lib",
														})}
													</div>
												</div>
												{item.order_types?.length > 0 && (
													<div className="d-flex">
														<div className="item-advanced-table-label">
															{t("modules.item_group.field.order_type.title")}:
														</div>
														<div className="item-advanced-table-info">
															{item.order_types
																.map((orderType) =>
																	t(`enums.orders.types.${orderType}`)
																)
																.join(", ")}
														</div>
													</div>
												)}
												{item.barcodes?.length > 0 && (
													<>
														<div className="d-flex">
															<div className="item-advanced-table-label">
																{t("modules.item_group.field.barcodes.title")}:
															</div>
															<div className="item-advanced-table-info">
																{item.barcodes?.map((barcode: any, barcodeIndex) => {
																	return (
																		<Tag key={barcodeIndex}>
																			{barcode.value ? barcode.value : barcode}
																		</Tag>
																	);
																})}
															</div>
														</div>
														{props.errors &&
															props.errors.length > 0 &&
															props.errors.find((f) =>
																f?.field?.includes(`items[${item.position}].barcodes`)
															) && (
																<div
																	className="invalid-feedback ms-0"
																	style={{ display: "inline" }}
																>
																	{t("constraints.duplicate_barcode", {
																		value: props.errors?.find((f) =>
																			f.field.includes(
																				`items[${item.position}].barcodes`
																			)
																		)?.barcode,
																	})}
																</div>
															)}
													</>
												)}
											</td>
											<td className="action">
												<RemoveSVG className={"icon"} onClick={() => onRemoveItem(index)} />
											</td>
										</tr>
									);
								})}
							</ReactSortable>
						</table>
					)}
				</>
			)}
			{showModal === null && (
				<ItemModalForm
					errors={props.errors}
					handleSave={(data) => handleSave(data, null)}
					allowNegativePrice={watch("type") === "PACKAGE"}
					handleClose={() => setShowModal(undefined)}
				/>
			)}
			<Button variant="add" type="button" onClick={() => setShowModal(null)}>
				+ {t("modules.item_group.header.add_variant.title")}
			</Button>
		</fieldset>
	);
};
export default ItemsList;
