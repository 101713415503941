import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { FormControlProps } from "react-bootstrap/FormControl";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { UseFormRegister, UseFormWatch } from "react-hook-form/dist/types/form";
import { hasErrors } from "..";
import { useCustomValidation } from "../hooks";
import { CustomValidationConfig } from "../services/types";
import { isConstraintARequiredConstraint } from "../services/utils";
import { FormErrorMessage } from "./FormErrorMessage";

interface Props extends FormControlProps {
	label?: string;
	placeholder?: string;
	name: string;
	help?: string;
	errors: any;
	options?: any;
	register: UseFormRegister<any>;
	firstInput?: boolean;
	customValidationConfig?: CustomValidationConfig<{
		watch: UseFormWatch<any>;
	}>;
}
export const FormInputSelect: BsPrefixRefForwardingComponent<"input", Props> = React.forwardRef((props: Props) => {
	const { label, name, errors, register, customValidationConfig, ...controlProps } = props;

	const value =
		props.value || (customValidationConfig?.utils.watch ? customValidationConfig.utils.watch(name) : null);

	const isFieldRequired = useMemo(
		() => customValidationConfig?.types.some(({ constraint }) => isConstraintARequiredConstraint(constraint)),
		[customValidationConfig?.types]
	);

	useCustomValidation({ name, value, customValidationConfig });

	return (
		<Form.Group className="form-group" controlId={name}>
			{!props.firstInput && label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
			<Form.Control
				as="select"
				{...register(name, {
					// setValueAs: (v:any) => v === "" ? undefined : v,
					setValueAs: (v: any) => {
						return v === "" ? undefined : v;
					},
				})}
				isInvalid={hasErrors(errors, name)}
				name={name}
				{...controlProps}
			>
				{props.placeholder && <option value={""}>{props.placeholder}</option>}
				{props.options.map((option: any) => {
					return (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					);
				})}
			</Form.Control>
			{props.firstInput && label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
			<FormErrorMessage errors={errors} name={name} />
		</Form.Group>
	);
});

FormInputSelect.displayName = "FormInputSelect";
