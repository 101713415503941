import React, { useContext, useEffect, useState } from "react";
import { generateUUID } from "go-report-combo/services/services";
import CreateTemplateReportComboComponent from "go-report-combo/template/Template/CreateTemplateReportComboComponent";
import { TemplateReportCombo } from "go-report-combo/template/Template/types";
import queryString from "query-string";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { SegmentContext } from "go-segment/context";
import { SegmentApi } from "go-segment/services/types";
import { CATALOG_TEMPLATE_LIST } from "../../services/catalogs";
import ComboTemplateUtils from "../../services/comboTemplateUtils";

const comboName = "REPORT_COMBO";
const resourceType = "REPORT_COMBO";

const catalog = CATALOG_TEMPLATE_LIST;
const UserReportsComboCreatePage = () => {
	const [template, setTemplate] = useState<TemplateReportCombo | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [segment, setSegment] = useState<SegmentApi | undefined>(undefined);
	const [errors, setErrors] = useState<ApiError[]>([]);
	const segmentContext = useContext(SegmentContext);
	const location = useLocation();
	const history = useHistory();
	const queryParams = queryString.parse(location.search);
	const segmentId = queryParams.segmentId;
	const { addFlash } = useFlash();

	useEffect(() => {
		fetchSegments();
	}, []);

	const fetchSegments = async () => {
		if (!segmentId) {
			setTemplate({
				id: generateUUID(),
				name: "Raport przykład",
				templates: [],
			} as TemplateReportCombo);
			setLoading(false);
			return;
		}

		let filter = `resource_type|e=${resourceType}${FILTER_SEPARATOR}list_name|e=${comboName}`;
		filter += `${FILTER_SEPARATOR}id|e=${segmentId}`;
		const segments = await segmentContext.api().getSegments({
			f: btoa(unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${filter}`))),
		});
		const segment = segments.data.data[0];
		setSegment(segment);
		if (segment) {
			setTemplate(ComboTemplateUtils.segmentToTemplate(segment));
		} else {
			setTemplate(undefined);
		}
		setLoading(false);
	};

	if (loading) {
		return <LoadingContainer />;
	}

	const saveTemplate = async (template: TemplateReportCombo) => {
		if (!template) return;

		const value = JSON.stringify(template);
		const newSegment = {
			name: template.name,
			slug: template.id,
			list_name: comboName,
			resource_type: resourceType,
			value,
		} as SegmentApi;

		let segmentResponse = undefined;
		try {
			if (segment) {
				newSegment.id = segment.id;
				const response = await segmentContext.api().updateSegment(newSegment);
				segmentResponse = response.data.data;
			} else {
				const response = await segmentContext.api().saveSegment(newSegment);
				segmentResponse = response.data.data;
			}
			history.push(`/combo_reports/${segmentResponse.id}`);
		} catch (err) {
			const errors = handleException(err);
			setErrors(errors);
			handleError.alert(err, addFlash);
		}
	};

	const onClose = async () => {
		if (segment) {
			history.push(`/combo_reports/${segment.id}`);
		} else {
			history.push("/combo_reports");
		}
	};

	const onRemove = async () => {
		try {
			if (segment) {
				await segmentContext.api().removeSegment(segment.id);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		history.push("/combo_reports");
	};

	if (!template) {
		return <LoadingContainer />;
	}

	if (segmentId && !segment) {
		return <div>Segment nie istnieje</div>;
	}

	return (
		<CreateTemplateReportComboComponent
			catalog={catalog}
			template={template}
			errors={errors}
			onClose={onClose}
			onRemove={onRemove}
			onSave={saveTemplate}
		/>
	);
};

export default UserReportsComboCreatePage;
