import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectUser } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	CompanyInfoApi,
	InvoiceApi,
	InvoiceDetailsApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../components/Errors/CompanyInfoNotFull";
import InvoiceDetailsNotExist from "../../../components/Errors/InvoiceDetailsNotExist";
import InvoiceForm from "../../../components/Form/InvoiceForm";

const OrganizationInvoicesProformaNewPage = (): JSX.Element => {
	const dateNow = new Date();
	const { addFlash } = useFlash();
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const { t } = useTranslation();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const user = useSelector(selectUser);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.field.proforma.title_new.title"));
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		Promise.all([
			api.organization().getInvoiceDetails(),
			api.organization().getCompanyInfo(),
			api.organization().getCustomFields(cfParams),
		])
			.then(([invoiceResponse, companyInfoResponse, cfResponse]) => {
				setInvoiceDetails(invoiceResponse);
				setCompanyInfo(companyInfoResponse);
				const data = invoiceResponse;
				setInvoice({
					type: "NET",
					dates: {
						issued_at: formatDateToString(dateNow),
						sold_at: formatDateToString(dateNow),
						payment_due_date: formatDateToString(new Date(dateNow.setDate(dateNow.getDate() + 14))),
					},
					document_type: "PROFORMA",
					signed_by: data.signed_by_logged_user ? user.name : data.signed_by,
					comment: data.default_comment,
					place_of_issue: data.place_of_issue,
				} as InvoiceApi);
				setCustomFieldsConfig(cfResponse);
			})
			.catch((e) => {
				handleError.alert(e, addFlash);
			});
	}, []);

	if (!invoice || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const checkIfCanRenderInvoiceForm = () => {
		let result = true;
		if (invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)))
			result = false;
		if (invoice && (!companyInfo || !companyInfo.name || !companyInfo.tax_id_no)) result = false;
		return result;
	};

	return (
		<>
			<Header title={t("modules.invoice.field.proforma.title_new.title")} />
			{invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)) && (
				<InvoiceDetailsNotExist />
			)}
			{invoice &&
				(!companyInfo ||
					Object.keys(companyInfo).length === 0 ||
					!companyInfo.company_name ||
					!companyInfo.tax_id_no) && <CompanyInfoNotFull />}
			{checkIfCanRenderInvoiceForm() && (
				<InvoiceForm
					invoiceDetails={invoiceDetails}
					invoice={invoice}
					customFieldsConfig={customFieldsConfig}
				/>
			)}
		</>
	);
};
export default OrganizationInvoicesProformaNewPage;
