import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";

export enum VirtualTableSortingModeEnum {
	"ASC" = "+",
	"DESC" = "-",
	"EMPTY" = "",
}

export interface VirtualTableListSelectedSort {
	columnName: string;
	sortingMode: VirtualTableSortingModeEnum;
}

export interface VirtualTableColumn {
	id: string;
	name: string;
}

export interface VirtualTableWrapperHandle {
	search: string;
	sortings: VirtualTableListSelectedSort[];
	selectedFilters: ListSelectedFilter[];
	visibleDefaultColumns: VirtualTableColumn[];
	selectedColumns: string[];
}

export interface VirtualColumnAction {
	title: string;
	onClick: () => void;
}

export interface VirtualTableAdditionalColumn {
	columns: VirtualTableColumn[];
	id: string;
}
