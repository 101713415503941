import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { deliveryEmployeesLastActivityOptions } from "../../services/orderFilters";
import { LiveOrderFilterOption, LiveOrdersFilters } from "../../services/types";
import { inputCustomStyles } from "./utils";

interface Props {
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
}

const LiveOrdersEmployeesLastActivityFilter = ({ innerFilters, setInnerFilters }: Props): JSX.Element => {
	const { t } = useTranslation();

	const onChange = (e: LiveOrderFilterOption | null) => {
		if (e) {
			setInnerFilters({
				...innerFilters,
				employeesLastActivity: e,
			});
		}
	};

	return (
		<div className="mt-3 mb-2">
			<Form.Label>{t("modules.live_order.action.last_employees_activity.title")}</Form.Label>
			<Select
				styles={inputCustomStyles}
				className="form-select"
				value={innerFilters.employeesLastActivity}
				defaultValue={innerFilters.employeesLastActivity}
				onChange={onChange}
				getOptionLabel={(opt) => opt.label}
				getOptionValue={(opt) => opt.id}
				options={deliveryEmployeesLastActivityOptions(t)}
			/>
		</div>
	);
};

export default LiveOrdersEmployeesLastActivityFilter;
