import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck, FormInput } from "go-form";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { DiscountApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	form: UseFormReturn<DiscountApi>;
	discount: DiscountApi;
}

const DiscountFormAdvanced = ({ form, discount }: Props) => {
	const {
		getValues,
		formState: { errors },
		register,
		control,
		watch,
	} = form;
	const { t } = useTranslation();
	const priceSortTypeValue = watch("price_sort_type");

	const searchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchRoles = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getRolesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const getRoleTags = (roles?: Record<string, any>[]) => {
		return roles?.map((role) => {
			return {
				label: role.role_name,
				id: Number(role.id),
			};
		});
	};

	return (
		<fieldset className="form-group no-gutters">
			<h5>{t("lib:common.word.advanced")}</h5>
			<FormCheck
				type="switch"
				register={register}
				label={t("modules.discount.field.hidden.title")}
				name="hidden"
				defaultChecked={getValues().hidden}
				errors={errors}
			/>
			<FormCheck
				type="switch"
				register={register}
				label={t("modules.discount.field.editable.title")}
				name="editable"
				errors={errors}
			/>
			<>
				<FormCheck
					errors={errors}
					name="price_sort_type"
					register={register}
					label={t("modules.discount.field.apply_to_position_with_the_highest_price.title")}
					type="switch"
					checked={priceSortTypeValue === "DESC" || priceSortTypeValue === true}
				/>
				<FormNumberInput
					errors={errors}
					name="usage_limit"
					control={control}
					label={t("modules.discount.field.usage_limit.title")}
					suffix=""
				/>
			</>
			<FormSelectGroup
				label={t("common.word.payment_method")}
				name="payment_method_id"
				errors={errors}
				getOptionLabel={(option) => option.label}
				getOptionValue={(option) => option.id}
				defaultValue={{
					label: discount.payment_method?.name,
					id: discount.payment_method?.id,
				}}
				loadOptions={searchPaymentMethods}
				control={control}
				data-testid="payment_method_id"
			/>
			<FormSelectGroup
				label={t("modules.discount.field.role.title")}
				isMulti
				name="roles"
				errors={errors}
				defaultValue={getRoleTags(discount.roles)}
				getOptionLabel={(option) => option.label}
				getOptionValue={(option) => option.id}
				loadOptions={searchRoles}
				control={control}
				data-testid="roles"
			/>
			<FormInput
				name="reference_id"
				register={register}
				errors={errors}
				label={t("common.word.reference", { ns: "lib" })}
			/>
			<FormCheck
				errors={errors}
				name="enforce_positive_order_price"
				register={register}
				label={t("modules.discount.field.enforce_positive_order_price.title")}
				type="switch"
			/>
		</fieldset>
	);
};

export default DiscountFormAdvanced;
