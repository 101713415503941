import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	status: string;
	translation?: string;
	className?: "primary" | "success" | "danger" | "warning" | "secondary" | "blue";
}

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:enums.common.status.ENABLED");
	t("lib:enums.common.status.DELETED");
	t("lib:enums.common.status.DISABLED");
	t("lib:enums.common.status.ERROR");
	t("lib:enums.common.status.SUCCESS");
};

export const getResourceStatusClass = (
	status: string
): "primary" | "success" | "danger" | "warning" | "secondary" | undefined => {
	switch (status) {
		case "ENABLED":
		case "FINISHED":
			return "success";
		case "DELETED":
		case "DISABLED":
		case "ERROR":
			return "danger";
		case "NEW":
			return "primary";
	}
	return undefined;
};

const getStatusTranslationKey = (status: string) => {
	return `enums.common.status.${status}`;
};
const FormatResourceStatusLabel: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	let labelClass = props.className;
	if (!labelClass) {
		labelClass = getResourceStatusClass(props.status);
	}

	if (labelClass === "warning") {
		labelClass += " text-dark";
	}

	let translation = props.translation;
	if (!translation) translation = t(getStatusTranslationKey(props.status), { ns: ["translation", "lib"] });
	return <span className={`badge ${labelClass ? `bg-${labelClass}` : ""}`}>{translation}</span>;
};

export default FormatResourceStatusLabel;
