import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDirty } from "go-form/components/FormDirty";
import { AvailabilityApi, AvailabilityHourItem, AvailabilityRequests } from "../../../services/types";
import { parseTime } from "./AvailabilityTimePicker";
import { DatesTableForm, parseDatesToRange } from "./DatesTableForm";
import { HoursTableForm } from "./HoursTableForm";

interface Props {
	availability: AvailabilityApi;
	requests: AvailabilityRequests;
	organizationId?: number;
}

export const AvailabilityForm = (props: Props): JSX.Element => {
	const {
		requests: { createAvailability, updateAvailability },
		organizationId,
	} = props;
	const { t } = useTranslation();
	const history = useHistory();
	const [apiHours, setApiHours] = useState<AvailabilityHourItem[]>(
		props.availability.hours ? props.availability.hours : []
	);
	const form = useForm<AvailabilityApi>({
		criteriaMode: "all",
		defaultValues: props.availability,
	});
	const [hoursVisible, setHoursVisible] = useState(
		props.availability.hours ? props.availability.hours.length > 0 : false
	);
	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setError,
		reset,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: AvailabilityApi) => {
		data.id = props.availability.id;
		data.hours = apiHours;
		data.hours.forEach((x: AvailabilityHourItem) => {
			x.hour_to = parseTime(x.hour_to);
			x.hour_from = parseTime(x.hour_from);
		});
		if (!hoursVisible) {
			data.hours = [];
		}
		setLoading(true);
		try {
			if (!data.id) {
				const res = await createAvailability(data, organizationId);
				history.push(`/${organizationId}/settings/availabilities/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const params: Record<string, any> = { include: "hours,dates" };
				const res = await updateAvailability(data, params, organizationId);
				setApiHours(res.hours as AvailabilityHourItem[]);
				const availability: AvailabilityApi = { ...res };
				if (availability.dates) {
					availability.dates = parseDatesToRange(availability);
				}
				reset(availability);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});
	const changeHours = (hours: AvailabilityHourItem[]) => {
		setApiHours([...hours]);
	};

	const editHours = (hour: AvailabilityHourItem, type: string) => {
		if (hour.id) {
			setApiHours([
				...apiHours.map((hr) => {
					return hr.id === hour.id ? hour : hr;
				}),
			]);
		} else if (type === "TO") {
			setApiHours([
				...apiHours.map((hr) => (hr.day_from === hour.day_from && hr.hour_from === hour.hour_from ? hour : hr)),
			]);
		} else {
			setApiHours([
				...apiHours.map((hr) => (hr.day_from === hour.day_from && hr.hour_to === hour.hour_to ? hour : hr)),
			]);
		}
	};

	const handleChangeVisibility = () => {
		setHoursVisible(!hoursVisible);
	};

	return (
		<FormDirty
			formState={formState}
			key="availability-form"
			noValidate
			className="availability-form"
			onSubmit={onSubmit}
		>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<FormInput
					label={t("common.word.name", { ns: "lib" })}
					register={register}
					name="name"
					errors={errors}
				/>
			</fieldset>

			<fieldset className="form-group no-gutters">
				<h5>
					{t("go_component.availability.field.dates.title", { ns: "lib" })}
					<small className="text-muted">
						<br />
						{t("go_component.availability.field.dates.description.title", { ns: "lib" })}
					</small>
				</h5>
				<DatesTableForm form={form} />
			</fieldset>

			<fieldset className="form-group no-gutters">
				<h5>{t("go_component.availability.field.availability_hours.title", { ns: "lib" })}</h5>
				<LimitComponentVisibility
					handleChange={handleChangeVisibility}
					defaultVisible={hoursVisible}
					id={"availability_hours"}
					legendText={t("go_component.availability.field.availability_hours.help_text.title", { ns: "lib" })}
					helpText={t("go_component.availability.field.availability_hours.help_text.description.title", {
						ns: "lib",
					})}
					unlimitedContent={
						<HoursTableForm editHours={editHours} changeHours={changeHours} form={form} hours={apiHours} />
					}
				/>
			</fieldset>
			<div className="form-footer">
				<Form.Group className="form-group">
					<ButtonLoading loading={loading} variant="primary" type="submit">
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					{formState.isDirty && Object.keys(formState.dirtyFields).length > 0 && (
						<Button variant="light" type="button" onClick={() => history.goBack()}>
							{t("common.action.cancel", { ns: "lib" })}
						</Button>
					)}
				</Form.Group>
			</div>
		</FormDirty>
	);
};
