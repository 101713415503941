import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatDate, { formatDateTimeToString } from "go-core/components/Formatters/FormatDate";
import { FormDirty } from "go-form/components/FormDirty";
import { apiOrganization } from "../../../../../../../../../../../../services/Api/Organization/apiOrganization";
import { LiveOrderApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../../../services/context";
import { liveOrderParamsInclude } from "../../../../../../services/orderSynchronizer";
import { getLiveOrderNumber } from "../../../../../../services/utils";
import LiveOrderChangeDateOptionsModal from "./LiveOrderChangeDateOptionsModal";

interface Props {
	show: boolean;
	onHide: () => void;
	order: LiveOrderApi;
	mode: "PICK_UP_DATE" | "PREPARATION_TIME";
}

const LiveOrderChangeDateModal = ({ show, onHide, order, mode }: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useForm<{
		pickup_at?: string;
		estimated_preparation_at?: string;
	}>({
		criteriaMode: "all",
	});
	const { handleSubmit, setError, formState, watch } = form;
	const [selectedTimeOption, setSelectedTimeOption] = useState<number | string | undefined>();
	const { addFlash, addSuccessFlash } = useFlash();
	const ordersContext = useContext(OrdersContext);

	const getParsedPickupAtDate = (data: { pickup_at?: string; estimated_preparation_at?: string }) => {
		if (typeof selectedTimeOption === "number") {
			const timeToAdd = selectedTimeOption * 60 * 1000;
			const date = new Date(new Date().getTime() + timeToAdd);
			return formatDateTimeToString(date);
		}

		return mode === "PICK_UP_DATE" ? data.pickup_at : data.estimated_preparation_at;
	};

	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);
		try {
			const newData =
				mode === "PICK_UP_DATE"
					? { pickup_at: getParsedPickupAtDate(data) }
					: { estimated_preparation_at: getParsedPickupAtDate(data) };
			const res = await apiOrganization.patchOrder(order.id, newData, { include: liveOrderParamsInclude });
			ordersContext.updateOrder({
				...res,
				pending_terminal_notifications: [...order.pending_terminal_notifications],
			});
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const getTimeToDisplay = () => {
		if (mode === "PICK_UP_DATE") {
			return order?.pickup_at ? FormatDate(order.pickup_at) : "-";
		}
		return order?.estimated_preparation_at ? FormatDate(order.estimated_preparation_at) : "-";
	};

	const getIsSubmitButtonDisabled = () => {
		if (mode === "PICK_UP_DATE") {
			return !selectedTimeOption && !watch("pickup_at");
		}
		return !selectedTimeOption && !watch("estimated_preparation_at");
	};

	return (
		<Modal show={show} onHide={onHide} className="live-order-change-date-modal">
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{`${
							mode === "PICK_UP_DATE"
								? t("modules.live_order.field.changing_completion_date.title")
								: t("modules.live_order.field.changing_preparation_status_date.title")
						} ${getLiveOrderNumber(order)}`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h5 className="mb-2">
						{mode === "PICK_UP_DATE"
							? t("modules.live_order.field.completion_time_limit.title")
							: t("modules.live_order.field.preparation_status_time_limit.title")}
					</h5>
					<div className="date-box">{getTimeToDisplay()}</div>
					<hr />
					<LiveOrderChangeDateOptionsModal
						selectedTimeOption={selectedTimeOption}
						setSelectedTimeOption={setSelectedTimeOption}
						form={form}
						mode={mode}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading
						loading={loading}
						type="submit"
						onClick={onSubmit}
						disabled={getIsSubmitButtonDisabled()}
					>
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default LiveOrderChangeDateModal;
