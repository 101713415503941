import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { apiOrganization } from "../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryEmployeeApi } from "../../../../../../../services/Api/Organization/types";
import { LiveOrdersFilters } from "./types";

export class DeliveryEmployeeSynchronizer {
	_employees: DeliveryEmployeeApi[];
	lastUpdatedAt?: string;
	size: number;

	constructor() {
		this._employees = [];
		const startDate = new Date();
		startDate.setHours(new Date().getHours() - 1);
		this.lastUpdatedAt = startDate.toISOString();
		this.size = 20;
	}

	async getAll(dateTo: string, filters: LiveOrdersFilters): Promise<DeliveryEmployeeApi[]> {
		let idFilter = "";
		let baseFilter = "";
		if (this.lastUpdatedAt) {
			const coordinatesUpdatedAt = new Date();
			coordinatesUpdatedAt.setHours(coordinatesUpdatedAt.getHours() - Number(filters.employeesLastActivity.id));
			baseFilter = `coordinates_or_employee_updated_at|bt=${
				this.lastUpdatedAt
			}${FILTER_VALUE_SEPARATOR}${dateTo}${FILTER_SEPARATOR}coordinates_updated_at|gteq=${coordinatesUpdatedAt.toISOString()}`;
			idFilter = baseFilter;
		}
		const allEmployees = [] as DeliveryEmployeeApi[];
		let fetchAll = false;
		while (!fetchAll) {
			const params: Record<string, any> = {
				page: 0,
				size: this.size,
				sort: "id",
				f: btoa(unescape(encodeURIComponent(NEW_WAY_TO_ENCODING_FILTER_SIGN + idFilter))),
			};
			const res = await apiOrganization.getDeliveryEmployees(params);
			const newEmployees = res as DeliveryEmployeeApi[];
			allEmployees.push(...newEmployees);
			if (newEmployees.length < this.size) {
				fetchAll = true;
			}
			const maxId = Math.max(...allEmployees.map(({ id }) => id));
			if (maxId) {
				idFilter = `${baseFilter}${FILTER_SEPARATOR}id|gt=${maxId}`;
			} else {
				idFilter = baseFilter;
			}
		}
		return allEmployees;
	}

	async sync(filters: LiveOrdersFilters): Promise<DeliveryEmployeeApi[]> {
		const now = new Date().toISOString();
		const allEmployees = await this.getAll(now, filters);
		this._employees = [
			...allEmployees.filter((employee) => !this._employees.find((e) => e.id === employee.id)),
			...this._employees.map((employee) => {
				const possibleUpdatedEmployee = allEmployees.find((e) => e.id === employee.id);
				if (possibleUpdatedEmployee) {
					return possibleUpdatedEmployee;
				}
				return employee;
			}),
		];
		this.lastUpdatedAt = now;
		return this._employees;
	}

	updateFilters(filters: LiveOrdersFilters): void {
		this._employees = [];

		const startDate = new Date();
		startDate.setHours(new Date().getHours() - Number(filters.employeesLastActivity.id));
		this.lastUpdatedAt = startDate.toISOString();
	}

	employees(): DeliveryEmployeeApi[] {
		return this._employees;
	}
}
