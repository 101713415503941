import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
	PointOfSaleApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../../components/VirtualTable/types";
import ItemGroupGroupEditingVirtualTableHeaderActionsRow from "./ItemGroupGroupEditingVirtualTableHeaderActionsRow";
import ItemGroupGroupEditingVirtualTableHeaderDefaultColumns from "./ItemGroupGroupEditingVirtualTableHeaderDefaultColumns";
import ItemGroupGroupEditingVirtualTableHeaderOtherColumns from "./ItemGroupGroupEditingVirtualTableHeaderOtherColumns";
import ItemGroupGroupEditingVirtualTableHeaderPointsOfSaleColumns from "./ItemGroupGroupEditingVirtualTableHeaderPointsOfSaleColumns";
import ItemGroupGroupEditingVirtualTableHeaderSearchColumnsRow from "./ItemGroupGroupEditingVirtualTableHeaderSearchColumnsRow";
import ItemGroupGroupEditingVirtualTableHeaderSearchOtherColumnsRow from "./ItemGroupGroupEditingVirtualTableHeaderSearchOtherColumnsRow";
import ItemGroupGroupEditingVirtualTableHeaderSearchPointsOfSaleColumnsRow from "./ItemGroupGroupEditingVirtualTableHeaderSearchPointsOfSaleColumnsRow";

interface Props {
	maxZindex: number;
	visibleDefaultColumns: VirtualTableColumn[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	getHeaderStyles: (columnId: string) => { width: number };
	sortings: VirtualTableListSelectedSort[];
	categorySearch: string | undefined;
	itemGroupSearch: string | undefined;
	nameSearch: string | undefined;
	fullNameSearch: string | undefined;
	setCategorySearch: (search: string) => void;
	setItemGroupSearch: (search: string) => void;
	setNameSearch: (search: string) => void;
	setFullNameSearch: (search: string) => void;
	items: ItemFormApi[];
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	visiblePointsOfSale: VirtualTableColumn[];
	visibleOtherColumns: VirtualTableColumn[];
	pointsOfSaleSearch: { id: string; search?: string }[];
	setPointsOfSaleSearch: (pointsOfSaleSearch: { id: string; search?: string }[]) => void;
	otherColumnsSearch: { id: string; search?: string }[];
	setOtherColumnsSearch: (otherColumnsSearch: { id: string; search?: string }[]) => void;
	pointsOfSale: PointOfSaleApi[];
}

const ItemGroupGroupEditingVirtualTableHeader = ({
	maxZindex,
	visibleDefaultColumns,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	getHeaderStyles,
	sortings,
	categorySearch,
	setCategorySearch,
	setItemGroupSearch,
	itemGroupSearch,
	nameSearch,
	setFullNameSearch,
	fullNameSearch,
	setNameSearch,
	items,
	form,
	visiblePointsOfSale,
	visibleOtherColumns,
	pointsOfSaleSearch,
	setPointsOfSaleSearch,
	setOtherColumnsSearch,
	otherColumnsSearch,
	pointsOfSale,
}: Props) => {
	return (
		<thead
			style={{
				position: "sticky",
				zIndex: maxZindex + 1,
			}}
		>
			<tr className="headers">
				<ItemGroupGroupEditingVirtualTableHeaderDefaultColumns
					getHeaderStyles={getHeaderStyles}
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					maxZindex={maxZindex}
					sortings={sortings}
					visibleDefaultColumns={visibleDefaultColumns}
				/>
				<ItemGroupGroupEditingVirtualTableHeaderPointsOfSaleColumns
					getHeaderStyles={getHeaderStyles}
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					columns={visiblePointsOfSale}
					sortings={sortings}
				/>
				<ItemGroupGroupEditingVirtualTableHeaderOtherColumns
					columns={visibleOtherColumns}
					getHeaderStyles={getHeaderStyles}
					sortings={sortings}
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					maxZindex={maxZindex}
				/>
			</tr>
			<tr className="additional-header-row">
				<ItemGroupGroupEditingVirtualTableHeaderSearchColumnsRow
					visibleDefaultColumns={visibleDefaultColumns}
					maxZindex={maxZindex}
					setCategorySearch={setCategorySearch}
					setNameSearch={setNameSearch}
					setFullNameSearch={setFullNameSearch}
					itemGroupSearch={itemGroupSearch}
					setItemGroupSearch={setItemGroupSearch}
					nameSearch={nameSearch}
					fullNameSearch={fullNameSearch}
					categorySearch={categorySearch}
				/>
				<ItemGroupGroupEditingVirtualTableHeaderSearchPointsOfSaleColumnsRow
					columns={visiblePointsOfSale}
					pointsOfSaleSearch={pointsOfSaleSearch}
					setPointsOfSaleSearch={setPointsOfSaleSearch}
				/>
				<ItemGroupGroupEditingVirtualTableHeaderSearchOtherColumnsRow
					columns={visibleOtherColumns}
					setOtherColumnsSearch={setOtherColumnsSearch}
					otherColumnsSearch={otherColumnsSearch}
				/>
			</tr>
			<ItemGroupGroupEditingVirtualTableHeaderActionsRow
				visibleDefaultColumns={visibleDefaultColumns}
				items={items}
				form={form}
				maxZIndex={maxZindex}
				visibleOtherColumns={visibleOtherColumns}
				visiblePointsOfSale={visiblePointsOfSale}
				pointsOfSale={pointsOfSale}
			/>
		</thead>
	);
};

export default ItemGroupGroupEditingVirtualTableHeader;
