import React, { ReactElement, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
	defaultVisible: boolean;
	id: string;
	limitedContent?: ReactElement;
	unlimitedContent?: ReactElement;
	legendText?: string;
	helpText?: string;
	handleChange?: (visible?: boolean) => void;
	classNames?: string;
	isVisibleControl?: boolean;
	setIsVisibleControl?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LimitComponentVisibility = ({
	classNames,
	unlimitedContent,
	limitedContent,
	id,
	defaultVisible,
	helpText,
	legendText,
	handleChange,
	isVisibleControl,
	setIsVisibleControl,
}: Props): JSX.Element => {
	const visibilityInternalState = useState(defaultVisible);
	const [isVisible, setIsVisible] =
		isVisibleControl !== undefined && setIsVisibleControl !== undefined
			? [isVisibleControl, setIsVisibleControl]
			: visibilityInternalState;
	const { t } = useTranslation();
	const handleChangeVisibility = () => {
		setIsVisible(!isVisible);
		if (handleChange) {
			handleChange(!isVisible);
		}
	};

	useEffect(() => {
		setIsVisible(defaultVisible);
	}, [defaultVisible, setIsVisible]);

	return (
		<>
			{limitedContent}
			<div className={`limit-component-visibility-form-box ${classNames ? classNames : ""}`}>
				<div className="form-box-check">
					<Form.Check type="switch" id={id} checked={isVisible} onClick={() => handleChangeVisibility()} />
					<Form.Check.Label onClick={() => handleChangeVisibility()}>
						<h6>{legendText ? legendText : t("common.action.show_more", { ns: "lib" })}</h6>
					</Form.Check.Label>
				</div>
				<div className="form-box-content">
					<div className="form-box-title">
						<Form.Check.Label onClick={() => handleChangeVisibility()}>
							<h6>{legendText ? legendText : t("common.action.show_more", { ns: "lib" })}</h6>
						</Form.Check.Label>
					</div>
					{helpText && (
						<div className={"form-box-description"}>
							<small className="text-muted">{helpText}</small>
						</div>
					)}
					{isVisible && unlimitedContent}
				</div>
			</div>
		</>
	);
};
