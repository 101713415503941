const defaultChartConfig = {
	responsive: true,
	bezierCurve: false,
	datasetFill: true,
	pointDotRadius: 1,
	pointDotStrokeWidth: 8,
	pointHitDetectionRadius: 20,
	maintainAspectRatio: false,
};

const chartDataSetConfig = {
	type: "line",
	fill: true,
	lineTension: 0.1,
	borderCapStyle: "butt",
	borderDash: [],
	borderDashOffset: 0.0,
	borderJoinStyle: "miter",
	pointHoverRadius: 6,
	pointHoverBorderWidth: 2,
	pointHoverBackgroundColor: "#FFF",
	pointRadius: 4,
	spanGaps: false,
};

export { defaultChartConfig, chartDataSetConfig };
