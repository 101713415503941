import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Item {
	name: string;
	link: string;
}

interface Props {
	onHide: () => void;
	isShown: boolean;
	title?: string;
	message?: string;
	linkTo: string;
	linkTitle: string;
	items?: Item[];
}

const EntityUsedErrorModal: FC<Props> = ({ onHide, isShown, title, message, linkTo, linkTitle, items }) => {
	const drawItems = () => {
		return (
			items &&
			items.length > 0 &&
			items.map((item) => (
				<Link key={item.link} to={item.link} className={"mb-2"} target="_blank" rel="no_referrer">
					{item.name}
				</Link>
			))
		);
	};

	return (
		<Modal show={isShown} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{message}
				{items && items.length > 0 && (
					<div className="d-flex flex-column">
						{drawItems()}
						{items && items?.length > 9 && (
							<div className="mt-2">
								<Link to={linkTo} className={"btn btn-primary"} target={"_blank"} rel={"no_referrer"}>
									{linkTitle}
								</Link>
							</div>
						)}
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default EntityUsedErrorModal;
