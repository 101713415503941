import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DiagnosticsPage } from "go-component/components/Diagnostics";
import { DiagnoseElementApi } from "go-component/services/types";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import { apiOrganization } from "../../../../../../services/Api/Organization/apiOrganization";

export const OrganizationDiagnosticsPage = () => {
	const organization = useSelector(selectOrganization);
	const user = useSelector(selectUser);
	const { getDiagnosticsTypes, diagnose, getDiagnoseProgress, diagnoseFix } = apiOrganization;
	const { t } = useTranslation();

	const diagnosticsTranslations: Record<string, string> = {
		MIGRATION_POS_REPORT: "Migracja raportów POS",
		MIGRATION_ORDER: "Migracja rachunków",
		MIGRATION_INVOICE: "Migracja faktur",
		IMAGES: "Zdjęcie",
		AVAILABILITY: "Dostępność",
		CATEGORY: "Kategoria produktu",
		CLIENT: "Klient",
		CLIENT_GROUP: "Grupa klientów",
		CURRENCY_RATE: "Kurs walut",
		CUSTOM_FIELD_TEMPLATE: "Zdefiniowane pole",
		DEFAULT_COMMENT: "Domyślny komentarz",
		DIRECTION: "Kierunek rozchodu",
		EMPLOYEE: "Pracownik",
		ITEM: "Wariant produktu",
		MENU: "Menu",
		MENU_DISCOUNT: "Zniżka",
		MENU_PRICE_LIST: "Cennik",
		MODIFIER_GROUP: "Grupa modyfikatorów",
		PAYMENT_METHOD: "Metoda płatności",
		POINT_OF_SALE: "Punkt sprzedaży",
		PRINTOUT_TEMPLATE: "Szablon wydruku",
		ROOM: "Pomieszczenie",
		VENUE_ROLE: "Pomieszczenie",
		SEGMENT: "Segment",
		VENUE: "Miejsce",
		ORDER: "Rachunek",
		REPORT_SYSTEM: "System zgłaszania",
		REQUEST: "Zapytanie",
		WORKPLACE: "Stanowisko pracy",
		LOCATIONS_CONNECTION_ENTRY: "Informacja o multilokalizacji w bazie Master",
		TAX: "Podatek",
	};

	const renderDiagnseElementLink = (diagnoseElement: DiagnoseElementApi, id: string, title: string) => {
		switch (diagnoseElement.resource_type) {
			case "MENU":
				return (
					<Link rel="noreferrer" target="_blank" to={`/${organization.id}/menu/menus`}>
						{title}
					</Link>
				);
			case "ITEM_GROUP":
				return (
					<Link rel="noreferrer" target="_blank" to={`/${organization.id}/menu/item_groups/${id}`}>
						{title}
					</Link>
				);
			case "ITEM":
				return (
					<Link
						to={`/${organization.id}/menu/item_groups?f=${btoa(
							`${NEW_WAY_TO_ENCODING_FILTER_SIGN}item|e=${id}`
						)}`}
						target="_blank"
						rel="noreferrer"
					>
						{title}
					</Link>
				);
			case "ORDER":
				return (
					<Link rel="noreferrer" target="_blank" to={`/${organization.id}/sales/orders/${id}`}>
						{title}
					</Link>
				);
			case "INVOICE":
				return (
					<Link to={`/${organization.id}/invoices/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "TRANSACTION":
				return (
					<Link to={`/${organization.id}/sales/transactions`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "CATEGORY":
				return (
					<Link to={`/${organization.id}/menu/categories/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "MENU_DISCOUNT":
				return (
					<Link to={`/${organization.id}/menu/discounts/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "DEVICE":
				return (
					<Link to={`/${organization.id}/settings/devices/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "TERMINAL":
				return (
					<Link to={`/${organization.id}/settings/terminals/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "CLIENT_GROUP":
				return (
					<Link to={`/${organization.id}/clients/client-groups/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "MODIFIER_GROUP":
				return (
					<Link to={`/${organization.id}/menu/modifier_groups/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "CLIENT":
				return (
					<Link to={`/${organization.id}/clients/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "EMPLOYEE":
				return (
					<Link to={`/${organization.id}/employees/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "RESERVATION":
				return (
					<Link to={`/${organization.id}/reservations/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "AVAILABILITY":
				return (
					<Link to={`/${organization.id}/settings/availabilities/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "DIRECTION":
				return (
					<Link to={`/${organization.id}/settings/rooms/directions`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "POINT_OF_SALE":
				return (
					<Link to={`/${organization.id}/settings/rooms/point-of-sales`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "ROLE":
				return (
					<Link to={`/${organization.id}/settings/permissions/roles`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "PRINTOUT_TEMPLATE":
				return (
					<Link to={`/${organization.id}/settings/printout_templates/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "PAYMENT_METHOD":
				return (
					<Link to={`/${organization.id}/settings/payment_methods/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "TAX":
				return (
					<Link to={`/${organization.id}/settings/taxes`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "CURRENCY_RATE":
				return (
					<Link to={`/${organization.id}/settings/currencies`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "ROOM":
				return (
					<Link to={`/${organization.id}/settings/rooms`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "WORKPLACE":
				return (
					<Link to={`/${organization.id}/employees/workplaces`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "DEFAULT_COMMENT":
				return (
					<Link to={`/${organization.id}/settings/default_comments`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "PERMISSION":
				return (
					<Link to={`/${organization.id}/settings/permissions`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "REPORT":
				return (
					<Link to={`/${organization.id}/sales/pos_reports/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "WORK_TIME":
				return (
					<Link to={`/${organization.id}/employees/worktimes`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "WEBHOOK":
				return (
					<Link to={`/${organization.id}/logs/webhooks/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "APP":
				return (
					<Link to={`/${organization.id}/apps/active/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "RECEIPT":
				return (
					<Link to={`/${organization.id}/settings/info/receipt`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "INVOICE_DETAILS":
				return (
					<Link to={`/${organization.id}/settings/info/invoice_details`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			case "MENU_PRICE_LIST":
				return (
					<Link to={`/${organization.id}/menu/price_lists/${id}`} target="_blank" rel="noreferrer">
						{title}
					</Link>
				);
			default:
				return (
					<>
						{diagnoseElement.resource_type}: {diagnoseElement.id}{" "}
						{diagnoseElement.link ? (
							<a href={diagnoseElement.link} target="_blank" rel="noreferrer">
								{t("common.action.goto", { ns: "lib" })}
							</a>
						) : (
							""
						)}
					</>
				);
		}
	};

	return (
		<DiagnosticsPage
			getDiagnosticsTypes={getDiagnosticsTypes}
			diagnose={diagnose}
			getDiagnoseProgress={getDiagnoseProgress}
			diagnoseFix={diagnoseFix}
			organization={organization}
			user={user}
			diagnosticsTranslations={diagnosticsTranslations}
			renderDiagnseElementLink={renderDiagnseElementLink}
			diagnoseElementTitleKeyFormat="enums.message_events.resource_type"
		/>
	);
};
