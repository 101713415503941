import React, { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import axios, { CancelTokenSource, CanceledError } from "axios";
import classNames from "classnames";
import { Button, Collapse } from "react-bootstrap";
import { RangeWithKey } from "react-date-range";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import useFlash, { handleErrorForAlert } from "go-alert/AlertMessage";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleException from "go-core/api/handleException";
import { ListDataRequestResponse } from "go-core/api/types";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import PdfGenerationOptionsModal from "go-core/components/PdfGenerationOptionsModal";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useListenEvent } from "go-core/hooks";
import { PdfOrientationType } from "go-core/types";
import { ExportConfig } from "go-report/core/components/Actions/services/types";
import Segment from "go-segment/components";
import List from "../core";
import { createCSVFile, exportCsv, exportCustomPdf, exportPdf } from "../core/components/Actions/services";
import MobileFilters from "../core/components/Filter/components/MobileFilters/MobileFilters";
import { FILTER_SEPARATOR } from "../core/components/Filter/services";
import { listFiltersEncode } from "../core/components/Filter/services/decoder";
import { ListSelectedFilter } from "../core/components/Filter/services/types";
import { ReactComponent as ColumnSVG } from "../core/images/columns.svg";
import { ReactComponent as SearchSVG } from "../core/images/search.svg";
import isPredefinedRange, { parseDatesToDefaultString, parsePredefinedRangeToRange } from "../utils/daterangeutils";
import { fetchTotalItemsCount, listDataFetch } from "./services/fetch-data";
import { getFixedConfig } from "./services/getFixedConfig";
import { getResponseHasNextPage } from "./services/getResponseHasNextPage";
import { getResponseItemData } from "./services/getResponseItemData";
import { groupDataByConfig } from "./services/group-data-by-config";
import { listDataPushHistorySearch } from "./services/push-history";
import { ListParamsReducer, listParamsInitialState } from "./services/reducer";
import { isSegmentChanged, paramsToSegment, segmentToParams } from "./services/segment-service";
import {
	selectActiveSegment,
	selectColumns,
	selectFilters,
	selectSelectedColumns,
	selectSelectedFilters,
	selectSelectedFiltersFullNames,
	selectSelectedSorts,
} from "./services/selectors";
import {
	ExportAction,
	FetchDefaultParameters,
	GoListSegmentType,
	ListConfig,
	ListConfigEmptyList,
	ListParamsType,
} from "./services/types";

interface Props {
	config: ListConfig;
	data?: any[];
	onFetch?: ((items: any[]) => void) | ((data: ListDataRequestResponse) => void);
	emptyList?: ListConfigEmptyList | JSX.Element;
	customTableRow?: ReactElement;
	customView?: JSX.Element;
	customActions?: JSX.Element;
	customMobileActions?: MobileActionProps[];
	canManageMultiActionsForAllItems?: boolean;
	shouldFilterAvailableMultipleActions?: boolean;
	// the shouldFilterAvailableMultipleActions prop should be removed after configs in all components that use ListTable are updated (singleActions should contain all the available actions)
	onSegmentUpdate?: (segment: GoListSegmentType) => void;
	withoutSegments?: boolean;
	customExportActions?: ExportAction[];
	forceFetchingList?: boolean;
	setForceFetchingList?: (value: boolean) => void;
	mobileActions?: MobileActionProps[];
	shouldChangeSegmentFromOutside?: boolean;
	displayListFeaturesOnEmptyList?: boolean;
}

const ListData = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const firstUpdate = useRef(true);
	const config = getFixedConfig(props.config);
	const firstDataLoaded = useRef(true);
	const [totalItemsCountForSelectedFilters, setTotalItemsCountForSelectedFilters] = useState<number>();
	const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
	const [openFilters, setOpenFilters] = useState(true);
	const [segmentModal, setSegmentModal] = useState(false);
	const [data, setData] = useState<Array<any>>([]);
	const [hasNextPage, setHasNextPage] = useState(false);
	const [numberOfActiveStickyColumns, setNumberOfActiveStickyColumns] = useState(
		config.numberOfStickyColumnsAtTheStart || 0
	);
	const [fetchItemsErrorOccurred, setFetchItemsErrorOccurred] = useState(false);
	const activeData =
		props.data !== undefined
			? groupDataByConfig(props.data, config.groupBy)
			: groupDataByConfig(data, config.groupBy);
	const { addFlash } = useFlash();
	const [loadingData, setLoadingData] = useState(true);
	const [state, dispatch] = useReducer(ListParamsReducer, listParamsInitialState(props.config, location));
	const batchedRenderUpdatesToMake = useRef<{ update: Function; args: any }[]>([]);
	const { segments, search, size, page, selectedSegment } = state;
	const segment = selectActiveSegment(state);
	const selectedFilters = selectSelectedFilters(state, config.filterValues);
	const selectedColumns = selectSelectedColumns(state, config);
	const selectedSorts = selectSelectedSorts(state);
	const filters = selectFilters(config);
	const columns = selectColumns(config, selectedColumns);
	const stickyColumnsDividerPosition = useRef(numberOfActiveStickyColumns);
	const allColumnsInOrder = useRef(columns);
	const isMobile = useWindowSize().isMobile;
	const [showMobileFilters, setShowMobileFilters] = useState(false);
	const [showMobileSearch, setShowMobileSearch] = useState(false);
	const [showPdfGenerationOptionsModal, setShowPdfGenerationOptionsModal] = useState(false);
	const segmentFromUrl = useMemo(() => {
		return new URLSearchParams(location.search).get("s");
	}, [location.search]);

	useEffect(() => {
		if (isMobile && showMobileFilters) {
			setShowMobileFilters(false);
		}
		if (isMobile && showMobileSearch) {
			setShowMobileSearch(false);
		}
	}, [isMobile]);

	const onChangeFilters = (newFilters: ListSelectedFilter[]) => {
		let changePage = false;
		newFilters?.forEach((filter) => {
			if (filter.condition === "a" || filter.condition === "u") {
				changePage = true;
			} else if (filter.value) {
				changePage = true;
			}
		});
		if (changePage) {
			dispatch({ type: "setPage", page: "0" });
		}
		dispatch({ type: "setFilters", filters: newFilters });
	};

	const onSort = (sorts: string[]) => {
		dispatch({ type: "setSort", sort: sorts });
		dispatch({ type: "setPage", page: "0" });
	};

	const onChangeSize = (size: string) => {
		dispatch({ type: "setSize", size });
		dispatch({ type: "setPage", page: "0" });
	};
	const onChangePage = (page: string) => {
		dispatch({ type: "setPage", page });
	};

	const onChangeSearch = (search: string) => {
		dispatch({ type: "setSearch", search });
		dispatch({ type: "setPage", page: "0" });
	};

	const onChangeColumnsOrder = useCallback((newAllColumnsInOrder: string[]) => {
		dispatch({ type: "setAllColumnsInOrder", allColumnsInOrder: newAllColumnsInOrder });
	}, []);

	const onChangeStickyColumnsDividerPosition = useCallback((dividerPosition: number) => {
		dispatch({ type: "setStickyColumnsDividerPosition", dividerPosition });
	}, []);

	const fetchCancellation = async (params: Record<string, any>) => {
		if (props.config?.fetch === undefined) return undefined;
		const CancelToken = axios.CancelToken;
		if (cancelTokenSource !== undefined) cancelTokenSource.cancel();
		const newCancelToken = CancelToken.source();
		setCancelTokenSource(newCancelToken);
		return props.config?.fetch(params, newCancelToken);
	};

	const fetchTotalItemsCountForSelectedFiltersWithCancellation = async (params: Record<string, any>) => {
		if (props.config?.fetchTotalItemsCountForSelectedFilters === undefined) return undefined;
		const CancelToken = axios.CancelToken;
		if (cancelTokenSource !== undefined) cancelTokenSource.cancel();
		const newCancelToken = CancelToken.source();
		setCancelTokenSource(newCancelToken);
		return props.config.fetchTotalItemsCountForSelectedFilters(params, newCancelToken);
	};

	const updateListParamsFromSegment = (newState: ListParamsType) => {
		if (!newState.filters && newState.selectedSegment) {
			const matchedSegment = state.segments?.find((f) => f.slug === newState.selectedSegment);
			let newFilters: ListSelectedFilter[] = [...(newState?.filters || [])];
			if (matchedSegment && matchedSegment.filters) {
				const f = listFiltersEncode(matchedSegment.filters);
				newFilters = [...newFilters, ...matchedSegment.filters] as ListSelectedFilter[];
				newState = { ...newState, f };
			}
			newState = { ...newState, filters: newFilters };
		}
		return newState;
	};

	const onCsvExport = async () => {
		if (!config.exportConfig || !config.exportConfig.title || !config.exportConfig.filename) {
			return;
		}
		let newState = { ...state };
		newState.page = "0";
		newState.size = "0";
		newState = updateListParamsFromSegment(newState);
		if (config.exportConfig.customExportRequests?.csvFetch) {
			newState.filters = newState.filters?.map((filter) => {
				if (isPredefinedRange(filter.value)) {
					const { startDate, endDate } = parsePredefinedRangeToRange(filter.value) as RangeWithKey;
					const dateRange = parseDatesToDefaultString(startDate, endDate);
					return { ...filter, value: dateRange, condition: "bt" };
				}
				return filter;
			});
			try {
				const params: Record<string, any> = {
					f: listFiltersEncode(newState.filters as ListSelectedFilter[]),
					columns: newState.columns?.join(",").toUpperCase() || "",
				};
				if (newState?.sort) params.sort = newState.sort.join(",");
				const res = await config.exportConfig.customExportRequests.csvFetch(params);
				createCSVFile(res.data, config?.exportConfig);
			} catch (err) {
				handleErrorForAlert(err, addFlash);
			}
		} else {
			listDataFetch(newState, props.config?.fetch)
				.then((res) => {
					const responseData = getResponseItemData(res);

					exportCsv(selectedColumns, columns, filters, responseData, config?.exportConfig);
				})
				.catch((err) => {
					handleErrorForAlert(err, addFlash);
				});
		}
	};

	const handleExportToPdf = async (orientation?: PdfOrientationType, fontSize?: string) => {
		if (!config.exportConfig || !config.exportConfig.title || !config.exportConfig.filename) {
			return;
		}

		let newState = { ...state };
		newState.page = "0";
		newState.size = "0";
		newState = { ...updateListParamsFromSegment(newState) };

		const exportConfig: ExportConfig = {
			...config.exportConfig,
		};

		if (exportConfig.pdfOrientation) {
			exportConfig.pdfOrientation =
				exportConfig.pdfOrientation === "ASK" ? orientation || "LANDSCAPE" : exportConfig.pdfOrientation;
		}

		if (fontSize) {
			exportConfig.pdfFontSize = fontSize;
		}

		if (config.exportConfig.customExportRequests?.pdfFetch) {
			newState.filters = newState.filters?.map((filter) => {
				if (isPredefinedRange(filter.value)) {
					const { startDate, endDate } = parsePredefinedRangeToRange(filter.value) as RangeWithKey;
					const dateRange = parseDatesToDefaultString(startDate, endDate);
					return { ...filter, value: dateRange, condition: "bt" };
				}
				return filter;
			});

			try {
				const params: Record<string, any> = {
					f: listFiltersEncode(newState.filters as ListSelectedFilter[]),
					columns: newState.columns?.join(",").toUpperCase() || "",
				};
				if (newState?.sort) params.sort = newState.sort.join(",");
				const res = await config.exportConfig.customExportRequests.pdfFetch(params);
				const exportData = res.data;
				exportCustomPdf(exportData, exportConfig);
			} catch (err) {
				handleErrorForAlert(err, addFlash);
			}
		} else {
			listDataFetch(newState, props.config?.fetch)
				.then((res) => {
					const exportData = getResponseItemData(res);

					exportPdf(
						selectedColumns,
						columns,
						selectSelectedFiltersFullNames(selectedFilters, filters),
						exportData,
						exportConfig
					);
				})
				.catch((err) => {
					handleErrorForAlert(err, addFlash);
				});
		}
	};

	const onPdfExport = (orientation?: PdfOrientationType, fontSize?: string) => {
		if (config.exportConfig?.pdfOrientation === "ASK" || config.exportConfig?.pdfFontSize === "0") {
			setShowPdfGenerationOptionsModal(true);
			return;
		}

		handleExportToPdf(orientation, fontSize);
	};

	const onShowSaveSegment = () => {
		setSegmentModal(true);
	};
	const onHideSegmentModal = () => {
		setSegmentModal(false);
	};
	const saveSegment = async (
		segment: GoListSegmentType,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => {
		if (config.saveSegment) {
			const newSelectedColumns = (segmentColumns ? segmentColumns : selectedColumns).filter((columnId) =>
				allColumnsInOrder.current.find((column) => column.id === columnId)
			);

			const newSegment = {
				...segment,
				filters: segmentFilters
					? selectSelectedFilters({ ...state, filters: [...segmentFilters] })
					: selectSelectedFilters(state),
				columns: newSelectedColumns,
				allColumnsInOrder: segmentAllColumnsInOrder ?? allColumnsInOrder.current.map((column) => column.id),
				stickyColumnsDividerPosition:
					segmentStickyColumnsDividerPosition !== undefined
						? segmentStickyColumnsDividerPosition
						: stickyColumnsDividerPosition.current,
				sort: selectedSorts,
				position: segment.position !== undefined ? segment.position : (segments || []).length - 1,
				default: segment.default !== undefined ? segment.default : false,
			};
			try {
				const dataSegment = await config.saveSegment(segmentToParams(newSegment));
				dispatch({
					type: "updateSegment",
					data: {
						segment: paramsToSegment(dataSegment.data.data),
						preventReturnUpdatedSegment: preventUpdateSegment,
					},
				});
				if (props.onSegmentUpdate) props.onSegmentUpdate(dataSegment.data.data);
				setSegmentModal(false);
				return dataSegment.data.data;
			} catch (e) {
				return handleException(e);
			}
		}
	};

	const onSaveSegment = async (
		segment: GoListSegmentType,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => {
		return saveSegment(
			segment,
			segmentFilters,
			segmentColumns,
			segmentAllColumnsInOrder,
			segmentStickyColumnsDividerPosition,
			preventUpdateSegment
		);
	};
	const onCreateSegment = async (
		name: string,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => {
		const params = {
			name,
			filters: segmentFilters
				? selectSelectedFilters({ ...state, filters: [...segmentFilters] })
				: selectSelectedFilters(state),
			columns: segmentColumns ?? selectedColumns,
			allColumnsInOrder: segmentAllColumnsInOrder ?? allColumnsInOrder.current.map((column) => column.id),
			stickyColumnsDividerPosition:
				segmentStickyColumnsDividerPosition !== undefined
					? segmentStickyColumnsDividerPosition
					: stickyColumnsDividerPosition.current,
			sort: selectedSorts,
			default: false,
			position: (segments || []).length - 1,
		} as GoListSegmentType;
		try {
			return await saveSegment(
				params,
				segmentFilters,
				segmentColumns,
				segmentAllColumnsInOrder,
				segmentStickyColumnsDividerPosition,
				preventUpdateSegment
			);
		} catch (e) {
			return handleException(e);
		}
	};

	const onChangeSegment = (segment: GoListSegmentType) => {
		if (!segment.columns && config.fields) {
			allColumnsInOrder.current = config.fields;
		}

		dispatch({ type: "setSegment", segment: segment.slug });

		if (segment.stickyColumnsDividerPosition === undefined) {
			onChangeStickyColumnsDividerPosition(config.numberOfStickyColumnsAtTheStart || 0);
		}
	};

	const onChangeColumn = useCallback((selectedColumns: string[], sortedColumns: string[]) => {
		const sortedSelectedColumns = sortedColumns.filter((column) => selectedColumns.includes(column));

		dispatch({ type: "setColumns", columns: sortedSelectedColumns });
	}, []);

	const addBatchedUpdate = (update?: Function, args?: any) => {
		if (update) {
			batchedRenderUpdatesToMake.current = [
				...batchedRenderUpdatesToMake.current,
				{
					update,
					args,
				},
			];
		}
	};

	const fetchItemData = async (params: ListParamsType) => {
		setLoadingData(true);
		setTotalItemsCountForSelectedFilters(undefined);

		const page = state.page ? state.page : FetchDefaultParameters.PAGE;
		const size = state.size ? state.size : FetchDefaultParameters.SIZE;
		const updatedParams: ListParamsType = updateListParamsFromSegment({ ...params, page, size });

		return listDataFetch(updatedParams, fetchCancellation)
			.then((response) => {
				const responseData = getResponseItemData(response);
				const responseHasNextPage = getResponseHasNextPage(response);

				addBatchedUpdate(setData, responseData);
				addBatchedUpdate(setHasNextPage, responseHasNextPage);

				if (firstDataLoaded.current) {
					addBatchedUpdate(() => (firstDataLoaded.current = false));
				}

				addBatchedUpdate(props.onFetch, responseData);
				addBatchedUpdate(setLoadingData, false);
			})
			.catch((err) => {
				const previousRequestAbortOccurred = err instanceof CanceledError;
				if (!previousRequestAbortOccurred) {
					setFetchItemsErrorOccurred(true);
					handleErrorForAlert(err, addFlash);
				}
			})
			.finally(() => setLoadingData(false));
	};

	const fetchTotalItemsCountForSelectedFilters = async (params: ListParamsType) => {
		const updatedParams = {
			...updateListParamsFromSegment(params),
			size: "0",
		};

		fetchTotalItemsCount(updatedParams, fetchTotalItemsCountForSelectedFiltersWithCancellation)
			.then((count) => setTotalItemsCountForSelectedFilters(typeof count === "object" ? count.data : count))
			.catch((err) => handleErrorForAlert(err, addFlash));
	};

	useEffect(() => {
		if (!loadingData) {
			const listEl = document.getElementsByClassName("list-container")[0] as HTMLElement;
			if (listEl) {
				listEl.style.display = "";
			}
		}
	}, [loadingData]);

	useEffect(() => {
		if (activeData.length > 0) {
			const listEl = document.getElementsByClassName("list-container")[0] as HTMLElement;
			if (listEl) {
				listEl.style.display = "";
			}
		}
	}, [activeData]);

	const getFilterWithValues = (): ListSelectedFilter[] => {
		const filters: ListSelectedFilter[] = [];
		const currentFilters = !state.filters && segment?.filters ? segment.filters : state.filters;

		currentFilters?.forEach((filter) => {
			if (filter.condition === "a" || filter.condition === "u") {
				filters.push(filter);
			} else if (filter.value) {
				filters.push(filter);
			}
		});

		return filters;
	};

	const usedFilters = getFilterWithValues()
		.map((x) => {
			if (x.condition === "a" || x.condition === "u") {
				return x.condition;
			}
			if (x.value) {
				return `${x.condition}${x.value}`;
			}
			return "";
		})
		.join(FILTER_SEPARATOR);

	const shouldEnableNextPageButton = (): boolean => {
		if (hasNextPage !== undefined) {
			return hasNextPage;
		}

		const currentSize = size ? size : FetchDefaultParameters.SIZE;

		return data.length === Number(currentSize);
	};

	const runBatchedUpdates = async (collectionOfActionsCreatingUpdates: Function[]) => {
		await Promise.all(collectionOfActionsCreatingUpdates.map((createUpdates) => createUpdates()));

		unstable_batchedUpdates(() => {
			batchedRenderUpdatesToMake.current.forEach(({ update, args }) => update(args));
		});

		batchedRenderUpdatesToMake.current = [];
	};

	useListenEvent({
		eventName: "RequestTotalItemsCountEvent",
		callback: () => fetchTotalItemsCountForSelectedFilters(state),
	});

	useEffect(() => {
		runBatchedUpdates([() => fetchItemData(state)]);

		if (firstUpdate.current) {
			firstUpdate.current = false;
		} else if (history) {
			listDataPushHistorySearch(history, state, config);
		}
	}, [search, page, size, selectedSegment, selectedSorts.join(","), usedFilters, state.segments]);

	useEffect(() => {
		if (props.forceFetchingList && props.setForceFetchingList) {
			runBatchedUpdates([() => fetchItemData(state)]);
			listDataPushHistorySearch(history, state, config);
			props.setForceFetchingList(false);
		}
	}, [props.forceFetchingList]);

	useEffect(() => {
		if (!segmentFromUrl) return;
		if (segmentFromUrl === selectedSegment) return;
		if (props.shouldChangeSegmentFromOutside) {
			dispatch({ type: "setSegment", segment: segmentFromUrl });
		}
	}, [segmentFromUrl]);

	// useEffect(() => {
	//     const searchParams = new URLSearchParams(window.location.search);
	//
	//     const pageFromUrl = searchParams.get('page') || '0';
	//     if (pageFromUrl !== page) {
	//         firstUpdate.current = true;
	//         listDataPushHistorySearch(history, state, config);
	//         dispatch({type: "setPage", page: pageFromUrl})
	//     }
	//     else if (history.action === "POP" && page !== undefined && page === pageFromUrl) {
	//         dispatch({type: "setPage", page: (+page - 1).toString()})
	//     }
	// }, [location.search])

	useEffect(() => {
		if (history) {
			listDataPushHistorySearch(history, state, config);
		}
	}, [state.columns, selectedColumns.join(",")]);

	const createEmptyList = () => {
		if (!props.emptyList)
			return (
				<>
					<EmptyList
						title={t("lib:go_core.empty_list_data.title")}
						description={t("lib:go_core.empty_list_data.description")}
					/>
					<MobileActions actions={mobileActions} />
				</>
			);
		if (React.isValidElement(props.emptyList)) return props.emptyList;
		const emptyList = props.emptyList as ListConfigEmptyList;
		let emptyListActions = emptyList.actions ? emptyList.actions : undefined;
		const emptyListTitle = emptyList.resourceName ? emptyList.resourceName : t("lib:go_core.empty_list_data.title");
		if (!emptyListActions && emptyList.addAction) {
			emptyListActions = [
				{
					name: `+ ${t("common.action.add", { ns: "lib" })}${
						emptyList.resourceName ? ` ${emptyList.resourceName}` : ""
					}`,
					click: emptyList.addAction,
				},
			];
		}
		return (
			<>
				<EmptyList
					title={emptyListTitle}
					description={t("lib:go_core.empty_list_data.description")}
					actions={emptyListActions}
				/>
				<MobileActions actions={mobileActions} />
			</>
		);
	};

	const mobileActions: MobileActionProps[] = useMemo(
		() => [
			...(props.mobileActions || []),
			...(props.customMobileActions || []),
			{
				title: t("lib:common.action.export_csv"),
				action: onCsvExport,
			},
			{
				title: t("lib:common.action.export_pdf"),
				action: onPdfExport,
			},
			...(props.customExportActions || []).map((action) => ({
				title: action.title,
				action: () => action.onClick(state),
			})),
		],
		[props.mobileActions, props.customExportActions, state]
	);

	if (fetchItemsErrorOccurred) {
		return (
			<EmptyList
				title={t("lib:go_core.fetch_items_error.title")}
				description={t("lib:go_core.fetch_items_error.description")}
				actions={[
					{
						name: t("lib:common.action.refresh"),
						click: () => document.location.reload(),
					},
				]}
			/>
		);
	}

	if (
		!props.displayListFeaturesOnEmptyList &&
		selectedFilters.length === 0 &&
		search === "" &&
		activeData.length === 0 &&
		!loadingData
	) {
		return createEmptyList();
	}

	if (firstDataLoaded.current && loadingData) {
		return <LoadingContainer />;
	}

	const filterIconClasses = `filter-icon ${openFilters ? "" : "filter-icon-inactive"}`;

	return (
		<List>
			{showPdfGenerationOptionsModal && (
				<PdfGenerationOptionsModal
					show={showPdfGenerationOptionsModal}
					onHide={() => setShowPdfGenerationOptionsModal(false)}
					onSubmit={(orientation: PdfOrientationType, fontSize: string) =>
						handleExportToPdf(orientation, fontSize)
					}
					defaultValues={{
						fontSize: config?.exportConfig?.pdfFontSize,
						orientation:
							config?.exportConfig?.pdfOrientation === "ASK"
								? undefined
								: config?.exportConfig?.pdfOrientation,
					}}
				/>
			)}
			{!props.customView ? (
				<>
					{!isMobile && (
						<>
							<div className={`list-actions ${isMobile ? "mt-3" : "mt-0"}`}>
								{segments && !props.withoutSegments && (
									<Segment.List
										selected={selectedSegment}
										segments={segments}
										onChange={onChangeSegment}
									/>
								)}
								{!config.hideFilters && (
									<Button
										variant={classNames("", { primary: openFilters })}
										onClick={() => setOpenFilters(!openFilters)}
										aria-controls="filter-list-collapse"
										className={"filter-list-collapse"}
										aria-expanded={openFilters}
									>
										<ColumnSVG className={filterIconClasses} />
										{selectedFilters && selectedFilters.length > 0
											? `${selectedFilters.length} ${t("lib:common.word.filters")}`
											: `${t("lib:common.word.filters")}`}
									</Button>
								)}
								{segment &&
									!props.withoutSegments &&
									isSegmentChanged(state, segment, config) &&
									config.saveSegment && <Segment.Save onClick={onShowSaveSegment} />}
							</div>
							{segment && segmentModal && (
								<Segment.SaveModal
									segment={segment}
									onSave={onSaveSegment}
									onCreate={onCreateSegment}
									onHide={onHideSegmentModal}
								/>
							)}
						</>
					)}
					{!config.hideFilters &&
						filters &&
						(isMobile ? (
							<>
								{showMobileSearch && (
									<div className="list-search mb-1">
										<div className="list-search-input">
											<List.Search value={search} onChange={onChangeSearch} />
										</div>
									</div>
								)}
								<MobileFilters
									filters={filters}
									selectedFilters={selectedFilters}
									onChangeFilters={onChangeFilters}
									onChangeColumns={onChangeColumn}
									setShowMobileFilters={setShowMobileFilters}
									showMobileFilters={showMobileFilters}
									minDate={new Date("2015")}
									maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
									onChangeSegment={(segment) => onChangeSegment(segment as GoListSegmentType)}
									segments={state.segments}
									hasConfigSaveSegment={!!config.saveSegment}
									segmentModal={segmentModal}
									segment={segment}
									setSegmentModal={setSegmentModal}
									onCreateSegment={onCreateSegment}
									onSaveSegment={(
										segment,
										segmentFilters,
										segmentColumns,
										segmentAllColumnsInOrder,
										segmentStickyColumnsDividerPosition,
										preventUpdateSegment
									) =>
										saveSegment(
											segment as GoListSegmentType,
											segmentFilters,
											segmentColumns,
											segmentAllColumnsInOrder,
											segmentStickyColumnsDividerPosition,
											preventUpdateSegment
										)
									}
									selectedColumns={selectedColumns}
									allColumns={allColumnsInOrder}
									onChangeStickyColumnsDividerPosition={onChangeStickyColumnsDividerPosition}
									isNumberOfStickyColumnsDynamic={config.isNumberOfStickyColumnsDynamic}
									numberOfStickyColumnsAtTheStart={config.numberOfStickyColumnsAtTheStart}
									numberOfActiveStickyColumns={numberOfActiveStickyColumns}
									setNumberOfActiveStickyColumns={setNumberOfActiveStickyColumns}
									shouldDisableSortingOfStickyColumns={config.shouldDisableSortingOfStickyColumns}
									stickyColumnsDividerPosition={stickyColumnsDividerPosition}
									columnsFields={config.fields}
									customFields={state.customFields}
									defaultSelectedColumns={config.selectedColumns}
									defaultSelectedColumnsOrder={config.fields}
									stateColumns={state?.columns}
									defaultSort={state?.sort}
								/>
							</>
						) : (
							<Collapse in={openFilters}>
								<div id="filter-list-collapse" className={"filters-list"}>
									<List.Filters
										filters={filters}
										selectedFilters={selectedFilters}
										onChange={onChangeFilters}
									/>
								</div>
							</Collapse>
						))}
					{!isMobile && (
						<div className="list-search">
							<div className="list-search-input">
								<List.Search value={search} onChange={onChangeSearch} />
							</div>
							<div
								className={`list-search-actions flex-wrap justify-content-end ${
									props.customActions ? "" : "without-custom-actions"
								}`}
							>
								{props.customActions}
								<div className="list-search-columns">
									<List.Columns
										columnsFields={config.fields}
										customFields={state.customFields}
										allColumnsInOrder={allColumnsInOrder}
										onChangeColumnsOrder={onChangeColumnsOrder}
										onChangeStickyColumnsDividerPosition={onChangeStickyColumnsDividerPosition}
										onChange={onChangeColumn}
										selectedColumns={selectedColumns}
										segment={segment}
										isNumberOfStickyColumnsDynamic={config.isNumberOfStickyColumnsDynamic}
										numberOfActiveStickyColumns={numberOfActiveStickyColumns}
										setNumberOfActiveStickyColumns={setNumberOfActiveStickyColumns}
										shouldDisableSortingOfStickyColumns={config.shouldDisableSortingOfStickyColumns}
										stickyColumnsDividerPosition={stickyColumnsDividerPosition}
									/>
								</div>
								<List.Actions
									onCsvExport={onCsvExport}
									onPdfExport={onPdfExport}
									state={state}
									customExportActions={props.customExportActions}
								/>
							</div>
						</div>
					)}
					<MobileActions actions={mobileActions}>
						<SearchSVG className="me-2" onClick={() => setShowMobileSearch((prevState) => !prevState)} />
					</MobileActions>
					<List.Table
						loading={loadingData}
						data={activeData}
						selectedColumns={selectedColumns}
						columns={columns}
						actions={config.actions}
						multipleActions={config.multipleActions}
						groupBy={config.groupBy}
						selectedSorts={selectedSorts}
						onSort={onSort}
						selectedSegment={selectedSegment}
						nested_config={config.nested_config}
						customTableRow={props.customTableRow}
						getStatus={config.getStatus}
						numberOfActiveStickyColumns={numberOfActiveStickyColumns}
						totalItemsCount={totalItemsCountForSelectedFilters}
						params={updateListParamsFromSegment(state)}
						canManageMultiActionsForAllItems={props.canManageMultiActionsForAllItems}
						shouldFilterAvailableMultipleActions={props.shouldFilterAvailableMultipleActions}
						doesIdColumnRedirectToPreviewPage={config.doesIdColumnRedirectToPreviewPage}
					/>
					{(activeData.length > 0 || page !== undefined) && (
						<List.Pagination>
							<List.Pagination.Size
								value={size}
								onChange={onChangeSize}
								customPageSizes={config.customPageSizes}
							/>
							<List.Pagination.Pages
								value={page}
								nextPage={shouldEnableNextPageButton()}
								onChange={onChangePage}
							/>
						</List.Pagination>
					)}
				</>
			) : (
				<>{props.customView}</>
			)}
		</List>
	);
};
export { ListData };
