import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import { ReactComponent as SearchSVG } from "../../../images/svg/search.svg";

interface Props {
	loading?: boolean;
	onButtonClick: () => void;
	onInput: (msg: string) => void;
	value?: string;
}

const InputWithSearch = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div className="input-group">
			<input
				type="text"
				className="form-control"
				value={props.value}
				placeholder={t("common.word.search", { ns: "lib" })}
				onChange={(evt) => props.onInput(evt.target.value)}
			/>
			<SearchSVG className="search-icon" />
			<div className="input-group-append">
				<ButtonLoading loading={!!props.loading} variant={"primary"} onClick={props.onButtonClick}>
					{t("common.action.create", { ns: "lib" })}
				</ButtonLoading>
			</div>
		</div>
	);
};
export default InputWithSearch;
