import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { DashboardFilterActions } from "../../services/filters/reducer";
import { DashboardType } from "../../services/types";
import useDashboardContext from "../../services/useDashboardContext";
import { DashboardOrganizationSearchSelect } from "./DashboardOrganizationSearchSelect";
import DashboardFilterDateRange from "./FilterDateRange";

interface Props {
	show: boolean;
	onHide: () => void;
	dateFilterTypes: string[];
	isPredefinedDate: boolean;
	setSelectedDateFilter: (value: string | null) => void;
	selectedDateFilter: string | null;
}

const DashboardMobileFilters = ({
	show,
	onHide,
	dateFilterTypes,
	isPredefinedDate,
	setSelectedDateFilter,
	selectedDateFilter,
}: Props) => {
	const { t } = useTranslation();
	const { dashboardType, dispatch, state } = useDashboardContext();
	const isMobile = useWindowSize().isMobile;
	const [innerSelectedDateFilter, setInnerSelectedDateFilter] = useState(selectedDateFilter);
	const [selectedOrganizations, setSelectedOrganizations] = useState(
		(state.filtersConfig.selectedFilter.organizationIds || "").split(FILTER_VALUE_SEPARATOR).join(",")
	);

	useEffect(() => {
		dispatch({
			type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
			data: false,
		});
	}, []);

	const handleClearFilters = () => {
		dispatch({
			type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
			data: false,
		});

		onHide();
	};

	const handleApply = () => {
		if (innerSelectedDateFilter !== selectedDateFilter) {
			dispatch({
				type: DashboardFilterActions.CHANGE_DATE_FILTER,
				data:
					innerSelectedDateFilter === null
						? "today"
						: dateFilterTypes.includes(innerSelectedDateFilter)
						? innerSelectedDateFilter
						: innerSelectedDateFilter.replace(" - ", ","),
			});
			dispatch({
				type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
				data: true,
			});
			setSelectedDateFilter(innerSelectedDateFilter === null ? "today" : innerSelectedDateFilter);
		}

		if (dashboardType === DashboardType.GROUP_ORGANIZATIONS) {
			const prevOrganizations = (state.filtersConfig.selectedFilter.organizationIds || "")
				.split(FILTER_VALUE_SEPARATOR)
				.join(",");
			if (JSON.stringify(selectedOrganizations) !== JSON.stringify(prevOrganizations)) {
				dispatch({
					type: DashboardFilterActions.CHANGE_ORGANIZATIONS_FILTER,
					data: selectedOrganizations,
				});
			}
		}

		onHide();
	};

	const renderFilters = useMemo(() => {
		return (
			<div className="dashboard-filters-list">
				<div className="filters-list-items mb-2">
					{dateFilterTypes.map((filter) => (
						<Button
							key={filter}
							onClick={() => setInnerSelectedDateFilter(filter)}
							variant={filter === innerSelectedDateFilter ? "primary" : "outline-dark"}
						>
							{t(`modules.dashboard.field.${filter}.title`)}
						</Button>
					))}
				</div>
				<div
					className={`${
						dashboardType === DashboardType.GROUP_ORGANIZATIONS ? "mb-2" : "mb-0"
					} date-range-container`}
				>
					<DashboardFilterDateRange
						isPredefinedDate={isPredefinedDate}
						selectedDateFilter={innerSelectedDateFilter}
						setSelectedDateFilter={setInnerSelectedDateFilter}
					/>
				</div>
				<hr />
				{dashboardType === DashboardType.GROUP_ORGANIZATIONS && (
					<>
						<DashboardOrganizationSearchSelect
							setSelectedOrganizations={setSelectedOrganizations}
							selectedOrganizations={selectedOrganizations}
						/>
						<hr />
					</>
				)}
			</div>
		);
	}, [dateFilterTypes, isPredefinedDate, dashboardType, innerSelectedDateFilter, isMobile]);

	return (
		<Modal show={show} onHide={onHide} className="mobile-filters">
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:common.word.filters")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5>{t("modules.dashboard.field.according_to_time.title")}</h5>
				{renderFilters}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="light" onClick={handleClearFilters}>
					{t("lib:common.action.cancel")}
				</Button>
				<Button onClick={handleApply}>{t("lib:go_list.action.show_results.title")}</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DashboardMobileFilters;
