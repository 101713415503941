import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmptyList from "go-core/components/EmptyList";
import { parseToSelectedDateFilter } from "../../services/charts/chartService";
import { DashboardCardConfig } from "../../services/charts/types";
import { DashboardReportType } from "../../services/types";
import useDashboardContext from "../../services/useDashboardContext";
import { useDashboardReportAggregate } from "../../utils/useDashboardReportAggregate";
import ReportItemWrapper from "./ReportItemWrapper/ReportItemWrapper";
import ReportRenderer from "./ReportRenderer";

const DashboardReportProduct = (): JSX.Element => {
	const { state } = useDashboardContext();
	const { t } = useTranslation();
	const chartDataRangeType = state?.chartsConfig?.reportProduct.range;
	const chartType = state?.chartsConfig.reportProduct.type;
	const { reportAggregateType } = useDashboardReportAggregate(DashboardReportType.PRODUCT);

	const report = useMemo(
		() => ({
			sub_report: state?.data?.product_reports?.reports,
		}),
		[state?.data?.product_reports?.reports]
	);
	const selectedFilters = useMemo(
		() => parseToSelectedDateFilter(state.filtersConfig.selectedFilter.dateRange),
		[state.filtersConfig.selectedFilter.dateRange]
	);
	const renderView = useMemo(() => {
		if (!report.sub_report || !report.sub_report.length) {
			return <EmptyList />;
		}
		return (
			<ReportRenderer
				data={report.sub_report}
				selectedFilters={selectedFilters}
				chartDataRangeType={chartDataRangeType}
				type={chartType}
				reportType={DashboardReportType.PRODUCT}
			/>
		);
	}, [chartType, reportAggregateType, chartDataRangeType, report]);

	const config = {
		title: t("modules.dashboard.field.top_products.title"),
		link: "reports/products",
		type: "product_reports",
		chartDataRangeType,
		chartType,
	} as DashboardCardConfig;

	return (
		<Card className={"dashboard-card"}>
			<Card.Body>
				<ReportItemWrapper config={config}>{renderView}</ReportItemWrapper>
			</Card.Body>
		</Card>
	);
};

export default DashboardReportProduct;
