import { useEffect, useRef, useState } from "react";
import { GoListSegmentType } from "go-list/list/services/types";
import { ListConfigColumn } from "../services/types";

export const useNewColumnsAutoSelect = ({
	columnsFields,
	allColumnsInOrder,
	selectedColumns,
	onChange,
	segment,
}: {
	columnsFields?: ListConfigColumn[];
	allColumnsInOrder: React.MutableRefObject<ListConfigColumn[]>;
	selectedColumns: string[];
	onChange: (newSelectedColumns: string[], sortedColumns: string[]) => void;
	segment?: GoListSegmentType | undefined;
}): void => {
	const previousSegmentSlug = useRef<string | undefined>(segment?.slug);
	const newColumnsIds = useRef<string[]>([]);
	const [shouldUpdateSelectedColumns, setShouldUpdateSelectedColumns] = useState(false);

	useEffect(() => {
		if (previousSegmentSlug.current !== segment?.slug) {
			previousSegmentSlug.current = segment?.slug;
			setShouldUpdateSelectedColumns(false);
		}

		if (!shouldUpdateSelectedColumns && allColumnsInOrder.current.length === columnsFields?.length) {
			setShouldUpdateSelectedColumns(true);
		}
	}, [segment, shouldUpdateSelectedColumns, allColumnsInOrder, columnsFields]);

	useEffect(() => {
		if (!columnsFields) {
			return;
		}

		if (columnsFields.length > allColumnsInOrder.current.length) {
			const newColumns = columnsFields.filter(
				(field) => !allColumnsInOrder.current.find((column) => column.id === field.id)
			);

			newColumnsIds.current = newColumns.map((column) => column.id);
		}
	}, [allColumnsInOrder, columnsFields]);

	useEffect(() => {
		if (!columnsFields || !shouldUpdateSelectedColumns) {
			newColumnsIds.current = [];
			return;
		}

		if (newColumnsIds.current.length > 0 && columnsFields.length === allColumnsInOrder.current.length) {
			onChange(
				[...selectedColumns, ...newColumnsIds.current],
				allColumnsInOrder.current.map((column) => column.id)
			);

			newColumnsIds.current = [];
		}
	}, [allColumnsInOrder, columnsFields, selectedColumns, onChange, shouldUpdateSelectedColumns]);
};
