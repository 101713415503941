import { ConfigReportComboApi } from "go-report-combo/services/types";

export const REPORT_tpizza_yearly = {
	id: "REPORT_tpizza_yearly",
	name: "Tpizza - Raport roczny z podziałem na miesiące",
	data: [
		{
			id: "BILLS",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids__ORGANIZATION}`,
			groups: ["NONE", "YEAR"],
			columns: [
				{
					id: "monthly",
					group: "MONTH",
					filter: "date_range|bt=previous_4_years",
				},
			],
		},
	],
	rows: [
		{
			id: "BILLS",
			name: "BILLS",
			data_id: "BILLS",
			group: "YEAR",
			aggregate: "total_money",
			compare: [],
		},
	],
	columns: [
		{
			id: "monthly",
			data_column_id: "monthly",
			name: "Sprzedaż",
			values_dynamic: `values_previous_12_months`,
		},
		{
			id: "summary",
			data_column_id: "monthly",
			name: "Sprzedaż",
		},
	],
} as ConfigReportComboApi;
