import React, { useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { AlertMessagePermission, AlertMessages, CustomMessageProvider } from "go-alert/AlertMessage";
import ScrollToTop from "go-core/components/ScrollToTop";
import { useMomentLocaleForUser } from "go-core/hooks/useMomentLocale";
import { ConfirmationServiceProvider } from "go-form/components/ModalConfirm";
import ParametersSecurity from "go-security/services";
import { restSecurityApi } from "go-security/services/Api/api";
import { refreshTokenApi } from "go-security/services/Api/applyAppTokenRefreshInterceptor";
import { AuthCredentialsService } from "go-security/services/AuthCredentialsService";
import { AuthCredentials } from "go-security/services/types";
import { selectUser } from "go-security/services/users/selectors";
import Routes from "./Routes";
import { useSentry } from "./app/crashreport/useSentry";
import GetResponse from "./components/Helmet/GetResponse";
import GoogleAnalytics from "./components/Helmet/GoogleAnalytics";
import { getApiURL, getGoAccountsApiURL, getSentryDSN, getTokenClientId, getTokenClientSecret } from "./utils/envutil";

const accountsUrl = getGoAccountsApiURL();

AuthCredentialsService.init({
	clientId: getTokenClientId(),
	secretId: getTokenClientSecret(),
	url: getApiURL(),
	accountsUrl,
} as AuthCredentials);
restSecurityApi.defaults.baseURL = `${getApiURL()}/ajax/`;
refreshTokenApi.defaults.baseURL = `${getApiURL()}/`;
ParametersSecurity.setLoginRedirectUrl(accountsUrl);
ParametersSecurity.setIframeUrl(accountsUrl);
ParametersSecurity.setAccountsUrl(accountsUrl);
ParametersSecurity.setAppName("GoPOS");

function App(): JSX.Element {
	const user = useSelector(selectUser);
	const { t } = useTranslation();

	const permissionIds = [
		"DISCOUNT_READ",
		"DISCOUNT_WRITE",
		"MANAGE",
		"MENU_READ",
		"MENU_WRITE",
		"NOTIFICATIONS",
		"PRICE_LIST_READ",
		"PRICE_LIST_WRITE",
		"SETTINGS",
		"VENUE_API",
		"VENUE_CLIENT_MANAGE",
		"VENUE_DISCOUNT_MANAGE",
		"VENUE_EMPLOYEE_MANAGE",
		"VENUE_EMPLOYEE_HOURLY_RATE_ACCESS",
		"VENUE_INVOICE_EDIT",
		"VENUE_INVOICE_MANAGE",
		"VENUE_LIVE_ORDERS",
		"VENUE_OWN_INVOICE_MANAGE",
		"VENUE_MENU_MANAGE",
		"VENUE_ORDER_EDIT",
		"VENUE_ORDER_SHOW",
		"VENUE_POS_ACCESS",
		"VENUE_POS_ACCESS",
		"VENUE_REPORTPOS_SHOW",
		"VENUE_REPORTPOS_PAID_MANAGE",
		"VENUE_REPORTS_SHOW",
		"VENUE_ROOM_MANAGE",
		"VENUE_TABLE_RESERVATION",
	];

	const permissions: AlertMessagePermission[] = permissionIds.map((id) => ({
		id,
		title: t(`enums.permissions.venue.value.${id}`),
	}));

	useEffect(() => {
		if (i18n.language) document.documentElement.lang = i18n.language;
	}, [i18n.language]);

	const sentryDSN = getSentryDSN();
	useSentry(sentryDSN);
	useMomentLocaleForUser(user);

	return (
		<div className="App">
			<GoogleAnalytics />
			<GetResponse />
			<IntercomProvider autoBoot={false} appId={user.more?.intercom?.id ? user.more?.intercom.id : ""}>
				<BrowserRouter>
					<CustomMessageProvider>
						<ConfirmationServiceProvider>
							<AlertMessages permissions={permissions} />
							<ScrollToTop />
							<Routes />
						</ConfirmationServiceProvider>
					</CustomMessageProvider>
				</BrowserRouter>
			</IntercomProvider>
		</div>
	);
}

export default App;
