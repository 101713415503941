import { ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "../../Filter/services";

export const getMultuipleActionFParam = (params: ListParamsType): MultipleActionsParams | undefined => {
	if (params.f) return { f: params.f };
	if (params.filters) {
		let fParamString = "";
		params.filters.forEach((filter) => {
			fParamString += `${FILTER_SEPARATOR}${filter.filterId}|${filter.condition}=${filter.value}`;
		});
		return { f: btoa(unescape(encodeURIComponent(NEW_WAY_TO_ENCODING_FILTER_SIGN + fParamString))) };
	}
	return undefined;
};
