import React, { FC, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { FilterCondition, ListConfigFilter, ListSelectedFilter } from "../../services/types";

interface State {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	onChange: (condition: FilterCondition, selectedFilters: string) => void;
}

const ListFilterRadio: FC<State> = (props) => {
	const [value, setValue] = useState(
		props.selectedFilter.value
			? props.selectedFilter.value
			: Object.keys(
					(props.filter.options
						? props.filter.options
						: props.filter.listOptions
						? props.filter.listOptions
						: {}) as Record<string, any>
			  )[0]?.toString()
	);
	const onChangeValue = (value: string) => {
		setValue(value);
		props.onChange("e", value);
	};

	const onInputClick = (value: string, evt?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		evt?.stopPropagation();
		onChangeValue(value);
	};

	useEffect(() => {
		props.onChange("e", value);
	}, []);

	const options = props.filter.options
		? props.filter.options
		: props.filter.listOptions
		? props.filter.listOptions
		: [];
	return (
		<>
			<Form.Group className="form-group">
				{Object.entries(options).map(([key, val]) => {
					return (
						<Dropdown.Item
							key={`type_${props.id}_${key}`}
							onClick={() => onChangeValue(key as FilterCondition)}
						>
							<Form.Check
								type="radio"
								className="form-check"
								label={val}
								name={`type_${props.id}_${key}`}
								id={`type${props.id}_${key}`}
								value={key}
								checked={key === value}
								onClick={(e) => onInputClick(e.currentTarget.value as FilterCondition, e)}
							/>
						</Dropdown.Item>
					);
				})}
			</Form.Group>
		</>
	);
};

export default ListFilterRadio;
