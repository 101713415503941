import { ApiError, Exception } from "./types";

export interface ExceptionHandler {
	handle(exception: Exception | unknown): Array<ApiError> | null;
}

export class CustomExceptionHandler {
	static specificExceptionHandlers: ExceptionHandler[] = [];

	static setup(exceptionHandlers: ExceptionHandler[]): void {
		this.specificExceptionHandlers = exceptionHandlers;
	}

	static handle(exception: Exception | unknown): Array<ApiError> | null {
		for (let i = 0; i < CustomExceptionHandler.specificExceptionHandlers.length; i++) {
			const exceptionHandlerResult = CustomExceptionHandler.specificExceptionHandlers[i].handle(exception);

			if (exceptionHandlerResult) {
				return exceptionHandlerResult;
			}
		}

		return null;
	}
}
