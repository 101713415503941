import React from "react";
import VirtualTableSearchColumn from "../../../../../../components/VirtualTable/VirtualTableSearchColumn";
import { VirtualTableColumn } from "../../../../../../components/VirtualTable/types";
import {
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../../utils";

interface Props {
	visibleDefaultColumns: VirtualTableColumn[];
	maxZindex: number;
	setCategorySearch: (search: string) => void;
	setItemGroupSearch: (search: string) => void;
	setFullNameSearch: (search: string) => void;
	setNameSearch: (search: string) => void;
	categorySearch: string | undefined;
	itemGroupSearch: string | undefined;
	nameSearch: string | undefined;
	fullNameSearch: string | undefined;
}

const ItemGroupGroupEditingVirtualTableHeaderSearchColumnsRow = ({
	visibleDefaultColumns,
	setCategorySearch,
	maxZindex,
	categorySearch,
	setItemGroupSearch,
	itemGroupSearch,
	setFullNameSearch,
	setNameSearch,
	nameSearch,
	fullNameSearch,
}: Props) => {
	const handleClearFilter = (columnId: string) => {
		switch (columnId) {
			case ItemGroupGroupEditingColumn.CATEGORY:
				setCategorySearch("");
				break;
			case ItemGroupGroupEditingColumn.ITEM_GROUP:
				setItemGroupSearch("");
				break;
			case ItemGroupGroupEditingColumn.NAME:
				setNameSearch("");
				break;
			case ItemGroupGroupEditingColumn.FULL_NAME:
				setFullNameSearch("");
				break;
		}
	};

	const handleSearch = (columnId: string, search: string) => {
		switch (columnId) {
			case ItemGroupGroupEditingColumn.CATEGORY:
				setCategorySearch(search);
				break;
			case ItemGroupGroupEditingColumn.ITEM_GROUP:
				setItemGroupSearch(search);
				break;
			case ItemGroupGroupEditingColumn.NAME:
				setNameSearch(search);
				break;
			case ItemGroupGroupEditingColumn.FULL_NAME:
				setFullNameSearch(search);
				break;
		}
	};

	const getSearchValue = (columnId: string) => {
		switch (columnId) {
			case ItemGroupGroupEditingColumn.CATEGORY:
				return categorySearch;
			case ItemGroupGroupEditingColumn.ITEM_GROUP:
				return itemGroupSearch;
			case ItemGroupGroupEditingColumn.NAME:
				return nameSearch;
			case ItemGroupGroupEditingColumn.FULL_NAME:
				return fullNameSearch;
			default:
				return "";
		}
	};

	const drawSearchColumns = () => {
		let prevLeftPosition = 0;
		return visibleDefaultColumns.map((col, index) => {
			const prevColumn = visibleDefaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const isLastColumn = index === visibleDefaultColumns.length - 1;
			const zIndex = maxZindex - index;
			const isHide = isVirtualTableColumnHide(col.id, visibleDefaultColumns);

			return (
				<VirtualTableSearchColumn
					isEmpty={false}
					key={`search-column-${col.id}`}
					clearFilter={() => handleClearFilter(col.id)}
					searchValue={getSearchValue(col.id)}
					search={(value) => handleSearch(col.id, value)}
					isHide={isHide}
					positionLeft={leftPosition}
					isSticky
					lastColumn={isLastColumn}
					zIndex={zIndex}
				/>
			);
		});
	};

	return <>{drawSearchColumns()}</>;
};

export default ItemGroupGroupEditingVirtualTableHeaderSearchColumnsRow;
