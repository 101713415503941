import React, { memo, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
	PointOfSaleApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTable } from "../../../../../components/VirtualTable/VirtualTable";
import { useResizeVirtualTableHeader } from "../../../../../components/VirtualTable/hooks/useResizeVirtualTableHeader";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../components/VirtualTable/types";
import {
	STARTING_CSS_INDEX,
	getVirtualTableHeaderStyles,
	getVirtualTableItemsAfterSortingAndFiltering,
} from "../../../../../components/VirtualTable/utils";
import {
	ItemGroupGroupEditingColumn,
	getItemGroupGroupEditingListOtherColumns,
	itemGroupVirtualTableColumnKeys,
} from "../utils";
import ItemGroupGroupEditingVirtualTableHeader from "./ItemGroupGroupEditingVirtualTableHeader/ItemGroupGroupEditingVirtualTableHeader";
import ItemGroupGroupEditingVirtualTableRow from "./ItemGroupGroupEditingVirtualTableRow";

interface Props {
	heightTr: number;
	width: number;
	height: number;
	items: ItemFormApi[];
	sortings: VirtualTableListSelectedSort[];
	setSortings: (sortings: VirtualTableListSelectedSort[]) => void;
	visibleDefaultColumns: VirtualTableColumn[];
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	visiblePointsOfSale: VirtualTableColumn[];
	visibleOtherColumns: VirtualTableColumn[];
	pointsOfSale: PointOfSaleApi[];
}

const Row = memo(
	({
		index,
		data,
		style,
		form,
		visibleDefaultColumns,
		columnWidths,
		maxZIndex,
		visiblePointsOfSale,
		visibleOtherColumns,
		pointsOfSales,
	}: {
		index: number;
		data: ItemFormApi[];
		style: Record<string, any>;
		form: UseFormReturn<ItemGroupGroupEditingFormProps>;
		visibleDefaultColumns: VirtualTableColumn[];
		columnWidths: Record<string, number>;
		maxZIndex: number;
		visiblePointsOfSale: VirtualTableColumn[];
		visibleOtherColumns: VirtualTableColumn[];
		pointsOfSales: PointOfSaleApi[];
	}) => {
		const item = data[index];
		return (
			<ItemGroupGroupEditingVirtualTableRow
				item={item}
				form={form}
				defaultColumns={visibleDefaultColumns}
				columnWidths={columnWidths}
				maxZIndex={maxZIndex}
				style={style}
				pointsOfSaleColumns={visiblePointsOfSale}
				pointsOfSale={pointsOfSales}
				visibleOtherColumns={visibleOtherColumns}
			/>
		);
	}
);

const ItemGroupGroupEditingVirtualTable = ({
	height,
	heightTr,
	width,
	items,
	sortings,
	setSortings,
	visibleDefaultColumns,
	form,
	visiblePointsOfSale,
	visibleOtherColumns,
	pointsOfSale,
}: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const intervalRef = useRef<any>(null);
	const [categorySearch, setCategorySearch] = useState<string | undefined>();
	const [itemGroupSearch, setItemGroupSearch] = useState<string | undefined>();
	const [nameSearch, setNameSearch] = useState<string | undefined>();
	const [fullNameSearch, setFullNameSearch] = useState<string | undefined>();
	const [pointsOfSaleSearch, setPointsOfSaleSearch] = useState<{ id: string; search?: string }[]>(
		pointsOfSale.map((point) => ({
			id: point.id.toString(),
			search: undefined,
		}))
	);
	const prevItems = useRef<string | string[]>([]);
	const { handleVirtualTableHeaderResize, handleVirtualTableMouseDown, handleVirtualTableMouseUp } =
		useResizeVirtualTableHeader();
	const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});
	const MAX_ZINDEX = visibleDefaultColumns.length + STARTING_CSS_INDEX;
	const { watch } = form;
	const [otherColumnsSearch, setOtherColumnsSearch] = useState<{ id: string; search?: string }[]>([
		...getItemGroupGroupEditingListOtherColumns(t).map((column) => ({
			id: column.id,
			search: undefined,
		})),
	]);

	const style = {} as Record<string, any>;
	let finalHeight = 500;
	if (height && width) {
		style.height = height;
		style.width = width;
		finalHeight = height;
	}

	useEffect(() => {
		if (JSON.stringify(items) !== prevItems.current) {
			fakeLoading();
			prevItems.current = JSON.stringify(items);
		}
	}, [items]);

	useEffect(() => {
		fakeLoading();
	}, [
		categorySearch,
		nameSearch,
		fullNameSearch,
		itemGroupSearch,
		sortings,
		pointsOfSaleSearch.map(({ search }) => search).join(","),
		otherColumnsSearch.map(({ search }) => search).join(","),
	]);

	const fakeLoading = () => {
		setLoading(true);
		if (intervalRef.current) clearTimeout(intervalRef.current);
		intervalRef.current = setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	const isSearched = () => {
		return (
			categorySearch !== undefined ||
			itemGroupSearch !== undefined ||
			nameSearch !== undefined ||
			fullNameSearch !== undefined ||
			otherColumnsSearch.some(({ search }) => search !== undefined)
		);
	};

	const itemsSearcher = () => {
		const formItems: ItemFormApi[] = [...watch().items];
		let newItems = formItems.filter((formItem) => items.find((item) => formItem.id === item.id));
		if (!isSearched()) return [...newItems];

		if (categorySearch) {
			newItems = newItems.filter((item) =>
				item?.category.name?.toLowerCase()?.includes(categorySearch.toLowerCase())
			);
		}
		if (itemGroupSearch) {
			newItems = newItems.filter((item) =>
				item?.item_group?.name?.toLowerCase()?.includes(itemGroupSearch.toLowerCase())
			);
		}
		if (nameSearch) {
			if (nameSearch === "-") {
				newItems = newItems.filter((item) => item?.name === t("common.word.default"));
			} else {
				newItems = newItems.filter(
					(item) =>
						item.name !== t("common.word.default") &&
						item?.name?.toLowerCase()?.includes(nameSearch.toLowerCase())
				);
			}
		}
		if (fullNameSearch) {
			newItems = newItems.filter((item) =>
				item?.full_name?.toLowerCase()?.includes(fullNameSearch.toLowerCase())
			);
		}

		const availabilitySearch = otherColumnsSearch.find(
			(columnSearch) => columnSearch.id === ItemGroupGroupEditingColumn.AVAILABILITY
		);
		if (availabilitySearch && availabilitySearch?.search) {
			newItems = newItems.filter((item) =>
				item?.availability?.name?.toLowerCase()?.includes((availabilitySearch.search || "").toLowerCase())
			);
		}

		const directionSearch = otherColumnsSearch.find(
			(columnSearch) => columnSearch.id === ItemGroupGroupEditingColumn.DIRECTION
		);
		if (directionSearch && directionSearch?.search) {
			newItems = newItems.filter((item) =>
				item?.direction?.name?.toLowerCase()?.includes((directionSearch.search || "").toLowerCase())
			);
		}

		return newItems;
	};

	const itemsSearcherByPointsOfSale = () => {
		if (pointsOfSaleSearch.every((pointSearch) => pointSearch.search === undefined)) {
			return itemsSearcher();
		}

		const itemsFromItemSearcher = itemsSearcher();
		const formItems: ItemFormApi[] = [...watch().items];
		const newItems = formItems.filter((item) =>
			itemsFromItemSearcher.find((itemFromSearcher) => itemFromSearcher.id === item.id)
		);

		return newItems.filter((item) => {
			return item.points_of_sale.every((pointOfSale) => {
				const filterValue =
					pointsOfSaleSearch.find((search) => Number(search.id) === pointOfSale.point_of_sale_id)?.search ||
					"";
				return (pointOfSale?.direction?.name || "").toLowerCase().includes(filterValue.toLowerCase());
			});
		});
	};

	const handleHeaderResize = (columnWidth: number | undefined, key: string) => {
		handleVirtualTableHeaderResize(columnWidth, setColumnWidths, key, columnWidths);
	};

	const getHeaderStyles = (key: string) => {
		return getVirtualTableHeaderStyles(key, columnWidths);
	};

	const getItemsAfterSortingAndFiltering = () => {
		return getVirtualTableItemsAfterSortingAndFiltering(itemsSearcherByPointsOfSale(), sortings, [
			...itemGroupVirtualTableColumnKeys,
			...pointsOfSale.map((pointOfSale, index) => ({
				key: `points_of_sale.${index}.direction.name`,
				columnName: pointOfSale.id.toString(),
			})),
		]) as ItemFormApi[];
	};

	return (
		<div className="virtual-table item-group-group-editing-virtual-table" style={style}>
			<VirtualTable
				height={finalHeight}
				width="auto"
				itemCount={getItemsAfterSortingAndFiltering().length}
				itemSize={heightTr}
				loading={loading}
				itemKey={(key) => key}
				itemData={getItemsAfterSortingAndFiltering()}
				row={(props) => (
					<Row
						form={form}
						visibleDefaultColumns={visibleDefaultColumns}
						columnWidths={columnWidths}
						maxZIndex={MAX_ZINDEX}
						visiblePointsOfSale={visiblePointsOfSale}
						visibleOtherColumns={visibleOtherColumns}
						pointsOfSales={pointsOfSale}
						{...props}
					/>
				)}
				header={
					<ItemGroupGroupEditingVirtualTableHeader
						getHeaderStyles={getHeaderStyles}
						sortings={sortings}
						handleMouseUp={(e, columnName, skipSorting) =>
							handleVirtualTableMouseUp(e, columnName, sortings, setSortings, skipSorting)
						}
						visibleDefaultColumns={visibleDefaultColumns}
						handleHeaderResize={handleHeaderResize}
						maxZindex={MAX_ZINDEX}
						handleMouseDown={handleVirtualTableMouseDown}
						form={form}
						items={getItemsAfterSortingAndFiltering()}
						categorySearch={categorySearch}
						itemGroupSearch={itemGroupSearch}
						setCategorySearch={setCategorySearch}
						setItemGroupSearch={setItemGroupSearch}
						fullNameSearch={fullNameSearch}
						nameSearch={nameSearch}
						setFullNameSearch={setFullNameSearch}
						setNameSearch={setNameSearch}
						visiblePointsOfSale={visiblePointsOfSale}
						visibleOtherColumns={visibleOtherColumns}
						pointsOfSaleSearch={pointsOfSaleSearch}
						setPointsOfSaleSearch={setPointsOfSaleSearch}
						otherColumnsSearch={otherColumnsSearch}
						setOtherColumnsSearch={setOtherColumnsSearch}
						pointsOfSale={pointsOfSale}
					/>
				}
			/>
		</div>
	);
};

export default ItemGroupGroupEditingVirtualTable;
