import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { DirectionApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../../../utils/entityStatus/entityStatus";
import SubmenuRoomsNavigation from "../../../../components/SubmenuRoomsNavigation";
import DirectionModalForm from "../../components/DirectionModalForm";

interface ListState {
	resource?: Record<string, any>;
	setDirection: (value: DirectionApi | undefined) => void;
	items: DirectionApi[];
	setItems: (value: DirectionApi[]) => void;
	mobileActions: MobileActionProps[];
}

const listName = "DIRECTION";
const resourceType = "DIRECTION";

const List: FC<ListState> = ({ resource, setDirection, items, setItems, mobileActions }) => {
	const segmentContext = useContext(SegmentContext);
	const organization = useSelector(selectOrganization);
	const { addSuccessFlash, addFlash } = useFlash();
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { t } = useTranslation();

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRestoreDirection = async (item: DirectionApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreDirection(item.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemoveDirection = async (item: DirectionApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeDirection(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};
	const onUpdateDirection = (item: DirectionApi) => {
		setDirection(item);
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: DirectionApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<RenderLimitedText minWidth={200} onClick={() => onUpdateDirection(item)} textStyleIsLink>
								{item.name}
							</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: DirectionApi) => item.name,
			},
			{
				id: "device",
				disableSorting: true,
				name: t("modules.direction.field.devices.title"),
				render: (item: DirectionApi) => item.devices?.map((device) => device.name).join(", "),
			} as ListConfigField,
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: DirectionApi) => {
					onUpdateDirection(item);
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: DirectionApi) => onRemoveDirection(item),
				visible: (item) => item.status !== "DELETED",
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: DirectionApi) => onRestoreDirection(item),
				visible: (item) => item.status === "DELETED",
			},
		],
		selectedColumns: ["name", "device"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
			{
				id: "device",
				name: t("modules.direction.field.device.title"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getDevicesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			} as ListConfigField,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("common.word.directions"),
			filename: t("modules.direction.field.file_name.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "devices";
			setParams(fetchParams);
			return api.organization().getDirections(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			onFetch={(items: DirectionApi[]) => setItems(items)}
			emptyList={{
				addAction: () => setDirection({} as DirectionApi),
			}}
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationSettingsDirectionsIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const [items, setItems] = useState<DirectionApi[]>([]);
	const { addFlash } = useFlash();
	const [direction, setDirection] = useState<DirectionApi | undefined>(undefined);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("common.word.directions"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			variant: "primary",
			action: () => setDirection({} as DirectionApi),
			title: t("common.action.add", { ns: "lib" }),
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			action: () => setDirection({} as DirectionApi),
			title: t("common.action.add", { ns: "lib" }),
		},
	];

	const handleSave = async () => {
		setDirection(undefined);
		try {
			const res = await api.organization().getDirections({ include: "devices" });
			setItems(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<>
			{!isMobile && <Header title={t("modules.room.header.title")} buttons={buttons} />}
			<SubmenuRoomsNavigation />
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					setDirection={setDirection}
					setItems={setItems}
					items={items}
					mobileActions={mobileActions}
				/>
				{direction && (
					<DirectionModalForm
						direction={direction}
						onHide={() => setDirection(undefined)}
						handleSave={handleSave}
					/>
				)}
			</Suspense>
		</>
	);
};
export default OrganizationSettingsDirectionsIndexPage;
