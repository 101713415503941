import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DashboardFilterActions } from "../../services/filters/reducer";
import useDashboardContext from "../../services/useDashboardContext";

interface FilterItemProps {
	type: string;
}

const FilterItem = (props: FilterItemProps): JSX.Element => {
	const { state, dispatch } = useDashboardContext();
	const { type } = props;
	const { t } = useTranslation();
	const isSelected = state.filtersConfig.selectedFilter.dateRange === type;
	const reloadDashboardData = () => {
		dispatch({
			type: DashboardFilterActions.CHANGE_DATE_FILTER,
			data: type,
		});
	};
	return (
		<Button variant={isSelected ? "primary" : "default"} onClick={reloadDashboardData}>
			{t(`modules.dashboard.field.${type}.title`)}
		</Button>
	);
};
export default FilterItem;

// t(`modules.dashboard.field.today.title`);
// t(`modules.dashboard.field.yesterday.title`);
// t(`modules.dashboard.field.this_month.title`);
// t(`modules.dashboard.field.last_3_months.title`);
// t(`modules.dashboard.field.last_6_months.title`);
// t(`modules.dashboard.field.last_12_months.title`);
