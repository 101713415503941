import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { apiSecurity } from "../../services/Api/api";
import { AcceptOrganizationTermsSecurityApi } from "../../services/Api/typesSecurity";
import { useAuth } from "../../services/auth";
import { selectOrganization } from "../../services/organizations/selectors";
import { OrganizationActionTypes } from "../../services/organizations/types";

interface Props {
	baseUrl: string;
}

const SecurityOrganizationAcceptTerms = ({ baseUrl }: Props): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const { addFlash } = useFlash();
	const auth = useAuth(baseUrl);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const dispatch = useDispatch();
	const form = useForm<AcceptOrganizationTermsSecurityApi>({
		criteriaMode: "all",
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = form;
	const history = useHistory();

	const onSubmit = handleSubmit(async (data: AcceptOrganizationTermsSecurityApi) => {
		setLoading(true);
		try {
			await apiSecurity.organization().acceptTerms(data);
			await auth.checkLogin();
			const org = await apiSecurity.organization().getOrganizationMe(Number(organization?.id));
			dispatch({ type: OrganizationActionTypes.SET, payload: org.data.data });
			history.push(`/${org.data.data.id}`);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const renderButtons = () => {
		return (
			<>
				<ButtonLoading loading={loading} variant="primary" type="submit">
					{t("lib:common.action.accept")}
				</ButtonLoading>
			</>
		);
	};
	return (
		<div className="container-fluid mt-2">
			<div className="col-6">
				<h5>{t("lib:go_security.actions.accept_consent")}</h5>
				<FormDirty
					buttonSubmitCustom={renderButtons()}
					isDirty={false}
					errors={errors}
					loading={loading}
					noValidate
					onSubmit={onSubmit}
				>
					<FormCheck
						name={"accept_terms"}
						register={register}
						errors={errors}
						label={
							<>
								{t("lib:go_security.register.words.accept")}
								<a href="https://www.gopos.pl/tos">{t("lib:go_security.register.words.tos")}</a>,{" "}
								<a href="https://www.gopos.pl/privacy-policy">
									{t("lib:go_security.register.words.privacy_policy")}
								</a>{" "}
								{t("lib:go_security.register.words.and_accept_receive_informations_from_gopos")}
							</>
						}
					/>
					<FormCheck
						name="support_access"
						errors={errors}
						register={register}
						defaultChecked
						label={t("lib:go_security.register.words.support_access")}
					/>
				</FormDirty>
				{/*<a target="_blank" href={`${ParametersSecurity.getAccountsUrl()}/profile/remove_account`}>*/}
				{/*    {t("lib:go_security.actions.remove_account")}*/}
				{/*</a>*/}
			</div>
		</div>
	);
};

export default SecurityOrganizationAcceptTerms;
