import { TFunction } from "i18next";
import { FormatDurationSeconds } from "go-core/components/Formatters/FormatDate";
import { ListConfigFilter } from "go-list/core/components/Filter/services/types";
import { ReportConfigColumn } from "go-report/core/services/types";
import { api } from "../../services/Api/api";

export class ReportKdsUtils {
	static getSelectedColumns(): string[] {
		return [
			"average_new_time",
			"average_in_progress_time",
			"average_packing_time",
			"average_delivery_time",
			"average_total_time",
		];
	}

	static getColumns(t: TFunction): ReportConfigColumn[] {
		return [
			{
				id: "average_new_time",
				name: t("modules.preparation_status_report.field.average_new_time.title"),
				renderView: (item: number) => {
					return FormatDurationSeconds(item, t, true, true);
				},
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
			{
				id: "average_in_progress_time",
				name: t("modules.preparation_status_report.field.average_in_progress_time.title"),
				renderView: (item: number) => {
					return FormatDurationSeconds(item, t, true, true);
				},
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
			{
				id: "average_packing_time",
				name: t("modules.preparation_status_report.field.average_packing_time.title"),
				renderView: (item: number) => {
					return FormatDurationSeconds(item, t, true, true);
				},
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
			{
				id: "average_delivery_time",
				name: t("modules.preparation_status_report.field.average_delivery_time.title"),
				renderView: (item: number) => {
					return FormatDurationSeconds(item, t, true, true);
				},
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
			{
				id: "average_total_time",
				name: t("modules.preparation_status_report.field.average_total_time.title"),
				renderView: (item: number) => {
					return FormatDurationSeconds(item, t, true, true);
				},
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
		];
	}

	static getGroups(t: TFunction): Array<Record<string, any>> {
		return [
			{
				id: "EMPLOYEE",
				name: t("components.reports.groups.EMPLOYEE"),
			},
		];
	}

	static getFilters(t: TFunction): ListConfigFilter[] {
		return [
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "average_new_time",
				name: t("modules.kds_order_report.field.average_new_time.title"),
				type: "number",
			},
			{
				id: "average_in_progress_time",
				name: t("modules.kds_order_report.field.average_in_progress_time.title"),
				type: "number",
			},
			{
				id: "average_packing_time",
				name: t("modules.kds_order_report.field.average_packing_time.title"),
				type: "number",
			},
			{
				id: "date_range",
				name: t("modules.item_group_report.field.date_range.title"),
				type: "date",
				typeOptions: ["bt"],
				isRemovable: false,
			},
		];
	}
}
