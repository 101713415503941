import { useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import { VenueSettingsApi } from "../../../../../../../../../../../services/Api/Organization/types";
import SettingsTable from "./SettingsTable";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	title: string;
	types: Record<string, any>[];
	search: string;
}

export const DriversCard = ({ form, title, types, search }: Props) => {
	const [collapseIn, setCollapseIn] = useState(false);
	const toggleCollapse = () => setCollapseIn((prevCollapse) => !prevCollapse);

	useEffect(() => {
		if (search.length > 0 && !collapseIn && types.length > 0) setCollapseIn(true);

		if (types.length === 0 && collapseIn) setCollapseIn(false);
	}, [search.length, collapseIn, types]);

	return (
		<Card className="venue-settings">
			<Card.Body>
				<div className="title" onClick={toggleCollapse}>
					<h5>{title}</h5>
					<CollapseSVG
						className={"collapse-icon"}
						style={{ transform: collapseIn ? "rotate(180deg)" : "" }}
					/>
				</div>
				<Collapse in={collapseIn}>
					<div>
						<table className={"table table-preview"}>
							<SettingsTable types={types} form={form} />
						</table>
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};
