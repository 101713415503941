import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationMenuPriceListsGroupEditing from "./pages/GroupEditing";
import OrganizationMenuPriceListsIndexPage from "./pages/Index";
import OrganizationMenuPriceListsPredicatesPage from "./pages/Predicates";

const OrganizationMenuPriceListsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="page-container">
			<Switch>
				<Route
					exact
					path={`${props.match.url}/predicates`}
					component={OrganizationMenuPriceListsPredicatesPage}
				/>
				<Route
					exact
					path={`${props.match.url}/group_editing`}
					component={OrganizationMenuPriceListsGroupEditing}
				/>
				<Route path={`${props.match.url}/`} component={OrganizationMenuPriceListsIndexPage} />
			</Switch>
		</div>
	);
};
export default OrganizationMenuPriceListsPage;
