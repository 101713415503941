export const reportTypes = [
	"product_reports",
	"category_reports",
	"payment_reports",
	"sales_reports",
	"sales_advanced_reports",
] as const;
export type ReportType = (typeof reportTypes)[number];

export type DashboardCardConfig = {
	title: string | JSX.Element;
	link: string;
	type: ReportType;
	chartDataRangeType: ChartDataRangeType;
	chartType: ChartType;
};

export const salesAggregateOptions = [
	"total_money",
	"transaction_count",
	"average_money",
	"extended.average_per_guest_money",
	"net_profit_money",
] as const;

export const orderItem = [
	"discount_count",
	"discount_money",
	"net_production_money",
	"net_profit_money",
	"net_total_money",
	"product_quantity",
	"sub_total_money",
	"tax_money",
	"total_money",
	"transaction_count",
	"average_money",
] as const;

export type AggregateOption = (typeof salesAggregateOptions)[number] | (typeof orderItem)[number];

export type ChartConfig = {
	type: ChartType;
	range: ChartDataRangeType;
	aggregate: AggregateOption;
};

export type DashboardChartConfig = {
	reportCategory: ChartConfig;
	reportPayment: ChartConfig;
	reportProduct: ChartConfig;
	reportSales: ChartConfig;
	reportSalesAdvanced: ChartConfig;
};

export enum ChartType {
	LINE = "LINE",
	VERTICAL_BAR = "VERTICAL_BAR",
	HORIZONTAL_BAR = "HORIZONTAL_BAR",
	PROGRESS_BAR = "PROGRESS_BAR",
	BOX = "BOX",
}

export const chartDataRangeOptions = ["HOUR_OF_DAY", "DAY_OF_WEEK", "DAY_OF_MONTH", "DATE"] as const;
export type ChartDataRangeType = (typeof chartDataRangeOptions)[number];
