import * as H from "history";
import { FILTER_VALUE_SEPARATOR } from "../../core/components/Filter/services";
import { listDataParamsDefault } from "./decoder";
import { isSegmentChanged } from "./segment-service";
import { selectActiveSegment } from "./selectors";
import { FetchDefaultParameters, GoListSegmentType, ListConfig, ListParamsType } from "./types";

const listDataParamsQuery = (params: ListParamsType, config: ListConfig) => {
	const paramsObj = new URLSearchParams(listDataParamsDefault(params));
	const activeSegment = selectActiveSegment(params) as GoListSegmentType;
	if (isSegmentChanged(params, activeSegment, config)) return `?${paramsObj.toString()}`;
	const slug = paramsObj
		.toString()
		.slice(
			0,
			paramsObj.toString().indexOf("&") !== -1 ? paramsObj.toString().indexOf("&") : paramsObj.toString().length
		);

	let urlParams = `?${slug}`;

	if (params.search !== "") {
		urlParams += `&search=${params.search}`;
	}

	if (params.size && params.size !== FetchDefaultParameters.SIZE) {
		urlParams += `&size=${params.size}`;
	}

	if (params.page && params.page !== FetchDefaultParameters.PAGE) {
		urlParams += `&page=${params.page}`;
	}

	urlParams += `&f=${paramsObj.get("f") || ""}`;

	return urlParams;
};
export const listDataPushHistorySearch = (history: H.History, params: ListParamsType, config: ListConfig): void => {
	if (history !== undefined && filtersStateChanged(params, config)) {
		history.push({ search: listDataParamsQuery(params, config) });
	}
};

const filtersStateChanged = (params: ListParamsType, config: ListConfig) => {
	const initFiltersChanged =
		params.filters?.map((x) => x.value).join(FILTER_VALUE_SEPARATOR) !==
		config.externalSelectedFilters?.map((x) => x.value).join(FILTER_VALUE_SEPARATOR);
	if (
		initFiltersChanged ||
		params.selectedSegment !== config.selectedSegment ||
		params.columns !== config.selectedColumns
	) {
		return true;
	}
	return false;
};
