import { ListConfigField } from "../../core/services/types";
import { ListConfig } from "./types";

const disableStickyColumnsOptions = (config: ListConfig) => {
	config.shouldDisableSortingOfStickyColumns = false;
	config.isNumberOfStickyColumnsDynamic = false;
	config.numberOfStickyColumnsAtTheStart = 0;
};

const sortSelectedColumns = (selectedColumns: string[], configFields: ListConfigField[]) => {
	selectedColumns.sort((firstColumnId, secondColumnId) => {
		const indexOfFirstColumns = configFields.findIndex((field) => field.id === firstColumnId);
		const indexOfSecondColumns = configFields.findIndex((field) => field.id === secondColumnId);

		return indexOfFirstColumns > indexOfSecondColumns ? 1 : -1;
	});
};

const fixSelectedColumns = (config: ListConfig) => {
	if (config.selectedColumns && config.fields) {
		sortSelectedColumns(config.selectedColumns, config.fields);
	}
};

const fixStickyColumnsOptions = (config: ListConfig) => {
	const areStickyColumnsOptionInvalid =
		(config.shouldDisableSortingOfStickyColumns && config.isNumberOfStickyColumnsDynamic) ||
		(config.isNumberOfStickyColumnsDynamic && !config.numberOfStickyColumnsAtTheStart);

	if (areStickyColumnsOptionInvalid) {
		disableStickyColumnsOptions(config);
	}
};

export const getFixedConfig = (config: ListConfig): ListConfig => {
	const fixedConfig = { ...config };

	fixSelectedColumns(fixedConfig);
	fixStickyColumnsOptions(fixedConfig);

	return fixedConfig;
};
