import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationMenuPriceListsEditPage from "./pages/Edit";
import OrganizationMenuPriceListsIndexIndexPage from "./pages/Index/Index";
import OrganizationMenuPriceListsNewPage from "./pages/New/Index";

const OrganizationMenuPriceListsIndexPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"content"}>
			<Switch>
				<Route exact path={`${props.match.url}/new`} component={OrganizationMenuPriceListsNewPage} />
				<Route path={`${props.match.url}/:priceListId`} component={OrganizationMenuPriceListsEditPage} />
				<Route path={`${props.match.url}/`} component={OrganizationMenuPriceListsIndexIndexPage} />
			</Switch>
		</div>
	);
};
export default OrganizationMenuPriceListsIndexPage;
