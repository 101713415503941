import React, { MutableRefObject, Suspense, useContext, useEffect, useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { TFunction, useTranslation } from "react-i18next";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext, SegmentListService } from "go-segment/context";
import { ClientDetailsForm } from "../../components/ClientDetailsForm";
import { ClientDetailsApi, ClientDetailsImportContext } from "../../services/types";

interface ListProps {
	resource: any;
	requests: ClientDetailsImportContext;
}

interface ClientDetailsProps {
	requests: ClientDetailsImportContext;
}

const listName = "CLIENT_DETAILS";
const resourceType = "CLIENT_DETAILS";

const buildConfig = (
	t: TFunction<"translation", undefined>,
	data: any,
	segmentListService: SegmentListService,
	deleteClientDetailHandler: (clientDetailId: string) => void,
	updateClientDetailHandler: (data: ClientDetailsApi) => void,
	getClientDetails: (params?: Record<string, any>, options?: Record<string, any>) => Promise<ClientDetailsApi[]>,
	listParams: MutableRefObject<Record<string, any>>
) => {
	let config = {
		fields: [
			{
				id: "client_id",
				name: t("lib:go_component.client_details.field.client_id.title"),
			},
			{
				id: "client_secret",
				name: t("lib:go_component.client_details.field.client_secret.title"),
			},
			{
				id: "scopes",
				name: "Scopes",
				render: (item: ClientDetailsApi) => (
					<RenderLimitedText minWidth={200}>{item.scopes.join(", ")}</RenderLimitedText>
				),
				renderExport: (item: ClientDetailsApi) => item.scopes.join(", "),
			},
			{
				id: "additional_information",
				name: t("lib:go_component.client_details.field.additional_information.title"),
				render: (item: ClientDetailsApi) =>
					item.additional_information.map((additionalInformation) => {
						return (
							<React.Fragment key={additionalInformation.name}>
								<span>{`${additionalInformation.name}: ${additionalInformation.value}`}</span>
								<br />
							</React.Fragment>
						);
					}),
				renderExport: (item: ClientDetailsApi) =>
					item.additional_information
						.map((additionalInformation) => {
							return `${additionalInformation.name}: ${additionalInformation.value}`;
						})
						.join(", "),
			},
			{
				id: "authorized_grant_types",
				name: "Scopes",
				render: (item: ClientDetailsApi) => (
					<RenderLimitedText minWidth={200}>{item.authorized_grant_types.join(", ")}</RenderLimitedText>
				),
				renderExport: (item: ClientDetailsApi) => item.authorized_grant_types.join(", "),
			},
		],
		actions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: ClientDetailsApi) => deleteClientDetailHandler(item.client_id),
			},
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: ClientDetailsApi) => updateClientDetailHandler(item),
			},
		],
		selectedColumns: ["client_id", "client_secret", "scopes", "additional_information", "authorized_grant_types"],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
		],
		filters: [],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("lib:go_component.client_details.field.export_config.title"),
			filename: t("lib:go_component.client_details.header.title"),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			listParams.current = params;
			return getClientDetails(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentListService.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return config;
};

const List = ({ resource, requests }: ListProps) => {
	const [responseData, setResponseData] = useState<ClientDetailsApi[]>([]);
	const [openModal, setOpenModal] = useState<ClientDetailsApi | undefined>(undefined);
	const segmentContextValue = useContext(SegmentContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const listParams = useRef<Record<string, any>>({});
	const { getClientDetails, deleteClientDetail } = requests;
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	if (!resource) return null;

	const updateClientDetailHandler = (data: ClientDetailsApi) => setOpenModal(data);

	const createClientDetailHandler = () => setOpenModal({} as ClientDetailsApi);

	const deleteClientDetailHandler = async (clientDetailId: string) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await deleteClientDetail(clientDetailId);
			onRefresh();
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const data = resource.read();
	const config = buildConfig(
		t,
		data,
		segmentContextValue,
		deleteClientDetailHandler,
		updateClientDetailHandler,
		getClientDetails,
		listParams
	);

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(listParams.current);
			setResponseData(res);
		}
	};

	const onModalHide = () => {
		setOpenModal(undefined);
		onRefresh();
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: createClientDetailHandler,
			variant: "primary",
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: createClientDetailHandler,
		},
	];

	return (
		<>
			{openModal && <ClientDetailsForm requests={requests} data={openModal} onHide={onModalHide} />}
			{!isMobile && <Header title={t("lib:go_component.client_details.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<ListData data={responseData} config={config} onFetch={setResponseData} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};

export const ClientDetailsList = ({ requests }: ClientDetailsProps): JSX.Element => {
	const [resource, setResource] = useState<any>();
	const segmentContextValue = useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_component.client_details.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<List resource={resource} requests={requests} />
		</Suspense>
	);
};
