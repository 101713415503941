import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { MoneyApi } from "go-core/api/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	DeliveryEstimationApi,
	LiveOrderApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { LiveOrderDeliveryOptionType } from "../../../../../../services/types";
import { isWoltDriveEnabled } from "../../../../../../services/utils";
import LiveOrderDeliveryOption from "./LiveOrderDeliveryOption";

interface Props {
	setSelectedDeliveryOption: (option: LiveOrderDeliveryOptionType | undefined) => void;
	selectedDeliveryOption: LiveOrderDeliveryOptionType | undefined;
	selectedTimeOption: number | undefined | string;
	orderId: number | string;
	setEstimatedDeliveryData: (data: DeliveryEstimationApi | undefined) => void;
	setFirstEstimatedDeliveryTime?: (time: number | undefined) => void;
	firstEstimatedDeliveryTime?: number;
	setFirstEstimatedPrice?: (price: MoneyApi | undefined) => void;
	firstEstimatedPrice?: MoneyApi;
	estimatedDeliveryData: DeliveryEstimationApi | undefined;
	setEstimatingLoading: (value: boolean) => void;
	estimatingLoading: boolean;
	handleToggle?: (option?: LiveOrderDeliveryOptionType) => void;
	setSelectedTimeOption?: (option: number | undefined | string) => void;
	order?: LiveOrderApi;
	orderEstimatedPreparationInMinutes?: number;
}

const LiveOrderDeliveryOptions = ({
	setSelectedDeliveryOption,
	selectedDeliveryOption,
	selectedTimeOption,
	orderId,
	setEstimatedDeliveryData,
	estimatedDeliveryData,
	firstEstimatedDeliveryTime,
	setEstimatingLoading,
	estimatingLoading,
	handleToggle,
	setFirstEstimatedDeliveryTime,
	setFirstEstimatedPrice,
	firstEstimatedPrice,
	setSelectedTimeOption,
	order,
	orderEstimatedPreparationInMinutes,
}: Props) => {
	const organization = useSelector(selectOrganization);
	const woltDriveEnabled = isWoltDriveEnabled(organization);
	const [isOutOfDeliveryZone, setIsOutOfDeliveryZone] = useState(false);
	const { addFlash } = useFlash();
	const isWoltDriveAssigned = order?.delivery?.delivery_employee?.name === "WOLT_DRIVE";

	const getDeliveryOptions = () => {
		const options = [LiveOrderDeliveryOptionType.OWN_DRIVER];
		if (woltDriveEnabled) options.push(LiveOrderDeliveryOptionType.WOLT_DRIVE);

		return options;
	};

	const handleEstimateDelivery = useCallback(async () => {
		setEstimatingLoading(true);
		try {
			const minutes = typeof selectedTimeOption === "number" ? selectedTimeOption : 0;
			const res = await apiOrganization.estimateOrderDelivery(orderId, "WOLT_DRIVE", minutes);
			if (
				(!selectedDeliveryOption || selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE) &&
				setSelectedTimeOption &&
				res?.delivery_pickup_in_minutes
			) {
				setSelectedTimeOption(res.delivery_pickup_in_minutes);
			}
			setEstimatedDeliveryData(res);
			setEstimatingLoading(false);
			return res;
		} catch (err) {
			const errors = handleException(err);
			if (
				errors[0].code === "dropoff_outside_of_delivery_area" ||
				errors[0].code === "order_has_no_coordinates"
			) {
				setIsOutOfDeliveryZone(true);
			} else {
				handleError.alert(err, addFlash);
			}
			setEstimatingLoading(false);
		}
	}, [addFlash, orderId, selectedTimeOption]);

	const handleInit = async () => {
		const res = await handleEstimateDelivery();
		setFirstEstimatedDeliveryTime && setFirstEstimatedDeliveryTime(res?.delivery_pickup_in_minutes);
		setFirstEstimatedPrice && setFirstEstimatedPrice(res?.price);
	};

	useEffect(() => {
		if (woltDriveEnabled && !isWoltDriveAssigned) handleInit();
		if (isWoltDriveAssigned && setSelectedTimeOption) {
			setSelectedTimeOption(orderEstimatedPreparationInMinutes);
			if (setFirstEstimatedDeliveryTime) {
				setFirstEstimatedDeliveryTime(orderEstimatedPreparationInMinutes);
			}
		}
		if (isWoltDriveAssigned && setFirstEstimatedPrice) {
			if (setFirstEstimatedPrice) {
				setFirstEstimatedPrice(order?.delivery_price);
			}
		}
	}, []);

	useEffect(() => {
		if (
			selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE &&
			selectedTimeOption &&
			selectedTimeOption !== orderEstimatedPreparationInMinutes
		) {
			handleEstimateDelivery();
		}
	}, [selectedDeliveryOption, handleEstimateDelivery, selectedTimeOption]);

	if (!woltDriveEnabled) return <></>;

	return (
		<>
			<div className="d-flex flex-wrap gap-3 live-order-delivery-options">
				{getDeliveryOptions().map((deliveryOption) => (
					<LiveOrderDeliveryOption
						option={deliveryOption}
						key={deliveryOption}
						setSelectedDeliveryOption={setSelectedDeliveryOption}
						selectedDeliveryOption={selectedDeliveryOption}
						estimatingLoading={
							deliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE ? estimatingLoading : undefined
						}
						estimatedDeliveryData={
							deliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE
								? estimatedDeliveryData
								: undefined
						}
						firstEstimatedDeliveryTime={firstEstimatedDeliveryTime}
						firstEstimatedPrice={firstEstimatedPrice}
						disabled={deliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE && isOutOfDeliveryZone}
						handleToggle={handleToggle}
						orderEstimatedPreparationInMinutes={orderEstimatedPreparationInMinutes}
						deliveryMoney={order?.delivery_cost}
						selectedTimeOption={selectedTimeOption}
					/>
				))}
			</div>
			{selectedDeliveryOption && <hr />}
		</>
	);
};

export default LiveOrderDeliveryOptions;
