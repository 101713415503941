import React, { FC, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as PreviewArrowSVG } from "../../../../../../../../../../images/svg/preview-arrow.svg";
import { PosReportApi } from "../../../../../../../../../../services/Api/Organization/types";
import LastTransactionsTable from "../../../../../../components/Transaction/LastTransactionsTable";

interface Props {
	report: PosReportApi;
}

const TransactionsTabPreview: FC<Props> = ({ report }) => {
	const { t } = useTranslation();
	const [showTransaction, setShowTransaction] = useState<null | number>(null);
	const organization = useSelector(selectOrganization);
	// TODO dodać scroll
	return (
		<Card>
			<Card.Body>
				<LastTransactionsTable
					showTransaction={showTransaction}
					setShowTransaction={setShowTransaction}
					transactions={report.transactions}
					rowActions={(transaction) => (
						<Button variant={"btn btn-light"} onClick={() => setShowTransaction(transaction.id)}>
							<PreviewArrowSVG />
						</Button>
					)}
				/>
				{report.transactions.length > 2 && (
					<Link
						to={`/${organization.id}/sales/transactions?f=${btoa(
							`${NEW_WAY_TO_ENCODING_FILTER_SIGN}report|e=${report.id}`
						)}`}
						className={"btn btn-add btn-block mb-0"}
					>
						{t("common.word.see_more")}
					</Link>
				)}
			</Card.Body>
		</Card>
	);
};
export default TransactionsTabPreview;
