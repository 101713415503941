import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { defaultDeliveryTimeOptions } from "../../../../../../services/types";
import LiveOrderDeliveryTimeOption from "../LiveOrderDeliveryTimeOptions/LiveOrderDeliveryTimeOption";

interface Props {
	selectedTimeOption: number | string | undefined;
	setSelectedTimeOption: (option: number | string | undefined) => void;
	form: UseFormReturn<{ pickup_at?: string; estimated_preparation_at?: string }>;
	mode: "PICK_UP_DATE" | "PREPARATION_TIME";
}

const LiveOrderChangeDateOptionsModal = ({ selectedTimeOption, setSelectedTimeOption, form, mode }: Props) => {
	const { t } = useTranslation();
	const {
		control,
		formState: { errors },
		setValue,
	} = form;

	const handleSelect = () => {
		if (mode === "PICK_UP_DATE") {
			setValue("pickup_at", "");
		} else setValue("estimated_preparation_at", "");
	};

	return (
		<div className="live-order-delivery-time-options">
			<h5 className="mb-2">
				{mode === "PICK_UP_DATE"
					? t("modules.live_order.field.delay_order.title")
					: t("modules.live_order.field.delay_preparation_time.title")}
			</h5>
			<div className="d-flex flex-wrap gap-2 align-items-center live-order-delivery-time-options-container">
				{defaultDeliveryTimeOptions.map((option) => (
					<LiveOrderDeliveryTimeOption
						key={`${option}`}
						option={option}
						selectedTimeOption={selectedTimeOption}
						setSelectedTimeOption={setSelectedTimeOption}
						handleSelect={handleSelect}
					/>
				))}
				<FormDatePicker
					control={control}
					name={mode === "PICK_UP_DATE" ? "pickup_at" : "estimated_preparation_at"}
					errors={errors}
					allowResetDate
					onApply={(date) => {
						setSelectedTimeOption(date as string | undefined);
					}}
					placeholder={t("modules.live_order.field.execution_at.placeholder")}
				/>
			</div>
		</div>
	);
};

export default LiveOrderChangeDateOptionsModal;
