import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import { mapCustomFieldsToForm } from "go-app/components/CustomFields";
import CustomFieldsForm from "go-app/components/CustomFields/CustomFieldsForm";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { SaveAndAddButtons, TranslationType } from "go-core/components/SaveAndAddButtons";
import { FormDirty } from "go-form/components/FormDirty";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { SaveAndAddModal } from "../../../../../../../../../components/Common/SaveAndAddItem";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import { EmployeeApi, SaveAndAddActionType } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import EmployeeWorkplacesForm from "./EmployeeWorkplacesForm";

interface Props {
	employee?: EmployeeApi;
	customFieldsConfig: CustomFieldTemplateApi[];
}

const formEmptyValues = {
	name: "",
	tax_id_no: "",
	card_code: "",
	card_number: "",
	address_street: "",
	address_build_nr: "",
	address_flat_nr: "",
	address_zip_code: "",
	address_city: "",
	address_country: "",
	contact_email: "",
	contact_other: "",
	nin_no: "",
	id_card_number: "",
	id_card_issuing_authority: "",
	avatar: undefined,
	avatar_link: undefined,
	birthday: undefined,
	card_expires_at: undefined,
	client_group_id: undefined,
	color: undefined,
	contact_phone_number: undefined,
	id_card_issued_at: undefined,
	label: undefined,
	custom_fields: undefined,
};

const EmployeeForm = (props: Props): JSX.Element => {
	const [newAvatar, setNewAvatar] = useState<string | undefined>(props.employee?.avatar_link?.default_link);
	const [showImage, setShowImage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [saveAndAddLoading, setSaveAndAddLoading] = useState(false);
	const [saveAndAddModalActive, setSaveAndAddModalActive] = useState<EmployeeApi | undefined>(undefined);
	const saveAndAddMode = useRef(false);
	const form = useForm<EmployeeApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.employee,
			custom_fields: mapCustomFieldsToForm(props.customFieldsConfig, props.employee?.custom_fields || []),
		},
	});
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const hasVenueEmployeeHourlyRateAccess = useSelector(hasPermission("VENUE_EMPLOYEE_HOURLY_RATE_ACCESS"));
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		control,
		formState,
		getValues,
		setValue,
		watch,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const currency = organization.currency || "PLN";
	const isEdit = location.pathname.includes("/edit");
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		if (props.employee) {
			handleChangeTabTitle(`${props.employee?.name || ""} | ${t("modules.employee.header.title")}`);
		}
	}, [props.employee?.name]);

	const onSubmit = handleSubmit(async (data: EmployeeApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		saveAndAddMode.current ? setSaveAndAddLoading(true) : setLoading(true);

		if (props.employee?.id) data.id = props.employee.id;

		if (getValues("avatar.image_data") || !newAvatar) {
			data.avatar_link = undefined;
		}
		if (!getValues("color")) {
			data.color = undefined;
		}
		try {
			if (data.id && isEdit) {
				const params: Record<string, any> = {};
				params.include = "role,role.permissions,workplace,workplaces,workplaces.workplace,custom_fields";
				const res = await api.organization().updateEmployee(data, params);
				if (props.employee?.avatar_link) props.employee.avatar_link = res.avatar_link;
				if (saveAndAddMode.current) {
					history.push(`/${organization.id}/employees/new`);
				}
				reset({
					...res,
					code: res.code ? res.code : "",
					contact_phone_number: res?.contact_phone_number ? res.contact_phone_number : "",
					custom_fields: mapCustomFieldsToForm(props.customFieldsConfig, res.custom_fields),
				});
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				handleChangeTabTitle(`${res.name || ""} | ${t("modules.employee.header.title")}`);
			} else {
				const res = await api.organization().createEmployee(data);
				if (saveAndAddMode.current) {
					setSaveAndAddModalActive(res);
				} else history.push(`/${organization.id}/employees/${res.id}/edit`);

				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setLoading(false);
			setSaveAndAddLoading(false);
			saveAndAddMode.current = false;
		}
	});

	const handleSave = (imageBase: string | undefined) => {
		setNewAvatar(imageBase);
	};

	const searchRoles = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getRolesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchWorkplaces = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getWorkplacesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onHideSaveAndAddModal = () => {
		saveAndAddMode.current = false;
		setSaveAndAddLoading(false);
		setSaveAndAddModalActive(undefined);
	};

	const saveAndAddItemHandler = () => {
		saveAndAddMode.current = true;
		onSubmit();
	};

	const onSaveAndAdd = async (action: SaveAndAddActionType, itemId?: string) => {
		if (action === SaveAndAddActionType.ADD) {
			reset(formEmptyValues);
			setValue("hourly_rate.amount", null);
		} else {
			if (!itemId) return;
			try {
				const params: Record<string, any> = {};
				params.include = "role,workplace,workplaces,custom_fields,hourly_rate,avatar";
				const res = await apiOrganization.getEmployee(Number(itemId), params);
				reset({ ...formEmptyValues, avatar_link: res.avatar_link });
				reset({
					...props.employee,
					...res,
					name: `${t("common.word.copy")} ${res.name}`,
					custom_fields: mapCustomFieldsToForm(props.customFieldsConfig, res.custom_fields),
					code: "",
					card_code: "",
					contact_email: "",
					contact_phone_number: "",
					address_street: "",
					address_build_nr: "",
					address_flat_nr: "",
					address_zip_code: "",
					address_city: "",
					address_country: "",
					contact_other: "",
				});
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
		setSaveAndAddLoading(false);
		setSaveAndAddModalActive(undefined);
	};

	return (
		<FormDirty
			formState={formState}
			key="employee-form"
			loading={loading}
			noValidate
			onSubmit={onSubmit}
			buttonSubmitCustom={
				<SaveAndAddButtons
					saveLoading={loading}
					saveAndAddLoading={saveAndAddLoading}
					onSaveAndAddClick={saveAndAddItemHandler}
					translationType={TranslationType.MALE}
				/>
			}
		>
			<fieldset className="form-group">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<div className="row">
					<div className="col-md-10">
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.word.name", { ns: "lib" })}
									register={register}
									name="name"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormSelectGroup
									label={t("modules.employee.field.role.title")}
									name="venue_role_id"
									placeholder={t("modules.employee.action.role_placeholder.title")}
									errors={errors}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.id}
									defaultValue={{
										id: props.employee?.venue_role_id || watch("venue_role_id"),
										label: props.employee?.venue_role?.name || watch("venue_role.name"),
									}}
									loadOptions={searchRoles}
									control={control}
									data-testid="venue_role_id"
								/>
							</div>
							<div className={"col-md-6"}>
								<FormSelectGroup
									label={t("modules.employee.field.workplace.title")}
									name="workplace_id"
									placeholder={t("modules.employee.field.workplace_placeholder.title")}
									errors={errors}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.id}
									defaultValue={{
										label: props.employee?.workplace?.name || watch("workplace.name"),
										id: props.employee?.workplace?.id || watch("workplace.id"),
									}}
									loadOptions={searchWorkplaces}
									control={control}
									data-testid="workplace_id"
								/>
							</div>
							<div className={"col-md-6"}>
								<FormMoneyInput
									label={t("modules.employee.field.hourly_rate_net.title")}
									control={control}
									currency={currency}
									name="hourly_rate.amount"
									errors={errors}
								/>
							</div>
						</div>
					</div>
					<div className={"col-md-2"} style={{ minWidth: "150px" }}>
						<ImageColorForm
							form={form}
							handleOpen={() => setShowImage(true)}
							handleSave={handleSave}
							resourceLink={props.employee?.avatar_link || watch("avatar_link")}
							resourceData={props.employee?.avatar || newAvatar}
							mode="IMAGE_COLOR"
							displayColor={true}
							prefix="avatar"
							show={showImage}
							data={Object.keys(props.employee || {}).length > 0 ? props.employee : watch()}
							setTextDisabled={true}
							placeholder="/graphics/user-avatar.svg"
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group">
				<legend>
					<h5>{t("lib:common.word.informations")}</h5>
				</legend>
				<div className={"row"}>
					<div className="col-md-6">
						<FormNumberInput
							name="code"
							errors={errors}
							control={control}
							format={"######"}
							suffix={""}
							label={t("modules.employee.field.code.title")}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("modules.employee.field.card_code.title")}
							register={register}
							name="card_code"
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group">
				<legend>
					<h5>
						{t("modules.employee.field.contact.title")}
						<small className="text-muted">
							<br />
							{t("modules.employee.field.contact.help_text.description")}
						</small>
					</h5>
				</legend>
				<div className="row">
					<div className="col-md-6">
						<FormNumberInput
							label={t("common.contact.phone.label")}
							control={control}
							suffix=""
							name="contact_phone_number"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.word.email", { ns: "lib" })}
							register={register}
							name="contact_email"
							errors={errors}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<FormInput
							label={t("common.address.city", { ns: "lib" })}
							register={register}
							name="address_city"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.address.zip_code", { ns: "lib" })}
							register={register}
							name="address_zip_code"
							errors={errors}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<FormInput
							label={t("common.address.street", { ns: "lib" })}
							register={register}
							name="address_street"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.address.build_nr", { ns: "lib" })}
									register={register}
									name="address_build_nr"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("common.address.flat_nr", { ns: "lib" })}
									register={register}
									name="address_flat_nr"
									errors={errors}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.address.country", { ns: "lib" })}
									register={register}
									name="address_country"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("common.contact.others.label")}
									register={register}
									name="contact_other"
									errors={errors}
								/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			{props.customFieldsConfig.length > 0 && (
				<fieldset className="form-group no-gutters">
					<h5>{t("lib:common.word.advanced")}</h5>
					<CustomFieldsForm
						form={form}
						customFieldsConfig={props.customFieldsConfig}
						customFields={props.employee?.custom_fields || []}
						setErrors={setErrors}
					/>
				</fieldset>
			)}
			{hasVenueEmployeeHourlyRateAccess && (
				<fieldset className={"form-group"}>
					<LimitComponentVisibility
						defaultVisible={
							typeof props.employee?.workplaces?.length === "number" &&
							props.employee?.workplaces?.length > 0
						}
						id={"emp_workplaces"}
						handleChange={(val) => val === false && setValue("workplaces", [], { shouldDirty: true })}
						legendText={t("modules.employee.field.workplaces_legend.title")}
						helpText={t("modules.employee.field.workplaces_legend.help_text.description")}
						unlimitedContent={<EmployeeWorkplacesForm form={form} />}
					/>
				</fieldset>
			)}
			{saveAndAddModalActive && (
				<SaveAndAddModal
					onHide={onHideSaveAndAddModal}
					onSaveAndAdd={onSaveAndAdd}
					onFetchItems={api.organization().getEmployeesSearchSelect}
					itemValue={saveAndAddModalActive}
					translations={{
						copyItemLabel: t("modules.employee.field.copy_employee.title"),
						copyItemDescription: t("modules.employee.field.copy_employee.description.title"),
						newItemLabel: t("modules.employee.field.new_employee.title"),
						newItemDescription: t("modules.employee.field.new_employee.description.title"),
						chooseItem: t("modules.employee.action.choose_employee.title"),
						copyItemHeader: t("modules.employee.action.create_employee.title"),
					}}
				/>
			)}
		</FormDirty>
	);
};
export default EmployeeForm;
