import React, { FC, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import EmptyList from "go-core/components/EmptyList";
import { FormatDateToDateHoursRange, FormatDuration } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as MoreSvg } from "../../../../../../../../../../images/svg/more.svg";
import {
	EmployeeApi,
	EmployeeInfoApi,
	WorkTimeApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import WorktimeModalForm from "./WorktimeModalForm";

interface Props {
	employee: EmployeeApi;
	handleAddWorkTime: () => void;
	employeeInfo: EmployeeInfoApi;
}

const WorktimesCard: FC<Props> = ({ employee, handleAddWorkTime, employeeInfo }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [addWorktime, setAddWorktime] = useState<WorkTimeApi | undefined>(undefined);
	const confirmation = useConfirmation();
	const [workTimes, setWorkTimes] = useState(employeeInfo.work_times);
	const { addFlash, addSuccessFlash } = useFlash();

	const removeWorkTime = async (worktime: WorkTimeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeWorktime(worktime.id);
			handleAddWorkTime();
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	useEffect(() => {
		setWorkTimes(employeeInfo.work_times);
	}, [employeeInfo.work_times]);

	const payWorkTime = async (worktime: WorkTimeApi) => {
		if (worktime.status === "PAID") {
			const res = await api.organization().workTimeCancelPay(String(worktime.id));
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setWorkTimes([...workTimes.map((w) => (w.id === res.id ? res : w))]);
		} else {
			const res = await api.organization().workTimePay(worktime.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setWorkTimes([...workTimes.map((w) => (w.id === res.id ? res : w))]);
		}
	};

	const drawWorkTimeStatus = (worktime: WorkTimeApi) => {
		switch (worktime.status) {
			case "CONFIRMED":
				return <span className="badge bg-danger">{t("enums.worktimes.status.CONFIRMED")}</span>;
			case "SKIPPED":
				return <span className="badge bg-warning text-dark">{t("enums.worktimes.status.SKIPPED")}</span>;
			case "PAID":
				return <span className="badge bg-success">{t("enums.worktimes.status.PAID")}</span>;
			default:
				return "";
		}
	};

	const drawDropDownItemsToolByWorkTimeStatus = (worktime: WorkTimeApi) => {
		switch (worktime.status) {
			case "PAID":
				return (
					<Dropdown.Item onClick={() => payWorkTime(worktime)}>
						{t("modules.employee.action.cancel_pay.title")}
					</Dropdown.Item>
				);
			case "SKIPPED":
				return <></>;
			case "CONFIRMED":
				return (
					<Dropdown.Item onClick={() => payWorkTime(worktime)}>
						{t("modules.employee.action.pay.title")}
					</Dropdown.Item>
				);
			default:
				return <></>;
		}
	};

	return (
		<Card>
			<Card.Body>
				<div className={"action-header"}>
					<h5> {t("modules.employee.field.worktimes.title")}</h5>
					<Button variant={"add"} onClick={() => setAddWorktime({} as WorkTimeApi)}>
						+ {t("common.action.add", { ns: "lib" })}
					</Button>
				</div>
				{workTimes?.length > 0 ? (
					<div className="table-responsive">
						<table className="table table-preview">
							<thead>
								<tr>
									<th>{t("modules.employee.field.time.title")}</th>
									<th>{t("modules.employee.field.duration.title")}</th>
									<th>{t("modules.employee.field.to_pay.title")}</th>
									<th>{t("common.word.status", { ns: "lib" })}</th>
									<th className="w-1"></th>
								</tr>
							</thead>
							<tbody>
								{workTimes.map((worktime, index) => {
									return (
										<tr key={index}>
											<td>{FormatDateToDateHoursRange(worktime.start_at, worktime.end_at)}</td>
											<td className={"nowrap"}>
												<div className="d-flex flex-column">
													{FormatDuration(worktime.duration_in_minutes, t, true)}
													<small className="text-muted">{worktime.workplace?.name}</small>
												</div>
											</td>
											<td className={"nowrap"}>
												<div className="d-flex flex-column">
													{FormatMoney(worktime.to_pay)}
													<small className="text-muted">
														{FormatMoney(worktime.hourly_rate)}/h
													</small>
												</div>
											</td>
											<td>
												<div className={"d-flex align-items-center"}>
													{drawWorkTimeStatus(worktime)}
												</div>
											</td>
											<td>
												<Dropdown as={ButtonGroup}>
													<Dropdown.Toggle as={MoreSvg}>
														{t("common.action.more", { ns: "lib" })}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={() => setAddWorktime(worktime)}>
															{t("common.action.edit", { ns: "lib" })}
														</Dropdown.Item>
														{drawDropDownItemsToolByWorkTimeStatus(worktime)}
														<Dropdown.Item onClick={() => removeWorkTime(worktime)}>
															{t("common.action.remove", { ns: "lib" })}
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<Link
							to={`/${organization.id}/employees/worktimes?f=${btoa(
								`${NEW_WAY_TO_ENCODING_FILTER_SIGN}employee|e=${employee.id}`
							)}`}
							target={"_blank"}
							rel={"noreferrer"}
							className={"btn btn-add card-action"}
						>
							{t("common.action.show_more", { ns: "lib" })}
						</Link>
					</div>
				) : (
					<>
						<EmptyList
							title={t("modules.employee.field.worktimes.title")}
							description={t("modules.employee.field.worktimes_empty.title")}
						/>
					</>
				)}
			</Card.Body>
			{addWorktime && (
				<WorktimeModalForm
					workplaceIds={[...employee.workplaces.map((x) => x.workplace_id), employee.workplace_id].filter(
						(f) => f !== undefined
					)}
					employee={employee}
					worktime={addWorktime}
					handleClose={() => setAddWorktime(undefined)}
					handleSave={() => {
						setAddWorktime(undefined);
						handleAddWorkTime();
					}}
				/>
			)}
		</Card>
	);
};
export default WorktimesCard;
