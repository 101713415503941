import React, { FC, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { PathOptions } from "leaflet";
import { MapContainer, Polyline, TileLayer, useMap } from "react-leaflet";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as CrosshairsSVG } from "../../../../../../../../../images/svg/liveOrders/crosshairs.svg";
import { ReactComponent as InfoCircleSVG } from "../../../../../../../../../images/svg/liveOrders/info-circle.svg";
import {
	DeliveryEmployeeApi,
	LiveOrderApi,
	OrganizationAddressApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../services/context";
import {
	areDeliveryCoordinates,
	deliveryZonesColors,
	parseDeliveryZonePath,
	polandCoords,
} from "../../../services/orderDelivery";
import { GroupedMarker } from "../../../services/types";
import LiveOrdersMapDeliveryEmployeeMarker from "./DeliveryEmployeeMarker/LiveOrdersMapDeliveryEmployeeMarker";
import LiveOrderOrganizationMarker from "./LiveOrderOrganizationMarker";
import LiveOrdersMapMarker from "./LiveOrdersMapMarker";

interface RecenterAutomaticallyApi {
	lat: number;
	lng: number;
	resetPosition: MutableRefObject<boolean>;
	setMap: (map: any) => void;
}

interface Props {
	focusedOrder: LiveOrderApi | undefined;
	selectedOrder: LiveOrderApi | undefined;
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	setShowMapLegend: (value: boolean) => void;
	showOrderList: boolean;
	isOrderSelected: boolean;
	setMap: (map: any) => void;
	map: any;
}

const RecenterAutomatically = ({ lat, lng, resetPosition, setMap }: RecenterAutomaticallyApi) => {
	const map = useMap();

	map.scrollWheelZoom.enable();
	map.invalidateSize();

	if (resetPosition.current) {
		map.flyTo([lat, lng], map.getZoom(), { duration: 0 });
		resetPosition.current = false;
	}

	useEffect(() => {
		setMap(map);
	}, [map]);

	useEffect(() => {
		map.flyTo([lat, lng], map.getZoom(), { duration: 0 });
	}, [lat, lng]);
	return null;
};

export const getDefaultMapPosition = (address?: OrganizationAddressApi): number[] => {
	if (address?.coordinates && Object.keys(address?.coordinates).length) {
		return [address.coordinates.latitude, address.coordinates.longitude];
	}
	return polandCoords;
};

const LiveOrdersMap: FC<Props> = ({
	focusedOrder,
	selectedOrder,
	isOrderSelected,
	setFocusedOrder,
	setShowMapLegend,
	showOrderList,
	map,
	setMap,
}) => {
	const orders = useContext(OrdersContext);
	const organizationPosition = getDefaultMapPosition(orders.organizationAddress);
	const [position, setPosition] = useState<number[]>(organizationPosition);
	const resetMapPosition = useRef<any>(false);
	const organizationAddressAsString = JSON.stringify(orders?.organizationAddress);
	const isMobile = useWindowSize().isMobile;
	const selectedOrderObject = selectedOrder
		? {
				id: selectedOrder.id,
				coordinates: areDeliveryCoordinates(selectedOrder) ? selectedOrder.delivery.coordinates : "",
		  }
		: "";
	const selectedOrderAsString = JSON.stringify(selectedOrderObject);
	const [deliveryEmployees, setDeliveryEmployees] = useState<DeliveryEmployeeApi[]>(orders.deliveryEmployees);
	const deliveryEmployeesAsString = JSON.stringify(orders.deliveryEmployees);

	useEffect(() => {
		if (
			JSON.stringify({
				latitude: position[0],
				longitude: position[1],
			}) !== JSON.stringify(orders?.organizationAddress?.coordinates)
		) {
			setPosition([...getDefaultMapPosition(orders.organizationAddress)]);
		}
	}, [organizationAddressAsString]);

	const updateActiveEmployees = () => {
		const activeEmployees = orders.deliveryEmployees.filter((deliveryEmployee) => {
			const employeesLastActivityFilterInMilliseconds =
				Number(orders.filters.employeesLastActivity.id) * 60 * 60 * 1000;
			const isActive =
				Date.now() <
				new Date(`${deliveryEmployee.coordinates_updated_at}Z`).getTime() +
					employeesLastActivityFilterInMilliseconds;

			if (isActive) return deliveryEmployee;
			return undefined;
		});
		setDeliveryEmployees(activeEmployees);
	};

	useEffect(() => {
		updateActiveEmployees();
		const interval = setInterval(updateActiveEmployees, 5000);
		return () => {
			clearInterval(interval);
		};
	}, [deliveryEmployeesAsString, orders.filters.employeesLastActivity.id]);

	useEffect(() => {
		if (selectedOrder) {
			const newPosition = [
				selectedOrder?.delivery?.coordinates?.latitude || getDefaultMapPosition(orders.organizationAddress)[0],
				selectedOrder?.delivery?.coordinates?.longitude || getDefaultMapPosition(orders.organizationAddress)[1],
			];
			setPosition(newPosition);
			if (map) {
				const mapCenter = map.getCenter();
				if (
					mapCenter.lat.toFixed(5) !== newPosition[0].toFixed(5) ||
					mapCenter.lng.toFixed(5) !== newPosition[1].toFixed(5)
				) {
					resetMapPosition.current = true;
				}
			}
		}
	}, [selectedOrderAsString]);

	const getGroupedMarkersByCoords = () => {
		let groupedMarkers: GroupedMarker[] = [];
		const newOrders = [...orders.orders];
		newOrders.forEach((order) => {
			const lat = order?.delivery?.coordinates?.latitude;
			const lon = order?.delivery?.coordinates?.longitude;
			const existingMarker = groupedMarkers?.find(
				(marker) => marker.coords.latitude === lat && marker.coords.longitude === lon
			);

			if (existingMarker) {
				const markerIndex = groupedMarkers.indexOf(existingMarker);
				const newGroupedMarkers = [...groupedMarkers];
				newGroupedMarkers[markerIndex] = {
					...newGroupedMarkers[markerIndex],
					orders: [...newGroupedMarkers[markerIndex].orders, order],
				};
				groupedMarkers = [...newGroupedMarkers];
			} else if (lat && lon) {
				const coords = { latitude: lat, longitude: lon };
				groupedMarkers.push({ coords, orders: [order] });
			}
		});

		return groupedMarkers;
	};

	const drawMarkers = () => {
		return getGroupedMarkersByCoords().map((marker, index) => (
			<LiveOrdersMapMarker
				groupedMarker={marker}
				key={`order-marker-${index}-${marker.coords.latitude}-${marker.coords.longitude}`}
				setFocusedOrder={setFocusedOrder}
				focusedOrder={focusedOrder}
				isFocused={!!marker.orders.find((order) => order.id === focusedOrder?.id)}
			/>
		));
	};

	const onResetMapPosition = () => {
		setPosition(organizationPosition);
		resetMapPosition.current = true;
	};

	const getDeliveryZoneColor = (index: number) => {
		if (index > deliveryZonesColors.length) return "#fff";
		return deliveryZonesColors[index];
	};

	const drawDeliveryZones = () => {
		return orders.deliveryZones.map((zone, index) => {
			const positions = parseDeliveryZonePath(zone);
			const color = getDeliveryZoneColor(index);
			const options: PathOptions = { color };

			return (
				<Polyline
					key={`zone-${zone.id}`}
					pathOptions={options}
					positions={positions as any[]}
					renderer={undefined}
				/>
			);
		});
	};

	const drawDeliveryEmployees = () => {
		return deliveryEmployees.map((employee, index) => (
			<LiveOrdersMapDeliveryEmployeeMarker
				employee={employee}
				key={`employee-marker-${index}`}
				setFocusedOrder={setFocusedOrder}
				focusedOrder={focusedOrder}
			/>
		));
	};

	const getRightLeafletPosition = () => {
		if (isMobile) return "0";
		if (isOrderSelected) return "165px";
		if (showOrderList) return "175px";
		return "10px";
	};

	const getLeftLeafletPosition = () => {
		if (isMobile) return "0";
		if (showOrderList) return "195px";
		return "30px";
	};

	return (
		<MapContainer center={position as any} zoom={13} minZoom={8} style={{ height: "100%" }} preferCanvas>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<div
				className="leaflet-top leaflet-right"
				style={{
					right: getRightLeafletPosition(),
					transition: "0.2s all",
				}}
			>
				<div className="leaflet-control-zoom leaflet-bar leaflet-control legend-button">
					<a
						className="leaflet-control-zoom-out"
						href="#"
						title="Legend"
						role="button"
						onClick={() => setShowMapLegend(true)}
						aria-label="Legend"
					>
						<InfoCircleSVG />
					</a>
				</div>
			</div>
			<div
				key={`${isMobile}`}
				className="leaflet-bottom leaflet-left"
				style={{ left: getLeftLeafletPosition(), transition: "0.2s all" }}
			>
				<div className="leaflet-control-zoom leaflet-bar leaflet-control">
					<a
						className="leaflet-control-zoom-out reset-position-button"
						href="#"
						title="Rest position"
						role="button"
						onClick={onResetMapPosition}
						aria-label="Reset position"
					>
						<CrosshairsSVG />
					</a>
				</div>
			</div>
			{drawDeliveryZones()}
			{drawDeliveryEmployees()}
			{drawMarkers()}
			<LiveOrderOrganizationMarker />
			<RecenterAutomatically
				lat={position[0]}
				lng={position[1]}
				resetPosition={resetMapPosition}
				setMap={setMap}
			/>
		</MapContainer>
	);
};

export default LiveOrdersMap;
