import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownArrowSVG } from "../../../images/down-arrow.svg";
import { ReactComponent as UpArrowSVG } from "../../../images/up-arrow.svg";
import { ReportApi, ReportConfigColumn, SubReportApi } from "../../../services/types";
import {
	getAggregatePercentValue,
	getAggregateValue,
	renderAggregateComparePercentValue,
	renderAggregateValue,
	renderGroupByName,
} from "../../../services/utils";

interface Props {
	columns: ReportConfigColumn[];
	data: ReportApi;
	aggregatePrefix?: string;
	comparable?: boolean;
	showPercent: boolean;
	groupsInfo?: Record<string, any>;
	selectedGroups?: string[];
	groups?: Array<Record<string, string>>;
}

const Table = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [renderAmount, setRenderAmount] = useState(30);

	useEffect(() => {
		handleResize();
	}, []);

	const handleScroll = (e: any) => {
		if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1) {
			setRenderAmount((renderAmount) => renderAmount + 20);
		}
	};

	const handleResize = () => {
		if (window.innerHeight > window.screen.availHeight) {
			setRenderAmount((renderAmount) => renderAmount + 20);
			setTimeout(() => {
				handleResize();
			}, 100);
		}
	};

	const buildLevel = (subItem: SubReportApi, index: number, mainAggregate: any, level: number) => {
		const padding = (level - 1) * 30 + 10;

		return (
			<tr>
				{subItem.group_by_type === "NONE" ? (
					<td>
						<strong>{t("lib:common.word.summary")}</strong>
					</td>
				) : (
					<td style={{ paddingLeft: `${padding}px` }}>
						{props.groupsInfo &&
						Object.prototype.hasOwnProperty.call(props.groupsInfo, `${subItem.group_by_type}`) ? (
							<>{props.groupsInfo[`${subItem.group_by_type}`](subItem.group_by_value)}</>
						) : (
							renderGroupByName(subItem.group_by_value?.name)
						)}
					</td>
				)}
				{props.columns.map((col, index) => {
					let percentValue;
					if (level === 0) {
						percentValue = `${t("lib:go_report.word.percent_from_all")}`;
					} else {
						percentValue = getAggregatePercentValue(col, subItem, mainAggregate, props.aggregatePrefix);
					}
					const compareValue = renderAggregateComparePercentValue(col, subItem, props.aggregatePrefix);

					return (
						<td key={index}>
							{props.comparable ? (
								<>
									{level === 0 ? (
										<>
											{props.showPercent && (
												<strong>
													{compareValue}
													{compareValue !== "-" ? "%" : ""}
												</strong>
											)}
											{Number(getAggregateValue(col, subItem.aggregate, props.aggregatePrefix)) >
												Number(
													getAggregateValue(
														col,
														subItem.compare_aggregate,
														props.aggregatePrefix
													)
												) && <UpArrowSVG className="arrow-icon" />}
											{Number(getAggregateValue(col, subItem.aggregate, props.aggregatePrefix)) <
												Number(
													getAggregateValue(
														col,
														subItem.compare_aggregate,
														props.aggregatePrefix
													)
												) && <DownArrowSVG className="arrow-icon" />}
											<br />
											{renderAggregateValue(col, subItem.aggregate, props.aggregatePrefix)}
											<small className="ms-1">
												vs{" "}
												{renderAggregateValue(
													col,
													subItem.compare_aggregate,
													props.aggregatePrefix
												)}
											</small>
										</>
									) : (
										<>
											{renderAggregateValue(col, subItem.aggregate, props.aggregatePrefix)}
											{Number(getAggregateValue(col, subItem.aggregate, props.aggregatePrefix)) >
												Number(
													getAggregateValue(
														col,
														subItem.compare_aggregate,
														props.aggregatePrefix
													)
												) && <UpArrowSVG className="arrow-icon" />}
											{Number(getAggregateValue(col, subItem.aggregate, props.aggregatePrefix)) <
												Number(
													getAggregateValue(
														col,
														subItem.compare_aggregate,
														props.aggregatePrefix
													)
												) && <DownArrowSVG className="arrow-icon" />}
											<small className="ms-1">
												vs{" "}
												{renderAggregateValue(
													col,
													subItem.compare_aggregate,
													props.aggregatePrefix
												)}
											</small>
											<br />
											{props.showPercent && (
												<strong>
													{compareValue}
													{compareValue !== "-" ? "%" : ""}
												</strong>
											)}
										</>
									)}
								</>
							) : (
								<strong>
									{renderAggregateValue(col, subItem.aggregate, props.aggregatePrefix)}
									{props.showPercent && <small> {percentValue}</small>}
								</strong>
							)}
						</td>
					);
				})}
			</tr>
		);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, true);
		window.addEventListener("resize", handleResize, true);
		return () => {
			window.removeEventListener("scroll", handleScroll, true);
			window.removeEventListener("resize", handleResize, true);
		};
	});
	let amount = 0;
	return (
		<tbody>
			{props.data.reports.map((subItem, index) => {
				const mainAggregate = subItem.aggregate[`${props.aggregatePrefix}`];
				++amount;
				if (amount > renderAmount) {
					return null;
				}
				return (
					<React.Fragment key={`aggr_${index}_${0}`}>
						{buildLevel(subItem, index, mainAggregate, 0)}
						{subItem.sub_report?.map((subItem2, index) => {
							++amount;
							if (amount > renderAmount) {
								return null;
							}
							return (
								<React.Fragment key={`aggr_${index}_${1}`}>
									{buildLevel(subItem2, index, mainAggregate, 1)}
									{subItem2.sub_report?.map((subItem3, index) => {
										++amount;
										if (amount > renderAmount) {
											return null;
										}
										return (
											<React.Fragment key={`aggr_${index}_${2}`}>
												{buildLevel(subItem3, index, mainAggregate, 2)}
												{subItem3.sub_report?.map((subItem4, index) => {
													++amount;
													if (amount > renderAmount) {
														return null;
													}
													return (
														<React.Fragment key={`aggr_${index}_${3}`}>
															{buildLevel(subItem4, index, mainAggregate, 3)}
															{subItem4.sub_report?.map((subItem5, index) => {
																++amount;
																if (amount > renderAmount) {
																	return null;
																}
																return (
																	<React.Fragment key={`aggr_${index}_${4}`}>
																		{buildLevel(subItem5, index, mainAggregate, 4)}
																		{subItem5.sub_report?.map((subItem6, index) => {
																			++amount;
																			if (amount > renderAmount) {
																				return null;
																			}
																			return (
																				<React.Fragment
																					key={`aggr_${index}_${5}`}
																				>
																					{buildLevel(
																						subItem6,
																						index,
																						mainAggregate,
																						5
																					)}
																					{subItem6.sub_report?.map(
																						(subItem7, index) => {
																							++amount;
																							if (amount > renderAmount) {
																								return null;
																							}
																							return (
																								<React.Fragment
																									key={`aggr_${index}_${6}`}
																								>
																									{buildLevel(
																										subItem7,
																										index,
																										mainAggregate,
																										6
																									)}
																								</React.Fragment>
																							);
																						}
																					)}
																				</React.Fragment>
																			);
																		})}
																	</React.Fragment>
																);
															})}
														</React.Fragment>
													);
												})}
											</React.Fragment>
										);
									})}
								</React.Fragment>
							);
						})}
					</React.Fragment>
				);
			})}
		</tbody>
	);
};
export default Table;
