import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	LiveOrderApi,
	OrderPreparationStatusName,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { OrdersContext } from "../../../../../services/context";
import { liveOrderParamsInclude } from "../../../../../services/orderSynchronizer";
import LiveOrderFiscalizationModal from "../../../components/FiscalizationModal/LiveOrderFiscalizationModal";
import LiveOrderAcceptanceModal from "./AcceptanceModal/LiveOrderAcceptanceModal";
import LiveOrderRejectionModal from "./RejectionModal/LiveOrderRejectionModal";

interface Props {
	order: LiveOrderApi;
	setOrder: (order: LiveOrderApi) => void;
	orderListRoutePath: string;
}

const getNextAvailablePreparationStatus = (order: LiveOrderApi, supportedStatuses: OrderPreparationStatusName[]) => {
	if (!order.preparation_status?.status) return undefined;

	const allPossibleStatuses = [
		OrderPreparationStatusName.NEW,
		OrderPreparationStatusName.IN_PROGRESS,
		OrderPreparationStatusName.READY,
		OrderPreparationStatusName.PACKED,
		OrderPreparationStatusName.RECEIVED,
		OrderPreparationStatusName.DELIVERED,
	];
	const indexOfCurrentStatus = supportedStatuses.indexOf(order.preparation_status.status);
	if (indexOfCurrentStatus > -1) {
		return supportedStatuses[indexOfCurrentStatus + 1];
	}

	const statusIndex = allPossibleStatuses.findIndex((status) => status === order.preparation_status?.status);
	let statusToReturn: OrderPreparationStatusName | undefined = undefined;

	for (let i = statusIndex; i < allPossibleStatuses.length; i++) {
		const nextStatus = allPossibleStatuses[i + 1];
		if (nextStatus && supportedStatuses.find((status) => status === nextStatus)) {
			statusToReturn = nextStatus;
			break;
		}
	}
	return statusToReturn;
};

const LiveOrdersOrderPreviewFooter = ({ order, setOrder, orderListRoutePath }: Props) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [showFiscalizationModal, setShowFiscalizationModal] = useState(false);
	const orders = useContext(OrdersContext);
	const [showAcceptanceModal, setShowAcceptanceModal] = useState(false);
	const [showRejectionModal, setShowRejectionModal] = useState(false);
	const [changePreparationStatusButtonLoading, setChangePreparationStatusButtonLoading] = useState(false);
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const ordersContext = useContext(OrdersContext);
	const supportedOrganizationStatuses = organization?.more
		?.supported_preparation_statuses as OrderPreparationStatusName[];
	const [collectMode, setCollectMode] = useState(false);
	const [acceptanceLoading, setAcceptanceLoading] = useState(false);

	const isChangePreparationStatusButtonVisible = () => {
		if (order.status === "EXTERNAL") return false;

		return (
			getNextAvailablePreparationStatus(order, supportedOrganizationStatuses) !==
				OrderPreparationStatusName.RECEIVED &&
			getNextAvailablePreparationStatus(order, supportedOrganizationStatuses) !== undefined
		);
	};

	const isCollectButtonVisible = () => {
		if (order.status === "EXTERNAL") return false;

		return (
			getNextAvailablePreparationStatus(order, supportedOrganizationStatuses) ===
			OrderPreparationStatusName.RECEIVED
		);
	};

	const isClosingOrderButtonLoading = () => {
		const orderCloseNotifications = order.pending_terminal_notifications.filter(
			(notification) => notification.type === "ORDER_CLOSE"
		);
		if (orderCloseNotifications.length === 0) return false;
		return !orderCloseNotifications.every((notification) => notification.error);
	};

	const handleAcceptOrder = async () => {
		setAcceptanceLoading(true);
		try {
			const res = await apiOrganization.acceptLiveOrder(order.id, undefined, { include: liveOrderParamsInclude });
			ordersContext.updateOrder(res);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setAcceptanceLoading(false);
	};

	const handleChangePreparationStatusButton = async () => {
		await confirmation({ title: t("lib:confirmation.title"), message: t("lib:confirmation.message.action") });
		setChangePreparationStatusButtonLoading(true);
		try {
			const res = await api
				.organization()
				.changeOrderPreparationStatus(
					order.id,
					getNextAvailablePreparationStatus(order, supportedOrganizationStatuses),
					{
						include: "preparation_status",
					}
				);
			const newOrder = {
				...order,
				preparation_status: {
					...res.preparation_status,
				},
			};
			ordersContext.updateOrder(newOrder);
			setOrder(newOrder);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setChangePreparationStatusButtonLoading(false);
	};

	return (
		<>
			{order.status === "OPENED" && !isChangePreparationStatusButtonVisible() && !isCollectButtonVisible() && (
				<div className="live-orders-order-preview-footer">
					<ButtonLoading
						onClick={() => setShowFiscalizationModal(true)}
						className="w-100 p-2"
						disabled={!orders.isOnline}
						loading={isClosingOrderButtonLoading()}
					>
						{isClosingOrderButtonLoading()
							? t("modules.live_order.field.pending_close_order.title")
							: t("modules.live_order.action.close_order.title")}
						{isClosingOrderButtonLoading() && (
							<>
								<br />
								<small style={{ color: "white", opacity: "50%", lineHeight: "12px" }}>
									{t("modules.live_order.field.pending_close_order.description")}
								</small>
							</>
						)}
					</ButtonLoading>
				</div>
			)}
			{order.status === "EXTERNAL" && (
				<div className="live-orders-order-preview-footer gap-2">
					<Button variant="danger" className="w-30" onClick={() => setShowRejectionModal(true)}>
						{t("lib:common.action.reject")}
					</Button>
					{order.type === "DELIVERY" ? (
						<Button variant="success" className="w-70" onClick={() => setShowAcceptanceModal(true)}>
							{t("modules.live_order.action.accept.title")}
						</Button>
					) : (
						<ButtonLoading
							loading={acceptanceLoading}
							variant="success"
							className="w-70"
							onClick={handleAcceptOrder}
						>
							{t("modules.live_order.action.accept.title")}
						</ButtonLoading>
					)}
				</div>
			)}

			{isChangePreparationStatusButtonVisible() && !isCollectButtonVisible() && (
				<div className="live-orders-order-preview-footer">
					<ButtonLoading
						loading={changePreparationStatusButtonLoading}
						onClick={handleChangePreparationStatusButton}
						variant={
							getNextAvailablePreparationStatus(order, supportedOrganizationStatuses) ===
							OrderPreparationStatusName.READY
								? "success"
								: "primary"
						}
						className="w-100 p-2 pending-closing-button"
					>
						{getNextAvailablePreparationStatus(order, supportedOrganizationStatuses) ===
						OrderPreparationStatusName.PACKED
							? t("modules.live_order.action.pack.title")
							: t(
									`enums.preparation_statuses.${getNextAvailablePreparationStatus(
										order,
										supportedOrganizationStatuses
									)}`
							  )}
					</ButtonLoading>
				</div>
			)}

			{isCollectButtonVisible() && (
				<div className="live-orders-order-preview-footer">
					<ButtonLoading
						onClick={() => {
							setCollectMode(true);
							setShowFiscalizationModal(true);
						}}
						className="w-100 p-2"
						disabled={!orders.isOnline}
						loading={isClosingOrderButtonLoading()}
					>
						{isClosingOrderButtonLoading()
							? t("modules.live_order.field.pending_close_order.title")
							: t("modules.live_order.action.collect.title")}
						{isClosingOrderButtonLoading() && (
							<>
								<br />
								<small style={{ color: "white", opacity: "50%", lineHeight: "12px" }}>
									{t("modules.live_order.field.pending_close_order.description")}
								</small>
							</>
						)}
					</ButtonLoading>
				</div>
			)}

			{showFiscalizationModal && (
				<LiveOrderFiscalizationModal
					isShown={showFiscalizationModal}
					onHide={() => {
						setShowFiscalizationModal(false);
						if (collectMode) {
							setCollectMode(false);
						}
					}}
					order={order}
					inCollectMode={collectMode}
				/>
			)}
			{showAcceptanceModal && (
				<LiveOrderAcceptanceModal
					show={showAcceptanceModal}
					onHide={() => setShowAcceptanceModal(false)}
					order={order}
				/>
			)}
			{showRejectionModal && (
				<LiveOrderRejectionModal
					show={showRejectionModal}
					onHide={() => setShowRejectionModal(false)}
					order={order}
					orderListRoutePath={orderListRoutePath}
				/>
			)}
		</>
	);
};

export default LiveOrdersOrderPreviewFooter;
