import React from "react";
import { useSelector } from "react-redux";
import SecurityAccessDenied from "go-security/components/AccessDenied";
import SecurityOrganizationRemoveOrganizationComponent from "go-security/components/Organization/Remove";
import { hasPermission } from "go-security/services/organizations/selectors";
import { getApiURL } from "../../../../../../../../utils/envutil";

const OrganizationSettingsRemoveOrganizationPage = (): JSX.Element => {
	const hasAccess = useSelector(hasPermission("MANAGE"));
	if (!hasAccess) return <SecurityAccessDenied />;

	return (
		<div className="content">
			<SecurityOrganizationRemoveOrganizationComponent baseUrl={getApiURL()} />
		</div>
	);
};
export default OrganizationSettingsRemoveOrganizationPage;
