import * as H from "history";
import { FILTER_SEPARATOR } from "go-list/core/components/Filter/services";
import { ReportAction } from "./actions";
import { listDataDecoderQuery } from "./decoder";
import { getShowPercentageInitialState } from "./report-service";
import { paramsToSegment } from "./segment-service";
import { ReportConfig, ReportParamsType } from "./types";

export const reportParamsInitialState = (config: ReportConfig, location: H.Location): ReportParamsType => {
	const urlParams = listDataDecoderQuery(new URLSearchParams(location.search));
	const externalSegments =
		config.externalSegments !== undefined
			? config.externalSegments.map((externalSegment) => paramsToSegment(externalSegment))
			: [];
	let segments = config.segments ? config.segments : [];
	segments = [...segments, ...externalSegments];
	const columnsFromLocalStorage = window.localStorage
		.getItem(`go_report.${config.reportConfigId}.columns`)
		?.split(",")
		.filter((column) => !column.includes("show_percentage"));
	let initParams: ReportParamsType = {
		segments,
		columns: columnsFromLocalStorage ?? config.selectedColumns,
		groupColumn: config.selectedGroupColumn,
		filters: undefined,
		showPercent: getShowPercentageInitialState(config),
		selectedSegment: config.selectedSegment ? config.selectedSegment : undefined,
	};

	if (location.search === "") {
		initParams.groups = config.selectedGroups;
	} else {
		initParams.groups = urlParams.groups;
	}

	const selectedSegment = JSON.parse(
		JSON.stringify(initParams.segments?.find((segment) => segment.slug === initParams.selectedSegment) || null)
	);
	if (selectedSegment) {
		initParams.selectedSegment = selectedSegment.slug;
		if (selectedSegment.columns) {
			initParams.columns = selectedSegment.columns;
		}
		if (selectedSegment.filters) {
			initParams.filters = selectedSegment.filters;
		}
		if (selectedSegment.groups) {
			initParams.groups = selectedSegment.groups;
		}
	}

	if (location.search) {
		if (urlParams.selectedSegment) {
			let selectedSegment = initParams.segments?.filter(
				(segment) => segment.slug === urlParams.selectedSegment
			)[0];
			if (selectedSegment) selectedSegment = JSON.parse(JSON.stringify(selectedSegment));
			if (selectedSegment) {
				initParams.selectedSegment = selectedSegment.slug;
				if (selectedSegment.columns) {
					initParams.columns = selectedSegment.columns;
				}
				if (selectedSegment.groups) {
					initParams.groups = selectedSegment.groups;
				}
				if (selectedSegment.groupColumn) {
					initParams.groupColumn = selectedSegment.groupColumn;
				}
				if (
					urlParams.filters &&
					urlParams.filters.map((f) => `${f.condition}|${f.value}`).join(FILTER_SEPARATOR) !==
						selectedSegment.filters?.map((f: any) => `${f.condition}|${f.value}`).join(FILTER_SEPARATOR)
				) {
					selectedSegment.filters = urlParams.filters;
				}
				if (urlParams.columns?.join(",") !== selectedSegment.columns?.join(",")) {
					selectedSegment.columns = urlParams.columns;
				}
				if (selectedSegment.sort) {
					initParams.sort = selectedSegment.sort;
				}
			}
		}
		initParams = { ...initParams, ...urlParams };
	}
	if (config.externalSelectedFilters && (location.search === "" || (!urlParams.f && !config.reportInternal))) {
		config.externalSelectedFilters.forEach((filter) => {
			if (initParams.filters === undefined) {
				initParams.filters = [];
				initParams.filters?.push({
					filterId: filter.id ? filter.id : "",
					value: filter.value ? filter.value : "",
					condition: filter.condition,
					valueInfo: filter.valueInfo,
				});
			} else if (!initParams.filters.find((f) => f.filterId === filter.id)) {
				initParams.filters?.push({
					filterId: filter.id ? filter.id : "",
					value: filter.value ? filter.value : "",
					condition: filter.condition,
					valueInfo: filter.valueInfo,
				});
			}
		});
	}

	return initParams;
};

export const ReportParamsReducer = (state: ReportParamsType, action: ReportAction): ReportParamsType => {
	switch (action.type) {
		case "setShowPercent":
			return {
				...state,
				showPercent: action.showPercent,
			};
		case "setGroups":
			return {
				...state,
				groups: action.groups,
			};
		case "setGroupColumn":
			return {
				...state,
				groupColumn: action.groupColumn,
			};
		case "setFilters":
			return {
				...state,
				filters: action.filters,
			};
		case "setColumns":
			return {
				...state,
				columns: action.columns,
			};
		case "setSegments":
			if (!state.selectedSegment) {
				return {
					...state,
					segments: action.segments,
				};
			}
			return {
				...state,
				segments: action.segments,
			};
		case "setSegment": {
			const matchedSegment = state.segments?.find((segment) => segment.slug === action.segment);
			return {
				...state,
				selectedSegment: action.segment,
				filters: matchedSegment?.filters,
				columns: matchedSegment?.columns,
				groups: matchedSegment?.groups,
				groupColumn: matchedSegment?.groupColumn,
				sort: matchedSegment?.sort,
			};
		}
		case "updateSegment": {
			let newSegment = action.data.segment;
			const newSegments = state.segments !== undefined ? state.segments : [];
			const oldSegment = newSegments.filter((segment) => segment.slug === newSegment.slug)[0];
			if (oldSegment === undefined) {
				newSegments.push(newSegment);
			} else {
				newSegment = { ...oldSegment, ...newSegment };
				newSegments[newSegments.indexOf(oldSegment)] = newSegment;
			}

			if (action.data.preventReturnUpdatedSegment) {
				return state;
			}

			return {
				...state,
				selectedSegment: newSegment.slug,
				filters: newSegment?.filters,
				columns: newSegment?.columns,
				groups: newSegment?.groups,
				groupColumn: newSegment?.groupColumn,
				sort: newSegment?.sort,
			};
		}
		case "setSort":
			return {
				...state,
				sort: action.sort,
			};
	}
	return state;
};
