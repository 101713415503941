import React, { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { FiscalizeOrderFormProps, LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
	form: UseFormReturn<FiscalizeOrderFormProps>;
	order: LiveOrderApi;
}

const LiveOrderFiscalizationDeliveryEmployee = ({ setErrors, form, order }: Props) => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
	} = form;

	const searchDeliveryEmployee = async (
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	) => {
		const newParams = {
			...params,
			"status|ne": "DELETED",
		};
		return apiOrganization.getDeliveryEmployeeSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	const selectValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	return (
		<div>
			<h5 className="mb-2">{`${t("modules.live_order.field.choose_delivery_employee.title")} *`}</h5>
			<FormSelectGroup
				errors={errors}
				control={control}
				customValidationConfig={selectValidationConfig}
				loadOptions={searchDeliveryEmployee}
				name="employee_id"
				defaultValue={{
					value: order.delivery?.delivery_employee?.reference_id || order.delivery?.delivery_employee?.id,
					label: order.delivery?.delivery_employee?.name,
				}}
			/>
			{order.status !== "CLOSED" && <hr />}
		</div>
	);
};

export default LiveOrderFiscalizationDeliveryEmployee;
