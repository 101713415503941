import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { CopyPlaceApi } from "../../../../../../../../../../services/Api/Organization/types";
import CopyPlaceForm from "./components/CopyPlaceForm";

const OrganizationSettingsAdvancedCopyPlacePage = (): JSX.Element => {
	const [copyPlace] = useState<CopyPlaceApi>({} as CopyPlaceApi);
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.action.copy_data_from_other_location.title"));
	}, []);

	return <CopyPlaceForm copyPlace={copyPlace} />;
};

export default OrganizationSettingsAdvancedCopyPlacePage;
