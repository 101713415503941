import React, { FC, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLimitedOptions, textFilterOptions } from "../../services";
import { FilterCondition, ListConfigFilter, ListSelectedFilter } from "../../services/types";

interface State {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	onChange: (condition: FilterCondition, selectedFilters: string) => void;
}

const ListFilterText: FC<State> = (props) => {
	const { t } = useTranslation();
	const opts = props.filter.options;
	const [value, setValue] = useState(
		props.selectedFilter && props.selectedFilter.value ? props.selectedFilter.value : ""
	);
	let options = opts
		? textFilterOptions(t)
				.filter((item) => opts?.indexOf(item.id) !== -1)
				.sort((a, b) => opts.indexOf(a) + opts.indexOf(b))
		: textFilterOptions(t);
	options = getLimitedOptions(
		textFilterOptions(t),
		options.map((o) => o.id as FilterCondition),
		props.filter.typeOptions,
		props.filter.extraOptions
	);
	const [type, setType] = useState<FilterCondition>(
		props.selectedFilter.condition ? props.selectedFilter.condition : ((options[0]?.id || "") as FilterCondition)
	);
	const onChangeValue = (value: string) => {
		setValue(value);
		props.onChange(type, value);
	};

	const onInputClick = (value: FilterCondition, evt?: any) => {
		evt.stopPropagation();
		onChangeType(value);
	};

	const onChangeType = (newType: FilterCondition) => {
		setType(newType);
		props.onChange(newType, value);
	};

	return (
		<>
			<Form.Group className="form-group">
				{options.map((option) => {
					return (
						<>
							<Dropdown.Item
								key={`type_${props.id}_${option.id}`}
								onClick={() => onChangeType(option.id as FilterCondition)}
							>
								<Form.Check
									type="radio"
									className="form-check"
									label={option.name}
									name={`type_${props.id}_${option.id}`}
									value={option.id}
									checked={type === option.id}
									onClick={(e) => onInputClick(option.id as FilterCondition, e)}
								/>
							</Dropdown.Item>
							{type === option.id && option.isValue && (
								<Form.Control
									className="filter-input"
									type="text"
									value={value}
									onChange={(e) => onChangeValue(e.currentTarget.value)}
								/>
							)}
						</>
					);
				})}
			</Form.Group>
		</>
	);
};

export default ListFilterText;
