import { AxiosResponse } from "axios";
import { PdfOrientationType } from "go-core/types";

export interface CardApi {
	id: number;
	contact_id: number;
	card_number: string;
	card_code: string;
	card_expiry_date: string;
}

export interface ExportCSVTranslationsApi {
	export_csv?: string;
	resource_name: string;
	constraints: Record<string, string>;
	database_column_name: Record<string, string>;
}

export enum AvailabilityStatusApi {
	ENABLED = "ENABLED",
	DISABLED = "DISABLED",
	DELETED = "DELETED",
}

export interface AvailabilityApi {
	id: number;
	name: string;
	hours?: AvailabilityHourItem[];
	dates?: AvailabilityDateItem[];
	status: AvailabilityStatusApi;
}

export interface AvailabilityHourItem {
	id?: number;
	day_from: string;
	day_to: string;
	hour_from: string;
	hour_to: string;
}

export interface AvailabilityDateItem {
	id: number;
	date_range: string | null;
	date_from?: string;
	date_to?: string;
}

export interface AvailabilityRequests {
	updateAvailability: (
		availability: AvailabilityApi,
		params?: Record<string, any>,
		organizationId?: number
	) => Promise<AvailabilityApi>;
	createAvailability: (availability: AvailabilityApi, organizationId?: number) => Promise<AvailabilityApi>;
	removeAvailability: (id: number, organizationId?: number) => Promise<{}>;
	restoreAvailability: (id: number, organizationId?: number) => Promise<AvailabilityApi>;
	getAvailability: (id: number, params?: Record<string, any>, organizationId?: number) => Promise<AvailabilityApi>;
	getAvailabilities: (
		params?: Record<string, any>,
		options?: Record<string, any>,
		organizationId?: number
	) => Promise<AvailabilityApi[]>;
}

export interface AvailabilitiesProps {
	requests: AvailabilityRequests;
	organizationId?: number;
	organizationName?: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

export interface AdminJobApi {
	id: string;
	name: string;
	organization_id: string;
	status: string;
	created_at: string;
	updated_at: string;
	error: string;
}

export interface DiagnoseApi {
	message: string;
	elements: DiagnoseElementApi[];
	fix_id?: string;
}

export interface DiagnoseElementApi {
	resource_type: string;
	id: string;
	link?: string;
}

export interface DiagnosticsRequests {
	getDiagnosticsTypes: (organizationId?: number) => Promise<string[]>;
	diagnose: (types: string[], organizationId?: number, options?: Record<string, any>) => Promise<string>;
	getDiagnoseProgress: (
		jobUid: string,
		organizationId?: number,
		options?: Record<string, any>
	) => Promise<AxiosResponse>;
	diagnoseFix: (fixId: string, organizationId?: number) => Promise<{}>;
}
