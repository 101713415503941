import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomFieldResourceApi, CustomFieldTemplateApi, CustomFieldTemplateFormApi } from "go-segment/services/types";
import CustomFieldConstraintsForm from "./components/ConstraintsForm";
import CustomFieldOptionsForm from "./components/OptionsForm";
import CustomFieldPermissionsForm from "./components/PermissionsForm";
import { CustomFieldContext } from "./services/context";

interface Props {
	customField: CustomFieldTemplateApi;
	onSuccess: (customFieldTemplate: CustomFieldTemplateApi) => void;
}

const CustomFieldForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const customField = props.customField;
	const form = useForm<CustomFieldTemplateApi>({
		criteriaMode: "all",
		defaultValues: {
			...customField,
			options: customField.options || [],
		},
	});
	const {
		register,
		watch,
		handleSubmit,
		setError,
		control,
		reset,
		formState,
		formState: { errors },
		setValue,
	} = form;
	const [loading, setLoading] = useState<boolean>(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const customFieldService = React.useContext(CustomFieldContext);
	const watchedType = watch("type");

	const getParsedFormData = (data: CustomFieldTemplateApi): CustomFieldTemplateFormApi => {
		return {
			id: customField.id,
			resources:
				data.resources?.map((resource: CustomFieldResourceApi | string) =>
					typeof resource === "string" ? resource : resource.resource
				) || [],
			options_list: data.options,
			constraints: data.constraints,
			name: data.name,
			priority: data.priority,
			type: data.type,
			permissions: data.permissions,
			slug: data.slug,
			status: data.status,
			description: data.description,
		};
	};

	const onSubmit = handleSubmit(async (data: CustomFieldTemplateApi) => {
		setLoading(true);
		const newData = getParsedFormData(data);
		try {
			const params: Record<string, any> = {};
			params.include = "options,resources,permissions,constraints";
			let res;
			if (data.id) {
				res = await customFieldService.api().update(newData, params);
				data.options = res.options;
			} else {
				res = await customFieldService.api().create(newData, params);
			}
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			setLoading(false);
			reset(data);
			props.onSuccess(res);
		} catch (err) {
			setLoading(false);
			handleError.form(err, setError, addFlash);
		}
	});

	const cfTypes = [
		{ value: "NUMBER", label: `${t("lib:enums.custom_field.type.NUMBER")}` },
		{ value: "TEXT", label: `${t("lib:enums.custom_field.type.TEXT")}` },
		{ value: "LONG_TEXT", label: `${t("lib:enums.custom_field.type.LONG_TEXT")}` },
		{ value: "DATE", label: `${t("lib:enums.custom_field.type.DATE")}` },
	];

	const loadResourceOptions = (search: string) => {
		return customFieldService
			.types()
			.map((x) => {
				return {
					id: x.id,
					resource: x.id,
					searchValue: x.title,
				};
			})
			.filter((option) => option.searchValue.toLowerCase().includes(search.toLowerCase()) || !search);
	};

	const getResourceOptions = (options?: CustomFieldResourceApi[]) => {
		return options?.map((option) => {
			return {
				id: option.id,
				resource: option.resource,
			};
		});
	};

	const generateSlug = () => {
		const name = watch("name");
		return name?.toLowerCase()?.replace(/\s+/g, "_");
	};

	const changeTypeHandler = (type: string) => {
		if (type === "DATE") {
			watch("options").forEach((_, index) => {
				setValue(`options.${index}.value`, "");
			});
		}
	};

	return (
		<FormDirty
			key="custom-field-form"
			loading={customField.status === "DEFAULT" ? undefined : loading}
			formState={formState}
			noValidate
			onSubmit={onSubmit}
		>
			<FormErrorMessage errors={errors} name={"options"} />
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
				<FormInput
					errors={errors}
					register={register}
					name="slug"
					placeholder={generateSlug()}
					label="Slug"
					disabled={Boolean(customField.id)}
				/>
				<FormSelectGroup
					label={t("lib:common.word.type")}
					name="type"
					defaultValue={customField.type}
					errors={errors}
					options={cfTypes}
					control={control}
					data-testid="type"
					onChange={changeTypeHandler}
				/>
				<FormSelectGroup
					label={t("lib:go_component.custom_field.field.resources.title")}
					isMulti={true}
					name="resources"
					errors={errors}
					defaultValue={getResourceOptions(customField.resources)}
					getOptionLabel={(option) => `${t(`enums.custom_fields.resources.${option.resource}`)}`}
					getOptionValue={(option) => option.resource}
					loadOptions={loadResourceOptions}
					control={control}
					data-testid="resources"
				/>
				<FormNumberInput
					control={control}
					errors={errors}
					suffix=""
					name="priority"
					label={t("lib:go_component.custom_field.field.priority.title")}
				/>
				<FormInput
					errors={errors}
					register={register}
					name="description"
					label={t("lib:common.word.description")}
					as="textarea"
				/>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("lib:go_component.custom_field.field.options.title")}</h5>
				<CustomFieldOptionsForm form={form} type={watchedType} />
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("lib:go_component.custom_field.field.permissions.title")}</h5>
				<CustomFieldPermissionsForm form={form} />
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("lib:go_component.custom_field.field.constraints.title")}</h5>
				<CustomFieldConstraintsForm form={form} />
			</fieldset>
		</FormDirty>
	);
};

export default CustomFieldForm;
