import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormInputSelect } from "go-form/components/FormInputSelect";
import { apiSecurity } from "go-security/services/Api/api";
import { RegisterOrganizationSecurityApi } from "go-security/services/Api/typesSecurity";
import { useAuth } from "go-security/services/auth";
import { getOrganizationCategories } from "../services/utils";

interface Props {
	baseUrl: string;
}

const RegisterOrganizationForm = ({ baseUrl }: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash } = useFlash();
	const history = useHistory();
	const auth = useAuth(baseUrl);

	const form = useForm<RegisterOrganizationSecurityApi>({
		criteriaMode: "all",
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = form;

	const onSubmit = handleSubmit(async (data: RegisterOrganizationSecurityApi) => {
		setLoading(true);
		try {
			if (data.category === "") data.category = undefined;
			if (data.accept_rules) {
				data.accept_terms = true;
				data.accept_privacy = true;
			} else {
				data.accept_terms = false;
				data.accept_privacy = false;
			}
			const response = await apiSecurity.user().registerOrganization(data);
			const organizationId = response.data.data.id;
			await auth.checkLogin();
			history.push(`/${organizationId}`);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash, ["accept_privacy", "accept_terms"]);
		}
	});

	const categories = getOrganizationCategories().map((x) => {
		return {
			value: x,
			label: t(`lib:go_security.enums.organization_category.${x}`),
		};
	});
	return (
		<>
			<FormDirty
				errors={errors}
				isDirty={false}
				key="register-organization-form"
				loading={loading}
				noValidate
				onSubmit={onSubmit}
			>
				<FormInput
					firstInput={true}
					label={t("lib:go_security.register.form.name.label")}
					register={register}
					placeholder={t("lib:go_security.register.form.name.placeholder")}
					name="name"
					errors={errors}
				/>

				<FormInputSelect
					firstInput={true}
					label={t("lib:go_security.register.form.category.label")}
					name="category"
					placeholder={t("lib:go_security.register.form.category.placeholder")}
					errors={errors}
					register={register}
					options={categories}
				/>

				<FormCheck
					name={"accept_rules"}
					register={register}
					errors={errors}
					label={
						<>
							{t("lib:go_security.register.words.accept")}{" "}
							<a href="https://www.gopos.pl/tos">{t("lib:go_security.register.words.tos")}</a>,{" "}
							<a href="https://www.gopos.pl/privacy-policy">
								{t("lib:go_security.register.words.privacy_policy")}
							</a>{" "}
							{t("lib:go_security.register.words.and_accept_receive_informations_from_gopos")}
						</>
					}
				/>

				<FormCheck
					name={"accept_marketing_content"}
					register={register}
					errors={errors}
					label={<>{t("lib:go_security.register.form.accept_marketing_content.label")}</>}
				/>
			</FormDirty>
		</>
	);
};

export default RegisterOrganizationForm;
