import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { TerminalDiagnoseApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	diagnoses: TerminalDiagnoseApi[];
	hasLimitedDiagnosesAmount: boolean;
	setDiagnoseToShow: (diagnose: TerminalDiagnoseApi) => void;
}

const TerminalDiagnosesTable: FC<Props> = ({ diagnoses, hasLimitedDiagnosesAmount, setDiagnoseToShow }) => {
	const { t } = useTranslation();
	const diagnosesToDraw = hasLimitedDiagnosesAmount ? diagnoses.filter((_, index) => index < 10) : diagnoses;

	return (
		<table className="table">
			<thead>
				<tr>
					<td>{t("lib:common.word.date")}</td>
					<td />
				</tr>
			</thead>
			<tbody>
				{diagnosesToDraw.map((diagnose, index) => (
					<tr key={hasLimitedDiagnosesAmount ? `diagnose-row-${index}` : `modal-diagnose-row-${index}`}>
						<td>{FormatDate(diagnose.occurred_at)}</td>
						<td className="text-end">
							<Button onClick={() => setDiagnoseToShow(diagnose)} variant="light">
								{t("common.action.preview", { ns: "lib" })}
							</Button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default TerminalDiagnosesTable;
