import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationSettingsRoomsIndexIndexPage from "./pages/Index";

const OrganizationSettingsRoomsIndexPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"content"}>
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationSettingsRoomsIndexIndexPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSettingsRoomsIndexPage;
