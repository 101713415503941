import { OrganizationActionTypes, OrganizationState } from "./types";

export const clearOrganization = (): { type: OrganizationActionTypes.CLEAR } => ({
	type: OrganizationActionTypes.CLEAR,
});

export const authOrganization = (organization: OrganizationState): Record<string, any> => ({
	type: OrganizationActionTypes.SET,
	payload: organization,
});
