import { chartsConfigReducer } from "./charts/reducer";
import { AggregateOption, ChartDataRangeType, ChartType } from "./charts/types";
import { dashboardDataReducer } from "./data/reducer";
import { DashboardInitialStateType } from "./data/types";
import { filtersConfigReducer } from "./filters/reducer";

export const dashboardInitialState = {
	data: undefined,
	filtersConfig: {
		selectedSegment: "all",
		selectedFilter: {
			dateRange: window.localStorage.getItem("go_report.dashboard_filters.date_range")?.split("=")[1] ?? "today",
		},
		forceApplyDateFilter: true,
		segments: [],
	},
	chartsConfig: {
		reportCategory: {
			type: ChartType.HORIZONTAL_BAR,
			range: "DAY_OF_WEEK" as ChartDataRangeType,
			aggregate: "total_money" as AggregateOption,
		},
		reportPayment: {
			type: ChartType.VERTICAL_BAR,
			range: "DAY_OF_WEEK" as ChartDataRangeType,
			aggregate: "total_money" as AggregateOption,
		},
		reportProduct: {
			type: ChartType.BOX,
			range: "DAY_OF_WEEK" as ChartDataRangeType,
			aggregate: "total_money" as AggregateOption,
		},
		reportSales: {
			type: ChartType.BOX,
			range: "DAY_OF_WEEK" as ChartDataRangeType,
			aggregate: "total_money" as AggregateOption,
		},
		reportSalesAdvanced: {
			type: ChartType.LINE,
			range: "DAY_OF_WEEK" as ChartDataRangeType,
			aggregate: "total_money" as AggregateOption,
		},
	},
};

export const dashboardReducer = (
	{ data, filtersConfig, chartsConfig }: DashboardInitialStateType,
	action: Record<string, any>
): DashboardInitialStateType => ({
	data: dashboardDataReducer(data, action),
	filtersConfig: filtersConfigReducer(filtersConfig, action),
	chartsConfig: chartsConfigReducer(chartsConfig, action),
});
