import React, { useEffect, useState } from "react";

interface Props {
	time: string;
	onChange: (time: string) => void;
}

const parseNumberToTime = (val: number) => {
	if (val < 10) {
		return `0${val}`;
	}
	return val.toString();
};

export const TimePicker = (props: Props): JSX.Element => {
	const [time, setTime] = useState(props.time);
	const buildHours = () => {
		const hours: string[] = [];
		for (let i = 0; i <= 23; i++) {
			hours.push(parseNumberToTime(i));
		}
		return hours;
	};
	const buildMinutes = () => {
		const hours: string[] = [];
		for (let i = 0; i <= 59; i++) {
			hours.push(parseNumberToTime(i));
		}
		return hours;
	};
	const getMinute = (value: string) => {
		return value.split(":")[1];
	};
	const getHour = (value: string) => {
		return value.split(":")[0];
	};
	const onChangeHour = (value: string) => {
		setTime(`${value}:${getMinute(time)}`);
		return `${value}:${getMinute(time)}`;
	};

	const onChangeMinute = (value: string) => {
		setTime(`${getHour(time)}:${value}`);
		return `${getHour(time)}:${value}`;
	};

	useEffect(() => {
		setTime(props.time);
	}, [props.time]);

	return (
		<div style={{ display: "flex" }}>
			<select
				className="form-control"
				value={getHour(time)}
				name="hour"
				onChange={(e) => props.onChange(onChangeHour(e.target.value))}
				data-testid="time-picker-hour"
			>
				{buildHours().map((hour) => {
					return (
						<option key={hour} value={hour}>
							{hour}
						</option>
					);
				})}
			</select>
			<span className="m-1">:</span>
			<select
				className="form-control"
				name="minutes"
				value={getMinute(time)}
				onChange={(e) => props.onChange(onChangeMinute(e.target.value))}
				data-testid="time-picker-minute"
			>
				{buildMinutes().map((minute) => {
					return (
						<option key={minute} value={minute}>
							{minute}
						</option>
					);
				})}
			</select>
		</div>
	);
};
