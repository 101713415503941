import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import { GroupByConfig } from "../../core/components/Table/services/types";

export const groupDataByConfig = (data: any, config?: GroupByConfig): Array<any> => {
	if (!config) {
		return data;
	}
	switch (config.type) {
		case "date":
			return groupByDate(data, config.fieldName);
		default:
			return data;
	}
};

const groupByDate = (data: any, field: string): Record<string, any>[] => {
	let groupedItems: any[] = [];
	const newData = data.reduce((sum: any, item: any) => {
		const groupByVal = item[field].split("T")[0];
		groupedItems = sum.get(groupByVal) || [];
		groupedItems.push(item);
		return sum.set(groupByVal, groupedItems);
	}, new Map());
	return Array.from(newData, ([name, values]) => ({
		name: FormatDate(name, undefined, false, defaultFormatDate),
		values,
	}));
};
