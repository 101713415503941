import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	LiveOrderApi,
	OrderPreparationStatusName,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import PreparationStatusModalOption from "./PreparationStatusModalOption";

interface Props {
	status: OrderPreparationStatusName;
	setLoading: (loading: boolean) => void;
	order: LiveOrderApi;
	setOrder: (order: LiveOrderApi) => void;
	handleSuccessAction: () => void;
}

const PreparationStatusModalOptions = ({
	status,
	order,
	setOrder,
	setLoading,
	handleSuccessAction,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const getPreparationStatusOptions = () => {
		const preparationStatusOptions: OrderPreparationStatusName[] =
			[...organization?.more?.supported_preparation_statuses] || [];

		return preparationStatusOptions.map((option) => ({
			value: option,
			label: t(`enums.preparation_statuses.${option}`),
		}));
	};

	return (
		<div className="row preparation-status-modal-options">
			{getPreparationStatusOptions().map((option) => (
				<div className="col-12 col-md-6" key={option.value}>
					<PreparationStatusModalOption
						order={order}
						setOrder={setOrder}
						setLoading={setLoading}
						option={option}
						handleSuccessAction={handleSuccessAction}
						isSelected={status === option.value}
					/>
				</div>
			))}
		</div>
	);
};

export default PreparationStatusModalOptions;
