import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingEmptyData } from "go-core/components/Loading";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	FiscalizeOrderFormProps,
	PaymentMethodSearchSelectApi,
} from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<FiscalizeOrderFormProps>;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<FiscalizeOrderFormProps>[]>>;
	setPaymentMethodOutflowType: (value: string) => void;
}

const LiveOrderFiscalizationPaymentMethods = ({ form, setErrors, setPaymentMethodOutflowType }: Props): JSX.Element => {
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const {
		setValue,
		watch,
		register,
		formState: { errors },
	} = form;
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodSearchSelectApi[] | undefined>();

	useEffect(() => {
		fetchPaymentMethods();
	}, []);

	const fetchPaymentMethods = async () => {
		try {
			const f = btoa(unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=ENABLED`)));
			const res = await apiOrganization.getPaymentMethodsSearchSelect("", { size: 0, f }, undefined, true);
			setPaymentMethods(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleChangePaymentMethod = (paymentMethod: PaymentMethodSearchSelectApi) => {
		setValue("payment_method_id", Number(paymentMethod.id));
		setPaymentMethodOutflowType(paymentMethod.outflow_type);
	};

	const drawPaymentMethods = () =>
		(paymentMethods || []).map((paymentMethod) => {
			const isSelected = Number(watch("payment_method_id")) === Number(paymentMethod.id);
			return (
				<Button
					key={`payment-method-${paymentMethod.id}`}
					onClick={() => handleChangePaymentMethod(paymentMethod)}
					className={`${isSelected ? "btn-primary" : "btn-light"}`}
				>
					{paymentMethod.label}
				</Button>
			);
		});

	const paymentMethodInputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<div>
			<h5 className="mb-2">{`${t("modules.live_order.field.payment_method.title")} *`}</h5>
			<div className="d-flex flex-wrap gap-2">
				{!paymentMethods ? <LoadingEmptyData /> : drawPaymentMethods()}
			</div>
			<FormInput
				name="payment_method_id"
				errors={errors}
				register={register}
				hidden
				customValidationConfig={paymentMethodInputValidationConfig}
			/>
			<hr />
		</div>
	);
};

export default LiveOrderFiscalizationPaymentMethods;
