import { Location } from "history";
import { parse, stringify } from "query-string";
import { useTranslation } from "react-i18next";
import { ApiError } from "./api/types";

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:common.action.activate");
	t("lib:common.word.from");
	t("lib:common.word.to");
	t("lib:common.action.less");
	t("lib:common.action.disable");
	t("lib:common.word.email");
	t("lib:common.word.tax_id_no.title");
	t("lib:common.word.tax_id_no.search");
	t("lib:common.word.phone_number");
	t("lib:common.word.status");
	t("lib:common.word.advanced");
	t("lib:common.action.import");
	t("lib:common.action.sync");
	t("lib:common.action.reset");
	t("lib:common.action.print");
	t("lib:common.action.apply");
	t("lib:common.action.generate");
	t("lib:common.action.send");
	t("lib:common.action.copy");
	t("lib:common.action.fix_error");
	t("lib:common.word.type");
	t("lib:common.word.source");
	t("lib:common.word.ip_address");
	t("lib:common.word.mac_address");
	t("lib:common.word.date");
	t("lib:common.word.informations");
	t("lib:common.word.number");
	t("lib:common.word.segment");
	t("lib:common.word.users");
	t("lib:common.word.hour");
	t("lib:common.word.settings");
	t("lib:common.word.reference");
};
const parseValue = (value: any): any => {
	if (Array.isArray(value)) {
		return value.map((v) => parseValue(v));
	}

	switch (value) {
		case "true":
			return true;
		case "false":
			return false;
		case "null":
			return null;
	}

	if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
		return value;
		// return moment(value);
	}

	if (/^-?\d{1,6}$/.test(value)) {
		return parseInt(value);
	}

	return value;
};

const stringifyValue = (value: any) => {
	if (value instanceof Date) {
		return value;
		// return moment(value).format();
	}

	return value;
};

export function parseSearchParams(s = document.location.search): Record<string, any> {
	const parsed = parse(s.replace(/(\w+)\[\d+\]/g, "$1[]"), { arrayFormat: "bracket" });

	return Object.keys(parsed).reduce((object, key) => {
		const value = parseValue(parsed[key]);

		return { ...object, [key]: value };
	}, {});
}

const sortQueryParams = (leftParam: string, rightParam: string): number => {
	if (leftParam === "f") {
		return 1;
	}

	if (rightParam === "f") {
		return -1;
	}

	return 0;
};

export function stringifySearchParams(params: Record<string, any>): string {
	const object = Object.keys(params).reduce((newObject, key): any => {
		const value = params[key];
		if (!value) {
			return newObject;
		}
		return { ...newObject, [key]: stringifyValue(value) };
	}, {});
	return stringify(object, { arrayFormat: "index", sort: sortQueryParams });
}

export const delay = (ms: number): Promise<unknown> => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const wrapPromise = (promise: Promise<any>): Record<string, any> => {
	let status = "pending";
	let result: any = undefined;
	const suspender = promise.then(
		(r) => {
			status = "success";
			result = r;
		},
		(e) => {
			status = "error";
			result = e;
		}
	);
	return {
		read() {
			if (status === "pending") {
				throw suspender;
			} else if (status === "error") {
				throw result;
			} else if (status === "success") {
				return result;
			}
		},
	};
};

export function domain(): string {
	return `${window.location.protocol}//${window.location.host}`;
}

export const hasErrorsApi = (errors: ApiError[], name?: string): boolean => {
	if (Array.isArray(errors) && name) {
		const error = errors.find((f) => f.field === name);
		if (error) {
			return true;
		}
	}
	return false;
};
export const getUrl = (location: Location): string => {
	return `${location.pathname}${location.search}`;
};
