import { useEffect, useState } from "react";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { CompanyInfoApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { InvoicePreviousDataApi, JPKInvoiceType } from "../../../services/types";

export const useInvoice = (jpkInvoiceType: JPKInvoiceType) => {
	const [loading, setLoading] = useState(false);
	const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
	const [previousInvoiceData, setPreviousInvoiceData] = useState<InvoicePreviousDataApi | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const { addFlash } = useFlash();

	useEffect(() => {
		if (jpkInvoiceType !== JPKInvoiceType.JPK) {
			fetchCompanyInfo();
		}
		getPreviousFormValues();
	}, []);

	const fetchCompanyInfo = async () => {
		setLoadingCompanyInfo(true);
		try {
			const res = await api.organization().getCompanyInfo();
			setCompanyInfo(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoadingCompanyInfo(false);
	};

	const getPreviousFormValues = async () => {
		try {
			const res = await api.organization().getJPKDetails();
			setPreviousInvoiceData(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const getFileName = (invoiceType: JPKInvoiceType, formData: Record<string, any>): string => {
		switch (invoiceType) {
			case JPKInvoiceType.JPK:
				return `JPK_${formData.date_from}_${formData.date_to}.xml`;
			case JPKInvoiceType.JPK_FA:
				return `JPK_FA_${formData.date_from}_${formData.date_to}.xml`;
			case JPKInvoiceType.JPK_V7:
				return `JPK_v7_${formData.date}.xml`;
			default:
				return "";
		}
	};

	const downloadJpk = (data: any, formData: Record<string, any>) => {
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement("a");
		link.href = url;
		const fileName = getFileName(jpkInvoiceType, formData);
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
	};

	return {
		loading,
		setLoading,
		loadingCompanyInfo,
		companyInfo,
		previousInvoiceData,
		downloadJpk,
		getPreviousFormValues,
	};
};
