import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { selectUser } from "go-security/services/users/selectors";
import { apiSecurity } from "../../services/Api/api";

const UnverifiedEmailNotification: FC = () => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const user = useSelector(selectUser);
	const send = () => {
		setLoading(true);
		apiSecurity
			.user()
			.sendVerifyEmail()
			.then(() => {
				setLoading(false);
				setShowModal(false);
				addSuccessFlash(t("lib:go_security.flash.unverified_email.confirmation_sent"));
			})
			.catch((e: Error) => {
				handleError.alert(e, addFlash);
				setLoading(false);
			});
	};
	if (user?.verified) {
		return null;
	}

	return (
		<>
			{showModal && (
				<Modal show={true} onHide={() => setShowModal(false)}>
					<Modal.Header closeButton>
						<Modal.Title>
							<p className="mb-0">{t("lib:go_security.unverified_email.modal_title")}</p>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							{t("lib:go_security.unverified_email.modal_message")} {user?.email}{" "}
							{t("lib:go_security.unverified_email.modal_message_2")}
							<a href="https://gopos.pl/contact" target="_blank" rel="noreferrer">
								{t("lib:go_security.unverified_email.modal_link_help")}
							</a>
						</p>
					</Modal.Body>
					<Modal.Footer>
						<ButtonLoading loading={loading} variant="primary" type="submit" onClick={send}>
							{t("lib:common.action.send_again")}
						</ButtonLoading>
						<Button variant="secondary" onClick={() => setShowModal(false)}>
							{t("lib:common.action.cancel")}
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			<div className="unverified-email">
				{t("lib:go_security.unverified_email.title")}{" "}
				<a href="#" onClick={() => setShowModal(true)}>
					{t("lib:go_security.unverified_email.title_2")}
				</a>
			</div>
		</>
	);
};
export default UnverifiedEmailNotification;
