export const getNewItemSortingColumns = (columnIdToSortBy: string, currentlySortingColumnsIds?: string[]): string[] => {
	let sortingColumnsIds = currentlySortingColumnsIds ? [...currentlySortingColumnsIds] : [];

	if (sortingColumnsIds.includes(columnIdToSortBy)) {
		sortingColumnsIds.splice(sortingColumnsIds.indexOf(columnIdToSortBy), 1, `-${columnIdToSortBy}`);
	} else if (sortingColumnsIds.includes(`-${columnIdToSortBy}`)) {
		sortingColumnsIds = sortingColumnsIds.filter((sortingColumnId) => sortingColumnId !== `-${columnIdToSortBy}`);
	} else {
		sortingColumnsIds.unshift(columnIdToSortBy);
	}

	return sortingColumnsIds;
};
