import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as DiscountSVG } from "../../../../../../../../../images/svg/bills/discount.svg";
import { ReactComponent as DropdownSVG } from "../../../../../../../../../images/svg/dropdown.svg";
import { OrderApi } from "../../../../../../../../../services/Api/Organization/types";

export interface Props {
	order: OrderApi;
}

const PromotionsCard: FC<Props> = ({ order }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const nestedCellStyles = {
		borderTop: "none",
	};

	const showMore = (index: number) => {
		const item = document.getElementById(`icon-discount-${index}`);
		const elements = document.getElementsByClassName(`toggled-row-discount-${index}`);
		if (item) {
			if (item.style.transform === "rotate(180deg)") {
				item.style.transform = "";
			} else {
				item.style.transform = "rotate(180deg)";
			}
		}
		for (let i = 0; i < elements.length; ++i) {
			const el = document.getElementById(`toggled-row-discount-${index}-${i}`);
			if (el) {
				if (el.style.display === "none") {
					el.style.display = "";
				} else {
					el.style.display = "none";
				}
			}
		}
	};

	const drawDiscountName = (discountType: string, discountName: string, discountValue?: MoneyApi) => {
		if (discountType === discountName || discountName === "FIXED_RATE") {
			if (discountName === "FIXED_RATE" || discountName === "FIXED_PRICE") {
				return t(`modules.order.field.${discountName}.title`, { value: FormatMoney(discountValue) });
			}
			return t(`enums.discounts.type.${discountName}`);
		}
		return discountName;
	};

	const tdStyles = {
		padding: "14px 0px",
	};

	return (
		<Card>
			<Card.Body>
				<h5>{t("common.word.discounts")}</h5>
				<table className="table table-preview">
					<thead>
						<tr>
							<th>{t("lib:common.word.name")}</th>
							<th>{t("common.word.amount")}</th>
						</tr>
					</thead>
					<tbody className={"positions"}>
						{order.promotions.map((item, parentIndex) => {
							return (
								<React.Fragment key={parentIndex}>
									<tr>
										<td className="w-60" style={tdStyles}>
											{item.items && item.items.length > 0 && (
												<DropdownSVG
													id={`icon-discount-${parentIndex}`}
													style={{
														cursor: "pointer",
														minWidth: "10px",
														minHeight: "6px",
													}}
													onClick={() => showMore(parentIndex)}
													className={"me-2"}
												/>
											)}
											<DiscountSVG />{" "}
											{drawDiscountName(
												item.type,
												item.name,
												item.type === "FIXED_PRICE" || item.type === "FIXED_RATE"
													? item.value
													: item?.used
											)}
										</td>
										<td style={tdStyles}>{FormatMoney(item.used)}</td>
									</tr>
									{item.items && item.items.length > 0 && (
										<>
											{item.items.map((subItem, index) => {
												return (
													<tr
														key={subItem.id}
														className={`toggled-row-discount-${parentIndex}`}
														id={`toggled-row-discount-${parentIndex}-${index}`}
														style={{ display: "none" }}
													>
														<td style={{ ...nestedCellStyles, paddingLeft: "18px" }}>
															<div className="d-flex align-items-center">
																<RenderImage
																	data={subItem}
																	imageLink={subItem.image_link}
																/>
																{subItem.item_group_id ? (
																	<Link
																		to={`/${organization.id}/menu/item_groups/${subItem.item_group_id}`}
																		target={"_blank"}
																		rel="noreferrer"
																	>
																		{subItem.name}
																	</Link>
																) : (
																	<>{subItem.name}</>
																)}
															</div>
														</td>
														<td style={{ ...nestedCellStyles, color: "#6C757D" }}>
															{FormatMoney(subItem.used)}
														</td>
													</tr>
												);
											})}
										</>
									)}
								</React.Fragment>
							);
						})}
						<tr className={"summary"}>
							<td></td>
							<td style={{ whiteSpace: "nowrap" }}>
								<strong className={"float-start"}>{t("common.word.sum")}</strong>
								<strong>
									{FormatMoney({
										amount: order.promotions.reduce(
											(acc, val) => acc + (val.used?.amount ? val.used.amount : 0),
											0
										),
										currency: organization.currency || "",
									})}
								</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</Card.Body>
		</Card>
	);
};
export default PromotionsCard;

// t("modules.order.field.FIXED_RATE.title");
// t("modules.order.field.FIXED_PRICE.title");
