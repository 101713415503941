import React, { useRef, useState } from "react";
import { ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useListenEvent } from "go-core/hooks";
import { getFilterName } from "../../../services";
import {
	FilterCustomAction,
	FilterCustomDateRange,
	ListConfigFilter,
	ListSelectedFilter,
} from "../../../services/types";
import ListFilterItem from "../../Item";

interface Props {
	internalFilters: ListSelectedFilter[];
	setInternalFilters: (filters: ListSelectedFilter[]) => void;
	allUniqueParsedFilters: ListConfigFilter[];
	minDate?: Date | string;
	maxDate?: Date | string;
	dateRangePickerCustomActions?: FilterCustomAction[];
	dateRangePickerCustomRanges?: FilterCustomDateRange[];
}

const MobileFiltersFiltersList = ({
	internalFilters,
	allUniqueParsedFilters,
	setInternalFilters,
	minDate,
	maxDate,
	dateRangePickerCustomRanges,
	dateRangePickerCustomActions,
}: Props) => {
	const { t } = useTranslation();
	const [openFilter, setOpenFilter] = useState<string | null>(null);
	const [searchFilter, setSearchFilter] = useState<string>("");
	const filterDropdownRef = useRef<HTMLDivElement>(null);
	const [filterDropdownTopOffset, setFilterDropdownTopOffset] = useState(0);
	const filterOptions = allUniqueParsedFilters.filter((filter) => filter.visible !== false);

	useListenEvent({
		eventName: "resize",
		callback: () => setFilterDropdownTopOffset(filterDropdownRef.current?.getBoundingClientRect()?.bottom || 0),
		shouldRunCallbackOnAdd: true,
	});

	const handleChangeFilterValue = (
		oldSelectedFilter: ListSelectedFilter,
		newSelectedFilter: ListSelectedFilter | null
	) => {
		if (newSelectedFilter === null) {
			if (!oldSelectedFilter.value) {
				setInternalFilters(internalFilters.filter((f) => f.filterId !== oldSelectedFilter.filterId));
			}
			return;
		}
		if (newSelectedFilter.condition !== "u" && newSelectedFilter.condition !== "a") {
			if (!newSelectedFilter.value || newSelectedFilter.value === "") {
				setInternalFilters(internalFilters.filter((f) => f.filterId !== oldSelectedFilter.filterId));
				return;
			}
		}
		const selectedFilter = internalFilters.filter((internalFilter) => internalFilter === oldSelectedFilter)[0];
		if (selectedFilter === undefined) {
			return;
		}
		if (newSelectedFilter.value === undefined) {
			alert("ERROR");
			return;
		}
		const newFilters = [...internalFilters];
		newSelectedFilter = { ...selectedFilter, ...newSelectedFilter };
		newFilters[newFilters.indexOf(selectedFilter)] = newSelectedFilter;
		setInternalFilters(newFilters);
		setSearchFilter("");
	};

	const handleRemoveFilter = (filterIndex: number) => {
		const newFilters = [...internalFilters];
		newFilters.splice(filterIndex, 1);
		setInternalFilters(newFilters);
	};

	const handleAddFilter = ({ filter }: { filter: ListConfigFilter }) => {
		setSearchFilter("");
		const selectedFilter = {} as ListSelectedFilter;
		selectedFilter.filterId = filter.id;
		setOpenFilter(`${filter.id}_${internalFilters.length}`);
		setInternalFilters([...internalFilters, selectedFilter]);
	};

	return (
		<div className="filters-list">
			<div className="filters-list-items mb-3">
				{internalFilters.map((internalFilter, index) => {
					const filter = allUniqueParsedFilters.filter((filter) => filter.id === internalFilter.filterId)[0];

					if (filter === undefined) {
						return null;
					}

					const key = `${filter.id}_${index}`;

					return (
						<ListFilterItem
							id={key}
							key={key}
							onCloseFilter={() => setOpenFilter(null)}
							openFilter={openFilter}
							filter={{
								...filter,
								name: getFilterName(filter, allUniqueParsedFilters),
							}}
							maxDate={maxDate}
							minDate={minDate}
							selectedFilter={internalFilter}
							onChange={handleChangeFilterValue}
							removeFilter={() => handleRemoveFilter(index)}
							dateRangePickerCustomActions={dateRangePickerCustomActions}
							dateRangePickerCustomRanges={dateRangePickerCustomRanges}
						/>
					);
				})}
			</div>
			<Dropdown as={ButtonGroup}>
				<div onClick={() => setSearchFilter("")}>
					<Dropdown.Toggle variant="" className="add">
						{t("lib:common.action.add_filter")}
					</Dropdown.Toggle>
				</div>
				<div ref={filterDropdownRef} className="add-filter-dropdown-container">
					<Dropdown.Menu
						style={{
							overflowY: "auto",
							maxHeight: `calc(100vh - ${filterDropdownTopOffset}px - 8px)`,
						}}
					>
						{filterOptions.length > 10 && (
							<Form.Control
								placeholder={t("lib:common.word.search_filter")}
								type="search"
								value={searchFilter}
								className="mb-2"
								onChange={(e) => setSearchFilter(e.target.value)}
							/>
						)}
						{filterOptions
							.filter((filter) =>
								getFilterName(filter, allUniqueParsedFilters)
									.toLowerCase()
									.includes(searchFilter.toLowerCase())
							)
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((filter) => {
								return (
									<Dropdown.Item
										key={filter.id}
										eventKey={filter.id}
										onClick={() => {
											setSearchFilter("");
											handleAddFilter({ filter });
										}}
									>
										{getFilterName(filter, allUniqueParsedFilters)}
									</Dropdown.Item>
								);
							})}
					</Dropdown.Menu>
				</div>
			</Dropdown>
			<hr />
		</div>
	);
};

export default MobileFiltersFiltersList;
