import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import Header from "go-app/components/Header";
import SubmenuInvoicesJpkNavigation from "./components/SubmenuInvoicesJpkNavigation";
import OrganizationInvoicesJpkIndexPage from "./pages/Index";
import OrganizationInvoicesJpkJpkFAPage from "./pages/JpkFA";
import OrganizationInvoicesJpkJpkV7Page from "./pages/JpkV7";

export const OrganizationInvoicesJPKPage = (props: RouteComponentProps) => {
	const { t } = useTranslation();

	return (
		<>
			<Header title={t("modules.invoice.field.jpk.title")} />
			<SubmenuInvoicesJpkNavigation />
			<Switch>
				<Route path={props.match.url} exact component={OrganizationInvoicesJpkIndexPage} />
				<Route path={`${props.match.url}/jpk_v7`} component={OrganizationInvoicesJpkJpkV7Page} />
				<Route path={`${props.match.url}/jpk_fa`} component={OrganizationInvoicesJpkJpkFAPage} />
			</Switch>
		</>
	);
};
