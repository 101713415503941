import React from "react";
import { get } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiError } from "go-core/api/types";

interface FormErrorMessageAlertApiProps {
	errors: ApiError[];
	type?: string;
	field?: string;
}
export const FormErrorMessageAlertApi = (props: FormErrorMessageAlertApiProps): JSX.Element | null => {
	const errors = props.errors;
	const { t } = useTranslation();
	if (Array.isArray(errors)) {
		const messages = errors.filter(
			(f) =>
				(f.field === undefined && !props.field) ||
				f.field === props.field ||
				(props.field === "all" && !f.field)
		);
		if (messages === undefined || messages.length <= 0) return null;
		return (
			<div className="alert alert-danger">
				{messages.map((message) => (
					<React.Fragment key={`alert-form-${message.code}`}>
						{t(`constraints.${message.code}`, { ns: ["translation", "lib"] })}
						<br />
					</React.Fragment>
				))}
			</div>
		);
	}
	return null;
};
export const FormErrorMessageAlert = (props: { name?: string; errors: any }): JSX.Element | null => {
	const errors = props.errors;
	const name = props.name;
	if (name) {
		const error = get(errors, name);
		if (!error) {
			return null;
		}
		const messages = error.types;
		return (
			<div className="alert alert-danger">
				{Object.entries(messages).map(([type]) => (
					<React.Fragment key={`alert-form-${type}`}>
						{type}
						<br />
					</React.Fragment>
				))}
			</div>
		);
	}
	return null;
};
