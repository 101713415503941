import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { DateRange, DateRangeProps, Range, RangeWithKey } from "react-date-range";
import "react-date-range/dist/styles.css";
// main style file
import "react-date-range/dist/theme/default.css";
import { useTranslation } from "react-i18next";
import { exactlySameDay, formatStringToDate } from "go-core/components/Formatters/FormatDate";
// theme css file
import { ReactComponent as CalendarSVG } from "go-core/images/svg/calendar.svg";
import { FilterCustomAction, FilterDateRange } from "go-list/core/components/Filter/services/types";
import { parsePredefinedRangeToRange, parseRangeToString } from "go-list/utils/daterangeutils";
import { getLocaleForDatePicker, hasErrors } from "../services/utils";
import { FormErrorMessage } from "./FormErrorMessage";
import { TimePicker } from "./TimePicker";

type CustomToggleProps = {
	children?: React.ReactNode;
	onClick?: (event: React.MouseEvent<any, MouseEvent>) => {};
	onChange?: (event: any) => void;
	value?: string;
	errors?: any;
	name?: string;
	id?: string;
	placeholder?: string;
};

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:go_form.date_range_picker.ranges.today");
	t("lib:go_form.date_range_picker.ranges.yesterday");
	t("lib:go_form.date_range_picker.ranges.last_7_days");
	t("lib:go_form.date_range_picker.ranges.last_30_days");
	t("lib:go_form.date_range_picker.ranges.this_month");
	t("lib:go_form.date_range_picker.ranges.previous_month");
	t("lib:go_form.date_range_picker.ranges.custom");
	t("lib:go_form.date_range_picker.ranges.last_3_months");
	t("lib:go_form.date_range_picker.ranges.last_6_months");
	t("lib:go_form.date_range_picker.ranges.last_12_months");
};

const oneDayInMs = 24 * 60 * 60 * 1000;

const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => (
	<>
		<div className={`d-flex flex-grow-1${hasErrors(props.errors, props.name) ? " is-invalid" : ""}`}>
			<label className="d-flex mb-0" aria-label={props.id}>
				<input
					id={props.id}
					type="text"
					autoComplete="off"
					className={`form-control${hasErrors(props.errors, props.name) ? " is-invalid" : ""}`}
					ref={ref}
					style={{ paddingRight: "25px" }}
					onClick={(e) => {
						e.preventDefault();
						if (props.onClick) props.onClick(e);
					}}
					value={props.value}
					onChange={props.onChange}
					placeholder={props.placeholder}
				/>
				<CalendarSVG className="form-input-icon" />
			</label>
		</div>
		<FormErrorMessage errors={props.errors} name={props.name} />
	</>
));

CustomToggle.displayName = "CustomToggle";

export interface PropsRanges {
	selection: RangeWithKey;
}

const defaultRanges: FilterDateRange[] = [
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.today",
		range: parsePredefinedRangeToRange("today"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.yesterday",
		range: parsePredefinedRangeToRange("yesterday"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.last_7_days",
		range: parsePredefinedRangeToRange("last_7_days"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.last_30_days",
		range: parsePredefinedRangeToRange("last_30_days"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.last_3_months",
		range: parsePredefinedRangeToRange("last_3_months"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.last_6_months",
		range: parsePredefinedRangeToRange("last_6_months"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.last_12_months",
		range: parsePredefinedRangeToRange("last_12_months"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.this_month",
		range: parsePredefinedRangeToRange("this_month"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.previous_month",
		range: parsePredefinedRangeToRange("previous_month"),
	},
	{
		checked: false,
		name: "go_form.date_range_picker.ranges.custom",
		range: {
			key: "selection",
		},
	},
];

const getWindowWidth = () => {
	const { innerWidth: width } = window;
	return width;
};

const DateRangePickerInline = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const locale = getLocaleForDatePicker();

	const onChangeInternal = (item: any) => {
		if (props.onChangeRange) props.onChangeRange(item.selection || item.range1 || {});
	};

	const ranges = props.ranges;
	if (ranges && ranges[0]) {
		if (ranges[0].startDate === undefined) {
			const startDate = new Date();
			if (props.timeStart) {
				startDate.setHours(getHour(props.timeStart), getMinute(props.timeStart), 59);
			}
			ranges[0].startDate = startDate;
		}
		if (ranges[0].endDate === undefined) {
			const endDate = new Date();
			if (props.timeEnd) {
				endDate.setHours(getHour(props.timeEnd), getMinute(props.timeEnd), 59);
			}
			ranges[0].endDate = endDate;
		}
	}

	return (
		<>
			<DateRange
				direction={`${getWindowWidth() > 768 ? "horizontal" : "vertical"}`}
				editableDateInputs
				autoFocus={false}
				onChange={onChangeInternal}
				months={2}
				moveRangeOnFirstSelection={false}
				ranges={props.ranges}
				locale={locale}
				minDate={props.minDate}
				maxDate={props.maxDate}
				startDatePlaceholder={t("common.word.start_date", { ns: "lib" }).toString()}
				endDatePlaceholder={t("common.word.end_date", { ns: "lib" }).toString()}
			/>
		</>
	);
};
const getMinute = (value: string) => {
	return Number(value.split(":")[1]);
};
const getHour = (value: string) => {
	return Number(value.split(":")[0]);
};
const parseToTime = (date: Date) => {
	const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
	const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
	const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
	return `${hours}:${minutes}:${seconds}`;
};

interface Props extends DateRangeProps {
	onChangeRange?: (range: Range, predefinedRange?: string) => void;
	range?: Range;
	onApply?: (range?: Range, predefinedRange?: string) => void;
	onClear?: () => void;
	errors?: any;
	name?: string;
	predefineRange?: string;
	onClose?: (state: Range) => void;
	timeStart?: string;
	timeEnd?: string;
	placeholder?: string;
	minDate?: string | Date;
	maxDate?: string | Date;
	customActions?: FilterCustomAction[];
	customRanges?: FilterDateRange[];
}

export const DateRangePickerInlineFull = (props: Props): JSX.Element => {
	const range = props.ranges ? props.ranges[0] : undefined;
	const [ranges, setRanges] = useState(props.customRanges ? props.customRanges : defaultRanges);

	const [timeFrom, setTimeFrom] = useState(range && range.startDate ? parseToTime(range.startDate) : "00:00:00");
	const [timeTo, setTimeTo] = useState(range && range.endDate ? parseToTime(range.endDate) : "23:59:59");
	const locale = getLocaleForDatePicker();
	const onChangeInternal = (newRange: Range, predefinedRange?: string) => {
		if (props.onChangeRange) {
			props.onChangeRange(newRange, predefinedRange);
		}
	};

	const getCustomRangeConfigFromRange = (range: Range) => {
		const customRange = ranges.find(({ range: customRange }) => {
			return (
				customRange?.startDate?.toString() === range?.startDate?.toString() &&
				customRange?.endDate?.toString() === range?.endDate?.toString()
			);
		});

		return customRange;
	};

	useEffect(() => {
		const customRangeConfig = getCustomRangeConfigFromRange(range as Range);

		if (customRangeConfig) {
			onChangeDateInternal(range as Range, customRangeConfig);
		} else {
			uncheckRanges();
		}
	}, []);

	useEffect(() => {
		if (!props.ranges?.[0]) {
			return;
		}

		const { startDate, endDate } = props.ranges[0];

		if (!startDate || !endDate) {
			return;
		}

		const customRangeConfig = getCustomRangeConfigFromRange(props.ranges[0]);

		if (!customRangeConfig) {
			uncheckRanges();
		} else if (timeFrom !== parseToTime(startDate) && timeTo !== parseToTime(endDate)) {
			onChangeDateInternal(customRangeConfig.range as Range, customRangeConfig);
		}
	}, [props.ranges]);

	useEffect(() => {
		if (props.ranges && props.ranges.length > 0) {
			const { startDate, endDate } = props.ranges[0];
			if (startDate && endDate) {
				setTimeFrom(parseToTime(startDate));
				setTimeTo(parseToTime(endDate));
			}
		}
	}, [props.ranges]);

	const onChangeDate = (newRange: Range) => {
		uncheckRanges();
		checkCustomRange();
		if (newRange.startDate && newRange.endDate) {
			const newStartDate = new Date(newRange.startDate.valueOf());
			newStartDate.setHours(getHour(timeFrom), getMinute(timeFrom), 0);
			const newEndDate = new Date(newRange.endDate.valueOf());
			newEndDate.setHours(getHour(timeTo), getMinute(timeTo), 0);
			newRange.startDate = newStartDate;
			newRange.endDate = newEndDate;
		}
		onChangeInternal(newRange, undefined);
	};

	const onChangeTimeInternal = (newRange: Range) => {
		uncheckRanges();
		checkCustomRange();
		if (props.onChangeRange) {
			props.onChangeRange(newRange, undefined);
		}
	};

	const checkCustomRange = () => {
		setRanges((ranges) =>
			ranges.map((range, index) => {
				if (index < ranges.length - 1) {
					return range;
				}

				return {
					...range,
					checked: true,
				};
			})
		);
	};

	const uncheckRanges = () => {
		setRanges((ranges) => ranges.map((range) => ({ ...range, checked: false })));
	};

	const onChangeDateInternal = (newRange: Range, rangeConfig?: any) => {
		setTimeFrom("00:00:00");
		setTimeTo("23:59:59");
		if (newRange.startDate && newRange.endDate) {
			const newStartDate = new Date(newRange.startDate.valueOf());
			newStartDate.setHours(0, 0, 0);
			const newEndDate = new Date(newRange.endDate.valueOf());
			newEndDate.setHours(23, 59, 59);
			newRange.startDate = newStartDate;
			newRange.endDate = newEndDate;
		}
		if (!rangeConfig && rangeConfig.name === t("lib:go_form.date_range_picker.ranges.custom")) {
			uncheckRanges();
			checkCustomRange();
			if (range) {
				newRange = range;
			}
		} else {
			setRanges((ranges) =>
				ranges.map((range) => {
					const areRangesTheSame =
						range.range?.startDate?.toString() === rangeConfig.range?.startDate?.toString() &&
						range.range?.endDate?.toString() === rangeConfig.range?.endDate?.toString();

					if (areRangesTheSame) {
						return {
							...range,
							checked: true,
						};
					}

					return {
						...range,
						checked: false,
					};
				})
			);
		}
		if (newRange?.endDate && newRange?.startDate && props.customRanges) {
			onChangeInternal(newRange);
		} else if (newRange?.endDate && newRange?.startDate) {
			onChangeInternal(newRange, rangeConfig.name.split(".")?.pop());
		}
	};
	const onChangeTimeStartDate = (time: string) => {
		if (range && range.startDate) {
			const newStartDate = new Date(range.startDate.valueOf());
			setTimeFrom(time);
			newStartDate.setHours(getHour(time), getMinute(time), 0);
			range.startDate = newStartDate;
			if (range) {
				onChangeTimeInternal(range);
			}
		}
	};

	const onChangeTimeEndDate = (time: string) => {
		if (range && range.endDate) {
			setTimeTo(time);
			const newEndDate = new Date(range.endDate.valueOf());
			newEndDate.setHours(getHour(time), getMinute(time), 59);
			range.endDate = newEndDate;

			if (range) {
				onChangeTimeInternal(range);
			}
		}
	};

	const { t } = useTranslation();

	const onApply = () => {
		if (props.onApply) {
			props.onApply();
		}
	};

	const onClear = () => {
		if (props.onChangeRange) {
			props.onChangeRange({
				startDate: undefined,
				endDate: undefined,
				key: "selection",
			});
		}
		if (props.onClear) {
			props.onClear();
		}
	};

	return (
		<div className="daterangepicker" data-testid="range-date-picker">
			<div className="ranges">
				<ul className="list-unstyled">
					{ranges
						.filter((x) => x.range)
						.map((range) => {
							return (
								<li
									className={`range-label${range.checked ? " active" : ""}`}
									key={range.name}
									onClick={() => {
										onChangeDateInternal(range.range as Range, range);
									}}
								>
									{t(`lib:${range.name}`)}
								</li>
							);
						})}
				</ul>
			</div>
			<div className="date-range-picker-wrapper">
				<DateRangePickerInline
					timeEnd={props.timeEnd}
					timeStart={props.timeStart}
					ranges={props.ranges}
					onChangeRange={onChangeDate}
					locale={locale}
					minDate={props.minDate}
					maxDate={props.maxDate}
				/>
				<div className="d-flex time-pickers">
					<div className={"time-picker"}>
						<TimePicker time={timeFrom} onChange={onChangeTimeStartDate} />
					</div>
					<div className={"time-picker"}>
						<TimePicker time={timeTo} onChange={onChangeTimeEndDate} />
					</div>
				</div>
				<div className="calendar-actions">
					<Button className="calendar-btn btn-confirm" onClick={onApply}>
						{t("lib:common.action.confirm")}
					</Button>
					<Button className="calendar-btn" onClick={onClear}>
						{t("lib:common.action.clear")}
					</Button>
					{props.customActions && props.customActions.map((action) => action.button)}
				</div>
			</div>
		</div>
	);
};

const isValueDateFormatStrictCorrect = (value: any) => {
	const validDateRegexOne = /\d{2}[-]\d{2}[-]\d{4},\s\d{2}:\d{2}\s-\s\d{2}[-]\d{2}[-]\d{4},\s\d{2}:\d{2}/;
	const validDateRegexTwo = /\d{2}[.]\d{2}[.]\d{4},\s\d{2}:\d{2}\s-\s\d{2}[.]\d{2}[.]\d{4},\s\d{2}:\d{2}/;
	const validDateRegexThree = /\d{2}[/]\d{2}[/]\d{4},\s\d{2}:\d{2}\s-\s\d{2}[/]\d{2}[/]\d{4},\s\d{2}:\d{2}/;
	return validDateRegexOne.test(value) || validDateRegexTwo.test(value) || validDateRegexThree.test(value);
};

const isValueDateFormatMixedCorrect = (value: any) => {
	const validDateRegex = /\d{2}[-./]\d{2}[-./]\d{4},\s\d{2}:\d{2}\s-\s\d{2}[-./]\d{2}[-./]\d{4},\s\d{2}:\d{2}/;
	return validDateRegex.test(value);
};

export const DateRangePicker = (props: Props): JSX.Element => {
	const [state, setState] = useState<Range>(
		props.range
			? { ...props.range }
			: {
					startDate: undefined,
					endDate: undefined,
					key: "selection",
			  }
	);
	const [value, setValue] = useState(parseRangeToString(state));
	const [predefinedRange, setPredefinedRange] = useState<string | undefined>(props.predefineRange);
	useEffect(() => {
		if (value) handleDataParsingToState();
		else {
			setState((state) => ({
				...state,
				startDate: undefined,
				endDate: undefined,
			}));
		}
	}, [value]);

	useEffect(() => {
		if (props.range) {
			setState(props.range);
		}
	}, [props.range]);

	const onChangeInternal = (newRange: Range, predefinedRange?: string) => {
		const { startDate, endDate } = newRange;
		const rangeDatesPointAtTheSameDay = exactlySameDay(startDate, endDate);
		if (rangeDatesPointAtTheSameDay) {
			const startDateHour = startDate?.getHours();
			const startDateMinute = startDate?.getMinutes();
			const endDateHour = endDate?.getHours();
			const endDateMinute = endDate?.getMinutes();

			if (
				startDateHour === undefined ||
				startDateMinute === undefined ||
				endDateHour === undefined ||
				endDateMinute === undefined
			)
				return;

			const hourDateToIsLessThanHourDateFrom = endDateHour < startDateHour;
			const hourDateToEqualsHourDateFrom = endDateHour === startDateHour;
			const minuteDateToIsLessThanHourDateFrom = endDateMinute < startDateMinute;
			const shouldSetEndDateToNextDay =
				hourDateToIsLessThanHourDateFrom ||
				(hourDateToEqualsHourDateFrom && minuteDateToIsLessThanHourDateFrom);

			if (shouldSetEndDateToNextDay && endDate) {
				newRange.endDate = new Date(endDate.getTime() + oneDayInMs);
			}
		}

		setPredefinedRange(predefinedRange);
		setState(newRange);
		setValue(parseRangeToString(newRange));
		props?.onChangeRange && props.onChangeRange(newRange, predefinedRange);
	};

	useEffect(() => {
		if (!isValueDateFormatMixedCorrect(value)) return;
		setValue(parseRangeToString(state));
	}, []);

	const handleDataParsingToState = () => {
		if (!isValueDateFormatStrictCorrect(value)) return;

		const dateStrings = value.split(" - ");
		const [fromDateString, toDateString] = dateStrings;
		const fromDate = formatStringToDate(fromDateString);
		const toDate = formatStringToDate(toDateString);
		setState((state) => ({
			...state,
			startDate: fromDate,
			endDate: toDate,
		}));
	};

	const onApply = () => {
		const doc = document.getElementById(`dropdown-daterange-${props.name}`);
		if (doc) {
			doc.click();
		}
		if (props.onApply) {
			props.onApply(state, predefinedRange);
		}
	};

	const onClear = () => {
		const doc = document.getElementById(`dropdown-daterange-${props.name}`);
		if (doc) {
			doc.click();
		}
		if (props.onClear) {
			props.onClear();
		}
	};

	const handleValueChange = (event: any) => {
		const inputValue: string = event.target.value;
		if (inputValue.length > 37) return;

		const correctChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", " ", ",", ".", "-", ":"];
		const inputValueAsArray = inputValue.split("");
		const inputValueAsArrayWithCorrectChars = inputValueAsArray.filter((char) => correctChars.includes(char));
		const newInputValue = inputValueAsArrayWithCorrectChars.join("");

		setValue(newInputValue);
		setPredefinedRange(undefined);
	};
	return (
		<div>
			<Dropdown>
				<Dropdown.Toggle
					as={CustomToggle}
					value={value}
					id={`dropdown-daterange-${props.name}`}
					placeholder={props.placeholder}
					onChange={handleValueChange}
				></Dropdown.Toggle>

				<div className="dropdown-filter">
					<Dropdown.Menu style={{ paddingLeft: 0 }}>
						<DateRangePickerInlineFull
							onChangeRange={onChangeInternal}
							onApply={onApply}
							onClear={onClear}
							timeStart={props.timeStart}
							timeEnd={props.timeEnd}
							ranges={[state]}
							minDate={props.minDate}
							maxDate={props.maxDate}
							customActions={props.customActions}
							customRanges={props.customRanges}
						/>
					</Dropdown.Menu>
				</div>
			</Dropdown>
		</div>
	);
};
