import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormCheck, FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ModifierGroupApi, TranslationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import ModifierGroupOptionsForm from "./ModifierGroupOptionsForm";

interface Props {
	modifierGroup: ModifierGroupApi;
	modifierGroupTranslations?: TranslationApi[];
}

const ModifierGroupForm = (props: Props): JSX.Element => {
	const form = useForm<ModifierGroupApi>({
		criteriaMode: "all",
		defaultValues: props.modifierGroup,
		shouldUnregister: false,
	});
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		formState,
		reset,
		setError,
		setValue,
		control,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	useEffect(() => {
		props.modifierGroupTranslations?.forEach((translation, index) => {
			const prefix = `translations.${index}`;
			registerObject(register, prefix, ["locale", "name"]);
			setValue(`translations.${index}`, translation);
		});
	}, [props.modifierGroupTranslations]);

	const onSubmit = handleSubmit(async (data: ModifierGroupApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		const params: Record<string, any> = {
			include: "options,options.item,options.quantity_info,options.price,translations",
		};
		data.translations = data.translations?.filter((f: { name: string }) => f.name.length > 0);
		data.id = props.modifierGroup.id;
		setLoading(true);
		try {
			if (data.id) {
				const res = await api.organization().updateModifierGroup(data, params);
				setLoading(false);
				reset(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createModifierGroup(data);
				history.push(`/${organization.id}/menu/modifier_groups/${res.id}`);
				setLoading(false);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
			handleError.alert(e, addFlash);
		}
	});

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch: form.watch },
		}),
		[setErrors, form.watch]
	) satisfies CustomValidationConfig;

	return (
		<FormDirty formState={formState} loading={loading} key="modifier-group-form" noValidate onSubmit={onSubmit}>
			<fieldset className="form-group no-gutters">
				<FormInput
					label={t("lib:common.word.name")}
					register={register}
					name="name"
					errors={errors}
					customValidationConfig={nameValidationConfig}
				/>
				<div className={"row"}>
					<div className="col-md-4">
						<FormNumberInput
							errors={errors}
							name="quantity_info.min_permitted"
							suffix=""
							decimalScale={0}
							control={control}
							onChange={() => clearErrors(["quantity_info.max_permitted", "quantity_info"])}
							label={t("modules.modifier_group.field.min_permitted.title")}
							maxPermittedAmountOfDigits={3}
						/>
					</div>
					<div className="col-md-4">
						<FormNumberInput
							errors={errors}
							name="quantity_info.max_permitted"
							suffix=""
							decimalScale={0}
							control={control}
							label={t("modules.modifier_group.field.max_permitted.title")}
							onChange={() => clearErrors(["quantity_info.min_permitted", "quantity_info"])}
							maxPermittedAmountOfDigits={3}
						/>
					</div>
					<div className="col-md-4">
						<FormNumberInput
							errors={errors}
							name="quantity_info.charge_above"
							suffix=""
							decimalScale={0}
							control={control}
							label={t("modules.modifier_group.field.charge_above.title")}
							onChange={() => clearErrors(["quantity_info.charge_above", "quantity_info"])}
							maxPermittedAmountOfDigits={3}
						/>
					</div>
				</div>
				<ModifierGroupOptionsForm form={form} modifierOptions={props.modifierGroup.options} />
			</fieldset>
			<fieldset className={"form-group"}>
				<h5 className={"mb-3"}>{t("common.word.advanced", { ns: "lib" })}</h5>
				<LimitComponentVisibility
					defaultVisible={props.modifierGroup.split}
					id={"modifier_split"}
					helpText={t("modules.modifier_group.field.split.helptext.description")}
					legendText={t("modules.modifier_group.field.split.title")}
					handleChange={(visible) => {
						setValue("split", visible ? visible : false);
					}}
					unlimitedContent={
						<FormCheck
							name="equal_pricing"
							errors={errors}
							register={register}
							type="switch"
							label={t(
								"modules.modifier_group.action.calculate_price_based_on_most_expensive_element.title"
							)}
						/>
					}
				/>
			</fieldset>
		</FormDirty>
	);
};
export default ModifierGroupForm;
