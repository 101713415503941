import React from "react";
import { AxiosResponse } from "axios";
import { Flash } from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { UnprocessableEntity } from "go-core/api/exceptions";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import ParametersSecurity from "../go-security/services";
import { SegmentType } from "./components/types";
import {
	CustomFieldResource,
	CustomFieldTemplateApi,
	ListInfoApi,
	SegmentApi,
	SegmentContextProps,
	UpdateSegmentApi,
} from "./services/types";

export class SegmentListService {
	apiSegment: SegmentContextProps;
	listInfo: (params?: Record<string, any>) => Promise<AxiosResponse>;
	segmentPath: string;
	addFlash: (_: Flash) => void;

	constructor(
		apiSegment: SegmentContextProps,
		segmentPath: string,
		listInfo: (params?: Record<string, any>) => Promise<AxiosResponse>,
		addFlash: (_: Flash) => void
	) {
		this.apiSegment = apiSegment;
		this.listInfo = listInfo;
		this.segmentPath = segmentPath;
		this.addFlash = addFlash;
	}

	save(name: string, type: string, segment: SegmentType): Promise<any> {
		let value = decodeURIComponent(escape(atob(segment.value_v2 || "")));
		value = decodeURIComponent(value);
		const urlParams = new URLSearchParams(value);
		const f = urlParams.get("f") || "";
		let decodedF;
		try {
			decodedF = decodeURIComponent(escape(atob(f)));
		} catch (e) {
			return new Promise(() => {
				throw new UnprocessableEntity({
					errors: [
						{
							field: "all",
							message: "Invalid segment value",
							code: "invalid_segment_value",
						},
					],
				});
			});
		}

		const finalF = btoa(unescape(encodeURIComponent(decodeURIComponent(decodedF))));
		urlParams.set("f", finalF);
		value = btoa(urlParams.toString());
		const segmentApi = {
			id: segment.id ? Number(segment.id) : undefined,
			name: segment.name,
			value,
			resource_type: type,
			list_name: ParametersSecurity.getAppName() === "GoStock" ? `V2_${name}` : name,
			default: segment.default ?? false,
		} as SegmentApi;
		if (segment.id) {
			return this.apiSegment.updateSegment(segmentApi);
		}
		return this.apiSegment.saveSegment(segmentApi);
	}

	find(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return this.apiSegment.getSegments(params, options);
	}

	remove(segmentId: number): Promise<AxiosResponse> {
		return this.apiSegment.removeSegment(segmentId);
	}

	restore(segment: SegmentApi): Promise<AxiosResponse> {
		return this.apiSegment.restoreSegment(segment);
	}

	setAsDefault(segmentId: number): Promise<AxiosResponse> {
		return this.apiSegment.setAsDefault(segmentId);
	}

	setNotDefault(segmentId: number): Promise<AxiosResponse> {
		return this.apiSegment.setNotDefault(segmentId);
	}

	updateSegments(data: UpdateSegmentApi, params?: Record<string, any>): Promise<AxiosResponse> {
		return this.apiSegment.updateSegments(data, params);
	}

	async get(
		name: string,
		type: string,
		customFieldResourceTypes?: CustomFieldResource[],
		newParams?: Record<string, any>,
		encodedFilters?: string
	): Promise<ListInfoApi> {
		const urlSearchParams = new URLSearchParams(window.location.search);
		let params: Record<string, any> = {};
		if (newParams)
			params = {
				...params,
				...newParams,
			};
		params.list_name = name;
		if (ParametersSecurity.getAppName() === "GoStock") params.list_name = `V2_${params.list_name}`;

		params.list_resource_type = type;
		const resourceTypes: string[] = [];

		if (customFieldResourceTypes) {
			customFieldResourceTypes.forEach((customFieldResourceType) => {
				if (customFieldResourceType && customFieldResourceType.type) {
					resourceTypes.push(customFieldResourceType.type);
				}
			});
		}
		params.custom_field_resource_type = resourceTypes.join(",");

		try {
			params.filter = !encodedFilters
				? decodeURIComponent(escape(atob(urlSearchParams.get("f") || "")))
				: encodedFilters;
		} catch (err) {
			params.filter = "";
		}

		params.filter = decodeURIComponent(params.filter);
		const filterPrefix = params.filter.startsWith(NEW_WAY_TO_ENCODING_FILTER_SIGN)
			? ""
			: NEW_WAY_TO_ENCODING_FILTER_SIGN;
		params.filter = btoa(unescape(encodeURIComponent(filterPrefix + params.filter)));

		try {
			const response = await this.listInfo(params);
			const cfs: any[] = [];

			response.data.data.custom_fields?.forEach((cf: CustomFieldTemplateApi) => {
				const cfOptions: string[] = [];
				cf.options.forEach((opt) => {
					cfOptions.push(opt.value);
				});

				const filteredResources = cf?.resources?.filter((resource) => {
					const customFieldResourceType = customFieldResourceTypes?.find(
						(customFieldResourceType) => customFieldResourceType?.type === resource.resource
					);
					if (customFieldResourceType) return resource;
					return undefined;
				});

				const resources = filteredResources?.map((resource) => {
					const customFieldResourceType = customFieldResourceTypes?.find(
						(customFieldResourceType) => customFieldResourceType?.type === resource.resource
					);
					return {
						...resource,
						name: customFieldResourceType?.name,
					};
				});

				cfs.push({
					id: `+${cf.slug}`,
					name: cf.name,
					...(cf.options && cf.options.length > 0
						? {
								options: cfOptions,
								type: "list",
						  }
						: { type: cf.type.toLowerCase() }),
					resources: [...resources],
					filterByListOptionValue: true,
				});
			});
			return {
				segments: response.data.data.segments,
				fields: cfs,
				filter_values: response.data.data.filter_values,
			};
		} catch (err) {
			handleError.alert(err, this.addFlash);
			return {
				segments: [],
				fields: [],
				filter_values: [],
			};
		}
	}

	api() {
		return this.apiSegment;
	}

	getPath(): string {
		return this.segmentPath;
	}
}

export const SegmentContext = React.createContext<SegmentListService>({} as SegmentListService);
