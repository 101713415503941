import React from "react";
import { Route, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import OrganizationLiveOrdersListIndexPage from "./pages/Index";

const OrganizationLiveOrdersListPage = (): JSX.Element => {
	return (
		<Switch>
			<Route component={OrganizationLiveOrdersListIndexPage} />
			<Route component={Page404} />
		</Switch>
	);
};

export default OrganizationLiveOrdersListPage;
