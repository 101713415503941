import React, { FC, ReactNode, useEffect } from "react";
import { Location } from "history";
import { Dropdown, Nav } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import SubNavigation from "./SubNavigation";
import SubNavigationItemWrapper from "./SubNavigationItemWrapper";

export interface SubNavigationItemProps {
	title: string;
	route: string;
	subtitle?: string;
	hide?: boolean;
	divider?: boolean;
	isActiveRoute?: (match: Record<string, any> | null, location: Location) => boolean;
	icon?: JSX.Element;
	children?: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface SubNavigationDividerProps extends SubNavigationItemProps {
	key: number;
}

interface Props {
	title: string;
	subtitle?: string;
	items: SubNavigationItemProps[];
	activeRoute?: string | undefined;
}

const mapItems = (items: SubNavigationItemProps[]): (SubNavigationItemProps | SubNavigationDividerProps)[] => {
	return items.map((item, index) =>
		item.divider
			? {
					divider: true,
					key: index,
					title: "",
					route: "divider",
			  }
			: item
	);
};
const SubNavigationItems: FC<Props> = (props) => {
	const location = useLocation();
	const parsedItems = mapItems(props.items);
	const items = parsedItems.filter((x) => !x?.hide);
	const history = useHistory();

	useEffect(() => {
		shouldRedirectToFirstNotHiddenItem();
	}, []);

	const shouldRedirectToFirstNotHiddenItem = () => {
		const filteredItems = parsedItems.filter((item) => item.route);
		const currentItem = filteredItems
			// sort items from longest to shortest to match longest path first
			.sort((item1, item2) => item2.route.length - item1.route.length)
			.find((item) => item.route === location.pathname || location.pathname.includes(item.route));
		if (currentItem && currentItem.hide) redirectToFirstNotHiddenItem();
	};

	const redirectToFirstNotHiddenItem = () => {
		const notHiddenItem = parsedItems.find((item) => !item.hide);
		if (notHiddenItem) {
			history.push(`${notHiddenItem.route}`);
		}
	};

	const getRouteName = () => {
		const item = items.filter((x) => {
			if (x.isActiveRoute) return x.isActiveRoute({ url: x.route }, location);
			return location.pathname.includes(x.route);
		})[0];
		if (item) return item.title;
		return props.title;
	};
	return (
		<SubNavigation title={props.title} subtitle={props.subtitle} getRouteName={getRouteName}>
			{items.length > 0 && (
				<div className="navigation-body">
					<Nav variant="pills" className="flex-column">
						{items.map((item) => {
							if (item.divider) {
								return <Dropdown.Divider key={(item as SubNavigationDividerProps).key} />;
							}

							return (
								<Nav.Item key={item.route}>
									{item.isActiveRoute ? (
										<Nav.Link
											as={() => (
												<NavLink
													className={`nav-link`}
													isActive={item.isActiveRoute}
													to={item.route}
												>
													<SubNavigationItemWrapper
														title={item.title}
														subtitle={item?.subtitle}
														icon={item?.icon}
													/>
												</NavLink>
											)}
										/>
									) : (
										<>
											{props.activeRoute ? (
												<Nav.Link
													as={() => (
														<Link
															className={`nav-link ${
																(props.activeRoute
																	? props.activeRoute === item.route
																	: false) && "active"
															}`}
															to={item.route}
														>
															<SubNavigationItemWrapper
																title={item.title}
																subtitle={item?.subtitle}
																icon={item?.icon}
															/>
														</Link>
													)}
												/>
											) : (
												<>
													<Nav.Link as={NavLink} to={item.route} onClick={item.onClick}>
														<div className="d-flex flex-column w-100">
															<SubNavigationItemWrapper
																title={item.title}
																subtitle={item?.subtitle}
																icon={item?.icon}
															/>
														</div>
													</Nav.Link>
													{item.children}
												</>
											)}
										</>
									)}
								</Nav.Item>
							);
						})}
					</Nav>
				</div>
			)}
		</SubNavigation>
	);
};

export default SubNavigationItems;
