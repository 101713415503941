import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { PointOfSaleApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../../../utils/entityStatus/entityStatus";
import SubmenuRoomsNavigation from "../../../../components/SubmenuRoomsNavigation";
import PointOfSaleModalForm from "../../components/PointOfSaleModalForm";

interface ListState {
	resource?: Record<string, any>;
	updatePointOfSale: (item: PointOfSaleApi, items: PointOfSaleApi[]) => void;
	responseData?: PointOfSaleApi[] | undefined;
	createPointOfSale: () => void;
	mobileActions: MobileActionProps[];
}

const listName = "POINT_OF_SALE";
const resourceType = "POINT_OF_SALE";

const List: FC<ListState> = ({ resource, updatePointOfSale, responseData, createPointOfSale, mobileActions }) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<PointOfSaleApi[]>([]);
	const organization = useSelector(selectOrganization);
	const [params, setParams] = useState<Record<string, any>>({});
	const confirmation = useConfirmation();
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		if (responseData) {
			setItems(responseData);
		}
	}, [responseData]);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRestorePointOfSale = async (item: PointOfSaleApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restorePointOfSale(item.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemovePointOfSale = async (item: PointOfSaleApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removePointOfSale(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};
	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: PointOfSaleApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<RenderLimitedText
								minWidth={200}
								onClick={() => updatePointOfSale(item, items)}
								textStyleIsLink
							>
								{item.name}
							</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: PointOfSaleApi) => item.name,
			},
			{
				id: "default_direction",
				name: t("modules.point_of_sale.field.default_direction.title"),
				type: "search_select",
				render: (item: PointOfSaleApi) => {
					return item.default_direction?.name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getDirectionsSearchSelect(
							search,
							{
								page: 1,
							},
							{
								cancelToken: options?.token,
							}
						),
				} as ListFilterSource,
			} as ListConfigField,
			{
				id: "id",
				name: "Id",
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: PointOfSaleApi) => {
					updatePointOfSale(item, items);
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: PointOfSaleApi) => {
					onRemovePointOfSale(item);
				},
				visible: (item: PointOfSaleApi) => item.status !== "DELETED",
			},
			{
				name: t("common.action.restore", { ns: "lib" }),
				click: (item: PointOfSaleApi) => {
					onRestorePointOfSale(item);
				},
				visible: (item: PointOfSaleApi) => item.status === "DELETED",
			},
		],
		selectedColumns: ["name", "default_direction"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		exportConfig: {
			title: t("modules.point_of_sale.field.export_config.title"),
			filename: t("modules.point_of_sale.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "default_direction";
			setParams(fetchParams);
			return api.organization().getPointOfSales(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{
				addAction: () => createPointOfSale(),
			}}
			onFetch={(fetchItems: PointOfSaleApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationSettingsRoomsPointOfSalesIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const [pointOfSale, setPointOfSale] = useState<PointOfSaleApi | undefined>(undefined);
	const [responseData, setResponseData] = useState<PointOfSaleApi[] | undefined>(undefined);
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("common.word.points_of_sale"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const onUpdatePointOfSale = (pos: PointOfSaleApi, items: PointOfSaleApi[]) => {
		setPointOfSale(pos);
		setResponseData(items);
	};

	const handleSave = async (pos: PointOfSaleApi) => {
		setPointOfSale(undefined);
		if (responseData) {
			const index = responseData?.findIndex((dataItem) => dataItem.id === pos.id);
			if (index !== -1 && responseData) {
				responseData[index] = pos;
				setResponseData([...responseData]);
			} else {
				setResponseData([...responseData, pos]);
			}
		} else {
			const res = await api.organization().getPointOfSales();
			setResponseData(res);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setPointOfSale({} as PointOfSaleApi),
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setPointOfSale({} as PointOfSaleApi),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.room.header.title")} buttons={buttons} />}
			<SubmenuRoomsNavigation />
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					updatePointOfSale={onUpdatePointOfSale}
					responseData={responseData}
					createPointOfSale={() => setPointOfSale({} as PointOfSaleApi)}
					mobileActions={mobileActions}
				/>
				{pointOfSale && (
					<PointOfSaleModalForm
						pointOfSale={pointOfSale}
						onHide={() => setPointOfSale(undefined)}
						handleSave={handleSave}
					/>
				)}
			</Suspense>
		</>
	);
};

export default OrganizationSettingsRoomsPointOfSalesIndexPage;
