import React, { useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../../images/svg/collapse.svg";
import { VenueSettingsApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import KioskSettingsColorsCard from "./KioskSettingsColorsCard";
import KioskSettingsImagesCard from "./KioskSettingsImagesCard";
import KioskSettingsTextsCard from "./KioskSettingsTextsCard";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	search: string;
}

const KioskSettingsCard = ({ search, form }: Props) => {
	const { t } = useTranslation();
	const [collapseIn, setCollapseIn] = useState(false);

	const onCollapse = () => {
		const icon = document.getElementById("open-icon");
		if (icon) {
			icon.style.transform = !collapseIn ? "rotate(180deg)" : "";
		}
		setCollapseIn(!collapseIn);
	};

	useEffect(() => {
		if (search.length > 0 && !collapseIn) {
			onCollapse();
		}
	}, [search]);

	return (
		<Card className="venue-settings kiosk-venue-settings">
			<Card.Body>
				<div className="title" onClick={() => onCollapse()}>
					<h5>{t("modules.venue_settings.field.kiosk_settings.title")}</h5>
					<CollapseSVG id="open-icon" className="collapse-icon" />
				</div>
				<Collapse in={collapseIn}>
					<div>
						<KioskSettingsColorsCard form={form} collapseIn={collapseIn} />
						<KioskSettingsTextsCard form={form} />
						<KioskSettingsImagesCard form={form} />
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};

export default KioskSettingsCard;
