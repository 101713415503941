import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../images/svg/virtualTable/remove.svg";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationListFormProps,
	TranslationApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn } from "../../../../../components/VirtualTable/types";
import {
	getVirtualTableColumnStyles,
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../components/VirtualTable/utils";
import ChangeTranslationDescriptionModal from "./ChangeTranslationDescriptionModal/ChangeTranslationDescriptionModal";
import TranslationVirtualTableInput from "./TranslationVirtualTableInput";

interface Props {
	columnWidths: Record<string, number>;
	style: Record<string, any>;
	item: ItemTranslationItemNameFormApi;
	locales: ItemTranslationItemNameTranslationFormApi[];
	form: UseFormReturn<ItemTranslationListFormProps>;
	defaultColumns: VirtualTableColumn[];
	maxZIndex: number;
}

const parseItemTranslationsToItemLocales = (
	translations: TranslationApi[],
	locales: ItemTranslationItemNameTranslationFormApi[]
) => {
	const itemLocales: { id: string; value: string }[] = [];

	locales.forEach((locale) => {
		const localeId = locale.id.split("_")[0];
		const itemTranslation = translations.find((translation) => translation.locale === localeId);
		if (itemTranslation) {
			itemLocales.push({
				id: itemTranslation.locale,
				value: itemTranslation?.name || "",
			});
		} else {
			itemLocales.push({
				id: localeId,
				value: "",
			});
		}
	});
	return itemLocales;
};

const TranslationsVirtualTableRow = ({
	form,
	locales,
	item,
	defaultColumns,
	maxZIndex,
	columnWidths,
	style,
}: Props) => {
	const organization = useSelector(selectOrganization);
	const [showChangeDescriptionModal, setShowChangeDescriptionModal] = useState(false);
	const [localeToChange, setLocaleToChange] = useState<ItemTranslationItemNameTranslationFormApi | undefined>();
	const { setValue, watch } = form;
	const styleTr = { ...style };
	if (styleTr.width !== undefined) {
		styleTr.width = undefined;
	}
	const [itemName, setItemName] = useState<string>(item.name);
	const [itemPrintingName, setItemPrintingName] = useState<string>(item?.printing_name || "");
	const [itemLocales, setItemLocales] = useState<{ value: string; id: string }[]>(
		parseItemTranslationsToItemLocales(
			item.translations,
			locales.filter((locale) => !locale.id.includes("description"))
		)
	);

	const getColumnStyles = (key: string, index?: number) => {
		return getVirtualTableColumnStyles(key, columnWidths, maxZIndex, index);
	};

	const handleClearDescriptionInput = (locale?: ItemTranslationItemNameTranslationFormApi) => {
		if (locale) {
			setValue(`items.${item.index}.translations.${locale.index}.description`, "");
		} else {
			setValue(`items.${item.index}.description`, "");
		}
	};

	const renderDefaultColumnCells = () => {
		let prevLeftPosition = 0;
		return defaultColumns.map((col, index) => {
			const prevColumn = defaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const lastColumn = index === defaultColumns.length - 1;
			const isHide = isVirtualTableColumnHide(col.id, defaultColumns);
			const className = `${isHide ? "d-none" : ""} sticky-column ${lastColumn ? "with-wider-border" : ""}`;
			const style = {
				...getColumnStyles(col.id, index),
				left: leftPosition,
				position: "sticky",
			} as React.CSSProperties;

			if (col.id === "category_name")
				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="name-box" title={item.category_name}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/categories/${item.category_id}`}
							>
								{item.category_name}
							</Link>
						</div>
					</td>
				);
			if (col.id === "item_group_name")
				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="name-box" title={item.item_group_name}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/item_groups/${item.item_group_id}`}
							>
								{item.item_group_name}
							</Link>
						</div>
					</td>
				);
			if (col.id === "item_full_name") {
				const name = itemName !== item.name ? `${item.item_group_name} ${itemName}` : item.item_full_name;

				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="name-box" title={name}>
							{name}
						</div>
					</td>
				);
			}
			if (col.id === "name")
				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="input-box" title={itemName}>
							<TranslationVirtualTableInput
								item={item}
								form={form}
								name={`items.${item.index}.name`}
								handleClear={() => handleClearDefaultColumnInput("name")}
								handleOnChange={setItemName}
							/>
						</div>
					</td>
				);
			if (col.id === "description") {
				const value = watch(`items.${item.index}.description`);
				return (
					<td
						key={`${item.index}-${col.id}`}
						className={className}
						style={{ ...style, cursor: "pointer" }}
						onClick={() => {
							setShowChangeDescriptionModal(true);
						}}
					>
						<div className="name-box name-box-with-icon" title={value}>
							{value}
						</div>
						{value && (
							<RemoveSVG
								style={{ cursor: "pointer" }}
								className="absolute-remove-icon"
								onClick={() => handleClearDescriptionInput()}
							/>
						)}
					</td>
				);
			}
			if (col.id === "printing_name") {
				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="input-box" title={itemPrintingName}>
							<TranslationVirtualTableInput
								item={item}
								form={form}
								name={`items.${item.index}.printing_name`}
								handleClear={() => handleClearDefaultColumnInput("printing_name")}
								handleOnChange={setItemPrintingName}
							/>
						</div>
					</td>
				);
			}
			return <></>;
		});
	};

	const handleClearLocaleColumnInput = (index: number, isDescription?: boolean) => {
		if (isDescription) {
			setValue(`items.${item.index}.translations.${index}.description`, "");
		} else {
			setValue(`items.${item.index}.translations.${index}.name`, "");
		}
	};

	const handleClearDefaultColumnInput = (fieldName: "printing_name" | "description" | "name") => {
		setValue(`items.${item.index}.${fieldName}`, "");
	};

	const handleChangeLocaleName = (id: string, value: string) => {
		const localeToUpdate = itemLocales.find((itemLocale) => itemLocale.id === id);
		if (!localeToUpdate) return;

		const index = itemLocales.indexOf(localeToUpdate);
		const currentItemLocales = [...itemLocales];

		currentItemLocales[index] = {
			...localeToUpdate,
			value,
		};
		setItemLocales([...currentItemLocales]);
	};

	const renderLocaleColumnCells = () => {
		return locales.map((locale) => {
			const isHide = isVirtualTableColumnHide(locale.id, locales);
			const isDescriptionColumn = locale.id.includes("description");
			const name = isDescriptionColumn
				? `items.${item.index}.translations.${locale.index}.description`
				: `items.${item.index}.translations.${locale.index}.name`;
			const descriptionColumnValue = watch(`items.${item.index}.translations.${locale.index}.description`);
			const localeId = locale.id.split("_")[0];
			const nameColumnValue = itemLocales.find((itemLocale) => itemLocale.id === localeId)?.value || "";

			return (
				<td
					className={`${isHide ? "d-none" : ""}`}
					style={{ ...getColumnStyles(locale.id), cursor: isDescriptionColumn ? "pointer" : "default" }}
					key={`${item.index}-${locale.id}`}
					onClick={() => {
						if (isDescriptionColumn) {
							setShowChangeDescriptionModal(true);
							setLocaleToChange(locale);
						}
					}}
				>
					{isDescriptionColumn ? (
						<>
							<div className="name-box name-box-with-icon" title={descriptionColumnValue}>
								{descriptionColumnValue}
							</div>
							{descriptionColumnValue && (
								<RemoveSVG
									style={{ cursor: "pointer" }}
									className="absolute-remove-icon"
									onClick={() => handleClearDescriptionInput(locale)}
								/>
							)}
						</>
					) : (
						<div
							className="input-box"
							title={nameColumnValue}
							key={`items.${item.index}.translations.${locale.index}.name`}
						>
							<TranslationVirtualTableInput
								item={item}
								form={form}
								name={name}
								handleClear={() => handleClearLocaleColumnInput(locale.index, isDescriptionColumn)}
								handleOnChange={(value) => {
									handleChangeLocaleName(localeId, value);
								}}
							/>
						</div>
					)}
				</td>
			);
		});
	};

	const handleHideChangeDescriptionModal = () => {
		if (localeToChange) {
			setLocaleToChange(undefined);
		}
		setShowChangeDescriptionModal(false);
	};

	return (
		<tr style={styleTr}>
			{showChangeDescriptionModal && (
				<ChangeTranslationDescriptionModal
					show={showChangeDescriptionModal}
					onHide={handleHideChangeDescriptionModal}
					form={form}
					item={item}
					locale={localeToChange}
				/>
			)}
			{renderDefaultColumnCells()}
			{renderLocaleColumnCells()}
		</tr>
	);
};

export default TranslationsVirtualTableRow;
