import React, { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { AppState } from "../AppModal";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const CallRecorderFormModalCard: FC<Props> = ({ appState, handleUpdateApp }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
		},
	});
	const {
		formState,
		formState: { errors },
		register,
		handleSubmit,
		reset,
		setError,
	} = form;
	const [loading, setLoading] = useState<boolean>(false);
	const { addSuccessFlash, addFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const newData = {
			name: data.name,
			id: appState.app.id,
			settings: {},
		};

		try {
			const res = await api.organization().updateApp(newData, params);
			handleUpdateApp(res);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			reset(res);
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} className="app-form" onSubmit={onSubmit}>
					<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
					<ButtonLoading loading={loading} variant="add" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};

export default CallRecorderFormModalCard;
