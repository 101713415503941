import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";

const MenusNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const prefix = `/${organization.id}/menu`;
	const menuReadWritePermissions = useSelector(hasPermission(["MENU_READ", "MENU_WRITE"]));
	const discountReadWritePermissions = useSelector(hasPermission(["DISCOUNT_READ", "DISCOUNT_WRITE"]));
	const priceListReadWritePermissions = useSelector(hasPermission(["PRICE_LIST_READ", "PRICE_LIST_WRITE"]));

	const items = [
		{
			title: t("common.word.menu"),
			route: `${prefix}/menus`,
			hide: !menuReadWritePermissions,
		},
		{
			title: t("modules.category.header.title"),
			route: `${prefix}/categories`,
			hide: !menuReadWritePermissions,
		},
		{
			title: t("modules.item_group.header.title"),
			route: `${prefix}/item_groups`,
			hide: !menuReadWritePermissions,
		},
		{
			title: t("modules.modifier_group.header.title"),
			route: `${prefix}/modifier_groups`,
			hide: !menuReadWritePermissions,
		},
		{
			title: t("modules.price_list.header.title"),
			route: `${prefix}/price_lists`,
			hide: !priceListReadWritePermissions,
		},
		{
			title: t("common.word.discounts"),
			route: `${prefix}/discounts`,
			hide: !discountReadWritePermissions,
		},
	];

	return <SubNavigationItems title={t("components.navigation.menu")} items={items} />;
};

export default MenusNavigation;
