import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import OrganizationEmployeesWorkplacesIndexPage from "./pages/Index";

const OrganizationEmployeesWorkplacesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/`} exact={true} component={OrganizationEmployeesWorkplacesIndexPage} />
				<Route component={Page404} />
			</Switch>
		</div>
	);
};

export default OrganizationEmployeesWorkplacesPage;
