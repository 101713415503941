import { CustomFieldEntityApi, CustomFieldTemplateApi } from "go-segment/services/types";

export const mapCustomFieldsToForm = (
	customFieldConfig: CustomFieldTemplateApi[],
	customFields: CustomFieldEntityApi[]
): CustomFieldEntityApi[] => {
	const newFields: CustomFieldEntityApi[] = [];
	customFieldConfig?.forEach((cf) => {
		const matched = customFields?.find((f) => f.slug === cf.slug);
		const newField = {
			slug: cf.slug,
		} as CustomFieldEntityApi;
		if (matched) {
			newField.id = matched.id;
			newField.value = parseCustomFieldValue(matched);
		} else {
			newField.value = "";
		}
		newFields.push(newField);
	});
	return newFields;
};

const parseCustomFieldValue = (customField: CustomFieldEntityApi) => {
	let newValue = "";
	if (!isNaN(Number(customField.value)) && typeof customField.value === "number") {
		if (customField.value === "") newValue = "";
		else newValue = `${Number(customField.value)}`;
	} else {
		newValue = customField.value;
	}
	return newValue;
};
