import { FC, useState } from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormatDuration, getShortMonthName } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import DateInfoBox from "../../../../../../../../../../components/Common/DateInfoBox/DateInfoBox";
import {
	EmployeeApi,
	EmployeeInfoApi,
	EmployeeWeeklyWorktimeApi,
} from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	employee: EmployeeApi;
	employeeInfo: EmployeeInfoApi;
}

const WorkedHours: FC<Props> = ({ employee, employeeInfo }) => {
	const { t } = useTranslation();
	const [groupWorktimesBy, setGroupWorktimesBy] = useState("DAY");
	const organization = useSelector(selectOrganization);

	const getSummaryDuration = () => {
		if (groupWorktimesBy === "DAY") {
			return employeeInfo.daily_work_times.reduce((acc, val) => acc + val.duration_in_minutes, 0);
		}
		if (groupWorktimesBy === "WEEK") {
			return employeeInfo.weekly_work_times.reduce((acc, val) => acc + val.duration_in_minutes, 0);
		}
		if (groupWorktimesBy === "MONTH") {
			return employeeInfo.monthly_work_times.reduce((acc, val) => acc + val.duration_in_minutes, 0);
		}
		return 0;
	};

	const getSubDate = (item: EmployeeWeeklyWorktimeApi) => {
		const startDay = new Date(item.start_at).getDate();
		const endDay = new Date(item.end_at).getDate();
		return `${startDay}-${endDay}`;
	};

	const getMonth = (item: EmployeeWeeklyWorktimeApi) => {
		const startMonth = new Date(item.start_at).getMonth() + 1;
		const endMonth = new Date(item.end_at).getMonth() + 1;
		if (startMonth === endMonth) {
			return getShortMonthName(startMonth.toString(), t);
		}
		return `${getShortMonthName(startMonth.toString(), t)}-${getShortMonthName(endMonth.toString(), t)}`;
	};

	return (
		<Card className="worked-hours">
			<Card.Body className={"pt-3"}>
				<div className="header">
					<h5 className={"worked-hours-title"}> {t("modules.employee.field.worked_hours.title")}</h5>
					<span className={"badge bg-success hours-summary"}>
						{FormatDuration(getSummaryDuration(), t)} {t("common.word.last")} 7{" "}
						{t(`enums.dates.type.${groupWorktimesBy}`).toLowerCase()}
					</span>
					<ButtonGroup>
						<Button
							onClick={() => setGroupWorktimesBy("DAY")}
							variant={groupWorktimesBy === "DAY" ? "primary" : "outline-primary"}
						>
							{t(`enums.dates.type.DAY`)}
						</Button>
						<Button
							onClick={() => setGroupWorktimesBy("WEEK")}
							variant={groupWorktimesBy === "WEEK" ? "primary" : "outline-primary"}
						>
							{t(`enums.dates.type.WEEK`)}
						</Button>
						<Button
							onClick={() => setGroupWorktimesBy("MONTH")}
							variant={groupWorktimesBy === "MONTH" ? "primary" : "outline-primary"}
						>
							{t(`enums.dates.type.MONTH`)}
						</Button>
					</ButtonGroup>
				</div>
				{groupWorktimesBy === "DAY" && (
					<>
						<div className="worked-info-boxes">
							{employeeInfo.daily_work_times.map((item, index) => {
								return (
									<DateInfoBox
										key={`day-${index}`}
										date={item.day}
										infoSubHeader={`${FormatMoney(item.to_pay)}`}
										infoHeader={`${FormatDuration(item.duration_in_minutes, t)}`}
									/>
								);
							})}
						</div>
					</>
				)}
				{groupWorktimesBy === "MONTH" && (
					<>
						<div className="worked-info-boxes">
							{employeeInfo.monthly_work_times.map((item, index) => {
								return (
									<DateInfoBox
										key={`day-${index}`}
										dateSubHeader={item.month[0]}
										dateHeader={getShortMonthName(item.month[1].toString(), t)}
										infoSubHeader={`${FormatMoney(item.to_pay)}`}
										infoHeader={`${FormatDuration(item.duration_in_minutes, t)}`}
									/>
								);
							})}
						</div>
					</>
				)}
				{groupWorktimesBy === "WEEK" && (
					<>
						<div className="worked-info-boxes">
							{employeeInfo.weekly_work_times.map((item, index) => {
								return (
									<DateInfoBox
										key={`day-${index}`}
										dateHeader={getSubDate(item)}
										dateSubHeader={getMonth(item)}
										infoSubHeader={`${FormatMoney(item.to_pay)}`}
										infoHeader={`${FormatDuration(item.duration_in_minutes, t)}`}
									/>
								);
							})}
						</div>
					</>
				)}
				<Link
					to={`/${organization.id}/employees/worktimes?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}employee|e=${employee.id}`
					)}`}
					className={"btn btn-add card-action"}
				>
					{t("common.action.show_more", { ns: "lib" })}
				</Link>
			</Card.Body>
		</Card>
	);
};
export default WorkedHours;
