export enum JPKInvoiceType {
	JPK,
	JPK_V7,
	JPK_FA,
}

export interface InvoicePreviousDataApi {
	build_nr: string;
	city: string;
	country_code: string;
	district: string;
	email: string;
	full_name: string;
	parish: string;
	street: string;
	tax_id_no: string;
	tax_office_code: string;
	voivodeship: string;
	zip_code: string;
}
