import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { AvailabilitiesProps, AvailabilityApi } from "../../../../services/types";
import { AvailabilityForm } from "../../../Availabilities/components/AvailabilityForm";

export const SettingsAvailabilitiesNewPage = ({ requests, organizationId }: AvailabilitiesProps): JSX.Element => {
	const [availability, setAvailability] = useState<AvailabilityApi>();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("go_component.availability.header.new.title", { ns: "lib" }));
		setAvailability({} as AvailabilityApi);
	}, []);

	if (!availability) {
		return <LoadingContainer />;
	}
	return (
		<>
			<Header title={t("go_component.availability.header.new.title", { ns: "lib" })} />
			<AvailabilityForm availability={availability} requests={requests} organizationId={organizationId} />
		</>
	);
};
