import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Loading } from "go-core/components/Loading";
import { apiOrganization } from "../../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryEmployeeApi } from "../../../../../../../../services/Api/Organization/types";
import { LiveOrderFilterOption, LiveOrdersFilters } from "../../services/types";
import { inputCustomStyles } from "./utils";

interface Props {
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
}

type optionType = "exist" | "not_exist" | "select" | undefined;

const getDefaultSelectedOption = (innerFilters: LiveOrdersFilters) => {
	const { deliveryEmployee } = innerFilters;

	if (deliveryEmployee?.id === "exist") return "exist";
	if (deliveryEmployee?.id === "not_exist") {
		return "not_exist";
	}
	if (deliveryEmployee?.id) return "select";
	return undefined;
};

const LiveOrdersDeliveryEmployeeFilter = ({ innerFilters, setInnerFilters }: Props) => {
	const [selectedOption, setSelectedOption] = useState<optionType | undefined>(
		getDefaultSelectedOption(innerFilters)
	);
	const { t } = useTranslation();
	const [deliveryEmployeesLoading, setDeliveryEmployeesLoading] = useState(false);
	const [deliveryEmployees, setDeliveryEmployees] = useState<DeliveryEmployeeApi[]>([]);
	const { addFlash } = useFlash();

	useEffect(() => {
		if (selectedOption === "select") {
			handleFetchDeliveryEmployees();
		}
	}, [selectedOption]);

	const handleFetchDeliveryEmployees = async () => {
		setDeliveryEmployeesLoading(true);
		try {
			const res = await apiOrganization.getDeliveryEmployees({ size: 0, include: "employee" });
			setDeliveryEmployees(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setDeliveryEmployeesLoading(false);
	};

	const onSelectChange = (value: LiveOrderFilterOption | null) => {
		setInnerFilters({
			...innerFilters,
			deliveryEmployee: value,
		});
	};

	const handleOnButtonClick = (type: optionType) => {
		if (selectedOption === type) {
			setSelectedOption(undefined);
			setInnerFilters({
				...innerFilters,
				deliveryEmployee: null,
			});
		} else {
			const employeeValue =
				type === "exist"
					? {
							id: type,
							label: t("lib:go_list.filters.any"),
					  }
					: type === "not_exist"
					? {
							id: type,
							label: t("lib:go_list.filters.unknown"),
					  }
					: null;
			setInnerFilters({
				...innerFilters,
				deliveryEmployee: employeeValue,
			});
			setSelectedOption(type);
		}
	};

	return (
		<div className="mt-2">
			<Form.Label>{t("enums.workplaces.type.DRIVER")}</Form.Label>
			<div className="row row-gap-2">
				<div className="col-sm-4">
					<Button
						className="w-100"
						variant={selectedOption === "exist" ? "primary" : "light"}
						onClick={() => handleOnButtonClick("exist")}
					>
						{t("lib:go_list.filters.any")}
					</Button>
				</div>
				<div className="col-sm-4">
					<Button
						className="w-100"
						onClick={() => handleOnButtonClick("not_exist")}
						variant={selectedOption === "not_exist" ? "primary" : "light"}
					>
						{t("lib:go_list.filters.unknown")}
					</Button>
				</div>
				<div className="col-sm-4">
					<Button
						className="w-100"
						onClick={() => handleOnButtonClick("select")}
						variant={selectedOption === "select" ? "primary" : "light"}
					>
						{t("lib:common.action.select")}
					</Button>
				</div>
			</div>
			{selectedOption === "select" && (
				<div className="mt-2">
					{deliveryEmployeesLoading ? (
						<Loading />
					) : (
						<Select
							styles={inputCustomStyles}
							className="form-select"
							value={innerFilters.deliveryEmployee}
							defaultValue={innerFilters.deliveryEmployee}
							onChange={onSelectChange}
							getOptionValue={(opt) => opt.id}
							options={deliveryEmployees.map((employee) => ({
								label: employee.employee?.name || "",
								id: employee.source.id,
							}))}
							placeholder={t("lib:common.action.select")}
							isClearable
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default LiveOrdersDeliveryEmployeeFilter;
