import { TFunction } from "react-i18next";
import { getDayName, getMonthName } from "go-core/components/Formatters/FormatDate";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { UserOrganizationState, UserState } from "go-security/services/users/types";

class ReportShareUtils {
	static getOrganizations = (orgs?: UserOrganizationState[]) => {
		const orgObj: Record<number, string> = {};
		orgs?.forEach((org) => {
			orgObj[org.id] = org.name;
		});
		return orgObj;
	};

	static getGroupName = (name: string, t: TFunction) => {
		if (name === "FIXED_PRICE") return t("enums.discounts.type.FIXED_PRICE");
		if (name === "FIXED_RATE") return t("enums.discounts.type.FIXED_RATE");
		if (name === "AMOUNT") return t("enums.discounts.type.AMOUNT");
		return name;
	};

	static mapDiscountNames = (key: string, t: TFunction) => {
		return key
			.replace(/FIXED_RATE/g, t("enums.discounts.type.FIXED_RATE"))
			.replace(/AMOUNT/g, t("enums.discounts.type.AMOUNT"))
			.replace(/FIXED_PRICE/g, t("enums.discounts.type.FIXED_PRICE"));
	};

	static getGroupsInfo = (t: TFunction) => {
		return {
			CREATED_AT_MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			ORDER_STATUS: (groupByValue: Record<string, any>) => {
				return t(`enums.common.status.${groupByValue.name}`, { ns: ["translation", "lib"] });
			},
			ORDER_TYPE: (groupByValue: Record<string, any>) => {
				return t(`enums.orders.types.${groupByValue.name}`);
			},
			CREATED_AT_DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			DISCOUNT: (groupByValue: Record<string, any>) => {
				return ReportShareUtils.mapDiscountNames(groupByValue.name, t);
			},
			REPORT_DATE_DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			EXECUTION_AT_DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			REPORT_DATE_MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			EXECUTION_AT_MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			CREATED_AT_DATE: (groupByValue: Record<string, any>) => {
				const date = new Date(Number(groupByValue.name));
				return date.toLocaleDateString();
			},
			PAYMENT_METHOD: (groupByValue: Record<string, any>) => {
				if (!groupByValue.name || groupByValue.name === "empty") return t("common.word.unpaid_female");
				return groupByValue.name;
			},
			PAYMENT_OUTFLOW_TYPE: (groupByValue: Record<string, any>) => {
				let paymentOutflowType = groupByValue.name;
				if (paymentOutflowType === "") return "-";
				if (paymentOutflowType === "empty") return "-";
				if (paymentOutflowType === "RW / WZ") paymentOutflowType = "RW_WZ";
				return t(`enums.payment_methods.payment_outflow_types.${paymentOutflowType}`);
			},
		};
	};

	static getGroups = (t: TFunction) => [
		{
			id: "CURRENCY",
			name: t("components.reports.groups.CURRENCY"),
		},
	];

	static getFilters = (t: TFunction, user: UserState) => [
		{
			id: "organization_ids",
			name: t("common.word.venue", { ns: "lib" }),
			type: "list",
			options: ReportShareUtils.getOrganizations(user.organizations),
			sortAlphabetically: true,
			hasDefaultOptions: false,
		},
		{
			id: "currency",
			name: t("modules.sale_report.field.currency.title"),
			type: "list",
			listOptions: ReportShareUtils.getCurrencyFilterOptions(user?.organizations || []),
			hasDefaultOptions: false,
		},
	];

	static getCurrencyFilterOptions = (organizations: UserOrganizationState[]) => {
		const currencies = Array.from(new Set(organizations.map((organization) => organization.currency)));
		return currencies.reduce((acc: Record<string, string>, cur: string) => {
			acc[cur] = cur;
			return acc;
		}, {});
	};

	static getGroupReportCommonGroups(t: TFunction) {
		return [
			{
				id: "ORGANIZATION",
				name: t("components.reports.groups.VENUE"),
			},
		];
	}
}

export default ReportShareUtils;
