import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LiveOrderRejectionFormApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import LiveOrderRejectionModalOption from "./LiveOrderRejectionModalOption";

interface Props {
	setSelectedOption: (option: string | undefined) => void;
	selectedOption: string | undefined;
	setShowReasonInput: (value: boolean) => void;
	form: UseFormReturn<LiveOrderRejectionFormApi>;
	hasReasonOptionError: boolean;
}

const LiveOrderRejectionModalOptions = ({
	setSelectedOption,
	selectedOption,
	setShowReasonInput,
	form,
	hasReasonOptionError,
}: Props) => {
	const { t } = useTranslation();
	const options = [
		t("modules.live_order.field.no_delivery_employees.title"),
		t("modules.live_order.field.not_enough_time.title"),
		t("modules.live_order.field.outside_delivery_zone.title"),
		t("modules.live_order.field.other_reason.title"),
	];

	return (
		<>
			<div className="d-flex flex-wrap gap-2">
				{options.map((option, index) => (
					<LiveOrderRejectionModalOption
						option={option}
						key={option}
						setShowReasonInput={setShowReasonInput}
						setSelectedOption={setSelectedOption}
						selectedOption={selectedOption}
						form={form}
						otherReason={options.length - 1 === index}
					/>
				))}
			</div>
			{hasReasonOptionError && (
				<div className="invalid-feedback ms-1  d-inline">{t("lib:constraints.must_not_be_null")}</div>
			)}
		</>
	);
};

export default LiveOrderRejectionModalOptions;
