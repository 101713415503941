import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as RemoveSVG } from "go-core/images/svg/actions/remove.svg";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomFieldTemplateApi } from "go-segment/services/types";

interface Props {
	form: UseFormReturn<CustomFieldTemplateApi>;
}

const CustomFieldConstraintsForm: FC<Props> = (props) => {
	const { t } = useTranslation("admin");
	const {
		control,
		formState: { errors },
		register,
		watch,
		setValue,
	} = props.form;
	const { fields, remove, append } = useFieldArray({ name: "constraints", control });
	const isMobile = useWindowSize().isMobile;

	const constraintTypeChangeHandler = (index: number) => {
		if (watch(`constraints.${index}.type`) !== "REGEX") setValue(`constraints.${index}.value`, "");
	};

	const onRemove = (index: number) => {
		remove(index);
	};

	const constraintsOptions = [
		{ value: "REGEX", label: t("lib:enums.custom_field.constraints.REGEX") },
		{ value: "UNIQUE", label: t("lib:enums.custom_field.constraints.UNIQUE") },
		{ value: "NOTNULL", label: t("lib:enums.custom_field.constraints.NOTNULL") },
	];

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form">
					<thead>
						<tr>
							<th className="action" />
							<th style={{ minWidth: isMobile ? "175px" : "unset" }}>
								{t("lib:go_component.custom_field.field.constraints.validation_type")}
							</th>
							<th style={{ minWidth: isMobile ? "225px" : "unset" }}>
								{t("lib:go_component.custom_field.field.constraints.validation_value")}
							</th>
							<th className="action" />
						</tr>
					</thead>
					<tbody>
						{fields.map((field, i) => {
							return (
								<tr key={field.id}>
									<td className="action" />
									<td>
										<FormSelectGroup
											name={`constraints.${i}.type`}
											control={control}
											options={constraintsOptions}
											errors={errors}
											onChange={() => constraintTypeChangeHandler(i)}
											data-testid={`constraints.${i}.type`}
										/>
									</td>
									<td>
										<FormInput
											name={`constraints.${i}.value`}
											register={register}
											errors={errors}
											disabled={watch(`constraints.${i}.type`) !== "REGEX"}
										/>
									</td>
									<td className="action">
										<RemoveSVG className="icon" onClick={() => onRemove(i)} />
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<Button
				variant="add"
				onClick={() =>
					append({
						value: "",
					})
				}
			>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
		</>
	);
};

export default CustomFieldConstraintsForm;
