import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationSettingsCurrenciesIndexPage from "./pages/Index";

const OrganizationSettingsCurrenciesPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route path={`${props.match.url}/`} exact={true} component={OrganizationSettingsCurrenciesIndexPage} />
		</Switch>
	);
};

export default OrganizationSettingsCurrenciesPage;
