import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDirty } from "go-form/components/FormDirty";
import { CompanyInfoApi, PosReportSettingsApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { exportPosReportPdf, printPosReportPdf } from "../services/exportPosReport";

interface GeneratePdfField {
	id: string;
	type: string;
	title: string;
	description?: string;
	tableColumns: string[];
}

export interface GeneratePdfConfig {
	configId: string;
	configName: string;
	fields: GeneratePdfField[];
}

interface Props {
	isShown: boolean;
	onHide: () => void;
	config: GeneratePdfConfig;
	item: Record<string, any>;
	sendByEmail?: boolean;
}

const GeneratePdfModal: FC<Props> = ({ config, isShown, onHide, item, sendByEmail }) => {
	const { t } = useTranslation();
	const form = useForm<PosReportSettingsApi>({
		criteriaMode: "all",
		shouldUnregister: false,
	});
	const {
		register,
		formState,
		formState: { errors },
		handleSubmit,
		reset,
		getValues,
		setError,
	} = form;
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [settings, setSettings] = useState<PosReportSettingsApi | undefined>(undefined);
	const [companyDetails, setCompanyDetails] = useState<CompanyInfoApi | undefined>(undefined);
	const { addFlash, addSuccessFlash } = useFlash();

	useEffect(() => {
		reset();
		if (isShown) {
			fetchSettings();
			fetchCompanyDetails();
		}
	}, [isShown]);

	const drawConfigFields = (field: GeneratePdfField) => {
		switch (field.type) {
			case "checkbox":
				return (
					<FormCheck
						type="switch"
						key={field.id}
						register={register}
						name={field.id}
						errors={errors}
						label={t(`components.generate_pdf_modal.pos_report.fields.${field.id}.label`)}
						help={field.description}
					/>
				);
			default:
				return <></>;
		}
	};

	const fetchSettings = async () => {
		try {
			const res = await api.organization().getPosPdfSettings();
			reset(res);
			setSettings(res);
			setLoading(false);
		} catch (e) {
			handleError.alert(e, addFlash);
			setLoading(false);
		}
	};

	const fetchCompanyDetails = async () => {
		try {
			const res = await api.organization().getCompanyInfo();
			setCompanyDetails(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const saveSettings = async (data: PosReportSettingsApi) => {
		if (data.remember_settings) {
			await api.organization().updatePosPdfSettings({ ...data, remember_settings: undefined });
		}
	};

	const onPrint = handleSubmit(async () => {
		setLoading2(true);
		await saveSettings(getValues());
		await printPosReportPdf(item.id, { ...getValues(), remember_settings: undefined }, t, addFlash);
		setLoading2(false);
		onHide();
	});

	const onGenerate = handleSubmit(async () => {
		setLoading(true);
		await saveSettings(getValues());
		await exportPosReportPdf(item.id, { ...getValues(), remember_settings: undefined }, t, addFlash);
		setLoading(false);
		onHide();
	});

	const onSendByEmail = handleSubmit(async (data: PosReportSettingsApi) => {
		setLoading3(true);
		try {
			await saveSettings(getValues());
			await api.organization().sendPosReportByEmail(item.id, { ...getValues() }, data?.email || "");
			onHide();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading3(false);
	});

	return (
		<Modal show={isShown} onHide={onHide}>
			<FormDirty formState={formState} key="generate-pdf-modal-form">
				<Modal.Header closeButton>
					<div className="d-flex flex-column">
						<Modal.Title>
							{sendByEmail
								? t("components.generate_pdf_modal.header.title2")
								: t("components.generate_pdf_modal.header.title")}
						</Modal.Title>
						<span>
							{sendByEmail
								? t("components.generate_pdf_modal.header.description2")
								: t("components.generate_pdf_modal.header.description")}
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					{!settings || (sendByEmail && !companyDetails) ? (
						<LoadingContainer />
					) : (
						<>
							{sendByEmail && (
								<div className="mt-1 mb-4">
									<FormInput
										name="email"
										errors={errors}
										register={register}
										defaultValue={companyDetails?.contact_email}
										label={t(
											"components.generate_pdf_modal.actions.send_by_email.fields.email.label"
										)}
									/>
								</div>
							)}
							{config?.fields.map((field) => drawConfigFields(field))}
							<div className="d-flex flex-column mt-3">
								<Modal.Title>{t("components.generate_pdf_modal.actions.remember.title")}</Modal.Title>
								<span>{t("components.generate_pdf_modal.actions.remember.description")}</span>
								<FormCheck
									className="mt-3"
									name={"remember_settings"}
									errors={errors}
									type="switch"
									register={register}
									label={t("components.generate_pdf_modal.actions.remember.action")}
								/>
							</div>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					{!sendByEmail && (
						<>
							<ButtonLoading variant="primary" onClick={onGenerate} loading={loading}>
								{t("common.action.generate", { ns: "lib" })}
							</ButtonLoading>
							<ButtonLoading variant="primary" onClick={onPrint} loading={loading2}>
								{t("common.action.reset", { ns: "lib" })}
							</ButtonLoading>
						</>
					)}
					{sendByEmail && (
						<ButtonLoading variant="primary" onClick={onSendByEmail} loading={loading3}>
							{t("common.action.send", { ns: "lib" })}
						</ButtonLoading>
					)}
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default GeneratePdfModal;
