import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { EmployeeApi, EmployeeInfoApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EmployeePreview from "../../components/EmployeePreview/EmployeePreview";

interface MatchParams {
	employeeId: string;
}

const OrganizationEmployeesIndexPreviewPage = (): JSX.Element => {
	const [employee, setEmployee] = useState<EmployeeApi | undefined>();
	const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfoApi | undefined>(undefined);
	const { addFlash } = useFlash();
	const { employeeId } = useParams<MatchParams>();
	const params: Record<string, any> = {
		include: "role,role.permissions,workplace,workplaces,workplaces.workplace,work_times",
	};
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	const fetchEmployee = async () => {
		try {
			const res = await api.organization().getEmployee(Number(employeeId), params);
			setEmployee(res);
			handleChangeTabTitle(`${res.name} | ${t("modules.employee.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const fetchEmployeeInfo = async () => {
		try {
			const res = await api.organization().getEmployeeInfo(Number(employeeId));
			setEmployeeInfo(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	useEffect(() => {
		fetchEmployee();
		fetchEmployeeInfo();
	}, []);

	if (!employee || !employeeInfo) return <LoadingContainer />;

	return <EmployeePreview handleAddWorkTime={fetchEmployeeInfo} employee={employee} employeeInfo={employeeInfo} />;
};
export default OrganizationEmployeesIndexPreviewPage;
