import React from "react";

interface Props {
	permissions: string[];
	organizationName?: string;
}

const MissingPermissionList = ({ permissions, organizationName }: Props): JSX.Element => {
	return (
		<div className="d-flex flex-column">
			{permissions.map((permission) => (
				<span key={permission}>{organizationName ? `${permission}- ${organizationName}` : permission}</span>
			))}
		</div>
	);
};

export default MissingPermissionList;
