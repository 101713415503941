import { MoneyApi } from "go-core/api/types";
import { AddressApi } from "../types";

export enum PaymentStatus {
	OPEN = "OPEN",
	PAID = "PAID",
	WAIT_TO_PAY = "WAIT_TO_PAY",
}

export interface OrderTaxItemData {
	total_price_net: MoneyApi;
	total_price_tax: MoneyApi;
	total_price_gross: MoneyApi;
	tax_amount: number;
	tax_code: string;
}

export interface OrderItemData {
	quantity: number;
	unit_price: MoneyApi;
	total_price: MoneyApi;
	comment?: string;
	product?: {
		name: string;
	};
}

export interface OrderCompanyData {
	name?: string;
	public_name?: string;
	tax_id_no?: string;
	address?: AddressApi;
	facebook?: string;
	instagram?: string;
	website?: string;
	logo_link?: {
		default_link?: string;
		small?: string;
	};
}

export enum PaymentReceiptType {
	CASH = "CASH",
	CARD = "CARD",
	CHECK = "CHECK",
	BOND = "BOND",
	CREDIT = "CREDIT",
	OTHER = "OTHER",
	VOUCHER = "VOUCHER",
	CLIENT_ACCOUNT = "CLIENT_ACCOUNT",
	BANK_TRANSFER = "BANK_TRANSFER",
}

export interface PaymentMethod {
	id: string | number;
	name: string;
	receipt_type: PaymentReceiptType;
}

export interface Transaction {
	price: MoneyApi;
	tender_price: MoneyApi;
	change_price: MoneyApi;
	status: PaymentStatus;
	payment_method: PaymentMethod;
	paid_at?: string;
}

export interface OrderDetailsData {
	number: string;
	total_price: MoneyApi;
	total_price_tax: MoneyApi;
	payment_status: PaymentStatus;
	order_at?: string;
	closed_at?: string;
	discount_price?: MoneyApi;
	items?: OrderItemData[];
	tax_items: OrderTaxItemData[];
	source?: {
		number?: string;
	};
	transactions?: Transaction[];
}

export interface OrderData extends OrderDetailsData {
	company?: OrderCompanyData;
}
