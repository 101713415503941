import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import AccessTokenSessionService from "go-security/services/accessTokenSessionService";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { AppState } from "../Apps/AppModal";
import MewsPreInstallationForm from "./MewsPreInstallationForm";

interface Props {
	isShown: boolean;
	onHide: () => void;
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const MewsModalForm: FC<Props> = ({ appState, handleUpdateApp, onHide, isShown }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
		},
	});
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const { formState, getValues, setError, handleSubmit, reset } = form;
	const [loading, setLoading] = useState<boolean>(false);
	const accessToken = AccessTokenSessionService.get();
	const history = useHistory();

	const onInstall = async (data: ApplicationApi) => {
		setLoading(true);
		try {
			const res = await api.organization().installApplicationPost("MEWS", accessToken, data);
			reset(res);
			addSuccessFlash(t("lib:common.flash.completed"));
			handleUpdateApp(res);
			onHide();
			history.push(`/${organization.id}/apps/active/${res.id}`);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	};

	const onSubmit = handleSubmit((data: ApplicationApi) => {
		onInstall(data);
	});

	return (
		<Modal onHide={onHide} show={isShown}>
			<FormDirty formState={formState} onSubmit={onSubmit} className="app-form">
				<Modal.Header closeButton>
					<Modal.Title>
						{t("modules.app.field.install.title")} {t("enums.apps.providers.MEWS")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<MewsPreInstallationForm form={form} />
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading
						loading={loading}
						disabled={!getValues("settings.accesstoken")}
						onClick={onSubmit}
						type="submit"
					>
						{t("lib:common.action.install")}
					</ButtonLoading>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default MewsModalForm;
