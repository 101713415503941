import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import OrganizationSettingsPrintoutTemplatesEditPage from "./pages/Edit";
import OrganizationSettingsPrintoutTemplatesIndexPage from "./pages/Index";
import OrganizationSettingsPrintoutTemplatesNewPage from "./pages/New";

const OrganizationSettingsPrintoutTemplatesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationSettingsPrintoutTemplatesIndexPage} />
				<Route
					exact={true}
					path={`${props.match.url}/new`}
					component={OrganizationSettingsPrintoutTemplatesNewPage}
				/>
				<Route
					exact
					path={`${props.match.url}/:printout_id`}
					component={OrganizationSettingsPrintoutTemplatesEditPage}
				/>
				<Route component={Page404} />
			</Switch>
		</div>
	);
};
export default OrganizationSettingsPrintoutTemplatesPage;
