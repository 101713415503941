import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedItemCost } from "go-core/utils/utils";
import RenderPaymentMethod from "../../../../../../../components/Common/Renderers/RenderPaymentMethod";
import { PaymentStatus, Transaction } from "../../../../../../../services/Api/Public/types";
import { getTransactionDateString } from "../../../utils";

interface PaymentDetailsProps {
	transactions?: Transaction[];
}

export const PaymentDetails = ({ transactions }: PaymentDetailsProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="payment" data-testid="e-order-order-details-payment-details">
			{!!transactions?.length && (
				<div className="payment-transactions" data-testid="e-order-order-details-payment-details-transactions">
					{transactions.map((transaction, index) => (
						<>
							<div key={index} className="payment-transaction">
								<div
									className={`payment-transaction-method ${
										transaction.status !== PaymentStatus.PAID ? "transaction-unpaid" : ""
									}`}
								>
									<RenderPaymentMethod
										name={transaction.payment_method.name}
										type={transaction.payment_method.receipt_type}
									/>
									{transactions.length > 1 && (
										<span>
											(
											{getFormattedItemCost(transaction.price.amount, transaction.price.currency)}
											)
										</span>
									)}
								</div>
								{transaction.paid_at && (
									<span className="payment-transaction-date">
										{getTransactionDateString(transaction.paid_at)}
									</span>
								)}
							</div>
							{transaction.status !== PaymentStatus.PAID && (
								<div className="transaction-status transaction-unpaid">
									({t("modules.e_order.field.payment_status_unpaid.title")})
								</div>
							)}
						</>
					))}
				</div>
			)}
		</div>
	);
};
