import React from "react";
import { TFunction } from "react-i18next";
import { ReportResponseReportComboApi } from "./types";

export interface ReportComboContextProps {
	get: (
		resource: string,
		dataId: string,
		dataColumnId: string,
		params: Record<string, string>
	) => Promise<ReportResponseReportComboApi>;
	getPath: (resource: string, filter?: string) => string | undefined;
	getFilterVariableComponent: (
		variableId: string,
		updateFilters: (value: Record<string, string>) => void,
		filter?: Record<string, string>
	) => Element | undefined;
}

export class ReportComboService {
	_api: ReportComboContextProps;
	_t: TFunction;

	constructor(api: ReportComboContextProps, t: TFunction) {
		this._api = api;
		this._t = t;
	}

	api(): ReportComboContextProps {
		return this._api;
	}

	getPath(resource: string, filter?: string): string | undefined {
		return this._api.getPath(resource, filter);
	}

	getFilterVariableComponent(
		variableId: string,
		updateFilters: (value: Record<string, string>) => void,
		filter?: Record<string, string>
	): Element | undefined {
		return this._api.getFilterVariableComponent(variableId, updateFilters, filter);
	}
}

export const ReportComboContext = React.createContext<ReportComboService>({} as ReportComboService);
