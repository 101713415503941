import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { TagApi } from "../../../../../../../../../../services/Api/Organization/types";
import TagForm from "../../components/TagForm";

const OrganizationSettingsTagsNewPage = (): JSX.Element => {
	const [tag, setTag] = useState<TagApi | undefined>();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.tag.header.new.title"));
		setTag({} as TagApi);
	}, []);

	if (!tag) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Header title={t("modules.tag.header.new.title")} />
			<TagForm tag={tag} />
		</>
	);
};

export default OrganizationSettingsTagsNewPage;
