import { addServerErrors } from "go-form";
import { Flash, handleAlertErrorsWithoutField, handleErrorForAlert } from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { ApiError, Exception } from "go-core/api/types";
import { parseOmitErrors } from "go-core/utils/utils";

export const handleError = {
	alert(exception: Exception | unknown, setFlash: (_: Flash) => void, omitErrors?: string[]): null {
		return handleErrorForAlert(exception, setFlash, omitErrors);
	},
	form(
		exception: Exception | unknown,
		setError: (
			fieldName: any,
			error: {
				types?: any;
				type?: string;
				message?: string;
				duplicateId?: number;
				arguments?: Record<string, number>;
			}
		) => void,
		setFlash?: (_: Flash) => void,
		omitErrors?: string[]
	): ApiError[] {
		if (!(exception instanceof Error)) {
			throw exception;
		}
		const exceptionErrors = omitErrors
			? parseOmitErrors(handleException(exception), omitErrors)
			: handleException(exception);
		addServerErrors(exceptionErrors, setError);
		if (setFlash !== undefined) handleAlertErrorsWithoutField(exceptionErrors, setFlash);
		// handleErrorForAlert(exception, setFlash);
		return exceptionErrors;
	},
};
export default handleError;
