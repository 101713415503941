import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import LoginSecurityLayout from "go-security/components/LoginSecurityLayout";
import LostPasswordForm from "go-security/components/LostPasswordForm";

const SecurityLostPasswordPage: FC<RouteComponentProps> = () => {
	const { t } = useTranslation();
	const [navbarIsVisible, setNavbarIsVisible] = useState(true);

	const linkSentHandler = () => setNavbarIsVisible(false);

	return (
		<LoginSecurityLayout>
			<div className="form-login-header">
				{navbarIsVisible && (
					<>
						<span className="ms-auto">{t("lib:go_scurity.register.field.remember_password.title")}</span>
						<Link to="/login" className="btn btn-primary-light">
							{t("lib:go_scurity.register.action.back_to_login_page.title")}
						</Link>{" "}
					</>
				)}
			</div>
			<div className="form-login">
				<LostPasswordForm onLinkSent={linkSentHandler} />
			</div>
		</LoginSecurityLayout>
	);
};
export default SecurityLostPasswordPage;
