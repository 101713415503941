import React, { ReactNode } from "react";
import { InputGroup } from "react-bootstrap";

interface Props {
	children: ReactNode;
	firstInput?: boolean;
	customActions?: JSX.Element[];
	name: string;
}

const FormInputWrapper = ({ firstInput, customActions, children, name }: Props): JSX.Element => {
	return customActions && !firstInput ? (
		<InputGroup>
			{children}
			{customActions.map((customAction, index) => (
				<InputGroup.Text key={`input-${name}-custom-action-${index}`}>{customAction}</InputGroup.Text>
			))}
		</InputGroup>
	) : (
		<>{children}</>
	);
};

export default FormInputWrapper;
