import { AxiosResponse } from "axios";
import { restSecurityApi } from "go-security/services/Api/api";

class adminApi {
	static tenantMigration(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.post(`/tenant_migration`, { params, cancelToken: options?.cancelToken });
	}

	static async generateLicencesCSV(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		const res = await restSecurityApi.get(`/admin/tools/licences/csv`, {
			params,
			cancelToken: options?.cancelToken,
		});
		return res.data;
	}
}

export { adminApi };
