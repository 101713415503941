import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingContainer } from "go-core/components/Loading";

interface Props {
	onHide: () => void;
	logs?: Record<string, any>;
	stringContent?: string;
}

const ShowJsonModal: FC<Props> = ({ onHide, logs, stringContent }) => {
	const { t } = useTranslation();
	return (
		<Modal scrollable={true} size={"lg"} show={true} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:common.word.logs")}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ minHeight: "100px" }}>
				{logs ? (
					<>
						<pre>
							<code>{JSON.stringify(logs, null, 2)}</code>
						</pre>
						{stringContent ? stringContent : null}
					</>
				) : (
					<LoadingContainer />
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default ShowJsonModal;
