import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import FormatResourceStatusLabel from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import CustomFieldForm from "go-component/components/CustomField/CustomFieldForm";
import { CustomFieldContext } from "go-component/components/CustomField/services/context";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";

interface MatchParams {
	customFieldId: string;
}

const OrganizationSettingsCustomFieldsEditPage = (): JSX.Element => {
	const [customField, setCustomField] = useState<CustomFieldTemplateApi>();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const { customFieldId } = useParams<MatchParams>();
	const customFieldService = React.useContext(CustomFieldContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchCustomField();
	}, []);

	const onFetchCustomField = async () => {
		const params: Record<string, any> = {};
		params.include = "resources,options,permissions,constraints";
		try {
			const res = await customFieldService.api().get(Number(customFieldId), params);
			setCustomField(res);
			handleChangeTabTitle(`${res.name} | ${t("lib:go_component.custom_field.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removeCustomField = async (cf: CustomFieldTemplateApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await customFieldService.api().remove(cf.id);
			history.push(`/${organization.id}/settings/custom_fields`);
			addSuccessFlash(t("lib:common.flash.removed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restoreCustomField = async (cf: CustomFieldTemplateApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await customFieldService.api().restore(cf.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			setCustomField(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!customField) {
		return <LoadingContainer />;
	}

	const renderTitle = () => (
		<>
			{t("modules.custom_field.header.title")} "{customField.name}"
			<FormatResourceStatusLabel status={customField.status} />
		</>
	);

	const buttons: ButtonProps[] = [];
	if (customField.status === "ENABLED")
		buttons.push({
			action: () => removeCustomField(customField),
			title: t("common.action.remove", { ns: "lib" }),
			dropdown: true,
		});
	if (customField.status === "DELETED")
		buttons.push({
			action: () => restoreCustomField(customField),
			title: t("common.action.activate", { ns: "lib" }),
			dropdown: true,
		});

	const mobileActions: MobileActionProps[] = [
		{
			action: () => removeCustomField(customField),
			title: t("common.action.remove", { ns: "lib" }),
			hide: customField.status !== "ENABLED",
		},
		{
			action: () => restoreCustomField(customField),
			title: t("common.action.activate", { ns: "lib" }),
			hide: customField.status !== "DELETED",
		},
	];

	const onSuccess = (res: CustomFieldTemplateApi) => {
		history.push(`/${organization.id}/settings/custom_fields/${res.id}`);
	};

	return (
		<div className="content">
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<CustomFieldForm customField={customField} onSuccess={onSuccess} />
		</div>
	);
};
export default OrganizationSettingsCustomFieldsEditPage;
