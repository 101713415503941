import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getCurrencies, getLang } from "go-core/utils/utils";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ListConfigColumn } from "../../Column/services/types";
import { ListConfigFilter } from "../../Filter/services/types";
import RenderColumnUtils from "./RenderColumnUtils";
import { ExportConfig } from "./types";

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("enums.languages.pl", { ns: "lib" });
	t("enums.languages.en", { ns: "lib" });
	t("enums.languages.de", { ns: "lib" });
	t("enums.languages.uk", { ns: "lib" });
	t("enums.languages.vi", { ns: "lib" });
};

const getCustomFieldExportValue = (columnFromConfigId: string, item: any): string | undefined => {
	if (!(columnFromConfigId.startsWith("+") || columnFromConfigId.startsWith("-"))) return "";
	const key = columnFromConfigId.substring(1);
	const value = item.custom_fields?.find((f: CustomFieldTemplateApi) => f.slug === key)?.value;
	return value ? value : "";
};

const parseValue = (text: string) => {
	const lang = getLang();
	if (lang === "pl" || lang === "pl-PL") {
		return `\"${text.replace(/\./g, ",")}\"`;
	}
	return `\"${text}\"`;
};

export const valueEncoder = (value: string): string => {
	return value.replace(/[#"']/g, "");
};

export const createCSVFile = (data: string, exportConfig: ExportConfig | undefined): void => {
	const language = getLang();
	const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
	const encodedUri = URL.createObjectURL(blob);
	// let encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute(
		"download",
		`${exportConfig?.filename}_${language === "pl-PL" || language === "pl" ? "pl" : "en"}.csv`
	);
	link.style.display = "none";
	document.body.appendChild(link); // Required for FF

	link.click();
};

export const exportCustomPdf = (data: string, exportConfig: ExportConfig | undefined): void => {
	const language = getLang();
	const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf;charset=UTF-8" }));
	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute(
		"download",
		`${exportConfig?.filename}_${language === "pl-PL" || language === "pl" ? "pl" : "en"}.pdf`
	);
	document.body.appendChild(link);
	link.click();
};

const parseCsvColumnValue = (filters: ListConfigFilter[], columnFromConfig: ListConfigColumn, value: any): string => {
	const availableCurrencies = getCurrencies();

	const columnFieldType = columnFromConfig?.exportType || filters.find((f) => f.id === columnFromConfig?.id)?.type;
	let parsedValue = value;
	if (columnFieldType === "number") {
		parsedValue = parseValue(valueEncoder(`"${parsedValue}"`));
	} else {
		if (typeof parsedValue === "string" || parsedValue instanceof String) {
			if (parsedValue.includes('"')) {
				parsedValue = parsedValue.replaceAll('"', '""');
			}
		} else {
			console.log("NOT STRING: ", parsedValue, columnFromConfig);
		}
		parsedValue = `"${parsedValue}"`;
	}
	const currency = availableCurrencies.find((curr) => parsedValue.includes(curr.value));

	if (currency) {
		parsedValue = parsedValue.replace(currency.value, "");
	}

	return parsedValue;
};

export const exportCsv = (
	columns: Array<string>,
	columnsConfig: ListConfigColumn[],
	filters: ListConfigFilter[],
	data: any[],
	exportConfig: ExportConfig | undefined
): void => {
	let csvContent = "data:text/csv;charset=UTF-8,";
	const rows: any[] = [];
	const thead: string[] = [];
	columns.forEach((column) => {
		const columnFromConfig = columnsConfig.find((f) => f.id === column);
		if (columnFromConfig) {
			thead.push(`"${columnFromConfig?.name}"`);
		}
	});
	rows.push(thead);
	data.forEach((item) => {
		const bodyRow: string[] = [];
		columns.forEach((column) => {
			const columnFromConfig = columnsConfig.find((f) => f.id === column);
			if (columnFromConfig) {
				let val;
				if (columnFromConfig.renderExport) {
					val = columnFromConfig.renderExport(item);
				} else if (columnFromConfig.render) {
					val = columnFromConfig.render(item);
				} else {
					val = getCustomFieldExportValue(columnFromConfig.id, item);
					val = val ? val : item[columnFromConfig?.id];
				}
				if (val === null || val === undefined) {
					val = "";
				}

				const parsedValue = parseCsvColumnValue(filters, columnFromConfig, val);
				bodyRow.push(parsedValue);
			}
		});
		rows.push(bodyRow);
	});

	const isSummaryVisible = columnsConfig.some((config) => config.renderSummary);
	if (isSummaryVisible) {
		const summaryRow: string[] = [];
		columns.forEach((column) => {
			const columnFromConfig = columnsConfig.find((f) => f.id === column);
			if (columnFromConfig?.renderSummary) {
				const parsedValue = parseCsvColumnValue(filters, columnFromConfig, columnFromConfig.renderSummary());
				summaryRow.push(parsedValue);
			}
		});
		rows.push(summaryRow);
	}

	// csvContent += rows.map(e => e.join(',')).join("\n");
	csvContent = rows.map((e) => e.join(",")).join("\n");
	csvContent = csvContent.replace(/,\n/g, "\n");

	createCSVFile(csvContent, exportConfig);
};

export const exportPdf = async (
	columns: Array<string>,
	columnsConfig: ListConfigColumn[],
	filterNames: Array<string>,
	data: any[],
	exportConfig: ExportConfig | undefined
): Promise<void> => {
	const table = generatePdfTable(columns, columnsConfig, data, exportConfig);
	let organizationName: string | undefined = undefined;
	let companyName: string | undefined = undefined;
	let taxIdNo: string | undefined = undefined;

	if (
		(exportConfig?.company && exportConfig.company !== "undefined") ||
		(exportConfig?.taxIdNo && exportConfig.taxIdNo !== "undefined")
	) {
		if (exportConfig?.company && exportConfig.company !== "undefined") companyName = exportConfig.company;
		if (exportConfig?.taxIdNo && exportConfig.taxIdNo !== "undefined") taxIdNo = exportConfig.taxIdNo;
	} else if (exportConfig?.organization && exportConfig.organization !== "undefined") {
		organizationName = exportConfig?.organization;
	}

	let filterDefinition: Record<string, any> | null = null;
	if (filterNames.length > 0) {
		filterDefinition = {
			text: `${i18next.t("lib:common.word.filters")}:\n ${filterNames.join("\n")}`,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 3 : 12,
		};
	}
	const docDefinition: any = {
		pageOrientation: table.pageOrientation,
		pageMargins: [20, 20, 20, 40],
		footer(currentPage: any, pageCount: any) {
			const d = new Date();
			const utc = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
			let footerFirstColumnText = "";

			if (organizationName) footerFirstColumnText += `${i18next.t("lib:common.word.venue")}: ${organizationName}`;
			if (companyName) footerFirstColumnText += `\n ${companyName}`;
			if (taxIdNo) footerFirstColumnText += `\n ${i18next.t("lib:common.word.tax_id_no.title")}: ${taxIdNo}`;
			footerFirstColumnText += `\n ${utc} by GoPOS.pl`;

			return {
				columns: [
					{
						text: `${footerFirstColumnText}`,
						alignment: "left",
						fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
						margin: [20, organizationName ? 10 : 0, 0, 0],
					},
					{
						text: `${i18next.t("lib:common.word.page")} ${currentPage.toString()} ${i18next.t(
							"lib:common.word.of"
						)} ${pageCount}`,
						fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
						alignment: "right",
						margin: [0, 20, 20, 0],
					},
				],
			};
		},
		content: [
			{
				text: `${exportConfig?.title}`,
				bold: true,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 6 : 15,
				alignment: "center",
				margin: [0, 0, 0, 20],
			},
			filterDefinition,
			{
				style: "tableExample",
				color: "#444",
				table,
			},
		],
		styles: table.overWidth ? RenderColumnUtils.getPdfStylesSmall() : RenderColumnUtils.getPdfStyles(),
	};

	const pdfMake = (await import("pdfmake/build/pdfmake")).default;
	const pdfFonts = (await import("pdfmake/build/vfs_fonts")).default;
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	pdfMake.createPdf(docDefinition).download(`${exportConfig?.filename}`);
};

const getTableStyles = (exportConfig?: ExportConfig) => ({
	bold: {
		bold: true,
	},
	small: {
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 3 : 6,
		color: "#9b9b9b",
	},
	header: {
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
		bold: true,
	},
	subheader: {
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
		bold: true,
		margin: [0, 10, 0, 5],
	},
	tableExample: {
		margin: [0, 10, 0, 0],
	},
	tableHeader: {
		bold: true,
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 1 : 10,
		color: "#333a38",
	},
	tableSmall: {
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
		color: "#6b6d6d",
	},
	tableCell: {
		bold: false,
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
		color: "#464e4c",
	},
	tableCellNested: {
		bold: false,
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
		color: "#464e4c",
		fillColor: "#bcc0c7",
	},
	evenDraftCell: {
		bold: false,
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
		color: "#464e4c",
		fillColor: "#F0F0F0",
	},
	draftCell: {
		bold: false,
		fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
		color: "#464e4c",
	},
});

const generatePdfTable = (
	columns: Array<string>,
	columnsConfig: ListConfigColumn[],
	data: any[],
	exportConfig?: ExportConfig
) => {
	const widths: (string | number)[] = [];
	const tHead: any[] = [];
	const A4Height = 842;

	const filteredColumns = columns.filter((column) =>
		columnsConfig.find((configColumn) => column === configColumn.id)
	);

	const getStyles = (column: any) => {
		if (column.styleOverride) {
			return [
				RenderColumnUtils.parsePdfMoneyStyles(column?.styleOverride),
				getTableStyles(exportConfig).tableCell,
			];
		}
		return getTableStyles(exportConfig).tableCell;
	};

	filteredColumns.forEach((column) => {
		const columnFromConfig = columnsConfig.find((f) => f.id === column);
		if (columnFromConfig) {
			tHead.push({
				text: columnFromConfig?.name,
				style: columnFromConfig?.styleOverride
					? [
							RenderColumnUtils.parsePdfMoneyStyles(columnFromConfig?.styleOverride),
							getTableStyles(exportConfig).tableHeader,
					  ]
					: getTableStyles(exportConfig).tableHeader,
			});
			widths.push("auto");
		}
	});
	const tableBody: any[] = [];
	const table = {
		widths,
		body: tableBody,
		dontBreakRows: true,
		pageOrientation: exportConfig?.pdfOrientation ?? "landscape",
		overWidth: false,
	};
	table.body.push(tHead);

	const newWidthsArray = table.widths.map(() => 0);

	const defaultMaxWidth = (A4Height * 1.5) / columns.length;

	if (Array.isArray(data)) {
		data.forEach((item) => {
			const bodyRow: any[] = [];
			filteredColumns.forEach((column, index) => {
				const columnFromConfig = columnsConfig.find((f) => f.id === column);
				if (columnFromConfig) {
					if (columnFromConfig.renderExport) {
						bodyRow.push({
							text: { text: columnFromConfig.renderExport(item), noWrap: false },
							style: getStyles(columnFromConfig),
						});
					} else if (columnFromConfig.render) {
						bodyRow.push({
							text: { text: columnFromConfig.render(item), noWrap: false },
							style: getStyles(columnFromConfig),
						});
					} else {
						const cfValue = getCustomFieldExportValue(columnFromConfig.id, item);
						const text = cfValue ? cfValue : item[`${columnFromConfig.id}`];
						bodyRow.push({
							text: { text, noWrap: false },
							style: getStyles(columnFromConfig),
						});
					}
				}

				if (columnFromConfig?.exportColumnFixedWidth) {
					newWidthsArray[index] = columnFromConfig.exportColumnFixedWidth;
				} else {
					const maxWidth = columnFromConfig?.exportColumnWidth || defaultMaxWidth;
					const row = bodyRow[index];
					if (row) {
						const headLength = tHead[index]?.text.length;
						const contentLength = !row.text.text ? headLength : row.text.text.length;
						const text = (headLength >= contentLength ? tHead[index]?.text : row.text.text) || "";
						if (text) {
							const width = estimatePdfTextWidth(text, headLength >= contentLength, exportConfig);
							if (width > maxWidth) {
								newWidthsArray[index] = maxWidth;
							} else {
								newWidthsArray[index] = Math.max(newWidthsArray[index], width);
							}
						}
					}
				}
			});
			table.body.push(bodyRow);
		});
	}

	const isSummaryVisible = columnsConfig.some((config) => config.renderSummary);
	if (isSummaryVisible) {
		const summaryRow: any[] = [];
		filteredColumns.forEach((column, index) => {
			const columnFromConfig = columnsConfig.find((f) => f.id === column);
			if (columnFromConfig?.renderSummary) {
				const text = String(columnFromConfig.renderSummary());
				summaryRow.push({
					text: { text, noWrap: false },
					style: getStyles(columnFromConfig),
				});

				const maxWidth = columnFromConfig?.exportColumnWidth || defaultMaxWidth;

				const headLength = tHead[index]?.text.length;
				const contentLength = !text ? headLength : text.length;
				const newText = (headLength >= contentLength ? tHead[index]?.text : text) || "";
				if (newText) {
					const width = estimatePdfTextWidth(newText, headLength >= contentLength, exportConfig);
					if (width > maxWidth) {
						newWidthsArray[index] = maxWidth;
					} else {
						newWidthsArray[index] = Math.max(newWidthsArray[index], width);
					}
				}
			}
		});
		table.body.push(summaryRow);
	}

	table.widths = newWidthsArray.map((width, index) => {
		if (!width) return table.widths[index];
		return width;
	});

	const tableWidthSum: number = table.widths
		.map((width) => {
			return Number(width) || 0;
		})
		.reduce((acc, curr) => {
			return acc + curr;
		}, 0);

	const fullTableWidth = tableWidthSum + 20 * 2 + 7 * filteredColumns.length;

	// table.pageOrientation = fullTableWidth <= A4Width ? "portrait" : table.pageOrientation;
	if (fullTableWidth > A4Height) {
		table.widths = table.widths.map((width, index) => {
			return (table.widths[index] as number) * 0.85;
		});
		table.overWidth = true;
	} else {
		table.overWidth = false;
	}
	return table;
};

function estimatePdfTextWidth(text: string, isHeader: boolean, exportConfig?: ExportConfig) {
	const lines = text.toString().split("\n");
	const longestLine = lines.reduce((longest, current) => (current.length > longest.length ? current : longest));
	// estimate
	const font = "Noto Sans";
	let fontSize: number;
	if (exportConfig?.pdfFontSize) {
		fontSize = isHeader ? Number(exportConfig.pdfFontSize) + 1 : Number(exportConfig.pdfFontSize);
	} else {
		fontSize = isHeader ? 11 : 10;
	}

	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d")!;
	context.font = `${fontSize}px ${font}`;
	let width = 0;
	for (let i = 0; i < longestLine.length; i++) {
		const characterWidth = context.measureText(longestLine[i]).width;
		width += characterWidth;
	}
	return width;
}
