import React, { JSX, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import { LoadingContainer } from "go-core/components/Loading";
import ReportData from "go-report/reports";
import {
	getChartReport,
	getChartType,
	getLastMonthFilterBetween,
	getReport,
	getReportCustomFieldsResources,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import { ReportDiscountsUtils } from "../../../../../../../../utils/reports/ReportDiscountsUtils";

interface Props {
	resource?: Record<string, any>;
}

const listName = "ORDER_PROMOTIONS_REPORT";
const resourceType = "ORDER_PROMOTIONS_REPORT";

const Report = ({ resource }: Props): JSX.Element | null => {
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const organization = useSelector(selectOrganization);
	const [filtersParams, setFilterParams] = useState("");

	if (!resource) return null;
	const data = resource.read();

	let config = {
		reportConfigId: "order_promotions",
		type: "order_promotion",
		defaultGroup: "NONE",
		aggregatePrefix: "sales",
		selectedColumns: ReportDiscountsUtils.getSelectedColumns("order_promotions"),
		groups: ReportDiscountsUtils.getGroups(t),
		groupsInfo: ReportDiscountsUtils.getGroupsInfo(t),
		showPercent: true,
		selectedGroups: ["NAME"],
		chart: {
			title: t("common.word.discounts"),
			lineTitle: "",
			aggregate: "total_money",
			type: getChartType(),
			parseGroup: (group: string) => {
				if (group === "HOUR_OF_DAY") return "ORDER_CLOSED_AT_HOUR_OF_DAY";
				if (group === "DAY_OF_MONTH") return "ORDER_CLOSED_AT_DAY_OF_MONTH";
				if (group === "DATE") return "ORDER_CLOSED_AT_DATE";
				if (group === "DAY_OF_WEEK") return "ORDER_CLOSED_AT_DAY_OF_WEEK";
				return group;
			},
		},
		columns: ReportDiscountsUtils.getColumns(t),
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date",
				value: getLastMonthFilterBetween().split("=")[1],
				condition: getLastMonthFilterBetween().split("=")[0],
				visible: true,
			},
		],
		filters: ReportDiscountsUtils.getFilters(t, "order_promotion", filtersParams),
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				columns: ReportDiscountsUtils.getSelectedColumns("order_promotions"),
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			setFilterParams(params.f);
			return Promise.all([
				getReport(
					apiReport.getReportsCustom,
					params,
					organization?.id,
					config.filters,
					config.type,
					sourceToken
				),
				getChartReport(
					apiReport.getReportsCustom,
					params,
					"NAME",
					organization.id,
					config.filters,
					config.type,
					sourceToken,
					5
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return getChartReport(
				apiReport.getReportsCustom,
				params,
				"NAME",
				organization?.id,
				config.filters,
				config.type,
				sourceToken,
				5
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.discount_report.field.export_config.title"),
			filename: t("common.word.discounts"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		withCompareDateRange: true,
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
	} as ReportConfig;

	data.fields = getReportCustomFieldsResources(data.fields, ["ORDER"], t);

	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

const OrganizationReportsDiscountsPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "ORDER",
			name: t(`enums.custom_fields.resources.ORDER`),
		},
	];

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};

export default OrganizationReportsDiscountsPage;
