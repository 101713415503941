import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { EmployeeApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "EMPLOYEE";
const resourceType = "EMPLOYEE";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const [params, setParams] = useState<Record<string, any>>({});
	const confirmation = useConfirmation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<EmployeeApi[]>([]);
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContextValue = React.useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (fetchParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(fetchParams);
			setItems(res);
		}
	};

	const onRemoveEmployee = async (item: EmployeeApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.irreversible_action", { ns: "lib" }),
			});
			await api.organization().removeEmployee(item.id);
			setItems(items.filter((dataItem) => dataItem.id !== item.id));
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh(params);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onHireEmployee = async (item: EmployeeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			await api.organization().hireEmployee(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh(params);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onFireEmployee = async (item: EmployeeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			await api.organization().fireEmployee(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh(params);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onFireEmployees = async (
		multipleActionItems: EmployeeApi[],
		listParams?: ListParamsType,
		areAllItemsSelected?: boolean,
		multipleActionParams?: MultipleActionsParams
	) => {
		const idsAsString = multipleActionItems.map((item) => item.id.toString()).join(",");

		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		const formattedParams = listParams ? listDataParams(listParams) : {};
		formattedParams.include = "role,role.permissions,workplace,workplaces,workplaces.workplace";
		try {
			await api.organization().fireEmployees(idsAsString, multipleActionParams, areAllItemsSelected);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh(formattedParams);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onHireEmployees = async (
		multipleActionItems: EmployeeApi[],
		listParams?: ListParamsType,
		areAllItemsSelected?: boolean,
		multipleActionParams?: MultipleActionsParams
	) => {
		const idsAsString = multipleActionItems.map((item) => item.id.toString()).join(",");

		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		const formattedParams = listParams ? listDataParams(listParams) : {};
		formattedParams.include = "role,role.permissions,workplace,workplaces,workplaces.workplace";
		try {
			await api.organization().hireEmployees(idsAsString, multipleActionParams, areAllItemsSelected);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh(formattedParams);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRemoveEmployees = async (
		multipleActionItems: EmployeeApi[],
		listParams?: ListParamsType,
		areAllItemsSelected?: boolean,
		multipleActionParams?: MultipleActionsParams
	) => {
		const idsAsString = multipleActionItems.map((item) => item.id.toString()).join(",");

		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.irreversible_action", { ns: "lib" }),
		});
		const formattedParams = listParams ? listDataParams(listParams) : {};
		formattedParams.include = "role,role.permissions,workplace,workplaces,workplaces.workplace";
		try {
			await api.organization().removeEmployees(idsAsString, multipleActionParams, areAllItemsSelected);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh(formattedParams);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onCopyEmployee = (employeeId: number) => {
		history.push({ pathname: `/${organization.id}/employees/new`, state: { employeeId } });
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: EmployeeApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<ListImageName
								showColor={true}
								data={{ ...item, limitTextWidth: 200 }}
								imageLink={item.avatar_link}
								link={`${location.pathname}/${item.id}`}
							/>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: EmployeeApi) => item.name,
			},
			{
				id: "role",
				name: t("modules.employee.field.role.title"),
				type: "search_select" as FilterType,
				render: (item: EmployeeApi) => {
					return <span>{item.venue_role?.name}</span>;
				},
				renderExport: (item: EmployeeApi) => {
					return item.venue_role?.name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getRolesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "card_code",
				name: t("modules.employee.field.card_code.title"),
				type: "text" as FilterType,
			},
			{
				id: "hourly_rate",
				name: t("modules.employee.field.hourly_rate_net.title"),
				type: "number" as FilterType,
				render: (item: EmployeeApi) => {
					return FormatMoney(item.hourly_rate);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "workplace",
				name: t("modules.employee.field.workplace.title"),
				type: "search_select" as FilterType,
				render: (item: EmployeeApi) => {
					return item.workplace?.name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getWorkplacesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			} as ListConfigField,
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					HIRED: t("enums.common.status.HIRED"),
					FIRED: t("enums.common.status.FIRED"),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: EmployeeApi) => `${location.pathname}/${item.id}`,
			},
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: EmployeeApi) => `${location.pathname}/${item.id}/edit`,
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				visible: (item: EmployeeApi) => item.status !== "REMOVED",
				click: (item: EmployeeApi) => onRemoveEmployee(item),
			},
			{
				name: t("modules.employee.action.hire.title"),
				visible: (item: EmployeeApi) => {
					return item.status === "FIRED";
				},
				click: (item: EmployeeApi) => onHireEmployee(item),
			},
			{
				name: t("modules.employee.action.fire.title"),
				visible: (item: EmployeeApi) => {
					return item.status === "HIRED";
				},
				click: (item: EmployeeApi) => onFireEmployee(item),
			},
			{
				name: t("common.action.copy", { ns: "lib" }),
				click: (item: EmployeeApi) => onCopyEmployee(item.id),
			},
		],
		multipleActions: [
			{
				name: t("modules.employee.action.fire.title"),
				click: (
					multipleActionsItems: EmployeeApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					onFireEmployees(multipleActionsItems, listParams, areAllItemsSelected, multipleActionParams);
				},
				visible: (multipleActionsItems: EmployeeApi[]) =>
					multipleActionsItems.some((item) => item.status === "HIRED"),
			},
			{
				name: t("modules.employee.action.hire.title"),
				click: (
					multipleActionsItems: EmployeeApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					onHireEmployees(multipleActionsItems, listParams, areAllItemsSelected, multipleActionParams);
				},
				visible: (multipleActionsItems: EmployeeApi[]) =>
					multipleActionsItems.some((item) => item.status === "FIRED"),
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (
					multipleActionsItems: EmployeeApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					onRemoveEmployees(multipleActionsItems, listParams, areAllItemsSelected, multipleActionParams);
				},
				visible: (multipleActionsItems: EmployeeApi[]) =>
					multipleActionsItems.some((item) => item.status !== "DELETED"),
			},
		],
		selectedColumns: ["name", "role", "workplace", "hourly_rate"],
		segments: [
			{
				id: "all",
				name: t("common.word.all_male"),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted_male", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e",
					},
				],
			},
			{
				id: "hired",
				name: t("modules.employee.field.hired.title"),
				slug: "hired",
				filters: [
					{
						filterId: "status",
						value: "HIRED",
						condition: "e",
					},
				],
			},
			{
				id: "fired",
				name: t("modules.employee.field.fired.title"),
				slug: "fired",
				filters: [
					{
						filterId: "status",
						value: "FIRED",
						condition: "e",
					},
				],
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "hired"),
		exportConfig: {
			title: t("modules.employee.field.export_config.title"),
			filename: t("modules.employee.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "role,workplace,custom_fields";
			setParams(fetchParams);
			return api.organization().getEmployees(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		getStatus: (item: EmployeeApi) => {
			if (item.status === "HIRED") return "ENABLED";
			if (item.status === "FIRED") return "WARNING";
			return "DISABLED";
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
		fetchTotalItemsCountForSelectedFilters: (
			fetchTotalItemsParams: Record<string, any>,
			sourceToken?: CancelTokenSource
		) => api.organization().getEmployeeCount(fetchTotalItemsParams, { cancelToken: sourceToken?.token }),
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
			onFetch={(fetchItems: EmployeeApi[]) => {
				setItems(fetchItems);
			}}
			canManageMultiActionsForAllItems
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationEmployeesIndexIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContextValue = React.useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "EMPLOYEE",
			name: t(`enums.custom_fields.resources.EMPLOYEE`),
		},
	];
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.employee.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			path: `${props.match.url}/new`,
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.employee.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationEmployeesIndexIndexPage;
