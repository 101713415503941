export enum PosReportsBackendAcceptableParams {
	STATUS = "STATUS",
	ID = "ID",
	OPENED_AT = "OPENED_AT",
	CLOSED_AT = "CLOSED_AT",
	TYPE = "TYPE",
	TERMINAL = "TERMINAL",
	DESCRIPTION = "DESCRIPTION",
	INCOME_AMOUNT = "INCOME_AMOUNT",
}
