import React, { FC } from "react";
import CSS from "csstype";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as MoreSVG } from "../../../../../../../../../../../../../images/svg/more.svg";
import { ReactComponent as CopyPriceSVG } from "../../../../../../../../../../../../../images/svg/priceLists/copy_price.svg";
import { ReactComponent as RemovePriceSVG } from "../../../../../../../../../../../../../images/svg/virtualTable/remove.svg";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
} from "../../../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn } from "../../../../../../../components/VirtualTable/types";
import {
	getVirtualTableColumnStyles,
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../../components/VirtualTable/utils";

interface Props {
	columnWidths: Record<string, number>;
	style: CSS.Properties;
	item: MenuPriceListFlatApi;
	priceLists: MenuPriceListApi[];
	form: UseFormReturn<any>;
	applyPriceToAll: (itemIndex: number) => void;
	defaultColumns: VirtualTableColumn[];
	setShowPriceListsModal: (value: boolean) => void;
	setApplyPriceToItemIndex: (index: number) => void;
	maxZIndex: number;
}

const SpreadsheetPriceVirtualTableRow: FC<Props> = (props) => {
	const item = props.item;
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const {
		control,
		formState: { errors },
		getValues,
		setValue,
	} = props.form;
	const currency = organization.currency || "PLN";
	const styleTr = { ...props.style };
	if (styleTr.width !== undefined) {
		styleTr.width = undefined;
	}

	const placeholderValue = useWatch({
		control,
		name: `items.${item.index}.price`,
	});

	const parentIndex = getValues("items").findIndex((x: MenuPriceListFlatApi) => x.item_id === item.sub_item_id);
	const parentPrice = useWatch({ control, name: `items.${parentIndex}.price` });
	const getColumnStyles = (key: string, index?: number) => {
		return getVirtualTableColumnStyles(key, props.columnWidths, props.maxZIndex, index);
	};
	const onChange = (val?: number) => {
		getValues("items")
			.filter((f: MenuPriceListFlatApi) => f.sub_item_name === item.item_name)
			?.forEach((it: MenuPriceListFlatApi) => {
				if (val || val === 0) {
					setValue(`items.${it.index}.default_price.amount`, val);
				} else {
					setValue(`items.${it.index}.default_price.amount`, "");
				}
			});
	};

	const getDefaultPrice = () => {
		if (placeholderValue?.amount || placeholderValue?.amount === 0) {
			return placeholderValue.amount;
		}

		if (item?.default_price?.amount || item?.default_price?.amount === 0) {
			return item.default_price.amount;
		}
		if (parentPrice?.amount || parentPrice?.amount === 0) {
			return parentPrice.amount;
		}

		return 0;
	};

	const getPriceForPriceList = (priceListId: number) => {
		if (placeholderValue?.amount || placeholderValue?.amount === 0) {
			return placeholderValue.amount;
		}

		const parentItem = getValues(`items.${parentIndex}`);
		const priceListItem = parentItem?.price_list_items.filter(
			(priceList: Record<string, any>) => priceList.price_list_id === priceListId
		)[0];
		const parentPricePriceList = priceListItem?.price;

		if (parentPricePriceList?.amount || parentPricePriceList?.amount === 0) {
			return parentPricePriceList.amount;
		} else if (item?.default_price?.amount || item?.default_price?.amount === 0) {
			return item.default_price.amount;
		} else if (getDefaultPrice() || getDefaultPrice() === 0) {
			return getDefaultPrice();
		}

		return 0;
	};

	const drawPricePlaceholder = (priceListId?: number) => {
		const amount = priceListId ? getPriceForPriceList(priceListId) : getDefaultPrice();

		return FormatMoney({ amount, currency });
	};

	const renderDefaultColumnCells = () => {
		let prevLeftPosition = 0;
		return props.defaultColumns.map((col, index) => {
			const prevColumn = props.defaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const lastColumn = index === props.defaultColumns.length - 1;
			const isHide = isVirtualTableColumnHide(col.id, props.defaultColumns);
			const className = `${isHide ? "d-none" : ""} sticky-column ${lastColumn ? "with-wider-border" : ""}`;
			const style = {
				...getColumnStyles(col.id, index),
				left: leftPosition,
				position: "sticky",
			} as React.CSSProperties;

			if (col.id === "category_name")
				return (
					<td className={className} style={style} key={col.name}>
						<div className="name-box" title={item.category_name}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/categories/${item.category_id}`}
							>
								{item.category_name}
							</Link>
						</div>
					</td>
				);
			if (col.id === "item_group_name")
				return (
					<td className={className} style={style} key={col.name}>
						<div className="name-box" title={item.item_group_name}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/item_groups/${item.item_group_id}`}
							>
								{item.item_group_name}
							</Link>
						</div>
					</td>
				);
			if (col.id === "item_name") {
				const name = `${item.item_name}\n`;
				const sku = item.sku && `${t("modules.group_edit.field.sku.title")}: ${item.sku}\n`;
				const barcodes =
					item.barcodes.length > 0 &&
					`${t("modules.group_edit.field.barcodes.title")}: ${item.barcodes.join(", ")}`;
				const title = `${name}${sku ?? ""}${barcodes || ""}`;

				return (
					<td key={title} className={className} style={style}>
						<div
							className={`name-box ${
								item.item_name === t("common.word.default") && "text-black-50"
							} sticky-column`}
							title={title}
						>
							{item.item_name}
						</div>
					</td>
				);
			}
			if (col.id === "item_full_name")
				return (
					<td className={className} style={style} key={col.name}>
						<div className="name-box" title={item.item_full_name}>
							{item.item_full_name}
						</div>
					</td>
				);
			if (col.id === "modifier_group_name")
				return (
					<td className={className} style={style} key={col.name}>
						<div className="name-box" title={item.modifier_group_name}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/modifier_groups/${item.modifier_group_id}`}
							>
								{item.modifier_group_name}
							</Link>
						</div>
					</td>
				);
			if (col.id === "sub_item_name")
				return (
					<td className={className} style={style} key={col.name}>
						<div className="name-box" title={item.sub_item_name}>
							{item.sub_item_name}
						</div>
					</td>
				);
			if (col.id === "price")
				return (
					<td className={className} style={style} key={col.name}>
						<div className="input-box">
							<FormMoneyInput
								control={control}
								errors={errors}
								currency={currency}
								placeholder={drawPricePlaceholder(undefined)}
								onChange={onChange}
								allowNegative
								name={`items.${item.index}.price.amount`}
							/>
							<Dropdown as={ButtonGroup} className="ms-auto" drop={"down"}>
								<Dropdown.Toggle as={MoreSVG} className="more-svg" />
								<Dropdown.Menu>
									<Dropdown.Item href="#" onClick={() => props.applyPriceToAll(item.index)}>
										{t("modules.group_edit.field.all.title")}
									</Dropdown.Item>
									<Dropdown.Item
										href="#"
										onClick={() => {
											props.setShowPriceListsModal(true);
											props.setApplyPriceToItemIndex(item.index);
										}}
									>
										{t("modules.group_edit.field.chosen.title")}
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</td>
				);
			return <></>;
		});
	};

	return (
		<tr style={styleTr}>
			{renderDefaultColumnCells()}
			{props.priceLists.map((priceList) => {
				const hasValue = getValues(`items.${item.index}.price_list_items.${priceList.index}.price.amount`);
				const isHide = isVirtualTableColumnHide(priceList.id, props.priceLists);
				return (
					<td
						key={`${item.index}_${priceList.name}`}
						className={`${isHide ? "d-none" : ""}`}
						style={getColumnStyles(priceList.name)}
					>
						<div className="input-box">
							<FormMoneyInput
								control={control}
								errors={errors}
								currency={currency}
								placeholder={drawPricePlaceholder(priceList.id)}
								name={`items.${item.index}.price_list_items.${priceList.index}.price.amount`}
								allowNegative
							/>
							{hasValue || hasValue === 0 ? (
								<RemovePriceSVG
									style={{ cursor: "pointer" }}
									onClick={() =>
										setValue(
											`items.${item.index}.price_list_items.${priceList.index}.price.amount`,
											""
										)
									}
								/>
							) : (
								<CopyPriceSVG
									style={{ cursor: "pointer" }}
									onClick={() =>
										setValue(
											`items.${item.index}.price_list_items.${priceList.index}.price.amount`,
											placeholderValue?.amount
										)
									}
								/>
							)}
						</div>
					</td>
				);
			})}
		</tr>
	);
};

export default SpreadsheetPriceVirtualTableRow;
