import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:confirmation.title");
	t("lib:confirmation.message.cancel");
	t("lib:confirmation.message.remove");
	t("lib:confirmation.message.activate");
	t("lib:confirmation.message.disable");
	t("lib:confirmation.message.action");
	t("lib:confirmation.message.irreversible_action");
};
export interface ConfirmationModalOptions {
	title?: string;
	message?: string;
	loading?: boolean;
}

interface ConfirmationModalProps extends ConfirmationModalOptions {
	open: boolean;
	loading: boolean;
	onSubmit: () => void;
	onClose: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
	open,
	loading,
	title,
	message,
	onSubmit,
	onClose,
}): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Modal show={open} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<ButtonLoading variant="danger" onClick={onSubmit} loading={loading}>
					{t("lib:common.word.yes")}
				</ButtonLoading>
				<Button variant="light" onClick={onClose}>
					{t("lib:common.word.no")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const ConfirmationServiceContext = React.createContext<(options: ConfirmationModalOptions) => Promise<void>>(
	Promise.reject
);

export const useConfirmation = (): ((options: ConfirmationModalOptions) => Promise<void>) =>
	React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider: FC = (props): JSX.Element => {
	const [confirmationState, setConfirmationState] = React.useState<ConfirmationModalOptions | null>(null);
	const [loading, setLoading] = useState(false);
	const awaitingPromiseRef = React.useRef<{
		resolve: () => void;
		reject: () => void;
	}>();

	const openConfirmation = (options: ConfirmationModalOptions) => {
		setConfirmationState(options);
		setLoading(false);
		return new Promise<void>((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject };
		});
	};

	const handleClose = () => {
		setConfirmationState(null);
	};

	const handleSubmit = () => {
		setLoading(true);
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.resolve();
		}
		setConfirmationState(null);
	};
	return (
		<React.Fragment>
			<ConfirmationServiceContext.Provider value={openConfirmation}>
				{props.children}
			</ConfirmationServiceContext.Provider>

			<ConfirmationModal
				loading={loading}
				open={Boolean(confirmationState)}
				onSubmit={handleSubmit}
				onClose={handleClose}
				{...confirmationState}
			/>
		</React.Fragment>
	);
};
