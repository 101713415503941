import { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmptyList from "go-core/components/EmptyList";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { PosReportApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	report: PosReportApi;
}

const DiscountsTabPreview: FC<Props> = ({ report }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const tdStyles = {
		padding: "14px 0px",
	};

	const reportFilterType = report.type === "SHIFTWORK" ? "shiftwork_report" : "drawer_report";

	return (
		<Card>
			<Card.Body>
				{report.discounts?.length > 0 ? (
					<>
						<div className={"table-responsive mb-10"}>
							<table className={"table table-preview mb-0"}>
								<thead>
									<tr>
										<th>{t("lib:common.word.name")}</th>
										<th>{t("common.word.amount")}</th>
										<th>{t("common.word.quantity")}</th>
										<th className="w-1" />
									</tr>
								</thead>
								<tbody>
									{report.discounts.map((discount, index) => {
										const name =
											discount.type === "FIXED_PRICE"
												? t("enums.discounts.type.FIXED_PRICE")
												: discount.name === "AMOUNT"
												? t("enums.discounts.type.AMOUNT_DSC")
												: discount.name;
										return (
											<tr key={index}>
												<td style={tdStyles}>{name}</td>
												<td>{FormatMoney(discount.amount)}</td>
												<td>{discount.count}</td>
												<td style={{ whiteSpace: "nowrap" }}>
													{discount.id ? (
														<Link
															target={"_blank"}
															rel={"noreferrer"}
															to={`/${organization.id}/sales/orders?f=${btoa(
																unescape(
																	encodeURIComponent(
																		`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${reportFilterType}|e=${report.id}${FILTER_SEPARATOR}discount_type|e=${discount.type}${FILTER_SEPARATOR}discount|e=${discount.id}`
																	)
																)
															)}`}
															className={"btn btn-primary-light"}
														>
															{t("modules.pos_report.action.show_orders.title")}
														</Link>
													) : (
														<Link
															target={"_blank"}
															rel={"noreferrer"}
															to={`/${organization.id}/sales/orders?f=${btoa(
																unescape(
																	encodeURIComponent(
																		`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${reportFilterType}|e=${report.id}${FILTER_SEPARATOR}discount_type|e=${discount.type}${FILTER_SEPARATOR}discount|u`
																	)
																)
															)}`}
															className={"btn btn-primary-light"}
														>
															{t("modules.pos_report.action.show_orders.title")}
														</Link>
													)}
												</td>
											</tr>
										);
									})}
									<tr className={"summary"}>
										<td>
											<strong>{t("common.word.sum")}</strong>
										</td>
										<td>
											<strong>
												{FormatMoney({
													amount: report.discounts.reduce(
														(acc, val) => acc + (val.amount.amount ? val.amount.amount : 0),
														0
													),
													currency: report?.discounts[0].amount.currency,
												})}
											</strong>
										</td>
										<td>
											<strong>
												{t("modules.pos_report.field.discount.title", {
													count: report.discounts.reduce((acc, val) => acc + val.count, 0),
												})}
											</strong>
										</td>
										<td />
									</tr>
								</tbody>
							</table>
						</div>
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/sales/orders?f=${btoa(
								unescape(
									encodeURIComponent(
										`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${reportFilterType}|e=${report.id}${FILTER_SEPARATOR}discount_count|gt=0`
									)
								)
							)}`}
							className={"btn btn-block btn-add"}
						>
							{t("modules.pos_report.action.show_orders.title")}
						</Link>
					</>
				) : (
					<EmptyList />
				)}
			</Card.Body>
		</Card>
	);
};
export default DiscountsTabPreview;
