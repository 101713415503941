import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as CloseSVG } from "../../../../../../../../../images/svg/liveOrders/close.svg";
import { LiveOrderApi } from "../../../../../../../../../services/Api/Organization/types";
import LiveOrdersListItem from "../../../components/LiveOrdersListItem";

interface Props {
	orders: LiveOrderApi[];
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	focusedOrder: LiveOrderApi | undefined;
}

const LiveOrdersMapMarkerOrdersList: FC<Props> = ({ orders, setFocusedOrder, focusedOrder }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const ref = useRef<any>(null);

	const drawOrders = () => {
		return orders.map((order, index) => {
			const isFocused = order.id === focusedOrder?.id;
			return (
				<LiveOrdersListItem
					key={`live-orders-list-item-${index}-${order.id}`}
					order={order}
					onSelectOrder={() => {
						setFocusedOrder(order);
						history.push(`/${organization.id}/live_orders/map/orders/${order.id}`);
					}}
					className={`${isFocused && "live-orders-list-item--focused"}`}
				/>
			);
		});
	};

	const ordersAddress = {
		...orders[0].delivery.address,
		flat_nr: undefined,
	};

	const handleClosePopup = () => {
		if (ref.current) {
			ref.current._closeButton.click();
		}
	};

	return (
		<Popup ref={ref}>
			<div className="header">
				<div className="d-flex flex-column">
					<h5 className="mb-1 text-primary">
						<FormatAddress address={ordersAddress} />
					</h5>
					<span className="text-muted">
						{t("modules.live_orders.field.orders", { count: orders.length })}
					</span>
				</div>
				<CloseSVG onClick={handleClosePopup} className="close-button" />
			</div>
			{drawOrders()}
		</Popup>
	);
};

export default LiveOrdersMapMarkerOrdersList;
