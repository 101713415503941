import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { ReactComponent as BackSVG } from "../../../../../../../../../images/svg/menu/header-back.svg";
import { ItemApi, ItemGroupApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import CreateMenuPageItemItemGroupModalBody from "./CreateMenuPageItemItemGroupModalBody";

interface Props {
	searchText: string;
	handleCancel: () => void;
	handleSave: (data: ItemGroupApi) => void;
	contextType?: string;
	nestedContext?: boolean;
}

const CreateMenuPageItemItemGroupModal = (props: Props): JSX.Element => {
	const location = useLocation();
	const [itemGroup] = useState<ItemGroupApi>({ name: props.searchText } as ItemGroupApi);
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const form = useForm<ItemGroupApi>({
		criteriaMode: "all",
		defaultValues: itemGroup,
	});
	const { handleSubmit, setError } = form;
	const onSubmit = handleSubmit(async (data: ItemGroupApi) => {
		const objData = {
			name: data.name,
			tax_id: data.tax_id,
			category_id: data.category_id,
			items: [
				{
					position: 0,
					name: data.name,
					price: data.items[0].price,
					discountable: true,
					weighted_type: "DISABLED",
					open_price: false,
				} as ItemApi,
			],
		} as ItemGroupApi;
		try {
			const params: Record<string, any> = { include: "items" };
			const res = await api.organization().createItemGroup(objData, params);
			props.handleSave(res);
			if (!location?.pathname?.includes("item_groups") && !location?.pathname?.includes("modifier_groups"))
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
	});

	return (
		<>
			<Form key="category-modal-form" onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.nestedContext && <BackSVG className="icon me-2" onClick={() => props.handleCancel()} />}
						{t("modules.menu.header.add_new_product.title")}
					</Modal.Title>
				</Modal.Header>
				<CreateMenuPageItemItemGroupModalBody
					searchText={props.searchText}
					handleCancel={props.handleCancel}
					handleSave={props.handleSave}
				/>
			</Form>
		</>
	);
};

export default CreateMenuPageItemItemGroupModal;
