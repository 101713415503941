import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { DiagnoseApi } from "go-component/services/types";
import handleException from "go-core/api/handleException";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDirty } from "go-form/components/FormDirty";
import { FormErrorMessageAlert } from "go-form/components/FormErrorMessageAlert";

interface DiagnosticsFormProps {
	types: boolean[];
}

interface Props {
	handleUpdate: (data: DiagnoseApi[]) => void;
	diagnosticTypes: string[];
	diagnose: (types: string[], organizationId?: number, options?: Record<string, any>) => Promise<string>;
	getDiagnoseProgress: (
		jobUid: string,
		organizationId?: number,
		options?: Record<string, any>
	) => Promise<AxiosResponse>;
	organizationId?: number;
	diagnosticsTranslations?: Record<string, string>;
}

export const DiagnosticsForm = ({
	handleUpdate,
	diagnosticTypes,
	getDiagnoseProgress,
	diagnose,
	organizationId,
	diagnosticsTranslations,
}: Props) => {
	const [loading, setLoading] = useState(false);
	const [selectedAll, setSelectedAll] = useState(false);
	const form = useForm<DiagnosticsFormProps>({
		defaultValues: {
			types: [],
		},
	});
	const {
		formState,
		formState: { errors },
		setValue,
		register,
		handleSubmit,
		setError,
	} = form;
	const { addFlash } = useFlash();
	const timeoutRef = useRef<number | undefined>();
	const cancelTokenSource = useRef<CancelTokenSource>();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		selectAll();

		handleChangeTabTitle("Narzędzia diagnostyczne");
		return () => {
			if (cancelTokenSource.current !== undefined) {
				cancelTokenSource.current.cancel();
			}
			window.clearTimeout(timeoutRef.current);
		};
	}, []);

	const handleSubmitTimeout = (jobUid: string) => {
		timeoutRef.current = window.setTimeout(async () => {
			try {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;

				const res = await getDiagnoseProgress(jobUid, organizationId, {
					cancelToken: newCancelToken.token,
				});
				if (!res.data) {
					setLoading(true);
					handleSubmitTimeout(jobUid);
				} else {
					setLoading(false);
					window.clearTimeout(timeoutRef.current);
					handleUpdate(res.data.data);
				}
			} catch (err) {
				const errs = handleException(err);
				if (errs[0].message === "canceled") return;
				handleError.alert(err, addFlash);
				setLoading(false);
				window.clearTimeout(timeoutRef.current);
			}
		}, 1000);
	};

	const onSubmit = handleSubmit(async (data: DiagnosticsFormProps) => {
		const newData = { ...data } as Record<string, any>;
		newData.types = [];
		diagnosticTypes.forEach((type, index) => {
			if (data.types[index]) {
				newData.types.push(type);
			}
		});
		setLoading(true);

		try {
			const CancelToken = axios.CancelToken;
			const newCancelToken = CancelToken.source();
			cancelTokenSource.current = newCancelToken;
			const res = await diagnose(newData.types, organizationId, {
				cancelToken: newCancelToken.token,
			});
			handleSubmitTimeout(res);
		} catch (e) {
			handleError.form(e, setError, addFlash);
			setLoading(false);
		}
	});

	if (!diagnosticTypes) {
		return <LoadingContainer />;
	}

	const selectAll = () => {
		setSelectedAll(!selectedAll);
		diagnosticTypes.forEach((type, index) => {
			setValue(`types.${index}`, !selectedAll);
		});
	};

	return (
		<>
			<Header title="Narzędzia diagnostyczne" />
			<FormDirty
				formState={formState}
				onSubmit={onSubmit}
				loading={loading}
				buttonSubmitCustom={
					<ButtonLoading loading={loading} variant="primary" type="submit">
						Sprawdź
					</ButtonLoading>
				}
			>
				<fieldset className="form-group">
					<h5>Wybierz zasoby, które chcesz sprawdzić</h5>
					<FormErrorMessageAlert errors={errors} name="types" />
					<div className="row">
						{diagnosticTypes.map((type, index) => {
							return (
								<div key={index} className={"col-md-6"}>
									<FormCheck
										type={"switch"}
										name={`types.${index}`}
										errors={errors}
										label={diagnosticsTranslations?.[type] ?? type}
										register={register}
									/>
								</div>
							);
						})}
					</div>
					<Form.Check
						id="select-all"
						type="switch"
						checked={selectedAll}
						onClick={selectAll}
						label="Zaznacz wszystko"
					/>
				</fieldset>
			</FormDirty>
		</>
	);
};
