import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { MenuAppConfig, WebApp } from "go-app/services/types";
import { isWebView } from "go-core/utils/utils";
import NavigationComponentSecurity from "go-security/components/Navigation";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin, selectUser } from "go-security/services/users/selectors";
import { ConfigContext } from "../../services/Config/context";
import { getApiURL } from "../../utils/envutil";
import NavigationOrganizationMenu from "./components/NavigationOrganizationMenu";
import NavigationUserMenu from "./components/NavigationUserMenu";

const Navigation = (): JSX.Element | null => {
	const user = useSelector(selectUser);
	const organization = useSelector(selectOrganization);
	const isAdmin = useSelector(selectIsAdmin);
	const configService = useContext(ConfigContext);
	const applications = configService.applications();
	const baseUrl = getApiURL();

	if (!user) {
		return null;
	}

	const menuAppConfig: MenuAppConfig = {
		webApp: WebApp.GOPOS,
		userEnteredLocation: organization.company_id !== undefined,
		oldVersionAppsTimestamp: configService.getAppsOldVersionTimestamps(),
	};

	if (isWebView) return <></>;

	return (
		<NavigationComponentSecurity
			user={user}
			organization={organization}
			apps={applications}
			logo={"/graphics/gopos-logo.svg"}
			logo_small={"/graphics/apps/gopos.svg"}
			isAdmin={isAdmin}
			baseUrl={baseUrl}
			menuAppConfig={menuAppConfig}
		>
			{organization.id ? (
				<NavigationOrganizationMenu organization={organization} />
			) : (
				<NavigationUserMenu goaccounts_url={configService.getGoAccountsUrl()} user={user} isAdmin={isAdmin} />
			)}
		</NavigationComponentSecurity>
	);
};

export default Navigation;
