import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import { polandCoords } from "../../../../../LiveOrders/services/orderDelivery";
import DeliveryZoneBasicInfoForm from "./DeliveryZoneBasicInfoForm";
import DeliveryZoneZonesForm from "./DeliveryZoneZonesForm";

interface StateType {
	appId: string;
}

interface Props {
	deliveryZones: DeliveryZoneApi[];
	deliveryZone?: DeliveryZoneApi;
}

const DeliveryZoneForm = ({ deliveryZone, deliveryZones }: Props) => {
	const { addFlash, addSuccessFlash } = useFlash();
	const { state } = useLocation<StateType>();
	const form = useForm<DeliveryZoneApi>({
		criteriaMode: "all",
		defaultValues: {
			...deliveryZone,
			app_id: deliveryZone ? deliveryZone.app_id : state.appId,
		},
	});
	const { formState, handleSubmit, setError, reset, clearErrors } = form;
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const [organizationPosition, setOrganizationPosition] = useState<number[] | undefined>();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const [formErrors, setFormErrors] = useState<ApiError[]>([]);

	useEffect(() => {
		fetchOrganizationAddress();
	}, []);

	const fetchOrganizationAddress = async () => {
		try {
			const res = await apiOrganization.getOrganizationAddress();
			const position =
				res?.coordinates && Object.keys(res?.coordinates).length
					? [res.coordinates.latitude, res.coordinates.longitude]
					: polandCoords;
			setOrganizationPosition(position);
		} catch (err) {
			handleError.alert(err, addFlash);
			setOrganizationPosition(polandCoords);
		}
	};

	const onSubmit = handleSubmit(async (data: DeliveryZoneApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		try {
			let res;
			if (deliveryZone?.id) {
				res = await apiOrganization.updateDeliveryZone(data.id, data, { include: "app" });
				reset(res);
			} else {
				res = await apiOrganization.createDeliveryZone(data);
				history.push(`/${organization.id}/settings/delivery_zones/${res.id}`);
			}
			addSuccessFlash(t("lib:common.flash.saved"));
		} catch (err) {
			handleError.form(err, setError, addFlash);
			const errs = handleException(err);
			setFormErrors(errs);
		}
		setLoading(false);
	});

	if (!organizationPosition) return <LoadingContainer />;

	return (
		<FormDirty
			formState={formState}
			onSubmit={(event) => {
				clearErrors();
				onSubmit(event);
			}}
			loading={loading}
			className="delivery-zone-form"
			noValidate
		>
			<DeliveryZoneBasicInfoForm form={form} setErrors={setErrors} />
			<DeliveryZoneZonesForm
				form={form}
				organizationPosition={organizationPosition}
				deliveryZones={deliveryZones}
				deliveryZone={deliveryZone}
				formErrors={formErrors}
			/>
		</FormDirty>
	);
};

export default DeliveryZoneForm;
