import React, { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import {
	AvailabilitiesProps,
	AvailabilityApi,
	AvailabilityDateItem,
	AvailabilityHourItem,
	AvailabilityStatusApi,
} from "../../../../services/types";
import { buildTime } from "../../components/AvailabilityTimePicker";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const convertListStatusToEntityStatus = (status: AvailabilityStatusApi) => {
	switch (status) {
		case AvailabilityStatusApi.DELETED:
			return "DISABLED";
		case AvailabilityStatusApi.DISABLED:
		case AvailabilityStatusApi.ENABLED:
		default:
			return status;
	}
};

const listName = "AVAILABILITY";
const resourceType = "AVAILABILITY";

const List = ({
	resource,
	requests,
	organizationId,
	organizationName,
	companyName,
	taxIdNo,
	mobileActions,
	pdfFontSize,
	pdfOrientation,
}: ListState & AvailabilitiesProps) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const [items, setItems] = useState<AvailabilityApi[]>([]);
	const { addFlash, addSuccessFlash } = useFlash();
	const [params, setParams] = useState<Record<string, any>>({});
	const segmentContext = useContext(SegmentContext);
	const { restoreAvailability, removeAvailability, getAvailabilities } = requests;

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRestoreAvailability = async (item: AvailabilityApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			await restoreAvailability(item.id, organizationId);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRemoveAvailability = async (item: AvailabilityApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await removeAvailability(item.id, organizationId);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text",
				render: (item: AvailabilityApi) => {
					return (
						<StickyColumnWithEntityStatus
							minWidth={200}
							status={convertListStatusToEntityStatus(item.status)}
							to={`${location.pathname}/${item.id}`}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: AvailabilityApi) => item.name,
			},
			{
				id: "dates",
				name: t("go_component.availability.field.dates.title", { ns: "lib" }),
				render: (item: AvailabilityApi) => {
					return item.dates?.map((date: AvailabilityDateItem) => {
						return (
							<React.Fragment key={date.id}>
								<span>
									{FormatDate(date.date_from || "")} - {FormatDate(date.date_to || "")}
								</span>
								<br />
							</React.Fragment>
						);
					});
				},
				renderExport: (item: AvailabilityApi) => {
					return item.dates
						?.map((date: AvailabilityDateItem) => {
							return `${FormatDate(date.date_from || "")} - ${FormatDate(date.date_to || "")}`;
						})
						.join(", ");
				},
			},
			{
				id: "hours",
				name: t("go_component.availability.field.availability_hours.title", { ns: "lib" }),
				render: (item: AvailabilityApi) => {
					return item.hours?.map((hour: AvailabilityHourItem) => {
						return (
							<React.Fragment key={hour.id}>
								<span>
									{t(`enums.days_short.${hour.day_from}`, { ns: "lib" })}:{" "}
									{hour.hour_from.slice(0, -3)} - {buildTime(hour.hour_to.slice(0, -3), true)}
								</span>
								<br />
							</React.Fragment>
						);
					});
				},
				renderExport: (item: AvailabilityApi) => {
					return item.hours
						?.map((hour: AvailabilityHourItem) => {
							return `${t(`enums.days_short.${hour.day_from}`, { ns: "lib" })}: ${hour.hour_from.slice(
								0,
								-3
							)} - ${buildTime(hour.hour_to.slice(0, -3), true)}`;
						})
						.join(", ");
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: AvailabilityApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: AvailabilityApi) => {
					onRemoveAvailability(item);
				},
				visible: (item: AvailabilityApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: AvailabilityApi) => {
					onRestoreAvailability(item);
				},
				visible: (item: AvailabilityApi) => {
					return item.status === "DELETED";
				},
			},
		],
		selectedColumns: ["name", "dates", "hours"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e",
					},
				],
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "hours,dates";
			setParams(params);
			return getAvailabilities(params, { cancelToken: sourceToken?.token }, organizationId);
		},
		exportConfig: {
			title: t("go_component.availability.field.export_config.title", { ns: "lib" }),
			filename: t("go_component.availability.header.title", { ns: "lib" }),
			organization: organizationName,
			taxIdNo,
			company: companyName,
			pdfOrientation,
			pdfFontSize,
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{
				addAction: () => history.push(`${location.pathname}/new`),
			}}
			onFetch={(fetchItems: AvailabilityApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};

export const SettingsAvailabilitiesIndexPage = (props: RouteComponentProps & AvailabilitiesProps) => {
	const isMobile = useWindowSize().isMobile;
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("go_component.availability.header.title", { ns: "lib" }));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			variant: "primary",
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && (
				<Header title={t("go_component.availability.header.title", { ns: "lib" })} buttons={buttons} />
			)}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					requests={props.requests}
					organizationId={props.organizationId}
					organizationName={props.organizationName}
					taxIdNo={props.taxIdNo}
					companyName={props.companyName}
					mobileActions={mobileActions}
					pdfOrientation={props.pdfOrientation}
					pdfFontSize={props.pdfFontSize}
				/>
			</Suspense>
		</>
	);
};
