import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { delay } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_VALUE_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { RequiredFieldsInformation } from "go-security/components/RequiredFieldsInformation/RequiredFieldsInformation";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	ItemApi,
	ItemGroupApi,
	ItemModifierGroupApi,
	ModifierGroupApi,
	PointOfSaleApi,
	TranslationApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalButton from "../../../../components/translations/EntityTranslationsModalButton";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import ItemGroupForm from "../../components/ItemGroupForm";
import { sortModifierGroupsByPositions } from "../../utils";

interface StateType {
	from: { pathname: string };
	copiedItem: number;
}

const OrganizationMenuItemGroupsNewPage = (): JSX.Element => {
	const org = useSelector(selectOrganization);
	const defaultItemGroup = {
		type: org.category === "SHOP" ? "PRODUCT" : "MODIFIER",
		items: [
			{
				id: Math.random(),
				position: 0,
				open_price: false,
				discountable: true,
				weighted_type: "DISABLED",
				translations: [] as TranslationApi[],
			} as ItemApi,
		],
	} as ItemGroupApi;
	const [itemGroup, setItemGroup] = useState<ItemGroupApi | undefined>(defaultItemGroup);
	const [itemGroupName, setItemGroupName] = useState("");
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [pointsOfSale, setPointsOfSale] = useState<PointOfSaleApi[] | undefined>(undefined);
	const [modifierGroups, setModifierGroups] = useState<ModifierGroupApi[]>([]);
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [showTranslations, setShowTranslations] = useState(false);
	const [translationItems, setTranslationItems] = useState(itemGroup?.items);
	const [itemGroupTranslations, setItemGroupTranslations] = useState(itemGroup?.translations);
	const { state } = useLocation<StateType>();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.item_group.header.add_new_product.title"));
		onFetchConfig();
	}, []);

	const onFetchConfig = async () => {
		try {
			if (state && state.copiedItem) {
				fetchData(state.copiedItem);
			} else {
				const res = await api.organization().getItemGroupConfig();
				setPointsOfSale(res.points_of_sale);
				setCustomFieldsConfig(res.custom_fields);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const clearIds = (responseData: any) => {
		responseData.id = undefined;
		responseData.items.forEach(
			(item: {
				id: undefined;
				name: string;
				modifier_groups: ItemModifierGroupApi[];
				source_item_id?: number;
			}) => {
				item.source_item_id = item.id;
				item.id = undefined;
				item.modifier_groups.forEach((mod) => {
					mod.id = undefined;
				});
				if (responseData.items.length > 1) {
					item.name = item.name.replace(`${responseData.name} `, "");
				}
			}
		);
		responseData.points_of_sale.forEach((item: { id: undefined }) => {
			item.id = undefined;
		});
		responseData.points_of_sale.forEach((item: { id: undefined }) => {
			item.id = undefined;
		});
		return responseData;
	};
	const fetchData = async (id?: number) => {
		if (id === undefined) {
			setItemGroup(undefined);
			await delay(10);
			setItemGroup(defaultItemGroup);
			return;
		}
		setItemGroup(undefined);
		const itemGroupParams: Record<string, any> = {
			include:
				"category,tax,roles,direction,custom_fields,items,items.modifier_groups,items.modifier_groups.quantity_info_override,items.modifier_groups.quantity_info,items.modifier_groups.modifier_group,items.modifier_groups.options,items.modifier_groups.options.quantity_info_override,items.modifier_groups.options.price_override,items.stock_info,items.translations,points_of_sale,points_of_sale.direction,points_of_sale.point_of_sale,items.barcodes,items.availability,items.stock_info,items.order_types",
		};
		Promise.all([
			api.organization().getItemGroup(Number(id), itemGroupParams),
			api.organization().getItemGroupConfig(),
		])
			.then(([singleItemGroup, config]) => {
				singleItemGroup.items?.filter((item: ItemApi) => item.id >= 1);
				unstable_batchedUpdates(() => {
					const params: Record<string, string> = {};
					params.include = "options,options.item,options.quantity_info,options.price,translations";
					const ids = singleItemGroup.items
						.filter((item: ItemApi) => item.id >= 1)
						.map((item: { modifier_groups: ItemModifierGroupApi[] }) =>
							item.modifier_groups.map((mod) => mod.modifier_group_id)
						);
					const modifierGroupIds = [...new Set(ids.flat())];
					params.f = btoa(
						unescape(
							encodeURIComponent(
								`${NEW_WAY_TO_ENCODING_FILTER_SIGN}id|e=${modifierGroupIds.join(
									FILTER_VALUE_SEPARATOR
								)}`
							)
						)
					);
					const item = clearIds(singleItemGroup);
					if (modifierGroupIds.length > 0) {
						api.organization()
							.getModifierGroups(params)
							.then((modifiers: ModifierGroupApi[]) => {
								const sortedModifiers = sortModifierGroupsByPositions(modifiers, singleItemGroup.items);
								setModifierGroups(sortedModifiers);
							})
							.catch((e: Error) => {
								handleError.alert(e, addFlash);
							});
					} else {
						setModifierGroups([]);
					}
					setPointsOfSale(config.points_of_sale);
					if (state?.copiedItem) {
						item.items?.forEach((x: Record<string, any>) => {
							delete x?.sku;
							delete x?.stock_info?.stock_amount;
							delete x?.stock_info?.default_cost;
							delete x?.barcodes;
						});
					}
					setItemGroup({
						...item,
						name: `${t("common.word.copy")} ${item.name}`,
						source_item_group_id: id,
					});
					setCustomFieldsConfig(config.custom_fields);
				});
			})
			.catch((e) => {
				handleError.alert(e, addFlash);
			});
	};

	if (!pointsOfSale || !customFieldsConfig || !itemGroup) {
		return <LoadingContainer />;
	}

	const handleSaveTranslations = (data: Record<string, any>) => {
		unstable_batchedUpdates(() => {
			setItemGroupTranslations(data.entity_translations);
			setTranslationItems(data.translation_items);
			setShowTranslations(false);
		});
		setShowTranslations(false);
	};

	const buttons: ButtonProps[] = [
		{
			content: (
				<EntityTranslationsModalButton
					itemsLength={translationItems?.length}
					translations={[
						...(itemGroupTranslations || []),
						...(translationItems?.map((x) => x.translations) || []),
					].flat()}
					showTranslation={showTranslations}
					setShowTranslations={(show) => setShowTranslations(show)}
				/>
			),
		},
	];

	const renderSubtitle = () => <RequiredFieldsInformation />;

	return (
		<>
			<Header
				title={t("modules.item_group.header.add_new_product.title")}
				subtitle={renderSubtitle()}
				buttons={buttons}
			/>
			{showTranslations && (
				<EntityTranslationsModalForm
					handleSave={handleSaveTranslations}
					allowDescription={true}
					onHide={() => setShowTranslations(!showTranslations)}
					translations={itemGroupTranslations}
					translationItems={translationItems}
				/>
			)}
			<ItemGroupForm
				passTranslations={(newItemGroupTranslations, items, newItemGroupName) =>
					unstable_batchedUpdates(() => {
						setItemGroupTranslations(newItemGroupTranslations);
						setTranslationItems(items);
						setItemGroupName(newItemGroupName || "");
					})
				}
				itemGroupTranslations={itemGroupTranslations}
				translationItems={translationItems}
				createNewProduct={(id) => fetchData(id)}
				itemGroup={itemGroup}
				modifierGroups={modifierGroups}
				pointsOfSale={pointsOfSale}
				customFieldsConfig={customFieldsConfig}
			/>
		</>
	);
};
export default OrganizationMenuItemGroupsNewPage;
