import { ColumnConfigReportComboApi, ColumnConfigValueReportComboApi } from "./types";

function getLast12Months(date: Date): ColumnConfigValueReportComboApi[] {
	const result: ColumnConfigValueReportComboApi[] = [];

	for (let i = 0; i < 12; i++) {
		const month = (date.getMonth() - i + 12) % 12 || 12;
		result.push({ id: (month + 1).toString() });
	}
	return result;
}

class ReportComboUtils {
	static getDataColumnId = (configColumn: ColumnConfigReportComboApi) => {
		if (configColumn.data_column_id) return configColumn.data_column_id;
		return configColumn.id;
	};

	static renderValueObj = (value: any) => {
		if (typeof value === "object" && value !== null && "amount" in value && "currency" in value) {
			return `${value.amount} ${value.currency}`;
		}
		return `${value}`;
	};

	static getValueFromValueObj = (value: any) => {
		if (!value) return 0;
		if (typeof value === "object" && value !== null && "amount" in value && "currency" in value) {
			return parseFloat(value.amount);
		}
		return parseFloat(value);
	};

	static parseValueToObject = (value: any, newValue: number) => {
		if (typeof value === "object" && value !== null && "amount" in value && "currency" in value) {
			return { ...value, amount: newValue };
		}
		return newValue;
	};

	static getDynamicValues = (dynamicValue: string) => {
		switch (dynamicValue) {
			case "values_previous_12_months": {
				return getLast12Months(new Date());
			}
		}
		return undefined;
	};

	static findDuplicates = (items: any[], key: string) => {
		const seenIds = new Set<string>();
		const duplicates: string[] = [];

		for (const row of items) {
			const value = row[key];
			if (seenIds.has(value)) {
				duplicates.push(value);
			} else {
				seenIds.add(row[key]);
			}
		}

		return duplicates;
	};

	static findVariablesInString = (jsonString: string) => {
		const regex = /\{(\w+)\}/g;
		const variables: string[] = [];
		let match;

		while ((match = regex.exec(jsonString)) !== null) {
			const value = match[1];
			if (!variables.includes(value)) {
				variables.push(value); // Capture the variable name without the curly braces
			}
		}

		return variables;
	};
}

export default ReportComboUtils;
