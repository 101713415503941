import React, { FC, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ItemInterface, ReactSortable } from "react-sortablejs";
import { FormInput } from "go-form";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSvg } from "../../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../images/svg/sortable-handle.svg";
import {
	ItemPriceOverrideApi,
	ModifierGroupApi,
	ModifierGroupOptionApi,
	QuantityInfoOverrideApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { checkIfValueIsEmpty } from "../../utils";

interface ItemQuantityOverrideFormApi {
	quantity_overrides: QuantityInfoOverrideApi[];
}

interface ItemPriceOverrideFormApi {
	price_overrides: ItemPriceOverrideApi[];
}

interface Props {
	form: UseFormReturn<ModifierGroupApi>;
	priceInfoOverridesForm: UseFormReturn<ItemPriceOverrideFormApi>;
	quantityInfoOverridesForm: UseFormReturn<ItemQuantityOverrideFormApi>;
	handleRemoveOption: (index: number) => void;
	onAddModifierOptions: () => void;
	fields: any[];
	handleReplace: (items: ModifierGroupOptionApi[]) => void;
	getParsedErrors: (errors: Record<string, any>, partToReplace?: string, replaceWith?: string) => Record<string, any>;
}

const ItemModifierGroupOptionsTable: FC<Props> = ({
	form,
	priceInfoOverridesForm,
	quantityInfoOverridesForm,
	handleRemoveOption,
	onAddModifierOptions,
	fields,
	handleReplace,
	getParsedErrors,
}) => {
	const {
		formState: { errors },
		register,
		getValues,
		clearErrors,
		watch,
	} = form;
	const {
		clearErrors: clearPriceErrors,
		register: priceRegister,
		control: priceControl,
		formState: { errors: priceErrors },
		watch: pricesWatch,
		setValue: setPricesValue,
	} = priceInfoOverridesForm;

	const {
		register: quantityRegister,
		control: quantityControl,
		formState: { errors: quantityErrors },
		clearErrors: clearQuantityErrors,
		watch: quantitiesWatch,
		setValue: setQuantitiesValue,
	} = quantityInfoOverridesForm;

	const isSortingRef = useRef(false);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const isMobile = useWindowSize().isMobile;

	const updatePositions = (items: ModifierGroupOptionApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items.forEach((item, index) => {
			item.position = index;
		});
		handleReplace(items);
	};

	const onRemoveOption = (index: number) => {
		clearErrors(`options.${index}.item_id`);
		handleRemoveOption(index);
	};

	const getDefaultOptionValue = (value: string | null, valueToDisplay: string, optionValue?: string | null) => {
		if (checkIfValueIsEmpty(optionValue) && !checkIfValueIsEmpty(value) && parseInt(value!) >= 0) {
			return `${t("common.word.default_none")}: ${valueToDisplay}`;
		}
		if (parseInt(value!) >= 0 && !checkIfValueIsEmpty(optionValue) && parseInt(optionValue!) >= 0)
			return `${t("common.word.default_none")}: ${optionValue}`;
		return "";
	};

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form item-modifier-group-options-table">
					<thead>
						<tr>
							<th className="action" />
							<th className="w-25">{t("lib:common.word.name")}</th>
							<th style={{ minWidth: isMobile ? "90px" : "unset" }}>
								{t("modules.item_group.field.price.title")}
							</th>
							<th>{t("modules.item_group.field.min_amount.title")}</th>
							<th>{t("modules.item_group.field.max_amount.title")}</th>
							<th>{t("modules.item_group.field.default_quantity.title")}</th>
							<th>{t("modules.item_group.field.charge_above.title")}</th>
							<th className="action" />
						</tr>
					</thead>
					{
						<ReactSortable
							handle=".sortable-handler"
							list={fields}
							tag={"tbody"}
							onUpdate={() => (isSortingRef.current = true)}
							setList={(fields) => updatePositions(fields)}
						>
							{fields.map((option, index) => {
								const optionPriceAmount = watch().options[index].price?.amount;
								const optionSubItemPriceAmount = watch().options[index]?.sub_item?.price?.amount;
								const optionMinPermitted = watch().options[index].quantity_info?.min_permitted;
								const optionMaxPermitted = watch().options[index].quantity_info?.max_permitted;
								const optionDefaultQuantity = watch().options[index].quantity_info?.default_quantity;
								const optionChargeAbove = watch().options[index].quantity_info?.charge_above;
								const defaultQuantity = quantitiesWatch(
									`quantity_overrides.${index}.quantity_info.default_quantity`
								);
								const maxPermitted = quantitiesWatch(
									`quantity_overrides.${index}.quantity_info.max_permitted`
								);
								const minPermitted = quantitiesWatch(
									`quantity_overrides.${index}.quantity_info.min_permitted`
								);
								const chargeAbove = quantitiesWatch(
									`quantity_overrides.${index}.quantity_info.charge_above`
								);

								return (
									<tr key={option.item_id}>
										<td className="action">
											<SortableHandleSvg
												style={{ marginLeft: "6px" }}
												className="sortable-handler"
											/>
										</td>
										<td>
											<FormInput
												type="hidden"
												errors={getParsedErrors(errors)}
												name={`options.${index}.item_id`}
												register={register}
											/>
											<FormInput
												type="hidden"
												errors={getParsedErrors(errors)}
												defaultValue={index}
												name={`options.${index}.position`}
												register={register}
											/>
											<FormInput
												errors={getParsedErrors(errors)}
												type="hidden"
												register={priceRegister}
												name={`price_overrides.${index}.item_id`}
											/>
											<strong>
												<Link
													to={`/${organization.id}/menu/item_groups/${option.sub_item?.item_group?.id}`}
													target={"_blank"}
													rel="noreferrer"
												>
													{option.sub_item?.name}
												</Link>
											</strong>
											{Object.values(errors).map((f: Record<string, any>) => {
												if (f[index] && f[index].item_id) {
													return (
														<Form.Control.Feedback
															key={f[index].item_id}
															type={"invalid"}
															className={"d-inline"}
														>
															{t(
																"modules.item_group.constraints.item_contains_modifier_group"
															)}
														</Form.Control.Feedback>
													);
												}
												return null;
											})}
										</td>
										<td>
											<div className={"d-flex flex-column"}>
												{(pricesWatch(`price_overrides.${index}.price.amount`) || -1) >= 0 && (
													<>
														{typeof getValues().options[index].price?.amount ===
														"number" ? (
															<small className={"modifier-parent-value"}>
																{t("common.word.default_short")}{" "}
																{FormatMoney({
																	amount: getValues().options[index].price?.amount,
																	currency,
																})}
															</small>
														) : (
															<small className={"modifier-parent-value"}>
																{t("common.word.default_short")}{" "}
																{getValues().options[index]?.sub_item?.price?.amount
																	? FormatMoney(
																			getValues().options[index].sub_item?.price
																	  )
																	: FormatMoney({
																			amount: 0,
																			currency,
																	  })}
															</small>
														)}
													</>
												)}
												<div className="input-container">
													<FormMoneyInput
														control={priceControl}
														currency={currency}
														onChange={() =>
															clearPriceErrors(`price_overrides.${index}.price.amount`)
														}
														placeholder={
															typeof optionPriceAmount === "number"
																? FormatMoney({
																		amount: optionPriceAmount,
																		currency,
																  })
																: FormatMoney({
																		amount: optionSubItemPriceAmount,
																		currency,
																  })
														}
														name={`price_overrides.${index}.price.amount`}
														errors={getParsedErrors(priceErrors)}
													/>
													{!checkIfValueIsEmpty(
														pricesWatch(`price_overrides.${index}.price.amount`)
													) && (
														<RemoveSvg
															className="remove-icon"
															onClick={() =>
																setPricesValue(
																	`price_overrides.${index}.price.amount`,
																	null
																)
															}
														/>
													)}
												</div>
											</div>
										</td>
										<td>
											<FormInput
												type="hidden"
												errors={getParsedErrors(errors)}
												register={quantityRegister}
												name={`${index}.item_id`}
											/>
											<div className={"d-flex flex-column"}>
												{getDefaultOptionValue(minPermitted, "0", optionMinPermitted) && (
													<small className={"modifier-parent-value"}>
														{getDefaultOptionValue(minPermitted, "0", optionMinPermitted)}
													</small>
												)}
												<div className="input-container">
													<FormNumberInput
														errors={getParsedErrors(
															quantityErrors,
															"options",
															"quantity_overrides"
														)}
														name={`quantity_overrides.${index}.quantity_info.min_permitted`}
														onChange={() =>
															clearQuantityErrors(
																`quantity_overrides.${index}.quantity_info.min_permitted`
															)
														}
														placeholder={`${optionMinPermitted || 0}`}
														suffix=""
														decimalScale={0}
														control={quantityControl}
														maxPermittedAmountOfDigits={3}
													/>
													{!checkIfValueIsEmpty(
														quantitiesWatch(
															`quantity_overrides.${index}.quantity_info.min_permitted`
														)
													) && (
														<RemoveSvg
															className="remove-icon"
															onClick={() =>
																setQuantitiesValue(
																	`quantity_overrides.${index}.quantity_info.min_permitted`,
																	null
																)
															}
														/>
													)}
												</div>
											</div>
										</td>
										<td>
											<div className={"d-flex flex-column"}>
												{getDefaultOptionValue(maxPermitted, "∞", optionMaxPermitted) && (
													<small className={"modifier-parent-value"}>
														{getDefaultOptionValue(maxPermitted, "∞", optionMaxPermitted)}
													</small>
												)}
												<div className="input-container">
													<FormNumberInput
														errors={getParsedErrors(
															quantityErrors,
															"options",
															"quantity_overrides"
														)}
														name={`quantity_overrides.${index}.quantity_info.max_permitted`}
														onChange={() =>
															clearQuantityErrors(
																`quantity_overrides.${index}.quantity_info.max_permitted`
															)
														}
														placeholder={`${optionMaxPermitted || "∞"}`}
														suffix=""
														decimalScale={0}
														control={quantityControl}
														maxPermittedAmountOfDigits={3}
													/>
													{!checkIfValueIsEmpty(
														quantitiesWatch(
															`quantity_overrides.${index}.quantity_info.max_permitted`
														)
													) && (
														<RemoveSvg
															className="remove-icon"
															onClick={() =>
																setQuantitiesValue(
																	`quantity_overrides.${index}.quantity_info.max_permitted`,
																	null
																)
															}
														/>
													)}
												</div>
											</div>
										</td>
										<td>
											<div className={"d-flex flex-column"}>
												{getDefaultOptionValue(defaultQuantity, "0", optionDefaultQuantity) && (
													<small className={"modifier-parent-value"}>
														{getDefaultOptionValue(
															defaultQuantity,
															"0",
															optionDefaultQuantity
														)}
													</small>
												)}
												<div className="input-container">
													<FormNumberInput
														errors={getParsedErrors(
															quantityErrors,
															"options",
															"quantity_overrides"
														)}
														name={`quantity_overrides.${index}.quantity_info.default_quantity`}
														onChange={() =>
															clearQuantityErrors(
																`quantity_overrides.${index}.quantity_info.default_quantity`
															)
														}
														placeholder={`${optionDefaultQuantity || 0}`}
														suffix=""
														decimalScale={0}
														control={quantityControl}
														maxPermittedAmountOfDigits={3}
													/>
													{!checkIfValueIsEmpty(
														quantitiesWatch(
															`quantity_overrides.${index}.quantity_info.default_quantity`
														)
													) && (
														<RemoveSvg
															className="remove-icon"
															onClick={() =>
																setQuantitiesValue(
																	`quantity_overrides.${index}.quantity_info.default_quantity`,
																	null
																)
															}
														/>
													)}
												</div>
											</div>
										</td>
										<td>
											<div className={"d-flex flex-column"}>
												{getDefaultOptionValue(chargeAbove, "0", optionChargeAbove) && (
													<small className={"modifier-parent-value"}>
														{getDefaultOptionValue(chargeAbove, "0", optionChargeAbove)}
													</small>
												)}
												<div className="input-container">
													<FormNumberInput
														errors={getParsedErrors(
															quantityErrors,
															"options",
															"quantity_overrides"
														)}
														name={`quantity_overrides.${index}.quantity_info.charge_above`}
														onChange={() =>
															clearQuantityErrors(
																`quantity_overrides.${index}.quantity_info.charge_above`
															)
														}
														placeholder={`${optionChargeAbove || 0}`}
														suffix=""
														decimalScale={0}
														control={quantityControl}
														maxPermittedAmountOfDigits={3}
													/>
													{!checkIfValueIsEmpty(
														quantitiesWatch(
															`quantity_overrides.${index}.quantity_info.charge_above`
														)
													) && (
														<RemoveSvg
															className="remove-icon"
															onClick={() =>
																setQuantitiesValue(
																	`quantity_overrides.${index}.quantity_info.charge_above`,
																	null
																)
															}
														/>
													)}
												</div>
											</div>
										</td>
										<td className="action">
											<RemoveSvg
												style={{ marginRight: "6px" }}
												onClick={() => onRemoveOption(index)}
												className="icon"
											/>
										</td>
									</tr>
								);
							})}
						</ReactSortable>
					}
				</table>
			</div>
			<Button variant="add" className={"mb-4"} onClick={onAddModifierOptions}>
				{t("common.action.assign_to_group")}
			</Button>
		</>
	);
};
export default ItemModifierGroupOptionsTable;
