import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import OrganizationClientsClientGroupsEditPage from "./pages/Edit";
import OrganizationClientsClientGroupsIndexPage from "./pages/Index";
import OrganizationClientsClientGroupsNewPage from "./pages/New";

const OrganizationClientsClientGroupsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationClientsClientGroupsIndexPage} />
				<Route exact path={`${props.match.url}/new`} component={OrganizationClientsClientGroupsNewPage} />
				<Route path={`${props.match.url}/:clientGroupId`} component={OrganizationClientsClientGroupsEditPage} />
				<Route component={Page404} />
			</Switch>
		</div>
	);
};
export default OrganizationClientsClientGroupsPage;
