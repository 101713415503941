import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FiltersSVG } from "../../../../images/filters.svg";
import { ListConfigFilter, ListSelectedFilter } from "../../services/types";
import { renderFilterValue } from "../Item";

interface Props {
	setShowMobileFilters: (value: boolean) => void;
	selectedFilters: ListSelectedFilter[];
	filters: ListConfigFilter[];
	selectedSegment?: string;
}

const MobileFiltersButton = ({ setShowMobileFilters, selectedFilters, filters, selectedSegment }: Props) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLParagraphElement | null>(null);
	const [isOverflow, setIsOverflow] = React.useState(false);

	React.useLayoutEffect(() => {
		const { current } = ref;
		if (!current) return;

		const trigger = () => {
			const hasOverflow = current.scrollHeight > current.clientHeight;

			setIsOverflow(hasOverflow);
		};

		if (current) {
			trigger();
		}
	}, [JSON.stringify(selectedFilters)]);

	const getSelectedFiltersValues = () => {
		const newFilters = [...filters];

		return selectedFilters
			.map((selectedFilter) => {
				const configFilter = newFilters.find((filter) => filter.id === selectedFilter.filterId);

				if (configFilter) {
					return renderFilterValue(selectedFilter, configFilter, t);
				}

				return undefined;
			})
			.filter((value) => value)
			.join(", ");
	};

	return (
		<div className="mobile-filters-button my-3" onClick={() => setShowMobileFilters(true)}>
			<div className="d-flex align-items-center">
				<FiltersSVG />
				<p ref={ref} className="filters-values">
					{selectedSegment && (
						<strong className="text-dark">{`${selectedSegment}${
							getSelectedFiltersValues().length > 0 ? ", " : ""
						}`}</strong>
					)}
					{getSelectedFiltersValues()}
				</p>
				{isOverflow && selectedFilters.length > 0 && <p className="filters-count">+{selectedFilters.length}</p>}
			</div>
		</div>
	);
};

export default MobileFiltersButton;
