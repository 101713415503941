import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { VenueSettingsApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import KioskSettingsLanguageInputs from "./KioskSettingsLanguageInputs";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
}

const KioskSettingsTextsCard = ({ form }: Props) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const languages = [...(organization.languages || [])].sort((a, b) => a.localeCompare(b));

	return (
		<fieldset className="form-group">
			<h6>{t("modules.venue_settings.field.texts.title")}</h6>
			<div className="d-flex flex-column gap-3">
				{languages.map((language, index) => (
					<KioskSettingsLanguageInputs key={language} form={form} language={language} index={index} />
				))}
			</div>
		</fieldset>
	);
};

export default KioskSettingsTextsCard;
