import React, { FC, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { PosReportApi, PosReportPaidApi } from "../../../../../../../../../../services/Api/Organization/types";
import TransactionPreview from "../../../Transactions/components/TransactionPreview";
import PaidEditModal from "../PaidEditModal";

interface Props {
	report: PosReportApi;
	handlePaidSave: (data: PosReportPaidApi) => void;
}

const PaidsTabPreview: FC<Props> = ({ report, handlePaidSave }): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [showPaidModal, setShowPaidModal] = useState<undefined | PosReportPaidApi>(undefined);
	const [showTransaction, setShowTransaction] = useState<number | undefined>(undefined);
	const paids = report.paids;

	const getReportId = () => {
		if (report.type === "DRAWER") return report.id;
		return showPaidModal!.pos_report_id;
	};

	return (
		<Card>
			<Card.Body>
				{paids?.length > 0 ? (
					<div className={"table-responsive"}>
						{report.type !== "SHIFTWORK" && (
							<div className={"d-flex"}>
								<Button
									className={"ms-auto"}
									variant={"primary-light"}
									onClick={() => setShowPaidModal({} as PosReportPaidApi)}
								>
									+ {t("common.action.add", { ns: "lib" })}
								</Button>
							</div>
						)}
						<table className={"table table-preview mb-2"}>
							<thead>
								<tr>
									<th>{t("modules.pos_report.field.number.title")}</th>
									<th>{t("modules.pos_report.field.created_at.title")}</th>
									<th>{t("modules.pos_report.field.employee.title")}</th>
									<th>{t("common.word.client")}</th>
									<th>{t("common.word.amount")}</th>
									<th>{t("lib:common.word.description")}</th>
									<th className={"w-1"} />
								</tr>
							</thead>
							<tbody>
								{paids?.map((paid, index) => {
									return (
										<tr key={index}>
											<td>
												{paid.transaction?.id ? (
													<div
														className={"action-ref"}
														onClick={() => setShowTransaction(paid.transaction.id)}
													>
														{paid.number}
													</div>
												) : (
													<>{paid.number}</>
												)}
											</td>
											<td>{FormatDate(paid.created_at)}</td>
											<td>
												{paid.employee && (
													<Link
														target={"_blank"}
														rel={"noreferrer"}
														to={`/${organization.id}/employees/${paid.employee.id}`}
													>
														{paid.employee.name}
													</Link>
												)}
											</td>
											<td>
												{paid.client &&
													(paid.client_id ? (
														<Link
															target={"_blank"}
															rel={"noreferrer"}
															to={`/${organization.id}/clients/${paid.client.id}`}
														>
															{paid.client.name || paid.client.contact?.phone_number}
														</Link>
													) : (
														paid.client.name || paid.client.contact?.phone_number
													))}
											</td>
											<td style={{ whiteSpace: "nowrap" }}>{FormatMoney(paid.amount)}</td>
											<td>{paid.description}</td>
											<td>
												<Button
													variant={"primary-light"}
													onClick={() => setShowPaidModal(paid)}
												>
													{t("common.action.edit", { ns: "lib" })}
												</Button>
											</td>
										</tr>
									);
								})}
								<tr className={"summary"}>
									<td>
										<strong>{t("common.word.sum")}</strong>
									</td>
									<td />
									<td></td>
									<td />
									<td className={"nowrap"}>
										<strong>
											{FormatMoney({
												amount: report.paids.reduce(
													(acc, val) => acc + (val.amount.amount ? val.amount.amount : 0),
													0
												),
												currency: paids[0]?.amount.currency,
											})}
										</strong>
									</td>
									<td />
									<td />
								</tr>
							</tbody>
						</table>
					</div>
				) : (
					<>
						<EmptyList
							title={t("modules.pos_report.header.paids.title")}
							description={t("modules.pos_report.field.paids_empty_list.title")}
							actions={
								report.type !== "SHIFTWORK"
									? [
											{
												name: `+ ${t("lib:common.action.add")}`,
												click: () => {
													setShowPaidModal({} as PosReportPaidApi);
												},
											},
									  ]
									: []
							}
						/>
					</>
				)}
			</Card.Body>
			{showPaidModal && (
				<PaidEditModal
					handleSave={(data) => {
						handlePaidSave(data);
						setShowPaidModal(undefined);
					}}
					paid={showPaidModal}
					reportId={getReportId()}
					onHide={() => setShowPaidModal(undefined)}
				/>
			)}
			{showTransaction && (
				<TransactionPreview
					styles={{ position: "fixed" }}
					id={showTransaction}
					onClose={() => setShowTransaction(undefined)}
				/>
			)}
		</Card>
	);
};
export default PaidsTabPreview;
