import React, { FC, Fragment } from "react";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as WCSVG } from "../../../../../../../../../../../images/svg/rooms/wc.svg";
import {
	RoomApi,
	RoomElementApi,
	RoomTableApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import Hooker from "./elements/Hooker";
import Rectangle from "./elements/Rectangle";
import RoomElementRectangle from "./elements/RoomElementRectangle";
import Round from "./elements/Round";
import Square from "./elements/Square";

interface Props {
	mode: string;
	data: RoomApi;
	onHide: () => void;
}

const recalculate = (data: RoomElementApi | RoomTableApi) => {
	return data;
};

const RoomModalPreview: FC<Props> = ({ mode, data, onHide }) => {
	const { t } = useTranslation();
	return (
		<Modal
			className={classNames("room-modal-preview", {
				r16_09: mode === "16:9",
				r09_16: mode === "9:16",
				r10_16: mode === "10:16",
				r16_10: mode === "16:10",
			})}
			show={true}
			onHide={onHide}
		>
			<Modal.Header closeButton>
				<Modal.Title>{`${t("modules.room.field.room_preview_in.title")} {mode}`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					className={"room-body"}
					style={{
						background: data.background?.image_data
							? `url("data:${data.background?.type};base64,${data.background?.image_data}")`
							: data.background_link?.default_link
							? `url("${data.background_link?.default_link}")`
							: data.color
							? data.color
							: undefined,
					}}
				>
					{data.elements.map((element, index) => {
						const recalculated = recalculate(element);
						const elStyles = {
							width: `${recalculated.width}px`,
							height: `${recalculated.height}px`,
							left: recalculated.position_left,
							top: recalculated.position_top,
							position: "absolute",
						};
						const isVertical = element.height > element.width;
						return (
							<Fragment key={index}>
								{element.type === "TOILET" ? (
									<RoomElementRectangle styles={elStyles} className={"d-flex align-items-center"}>
										<span
											className={"element-text"}
											style={{ transform: isVertical ? "rotate(90deg)" : undefined }}
										>
											<div className={"d-flex align-items-center"}>
												<WCSVG className={"me-2"} /> {element.name}
											</div>
										</span>
									</RoomElementRectangle>
								) : (
									<RoomElementRectangle styles={elStyles}>
										<span
											className={"element-text"}
											style={{ transform: isVertical ? "rotate(90deg)" : undefined }}
										>
											{element.name}
										</span>
									</RoomElementRectangle>
								)}
							</Fragment>
						);
					})}
					{data.tables.map((table, index) => {
						const recalculated = recalculate(table);
						const elStyles = {
							width: `${recalculated.width}px`,
							height: `${recalculated.height}px`,
							left: recalculated.position_left,
							top: recalculated.position_top,
							position: "absolute",
						};
						return (
							<Fragment key={index}>
								{table.type === "SQUARE" && (
									<Square name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
								)}
								{table.type === "ROUND" && (
									<Round name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
								)}
								{table.type === "RECTANGLE" && (
									<Rectangle
										name={table.name}
										styles={elStyles}
										guestAmount={table.number_of_seats}
									/>
								)}
								{table.type === "HOKER" && (
									<Hooker name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
								)}
							</Fragment>
						);
					})}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default RoomModalPreview;
