import { MoneyApi } from "go-core/api/types";

export enum DashboardType {
	ORGANIZATION = "ORGANIZATION",
	GROUP_ORGANIZATIONS = "GROUP_ORGANIZATIONS",
}

export enum DashboardAggregateType {
	TOTAL_MONEY = "total_money",
	TRNSACTION_COUNT = "transaction_count",
	AVERAGE_MONEY = "average_money",
}

export enum DashboardReportType {
	CATEGORY = "Category",
	PAYMENT = "Payment",
	PRODUCT = "Product",
	SALES = "Sales",
}

export enum DashboardClientInfomationsType {
	AVERAGE_MONEY = "average_money",
	AVERAGE_PER_GUEST_MONEY = "average_per_guest_money",
	NUMBER_OF_GUESTS = "number_of_guests",
}

export interface DashboardClientInfomationsConfig {
	title: string;
	icon: JSX.Element;
	value: MoneyApi | number;
	compareValue: MoneyApi | number;
	seeMoreLink: string;
}
