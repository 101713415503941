import React, { FC, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { AppLinkApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { GoCRMItemTypeTable } from "./components/GoCRMItemTypeTable";
import { GoZoneProductTable } from "./components/GoZoneProductTable";

interface Props {
	item: AppLinkApi;
	onHide: () => void;
	handleSave: (item: AppLinkApi) => void;
}

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("constraints.one_to_one_link_required");
};

const LinkEntityModal: FC<Props> = ({ item, onHide, handleSave }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const isGoZoneItemToLink = item.app.provider === "GOZONE";
	const isCRMAndItemTypeToLink = item.app.provider === "GOCRM" && item.resource_type === "ITEM";
	const newItem = {
		...item,
		resource: {
			id: item.resource_id,
			name: item.resource_name,
		},
	};

	const [itemState, setItemState] = useState(newItem);

	const form = useForm<AppLinkApi>({
		criteriaMode: "all",
		defaultValues: newItem,
	});
	const {
		handleSubmit,
		formState: { errors },
		setError,
		control,
	} = form;

	const onSubmit = handleSubmit(async (data: AppLinkApi) => {
		setLoading(true);

		data.items = data.items?.filter(
			(itemToBeSent) => itemToBeSent.item_id !== 0 && itemToBeSent.item_name !== "" && !itemToBeSent.item
		);

		const params: Record<string, any> = { include: "app" };
		try {
			if (itemState.skipped) {
				const res = await api.organization().skipEntity(newItem.id, params);
				handleSave(res);
				addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			} else if (itemState.location) {
				const res = await api.organization().createByApp(newItem.id, params);
				handleSave(res);
				addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			} else {
				const res = await api.organization().linkEntity(newItem.id, data, params);
				addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
				handleSave(res);
			}
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const searchResources = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		switch (newItem.resource_type) {
			case "ITEM":
				return api.organization().getItemsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "ITEM_GROUP":
				return api.organization().getItemGroupsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "DISCOUNT":
				return api.organization().getDiscountsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "MODIFIER_GROUP":
				return api.organization().getModifierGroupsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "CATEGORY":
				return api.organization().getCategoriesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "MENU":
				return api.organization().getMenusSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "PAYMENT_METHOD":
				return api.organization().getPaymentMethodsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "AVAILABILITY":
				return api.organization().getAvailabilitiesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "MENU_PRICE_LIST":
				return api.organization().getPriceListsSearchSelect(search, params, undefined, {
					cancelToken: options?.token,
				});
			case "VENUE_ROLE":
				return api.organization().getRolesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "EMPLOYEE":
				return api.organization().getEmployeesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "DIRECTION":
				return api.organization().getDirectionsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "POINT_OF_SALE":
				return api.organization().getPointsOfSaleSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "WORKPLACE":
				return api.organization().getWorkplacesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "CLIENT_GROUP":
				return api.organization().getClientGroupsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "CUSTOM_FIELD_TEMPLATE_OPTION":
				return api.organization().getCustomFieldTemplateOptionsSearchSelect(item.id, search, params, {
					cancelToken: options?.token,
				});
			case "SEGMENT":
				return api.organization().getSegmentsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "CUSTOM_FIELD_TEMPLATE":
				return api.organization().getCustomFieldsTemplateSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "PRINTOUT_TEMPLATE":
				return api.organization().getPrintoutTemplatesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
		}
	};

	const { addFlash, addSuccessFlash } = useFlash();

	return (
		<Modal size={"lg"} show={true} onHide={onHide}>
			<Form key="link-entity-modal" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{`${t(`modules.app_link.action.link_${item.resource_type}.title`)} "${item.external_name}"`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={"row"}>
						<div className={"col-md-6"}>
							<Card>
								<Card.Body>
									<h5>{t("lib:common.word.informations")}</h5>
									{item.external_id && (
										<div className="card-body-item">
											<div className="item-info">
												<span className="label">
													{t(`modules.app_link.field.external_id.title`)}
												</span>
												<span>{item.external_id}</span>
											</div>
										</div>
									)}
									<div className="card-body-item">
										<div className="item-info">
											<span className="label">
												{t(`modules.app_link.field.updated_at.title`)}
											</span>
											<span>{FormatDate(item.updated_at)}</span>
										</div>
									</div>
									{item.imported_at && (
										<div className="card-body-item">
											<div className="item-info">
												<span className="label">
													{t(`modules.app_link.field.imported_at.title`)}
												</span>
												<span>{FormatDate(item.imported_at)}</span>
											</div>
										</div>
									)}
									{item.exported_at && (
										<div className="card-body-item">
											<div className="item-info">
												<span className="label">
													{t(`modules.app_link.field.exported_at.title`)}
												</span>
												<span>{FormatDate(item.exported_at)}</span>
											</div>
										</div>
									)}
								</Card.Body>
							</Card>
						</div>
						<div className={"col-md-6"}>
							{!isGoZoneItemToLink && !isCRMAndItemTypeToLink && (
								<>
									<LimitComponentVisibility
										defaultVisible={
											(itemState.status === "OPEN" ||
												itemState.status === "CLOSED" ||
												itemState.status === "TO_EXPORT") &&
											!itemState.skipped &&
											!itemState.location
										}
										id={"link_product"}
										handleChange={(evt) =>
											setItemState({ ...itemState, skipped: !evt, location: false })
										}
										legendText={`${t("common.action.select", { ns: "lib" })} ${t(
											`modules.app_link.field.${item.resource_type}.title`
										)}`}
										unlimitedContent={
											<>
												<FormSelectGroup
													name="resource_id"
													errors={errors}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.id}
													defaultValue={{
														id: newItem?.resource?.id,
														label: newItem?.resource?.name,
													}}
													loadOptions={searchResources}
													control={control}
													data-testid="resource_id"
												/>
											</>
										}
									/>
									<LimitComponentVisibility
										defaultVisible={itemState.skipped && !itemState.location}
										id={"skip_product"}
										handleChange={() =>
											setItemState({
												...itemState,
												skipped: !itemState.skipped,
												location: false,
											})
										}
										legendText={t(`modules.app_link.field.skip_linking.title`)}
									/>
								</>
							)}
							{item.app.provider === "LOCATIONS" && (
								<LimitComponentVisibility
									defaultVisible={itemState.location ? itemState.location : false}
									id={"create_by_app"}
									handleChange={() =>
										setItemState({
											...itemState,
											skipped: false,
											location: !itemState.location,
										})
									}
									legendText={t(`modules.app_link.field.create_by_app.title`)}
								/>
							)}
						</div>
					</div>
					{isGoZoneItemToLink && (
						<div className="row mt-3">
							<div className="col">
								<GoZoneProductTable
									form={form}
									receivedItems={Array.isArray(item.items) && item.items.length > 0}
								/>
							</div>
						</div>
					)}
					{isCRMAndItemTypeToLink && <GoCRMItemTypeTable form={form} />}
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
export default LinkEntityModal;

// t("modules.app_link.action.link_CATEGORY.title");
// t("modules.app_link.action.link_AVAILABILITY.title");
// t("modules.app_link.action.link_ITEM.title");
// t("modules.app_link.action.link_MODIFIER_GROUP.title");
// t("modules.app_link.action.link_PAYMENT_METHOD.title");
// t("modules.app_link.action.link_SEGMENT.title");
// t("modules.app_link.action.link_DISCOUNT.title");
// t("modules.app_link.action.link_MENU.title");
// t("modules.app_link.action.link_ITEM_GROUP.title");
// t("modules.app_link.action.link_MENU_PRICE_LIST.title");
// t("modules.app_link.action.link_CLIENT_GROUP.title");
// t("modules.app_link.action.link_CUSTOM_FIELD_TEMPLATE.title");
// t("modules.app_link.action.link_CUSTOM_FIELD_TEMPLATE_OPTION.title");
// t("modules.app_link.action.link_PRINTOUT_TEMPLATE.title");
// t("modules.app_link.field.ITEM.title");
// t("modules.app_link.field.CATEGORY.title");
// t("modules.app_link.field.AVAILABILITY.title");
// t("modules.app_link.field.MODIFIER_GROUP.title");
// t("modules.app_link.field.PAYMENT_METHOD.title");
// t("modules.app_link.field.SEGMENT.title");
// t("modules.app_link.field.DISCOUNT.title");
// t("modules.app_link.field.MENU_PRICE_LIST.title");
// t("modules.app_link.field.ITEM_GROUP.title");
// t("modules.app_link.field.MENU.title");
// t("modules.app_link.field.CLIENT_GROUP.title");
// t("modules.app_link.field.CUSTOM_FIELD_TEMPLATE_OPTION.title");
// t("modules.app_link.field.CUSTOM_FIELD_TEMPLATE.title");
// t("modules.app_link.field.PRINTOUT_TEMPLATE.title");
