import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatBoolean, { convertBoolean } from "go-core/components/Formatters/FormatBoolean";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import EntityUsedErrorModal from "../../../../../../../../../../components/Common/EntityUsedErrorModal/EntityUsedErrorModal";
import { CategoryApi, ItemGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "CATEGORY";
const resourceType = "CATEGORY";

interface RemoveErrorProps {
	ids: number[];
	errors: ApiError[];
}

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const organization = useSelector(selectOrganization);
	const [errors, setErrors] = useState<RemoveErrorProps | undefined>(undefined);
	const [categoriesToRemove, setCategoriesToRemove] = useState<CategoryApi[] | undefined>(undefined);
	const [showCategoryUsedErrorModal, setShowCategoryUsedErrorModal] = useState(false);
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const [items, setItems] = useState<CategoryApi[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const [itemGroups, setItemGroups] = useState<ItemGroupApi[]>([]);
	const segmentContextValue = React.useContext(SegmentContext);

	useEffect(() => {
		if (errors?.errors !== undefined && errors?.errors.filter((f) => f.code === "category_is_used").length > 0)
			setShowCategoryUsedErrorModal(true);
	}, [errors]);
	useEffect(() => {
		if (categoriesToRemove) fetchItemGroups();
	}, [categoriesToRemove]);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (refreshParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(refreshParams);
			setItems(res);
		}
	};

	const fetchItemGroups = async () => {
		try {
			params.size = 10;
			params.f = `${btoa(
				`${NEW_WAY_TO_ENCODING_FILTER_SIGN}category|e=${categoriesToRemove?.map((category) => category.id)}`
			)}`;
			const res = await api.organization().getItemGroups(params);
			setItemGroups(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemoveCategory = async (item: CategoryApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			setCategoriesToRemove([item]);
			await api.organization().removeCategory(item.id);
			const res = await api.organization().getCategories(params);
			setItems(res);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			setErrors({ ids: [item.id], errors: handleException(e) });
		}
	};

	const onRestoreCategory = async (item: CategoryApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			await api.organization().restoreCategory(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onDisableCategory = async (item: CategoryApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			await api.organization().disableCategory(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const drawItemGroups = () => {
		return (
			itemGroups &&
			itemGroups.map((item) => {
				return {
					name: item.name,
					link: `/${organization.id}/menu/item_groups/${item.id}`,
				};
			})
		);
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text" as FilterType,
				render: (item: CategoryApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<ListImageName
								data={{ ...item, limitTextWidth: 200 }}
								imageLink={item.image_link}
								link={`${location.pathname}/${item.id}`}
							/>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: CategoryApi) => item?.name,
			},
			{
				id: "discountable",
				name: t("modules.category.field.discountable.title"),
				type: "boolean" as FilterType,
				render: (item: CategoryApi) => <FormatBoolean value={item.discountable} />,
				renderExport: (item: CategoryApi) => convertBoolean(item?.discountable, t),
			},
			{
				id: "point_of_sale",
				name: t("common.word.points_of_sale"),
				disableSorting: true,
				render: (item: CategoryApi) => {
					return item.points_of_sale
						.filter((x) => x.visibility === "VISIBLE")
						.map((x) => x.point_of_sale?.name)
						.join(", ");
				},
				renderExport: (item: CategoryApi) => {
					return item?.points_of_sale
						.filter((x) => x.visibility === "VISIBLE")
						.map((x) => x.point_of_sale?.name)
						.join(", ");
				},
			},
			{
				id: "direction",
				name: t("common.word.directions"),
				disableSorting: true,
				render: (item: CategoryApi) => {
					if (item.direction) {
						return item.direction.name;
					}
					return item.points_of_sale
						.filter((x) => x.direction)
						.map((x) => x.direction?.name)
						.join(", ");
				},
				renderExport: (item: CategoryApi) => {
					if (item.direction) {
						return item.direction.name;
					}
					return item?.points_of_sale
						.filter((x) => x.direction)
						.map((x) => x.direction?.name)
						.join(", ");
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: CategoryApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: CategoryApi) => {
					onRemoveCategory(item);
				},
				visible: (item: CategoryApi) => {
					return item.status !== "DELETED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: CategoryApi) => {
					onRestoreCategory(item);
				},
				visible: (item: CategoryApi) => {
					return item.status === "DELETED" || item.status === "DISABLED";
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: CategoryApi) => {
					onDisableCategory(item);
				},
				visible: (item: CategoryApi) => {
					return item.status === "ENABLED";
				},
			},
		],
		multipleActions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: async (
					multipleActionsItems: CategoryApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.category.field.remove_message.title"),
					});

					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

						setCategoriesToRemove(multipleActionsItems);
						await api
							.organization()
							.removeCategories(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
						onRefresh(formattedParams);
					} catch (e) {
						setErrors({ ids: multipleActionsItems.map((x) => x.id), errors: handleException(e) });
					}
				},
				visible: (multipleActionsItems: CategoryApi[]) => {
					return multipleActionsItems.filter((f) => f.status !== "DELETED").length > 0;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: async (
					multipleActionsItems: CategoryApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.category.field.activate_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

						await api
							.organization()
							.restoreCategories(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: CategoryApi[]) => {
					return (
						multipleActionsItems.filter((f) => f.status === "DELETED" || f.status === "DISABLED").length > 0
					);
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: async (
					multipleActionsItems: CategoryApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.category.field.disable_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

						await api
							.organization()
							.disableCategories(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: CategoryApi[]) => {
					return multipleActionsItems.filter((f) => f.status === "ENABLED").length > 0;
				},
			},
		],
		selectedColumns: ["name", "discountable", "point_of_sale", "direction"],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DISABLED: t("enums.common.status.DISABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
			{
				id: "point_of_sale",
				name: t("common.word.point_of_sale"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPointsOfSaleSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "direction",
				name: t("common.word.direction_of_expenditure"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getDirectionsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.category.field.export_config.title"),
			filename: t("modules.category.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include =
				"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";
			setParams(fetchParams);
			setErrors(undefined);
			return api.organization().getCategories(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		fetchTotalItemsCountForSelectedFilters: (
			fetchTotalItemsParams: Record<string, any> = {},
			sourceToken?: CancelTokenSource
		) => {
			fetchTotalItemsParams.include =
				"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

			return api.organization().getCategoriesCount(fetchTotalItemsParams, { cancelToken: sourceToken?.token });
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return (
		<>
			{
				<EntityUsedErrorModal
					onHide={() => {
						setErrors(undefined);
						setShowCategoryUsedErrorModal(false);
						setCategoriesToRemove(undefined);
					}}
					isShown={showCategoryUsedErrorModal}
					linkTo={`/${organization.id}/menu/item_groups?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}category|e=${
							categoriesToRemove &&
							(categoriesToRemove?.length > 0
								? categoriesToRemove.map((category) => category.id)
								: categoriesToRemove[0]?.id)
						}`
					)}`}
					title={t("constraints.category_is_used_move_prods_to_other_category")}
					linkTitle={t("modules.category.action.more_products.title")}
					items={drawItemGroups()}
				/>
			}
			<ListData
				data={items}
				config={config}
				emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
				onFetch={(fetchItems: CategoryApi[]) => setItems(fetchItems)}
				canManageMultiActionsForAllItems
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationMenuCategoriesIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContextValue = React.useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.category.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
			path: `${props.match.url}/new`,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.category.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationMenuCategoriesIndexPage;
