import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "go-app/components/Header";
import WebhookForm from "go-component/components/Webhook/WebhookForm";
import { WebhookApi } from "go-component/components/Webhook/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { selectOrganization } from "go-security/services/organizations/selectors";

const OrganizationLogsWebhooksNewPage = (): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(
			`${t("common.action.add", { ns: "lib" })} | ${t("lib:go_component.webhook.header.title")}`
		);
	}, []);

	const onSuccess = (webhook: WebhookApi) => {
		history.push(`/${organization.id}/logs/webhooks/${webhook.id}`);
	};

	return (
		<div className="content">
			<Header title={t("common.action.add", { ns: "lib" })} />
			<WebhookForm webhook={{ resource_type: "ORDER" } as WebhookApi} onSuccess={onSuccess} />
		</div>
	);
};

export default OrganizationLogsWebhooksNewPage;
