import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import handleError from "go-app/services/errors";
import EmptyData from "go-core/components/EmptyData";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ReactComponent as LockSVG } from "../../../../../../../../images/svg/liveOrders/lock.svg";
import { apiOrganization } from "../../../../../../../../services/Api/Organization/apiOrganization";
import { ItemApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	products: ItemApi[];
	setProductToBlock: (product?: ItemApi) => void;
	loading: boolean;
	handleUnblockProduct: (index: number, product: ItemApi) => void;
}

const LiveOrdersBlockedProductsTableModal = ({
	products,
	setProductToBlock,
	loading,
	handleUnblockProduct,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const [loadingIndex, setLoadingIndex] = useState(-1);
	const confirmation = useConfirmation();

	const handleUnblock = async (item: ItemApi, index: number) => {
		await confirmation({
			title: t("lib:confirmation.title"),
			message: t("lib:confirmation.message.action"),
		});
		setLoadingIndex(index);
		try {
			const res = await apiOrganization.unblockItem(item.id, {
				include: "category,item_group",
			});
			handleUnblockProduct(index, res);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoadingIndex(-1);
	};

	const handleChangeSuspension = (item: ItemApi, index: number, block: boolean) => {
		if (block) {
			setProductToBlock(item);
		} else {
			handleUnblock(item, index);
		}
	};

	const drawBlockingInformation = (product: ItemApi) => {
		if (product.suspended) {
			return (
				<span className="text-danger ms-2 text-break">
					<LockSVG style={{ marginBottom: "3px" }} />
					{product?.suspension?.until && (
						<span className="ms-1 ">
							{`${t("lib:common.word.to")} ${FormatDate(product.suspension.until)}`}
						</span>
					)}
					{product?.suspension?.reason && product.suspension.reason !== "-" && (
						<span className="text-break">{`${product?.suspension?.until ? ", " : " "}${
							product.suspension.reason
						}`}</span>
					)}
				</span>
			);
		}
	};

	return (
		<table className="table table-preview live-orders-blocked-products-table">
			<thead>
				<tr>
					<th />
					<th className="action" />
				</tr>
			</thead>
			<tbody>
				{products.length > 0
					? products.map((product, index) => (
							<tr key={`product-${product.id}`}>
								<td>
									<div className="d-flex align-items-center">
										<RenderImage
											showColor
											imageLink={product?.item_group?.image_link}
											data={product}
										/>
										<div className="d-flex flex-column">
											<div className="d-flex">
												<h5
													className={`mb-0 name text-break ${
														product.suspended ? "text-danger" : ""
													}`}
													title={product.name}
												>
													{product.name}
												</h5>
											</div>
											<div className="d-flex flex-wrap">
												<span className="text-muted text-break">{product.category.name}</span>
												{drawBlockingInformation(product)}
											</div>
										</div>
									</div>
								</td>
								<td className="action">
									<ButtonLoading
										loading={index === loadingIndex}
										variant={product.suspended ? "success" : "danger"}
										onClick={() => handleChangeSuspension(product, index, !product.suspended)}
									>
										{product.suspended
											? t("modules.live_order.action.unblock.title")
											: t("modules.live_order.action.block.title")}
									</ButtonLoading>
								</td>
							</tr>
					  ))
					: !loading && <EmptyData />}
			</tbody>
		</table>
	);
};

export default LiveOrdersBlockedProductsTableModal;
