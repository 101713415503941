import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationMenuCategoriesEditPage from "./pages/Edit";
import OrganizationMenuCategoriesIndexPage from "./pages/Index";
import OrganizationMenuCategoriesNewPage from "./pages/New";

const OrganizationMenuCategoriesPage: FC<RouteComponentProps> = (props) => {
	return (
		<>
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/new`} exact={true} component={OrganizationMenuCategoriesNewPage} />
					<Route path={`${props.match.url}/:categoryId`} component={OrganizationMenuCategoriesEditPage} />
					<Route path={`${props.match.url}`} component={OrganizationMenuCategoriesIndexPage} />
				</Switch>
			</div>
		</>
	);
};
export default OrganizationMenuCategoriesPage;
