import React, { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormInput } from "go-form";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomFieldTemplateApi } from "go-segment/services/types";

export interface AttributeProps {
	csv_field_name?: string;
	domain_field_name: string;
}

interface Props {
	attributes: AttributeProps[];
	handleImport: (data: ImportFormProps) => void;
	loading: boolean;
	customFieldsConfig?: CustomFieldTemplateApi[];
	redirectionUrl: string;
	databaseColumnName: Record<string, string>;
}

export interface ImportFormProps {
	attributes: AttributeProps[];
}

const AttributesTable = ({
	attributes,
	handleImport,
	loading,
	customFieldsConfig,
	redirectionUrl,
	databaseColumnName,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();
	const form = useForm<ImportFormProps>({
		criteriaMode: "all",
		defaultValues: { attributes },
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		formState,
	} = form;
	const { fields } = useFieldArray({
		control,
		name: "attributes",
		keyName: "key",
	});

	const getLabelValue = (item: AttributeProps) => {
		if (!item.domain_field_name) return;
		if (item?.domain_field_name?.includes("+")) {
			const cf = customFieldsConfig?.find((f) => f.slug === item.domain_field_name.replace("+", ""));
			if (cf) return cf.name;
			return item.domain_field_name;
		}
		return databaseColumnName[item.domain_field_name];
	};

	const getCsvOptions = () => {
		return attributes
			.filter((item) => item.domain_field_name)
			?.map((item) => {
				return {
					label: getLabelValue(item),
					value: item.domain_field_name,
				};
			});
	};

	const onSubmit = handleSubmit((data: ImportFormProps) => handleImport(data));

	useEffect(() => {
		fields.forEach((field, index) => {
			setValue(`attributes.${index}.domain_field_name`, field?.domain_field_name);
		});
	}, [fields, setValue]);

	return (
		<FormDirty formState={formState} onSubmit={onSubmit}>
			<Card>
				<Card.Body>
					<h5>{t("go_component.import_csv.action.match_column.title", { ns: "lib" })}</h5>
					<small className={"text-muted"}>
						{t("go_component.import_csv.field.match_columns_info.title", { ns: "lib" })}
					</small>
					<table className="table table-form table-with-sticky-header">
						<thead>
							<tr>
								<th className={"action"} />
								<th>{t("go_component.import_csv.field.csv_column.title", { ns: "lib" })}</th>
								<th>{t("go_component.import_csv.field.database_column.title", { ns: "lib" })}</th>
								<th className={"action"} />
							</tr>
						</thead>
						<tbody>
							{fields.map((attr, index) => {
								return (
									<tr key={index}>
										<td className={"action"} />
										<td>
											<FormInput
												errors={errors}
												type={"hidden"}
												register={register}
												name={`attributes.${index}.csv_field_name`}
												value={attr.csv_field_name}
											/>
											{attr?.csv_field_name}
										</td>
										<td>
											<FormSelectGroup
												isClearable={true}
												control={control}
												defaultValue={attr.domain_field_name}
												errors={errors}
												name={`attributes.${index}.domain_field_name`}
												options={getCsvOptions()}
												data-testid={`attributes.${index}.domain_field_name`}
											/>
										</td>
										<td className={"action"} />
									</tr>
								);
							})}
						</tbody>
					</table>
				</Card.Body>
			</Card>
			<div className="form-footer">
				<Form.Group className="form-group">
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("common.action.import", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={() => history.push(redirectionUrl)}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Form.Group>
			</div>
		</FormDirty>
	);
};
export default AttributesTable;
