import React, { FC, useState } from "react";
import classNames from "classnames";
import { Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import AccessTokenSessionService from "go-security/services/accessTokenSessionService";
import { ReactComponent as GastroSupplierSVG } from "../../../../../../../../images/svg/apps/large/gastrosupplier.svg";
import { ReactComponent as GoCrmSVG } from "../../../../../../../../images/svg/apps/large/goclient.svg";
import { ReactComponent as GoOrderSVG } from "../../../../../../../../images/svg/apps/large/goorder.svg";
import { ReactComponent as GoposSVG } from "../../../../../../../../images/svg/apps/large/gopos.svg";
import { ReactComponent as GoStockSVG } from "../../../../../../../../images/svg/apps/large/gostock.svg";
import { ReactComponent as GoTicketSVG } from "../../../../../../../../images/svg/apps/large/goticket.svg";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import MewsModalForm from "../Mews/MewsModalForm";
import AppCardInstalled from "./AppCardInstalled";
import AppCardUninstalled from "./AppCardUninstalled";
import AppModal from "./AppModal";
import BetasiModalForm from "./Betasi/BetasiModalForm";
import ChartModalForm from "./Chart/ChartModalForm";
import GoHotelIntegrationModalForm from "./GoHotelIntegration/GoHotelIntegrationModalForm";
import HolidayPmsInstalModal from "./HolidayPms/HolidayPmsInstalModal";
import HotelgramInstallModal from "./Hotelgram/HotelgramInstallModal";
import HotellionInstallModal from "./Hotellion/HotellionInstallModal";
import InforStarlightInstallModal from "./InforStarlight/InforStarlightInstallModal";
import KWHotelModalForm from "./KWHotel/KWHotelModalForm";
import MultiLocationInstallModal from "./MultiLocation/MultiLocationInstallModal";
import NfHotelInstallModal from "./NfHotel/NfHotelInstallModal";
import WoltDriveInstallModal from "./WoltDrive/WoltDriveInstallModal";

interface Props {
	configApp: ApplicationTemplateApi;
	installedApp?: ApplicationApi;
	routeProps?: RouteComponentProps;
	handleUninstall?: (app: ApplicationApi) => void;
	handleUpdate?: (app: ApplicationApi) => void;
	handleUpdateNewInstalledApp?: (app: ApplicationApi) => void;
}

export const renderAppIcon = (app: ApplicationTemplateApi): JSX.Element => {
	if (app.logo_link) {
		return <img className={"application-template-logo"} src={app.logo_link} />;
	}
	switch (app.provider) {
		case "GOPOS":
		case "CALL_RECORDER":
		case "LOCATIONS":
			return <GoposSVG />;
		case "GOCRM":
			return <GoCrmSVG />;
		case "GASTROSUPPLIER":
			return <GastroSupplierSVG />;
		case "GOTICKET":
			return <GoTicketSVG />;
		case "GOORDER":
			return <GoOrderSVG />;
		case "GOSTOCK":
			return <GoStockSVG />;
		default:
			return <img className={"application-template-logo"}></img>;
	}
};

const AppCard: FC<Props> = ({
	configApp,
	installedApp,
	routeProps,
	handleUpdate,
	handleUninstall,
	handleUpdateNewInstalledApp,
}): JSX.Element => {
	const [showModal, setShowModal] = useState(false);
	const [showLocationsModal, setShowLocationsModal] = useState(false);
	const [showGoHotelIntegrationModal, setShowGoHotelIntegrationModal] = useState(false);
	const [showWoltDriveModal, setShowWoltDriveModal] = useState(false);
	const [showMewsModal, setShowMewsModal] = useState<boolean>(false);
	const [showHotellionModal, setShowHotellionModal] = useState<boolean>(false);
	const [showInforStarlightModal, setShowInforStarlightModal] = useState<boolean>(false);
	const [showKWHotelModal, setShowKWHotelModal] = useState<boolean>(false);
	const [showChartModal, setShowChartModal] = useState<boolean>(false);
	const [showBetasiModal, setShowBetasiModal] = useState<boolean>(false);
	const [showHolidayPmsModal, setShowHolidayPmsModal] = useState<boolean>(false);
	const [showHotelgramModal, setShowHotelgramModal] = useState<boolean>(false);
	const [showNfHotelModal, setShowNfHotelModal] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();
	const onHide = () => {
		setShowLocationsModal(false);
	};

	const handleInstall = async () => {
		const accessToken = AccessTokenSessionService.get();
		switch (configApp.provider) {
			case "LOCATIONS":
				setShowLocationsModal(true);
				break;
			case "GOHOTELINTEGRATION":
				setShowGoHotelIntegrationModal(true);
				break;
			case "CALL_RECORDER": {
				const res = await api
					.organization()
					.installApplicationPost(configApp.provider, accessToken ? accessToken : "");
				handleUpdateNewInstalledApp && handleUpdateNewInstalledApp(res);
				break;
			}
			case "MEWS":
				setShowMewsModal(true);
				break;
			case "HOTELLION":
				setShowHotellionModal(true);
				break;
			case "INFOR_STARLIGHT":
				setShowInforStarlightModal(true);
				break;
			case "HOLIDAY_PMS":
				setShowHolidayPmsModal(true);
				break;
			case "HOTELGRAM":
				setShowHotelgramModal(true);
				break;
			case "BETASI":
				setShowBetasiModal(true);
				break;
			case "CHART":
				setShowChartModal(true);
				break;
			case "KWHOTEL":
				setShowKWHotelModal(true);
				break;
			case "NFHOTEL":
				setShowNfHotelModal(true);
				break;
			case "WOLT_DRIVE":
				setShowWoltDriveModal(true);
				break;
			default:
				api.organization().installApplication(configApp.provider, accessToken ? accessToken : "");
		}
	};

	return (
		<div className={"col-md-4 mb-3"}>
			{showModal && (
				<AppModal
					configApp={configApp}
					key={showModal ? "modal-on" : "modal-off"}
					handleUpdate={handleUpdate}
					installedApp={installedApp}
					handleUninstall={handleUninstall}
					handleUpdateNewInstalledApp={handleUpdateNewInstalledApp}
					onHide={() => {
						setShowModal(false);
						setShowLocationsModal(false);
						setShowGoHotelIntegrationModal(false);
						setShowMewsModal(false);
						setShowHotellionModal(false);
						setShowHolidayPmsModal(false);
						setShowBetasiModal(false);
						setShowChartModal(false);
						setShowHolidayPmsModal(false);
					}}
				/>
			)}
			{showHotellionModal && (
				<HotellionInstallModal
					isShown={showHotellionModal}
					onHide={() => setShowHotellionModal(false)}
					appState={{ app: {} as ApplicationApi }}
				/>
			)}
			{showInforStarlightModal && (
				<InforStarlightInstallModal
					isShown={showInforStarlightModal}
					onHide={() => setShowInforStarlightModal(false)}
					appState={{ app: {} as ApplicationApi }}
				/>
			)}
			{showWoltDriveModal && (
				<WoltDriveInstallModal
					isShown={showWoltDriveModal}
					onHide={() => setShowWoltDriveModal(false)}
					appState={{ app: {} as ApplicationApi }}
				/>
			)}
			{showHolidayPmsModal && (
				<HolidayPmsInstalModal
					isShown={showHolidayPmsModal}
					onHide={() => setShowHolidayPmsModal(false)}
					appState={{ app: {} as ApplicationApi }}
				/>
			)}
			{showHotelgramModal && (
				<HotelgramInstallModal
					isShown={showHotelgramModal}
					onHide={() => setShowHotelgramModal(false)}
					appState={{ app: {} as ApplicationApi }}
				/>
			)}
			{showNfHotelModal && (
				<NfHotelInstallModal
					isShown={showNfHotelModal}
					onHide={() => setShowNfHotelModal(false)}
					appState={{ app: {} as ApplicationApi }}
				/>
			)}
			{showLocationsModal && <MultiLocationInstallModal onHide={onHide} />}
			{showMewsModal && (
				<MewsModalForm
					appState={{ app: {} as ApplicationApi }}
					handleUpdateApp={(data) => handleUpdateNewInstalledApp && handleUpdateNewInstalledApp(data)}
					onHide={() => setShowMewsModal(false)}
					isShown={showMewsModal}
				/>
			)}
			{showGoHotelIntegrationModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowModal(false);
						setShowLocationsModal(false);
						setShowGoHotelIntegrationModal(false);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.GOHOTELINTEGRATION")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<GoHotelIntegrationModalForm
							handleUpdateApp={() => {
								setShowModal(false);
								setShowLocationsModal(false);
								setShowGoHotelIntegrationModal(false);
							}}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showKWHotelModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowModal(false);
						setShowLocationsModal(false);
						setShowKWHotelModal(false);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.KWHOTEL")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<KWHotelModalForm
							handleUpdateApp={() => {
								setShowModal(false);
								setShowLocationsModal(false);
								setShowKWHotelModal(false);
							}}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showChartModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowModal(false);
						setShowLocationsModal(false);
						setShowChartModal(false);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.CHART")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ChartModalForm
							handleUpdateApp={() => {
								setShowModal(false);
								setShowLocationsModal(false);
								setShowChartModal(false);
							}}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showBetasiModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowModal(false);
						setShowLocationsModal(false);
						setShowBetasiModal(false);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.BETASI")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<BetasiModalForm
							handleUpdateApp={() => {
								setShowModal(false);
								setShowLocationsModal(false);
								setShowBetasiModal(false);
							}}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			<Card
				className={classNames("h-100", { "card-hover": true })}
				onClick={() =>
					installedApp ? history.push(`${routeProps?.match.url}/${installedApp.id}`) : setShowModal(true)
				}
			>
				<Card.Body>
					{installedApp ? (
						<AppCardInstalled
							handleUninstall={handleUninstall}
							routeProps={routeProps}
							installedApp={installedApp}
							configApp={configApp}
							handleUpdate={handleUpdate}
						/>
					) : (
						<AppCardUninstalled configApp={configApp} onInstall={handleInstall} />
					)}
				</Card.Body>
			</Card>
		</div>
	);
};
export default AppCard;
