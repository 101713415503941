import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { FormInput } from "go-form";
import { ReactComponent as SortableHandleSvg } from "go-component/images/svg/sortable-handle.svg";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as RemoveSVG } from "go-core/images/svg/actions/remove.svg";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { CustomFieldOptionApi, CustomFieldTemplateApi } from "go-segment/services/types";

interface Props {
	form: UseFormReturn<CustomFieldTemplateApi>;
	type: string;
}

const CustomFieldOptionsForm = ({ form, type }: Props) => {
	const previousTypeValueWasDate = useRef<boolean>(type === "DATE");
	const {
		formState: { errors },
		control,
		register,
		watch,
	} = form;
	const { t } = useTranslation("admin");
	const { fields, remove, append, replace } = useFieldArray({
		control,
		name: "options",
		keyName: "key",
	});
	const isSortingRef = useRef(false);
	const isMobile = useWindowSize().isMobile;
	const watchedOptions = watch("options");

	useEffect(() => {
		if (type === "DATE" && previousTypeValueWasDate.current) return;

		for (let index = fields.length - 1; index >= 0; index--) {
			if (type === "DATE" || previousTypeValueWasDate.current) {
				remove(index);
			}
		}

		previousTypeValueWasDate.current = type === "DATE";
	}, [type, previousTypeValueWasDate.current]);

	const onRemove = (index: number) => {
		remove(index);
	};

	const handleUpdatePositions = (options: CustomFieldOptionApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		options?.forEach((option, index) => {
			option.position = index;
		});
		replace(options);
	};

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form">
					<thead>
						<tr>
							<th className="action" />
							<th style={{ minWidth: isMobile ? "400px" : "unset" }}>{t("lib:common.word.name")}</th>
							<th className="action" />
						</tr>
					</thead>
					<ReactSortable
						handle=".sortable-handler"
						tag="tbody"
						list={watchedOptions}
						onUpdate={() => (isSortingRef.current = true)}
						setList={handleUpdatePositions}
					>
						{watchedOptions.map((item, index) => {
							return (
								<tr key={fields[index].key}>
									<td className="action">
										<SortableHandleSvg className="sortable-handler" />
										<FormInput
											errors={errors}
											type="hidden"
											register={register}
											name={`options.${index}.position`}
											defaultValue={index}
										/>
									</td>
									<td>
										{type === "TEXT" && (
											<FormInput
												register={register}
												defaultValue={item.value}
												name={`options.${index}.value`}
												errors={errors}
											/>
										)}
										{type === "DATE" && (
											<FormDatePicker
												control={control}
												name={`options.${index}.value`}
												errors={errors}
											/>
										)}
										{type === "NUMBER" && (
											<FormInput
												type="number"
												register={register}
												defaultValue={item.value}
												name={`options.${index}.value`}
												errors={errors}
											/>
										)}
										{type === "LONG_TEXT" && (
											<FormInput
												register={register}
												name={`options.${index}.value`}
												errors={errors}
												defaultValue={item.value}
											/>
										)}
									</td>

									<td className="action">
										<RemoveSVG className="icon" onClick={() => onRemove(index)} />
									</td>
								</tr>
							);
						})}
					</ReactSortable>
				</table>
			</div>
			<Button
				variant="add"
				type="button"
				onClick={() => append({ value: "", position: fields.length - 1 } as CustomFieldOptionApi)}
			>{`+${t("common.action.add", { ns: "lib" })}`}</Button>
		</>
	);
};
export default CustomFieldOptionsForm;
