import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ReservationApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import ReservationForm from "../../components/ReservationForm";

interface StateType {
	from: { pathname: string };
	copiedItem: number;
}

const OrganizationReservationsNewPage = (): JSX.Element => {
	const [reservation, setReservation] = useState<ReservationApi | undefined>(undefined);
	const { t } = useTranslation();
	const { state } = useLocation<StateType>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchReservation();
	}, []);

	const onFetchReservation = async () => {
		try {
			if (state && state.copiedItem) {
				const params: Record<string, string> = { include: "room,table,client" };
				const res = await api.organization().getReservation(state.copiedItem, params);
				setReservation({
					...res,
					id: undefined,
					reservation_status: "",
				} as unknown as ReservationApi);
			} else {
				setReservation({} as ReservationApi);
			}
			handleChangeTabTitle(t("modules.reservation.header.new.title"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!reservation) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Header title={t("modules.reservation.header.new.title")} />
			<ReservationForm reservation={reservation} />
		</>
	);
};

export default OrganizationReservationsNewPage;
