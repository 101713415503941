import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DiscountApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import DiscountForm from "../../components/Form/DiscountForm";

interface MatchParams {
	discountId: string;
}

export const discountInclude = "entities.entity,entities,roles,payment_method,availability,tip,conditions";

const OrganizationMenuDiscountsEditPage = (): JSX.Element => {
	const [discount, setDiscount] = useState<DiscountApi | undefined>();
	const [showLogs, setShowLogs] = useState(false);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const isMobile = useWindowSize().isMobile;
	const { discountId } = useParams<MatchParams>();
	const { addFlash, addSuccessFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchDiscount();
	}, []);

	const onFetchDiscount = async () => {
		try {
			const params: Record<string, any> = {
				include: discountInclude,
			};
			const res = await api.organization().getDiscount(Number(discountId), params);
			setDiscount(res);
			handleChangeTabTitle(`${res.name} | ${t("common.word.discounts")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removeDiscount = async (discountToBeRemoved: DiscountApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeDiscount(discountToBeRemoved.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/menu/discounts`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restoreDiscount = async (discountToBeRestored: DiscountApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const params = { include: discountInclude };
			const res = await api.organization().restoreDiscount(discountToBeRestored.id, params);
			addSuccessFlash(t("lib:common.flash.completed"));
			setDiscount(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const disableDiscount = async (discountToBeDisabled: DiscountApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			await api.organization().disableDiscount(discountToBeDisabled);
			addSuccessFlash(t("lib:common.flash.completed"));
			history.push(`/${organization.id}/menu/discounts`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!discount) {
		return <LoadingContainer />;
	}

	const buttons: ButtonProps[] = [
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			variant: "light",
		},
	];
	if (discount.status !== "DELETED") {
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeDiscount(discount),
			dropdown: true,
		});
	}
	if (discount.status === "ENABLED") {
		buttons.push({
			title: t("common.action.disable", { ns: "lib" }),
			action: () => disableDiscount(discount),
			dropdown: true,
		});
	}
	if (discount.status === "DELETED" || discount.status === "DISABLED") {
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreDiscount(discount),
			dropdown: true,
		});
	}
	buttons.push({
		title: t("common.action.copy", { ns: "lib" }),
		action: () => {
			history.push({
				pathname: `${location.pathname.split("/").slice(0, -1).join("/")}/new`,
				state: {
					discount: {
						...discount,
						id: undefined,
						name: t("modules.discount.field.copy.title", { name: discount.name }),
					},
				},
			});
		},
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeDiscount(discount),
			hide: discount.status === "DELETED",
		},
		{
			title: t("common.action.disable", { ns: "lib" }),
			action: () => disableDiscount(discount),
			hide: discount.status !== "ENABLED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreDiscount(discount),
			hide: discount.status !== "DELETED" && discount.status !== "DISABLED",
		},
		{
			title: t("common.action.copy", { ns: "lib" }),
			action: () => {
				history.push({
					pathname: `${location.pathname.split("/").slice(0, -1).join("/")}/new`,
					state: {
						discount: {
							...discount,
							id: undefined,
							name: t("modules.discount.field.copy.title", { name: discount.name }),
						},
					},
				});
			},
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={`${t("common.word.discounts")} "${discount.name}"`} buttons={isMobile ? [] : buttons} />
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={discount.id}
					resourceType={"MENU_DISCOUNT"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			<DiscountForm discount={discount} />
		</>
	);
};

export default OrganizationMenuDiscountsEditPage;
