import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { unstable_batchedUpdates } from "react-dom";
import { useForm } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import { mapCustomFieldsToForm } from "go-app/components/CustomFields";
import CustomFieldsForm from "go-app/components/CustomFields/CustomFieldsForm";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { SaveAndAddButtons, TranslationType } from "go-core/components/SaveAndAddButtons";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	CategoryApi,
	ItemApi,
	ItemFormPointOfSaleApi,
	ItemGroupApi,
	ItemModifierGroupApi,
	ItemModifierGroupOptionApi,
	ModifierGroupApi,
	PointOfSaleApi,
	TranslationApi,
	VenueRoleApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { mapRoles } from "../../../../../components/ResourcePermissionTable";
import ImageLinks from "../../../components/ImageLinks";
import CreateMenuPageItemCategoryModal from "../../Menus/components/CreateMenuPageItemCategoryModal";
import { mapBarcodesToString } from "./Items/ItemModalForm";
import ItemsDirectionsForm from "./Items/ItemsDirectionsForm";
import ItemsList from "./Items/ItemsList";
import ItemsPointsOfSalesForm from "./Items/ItemsPointsOfSalesForm";
import NextItemGroupModal from "./Modals/NextItemGroupModal";
import ModifierGroupsList from "./ModifierGroups/ModifierGroupsList";

interface Props {
	itemGroup: ItemGroupApi;
	customFieldsConfig: CustomFieldTemplateApi[];
	pointsOfSale: PointOfSaleApi[];
	modifierGroups: ModifierGroupApi[];
	createNewProduct?: (id?: number) => void;
	passTranslations?: (itemGroupTranslations: TranslationApi[], items: ItemApi[], productName?: string) => void;
	translationItems?: ItemApi[];
	itemGroupTranslations?: TranslationApi[];
}

export const getWeightedVariantOptions = (t: TFunction): Record<string, any> => [
	{ value: "DISABLED", label: t("enums.item_groups.weighted.DISABLED") },
	{ value: "WEIGHTED", label: t("enums.item_groups.weighted.WEIGHTED") },
	{ value: "WEIGHTED_AFTER", label: t("enums.item_groups.weighted.WEIGHTED_AFTER") },
];

const getParsedItemPointsOfSale = (item: ItemApi, pointsOfSales: PointOfSaleApi[]) => {
	const itemPointsOfSale: ItemFormPointOfSaleApi[] = [];

	pointsOfSales.forEach((pointOfSale) => {
		const itemPointOfSale = (item.points_of_sale || []).find(
			(itemPoint) => itemPoint.point_of_sale_id === pointOfSale.id
		);

		if (itemPointOfSale) {
			itemPointsOfSale.push({
				...itemPointOfSale,
				visibility:
					typeof itemPointOfSale.visibility !== "boolean"
						? itemPointOfSale.visibility === "VISIBLE"
						: itemPointOfSale.visibility,
			});
		} else {
			itemPointsOfSale.push({
				point_of_sale: { ...pointOfSale },
				direction_id: undefined,
				direction: undefined,
				visibility: false,
				point_of_sale_id: pointOfSale.id,
			});
		}
	});

	return itemPointsOfSale;
};

const hasEveryItemSamePointsOfSales = (items: ItemApi[]) => {
	const firstItemSortedPointsOfSale = [
		...items[0].points_of_sale.sort((a, b) => a.point_of_sale_id - b.point_of_sale_id),
	].map((pointOfSale) => {
		return {
			...pointOfSale,
			id: "1",
		};
	});

	return items.every((item) => {
		const currentItemSortedPointsOfSale = [
			...item.points_of_sale.sort((a, b) => a.point_of_sale_id - b.point_of_sale_id),
		].map((pointOfSale) => {
			return {
				...pointOfSale,
				id: "1",
			};
		});

		return JSON.stringify(firstItemSortedPointsOfSale) === JSON.stringify(currentItemSortedPointsOfSale);
	});
};

const hasEveryItemSameDirection = (items: ItemApi[]) => {
	const firstItemDirectionId = items[0].direction?.id?.toString();
	return items.every((item) => item.direction?.id?.toString() === firstItemDirectionId);
};

const ItemGroupForm = (props: Props): JSX.Element => {
	const form = useForm<ItemGroupApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.itemGroup,
			custom_fields: mapCustomFieldsToForm(props.customFieldsConfig, props.itemGroup.custom_fields),
			items: [
				...props.itemGroup.items.map((item) => ({
					...item,
					points_of_sale: getParsedItemPointsOfSale(item, props.pointsOfSale),
				})),
			],
		},
		shouldUnregister: false,
	});
	const [advancedPointsOfSaleView, setAdvancedPointsOfSaleView] = useState(
		props.itemGroup.items.some((item) => (item.points_of_sale || []).length > 0)
	);
	const [advancedDirectionView, setAdvancedDirectionView] = useState(
		props.itemGroup.items.every((item) => (item.points_of_sale || []).length === 0) &&
			props.itemGroup.items.some((item) => item.direction)
	);
	const organization = useSelector(selectOrganization);
	const [newAvatar, setNewAvatar] = useState<string | undefined>(props.itemGroup.image_link?.default_link);
	const [saveLoading, setSaveLoading] = useState(false);
	const [saveAndAddLoading, setSaveAndAddLoading] = useState(false);
	const [category, setCategory] = useState(props.itemGroup.category);
	const [newCategory, setNewCategory] = useState<CategoryApi>();
	const [showCategoryModal, setShowCategoryModal] = useState(false);
	const [itemModifierGroups, setItemModifierGroups] = useState<Record<string, any>[]>([]);
	const [modifierGroups, setModifierGroups] = useState(props.modifierGroups);
	const [formApiErrors, setFormApiErrors] = useState<Record<string, any>[]>([]);
	const [showModal, setShowModal] = useState<Record<string, any> | undefined>(undefined);
	const [showImageLinks, setShowImageLinks] = useState(
		props.itemGroup.image_links && props.itemGroup.image_links?.length > 0
	);
	const saveAndAddMode = useRef<boolean>(false);
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		setError,
		control,
		reset,
		setValue,
		getValues,
		formState,
		clearErrors,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const watchedItems = watch("items");
	const [adjustItemsPointsOfSales, setAdjustItemsPointsOfSales] = useState(
		!hasEveryItemSamePointsOfSales(watchedItems)
	);
	const [adjustItemsDirections, setAdjustItemsDirections] = useState(!hasEveryItemSameDirection(watchedItems));

	useEffect(() => {
		props.itemGroupTranslations?.forEach((translation, index) => {
			const prefix = `translations.${index}`;
			registerObject(register, prefix, ["locale", "name", "description"]);
			setValue(`translations.${index}`, translation);
		});
		if (props.translationItems) {
			props.translationItems?.forEach((item, itemIndex) => {
				item.translations?.forEach((translation, index) => {
					const prefix = `items.${itemIndex}.translations.${index}`;
					registerObject(register, prefix, ["locale", "name", "description"]);
					setValue(`items.${itemIndex}.translations.${index}`, translation);
				});
			});
		}
	}, [props.itemGroupTranslations, props.translationItems]);

	useEffect(() => {
		reset({
			...props.itemGroup,
			items: [
				...props.itemGroup.items.map((item) => {
					return {
						...item,
						name: item.name?.replace(`${props.itemGroup.name} `, ""),
						points_of_sale: getParsedItemPointsOfSale(item, props.pointsOfSale),
					};
				}),
			],
			custom_fields: mapCustomFieldsToForm(props.customFieldsConfig, props.itemGroup.custom_fields),
		});
		setCategory(props.itemGroup.category);
	}, [props.itemGroup.status]);

	const getOmittedErrors = (omittedErrors: Array<Record<string, any>>, omittedFields: string[]) => {
		const newErrors: Record<string, any>[] = [];
		omittedErrors.forEach((err) => {
			if (!omittedFields?.some((field) => err.field?.endsWith(field))) {
				newErrors.push(err);
			}
		});
		return newErrors;
	};

	const processFormData = (data: ItemGroupApi) => {
		data.translations = data.translations?.filter(
			(f) => f.name?.length > 0 || (f.description && f.description.length > 0)
		);
		if (advancedDirectionView || !advancedPointsOfSaleView) {
			data.items = [
				...data.items.map((item) => ({
					...item,
					points_of_sale: [],
				})),
			];
		} else {
			data.items = [
				...data.items.map((item) => ({
					...item,
					points_of_sale: [...item.points_of_sale].map((point) => ({
						...point,
						visibility:
							typeof point.visibility !== "boolean"
								? point.visibility
								: point.visibility
								? "VISIBLE"
								: "HIDDEN",
					})),
					direction_id: undefined,
				})),
			];
		}

		if (!adjustItemsDirections) {
			data.items = [
				...data.items.map((item) => ({
					...item,
					direction: data.items[0].direction ? { ...data.items[0].direction } : undefined,
					direction_id: data.items[0].direction_id,
				})),
			];
		}

		if (getValues("image.image_data") || !newAvatar) {
			data.image_link = undefined;
		}
		if (!getValues("color")) {
			data.color = undefined;
		}
		if (data.items.length === 1) {
			if (data.items[0].name?.length === 0) {
				data.items[0].name = watch("name");
			}
			data.items[0].description = watch("description");
			data.items[0].translations = data.translations;
		}
		data.items.forEach((item, index) => {
			if (index === 0) {
				if (item.name === "" || !item.name) {
					item.name = data.name;
				} else if (!item.name.includes(data.name) && data.items.length > 1) {
					item.name = `${data.name} ${item.name}`;
				} else item.name = `${data.name} ${item.name}`;
			} else {
				item.name = `${data.name} ${item.name}`;
			}
		});

		data.items.forEach((item) => {
			if (!item?.suspended && item?.suspension && Object.keys(item?.suspension)?.length > 0) {
				delete item.suspension;
			}
		});

		data.items = data.items.map((item) => {
			return {
				...item,
				id: item.id < 1 ? undefined : item.id,
				barcodes: mapBarcodesToString(item.barcodes),
				translations: item.translations?.filter(
					(f) => f.name?.length > 0 || (f.description && f.description?.length > 0)
				),
				modifier_groups: !item.modifier_groups
					? []
					: [
							...item.modifier_groups.map((x) => {
								return {
									...x,
									position: modifierGroups.findIndex((f) => f.id === x.modifier_group_id),
								};
							}),
					  ].sort((a, b) => a.position - b.position),
			} as ItemApi;
		});
		data.points_of_sale = undefined;
		delete data.direction;
		delete data.direction_id;

		return data;
	};

	const onSubmit = handleSubmit(async (data: ItemGroupApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		saveAndAddMode.current ? setSaveAndAddLoading(true) : setSaveLoading(true);
		data = processFormData(data);
		data.id = props.itemGroup.id;
		try {
			if (props.itemGroup.id) {
				data.roles = mapRoles(data);
				if (data.items.length === 1) {
					data.items[0].name = data.name;
				}
				const itemGroupParams: Record<string, any> = {
					include:
						"image_links,category,tax,roles,translations,direction,custom_fields,items,items.modifier_groups,items.modifier_groups.quantity_info,items.modifier_groups.modifier_group,items.modifier_groups.options,items.modifier_groups.options.quantity_info_override,items.modifier_groups.options.price_override,items.stock_info,items.translations,points_of_sale,points_of_sale.direction,points_of_sale.point_of_sale,items.barcodes,items.availability,items.stock_info,items.order_types",
				};
				const res = await api.organization().updateItemGroup(data, itemGroupParams);
				if (saveAndAddMode.current) history.push(`/${organization.id}/menu/item_groups/new`);
				props.itemGroup.image_link = res.image_link;
				const newData = {
					...res,
					items: res.items.map((item: ItemApi) => {
						return {
							...item,
							name:
								res.items.length > 1
									? item.name.includes(`${res.name} `)
										? item.name.replace(`${res.name} `, "")
										: item.name.replace(`${res.name}`, "")
									: item.name,
							points_of_sale: [...getParsedItemPointsOfSale(item, props.pointsOfSale)],
						};
					}),
					custom_fields: mapCustomFieldsToForm(props.customFieldsConfig, res.custom_fields),
				};
				reset(newData);
				setFormApiErrors([]);
				setValue("name", res.name);
				setValue("description", res.description);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				data.roles = mapRoles(data);
				if (data.items.length === 1) {
					data.items[0].name = data.name;
				}
				const res = await api.organization().createItemGroup(data);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));

				if (saveAndAddMode.current) setShowModal({ id: res.id, name: res.name });
				else history.push(`/${organization.id}/menu/item_groups/${res.id}`);
			}
		} catch (e) {
			handleError.form(e, setError, addFlash, ["quantity_info", "barcodes"]);
			setFormApiErrors(getOmittedErrors(handleException(e), ["quantity_info"]));
		} finally {
			setSaveLoading(false);
			setSaveAndAddLoading(false);
		}
	});

	useEffect(() => {
		setModifierGroups(() => [...props.modifierGroups]);
	}, [props.modifierGroups]);

	const onCreateNextProduct = async () => {
		saveAndAddMode.current = true;
		onSubmit();
	};

	useEffect(() => {
		const currentModifierGroups: ItemModifierGroupApi[] = [];
		props.itemGroup?.items.forEach((item) => {
			item.modifier_groups?.forEach((mod) => {
				currentModifierGroups.push(mod);
			});
		});
		const currentItemModifierGroups = [
			...currentModifierGroups.filter(
				(v, i, a) =>
					a.findIndex((currentModifier) => currentModifier.modifier_group_id === v.modifier_group_id) === i
			),
		];
		setItemModifierGroups(currentItemModifierGroups);
		const x = document.getElementById("entity_translation");
		if (x) {
			x.addEventListener("click", () =>
				props.passTranslations
					? props.passTranslations(getValues().translations, getValues().items, getValues("name"))
					: undefined
			);
		}
	}, []);

	const searchTaxes = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getTaxesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchCategories = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getCategoriesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onCategoryCreate = (inputValue?: string) => {
		setNewCategory({ name: inputValue } as CategoryApi);
		setShowCategoryModal(true);
	};
	const handleCategorySave = (categoryToBeSaved: CategoryApi) => {
		setValue("category_id", categoryToBeSaved.id);
		setCategory(categoryToBeSaved);
		setShowCategoryModal(false);
	};

	const handleImageChange = (imageBase: string | undefined) => {
		setNewAvatar(imageBase);
	};

	const searchRoles = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getRolesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	useEffect(() => {
		if (props.passTranslations) {
			props.passTranslations(getValues().translations, getValues().items, getValues("name"));
		}
	}, [watchedItems.length]);

	const getRoleTags = (roles?: VenueRoleApi[]) => {
		return roles?.map((role) => {
			return {
				label: role.role_name,
				id: Number(role.id),
			};
		});
	};

	const parseToItemModifierGroup = (data: ModifierGroupApi) => {
		return {
			id: null,
			modifier_group_id: data.id,
			name: data.name,
			options: data.options,
		};
	};

	const handleAddModifierGroup = (
		data: ModifierGroupApi,
		items: ItemApi[] | undefined,
		quantityOverrides: any,
		priceOverrides: any,
		itemQuantityOverrides: any
	) => {
		const obj = JSON.parse(JSON.stringify(data)) as ModifierGroupApi;
		formApiErrors.forEach((err) => {
			if (err.field?.includes && err.field.includes("modifier_groups")) {
				clearErrors(err.field);
			}
		});
		setFormApiErrors([]);
		setItemModifierGroups([...itemModifierGroups, parseToItemModifierGroup(data)]);
		const mappedOptions = obj.options.map((opt) => {
			const option = {} as ItemModifierGroupOptionApi;
			const price = priceOverrides?.find((f: { item_id: string }) => f.item_id === opt.item_id.toString());
			const quan = quantityOverrides?.find((f: { item_id: string }) => f.item_id === opt.item_id.toString());
			if (quan) {
				option.quantity_info = quan.quantity_info;
			}
			if (price) {
				option.price = price.price;
			}
			if (opt.name) {
				option.name = opt.name;
			}
			option.item_id = opt.item_id;
			return option;
		});
		const dataTest = {
			id: undefined,
			modifier_group_id: obj.id,
			name: obj.name,
			position: 0,
			quantity_info: itemQuantityOverrides,
			options: mappedOptions,
		} as ItemModifierGroupApi;
		watchedItems.forEach((item, index) => {
			if (items === undefined) {
				let currItems = getValues(`items.${index}.modifier_groups`) as ItemModifierGroupApi[];
				if (!currItems) {
					currItems = [];
				}
				dataTest.position = currItems.length;
				const newValueItems = [...currItems, dataTest];
				setValue(`items.${index}.modifier_groups`, newValueItems, { shouldDirty: true });
			} else if (
				items.find(
					(f) => (f.id && f.id === item.id) || (f.source_item_id && f.source_item_id === item.source_item_id)
				)
			) {
				let currItems = getValues(`items.${index}.modifier_groups`) as ItemModifierGroupApi[];
				if (!currItems) {
					currItems = [];
				} else {
					currItems = currItems.filter((f) => f.modifier_group_id !== obj.id);
				}
				dataTest.position = currItems.length;
				const newValueItems = [...currItems, dataTest];
				setValue(`items.${index}.modifier_groups`, newValueItems, { shouldDirty: true });
			} else {
				let currItems = getValues(`items.${index}.modifier_groups`) as ItemModifierGroupApi[];
				currItems = currItems.filter((f) => f.modifier_group_id !== obj.id);
				dataTest.position = currItems.length;
				setValue(`items.${index}.modifier_groups`, [...currItems], { shouldDirty: true });
			}
		});
	};

	const handleRemoveModifierGroup = (data: ModifierGroupApi) => {
		formApiErrors.forEach((err) => {
			if (err.field.includes("modifier_groups")) {
				clearErrors(err.field);
			}
		});
		setFormApiErrors([]);
		watchedItems.forEach((item, index) => {
			const currItems = getValues(`items.${index}.modifier_groups`);
			if (!currItems) return;
			setValue(`items.${index}.modifier_groups`, [...currItems.filter((f) => f.modifier_group_id !== data.id)], {
				shouldDirty: true,
			});
		});
	};

	const redirectToNewProduct = (type?: string, id?: number) => {
		if (type === "NEW") {
			props.createNewProduct?.(undefined);
		}
		if (type === "COPY") {
			props.createNewProduct?.(getValues().id);
		}
		if (type === "EXISTING") {
			props.createNewProduct?.(id);
		}
		setShowModal(undefined);
	};

	const changeDefaultDirectionView = () => {
		unstable_batchedUpdates(() => {
			setAdvancedDirectionView(!advancedDirectionView);
			setAdvancedPointsOfSaleView(false);
			if (advancedDirectionView) {
				watchedItems.forEach((_, index) => {
					setValue(`items.${index}.direction_id`, undefined, { shouldDirty: true });
				});
			}
		});
	};

	const changeAdvancedPosesView = () => {
		unstable_batchedUpdates(() => {
			setAdvancedPointsOfSaleView(!advancedPointsOfSaleView);
			setAdvancedDirectionView(false);
			if (!advancedPointsOfSaleView) {
				watchedItems.forEach((item, index) => {
					const initialItem = props.itemGroup.items.find(({ id }) => item.id === id);
					setValue(
						`items.${index}.points_of_sale`,
						getParsedItemPointsOfSale(initialItem ?? item, props.pointsOfSale).map((item) => ({
							...item,
							visibility: true,
						})),
						{
							shouldDirty: true,
						}
					);
				});
			} else {
				watchedItems.forEach((item, index) => {
					setValue(`items.${index}.points_of_sale`, [], {
						shouldDirty: true,
					});
				});
			}
		});
		watchedItems.forEach((_, index) => {
			setValue(`items.${index}.direction_id`, undefined, { shouldDirty: true });
		});
	};

	const onChangeItemGroupName = (evt: React.ChangeEvent<HTMLInputElement>) => {
		getValues().items.forEach((item, index) => {
			clearErrors(`items.${index}.name`);
		});
		if (watchedItems.length === 1) {
			setValue("items.0.name", evt.target.value);
		}
		setValue("name", evt.target.value, { shouldDirty: true });
	};

	const productTypes = ["PRODUCT", "MODIFIER", "PACKAGE"].map((pt) => ({
		value: pt,
		label: t(`enums.items.type.${pt}`),
	}));

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const categoryValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	const taxValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	return (
		<FormDirty
			formState={formState}
			loading={saveLoading}
			onSubmit={onSubmit}
			key="product-form"
			buttonSubmitCustom={
				<SaveAndAddButtons
					saveLoading={saveLoading}
					saveAndAddLoading={saveAndAddLoading}
					onSaveAndAddClick={onCreateNextProduct}
					translationType={TranslationType.MALE_2}
				/>
			}
		>
			<fieldset className="form-group no-gutters">
				<div className="row">
					<div className={"col-md-10"}>
						<div className={"row"}>
							<div className={"col-md-6"}>
								<FormInput type="hidden" name="id" errors={errors} register={register} />
								<FormInput
									label={t("lib:common.word.name")}
									register={register}
									name="name"
									errors={errors}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeItemGroupName(e)}
									customValidationConfig={nameValidationConfig}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormSelectGroup
									label={t("common.word.category")}
									path={`/${organization.id}/menu/categories/`}
									name="category_id"
									errors={errors}
									onCreateOption={onCategoryCreate}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.id}
									defaultValue={{
										label: category?.name,
										id: category?.id,
									}}
									loadOptions={searchCategories}
									control={control}
									customValidationConfig={categoryValidationConfig}
									data-testid="category_id"
								/>
								{showCategoryModal && (
									<Modal show={true} onHide={() => setShowCategoryModal(false)}>
										<CreateMenuPageItemCategoryModal
											searchText={newCategory?.name ? newCategory.name : ""}
											handleCancel={() => setShowCategoryModal(false)}
											handleSave={(data) => handleCategorySave(data)}
										/>
									</Modal>
								)}
							</div>
							<div className={"col-md-6"}>
								<FormSelectGroup
									label={t("modules.item_group.field.tax.title")}
									name="tax_id"
									errors={errors}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.id}
									defaultValue={{
										label: props.itemGroup.tax?.name,
										id: props.itemGroup.tax?.id,
									}}
									loadOptions={searchTaxes}
									control={control}
									customValidationConfig={taxValidationConfig}
									data-testid="tax_id"
								/>
							</div>
							<div className={"col-md-6"}>
								<FormSelectGroup
									control={control}
									label={t("lib:common.word.type")}
									options={productTypes}
									name="type"
									errors={errors}
									data-testid="type"
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									register={register}
									as={"textarea"}
									rows={3}
									label={t("lib:common.word.description")}
									name={"description"}
									errors={errors}
									style={{ minHeight: "calc(2 * 1px + 2 * 6px + 50.391px)" }}
								/>
							</div>
						</div>
					</div>
					<div className={"col-md-2"} style={{ minWidth: "150px" }}>
						<ImageColorForm
							form={form}
							handleSave={handleImageChange}
							prefix={"image"}
							mode="IMAGE_COLOR"
							data={props.itemGroup}
							resourceLink={props.itemGroup.image_link}
							resourceData={props.itemGroup.image}
							entityName={watch("name")}
						/>
						{!showImageLinks &&
							(watch("image_links")?.length === 0 || watch("image_links") === undefined) && (
								<div
									className={"action-ref text-center"}
									onClick={() => setShowImageLinks(true)}
								>{`+ ${t("modules.item_group.action.add_more_images.title")}`}</div>
							)}
					</div>
				</div>
				{showImageLinks && <ImageLinks handleCloseModal={() => setShowImageLinks(false)} form={form} />}
			</fieldset>
			<ItemsList
				errors={formApiErrors}
				setFormApiErrors={setFormApiErrors}
				setErrors={setErrors}
				modifierGroups={modifierGroups}
				itemModifierGroups={itemModifierGroups}
				form={form}
			/>
			<ModifierGroupsList
				form={form}
				modifierGroups={modifierGroups}
				itemModifierGroups={itemModifierGroups}
				items={watchedItems}
				errors={formApiErrors}
				handleAddModifierGroup={(
					modifierGroup,
					items,
					quantityOverrides,
					priceOverrides,
					itemQuantityOverrides
				) =>
					handleAddModifierGroup(
						modifierGroup,
						items,
						quantityOverrides,
						priceOverrides,
						itemQuantityOverrides
					)
				}
				handleRemoveModifierGroup={(modifierGroup) => handleRemoveModifierGroup(modifierGroup)}
				changeModifierGroups={(items) => setModifierGroups(items)}
				addModifierGroup={(item) => setModifierGroups((groups) => [...groups, item])}
			/>
			<fieldset className="no-gutters items-points-of-sales-form">
				<ItemsDirectionsForm
					form={form}
					advancedDirectionView={advancedDirectionView}
					items={watchedItems}
					changeDefaultDirectionView={changeDefaultDirectionView}
					setAdjustItems={setAdjustItemsDirections}
					adjustItems={adjustItemsDirections}
				/>
				<ItemsPointsOfSalesForm
					form={form}
					pointsOfSales={props.pointsOfSale}
					advancedPosesView={advancedPointsOfSaleView}
					changeAdvancedPosesView={changeAdvancedPosesView}
					items={watchedItems}
					setAdjustItems={setAdjustItemsPointsOfSales}
					adjustItems={adjustItemsPointsOfSales}
				/>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("lib:common.word.advanced")}</h5>
				<CustomFieldsForm
					form={form}
					customFieldsConfig={props.customFieldsConfig}
					customFields={props.itemGroup.custom_fields}
					setErrors={setErrors}
				/>
				<FormSelectGroup
					label={t("modules.item_group.field.role.title")}
					isMulti={true}
					name="roles"
					errors={errors}
					defaultValue={getRoleTags(props.itemGroup.roles)}
					getOptionLabel={(option) => option.label}
					getOptionValue={(option) => option.id}
					loadOptions={searchRoles}
					control={control}
					data-testid="roles"
				/>
			</fieldset>
			{showModal && (
				<NextItemGroupModal
					onHide={() => setShowModal(undefined)}
					itemProps={showModal}
					form={form}
					createItemGroup={(type, id) => redirectToNewProduct(type, id)}
				/>
			)}
		</FormDirty>
	);
};
export default ItemGroupForm;

// t("enums.items.type.PRODUCT");
// t("enums.items.type.MODIFIER");
// t("enums.items.type.PACKAGE");
