import { getResourceStatusClass } from "go-app/components/FormatResourceStatus/FormatResourceStatus";

export const getComponentStatusClassName = (
	status: string
): "primary" | "success" | "danger" | "warning" | "secondary" | "blue" | undefined => {
	const labelClass = getResourceStatusClass(status);
	if (labelClass) return labelClass;
	switch (status) {
		case "OPEN":
		case "OPENED":
			return "blue";
		case "NEW":
		case "STARTED":
			return "primary";
		case "SKIPPED":
		case "TO_CANCEL":
			return "warning";
		case "FINISHED":
		case "SUCCESS":
			return "success";
		case "ERROR":
		case "CANCELLED":
		case "REMOVED":
			return "danger";
	}

	return undefined;
};
