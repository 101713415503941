import { TFunction } from "react-i18next";
import { getMonthName } from "go-core/components/Formatters/FormatDate";

export class ReportSaleUtils {
	static getGroupsInfo = (t: TFunction) => {
		return {
			FISCALIZATION_STATUS: (groupByValue: Record<string, any>) => {
				if (groupByValue.name === "empty") return "";
				return t(`enums.orders.fiscalization.${groupByValue.name}`);
			},
		};
	};

	static getGroups = (t: TFunction) => [
		{
			id: "FISCALIZATION_STATUS",
			name: t("modules.order.field.fiscalization_status.title"),
		},
		{
			id: "TAX_ID_NO",
			name: t("common.word.tax_id_no.title", { ns: "lib" }),
		},
	];

	static getFilters = (t: TFunction) => [
		{
			id: "fiscalization_status",
			name: t("modules.order.field.fiscalization_status.title"),
			type: "list",
			listOptions: {
				FISCALIZED: t("enums.orders.fiscalization.FISCALIZED"),
				NOT_FISCALIZED: t("enums.orders.fiscalization.NOT_FISCALIZED"),
				ERROR: t("enums.orders.fiscalization.ERROR"),
				UNKNOWN: t("enums.orders.fiscalization.UNKNOWN"),
			},
		},
		{
			id: "tax_id_no",
			name: t("common.word.tax_id_no.title", { ns: "lib" }),
			type: "text",
		},
	];
}
