import React, { useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import { VenueSettingsApi } from "../../../../../../../../../../../services/Api/Organization/types";
import SettingsTable from "./SettingsTable";

interface Props {
	types: Record<string, any>[];
	form: UseFormReturn<VenueSettingsApi>;
	title: string;
	search: string;
}

const ExternalStockCard = (props: Props): JSX.Element => {
	const [collapseIn, setCollapseIn] = useState(false);
	const onCollapse = () => {
		const icon = document.getElementById("stock-icon");
		if (icon) {
			icon.style.transform = !collapseIn ? "rotate(180deg)" : "";
		}
		setCollapseIn(!collapseIn);
	};

	useEffect(() => {
		if (props.search.length > 0 && !collapseIn && props.types.length > 0) {
			onCollapse();
		}
		if (props.types.length === 0 && collapseIn) {
			onCollapse();
		}
	}, [props.search]);

	return (
		<Card className={"venue-settings"}>
			<Card.Body>
				<div className={"title"} onClick={() => onCollapse()}>
					<h5>{props.title}</h5>
					<CollapseSVG id={"stock-icon"} className={"collapse-icon"} />
				</div>
				<Collapse in={collapseIn}>
					<div>
						<table className={"table table-preview"}>
							<SettingsTable types={props.types} form={props.form} />
						</table>
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};
export default ExternalStockCard;
