import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Range } from "react-date-range";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDateRangePicker } from "go-form/components/FormDateRangePicker";
import { apiOrganization } from "../../../../../../../../../../../../services/Api/Organization/apiOrganization";
import { PosReporCheckBeforeDeletionApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { DateRange } from "../../services/types";
import ClearSaleByPOSReport from "./ClearSaleByPOSReport";
import { ClearSaleFormProps, SaleNonPermanentDeletionType } from "./services/types";

interface Props {
	form: UseFormReturn<ClearSaleFormProps>;
	posReportDetails?: PosReporCheckBeforeDeletionApi;
	onSetPosReportDetails: (posReportDetails: PosReporCheckBeforeDeletionApi | undefined) => void;
	currentReportUid?: string;
	onSetCurrentReportUid: (currentReportUid: string | undefined) => void;
	nonPermanentDeletionType: SaleNonPermanentDeletionType;
	onSetNonPermanentDeletionType: (type: SaleNonPermanentDeletionType) => void;
	selectedDeletionRange?: string | DateRange;
	onSetSelectedDeletionRange: (range: string | DateRange | undefined) => void;
}

export const handleFormChange = (
	dataToBeDeleted: string[] | Range,
	onSetPosReportDetails: (posReportDetails: PosReporCheckBeforeDeletionApi | undefined) => void,
	onSetSelectedDeletionRange: (currentReportUid: string | DateRange | undefined) => void,
	isDatePickerActive: boolean = false
): void => {
	if (isDatePickerActive) {
		const { startDate, endDate } = dataToBeDeleted as Range;
		onSetSelectedDeletionRange({
			date_from: startDate,
			date_to: endDate,
		});
	} else if (Array.isArray(dataToBeDeleted)) {
		if (dataToBeDeleted.length === 0) {
			onSetSelectedDeletionRange(undefined);
			return;
		}
		onSetSelectedDeletionRange(dataToBeDeleted.join(","));
	}
	onSetPosReportDetails(undefined);
};

const POSReportDeletion = ({
	form,
	posReportDetails,
	onSetPosReportDetails,
	onSetCurrentReportUid,
	nonPermanentDeletionType,
	onSetNonPermanentDeletionType,
	selectedDeletionRange,
	onSetSelectedDeletionRange,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const {
		formState: { errors },
		control,
		watch,
		resetField,
	} = form;
	const { addFlash } = useFlash();

	useEffect(() => {
		if (nonPermanentDeletionType === SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION) {
			const today = new Date();
			const initialDateRange = {
				startDate: new Date(today.setHours(0, 0, 0, 0)),
				endDate: new Date(today.setHours(23, 59, 59)),
			} as Range;
			handleFormChange(initialDateRange, onSetPosReportDetails, onSetSelectedDeletionRange, true);
		}
	}, [nonPermanentDeletionType]);

	const checkPOSReport = async () => {
		if (!selectedDeletionRange) return;
		setLoading(true);
		try {
			let res: PosReporCheckBeforeDeletionApi | null = null;
			if (typeof selectedDeletionRange === "string")
				res = await apiOrganization.checkPosReportBeforeClear(selectedDeletionRange);
			else if (selectedDeletionRange?.date_from && selectedDeletionRange?.date_to)
				res = await apiOrganization.clearSaleBasedOnDateRange(
					selectedDeletionRange.date_from,
					selectedDeletionRange.date_to
				);

			if (res !== null) {
				onSetPosReportDetails(res);
				onSetCurrentReportUid(res.uid);
			}
		} catch (err: any) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const dateChangeHandler = (dateRange: Range) => {
		handleFormChange(dateRange, onSetPosReportDetails, onSetSelectedDeletionRange, true);
	};

	const POSReportNoOptionSelected = !watch(SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION);
	const dateRangeActive = nonPermanentDeletionType === SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION;

	const setPOSReportDeletionTypeHandler = (limitedContentVisible?: boolean) => {
		if (limitedContentVisible) {
			onSetNonPermanentDeletionType(SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION);
			onSetPosReportDetails(undefined);
			onSetCurrentReportUid(undefined);
		} else {
			onSetNonPermanentDeletionType(SaleNonPermanentDeletionType.UNSET);
			resetField(SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION);
		}
	};

	const setDateRangeDeletionTypeHandler = (limitedContentVisible?: boolean) => {
		if (limitedContentVisible) {
			onSetNonPermanentDeletionType(SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION);
			resetField(SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION);
			onSetPosReportDetails(undefined);
			onSetCurrentReportUid(undefined);
		} else onSetNonPermanentDeletionType(SaleNonPermanentDeletionType.UNSET);
	};

	return (
		<>
			<div className="mt-2">
				<LimitComponentVisibility
					defaultVisible={nonPermanentDeletionType === SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION}
					id={SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION}
					legendText={t("modules.clear_data.field.delete_venue_based_on_specific_pos_report.title")}
					handleChange={setPOSReportDeletionTypeHandler}
					unlimitedContent={
						<ClearSaleByPOSReport
							selectedDeletionRange={selectedDeletionRange}
							form={form}
							onSetSelectedDeletionRange={onSetSelectedDeletionRange}
							posReportDetails={posReportDetails}
							onSetPosReportDetails={onSetPosReportDetails}
						/>
					}
				/>
			</div>
			<LimitComponentVisibility
				defaultVisible={nonPermanentDeletionType === SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION}
				id={SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION}
				legendText={t("modules.clear_data.field.clear_sale_based_on_date.title")}
				handleChange={setDateRangeDeletionTypeHandler}
				unlimitedContent={
					<FormDateRangePicker
						timeStart={"00:00"}
						timeEnd={"23:59"}
						name={SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION}
						errors={errors}
						control={control}
						minDate={new Date("2015")}
						maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
						onApply={dateChangeHandler}
					/>
				}
			/>
			{posReportDetails && nonPermanentDeletionType !== SaleNonPermanentDeletionType.UNSET && (
				<div className="mb-2">
					<span>{`${t("modules.clear_data.field.number_of_orders.title")}: ${
						posReportDetails.order_count
					}`}</span>
					<br />
					<span>{`${t("modules.clear_data.field.shift_work_report_count.title")}: ${
						posReportDetails.shift_work_report_count
					}`}</span>
					<br />
					<span>{`${t("modules.clear_data.field.drawer_report_count.title")}: ${
						posReportDetails.drawer_report_count
					}`}</span>
					<br />
					<span>{`${t("modules.clear_data.field.number_of_external_reports.title")}: ${
						posReportDetails.external_report_count
					}`}</span>
					<br />
					<span>{`${t("modules.clear_data.field.number_of_transactions.title")}: ${
						posReportDetails.transaction_count
					}`}</span>
					<br />
					<span>{`${t("modules.clear_data.field.number_of_invoices.title")}: ${
						posReportDetails.invoice_count
					}`}</span>
					<br />
				</div>
			)}
			{(dateRangeActive || !POSReportNoOptionSelected) && (
				<ButtonLoading loading={loading} onClick={checkPOSReport} className="me-2">
					{t("modules.clear_data.action.check.title")}
				</ButtonLoading>
			)}
		</>
	);
};

export default POSReportDeletion;
