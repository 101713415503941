import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { ReactComponent as DropdownSVG } from "../../../images/dropdown.svg";
import { ReactComponent as SortableHandleSvg } from "../../../images/sortable-handle.svg";
import { ReportGroup } from "../../../services/types";

const getAllUniqueGroups = (groups: Record<string, any>[]) => {
	return groups
		.map((g) => {
			if (g?.resources && g?.resources?.length > 0) {
				return g.resources.map((r: any) => ({
					...g,
					resources: [r],
				}));
			}
			return [g];
		})
		.flat();
};

const getIdForGroup = (group: Record<string, any>) => {
	if (group?.resources && group?.resources?.length > 0) {
		const resources = group.resources[0].resource;
		return `${group.id}.${resources}`;
	}
	return group.id;
};

interface Props {
	selectedGroups: string[];
	groups: Array<Record<string, any>>;
	onChangeGroups: (checkedGroups: Array<string>, initialRender: boolean) => void;
}

const SortableGroups = (props: Props): JSX.Element => {
	const isSortingRef = useRef(false);
	const groups = props.groups;
	const [showMore, setShowMore] = useState(false);
	const { t } = useTranslation();

	const createSortableGroups = (): ReportGroup[] => {
		const newCheckedGroups: ReportGroup[] = [];
		const sortOrder = props.selectedGroups;
		const groupIds = getAllUniqueGroups(groups).map(getIdForGroup);
		const groupsToSort = groupIds
			.filter((f) => sortOrder.includes(f))
			.sort((a, b) => {
				const indexOfA = sortOrder.indexOf(a);
				const indexOfB = sortOrder.indexOf(b);
				if (indexOfA < indexOfB) {
					return -1;
				}

				if (indexOfA > indexOfB) {
					return 1;
				}

				return 0;
			});
		const newGroups = [...groupsToSort, ...getAllUniqueGroups(groups).map(getIdForGroup)];
		newGroups
			.filter((v, i) => newGroups.indexOf(v) === i)
			.forEach((gr) => {
				const group = getAllUniqueGroups(groups).find((g) => getIdForGroup(g) === gr);
				if (!group) return;

				if (props.selectedGroups.includes(getIdForGroup(group))) {
					newCheckedGroups.push({
						id: getIdForGroup(group),
						checked: true,
					} as ReportGroup);
				} else {
					newCheckedGroups.push({
						id: getIdForGroup(group),
						checked: false,
					} as ReportGroup);
				}
			});
		return newCheckedGroups;
	};

	const newCheckedGroups = createSortableGroups();

	const [checkedGroups, setCheckedGroups] = useState<ReportGroup[]>([...newCheckedGroups]);

	useEffect(() => {
		setCheckedGroups(createSortableGroups());
	}, [props.selectedGroups, props.groups]);

	const checkGroup = (group: ReportGroup, index: number) => {
		if (checkedGroups.filter((f) => f.checked).length >= 6 && !group.checked) return;
		group.checked = !group.checked;
		const items = [...checkedGroups];
		items[index] = group;
		setCheckedGroups(items);
		props.onChangeGroups(
			items.filter((f) => f.checked).map((f) => f.id),
			false
		);
	};

	const updateCheckedGroups = (checkedGroups: ReportGroup[], initialRender?: boolean) => {
		if (!isSortingRef.current) return;

		isSortingRef.current = false;
		setCheckedGroups(checkedGroups);
		// setGroups(checkedGroups.map(f => f.id));
		props.onChangeGroups(
			checkedGroups.filter((f) => f.checked).map((f) => f.id),
			Boolean(initialRender)
		);
	};

	const drawName = (g: ReportGroup) => {
		const group = getAllUniqueGroups(groups).find((f) => getIdForGroup(f) === g.id);
		let name = group?.name;
		if (group?.resources && group?.resources[0]?.name) name += ` (${group?.resources[0]?.name})`;
		return name;
	};

	const renderGroups = useMemo(() => {
		const MAX_AMOUNT_TO_DISPLAY = 15;
		const groupsToDisplay = showMore
			? checkedGroups
			: checkedGroups.filter((_, index) => index < MAX_AMOUNT_TO_DISPLAY);
		const remainingAmount =
			checkedGroups.length - checkedGroups.filter((_, index) => index < MAX_AMOUNT_TO_DISPLAY).length;

		if (groupsToDisplay.length === 0) return <></>;

		return (
			<ReactSortable
				className="sortable-groups"
				list={groupsToDisplay}
				delayOnTouchOnly
				delay={200}
				onUpdate={() => (isSortingRef.current = true)}
				setList={(checkedGroups) => updateCheckedGroups(checkedGroups, true)}
				draggable=".group-button"
				forceFallback
			>
				<>
					{groupsToDisplay.map((group, index) => (
						<Button
							className="group-button"
							id={`aggregate_${group.id}`}
							key={group.id}
							onClick={() => checkGroup(group, index)}
							variant={`${group.checked ? "outline-primary" : "outline-dark"}`}
						>
							{
								<div className="d-flex align-items-center">
									<SortableHandleSvg className="me-1" />
									{drawName(group)}
								</div>
							}
						</Button>
					))}
					{remainingAmount > 0 && (
						<Button
							variant="light"
							onClick={() => setShowMore((prevState) => !prevState)}
							className="me-2"
							id="show-more-btn"
						>
							<div className="d-flex align-items-center">
								{showMore
									? `${t("lib:common.action.less")} -${remainingAmount}`
									: `${t("lib:common.action.more")} +${remainingAmount}`}
								{showMore ? (
									<DropdownSVG style={{ transform: "rotate(180deg)", marginLeft: 8 }} />
								) : (
									<DropdownSVG className="ms-2" />
								)}
							</div>
						</Button>
					)}
				</>
			</ReactSortable>
		);
	}, [checkedGroups, showMore, updateCheckedGroups, checkGroup, drawName]);

	return renderGroups;
};

export default SortableGroups;
