import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormatDate, { FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_SEPARATOR } from "go-list/core/components/Filter/services";
import { apiOrganization } from "../../../../../../../../../../../../services/Api/Organization/apiOrganization";
import { PosReporCheckBeforeDeletionApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { DateRange } from "../../services/types";
import { handleFormChange } from "./POSReportDeletion";
import { ClearSaleFormProps, SaleNonPermanentDeletionType } from "./services/types";

interface Props {
	form: UseFormReturn<ClearSaleFormProps>;
	posReportDetails?: PosReporCheckBeforeDeletionApi;
	onSetPosReportDetails: (posReportDetails: PosReporCheckBeforeDeletionApi | undefined) => void;
	selectedDeletionRange?: string | DateRange;
	onSetSelectedDeletionRange: (currentReportUid: string | DateRange | undefined) => void;
}

const ClearSaleByPOSReport = ({
	form,
	onSetPosReportDetails,
	selectedDeletionRange,
	onSetSelectedDeletionRange,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
		resetField,
	} = form;

	useEffect(() => {
		if (selectedDeletionRange === undefined) resetField(SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION);
	}, [selectedDeletionRange, resetField]);

	const getDropdownValues = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = { ...params, f: `query|c=${search}${FILTER_SEPARATOR}parent_uid|u` };
		return apiOrganization.getPosReportsSearchSelect(search, newParams, { cancelToken: options?.token }, true);
	};

	return (
		<div className="pt-10">
			<FormSelectGroup
				errors={errors}
				control={control}
				label={t("common.word.pos_report")}
				name={SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION}
				loadOptions={getDropdownValues}
				onChange={(obj) => handleFormChange(obj, onSetPosReportDetails, onSetSelectedDeletionRange)}
				isMulti
				getOptionLabel={(option) =>
					`${t(`enums.pos_reports.type.${option.type}`)} #${option.label} (${
						option.opened_at && option.closed_at
							? FormatDateToDateHoursRange(option.opened_at, option.closed_at)
							: FormatDate(option.opened_at)
					})`
				}
				getOptionValue={(option) => option.label}
				data-testid={SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION}
			/>
		</div>
	);
};

export default ClearSaleByPOSReport;
