import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { apiSecurity } from "go-security/services/Api/api";
import { OrganizationRoleApi } from "go-security/services/Api/typesSecurity";

interface Props {
	role: OrganizationRoleApi;
	onHide: () => void;
	handleSave: (role: OrganizationRoleApi) => void;
	handleRemove: (role: OrganizationRoleApi) => void;
}

const SecurityRoleModalFormComponent = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<OrganizationRoleApi>({
		criteriaMode: "all",
		defaultValues: props.role,
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setError,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();

	const onRemove = async () => {
		await confirmation({ title: t("lib:confirmation.title"), message: t("lib:confirmation.message.remove") });
		apiSecurity
			.organization()
			.removeRole(props.role.uid)
			.then(() => {
				addSuccessFlash(t("lib:common.flash.removed"));
				props.handleRemove(props.role);
			})
			.catch((err: Error) => {
				handleError.alert(err, addFlash);
			});
	};

	const onSubmit = handleSubmit(async (data: OrganizationRoleApi) => {
		setLoading(true);
		data.uid = props.role.uid;
		try {
			if (data.uid) {
				data.permissions = props.role.permissions;
				const res = await apiSecurity.organization().updateRole(data.uid, data);
				props.handleSave(res.data.data);
				addSuccessFlash(t("lib:common.flash.saved"));
			} else {
				const res = await apiSecurity.organization().createRole(data);
				data.permissions = [];
				props.handleSave(res.data.data);
				addSuccessFlash(t("lib:common.flash.saved"));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={!!props.role} onHide={props.onHide}>
			<FormDirty formState={formState} key="organization-role-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					{props.role.uid ? (
						<>
							{t("lib:go_component.permission.roles.header.edit")} "{props.role.name}"
						</>
					) : (
						<>{t("lib:go_component.permission.roles.header.new")}</>
					)}
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("lib:go_component.permission.roles.form.name.label")}
						register={register}
						name="name"
						errors={errors}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant={"danger"} type={"button"} onClick={onRemove}>
						{t("lib:common.action.remove")}
					</Button>
					<Button variant="light" onClick={props.onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default SecurityRoleModalFormComponent;
