import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { InvoiceDetailsApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	details: InvoiceDetailsApi;
}

const InvoiceDetailsForm = (props: Props): JSX.Element => {
	const form = useForm<InvoiceDetailsApi>({
		criteriaMode: "all",
		defaultValues: props.details,
	});
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const [newAvatar, setNewAvatar] = useState<string | undefined>(props.details.logo_link?.default_link);
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		reset,
		setError,
		formState,
		control,
		setValue,
		watch,
	} = form;
	const { t } = useTranslation();
	const onSubmit = handleSubmit(async (data: InvoiceDetailsApi) => {
		setLoading(true);
		if (getValues("logo.image_data") || !newAvatar) {
			data.logo_link = undefined;
		}

		try {
			const res = await api.organization().updateInvoiceDetails(data);
			reset(res);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const handleImageChange = (imageBase: string | undefined) => {
		setNewAvatar(imageBase);
	};

	const numerationTypes = [
		{ value: "NUMBER_YEAR", label: t("enums.invoices.numeration.NUMBER") },
		{ value: "NUMBER_MONTH_YEAR", label: t("enums.invoices.numeration.NUMBER_MONTH_YEAR") },
	];

	return (
		<FormDirty loading={loading} formState={formState} onSubmit={onSubmit}>
			<fieldset className="form-group no-gutters">
				<div className={"row"}>
					<div className={"col-md-10"}>
						<div className={"row row-cols-md-2"}>
							<div className={"col-md-6"}>
								<FormSelectGroup
									label={t("modules.invoice_details.field.numeration_type.title")}
									name="numeration_type"
									defaultValue={props.details.numeration_type}
									errors={errors}
									options={numerationTypes}
									control={control}
									data-testid="numeration_type"
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.place_of_issue.title")}
									register={register}
									name="place_of_issue"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.signed_by.title")}
									register={register}
									name="signed_by"
									errors={errors}
								/>
							</div>
							<div className=" d-flex align-items-end mb-1">
								<FormCheck
									name="signed_by_logged_user"
									register={register}
									errors={errors}
									type="switch"
									label={t("modules.invoice_details.field.signed_by_logged_user.title")}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.default_comment.title")}
									register={register}
									name="default_comment"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.invoice_prefix.title")}
									register={register}
									name="prefix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.invoice_suffix.title")}
									register={register}
									name="suffix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.correction_prefix.title")}
									register={register}
									name="correction_prefix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.correction_suffix.title")}
									register={register}
									name="correction_suffix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.proforma_prefix.title")}
									register={register}
									name="proforma_prefix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.proforma_suffix.title")}
									register={register}
									name="proforma_suffix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.advance_prefix.title")}
									register={register}
									name="advance_prefix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormInput
									label={t("modules.invoice_details.field.advance_suffix.title")}
									register={register}
									name="advance_suffix"
									errors={errors}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormNumberInput
									control={control}
									errors={errors}
									decimalScale={1}
									suffix={""}
									name={"first_invoice_number"}
									label={t("modules.invoice_details.field.first_invoice_number.title")}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormNumberInput
									control={control}
									errors={errors}
									decimalScale={1}
									suffix={""}
									name={"first_proforma_number"}
									label={t("modules.invoice_details.field.first_proforma_number.title")}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormNumberInput
									control={control}
									errors={errors}
									decimalScale={1}
									suffix={""}
									name={"first_advance_number"}
									label={t("modules.invoice_details.field.first_advance_number.title")}
								/>
							</div>
							<div className={"col-md-6"}>
								<FormNumberInput
									control={control}
									errors={errors}
									decimalScale={1}
									suffix={""}
									name={"first_correction_number"}
									label={t("modules.invoice_details.field.first_correction_number.title")}
								/>
							</div>
							<div className=" d-flex align-items-end mb-1">
								<FormCheck
									name="exclude_tip_from_grouping"
									register={register}
									errors={errors}
									type="switch"
									label={t("modules.invoice_details.field.exclude_tip_from_grouping.title")}
								/>
							</div>
							<div className=" d-flex align-items-end mb-1">
								<FormCheck
									name="print_payment_due"
									register={register}
									errors={errors}
									type="switch"
									label={t("modules.invoice_details.field.print_payment_due.title")}
								/>
							</div>
							<div className=" d-flex align-items-end mb-1">
								<FormCheck
									name="separate_advance_correction_numeration"
									register={register}
									errors={errors}
									type="switch"
									label={t(
										"modules.invoice_details.field.separate_advance_correction_numeration.title"
									)}
								/>
							</div>
							{watch("separate_advance_correction_numeration") && (
								<>
									<div className={"col-md-6"}>
										<FormNumberInput
											control={control}
											errors={errors}
											decimalScale={1}
											suffix={""}
											name={"first_advance_correction_number"}
											label={t(
												"modules.invoice_details.field.first_advance_correction_number.title"
											)}
										/>
									</div>
									<div className={"col-md-6"}>
										<FormInput
											label={t("modules.invoice_details.field.advance_correction_prefix.title")}
											register={register}
											name="advance_correction_prefix"
											errors={errors}
										/>
									</div>
									<div className={"col-md-6"}>
										<FormInput
											label={t("modules.invoice_details.field.advance_correction_suffix.title")}
											register={register}
											name="advance_correction_suffix"
											errors={errors}
										/>
									</div>
								</>
							)}
						</div>
					</div>
					<div className={"col-md-2"}>
						<ImageColorForm
							form={form}
							handleSave={handleImageChange}
							prefix="logo"
							mode="IMAGE"
							data={props.details}
							resourceLink={props.details.logo_link}
							resourceData={props.details.logo}
						/>
					</div>
				</div>
			</fieldset>
		</FormDirty>
	);
};
export default InvoiceDetailsForm;
