import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkSVG } from "../../../../../../../../../images/svg/bills/link.svg";
import { OrderExternalLinkApi } from "../../../../../../../../../services/Api/Organization/types";
import { RenderOrderSourceLogo } from "../../../../../../../../../utils/orders/RenderOrderSourceLogo";

interface Props {
	externalLinks: OrderExternalLinkApi[];
}

const ExternalLinksCard: FC<Props> = ({ externalLinks }) => {
	const { t } = useTranslation();

	return (
		<Card className="order-external-links-card">
			<Card.Body>
				<h5>{t("modules.order.field.external_links.title")}</h5>
				{externalLinks.map((link, index) => (
					<div
						className="card-body-item align-items-center justify-content-between"
						key={`external-link-${index}`}
					>
						<div className="d-flex align-items-center">
							<RenderOrderSourceLogo source={link?.app_provider} />
							<div className="item-info">
								<div className="d-flex flex-column">
									<a href={link.external_link}>{t(`enums.apps.providers.${link.app_provider}`)}</a>
									<small className="text-muted font-weight-bold mt-n1">#{link.external_id}</small>
								</div>
							</div>
						</div>
						<a href={link.external_link}>
							<LinkSVG className="icon" />
						</a>
					</div>
				))}
			</Card.Body>
		</Card>
	);
};

export default ExternalLinksCard;
