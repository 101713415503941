import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmptyList from "go-core/components/EmptyList";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { InvoiceApi, InvoiceItemApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	invoice: InvoiceApi;
}

const AdvanceItemsTable: FC<Props> = ({ invoice }) => {
	const { t } = useTranslation();
	const nestedCellStyles = {
		borderTop: "none",
		verticalAlign: "top",
		paddingTop: 0,
		paddingBottom: "20px",
	};

	const labelStyles = {
		fontWeight: 500,
		fontSize: "12px",
		color: "#6C757D",
		marginBottom: "16px",
		maxWidth: "60px",
	};

	const verticalTop = {
		verticalAlign: "top",
		paddingTop: "24px",
	};

	const onCalcPriceNet = (amount: number, quantity: number, taxAmount: number, type?: string) => {
		if (type === "GROSS") {
			return (amount * quantity) / (1 + taxAmount / 100);
		}
		return amount * quantity;
	};

	const onCalcPriceGross = (amount: number, quantity: number, taxAmount: number, type?: string) => {
		if (type === "GROSS") {
			return amount * quantity;
		}
		return amount * quantity * (1 + taxAmount / 100);
	};

	const getGroupedItemsByTaxAmount = () => {
		let groupedItems: Record<string, any>[] = [];
		invoice.order_items.forEach((item) => {
			if (!groupedItems[item.tax_id]) groupedItems[item.tax_id] = [];
			groupedItems[item.tax_id].push(item);
		});
		groupedItems = groupedItems.filter((g) => g.length > 0) || [];
		const result: Record<string, any>[] = [];
		const { type } = invoice;

		groupedItems.forEach((groupedItemArr) => {
			const currency = groupedItemArr[0].price_sum.currency;
			const taxAmount = groupedItemArr[0].tax.amount;
			const priceNet = groupedItemArr
				.map((i: Record<string, any>) => onCalcPriceNet(i.price.amount, i.quantity, taxAmount, type))
				.reduce((acc: number, cur: number) => acc + cur, 0);
			const priceGross = groupedItemArr
				.map((i: Record<string, any>) => onCalcPriceGross(i.price.amount, i.quantity, taxAmount, type))
				.reduce((acc: number, cur: number) => acc + cur, 0);
			const priceTax = priceGross - priceNet;

			result.push({
				price_sum_net: { amount: priceNet, currency },
				price_sum_gross: { amount: priceGross, currency },
				price_tax: { amount: priceTax, currency },
				tax: groupedItemArr[0].tax,
			});
		});
		return result.sort((a, b) => {
			if (a.tax.amount < b.tax.amount) return -1;
			if (b.tax.amount < a.tax.amount) return 1;
			return 0;
		});
	};

	const getSummary = () => {
		const items = getGroupedItemsByTaxAmount();
		const currency = items[0].price_sum_net.currency;
		const summary: Record<string, any>[] = [];
		const total_sum_net = items.map((item) => item.price_sum_net.amount).reduce((acc, cur) => acc + cur) || 0;
		const total_sum_gross = items.map((item) => item.price_sum_gross.amount).reduce((acc, cur) => acc + cur) || 0;
		const total_sum_tax = items.map((item) => item.price_tax.amount).reduce((acc, cur) => acc + cur) || 0;
		summary.push({
			total_sum_net: { amount: total_sum_net, currency },
			total_sum_gross: { amount: total_sum_gross, currency },
			total_sum_tax: { amount: total_sum_tax, currency },
		});
		return summary;
	};

	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.invoice.field.orders.title")}</h5>
				{invoice.order_items && invoice.order_items.length > 0 ? (
					<div className={"table-responsive"}>
						<table className={"table table-preview"}>
							<thead>
								<tr>
									<th className={"w-40"}>{t("modules.invoice.field.name.title")}</th>
									<th>{t("modules.invoice.field.pkwiu.title")}</th>
									<th>{t("modules.invoice.field.gtu.title")}</th>
									<th style={{ width: "9%" }}>
										{invoice.type === "GROSS"
											? t("modules.invoice.field.unit_price_gross.title")
											: t("modules.invoice.field.unit_price_net.title")}
									</th>
									<th className={"w-1"}>{t("modules.invoice.field.tax_value.title")}</th>
									<th>{t("common.word.quantity")}</th>
									<th>{t("modules.invoice.field.measure.title")}</th>
									<th className={"w-1"} />
									<th className={"w-1"}>
										{invoice.type === "GROSS" ? (
											<>{t("modules.invoice.field.total_price_gross.title")}</>
										) : (
											<>{t("modules.invoice.field.total_price_net.title")}</>
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{invoice.order_items.map((item, index) => {
									return (
										<tr key={index}>
											<td>{item.name}</td>
											<td>{item.pkwiu}</td>
											<td>{item.gtu}</td>
											<td className={"nowrap"}>{FormatMoney(item.price)}</td>
											<td>{item.tax?.amount}%</td>
											<td>{item.quantity}</td>
											<td>{item.volume}</td>
											<td />
											<td className={"nowrap"}>{FormatMoney(item.price_sum)}</td>
										</tr>
									);
								})}
								<tr className={"summary"}>
									<td colSpan={3} style={verticalTop}>
										<div className={"d-flex flex-column"}>
											<span style={{ marginBottom: "30px" }}>
												<strong>{t("common.word.summary", { ns: "lib" })}</strong>
											</span>
											<span>{t("modules.invoice.field.summary_taxes.title")}</span>
										</div>
									</td>
									<td style={verticalTop}>
										<div className={"d-flex flex-column"}>
											<span style={labelStyles}>
												{t("modules.invoice.field.total_price_net.title")}
											</span>
											{getGroupedItemsByTaxAmount().map((item, index) => {
												return (
													<div key={index} className={"mb-1 nowrap"}>
														{FormatMoney(item.price_sum_net)}
													</div>
												);
											})}
										</div>
									</td>
									<td style={verticalTop}>
										<span style={labelStyles}>{t("modules.invoice.field.tax_value.title")}</span>
										{getGroupedItemsByTaxAmount().map((item, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{item.tax.amount}%
												</div>
											);
										})}
									</td>
									<td style={verticalTop} className={"w-1"}>
										<span style={{ ...labelStyles, maxWidth: "40px" }}>
											{t("modules.invoice.field.tax_amount.title")}
										</span>
										{getGroupedItemsByTaxAmount().map((item, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{FormatMoney(item.price_tax)}
												</div>
											);
										})}
									</td>
									<td colSpan={2} />
									<td style={verticalTop}>
										<span style={{ ...labelStyles, maxWidth: "100%", marginLeft: "5px" }}>
											{t("modules.invoice.field.total_price_gross.title")}
										</span>
										{getGroupedItemsByTaxAmount().map((item, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{FormatMoney(item.price_sum_gross)}
												</div>
											);
										})}
									</td>
								</tr>
								<tr>
									<td className={"nowrap"} style={nestedCellStyles} colSpan={3}>
										<strong>{t("common.word.altogether")}</strong>
									</td>
									<td className={"nowrap"} style={nestedCellStyles}>
										<strong>{FormatMoney(getSummary()[0].total_sum_net)}</strong>
									</td>
									<td className={"nowrap"} style={nestedCellStyles} />
									<td className={"nowrap"} style={nestedCellStyles}>
										<strong>{FormatMoney(getSummary()[0].total_sum_tax)}</strong>
									</td>
									<td colSpan={2} style={nestedCellStyles} />
									<td className={"nowrap"} style={nestedCellStyles}>
										<strong>{FormatMoney(getSummary()[0].total_sum_gross)}</strong>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				) : (
					<EmptyList description={t("modules.invoice.field.empty.title")} />
				)}
			</Card.Body>
		</Card>
	);
};
export default AdvanceItemsTable;
