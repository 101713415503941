import { useMemo } from "react";
import { TooltipItem } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ReportApi } from "go-report/core/services/types";
import { ChartType } from "../../services/charts/types";
import { DashboardReportType } from "../../services/types";
import { getAggregateName } from "../../utils/dashboardRenderUtils";
import { useDashboardReportAggregate } from "../../utils/useDashboardReportAggregate";

interface Props {
	data: ReportApi;
	type: ChartType;
	reportType: DashboardReportType;
}

const HorizontalBar = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const { data, reportType } = props;
	const { getSubReportFilterCondition, getAggregateValue, reportAggregateType } =
		useDashboardReportAggregate(reportType);
	const filteredSubReport = data.reports[0].sub_report.filter(getSubReportFilterCondition());
	const labels = filteredSubReport.map((x) => getAggregateName(x, t));
	const values = filteredSubReport.map((subReport) => getAggregateValue(subReport));

	const chartConfig = useMemo(
		() => ({
			responsive: true,
			indexAxis: "y" as const,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					callbacks: {
						label: (ctx: TooltipItem<"bar">) => {
							if (reportAggregateType !== "transaction_count") ctx.formattedValue += " PLN";
							return ctx.formattedValue;
						},
					},
				},
			},
		}),
		[reportAggregateType]
	);

	const renderChart = useMemo(() => {
		const data = {
			labels,
			datasets: [
				{
					data: values,
					backgroundColor: "#1A86E0",
					barPercentage: 0.6,
				},
			],
		};
		return <Bar options={chartConfig} data={data} />;
	}, [data, chartConfig, reportAggregateType]);

	return <>{renderChart}</>;
};
export default HorizontalBar;
