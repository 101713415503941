import React, { ReactElement, createRef, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import EmptyList from "go-core/components/EmptyList";
import { Loading } from "go-core/components/Loading";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ListParamsType } from "../../../list/services/types";
import { ReactComponent as DropdownSVG } from "../../images/dropdown.svg";
import { ListConfigColumn } from "../Column/services/types";
import { ReactComponent as SortDownSVG } from "./../../images/sort-down.svg";
import { ReactComponent as SortUpDownSVG } from "./../../images/sort-up-down.svg";
import { ReactComponent as SortUpSVG } from "./../../images/sort-up.svg";
import { MultiActionsBar, SingleActionsButton } from "./components/actions";
import { useDynamicTableHeight, useStickyColumns } from "./hooks";
import { GroupByConfig, ListConfigAction, ListConfigMultipleAction } from "./services/types";
import { getColumnsToDisplay, getItemStatus, getNewItemSortingColumns, getSortedColumns } from "./utils";

interface ListTableProps {
	data: Array<any>;
	columns: ListConfigColumn[];
	actions?: ListConfigAction[];
	loading?: boolean;
	multipleActions?: ListConfigMultipleAction[];
	selectedColumns?: Array<string>;
	sortedColumns?: Array<string>;
	selectedSorts?: Array<string>;
	getStatus?: (dataItem: any) => "ENABLED" | "DISABLED" | "WARNING";
	onSort: (sort: string[]) => void;
	groupBy?: GroupByConfig;
	nested_config?: Record<string, any>;
	customTableRow?: ReactElement;
	selectedSegment?: string;
	numberOfActiveStickyColumns?: number;
	totalItemsCount?: number;
	params: ListParamsType;
	canManageMultiActionsForAllItems?: boolean;
	shouldFilterAvailableMultipleActions?: boolean;
	// the shouldFilterAvailableMultipleActions prop should be removed after configs in all components that use ListTable are updated (singleActions should contain all the available actions)
	doesIdColumnRedirectToPreviewPage?: boolean;
	// the doesIdColumnRedirectToPreviewPage prop should be removed after configs in all components that use ListTable are updated (render function of the id column should redirect on click to the preview page of the item)
}

const ListTable = (props: ListTableProps): JSX.Element => {
	const { t } = useTranslation();
	const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
	const [areAllSelected, setAreAllSelected] = useState(false);
	const [areAllVisibleSelected, setAreAllVisibleSelected] = useState(false);
	const tableRef = useRef<HTMLTableElement>(null);
	const mainDivRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const lastColumnRef = useRef<HTMLTableCellElement>(null);
	const tableHeaderRowRef = useRef<HTMLTableRowElement>(null);
	const singleActions = props.actions;
	const multipleActions = props.multipleActions;
	const columnsToDisplay = getColumnsToDisplay(props.columns, props.selectedColumns);
	const sortedColumnsToDisplay = props.sortedColumns
		? getSortedColumns(columnsToDisplay, props.sortedColumns)
		: columnsToDisplay;
	const sortedColumnsToDisplayRefs = useRef(sortedColumnsToDisplay.map(() => createRef<HTMLTableCellElement>()));
	const shouldShowSummary = sortedColumnsToDisplay.some((column) => column.renderSummary);

	const { isTableHeightOverflowing, mainDivStyles } = useDynamicTableHeight(mainDivRef);

	const { getStickyCellStyles, areStickyColumnsActive, widthOfTheLastCell, StickyColumnsShadows } = useStickyColumns({
		numberOfActiveStickyColumns: props.numberOfActiveStickyColumns,
		numberOfColumns: sortedColumnsToDisplay.length,
		columnsOrder: props.sortedColumns || props.selectedColumns,
		isTableHeightOverflowing,
		columnsRefs: sortedColumnsToDisplayRefs,
		wrapperRef,
		mainDivRef,
		tableRef,
		lastColumnRef,
	});

	useEffect(() => {
		unstable_batchedUpdates(() => {
			setAreAllSelected(false);
			setAreAllVisibleSelected(false);
			setSelectedItemsIds([]);
		});
	}, [props.data, setSelectedItemsIds, setAreAllVisibleSelected, setAreAllSelected]);

	const toggleSelectAllVisible = () => {
		setSelectedItemsIds(areAllVisibleSelected ? [] : props.data.map((dataItem) => dataItem.id));
		setAreAllVisibleSelected(!areAllVisibleSelected);
		if (!areAllVisibleSelected) {
			window.dispatchEvent(new Event("RequestTotalItemsCountEvent"));
		}
	};

	const deselectAll = () => {
		setSelectedItemsIds([]);
		setAreAllVisibleSelected(false);
		setAreAllSelected(false);
	};

	const onChangeSelected = (itemId: string) => {
		setSelectedItemsIds((ids) => (ids.includes(itemId) ? ids.filter((item) => item !== itemId) : [...ids, itemId]));
		if (selectedItemsIds.length === 0) {
			window.dispatchEvent(new Event("RequestTotalItemsCountEvent"));
		}
	};

	const sortItemsByColumn = (columnId: string) => {
		const sortingColumnsIds = getNewItemSortingColumns(columnId, props.selectedSorts);
		props.onSort(sortingColumnsIds);
	};

	const toggleRow = (dropdownId: string, dataItem: any) => {
		const el = document.getElementById(dropdownId);
		if (el) {
			if (!el.style.transform || el.style.transform === "none") {
				el.style.transform = "rotate(180deg)";
			} else {
				el.style.transform = "none";
			}
		}
		if (props.nested_config) {
			dataItem[`${props.nested_config.field}`]?.forEach((element: any, index: number) => {
				const foundElement = document.getElementById(`toggled[${dataItem.id}].nested[${index}]`);
				if (foundElement) {
					if (foundElement.style.display === "none") {
						foundElement.style.display = "";
					} else {
						foundElement.style.display = "none";
					}
				}
			});
		}
	};

	const isNested = (dataItem: any): boolean => {
		return (
			props.nested_config &&
			dataItem[`${props.nested_config.field}`] &&
			dataItem[`${props.nested_config.field}`].length > 0
		);
	};

	const getStatus = (dataItem: any) => {
		if (props.getStatus) {
			return props.getStatus(dataItem);
		}

		if (dataItem.status) {
			return getItemStatus(dataItem.status);
		}
	};

	return (
		<div className="table-wrapper" ref={wrapperRef}>
			<StickyColumnsShadows />
			<div className="table-list" ref={mainDivRef} style={mainDivStyles}>
				{props.data.length === 0 ? (
					<>
						{!props.loading ? (
							<EmptyList
								title={t("lib:go_list.empty_list.title")}
								description={t("lib:go_list.empty_list.description")}
							/>
						) : (
							<div className="list-loading">
								<Loading />
							</div>
						)}
					</>
				) : (
					<>
						<Table ref={tableRef}>
							<thead>
								<tr ref={tableHeaderRowRef}>
									{multipleActions && multipleActions.length > 0 && (
										<th
											style={{
												width: "1%",
												...getStickyCellStyles(-1),
											}}
											ref={sortedColumnsToDisplayRefs.current[0]}
										>
											<input
												disabled={areAllSelected}
												type="checkbox"
												checked={areAllSelected || areAllVisibleSelected}
												onChange={toggleSelectAllVisible}
											/>
										</th>
									)}
									{sortedColumnsToDisplay.map((column, i) => {
										let sortIcons;
										if (!column.disableSorting) {
											if (props.selectedSorts?.includes(column.id)) sortIcons = <SortUpSVG />;
											else if (props.selectedSorts?.includes(`-${column.id}`))
												sortIcons = <SortDownSVG />;
											else
												sortIcons = (
													<div className="ms-1">
														<SortUpDownSVG />
													</div>
												);
										}
										const tableHeaderContent = sortIcons ? (
											<div className="d-inline-flex">
												{column.name}
												{column.headerNameAfter && column.headerNameAfter()}
												{sortIcons}
											</div>
										) : (
											<>
												{column.name}
												{column.headerNameAfter && column.headerNameAfter()}
											</>
										);
										return (
											<th
												key={column.id}
												style={{
													...getStickyCellStyles(i),
													...column?.styleOverride,
													...column?.headerStyleOverride,
												}}
												onClick={
													column.disableSorting
														? undefined
														: () => sortItemsByColumn(column.id)
												}
												ref={sortedColumnsToDisplayRefs.current[i + 1]}
											>
												{tableHeaderContent}
											</th>
										);
									})}
									{singleActions && singleActions.length > 0 && (
										<th
											ref={lastColumnRef}
											className={`last-empty-cell ${
												areStickyColumnsActive ? "sticky-last-empty-cell" : ""
											}`}
										/>
									)}
								</tr>
							</thead>
							{!props.loading && (
								<tbody>
									{multipleActions &&
										selectedItemsIds.length > 0 &&
										props.data.length > 0 &&
										mainDivRef.current && (
											<MultiActionsBar
												isButtonSticky={areStickyColumnsActive}
												numberOfColumns={sortedColumnsToDisplay.length}
												mainDivRef={mainDivRef}
												multiActions={multipleActions}
												singleActions={singleActions}
												data={props.data}
												selectedItems={selectedItemsIds}
												areAllSelected={areAllSelected}
												setAreAllSelected={setAreAllSelected}
												allItemsCount={props.totalItemsCount}
												groupBy={props.groupBy}
												isNested={isNested}
												nestedConfig={props.nested_config}
												params={props.params}
												canManageMultiActionsForAllItems={
													props.canManageMultiActionsForAllItems
												}
												shouldFilterAvailableMultipleActions={
													props.shouldFilterAvailableMultipleActions
												}
												clearSelected={deselectAll}
												widthOfTheLastCell={widthOfTheLastCell}
												tableHeaderRowRef={tableHeaderRowRef}
											/>
										)}
									{props.groupBy ? (
										<>
											{props.data.map((obj) => {
												const styleOverride = { ...getStickyCellStyles(0) };
												return (
													<React.Fragment key={obj.name}>
														<tr className="grouped-table-row" key={obj.name}>
															<td
																colSpan={
																	areStickyColumnsActive
																		? 0
																		: sortedColumnsToDisplay.length + 1
																}
																style={styleOverride}
															>
																{obj.name}
															</td>
														</tr>
														{obj.values.map((dataItem: any) => {
															const itemActions =
																singleActions !== undefined
																	? singleActions.filter((action) => {
																			return (
																				action.visible === undefined ||
																				action.visible(dataItem)
																			);
																	  })
																	: undefined;
															return (
																<tr key={dataItem.id}>
																	{
																		<>
																			{sortedColumnsToDisplay.map(
																				(column, index) => {
																					let overrideStyles = {
																						...column?.styleOverride,
																						...getStickyCellStyles(index),
																					};
																					if (column?.cellStyleOverride) {
																						overrideStyles = {
																							...overrideStyles,
																							...column?.cellStyleOverride(
																								dataItem
																							),
																						};
																					}

																					const value = dataItem[column.id];
																					if (column.render) {
																						return (
																							<td
																								id={`${column.id}[${dataItem.id}]`}
																								key={column.id}
																								style={overrideStyles}
																							>
																								{column.render(
																									dataItem
																								)}
																							</td>
																						);
																					}
																					if (typeof value === "object") {
																						return (
																							<td key={column.id}>
																								PROBLEM: OBJECT
																							</td>
																						);
																					}
																					return (
																						<td
																							id={`${column.id}[${dataItem.id}]`}
																							key={column.id}
																							style={overrideStyles}
																						>
																							{value}
																						</td>
																					);
																				}
																			)}
																			<SingleActionsButton
																				actions={itemActions}
																				item={dataItem}
																				mainDivRef={mainDivRef}
																				isButtonSticky={areStickyColumnsActive}
																				status={getStatus(dataItem)}
																				doesIdColumnRedirectToPreviewPage={
																					props.doesIdColumnRedirectToPreviewPage
																				}
																				params={props.params}
																			/>
																		</>
																	}
																</tr>
															);
														})}
													</React.Fragment>
												);
											})}
										</>
									) : (
										<>
											{props.data.map((dataItem) => {
												const itemActions =
													singleActions !== undefined
														? singleActions.filter((action) => {
																return (
																	action.visible === undefined ||
																	action.visible(dataItem)
																);
														  })
														: undefined;
												return (
													<React.Fragment key={dataItem.id}>
														<tr>
															{multipleActions && multipleActions.length > 0 && (
																<td
																	style={{
																		width: "1%",
																		...getStickyCellStyles(-1),
																	}}
																>
																	<input
																		disabled={areAllSelected}
																		type="checkbox"
																		onChange={() => onChangeSelected(dataItem.id)}
																		checked={
																			areAllSelected ||
																			selectedItemsIds.includes(dataItem.id)
																		}
																	/>
																</td>
															)}
															{sortedColumnsToDisplay.map((column, i) => {
																let overrideStyles = column?.cellStyleOverride
																	? {
																			...column?.styleOverride,
																			...column?.cellStyleOverride(dataItem),
																	  }
																	: { ...column?.styleOverride };
																overrideStyles = {
																	...getStickyCellStyles(i),
																	...overrideStyles,
																};

																if (column.id.startsWith("+")) {
																	const key = column.id.substring(1);
																	let value = dataItem.custom_fields?.find(
																		(f: CustomFieldTemplateApi) => f.slug === key
																	)?.value;
																	if (!value) value = "";
																	return (
																		<td
																			id={`${column.id}[${dataItem.id}]`}
																			key={column.id}
																			style={overrideStyles}
																		>
																			{i === 0 && isNested(dataItem) && (
																				<DropdownSVG
																					onClick={() =>
																						toggleRow(
																							`toggle-${dataItem.id}`,
																							dataItem
																						)
																					}
																					style={{ cursor: "pointer" }}
																					id={`toggle-${dataItem.id}`}
																					className={"me-1"}
																				/>
																			)}
																			{value}
																		</td>
																	);
																}
																const value = dataItem[column.id];
																if (column.render) {
																	return (
																		<td
																			className={
																				column.id === "name"
																					? "list-image-name"
																					: ""
																			}
																			id={`${column.id}[${dataItem.id}]`}
																			key={column.id}
																			style={overrideStyles}
																		>
																			{i === 0 && isNested(dataItem) ? (
																				<div className="d-flex align-items-center">
																					<DropdownSVG
																						onClick={() =>
																							toggleRow(
																								`toggle-${dataItem.id}`,
																								dataItem
																							)
																						}
																						style={{
																							cursor: "pointer",
																						}}
																						id={`toggle-${dataItem.id}`}
																						className={"me-1"}
																					/>
																					{column.render(dataItem)}
																				</div>
																			) : (
																				<>{column.render(dataItem)}</>
																			)}
																		</td>
																	);
																}
																if (typeof value === "object") {
																	return (
																		<td key={column.id} style={overrideStyles}>
																			PROBLEM: OBJECT
																		</td>
																	);
																}
																return (
																	<td
																		id={`${column.id}[${dataItem.id}]`}
																		key={column.id}
																		style={overrideStyles}
																	>
																		{i === 0 && isNested(dataItem) && (
																			<DropdownSVG
																				onClick={() =>
																					toggleRow(
																						`toggle-${dataItem.id}`,
																						dataItem
																					)
																				}
																				style={{ cursor: "pointer" }}
																				id={`toggle-${dataItem.id}`}
																				className={"me-1"}
																			/>
																		)}
																		{value}
																	</td>
																);
															})}
															<SingleActionsButton
																actions={itemActions}
																item={dataItem}
																mainDivRef={mainDivRef}
																isButtonSticky={areStickyColumnsActive}
																status={getStatus(dataItem)}
																doesIdColumnRedirectToPreviewPage={
																	props.doesIdColumnRedirectToPreviewPage
																}
																params={props.params}
															/>
														</tr>
														{isNested(dataItem) &&
															dataItem[`${props.nested_config?.field}`].map(
																(item: any, index: number) => {
																	const nestedItemActions =
																		singleActions !== undefined
																			? singleActions.filter((action) => {
																					return (
																						action.visible === undefined ||
																						action.visible(item)
																					);
																			  })
																			: undefined;
																	return (
																		<tr
																			key={item.id}
																			id={`toggled[${dataItem.id}].nested[${index}]`}
																			style={{ display: "none" }}
																		>
																			{sortedColumnsToDisplay.map((column, i) => {
																				let overrideStyles = {
																					...column?.styleOverride,
																					...getStickyCellStyles(i),
																				};
																				if (column?.cellStyleOverride)
																					overrideStyles = {
																						...overrideStyles,
																						...column?.cellStyleOverride(
																							dataItem
																						),
																					};
																				if (column.id.startsWith("+")) {
																					const key = column.id.substring(1);
																					let value = item.custom_fields
																						? item.custom_fields[key]
																						: undefined;
																					if (!value) value = "";
																					return (
																						<td
																							style={
																								i === 0
																									? {
																											paddingLeft:
																												"40px",
																											...overrideStyles,
																									  }
																									: {
																											...overrideStyles,
																									  }
																							}
																							id={`nested_${column.id}[${item.id}]`}
																							key={column.id}
																						>
																							{value}
																						</td>
																					);
																				}
																				const value = item[column.id];
																				if (column.render) {
																					return (
																						<td
																							style={
																								i === 0
																									? {
																											paddingLeft:
																												"40px",
																											...overrideStyles,
																									  }
																									: {
																											...overrideStyles,
																									  }
																							}
																							className={
																								column.id === "name"
																									? "list-image-name"
																									: ""
																							}
																							id={`nested_${column.id}[${item.id}]`}
																							key={column.id}
																						>
																							{column.render(item)}
																						</td>
																					);
																				}
																				if (typeof value === "object") {
																					return (
																						<td
																							style={
																								i === 0
																									? {
																											paddingLeft:
																												"40px",
																											...overrideStyles,
																									  }
																									: {
																											...overrideStyles,
																									  }
																							}
																							key={column.id}
																						>
																							PROBLEM: OBJECT
																						</td>
																					);
																				}
																				return (
																					<td
																						style={
																							i === 0
																								? {
																										paddingLeft:
																											"40px",
																										...overrideStyles,
																								  }
																								: {
																										...overrideStyles,
																								  }
																						}
																						id={`nested_${column.id}[${item.id}]`}
																						key={column.id}
																					>
																						{value}
																					</td>
																				);
																			})}
																			<SingleActionsButton
																				actions={nestedItemActions}
																				item={item}
																				mainDivRef={mainDivRef}
																				isButtonSticky={areStickyColumnsActive}
																				status={getStatus(dataItem)}
																				doesIdColumnRedirectToPreviewPage={
																					props.doesIdColumnRedirectToPreviewPage
																				}
																				params={props.params}
																			/>
																		</tr>
																	);
																}
															)}
													</React.Fragment>
												);
											})}
											{props.customTableRow}
											{shouldShowSummary && (
												<tr>
													{props.canManageMultiActionsForAllItems && <td />}
													{sortedColumnsToDisplay.map((column, index) => {
														if (!column.renderSummary) return <></>;
														return (
															<td
																key={`summary-${column.id}`}
																style={{
																	...getStickyCellStyles(index),
																	textAlign: "right",
																}}
															>
																{column.renderSummary()}
															</td>
														);
													})}
													{props.canManageMultiActionsForAllItems && (
														<td className="sticky-single-action-button-container"></td>
													)}
												</tr>
											)}
										</>
									)}
								</tbody>
							)}
						</Table>
						{props.loading && (
							<div className="list-loading">
								<Loading />
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ListTable;
