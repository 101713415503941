import { ListConfigColumn } from "../components/Column/services/types";
import { FilterCondition, FilterType, ListFilterSource } from "../components/Filter/services/types";

export interface ListConfigField extends ListConfigColumn {
	type?: FilterType;
	// DEPRECATED
	options?: Array<string> | {};
	listOptions?: Array<string> | [] | Record<string, any>;
	extraOptions?: FilterCondition[];
	typeOptions?: FilterCondition[];
	source?: ListFilterSource;
	resources?: Array<Record<string, any>>;
	insertPosition?: CustomFieldInsertPosition;
	filterByListOptionValue?: boolean;
}

export enum CustomFieldInsertPosition {
	ALPHABETICAL = "alphabetical",
	END = "end",
}
