import { ListConfigColumn, UncheckedColumn } from "../services/types";

export const getUncheckedColumns = (
	listStateColumns: ListConfigColumn[],
	segmentColumns: string[]
): UncheckedColumn[] => {
	return listStateColumns.reduce<UncheckedColumn[]>((uncheckedColumns, listStateColumn, index) => {
		if (!segmentColumns.includes(listStateColumn.id))
			return [
				...uncheckedColumns,
				{
					columnName: listStateColumn.id,
					index,
				},
			];
		return uncheckedColumns;
	}, []);
};

export const insertUncheckedColumnsToSegmentColumns = (
	uncheckedColumns: UncheckedColumn[],
	segmentColumns: string[]
): void => {
	uncheckedColumns.forEach((uncheckedColumn) =>
		segmentColumns.splice(uncheckedColumn.index, 0, uncheckedColumn.columnName)
	);
};

export const getSortedColumnsAsObjects = (
	segmentColumns: string[],
	listStateColumns: ListConfigColumn[]
): ListConfigColumn[] => {
	return segmentColumns.reduce<ListConfigColumn[]>((sortedColumns, segmentColumnId) => {
		const listStateColumn = listStateColumns.find((stateColumn) => stateColumn.id === segmentColumnId);

		if (listStateColumn) {
			return [...sortedColumns, listStateColumn];
		}

		return sortedColumns;
	}, []);
};

export const getSortedColumnsWithAppendedCustomFields = (
	customFieldColumns: ListConfigColumn[],
	sortedColumns: ListConfigColumn[]
): ListConfigColumn[] => {
	const newSortedColumns = sortedColumns.filter(
		(column) => !customFieldColumns.find((field) => field.id === column.id)
	);
	newSortedColumns.push(...customFieldColumns);

	return newSortedColumns;
};

export const listColumnSort = (columnsToSort: string[], sortedColumns: string[]): string[] => {
	const sortedNewColumns = sortedColumns
		? [...columnsToSort].sort((a, b) => {
				const indexOfA = sortedColumns.indexOf(a);
				const indexOfB = sortedColumns.indexOf(b);
				return indexOfA === indexOfB ? 0 : indexOfA > indexOfB ? 1 : -1;
		  })
		: columnsToSort;
	return sortedNewColumns;
};

export const didColumnListChange = (
	newColumnsList: ListConfigColumn[],
	currentColumnsList: ListConfigColumn[]
): boolean => {
	return (
		newColumnsList.length !== currentColumnsList.length ||
		newColumnsList.some((column, index) => column.id !== currentColumnsList[index].id)
	);
};

export const updateColumnsState = (
	columnsList: ListConfigColumn[],
	setColumnsState: React.Dispatch<React.SetStateAction<ListConfigColumn[]>>
): void => {
	setColumnsState((state) => {
		if (didColumnListChange(columnsList, state)) {
			return columnsList;
		}

		return state;
	});
};

export const clickOutsideDropdown = (): void => document.body.click();
