import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PdfOrientationType } from "go-core/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import FormatRequestItemStatus from "./components/FormatRequestItemStatus";
import { RequestItemContext } from "./services/context";
import { RequestItemApi } from "./services/types";

interface ListState {
	resource: any;
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

interface Props {
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

const listName = "REQUEST_ITEM";
const resourceType = "REQUEST_ITEM";

const List: FC<ListState> = ({ resource, organization_name, companyName, taxIdNo, pdfOrientation, pdfFontSize }) => {
	const [items, setItems] = useState<RequestItemApi[]>([]);
	const location = useLocation();
	const { t } = useTranslation();
	const segmentContextValue = React.useContext(SegmentContext);
	const requestItemService = React.useContext(RequestItemContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "created_at",
				name: t("lib:common.word.created_at"),
				render: (item: RequestItemApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={item.status === "OPEN" ? `OPEN_BLUE` : item.status}
							to={`${location.pathname}/${item.id}`}
						>
							{FormatDate(item.created_at, undefined, true)}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: RequestItemApi) => {
					return FormatDate(item.created_at, undefined, true);
				},
				type: "date",
			},
			{
				id: "publish_at",
				name: t("lib:go_component.request_item.list.publish_at.title"),
				type: "date",
				render: (item: RequestItemApi) => FormatDate(item.publish_at, undefined, true),
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				render: (option: RequestItemApi) => requestItemService.getTypeTitle(option.type),
			},
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list",
				options: {
					OPEN: t("lib:go_component.request_item.status.OPEN"),
					SUCCESS: t("lib:go_component.request_item.status.SUCCESS"),
					ERROR: t("lib:go_component.request_item.status.ERROR"),
				},
				render: (option: RequestItemApi) => {
					return <FormatRequestItemStatus status={option.status} />;
				},
				renderExport: (option: RequestItemApi) => {
					return t(`lib:go_component.request_item.status.${option.status}`);
				},
			},
			{
				id: "context",
				name: t("lib:go_component.request_item.list.table.context"),
				render: (item: RequestItemApi) => {
					return `${item.context}`;
				},
			},
			{
				id: "sent_at",
				name: t("lib:go_component.request_item.list.table.sent_at"),
				render: (item: RequestItemApi) => {
					return FormatDate(item.sent_at, undefined, true);
				},
				renderExport: (item: RequestItemApi) => {
					return FormatDate(item.sent_at, undefined, true);
				},
				type: "date",
			},
		],
		filters: [
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "search_select",
				source: {
					request: async () => {
						const res = await requestItemService.api().getTypeOptions();
						return res.map((option) => ({
							id: option,
							label: requestItemService.getTypeTitle(option),
						}));
					},
				},
				withoutInfiniteScroll: true,
			},
		],
		actions: [
			{
				name: t("lib:common.action.preview"),
				link: (item: RequestItemApi) => `${location.pathname}/${item.id}`,
			},
		],
		selectedColumns: ["created_at", "publish_at", "sent_at", "type", "context", "status"],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: async (params: Record<string, RequestItemApi> = {}, sourceToken?: CancelTokenSource) => {
			return (await requestItemService.api().all(params, { cancelToken: sourceToken?.token })).data.data;
		},
		exportConfig: {
			title: t("lib:go_component.request_item.list.export_config.title"),
			filename: t("lib:go_component.request_item.list.export_config.filename"),
			organization: `${organization_name}`,
			company: companyName,
			taxIdNo,
			pdfOrientation,
			pdfFontSize,
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};

	return <ListData data={items} config={config} onFetch={setItems} />;
};

const RequestItemsList = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<any>();
	const segmentContextValue = React.useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_component.request_item.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("lib:go_component.request_item.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List
					organization_name={props.organization_name}
					resource={resource}
					taxIdNo={props.taxIdNo}
					companyName={props.companyName}
					pdfFontSize={props.pdfFontSize}
					pdfOrientation={props.pdfOrientation}
				/>
			</Suspense>
		</div>
	);
};
export default RequestItemsList;
