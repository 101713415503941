import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "go-app/components/Header";
import SubmenuAdvancedNavigation from "./components/SubmenuAdvancedNavigation";
import OrganizationSettingsAdvancedCopyPlacePage from "./pages/CopyPlace";
import OrganizationSettingsAdvancedToolsPage from "./pages/Tools";

const OrganizationSettingsAdvancedPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Header title={"Eksport/Import danych"} />
			<SubmenuAdvancedNavigation />
			<Switch>
				<Route path={`${props.match.url}/copy-place`} component={OrganizationSettingsAdvancedCopyPlacePage} />
				<Route path={`${props.match.url}/tools`} component={OrganizationSettingsAdvancedToolsPage} />
				{/*<Route path={props.match.url + "/copy-test-place"}*/}
				{/*       component={OrganizationSettingsAdvancedCopyTestPlacePage}/>*/}
				<Redirect from={props.match.url} exact to={`${props.match.url}/copy-place`} />
			</Switch>
		</div>
	);
};

export default OrganizationSettingsAdvancedPage;
