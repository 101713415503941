import React from "react";
import VirtualTableSearchColumn from "../../../../../../components/VirtualTable/VirtualTableSearchColumn";
import { VirtualTableColumn } from "../../../../../../components/VirtualTable/types";
import {
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";

interface Props {
	visibleDefaultColumns: VirtualTableColumn[];
	maxZindex: number;
	setCategorySearch: (search: string) => void;
	setItemGroupSearch: (search: string) => void;
	setItemFullNameSearch: (search: string) => void;
	setDescriptionSearch: (search: string) => void;
	setPrintingNameSearch: (search: string) => void;
	setNameSearch: (search: string) => void;
	categorySearch: string | undefined;
	itemGroupSearch: string | undefined;
	itemFullNameSearch: string | undefined;
	descriptionSearch: string | undefined;
	nameSearch: string | undefined;
	printingNameSearch: string | undefined;
}
const TranslationsVirtualTableHeaderSearchColumnsRow = ({
	descriptionSearch,
	setNameSearch,
	setDescriptionSearch,
	setPrintingNameSearch,
	categorySearch,
	itemGroupSearch,
	itemFullNameSearch,
	nameSearch,
	setCategorySearch,
	setItemGroupSearch,
	printingNameSearch,
	visibleDefaultColumns,
	maxZindex,
	setItemFullNameSearch,
}: Props) => {
	const handleClearFilter = (columnId: string) => {
		switch (columnId) {
			case "category_name":
				setCategorySearch("");
				break;
			case "item_group_name":
				setItemGroupSearch("");
				break;
			case "item_full_name":
				setItemFullNameSearch("");
				break;
			case "description":
				setDescriptionSearch("");
				break;
			case "printing_name":
				setPrintingNameSearch("");
				break;
			case "name":
				setNameSearch("");
				break;
		}
	};

	const handleSearch = (columnId: string, search: string) => {
		switch (columnId) {
			case "category_name":
				setCategorySearch(search);
				break;
			case "item_group_name":
				setItemGroupSearch(search);
				break;
			case "item_full_name":
				setItemFullNameSearch(search);
				break;
			case "description":
				setDescriptionSearch(search);
				break;
			case "printing_name":
				setPrintingNameSearch(search);
				break;
			case "name":
				setNameSearch(search);
				break;
		}
	};

	const getSearchValue = (columnId: string) => {
		switch (columnId) {
			case "category_name":
				return categorySearch;
			case "item_group_name":
				return itemGroupSearch;
			case "item_full_name":
				return itemFullNameSearch;
			case "description":
				return descriptionSearch;
			case "printing_name":
				return printingNameSearch;
			case "name":
				return nameSearch;
			default:
				return "";
		}
	};

	const drawSearchColumns = () => {
		let prevLeftPosition = 0;
		return visibleDefaultColumns.map((col, index) => {
			const prevColumn = visibleDefaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const isLastColumn = index === visibleDefaultColumns.length - 1;
			const zIndex = maxZindex - index;
			const isHide = isVirtualTableColumnHide(col.id, visibleDefaultColumns);

			return (
				<VirtualTableSearchColumn
					isEmpty={false}
					key={`search-column-${col.id}`}
					clearFilter={() => handleClearFilter(col.id)}
					searchValue={getSearchValue(col.id)}
					search={(value) => handleSearch(col.id, value)}
					isHide={isHide}
					positionLeft={leftPosition}
					isSticky
					lastColumn={isLastColumn}
					zIndex={zIndex}
				/>
			);
		});
	};

	return <>{drawSearchColumns()}</>;
};

export default TranslationsVirtualTableHeaderSearchColumnsRow;
