import { TFunction } from "react-i18next";

export interface AppConfigApi {
	provider: string;
	name: string;
	description: string;
}

export enum TimeOption {
	SECOND = "SECOND",
	HOUR = "HOUR",
	DAY = "DAY",
}

export const getApplications = (t: TFunction): Record<string, any>[] => {
	return [
		{
			provider: "GOPOS",
			name: t("modules.app.field.gopos.title"),
			description: t("modules.app.field.gopos.helptext.description"),
		},
		{
			provider: "LOCATIONS",
			name: t("modules.app.field.locations.title"),
			description: t("modules.app.field.locations.helptext.description"),
		},
		{
			provider: "GOORDER",
			name: t("modules.app.field.goorder.title"),
			description: t("modules.app.field.goorder.helptext.description"),
		},
		{
			provider: "GASTROSUPPLIER",
			name: t("modules.app.field.gastrosupplier.title"),
			description: t("modules.app.field.gastrosupplier.helptext.description"),
		},
		{
			provider: "GOCLIENT",
			name: t("modules.app.field.goclient.title"),
			description: t("modules.app.field.goclient.helptext.description"),
		},
		{
			provider: "GOSTOCK",
			name: t("modules.app.field.gostock.title"),
			description: t("modules.app.field.gostock.helptext.description"),
		},
		{
			provider: "GOTICKET",
			name: t("modules.app.field.goticket.title"),
			description: t("modules.app.field.goticket.helptext.description"),
		},
	] as AppConfigApi[];
};

export const parseApplicationSettings = (settings: Record<string, any>): Record<string, any> => {
	return Object.keys(settings).reduce((acc: Record<string, any>, key: string) => {
		const value = settings[key];
		acc[key] = value;

		if (typeof value === "object" && value?.length >= 0) {
			if (value.some((v: Record<string, any>) => !!v?.slug)) {
				acc[key] = value.map(({ slug }: Record<string, any>) => slug).join(",");
			} else if (value.some((v: Record<string, any>) => !!v?.id)) {
				acc[key] = value.map(({ id }: Record<string, any>) => id).join(",");
			} else {
				acc[key] = value.join(",");
			}
		}
		return acc;
	}, {});
};

const booleanFields = [
	"auto_link_by_name",
	"import_contact_groups",
	"import_products",
	"export_all_contacts",
	"import_availabilities",
	"auto_create_imported_availabilities",
	"update_imported_availabilities",
	"import_menu_price_lists",
	"auto_create_imported_menu_price_lists",
	"update_imported_menu_price_lists",
	"import_items",
	"update_imported_items",
	"import_categories",
	"auto_create_imported_categories",
	"update_imported_categories",
	"import_menus",
	"auto_create_imported_menus",
	"update_imported_menus",
	"import_menu_discounts",
	"auto_create_imported_menu_discounts",
	"update_imported_menu_discounts",
	"import_modifier_groups",
	"auto_create_imported_modifier_groups",
	"update_imported_modifier_groups",
	"import_employees",
	"auto_create_imported_employees",
	"update_imported_employees",
	"import_payment_methods",
	"auto_create_imported_payment_methods",
	"update_imported_payment_methods",
	"import_venue_roles",
	"auto_create_imported_venue_roles",
	"update_imported_venue_roles",
	"delete_data_not_related_to_price_list",
	"external_delivery",
	"auto_update_menu_after_changes",
	"item_multi_level",
	"send_menu_based_on_price_list",
	"send_menu_to_go_hub",
	"legacy_version",
	"import_custom_field_templates",
	"auto_create_imported_custom_field_templates",
	"update_imported_custom_field_templates",
	"import_segments",
	"auto_create_imported_segments",
	"update_imported_segments",
	"auto_create_imported_items",
	"import_printout_templates",
	"auto_create_imported_printout_templates",
	"update_imported_printout_templates",
	"overwrite_all_configurations",
	"export_availabilities",
	"export_company_info",
	"export_store_configuration",
	"export_menu_discounts",
];

export const parseApplicationSettingsValues = (settings: Record<string, string>): Record<string, any> => {
	return Object.keys(settings).reduce((acc: Record<string, any>, key: string) => {
		const value = settings[key];

		if (booleanFields.includes(key)) {
			if (value === "false") acc[key] = false;
			if (value === "true") acc[key] = true;
		}

		return acc;
	}, {});
};

const parseMultiValueToString = (settings: Record<string, any>, field: string): string => {
	if (typeof settings[field] === "object") {
		return settings[field].map((f: string) => f).join(",");
	}
	return settings[field];
};

export const parseStringToList = (
	settings: Record<string, any>,
	config: Record<string, any>,
	settingsField: string,
	configField: string
): Record<string, any>[] => {
	const parsedValue = parseMultiValueToString(settings, settingsField);
	return parsedValue?.split(",")?.map((x: string) => {
		return config[configField as keyof typeof config]?.find(
			(f: Record<string, any>) => (f.slug ? f.slug : Number(f.id)) === (f.slug ? x : Number(x))
		);
	});
};

export const getNumberOfSecondsFromAcceptanceTime = (numberOfTimeUnits?: number, timeUnit?: TimeOption) => {
	if (!numberOfTimeUnits || !timeUnit) {
		return undefined;
	}

	const hourInSeconds = 60 * 60;
	const dayInSeconds = 24 * 60 * 60;

	switch (timeUnit) {
		case TimeOption.SECOND:
			return numberOfTimeUnits;
		case TimeOption.HOUR:
			return numberOfTimeUnits * hourInSeconds;
		case TimeOption.DAY:
			return numberOfTimeUnits * dayInSeconds;
		default:
			return numberOfTimeUnits;
	}
};

export const getAcceptanceTimeFromNumberOfSeconds = (numberOfSeconds?: number) => {
	if (!numberOfSeconds) {
		return undefined;
	}

	const hourInSeconds = 60 * 60;
	const dayInSeconds = 24 * 60 * 60;

	if (numberOfSeconds % dayInSeconds === 0) {
		return {
			numberOfTimeUnits: numberOfSeconds / dayInSeconds,
			timeUnit: TimeOption.DAY,
		};
	}

	if (numberOfSeconds % hourInSeconds === 0) {
		return {
			numberOfTimeUnits: numberOfSeconds / hourInSeconds,
			timeUnit: TimeOption.HOUR,
		};
	}

	return {
		numberOfTimeUnits: numberOfSeconds,
		timeUnit: TimeOption.SECOND,
	};
};

export const getTerminalsWithApiKeys = (
	settingsFromApp: Record<string, any>,
	terminalsFromConfig: Record<string, any>
) => {
	const objectEntries = Object.entries(settingsFromApp);
	const terminalsEntries = objectEntries.filter((objectEntry) => objectEntry.at(0).startsWith("terminal_api_key"));
	const terminalsWithApiKeyObject = terminalsEntries.map((terminal) => {
		const terminalUid = terminal[0].split(" ")[1];
		const TERMINAL_NAME = terminalsFromConfig.find(
			(terminalFromConfig: Record<string, any>) => terminalFromConfig.uid === terminalUid
		)?.name;

		return {
			TERMINAL_KEY: terminal[1],
			TERMINAL_UID: terminalUid,
			TERMINAL_NAME,
		};
	});

	return terminalsWithApiKeyObject;
};

export const convertTerminalsFormDataToDataAcceptableByBackend = (terminals: Record<string, any>) => {
	const convertedTerminalsDataArray = terminals.map((terminalData: Record<string, any>) => {
		return {
			[`TERMINAL_API_KEY ${terminalData.TERMINAL_UID}`]: terminalData.TERMINAL_KEY,
		};
	});

	const convertedTerminalsDataObject = convertedTerminalsDataArray.reduce(
		(objectIncludingTerminalsData: Record<string, any>, terminalData: Record<string, any>) => {
			return { ...objectIncludingTerminalsData, ...terminalData };
		},
		{}
	);

	return convertedTerminalsDataObject;
};

export const getRidOfReceivedTerminalApiKeysFromBackend = (settings: Record<string, any>) => {
	const dataSettingsEntries = Object.entries(settings);
	const terminalApiKeysFilteredOutFromDataSettings = dataSettingsEntries.filter((dataSetting) => {
		return !dataSetting.at(0).startsWith("terminal_api_key");
	});

	const objectWithFilteredTerminalApiKeys = Object.fromEntries(terminalApiKeysFilteredOutFromDataSettings);

	return objectWithFilteredTerminalApiKeys;
};
