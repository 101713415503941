import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Header from "go-app/components/Header";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as LoadingSVG } from "go-core/images/svg/loading.svg";
import { hasPermission } from "go-security/services/organizations/selectors";
import { ReportLastUpdateInfo } from "../../Common/ReportLastUpdateInfo/ReportLastUpdateInfo";
import { DashboardContext } from "../index";
import { DashboardFetchActions } from "../services/data/reducer";
import { DashboardType } from "../services/types";
import useDashboardApi from "../services/useDashboardApi";
import useIsFirstRender from "../utils/useIsFirstRender";
import DashboardAlerts from "./Alerts/DashboardAlerts";
import { DashboardClientsDetailsInformations } from "./ClientsDetails/DashboardClientsDetails";
import DashboardFilters from "./Filter/DashboardFilters";
import DashboardLive from "./Live/DashboardLive";
import DashboardReportCategory from "./Reports/DashboardReportCategory";
import DashboardReportPayment from "./Reports/DashboardReportPayment";
import DashboardReportProduct from "./Reports/DashboardReportProduct";
import DashboardReportSales from "./Reports/DashboardReportSales";
import DashboardReportSalesAdvanced from "./Reports/DashbordReportSalesAdvanced";
import DashboardExtendedTab from "./Tabs/DashboardExtendedTab";
import DashboardGeneralTab from "./Tabs/DashboardGeneralTab";
import DashboardTabs, { tabType } from "./Tabs/DashboardTabs";

const Dashboard = (): JSX.Element => {
	const { t } = useTranslation();
	const { initialData, fetchData, loading, rerenderDashboard } = useDashboardApi();
	const { state, dispatch, dashboardType } = useContext(DashboardContext);
	const isFirstRender = useIsFirstRender();
	const nextDataLoading = rerenderDashboard && loading && !isFirstRender;
	const isMobile = useWindowSize().isMobile;
	let hasVenueReportsShowPermission = useSelector(hasPermission("VENUE_REPORTS_SHOW"));
	if (dashboardType === DashboardType.GROUP_ORGANIZATIONS) hasVenueReportsShowPermission = true;
	const displayEmptyList = !hasVenueReportsShowPermission && dashboardType === DashboardType.ORGANIZATION;
	const isDateRangeChanged = useMemo(() => {
		if (isMobile) {
			return state.filtersConfig.forceApplyDateFilter;
		}
		return state.filtersConfig.selectedFilter.dateRange;
	}, [state.filtersConfig.forceApplyDateFilter, isMobile, state.filtersConfig.selectedFilter.dateRange]);
	const [activeTab, setActiveTab] = useState<tabType>("GENERAL");

	useEffect(() => {
		dispatch({
			type: DashboardFetchActions.fetchAllData,
			payload: {
				data: initialData,
				shouldOverrideExistingData: initialData === undefined,
			},
		});
	}, [initialData, dispatch]);

	useEffect(() => {
		if (isMobile && !isDateRangeChanged) return;

		const controller = new AbortController();
		fetchData(state.filtersConfig, state.chartsConfig, controller);

		return () => {
			controller.abort();
		};
	}, [
		isDateRangeChanged,
		state.filtersConfig.selectedFilter.organizationIds,
		state.filtersConfig.selectedSegment,
		fetchData,
		isMobile,
	]);

	if (loading) {
		if (isFirstRender) {
			return <LoadingContainer />;
		}
	}

	if (displayEmptyList) {
		return (
			<div className="d-flex justify-content-center align-items-center w-100">
				<EmptyList title={t("modules.dashboard.field.lack_of_permissions.title")} />
			</div>
		);
	}

	return (
		<div className={"content"}>
			{!isMobile && (
				<div className="dashboard-header">
					<Header title={t("modules.dashboard.header.title")} />
					<ReportLastUpdateInfo lastUpdateDate={state?.data?.last_update} />
				</div>
			)}
			<MobileActions>
				<ReportLastUpdateInfo lastUpdateDate={state?.data?.last_update} />
			</MobileActions>
			<div className={"dashboard"}>
				<div className={"content-preview"}>
					<DashboardFilters />
					{isMobile && <DashboardTabs setActiveTab={setActiveTab} activeTab={activeTab} />}
					<div className={"row dashboard-content"}>
						{nextDataLoading && <LoadingSVG className={"loading-custom"} style={{ top: "40%" }} />}
						{isMobile ? (
							<>
								<div className="col">
									{activeTab === "GENERAL" ? (
										<DashboardGeneralTab loading={nextDataLoading} />
									) : (
										<DashboardExtendedTab />
									)}
								</div>
							</>
						) : (
							<>
								<div className={"col-md-4"}>
									{dashboardType === DashboardType.ORGANIZATION && <DashboardAlerts />}
									<DashboardClientsDetailsInformations />
									{dashboardType === DashboardType.ORGANIZATION && (
										<DashboardLive loading={nextDataLoading} />
									)}
								</div>
								{hasVenueReportsShowPermission && (
									<div className={"col-md-8"}>
										<div className={"row"}>
											<div className={"col-md-12"}>
												<DashboardReportSalesAdvanced />
											</div>
											<div className={"col-md-12"}>
												<div className={"card-columns"}>
													<DashboardReportPayment />
													<DashboardReportProduct />
													<DashboardReportCategory />
													<DashboardReportSales />
												</div>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
