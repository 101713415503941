import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FILTER_VALUE_SEPARATOR } from "../../services";
import { FilterCondition, ListConfigFilter, ListSelectedFilter } from "../../services/types";

interface Props {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	onChange: (condition: FilterCondition, selectedFilters: string) => void;
}

const ListFilterList = ({ id, filter, selectedFilter, onChange }: Props): JSX.Element => {
	const [search, setSearch] = useState("");
	const [value, setValue] = useState(selectedFilter ? selectedFilter.value : "");
	const [options, setOptions] = useState(
		filter.listOptions ? filter.listOptions : filter.options ? filter.options : {}
	);
	const [uniqueOptions, setUniqueOptions] = useState<typeof options>([]);
	const [areAllSelected, setAreAllSelected] = useState(false);
	const [selectedValues, setSelectedValues] = useState<string[]>(value ? value.split(FILTER_VALUE_SEPARATOR) : []);
	const { t } = useTranslation();
	const onInputClick = (value: string, evt?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		evt?.stopPropagation();
		onChangeValue(value);
	};

	const onChangeValue = (value: string) => {
		const newSelectedValues = selectedValues.includes(value)
			? selectedValues.filter((selectedValue) => selectedValue !== value)
			: [...selectedValues, value];

		if (
			(value === "a" && selectedValues[0] === "a" && selectedValues.length === 1) ||
			(value === "u" && selectedValues[0] === "u" && selectedValues.length === 1)
		)
			return setValue("");

		if (value !== "a" && value !== "u" && (newSelectedValues.includes("a") || newSelectedValues.includes("u")))
			return setValue(value);

		setValue(value === "a" || value === "u" ? value : newSelectedValues.join(FILTER_VALUE_SEPARATOR));
	};

	const onSearch = (value: string) => {
		setSearch(value);
	};

	useEffect(() => {
		const initialOptions = filter.listOptions ? filter.listOptions : filter.options ? filter.options : {};
		const filterOptions: Record<string, any> = {
			...initialOptions,
			a: t("lib:go_list.filters.any"),
			u: t("lib:go_list.filters.unknown"),
		};
		if (search === "") {
			setOptions(filterOptions);
		} else {
			setOptions(
				Object.fromEntries(
					Object.entries(filterOptions).filter(
						([key, value]) =>
							(typeof key === "string" &&
								typeof value === "string" &&
								value.toLowerCase().includes(search.toLowerCase())) ||
							key === "a" ||
							key === "u" ||
							selectedValues.includes(filter.filterByListOptionValue ? value : key)
					)
				)
			);
		}
	}, [search]);

	const getSortedAlphabeticallyOptions = () => {
		const optionsArray = Object.entries(options);
		return optionsArray.sort((a, b) => a[1].localeCompare(b[1]));
	};

	const optionsToDisplay = filter.sortAlphabetically ? getSortedAlphabeticallyOptions() : Object.entries(options);

	const toggleSelectAll = () => {
		let allUniqueOptionsIds: string;

		if (Array.isArray(uniqueOptions)) {
			allUniqueOptionsIds = uniqueOptions.join(FILTER_VALUE_SEPARATOR);
		} else if (filter.filterByListOptionValue) {
			allUniqueOptionsIds = Object.values(uniqueOptions).join(FILTER_VALUE_SEPARATOR);
		} else {
			allUniqueOptionsIds = Object.keys(uniqueOptions).join(FILTER_VALUE_SEPARATOR);
		}

		if (areAllSelected && allUniqueOptionsIds === value) {
			setValue("");
		} else {
			setValue(allUniqueOptionsIds);
		}

		setAreAllSelected((state) => !state);
	};

	useEffect(() => {
		setAreAllSelected(false);
	}, [options, search, setAreAllSelected, setValue]);

	useEffect(() => {
		if (Array.isArray(options)) {
			setUniqueOptions(options.filter((id) => id !== "u" && id !== "a"));
		} else {
			setUniqueOptions(Object.fromEntries(Object.entries(options).filter(([id]) => id !== "u" && id !== "a")));
		}
	}, [options]);

	useEffect(() => {
		let allUniqueOptionsIds: string;

		if (Array.isArray(uniqueOptions)) {
			allUniqueOptionsIds = uniqueOptions.join(FILTER_VALUE_SEPARATOR);
		} else if (filter.filterByListOptionValue) {
			allUniqueOptionsIds = Object.values(uniqueOptions).join(FILTER_VALUE_SEPARATOR);
		} else {
			allUniqueOptionsIds = Object.keys(uniqueOptions).join(FILTER_VALUE_SEPARATOR);
		}

		if (allUniqueOptionsIds !== value) {
			setAreAllSelected(false);
		}

		const newSelectedValues = value ? value.split(FILTER_VALUE_SEPARATOR) : [];
		const isEverythingTheSame =
			selectedValues.length === newSelectedValues.length &&
			selectedValues.every((value, index) => newSelectedValues[index] === value);

		if (!isEverythingTheSame) {
			setSelectedValues(newSelectedValues);
			const isExistsOrDoesNotExistValueSelected = value === "a" || value === "u";
			onChange(isExistsOrDoesNotExistValueSelected ? value : "e", value);
		}
	}, [value, uniqueOptions, setAreAllSelected, onChange, selectedValues]);

	return (
		<>
			<Form.Control
				type="search"
				className="filter-input"
				value={search}
				onChange={(e) => onSearch(e.target.value)}
			/>
			<Form.Group className={"form-group list-search-select-container"}>
				{(Array.isArray(uniqueOptions) ? uniqueOptions.length > 0 : Object.keys(uniqueOptions).length > 0) && (
					<div className="list-search-select-select-all">
						<Dropdown.Item onClick={toggleSelectAll}>
							<Form.Check
								id="select_all"
								type="checkbox"
								label={t("lib:go_list.filters.select_all")}
								checked={areAllSelected}
								onClick={(e) => {
									e.stopPropagation();
									toggleSelectAll();
								}}
							/>
						</Dropdown.Item>
					</div>
				)}
				{optionsToDisplay.map(([key, value]) => {
					if (key === "a" || key === "u") return null;
					return (
						<Dropdown.Item
							key={`type_${id}_${key}`}
							onClick={() =>
								onChangeValue(filter.filterByListOptionValue ? value : (key as FilterCondition))
							}
						>
							<Form.Check
								type="checkbox"
								label={value}
								name={`type_${id}_${key}`}
								id={`type${id}_${filter.filterByListOptionValue ? value : key}`}
								value={filter.filterByListOptionValue ? value : key}
								checked={selectedValues.includes(filter.filterByListOptionValue ? value : key)}
								onClick={(e) => onInputClick(e.currentTarget.value as FilterCondition, e)}
							/>
						</Dropdown.Item>
					);
				})}
				{filter.hasDefaultOptions !== false && (
					<div className={"list-search-select-footer"}>
						{Object.entries(options).map(([key, value]) => {
							if (key === "a" || key === "u") {
								return (
									<Dropdown.Item
										key={`type_${id}_${key}`}
										onClick={() => onChangeValue(key as FilterCondition)}
									>
										<Form.Check
											type="checkbox"
											label={value}
											name={`type_${id}_${key}`}
											id={`type${id}_${key}`}
											value={key}
											checked={selectedValues.includes(key)}
											onClick={(e) => onInputClick(e.currentTarget.value as FilterCondition, e)}
										/>
									</Dropdown.Item>
								);
							}
							return null;
						})}
					</div>
				)}
			</Form.Group>
		</>
	);
};

export default ListFilterList;
