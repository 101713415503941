import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { JPK_FAInvoiceApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../../components/Errors/CompanyInfoNotFull";
import { JPKInvoiceType } from "../../../../services/types";
import { useInvoice } from "../../hooks/useInvoice";

const today = new Date();
const dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const dateTo = new Date(today.getFullYear(), today.getMonth(), 0);

const OrganizationInvoicesJpkJpkFAPage = (): JSX.Element => {
	const form = useForm<JPK_FAInvoiceApi>({
		criteriaMode: "all",
	});
	const {
		control,
		formState: { errors },
		setError,
		reset,
		formState,
		handleSubmit,
		register,
		watch,
	} = form;
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { loading, setLoading, loadingCompanyInfo, companyInfo, previousInvoiceData, downloadJpk } = useInvoice(
		JPKInvoiceType.JPK_FA
	);
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.field.jpk_fa.title"));
	}, []);

	useEffect(() => {
		if (previousInvoiceData) {
			reset({
				...previousInvoiceData,
				date_from: formatDateToString(dateFrom),
				date_to: formatDateToString(dateTo),
			});
		}
	}, [reset, previousInvoiceData]);

	const onSubmit = handleSubmit(async (data: JPK_FAInvoiceApi) => {
		if (!validateCustomErrors()) return;
		try {
			setLoading(true);
			const res = await api.organization().generateJpkFA(data);
			downloadJpk(res, data);
			reset(data);
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setLoading(false);
		}
	});

	const checkIfCanRenderForm = () => {
		return !(!companyInfo || !companyInfo.name || !companyInfo.tax_id_no);
	};

	const jpkFAValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	if (!companyInfo || loadingCompanyInfo || !previousInvoiceData) return <LoadingContainer />;

	return (
		<>
			{(!companyInfo ||
				Object.keys(companyInfo).length === 0 ||
				!companyInfo.company_name ||
				!companyInfo.tax_id_no) && <CompanyInfoNotFull />}
			{checkIfCanRenderForm() && (
				<FormDirty
					loading={loading}
					onSubmit={onSubmit}
					formState={formState}
					buttonSubmitCustom={
						<ButtonLoading type="submit" variant="primary" loading={loading}>
							{t("modules.invoice.action.generate_jpkfa.title")}
						</ButtonLoading>
					}
				>
					<fieldset className={"form-group"}>
						<h5>
							{t("modules.invoice.field.jpkfa_legend.title")}
							<small className="text-muted">
								<br />
								{t("modules.invoice.field.jpkfa_legend.help_text.description")}
							</small>
						</h5>
						<div className="row">
							<div className="col-md-6">
								<FormDatePicker
									allowClear={false}
									label={t("common.word.start_date", { ns: "lib" })}
									control={control}
									name="date_from"
									errors={errors}
									timePicker={false}
								/>
							</div>
							<div className="col-md-6">
								<FormDatePicker
									allowClear={false}
									label={t("common.word.end_date", { ns: "lib" })}
									control={control}
									name="date_to"
									errors={errors}
									timePicker={false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<FormInput
									name="tax_office_code"
									label={t("modules.invoice.field.jpkv7_tax_office_code.title")}
									errors={errors}
									register={register}
									customValidationConfig={jpkFAValidationConfig}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<FormCheck
									label={t("modules.invoice.action.jpkv7_extend.title")}
									type="switch"
									name="extended"
									errors={errors}
									register={register}
								/>
							</div>
						</div>
					</fieldset>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								name="tax_id_no"
								register={register}
								errors={errors}
								label={t("common.word.tax_id_no.title", { ns: "lib" })}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								name="full_name"
								register={register}
								errors={errors}
								label={t("common.word.full_company_name")}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								name="country_code"
								register={register}
								errors={errors}
								label={t("modules.jpk_fa.field.country_code.title")}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								name="voivodeship"
								register={register}
								errors={errors}
								label={t("modules.jpk_fa.field.voivodeship.title")}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								name="district"
								register={register}
								errors={errors}
								label={t("modules.jpk_fa.field.district.title")}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								name="parish"
								register={register}
								errors={errors}
								label={t("modules.jpk_fa.field.parish.title")}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								name="city"
								register={register}
								errors={errors}
								label={t("common.address.city", { ns: "lib" })}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								name="street"
								register={register}
								errors={errors}
								label={t("common.address.street", { ns: "lib" })}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								name="build_nr"
								register={register}
								errors={errors}
								label={t("common.address.build_nr", { ns: "lib" })}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								name="flat_nr"
								register={register}
								errors={errors}
								label={t("common.address.flat_nr", { ns: "lib" })}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								name="zip_code"
								register={register}
								errors={errors}
								label={t("common.address.zip_code", { ns: "lib" })}
								customValidationConfig={jpkFAValidationConfig}
							/>
						</div>
					</div>
				</FormDirty>
			)}
		</>
	);
};

export default OrganizationInvoicesJpkJpkFAPage;
