import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import ListCardOrganization from "go-app/components/Organizations/ListCardOrganization";
import ListCardOrganizationInvitation from "go-app/components/Organizations/ListCardOrganizationInvitation";
import handleError from "go-app/services/errors";
import { GoSuiteApp, OrganizationCard } from "go-app/services/types";
import { useOrganizations } from "go-app/utils/OrganizationsContext";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyData from "go-core/components/EmptyData";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import Search from "go-list/core/components/Search";
import { getGoAccountsApiURL } from "../../../../utils/envutil";
import { apiSecurity } from "../../services/Api/api";
import { InvitationSecurityApi } from "../../services/Api/typesSecurity";
import { useAuth } from "../../services/auth";
import { selectUser } from "../../services/users/selectors";

interface Props {
	applications: GoSuiteApp[];
	baseUrl: string;
	oldVersionAppsTimestamp: Record<string, number>;
}

const SecurityUserDashboardComponent: FC<Props> = (props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const { organizations, setOrganizations } = useOrganizations();
	const [invitations, setInvitations] = useState<InvitationSecurityApi[]>([]);
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const [search, setSearch] = useState("");
	const [appliedSearch, setAppliedSearch] = useState("");
	const auth = useAuth(props.baseUrl);
	const applications = props.applications;
	const history = useHistory();
	const user = useSelector(selectUser);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("lib:common.word.locations"));
	}, []);

	const fetchOrganizations = async (search: string) => {
		try {
			const res = await apiSecurity.user().getExternalOrganizations({ search, size: 100 });
			setOrganizations(res.data.data);
			setAppliedSearch(search);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};
	const fetchInvitations = async () => {
		try {
			const res = await apiSecurity.user().getUserInvitations({
				include: "organization,organization.info",
			});
			setInvitations(res.data.data);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const fetch = async () => {
		await Promise.all([fetchOrganizations(search), fetchInvitations()]);
		setAppliedSearch(search);
		setLoading(false);
	};
	useEffect(() => {
		fetch();
	}, [search]);

	const handleAcceptInvitation = async (id: string) => {
		const invitationId = Number(id);
		try {
			await apiSecurity.user().acceptUserInvitation(invitationId);
			setInvitations([...invitations.filter((f) => f.id !== invitationId)]);
			await auth.refreshUser();
			addSuccessFlash(t("lib:go_security.flash.invitation_accepted"));
			fetchOrganizations(search);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleRejectInvitation = async (id: string) => {
		await confirmation({
			title: t("lib:common.word.confirmation"),
			message: t("lib:go_security.invitation.reject.confirmation.message"),
		});
		const invitationId = Number(id);
		try {
			await apiSecurity.user().rejectUserInvitation(invitationId);
			setInvitations([...invitations.filter((f) => f.id !== invitationId)]);
			await auth.refreshUser();
			addSuccessFlash(t("lib:go_security.flash.invitation_rejected"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const getFilteredInvitations = () => {
		if (!search) return invitations;
		return invitations.filter((invitation) => {
			const organizationName = invitation.organization_name;
			return search ? organizationName.toLowerCase().includes(search.toLowerCase()) : true;
		});
	};
	const mapOrganization = (x: Record<string, any>) => {
		return {
			name: x.name,
			company_id: x.company_id,
			created_at: x.created_at,
			applications: x.applications,
		} as OrganizationCard;
	};
	if (loading) return <LoadingContainer />;

	const installedOrganizations = organizations.filter((x) => x.application);
	const notInstalledOrganizations = organizations.filter((x) => !x.application);
	const viewInstalledOrganizations = installedOrganizations.map((x) => mapOrganization(x));
	const viewNotInstalledOrganizations = notInstalledOrganizations.map((x) => mapOrganization(x));

	const viewInvitations = getFilteredInvitations().map((x) => {
		return {
			organization: {
				name: x.organization_name,
				company_id: x.company_id,
			} as OrganizationCard,
			invitation_id: x.id,
		};
	});

	const addLocationHandler = () =>
		window.location.replace(user.partners?.length ? `${getGoAccountsApiURL()}?action=add` : "/register");

	if (
		organizations.length === 0 &&
		viewInvitations.length === 0 &&
		appliedSearch.length === 0 &&
		search.length === 0
	) {
		history.push(`/register`);
	}

	return (
		<div className="content">
			<div className="content-header">
				<h1 className="content-header-title">{t("lib:go_security.user.dashboard.title")}</h1>
				<div className="content-actions">
					<div>
						<Search onChange={(e) => setSearch(e)} />
					</div>
					<Button onClick={addLocationHandler} className="btn btn-primary">
						+ {t("lib:go_security.user.dashboard.actions.create_organization")}
					</Button>
				</div>
			</div>
			{organizations.length === 0 && viewInvitations.length === 0 ? (
				<EmptyData
					title={
						!search
							? t("lib:go_security.user.dashboard.empty_data.no_organizations")
							: t("lib:go_security.user.dashboard.empty_data.no_organizations_found")
					}
					actions={[
						{
							name: t("lib:go_security.user.dashboard.actions.create_organization"),
							click: () => {
								history.push(`/register`);
							},
						},
					]}
				/>
			) : (
				<>
					{viewInvitations !== undefined && viewInvitations.length > 0 && (
						<>
							<h5>{t("lib:go_security.user.dashboard.header.your_invitations")}</h5>
							<div className="row row-cols-1 rows-cols-md-2 row-cols-lg-3 row-cols-xl-4">
								{viewInvitations.map((invitation) => {
									return (
										<ListCardOrganizationInvitation
											key={`invitation-${invitation.invitation_id}`}
											organization={invitation.organization}
											id={invitation.invitation_id.toString()}
											onAccept={handleAcceptInvitation}
											onReject={handleRejectInvitation}
										/>
									);
								})}
							</div>
							{organizations.length > 0 && (
								<div>
									<hr className={"mb-4"} />
								</div>
							)}
						</>
					)}
					{organizations.length > 0 && (
						<>
							<h5>{t("lib:go_security.user.dashboard.header.your_organizations")}</h5>
							<div className="row row-cols-1 rows-cols-md-2 row-cols-lg-3 row-cols-xl-4">
								{viewInstalledOrganizations.map((organization, index) => (
									<ListCardOrganization
										key={`installed-organization-${index}`}
										organization={organization}
										applications={applications}
										oldVersionAppsTimestamp={props.oldVersionAppsTimestamp}
									/>
								))}
							</div>
							<div className="text-success">
								<hr className={"mb-4"} />
							</div>
							<div className="row row-cols-1 rows-cols-md-2 row-cols-lg-3 row-cols-xl-4">
								{viewNotInstalledOrganizations.map((organization, index) => (
									<ListCardOrganization
										key={`not-installed-organization-${index}`}
										organization={organization}
										applications={applications}
										oldVersionAppsTimestamp={props.oldVersionAppsTimestamp}
									/>
								))}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default SecurityUserDashboardComponent;
