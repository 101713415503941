import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

const SubmenuAdvancedNavigation = (): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	return (
		<div className="content-navigation">
			<Nav>
				<Nav.Item>
					<Nav.Link as={NavLink} to={`/${organization.id}/settings/advanced/copy-place`}>
						{t("modules.setting.action.copy_data_from_other_location.title")}
					</Nav.Link>
				</Nav.Item>
				{/*<Nav.Item>*/}
				{/*    <Nav.Link as={NavLink} to={`/${organization.id}/settings/advanced/copy-test-place`}>*/}
				{/*        {t("modules.setting.action.copy_data_from_other_test_location.title")}*/}
				{/*    </Nav.Link>*/}
				{/*</Nav.Item>*/}
			</Nav>
		</div>
	);
};

export default SubmenuAdvancedNavigation;
