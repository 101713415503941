import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { RequiredFieldsInformation } from "go-security/components/RequiredFieldsInformation/RequiredFieldsInformation";
import { DiscountApi } from "../../../../../../../../../../services/Api/Organization/types";
import DiscountForm from "../../components/Form/DiscountForm";

interface HistoryStateProps {
	discount?: DiscountApi;
}

const OrganizationMenuDiscountsNewPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { state } = useLocation<HistoryStateProps>();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const baseDiscount = useRef(
		state?.discount ||
			({
				discount_type: "PERCENT",
				status: "ENABLED",
			} as DiscountApi)
	);

	useEffect(() => {
		handleChangeTabTitle(t("modules.discount.header.new.title"));
	}, []);

	const renderTitle = () => t("modules.discount.header.new.title");
	const renderSubtitle = () => <RequiredFieldsInformation />;

	return (
		<>
			<Header title={renderTitle()} subtitle={renderSubtitle()} />
			<DiscountForm discount={baseDiscount.current} />
		</>
	);
};

export default OrganizationMenuDiscountsNewPage;
