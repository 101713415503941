import React, { FC, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../../images/svg/sortable-handle.svg";
import {
	PriceListPredicateApi,
	UpdatePriceListPredicateApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import AddNewPredicateModal from "./AddNewPredicateModal";
import PredicateConditionsTable from "./PredicateConditionsTable";

interface Props {
	predicates: PriceListPredicateApi[];
	setPredicates: (value: PriceListPredicateApi[]) => void;
	setShowAddNewPredicateModal: (value: boolean) => void;
	showAddNewPredicateModal: boolean;
	onRefresh: () => void;
}

const PredicatesForm: FC<Props> = ({
	predicates,
	setPredicates,
	onRefresh,
	setShowAddNewPredicateModal,
	showAddNewPredicateModal,
}) => {
	const isSortingRef = useRef(false);
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [predicatePriceList, setPredicatePriceList] = useState<Record<string, any> | undefined>(undefined);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<UpdatePriceListPredicateApi>({
		criteriaMode: "all",
		defaultValues: {
			predicates,
		},
	});
	const { control, formState, watch, handleSubmit, setError, reset } = form;
	const { fields, remove, append, replace } = useFieldArray({
		control,
		name: "predicates",
		keyName: "key",
	});
	const watchedPredicates = watch("predicates") || [];
	const confirmation = useConfirmation();

	const updatePositions = (items: PriceListPredicateApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items.forEach((item, index) => {
			item.position = index;
		});
		replace(items);
	};

	const onSubmit = handleSubmit(async (data: UpdatePriceListPredicateApi) => {
		const params = {
			include: "conditions,menu_price_list",
		};
		const newData = { predicates: data.predicates };
		setLoading(true);
		try {
			const res = await api.organization().updatePriceListPredicates(newData, params);
			setPredicates(res);
			reset({
				predicates: res,
			});
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const onAddNewPredicate = () => {
		if (!predicatePriceList) return;

		append({
			position: fields.length,
			price_list: { ...predicatePriceList },
			conditions: [{ type: "ORDER_TYPE", operator: null, value: "", name: "" }],
			price_list_id: predicatePriceList.id,
		});
		setPredicatePriceList(undefined);
	};

	const onRemovePredicate = async (id: number | undefined, index: number) => {
		const existingPredicate = predicates.find((predicate) => predicate.id === id);
		if (existingPredicate) {
			try {
				await confirmation({
					title: t("confirmation.title", { ns: "lib" }),
					message: t("confirmation.message.remove", { ns: "lib" }),
				});
				await api.organization().removePriceListPredicate(Number(id));
				await onRefresh();
				addSuccessFlash(t("lib:common.flash.removed"));
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
		remove(index);
	};

	return (
		<FormDirty formState={formState} onSubmit={onSubmit} loading={loading}>
			<ReactSortable
				handle=".sortable-handler"
				tag="div"
				list={watchedPredicates}
				onUpdate={() => (isSortingRef.current = true)}
				setList={(fields) => updatePositions(fields)}
			>
				{fields.map((predicate: PriceListPredicateApi, index) => (
					<div key={fields[index].key}>
						<div className="d-flex justify-content-between align-items-center">
							<h5 className="mt-4">
								<SortableHandleSvg className="sortable-handler me-2" />
								{t("common.word.price_list")} "{predicate?.price_list?.name}"
							</h5>
							<RemoveSVG className="icon" onClick={() => onRemovePredicate(predicate?.id, index)} />
						</div>
						<PredicateConditionsTable form={form} index={index} />
					</div>
				))}
			</ReactSortable>
			{showAddNewPredicateModal && (
				<AddNewPredicateModal
					onHide={() => setShowAddNewPredicateModal(false)}
					onSuccessAction={onAddNewPredicate}
					form={form}
					setPredicatePriceList={setPredicatePriceList}
					isShown={showAddNewPredicateModal}
				/>
			)}
		</FormDirty>
	);
};

export default PredicatesForm;
