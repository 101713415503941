import { MoneyApi, MoneyApiForm } from "../../api/types";

export const RoundMoney = (num: number): number => {
	return Math.round(num * 100) / 100;
};

const FormatMoney = (money?: MoneyApi | MoneyApiForm): string => {
	let val;
	if (!money) {
		return "";
	}
	const amount = money.amount;

	if (amount === null || amount === undefined || amount?.toFixed === undefined) {
		val = "-";
	} else {
		val = `${amount.toFixed(2)} ${money.currency}`;
	}
	return val;
};

export default FormatMoney;
