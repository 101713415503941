import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { apiOrganization } from "go-security/services/Api/apiOrganization";
import { authOrganization } from "go-security/services/organizations/actions";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { OrganizationState } from "go-security/services/organizations/types";
import { selectUser } from "go-security/services/users/selectors";
import {
	ClearVenueCustomFormProps,
	PosReporCheckBeforeDeletionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ClearSale from "./components/ClearSale/ClearSale";
import { SaleDeletionType } from "./components/ClearSale/services/types";
import ClearVenue from "./components/ClearVenue";
import ClearVenueCustom from "./components/ClearVenueCustom";
import ClearVenueCustomPermanently from "./components/ClearVenueCustomPermanently";
import { DateRange, DeletionType } from "./services/types";

const OrganizationSettingsInfoClearVenuePage = (): JSX.Element => {
	const [actionType, setActionType] = useState<DeletionType>(DeletionType.UNSET);
	const [loading, setLoading] = useState(false);
	const [currentReportUid, setCurrentReportUid] = useState<string | undefined>(undefined);
	const [posReportDetails, setPosReportDetails] = useState<PosReporCheckBeforeDeletionApi | undefined>(undefined);
	const [selectedDeletionRange, setSelectedDeletionRange] = useState<string | DateRange | undefined>(undefined);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const user = useSelector(selectUser);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.clear_venue.title"));
	}, []);

	if (!user.admin) {
		addFlash({
			type: "danger",
			msg: "access_forbidden",
		});
		return <Redirect to={`/${organization.id}`} />;
	}

	const clearSaleHandler = async (password: string, deletionType: string) => {
		try {
			if (deletionType === SaleDeletionType.UNSET) return;
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.action", { ns: "lib" }),
			});
			setLoading(true);

			if (deletionType === SaleDeletionType.SALE_PERMANENT_DELETION)
				await api.organization().clearVenueSale(password);
			else if (currentReportUid)
				await api.organization().clearVenueSaleBasedOnPosReport(currentReportUid, password);

			setLoading(false);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setCurrentReportUid(undefined);
			setSelectedDeletionRange(undefined);
			setPosReportDetails(undefined);
		} catch (e) {
			setLoading(false);
			handleError.alert(e, addFlash);
		}
	};

	const clearVenueHandler = async (password: string) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.action", { ns: "lib" }),
			});
			setLoading(true);
			await api.clearVenue(password);
			if (organization.id) {
				const resp = await apiOrganization.getOrganizationMe(organization.id);

				if (resp.data.data.status === "NEW" || resp.data.data.status === "IN_PROGRESS") {
					dispatch(authOrganization(resp.data.data as OrganizationState));
					history.push(`/${organization.id}/install`);
				} else {
					dispatch(authOrganization(resp.data.data as OrganizationState));
				}
			}
			setLoading(false);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			setLoading(false);
			handleError.alert(e, addFlash);
		}
	};

	const clearVenueCustomHandler = async (data: ClearVenueCustomFormProps, password: string) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.action", { ns: "lib" }),
			});
			setLoading(true);
			await api.organization().clearVenueCustom(data, password);
			setLoading(false);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			setLoading(false);
			handleError.alert(e, addFlash);
		}
	};

	const formCancelHandler = () => setActionType(DeletionType.UNSET);

	return (
		<form>
			<LimitComponentVisibility
				defaultVisible={actionType === DeletionType.SALE}
				id={"SALE"}
				legendText={t("modules.clear_data.field.clear_venue_permanently.title")}
				helpText={t("modules.clear_data.field.clear_venue_permanently.helptext.description")}
				handleChange={(val) => setActionType(val ? DeletionType.SALE : DeletionType.UNSET)}
				unlimitedContent={
					<ClearSale
						loading={loading}
						currentReportUid={currentReportUid}
						onSetCurrentReportUid={setCurrentReportUid}
						handleClearSale={clearSaleHandler}
						onCancel={formCancelHandler}
						posReportDetails={posReportDetails}
						onSetPosReportDetails={setPosReportDetails}
						selectedDeletionRange={selectedDeletionRange}
						onSetSelectedDeletionRange={setSelectedDeletionRange}
					/>
				}
			/>
			<LimitComponentVisibility
				defaultVisible={actionType === DeletionType.CUSTOM}
				id={"CUSTOM"}
				legendText={t("modules.clear_data.field.clear_specific_data.title")}
				helpText={t("modules.clear_data.field.clear_specific_data.helptext.description")}
				handleChange={(val) => setActionType(val ? DeletionType.CUSTOM : DeletionType.UNSET)}
				unlimitedContent={
					<ClearVenueCustom loading={loading} handleClearVenueCustom={clearVenueCustomHandler} />
				}
			/>
			<LimitComponentVisibility
				defaultVisible={actionType === DeletionType.PERMANENT}
				id={"PERMANENT"}
				legendText={t("modules.clear_data.field.data_permanent_delete.title")}
				helpText={t("modules.clear_data.field.data_permanent_delete.helptext.description")}
				handleChange={(val) => setActionType(val ? DeletionType.PERMANENT : DeletionType.UNSET)}
				unlimitedContent={<ClearVenueCustomPermanently loading={loading} setLoading={setLoading} />}
			/>
			<LimitComponentVisibility
				defaultVisible={actionType === DeletionType.VENUE}
				id={"VENUE"}
				legendText={t("modules.clear_data.field.clear_all_data.title")}
				helpText={t("modules.clear_data.field.clear_all_data.helptext.description")}
				handleChange={(val) => setActionType(val ? DeletionType.VENUE : DeletionType.UNSET)}
				unlimitedContent={<ClearVenue handleClearVenue={clearVenueHandler} loading={loading} />}
			/>
		</form>
	);
};

export default OrganizationSettingsInfoClearVenuePage;
