import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingEmptyData } from "go-core/components/Loading";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { FiscalizeOrderFormProps } from "../../../../../../../../../../services/Api/Organization/types";
import { SearchSelectApi } from "../../../../../../../../../../services/Api/types";

interface Props {
	form: UseFormReturn<FiscalizeOrderFormProps>;
}

const LiveOrderFiscalizationTerminals = ({ form }: Props): JSX.Element => {
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const { setValue, watch } = form;
	const [terminals, setTerminals] = useState<SearchSelectApi[] | undefined>();
	const organization = useSelector(selectOrganization);

	useEffect(() => {
		fetchTerminals();
	}, []);

	useEffect(() => {
		if (terminals?.length === 1 && organization.more?.close_order_terminal) {
			setValue("terminal_id", Number(terminals[0].id));
		}
	}, [terminals, organization.more?.close_order_terminal]);

	const fetchTerminals = async () => {
		try {
			let params: Record<string, any> = {
				"status|e": "ENABLED",
				"type|e": "POS",
				size: 0,
			};
			if (organization.more?.close_order_terminal) {
				params = {
					...params,
					"id|e": organization.more.close_order_terminal,
				};
			}
			const res = await apiOrganization.getTerminalsSearchSelect(
				"",
				params,
				undefined,
				undefined,
				!organization.more?.close_order_terminal
			);
			setTerminals(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleChangeTerminal = (terminal: SearchSelectApi) => {
		const currentTerminalValue = watch("terminal_id");
		if (currentTerminalValue === terminal.id) setValue("terminal_id", undefined);
		else setValue("terminal_id", Number(terminal.id));
	};

	const drawTerminals = () =>
		(terminals || []).map((terminal) => {
			const isSelected = Number(watch("terminal_id")) === Number(terminal.id);
			return (
				<Button
					key={`terminal-${terminal.id}`}
					onClick={() => handleChangeTerminal(terminal)}
					className={`${isSelected ? "btn-primary" : "btn-light"}`}
				>
					{terminal.label}
				</Button>
			);
		});

	return (
		<div>
			<h5 className="mb-2">{t("modules.live_order.field.terminal.title")}</h5>
			{!organization.more?.close_order_terminal && (
				<span className="text-muted">{t("modules.live_order.field.terminal.description")}</span>
			)}
			<div className="d-flex flex-wrap gap-2 mt-3">{!drawTerminals ? <LoadingEmptyData /> : drawTerminals()}</div>
		</div>
	);
};

export default LiveOrderFiscalizationTerminals;
