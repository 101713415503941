import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as InstallSVG } from "../../../../../../../../images/svg/apps/install.svg";
import { ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { renderAppIcon } from "./AppCard";

interface UninstalledAppProps {
	configApp: ApplicationTemplateApi;
	onInstall: () => void;
}

const AppCardUninstalled: FC<UninstalledAppProps> = ({ configApp, onInstall }) => {
	const handleInstall = (evt: MouseEvent) => {
		evt.stopPropagation();
		onInstall();
	};

	const { t } = useTranslation();
	return (
		<div className={"app-card"}>
			<div className={"app-card-header"}>
				{renderAppIcon(configApp)}
				<div className={"app-header-actions"}>
					<InstallSVG onClick={handleInstall} />
				</div>
			</div>
			<div className={"app-card-body"} style={{ paddingBottom: 0, borderBottom: "none" }}>
				<h5>{t(`enums.apps.providers.${configApp.provider}`)}</h5>
				<span className={"text-muted"}>{configApp.short_description}</span>
			</div>
		</div>
	);
};
export default AppCardUninstalled;
