import { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationInvoicesProformaEditPage from "./Edit";
import OrganizationInvoicesIndexProformaPage from "./Index/index";
import OrganizationInvoicesProformaNewPage from "./New";
import OrganizationInvoicesProformaPreviewPage from "./Preview";

const OrganizationInvoicesProformaPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route exact={true} path={props.match.url} component={OrganizationInvoicesIndexProformaPage} />
			<Route path={`${props.match.url}/new`} exact={true} component={OrganizationInvoicesProformaNewPage} />
			<Route exact path={`${props.match.url}/:invoice_id`} component={OrganizationInvoicesProformaPreviewPage} />
			<Route path={`${props.match.url}/:invoice_id/edit`} component={OrganizationInvoicesProformaEditPage} />
		</Switch>
	);
};
export default OrganizationInvoicesProformaPage;
