import React, { FC, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import DateInfoBox from "../../../../../../../../components/Common/DateInfoBox/DateInfoBox";
import { ReactComponent as AlertSVG } from "../../../../../../../../images/svg/alert.svg";
import { ReactComponent as RemoveSVG } from "../../../../../../../../images/svg/remove.svg";
import { InvoiceApi, InvoiceOrderApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { invoicePreviewIncludes } from "../../pages/Index/Preview";
import AddInvoiceOrderModal from "./AddInvoiceOrderModal";

interface Props {
	orders: InvoiceOrderApi[];
	invoice: InvoiceApi;
	handleUpdateInvoice: (data: InvoiceApi) => void;
	isAdvance: boolean;
}

const InvoiceOrdersCard: FC<Props> = ({ orders, invoice, handleUpdateInvoice, isAdvance }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [showModal, setShowModal] = useState(false);
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();

	const handleRemoveOrder = async (orderId: number) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		const params: Record<string, any> = invoicePreviewIncludes;
		const dto = { ...invoice };
		dto.orders = [
			...invoice.orders
				.filter((order) => order?.order_id && order?.order_id !== orderId)
				.map((order) => {
					return { order_id: order.order_id } as InvoiceOrderApi;
				}),
		];
		try {
			const res = await api.organization().updateInvoice(dto, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleUpdateInvoice(res);
		} catch (err) {
			handleError.alert(err, addFlash);
			handleException(err);
		}
	};

	return (
		<Card>
			<Card.Body>
				<div className={"action-header"}>
					<h5>{t("modules.invoice.field.orders.title")}</h5>
					<Button variant={"add"} onClick={() => setShowModal(true)}>
						+ {t("common.action.add", { ns: "lib" })}
					</Button>
				</div>
				{orders.length > 0 ? (
					<>
						{orders.map((order, index) => {
							if (!order?.order_id) return null;

							return (
								<div className={"invoice-orders"} key={index}>
									<DateInfoBox date={order.closed_at} />
									<div className={"invoice-order-box"}>
										<div className={"invoice-order-details"}>
											<Link
												className={"order-link"}
												to={`/${organization.id}/sales/orders/${order.order_id}`}
												target={"_blank"}
												rel={"noreferrer"}
											>
												{order.number}
												{order.tax_id_no_mismatch && (
													<OverlayTrigger
														placement="top"
														overlay={
															<Tooltip id={`tooltip-${index}`}>
																{t("modules.invoice.constraints.tax_id_no_mismatched")}
															</Tooltip>
														}
													>
														{({ ref, ...triggerHandler }) => (
															<AlertSVG
																className={"ms-1"}
																ref={ref}
																{...triggerHandler}
															/>
														)}
													</OverlayTrigger>
												)}
											</Link>
											<div className={"order-date"}>
												{FormatDate(order.closed_at, undefined, true)}
											</div>
										</div>
										<div className={"order-remove"}>
											<RemoveSVG
												className="icon"
												onClick={() => handleRemoveOrder(order?.order_id)}
											/>
										</div>
									</div>
								</div>
							);
						})}
					</>
				) : (
					<EmptyList description={t("modules.invoice.field.empty_orders.title")} />
				)}
			</Card.Body>
			{showModal && (
				<AddInvoiceOrderModal
					invoice={invoice}
					onClose={() => setShowModal(false)}
					onAdd={(data) => {
						handleUpdateInvoice(data);
						setShowModal(false);
					}}
					isAdvance={isAdvance}
				/>
			)}
		</Card>
	);
};
export default InvoiceOrdersCard;
