import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";

const SalesNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const prefix = `/${organization.id}/sales`;
	const venueReportPosPermission = useSelector(hasPermission("VENUE_REPORTPOS_SHOW"));
	const venueOrderShowPermission = useSelector(hasPermission("VENUE_ORDER_SHOW"));

	const items = [
		{
			title: t("modules.order.header.title"),
			route: `${prefix}/orders`,
			hide: !venueOrderShowPermission,
		},
		{
			title: t("modules.transaction.header.title"),
			route: `${prefix}/transactions`,
			hide: !venueOrderShowPermission,
		},
		{
			title: t("modules.pos_report.header.title"),
			route: `${prefix}/pos_reports`,
			hide: !venueReportPosPermission,
		},
	];

	return <SubNavigationItems title={t("components.navigation.sales")} items={items} />;
};
export default SalesNavigation;
