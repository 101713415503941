import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiError } from "go-core/api/types";

const hasErrors = (errors: ApiError[], name?: string): boolean => {
	if (Array.isArray(errors) && name) {
		const error = errors.find((f) => f.field === name);
		if (error) {
			return true;
		}
	}
	return false;
};

const FormErrorMessage = (props: Record<string, any>): JSX.Element | null => {
	const errors = props.errors;
	const { t } = useTranslation();
	if (Array.isArray(errors) && props.field) {
		const error = errors.find((f) => f.field === props.field);
		if (error) {
			return (
				<Form.Control.Feedback type="invalid">
					<div>{t(`constraints.${error.code}`)}</div>
				</Form.Control.Feedback>
			);
		}
	}
	return null;
};

export { hasErrors, FormErrorMessage };
