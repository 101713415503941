import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import LiveOrdersFiltersNavigation from "./filters/LiveOrdersFiltersNavigation";

const LiveOrdersNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const isMobile = useWindowSize().isMobile;
	const { t } = useTranslation();
	const location = useLocation();

	const getNavigationDropdownTitle = () => {
		const { pathname } = location;

		if (pathname.includes("map")) return t("modules.live_order.field.map.title");
		if (pathname.includes("list")) return t("modules.live_order.field.list.title");
		if (pathname.includes("table")) return t("modules.live_order.field.table.title");
		return "";
	};

	return (
		<div className="contextual-navigation">
			<div className="navigation-body">
				{isMobile ? (
					<Nav>
						<NavDropdown title={getNavigationDropdownTitle()} className="navigation-dropdown">
							<NavDropdown.Item as={NavLink} to={`/${organization.id}/live_orders/map`}>
								{t("modules.live_order.field.map.title")}
							</NavDropdown.Item>
							<NavDropdown.Item as={NavLink} to={`/${organization.id}/live_orders/list`}>
								{t("modules.live_order.field.list.title")}
							</NavDropdown.Item>
							<NavDropdown.Item as={NavLink} to={`/${organization.id}/live_orders/table`}>
								{t("modules.live_order.field.table.title")}
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				) : (
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link as={NavLink} to={`/${organization.id}/live_orders/map`}>
								{t("modules.live_order.field.map.title")}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to={`/${organization.id}/live_orders/list`}>
								{t("modules.live_order.field.list.title")}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to={`/${organization.id}/live_orders/table`}>
								{t("modules.live_order.field.table.title")}
							</Nav.Link>
						</Nav.Item>
					</Nav>
				)}
			</div>
			<LiveOrdersFiltersNavigation isMobile={isMobile} />
		</div>
	);
};

export default LiveOrdersNavigation;
