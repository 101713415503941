import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { CopyPlaceApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import CopyPlaceOptions from "../../../components/CopyPlaceOptions";
import { RemoveAndCopyDataModal } from "./RemoveAndCopyDataModal";

interface Props {
	copyPlace: CopyPlaceApi;
}

const CopyPlaceForm = (props: Props): JSX.Element => {
	const form = useForm<CopyPlaceApi>({
		defaultValues: props.copyPlace,
	});
	const [defaultOrganization, setDefaultOrganization] = useState(undefined);
	const { t } = useTranslation();
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteDataAndCopyLoading, setDeleteDataAndCopyLoading] = useState(false);
	const [showClearAndCopyDataModal, setShowClearAndCopyDataModal] = useState(false);
	const {
		formState,
		formState: { errors },
		handleSubmit,
		control,
		setError,
		reset,
		watch,
	} = form;
	const { validateCustomErrors, setErrors } = useCustomErrors<CopyPlaceApi>(setError);
	const { addFlash, addSuccessFlash } = useFlash();
	const onCopyPlaceSubmit = handleSubmit(async (data: CopyPlaceApi) => {
		if (!validateCustomErrors()) {
			return;
		}
		setSaveLoading(true);
		try {
			if (data.password) delete data.password;
			await api.organization().copyPlace(data);
			addSuccessFlash(t("lib:common.flash.completed"));
			setDefaultOrganization(undefined);
			reset({
				copied_organization_id: null,
				copy_menus: false,
				copy_availabilities: false,
				copy_rooms: false,
				copy_payment_methods: false,
				copy_printout_templates: false,
				copy_clients: false,
				copy_employees: false,
				copy_segments: false,
				copy_custom_fields: false,
				copy_invoice_details: false,
				copy_venue_settings: false,
				copy_venue_roles_and_permissions: false,
				copy_default_comments: false,
				copy_currency_rates: false,
				copy_taxes: false,
				copy_directions: false,
				copy_workplaces: false,
				copy_point_of_sales: false,
				copy_notification_templates: false,
				copy_receipt: false,
				copy_tags: false,
			});
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setSaveLoading(false);
		}
	});

	const searchPlaces = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getOrganizationsToCopySearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const customSubmitButtons = (
		<>
			<ButtonLoading loading={saveLoading} type="submit" data-testid="form-save-button">
				{t("common.action.save", { ns: "lib" })}
			</ButtonLoading>
			<Button variant="danger" onClick={() => setShowClearAndCopyDataModal(true)}>
				{t("modules.copyplace.action.delete_data_and_copy.title")}
			</Button>
		</>
	);

	const deleteAndCopyDataHandler = handleSubmit(async (data: CopyPlaceApi) => {
		if (!validateCustomErrors()) {
			return;
		}
		try {
			setDeleteDataAndCopyLoading(true);

			const dataEntries = Object.entries(data);
			const dataEntriesWithRemoveKeys = dataEntries.map(([dataEntryName, dataEntryValue]) => {
				if (dataEntryName === "copied_organization_id") return [];
				const newDataEntryName = dataEntryName.replace("copy", "remove");
				return [newDataEntryName, dataEntryValue];
			});
			let dataChangedToDeletionFormat = Object.fromEntries(dataEntriesWithRemoveKeys);
			if (dataChangedToDeletionFormat.remove_menus) {
				dataChangedToDeletionFormat = {
					...dataChangedToDeletionFormat,
					remove_categories: true,
					remove_item_groups_and_items: true,
					remove_discounts: true,
					remove_modifier_groups: true,
					remove_price_lists: true,
				};
			}

			await api.organization().clearVenueCustomPermanently(dataChangedToDeletionFormat, data.password);
			await onCopyPlaceSubmit();
			setShowClearAndCopyDataModal(false);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		} finally {
			setDeleteDataAndCopyLoading(false);
		}
	});

	const onHideClearOrganizationModal = () => setShowClearAndCopyDataModal(false);

	const copiedOrganizationIdValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<>
			<FormDirty
				formState={formState}
				onSubmit={onCopyPlaceSubmit}
				loading={saveLoading}
				buttonSubmitCustom={customSubmitButtons}
			>
				<FormSelectGroup
					name="copied_organization_id"
					onChange={(obj, fullObj) => setDefaultOrganization(fullObj)}
					loadOptions={searchPlaces}
					defaultValue={defaultOrganization}
					getOptionLabel={(opt) => opt.name}
					getOptionValue={(opt) => opt.id}
					label={t("modules.copyplace.field.copied_organization_id.title")}
					control={control}
					errors={errors}
					data-testid="copied_organization_id"
					customValidationConfig={copiedOrganizationIdValidationConfig}
				/>
				<CopyPlaceOptions form={form} />
			</FormDirty>
			<RemoveAndCopyDataModal
				showModal={showClearAndCopyDataModal}
				onHide={onHideClearOrganizationModal}
				onDeleteAndCopy={deleteAndCopyDataHandler}
				deleteDataAndCopyLoading={deleteDataAndCopyLoading}
				form={form}
			/>
		</>
	);
};
export default CopyPlaceForm;
