import React, { FormEvent, KeyboardEvent } from "react";
import { ReactComponent as SearchSVG } from "../../../images/svg/search.svg";

interface Props {
	placeholder?: string;
	onSearch: (searchString: string) => void;
}

const Search = (props: Props): JSX.Element => {
	const inputStyles = {
		marginLeft: "-22px",
		paddingLeft: "26px",
	};

	const svgStyles = {
		marginLeft: "8px",
		marginTop: "8px",
		zIndex: 2,
	};

	const onChangeInput = (evt: FormEvent<HTMLInputElement>) => {
		props.onSearch((evt.target as HTMLInputElement).value);
	};

	const onInputKeyPress = (evt: KeyboardEvent) => {
		if (evt.charCode === 13) evt.preventDefault();
	};

	return (
		<div className={"d-flex"}>
			<SearchSVG style={svgStyles} />
			<input
				onKeyPress={onInputKeyPress}
				type="text"
				placeholder={props.placeholder}
				className="form-control mb-3"
				style={inputStyles}
				onChange={onChangeInput}
			/>
		</div>
	);
};

export default Search;
