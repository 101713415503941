import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { selectOrganization } from "go-security/services/organizations/selectors";
import LiveOrdersListItem from "../../../components/LiveOrdersListItem";
import { OrdersContext } from "../../../services/context";

const LiveOrdersList = (): JSX.Element => {
	const ordersContext = useContext(OrdersContext);
	const organization = useSelector(selectOrganization);
	const orderListRoutePath = `/${organization.id}/live_orders/list`;
	const history = useHistory();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		if (orderListRoutePath.endsWith("list")) {
			handleChangeTabTitle(
				`${t("modules.live_order.field.list.title")} | ${t("modules.live_order.header.title")}`
			);
		}
	}, [location.pathname]);

	const handleSelectOrder = (orderId: number) => {
		history.push(`/${organization.id}/live_orders/list/orders/${orderId}`);
	};

	const drawLiveOrders = () => {
		return ordersContext.orders.map((order) => (
			<LiveOrdersListItem
				key={`live-orders-list-item-${order.id}`}
				order={order}
				orderListRoutePath={orderListRoutePath}
				onSelectOrder={() => handleSelectOrder(order.id)}
			/>
		));
	};

	return ordersContext.orders.length > 0 ? (
		<div className="live-orders-grid-list">{drawLiveOrders()}</div>
	) : (
		<EmptyList />
	);
};

export default LiveOrdersList;
