import { FC } from "react";
import { ReactComponent as CopySVG } from "../../../../../../../../../../../../images/svg/rooms/copy.svg";

interface Props {
	handleCopy: () => void;
}

const CopyHandler: FC<Props> = ({ handleCopy }) => {
	return (
		<div className={"room-item-handler copy"} onClick={handleCopy}>
			<CopySVG />
		</div>
	);
};
export default CopyHandler;
