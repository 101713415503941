import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { useWindowSize } from "go-core/components/useWindowSize";
import UserInvoicesIndexPage from "./pages";

const UserInvoicesPage: FC<RouteComponentProps> = (props) => {
	const isMobile = useWindowSize().isMobile;
	const { t } = useTranslation();

	return (
		<div className={"page-container"}>
			{isMobile && <SubNavigationItems title={t("modules.invoice.header.title")} items={[]} />}
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/`} component={UserInvoicesIndexPage} />
				</Switch>
			</div>
		</div>
	);
};

export default UserInvoicesPage;
