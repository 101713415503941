import React, { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormInput } from "go-form";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<DeliveryZoneApi>;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<DeliveryZoneApi>[]>>;
}

const DeliveryZoneBasicInfoForm = ({ form, setErrors }: Props) => {
	const {
		register,
		formState: { errors },
		watch,
		control,
	} = form;
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const currency = organization?.currency || "PLN";
	const textInputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<fieldset className="form-group no-gutters">
			<h5>{t("lib:common.word.basic_data")}</h5>
			<FormInput
				label={t("lib:common.word.name")}
				register={register}
				name="name"
				errors={errors}
				customValidationConfig={textInputValidationConfig}
			/>
			<FormInput
				label={t("lib:common.word.description")}
				register={register}
				as="textarea"
				name="description"
				errors={errors}
			/>
			<FormMoneyInput
				label={t("modules.delivery_zone.field.min_order_price.title")}
				errors={errors}
				name="min_order_price.amount"
				currency={currency}
				control={control}
			/>
			<FormMoneyInput
				label={t("modules.delivery_zone.field.delivery_price.title")}
				errors={errors}
				name="delivery_price.amount"
				currency={currency}
				control={control}
			/>
			<FormMoneyInput
				label={t("modules.delivery_zone.field.free_delivery_price.title")}
				errors={errors}
				name="free_delivery_price.amount"
				currency={currency}
				control={control}
			/>
		</fieldset>
	);
};

export default DeliveryZoneBasicInfoForm;
