import React, { FC, useEffect, useRef, useState } from "react";
import { Icon, Map } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatAddress, { FormatAddressString } from "go-core/components/Formatters/FormatAddress";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as ClientLogo } from "../../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as HomeSVG } from "../../../../../../../../../images/svg/bills/home.svg";
import { ReactComponent as IdSVG } from "../../../../../../../../../images/svg/bills/id.svg";
import { ReactComponent as PriceBagSVG } from "../../../../../../../../../images/svg/bills/price-bag.svg";
import { OrderDeliveryApi } from "../../../../../../../../../services/Api/Organization/types";
import { RenderOrderSourceLogo } from "../../../../../../../../../utils/orders/RenderOrderSourceLogo";

export interface Props {
	delivery: OrderDeliveryApi;
}

const DeliveryCard: FC<Props> = ({ delivery }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [width, setWidth] = useState(277);
	const divRef = useRef<HTMLDivElement | null>(null);
	const mapRef = useRef<Map | null>(null);

	const updateDimensions = () => {
		if (divRef.current) {
			setWidth(divRef.current.offsetWidth);
		}
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (divRef.current) {
				setWidth(divRef.current.offsetWidth);
				mapRef.current?.invalidateSize(false);
			}
		}, 500);

		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => {
			window.removeEventListener("resize", updateDimensions);
		};
	});

	const drawProvider = () => {
		switch (delivery.provider) {
			case "WOLT_DRIVE":
				return <RenderOrderSourceLogo source={delivery.provider} variant="medium" />;
			default:
				return <></>;
		}
	};

	return (
		<Card className="delivery-card">
			<Card.Body>
				<h5>{t("modules.order.field.delivery.title")}</h5>
				{delivery.address && (
					<div className="card-body-item">
						<HomeSVG />
						<div className="item-info">
							<span>
								{!delivery.coordinates ? (
									<a
										href={`https://maps.google.com/?q=${FormatAddressString(delivery.address)}`}
										target={"_blank"}
										rel="noreferrer"
									>
										<FormatAddress address={delivery.address} />
									</a>
								) : (
									<FormatAddress address={delivery.address} />
								)}
							</span>
						</div>
					</div>
				)}
				{delivery.provider ? (
					<div className="card-body-item delivery-provider">
						{drawProvider()}
						<span>{delivery.provider}</span>
					</div>
				) : (
					delivery.delivery_employee && (
						<div className="card-body-item">
							<ClientLogo />
							<div className="item-info">
								<span className="label">{t("modules.order.field.delivery_employee.title")}</span>
								<span>
									{delivery.delivery_employee.id ? (
										<Link
											target={"_blank"}
											rel={"noreferrer"}
											to={`/${organization.id}/employees/${delivery.delivery_employee.id}`}
										>
											{delivery.delivery_employee.name}
										</Link>
									) : (
										delivery.delivery_employee.name
									)}
								</span>
							</div>
						</div>
					)
				)}
				{delivery?.external_id && (
					<div className="card-body-item">
						<IdSVG />
						<div className="item-info">
							<span className="label">{t("modules.order.field.delivery_id.title")}</span>
							<span>{delivery.external_id}</span>
						</div>
					</div>
				)}
				{delivery?.cost && (
					<div className="card-body-item">
						<PriceBagSVG />
						<div className="item-info">
							<span className="label">{t("modules.order.field.delivery_cost.title")}</span>
							<span>{FormatMoney(delivery.cost)}</span>
						</div>
					</div>
				)}
				{delivery.coordinates && (
					<div className="card-body-item" ref={divRef}>
						<div className="item-info" style={{ width: `${width}px` }}>
							<div
								className={"order-delivery"}
								onClick={() =>
									window.open(
										`http://maps.google.com/maps?q=${delivery.coordinates.latitude},${delivery.coordinates.longitude}`
									)
								}
							>
								<MapContainer
									zoomControl={false}
									style={{ width: "100%", height: "150px", cursor: "pointer" }}
									whenCreated={(map) => (mapRef.current = map)}
									id={"delivery-map"}
									scrollWheelZoom={false}
									center={[delivery.coordinates.latitude, delivery.coordinates.longitude]}
									zoom={16}
								>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									<Marker
										icon={
											new Icon({
												iconUrl: markerIconPng,
												iconSize: [25, 41],
												iconAnchor: [12, 41],
											})
										}
										position={[delivery.coordinates.latitude, delivery.coordinates.longitude]}
									/>
								</MapContainer>
							</div>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default DeliveryCard;
