import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationMenuModifierGroupsEditPage from "./pages/Edit";
import OrganizationMenuModifierGroupsIndexPage from "./pages/Index";
import OrganizationMenuModifierGroupsNewPage from "./pages/New";

const OrganizationMenuModifierGroupsPage: FC<RouteComponentProps> = (props) => {
	return (
		<>
			<div className="content">
				<Switch>
					<Route
						path={`${props.match.url}/new`}
						exact={true}
						component={OrganizationMenuModifierGroupsNewPage}
					/>
					<Route
						path={`${props.match.url}/:modifier_group_id`}
						component={OrganizationMenuModifierGroupsEditPage}
					/>
					<Route path={`${props.match.url}/`} component={OrganizationMenuModifierGroupsIndexPage} />
				</Switch>
			</div>
		</>
	);
};
export default OrganizationMenuModifierGroupsPage;
