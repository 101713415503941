import React, { FC, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../images/svg/sortable-handle.svg";
import { ModifierGroupApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	onHide: () => void;
	isShown: boolean;
	modifierGroups: ModifierGroupApi[];
	changeModifierGroups: (data: ModifierGroupApi[]) => void;
}

const SortModifierGroupsModal: FC<Props> = ({ onHide, isShown, modifierGroups, changeModifierGroups }) => {
	const { t } = useTranslation();
	const isSortingRef = useRef(false);
	const [newModifierGroups, setNewModifierGroups] = useState<ModifierGroupApi[]>(modifierGroups);

	const sortModifierAlphabetically = () => {
		const sortedModifiers = [...newModifierGroups].sort((a, b) => a.name.localeCompare(b.name));
		setNewModifierGroups(sortedModifiers);
	};

	const updatePositions = (items: ModifierGroupApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		setNewModifierGroups(items);
	};

	const onSave = () => {
		changeModifierGroups(newModifierGroups);
		onHide();
	};

	return (
		<Modal onHide={onHide} show={isShown}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.item_group.header.sort_modifier_groups.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-column">
					<span className="text-muted">
						{t("modules.item_group.field.sort_modifier_groups_description.title")}
					</span>
					<div className="mt-3">
						<Button onClick={sortModifierAlphabetically}>
							{t("modules.item_group.field.sort_modifier_groups_alphabetically.title")}
						</Button>
					</div>
					<div className="sorted-modifier-groups mt-3 p-1">
						<ReactSortable
							handle=".sortable-handler"
							tag="div"
							list={newModifierGroups}
							onUpdate={() => (isSortingRef.current = true)}
							setList={(fields) => updatePositions(fields)}
						>
							{newModifierGroups.map((modifier_group, index) => (
								<div
									className="d-flex align-items-center sorted-modifier-group sortable-handler"
									key={`sorted-modifier-group-${index}`}
								>
									<SortableHandleSvg />
									<span className="ms-2">{modifier_group.name}</span>
								</div>
							))}
						</ReactSortable>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onSave}>{t("common.action.save", { ns: "lib" })}</Button>
				<Button variant="light" onClick={onHide}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SortModifierGroupsModal;
