import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import queryString from "query-string";
import { apiOrganization } from "./apiOrganization";
import { apiUser } from "./apiUser";
import applyAppTokenInterceptor from "./applyAppTokenInterceptor";
import applyAppTokenRefreshInterceptor from "./applyAppTokenRefreshInterceptor";

const restSecurityApi = axios.create({
	withCredentials: true,
});

applyAppTokenInterceptor(restSecurityApi);
applyAppTokenRefreshInterceptor(restSecurityApi);

restSecurityApi.interceptors.request.use((config) => {
	config.paramsSerializer = (params) => queryString.stringify(params, { arrayFormat: "bracket" });
	return config;
});

class apiSecurity {
	//ME LOGIN

	static getAxios = () => {
		return restSecurityApi;
	};

	//LOGIN
	static registerUserWithOrganization(data: Record<string, any>): Promise<AxiosResponse> {
		// restSecurityApi.defaults.headers.common['Authorization'] = "";
		const headers = { Authorization: `` };
		return restSecurityApi.post("/login/register", data, { headers });
	}

	static forgotPassword(email: string): Promise<AxiosResponse> {
		// restSecurityApi.defaults.headers.common['Authorization'] = "";
		const headers = { Authorization: `` };
		const encodedEmail = encodeURIComponent(email);
		return restSecurityApi.post(`/login/forgot_password?email=${encodedEmail}`, undefined, { headers });
	}

	static checkAuthorize(orgId: any, data: Record<string, any>): Promise<AxiosResponse> {
		const config = { params: data } as AxiosRequestConfig;
		// return await restSecurityApi.post(`/authorize1?organization_id=${orgId}`, undefined, config)
		return restSecurityApi.get(`/authorize_code?context=${orgId}`, config);
		// let config = {params: data} as AxiosRequestConfig
		// return await restSecurityApi.post(`/authorize?organization_id=${orgId}`, undefined, config)
	}
	static authorize(orgId: any, data: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.post(`/authorize_code`, { ...data, ...{ organization_id: orgId } });
		// let config = {params: data} as AxiosRequestConfig
		// return await restSecurityApi.post(`/authorize?organization_id=${orgId}`, undefined, config)
	}
	static authorizeOld(orgId: number, data: Record<string, any>): Promise<AxiosResponse> {
		const config = { params: data } as AxiosRequestConfig;
		return restSecurityApi.post(`/authorize_old?organization_id=${orgId}`, undefined, config);
	}
	static organization(): Record<string, any> {
		return apiOrganization;
	}

	static user(): Record<string, any> {
		return apiUser;
	}
}

export { restSecurityApi, apiSecurity };
