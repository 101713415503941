import { TFunction } from "react-i18next";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn } from "../../../../../components/VirtualTable/types";
import {
	getVirtualTablePdfDocumentDefinition,
	getVirtualTablePdfPageSize,
	getVirtualTableStyles,
} from "../../../../../components/VirtualTable/utils";

class PriceListPdfService {
	static generatePdf = async (
		items: MenuPriceListFlatApi[],
		priceLists: MenuPriceListApi[],
		defaultColumns: VirtualTableColumn[],
		exportConfig: ExportConfig,
		filterNames: Array<string>,
		t: TFunction
	) => {
		const table = PriceListPdfService.generateTable(items, priceLists, defaultColumns, exportConfig);
		let filterDefinition: Record<string, any> = {};
		if (filterNames.length > 0) {
			filterDefinition = {
				text: `${t("common.word.filters", { ns: "lib" })}:\n ${filterNames.join("\n")}`,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 3 : 12,
			};
		}
		const docDefinition: any = getVirtualTablePdfDocumentDefinition(
			getVirtualTablePdfPageSize(priceLists.length),
			exportConfig,
			t,
			filterDefinition,
			table
		);

		const pdfMake = (await import("pdfmake/build/pdfmake")).default;
		const pdfFonts = (await import("pdfmake/build/vfs_fonts")).default;
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		pdfMake.createPdf(docDefinition).download(`${exportConfig?.filename}`);
	};

	static generateTable = (
		items: MenuPriceListFlatApi[],
		priceLists: MenuPriceListApi[],
		defaultColumns: VirtualTableColumn[],
		exportConfig: ExportConfig
	) => {
		const widths: string[] = [];
		const tHead: Record<string, any>[] = [];
		defaultColumns.forEach((col) => {
			tHead.push({
				text: col.name,
				style:
					col.id === "price"
						? [
								RenderColumnUtils.parsePdfMoneyStyles({
									textAlign: "right",
									whiteSpace: "nowrap",
								}),
								getVirtualTableStyles(exportConfig).tableHeader,
						  ]
						: getVirtualTableStyles(exportConfig).tableHeader,
			});
			widths.push("auto");
		});
		priceLists.forEach((priceList) => {
			tHead.push({
				text: priceList.name,
				style: getVirtualTableStyles(exportConfig).tableHeader,
			});
			widths.push("auto");
		});
		const tableBody: Record<string, any>[] = [];
		const table = {
			widths,
			body: tableBody,
			dontBreakRows: true,
		};
		table.body.push(tHead);
		items.forEach((item) => {
			const bodyRow: Record<string, any>[] = [];
			defaultColumns.forEach((col) => {
				bodyRow.push({
					text: {
						text:
							col.id === "price"
								? item.default_price
									? FormatMoney(item.default_price)
									: ""
								: item[`${col.id}` as keyof MenuPriceListFlatApi],
						noWrap: col.id === "price",
					},
					style:
						col.id === "price"
							? [
									RenderColumnUtils.parsePdfMoneyStyles({
										textAlign: "right",
										whiteSpace: "nowrap",
									}),
									getVirtualTableStyles(exportConfig).tableHeader,
							  ]
							: getVirtualTableStyles(exportConfig).tableHeader,
				});
			});
			priceLists.forEach((priceList) => {
				const money = item.price_list_items.find((f) => f.price_list_id === priceList.id)?.price;
				bodyRow.push({
					text: { text: money ? FormatMoney(money) : "", noWrap: true },
					style: [
						RenderColumnUtils.parsePdfMoneyStyles({
							textAlign: "right",
							whiteSpace: "nowrap",
						}),
						getVirtualTableStyles(exportConfig).tableCell,
					],
				});
			});
			table.body.push(bodyRow);
		});
		return table;
	};
}

export default PriceListPdfService;
