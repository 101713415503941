import { Fragment, ReactNode, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormInput } from "go-form";
import { FormDirty } from "go-form/components/FormDirty";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { TranslationApi } from "../../../../../../../../services/Api/Organization/types";
import ButtonLanguage from "./ButtonLanguage";

interface Props {
	translations?: TranslationApi[];
	translationItems?: Record<string, any>[];
	onHide: () => void;
	handleSave: (entityTranslations: Record<string, any>, translationItems?: Record<string, any>[]) => void;
	allowDescription?: boolean;
	preventSubmitOnHide?: boolean;
	formChildren?: ReactNode;
	customTitle?: string;
	disableButton?: boolean;
}

const fillTranslations = (languages: string[], translations?: TranslationApi[], allowDescription?: boolean) => {
	const processedTranslations: TranslationApi[] = [];
	languages.forEach((lan) => {
		const found = translations?.filter((f) => f.locale).find((f) => f.locale === lan);
		if (!found) {
			processedTranslations.push({
				name: "",
				locale: lan,
				...(allowDescription && { description: "" }),
			});
		} else processedTranslations.push(found);
	});
	return processedTranslations;
};

const mapToForm = (
	languages: string[],
	entityTranslations?: TranslationApi[],
	translationItems?: Record<string, any>[],
	allowDescription?: boolean
) => {
	return {
		entity_translations: fillTranslations(languages, entityTranslations, allowDescription),
		translation_items:
			translationItems && translationItems.length > 0
				? translationItems.map((item) => {
						const entityTranslationNames = entityTranslations?.map(
							(entityTranslation) => entityTranslation.name
						);
						if (item.translations) {
							item.translations = item?.translations.map((translation: Record<string, any>) => {
								const name = entityTranslationNames?.find((n) => translation?.name?.includes(n));
								if (name)
									return {
										name: translation.name.replace(`${name} `, ""),
										locale: translation.locale,
										description: translation.description,
									};
								return translation;
							});
						}

						return {
							...item,
							translations: fillTranslations(languages, item.translations, allowDescription),
						};
				  })
				: undefined,
	};
};

const EntityTranslationsModalForm = ({
	translations,
	translationItems,
	onHide,
	handleSave,
	allowDescription,
	preventSubmitOnHide,
	formChildren,
	customTitle,
	disableButton,
}: Props) => {
	const languages = useSelector(selectOrganization).languages;
	const form = useForm<Record<string, any>>({
		criteriaMode: "all",
		defaultValues: useMemo(() => {
			return mapToForm(languages, translations, translationItems, allowDescription);
		}, []),
		shouldUnregister: false,
	});
	const {
		formState: { errors },
		register,
		handleSubmit,
		watch,
		formState,
	} = form;
	const [selectedLanguage, setSelectedLanguage] = useState("pl");
	const { t } = useTranslation();

	const onSubmit = handleSubmit((data: Record<string, any>) => {
		if (data.translation_items) {
			data.translation_items.forEach((item: Record<string, any>) => {
				item.translations.forEach((translation: Record<string, any>) => {
					if (translation.name) {
						translation.name = `${
							data.entity_translations.find(
								(entity: Record<string, any>) => entity.locale === translation.locale
							).name
						} ${translation.name}`;
					}
				});
			});
		}
		handleSave(data);
	});

	const handleOnHide = async () => {
		if (preventSubmitOnHide) {
			onHide();
		} else {
			await onSubmit();
		}
	};

	return (
		<Modal className={"modal-translations"} show={true} onHide={handleOnHide}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{customTitle ?? t("components.modal_translations.header.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{formChildren}
					<Form.Group className={"form-group language-options"}>
						{languages.map((lan, index) => {
							return (
								<ButtonLanguage
									key={index}
									selectedLanguage={selectedLanguage}
									onClick={() => setSelectedLanguage(lan)}
									language={lan}
								/>
							);
						})}
					</Form.Group>
					<fieldset className="form-group">
						<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
						{watch().entity_translations?.map((translation: TranslationApi, index: number) => {
							if (translation.locale === selectedLanguage) {
								if (allowDescription) {
									return (
										<div key={index} className={"row"}>
											<div className={"col-md-6"}>
												<FormInput
													register={register}
													label={t("components.modal_translations.form.name.label")}
													name={`entity_translations.${index}.name`}
													errors={errors}
												/>
											</div>
											<div className={"col-md-6"}>
												<FormInput
													register={register}
													as={"textarea"}
													rows={3}
													label={t("components.modal_translations.form.description.label")}
													name={`entity_translations.${index}.description`}
													errors={errors}
												/>
											</div>
										</div>
									);
								}
								return (
									<FormInput
										key={index}
										register={register}
										label={t("components.modal_translations.form.name.label")}
										name={`entity_translations.${index}.name`}
										errors={errors}
									/>
								);
							}
							return null;
						})}
					</fieldset>
					{translationItems && translationItems.length > 1 && (
						<fieldset className="form-group">
							{translationItems?.map((item, index) => {
								return (
									<Fragment key={index}>
										<h5>
											{t("common.word.variant")}{" "}
											{item.name?.length === 0 || !item.name
												? t("common.word.default")
												: item.name}
										</h5>
										<>
											{watch()
												.translation_items.find((it: { id: string }) => it.id === item.id)
												.translations.map(
													(translation: TranslationApi, translationIndex: number) => {
														if (translation.locale === selectedLanguage) {
															return (
																<div
																	key={translationIndex}
																	className={`row ${
																		index < watch().entity_translations.length
																			? "mb-2"
																			: ""
																	}`}
																>
																	<div className={"col-md-6"}>
																		<FormInput
																			register={register}
																			label={t(
																				"components.modal_translations.form.name.label"
																			)}
																			name={`translation_items.${index}.translations[${translationIndex}].name`}
																			errors={errors}
																		/>
																	</div>
																	<div className={"col-md-6"}>
																		<FormInput
																			as={"textarea"}
																			rows={3}
																			register={register}
																			label={t(
																				"components.modal_translations.form.description.label"
																			)}
																			name={`translation_items.${index}.translations[${translationIndex}].description`}
																			errors={errors}
																		/>
																	</div>
																</div>
															);
														}
														return null;
													}
												)}
										</>
									</Fragment>
								);
							})}
						</fieldset>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" type="submit" disabled={disableButton}>
						{t("common.action.save", { ns: "lib" })}
					</Button>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default EntityTranslationsModalForm;
