import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as UserSlashBlackSVG } from "../../../../../../../../../../images/svg/liveOrders/user-slash-black.svg";
import { statusColors } from "../../../../services/orderStatus";

const LiveOrdersMapStatusesLegend = (): JSX.Element => {
	const { t } = useTranslation();

	const statuses = [
		{
			color: statusColors.GRAY,
			label: t("modules.live_order.field.more_than_one_hour.title"),
		},
		{
			color: statusColors.GREEN,
			label: t("modules.live_order.field.between_one_hour_and_30_min.title"),
		},
		{
			color: statusColors.YELLOW,
			label: t("modules.live_order.field.between_30_min_and_15_min.title"),
		},
		{
			color: statusColors.RED,
			label: t("modules.live_order.field.less_than_15_min.title"),
		},
	];

	return (
		<div className="d-flex flex-column">
			<h5>{t("modules.live_order.field.statuses.title")}</h5>
			{statuses.map((status, index) => (
				<div className="d-flex align-items-center status-legend-item" key={`status-legend-${index}`}>
					<div className="status-legend-item-box" style={{ backgroundColor: status.color }} />
					<span className="text-muted">{status.label}</span>
				</div>
			))}
			<div className="d-flex align-items-center status-legend-item">
				<div className="status-legend-item-box employee-box">
					<UserSlashBlackSVG className="live-orders-red-icon" />
				</div>
				<span className="text-muted">{t("modules.live_order.field.without_driver_employee.title")}</span>
			</div>
		</div>
	);
};

export default LiveOrdersMapStatusesLegend;
