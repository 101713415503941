import React, { FC, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { useConfirmation } from "go-form/components/ModalConfirm";
import {
	EmployeeApi,
	RecalculateWorkTimesFormProps,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	employee: EmployeeApi;
	onHide: () => void;
	isShown: boolean;
}

const RecalculateWorktimesModal: FC<Props> = ({ employee, onHide, isShown }) => {
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const params: Record<string, any> = {
		include: "role,role.permissions,workplace,workplaces,workplaces.workplace",
	};
	const form = useForm<RecalculateWorkTimesFormProps>({
		criteriaMode: "all",
		defaultValues: {
			employees: [employee.id],
			date_from: "",
		},
		shouldUnregister: false,
	});
	const {
		formState,
		control,
		formState: { errors },
		handleSubmit,
		setValue,
		setError,
	} = form;
	const [loading, setLoading] = useState(false);

	const onRecalculateWorktime = handleSubmit(async (data: RecalculateWorkTimesFormProps) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		setLoading(true);
		try {
			setValue("employees", [employee.id]);
			await api.organization().recalculateWorktime(data, params);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal onHide={onHide} show={isShown}>
			<FormDirty
				formState={formState}
				key="recalculate-worktimes-form"
				onSubmit={onRecalculateWorktime}
				noValidate
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.employee.action.recalculate_worktimes.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant="warning">
						{t("modules.employee.constraints.recalculate_worktimes_modal_warning.title")}
					</Alert>
					<div>
						<FormDatePicker
							label={t("lib:common.word.date")}
							control={control}
							name="date_from"
							errors={errors}
						/>
						<small className="text-muted">{t("modules.employee.field.date_from.help_text.title")}</small>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading variant="primary" onClick={onRecalculateWorktime} loading={loading}>
						{t("modules.employee.action.recalculate.title")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default RecalculateWorktimesModal;
