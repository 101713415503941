import React, { ReactNode } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
	organizationId?: number;
	children: ReactNode;
}

const NavigationHeaderWrapperProfileSecurity = ({ organizationId, children }: Props): JSX.Element => {
	return organizationId ? (
		<Dropdown.Item className="navigation-header-profile-wrapper" as={Link} to={`/${organizationId}`}>
			{children}
		</Dropdown.Item>
	) : (
		<div className="navigation-header-profile-wrapper">{children}</div>
	);
};

export default NavigationHeaderWrapperProfileSecurity;
