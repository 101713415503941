import React, { useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { Form, FormControlProps } from "react-bootstrap";
import {
	Control,
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
	UseFormStateReturn,
	useController,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { ActionMeta, InputActionMeta, components } from "react-select";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as PreviewSVG } from "go-core/images/svg/preview.svg";
import { hasErrors } from "..";
import { useCustomValidation } from "../hooks";
import { CustomValidationConfig } from "../services/types";
import { isConstraintARequiredConstraint } from "../services/utils";
import { FormErrorMessage } from "./FormErrorMessage";

const parseToValues = (values: any, getOptionValue: (obj: any) => any) => {
	if (Array.isArray(values)) {
		return values.map((value) => {
			return getOptionValue(value);
		});
	}
	return getOptionValue(values);
};
const parseToObj = (values: any, defaultOptions: any, getOptionValue: (obj: any) => any, forceClear?: boolean) => {
	if (forceClear) return null;
	if (values && Array.isArray(values)) {
		let val = defaultOptions.filter((option: any) =>
			values.map((x) => getOptionValue(x)).includes(getOptionValue(option))
		);
		if (val.length === 0) {
			val = defaultOptions.filter((option: any) => values.map((x) => x).includes(getOptionValue(option)));
		}
		return val;
	}
	return values ? defaultOptions?.filter((option: any) => getOptionValue(option) === values)[0] : undefined;
};

interface FormSelectGroupProps extends FormSelectProps {
	label?: string;
	errors: any;
	focus?: boolean;
	help?: string;
}

const customStyles = { menu: (styles: any) => ({ ...styles, zIndex: 999 }) };
const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

export const FormSelectGroup = (props: FormSelectGroupProps): JSX.Element => {
	const { label, name, help, errors, ...controlProps } = props;
	const isFieldRequired = useMemo(
		() =>
			controlProps.customValidationConfig?.types.some(({ constraint }) =>
				isConstraintARequiredConstraint(constraint)
			),
		[controlProps.customValidationConfig?.types]
	);

	return (
		<Form.Group className="form-group" controlId={name}>
			{label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}

			{props.loadOptions ? (
				<FormSelect isInvalid={hasErrors(errors, name)} name={name} {...controlProps} />
			) : (
				<FormSelectSimple
					focus={props.focus}
					isInvalid={hasErrors(errors, name)}
					name={name}
					{...controlProps}
				/>
			)}
			{help && <Form.Text muted>{help}</Form.Text>}
			<FormErrorMessage errors={errors} name={name} />
		</Form.Group>
	);
};

interface FormSelectProps extends FormControlProps {
	options?: any;
	defaultValue?: any;
	loadOptions?: any;
	isInvalid?: boolean;
	isMulti?: boolean;
	parse?: (obj: any) => any;
	getOptionLabel?: (obj: any) => any;
	getOptionValue?: (obj: any) => any;
	onCreateOption?: (obj: any) => any;
	forceCreateOption?: boolean;
	onChange?: (obj: any, fullObj?: any) => any;
	path?: string;
	groupLabel?: string;
	placeholder?: string;
	focus?: boolean;
	name?: any;
	control?: Control<any>;
	onFocus?: () => void;
	isClearable?: boolean;
	disabled?: boolean;
	formatOptionLabel?: (option: any, labelMeta: any) => React.ReactNode;
	onMenuOpen?: (props: any) => Promise<any>;
	cacheUniqs?: any[];
	tabIndex?: number;
	onBlur?: () => void;
	openMenuOnFocus?: boolean;
	customActions?: JSX.Element[];
	toEditPath?: boolean;
	customValidationConfig?: CustomValidationConfig;
	forceClear?: boolean;
	onBlurShouldForceChangeOption?: boolean;
	forceInputChange?: boolean;
}

const IconOption = (props: any) => {
	const {
		path,
		getOptionValue,
		components: { toEditPath },
	} = props.selectProps;
	const value = props.data;
	const finalPath = toEditPath
		? `${String(path) + getOptionValue(value)}/edit`
		: String(path) + getOptionValue(value);

	return (
		<components.Option {...props}>
			{value.__isNew__ ? (
				props.children
			) : (
				<div className="option-icon">
					<span>{props.children}</span>
					<Link rel="noreferrer" target="_blank" to={finalPath} className="preview-option">
						<PreviewSVG />
					</Link>
				</div>
			)}
		</components.Option>
	);
};
const ControlView = (props: any) => {
	const {
		path,
		getOptionValue,
		components: { customActions, toEditPath },
	} = props.selectProps;
	const value = props.getValue()[0];
	let finalPath = "";
	if (path) {
		finalPath = toEditPath ? `${String(path) + getOptionValue(value)}/edit` : String(path) + getOptionValue(value);
	}

	return (
		<components.Control {...props}>
			{props.children}
			{finalPath && (
				<Link
					rel="noreferrer"
					target="_blank"
					to={finalPath}
					className={classNames("preview", { disabled: !value })}
				>
					<PreviewSVG />
				</Link>
			)}
			{customActions}
		</components.Control>
	);
};

export const FormSelectSimple = (props: FormSelectProps): JSX.Element => {
	const selectRef: any = useRef(null);
	const {
		name,
		isClearable,
		disabled,
		isMulti,
		control,
		getOptionValue,
		isInvalid,
		placeholder,
		formatOptionLabel,
		onChange,
		options,
		tabIndex,
		onBlur,
		openMenuOnFocus,
		customValidationConfig,
		forceClear,
		...controlProps
	} = props;
	const {
		field: { value },
	} = useController({
		name,
		control,
	});
	const { t } = useTranslation();
	useEffect(() => {
		if (props.focus && selectRef && selectRef.current) {
			selectRef.current.focus();
		}
	});
	const getOptionValueInternal = (newValue: any) => {
		if (!newValue) {
			return null;
		}
		return getOptionValue ? getOptionValue(newValue) : newValue.value;
	};
	const handleChange = (
		e: any,
		changeProps: {
			field: ControllerRenderProps<any, any>;
			fieldState: ControllerFieldState;
			formState: UseFormStateReturn<any>;
		}
	) => {
		let changeValue = null;
		if (e) {
			changeValue = parseToValues(e, getOptionValueInternal);
		}
		changeProps.field.onChange(changeValue);
		if (onChange) {
			onChange(changeValue);
		}
	};

	useCustomValidation({ name, value, customValidationConfig });

	return (
		<Controller
			name={name}
			render={(controlerProps) => {
				const text1 = parseToObj(value, options, getOptionValueInternal, forceClear);
				return (
					<div data-testid={props["data-testid" as keyof FormSelectProps]}>
						<Select
							options={options}
							name={name}
							styles={customStyles}
							ref={selectRef}
							className={classNames("form-select", { "is-invalid": isInvalid })}
							classNamePrefix={"form-select"}
							onChange={(e) => handleChange(e, controlerProps)}
							onBlur={onBlur}
							value={text1}
							getOptionValue={getOptionValueInternal}
							formatOptionLabel={formatOptionLabel ? formatOptionLabel : undefined}
							isMulti={isMulti}
							isDisabled={disabled}
							isClearable={isClearable !== undefined ? isClearable : true}
							placeholder={placeholder || t("lib:common.action.select")}
							loadingMessage={() => `${t("lib:common.action.loading")}...`}
							tabIndex={tabIndex?.toString()}
							openMenuOnFocus={openMenuOnFocus}
							menuPlacement="auto"
							defaultValue={props.defaultValue}
							// onFocus={() => {
							//     // if(selectRef && selectRef.current) {
							//     //     console.log(selectRef);
							//     //     selectRef.current.focus()
							//     // }
							// }}
						/>
					</div>
				);
			}}
			onFocus={() => {
				if (selectRef && selectRef.current) {
					selectRef.current.focus();
				}
			}}
			control={control}
			{...controlProps}
		/>
	);
};
export const FormSelect = (props: FormSelectProps): JSX.Element => {
	const {
		name,
		isClearable,
		disabled,
		isMulti,
		control,
		getOptionLabel,
		getOptionValue,
		onCreateOption,
		forceCreateOption,
		defaultValue,
		groupLabel,
		isInvalid,
		placeholder,
		loadOptions,
		path,
		onChange,
		formatOptionLabel,
		onMenuOpen,
		cacheUniqs,
		tabIndex,
		openMenuOnFocus,
		toEditPath,
		customValidationConfig,
		onBlurShouldForceChangeOption,
		forceInputChange,
		...controlProps
	} = props;
	const {
		field: { value },
	} = useController({
		name,
		control,
	});

	const [defaultOptions, setDefaultOptions] = React.useState<any[]>([]);
	const page = useRef<number>(0);
	const [search, setSearch] = React.useState<string>("");
	const [innerInputLabel, setInnerInputLabel] = React.useState(value);
	const [internalOptions, setInternalOptions] = React.useState<any[]>([]);
	const defaultValues = Array.isArray(defaultValue) ? [...defaultValue] : [defaultValue];
	const { t } = useTranslation();

	const getOptionLabelInternal = (optionValue: any) => {
		if (!optionValue) {
			return null;
		}
		if (optionValue.__isNew__) {
			return optionValue.label;
		}
		return getOptionLabel ? getOptionLabel(optionValue) : optionValue.label;
	};
	const getNewOptionDataInternal = (inputValue: any, optionLabel: any) => {
		return {
			id: inputValue,
			name: optionLabel,
		};
	};
	const getOptionValueInternal = (optionValue: any) => {
		if (!optionValue) {
			return null;
		}
		return getOptionValue ? getOptionValue(optionValue) : optionValue.value;
	};
	const loadOptionsInternal = async (data: any, scrollData: Array<{}>) => {
		if (scrollData.length % 20 === 0 && scrollData.length > 0) {
			page.current++;
		}
		if (data.toLowerCase() !== search.toLowerCase()) {
			page.current = 0;
		}
		setSearch(data);
		const response = await loadOptions(data, {
			page: page.current,
		});
		if (response.length === 0) page.current = 0;
		const resOptions = response.map((item: any) => {
			const newItem = { ...item };
			delete newItem.options;
			if (groupLabel) {
				newItem.options = item[groupLabel];
			}
			return newItem;
		});

		setInternalOptions([...resOptions]);

		return {
			options: [...resOptions],
			hasMore: resOptions.length === 20,
		};
	};

	const [text, setText] = React.useState(parseToObj(value, defaultValues, getOptionValueInternal));

	useCustomValidation({ name, value, customValidationConfig });

	useEffect(() => {
		const newText = parseToObj(value, defaultValues, getOptionValueInternal);
		if (newText?.label && newText?.id) {
			setText(newText);
		}
	}, [value, defaultValues]);

	const handleChange = (
		e: (value: any, action: ActionMeta<any>) => void,
		changeProps: {
			field: ControllerRenderProps<any, any>;
			fieldState: ControllerFieldState;
			formState: UseFormStateReturn<any>;
		}
	) => {
		setText(e);
		let changeValue = null;
		if (e) {
			changeValue = parseToValues(e, getOptionValueInternal);
		}
		changeProps.field.onChange(changeValue);
		if (onChange) {
			onChange(changeValue, e);
		}

		if (forceInputChange) {
			if (e === null) {
				setInnerInputLabel("");
			} else {
				setInnerInputLabel(getOptionLabelInternal(e));
			}
		}
	};

	const handleOnBlur = (
		event: React.FocusEvent<HTMLInputElement>,
		changeProps: {
			field: ControllerRenderProps<any, any>;
			fieldState: ControllerFieldState;
			formState: UseFormStateReturn<any>;
		}
	) => {
		if (onBlurShouldForceChangeOption && !isMulti) {
			const eventValue = event.target.value;
			const possibleOption = internalOptions.find((o) => getOptionLabelInternal(o) === eventValue);

			if (changeProps.field.value === undefined) return;
			if (eventValue === changeProps.field.value) return;

			if (possibleOption) {
				handleChange(possibleOption, changeProps);
			} else if (forceCreateOption) {
				handleChange(
					{
						label: eventValue,
						value: eventValue,
						__isNew__: true,
					} as any,
					changeProps
				);
			}
		}
	};

	let components: any = null;
	components = {
		Control: ControlView,
		customActions: props.customActions,
	};
	if (path) {
		components = {
			...components,
			Option: IconOption,
			toEditPath,
		};
	}
	const formatGroupLabel = (data: Record<string, any>) => {
		return (
			<div>
				<span>{data.label}</span>
				<span>{data.options.length}</span>
			</div>
		);
	};

	const onFocusInternal = async () => {
		if (defaultOptions.length <= 0 && !onMenuOpen) {
			const values = await loadOptionsInternal("", []);
			setDefaultOptions(values.options);
		}
	};

	const loadOptionsOnMenuOpen = async () => {
		const values = await loadOptionsInternal(forceInputChange ? innerInputLabel : "", []);
		setDefaultOptions(values.options);
	};

	const handleOnInputChange = (text: string, action: InputActionMeta) => {
		if (!forceInputChange) return;

		if (action.action === "input-change") {
			setInnerInputLabel(text);
			return text;
		}
		return innerInputLabel;
	};

	return (
		<Controller
			name={name}
			render={(controlProps) => {
				let text1 = parseToObj(value, defaultValues, getOptionValueInternal);
				let text2 = text;
				if (isMulti && onCreateOption) {
					text2 = text1;
				}
				if (!onCreateOption) {
					text1 = text;
				}
				if (onCreateOption && !value) {
					text1 = text;
				}
				if (!value) {
					text1 = null;
					text2 = null;
				}

				return (
					<div data-testid={props["data-testid" as keyof FormSelectProps]}>
						{onCreateOption || forceCreateOption ? (
							<CreatableAsyncPaginate
								loadOptions={loadOptionsInternal}
								// defaultOptions={defaultOptions}
								// onFocus={onFocusInternal}
								onMenuOpen={onMenuOpen ? () => loadOptionsOnMenuOpen() : undefined}
								path={path}
								styles={customStyles}
								getOptionValue={getOptionValueInternal}
								getOptionLabel={getOptionLabelInternal}
								formatOptionLabel={formatOptionLabel ? formatOptionLabel : undefined}
								components={components}
								onCreateOption={onCreateOption}
								formatGroupLabel={formatGroupLabel}
								cacheUniqs={[
									search,
									onBlurShouldForceChangeOption && !isMulti ? text1 : "",
									innerInputLabel,
									...(cacheUniqs || []),
								]}
								isMulti={isMulti}
								createOptionPosition="first"
								className={classNames("form-select", { "is-invalid": isInvalid, "is-multi": isMulti })}
								classNamePrefix={"form-select"}
								onChange={(e) => handleChange(e, controlProps)}
								onBlur={(e) => handleOnBlur(e as React.FocusEvent<HTMLInputElement>, controlProps)}
								value={isMulti ? text2 : text1}
								inputValue={forceInputChange ? innerInputLabel : undefined}
								onInputChange={handleOnInputChange}
								placeholder={placeholder || t("lib:common.action.select")}
								isDisabled={disabled}
								controlShouldRenderValue={!forceInputChange}
								isClearable={isClearable === undefined || isClearable}
								formatCreateLabel={() => `${t("lib:common.action.create")} "${search}"`}
								loadingMessage={() => `${t("lib:common.action.loading")}...`}
								tabIndex={tabIndex?.toString()}
								openMenuOnFocus={openMenuOnFocus}
								menuPlacement="auto"
							/>
						) : (
							<AsyncPaginate
								loadOptions={loadOptionsInternal}
								defaultOptions={defaultOptions}
								path={path}
								cacheUniqs={[search, ...(cacheUniqs || [])]}
								getNewOptionData={getNewOptionDataInternal}
								getOptionValue={getOptionValueInternal}
								getOptionLabel={getOptionLabelInternal}
								formatOptionLabel={formatOptionLabel ? formatOptionLabel : undefined}
								className={classNames("form-select", { "is-invalid": isInvalid, "is-multi": isMulti })}
								classNamePrefix={"form-select"}
								styles={customStyles}
								components={components}
								isMulti={isMulti}
								onChange={(e) => handleChange(e, controlProps)}
								value={isMulti ? text2 : text1}
								placeholder={placeholder || t("lib:common.action.select")}
								isDisabled={disabled}
								isClearable={isClearable === undefined || isClearable}
								formatCreateLabel={() => `${t("lib:common.action.create")} "${search}"`}
								loadingMessage={() => `${t("lib:common.action.loading")}...`}
								tabIndex={tabIndex?.toString()}
								openMenuOnFocus={openMenuOnFocus}
								menuPlacement="auto"
							/>
						)}
					</div>
				);
			}}
			control={control}
			{...controlProps}
		/>
	);
};
