import React, { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationSalesTransactionsIndexPage from "./pages";

const OrganizationSalesTransactionsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/`} component={OrganizationSalesTransactionsIndexPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSalesTransactionsPage;
