import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { EmployeeApi, EmployeeWorkplaceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { ReactComponent as RemoveSVG } from "./../../../../../../../../../images/svg/remove.svg";

interface Props {
	form: UseFormReturn<EmployeeApi>;
}

const EmployeeWorkplacesForm: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
	} = props.form;
	const { fields, remove, append } = useFieldArray({
		control,
		name: "workplaces",
		keyName: "key",
	});
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";

	const searchWorkplaces = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getWorkplacesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};
	return (
		<>
			<table className="table table-form">
				<thead>
					<tr>
						<th className="w-75">{t("modules.employee.field.workplace.title")}</th>
						<th>{t("modules.employee.field.hourly_rate_net.title")}</th>
						<th className="action" />
					</tr>
				</thead>
				<tbody>
					{fields.map((item, index) => {
						return (
							<tr key={fields[index].key}>
								<td>
									<FormSelectGroup
										name={`workplaces.${index}.workplace_id`}
										placeholder={t("modules.employee.field.workplace_placeholder.title")}
										errors={errors}
										getOptionLabel={(option) => option.label}
										getOptionValue={(option) => option.id}
										defaultValue={{
											label: item.workplace?.name,
											id: item.workplace?.id,
										}}
										loadOptions={searchWorkplaces}
										control={control}
										data-testid={`workplaces.${index}.workplace_id`}
									/>
								</td>
								<td>
									<FormMoneyInput
										control={control}
										currency={currency}
										name={`workplaces.${index}.hourly_rate.amount`}
										errors={errors}
									/>
								</td>
								<td className="action">
									<RemoveSVG className="icon" onClick={() => remove(index)} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<Button variant="add" type="button" onClick={() => append({} as EmployeeWorkplaceApi)}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
		</>
	);
};
export default EmployeeWorkplacesForm;
