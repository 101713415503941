import React, { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { parseApplicationSettings, parseApplicationSettingsValues, parseStringToList } from "../../utils/utils";
import { AppState } from "../Apps/AppModal";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const MewsFormModalCard: FC<Props> = ({ handleUpdateApp, appState }) => {
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState<boolean>(false);

	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings),
				custom_fields_to_sync: parseStringToList(
					appState.app.settings,
					appState.config || {},
					"custom_fields_to_sync",
					"custom_fields"
				)
					?.filter((cf) => cf && (cf.label || cf.name))
					?.map((cf) => ({
						label: cf.label ? cf.label : cf.name,
						id: cf.slug,
					})),
				item_custom_fields_to_sync: parseStringToList(
					appState.app.settings,
					appState.config || {},
					"item_custom_fields_to_sync",
					"item_custom_fields"
				)
					?.filter((cf) => cf && (cf.label || cf.name))
					?.map((cf) => ({
						label: cf.label ? cf.label : cf.name,
						id: cf.slug,
					})),
			},
		},
	});
	const {
		formState,
		handleSubmit,
		control,
		register,
		formState: { errors },
		reset,
		watch,
		setError,
	} = form;
	const { t } = useTranslation();

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };
		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};
		try {
			const res = await api.organization().updateApp(newData, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleUpdateApp(res);
			reset(watch());
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const defaultCustomFields = parseStringToList(
		appState.app.settings,
		appState.config || {},
		"custom_fields_to_sync",
		"custom_fields"
	)
		?.filter((cf) => cf && (cf.label || cf.name))
		?.map((cf) => ({
			label: cf.label ? cf.label : cf.name,
			id: cf.slug,
		}));

	const defaultItemCustomFields = parseStringToList(
		appState.app.settings,
		appState.config || {},
		"item_custom_fields_to_sync",
		"item_custom_fields"
	)
		?.filter((cf) => cf && (cf.label || cf.name))
		?.map((cf) => ({
			label: cf.label ? cf.label : cf.name,
			id: cf.slug,
		}));

	const searchCustomFields = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newObj = {
			...params,
			"resource_type|e": "ORDER",
		};
		return api.organization().getCustomFieldsSearchSelect(search, newObj, {
			cancelToken: options?.token,
		});
	};

	const searchItemCustomFields = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newObj = {
			...params,
			"resource_type|e": "ITEM",
		};
		return api.organization().getCustomFieldsSearchSelect(search, newObj, {
			cancelToken: options?.token,
		});
	};

	const onSearchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const organization = useSelector(selectOrganization);

	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} onSubmit={onSubmit} className={"app-form"}>
					<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
					<FormSelectGroup
						name="settings.payment_method_id"
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						label={t("modules.app.field.payment_method.title")}
						errors={errors}
						defaultValue={{
							label: appState?.config?.payment_method_name,
							id: appState.app.settings.payment_method_id,
						}}
						loadOptions={onSearchPaymentMethods}
						control={control}
						data-testid="settings.payment_method_id"
					/>
					<FormSelectGroup
						isMulti={true}
						name="settings.custom_fields_to_sync"
						path={`/${organization.id}/settings/payment_methods/`}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.slug}
						label={t("modules.app.action.custom_fields_sync.title")}
						errors={errors}
						defaultValue={defaultCustomFields}
						loadOptions={searchCustomFields}
						control={control}
						data-testid="settings.custom_fields_to_sync"
					/>
					<FormSelectGroup
						isMulti={true}
						name="settings.item_custom_fields_to_sync"
						path={`/${organization.id}/settings/custom_fields/`}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.slug}
						label={t("modules.app.action.item_custom_fields_sync.title")}
						errors={errors}
						defaultValue={defaultItemCustomFields}
						loadOptions={searchItemCustomFields}
						control={control}
						data-testid="settings.item_custom_fields_to_sync"
					/>
					<ButtonLoading loading={loading} variant="add" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};

export default MewsFormModalCard;
