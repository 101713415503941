import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ClientForm from "../../components/ClientForm";

interface MatchParams {
	clientId: string;
}

const OrganizationClientsIndexEditPage = (): JSX.Element => {
	const [client, setClient] = useState<ClientApi>();
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	const { clientId } = useParams<MatchParams>();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		fetch();
	}, []);

	useEffect(() => {
		handleChangeTabTitle(
			`${client?.name || client?.tax_id_no || client?.contact_phone_number || client?.contact_email} | ${t(
				"modules.client.header.title"
			)}`
		);
	}, [client?.name, client?.tax_id_no, client?.contact_phone_number, client?.contact_email]);

	const fetch = async () => {
		try {
			const params: Record<string, any> = {};
			params.include = "client_group";
			const res = await api.organization().getClient(Number(clientId), params);
			setClient(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!client) {
		return <LoadingContainer />;
	}

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/clients/${client.id}`);

	return (
		<>
			<Header title={`${t("modules.client.header.title")} "${client.name}"`} back={goBack} />
			<ClientForm client={client} setClient={setClient} />
		</>
	);
};
export default OrganizationClientsIndexEditPage;
