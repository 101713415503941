import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { PdfOrientationType } from "../types";
import { getPdfFontSizeOptions } from "../utils/utils";

interface Props {
	show: boolean;
	onHide: () => void;
	onSubmit: (orientation: PdfOrientationType, fontSize: string) => void;
	defaultValues?: {
		orientation?: PdfOrientationType;
		fontSize?: string;
	};
}

const PdfGenerationOptionsModal = ({ onHide, show, onSubmit, defaultValues }: Props) => {
	const { t } = useTranslation();
	const [orientation, setOrientation] = useState<PdfOrientationType>(defaultValues?.orientation || "LANDSCAPE");
	const [fontSize, setFontSize] = useState<string>(
		defaultValues?.fontSize === "0" ? "9" : defaultValues?.fontSize ?? "9"
	);

	const handleSubmit = () => {
		onSubmit(orientation, fontSize);
		onHide();
	};

	return (
		<Modal onHide={onHide} show={show}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:go_core.word.pdf_generation_options_modal")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="mb-3">
					<h5>{t("lib:go_core.word.orientation")}</h5>
					<Form.Check
						type="radio"
						label={t("lib:go_core.word.landscape")}
						id="landscape"
						checked={orientation === "LANDSCAPE"}
						onClick={() => setOrientation("LANDSCAPE")}
					/>
					<Form.Check
						type="radio"
						label={t("lib:go_core.word.portrait")}
						id="portrait"
						onClick={() => setOrientation("PORTRAIT")}
						checked={orientation === "PORTRAIT"}
					/>
				</div>
				<div>
					<h5>{t("lib:go_core.word.font_size")}</h5>
					<Select
						className="form-select"
						classNamePrefix="form-select"
						value={{ value: fontSize, label: fontSize }}
						onChange={(value) => setFontSize(value?.value || "")}
						options={getPdfFontSizeOptions()}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button type="submit" onClick={handleSubmit}>
					{t("lib:common.action.select")}
				</Button>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PdfGenerationOptionsModal;
