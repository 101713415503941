import { RefObject, useCallback, useEffect, useState } from "react";
import CSS from "csstype";
import { useListenEvent } from "go-core/hooks";
import { FOOTER_HEIGHT } from "../services/consts";

export const useDynamicTableHeight = (
	mainDivRef: RefObject<HTMLDivElement>,
	footerHeight: number = FOOTER_HEIGHT,
	pageContainerRef?: RefObject<HTMLDivElement | null>
): Record<string, any> => {
	const [isTableHeightOverflowing, setIsTableHeightOverflowing] = useState(false);
	const [containerHeight, setContainerHeight] = useState<number>();
	const [mainDivStyles, setMainDivStyles] = useState<CSS.Properties>();

	const checkOverflow = useCallback(() => {
		if (mainDivRef.current) {
			const tableBottomDistanceFromPageTop = mainDivRef.current.getBoundingClientRect().bottom;
			const isTableOutsideOfTheScreen = window.innerHeight - tableBottomDistanceFromPageTop - FOOTER_HEIGHT < 0;
			const doesTableHaveVerticalScroll = mainDivRef.current.scrollHeight > mainDivRef.current.clientHeight;

			return setIsTableHeightOverflowing(isTableOutsideOfTheScreen || doesTableHaveVerticalScroll);
		}

		return setIsTableHeightOverflowing(false);
	}, [mainDivRef]);

	const updateMainDivStyles = useCallback(() => {
		if (mainDivRef.current) {
			const containerOffset = containerHeight ? containerHeight - window.innerHeight : 0;
			const mainDivTopOffset = Math.abs(mainDivRef.current.getBoundingClientRect().top - containerOffset);
			const newMainDivMaxHeight = window.innerHeight - mainDivTopOffset - footerHeight - containerOffset;

			return setMainDivStyles((currentStyles) => {
				const didMainDivMaxHeightChange = currentStyles?.maxHeight !== `${newMainDivMaxHeight}px`;

				if (didMainDivMaxHeightChange) {
					return {
						maxHeight: `${newMainDivMaxHeight}px`,
					};
				}

				return currentStyles;
			});
		}

		setMainDivStyles(undefined);
	}, [mainDivRef, footerHeight, containerHeight]);

	const updateContainerHeight = useCallback(() => {
		setContainerHeight(pageContainerRef?.current?.clientHeight);
	}, [pageContainerRef]);

	useListenEvent({
		eventName: "resize",
		callback: updateMainDivStyles,
		shouldRunCallbackOnAdd: true,
		shouldRunCallbackOnRemove: true,
	});

	useEffect(() => {
		if (mainDivRef.current) {
			const resizeObserver = new ResizeObserver(checkOverflow);

			resizeObserver.observe(mainDivRef.current);

			return () => resizeObserver.disconnect();
		}
	}, [mainDivRef, checkOverflow]);

	useEffect(() => {
		if (pageContainerRef?.current) {
			const resizeObserver = new ResizeObserver(updateContainerHeight);

			resizeObserver.observe(pageContainerRef.current);

			return () => resizeObserver.disconnect();
		}
	}, [pageContainerRef, updateContainerHeight]);

	return {
		isTableHeightOverflowing,
		mainDivStyles,
	};
};
