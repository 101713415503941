import React from "react";
import { Location } from "history";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, match } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

const SubmenuInvoicesJpkNavigation = (): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	return (
		<div className="content-navigation">
			<Nav>
				<Nav.Item>
					<Nav.Link
						as={NavLink}
						isActive={(match: match | null, location: Location) => location.pathname.endsWith("jpk")}
						to={`/${organization.id}/invoices/jpk`}
					>
						{t("modules.invoice.field.jpk.title")}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link as={NavLink} to={`/${organization.id}/invoices/jpk/jpk_v7`}>
						{t("modules.invoice.field.jpk_v7.title")}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link as={NavLink} to={`/${organization.id}/invoices/jpk/jpk_fa`}>
						{t("modules.invoice.field.jpk_fa.title")}
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</div>
	);
};

export default SubmenuInvoicesJpkNavigation;
