import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	InconsistentReference,
	InconsistentReferenceTitle,
} from "../../../../../../../../../components/Common/InconsistentReferenceTooltip/InconsistentReference";
import { ReactComponent as CommentSVG } from "../../../../../../../../../images/svg/bills/comment.svg";
import { ReactComponent as ClientLogo } from "../../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as PriceSVG } from "../../../../../../../../../images/svg/bills/price.svg";
import { PosReportPaidApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	id: number;
	contextId: string;
	inconsistent_reference_id: boolean;
}

const TransactionReportPaidCard = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [reportPaid, setReportPaid] = useState<undefined | PosReportPaidApi>(undefined);
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();

	useEffect(() => {
		onFetchPosReportPaid();
	}, []);

	const onFetchPosReportPaid = async () => {
		try {
			const reportPaidParams: Record<string, any> = { include: "client" };
			const res = await api.organization().getPosReportPaid(props.id, reportPaidParams);
			setReportPaid(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!reportPaid) {
		return <LoadingContainer />;
	}

	return (
		<Card>
			<Card.Body>
				<InconsistentReferenceTitle
					titleText={`${
						reportPaid.is_paid_in
							? t("modules.transaction.field.report_paid.title")
							: t("modules.transaction.field.report_paid_out.title")
					} #${props.contextId}`}
					inconsistentReferenceId={props.inconsistent_reference_id}
				/>
				{reportPaid.employee && (
					<div className="card-body-item">
						<ClientLogo />
						<div className="item-info">
							<span className="label">{t("modules.transaction.field.adding_person.title")}</span>
							<span>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/employees/${reportPaid.employee.id}`}
								>
									{reportPaid.employee.name}
								</Link>
							</span>
						</div>
					</div>
				)}
				{reportPaid.amount.amount && (
					<div className="card-body-item">
						<PriceSVG />
						<div className="item-info">
							<span className="label">{t("common.word.amount")}</span>
							<span>{FormatMoney(reportPaid.amount)}</span>
						</div>
					</div>
				)}
				{reportPaid.client && (
					<div className="card-body-item">
						<ClientLogo />
						<div className="item-info">
							<span className="label">{t("common.word.client")}</span>
							<span>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/clients/${reportPaid.client.id}`}
								>
									{reportPaid.client.name}
								</Link>
							</span>
						</div>
					</div>
				)}
				{reportPaid.description && (
					<div className="card-body-item">
						<CommentSVG />
						<div className="item-info">
							<span className="label">{t("lib:common.word.description")}</span>
							<span>{reportPaid.description}</span>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default TransactionReportPaidCard;
