import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { PrintoutTemplateApi } from "../../../../../../../../../../services/Api/Organization/types";
import PrintoutTemplateForm from "../../components/PrintoutTemplateForm";

const OrganizationSettingsPrintoutTemplatesNewPage = (): JSX.Element => {
	const [printoutTemplate] = useState<PrintoutTemplateApi | undefined>({
		text_size: "SMALL",
		items_sort: "ALPHABETICAL",
	} as PrintoutTemplateApi);
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.printout_template.header.new.title"));
	}, []);

	if (!printoutTemplate) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Header title={t("modules.printout_template.header.new.title")} />
			<PrintoutTemplateForm printoutTemplate={printoutTemplate} />
		</>
	);
};

export default OrganizationSettingsPrintoutTemplatesNewPage;
