import { AxiosResponse } from "axios";
import { MessageEventApi } from "go-component/components/MessageEvent/services/types";
import { parseApiData } from "go-core/api/parseApiData";
import { formatDateTimeToString } from "go-core/components/Formatters/FormatDate";
import { restSecurityApi } from "go-security/services/Api/api";
import { getSearchSelectPage, mapToBase } from "go-security/services/Api/searchSelectService";
import OrganizationIdService from "go-security/services/organizationIdService";
import { CustomFieldApi, CustomFieldTemplateApi } from "go-segment/services/types";
import { CoordinatesApi, GetCoordinatesApi, MigrationFormProps, SearchSelectApi } from "../types";
import {
	AppLinkApi,
	ApplicationApi,
	ApplicationTemplateApi,
	ApplicationUpdateApi,
	AssignEmployeeDriverFormProps,
	AuthorizeApplicationApi,
	CategoryApi,
	CategoryConfigApi,
	ClearVenueCustomFormProps,
	ClearVenueCustomPermanentlyFormProps,
	ClientApi,
	ClientGroupApi,
	CompanyInfoApi,
	CopyPlaceApi,
	CsvAttributeApi,
	CurrencyApi,
	DashboardAlertApi,
	DashboardApi,
	DashboardReportsApi,
	DefaultCommentApi,
	DeliveryEmployeeApi,
	DeliveryEstimationApi,
	DeliveryZoneApi,
	DeviceApi,
	DirectionApi,
	DiscountApi,
	EmployeeActivityApi,
	EmployeeApi,
	EmployeeInfoApi,
	EmployeeTransactionApi,
	EntityConfigApi,
	ExternalReportApi,
	FiscalizeOrderFormProps,
	GusApi,
	InvoiceApi,
	InvoiceDetailsApi,
	ItemApi,
	ItemAppLinkApi,
	ItemFormApi,
	ItemGroupApi,
	ItemGroupConfigApi,
	ItemSuspensionApi,
	ItemTranslationListFormProps,
	ItemTranslationNameListResponseApi,
	JPKInvoiceApi,
	JPKInvoiceV7Api,
	JPK_FAInvoiceApi,
	LiveOrderAcceptanceFormApi,
	LiveOrderApi,
	LiveOrderRejectionFormApi,
	MenuApi,
	MenuPriceListApi,
	MenuPriceListFlatResponseApi,
	ModifierGroupApi,
	NotificationTemplateApi,
	OrderApi,
	OrderPreparationStatusName,
	OrderSearchSelectApi,
	OrganizationAddressApi,
	OrganizationInfoApi,
	POSReportSearchSelectApi,
	PatchOrderApi,
	PaymentMethodApi,
	PaymentMethodSearchSelectApi,
	PointOfSaleApi,
	PosAccessApi,
	PosReporCheckBeforeDeletionApi,
	PosReportApi,
	PosReportPaidApi,
	PosReportSettingsApi,
	PreviewNotificationTemplateApi,
	PriceListPredicateApi,
	PrintoutTemplateApi,
	RecalculateWorkTimesFormProps,
	ReceiptApi,
	ReservationApi,
	RoomApi,
	RoomTableSearchSelectApi,
	RoomsUpdate,
	SpreadsheetPriceListFormProps,
	TagApi,
	TaxApi,
	TerminalApi,
	TerminalType,
	TransactionApi,
	UpdateOrderApi,
	UpdatePriceListPredicateApi,
	UpdateTransactionApi,
	VenueSettingsApi,
	WorkTimeApi,
	WorkplaceApi,
} from "./types";

class apiOrganization {
	static getOrganizationId = () => {
		return OrganizationIdService.getWithCheck();
	};

	static authorizeApplication(orgId: number, uri: string): Promise<AuthorizeApplicationApi> {
		return parseApiData(restSecurityApi.post(`/authorize?organization_id=${orgId}&redirect_uri=${uri}`));
	}

	static installApps(
		provider: string,
		data?: ApplicationUpdateApi,
		params?: Record<string, any>
	): Promise<ApplicationApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/apps/${provider}/install`, data, { params })
		);
	}

	static getAppsConfig(appId: number, options?: Record<string, any>): Promise<Record<string, any>> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/${appId}/config`, {
				...options,
			})
		);
	}

	static installApplicationPost(provider: string, token: string, data?: ApplicationApi): Promise<ApplicationApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/apps/${provider}/install?access_token=${token}`, data)
		);
	}

	static installApplication(provider: string, token: string): void {
		window.location.href = `${
			restSecurityApi.defaults.baseURL
		}${this.getOrganizationId()}/apps/${provider}/install?access_token=${token}`;
	}

	static reinstallApplication(app: ApplicationApi, token: string): void {
		window.location.href = `${restSecurityApi.defaults.baseURL}${this.getOrganizationId()}/apps/${
			app.id
		}/install?provider=${app.provider.toUpperCase()}&access_token=${token}`;
	}

	static checkMigration(): Promise<AxiosResponse> {
		return restSecurityApi.get(`/${this.getOrganizationId()}/migration`);
	}

	static cancelMigration(): Promise<{}> {
		return parseApiData(restSecurityApi.post(`/${this.getOrganizationId()}/migration/cancel`));
	}

	static checkMigrationTypes(): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`/${this.getOrganizationId()}/migration/types`));
	}

	static getOrganizationAddress(): Promise<OrganizationAddressApi> {
		return parseApiData(restSecurityApi.get(`/${this.getOrganizationId()}/address`));
	}

	static getPriceListsSearchSelect(
		t: string,
		obj: Record<string, any>,
		externalOrg?: string,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(
				`${
					externalOrg ? externalOrg : this.getOrganizationId()
				}/menu/price_lists/search_select?f=${f}&page=${page}`,
				{
					cancelToken: options?.cancelToken,
				}
			)
		);
	}

	static getMigrationOrganizationsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(
				`/${this.getOrganizationId()}/migration/organizations/search_select?f=${f}&page=${page}`,
				{
					cancelToken: options?.cancelToken,
				}
			)
		);
	}

	static getEntityConfig(cfResourceType?: string): Promise<EntityConfigApi> {
		return parseApiData(
			restSecurityApi.get(`/${this.getOrganizationId()}/config?custom_field_resource_type=${cfResourceType}`)
		);
	}

	static migrate(data: MigrationFormProps): Promise<AxiosResponse> {
		return restSecurityApi.post(`/${this.getOrganizationId()}/migration`, data);
	}

	static finishMigration(data: MigrationFormProps): Promise<AxiosResponse> {
		return restSecurityApi.post(`/${this.getOrganizationId()}/migration/finish`, data);
	}

	static getLinks(params?: Record<string, any>, options?: Record<string, any>): Promise<AppLinkApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/app_links`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getItemLinks(params?: Record<string, any>, options?: Record<string, any>): Promise<ItemAppLinkApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/items/app_links`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static skipEntity(id: number, params?: Record<string, any>): Promise<AppLinkApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/app_links/${id}/skip`, undefined, { params })
		);
	}

	static createByApp(id: number, params?: Record<string, any>): Promise<AppLinkApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/app_links/${id}/create_by_app`, undefined, { params })
		);
	}

	static linkEntity(id: number, data: AppLinkApi, params?: Record<string, any>): Promise<AppLinkApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/app_links/${id}/link`, data, { params }));
	}

	static getAppLinkResourceTypes(): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/app_links/resource_types`));
	}

	static getGoOrderStoresSearchSelect(
		appId: number,
		obj: Record<string, any>,
		t: string,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/${appId}/stores/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getAppLogs(params?: Record<string, any>, options?: Record<string, any>): Promise<MessageEventApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/app_logs`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getApps(params?: Record<string, any>): Promise<ApplicationApi[]> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/apps`, { params }));
	}

	static getAppTemplates(): Promise<ApplicationTemplateApi[]> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/app_templates`));
	}

	static getAppTemplate(id: number): Promise<ApplicationTemplateApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/app_templates/${id}`));
	}

	static getAppsLocations(search: string, params: Record<string, any>): Promise<SearchSelectApi[]> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/LOCATIONS/organizations?f=${f}&page=${page}`)
		);
	}

	static getRolesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/venue_roles/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getAppsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getApp(appId: number, params?: Record<string, any>, options?: Record<string, any>): Promise<ApplicationApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/${appId}`, {
				params,
				...options,
			})
		);
	}

	static exportMenu(appId: number, params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/apps/${appId}/export_menu_async`, undefined, {
				params,
				...options,
			})
		);
	}

	static getExportMenuProgress(
		appId: number,
		jobId: string,
		options?: Record<string, any>
	): Promise<{
		job_status: string;
		job_uid: string;
	}> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/${appId}/export_menu/progress/${jobId}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateApp(data: ApplicationUpdateApi, params?: Record<string, any>): Promise<ApplicationApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/apps/${data.id}`, data, { params }));
	}

	static removeApp(data: ApplicationApi): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/apps/${data.id}`));
	}

	static syncApp(data: ApplicationApi): Promise<{}> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/apps/${data.id}/sync`));
	}

	static syncApps(): Promise<ApplicationApi[]> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/apps/sync`));
	}

	static enableApp(data: ApplicationApi, params?: Record<string, any>): Promise<ApplicationApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/apps/${data.id}/enable`, undefined, { params })
		);
	}

	static resetApp(data: ApplicationApi, params?: Record<string, any>): Promise<ApplicationApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/apps/${data.id}/reset`, undefined, { params })
		);
	}

	static deactivateApp(data: ApplicationApi, params?: Record<string, any>): Promise<ApplicationApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/apps/${data.id}/disable`, undefined, { params })
		);
	}

	static getOrganizationInfo(): Promise<OrganizationInfoApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/company_basic`));
	}

	static saveOrganizationBasicInfo(data: OrganizationInfoApi): Promise<OrganizationInfoApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/new_company_basic`, data));
	}

	static getReceiptSettings(): Promise<ReceiptApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/receipt`));
	}

	static updateReceiptSettings(data: ReceiptApi): Promise<ReceiptApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/receipt`, data));
	}

	static copyPlace(data: CopyPlaceApi): Promise<AxiosResponse> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/copy_place`, data));
	}

	static copyTestPlace(data: CopyPlaceApi): Promise<AxiosResponse> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/copy_test_place`, data));
	}

	static getOrganizationsToCopySearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/copy_place/organizations?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPrintoutTemplatesSettings(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<PrintoutTemplateApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/printout_templates`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPrintoutTemplatesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/printout_templates/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPrintoutTemplateSettings(id: number, params: Record<string, string>): Promise<PrintoutTemplateApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/printout_templates/${id}`, { params }));
	}

	static updatePrintoutTemplateSettings(
		data: PrintoutTemplateApi,
		params: Record<string, string>
	): Promise<PrintoutTemplateApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/printout_templates/${data.id}`, data, { params })
		);
	}

	static createPrintoutTemplateSettings(data: PrintoutTemplateApi): Promise<PrintoutTemplateApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/printout_templates`, data));
	}

	static removePrintoutTemplate(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/printout_templates/${id}`));
	}

	static restorePrintoutTemplate(id: number, params: Record<string, string>): Promise<PrintoutTemplateApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/printout_templates/${id}/restore`, { params })
		);
	}

	static getVenueSettings(): Promise<VenueSettingsApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/venue_settings`));
	}

	static updateVenueSettings(settings: VenueSettingsApi): Promise<VenueSettingsApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/venue_settings`, settings));
	}

	static getVenueSettingsWeightBarcodes(): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/venue_settings/weight_barcodes/prefixes`));
	}

	static getCompanyInfo(): Promise<CompanyInfoApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/company`));
	}

	static saveCompanyInfo(data: CompanyInfoApi): Promise<CompanyInfoApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/company`, data));
	}

	static savePosAccess(data: PosAccessApi): Promise<PosAccessApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/pos_access`, data));
	}

	static authorizePosAccess(): Promise<PosAccessApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/display_pos_access`));
	}

	static getPosAccess(): Promise<PosAccessApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/pos_access`));
	}

	static deletePosAccess(password: string): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/pos_access?password=${password}`));
	}

	static getPaymentMethods(params?: Record<string, any>, options?: Record<string, any>): Promise<PaymentMethodApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/payment_methods`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPaymentMethod(paymentMethodId: number, params?: Record<string, any>): Promise<PaymentMethodApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/payment_methods/${paymentMethodId}`, {
				params,
			})
		);
	}

	static removePaymentMethod(paymentMethodId: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/payment_methods/${paymentMethodId}`));
	}

	static restorePaymentMethod(paymentMethodId: number): Promise<PaymentMethodApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/payment_methods/${paymentMethodId}/restore`)
		);
	}

	static disablePaymentMethod(paymentMethodId: number): Promise<PaymentMethodApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/payment_methods/${paymentMethodId}/disable`)
		);
	}

	static updatePaymentMethod(
		paymentMethod: PaymentMethodApi,
		params?: Record<string, any>
	): Promise<PaymentMethodApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/payment_methods/${paymentMethod.id}`, paymentMethod, {
				params,
			})
		);
	}

	static createPaymentMethod(paymentMethod: PaymentMethodApi): Promise<PaymentMethodApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/payment_methods`, paymentMethod));
	}

	static getTags(params?: Record<string, any>, options?: Record<string, any>): Promise<TagApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/tags`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getTag(tagId: number, params?: Record<string, any>): Promise<TagApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/tags/${tagId}`, { params }));
	}

	static removeTag(tagId: number): Promise<TagApi> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/tags/${tagId}`));
	}

	static updateTag(tag: TagApi): Promise<TagApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/tags/${tag.id}`, tag));
	}

	static createTag(tag: TagApi): Promise<TagApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/tags`, tag));
	}

	static getTaxesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/taxes/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getTaxes(params?: Record<string, any>, options?: Record<string, any>): Promise<TaxApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/taxes`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createOrUpdateTax(tax: TaxApi): Promise<TaxApi> {
		if (tax.id) {
			return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/taxes/${tax.id}`, tax));
		}
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/taxes`, tax));
	}

	static removeTax(taxId: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/taxes/${taxId}`));
	}

	static restoreTax(taxId: number): Promise<TaxApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/taxes/${taxId}/restore`));
	}

	static getCurrencies(params?: Record<string, any>, options?: Record<string, any>): Promise<CurrencyApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/currencies`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createOrUpdateCurrency(currency: CurrencyApi): Promise<CurrencyApi> {
		if (currency.id) {
			return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/currencies/${currency.id}`, currency));
		}
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/currencies`, currency));
	}

	static removeCurrency(currencyId: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/currencies/${currencyId}`));
	}

	static getDefaultComments(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<DefaultCommentApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/default_comments`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createOrUpdateDefaultComment(defaultComment: DefaultCommentApi): Promise<DefaultCommentApi> {
		if (defaultComment.id) {
			return parseApiData(
				restSecurityApi.put(`${this.getOrganizationId()}/default_comments/${defaultComment.id}`, defaultComment)
			);
		}
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/default_comments`, defaultComment));
	}

	static removeDefaultComment(defaultCommentId: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/default_comments/${defaultCommentId}`));
	}

	static restoreDefaultComment(defaultCommentId: number): Promise<DefaultCommentApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/default_comments/${defaultCommentId}/restore`)
		);
	}

	static getDiscountsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/discounts/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getClientGroupsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/client_groups/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getListDiscountsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/discounts/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getDirectionsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/directions/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getWorkplacesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/workplaces/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getAvailabilitiesSearchSelect(
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/availabilities/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPaymentMethodsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>,
		isNotInfinite?: boolean
	): Promise<PaymentMethodSearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		let path = `${this.getOrganizationId()}/payment_methods/search_select`;
		if (!isNotInfinite) path += `?f=${f}&page=${page}`;

		return parseApiData(
			restSecurityApi.get(path, {
				params: isNotInfinite ? obj : undefined,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getDirections(params?: Record<string, any>, options?: Record<string, any>): Promise<DirectionApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/directions`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createDirection(direction: DirectionApi, params?: Record<string, any>): Promise<DirectionApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/directions`, direction, { params }));
	}

	static updateDirection(direction: DirectionApi, params?: Record<string, any>): Promise<DirectionApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/directions/${direction.id}`, direction, { params })
		);
	}

	static removeDirection(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/directions/${id}`));
	}

	static restoreDirection(id: number): Promise<DirectionApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/directions/${id}/restore`));
	}

	static getPointOfSales(params?: Record<string, any>, options?: Record<string, any>): Promise<PointOfSaleApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/points_of_sale`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getTablesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/rooms/tables/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static restorePointOfSale(id: number): Promise<PointOfSaleApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/points_of_sale/${id}/restore`));
	}

	static getPointsOfSaleSearchSelect(
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/points_of_sale/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createPointOfSale(pointOfSale: PointOfSaleApi, params?: Record<string, any>): Promise<PointOfSaleApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/points_of_sale`, pointOfSale, { params })
		);
	}

	static updatePointOfSale(pointOfSale: PointOfSaleApi, params?: Record<string, any>): Promise<PointOfSaleApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/points_of_sale/${pointOfSale.id}`, pointOfSale, { params })
		);
	}

	static removePointOfSale(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/points_of_sale/${id}`));
	}

	static getRooms(params?: Record<string, any>, options?: Record<string, any>): Promise<RoomApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/rooms`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getRoomTablesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<RoomTableSearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/rooms/tables/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getRoomsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/rooms/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getRoom(id: number): Promise<RoomApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/rooms/${id}`));
	}

	static updateRooms(rooms: RoomsUpdate, params?: Record<string, string>): Promise<RoomApi[]> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/rooms`, rooms, { params }));
	}

	static createDevice(data: DeviceApi, params?: Record<string, any>): Promise<DeviceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/devices`, data, { params }));
	}

	static updateDevice(data: DeviceApi, params?: Record<string, any>): Promise<DeviceApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/devices/${data.id}`, data, { params }));
	}

	static getDevices(params?: Record<string, any>, options?: Record<string, any>): Promise<DeviceApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/devices`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getDevicesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/devices/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getDevice(id: number, params?: Record<string, any>): Promise<DeviceApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/devices/${id}`, { params }));
	}

	static removeDevice(id: number, params?: Record<string, any>): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/devices/${id}`, { params }));
	}

	static restoreDevice(id: number, params?: Record<string, any>): Promise<DeviceApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/devices/${id}/restore`, undefined, { params })
		);
	}

	static getTerminals(params?: Record<string, any>, options?: Record<string, any>): Promise<TerminalApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/terminals`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateTerminalName(
		id: number,
		data: { web_name: string },
		params?: Record<string, any>
	): Promise<TerminalApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/terminals/${id}`, data, { params }));
	}

	static changeTerminalType(id: number, type: TerminalType, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(restSecurityApi.patch(`${this.getOrganizationId()}/terminals/${id}`, { type }, { params }));
	}

	static terminalSendDiagnosticReport(id: number): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/terminals/${id}/send_diagnostic_report`, undefined)
		);
	}

	static getTerminalsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>,
		alreadyUsedTerminalIds?: string,
		isNotInfinite?: boolean
	): Promise<SearchSelectApi[]> {
		let f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);

		let path = `${this.getOrganizationId()}/terminals/search_select`;
		let fParamDecoded = decodeURIComponent(escape(atob(f)));
		if (alreadyUsedTerminalIds) {
			fParamDecoded += `uid|ne=${alreadyUsedTerminalIds}`;
		}
		f = btoa(unescape(encodeURIComponent(fParamDecoded)));
		if (!isNotInfinite) path += `?f=${f}&page=${page}`;

		return parseApiData(
			restSecurityApi.get(path, {
				params: isNotInfinite ? obj : undefined,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getTerminal(id: number, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/terminals/${id}`, { params }));
	}

	static releaseTerminal(id: number, params?: Record<string, any>): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/terminals/${id}/release`, undefined, { params })
		);
	}

	static disableTerminal(id: number, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/terminals/${id}/disable`, undefined, { params })
		);
	}

	static enableTerminal(id: number, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/terminals/${id}/enable`, undefined, { params })
		);
	}

	static disableTerminalMaster(id: number, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/terminals/${id}/disable_master`, undefined, { params })
		);
	}

	static enableTerminalMaster(id: number, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/terminals/${id}/enable_master`, undefined, { params })
		);
	}

	static exportTerminalDatabase(id: number, params?: Record<string, any>): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/terminals/${id}/export_database`, undefined, { params })
		);
	}

	static clearTerminalName(id: number, params?: Record<string, any>): Promise<TerminalApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/terminals/${id}/clear_name`, undefined, { params })
		);
	}

	static updateCustomField(client: CustomFieldTemplateApi, params?: Record<string, any>): Promise<CustomFieldApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/custom_fields/${client.id}`, client, { params })
		);
	}

	static createCustomField(client: CustomFieldTemplateApi): Promise<CustomFieldApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/custom_fields`, client));
	}

	static removeCustomField(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/custom_fields/${id}`));
	}

	static restoreCustomField(id: number): Promise<CustomFieldTemplateApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/custom_fields/${id}/restore`));
	}

	static getCustomField(id: number, params?: Record<string, any>): Promise<CustomFieldTemplateApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/custom_fields/${id}`, { params }));
	}

	static getCustomFields(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<CustomFieldTemplateApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/custom_fields`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getCustomFieldTemplateOptionsSearchSelect(
		id: number | string,
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(
				`${this.getOrganizationId()}/app_links/${id}/search_select_resources?f=${f}&page=${page}`,
				{
					cancelToken: options?.cancelToken,
				}
			)
		);
	}

	static getTagsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/tags/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static async getCustomFieldsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		const res = await parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/custom_fields/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
		return res.map((x: { slug: string }) => {
			return {
				...x,
				id: x.slug,
			};
		});
	}

	static getCustomFieldsTemplateSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/custom_fields/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getSegmentsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/segments/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateClient(client: ClientApi, params?: Record<string, any>): Promise<ClientApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/clients/${client.id}`, client, { params })
		);
	}

	static createClient(client: ClientApi): Promise<ClientApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/clients`, client));
	}

	static removeClient(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/clients/${id}`));
	}

	static patchClients(data: Record<string, any>, params?: Record<string, any>): Promise<ClientApi[]> {
		return parseApiData(restSecurityApi.patch(`${this.getOrganizationId()}/clients/`, data, { params }));
	}

	static removeClients(clientIds: string, params?: Record<string, any>, areAllItemsSelected?: boolean): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/clients`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${clientIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static getClient(id: number, params?: Record<string, any>): Promise<ClientApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/clients/${id}`, { params }));
	}

	static getClientsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/clients/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getClients(params?: Record<string, any>, options?: Record<string, any>): Promise<ClientApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/clients`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getClientDataByTaxIdNo(tax_id_no: string): Promise<GusApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/clients/update_by_tax_id_no?tax_id_no=${tax_id_no}`)
		);
	}

	static updateClientGroup(clientGroup: ClientGroupApi): Promise<ClientGroupApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/client_groups/${clientGroup.id}`, clientGroup)
		);
	}

	static createClientGroup(clientGroup: ClientGroupApi): Promise<ClientGroupApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/client_groups`, clientGroup));
	}

	static removeClientGroup(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/client_groups/${id}`));
	}

	static restoreClientGroup(id: number): Promise<ClientGroupApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/client_groups/${id}/restore`));
	}

	static getClientGroup(id: number, params?: Record<string, any>): Promise<ClientGroupApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/client_groups/${id}`, { params }));
	}

	static getClientGroups(params?: Record<string, any>, options?: Record<string, any>): Promise<ClientGroupApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/client_groups`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getClientsCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/clients/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateWorkplace(workplace: WorkplaceApi): Promise<WorkplaceApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/workplaces/${workplace.id}`, workplace));
	}

	static createWorkplace(workplace: WorkplaceApi): Promise<WorkplaceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/workplaces`, workplace));
	}

	static removeWorkplace(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/workplaces/${id}`));
	}

	static restoreWorkplace(id: number): Promise<WorkplaceApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/workplaces/${id}/restore`));
	}

	static getWorkplaces(params?: Record<string, any>, options?: Record<string, any>): Promise<WorkplaceApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/workplaces`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getEmployeesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/employees/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateEmployee(employee: EmployeeApi, params?: Record<string, any>): Promise<EmployeeApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/employees/${employee.id}`, employee, { params })
		);
	}

	static createEmployee(employee: EmployeeApi): Promise<EmployeeApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/employees`, employee));
	}

	static removeEmployee(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/employees/${id}`));
	}

	static fireEmployee(id: number, params?: Record<string, any>): Promise<EmployeeApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/employees/${id}/fire`, undefined, { params })
		);
	}

	static hireEmployee(id: number, params?: Record<string, any>): Promise<EmployeeApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/employees/${id}/hire`, undefined, { params })
		);
	}

	static getEmployee(id: number, params?: Record<string, any>): Promise<EmployeeApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/employees/${id}`, { params }));
	}

	static getEmployees(params?: Record<string, any>, options?: Record<string, any>): Promise<EmployeeApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/employees`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getEmployeeTransactions(id: number, params?: Record<string, any>): Promise<EmployeeTransactionApi[]> {
		return parseApiData(
			restSecurityApi.get(`/${this.getOrganizationId()}/employees/${id}/transactions`, { params })
		);
	}

	static fireEmployees(
		employeeIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<EmployeeApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/employees/fire`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${employeeIds}&${urlParams}`;

		return parseApiData(restSecurityApi.post(endpoint));
	}

	static hireEmployees(
		employeeIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<EmployeeApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/employees/hire`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${employeeIds}&${urlParams}`;

		return parseApiData(restSecurityApi.post(endpoint));
	}

	static removeEmployees(
		employeeIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/employees`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${employeeIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static getEmployeeActivities(id: number, params?: Record<string, any>): Promise<EmployeeActivityApi[]> {
		return parseApiData(restSecurityApi.get(`/${this.getOrganizationId()}/employees/${id}/activities`, { params }));
	}

	static getEmployeeInfo(id: number, params?: Record<string, any>): Promise<EmployeeInfoApi> {
		return parseApiData(restSecurityApi.get(`/${this.getOrganizationId()}/employees/${id}/info`, { params }));
	}

	static getEmployeeCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/employees/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateDiscount(discount: DiscountApi, params?: Record<string, any>): Promise<DiscountApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/discounts/${discount.id}`, discount, { params })
		);
	}

	static createDiscount(discount: DiscountApi): Promise<DiscountApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/menu/discounts`, discount));
	}

	static removeDiscount(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/menu/discounts/${id}`));
	}

	static restoreDiscount(id: number, params: Record<string, any>): Promise<DiscountApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/discounts/${id}/restore`, undefined, {
				params,
			})
		);
	}

	static disableDiscount(discount: DiscountApi): Promise<DiscountApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/discounts/${discount.id}/disable`));
	}

	static getDiscount(id: number, params?: Record<string, any>): Promise<DiscountApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/discounts/${id}`, { params }));
	}

	static getDiscounts(params?: Record<string, any>, options?: Record<string, any>): Promise<DiscountApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/discounts`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static removeDiscounts(
		discountIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/discounts`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${discountIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static restoreDiscounts(
		discountIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/discounts/restore`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${discountIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static disableDiscounts(
		discountIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/discounts/disable`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${discountIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static getDiscountsCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/discounts/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getMenusSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/menus/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static setDefaultMenu(id: number): Promise<MenuApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/menus/${id}/set_as_default`));
	}

	static getMenus(params?: Record<string, any>, options?: Record<string, any>): Promise<MenuApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/menus`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateMenu(menu: MenuApi, params?: Record<string, any>): Promise<MenuApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/menus/${menu.id}`, menu, { params }));
	}

	static createMenu(menu: MenuApi, params?: Record<string, any>): Promise<MenuApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/menu/menus`, menu, { params }));
	}

	static removeMenu(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/menu/menus/${id}`));
	}

	static updateItemGroup(product: ItemGroupApi, params?: Record<string, any>): Promise<ItemGroupApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/item_groups/${product.id}`, product, { params })
		);
	}

	static createItemGroup(product: ItemGroupApi, params?: Record<string, any>): Promise<ItemGroupApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/menu/item_groups`, product, { params }));
	}

	static removeItemGroup(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/menu/item_groups/${id}`));
	}

	static restoreItemGroup(id: number, params?: Record<string, any>): Promise<ItemGroupApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/item_groups/${id}/restore`, null, { params })
		);
	}

	static getItemGroupConfig(): Promise<ItemGroupConfigApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/item_groups/info`));
	}

	static getItemGroup(id: number, params?: Record<string, any>): Promise<ItemGroupApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/item_groups/${id}`, { params }));
	}

	static getItemGroups(params?: Record<string, any>, options?: Record<string, any>): Promise<ItemGroupApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/item_groups`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getItemGroupsCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/item_groups/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static disableItemGroup(
		id: number,
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<ItemGroupApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/item_groups/${id}/disable`, undefined, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static removeItemGroups(
		itemGroupIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/item_groups`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${itemGroupIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static restoreItemGroups(
		itemGroupIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ItemGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/item_groups/restore`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${itemGroupIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static disableItemGroups(
		itemGroupIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ItemGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/item_groups/disable`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${itemGroupIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static patchItemGroups(data: Record<string, any>, params?: Record<string, any>): Promise<ItemGroupApi[]> {
		return parseApiData(restSecurityApi.patch(`${this.getOrganizationId()}/menu/item_groups`, data, { params }));
	}

	static getItemGroupsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/item_groups/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getItemsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const params = { include: "gtu,tax" };
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/items/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
				params,
			})
		);
	}

	static getItems(params?: Record<string, any>, options?: Record<string, any>): Promise<ItemApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/items`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateItems(
		data: { items: ItemFormApi[] },
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<{}> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/items`, data, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static blockItem(itemId: number, data?: ItemSuspensionApi, params?: Record<string, any>): Promise<ItemApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/items/suspend/${itemId}`, data, { params })
		);
	}

	static unblockItem(itemId: number, params?: Record<string, any>): Promise<ItemApi> {
		return parseApiData(
			restSecurityApi.delete(`${this.getOrganizationId()}/menu/items/suspend/${itemId}`, { params })
		);
	}

	static getCategoryConfig(): Promise<CategoryConfigApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/categories/info`));
	}

	static updateCategory(category: CategoryApi, params?: Record<string, any>): Promise<CategoryApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/categories/${category.id}`, category, { params })
		);
	}

	static createCategory(Category: CategoryApi): Promise<CategoryApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/menu/categories`, Category));
	}

	static removeCategory(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/menu/categories/${id}`));
	}

	static restoreCategory(id: number): Promise<CategoryApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/categories/${id}/restore`));
	}

	static disableCategory(id: number): Promise<CategoryApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/categories/${id}/disable`));
	}

	static getCategory(id: number, params?: Record<string, any>): Promise<CategoryApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/categories/${id}`, { params }));
	}

	static getCategories(params?: Record<string, any>, options?: Record<string, any>): Promise<CategoryApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/categories`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static removeCategories(
		categoryIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/categories`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${categoryIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static restoreCategories(
		categoryIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<CategoryApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/categories/restore`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${categoryIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static disableCategories(
		categoryIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<CategoryApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/categories/disable`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${categoryIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static getCategoriesCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/categories/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getNextPosReport(id: number | string): Promise<number | string | null> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/${id}/next`));
	}

	static getPreviousPosReport(id: number | string): Promise<number | string | null> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/${id}/previous`));
	}

	static downloadPosReport(id: number): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/${id}/pdf`, {
			responseType: "blob",
		});
	}

	static downloadPosReportAdvanced(id: number, data: PosReportSettingsApi): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/${id}/pdf`, {
			responseType: "blob",
			params: data,
		});
	}

	static getPosPdfSettings(): Promise<PosReportSettingsApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/pdf_settings`));
	}

	static updatePosPdfSettings(data: PosReportSettingsApi): Promise<PosReportSettingsApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/pos_reports/pdf_settings`, data));
	}

	static closePosReport(id: number, params?: Record<string, string>): Promise<PosReportApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/pos_reports/${id}/close`, undefined, { params })
		);
	}

	static getPosReports(params?: Record<string, any>, options?: Record<string, any>): Promise<PosReportApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/pos_reports`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPosReportsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>,
		queryInFilter = false
	): Promise<POSReportSearchSelectApi[]> {
		let f;
		const page = getSearchSelectPage(obj);

		if (queryInFilter) f = btoa(unescape(encodeURIComponent(obj.f)));
		else f = mapToBase(t, obj);

		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPosReport(id: number, params?: Record<string, any>): Promise<PosReportApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/${id}`, { params }));
	}

	static sendPosReportByEmail(id: number, data: PosReportSettingsApi, email: string): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(
				`${this.getOrganizationId()}/pos_reports/${id}/pdf_via_mail`,
				{ email },
				{ params: data }
			)
		);
	}

	static updatePosReportPaid(data: PosReportPaidApi, reportId: number): Promise<PosReportPaidApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/pos_reports/${reportId}/paids/${data.id}`, data)
		);
	}

	static createPosReportPaid(data: PosReportPaidApi, reportId: number): Promise<PosReportPaidApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/pos_reports/${reportId}/paids`, data));
	}

	static getPosReportPaid(id: number, params?: Record<string, any>): Promise<PosReportPaidApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/paids/${id}`, { params }));
	}

	static getPosReportPDF(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/pdf`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getPosReportCSV(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/pos_reports/csv`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getTransactions(params?: Record<string, any>, options?: Record<string, any>): Promise<TransactionApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/transactions`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getTransaction(id: number, params?: Record<string, any>): Promise<TransactionApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/transactions/${id}`, { params }));
	}

	static updateTransaction(
		id: number,
		data: UpdateTransactionApi,
		params?: Record<string, any>
	): Promise<TransactionApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/transactions/${id}`, data, { params }));
	}

	static getTransactionsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/transactions/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getTransactionPDF(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/transactions/pdf`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getTransactionCSV(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/transactions/csv`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getKdsReport(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/reports/preparation_status_changes`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getKdsOrdersReport(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/reports/preparation_status_changes/orders`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getStornReport(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/storno_report`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getProductPricesReport(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/item_price_histories/report`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getTransactionsReport(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/transactions/report`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static rebuildReport(requestBody: { type: string }): Promise<{}> {
		return restSecurityApi.post(`${this.getOrganizationId()}/reports/rebuild`, requestBody);
	}

	static getDepartmentReport(params: Record<string, any>): Promise<{}> {
		return restSecurityApi.get(`${this.getOrganizationId()}/reports/department`, { params });
	}

	static removeOrders(password: string): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/orders?password=${password}`));
	}

	static getOrders(params?: Record<string, any>, options?: Record<string, any>): Promise<OrderApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/orders`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateOrderTransactions(
		orderId: number,
		data: UpdateTransactionApi,
		params?: Record<string, any>
	): Promise<TransactionApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/orders/${orderId}/transactions`, data, { params })
		);
	}

	static releaseOrder(orderId: number): Promise<TransactionApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/orders/${orderId}/release`));
	}

	static estimateOrderDelivery(
		orderId: number | string,
		appProvider: string,
		timeInMinutes: number,
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<DeliveryEstimationApi> {
		return parseApiData(
			restSecurityApi.get(
				`${this.getOrganizationId()}/orders/${orderId}/delivery_estimates?app_provider=${appProvider}&delivery_pickup_in_minutes=${timeInMinutes}`,
				{
					params,
					cancelToken: options?.cancelToken,
				}
			)
		);
	}

	static getLiveOrders(params?: Record<string, any>, options?: Record<string, any>): Promise<LiveOrderApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/orders/live_orders`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getOrdersDeliveryZones(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<DeliveryZoneApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/orders/delivery_zones`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static acceptLiveOrder(
		orderId: number | string,
		data?: LiveOrderAcceptanceFormApi,
		params?: Record<string, any>
	): Promise<LiveOrderApi> {
		let dataParam = "";
		if (data) {
			dataParam = data?.pickup_at
				? `pickup_at=${data.pickup_at}`
				: `estimated_preparation_at=${data.estimated_preparation_at}`;
		}

		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/orders/${orderId}/accept?${dataParam}`, undefined, {
				params,
			})
		);
	}

	static rejectLiveOrder(
		orderId: number | string,
		data: LiveOrderRejectionFormApi,
		params?: Record<string, any>
	): Promise<LiveOrderApi> {
		return parseApiData(
			restSecurityApi.put(
				`${this.getOrganizationId()}/orders/${orderId}/reject?reason=${data.reason}`,
				undefined,
				{ params }
			)
		);
	}

	static getOrder(id: number | string, params?: Record<string, any>): Promise<OrderApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/orders/${id}`, { params }));
	}

	static updateOrder(id: number | string, data: UpdateOrderApi, params?: Record<string, any>): Promise<OrderApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/orders/${id}`, data, { params }));
	}

	static patchOrder(id: number | string, data: PatchOrderApi, params?: Record<string, any>): Promise<OrderApi> {
		return parseApiData(restSecurityApi.patch(`${this.getOrganizationId()}/orders/${id}`, data, { params }));
	}

	static fiscalizeOrder(id: number, data: FiscalizeOrderFormProps, params?: Record<string, any>): Promise<OrderApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/orders/${id}/fiscalize`, data, { params })
		);
	}

	static getOrderSourceSearchSelect(
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/orders/sources?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static changeOrderPreparationStatus(
		id: string | number,
		status: OrderPreparationStatusName,
		params?: Record<string, any>
	): Promise<OrderApi> {
		return parseApiData(
			restSecurityApi.put(
				`${this.getOrganizationId()}/orders/${id}/preparation_status`,
				{ preparation_status: status },
				{ params }
			)
		);
	}

	static async getOrderJson(id: number): Promise<string> {
		const res = await restSecurityApi.get(`${this.getOrganizationId()}/orders/${id}/json`);
		return res.data;
	}

	static getOrderPDF(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/orders/pdf`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getOrderCSV(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/orders/csv`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static deleteOrder(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/orders/${id}`));
	}

	static sendOrderEReceiptViaEmail(orderId: number, data: { email: string }): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/orders/${orderId}/send_ereceipt_via_mail`, data)
		);
	}

	static getOrderEReceipt(orderId: number): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/orders/${orderId}/ereceipt`, { responseType: "blob" });
	}

	static getOrderFiscalizationEReceipt(orderId: number, orderReceiptUid: string): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/orders/${orderId}/ereceipt/${orderReceiptUid}`, {
			responseType: "blob",
		});
	}

	static async getOrderLink(orderId: number): Promise<string> {
		const res = await restSecurityApi.get(`${this.getOrganizationId()}/orders/${orderId}/public_link`);
		return res.data.link;
	}

	static getOrdersCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/orders/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static assignEmployeeDeliveryToOrder(
		orderId: number,
		data: AssignEmployeeDriverFormProps,
		params?: Record<string, any>
	): Promise<OrderApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/orders/${orderId}/delivery_employee`, data, { params })
		);
	}

	static exportEInvoice(id: number, externalOrg?: number): Promise<AxiosResponse> {
		const organizationId = externalOrg ? externalOrg : this.getOrganizationId();
		return restSecurityApi.get(`${organizationId}/invoices/${id}/efaktura`, { responseType: "blob" });
	}

	static exportOrderInvoice(id: number, externalOrg?: number, params?: Record<string, any>): Promise<AxiosResponse> {
		const organizationId = externalOrg ? externalOrg : this.getOrganizationId();
		return restSecurityApi.get(`${organizationId}/invoices/${id}/pdf`, { responseType: "blob", params });
	}

	static exportOrderInvoicePreview(id: number, externalOrg?: number): Promise<AxiosResponse> {
		const organizationId = externalOrg ? externalOrg : this.getOrganizationId();
		return restSecurityApi.get(`${organizationId}/invoices/${id}/pdf/preview_image`, { responseType: "blob" });
	}

	static getOrdersSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<OrderSearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/orders/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getCategoriesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/categories/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getModifierGroupsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/modifier_groups/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getModifierGroups(params?: Record<string, any>, options?: Record<string, any>): Promise<ModifierGroupApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/modifier_groups`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getModifierGroup(id: number, params?: Record<string, any>): Promise<ModifierGroupApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/modifier_groups/${id}`, { params }));
	}

	static updateModifierGroup(modifier: ModifierGroupApi, params?: Record<string, any>): Promise<ModifierGroupApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/modifier_groups/${modifier.id}`, modifier, { params })
		);
	}

	static createModifierGroup(modifier: ModifierGroupApi, params?: Record<string, any>): Promise<ModifierGroupApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/menu/modifier_groups`, modifier, { params })
		);
	}

	static removeModifierGroup(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/menu/modifier_groups/${id}`));
	}

	static restoreModifierGroup(id: number): Promise<ModifierGroupApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/modifier_groups/${id}/restore`));
	}

	static disableModifierGroup(id: number): Promise<ModifierGroupApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/modifier_groups/${id}/disable`));
	}

	static removeModifierGroups(
		modifierGroupIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<{}> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/modifier_groups`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${modifierGroupIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static restoreModifierGroups(
		modifierGroupIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ModifierGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/modifier_groups/restore`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${modifierGroupIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static disableModifierGroups(
		modifierGroupIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ModifierGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/modifier_groups/disable`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${modifierGroupIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static getModifierGroupsCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/modifier_groups/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getFlatPriceLists(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<MenuPriceListFlatResponseApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/price_lists/flat`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateFlatGroupPriceLists(
		data: SpreadsheetPriceListFormProps,
		params?: Record<string, any>
	): Promise<MenuPriceListFlatResponseApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/price_lists/flat`, data, { params }));
	}

	static getPriceLists(params?: Record<string, any>, options?: Record<string, any>): Promise<MenuPriceListApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/price_lists`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPriceList(id: number, params?: Record<string, any>): Promise<MenuPriceListApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/menu/price_lists/${id}`, { params }));
	}

	static createPriceList(priceList: MenuPriceListApi, params?: Record<string, any>): Promise<MenuPriceListApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/menu/price_lists`, priceList, { params })
		);
	}

	static updatePriceList(priceList: MenuPriceListApi, params?: Record<string, any>): Promise<MenuPriceListApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/price_lists/${priceList.id}`, priceList, { params })
		);
	}

	static removePriceList(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/menu/price_lists/${id}`));
	}

	static restorePriceList(id: number): Promise<MenuPriceListApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/price_lists/${id}/restore`));
	}

	static disablePriceList(id: number): Promise<MenuPriceListApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/menu/price_lists/${id}/disable`));
	}

	static removePriceLists(
		priceListIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ModifierGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/price_lists`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${priceListIds}&${urlParams}`;

		return parseApiData(restSecurityApi.delete(endpoint));
	}

	static restorePriceLists(
		priceListIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ModifierGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/price_lists/restore`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${priceListIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static disablePriceLists(
		priceListIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<ModifierGroupApi[]> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/menu/price_lists/disable`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${priceListIds}&${urlParams}`;

		return parseApiData(restSecurityApi.put(endpoint));
	}

	static getPriceListCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/price_lists/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getEmployeesActivities(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<EmployeeActivityApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/employee_activities`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getEmployeeActivitiesPDF(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/employee_activities/pdf`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getEmployeeActivitiesCSV(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/employee_activities/csv`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getEmployeeActivityTypes(): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/employee_activities/types`));
	}

	static getMessageEventsPDF(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/message_events/pdf`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getMessageEventsCSV(params?: Record<string, any>, options?: Record<string, any>): Promise<string> {
		return restSecurityApi.get(`${this.getOrganizationId()}/message_events/csv`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getWorkTimes(params?: Record<string, any>, options?: Record<string, any>): Promise<WorkTimeApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/work_times`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getWorkTimesReport(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/work_times/report`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static createWorktime(data: WorkTimeApi, params?: Record<string, any>): Promise<WorkTimeApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/work_times`, data, { params }));
	}

	static updateWorktime(data: WorkTimeApi, params?: Record<string, any>): Promise<WorkTimeApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/work_times/${data.id}`, data, { params }));
	}

	static removeWorktime(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/work_times/${id}`));
	}

	static restoreWorktime(id: number): Promise<WorkTimeApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/work_times/${id}/restore`));
	}

	static recalculateWorktime(
		data: RecalculateWorkTimesFormProps,
		params?: Record<string, any>
	): Promise<WorkTimeApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/work_times/recalculate`, data, { params })
		);
	}

	static worktimeCheckout(id: number): Promise<{}> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/work_times/${id}/checkout`));
	}

	static workTimePay(id: number): Promise<WorkTimeApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/work_times/${id}/pay`));
	}

	static workTimesPay(
		worktimeIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<WorkTimeApi> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/work_times/pay`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${worktimeIds}&${urlParams}`;

		return parseApiData(restSecurityApi.post(endpoint));
	}

	static workTimeCancelPay(
		worktimeIds: string,
		params?: Record<string, any>,
		areAllItemsSelected?: boolean
	): Promise<WorkTimeApi> {
		const urlParams = new URLSearchParams(params).toString();
		const baseEndpoint = `/${this.getOrganizationId()}/work_times/cancel_pay`;
		const endpoint = areAllItemsSelected
			? `${baseEndpoint}?${urlParams}`
			: `${baseEndpoint}?id=${worktimeIds}&${urlParams}`;

		return parseApiData(restSecurityApi.post(endpoint));
	}

	static getWorktimesCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/work_times/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static generateJpkV7(data: JPKInvoiceV7Api): Promise<Record<string, any>> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/jpk_v7`, data));
	}

	static async generateJpk(data: JPKInvoiceApi): Promise<Record<string, any>> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/jpk`, data));
	}

	static generateJpkFA(data: JPK_FAInvoiceApi): Promise<string> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/jpk_fa`, data));
	}

	static getJPKDetails(): Promise<Record<string, any>> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/invoices/jpk_details`));
	}

	static createInvoice(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices`, invoice));
	}

	static removeInvoice(id: number, externalOrg?: number): Promise<{}> {
		const organizationId = externalOrg ? externalOrg : this.getOrganizationId();
		return parseApiData(restSecurityApi.delete(`${organizationId}/invoices/${id}`));
	}

	static groupInvoice(id: number, group: string, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/invoices/${id}/group?group=${group}`, undefined, {
				params,
			})
		);
	}

	static getUngroupedInvoice(id: number, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/invoices/${id}/ungroup`, { params }));
	}

	static getGroupedInvoice(id: number, group: string, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/invoices/${id}/group?group=${group}`, { params })
		);
	}

	static ungroupInvoice(id: number, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/invoices/${id}/ungroup`, undefined, { params })
		);
	}

	static updateProformaInvoice(invoice: InvoiceApi, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/invoices/proformas/${invoice.id}`, invoice, { params })
		);
	}

	static createInvoiceFromOrder(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/from_orders`, invoice));
	}

	static createProformaInvoice(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/proformas`, invoice));
	}

	static createInvoiceFromProforma(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/from_proforma`, invoice));
	}

	static createAdvanceFromProforma(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/invoices/advances/from_proforma`, invoice)
		);
	}

	static updateInvoice(invoice: InvoiceApi, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/invoices/${invoice.id}`, invoice, { params })
		);
	}

	static updateAdvanceInvoice(invoice: InvoiceApi, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/invoices/advances/${invoice.id}`, invoice, { params })
		);
	}

	static createCorrection(invoiceId: number, correction: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/invoices/${invoiceId}/correct`, correction)
		);
	}

	static updateCorrection(correction: InvoiceApi, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/invoices/corrections/${correction.id}`, correction, {
				params,
			})
		);
	}

	static getInvoice(id: number, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/invoices/${id}`, { params }));
	}

	static getInvoiceGtu(search: string, params: Record<string, any>): Promise<Array<string>> {
		const page = getSearchSelectPage(params);
		const f = mapToBase(search, params);
		return parseApiData(restSecurityApi.get(`/${this.getOrganizationId()}/invoices/gtu?f=${f}&page=${page}`));
	}

	static getProforma(id: number, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/invoices/proformas/${id}`, { params }));
	}

	static getInvoices(params?: Record<string, any>, options?: Record<string, any>): Promise<InvoiceApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/invoices`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getInvoicesSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/invoices/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getProformas(params?: Record<string, any>, options?: Record<string, any>): Promise<InvoiceApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/invoices/proformas`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getInvoiceDetails(): Promise<InvoiceDetailsApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/invoices/details`));
	}

	static createInvoiceFromAdvance(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/from_advance`, invoice));
	}

	static createAdvance(invoice: InvoiceApi): Promise<InvoiceApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/invoices/advances`, invoice));
	}

	static updateAdvance(invoice: InvoiceApi, params?: Record<string, any>): Promise<InvoiceApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/invoices/advances/${invoice.id}`, invoice, { params })
		);
	}

	static updateInvoiceDetails(data: InvoiceDetailsApi): Promise<InvoiceDetailsApi> {
		return parseApiData(restSecurityApi.put(`${this.getOrganizationId()}/invoices/details`, data));
	}

	static sendInvoiceByEmail(id: number, data: { email: string }, externalOrg?: number): Promise<{}> {
		const organizationId = externalOrg ? externalOrg : this.getOrganizationId();
		return parseApiData(restSecurityApi.post(`${organizationId}/invoices/${id}/pdf_via_mail`, data));
	}

	static downloadInvoices(params?: Record<string, any>, options?: Record<string, any>): Promise<Record<string, any>> {
		return restSecurityApi.get(`${this.getOrganizationId()}/invoices/pdf`, {
			params,
			cancelToken: options?.cancelToken,
			responseType: "blob",
		});
	}

	static getInvoicesCount(params?: Record<string, any>, options?: Record<string, any>): Promise<number> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/invoices/count`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createReservation(reservation: ReservationApi): Promise<ReservationApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/reservations`, reservation));
	}

	static cancelReservation(id: number, params?: Record<string, any>): Promise<ReservationApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/reservations/${id}/cancel`, undefined, { params })
		);
	}

	static updateReservation(reservation: ReservationApi, params?: Record<string, any>): Promise<ReservationApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/reservations/${reservation.id}`, reservation, { params })
		);
	}

	static getReservation(id: number, params?: Record<string, any>): Promise<ReservationApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/reservations/${id}`, { params }));
	}

	static getReservations(params?: Record<string, any>, options?: Record<string, any>): Promise<ReservationApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/reservations`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getCsvAttributes(
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>
	): Promise<CsvAttributeApi[]> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/attributes`, formData, headers)
		);
	}

	static getCsvExampleSimpleFile(resourceType: string): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/simple_example`, {
			responseType: "blob",
		});
	}

	static getCsvExampleFile(resourceType: string): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/example`, { responseType: "blob" });
	}

	static getCsvFromVenue(resourceType: string): Promise<AxiosResponse> {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/export`, { responseType: "blob" });
	}

	static importCsv(
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		params: Record<string, string>
	): Promise<AxiosResponse> {
		return restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/dry_import`, formData, {
			headers,
			params,
		});
	}

	static finishImportCsv(
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>
	): Promise<AxiosResponse> {
		return restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/standard_import`, formData, {
			headers,
		});
	}

	static getDashboard(): Promise<DashboardApi> {
		return parseApiData(restSecurityApi.get(`${this.getOrganizationId()}/dashboard`));
	}

	static getDashboardReports(controller: AbortController): Promise<DashboardReportsApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/dashboard/reports`, { signal: controller.signal })
		);
	}

	static getDashboardAlerts(controller: AbortController): Promise<DashboardAlertApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/dashboard/alerts`, { signal: controller.signal })
		);
	}

	static clearVenueCustom(data: ClearVenueCustomFormProps, password: string): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`/${this.getOrganizationId()}/clear/config/status?password=${password}`, data)
		);
	}

	static clearVenueCustomPermanently(data: ClearVenueCustomPermanentlyFormProps, password: string): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`/${this.getOrganizationId()}/clear/config/permanent?password=${password}`, data)
		);
	}

	static clearVenueSale(password: string): Promise<{}> {
		return parseApiData(restSecurityApi.post(`/${this.getOrganizationId()}/clear/sale?password=${password}`));
	}

	static getDiagnosticsTypes(organizationId?: number): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`${organizationId}/diagnostics/types`));
	}

	static diagnose(types: string[], organizationId?: number, options?: Record<string, any>): Promise<string> {
		return parseApiData(
			restSecurityApi.post(`${organizationId}/diagnostics_async?type=${types}`, undefined, {
				...options,
			})
		);
	}

	static getDiagnoseProgress = (
		jobUid: string,
		organizationId?: number,
		options?: Record<string, any>
	): Promise<AxiosResponse> => {
		return restSecurityApi.get(`${organizationId}/diagnostics_async/progress/${jobUid}`, {
			...options,
		});
	};

	static diagnoseFix(fixId: string, organizationId?: number): Promise<{}> {
		return parseApiData(restSecurityApi.put(`${organizationId}/diagnostics/${fixId}/fix`));
	}

	static manageTerminal(
		terminalId: number,
		data: {
			diagnostics_enabled: boolean;
		},
		params?: Record<string, any>
	): Promise<TerminalApi> {
		return parseApiData(
			restSecurityApi.put(`${apiOrganization.getOrganizationId()}/terminals/${terminalId}/manage`, data, {
				params,
			})
		);
	}

	static getExternalReports(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<ExternalReportApi[]> {
		return parseApiData(
			restSecurityApi.get(`${apiOrganization.getOrganizationId()}/external_reports`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getExternalReport(id: number, params?: Record<string, any>): Promise<ExternalReportApi> {
		return parseApiData(
			restSecurityApi.get(`${apiOrganization.getOrganizationId()}/external_reports/${id}`, { params })
		);
	}

	static toolsFixTransactions(
		orderId: number | undefined,
		force: boolean,
		all: boolean,
		onlyClose: boolean,
		closePaid: boolean
	): Promise<Record<string, any>> {
		return parseApiData(
			restSecurityApi.post(
				`${apiOrganization.getOrganizationId()}/tools/transaction_fix`,
				{},
				{
					params: {
						order_id: orderId,
						force,
						all,
						only_close: onlyClose,
						close_paid: closePaid,
					},
				}
			)
		);
	}

	static getResourceTypes(
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<{
		custom_fields: CustomFieldTemplateApi[];
	}> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/resource_config`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getPriceListPredicates(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<PriceListPredicateApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/price_list_predicates`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updatePriceListPredicates(
		data: UpdatePriceListPredicateApi,
		params?: Record<string, any>
	): Promise<PriceListPredicateApi[]> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/price_list_predicates/group`, data, { params })
		);
	}

	static removePriceListPredicate(predicateId: number): Promise<{}> {
		return parseApiData(
			restSecurityApi.delete(`${this.getOrganizationId()}/menu/price_list_predicates/${predicateId}`)
		);
	}

	static createPriceListPredicate(
		data: UpdatePriceListPredicateApi,
		params?: Record<string, any>
	): Promise<PriceListPredicateApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/menu/price_list_predicates`, data, { params })
		);
	}

	static checkPosReportBeforeClear(POSReportIds: string): Promise<PosReporCheckBeforeDeletionApi> {
		return parseApiData(
			restSecurityApi.get(`/${this.getOrganizationId()}/clear/sale/by_reports?report_id=${POSReportIds}`)
		);
	}

	static clearSaleBasedOnDateRange(dateFrom: Date, dateTo: Date): Promise<PosReporCheckBeforeDeletionApi> {
		return parseApiData(
			restSecurityApi.get(
				`/${this.getOrganizationId()}/clear/sale/by_reports?date_from=${formatDateTimeToString(
					dateFrom
				)}&date_to=${formatDateTimeToString(dateTo)}`
			)
		);
	}

	static clearVenueSaleBasedOnPosReport(posReportId: string, password: string): Promise<AxiosResponse> {
		return parseApiData(
			restSecurityApi.post(
				`/${this.getOrganizationId()}/clear/sale/by_reports?clear_uid=${posReportId}&password=${password}`
			)
		);
	}

	static clearVenueSaleBasedOnDateRange(
		password: string,
		dateFrom: Date,
		dateTo: Date,
		data: Record<string, any>
	): Promise<AxiosResponse> {
		return parseApiData(
			restSecurityApi.post(
				`/${this.getOrganizationId()}/clear/by_date_range?date_from=${formatDateTimeToString(
					dateFrom
				)}&date_to=${formatDateTimeToString(dateTo)}&password=${password}`,
				data
			)
		);
	}

	static async getDeliveryEmployeeSearchSelect(
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		const res = await parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/employee_deliveries?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);

		return res.map((item: DeliveryEmployeeApi) => ({
			value: item.source.id,
			label: item.employee?.name || item?.source?.name || "",
		}));
	}

	static getDeliveryEmployees(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<DeliveryEmployeeApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/employee_deliveries`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	// ITEM GROUP TRANSLATIONS
	static getItemGroupsTranslations(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<ItemTranslationNameListResponseApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/menu/items/translations`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateItemGroupsTranslations(
		data: ItemTranslationListFormProps,
		params?: Record<string, any>
	): Promise<ItemTranslationNameListResponseApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/menu/items/translations`, data, { params })
		);
	}

	static getNotificationTemplatesSettings(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<PrintoutTemplateApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/notification_templates`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static activateNotificationTemplate(id: number, params?: Record<string, string>): Promise<NotificationTemplateApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/notification_templates/${id}/restore`, { params })
		);
	}

	static deactivateNotificationTemplate(
		id: number,
		params: Record<string, string>
	): Promise<NotificationTemplateApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/notification_templates/${id}/disable`, { params })
		);
	}

	static removeNotificationTemplate(id: number): Promise<NotificationTemplateApi> {
		return parseApiData(restSecurityApi.delete(`${this.getOrganizationId()}/notification_templates/${id}`));
	}

	static createNotificationTemplate(
		data: NotificationTemplateApi,
		params: Record<string, string>
	): Promise<NotificationTemplateApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/notification_templates`, data, { params })
		);
	}

	static updateNotificationTemplate(
		data: NotificationTemplateApi,
		params: Record<string, string>
	): Promise<NotificationTemplateApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/notification_templates/${data.id}`, data, { params })
		);
	}

	static getAvailableVariablesBasedOnCategory(category: string) {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/notification_templates/sample_variables`, {
				params: {
					category,
				},
			})
		);
	}

	static getNotificationTemplateDefaultValues(category: string, type: string): Promise<NotificationTemplateApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/notification_templates/default`, {
				params: {
					category,
					type,
				},
			})
		);
	}

	static getNotificationPreview(data: NotificationTemplateApi): Promise<PreviewNotificationTemplateApi> {
		return parseApiData(restSecurityApi.post(`${this.getOrganizationId()}/notification_templates/try`, data));
	}

	static getNotificationTemplate(id: number, params?: Record<string, any>): Promise<PreviewNotificationTemplateApi> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/notification_templates/${id}`, { params })
		);
	}

	static getNotificationTemplateCategories(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<{ categories: string[] }> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/notification_templates/categories`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getDeliveryZones(params?: Record<string, any>, options?: Record<string, any>): Promise<DeliveryZoneApi[]> {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/apps/delivery_zones`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static createDeliveryZone(data: DeliveryZoneApi, params?: Record<string, any>): Promise<DeliveryZoneApi> {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/apps/${data.app_id}/delivery_zones`, data, {
				params,
			})
		);
	}

	static updateDeliveryZone(
		id: string,
		data: DeliveryZoneApi,
		params?: Record<string, any>
	): Promise<DeliveryZoneApi> {
		return parseApiData(
			restSecurityApi.put(`${this.getOrganizationId()}/apps/${data.app_id}/delivery_zones/${id}`, data, {
				params,
			})
		);
	}

	static removeDeliveryZone(id: string, appId: number, params?: Record<string, any>): Promise<DeliveryZoneApi> {
		return parseApiData(
			restSecurityApi.delete(`${this.getOrganizationId()}/apps/${appId}/delivery_zones/${id}`, {
				params,
			})
		);
	}

	static async getOrganizationCoordinates(params: GetCoordinatesApi): Promise<CoordinatesApi> {
		return parseApiData(restSecurityApi.get(`geolocation/coordinates`, { params }));
	}
}

export { apiOrganization };
