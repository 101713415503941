import { MutableRefObject } from "react";
import { TFunction } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { ListConfigFilter } from "go-list/core/components/Filter/services/types";
import { ReportConfigColumn } from "go-report/core/services/types";
import { getChartType, getReportFilter } from "go-report/reports/services/report-service";
import { apiReport } from "../../services/Api/Report/apiReport";

const parseTimestampToDate = (timestamp: string, t: TFunction) => {
	if (timestamp === "empty") return t("modules.transaction.field.no_assigned_date.title");
	return FormatDate(new Date(Number(timestamp)), undefined, undefined, { hours: "none" });
};

export class ReportInvoicesUtils {
	static getChartConfig(t: TFunction) {
		return {
			title: t("modules.invoice.field.chart_title.title"),
			lineTitle: t("modules.invoice.field.gross_total_money.title"),
			type: getChartType("invoice_report_type"),
			aggregate: "gross_total_money",
			groups: [
				{
					id: "DAY_OF_MONTH",
					title: t("go_report.chart.aggregates.DAY_OF_MONTH", { ns: "lib" }),
					type: "DAY_OF_MONTH",
				},
				{
					id: "DATE",
					title: t("go_report.chart.aggregates.DATE", { ns: "lib" }),
					type: "DATE",
				},
				{
					id: "DAY_OF_WEEK",
					title: t("go_report.chart.aggregates.DAY_OF_WEEK", { ns: "lib" }),
					type: "DAY_OF_WEEK",
				},
			],
			parseGroup: (group: string) => {
				if (group === "DAY_OF_MONTH") return "ISSUED_AT_DAY_OF_MONTH";
				if (group === "DATE") return "ISSUED_AT";
				if (group === "DAY_OF_WEEK") return "ISSUED_AT_DAY_OF_WEEK";
				return group;
			},
		};
	}

	static getSelectedColumns(columnType: string): string[] {
		const columnsFromLocalStorage = window.localStorage.getItem(`go_report.${columnType}.columns`)?.split(",");
		if (columnsFromLocalStorage) {
			return columnsFromLocalStorage;
		}
		return ["gross_total_money", "net_total_money", "transaction_count"];
	}

	static getColumns(t: TFunction): ReportConfigColumn[] {
		return [
			{
				id: "gross_total_money",
				name: t("modules.invoice.field.gross_total_money.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "net_total_money",
				name: t("common.word.net_total_money"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "transaction_count",
				name: t("modules.invoice.field.transaction_count.title"),
			},
		];
	}

	static getGroups(t: TFunction): Array<Record<string, any>> {
		return [
			{
				id: "SOLD_AT",
				name: t("modules.invoice.field.sold_at.title"),
			},
			{
				id: "ISSUED_AT",
				name: t("modules.invoice.field.issued_at.title"),
			},
			{
				id: "PAYMENT_DUE_DATE",
				name: t("modules.invoice.field.paid_at_date.title"),
			},
			{
				id: "PLACE_OF_ISSUE",
				name: t("modules.invoice.field.place_of_issue.title"),
			},
			{
				id: "SIGNED_BY",
				name: t("modules.invoice.field.signed_by.title"),
			},
			{
				id: "PAYMENT_OUTFLOW_TYPE",
				name: t("modules.payment_method.field.payment_outflow_type.title"),
			},
			{
				id: "RECIPIENT_NAME",
				name: t("modules.invoice.field.recipient.title"),
			},
			{
				id: "RECIPIENT_TAX_ID_NO",
				name: t("modules.invoice.field.recipient_tax_id_no.title"),
			},
			{
				id: "RECIPIENT_CITY",
				name: t("modules.invoice.field.recipient_city.title"),
			},
			{
				id: "ISSUER_NAME",
				name: t("modules.invoice.field.seller.title"),
			},
			{
				id: "ISSUER_TAX_ID_NO",
				name: t("modules.invoice.field.issuer_tax_id_no.title"),
			},
			{
				id: "ISSUER_CITY",
				name: t("modules.invoice.field.issuer_city.title"),
			},
			{
				id: "PAYMENT_METHOD",
				name: t("common.word.payment_method"),
			},
			{
				id: "PAYMENT_STATUS",
				name: t("common.word.payment_status"),
			},
			{
				id: "ORDER_NUMBER",
				name: t("common.word.order"),
			},
			{
				id: "DOCUMENT_TYPE",
				name: t("modules.invoice.field.invoice_type.title"),
			},
			{
				id: "NUMBER",
				name: t("modules.invoice.field.invoice_number.title"),
			},
		];
	}

	static getGroupsInfo = (t: TFunction) => {
		return {
			SOLD_AT: (groupByValue: Record<string, any>) => parseTimestampToDate(groupByValue.name, t),
			ISSUED_AT: (groupByValue: Record<string, any>) => parseTimestampToDate(groupByValue.name, t),
			PAYMENT_DUE_DATE: (groupByValue: Record<string, any>) => parseTimestampToDate(groupByValue.name, t),
			PAYMENT_STATUS: (groupByValue: Record<string, any>) => t(`enums.common.status.${groupByValue.name}`),
			PAYMENT_OUTFLOW_TYPE: (groupByValue: Record<string, any>) => {
				let paymentOutflowType = groupByValue.name;
				if (paymentOutflowType === "") return "-";
				if (paymentOutflowType === "RW / WZ") paymentOutflowType = "RW_WZ";
				return t(`enums.payment_methods.payment_outflow_types.${paymentOutflowType}`);
			},
			DOCUMENT_TYPE: (groupByValue: Record<string, any>) =>
				t(`enums.invoices.document_type.${groupByValue.name}`),
			PAYMENT_METHOD: (groupByValue: Record<string, any>) => {
				if (!groupByValue.name || groupByValue.name === "empty") return t("common.word.unpaid_female");
				return groupByValue.name;
			},
		};
	};

	static getFilters(t: TFunction, filtersParams: MutableRefObject<string>, type: string): ListConfigFilter[] {
		return [
			{
				id: "date_range",
				name: t("modules.item_group_report.field.date_range.title"),
				type: "date",
				options: ["bt"],
				isRemovable: false,
			},
			{
				id: "sold_at",
				name: t("modules.invoice.field.sold_at.title"),
				type: "date",
			},
			{
				id: "issued_at",
				name: t("modules.invoice.field.date_of_issue.title"),
				type: "date",
			},
			{
				id: "payment_outflow_type",
				name: t("modules.payment_method.field.payment_outflow_type.title"),
				type: "list",
				listOptions: {
					RW: t("enums.payment_methods.payment_outflow_types.RW"),
					WZ: t("enums.payment_methods.payment_outflow_types.WZ"),
				},
			},
			{
				id: "payment_due_date",
				name: t("modules.invoice.field.paid_at_date.title"),
				type: "date",
			},
			{
				id: "place_of_issue",
				name: t("modules.invoice.field.place_of_issue.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"PLACE_OF_ISSUE",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "signed_by",
				name: t("modules.invoice.field.issuer.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"SIGNED_BY",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "recipient_name",
				name: t("modules.invoice.field.recipient_name.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"RECIPIENT_NAME",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "recipient_tax_id_no",
				name: t("modules.invoice.field.recipient_tax_id_no.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"RECIPIENT_TAX_ID_NO",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "recipient_city",
				name: t("modules.invoice.field.recipient_city.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"RECIPIENT_CITY",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "issuer_name",
				name: t("modules.invoice.field.seller.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ISSUER_NAME",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "issuer_tax_id_no",
				name: t("modules.invoice.field.issuer_tax_id_no.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ISSUER_TAX_ID_NO",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "issuer_city",
				name: t("modules.invoice.field.issuer_city.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ISSUER_CITY",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"PAYMENT_METHOD",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
				conditionCustomLabelConfig: [{ condition: "u", label: t("common.word.unpaid_female") }],
			},
			{
				id: "payment_status",
				name: t("common.word.payment_status"),
				type: "list",
				listOptions: {
					PAID: t("enums.common.status.PAID"),
					UNPAID: t("enums.common.status.UNPAID"),
				},
			},
			{
				id: "order_number",
				name: t("common.word.order"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ORDER_NUMBER",
							queryString,
							filtersParams.current,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "document_type",
				name: t("modules.invoice.field.invoice_type.title"),
				type: "list",
				listOptions: {
					PROFORMA: t("enums.invoices.document_type.PROFORMA"),
					INVOICE: t("enums.invoices.document_type.INVOICE"),
					CORRECTION: t("enums.invoices.document_type.CORRECTION"),
					ADVANCE: t("enums.invoices.document_type.ADVANCE"),
				},
			},
		];
	}
}
