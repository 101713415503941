import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import { mapCustomFieldsToForm } from "go-app/components/CustomFields";
import CustomFieldsForm from "go-app/components/CustomFields/CustomFieldsForm";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { OrderApi, UpdateOrderApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	order: OrderApi;
	customFieldsConfig: CustomFieldTemplateApi[];
}

const EditOrderForm = ({ order, customFieldsConfig }: Props) => {
	const { t } = useTranslation();
	const form = useForm<UpdateOrderApi>({
		criteriaMode: "all",
		defaultValues: {
			number_of_guests: order.number_of_guests,
			comment: order.comment,
			table_id: order.table?.id,
			custom_fields: mapCustomFieldsToForm(customFieldsConfig, order.custom_fields),
		},
	});
	const {
		handleSubmit,
		formState,
		formState: { errors },
		setError,
		control,
		register,
		reset,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	const onSubmit = handleSubmit(async (data: UpdateOrderApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		try {
			const params = { include: "table,custom_fields" };
			const res = await api.organization().updateOrder(order.id, data, params);
			reset({
				...res,
				table_id: res.table?.id,
				custom_fields: mapCustomFieldsToForm(customFieldsConfig, res.custom_fields),
			});
			addSuccessFlash(t("lib:common.flash.saved"));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const searchTables = async (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getTablesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	return (
		<FormDirty formState={formState} loading={loading} key="client-group-form" noValidate onSubmit={onSubmit}>
			<fieldset className="form-group">
				<FormNumberInput
					errors={errors}
					name="number_of_guests"
					label={t("modules.order.field.number_of_guests.title")}
					allowNegative={false}
					control={control}
					suffix=""
				/>
				<FormSelectGroup
					errors={errors}
					loadOptions={searchTables}
					control={control}
					label={t("common.word.table")}
					name="table_id"
					getOptionValue={(option) => option.id}
					getOptionLabel={(option) => `${option.label} (${option.room_name})`}
					defaultValue={{
						id: order.table?.id,
						label: order.table?.name,
						room_name: order.table?.room_name,
					}}
				/>
				<FormInput name="comment" label={t("common.word.comment")} errors={errors} register={register} />
			</fieldset>
			<fieldset className="form-group">
				<h5>{t("lib:common.word.advanced")}</h5>
				<CustomFieldsForm
					form={form}
					customFields={order.custom_fields}
					customFieldsConfig={customFieldsConfig}
					setErrors={setErrors}
				/>
			</fieldset>
		</FormDirty>
	);
};

export default EditOrderForm;
