import React from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { LiveOrderRejectionFormApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	option: string;
	setSelectedOption: (option: string | undefined) => void;
	selectedOption: string | undefined;
	setShowReasonInput: (value: boolean) => void;
	form: UseFormReturn<LiveOrderRejectionFormApi>;
	otherReason: boolean;
}

const LiveOrderRejectionModalOption = ({
	setSelectedOption,
	option,
	selectedOption,
	setShowReasonInput,
	form,
	otherReason,
}: Props) => {
	const { setValue } = form;

	const toggleOnClick = () => {
		if (option === selectedOption) {
			setSelectedOption(undefined);
			setShowReasonInput(false);
		} else {
			setSelectedOption(option);
			if (otherReason) setShowReasonInput(true);
			else {
				setShowReasonInput(false);
				setValue("reason", undefined);
			}
		}
	};

	return (
		<Button
			variant={selectedOption === option ? "primary" : "light"}
			onClick={toggleOnClick}
			className="live-order-rejection-modal-option"
		>
			{option}
		</Button>
	);
};

export default LiveOrderRejectionModalOption;
