import React from "react";
import { useSelector } from "react-redux";
import OrganizationInstallView from "go-security/components/OrganizationInstall/OrganizationInstallView";
import { selectOrganization } from "go-security/services/organizations/selectors";

const OrganizationInstallPage = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	return <OrganizationInstallView organization={organization} />;
};
export default OrganizationInstallPage;
