import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import FormatDate, {
	FormatDateToDateHoursRange,
	filterParseDateBetween,
} from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import ReportData from "go-report/reports";
import { getChartReport, getReport } from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import { api } from "../../../../../../../../services/Api/api";
import { ReportEmployeesUtils } from "../../../../../../../../utils/reports/ReportEmployeesUtils";

interface ListState {
	resource?: Record<string, any>;
}

const type = "work_time";
const listName = "EFFICIENCY_REPORT_V2";
const resourceType = "EFFICIENCY_REPORT_V2";

const Report: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();
	let config = {
		reportConfigId: "employees",
		aggregatePrefix: "sales",
		defaultGroup: "NONE",
		type: "order",
		chart: ReportEmployeesUtils.getChartConfig(t),
		groups: ReportEmployeesUtils.getGroups(t),
		selectedColumns: ReportEmployeesUtils.getSelectedColumns(),
		selectedGroups: ["DAY_OF_WEEK"],
		groupsInfo: ReportEmployeesUtils.getGroupsInfo(t),
		showPercent: true,
		columns: ReportEmployeesUtils.getColumns(t),
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date",
				condition: "bt",
				value: filterParseDateBetween(
					new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0),
					new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
				),
				visible: true,
			},
		],
		filters: [
			{
				id: "date_range",
				name: t("lib:common.word.date"),
				type: "date",
				isRemovable: false,
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select",
				source: {
					request: async (search: string, params: Record<string, any>, options?: Record<string, any>) => {
						const response = await api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						});

						return response.map((responseItem: Record<string, any>) => ({
							id: responseItem.label,
							label: responseItem.label,
						}));
					},
				},
			},
			...ReportEmployeesUtils.getFilters(t),
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				groups: ["DAY_OF_WEEK"],
				columns: ReportEmployeesUtils.getSelectedColumns(),
				filters: [
					{
						value: filterParseDateBetween(
							new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0),
							new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
						),
						condition: "bt",
						filterId: "date_range",
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return Promise.all([
				getReport(
					apiReport.getReportsCustom,
					params,
					organization.id,
					config.filters,
					type,
					sourceToken,
					params.groups === "NONE"
				),
				getChartReport(
					apiReport.getReportsCustom,
					params,
					"NONE",
					organization.id,
					config.filters,
					type,
					sourceToken
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return getChartReport(
				apiReport.getReportsCustom,
				params,
				"NONE",
				organization.id,
				config.filters,
				type,
				sourceToken
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.efficiency_report.field.efficiency_export.title"),
			filename: t("modules.efficiency_report.field.efficiency_export_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		withCompareDateRange: true,
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
	} as ReportConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

const OrganizationReportsEfficiencyPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};

export default OrganizationReportsEfficiencyPage;
