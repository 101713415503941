import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormSelectGroup } from "go-form/components/FormSelect";
import FormatResourceStatus from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { DefaultCommentApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	defaultComment: DefaultCommentApi;
	onHide: () => void;
	handleSave: (defaultComment: DefaultCommentApi) => void;
}

const DefaultCommentModalForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<DefaultCommentApi>({
		criteriaMode: "all",
		defaultValues: props.defaultComment,
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: DefaultCommentApi) => {
		setLoading(true);
		data.id = props.defaultComment.id;
		try {
			const res = await api.organization().createOrUpdateDefaultComment(data);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			props.handleSave(res);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const types = [
		{ value: "ORDER", label: `${t("enums.default_comments.types.ORDER")}` },
		{ value: "ORDER_ITEM", label: `${t("enums.default_comments.types.ORDER_ITEM")}` },
		{ value: "ORDER_ITEM_STORNO_REASON", label: `${t("enums.default_comments.types.ORDER_ITEM_STORNO_REASON")}` },
		{ value: "ORDER_REMOVE_REASON", label: `${t("enums.default_comments.types.ORDER_REMOVE_REASON")}` },
		{ value: "ORDER_CLOSE", label: `${t("enums.default_comments.types.ORDER_CLOSE")}` },
	];

	return (
		<Modal show={!!props.defaultComment} onHide={props.onHide}>
			<Form key="default-comment-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.defaultComment.id ? (
							<>
								{t("modules.default_comment.header.title")} "{props.defaultComment.content}"
								<FormatResourceStatus status={props.defaultComment.status} />
							</>
						) : (
							<>{t("modules.default_comment.header.new.title")}</>
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						label={t("lib:common.word.type")}
						name="type"
						errors={errors}
						options={types}
						defaultValue={props.defaultComment.type}
						control={control}
						data-testid="type"
					/>
					<FormInput
						label={t("modules.default_comment.field.content.title")}
						register={register}
						name="content"
						errors={errors}
					/>
					<FormCheck
						type="switch"
						label={t("modules.default_comment.form.high_priority.title")}
						register={register}
						name="high_priority"
						errors={errors}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
export default DefaultCommentModalForm;
