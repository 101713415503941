import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import { VenueSettingsApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
}
const KioskSettingsImagesCard = ({ form }: Props) => {
	const { t } = useTranslation();
	const { watch } = form;
	return (
		<fieldset className="form-group">
			<h6>{t("modules.venue_settings.field.images.title")}</h6>
			<div className="d-flex align-items-center gap-3 flex-wrap">
				<ImageColorForm
					form={form}
					handleSave={() => {}}
					prefix="kiosk.logo_image"
					mode="IMAGE"
					label={t("modules.venue_settings.field.logo.title")}
					data={{ ...watch(`kiosk.logo_image`), color: "" }}
					resourceLink={watch(`kiosk.logo_image_link`)}
				/>
				<ImageColorForm
					form={form}
					handleSave={() => {}}
					prefix="kiosk.cover_image"
					mode="IMAGE"
					label={t("modules.venue_settings.field.background.title")}
					data={{ ...watch(`kiosk.cover_image`), color: "" }}
					resourceLink={watch(`kiosk.cover_image_link`)}
				/>
				<ImageColorForm
					form={form}
					handleSave={() => {}}
					prefix="kiosk.header_image"
					mode="IMAGE"
					data={{ ...watch(`kiosk.header_image`), color: "" }}
					label={t("modules.venue_settings.field.header.title")}
					resourceLink={watch(`kiosk.header_image_link`)}
				/>
			</div>
		</fieldset>
	);
};

export default KioskSettingsImagesCard;
