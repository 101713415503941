import { Flash } from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { UnprocessableEntity } from "go-core/api/exceptions";
import { Exception } from "go-core/api/types";

export const handleEReceiptError = (error: Exception | unknown, addFlash: (_: Flash) => void): void | null => {
	if (error instanceof UnprocessableEntity) {
		const eReceiptNotFoundError = new UnprocessableEntity({
			errors: [
				{
					message: "e_receipt_not_found",
					code: "e_receipt_not_found",
				},
			],
		});

		return handleError.alert(eReceiptNotFoundError, addFlash);
	}

	handleError.alert(error, addFlash);
};
