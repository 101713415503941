import { GoSuiteApp, WebApp } from "../services/types";

export const getUninstalledAppProperVersion = (
	applications: GoSuiteApp[],
	organization: Record<string, any>,
	duplicatedAppName: string,
	oldVersionAppsTimestamp: number
): GoSuiteApp[] => {
	return applications.filter((application) => {
		if (application.id.toUpperCase() !== duplicatedAppName) return true;
		if (organization.applications.includes(duplicatedAppName)) {
			return !application.old;
		}

		const organizationCreatedAtTimestamp = new Date(organization?.created_at).getTime();
		const companyIdStartsWithGopos = organization.company_id?.startsWith("gopos_");
		const organizationCreatedInOldAppScope = oldVersionAppsTimestamp > organizationCreatedAtTimestamp;
		const conditionsToDisplayOldApp = organizationCreatedInOldAppScope || companyIdStartsWithGopos;
		const shouldRenderApplicationOld = application.old && conditionsToDisplayOldApp;
		const shouldRenderApplicationNew = !application.old && !conditionsToDisplayOldApp;

		if (shouldRenderApplicationOld || shouldRenderApplicationNew) {
			return true;
		}

		return false;
	});
};

const spliceApps = (applications: GoSuiteApp[], uniqueDuplicatedAppName: string, shouldRemoveOldVersion: boolean) => {
	const applicationToBeRemoved = applications.find(
		(application) =>
			application.id.toUpperCase() === uniqueDuplicatedAppName && application.old === shouldRemoveOldVersion
	);
	if (applicationToBeRemoved) {
		const indexOfAppToBeRemoved = applications.indexOf(applicationToBeRemoved);
		applications.splice(indexOfAppToBeRemoved, 1);
	}
};

export const getUniqueApps = (
	apps: GoSuiteApp[],
	organization: Record<string, any>,
	uniqueDuplicatedAppNames: string[],
	oldVersionAppsTimestamp: Record<string, number>,
	applyToMenuApps: boolean,
	userEnteredLocation?: boolean,
	webApp?: WebApp,
	organizations?: Record<string, any>[]
) => {
	let newApps = [...apps];
	if (uniqueDuplicatedAppNames.length === 0) return newApps;

	uniqueDuplicatedAppNames.forEach((uniqueDuplicatedAppName) => {
		if (applyToMenuApps) {
			const shouldRedirectToOldAppVersion = webApp === uniqueDuplicatedAppName && userEnteredLocation;
			if (shouldRedirectToOldAppVersion) {
				const indexOfNewAppVersion = newApps.findIndex((app) => app.id.toUpperCase() === webApp && !app.old);
				newApps.splice(indexOfNewAppVersion, 1);
				return;
			}

			if (!userEnteredLocation) {
				const shouldRedirectToNewAppVersion = organizations?.some((userOrganization) => {
					return userOrganization.applications.some((application: Record<string, any>) => {
						if (application.slug) {
							return application.slug === uniqueDuplicatedAppName;
						}
						return application.includes(uniqueDuplicatedAppName);
					});
				});
				spliceApps(newApps, uniqueDuplicatedAppName, Boolean(shouldRedirectToNewAppVersion));

				return;
			}
		}

		const applicationAlreadyInstalled =
			organization.applications?.some(
				(application: Record<string, any>) => application.slug === uniqueDuplicatedAppName
			) || organization.applications?.includes(uniqueDuplicatedAppName);

		if (applicationAlreadyInstalled) {
			const indexOfOldAppVersion = newApps.findIndex(
				(app) => app.id.toUpperCase() === uniqueDuplicatedAppName && app.old
			);

			newApps.splice(indexOfOldAppVersion, 1);
			return;
		}

		const timestampForSpecificApp = Object.entries(oldVersionAppsTimestamp).find(([timestampName]) => {
			return timestampName === uniqueDuplicatedAppName;
		});

		if (timestampForSpecificApp && timestampForSpecificApp[1]) {
			const timestampValueForSpecificApp = timestampForSpecificApp[1];
			newApps = getUninstalledAppProperVersion(
				newApps,
				organization,
				uniqueDuplicatedAppName,
				timestampValueForSpecificApp
			);
		}
	});

	return newApps;
};

export const getRidOfDuplicateApps = (
	apps: GoSuiteApp[],
	organization: Record<string, any>,
	oldVersionAppsTimestamp: Record<string, number>,
	applyToMenuApps: boolean,
	userEnteredLocation?: boolean,
	webApp?: WebApp,
	organizations?: Record<string, any>[]
): GoSuiteApp[] => {
	let newApps = [...apps];
	const uniqueDuplicatedAppNames = getDuplicateApps(apps);
	newApps = getUniqueApps(
		newApps,
		organization,
		uniqueDuplicatedAppNames,
		oldVersionAppsTimestamp,
		applyToMenuApps,
		userEnteredLocation,
		webApp,
		organizations
	);

	return newApps;
};

export const getDuplicateApps = (apps: GoSuiteApp[]): string[] => {
	const newApps = [...apps];
	const duplicatedApps = newApps.filter((app) => {
		const filteredApp = newApps.filter((newApp) => newApp.id === app.id);
		if (filteredApp.length > 1) return true;
		return false;
	});
	const duplicatedAppNames = duplicatedApps.map((app) => app.id);
	const uniqueDuplicatedAppNames = duplicatedAppNames.reduce<string[]>((duplicatedAppNames, currentApp) => {
		if (duplicatedAppNames.includes(currentApp.toUpperCase())) return duplicatedAppNames;
		return [...duplicatedAppNames, currentApp.toUpperCase()];
	}, []);

	return uniqueDuplicatedAppNames;
};
