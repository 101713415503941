import { AxiosResponse } from "axios";
import { AttributeProps } from "go-component/components/ImportCsv/AttributesTable";
import { JobApi } from "go-component/components/Job/services/types";
import { MessageEventApi } from "go-component/components/MessageEvent/services/types";
import { RequestItemApi } from "go-component/components/RequestItem/services/types";
import { WebhookApi } from "go-component/components/Webhook/services/types";
import { AvailabilityApi } from "go-component/services/types";
import { parseApiData } from "go-core/api/parseApiData";
import { ItemResponse, ListResponse } from "go-core/api/types";
import { restSecurityApi } from "go-security/services/Api/api";
import OrganizationIdService from "go-security/services/organizationIdService";
import { CustomFieldTemplateApi, CustomFieldTemplateFormApi } from "go-segment/services/types";
import { OrderSyncApi } from "./types";

class apiOrganizationComponent {
	static getOrganizationId = () => {
		return OrganizationIdService.getWithCheck();
	};

	static getWebhooks(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<WebhookApi>>> {
		return restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/webhooks`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getWebhook(id: string, params?: Record<string, any>): Promise<AxiosResponse<ItemResponse<WebhookApi>>> {
		return restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/webhooks/${id}`, { params });
	}

	static createWebhook(data: WebhookApi): Promise<AxiosResponse<ItemResponse<WebhookApi>>> {
		return restSecurityApi.post(`/${apiOrganizationComponent.getOrganizationId()}/webhooks`, data);
	}

	static updateWebhook(data: WebhookApi): Promise<AxiosResponse<ItemResponse<WebhookApi>>> {
		return restSecurityApi.put(`/${apiOrganizationComponent.getOrganizationId()}/webhooks/${data.id}`, data);
	}

	static getWebhookResourceTypes(): Promise<string[]> {
		return parseApiData(
			restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/webhooks/resource_types`)
		);
	}

	static getRequestItems(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<RequestItemApi>>> {
		return restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/requests`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getRequestItem(
		id: string,
		params?: Record<string, any>
	): Promise<AxiosResponse<ItemResponse<RequestItemApi>>> {
		return restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/requests/${id}`, { params });
	}

	static retryRequestItem(
		id: string,
		params?: Record<string, any>
	): Promise<AxiosResponse<ItemResponse<RequestItemApi>>> {
		return restSecurityApi.post(
			`/${apiOrganizationComponent.getOrganizationId()}/requests/${id}/retry`,
			undefined,
			{ params }
		);
	}

	static getRequestItemTypes(): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/requests/types`));
	}

	static getJobs(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<JobApi>>> {
		return restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/jobs`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getJob(id: string, params?: Record<string, any>): Promise<AxiosResponse<ItemResponse<JobApi>>> {
		return restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/jobs/${id}`, { params });
	}

	static cancelJob(id: string, params?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.post(`/${apiOrganizationComponent.getOrganizationId()}/jobs/${id}/cancel`, undefined, {
			params,
		});
	}

	static getJobNames(): Promise<string[]> {
		return parseApiData(restSecurityApi.get(`/${apiOrganizationComponent.getOrganizationId()}/jobs/names`));
	}

	static getMessageEvents(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<MessageEventApi>>> {
		return restSecurityApi.get(`${apiOrganizationComponent.getOrganizationId()}/message_events`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getMessageEvent(
		id: string,
		params?: Record<string, any>
	): Promise<AxiosResponse<ItemResponse<MessageEventApi>>> {
		return restSecurityApi.get(`${apiOrganizationComponent.getOrganizationId()}/message_events/${id}`, { params });
	}

	static getMessageEventsTypes(): Promise<string[]> {
		return parseApiData(
			restSecurityApi.get(`${apiOrganizationComponent.getOrganizationId()}/message_events/types`)
		);
	}

	// CUSTOM FIELDS

	static removeCustomField(id: number, params?: Record<string, any>): Promise<{}> {
		return parseApiData(
			restSecurityApi.delete(`${apiOrganizationComponent.getOrganizationId()}/custom_fields/${id}`, { params })
		);
	}

	static restoreCustomField(id: number, params?: Record<string, any>): Promise<CustomFieldTemplateApi> {
		return parseApiData(
			restSecurityApi.put(
				`${apiOrganizationComponent.getOrganizationId()}/custom_fields/${id}/restore`,
				undefined,
				{ params }
			)
		);
	}

	static getCustomField(id: number, params?: Record<string, any>): Promise<CustomFieldTemplateApi> {
		return parseApiData(
			restSecurityApi.get(`${apiOrganizationComponent.getOrganizationId()}/custom_fields/${id}`, { params })
		);
	}

	static getCustomFields(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<CustomFieldTemplateApi[]> {
		return parseApiData(
			restSecurityApi.get(`${apiOrganizationComponent.getOrganizationId()}/custom_fields`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static updateCustomField(
		data: CustomFieldTemplateFormApi,
		params?: Record<string, any>
	): Promise<CustomFieldTemplateApi> {
		return parseApiData(
			restSecurityApi.put(`${apiOrganizationComponent.getOrganizationId()}/custom_fields/${data.id}`, data, {
				params,
			})
		);
	}

	static createCustomField(
		data: CustomFieldTemplateFormApi,
		params?: Record<string, any>
	): Promise<CustomFieldTemplateApi> {
		return parseApiData(
			restSecurityApi.post(`${apiOrganizationComponent.getOrganizationId()}/custom_fields`, data, { params })
		);
	}

	static importCsv = (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		params: Record<string, string>
	): Promise<Record<string, any>> => {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/dry_import`, formData, {
				headers,
				params,
			})
		);
	};

	static importAsyncCsv = (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		params: Record<string, string>,
		options: Record<string, any>
	): Promise<string> => {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/dry_import_async`, formData, {
				headers,
				params,
				...options,
			})
		);
	};

	static getImportCsvProgress = (
		jobUid: string,
		resourceType: string,
		params: Record<string, string>,
		options?: Record<string, any>
	): Promise<AxiosResponse> => {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/dry_import/progress/${jobUid}`, {
			params,
			...options,
		});
	};

	static finishImportCsv = (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>
	): Promise<AxiosResponse> => {
		return restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/standard_import`, formData, {
			headers,
		});
	};

	static finishImportAsyncCsv = (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		options: Record<string, any>
	): Promise<AxiosResponse> => {
		return restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/standard_import_async`, formData, {
			headers,
			...options,
		});
	};

	static getFinishImportCsvProgress = (
		jobUid: string,
		resourceType: string
	): Promise<{ job_status: string; job_uid: string }> => {
		return parseApiData(
			restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/standard_import/progress/${jobUid}`)
		);
	};

	static getCsvExampleSimpleFile = (resourceType: string): Promise<AxiosResponse> => {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/simple_example`, {
			responseType: "blob",
		});
	};

	static getCsvExampleFile = (resourceType: string): Promise<AxiosResponse> => {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/example`, { responseType: "blob" });
	};

	static getCsvFromVenue = (resourceType: string): Promise<AxiosResponse> => {
		return restSecurityApi.get(`${this.getOrganizationId()}/${resourceType}/export`, { responseType: "blob" });
	};

	static getCsvAttributes = (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>
	): Promise<AttributeProps[]> => {
		return parseApiData(
			restSecurityApi.post(`${this.getOrganizationId()}/${resourceType}/attributes`, formData, headers)
		);
	};

	static updateAvailability(
		availability: AvailabilityApi,
		params?: Record<string, any>,
		organizationId?: number
	): Promise<AvailabilityApi> {
		return parseApiData(
			restSecurityApi.put(`${organizationId}/availabilities/${availability.id}`, availability, {
				params,
			})
		);
	}

	static createAvailability(availability: AvailabilityApi, organizationId?: number): Promise<AvailabilityApi> {
		return parseApiData(restSecurityApi.post(`${organizationId}/availabilities`, availability));
	}

	static removeAvailability(id: number, organizationId?: number): Promise<{}> {
		return parseApiData(restSecurityApi.delete(`${organizationId}/availabilities/${id}`));
	}

	static restoreAvailability(id: number, organizationId?: number): Promise<AvailabilityApi> {
		return parseApiData(restSecurityApi.put(`${organizationId}/availabilities/${id}/restore`));
	}

	static getAvailability(
		id: number,
		params?: Record<string, any>,
		organizationId?: number
	): Promise<AvailabilityApi> {
		return parseApiData(restSecurityApi.get(`${organizationId}/availabilities/${id}`, { params }));
	}

	static getAvailabilities(
		params?: Record<string, any>,
		options?: Record<string, any>,
		organizationId?: number
	): Promise<AvailabilityApi[]> {
		return parseApiData(
			restSecurityApi.get(`${organizationId}/availabilities`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}

	static getOrderSync(
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<OrderSyncApi[]>>> {
		return parseApiData(
			restSecurityApi.get(`${apiOrganizationComponent.getOrganizationId()}/order_sync/report`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}
}

export { apiOrganizationComponent };
