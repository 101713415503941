import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Page404 from "go-app/components/Page404/Page404";
import SecurityProfilePage from "go-security/pages/Profile";
import { useAuth } from "go-security/services/auth";
import { selectUser } from "go-security/services/users/selectors";
import { SegmentContext, SegmentListService } from "go-segment/context";
import { apiUserSegment } from "../../../../services/Api/User/apiUserSegment";
import { getApiURL } from "../../../../utils/envutil";
import AdminPage from "../Admin";
import MigrationPage from "../Migration";
import UserReportsComboPage from "./pages/Combo";
import { UserDashboardPage } from "./pages/Dashboard";
import UserInvoicesPage from "./pages/Invoices";
import { JobsIndexPage } from "./pages/Jobs";
import UserLocationsPage from "./pages/Locations";
import { UserReportsPage } from "./pages/Reports";
import UserSegmentsPage from "./pages/Segments/UserSegmentsPage";

const UserPage = (): JSX.Element => {
	const user = useSelector(selectUser);
	const history = useHistory();
	const location = useLocation();
	const auth = useAuth(getApiURL());
	const segmentPath = `/segments`;
	const { addFlash } = useFlash();
	const isSupportRole = user?.roles?.includes("ROLE_SUPPORT");

	useEffect(() => {
		auth.logoutOrganization();
		if (!user?.accept_terms) {
			history.push({ pathname: "/accept_terms", state: location });
		}
	}, []);

	return (
		<SegmentContext.Provider
			value={
				new SegmentListService(
					{
						saveSegment: apiUserSegment.saveSegment,
						removeSegment: apiUserSegment.removeSegment,
						getSegments: apiUserSegment.getSegments,
						restoreSegment: apiUserSegment.restoreSegment,
						updateSegment: apiUserSegment.updateSegment,
						updateSegments: apiUserSegment.updateSegments,
						setAsDefault: apiUserSegment.setSegmentAsDefault,
						setNotDefault: apiUserSegment.setSegmentNotDefault,
					},
					segmentPath,
					apiUserSegment.getListInfo,
					addFlash
				)
			}
		>
			<Switch>
				<Route path={"/group_reports"} component={UserReportsPage} />
				<Route path={"/invoices"} component={UserInvoicesPage} />
				<Route path={"/segments"} component={UserSegmentsPage} />
				<Route path={"/profile"} component={SecurityProfilePage} />
				<Route path={"/migration"} component={MigrationPage} />
				<Route path={"/admin"} component={AdminPage} />
				<Route path={"/dashboard"} component={UserDashboardPage} />
				<Route path={`/combo_reports`} component={UserReportsComboPage} />
				{isSupportRole && <Route path={"/jobs"} component={JobsIndexPage} />}
				<Route exact path={"/"} component={UserLocationsPage} />
				<Page404 />
			</Switch>
		</SegmentContext.Provider>
	);
};

export default UserPage;
