import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PreviewSVG } from "../../../../images/svg/actions/preview.svg";

interface ActionProps {
	name?: string;
}

const PreviewAction: FC<ActionProps> = (props: ActionProps) => {
	const { t } = useTranslation();
	const name = props.name ? props.name : t("common.action.preview", { ns: "lib" });
	return (
		<div className="d-flex">
			<div className="common-actions icon">
				<PreviewSVG />
			</div>
			{name}
		</div>
	);
};
export default PreviewAction;
