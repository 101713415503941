import React, { useEffect, useState } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ReactComponent as MoreSvg } from "../../../../../../../../../images/svg/more.svg";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import { MenuApi, MenuItemApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import MenuGrid from "../components/Grid/MenuGrid";
import MenuList from "../components/List/MenuList";
import MenuModalForm from "../components/MenuModalForm";
import NoMenu from "../components/NoMenu";

const OrganizationMenuMenusIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [menus, setMenus] = useState<MenuApi[]>([]);
	const [initialMenu, setInitialMenu] = useState<MenuApi>();
	const [menuToEdit, setMenuToEdit] = useState<MenuApi | undefined>(undefined);
	const [selectedMenu, setSelectedMenu] = useState<MenuApi | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [defaultMenuLoading, setDefaultMenuLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const [isDirty, setIsDirty] = useState(false);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.menu.header.menu_config.title"));
		onFetchMenu();
	}, []);

	const onFetchMenu = async () => {
		setLoading(true);
		try {
			const params: Record<string, any> = {
				include: "pages,pages.items,pages.items.entity,pages.translations",
				size: 0,
			};
			const res = await apiOrganization.getMenus(params);
			const menusResponse = res;
			setMenus(menusResponse);
			if (menusResponse.length > 0) {
				const defaultMenu = menusResponse.find((menuResponseItem) => menuResponseItem.is_default);
				const menu = checkMenu(defaultMenu ?? menusResponse[0]);
				setInitialMenu(JSON.parse(JSON.stringify(menu)));
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const checkMenu = (menu: MenuApi) => {
		if (menu.type === "GRID") {
			menu.pages.forEach((page) => {
				const newItems: MenuItemApi[] = [];
				for (let i: number = 0; i < 25; ++i) {
					newItems.push({} as MenuItemApi);
				}
				page.items.forEach((pageItem: MenuItemApi) => {
					newItems[pageItem.position] = pageItem;
				});
				page.items = newItems;
			});
		}
		setSelectedMenu(menu);
		return menu;
	};

	if (loading) {
		return <LoadingContainer />;
	}

	const confirmDialog = (msg: string) => {
		return new Promise((resolve, reject) => {
			const confirmed = window.confirm(msg);
			return confirmed ? resolve(true) : reject(false);
		});
	};

	const handleRemoveMenu = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		if (selectedMenu) {
			try {
				await api.organization().removeMenu(selectedMenu.id);
				const newMenus = [...menus.filter((f) => f.id !== selectedMenu.id)];
				setMenus(newMenus);
				if (newMenus.length > 0) {
					checkMenu(newMenus[0]);
					setInitialMenu(newMenus[0]);
				}
				addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
	};

	const parseDuplicateMenu = () => {
		const menuCopy = selectedMenu;
		if (menuCopy) {
			setMenuToEdit({
				...menuCopy,
				id: 0,
				name: `${t("common.word.copy")} ${selectedMenu?.name}`,
			});
		}
	};

	const handleDuplicateMenu = async () => {
		if (isDirty) {
			await confirmDialog(t("modules.menu.field.unsaved_data_info.title"));
			parseDuplicateMenu();
		} else {
			parseDuplicateMenu();
		}
	};

	const handleSaveMenu = (data: MenuApi, id: number | undefined) => {
		setMenuToEdit(undefined);
		setIsDirty(false);
		if (id) {
			const updatedMenu = checkMenu(data);
			setInitialMenu(JSON.parse(JSON.stringify(updatedMenu)));
			menus[menus.findIndex((menu) => menu.id === id)] = updatedMenu;
			setMenus(menus);
		} else {
			const updatedMenu = checkMenu(data);
			if (updatedMenu.type === "LIST") {
				setInitialMenu(updatedMenu);
			} else setInitialMenu(JSON.parse(JSON.stringify(updatedMenu)));
			setSelectedMenu(updatedMenu);
			setMenus([...menus, updatedMenu]);
		}
	};

	const createMenu = async () => {
		await changeMenu(null);
		setMenuToEdit({ status: "ENABLED" } as MenuApi);
	};

	const updateMenu = async () => {
		await changeMenu(null);
		setMenuToEdit(selectedMenu);
	};

	const changeMenu = async (e: Record<string, any> | null) => {
		if (isDirty) {
			await confirmation({
				title: t("modules.menu.field.unsaved_data_info.title"),
				message: t("modules.menu.field.unsaved_data_info.message"),
			});
			const menu = menus.find((menuItem) => menuItem.id === e?.id);
			if (menu) {
				const newInitialMenu = checkMenu(menu);
				setInitialMenu(newInitialMenu);
			}
		} else {
			if (e === null) {
				return;
			}
			const menu = menus.find((menuItem) => menuItem.id === e.id);
			if (menu) {
				const newInitialMenu = checkMenu(menu);
				setInitialMenu(newInitialMenu);
			}
		}
	};

	const customStyles = {
		control: (provided: Record<string, any>) => ({
			...provided,
			minHeight: "30px",
			height: "30px",
		}),

		valueContainer: (provided: Record<string, any>) => ({
			...provided,
			height: "30px",
			padding: "0 6px",
		}),

		input: (provided: Record<string, any>) => ({
			...provided,
			margin: "0px",
		}),
		indicatorsContainer: (provided: Record<string, any>) => ({
			...provided,
			height: "30px",
		}),
		dropdownIndicator: (provided: Record<string, any>, state: Record<string, any>) => ({
			...provided,
			transform: state.selectProps.menuIsOpen && "rotate(180deg)",
		}),
	};

	const setMenuAsDefault = async () => {
		setDefaultMenuLoading(true);
		if (selectedMenu) {
			try {
				await api.organization().setDefaultMenu(selectedMenu.id);
				setSelectedMenu({ ...selectedMenu, is_default: true } as MenuApi);
				setMenus(
					menus.map((x) =>
						x.id === selectedMenu.id
							? { ...x, is_default: true }
							: {
									...x,
									is_default: false,
							  }
					)
				);
				addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
		setDefaultMenuLoading(false);
	};

	const formatOptionLabel = (menu: MenuApi) => {
		return (
			<div style={{ display: "flex" }}>
				<div>{menu.name}</div>
				{menu.is_default && (
					<div style={{ marginLeft: "4px", color: "#ccc" }}>({t("common.word.default")})</div>
				)}
				{menu.status === "DISABLED" && (
					<div
						className="text-danger"
						style={{
							marginLeft: "4px",
						}}
					>
						{t("modules.menu.field.inactive.title")}
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<Header title={t("modules.menu.header.menu_config.title")} />
			{menus?.length === 0 ? (
				<NoMenu handleSaveMenu={handleSaveMenu} />
			) : (
				<>
					<div className="row mb-3">
						<div className="col-md-6 menu-actions">
							<Select
								styles={customStyles}
								className="menu-select"
								value={selectedMenu}
								onChange={(e) => changeMenu(e)}
								formatOptionLabel={(menu: MenuApi) => formatOptionLabel(menu)}
								getOptionLabel={(menu: MenuApi) =>
									menu.is_default ? `${menu.name} (${t("common.word.default")})` : menu.name
								}
								getOptionValue={(opt: Record<string, any>) => opt.id}
								options={menus}
							/>
							{!selectedMenu?.is_default && (
								<div>
									<ButtonLoading
										onClick={() => setMenuAsDefault()}
										className="ms-auto"
										loading={defaultMenuLoading}
										variant={"primary"}
									>
										{t("modules.menu.action.set_default.title")}
									</ButtonLoading>
								</div>
							)}
							<div>
								<Dropdown as={ButtonGroup}>
									<Dropdown.Toggle className="contextual-menu" as={MoreSvg}>
										{t("common.action.more", { ns: "lib" })}
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={() => updateMenu()}>
											{t("common.action.edit", { ns: "lib" })}
										</Dropdown.Item>
										<Dropdown.Item onClick={() => createMenu()}>
											{t("common.action.add", { ns: "lib" })}
										</Dropdown.Item>
										<Dropdown.Item onClick={() => handleRemoveMenu()}>
											{t("common.action.remove", { ns: "lib" })}
										</Dropdown.Item>
										<Dropdown.Item onClick={() => handleDuplicateMenu()}>
											{t("common.action.copy", { ns: "lib" })}
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
					{menuToEdit && (
						<MenuModalForm
							menu={menuToEdit}
							handleClose={() => setMenuToEdit(undefined)}
							handleSave={handleSaveMenu}
						/>
					)}
					{selectedMenu &&
						initialMenu &&
						(selectedMenu.type === "GRID" && initialMenu.type === "GRID" ? (
							<MenuGrid
								menu={selectedMenu}
								initialMenu={initialMenu}
								handleSave={handleSaveMenu}
								isDirty={isDirty}
								menuChanged={(isMenuDirty) => setIsDirty(isMenuDirty)}
							/>
						) : (
							<MenuList
								key={selectedMenu.id}
								menu={selectedMenu}
								initialMenu={initialMenu}
								menuChanged={(isMenuDirty) => setIsDirty(isMenuDirty)}
								isDirty={isDirty}
								handleSave={handleSaveMenu}
							/>
						))}
				</>
			)}
		</>
	);
};
export default OrganizationMenuMenusIndexPage;
