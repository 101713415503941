import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import { ReactComponent as EReceiptSVG } from "../../../../../../../../../../images/svg/bills/external-number.svg";
import {
	OrderApi,
	OrderFiscalizationDeliveryType,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import SendReceiptModal from "../SendReceiptModal";

interface Props {
	order: OrderApi;
}

const FiscalizationERecepit = ({ order }: Props): JSX.Element => {
	const { t } = useTranslation();
	const { fiscalization } = order;
	const [downloadLoading, setDownloadLoading] = useState(false);
	const { addFlash } = useFlash();
	const [showSendViaEmailModal, setShowSendViaEmailModal] = useState(false);

	const getFiscalizationInfo = () => {
		if (!fiscalization.delivery || Object.keys(fiscalization.delivery).length === 0) return "";

		switch (fiscalization.delivery.type) {
			case OrderFiscalizationDeliveryType.EMAIL:
				return fiscalization.delivery.email;
			case OrderFiscalizationDeliveryType.SMS:
				return fiscalization.delivery.phone_number;
			default:
				return "";
		}
	};

	const handleDownloadEReceipt = async () => {
		setDownloadLoading(true);
		try {
			const response = await api.organization().getOrderEReceipt(order.id);
			const href = URL.createObjectURL(response.data);
			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", `${t(`modules.order.field.order_filename.title`)} #${order.number}.json`);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setDownloadLoading(false);
	};

	return (
		<>
			<div className="card-body-item">
				<EReceiptSVG />
				<div className="item-info">
					<span className="label">
						{`${t("modules.order.field.ereceipt.title")} (${FormatDate(
							fiscalization.created_at,
							undefined,
							false,
							defaultFormatDate
						)})`}
					</span>
					<span>{getFiscalizationInfo()}</span>
				</div>
			</div>
			<div className="row-buttons">
				<ButtonLoading
					variant="add"
					className="flex-grow-1"
					loading={downloadLoading}
					onClick={handleDownloadEReceipt}
				>
					{t("modules.order.action.download.title")}
				</ButtonLoading>
				<Button variant="add" className="flex-grow-1" onClick={() => setShowSendViaEmailModal(true)}>
					{t("lib:common.action.send")}
				</Button>
			</div>
			{showSendViaEmailModal && (
				<SendReceiptModal
					isShown={showSendViaEmailModal}
					onHide={() => setShowSendViaEmailModal(false)}
					order={order}
				/>
			)}
		</>
	);
};

export default FiscalizationERecepit;
