import { ApplicationState } from "../types";
import { OrganizationState } from "./types";

type hasPermissionType = (store: ApplicationState) => boolean;

export const selectOrganization = (store: ApplicationState): OrganizationState => store.organization;

export const hasPermission =
	(permission: string | string[], acceptEveryPermissionThatStartsWithGivenPermission?: boolean): hasPermissionType =>
	(store: ApplicationState) => {
		if (store.user?.admin) return true;
		if (Array.isArray(permission)) {
			return store.organization.permissions.some((userPermission) => {
				return permission.some((signlePermission) => {
					if (acceptEveryPermissionThatStartsWithGivenPermission) {
						return userPermission.startsWith(signlePermission);
					}
					return signlePermission === userPermission;
				});
			});
		}

		if (acceptEveryPermissionThatStartsWithGivenPermission) {
			return store.organization.permissions.some((userPermission) => {
				return userPermission.startsWith(permission);
			});
		}

		return store.organization.permissions.includes(permission);
	};
