import React from "react";
import { ReactComponent as GoAccountsSVG } from "../../images/svg/apps/goaccounts.svg";
import { ReactComponent as GoCRMSVG } from "../../images/svg/apps/gocrm.svg";
import { ReactComponent as GoHubSVG } from "../../images/svg/apps/gohub.svg";
import { ReactComponent as GoOrderSVG } from "../../images/svg/apps/goorder.svg";
import { ReactComponent as GoPOSSVG } from "../../images/svg/apps/gopos.svg";
import { ReactComponent as GoStockSVG } from "../../images/svg/apps/gostock.svg";

export const appIcon = (id: string): JSX.Element => {
	switch (id.toUpperCase()) {
		case "GOPOS":
			return <GoPOSSVG />;
		case "GOORDER":
			return <GoOrderSVG />;
		case "GOSTOCK":
			return <GoStockSVG />;
		case "GOCRM":
			return <GoCRMSVG />;
		case "GOACCOUNTS":
			return <GoAccountsSVG />;
		case "GOHUB":
			return <GoHubSVG />;
		default:
			return <></>;
	}
};
