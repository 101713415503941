import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormatResourceStatusLabel from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import { getComponentStatusClassName } from "go-component/Common/FormatStatus";

const getStatusTranslationKey = (status: string): string => {
	return `lib:go_security.enums.tenant.status.${status}`;
};
export const getStatusClassName = (
	status: string
): "primary" | "success" | "danger" | "warning" | "secondary" | "blue" | undefined => {
	let labelClass = getComponentStatusClassName(status);
	if (labelClass) return labelClass;
	switch (status) {
		case "DRAFT":
			labelClass = "warning";
			break;
		case "PREBUILT":
			labelClass = "secondary";
			break;
		case "TO_CREATE":
			labelClass = "blue";
			break;
		case "ACTIVE":
			labelClass = "success";
			break;
	}

	return labelClass;
};

interface Props {
	status: string;
}

const TenantStatus: FC<Props> = (props: Props): JSX.Element => {
	const status = props.status;
	const { t } = useTranslation();
	const labelClass = getStatusClassName(status);

	const translation = t(getStatusTranslationKey(status), { ns: ["translation", "lib"] });
	return <FormatResourceStatusLabel status={status} className={labelClass} translation={translation} />;
};

export default TenantStatus;
