import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import AppCard from "./AppCard";

interface Props {
	installedApps: ApplicationApi[];
	appTemplates: ApplicationTemplateApi[];
	routeProps: RouteComponentProps;
	handleUninstall: (app: ApplicationApi) => void;
	handleUpdate: (app: ApplicationApi) => void;
}

const InstalledApps: FC<Props> = ({ handleUpdate, installedApps, appTemplates, routeProps, handleUninstall }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const syncApplications = async () => {
		setLoading(true);
		try {
			await api.organization().syncApps();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}

		setLoading(false);
	};

	const buttons: ButtonProps[] = [];
	if (installedApps.length > 0)
		buttons.push({
			title: t("common.action.sync", { ns: "lib" }),
			action: () => syncApplications(),
			loading,
			variant: "primary",
		});

	return (
		<div>
			<Header title={t("modules.app.header.installed.title")} buttons={buttons} />
			<div className={"content-preview"}>
				<div className={"row"}>
					{installedApps.map((installedApp) => {
						const configApp = appTemplates.find((f) => f.provider === installedApp.provider);
						if (installedApp && configApp)
							return (
								<AppCard
									handleUpdate={handleUpdate}
									handleUninstall={handleUninstall}
									routeProps={routeProps}
									configApp={configApp}
									installedApp={installedApp}
									key={`${installedApp.id}-${installedApp.status}`}
								/>
							);
						return null;
					})}
				</div>
			</div>
		</div>
	);
};
export default InstalledApps;
