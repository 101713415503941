import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VenueSettingsApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import KioskSettingsColorInput from "./KioskSettingsColorInput";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	collapseIn: boolean;
}

const KioskSettingsColorsCard = ({ form, collapseIn }: Props) => {
	const { t } = useTranslation();

	return (
		<fieldset className="form-group">
			<h6>{t("modules.venue_settings.field.colors.title")}</h6>
			<div className="d-flex flex-column gap-2">
				<KioskSettingsColorInput
					name="font_color"
					form={form}
					label={t("modules.venue_settings.field.font_color.title")}
					collapseIn={collapseIn}
				/>
				<KioskSettingsColorInput
					name="accent_color"
					form={form}
					label={t("modules.venue_settings.field.accent_color.title")}
					collapseIn={collapseIn}
				/>
				<KioskSettingsColorInput
					name="background_color"
					form={form}
					label={t("modules.venue_settings.field.background_color.title")}
					collapseIn={collapseIn}
				/>
				<KioskSettingsColorInput
					name="curve_color"
					form={form}
					label={t("modules.venue_settings.field.curve_color.title")}
					collapseIn={collapseIn}
				/>
			</div>
		</fieldset>
	);
};

export default KioskSettingsColorsCard;
