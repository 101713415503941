import React, { MouseEvent } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import { adminApi } from "../../../../../../services/Api/Admin/adminApi";

const AdminIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const databaseMigrationHandler = async (e: MouseEvent<HTMLElement>) => {
		e.preventDefault();
		try {
			await adminApi.tenantMigration();
			addFlash({ type: "success", msg: t("lib:common.flash.completed") });
		} catch (err: unknown) {
			addFlash({ type: "danger", msg: t("modules.admin.constraints.failure") });
		}
	};

	const createCsvFile = (data: string) => {
		const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
		const encodedUri = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${t("modules.admin.field.licences.title")}.csv`);
		link.style.display = "none";
		document.body.appendChild(link);

		link.click();
	};

	const generateLicencesCSVHandler = async () => {
		try {
			const res: string = await adminApi.generateLicencesCSV();
			createCsvFile(res);
			addFlash({ type: "success", msg: "Wygenerowano plik CSV" });
		} catch (err: unknown) {
			addFlash({ type: "danger", msg: t("modules.admin.constraints.failure") });
		}
	};

	return (
		<div className="content-preview mt-3">
			<div className="d-flex flex-column align-items-start">
				<Button onClick={databaseMigrationHandler} className="mb-2">
					{t("modules.admin.action.migrate_databases.title")}
				</Button>
				<Button onClick={generateLicencesCSVHandler}>
					{t("modules.admin.action.generate_client_licenses_csv.title")}
				</Button>
			</div>
		</div>
	);
};

export default AdminIndexPage;
