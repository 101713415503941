import { Reducer } from "redux";
import { OrganizationActionTypes, OrganizationState } from "./types";

const initialState: OrganizationState = {
	currency: undefined,
	languages: [],
	id: undefined,
	name: undefined,
	status: undefined,
	permissions: [],
	applications: [],
	accept_privacy: undefined,
	accept_terms: undefined,
	accept_rules: undefined,
	alias: undefined,
	company_id: undefined,
};

export const organizationReducer: Reducer<OrganizationState> = (state = initialState, action) => {
	switch (action.type) {
		case OrganizationActionTypes.SET: {
			return { ...state, ...action.payload };
		}
		case OrganizationActionTypes.CLEAR: {
			return { ...initialState };
		}
		case OrganizationActionTypes.UPDATE: {
			return { ...state, ...action.payload };
		}
		default: {
			return state;
		}
	}
};
