import { DashboardFilterConfigType } from "./types";

export enum DashboardFilterActions {
	CHANGE_DATE_FILTER = "CHANGE_DATE_FILTER",
	CHANGE_ORGANIZATIONS_FILTER = "CHANGE_ORGANIZATIONS_FILTER",
	CHANGE_FORCE_APPLY_DATE_FILTER = "CHANGE_FORCE_APPLY_DATE_FILTER",
}

export const filtersConfigReducer = (
	state: DashboardFilterConfigType,
	action: Record<string, any>
): DashboardFilterConfigType => {
	switch (action.type) {
		case DashboardFilterActions.CHANGE_DATE_FILTER:
			window.localStorage.setItem("go_report.dashboard_filters.date_range", `bt=${action.data}`);
			return {
				...state,
				selectedFilter: {
					...state.selectedFilter,
					dateRange: action.data,
				},
			};
		case DashboardFilterActions.CHANGE_ORGANIZATIONS_FILTER:
			return {
				...state,
				selectedFilter: {
					...state.selectedFilter,
					organizationIds: action.data,
				},
			};
		case DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER:
			return {
				...state,
				forceApplyDateFilter: action.data,
			};
		default:
			return state;
	}
};
