import React from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";
import VirtualTableSort from "../../../../../../components/VirtualTable/VirtualTableSort";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../../components/VirtualTable/types";
import {
	getCurrentVirtualTableColumnSortingMode,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../../utils";

interface Props {
	columns: VirtualTableColumn[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	getHeaderStyles: (columnId: string) => { width: number };
	sortings: VirtualTableListSelectedSort[];
}

const ItemGroupGroupEditingVirtualTableHeaderPointsOfSaleColumns = ({
	getHeaderStyles,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	columns,
	sortings,
}: Props) => {
	const { t } = useTranslation();

	const drawColumns = () => {
		return columns.map((column, index) => {
			const isHide = isVirtualTableColumnHide(column.id, columns);
			const lastColumn = index === columns.length - 1;
			const className = `${isHide ? "d-none" : ""} sortable-column ${lastColumn ? "with-wider-border" : ""}`;

			if (column.id === ItemGroupGroupEditingColumn.POINT_OF_SALE) {
				return (
					<ReactResizeDetector
						key={column.id}
						onResize={(width) => handleHeaderResize(width, column.id.toString())}
					>
						<th
							id={column.id.toString()}
							key={column.id}
							title={t("common.word.point_of_sale")}
							className={className}
							onMouseDown={(e) => handleMouseDown(e)}
							onMouseUp={(e) => handleMouseUp(e, column.id)}
						>
							<div style={getHeaderStyles(column.id.toString())} className="d-flex align-items-center">
								{t("common.word.point_of_sale")}
							</div>
						</th>
					</ReactResizeDetector>
				);
			}

			return (
				<ReactResizeDetector
					key={column.id}
					onResize={(width) => handleHeaderResize(width, column.id.toString())}
				>
					<th
						id={column.id.toString()}
						key={column.id}
						title={column.name}
						className={className}
						onMouseDown={(e) => handleMouseDown(e)}
						onMouseUp={(e) => handleMouseUp(e, column.id.toString())}
					>
						<div style={getHeaderStyles(column.id.toString())} className="d-flex align-items-center">
							{column.name}
							<VirtualTableSort
								sortingMode={getCurrentVirtualTableColumnSortingMode(column.id.toString(), sortings)}
							/>
						</div>
					</th>
				</ReactResizeDetector>
			);
		});
	};

	return <>{drawColumns()}</>;
};

export default ItemGroupGroupEditingVirtualTableHeaderPointsOfSaleColumns;
