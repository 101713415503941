import React from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import { SaleDeletionType, SaleNonPermanentDeletionType } from "./services/types";

interface Props {
	deletionType: SaleDeletionType;
	nonPermanentDeletionType: SaleNonPermanentDeletionType;
	currentReportUid?: string;
	setPassword: (password: string) => void;
	loading: boolean;
	deleteSaleDataHandler: () => void;
	onCancel: () => void;
}

const ConfirmDeletion = ({
	deletionType,
	nonPermanentDeletionType,
	currentReportUid,
	setPassword,
	loading,
	deleteSaleDataHandler,
	onCancel,
}: Props): JSX.Element | null => {
	const { t } = useTranslation();
	const posReportChecked =
		nonPermanentDeletionType === SaleNonPermanentDeletionType.SALE_POS_REPORT_DELETION &&
		currentReportUid !== undefined;

	const dateRangeChecked =
		nonPermanentDeletionType === SaleNonPermanentDeletionType.SALE_DATE_RANGE_DELETION &&
		currentReportUid !== undefined;

	const permanentDeletionSelected = deletionType === SaleDeletionType.SALE_PERMANENT_DELETION;

	if (!(permanentDeletionSelected || posReportChecked || dateRangeChecked)) return null;

	return (
		<>
			<Form.Label>{t("lib:common.word.password")}:</Form.Label>
			<Form.Control type={"password"} onChange={(event) => setPassword(event.target.value)} />
			<ButtonLoading loading={loading} variant="primary" className="mt-2" onClick={deleteSaleDataHandler}>
				{t("modules.clear_data.action.clear_sale_data.title")}
			</ButtonLoading>
			<Button variant="light" onClick={onCancel} className="ms-1 mt-2">
				{t("lib:common.action.cancel")}
			</Button>
		</>
	);
};

export default ConfirmDeletion;
