import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import FormatResourceStatus from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { WorkplaceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	workplace: WorkplaceApi;
	onHide: () => void;
	handleSave: (workplaceApi: WorkplaceApi) => void;
}

const WorkplaceModalForm = (props: Props): JSX.Element => {
	const form = useForm<WorkplaceApi>({
		criteriaMode: "all",
		defaultValues: props.workplace,
	});
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		formState,
		control,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: WorkplaceApi) => {
		setLoading(true);
		data.id = props.workplace.id;
		try {
			if (data.id) {
				const res = await api.organization().updateWorkplace(data);
				props.handleSave(res);
			} else {
				const res = await api.organization().createWorkplace(data);
				props.handleSave(res);
			}
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			setLoading(false);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});
	const workplaceTypes = [
		{ value: "NONE", label: t("enums.workplaces.type.NONE") },
		{ value: "DRIVER", label: t("enums.workplaces.type.DRIVER") },
	];
	return (
		<Modal show={!!props.workplace} onHide={props.onHide}>
			<FormDirty formState={formState} key="workplace-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.workplace.id ? (
							<>
								{t("modules.employee_workplace.header.title")} "{props.workplace.name}"
								<FormatResourceStatus status={props.workplace.status} />
							</>
						) : (
							<>{t("modules.employee_workplace.header.new.title")}</>
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						register={register}
						name="name"
						errors={errors}
					/>
					<FormSelectGroup
						label={t("common.word.type", { ns: "lib" })}
						name="type"
						defaultValue={props.workplace.type}
						errors={errors}
						options={workplaceTypes}
						control={control}
						data-testid="type"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default WorkplaceModalForm;
