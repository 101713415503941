import React from "react";

interface Props {
	title: string;
	onClick: () => void;
	isActive: boolean;
}

const DashboardTab = ({ title, onClick, isActive }: Props) => {
	return (
		<div className={`dashboard-tab ${isActive ? "dashboard-tab--active" : ""}`} onClick={onClick}>
			{title}
		</div>
	);
};

export default DashboardTab;
