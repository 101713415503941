import React, { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../services/Api/types";
import { parseApplicationSettings, parseApplicationSettingsValues } from "../../../utils/utils";
import { AppState } from "../AppModal";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const WoltDriveFormModalCard: FC<Props> = ({ appState, handleUpdateApp }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings || {}),
			},
		},
	});
	const [loading, setLoading] = useState<boolean>(false);
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		formState,
		setError,
		control,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};

		try {
			const res = await api.organization().updateApp(newData, params);
			handleUpdateApp(res);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			reset({
				...res,
				settings: parseApplicationSettingsValues(res.settings),
			});
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} onSubmit={onSubmit} className="hotellion-app-form">
					<FormInput
						name="settings.venue_id"
						errors={errors}
						register={register}
						label={t("modules.app.field.venue_id.title")}
						disabled={true}
					/>
					<FormInput
						name="settings.merchant_id"
						errors={errors}
						register={register}
						label={t("modules.app.field.merchant_id.title")}
						disabled={true}
					/>
					<FormInput
						name="settings.api_key"
						errors={errors}
						register={register}
						label={t("modules.app.field.api_key.title")}
						disabled={true}
					/>
					<FormInput
						name="settings.support_url"
						errors={errors}
						register={register}
						label={t("modules.app.field.support_url.title")}
					/>
					<FormInput
						name="settings.support_email"
						errors={errors}
						register={register}
						label={t("modules.app.field.support_email.title")}
					/>
					<FormInput
						name="settings.support_phone_number"
						errors={errors}
						register={register}
						label={t("modules.app.field.support_phone_number.title")}
					/>
					<ButtonLoading loading={loading} variant="add" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};

export default WoltDriveFormModalCard;
