import { useCallback, useState } from "react";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FILTER_VALUE_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { ReportApi } from "go-report/core/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectToken } from "go-security/services/session/selectors";
import { DashboardAlertApi, DashboardApi, DashboardReportsApi } from "../../../services/Api/Organization/types";
import { apiReport } from "../../../services/Api/Report/apiReport";
import { api } from "../../../services/Api/api";
import { processDashboardReportRequestParams } from "../utils/reportConfigUtils";
import { ChartDataRangeType, DashboardChartConfig } from "./charts/types";
import { DashboardFilterConfigType } from "./filters/types";
import { DashboardType } from "./types";
import useDashboardContext from "./useDashboardContext";

const getRidOfForbiddenAccessErrorForOrganizationDashboard = (err: any, dashboardType: DashboardType) => {
	let newErr = err;
	if (dashboardType === DashboardType.ORGANIZATION && err?.data?.errors) {
		newErr = { ...err };
		newErr.data.errors = newErr.data.errors.filter((error: Record<string, any>) => error?.code !== "access_denied");
	}
	return newErr;
};

const useDashboardApi = (): Record<string, any> => {
	const [loading, setLoading] = useState(true);
	const [initialData, setInitialData] = useState<DashboardApi>();
	const { addFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const token = useSelector(selectToken);
	const { state, dashboardType } = useDashboardContext();
	const fetchDashboardData = () => {
		return api.organization().getDashboard();
	};
	const [rerenderDashboard, setRerenderDashboard] = useState<boolean>(true);

	const fetchDashboardAlerts = useCallback((controller: AbortController): Promise<DashboardAlertApi> => {
		return api.organization().getDashboardAlerts(controller);
	}, []);

	const fetchDashboardReports = useCallback((controller: AbortController): Promise<DashboardReportsApi> => {
		return api.organization().getDashboardReports(controller);
	}, []);

	const fetchClientsInformations = useCallback(
		(filtersConfig: DashboardFilterConfigType, controller: AbortController): Promise<AxiosResponse> => {
			return apiReport.getReportsCustom(
				processDashboardReportRequestParams({
					defaultGroup: "NONE",
					chartType: "",
					organizationId: organization.id,
					type: "order",
					size: 6,
					filtersConfig,
					dashboardType,
					include: "AVERAGE_PER_GUESTS",
				}),
				{ controller }
			);
		},
		[organization.id, dashboardType]
	);

	const fetchPaymentReports = useCallback(
		(
			filtersConfig: DashboardFilterConfigType,
			controller: AbortController,
			chartDataRangeType?: ChartDataRangeType
		): Promise<AxiosResponse<ReportApi>> => {
			return apiReport.getReportsCustom(
				processDashboardReportRequestParams({
					defaultGroup: "PAYMENT_METHOD",
					chartType: chartDataRangeType ?? "DAY_OF_WEEK",
					organizationId: organization.id,
					type: "order_payment",
					size: 3,
					filtersConfig,
					dashboardType,
				}),
				{ controller }
			);
		},
		[organization.id, dashboardType]
	);

	const fetchCategoryReports = useCallback(
		(
			filtersConfig: DashboardFilterConfigType,
			controller: AbortController,
			chartDataRangeType?: ChartDataRangeType
		): Promise<AxiosResponse<ReportApi>> => {
			return apiReport.getReportsCustom(
				processDashboardReportRequestParams({
					defaultGroup: "PRODUCT_CATEGORY",
					chartType: chartDataRangeType ?? "DAY_OF_WEEK",
					organizationId: organization.id,
					type: "order_item",
					size: 6,
					filtersConfig,
					dashboardType,
				}),
				{ controller }
			);
		},
		[organization.id, dashboardType]
	);

	const fetchProductReports = useCallback(
		(
			filtersConfig: DashboardFilterConfigType,
			controller: AbortController,
			chartDataRangeType?: ChartDataRangeType
		): Promise<AxiosResponse<ReportApi>> => {
			return apiReport.getReportsCustom(
				processDashboardReportRequestParams({
					defaultGroup: "PRODUCT",
					chartType: chartDataRangeType ?? "DAY_OF_WEEK",
					organizationId: organization.id,
					type: "order_item",
					size: 6,
					filtersConfig,
					dashboardType,
				}),
				{ controller }
			);
		},
		[organization.id, dashboardType]
	);

	const fetchSalesReports = useCallback(
		(
			filtersConfig: DashboardFilterConfigType,
			controller: AbortController,
			chartDataRangeType?: ChartDataRangeType
		): Promise<AxiosResponse<ReportApi>> => {
			return apiReport.getReportsCustom(
				processDashboardReportRequestParams({
					defaultGroup: "ORDER_TYPE",
					chartType: chartDataRangeType ?? "DAY_OF_WEEK",
					organizationId: organization.id,
					type: "order",
					size: 6,
					filtersConfig,
					dashboardType,
				}),
				{ controller }
			);
		},
		[organization.id, dashboardType]
	);

	const fetchSalesChartReports = useCallback(
		(
			filtersConfig: DashboardFilterConfigType,
			controller: AbortController,
			chartDataRangeType?: ChartDataRangeType
		): Promise<AxiosResponse<ReportApi>> => {
			return apiReport.getReportsCustom(
				processDashboardReportRequestParams({
					defaultGroup: "NONE",
					chartType: chartDataRangeType ?? "DAY_OF_WEEK",
					organizationId: organization.id,
					type: "order",
					filtersConfig,
					include: "AVERAGE_PER_GUESTS",
					dashboardType,
				}),
				{ controller }
			);
		},
		[organization.id, dashboardType]
	);

	const getReportLastUpdateDate = useCallback(() => {
		const URLPathname = window.location.pathname;
		const isGroupLocationsDashboard = URLPathname.includes("/dashboard");
		let organizationParam = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}organization_id|e=`;
		if (isGroupLocationsDashboard) {
			let listOfOrganizations = state.filtersConfig.selectedFilter.organizationIds || "";
			listOfOrganizations = listOfOrganizations.replaceAll(",", FILTER_VALUE_SEPARATOR);
			organizationParam = `${organizationParam}${listOfOrganizations}`;
		} else {
			let selectedOrganization = URLPathname.substring(1);
			selectedOrganization = selectedOrganization.replaceAll(",", FILTER_VALUE_SEPARATOR);
			organizationParam = `${organizationParam}${selectedOrganization}`;
		}
		const params = {
			access_token: token,
			f: btoa(unescape(encodeURIComponent(organizationParam))),
			type: "ORDER",
		};
		return apiReport.getReportLastUpdateDate(params);
	}, [token, state.filtersConfig.selectedFilter.organizationIds]);

	const getTheOldestUpdate = (updates: { organizations: { last_sale_sync_at: string }[] }): string | undefined => {
		if (updates?.organizations?.length === 0) return undefined;
		const updateDates = updates?.organizations
			?.filter((update) => update.last_sale_sync_at)
			.map((update) => new Date(update.last_sale_sync_at).getTime());
		const oldestTimestamp = Math.min(...updateDates);
		return new Date(oldestTimestamp).toISOString();
	};

	const fetchData = useCallback(
		async (
			filtersConfig: DashboardFilterConfigType,
			chartConfig: DashboardChartConfig,
			controller: AbortController
		) => {
			setLoading(true);
			setRerenderDashboard(true);

			try {
				let response, data: Record<string, any>;

				if (dashboardType === DashboardType.ORGANIZATION) {
					response = await Promise.all([
						fetchDashboardAlerts(controller),
						fetchDashboardReports(controller),
						fetchPaymentReports(filtersConfig, controller, chartConfig.reportPayment.range),
						fetchProductReports(filtersConfig, controller, chartConfig.reportProduct.range),
						fetchCategoryReports(filtersConfig, controller, chartConfig.reportCategory.range),
						fetchSalesReports(filtersConfig, controller, chartConfig.reportSales.range),
						fetchSalesChartReports(filtersConfig, controller, chartConfig.reportSalesAdvanced.range),
						fetchClientsInformations(filtersConfig, controller),
						getReportLastUpdateDate(),
					]);

					const [
						alerts,
						reports,
						payment_reports,
						product_reports,
						category_reports,
						sales_reports,
						sales_advanced_reports,
						clients_informations,
						last_update,
					] = response;

					const lastUpdateValue = last_update?.data?.organizations[0]?.last_sale_sync_at;

					data = {
						alerts,
						reports,
						payment_reports: payment_reports?.data,
						product_reports: product_reports?.data,
						category_reports: category_reports?.data,
						sales_reports: sales_reports?.data,
						sales_advanced_reports: sales_advanced_reports?.data,
						clients_informations: clients_informations?.data,
						last_update: lastUpdateValue,
					};
				} else {
					response = await Promise.all([
						fetchPaymentReports(filtersConfig, controller, chartConfig.reportPayment.range),
						fetchProductReports(filtersConfig, controller, chartConfig.reportProduct.range),
						fetchCategoryReports(filtersConfig, controller, chartConfig.reportCategory.range),
						fetchSalesReports(filtersConfig, controller, chartConfig.reportSales.range),
						fetchSalesChartReports(filtersConfig, controller, chartConfig.reportSalesAdvanced.range),
						fetchClientsInformations(filtersConfig, controller),
						getReportLastUpdateDate(),
					]);

					const [
						payment_reports,
						product_reports,
						category_reports,
						sales_reports,
						sales_advanced_reports,
						clients_informations,
						last_update,
					] = response;

					const lastUpdateValue = getTheOldestUpdate(last_update?.data);

					data = {
						payment_reports: payment_reports?.data,
						product_reports: product_reports?.data,
						category_reports: category_reports?.data,
						sales_reports: sales_reports?.data,
						sales_advanced_reports: sales_advanced_reports?.data,
						clients_informations: clients_informations?.data,
						last_update: lastUpdateValue,
					};
				}

				setInitialData((prevInitialData) => ({ ...prevInitialData, ...data }));
			} catch (err: any) {
				setInitialData(undefined);
				const newErr = getRidOfForbiddenAccessErrorForOrganizationDashboard(err, dashboardType);
				handleError.alert(newErr, addFlash);
			} finally {
				setLoading(false);
				setRerenderDashboard(false);
			}
		},
		[
			addFlash,
			fetchDashboardAlerts,
			fetchDashboardReports,
			fetchPaymentReports,
			fetchProductReports,
			fetchCategoryReports,
			fetchSalesReports,
			fetchSalesChartReports,
			dashboardType,
			getReportLastUpdateDate,
			fetchClientsInformations,
		]
	);
	return {
		fetchDashboardAlerts,
		fetchDashboardReports,
		fetchDashboardData,
		fetchData,
		loading,
		initialData,
		fetchPaymentReports,
		fetchCategoryReports,
		fetchProductReports,
		fetchSalesReports,
		fetchSalesChartReports,
		rerenderDashboard,
	};
};
export default useDashboardApi;
