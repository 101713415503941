import React, { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import { apiOrganization } from "../../../../../../../../../../../services/Api/Organization/apiOrganization";
import { VenueSettingsApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { SearchSelectApi } from "../../../../../../../../../../../services/Api/types";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
}

const LiveOrdersSettingsCard = ({ form }: Props) => {
	const {
		formState: { errors },
		control,
		setValue,
		watch,
	} = form;
	const { t } = useTranslation();
	const [collapseIn, setCollapseIn] = useState(false);

	const handleCollapse = () => {
		const collapse = collapseIn;
		setCollapseIn(!collapse);
		const icon = document.getElementById("printout-icon");
		if (icon) {
			icon.style.transform = !collapse ? "rotate(180deg)" : "";
		}
	};

	const searchTerminals = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return apiOrganization.getTerminalsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const updateCloseOrderTerminal = (_: string, obj: SearchSelectApi) => {
		if (obj) setValue(`close_order_terminal.name`, obj.label);
		else {
			setValue(`close_order_terminal`, undefined);
		}
	};

	return (
		<Card className="venue-settings">
			<Card.Body>
				<div className="title" onClick={handleCollapse}>
					<h5>{t("modules.venue_settings.field.live_orders.title")}</h5>
					<CollapseSVG id="printout-icon" className="collapse-icon" />
				</div>
				<Collapse in={collapseIn}>
					<div className="row">
						<div className="col-md-6">
							<FormSelectGroup
								label={t(`modules.venue_settings.field.close_order_terminal.label`)}
								name={`close_order_terminal.id`}
								errors={errors}
								defaultValue={{
									label: watch("close_order_terminal")?.name,
									id: watch("close_order_terminal")?.id,
								}}
								getOptionValue={(option) => option.id}
								loadOptions={searchTerminals}
								control={control}
								onChange={updateCloseOrderTerminal}
								data-testid={`close_order_terminal.id`}
							/>
						</div>
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};

export default LiveOrdersSettingsCard;
