import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";

interface Props {
	isShown: boolean;
	onHide: () => void;
	invoice: InvoiceApi;
	externalOrganization?: number;
	onSuccess?: () => void;
}

const SendInvoiceByEmailModal: FC<Props> = ({ isShown, onHide, invoice, externalOrganization, onSuccess }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useForm<{ email: string }>({
		criteriaMode: "all",
		defaultValues: {
			email: invoice.recipient?.contact?.email || "",
		},
	});
	const {
		register,
		formState,
		handleSubmit,
		formState: { errors },
		setError,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: { email: string }) => {
		setLoading(true);
		try {
			const externalOrg = externalOrganization ? externalOrganization : undefined;
			await api.organization().sendInvoiceByEmail(invoice.id, { email: data.email }, externalOrg);
			addSuccessFlash(t("lib:common.flash.completed"));
			if (onSuccess) onSuccess();
			onHide();
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={isShown} onHide={onHide}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t(`modules.invoice.action.send_invoice_by_email_modal.${invoice.document_type}.title`)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("common.word.email", { ns: "lib" })}
						name={"email"}
						errors={errors}
						register={register}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit">
						{t("common.action.send", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default SendInvoiceByEmailModal;

// t("modules.invoice.action.send_invoice_by_email_modal.INVOICE.title");
// t("modules.invoice.action.send_invoice_by_email_modal.CORRECTION.title");
// t("modules.invoice.action.send_invoice_by_email_modal.ADVANCE.title");
// t("modules.invoice.action.send_invoice_by_email_modal.PROFORMA.title");
