import React, { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck } from "go-form";
import { ClearVenueCustomFormProps } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	loading: boolean;
	handleClearVenueCustom: (data: ClearVenueCustomFormProps, password: string) => void;
}

const ClearVenueCustom: FC<Props> = ({ loading, handleClearVenueCustom }) => {
	const form = useForm<ClearVenueCustomFormProps>({
		defaultValues: {
			remove_menus: false,
			remove_item_groups_and_items: false,
			remove_discounts: false,
			remove_categories: false,
			remove_price_lists: false,
			remove_modifier_groups: false,
			remove_availabilities: false,
			remove_rooms: false,
			remove_payment_methods: false,
			remove_printout_templates: false,
			remove_clients: false,
			remove_employees: false,
			remove_segments: false,
			remove_custom_fields: false,
			remove_venue_roles_and_permissions: false,
			remove_default_comments: false,
			remove_taxes: false,
			remove_directions: false,
			remove_workplaces: false,
			remove_point_of_sales: false,
			remove_notification_templates: false,
		},
	});
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
		getValues,
	} = form;
	const [password, setPassword] = useState("");

	const clearVenueCustom = () => {
		handleClearVenueCustom(getValues(), password);
	};

	return (
		<>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_menus.title")}
				name={"remove_menus"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_item_groups_and_items.title")}
				name={"remove_item_groups_and_items"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_discounts.title")}
				name={"remove_discounts"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_categories.title")}
				name={"remove_categories"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_price_lists.title")}
				name={"remove_price_lists"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_modifier_groups.title")}
				name={"remove_modifier_groups"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_availabilities.title")}
				name={"remove_availabilities"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_rooms.title")}
				name={"remove_rooms"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_payment_methods.title")}
				name={"remove_payment_methods"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_printout_templates.title")}
				name={"remove_printout_templates"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_clients.title")}
				name={"remove_clients"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_employees.title")}
				name={"remove_employees"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_segments.title")}
				name={"remove_segments"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_custom_fields.title")}
				name={"remove_custom_fields"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_venue_roles_and_permissions.title")}
				name={"remove_venue_roles_and_permissions"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_default_comments.title")}
				name={"remove_default_comments"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_taxes.title")}
				name={"remove_taxes"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_directions.title")}
				name={"remove_directions"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.clear.action.remove_workplaces.title")}
				name={"remove_workplaces"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type="switch"
				label={t("modules.clear.action.remove_point_of_sales.title")}
				name="remove_point_of_sales"
				errors={errors}
				register={register}
			/>
			<FormCheck
				type="switch"
				label={t("modules.clear.action.remove_notification_templates.title")}
				name="remove_notification_templates"
				errors={errors}
				register={register}
			/>
			<Form.Group className="form-group">
				<Form.Label>{`${t("lib:common.word.password")}:`}</Form.Label>
				<Form.Control type={"password"} onChange={(event) => setPassword(event.target.value)} />
			</Form.Group>
			<ButtonLoading loading={loading} variant="primary" onClick={clearVenueCustom}>
				{t("modules.clear.action.clear_data.title")}
			</ButtonLoading>
		</>
	);
};
export default ClearVenueCustom;
