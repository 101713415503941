import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import FormatResourceStatus from "../../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { InvoiceApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import ConfirmedInvoiceWarning from "../../../../../components/ConfirmedInvoiceWarning";
import ExportInvoiceConfirmationModal from "../../../../../components/ExportInvoicesConfirmationModal/ExportInvoiceConfirmationModal";
import InvoicePdfPreviewModal from "../../../../../components/InvoicePdfPreviewModal/InvoicePdfPreviewModal";
import AddPreviousAdvanceModal from "../../../../../components/Preview/AddPreviousAdvanceModal";
import InvoicePreview from "../../../../../components/Preview/InvoicePreview";
import SendInvoiceByEmailModal from "../../../../../components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import {
	exportInvoicePdf,
	generateInvoicePreviewPdfLink,
	printInvoicePdf,
} from "../../../../../services/exportInvoice";

interface MatchParams {
	advance_id: string;
}

export const invoicePreviewIncludes = {
	include:
		"order_items,order_items.tax,previous_advances,custom_fields,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver,issuer,linked_invoices",
};

const OrganizationInvoicesAdvancesPreviewPage = (): JSX.Element => {
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const [showLogs, setShowLogs] = useState<boolean>(false);
	const { advance_id } = useParams<MatchParams>();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [showAdvancesModal, setShowAdvancesModal] = useState(false);
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const [invoicePdfLink, setInvoicePdfLink] = useState<string>("");
	const hasVenueInvoiceEditPermission = useSelector(
		hasPermission(["VENUE_OWN_INVOICE_MANAGE", "VENUE_INVOICE_EDIT"])
	);
	const isAdmin = useSelector(selectIsAdmin);
	const [showConfirmationExportInvoice, setShowConfirmationExportInvoice] = useState<boolean>(false);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const params: Record<string, any> = invoicePreviewIncludes;
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		Promise.all([
			api.organization().getInvoice(Number(advance_id), params),
			api.organization().getCustomFields(cfParams),
		])
			.then(([invoiceResponse, cfResponse]) => {
				setInvoice(invoiceResponse);
				setCustomFieldsConfig(cfResponse);
				handleChangeTabTitle(`${invoiceResponse.number} | ${t("modules.invoice.field.advance.title")}`);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, [advance_id]);

	if (!invoice || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const removeInvoice = async (invoiceToBeRemoved: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoiceToBeRemoved.id);
			history.push(`/${organization.id}/invoices`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const createFinalInvoice = () => {
		history.push({
			pathname: `/${organization.id}/invoices/new`,
			state: {
				advance: invoice,
				isFinalInvoice: true,
			},
		});
	};

	const exportInvoiceToPdf = () => {
		if (invoice.status === "OPEN") {
			setShowConfirmationExportInvoice(true);
		} else {
			exportInvoicePdf(invoice, t);
		}
	};

	const buttons: ButtonProps[] = [];
	if (!invoice.linked_invoices.find((inv) => inv.document_type === "CORRECTION")) {
		if (!(!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin)) {
			buttons.push({
				title: t("common.action.edit", { ns: "lib" }),
				path: `/${organization.id}/invoices/advances/${invoice.id}/edit`,
				variant: "light",
			});
		}
	}
	buttons.push({
		title: t("common.action.export_pdf", { ns: "lib" }),
		action: exportInvoiceToPdf,
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.print", { ns: "lib" }),
		action: () => printInvoicePdf(invoice),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.preview", { ns: "lib" }),
		action: async () => {
			const link = await generateInvoicePreviewPdfLink(invoice);
			setInvoicePdfLink(link);
		},
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.remove", { ns: "lib" }),
		action: () => removeInvoice(invoice),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.action.create_final_invoice.title"),
		action: () => createFinalInvoice(),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.action.make_correction.title"),
		path: `/${organization.id}/invoices/${invoice.id}/corrections/new`,
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
		action: () => setShowSendInvoiceByEmailModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.action.add_advance.title"),
		action: () => setShowAdvancesModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("lib:common.word.logs"),
		action: () => setShowLogs(true),
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			hide: !(
				!invoice.linked_invoices.find((inv) => inv.document_type === "CORRECTION") &&
				!(!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin)
			),
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/invoices/advances/${invoice.id}/edit`,
		},
		{
			title: t("common.action.export_pdf", { ns: "lib" }),
			action: exportInvoiceToPdf,
		},
		{
			title: t("common.action.print", { ns: "lib" }),
			action: () => printInvoicePdf(invoice),
		},
		{
			title: t("common.action.preview", { ns: "lib" }),
			action: async () => {
				const link = await generateInvoicePreviewPdfLink(invoice);
				setInvoicePdfLink(link);
			},
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeInvoice(invoice),
		},
		{ title: t("modules.invoice.action.create_final_invoice.title"), action: () => createFinalInvoice() },
		{
			title: t("modules.invoice.action.make_correction.title"),
			path: `/${organization.id}/invoices/${invoice.id}/corrections/new`,
		},
		{
			title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
			action: () => setShowSendInvoiceByEmailModal(true),
		},
		{
			title: t("modules.invoice.action.add_advance.title"),
			action: () => setShowAdvancesModal(true),
		},
		{ title: t("lib:common.word.logs"), action: () => setShowLogs(true) },
	];

	const renderSubtitle = () => (
		<ul>
			<li className="me-1">{FormatDate(invoice.dates?.issued_at, undefined, false, defaultFormatDate)}</li>
			<li>{FormatMoney(invoice?.summary?.price_sum_gross)}</li>
		</ul>
	);

	const avatar = {
		color: "#1A86E0",
		date: invoice.dates?.issued_at,
	};

	const renderTitle = () => (
		<>
			{t("modules.invoice.field.advance.title")} {invoice.number}
			<FormatResourceStatus status={invoice.status === "OPEN" ? "NEW" : invoice.status} />
		</>
	);

	const changeInvoiceStatusToConfirmed = () => {
		setInvoice({
			...invoice,
			status: "CONFIRMED",
		});
	};

	return (
		<div className={"content-preview"}>
			<SendInvoiceByEmailModal
				isShown={showSendInvoiceByEmailModal}
				onHide={() => setShowSendInvoiceByEmailModal(false)}
				invoice={invoice}
				onSuccess={changeInvoiceStatusToConfirmed}
			/>
			<MobileActions actions={mobileActions} />
			<Header
				title={renderTitle()}
				subtitle={renderSubtitle()}
				avatar={avatar}
				buttons={isMobile ? [] : buttons}
			/>
			{!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin && (
				<ConfirmedInvoiceWarning />
			)}
			<InvoicePreview
				invoice={invoice}
				setInvoice={setInvoice}
				config={customFieldsConfig}
				handleUpdateInvoice={(data) => setInvoice(data)}
			/>
			{showAdvancesModal && (
				<AddPreviousAdvanceModal
					onAdd={(data) => {
						setInvoice(data);
						setShowAdvancesModal(false);
					}}
					onClose={() => setShowAdvancesModal(false)}
					invoice={invoice}
				/>
			)}
			{showConfirmationExportInvoice && (
				<ExportInvoiceConfirmationModal
					isShown={showConfirmationExportInvoice}
					onHide={() => setShowConfirmationExportInvoice(false)}
					invoices={[invoice]}
					onSuccessAction={changeInvoiceStatusToConfirmed}
				/>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={invoice.id}
					resourceType={"INVOICE"}
					onHide={() => setShowLogs(false)}
					organizationId={organization.id}
				/>
			)}
			{invoicePdfLink && (
				<InvoicePdfPreviewModal
					show={!!invoicePdfLink}
					onHide={() => setInvoicePdfLink("")}
					pdfLink={invoicePdfLink}
				/>
			)}
		</div>
	);
};
export default OrganizationInvoicesAdvancesPreviewPage;
