import React, { FC } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatStringToDate, getShortMonthName } from "go-core/components/Formatters/FormatDate";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { getRemValueInPixels } from "go-core/utils/domUtils";

interface Props {
	data: any;
	imageLink?: Record<string, any>;
	variant?: string;
	showColor?: boolean;
	description?: string;
	date?: string;
	link?: string;
	shouldOpenInNewTab?: boolean;
}

const ListImageName = (props: Props): JSX.Element => {
	const data = props.data;
	const imageLink = props.imageLink;
	if (!props.showColor && imageLink) {
		data.color = undefined;
	}
	const imageMargin = 5;
	const entityStatusWidthWithMargin = 16 + getRemValueInPixels(0.5);
	const content = props.link ? (
		<Link
			rel={props.shouldOpenInNewTab ? "noreferrer" : undefined}
			target={props.shouldOpenInNewTab ? "_blank" : undefined}
			to={props.link}
		>
			{data.name}
		</Link>
	) : (
		data.name
	);

	return (
		<div className="d-flex align-items-center">
			<RenderImage showColor={true} data={data} imageLink={imageLink} />
			{data.limitTextWidth !== undefined ? (
				<RenderLimitedText
					minWidth={data.limitTextWidth < 75 ? 75 : data.limitTextWidth}
					offsetWidth={imageMargin + (data.status ? entityStatusWidthWithMargin : 0)}
				>
					{content}
				</RenderLimitedText>
			) : (
				content
			)}
		</div>
	);
};

export const generateLabel = (name: string): string => {
	if (!name) return "";
	name = name.replace(/[^0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]/gi, " ");
	const nameParts = name.split(" ");
	if (nameParts.length === 1) return name.slice(0, 2);
	if (nameParts.length === 2) {
		if (nameParts[1] === "") return name.slice(0, 2);
	}
	return nameParts
		.map((name) => name.slice(0, 1))
		.join("")
		.slice(0, 3)
		.toUpperCase();
};
const getLabel = (label?: string, date?: string) => {
	if (label && !date) return generateLabel(label);
	if (date) return formatStringToDate(date).getDate().toString();
};

const getDescription = (t: TFunction, description?: string, date?: string) => {
	if (description && !date) return description;
	if (date) return getShortMonthName((formatStringToDate(date).getMonth() + 1).toString(), t);
};

const drawLabel = (label?: string, date?: string, description?: string) => {
	if (description) return <h3 className="mb-0 text-white font-weight-normal">{getLabel(label, date)}</h3>;
	return <span>{getLabel(label, date)}</span>;
};

export const RenderImage: FC<Props> = ({ data, imageLink, variant, showColor, description, date }) => {
	const { t } = useTranslation();

	let imgVariant = variant;
	if (!imgVariant) {
		imgVariant = date ? "lg" : "sm";
	}
	let name;
	if (!showColor && data.color && imageLink) {
		data.color = undefined;
	}

	if (!data.image_label) {
		name = data.label;
	} else {
		name = data.image_label;
	}

	if (!name || name === "") {
		name = data.name;
		if (data.label && data.label !== "") {
			name = data.label;
		}
		if (!name || name === "") {
			name = date;
		}
	}
	if (!imageLink && !data.text && !data.color && !showColor && !date) {
		return (
			<div className={`list-image-box ${imgVariant}`}>
				<span>{generateLabel(name)}</span>
			</div>
		);
	}
	if (data.color && imageLink) {
		imgVariant = `${imgVariant}-bordered`;
	}
	if (imageLink) {
		if (data.color) {
			return (
				<div className={`list-image-box ${imgVariant}`} style={{ border: `2px solid ${data.color}` }}>
					<img alt="user.jpg" className={`${imgVariant}`} src={imageLink.small} />
				</div>
			);
		}
		return (
			<div className={`list-image-box ${imgVariant}`}>
				<img alt="user.jpg" className={`${imgVariant}`} src={imageLink.small} />
			</div>
		);
	}
	if (name) {
		if (data.color) {
			return (
				<div className={`list-image-box ${imgVariant}`} style={{ backgroundColor: data.color }}>
					{drawLabel(name, date, getDescription(t, description, date))}
					{(description || date) && (
						<span className="description">{getDescription(t, description, date)}</span>
					)}
				</div>
			);
		}
		return (
			<div className={`list-image-box ${imgVariant} ${date && "bg-primary"}`}>
				{drawLabel(name, date, getDescription(t, description, date))}
				{(description || date) && <span className="description">{getDescription(t, description, date)}</span>}
			</div>
		);
	}
	return <div className={`list-image-box ${imgVariant}`} style={{ backgroundColor: data.color }} />;
};
export default ListImageName;
