import React from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";
import VirtualTableSort from "../../../../../../components/VirtualTable/VirtualTableSort";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../../components/VirtualTable/types";
import {
	getCurrentVirtualTableColumnSortingMode,
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../../utils";

interface Props {
	visibleDefaultColumns: VirtualTableColumn[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	maxZindex: number;
	getHeaderStyles: (columnId: string) => { width: number };
	sortings: VirtualTableListSelectedSort[];
}

const ItemGroupGroupEditingVirtualTableHeaderDefaultColumns = ({
	visibleDefaultColumns,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	maxZindex,
	getHeaderStyles,
	sortings,
}: Props) => {
	const { t } = useTranslation();

	const getColumnTitle = (id: string) => {
		switch (id) {
			case ItemGroupGroupEditingColumn.CATEGORY:
				return t("common.word.category");
			case ItemGroupGroupEditingColumn.ITEM_GROUP:
				return t("common.word.item_group");
			case ItemGroupGroupEditingColumn.NAME:
				return t("common.word.variant");
			case ItemGroupGroupEditingColumn.FULL_NAME:
				return t("common.word.item_full_name");
			default:
				return "";
		}
	};

	const drawColumns = () => {
		let prevLeftPosition = 0;
		return visibleDefaultColumns.map((col, index) => {
			const prevColumn = visibleDefaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const lastColumn = index === visibleDefaultColumns.length - 1;
			const isHide = isVirtualTableColumnHide(col.id, visibleDefaultColumns);

			return (
				<ReactResizeDetector onResize={(width) => handleHeaderResize(width, col.id)} key={`col-${col.id}`}>
					<th
						id={`column-${col.id}`}
						onMouseDown={(e) => handleMouseDown(e)}
						onMouseUp={(e) => handleMouseUp(e, col.id)}
						className={`default-column sticky-column sortable-column ${isHide ? "d-none" : ""} ${
							lastColumn ? "with-wider-border" : ""
						}`}
						style={{
							left: leftPosition,
							zIndex: maxZindex - index,
						}}
					>
						<div className="d-flex align-items-center" style={getHeaderStyles(col.id)}>
							{getColumnTitle(col.id)}
							<VirtualTableSort sortingMode={getCurrentVirtualTableColumnSortingMode(col.id, sortings)} />
						</div>
					</th>
				</ReactResizeDetector>
			);
		});
	};

	return <>{drawColumns()}</>;
};

export default ItemGroupGroupEditingVirtualTableHeaderDefaultColumns;
