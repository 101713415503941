import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { apiOrganization } from "go-security/services/Api/apiOrganization";
import { SaveAndAddActionType } from "../../../services/Api/Organization/types";

interface TranslationsApi {
	copyItemLabel: string;
	copyItemDescription: string;
	newItemLabel: string;
	newItemDescription: string;
	chooseItem: string;
	copyItemHeader: string;
}

interface FormProps {
	currentType: string;
	selectedItemId: number;
	selectedItem: Record<string, any>;
}

interface Props {
	translations: TranslationsApi;
	onFetchItems: (search: string, params: Record<string, any>, options?: Record<string, any>) => Promise<any>;
	itemValue: Record<string, any>;
	onHide: () => void;
	onSaveAndAdd: (action: SaveAndAddActionType, itemId?: string) => void;
}

export const SaveAndAddModal = ({
	itemValue,
	translations,
	onFetchItems,
	onHide,
	onSaveAndAdd,
}: Props): JSX.Element => {
	const form = useForm<FormProps>({
		defaultValues: {
			currentType: "COPY",
			selectedItem: itemValue,
			selectedItemId: itemValue?.id,
		},
	});
	const { copyItemLabel, copyItemDescription, newItemLabel, newItemDescription, chooseItem, copyItemHeader } =
		translations;
	const { control, getValues } = form;
	const { t } = useTranslation();
	const [currentType, setCurrentType] = useState("COPY");

	const createItem = () => {
		if (currentType === "COPY") {
			const { selectedItemId } = getValues();
			onSaveAndAdd(
				selectedItemId === itemValue.id.toString() ? SaveAndAddActionType.COPY : SaveAndAddActionType.EXISTING,
				selectedItemId.toString()
			);
		} else {
			onSaveAndAdd(SaveAndAddActionType.ADD);
		}
	};

	const searchItems = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return onFetchItems.bind(apiOrganization)(search, params, {
			cancelToken: options?.token,
		});
	};

	return (
		<FormDirty>
			<Modal style={{ marginTop: "100px" }} show={true} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{copyItemHeader}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<LimitComponentVisibility
						defaultVisible={currentType === "COPY"}
						classNames={"mb-3"}
						handleChange={() => setCurrentType("COPY")}
						id={"copy_item"}
						legendText={copyItemLabel}
						helpText={copyItemDescription}
						unlimitedContent={
							<FormSelectGroup
								name="selectedItemId"
								control={control}
								errors={[]}
								defaultValue={{
									id: itemValue.id,
									label: itemValue.name,
								}}
								placeholder={chooseItem}
								getOptionLabel={(opt) => opt.label}
								getOptionValue={(opt) => opt.id}
								loadOptions={searchItems}
								isClearable={false}
								data-testid="selectedItemId"
							/>
						}
					/>
					<LimitComponentVisibility
						defaultVisible={currentType === "NEW"}
						handleChange={() => setCurrentType("NEW")}
						id={"new_item"}
						legendText={newItemLabel}
						helpText={newItemDescription}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" type="submit" onClick={createItem}>
						{t("common.action.goto", { ns: "lib" })}
					</Button>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Modal>
		</FormDirty>
	);
};
