import React, { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import { FormatDurationSeconds, getDayName, getMonthName } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import ReportData from "go-report/reports";
import {
	getChartType,
	getLastMonthFilterBetween,
	reportParamsConverter,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { api } from "../../../../../../../../services/Api/api";
import { ReportKdsUtils } from "../../../../../../../../utils/reports/ReportKdsUtils";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "ORDER_PREPARATION_STATUS_CHANGE_ORDER_REPORT";
const resourceType = "ORDER_PREPARATION_STATUS_CHANGE_ORDER_REPORT";

const getKsdOrdersSelectedColumns = () => {
	const newSelectedColumns = [...ReportKdsUtils.getSelectedColumns()];
	newSelectedColumns.splice(3, 0, "average_receiving_time");

	return newSelectedColumns;
};

const getKsdOrdersColumns = (t: TFunction) => {
	const newColumns = [...ReportKdsUtils.getColumns(t)];
	newColumns.splice(3, 0, {
		id: "average_receiving_time",
		name: t("modules.kds_order_report.field.average_receiving_time.title"),
		renderView: (item: number) => {
			return FormatDurationSeconds(item, t, true, true);
		},
		renderCsv: (item: number) => `${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
	});

	return newColumns;
};

const Report = ({ resource }: ListState) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		reportConfigId: "kds_report_order",
		aggregatePrefix: "summary",
		type: "bill",
		chart: {
			title: t("modules.kds_order_report.header.report.title"),
			lineTitle: t("modules.kds_order_report.field.report_chart.title"),
			aggregate: "average_total_time",
			type: getChartType(),
		},
		groups: [
			{
				id: "DAY_OF_MONTH",
				name: t("common.word.day_of_month"),
			},
			{
				id: "MONTH",
				name: t("modules.kds_order_report.field.month.title"),
			},
			{
				id: "HOUR_OF_DAY",
				name: t("common.word.hour", { ns: "lib" }),
			},
			{
				id: "DATE",
				name: t("common.word.date", { ns: "lib" }),
			},
			{
				id: "DAY_OF_WEEK",
				name: t("common.word.day_of_week"),
			},
			{
				id: "EXECUTION_AT",
				name: t("modules.kds_order_report.field.execution_at.title"),
			},
			{
				id: "TYPE",
				name: t("modules.kds_order_report.field.order_type.title"),
			},
			{
				id: "SOURCE",
				name: t("common.word.source", { ns: "lib" }),
			},
			{
				id: "POINT_OF_SALE",
				name: t("common.word.point_of_sale"),
			},
			{
				id: "COMMENT",
				name: t("common.word.comment"),
			},
			{
				id: "EMPLOYEE_DELIVERY",
				name: t("modules.kds_order_report.field.employee_delivery.title"),
			},
			{
				id: "NUMBER",
				name: t("common.word.order_number"),
			},
			...ReportKdsUtils.getGroups(t),
		],
		selectedColumns: [...getKsdOrdersSelectedColumns(), "average_acceptance_time"],
		selectedGroups: ["NONE"],
		groupsInfo: {
			EMPLOYEE: (groupByValue: Record<string, any>) => {
				return groupByValue.id !== "null" ? (
					<Link to={`/${organization.id}/employees/${groupByValue.id}`} rel="noreferrer" target="_blank">
						{groupByValue.name}
					</Link>
				) : (
					"-"
				);
			},
			MONTH: (groupByValue: Record<string, any>) => getMonthName(groupByValue.name, t),
			DAY_OF_WEEK: (groupByValue: Record<string, any>) => getDayName(groupByValue.name, t),
		},
		showPercent: true,
		columns: [
			...getKsdOrdersColumns(t),
			{
				id: "average_acceptance_time",
				name: t("modules.kds_order_report.field.average_acceptance_time.title"),
				renderView: (item: number) => {
					return FormatDurationSeconds(item, t, true, true);
				},
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
		],
		filters: [
			{
				id: "date",
				name: t("modules.kds_order_report.field.created_at.title"),
				type: "date",
			},
			{
				id: "employee_delivery",
				name: t("modules.kds_order_report.field.employee_delivery.title"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "order_type",
				name: t("modules.kds_order_report.field.order_type.title"),
				type: "list",
				listOptions: {
					DINE_IN: t("enums.orders.types.DINE_IN"),
					DELIVERY: t("enums.orders.types.DELIVERY"),
					ROOM_SERVICE: t("enums.orders.types.ROOM_SERVICE"),
					PICK_UP: t("enums.orders.types.PICK_UP"),
				},
			},
			{
				id: "order_source",
				name: t("common.word.source", { ns: "lib" }),
				type: "text",
			},
			{
				id: "point_of_sale",
				name: t("common.word.point_of_sale"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPointsOfSaleSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "comment",
				name: t("common.word.comment"),
				type: "text",
			},
			{
				id: "order_number",
				name: t("common.word.order_number"),
				type: "text",
			},
			{
				id: "execution_at",
				name: t("modules.kds_order_report.field.execution_at.title"),
				type: "date",
			},
			{
				id: "average_acceptance_time",
				name: t("modules.kds_order_report.field.average_acceptance_time.title"),
				type: "number",
			},
			{
				id: "average_delivery_time",
				name: t("modules.kds_order_report.field.average_delivery_time.title"),
				type: "number",
			},
			{
				id: "average_total_time",
				name: t("modules.kds_order_report.field.average_total_time.title"),
				type: "number",
			},
			{
				id: "average_receiving_time",
				name: t("modules.kds_order_report.field.average_receiving_time.title"),
				type: "number",
			},
			...ReportKdsUtils.getFilters(t),
		],
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				groups: ["NONE"],
				columns: [...getKsdOrdersSelectedColumns(), "average_acceptance_time"],
				filters: [
					{
						filterId: "date",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			const chartParams = { ...params };

			delete chartParams.group_column;
			delete params.chart_type;
			chartParams.groups = [chartParams.chart_type];
			chartParams.duration_unit = "minute";
			delete chartParams.chart_type;
			return Promise.all([
				api
					.organization()
					.getKdsOrdersReport(reportParamsConverter(params), { cancelToken: sourceToken?.token }),
				api
					.organization()
					.getKdsOrdersReport(reportParamsConverter(chartParams), { cancelToken: sourceToken?.token }),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			params.groups = params.chart_type;
			params.duration_unit = "minute";
			delete params.group_column;
			delete params.chart_type;
			return api
				.organization()
				.getKdsOrdersReport(reportParamsConverter(params), { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.kds_order_report.header.title"),
			filename: t("modules.kds_order_report.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
	} as ReportConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

export const OrganizationReportsKdsOrdersPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};
