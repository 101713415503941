import React from "react";
import { AxiosResponse } from "axios";
import { TFunction } from "react-i18next";
import { ItemResponse, ListResponse } from "go-core/api/types";
import { RequestItemApi } from "./types";

export interface RequestItemContextProps {
	all: (
		params?: Record<string, any>,
		options?: Record<string, any>
	) => Promise<AxiosResponse<ListResponse<RequestItemApi>>>;
	get: (id: string, params?: Record<string, any>) => Promise<AxiosResponse<ItemResponse<RequestItemApi>>>;
	retry: (id: string, params?: Record<string, any>) => Promise<AxiosResponse>;
	getTypeOptions: () => Promise<string[]>;
}

export class RequestItemService {
	_api: RequestItemContextProps;
	_typeTranslationPrefix: string;
	_t: TFunction;

	constructor(api: RequestItemContextProps, typeTranslationPrefix: string, t: TFunction) {
		this._api = api;
		this._typeTranslationPrefix = typeTranslationPrefix;
		this._t = t;
	}

	api(): RequestItemContextProps {
		return this._api;
	}

	getTypeTitle(id: string): string {
		const translation = this._t(`${this._typeTranslationPrefix}.${id}`);
		return translation.includes(".") ? id : translation;
	}
}

export const RequestItemContext = React.createContext<RequestItemService>({} as RequestItemService);
