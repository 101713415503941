import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	CompanyInfoApi,
	InvoiceApi,
	InvoiceDatesApi,
	InvoiceDetailsApi,
	OrderApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../components/Errors/CompanyInfoNotFull";
import InvoiceDetailsNotExist from "../../../components/Errors/InvoiceDetailsNotExist";
import InvoiceForm from "../../../components/Form/InvoiceForm";

interface StateType {
	from: { pathname: string };
	order?: OrderApi & { additionalOrderIds?: string[]; additionalOrderNumbers?: string[] };
	proforma?: InvoiceApi;
	advance?: InvoiceApi;
	isFinalInvoice?: boolean;
}

const OrganizationInvoicesIndexNewPage = (): JSX.Element => {
	const { addFlash } = useFlash();
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const user = useSelector(selectUser);
	const { state } = useLocation<StateType>();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const maxInvoicesToShow = 5;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const generateDates = () => {
		const dateNow = new Date();
		let dates = {} as InvoiceDatesApi;
		if (state?.order) {
			dates.sold_at = state.order.closed_at;
			dates.issued_at = formatDateToString(dateNow);
			dates.payment_due_date = state.order.closed_at;
		} else if (state?.proforma) {
			dates = state.proforma.dates;
		} else if (state?.advance) {
			dates = state.advance.dates;
		} else {
			dates.issued_at = formatDateToString(dateNow);
			dates.sold_at = formatDateToString(dateNow);
			dates.payment_due_date = formatDateToString(new Date(dateNow.setDate(dateNow.getDate() + 14)));
		}
		return dates;
	};

	const getTabTitle = () => {
		if (state?.proforma) return t("modules.invoice.field.proforma.title_new.title");
		if (state?.advance) {
			return t("modules.invoice.header.add_advance.title");
		}
		return t("modules.invoice.action.add.title");
	};

	useEffect(() => {
		handleChangeTabTitle(getTabTitle());
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		Promise.all([
			api.organization().getInvoiceDetails(),
			api.organization().getCompanyInfo(),
			api.organization().getCustomFields(cfParams),
		])
			.then(([invoiceResponse, companyInfoResponse, cfResponse]) => {
				const data = invoiceResponse;
				setInvoiceDetails(invoiceResponse);
				setCompanyInfo(companyInfoResponse);
				const invoice = {
					type: "NET",
					document_type: "INVOICE",
					dates: generateDates(),
					signed_by: data.signed_by_logged_user ? user.name : data.signed_by,
					comment: data.default_comment,
					place_of_issue: data.place_of_issue,
				} as InvoiceApi;
				if (state?.proforma) {
					invoice.document_type = "PROFORMA";
					invoice.type = state?.proforma.type;
					invoice.items = state.proforma.items;
					invoice.payments = [...state.proforma.payments];
					invoice.recipient = state.proforma.recipient;
					invoice.receiver = state.proforma.receiver;
					invoice.receiver_id = state.proforma.receiver_id;
					invoice.recipient_id = state.proforma.recipient_id;
					invoice.custom_fields = state.proforma.custom_fields;
				}
				if (state?.advance) {
					invoice.type = state?.advance.type;
					invoice.document_type = "ADVANCE";
					invoice.items = state.advance.items;
					invoice.recipient = state.advance.recipient;
					invoice.receiver = state.advance.receiver;
					invoice.receiver_id = state.advance.receiver_id;
					invoice.recipient_id = state.advance.recipient_id;
					invoice.custom_fields = state.advance.custom_fields;
					invoice.advance_id = state.advance.id;
					invoice.order_items = [...state.advance.order_items];
					invoice.payments = [...state.advance.payments];
				}
				setInvoice(invoice);
				setCustomFieldsConfig(cfResponse);
			})
			.catch((e) => {
				handleError.alert(e, addFlash);
			});
	}, []);

	if (!invoice || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const checkIfCanRenderInvoiceForm = () => {
		let result = true;
		if (invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)))
			result = false;
		if (invoice && (!companyInfo || !companyInfo.name || !companyInfo.tax_id_no)) result = false;
		return result;
	};

	const renderTitle = () => {
		if (state?.order)
			return state?.order.additionalOrderNumbers ? (
				<>
					{t("modules.invoice.action.invoice_to_orders.title", {
						ids_list: state?.order.additionalOrderNumbers.slice(0, maxInvoicesToShow).join(", "),
					})}
					{state?.order.additionalOrderNumbers.length > maxInvoicesToShow && "..."}
				</>
			) : (
				<>
					{t("modules.invoice.action.invoice_to_order.title")}&nbsp;
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/sales/orders/${state.order.id}`}
					>
						{state.order.number}
					</Link>
					&nbsp;{t("common.word.from", { ns: "lib" })}&nbsp;{FormatDate(state?.order.closed_at)}
				</>
			);
		if (state?.proforma)
			return `${t("modules.invoice.field.invoice_from_proforma.title")} ${state.proforma.number}`;
		if (!state?.proforma && !state?.order && !state?.advance) return t("modules.invoice.action.add.title");
		if (state?.advance) return `${t("modules.invoice.action.invoice_from_advance.title")} ${state.advance.number}`;
		return "";
	};

	return (
		<>
			<Header title={renderTitle()} />
			{invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)) && (
				<InvoiceDetailsNotExist />
			)}
			{invoice &&
				(!companyInfo ||
					Object.keys(companyInfo).length === 0 ||
					!companyInfo.company_name ||
					!companyInfo.tax_id_no) && <CompanyInfoNotFull />}
			{checkIfCanRenderInvoiceForm() && (
				<InvoiceForm
					invoiceDetails={invoiceDetails}
					advance={state?.advance}
					proforma={state?.proforma}
					isFinalInvoice={state?.isFinalInvoice}
					order={state?.order}
					invoice={invoice}
					customFieldsConfig={customFieldsConfig}
				/>
			)}
		</>
	);
};
export default OrganizationInvoicesIndexNewPage;
