import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import SecurityAuthorizePage from "go-security/pages/Authorize/AuthorizePage";
import SecurityLoginPage from "go-security/pages/Login/LoginPage";
import SecurityLoginCodePage from "go-security/pages/LoginCode/LoginCodePage";
import SecurityLostPasswordPage from "go-security/pages/LostPassword/LostPasswordPage";
import SecurityRegisterPage from "go-security/pages/Register/RegisterPage";
import AppPage from "./pages/App";
import PublicPage from "./pages/Public";
import { getApiURL } from "./utils/envutil";

const Routes: FC = () => {
	const baseUrl = getApiURL();
	useBrowserTabTitle();
	return (
		<BrowserRouter>
			<Switch>
				{/*<Route path="/" component={UserPage}/>*/}
				<Route
					path="/login"
					component={(props: RouteComponentProps) => (
						<SecurityLoginPage {...props} displayUseOldGoposVersionText baseUrl={baseUrl} />
					)}
				/>
				<Route path="/login_code" component={SecurityLoginCodePage} />
				<Route path="/lost-password" component={SecurityLostPasswordPage} />
				<Route path="/register" component={() => <SecurityRegisterPage appName="GoPOS" baseUrl={baseUrl} />} />
				<Route
					path="/oauth_authorize"
					component={(props: RouteComponentProps) => <SecurityAuthorizePage {...props} baseUrl={baseUrl} />}
				/>
				<Route path="/public" component={PublicPage} />
				<Route path="/404_page" component={Page404} />
				<Route path="/" component={AppPage} />
				<Route component={Page404} />
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
