import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectUser } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	CompanyInfoApi,
	InvoiceApi,
	InvoiceDatesApi,
	InvoiceDetailsApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../../../components/Errors/CompanyInfoNotFull";
import InvoiceDetailsNotExist from "../../../../../components/Errors/InvoiceDetailsNotExist";
import InvoiceForm from "../../../../../components/Form/InvoiceForm";

interface StateType {
	proforma?: InvoiceApi;
}

const OrganizationInvoicesAdvancesNewPage = (): JSX.Element => {
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const [advance, setAdvance] = useState<InvoiceApi | undefined>(undefined);
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const { state } = useLocation<StateType>();
	const user = useSelector(selectUser);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const generateDates = () => {
		const dateNow = new Date();
		const dates = {} as InvoiceDatesApi;
		dates.issued_at = formatDateToString(dateNow);
		dates.sold_at = formatDateToString(dateNow);
		dates.payment_due_date = formatDateToString(new Date(dateNow.setDate(dateNow.getDate() + 14)));
		return dates;
	};

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.header.add_advance.title"));
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		Promise.all([
			api.organization().getInvoiceDetails(),
			api.organization().getCompanyInfo(),
			api.organization().getCustomFields(cfParams),
		])
			.then(([invoiceResponse, companyInfoResponse, cfResponse]) => {
				setInvoiceDetails(invoiceResponse);
				setCompanyInfo(companyInfoResponse);
				const data = invoiceResponse;
				const advance = {
					type: "NET",
					document_type: "ADVANCE",
					dates: generateDates(),
					signed_by: data.signed_by_logged_user ? user.name : data.signed_by,
					comment: data.default_comment,
					place_of_issue: data.place_of_issue,
				} as InvoiceApi;
				if (state?.proforma?.proforma_id) {
					const proforma = state?.proforma;
					advance.type = proforma?.type;
					advance.proforma_id = state.proforma.proforma_id;
					proforma?.issuer && (advance.issuer = proforma?.issuer);
					proforma?.recipient && (advance.recipient = proforma?.recipient);
					proforma?.receiver && (advance.receiver = proforma?.receiver);
					proforma?.recipient_id && (advance.recipient_id = proforma?.recipient_id);
					proforma?.receiver_id && (advance.receiver_id = proforma?.receiver_id);
					proforma?.items && (advance.items = [...proforma.items]);
					proforma?.items && (advance.payments = [...proforma.payments]);
				}

				setCustomFieldsConfig(cfResponse);
				setAdvance(advance);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	if (!advance || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const checkIfCanRenderInvoiceForm = () => {
		let result = true;
		if (advance && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)))
			result = false;
		if (advance && (!companyInfo || !companyInfo.name || !companyInfo.tax_id_no)) result = false;
		return result;
	};

	return (
		<>
			<Header title={t("modules.invoice.header.add_advance.title")} />
			{advance && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)) && (
				<InvoiceDetailsNotExist />
			)}
			{advance &&
				(!companyInfo ||
					Object.keys(companyInfo).length === 0 ||
					!companyInfo.company_name ||
					!companyInfo.tax_id_no) && <CompanyInfoNotFull />}
			{checkIfCanRenderInvoiceForm() && (
				<InvoiceForm
					invoiceDetails={invoiceDetails}
					invoice={advance}
					proformaAdvance={!!state?.proforma?.proforma_id}
					customFieldsConfig={customFieldsConfig}
				/>
			)}
		</>
	);
};
export default OrganizationInvoicesAdvancesNewPage;
