import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	CompanyInfoApi,
	InvoiceApi,
	InvoiceDetailsApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../../../components/Errors/CompanyInfoNotFull";
import InvoiceDetailsNotExist from "../../../../../components/Errors/InvoiceDetailsNotExist";
import CorrectionForm from "../../components/CorrectionForm";

interface Props extends RouteComponentProps {
	invoiceId: string;
}

const OrganizationInvoicesCorrectionsNewPage: FC<Props> = (props) => {
	const organization = useSelector(selectOrganization);
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const [correction, setCorrection] = useState<InvoiceApi | undefined>(undefined);
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const history = useHistory();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const cfParams: Record<string, string> = {
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
			include: "options,constraints",
		};
		const invParams: Record<string, string> = { include: "receiver,recipient" };
		Promise.all([
			api.organization().getInvoice(Number(props.invoiceId), invParams),
			api.organization().getCustomFields(cfParams),
			api.organization().getInvoiceDetails(),
			api.organization().getCompanyInfo(),
		])
			.then(([invoiceResponse, cfResponse, detailsResponse, companyInfo]) => {
				const inv = invoiceResponse;
				setInvoiceDetails(detailsResponse);
				setInvoice(inv);
				setCompanyInfo(companyInfo);
				setCustomFieldsConfig(cfResponse);
				setCorrection({
					dates: {
						sold_at: inv.dates.sold_at,
						issued_at: inv.dates.issued_at,
						payment_due_date: inv.dates.payment_due_date,
					},
					recipient: inv.recipient,
					recipient_id: inv.recipient_id,
					receiver: inv.receiver,
					receiver_id: inv.receiver_id,
					place_of_issue: inv.place_of_issue,
					comment: inv.comment,
					signed_by: inv.signed_by,
					document_type: "CORRECTION",
				} as InvoiceApi);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	useEffect(() => {
		handleChangeTabTitle(`${t("modules.invoice.field.correction.title")} ${invoice?.number}`);
	}, [invoice?.document_type]);

	if (!invoice || !customFieldsConfig || !correction) {
		return <LoadingContainer />;
	}

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/invoices/${invoice.id}`);

	const renderTitle = () => (
		<>
			{invoice.document_type === "ADVANCE"
				? t("modules.invoice.field.advance.title")
				: t("modules.invoice.header.title")}
			&nbsp;
			<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/invoices/${invoice.id}`}>
				{invoice.number}
			</Link>
		</>
	);

	return (
		<>
			<Header back={goBack} title={renderTitle()} />
			{invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)) ? (
				<InvoiceDetailsNotExist />
			) : (
				<>
					{invoice &&
					(!companyInfo ||
						Object.keys(companyInfo).length === 0 ||
						!companyInfo.company_name ||
						!companyInfo.tax_id_no) ? (
						<CompanyInfoNotFull />
					) : (
						<CorrectionForm
							invoice={invoice}
							correction={correction}
							customFieldsConfig={customFieldsConfig}
						/>
					)}
				</>
			)}
		</>
	);
};
export default OrganizationInvoicesCorrectionsNewPage;
