import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	NotificationTemplateApi,
	PreviewNotificationTemplateApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { NotificationTemplateInfoForm } from "./NotificationTemplateInfoForm";
import { NotificationTemplatePreviewRenderer } from "./NotificationTemplatePreviewRenderer";

interface Props {
	notificationTemplate?: NotificationTemplateApi;
	notificationPreviewData?: PreviewNotificationTemplateApi;
}

const getFormDefaultValues = (notificationTemplate?: NotificationTemplateApi) => {
	const defaultValues = { ...notificationTemplate, type: "EMAIL" };
	const includeReportDrawersSetting = defaultValues.settings?.INCLUDE_REPORT_DRAWERS;
	if (defaultValues.settings?.INCLUDE_REPORT_DRAWERS !== undefined) {
		defaultValues.settings.INCLUDE_REPORT_DRAWERS =
			typeof includeReportDrawersSetting === "string"
				? includeReportDrawersSetting === "true"
				: includeReportDrawersSetting ?? false;
	}
	return defaultValues;
};

export const NotificationTemplateForm = (props: Props) => {
	const { notificationTemplate } = props;
	const { t } = useTranslation();
	const [notificationPreviewData, setNotificationPreviewData] = useState<PreviewNotificationTemplateApi | undefined>(
		props.notificationPreviewData
	);
	const [renderPreviewLoading, setRenderPreviewLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { addSuccessFlash, addFlash } = useFlash();
	const form = useForm<NotificationTemplateApi>({ defaultValues: getFormDefaultValues(notificationTemplate) });
	const { formState, handleSubmit, reset, setError, watch } = form;
	const { validateCustomErrors, setErrors } = useCustomErrors(setError);

	const onSubmit = handleSubmit(async (data: NotificationTemplateApi) => {
		if (!validateCustomErrors()) {
			return;
		}
		try {
			setLoading(true);
			if (notificationTemplate?.id) {
				const res = await api.organization().updateNotificationTemplate(data);
				reset(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createNotificationTemplate(data);
				history.push(`/${organization.id}/settings/notification_templates/${res.id}`);
			}
		} catch (err) {
			handleError.form(err, setError, addFlash);
		} finally {
			setLoading(false);
		}
	});

	useEffect(() => {
		if (props.notificationPreviewData && notificationTemplate) {
			const { title, content } = notificationTemplate;
			setNotificationPreviewData(props.notificationPreviewData);
			reset({ ...watch(), title, content });
		}
	}, [props.notificationPreviewData, notificationTemplate, reset, watch]);

	return (
		<FormDirty className="mw-100" formState={formState} onSubmit={onSubmit} loading={loading}>
			<div className="row">
				<div className="col-xl-6">
					<NotificationTemplateInfoForm
						form={form}
						setNotificationPreviewData={setNotificationPreviewData}
						notificationTemplateCategory={notificationTemplate?.category}
						setErrors={setErrors}
						setRenderPreviewLoading={setRenderPreviewLoading}
					/>
				</div>
				<div className="col-xl-6">
					<NotificationTemplatePreviewRenderer
						notificationPreviewData={notificationPreviewData}
						renderPreviewLoading={renderPreviewLoading}
					/>
				</div>
			</div>
		</FormDirty>
	);
};
