import React from "react";
import { useTranslation } from "react-i18next";
import { getTransactionDateString } from "../../../utils";

interface OrderNumberProps {
	orderNumber: string;
	transactionDate?: string;
}

export const OrderNumber = ({ orderNumber, transactionDate }: OrderNumberProps): JSX.Element => {
	const { t } = useTranslation();
	const transactionDateString = getTransactionDateString(transactionDate);

	return (
		<h1 data-testid="e-order-order-details-order-number">
			{t("modules.e_order.field.order_title.title")} #{orderNumber}
			{transactionDateString && (
				<div data-testid="e-order-order-details-payment-details-date">{transactionDateString}</div>
			)}
		</h1>
	);
};
