import React, { FC, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loading } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as PreviewArrowSVG } from "../../../../../../../../../../images/svg/preview-arrow.svg";
import {
	EmployeeApi,
	EmployeeTransactionApi,
	TransactionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import LastTransactionsTable from "../../../../../../components/Transaction/LastTransactionsTable";

interface Props {
	employee: EmployeeApi;
	transactions: EmployeeTransactionApi[];
	loadingTransactions: boolean;
}

const LastTransactions: FC<Props> = ({ employee, transactions, loadingTransactions }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [showTransaction, setShowTransaction] = useState<null | number>(null);
	return (
		<Card>
			<Card.Body>
				<h5> {t("modules.employee.field.last_transactions.title")}</h5>
				{loadingTransactions ? (
					<Loading />
				) : (
					<>
						<LastTransactionsTable
							transactions={transactions as TransactionApi[]}
							showTransaction={showTransaction}
							setShowTransaction={setShowTransaction}
							rowActions={(transaction) => (
								<Button variant={"btn btn-light"} onClick={() => setShowTransaction(transaction.id)}>
									<PreviewArrowSVG />
								</Button>
							)}
						/>
						{transactions.length > 0 && (
							<Link
								to={`/${organization.id}/sales/transactions?f=${btoa(
									`${NEW_WAY_TO_ENCODING_FILTER_SIGN}employee|e=${employee.id}`
								)}`}
								className={"btn btn-add card-action"}
							>
								{t("common.action.show_more", { ns: "lib" })}
							</Link>
						)}
					</>
				)}
			</Card.Body>
		</Card>
	);
};
export default LastTransactions;
