import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { MoneyApi } from "go-core/api/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import {
	FormatDate,
	FormatDateToDateHoursRange,
	FormatDuration,
	filterParseDateBetween,
} from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import {
	ExternalListSelectedFilter,
	FilterType,
	ListFilterSource,
} from "go-list/core/components/Filter/services/types";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import ReportData from "go-report/reports";
import { getReportCustomFieldsResources, reportParamsConverter } from "go-report/reports/services/report-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { api } from "../../../../../../../../services/Api/api";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "WORK_TIME_REPORT";
const resourceType = "WORK_TIME_REPORT";

const Report: FC<ListState> = ({ resource }): JSX.Element | null => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();
	const columnsFromLocalStorage = window.localStorage.getItem(`go_report.worktimes.columns`)?.split(",");

	let config = {
		reportConfigId: "worktimes",
		aggregatePrefix: "summary",
		chartTitle: t("modules.work_time.header.title"),
		reportInternal: true,
		selectedColumns: columnsFromLocalStorage
			? columnsFromLocalStorage
			: ["work_duration_in_minutes", "to_pay_money"],
		showPercent: true,
		groups: [
			{
				id: "EMPLOYEE",
				name: t("components.reports.groups.EMPLOYEE"),
			},
			{
				id: "DATE_RANGE",
				name: t("components.reports.groups.DATE_RANGE"),
			},
			{
				id: "WORKPLACE",
				name: t("components.reports.groups.WORKPLACE"),
			},
			{
				id: "YEAR",
				name: t("modules.work_time.field.year.title"),
			},
			{
				id: "MONTH",
				name: t("modules.work_time.field.month.title"),
			},
			{
				id: "DAY_OF_MONTH",
				name: t("common.word.day_of_month"),
			},
			{
				id: "HOURLY_RATE",
				name: t("modules.work_time.field.hourly_rate.title"),
			},
		],
		selectedGroups: ["EMPLOYEE", "DATE_RANGE", "WORKPLACE"],
		columns: [
			{
				id: "work_duration_in_minutes",
				name: t("common.word.work_duration"),
				renderView: (item: number) => FormatDuration(item, t, true),
				renderCsv: (item: number) => (item / 60).toFixed(2),
			},
			{
				id: "to_pay_money",
				name: t("modules.work_time.field.to_pay.title"),
				render: (item: MoneyApi) => {
					return `${item.amount} ${item.currency}`;
				},
			},
		],
		groupsInfo: {
			EMPLOYEE: (groupByValue: Record<string, any>) => {
				return (
					<Link
						to={`/${organization.id}/employees/${groupByValue.reference_id}`}
						rel="noreferrer"
						target="_blank"
					>
						{groupByValue.name}
					</Link>
				);
			},
		},
		externalSelectedFilters: [
			{
				id: "date",
				filterType: "date" as FilterType,
				condition: "bt",
				value: `${filterParseDateBetween(
					new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0),
					new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
				).replace(",", FILTER_VALUE_SEPARATOR)}`,
				visible: true,
			} as ExternalListSelectedFilter,
		],
		filters: [
			{
				id: "date",
				name: t("modules.work_time.field.start_at.title"),
				type: "date",
			},
			{
				id: "working_at",
				name: t("modules.work_time.field.working_at.title"),
				type: "date",
			},
			{
				id: "work_duration_in_hours",
				name: t("modules.work_time.field.work_duration_in_hours.title"),
				type: "number" as FilterType,
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) => {
						return api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						});
					},
				} as ListFilterSource,
			},
			{
				id: "workplace",
				name: t("modules.employee.field.workplace.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getWorkplacesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "venue_role",
				name: t("modules.employee.field.role.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) => {
						return api.organization().getRolesSearchSelect(search, params, {
							cancelToken: options?.token,
						});
					},
				} as ListFilterSource,
			},
			{
				id: "rounding_type",
				name: t("modules.employee.field.rounding_type.title"),
				type: "radio" as FilterType,
				options: {
					NONE: t("enums.employees_online.rounding_type.NONE"),
					UP_5_MIN: t("enums.employees_online.rounding_type.UP_5_MIN"),
					UP_15_MIN: t("enums.employees_online.rounding_type.UP_15_MIN"),
					UP_30_MIN: t("enums.employees_online.rounding_type.UP_30_MIN"),
					DOWN_5_MIN: t("enums.employees_online.rounding_type.DOWN_5_MIN"),
					DOWN_15_MIN: t("enums.employees_online.rounding_type.DOWN_15_MIN"),
					DOWN_30_MIN: t("enums.employees_online.rounding_type.DOWN_30_MIN"),
					DOWN_60_MIN: t("enums.employees_online.rounding_type.DOWN_60_MIN"),
				},
			},
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list" as FilterType,
				options: {
					PAID: t("enums.worktimes.status.PAID"),
					CONFIRMED: t("enums.worktimes.status.CONFIRMED"),
					SKIPPED: t("enums.worktimes.status.SKIPPED"),
				},
			},
			{
				id: "report",
				name: t("common.word.pos_report"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: Record<string, any>) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at
						)})`;
					},
				} as ListFilterSource,
			},
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				groups: ["EMPLOYEE", "DATE_RANGE", "WORKPLACE"],
				columns: columnsFromLocalStorage
					? columnsFromLocalStorage
					: ["work_duration_in_minutes", "to_pay_money"],
				filters: [
					{
						filterId: "date",
						filterType: "date" as FilterType,
						condition: "bt",
						value: `${filterParseDateBetween(
							new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0),
							new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
						).replace(",", FILTER_VALUE_SEPARATOR)}`,
					},
				],
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			delete params.chart_type;
			return Promise.all([
				api.organization().getWorkTimesReport(reportParamsConverter(params), sourceToken?.token),
			]);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.work_time.field.export_config.title"),
			filename: t("modules.work_time.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
	} as ReportConfig;

	data.fields = getReportCustomFieldsResources(data.fields, ["EMPLOYEE"], t);

	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

const OrganizationEmployeesWorktimesReportPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "EMPLOYEE",
			name: t(`enums.custom_fields.resources.EMPLOYEE`),
		},
	];
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.work_time.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.work_time.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<Report resource={resource} />
			</Suspense>
		</div>
	);
};
export default OrganizationEmployeesWorktimesReportPage;
