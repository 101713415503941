import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import InvoiceCorrectionPositionsCard from "./InvoiceCorrectionPositionsCard";
import InvoiceInfoCard from "./InvoiceInfoCard";
import InvoiceIssuerCard from "./InvoiceIssuerCard";
import InvoiceReceiverCard from "./InvoiceReceiverCard";
import InvoiceTransactionsCard from "./InvoiceTransactionsCard";
import LinkedDocumentsCard from "./LinkedDocumentsCard";

interface Props {
	correction: InvoiceApi;
	setCorrection: (correction: InvoiceApi) => void;
	config: CustomFieldTemplateApi[];
}

const InvoiceCorrectionPreview: FC<Props> = ({ correction, setCorrection, config }) => {
	const { t } = useTranslation();

	return (
		<div className={"content-preview-body"}>
			<div className={"row"}>
				{correction.linked_invoices && correction.linked_invoices.length > 0 && (
					<div className={"col-md-12"}>
						<LinkedDocumentsCard invoices={correction.linked_invoices} />
					</div>
				)}
				<div className={"col-md-12"}>
					<InvoiceInfoCard invoice={correction} config={config} />
				</div>
				{correction.receiver && correction.recipient && correction.receiver ? (
					<>
						<div className={"col-md-4"}>
							<InvoiceIssuerCard issuer={correction.issuer} />
						</div>
						<div className={"col-md-4"}>
							<InvoiceReceiverCard
								id={correction.recipient_id}
								title={t("modules.invoice.field.recipient.title")}
								client={correction.recipient}
							/>
						</div>
						<div className={"col-md-4"}>
							<InvoiceReceiverCard
								id={correction.receiver_id}
								title={t("modules.invoice.field.receiver.title")}
								client={correction.receiver}
							/>
						</div>
					</>
				) : (
					<>
						{correction.issuer && (
							<div className={"col-md-6"}>
								<InvoiceIssuerCard issuer={correction.issuer} />
							</div>
						)}
						{correction.recipient && (
							<div className={"col-md-6"}>
								<InvoiceReceiverCard
									id={correction.recipient_id}
									title={t("modules.invoice.field.recipient.title")}
									client={correction.recipient}
								/>
							</div>
						)}
					</>
				)}
				<div className={"col-md-12"}>
					<InvoiceCorrectionPositionsCard
						correction={correction}
						items={
							correction.linked_invoices.find(
								(f) => f.document_type === "INVOICE" || f.document_type === "ADVANCE"
							)?.items
						}
					/>
				</div>

				<div className={"col-md-12"}>
					<InvoiceTransactionsCard
						invoice={correction}
						handleUpdateInvoice={(data) => setCorrection(data)}
						payments={correction.payments}
					/>
				</div>
			</div>
		</div>
	);
};
export default InvoiceCorrectionPreview;
