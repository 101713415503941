import React from "react";
import { useTranslation } from "react-i18next";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import { deliveryZonesColors } from "../../../../services/orderDelivery";

interface Props {
	deliveryZones: DeliveryZoneApi[];
}

const LiveOrdersMapDeliveryZonesLegend = ({ deliveryZones }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column">
			<h5>{t("modules.live_order.field.areas.title")}</h5>
			{deliveryZones.map((zone, index) => {
				const color = deliveryZonesColors[index];
				return (
					<div className="delivery-zone-legend" key={index}>
						<div className="delivery-zone-legend-color" style={{ backgroundColor: color }} />
						<span className="text-muted">{zone.name}</span>
					</div>
				);
			})}
		</div>
	);
};

export default LiveOrdersMapDeliveryZonesLegend;
