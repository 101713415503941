import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRightSVG } from "go-core/images/svg/arrow-right.svg";
import { OrganizationState } from "../../../services/organizations/types";
import { UserState } from "../../../services/users/types";

interface Props {
	user: UserState;
	organization: OrganizationState;
}

const NavigationOrganizationsProfileSecurity = ({ user, organization }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="drawer-choose-organization">
			<div className="dropdown-menu-padding">
				<Dropdown.Header as={Link} to={"/"}>
					{t("lib:go_component.navigation.your_organizations")} <ArrowRightSVG />
				</Dropdown.Header>
				<div className="drawer-choose-organization-list">
					{user.organizations?.map((org) => {
						return (
							<Dropdown.Item
								key={org?.id}
								as={Link}
								to={`/${org?.id}`}
								className={`${organization.id === org?.id ? "selected-organization" : ""}`}
							>
								{org?.name}
							</Dropdown.Item>
						);
					})}
					{user.partners_organizations &&
						user.partners_organizations?.length > 0 &&
						user.organizations &&
						user.organizations.length > 0 && <Dropdown.Divider />}
					{user.partners_organizations?.map((org) => {
						return (
							<Dropdown.Item
								key={org?.id}
								as={Link}
								to={`/${org?.id}`}
								className={`${organization.id === org?.id ? "selected-organization" : ""}`}
							>
								{org?.name}
							</Dropdown.Item>
						);
					})}
				</div>
			</div>
			<Dropdown.Divider />
		</div>
	);
};

export default NavigationOrganizationsProfileSecurity;
