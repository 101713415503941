import React from "react";
import { Form } from "react-bootstrap";
import { FormCheckProps } from "react-bootstrap/FormCheck";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";
import { hasErrors } from "..";
import { getErrors } from "../services/utils";

interface Props extends FormCheckProps {
	label?: React.ReactNode;
	name: string;
	errors: any;
	register: UseFormRegister<any> | null;
	help?: string;
}
export const FormCheck: BsPrefixRefForwardingComponent<"input", Props> = React.forwardRef((props: Props) => {
	const { label, help, name, errors, register, ...controlProps } = props;
	const feedbackErrors = getErrors(errors, name);
	const { t } = useTranslation();
	return (
		<Form.Group className="form-group" controlId={`${name}`}>
			{register !== null ? (
				<Form.Check
					feedback={feedbackErrors?.map(([type]) => {
						return <div key={type}>{t(`constraints.${type}`, { ns: ["translation", "lib"] })}</div>;
					})}
					label={label}
					{...controlProps}
					isInvalid={hasErrors(errors, name)}
					{...register(name)}
					className={`form-check ${controlProps.className || ""}`}
				/>
			) : (
				<Form.Check
					feedback={feedbackErrors?.map(([type]) => {
						return <div key={type}>{t(`constraints.${type}`, { ns: ["translation", "lib"] })}</div>;
					})}
					label={label}
					{...controlProps}
					isInvalid={hasErrors(errors, name)}
					className={`form-check ${controlProps.className || ""}`}
				/>
			)}
			{help && <Form.Text muted>{help}</Form.Text>}
		</Form.Group>
	);
});

FormCheck.displayName = "FormCheck";
