import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import EntityUsedErrorModal from "../../../../../../../../../../components/Common/EntityUsedErrorModal/EntityUsedErrorModal";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { CategoryApi, PointOfSaleApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalButton from "../../../../components/translations/EntityTranslationsModalButton";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import CategoryForm from "../../components/CategoryForm";

interface MatchParams {
	categoryId: string;
}

const OrganizationMenuCategoriesEditPage = (): JSX.Element => {
	const [category, setCategory] = useState<CategoryApi | undefined>();
	const [showCategoryUsedErrorModal, setShowCategoryUsedErrorModal] = useState(false);
	const [showLogs, setShowLogs] = useState(false);
	const [pointsOfSale, setPointsOfSale] = useState<PointOfSaleApi[] | undefined>(undefined);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const [showTranslations, setShowTranslations] = useState(false);
	const [categoryTranslations, setCategoryTranslations] = useState(category?.translations);
	const [errors, setErrors] = useState<ApiError[]>([]);
	const { categoryId } = useParams<MatchParams>();
	const { addFlash, addSuccessFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const params: Record<string, any> = {
			include:
				"image_links,translations,points_of_sale,direction,points_of_sale.direction,points_of_sale.point_of_sale",
		};
		Promise.all([
			api.organization().getCategory(Number(categoryId), params),
			api.organization().getCategoryConfig(),
		])
			.then(([newCategory, config]) => {
				setCategory(newCategory);
				setPointsOfSale(config.points_of_sale);
				setCategoryTranslations(newCategory.translations);
				handleChangeTabTitle(`${newCategory.name} | ${t("modules.category.header.title")}`);
			})
			.catch((e) => {
				handleError.alert(e, addFlash);
			});
	}, []);

	useEffect(() => {
		if (errors.filter((f) => f.code === "category_is_used").length > 0) setShowCategoryUsedErrorModal(true);
	}, [errors]);

	const handleSaveTranslations = (data: Record<string, any>) => {
		unstable_batchedUpdates(() => {
			setCategoryTranslations(data.entity_translations);
			setShowTranslations(false);
		});
		setShowTranslations(false);
	};

	const removeCategory = async (categoryToBeRemoved: CategoryApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeCategory(categoryToBeRemoved.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/menu/categories`);
		} catch (err) {
			handleError.alert(err, addFlash);
			setErrors(handleException(err));
		}
	};

	const restoreCategory = async (categoryToBeRestored: CategoryApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().restoreCategory(categoryToBeRestored.id);
			addSuccessFlash(t("lib:common.flash.restored"));
			setCategory(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDisableCategory = async (categoryToBeDisabled: CategoryApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			const res = await api.organization().disableCategory(categoryToBeDisabled.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setCategory(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	if (!category || !pointsOfSale) {
		return <LoadingContainer />;
	}

	const renderTitle = () => (
		<>
			{t("modules.category.header.title")} "{category.name}"
			<FormatResourceStatus status={category.status} />
		</>
	);

	const buttons: ButtonProps[] = [
		{
			content: (
				<EntityTranslationsModalButton
					translations={categoryTranslations || []}
					showTranslation={showTranslations}
					setShowTranslations={(show) => setShowTranslations(show)}
				/>
			),
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			variant: "light",
		},
	];
	if (category.status !== "DELETED") {
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeCategory(category),
			dropdown: true,
		});
	}
	if (category.status === "DELETED" || category.status === "DISABLED") {
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreCategory(category),
			dropdown: true,
		});
	}
	if (category.status === "ENABLED") {
		buttons.push({
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisableCategory(category),
			dropdown: true,
		});
	}

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeCategory(category),
			hide: category.status === "DELETED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreCategory(category),
			hide: category.status !== "DELETED" && category.status !== "DISABLED",
		},
		{
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisableCategory(category),
			hide: category.status !== "ENABLED",
		},
	];

	return (
		<>
			<Header title={renderTitle()} buttons={isMobile ? buttons.filter((button) => button.content) : buttons} />
			<MobileActions actions={mobileActions} />
			{showTranslations && (
				<EntityTranslationsModalForm
					handleSave={handleSaveTranslations}
					allowDescription={true}
					onHide={() => setShowTranslations(!showTranslations)}
					translations={categoryTranslations}
				/>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={category.id}
					resourceType={"CATEGORY"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			{
				<EntityUsedErrorModal
					onHide={() => {
						setErrors([]);
						setShowCategoryUsedErrorModal(false);
					}}
					isShown={showCategoryUsedErrorModal}
					linkTo={`/${organization.id}/menu/item_groups?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}category|e=${category?.id}`
					)}`}
					title={t("constraints.category_is_used")}
					linkTitle={t("modules.category.action.check_products.title")}
				/>
			}
			<CategoryForm
				passTranslations={(categoryTranslations) =>
					unstable_batchedUpdates(() => {
						setCategoryTranslations(categoryTranslations);
					})
				}
				categoryTranslations={categoryTranslations}
				category={category}
				pointsOfSale={pointsOfSale}
			/>
		</>
	);
};

export default OrganizationMenuCategoriesEditPage;
