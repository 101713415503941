import React, { MetaHTMLAttributes, ReactNode, createContext, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface BrowserTabContextType {
	title: string;
	setTitle: (title: string) => void;
}

export const BrowserTabContext = createContext<BrowserTabContextType>({
	title: "",
	setTitle: (_: string) => {},
});

interface Props {
	children?: ReactNode;
}

export const BrowserTabProvider = ({ children }: Props) => {
	const [title, setTitle] = useState<string>("");
	const location = useLocation();

	useLayoutEffect(() => {
		handleChangeTabTitle();
	}, [title]);

	useLayoutEffect(() => {
		setTitle("");
	}, [location.pathname]);

	const handleChangeTabTitle = () => {
		if (title) {
			document.title = title;
		} else {
			document.title = getDefaultTitle();
		}
	};

	const getDefaultTitle = () => {
		return (
			(document.querySelector('meta[property="og:title"]') as MetaHTMLAttributes<{ content: string }>).content ||
			""
		);
	};

	return (
		<BrowserTabContext.Provider
			value={{
				title,
				setTitle,
			}}
		>
			{children}
		</BrowserTabContext.Provider>
	);
};
