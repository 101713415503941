import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import WebhookForm from "go-component/components/Webhook/WebhookForm";
import { WebhookContext } from "go-component/components/Webhook/services/context";
import { WebhookApi } from "go-component/components/Webhook/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { selectOrganization } from "go-security/services/organizations/selectors";

interface MatchParams {
	webhook_id: string;
}

const OrganizationLogsWebhooksEditPage = (): JSX.Element => {
	const [webhook, setWebhook] = useState<WebhookApi | undefined>();
	const webhookService = React.useContext(WebhookContext);
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	const { webhook_id } = useParams<MatchParams>();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		fetch();
	}, []);

	const fetch = async () => {
		const params: Record<string, any> = {};
		params.include = "requests";
		try {
			const res = await webhookService.api().get(webhook_id, params);
			setWebhook(res.data.data);
			handleChangeTabTitle(`${res.data.data.name} | ${t("lib:go_component.webhook.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!webhook) {
		return <LoadingContainer />;
	}

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/logs/webhooks/${webhook.id}`);

	return (
		<div className="content">
			<Header title={webhook.name} back={goBack} />
			<WebhookForm webhook={webhook} onSuccess={fetch} />
		</div>
	);
};

export default OrganizationLogsWebhooksEditPage;
