import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import EmptyData from "go-core/components/EmptyData";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as ExclamationCircleSVG } from "../../../../../../../../../../images/svg/exclamation-circle.svg";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../images/svg/sortable-handle.svg";
import {
	ItemApi,
	ItemGroupApi,
	ModifierGroupApi,
	ModifierGroupOptionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { checkIfValueIsEmpty } from "../../utils";
import SearchModifierGroupForm from "../ModifierGroups/SearchModifierGroupForm";
import { findItemQuantityOverrides } from "./CreateOrUpdateModifierGroupModalForm";
import SortModifierGroupsModal from "./SortModifierGroupsModal";

interface Props {
	form: UseFormReturn<ItemGroupApi>;
	itemModifierGroups: Record<string, any>[];
	modifierGroups: ModifierGroupApi[];
	handleAddModifierGroup: (
		data: ModifierGroupApi,
		items: ItemApi[] | undefined,
		quantityOverrides?: Record<string, any>[],
		priceOverrides?: Record<string, any>[],
		itemQuantityOverrides?: Record<string, any>[]
	) => void;
	handleRemoveModifierGroup: (data: ModifierGroupApi) => void;
	items: ItemApi[];
	changeModifierGroups: (data: ModifierGroupApi[]) => void;
	addModifierGroup: (data: ModifierGroupApi) => void;
	errors?: Record<string, any>[];
}

const ModifierGroupsList = (props: Props): JSX.Element => {
	const [showModal, setShowModal] = useState<number | null | undefined>(undefined);
	const [showSortModifierGroupsModal, setShowSortModifierGroupsModal] = useState<boolean>(false);
	const modifierGroups = props.modifierGroups;
	const {
		watch,
		getValues,
		formState: { errors },
	} = props.form;
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const isSortingRef = useRef(false);
	const watchedVariants = watch("items");
	const confirmation = useConfirmation();

	const handleSave = (
		data: ModifierGroupApi,
		itemId: number | null,
		items: ItemApi[] | undefined,
		quantityOverrides?: Record<string, any>[],
		priceOverrides?: Record<string, any>[],
		itemQuantityOverrides?: Record<string, any>[]
	) => {
		if (showModal === null) {
			setShowModal(undefined);
			props.addModifierGroup(data);
			props.handleAddModifierGroup(data, items, quantityOverrides, priceOverrides, itemQuantityOverrides);
		} else {
			const currentModifierGroups = modifierGroups;
			currentModifierGroups[showModal as number] = data;
			props.changeModifierGroups([...currentModifierGroups]);
			setShowModal(undefined);
			props.handleAddModifierGroup(data, items, quantityOverrides, priceOverrides, itemQuantityOverrides);
		}
	};
	const updatePositions = (items: ModifierGroupApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		props.changeModifierGroups([...items]);
	};
	const removeModifierGroup = async (index: number) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		const newModifierGroups = modifierGroups;
		const removed: ModifierGroupApi = newModifierGroups.splice(index, 1)[0];
		props.changeModifierGroups([...newModifierGroups]);
		props.handleRemoveModifierGroup(removed);
	};

	const variantsInGroup = (item: ModifierGroupApi) => {
		const variants: ItemApi[] = [];
		watchedVariants.forEach((x) => {
			const existInGroup = x.modifier_groups?.find((f) => f.modifier_group_id === item.id);
			if (x.name === getValues("name")) {
				x.name = t("common.word.default");
			}
			if (existInGroup) {
				variants.push(x);
			}
		});
		return variants;
	};

	const getModifierGroupDefaultQuantityAndName = (opt: ModifierGroupOptionApi, modifierGroupId: number) => {
		if (opt) {
			const newModifierGroups = watchedVariants
				.map((variant) => (variant.modifier_groups ? variant.modifier_groups : []))
				.flat();

			const options = newModifierGroups
				.filter((modifierGroup) => modifierGroup.modifier_group_id === modifierGroupId)
				.map((modifierGroup) => modifierGroup?.options);

			const searchingOption = options?.flat().find((option) => {
				if (option.item_id) {
					return option.item_id === opt.item_id;
				}
				return option.name === opt.name;
			});

			const variantQuantity = Number(searchingOption?.quantity_info?.default_quantity);
			const optQuantity = Number(opt?.quantity_info?.default_quantity);
			const result = { name: opt?.sub_item?.name || "", quantity: 0 };

			if (!isNaN(variantQuantity)) {
				if (variantQuantity > 1) {
					result.name = `${result.name} (${variantQuantity})`;
				}
				result.quantity = variantQuantity;
			} else if (!isNaN(optQuantity)) {
				if (optQuantity > 1) {
					result.name = `${result.name} (${optQuantity})`;
				}
				result.quantity = optQuantity;
			}
			return result;
		}
		return { name: "", quantity: "" };
	};

	const drawModifierOptions = (options: ModifierGroupOptionApi[], modifierGroupId: number) => {
		return (
			options &&
			options.length > 0 &&
			options.map((opt: ModifierGroupOptionApi, index: number) => {
				const isNotLastElement = index < options.length - 1;
				const modifierGroupInfo = getModifierGroupDefaultQuantityAndName(opt, modifierGroupId);
				return (
					<p key={`${opt.id}`} className={Number(modifierGroupInfo?.quantity) > 0 ? "font-weight-bold" : ""}>
						{modifierGroupInfo?.name}
						{isNotLastElement && <span>,&nbsp;</span>}
					</p>
				);
			})
		);
	};

	const getItemQuantityInfo = (modifierGroupId: number) => {
		return findItemQuantityOverrides(
			watchedVariants.map((variant) => (variant.modifier_groups ? variant.modifier_groups : [])).flat(),
			modifierGroupId
		);
	};

	const drawQuantityInfo = (modifierGroupId: number, modifierGroup: ModifierGroupApi) => {
		const itemQuantityInfo = getItemQuantityInfo(modifierGroupId);
		const defaultQuantityInfo = modifierGroup.quantity_info;
		let minPermitted, maxPermitted;

		if (itemQuantityInfo && !checkIfValueIsEmpty(itemQuantityInfo?.min_permitted)) {
			minPermitted = itemQuantityInfo.min_permitted;
		} else if (defaultQuantityInfo && !checkIfValueIsEmpty(defaultQuantityInfo?.min_permitted)) {
			minPermitted = defaultQuantityInfo.min_permitted;
		}

		if (itemQuantityInfo && !checkIfValueIsEmpty(itemQuantityInfo?.max_permitted)) {
			maxPermitted = itemQuantityInfo.max_permitted;
		} else if (defaultQuantityInfo && !checkIfValueIsEmpty(defaultQuantityInfo?.max_permitted)) {
			maxPermitted = defaultQuantityInfo.max_permitted;
		}

		if (
			typeof minPermitted === "number" &&
			minPermitted >= 0 &&
			(maxPermitted === undefined || maxPermitted === null)
		) {
			if (minPermitted === itemQuantityInfo?.min_permitted) return <strong>{`${minPermitted} -`}</strong>;
			return `${minPermitted} -`;
		}
		if (
			(minPermitted === undefined || minPermitted === null) &&
			typeof maxPermitted === "number" &&
			maxPermitted >= 0
		) {
			if (maxPermitted === itemQuantityInfo?.max_permitted) return <strong>{`- ${maxPermitted}`}</strong>;
			return `- ${maxPermitted}`;
		}
		if (
			typeof minPermitted === "number" &&
			typeof maxPermitted === "number" &&
			minPermitted >= 0 &&
			maxPermitted >= 0
		) {
			if (minPermitted === itemQuantityInfo?.min_permitted && maxPermitted === itemQuantityInfo?.max_permitted)
				return (
					<span>
						<strong>{minPermitted}</strong>
						{` - `}
						<strong>{maxPermitted}</strong>
					</span>
				);
			if (minPermitted === itemQuantityInfo?.min_permitted && maxPermitted === defaultQuantityInfo?.max_permitted)
				return (
					<span>
						<strong>{minPermitted}</strong>
						{` - ${maxPermitted}`}
					</span>
				);
			if (minPermitted === defaultQuantityInfo?.min_permitted && maxPermitted === itemQuantityInfo?.max_permitted)
				return (
					<span>
						{`${minPermitted} - `}
						<strong>{maxPermitted}</strong>
					</span>
				);
			if (
				minPermitted === defaultQuantityInfo?.min_permitted &&
				maxPermitted === defaultQuantityInfo?.max_permitted
			)
				return `${minPermitted} - ${maxPermitted}`;
			return `${minPermitted} - ${maxPermitted}`;
		}
		return "-";
	};

	const isMobileView = useWindowSize().isMobile;
	return (
		<fieldset className="form-group">
			<div className="d-flex align-items-center justify-content-between mb-2">
				<h5 className="mb-0">{t("modules.modifier_group.header.modifiers.title")}</h5>
				<Button type="button" variant="light" onClick={() => setShowSortModifierGroupsModal(true)}>
					{t("modules.item_group.action.sort_modifier_group_list.title")}
				</Button>
			</div>
			{modifierGroups.length > 0 ? (
				<>
					{isMobileView ? (
						<div className="div-table">
							<div className="div-table-header"></div>
							<ReactSortable
								handle=".sortable-handler"
								style={{ display: "contents" }}
								list={modifierGroups}
								onUpdate={() => (isSortingRef.current = true)}
								setList={(fields) => updatePositions(fields)}
							>
								{modifierGroups.map((item, index) => {
									const itemInactive = item.status !== "ENABLED";
									const inactiveClass = itemInactive ? "modifier-group-inactive-in-item-group" : "";
									return (
										<div className={"div-table-body"} key={index}>
											{showModal === index && (
												<SearchModifierGroupForm
													selectedValues={modifierGroups.map((x) => x.id)}
													itemGroupName={getValues("name")}
													handleClose={() => setShowModal(undefined)}
													errors={props.errors}
													items={props.items}
													itemsInGroup={variantsInGroup(item)}
													index={index}
													modifierGroup={item}
													handleSave={handleSave}
												/>
											)}
											<div className={"div-table-row"}>
												<div className="div-table-cell action">
													<SortableHandleSvg className="sortable-handler" />
												</div>
												<div className="div-table-cell">
													<div className={"d-flex"}>
														<strong>
															<Link
																to={`/${organization.id}/menu/modifier_groups/${item.id}`}
																target={"_blank"}
																rel="noreferrer"
															>
																{itemInactive ? (
																	<div
																		className={`d-flex align-items-center ${inactiveClass}`}
																	>
																		<ExclamationCircleSVG />
																		<span className="ms-1">{item.name}</span>
																	</div>
																) : (
																	item.name
																)}
															</Link>
														</strong>
														<div
															className="action-ref modifier-ref ms-auto"
															onClick={() => setShowModal(index)}
														>
															{t("common.word.adjust")}...
														</div>
														{Object.values(errors).map((f: Record<string, any>) => {
															if (!Array.isArray(f)) return null;
															let rendered = false;
															return f?.map((m) => {
																if (!m?.modifier_groups || !m.modifier_groups[index]) {
																	return null;
																}
																return m?.modifier_groups?.map(
																	(
																		mg: Record<string, any>,
																		modifierGroupIndex: number
																	) => {
																		if (mg.options && !rendered) {
																			rendered = true;
																			return (
																				<Form.Control.Feedback
																					key={modifierGroupIndex}
																					type={"invalid"}
																					className={"d-inline"}
																				>
																					{t(
																						"modules.modifier_group.constraints.modifier_groups_configuration_contains_errors"
																					)}
																				</Form.Control.Feedback>
																			);
																		}
																		return null;
																	}
																);
															});
														})}
														{props.errors &&
															props.errors.length > 0 &&
															props.errors.find((f) =>
																f?.field?.includes(`modifier_groups[${index}]`)
															) && (
																<Form.Control.Feedback
																	type={"invalid"}
																	className={"d-inline"}
																>
																	{t(
																		"modules.item_group.constraints.configuration_contains_errors"
																	)}
																</Form.Control.Feedback>
															)}
													</div>
												</div>
												<div className="div-table-cell action">
													<RemoveSVG
														className={"icon"}
														onClick={() => removeModifierGroup(index)}
													/>
												</div>
											</div>
											<div className={"div-table-row"}>
												<div className={"div-table-cell action"} />
												<div className="div-table-cell" style={{ borderTop: "none" }}>
													<div className="d-flex">
														<div className={`item-advanced-table-label ${inactiveClass}`}>
															{t("modules.item_group.field.amount.title")}:
														</div>
														<div className={`item-advanced-table-info`}>
															<span className={`${inactiveClass}`}>
																{drawQuantityInfo(item.id, item)}
															</span>
															<span className="ms-auto"></span>
														</div>
													</div>
													<div className={`d-flex ${inactiveClass}`}>
														<div className="item-advanced-table-label">
															{`${t("modules.item_group.field.modifiers.title")}:`}
														</div>
														<div className="item-advanced-table-info flex-wrap">
															{drawModifierOptions(item?.options, item.id)}
														</div>
													</div>
													<div className={`d-flex ${inactiveClass}`}>
														<div className="item-advanced-table-label">
															{`${t("modules.item_group.field.items.title")}:`}
														</div>
														<div className="item-advanced-table-info">
															<p>
																{variantsInGroup(item).map((x) => x.name).length ===
																watchedVariants.length
																	? t("common.word.all", { ns: "lib" })
																	: variantsInGroup(item)
																			.map((x) => {
																				if (x.name) return x.name;
																				return t("common.word.default");
																			})
																			.join(", ")}
															</p>
														</div>
													</div>
												</div>
												<div className={"div-table-cell action"} />
											</div>
										</div>
									);
								})}
							</ReactSortable>
						</div>
					) : (
						<table className="table table-form advanced">
							<thead>
								<tr>
									<th className="action" />
									<th className={"w-25"}>{t("modules.item_group.field.modifiers_group.title")}</th>
									<th>{t("modules.item_group.field.includes.title")}</th>
									<th className="action" />
								</tr>
							</thead>
							<ReactSortable
								handle=".sortable-handler"
								tag="tbody"
								list={modifierGroups}
								onUpdate={() => (isSortingRef.current = true)}
								setList={(fields) => updatePositions(fields)}
							>
								{modifierGroups.map((item, index) => {
									const itemInactive = item.status !== "ENABLED";
									const inactiveClass = itemInactive ? "modifier-group-inactive-in-item-group" : "";
									return (
										<React.Fragment key={index}>
											{showModal === index && (
												<SearchModifierGroupForm
													selectedValues={modifierGroups.map((x) => x.id)}
													itemGroupName={getValues("name")}
													handleClose={() => setShowModal(undefined)}
													errors={props.errors}
													items={props.items}
													itemsInGroup={variantsInGroup(item)}
													index={index}
													modifierGroup={item}
													handleSave={handleSave}
												/>
											)}
											<tr>
												<td className="action">
													<SortableHandleSvg className="sortable-handler" />
												</td>
												<td>
													<strong>
														<Link
															to={`/${organization.id}/menu/modifier_groups/${item.id}`}
															target={"_blank"}
															rel="noreferrer"
														>
															{itemInactive ? (
																<div
																	className={`d-flex align-items-center ${inactiveClass}`}
																>
																	<ExclamationCircleSVG />
																	<span className="ms-1">{item.name}</span>
																</div>
															) : (
																item.name
															)}
														</Link>
													</strong>
													{Object.values(errors).map((f: Record<string, any>) => {
														if (!Array.isArray(f)) return null;
														return f?.map((m) => {
															if (!m?.modifier_groups || !m.modifier_groups[index]) {
																return null;
															}
															return m?.modifier_groups?.map(
																(mg: Record<string, any>, mgIndex: number) => {
																	if (mg.types && mg.types.modifier_group_cycle) {
																		return (
																			<Form.Control.Feedback
																				key={mgIndex}
																				type={"invalid"}
																				className={"d-inline"}
																			>
																				{t(
																					"modules.item_group.constraints.item_already_assigned"
																				)}
																			</Form.Control.Feedback>
																		);
																	}
																	return null;
																}
															);
														});
													})}
												</td>
												<td>
													<div className="d-flex">
														<div className={`item-advanced-table-label ${inactiveClass}`}>
															{t("modules.item_group.field.amount.title")}:
														</div>
														<div className={`item-advanced-table-info`}>
															<span className={`${inactiveClass}`}>
																{drawQuantityInfo(item.id, item)}
															</span>
															<span className="ms-auto">
																<div
																	className="action-ref modifier-ref"
																	onClick={() => setShowModal(index)}
																>
																	{t("common.word.adjust")}...
																</div>
																{props.errors &&
																	props.errors.length > 0 &&
																	props.errors.find((f) =>
																		f?.field?.includes(`modifier_groups[${index}]`)
																	) && (
																		<Form.Control.Feedback
																			type={"invalid"}
																			className={"d-inline"}
																		>
																			{t(
																				"modules.item_group.constraints.configuration_contains_errors"
																			)}
																		</Form.Control.Feedback>
																	)}
																{Object.values(errors).map((f: Record<string, any>) => {
																	if (!Array.isArray(f)) return null;
																	let rendered = false;
																	return f?.map((m) => {
																		if (
																			!m?.modifier_groups ||
																			!m.modifier_groups[index]
																		) {
																			return null;
																		}
																		return m?.modifier_groups?.map(
																			(
																				mg: Record<string, any>,
																				mgIndex: number
																			) => {
																				if (mg.options && !rendered) {
																					rendered = true;
																					return (
																						<Form.Control.Feedback
																							key={mgIndex}
																							type={"invalid"}
																							className={"d-inline"}
																						>
																							{t(
																								"modules.modifier_group.constraints.modifier_groups_configuration_contains_errors"
																							)}
																						</Form.Control.Feedback>
																					);
																				}
																				return null;
																			}
																		);
																	});
																})}
															</span>
														</div>
													</div>
													<div className={`d-flex ${inactiveClass}`}>
														<div className="item-advanced-table-label">
															{`${t("modules.item_group.field.modifiers.title")}:`}
														</div>
														<div
															className="item-advanced-table-info flex-wrap"
															style={{ paddingRight: "8rem" }}
														>
															{drawModifierOptions(item?.options, item.id)}
														</div>
													</div>
													<div className={`d-flex ${inactiveClass}`}>
														<div className="item-advanced-table-label">
															{`${t("modules.item_group.field.items.title")}:`}
														</div>
														<div className="item-advanced-table-info">
															<p>
																{variantsInGroup(item).map((x) => x.name).length ===
																watchedVariants.length
																	? t("common.word.all", { ns: "lib" })
																	: variantsInGroup(item)
																			.map((x) => {
																				if (x.name) return x.name;
																				return t("common.word.default");
																			})
																			.join(", ")}
															</p>
														</div>
													</div>
												</td>
												<td className="action">
													<RemoveSVG
														className={"icon"}
														onClick={() => removeModifierGroup(index)}
													/>
												</td>
											</tr>
										</React.Fragment>
									);
								})}
							</ReactSortable>
						</table>
					)}
				</>
			) : (
				<EmptyData
					title={t("modules.modifier_group.field.empty_list.title")}
					styles={{
						background: "#F5F8FA",
						marginBottom: "10px",
					}}
					description={`${t("modules.modifier_group.action.press_button.title")} \"+ ${t(
						"lib:common.action.add"
					)}\" ${t("modules.modifier_group.action.and_assign_modifier_group.title")}`}
					actions={[
						{
							name: `+ ${t("lib:common.action.add")}`,
							variant: "primary",
							click: () => {
								setShowModal(null);
							},
						},
					]}
				/>
			)}
			{modifierGroups.length > 0 && (
				<Button variant="add" type="button" onClick={() => setShowModal(null)}>{`+ ${t(
					"modules.modifier_group.action.assign_modifier_group.title"
				)}`}</Button>
			)}
			{showModal === null && (
				<SearchModifierGroupForm
					selectedValues={modifierGroups.map((x) => x.id)}
					itemGroupName={getValues("name")}
					errors={props.errors}
					handleClose={() => setShowModal(undefined)}
					items={props.items}
					index={null}
					handleSave={handleSave}
				/>
			)}
			{showSortModifierGroupsModal && (
				<SortModifierGroupsModal
					onHide={() => setShowSortModifierGroupsModal(false)}
					isShown={showSortModifierGroupsModal}
					modifierGroups={modifierGroups}
					changeModifierGroups={props.changeModifierGroups}
				/>
			)}
		</fieldset>
	);
};

export default ModifierGroupsList;
