import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { MenuPriceListApi } from "../../../../../../../../../../../../../../services/Api/Organization/types";
import { isVirtualTableColumnHide } from "../../../../../../../../components/VirtualTable/utils";

interface Props {
	columns: MenuPriceListApi[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	getHeaderStyles: (columnId: string) => { width: number };
}

const SpreadsheetPriceListVirtualTableHeaderPriceListsColumns = ({
	columns,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	getHeaderStyles,
}: Props) => {
	const drawColumns = () => {
		return columns.map((column) => {
			const isHide = isVirtualTableColumnHide(column.id, columns);
			return (
				<ReactResizeDetector key={column.name} onResize={(width) => handleHeaderResize(width, column.name)}>
					<th
						id={`column-${column.name}`}
						key={column.name}
						title={column.name}
						className={`${isHide ? "d-none" : ""}`}
						onMouseDown={(e) => handleMouseDown(e)}
						onMouseUp={(e) => handleMouseUp(e, column.name, true)}
					>
						<div style={getHeaderStyles(column.name)} className="d-flex align-items-center">
							{column.name}
						</div>
					</th>
				</ReactResizeDetector>
			);
		});
	};
	return <>{drawColumns()}</>;
};

export default SpreadsheetPriceListVirtualTableHeaderPriceListsColumns;
