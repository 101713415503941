import React from "react";
import { useTranslation } from "react-i18next";
import { getLicenseAlertClass, getLicenseAlertText, isLicenseExpired } from "../../services/license";
import { OrganizationState } from "../../services/organizations/types";
import { isInstalled } from "../../services/utils";

interface Props {
	organization: OrganizationState;
	goaccounts_url: string;
	topStyle?: string;
}

const LicenseExpiringNotification = (props: Props): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = props.organization;
	const licenseType = organization.license_type;
	const licenseExpiredAt = organization.license_expires_at ?? "";

	const goAccountsSubscriptionsUrl = `${props.goaccounts_url}/enter_organization?company_id=${organization.company_id}&redirect_page=subscriptions`;

	if (!isInstalled(organization) || !organization.id) return null;

	if (!isLicenseExpired(organization.license_expires_at, organization.license_type, organization.timezone)) {
		return null;
	}

	return (
		<a
			href={goAccountsSubscriptionsUrl}
			className={`license-${getLicenseAlertClass(licenseExpiredAt, licenseType, organization.timezone)}`}
			style={{ top: props.topStyle ?? 0 }}
		>
			{getLicenseAlertText(t, licenseExpiredAt, licenseType, organization.timezone)}
		</a>
	);
};
export default LicenseExpiringNotification;
