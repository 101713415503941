import React, { useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { unstable_batchedUpdates } from "react-dom";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput, registerObject } from "go-form";
import { ReactComponent as TickSVG } from "go-core/images/svg/tick.svg";
import ImageCropperModal from "../ImageCropper/ImageCropperModal";
import { generateLabel } from "../ImageRenderer/ListImageName";
import FileUpload from "./FileUpload";

interface Props {
	data: any;
	show?: boolean;
	handleSave: (img: string | undefined, type?: string) => void;
	placeholder?: string;
	form: UseFormReturn<any>;
	setTextDisabled?: boolean;
	setColorDisabled?: boolean;
	resourceData?: any;
	resourceLink?: any | undefined;
	handleOpen?: () => void;
	prefix: string;
	mode?: string;
	label?: string;
	entityName?: string;
	displayColor?: boolean;
	shouldOverrideImageLink?: boolean;
}

export const processImageFormColor = (data: any, prefix: string, imageData: any): Record<string, any> => {
	if (!imageData) {
		data[prefix] = undefined;
		data[`${prefix}_link`] = undefined;
	}
	if (data.color === "") {
		data.color = undefined;
	}
	return data;
};

const ImageColorForm = (props: Props): JSX.Element => {
	const [type, setType] = useState(props.mode === "IMAGE" ? "IMAGE" : props.resourceLink ? "IMAGE" : "COLOR");
	const [selectedFile, setSelectedFile] = useState<undefined | string>(undefined);
	const [showCropper, setShowCropper] = useState(false);
	const [imageData, setImageData] = useState(props.resourceLink ? props.resourceLink.default_link : undefined);
	const imageDataPreviousValue = useRef<string | undefined>(undefined);
	const { t } = useTranslation();
	const {
		formState: { errors },
		register,
		setValue,
		watch,
	} = props.form;
	const [color, setColor] = useState(props.data.color);
	const [text, setText] = useState(props.data.label);
	const imageExtensions = ["jpeg", "jpg", "png"];
	const colors = [
		"#a0a5a9",
		"#4ab301",
		"#0c8001",
		"#13b0bf",
		"#2a52cb",
		"#a62ee7",
		"#e7467c",
		"#b21212",
		"#e5c000",
		"#593c02",
	];
	const changeText = (newText: string) => {
		props.data.label = newText;
		setText(newText);
		setValue("label", newText);
	};

	const updateImage = (imageBase: string) => {
		setType("IMAGE");
		setImageData(imageBase);
		setShowCropper(false);
		imageDataPreviousValue.current = undefined;
		if (imageBase && imageBase.includes(";base64")) {
			const base64dataSplit = imageBase.split(",");
			let imageType = base64dataSplit[0];
			imageType = imageType.split(";")[0];
			imageType = imageType.split(":")[1];
			registerObject(register, props.prefix, ["type", "image_data"]);
			if (watch(`${props.prefix}_link`) && !props.shouldOverrideImageLink) {
				setValue(`${props.prefix}_link.default_link`, undefined);
				setValue(`${props.prefix}_link.small`, undefined);
			}
			setValue(`${props.prefix}.type`, imageType, { shouldDirty: true });
			setValue(`${props.prefix}.image_data`, base64dataSplit[1], { shouldDirty: true });

			if (props.shouldOverrideImageLink) {
				setValue(`${props.prefix}_link.default_link`, imageBase, { shouldDirty: true });
				setValue(`${props.prefix}_link.small`, imageBase, { shouldDirty: true });
			}

			props.handleSave(base64dataSplit[1], imageType);
		}
	};

	const handleClose = () => {
		setSelectedFile(undefined);
		setShowCropper(false);
		if (imageDataPreviousValue.current) {
			setImageData(imageDataPreviousValue.current);
		}
	};

	const handleRemoveImage = () => {
		setImageData(undefined);
		setSelectedFile(undefined);
		setValue(`${props.prefix}_link`, undefined, { shouldDirty: true });
		setValue(`${props.prefix}.image_data`, undefined);
		setValue(`${props.prefix}.type`, undefined);
		setValue(`${props.prefix}`, undefined, { shouldDirty: true });
		if (type === "IMAGE") {
			setType("COLOR");
		}
		props.handleSave(undefined);
	};

	const changeColor = (color: string) => {
		setColor(color);
		setValue("color", color, { shouldDirty: true });
	};

	useEffect(() => {
		if (props.resourceLink) {
			setImageData(props.resourceLink.default_link);
			setSelectedFile(props.resourceLink.default_link);
		} else handleRemoveImage();
	}, [JSON.stringify(props.resourceLink)]);

	useEffect(() => {
		if (props.data.color) {
			setColor(props.data.color);
		}
	}, [props.data.color]);

	useEffect(() => {
		if (
			props.data.color === undefined &&
			props.data.label === undefined &&
			props.data[`${getParsedPrefix()}_link`] === undefined
		) {
			unstable_batchedUpdates(() => {
				setColor(undefined);
				setImageData(undefined);
				setText(undefined);
			});
		}
	}, [props.data.color, props.data.label]);

	const getParsedPrefix = () => {
		if (props.prefix.includes(".")) {
			const prefixParts = props.prefix.split(".");
			return prefixParts[prefixParts.length - 1];
		}
		return props.prefix;
	};

	const editImage = () => {
		if (imageData) {
			setShowCropper(true);
		} else {
			document.getElementById(`edit-image-${props.prefix}`)?.click();
		}
	};

	const changeFormType = (type: string) => {
		if (type === "COLOR") {
			setValue("color", color, { shouldDirty: true });
			setValue("label", text, { shouldDirty: true });
			setValue(`${props.prefix}`, {});
			props.handleSave(undefined);
		} else {
			setValue("color", undefined, { shouldDirty: true });
			setValue("label", undefined, { shouldDirty: true });
		}
		setType(type);
	};

	const onUpdateFile = (file: string) => {
		setSelectedFile(file);
		imageDataPreviousValue.current = imageData;
		setImageData(undefined);
		setShowCropper(true);
	};

	return (
		<div className="form-group">
			{props.resourceLink && (
				<>
					<FormInput
						errors={errors}
						type={"hidden"}
						register={register}
						name={`${props.prefix}_link.default_link`}
					/>
					<FormInput
						errors={errors}
						type={"hidden"}
						register={register}
						name={`${props.prefix}_link.small`}
					/>
				</>
			)}
			{showCropper && (
				<ImageCropperModal
					handleClose={handleClose}
					handleSave={updateImage}
					defaultImage={imageData}
					image={selectedFile}
				/>
			)}
			<label className="form-label">{props.label ? props.label : t("lib:common.word.image")}</label>
			<div
				className="form-image"
				style={{
					border:
						color && props.displayColor && imageData && props.mode === "IMAGE_COLOR"
							? `2px solid ${color}`
							: `none`,
				}}
			>
				{props.mode === "IMAGE" && (
					<div className={`form-image-preview`} onClick={() => editImage()}>
						{imageData && <img className="form-image-preview" id="form-image" src={imageData} />}
					</div>
				)}
				{props.mode === "IMAGE_OR_COLOR" && (
					<>
						{type === "COLOR" && (
							<div
								className="form-image-preview"
								onClick={() => editImage()}
								style={{ backgroundColor: `${type === "COLOR" && color}` }}
							>
								<div className="form-image-text">{text}</div>
							</div>
						)}
						{type === "IMAGE" && (
							<div className={`form-image-preview`} onClick={() => editImage()}>
								{imageData && <img className="form-image-preview" id="form-image" src={imageData} />}
							</div>
						)}
					</>
				)}
				{props.mode === "IMAGE_COLOR" && (
					<>
						<FormInput type={"hidden"} errors={errors} name="color" value={color} register={register} />
						<FormInput type={"hidden"} errors={errors} name="label" value={text} register={register} />
						{!imageData && (
							<div
								className="form-image-preview"
								onClick={() => editImage()}
								style={{ backgroundColor: `${type === "COLOR" && color}` }}
							>
								<div className="form-image-text">
									{text ? text : generateLabel(props.entityName ? props.entityName : "")}
								</div>
							</div>
						)}
						{imageData && (
							<div
								className={`form-image-preview ${imageData && color && "bordered"}`}
								onClick={() => editImage()}
							>
								{imageData && <img className="form-image-preview" id="form-image" src={imageData} />}
							</div>
						)}
					</>
				)}
				<div className="form-image-action">
					<Dropdown as={ButtonGroup} className="d-flex">
						<Dropdown.Toggle variant="add" className="edit-image mb-0" id={`edit-image-${props.prefix}`}>
							{imageData || color || text ? t("lib:common.action.edit") : t("lib:common.action.set")}
						</Dropdown.Toggle>
						<Dropdown.Menu className="form-image-dropdown">
							<>
								{props.mode === "IMAGE" && (
									<>
										<Form.Check
											type="radio"
											id="avatar-image"
											className="form-check"
											label={t("lib:common.word.image")}
											checked={type === "IMAGE"}
											onChange={() => changeFormType("IMAGE")}
										/>
										{type === "IMAGE" && (
											<>
												<FileUpload
													fileExtensions={imageExtensions}
													selectedFile={selectedFile}
													imageData={imageData}
													onUpdateFile={onUpdateFile}
												/>
												{imageData && (
													<div className="d-flex">
														<div onClick={handleRemoveImage} className="remove-image">
															{t("lib:common.action.remove")} &times;
														</div>
													</div>
												)}
											</>
										)}
									</>
								)}
								{props.mode === "IMAGE_COLOR" && (
									<>
										<div className="option-layout">
											<FormInput
												label={t("lib:go_component.image_form.color.label")}
												defaultValue={text}
												register={register}
												name="label"
												errors={errors}
												onChange={(e) => changeText(e.currentTarget.value)}
											/>
											<div
												className="d-flex mb-2"
												style={{ flexWrap: "wrap", marginLeft: "-5px", marginRight: "-5px" }}
											>
												{colors.map((col, index) => {
													return (
														<div
															key={index}
															className="form-image-box"
															style={{ background: `${col}` }}
															onClick={() => changeColor(col)}
														>
															{col === color && <TickSVG className="icon icon-box" />}
														</div>
													);
												})}
											</div>
										</div>
										<>
											<FileUpload
												fileExtensions={imageExtensions}
												selectedFile={selectedFile}
												imageData={imageData}
												onUpdateFile={onUpdateFile}
											/>
											{imageData && (
												<div className="d-flex">
													<div onClick={handleRemoveImage} className="remove-image">
														{t("lib:common.action.remove")} &times;
													</div>
												</div>
											)}
										</>
									</>
								)}
								{props.mode === "IMAGE_OR_COLOR" && (
									<>
										<Form.Check
											type="radio"
											id="avatar-color"
											className="form-check"
											label={
												props.setTextDisabled
													? t("lib:go_component.image_form.type.color")
													: t("lib:go_component.image_form.type.color_text")
											}
											checked={type === "COLOR"}
											onChange={() => changeFormType("COLOR")}
										/>
										{type === "COLOR" && (
											<>
												<FormInput
													type={"hidden"}
													errors={errors}
													name="color"
													value={color}
													register={register}
												/>
												<div className="option-layout">
													{!props.setTextDisabled && (
														<FormInput
															label={t("lib:go_component.image_form.color.label")}
															defaultValue={text}
															register={register}
															name="label"
															errors={errors}
															onChange={(e) => changeText(e.currentTarget.value)}
														/>
													)}
													<div
														className="d-flex mb-2"
														style={{
															flexWrap: "wrap",
															marginLeft: "-5px",
															marginRight: "-5px",
														}}
													>
														{colors.map((col, index) => {
															return (
																<div
																	key={index}
																	className="form-image-box"
																	style={{ background: `${col}` }}
																	onClick={() => changeColor(col)}
																>
																	{col === color && (
																		<TickSVG className="icon icon-box" />
																	)}
																</div>
															);
														})}
													</div>
												</div>
											</>
										)}
										<Form.Check
											type="radio"
											id="avatar-image"
											className="form-check"
											label={t("lib:common.word.image")}
											checked={type === "IMAGE"}
											onChange={() => changeFormType("IMAGE")}
										/>
										{type === "IMAGE" && (
											<>
												<FileUpload
													fileExtensions={imageExtensions}
													selectedFile={selectedFile}
													imageData={imageData}
													onUpdateFile={onUpdateFile}
												/>
												{imageData && (
													<div className="d-flex">
														<div onClick={handleRemoveImage} className="remove-image">
															{t("lib:common.action.remove")} &times;
														</div>
													</div>
												)}
											</>
										)}
									</>
								)}
							</>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};
export default ImageColorForm;
