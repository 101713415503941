import React, { FC, Suspense, useContext, useEffect, useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import AssignClientGroupModal from "../../components/AssignClientGroupModal";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "CLIENT";
const resourceType = "CLIENT";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const [showAssignClientGroupModal, setShowAssignClientGroupModal] = useState<number[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const [items, setItems] = useState<ClientApi[]>([]);
	const segmentContextValue = useContext(SegmentContext);
	const multipleActionParamsRef = useRef<MultipleActionsParams>();
	const areAllItemsSelectedRef = useRef<boolean>();
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (fetchParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(fetchParams);
			setItems(res);
		}
	};

	const onRemoveClient = async (client: ClientApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeClient(client.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh(params);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRemoveClients = async (
		multipleActionsItems: ClientApi[],
		listParams?: ListParamsType,
		areAllItemsSelected?: boolean,
		multipleActionParams?: MultipleActionsParams
	) => {
		const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			const formattedParams = listParams ? listDataParams(listParams) : {};
			formattedParams.include = "client_group,transactions,invoices,orders";
			await api.organization().removeClients(idsAsString, multipleActionParams, areAllItemsSelected);
			addSuccessFlash(t(t("common.flash.removed", { ns: "lib" }), { ns: "lib" }));
			onRefresh(formattedParams);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRemoveClientGroups = async (ids: string, multipleActionParams?: MultipleActionsParams) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			const requestParams = {
				...multipleActionParams,
				id: ids,
			};
			await api.organization().patchClients({ client_group_id: null }, requestParams);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh(params);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onAssignClientGroups = () => {
		setShowAssignClientGroupModal([]);
		onRefresh(params);
	};

	let config = {
		fields: [
			{
				id: "name",
				name: `${t("common.word.name", { ns: "lib" })}`,
				type: "text" as FilterType,
				render: (item: ClientApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<ListImageName
								data={{ ...item, limitTextWidth: 200 }}
								imageLink={item.avatar_link}
								link={`${location.pathname}/${item.id}`}
							/>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: ClientApi) => item?.name,
			},
			{
				id: "tax_id_no",
				name: t("common.word.tax_id_no.title", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: ClientApi) => item?.tax_id_no,
			},
			{
				id: "card_number",
				name: t("modules.client.field.card_number.title"),
				type: "text" as FilterType,
				render: (item: ClientApi) => item?.card_number,
			},
			{
				id: "contact_phone_number",
				name: t("common.contact.phone.label"),
				type: "text" as FilterType,
				render: (item: ClientApi) => item?.contact_phone_number,
			},
			{
				id: "contact_email",
				name: t("common.word.email", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: ClientApi) => item?.contact_email,
			},
			{
				id: "client_group",
				name: t("modules.client.field.client_group.title"),
				type: "search_select" as FilterType,
				render: (item: ClientApi) => {
					return (
						<Link
							onClick={(e) => e.stopPropagation()}
							to={`/${organization.id}/clients/client-groups/${item.client_group_id}`}
						>
							{item.client_group?.name}
						</Link>
					);
				},
				renderExport: (item: ClientApi) => {
					return item.client_group?.name;
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getClientGroupsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			} as ListConfigField,
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: ClientApi) => `${location.pathname}/${item.id}`,
			},
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: ClientApi) => {
					return `${location.pathname}/${item.id}/edit`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: ClientApi) => onRemoveClient(item),
			},
		],
		multipleActions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (
					multipleActionsItems: ClientApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					onRemoveClients(multipleActionsItems, listParams, areAllItemsSelected, multipleActionsParams);
				},
				visible: (multipleActionsItems: ClientApi[]) =>
					multipleActionsItems.filter((f) => f.status !== "DELETED").length > 0,
			},
			{
				name: t("modules.client.action.assign_client_group.title"),
				click: (
					multipleActionsItems: ClientApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowAssignClientGroupModal(multipleActionsItems.map((item) => item.id));
					multipleActionParamsRef.current = multipleActionsParams;
					areAllItemsSelectedRef.current = Boolean(areAllItemsSelected);
				},
				visible: (multipleActionsItems: ClientApi[]) =>
					multipleActionsItems.filter((f) => f.status !== "DELETED").length > 0,
			},
			{
				name: t("modules.client.action.delete_from_client_group.title"),
				click: (
					multipleActionsItems: ClientApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					const ids = multipleActionsItems.map((item) => item.id).join(",");
					onRemoveClientGroups(ids, multipleActionsParams);
				},
				visible: (multipleActionsItems: ClientApi[]) =>
					multipleActionsItems.filter((f) => f?.client_group?.id).length > 0,
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
			{
				id: "birthday",
				name: t("modules.client.field.birthday_date.title"),
				type: "date" as FilterType,
			},
		],
		selectedColumns: ["name", "tax_id_no", "card_number", "contact_phone_number", "contact_email", "client_group"],
		segments: [
			{
				id: "all",
				name: `${t("common.word.all_male")}`,
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted_male", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		exportConfig: {
			title: t("modules.client.field.export_config.title"),
			filename: t("modules.client.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "client_group";
			setParams(params);
			return api.organization().getClients(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
		fetchTotalItemsCountForSelectedFilters: (params: Record<string, any>, sourceToken?: CancelTokenSource) =>
			api.organization().getClientsCount(params, { cancelToken: sourceToken?.token }),
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return (
		<>
			{showAssignClientGroupModal.length > 0 && (
				<AssignClientGroupModal
					onHide={() => setShowAssignClientGroupModal([])}
					ids={showAssignClientGroupModal}
					onSuccessfulAction={onAssignClientGroups}
					areAllItemsSelected={areAllItemsSelectedRef.current}
					multipleActionsParams={multipleActionParamsRef.current}
				/>
			)}
			<ListData
				data={items}
				config={config}
				emptyList={{
					addAction: () => history.push(`${location.pathname}/new`),
				}}
				onFetch={(fetchItems: ClientApi[]) => {
					setItems(fetchItems);
				}}
				canManageMultiActionsForAllItems
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationClientsIndexIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any> | undefined>();
	const isMobile = useWindowSize().isMobile;
	const segmentContextValue = React.useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.client.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			path: `${props.match.url}/new`,
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
		},
		{
			path: `${props.match.url}/import`,
			title: t("common.action.import_from_csv", { ns: "lib" }),
			dropdown: true,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			path: `${props.match.url}/new`,
			title: t("common.action.add", { ns: "lib" }),
		},
		{
			path: `${props.match.url}/import`,
			title: t("common.action.import_from_csv", { ns: "lib" }),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.client.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationClientsIndexIndexPage;
