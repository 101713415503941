import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import ErrorDuplicateName from "../../../../../../../../../components/Common/Errors/ErrorDuplicateName";
import { ReactComponent as BackSVG } from "../../../../../../../../../images/svg/menu/header-back.svg";
import { CategoryApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	searchText: string;
	handleCancel: () => void;
	handleSave: (data: CategoryApi) => void;
	nestedContext?: boolean;
}

const CreateMenuPageItemCategoryModal = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [category] = useState<CategoryApi>({ name: props.searchText, discountable: true } as CategoryApi);
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<CategoryApi>({
		criteriaMode: "all",
		defaultValues: category,
	});
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = form;
	const onSubmit = handleSubmit(async (data: CategoryApi) => {
		setLoading(true);
		try {
			const res = await api.organization().createCategory(data);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			props.handleSave(res);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	return (
		<>
			<Form key="category-modal-form" onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.nestedContext && <BackSVG className="icon me-2" onClick={() => props.handleCancel()} />}
						{t("modules.menu.header.add_new_category.title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
					<ErrorDuplicateName resource_type={"CATEGORY"} errors={errors} />
					<FormCheck
						type="switch"
						register={register}
						label={t("modules.category.field.discountable.title")}
						name="discountable"
						errors={errors}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading variant="primary" type="submit" loading={loading} onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.handleCancel}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</>
	);
};

export default CreateMenuPageItemCategoryModal;
