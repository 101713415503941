import React, { FC, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { domain } from "go-core";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormInputSelect } from "go-form/components/FormInputSelect";
import FormNumberInput from "go-form/components/FormNumberInput";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { apiSecurity } from "go-security/services/Api/api";
import { OrganizationSecurityApi, RegisterWithUserSecurityApi } from "go-security/services/Api/typesSecurity";
import { useAuth } from "go-security/services/auth";
import SessionIdService from "go-security/services/sessionIdService";
import ParametersSecurity from "../services";
import { getOrganizationCategories } from "../services/utils";

interface Props {
	baseUrl: string;
}

const RegisterUserForm = ({ baseUrl }: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const searchParams = new URLSearchParams(window.location.search);
	const { addFlash } = useFlash();
	const auth = useAuth(baseUrl);

	const form = useForm<RegisterWithUserSecurityApi>({
		criteriaMode: "all",
		shouldFocusError: false,
		defaultValues: {
			user_email: searchParams.get("email") || "",
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		watch,
		control,
	} = form;

	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	const onSubmit = handleSubmit(async (data: RegisterWithUserSecurityApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		try {
			if (data.category === "") data.category = undefined;
			if (data.accept_rules) {
				data.accept_terms = true;
				data.accept_privacy = true;
				data.user_accept_terms = true;
			} else {
				data.accept_terms = false;
				data.accept_privacy = false;
				data.user_accept_terms = true;
			}
			const response = await apiSecurity.registerUserWithOrganization(data);
			const organizations = response.data.data.organizations;

			if (SessionIdService.get()) {
				const latestOrganization = organizations.reduce(
					(prev: OrganizationSecurityApi, curr: OrganizationSecurityApi) => (prev.id > curr.id ? prev : curr)
				);
				const redirectUrl = `/${latestOrganization.id}`;
				window.location.href = `${ParametersSecurity.getLoginRedirectUrl()}/save_session_id?reference_uri=${domain()}${redirectUrl}&session_id=${SessionIdService.get()}`;
			} else {
				await auth.login(data.user_email, data.user_password, false, true);
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash, ["accept_terms", "accept_privacy"]);
		}
	});

	const categories = getOrganizationCategories().map((x) => {
		return {
			value: x,
			label: t(`lib:go_security.enums.organization_category.${x}`),
		};
	});

	const userNameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const userPasswordValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const userPasswordConfirmationValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const userEmailValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const phoneNumberValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	const categoryValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<>
			<FormDirty
				errors={errors}
				isDirty={false}
				key="register-with-user-form"
				loading={loading}
				noValidate
				onSubmit={onSubmit}
				buttonSubmitCustom={
					<ButtonLoading loading={loading} variant="primary" type="submit">
						{t("lib:common.action.register")}
					</ButtonLoading>
				}
			>
				<div className="row">
					<div className="col-md-6">
						<FormInput
							firstInput={true}
							label={t("lib:go_security.register.form.user_name.label")}
							register={register}
							placeholder={t("lib:go_security.register.form.user_name.placeholder")}
							name="user_name"
							errors={errors}
							customValidationConfig={userNameValidationConfig}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							firstInput={true}
							label={t("lib:go_security.register.form.name.label")}
							register={register}
							placeholder={t("lib:go_security.register.form.name.placeholder")}
							name="name"
							errors={errors}
							customValidationConfig={nameValidationConfig}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						<FormInput
							firstInput={true}
							type="password"
							label={t("lib:go_security.register.form.user_password.label")}
							register={register}
							placeholder={t("lib:go_security.register.form.user_password.placeholder")}
							name="user_password"
							errors={errors}
							customValidationConfig={userPasswordValidationConfig}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							firstInput={true}
							type="password"
							label={t("lib:go_security.register.form.user_password_confirm.label")}
							register={register}
							placeholder={t("lib:go_security.register.form.user_password_confirm.placeholder")}
							name="user_password_confirmation"
							errors={errors}
							customValidationConfig={userPasswordConfirmationValidationConfig}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						<FormInput
							firstInput={true}
							label={t("lib:go_security.register.form.user_email.label")}
							register={register}
							placeholder={t("lib:go_security.register.form.user_email.placeholder")}
							name="user_email"
							errors={errors}
							customValidationConfig={userEmailValidationConfig}
						/>
					</div>
					<div className="col-md-6">
						<FormNumberInput
							firstInput
							label={t("lib:go_security.register.form.user_phone_number.label")}
							control={control}
							suffix=""
							placeholder={t("lib:go_security.register.form.user_phone_number.placeholder")}
							name="user_phone_number"
							errors={errors}
							customValidationConfig={phoneNumberValidationConfig}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						<FormInputSelect
							firstInput={true}
							label={t("lib:go_security.register.form.category.label")}
							name="category"
							placeholder={t("lib:go_security.register.form.category.placeholder")}
							errors={errors}
							register={register}
							options={categories}
							customValidationConfig={categoryValidationConfig}
						/>
					</div>
				</div>

				<FormCheck
					name={"accept_rules"}
					register={register}
					errors={errors}
					label={
						<>
							{t("lib:go_security.register.words.accept")}{" "}
							<a href="https://www.gopos.pl/tos">{t("lib:go_security.register.words.tos")}</a>,{" "}
							<a href="https://www.gopos.pl/privacy-policy">
								{t("lib:go_security.register.words.privacy_policy")}
							</a>{" "}
							{t("lib:go_security.register.words.and_accept_receive_informations_from_gopos")}
						</>
					}
				/>

				<FormCheck
					name={"user_accept_marketing_content"}
					register={register}
					errors={errors}
					label={<>{t("lib:go_security.register.form.accept_marketing_content.label")}</>}
				/>
			</FormDirty>
		</>
	);
};

export default RegisterUserForm;
