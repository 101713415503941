import React, { useState } from "react";
import { Form } from "react-bootstrap";

interface Props {
	filter?: Record<string, string>;
	name: string;
	onChange: (value: Record<string, string>) => void;
}

const BaseVariableReportCombo = (props: Props) => {
	const [value, setValue] = useState<string | undefined>(props.filter ? props.filter[props.name] : undefined);

	const onChange = (value: string) => {
		const parserFilters: Record<string, any> = {
			[props.name]: value,
		};

		props.onChange(parserFilters);
	};

	return (
		<>
			<Form.Group className="form-group">
				<Form.Label>{props.name}</Form.Label>
				<input
					type={"text"}
					value={value}
					className={"form-control"}
					onBlur={(e) => onChange(e.target.value)}
					onChange={(e) => setValue(e.target.value)}
				/>
			</Form.Group>
		</>
	);
};

export default BaseVariableReportCombo;
