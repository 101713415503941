import React, { FC, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import EmptyList from "go-core/components/EmptyList";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as DoubleAngleArrowLeft } from "../../../../../../../images/svg/liveOrders/double-angle-arrow-left.svg";
import { ReactComponent as DoubleAngleArrowRight } from "../../../../../../../images/svg/liveOrders/double-angle-arrow-right.svg";
import { LiveOrderApi } from "../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../services/context";
import LiveOrdersListItem from "./LiveOrdersListItem";

interface Props {
	isOrderSelected?: boolean;
	setFocusedOrder?: (order: LiveOrderApi | undefined) => void;
	orderRoutePath?: string;
	isShown: boolean;
	orderListRoutePath: string;
	setIsShown: (show: boolean) => void;
}

const LiveOrdersList: FC<Props> = ({
	setFocusedOrder,
	isOrderSelected,
	orderRoutePath,
	orderListRoutePath,
	isShown,
	setIsShown,
}) => {
	const history = useHistory();
	const clickRef = useRef<boolean>(false);
	const isMobile = useWindowSize().isMobile;

	const onSelectOrder = (order: LiveOrderApi) => {
		if (setFocusedOrder && orderRoutePath) {
			clickRef.current = true;
			setTimeout(() => {
				clickRef.current = false;
			}, 10);
			setFocusedOrder(order);
			history.push(orderRoutePath + order.id);
		}
	};

	const drawOrders = useCallback(
		(orders: LiveOrderApi[]) =>
			orders.map((order, index) => (
				<LiveOrdersListItem
					order={order}
					key={`live-orders-${index}-${order.id}`}
					orderListRoutePath={orderListRoutePath}
					onSelectOrder={() => onSelectOrder(order)}
					setFocusedOrder={() => setFocusedOrder && setFocusedOrder(order)}
					onMouseEnter={() => setFocusedOrder && setFocusedOrder(order)}
					onMouseLeave={() => {
						if (clickRef.current) return;
						setFocusedOrder && setFocusedOrder(undefined);
					}}
				/>
			)),
		[isShown, isMobile]
	);

	return (
		<OrdersContext.Consumer>
			{(orders) => (
				<>
					<div className={`live-orders-list ${!isShown ? "live-orders-list--collapsed" : ""}`}>
						{orders.orders?.length > 0 ? drawOrders(orders.orders) : <EmptyList />}
					</div>
					)
					<button
						className="live-orders-list-button"
						onClick={() => setIsShown(!isShown)}
						style={{
							left: isShown ? "-379px" : "-45px",
							transition: "0.2s all",
							opacity: isOrderSelected ? "0" : "1",
						}}
					>
						{isShown ? <DoubleAngleArrowRight /> : <DoubleAngleArrowLeft />}
					</button>
				</>
			)}
		</OrdersContext.Consumer>
	);
};

export default LiveOrdersList;
