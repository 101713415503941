import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Calendar, OnChangeProps } from "react-date-range";
import "react-date-range/dist/styles.css";
// main style file
import "react-date-range/dist/theme/default.css";
import { useTranslation } from "react-i18next";
import FormatDate, { formatStringToDate } from "go-core/components/Formatters/FormatDate";
// theme css file
import { ReactComponent as CalendarSVG } from "go-core/images/svg/calendar.svg";
import { getLang } from "go-core/utils/utils";
import { getLocaleForDatePicker, hasErrors } from "../services/utils";
import { FormErrorMessage } from "./FormErrorMessage";
import { TimePicker } from "./TimePicker";

type CustomToggleProps = {
	children?: React.ReactNode;
	onClick?: (event: React.MouseEvent<any, MouseEvent>) => {};
	value?: string;
	errors?: any;
	name?: string;
	id?: string;
	onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
};
const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={`d-flex flex-grow-1${hasErrors(props.errors, props.name) ? " is-invalid" : ""}`}>
				<input
					id={props.id}
					type="text"
					autoComplete="off"
					disabled={props.disabled}
					className={`form-control${hasErrors(props.errors, props.name) ? " is-invalid" : ""}`}
					style={{ paddingRight: "25px", backgroundColor: props.disabled ? "hsl(0, 0%, 95%)" : "inherit" }}
					ref={ref}
					onClick={(e) => {
						e.preventDefault();
						if (props.onClick) props.onClick(e);
					}}
					onChange={(evt) => (props.onChange ? props.onChange(evt) : undefined)}
					value={
						props.value === "Invalid Date"
							? t("constraints.invalid_date", { ns: "lib" }).toString()
							: props.value
					}
				/>
				<CalendarSVG
					className={`form-input-icon ${hasErrors(props.errors, props.name) ? "active" : ""}`}
					onClick={(e) => {
						e.preventDefault();
						if (props.onClick) props.onClick(e);
					}}
				/>
			</div>
			<FormErrorMessage errors={props.errors} name={props.name} />
		</>
	);
});

CustomToggle.displayName = "CustomToggle";

const getMinute = (value: string) => {
	return Number(value.split(":")[1]);
};
const getHour = (value: string) => {
	return Number(value.split(":")[0]);
};
const parseToTime = (date: Date | string) => {
	if (typeof date === "string") {
		return "";
	}
	const hours = date.getHours() > 9 ? `${date.getHours()}` : `0${date.getHours()}`;
	const minutes = date.getMinutes() > 9 ? `${date.getMinutes()}` : `0${date.getMinutes()}`;
	const seconds = date.getSeconds() > 9 ? `${date.getSeconds()}` : `0${date.getSeconds()}`;
	return `${hours}:${minutes}:${seconds}`;
};

const parseToDate = (date: Date | string) => {
	if (typeof date === "string") {
		return "";
	}
	return date;
};

interface Props {
	date?: Date | string;
	onChange?: (date: Date | string) => void;
	formPicker?: boolean;
	timePicker: boolean;
	errors?: any;
	name?: string;
	onApply?: (date?: Date | string) => void;
	onClear?: () => void;
	dayPicker: boolean;
	allowClear?: boolean;
	disabled?: boolean;
	minDate?: Date | string;
	maxDate?: Date | string;
	allowResetDate?: boolean;
}

const DatePickerInline = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [timeFrom, setTimeFrom] = useState(props.date ? parseToTime(props.date) : "00:00:00");
	const [date, setDate] = useState<Date | string>(props.date ? parseToDate(props.date) : "");
	const locale = getLocaleForDatePicker();

	const onChangeInternal = (date: OnChangeProps) => {
		const dateValue = date as Date;
		dateValue.setHours(getHour(timeFrom), getMinute(timeFrom), 0);
		setDate(dateValue);
		if (props.onChange) {
			props.onChange(dateValue);
		}
	};
	const onChangeTimeStartDate = (time: string) => {
		setTimeFrom(time);
		if (date) {
			const newStartDate = typeof date === "string" ? formatStringToDate(date) : new Date(date.valueOf());
			newStartDate.setHours(getHour(time), getMinute(time), 0);
			onChangeTime(newStartDate);
		}
	};

	const onChangeTime = (date: Date) => {
		const dateValue = date as Date;
		setDate(dateValue);
		props.onChange?.(dateValue);
	};

	const onApply = () => {
		props.onApply?.(date);
	};

	const onClear = () => {
		setTimeFrom("00:00:00");
		props.onChange?.("");
		props.onClear?.();
	};

	useEffect(() => {
		if (props.date === undefined || props.date === "") {
			setDate("");
		}
		if (typeof props.date === "string" && props.date.length > 0) {
			const dates = props.date.split(/[-./]/g);
			let day = Number(dates[0]);
			let month = Number(dates[1]);
			let year, newDate, times, hours, minutes;

			if (props.timePicker && dates[2]) {
				const yearTime = dates[2].split(", ");
				year = Number(yearTime[0]);
				if (yearTime[1]) {
					times = yearTime[1].split(":");
					hours = Number(times[0]);
					minutes = Number(times[1]);
					newDate = new Date(year, month - 1, day, hours, minutes, 0);
					if (
						newDate instanceof Date &&
						!isNaN(newDate.getTime()) &&
						dates[0].length > 0 &&
						year.toString().length === 4 &&
						dates[1].length > 1 &&
						day > 0 &&
						month > 0 &&
						times[0].length === 2 &&
						times[1].length === 2
					) {
						setDate(newDate);
						setTimeFrom(`${times[0]}:${times[1]}`);
						if (props.onChange) {
							props.onChange(newDate);
						}
					}
				}
			} else {
				if (dates.length > 2) {
					year = Number(dates[2]);
				} else {
					year = Number(dates[1]);
					month = Number(dates[0]);
					day = new Date().getDate();
				}
				newDate = new Date(year, month - 1, day);
				if (
					newDate instanceof Date &&
					!isNaN(newDate.getTime()) &&
					dates[0].length > 0 &&
					year.toString().length === 4 &&
					dates[1].length > 1 &&
					day > 0 &&
					month > 0
				) {
					setDate(newDate);
					if (props.onChange) {
						props.onChange(newDate);
					}
				}
			}
		}
	}, [props.date]);

	useEffect(() => {
		const els = document.getElementsByClassName("rdrMonths")[0] as HTMLElement;
		if (els) {
			if (!props.dayPicker) {
				els.style.display = "none";
			} else {
				els.style.display = "";
			}
		}
	}, []);

	const onChangeMonths = (changedDate: Date) => {
		if (date instanceof Date && !isNaN(date.getTime())) {
			changedDate.setDate(date.getDate());
		}
		onChangeInternal(changedDate);
	};

	const setCurrentDateHandler = () => {
		const currentDate = new Date();
		setDate(currentDate);
		setTimeFrom(parseToTime(currentDate));
		if (props.onChange) {
			props.onChange(currentDate);
		}
	};

	return (
		<div className="date-picker" style={{ width: "auto" }} data-testid="date-picker">
			<Button type="button" variant="light" className="w-100 mw-100" onClick={setCurrentDateHandler}>
				{t("go_form.action.set_current_date_and_time.title", { ns: "lib" })}
			</Button>
			<Calendar
				editableDateInputs={true}
				onShownDateChange={(date) => onChangeMonths(date)}
				date={parseToDate(date ? date : "")}
				locale={locale}
				minDate={props.minDate}
				maxDate={props.maxDate}
				onChange={onChangeInternal}
			/>
			{props.timePicker && (
				<div className="time-picker">
					<TimePicker time={timeFrom} onChange={onChangeTimeStartDate} />
				</div>
			)}
			<div className="calendar-actions">
				<Button type="button" variant="light" className="calendar-btn btn-confirm" onClick={onApply}>
					{t("lib:common.action.confirm")}
				</Button>
				{props.allowClear && (
					<Button variant="light" type="button" className="calendar-btn" onClick={onClear}>
						{t("lib:common.action.clear")}
					</Button>
				)}
			</div>
		</div>
	);
};

export const DatePicker = (props: Props): JSX.Element => {
	const [state, setState] = useState<Date | string>(props.date ? props.date : "");
	const [separator, setSeparator] = useState<string>("");
	const onChangeInternal = (item: Date | string) => {
		setState(item);
		if (props.onChange) {
			props.onChange(item);
		}
	};

	useEffect(() => {
		if (props.allowResetDate || props.date) {
			setState(props.date || "");
		}
	}, [props.date, props.allowResetDate]);

	useEffect(() => {
		const separator = (state && state.toString().match(/[-./]/g)?.[0]) || "";
		if (separator) {
			setSeparator(separator);
		}
	}, [state]);

	const renderDate = () => {
		if (props.timePicker) {
			const formattedDate = FormatDate(state);
			// let formattedDate = state?.toLocaleString(getLang(), {
			//     ...formatDateTimeOptions,
			//     second: undefined
			// } as any)
			if (!separator) return formattedDate;
			return formattedDate.replace(/[.]/g, separator);
		}
		if (!props.dayPicker) {
			if (!state) return "";
			const splitted = FormatDate(state).split(",")[0].split(/[-./]/g);
			// let splitted = state?.toLocaleString("pl-PL", {
			//     ...formatDateTimeOptions,
			//     second: "2-digit"
			// } as any).split(',')[0].split(/[-./]/g);
			if (splitted.length > 2) {
				return state?.toLocaleString(getLang(), { year: "numeric", month: "2-digit" });
				// return `${splitted[1]}${separator || "."}${splitted[2]}`
			} else if (splitted.length === 1) {
				return `${splitted[0]}`;
			}
			return state?.toLocaleString(getLang(), { year: "numeric", month: "2-digit" });
			// return `${splitted[0]}${separator || "."}${splitted[1]}`
		}
		return state?.toLocaleString(getLang()).split(",")[0];
	};

	const onApply = () => {
		const doc = document.getElementById(`${props.name}`);
		if (doc) {
			doc.click();
		}
		if (props.onApply) {
			props.onApply(state);
		}
	};

	const onClear = () => {
		const doc = document.getElementById(`${props.name}`);
		if (doc) {
			doc.click();
			if (props.onClear) {
				props.onClear();
			}
		}
	};

	const handleChangeDate = (val: string | null) => {
		if (val) {
			setState(val);
		}
	};

	return (
		<Dropdown onSelect={(value) => handleChangeDate(value)}>
			<Dropdown.Toggle
				as={CustomToggle}
				name={props.name}
				errors={props.errors}
				value={renderDate()}
				disabled={props.disabled}
				id={`${props.name}`}
			/>

			<Dropdown.Menu>
				<DatePickerInline
					dayPicker={props.dayPicker}
					timePicker={props.timePicker}
					minDate={props.minDate}
					maxDate={props.maxDate}
					date={state}
					onChange={onChangeInternal}
					onApply={onApply}
					onClear={onClear}
					allowClear={props.allowClear}
				/>
			</Dropdown.Menu>
		</Dropdown>
	);
};
