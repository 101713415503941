import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	client: ClientApi;
}

const ClientInfoCard: FC<Props> = ({ client }) => {
	const { t } = useTranslation();

	return (
		<Card>
			<Card.Body>
				<h5>{t("lib:common.word.informations")}</h5>
				{client.card_number && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.client.field.card_number.title")}</span>
							<span>{client.card_number}</span>
						</div>
					</div>
				)}
				{client.card_code && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.client.field.card_code.title")}</span>
							<span>{client.card_code}</span>
						</div>
					</div>
				)}
				{client.card_expires_at && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.client.field.card_expires_at.title")}</span>
							{FormatDate(client.card_expires_at).split(",")[0]}
						</div>
					</div>
				)}
				{client.last_activity && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.client.field.card_last_activity.title")}</span>
							{FormatDate(client.last_activity)}
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

export default ClientInfoCard;
