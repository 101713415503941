import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";

const LogsNavigation = (): JSX.Element => {
	const user = useSelector(selectUser);
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const defaultPermission = useSelector(hasPermission("DEFAULT"));
	const venueOrderShowPermission = useSelector(hasPermission("VENUE_ORDER_SHOW"));
	const managePermission = useSelector(hasPermission("MANAGE"));
	const settingsPermission = useSelector(hasPermission("SETTINGS"));
	const isSupportRole = user?.roles?.includes("ROLE_SUPPORT");
	const prefix = `/${organization.id}/logs`;

	const items = [
		{
			title: t("modules.employee_activity.header.title"),
			route: `${prefix}/employee_activities`,
			hide: !venueOrderShowPermission,
		},
		{
			title: t("lib:go_component.message_events.header.title"),
			route: `${prefix}/message_events`,
			hide: !defaultPermission,
		},
		{
			title: t("lib:go_component.job.header.title"),
			route: `${prefix}/jobs`,
			hide: false,
		},
		{
			title: t("lib:go_component.webhook.header.title"),
			route: `${prefix}/webhooks`,
			hide: !managePermission,
		},
		{
			title: t("lib:go_component.request_item.header.title"),
			route: `${prefix}/request_items`,
			hide: !defaultPermission,
		},
		{
			title: t("modules.order_sync.header.title"),
			route: `${prefix}/reports/order_sync`,
			hide: !(isSupportRole || settingsPermission),
		},
	];

	return <SubNavigationItems title={t("lib:common.word.logs")} items={items} />;
};

export default LogsNavigation;
