import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import CustomFieldsForm from "go-app/components/CustomFields/CustomFieldsForm";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { MultipleActionsParams } from "go-list/list/services/types";
import { CustomFieldEntityApi, CustomFieldTemplateApi } from "go-segment/services/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	onClose: () => void;
	items: number[];
	handleSave: () => void;
	allItemsSelected: boolean;
	multipleActionParams?: MultipleActionsParams;
}

interface FormProps {
	custom_fields: CustomFieldEntityApi[];
}

const AssignListCustomFieldsModal: FC<Props> = ({
	onClose,
	items,
	handleSave,
	allItemsSelected,
	multipleActionParams,
}) => {
	const cfParams: Record<string, string> = {
		include: "options,constraints",
		f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=ITEM`),
	};
	const form = useForm<FormProps>({
		defaultValues: {},
		criteriaMode: "all",
	});
	const [loading, setLoading] = useState(false);
	const { setError, handleSubmit, formState } = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const [customFields, setCustomFields] = useState<CustomFieldTemplateApi[] | undefined>(undefined);

	useEffect(() => {
		onFetchCustomFields();
	}, []);

	const onFetchCustomFields = async () => {
		try {
			const res = await api.organization().getCustomFields(cfParams);
			setCustomFields(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onSubmit = handleSubmit(async (data: FormProps) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		const params: Record<string, any> = multipleActionParams ? { ...multipleActionParams } : {};
		if (!allItemsSelected) params.id = items.join(",");
		data.custom_fields = data.custom_fields.filter((customField) => customField?.value);
		try {
			await api.organization().patchItemGroups(data, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleSave();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});
	return (
		<Modal show={true} onHide={onClose}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<h5>{t("modules.item_group.action.assign_custom_fields.title")}</h5>
				</Modal.Header>
				<Modal.Body>
					{customFields ? (
						<CustomFieldsForm
							form={form}
							customFields={[]}
							customFieldsConfig={customFields}
							setErrors={setErrors}
						/>
					) : (
						<LoadingContainer />
					)}
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default AssignListCustomFieldsModal;
