import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import ComboReportTable from "./components/ComboReportTable";

const OrganizationReportsComboPage = () => {
	const [data, setData] = useState<(string | number)[][] | undefined>();
	const [loading, setLoading] = useState(false);
	const { addFlash } = useFlash();
	const form = useForm<{ data: "string" }>({
		criteriaMode: "all",
	});
	const { formState, register, getValues } = form;
	const { t } = useTranslation();

	const fetchData = async (formData: { data: string }) => {
		setLoading(true);
		let parsedFormData;

		try {
			parsedFormData = JSON.parse(formData.data);
		} catch (err) {
			addFlash({
				type: "danger",
				msg: "wrong_data",
			});
			setLoading(false);
			setData(undefined);
			return;
		}

		try {
			const res = await apiReport.getComboReport(parsedFormData);
			setData(res);
		} catch (err) {
			handleError.alert(err, addFlash);
			setData(undefined);
		}
		setLoading(false);
	};

	return (
		<FormDirty formState={formState}>
			<div className="mb-3">
				<FormInput name="data" errors={formState.errors} register={register} as="textarea" />
				<ButtonLoading loading={loading} onClick={() => fetchData(getValues())}>
					{t("lib:common.action.send")}
				</ButtonLoading>
			</div>
			<ComboReportTable data={data} loading={loading} />
		</FormDirty>
	);
};

export default OrganizationReportsComboPage;
