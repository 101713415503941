import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as RemoveSVG } from "../../../../../images/svg/remove.svg";
import { ClientDetailsApi, ClientDetailsImportContext } from "../services/types";

interface Props {
	requests: ClientDetailsImportContext;
	onHide: () => void;
	data?: ClientDetailsApi;
}

export const ClientDetailsForm = ({ onHide, requests, data }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useForm<ClientDetailsApi>({ defaultValues: data });
	const {
		handleSubmit,
		control,
		register,
		formState: { errors },
	} = form;

	const { fields, remove, append } = useFieldArray({
		control,
		name: "additional_information",
		keyName: "key",
	});

	const { getClientScopes, editClientDetail, addClientDetail, getClientGrantTypes } = requests;
	const { addFlash, addSuccessFlash } = useFlash();

	const submitHandler = handleSubmit(async (formData: ClientDetailsApi) => {
		setLoading(true);
		try {
			if (data?.client_id) await editClientDetail(data.client_id, formData);
			else await addClientDetail(formData);

			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			onHide();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	});

	const getScopes = async () => {
		const scopes = await getClientScopes();
		return scopes.map((scope) => ({ value: scope, label: scope }));
	};

	const getAuthorizedGrantTypes = async () => {
		const grantTypes = await getClientGrantTypes();
		return grantTypes.map((grantType) => ({ value: grantType, label: grantType }));
	};

	return (
		<Modal show={true} onHide={onHide} size="lg">
			<Modal.Header>
				<Modal.Title>
					{data?.client_id
						? t("go_component.client_details.action.edit_client_detail.title", { ns: "lib" })
						: t("go_component.client_details.action.add_client_detail.title", { ns: "lib" })}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container px-0">
					<div className="row">
						<div className="col-md-6">
							<FormSelectGroup
								label="Scopes"
								name="scopes"
								control={control}
								loadOptions={getScopes}
								errors={errors}
								isMulti
								defaultValue={data?.scopes?.map((grantType) => ({
									value: grantType,
									label: grantType,
								}))}
								data-testid="scopes"
							/>
						</div>
						<div className="col-md-6">
							<FormSelectGroup
								errors={errors}
								name="authorized_grant_types"
								control={control}
								loadOptions={getAuthorizedGrantTypes}
								label="Grand Types"
								isMulti
								defaultValue={data?.authorized_grant_types?.map((grantType) => ({
									value: grantType,
									label: grantType,
								}))}
								data-testid="authorized_grant_types"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormNumberInput
								name="access_token_validity"
								errors={errors}
								control={control}
								label={t("go_component.client_details.field.access_token_validity.title", {
									ns: "lib",
								})}
								suffix=""
							/>
						</div>
						<div className="col-md-6">
							<FormNumberInput
								name="refresh_token_validity"
								errors={errors}
								control={control}
								label={t("go_component.client_details.field.refresh_token_validity.title", {
									ns: "lib",
								})}
								suffix=""
							/>
						</div>
					</div>
					{fields.map((field, index) => {
						return (
							<div className="row align-items-center" key={field.key}>
								<div className="col-6">
									<FormInput
										name={`additional_information.${index}.name`}
										register={register}
										errors={errors}
										label={t("go_component.client_details.field.additional_information_key.title", {
											ns: "lib",
										})}
										defaultValue={field.name}
									/>
								</div>
								<div className="col-5">
									<FormInput
										name={`additional_information.${index}.value`}
										register={register}
										errors={errors}
										label={t(
											"go_component.client_details.field.additional_information_value.title",
											{
												ns: "lib",
											}
										)}
										defaultValue={field.value}
									/>
								</div>
								<div className="col-1">
									<RemoveSVG onClick={() => remove(index)} />
								</div>
							</div>
						);
					})}
					<Button variant="add" type="button" onClick={() => append({ name: "", value: "" })}>
						+ {t("common.action.add", { ns: "lib" })}
					</Button>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onHide}>{t("common.action.cancel", { ns: "lib" })}</Button>
				<ButtonLoading loading={loading} onClick={submitHandler}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
			</Modal.Footer>
		</Modal>
	);
};
