import React, { useEffect } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Header from "go-app/components/Header";
import ImportCsv from "go-component/components/ImportCsv/ImportCsv";
import { camelToSnakeCase } from "go-component/utils/componentUtils";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";

const getTranslationsConfig = (t: TFunction) => {
	return {
		export_csv: t("modules.client.action.export_csv.title"),
		resource_name: t("modules.client.field.resource_name.title"),
		constraints: {
			cannot_be_empty: t("constraints.cannot_be_empty"),
			csv_corrupted: t("constraints.csv_corrupted"),
			duplicate_name: t("constraints.duplicate_name"),
			duplicate_tax_id_no: t("constraints.duplicate_tax_id_no"),
			invalid_custom_field_option: t("constraints.invalid_custom_field_option"),
			invalid_custom_field_value: t("constraints.invalid_custom_field_value"),
			invalid_email: t("constraints.invalid_email"),
			invalid_value: t("constraints.invalid_value"),
			max_length: t("constraints.max_length"),
		},
		database_column_name: {
			name: t("enums.resource_type.client.name"),
			uid: t("enums.resource_type.client.uid"),
			clientGroup: t("enums.resource_type.client.clientGroup"),
			taxIdNo: t("enums.resource_type.client.taxIdNo"),
			addressStreet: t("enums.resource_type.client.addressStreet"),
			addressBuildingNr: t("enums.resource_type.client.addressBuildingNr"),
			addressFlatNr: t("enums.resource_type.client.addressFlatNr"),
			addressCity: t("enums.resource_type.client.addressCity"),
			addressCountry: t("enums.resource_type.client.addressCountry"),
			addressZipCode: t("enums.resource_type.client.addressZipCode"),
			contactPhoneNr: t("enums.resource_type.client.contactPhoneNr"),
			contactEmail: t("enums.resource_type.client.contactEmail"),
		},
	};
};

const OrganizationClientsIndexImportPage = (): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.client.action.import.title"));
	}, []);

	const comparationCheck = (data: ClientApi, domainFieldName: string) => {
		if (!data) return false;

		switch (domainFieldName) {
			case "client_group":
				return data.client_group?.name;
			case "contact_phone_nr":
				return data.contact_phone_number;
			case "address_building_nr":
				return data.address_build_nr;

			default:
				return data[domainFieldName as keyof ClientApi];
		}
	};

	const renderComparedValue = (data: Record<string, any>, fieldName: string, differs?: boolean) => {
		if (!data) return <span></span>;

		const replacedCf = fieldName.replace("+", "");
		const cf = data.custom_fields?.find((x: Record<string, any>) => x.slug === camelToSnakeCase(replacedCf, true));
		if (cf && Object.keys(cf).length > 0) return cf.value;

		let caption, title;
		switch (fieldName) {
			case "client_group":
				title = data.client_group?.name;
				caption = title;
				break;
			case "contact_phone_nr":
				title = data.contact_phone_number;
				caption = title;
				break;
			case "address_building_nr":
				title = data.address_build_nr;
				caption = title;
				break;
			default:
				title = data[fieldName];
				caption = title;
				break;
		}

		return caption?.length > 0 && title?.length > 0 ? (
			<span style={{ textDecoration: `${differs ? "line-through" : "none"}` }} title={title}>
				{caption}
			</span>
		) : (
			<></>
		);
	};

	return (
		<>
			<Header title={t("modules.client.action.import.title")} />
			<ImportCsv
				resourceType="clients"
				redirectionUrl={`/${organization.id}/clients`}
				importCsvRequestParams="client_group"
				comparationCheck={comparationCheck}
				renderComparedValue={renderComparedValue}
				translations={getTranslationsConfig(t)}
				workingInBackground
			/>
		</>
	);
};
export default OrganizationClientsIndexImportPage;
