import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as AlertSVG } from "../../../../../../../../../../../images/svg/alert.svg";
import { TerminalApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { getTerminalVersion } from "../../../utils";

interface Props {
	terminal: TerminalApi;
}

const TerminalVersion = ({ terminal }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="d-flex align-items-center">
			<span>{getTerminalVersion(terminal)}</span>
			{terminal.up_to_date === false && (
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip id={`tooltip-${terminal.id}`}>
							{t("modules.terminal.constraints.terminal_not_up_to_date.title")}
						</Tooltip>
					}
				>
					{({ ref, ...triggerHandler }) => <AlertSVG className={"ms-1"} ref={ref} {...triggerHandler} />}
				</OverlayTrigger>
			)}
		</div>
	);
};

export default TerminalVersion;
