import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDateRangePicker } from "go-form/components/FormDateRangePicker";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { parsePredefinedRangeToDefaultString } from "go-list/utils/daterangeutils";
import { DashboardFilterActions } from "../../services/filters/reducer";
import useDashboardContext from "../../services/useDashboardContext";

interface Props {
	selectedFilter?: string | null;
	isPredefinedDate: boolean;
	selectedDateFilter?: string | null;
	setSelectedDateFilter?: (value: string | null) => void;
}

const getInitialDate = (isPredefinedDate: boolean, selectedFilter: string) => {
	if (isPredefinedDate) return undefined;
	const date = parsePredefinedRangeToDefaultString(selectedFilter);
	return date
		? date.replace(date.includes(FILTER_VALUE_SEPARATOR) ? FILTER_VALUE_SEPARATOR : ",", " - ")
		: selectedFilter.replace(",", " - ");
};

const DashboardFilterDateRange = ({
	isPredefinedDate,
	selectedDateFilter,
	setSelectedDateFilter,
}: Props): JSX.Element => {
	const { state, dispatch } = useDashboardContext();
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [refId, setRefId] = useState<HTMLDivElement | null>(null);
	const { t } = useTranslation();
	const form = useForm<Props>({
		defaultValues: {
			selectedFilter: getInitialDate(isPredefinedDate, state.filtersConfig.selectedFilter.dateRange),
		},
	});
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = form;
	const watchedDate = watch("selectedFilter");
	const pickerEl = document.getElementById("dropdown-daterange-selectedFilter") as HTMLInputElement;
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		if (pickerEl && isPredefinedDate) pickerEl.value = "";
	}, [state.filtersConfig.selectedFilter.dateRange, isPredefinedDate]);

	useEffect(() => {
		if (refId) {
			wrapperRef.current = refId;
		}
	}, [watchedDate]);

	const handleClickOutside = (event: MouseEvent) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
			const date = watch().selectedFilter;
			if (isMobile) {
				if (date && selectedDateFilter !== date) {
					const value = date.replace(" - ", ",");
					if (setSelectedDateFilter) setSelectedDateFilter(value);
					setValue("selectedFilter", value);
					wrapperRef.current = null;
				}
				return;
			}
			if (date && state.filtersConfig.selectedFilter.dateRange !== date) {
				dispatch({
					type: DashboardFilterActions.CHANGE_DATE_FILTER,
					data: date.replace(" - ", ","),
				});
				wrapperRef.current = null;
			}
		}
	};

	useEffect(() => {
		if (wrapperRef.current) {
			setRefId(wrapperRef.current);
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	const renderDatePicker = useMemo(() => {
		return (
			<FormDateRangePicker
				timeStart={"00:00"}
				timeEnd={"23:59"}
				errors={errors}
				control={control}
				name={"selectedFilter"}
				minDate={new Date("2015")}
				maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
				onApply={handleClickOutside}
				placeholder={t("modules.dashboard.field.date_range.title")}
				handleInternalChange={
					isMobile
						? (value) => {
								setValue("selectedFilter", value);
								if (setSelectedDateFilter) setSelectedDateFilter(value);
						  }
						: undefined
				}
			/>
		);
	}, [isMobile, selectedDateFilter]);

	return (
		<div className={"filter-date-range-wrapper"} ref={wrapperRef}>
			{renderDatePicker}
		</div>
	);
};
export default DashboardFilterDateRange;
