import { RefObject } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ListParamsType } from "../../../../../../list/services/types";
import { ListConfigAction } from "../../../services/types";
import { ActionsMenu } from "../ActionsMenu";
import { SingleActionButton } from "../buttons/SingleActionButton";

interface ActionsListProps {
	mainDivRef: RefObject<HTMLDivElement>;
	actions: ListConfigAction[];
	item: any;
	params?: ListParamsType;
}

export interface SingleActionListProps {
	mainDivRef: RefObject<HTMLDivElement>;
	actions?: ListConfigAction[];
	item: any;
	doesIdColumnRedirectToPreviewPage?: boolean;
	params?: ListParamsType;
}

const ActionsList = ({ mainDivRef, actions, item, params }: ActionsListProps): JSX.Element => {
	return (
		<ActionsMenu mainDivRef={mainDivRef}>
			{actions.map((action, index) => {
				if (action.link) {
					return (
						<Dropdown.Item key={index} as={Link} to={action.link(item)}>
							{action.name}
						</Dropdown.Item>
					);
				}

				const onClick = () => action.click?.(item, params);

				if (onClick) {
					return (
						<Dropdown.Item key={index} onClick={onClick}>
							{action.name}
						</Dropdown.Item>
					);
				}

				return null;
			})}
		</ActionsMenu>
	);
};

export const SingleActionList = ({
	mainDivRef,
	actions,
	item,
	doesIdColumnRedirectToPreviewPage,
	params,
}: SingleActionListProps): JSX.Element | null => {
	if (!actions || actions.length === 0) return null;

	if (doesIdColumnRedirectToPreviewPage) {
		return <ActionsList mainDivRef={mainDivRef} actions={actions} item={item} params={params} />;
	}

	const moreActions = actions.slice(1);

	return (
		<ButtonGroup className="actions-button-group">
			<SingleActionButton action={actions[0]} item={item} params={params} />
			{moreActions.length > 0 && (
				<ActionsList mainDivRef={mainDivRef} actions={moreActions} item={item} params={params} />
			)}
		</ButtonGroup>
	);
};
