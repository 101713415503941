import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { domain } from "go-core";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import { LoadingContainer } from "go-core/components/Loading";
import LoginSecurityLayout from "go-security/components/LoginSecurityLayout";
import RegisterForm from "go-security/components/RegisterForm";
import { useAuth } from "go-security/services/auth";
import { selectIsAuthenticated } from "go-security/services/session/selectors";
import { selectUser } from "go-security/services/users/selectors";
import ParametersSecurity from "../../services";

interface Props {
	appName: string;
	baseUrl: string;
}

const SecurityRegisterPage = (props: Props): JSX.Element => {
	const [loading, setLoading] = useState(true);
	const location: any = useLocation();
	const auth = useAuth(props.baseUrl);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const user = useSelector(selectUser);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	if (isAuthenticated && loading) {
		setLoading(false);
	}

	useEffect(() => {
		if (!isAuthenticated) {
			auth.checkLogin()
				.then(() => {
					setLoading(false);
				})
				.catch((err: Exception | unknown) => {
					handleError.alert(err, addFlash);
				});
		}
	}, []);

	if (loading) {
		return <LoadingContainer />;
	}

	const renderInfo = () => {
		return (
			<div className="info">
				<img className="security-info-dots" src="/graphics/login/dots-light-blue.svg" alt="Blue dots" />
				<div className="registration-info-content">
					<ul>
						<li>
							{t("lib:go_security.register.features.trial.title")}
							<small>{t("lib:go_security.register.features.trial.description")}</small>
						</li>
						<li>
							{t("lib:go_security.register.features.about.title")}
							<small>{t("lib:go_security.register.features.about.description")}</small>
						</li>
						<li>
							{t("lib:go_security.register.features.help.title")}
							<small>
								{t("lib:go_security.register.features.help.description_1")}{" "}
								<a target="_blank" href="https://gopos.pl/pl/support" rel="noreferrer">
									{t("lib:go_security.register.features.help.support")}
								</a>{" "}
								{t("lib:go_security.register.features.help.description_2")}
							</small>
						</li>
					</ul>
				</div>
			</div>
		);
	};

	const shouldRedirect = () => {
		return !!(location && location.state && location.state.from);
	};

	const getEncodeRedirectUrl = () => {
		return `${location.state.from.pathname}${
			location.state.from.search && encodeURIComponent(location.state.from.search)
		}`;
	};

	const registerWithGoogle = () => {
		const url = ParametersSecurity.getLoginRedirectUrl();
		let redirectUrl;
		if (shouldRedirect()) {
			redirectUrl = domain() + getEncodeRedirectUrl();
		} else {
			redirectUrl = `${domain()}/dashboard`;
		}
		window.location.href = `${url}/oauth2/authorization/google?redirect_uri=${redirectUrl}`;
	};

	return (
		<LoginSecurityLayout info={renderInfo()}>
			<div className="form-login-header">
				{isAuthenticated ? (
					<>
						<span className="ms-auto">
							{t("lib:go_security.register.words.logged_as")}
							<Link to="/profile">{user?.name ? user.name : user?.email}</Link>{" "}
						</span>
						<a className="btn btn-primary-light" href="#" onClick={() => auth.logout(true)}>
							{t("lib:common.action.logout")}
						</a>
					</>
				) : (
					<>
						<span className="ms-auto">{t("lib:go_security.register.words.already_registered")}</span>
						<Link to="/login" className="btn btn-primary-light">
							&nbsp;{t("lib:common.action.login")}
						</Link>
					</>
				)}
			</div>
			<div className="form-login">
				<RegisterForm appName={props.appName} baseUrl={props.baseUrl} />
				<p className="login-contact-link mt-3">
					{t("lib:go_security.register.words.have_question")}
					<a href="https://www.gopos.pl/contact">{t("lib:go_security.register.words.contact_with_sales")}</a>
				</p>
				{!isAuthenticated && (
					<>
						<div className="horizontal-row">
							<div className="horizontal-text">{t("lib:common.word.or")}</div>
						</div>
						<div className="oauth-google" onClick={registerWithGoogle}>
							<div className="oauth-google-inner">
								<img
									src="/graphics/login/google-logo.png"
									className="oauth-google-img"
									style={{ width: "18px", margin: 0, height: "18px" }}
								/>
								<div className="oauth-google-text">
									{t("lib:go_security.login.actions.continue_with_google")}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</LoginSecurityLayout>
	);
};

export default SecurityRegisterPage;
