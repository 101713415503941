import React, { FC, useState } from "react";
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import EditAction from "../../../../../../../../components/Common/CommonActions/EditAction/EditAction";
import RemoveAction from "../../../../../../../../components/Common/CommonActions/RemoveAction/RemoveAction";
import { ReactComponent as MoreSvg } from "../../../../../../../../images/svg/more.svg";
import { InvoiceApi, InvoicePaymentApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import LastTransactionsTable from "../../../../components/Transaction/LastTransactionsTable";
import { invoicePreviewIncludes } from "../../pages/Index/Preview";
import CreateOrUpdateInvoiceTransactionModal from "./CreateOrUpdateInvoiceTransactionModal";

interface Props {
	payments: InvoicePaymentApi[];
	invoice: InvoiceApi;
	handleUpdateInvoice: (data: InvoiceApi) => void;
}

const parseInvoiceTransactions = (payments: InvoicePaymentApi[]) => {
	return payments.map((payment) => {
		const { transaction } = payment;
		return {
			id: transaction.id,
			status: transaction.status,
			amount: transaction.amount,
			type: transaction.type,
			payment_method_id: transaction.payment_method_id,
			payment_method: transaction.payment_method,
			payment_method_name: transaction.payment_method_name,
			contexts: transaction.contexts,
			tender_amount: transaction.tender_amount,
			paid: payment.paid,
			paid_at: transaction.paid_at,
			uid: transaction.uid,
			inconsistent_reference_id: transaction.inconsistent_reference_id,
		};
	});
};

const InvoiceTransactionsCard: FC<Props> = ({ payments, invoice, handleUpdateInvoice }) => {
	const { t } = useTranslation();
	const [showTransaction, setShowTransaction] = useState<null | number>(null);
	const [showModal, setShowModal] = useState<InvoicePaymentApi | undefined>(undefined);
	const { addSuccessFlash, addFlash } = useFlash();
	const confirmation = useConfirmation();

	const handleRemoveTransaction = async (index: number) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		const params: Record<string, any> = invoicePreviewIncludes;
		const dto = JSON.parse(JSON.stringify(invoice));
		dto.payments.splice(index, 1);
		let res;
		try {
			switch (invoice.document_type) {
				case "INVOICE":
					res = await api.organization().updateInvoice(dto, params);
					break;
				case "PROFORMA":
					res = await api.organization().updateProformaInvoice(dto, params);
					break;
				case "ADVANCE":
					res = await api.organization().updateAdvance(dto, params);
					break;
				case "CORRECTION":
					res = await api.organization().updateCorrection(dto, params);
					break;
			}
			if (res) {
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				handleUpdateInvoice(res);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
			handleException(err);
		}
	};

	return (
		<Card id="invoice-transactions-card">
			<Card.Body>
				<div className={"action-header"}>
					<h5>{t("modules.invoice.field.payments.title")}</h5>
					<Button variant={"add"} onClick={() => setShowModal({} as InvoicePaymentApi)}>
						+ {t("common.action.add", { ns: "lib" })}
					</Button>
				</div>
				<LastTransactionsTable
					transactions={parseInvoiceTransactions(payments)}
					showTransaction={showTransaction}
					setShowTransaction={setShowTransaction}
					rowActions={(transaction, index) => (
						<Dropdown as={ButtonGroup}>
							<Dropdown.Toggle as={MoreSvg}>{t("common.action.more", { ns: "lib" })}</Dropdown.Toggle>
							{createPortal(
								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => {
											setShowModal({
												...(payments.find(
													(p) => p.transaction.uid === transaction.uid
												) as InvoicePaymentApi),
												id: index?.toString(),
											});
										}}
									>
										<EditAction />
									</Dropdown.Item>
									<Dropdown.Item onClick={() => handleRemoveTransaction(index)}>
										<RemoveAction />
									</Dropdown.Item>
								</Dropdown.Menu>,
								document.getElementById("invoice-transactions-card") ?? (document.body as HTMLElement)
							)}
						</Dropdown>
					)}
				/>
				<Link
					to={`/${api.getOrganizationId()}/sales/transactions?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}invoice|e=${invoice.id}`
					)}`}
					target="_blank"
					className={"btn btn-add card-action"}
				>
					{t("common.action.show_more", { ns: "lib" })}
				</Link>
			</Card.Body>
			{showModal && (
				<CreateOrUpdateInvoiceTransactionModal
					payment={showModal}
					onClose={() => setShowModal(undefined)}
					invoice={invoice}
					onAdd={(data) => {
						handleUpdateInvoice(data);
						setShowModal(undefined);
					}}
				/>
			)}
		</Card>
	);
};
export default InvoiceTransactionsCard;
