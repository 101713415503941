import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationMenuItemGroupsEditPage from "./pages/Edit";
import OrganizationMenuItemGroupsGroupEditingPage from "./pages/GroupEditing";
import OrganizationMenuItemGroupsImportPage from "./pages/Import";
import OrganizationMenuItemGroupsIndexPage from "./pages/Index";
import OrganizationMenuItemGroupsNewPage from "./pages/New";
import OrganizationMenuItemGroupsTranslationPage from "./pages/Translation";

const OrganizationMenuItemGroupsPage: FC<RouteComponentProps> = (props) => {
	return (
		<>
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/new`} exact={true} component={OrganizationMenuItemGroupsNewPage} />
					<Route exact path={`${props.match.url}/import`} component={OrganizationMenuItemGroupsImportPage} />
					<Route
						exact
						path={`${props.match.url}/translations`}
						component={OrganizationMenuItemGroupsTranslationPage}
					/>
					<Route
						exact
						path={`${props.match.url}/group_editing`}
						component={OrganizationMenuItemGroupsGroupEditingPage}
					/>
					<Route path={`${props.match.url}/:itemGroupId`} component={OrganizationMenuItemGroupsEditPage} />
					<Route path={`${props.match.url}/`} component={OrganizationMenuItemGroupsIndexPage} />
				</Switch>
			</div>
		</>
	);
};
export default OrganizationMenuItemGroupsPage;
