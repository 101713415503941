import React from "react";
import { Alert } from "react-bootstrap";
import { ReactComponent as DangerCircleSVG } from "../../../../../../../images/svg/exclamation-circle.svg";

interface Props {
	message: string;
}
const LiveOrderListItemErrorNotification = ({ message }: Props) => {
	return (
		<Alert variant="danger" className="live-orders-error-notification">
			<div className="d-flex">
				<DangerCircleSVG />
				<span>{message}</span>
			</div>
		</Alert>
	);
};

export default LiveOrderListItemErrorNotification;
