import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedItemCost } from "go-core/utils/utils";
import { OrderItemData } from "../../../../../../../services/Api/Public/types";

interface ItemsListProps {
	items?: OrderItemData[];
}

export const ItemsList = ({ items }: ItemsListProps): JSX.Element | null => {
	const { t } = useTranslation();

	if (!items || items.length === 0) {
		return null;
	}

	return (
		<section data-testid="e-order-order-details-items-list">
			<ul>
				{items.map((item) => {
					const productName = `${item.product?.name ?? t("modules.e_order.field.no_name_product.title")} x${
						item.quantity
					}`;
					const key = `${productName}${item.quantity}${item.total_price.amount}${item.unit_price.amount}`;

					return (
						<li key={key} className="item-info">
							<div>
								<span>{productName}</span>
								<span>{getFormattedItemCost(item.total_price.amount, item.total_price.currency)}</span>
							</div>
							{item.comment && <span className="comment">{item.comment}</span>}
						</li>
					);
				})}
			</ul>
		</section>
	);
};
