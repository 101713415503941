let loginRedirectUrl: string | undefined = undefined;
let iframeUrl: string | undefined = undefined;
let accountsUrl: string | undefined = undefined;
let appName: string | undefined = undefined;

const setLoginRedirectUrl = (value: string): void => {
	loginRedirectUrl = value;
};
const setIframeUrl = (value: string): void => {
	iframeUrl = value;
};
const setAccountsUrl = (value: string): void => {
	accountsUrl = value;
};
const setAppName = (value: string): void => {
	appName = value;
};
const getAppName = (): string | undefined => {
	return appName;
};
const getIframeUrl = (): string | undefined => {
	return iframeUrl;
};
const getLoginRedirectUrl = (): string | undefined => {
	return loginRedirectUrl;
};
const getAccountsUrl = (): string | undefined => {
	return accountsUrl;
};
export const ParametersSecurity = {
	getLoginRedirectUrl,
	setLoginRedirectUrl,
	getIframeUrl,
	setIframeUrl,
	getAppName,
	setAppName,
	setAccountsUrl,
	getAccountsUrl,
};
export default ParametersSecurity;
