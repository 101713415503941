import { useEffect } from "react";
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { Loading } from "go-core/components/Loading";
import { ReactComponent as PinSVG } from "../../../../../../../../../../../images/svg/pin.svg";
import { OrganizationInfoApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { CoordinatesApi } from "../../../../../../../../../../../services/Api/types";
import { polandCoords } from "../../../../../../LiveOrders/services/orderDelivery";

interface RecenterProps {
	lat: number;
	lng: number;
	zoom: number;
}

interface Props {
	organizationInfo: OrganizationInfoApi;
	getCoordinates: (getCoordinatesOnBlur: boolean) => void;
	loadingCoords: boolean;
	organizationCoordinates: CoordinatesApi;
}

const RecenterAutomatically = ({ lat, lng, zoom }: RecenterProps) => {
	const map = useMap();

	map.scrollWheelZoom.disable();
	map.invalidateSize(false);
	map.dragging.disable();
	map.touchZoom.disable();
	map.doubleClickZoom.disable();
	map.scrollWheelZoom.disable();
	map.boxZoom.disable();
	map.keyboard.disable();
	if (map.tap) map.tap.disable();

	useEffect(() => {
		map.flyTo([lat, lng], zoom);
	}, [lat, lng, zoom]);
	return null;
};

export const OrganizationAddressMap = ({ organizationCoordinates, getCoordinates, loadingCoords }: Props) => {
	const [polandCoordsLat, polandCoordsLng] = polandCoords;
	const properCoordinatesLoaded =
		organizationCoordinates?.latitude !== polandCoordsLat && organizationCoordinates?.longitude !== polandCoordsLng;

	const icon = L.divIcon({
		className: "",
		html: ReactDOMServer.renderToString(<PinSVG className="icon" />),
	});

	useEffect(() => {
		getCoordinates(false);
	}, []);

	if (loadingCoords) return <Loading />;

	return (
		<div
			onClick={() =>
				window.open(
					`http://maps.google.com/maps?q=${organizationCoordinates.latitude},${organizationCoordinates.longitude}`
				)
			}
		>
			<MapContainer
				zoomControl={false}
				id={"organization-map"}
				scrollWheelZoom={false}
				style={{ height: "178px" }}
				className="w-100"
				center={[organizationCoordinates.latitude, organizationCoordinates?.longitude]}
				zoom={16}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker icon={icon} position={[organizationCoordinates.latitude, organizationCoordinates.longitude]} />
				<RecenterAutomatically
					lat={organizationCoordinates.latitude}
					lng={organizationCoordinates.longitude}
					zoom={properCoordinatesLoaded ? 16 : 5}
				/>
			</MapContainer>
		</div>
	);
};
