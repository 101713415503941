import { validateGreaterThan } from ".";

export const validateGreaterThanIfRequired = (
	value: number | undefined | null,
	minValue: number,
	isRequired: () => boolean
): boolean => {
	if (isRequired()) {
		return validateGreaterThan(value, minValue);
	}

	return true;
};
