import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { SaveAndAddButtons, TranslationType } from "go-core/components/SaveAndAddButtons";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	PaymentMethodApi,
	PaymentMethodCustomFieldTemplateApi,
	VenueRoleApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { mapRoles } from "../../../../../components/ResourcePermissionTable";

interface Props {
	paymentMethod: PaymentMethodApi;
}

const PaymentMethodForm = (props: Props): JSX.Element => {
	const form = useForm<PaymentMethodApi>({
		criteriaMode: "all",
		defaultValues: props.paymentMethod,
	});
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const history = useHistory();
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		formState,
		setError,
		reset,
		setValue,
		watch,
	} = form;
	const [saveLoading, setSaveLoading] = useState(false);
	const [saveAndAddLoading, setSaveAndAddLoading] = useState(false);
	const saveAndAddMode = useRef<boolean>(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const limitAmount = watch("limit.amount");
	const minAmount = watch("min_amount.amount");
	const customFieldTemplates = watch("custom_field_templates");
	const printCopy = watch("print_copy");
	const priceWasted = watch("price_wasted");
	const expandLimitedContent = useRef<boolean>(
		Boolean(
			limitAmount ||
				minAmount ||
				(customFieldTemplates && customFieldTemplates.length !== 0) ||
				printCopy ||
				priceWasted
		)
	);

	const parseFormData = (data: PaymentMethodApi) => {
		const roles = mapRoles(data);
		return {
			...data,
			roles,
			id: props.paymentMethod.id,
		};
	};

	const onSubmit = handleSubmit(async (data: PaymentMethodApi) => {
		saveAndAddMode.current ? setSaveAndAddLoading(true) : setSaveLoading(true);
		try {
			const newData = parseFormData(data);
			if (newData.id) {
				const params = { include: "roles,custom_field_templates" };
				await api.organization().updatePaymentMethod(newData, params);
				if (saveAndAddMode.current) {
					history.push(`/${organization.id}/settings/payment_methods/new`);
				}
				reset({
					...newData,
					custom_field_templates: newData.custom_field_templates.map((customFieldTemplate) => ({
						...customFieldTemplate,
						label: customFieldTemplate.name,
						id: customFieldTemplate.slug,
					})),
				});
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				if (newData.price_wasted === undefined) newData.price_wasted = false;
				if (newData.print_copy === undefined) newData.print_copy = false;
				await api.organization().createPaymentMethod(newData);
				if (saveAndAddMode.current) reset({ print_receipt: true });
				else history.push(`/${organization.id}/settings/payment_methods`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setSaveAndAddLoading(false);
			setSaveLoading(false);
		}
	});

	const onCreateNextPaymentMethod = async () => {
		saveAndAddMode.current = true;
		onSubmit();
	};

	const searchRoles = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getRolesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchCustomFields = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"resource_type|e": "PAYMENT_METHOD",
		};
		return api.organization().getCustomFieldsSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	const getRoleTags = (roles?: VenueRoleApi[]) => {
		return roles?.map((role) => {
			return {
				label: role.role_name,
				id: role.id,
			};
		});
	};

	const getCustomFieldTemplates = (customFieldTemplates?: PaymentMethodCustomFieldTemplateApi[]) => {
		return customFieldTemplates?.map((customFieldTemplate) => {
			return {
				label: customFieldTemplate.name,
				name: customFieldTemplate.name,
				slug: customFieldTemplate.slug,
			};
		});
	};

	const handleChangeCustomFieldTemplates = (customFieldTemplateObjects: CustomFieldTemplateApi[]) => {
		const parsedCustomFieldTemplates = customFieldTemplateObjects.map((customFieldTemplateObject) => ({
			slug: customFieldTemplateObject.slug,
			name: customFieldTemplateObject.name,
		}));
		setValue("custom_field_templates", parsedCustomFieldTemplates);
	};

	const paymentOutflowTypes = [
		{ value: "WZ", label: `${t("enums.payment_methods.payment_outflow_types.WZ")}` },
		{ value: "RW", label: `${t("enums.payment_methods.payment_outflow_types.RW")}` },
	];

	const paymentReceiptTypes = [
		{ value: "CASH", label: `${t("enums.payment_methods.payment_receipt_types.CASH")}` },
		{ value: "CARD", label: `${t("enums.payment_methods.payment_receipt_types.CARD")}` },
		{ value: "CHECK", label: `${t("enums.payment_methods.payment_receipt_types.CHECK")}` },
		{ value: "BOND", label: `${t("enums.payment_methods.payment_receipt_types.BOND")}` },
		{ value: "CREDIT", label: `${t("enums.payment_methods.payment_receipt_types.CREDIT")}` },
		{ value: "OTHER", label: `${t("enums.payment_methods.payment_receipt_types.OTHER")}` },
		{ value: "VOUCHER", label: `${t("enums.payment_methods.payment_receipt_types.VOUCHER")}` },
		{ value: "CLIENT_ACCOUNT", label: `${t("enums.payment_methods.payment_receipt_types.CLIENT_ACCOUNT")}` },
		{ value: "BANK_TRANSFER", label: `${t("enums.payment_methods.payment_receipt_types.BANK_TRANSFER")}` },
	];

	return (
		<FormDirty
			formState={formState}
			key="payment-form"
			loading={saveLoading}
			onSubmit={onSubmit}
			buttonSubmitCustom={
				<SaveAndAddButtons
					saveLoading={saveLoading}
					saveAndAddLoading={saveAndAddLoading}
					onSaveAndAddClick={onCreateNextPaymentMethod}
					translationType={TranslationType.FEMALE}
				/>
			}
		>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<FormInput
					label={t("common.word.name", { ns: "lib" })}
					register={register}
					name="name"
					errors={errors}
				/>
				<FormSelectGroup
					label={t("modules.payment_method.field.outflow_type.title")}
					name="outflow_type"
					errors={errors}
					options={paymentOutflowTypes}
					control={control}
					data-testid="outflow_type"
				/>
				<FormSelectGroup
					label={t("modules.payment_method.field.receipt_type.title")}
					name="receipt_type"
					errors={errors}
					options={paymentReceiptTypes}
					control={control}
					data-testid="receipt_type"
				/>
				<FormCheck
					type="switch"
					register={register}
					label={t("modules.payment_method.field.auto_closed.title")}
					name="auto_closed"
					errors={errors}
					help={t("modules.payment_method.field.auto_closed.help_text.title")}
				/>
				<FormCheck
					type="switch"
					register={register}
					label={t("modules.payment_method.field.print_receipt.title")}
					name="print_receipt"
					errors={errors}
				/>
				<FormCheck
					type="switch"
					register={register}
					label={t("modules.payment_method.field.open_cash_drawer.title")}
					name="open_cash_drawer"
					errors={errors}
				/>
			</fieldset>

			<fieldset className="form-group no-gutters">
				<h5>
					{t("modules.payment_method.field.roles.title")}
					<small className="text-muted">
						<br />
						{t("modules.payment_method.field.roles.help_text.description")}
					</small>
				</h5>
				<FormSelectGroup
					label={t("modules.payment_method.field.roles.label.title")}
					isMulti={true}
					name="roles"
					errors={errors}
					defaultValue={getRoleTags(props.paymentMethod.roles)}
					getOptionLabel={(option) => option.label}
					getOptionValue={(option) => option.id}
					loadOptions={searchRoles}
					control={control}
					data-testid="roles"
				/>
				<LimitComponentVisibility
					id="advanced"
					defaultVisible={expandLimitedContent.current}
					legendText={t("common.word.advanced", { ns: "lib" })}
					unlimitedContent={
						<>
							<FormNumberInput
								label={t("modules.payment_method.field.payment_limit.title")}
								suffix={""}
								control={control}
								name="limit.amount"
								errors={errors}
								help={t("modules.payment_method.field.payment_limit.help_text.title")}
							/>
							<FormNumberInput
								label={t("modules.payment_method.field.payment_min_amount.title")}
								suffix={""}
								control={control}
								name="min_amount.amount"
								errors={errors}
								help={t("modules.payment_method.field.payment_min_amount.help_text.title")}
							/>
							<FormSelectGroup
								label={t("modules.payment_method.field.custom_field_templates.title")}
								name="custom_field_templates"
								errors={errors}
								loadOptions={searchCustomFields}
								control={control}
								isMulti
								onChange={(_, fullObj) => handleChangeCustomFieldTemplates(fullObj)}
								defaultValue={getCustomFieldTemplates(props.paymentMethod.custom_field_templates)}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.slug}
								data-testid="custom_field_templates"
								help={t("modules.payment_method.field.custom_field_templates.help_text.title")}
							/>
							<FormCheck
								type="switch"
								register={register}
								label={t("modules.payment_method.field.print_copy.title")}
								name="print_copy"
								errors={errors}
								help={t("modules.payment_method.field.print_copy.help_text.title")}
							/>
							<FormCheck
								type="switch"
								register={register}
								label={t("modules.payment_method.field.price_wasted.title")}
								name="price_wasted"
								errors={errors}
								help={t("modules.payment_method.field.price_wasted.help_text.title")}
							/>
						</>
					}
				/>
			</fieldset>
		</FormDirty>
	);
};

export default PaymentMethodForm;
