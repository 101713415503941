import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ClientApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
	onSave: (data: ClientApi) => void;
	name: string;
	tax_id_no?: string;
}

const AddClientModal: FC<Props> = ({ onHide, onSave, name, tax_id_no }) => {
	const [clientType, setClientType] = useState<"COMPANY" | "PERSON">("COMPANY");
	const form = useForm<ClientApi>({
		defaultValues: { name, tax_id_no } as ClientApi,
		criteriaMode: "all",
	});
	const [searchingClient, setSearchingClient] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		formState,
		register,
		control,
		formState: { errors },
		setError,
		setValue,
		handleSubmit,
		getValues,
		clearErrors,
	} = form;
	const searchClient = async () => {
		const taxIdNo = getValues("tax_id_no");
		setSearchingClient(true);
		try {
			const res = await api.organization().getClientDataByTaxIdNo(taxIdNo);
			setValue("address_city", res.address_city);
			setValue("address_build_nr", res.address_build_nr);
			setValue("name", res.name);
			setValue("address_country", res.address_country);
			setValue("address_street", res.address_street);
			setValue("address_zip_code", res.address_zip_code);
			setValue("address_flat_nr", res.address_flat_nr);
			setSearchingClient(false);
			clearErrors("tax_id_no");
		} catch (err) {
			setSearchingClient(false);
			handleError.form(err, setError, addFlash);
		}
	};

	const onSubmit = handleSubmit(async (data: ClientApi) => {
		setLoading(true);
		try {
			const res = await api.organization().createClient(data);
			setLoading(false);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			onSave(res);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const types = [
		{ value: "PERSON", label: t("common.word.person") },
		{ value: "COMPANY", label: t("common.word.company") },
	];

	const handleImageChange = () => {};

	const onChangeClientType = (type: "COMPANY" | "PERSON") => {
		setClientType(type);
		clearErrors("tax_id_no");
	};

	return (
		<Modal size={"lg"} show={true} onHide={() => onHide()}>
			<FormDirty formState={formState} noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t("common.word.new")} {t(`common.word.receiver`)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={"row"}>
						<div className={"col-md-4"} style={{ minWidth: "312px" }}>
							<FormSelectGroup
								label={t("common.word.type", { ns: "lib" })}
								name="type"
								errors={errors}
								onChange={(type) => onChangeClientType(type)}
								options={types}
								defaultValue={clientType}
								control={control}
								data-testid="type"
							/>
							{clientType === "COMPANY" && (
								<FormInput
									name={"tax_id_no"}
									label={t("common.word.tax_id_no.title", { ns: "lib" })}
									errors={errors}
									register={register}
								/>
							)}
						</div>
						<div className={"col-md-4"} style={{ minWidth: "312px" }}>
							<FormInput
								label={t("common.address.country", { ns: "lib" })}
								name={"address_country"}
								errors={errors}
								register={register}
							/>
							{clientType === "COMPANY" && (
								<ButtonLoading
									style={{ marginTop: "23px", height: "31px" }}
									onClick={() => searchClient()}
									loading={searchingClient}
									variant={"add btn-block"}
								>
									{t("common.word.tax_id_no.search", { ns: "lib" })}
								</ButtonLoading>
							)}
						</div>
						<div className={"col-md-2"} style={{ minWidth: "150px" }}>
							<ImageColorForm
								form={form}
								handleSave={handleImageChange}
								prefix="avatar"
								mode="IMAGE"
								placeholder="/graphics/user-avatar.svg"
								data={{} as ClientApi}
								resourceLink={undefined}
								resourceData={undefined}
							/>
						</div>
					</div>

					<div className={"row"}>
						<div className={"col-md-12"}>
							<FormInput
								label={t("common.word.name", { ns: "lib" })}
								register={register}
								name="name"
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								label={t("common.address.street", { ns: "lib" })}
								register={register}
								name="address_street"
								errors={errors}
							/>
						</div>
						<div className="col-md-3">
							<FormInput
								label={t("common.address.build_nr", { ns: "lib" })}
								register={register}
								name="address_build_nr"
								errors={errors}
							/>
						</div>
						<div className="col-md-3">
							<FormInput
								label={t("common.address.flat_nr", { ns: "lib" })}
								register={register}
								name="address_flat_nr"
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								label={t("common.address.zip_code", { ns: "lib" })}
								register={register}
								name="address_zip_code"
								errors={errors}
							/>
						</div>

						<div className="col-md-6">
							<FormInput
								label={t("common.address.city", { ns: "lib" })}
								register={register}
								name="address_city"
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormNumberInput
								label={t("common.contact.phone.label")}
								control={control}
								name="contact_phone_number"
								suffix=""
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								label={t("common.word.email", { ns: "lib" })}
								register={register}
								name="contact_email"
								errors={errors}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default AddClientModal;
