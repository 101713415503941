import React from "react";
import { Modal } from "react-bootstrap";
import Linkify from "react-linkify";

interface Props {
	show: boolean;
	onHide: () => void;
	description: string;
}

const CustomFieldDescriptionModal = ({ show, onHide, description }: Props) => {
	const componentDecorator = (href: string, text: string, key: number) => {
		return (
			<a href={href} key={key} target="_blank" rel="noreferrer">
				{text}
			</a>
		);
	};
	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton />
			<Modal.Body>
				<Linkify componentDecorator={componentDecorator}>{description}</Linkify>
			</Modal.Body>
		</Modal>
	);
};

export default CustomFieldDescriptionModal;
