import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { delay } from "go-core";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { restSecurityApi } from "../../services/Api/api";
import OrganizationIdService from "../../services/organizationIdService";
import { OrganizationActionTypes, OrganizationState } from "../../services/organizations/types";
import { UserActionTypes } from "../../services/users/types";

interface Props {
	organization: OrganizationState;
}

const OrganizationInstallView = (props: Props): JSX.Element => {
	const history = useHistory();
	const dispatch = useDispatch();
	const initialRender = useRef(true);
	const mountedRef = useRef(true);
	const [error, setError] = useState(false);
	const { t } = useTranslation();
	const organization = props.organization;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	if (
		organization.status === "ENABLED" ||
		organization.status === "REMOVED" ||
		organization.status === "DISABLED" ||
		organization.status === "FROZEN"
	) {
		history.push(`/${organization.id}`);
	}

	const checkInstallationStatus = useCallback(async () => {
		try {
			while (mountedRef.current) {
				let res;
				if (initialRender.current) {
					res = await restSecurityApi.get(`security/organizations/${organization.id}/install`);
					initialRender.current = false;
				} else {
					await delay(2000);
					res = await restSecurityApi.get(`security/organizations/${organization.id}/me`);
				}
				if (res.data.data && res.data.data.status === "ENABLED") {
					dispatch({
						type: OrganizationActionTypes.SET,
						payload: res.data.data,
					});
					dispatch({
						type: UserActionTypes.UPDATE_ORGANIZATION,
						payload: res.data.data,
					});
					mountedRef.current = false;
					OrganizationIdService.update(res.data.data.id);
				}
			}
		} catch (err) {
			mountedRef.current = false;
			setError(true);
		}
	}, []);

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_security.components.install.header.title"));
		checkInstallationStatus();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const startInstall = () => {
		mountedRef.current = true;
		checkInstallationStatus();
	};

	if (error) {
		return (
			<div className="container">
				<div className="alert alert-danger">
					<p>${t("lib:go_security.components.install.error_with_install")}</p>
					<Button variant="primary" onClick={() => startInstall()}>
						{t("lib:go_security.components.install.action.retry")}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<LoadingContainer
			title={t("lib:go_security.components.install.progress.title")}
			description={t("lib:go_security.components.install.progress.description")}
		/>
	);
};
export default OrganizationInstallView;
