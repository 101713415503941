import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import AllApps from "../../components/Apps/AllApps";
import InstalledApps from "../../components/Apps/InstalledApps";
import OrganizationAppsEditPage from "../Edit";

const OrganizationAppsAllPage: FC<RouteComponentProps> = (props) => {
	const [apps, setApps] = useState<undefined | ApplicationApi[]>(undefined);
	const [appTemplates, setAppTemplates] = useState<undefined | ApplicationTemplateApi[]>(undefined);
	const { addFlash } = useFlash();
	const history = useHistory();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("modules.app.field.all.title"));
		Promise.all([api.organization().getApps({ include: "settings" }), api.organization().getAppTemplates()])
			.then((res) => {
				setApps(res[0]);
				setAppTemplates(res[1]);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	const handleUninstall = (app: ApplicationApi) => {
		history.push(props?.match.url);
		setApps(apps?.filter((f) => f.id !== app.id));
	};

	const handleUpdate = (app: ApplicationApi) => {
		const currApps = apps;
		if (currApps) {
			const currentAppIndex = currApps.findIndex((f) => f.id === app.id);
			currApps[currentAppIndex] = app;
			setApps([...currApps]);
		}
	};

	const handleUpdateNewInstalledApp = (app: ApplicationApi) => {
		const currentApp = apps?.find((f) => f.id === app.id);
		if (!currentApp) setApps([...(apps || []), { ...app }]);
		history.push(`${props?.match.url}/${app.id}`);
	};

	const renderRoute = useMemo(() => {
		return (
			<Route
				path={`${props?.match.url}/:app_id`}
				component={() => (
					<OrganizationAppsEditPage
						handleUpdate={handleUpdate}
						handleUninstall={handleUninstall}
						routeProps={props}
						installedApps={apps}
						appConfigs={appTemplates}
					/>
				)}
			/>
		);
	}, [props.match.url, appTemplates]);

	if (!apps || !appTemplates) {
		return <LoadingContainer />;
	}

	return (
		<>
			{apps.length > 0 && (
				<InstalledApps
					handleUpdate={handleUpdate}
					handleUninstall={handleUninstall}
					routeProps={props}
					installedApps={apps}
					appTemplates={appTemplates}
				/>
			)}
			<AllApps appTemplates={appTemplates} handleUpdateNewInstalledApp={handleUpdateNewInstalledApp} />
			{renderRoute}
		</>
	);
};
export default OrganizationAppsAllPage;
