import React, { FC, useContext, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { MoneyApi } from "go-core/api/types";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	DeliveryEstimationApi,
	LiveOrderApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { OrdersContext } from "../../../../../../services/context";
import { liveOrderParamsInclude } from "../../../../../../services/orderSynchronizer";
import { LiveOrderDeliveryOptionType, woltDriveTimeOptions } from "../../../../../../services/types";
import { getDefaultDeliveryOptions, isWoltDriveEnabled } from "../../../../../../services/utils";
import LiveOrderDeliveryOptions from "../LiveOrderDeliveryOptions/LiveOrderDeliveryOptions";
import LiveOrderDeliveryTimeOptions from "../LiveOrderDeliveryTimeOptions/LiveOrderDeliveryTimeOptions";
import LiveOrdersAssignDeliveryEmployeeModalOptions from "./LiveOrdersAssignDeliveryEmployeeModalOptions";

interface Props {
	isShown: boolean;
	onHide: () => void;
	order: LiveOrderApi;
}

const LiveOrdersAssignDeliveryEmployeeModal: FC<Props> = ({ isShown, onHide, order }) => {
	const { t } = useTranslation();
	const size = useWindowSize();
	const organization = useSelector(selectOrganization);
	const ordersContext = useContext(OrdersContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const [deliveryEstimatingLoading, setDeliveryEstimatingLoading] = useState(false);
	const [estimatedDeliveryData, setEstimatedDeliveryData] = useState<DeliveryEstimationApi | undefined>();
	const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<LiveOrderDeliveryOptionType | undefined>(
		getDefaultDeliveryOptions(organization, order)
	);
	const [selectedTimeOption, setSelectedTimeOption] = useState<number | string | undefined>();
	const assignedDeliveryEmployee =
		order.delivery?.delivery_employee?.reference_id ||
		order.delivery?.delivery_employee?.id ||
		order?.delivery?.delivery_employee?.name === "WOLT_DRIVE";
	const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(
		order?.delivery?.delivery_employee?.reference_id
	);
	const [loading, setLoading] = useState(false);
	const [unassignLoading, setUnassignLoading] = useState(false);
	const [firstEstimatedDeliveryTime, setFirstEstimatedDeliveryTime] = useState<number | undefined>();
	const [firstEstimatedPrice, setFirstEstimatedPrice] = useState<MoneyApi | undefined>();
	const orderEstimatedPreparationInMinutes = useMemo(() => {
		return order.delivery?.delivery_pickup_in_minutes !== undefined ? order.delivery.delivery_pickup_in_minutes : 0;
	}, [order?.estimated_preparation_at]);
	const currency = organization.currency || "PLN";

	const getParsedEmployeeDataForm = (unassign?: boolean) => {
		let employeeId;
		if (selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE) {
			employeeId = unassign
				? undefined
				: estimatedDeliveryData?.id || order?.delivery?.delivery_employee?.reference_id;
		}
		if (selectedDeliveryOption === LiveOrderDeliveryOptionType.OWN_DRIVER) {
			employeeId = unassign ? undefined : selectedEmployeeId;
		}

		return {
			delivery_employee_id: employeeId,
			delivery_cost: { amount: estimatedDeliveryData?.price?.amount || 0, currency },
			delivery_pickup_in_minutes: estimatedDeliveryData?.delivery_pickup_in_minutes,
		};
	};

	const handleChangeEmployee = async (unassign?: boolean) => {
		if (unassign) {
			setUnassignLoading(true);
		} else {
			setLoading(true);
		}

		try {
			const data = getParsedEmployeeDataForm(unassign);
			const params: Record<string, any> = { include: liveOrderParamsInclude };
			const res = await api.organization().assignEmployeeDeliveryToOrder(order.id, data, params);
			let orderToUpdate = ordersContext.orders.find((o) => o.id === order.id);
			if (orderToUpdate) {
				orderToUpdate = {
					...res,
				};
				ordersContext.updateOrder(orderToUpdate as LiveOrderApi);
			}
			addSuccessFlash(t("lib:common.flash.saved"));
			onHide();
		} catch (err: any) {
			const wrongTerminalError = err.errors?.find(
				(error: Record<string, any>) => error.code === "wrong_order_terminal"
			);
			if (wrongTerminalError) {
				const flashMessage = t("constraints.wrong_terminal_order", {
					terminal_name: wrongTerminalError.terminal_name,
				});
				addFlash({
					type: "danger",
					msg: flashMessage,
					messageAlreadyCreated: true,
				});
			} else handleError.alert(err, addFlash);
		}
		if (unassign) {
			setUnassignLoading(false);
		} else {
			setLoading(false);
		}
	};

	const isSubmitButtonDisabled = () => {
		if (!selectedDeliveryOption) return true;
		if (selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE) {
			return !selectedTimeOption;
		}
		if (selectedDeliveryOption === LiveOrderDeliveryOptionType.OWN_DRIVER) {
			return !selectedEmployeeId || selectedEmployeeId.includes("WOLT_DRIVE");
		}
	};

	const handleUnselectDeliveryOption = (option: LiveOrderDeliveryOptionType | undefined) => {
		if (option === LiveOrderDeliveryOptionType.OWN_DRIVER) {
			setSelectedTimeOption(undefined);
			const orderDeliveryEmployeeId =
				order?.delivery?.delivery_employee?.reference_id || order?.delivery?.delivery_employee?.id;
			if (orderDeliveryEmployeeId) {
				setSelectedEmployeeId(orderDeliveryEmployeeId.toString());
			} else setSelectedEmployeeId(undefined);
		} else if (option === LiveOrderDeliveryOptionType.WOLT_DRIVE) {
			if (!selectedTimeOption && order?.delivery?.delivery_employee?.name === "WOLT_DRIVE") {
				if (orderEstimatedPreparationInMinutes) {
					setSelectedTimeOption(orderEstimatedPreparationInMinutes);
				} else {
					setSelectedTimeOption(estimatedDeliveryData?.delivery_pickup_in_minutes);
				}
			} else if (!assignedDeliveryEmployee) {
				setSelectedTimeOption(estimatedDeliveryData?.delivery_pickup_in_minutes);
			} else if (orderEstimatedPreparationInMinutes === selectedTimeOption) {
				setSelectedTimeOption(orderEstimatedPreparationInMinutes);
			} else if (orderEstimatedPreparationInMinutes <= 0) {
				setSelectedTimeOption(firstEstimatedDeliveryTime);
			} else if (
				[...woltDriveTimeOptions, firstEstimatedDeliveryTime].includes(orderEstimatedPreparationInMinutes)
			) {
				setSelectedTimeOption(orderEstimatedPreparationInMinutes);
			} else {
				setSelectedTimeOption(undefined);
			}
		} else {
			setSelectedTimeOption(undefined);
			setSelectedEmployeeId(undefined);
		}
	};

	return (
		<Modal
			onHide={onHide}
			show={isShown}
			className="assign-delivery-employee-modal"
			size={size.isMobile ? "sm" : "lg"}
		>
			<Modal.Header closeButton>
				<div className="d-flex justify-content-between align-items-center w-100">
					<Modal.Title>
						{`${t("modules.live_order.header.assign_driver_to_order.title")} #${order.number}`}
					</Modal.Title>
					{assignedDeliveryEmployee && (
						<ButtonLoading
							loading={unassignLoading}
							variant="danger"
							className="me-2"
							onClick={() => handleChangeEmployee(true)}
						>
							{t("modules.live_order.action.unassign_driver.title")}
						</ButtonLoading>
					)}
				</div>
			</Modal.Header>
			<Modal.Body>
				{isWoltDriveEnabled(organization) && (
					<>
						<h5 className="mb-2">{t("modules.live_order.field.choose_delivery.title")}</h5>
						<LiveOrderDeliveryOptions
							setSelectedDeliveryOption={setSelectedDeliveryOption}
							selectedDeliveryOption={selectedDeliveryOption}
							estimatingLoading={deliveryEstimatingLoading}
							estimatedDeliveryData={estimatedDeliveryData}
							firstEstimatedDeliveryTime={firstEstimatedDeliveryTime}
							setEstimatingLoading={setDeliveryEstimatingLoading}
							orderId={order.id}
							setEstimatedDeliveryData={setEstimatedDeliveryData}
							selectedTimeOption={selectedTimeOption}
							handleToggle={handleUnselectDeliveryOption}
							setFirstEstimatedDeliveryTime={setFirstEstimatedDeliveryTime}
							setFirstEstimatedPrice={setFirstEstimatedPrice}
							firstEstimatedPrice={firstEstimatedPrice}
							setSelectedTimeOption={setSelectedTimeOption}
							order={order}
							orderEstimatedPreparationInMinutes={orderEstimatedPreparationInMinutes}
						/>
					</>
				)}
				{selectedDeliveryOption === LiveOrderDeliveryOptionType.OWN_DRIVER && (
					<div className={`d-flex align-items-center  w-100`}>
						<h5 className="mb-2">{t("modules.live_order.field.choose_delivery_employee.title")}</h5>
					</div>
				)}

				{selectedDeliveryOption === LiveOrderDeliveryOptionType.OWN_DRIVER && (
					<LiveOrdersAssignDeliveryEmployeeModalOptions
						setSelectedEmployeeId={setSelectedEmployeeId}
						selectedEmployeeId={selectedEmployeeId}
					/>
				)}

				{selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE && (
					<LiveOrderDeliveryTimeOptions
						selectedTimeOption={selectedTimeOption}
						selectedDeliveryOption={selectedDeliveryOption}
						deliveryEstimatingLoading={deliveryEstimatingLoading}
						setSelectedTimeOption={setSelectedTimeOption}
						optionsFor={[LiveOrderDeliveryOptionType.WOLT_DRIVE]}
						firstEstimatedDeliveryTime={firstEstimatedDeliveryTime}
						order={order}
						orderEstimatedPreparationInMinutes={orderEstimatedPreparationInMinutes}
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading
					loading={loading}
					onClick={() => handleChangeEmployee()}
					type="submit"
					disabled={isSubmitButtonDisabled()}
				>
					{t("lib:common.action.save")}
				</ButtonLoading>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default LiveOrdersAssignDeliveryEmployeeModal;
