import { createContext, useContext, useState } from "react";

interface OrganizationContextApi {
	organizations: Record<string, any>[];
	setOrganizations: (organizations: Record<string, any>[]) => void;
}

const OrganizationsContext = createContext<OrganizationContextApi>({
	organizations: [] as Record<string, any>[],
	setOrganizations: (organizations: Record<string, any>[]) => {},
});

export const OrganizationsContextProvier = ({ children }: { children: JSX.Element }) => {
	const [organizations, setOrganizations] = useState<Record<string, any>[]>([]);

	return (
		<OrganizationsContext.Provider value={{ organizations, setOrganizations }}>
			{children}
		</OrganizationsContext.Provider>
	);
};

export const useOrganizations = () => {
	const context = useContext(OrganizationsContext);
	if (!context) throw new Error("Organization context was used outside provider");
	return context;
};
