import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormatResourceStatusLabel from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import { getComponentStatusClassName } from "go-component/Common/FormatStatus";

interface Props {
	status: string;
}

const getStatusTranslationKey = (status: string): string => {
	return `enums.common.status.${status}`;
};

export const getStatusClassName = (
	status: string
): "primary" | "success" | "danger" | "warning" | "secondary" | "blue" | undefined => {
	let labelClass = getComponentStatusClassName(status);
	if (labelClass) return labelClass;

	switch (status) {
		case "OPENED":
			labelClass = "primary";
			break;
		case "PAID":
		case "CLOSED":
		case "ACTIVE":
		case "HIRED":
		case "CONFIRMED":
			labelClass = "success";
			break;
		case "VOIDED":
		case "WAITING":
			labelClass = "secondary";
			break;
		case "INACTIVE_APP":
		case "WAITING_PAYMENT":
		case "EXTERNAL":
		case "FIRED":
			labelClass = "warning";
			break;
		case "INACTIVE":
		case "DELETED":
		case "REMOVED":
		case "FAILURE":
		case "CANCELED":
		case "UNPAID":
			labelClass = "danger";
			break;
		default:
			labelClass = "primary";
			break;
	}

	return labelClass;
};

const FormatResourceStatus: FC<Props> = (props: Props): JSX.Element => {
	const status = props.status;
	const { t } = useTranslation();
	const labelClass = getStatusClassName(status);

	const translation = t(getStatusTranslationKey(status), { ns: ["translation", "lib"] });
	return <FormatResourceStatusLabel status={status} className={labelClass} translation={translation} />;
};

export default FormatResourceStatus;

// t("enums.common.status.CONFIRMED");
// t("enums.common.status.SUPPORTED");
// t("enums.common.status.WAITING_PAYMENT");
// t("enums.common.status.SKIPPED");
// t("enums.common.status.TO_CANCEL");
// t("enums.common.status.FINISHED");
// t("enums.common.status.FAILURE");
// t("enums.common.status.ACTIVE");
// t("enums.common.status.STARTED");
