import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { InvoiceDetailsApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import InvoiceDetailsForm from "./components/InvoiceDetailsForm";

const OrganizationSettingsInfoInvoiceDetailsPage = (): JSX.Element => {
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.invoice_settings.title"));
		onFetchInvoiceDetails();
	}, []);

	const onFetchInvoiceDetails = async () => {
		try {
			const res = await api.organization().getInvoiceDetails();
			setInvoiceDetails(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!invoiceDetails) {
		return <LoadingContainer />;
	}

	return (
		<>
			<InvoiceDetailsForm details={invoiceDetails} />
		</>
	);
};

export default OrganizationSettingsInfoInvoiceDetailsPage;
