import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ReceiptApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ReceiptForm from "./components/ReceiptForm";

const OrganizationSettingsInfoReceiptPage = (): JSX.Element => {
	const [receipt, setReceipt] = useState<ReceiptApi | undefined>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.receipt_template.title"));
		onFetchReceiptSettings();
	}, []);

	const onFetchReceiptSettings = async () => {
		try {
			const res = await api.organization().getReceiptSettings();
			setReceipt(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!receipt) {
		return <LoadingContainer />;
	}

	return <ReceiptForm receipt={receipt} />;
};

export default OrganizationSettingsInfoReceiptPage;
