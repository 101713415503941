import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { UseFieldArrayRemove, UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SortEnd, SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import useFlash from "go-alert/AlertMessage";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { ReactComponent as DropdownSVG } from "../../../../../../../../../../images/svg/dropdown.svg";
import { ReactComponent as AddSVG } from "../../../../../../../../../../images/svg/menu/plus.svg";
import { ReactComponent as MoreSVG } from "../../../../../../../../../../images/svg/more.svg";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { MenuApi, MenuItemApi, MenuPageApi } from "../../../../../../../../../../services/Api/Organization/types";
import { ImageLinkApi } from "../../../../../../../../../../services/Api/types";
import MenuPageItemModal from "../MenuPageItemModal";
import MenuListPageImageModal from "./MenuListPageImageModal";
import MenuListPageItem from "./MenuListPageItem";
import MenuListSortableHandler from "./MenuListSortableHandler";

interface Props {
	page: MenuPageApi;
	remove: UseFieldArrayRemove;
	setPageToChange: (page: MenuPageApi) => void;
	pageIndex: number;
	form: UseFormReturn<MenuApi>;
	setCategoryName: (name: string) => void;
}

interface ListProps {
	items: MenuItemApi[];
	removeItem: (index: number) => void;
}

const MenuListPageItemList = SortableContainer(({ items, removeItem }: ListProps) => (
	<tr className="menu-list-page-item">
		{items.map((item, index) => {
			return (
				<MenuListPageItem
					index={index}
					handleRemove={() => removeItem(index)}
					key={item.entity.id || item.id}
					pageItem={item}
				/>
			);
		})}
	</tr>
));

const MenuListPage = SortableElement(({ page, setPageToChange, remove, pageIndex, form, setCategoryName }: Props) => {
	const { t } = useTranslation();
	const [showItems, setShowItems] = useState(!!page.appended);
	const [showItemModal, setShowItemModal] = useState(false);
	const { control, watch } = form;
	const {
		append,
		replace,
		remove: removeItem,
	} = useFieldArray({
		control,
		name: `pages.${pageIndex}.items`,
		keyName: "key",
	});
	const watchedItems = watch(`pages.${pageIndex}.items`) || [];
	const [loading, setLoading] = useState<number | undefined>(undefined);
	const { addFlash } = useFlash();
	const [showItemImageModal, setShowItemImageModal] = useState(false);

	const handleRemove = () => {
		remove(pageIndex);
	};

	const handleToggleShowItems = () => {
		setShowItems((prevState) => !prevState);
	};

	const handleShowMenuPageImageModal = () => {
		setShowItemImageModal(true);
	};

	const handleAddItem = async (item: MenuItemApi) => {
		if (item.context_type === "CATEGORY") {
			setLoading(Number(item.context_id));
			const filter = btoa(unescape(encodeURIComponent(`category|e=${item.context_id}`)));
			const categoryProducts = await apiOrganization.getItemGroups({
				size: 200,
				f: filter,
				include: "image",
			});

			if (categoryProducts.length > 0) {
				categoryProducts.forEach((product) => {
					append({
						internalId: `page-${pageIndex}-item-${new Date().getTime()}`,
						position: watchedItems.length,
						entity: {
							image_link: product.image_link as ImageLinkApi,
							id: product.id,
							name: product.name,
							color: product.color,
							status: product.status,
							label: product.name,
						},
						id: product.id,
						context_id: product.id,
						context_type: "ITEM_GROUP",
					});
				});
			} else {
				addFlash({
					type: "danger",
					msg: "category_has_no_products",
				});
			}
			setLoading(undefined);
		} else {
			append({
				internalId: `page-${pageIndex}-item-${new Date().getTime()}`,
				position: watchedItems.length,
				entity: item.entity,
				id: item.context_id,
				context_id: item.context_id,
				context_type: item.context_type,
			});
		}
		setShowItemModal(false);
		setShowItems(true);
	};

	const handleUpdateItemsPositions = ({ oldIndex, newIndex }: SortEnd) => {
		let newItems = [...watchedItems];
		newItems = arrayMove(newItems, oldIndex, newIndex);
		newItems.forEach((item, index) => {
			item.position = index;
		});
		replace(newItems);
	};

	const handleOpenTranslationsModal = () => {
		setPageToChange(page);
		setCategoryName(page.name);
	};

	return (
		<tbody className="menu-list-table-page-list-body">
			{showItemModal && (
				<MenuPageItemModal
					currentMenuItems={page.items}
					handleClose={() => setShowItemModal(false)}
					handleSave={handleAddItem}
					contextTypes={["ITEM", "ITEM_GROUP", "CATEGORY"]}
					hasMultipleChoice
					itemButtonLoading={loading}
				/>
			)}
			<tr
				className={`menu-list-page ${showItems && watchedItems.length > 0 ? "" : "menu-list-page-with-border"}`}
			>
				<td className="menu-list-table-td action">
					<MenuListSortableHandler />
				</td>
				<td className="w-75 menu-list-table-td">
					<div className="d-flex align-items-center category-container" onClick={handleToggleShowItems}>
						<DropdownSVG className={`dropdown-svg ${showItems ? "dropdown-svg--active" : ""}`} />
						<ListImageName data={page} imageLink={page.image_link} />
					</div>
				</td>
				<td className="action menu-list-table-actions w-25 menu-list-table-td">
					<div className="actions-container d-flex align-items-center gap-2 justify-content-end">
						<Button variant="add" onClick={() => setShowItemModal(true)}>
							<AddSVG />
						</Button>
						<Dropdown className="icon">
							<Dropdown.Toggle as={MoreSVG} />
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleRemove()}>
									{t("lib:common.action.remove")}
								</Dropdown.Item>
								<Dropdown.Item onClick={handleOpenTranslationsModal}>
									{t("lib:common.action.edit")}
								</Dropdown.Item>
								<Dropdown.Item onClick={handleShowMenuPageImageModal}>
									{t("modules.menu.action.set_image.title")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</td>
			</tr>
			{showItems && watchedItems.length > 0 && (
				<MenuListPageItemList
					hideSortableGhost
					items={watchedItems}
					removeItem={removeItem}
					useDragHandle
					onSortEnd={handleUpdateItemsPositions}
				/>
			)}
			{showItemImageModal && (
				<MenuListPageImageModal
					show={showItemImageModal}
					onHide={() => setShowItemImageModal(false)}
					pageItem={page}
					form={form}
					pageItemIndex={pageIndex}
				/>
			)}
		</tbody>
	);
});

export default MenuListPage;
