import React from "react";
import { GoSuiteApp } from "go-app/services/types";
import { redirectToApplication } from "go-core/utils/utils";
import { ConfigApi } from "./types";

export class ConfigService {
	config: ConfigApi;
	private appsOldVersionTimestamp: Record<string, number>;

	constructor(config: ConfigApi) {
		this.config = config;
		this.appsOldVersionTimestamp = {
			GOSTOCK: new Date(2024, 4, 20, 0, 0, 0, 0).getTime(),
		};
	}

	get(): ConfigApi {
		return this.config;
	}

	applications(): GoSuiteApp[] {
		return this.config.apps.map((x) => {
			return {
				id: x.id,
				name: x.name,
				url: x.url,
				old: x.old,
			} as GoSuiteApp;
		});
	}

	getAppsOldVersionTimestamps() {
		return this.appsOldVersionTimestamp;
	}

	getGoAccountsUrl(): string {
		const goAccountsApp = this.config.apps.filter((x) => x.id.toUpperCase() === "GOACCOUNTS")[0];
		const goAccountsAppUrl = goAccountsApp?.url;
		return goAccountsAppUrl;
	}

	getRedirectUrl(appId: string, companyId: string): string | undefined {
		const app = this.applications().filter((x) => x.id.toUpperCase() === appId.toUpperCase())[0];
		if (!app) return undefined;
		return redirectToApplication(app.url, companyId);
	}
}

export const ConfigContext = React.createContext<ConfigService>({} as ConfigService);
