import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import SecurityAccessDenied from "go-security/components/AccessDenied";
import SecurityOrganizationAcceptTerms from "go-security/components/OrganizationAcceptTerms/OrganizationAcceptTerms";
import OrganizationBlockedView from "go-security/components/OrganizationBlocked/OrganizationBlockedView";
import { apiSecurity } from "go-security/services/Api/api";
import { useAuth } from "go-security/services/auth";
import { isLicenseBlocked } from "go-security/services/license";
import { OrganizationState } from "go-security/services/organizations/types";
import { UserState } from "go-security/services/users/types";
import { selectOrganization } from "../../../services/organizations/selectors";
import { selectUser } from "../../../services/users/selectors";
import { isExternalAccess, isInstalled } from "../../../services/utils";
import OrganizationInstallView from "../../OrganizationInstall/OrganizationInstallView";

interface LocationStateType {
	registerCompany?: boolean;
}

interface Props {
	organizationId: string;
	locationState: any;
	goaccounts_url: string;
	baseUrl: string;
}

const OrganizationIndexSecurityComponent: FC<Props> = (props) => {
	const user = useSelector(selectUser);
	const currentOrganization = useSelector(selectOrganization);
	const organizationId = props.organizationId;
	const userOrganizationsIds = user?.organizations?.map((org) => org.id) || [];
	let organizationIdNumber = Number(organizationId);
	const { addFlash } = useFlash();
	const auth = useAuth(props.baseUrl);
	const [loading, setLoading] = useState(true);
	const [acccessDenied, setAccessDenied] = useState(false);
	const history = useHistory();

	const state = props.locationState as LocationStateType;

	const isAcceptTermsRequired = (organization: OrganizationState, userToBeChecked: UserState) => {
		if (organization.accept_privacy && organization.accept_rules && organization.accept_terms) return false;
		if (!isExternalAccess(userToBeChecked, currentOrganization)) return true;
		return false;
	};
	const checkOrganizationStatus = useCallback(async (orgId: number | string) => {
		setAccessDenied(false);
		setLoading(true);
		if (state?.registerCompany) {
			await auth.checkLogin();
		}
		try {
			if (isNaN(organizationIdNumber)) {
				const organizationIdObj = await apiSecurity.organization().getId(orgId);
				organizationIdNumber = Number(organizationIdObj.data.data);
				history.push(`/${organizationIdNumber}`);
				return;
			}
			const loginResponse = await auth.loginOrganization(orgId);
			if (loginResponse === undefined) {
				setAccessDenied(true);
				return;
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			handleError.alert(err, addFlash);
		}
	}, []);

	useEffect(() => {
		checkOrganizationStatus(organizationId);
	}, [organizationId, checkOrganizationStatus]);

	if (acccessDenied) {
		return (
			<div className={"content"}>
				<SecurityAccessDenied />
			</div>
		);
	}
	if (loading) {
		return <LoadingContainer />;
	}

	if (currentOrganization && currentOrganization.id !== organizationIdNumber) {
		if (!isNaN(organizationIdNumber) && userOrganizationsIds.includes(organizationIdNumber)) {
			checkOrganizationStatus(organizationIdNumber);
			return <Redirect to={`/${organizationIdNumber}`} />;
		}

		return <Redirect to="/" />;
	}

	if (isAcceptTermsRequired(currentOrganization, user)) {
		return (
			<>
				<SecurityOrganizationAcceptTerms baseUrl={props.baseUrl} />
			</>
		);
	}
	if (!isInstalled(currentOrganization)) {
		return <OrganizationInstallView organization={currentOrganization} />;
	}

	if (
		!isExternalAccess(user, currentOrganization) &&
		isLicenseBlocked(
			currentOrganization.license_expires_at,
			currentOrganization.license_type,
			currentOrganization.timezone
		)
	) {
		return (
			<div className={"content"}>
				<OrganizationBlockedView organization={currentOrganization} goaccounts_url={props.goaccounts_url} />
			</div>
		);
	}

	return <>{props.children}</>;
};
export default OrganizationIndexSecurityComponent;
