import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Page404 from "go-app/components/Page404/Page404";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import EmptyData from "go-core/components/EmptyData";
import { FormatAddressString } from "go-core/components/Formatters/FormatAddress";
import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useIsOnline } from "go-core/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../services/Api/Organization/apiOrganization";
import {
	DeliveryEmployeeApi,
	DeliveryZoneApi,
	LiveOrderApi,
	OrderPreparationStatusApi,
	OrderPreparationStatusName,
	OrganizationAddressApi,
} from "../../../../../../services/Api/Organization/types";
import LiveOrdersBadInternetConnectionBar from "./components/LiveOrdersBadInternetConnectionBar";
import LiveOrdersNavigation from "./components/LiveOrdersNavigation";
import { LiveOrdersRefreshPageBar } from "./components/LiveOrdersRefreshPageBar";
import OrganizationLiveOrdersListPage from "./pages/List";
import OrganizationLiveOrdersMapPage from "./pages/Map";
import OrganizationLiveOrdersTablePage from "./pages/Table";
import { OrdersContext } from "./services/context";
import { DeliveryEmployeeSynchronizer } from "./services/deliveryEmployeeSynchronizer";
import { getDefaultOrdersFilters } from "./services/orderFilters";
import { isOrderActive } from "./services/orderStatus";
import { OrderSynchronizer } from "./services/orderSynchronizer";
import { LiveOrderFilterOption, LiveOrdersFilters } from "./services/types";

const OrganizationLiveOrdersPage: FC<RouteComponentProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [ordersLoading, setOrdersLoading] = useState<boolean>(true);
	const [synchronizeFailed, setSynchronizeFailed] = useState<string | undefined>(undefined);
	const [orders, setOrders] = useState<LiveOrderApi[]>([]);
	const ordersRef = useRef<LiveOrderApi[]>([]);
	const { t } = useTranslation();
	const [filters, setFilters] = useState<LiveOrdersFilters>(getDefaultOrdersFilters(t));
	const [iconsScale, setIconsScale] = useState<number>(1);
	const [organizationAddress, setOrganizationAddress] = useState<OrganizationAddressApi>(
		{} as OrganizationAddressApi
	);
	const [deliveryZones, setDeliveryZones] = useState<DeliveryZoneApi[]>([]);
	const { addFlash } = useFlash();
	const orderSynchronizer = useRef(new OrderSynchronizer());
	const employeeSynchronizer = useRef(new DeliveryEmployeeSynchronizer());
	const interval = useRef<number>(5000);
	const employeesInterval = useRef<number>(5000);
	const timeoutRef = useRef<any>(null);
	const employeeTimeoutRef = useRef<any>(null);
	const [didMount, setDidMount] = useState(false);
	const employeesRef = useRef<DeliveryEmployeeApi[]>([]);
	const [employees, setEmployees] = useState<DeliveryEmployeeApi[]>([]);
	const [employeesLoading, setEmployeesLoading] = useState<boolean>(true);
	const [shouldDisplayRefreshPageBar, setShouldDisplayRefreshPageBar] = useState<boolean>(false);
	const isOnline = useIsOnline();
	const syncOrdersInProgress = useRef(false);
	const ignorePreviousSyncOrdersRequest = useRef(false);
	const syncEmployeesInProgress = useRef(false);
	const ignorePreviousSyncEmployeesRequest = useRef(false);
	const [ordersFetchingTime, setOrdersFetchingTime] = useState(0);
	const ordersFetchingTimeTimeoutRef = useRef<number | undefined>(undefined);
	const [employeesFetchingTime, setEmployeesFetchingTime] = useState(0);
	const employeesFetchingTimeTimeoutRef = useRef<number | undefined>(undefined);
	const organization = useSelector(selectOrganization);
	const possiblePreparationStatuses = (organization.more?.supported_preparation_statuses ||
		[]) as OrderPreparationStatusName[];

	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
			employeeTimeoutRef.current = null;
			clearTimeout(employeeTimeoutRef.current);
			window.clearTimeout(ordersFetchingTimeTimeoutRef.current);
			ordersFetchingTimeTimeoutRef.current = undefined;
			window.clearTimeout(employeesFetchingTimeTimeoutRef.current);
			employeesFetchingTimeTimeoutRef.current = undefined;
		};
	}, []);

	useEffect(() => {
		if (!isOnline) {
			setShouldDisplayRefreshPageBar(true);
		}
	}, [isOnline]);

	useEffect(() => {
		setDidMount(true);
		onFetch();
	}, []);

	useEffect(() => {
		if (ordersLoading) {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			timeoutRef.current = 1;
			synchronize(true);
		}
	}, [ordersLoading]);

	useEffect(() => {
		if (employeesLoading) {
			if (employeeTimeoutRef.current) {
				clearTimeout(employeeTimeoutRef.current);
			}
			employeeTimeoutRef.current = 1;
			synchronizeEmployees(true);
		}
	}, [employeesLoading]);

	useEffect(() => {
		if (didMount) {
			if (syncOrdersInProgress.current) {
				ignorePreviousSyncOrdersRequest.current = true;
			}
			syncOrdersInProgress.current = false;
			setOrdersLoading(true);
			orderSynchronizer.current.updateFilters(filters);
		}
	}, [filters.time]);

	useEffect(() => {
		if (didMount) {
			if (syncEmployeesInProgress.current) {
				ignorePreviousSyncEmployeesRequest.current = true;
			}
			syncEmployeesInProgress.current = false;
			setEmployeesLoading(true);
			employeeSynchronizer.current.updateFilters(filters);
		}
	}, [filters.employeesLastActivity]);

	useEffect(() => {
		handleOrdersFetchingTimeTimeout();
	}, [syncOrdersInProgress.current]);

	useEffect(() => {
		handleEmployeesFetchingTimeTimeout();
	}, [syncEmployeesInProgress.current]);

	const handleOrdersFetchingTimeTimeout = () => {
		if (ordersFetchingTimeTimeoutRef.current) {
			clearTimeout(ordersFetchingTimeTimeoutRef.current);
		}
		ordersFetchingTimeTimeoutRef.current = window.setTimeout(() => {
			if (ordersFetchingTimeTimeoutRef.current) {
				if (syncOrdersInProgress.current) {
					setOrdersFetchingTime((prevState) => prevState + 1);
				} else {
					setOrdersFetchingTime(0);
				}
				handleOrdersFetchingTimeTimeout();
			}
		}, 1000);
	};

	const handleEmployeesFetchingTimeTimeout = () => {
		if (employeesFetchingTimeTimeoutRef.current) {
			clearTimeout(employeesFetchingTimeTimeoutRef.current);
		}
		employeesFetchingTimeTimeoutRef.current = window.setTimeout(() => {
			if (employeesFetchingTimeTimeoutRef.current) {
				if (syncEmployeesInProgress.current) {
					setEmployeesFetchingTime((prevState) => prevState + 1);
				} else {
					setEmployeesFetchingTime(0);
				}
				handleEmployeesFetchingTimeTimeout();
			}
		}, 1000);
	};

	const getSortedOrdersByStatus = (orders: LiveOrderApi[]) => {
		const copiedOrders = [...orders];
		const priority = {
			EXTERNAL: 1,
			NEW: 2,
			OTHER: 3,
		};

		return copiedOrders.sort((a, b) => {
			const statusA =
				a.status === "EXTERNAL"
					? priority[a.status]
					: priority[
							a.preparation_status?.status === OrderPreparationStatusName.NEW
								? OrderPreparationStatusName.NEW
								: "OTHER"
					  ];
			const statusB =
				b.status === "EXTERNAL"
					? priority[b.status]
					: priority[
							b.preparation_status?.status === OrderPreparationStatusName.NEW
								? OrderPreparationStatusName.NEW
								: "OTHER"
					  ];
			return statusA - statusB;
		});
	};

	const synchronize = async (firstSync: boolean) => {
		if (syncOrdersInProgress.current) return;
		try {
			syncOrdersInProgress.current = true;
			let newOrders = await orderSynchronizer.current.sync();
			const firstPossiblePreparationStatus = possiblePreparationStatuses[0];
			newOrders = newOrders.map((order) => {
				if (!order.preparation_status?.status) {
					if (!order.preparation_status) {
						order.preparation_status = {
							status: firstPossiblePreparationStatus,
						} as OrderPreparationStatusApi;
					} else {
						order.preparation_status.status = firstPossiblePreparationStatus;
					}
				}
				return order;
			});

			if (ignorePreviousSyncOrdersRequest.current) return;

			if (!timeoutRef.current) return;

			if (JSON.stringify(newOrders) !== JSON.stringify(ordersRef.current)) {
				setOrders([...getSortedOrdersByStatus(newOrders)]);
				ordersRef.current = [...getSortedOrdersByStatus(newOrders)];
			}
			if (ordersLoading) setOrdersLoading(false);
			if (synchronizeFailed) setSynchronizeFailed(undefined);
			setShouldDisplayRefreshPageBar(false);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			startTimeout();
		} catch (err) {
			setSynchronizeFailed("ERROR");
			handleSynchronizationError(err);
		} finally {
			syncOrdersInProgress.current = false;
			ignorePreviousSyncOrdersRequest.current = false;
		}
		if (firstSync) {
			startTimeout();
		}
	};

	const startTimeout = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = window.setTimeout(() => {
			if (timeoutRef.current) {
				synchronize(false);
				startTimeout();
			}
		}, interval.current);
	};

	const synchronizeEmployees = async (firstSync: boolean) => {
		if (syncEmployeesInProgress.current) return;
		try {
			syncEmployeesInProgress.current = true;
			const newEmployees = await employeeSynchronizer.current.sync(filters);

			if (ignorePreviousSyncEmployeesRequest.current) return;

			if (!employeeTimeoutRef.current) return;

			if (JSON.stringify(newEmployees) !== JSON.stringify(employeesRef.current)) {
				setEmployees([...newEmployees]);
				employeesRef.current = [...newEmployees];
			}
			if (employeesLoading) setEmployeesLoading(false);
			if (synchronizeFailed) setSynchronizeFailed(undefined);
			setShouldDisplayRefreshPageBar(false);

			if (employeeTimeoutRef.current) {
				clearTimeout(employeeTimeoutRef.current);
			}
			startEmployeeTimeout();
		} catch (err) {
			setSynchronizeFailed("ERROR");
			handleSynchronizationError(err);
		} finally {
			syncEmployeesInProgress.current = false;
			ignorePreviousSyncEmployeesRequest.current = false;
		}
		if (firstSync) {
			startEmployeeTimeout();
		}
	};

	const handleSynchronizationError = (err: unknown) => {
		const errorCodesForRefreshingPage = [504, 502, 0];
		const exceptionStatus = (err as Exception).status;

		if (typeof exceptionStatus === "number" && errorCodesForRefreshingPage.includes(exceptionStatus)) {
			return setShouldDisplayRefreshPageBar(true);
		}

		handleError.alert(err, addFlash);
		setShouldDisplayRefreshPageBar(false);
	};

	const startEmployeeTimeout = () => {
		if (employeeTimeoutRef.current) {
			clearTimeout(employeeTimeoutRef.current);
		}
		employeeTimeoutRef.current = window.setTimeout(() => {
			if (employeeTimeoutRef.current) {
				synchronizeEmployees(false);
				startEmployeeTimeout();
			}
		}, employeesInterval.current);
	};

	const onFetch = () => {
		Promise.all([apiOrganization.getOrganizationAddress(), apiOrganization.getOrdersDeliveryZones()])
			.then((res) => {
				setOrganizationAddress(res[0]);
				setDeliveryZones(res[1]);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
		setLoading(false);
	};

	const getSortedOrdersByExecutionDate = (orders: LiveOrderApi[]) => {
		const newOrders = props.location.pathname.includes("map") ? [...getDeliveryOrders(orders)] : [...orders];
		return newOrders.sort((a, b) => {
			const aExecutionAt = a.execution_at ? a.execution_at : a.created_at;
			const bExecutionAt = b.execution_at ? b.execution_at : b.created_at;
			return formatStringToDate(bExecutionAt).getTime() - formatStringToDate(aExecutionAt).getTime();
		});
	};

	const getFilteredOrdersByStatus = (orders: LiveOrderApi[], status: LiveOrderFilterOption) =>
		getSortedOrdersByExecutionDate(orders).filter((order) => {
			if (status.id === "ALL" || !status.id) return order;
			if (status.id === "ACTIVE") if (isOrderActive(order)) return order;
			if (status.id === "EXTERNAL") return order.status === status.id;
			return order?.preparation_status?.status === status.id;
		});

	const getFilteredOrdersByDeliveryEmployee = (orders: LiveOrderApi[]) => {
		const { deliveryEmployee } = filters;

		return orders.filter((order) => {
			if (deliveryEmployee?.id === "exist") {
				return !!order.delivery?.delivery_employee?.id;
			}
			if (deliveryEmployee?.id === "not_exist") {
				return !order.delivery?.delivery_employee?.id;
			}
			if (deliveryEmployee?.id) {
				return order.delivery?.delivery_employee?.id?.toString() === deliveryEmployee.id.toString();
			}
			return order;
		});
	};

	const getSearchedOrders = (orders: LiveOrderApi[], skipFilterKeys?: string[]): LiveOrderApi[] => {
		let finalOrders = orders.filter((order) => order?.status !== "REMOVED" && order?.status !== "VOIDED");
		if (!skipFilterKeys?.includes("type")) {
			if (!props.location.pathname.includes("map")) {
				if (filters.type === null) {
					finalOrders = finalOrders.filter((order) => order.type);
				} else finalOrders = finalOrders.filter((order) => order.type === filters.type?.id);
			}
		}

		if (!skipFilterKeys?.includes("search")) {
			const search = filters.search.toLowerCase();
			finalOrders = finalOrders.filter((order) => {
				const address = FormatAddressString(order?.delivery?.address);

				if (
					order?.number?.toLowerCase()?.includes(search) ||
					address?.toLowerCase()?.includes(search) ||
					order?.tax_id_no?.toLowerCase()?.includes(search) ||
					order?.source?.number?.includes(search) ||
					order?.contact?.phone_number?.includes(search)
				)
					return order;

				return undefined;
			});
		}

		if (!skipFilterKeys?.includes("status")) {
			finalOrders = getFilteredOrdersByStatus(finalOrders, filters.status);
		}

		if (!skipFilterKeys?.includes("deliveryEmployee")) {
			finalOrders = getFilteredOrdersByDeliveryEmployee(finalOrders);
		}

		return finalOrders;
	};

	const updateOrder = (order: LiveOrderApi) => {
		const newOrders = [...orders];
		const orderIndex = newOrders.findIndex((o) => o.id === order.id);
		if (orderIndex > -1) {
			newOrders[orderIndex] = { ...order };
		}
		setOrders(newOrders);
	};

	const getDeliveryOrders = (orders: LiveOrderApi[]) => orders.filter((order) => order.type === "DELIVERY");

	const showBadInternetConnectionBar = isOnline && (ordersFetchingTime > 5 || employeesFetchingTime > 5);

	if (loading || ordersLoading || employeesLoading) {
		if (synchronizeFailed) {
			return (
				<div className="w-100 h-100 d-flex align-items-center justify-content-center">
					<EmptyData
						title={t("modules.live_order.constraints.download_orders_error")}
						actions={[
							{
								name: t("common.action.refresh", { ns: "lib" }),
								variant: "primary",
								click: () => {
									synchronize(false);
									synchronizeEmployees(false);
								},
							},
						]}
					/>
				</div>
			);
		}
		return (
			<>
				{showBadInternetConnectionBar && <LiveOrdersBadInternetConnectionBar />}
				<LoadingContainer />
			</>
		);
	}

	return (
		<OrdersContext.Provider
			value={{
				organizationAddress,
				filteredOrdersWithoutFilterKeys: (ids?: string[]) =>
					getSearchedOrders(
						props.location.pathname.includes("map") ? getDeliveryOrders(orders) : orders,
						ids
					),
				orders: getSearchedOrders(orders),
				updateOrder,
				filters,
				setFilters,
				iconsScale,
				setIconsScale,
				deliveryZones,
				deliveryEmployees: employees,
				isOnline: !shouldDisplayRefreshPageBar,
			}}
		>
			<div className="page-container live-orders-page-container">
				<LiveOrdersRefreshPageBar />
				{showBadInternetConnectionBar && <LiveOrdersBadInternetConnectionBar />}
				<LiveOrdersNavigation />
				<Switch>
					<Route path={`${props.match.url}/map`} component={OrganizationLiveOrdersMapPage} />
					<Route path={`${props.match.url}/list`} component={OrganizationLiveOrdersListPage} />
					<Route path={`${props.match.url}/table`} component={OrganizationLiveOrdersTablePage} />
					<Redirect from={`${props.match.url}/`} exact to={`${props.match.url}/map`} />
					<Route component={Page404} />
				</Switch>
			</div>
		</OrdersContext.Provider>
	);
};

export default OrganizationLiveOrdersPage;
