import React, { useCallback, useEffect, useRef, useState } from "react";
import { Range } from "react-date-range";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronRightSVG } from "go-core/images/svg/arrow-right.svg";
import { DateRangePickerInlineFull } from "go-form/components/DateRangePicker";
import { ReactComponent as FiltersSVG } from "go-list/core/images/filters.svg";
import { parseRangeToDefaultString, parseRangeToString, parseStringToRange } from "go-list/utils/daterangeutils";
import { ReactComponent as DropdownSVG } from "../../../../images/svg/dropdown.svg";
import { DashboardFilterActions } from "../../services/filters/reducer";
import useDashboardContext from "../../services/useDashboardContext";

interface Props {
	setShowMobileFilters: (value: boolean) => void;
	filterItemTypes: string[];
	setSelectedDateFilter: (value: string | null) => void;
	selectedDateFilter: string | null;
}

const DashboardMobileFiltersButton = ({
	setShowMobileFilters,
	filterItemTypes,
	setSelectedDateFilter,
	selectedDateFilter,
}: Props) => {
	const { t } = useTranslation();
	const { state, dispatch } = useDashboardContext();
	const [showDatePicker, setShowDatePicker] = useState(false);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [date, setDate] = useState<Range>(
		parseStringToRange(state.filtersConfig.selectedFilter.dateRange.replace(",", " - "))
	);
	const [predefinedDate, setPredefinedDate] = useState(
		filterItemTypes.includes(state.filtersConfig.selectedFilter.dateRange)
			? state.filtersConfig.selectedFilter.dateRange
			: undefined
	);
	const [refId, setRefId] = useState<HTMLDivElement | null>(null);
	const [shouldForceFetchData, setShouldForceFetchData] = useState(false);

	useEffect(() => {
		if (wrapperRef.current) {
			setRefId(wrapperRef.current);
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, date, showDatePicker]);

	useEffect(() => {
		if (refId) {
			wrapperRef.current = refId;
		}
	}, [date, showDatePicker]);

	useEffect(() => {
		if (selectedDateFilter && filterItemTypes.includes(selectedDateFilter)) {
			if (selectedDateFilter !== predefinedDate) {
				setPredefinedDate(selectedDateFilter);
				setDate(parseStringToRange(selectedDateFilter));
			}
		} else if (
			selectedDateFilter &&
			JSON.stringify(parseStringToRange(selectedDateFilter.replace(",", " - "))) !== JSON.stringify(date)
		) {
			setPredefinedDate(undefined);
			setDate(parseStringToRange(selectedDateFilter.replace(",", " - ")));
		}
	}, [selectedDateFilter]);

	useEffect(() => {
		if (shouldForceFetchData) {
			setShouldForceFetchData(false);
			dispatch({
				type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
				data: true,
			});
		}
	}, [shouldForceFetchData]);

	const getDateTitle = () => {
		let ranges = parseRangeToString(date).split(" - ");
		ranges = ranges.map((range) => {
			return range.slice(0, range.indexOf(","));
		});

		return ranges.join(" - ");
	};

	const checkIfDateIsSameAsDateFilter = () => {
		return (
			JSON.stringify(parseStringToRange(state.filtersConfig.selectedFilter.dateRange.replace(",", " - "))) !==
			JSON.stringify(date)
		);
	};

	const handleChangeDateFilter = () => {
		dispatch({
			type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
			data: false,
		});
		const dateToUpdate = predefinedDate ?? parseRangeToDefaultString(date, true).replace(" - ", ",");
		dispatch({
			type: DashboardFilterActions.CHANGE_DATE_FILTER,
			data: dateToUpdate,
		});
		dispatch({
			type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
			data: true,
		});
		setSelectedDateFilter(dateToUpdate);
	};

	const toggleShowDatePicker = () => {
		if (showDatePicker) {
			if (checkIfDateIsSameAsDateFilter()) {
				handleChangeDateFilter();
			}
		}
		setShowDatePicker((prevState) => !prevState);
	};
	const handleClickOutside = (event: MouseEvent) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
			if (checkIfDateIsSameAsDateFilter()) {
				handleChangeDateFilter();
			} else {
				dispatch({
					type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
					data: false,
				});
			}
			wrapperRef.current = null;
			setShowDatePicker(false);
		}
	};

	const handleChangeRange = (range: Range, predefinedRange?: string, shouldCloseCalendar?: boolean) => {
		let newDate;
		let selectedDateFilterAssigned = false;

		if (predefinedRange && filterItemTypes.includes(predefinedRange)) {
			setPredefinedDate(predefinedRange);
			setSelectedDateFilter(predefinedRange);
			selectedDateFilterAssigned = true;
		} else {
			setPredefinedDate(undefined);
		}

		if (!range?.startDate && !range.endDate) {
			newDate = null;
		} else {
			newDate = parseRangeToDefaultString(range, true).replace(",", " - ");
		}
		setDate(parseStringToRange((newDate || "").replace(",", " - ")));
		if (!selectedDateFilterAssigned) setSelectedDateFilter(newDate);
		if (shouldCloseCalendar) setShowDatePicker(false);
	};

	const handleApplyRange = () => {
		if (checkIfDateIsSameAsDateFilter()) {
			handleChangeDateFilter();
		}
		setShowDatePicker(false);
	};

	const handleChangePredefinedRange = (type: "next" | "prev") => {
		dispatch({
			type: DashboardFilterActions.CHANGE_FORCE_APPLY_DATE_FILTER,
			data: false,
		});
		const indexOfCurrentRange = predefinedDate ? filterItemTypes.indexOf(predefinedDate) : -1;
		const newRange = filterItemTypes[type === "next" ? indexOfCurrentRange + 1 : indexOfCurrentRange - 1];
		if (newRange) {
			setPredefinedDate(newRange);
			setSelectedDateFilter(newRange);
			setDate(parseStringToRange(newRange));
			dispatch({
				type: DashboardFilterActions.CHANGE_DATE_FILTER,
				data: newRange,
			});
			setShouldForceFetchData(true);
		}
	};

	const isChangePredefinedRangeArrowDisabled = useCallback(
		(type: "next" | "prev") => {
			const indexOfCurrentRange = predefinedDate ? filterItemTypes.indexOf(predefinedDate) : -1;

			if (indexOfCurrentRange === -1) return true;
			if (type === "next") return !filterItemTypes[indexOfCurrentRange + 1];
			if (type === "prev") return !filterItemTypes[indexOfCurrentRange - 1];
			return false;
		},
		[predefinedDate, filterItemTypes]
	);

	return (
		<div ref={wrapperRef} className="mobile-dashboard-filters-button-wrapper">
			<div className="mobile-filters-button dashboard-filters-button mb-3">
				<div className="d-flex align-items-center left-container text-truncate">
					<div className="d-flex flex-column text-truncate" onClick={toggleShowDatePicker}>
						{predefinedDate && (
							<strong className="text-truncate">
								{t(`lib:go_form.date_range_picker.ranges.${predefinedDate}`)}
							</strong>
						)}
						<span className="text-truncate">{getDateTitle()}</span>
					</div>
					<DropdownSVG onClick={toggleShowDatePicker} />
				</div>
				<div className="right-container d-flex align-items-center justify-content-end">
					<div className="arrows-container d-flex align-items-center">
						<ChevronRightSVG
							className={isChangePredefinedRangeArrowDisabled("prev") ? "disabled" : ""}
							onClick={() => handleChangePredefinedRange("prev")}
						/>
						<ChevronRightSVG
							className={isChangePredefinedRangeArrowDisabled("next") ? "disabled" : ""}
							onClick={() => handleChangePredefinedRange("next")}
						/>
					</div>
					<div className="separator" />
					<FiltersSVG className="filters-icon" onClick={() => setShowMobileFilters(true)} />
				</div>
			</div>
			{showDatePicker && (
				<DateRangePickerInlineFull
					onChangeRange={handleChangeRange}
					onApply={handleApplyRange}
					timeStart="00:00"
					timeEnd="23:59"
					ranges={[date]}
					minDate={new Date("2015")}
					maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
				/>
			)}
		</div>
	);
};

export default DashboardMobileFiltersButton;
