import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { getCreatingDeliveryZones, getNotificationTemplate } from "../../../../../../../utils/envutil";

const SettingsNavigationMenu = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const settingsPermission = useSelector(hasPermission("SETTINGS"));
	const venueRoomManagePermission = useSelector(hasPermission("VENUE_ROOM_MANAGE"));
	const managePermission = useSelector(hasPermission("MANAGE"));
	const prefix = `/${organization.id}/settings`;
	const location = useLocation();
	const history = useHistory();
	const isGoOrderAppInstalled = (organization?.more?.installed_app_providers || []).includes("GOORDER");

	useEffect(() => {
		shouldRedirectToFirstVisibleItem();
	}, []);

	const shouldRedirectToFirstVisibleItem = () => {
		const currentItem = items.find(
			(item) =>
				item.route === location.pathname ||
				location.pathname.includes(item.route) ||
				item.route.includes(location.pathname)
		);
		if (currentItem && currentItem.hide) redirectToFirstVisibleItem();
	};

	const redirectToFirstVisibleItem = () => {
		const firstVisibleNavigationItem = visibleNavigationItems[0];
		if (firstVisibleNavigationItem) {
			history.replace(firstVisibleNavigationItem.route);
		}
	};

	const items = [
		{
			title: t("lib:common.word.informations"),
			route: `${prefix}/info`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.tax.header.title"),
			route: `${prefix}/taxes`,
			hide: !settingsPermission,
		},
		{
			title: t("common.word.payment_methods"),
			route: `${prefix}/payment_methods`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.currency.header.title"),
			route: `${prefix}/currencies`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.room.header.title"),
			route: `${prefix}/rooms`,
			hide: !venueRoomManagePermission,
		},
		{
			title: t("modules.printout_template.header.title"),
			route: `${prefix}/printout_templates`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.notification_template.header.title"),
			route: `${prefix}/notification_templates`,
			hide: !settingsPermission || !getNotificationTemplate(),
		},
		{
			title: t("modules.user.header.title"),
			route: `${prefix}/users`,
			hide: !managePermission,
		},
		{
			title: t("modules.permission.header.title"),
			route: `${prefix}/permissions`,
			hide: !managePermission,
		},
		{
			title: t("modules.default_comment.header.title"),
			route: `${prefix}/default_comments`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.custom_field.header.title"),
			route: `${prefix}/custom_fields`,
			hide: !settingsPermission,
		},
		{
			title: t("go_component.availability.header.title", { ns: "lib" }),
			route: `${prefix}/availabilities`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.terminal.header.title"),
			route: `${prefix}/terminals`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.device.header.title"),
			route: `${prefix}/devices`,
			hide: !settingsPermission,
		},
		{
			title: t("modules.segment.header.title"),
			route: `${prefix}/segments`,
		},
		{
			title: t("modules.tag.header.title"),
			route: `${prefix}/tags`,
		},
		{
			title: t("modules.delivery_zone.header.title"),
			route: `${prefix}/delivery_zones`,
			hide: !isGoOrderAppInstalled || !getCreatingDeliveryZones(),
		},
		{
			title: t("lib:common.word.advanced"),
			route: `${prefix}/advanced`,
			hide: !settingsPermission,
		},
		{
			title: t("lib:go_component.remove_organization.header.title"),
			route: `${prefix}/remove_organization`,
			hide: !managePermission,
		},
	];

	const visibleNavigationItems = items.filter((item) => !item.hide);

	return <SubNavigationItems title={t("components.navigation.settings")} items={items} />;
};

export default SettingsNavigationMenu;
