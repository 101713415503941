import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import EmptyList from "go-core/components/EmptyList";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ItemPriceHistoryApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import DepartmentReportModal from "../../components/DepartmentReportModal";
import { RebuildReportModal } from "../../components/RebuildReportModal";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "ITEM_PRICE_HISTORIES";
const resourceType = "ITEM_PRICE_HISTORIES";

const List: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<ItemPriceHistoryApi[]>([]);
	const [showDepartmentReportModal, setShowDepartmentReportModal] = useState(false);
	const [showRebuildReportModal, setShowRebuildReportModal] = useState(false);
	const mobileActions: MobileActionProps[] = [
		{
			title: t("modules.report.action.rebuild.title"),
			action: () => setShowRebuildReportModal(true),
		},
		{
			title: t("modules.report.field.department_report.title"),
			action: () => setShowDepartmentReportModal(true),
		},
	];
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();
	let config = {
		fields: [
			{
				id: "item",
				name: t("common.word.item_group"),
				render: (item: ItemPriceHistoryApi) => (
					<ListImageName data={{ ...item, name: item.item.name }} imageLink={item.item?.image_link} />
				),
				renderExport: (item: ItemPriceHistoryApi) => item.item.name,
				disableSorting: true,
			},
			{
				id: "price",
				name: t("modules.item_price_history.field.price.title"),
				render: (item: ItemPriceHistoryApi) => FormatMoney(item.price),
				styleOverride: RenderColumnUtils.getMoneyStyles(),
				type: "number",
			},
			{
				id: "lowest_price_since30_days",
				name: t("modules.item_price_history.field.lowest_price_since_30_days.title"),
				render: (item: ItemPriceHistoryApi) => FormatMoney(item.lowest_price_since30_days),
				styleOverride: RenderColumnUtils.getMoneyStyles(),
				exportType: "number",
			},
		],
		filters: [
			{
				id: "item_group",
				name: t("common.word.item_group"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemGroupsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "price_at",
				name: t("lib:common.word.date"),
				type: "date",
				typeOptions: ["gt", "lt", "bt"],
			},
			{
				id: "price_difference",
				name: t("modules.item_price_history.field.price_difference.title"),
				type: "number",
			},
		],
		selectedColumns: ["item", "price", "lowest_price_since30_days"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.item_price_history.header.title"),
			filename: t("modules.item_price_history.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: async (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			const res = await api.organization().getProductPricesReport(params, { cancelToken: sourceToken?.token });
			return res.data.data;
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			<DepartmentReportModal
				show={showDepartmentReportModal}
				onHide={() => setShowDepartmentReportModal(false)}
			/>
			<RebuildReportModal show={showRebuildReportModal} onHide={() => setShowRebuildReportModal(false)} />
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.item_price_history.header.title")}
						description={t("modules.item_price_history.field.empty_list_description.title")}
					/>
				}
				onFetch={(fetchItems: ItemPriceHistoryApi[]) => setItems(fetchItems)}
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationReportsItemPriceHistoryPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<List resource={resource} />
		</Suspense>
	);
};

export default OrganizationReportsItemPriceHistoryPage;
