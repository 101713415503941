import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import FormatResourceStatus from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { InvoiceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import ConfirmedInvoiceWarning from "../../../components/ConfirmedInvoiceWarning";
import ExportInvoicePdfModal from "../../../components/ExportInvoicePdfModal/ExportInvoicePdfModal";
import ExportInvoiceConfirmationModal from "../../../components/ExportInvoicesConfirmationModal/ExportInvoiceConfirmationModal";
import InvoicePdfPreviewModal from "../../../components/InvoicePdfPreviewModal/InvoicePdfPreviewModal";
import InvoicePreview from "../../../components/Preview/InvoicePreview";
import SendInvoiceByEmailModal from "../../../components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import { exportEInvoice, generateInvoicePreviewPdfLink, printInvoicePdf } from "../../../services/exportInvoice";

interface MatchParams {
	invoice_id: string;
}

export const invoicePreviewIncludes = {
	include:
		"custom_fields,items,items.tax,payments,payments.transaction,orders,order_items.tax,order_items,summary,summary.taxes,recipient,recipient.details,receiver,issuer,linked_invoices,linked_invoices.items,previous_advances",
};

const OrganizationInvoicesIndexPreviewPage = (): JSX.Element => {
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const [showLogs, setShowLogs] = useState<boolean>(false);
	const { invoice_id } = useParams<MatchParams>();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const linkedInvoice = invoice?.linked_invoices.find((f) => f.document_type === "INVOICE");
	const [showExportPdfInvoiceModal, setShowExportPdfInvoiceModal] = useState<boolean>(false);
	const hasVenueInvoiceEditPermission = useSelector(
		hasPermission(["VENUE_INVOICE_EDIT", "VENUE_OWN_INVOICE_MANAGE"])
	);
	const [invoicePdfLink, setInvoicePdfLink] = useState<string>("");
	const isAdmin = useSelector(selectIsAdmin);
	const [showConfirmationExportInvoice, setShowConfirmationExportInvoice] = useState<boolean>(false);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const getTabTitle = (invoice: InvoiceApi) => {
		let title = `${invoice.document_type === "CORRECTION" ? linkedInvoice?.number : invoice.number} | `;
		if (invoice.document_type === "INVOICE") title += t("enums.invoices.document_type.INVOICE");
		if (invoice.document_type === "ADVANCE") title += t("enums.invoices.document_type.ADVANCE");
		if (invoice.document_type === "CORRECTION")
			title = `${t("modules.invoice.field.correction.title")} ${invoice.number} ${t("common.word.to", {
				ns: "lib",
			})} ${t("common.word.invoice")}`;

		return title;
	};

	useEffect(() => {
		const params: Record<string, any> = invoicePreviewIncludes;
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		Promise.all([
			api.organization().getInvoice(Number(invoice_id), params),
			api.organization().getCustomFields(cfParams),
		])
			.then(([invoiceResponse, cfResponse]) => {
				setInvoice(invoiceResponse);
				setCustomFieldsConfig(cfResponse);
				handleChangeTabTitle(getTabTitle(invoiceResponse));
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, [invoice_id]);

	if (!invoice || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const removeInvoice = async (invoice: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoice.id);
			history.push(`/${organization.id}/invoices`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const exportInvoiceToEInvoice = () => {
		if (invoice.status === "OPEN") {
			setShowConfirmationExportInvoice(true);
		} else {
			exportEInvoice(invoice, t);
		}
	};

	const getLinkToEditPage = () => {
		if (invoice.document_type === "CORRECTION")
			return `/${organization.id}/invoices/${linkedInvoice?.id}/corrections/edit`;
		return `/${organization.id}/invoices/${invoice.id}/edit`;
	};
	const renderTitle = () => (
		<>
			{invoice.document_type === "INVOICE" && t("enums.invoices.document_type.INVOICE")}
			{invoice.document_type === "ADVANCE" && t("enums.invoices.document_type.ADVANCE")}
			{invoice.document_type === "CORRECTION" &&
				`${t("modules.invoice.field.correction.title")} ${invoice.number} ${t("common.word.to", {
					ns: "lib",
				})} ${t("common.word.invoice")}`}
			&nbsp;{invoice.document_type === "CORRECTION" ? linkedInvoice?.number : invoice.number}
			<FormatResourceStatus status={invoice.status === "OPEN" ? "NEW" : invoice.status} />
		</>
	);

	const renderSubtitle = () => (
		<ul>
			<li>{FormatDate(invoice.dates?.issued_at, undefined, false, defaultFormatDate)}</li>
			<li>{FormatMoney(invoice?.summary?.price_sum_gross)}</li>
		</ul>
	);

	const buttons: ButtonProps[] = [];
	if (!invoice.linked_invoices.find((inv) => inv.document_type === "CORRECTION")) {
		if (!(!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin)) {
			buttons.push({
				title: t("common.action.edit", { ns: "lib" }),
				variant: "light",
				path: getLinkToEditPage(),
			});
		}
	}
	buttons.push({
		title: t("common.action.export_pdf", { ns: "lib" }),
		action: () => setShowExportPdfInvoiceModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.print", { ns: "lib" }),
		action: () => printInvoicePdf(invoice),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.preview", { ns: "lib" }),
		action: async () => {
			const link = await generateInvoicePreviewPdfLink(invoice);
			setInvoicePdfLink(link);
		},
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.field.e_invoice.title"),
		action: exportInvoiceToEInvoice,
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.remove", { ns: "lib" }),
		action: () => removeInvoice(invoice),
		dropdown: true,
	});
	if (
		!invoice.linked_invoices?.find((f) => f.document_type === "CORRECTION") &&
		invoice.document_type !== "CORRECTION"
	)
		buttons.push({
			title: t("modules.invoice.action.make_correction.title"),
			path: `/${organization.id}/invoices/${invoice.id}/corrections/new`,
			dropdown: true,
		});
	buttons.push({
		title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
		action: () => setShowSendInvoiceByEmailModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("lib:common.word.logs"),
		action: () => setShowLogs(true),
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: getLinkToEditPage(),
			hide: !(
				!invoice.linked_invoices.find((inv) => inv.document_type === "CORRECTION") &&
				!(!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin)
			),
		},
		{
			title: t("common.action.export_pdf", { ns: "lib" }),
			action: () => setShowExportPdfInvoiceModal(true),
		},
		{
			title: t("common.action.print", { ns: "lib" }),
			action: () => printInvoicePdf(invoice),
		},
		{
			title: t("common.action.preview", { ns: "lib" }),
			action: async () => {
				const link = await generateInvoicePreviewPdfLink(invoice);
				setInvoicePdfLink(link);
			},
		},
		{
			title: t("modules.invoice.field.e_invoice.title"),
			action: exportInvoiceToEInvoice,
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeInvoice(invoice),
		},
		{
			title: t("modules.invoice.action.make_correction.title"),
			path: `/${organization.id}/invoices/${invoice.id}/corrections/new`,
			hide: !(
				!invoice.linked_invoices?.find((f) => f.document_type === "CORRECTION") &&
				invoice.document_type !== "CORRECTION"
			),
		},
		{
			title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
			action: () => setShowSendInvoiceByEmailModal(true),
		},
		{
			title: t("lib:common.word.logs"),
			action: () => setShowLogs(true),
		},
	];

	const avatar = {
		color: "#1A86E0",
		date: invoice.dates?.issued_at,
	};

	const changeInvoiceStatusToConfirmed = () => {
		setInvoice({
			...invoice,
			status: "CONFIRMED",
		});
	};

	return (
		<div className={"content-preview"}>
			<SendInvoiceByEmailModal
				onSuccess={changeInvoiceStatusToConfirmed}
				isShown={showSendInvoiceByEmailModal}
				onHide={() => setShowSendInvoiceByEmailModal(false)}
				invoice={invoice}
			/>
			<MobileActions actions={mobileActions} />
			<Header
				title={renderTitle()}
				subtitle={renderSubtitle()}
				buttons={isMobile ? [] : buttons}
				avatar={avatar}
			/>
			{!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin && (
				<ConfirmedInvoiceWarning />
			)}
			<InvoicePreview invoice={invoice} config={customFieldsConfig} setInvoice={setInvoice} />
			{showExportPdfInvoiceModal && (
				<ExportInvoicePdfModal
					isShown={showExportPdfInvoiceModal}
					invoice={invoice}
					onSuccess={changeInvoiceStatusToConfirmed}
					onHide={() => setShowExportPdfInvoiceModal(false)}
				/>
			)}
			{showConfirmationExportInvoice && (
				<ExportInvoiceConfirmationModal
					isShown={showConfirmationExportInvoice}
					onHide={() => setShowConfirmationExportInvoice(false)}
					invoices={[invoice]}
					onSuccessAction={() =>
						setInvoice({
							...invoice,
							status: "CONFIRMED",
						})
					}
				/>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={invoice.id}
					resourceType={"INVOICE"}
					onHide={() => setShowLogs(false)}
					organizationId={organization.id}
				/>
			)}
			{invoicePdfLink && (
				<InvoicePdfPreviewModal
					show={!!invoicePdfLink}
					onHide={() => setInvoicePdfLink("")}
					pdfLink={invoicePdfLink}
				/>
			)}
		</div>
	);
};
export default OrganizationInvoicesIndexPreviewPage;
