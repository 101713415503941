import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { VenueSettingsApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { getOptionsByVenueSetting } from "./VenueSettingsForm";

interface Props {
	types: any[];
	form: UseFormReturn<VenueSettingsApi>;
	AdditionalSettings?: React.ReactNode;
}
const SettingsTable = ({ form, types, AdditionalSettings }: Props): JSX.Element => {
	const {
		formState: { errors },
		control,
		getValues,
	} = form;
	const { t } = useTranslation();
	return (
		<>
			<thead>
				<tr>
					<th>{t("common.word.name", { ns: "lib" })}</th>
					<th>{t("modules.venue_settings.field.default.title")}</th>
					<th className={"w-20"}>{t("common.word.value")}</th>
				</tr>
			</thead>
			<tbody>
				{types
					.filter((f: { id: string }) => !f.id.includes("?"))
					.map((type: Record<string, any>) => {
						const ind = getValues().settings.findIndex((f) => f.type === type.id);
						const defVal = getValues().settings[ind]?.default_value ?? "true";
						const { isNumberInput, customPlaceholder } = type;
						return (
							<tr key={type.id}>
								<td className={"w-70"}>
									<div className={"d-flex flex-column"}>
										<span>{t(`enums.venue_settings.${type.id}.label`)}</span>
										<span className={"text-muted"}>
											{t(`enums.venue_settings.${type.id}.desc`)}
										</span>
									</div>
								</td>
								<td>
									<span className={"venue-default-setting"}>
										{isNumberInput
											? type.defaultValue
											: t(`modules.venue_settings.field.${type.defaultValue}.title`)}
									</span>
								</td>
								<td>
									{isNumberInput ? (
										<FormNumberInput
											errors={errors}
											control={control}
											name={`settings.${ind}.value`}
											allowNegative={false}
											suffix=""
											defaultValue={defVal}
										/>
									) : (
										<FormSelectGroup
											control={control}
											errors={errors}
											name={`settings.${ind}.value`}
											defaultValue={defVal}
											options={getOptionsByVenueSetting(defVal, type.id, t)}
											placeholder={customPlaceholder}
											data-testid={`settings.${ind}.value`}
										/>
									)}
								</td>
							</tr>
						);
					})}
				{AdditionalSettings}
			</tbody>
		</>
	);
};
export default SettingsTable;

// t("modules.venue_settings.field.0.title");
// t("modules.venue_settings.field.1.title");
// t("modules.venue_settings.field.empty.title");
// t("modules.venue_settings.field.no.title");
// t(`modules.venue_settings.field.true.title`);
// t(`modules.venue_settings.field.false.title`);
// t(`modules.venue_settings.field.ShiftWork.title`);
