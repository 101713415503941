import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "go-core/components/useWindowSize";
import { LiveOrdersFilters } from "../../services/types";
import LiveOrdersDeliveryEmployeeFilter from "./LiveOrdersDeliveryEmployeeFilter";
import LiveOrdersEmployeesLastActivityFilter from "./LiveOrdersEmployeesLastActivityFilter";
import LiveOrdersSearch from "./LiveOrdersSearch";
import LiveOrdersStatusFilter from "./LiveOrdersStatusFilter";
import LiveOrdersTimeFilter from "./LiveOrdersTimeFilter";
import LiveOrdersTypeFilter from "./TypeFilter/LiveOrdersTypeFilter";

interface Props {
	isShown: boolean;
	onHide: () => void;
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
	handleApplyFilters: () => void;
	filters: LiveOrdersFilters;
}

const LiveOrdersFiltersModal: FC<Props> = ({
	isShown,
	onHide,
	innerFilters,
	setInnerFilters,
	handleApplyFilters,
	filters,
}) => {
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;
	const location = useLocation();

	const onApply = () => {
		handleApplyFilters();
		onHide();
	};

	const handleOnHide = () => {
		onHide();
		setInnerFilters({ ...filters });
	};

	return (
		<Modal onHide={handleOnHide} show={isShown} className="live-orders-filters-modal">
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.live_order.header.filter_orders.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isMobile && (
					<>
						<LiveOrdersSearch innerFilters={innerFilters} setInnerFilters={setInnerFilters} insideModal />
						<LiveOrdersStatusFilter
							innerFilters={innerFilters}
							setInnerFilters={setInnerFilters}
							insideModal
						/>
					</>
				)}
				<LiveOrdersTimeFilter innerFilters={innerFilters} setInnerFilters={setInnerFilters} />
				<LiveOrdersEmployeesLastActivityFilter innerFilters={innerFilters} setInnerFilters={setInnerFilters} />
				{!location.pathname.includes("map") && (
					<LiveOrdersTypeFilter innerFilters={innerFilters} setInnerFilters={setInnerFilters} />
				)}
				<LiveOrdersDeliveryEmployeeFilter innerFilters={innerFilters} setInnerFilters={setInnerFilters} />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onApply}>{t("lib:common.action.apply")}</Button>
				<Button variant="light" onClick={handleOnHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default LiveOrdersFiltersModal;
