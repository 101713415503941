import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationDeliveryZoneEditPage from "./pages/Edit";
import OrganizationSettingsDeliveryZonesIndexPage from "./pages/Index";
import OrganizationDeliveryZoneNewPage from "./pages/New";

const OrganizationSettingsDeliveryZonesPage = (props: RouteComponentProps) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/new`} exact component={OrganizationDeliveryZoneNewPage} />
				<Route path={`${props.match.url}/:deliveryZoneId`} component={OrganizationDeliveryZoneEditPage} />
				<Route path={`${props.match.url}/`} exact component={OrganizationSettingsDeliveryZonesIndexPage} />
			</Switch>
		</div>
	);
};

export default OrganizationSettingsDeliveryZonesPage;
