import React from "react";
import { useTranslation } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import { getFormattedItemCost } from "go-core/utils/utils";

interface SummaryProps {
	totalPrice: MoneyApi;
}

export const Summary = ({ totalPrice }: SummaryProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<section data-testid="e-order-order-details-summary">
			<ul>
				<li className="total-cost">
					<span>{t("modules.e_order.field.total_cost.title")}</span>
					<span>{getFormattedItemCost(totalPrice.amount, totalPrice.currency)}</span>
				</li>
			</ul>
		</section>
	);
};
