import React, { FC, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { InvoiceApi, OrderInvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import { exportInvoicePdf } from "../../services/exportInvoice";

interface Props {
	isShown: boolean;
	onHide: () => void;
	invoice: OrderInvoiceApi | InvoiceApi;
	onSuccess: () => void;
}

interface FormProps {
	duplicate_date: string;
}

const ExportInvoicePdfModal: FC<Props> = ({ isShown, onHide, invoice, onSuccess }) => {
	const { t } = useTranslation();
	const [duplicate, setDuplicate] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const { addFlash } = useFlash();
	const form = useForm<FormProps>({
		criteriaMode: "all",
	});
	const {
		formState,
		handleSubmit,
		formState: { errors },
		control,
		setError,
		watch,
		setValue,
	} = form;

	const onSubmit = handleSubmit(async () => {
		setLoading(true);
		try {
			const params: Record<string, any> = {
				duplicate_date: watch("duplicate_date"),
			};
			await exportInvoicePdf(invoice, t, params);
			onSuccess();
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const toggleDuplicate = () => {
		setDuplicate(!duplicate);
		if (!duplicate) {
			setValue("duplicate_date", formatDateToString(new Date()));
		} else {
			setValue("duplicate_date", "");
		}
	};

	return (
		<Modal onHide={onHide} show={isShown}>
			<FormDirty formState={formState} key="export-invoice-pdf-modal-form" onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.invoice.action.export_invoice_pdf_modal.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant="warning">{t("modules.invoice.field.confirm_export_invoice_to_pdf.title")}</Alert>
					<span>{t("modules.invoice.field.export_invoice_pdf_modal_duplicate.help_text.description")}</span>
					<div className="mt-2">
						<Form.Check
							type="switch"
							id="duplicate"
							label={t("modules.invoice.field.export_invoice_pdf_modal_duplicate.title")}
							checked={duplicate}
							onChange={toggleDuplicate}
							className={`${duplicate && "mb-2"}`}
						/>
						{duplicate && (
							<FormDatePicker
								errors={errors}
								timePicker={false}
								label={t("modules.invoice.field.export_invoice_pdf_modal_duplicate_date.title")}
								control={control}
								name="duplicate_date"
							/>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("common.action.export_pdf", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default ExportInvoicePdfModal;
