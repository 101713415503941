import { memo } from "react";
import { ChartData } from "chart.js";
import { t } from "i18next";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { ReactComponent as ArrowRightSVG } from "../../../../images/svg/dashboard/arrow-right.svg";
import { DashboardClientInfomationsConfig } from "../../services/types";
import { AggregateValuePercentageChange } from "../Charts/AggregateValuePercentageChange";

const chartData: ChartData<"line", number[]> = {
	labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
	datasets: [
		{
			label: "",
			data: [20, 30, 10, 35, 40, 0, 50, 40, 30, 20, 15, 10],
			backgroundColor: "rgb(53, 162, 235)",
			borderColor: "rgb(53, 162, 235)",
			pointRadius: 0,
		},
	],
};

const chartOptions = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
				drawTicks: false,
				borderWidth: 0,
			},
		},
		y: {
			grid: {
				display: false,
				drawTicks: false,
				borderWidth: 0,
			},
			ticks: {
				display: false,
			},
		},
	},
};

const DashboardClientsDetailsItem = ({ config }: { config: DashboardClientInfomationsConfig }) => {
	const { title, icon, value, seeMoreLink, compareValue } = config;
	const formattedValue = typeof value === "object" ? FormatMoney(value) : value;

	return (
		<Card className="dashboard-clients-informations-item">
			<Card.Body className="d-flex flex-column justify-content-between">
				<div className="d-flex justify-content-between align-items-center">
					<h6 className="client-information-title">{title}</h6>
					{icon}
				</div>
				<div className="mt-3">
					<div className="d-flex flex-column justify-content-between">
						<div className="d-flex justify-content-between">
							<div className="value-and-chart">
								<span className="clients-informations-value">{formattedValue}</span>
								<AggregateValuePercentageChange
									currentPeriodValue={value}
									lastPeriodValue={compareValue}
								/>
							</div>
							<div className="w-30">
								<Line data={chartData} options={chartOptions} style={{ maxHeight: "24px" }} />
							</div>
						</div>
						<div className="d-flex justify-content-end">
							{/* Na grafice jest taka funkcjonalnosc, poki co w requescie nie ma */}
							{/* <span className="percentage-change">2% mniej niz godzine temu</span> */}
							<Link to={seeMoreLink}>
								<span className="see-report">{t("modules.dashboard.action.see_report.title")}</span>
								<ArrowRightSVG />
							</Link>
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default memo(DashboardClientsDetailsItem);
