import { NotFoundException } from "go-core/api/exceptions";

let currentOrganizationId: number | undefined = undefined;
const get = (): number | undefined => {
	return currentOrganizationId;
};
const getWithCheck = (): number | undefined => {
	if (currentOrganizationId === undefined) throw new NotFoundException();
	return currentOrganizationId;
};
const update = (id?: number): void => {
	currentOrganizationId = id;
};
const clear = (): void => {
	currentOrganizationId = undefined;
};
export const OrganizationIdService = { get, update, clear, getWithCheck };
export default OrganizationIdService;
