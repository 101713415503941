export interface GoSuiteApp {
	id: string;
	name: string;
	url: string;
	header?: string;
	old?: boolean;
}

export interface OrganizationCard {
	id: string;
	name: string;
	company_id: string;
	created_at: string;
	applications: string[];
	place?: OrganizationCardPlace;
	errors?: OrganizationCardError[];
}

export interface OrganizationCardError {
	error: string;
	link?: string;
}

export interface OrganizationCardPlace {
	facebook: string;
	instagram: string;
	website: string;
	linkedin: string;
	city: string;
	street: string;
	build_nr: string;
	flat_nr: string;
	country: string;
	zip_code: string;
}

export enum WebApp {
	GOPOS = "GOPOS",
	GOSTOCK = "GOSTOCK",
	GOCRM = "GOCRM",
	GOORDER = "GOORDER",
	GOACCOUNTS = "GOACCOUNTS",
}

export interface MenuAppConfig {
	webApp: WebApp;
	userEnteredLocation: boolean;
	oldVersionAppsTimestamp: Record<string, number>;
	oldVersionAppsCustomRedirectURL?: Record<string, string>;
}
