import { ConfigReportComboApi } from "go-report-combo/services/types";

export const REPORT_basic_example_1 = {
	id: "REPORT_basic_example_1",
	name: "Miesiac sprzedaz wedlug zrodla z podzialem na zrodla",
	data: [
		{
			id: "BILLS",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids}`,
			groups: ["NONE", "SOURCE"],
			columns: [
				{
					id: "summary",
					filter: "date_range|bt=this_month",
				},
			],
		},
		{
			id: "BILLS_LAST_MONTH",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids}`,
			groups: ["NONE", "SOURCE"],
			columns: [
				{
					id: "summary",
					filter: "date_range|bt=previous_1_months",
				},
			],
		},
		{
			id: "BILLS_LAST_YEAR",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids}`,
			groups: ["NONE", "SOURCE"],
			columns: [
				{
					id: "summary",
					filter: "date_range|bt=previous_12-13_months",
				},
			],
		},
	],
	rows: [
		{
			id: "BILLS",
			name: "BILLS",
			data_id: "BILLS",
			group: "SOURCE",
			aggregate: "total_money",
			aggregate_columns: {
				total_money: "total_money",
				transaction_count: "transaction_count",
				average_money: "average_money",
			},
			compare: [
				{
					id: "previous_value",
					data_id: "BILLS_LAST_MONTH",
					name: "vs poprzedni miesiac",
					format: "VALUE",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
						transaction_count: "transaction_count",
						average_money: "average_money",
					},
				},
				{
					id: "previous_percent",
					data_id: "BILLS_LAST_MONTH",
					name: "vs poprzedni miesiac %",
					format: "PERCENT",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
						transaction_count: "transaction_count",
						average_money: "average_money",
					},
				},
				{
					id: "previous_value_year",
					data_id: "BILLS_LAST_YEAR",
					name: "vs poprzedni rok",
					format: "VALUE",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
						transaction_count: "transaction_count",
						average_money: "average_money",
					},
				},
				{
					id: "previous_percent_year",
					data_id: "BILLS_LAST_YEAR",
					name: "vs poprzedni rok %",
					format: "PERCENT",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
						transaction_count: "transaction_count",
						average_money: "average_money",
					},
				},
			],
		},
	],
	columns: [
		{
			id: "total_money",
			data_column_id: "summary",
			name: "Sprzedaż",
		},
		{
			id: "transaction_count",
			data_column_id: "summary",
			name: "Ilość",
		},
		{
			id: "average_money",
			data_column_id: "summary",
			name: "Sredni rachunek",
		},
	],
} as ConfigReportComboApi;
