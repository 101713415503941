import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PdfOrientationType } from "go-core/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ReactComponent as InfoCircleSVG } from "../../../go-component/images/svg/info-circle.svg";
import FormatJobStatus from "./components/FormatJobStatus";
import { JobContext } from "./services/context";
import { JobApi } from "./services/types";

interface ListState {
	resource: any;
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

interface Props {
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

const listName = "JOB";
const resourceType = "JOB";

const List: FC<ListState> = ({ resource, organization_name, taxIdNo, companyName, pdfOrientation, pdfFontSize }) => {
	const [items, setItems] = useState<JobApi[]>([]);
	const location = useLocation();
	const { t } = useTranslation();
	const segmentContextValue = React.useContext(SegmentContext);
	const jobService = React.useContext(JobContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				render: (item: JobApi) => (
					<RenderLimitedText minWidth={200}>
						<Link to={`${location.pathname}/${item.id}`}>{jobService.getNameTitle(item.name)}</Link>
					</RenderLimitedText>
				),
				renderExport: (item: JobApi) => jobService.getNameTitle(item.name),
			},
			{
				id: "created_at",
				name: t("lib:common.word.created_at"),
				type: "date",
				render: (item: JobApi) => {
					return (
						<Link to={`${location.pathname}/${item.id}`}>
							{FormatDate(item.created_at, undefined, true)}
						</Link>
					);
				},
				renderExport: (item: JobApi) => {
					return FormatDate(item.created_at, undefined, true);
				},
			},
			{
				id: "updated_at",
				name: t("lib:common.word.updated_at"),
				type: "date",
				render: (item: JobApi) => {
					return FormatDate(item?.updated_at, undefined, true);
				},
				renderExport: (item: JobApi) => {
					return FormatDate(item?.updated_at, undefined, true);
				},
			},
			{
				id: "finished_at",
				name: t("lib:common.word.finished_at"),
				type: "date",
				render: (item: JobApi) => {
					return FormatDate(item?.finished_at, undefined, true);
				},
				renderExport: (item: JobApi) => {
					return FormatDate(item?.finished_at, undefined, true);
				},
			},
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list",
				options: {
					STARTED: t(`lib:go_component.job.status.STARTED`),
					ERROR: t(`lib:go_component.job.status.ERROR`),
					CANCELLED: t(`lib:go_component.job.status.CANCELLED`),
					TO_CANCEL: t(`lib:go_component.job.status.TO_CANCEL`),
					FINISHED: t(`lib:go_component.job.status.FINISHED`),
				},
				render: (item: JobApi) => {
					if (item.status === "ERROR") {
						return (
							<>
								<FormatJobStatus status={item.status} />
								<OverlayTrigger
									placement="top"
									overlay={<Tooltip id={`tooltip-${item?.id}`}>{item?.error}</Tooltip>}
								>
									<InfoCircleSVG className="ms-1" />
								</OverlayTrigger>
							</>
						);
					}

					return <FormatJobStatus status={item.status} />;
				},
				renderExport: (item: JobApi) => {
					return t(`lib:go_component.job.status.${item.status}`);
				},
			},
		],
		filters: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "search_select",
				source: {
					request: async () => {
						const res = await jobService.api().getNameOptions();
						return res.map((option) => ({
							id: option,
							label: jobService.getNameTitle(option),
						}));
					},
				},
				withoutInfiniteScroll: true,
			},
		],
		actions: [
			{
				name: t("lib:common.action.preview"),
				link: (item: JobApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
		],
		selectedColumns: ["name", "created_at", "finished_at", "status"],
		segments: [
			{
				id: "all",
				name: `${t("lib:common.word.all")}`,
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: async (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			return (await jobService.api().all(params, { cancelToken: sourceToken?.token })).data.data;
		},
		exportConfig: {
			title: t("lib:go_component.job.list.export_config.title"),
			filename: t("lib:go_component.job.list.export_config.filename"),
			organization: `${organization_name}`,
			company: companyName,
			taxIdNo,
			pdfFontSize,
			pdfOrientation,
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 2,
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};

	return <ListData data={items} config={config} onFetch={setItems} />;
};

const OrganizationLogsJobsIndexPage = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<any>();
	const isMobile = useWindowSize().isMobile;
	const segmentContextValue = React.useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_component.job.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("lib:go_component.job.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List
					organization_name={props.organization_name}
					resource={resource}
					taxIdNo={props.taxIdNo}
					companyName={props.companyName}
					pdfFontSize={props.pdfFontSize}
					pdfOrientation={props.pdfOrientation}
				/>
			</Suspense>
		</div>
	);
};
export default OrganizationLogsJobsIndexPage;
