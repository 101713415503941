import { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmptyList from "go-core/components/EmptyList";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import RenderPaymentMethod from "../../../../../../../../../../components/Common/Renderers/RenderPaymentMethod";
import { PosReportApi, PosReportCurrencyApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	report: PosReportApi;
}

const PaymentMethodsTabPreview: FC<Props> = ({ report }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const tdStyles = {
		padding: "14px 0px",
	};

	const getPaymentMethodTotalAmount = (currency: PosReportCurrencyApi) => {
		return currency.payment_methods.map((paymentMethod) => paymentMethod.count).reduce((acc, cur) => acc + cur, 0);
	};

	const getPaymentMethodTotalMoney = (currency: PosReportCurrencyApi) => {
		return currency.payment_methods
			.map((paymentMethod) => Number(paymentMethod.amount.amount))
			.reduce((acc, cur) => acc + cur, 0);
	};

	const getAllSortedPaymentMethods = () => {
		const paymentMethods: string[] = [];
		report.currencies.map((currency) =>
			currency.payment_methods.forEach((paymentMethod) => {
				if (!paymentMethods.includes(paymentMethod.receipt_type))
					paymentMethods.push(paymentMethod.receipt_type);
			})
		);

		return paymentMethods.sort((a, b) => a.localeCompare(b));
	};

	return (
		<Card>
			<Card.Body>
				{getAllSortedPaymentMethods().length > 0 ? (
					<div className={"table-responsive"}>
						<table className={"table table-preview mb-0 payment-methods-table"}>
							<thead>
								<tr>
									<th>{t("lib:common.word.name")}</th>
									<th>{t("common.word.amount")}</th>
									<th>{t("common.word.quantity")}</th>
									<th className={"w-1"}></th>
								</tr>
							</thead>
							<tbody>
								{getAllSortedPaymentMethods().map((paymentMethod) => {
									return report.currencies?.map((currency) =>
										currency.payment_methods
											.sort((a, b) => a.receipt_type.localeCompare(b.receipt_type))
											.map((method, index) => {
												if (paymentMethod === method.receipt_type)
													return (
														<tr key={index}>
															<td style={tdStyles}>
																<RenderPaymentMethod
																	name={method.name}
																	type={method.receipt_type}
																/>
															</td>
															<td>{FormatMoney(method.amount)}</td>
															<td>
																{t("modules.pos_report.field.method_amount.title", {
																	count: method.count,
																})}
															</td>
															<td style={{ whiteSpace: "nowrap" }}>
																<Link
																	to={`/${organization.id}/sales/orders?f=${btoa(
																		unescape(
																			encodeURIComponent(
																				`${NEW_WAY_TO_ENCODING_FILTER_SIGN}report|e=${report.id}${FILTER_SEPARATOR}payment_method|e=${method.id}`
																			)
																		)
																	)}`}
																	className={"btn btn-primary-light"}
																>
																	{t("modules.pos_report.action.show_orders.title")}
																</Link>
															</td>
														</tr>
													);
												return <></>;
											})
									);
								})}
								{report.currencies.map((currency, index) => {
									return (
										<tr
											className={`summary ${index > 0 && "summary-no-border"}`}
											key={`summary-row-${index}`}
										>
											{index === 0 ? (
												<td>
													<strong>{t("common.word.sum")}</strong>
												</td>
											) : (
												<td />
											)}
											<td>
												<strong>
													{FormatMoney({
														amount: getPaymentMethodTotalMoney(currency),
														currency: currency.currency,
													})}
												</strong>
											</td>
											<td>
												<strong>
													{t("modules.pos_report.field.method_amount.title", {
														count: getPaymentMethodTotalAmount(currency),
													})}
												</strong>
											</td>
											<td />
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				) : (
					<EmptyList />
				)}
			</Card.Body>
		</Card>
	);
};
export default PaymentMethodsTabPreview;
