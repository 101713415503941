import React, { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDateRangePicker } from "go-form/components/FormDateRangePicker";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ClearVenueCustomPermanentlyFormProps } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	loading: boolean;
	setLoading: (loading: boolean) => void;
}

const ClearVenueCustomPermanently: FC<Props> = ({ loading, setLoading }) => {
	const [password, setPassword] = useState("");
	const [selectedDeletionType, setSelectedDeletionType] = useState<
		"" | "PERMANENT_DELETION_BASED_ON_DATE" | "PERMANENT_DELETION"
	>("");
	const { addSuccessFlash, addFlash } = useFlash();
	const form = useForm<ClearVenueCustomPermanentlyFormProps>({
		defaultValues: {
			remove_menus: false,
			remove_item_groups_and_items: false,
			remove_discounts: false,
			remove_categories: false,
			remove_price_lists: false,
			remove_modifier_groups: false,
			remove_availabilities: false,
			remove_rooms: false,
			remove_payment_methods: false,
			remove_printout_templates: false,
			remove_clients: false,
			remove_employees: false,
			remove_segments: false,
			remove_custom_fields: false,
			remove_venue_settings: false,
			remove_invoice_details: false,
			remove_venue_roles_and_permissions: false,
			remove_default_comments: false,
			remove_currency_rates: false,
			remove_taxes: false,
			remove_directions: false,
			remove_workplaces: false,
			remove_point_of_sales: false,
			remove_notification_templates: false,
			remove_receipt: false,
			remove_tags: false,
			dateRangeVenueClear: {
				dateRange: undefined,
				remove_employee_activities: false,
				remove_reservations: false,
				remove_terminal_notifications: false,
				remove_work_times: false,
			},
		},
	});
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
		getValues,
		control,
		reset,
		watch,
	} = form;
	const confirmation = useConfirmation();

	const clearVenueCustomPermanently = async () => {
		const data = getValues();
		try {
			if (!selectedDeletionType) return;
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.action", { ns: "lib" }),
			});
			setLoading(true);
			if (selectedDeletionType === "PERMANENT_DELETION_BASED_ON_DATE") {
				const dateRangeArr = data.dateRangeVenueClear.dateRange.split(" - ");
				const requestBody = Object.entries(data.dateRangeVenueClear).reduce(
					(body, [parameterKey, parameterValue]) => {
						if (parameterKey === "dateRange") return body;
						return { ...body, [parameterKey]: parameterValue };
					},
					{}
				);
				await api
					.organization()
					.clearVenueSaleBasedOnDateRange(
						password,
						new Date(dateRangeArr[0]),
						new Date(dateRangeArr[1]),
						requestBody
					);
			} else {
				const dataToBeSent = Object.entries(data).reduce((data, [dataParameterKey, dataParameterValue]) => {
					if (dataParameterKey === "dateRangeVenueClear") return data;
					return { ...data, [dataParameterKey]: dataParameterValue };
				}, {} as ClearVenueCustomPermanentlyFormProps);
				await api.organization().clearVenueCustomPermanently(dataToBeSent, password);
			}

			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setPassword("");
			reset();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		setLoading(false);
	};

	return (
		<>
			<LimitComponentVisibility
				id="PERMANENT_DELETION_BASED_ON_DATE"
				legendText={t("modules.clear_data.field.delete_venue_based_on_date.title")}
				defaultVisible={selectedDeletionType === "PERMANENT_DELETION_BASED_ON_DATE"}
				handleChange={(visible) => setSelectedDeletionType(visible ? "PERMANENT_DELETION_BASED_ON_DATE" : "")}
				unlimitedContent={
					<div className="my-2 pl-3 pt-3 border rounded">
						<FormDateRangePicker
							timeStart={"00:00"}
							timeEnd={"23:59"}
							name={"dateRangeVenueClear.dateRange"}
							errors={errors}
							control={control}
							minDate={new Date("2015")}
							maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_reservations.title")}
							help={t("modules.clear.action.remove_reservations.help_text.title")}
							name={"dateRangeVenueClear.remove_reservations"}
							errors={errors}
							register={register}
							checked={watch("dateRangeVenueClear.remove_reservations")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_employee_activities.title")}
							help={t("modules.clear.action.remove_employee_activities.help_text.title")}
							name={"dateRangeVenueClear.remove_employee_activities"}
							errors={errors}
							register={register}
							checked={watch("dateRangeVenueClear.remove_employee_activities")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_terminal_notifications.title")}
							help={t("modules.clear.action.remove_terminal_notifications.help_text.title")}
							name={"dateRangeVenueClear.remove_terminal_notifications"}
							errors={errors}
							register={register}
							checked={watch("dateRangeVenueClear.remove_terminal_notifications")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_work_times.title")}
							help={t("modules.clear.action.remove_work_times.help_text.title")}
							name={"dateRangeVenueClear.remove_work_times"}
							errors={errors}
							register={register}
							checked={watch("dateRangeVenueClear.remove_work_times")}
						/>
					</div>
				}
			/>
			<LimitComponentVisibility
				id="PERMANENT_DELETION"
				legendText={t("modules.clear.action.delete_selected_data.title")}
				defaultVisible={selectedDeletionType === "PERMANENT_DELETION"}
				handleChange={(visible) => setSelectedDeletionType(visible ? "PERMANENT_DELETION" : "")}
				unlimitedContent={
					<div className="my-2 pl-3 pt-3 border rounded">
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_menus.title")}
							name={"remove_menus"}
							errors={errors}
							register={register}
							checked={watch("remove_menus")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_item_groups_and_items.title")}
							name={"remove_item_groups_and_items"}
							errors={errors}
							register={register}
							checked={watch("remove_item_groups_and_items")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_discounts.title")}
							name={"remove_discounts"}
							errors={errors}
							register={register}
							checked={watch("remove_discounts")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_categories.title")}
							name={"remove_categories"}
							errors={errors}
							register={register}
							checked={watch("remove_categories")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_price_lists.title")}
							name={"remove_price_lists"}
							errors={errors}
							register={register}
							checked={watch("remove_price_lists")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_modifier_groups.title")}
							name={"remove_modifier_groups"}
							errors={errors}
							register={register}
							checked={watch("remove_modifier_groups")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_availabilities.title")}
							name={"remove_availabilities"}
							errors={errors}
							register={register}
							checked={watch("remove_availabilities")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_rooms.title")}
							name={"remove_rooms"}
							errors={errors}
							register={register}
							checked={watch("remove_rooms")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_payment_methods.title")}
							name={"remove_payment_methods"}
							errors={errors}
							register={register}
							checked={watch("remove_payment_methods")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_printout_templates.title")}
							name={"remove_printout_templates"}
							errors={errors}
							register={register}
							checked={watch("remove_printout_templates")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_clients.title")}
							name={"remove_clients"}
							errors={errors}
							register={register}
							checked={watch("remove_clients")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_employees.title")}
							name={"remove_employees"}
							errors={errors}
							register={register}
							checked={watch("remove_employees")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_segments.title")}
							name={"remove_segments"}
							errors={errors}
							register={register}
							checked={watch("remove_segments")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_custom_fields.title")}
							name={"remove_custom_fields"}
							errors={errors}
							register={register}
							checked={watch("remove_custom_fields")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_venue_settings.title")}
							name={"remove_venue_settings"}
							errors={errors}
							register={register}
							checked={watch("remove_venue_settings")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_invoice_details.title")}
							name={"remove_invoice_details"}
							errors={errors}
							register={register}
							checked={watch("remove_invoice_details")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_venue_roles_and_permissions.title")}
							name={"remove_venue_roles_and_permissions"}
							errors={errors}
							register={register}
							checked={watch("remove_venue_roles_and_permissions")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_default_comments.title")}
							name={"remove_default_comments"}
							errors={errors}
							register={register}
							checked={watch("remove_default_comments")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_currency_rates.title")}
							name={"remove_currency_rates"}
							errors={errors}
							register={register}
							checked={watch("remove_currency_rates")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_taxes.title")}
							name={"remove_taxes"}
							errors={errors}
							register={register}
							checked={watch("remove_taxes")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_directions.title")}
							name={"remove_directions"}
							errors={errors}
							register={register}
							checked={watch("remove_directions")}
						/>
						<FormCheck
							type={"switch"}
							label={t("modules.clear.action.remove_workplaces.title")}
							name={"remove_workplaces"}
							errors={errors}
							register={register}
							checked={watch("remove_workplaces")}
						/>
						<FormCheck
							type="switch"
							label={t("modules.clear.action.remove_point_of_sales.title")}
							name="remove_point_of_sales"
							errors={errors}
							register={register}
						/>
						<FormCheck
							type="switch"
							label={t("modules.clear.action.remove_notification_templates.title")}
							name="remove_notification_templates"
							errors={errors}
							register={register}
						/>
						<FormCheck
							type="switch"
							label={t("modules.clear.action.remove_receipt.title")}
							name="remove_receipt"
							errors={errors}
							register={register}
						/>
						<FormCheck
							type="switch"
							label={t("modules.clear.action.remove_tags.title")}
							name="remove_tags"
							errors={errors}
							register={register}
						/>
					</div>
				}
			/>
			{selectedDeletionType && (
				<>
					<Form.Group className="form-group">
						<Form.Label>{`${t("lib:common.word.password")}:`}</Form.Label>
						<Form.Control
							type={"password"}
							value={password}
							onChange={(event) => setPassword(event.target.value)}
						/>
					</Form.Group>
					<ButtonLoading loading={loading} variant="primary" onClick={() => clearVenueCustomPermanently()}>
						{t("modules.clear.action.clear_data.title")}
					</ButtonLoading>
				</>
			)}
		</>
	);
};
export default ClearVenueCustomPermanently;
