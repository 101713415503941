import React, { FC, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { match, useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import { hasPermission } from "go-security/services/organizations/selectors";
import { OrganizationState } from "go-security/services/organizations/types";
import { selectUser } from "go-security/services/users/selectors";
import { ReactComponent as CalendarSVG } from "../../../images/svg/navigation/calendar.svg";
import { ReactComponent as ClientsSVG } from "../../../images/svg/navigation/clients.svg";
import { ReactComponent as EmployeesSVG } from "../../../images/svg/navigation/employees.svg";
import { ReactComponent as InvoiceSVG } from "../../../images/svg/navigation/invoice.svg";
import { ReactComponent as LiveOrdersSVG } from "../../../images/svg/navigation/live-orders.svg";
import { ReactComponent as MenuSVG } from "../../../images/svg/navigation/menu.svg";
import { ReactComponent as PlaceSVG } from "../../../images/svg/navigation/place.svg";
import { ReactComponent as SaleSVG } from "../../../images/svg/navigation/sale.svg";
import { ReactComponent as SettingsSVG } from "../../../images/svg/navigation/settings.svg";

interface NavigationProps {
	organization: OrganizationState;
}

interface NavigationItem {
	title: string;
	icon: JSX.Element;
	route: string;
	isActive?: (match: Record<string, any> | null, location: Record<string, any> | null) => boolean;
	hide?: boolean;
	rightElement?: JSX.Element;
}

const NavigationOrganizationMenu: FC<NavigationProps> = (props) => {
	const user = useSelector(selectUser);
	const organization = props.organization;
	const { t } = useTranslation();
	const defaultPermission = useSelector(hasPermission("DEFAULT"));
	const venueOrderShowPermission = useSelector(hasPermission("VENUE_ORDER_SHOW"));
	const venueLiveOrdersPermission = useSelector(hasPermission("VENUE_LIVE_ORDERS"));
	const venueEmployeeManagePermission = useSelector(hasPermission("VENUE_EMPLOYEE_MANAGE"));
	const venueClientManagePermission = useSelector(hasPermission("VENUE_CLIENT_MANAGE"));
	const venueReportsShowPermission = useSelector(hasPermission("VENUE_REPORTS_SHOW"));
	const venueTableReservationPermission = useSelector(hasPermission("VENUE_TABLE_RESERVATION"));
	const hasInvoicePermissions = useSelector(
		hasPermission(["VENUE_OWN_INVOICE_MANAGE", "VENUE_INVOICE_EDIT", "VENUE_INVOICE_MANAGE"])
	);
	const venueReportPosPermission = useSelector(hasPermission("VENUE_REPORTPOS_SHOW"));

	const hasMenuPermissions = useSelector(
		hasPermission([
			"MENU_READ",
			"MENU_WRITE",
			"DISCOUNT_READ",
			"DISCOUNT_WRITE",
			"PRICE_LIST_READ",
			"PRICE_LIST_WRITE",
		])
	);
	const prefix = `/${organization.id}`;
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		shouldRedirectToFirstVisibleItem();
	}, []);

	const shouldRedirectToFirstVisibleItem = () => {
		const currentItem = items.find(
			(item) => item.route === location.pathname || location.pathname.includes(item.route)
		);
		if (currentItem && currentItem.hide) redirectToFirstVisibleItem();
	};

	const redirectToFirstVisibleItem = () => {
		const firstVisibleNavigationItem = visibleNavigationItems[0];
		if (firstVisibleNavigationItem) {
			history.replace(firstVisibleNavigationItem.route);
		}
	};

	const items: NavigationItem[] = [
		{
			title: t("components.navigation.dashboard"),
			icon: <PlaceSVG />,
			isActive: (match: Record<string, any> | null, location: Record<string, any> | null) =>
				match?.url === location?.pathname,
			route: prefix,
		},
		{
			title: t("modules.order.header.sale.title"),
			icon: <SaleSVG />,
			route: `${prefix}/sales`,
			hide: !venueOrderShowPermission && !venueReportPosPermission,
		},
		{
			title: t("common.word.menu"),
			icon: <MenuSVG />,
			route: `${prefix}/menu`,
			hide: !hasMenuPermissions,
		},
		{
			title: t("modules.employee.header.title"),
			icon: <EmployeesSVG />,
			route: `${prefix}/employees`,
			hide: !venueEmployeeManagePermission,
		},
		{
			title: t("modules.client.header.title"),
			icon: <ClientsSVG />,
			route: `${prefix}/clients`,
			hide: !venueClientManagePermission,
		},
		{
			title: t("modules.invoice.header.title"),
			icon: <InvoiceSVG />,
			route: `${prefix}/invoices`,
			hide: !hasInvoicePermissions,
		},
		{
			title: t("modules.sale_report.header.title"),
			icon: <SaleSVG />,
			route: `${prefix}/reports`,
			hide: !venueReportsShowPermission,
		},
		{
			title: t("modules.reservation.header.title"),
			icon: <CalendarSVG />,
			route: `${prefix}/reservations`,
			hide: !venueTableReservationPermission,
		},
		{
			title: t("modules.live_order.header.title"),
			icon: <LiveOrdersSVG />,
			route: `${prefix}/live_orders`,
			hide: !venueLiveOrdersPermission,
			rightElement: <span className="badge bg-warning text-dark">BETA</span>,
		},
		{
			title: t("modules.setting.header.title"),
			icon: <SettingsSVG />,
			route: `${prefix}/settings`,
		},
	];

	const visibleNavigationItems = items.filter((item) => !item.hide);

	return defaultPermission || user?.roles?.includes("ROLE_ADMIN") ? (
		<Nav variant="pills" className="flex-column" defaultActiveKey="/home">
			{visibleNavigationItems.map((item) => (
				<Nav.Item key={item.route}>
					<Nav.Link as={NavLink} to={item.route} isActive={item.isActive}>
						<div className="nav-link-svg">{item.icon}</div>
						<span>{item.title}</span>
						{item?.rightElement && <div className="ms-auto">{item.rightElement}</div>}
					</Nav.Link>
				</Nav.Item>
			))}
		</Nav>
	) : (
		<></>
	);
};

export default NavigationOrganizationMenu;
