import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationSettingsPaymentMethodsEditPage from "./pages/Edit";
import OrganizationSettingsPaymentMethodsIndexPage from "./pages/Index";
import OrganizationSettingsPaymentMethodsNewPage from "./pages/New";

const OrganizationSettingsPaymentMethodsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route
					path={`${props.match.url}/`}
					exact={true}
					component={OrganizationSettingsPaymentMethodsIndexPage}
				/>
				<Route
					path={`${props.match.url}/new`}
					exact={true}
					component={OrganizationSettingsPaymentMethodsNewPage}
				/>
				<Route
					path={`${props.match.url}/:paymentMethodId`}
					component={OrganizationSettingsPaymentMethodsEditPage}
				/>
			</Switch>
		</div>
	);
};

export default OrganizationSettingsPaymentMethodsPage;
