import { Dispatch, ReactNode, SetStateAction } from "react";
import { ReactComponent as AngelDoubleLeftBlack } from "go-core/images/svg/navigation/angle-double-left-black.svg";

interface Props {
	collapseOpen: boolean;
	setCollapseOpen: Dispatch<SetStateAction<boolean>>;
	title: string;
	subtitle?: string;
	children: ReactNode;
}

export const SubNavigationNonMobile = ({ collapseOpen, setCollapseOpen, title, subtitle, children }: Props) => {
	return collapseOpen ? (
		<>
			<div className="navigation-header" id="contextual-navigation-header">
				<div className="contextual-navigation-main-content">
					<h2>
						{title}
						{subtitle && <small>{subtitle}</small>}
					</h2>
					<AngelDoubleLeftBlack onClick={() => setCollapseOpen((prevCollapseOpen) => !prevCollapseOpen)} />
				</div>
			</div>
			<div className="navigation-body">{children}</div>
		</>
	) : (
		<AngelDoubleLeftBlack
			onClick={() => setCollapseOpen((prevCollapseOpen) => !prevCollapseOpen)}
			className="icon-collapse-close"
		/>
	);
};
