import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import handleError from "go-app/services/errors";
import { parseApiData } from "go-core/api/parseApiData";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as QRCodeSvg } from "../../../../../../../../../../../images/svg/gopos-qr-code.svg";
import {
	CompanyInfoApi,
	PrintoutAdditionsPrintMethod,
	ReceiptApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	receipt: ReceiptApi;
}

const ReceiptForm = (props: Props): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const [newAvatar, setNewAvatar] = useState<string | undefined>(props.receipt.logo_link?.default_link);
	const [receiptImageData, setReceiptImageData] = useState<{ default_link: string } | undefined>(undefined);
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const defaultLuminanceThreshold = props.receipt?.luminance_threshold ?? 127.5;
	const currency = organization?.currency || "";

	const form = useForm<ReceiptApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.receipt,
			facebook: props.receipt.facebook?.replace("fb.com/", ""),
			instagram: props.receipt.instagram?.replace("instagram.com/", ""),
		},
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		watch,
		formState,
		setError,
		reset,
		setValue,
	} = form;

	const showEmployee = watch("print_employee");

	useEffect(() => {
		fetchCompanyInfo();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			onChangeImageContrast(defaultLuminanceThreshold);
		}, 100);
	}, []);

	useEffect(() => {
		const getImage = async () => {
			if (!props.receipt?.logo_link?.default_link) return;
			try {
				const downloadedImageAsBlob: Blob = await parseApiData(
					axios.get(props.receipt.logo_link.default_link, {
						headers: {
							"Access-Control-Allow-Origin": "*",
							"Cache-Control": "no-cache",
						},
						responseType: "blob",
					})
				);
				const imageLinkObj = URL.createObjectURL(downloadedImageAsBlob);
				setReceiptImageData({ default_link: imageLinkObj });
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		};

		getImage();
	}, [addFlash, props.receipt.logo_link?.default_link]);

	const handleImageChange = (imageBase?: string, type?: string) => {
		if (type) {
			setNewAvatar(`data:${type};base64,${imageBase}`);
		}
		if (!type && receiptImageData) {
			setNewAvatar(undefined);
			setReceiptImageData(undefined);
		}
	};

	const fetchCompanyInfo = async () => {
		try {
			const res = await api.organization().getCompanyInfo();
			setCompanyInfo(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onSubmit = handleSubmit(async (data: ReceiptApi) => {
		setLoading(true);
		if (data.facebook) {
			data.facebook = `fb.com/${data.facebook}`;
		}
		if (data.instagram) {
			data.instagram = `instagram.com/${data.instagram}`;
		}
		if (getValues("logo.image_data") || !newAvatar) {
			data.logo_link = undefined;
		}
		try {
			const res = await api.organization().updateReceiptSettings(data);
			reset({
				...res,
				facebook: res.facebook?.replace("fb.com/", ""),
				instagram: res.instagram?.replace("instagram.com/", ""),
			});
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const onChangeInsta = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if ("inputType" in evt.nativeEvent && evt.nativeEvent.inputType === "insertFromPaste") {
			const parts = evt.target.value.split("/");
			const name = parts[parts.length - 1];
			if (name) {
				setValue("instagram", name);
			}
			if (!name && parts.length >= 2) {
				setValue("instagram", parts[parts.length - 2]);
			}
		} else {
			setValue("instagram", evt.target.value, { shouldDirty: true });
		}
	};

	const onChangeFb = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if ("inputType" in evt.nativeEvent && evt.nativeEvent.inputType === "insertFromPaste") {
			const parts = evt.target.value.split("/");
			const name = parts[parts.length - 1];
			if (name) {
				setValue("facebook", name);
			}
			if (!name && parts.length >= 2) {
				setValue("facebook", parts[parts.length - 2]);
			}
		} else {
			setValue("facebook", evt.target.value, { shouldDirty: true });
		}
	};

	const onConvertToBlackAndWhite = (sourceBitmap: HTMLImageElement, luminanceThreshold: number) => {
		const blackAndWhiteCanvas = document.createElement("canvas");
		blackAndWhiteCanvas.width = sourceBitmap.naturalWidth;
		blackAndWhiteCanvas.height = sourceBitmap.naturalHeight;
		const blackAndWhiteCtx = blackAndWhiteCanvas.getContext("2d") as CanvasRenderingContext2D;

		blackAndWhiteCtx?.drawImage(sourceBitmap, 0, 0);
		const imageData = blackAndWhiteCtx.getImageData(0, 0, blackAndWhiteCanvas.width, blackAndWhiteCanvas.height);
		const data = imageData.data;
		for (let i = 0; i < data.length; i += 4) {
			const r = data[i];
			const g = data[i + 1];
			const b = data[i + 2];
			const a = data[i + 3];
			if (a === 0 || (r === 255 && g === 255 && b === 255)) {
				data[i] = 255;
				data[i + 1] = 255;
				data[i + 2] = 255;
			} else if (r === 0 && g === 0 && b === 0) {
				data[i] = 0;
				data[i + 1] = 0;
				data[i + 2] = 0;
			} else {
				const luminance = 0.3 * r + 0.59 * g + 0.11 * b;
				if (luminance < luminanceThreshold) {
					data[i] = 0;
					data[i + 1] = 0;
					data[i + 2] = 0;
				} else {
					data[i] = 255;
					data[i + 1] = 255;
					data[i + 2] = 255;
				}
			}
		}
		blackAndWhiteCtx.putImageData(imageData, 0, 0);
		const blackAndWhiteImage = new Image();
		blackAndWhiteImage.crossOrigin = "anonymous";
		blackAndWhiteImage.src = blackAndWhiteCanvas.toDataURL();
		return blackAndWhiteImage;
	};

	const onChangeImageContrast = (luminanceThreshold: number) => {
		setValue("luminance_threshold", luminanceThreshold);
		const originalImage = document.getElementById("form-image") as HTMLImageElement | null;
		const newImage: HTMLImageElement = document.getElementById("printout-logo") as HTMLImageElement;
		if (originalImage?.naturalWidth && originalImage?.naturalHeight) {
			const blackAndWhiteImage = onConvertToBlackAndWhite(originalImage, luminanceThreshold);

			newImage.src = blackAndWhiteImage.src;
		}
	};

	return (
		<FormDirty formState={formState} loading={loading} key="receipt-form" noValidate onSubmit={onSubmit}>
			<fieldset className={"form-group"}>
				<div className={"row"}>
					<div className={"col-md-4"}>
						<div className="receipt mb-2">
							<div className="receipt-list">
								<div className="receipt-header">
									<div className={"receipt-content-header"}>
										<div className={"d-flex mb-4"}>
											{newAvatar && (
												<img
													id="printout-logo"
													className="logo-image"
													src={newAvatar}
													alt={"Receipt image"}
												/>
											)}
										</div>
										<div className={"company-header"}>
											{watch("print_organization") && <h5>{organization.name}</h5>}
											{watch("print_company") && (
												<h6>
													{companyInfo && companyInfo.company_name
														? companyInfo.company_name
														: t("modules.receipt.field.company_name.title")}
												</h6>
											)}
											{watch("print_address") && (
												<div className={"mb-2"} style={{ marginTop: "-8px" }}>
													<FormatAddress
														address={{
															city: companyInfo?.address_city,
															street: companyInfo?.address_street,
															build_nr: companyInfo?.address_build_nr,
															flat_nr: companyInfo?.address_flat_nr,
															zip_code: companyInfo?.address_zip_code,
														}}
													/>
												</div>
											)}
											{watch("custom_header") && <h6>{watch("custom_header")}</h6>}
										</div>
										<div className={"company-details"}>
											<span>
												{organization.more?.address?.street}{" "}
												{organization.more?.address?.build_nr}
												{`${
													organization.more?.address?.flat_nr
														? `/${organization.more?.address.flat_nr}`
														: ""
												}`}
											</span>
											<span>
												{organization.more?.address?.zip_code}{" "}
												{organization.more?.address?.city}
											</span>
											<span>
												{organization.more?.tax_id_no && (
													<>
														{t("lib:common.word.tax_id_no.title")}:{" "}
														{organization.more?.tax_id_no}
													</>
												)}
											</span>
										</div>
									</div>
									<div className={"receipt-content-footer"}>
										<span>
											{t("modules.receipt.field.day_short.title")}{" "}
											{FormatDate(new Date().toLocaleDateString())}
										</span>
										<span>{t("modules.receipt.field.printout.title")} 0000001</span>
									</div>
								</div>
								<div className={"receipt-content"}>
									<div className={"receipt-content-header"}>
										<h6>{t("modules.receipt.field.fiscal_receipt.title")}</h6>
									</div>
									<div className={"receipt-content-body"}>
										<div className={"receipt-content-body-item"}>
											{watch("print_discount") ? (
												<>
													<div className={"receipt-position"}>
														<span>Coca Cola 0.85l</span>
														<span>
															{FormatMoney({
																amount: 10,
																currency,
															})}
														</span>
													</div>
													<div className={"receipt-position"}>
														<span className={"ms-4"}>
															{t("modules.receipt.field.discount.title")}
														</span>
														<span>
															{FormatMoney({
																amount: -4,
																currency,
															})}
														</span>
													</div>
													<div className={"receipt-position"}>
														<span className={"ms-auto"}>
															{FormatMoney({
																amount: 6,
																currency,
															})}
														</span>
													</div>
												</>
											) : (
												<div className={"receipt-position"}>
													<span>Coca Cola 0.85l</span>
													<span>
														{FormatMoney({
															amount: 6,
															currency,
														})}
													</span>
												</div>
											)}
											{watch("additions_print_method") ===
												PrintoutAdditionsPrintMethod.AS_SEPARATE_ITEMS && (
												<>
													<div className="receipt-position">
														<span>Pizza Margherita</span>
														<span>
															{FormatMoney({
																amount: 30,
																currency,
															})}
														</span>
													</div>
													<div className="receipt-position">
														<span>{t("modules.receipt.field.ham.title")}</span>
														<span>
															{FormatMoney({
																amount: 3,
																currency,
															})}
														</span>
													</div>
													<div className="receipt-position">
														<span>{t("modules.receipt.field.mushroom.title")}</span>
														<span>
															{FormatMoney({
																amount: 3,
																currency,
															})}
														</span>
													</div>
												</>
											)}
											{watch("additions_print_method") ===
												PrintoutAdditionsPrintMethod.IN_DESCRIPTION && (
												<>
													<div className="receipt-position">
														<span>Pizza Margherita</span>
														<span>
															{FormatMoney({
																amount: 36,
																currency,
															})}
														</span>
													</div>
													<div className="ms-1 text-muted">
														{t("modules.receipt.field.ham.title")},{" "}
														{t("modules.receipt.field.mushroom.title")}
													</div>
												</>
											)}
											{watch("additions_print_method") ===
												PrintoutAdditionsPrintMethod.NO_ADDITIONS && (
												<div className="receipt-position">
													<span>Pizza Margherita</span>
													<span>
														{FormatMoney({
															amount: 36,
															currency,
														})}
													</span>
												</div>
											)}
										</div>
										<div className={"receipt-content-body-item"}>
											<div className={"receipt-position"}>
												<span>{t("modules.receipt.field.tax.title")} 5%</span>
												<span>{FormatMoney({ amount: 2.1, currency })}</span>
											</div>
											<div className={"receipt-position"}>
												<span>
													{t("modules.receipt.field.tax.title")}{" "}
													{t("modules.receipt.field.sum.title")}
												</span>
												<span>{FormatMoney({ amount: 2.1, currency })}</span>
											</div>
										</div>
										<div className={"receipt-content-body-item"}>
											<div className={"receipt-position"}>
												<strong>{t("modules.receipt.field.sum.title").toUpperCase()}</strong>
												<strong>
													{FormatMoney({
														amount: 42,
														currency,
													})}
												</strong>
											</div>
										</div>
									</div>
									<div className={"receipt-content-desc"}>
										<div className={"desc-item"}>
											{showEmployee && (
												<>
													<div className={"employee"}>
														<span>{t("common.word.employee")}</span>
														<span>Jan Kowalski</span>
													</div>
												</>
											)}
											{watch("print_comment") && (
												<span className={"d-flex justify-content-center"}>
													{t("modules.receipt.field.example_bill_comment.title")}
												</span>
											)}
										</div>
									</div>
									<div>
										{(watch("facebook") || watch("instagram") || watch("website")) && (
											<div className={"receipt-content-footer"}>
												<div className={"receipt-content-footer-item"}>
													{watch("facebook") && <span>fb.com/{watch("facebook")}</span>}
													{watch("instagram") && (
														<span>instagram.com/{watch("instagram")}</span>
													)}
													{watch("website") && <span>{watch("website")}</span>}
												</div>
											</div>
										)}
										{(watch("custom_fiscal_text") || watch("custom_text")) && (
											<div className={"receipt-content-footer"}>
												<div className={"receipt-content-footer-item"}>
													{watch("custom_text") && <span>{watch("custom_text")}</span>}
													{watch("custom_fiscal_text") && (
														<span>{watch("custom_fiscal_text")}</span>
													)}
												</div>
											</div>
										)}
										{watch("print_qr_code") && (
											<div className={"receipt-content-footer"}>
												<div className={"receipt-content-footer-item"}>
													<QRCodeSvg />
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={"col-md-8 receipt-form"}>
						<Card>
							<Card.Body>
								<h6>{t("modules.receipt.field.bill_info.title")}</h6>
								<div className={"row"}>
									<div className={"col-md-9"}>
										<div className={"row row-cols-1"}>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_organization.title")}
													name="print_organization"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_company.title")}
													name="print_company"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_address.title")}
													name="print_address"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_comment.title")}
													name="print_comment"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_employee.title")}
													name="print_employee"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_discount.title")}
													name="print_discount"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_qr_code.title")}
													name="print_qr_code"
													errors={errors}
												/>
											</div>
											<div className={"col-md-12"}>
												<FormCheck
													type="switch"
													register={register}
													label={t("modules.receipt.action.print_table.title")}
													name="print_table"
													errors={errors}
												/>
											</div>
											<div className={"col-12"}>
												<div className="d-flex flex-column mb-3">
													<Form.Label>
														{t("modules.receipt.action.luminance_threshold.title")}
													</Form.Label>
													<input
														type="range"
														min="0"
														max="255"
														step="12.75"
														onChange={(e) => onChangeImageContrast(Number(e.target.value))}
														defaultValue={defaultLuminanceThreshold}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className={"col-md-3 logo"}>
										<ImageColorForm
											label={t("common.word.printed_logo")}
											form={form}
											handleSave={handleImageChange}
											prefix="logo"
											mode="IMAGE"
											data={props.receipt}
											resourceLink={receiptImageData}
											resourceData={props.receipt.logo}
										/>
									</div>
								</div>
								<FormSelectGroup
									label={t("modules.receipt.field.additions_print_method.title")}
									name="additions_print_method"
									errors={errors}
									options={[
										{
											value: PrintoutAdditionsPrintMethod.NO_ADDITIONS,
											label: t("modules.receipt.field.additions_print_method_no_additions.title"),
										},
										{
											value: PrintoutAdditionsPrintMethod.IN_DESCRIPTION,
											label: t(
												"modules.receipt.field.additions_print_method_in_description.title"
											),
										},
										{
											value: PrintoutAdditionsPrintMethod.AS_SEPARATE_ITEMS,
											label: t(
												"modules.receipt.field.additions_print_method_as_separate_items.title"
											),
										},
									]}
									control={form.control}
									isClearable={false}
									data-testid="additions_print_method"
								/>
								<Form.Label>{t("modules.receipt.field.facebook.title")}</Form.Label>
								<InputGroup className={"form-input-group"}>
									<InputGroup.Text style={{ height: "31px" }}>fb.com/</InputGroup.Text>
									<FormInput
										onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChangeFb(evt)}
										name={"facebook"}
										errors={errors}
										register={register}
									/>
								</InputGroup>
								<Form.Label>{t("modules.receipt.field.instagram.title")}</Form.Label>
								<InputGroup className={"form-input-group"}>
									<InputGroup.Text style={{ height: "31px" }}>instagram.com/</InputGroup.Text>
									<FormInput
										onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChangeInsta(evt)}
										name={"instagram"}
										errors={errors}
										register={register}
									/>
								</InputGroup>
								<FormInput
									label={t("modules.receipt.field.website.title")}
									register={register}
									name={"website"}
									errors={errors}
								/>
								<FormInput
									as={"textarea"}
									rows={3}
									label={t("modules.receipt.field.custom_header.title")}
									register={register}
									name={"custom_header"}
									errors={errors}
								/>
								<FormInput
									as={"textarea"}
									rows={3}
									label={t("modules.receipt.field.custom_text.title")}
									register={register}
									name={"custom_text"}
									errors={errors}
								/>
								<FormInput
									as={"textarea"}
									rows={3}
									label={t("modules.receipt.field.custom_fiscal_text.title")}
									register={register}
									name={"custom_fiscal_text"}
									errors={errors}
								/>
							</Card.Body>
						</Card>
					</div>
				</div>
			</fieldset>
		</FormDirty>
	);
};

export default ReceiptForm;
