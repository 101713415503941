import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { useIntercom } from "react-use-intercom";
import BlankLayout from "go-core/components/Layout/BlankLayout";
import { isWebView } from "go-core/utils/utils";
import { IntercomSecurity } from "../../services/intercom/intercomSecurity";
import { isLicenseExpired } from "../../services/license";
import { selectOrganization } from "../../services/organizations/selectors";
import { OrganizationState } from "../../services/organizations/types";
import { selectIsAuthenticated } from "../../services/session/selectors";
import { selectUser } from "../../services/users/selectors";
import { isInstalled } from "../../services/utils";
import SecurityAcceptTerms from "../AcceptTerms/AcceptTerms";
import LicenseExpiringNotification from "../LicenseExpiringNotification/LicenseExpiringNotification";
import OrganizationNotAcceptedTermsNotification from "../OrganizationAcceptTerms/OrganizationNotAcceptedTermsNotification";
import UnverifiedEmailNotification from "../UnverifiedEmailNotification/UnverifiedEmailNotification";

interface Props {
	goaccounts_url: string;
	navigation: () => JSX.Element;
	baseUrl: string;
	roles?: string[];
}

const AppIndexSecurityComponent: FC<Props> = (props) => {
	const user = useSelector(selectUser);
	const location = useLocation();
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const organization = useSelector(selectOrganization);
	const { boot, update } = useIntercom();
	const [intercomBannerElement, setIntercomBannerElement] = useState<HTMLElement>();
	const observerRef = useRef<MutationObserver>();
	const notAcceptedTerms = !organization.accept_terms || !organization.accept_privacy || !organization.accept_rules;

	const initDomObserver = () => {
		observerRef.current = new MutationObserver(() => {
			setTimeout(() => {
				updateBannerRef();
			}, 300);
		});
		const body = document.querySelector("body");
		if (body) {
			observerRef.current.observe(body, {
				attributes: false,
				childList: true,
				subtree: false,
			});
		}
	};

	const cleanupDomObserver = () => {
		observerRef.current?.disconnect();
	};

	const updateBannerRef = () => {
		const el = document.getElementsByName("intercom-banner-frame")[0];
		setIntercomBannerElement(el);
	};

	const initIntercom = () => {
		if (isWebView) return;

		const cfg = IntercomSecurity.cfg(user, organization);
		if (!cfg) return;
		if (!IntercomSecurity.isInitialized()) {
			IntercomSecurity.initialize();
			boot(cfg);
		} else {
			update(cfg);
		}
	};

	useEffect(() => {
		initIntercom();
	}, [
		IntercomSecurity.getIntercomId(user),
		IntercomSecurity.userHash(user),
		IntercomSecurity.companyId(organization),
	]);

	useLayoutEffect(() => {
		initDomObserver();
		return () => {
			cleanupDomObserver();
		};
	}, []);

	if (!isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { from: location },
				}}
			/>
		);
	}
	const isOrganizationLicenseExpired = (organizationToBeChecked: OrganizationState) => {
		if (!isInstalled(organizationToBeChecked)) return false;
		return isLicenseExpired(
			organizationToBeChecked.license_expires_at,
			organizationToBeChecked.license_type,
			organizationToBeChecked.timezone
		);
	};

	const checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization = () => {
		if (location.pathname !== `/${organization.id}/accept_terms`) {
			return organization.id && notAcceptedTerms;
		}
		return false;
	};

	const getTopMargin = (): string => {
		let marginToAdd = 0;
		if (intercomBannerElement) {
			if (window.getComputedStyle(intercomBannerElement, null).top === "0px") {
				marginToAdd += intercomBannerElement.clientHeight;
			}
		}

		if (!user?.verified) {
			if (isOrganizationLicenseExpired(organization)) {
				if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${138 + marginToAdd}px`;
				return `${92 + marginToAdd}px`;
			}
			if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${92 + marginToAdd}px`;
			return `${46 + marginToAdd}px`;
		}
		if (isOrganizationLicenseExpired(organization)) {
			if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${92 + marginToAdd}px`;
			return `${46 + marginToAdd}px`;
		}
		if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${46 + marginToAdd}px`;
		return `${marginToAdd}px`;
	};

	const getTopStyleForLicenseExpiringNotification = (): string => {
		let marginToAdd = 0;
		if (intercomBannerElement) {
			if (window.getComputedStyle(intercomBannerElement, null).top === "0px") {
				marginToAdd += intercomBannerElement.clientHeight;
			}
		}

		if (!user?.verified) {
			if (isOrganizationLicenseExpired(organization)) {
				return `${46 + marginToAdd}px`;
			}
			return `${marginToAdd}px`;
		}
		return `${marginToAdd}px`;
	};

	const getTopStyleForOrganizationNotAcceptedTermsNotification = (): string => {
		let marginToAdd = 0;
		if (intercomBannerElement) {
			if (window.getComputedStyle(intercomBannerElement, null).top === "0px") {
				marginToAdd += intercomBannerElement.clientHeight;
			}
		}

		if (!user?.verified) {
			if (isOrganizationLicenseExpired(organization)) {
				if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${46 + marginToAdd}px`;
			}
			if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${46 + marginToAdd}px`;
			return `${marginToAdd}px`;
		}
		if (isOrganizationLicenseExpired(organization)) {
			if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${46 + marginToAdd}px`;
			return `${marginToAdd}px`;
		}
		if (checkIfRenderCustomTopStyleWhenNotAcceptedTermsByOrganization()) return `${marginToAdd}px`;
		return `${marginToAdd}px`;
	};

	const getBottomMargin = () => {
		let margin = 0;

		if (intercomBannerElement) {
			if (window.getComputedStyle(intercomBannerElement, null).bottom === "0px") {
				margin += intercomBannerElement.clientHeight;
			}
		}

		return `${margin}px`;
	};

	if (!user.accept_terms) {
		return (
			<div className="page">
				{props.navigation()}
				<SecurityAcceptTerms baseUrl={props.baseUrl} />
			</div>
		);
	}

	const getStyle = () => ({
		marginTop: getTopMargin(),
		marginBottom: getBottomMargin(),
	});

	return (
		<BlankLayout>
			<UnverifiedEmailNotification />
			<LicenseExpiringNotification
				organization={organization}
				goaccounts_url={props.goaccounts_url}
				topStyle={getTopStyleForLicenseExpiringNotification()}
			/>
			<OrganizationNotAcceptedTermsNotification
				organization={organization}
				topStyle={getTopStyleForOrganizationNotAcceptedTermsNotification()}
				roles={props.roles}
			/>
			<div className="page" style={getStyle()}>
				{props.navigation()}
				{props.children}
			</div>
		</BlankLayout>
	);
};
export default AppIndexSecurityComponent;
