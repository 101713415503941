import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatBoolean, { convertBoolean } from "go-core/components/Formatters/FormatBoolean";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { DiscountApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "DISCOUNT";
const resourceType = "DISCOUNT";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<DiscountApi[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (refreshParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(refreshParams);
			setItems(res);
		}
	};

	const onRemoveDiscount = async (item: DiscountApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeDiscount(item.id);
			onRefresh(params);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestoreDiscount = async (item: DiscountApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreDiscount(item.id);
			onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onDisableDiscount = async (item: DiscountApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.disable", { ns: "lib" }),
			});
			await api.organization().disableDiscount(item);
			onRefresh(params);
			addSuccessFlash(t("lib:common.word.completed"));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: DiscountApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(item.status)}
							minWidth={200}
							to={`${location.pathname}/${item.id}`}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: DiscountApi) => item.name,
			},
			{
				id: "hidden",
				name: t("modules.discount.field.hidden.title"),
				type: "boolean",
				render: (item: DiscountApi) => <FormatBoolean value={item.hidden} />,
				renderExport: (item: DiscountApi) => convertBoolean(item.hidden, t),
			},
			{
				id: "editable",
				name: t("modules.discount.field.editable.title"),
				type: "boolean",
				render: (item: DiscountApi) => <FormatBoolean value={item.editable} />,
				renderExport: (item: DiscountApi) => convertBoolean(item.editable, t),
			},
			{
				id: "volume",
				name: t("modules.discount.field.volume.title"),
				type: "number",
			},
			{
				id: "discount_type",
				name: t("common.word.discount_type"),
				type: "list",
				options: {
					AMOUNT: t("enums.discounts.type.AMOUNT"),
					PERCENT: t("enums.discounts.type.PERCENT"),
					TIP: t("enums.discounts.type.TIP"),
				},
				render: (item: DiscountApi) => {
					return t(`enums.discounts.type.${item.discount_type}`);
				},
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				type: "search_select",
				render: (item: DiscountApi) => {
					return <span>{item.payment_method?.name}</span>;
				},
				renderExport: (item: DiscountApi) => {
					return item.payment_method?.name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPaymentMethodsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "availability",
				name: t("common.word.availability"),
				type: "search_select",
				render: (item: DiscountApi) => {
					return <span>{item.availability?.name}</span>;
				},
				renderExport: (item: DiscountApi) => {
					return item.availability?.name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getAvailabilitiesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "reference_id",
				name: t("common.word.reference", { ns: "lib" }),
				type: "text",
			},
			{
				id: "application_mode",
				name: t("modules.discount.field.add_mode.title"),
				render: (item: DiscountApi) => t(`modules.discount.field.${item.application_mode.toLowerCase()}.title`),
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: DiscountApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: DiscountApi) => {
					onRemoveDiscount(item);
				},
				visible: (item: DiscountApi) => {
					return item.status !== "DELETED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: DiscountApi) => {
					onRestoreDiscount(item);
				},
				visible: (item: DiscountApi) => {
					return item.status === "DELETED" || item.status === "DISABLED";
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: DiscountApi) => {
					onDisableDiscount(item);
				},
				visible: (item: DiscountApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.copy", { ns: "lib" }),
				click: (item: DiscountApi) => {
					history.push({
						pathname: `${location.pathname}/new`,
						state: {
							discount: {
								...item,
								id: undefined,
								name: t("modules.discount.field.copy.title", { name: item.name }),
							},
						},
					});
				},
			},
		],
		multipleActions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: async (
					multipleActionsItems: DiscountApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.discount.field.confirmation_remove.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include = "entities,roles,payment_method,availability";

						await api
							.organization()
							.removeDiscounts(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: DiscountApi[]) => {
					return multipleActionsItems.filter((f) => f.status !== "DELETED").length > 0;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: async (
					multipleActionsItems: DiscountApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.discount.field.confirmation_activate.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include = "entities,roles,payment_method,availability";

						await api
							.organization()
							.restoreDiscounts(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: DiscountApi[]) => {
					return (
						multipleActionsItems.filter((f) => f.status === "DELETED" || f.status === "DISABLED").length > 0
					);
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: async (
					multipleActionsItems: DiscountApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.discount.field.confirmation_disable.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include = "entities,roles,payment_method,availability";

						await api
							.organization()
							.disableDiscounts(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: DiscountApi[]) => {
					return multipleActionsItems.filter((f) => f.status === "ENABLED").length > 0;
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					DISABLED: t("enums.common.status.DISABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
				},
			},
			{
				id: "application_mode",
				name: t("modules.discount.field.add_mode.title"),
				type: "list",
				listOptions: {
					AUTOMATIC: t("modules.discount.field.automatic.title"),
					MANUAL: t("modules.discount.field.manual.title"),
				},
				hasDefaultOptions: false,
			},
		],
		selectedColumns: ["name", "hidden", "discount_type", "volume", "payment_method", "availability"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e",
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.discount.field.export_config.title"),
			filename: t("common.word.discounts"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "payment_method,availability,entities.entity,entities,roles,tip";
			setParams(fetchParams);
			return api.organization().getDiscounts(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
		fetchTotalItemsCountForSelectedFilters: (
			fetchTotalItemsParams: Record<string, any>,
			sourceToken?: CancelTokenSource
		) => api.organization().getDiscountsCount(fetchTotalItemsParams, { cancelToken: sourceToken?.token }),
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={
				<EmptyList
					title={t("common.word.discounts")}
					description={t("modules.discount.field.empty_list_description.title")}
					actions={[
						{
							name: `+ ${t("common.action.add", { ns: "lib" })}`,
							click: () => {
								history.push(`${location.pathname}/new`);
							},
						},
					]}
				/>
			}
			onFetch={(fetchItems: DiscountApi[]) => setItems(fetchItems)}
			canManageMultiActionsForAllItems
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationMenuDiscountsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("common.word.discounts"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("common.word.discounts")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};

export default OrganizationMenuDiscountsIndexPage;
