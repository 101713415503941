import React, { FC, ReactElement } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";

interface ListPaginationSizeState {
	value?: string;
	onChange: (value: string) => void;
	customPageSizes?: string[];
}

interface ListPaginationPagesState {
	value?: string;
	onChange: (value: string) => void;
	nextPage: boolean;
}

const ListPagination: FC & { Size: FC<ListPaginationSizeState>; Pages: FC<ListPaginationPagesState> } = (
	props
): ReactElement => {
	const isMobile = useWindowSize().isMobile;

	return (
		<div className={`table-pagination ${isMobile ? "justify-content-between w-100" : "justify-content-start"}`}>
			{props.children}
		</div>
	);
};

const Size: FC<ListPaginationSizeState> = (props): ReactElement => {
	const pageSizes = props.customPageSizes || ["10", "20", "50", "100"];
	const value = props.value ? props.value : "20";
	return (
		<Dropdown as={ButtonGroup} className="me-auto">
			<Dropdown.Toggle variant="light">{value}</Dropdown.Toggle>
			<Dropdown.Menu>
				{pageSizes.map((size, index) => {
					return (
						<Dropdown.Item active={size === value} key={index} onClick={() => props.onChange(size)}>
							{size}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};
const Pages: FC<ListPaginationPagesState> = (props): ReactElement => {
	const value = props.value ? props.value : "0";
	const { t } = useTranslation();
	return (
		<ButtonGroup className="ms-auto">
			<Button
				variant="light"
				disabled={value === "0"}
				onClick={() => props.onChange((parseFloat(value) - 1).toString())}
			>
				{t("lib:common.action.previous")}
			</Button>
			<Button className="current-page" variant="light">
				{Number(value) + 1}
			</Button>
			<Button
				variant="light"
				disabled={!props.nextPage}
				onClick={() => props.onChange((parseFloat(value) + 1).toString())}
			>
				{t("lib:common.action.next")}
			</Button>
		</ButtonGroup>
	);
};

ListPagination.Size = Size;
ListPagination.Pages = Pages;

export default ListPagination;
