import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { PdfOrientationType } from "go-core/types";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { FilterType, ListConfigFilter } from "go-list/core/components/Filter/services/types";
import { selectSelectedFiltersFullNames } from "go-list/list/services/selectors";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
	MenuPriceListGroupItemItemApi,
	SpreadsheetPriceListFormProps,
} from "../../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../../services/Api/api";
import VirtualTableWrapper from "../../../../../../../components/VirtualTable/VirtualTableWrapper";
import {
	VirtualTableListSelectedSort,
	VirtualTableWrapperHandle,
} from "../../../../../../../components/VirtualTable/types";
import PriceListCsvService from "../../../services/PriceListCsvService";
import PriceListPdfService from "../../../services/PriceListPdfService";
import { getInitialSpreadsheetPriceListDefaultColumns, getPossibleSpreadsheetPriceListDefaultColumns } from "../utils";
import PriceListsModal from "./PriceListsModal";
import SpreadsheetPriceListVirtualTable from "./SpreadsheetPriceListVirtualTable";

interface Props {
	items: MenuPriceListFlatApi[];
	priceLists: MenuPriceListApi[];
	handleUpdate: (res: MenuPriceListFlatApi[]) => void;
}

const parsePriceListsToColumns = (priceLists: MenuPriceListApi[]) => {
	return priceLists.map((priceList) => ({
		id: priceList.id.toString(),
		name: priceList.name,
	}));
};

const mapItemPriceLists = (priceLists: MenuPriceListApi[], item: MenuPriceListFlatApi) => {
	const newPriceLists: Record<string, any>[] = [];
	if (priceLists.length > 0) {
		for (let i = 0; i < priceLists.length; i++) {
			const priceListItem = item.price_list_items.find(
				(priceListArrayItem) => priceListArrayItem.price_list_id === priceLists[i].id
			);
			if (priceListItem)
				newPriceLists.push({
					price_list_id: priceListItem.price_list_id,
					price:
						priceListItem.price?.amount || priceListItem.price?.amount === 0
							? priceListItem.price
							: { amount: null },
					name: priceLists[i].name,
				});
			else
				newPriceLists.push({
					price_list_id: priceLists[i].id,
					price: {
						amount: null,
					},
					name: priceLists[i].name,
				});
		}
	}
	return newPriceLists;
};

const SpreadsheetPriceListVirtualTableForm: FC<Props> = ({ items, priceLists, handleUpdate }) => {
	const form = useForm<SpreadsheetPriceListFormProps>({
		criteriaMode: "all",
		defaultValues: {
			items: items.map((item) => ({
				...item,
				price_list_items: mapItemPriceLists(priceLists, item),
			})),
		},
	});
	const { handleSubmit, setValue, getValues, reset } = form;
	const { t } = useTranslation();
	const priceListsColumns = parsePriceListsToColumns(priceLists);
	const defaultColumns = getInitialSpreadsheetPriceListDefaultColumns(t);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const [sortings, setSortings] = useState<VirtualTableListSelectedSort[]>([]);
	const [showPriceListsModal, setShowPriceListsModal] = useState<boolean>(false);
	const [applyPriceToItemIndex, setApplyPriceToItemIndex] = useState<number | undefined>(undefined);
	const filters = [
		{
			id: "category",
			name: t("common.word.item_group_category"),
			type: "list" as FilterType,
			options: Array.from(new Set(items.map((item) => item.category_name))),
			hasDefaultOptions: false,
		},
		{
			id: "item",
			name: t("modules.group_edit.field.item.title"),
			type: "list" as FilterType,
			options: Array.from(new Set(items.map((item) => item.item_name))).filter(
				(item) => item !== t("common.word.default")
			),
		},
		{
			id: "item_full",
			name: t("common.word.item_full_name"),
			type: "list" as FilterType,
			options: Array.from(new Set(items.map((item) => item.item_full_name))),
		},
		{
			id: "item_group",
			name: t("common.word.item_group"),
			type: "list" as FilterType,
			options: Array.from(new Set(items.map((item) => item.item_group_name).filter((itemGroup) => itemGroup))),
			hasDefaultOptions: false,
		},
		{
			id: "modifier_group",
			name: t("modules.group_edit.field.group.title"),
			type: "list" as FilterType,
			options: Array.from(new Set(items.map((item) => item.modifier_group_name).filter((mod) => mod))),
		},
		{
			id: "sub_item",
			name: t("modules.group_edit.field.sub_item.title"),
			type: "list" as FilterType,
			options: Array.from(new Set(items.map((item) => item.sub_item_name).filter((subItem) => subItem))),
		},
		{
			id: "price",
			name: t("modules.group_edit.field.price.title"),
			type: "number",
			typeOptions: ["a", "u"],
		},
	] as ListConfigFilter[];
	const tableWrapperRef = useRef<VirtualTableWrapperHandle>(null);

	const returnSearchedItems = () => {
		const search = tableWrapperRef?.current?.search;
		if (!search) return returnFilteredItems();

		const newItems = returnFilteredItems().filter((item) => {
			if (search === "-") {
				return (
					item.item_name === t("common.word.default") ||
					item.sku === search ||
					item.barcodes.find((barcode) => barcode === search)
				);
			} else if (
				item?.item_group_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.sub_item_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				(item?.item_name?.toLowerCase()?.includes(search.toLowerCase()) &&
					item?.item_name !== t("common.word.default")) ||
				item?.item_full_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.modifier_group_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.category_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item.sku === search ||
				item.barcodes.find((barcode) => barcode === search)
			)
				return item;
			return false;
		});
		return newItems;
	};

	const filterByFilterIdHandler = (filterId: string, filterItems: MenuPriceListFlatApi[]) => {
		const selectedFilters = tableWrapperRef?.current?.selectedFilters || [];
		const priceListColumnsIds = getVisiblePriceListsColumns().map(({ id }) => id);
		const selectedFiltersIds = selectedFilters.map((selectedFilter) => selectedFilter.filterId);
		if (selectedFiltersIds.includes(filterId)) {
			const filterValues = selectedFilters
				.filter((f) => f.filterId === filterId)[0]
				?.value?.split(FILTER_VALUE_SEPARATOR);
			if (filterValues && filterValues.length > 0) {
				if (filterId !== "price") {
					if (filterValues[0] === "u")
						return filterItems.filter(
							(item) =>
								!item[`${filterId}_name` as keyof MenuPriceListFlatApi] ||
								item[`${filterId}_name` as keyof MenuPriceListFlatApi] === t("common.word.default")
						);
					if (filterValues[0] === "a")
						return filterItems.filter(
							(item) =>
								item[`${filterId}_name` as keyof MenuPriceListFlatApi] &&
								item[`${filterId}_name` as keyof MenuPriceListFlatApi] !== t("common.word.default")
						);
				} else {
					if (filterValues[0] === "u")
						return filterItems.filter((item) =>
							item.price_list_items.every(
								(priceList) => !priceListColumnsIds.includes(priceList.price_list_id)
							)
						);
					if (filterValues[0] === "a")
						return filterItems.filter((item) =>
							item.price_list_items.some((priceList) =>
								priceListColumnsIds.includes(priceList.price_list_id)
							)
						);
				}
				const values = filters
					.filter((f) => f.id === filterId)[0]
					?.options?.map((option: string, index: number) => {
						if (filterValues.includes(index.toString())) {
							return option;
						}

						return undefined;
					})
					.filter((x: ListConfigFilter) => x);
				if (filterId !== "price")
					return filterItems.filter((item) =>
						values.includes(item[`${filterId}_name` as keyof MenuPriceListFlatApi])
					);
			}
		}
		return filterItems;
	};

	const returnFilteredItems = () => {
		const selectedFilters = tableWrapperRef?.current?.selectedFilters || [];
		if (selectedFilters.length === 0) return items;

		let newItems = [...items];
		if (filterByFilterIdHandler("category", newItems)) newItems = filterByFilterIdHandler("category", newItems);
		if (filterByFilterIdHandler("item", newItems)) newItems = filterByFilterIdHandler("item", newItems);
		if (filterByFilterIdHandler("item_full", newItems)) newItems = filterByFilterIdHandler("item_full", newItems);
		if (filterByFilterIdHandler("item_group", newItems)) newItems = filterByFilterIdHandler("item_group", newItems);
		if (filterByFilterIdHandler("modifier_group", newItems))
			newItems = filterByFilterIdHandler("modifier_group", newItems);
		if (filterByFilterIdHandler("sub_item", newItems)) newItems = filterByFilterIdHandler("sub_item", newItems);
		if (filterByFilterIdHandler("price", newItems)) newItems = filterByFilterIdHandler("price", newItems);
		return newItems;
	};

	const applyPriceToAll = (itemIndex: number) => {
		priceLists.forEach((priceList, index) => {
			const changeDueToColumnVisible = getVisiblePriceListsColumns()
				.map((c) => c.id)
				.includes(priceList.id || 0);
			changeDueToColumnVisible &&
				setValue(
					`items.${itemIndex}.price_list_items.${index}.price.amount`,
					getValues(`items.${itemIndex}.price.amount`)
				);
		});
	};
	const applyPriceToSelected = (selectedPriceLists: MenuPriceListApi[], itemIndex: number) => {
		priceLists.forEach((priceList, index) => {
			const changeDueToColumnVisible = selectedPriceLists.map((x) => x.id).includes(priceList.id || 0);
			changeDueToColumnVisible &&
				setValue(
					`items.${itemIndex}.price_list_items.${index}.price.amount`,
					getValues(`items.${itemIndex}.price.amount`)
				);
			setShowPriceListsModal(false);
			setApplyPriceToItemIndex(undefined);
		});
	};

	const getParsedDataItemPriceLists = (dataItem: MenuPriceListFlatApi, item: MenuPriceListFlatApi) => {
		const parsedPriceLists: MenuPriceListGroupItemItemApi[] = [];
		(dataItem?.price_list_items || []).forEach((priceListItem) => {
			const existingItemPriceListItem = item?.price_list_items?.find(
				(itemPriceListItem) => itemPriceListItem?.price_list_id === priceListItem?.price_list_id
			);

			if (existingItemPriceListItem) parsedPriceLists.push(existingItemPriceListItem);
			else {
				parsedPriceLists.push({
					price_list_id: priceListItem.price_list_id,
					price: {
						amount: null,
					},
				} as MenuPriceListGroupItemItemApi);
			}
		});
		return parsedPriceLists;
	};

	const arePriceListItemsPricesDifferent = (
		itemPriceLists: MenuPriceListGroupItemItemApi[],
		dataItemPriceLists: MenuPriceListGroupItemItemApi[]
	) => {
		const itemPriceListPrices = itemPriceLists.map((itemPriceList) => itemPriceList?.price?.amount);
		const dataItemPriceListPrices = dataItemPriceLists.map((dataItemPriceList) => dataItemPriceList?.price?.amount);
		return JSON.stringify(dataItemPriceListPrices) !== JSON.stringify(itemPriceListPrices);
	};

	const getParsedDataItems = (data: SpreadsheetPriceListFormProps) => {
		return data.items.filter((dataItem) => {
			const itemToCompare = items.find((item) => item.index === dataItem.index);
			if (!itemToCompare) return undefined;

			const itemToComparePriceLists = getParsedDataItemPriceLists(dataItem, itemToCompare);
			if (
				itemToCompare?.price?.amount !== dataItem?.price?.amount ||
				arePriceListItemsPricesDifferent(itemToComparePriceLists, dataItem?.price_list_items || [])
			) {
				return dataItem;
			}
			return undefined;
		});
	};

	const onSubmit = handleSubmit(async (data: SpreadsheetPriceListFormProps) => {
		data.items = [...getParsedDataItems(data)];
		const params: Record<string, any> = { page: "0", size: "0" };
		await api.organization().updateFlatGroupPriceLists(data, params);
		const res2 = await api.organization().getFlatPriceLists(params);
		handleUpdate(
			res2.items.map((item: MenuPriceListFlatApi, index: number) => ({
				...item,
				item_full_name: item.item_name,
				item_name:
					item.item_name !== item.item_group_name
						? item.item_name.replace(item.item_group_name, "").trim()
						: t("common.word.default"),
				index,
			}))
		);

		reset({
			...res2,
			items: res2.items.map((item: MenuPriceListFlatApi, index: number) => {
				return {
					...item,
					index,
					price_list_items: mapItemPriceLists(priceLists, item),
				};
			}),
		});
	});

	const getVisiblePriceListsColumns = () => {
		const selectedColumns = tableWrapperRef?.current?.selectedColumns || [];
		return priceLists
			.filter((priceList) => selectedColumns?.includes(priceList.id?.toString()))
			.sort((a, b) => selectedColumns.indexOf(a.id.toString()) - selectedColumns.indexOf(b.id.toString()));
	};

	const handleGeneratePdf = (orientation?: PdfOrientationType, fontSize?: string) => {
		PriceListPdfService.generatePdf(
			returnSearchedItems(),
			getVisiblePriceListsColumns(),
			tableWrapperRef?.current?.visibleDefaultColumns || [],
			{
				filename: t("modules.price_list.header.title"),
				title: t("modules.price_list.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
				pdfOrientation: orientation,
				pdfFontSize: fontSize,
			},
			selectSelectedFiltersFullNames(tableWrapperRef?.current?.selectedFilters || [], filters),
			t
		);
	};

	const handleGenerateCsv = () => {
		PriceListCsvService.generateCsv(
			returnSearchedItems(),
			getVisiblePriceListsColumns(),
			tableWrapperRef?.current?.visibleDefaultColumns || [],
			{
				filename: t("modules.price_list.header.title"),
				title: t("modules.price_list.header.title"),
				organization: organization.name,
			}
		);
	};

	return (
		<>
			<PriceListsModal
				isShown={showPriceListsModal}
				onHide={() => setShowPriceListsModal(false)}
				priceLists={priceLists}
				applyPriceToSelected={applyPriceToSelected}
				itemIndex={applyPriceToItemIndex}
			/>
			<VirtualTableWrapper
				defaultColumns={defaultColumns}
				additionalColumns={[{ id: "priceLists", columns: [...priceListsColumns] }]}
				allPossibleDefaultColumns={getPossibleSpreadsheetPriceListDefaultColumns(t)}
				segmentType="GROUP_PRICE_LISTS"
				filters={filters}
				items={returnSearchedItems()}
				form={form}
				emptyListDescription={t("modules.group_edit.field.empty_list_description.title")}
				ref={tableWrapperRef}
				emptyListActions={[
					{
						click: () => history.push(`/${organization.id}/menu/price_lists/new`),
						name: t("modules.group_edit.action.add_price_list.title"),
					},
					{
						click: () => history.push(`/${organization.id}/menu/menus`),
						name: t("modules.group_edit.action.configure_menu.title"),
					},
				]}
				initialItems={items}
				sortings={sortings}
				setSortings={setSortings}
				handleSubmit={onSubmit}
				handleGeneratePdf={handleGeneratePdf}
				handleGenerateCsv={handleGenerateCsv}
				exportConfig={{
					filename: t("modules.price_list.header.title"),
					title: t("modules.price_list.header.title"),
					organization: organization.name,
					taxIdNo: organization?.more?.print_company_on_pdf
						? organization.more?.company_tax_id_no
						: undefined,
					company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
					pdfOrientation: organization?.more?.pdf_orientation,
					pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
				}}
			>
				<AutoSizer>
					{({ height, width }) => (
						<SpreadsheetPriceListVirtualTable
							width={width}
							height={height}
							heightTr={38}
							items={returnSearchedItems()}
							priceLists={priceLists}
							form={form}
							visiblePriceListsColumns={getVisiblePriceListsColumns()}
							applyPriceToAll={applyPriceToAll}
							visibleDefaultColumns={tableWrapperRef?.current?.visibleDefaultColumns || []}
							setShowPriceListsModal={setShowPriceListsModal}
							setApplyPriceToItemIndex={setApplyPriceToItemIndex}
							sortings={sortings}
							setSortings={setSortings}
						/>
					)}
				</AutoSizer>
			</VirtualTableWrapper>
		</>
	);
};

export default SpreadsheetPriceListVirtualTableForm;
