import { PaymentStatus } from "../../../../../../services/Api/Public/types";
import { useOrderActions } from "../../hooks";
import { Actions } from "../Actions/Actions";
import { EmailModal } from "./EmailModal";

interface OrderActionsSectionProps {
	paymentStatus: PaymentStatus;
	orderNumber: string;
}

export const OrderActionsSection = ({ paymentStatus, orderNumber }: OrderActionsSectionProps): JSX.Element => {
	const { actionsRows, isEmailModalOpen, hideEmailModal } = useOrderActions(paymentStatus, orderNumber);

	return (
		<section data-testid="e-order-order-actions-section">
			<Actions actionsRows={actionsRows} />
			<EmailModal isEmailModalOpen={isEmailModalOpen} hideEmailModal={hideEmailModal} />
		</section>
	);
};
