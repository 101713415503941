import React from "react";
import { CompanyFooterInfo, CompanyFooterInfoProps } from "./CompanyFooterInfo";
import { GoPOSFooterInfo } from "./GoPOSFooterInfo";

export const Footer = (props: CompanyFooterInfoProps): JSX.Element => {
	return (
		<footer data-testid="e-order-footer">
			<CompanyFooterInfo {...props} />
			<GoPOSFooterInfo />
		</footer>
	);
};
