import { TFunction } from "react-i18next";

class ReportProductUtils {
	static getGroups = (t: TFunction<"translation", undefined>) => {
		return [
			{
				id: "PRODUCT_CATEGORY_LVL_1",
				name: t("components.reports.groups.PRODUCT_CATEGORY_LVL_1"),
			},
			{
				id: "PRODUCT_CATEGORY_LVL_2",
				name: t("components.reports.groups.PRODUCT_CATEGORY_LVL_2"),
			},
			{
				id: "PRODUCT_CATEGORY_LVL_3",
				name: t("components.reports.groups.PRODUCT_CATEGORY_LVL_3"),
			},
			{
				id: "PRODUCT_CATEGORY_LVL_4",
				name: t("components.reports.groups.PRODUCT_CATEGORY_LVL_4"),
			},
			{
				id: "CREATED_AT_DATE",
				name: t("components.reports.groups.CREATED_AT_DATE"),
			},
			{
				id: "CREATED_AT_YEAR",
				name: t("components.reports.groups.CREATED_AT_YEAR"),
			},
			{
				id: "CREATED_AT_MONTH",
				name: t("components.reports.groups.CREATED_AT_MONTH"),
			},
			{
				id: "CREATED_AT_DAY_OF_MONTH",
				name: t("components.reports.groups.CREATED_AT_DAY_OF_MONTH"),
			},
			{
				id: "CREATED_AT_DAY_OF_WEEK",
				name: t("components.reports.groups.CREATED_AT_DAY_OF_WEEK"),
			},
			{
				id: "CREATED_AT_HOUR_OF_DAY",
				name: t("components.reports.groups.CREATED_AT_HOUR_OF_DAY"),
			},
			{
				id: "CREATED_AT_MINUTE_OF_HOUR",
				name: t("components.reports.groups.CREATED_AT_MINUTE_OF_HOUR"),
			},
			{
				id: "CREATED_AT_SECOND_OF_MINUTE",
				name: t("components.reports.groups.CREATED_AT_SECOND_OF_MINUTE"),
			},
			{
				id: "SKU",
				name: t("components.reports.groups.SKU"),
			},
		];
	};
}

export default ReportProductUtils;
