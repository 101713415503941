import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as CloseButtonSVG } from "../../../../../../../../../../images/svg/liveOrders/close.svg";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../services/context";
import LiveOrdersMapDeliveryEmployeesLegend from "./LiveOrdersMapDeliveryEmployeesLegend";
import LiveOrdersMapDeliveryZonesLegend from "./LiveOrdersMapDeliveryZonesLegend";
import LiveOrdersMapIconSizesLegend from "./LiveOrdersMapIconSizesLegend";
import LiveOrdersMapPreparationStatusesLegend from "./LiveOrdersMapPreparationStatusesLegend";
import LiveOrdersMapStatusesLegend from "./LiveOrdersMapStatusesLegend";

type LiveOrderMapLegendSection = "delivery_zones" | "preparation_statuses" | "statuses" | "employees" | "icon_sizes";

interface Props {
	setShowMapLegend: (value: boolean) => void;
	showOrderList: boolean;
	isOrderSelected: boolean;
	deliveryZones?: DeliveryZoneApi[];
	rightPosition?: number;
	omittedSections?: LiveOrderMapLegendSection[];
}

const LiveOrdersMapLegend: FC<Props> = ({
	setShowMapLegend,
	showOrderList,
	isOrderSelected,
	deliveryZones,
	rightPosition,
	omittedSections,
}) => {
	const isMobile = useWindowSize().isMobile;
	const ordersContext = useContext(OrdersContext);
	const { t } = useTranslation();
	const sections: LiveOrderMapLegendSection[] = [
		"delivery_zones",
		"preparation_statuses",
		"statuses",
		"employees",
		"icon_sizes",
	];

	const getRightPosition = (): string => {
		if (!isMobile) {
			if (rightPosition !== undefined) return `${rightPosition}px`;
			if (!showOrderList) return "82px";
			if (isOrderSelected) return "406px";
			return "416px";
		}
		return "0";
	};

	const onClose = () => setShowMapLegend(false);

	const deliveryZonesToDraw = deliveryZones ?? ordersContext.deliveryZones;

	const shouldDrawSectionBreakLine = (index: number) => {
		if (index === sections.length - 1) return false;
		return !omittedSections ? true : index > omittedSections.length - 1;
	};

	const getSectionElementToRender = (section: LiveOrderMapLegendSection) => {
		switch (section) {
			case "delivery_zones":
				return <LiveOrdersMapDeliveryZonesLegend deliveryZones={deliveryZonesToDraw} />;
			case "preparation_statuses":
				return <LiveOrdersMapPreparationStatusesLegend />;
			case "statuses":
				return <LiveOrdersMapStatusesLegend />;
			case "employees":
				return <LiveOrdersMapDeliveryEmployeesLegend />;
			case "icon_sizes":
				return <LiveOrdersMapIconSizesLegend />;
			default:
				return <></>;
		}
	};

	const drawSections = () => {
		return sections.map((section, index) => {
			const shouldNotRender =
				section !== "delivery_zones"
					? omittedSections?.includes(section)
					: omittedSections?.includes(section) && deliveryZonesToDraw.length > 0;

			if (!shouldNotRender) {
				return (
					<React.Fragment key={section}>
						{getSectionElementToRender(section)}
						{shouldDrawSectionBreakLine(index) && <hr />}
					</React.Fragment>
				);
			}
			return <></>;
		});
	};

	return (
		<div
			className={`live-orders-map-legend ${isMobile ? "live-orders-map-legend--mobile" : ""}`}
			style={{ right: getRightPosition() }}
		>
			<div className="live-orders-map-legend-header d-flex justify-content-between">
				<h5>{t("modules.live_order.field.legend.title")}</h5>
				<CloseButtonSVG className="cursor-pointer" onClick={onClose} />
			</div>
			<div className="live-orders-map-legend-box">{drawSections()}</div>
		</div>
	);
};

export default LiveOrdersMapLegend;
