import { ReportApi, SubReportApi } from "go-report/core/services/types";
import { DashboardChartActions } from "../services/charts/reducer";
import { ReportType } from "../services/charts/types";

const getDashboardChartActionType = (type: ReportType): DashboardChartActions => {
	return `change_${type}` as DashboardChartActions;
};

const mapToReport = (subReport: { sub_report: SubReportApi[] }): ReportApi => {
	return {
		reports: [
			{
				sub_report: subReport.sub_report,
			},
		],
	} as ReportApi;
};

export { getDashboardChartActionType, mapToReport };
