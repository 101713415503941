import { DataAttributesCompany } from "react-use-intercom";
import { OrganizationState } from "../organizations/types";
import { UserState } from "../users/types";

let initalized = false;

class IntercomSecurity {
	static initialize(): void {
		initalized = true;
	}

	static isInitialized(): boolean {
		return initalized;
	}

	static getIntercomId(user: UserState): string {
		return user?.more?.intercom?.id;
	}

	static userHash(user: UserState): string {
		return user?.more?.intercom?.hash_web;
	}

	static email(user: UserState): string {
		return user?.more?.intercom?.user_email;
	}

	static alias(user: UserState): string {
		return user.name ? user.name : user?.more?.intercom?.user_email;
	}

	static companyId(organization: OrganizationState): string {
		return organization.more?.intercom?.company_id;
	}

	static cfg(user: UserState, organization: OrganizationState): Record<string, any> | undefined {
		if (!this.getIntercomId(user) || !this.email(user)) return undefined;
		let intercom = {
			email: this.email(user),
			name: this.alias(user),
			userHash: this.userHash(user),
			company: {} as DataAttributesCompany,
			userId: this.email(user),
		};
		const companyId = this.companyId(organization);
		if (companyId) {
			intercom = {
				...intercom,
				company: {
					companyId,
					name: organization.name,
				},
			};
		}
		return intercom;
	}
}

export { IntercomSecurity };
