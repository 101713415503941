import { useEffect } from "react";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/pl";
import "moment/locale/uk";
import "moment/locale/vi";

export const useMomentLocaleForUser = (user?: Record<string, any>) => {
	useEffect(() => {
		if (user?.organizations) {
			const everyOrganizationLanguages = user.organizations.flatMap(
				(organization: Record<string, any>) => organization.languages
			);
			const userPossibleLanguages = new Set(everyOrganizationLanguages);
			const { language: browserLanguage } = navigator;
			const pureBrowserLanguage = browserLanguage.includes("-") ? browserLanguage.split("-")[0] : browserLanguage;
			let momentLocaleLanguage = "";
			if (userPossibleLanguages.has(pureBrowserLanguage)) {
				momentLocaleLanguage = pureBrowserLanguage;
			} else {
				momentLocaleLanguage = "en";
			}
			moment.locale(momentLocaleLanguage);
		}
	}, [user]);
};

export const useMomentLocaleForLocation = (organization?: Record<string, any>) => {
	useEffect(() => {
		if (organization?.languages) {
			const { language: browserLanguage } = navigator;
			const pureBrowserLanguage = browserLanguage.includes("-") ? browserLanguage.split("-")[0] : browserLanguage;
			let momentLocaleLanguage = "";
			if (organization.languages.includes(pureBrowserLanguage)) {
				momentLocaleLanguage = pureBrowserLanguage;
			} else {
				momentLocaleLanguage = "en";
			}
			moment.locale(momentLocaleLanguage);
		}
	}, [organization]);
};
