class RenderColumnUtils {
	static getMoneyStyles = () => {
		return { textAlign: "right", whiteSpace: "nowrap" };
	};

	static getPdfStyles = () => {
		return {
			bold: {
				bold: true,
			},
			small: {
				fontSize: 6,
				color: "#9b9b9b",
			},
			header: {
				fontSize: 8,
				bold: true,
			},
			subheader: {
				fontSize: 8,
				bold: true,
				margin: [0, 10, 0, 5],
			},
			tableExample: {
				margin: [0, 10, 0, 0],
			},
			tableHeader: {
				bold: true,
				fontSize: 10,
				color: "#333a38",
			},
			tableCell: {
				bold: false,
				fontSize: 9,
				color: "#464e4c",
			},
			tableCellNested: {
				bold: false,
				fontSize: 9,
				color: "#464e4c",
				fillColor: "#bcc0c7",
			},
			evenDraftCell: {
				bold: false,
				fontSize: 9,
				color: "#464e4c",
				fillColor: "#F0F0F0",
			},
			draftCell: {
				bold: false,
				fontSize: 9,
				color: "#464e4c",
			},
		};
	};
	static getPdfStylesSmall = () => {
		return {
			bold: {
				bold: true,
			},
			small: {
				fontSize: 5,
				color: "#9b9b9b",
			},
			header: {
				fontSize: 7,
				bold: true,
			},
			subheader: {
				fontSize: 7,
				bold: true,
				margin: [0, 10, 0, 5],
			},
			tableExample: {
				margin: [0, 10, 0, 0],
			},
			tableHeader: {
				bold: true,
				fontSize: 9,
				color: "#333a38",
			},
			tableCell: {
				bold: false,
				fontSize: 8,
				color: "#464e4c",
			},
			tableCellNested: {
				bold: false,
				fontSize: 8,
				color: "#464e4c",
				fillColor: "#bcc0c7",
			},
			evenDraftCell: {
				bold: false,
				fontSize: 8,
				color: "#464e4c",
				fillColor: "#F0F0F0",
			},
			draftCell: {
				bold: false,
				fontSize: 8,
				color: "#464e4c",
			},
		};
	};
	static parsePdfMoneyStyles = (styles?: Record<string, any>) => {
		if (styles) {
			const newStyles = { ...styles };
			Object.keys(newStyles).forEach((key) => {
				if (key === "textAlign") {
					newStyles.alignment = newStyles.textAlign;
					delete newStyles.textAlign;
				}
				if (key === "whiteSpace") delete newStyles.whiteSpace;
			});
			return newStyles;
		}
	};
}

export default RenderColumnUtils;
