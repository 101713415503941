import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { ButtonProps } from "react-bootstrap/Button";

interface Props extends ButtonProps {
	loading: boolean;
}

export const ButtonLoading = (props: Props): JSX.Element => {
	const { loading, ...newProps } = props;
	return (
		<Button {...newProps} disabled={props.disabled ? props.disabled : Boolean(loading)}>
			{loading && (
				<Spinner
					as="span"
					animation="border"
					size="sm"
					role="status"
					aria-hidden="true"
					data-testid="loadingSpinner"
				/>
			)}
			{newProps.children}
		</Button>
	);
};
