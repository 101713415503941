import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { MenuPriceListApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../../../utils/entityStatus/entityStatus";
import SubmenuPriceListsNavigation from "../../../../components/SubmenuPriceListsNavigation";
import { getInitialSpreadsheetPriceListDefaultColumns } from "../../../GroupEditing/pages/Spreadsheet/utils";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "MENU_PRICE_LIST";
const resourceType = "MENU_PRICE_LIST";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const confirmation = useConfirmation();
	const organization = useSelector(selectOrganization);
	const { addSuccessFlash, addFlash } = useFlash();
	const [items, setItems] = useState<MenuPriceListApi[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (refreshParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(refreshParams);
			setItems(res);
		}
	};

	const onRemovePriceList = async (item: MenuPriceListApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removePriceList(item.id);
			onRefresh(params);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			console.error(e);
		}
	};

	const onRestorePriceList = async (item: MenuPriceListApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restorePriceList(item.id);
			onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onDisablePriceList = async (item: MenuPriceListApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.disable", { ns: "lib" }),
			});
			await api.organization().disablePriceList(item.id);
			onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onEditPrices = (item: MenuPriceListApi) => {
		const defaultColumns = getInitialSpreadsheetPriceListDefaultColumns(t).map((col) => col.id);
		const columns = [...defaultColumns, item.id];
		history.push({
			pathname: `/${organization.id}/menu/price_lists/group_editing`,
			search: `c=${columns.join(",")}`,
		});
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text" as FilterType,
				render: (item: MenuPriceListApi) => (
					<StickyColumnWithEntityStatus
						minWidth={200}
						to={`${location.pathname}/${item.id}`}
						status={convertListStatusToEntityStatus(item.status)}
					>
						{item.name}
					</StickyColumnWithEntityStatus>
				),
				renderExport: (item: MenuPriceListApi) => item.name,
			},
			{
				id: "availability",
				name: t("common.word.availability"),
				render: (item: MenuPriceListApi) => item?.availability?.name,
			},
		],
		filters: [
			{
				id: "availability",
				name: t("common.word.availability"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getAvailabilitiesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list" as FilterType,
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DISABLED: t("enums.common.status.DISABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		selectedColumns: ["name", "availability"],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: MenuPriceListApi) => `${location.pathname}/${item.id}`,
			},
			{
				name: t("modules.price_list.action.edit_prices.title"),
				click: (item: MenuPriceListApi) => onEditPrices(item),
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: MenuPriceListApi) => onRemovePriceList(item),
				visible: (item: MenuPriceListApi) => item.status !== "DELETED",
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: MenuPriceListApi) => onDisablePriceList(item),
				visible: (item: MenuPriceListApi) => item.status === "ENABLED",
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: MenuPriceListApi) => onRestorePriceList(item),
				visible: (item: MenuPriceListApi) => item.status === "DISABLED" || item.status === "DELETED",
			},
		],
		multipleActions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: async (
					multipleActionsItems: MenuPriceListApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.price_list.field.remove_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include = "availability";

						await api
							.organization()
							.removePriceLists(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
						onRefresh(formattedParams);
					} catch (e) {
						console.error(e);
					}
				},
				visible: (multipleActionsitems: MenuPriceListApi[]) => {
					return multipleActionsitems.filter((f) => f.status !== "DELETED").length > 0;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: async (
					multipleActionsItems: MenuPriceListApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.price_list.field.activate_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include = "availability";

						await api
							.organization()
							.restorePriceLists(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsitems: MenuPriceListApi[]) => {
					return (
						multipleActionsitems.filter((f) => f.status === "DELETED" || f.status === "DISABLED").length > 0
					);
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: async (
					multipleActionsItems: MenuPriceListApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.price_list.field.disable_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include = "availability";

						await api
							.organization()
							.disablePriceLists(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						onRefresh(formattedParams);
					} catch (e) {
						console.error(e);
					}
				},
				visible: (multipleActionsitems: MenuPriceListApi[]) => {
					return multipleActionsitems.filter((f) => f.status === "ENABLED").length > 0;
				},
			},
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.price_list.field.export_config.title"),
			filename: t("modules.price_list.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "availability";
			setParams(fetchParams);
			return api.organization().getPriceLists(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		fetchTotalItemsCountForSelectedFilters: (
			fetchTotalItemsParams: Record<string, any>,
			sourceToken?: CancelTokenSource
		) => api.organization().getPriceListCount(fetchTotalItemsParams, { cancelToken: sourceToken?.token }),

		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.externalSegments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			config={config}
			data={items}
			onFetch={(fetchItems: MenuPriceListApi[]) => setItems(fetchItems)}
			emptyList={
				<EmptyList
					title={t("modules.price_list.header.title")}
					description={t("modules.price_list.field.empty_list_description.title")}
					actions={[
						{
							name: `+ ${t("common.action.add", { ns: "lib" })}`,
							click: () => {
								history.push(`${location.pathname}/new`);
							},
						},
					]}
				/>
			}
			mobileActions={mobileActions}
			canManageMultiActionsForAllItems
		/>
	);
};

const OrganizationMenuPriceListsIndexIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.price_list.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.price_list.header.title")} buttons={buttons} />}
			<SubmenuPriceListsNavigation />
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};

export default OrganizationMenuPriceListsIndexIndexPage;
