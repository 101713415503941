import { TFunction } from "react-i18next";
import { ItemOrderType } from "../../../../../../../services/Api/Organization/types";
import { LiveOrderFilterOption, LiveOrdersFilters } from "./types";

export const orderStatusOptions = (t: TFunction): LiveOrderFilterOption[] => [
	{
		id: "ALL",
		label: t("lib:common.word.all"),
	},
	{
		id: "ACTIVE",
		label: t("modules.live_order.field.active.title"),
	},
	{
		id: "NEW",
		label: t("modules.live_order.field.new.title"),
	},
	{
		id: "IN_PROGRESS",
		label: t("modules.live_order.field.in_progress.title"),
	},
	{
		id: "READY",
		label: t("modules.live_order.field.ready.title"),
	},
	{
		id: "PACKED",
		label: t("modules.live_order.field.packed.title"),
	},
	{
		id: "RECEIVED",
		label: t("modules.live_order.field.received.title"),
	},
	{
		id: "DELIVERED",
		label: t("modules.live_order.field.delivered.title"),
	},
	{
		id: "EXTERNAL",
		label: t("modules.live_order.field.external.title"),
	},
];

export const orderTimeOptions = (t: TFunction): LiveOrderFilterOption[] => [
	{
		id: "6",
		label: `6h (${t("modules.live_order.field.default.title")})`,
	},
	{
		id: "12",
		label: "12h",
	},
	{
		id: "24",
		label: "24h",
	},
	{
		id: "48",
		label: `2 ${t("modules.live_order.field.days.title")}`,
	},
	{
		id: "168",
		label: `7 ${t("modules.live_order.field.days.title")}`,
	},
];

export const orderTypeOptions = (t: TFunction): LiveOrderFilterOption[] => [
	{
		id: ItemOrderType.DINE_IN,
		label: t("enums.orders.types.DINE_IN"),
	},
	{
		id: ItemOrderType.ROOM_SERVICE,
		label: t("enums.orders.types.ROOM_SERVICE"),
	},
	{
		id: ItemOrderType.PICK_UP,
		label: t("enums.orders.types.PICK_UP"),
	},
	{
		id: ItemOrderType.DELIVERY,
		label: t("enums.orders.types.DELIVERY"),
	},
];

export const getDefaultOrdersFilters = (t: TFunction): LiveOrdersFilters => ({
	status: orderStatusOptions(t)[1],
	search: "",
	time: orderTimeOptions(t)[0],
	employeesLastActivity: deliveryEmployeesLastActivityOptions(t)[0],
	deliveryEmployee: null,
	type: null,
});

export const deliveryEmployeesLastActivityOptions = (t: TFunction): LiveOrderFilterOption[] => [
	{
		id: "1",
		label: `1h (${t("modules.live_order.field.default.title")})`,
	},
	{
		id: "3",
		label: "3h",
	},
	{
		id: "6",
		label: "6h",
	},
	{
		id: "12",
		label: "12h",
	},
	{
		id: "24",
		label: "24h",
	},
];
