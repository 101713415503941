import { useSelector } from "react-redux";
import RequestItemsList from "go-component/components/RequestItem/RequestItemsList";
import { selectOrganization } from "go-security/services/organizations/selectors";

const OrganizationLogsRequestItemsIndexPage = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const taxIdNo = organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined;
	const companyName = organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined;
	const pdfOrientation = organization?.more?.pdf_orientation;
	const pdfFontSize = organization?.more?.default_pdf_font_size?.toString();

	return (
		<RequestItemsList
			organization_name={organization.name || ""}
			companyName={companyName}
			taxIdNo={taxIdNo}
			pdfOrientation={pdfOrientation}
			pdfFontSize={pdfFontSize}
		/>
	);
};
export default OrganizationLogsRequestItemsIndexPage;
