import { FC } from "react";
import { ReactComponent as ChairSVG } from "../../../../../../../../../../../../images/svg/rooms/chair.svg";
import { RoomTableApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	name?: string;
	guestAmount?: number;
	styles?: Record<string, any>;
	handleCreate?: (roomTable: RoomTableApi) => void;
}

const Rectangle: FC<Props> = ({ name, guestAmount, styles, handleCreate }) => {
	const onCreate = () => {
		const obj = {
			type: "RECTANGLE",
			width: 132,
			height: 96,
			number_of_seats: guestAmount,
			position_top: 50,
			position_left: 50,
			name: "",
		} as RoomTableApi;
		if (handleCreate) {
			handleCreate(obj);
		}
	};

	return (
		<div className={"table-box box-RECTANGLE"} style={styles} onClick={onCreate}>
			{guestAmount && guestAmount > 0 && (
				<div className={"box-chair"}>
					<ChairSVG /> {guestAmount}
				</div>
			)}
			<div className={"box-small"}>{name ? name : "#"}</div>
		</div>
	);
};
export default Rectangle;
