import React, { useEffect, useState } from "react";
import { LoadingContainer } from "go-core/components/Loading";
import ReportCombo from "../../ReportCombo";
import { ReportComboContext } from "../../services/context";
import { KpisConfigReportComboApi } from "../../services/types";
import ReportComboUtils from "../../services/utils";
import BaseVariableReportCombo from "./Variable/BaseVariableReportCombo";
import QuarterReportCombo from "./Variable/QuarterReportCombo";
import { TemplateReportCombo } from "./types";

const parseConfigValue = (value: string, replaces: Record<string, string>) => {
	let newValue = value;
	Object.entries(replaces).forEach(([key, replace]) => {
		if (replace) {
			newValue = newValue.replace(`{${key}}`, replace);
		}
	});
	return newValue;
};

const parseConfig = (configs: KpisConfigReportComboApi, replaces: Record<string, string>) => {
	const newConfigs: KpisConfigReportComboApi = JSON.parse(JSON.stringify(configs));

	newConfigs.kpis.forEach((config) => {
		config.data.forEach((configData) => {
			configData.filter = parseConfigValue(configData.filter, replaces);
			configData.columns.forEach((configDataColumn) => {
				configDataColumn.filter = parseConfigValue(configDataColumn.filter, replaces);
				if (configDataColumn.compare?.filter) {
					configDataColumn.compare.filter = parseConfigValue(configDataColumn.compare?.filter, replaces);
				}
			});
			if (configData.groups) {
				configData.groups = configData.groups.map((configDataGroup) => {
					return parseConfigValue(configDataGroup, replaces);
				});
			}
		});
		config.rows.forEach((configRow) => {
			if (configRow.group) configRow.group = parseConfigValue(configRow.group, replaces);
		});
	});

	return newConfigs;
};

const parseTemplates = (template: TemplateReportCombo): KpisConfigReportComboApi => {
	const kpis = template.templates.flatMap((x) => x.data);
	return {
		kpis,
	};
};

interface Props {
	template: TemplateReportCombo;
	onChangeFilter?: (filter: Record<string, string>) => void;
}

const TemplateReportComboComponent = (props: Props) => {
	const config = parseTemplates(props.template);
	const [filter, setFilter] = useState<Record<string, string> | undefined>(props.template.filter);
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(true);
	const configParsed = parseConfig(config, filter ? filter : {});
	const variableIds = ReportComboUtils.findVariablesInString(JSON.stringify(config));
	const reportComboService = React.useContext(ReportComboContext);

	useEffect(() => {
		setLoading(false);
	}, []);

	if (loading) {
		return <LoadingContainer />;
	}

	const ids = ReportComboUtils.findVariablesInString(JSON.stringify(configParsed));

	const updateFilters = (value: Record<string, string>) => {
		const newFilter = filter ? { ...filter, ...value } : { ...value };
		const keys = Object.keys(newFilter);
		keys.forEach((key) => {
			if (!variableIds.includes(key)) {
				delete newFilter[key];
			}
		});

		setFilter(newFilter);
		if (props.onChangeFilter) props.onChangeFilter(newFilter);
	};

	const isPossibleFetch = () => {
		if (!ids || ids.length <= 0) return true;
		if (!filter) return false;
		return ids.every((x) => filter[x] !== undefined && filter[x]);
	};

	const renderVariableFilters = (variableId: string) => {
		const filterType = variableId.split("__")[1];

		const filterVariableInput = reportComboService.getFilterVariableComponent(
			variableId,
			(value) => updateFilters(value),
			filter
		);
		if (filterVariableInput) return filterVariableInput;
		switch (filterType) {
			case "QUARTER": {
				return (
					<>
						<QuarterReportCombo
							name={variableId}
							filter={filter}
							onChange={(value) => updateFilters(value)}
						/>
						<hr />
					</>
				);
			}
			default: {
				return (
					<>
						<BaseVariableReportCombo filter={filter} name={variableId} onChange={updateFilters} />
						<hr />
					</>
				);
			}
		}
	};

	return (
		<>
			{variableIds.map((variableId) => {
				return <React.Fragment key={variableId}>{renderVariableFilters(variableId)}</React.Fragment>;
			})}

			{!isPossibleFetch() ? (
				<>
					Wybierz wymagane filtry:
					{ids.join(", ")}
				</>
			) : (
				<>
					{show && (
						<>
							{configParsed.kpis.map((kpi) => (
								<div key={kpi.id}>
									<ReportCombo config={kpi} key={kpi.id} />
								</div>
							))}
						</>
					)}
				</>
			)}
		</>
	);
};

export default TemplateReportComboComponent;
