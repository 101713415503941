import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DeliveryZoneApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	deliveryZone: DeliveryZoneApi;
	color: string;
}

const DeliveryZoneMapLegendItem = ({ deliveryZone, color }: Props) => {
	const organization = useSelector(selectOrganization);

	return (
		<div className="delivery-zone-form-map-legend-item">
			<div className="delivery-zone-form-map-legend-item-box" style={{ backgroundColor: color }} />
			<Link target="_blank" to={`/${organization.id}/settings/delivery_zones/${deliveryZone.id}`}>
				{deliveryZone.name}
			</Link>
		</div>
	);
};

export default DeliveryZoneMapLegendItem;
