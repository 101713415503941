import React, { FC, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import {
	formatStringToDate,
	isFilterDateFromSelected,
	isFilterDateToSelected,
	isValidDate,
	parseDateToDefaultString,
} from "go-core/components/Formatters/FormatDate";
import { DatePicker } from "go-form/components/DatePicker";
import {
	isPredefinedRange,
	parsePredefinedRangeToRange,
	parseRangeToDefaultString,
	parseStringToRange,
} from "../../../../../utils/daterangeutils";
import { FILTER_VALUE_SEPARATOR, dateFilterOptions, fullDateFilterOptions, getLimitedOptions } from "../../services";
import {
	FilterCondition,
	FilterCustomAction,
	FilterDateRange,
	ListConfigFilter,
	ListConfigFilterOption,
	ListSelectedFilter,
} from "../../services/types";
import ListFilterDateRange3 from "../DateRange3";

interface State {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	onChange: (condition: FilterCondition, selectedFilters: string, autoApply?: boolean) => void;
	minDate?: Date | string;
	maxDate?: Date | string;
	dateRangePickerCustomActions?: FilterCustomAction[];
	dateRangePickerCustomRanges?: FilterDateRange[];
}

const parseToFilterRequest = (value: string) => {
	const range = parseStringToRange(value);
	if (!range || !range.startDate || !range.endDate) return "";
	const valueDate = `${parseDateToDefaultString(range.startDate)}${FILTER_VALUE_SEPARATOR}${parseDateToDefaultString(
		range.endDate
	)}`;
	return valueDate;
};
const parseFilesRequestToValue = (value: string) => {
	if (typeof value !== "string") return "";

	if (isPredefinedRange(value)) return value;
	return value.replace(FILTER_VALUE_SEPARATOR, " - ");
};

const getTypeInitialValue = (
	options: ListConfigFilterOption[],
	condition?: FilterCondition,
	typeOptions?: FilterCondition[]
): FilterCondition => {
	if (condition) {
		if (condition === "btd") return "bt";

		const typeOptionsIncludeOldTypeOptions = typeOptions?.some(
			(typeOption) => typeOption === "lt" || typeOption === "gt"
		);

		if (typeOptionsIncludeOldTypeOptions) {
			if (condition === "lte") return "lt";
			if (condition === "gte") return "gt";
		}

		return condition;
	}

	return options[0].id;
};

const changeOptionsConditionsBasedOnTypeOptions = (
	options: ListConfigFilterOption[],
	typeOptions?: FilterCondition[]
) => {
	if (!typeOptions) return options;

	const newOptions = options.map((option) => {
		const newTypeOption = typeOptions.find((typeOption) => option.id.includes(typeOption));

		if (newTypeOption) return { ...option, id: newTypeOption };
		return option;
	});
	return newOptions;
};

const getOptions = (filter: ListConfigFilter, t: TFunction) => {
	const { options, typeOptions, extraOptions } = filter;
	let newOptions = options
		? dateFilterOptions(t)
				.filter((item) => options?.indexOf(item.id) !== -1)
				.sort((a, b) => options.indexOf(a) - options.indexOf(b))
		: dateFilterOptions(t);

	newOptions = changeOptionsConditionsBasedOnTypeOptions(newOptions, typeOptions);

	newOptions = getLimitedOptions(
		fullDateFilterOptions(newOptions, t),
		newOptions.map((o) => o.id as FilterCondition),
		typeOptions,
		extraOptions
	);

	return newOptions;
};

const ListFilterDate: FC<State> = (props) => {
	const { t } = useTranslation();
	const options = getOptions(props.filter, t);
	const [value, setValue] = useState(props.selectedFilter.value || "");
	const [type, setType] = useState<FilterCondition>(() =>
		getTypeInitialValue(options, props.selectedFilter.condition, props.filter.typeOptions)
	);
	useEffect(() => {
		setValue(props.selectedFilter.value);
	}, [props.selectedFilter.value]);

	const parseValueToDate = (value: string) => {
		const date = formatStringToDate(value);
		if (isValidDate(date)) return date;
		return undefined;
	};
	const isBt = (value: string) => {
		if (value === "bt" || value === "btd") return true;
		return false;
	};

	const onInputClick = (value: string, evt?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		evt?.stopPropagation();
		onChangeType(value as FilterCondition);
	};

	const onChangeValue = (date?: Date | string, autoApply?: boolean) => {
		if (typeof date === "string") {
			setValue("");
			props.onChange(type, "");
			return;
		}
		if (date) {
			let value = parseDateToDefaultString(date);
			value = getFilterValueSecondsBasedOnFilterCondition(value, type);
			props.onChange(type, value, autoApply);
			setValue(value);
		}
	};

	const getFilterValueSecondsBasedOnFilterCondition = (filterValue: string, type: FilterCondition) => {
		if (isFilterDateToSelected(type)) {
			return changeFilterValueSeconds(filterValue, "59");
		} else if (isFilterDateFromSelected(type)) {
			return changeFilterValueSeconds(filterValue, "00");
		}

		return filterValue;
	};

	const changeFilterValueSeconds = (filterValue: string, newSeconds: string) => {
		const valueWithoudSeconds = filterValue.substring(0, filterValue.length - 2);
		return `${valueWithoudSeconds}${newSeconds}`;
	};

	const getFilterValue = (type: FilterCondition, alreadySelectedFilterValue?: string) => {
		let newValue = alreadySelectedFilterValue ?? "";

		if (!newValue) return newValue;

		newValue = getFilterValueSecondsBasedOnFilterCondition(newValue, type);

		return newValue;
	};

	const onChangeType = (newType: FilterCondition, autoApply?: boolean) => {
		let val = getFilterValue(newType, value);
		let newValue = "";

		if (isPredefinedRange(val)) {
			const customRange = parsePredefinedRangeToRange(val);

			val = customRange ? parseRangeToDefaultString(customRange) : "";
		}

		if (isBt(type)) {
			if (newType === "gte" || newType === "gt") {
				newValue = val.split(FILTER_VALUE_SEPARATOR)[0];
			} else if (newType === "lte" || newType === "lt") {
				if (val.includes(FILTER_VALUE_SEPARATOR)) {
					newValue = val.split(FILTER_VALUE_SEPARATOR)[1];
				}
			} else {
				newValue = val;
			}
		} else {
			if (isBt(newType)) {
				if (val === "") {
					newValue = "";
				} else {
					newValue = `${val}${FILTER_VALUE_SEPARATOR}${val}`;
				}
			} else {
				newValue = val;
			}

			if (type === "a" || type === "u") {
				newValue = "";
			}
		}

		setType(newType);
		setValue(newValue);

		if (newType === "bt" && !newValue.includes(FILTER_VALUE_SEPARATOR)) {
			props.onChange("btd", newValue, autoApply);
		} else {
			props.onChange(newType, newValue, autoApply);
		}
	};

	const onChangeRange = (range: string, predefinedRange?: string, autoApply?: boolean) => {
		if (predefinedRange) {
			props.onChange("btd", predefinedRange, autoApply);
		} else {
			props.onChange(type, `${parseToFilterRequest(range)}`, autoApply);
		}
	};

	const renderOption = (option: ListConfigFilterOption) => {
		return (
			<React.Fragment key={`type_${props.id}_${option.id}`}>
				<Dropdown.Item
					key={`type_${props.id}_${option.id}`}
					onClick={() => onChangeType(option.id as FilterCondition)}
				>
					<Form.Check
						type="radio"
						className="form-check"
						label={option.name}
						name={`type_${props.id}_${option.id}`}
						id={`type_${props.id}_${option.id}`}
						value={option.id}
						checked={type === option.id}
						onClick={(e) => onInputClick(e.currentTarget.value as FilterCondition, e)}
					/>
				</Dropdown.Item>
				{type === option.id && type !== "bt" && option.isValue && (
					<div className={"list-date-filter-wrapper"}>
						<DatePicker
							name={`type_${props.id}_${option.id}`}
							formPicker={true}
							onApply={(val) => onChangeValue(val, true)}
							timePicker={true}
							date={parseValueToDate(value)}
							dayPicker={true}
							minDate={props.minDate}
							maxDate={props.maxDate}
							onChange={onChangeValue}
						/>
					</div>
				)}
				{type === option.id && type === "bt" && option.isValue && (
					<div className={"list-date-filter-wrapper"}>
						<ListFilterDateRange3
							value={parseFilesRequestToValue(value)}
							onChange={onChangeRange}
							minDate={props.minDate}
							maxDate={props.maxDate}
							customActions={props.dateRangePickerCustomActions}
							customRanges={props.dateRangePickerCustomRanges}
						/>
					</div>
				)}
			</React.Fragment>
		);
	};

	useEffect(() => {
		if (isPredefinedRange(value)) {
			props.onChange("btd", value, true);
		} else if (value.includes(FILTER_VALUE_SEPARATOR)) {
			props.onChange(type, value, true);
		} else {
			onChangeValue(parseValueToDate(value), true);
		}
	}, []);

	return (
		<>
			<Form.Group className="form-group">{options.map(renderOption)}</Form.Group>
		</>
	);
};

export default ListFilterDate;
