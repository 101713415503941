let errorSessionId: string | undefined = undefined;
const get = (): string | undefined => {
	return errorSessionId;
};
const update = (value?: string): void => {
	errorSessionId = value;
};
const clear = (): void => {
	errorSessionId = undefined;
};
export const AuthCache = { get, update, clear };
export default AuthCache;
