import { valueEncoder } from "go-list/core/components/Actions/services";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn } from "../../../../../components/VirtualTable/types";

class PriceListCsvService {
	static generateCsv = (
		items: MenuPriceListFlatApi[],
		priceLists: MenuPriceListApi[],
		defaultColumns: VirtualTableColumn[],
		exportConfig: ExportConfig
	) => {
		let csvContent = "data:text/csv;charset=UTF-8,";
		const rows: string[][] = [];
		const thead: string[] = [];
		defaultColumns.forEach((column) => {
			thead.push(`"${column.name}"`);
		});
		priceLists.forEach((priceList) => {
			thead.push(`"${valueEncoder(priceList.name)}"`);
		});
		rows.push(thead);
		items.forEach((item) => {
			const bodyRow: string[] = [];
			defaultColumns.forEach((col) => {
				let val = "";
				if (col.id === "price") {
					val = item.price?.amount?.toString() || "";
				} else {
					val = item[`${col.id}` as keyof MenuPriceListFlatApi]?.toString() || "";
				}
				bodyRow.push(valueEncoder(`"${val}"`));
			});
			priceLists.forEach((priceList) => {
				const money = item.price_list_items.find((f) => f.price_list_id === priceList.id)?.price;
				let val = "";
				if (money) {
					val = money.amount?.toString() || "";
				} else {
					val = "";
				}
				bodyRow.push(valueEncoder(`"${val}"`));
			});
			rows.push(bodyRow);
		});
		csvContent += rows.map((e) => e.join(",")).join("\n");
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${exportConfig?.filename}.csv`);
		document.body.appendChild(link); // Required for FF
		link.click();
	};
}

export default PriceListCsvService;
