import { AxiosResponse } from "axios";
import { SegmentApi, UpdateSegmentApi } from "go-segment/services/types";
import { restSecurityApi } from "../api";

class apiUserSegment {
	static getListInfo(params?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get("/lists", { params });
	}

	static saveSegment(segment: SegmentApi): Promise<AxiosResponse> {
		return restSecurityApi.post("/segments", segment);
	}

	static updateSegment(segment: SegmentApi): Promise<AxiosResponse> {
		return restSecurityApi.put(`/segments/${segment.id}`, segment);
	}

	static restoreSegment(segment: SegmentApi): Promise<AxiosResponse> {
		return restSecurityApi.put(`/segments/${segment.id}/restore`);
	}

	static removeSegment(segmentId: number): Promise<AxiosResponse> {
		return restSecurityApi.delete(`/segments/${segmentId}`);
	}

	static getSegments(params?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get("/segments", { params });
	}

	static async setSegmentAsDefault(segmentId: number) {
		return restSecurityApi.put(`/segments/${segmentId}/make_default`);
	}

	static async setSegmentNotDefault(segmentId: number) {
		return restSecurityApi.put(`/segments/${segmentId}/make_not_default`);
	}

	static async updateSegments(data: UpdateSegmentApi, params?: Record<string, any>) {
		return restSecurityApi.patch(`/segments`, data, { params });
	}
}

export { apiUserSegment };
