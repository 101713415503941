import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import InstalledApps from "../../components/Apps/InstalledApps";
import OrganizationAppsEditPage from "../Edit";

const OrganizationAppsIndexPage: FC<RouteComponentProps> = (props) => {
	const [apps, setApps] = useState<undefined | ApplicationApi[]>(undefined);
	const history = useHistory();
	const [appTemplates, setAppTemplates] = useState<undefined | ApplicationTemplateApi[]>(undefined);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(`${t("modules.app.field.active.title")} | ${t("modules.app.header.title")}`);
		Promise.all([
			api.organization().getApps({ include: "settings", size: 100 }),
			api.organization().getAppTemplates(),
		])
			.then((res) => {
				setApps(res[0]);
				setAppTemplates(res[1]);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	const handleUninstall = (app: ApplicationApi) => {
		history.push(props?.match.url);
		setApps(apps?.filter((f) => f.id !== app.id));
	};

	const handleUpdate = (app: ApplicationApi) => {
		const currApps = apps;
		if (currApps) {
			const currentAppIndex = currApps.findIndex((f) => f.id === app.id);
			currApps[currentAppIndex] = app;
			setApps([...currApps]);
		}
	};

	const renderRoute = useMemo(() => {
		return (
			<Route
				path={`${props?.match.url}/:app_id`}
				component={() => (
					<OrganizationAppsEditPage
						handleUpdate={handleUpdate}
						handleUninstall={handleUninstall}
						routeProps={props}
						installedApps={apps}
						appConfigs={appTemplates}
					/>
				)}
			/>
		);
	}, [props.match.url, appTemplates]);

	if (!apps || !appTemplates) {
		return <LoadingContainer />;
	}

	return (
		<>
			<InstalledApps
				handleUpdate={handleUpdate}
				handleUninstall={handleUninstall}
				routeProps={props}
				appTemplates={appTemplates}
				installedApps={apps}
			/>
			{renderRoute}
		</>
	);
};
export default OrganizationAppsIndexPage;
