import React, { useCallback, useEffect, useState } from "react";
import { Location } from "history";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { EmployeeApi } from "../../../../../../../../../../services/Api/Organization/types";
import EmployeeForm from "../../components/EmployeeForm";
import { fetchEmployeeConfig, onFetchEmployee } from "../Edit";

const OrganizationEmployeesIndexNewPage = (): JSX.Element => {
	const [employee, setEmployee] = useState<EmployeeApi | undefined>();
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>();
	const location: Location<{ employeeId: number }> = useLocation();
	const isCopyMode = location.state?.employeeId !== undefined;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.employee.action.new.title"));
	}, []);

	const getEmployeeFormDetails = useCallback(async () => {
		await onFetchEmployee(location.state.employeeId, setEmployee, addFlash, t, isCopyMode);
		await fetchEmployeeConfig(setCustomFieldsConfig, addFlash);
	}, [addFlash, location.state?.employeeId, isCopyMode, t]);

	const initializeStateValues = useCallback(async () => {
		await fetchEmployeeConfig(setCustomFieldsConfig, addFlash);
	}, [addFlash]);

	useEffect(() => {
		if (isCopyMode) getEmployeeFormDetails();
		else initializeStateValues();
	}, [getEmployeeFormDetails, initializeStateValues, isCopyMode]);

	if (!customFieldsConfig) return <LoadingContainer />;

	return (
		<>
			<Header title={t("modules.employee.action.new.title")} />
			<EmployeeForm employee={employee} customFieldsConfig={customFieldsConfig} />
		</>
	);
};
export default OrganizationEmployeesIndexNewPage;
