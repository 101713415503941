import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { hasPermission } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import LogsNavigation from "./components/LogsNavigation";
import OrganizationLogsEmployeeActivitiesPage from "./pages/EmployeeActivities";
import OrganizationLogsJobsPage from "./pages/Jobs";
import OrganizationLogsMessageEventsPage from "./pages/MessageEvents";
import { OrganizationLogsReportOrderSyncPage } from "./pages/OrderSync";
import OrganizationLogsRequestItemsPage from "./pages/RequestItems";
import OrganizationLogsWebhooksPage from "./pages/Webhooks";

const OrganizationLogsPage: FC<RouteComponentProps> = (props) => {
	const user = useSelector(selectUser);
	const isSupport = user?.roles?.includes("ROLE_SUPPORT");
	const isSettingsPermission = useSelector(hasPermission("SETTINGS"));

	return (
		<div className="page-container">
			<LogsNavigation />
			<Switch>
				<Route
					path={`${props.match.url}/employee_activities`}
					component={OrganizationLogsEmployeeActivitiesPage}
				/>
				<Route path={`${props.match.url}/message_events`} component={OrganizationLogsMessageEventsPage} />
				<Route path={`${props.match.url}/jobs`} component={OrganizationLogsJobsPage} />
				<Route path={`${props.match.url}/request_items`} component={OrganizationLogsRequestItemsPage} />
				<Route path={`${props.match.url}/webhooks`} component={OrganizationLogsWebhooksPage} />
				{(isSupport || isSettingsPermission) && (
					<Route
						path={`${props.match.url}/reports/order_sync`}
						component={OrganizationLogsReportOrderSyncPage}
					/>
				)}
				<Redirect from={props.match.url} exact to={`${props.match.url}/employee_activities`} />
			</Switch>
		</div>
	);
};

export default OrganizationLogsPage;
