import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError, Exception } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	InvoiceApi,
	InvoiceItemApi,
	InvoiceSummaryTaxApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import InvoiceFormAdvanced from "../../../../components/Form/InvoiceFormAdvanced";
import InvoiceFormBasicInfo from "../../../../components/Form/InvoiceFormBasicInfo";
import InvoiceFormClientsInfo from "../../../../components/Form/InvoiceFormClientsInfo";
import InvoiceFormItemsTable from "../../../../components/Form/InvoiceFormItemsTable";
import InvoiceFormPaymentsTable from "../../../../components/Form/InvoiceFormPaymentsTable";

interface Props {
	correction: InvoiceApi;
	customFieldsConfig: CustomFieldTemplateApi[];
	invoice: InvoiceApi;
}

const CorrectionForm: FC<Props> = ({ correction, customFieldsConfig, invoice }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const [summaryTaxes, setSummaryTaxes] = useState<InvoiceSummaryTaxApi[]>([]);
	const params: Record<string, any> = {
		include:
			"custom_fields,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver,previous_advances",
	};

	const form = useForm<InvoiceApi>({
		criteriaMode: "all",
		defaultValues: {
			...correction,
			items: !correction.items
				? [{ position: 0 } as InvoiceItemApi]
				: [...correction.items, { position: correction.items.length }],
		},
	});
	const { addFlash, addSuccessFlash } = useFlash();
	const { handleSubmit, formState, setError, reset } = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const [loading, setLoading] = useState(false);
	const onSubmit = handleSubmit(async (data: InvoiceApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		data.items = data.items?.filter(
			(f: InvoiceItemApi) =>
				f.name?.length > 0 || f.pkwiu || f.gtu || f.price?.amount || f.quantity || f.volume || f.tax_id
		);
		// data.custom_fields = data.custom_fields?.filter((f: { value: any; }) => f.value);

		try {
			if (correction.id) {
				data.id = correction.id;
				const res = await api.organization().updateCorrection(data, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				reset({ ...res, items: [...res.items, {}] });
			} else {
				await api.organization().createCorrection(invoice.id, data);
				history.push(`/${organization.id}/invoices/${invoice.id}/corrections`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
			handleException(e);
		}
		setLoading(false);
	});

	return (
		<FormDirty formState={formState} loading={loading} key="client-group-form" noValidate onSubmit={onSubmit}>
			<InvoiceFormBasicInfo form={form} invoice={correction} />
			<InvoiceFormClientsInfo form={form} invoice={correction} formErrors={[]} />
			{correction.id && (
				<InvoiceFormItemsTable
					invoicePayments={invoice?.payments?.filter((f) => f.paid)}
					oldInvoice={correction.linked_invoices.find(
						(f) => f.document_type === "INVOICE" || f.document_type === "ADVANCE"
					)}
					form={form}
					summaryTaxes={summaryTaxes}
					setSummaryTaxes={setSummaryTaxes}
					oldItems={
						correction.linked_invoices.find(
							(f) => f.document_type === "INVOICE" || f.document_type === "ADVANCE"
						)?.items
					}
				/>
			)}
			{correction.id && <InvoiceFormPaymentsTable form={form} summaryTaxes={summaryTaxes} />}
			<InvoiceFormAdvanced
				form={form}
				invoice={correction}
				customFieldsConfig={customFieldsConfig}
				setErrors={setErrors}
			/>
		</FormDirty>
	);
};

export default CorrectionForm;
