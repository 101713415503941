import React from "react";
import { useTranslation } from "react-i18next";
import { OrderPreparationStatusName } from "../../../../../../../../../../services/Api/Organization/types";
import { getOrderPreparationStatusColor, preparationStatusColors } from "../../../../services/orderStatus";

const LiveOrdersMapPreparationStatusesLegend = (): JSX.Element => {
	const { t } = useTranslation();

	const statuses = [
		OrderPreparationStatusName.NEW,
		OrderPreparationStatusName.PACKED,
		OrderPreparationStatusName.IN_PROGRESS,
		OrderPreparationStatusName.RECEIVED,
		OrderPreparationStatusName.READY,
		OrderPreparationStatusName.DELIVERED,
	];

	const statusOptions = statuses.map((status) => ({
		color: getOrderPreparationStatusColor(status),
		label: t(`enums.preparation_statuses.${status}`),
	}));

	return (
		<div className="d-flex flex-column">
			<h5>{t("modules.live_order.field.preparation_statuses.title")}</h5>
			<div className="row">
				{statusOptions.map((status, index) => (
					<div className="col-6" key={`preparation-status-legend-${index}`}>
						<div className="d-flex align-items-center preparation-status-legend-item">
							<div
								className={`preparation-status-legend-item-box ${
									status.color === preparationStatusColors.WHITE
										? "preparation-status-legend-item-box--bordered"
										: ""
								}`}
								style={{ backgroundColor: status.color }}
							/>
							<span className="text-muted">{status.label}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default LiveOrdersMapPreparationStatusesLegend;
