export enum TransactionsBackendAcceptableParams {
	CREATED_AT = "CREATED_AT",
	DESCRIPTION = "DESCRIPTION",
	ID = "ID",
	PAYMENT_METHOD = "PAYMENT_METHOD",
	PRICE = "PRICE",
	STATUS = "STATUS",
	TYPE = "TYPE",
	DOCUMENTS = "DOCUMENTS",
}
