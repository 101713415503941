import React from "react";
import { Card } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { PrintoutTemplateApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<PrintoutTemplateApi>;
}

const PrintoutTemplateBasicInfoForm = ({ form }: Props) => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		register,
		control,
	} = form;

	const sortOptions = [
		{
			label: t("enums.printout.sort.ALPHABETICAL"),
			value: "ALPHABETICAL",
		},
		{
			label: t("enums.printout.sort.CHRONOLOGICAL"),
			value: "CHRONOLOGICAL",
		},
	];

	const mergeItemsOptions = [
		{
			label: t("enums.printout.merge_items.ORIGINAL"),
			value: "ORIGINAL",
		},
		{
			label: t("enums.printout.merge_items.MERGE"),
			value: "MERGE",
		},
		{
			label: t("enums.printout.merge_items.SPLIT"),
			value: "SPLIT",
		},
	];

	const fontOptions = [
		{
			label: t("enums.printout.font.SMALL"),
			value: "SMALL",
		},
		{
			label: t("enums.printout.font.MEDIUM"),
			value: "MEDIUM",
		},
		{
			label: t("enums.printout.font.DELIVERY"),
			value: "DELIVERY",
		},
		{
			label: t("enums.printout.font.LARGE"),
			value: "LARGE",
		},
	];

	return (
		<Card className={"mb-3"}>
			<Card.Body>
				<h6>{t("modules.printout_template.field.legend_info.title")}</h6>
				<div className={"row"}>
					<div className={"col-md-12"}>
						<FormInput
							label={t("modules.printout_template.field.name.title")}
							errors={errors}
							name={"name"}
							register={register}
						/>
					</div>
					<div className={"col-md-12"}>
						<FormSelectGroup
							label={t("modules.printout_template.field.merge_items.title")}
							name="merge_items"
							errors={errors}
							options={mergeItemsOptions}
							control={control}
							data-testid="merge_items"
						/>
					</div>
					<div className={"col-md-6"}>
						<FormSelectGroup
							label={t("modules.printout_template.field.text_size.title")}
							name="text_size"
							errors={errors}
							options={fontOptions}
							control={control}
							data-testid="text_size"
						/>
					</div>
					<div className={"col-md-6"}>
						<FormSelectGroup
							label={t("modules.printout_template.field.items_sort.title")}
							name="items_sort"
							errors={errors}
							options={sortOptions}
							control={control}
							data-testid="items_sort"
						/>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default PrintoutTemplateBasicInfoForm;
