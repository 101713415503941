import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BlankLayout from "go-core/components/Layout/BlankLayout";

interface LoginSecurityLayoutProps {
	info?: ReactElement;
}

const LoginSecurityLayout: FC<LoginSecurityLayoutProps> = (props) => {
	const { t } = useTranslation();

	return (
		<BlankLayout>
			<div className="security-container">
				<div className="security-shape" />
				<img className="security-yellow-dots" src="/graphics/login/dots-yellow.svg" alt="Yellow dots" />
				<div className="security-content">
					<div className="info-content">
						<div className="logo">
							<a href="/">
								<img alt="GoPOS Logo" className="logo-img" src={"/graphics/logo.png"} />
							</a>
						</div>
						{props.info ? (
							props.info
						) : (
							<>
								<div className="info">
									<img
										className="security-info-dots"
										src="/graphics/login/dots-light-blue.svg"
										alt="Blue dots"
									/>
									<img
										alt="GoPOS Login"
										className="info-graphics"
										src={"/graphics/login/login.png"}
									/>
								</div>
							</>
						)}
					</div>
					<div className="form-content">
						<div className="form-login-logo">
							<a href="https://gopos.pl">
								<img alt="GoPOS Logo" className="logo-img" src={"/graphics/logo.png"} />
							</a>
						</div>
						<>{props.children}</>
						<div className="login-footer">
							<ul className="list-inline float-end">
								<li className="list-inline-item">GoPOS</li>
								<li className="list-inline-item">
									<a href="https://www.gopos.pl/contact">
										{t("lib:go_security.layout.footer.words.contact")}
									</a>
								</li>
								<li className="list-inline-item">
									<a href="https://www.gopos.pl/privacy-policy">
										{t("lib:go_security.layout.footer.words.rules")}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</BlankLayout>
	);
};

export default LoginSecurityLayout;
