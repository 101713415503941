import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { apiPublic } from "../../../../../services/Api/Public/apiPublic";
import { OrderData } from "../../../../../services/Api/Public/types";

export const useOrder = (orderId: string): OrderData | undefined => {
	const history = useHistory();
	const [order, setOrder] = useState<OrderData>();

	useEffect(() => {
		const getOrder = async () => {
			try {
				const newOrder = await apiPublic.getOrderData(orderId);

				if (newOrder) {
					return setOrder(newOrder);
				}

				history.push("/404_page");
			} catch {
				history.push("/404_page");
			}
		};

		getOrder();
	}, [orderId, history]);

	return order;
};
