import { MoneyApi } from "go-core/api/types";
import { InvoiceItemApi, InvoiceSummaryTaxApi } from "../../../../../../../services/Api/Organization/types";

export const calculatePriceNet = (
	amount: number | null | undefined,
	quantity: number,
	taxAmount: number,
	type?: string
) => {
	if (!amount) {
		return 0;
	}
	if (type === "GROSS") {
		return Number(((amount * quantity) / (1 + taxAmount / 100)).toFixed(2));
	}
	return amount * quantity;
};

export const calculatePriceGross = (
	amount: number | null | undefined,
	quantity: number,
	taxAmount: number,
	type?: string
) => {
	if (!amount) {
		return 0;
	}
	if (type === "GROSS") {
		return amount * quantity;
	}
	return Number((amount * quantity * (1 + taxAmount / 100)).toFixed(2));
};

export const getRecalculatedSummaryTaxes = (
	items: InvoiceItemApi[],
	currency: string,
	type?: string,
	index?: number,
	val?: number,
	quantity?: string
) => {
	const newTaxes: InvoiceSummaryTaxApi[] = [];
	const taxes: {
		tax_id: number;
		tax_name: string;
		tax_amount: number;
		price_sum: MoneyApi;
	}[] = [];
	const itemsWithTax = items.filter((f) => f.tax_id);

	itemsWithTax.forEach((item, itemIndex) => {
		const parsedItem = {
			...item,
			quantity: !isNaN(item.quantity) ? item.quantity : 0,
		};
		const taxId = parseInt(item.tax_id.toString());
		const matchedItem = taxes.find((newTax) => newTax.tax_id === taxId);

		if (itemIndex === index) {
			if (quantity && val) {
				item.quantity = val;
			} else if (!quantity) {
				item.price.amount = val;
			}
		}

		if (!matchedItem) {
			const newItem = {
				tax_id: taxId,
				tax_name: parsedItem.tax.name,
				tax_amount: parsedItem.tax.amount,
				price_sum: {
					...parsedItem.price,
					amount: parsedItem.price.amount
						? Number((parsedItem.price.amount * parsedItem.quantity).toFixed(2))
						: 0,
				},
			};
			taxes.push(newItem);
		} else {
			taxes[taxes.indexOf(matchedItem)] = {
				...matchedItem,
				price_sum: {
					...matchedItem.price_sum,
					amount:
						matchedItem.price_sum.amount +
						(parsedItem.price?.amount
							? Number((parsedItem.price?.amount * parsedItem.quantity).toFixed(2))
							: 0),
				},
			};
		}
	});

	taxes.forEach((tax) => {
		const priceNet = calculatePriceNet(tax.price_sum.amount, 1, tax.tax_amount, type);
		const priceGross = calculatePriceGross(tax.price_sum.amount, 1, tax.tax_amount, type);
		const priceTax =
			calculatePriceGross(tax.price_sum.amount, 1, tax.tax_amount, type) -
			calculatePriceNet(tax.price_sum.amount, 1, tax.tax_amount, type);
		const newTax = {
			tax_id: tax.tax_id,
			tax_name: tax.tax_name,
			tax_amount: tax.tax_amount,
			price_net: {
				amount: priceNet,
				currency,
			},
			price_gross: {
				amount: priceGross,
				currency,
			},
			price_tax: {
				amount: priceTax,
				currency,
			},
		};
		newTaxes.push(newTax);
	});

	return newTaxes;
};
