import React from "react";
import { useSelector } from "react-redux";
import { SortableElement } from "react-sortable-hoc";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../images/svg/remove.svg";
import { MenuItemApi } from "../../../../../../../../../../services/Api/Organization/types";
import MenuListSortableHandler from "./MenuListSortableHandler";

interface Props {
	pageItem: MenuItemApi;
	handleRemove: () => void;
}

const MenuListPageItem = SortableElement(({ pageItem, handleRemove }: Props) => {
	const organization = useSelector(selectOrganization);

	const getItemLink = () => {
		if (pageItem.context_type === "ITEM_GROUP") {
			return `/${organization.id}/menu/item_groups/${pageItem.context_id}`;
		}
		return `/${organization.id}/menu/item_groups/${pageItem.entity.parent_id}`;
	};

	return (
		<tr className="menu-list-page-item-subrow">
			<td className="action menu-list-table-td" />
			<td className="menu-list-page-item-subrow-item-cell w-75 menu-list-table-td">
				<div className="d-flex align-items-center">
					<MenuListSortableHandler />
					<ListImageName
						data={pageItem.entity}
						link={getItemLink()}
						shouldOpenInNewTab
						imageLink={pageItem.entity.image_link}
					/>
				</div>
			</td>
			<td className="action w-25 menu-list-table-actions menu-list-table-td">
				<div className="d-flex align-items-center justify-content-end">
					<div className="remove-icon-container icon" onClick={handleRemove}>
						<RemoveSVG />
					</div>
				</div>
			</td>
		</tr>
	);
});

export default MenuListPageItem;
