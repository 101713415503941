import React, { RefObject } from "react";
import { Dropdown } from "react-bootstrap";
import { ListParamsType } from "../../../../../../list/services/types";
import { GroupByConfig, ListConfigAction, ListConfigMultipleAction } from "../../../services/types";
import { getMultuipleActionFParam } from "../../../utils/getMultipleActionsParams";
import { ActionsMenu } from "../ActionsMenu";

export interface MultiActionListProps {
	mainDivRef: RefObject<HTMLDivElement>;
	multiActions?: ListConfigMultipleAction[];
	singleActions?: ListConfigAction[];
	data: Array<any>;
	selectedItems: string[];
	areAllSelected: boolean;
	groupBy?: GroupByConfig;
	isNested: (dataItem: any) => boolean;
	nestedConfig?: Record<string, any>;
	params: ListParamsType;
	shouldFilterAvailableMultipleActions?: boolean;
	// the shouldFilterAvailableMultipleActions prop should be removed after configs in all components that use ListTable are updated (singleActions should contain all the available actions)
}

export const MultiActionList = ({
	mainDivRef,
	multiActions,
	singleActions,
	data,
	selectedItems,
	areAllSelected,
	groupBy,
	isNested,
	nestedConfig,
	params,
	shouldFilterAvailableMultipleActions,
}: MultiActionListProps): JSX.Element | null => {
	if (!multiActions || (shouldFilterAvailableMultipleActions && !singleActions)) {
		return null;
	}

	const multipleActionsParams = getMultuipleActionFParam(params);
	const getIsActionAvailableForEveryItem = (action: ListConfigAction): boolean => {
		if (groupBy) {
			return data.every((obj) => obj.values.every((item: any) => action.visible?.(item)));
		}

		const itemsToCheck = areAllSelected ? data : data.filter((item: any) => selectedItems.includes(item.id));

		return itemsToCheck.every((item) => {
			const isActionAvaiableForItem = action.visible?.(item);

			if (!isNested(item)) {
				return isActionAvaiableForItem;
			}

			const nestedItems = item[`${nestedConfig?.field}`];
			const isActionAvaiableForNestedItems = nestedItems.every((nestedItem: any) => action.visible?.(nestedItem));

			return isActionAvaiableForItem && isActionAvaiableForNestedItems;
		});
	};

	const selectedItemsIds: string[] | number[] = data.filter((dataItem) => selectedItems.includes(dataItem.id));

	const actions = shouldFilterAvailableMultipleActions
		? singleActions?.reduce<ListConfigMultipleAction[]>((availableActions, currentAction) => {
				const isActionAvaibleForEveryItem = getIsActionAvailableForEveryItem(currentAction);

				if (isActionAvaibleForEveryItem) {
					const correspondingMultiAction = multiActions.find((action) => action.name === currentAction.name);

					if (correspondingMultiAction) {
						return [...availableActions, correspondingMultiAction];
					}
				}

				return availableActions;
		  }, [])
		: multiActions.filter((action) => (action.visible ? action.visible(selectedItemsIds) : true));

	if (!actions || !actions.length) {
		return null;
	}

	return (
		<ActionsMenu mainDivRef={mainDivRef} isButtonPrimary={true}>
			{actions.map((action, index) => {
				const onClick = () => action.click(selectedItemsIds, params, areAllSelected, multipleActionsParams);

				return (
					<Dropdown.Item key={index} onClick={onClick}>
						{action.name}
					</Dropdown.Item>
				);
			})}
		</ActionsMenu>
	);
};
