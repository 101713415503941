import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
	handleRemoveOrders: () => void;
}

interface FormProps {
	password: string;
}

const RemoveOrdersModal = (props: Props): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const form = useForm<FormProps>({
		criteriaMode: "all",
		defaultValues: {
			password: "",
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: FormProps) => {
		setLoading(true);
		try {
			await api.organization().removeOrders(data.password);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			props.handleRemoveOrders();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	});

	const { t } = useTranslation();
	return (
		<Modal show={true} onHide={props.onHide}>
			<Form key="link-entity-modal" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.order.action.remove_orders.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						type={"password"}
						name={"password"}
						label={t("modules.order.action.enter_password.title")}
						help={t("modules.order.field.enter_password.helptext.description")}
						errors={errors}
						register={register}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="danger" type="submit" onClick={onSubmit}>
						{t("common.action.remove", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
export default RemoveOrdersModal;
