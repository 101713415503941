import React, { JSX, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import {
	LiveOrderAcceptanceFormApi,
	LiveOrderApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import {
	LiveOrderDeliveryOptionType,
	defaultDeliveryTimeOptions,
	woltDriveTimeOptions,
} from "../../../../../../services/types";
import LiveOrderDeliveryTimeOption from "./LiveOrderDeliveryTimeOption";

interface Props {
	setSelectedTimeOption: (option: string | number | undefined) => void;
	selectedTimeOption: number | string | undefined;
	form?: UseFormReturn<LiveOrderAcceptanceFormApi>;
	selectedDeliveryOption: LiveOrderDeliveryOptionType | undefined;
	deliveryEstimatingLoading: boolean;
	optionsFor?: LiveOrderDeliveryOptionType[];
	firstEstimatedDeliveryTime?: number;
	order?: LiveOrderApi;
	orderEstimatedPreparationInMinutes?: number;
}

const LiveOrderDeliveryTimeOptions = ({
	setSelectedTimeOption,
	selectedTimeOption,
	form,
	selectedDeliveryOption,
	deliveryEstimatingLoading,
	optionsFor,
	firstEstimatedDeliveryTime,
	order,
	orderEstimatedPreparationInMinutes,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const isWoltDriveSelected = selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE;
	const isWoltDriveAssigned = order?.delivery?.delivery_employee?.name === "WOLT_DRIVE";

	return useMemo(() => {
		const getUniqueWoltDriveOptions = () => {
			let options: number[] = [...woltDriveTimeOptions];

			if (firstEstimatedDeliveryTime) {
				options = [...new Set([...options, firstEstimatedDeliveryTime])];
			}

			if (
				isWoltDriveAssigned &&
				orderEstimatedPreparationInMinutes !== undefined &&
				orderEstimatedPreparationInMinutes >= 0
			) {
				options = [...new Set([...options, orderEstimatedPreparationInMinutes])];
			}

			return options.sort((a, b) => a - b);
		};
		const getOptions = () => {
			if (optionsFor) {
				if (optionsFor.includes(LiveOrderDeliveryOptionType.WOLT_DRIVE) && isWoltDriveSelected)
					return getUniqueWoltDriveOptions();
				if (optionsFor.includes(LiveOrderDeliveryOptionType.OWN_DRIVER) && !isWoltDriveSelected)
					return defaultDeliveryTimeOptions;
			}
			return isWoltDriveSelected ? getUniqueWoltDriveOptions() : defaultDeliveryTimeOptions;
		};

		return selectedDeliveryOption ? (
			<div className="position-relative live-order-delivery-time-options">
				{deliveryEstimatingLoading && <div className="overlay" />}
				<h5>
					{!isWoltDriveSelected
						? t("modules.live_order.field.choose_delivery_time.title")
						: t("modules.live_order.field.choose_preparation_time_for_driver.title")}
				</h5>
				<div className="d-flex flex-wrap gap-2 align-items-center live-order-delivery-time-options-container">
					{getOptions().map((option) => (
						<LiveOrderDeliveryTimeOption
							setSelectedTimeOption={setSelectedTimeOption}
							option={option}
							selectedTimeOption={selectedTimeOption}
							key={option.toString()}
						/>
					))}
					{!isWoltDriveSelected && form && (
						<FormDatePicker
							control={form.control}
							name="pickup_at"
							errors={form.formState.errors}
							allowResetDate
							onApply={(date) => {
								setSelectedTimeOption(date as string | undefined);
							}}
							placeholder={t("modules.live_order.field.execution_at.placeholder")}
						/>
					)}
				</div>
			</div>
		) : (
			<></>
		);
	}, [
		selectedTimeOption,
		setSelectedTimeOption,
		form?.control,
		t,
		form?.formState?.errors,
		selectedDeliveryOption,
		isWoltDriveSelected,
		deliveryEstimatingLoading,
		firstEstimatedDeliveryTime,
		JSON.stringify(optionsFor),
	]);
};

export default LiveOrderDeliveryTimeOptions;
