import React, { useCallback, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import ImportCsv from "go-component/components/ImportCsv/ImportCsv";
import { camelToSnakeCase } from "go-component/utils/componentUtils";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ItemGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

const getTranslationsConfig = (t: TFunction) => {
	return {
		export_csv: t("modules.item_group.action.export_csv.title"),
		resource_name: t("modules.item_group.field.resource_name.title"),
		constraints: {
			cannot_be_empty: t("constraints.cannot_be_empty"),
			csv_corrupted: t("constraints.csv_corrupted"),
			duplicate_barcode: t("constraints.duplicate_barcode"),
			duplicate_name: t("constraints.duplicate_name"),
			duplicate_sku: t("constraints.duplicate_sku"),
			invalid_custom_field_option: t("constraints.invalid_custom_field_option"),
			invalid_custom_field_value: t("constraints.invalid_custom_field_value"),
			invalid_custom_field_resource: t("constraints.invalid_custom_field_resource"),
			invalid_email: t("constraints.invalid_email"),
			invalid_value: t("constraints.invalid_value"),
			max_length: t("constraints.max_length"),
		},
		database_column_name: {
			uid: t("enums.resource_type.item_group.uid"),
			itemGroupName: t("enums.resource_type.item_group.itemGroupName"),
			itemGroupDescription: t("enums.resource_type.item_group.itemGroupDescription"),
			category: t("enums.resource_type.item_group.category"),
			sku: t("enums.resource_type.item_group.sku"),
			itemName: t("enums.resource_type.item_group.itemName"),
			itemDescription: t("enums.resource_type.item_group.itemDescription"),
			price: t("enums.resource_type.item_group.price"),
			tax: t("enums.resource_type.item_group.tax"),
			openPrice: t("enums.resource_type.item_group.openPrice"),
			weighted: t("enums.resource_type.item_group.weighted"),
			discountable: t("enums.resource_type.item_group.discountable"),
			availability: t("enums.resource_type.item_group.availability"),
			modifierGroups: t("enums.resource_type.item_group.modifierGroups"),
			barcodes: t("enums.resource_type.item_group.barcodes"),
			direction: t("enums.resource_type.item_group.direction"),
			defaultCost: t("enums.resource_type.item_group.defaultCost"),
			stockAmount: t("enums.resource_type.item_group.stockAmount"),
		},
	};
};

const getVariantName = (itemNameIncludingVariant: string, itemName: string) =>
	itemNameIncludingVariant.replace(itemName, "").trim();

const OrganizationMenuItemGroupsImportPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const { addFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.item_group.header.item_groups_import.title"));
	}, []);

	const onFetchCustomFields = useCallback(async () => {
		const params: Record<string, string> = {
			resource_name: "ITEM",
			custom_field_resource_type: "ITEM",
			custom_field_permission_scope: "READ",
		};
		try {
			const res = await api.organization().getResourceTypes(params);
			setCustomFieldsConfig(res.custom_fields);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	}, [addFlash]);

	useEffect(() => {
		onFetchCustomFields();
	}, [onFetchCustomFields]);

	const comparationCheck = (data: ItemGroupApi, domainFieldName: string) => {
		switch (domainFieldName) {
			case "category":
				return data.category?.name;
			case "direction":
				return data.items.map((item) => item.direction?.name).join("");
			case "tax":
				return data.tax?.name;
			case "item_name":
				return data.name;
			case "item_group_name":
				return data.name;
			case "item_description":
				return data.description;
			case "item_group_description":
				return data.description;
			case "price":
				return data.items.map((item) => item.price.amount).join("");
			case "sku":
				return data.items.map((item) => item.sku).join("");
			case "open_price":
				return data.items.map((item) => item.open_price).join("");
			case "weighted":
				return data.items.map((item) => item.weighted_type).join("");
			case "discountable":
				return data.items.map((item) => item.discountable).join("");
			case "availability":
				return data.items.map((item) => item.availability).join("");
			case "modifier_groups":
				return data.items.flatMap((item) => item.modifier_groups.map((modifier) => modifier.name)).join("");
			case "barcodes":
				return data.items.flatMap((item) => item.barcodes).join("");
			default:
				return data[domainFieldName as keyof ItemGroupApi];
		}
	};

	const renderComparedValue = (data: ItemGroupApi, fieldName: string, differs?: boolean) => {
		if (!data) return <span></span>;
		let title: string | undefined;
		let caption: JSX.Element[] | string | undefined;

		const replacedCf = fieldName.replace("+", "");
		const cf = data.custom_fields?.find((x) => x.slug === camelToSnakeCase(replacedCf, true));
		if (cf && Object.keys(cf).length > 0) {
			title = cf.value;
			caption = title;
		} else {
			switch (fieldName) {
				case "category":
					title = data.category?.name;
					caption = title;
					break;
				case "direction":
					if (data.items.length === 1) {
						title = data.items[0].sku;
						caption = title;
					} else {
						const formattedSkus = data.items
							.filter((item) => item.sku)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${item.sku}`;
							});
						title = formattedSkus.join(", ");

						caption = formattedSkus.map((formattedSku) => (
							<>
								{`${formattedSku},`}
								<br />
							</>
						));
					}
					if (data.items.length === 1) {
						title = data.items[0].direction?.name || "";
						caption = title;
					} else {
						const formattedDirections = data.items
							.filter((item) => item.direction)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${item.direction?.name ?? ""}`;
							});
						title = formattedDirections.join(", ");

						caption = formattedDirections.map((formattedSku) => (
							<>
								{`${formattedSku},`}
								<br />
							</>
						));
					}
					break;
				case "tax":
					title = data.tax?.name;
					caption = title;
					break;
				case "item_description":
					if (data.items.length === 1) {
						title = data.items[0].description;
						caption = title;
					} else {
						const formattedItemDescription = data.items
							.filter((item) => item.description)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${item.description}`;
							});
						title = formattedItemDescription.join(", ");

						caption = formattedItemDescription.map((formattedDescription) => (
							<>
								{`${formattedDescription},`}
								<br />
							</>
						));
					}
					break;
				case "item_group_name":
					title = data.name;
					caption = title;
					break;
				case "item_group_description":
					title = data.description;
					caption = title;
					break;
				case "item_name":
					if (data.items.length === 1) {
						title = data.name;
						caption = title;
					} else {
						title = data.items?.map((item) => item.name).join(FILTER_VALUE_SEPARATOR);
						caption = data.items?.map((item) => (
							<>
								{`${getVariantName(item.name, data.name)},`}
								<br />
							</>
						));
					}
					break;
				case "price":
					if (data.items.length === 1) {
						title = FormatMoney(data.items[0].price);
						caption = title;
					} else {
						const formattedPrices = data.items.map((item) => {
							const variantName = getVariantName(item.name, data.name);
							return `${variantName}: ${FormatMoney(item.price)}`;
						});
						title = formattedPrices.join(", ");
						caption = formattedPrices.map((formattedPrice) => (
							<>
								{`${formattedPrice},`}
								<br />
							</>
						));
					}
					break;
				case "sku":
					if (data.items.length === 1) {
						title = data.items[0].sku;
						caption = title;
					} else {
						const formattedSkus = data.items
							.filter((item) => item.sku)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${item.sku}`;
							});
						title = formattedSkus.join(", ");

						caption = formattedSkus.map((formattedSku) => (
							<>
								{`${formattedSku},`}
								<br />
							</>
						));
					}
					break;
				case "open_price":
					if (data.items.length === 1) {
						if (data.items[0].open_price !== undefined)
							title = data.items[0].open_price
								? t("common.word.yes", { ns: "lib" })
								: t("common.word.no", { ns: "lib" });
						else title = "";
						caption = title;
					} else {
						const formattedOpenPrices = data.items
							.filter((item) => item.open_price !== undefined)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${
									item.open_price
										? t("common.word.yes", { ns: "lib" })
										: t("common.word.no", { ns: "lib" })
								}`;
							});
						title = formattedOpenPrices.join(", ");
						caption = formattedOpenPrices.map((formattedOpenPrice) => (
							<>
								{`${formattedOpenPrice},`}
								<br />
							</>
						));
					}
					break;
				case "weighted":
					if (data.items.length === 1) {
						title = data.items[0].weighted_type
							? t(`enums.item_groups.weighted.${data.items[0].weighted_type}`)
							: "";
						caption = title;
					} else {
						const formattedWeightedTypes = data.items
							.filter((item) => item.weighted_type)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${t(`enums.item_groups.weighted.${item.weighted_type}`)}`;
							});
						title = formattedWeightedTypes.join(", ");

						caption = formattedWeightedTypes.map((formattedWeightedType) => (
							<>
								{`${formattedWeightedType},`}
								<br />
							</>
						));
					}
					break;
				case "discountable":
					if (data.items.length === 1) {
						if (data.items[0].discountable === undefined) title = "";
						else {
							title = data.items[0].discountable
								? t("common.word.yes", { ns: "lib" })
								: t("common.word.no", { ns: "lib" });
							caption = title;
						}
					} else {
						const formattedDiscountables = data.items
							.filter((item) => item.discountable !== undefined)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${
									item.discountable
										? t("common.word.yes", { ns: "lib" })
										: t("common.word.no", { ns: "lib" })
								}`;
							});
						title = formattedDiscountables.join(", ");
						caption = formattedDiscountables.map((formattedDiscountable) => (
							<>
								{`${formattedDiscountable},`}
								<br />
							</>
						));
					}
					break;
				case "availability":
					if (data.items.length === 1) {
						title = data.items[0].availability?.name;
						caption = title;
					} else {
						const formattedAvailabilities = data.items
							.filter((item) => item.availability?.name)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${item.availability?.name}`;
							});
						title = formattedAvailabilities.join(", ");

						caption = formattedAvailabilities.map((formattedAvailability) => (
							<>
								{`${formattedAvailability},`}
								<br />
							</>
						));
					}
					break;
				case "modifier_groups":
					if (data.items.length === 1) {
						title = data.items[0].modifier_groups.map((modifier) => modifier.name).join(", ");
						caption = title;
					} else {
						const formattedModifierGroups = data.items
							.filter((item) => item.modifier_groups.length > 0)
							.map((item) => {
								const modifierGroupsNames = item.modifier_groups
									.map((modifier) => modifier.name)
									.join(", ");
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${modifierGroupsNames}`;
							});
						title = formattedModifierGroups.join(", ");

						caption = formattedModifierGroups.map((formattedModifierGroup) => {
							return (
								<>
									{`${formattedModifierGroup},`}
									<br />
								</>
							);
						});
					}
					break;
				case "barcodes":
					if (data.items.length === 1) {
						title = data.items[0].barcodes.join(", ");
						caption = title;
					} else {
						const formattedBarcodes = data.items
							.filter((item) => item.barcodes.length > 0)
							.map((item) => {
								const variantName = getVariantName(item.name, data.name);
								return `${variantName}: ${item.barcodes.map((barcode) => barcode).join(", ")}`;
							});

						title = formattedBarcodes.join(", ");

						caption = formattedBarcodes.map((formattedBarcode) => (
							<>
								{`${formattedBarcode},`}
								<br />
							</>
						));
					}
					break;
				default:
					title = data[fieldName as keyof ItemGroupApi]?.toString() || "";
					caption = title;
					break;
			}
		}

		return caption?.length && caption.length > 0 && title?.length && title.length > 0 ? (
			<span style={{ textDecoration: `${differs ? "line-through" : "none"}` }} title={title}>
				{caption}
			</span>
		) : (
			<></>
		);
	};

	const customAttributesCompare = (attribute: string) => {
		if (attribute === "itemName") return "name";
		if (attribute === "itemDescription") return "description";
		return camelToSnakeCase(attribute, true);
	};

	if (!customFieldsConfig) return <LoadingContainer />;

	return (
		<>
			<Header title={t("modules.item_group.header.item_groups_import.title")} />
			<ImportCsv
				resourceType="items"
				customFieldsConfig={customFieldsConfig}
				redirectionUrl={`/${organization.id}/menu/item_groups`}
				importCsvRequestParams="category,tax,items,custom_fields,items.stock_info,items.availability,direction,items.modifier_groups,items.modifier_groups.modifier_group,items.barcodes"
				comparationCheck={comparationCheck}
				renderComparedValue={renderComparedValue}
				translations={getTranslationsConfig(t)}
				customAttributesCompare={customAttributesCompare}
				workingInBackground
			/>
		</>
	);
};
export default OrganizationMenuItemGroupsImportPage;
