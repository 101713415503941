import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { MessageEventApi } from "go-component/components/MessageEvent/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganizationComponent } from "../../../../../../../../../../services/Api/Organization/apiOrganizationComponent";

interface MatchParams {
	log_id: string;
}

const OrganizationLogsMessageEventsEditPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [messageEvent, setMessageEvent] = useState<MessageEventApi | undefined>();
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	const { log_id } = useParams<MatchParams>();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchMessageEvent();
	}, []);

	const onFetchMessageEvent = async () => {
		try {
			const params: Record<string, any> = {};
			params.include = "logs,resources,resources.entity_id,terminal";
			const res = await apiOrganizationComponent.getMessageEvent(log_id, params);
			setMessageEvent(res.data.data);
			handleChangeTabTitle(
				`${t(`enums.message_events.type.${res.data.data.type}`)} | ${t("modules.message_event.header.title")}"`
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!messageEvent) {
		return <LoadingContainer />;
	}

	const getResourceUrl = (resourceType: string, entityId: number, type?: string, name?: string) => {
		switch (resourceType) {
			case "INVOICE":
				return (
					<Link to={`/${organization.id}/invoices/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.INVOICE")}
					</Link>
				);
			case "ITEM": {
				let filter: string = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}item|e=${entityId}`;
				if (type?.includes("DELETED")) {
					filter += `${FILTER_SEPARATOR}status|e=DELETED`;
				}
				return (
					<Link
						to={`/${organization.id}/menu/item_groups?f=${btoa(unescape(encodeURIComponent(filter)))}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.ITEM")}
					</Link>
				);
			}
			case "VENUE":
				return (
					<Link to={`/${organization.id}/settings/info/company`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.VENUE")}
					</Link>
				);
			case "TRANSACTION":
				return (
					<Link to={`/${organization.id}/sales/transactions`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.TRANSACTION")}
					</Link>
				);
			case "CATEGORY":
				return (
					<Link to={`/${organization.id}/menu/categories/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.CATEGORY")}
					</Link>
				);
			case "MENU_DISCOUNT":
				return (
					<Link to={`/${organization.id}/menu/discounts/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.MENU_DISCOUNT")}
					</Link>
				);
			case "ITEM_GROUP":
				return (
					<Link to={`/${organization.id}/menu/item_groups/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.ITEM_GROUP")}
					</Link>
				);
			case "DEVICE":
				return (
					<Link to={`/${organization.id}/settings/devices/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.DEVICE")}
					</Link>
				);
			case "TERMINAL":
				return (
					<Link
						to={`/${organization.id}/settings/terminals/${entityId}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.TERMINAL")}
					</Link>
				);
			case "CLIENT_GROUP":
				return (
					<Link
						to={`/${organization.id}/clients/client-groups/${entityId}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.CLIENT_GROUP")}
					</Link>
				);
			case "MODIFIER_GROUP":
				return (
					<Link
						to={`/${organization.id}/menu/modifier_groups/${entityId}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.MODIFIER_GROUP")}
					</Link>
				);
			case "CLIENT":
				return (
					<Link to={`/${organization.id}/clients/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.CLIENT")}
					</Link>
				);
			case "EMPLOYEE":
				return (
					<Link to={`/${organization.id}/employees/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.EMPLOYEE")}
					</Link>
				);
			case "RESERVATION":
				return (
					<Link to={`/${organization.id}/reservations/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.RESERVATION")}
					</Link>
				);
			case "AVAILABILITY":
				return (
					<Link
						to={`/${organization.id}/settings/availabilities/${entityId}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.AVAILABILITY")}
					</Link>
				);
			case "DIRECTION":
				return (
					<Link to={`/${organization.id}/settings/rooms/directions`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.DIRECTION")}
					</Link>
				);
			case "POINT_OF_SALE":
				return (
					<Link to={`/${organization.id}/settings/rooms/point-of-sales`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.POINT_OF_SALE")}
					</Link>
				);
			case "ROLE":
				return (
					<Link to={`/${organization.id}/settings/permissions/roles`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.ROLE")}
					</Link>
				);
			case "PRINTOUT_TEMPLATE":
				return (
					<Link
						to={`/${organization.id}/settings/printout_templates/${entityId}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.PRINTOUT_TEMPLATE")}
					</Link>
				);
			case "ORDER":
				return (
					<Link to={`/${organization.id}/sales/orders/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.ORDER")}
					</Link>
				);
			case "PAYMENT_METHOD":
				return (
					<Link
						to={`/${organization.id}/settings/payment_methods/${entityId}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.PAYMENT_METHOD")}
					</Link>
				);
			case "TAX":
				return (
					<Link to={`/${organization.id}/settings/taxes`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.TAX")}
					</Link>
				);
			case "CURRENCY_RATE":
				return (
					<Link to={`/${organization.id}/settings/currencies`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.CURRENCY_RATE")}
					</Link>
				);
			case "ROOM":
				return (
					<Link to={`/${organization.id}/settings/rooms`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.ROOM")}
					</Link>
				);
			case "WORKPLACE":
				return (
					<Link to={`/${organization.id}/employees/workplaces`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.WORKPLACE")}
					</Link>
				);
			case "DEFAULT_COMMENT":
				return (
					<Link to={`/${organization.id}/settings/default_comments`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.DEFAULT_COMMENT")}
					</Link>
				);
			case "PERMISSION":
				return (
					<Link to={`/${organization.id}/settings/permissions`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.PERMISSION")}
					</Link>
				);
			case "REPORT":
				return (
					<Link to={`/${organization.id}/sales/pos_reports/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.REPORT")}
					</Link>
				);
			case "WORK_TIME":
				return (
					<Link to={`/${organization.id}/employees/worktimes`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.WORK_TIME")}
					</Link>
				);
			case "WEBHOOK":
				return (
					<Link to={`/${organization.id}/logs/webhooks/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.WEBHOOK")}
					</Link>
				);
			case "APP":
				return (
					<Link to={`/${organization.id}/apps/${entityId}/edit`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.APP")}
					</Link>
				);
			case "RECEIPT":
				return (
					<Link to={`/${organization.id}/settings/info/receipt`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.RECEIPT")}
					</Link>
				);
			case "INVOICE_DETAILS":
				return (
					<Link to={`/${organization.id}/settings/info/invoice_details`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.INVOICE_DETAILS")}
					</Link>
				);
			case "SEGMENT":
				return name || t("enums.message_events.resource_type.SEGMENT");
			case "IMAGE":
				return name || t("enums.message_events.resource_type.IMAGE");
			case "MENU_PRICE_LIST":
				return (
					<Link to={`/${organization.id}/menu/price_lists/${entityId}`} target={"_blank"} rel={"noreferrer"}>
						{name || t("enums.message_events.resource_type.MENU_PRICE_LIST")}
					</Link>
				);
			case "APP_LINK":
				return (
					<Link
						to={`/${organization.id}/apps/linking?f=${btoa(
							`${NEW_WAY_TO_ENCODING_FILTER_SIGN}id|e=${entityId}`
						)}`}
						target={"_blank"}
						rel={"noreferrer"}
					>
						{name || t("enums.message_events.resource_type.APP_LINK")}
					</Link>
				);
		}
		return name;
	};

	const description = <div dangerouslySetInnerHTML={{ __html: messageEvent.description }} />;
	return (
		<div className="content">
			<Header
				title={`${t("modules.message_event.header.title")} "${t(
					`enums.message_events.type.${messageEvent.type}`
				)}"`}
			/>
			<div className={"content-preview"}>
				<div className="row row-gap-2">
					<div className="col-md-8">
						<Card>
							<Card.Body>
								<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
								<dl className="mb-0">
									<dt>{t("lib:common.word.type")}</dt>
									<dd>{t(`enums.message_events.type.${messageEvent.type}`)}</dd>
								</dl>

								<dl className="mb-0">
									<dt>{t("lib:common.word.source")}</dt>
									<dd>{t(`enums.message_events.source.${messageEvent.source}`, { ns: "lib" })}</dd>
								</dl>

								<dl className="mb-0">
									<dt>{t("modules.message_event.field.created_at.title")}</dt>
									<dd>{FormatDate(messageEvent.created_at, undefined, true)}</dd>
								</dl>
								{messageEvent.username && (
									<dl className="mb-0">
										<dt>
											{messageEvent.source === "INTERNAL"
												? t("common.word.terminal")
												: t("modules.message_event.field.username.title")}
										</dt>
										<dd>
											{messageEvent.source === "INTERNAL" && messageEvent.terminal?.name ? (
												<Link
													to={`/${organization.id}/settings/terminals/${messageEvent.terminal.id}`}
												>
													{messageEvent.terminal.name}
												</Link>
											) : (
												messageEvent.username
											)}
										</dd>
									</dl>
								)}
								<dl className="mb-0">
									<dt>{t("lib:common.word.description")}</dt>
									<dd>{description || messageEvent.description}</dd>
								</dl>
							</Card.Body>
						</Card>
					</div>
					<div className="col-md-4">
						{messageEvent.resources.length > 0 && (
							<Card>
								<Card.Body>
									<h5>{t("modules.message_event.field.resources.title")}</h5>
									{messageEvent.resources.map((resource) =>
										getResourceUrl(
											resource.resource_type,
											resource.entity_id,
											messageEvent?.type,
											resource?.name
										)
									)}
								</Card.Body>
							</Card>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrganizationLogsMessageEventsEditPage;
