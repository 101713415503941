import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput, registerObject } from "go-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import {
	MenuItemApi,
	PrintoutTemplateApi,
	PrintoutTemplateEntityApi,
} from "../../../../../../../../../services/Api/Organization/types";
import MenuPageItemModal from "../../../../Menu/pages/Menus/components/MenuPageItemModal";
import { ReactComponent as RemoveSVG } from "./../../../../../../../../../images/svg/remove.svg";

interface Props {
	form: UseFormReturn<PrintoutTemplateApi>;
}

const PrintoutTemplateEntities: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
		setValue,
		register,
	} = props.form;
	const { fields, remove, append } = useFieldArray({
		control,
		name: "entities",
		keyName: "key",
	});
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleSave = (item: MenuItemApi) => {
		setShowModal(false);
		const discountEntity = {
			entity_id: item.context_id,
			type: item.context_type,
			entity: item.entity,
			split_type: "ENABLED",
		};
		append(discountEntity as PrintoutTemplateEntityApi);
	};

	const splitOptions = [
		{
			label: t("enums.common.status.ENABLED", { ns: "lib" }),
			value: "ENABLED",
		},
		{
			label: t("enums.common.status.DISABLED", { ns: "lib" }),
			value: "DISABLED",
		},
	];

	useEffect(() => {
		fields.forEach((field: PrintoutTemplateEntityApi, index: number) => {
			registerObject(register, `entities.${index}`, ["entity_id", "type", "split_type"]);
			registerObject(register, `entities.${index}.entity`, ["id", "name"]);
			setValue(`entities.${index}`, field);
		});
	}, [fields]);

	return (
		<>
			<table className="table table-form">
				<thead>
					<tr>
						<th className="w-75">{t("modules.printout_template.field.name.title")}</th>
						<th className="w-25">{t("modules.printout_template.field.splited.title")}</th>
						<th className="action" />
					</tr>
				</thead>
				<tbody>
					{fields.map((item: PrintoutTemplateEntityApi, index: number) => {
						return (
							<tr key={fields[index].key}>
								<td>
									<FormInput
										type="hidden"
										register={register}
										errors={errors}
										name={`entities.${index}.id`}
									/>
									<FormInput
										type="hidden"
										errors={errors}
										register={register}
										name={`entities.${index}.type`}
									/>
									{item.type === "CATEGORY" && `${t("common.word.category")}: `}
									{item.type === "ITEM_GROUP" && `${t("common.word.item_group")}: `}
									<strong>{item.entity.name}</strong>
								</td>
								<td>
									<FormSelectGroup
										name={`entities.${index}.split_type`}
										errors={errors}
										isClearable={false}
										options={splitOptions}
										control={control}
										data-testid={`entities.${index}.split_type`}
									/>
								</td>
								<td className="action">
									<RemoveSVG className="icon" onClick={() => remove(index)} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<Button variant="add" type="button" onClick={() => setShowModal(true)}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
			{showModal && (
				<MenuPageItemModal
					contextTypes={["CATEGORY", "ITEM_GROUP"]}
					selectedValues={fields.map((x: PrintoutTemplateEntityApi) => {
						return {
							id: x.entity_id,
							type: x.type,
						};
					})}
					handleClose={() => setShowModal(false)}
					handleSave={handleSave}
				/>
			)}
		</>
	);
};
export default PrintoutTemplateEntities;
