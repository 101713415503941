import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useListenEvent } from "go-core/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { PosReportApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import PosReportPreview from "../../components/PosReportPreview";
import { switchToNextPOSReport, switchToPreviousPOSReport } from "../utils";

interface MatchParams {
	reportId: string;
}

const OrganizationSalesPosReportsPreviewPage: FC<RouteComponentProps> = (props) => {
	const history = useHistory();
	const [posReport, setPosReport] = useState<PosReportApi | undefined>(undefined);
	const { addFlash } = useFlash();
	const { reportId } = useParams<MatchParams>();
	const organization = useSelector(selectOrganization);
	const params: Record<string, any> = {
		include: "terminal,end_amounts,sub_reports,paids,paids.transaction,paids.client,full,currencies",
	};
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useListenEvent({
		eventName: "keyup",
		callback: (e) => {
			if (e?.key === "ArrowRight") {
				switchToNextPOSReport(reportId, history, organization);
			}

			if (e?.key === "ArrowLeft") {
				switchToPreviousPOSReport(reportId, history, organization);
			}
		},
	});

	useEffect(() => {
		onFetchPosReport();
	}, [reportId]);

	const onFetchPosReport = async () => {
		setPosReport(undefined);
		try {
			const res = await api.organization().getPosReport(Number(reportId), params);
			setPosReport(res);
			handleChangeTabTitle(`#${res.id} | ${t("modules.pos_report.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!posReport) {
		return <LoadingContainer />;
	}

	const handleUpdatePosReport = async () => {
		try {
			const res = await api.organization().getPosReport(Number(reportId), params);
			setPosReport(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<PosReportPreview
			handlePaidSave={handleUpdatePosReport}
			report={posReport}
			url={props.match.url}
			handleClose={(report) => setPosReport(report)}
		/>
	);
};
export default OrganizationSalesPosReportsPreviewPage;
