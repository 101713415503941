import React, { FC } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { UserState } from "go-security/services/users/types";
import { ReactComponent as AdminSVG } from "../../../images/svg/navigation/admin.svg";
import { ReactComponent as DashboardGroupedSVG } from "../../../images/svg/navigation/dashboard-grouped.svg";
import { ReactComponent as InvoiceSVG } from "../../../images/svg/navigation/invoice.svg";
import { ReactComponent as LocationsSVG } from "../../../images/svg/navigation/locations.svg";
import { ReactComponent as PartnerSVG } from "../../../images/svg/navigation/partner.svg";
import { ReactComponent as SaleSVG } from "../../../images/svgOld/navigation/sale.svg";
import { getGoAccountsApiURL } from "../../../utils/envutil";

interface NavigationProps {
	user: UserState;
	isAdmin?: boolean;
	goaccounts_url: string;
}

const NavigationUserMenu: FC<NavigationProps> = (props) => {
	const { t } = useTranslation();
	const { user } = props;
	const isAdmin = props.isAdmin !== undefined ? props.isAdmin : false;
	const isSupportRole = user?.roles?.includes("ROLE_SUPPORT");

	const isAdminPanelTabVisible = () => {
		return isAdmin || user?.roles?.includes("ROLE_OFFICE") || isSupportRole;
	};

	return (
		<Nav variant="pills" className="flex-column">
			<Nav.Item>
				<Nav.Link
					as={NavLink}
					to="/"
					isActive={(match: Record<string, any> | null, location: Record<string, any>) => {
						return location.pathname === "/" || location.pathname === "/locations";
					}}
				>
					<div className="nav-link-svg">
						<LocationsSVG />
					</div>
					<span>{t("components.navigation.locations")}</span>
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link as={NavLink} to="/dashboard">
					<div className="nav-link-svg">
						<DashboardGroupedSVG />
					</div>
					<span>{t("components.navigation.dashboard")}</span>
					<span className="badge bg-warning text-dark ms-auto">BETA</span>
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link as={NavLink} to="/group_reports">
					<div className="nav-link-svg">
						<SaleSVG />
					</div>
					<span>{t("components.navigation.group_reports")}</span>
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link as={NavLink} to="/invoices">
					<div className="nav-link-svg">
						<InvoiceSVG />
					</div>
					<span>{t("modules.invoice.header.title")}</span>
				</Nav.Link>
			</Nav.Item>
			{isSupportRole && (
				<Nav.Item>
					<Nav.Link as={NavLink} to="/jobs">
						<div className="nav-link-svg">
							<InvoiceSVG />
						</div>
						<span>{t("go_component.job.header.title", { ns: "lib" })}</span>
					</Nav.Link>
				</Nav.Item>
			)}
			{isAdmin && (
				<Nav.Item>
					<Nav.Link as={NavLink} to="/admin/tenants">
						<div className="nav-link-svg">
							<InvoiceSVG />
						</div>
						<span>{t("lib:go_security.tenant.header.title")}</span>
					</Nav.Link>
				</Nav.Item>
			)}
			{isAdmin && (
				<Nav.Item>
					<Nav.Link as={NavLink} to="/admin/client_details">
						<div className="nav-link-svg">
							<InvoiceSVG />
						</div>
						<span>{t("lib:go_component.client_details.header.title")}</span>
					</Nav.Link>
				</Nav.Item>
			)}
			{isAdminPanelTabVisible() && (
				<Nav.Item>
					<Nav.Link as="a" href={`${props.goaccounts_url}/admin`}>
						<div className="nav-link-svg">
							<AdminSVG />
						</div>
						<span>{t("components.navigation.admin_panel")}</span>
					</Nav.Link>
				</Nav.Item>
			)}
			<Nav.Item>
				<Nav.Link as="a" href={`${getGoAccountsApiURL()}/partnership`}>
					<div className="nav-link-svg">
						<PartnerSVG />
					</div>
					<span>{t("components.navigation.partner_panel")}</span>
				</Nav.Link>
			</Nav.Item>
		</Nav>
	);
};

export default NavigationUserMenu;
