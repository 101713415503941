import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ReactComponent as CustomFieldSVG } from "../../../../../../../../images/svg/bills/custom-fields.svg";
import { ReactComponent as ClientLogo } from "../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as DateSVG } from "../../../../../../../../images/svg/invoices/date.svg";
import { ReactComponent as InvoiceTypeSVG } from "../../../../../../../../images/svg/invoices/invoice_type.svg";
import { ReactComponent as PaymentDateSVG } from "../../../../../../../../images/svg/invoices/payment_date.svg";
import { ReactComponent as PlaceOfIssueSVG } from "../../../../../../../../images/svg/invoices/place_of_issue.svg";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	invoice: InvoiceApi;
	config: CustomFieldTemplateApi[];
}

const InvoiceInfoCard: FC<Props> = ({ invoice, config }) => {
	const { t } = useTranslation();
	const bankAccount = invoice.issuer?.bank_account?.number;

	return (
		<Card>
			<Card.Body className={"pb-0"}>
				<h5>{t("modules.invoice.field.info.title")}</h5>
				<div className={"row"}>
					<div className={"col-md-3"}>
						<div className="card-body-item">
							<PlaceOfIssueSVG />
							<div className="item-info">
								<span className="label">{t("modules.invoice.field.place_of_issue.title")}</span>
								<span>{invoice.place_of_issue}</span>
							</div>
						</div>
					</div>
					{invoice.dates?.issued_at && (
						<div className={"col-md-3"}>
							<div className="card-body-item">
								<DateSVG />
								<div className="item-info">
									<span className="label">{t("modules.invoice.field.date_of_issue.title")}</span>
									<span>
										{FormatDate(invoice.dates.issued_at, undefined, false, defaultFormatDate)}
									</span>
								</div>
							</div>
						</div>
					)}
					{invoice.dates.sold_at && (
						<div className={"col-md-3"}>
							<div className="card-body-item">
								<DateSVG />
								<div className="item-info">
									<span className="label">{t("modules.invoice.field.sold_date.title")}</span>
									<span>
										{FormatDate(invoice.dates.sold_at, undefined, false, defaultFormatDate)}
									</span>
								</div>
							</div>
						</div>
					)}
					{invoice.dates.payment_due_date && (
						<div className={"col-md-3"}>
							<div className="card-body-item">
								<PaymentDateSVG />
								<div className="item-info">
									<span className="label">{t("modules.invoice.field.payment_date.title")}</span>
									<span>
										{FormatDate(
											invoice.dates.payment_due_date,
											undefined,
											false,
											defaultFormatDate
										)}
									</span>
								</div>
							</div>
						</div>
					)}
					<div className={"col-md-3"}>
						<div className="card-body-item">
							<InvoiceTypeSVG />
							<div className="item-info">
								<span className="label">{t("modules.invoice.field.invoice_type.title")}</span>
								<span>{t(`enums.invoices.type.${invoice.type}`)}</span>
							</div>
						</div>
					</div>
					<div className={"col-md-3"}>
						<div className="card-body-item">
							<ClientLogo />
							<div className="item-info">
								<span className="label">{t("modules.invoice.field.issuer.title")}</span>
								<span>{invoice.signed_by}</span>
							</div>
						</div>
					</div>
					{invoice.issuer.bank_account && (
						<div className={"col-md-6"}>
							<div className="card-body-item">
								<InvoiceTypeSVG />
								<div className="item-info">
									<span className="label">{t("modules.invoice.field.account_number.title")}</span>
									<span>{bankAccount}</span>
								</div>
							</div>
						</div>
					)}
					{invoice.reason_of_correction && (
						<div className={"col-md-3"}>
							<div className="card-body-item">
								<InvoiceTypeSVG />
								<div className="item-info">
									<span className="label">
										{t("modules.invoice.field.reason_of_correction.title")}
									</span>
									<span>
										{t(`enums.invoices.reason_of_correction.${invoice.reason_of_correction}`)}
									</span>
								</div>
							</div>
						</div>
					)}
					{invoice.custom_fields?.map((cf, index) => {
						return (
							<div className={"col-md-3"} key={index}>
								<div className="card-body-item">
									<CustomFieldSVG />
									<div className="item-info">
										<span className="label">{config.find((f) => f.slug === cf.slug)?.name}</span>
										<span>{cf.value}</span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</Card.Body>
		</Card>
	);
};
export default InvoiceInfoCard;
