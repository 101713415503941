import React, { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface State {
	value?: string | "";
	onChange: (searchQuery: string) => void;
}

const ListSearch: FC<State> = (props) => {
	const [draftSearch, setDraftSearch] = useState<string | undefined>(props.value);
	const { t } = useTranslation();

	const onBlur = () => {
		if (draftSearch !== undefined) {
			props.onChange(draftSearch);
		}
	};

	const checkKeyDown = (e: any) => {
		if (e.key === "Enter" && draftSearch !== undefined) {
			props.onChange(draftSearch);
		}
	};

	return (
		<Form.Control
			type="search"
			placeholder={t("lib:common.word.search")}
			value={draftSearch}
			onKeyDown={(e) => checkKeyDown(e)}
			onChange={(e) => setDraftSearch(e.target.value)}
			onBlur={() => onBlur()}
		/>
	);
};

export default ListSearch;
