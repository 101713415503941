import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

const InvoiceDetailsNotExist = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	return (
		<form>
			<Alert variant={"danger"}>
				<span className={"me-1"}>{t(`constraints.invoice_details_not_exists`)}.</span>
				<Link to={`/${organization.id}/settings/info/invoice_details`}>
					{t("modules.invoice.action.set_invoice_details.title")}
				</Link>
			</Alert>
		</form>
	);
};
export default InvoiceDetailsNotExist;
