import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	InconsistentReference,
	InconsistentReferenceTitle,
} from "../../../../../../../../../components/Common/InconsistentReferenceTooltip/InconsistentReference";
import { OrderApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	id: number;
	orderId: string;
	inconsistent_reference_id: boolean;
}

const TransactionOrderCard = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [order, setOrder] = useState<undefined | OrderApi>(undefined);
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	const blackTint = { color: "#212529" };

	useEffect(() => {
		onFetchOrder();
	}, []);

	const onFetchOrder = async () => {
		const orderParams: Record<string, any> = {
			include: "items,items.product,invoice,tax_items",
		};
		try {
			const res = await api.organization().getOrder(props.id, orderParams);
			setOrder(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const discountAmount =
		order?.sub_total_price?.amount && order?.total_price?.amount
			? order?.sub_total_price?.amount - order?.total_price?.amount
			: undefined;

	const hasDiscount = () => {
		return order?.sub_total_price?.amount !== order?.total_price?.amount;
	};

	const drawTaxes = () => {
		return order?.tax_items
			?.sort((a, b) => a.tax_amount - b.tax_amount)
			?.map((tax, index) => (
				<div className="table-summary mb-2" key={`order-tax-${index}`}>
					<span className="summary-label text-muted font-weight-bold" style={{ fontSize: "12px" }}>
						{tax.tax_name}
					</span>
					<span className="text-muted font-weight-bold" style={{ fontSize: "12px" }}>
						{FormatMoney(tax.total_price_tax)}
					</span>
				</div>
			));
	};

	if (!order) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Card>
				<Card.Body>
					{
						<>
							<InconsistentReferenceTitle
								titleText={`${t("common.word.order")} #${props.orderId}`}
								inconsistentReferenceId={props.inconsistent_reference_id}
							/>
							<div className={"table-responsive mb-4"}>
								<table className="table table-preview">
									<thead>
										<tr>
											<th className={"w-60"}>{t("modules.transaction.field.name.title")}</th>
											<th className={"w-20"}>{t("common.word.quantity")}</th>
											<th className={"w-20"}>{t("common.word.value")}</th>
										</tr>
									</thead>
									<tbody>
										{order.items?.map((item, index) => {
											return (
												<tr key={index} className={"bordered"}>
													<td>
														{item.product.item_group_id ? (
															<Link
																target={"_blank"}
																rel={"noreferrer"}
																to={`/${organization.id}/menu/item_groups/${item.product.item_group_id}/`}
															>
																{item.product.name}
															</Link>
														) : (
															<>{item.product.name}</>
														)}
													</td>
													<td>x{item.quantity}</td>
													<td style={{ whiteSpace: "nowrap" }}>
														{FormatMoney(item.total_price)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							{hasDiscount() && (
								<>
									<div className="table-summary" style={{ color: "#6C757D" }}>
										<span className="summary-label mb-2">{t("common.word.discount_value")}</span>
										<span>
											{FormatMoney({ ...order?.sub_total_price, amount: discountAmount })}
										</span>
									</div>
									<div className="table-summary" style={{ color: "#6C757D" }}>
										<span className="summary-label mb-2">
											{t("modules.transaction.field.total_price_without_discount.title")}
										</span>
										<span>{FormatMoney(order?.sub_total_price)}</span>
									</div>
								</>
							)}
							<div className="table-summary mb-2" style={blackTint}>
								<span className="summary-label">{t("common.word.amount_net")}</span>
								<span>{FormatMoney(order.total_price_net)}</span>
							</div>
							<div className="table-summary mb-2" style={blackTint}>
								<span className="summary-label">
									{`${t("modules.transaction.field.total_vat.title")} ${
										order?.tax_items?.length === 1 ? order?.tax_items[0]?.tax_name : ""
									}`}
								</span>
								<span>
									{FormatMoney({
										amount: order.total_price_tax?.amount ?? 0,
										currency: order.total_price.currency,
									})}
								</span>
							</div>
							{order?.tax_items?.length > 1 && drawTaxes()}
							<div className="table-summary mb-4" style={blackTint}>
								<span className="summary-label">
									<strong>{t("common.word.amount_gross")}</strong>
								</span>
								<span>
									<strong>{FormatMoney(order.total_price)}</strong>
								</span>
							</div>
						</>
					}
					<div className={"d-flex flex-column"} style={{ marginLeft: "-8px", marginRight: "-8px" }}>
						<div className={"w-100"}>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/sales/orders/${order.id}`}
								className={"btn btn-add btn-block"}
							>
								{t("common.action.show_bill")}
							</Link>
						</div>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};
export default TransactionOrderCard;
