import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageColorForm from "go-app/components/ImageForm/ImageColorForm";
import { MenuApi, MenuPageApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	show: boolean;
	onHide: () => void;
	form: UseFormReturn<MenuApi>;
	pageItem: MenuPageApi;
	pageItemIndex: number;
	previousImage?: string;
}

const MenuListPageImageModal = ({ show, onHide, form, pageItem, pageItemIndex }: Props) => {
	const { t } = useTranslation();
	const { watch, setValue } = form;
	const [previousImageLink] = useState(pageItem.image_link);
	const [previousImage] = useState(pageItem.image);

	const onSubmit = () => {
		onHide();
	};

	const handleOnHide = () => {
		setValue(`pages.${pageItemIndex}.image_link`, previousImageLink);
		setValue(`pages.${pageItemIndex}.image`, previousImage);
		onHide();
	};

	return (
		<Modal show={show} onHide={handleOnHide} className="menu-list-page-image-modal">
			<Modal.Header closeButton>
				<Modal.Title>
					{t("modules.menu.field.set_section_image.title")} {`"${watch(`pages.${pageItemIndex}`).name}"`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ImageColorForm
					form={form}
					handleSave={() => {}}
					prefix={`pages.${pageItemIndex}.image`}
					mode="IMAGE"
					data={watch(`pages.${pageItemIndex}`)}
					resourceLink={watch(`pages.${pageItemIndex}.image_link`)}
					resourceData={watch(`pages.${pageItemIndex}.image`)}
					shouldOverrideImageLink
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button type="submit" onClick={onSubmit}>
					{t("lib:common.action.set")}
				</Button>
				<Button variant="light" onClick={handleOnHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default MenuListPageImageModal;
