import React, { FC, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ImageLinksApi } from "../../../../../../../services/Api/Organization/types";
import ImageLinkItem from "./ImageLinkItem";
import ImageLinksModal from "./ImageLinksModal";

interface Props {
	form: UseFormReturn<any>;
	handleCloseModal: () => void;
}

interface ImageProps {
	index: number;
	modalImageLinkItem: ImageLinksApi | undefined;
}

const ImageLinks: FC<Props> = ({ form, handleCloseModal }) => {
	const { control } = form;
	const { t } = useTranslation();
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "image_links",
		keyName: "key",
	});
	const [modalImageLinkItem, setModalImageLinkItem] = useState<ImageProps | undefined>(
		fields.length === 0
			? {
					index: 0,
					modalImageLinkItem: {} as ImageLinksApi,
			  }
			: undefined
	);
	const handleUpdateImage = (data: ImageLinksApi) => {
		if (modalImageLinkItem?.modalImageLinkItem && Object.keys(modalImageLinkItem.modalImageLinkItem).length > 0) {
			update(modalImageLinkItem.index, {
				...data,
				image_link: data.image?.image_data ? undefined : data.image_link,
			});
		} else {
			append(data);
		}
		setModalImageLinkItem(undefined);
	};

	const handleClose = () => {
		setModalImageLinkItem(undefined);
		if (fields.length === 0) {
			handleCloseModal();
		}
	};

	const handleRemove = (index: number) => {
		remove(index);
		if (fields.length === 1) {
			handleCloseModal();
		}
	};

	const handleRemoveImage = () => {
		remove(modalImageLinkItem?.index);
		setModalImageLinkItem(undefined);
		if (fields.length === 1) {
			handleCloseModal();
		}
	};

	return (
		<>
			{fields.length > 0 && (
				<>
					<Form.Label>{t("modules.menu.field.additional_photos.title")}</Form.Label>
					<Card className={"image-links-card"}>
						<Card.Body>
							<div className={"d-flex flex-wrap"}>
								{fields.map((f, index) => {
									return (
										<ImageLinkItem
											handleRemove={() => handleRemove(index)}
											handleEdit={(item) =>
												setModalImageLinkItem({
													index,
													modalImageLinkItem: item,
												})
											}
											key={fields[index].key}
											image={f}
										/>
									);
								})}
								<ImageLinkItem
									handleAppend={() =>
										setModalImageLinkItem({
											index: fields.length,
											modalImageLinkItem: {} as ImageLinksApi,
										})
									}
								/>
							</div>
						</Card.Body>
					</Card>
				</>
			)}
			{modalImageLinkItem?.modalImageLinkItem && (
				<ImageLinksModal
					handleRemove={handleRemoveImage}
					handleUpdate={handleUpdateImage}
					imageLinkItem={modalImageLinkItem.modalImageLinkItem}
					handleClose={handleClose}
				/>
			)}
		</>
	);
};
export default ImageLinks;
