import * as H from "history";
import { FILTER_SEPARATOR } from "../../core/components/Filter/services";
import { ListSelectedFilter } from "../../core/components/Filter/services/types";
import { ListAction } from "./actions";
import { listDataDecoderQuery } from "./decoder";
import { paramsToSegment } from "./segment-service";
import { FetchDefaultParameters, ListConfig, ListParamsType } from "./types";

export const listParamsInitialState = (config: ListConfig, location: H.Location): ListParamsType => {
	const urlParams = listDataDecoderQuery(new URLSearchParams(location.search));
	const externalSegments =
		config.externalSegments !== undefined
			? config.externalSegments.map((externalSegment) => paramsToSegment(externalSegment))
			: [];
	let segments = config.segments ? config.segments : [];
	segments = [...segments, ...externalSegments];
	let initParams: ListParamsType = {
		search: "",
		segments,
		columns: undefined,
		allColumnsInOrder: undefined,
		stickyColumnsDividerPosition: undefined,
		filters: undefined,
		sort: config?.selectedSorts,
		customFields: config.customFields,
		page: urlParams.page ?? FetchDefaultParameters.PAGE,
		size: urlParams.size ?? FetchDefaultParameters.SIZE,
		selectedSegment: config.selectedSegment ? config.selectedSegment : undefined,
	};

	const segmentFromUrl = initParams.segments?.find((segment) => segment.slug === urlParams.selectedSegment);
	const segmentFromConfig = initParams.segments?.find((segment) => segment.slug === config.selectedSegment);

	const selectedSegment = JSON.parse(JSON.stringify(segmentFromUrl || segmentFromConfig || null));

	if (selectedSegment) {
		initParams.selectedSegment = selectedSegment.slug;
		initParams.columns = selectedSegment.columns;
		if (selectedSegment.filters) {
			initParams.filters = selectedSegment.filters;
		}
		if (selectedSegment.stickyColumnsDividerPosition !== undefined) {
			initParams.stickyColumnsDividerPosition = selectedSegment.stickyColumnsDividerPosition;
		}
	}

	if (location.search) {
		if (urlParams.selectedSegment) {
			const selectedSegment = JSON.parse(
				JSON.stringify(initParams.segments?.filter((segment) => segment.slug === initParams.selectedSegment)[0])
			);
			if (selectedSegment) {
				initParams.selectedSegment = selectedSegment.slug;
				if (selectedSegment.columns && urlParams.columns?.join(",") !== selectedSegment.columns.join(",")) {
					selectedSegment.columns = urlParams.columns;
				}
				if (
					urlParams.filters &&
					urlParams.filters
						.map((f: ListSelectedFilter) => `${f.condition}|${f.value}`)
						.join(FILTER_SEPARATOR) !==
						selectedSegment.filters?.map((f: any) => `${f.condition}|${f.value}`).join(FILTER_SEPARATOR)
				) {
					selectedSegment.filters = urlParams.filters;
				}
			}
		}
		initParams = { ...initParams, ...urlParams };
	}
	if (config.externalSelectedFilters) {
		config.externalSelectedFilters.forEach((filter, index) => {
			if (initParams.filters === undefined) {
				initParams.filters = [];
				initParams.filters?.push({
					filterId: filter.id ? filter.id : "",
					value: filter.value ? filter.value : "",
					condition: filter.condition,
					valueInfo: filter.valueInfo,
				});
			} else if (!initParams.filters.find((f) => f.filterId === filter.id)) {
				initParams.filters?.push({
					filterId: filter.id ? filter.id : "",
					value: filter.value ? filter.value : "",
					condition: filter.condition,
					valueInfo: filter.valueInfo,
				});
			}
		});
	}

	return initParams;
};

export const ListParamsReducer = (state: ListParamsType, action: ListAction): ListParamsType => {
	switch (action.type) {
		case "setSearch":
			return {
				...state,
				search: action.search,
			};
		case "setFilters":
			return {
				...state,
				filters: action.filters,
			};
		case "setColumns": {
			const isEverythingTheSame =
				state.columns &&
				action.columns &&
				state.columns.length === action.columns.length &&
				state.columns.every((columnId, index) => action.columns[index] === columnId);

			if (isEverythingTheSame) {
				return state;
			}

			return {
				...state,
				columns: action.columns,
			};
		}
		case "setAllColumnsInOrder":
			return {
				...state,
				allColumnsInOrder: action.allColumnsInOrder,
			};
		case "setStickyColumnsDividerPosition":
			return {
				...state,
				stickyColumnsDividerPosition: action.dividerPosition,
			};
		case "setSegments":
			if (!state.selectedSegment) {
				return {
					...state,
					segments: action.segments,
				};
			}

			return {
				...state,
				segments: action.segments,
			};
		case "setSegment": {
			const matchedSegment = state.segments?.find((segment) => segment.slug === action.segment);

			return {
				...state,
				selectedSegment: action.segment,
				filters: matchedSegment?.filters,
				page: FetchDefaultParameters.PAGE,
				sort: matchedSegment?.sort,
				columns: matchedSegment?.columns,
				stickyColumnsDividerPosition: matchedSegment?.stickyColumnsDividerPosition,
			};
		}
		case "updateSegment": {
			let newSegment = action.data.segment;
			const newSegments = state.segments !== undefined ? state.segments : [];
			const oldSegment = newSegments.filter((segment) => segment.slug === newSegment.slug)[0];
			if (oldSegment === undefined) {
				newSegments.push(newSegment);
			} else {
				newSegment = { ...newSegment };
				newSegments[newSegments.indexOf(oldSegment)] = newSegment;
			}

			if (action.data.preventReturnUpdatedSegment) {
				return state;
			}

			return {
				...state,
				segments: [...newSegments],
				selectedSegment: newSegment.slug,
				filters: newSegment?.filters,
				sort: newSegment?.sort,
				columns: newSegment?.columns,
				stickyColumnsDividerPosition: newSegment?.stickyColumnsDividerPosition,
			};
		}
		case "setSize":
			return {
				...state,
				size: action.size,
				page: FetchDefaultParameters.PAGE,
			};

		case "setPage":
			return {
				...state,
				page: action.page,
			};

		case "setSort":
			return {
				...state,
				sort: action.sort,
			};
	}
	return state;
};
