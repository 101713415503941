import React, { FC } from "react";

interface Props {
	title: string;
	className?: string;
}

const Tag: FC<Props> = ({ title, className }) => {
	return (
		<div className={`${className ? `${className}` : ""} tag`}>
			<span>#{title}</span>
		</div>
	);
};
export default Tag;
