import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { invoicePreviewIncludes } from "../../pages/Index/Advances/pages/Preview";

interface Props {
	onAdd: (invoice: InvoiceApi) => void;
	onClose: () => void;
	invoice: InvoiceApi;
}

interface FormProps {
	advance_id: number;
}

const AddPreviousAdvanceModal = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const form = useForm<FormProps>({
		defaultValues: { advance_id: undefined },
		criteriaMode: "all",
	});

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = form;

	const searchInvoices = async (search: string, params: Record<string, any>) => {
		const newParams = {
			f: btoa(
				unescape(
					encodeURIComponent(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}document_type=ADVANCE${FILTER_SEPARATOR}client=${props.invoice.recipient_id}${FILTER_SEPARATOR}issued_at|lte=${props.invoice.dates.issued_at}`
					)
				)
			),
			search,
			...params,
		};
		const res = await api.organization().getInvoices(newParams);
		const ids: number[] = [
			props.invoice.id,
			...props.invoice.linked_invoices.filter((f) => f.document_type === "ADVANCE").map((x) => x.id),
		];
		return res.filter((f: InvoiceApi) => !ids.includes(f.id));
	};

	const onSubmit = handleSubmit(async (data: FormProps) => {
		setLoading(true);
		const params: Record<string, any> = invoicePreviewIncludes;
		const dto = { ...props.invoice };
		dto.previous_advances = dto.previous_advances
			? [...dto.previous_advances, { advance_id: data.advance_id }]
			: [{ advance_id: data.advance_id }];
		try {
			const res = await api.organization().updateAdvance(dto, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			props.onAdd(res);
		} catch (err) {
			handleError.alert(err, addFlash);
			setLoading(false);
		}
	});

	const getOrderOption = (item: InvoiceApi) => {
		return `${t("enums.invoices.document_type.ADVANCE")} #${item.number}`;
	};

	return (
		<Modal show={true} onHide={() => props.onClose()}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.invoice.action.add_previous_advances.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormSelectGroup
					name="advance_id"
					errors={errors}
					getOptionLabel={(item) => getOrderOption(item)}
					defaultValue={undefined}
					getOptionValue={(option) => option.id}
					loadOptions={searchInvoices}
					control={control}
					data-testid="advance_id"
				/>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading variant="primary" onClick={onSubmit} loading={loading}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
				<Button variant="light" onClick={() => props.onClose()}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default AddPreviousAdvanceModal;
