import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { TranslationApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	showTranslation: boolean;
	setShowTranslations: (show: boolean) => void;
	translations: TranslationApi[];
	itemsLength?: number;
}

const EntityTranslationsModalButton = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const { translations } = props;
	const organizationLanguages = useSelector(selectOrganization).languages;

	const checkTranslationCompletion = () => {
		const everyLanguageHasTranslations =
			translations.every((translation) => translation && translation.name && translation.description) &&
			translations.length === organizationLanguages.length;

		const everyLanguageValueIsEmpty =
			translations.every((translation) => translation && !translation.name && !translation.description) ||
			translations.length === 0;

		if (everyLanguageHasTranslations) return "full";
		if (everyLanguageValueIsEmpty) return "none";
		return "partial";
	};

	return (
		<Button
			variant={"light"}
			id="entity_translation"
			style={{ position: "relative" }}
			onClick={() => props.setShowTranslations(!props.showTranslation)}
		>
			{t("common.word.translations")}{" "}
			<span className={`translation-button dot-${checkTranslationCompletion()}`} />
		</Button>
	);
};

export default EntityTranslationsModalButton;
