import React from "react";
import { useTranslation } from "react-i18next";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";

const ReportsNavigation = (): JSX.Element => {
	const { t } = useTranslation();

	const items: SubNavigationItemProps[] = [
		{
			title: t("modules.sale_report.header.orders.title"),
			route: `/group_reports/orders`,
		},
		{
			title: t("modules.sale_report.header.products.title"),
			route: "/group_reports/products",
		},
		{
			title: t("modules.sale_report.header.categories.title"),
			route: "/group_reports/categories",
		},
		{
			title: t("common.word.payment_methods"),
			route: "/group_reports/payments",
		},
		{ title: t("common.word.discounts"), route: "/group_reports/order_promotions" },
		{ title: t("modules.transaction.header.title"), route: "/group_reports/transactions" },
		{ title: t("modules.invoice.header.title"), route: "/group_reports/invoices" },
		{ title: t("modules.efficiency_report.header.group_report.title"), route: "/group_reports/efficiencies" },
		{ title: t("modules.guest_group_report.header.title"), route: "/group_reports/guests" },
	];

	return <SubNavigationItems title={t("components.navigation.reports")} items={items} />;
};
export default ReportsNavigation;
