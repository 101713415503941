import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { ItemTranslationItemNameTranslationFormApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { isVirtualTableColumnHide } from "../../../../../../components/VirtualTable/utils";

interface Props {
	columns: ItemTranslationItemNameTranslationFormApi[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	getHeaderStyles: (columnId: string) => { width: number };
}

const TranslationsVirtualTableHeaderLocalesColumns = ({
	columns,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	getHeaderStyles,
}: Props) => {
	const drawColumns = () => {
		return columns.map((column) => {
			const isHide = isVirtualTableColumnHide(column.id, columns);

			return (
				<ReactResizeDetector key={column.id} onResize={(width) => handleHeaderResize(width, column.id)}>
					<th
						id={column.id}
						key={column.id}
						title={column.id}
						className={`${isHide ? "d-none" : ""}`}
						onMouseDown={(e) => handleMouseDown(e)}
						onMouseUp={(e) => handleMouseUp(e, column.id, true)}
					>
						<div style={getHeaderStyles(column.id)} className="d-flex align-items-center">
							{column.name}
						</div>
					</th>
				</ReactResizeDetector>
			);
		});
	};
	return <>{drawColumns()}</>;
};

export default TranslationsVirtualTableHeaderLocalesColumns;
