import { stringifySearchParams } from "go-core";
import { SegmentFilterValue, SegmentType } from "go-segment/components/types";
import { SegmentApi } from "go-segment/services/types";
import {
	AMPERSAND_VALUE,
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "../../core/components/Filter/services";
import {
	getUrlParamsWithoutDecoding,
	listFiltersDecode,
	listFiltersEncode,
} from "../../core/components/Filter/services/decoder";
import { ListSelectedFilter } from "../../core/components/Filter/services/types";
import { updateFilterValuesForSelectedFilters } from "./selectors";
import { GoListSegmentType, ListConfig, ListParamsType } from "./types";

export const encodeSegmentValue = (segment: GoListSegmentType): string => {
	const paramsArray: Record<string, any> = {};
	if (segment.columns) {
		paramsArray.columns = segment.columns.join(",");
	}
	if (segment.filters && segment.filters.length > 0) {
		const f = listFiltersEncode(segment.filters, true);
		paramsArray.f = f;
	}
	if (segment.sort && segment.sort?.length > 0) {
		paramsArray.sort = segment.sort.join(",");
	}
	if (segment.allColumnsInOrder && segment.allColumnsInOrder?.length > 0) {
		paramsArray.allColumnsInOrder = segment.allColumnsInOrder.join(",");
	}
	if (segment.stickyColumnsDividerPosition !== undefined) {
		paramsArray.stickyColumnsDividerPosition = segment.stickyColumnsDividerPosition;
	}

	paramsArray.position = segment.position;
	paramsArray.default = segment.default;

	paramsArray.value = segment.value_v2;
	const query = stringifySearchParams(paramsArray);
	return btoa(unescape(encodeURIComponent(query)));
};

export const decodeSegmentValue = (
	segment: GoListSegmentType,
	value: string,
	filterValues?: SegmentFilterValue[]
): GoListSegmentType => {
	const valueDecode = decodeURIComponent(escape(atob(value)));
	const decodeParams = Object.fromEntries(new URLSearchParams(valueDecode));

	if (decodeParams.f) {
		let decodedF = decodeURIComponent(escape(atob(decodeParams.f)));

		decodedF = decodedF.substring(1);
		decodedF = decodedF.replaceAll("&", AMPERSAND_VALUE);
		decodedF = decodedF.replaceAll(FILTER_SEPARATOR, "&");

		const params = new URLSearchParams(decodedF);
		const valuesFromParams: { key: string; value: string }[] = [];
		const paramsWithoutDecoding = getUrlParamsWithoutDecoding(decodedF);
		let valuesFromParamsWithoutDecoding: { key: string; value: string }[] = [];

		for (const [key, value] of params.entries()) {
			const splittedKey = key.split("|");
			const valueIncludedInKey =
				splittedKey[1] === "a" ||
				splittedKey[1] === "u" ||
				splittedKey[1] === "true" ||
				splittedKey[1] === "false";
			valuesFromParams.push({
				key: key.replace(" ", ""),
				value: valueIncludedInKey ? splittedKey[1] : value.replaceAll(AMPERSAND_VALUE, "&"),
			});
		}

		for (const [key, value] of Object.entries(paramsWithoutDecoding)) {
			const exactKey = valuesFromParams.find((item) => key.endsWith(item.key));
			if (exactKey) {
				if (key.startsWith("&")) {
					key.substring(1);
				}
				valuesFromParamsWithoutDecoding.push({
					key,
					value: value.replaceAll(AMPERSAND_VALUE, "&"),
				});
			}
		}
		valuesFromParamsWithoutDecoding = [
			...valuesFromParamsWithoutDecoding,
			...valuesFromParams
				.filter((valuesFromParam) => {
					const splittedKey = valuesFromParam.key.split("|");
					if (
						(splittedKey[1] === "a" ||
							splittedKey[1] === "u" ||
							splittedKey[1] === "true" ||
							splittedKey[1] === "false") &&
						valuesFromParam.value
					)
						return valuesFromParam;
					return undefined;
				})
				.filter((value) => value),
		];

		const encodedParamsWithoutDecodingAsString = valuesFromParamsWithoutDecoding
			.map((item) => {
				const encodedValue = item.value
					.split(FILTER_VALUE_SEPARATOR)
					.map((itemValue) => encodeURIComponent(itemValue))
					.join(FILTER_VALUE_SEPARATOR);
				return `${item.key}=${encodedValue}`;
			})
			.join(FILTER_SEPARATOR);

		const finalF = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${encodedParamsWithoutDecodingAsString}`;
		const encodedF = btoa(unescape(encodeURIComponent(finalF)));

		const selectedFilters = listFiltersDecode(encodedF, true);
		if (selectedFilters.length > 0) {
			segment.filters = updateFilterValuesForSelectedFilters(selectedFilters, filterValues);
		}
	}
	if (decodeParams.columns) {
		segment.columns = decodeParams.columns.replaceAll(" ", "+").split(",");
	}
	if (decodeParams.sort) {
		segment.sort = decodeParams.sort.replaceAll(" ", "+").split(",");
	}
	if (decodeParams.allColumnsInOrder) {
		segment.allColumnsInOrder = decodeParams.allColumnsInOrder.replaceAll(" ", "+").split(",");
	}
	if (decodeParams.stickyColumnsDividerPosition) {
		segment.stickyColumnsDividerPosition = Number(decodeParams.stickyColumnsDividerPosition);
	}

	return segment;
};

export const segmentToParams = (segment: GoListSegmentType): GoListSegmentType => {
	segment.value_v2 = encodeSegmentValue(segment);
	return segment;
};

export const paramsToSegment = (params: SegmentType): GoListSegmentType => {
	let segment: GoListSegmentType = {
		id: String(params.id),
		name: params.name,
		slug: params.slug,
		editable: true,
		default: params.default,
		position: params.position,
	};
	if (params.value_v2) {
		segment = decodeSegmentValue(segment, params.value_v2, params.filter_values);
	}
	return segment;
};

export const getSortedFilters = (filters: ListSelectedFilter[] | undefined) => {
	return filters?.map((filter: any) =>
		Object.keys(filter)
			.sort()
			.reduce((obj, key) => {
				if (typeof filter[key] !== "object")
					//eslint-disable-next-line
					//@ts-ignore
					obj[key] = filter[key];
				return obj;
			}, {})
	);
};

export const isSegmentChanged = (state: ListParamsType, segment: GoListSegmentType, config: ListConfig): boolean => {
	if (segment) {
		const stateFilters = state?.filters?.length === 0 ? undefined : state?.filters;
		const segmentFilters = segment?.filters?.length === 0 ? undefined : segment.filters;
		const sortedStateFilters = getSortedFilters(stateFilters);
		const sortedSegmentFilters = getSortedFilters(segmentFilters);

		if (JSON.stringify(sortedStateFilters) !== JSON.stringify(sortedSegmentFilters)) {
			return true;
		}

		if (config.isNumberOfStickyColumnsDynamic) {
			if (segment.stickyColumnsDividerPosition !== undefined) {
				if (state.stickyColumnsDividerPosition !== segment.stickyColumnsDividerPosition) {
					return true;
				}
			} else if (state.stickyColumnsDividerPosition !== config.numberOfStickyColumnsAtTheStart) {
				return true;
			}
		}

		if (Array.isArray(config.selectedColumns) && Array.isArray(state.columns)) {
			const configColumns = config.selectedColumns.join(",");
			let segmentColumns = segment?.columns?.join(",");
			const stateColumns = state.columns.join(",");

			if (config.shouldDisableSortingOfStickyColumns && config.numberOfStickyColumnsAtTheStart) {
				const nonSortableColumns = state.columns.slice(0, config.numberOfStickyColumnsAtTheStart);
				const nonSortableColumnsMissingFromSegment = nonSortableColumns.filter(
					(columnId) => !segment.columns?.includes(columnId)
				);

				if (nonSortableColumnsMissingFromSegment.length && segmentColumns) {
					segmentColumns = `${nonSortableColumnsMissingFromSegment.join(",")},${segmentColumns}`;
				}
			}

			if (
				(segmentColumns && stateColumns !== segmentColumns) ||
				(!segmentColumns && stateColumns !== configColumns)
			)
				return true;
		}

		const stateSort = state?.sort?.length === 0 ? undefined : state?.sort?.join(",");
		const segmentSort = segment?.sort?.length === 0 ? undefined : segment?.sort?.join(",");
		if (segmentSort !== stateSort) {
			return true;
		}

		return false;
	}
	if (state.filters !== undefined) {
		return true;
	}

	if (state.columns?.join(",") !== config.selectedColumns?.join(",")) {
		return true;
	}
	return false;
};

export const getSelectedSegmentForListConfig = (segments: SegmentApi[], defaultSegment?: string) => {
	return segments.find((segment) => segment.default)?.slug ?? defaultSegment;
};
