import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PriceListPredicateApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import SubmenuPriceListsNavigation from "../../../../components/SubmenuPriceListsNavigation";
import PredicatesForm from "../../components/PredicatesForm";

const OrganizationMenuPriceListsPredicatesIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [loading, setLoading] = useState<boolean>(true);
	const [predicates, setPredicates] = useState<PriceListPredicateApi[]>([]);
	const [showAddNewPredicateModal, setShowAddNewPredicateModal] = useState<boolean>(false);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.predicate.header.title"));
		onFetchPredicates();
	}, []);

	const onFetchPredicates = async () => {
		try {
			const params = {
				include: "conditions,menu_price_list",
			};
			const res = await api.organization().getPriceListPredicates(params);
			setPredicates(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const buttons = [
		{
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
			action: () => setShowAddNewPredicateModal(true),
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setShowAddNewPredicateModal(true),
		},
	];

	if (loading) return <LoadingContainer />;

	return (
		<>
			<MobileActions actions={mobileActions} />
			{!isMobile && <Header title={t("modules.price_list.header.title")} buttons={buttons} />}
			<SubmenuPriceListsNavigation />
			<PredicatesForm
				predicates={predicates}
				setPredicates={setPredicates}
				onRefresh={onFetchPredicates}
				showAddNewPredicateModal={showAddNewPredicateModal}
				setShowAddNewPredicateModal={setShowAddNewPredicateModal}
			/>
		</>
	);
};

export default OrganizationMenuPriceListsPredicatesIndexPage;
