import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as AlertSVG } from "../../../images/svg/alert.svg";

interface InconsistentReferenceProps {
	svgCustomStyles?: React.CSSProperties;
}

export const InconsistentReference = ({ svgCustomStyles }: InconsistentReferenceProps) => {
	const { t } = useTranslation();
	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id="inconsistent-reference">
					<span>{t("modules.transaction.field.inconsistent_reference_id.title")}</span>
				</Tooltip>
			}
		>
			<AlertSVG style={svgCustomStyles} />
		</OverlayTrigger>
	);
};

interface Props {
	titleText: string;
	inconsistentReferenceId?: boolean;
}

export const InconsistentReferenceTitle = ({ titleText, inconsistentReferenceId }: Props) => {
	if (inconsistentReferenceId) {
		return (
			<div className="d-flex align-items-start gap-1">
				<h5>{titleText}</h5>
				<InconsistentReference svgCustomStyles={{ marginTop: "2px" }} />
			</div>
		);
	}

	return <h5>{titleText}</h5>;
};
