import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	FormatDateToDateHoursRange,
	formatDateToString,
	formatStringToDate,
} from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { parseDatesToDefaultString } from "go-list/utils/daterangeutils";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { PosReportApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	report: PosReportApi;
}

const InfoCard: FC<Props> = ({ report }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const getDateFilter = () => {
		if (!report.closed_at) {
			return `date_range|gt=${formatDateToString(formatStringToDate(report.opened_at))}`;
		}
		return `date_range|bt=${parseDatesToDefaultString(
			formatStringToDate(report.opened_at),
			formatStringToDate(report.closed_at)
		)}`;
	};

	return (
		<Card>
			<Card.Body>
				<h5>{t("lib:common.word.informations")}</h5>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("common.word.terminal")}</span>
						<span>
							{report.terminal.id ? (
								<Link
									to={`/${organization.id}/settings/terminals/${report.terminal.id}`}
									target="_blank"
								>
									{report.terminal.name}
								</Link>
							) : (
								<>{report.terminal.name}</>
							)}
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.id.title")}</span>
						<span>#{report.id}</span>
					</div>
				</div>
				{report.description && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("lib:common.word.description")}</span>
							<span>{report.description}</span>
						</div>
					</div>
				)}
				{report.shift_work_report_id && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.pos_report.field.report_shiftwork.title")}</span>
							<span>
								<Link to={`/${organization.id}/sales/pos_reports/${report.shift_work_report_id}`}>
									#{report.shift_work_report_id}
								</Link>
							</span>
						</div>
					</div>
				)}
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("lib:common.word.date")}</span>
						<span>{FormatDateToDateHoursRange(report.opened_at, report.closed_at)}</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.order_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/sales/orders?f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=CLOSED${FILTER_SEPARATOR}report|e=${report.id}`
										)
									)
								)}`}
							>
								{FormatMoney(report.order_amount)} / {report.order_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.void_order_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/sales/orders?f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=VOIDED${FILTER_SEPARATOR}report|e=${report.id}`
										)
									)
								)}`}
							>
								{report.void_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.invoices_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/invoices?f=${btoa(
									`${NEW_WAY_TO_ENCODING_FILTER_SIGN}report|e=${report.id}`
								)}`}
							>
								{FormatMoney(report.invoice_amount)} / {report.invoice_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.discounts_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/sales/orders?f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=CLOSED${FILTER_SEPARATOR}report|e=${report.id}${FILTER_SEPARATOR}discount_count|gt=0`
										)
									)
								)}`}
							>
								{FormatMoney(report.discount_amount)} / {report.discount_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.storn_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/reports/storn?f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}report|e=${report.id}${FILTER_SEPARATOR}saved|true`
										)
									)
								)}`}
							>
								{FormatMoney(report.storno_amount)} / {report.storno_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.storn_unsaved_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/reports/storn?f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}report|e=${report.id}${FILTER_SEPARATOR}saved|false`
										)
									)
								)}`}
							>
								{FormatMoney(report.storno_unsaved_amount)} / {report.storno_unsaved_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.payments_value.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/sales/transactions?s=all&f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=SUCCESS${FILTER_SEPARATOR}report|e=${report.id}`
										)
									)
								)}`}
							>
								{FormatMoney(report.payment_amount)} / {report.payment_count}
							</Link>
						</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.fiscal_reports_and_quantity.title")}</span>
						<span>
							<Link
								target={"_blank"}
								rel={"noreferrer"}
								to={`/${organization.id}/sales/orders?f=${btoa(
									unescape(
										encodeURIComponent(
											`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=CLOSED${FILTER_SEPARATOR}report|e=${report.id}${FILTER_SEPARATOR}fiscalization_status|e=FISCALIZED`
										)
									)
								)}`}
							>
								{FormatMoney(report.fiscal_receipt_amount)} /{" "}
								{t(`modules.pos_report.field.fiscal_reports.title`, {
									count: report.fiscal_receipt_count,
								})}
							</Link>
						</span>
					</div>
				</div>
				{report.number_of_guests?.toString() && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.pos_report.field.number_of_guests.title")}</span>
							<span>{report.number_of_guests}</span>
						</div>
					</div>
				)}
				{report.employee_count && report.employee_count > 0 ? (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.pos_report.field.employees_amount.title")}</span>
							<span>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/reports/efficiency_report?f=${btoa(
										unescape(
											encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${getDateFilter()}`)
										)
									)}&groups=DAY_OF_WEEK`}
								>
									{report.employee_count}
								</Link>
							</span>
						</div>
					</div>
				) : null}
				{report.employee_salary_amount && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.pos_report.field.employee_salary_amount.title")}</span>

							<span>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/employees/worktimes_report?f=${btoa(
										`${NEW_WAY_TO_ENCODING_FILTER_SIGN}report|e=${report.id}`
									)}&groups=EMPLOYEE${FILTER_VALUE_SEPARATOR}DATE_RANGE${FILTER_VALUE_SEPARATOR}WORKPLACE`}
								>
									{FormatMoney(report.employee_salary_amount)}
								</Link>
							</span>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default InfoCard;
