import { useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonLoading } from "go-form";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import EmptyData from "go-core/components/EmptyData";
import { Loading } from "go-core/components/Loading";
import { useInfiniteScroll } from "go-core/hooks/useInfiniteScroll";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import InputWithSearch from "../../../../../../../../../components/Common/Inputs/InputWithSearch";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import { MenuItemApi, MenuItemEntityApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import TaxModalForm from "../../../../Settings/pages/Taxes/components/TaxModalForm";
import CreateMenuPageItemCategoryModal from "./CreateMenuPageItemCategoryModal";
import CreateMenuPageItemItemGroupModal from "./CreateMenuPageItemItemGroupModal";

type ContextType = "CATEGORY" | "ITEM_GROUP" | "ITEM" | "TAX";

interface Props {
	handleClose: () => void;
	handleSave: (data: MenuItemApi) => void;
	handleUpdate?: (data: MenuItemApi) => void;
	item?: MenuItemApi;
	selectedValues?: Array<Record<string, any>>;
	contextTypes?: ContextType[];
	defaultType?: string;
	hasMultipleChoice?: boolean;
	currentMenuItems?: MenuItemApi[];
	itemButtonLoading?: number;
}

const MenuPageItemModal = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const contextTypes = props.contextTypes ? props.contextTypes : ["CATEGORY", "ITEM_GROUP", "ITEM"];
	const [menuItem, setMenuItem] = useState<MenuItemApi>(props.item ? props.item : ({} as MenuItemApi));
	const [searchText, setSearchText] = useState("");
	const [contextType, setContextType] = useState<string>(
		props.item?.context_type
			? props.item.context_type
			: props.defaultType
			? props.defaultType
			: contextTypes[0] || "CATEGORY"
	);
	const [itemBeingCreated] = useState(false);
	const [newItemMode, setNewItemMode] = useState(false);
	const organization = useSelector(selectOrganization);
	const [newMenuItems, setNewMenuItems] = useState<MenuItemApi[]>([]);
	const scrollRef = useRef(null);

	const fetchItems = (params: Record<string, any>, options?: CancelTokenSource) => {
		const { search, ...restParams } = params;
		let selectedIds;

		switch (contextType) {
			case "ITEM":
				selectedIds = props.selectedValues
					?.filter((f) => f.type === "ITEM")
					.map((x) => x.id)
					.join(FILTER_VALUE_SEPARATOR);
				return api.organization().getItemsSearchSelect(
					search,
					{ "id|ne": selectedIds ? selectedIds : "-1", ...restParams },
					{
						cancelToken: options?.token,
					}
				);
			case "CATEGORY":
				selectedIds = props.selectedValues
					?.filter((f) => f.type === "CATEGORY")
					.map((x) => x.id)
					.join(FILTER_VALUE_SEPARATOR);
				return api.organization().getCategoriesSearchSelect(
					search,
					{ "id|ne": selectedIds ? selectedIds : "-1", ...restParams },
					{
						cancelToken: options?.token,
					}
				);
			case "ITEM_GROUP":
				selectedIds = props.selectedValues
					?.filter((f) => f.type === "ITEM_GROUP")
					.map((x) => x.id)
					.join(FILTER_VALUE_SEPARATOR);
				return api.organization().getItemGroupsSearchSelect(
					search,
					{ "id|ne": selectedIds ? selectedIds : "-1", ...restParams },
					{
						cancelToken: options?.token,
					}
				);
			case "TAX":
				selectedIds = props.selectedValues
					?.filter((f) => f.type === "TAX")
					.map((x) => x.id)
					.join(FILTER_VALUE_SEPARATOR);
				return apiOrganization.getTaxesSearchSelect(
					search,
					{ "id|ne": selectedIds ? selectedIds : "-1", ...restParams },
					{
						cancelToken: options?.token,
					}
				);
			default:
				return [];
		}
	};

	const { items, loading } = useInfiniteScroll({
		fetchItems,
		search: searchText,
		scrollContainerElementRef: scrollRef,
		fetchDependencies: [contextType],
	});

	const getFilteredItems = () => {
		switch (contextType) {
			case "ITEM":
			case "ITEM_GROUP":
				if (props.hasMultipleChoice) {
					const noDuplicatedData = [
						...newMenuItems,
						...items.filter((item: Record<string, any>) => {
							const existingItem = newMenuItems.find((i) => item.id === i.id);
							if (!existingItem) return item;
							return false;
						}),
					];
					return noDuplicatedData?.filter(
						(item) => !props.selectedValues?.map((value) => Number(value.id)).includes(Number(item.id))
					);
				}
				return items;
			default:
				return items;
		}
	};

	const getCheckboxesValue = (item: MenuItemApi) => {
		return newMenuItems.includes(item);
	};

	const changeSelectedItem = (item: any) => {
		let contextId = item.id;
		if (contextType === "ITEM" && item.items && item.items[0].id) {
			contextId = item.items[0].id;
		}
		const newMenuItem = {
			context_type: contextType,
			context_id: contextId,
			entity: {
				name: item.name ? item.name : item.label,
				image_link: item.image_link,
				color: item.color,
				label: item.image_label,
				parent_id: item.id,
				price: item?.price,
				status: item?.status,
			} as MenuItemEntityApi,
		} as MenuItemApi;
		setMenuItem(newMenuItem);
		if (contextType === "ITEM") {
			newMenuItem.entity.parent_name = item.item_group_name ? item.item_group_name : item.name;
			newMenuItem.entity.parent_id = item.item_group_id;
			if (item.items && item.items[0].name) {
				newMenuItem.entity.name = item.items[0].name;
				newMenuItem.entity.price = item.items[0].price;
			}
		}
		if (props.item?.context_id && props.handleUpdate) {
			props.handleUpdate(newMenuItem);
		} else {
			props.handleSave(newMenuItem);
		}
	};

	const getRouteName = (contextName: string) => {
		if (contextName === "CATEGORY") {
			return "categories";
		}
		return "item_groups";
	};

	const onChangeSelectedItems = () => {
		newMenuItems &&
			newMenuItems.forEach((item) => {
				changeSelectedItem(item);
			});
	};

	const selectNewMenuItems = (item: MenuItemApi) => {
		if (newMenuItems && newMenuItems.length > 0) {
			const index = newMenuItems.findIndex((i) => i.id === item.id);
			const newArr = [...newMenuItems];
			if (index > -1) {
				newArr.splice(index, 1);
				setNewMenuItems([...newArr]);
			} else setNewMenuItems([...newArr, item]);
		} else setNewMenuItems([item]);
	};

	const isItemAlreadySelected = (item: any) => {
		const currentlySelectedItems = props.currentMenuItems?.filter(
			(menuArrayItem) => menuArrayItem.context_id !== undefined
		);

		if (!currentlySelectedItems || !currentlySelectedItems.length) {
			return false;
		}

		const currentlySelectedItemsWithTheSameContext = currentlySelectedItems.filter(
			(menuArrayItem) => menuArrayItem.context_type === contextType
		);

		const isItemSelected = currentlySelectedItemsWithTheSameContext?.some(
			(menuArrayItem) => menuArrayItem.context_id.toString() === item.id?.toString()
		);

		return isItemSelected;
	};

	return (
		<Modal className="menu-page-item-modal" show={true} onHide={props.handleClose}>
			{!newItemMode ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							{props.item?.entity ? (
								<>
									{t("modules.menu.action.edit_position.title")}{" "}
									<Link
										to={`/${organization.id}/menu/${getRouteName(props.item.context_type)}/${
											contextType === "ITEM"
												? props.item.entity?.parent_id
												: props.item.context_id
										}`}
										target={"_blank"}
										rel="noreferrer"
									>
										{props.item.entity.name}
									</Link>
								</>
							) : (
								t("modules.menu.action.add_new_position.title")
							)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="items" ref={scrollRef}>
						<div className="sticky-modal-search-header">
							{contextTypes.length > 0 && (
								<div className="options form-group">
									{contextTypes.map((type, index) => {
										return (
											<Button
												key={index}
												variant={contextType === type ? "primary" : "primary-light"}
												onClick={() => setContextType(type)}
											>
												{t(`enums.menu.entity_type.${type}`)}
											</Button>
										);
									})}
								</div>
							)}
							<div className="form-group search-input">
								<InputWithSearch
									loading={itemBeingCreated}
									value={searchText}
									onButtonClick={() => setNewItemMode(true)}
									onInput={(msg: string) => setSearchText(msg)}
								/>
							</div>
						</div>
						<>
							<div className="table-responsive">
								<table className="table">
									<tbody>
										<>
											{getFilteredItems().length > 0
												? getFilteredItems().map((responseItem: any, index: number) => {
														return (
															<tr
																key={index}
																className={
																	isItemAlreadySelected(responseItem)
																		? "table-primary"
																		: undefined
																}
															>
																{(contextType === "ITEM" ||
																	contextType === "ITEM_GROUP") &&
																	props.hasMultipleChoice && (
																		<td className="p-0 w-1">
																			<label>
																				<input
																					type="checkbox"
																					aria-label={`item-${index}`}
																					className="me-2"
																					checked={getCheckboxesValue(
																						responseItem
																					)}
																					onClick={() =>
																						selectNewMenuItems(responseItem)
																					}
																				/>
																			</label>
																		</td>
																	)}
																<td>
																	<div className="d-flex align-items-center">
																		<ListImageName
																			imageLink={responseItem.image_link}
																			data={responseItem}
																			showColor={false}
																		/>
																		<Link
																			to={`/${
																				organization.id
																			}/menu/${getRouteName(contextType)}/${
																				contextType === "ITEM"
																					? responseItem.item_group_id
																					: responseItem.id
																			}`}
																			target={"_blank"}
																			rel="noreferrer"
																		>
																			{responseItem.label}
																		</Link>
																	</div>
																</td>
																<td>
																	{props.itemButtonLoading !== undefined &&
																	props.itemButtonLoading ===
																		Number(responseItem?.id) ? (
																		<ButtonLoading
																			loading={!!props.itemButtonLoading}
																			className="float-end"
																			variant={
																				menuItem?.context_id === responseItem.id
																					? "primary"
																					: "primary-light"
																			}
																			type="button"
																			onClick={() =>
																				changeSelectedItem(responseItem)
																			}
																		>
																			{t("common.action.select", { ns: "lib" })}
																		</ButtonLoading>
																	) : (
																		<Button
																			className="float-end"
																			variant={
																				menuItem?.context_id === responseItem.id
																					? "primary"
																					: "primary-light"
																			}
																			type="button"
																			onClick={() =>
																				changeSelectedItem(responseItem)
																			}
																		>
																			{t("common.action.select", { ns: "lib" })}
																		</Button>
																	)}
																</td>
															</tr>
														);
												  })
												: !loading && <EmptyData />}
										</>
									</tbody>
								</table>
							</div>
							{loading && <Loading />}
						</>
					</Modal.Body>
				</>
			) : (
				<>
					{contextType === "CATEGORY" && (
						<CreateMenuPageItemCategoryModal
							nestedContext={true}
							handleSave={changeSelectedItem}
							handleCancel={() => setNewItemMode(false)}
							searchText={searchText}
						/>
					)}
					{(contextType === "ITEM_GROUP" || contextType === "ITEM") && (
						<CreateMenuPageItemItemGroupModal
							nestedContext={true}
							contextType={contextType}
							handleSave={changeSelectedItem}
							handleCancel={() => setNewItemMode(false)}
							searchText={searchText}
						/>
					)}
					{contextType === "TAX" && (
						<TaxModalForm
							tax={undefined}
							onHide={() => setNewItemMode(false)}
							handleSave={changeSelectedItem}
							show={newItemMode}
							name={searchText}
							showGoBack
						/>
					)}
				</>
			)}
			{newMenuItems.length > 0 && props.hasMultipleChoice && (
				<Modal.Footer>
					<Button variant="primary" onClick={onChangeSelectedItems}>
						{t("common.action.select", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default MenuPageItemModal;

// t("enums.menu.entity_type.ITEM_GROUP");
// t("enums.menu.entity_type.ITEM");
// t("enums.menu.entity_type.CATEGORY");
// t("enums.menu.entity_type.TAX");
