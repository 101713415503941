import React from "react";
import { ReactComponent as FacebookLogo } from "../../../../../../images/svg/socials/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../../../../../images/svg/socials/instagram.svg";
import { ReactComponent as WebsiteLogo } from "../../../../../../images/svg/socials/website.svg";

interface CompanySocialsProps {
	instagram?: string;
	facebook?: string;
	website?: string;
}

export const CompanySocials = ({ instagram, facebook, website }: CompanySocialsProps): JSX.Element | null => {
	if (!instagram && !facebook && !website) {
		return null;
	}

	return (
		<div className="socials" data-testid="e-order-footer-company-info-socials">
			{instagram && (
				<a href={instagram} target="_blank" rel="noreferrer" aria-label="instagram logo">
					<InstagramLogo />
				</a>
			)}
			{facebook && (
				<a href={facebook} target="_blank" rel="noreferrer" aria-label="facebook logo">
					<FacebookLogo />
				</a>
			)}
			{website && (
				<a href={website} target="_blank" rel="noreferrer" aria-label="website logo">
					<WebsiteLogo />
				</a>
			)}
		</div>
	);
};
