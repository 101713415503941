import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { AvailabilitiesProps } from "../../services/types";
import { SettingsAvailabilitiesEditPage } from "./pages/Edit";
import { SettingsAvailabilitiesIndexPage } from "./pages/Index";
import { SettingsAvailabilitiesNewPage } from "./pages/New";

export const SettingsAvailabilitiesPage = (props: RouteComponentProps & AvailabilitiesProps) => {
	return (
		<div className="content">
			<Switch>
				<Route
					path={`${props.match.url}/`}
					exact={true}
					render={() => <SettingsAvailabilitiesIndexPage {...props} />}
				/>
				<Route
					path={`${props.match.url}/new`}
					exact={true}
					render={() => (
						<SettingsAvailabilitiesNewPage
							requests={props.requests}
							organizationId={props.organizationId}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/:availabilityId`}
					render={() => (
						<SettingsAvailabilitiesEditPage
							requests={props.requests}
							organizationId={props.organizationId}
						/>
					)}
				/>
			</Switch>
		</div>
	);
};
