import { Dropdown, Nav, NavItem } from "react-bootstrap";
import { useWindowSize } from "go-core/components/useWindowSize";

interface Props {
	profile_avatar?: string;
	profile_name: string;
	organization_name?: string;
	navigationOpen: boolean;
	children: React.ReactNode;
}

const NavigationProfileComponent = (props: Props): JSX.Element => {
	const { isSmallBreakpoint } = useWindowSize();
	const dropdownDropPlacement = props.navigationOpen && isSmallBreakpoint ? "up-centered" : "end";

	return (
		<div className="navigation-user">
			<Nav variant="pills" className={"flex-column"}>
				<Dropdown as={NavItem} drop={dropdownDropPlacement} className="w-100">
					<Dropdown.Toggle as={Nav.Link}>
						<div className="navigation-user-img">
							<img alt="Profile Logo" src={props.profile_avatar || "/graphics/user-avatar.svg"} />
						</div>
						<div className="navigation-user-content">
							<div className="navigation-user-name">{props.profile_name}</div>
							{props.organization_name && (
								<div className="navigation-user-company">{props.organization_name}</div>
							)}
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu className={"dropdown-menu-no-padding"}>{props.children}</Dropdown.Menu>
				</Dropdown>
			</Nav>
		</div>
	);
};

export default NavigationProfileComponent;
