import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BlankLayout from "go-core/components/Layout/BlankLayout";
import styles from "./Page404.module.scss";

const Page404: FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<BlankLayout>
			<div className="container">
				<div className="row">
					<div className="offset-md-4 col-md-4 col-xs-12">
						<div className={styles["error-template"]}>
							<h1>{t("lib:go_app.page_not_found.title")}</h1>
							<h2>{t("lib:go_app.page_not_found.sub_title")}</h2>
							<div className={styles["error-actions"]}>
								<div className="d-flex justify-content-between">
									<Button color="primary" size="lg" onClick={history.goBack}>
										{t("lib:common.action.back")}
									</Button>
									<Button color="primary" size="lg" onClick={() => history.push("/")}>
										{t("lib:common.word.home")}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BlankLayout>
	);
};

export default Page404;
