import React from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
	isShown: boolean;
	onHide: () => void;
	link: string;
}

const OrderPublicLinkModal = ({ isShown, onHide, link }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Modal onHide={onHide} show={isShown}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.order.field.order_link.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Card>
					<Card.Body>
						<span>{link}</span>
					</Card.Body>
				</Card>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default OrderPublicLinkModal;
