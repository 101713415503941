const data = {} as any;
const get = (key: string): string => {
	return data[key];
};
const update = (key: string, value: string): void => {
	data[key] = value;
};
const remove = (): void => {
	delete data.key;
};
export const Storage = { get, update, remove };
export default Storage;
