import React from "react";
import { MultiActionList, MultiActionListProps } from "../../actionsLists/MultiActionList";
import { ClearButton, ClearButtonProps } from "../ClearButton";
import { SelectedItemsSummary, SelectedItemsSummaryProps } from "./SelectedItemsSummary";

export interface StaticMultiActionsBarProps extends MultiActionListProps, SelectedItemsSummaryProps, ClearButtonProps {
	numberOfColumns: number;
}

export const StaticMultiActionsBar = ({ numberOfColumns, ...props }: StaticMultiActionsBarProps): JSX.Element => {
	return (
		<>
			<td className="static-selected-summary-text-container" colSpan={numberOfColumns + 1}>
				<SelectedItemsSummary {...props} />
				<ClearButton {...props} />
			</td>
			<td className="static-selected-summary-button-container">
				<MultiActionList {...props} />
			</td>
		</>
	);
};
