import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { RequiredFieldsInformation } from "go-security/components/RequiredFieldsInformation/RequiredFieldsInformation";
import { MenuPriceListApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import PriceListForm from "../../components/PriceListForm";

const OrganizationMenuPriceListsNewPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [priceList] = useState<MenuPriceListApi>({} as MenuPriceListApi);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.price_list.header.new.title"));
	}, []);

	return (
		<>
			<Header title={t("modules.price_list.header.new.title")} subtitle={<RequiredFieldsInformation />} />
			<PriceListForm priceList={priceList} />
		</>
	);
};

export default OrganizationMenuPriceListsNewPage;
