import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatBoolean from "go-core/components/Formatters/FormatBoolean";
import FormatDate, { FormatDateToDateHoursRange, defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin } from "go-security/services/users/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ReactComponent as AlertSVG } from "../../../../../../../../../images/svg/alert.svg";
import { InvoiceApi, LinkedInvoiceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../utils/entityStatus/entityStatus";
import ExportInvoiceConfirmationModal from "../../../components/ExportInvoicesConfirmationModal/ExportInvoiceConfirmationModal";
import SendInvoiceByEmailModal from "../../../components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import { exportEInvoice, exportInvoicePdf, exportInvoicesPdf, printInvoicePdf } from "../../../services/exportInvoice";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "INVOICE";
const resourceType = "INVOICE";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const [params, setParams] = useState<Record<string, any>>({});
	const confirmation = useConfirmation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<InvoiceApi[]>([]);
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const [invoiceToSendByEmail, setInvoiceToSendByEmail] = useState<InvoiceApi | undefined>(undefined);
	const segmentContext = useContext(SegmentContext);
	const hasVenueInvoiceEditPermission = useSelector(
		hasPermission(["VENUE_OWN_INVOICE_MANAGE", "VENUE_INVOICE_EDIT"])
	);
	const isAdmin = useSelector(selectIsAdmin);
	const [invoicesToExportToPdf, setInvoicesToExportToPdf] = useState<InvoiceApi[] | undefined>(undefined);

	const { addSuccessFlash, addFlash } = useFlash();
	if (!resource) return null;

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const getPreviewLink = (item: InvoiceApi) => {
		if (item.document_type === "INVOICE") {
			return `${location.pathname}/${item.id}`;
		}
		if (item.document_type === "CORRECTION") {
			return `${location.pathname}/${
				item.linked_invoices.find((i) => i.document_type === "INVOICE" || i.document_type === "ADVANCE")?.id
			}/corrections?correction_id=${item.id}`;
		}
		if (item.document_type === "ADVANCE") {
			return `${location.pathname}/advances/${item.id}`;
		}

		return "";
	};

	const removeInvoice = async (invoice: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoice.id);
			onRefresh();
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const hasAtLeastOneIncompatibleTaxIdNo = (invoice: InvoiceApi) => {
		if (invoice.orders.length > 0) return invoice.orders.some((order) => order?.tax_id_no_mismatch);
	};

	const exportInvoiceToPdf = (invoice: InvoiceApi) => {
		if (invoice.status === "OPEN") {
			setInvoicesToExportToPdf([invoice]);
		} else {
			exportInvoicePdf(invoice, t);
		}
	};

	const exportInvoicesToPdf = (invoices: InvoiceApi[]) => {
		const invoiceWithOpenStatus = invoices.find((invoice) => invoice.status === "OPEN");
		if (invoiceWithOpenStatus) {
			setInvoicesToExportToPdf(invoices);
		} else {
			exportInvoicesPdf(invoices, t, params);
		}
	};

	const getLinkToLinkedInvoice = (invoice: InvoiceApi, linkedInvoice: LinkedInvoiceApi) => {
		switch (linkedInvoice.document_type) {
			case "PROFORMA":
				return `/${organization.id}/invoices/proforma/${linkedInvoice.id}`;
			case "INVOICE":
				return `/${organization.id}/invoices/${linkedInvoice.id}`;
			case "ADVANCE":
				return `/${organization.id}/invoices/advances/${linkedInvoice.id}`;
			case "CORRECTION":
				return `/${organization.id}/invoices/${invoice.id}/corrections`;
			default:
				return `/${organization.id}/invoices`;
		}
	};

	const data = resource.read();
	let config = {
		fields: [
			{
				id: "issued_at",
				name: t("modules.invoice.field.date_verb.title"),
				type: "date",
				render: (item: InvoiceApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(item.status === "OPEN" ? "OPEN_BLUE" : item.status)}
							to={getPreviewLink(item)}
						>
							{FormatDate(item.dates?.issued_at, undefined, false, defaultFormatDate)}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: InvoiceApi) => {
					return FormatDate(item.dates?.issued_at, undefined, false, defaultFormatDate);
				},
			},
			{
				id: "document",
				name: t("modules.invoice.field.document.title"),
				render: (item: InvoiceApi) => {
					return (
						<Link to={getPreviewLink(item)}>{`${t(`enums.invoices.document_type.${item.document_type}`)} ${
							item.number
						}`}</Link>
					);
				},
				renderExport: (item: InvoiceApi) => {
					return `${t(`enums.invoices.document_type.${item.document_type}`)} ${item.number}`;
				},
			},
			{
				id: "client",
				name: t("modules.invoice.field.recipient.title"),
				type: "search_select",
				render: (item: InvoiceApi) => {
					if (item.recipient && item.recipient_id) {
						return (
							<div className="d-flex flex-column">
								<RenderLimitedText minWidth={200}>
									<Link
										rel={"noreferrer"}
										target={"_blank"}
										to={`/${organization.id}/clients/${item.recipient_id}`}
									>
										{item.recipient.name}
									</Link>
								</RenderLimitedText>
							</div>
						);
					}
				},
				renderExport: (item: InvoiceApi) => {
					return item.recipient.name;
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getClientsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "tax_id_no",
				name: t("common.word.tax_id_no.title", { ns: "lib" }),
				render: (item: InvoiceApi) => {
					return (
						<div className="d-flex align-items-center">
							<span>{item.recipient.tax_id_no}</span>
							{hasAtLeastOneIncompatibleTaxIdNo(item) && (
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id={`tooltip-${item.id}`}>
											{t("modules.invoice.constraints.incompatible_tax_id_no")}
										</Tooltip>
									}
								>
									{({ ref, ...triggerHandler }) => (
										<AlertSVG className={"ms-1"} ref={ref} {...triggerHandler} />
									)}
								</OverlayTrigger>
							)}
						</div>
					);
				},
				renderExport: (item: InvoiceApi) => item.recipient.tax_id_no,
			},
			{
				id: "orders",
				name: t("modules.invoice.field.orders.title"),
				render: (item: InvoiceApi) => {
					return (
						<div className="d-flex flex-column">
							{item.orders?.map((order) =>
								order?.order_id ? (
									<Link
										key={`order_${order.order_id}`}
										rel={"noreferrer"}
										target={"_blank"}
										to={`/${organization.id}/sales/orders/${order.order_id}`}
									>
										{order.number}
									</Link>
								) : (
									<></>
								)
							)}
						</div>
					);
				},
				renderExport: (item: InvoiceApi) => {
					return item.orders?.map((order) => order?.number).join(", ");
				},
				disableSorting: true,
			},
			{
				id: "linked_invoices",
				name: t("modules.invoice.field.linked_invoices.title"),
				render: (item: InvoiceApi) => {
					if (Array.isArray(item.linked_invoices)) {
						return item.linked_invoices.map((linked_invoice) => {
							return (
								<Link
									key={`linked_invoice_${linked_invoice.id}`}
									to={getLinkToLinkedInvoice(item, linked_invoice)}
								>
									{`${t(`enums.invoices.document_type.${linked_invoice.document_type}`)} ${
										linked_invoice.number
									}`}
									<br />
								</Link>
							);
						});
					}
					return "";
				},
				renderExport: (item: InvoiceApi) => {
					return item.linked_invoices
						.map((linked_invoice) => {
							return `${linked_invoice.number}`;
						})
						.join(",\n");
				},
				disableSorting: true,
			},
			{
				id: "price_sum_net",
				name: t("common.word.amount_net"),
				render: (item: InvoiceApi) => {
					return FormatMoney(item.summary?.price_sum_net);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "price_sum_gross",
				name: t("common.word.amount_gross"),
				render: (item: InvoiceApi) => {
					return FormatMoney(item.summary?.price_sum_gross);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "sold_at",
				name: t("modules.invoice.field.sold_at.title"),
				type: "date",
				render: (item: InvoiceApi) => {
					return FormatDate(item.dates?.sold_at, undefined, false, defaultFormatDate);
				},
			},
			{
				id: "payment_due_date",
				name: t("modules.invoice.field.payment_due_date.title"),
				type: "date",
				render: (item: InvoiceApi) => {
					return FormatDate(item.dates?.payment_due_date, undefined, false, defaultFormatDate);
				},
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				disableSorting: true,
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPaymentMethodsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
				render: (item: InvoiceApi) =>
					item.payments?.map((payment) => payment?.transaction?.payment_method_name).join(", "),
			},
			{
				id: "paid",
				name: t("modules.invoice.field.paid.list.title"),
				type: "boolean",
				render: (item: InvoiceApi) => <FormatBoolean value={item.paid} />,
				renderExport: (item: InvoiceApi) => {
					return `${item.paid ? t("modules.invoice.field.paid.csv.title") : t("lib:common.word.no")}`;
				},
			},
		],
		filters: [
			{
				type: "list",
				name: t("modules.invoice.field.document_type.title"),
				id: "document_type",
				options: {
					INVOICE: t("enums.invoices.document_type.INVOICE"),
					CORRECTION: t("enums.invoices.document_type.CORRECTION"),
					ADVANCE: t("enums.invoices.document_type.ADVANCE"),
				},
			},
			{
				id: "price",
				name: t("common.word.amount_gross"),
				type: "number" as FilterType,
			},
			{
				id: "order",
				name: t("common.word.order"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getOrdersSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: InvoiceApi) => {
						if (item.created_at && item.closed_at) {
							return `#${item.label} (${FormatDateToDateHoursRange(item.created_at, item.closed_at)})`;
						}
						return `#${item.label} (${FormatDate(
							item.created_at || "",
							undefined,
							false,
							defaultFormatDate
						)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "tax_id_no_mismatch",
				name: t("modules.invoice.constraints.tax_id_no_mismatch"),
				type: "boolean" as FilterType,
			},
			{
				id: "report",
				name: t("common.word.pos_report"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: InvoiceApi) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at || "",
							undefined,
							false,
							defaultFormatDate
						)})`;
					},
				} as ListFilterSource,
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: InvoiceApi) => {
					if (item.document_type === "INVOICE") {
						return `${location.pathname}/${item.id}`;
					}
					if (item.document_type === "CORRECTION") {
						return `${location.pathname}/${
							item.linked_invoices.find(
								(i) => i.document_type === "INVOICE" || i.document_type === "ADVANCE"
							)?.id
						}/corrections?correction_id=${item.id}`;
					}
					if (item.document_type === "ADVANCE") {
						return `${location.pathname}/advances/${item.id}`;
					}
					if (item.document_type === "PROFORMA") {
						return `/${location.pathname}/invoices/proforma/${item.id}`;
					}

					return location.pathname;
				},
			},
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: InvoiceApi) => {
					if (item.document_type === "INVOICE") {
						return `${location.pathname}/${item.id}/edit`;
					}
					if (item.document_type === "CORRECTION") {
						return `${location.pathname}/${
							item.linked_invoices.find(
								(i) => i.document_type === "INVOICE" || i.document_type === "ADVANCE"
							)?.id
						}/corrections/edit?correction_id=${item.id}`;
					}
					if (item.document_type === "ADVANCE") {
						return `${location.pathname}/advances/${item.id}/edit`;
					}
					if (item.document_type === "PROFORMA") {
						return `/${location.pathname}/invoices/proforma/${item.id}/edit`;
					}

					return location.pathname;
				},
				visible: (item: InvoiceApi) =>
					!(!hasVenueInvoiceEditPermission && item.status === "CONFIRMED" && !isAdmin),
			},
			{
				name: t("common.action.export_pdf", { ns: "lib" }),
				click: (item: InvoiceApi) => exportInvoiceToPdf(item),
			},
			{
				name: t("common.action.print", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					printInvoicePdf(item);
				},
			},
			{
				name: t("modules.invoice.field.e_invoice.title"),
				click: (item: InvoiceApi) => {
					exportEInvoice(item, t);
				},
				visible: (item: InvoiceApi) => item.document_type !== "ADVANCE",
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					removeInvoice(item);
				},
			},
			{
				name: t("modules.invoice.action.send_invoice_by_e-mail.title"),
				click: (item: InvoiceApi) => {
					setShowSendInvoiceByEmailModal(true);
					setInvoiceToSendByEmail(item);
				},
			},
			{
				name: t("modules.invoice.action.make_correction.title"),
				link: (item: InvoiceApi) => `${location.pathname}/${item.id}/corrections/new`,
			},
		],
		multipleActions: [
			{
				name: t("common.action.export_pdf", { ns: "lib" }),
				click: (multipleActionsItems: InvoiceApi[]) => exportInvoicesToPdf(multipleActionsItems),
			},
		],
		customPageSizes: ["20", "100", "1000"],
		selectedColumns: [
			"issued_at",
			"document",
			"client",
			"tax_id_no",
			"orders",
			"linked_invoices",
			"price_sum_net",
			"price_sum_gross",
			"sold_at",
			"payment_due_date",
			"payment_method",
			"paid",
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.invoice.field.export_config.title"),
			filename: t("modules.invoice.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include =
				"summary,recipient,receiver,linked_invoices,orders,payments,payments.transaction,custom_fields";
			setParams(params);
			return api.organization().getInvoices(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 2,
		fetchTotalItemsCountForSelectedFilters: (params: Record<string, any>, sourceToken?: CancelTokenSource) =>
			api.organization().getInvoicesCount(params, { cancelToken: sourceToken?.token }),
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{invoiceToSendByEmail && (
				<SendInvoiceByEmailModal
					isShown={showSendInvoiceByEmailModal}
					onHide={() => {
						setShowSendInvoiceByEmailModal(false);
						setInvoiceToSendByEmail(undefined);
					}}
					invoice={invoiceToSendByEmail}
					onSuccess={onRefresh}
				/>
			)}
			{invoicesToExportToPdf && (
				<ExportInvoiceConfirmationModal
					isShown={!!setInvoicesToExportToPdf}
					onHide={() => setInvoicesToExportToPdf(undefined)}
					onSuccessAction={onRefresh}
					params={params}
					invoices={invoicesToExportToPdf}
				/>
			)}
			<ListData
				data={items}
				config={config}
				emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
				onFetch={(fetchItems: InvoiceApi[]) => {
					setItems(fetchItems);
				}}
				canManageMultiActionsForAllItems
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationInvoicesIndexIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "INVOICE",
			name: t(`enums.custom_fields.resources.INVOICE`),
		},
	];
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			path: `${props.match.url}/new`,
			variant: "primary",
			title: t("common.word.invoice"),
		},
		{
			path: `${props.match.url}/advances/new`,
			variant: "primary",
			title: t("modules.invoice.action.make_advance.title"),
		},
		{
			path: `${props.match.url}/new_to_order`,
			variant: "primary",
			title: t("modules.invoice.action.create_invoice_to_order.title"),
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			path: `${props.match.url}/new`,
			title: t("common.word.invoice"),
		},
		{
			path: `${props.match.url}/advances/new`,
			title: t("modules.invoice.action.make_advance.title"),
		},
		{
			path: `${props.match.url}/new_to_order`,
			title: t("modules.invoice.action.create_invoice_to_order.title"),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.invoice.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationInvoicesIndexIndexPage;
