import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatTimeAgo } from "go-core/components/Formatters/FormatDate";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as InfoCircleSVG } from "../../../images/svg/dashboard/info-circle.svg";

interface Props {
	lastUpdateDate?: string;
}

export const ReportLastUpdateInfo = ({ lastUpdateDate }: Props): JSX.Element | null => {
	const { t } = useTranslation();
	const lastSaleSyncAt = formatTimeAgo(lastUpdateDate || "", t, true);
	const caption = `${t("modules.dashboard.field.last_update.title")}: ${lastSaleSyncAt}`;
	const isMobile = useWindowSize().isMobile;

	if (lastUpdateDate === undefined || typeof lastUpdateDate !== "string") return null;

	const popover = (
		<Popover id="popover-basic">
			<Popover.Body>
				<div className="dashboard-update-info-popover-wrapper">
					<span className="last-updated-info">
						{t("modules.dashboard.field.last_update.description.title")}
					</span>
					<div className="dashboard-update-info-popover-footer">
						<span className="last-updated-time-period">{caption}</span>
						{/*Refresh button- ma byc w przyszlosci taka funkcjonalnosc */}
						{/* <Button className="dashboard-update-refresh-button">
							<ColumnsSVG />
						</Button> */}
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
			{isMobile ? (
				<InfoCircleSVG className="me-2 last-updated-info-svg" />
			) : (
				<div className="dashboard-update-info-wrapper">
					<span className="last-updated-info" style={{ whiteSpace: "pre" }}>
						{caption}
					</span>
					<InfoCircleSVG />
				</div>
			)}
		</OverlayTrigger>
	);
};
