import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { PosAccessApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import PosAccessForm from "./components/PosAccessForm";

const OrganizationSettingsInfoPosAccessPage = (): JSX.Element => {
	const [posAccess, setPosAccess] = useState<PosAccessApi | undefined>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.pos_access.title"));
		onFetchPosAccess();
	}, []);

	const onFetchPosAccess = async () => {
		try {
			const res = await api.organization().getPosAccess();
			setPosAccess(res);
		} catch (err) {
			const exceptionErrors = handleException(err);
			addFlash({
				type: "danger",
				msg: exceptionErrors,
			});
		}
	};

	const handleSave = (data: PosAccessApi) => {
		setPosAccess(data);
	};

	return <>{posAccess ? <PosAccessForm handleSave={handleSave} posAccess={posAccess} /> : <LoadingContainer />}</>;
};

export default OrganizationSettingsInfoPosAccessPage;
