import React, { useContext, useEffect, useRef } from "react";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as CloseSVG } from "../../../../../../../../../../images/svg/liveOrders/close.svg";
import { ReactComponent as LocationSVG } from "../../../../../../../../../../images/svg/liveOrders/location.svg";
import { DeliveryEmployeeApi, LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import LiveOrdersListItem from "../../../../components/LiveOrdersListItem";
import { OrdersContext } from "../../../../services/context";
import {
	calculateEmployeeDistance,
	getOrganizationCoords,
	hasEmployeeCoords,
} from "../../../../services/orderDelivery";
import LiveOrderDeliveryEmployeeAvatar from "../LiveOrderDeliveryEmployeeAvatar";

interface Props {
	employee: DeliveryEmployeeApi;
	orders: LiveOrderApi[];
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	noLastActivity: boolean;
	onHide: () => void;
	timeAgo: string;
}

const LiveOrdersMapDeliveryEmployeeMarkerOrdersListMobile = ({
	orders,
	setFocusedOrder,
	employee,
	onHide,
	noLastActivity,
	timeAgo,
}: Props): JSX.Element => {
	const ref = useRef(null);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const { organizationAddress } = useContext(OrdersContext);
	const organizationCoords = getOrganizationCoords(organizationAddress);
	const history = useHistory();

	useEffect(() => {
		if (ref?.current) {
			const disableClickPropagation = L?.DomEvent?.disableClickPropagation;
			disableClickPropagation(ref.current);
		}
	}, []);

	const getEmployeeDistanceFromRestaurant = () =>
		`${calculateEmployeeDistance(employee, organizationCoords, t)?.distance} ${
			calculateEmployeeDistance(employee, organizationCoords, t)?.measure
		}`;

	const onOrderFocus = (order: LiveOrderApi) => {
		onHide();
		setTimeout(() => {
			setFocusedOrder(order);
			history.push(`/${organization.id}/live_orders/map/orders/${order.id}`);
		}, 10);
	};

	const drawOrders = () => {
		return orders.length > 0 ? (
			orders.map((order) => (
				<LiveOrdersListItem
					order={order}
					onSelectOrder={() => onOrderFocus(order)}
					displayAsHeader={false}
					key={`employee-order-${order.id}`}
					withoutDeliveryEmployee
				/>
			))
		) : (
			<div className="live-orders-list-item">
				<h5 className="text-truncate mb-0">{t("modules.live_order.field.without_order.title")}</h5>
			</div>
		);
	};

	return (
		<div className="w-100 bg-white position-relative live-orders-delivery-employee-orders-list-mobile" ref={ref}>
			<div className="d-flex flex-column p-3 live-orders-delivery-employee-orders-list-mobile-container">
				<div className="header">
					<div className="d-flex">
						<LiveOrderDeliveryEmployeeAvatar employee={employee} />
						<div className="d-flex flex-column">
							{employee?.employee?.name ? (
								<Link
									className="employee-name"
									to={`/${organization.id}/employees/${employee.employee_id}`}
								>
									{employee.employee.name}
								</Link>
							) : (
								<h5 className="employee-name">{employee.source.name}</h5>
							)}
							<span>
								{noLastActivity ? (
									<LocationSVG className="me-1 live-orders-red-icon mb-1" />
								) : (
									<LocationSVG className="me-1 mb-1" />
								)}
								{noLastActivity && (
									<span className="text-danger me-2">
										{`${t("modules.live_order.field.last_update.title")} ${timeAgo}`}
									</span>
								)}
								{hasEmployeeCoords(employee) && (
									<span className="text-muted">{getEmployeeDistanceFromRestaurant()}</span>
								)}
							</span>
						</div>
					</div>
					<CloseSVG className="close-button" onClick={onHide} />
				</div>
				{drawOrders()}
			</div>
		</div>
	);
};

export default LiveOrdersMapDeliveryEmployeeMarkerOrdersListMobile;
