import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { FormDirty } from "go-form/components/FormDirty";
import { FormMoneyInputSimple } from "go-form/components/FormMoneyInputSimple";
import { RoomTableApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	table: RoomTableApi;
	onHide: () => void;
	handleUpdate: (data: RoomTableApi) => void;
}

const TableEditModal: FC<Props> = ({ table, onHide, handleUpdate }) => {
	const form = useForm<RoomTableApi>({
		defaultValues: table,
	});
	const { t } = useTranslation();
	const {
		formState,
		register,
		formState: { errors },
		handleSubmit,
	} = form;

	const onSubmit = handleSubmit((data: RoomTableApi) => {
		handleUpdate(data);
	});

	return (
		<Modal show={true} onHide={onHide}>
			<FormDirty formState={formState} noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("common.word.table")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						name={"name"}
						errors={errors}
						register={register}
					/>
					<FormMoneyInputSimple
						label={t("modules.room.field.number_of_seats.title")}
						name={"number_of_seats"}
						errors={errors}
						register={register}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</Button>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default TableEditModal;
