import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFieldArrayAppend } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MenuApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	show: boolean;
	onHide: () => void;
	indexPage: number;
	append: UseFieldArrayAppend<MenuApi>;
}

const CategoryModal = ({ show, onHide, indexPage, append }: Props) => {
	const { t } = useTranslation();
	const [name, setName] = useState<string>("");

	const handleCancel = () => {
		onHide();
	};

	const handleSave = () => {
		append({
			position: indexPage,
			name,
			appended: true,
			internalId: `page-${new Date().getTime()}`,
			items: [],
		});
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.menu.action.add_section.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<input className="form-control" onChange={(event) => setName(event.target.value)} />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleSave} disabled={!name}>
					{t("lib:common.action.save")}
				</Button>
				<Button variant="light" onClick={handleCancel}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CategoryModal;
