import { useEffect, useState } from "react";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import { api } from "../../../../../../../services/Api/api";
import MigrationForm from "./MigrationForm";
import MigrationPreview from "./MigrationPreview";

interface Props {
	context: string;
}

const MigrationComponent = (props: Props): JSX.Element => {
	const [data, setData] = useState<Record<string, any> | undefined>(undefined);
	const { addFlash } = useFlash();

	useEffect(() => {
		onCheckMigration();
	}, []);

	const onCheckMigration = async () => {
		try {
			if (props.context === "ORGANIZATION") {
				const res = await api.organization().checkMigration();
				setData(res.data);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!data) {
		return <LoadingContainer />;
	}
	return Object.keys(data).length === 0 ? (
		<MigrationForm handleUpdate={(data) => setData(data)} />
	) : (
		<MigrationPreview data={data} update={() => setData({})} />
	);
};
export default MigrationComponent;
