import React, { FC, createContext, useContext } from "react";
import { ReactComponent as LoadingSVG } from "../images/svg/loading.svg";

interface LoadingContextType {
	icon?: JSX.Element;
}

export const LoadingContext = createContext<LoadingContextType>({
	icon: undefined,
});

export const LoadingProvider = (props: { icon: JSX.Element; children: React.ReactNode }): JSX.Element => {
	return <LoadingContext.Provider value={{ icon: props.icon }}>{props.children}</LoadingContext.Provider>;
};

interface LoadingProps {
	title?: string;
	description?: string;
}

const getLoadingIcon = (icon?: JSX.Element) => {
	if (!icon) return <LoadingSVG />;
	return icon;
};

const Loading: FC<LoadingProps> = ({ title, description }) => {
	const { icon } = useContext(LoadingContext);

	return (
		<div className="text-center">
			{getLoadingIcon(icon)}
			{title && <h6>{title}</h6>}
			{description && <span>{description}</span>}
		</div>
	);
};

const LoadingEmptyData: FC<LoadingProps> = ({ title, description }) => {
	const { icon } = useContext(LoadingContext);

	return (
		<div className="data-empty-container text-center">
			{getLoadingIcon(icon)}
			{title && <h6>{title}</h6>}
			{description && <span>{description}</span>}
		</div>
	);
};

const LoadingContainer: FC<LoadingProps> = ({ title, description }) => {
	return (
		<div className="loading-container">
			<Loading title={title} description={description} />
		</div>
	);
};

export { Loading, LoadingContainer, LoadingEmptyData };
