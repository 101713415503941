import { Reducer } from "redux";
import AccessTokenSessionService from "../accessTokenSessionService";
import { SessionActionTypes, SessionState } from "./types";

const token = undefined;
const initialState: SessionState = {
	token: token ?? undefined,
	isAuthenticated: !!token,
};

export const sessionReducer: Reducer<SessionState> = (state = initialState, action) => {
	switch (action.type) {
		case SessionActionTypes.SET: {
			if (state && state.token) {
				AccessTokenSessionService.update(state.token);
			} else {
				AccessTokenSessionService.update(action.payload.token);
			}
			return { ...action.payload };
		}
		case SessionActionTypes.CLEAR: {
			AccessTokenSessionService.remove();
			return { ...initialState };
		}
		default: {
			return state;
		}
	}
};
