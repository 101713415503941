import React, { useContext } from "react";
import L, { LatLngExpression } from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { ReactComponent as OrganizationSVG } from "../../../../../../../../../images/svg/liveOrders/organization.svg";
import { CoordinatesApi } from "../../../../../../../../../services/Api/types";
import { OrdersContext } from "../../../services/context";
import { polandCoords } from "../../../services/orderDelivery";

interface Props {
	coordinates?: CoordinatesApi;
}

const LiveOrderOrganizationMarker = ({ coordinates }: Props): JSX.Element => {
	const { organizationAddress, iconsScale } = useContext(OrdersContext);
	const markerIcon = L.divIcon({
		className: "",
		html: ReactDOMServer.renderToString(
			<OrganizationSVG
				style={{
					transform: `scale(${iconsScale})`,
					transformOrigin: "center 43px",
				}}
			/>
		),
		iconAnchor: [20, 43],
		iconSize: [40, 43],
	});
	const hasOrganizationAddressCoords =
		organizationAddress?.coordinates && Object.keys(organizationAddress.coordinates).length > 0;

	const getCoords = () => {
		if (coordinates) return coordinates;

		return hasOrganizationAddressCoords
			? organizationAddress.coordinates
			: {
					latitude: polandCoords[0],
					longitude: polandCoords[1],
			  };
	};

	const position: LatLngExpression = [getCoords()?.latitude, getCoords()?.longitude];

	return <Marker position={position} icon={markerIcon} alt="organization" />;
};

export default LiveOrderOrganizationMarker;
