import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import FormatResourceStatus from "../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReservationApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "RESERVATION";
const resourceType = "RESERVATION";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<ReservationApi[]>([]);
	const confirmation = useConfirmation();
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onCancelReservation = async (item: ReservationApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			const params: Record<string, string> = { include: "room,table,client" };
			const res = await api.organization().cancelReservation(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setItems([...items.map((item) => (item.id !== res.id ? item : res))]);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "date_from",
				name: t("modules.reservation.field.date_from.title"),
				render: (item: ReservationApi) => {
					return <Link to={`${location.pathname}/${item.id}`}>{FormatDate(item.date_from)}</Link>;
				},
				renderExport: (item: ReservationApi) => FormatDate(item.date_from),
			},
			{
				id: "date_to",
				name: t("modules.reservation.field.date_to.title"),
				render: (item: ReservationApi) => {
					return <Link to={`${location.pathname}/${item.id}`}>{FormatDate(item.date_to)}</Link>;
				},
				renderExport: (item: ReservationApi) => FormatDate(item.date_to),
			},
			{
				id: "room",
				name: t("modules.reservation.field.room.title"),
				type: "search_select" as FilterType,
				render: (item: ReservationApi) => item.room?.name,
				source: {
					request: (search: string, obj: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getRoomsSearchSelect(search, obj, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "table",
				name: t("common.word.table"),
				type: "search_select" as FilterType,
				render: (item: ReservationApi) => item.table?.name,
				source: {
					request: (search: string, obj: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTablesSearchSelect(search, obj, {
							cancelToken: options?.token,
						}),
					render: (item: ReservationApi) => {
						return `${item.label}${
							item.room_name &&
							`(${item.room_name}, ${t("modules.room.field.number_of_seats.title")}: ${
								item.number_of_seats
							})`
						}`;
					},
				} as ListFilterSource,
			},
			{
				id: "seats_number",
				name: t("modules.reservation.field.seats_amount.title"),
				type: "number" as FilterType,
			},
			{
				id: "reservation_status",
				name: t("lib:common.word.status"),
				type: "list" as FilterType,
				options: {
					NEW: t(`enums.common.status.NEW`),
					CANCELED: t(`enums.common.status.CANCELED`),
				},
				render: (item: ReservationApi) => {
					return <FormatResourceStatus status={item.reservation_status} />;
				},
				renderExport: (item: ReservationApi) => item.reservation_status,
			},
			{
				id: "client",
				name: t("common.word.client"),
				type: "search_select" as FilterType,
				render: (item: ReservationApi) => item.client?.name,
				source: {
					request: (search: string, obj: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getClientsSearchSelect(search, obj, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "description",
				name: t("lib:common.word.description"),
				type: "text",
				render: (item: ReservationApi) => (
					<RenderLimitedText minWidth={200}>{item.description}</RenderLimitedText>
				),
				renderExport: (item: ReservationApi) => item.description,
			} as ListConfigField,
		],
		filters: [
			{
				id: "date",
				name: t("lib:common.word.date"),
				type: "date",
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: ReservationApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.cancel", { ns: "lib" }),
				click: (item: ReservationApi) => onCancelReservation(item),
				visible: (item: ReservationApi) => item.reservation_status !== "CANCELED",
			},
			{
				name: t("common.action.copy", { ns: "lib" }),
				click: (item: ReservationApi) => {
					history.push({
						pathname: `/${organization.id}/reservations/new`,
						state: {
							copiedItem: item.id,
						},
					});
				},
			},
		],
		selectedColumns: [
			"date_from",
			"date_to",
			"room",
			"table",
			"seats_number",
			"reservation_status",
			"client",
			"description",
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.reservation.field.export_config.title"),
			filename: t("modules.reservation.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "room,table,client";
			return api.organization().getReservations(params, { cancelToken: sourceToken?.token });
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 2,
		isNumberOfStickyColumnsDynamic: true,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={
				<EmptyList
					title={t("modules.reservation.header.title")}
					description={t("modules.reservation.field.empty_list_description.title")}
				/>
			}
			onFetch={(fetchItems: ReservationApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationReservationsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const venueTableReservationsPermission = useSelector(hasPermission("VENUE_TABLE_RESERVATION"));
	const isMobile = useWindowSize().isMobile;
	const segmentContext = useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.reservation.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [];
	if (venueTableReservationsPermission) {
		buttons.push({
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
			variant: "primary",
		});
	}
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
			hide: !venueTableReservationsPermission,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.reservation.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationReservationsIndexPage;
