import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationMenuPriceListsGroupEditingSpreadsheetIndexPage from "./pages/Spreadsheet";

const OrganizationMenuPriceListsGroupEditing: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"content"}>
			<Switch>
				<Route
					exact
					path={`${props.match.url}/`}
					component={OrganizationMenuPriceListsGroupEditingSpreadsheetIndexPage}
				/>
			</Switch>
		</div>
	);
};

export default OrganizationMenuPriceListsGroupEditing;
