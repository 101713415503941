import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { FormControlProps } from "react-bootstrap/FormControl";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { UseFormRegister, UseFormWatch } from "react-hook-form/dist/types/form";
import { hasErrors } from "..";
import { useCustomValidation } from "../hooks";
import { CustomValidationConfig } from "../services/types";
import { isConstraintARequiredConstraint } from "../services/utils";
import { FormErrorMessage } from "./FormErrorMessage";
import FormInputWrapper from "./FormInputWrapper";

interface Props extends FormControlProps {
	label?: string;
	placeholder?: string;
	name: string;
	help?: string;
	errors: any;
	firstInput?: boolean;
	register: UseFormRegister<any> | null;
	options?: Record<string, any>;
	customActions?: JSX.Element[];
	customValidationConfig?: CustomValidationConfig<{
		watch: UseFormWatch<any>;
	}>;
}

export const FormInput: BsPrefixRefForwardingComponent<"input", Props> = React.forwardRef((props: Props) => {
	const {
		label,
		options,
		placeholder,
		name,
		errors,
		register,
		help,
		customActions,
		customValidationConfig,
		...controlProps
	} = props;

	const value =
		props.value || (customValidationConfig?.utils.watch ? customValidationConfig.utils.watch(name) : null);

	const isFieldRequired = useMemo(
		() => customValidationConfig?.types.some(({ constraint }) => isConstraintARequiredConstraint(constraint)),
		[customValidationConfig?.types]
	);

	useCustomValidation({ name, value, customValidationConfig });

	const placeholderValue = placeholder && props.firstInput && isFieldRequired ? `${placeholder} *` : placeholder;

	return (
		<Form.Group controlId={name} className={`form-group ${props.type === "hidden" ? "mb-0" : ""}`}>
			{!props.firstInput && label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
			<FormInputWrapper name={name} firstInput={props.firstInput} customActions={customActions}>
				{register !== null ? (
					<Form.Control
						{...register(name, options ? options : undefined)}
						{...controlProps}
						placeholder={placeholderValue}
						isInvalid={hasErrors(errors, name)}
					/>
				) : (
					<Form.Control {...controlProps} placeholder={placeholder} isInvalid={hasErrors(errors, name)} />
				)}
			</FormInputWrapper>
			{help && <Form.Text muted>{help}</Form.Text>}
			{props.firstInput && label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
			<FormErrorMessage errors={errors} name={name} />
		</Form.Group>
	);
});

FormInput.displayName = "FormInput";
