import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExportSVG } from "go-report/core/images/export.svg";
import { ReportConfigColumn } from "../../services/types";
import ReportColumn from "../Columns";

interface Props {
	groups?: Array<Record<string, string>>;
	groupColumn: string | undefined;
	updateGroupColumn: (groupColumn: string | undefined) => void;
	handleCsvExport: () => void;
	handlePdfExport: (openPrintoutWindow?: boolean) => void;
	columns: ReportConfigColumn[];
	visibleColumns: ReportConfigColumn[];
	onChangeSelectedColumn: (selectedColumns: Array<string>, sortedColumns: Array<string>) => void;
	showPercent: boolean;
	onShowPercentChange: () => void;
	exportCSVWithEveryColumn?: (params: Record<string, any>) => Promise<any>;
	CSVExportWithEveryColumnHandler: () => Promise<void>;
	handlePrintPdf: () => void;
}

const TableActions = ({
	groups,
	groupColumn,
	updateGroupColumn,
	handlePdfExport,
	handleCsvExport,
	columns,
	onChangeSelectedColumn,
	visibleColumns,
	showPercent,
	onShowPercentChange,
	exportCSVWithEveryColumn,
	CSVExportWithEveryColumnHandler,
	handlePrintPdf,
}: Props) => {
	const { t } = useTranslation();

	const getGroupName = (group: Record<string, any>) => {
		const isCustomField = group.id.startsWith("+") && group.resources;
		const name = isCustomField ? `${group.name} (${group.resources[0]?.name})` : group.name;

		return name;
	};

	const getGroupSelectedValue = (allGroups: Record<string, any>[], groupName?: string) => {
		if (!groupName) return `${t("lib:go_report.table.actions.select_column")}`;

		const isGroupFromColumnACustomField = groupName.startsWith("+") && groupColumn?.includes(".");
		const newGroupName = isGroupFromColumnACustomField
			? groupColumn?.substring(0, groupColumn.indexOf("."))
			: groupName;
		const selectedGroup = allGroups.find((group) => {
			if (!group.resources) return group.id === newGroupName;

			const groupResource = groupColumn?.substring(groupColumn.indexOf(".") + 1);
			return (
				group.id === newGroupName &&
				group.resources.some((resource: Record<string, any>) => resource.resource === groupResource)
			);
		});

		if (!selectedGroup) return "";

		return getGroupName(selectedGroup);
	};

	const dropdownOptionSelectHandler = (group?: Record<string, any>) => {
		if (!group) {
			updateGroupColumn(undefined);
			return;
		}

		let groupId: string = group.id;
		const isGroupACustomField = group.id?.startsWith("+") && group.resources?.length > 0;
		if (isGroupACustomField) {
			groupId = `${group.id}.${group.resources[0].resource}`;
		}

		updateGroupColumn(groupId);
	};

	const checkIsActive = (group: Record<string, any>, groupColumnName?: string): boolean => {
		if (!groupColumnName) return false;

		const isGroupColumnACustomField = group.id.startsWith("+") && group.resources?.length > 0;
		let groupId = group.id;
		if (isGroupColumnACustomField) {
			groupId += `.${group.resources[0].resource}`;
		}

		return groupId === groupColumnName;
	};

	return (
		<div className="table-actions d-flex align-items-center mb-4 justify-content-between flex-wrap gap-2">
			{groups && (
				<Dropdown id="group-column-dropdown" className="groups-dropdown" as={ButtonGroup}>
					<Dropdown.Toggle variant="outline-light">
						{getGroupSelectedValue(groups, groupColumn)}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item
							as="button"
							active={groupColumn === undefined}
							onClick={() => dropdownOptionSelectHandler(undefined)}
						>
							{t("lib:go_report.table.actions.select_column")}
						</Dropdown.Item>
						{groups.map((group) => {
							const isOptionActive = checkIsActive(group, groupColumn);
							return (
								<Dropdown.Item
									as="button"
									key={getGroupName(group)}
									active={isOptionActive}
									onClick={() => dropdownOptionSelectHandler(group)}
								>
									{getGroupName(group)}
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
			)}
			<div className="d-flex align-items-center justify-content-end table-actions-right-box">
				<Dropdown as={ButtonGroup} className="me-2 export-dropdown">
					<Dropdown.Toggle variant="light">
						<ExportSVG />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => handleCsvExport()}>
							{t("lib:common.action.export_csv")}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => handlePdfExport()}>
							{t("lib:common.action.export_pdf")}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => handlePrintPdf()}>
							{t("go_report.action.print_pdf", { ns: "lib" })}
						</Dropdown.Item>
						{exportCSVWithEveryColumn && (
							<Dropdown.Item onClick={CSVExportWithEveryColumnHandler}>
								{t("go_report.action.export_csv_with_every_column", { ns: "lib" })}
							</Dropdown.Item>
						)}
					</Dropdown.Menu>
				</Dropdown>
				<ReportColumn
					columns={columns}
					onChange={onChangeSelectedColumn}
					selectedColumns={visibleColumns.map((i) => i.id)}
					showPercent={showPercent}
					onShowPercentChange={onShowPercentChange}
				/>
			</div>
		</div>
	);
};

export default TableActions;
