import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterType } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { TagApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "TAG";
const resourceType = "TAG";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const [items, setItems] = useState<TagApi[]>([]);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRemove = async (item: TagApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeTag(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: TagApi) => (
					<RenderLimitedText minWidth={200}>
						<Link to={`${location.pathname}/${item.id}`}>{item.name}</Link>
					</RenderLimitedText>
				),
				renderExport: (item: TagApi) => item.name,
			},
			{
				id: "priority",
				name: t("modules.tag.field.priority.title"),
				type: "list" as FilterType,
				options: {
					"0": t("modules.tag.field.priority_0.title"),
					"1": t("modules.tag.field.priority_1.title"),
					"2": t("modules.tag.field.priority_2.title"),
				},
				render: (item: TagApi) => {
					return t(`modules.tag.field.priority_${item.priority}.title`);
				},
			},
			{
				id: "resource_type",
				name: t("modules.tag.field.resource_type.title"),
				type: "list" as FilterType,
				options: {
					CASH: t("enums.tags.resource_types.MENU_IMAGE"),
				},
				render: (item: TagApi) => {
					return item.resource_types.map((singleResourceType) => {
						return t(`enums.tags.resource_types.${singleResourceType}`);
					});
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: TagApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: TagApi) => {
					onRemove(item);
				},
				visible: () => {
					return true;
				},
			},
		],
		selectedColumns: ["name", "priority", "resource_type"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		exportConfig: {
			title: t("modules.tag.header.title"),
			filename: t("modules.tag.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(fetchParams);
			return api.organization().getTags(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
			onFetch={(fetchItems: TagApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};
const OrganizationSettingsTagsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.tag.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			variant: "primary",
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.tag.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationSettingsTagsIndexPage;
