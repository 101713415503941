import { ChartDataset } from "chart.js";
import i18n from "i18next";
import moment from "moment";
import { isDST } from "go-core/components/Formatters/FormatDate";
import { getLang } from "go-core/utils/utils";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { isPredefinedRange, parsePredefinedRangeToDefaultString } from "go-list/utils/daterangeutils";
import { ReportApi, SubReportApi } from "go-report/core/services/types";
import { defaultChartConfig } from "../../utils/chartConfigUtils";
import { mockedDateRange } from "../../utils/reportConfigUtils";
import { AggregateOption, ChartDataRangeType } from "./types";

type DateLabel = {
	t: number;
	y: number;
};

const generateLabels = (type: ChartDataRangeType, selectedFilters: ListSelectedFilter[]): number[] => {
	let labels: number[] = [];
	switch (type) {
		case "HOUR_OF_DAY":
			for (let i = 0; i < 24; ++i) {
				labels.push(i);
			}
			break;
		case "DAY_OF_MONTH":
			for (let i = 1; i < 32; ++i) {
				labels.push(i);
			}
			break;
		case "DAY_OF_WEEK":
			for (let i = 0; i < 7; ++i) {
				labels.push(i);
			}
			break;
		case "DATE": {
			let dateStart, dateEnd;
			const filterDate = selectedFilters.find((f) => f.filterId === "date")?.value ?? mockedDateRange;
			const dateRange = isPredefinedRange(filterDate)
				? parsePredefinedRangeToDefaultString(filterDate)
				: filterDate;
			const dateRangeSplit = dateRange.includes(FILTER_VALUE_SEPARATOR)
				? dateRange.split(FILTER_VALUE_SEPARATOR)
				: dateRange.split(",");
			const dateStartString = dateRangeSplit[0];
			const dateEndString = dateRangeSplit[1];
			const dateStartSplit = dateStartString?.slice(0, 10).split("-");
			const dateEndSplit = dateEndString?.slice(0, 10).split("-");

			if (dateStartSplit)
				dateStart = moment.utc(`${dateStartSplit[0]}-${dateStartSplit[1]}-${dateStartSplit[2]}`).toDate();
			if (dateEndSplit) dateEnd = moment.utc(`${dateEndSplit[0]}-${dateEndSplit[1]}-${dateEndSplit[2]}`).toDate();

			const oneDay = 24 * 60 * 60 * 1000;
			if (dateEnd?.getTime() && dateStart?.getTime()) {
				const diffDays = Math.round(Math.abs((dateEnd?.getTime() - dateStart.getTime()) / oneDay));
				labels = [dateStart.getTime(), dateEnd.getTime(), diffDays];
			}
		}
	}
	return labels;
};

const generateLabelValues = (labels: number[], type: ChartDataRangeType): string[] => {
	let newLabels: string[] = [];
	switch (type) {
		case "DATE": {
			const initTime = labels[0];
			newLabels = [];
			for (let i = 0; i <= labels[2]; ++i) {
				const day = 24 * 60 * 60 * 1000;
				newLabels.push(new Date(initTime + day * i).toLocaleDateString(getLang()));
			}
			break;
		}
		case "DAY_OF_WEEK":
			newLabels = [
				i18n.t("enums.days.MONDAY", { ns: "lib" }),
				i18n.t("enums.days.TUESDAY", { ns: "lib" }),
				i18n.t("enums.days.WEDNESDAY", { ns: "lib" }),
				i18n.t("enums.days.THURSDAY", { ns: "lib" }),
				i18n.t("enums.days.FRIDAY", { ns: "lib" }),
				i18n.t("enums.days.SATURDAY", { ns: "lib" }),
				i18n.t("enums.days.SUNDAY", { ns: "lib" }),
			];
			break;
		default:
			return labels.map((label) => label.toString());
	}
	return newLabels;
};

const getDataSet = (
	subReports: SubReportApi[],
	aggregatePrefix: string,
	type: ChartDataRangeType,
	selectedFilters: ListSelectedFilter[]
): number[] => {
	let labelsValue: number[] = generateLabels(type, selectedFilters);
	const newLabelsValue: DateLabel[] = [];
	if (type === "DATE") {
		const oneDay = 24 * 60 * 60 * 1000;
		const twentyThreeHours = 23 * 60 * 60 * 1000;
		const oneHour = 3600 * 1000;
		for (let i = 0; i <= labelsValue[2]; ++i) {
			const prevDayDST = isDST(new Date(labelsValue[0] - twentyThreeHours));
			const thisDayDST = isDST(new Date(labelsValue[0]));
			const nextDayDST = isDST(new Date(labelsValue[0] + twentyThreeHours));

			if (thisDayDST && !prevDayDST) {
				labelsValue[0] = labelsValue[0] - oneHour;
			}
			if (thisDayDST && !nextDayDST) {
				labelsValue[0] = labelsValue[0] + oneHour;
			}

			newLabelsValue.push({
				t: labelsValue[0] + i * oneDay,
				y: 0,
			} as DateLabel);
		}
	}
	const insertedValuesMap: Record<string, any> = {};
	labelsValue.forEach((label: number) => {
		insertedValuesMap[label.toString()] = false;
	});
	subReports.forEach((rep) => {
		let value = rep.group_by_value?.name;
		const aggregate = rep.aggregate?.sales;
		let aggregateValue;

		if (type === "DAY_OF_WEEK") {
			value = value - 1;
		}

		value = parseFloat(value);

		if (!aggregate) {
			aggregateValue = 0;
		} else if (aggregatePrefix.includes(".")) {
			const aggregatePrefixPropertiesArray = aggregatePrefix.split(".");

			aggregateValue = aggregatePrefixPropertiesArray.reduce((reportAggregate, aggregateKeyName) => {
				if (
					typeof reportAggregate[aggregateKeyName] === "object" &&
					reportAggregate[aggregateKeyName] !== null &&
					typeof reportAggregate[aggregateKeyName].amount === "number"
				)
					return reportAggregate[aggregateKeyName].amount;

				return reportAggregate[aggregateKeyName];
			}, aggregate);
		} else aggregateValue = aggregate[aggregatePrefix];

		if (typeof aggregateValue === "object") {
			aggregateValue = aggregateValue.amount;
		}
		if (aggregateValue !== 0) {
			insertedValuesMap[`${value}`] = true;
		}

		if (type === "DATE") {
			const labelValue = newLabelsValue.find((f) => {
				return f.t === Number(value);
			});
			if (labelValue) {
				labelValue.y = aggregateValue;
			}
		} else if (aggregate && aggregateValue !== 0) {
			if (type === "DAY_OF_MONTH") labelsValue[value - 1] = aggregateValue;
			else labelsValue[value] = aggregateValue;
		}
	});
	if (type === "DATE") {
		labelsValue = newLabelsValue.map((i) => i.y);
	}

	Object.keys(insertedValuesMap).forEach((key) => {
		if (insertedValuesMap[key] === false && parseInt(key) !== 0) {
			if (type === "DAY_OF_MONTH") labelsValue[parseInt(key) - 1] = 0;
			else labelsValue[parseInt(key)] = 0;
		}
	});

	return labelsValue;
};

const createDataSet = (data: number[], config?: Record<string, any>, color?: string, label?: string) => {
	const lineLabel = label ?? "";
	const defaultConfig = config ?? defaultChartConfig;
	return { ...defaultConfig, data, label: lineLabel };
};

const generateDataSet = (
	data: ReportApi,
	type: ChartDataRangeType,
	aggregatePrefix: string,
	selectedFilters: ListSelectedFilter[],
	config?: Record<string, any>
) => {
	const dataSets: ChartDataset<"line", number[]>[] = [];
	if (data.reports?.length === 0) {
		const val = createDataSet(getDataSet([], aggregatePrefix, type, selectedFilters), config);
		dataSets.push(val);
	}
	for (let i = 0; i < data.reports?.length; ++i) {
		const report = data.reports[i];
		if (!report || !report.sub_report) {
			break;
		}
		const subReports = report.sub_report;
		const val = createDataSet(getDataSet(subReports, aggregatePrefix, type, selectedFilters), config);
		dataSets.push(val);
	}
	return dataSets;
};

const generateChartData = (
	data: ReportApi,
	type: ChartDataRangeType,
	aggregatePrefix: AggregateOption,
	selectedFilters: ListSelectedFilter[],
	dataSetConfig?: Record<string, any>
) => {
	const dataSets = generateDataSet(data, type, aggregatePrefix, selectedFilters, dataSetConfig);
	return {
		labels: generateLabelValues(generateLabels(type, selectedFilters), type),
		datasets: dataSets,
	};
};

const parseToSelectedDateFilter = (filterValue: string): ListSelectedFilter[] => {
	return [
		{
			filterId: "date",
			value: filterValue,
		} as ListSelectedFilter,
	];
};
export {
	generateChartData,
	getDataSet,
	parseToSelectedDateFilter,
	generateDataSet,
	generateLabelValues,
	generateLabels,
};
