import React, { ChangeEvent, ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyImageSVG } from "../../../go-component/images/svg/angle-input.svg";

interface Props {
	selectedFile?: string;
	imageData?: string;
	onUpdateFile: (file: string, fileObj?: File) => void;
	fileIcon?: ReactElement;
	fileExtensions?: string[];
	customString?: string;
}

const FileUpload = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [errors, setErrors] = useState<string[] | undefined>([]);
	const inputFile = useRef<HTMLInputElement>(null);
	const handleChangeFile = () => {
		if (inputFile.current !== null) {
			inputFile.current.click();
		}
	};

	const updateFile = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const uploadedFile = event.target.files[0];
			if (props.fileExtensions && !props.fileExtensions.includes(uploadedFile.type.split("/")[1])) {
				setErrors([
					t("lib:constraints.invalid_image_format", {
						acceptableExtensions: props.fileExtensions.join(", "),
					}),
				]);
				return;
			}
			const file = URL.createObjectURL(event.target.files[0]);
			setErrors(undefined);
			props.onUpdateFile(file, event.target.files[0]);
		}
	};

	const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const files = e.dataTransfer?.files;
		if (files && files[0]) {
			const uploadedFile = files[0];
			if (props.fileExtensions && !props.fileExtensions.includes(uploadedFile.type.split("/")[1])) {
				setErrors([
					t("lib:constraints.invalid_image_format", {
						acceptableExtensions: props.fileExtensions.join(", "),
					}),
				]);
				return;
			}
			const file = URL.createObjectURL(uploadedFile);
			setErrors(undefined);
			props.onUpdateFile(file, files[0]);
		}
	};

	const handleClick = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		const el = evt.target as HTMLInputElement;
		el.value = "";
	};

	return (
		<>
			<div
				className="d-flex dropzone"
				onDragOver={(evt) => dragOver(evt)}
				onDragEnter={(evt) => dragEnter(evt)}
				onDragLeave={(evt) => dragLeave(evt)}
				onDrop={(evt) => fileDrop(evt)}
				onClick={handleChangeFile}
			>
				{props.fileIcon ? (
					props.fileIcon
				) : (
					<>
						{props.selectedFile ? (
							<img src={props.selectedFile} className="dropzone-image" />
						) : props.imageData ? (
							<img src={props.imageData} className="dropzone-image" />
						) : (
							<EmptyImageSVG className="m-auto" />
						)}
					</>
				)}
				{props.customString && <span>{props.customString}</span>}
				<input
					type="file"
					ref={inputFile}
					style={{ display: "none" }}
					onChange={updateFile}
					onClick={handleClick}
				/>
			</div>
			{errors &&
				errors.map((err, index) => {
					return (
						<div key={index} className={"invalid-feedback image-format-errors"}>
							{err}
						</div>
					);
				})}
		</>
	);
};
export default FileUpload;
