import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { ReportConfigColumn, SubReportApi } from "./types";

export const getAggregatePercentValue = (
	col: ReportConfigColumn,
	reportItem: SubReportApi,
	mainAggregate: any,
	aggregatePrefix?: string,
	isGroup?: boolean
): string => {
	const objectPrefix = aggregatePrefix;
	if (mainAggregate === null || mainAggregate === undefined) {
		return " (0.00%)";
	}
	const idParts = col.id.includes(".") ? col.id.split(".").map((columnPart) => columnPart) : [col.id];

	let value: any = null;
	let mainValue = idParts.reduce((prev, curr) => {
		if (prev) return prev[`${curr}`];
		return curr;
	}, mainAggregate);

	if (aggregatePrefix) {
		if (reportItem.aggregate[`${objectPrefix}`]) {
			value = idParts.reduce((prev, curr) => {
				if (prev) return prev[curr];
				return curr;
			}, reportItem.aggregate[`${objectPrefix}`]);
		}
	}

	if (typeof value === "object" && typeof mainValue === "object" && value !== null) {
		value = value.amount;
		mainValue = mainValue.amount;
	}

	if (col.isDate) {
		return col.render(value) ? col.render(value) : value !== undefined ? value : "-";
	}

	if (mainValue === 0 || mainValue === "0" || value === 0 || value === "0") return "(0.00%)";

	let calculatedValue = !isGroup ? (value / mainValue) * 100 : (mainValue / value) * 100;

	if (isNaN(calculatedValue)) {
		calculatedValue = 0;
	}

	return ` (${calculatedValue.toFixed(2)}%)`;
};

export const renderAggregateValue = (col: ReportConfigColumn, aggregate: any, aggregatePrefix?: string): string => {
	const columnParts = col.id.includes(".") ? col.id.split(".").map((columnPart) => columnPart) : [col.id];
	let value = null;

	if (aggregate) {
		value = columnParts.reduce(
			(prev, curr) => prev?.[curr],
			aggregatePrefix ? aggregate[`${aggregatePrefix}`] : aggregate
		);
	}

	if (col.isDate) return "";

	if (value === undefined || value === null || (typeof value !== "object" && isNaN(value))) return "-";

	if (aggregatePrefix) {
		if (!aggregate[`${aggregatePrefix}`]) {
			if (col.renderView) {
				return col.renderView(0);
			}
			if (col.render) {
				return `0.00`;
			}
			return "0";
		}

		if (col.renderView) {
			return col.renderView(value);
		}
		if (col.render) {
			return col.render(value);
		}
		return value;
	}
	if (col.renderView) {
		return col.renderView(value);
	}
	if (col.render) {
		return col.render(value);
	}
	return value;
};

export const getAggregateValue = (
	col: ReportConfigColumn,
	aggregate: any = {},
	aggregatePrefix?: string
): string | number => {
	const columnParts = col.id.includes(".") ? col.id.split(".").map((columnPart) => columnPart) : [col.id];
	const value = columnParts.reduce(
		(prev, curr) => prev?.[curr],
		aggregatePrefix ? aggregate[`${aggregatePrefix}`] : aggregate
	);

	if (col.isDate) return "";

	if (aggregatePrefix) {
		if (!aggregate || !aggregate[`${aggregatePrefix}`]) {
			return 0.0;
		}
		if (col.render) {
			return parseFloat(col.render(value)).toFixed(2);
		}
		return parseFloat(value).toFixed(2);
	}
	if (col.render) {
		return col.render(value);
	}
	return parseFloat(value);
};

export const renderAggregateComparePercentValue = (
	col: ReportConfigColumn,
	reportItem: any,
	prefix?: string,
	aggregatePrefix?: string
): string | number => {
	let aggValue, aggCompareValue;
	if (aggregatePrefix) {
		aggValue = Number(getAggregateValue(col, reportItem.aggregate[`${aggregatePrefix}`], prefix));
		aggCompareValue = Number(getAggregateValue(col, reportItem.compare_aggregate[`${aggregatePrefix}`], prefix));
	} else {
		aggValue = Number(getAggregateValue(col, reportItem.aggregate, prefix));
		aggCompareValue = Number(getAggregateValue(col, reportItem.compare_aggregate, prefix));
	}

	if (isNaN(aggValue) || isNaN(aggCompareValue)) return "-";

	if (aggValue === 0 || aggCompareValue === 0) {
		return "0";
	}
	const percentDiffFixed = Math.round((1 - aggValue / aggCompareValue) * 10000) / 100;
	return percentDiffFixed * -1;
};

export const getAggregateValueById = (
	col: string,
	aggregate: any,
	aggregatePrefix?: string,
	propsAggregatePrefix?: string
): number => {
	let agg;
	const objectPrefix = propsAggregatePrefix ? propsAggregatePrefix : "summary";

	if (aggregatePrefix) {
		col = col.replace(`_${aggregatePrefix}`, "");
		const columnParts = col.includes(".") ? col.split(".").map((columnPart) => columnPart) : [col];
		if (aggregate.aggregate[`${aggregatePrefix}`] && aggregate.aggregate[`${aggregatePrefix}`][`${objectPrefix}`]) {
			agg = columnParts.reduce(
				(prev, curr) => prev?.[curr],
				aggregate.aggregate[`${aggregatePrefix}`][`${objectPrefix}`]
			);
		} else {
			agg = 0;
		}
	} else {
		if (!aggregate.aggregate[`${objectPrefix}`]) {
			return 0;
		}
		const columnParts = col.includes(".") ? col.split(".").map((columnPart) => columnPart) : [col];
		agg = columnParts.reduce((prev, curr) => prev?.[curr], aggregate.aggregate[`${objectPrefix}`]);
	}

	if (typeof agg === "object") {
		return agg.amount;
	}
	return agg;
};

export const getVisibleColumns = (selectedColumns: string[], columns: ReportConfigColumn[]): ReportConfigColumn[] => {
	const newColumns = columns
		.filter((f) => selectedColumns.includes(f.id))
		.sort((a, b) => {
			return selectedColumns.indexOf(a.id) - selectedColumns.indexOf(b.id);
		});
	return newColumns;
};

export const isComparable = (params: ListSelectedFilter[]): boolean => {
	const f = params?.find((param) => param.filterId === "compare_date_range");
	return Boolean(f && f.value && f.value.length > 0);
};

export const renderGroupByName = (name: any): boolean => {
	return name === "empty" ? "-" : name;
};
export const renderGroupColumnName = (name: any, groups?: Array<Record<string, string>>): string => {
	const groupItem = groups ? groups.filter((x) => x.id === name)[0] : undefined;
	if (!groupItem) return name;
	return groupItem.name;
};
