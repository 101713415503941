import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { JPKInvoiceV7Api } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../../components/Errors/CompanyInfoNotFull";
import { JPKInvoiceType } from "../../../../services/types";
import { useInvoice } from "../../hooks/useInvoice";

const dateNow = new Date();

const OrganizationInvoicesJpkJpkV7Page = (): JSX.Element => {
	const { loading, setLoading, loadingCompanyInfo, companyInfo, previousInvoiceData, downloadJpk } = useInvoice(
		JPKInvoiceType.JPK_V7
	);
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const form = useForm<JPKInvoiceV7Api>({
		criteriaMode: "all",
	});
	const {
		control,
		formState: { errors },
		setError,
		reset,
		register,
		formState,
		handleSubmit,
	} = form;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.field.jpk_v7.title"));
	}, []);

	useEffect(() => {
		if (previousInvoiceData) {
			reset({ ...previousInvoiceData, date: formatDateToString(dateNow) });
		}
	}, [reset, previousInvoiceData]);

	const onSubmit = handleSubmit(async (data: JPKInvoiceV7Api) => {
		try {
			setLoading(true);
			const res = await api.organization().generateJpkV7(data);
			downloadJpk(res, data);
			reset(data);
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setLoading(false);
		}
	});

	const checkIfCanRenderForm = () => {
		return !(!companyInfo || !companyInfo.name || !companyInfo.tax_id_no);
	};

	if (!companyInfo || loadingCompanyInfo || !previousInvoiceData) return <LoadingContainer />;
	return (
		<>
			{(!companyInfo ||
				Object.keys(companyInfo).length === 0 ||
				!companyInfo.company_name ||
				!companyInfo.tax_id_no) && <CompanyInfoNotFull />}
			{checkIfCanRenderForm() && (
				<FormDirty
					loading={loading}
					onSubmit={onSubmit}
					formState={formState}
					buttonSubmitCustom={
						<ButtonLoading type={"submit"} variant={"primary"} loading={loading}>
							{t("modules.invoice.action.generate_jpkv7.title")}
						</ButtonLoading>
					}
				>
					<fieldset className={"form-group"}>
						<h5>
							{t("modules.invoice.field.jpkv7_legend.title")}
							<small className="text-muted">
								<br />
								{t("modules.invoice.field.jpkv7_legend.help_text.description")}
							</small>
						</h5>
						<div className="row align-items-center">
							<div className="col-md-6">
								<FormDatePicker
									allowClear={false}
									label={t("modules.invoice.field.jpkv7_date_from.title")}
									control={control}
									name="date"
									errors={errors}
									dayPicker={false}
									timePicker={false}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									name="tax_office_code"
									label={t("modules.invoice.field.jpkv7_tax_office_code.title")}
									errors={errors}
									register={register}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<FormInput
									name="full_name"
									label={t("common.word.full_company_name")}
									errors={errors}
									register={register}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									name="tax_id_no"
									register={register}
									errors={errors}
									label={t("common.word.tax_id_no.title", { ns: "lib" })}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<FormInput
									name="email"
									register={register}
									errors={errors}
									label={t("common.word.contact_email")}
								/>
							</div>
						</div>
						<FormCheck
							label={t("modules.invoice.action.jpkv7_extend.title")}
							type="switch"
							name="extended"
							errors={errors}
							register={register}
						/>
					</fieldset>
				</FormDirty>
			)}
		</>
	);
};

export default OrganizationInvoicesJpkJpkV7Page;
