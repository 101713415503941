import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import { getUrl } from "go-core";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Loading } from "go-core/components/Loading";
import { apiSecurity } from "go-security/services/Api/api";
import { selectIsAuthenticated } from "go-security/services/session/selectors";
import { OrganizationSecurityApi } from "../services/Api/typesSecurity";
import { useAuth } from "../services/auth";
import { UserActionTypes, UserOrganizationState } from "../services/users/types";

interface Props {
	companyId: string;
	appName: string;
	baseUrl: string;
}

const RegisterCompanyForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const [loading, setLoading] = useState(true);
	const [loadingRegister, setLoadingRegister] = useState(false);
	const [organization, setOrganization] = useState<OrganizationSecurityApi | undefined>(undefined);
	const location = useLocation();
	const history = useHistory();
	const auth = useAuth(props.baseUrl);
	const { addFlash } = useFlash();
	const dispatch = useDispatch();
	const fetch = async () => {
		try {
			const res = await apiSecurity.organization().getCompany(props.companyId);
			if (res.data.data.application) {
				history.push({ pathname: `/${res.data.data.company_id}`, state: { registerCompany: true } });
			}
			setOrganization(res.data.data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};
	useEffect(() => {
		if (isAuthenticated) {
			fetch();
		}
	}, []);

	if (!isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: "/login",
					search: `?ref=${getUrl(location)}`,
					state: { from: location },
				}}
			/>
		);
	}
	if (loading) {
		return <Loading />;
	}
	if (!organization) {
		return (
			<>
				`${t("lib:go_security.comopnents.location.constraints.location_does_not_exist")}: ${props.companyId}`
			</>
		);
	}

	const register = async (companyId: string) => {
		setLoadingRegister(true);
		try {
			const res = await apiSecurity.organization().createOrganizationApplication(companyId);
			dispatch({
				type: UserActionTypes.ADD_ORGANIZATION,
				payload: { ...res.data.data } as UserOrganizationState,
			});
			await auth.checkLogin();
			setLoadingRegister(false);
		} catch (e) {
			handleError.alert(e, addFlash);
			setLoadingRegister(false);
		}
	};
	const applicationName = props.appName;
	return (
		<>
			<h3>
				{`${t("lib:go_security.components.action.app_instalation")} ${applicationName} ${t(
					"lib:go_security.components.field.for_location"
				)} ${organization.name}`}
			</h3>

			<p>
				{t("lib:go_security.components.field.your_location")} <strong>{organization.name}</strong>
				{` ${t("lib:go_security.components.field.not_have_installed_app_yet")}
                ${applicationName}. ${t("lib:go_security.components.field.after_register")}.`}
			</p>
			<ButtonLoading
				loading={loadingRegister}
				type="submit"
				color="primary"
				onClick={() => register(organization?.company_id)}
			>
				{t("lib:common.action.register")}
				<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10.2778 1L16.5 7M16.5 7L10.2778 13M16.5 7L0.5 7"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</ButtonLoading>
		</>
	);
};

export default RegisterCompanyForm;
