import React, { ChangeEvent, FormEvent, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { hasErrorsApi } from "go-core";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { FormErrorMessageApi } from "go-form/components/FormErrorMessage";
import { apiSecurity } from "../services/Api/api";

interface Props {
	show: boolean;
	onHide: () => void;
	initialEmail: string;
}

const PasswordReminderModal = ({ onHide, show, initialEmail }: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<ApiError[]>([]);
	const { addFlash } = useFlash();
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [email, setEmail] = useState(initialEmail);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		setLoading(true);
		try {
			await apiSecurity.forgotPassword(email);
			setShowSuccessMessage(true);
		} catch (err: any) {
			const exceptionErrors = handleException(err.response?.data ? err.response?.data : err);
			const alertErrors = exceptionErrors.filter((f) => f.field === "all");
			if (alertErrors.length > 0) {
				addFlash({
					type: "danger",
					msg: alertErrors,
				});
			}
			setErrors(exceptionErrors);
		}
		setLoading(false);
	};

	const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	return (
		<Modal onHide={onHide} show={show}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("lib:go_security.password_reminder.header.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{showSuccessMessage ? (
						<span>{t("lib:go_security.lost_password.form.actions.remind_password_sent")}</span>
					) : (
						<Form.Group className="form-group">
							<Form.Label htmlFor="login" sm={3}>
								{t("lib:go_security.lost_password.form.email.label")}
							</Form.Label>
							<Form.Control
								disabled={loading}
								id="email"
								isInvalid={hasErrorsApi(errors, "email")}
								onChange={handleChangeEmail}
								type="email"
								value={email}
								defaultValue={email}
								placeholder={t("lib:go_security.lost_password.form.email.placeholder")}
							/>
							<FormErrorMessageApi errors={errors} field="email" />
						</Form.Group>
					)}
				</Modal.Body>
				{!showSuccessMessage && (
					<Modal.Footer>
						<ButtonLoading loading={loading} type="submit">
							{t("lib:go_security.password_reminder.action.remind_password.title")}
						</ButtonLoading>
					</Modal.Footer>
				)}
			</Form>
		</Modal>
	);
};

export default PasswordReminderModal;
