import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
	handleClick: () => void;
}

const CustomFieldShowDescriptionButton = ({ handleClick }: Props) => {
	const { t } = useTranslation();

	return (
		<span className="show-more form-text" onClick={handleClick}>
			{t("lib:common.action.more")}
		</span>
	);
};

export default CustomFieldShowDescriptionButton;
