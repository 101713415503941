import { UseFormReturn } from "react-hook-form";
import { FormCheck } from "go-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../../../services/Api/types";

interface Props {
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	item: ItemFormApi;
	pointOfSaleIndex: number;
}

const ItemGroupGroupEditingVirtualTablePointOfSaleInput = ({ form, item, pointOfSaleIndex }: Props) => {
	const {
		register,
		formState: { errors },
		control,
		watch,
		setValue,
	} = form;

	const searchDirections = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDirectionsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const handleOnChangeDirection = (id: string, obj: SearchSelectApi) => {
		setValue(
			`items.${item.index}.points_of_sale.${pointOfSaleIndex}.direction`,
			id
				? {
						id: Number(obj.id),
						name: obj.label,
				  }
				: undefined
		);
	};

	return (
		<>
			<FormCheck
				name={`items.${item.index}.points_of_sale.${pointOfSaleIndex}.visibility`}
				errors={errors}
				register={register}
				type="switch"
				disabled={!watch(`items.${item.index}.point_of_sale_visible`)}
			/>
			<FormSelectGroup
				errors={errors}
				control={control}
				name={`items.${item.index}.points_of_sale.${pointOfSaleIndex}.direction_id`}
				loadOptions={searchDirections}
				getOptionValue={(option) => Number(option.id)}
				disabled={!watch(`items.${item.index}.point_of_sale_visible`)}
				onChange={(id, fullObj) => handleOnChangeDirection(id, fullObj)}
				defaultValue={{
					id: watch(`items.${item.index}.points_of_sale.${pointOfSaleIndex}.direction`)?.id,
					label: watch(`items.${item.index}.points_of_sale.${pointOfSaleIndex}.direction`)?.name,
				}}
			/>
		</>
	);
};

export default ItemGroupGroupEditingVirtualTablePointOfSaleInput;
