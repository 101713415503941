import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { hasErrorsApi } from "go-core";
import Header from "go-app/components/Header";
import { ReactComponent as RemoveSVG } from "go-component/images/svg/remove.svg";
import { ApiError } from "go-core/api/types";
import { FormErrorMessageApi } from "go-form/components/FormErrorMessage";
import { useConfirmation } from "go-form/components/ModalConfirm";
import CatalogReportComboComponent from "../CatalogReportCombo/CatalogReportComboComponent";
import TemplateReportComboComponent from "./TemplateReportComboComponent";
import { CatalogReportCombo, TemplateReportCombo } from "./types";

interface Props {
	catalog: CatalogReportCombo[];
	template: TemplateReportCombo;
	errors: ApiError[];
	onClose: () => void;
	onRemove: () => void;
	onSave: (template: TemplateReportCombo) => void;
	onChange?: (template: TemplateReportCombo) => void;
}

const CreateTemplateReportComboComponent = (props: Props) => {
	const [template, setTemplate] = useState<TemplateReportCombo>(props.template);
	const confirmation = useConfirmation();
	const { t } = useTranslation();
	const catalog = props.catalog;
	const errors = props.errors;

	const onClose = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("go_form.form_dirty.message", { ns: "lib" }),
		});
		props.onClose();
	};

	const onRemove = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		props.onRemove();
	};

	const onSave = async () => {
		props.onSave(template);
	};

	const updateTemplateData = (data: any) => {
		if (!template) return;

		const newTemplate = { ...template };
		if (data.name) {
			newTemplate.name = data.name;
		}
		onUpdateTemplate(newTemplate);
	};
	const onChangeFilter = (filter: Record<string, string>) => {
		if (!template) return;

		const newTemplate = { ...template };
		newTemplate.filter = filter;
		onUpdateTemplate(newTemplate);
	};

	const onUpdateTemplate = (newTemplate: TemplateReportCombo) => {
		setTemplate(newTemplate);
		if (props.onChange) props.onChange(newTemplate);
	};

	return (
		<div>
			<Header title={`Raport > ${template.name}`} />
			{template && (
				<div className={"list-preview"}>
					<div className="list-preview-header">
						<div className="header-content">
							<div className="title">Edytor raportu</div>
							<div className={"info"}>
								Uzupełnij wymagane pola oraz wybierz szablony, które chcesz mieć na raporcie
							</div>
							<hr />
							<div className={"me-2"}>
								<Form.Group className="form-group">
									<Form.Label>Nazwa</Form.Label>
									<input
										type={"text"}
										value={template.name}
										onChange={(e) => updateTemplateData({ name: e.target.value })}
										className={`form-control${hasErrorsApi(errors, "name") ? " is-invalid" : ""}`}
									/>
									<FormErrorMessageApi errors={errors} field={`name`} />
								</Form.Group>
							</div>
						</div>
						<div className={"list-preview-close"}>
							<RemoveSVG className={"icon"} onClick={onClose} />
						</div>
					</div>
					<div className={"p-2"}>
						<CatalogReportComboComponent
							catalog={catalog}
							template={template}
							onChange={onUpdateTemplate}
						/>
					</div>

					<div className="position-sticky bg-white bottom-0 left-0 p-2 border-top border-default">
						<Button className={"btn btn-primary"} onClick={onSave}>
							Zapisz
						</Button>
						{` `}
						<Button className={"btn btn-danger"} onClick={onRemove}>
							Usuń
						</Button>
					</div>
				</div>
			)}
			<hr />

			<div>
				{template && template.id && template.templates.length > 0 ? (
					<TemplateReportComboComponent onChangeFilter={onChangeFilter} template={template} />
				) : (
					<>
						Skonfiguruj i wybierz szablon po prawej stronie. Zobacz w czasie rzeczywistym jak będzie
						wyglądał Twój raport!
					</>
				)}
			</div>
		</div>
	);
};

export default CreateTemplateReportComboComponent;
