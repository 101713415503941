import React, { FC, useEffect, useRef, useState } from "react";
import { DraggableEvent } from "react-draggable";
import { UseFormReturn } from "react-hook-form";
import { DraggableData, Position, ResizableDelta, Rnd } from "react-rnd";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import { RoomTableApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { useKeyDown } from "../hooks/useKeyDown";
import {
	getElementPositionMovedByKeyPress,
	getPixelValue,
	getRoomItemStyles,
	getXScale,
	getYScale,
} from "../utils/utils";
import { RoomFormProps } from "./RoomsPreview";
import TableEditModal from "./TableEditModal";
import Hooker from "./elements/Hooker";
import Rectangle from "./elements/Rectangle";
import Round from "./elements/Round";
import Square from "./elements/Square";
import CopyHandler from "./handlers/CopyHandler";
import EditHandler from "./handlers/EditHandler";
import RemoveHandler from "./handlers/RemoveHandler";
import RotateHandler from "./handlers/RotateHandler";

interface Props {
	table: RoomTableApi;
	form: UseFormReturn<RoomFormProps>;
	tableIndex: number;
	roomIndex: number;
	handleDirty: () => void;
}

const RoomTable: FC<Props> = ({ table, form, tableIndex, roomIndex, handleDirty }) => {
	const {
		setValue,
		reset,
		getValues,
		formState: { errors },
		clearErrors,
	} = form;
	const ref = useRef<any>(null);
	const [showModal, setShowModal] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const defaultStyles = {
		width: `${table.width}px`,
		height: `${table.height}px`,
		background: table.color,
		borderRadius: table.type === "HOKER" || table.type === "ROUND" ? "50%" : "6px",
	};
	const [pos, setPos] = useState({
		x: table.position_left,
		y: table.position_top,
	});
	const [styles, setStyles] = useState(defaultStyles);

	useEffect(() => {
		setStyles({
			...styles,
			height: `${table.height}px`,
			width: `${table.width}px`,
			borderRadius: table.type === "HOKER" || table.type === "ROUND" ? "50%" : "6px",
		});
		setPos({
			x: table.position_left,
			y: table.position_top,
		});
	}, [table]);

	const handleResize = (
		e: MouseEvent | TouchEvent,
		dir: string,
		elementRef: HTMLElement,
		delta: ResizableDelta,
		position: Position
	) => {
		setValue(
			`rooms.${roomIndex}.tables.${tableIndex}`,
			{
				...(getValues(`rooms.${roomIndex}.tables.${tableIndex}`) as RoomTableApi),
				width: getPixelValue(elementRef.style.width) < 0 ? 0 : getPixelValue(elementRef.style.width),
				height: getPixelValue(elementRef.style.height) < 0 ? 0 : getPixelValue(elementRef.style.height),
				position_top: position.y,
				position_left: position.x,
			},
			{ shouldDirty: true }
		);
		handleDirty();
	};

	const onResize = (
		e: MouseEvent | TouchEvent,
		dir: string,
		elementRef: HTMLElement,
		delta: ResizableDelta,
		position: Position
	) => {
		setStyles({ ...styles, width: elementRef.style.width, height: elementRef.style.height });
		setPos(position);
	};

	const handleMove = (e: DraggableEvent, data: DraggableData) => {
		const x = getXScale(data.x) < 0 ? 0 : getXScale(data.x);
		const y = getYScale(data.y) < 0 ? 0 : getYScale(data.y);
		setValue(`rooms.${roomIndex}.tables.${tableIndex}.position_left`, x, { shouldDirty: true });
		setValue(`rooms.${roomIndex}.tables.${tableIndex}.position_top`, y, { shouldDirty: true });
		setPos({ x, y });
		handleDirty();
	};

	const handleCopy = () => {
		const tables = getValues(`rooms.${roomIndex}.tables`) as RoomTableApi[];
		const copy = { ...table, position_left: 50, position_top: 50, name: `#${tables.length}`, id: undefined };
		setValue(`rooms.${roomIndex}.tables.${tables.length}`, copy);
		handleDirty();
	};

	const handleRemove = () => {
		const rooms = getValues(`rooms.${roomIndex}.tables`) as RoomTableApi[];
		const newTables = rooms.filter((f, index) => index !== tableIndex);
		const newObj = getValues().rooms.map((room, index) =>
			index === roomIndex ? { ...room, tables: newTables } : room
		);
		reset({ rooms: newObj });
		handleDirty();
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (editMode) {
			const el = document.getElementById(ref.current.props.id);
			if (el && !el.contains(event.target as Node)) {
				setEditMode(false);
			}
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	const handleEdit = () => {
		setShowModal(true);
	};
	const el = document.getElementById(`room-draggable-wrapper-table-${tableIndex}`);
	const handleRotate = () => {
		if (el) {
			el.style.height = `${table.width}px`;
			el.style.width = `${table.height}px`;
		}
		setStyles({ ...styles, width: `${table.height}px`, height: `${table.width}px` });
		setValue(`rooms.${roomIndex}.tables.${tableIndex}`, {
			...(getValues(`rooms.${roomIndex}.tables.${tableIndex}`) as RoomTableApi),
			width: table.height,
			height: table.width,
		});
		handleDirty();
	};

	const onEdit = (data: RoomTableApi) => {
		clearErrors(`rooms.${roomIndex}.tables.${tableIndex}.name`);
		setValue(`rooms.${roomIndex}.tables.${tableIndex}`, {
			...(getValues(`rooms.${roomIndex}.tables.${tableIndex}`) as RoomTableApi),
			...data,
		});
		setShowModal(false);
		handleDirty();
	};

	const onElementClick = () => {
		setEditMode(true);
	};
	const elStyles = {
		...styles,
		height: `${getPixelValue(styles.height) - 4}px`,
		width: `${getPixelValue(styles.width) - 4}px`,
		margin: "2px",
		background: "#E0E3EA",
	};

	const handleMoveByKeyPress = (key: "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown") => {
		const { x, y } = getElementPositionMovedByKeyPress(key, pos.x, pos.y, roomIndex, table.height, table.width);
		setValue(`rooms.${roomIndex}.tables.${tableIndex}.position_left`, x, { shouldDirty: true });
		setValue(`rooms.${roomIndex}.tables.${tableIndex}.position_top`, y, { shouldDirty: true });
		setPos({ x, y });
		handleDirty();
	};

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowDown");
		},
		["ArrowDown"],
		editMode
	);

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowRight");
		},
		["ArrowRight"],
		editMode
	);

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowUp");
		},
		["ArrowUp"],
		editMode
	);

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowLeft");
		},
		["ArrowLeft"],
		editMode
	);

	return (
		<>
			<Rnd
				ref={ref}
				style={{
					position: "absolute",
					background: editMode ? undefined : "inherit",
					zIndex: editMode ? 100 : 1,
				}}
				disableDragging={!editMode}
				enableResizing={editMode}
				onMouseDown={() => onElementClick()}
				cancel={".room-item-handler"}
				bounds={".room-body"}
				id={`room-draggable-wrapper-table-${tableIndex}`}
				className={"room-draggable-wrapper"}
				default={{
					x: table.position_left,
					y: table.position_top,
					width: table.width,
					height: table.height,
				}}
				position={pos}
				resizeHandleStyles={editMode ? getRoomItemStyles() : undefined}
				minHeight={48}
				minWidth={48}
				lockAspectRatio={table.type === "HOKER" || table.type === "ROUND" ? 1 : false}
				onResize={(e, dir, elementRef, delta, position) => onResize(e, dir, elementRef, delta, position)}
				onDragStop={(e, data) => handleMove(e, data)}
				onResizeStop={(e, dir, elementRef, delta, position) =>
					handleResize(e, dir, elementRef, delta, position)
				}
			>
				{table.type === "SQUARE" && (
					<Square name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
				)}
				{table.type === "ROUND" && (
					<Round name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
				)}
				{table.type === "RECTANGLE" && (
					<Rectangle name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
				)}
				{table.type === "HOKER" && (
					<Hooker name={table.name} styles={elStyles} guestAmount={table.number_of_seats} />
				)}
				{editMode && (
					<>
						<RemoveHandler
							handleRemove={handleRemove}
							styles={table.position_left <= 0 ? { left: "-15px" } : undefined}
						/>
						<EditHandler handleEdit={handleEdit} />
						<CopyHandler handleCopy={handleCopy} />
						<RotateHandler
							handleRotate={handleRotate}
							styles={table.position_left <= 0 ? { left: "-15px" } : undefined}
						/>
					</>
				)}
				<div className={"room-element-errors"}>
					<FormErrorMessage errors={errors} name={`rooms.${roomIndex}.tables.${tableIndex}.name`} />
				</div>
			</Rnd>
			{showModal && <TableEditModal handleUpdate={onEdit} table={table} onHide={() => setShowModal(false)} />}
		</>
	);
};
export default RoomTable;
