import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import AppModal from "../../components/Apps/AppModal";

interface Props {
	routeProps: RouteComponentProps;
	appConfigs?: ApplicationTemplateApi[];
	installedApps?: ApplicationApi[];
	handleUninstall: (app: ApplicationApi) => void;
	handleUpdate: (app: ApplicationApi) => void;
}

interface MatchParams {
	app_id: string;
}

const OrganizationAppsEditPage: FC<Props> = ({
	handleUninstall,
	routeProps,
	appConfigs,
	installedApps,
	handleUpdate,
}) => {
	const history = useHistory();
	const { addFlash } = useFlash();
	const { app_id } = useParams<MatchParams>();
	const app = installedApps ? installedApps.find((f) => f.id === Number(app_id)) : undefined;
	const [configApp, setConfigApp] = useState(
		appConfigs ? appConfigs.find((f) => f.provider === app?.provider) : undefined
	);
	const [installedApp, setInstalledApp] = useState(app);
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const params = new URLSearchParams(document.location.search);
	const { t } = useTranslation();

	useEffect(() => {
		if (!configApp || !installedApps) {
			Promise.all([api.organization().getApp(Number(app_id)), api.organization().getAppTemplates()])
				.then((res) => {
					handleChangeTabTitle(`${res[0].name || ""} | ${t("modules.app.field.all.title")}`);
					setInstalledApp(res[0]);
					setConfigApp(res[1].find((f: ApplicationTemplateApi) => f.provider === res[0].provider));
				})
				.catch((err) => {
					handleError.alert(err, addFlash);
				});
		} else {
			handleChangeTabTitle(`${installedApp?.name || ""} | ${t("modules.app.field.all.title")}`);
		}
	}, []);

	if (!configApp) {
		return <LoadingContainer />;
	}
	return (
		<AppModal
			routeProps={routeProps}
			handleUpdate={handleUpdate}
			handleUninstall={handleUninstall}
			viewType={params.get("edit") !== null ? "FORM" : undefined}
			configApp={configApp}
			installedApp={installedApp}
			onHide={() => history.push(`${routeProps.match.url}`)}
		></AppModal>
	);
};
export default OrganizationAppsEditPage;
