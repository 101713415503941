import { TFunction } from "react-i18next";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import {
	ItemFormApi,
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	MenuPriceListApi,
	MenuPriceListFlatApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn, VirtualTableListSelectedSort, VirtualTableSortingModeEnum } from "../types";

export const COLUMN_WITH_CELL_DIVIDER_BORDER_OFFSET = 9;
export const COLUMN_WITHOUT_CELL_DIVIDER_BORDER_OFFSET = 8;
export const STARTING_CSS_INDEX = 5;

export const getVirtualTableValueByKeyParts = (
	key: string,
	item: MenuPriceListFlatApi | ItemTranslationItemNameFormApi | ItemFormApi
) => {
	const keyParts = (key.includes(".") ? key.split(".").map((keyPart) => keyPart) : [key]) as (keyof (
		| MenuPriceListFlatApi
		| ItemTranslationItemNameFormApi
		| ItemFormApi
	))[];

	return keyParts.reduce((acc, curr) => {
		if (acc && curr in acc) {
			return acc[curr] as any;
		}
		return "";
	}, item);
};

export const getVirtualTableItemsAfterSortingAndFiltering = (
	items: (MenuPriceListFlatApi | ItemTranslationItemNameFormApi | ItemFormApi)[],
	sortings: VirtualTableListSelectedSort[],
	keys?: { columnName: string; key: string }[]
) => {
	const returnItems = [...items];
	return returnItems.sort((itemA, itemB) => {
		let sortingValue = 0;
		sortings.forEach((sorting) => {
			let itemAColumn: string | number = "";
			let itemBColumn: string | number = "";

			const columnKey = sorting.columnName as keyof (
				| MenuPriceListFlatApi
				| ItemTranslationItemNameFormApi
				| ItemFormApi
			);
			const valueA = itemA[columnKey] as string | Record<string, any>;
			const valueB = itemB[columnKey] as string | Record<string, any>;

			if (keys && keys.length > 0) {
				const keyForColumn = keys.find((key) => key.columnName === columnKey);
				if (keyForColumn) {
					if (typeof getVirtualTableValueByKeyParts(keyForColumn.key, itemA) === "object") return;
					if (typeof getVirtualTableValueByKeyParts(keyForColumn.key, itemB) === "object") return;

					itemAColumn = getVirtualTableValueByKeyParts(keyForColumn.key, itemA) as unknown as string | number;
					itemBColumn = getVirtualTableValueByKeyParts(keyForColumn.key, itemB) as unknown as string | number;
				} else {
					if (typeof valueA !== "object") {
						itemAColumn = valueA;
					}
					if (typeof valueB !== "object") {
						itemBColumn = valueB;
					}
				}
			} else {
				if (typeof valueA !== "object") {
					itemAColumn = valueA;
				}
				if (typeof valueB !== "object") {
					itemBColumn = valueB;
				}
			}
			let result;
			if (sorting.sortingMode === "+") {
				if (typeof itemAColumn === "number" && typeof itemBColumn === "number") {
					result = itemAColumn - itemBColumn;
				} else if (!itemAColumn && itemBColumn) result = 1;
				else if (itemAColumn && !itemBColumn) result = -1;
				else if (!itemAColumn && !itemBColumn) result = 0;
				else result = itemAColumn.toString().localeCompare(itemBColumn.toString());
			} else if (typeof itemAColumn === "number" && typeof itemBColumn === "number") {
				result = itemBColumn - itemAColumn;
			} else if (!itemBColumn && itemAColumn) result = -1;
			else if (itemBColumn && !itemAColumn) result = 1;
			else if (!itemBColumn && !itemAColumn) result = 0;
			else result = itemBColumn.toString().localeCompare(itemAColumn.toString());

			if (result !== 0) sortingValue = result;
		});
		return sortingValue;
	});
};

export const getVirtualTableHeaderStyles = (key: string, columnWidths: Record<string, number>) => {
	const column = document.getElementById(`column-${key}`);
	const subtract = column?.classList.contains("sticky-column--last")
		? COLUMN_WITH_CELL_DIVIDER_BORDER_OFFSET
		: COLUMN_WITHOUT_CELL_DIVIDER_BORDER_OFFSET;

	return {
		width: columnWidths[key] - subtract || 130,
	};
};

export const toggleSortForVirtualTableColumn = (
	columnName: string,
	sortings: VirtualTableListSelectedSort[],
	setSortings: (newSortings: VirtualTableListSelectedSort[]) => void
) => {
	const nextSortingMode = getNextSortingMode(getCurrentVirtualTableColumnSortingMode(columnName, sortings));
	if (nextSortingMode === "") {
		const newSortings = [...sortings];
		newSortings.splice(
			newSortings.findIndex((s) => s.columnName === columnName),
			1
		);
		setSortings([...newSortings]);
	} else {
		const sortIndex = sortings.findIndex((s) => s.columnName === columnName);
		if (sortIndex > -1) {
			const newSortings = [...sortings];
			const sort = newSortings.splice(sortIndex, 1)[0];
			sort.sortingMode = nextSortingMode as VirtualTableSortingModeEnum;
			setSortings([...newSortings, sort]);
		} else {
			setSortings([{ columnName, sortingMode: VirtualTableSortingModeEnum.ASC }, ...sortings]);
		}
	}
};

const getNextSortingMode = (sortingType: string) => {
	const sortingModes = ["", VirtualTableSortingModeEnum.ASC, VirtualTableSortingModeEnum.DESC];
	return sortingModes[(sortingModes.indexOf(sortingType) + 1) % sortingModes.length];
};

export const getCurrentVirtualTableColumnSortingMode = (
	columnName: string,
	sortings: VirtualTableListSelectedSort[]
) => {
	return sortings.find((s) => s.columnName === columnName)?.sortingMode || VirtualTableSortingModeEnum.EMPTY;
};

export const isVirtualTableColumnHide = (
	columnId: number | string,
	columns: (MenuPriceListApi | VirtualTableColumn | ItemTranslationItemNameTranslationFormApi)[]
) => {
	const visibleColumnsIds = columns.map((col) => col.id);
	return !visibleColumnsIds.includes(columnId);
};

export const handleCalcVirtualTableStickyColumnLeftPosition = (
	prevLeftPosition: number,
	prevColumn?: VirtualTableColumn
) => {
	const thElement = document.getElementById(`column-${prevColumn?.id}`);
	let leftPosition = prevLeftPosition;
	if (thElement) {
		const thElementWidth = thElement?.getBoundingClientRect().width ?? 0;
		leftPosition = prevLeftPosition + thElementWidth;
	}

	return leftPosition;
};

export const readVirtualTableSegmentFromUrl = (): string | null => {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get("s");
};

export const getVirtualTableColumnWidth = (key: string, columnWidths: Record<string, number>) => {
	const column = document.getElementById(`column-${key}`);
	const defaultColumnWidth = column?.getBoundingClientRect().width || 138;
	return columnWidths[key] || defaultColumnWidth;
};

export const getVirtualTableColumnStyles = (
	key: string,
	columnWidths: Record<string, number>,
	maxZIndex: number,
	index?: number
) => {
	return {
		minWidth: getVirtualTableColumnWidth(key, columnWidths),
		maxWidth: getVirtualTableColumnWidth(key, columnWidths),
		zIndex: index !== undefined ? maxZIndex - index : undefined,
	};
};

export const getVirtualTableStyles = (exportConfig: ExportConfig) => {
	return {
		bold: {
			bold: true,
		},
		small: {
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 3 : 6,
			color: "#9b9b9b",
		},
		header: {
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
			bold: true,
		},
		subheader: {
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
			bold: true,
			margin: [0, 10, 0, 5],
		},
		tableExample: {
			margin: [0, 10, 0, 0],
		},
		tableHeader: {
			bold: true,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 1 : 10,
			color: "#333a38",
		},
		tableCell: {
			bold: false,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
			color: "#464e4c",
		},
		tableCellNested: {
			bold: false,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
			color: "#464e4c",
			fillColor: "#bcc0c7",
		},
		evenDraftCell: {
			bold: false,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
			color: "#464e4c",
			fillColor: "#F0F0F0",
		},
		draftCell: {
			bold: false,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
			color: "#464e4c",
		},
	};
};
export const getVirtualTablePdfDocumentDefinition = (
	pageSize: string,
	exportConfig: ExportConfig,
	t: TFunction,
	filterDefinition: Record<string, any>,
	table: Record<string, any>
) => {
	let organizationName: string | undefined = undefined;
	let companyName: string | undefined = undefined;
	let taxIdNo: string | undefined = undefined;

	if (
		(exportConfig?.company && exportConfig.company !== "undefined") ||
		(exportConfig?.taxIdNo && exportConfig.taxIdNo !== "undefined")
	) {
		if (exportConfig?.company && exportConfig.company !== "undefined") companyName = exportConfig.company;
		if (exportConfig?.taxIdNo && exportConfig.taxIdNo !== "undefined") taxIdNo = exportConfig.taxIdNo;
	} else if (exportConfig?.organization && exportConfig.organization !== "undefined") {
		organizationName = exportConfig?.organization;
	}

	return {
		pageOrientation: exportConfig?.pdfOrientation ?? "landscape",
		pageSize,
		pageMargins: [20, 20, 20, 40],
		footer(currentPage: number, pageCount: number) {
			const d = new Date();
			const utc = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
			let footerFirstColumnText = "";

			if (organizationName) footerFirstColumnText += `${t("lib:common.word.venue")}: ${organizationName}`;
			if (companyName) footerFirstColumnText += `\n ${companyName}`;
			if (taxIdNo) footerFirstColumnText += `\n ${t("lib:common.word.tax_id_no.title")}: ${taxIdNo}`;
			footerFirstColumnText += `\n${utc} by GoPOS.pl`;

			return {
				columns: [
					{
						text: `${footerFirstColumnText}`,
						alignment: "left",
						fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
						margin: [20, organizationName ? 10 : 0, 0, 0],
					},
					{
						text: `${t("lib:common.word.page")} ${currentPage.toString()} ${t(
							"lib:common.word.from"
						)} ${pageCount}`,
						fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
						alignment: "right",
						margin: [0, 20, 20, 0],
					},
				],
			};
		},
		content: [
			{
				text: `${exportConfig?.title}`,
				bold: true,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 6 : 15,
				alignment: "center",
				margin: [0, 0, 0, 20],
			},
			filterDefinition,
			{
				style: "tableExample",
				color: "#444",
				table,
			},
		],
		styles: getVirtualTableStyles(exportConfig),
	};
};

export const getVisibleVirtualTableColumns = (columns: VirtualTableColumn[], selectedColumns: string[]) => {
	return columns
		.filter((column) => selectedColumns?.includes(column.id))
		.sort((a, b) => selectedColumns.indexOf(a.id) - selectedColumns.indexOf(b.id));
};

export const getVirtualTablePdfPageSize = (length: number) => {
	if (length < 2) {
		return "A4";
	}
	if (length < 10) {
		return "A3";
	}
	return "A2";
};
