import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { OrderPreparationStatusName } from "../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../services/context";
import { orderActivePreparationStatuses } from "../../../services/orderStatus";
import LiveOrderTableColumn from "./LiveOrderTableColumn";

const LiveOrderTable = () => {
	const organization = useSelector(selectOrganization);
	const orderListRoutePath = `/${organization.id}/live_orders/table`;
	const preparationStatuses: OrderPreparationStatusName[] = organization.more?.supported_preparation_statuses;
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();
	const ordersContext = useContext(OrdersContext);

	useEffect(() => {
		if (orderListRoutePath.endsWith("table")) {
			handleChangeTabTitle(
				`${t("modules.live_order.field.table.title")} | ${t("modules.live_order.header.title")}`
			);
		}
	}, [location.pathname]);

	const getStatusesToDraw = useCallback(() => {
		const statusFilter = ordersContext.filters.status.id;

		if (statusFilter === "ALL") return preparationStatuses;
		if (statusFilter === "ACTIVE") {
			return orderActivePreparationStatuses.filter((status) => preparationStatuses.includes(status));
		}

		const preparationStatus = preparationStatuses.find((status) => statusFilter === status);

		if (
			statusFilter === "EXTERNAL" &&
			preparationStatuses.find((status) => status === OrderPreparationStatusName.NEW)
		)
			return [OrderPreparationStatusName.NEW];

		return preparationStatus ? [preparationStatus] : [];
	}, [ordersContext.filters.status.id, preparationStatuses]);

	const drawContent = useMemo(() => {
		return getStatusesToDraw().map((status) => {
			const ordersWithStatus = ordersContext.orders.filter(
				(order) => order.preparation_status?.status === status
			);
			return (
				<LiveOrderTableColumn
					status={status}
					key={status}
					orders={ordersWithStatus}
					orderListRoutePath={orderListRoutePath}
				/>
			);
		});
	}, [JSON.stringify(ordersContext.orders), getStatusesToDraw]);

	return preparationStatuses.length > 0 ? <div className="live-orders-table">{drawContent}</div> : <EmptyList />;
};

export default LiveOrderTable;
