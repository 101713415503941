import { useMemo } from "react";
import { ButtonGroup } from "react-bootstrap";
import { DashboardType } from "../../services/types";
import useDashboardContext from "../../services/useDashboardContext";
import { DashboardOrganizationSearchSelect } from "./DashboardOrganizationSearchSelect";
import DashboardFilterDateRange from "./FilterDateRange";
import FilterItem from "./FilterItem";

interface Props {
	filters: string[];
	isPredefinedDate: boolean;
}

const FilterList = ({ filters, isPredefinedDate }: Props): JSX.Element => {
	const { dashboardType } = useDashboardContext();

	const renderFilters = useMemo(() => {
		return (
			<>
				<ButtonGroup className={"me-2 text-nowrap flex-wrap dashboard-filters-buttons"}>
					{filters.map((filter) => {
						return <FilterItem key={filter} type={filter} />;
					})}
				</ButtonGroup>
				<DashboardFilterDateRange isPredefinedDate={isPredefinedDate} />
				{dashboardType === DashboardType.GROUP_ORGANIZATIONS && <DashboardOrganizationSearchSelect />}
			</>
		);
	}, [filters, isPredefinedDate, dashboardType]);

	return <div className="dashboard-filter">{renderFilters}</div>;
};
export default FilterList;
