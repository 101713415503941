import React, { ChangeEvent, useCallback, useContext, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useListenEvent } from "go-core/hooks";
import { DropdownOption } from "go-list/core/components/Filter/components/SearchSelect/components/DropdownOption";
import { selectUser } from "go-security/services/users/selectors";
import { UserState } from "go-security/services/users/types";
import { DashboardContext } from "../..";
import { DashboardFilterActions } from "../../services/filters/reducer";
import { DashboardOrganizationOption } from "../../services/filters/types";

const getFilterConfig = (t: TFunction, user: UserState) => ({
	id: "organization_id",
	name: t("modules.dashboard.field.organizations.title"),
	type: "search_select",
	isRemovable: false,
	filterOptions: (): DashboardOrganizationOption[] => {
		return (
			user.organizations?.map((organization) => ({
				id: organization.id,
				label: organization.name,
			})) || []
		);
	},
});

const getFilterDropdownValues = (
	noneFilterItemSelected: boolean,
	uniqueOptions: DashboardOrganizationOption[],
	t: TFunction,
	user: UserState,
	selectedValues: string[]
) => {
	if (noneFilterItemSelected) return "";

	const possibleFilterItems = getFilterConfig(t, user).filterOptions();
	const selectedValuesLabels = selectedValues.map(
		(selectedValue) => possibleFilterItems.find((filterItem) => filterItem.id.toString() === selectedValue)?.label
	);

	return selectedValuesLabels.join(", ");
};

interface Props {
	setSelectedOrganizations?: (organizations: string) => void;
	selectedOrganizations?: string;
}

export const DashboardOrganizationSearchSelect = ({
	setSelectedOrganizations,
	selectedOrganizations,
}: Props): JSX.Element => {
	const user = useSelector(selectUser);
	const { t } = useTranslation();
	const { dispatch } = useContext(DashboardContext);
	const [selectedValues, setSelectedValues] = useState<string[]>(() =>
		selectedOrganizations !== undefined
			? selectedOrganizations.split(",")
			: getFilterConfig(t, user)
					.filterOptions()
					.map((filterOption) => filterOption.id.toString())
	);
	const [areAllSelected, setAreAllSelected] = useState(
		selectedOrganizations !== undefined
			? selectedOrganizations.split(",").length ===
					getFilterConfig(t, user)
						.filterOptions()
						.map((filterOption) => filterOption.id.toString()).length
			: true
	);
	const [showDropdown, setShowDropdown] = useState(false);
	const [search, setSearch] = useState("");
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [uniqueOptions, setUniqueOptions] = useState<DashboardOrganizationOption[]>(() =>
		getFilterConfig(t, user).filterOptions()
	);
	const isMobile = useWindowSize().isMobile;

	const filterDropdownValue = getFilterDropdownValues(
		selectedValues.length === 0,
		uniqueOptions,
		t,
		user,
		selectedValues
	);

	const isOptionSelected = useCallback(
		(option) => {
			return selectedValues.includes(option.id.toString());
		},
		[selectedValues]
	);

	const toggleSelectAll = () => {
		setAreAllSelected((state) => !state);

		if (areAllSelected) setSelectedValues([]);
		else {
			const filterOptionsIds = getFilterConfig(t, user)
				.filterOptions()
				.map((filterOption) => filterOption.id.toString());
			setSelectedValues(filterOptionsIds || []);
		}
	};

	const onCloseDropdown = () => {
		if (!isMobile) {
			dispatch({
				type: DashboardFilterActions.CHANGE_ORGANIZATIONS_FILTER,
				data: selectedValues.join(","),
			});
		} else if (setSelectedOrganizations) setSelectedOrganizations(selectedValues.join(","));
	};

	const onSelectItem = (id: string) => {
		if (selectedValues.includes(id)) {
			const idIndex = selectedValues.indexOf(id);
			setSelectedValues((selectedValues) => {
				const newSelectedValues = [...selectedValues];
				newSelectedValues.splice(idIndex, 1);
				return newSelectedValues;
			});
			setAreAllSelected(false);
		} else {
			const newSelectedValues = [...selectedValues, id];
			const allItemsIdsFromConfig = getFilterConfig(t, user)
				.filterOptions()
				.map((filterOption) => filterOption.id)
				.sort()
				.join(",");
			const allCurrentSelectedItemsIds = [...newSelectedValues].sort().join(",");
			const allItemsAreSelected = allItemsIdsFromConfig === allCurrentSelectedItemsIds;

			if (allItemsAreSelected) setAreAllSelected(true);

			setSelectedValues(newSelectedValues);
		}
	};

	const openDropdown = () => setShowDropdown(true);

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target?.value;
		setSearch(inputValue);
		const newUniqueOptions = getFilterConfig(t, user)
			.filterOptions()
			.filter((filterOption) => filterOption.label.toUpperCase().includes(inputValue.toUpperCase()));

		setUniqueOptions(newUniqueOptions);
	};

	const handleClickOutside = (e?: MouseEvent) => {
		if (!e) return;
		if (dropdownRef.current && !dropdownRef.current?.contains(e.target as Node)) {
			onCloseDropdown();
			setShowDropdown(false);
		}
	};

	useListenEvent({ eventName: "click", callback: handleClickOutside });

	return (
		<Dropdown ref={dropdownRef} show={showDropdown} onClick={openDropdown} className="filters-list">
			<Dropdown.Toggle
				className={`dashboard-search-select-toggle d-inline-block ${
					isMobile ? "dashboard-search-select-toggle--mobile" : ""
				}`}
			>
				{isMobile ? (
					<div className="inner-container">
						<span>{`${t("common.word.venue", { ns: "lib" })}: `}</span>
						<span className="filter-value">{filterDropdownValue}</span>
					</div>
				) : (
					<>
						<span>{`${t("common.word.venue", { ns: "lib" })}: `}</span> {filterDropdownValue}
					</>
				)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Form.Control
					type="search"
					placeholder={t("lib:common.word.search")}
					className="filter-input mb-2"
					onChange={handleSearch}
					value={search}
				/>
				<Form.Group className="form-group list-search-select-container">
					{uniqueOptions && uniqueOptions.length > 0 && (
						<div className="list-search-select-select-all">
							<DropdownOption
								id="select_all"
								dropDownItemId="type_select_all"
								label={t("lib:go_list.filters.select_all")}
								isSelected={areAllSelected}
								onClick={toggleSelectAll}
							/>
						</div>
					)}
					{uniqueOptions?.map((option) => (
						<DropdownOption
							id={option.id.toString()}
							key={`type_${getFilterConfig(t, user).id}_${option.id}`}
							dropDownItemId={`type_${getFilterConfig(t, user).id}_${option.id}`}
							label={option.label}
							isSelected={isOptionSelected(option)}
							onClick={onSelectItem}
						/>
					))}
				</Form.Group>
			</Dropdown.Menu>
		</Dropdown>
	);
};
