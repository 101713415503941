import React, { FC, useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import { Button, Modal } from "react-bootstrap";
import Cropper from "react-cropper";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";

interface Props {
	handleClose: () => void;
	handleSave: (baseString: string) => void;
	image: string | undefined;
	defaultImage: string | undefined;
	unlimitedAspect?: boolean;
}

const getImage = (image?: string, imageData?: string) => {
	const imageDataIsNotALink = !imageData?.startsWith("https") && imageData !== undefined;
	if (imageDataIsNotALink) return imageData;

	return image ?? imageData;
};

const ImageCropperModal: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const image = getImage(props.image, props.defaultImage);
	const [cropStart, setCropStart] = useState(false);
	const [cropper, setCropper] = useState<Cropper | undefined>();
	const [zoomTo, setZoomTo] = useState(0);
	const [minRange, setMinRange] = useState(0);
	const { addFlash } = useFlash();
	const firstRender = useRef(true);
	const getCropData = () => {
		if (typeof cropper !== "undefined") {
			try {
				props.handleSave(cropper.getCroppedCanvas().toDataURL());
			} catch (e) {
				addFlash({ type: "danger", msg: "wrong_image" });
			}
		}
	};

	const handleClickOutside = (evt: MouseEvent) => {
		if (cropStart) {
			evt.stopPropagation();
		}
	};

	useEffect(() => {
		cropper?.zoomTo(zoomTo);
	}, [zoomTo]);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});
	return (
		<Modal size="lg" show={true} onHide={props.handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:go_component.image_form.action.edit_image")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Cropper
					style={{ marginBottom: "10px", height: 400, width: "100%" }}
					zoomTo={zoomTo}
					zoom={(e: any) => {
						if (e?.detail?.ratio > 1) {
							setZoomTo(1);
							e.preventDefault();
						}
					}}
					src={image}
					viewMode={2}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					responsive={true}
					autoCropArea={1}
					checkOrientation={false}
					checkCrossOrigin={true}
					onInitialized={(instance) => {
						setCropper(instance);
					}}
					cropstart={() => setCropStart(true)}
					cropend={() => {
						setCropStart(false);
					}}
					crop={(evt: any) => {
						const cropperData = evt.target?.cropper?.getCanvasData();
						const zoom = cropperData.width / cropperData.naturalWidth;
						if (firstRender.current && !isNaN(zoom)) {
							setMinRange(zoom);
							firstRender.current = false;
						}
						if (!isNaN(zoom)) {
							setZoomTo(zoom);
						}
					}}
					guides={true}
				/>
				<input
					className={"w-100"}
					onChange={(evt) => setZoomTo(parseFloat(evt.target.value))}
					type={"range"}
					min={minRange}
					max={1}
					value={zoomTo}
					step={0.001}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" type="submit" onClick={getCropData}>
					{t("lib:common.action.save")}
				</Button>
				<Button variant="light" onClick={props.handleClose}>
					{t("lib:common.action.close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default ImageCropperModal;
