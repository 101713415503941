import React from "react";
import { OrderPreparationStatusName } from "../../../../../../../services/Api/Organization/types";
import { getOrderPreparationStatusColor, preparationStatusColors } from "../services/orderStatus";

interface Props {
	status: OrderPreparationStatusName;
}

const LiveOrderStatus = ({ status }: Props): JSX.Element => {
	return (
		<div
			className={`live-order-status ${
				getOrderPreparationStatusColor(status) === preparationStatusColors.WHITE
					? "live-order-status--gray-bordered"
					: ""
			}`}
			style={{
				backgroundColor: getOrderPreparationStatusColor(status),
			}}
		/>
	);
};

export default LiveOrderStatus;
