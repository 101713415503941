import React, { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { TimeOption } from "../../../utils/utils";

interface GoOrderAcceptanceTimeSettingProps {
	form: UseFormReturn<ApplicationApi>;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<ApplicationApi>[]>>;
}

export const GoOrderAcceptanceTimeSetting = ({
	form: {
		control,
		formState: { errors },
		watch,
	},
	setErrors,
}: GoOrderAcceptanceTimeSettingProps) => {
	const { t } = useTranslation();

	const timeUnitsOptions = [
		{ value: TimeOption.SECOND, label: t("lib:enums.time_parts.plural.second_1") },
		{ value: TimeOption.HOUR, label: t("lib:enums.time_parts.plural.hour_1") },
		{ value: TimeOption.DAY, label: t("lib:enums.time_parts.plural.day") },
	];

	const timeUnit = watch("settings.time_unit");
	const numberOfTimeUnits = watch("settings.number_of_time_units");

	const numberOfTimeUnitsValidationConfig = useMemo(
		() => ({
			types: [
				{
					constraint: CustomValidationConstraint.REQUIRED_IF,
					isRequired: () => timeUnit !== undefined && timeUnit !== null,
				},
			],
			setErrors,
		}),
		[setErrors, timeUnit]
	) satisfies CustomValidationConfig;

	const timeUnitValidationConfig = useMemo(
		() => ({
			types: [
				{
					constraint: CustomValidationConstraint.REQUIRED_IF,
					isRequired: () =>
						numberOfTimeUnits !== undefined && numberOfTimeUnits !== null && Number(numberOfTimeUnits) > 0,
				},
			],
			setErrors,
		}),
		[setErrors, numberOfTimeUnits]
	) satisfies CustomValidationConfig;

	return (
		<div className="acceptance-time-setting">
			<div className="inputs-container">
				<FormNumberInput
					name="settings.number_of_time_units"
					suffix=""
					errors={errors}
					control={control}
					label={t("modules.app.field.acceptance_time.title")}
					customValidationConfig={numberOfTimeUnitsValidationConfig}
				/>
				<FormSelectGroup
					errors={errors}
					options={timeUnitsOptions}
					control={control}
					name="settings.time_unit"
					customValidationConfig={timeUnitValidationConfig}
					isClearable
					data-testid="settings.time_unit"
				/>
			</div>
		</div>
	);
};
