import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { MessageEventContext } from "./services/context";
import { MessageEventApi } from "./services/types";

interface Props {
	onHide: () => void;
	resourceId: number | string;
	resourceType: string;
	path: string;
	organizationId?: number;
}

const MessageEventModal: FC<Props> = ({ onHide, resourceId, resourceType, path, organizationId }) => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [events, setEvents] = useState<MessageEventApi[] | undefined>(undefined);
	const messageEventService = React.useContext(MessageEventContext);
	const fetch = async () => {
		const encoded = btoa(
			unescape(
				encodeURIComponent(
					`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_id=${resourceId}${FILTER_SEPARATOR}resource_type|e=${resourceType}`
				)
			)
		);
		const params: Record<string, any> = { page: 0, size: 5, f: encoded, include: "terminal" };
		try {
			const res = (await messageEventService.api().all(params)).data.data;
			setEvents(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};
	useEffect(() => {
		fetch();
	}, []);

	const findAllURI = () => {
		return btoa(
			unescape(
				encodeURIComponent(
					`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_id|e=${resourceId}${FILTER_SEPARATOR}resource_type|e=${resourceType}`
				)
			)
		);
	};
	return (
		<Modal size={"lg"} show={true} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:common.word.logs")}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ minHeight: "100px" }}>
				{!events ? (
					<LoadingContainer />
				) : (
					<>
						<div className="table-responsive">
							<table className="table table-preview">
								<thead>
									<tr>
										<th>{t("lib:common.word.created_at")}</th>
										<th>{t("lib:go_component.message_events.list.columns.initiator")}</th>
										<th>{t("lib:common.word.type")}</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{events.map((event, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="d-flex flex-column">
														{FormatDate(event.created_at, undefined, true)}
														<small className="text-muted">
															{t("lib:common.word.source")}:{" "}
															{t(`lib:enums.message_events.source.${event.source}`)}
														</small>
													</div>
												</td>
												<td>
													{event.terminal?.id && event.terminal.name && organizationId ? (
														<Link
															to={`/${organizationId}/settings/terminals/${event.terminal.id}`}
														>
															{event.terminal.name}
														</Link>
													) : (
														event.username
													)}
												</td>
												<td>{messageEventService.getMessageEventTypeTitle(event.type)}</td>
												<td className="w-1 text-end pr-0">
													<Link
														className={"btn btn-add mb-0"}
														target={"_blank"}
														rel="noreferrer"
														to={`${path}/${event.id}`}
													>
														{t("lib:common.action.preview")}
													</Link>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						<Link
							target="_blank"
							rel="noreferrer"
							to={`${path}?f=${findAllURI()}`}
							className={"btn btn-add"}
						>
							{t("lib:common.action.show_all")}
						</Link>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
};
export default MessageEventModal;
