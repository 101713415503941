import React, { FC, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as CloseSVG } from "../../../../../../../../../../images/svg/liveOrders/close.svg";
import { ReactComponent as LocationSVG } from "../../../../../../../../../../images/svg/liveOrders/location.svg";
import { DeliveryEmployeeApi, LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import LiveOrdersListItem from "../../../../components/LiveOrdersListItem";
import { OrdersContext } from "../../../../services/context";
import {
	calculateEmployeeDistance,
	getOrganizationCoords,
	hasEmployeeCoords,
} from "../../../../services/orderDelivery";
import LiveOrderDeliveryEmployeeAvatar from "../LiveOrderDeliveryEmployeeAvatar";

interface Props {
	employee: DeliveryEmployeeApi;
	orders: LiveOrderApi[];
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	focusedOrder: LiveOrderApi | undefined;
	noLastActivity: boolean;
	timeAgo: string;
}

const LiveOrdersMapDeliveryEmployeeMarkerOrdersList: FC<Props> = ({
	employee,
	orders,
	setFocusedOrder,
	noLastActivity,
	timeAgo,
}) => {
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const popupRef = useRef<any>(null);
	const { organizationAddress } = useContext(OrdersContext);
	const { t } = useTranslation();
	const organizationCoords = getOrganizationCoords(organizationAddress);

	const getEmployeeDistanceFromRestaurant = () =>
		`${calculateEmployeeDistance(employee, organizationCoords, t)?.distance} ${
			calculateEmployeeDistance(employee, organizationCoords, t)?.measure
		}`;

	const handleClosePopup = () => {
		if (popupRef.current) {
			popupRef.current._closeButton.click();
		}
	};

	const onOrderFocus = (order: LiveOrderApi) => {
		handleClosePopup();
		setTimeout(() => {
			setFocusedOrder(order);
			history.push(`/${organization.id}/live_orders/map/orders/${order.id}`);
		}, 10);
	};

	const drawOrders = () => {
		return orders.length > 0 ? (
			orders.map((order) => (
				<LiveOrdersListItem
					order={order}
					onSelectOrder={() => onOrderFocus(order)}
					displayAsHeader={false}
					key={`employee-order-${order.id}`}
					withoutDeliveryEmployee
				/>
			))
		) : (
			<div className="live-orders-list-item">
				<h5 className="text-truncate mb-0">{t("modules.live_order.field.without_order.title")}</h5>
			</div>
		);
	};

	return (
		<Popup className="delivery" ref={popupRef}>
			<div className="header">
				<LiveOrderDeliveryEmployeeAvatar employee={employee} />
				<div className="d-flex flex-column">
					{employee?.employee?.name ? (
						<Link className="employee-name" to={`/${organization.id}/employees/${employee.employee_id}`}>
							{employee.employee.name}
						</Link>
					) : (
						<h5 className="employee-name">{employee.source.name}</h5>
					)}
					<span>
						{noLastActivity ? (
							<LocationSVG className="me-1 live-orders-red-icon mb-1" />
						) : (
							<LocationSVG className="me-1 mb-1" />
						)}
						{noLastActivity && (
							<span className="text-danger me-2">
								{`${t("modules.live_order.field.last_update.title")} ${timeAgo}`}
							</span>
						)}
						{hasEmployeeCoords(employee) && (
							<span className="text-muted">{getEmployeeDistanceFromRestaurant()}</span>
						)}
					</span>
				</div>
				<CloseSVG onClick={handleClosePopup} className="close-button" />
			</div>
			{drawOrders()}
		</Popup>
	);
};

export default LiveOrdersMapDeliveryEmployeeMarkerOrdersList;
