import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { OrganizationInfoApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import OrganizationBasicInfoForm from "./components/OrganizationBasicInfoForm";

const OrganizationSettingsInfoBasicPage = (): JSX.Element => {
	const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfoApi | undefined>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.organization_info.title"));
		onFetchOrganizationInfo();
	}, []);

	const onFetchOrganizationInfo = async () => {
		try {
			const res = await api.organization().getOrganizationInfo();
			setOrganizationInfo(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!organizationInfo) return <LoadingContainer />;

	return <OrganizationBasicInfoForm organizationInfo={organizationInfo} />;
};

export default OrganizationSettingsInfoBasicPage;
