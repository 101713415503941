import { createContext } from "react";
import { AxiosResponse } from "axios";
import { AttributeProps } from "../AttributesTable";

interface CsvImportContext {
	importCsv?: (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		params: Record<string, string>
	) => Promise<any>;
	finishImportCsv?: (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>
	) => Promise<AxiosResponse>;
	getCsvExampleSimpleFile: (resourceType: string) => Promise<AxiosResponse>;
	getCsvExampleFile: (resourceType: string) => Promise<AxiosResponse>;
	getCsvFromVenue: (resourceType: string) => Promise<AxiosResponse>;
	getCsvAttributes: (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>
	) => Promise<AttributeProps[]>;
	finishImportAsyncCsv?: (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		options: Record<string, any>
	) => Promise<AxiosResponse>;
	importAsyncCsv?: (
		resourceType: string,
		formData: FormData,
		headers: Record<string, string>,
		params: Record<string, string>,
		options: Record<string, any>
	) => Promise<any>;
	getFinishImportCsvProgress?: (
		jobUid: string,
		resourceType: string,
		options?: Record<string, any>
	) => Promise<{ job_status: string; job_uid: string }>;
	getImportCsvProgress?: (
		jobUid: string,
		resourceType: string,
		params: Record<string, string>,
		options?: Record<string, any>
	) => Promise<AxiosResponse>;
}

export const CsvContext = createContext<CsvImportContext>({} as CsvImportContext);
