import React, { Suspense, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import Segment from "go-segment/components";
import { SegmentContext } from "go-segment/context";

const listName = "SEGMENT";
const resourceType = "SEGMENT";

const OrganizationSettingsSegmentsPage = (): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const resourceFilterTypes = {
		ORDER_REPORT: t("enums.common.resource_type.ORDER_REPORT"),
		JOB: t("enums.common.resource_type.JOB"),
		REQUEST_ITEM: t("enums.common.resource_type.REQUEST_ITEM"),
		ORGANIZATION_USERS: t("enums.common.resource_type.ORGANIZATION_USERS"),
		WEBHOOK: t("enums.common.resource_type.WEBHOOK"),
		APP_LOGS: t("enums.common.resource_type.APP_LOGS"),
		APP_LINK: t("enums.common.resource_type.APP_LINK"),
		CLIENT_GROUP: t("enums.common.resource_type.CLIENT_GROUP"),
		CLIENT: t("enums.common.resource_type.CLIENT"),
		EMPLOYEE: t("enums.common.resource_type.EMPLOYEE"),
		ORDER: t("enums.common.resource_type.ORDER"),
		POS_REPORT_PRINT_SETTINGS: t("enums.common.resource_type.POS_REPORT_PRINT_SETTINGS"),
		WORK_TIME: t("enums.common.resource_type.WORK_TIME"),
		ONLINE_EMPLOYEE: t("enums.common.resource_type.ONLINE_EMPLOYEE"),
		GROUP_PRICE_LISTS: t("enums.common.resource_type.GROUP_PRICE_LISTS"),
		WORKPLACE: t("enums.common.resource_type.WORKPLACE"),
		WORK_TIME_REPORT: t("enums.common.resource_type.WORK_TIME_REPORT"),
		INVOICE: t("enums.common.resource_type.INVOICE"),
		PROFORMA: t("enums.common.resource_type.PROFORMA"),
		EMPLOYEE_ACTIVITY: t("enums.common.resource_type.EMPLOYEE_ACTIVITY"),
		MESSAGE_EVENT: t("enums.common.resource_type.MESSAGE_EVENT"),
		CATEGORY: t("enums.common.resource_type.CATEGORY"),
		DISCOUNT: t("enums.common.resource_type.DISCOUNT"),
		ITEM_GROUP: t("enums.common.resource_type.ITEM_GROUP"),
		MODIFIER_GROUP: t("enums.common.resource_type.MODIFIER_GROUP"),
		MENU_PRICE_LIST: t("enums.common.resource_type.MENU_PRICE_LIST"),
		CATEGORY_REPORT: t("enums.common.resource_type.CATEGORY_REPORT"),
		EFFICIENCY_REPORT: t("enums.common.resource_type.EFFICIENCY_REPORT"),
		PAYMENT_METHOD_REPORT: t("enums.common.resource_type.PAYMENT_METHOD_REPORT"),
		STORNO_REPORT: t("enums.common.resource_type.STORNO_REPORT"),
		PRODUCT_REPORT: t("enums.common.resource_type.PRODUCT_REPORT"),
		RESERVATION: t("enums.common.resource_type.RESERVATION"),
		POS_REPORT: t("enums.common.resource_type.POS_REPORT"),
		TRANSACTION: t("enums.common.resource_type.TRANSACTION"),
		AVAILABILITY: t("enums.common.resource_type.AVAILABILITY"),
		CURRENCY: t("enums.common.resource_type.CURRENCY"),
		CUSTOM_FIELD: t("enums.common.resource_type.CUSTOM_FIELD"),
		DEFAULT_COMMENT: t("enums.common.resource_type.DEFAULT_COMMENT"),
		DEVICE: t("enums.common.resource_type.DEVICE"),
		PAYMENT_METHOD: t("enums.common.resource_type.PAYMENT_METHOD"),
		PRINTOUT_TEMPLATE: t("enums.common.resource_type.PRINTOUT_TEMPLATE"),
		DIRECTION: t("enums.common.resource_type.DIRECTION"),
		POINT_OF_SALE: t("enums.common.resource_type.POINT_OF_SALE"),
		TAG: t("enums.common.resource_type.TAG"),
		TAX: t("enums.common.resource_type.TAX"),
		TERMINAL: t("enums.common.resource_type.TERMINAL"),
		ORDER_REPORT_GROUP: t("enums.common.resource_type.ORDER_REPORT_GROUP"),
		CATEGORY_REPORT_GROUP: t("enums.common.resource_type.CATEGORY_REPORT_GROUP"),
		PAYMENT_METHOD_REPORT_GROUP: t("enums.common.resource_type.PAYMENT_METHOD_REPORT_GROUP"),
		PRODUCT_REPORT_GROUP: t("enums.common.resource_type.PRODUCT_REPORT_GROUP"),
	};

	const hyperLinksConfig = [
		{
			list_name: "ORDER_REPORT",
			url: `/${organization.id}/reports/orders`,
		},
		{
			list_name: "JOB",
			url: `/${organization.id}/logs/jobs`,
		},
		{
			list_name: "REQUEST_ITEM",
			url: `/${organization.id}/logs/request_items`,
		},
		{
			list_name: "ORGANIZATION_USERS",
			url: `/${organization.id}/settings/users`,
		},
		{
			list_name: "WEBHOOK",
			url: `/${organization.id}/logs/webhooks`,
		},
		{
			list_name: "APP_LOGS",
			url: ``,
		},
		{
			list_name: "APP_LINK",
			url: `/${organization.id}/apps/linking`,
		},
		{
			list_name: "CLIENT_GROUP",
			url: `/${organization.id}/clients/client-groups`,
		},
		{
			list_name: "CLIENT",
			url: `/${organization.id}/clients`,
		},
		{
			list_name: "EMPLOYEE",
			url: `/${organization.id}/employees`,
		},
		{
			list_name: "ORDER",
			url: `/${organization.id}/sales/orders`,
		},
		{
			list_name: "POS_REPORT_PRINT_SETTINGS",
			url: "",
		},
		{
			list_name: "WORK_TIME",
			url: `/${organization.id}/employees/worktimes`,
		},
		{
			list_name: "ONLINE_EMPLOYEE",
			url: `/${organization.id}/employees/online`,
		},
		{
			list_name: "GROUP_PRICE_LISTS",
			url: `/${organization.id}/menu/price_lists/group_editing`,
		},
		{
			list_name: "WORKPLACE",
			url: `/${organization.id}/employees/workplaces`,
		},
		{
			list_name: "WORK_TIME_REPORT",
			url: `/${organization.id}/employees/worktimes_report`,
		},
		{
			list_name: "INVOICE",
			url: `/${organization.id}/invoices`,
		},
		{
			list_name: "PROFORMA",
			url: `/${organization.id}/invoices/proforma`,
		},
		{
			list_name: "EMPLOYEE_ACTIVITY",
			url: `/${organization.id}/logs/employee_activities`,
		},
		{
			list_name: "MESSAGE_EVENT",
			url: `/${organization.id}/logs/message_events`,
		},
		{
			list_name: "CATEGORY",
			url: `/${organization.id}/menu/categories`,
		},
		{
			list_name: "DISCOUNT",
			url: `/${organization.id}/menu/discounts`,
		},
		{
			list_name: "ITEM_GROUP",
			url: `/${organization.id}/menu/item_groups`,
		},
		{
			list_name: "MODIFIER_GROUP",
			url: `/${organization.id}/menu/modifier_groups`,
		},
		{
			list_name: "MENU_PRICE_LIST",
			url: `/${organization.id}/menu/price_lists`,
		},
		{
			list_name: "CATEGORY_REPORT",
			url: `/${organization.id}/reports/categories`,
		},
		{
			list_name: "EFFICIENCY_REPORT",
			url: `/${organization.id}/reports/efficiency_report`,
		},
		{
			list_name: "PAYMENT_METHOD_REPORT",
			url: `/${organization.id}/reports/payments`,
		},
		{
			list_name: "STORNO_REPORT",
			url: `/${organization.id}/reports/storn`,
		},
		{
			list_name: "PRODUCT_REPORT",
			url: `/${organization.id}/reports/products`,
		},
		{
			list_name: "RESERVATION",
			url: `/${organization.id}/reservations`,
		},
		{
			list_name: "POS_REPORT",
			url: `/${organization.id}/sales/pos_reports`,
		},
		{
			list_name: "TRANSACTION",
			url: `/${organization.id}/sales/transactions`,
		},
		{
			list_name: "AVAILABILITY",
			url: `/${organization.id}/settings/availabilities`,
		},
		{
			list_name: "CURRENCY",
			url: `/${organization.id}/settings/currencies`,
		},
		{
			list_name: "CUSTOM_FIELD",
			url: `/${organization.id}/settings/custom_fields`,
		},
		{
			list_name: "DEFAULT_COMMENT",
			url: `/${organization.id}/settings/default_comments`,
		},
		{
			list_name: "DEVICE",
			url: `/${organization.id}/settings/devices`,
		},
		{
			list_name: "PAYMENT_METHOD",
			url: `/${organization.id}/settings/payment_methods`,
		},
		{
			list_name: "PRINTOUT_TEMPLATE",
			url: `/${organization.id}/settings/printout_templates`,
		},
		{
			list_name: "DIRECTION",
			url: `/${organization.id}/settings/rooms/directions`,
		},
		{
			list_name: "POINT_OF_SALE",
			url: `/${organization.id}/settings/rooms/point-of-sales`,
		},
		{
			list_name: "TAG",
			url: `/${organization.id}/settings/tags`,
		},
		{
			list_name: "TAX",
			url: `/${organization.id}/settings/taxes`,
		},
		{
			list_name: "TERMINAL",
			url: `/${organization.id}/settings/terminals`,
		},
		{
			list_name: "ORDER_REPORT_GROUP",
			url: `/group_reports/orders`,
		},
		{
			list_name: "CATEGORY_REPORT_GROUP",
			url: `/group_reports/categories`,
		},
		{
			list_name: "PAYMENT_METHOD_REPORT_GROUP",
			url: `/group_reports/payments`,
		},
		{
			list_name: "PRODUCT_REPORT_GROUP",
			url: `/group_reports/products`,
		},
	];
	const taxIdNo = organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined;
	const companyName = organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined;
	const pdfOrientation = organization?.more?.pdf_orientation;
	const pdfFontSize = organization?.more?.default_pdf_font_size?.toString();

	useEffect(() => {
		handleChangeTabTitle(t("modules.segment.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<div className={"content"}>
			{!isMobile && <Header title={t("modules.segment.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<Segment.SegmentList
					resourceFilterTypes={resourceFilterTypes}
					resource={resource}
					listName={listName}
					organizationName={organization.name || ""}
					segmentListService={segmentContext}
					resourceType={resourceType}
					hyperLinksConfig={hyperLinksConfig}
					companyName={companyName}
					taxIdNo={taxIdNo}
					pdfOrientation={pdfOrientation}
					pdfFontSize={pdfFontSize}
				/>
			</Suspense>
		</div>
	);
};
export default OrganizationSettingsSegmentsPage;
