import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	DiscountApi,
	DiscountConditionApi,
	DiscountEntityApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { mapRoles } from "../../../../../../components/ResourcePermissionTable";
import { discountInclude } from "../../pages/Edit";
import DiscountFormAdvanced from "./DiscountFormAdvanced";
import DiscountFormBasic from "./DiscountFormBasic";
import DiscountFormRules from "./DiscountFormRules";

interface Props {
	discount: DiscountApi;
}

export const getDiscountTypes = (t: TFunction): Record<string, any>[] => {
	const discountTypes = [
		{ label: t("enums.discounts.type.PERCENT"), value: "PERCENT" },
		{ label: t("enums.discounts.type.AMOUNT"), value: "AMOUNT" },
		{ label: t("enums.discounts.type.TIP"), value: "TIP" },
	];

	return discountTypes;
};

export const getEntityDiscountTypes = (t: TFunction): Record<string, any>[] => {
	const discountTypes = [
		{ label: t("enums.discounts.type.PERCENT"), value: "PERCENT" },
		{ label: t("enums.discounts.type.AMOUNT"), value: "AMOUNT" },
		{ label: t("enums.discounts.type.FIXED_PRICE"), value: "FIXED_PRICE" },
	];

	return discountTypes;
};

const DiscountForm = (props: Props): JSX.Element => {
	const form = useForm<DiscountApi>({
		criteriaMode: "all",
		defaultValues: props.discount,
	});
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const { handleSubmit, clearErrors, control, formState, reset, setError, watch } = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const { fields, remove, append } = useFieldArray({
		control,
		name: "entities",
		keyName: "key",
	});
	const [loading, setLoading] = useState(false);
	const [isDiscountTableVisible, setIsDiscountTableVisible] = useState(false);
	const [isAvailabilityVisible, setIsAvailabilityVisible] = useState(false);
	const [areConditionsVisible, setAreConditionsVisible] = useState((props.discount.conditions || []).length > 0);
	const { addFlash, addSuccessFlash } = useFlash();
	const discountType = watch("discount_type");

	const appendItem = (item: DiscountEntityApi) => {
		append({ ...item, volume: null } as Record<string, any>);
	};

	const getParsedDataForm = (data: any) => {
		data.price_sort_type = data.price_sort_type === true || data.price_sort_type === "DESC" ? "DESC" : "ASC";
		data.conditions = (data.conditions || []).filter(
			(condition: DiscountConditionApi) =>
				condition.type ||
				condition.value ||
				condition.value === 0 ||
				condition.operator ||
				condition.item_id ||
				condition.item_group_id ||
				condition.category_id
		);
		data.id = props.discount.id;
		data.roles = mapRoles(data);

		return data;
	};

	const onSubmit = handleSubmit(async (data: any) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		const newData = getParsedDataForm(data);
		try {
			if (data.id) {
				const params: Record<string, any> = {
					include: discountInclude,
				};
				const res = await api.organization().updateDiscount(newData, params);
				reset(res);

				!res.availability && setIsAvailabilityVisible(false);
				!res.entities.length && setIsDiscountTableVisible(false);
				!res.conditions.length && setAreConditionsVisible(false);

				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createDiscount(newData);
				history.push(`/${organization.id}/menu/discounts/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setLoading(false);
			clearErrors("tip_applied_after_discount");
		}
	});

	return (
		<FormDirty formState={formState} loading={loading} key="discount-form" noValidate onSubmit={onSubmit}>
			<DiscountFormBasic
				form={form}
				discount={props.discount}
				discountType={discountType}
				setErrors={setErrors}
			/>
			<DiscountFormRules
				discount={props.discount}
				form={form}
				remove={remove}
				discountType={discountType}
				fields={fields}
				isAvailabilityVisible={isAvailabilityVisible}
				setIsDiscountTableVisible={setIsDiscountTableVisible}
				appendItem={appendItem}
				isDiscountTableVisible={isDiscountTableVisible}
				setErrors={setErrors}
				setIsAvailabilityVisible={setIsAvailabilityVisible}
				setAreConditionsVisible={setAreConditionsVisible}
				areConditionsVisible={areConditionsVisible}
			/>
			<DiscountFormAdvanced form={form} discount={props.discount} />
		</FormDirty>
	);
};
export default DiscountForm;
