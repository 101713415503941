import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
	SpreadsheetPriceListFormProps,
} from "../../../../../../../../../../../../../../services/Api/Organization/types";
import VirtualTableHeaderActionsColumn from "../../../../../../../../components/VirtualTable/VirtualTableHeaderActionsColumn";
import { VirtualColumnAction, VirtualTableColumn } from "../../../../../../../../components/VirtualTable/types";
import {
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../../../components/VirtualTable/utils";
import AssignPricesModal from "../AssignPricesModal/AssignPricesModal";

interface Props {
	visiblePriceListsColumns: MenuPriceListApi[];
	visibleDefaultColumns: VirtualTableColumn[];
	form: UseFormReturn<SpreadsheetPriceListFormProps>;
	priceLists: MenuPriceListApi[];
	items: MenuPriceListFlatApi[];
	maxZIndex: number;
}

const SpreadsheetPriceListVirtualTableHeaderActionsRow = ({
	visiblePriceListsColumns,
	visibleDefaultColumns,
	form,
	items,
	maxZIndex,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const [showAssignPricesModal, setShowAssignPricesModal] = useState<boolean>(false);
	const [columnIdToFill, setColumnIdToFill] = useState<number | string | undefined>(undefined);
	let prevLeftPosition = 0;
	const getPriceListColumnActions = (columnId: number): VirtualColumnAction[] => [
		{
			onClick: () => {
				setShowAssignPricesModal(true);
				setColumnIdToFill(columnId);
			},
			title: t("modules.group_edit.actions.set_price.title"),
		},
	];

	return (
		<>
			<tr className="additional-header-row">
				{visibleDefaultColumns.map((column, index) => {
					const prevColumn = visibleDefaultColumns[index - 1];
					const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
					prevLeftPosition = leftPosition;
					const lastColumn = index === visibleDefaultColumns.length - 1;
					const isHide = isVirtualTableColumnHide(column.id, visibleDefaultColumns);

					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							id={column.id}
							leftPosition={leftPosition}
							isSticky
							lastColumn={lastColumn}
							zIndex={maxZIndex - index}
						/>
					);
				})}
				{visiblePriceListsColumns.map((column) => {
					const isHide = isVirtualTableColumnHide(column.id, visiblePriceListsColumns);
					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							actions={getPriceListColumnActions(column.id)}
							id={column.id.toString()}
						/>
					);
				})}
			</tr>
			{showAssignPricesModal && (
				<AssignPricesModal
					items={items}
					form={form}
					priceLists={visiblePriceListsColumns}
					isShown={showAssignPricesModal}
					onHide={() => setShowAssignPricesModal(false)}
					columnId={columnIdToFill}
				/>
			)}
		</>
	);
};

export default SpreadsheetPriceListVirtualTableHeaderActionsRow;
