import React from "react";
import { OrderDetailsData } from "../../../../../../services/Api/Public/types";
import { CostModifiersList } from "./components/CostModifiersList";
import { ItemsList } from "./components/ItemsList";
import { OrderNumber } from "./components/OrderNumber";
import { PaymentDetails } from "./components/PaymentDetails";
import { Summary } from "./components/Summary";

interface OrderDetailsSectionProps {
	details: OrderDetailsData;
}

export const OrderDetailsSection = ({
	details: { number, total_price, total_price_tax, tax_items, discount_price, items, closed_at, transactions },
}: OrderDetailsSectionProps): JSX.Element => {
	return (
		<>
			<section className="order-details" data-testid="e-order-order-details-section">
				<OrderNumber orderNumber={number} transactionDate={closed_at} />
				<ItemsList items={items} />
				<CostModifiersList
					totalPriceTax={total_price_tax}
					taxItems={tax_items}
					discountPrice={discount_price}
				/>
				<Summary totalPrice={total_price} />
			</section>
			<PaymentDetails transactions={transactions} />
		</>
	);
};
