import React, { useContext, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { ReactComponent as SortableHandleSvg } from "go-component/images/svg/sortable-handle.svg";
import EmptyList from "go-core/components/EmptyList";
import { ReactComponent as KebabMenuIconSVG } from "go-segment/images/kebab-menu-icon.svg";
import { SegmentContext } from "../../context";
import { SegmentType } from "../types";

interface Props {
	show: boolean;
	onHide: () => void;
	segments: SegmentType[];
	setEditableSegments: (segments: SegmentType[]) => void;
	changeSegment: (segment: SegmentType) => void;
	selectedSegment: SegmentType | null;
}

const SegmentManagementModal = ({
	segments,
	onHide,
	show,
	setEditableSegments,
	changeSegment,
	selectedSegment,
}: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const segmentContextValue = useContext(SegmentContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const isSortingRef = useRef<boolean>(false);
	const [internalSegments, setInternalSegments] = useState(segments);

	const handleSetDefaultSegment = async (segmentId: number) => {
		try {
			const res = await segmentContextValue.setAsDefault(segmentId);
			const newSegments = internalSegments.map((segment) => {
				segment.default = segment.slug === res?.data?.data?.slug;

				return segment;
			});
			setEditableSegments(newSegments);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleSetSegmentNotDefault = async (segmentId: number) => {
		try {
			const res = await segmentContextValue.setNotDefault(segmentId);
			const indexOfSegmentToUpdate = internalSegments.findIndex(
				(internalSegment) => internalSegment.slug === res?.data?.data?.slug
			);
			const newInternalSegments = [...internalSegments];
			if (indexOfSegmentToUpdate > -1) {
				newInternalSegments[indexOfSegmentToUpdate].default = false;
			}
			setEditableSegments(newInternalSegments);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleUpdateSegmentsPositions = (newSegments: SegmentType[]) => {
		const newSortedSegments = newSegments.map((segment, index) => ({
			...segment,
			position: index,
		}));
		setInternalSegments(newSortedSegments);
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const data = internalSegments.map((segment, index) => ({
				id: Number(segment.id),
				position: segment.position !== undefined ? segment.position : index,
			}));
			const res = await segmentContextValue.updateSegments({ data });
			setEditableSegments([...(res?.data?.data || [])].sort((a, b) => a.position - b.position));
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			onHide();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const handleRemoveSegment = async (segmentId: number, slug: string) => {
		try {
			await segmentContextValue.remove(segmentId);
			const newSegments = internalSegments.filter((internalSegment) => {
				return internalSegment.slug !== slug;
			});
			if (selectedSegment?.slug === slug) {
				changeSegment(newSegments[0] ?? null);
			}

			setEditableSegments(newSegments);
			setInternalSegments(newSegments);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<Modal show={show} onHide={onHide} className="segment-management-modal">
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:go_segment.action.manage_segments")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="table-responsive">
					{segments.length > 0 ? (
						<table className="table">
							<ReactSortable
								handle=".sortable-handler"
								tag="tbody"
								list={internalSegments}
								onUpdate={() => (isSortingRef.current = true)}
								setList={handleUpdateSegmentsPositions}
							>
								{internalSegments.map((segment) => {
									const segmentName = segment.default
										? `${segment.name} (${t("lib:go_segment.field.default.title")})`
										: segment.name;
									return (
										<tr key={segment.slug}>
											<td className="action align-middle">
												<SortableHandleSvg className="sortable-handler" />
											</td>
											<td className="align-middle">{segmentName}</td>
											<td className="text-end align-middle">
												<Dropdown as={ButtonGroup} id="segment-actions" drop="down">
													<Dropdown.Toggle variant="light">
														<KebabMenuIconSVG />
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() =>
																handleRemoveSegment(Number(segment.id), segment.slug)
															}
														>
															{t("lib:common.action.remove")}
														</Dropdown.Item>
														{!segment.default && (
															<Dropdown.Item
																onClick={() =>
																	handleSetDefaultSegment(Number(segment.id))
																}
															>
																{t("lib:go_segment.action.set_as_default")}
															</Dropdown.Item>
														)}
														{segment.default && (
															<Dropdown.Item
																onClick={() =>
																	handleSetSegmentNotDefault(Number(segment.id))
																}
															>
																{t("lib:go_segment.action.set_as_not_default")}
															</Dropdown.Item>
														)}
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									);
								})}
							</ReactSortable>
						</table>
					) : (
						<EmptyList />
					)}
				</div>
			</Modal.Body>
			{segments.length > 0 && (
				<Modal.Footer>
					<ButtonLoading loading={loading} onClick={handleSubmit} type="submit">
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default SegmentManagementModal;
