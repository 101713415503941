import React, { FC, useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { delay } from "go-core";
import { FormInput } from "go-form";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import EditAction from "../../../../../../../../../../../components/Common/CommonActions/EditAction/EditAction";
import RemoveAction from "../../../../../../../../../../../components/Common/CommonActions/RemoveAction/RemoveAction";
import { ReactComponent as SelectedMoreSvg } from "../../../../../../../../../../../images/svg/menu/dots-white.svg";
import { ReactComponent as MoreSvg } from "../../../../../../../../../../../images/svg/menu/dots.svg";
import { ReactComponent as TickSvg } from "../../../../../../../../../../../images/svg/menu/tick.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../../images/svg/sortable-handle.svg";
import { RoomApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { RoomFormProps } from "./RoomsPreview";

interface Props {
	room: RoomApi;
	defaultRoomIndex: number;
	index: number;
	form: UseFormReturn<RoomFormProps>;
	handleRemovePage: (index: number) => void;
	handleDirty: () => void;
}

const Room: FC<Props> = ({ handleDirty, defaultRoomIndex, index, form, handleRemovePage }) => {
	const [editMode, setEditMode] = useState(false);
	const {
		formState: { errors },
		register,
		watch,
		setValue,
		getValues,
		clearErrors,
	} = form;
	const inputPrefix = `rooms.${index}.name`;
	const { t } = useTranslation();
	const handleChangeName = (index: number, name?: string) => {
		setEditMode(false);
		handleDirty();
		if (name) {
			const fieldsLength = getValues().rooms.length;
			for (let i = 0; i < fieldsLength; ++i) {
				clearErrors(`rooms.${i}.name`);
			}
			setValue(`rooms.${index}.name`, name, { shouldDirty: true });
		}
	};

	const active = defaultRoomIndex === index;
	const roomName = watch().rooms[index]?.name;
	const changeName = async () => {
		setEditMode(true);
		await delay(100);
		document.getElementsByName(inputPrefix)[0]?.focus();
	};

	useEffect(() => {
		document.getElementsByName(inputPrefix)[0]?.focus();
	}, [editMode]);

	const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === "Enter") {
			handleChangeName(index);
			evt.preventDefault();
		}
	};

	return (
		<>
			<div className={"menu-page"}>
				<div className="sortable-handler">
					<SortableHandleSvg />
					<FormInput
						type="hidden"
						errors={errors}
						register={register}
						value={index}
						name={`rooms.${index}.position`}
					/>
				</div>
				{editMode ? (
					<Form.Group className="form-group" controlId={inputPrefix}>
						<FormInput
							errors={errors}
							defaultValue={roomName}
							onKeyPress={handleKeyPress}
							onBlur={(evt) => handleChangeName(index, evt.target.value)}
							name={inputPrefix}
							register={register}
							className={`menu-page-input form-control`}
							type="text"
						/>
						<FormErrorMessage errors={errors} name={inputPrefix} />
					</Form.Group>
				) : (
					<>
						<div style={{ display: "none" }}>
							<FormInput
								name={`rooms.${index}.name`}
								register={register}
								defaultValue={roomName}
								errors={errors}
								className={`menu-page-input form-control`}
								type="text"
							></FormInput>
						</div>
						<Button
							variant={active ? "primary" : "light"}
							className="add-menu-page"
							style={{ minHeight: "40px" }}
							onDoubleClick={() => setEditMode(true)}
						>
							{roomName}
						</Button>
					</>
				)}
				{editMode ? (
					<div className="page-name-edit">
						<TickSvg onClick={() => handleChangeName(index)} />
					</div>
				) : (
					<Dropdown as={ButtonGroup} className="page-context-menu">
						<Dropdown.Toggle as={active ? SelectedMoreSvg : MoreSvg} />
						<Dropdown.Menu>
							<Dropdown.Item onClick={changeName}>
								<EditAction name={t("common.action.change_name")} />
							</Dropdown.Item>
							<Dropdown.Item onClick={() => handleRemovePage(index)}>
								<RemoveAction />
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)}
			</div>
			<div className={"room-errors"}>{!editMode && <FormErrorMessage errors={errors} name={inputPrefix} />}</div>
		</>
	);
};
export default Room;
