import { FC } from "react";

interface Props {
	className?: string;
}

const Tag: FC<Props> = ({ children, className }) => {
	return (
		<div className={`${className ? `${className} ` : ""}barcode-label`}>
			<span>{children}</span>
		</div>
	);
};
export default Tag;
