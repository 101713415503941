import React from "react";
import { useTranslation } from "react-i18next";
import { OrderCompanyData } from "../../../../../../services/Api/Public/types";
import { getAddressString } from "../../utils";

interface CompanyInfoSectionProps {
	company?: OrderCompanyData;
}

export const CompanyInfoSection = ({ company }: CompanyInfoSectionProps): JSX.Element | null => {
	const { t } = useTranslation();

	if (!company) {
		return null;
	}

	const addressString = getAddressString(company.address);
	const companyName = company.public_name || company.name;

	return (
		<section data-testid="e-order-company-info-section">
			{company.logo_link?.default_link && (
				<img className="company-logo" src={company.logo_link.default_link} alt={`${companyName} logo`} />
			)}
			{companyName && <h1>{companyName}</h1>}
			{company.tax_id_no && (
				<h3>
					{t("modules.e_order.field.tax_id_number.title")}: {company.tax_id_no}
				</h3>
			)}
			{addressString && <h3>{addressString}</h3>}
		</section>
	);
};
