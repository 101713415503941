import { CancelTokenSource } from "axios";
import { EmptyListConfig } from "go-core/components/EmptyList";
import { SegmentType } from "go-segment/components/types";
import { ExportConfig } from "../../core/components/Actions/services/types";
import { ListConfigColumn } from "../../core/components/Column/services/types";
import {
	ExternalListSelectedFilter,
	ListConfigFilter,
	ListSelectedFilter,
} from "../../core/components/Filter/services/types";
import { GroupByConfig, ListConfigAction, ListConfigMultipleAction } from "../../core/components/Table/services/types";
import { ListConfigField } from "../../core/services/types";

export interface ListParamsType {
	search: string;
	filters?: ListSelectedFilter[];
	page?: string;
	size?: string;
	segments?: GoListSegmentType[];
	selectedSegment?: string;
	columns?: string[];
	allColumnsInOrder?: string[];
	stickyColumnsDividerPosition?: number;
	customFields?: ListConfigField[];
	sort?: string[];
	f?: string;
}

export interface ListConfig {
	columns?: ListConfigColumn[];
	filters?: ListConfigFilter[];
	hideFilters?: boolean;
	selectedColumns?: string[];
	selectedSorts?: string[];
	fetch?: (params: Record<string, any>, options?: CancelTokenSource) => Promise<any>;
	saveSegment?: (segment: GoListSegmentType) => Promise<any>;
	segments?: GoListSegmentType[];
	fields?: ListConfigField[];
	customFields?: ListConfigField[];
	externalSegments?: SegmentType[];
	selectedSegment?: string;
	actions?: ListConfigAction[];
	multipleActions?: ListConfigMultipleAction[];
	exportConfig?: ExportConfig;
	filterValues?: any;
	externalSelectedFilters?: ExternalListSelectedFilter[];
	groupBy?: GroupByConfig;
	nested_config?: Record<string, any>;
	getStatus?: (dataItem: any) => "ENABLED" | "DISABLED" | "WARNING";
	customPageSizes?: string[];
	fetchTotalItemsCountForSelectedFilters?: (
		params: Record<string, any>,
		options?: CancelTokenSource
	) => Promise<number | { data: number }>;
	numberOfStickyColumnsAtTheStart?: number;
	isNumberOfStickyColumnsDynamic?: boolean;
	shouldDisableSortingOfStickyColumns?: boolean;
	doesIdColumnRedirectToPreviewPage?: boolean;
}

export interface ListConfigEmptyList extends EmptyListConfig {
	resourceName?: string;
	addAction?: () => void;
}

export interface GoListSegmentType extends SegmentType {
	filters?: ListSelectedFilter[];
	columns?: string[];
	allColumnsInOrder?: string[];
	stickyColumnsDividerPosition?: number;
	sort?: string[];
}

export interface ExportAction {
	title: string;
	onClick: (state: ListParamsType) => void;
}

export enum FetchDefaultParameters {
	SIZE = "20",
	PAGE = "0",
}

export interface MultipleActionsParams {
	f?: string;
}
