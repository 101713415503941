import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { PdfOrientationType } from "go-core/types";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { ListConfigFilter } from "go-list/core/components/Filter/services/types";
import { selectSelectedFiltersFullNames } from "go-list/list/services/selectors";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	ItemTranslationItemNameApi,
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationListFormProps,
	TranslationApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import VirtualTableWrapper from "../../../../../components/VirtualTable/VirtualTableWrapper";
import {
	VirtualTableColumn,
	VirtualTableListSelectedSort,
	VirtualTableWrapperHandle,
} from "../../../../../components/VirtualTable/types";
import {
	generateTranslationsVirtualTableListCsv,
	generateTranslationsVirtualTableListPdf,
	getInitialItemGroupTranslationListDefaultColumns,
	getPossibleItemGroupTranslationListDefaultColumns,
} from "../utils";
import TranslationsVirtualTable from "./TranslationsVirtualTable";

interface Props {
	items: ItemTranslationItemNameFormApi[];
	locales: ItemTranslationItemNameTranslationFormApi[];
	handleUpdate: (res: ItemTranslationItemNameFormApi[]) => void;
}

const parseLocalesToColumns = (
	locales: ItemTranslationItemNameTranslationFormApi[],
	t: TFunction<"translation", undefined>
) => {
	const columns: VirtualTableColumn[] = [];
	locales.forEach((locale) => {
		const nameColumn = {
			id: `${locale.id}_name`,
			name: `${locale.name} (${t("lib:common.word.name")})`,
		};
		const descriptionColumn = {
			id: `${locale.id}_description`,
			name: `${locale.name} (${t("lib:common.word.description")})`,
		};
		columns.push(nameColumn);
		columns.push(descriptionColumn);
	});

	return columns;
};

const mapItemTranslations = (
	locales: ItemTranslationItemNameTranslationFormApi[],
	item: ItemTranslationItemNameApi
) => {
	const newTranslations: TranslationApi[] = [];
	if (locales.length > 0) {
		for (let i = 0; i < locales.length; i++) {
			const localeItem = item.translations.find((localeArrayItem) => localeArrayItem.locale === locales[i].id);
			if (localeItem)
				newTranslations.push({
					locale: localeItem.locale,
					description: localeItem?.description,
					name: localeItem?.name,
				});
			else
				newTranslations.push({
					locale: locales[i].id,
					description: "",
					name: "",
				});
		}
	}
	return newTranslations;
};

const TranslationsVirtualTableForm = ({ items, locales, handleUpdate }: Props) => {
	const { t } = useTranslation();
	const form = useForm<ItemTranslationListFormProps>({
		criteriaMode: "all",
		defaultValues: {
			items: items.map((item) => ({
				...item,
				translations: mapItemTranslations(locales, item),
			})),
		},
	});
	const localesColumns = parseLocalesToColumns(locales, t);
	const defaultColumns = getInitialItemGroupTranslationListDefaultColumns(t);
	const organization = useSelector(selectOrganization);
	const [sortings, setSortings] = useState<VirtualTableListSelectedSort[]>([]);
	const filters = [
		{
			id: "category",
			name: t("common.word.item_group_category"),
			type: "list",
			listOptions: Array.from(new Set(items.map((item) => item.category_name))),
			hasDefaultOptions: false,
		},
		{
			id: "item_full",
			name: t("common.word.item_full_name"),
			type: "list",
			listOptions: Array.from(new Set(items.map((item) => item.item_full_name))),
		},
		{
			id: "item",
			name: t("modules.item_translation.field.default_name.title"),
			type: "list",
			listOptions: Array.from(new Set(items.map((item) => item.name))),
		},
		{
			id: "item_group",
			name: t("common.word.item_group"),
			type: "list",
			listOptions: Array.from(
				new Set(items.map((item) => item.item_group_name).filter((itemGroup) => itemGroup))
			),
			hasDefaultOptions: false,
		},
	] as ListConfigFilter[];
	const tableWrapperRef = useRef<VirtualTableWrapperHandle>(null);
	const { handleSubmit, reset } = form;

	const filterByFilterIdHandler = (filterId: string, filterItems: ItemTranslationItemNameFormApi[]) => {
		const selectedFilters = tableWrapperRef?.current?.selectedFilters || [];

		const selectedFiltersIds = selectedFilters.map((selectedFilter) => selectedFilter.filterId);
		if (selectedFiltersIds.includes(filterId)) {
			const filterValues = selectedFilters
				.filter((f) => f.filterId === filterId)[0]
				?.value?.split(FILTER_VALUE_SEPARATOR);
			if (filterValues && filterValues.length > 0) {
				const itemKey = filterId === "item" ? "name" : `${filterId}_name`;
				if (filterValues[0] === "u")
					return filterItems.filter((item) => !item[itemKey as keyof ItemTranslationItemNameFormApi]);
				if (filterValues[0] === "a")
					return filterItems.filter((item) => item[itemKey as keyof ItemTranslationItemNameFormApi]);
				const values = filters
					.filter((filter) => filter.id === filterId)[0]
					?.listOptions?.map((option: string, index: number) => {
						if (filterValues.includes(index.toString())) {
							return option;
						}

						return undefined;
					})
					.filter((item: ItemTranslationItemNameFormApi) => item);
				return filterItems.filter((item) =>
					values.includes(item[itemKey as keyof ItemTranslationItemNameFormApi])
				);
			}
		}
		return filterItems;
	};

	const returnFilteredItems = () => {
		const selectedFilters = tableWrapperRef?.current?.selectedFilters || [];
		if (selectedFilters.length === 0) return items;

		let newItems = [...items];
		if (filterByFilterIdHandler("category", newItems)) newItems = filterByFilterIdHandler("category", newItems);
		if (filterByFilterIdHandler("item", newItems)) newItems = filterByFilterIdHandler("item", newItems);
		if (filterByFilterIdHandler("item_full", newItems)) newItems = filterByFilterIdHandler("item_full", newItems);
		if (filterByFilterIdHandler("item_group", newItems)) newItems = filterByFilterIdHandler("item_group", newItems);
		return newItems;
	};

	const returnSearchedItems = () => {
		const search = tableWrapperRef?.current?.search;
		if (!search) return returnFilteredItems();

		const newItems = returnFilteredItems().filter((item) => {
			if (
				item?.item_group_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.printing_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.item_full_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.description?.toLowerCase()?.includes(search.toLowerCase()) ||
				item?.category_name?.toLowerCase()?.includes(search.toLowerCase()) ||
				item.sku === search ||
				item.barcodes.find((barcode) => barcode === search)
			)
				return item;
			return false;
		});
		return newItems;
	};

	const getParsedDataItemTranslations = (
		dataItem: ItemTranslationItemNameFormApi,
		item: ItemTranslationItemNameFormApi
	) => {
		const parsedTranslations: TranslationApi[] = [];
		(dataItem?.translations || []).forEach((translation) => {
			const existingItemTranslationItem = item.translations.find(
				(itemTranslationItem) => itemTranslationItem?.locale === translation?.locale
			);

			if (existingItemTranslationItem) parsedTranslations.push(existingItemTranslationItem);
			else {
				parsedTranslations.push({
					locale: translation.locale,
					description: "",
					name: "",
				});
			}
		});
		return parsedTranslations;
	};

	const areTranslationsItemsDifferent = (
		itemTranslations: TranslationApi[],
		dataItemTranslations: TranslationApi[]
	) => {
		const itemTranslationStrings = [
			...itemTranslations.map((itemTranslation) => itemTranslation.name || ""),
			...itemTranslations.map((itemTranslation) => itemTranslation?.description || ""),
		];
		const dataItemTranslationStrings = [
			...dataItemTranslations.map((dataItemTranslation) => dataItemTranslation.name || ""),
			...dataItemTranslations.map((dataItemTranslation) => dataItemTranslation?.description || ""),
		];
		return JSON.stringify(itemTranslationStrings) !== JSON.stringify(dataItemTranslationStrings);
	};

	const getParsedDataItems = (data: ItemTranslationListFormProps) => {
		return data.items.filter((dataItem) => {
			const itemToCompare = items.find((item) => item.index === dataItem.index);
			if (!itemToCompare) return undefined;

			const itemToCompareTranslations = getParsedDataItemTranslations(dataItem, itemToCompare);
			if (
				itemToCompare.name !== dataItem.name ||
				(itemToCompare?.description || "") !== (dataItem?.description || "") ||
				(itemToCompare?.printing_name || "") !== (dataItem?.printing_name || "") ||
				areTranslationsItemsDifferent(itemToCompareTranslations, dataItem.translations)
			) {
				return dataItem;
			}
			return undefined;
		});
	};

	const getParsedResponseItem = (item: ItemTranslationItemNameApi, index: number) => {
		return {
			...item,
			item_full_name: item.name,
			name: item.name === item.item_group_name ? item.name : item.name.replace(item.item_group_name, "").trim(),
			index,
		};
	};

	const onSubmit = handleSubmit(async (data: ItemTranslationListFormProps) => {
		data.items = [...getParsedDataItems(data)];
		const params: Record<string, any> = { page: "0", size: "0" };
		await api.organization().updateItemGroupsTranslations(data, params);
		const res2 = await api.organization().getItemGroupsTranslations(params);

		handleUpdate(
			res2.items.map((item: ItemTranslationItemNameApi, index: number) => getParsedResponseItem(item, index))
		);

		reset({
			items: res2.items.map((item: ItemTranslationItemNameApi, index: number) => {
				return {
					...getParsedResponseItem(item, index),
					translations: mapItemTranslations(locales, item),
				};
			}),
		});
	});

	const handleGeneratePdf = (orientation?: PdfOrientationType, fontSize?: string) => {
		generateTranslationsVirtualTableListPdf(
			returnSearchedItems(),
			getVisibleLocalesColumns(),
			tableWrapperRef?.current?.visibleDefaultColumns || [],
			{
				filename: t("modules.item_translation.header.title"),
				title: t("modules.item_translation.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
				pdfOrientation: orientation,
				pdfFontSize: fontSize,
			},
			selectSelectedFiltersFullNames(tableWrapperRef?.current?.selectedFilters || [], filters),
			t
		);
	};

	const handleGenerateCsv = () => {
		generateTranslationsVirtualTableListCsv(
			tableWrapperRef?.current?.visibleDefaultColumns || [],
			{
				filename: t("modules.item_translation.header.title"),
				title: t("modules.item_translation.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			},
			returnSearchedItems(),
			getVisibleLocalesColumns()
		);
	};

	const getVisibleLocalesColumns = () => {
		const selectedColumns = tableWrapperRef?.current?.selectedColumns || [];
		return parseLocalesToColumns(locales, t)
			.map((column) => {
				const localeIdFromColumnId = column.id.split("_")[0];
				const localeForColumn = locales.find((locale) => localeIdFromColumnId === locale.id);
				const index = localeForColumn!.index;
				return {
					...column,
					index,
				};
			})
			.filter((locale) => selectedColumns?.includes(locale.id))
			.sort((a, b) => selectedColumns.indexOf(a.id) - selectedColumns.indexOf(b.id));
	};

	return (
		<VirtualTableWrapper
			defaultColumns={defaultColumns}
			additionalColumns={[{ id: "locales", columns: [...localesColumns] }]}
			allPossibleDefaultColumns={getPossibleItemGroupTranslationListDefaultColumns(t)}
			segmentType="ITEM_GROUP_TRANSLATIONS"
			filters={filters}
			items={returnSearchedItems()}
			form={form}
			emptyListDescription={t("modules.item_translation.field.empty_list_description.title")}
			ref={tableWrapperRef}
			initialItems={items}
			sortings={sortings}
			setSortings={setSortings}
			handleSubmit={onSubmit}
			handleGeneratePdf={handleGeneratePdf}
			handleGenerateCsv={handleGenerateCsv}
			exportConfig={{
				filename: t("modules.item_translation.header.title"),
				title: t("modules.item_translation.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
				pdfOrientation: organization?.more?.pdf_orientation,
				pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			}}
		>
			<AutoSizer>
				{({ height, width }) => (
					<TranslationsVirtualTable
						width={width}
						height={height}
						heightTr={38}
						items={returnSearchedItems()}
						form={form}
						visibleLocalesColumns={getVisibleLocalesColumns()}
						visibleDefaultColumns={tableWrapperRef?.current?.visibleDefaultColumns || []}
						sortings={sortings}
						setSortings={setSortings}
					/>
				)}
			</AutoSizer>
		</VirtualTableWrapper>
	);
};

export default TranslationsVirtualTableForm;
