import { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import { LoadingContainer } from "go-core/components/Loading";
import { ExternalListSelectedFilter, FilterType } from "go-list/core/components/Filter/services/types";
import ReportData from "go-report/reports";
import {
	getChartReport,
	getChartType,
	getLastMonthFilterBetween,
	getReport,
	getReportCustomFieldsResources,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import ReportCategoriesProductUtils from "../../../../../../../../utils/reports/ReportCategoriesProductUtils";
import { ReportSaleUtils } from "../../../../../../../../utils/reports/ReportSaleUtils";
import ReportShareUtils from "../../../../../../../../utils/reports/ReportShareUtils";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "CATEGORY_REPORT";
const resourceType = "CATEGORY_REPORT";

const Report: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [filtersParams, setFilterParams] = useState("");
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();
	let config = {
		reportConfigId: "categories",
		type: "order_item",
		chartTitle: t("modules.category_report.field.report.title"),
		aggregatePrefix: "sales",
		chartAggregate: "total_money",
		chartType: getChartType(),
		selectedColumns: ReportCategoriesProductUtils.getSelectedColumns("categories"),
		groups: [
			...ReportCategoriesProductUtils.getGroups(t),
			...ReportShareUtils.getGroups(t),
			...ReportSaleUtils.getGroups(t),
		],
		groupsInfo: { ...ReportShareUtils.getGroupsInfo(t), ...ReportSaleUtils.getGroupsInfo(t) },
		selectedGroups: ["PRODUCT_CATEGORY"],
		showPercent: true,
		columns: ReportCategoriesProductUtils.getColumns(t),
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date" as FilterType,
				value: getLastMonthFilterBetween().split("=")[1],
				condition: getLastMonthFilterBetween().split("=")[0],
				visible: true,
			} as ExternalListSelectedFilter,
		],
		filters: [...ReportCategoriesProductUtils.getFilters(t, filtersParams), ...ReportSaleUtils.getFilters(t)],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				columns: ReportCategoriesProductUtils.getSelectedColumns("categories"),
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			setFilterParams(params.f);
			return Promise.all([
				getReport(
					apiReport.getReportsCustom,
					params,
					organization.id,
					config.filters,
					config.type,
					sourceToken
				),
				getChartReport(
					apiReport.getReportsCustom,
					params,
					"PRODUCT_CATEGORY",
					organization.id,
					config.filters,
					config.type,
					sourceToken,
					5
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return getChartReport(
				apiReport.getReportsCustom,
				params,
				"PRODUCT_CATEGORY",
				organization.id,
				config.filters,
				config.type,
				sourceToken,
				5
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.category_report.field.export_config.title"),
			filename: t("modules.category_report.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		withCompareDateRange: true,
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
	} as ReportConfig;

	data.fields = getReportCustomFieldsResources(data.fields, ["ITEM", "ORDER"], t);

	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

const OrganizationReportsCategoriesPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "ITEM",
			name: t(`enums.custom_fields.resources.ITEM`),
		},
		{
			type: "ORDER",
			name: t(`enums.custom_fields.resources.ORDER`),
		},
	];

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};

export default OrganizationReportsCategoriesPage;
