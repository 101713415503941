import { useContext, useMemo } from "react";
import { Card } from "react-bootstrap";
import { Range } from "react-date-range";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MoneyApi } from "go-core/api/types";
import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import isPredefinedRange, {
	parsePredefinedRangeToDefaultString,
	parsePredefinedRangeToRange,
	parseRangeToDefaultString,
} from "go-list/utils/daterangeutils";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DashboardContext } from "../..";
import { ReactComponent as CashSVG } from "../../../../images/svg/dashboard/cash.svg";
import { ReactComponent as ChairSVG } from "../../../../images/svg/dashboard/chair.svg";
import { DashboardClientInfomationsConfig, DashboardClientInfomationsType } from "../../services/types";
import { parsePredefinedRangeToPreviousPeriodRange } from "../../utils/reportConfigUtils";
import DashboardClientsDetailsItem from "./DashboardClientsDetailsItem";

const getItemConfig = (
	clientInformationType: DashboardClientInfomationsType,
	value: number | MoneyApi,
	compareValue: number | MoneyApi,
	seeMoreLink: string,
	t: TFunction
): DashboardClientInfomationsConfig => {
	switch (clientInformationType) {
		case DashboardClientInfomationsType.AVERAGE_MONEY:
			return {
				title: t("modules.dashboard.field.average_money.title"),
				value,
				compareValue,
				icon: <CashSVG />,
				seeMoreLink,
			};
		case DashboardClientInfomationsType.AVERAGE_PER_GUEST_MONEY:
			return {
				title: t("modules.dashboard.field.average_per_guest_money.title"),
				value,
				compareValue,
				icon: <CashSVG />,
				seeMoreLink,
			};
		case DashboardClientInfomationsType.NUMBER_OF_GUESTS:
			return {
				title: t("modules.dashboard.field.number_of_guests.title"),
				value,
				compareValue,
				icon: <ChairSVG />,
				seeMoreLink,
			};
		default:
			return {} as DashboardClientInfomationsConfig;
	}
};

const getSeeMoreLink = (dateRange: string, organizationId?: number) => {
	let link = "";

	const dateIsPredefinedRange = isPredefinedRange(dateRange);

	if (organizationId) link += `${organizationId}/reports/guests?`;
	else link += "/group_reports/guests?";

	let dateParam = "&date_range|bt=";

	if (dateIsPredefinedRange) dateParam += parsePredefinedRangeToDefaultString(dateRange);
	else dateParam += dateRange;

	const dateCompareParam = `${FILTER_SEPARATOR}compare_date_range|bt=${parsePredefinedRangeToPreviousPeriodRange(
		dateRange,
		true
	)}`;

	link += `f=${btoa(
		unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${dateParam}${dateCompareParam}`))
	)}`;

	return link;
};

export const DashboardClientsDetailsInformations = () => {
	const { t } = useTranslation();

	const dashboardContextData = useContext(DashboardContext);
	const clientsDetailsDateRange = dashboardContextData.state.filtersConfig.selectedFilter.dateRange;
	const clientsDetailsData = dashboardContextData.state?.data?.clients_informations?.reports[0];

	const clientsDetailsCustomDateRange = clientsDetailsDateRange.includes(",") ? clientsDetailsDateRange : null;
	const organization = useSelector(selectOrganization);
	const seeMoreLink = getSeeMoreLink(clientsDetailsDateRange, organization?.id);

	const average_money = useMemo(
		() =>
			clientsDetailsData?.aggregate?.sales?.average_money || { amount: 0, currency: organization.currency || "" },
		[clientsDetailsData?.aggregate?.sales?.average_money?.amount, organization.currency]
	);
	const average_per_guest_money = useMemo(
		() =>
			clientsDetailsData?.aggregate?.sales?.extended?.average_per_guest_money || {
				amount: 0,
				currency: organization.currency || "",
			},
		[clientsDetailsData?.aggregate?.sales?.extended?.average_per_guest_money?.amount, organization.currency]
	);
	const number_of_guests = clientsDetailsData?.aggregate?.sales?.extended?.number_of_guests;

	const compare_average_money = useMemo(
		() =>
			clientsDetailsData?.compare_aggregate?.sales?.average_money || {
				amount: 0,
				currency: organization.currency || "",
			},
		[clientsDetailsData?.compare_aggregate?.sales?.average_money?.amount, organization.currency]
	);
	const compare_average_per_guest_money = useMemo(
		() =>
			clientsDetailsData?.compare_aggregate?.sales?.extended?.average_per_guest_money || {
				amount: 0,
				currency: organization.currency || "",
			},
		[clientsDetailsData?.compare_aggregate?.sales?.extended?.average_per_guest_money?.amount, organization.currency]
	);
	const compare_number_of_guests = clientsDetailsData?.compare_aggregate?.sales?.extended?.number_of_guests;

	const averageMoneyConfig = useMemo(
		() =>
			getItemConfig(
				DashboardClientInfomationsType.AVERAGE_MONEY,
				average_money,
				compare_average_money,
				seeMoreLink,
				t
			),
		[t, average_money, seeMoreLink, compare_average_money]
	);
	const averagePerGuestMoneyConfig = useMemo(
		() =>
			getItemConfig(
				DashboardClientInfomationsType.AVERAGE_PER_GUEST_MONEY,
				average_per_guest_money,
				compare_average_per_guest_money,
				seeMoreLink,
				t
			),
		[t, average_per_guest_money, seeMoreLink, compare_average_per_guest_money]
	);
	const numberOfGuests = useMemo(
		() =>
			getItemConfig(
				DashboardClientInfomationsType.NUMBER_OF_GUESTS,
				number_of_guests,
				compare_number_of_guests,
				seeMoreLink,
				t
			),
		[t, number_of_guests, seeMoreLink, compare_number_of_guests]
	);

	if (!clientsDetailsData) return null;

	const getBoxTitle = () => {
		if (!clientsDetailsCustomDateRange) return t(`modules.dashboard.field.${clientsDetailsDateRange}.title`);

		const dateRange =
			clientsDetailsCustomDateRange || parseRangeToDefaultString(parsePredefinedRangeToRange("today") as Range);
		const dateRangeArray = dateRange.split(",");

		const [startDate, endDate] = dateRangeArray;

		const formattedStartDate = new Intl.DateTimeFormat("pl-PL", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		}).format(formatStringToDate(startDate));
		const formattedEndDate = new Intl.DateTimeFormat("pl-PL", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		}).format(formatStringToDate(endDate));

		return `${formattedStartDate} - ${formattedEndDate}`;
	};

	return (
		<Card className="dashboard-live-card mb-2">
			<Card.Body>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<h5 className={"mb-0"}>{getBoxTitle()}</h5>
				</div>
				<div className="dashboard-clients-informations-wrapper">
					{number_of_guests !== undefined && <DashboardClientsDetailsItem config={numberOfGuests} />}
					{average_money && <DashboardClientsDetailsItem config={averageMoneyConfig} />}
					{average_per_guest_money && <DashboardClientsDetailsItem config={averagePerGuestMoneyConfig} />}
				</div>
			</Card.Body>
		</Card>
	);
};
