import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ExternalReportApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "EXTERNAL_REPORT";
const resourceType = "EXTERNAL_REPORT";

const List: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const [items, setItems] = useState<ExternalReportApi[]>([]);
	const organization = useSelector(selectOrganization);
	const segmentContext = React.useContext(SegmentContext);
	const location = useLocation();

	if (!resource) return null;
	const data = resource.read();

	const drawPosReportColumn = (item: ExternalReportApi) => {
		return (
			<div className="d-flex flex-column">
				{item.report_shift_work && (
					<Link to={`/${organization.id}/sales/pos_reports/${item.report_shift_work.id}`}>
						{t("enums.pos_reports.type.SHIFTWORK")}
					</Link>
				)}
				{item.report_drawer && (
					<Link to={`/${organization.id}/sales/pos_reports/${item.report_drawer.id}`}>
						{t("enums.pos_reports.type.DRAWER")}
					</Link>
				)}
			</div>
		);
	};

	let config = {
		fields: [
			{
				id: "opened_closed_at",
				name: t("modules.external_report.field.peroid.title"),
				render: (item: ExternalReportApi) => (
					<Link to={`${location.pathname}/${item.id}`}>{`#${item.id} | ${
						item.opened_at ? FormatDate(item.opened_at) : "-"
					} | ${item.closed_at ? FormatDate(item.closed_at) : "-"}`}</Link>
				),
				renderExport: (item: ExternalReportApi) =>
					`#${item.id} | ${item.opened_at ? FormatDate(item.opened_at) : "-"} | ${
						item.closed_at ? FormatDate(item.closed_at) : "-"
					}`,
			},
			{
				id: "device",
				name: t("modules.external_report.field.device.title"),
				render: (item: ExternalReportApi) => item.device?.name,
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) => {
						return api
							.organization()
							.getDevicesSearchSelect(search, params, { sourceToken: options?.token });
					},
				},
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "list",
				options: {
					FISCAL_PRINTER: t("enums.external_reports.type.FISCAL_PRINTER"),
					PAYMENT_TERMINAL: t("enums.external_reports.type.PAYMENT_TERMINAL"),
				},
				render: (item: ExternalReportApi) => `${t(`enums.external_reports.type.${item.type}`)}`,
			},
			{
				id: "created_at",
				name: t("modules.external_report.field.created_at.title"),
				type: "date",
				render: (item: ExternalReportApi) => `${FormatDate(item.created_at)}`,
			},
			{
				id: "total",
				name: t("common.word.amount"),
				type: "number",
				render: (item: ExternalReportApi) => `${FormatMoney(item.total)}`,
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "pos_reports",
				name: t("common.word.pos_report"),
				disableSorting: true,
				render: (item: ExternalReportApi) => drawPosReportColumn(item),
			},
		],
		selectedColumns: ["opened_closed_at", "device", "type", "created_at", "total", "pos_reports"],
		filters: [
			{
				id: "opened_at",
				name: t("modules.external_report.field.opened_at.title"),
				type: "date",
			},
			{
				id: "closed_at",
				name: t("modules.external_report.field.closed_at.title"),
				type: "date",
			},
		],
		segments: [
			{
				id: "all",
				slug: "all",
				name: t("common.word.all", { ns: "lib" }),
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: ExternalReportApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "report_drawer,report_shiftwork,infos,device";
			return api.organization().getExternalReports(params, { cancelToken: sourceToken?.token });
		},
		exportConfig: {
			title: t("modules.external_report.header.title"),
			filename: t("modules.external_report.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		numberOfStickyColumnsAtTheStart: 1,
		doesIdColumnRedirectToPreviewPage: true,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={
				<EmptyList
					title={t("modules.external_report.header.title")}
					description={t("modules.external_report.field.empty_list_description.title")}
				/>
			}
			onFetch={(fetchItems: ExternalReportApi[]) => setItems(fetchItems)}
		/>
	);
};

const OrganizationSalesPosReportsExternalReportsIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const hasReportsPermission = !!hasPermission("VENUE_REPORTS_SHOW");
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		handleChangeTabTitle(t("modules.external_report.header.title"));
		if (hasReportsPermission) {
			setResource(wrapPromise(segmentContext.get(listName, resourceType)));
		} else history.push(`/${organization.id}/sales/pos_reports`);
	}, []);

	return (
		<>
			{!isMobile && <Header title={t("modules.external_report.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
		</>
	);
};

export default OrganizationSalesPosReportsExternalReportsIndexPage;
