import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientGroupApi, ClientGroupDiscountApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	clientGroup: ClientGroupApi;
	setClientGroup?: (clientGroup: ClientGroupApi) => void;
}

const ClientGroupForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const history = useHistory();
	const form = useForm<ClientGroupApi>({
		criteriaMode: "all",
		defaultValues: props.clientGroup,
	});
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		formState,
		setError,
		control,
	} = form;
	const [loading, setLoading] = useState(false);

	const onSubmit = handleSubmit(async (data: ClientGroupApi) => {
		setLoading(true);
		data.id = props.clientGroup.id;
		const discounts: any[] = [];
		data?.discounts?.forEach((x: { id: number }) => {
			if (x.id) {
				discounts.push(x.id);
			} else {
				discounts.push(x);
			}
		});
		data.discounts = discounts;
		try {
			if (data.id) {
				await api.organization().updateClientGroup(data);
				setLoading(false);
				reset(data);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				if (props.setClientGroup) props.setClientGroup(data);
			} else {
				const res = await api.organization().createClientGroup(data);
				history.push(`/${organization.id}/clients/client-groups/${res.id}`);
				setLoading(false);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const searchDiscounts = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDiscountsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const getDiscountTags = (discounts?: ClientGroupDiscountApi[]) => {
		return discounts?.map((discount) => {
			return {
				label: discount.name,
				id: discount.id,
			};
		});
	};

	return (
		<FormDirty formState={formState} loading={loading} key="client-group-form" noValidate onSubmit={onSubmit}>
			<fieldset className="form-group no-gutters">
				<FormInput
					label={t("common.word.name", { ns: "lib" })}
					register={register}
					name="name"
					errors={errors}
				/>
				<FormMoneyInput
					label={t("modules.client_groups.field.daily_discount_limit.title")}
					control={control}
					currency={currency}
					name="daily_discount_limit.amount"
					errors={errors}
				/>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>
					{t("modules.client_groups.field.assigned_discounts.title")}
					<small className="text-muted">
						<br />
						{t("modules.client_groups.field.assigned_discounts_description.title")}
					</small>
				</h5>
				<FormSelectGroup
					label={t("common.word.discounts")}
					isMulti={true}
					name="discounts"
					errors={errors}
					defaultValue={getDiscountTags(props.clientGroup.discounts)}
					getOptionLabel={(option) => option.label}
					getOptionValue={(option) => option.id}
					loadOptions={searchDiscounts}
					control={control}
					data-testid="discounts"
				/>
			</fieldset>
		</FormDirty>
	);
};
export default ClientGroupForm;
