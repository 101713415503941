import React, { FC, useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useListenEvent } from "go-core/hooks";
import ListFilterItem from "./components/Item";
import { getAllUniqueFilters, getFilterName, getFilterObjectWithParsedId } from "./services";
import { FilterCustomAction, FilterCustomDateRange, ListConfigFilter, ListSelectedFilter } from "./services/types";

interface ListFilterProps {
	filters: ListConfigFilter[];
	selectedFilters?: ListSelectedFilter[];
	onChange: (selectedFilters: ListSelectedFilter[], didRemoveFilter?: boolean) => void;
	minDate?: Date | string;
	maxDate?: Date | string;
	dateRangePickerCustomActions?: FilterCustomAction[];
	dateRangePickerCustomRanges?: FilterCustomDateRange[];
}

const ListFilter: FC<ListFilterProps> = (props) => {
	const [openFilter, setOpenFilter] = useState<string | null>(null);
	const [searchFilter, setSearchFilter] = useState<string>("");
	const filterDropdownRef = useRef<HTMLDivElement>(null);
	const [filterDropdownTopOffset, setFilterDropdownTopOffset] = useState(0);
	const [selectedFilters, setSelectedFilters] = useState(props.selectedFilters ?? []);
	const { t } = useTranslation();

	useListenEvent({
		eventName: "resize",
		callback: () => setFilterDropdownTopOffset(filterDropdownRef.current?.getBoundingClientRect()?.bottom || 0),
		shouldRunCallbackOnAdd: true,
	});

	useEffect(() => {
		setSelectedFilters(props.selectedFilters ?? []);
	}, [props.selectedFilters]);

	const addFilter = ({ filter }: { filter: ListConfigFilter }) => {
		setSearchFilter("");
		const selectedFilter = {} as ListSelectedFilter;
		selectedFilter.filterId = filter.id;
		setOpenFilter(`${filter.id}_${selectedFilters.length}`);
		props.onChange([...selectedFilters, selectedFilter]);
	};

	const removeFilter = (filter: ListSelectedFilter) => {
		const newSelectedFilters = selectedFilters.filter((x) => x !== filter);
		props.onChange(newSelectedFilters, true);
	};

	const onChangeFilterValue = (
		oldSelectedFilter: ListSelectedFilter,
		newSelectedFilter: ListSelectedFilter | null
	) => {
		if (newSelectedFilter === null) {
			if (!oldSelectedFilter.value) {
				const updatedFilters = selectedFilters.filter((f) => {
					if (f.filterId === oldSelectedFilter.filterId) {
						return f.value !== oldSelectedFilter?.value;
					}
					return true;
				});

				props.onChange(updatedFilters);
			}
			return;
		}
		if (newSelectedFilter.condition !== "u" && newSelectedFilter.condition !== "a") {
			if (!newSelectedFilter.value || newSelectedFilter.value === "") {
				const updatedFilters = selectedFilters.filter((f) => {
					if (f.filterId === oldSelectedFilter.filterId) {
						return f.value !== oldSelectedFilter?.value;
					}
					return true;
				});

				props.onChange(updatedFilters);
				return;
			}
		}
		const selectedFilter = selectedFilters.filter((selectedFilter) => selectedFilter === oldSelectedFilter)[0];
		if (selectedFilter === undefined) {
			return;
		}
		if (newSelectedFilter.value === undefined) {
			alert("ERROR");
			return;
		}
		const newSelectedFilters = [...selectedFilters];
		newSelectedFilter = { ...selectedFilter, ...newSelectedFilter };
		newSelectedFilters[newSelectedFilters.indexOf(selectedFilter)] = newSelectedFilter;
		props.onChange(newSelectedFilters);
		setSearchFilter("");
	};

	const allUniqueParsedFilters = getAllUniqueFilters(props.filters).map(getFilterObjectWithParsedId);

	const filterOptions = allUniqueParsedFilters.filter((filter) => filter.visible !== false);

	return (
		<>
			<div className="filters-list">
				{selectedFilters.map((selectedFilter, index) => {
					const filter = allUniqueParsedFilters.filter((x) => x.id === selectedFilter.filterId)[0];
					if (filter === undefined) {
						return null;
					}
					const key = `${filter.id}_${index}`;
					return (
						<ListFilterItem
							id={key}
							key={key}
							onCloseFilter={() => setOpenFilter(null)}
							openFilter={openFilter}
							filter={{
								...filter,
								name: getFilterName(filter, allUniqueParsedFilters),
							}}
							maxDate={props.maxDate}
							minDate={props.minDate}
							selectedFilter={selectedFilter}
							onChange={onChangeFilterValue}
							removeFilter={removeFilter}
							dateRangePickerCustomActions={props.dateRangePickerCustomActions}
							dateRangePickerCustomRanges={props.dateRangePickerCustomRanges}
						/>
					);
				})}

				<Dropdown as={ButtonGroup}>
					<div onClick={() => setSearchFilter("")}>
						<Dropdown.Toggle variant="" className="add">
							{t("lib:common.action.add_filter")}
						</Dropdown.Toggle>
					</div>
					<div ref={filterDropdownRef}>
						<Dropdown.Menu
							style={{
								overflowY: "auto",
								maxHeight: `calc(100vh - ${filterDropdownTopOffset}px - 8px)`,
							}}
						>
							{filterOptions.length > 10 && (
								<Form.Control
									placeholder={t("lib:common.word.search_filter")}
									type="search"
									value={searchFilter}
									className="mb-2"
									onChange={(e) => setSearchFilter(e.target.value)}
								/>
							)}
							{filterOptions
								.filter((filter) =>
									getFilterName(filter, allUniqueParsedFilters)
										.toLowerCase()
										.includes(searchFilter.toLowerCase())
								)
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((filter) => {
									return (
										<Dropdown.Item
											key={filter.id}
											eventKey={filter.id}
											onClick={() => {
												setSearchFilter("");
												addFilter({ filter });
											}}
										>
											{getFilterName(filter, allUniqueParsedFilters)}
										</Dropdown.Item>
									);
								})}
						</Dropdown.Menu>
					</div>
				</Dropdown>
			</div>
		</>
	);
};

export default ListFilter;
