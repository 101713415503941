import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import InvoicesNavigation from "./components/InvoicesNavigation";
import OrganizationInvoicesIndexPage from "./pages/Index";
import { OrganizationInvoicesJPKPage } from "./pages/Jpk";
import OrganizationInvoicesProformaPage from "./pages/Proforma";

const OrganizationInvoicesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"page-container"}>
			<InvoicesNavigation />
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/jpk`} component={OrganizationInvoicesJPKPage} />
					<Route path={`${props.match.url}/proforma`} component={OrganizationInvoicesProformaPage} />
					<Route path={`${props.match.url}/`} component={OrganizationInvoicesIndexPage} />
					<Route component={Page404} />
				</Switch>
			</div>
		</div>
	);
};

export default OrganizationInvoicesPage;
