import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SegmentApi } from "go-segment/services/types";

interface Props {
	segments: SegmentApi[];
}

const ReportComboSegmentNavigation = (props: Props) => {
	return (
		<div className="content-navigation">
			<Nav>
				{props.segments.map((segment) => {
					return (
						<Nav.Item key={segment.id}>
							<Nav.Link exact as={NavLink} to={`/combo_reports/${segment.id}`}>
								{segment.name}
							</Nav.Link>
						</Nav.Item>
					);
				})}
			</Nav>
		</div>
	);
};

export default ReportComboSegmentNavigation;
