import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ClientPreview from "../../components/ClientPreview/ClientPreview";

interface MatchParams {
	clientId: string;
}

const OrganizationClientsIndexPreviewPage = (): JSX.Element => {
	const { addFlash } = useFlash();
	const [client, setClient] = useState<ClientApi | undefined>(undefined);
	const { clientId } = useParams<MatchParams>();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		fetchClient();
	}, []);

	const fetchClient = async () => {
		const params = {
			include: "client_group,orders,transactions,transactions.payment_method,transactions.context,invoices",
		};
		try {
			const res = await api.organization().getClient(Number(clientId), params);
			setClient(res);
			handleChangeTabTitle(
				`${res.name || res.tax_id_no || res.contact_phone_number || res.contact_email} | ${t(
					"modules.client.header.title"
				)}`
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!client) return <LoadingContainer />;

	return <ClientPreview client={client} />;
};

export default OrganizationClientsIndexPreviewPage;
