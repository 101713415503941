import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DeliveryZoneApi } from "../../../../../../../../../services/Api/Organization/types";
import { getDeliveryZoneColor } from "../utils";
import DeliveryZoneMapLegendItem from "./DeliveryZoneMapLegendItem";

interface Props {
	deliveryZones: DeliveryZoneApi[];
	deliveryZone?: DeliveryZoneApi;
}

const DeliveryZoneMapLegend = ({ deliveryZones, deliveryZone }: Props) => {
	const { t } = useTranslation();

	const renderItems = useMemo(() => {
		return deliveryZones
			.filter((item) => item.id !== deliveryZone?.id)
			.map((item, index) => (
				<DeliveryZoneMapLegendItem key={item.id} deliveryZone={item} color={getDeliveryZoneColor(index)} />
			));
	}, [deliveryZones, deliveryZone]);

	return (
		<div className="delivery-zone-form-map-legend">
			<h5>{t("modules.delivery_zone.field.legend.title")}</h5>
			{renderItems}
		</div>
	);
};

export default DeliveryZoneMapLegend;
