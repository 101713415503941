import React, { useEffect, useMemo, useState } from "react";
import { useWindowSize } from "go-core/components/useWindowSize";
import useDashboardContext from "../../services/useDashboardContext";
import DashboardMobileFilters from "./DashboardMobileFilters";
import DashboardMobileFiltersButton from "./DashboardMobileFiltersButton";
import FiltersList from "./FiltersList";

const DashboardFilters = () => {
	const isMobile = useWindowSize().isMobile;
	const [showMobileFilters, setShowMobileFilters] = useState(false);
	const { state } = useDashboardContext();
	const [selectedDateFilter, setSelectedDateFilter] = useState<string | null>(
		state.filtersConfig.selectedFilter.dateRange
	);
	const filterItemTypes = useMemo(
		() => ["today", "yesterday", "this_month", "last_3_months", "last_6_months", "last_12_months"],
		[]
	);
	const isPredefinedDate = filterItemTypes.includes(
		isMobile ? selectedDateFilter || "" : state?.filtersConfig.selectedFilter.dateRange
	);

	useEffect(() => {
		if (!isMobile && showMobileFilters) {
			setShowMobileFilters(false);
			setSelectedDateFilter(null);
		}
	}, [isMobile]);

	if (isMobile)
		return (
			<>
				<DashboardMobileFiltersButton
					setShowMobileFilters={setShowMobileFilters}
					filterItemTypes={filterItemTypes}
					setSelectedDateFilter={setSelectedDateFilter}
					selectedDateFilter={selectedDateFilter}
				/>
				{showMobileFilters && (
					<DashboardMobileFilters
						show={showMobileFilters}
						onHide={() => setShowMobileFilters(false)}
						isPredefinedDate={isPredefinedDate}
						dateFilterTypes={filterItemTypes}
						setSelectedDateFilter={setSelectedDateFilter}
						selectedDateFilter={selectedDateFilter}
					/>
				)}
			</>
		);

	return <FiltersList isPredefinedDate={isPredefinedDate} filters={filterItemTypes} />;
};

export default DashboardFilters;
