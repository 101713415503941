import React, { FC, useMemo } from "react";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { ReactComponent as ArrowRightSVG } from "../../../../images/svg/dashboard/arrow-right.svg";
import {
	drawAmountDiff,
	drawDiffArrow,
	drawPercentagesDiff,
	getPercentagesDiffTextStyle,
} from "../../utils/dashboardRenderUtils";

interface Props {
	title: string;
	currentValue: number | MoneyApi;
	comparedValue: number | MoneyApi;
	icon: JSX.Element;
	link?: string;
}

const DashboardLiveItem: FC<Props> = ({ title, currentValue, icon, comparedValue, link }) => {
	const newCurrentValue = (typeof currentValue === "number" ? currentValue : currentValue.amount) || 0;
	const newComparedValue = (typeof comparedValue === "number" ? comparedValue : comparedValue.amount) || 0;
	const { t } = useTranslation();

	const drawValue = () => {
		if (typeof currentValue === "number") return currentValue;
		return FormatMoney(currentValue);
	};

	const chartData = {
		labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
		datasets: [
			{
				label: "",
				data: [20, 30, 10, 35, 40, 0, 50, 40, 30, 20, 15, 10],
				backgroundColor: "rgb(53, 162, 235)",
				borderColor: "rgb(53, 162, 235)",
				pointRadius: 0,
			},
		],
	};

	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
					drawTicks: false,
					borderWidth: 0,
				},
			},
			y: {
				grid: {
					display: false,
					drawTicks: false,
					borderWidth: 0,
				},
				ticks: {
					display: false,
				},
			},
		},
	};

	const renderChart = useMemo(() => {
		return (
			<div className="dashboard-live-item-chart">
				<Line data={chartData} options={chartOptions} style={{ maxHeight: "24px" }} />
			</div>
		);
	}, []);

	const renderDiffValue = () => {
		if (typeof currentValue === "number") {
			return drawAmountDiff(newComparedValue, newCurrentValue);
		}
		return FormatMoney({
			amount: drawAmountDiff(newComparedValue, newCurrentValue),
			currency: currentValue.currency,
		});
	};

	return (
		<Card className="dashboard-live-item">
			<Card.Body>
				<div className="d-flex justify-content-between align-items-center">
					<span>{title}</span>
					{icon}
				</div>
				<div className="d-flex justify-content-between flex-wrap mt-3">
					<div className="dashboard-live-item-content">
						<h4 className="text-primary me-1 mb-0 d-inline-block">{drawValue()}</h4>
						<div className="d-inline-block">
							<div className="d-flex align-items-end">
								{drawDiffArrow(newComparedValue, newCurrentValue)}
								<span
									className={`${getPercentagesDiffTextStyle(
										newComparedValue,
										newCurrentValue
									)} font-weight-bold`}
								>
									{drawPercentagesDiff(newComparedValue, newCurrentValue)}%
								</span>
							</div>
						</div>
					</div>
					{renderChart}
				</div>
				<div className="d-flex justify-content-between flex-wrap">
					<small className="text-muted font-weight-bold">
						{renderDiffValue()}{" "}
						{newCurrentValue > newComparedValue
							? t("common.action.more", { ns: "lib" })
							: t("common.action.less", { ns: "lib" })}{" "}
						{t("modules.dashboard.field.one_hour_ago.title")}
					</small>
					{link && (
						<Link to={link} className="d-flex align-items-center font-weight-bold text-nowrap">
							{t("modules.dashboard.action.see_report.title")}
							<ArrowRightSVG className="ms-1 arrow-right-svg" />
						</Link>
					)}
				</div>
			</Card.Body>
		</Card>
	);
};

export default DashboardLiveItem;
