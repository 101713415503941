import { FC } from "react";
import ListFilter from "go-list/core/components/Filter";
import ReportChart from "./components/Chart";
import ReportColumns from "./components/Columns";
import ReportTable from "./components/ReportTable";

interface State {
	children?: any;
}

const Report: FC & {
	Filters: typeof ListFilter;
	Columns: typeof ReportColumns;
	ReportTable: typeof ReportTable;
	ReportChart: typeof ReportChart;
} = (props: State) => {
	return props.children;
};

Report.Filters = ListFilter;
Report.Columns = ReportColumns;
Report.ReportTable = ReportTable;
Report.ReportChart = ReportChart;
export default Report;
