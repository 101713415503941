import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Map } from "leaflet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import LiveOrdersList from "../../../../components/LiveOrdersList";
import { OrdersContext } from "../../../../services/context";
import { LiveOrdersFilters } from "../../../../services/types";
import LiveOrdersMap from "../../components/LiveOrdersMap";
import LiveOrdersMapLegend from "../../components/MapLegend/LiveOrdersMapLegend";
import OrganizationLiveOrdersMapOrderPreviewPage from "../OrderPreview";

const OrganizationLiveOrdersMapIndexPage: FC<RouteComponentProps> = (props) => {
	const [focusedOrder, setFocusedOrder] = useState<LiveOrderApi | undefined>(undefined);
	const [selectedOrder, setSelectedOrder] = useState<LiveOrderApi | undefined>(undefined);
	const [showMapLegend, setShowMapLegend] = useState<boolean>(false);
	const [showOrderList, setShowOrderList] = useState<boolean>(true);
	const organization = useSelector(selectOrganization);
	const isMobile = useWindowSize().isMobile;
	const { filters } = useContext(OrdersContext);
	const prevFilters = useRef<LiveOrdersFilters>({ ...filters });
	const prevFiltersAsString = JSON.stringify(prevFilters.current);
	const filtersAsString = JSON.stringify(filters);
	const history = useHistory();
	const location = useLocation();
	const mapOffsetDueToOpenedOrdersList = showOrderList || !!focusedOrder;
	const pageRegex = /(map\/orders\/)\d/;
	const isOrderSelected = pageRegex.test(location.pathname);
	const orderListRoutePath = `/${organization.id}/live_orders/map`;
	const orderRoutePath = `/${organization.id}/live_orders/map/orders/`;
	const [map, setMap] = useState<Map | undefined>();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		if (orderListRoutePath.endsWith("map")) {
			handleChangeTabTitle(
				`${t("modules.live_order.field.map.title")} | ${t("modules.live_order.header.title")}`
			);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (prevFiltersAsString !== filtersAsString) {
			setFocusedOrder(undefined);
			setSelectedOrder(undefined);
			history.push(orderListRoutePath);
			prevFilters.current = { ...filters };
		}
	}, [filtersAsString]);

	const drawContent = () => {
		if (isMobile) {
			return (
				<>
					<div className="h-100 w-100">
						<LiveOrdersMap
							focusedOrder={focusedOrder}
							selectedOrder={selectedOrder}
							setFocusedOrder={setFocusedOrder}
							setShowMapLegend={setShowMapLegend}
							showOrderList={mapOffsetDueToOpenedOrdersList}
							isOrderSelected={isOrderSelected}
							setMap={setMap}
							map={map}
						/>
					</div>
				</>
			);
		}
		return (
			<>
				<div
					className="w-100"
					style={{
						transition: "0.2s all",
						transform: `translateX(${mapOffsetDueToOpenedOrdersList ? "-196px" : "-26px"})`,
					}}
				>
					<LiveOrdersMap
						focusedOrder={focusedOrder}
						selectedOrder={selectedOrder}
						setFocusedOrder={setFocusedOrder}
						setShowMapLegend={setShowMapLegend}
						showOrderList={mapOffsetDueToOpenedOrdersList}
						isOrderSelected={isOrderSelected}
						setMap={setMap}
						map={map}
					/>
				</div>

				<LiveOrdersList
					setFocusedOrder={(order) => {
						if (!showOrderList) return;
						setFocusedOrder(order);
					}}
					isShown={mapOffsetDueToOpenedOrdersList}
					orderListRoutePath={orderListRoutePath}
					orderRoutePath={orderRoutePath}
					setIsShown={setShowOrderList}
					isOrderSelected={isOrderSelected}
				/>
			</>
		);
	};
	const setSelectedOrderInternal = (order: LiveOrderApi | undefined) => {
		setSelectedOrder(order);
		setFocusedOrder(order);
	};

	const isVisible = () => {
		if (!isMobile) return true;
		return !showMapLegend;
	};

	return (
		<div
			className={`live-orders-map-container ${mapOffsetDueToOpenedOrdersList && !isMobile ? "orders-shown" : ""}`}
		>
			{drawContent()}
			{showMapLegend && (
				<LiveOrdersMapLegend
					setShowMapLegend={setShowMapLegend}
					showOrderList={mapOffsetDueToOpenedOrdersList}
					isOrderSelected={isOrderSelected}
				/>
			)}
			<Switch>
				<Route
					path={`${props.match.url}/orders/:orderId`}
					render={() => (
						<OrganizationLiveOrdersMapOrderPreviewPage
							isVisible={isVisible()}
							orderListRoutePath={orderListRoutePath}
							setFocusedOrder={setSelectedOrderInternal}
							map={map}
						/>
					)}
				/>
			</Switch>
		</div>
	);
};

export default OrganizationLiveOrdersMapIndexPage;
