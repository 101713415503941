import { TFunction } from "react-i18next";
import { VirtualTableColumn } from "../../../../../../../components/VirtualTable/types";

export const getPossibleSpreadsheetPriceListDefaultColumns = (t: TFunction<"translation", undefined>) => [
	{
		id: "category_name",
		name: t("common.word.category"),
	},
	{
		id: "item_group_name",
		name: t("common.word.item_group"),
	},
	{
		id: "item_name",
		name: t("common.word.variant"),
	},
	{
		id: "item_full_name",
		name: t("common.word.item_full_name"),
	},
	{
		id: "modifier_group_name",
		name: t("modules.group_edit.field.group.title"),
	},
	{
		id: "sub_item_name",
		name: t("modules.group_edit.field.sub_item.title"),
	},
	{
		id: "price",
		name: t("modules.group_edit.field.default_price.title"),
	},
];

export const getInitialSpreadsheetPriceListDefaultColumns = (t: TFunction): VirtualTableColumn[] => [
	{
		id: "category_name",
		name: t("common.word.category"),
	},
	{
		id: "item_group_name",
		name: t("common.word.item_group"),
	},
	{
		id: "item_name",
		name: t("common.word.variant"),
	},
	{
		id: "modifier_group_name",
		name: t("modules.group_edit.field.group.title"),
	},
	{
		id: "sub_item_name",
		name: t("modules.group_edit.field.sub_item.title"),
	},
	{
		id: "price",
		name: t("modules.group_edit.field.default_price.title"),
	},
];
