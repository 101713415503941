import React, { FC, Suspense, useContext, useEffect, useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { CategoryApi, ItemApi, ItemGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import { AssignAvailabilityModal } from "../../components/Modals/AssignAvailabilityModal";
import AssignListCustomFieldsModal from "../../components/Modals/AssignListCustomFieldsModal";
import AssignListModifierGroupsModal from "../../components/Modals/AssignListModifierGroupsModal";
import AssignMultipleProductsToCategoryModal from "../../components/Modals/AssignMultipleProductsToCategoryModal";
import { AssignOrderTypesModal } from "../../components/Modals/AssignOrderTypesModal";
import ChangeProductTypeModal from "../../components/Modals/ChangeProductTypeModal";
import ChangeProductsTaxModal from "../../components/Modals/ChangeProductsTaxModal";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "ITEM_GROUP";
const resourceType = "ITEM_GROUP";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const [showModifiersModal, setShowModifiersModal] = useState<ItemGroupApi[]>([]);
	const [showCustomFieldsModal, setShowCustomFieldsModal] = useState<number[]>([]);
	const [showProductTypeModal, setShowProductTypeModal] = useState<number[]>([]);
	const [showAssignToCategoryModal, setShowAssignToCategoryModal] = useState<number[]>([]);
	const [productIdsToChangeTax, setProductIdsToChangeTax] = useState<number[]>([]);
	const [showAssignAvailabilitiesModal, setShowAssignAvailabilitiesModal] = useState<number[]>([]);
	const [showAssignOrderTypesModal, setShowAssignOrderTypesModal] = useState<number[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const [items, setItems] = useState<ItemGroupApi[]>([]);
	const allItemsSelectedRef = useRef(false);
	const multipleActionParamsRef = useRef<MultipleActionsParams | undefined>();
	const segmentContext = useContext(SegmentContext);
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const { addSuccessFlash, addFlash } = useFlash();

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (refreshParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(refreshParams);
			setItems(res);
		}
	};

	const onRestoreItemGroup = async (item: ItemGroupApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreItemGroup(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemoveItemGroup = async (item: ItemGroupApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeItemGroup(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDisableItemGroup = async (item: ItemGroupApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			await api.organization().disableItemGroup(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const handleAssignToCategory = () => {
		setShowAssignToCategoryModal([]);
		onRefresh(params);
	};

	const handleSaveModifierGroups = () => {
		setShowModifiersModal([]);
	};

	const handleSaveCustomFields = () => {
		setShowCustomFieldsModal([]);
	};

	const getCostCaption = (item: ItemGroupApi, productItem: ItemApi) => {
		const itemName = item.items.length === 1 ? productItem.name : productItem.name?.replace(item.name, "");
		const defaultCost = productItem.stock_info?.default_cost;
		const externalCost = productItem.stock_info?.external_cost;
		const noneCostReceived = defaultCost === undefined && externalCost === undefined;
		if (!itemName || noneCostReceived) return null;

		const renderTwoCosts = defaultCost !== undefined && externalCost !== undefined;
		const caption = `${itemName} (${defaultCost ? FormatMoney(defaultCost) : ""}${renderTwoCosts ? " / " : ""}${
			externalCost ? FormatMoney(externalCost) : ""
		})`;
		return caption;
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: ItemGroupApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<ListImageName
								data={{
									...item,
									name: `${item.name}${item.tax ? ` (${item.tax.name})` : ""}`,
									limitTextWidth: 200,
								}}
								imageLink={item.image_link}
								link={`${location.pathname}/${item.id}`}
							/>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: ItemGroupApi) => item?.name,
			},
			{
				id: "category",
				name: t("common.word.category"),
				type: "search_select" as FilterType,
				render: (item: ItemGroupApi) => {
					return item.category?.name;
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getCategoriesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "item",
				disableSorting: true,
				name: t("modules.item_group.field.product_items.title"),
				render: (item: ItemGroupApi) => {
					return (
						<div className={"d-flex flex-column"}>
							{item.items.map((x) => {
								if (x.name === item.name)
									return (
										<span style={{ whiteSpace: "nowrap" }} key={x.id}>
											{`${t("common.word.default")} (${FormatMoney(x.price)})`}
											{x.sku && (
												<small className="ms-1">
													{t("modules.item_group.field.sku.title")}: {x.sku}
												</small>
											)}
										</span>
									);
								return (
									<span key={x.id} style={{ whiteSpace: "nowrap" }}>
										{`${x.name.replace(item.name, "")} (${FormatMoney(x.price)})`}
										{x.sku && (
											<small className="ms-1">
												{t("modules.item_group.field.sku.title")}: {x.sku}
											</small>
										)}
									</span>
								);
							})}
						</div>
					);
				},
				renderExport: (item: ItemGroupApi) => {
					return item.items
						.map((x) => {
							if (x.name === item.name) return `${t("common.word.default")} (${FormatMoney(x.price)})`;
							return `${x.name.replace(item.name, "")} (${FormatMoney(x.price)})`;
						})
						.join(", ");
				},
			},
			{
				id: "stock_info",
				name: t("modules.item_group.field.stock_amount.title"),
				render: (item: ItemGroupApi) => {
					return item.items.map((stockItem) => {
						const stockAmount = stockItem.stock_info?.stock_amount
							? stockItem.stock_info.stock_amount
							: "-";

						return (
							<>
								{stockAmount}
								<br />
							</>
						);
					});
				},
				renderExport: (item: ItemGroupApi) => {
					return item.items
						.map((stockItem) =>
							stockItem.stock_info?.stock_amount ? stockItem.stock_info.stock_amount : "-"
						)
						.join(", ");
				},
				disableSorting: true,
			},
			{
				id: "barcodes",
				name: t("modules.item_group.field.barcodes.title"),
				disableSorting: true,
				render: (item: ItemGroupApi) => {
					return (
						<div className="d-flex flex-column">
							{item.items.map((x) => x?.barcodes.map((barcode) => <span key={barcode}>{barcode}</span>))}
						</div>
					);
				},
				renderExport: (item: ItemGroupApi) => {
					return item.items
						.map((x) => x?.barcodes)
						.flat()
						.join(", ");
				},
			},
			{
				id: "point_of_sale",
				name: t("common.word.points_of_sale"),
				disableSorting: true,
				render: (item: ItemGroupApi) => {
					const pointsOfSales = Array.from(
						new Set(
							item.items
								.map((item) =>
									item.points_of_sale
										.filter((pointOfSale) => pointOfSale.visibility !== "HIDDEN")
										.map((pointOfSale) => pointOfSale.point_of_sale?.name)
								)
								.flat()
								.filter((point) => point)
						)
					).join(", ");

					return <RenderLimitedText minWidth={200}>{pointsOfSales}</RenderLimitedText>;
				},
				renderExport: (item: ItemGroupApi) => {
					return Array.from(
						new Set(
							item.items
								.map((item) =>
									item.points_of_sale
										.filter((pointOfSale) => pointOfSale.visibility !== "HIDDEN")
										.map((pointOfSale) => pointOfSale.point_of_sale?.name)
								)
								.flat()
								.filter((point) => point)
						)
					).join(", ");
				},
			},
			{
				id: "direction",
				name: t("common.word.direction"),
				disableSorting: true,
				type: "search_select",
				render: (item: ItemGroupApi) => {
					let directions = [];
					directions = Array.from(
						new Set(item.items.map((item) => item.direction?.name).filter((point) => point))
					);
					if (directions.length > 0) {
						return directions.join(", ");
					}

					directions = Array.from(
						new Set(
							item.items
								.map((item) =>
									item.points_of_sale
										.filter((pointOfSale) => pointOfSale.visibility !== "HIDDEN")
										.map((pointOfSale) => pointOfSale.direction?.name)
								)
								.flat()
								.filter((direction) => direction)
						)
					);

					return <RenderLimitedText minWidth={200}>{directions.join(", ")}</RenderLimitedText>;
				},
				renderExport: (item: ItemGroupApi) => {
					let directions = [];
					directions = Array.from(
						new Set(item.items.map((item) => item.direction?.name).filter((point) => point))
					);

					if (directions.length > 0) {
						return directions.join(", ");
					}

					return Array.from(
						new Set(
							item.items
								.map((item) =>
									item.points_of_sale
										.filter((pointOfSale) => pointOfSale.visibility !== "HIDDEN")
										.map((pointOfSale) => pointOfSale.direction?.name)
								)
								.flat()
								.filter((direction) => direction)
						)
					).join(", ");
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getDirectionsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "cost",
				name: t("modules.item_group.field.stock_info_cost.title"),
				render: (item: ItemGroupApi) => {
					return item.items?.map((productItem) => {
						const caption = getCostCaption(item, productItem);

						return (
							<div key={productItem.id}>
								<span>{caption}</span>
								<br />
							</div>
						);
					});
				},
				renderExport: (item: ItemGroupApi) =>
					item.items?.map((productItem) => getCostCaption(item, productItem)),
				disableSorting: true,
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: ItemGroupApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: ItemGroupApi) => {
					onRemoveItemGroup(item);
				},
				visible: (item: ItemGroupApi) => {
					return item.status !== "DELETED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: ItemGroupApi) => {
					onRestoreItemGroup(item);
				},
				visible: (item: ItemGroupApi) => {
					return item.status === "DELETED" || item.status === "DISABLED";
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: ItemGroupApi) => {
					onDisableItemGroup(item);
				},
				visible: (item: ItemGroupApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.copy", { ns: "lib" }),
				click: (item: ItemGroupApi) => {
					history.push({
						pathname: `/${organization.id}/menu/item_groups/new`,
						state: {
							copiedItem: item.id,
						},
					});
				},
			},
		],
		multipleActions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: async (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.item_group.field.remove_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"tax,category,items,direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction,items.barcodes,custom_fields";

						await api
							.organization()
							.removeItemGroups(idsAsString, multipleActionsParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: ItemGroupApi[]) => {
					return multipleActionsItems.filter((f) => f.status !== "DELETED").length > 0;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: async (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.item_group.field.activate_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"tax,category,items,direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction,items.barcodes,custom_fields";

						await api
							.organization()
							.restoreItemGroups(idsAsString, multipleActionsParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: ItemGroupApi[]) => {
					return (
						multipleActionsItems.filter((f) => f.status === "DELETED" || f.status === "DISABLED").length > 0
					);
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: async (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.item_group.field.disable_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"tax,category,items,direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction,items.barcodes,custom_fields";

						await api
							.organization()
							.disableItemGroups(idsAsString, multipleActionsParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
						onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: ItemGroupApi[]) => {
					return multipleActionsItems.filter((f) => f.status === "ENABLED").length > 0;
				},
			},
			{
				name: t("modules.item_group.action.set_tax.title"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setProductIdsToChangeTax(multipleActionsItems.map((item) => item.id));
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
			{
				name: t("common.action.assign_category"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowAssignToCategoryModal(multipleActionsItems.map((item) => item.id));
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
			{
				name: t("modules.item_group.action.assign_modifier_groups.title"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowModifiersModal(multipleActionsItems);
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
			{
				name: t("modules.item_group.action.assign_custom_fields.title"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowCustomFieldsModal(multipleActionsItems.map((item) => item.id));
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
			{
				name: t("modules.item_group.action.set_product_type.title"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowProductTypeModal(multipleActionsItems.map((item) => item.id));
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
			{
				name: t("modules.item_group.action.assign_availability.title"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowAssignAvailabilitiesModal(multipleActionsItems.map((item) => item.id));
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
			{
				name: t("modules.item_group.action.assign_order_type.title"),
				click: (
					multipleActionsItems: ItemGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionsParams?: MultipleActionsParams
				) => {
					setShowAssignOrderTypesModal(multipleActionsItems.map((item) => item.id));
					allItemsSelectedRef.current = Boolean(areAllItemsSelected);
					multipleActionParamsRef.current = multipleActionsParams;
				},
			},
		],
		filters: [
			{
				id: "modifier_group",
				name: t("common.word.modifier_group"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getModifierGroupsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "modifier_group_option",
				name: t("modules.item_group.field.modifier_group_option.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "availability",
				name: t("common.word.availability"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getAvailabilitiesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "price",
				name: t("modules.item_group.field.price.title"),
				type: "number" as FilterType,
			},
			{
				id: "weighted_type",
				name: t("modules.item_group.field.weighted.title"),
				type: "list" as FilterType,
				listOptions: {
					DISABLED: t("enums.item_groups.weighted.DISABLED"),
					WEIGHTED: t("enums.item_groups.weighted.WEIGHTED"),
					WEIGHTED_AFTER: t("enums.item_groups.weighted.WEIGHTED_AFTER"),
				},
			},
			{
				id: "discountable",
				name: t("modules.item_group.field.discountable.title"),
				type: "boolean" as FilterType,
			},
			{
				id: "open_price",
				name: t("modules.item_group.field.open_price.title"),
				type: "boolean" as FilterType,
			},
			{
				id: "barcode",
				name: t("modules.item_group.field.barcode.title"),
				type: "text" as FilterType,
			},
			{
				id: "tax",
				name: t("modules.item_group.field.tax.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTaxesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				listOptions: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DISABLED: t("enums.common.status.DISABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
			{
				id: "item_count",
				name: t("modules.item_group.field.item_count.title"),
				type: "number" as FilterType,
				options: ["gt", "lt", "e", "bt"],
			},
			{
				id: "modifier_group_count",
				name: t("modules.item_group.field.modifier_group_count.title"),
				type: "number" as FilterType,
				options: ["gt", "lt", "e", "bt"],
			},
			{
				id: "app",
				name: t("modules.item_group.field.used_in_app.title"),
				type: "search_select" as FilterType,
				source: {
					request: (t1, obj, options?: Record<string, any>) =>
						api.organization().getAppsSearchSelect(t1, obj, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "type",
				name: t("common.word.type", { ns: "lib" }),
				type: "list",
				listOptions: {
					MODIFIER: t("enums.items.type.MODIFIER"),
					PRODUCT: t("enums.items.type.PRODUCT"),
					PACKAGE: t("enums.items.type.PACKAGE"),
				},
				hasDefaultOptions: false,
			},
			{
				id: "stock_amount",
				name: t("modules.item_group.field.stock_amount.title"),
				type: "number",
			},
			{
				id: "point_of_sale",
				name: t("common.word.point_of_sale"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPointsOfSaleSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "item",
				name: t("common.word.item_group_variant"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "order_type",
				name: t("modules.item_group.field.order_type.title"),
				type: "list",
				listOptions: {
					DELIVERY: t("enums.orders.types.DELIVERY"),
					DINE_IN: t("enums.orders.types.DINE_IN"),
					ROOM_SERVICE: t("enums.orders.types.ROOM_SERVICE"),
					PICK_UP: t("enums.orders.types.PICK_UP"),
				},
			},
			{
				id: "cost",
				name: t("common.word.cost"),
				type: "number",
			},
		],
		selectedColumns: ["name", "category", "item", "point_of_sale", "direction"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.item_group.field.export_config.title"),
			filename: t("modules.item_group.header.title"),
			organization: organization.name,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include =
				"tax,category,items,direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction,items.barcodes,custom_fields,items.stock_info";
			setParams(fetchParams);
			return api.organization().getItemGroups(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
		fetchTotalItemsCountForSelectedFilters: (
			fetchTotalItemsCount: Record<string, any>,
			sourceToken?: CancelTokenSource
		) => api.organization().getItemGroupsCount(fetchTotalItemsCount, { cancelToken: sourceToken?.token }),
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{showAssignToCategoryModal.length > 0 && (
				<AssignMultipleProductsToCategoryModal
					handleSave={handleAssignToCategory}
					onClose={() => setShowAssignToCategoryModal([])}
					items={showAssignToCategoryModal}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			{showModifiersModal.length > 0 && (
				<AssignListModifierGroupsModal
					handleSave={handleSaveModifierGroups}
					items={showModifiersModal}
					onClose={() => setShowModifiersModal([])}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			{showCustomFieldsModal.length > 0 && (
				<AssignListCustomFieldsModal
					handleSave={handleSaveCustomFields}
					items={showCustomFieldsModal}
					onClose={() => setShowCustomFieldsModal([])}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			{showProductTypeModal.length > 0 && (
				<ChangeProductTypeModal
					onClose={() => setShowProductTypeModal([])}
					items={showProductTypeModal}
					handleSave={() => setShowProductTypeModal([])}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			{productIdsToChangeTax.length > 0 && (
				<ChangeProductsTaxModal
					show={productIdsToChangeTax.length > 0}
					onHide={() => setProductIdsToChangeTax([])}
					productIds={productIdsToChangeTax}
					handleSave={() => onRefresh(params)}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			{showAssignAvailabilitiesModal.length > 0 && (
				<AssignAvailabilityModal
					show={showAssignAvailabilitiesModal.length > 0}
					onHide={() => setShowAssignAvailabilitiesModal([])}
					productIds={showAssignAvailabilitiesModal}
					handleSave={() => onRefresh(params)}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			{showAssignOrderTypesModal.length > 0 && (
				<AssignOrderTypesModal
					show={showAssignOrderTypesModal.length > 0}
					onHide={() => setShowAssignOrderTypesModal([])}
					productIds={showAssignOrderTypesModal}
					handleSave={() => onRefresh(params)}
					allItemsSelected={allItemsSelectedRef.current}
					multipleActionParams={multipleActionParamsRef.current}
				/>
			)}
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.item_group.header.title")}
						description={t("modules.item_group.field.empty_list_description.title")}
						actions={[
							{
								name: `+ ${t("modules.item_group.action.add_product.title")}`,
								click: () => {
									history.push(`${location.pathname}/new`);
								},
							},
						]}
					/>
				}
				onFetch={(fetchItems: ItemGroupApi[]) => setItems(fetchItems)}
				canManageMultiActionsForAllItems
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationMenuItemGroupsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const organization = useSelector(selectOrganization);
	const segmentContext = React.useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "ITEM",
			name: t(`enums.custom_fields.resources.ITEM`),
		},
	];
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		handleChangeTabTitle(t("modules.item_group.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
			path: `${props.match.url}/new`,
		},
		{
			title: t("common.action.import_from_csv", { ns: "lib" }),
			path: `${props.match.url}/import`,
			dropdown: true,
		},
		{
			title: t("common.action.edit_price_lists"),
			path: `/${organization.id}/menu/price_lists/group_editing`,
			dropdown: true,
		},
		{
			title: t("modules.item_group.action.edit_translations.title"),
			path: `${props.match.url}/translations`,
			dropdown: true,
		},
		{
			title: t("modules.item_group.action.group_editing.title"),
			path: `${props.match.url}/group_editing`,
			dropdown: true,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
		{
			title: t("common.action.import_from_csv", { ns: "lib" }),
			path: `${props.match.url}/import`,
		},
		{
			title: t("common.action.edit_price_lists"),
			path: `/${organization.id}/menu/price_lists/group_editing`,
		},
		{
			title: t("modules.item_group.action.edit_translations.title"),
			path: `${props.match.url}/translations`,
		},
		{
			title: t("modules.item_group.action.group_editing.title"),
			path: `${props.match.url}/group_editing`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.item_group.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationMenuItemGroupsIndexPage;
