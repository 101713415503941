import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { Loading } from "go-core/components/Loading";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { InvoiceApi, InvoiceItemApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { invoicePreviewIncludes } from "../../pages/Index/Preview";

interface Props {
	isShown: boolean;
	onHide: () => void;
	invoice: InvoiceApi;
	handleUpdateInvoice?: (data: InvoiceApi) => void;
}

const GroupInvoiceModal: FC<Props> = ({ isShown, onHide, invoice, handleUpdateInvoice }) => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const [loadingUngroupedInvoice, setLoadingUngroupedInvoice] = useState(false);
	const [loadingGroupedInvoice, setLoadingGroupedInvoice] = useState(false);
	const form = useForm<any>({
		criteriaMode: "all",
		defaultValues: {
			group: t("modules.invoice.field.catering.title"),
		},
	});
	const {
		register,
		formState,
		getValues,
		control,
		watch,
		formState: { errors },
	} = form;
	const params: Record<string, any> = invoicePreviewIncludes;
	const watchedAction = watch("action");
	const [ungroupedInvoice, setUngroupedInvoice] = useState<InvoiceApi | undefined>();
	const [groupedInvoice, setGroupedInvoice] = useState<InvoiceApi | undefined>();

	useEffect(() => {
		if (watchedAction === "ungroup") {
			handleGetUngroupInvoice();
		}
		if (watchedAction === "group") {
			handleGetGroupInvoice();
		}
	}, [watchedAction]);

	const handleGetUngroupInvoice = async () => {
		setLoadingUngroupedInvoice(true);
		try {
			const params = { include: "items,items.tax" };
			const res = await api.organization().getUngroupedInvoice(invoice.id, params);
			setUngroupedInvoice(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		setLoadingUngroupedInvoice(false);
	};

	const handleGetGroupInvoice = async () => {
		setLoadingGroupedInvoice(true);
		try {
			const params = { include: "items,items.tax" };
			const res = await api.organization().getGroupedInvoice(invoice.id, getValues().group, params);
			setGroupedInvoice(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		setLoadingGroupedInvoice(false);
	};

	const handleGroupInvoice = async () => {
		setLoading(true);
		try {
			const res = await api.organization().groupInvoice(invoice.id, getValues().group, params);
			onHide();
			if (handleUpdateInvoice) {
				handleUpdateInvoice(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		setLoading(false);
	};

	const handleUngroupInvoice = async () => {
		try {
			setLoading(true);
			const res = await api.organization().ungroupInvoice(invoice.id, params);
			onHide();
			if (handleUpdateInvoice) {
				handleUpdateInvoice(res);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			handleError.alert(e, addFlash);
		}
	};

	const actionOptions = [
		{ label: t("modules.invoice.action.group.title"), value: "group" },
		{
			label: t("modules.invoice.action.ungroup.title"),
			value: "ungroup",
		},
	];

	const drawTable = (items: any) => {
		return (
			<div className={"table-responsive"}>
				<table className={"table table-preview"}>
					<thead>
						<tr>
							<th className={"w-40"}>{t("modules.invoice.field.name.title")}</th>
							<th style={{ width: "9%" }}>
								{invoice.type === "GROSS"
									? t("modules.invoice.field.unit_price_gross.title")
									: t("modules.invoice.field.unit_price_net.title")}
							</th>
							<th className={"w-1"}>{t("modules.invoice.field.tax_value.title")}</th>
							<th>{t("common.word.quantity")}</th>
							<th>{t("modules.invoice.field.measure.title")}</th>
							<th className={"w-1"} />
							<th className={"w-1"}>
								{invoice.type === "GROSS" ? (
									<>{t("modules.invoice.field.total_price_gross.title")}</>
								) : (
									<>{t("modules.invoice.field.total_price_net.title")}</>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						{items.length > 0 &&
							items.map((item: Record<string, any>, index: number) => {
								return (
									<tr key={index}>
										<td>{item.name}</td>
										<td className="text-nowrap">{FormatMoney(item.price)}</td>
										<td>{item.tax?.name}</td>
										<td>{item.quantity}</td>
										<td>{item.volume}</td>
										<td />
										<td className="text-nowrap">{FormatMoney(item.price_sum)}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		);
	};

	const drawButtonDependingOnAction = () => {
		if (watch("action")) {
			if (watch("action") === "group") {
				return (
					<ButtonLoading variant="primary" loading={loading} onClick={handleGroupInvoice}>
						{t("common.action.group")}
					</ButtonLoading>
				);
			}
			return (
				<ButtonLoading variant="primary" loading={loading} onClick={handleUngroupInvoice}>
					{t("common.action.ungroup")}
				</ButtonLoading>
			);
		}
	};

	return (
		<Modal show={isShown} onHide={onHide} size="lg">
			<FormDirty formState={formState} key="group-invoice-modal" noValidate>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.invoice.action.group_invoice.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-column">
						<h5> {t("modules.invoice.field.group_options.title")}</h5>
						<div className={"d-flex flex-column"}>
							<h6 className="mb-0">{t("modules.invoice.field.group.title")}</h6>
							<span className={"mb-1"}>
								{t("modules.invoice.field.group_options.help_text.description")}
							</span>
							<h6 className="mb-0">{t("modules.invoice.field.ungroup.title")}</h6>
							<span className={"mb-10"}>{t("modules.invoice.field.ungroup.help_text.description")}</span>
						</div>
					</div>
					<FormSelectGroup
						label={t("modules.invoice.field.action.title")}
						name="action"
						errors={[]}
						options={actionOptions}
						control={control}
						data-testid="action"
					/>
					{watch("action") === "group" && (
						<>
							<FormInput
								name="group"
								errors={errors}
								label={t("modules.invoice.field.group_name.title")}
								register={register}
							/>
							<h5 className="mt-5">{t("modules.invoice.field.current_elements.title")}</h5>
							{invoice?.items.length > 0 && drawTable(invoice.items)}
							<div className="mt-5">
								<h5 className="mt-5">{t("modules.invoice.field.grouped_elements.title")}</h5>
								{loadingGroupedInvoice ? <Loading /> : drawTable(groupedInvoice?.items || [])}
							</div>
						</>
					)}
					{watch("action") === "ungroup" && (
						<>
							<h5 className="mt-5">{t("modules.invoice.field.current_elements.title")}</h5>
							{invoice?.items.length > 0 && drawTable(invoice.items)}
							<div className="mt-5">
								<h5 className="mt-5">{t("modules.invoice.field.ungrouped_elements.title")}</h5>
								{loadingUngroupedInvoice ? <Loading /> : drawTable(ungroupedInvoice?.items || [])}
							</div>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					{drawButtonDependingOnAction()}
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default GroupInvoiceModal;
