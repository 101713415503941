import { FILTER_SEPARATOR } from "go-list/core/components/Filter/services";
import { SubReportApi } from "go-report/core/services/types";
import {
	ColumnConfigReportComboApi,
	ConfigReportComboApi,
	DataConfigReportComboApi,
	FlatReportComboApi,
	ReportResponseReportComboApi,
	ResponseReportComboApi,
	ResultDataCompareFlatReportComboApi,
	ResultDataFlatReportComboApi,
	ResultFlatReportComboApi,
	RowCompareConfigReportComboApi,
	RowConfigReportComboApi,
	ValidationReportComboException,
} from "./types";
import ReportComboUtils from "./utils";

export const GROUP_SEPARATOR = "ç";

const parseStringToRecord = (str: string): { records: Record<string, string>; operators: string[] } => {
	const regex = /([a-zA-Z_]+)\.([a-zA-Z_]+)/g;
	const record: Record<string, string> = {};
	const operators: string[] = [];
	let match: RegExpExecArray | null;

	// Extract key-value pairs
	while ((match = regex.exec(str)) !== null) {
		record[match[1]] = match[2];
	}

	// Extract operators
	const operatorRegex = /[+\-*\/]/g;
	let operatorMatch: RegExpExecArray | null;
	while ((operatorMatch = operatorRegex.exec(str)) !== null) {
		operators.push(operatorMatch[0]);
	}

	return { records: record, operators };
};

const extractExpression = (
	expression: string,
	data: ReportResponseReportComboApi[],
	id: string,
	valueKey?: string,
	compare?: boolean
): number => {
	const records = parseStringToRecord(expression);
	const operators = records.operators;

	const values = Object.entries(records.records).map(([key, aggregateId]) => {
		const subReports: SubReportApi[] = compare
			? getSubReportsForCompareDataNew(data, id, key, valueKey)
			: getSubReportsForData(data, id, key, valueKey);

		return createResultData(subReports, id, aggregateId);
	});
	if (values.length <= 0) return 0;

	let totalValue = ReportComboUtils.getValueFromValueObj(values[0]);

	for (let i = 1; i < values.length; i++) {
		const value = ReportComboUtils.getValueFromValueObj(values[i]);
		const operator = operators[0];
		switch (operator) {
			case "*":
				totalValue *= value;
				break;
			case "+":
				totalValue += value;
				break;
		}
	}
	return ReportComboUtils.parseValueToObject(values[0], totalValue);
};

export const mapOldFilterToNew = (value: string) => {
	return value.replaceAll("&", FILTER_SEPARATOR);
};

const parseSubReportIds = (subReports: SubReportApi[], idArray: any[], group: string) => {
	const ids: string[] = [];

	subReports.forEach((subReport) => {
		if (!subReport.group_by_value) {
			throw new ValidationReportComboException(
				`group_by_value is not exist for group_by_type: ${subReport.group_by_type}`
			);
		}

		const idArray1 = [...idArray, subReport.group_by_value.name];
		const groupByType = subReport.group_by_type;
		if (group !== groupByType && subReport.sub_report && subReport.sub_report.length > 0) {
			parseSubReportIds(subReport.sub_report, idArray1, group).forEach((x) => {
				ids.push(x);
			});
		} else {
			ids.push(idArray1.join(GROUP_SEPARATOR));
		}
	});

	return ids;
};

const getAggregateByGroupValues = (subReports: SubReportApi[], groupValues: string[], index: number): any[] => {
	if (groupValues.length <= index) return [];

	const groupValue = groupValues[index];
	const reports = groupValue === "NONE" ? subReports : subReports.filter((x) => x.group_by_value.name === groupValue);

	if (reports.length === 0) {
		return [];
	}

	if (groupValues.length - 1 === index) {
		return reports.flatMap((x) => x.aggregate);
	}

	const newIndex = index + 1;
	return getAggregateByGroupValues(
		reports.flatMap((x) => (x.sub_report ? x.sub_report : [])),
		groupValues,
		newIndex
	);
};
const checkValueGroupMatch = (id: string, pattern: string): boolean => {
	return pattern
		.split(GROUP_SEPARATOR)
		.every((part, index) => part === "*" || part === id.split(GROUP_SEPARATOR)[index]);
};

const getGroupIds = (
	data: ReportResponseReportComboApi[],
	configDatas: DataConfigReportComboApi[],
	group: string,
	groupValues?: string[]
) => {
	let ids: string[] = [];
	data.forEach((dataItem) => {
		const configColumn = configDatas
			.filter((x) => x.id === dataItem.id)
			.flatMap((x) => x.columns)
			.filter((x) => x.id === dataItem.column_id)[0];
		let dataForIds: string[] = [];
		if (dataItem.data.sub_report) {
			if (configColumn.group) {
				dataForIds = parseSubReportIds(
					dataItem.data.sub_report
						.filter((x) => x.group_by_type === configColumn.group)
						.flatMap((x) => (x.sub_report ? x.sub_report : [])),
					[],
					group
				);
			} else {
				dataForIds = parseSubReportIds(dataItem.data.sub_report, [], group);
			}
		}
		dataForIds.forEach((id) => {
			if (!ids.includes(id)) {
				ids.push(id);
			}
		});
	});

	if (groupValues) {
		ids = ids.filter((id) => groupValues.some((groupValue) => checkValueGroupMatch(id, groupValue)));
	}

	return ids;
};

const getAggregateId = (columnId: string, defaultAggregate: string, aggregate_columns?: Record<string, string>) => {
	if (!aggregate_columns) return defaultAggregate;

	const aggregateColumnId = aggregate_columns[columnId];
	if (aggregateColumnId) return aggregateColumnId;

	return defaultAggregate;
};

const getAggregateColumnId = (configRow: RowConfigReportComboApi, columnId: string) => {
	return getAggregateId(columnId, configRow.aggregate, configRow.aggregate_columns);
};

const getCompareAggregateColumnId = (compareConfig: RowCompareConfigReportComboApi, columnId: string) => {
	return getAggregateId(columnId, compareConfig.aggregate, compareConfig.aggregate_columns);
};

const getSubReportsForCompareDataNew = (
	data: ReportResponseReportComboApi[],
	id: string,
	dataId: string,
	columnValueKey?: string
) => {
	const columnDatesForDataId = data.filter((x) => x.id === dataId);
	let subReports: SubReportApi[] = [];
	if (columnValueKey) {
		const dataForColumn = columnDatesForDataId
			.flatMap((x) => (x.compare_data.sub_report ? x.compare_data.sub_report : []))
			.filter((x) => x.group_by_value.name === columnValueKey)[0];
		subReports = dataForColumn
			? id === "NONE"
				? [dataForColumn]
				: dataForColumn.sub_report
				? dataForColumn.sub_report
				: []
			: [];
	} else {
		const columnDatesForDataId = data.filter((x) => x.id === dataId);
		subReports =
			id === "NONE"
				? [columnDatesForDataId[0].compare_data]
				: columnDatesForDataId.flatMap((x) => (x.compare_data.sub_report ? x.compare_data.sub_report : []));
	}

	return subReports;
};
const getSubReportsForData = (
	data: ReportResponseReportComboApi[],
	id: string,
	dataId: string,
	columnValueKey?: string
) => {
	const columnDatesForDataId = data.filter((x) => x.id === dataId);
	if (columnDatesForDataId.length === 0) {
		throw new ValidationReportComboException(`Data for data_id "${dataId}" not exist`);
	}

	let subReports: SubReportApi[] = [];
	if (columnValueKey) {
		const dataForColumn = columnDatesForDataId
			.flatMap((x) => x.data.sub_report)
			.filter((x) => x && x.group_by_value.name === columnValueKey)[0];
		subReports = dataForColumn
			? id === "NONE"
				? [dataForColumn]
				: dataForColumn.sub_report
				? dataForColumn.sub_report
				: []
			: [];
	} else {
		if (id !== "NONE") {
			const idLength = id.split(GROUP_SEPARATOR).length;
			let subReportLength = 0;
			columnDatesForDataId.forEach((x) => {
				if (!x.data.sub_report) return;
				let subReports1 = x.data.sub_report;
				if (subReports1.length > 0) subReportLength++;
				if (subReports1.some((x1) => x1.sub_report && x1.sub_report.length > 0)) subReportLength++;
				subReports1 = subReports1.flatMap((x1) => (x1.sub_report ? x1.sub_report : []));
				if (subReports1.some((x1) => x1.sub_report && x1.sub_report.length > 0)) {
					subReportLength++;
					subReports1 = subReports1.flatMap((x1) => (x1.sub_report ? x1.sub_report : []));
					if (subReports1.some((x1) => x1.sub_report && x1.sub_report.length > 0)) {
						subReportLength++;
						subReports1 = subReports1.flatMap((x1) => (x1.sub_report ? x1.sub_report : []));
						if (subReports1.some((x1) => x1.sub_report && x1.sub_report.length > 0)) subReportLength++;
					}
				}
			});
			const skipLevel = subReportLength - idLength;
			if (skipLevel > 0) {
				let flattened = columnDatesForDataId.flatMap((x) => (x.data.sub_report ? x.data.sub_report : []));
				for (let i = 0; i < skipLevel; i++) {
					flattened = flattened.flatMap((x) => (x.sub_report ? x.sub_report : []));
				}
				return flattened;
			}
		}

		const dataForColumn = columnDatesForDataId[0];
		subReports = dataForColumn
			? id === "NONE"
				? [dataForColumn.data]
				: columnDatesForDataId
						.flatMap((x) => (x.data.sub_report ? x.data.sub_report : []))
						.filter((x) => x !== undefined)
			: [];
	}

	return subReports;
};

const getAggregateById = (aggregate: any, aggregateId: string) => {
	const aggregateObj = aggregate[aggregateId];
	if (aggregateObj) return aggregateObj;
	//to get aggregate base on inside field ex. summary,sales
	const flattened = Object.assign({}, ...Object.values(aggregate));
	return flattened[aggregateId];
};
const createResultData = (subReports: SubReportApi[], id: string, aggregateId: string) => {
	const idExplode = id.split(GROUP_SEPARATOR);
	const fullAggregates = getAggregateByGroupValues(subReports, idExplode, 0);

	const value = fullAggregates
		.flatMap((x) => ReportComboUtils.getValueFromValueObj(getAggregateById(x, aggregateId)))
		.reduce((acc, curr) => acc + curr, 0);
	const aggregate = {} as any;
	aggregate[aggregateId] = value ? value : 0;

	return aggregate[aggregateId];
};

const createCompare = (
	columnData: ReportResponseReportComboApi[],
	configColumn: ColumnConfigReportComboApi,
	configRow: RowConfigReportComboApi,
	id: string,
	columnValueKey?: string
) => {
	const compare: ResultDataCompareFlatReportComboApi[] = [];
	if (configRow.compare) {
		configRow.compare.forEach((compareItem) => {
			if (compareItem.type === "CALCULATE") {
				const compareAggregateId = getCompareAggregateColumnId(compareItem, configColumn.id);
				const calculateValue = extractExpression(compareAggregateId, columnData, id, undefined);
				const aggregate = {
					calculate: calculateValue ? calculateValue.toString() : 0,
				};
				const newCompare = {
					compare_id: compareItem.id,
					value: aggregate.calculate ? aggregate.calculate : 0,
					aggregate,
				} as ResultDataCompareFlatReportComboApi;
				compare.push(newCompare);
			} else {
				const compareAggregateId = getCompareAggregateColumnId(compareItem, configColumn.id);
				const subReports = compareItem.data_id
					? getSubReportsForData(columnData, id, compareItem.data_id, columnValueKey)
					: getSubReportsForCompareDataNew(columnData, id, configRow.data_id, columnValueKey);
				const compareValue = createResultData(subReports, id, compareAggregateId);
				const aggregate = { [compareAggregateId]: compareValue };
				const newCompare = {
					compare_id: compareItem.id,
					value: compareValue,
					aggregate,
				} as ResultDataCompareFlatReportComboApi;
				compare.push(newCompare);
			}
		});
	}
	return compare;
};

const parseSubReportsForOneRow = (
	id: string,
	data: ReportResponseReportComboApi[],
	configColumns: ColumnConfigReportComboApi[],
	configRow: RowConfigReportComboApi
) => {
	const results = [] as ResultDataFlatReportComboApi[];
	configColumns.forEach((configColumn) => {
		const dataColumnId = ReportComboUtils.getDataColumnId(configColumn);
		const aggregateId = getAggregateColumnId(configRow, configColumn.id);
		const columnData = data.filter((x) => x.column_id === dataColumnId);
		if (configRow.type !== "CALCULATE") {
			if (configColumn.values) {
				configColumn.values.forEach((columnValue) => {
					const subReports = getSubReportsForData(columnData, id, configRow.data_id, columnValue.id);
					const value = createResultData(subReports, id, aggregateId);
					const aggregate = { [aggregateId]: value };
					const result = {
						id: `${id}-${configColumn.id}-${columnValue.id}`,
						column_id: configColumn.id,
						data_column_id: ReportComboUtils.getDataColumnId(configColumn),
						aggregate,
						value: aggregate[aggregateId] ? aggregate[aggregateId] : 0,
					} as ResultDataFlatReportComboApi;

					const compare = createCompare(columnData, configColumn, configRow, id, columnValue.id);
					if (compare.length > 0) {
						result.compare = compare;
					}
					results.push(result);
				});
			} else {
				const subReports = getSubReportsForData(columnData, id, configRow.data_id, undefined);
				const value = createResultData(subReports, id, aggregateId);
				const aggregate = { [aggregateId]: value };
				const result = {
					id: `${id}-${configColumn.id}`,
					column_id: configColumn.id,
					data_column_id: ReportComboUtils.getDataColumnId(configColumn),
					aggregate,
					value: aggregate[aggregateId] ? aggregate[aggregateId] : 0,
				} as ResultDataFlatReportComboApi;

				const compare = createCompare(columnData, configColumn, configRow, id);
				if (compare.length > 0) {
					result.compare = compare;
				}
				results.push(result);
			}
		} else if (configColumn.values) {
			configColumn.values.forEach((columnValue) => {
				const calculateValue = extractExpression(aggregateId, columnData, id, columnValue.id);
				const aggregate = {
					calculate: calculateValue,
				} as any;

				const result = {
					id: `${id}-${configColumn.id}-${columnValue.id}`,
					column_id: configColumn.id,
					data_column_id: ReportComboUtils.getDataColumnId(configColumn),
					aggregate,
					value: aggregate.calculate ? aggregate.calculate : 0,
				} as ResultDataFlatReportComboApi;
				results.push(result);
			});
		} else {
			const calculateValue = extractExpression(aggregateId, columnData, id, undefined);
			const aggregate = {
				calculate: calculateValue,
			} as any;
			const result = {
				id: `${id}-${configColumn.id}`,
				column_id: configColumn.id,
				data_column_id: ReportComboUtils.getDataColumnId(configColumn),
				aggregate,
				value: aggregate.calculate ? aggregate.calculate : 0,
			} as ResultDataFlatReportComboApi;

			const compare = createCompare(columnData, configColumn, configRow, id);
			if (compare.length > 0) {
				result.compare = compare;
			}
			results.push(result);
		}
	});

	return results;
};
const parseSubReports = (
	data: ReportResponseReportComboApi[],
	configRow: RowConfigReportComboApi,
	configColumns: ColumnConfigReportComboApi[],
	configData: DataConfigReportComboApi[]
) => {
	const results = [] as ResultFlatReportComboApi[];

	const result = {
		data_id: configRow.data_id,
		row_id: configRow.id,
		name: `${configRow.name}`,
		data: [],
		sub_report: [],
	} as ResultFlatReportComboApi;
	result.data = parseSubReportsForOneRow("NONE", data, configColumns, configRow);
	results.push(result);

	const group = configRow.group;
	if (group) {
		const groupResults = [] as ResultFlatReportComboApi[];
		const ids = getGroupIds(data, configData, group, configRow.group_values);
		ids.forEach((id) => {
			const idResult = {
				data_id: configRow.data_id,
				row_id: configRow.id,
				name: `${id}`,
				data: [],
			} as ResultFlatReportComboApi;
			const resultData = parseSubReportsForOneRow(id, data, configColumns, configRow);
			idResult.data = resultData;
			groupResults.push(idResult);
			result.sub_report = groupResults;
		});
	}
	return results;
};

const parseResults = (
	data: ReportResponseReportComboApi[],
	configColumns: ColumnConfigReportComboApi[],
	configRows: RowConfigReportComboApi[],
	configData: DataConfigReportComboApi[]
): ResultFlatReportComboApi[] => {
	//Results for every rows in table
	const results = [] as ResultFlatReportComboApi[];
	configRows.forEach((configRow) => {
		//create row base on config and columns
		const resultSubReports = parseSubReports(data, configRow, configColumns, configData);

		resultSubReports?.forEach((resultSubReport) => {
			results.push(resultSubReport);
		});
	});
	return results;
};

const validate = (config: ConfigReportComboApi) => {
	const ids = ReportComboUtils.findVariablesInString(JSON.stringify(config));
	if (ids.length > 0) {
		throw new ValidationReportComboException(`Variable is not defined: ${ids.join(",")}`);
	}

	const duplicates = ReportComboUtils.findDuplicates(config.rows, "id");
	if (duplicates.length > 0) {
		throw new ValidationReportComboException(`duplcate row ids: ${duplicates.join(",")}`);
	}
	const dataDuplicates = ReportComboUtils.findDuplicates(config.data, "id");
	if (dataDuplicates.length > 0) {
		throw new ValidationReportComboException(`duplcate data ids: ${dataDuplicates.join(",")}`);
	}
	const columnDuplicates = ReportComboUtils.findDuplicates(config.columns, "id");
	if (columnDuplicates.length > 0) {
		throw new ValidationReportComboException(`duplcate column ids: ${columnDuplicates.join(",")}`);
	}
};

export const comboFlat = (data: ResponseReportComboApi, config: ConfigReportComboApi): FlatReportComboApi => {
	validate(config);
	const flatCombo = {} as FlatReportComboApi;
	flatCombo.columns = config.columns;
	flatCombo.results = parseResults(data.reports, config.columns, config.rows, config.data);
	return flatCombo;
};

export const generateUUID = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
		const r = Math.floor(16 * Math.random());
		const v = c !== "x" ? (r % 4) + 8 : r;
		return v.toString(16);
	});
};
