import { useCallback, useState } from "react";
import { FieldValues, UseFormSetError } from "react-hook-form";
import useFlash from "go-alert/AlertMessage";
import { CustomValidationError } from "../services/types";

export const useCustomErrors = <FormType extends FieldValues>(
	setError: UseFormSetError<FormType>
): {
	errors: CustomValidationError<FormType>[];
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<FormType>[]>>;
	validateCustomErrors: () => boolean;
} => {
	const { addFlash } = useFlash();
	const [errors, setErrors] = useState<CustomValidationError<FormType>[]>([]);

	const validateCustomErrors = useCallback(() => {
		errors.forEach((error) => {
			setError(error.name, {
				type: error.options.type,
				types: error.options.types,
			});
		});

		if (errors.length === 0) {
			return true;
		}

		addFlash({
			type: "danger",
			msg: "form.wrong",
		});

		return false;
	}, [errors, setError, addFlash]);

	return {
		errors,
		setErrors,
		validateCustomErrors,
	};
};
