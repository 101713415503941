import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReservationApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import ReservationForm from "../../components/ReservationForm";

interface MatchParams {
	reservationId: string;
}

const OrganizationReservationsEditPage = (): JSX.Element => {
	const [reservation, setReservation] = useState<ReservationApi>();
	const { t } = useTranslation();
	const { reservationId } = useParams<MatchParams>();
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const confirmation = useConfirmation();
	const params: Record<string, string> = { include: "room,table,client" };
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchReservation();
	}, []);

	const onFetchReservation = async () => {
		try {
			const res = await api.organization().getReservation(Number(reservationId), params);
			setReservation(res);
			handleChangeTabTitle(t("modules.reservation.header.title"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!reservation) {
		return <LoadingContainer />;
	}

	const cancelReservation = async (reservation: ReservationApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			const res = await api.organization().cancelReservation(reservation.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setReservation(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const copyReservation = () => {
		history.push({
			pathname: `/${organization.id}/reservations/new`,
			state: {
				copiedItem: reservation.id,
			},
		});
	};

	const buttons: ButtonProps[] = [];
	if (reservation.reservation_status === "NEW") {
		buttons.push({
			title: t("common.action.cancel", { ns: "lib" }),
			action: () => cancelReservation(reservation),
			dropdown: true,
		});
	}
	buttons.push({
		title: t("common.action.copy", { ns: "lib" }),
		action: () => copyReservation(),
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.cancel", { ns: "lib" }),
			action: () => cancelReservation(reservation),
			hide: reservation.reservation_status !== "NEW",
		},
		{
			title: t("common.action.copy", { ns: "lib" }),
			action: () => copyReservation(),
		},
	];

	const renderTitle = () => (
		<>
			{t("modules.reservation.header.title")}
			<FormatResourceStatus status={reservation.reservation_status} />
		</>
	);

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<ReservationForm reservation={reservation} />
		</>
	);
};

export default OrganizationReservationsEditPage;
