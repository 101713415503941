import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import ErrorDuplicateName from "../../../../../../../../../components/Common/Errors/ErrorDuplicateName";
import { ItemApi, ItemGroupApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	searchText: string;
	handleCancel: () => void;
	handleSave: (data: ItemGroupApi) => void;
	contextType?: string;
	nestedContext?: boolean;
}

const CreateMenuPageItemItemGroupModalBody = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const location = useLocation();
	const [itemGroup] = useState<ItemGroupApi>({ name: props.searchText } as ItemGroupApi);
	const [loading, setLoading] = useState(false);
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<ItemGroupApi>({
		criteriaMode: "all",
		defaultValues: itemGroup,
	});
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		control,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(form.setError);
	const onSubmit = handleSubmit(async (data: ItemGroupApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		const objData = {
			name: data.name,
			tax_id: data.tax_id,
			category_id: data.category_id,
			items: [
				{
					position: 0,
					name: data.name ? data.name : "empty",
					price: data.items[0]?.price ? data.items[0].price : { amount: "0" },
					discountable: true,
					weighted_type: "DISABLED",
					open_price: false,
				} as ItemApi,
			],
		} as ItemGroupApi;
		try {
			const params: Record<string, any> = { include: "items" };
			const res = await api.organization().createItemGroup(objData, params);
			setLoading(false);
			props.handleSave(res);
			if (!location?.pathname?.includes("item_groups") && !location?.pathname?.includes("modifier_groups"))
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const searchTaxes = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getTaxesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchCategories = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getCategoriesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch: form.watch },
		}),
		[setErrors, form.watch]
	) satisfies CustomValidationConfig;

	const categoryValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	const taxValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	const priceValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	return (
		<>
			<Modal.Body>
				<div className={"row"}>
					<div className={"col-md-6"}>
						<FormInput
							label={t("lib:common.word.name")}
							register={register}
							name="name"
							errors={errors}
							customValidationConfig={nameValidationConfig}
						/>
						<ErrorDuplicateName resource_type={"ITEM_GROUP"} errors={errors} />
					</div>
					<div className={"col-md-6"}>
						<FormSelectGroup
							label={t("common.word.category")}
							path={`/${organization.id}/menu/categories/`}
							name="category_id"
							errors={errors}
							placeholder={t("modules.item_group.action.choose_category.title")}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							loadOptions={searchCategories}
							control={control}
							customValidationConfig={categoryValidationConfig}
							data-testid="category_id"
						/>
					</div>
					<div className={"col-md-6"}>
						<FormSelectGroup
							label={t("modules.item_group.field.tax.title")}
							placeholder={t("modules.item_group.action.choose_tax.title")}
							name="tax_id"
							errors={errors}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							loadOptions={searchTaxes}
							control={control}
							customValidationConfig={taxValidationConfig}
							data-testid="tax_id"
						/>
					</div>
					<div className={"col-md-6"}>
						<FormMoneyInput
							label={t("modules.item_group.field.price.title")}
							control={control}
							currency={currency}
							name="items[0].price.amount"
							errors={errors}
							customValidationConfig={priceValidationConfig}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading variant="primary" type="submit" loading={loading} onClick={onSubmit}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
				<Button variant="light" onClick={props.handleCancel}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</>
	);
};

export default CreateMenuPageItemItemGroupModalBody;
