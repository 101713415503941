import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { getCreatingDeliveryZones, getNotificationTemplate } from "../../../../../../utils/envutil";
import SettingsNavigationMenu from "./components/SettingsNavigationMenu";
import OrganizationSettingsAdvancedPage from "./pages/Advanced";
import { OrganizationSettingsAvailabilitiesPage } from "./pages/Availabilities";
import OrganizationSettingsCurrenciesPage from "./pages/Currencies";
import OrganizationSettingsCustomFieldsPage from "./pages/CustomFields";
import OrganizationSettingsDefaultCommentsPage from "./pages/DefaultComments";
import OrganizationSettingsDeliveryZonesPage from "./pages/DeliveryZones";
import OrganizationSettingsDevicesPage from "./pages/Devices";
import OrganizationSettingsInfoPage from "./pages/Info";
import { OrganizationSettingsNotificationTemplatesPage } from "./pages/NotificationTemplates";
import OrganizationSettingsPaymentMethodsPage from "./pages/PaymentMethods";
import OrganizationSettingsPermissionsPage from "./pages/Permissions";
import OrganizationSettingsPrintoutTemplatesPage from "./pages/PrintoutTemplates";
import OrganizationSettingsRemoveOrganizationPage from "./pages/RemoveOrganization";
import OrganizationSettingsRoomsPage from "./pages/Rooms";
import OrganizationSettingsSegmentsPage from "./pages/Segments";
import OrganizationSettingsTagsPage from "./pages/Tags";
import OrganizationSettingsTaxPage from "./pages/Taxes";
import OrganizationSettingsTerminalsPage from "./pages/Terminals";
import OrganizationSettingsUsersPage from "./pages/Users";

const OrganizationSettingsPage: FC<RouteComponentProps> = (props) => {
	const settingsPermission = useSelector(hasPermission("SETTINGS"));
	const venueRoomManagePermission = useSelector(hasPermission("VENUE_ROOM_MANAGE"));
	const managePermission = useSelector(hasPermission("MANAGE"));
	const organization = useSelector(selectOrganization);
	const isGoOrderAppInstalled = (organization?.more?.installed_app_providers || []).includes("GOORDER");

	const getRedirectTo = () => {
		if (settingsPermission) return `${props.match.url}/info`;
		if (venueRoomManagePermission) return `${props.match.url}/rooms`;
		if (managePermission) return `${props.match.url}/users`;
		return props.match.url;
	};

	return (
		<div className="page-container">
			<SettingsNavigationMenu />
			<Switch>
				<Route path={`${props.match.url}/taxes`} component={OrganizationSettingsTaxPage} />
				<Route path={`${props.match.url}/payment_methods`} component={OrganizationSettingsPaymentMethodsPage} />
				<Route path={`${props.match.url}/info`} component={OrganizationSettingsInfoPage} />
				<Route path={`${props.match.url}/currencies`} component={OrganizationSettingsCurrenciesPage} />
				<Route path={`${props.match.url}/rooms`} component={OrganizationSettingsRoomsPage} />
				<Route
					path={`${props.match.url}/printout_templates`}
					component={OrganizationSettingsPrintoutTemplatesPage}
				/>
				{getNotificationTemplate() && (
					<Route
						path={`${props.match.url}/notification_templates`}
						component={OrganizationSettingsNotificationTemplatesPage}
					/>
				)}
				<Route path={`${props.match.url}/users`} component={OrganizationSettingsUsersPage} />
				<Route
					path={`${props.match.url}/default_comments`}
					component={OrganizationSettingsDefaultCommentsPage}
				/>
				<Route path={`${props.match.url}/permissions`} component={OrganizationSettingsPermissionsPage} />
				<Route path={`${props.match.url}/availabilities`} component={OrganizationSettingsAvailabilitiesPage} />
				<Route path={`${props.match.url}/custom_fields`} component={OrganizationSettingsCustomFieldsPage} />
				<Route path={`${props.match.url}/terminals`} component={OrganizationSettingsTerminalsPage} />
				<Route path={`${props.match.url}/devices`} component={OrganizationSettingsDevicesPage} />
				<Route path={`${props.match.url}/advanced`} component={OrganizationSettingsAdvancedPage} />
				<Route path={`${props.match.url}/tags`} component={OrganizationSettingsTagsPage} />
				{isGoOrderAppInstalled && getCreatingDeliveryZones() && (
					<Route
						path={`${props.match.url}/delivery_zones`}
						component={OrganizationSettingsDeliveryZonesPage}
					/>
				)}
				<Route path={`${props.match.url}/segments`} component={OrganizationSettingsSegmentsPage} />
				<Route
					path={`${props.match.url}/remove_organization`}
					component={OrganizationSettingsRemoveOrganizationPage}
				/>
				<Redirect from={props.match.url} exact to={getRedirectTo()} />
				<Route component={Page404} />
			</Switch>
		</div>
	);
};

export default OrganizationSettingsPage;
