import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

const SubmenuPriceListsNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	return (
		<div className="content-navigation">
			<Nav>
				<Nav.Item>
					<Nav.Link exact as={NavLink} to={`/${organization.id}/menu/price_lists`}>
						{t("modules.price_list.header.title")}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link exact as={NavLink} to={`/${organization.id}/menu/price_lists/group_editing`}>
						{t("modules.group_edit.header.title")}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link exact as={NavLink} to={`/${organization.id}/menu/price_lists/predicates`}>
						{t("modules.predicate.header.title")}
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</div>
	);
};

export default SubmenuPriceListsNavigation;
