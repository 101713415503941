import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LiveOrdersBlockedProductFilter } from "../../services/types";

interface Props {
	selectedFilter: LiveOrdersBlockedProductFilter;
	setSelectedFilter: (filter: LiveOrdersBlockedProductFilter) => void;
	setSearch: (search: string) => void;
	search: string;
}

const LiveOrdersBlockedProductsFiltersModal = ({
	selectedFilter,
	setSelectedFilter,
	search,
	setSearch,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const isSelected = (filter: LiveOrdersBlockedProductFilter) => filter === selectedFilter;

	const handleChangeSelectedFilter = (filter: LiveOrdersBlockedProductFilter) => setSelectedFilter(filter);

	return (
		<div className="d-flex flex-wrap gap-2 live-orders-blocked-products-filters-modal">
			<Button
				variant={`${isSelected(LiveOrdersBlockedProductFilter.ALL) ? "outline-primary" : "outline-dark"}`}
				onClick={() => handleChangeSelectedFilter(LiveOrdersBlockedProductFilter.ALL)}
			>
				{t("lib:common.word.all")}
			</Button>
			<Button
				variant={`${isSelected(LiveOrdersBlockedProductFilter.BLOCKED) ? "outline-primary" : "outline-dark"}`}
				onClick={() => handleChangeSelectedFilter(LiveOrdersBlockedProductFilter.BLOCKED)}
			>
				{t("modules.live_order.field.blocked.title")}
			</Button>
			<Button
				className="me-3"
				variant={`${isSelected(LiveOrdersBlockedProductFilter.UNBLOCKED) ? "outline-primary" : "outline-dark"}`}
				onClick={() => handleChangeSelectedFilter(LiveOrdersBlockedProductFilter.UNBLOCKED)}
			>
				{t("modules.live_order.field.unblocked.title")}
			</Button>
			<input
				type="search"
				className="form-control search-input flex-grow-1 w-auto"
				placeholder={t("common.word.search", { ns: "lib" })}
				defaultValue={search}
				onChange={(evt) => setSearch(evt.target.value)}
			/>
		</div>
	);
};

export default LiveOrdersBlockedProductsFiltersModal;
