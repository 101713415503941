import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { ApiError } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../services/Api/types";
import { parseApplicationSettings, parseApplicationSettingsValues } from "../../../utils/utils";
import { AppState } from "../AppModal";
import GoOrderMenuErrors from "../GoOrder/GoOrderMenuErrors";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const KWHotelModalForm: FC<Props> = ({ appState, handleUpdateApp }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings || {}),
			},
		},
	});
	const [updatedConfig, setUpdatedConfig] = useState<Record<string, any> | undefined>(appState.config);
	const [menuErrors] = useState<ApiError[]>([]);
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
		formState,
		setError,
		control,
		clearErrors,
		watch,
	} = form;
	const [loading, setLoading] = useState(false);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};

		try {
			if (appState.app.id) {
				const res = await api.organization().updateApp(newData, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				handleUpdateApp(res);
				reset(watch());
			} else {
				const res = await api.organization().installApps("KWHOTEL", newData, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				history.push(`/${organization.id}/apps/active/${res.id}`);
				reset({
					...res,
					settings: parseApplicationSettingsValues(res.settings),
				});
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const searchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const updatePort = (evt: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors("settings.ip_address");
		if ((evt.nativeEvent as InputEvent).inputType === "deleteContentBackward") {
			return;
		}
		const value = evt.target.value;
		let finalValue;
		if (value.slice(value.length - 2) === "..") {
			setValue(`settings.ip_address`, value.slice(0, -1));
			return;
		}
		if (value.split(".").length > 3) {
			const values = value.split(".");
			let splitted = value.split(".")[3];
			if (splitted.length > 3) {
				splitted = splitted.slice(0, 3);
			}
			setValue(`settings.ip_address`, `${values[0]}.${values[1]}.${values[2]}.${splitted}`);
			return;
		}
		if (value.length === 3) {
			if (!isNaN(Number(value)) && Number(value) % 1 === 0 && value.slice(value.length - 1) !== ".") {
				finalValue = `${value}.`;
				setValue(`settings.ip_address`, finalValue);
			}
		}
		if (value.length > 3) {
			const sliced = value.slice(value.length - 3);
			if (Number(sliced) % 1 === 0 && sliced.slice(sliced.length - 1) !== ".") {
				finalValue = `${value}.`;
				setValue(`settings.ip_address`, finalValue);
			}
		}
	};

	const updateConfig = (field: string, obj?: SearchSelectApi) => {
		const cnf = { ...updatedConfig };
		if (field === "payment_method_name") {
			cnf.payment_method_name = obj?.label;
		}
		setUpdatedConfig(cnf);
	};

	return (
		<FormDirty formState={formState} onSubmit={onSubmit} className={"app-form"}>
			<FormSelectGroup
				name="settings.payment_method_id"
				getOptionLabel={(option) => option.label}
				getOptionValue={(option) => option.id}
				path={`/${organization.id}/settings/payment_methods/`}
				control={control}
				loadOptions={searchPaymentMethods}
				label={t("modules.app.field.default_payment_method.title")}
				defaultValue={{
					label: updatedConfig?.payment_method_name,
					id: appState.app?.settings?.payment_method_id,
				}}
				onChange={(_, fullObj) => updateConfig("payment_method_name", fullObj)}
				errors={errors}
				data-testid="settings.payment_method_id"
			/>
			<div className={"app-advanced mt-4"}>
				<strong>{t("common.word.advanced", { ns: "lib" })}</strong>
			</div>
			<FormNumberInput
				errors={errors}
				name="settings.port"
				label={t("modules.app.field.port.title")}
				defaultValue="11002"
				onChange={() => clearErrors("settings.port")}
				control={control}
				suffix=""
			/>
			<FormInput
				label={t("lib:common.word.ip_address")}
				name={"settings.ip_address"}
				onChange={(val: React.ChangeEvent<HTMLInputElement>) => updatePort(val)}
				errors={errors}
				register={register}
			/>
			<FormInput
				label={t("modules.app.field.file_path.title")}
				name={"settings.file_path"}
				errors={errors}
				register={register}
			/>
			<GoOrderMenuErrors menuErrors={menuErrors} />
			<ButtonLoading loading={loading} onClick={onSubmit}>
				{t("common.action.save", { ns: "lib" })}
			</ButtonLoading>
		</FormDirty>
	);
};
export default KWHotelModalForm;
