import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ClientContactCard from "./ClientContactCard";
import ClientExpenseCard from "./ClientExpenseCard";
import ClientInfoCard from "./ClientInfoCard";
import ClientLastInvoicesCard from "./ClientLastInvoicesCard";
import ClientLastTransactionsCard from "./ClientLastTransactionsCard";

interface Props {
	client: ClientApi;
}

const ClientPreview: FC<Props> = ({ client }) => {
	const { addSuccessFlash, addFlash } = useFlash();
	const confirmation = useConfirmation();
	const [showLogs, setShowLogs] = useState(false);
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const removeClient = async (client: ClientApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeClient(client.id);
			history.push(`/${organization.id}/clients`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/clients/${client.id}/edit`,
			variant: "light",
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			variant: "light",
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeClient(client),
			dropdown: true,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/clients/${client.id}/edit`,
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeClient(client),
		},
	];

	const renderSubtitle = () => (
		<ul>
			<li>{client?.client_group?.name}</li>
		</ul>
	);

	const avatar = {
		label: client.name,
		image: client.avatar_link,
	};

	return (
		<div className="content-preview">
			<MobileActions actions={mobileActions} />
			<Header title={client.name} subtitle={renderSubtitle()} buttons={isMobile ? [] : buttons} avatar={avatar} />
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={client.id}
					resourceType={"CLIENT"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			<div className="content-preview-body">
				<div className="row">
					<div className="col-md-4">
						<ClientContactCard client={client} />
						<ClientInfoCard client={client} />
					</div>
					<div className="col-md-8">
						<ClientExpenseCard client={client} />
						<ClientLastTransactionsCard client={client} />
						<ClientLastInvoicesCard client={client} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClientPreview;
