import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FormErrorMessage, hasErrors } from "../../../../../../../../../../../components/Common/Errors/ErrorMessage";
import { PosAccessApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	posAccess: PosAccessApi;
	handleSave: (data: PosAccessApi) => void;
}

const PosAccessForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<PosAccessApi>({
		criteriaMode: "all",
		defaultValues: props.posAccess,
	});
	const { handleSubmit, setValue, formState } = form;
	const [authErrors, setAuthErrors] = useState<ApiError[]>([]);
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const [authLoading, setAuthLoading] = useState(false);
	const [clearLoading, setClearLoading] = useState(false);
	const [login, setLogin] = useState(props.posAccess.login || "");
	const [password, setPassword] = useState(props.posAccess.password || "");
	const inputType = useRef<"text" | "password">("text");
	const [showPassword, setShowPassword] = useState(false);
	const confirmation = useConfirmation();
	const posAccess = props.posAccess;
	const onAuthorize = async () => {
		try {
			setAuthLoading(true);
			const res = await api.organization().authorizePosAccess();
			setPassword(res.password || "");
			setAuthLoading(false);
			setShowPassword(true);
		} catch (e) {
			const exceptionErrors = handleException(e);
			addFlash({
				type: "danger",
				msg: t("constraints.wrong_password", { ns: "lib" }),
			});
			setAuthErrors(exceptionErrors);
			setAuthLoading(false);
		}
	};

	const onSubmit = handleSubmit(async () => {
		try {
			setLoading(true);
			const passwordResponse = await api.organization().authorizePosAccess();
			const data = {
				login,
				password: password ? password : passwordResponse.password,
			};
			await api.organization().savePosAccess(data);
			setAuthErrors([]);
			setLoading(false);
			setPassword("");
			props.handleSave({ login, password: "" });
			setValue("password", "");
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			setLoading(false);
			const exceptionErrors = handleException(e);
			if (exceptionErrors[0]?.field === "all") {
				addFlash({
					type: "danger",
					msg: exceptionErrors,
				});
			} else {
				addFlash({
					type: "danger",
					msg: "form.wrong",
				});
			}
			setAuthErrors(exceptionErrors);
		}
	});

	const onClearData = async () => {
		await confirmation({
			title: t("common.word.confirmation", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			setClearLoading(true);
			await api.organization().deletePosAccess(password);
			setAuthErrors([]);
			setClearLoading(false);
			setPassword("");
			setLogin("");
			props.handleSave({});
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		inputType.current = "password";
	}, []);

	return (
		<>
			<FormDirty formState={formState} isSubmitting={formState.isDirty} autoComplete="off">
				{!posAccess.login && <Alert variant="warning">{t("modules.pos_access.field.warning.title")}</Alert>}
				<Form.Group className="form-group">
					<Form.Label>{t("modules.setting.field.login.title")}</Form.Label>
					<Form.Control
						id="login"
						isInvalid={hasErrors(authErrors, "login")}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setLogin(event.target.value)}
						value={login}
						type="text"
						autoComplete="off"
					/>
					<FormErrorMessage errors={authErrors} field="login" />
				</Form.Group>
				<Form.Group className="form-group">
					{props.posAccess.login && !showPassword && (
						<>
							<Form.Label>{t("lib:common.word.password")}</Form.Label>
							<div className="input-group">
								<Form.Control
									id="password"
									isInvalid={hasErrors(authErrors, "password")}
									placeholder="********"
									onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
									value={password}
									type={inputType.current}
									autoComplete="off"
								/>
								<div className="input-group-append">
									<ButtonLoading
										loading={authLoading}
										variant="primary"
										type="button"
										onClick={onAuthorize}
									>
										{t("modules.pos_access.action.show_password.title")}
									</ButtonLoading>
								</div>
								<FormErrorMessage errors={authErrors} field="password" />
							</div>
						</>
					)}
					{!props.posAccess.login && (
						<>
							<Form.Label>{t("lib:common.word.password")}</Form.Label>
							<Form.Control
								id="password"
								isInvalid={hasErrors(authErrors, "password")}
								onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
								value={password}
								type={inputType.current}
								autoComplete="off"
							/>
							<FormErrorMessage errors={authErrors} field="password" />
						</>
					)}
					{props.posAccess.login && showPassword && (
						<>
							<Form.Label>{t("lib:common.word.password")}</Form.Label>
							<Form.Control
								id="password"
								isInvalid={hasErrors(authErrors, "password")}
								placeholder="********"
								onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
								value={password}
								type="text"
								autoComplete="off"
							/>
							<FormErrorMessage errors={authErrors} field="password" />
						</>
					)}
				</Form.Group>
				<ButtonLoading className="me-1" loading={loading} variant="primary" type="submit" onClick={onSubmit}>
					{t("modules.pos_access.action.set_data.title")}
				</ButtonLoading>
				<ButtonLoading loading={clearLoading} variant="danger" type="button" onClick={onClearData}>
					{t("modules.pos_access.action.clear_data.title")}
				</ButtonLoading>
			</FormDirty>
		</>
	);
};
export default PosAccessForm;
