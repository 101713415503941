import { ReactComponent as ActiveSVG } from "../../../images/active.svg";
import { ReactComponent as DisabledSVG } from "../../../images/disabled.svg";
import { ReactComponent as DismissedSVG } from "../../../images/dismissed.svg";
import { ReactComponent as InactiveSVG } from "../../../images/inactive.svg";
import { ReactComponent as InformationSVG } from "../../../images/information.svg";

interface Props {
	status?: string;
}

const EntityStatus = ({ status }: Props): JSX.Element => {
	switch (status) {
		case "ACTIVE":
		case "ENABLED":
		case "DEFAULT":
		case "OPEN":
			return <ActiveSVG className="entity-status" />;
		case "DISABLED":
			return <DisabledSVG className="entity-status" />;
		case "WARNING":
			return <InactiveSVG className="entity-status" />;
		case "INFORMATION":
		case "OPEN_BLUE":
			return <InformationSVG className="entity-status" />;
		case "DISMISSED":
			return <DismissedSVG className="entity-status" />;
		default:
			return <></>;
	}
};

export default EntityStatus;
