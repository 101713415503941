import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import FormatDate from "go-core/components/Formatters/FormatDate";
import ShowJsonModal from "go-core/components/ShowJsonModal/ShowJsonModal";
import FormatRequestItemStatus from "./components/FormatRequestItemStatus";
import { RequestItemContext } from "./services/context";
import { RequestItemApi } from "./services/types";

interface Props {
	requestItem: RequestItemApi;
}

const RequestItemPreview = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [showJson, setShowJson] = useState(false);
	const [logs, setLogs] = useState<Record<string, any> | undefined>(undefined);
	const [requestItem, setRequestItem] = useState<RequestItemApi>(props.requestItem);
	const { addFlash, addSuccessFlash } = useFlash();
	const requestItemService = React.useContext(RequestItemContext);

	const retryRequest = async () => {
		const params: Record<string, any> = {};
		params.include = "logs,settings";
		try {
			const res = await requestItemService.api().retry(requestItem.id.toString(), params);
			setRequestItem(res.data.data);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const buttons: ButtonProps[] = [];
	if (requestItem.status !== "SUCCESS" && requestItem.retryable) {
		buttons.push({
			title: t("lib:common.action.send_again"),
			action: () => retryRequest(),
			dropdown: true,
		});
	}
	return (
		<div className="content">
			<Header
				title={
					<>
						{t("lib:go_component.request_item.preview.header.title")} "
						{t(`lib:go_component.request_item.types.${requestItem.type}`)}"{" "}
						<FormatRequestItemStatus status={requestItem.status} />
					</>
				}
				buttons={buttons}
			/>
			<div className="row row-gap-2">
				<div className="col-md-8">
					<Card>
						<Card.Body>
							<h5>{t("lib:common.word.basic_data")}</h5>
							<dl className="mb-0">
								<dt>{t("lib:common.word.type")}</dt>
								<dd>{t(`lib:go_component.request_item.types.${requestItem.type}`)}</dd>
							</dl>

							<dl className="mb-0">
								<dt>{t("lib:common.word.source")}</dt>
								<dd>{requestItem.source}</dd>
							</dl>

							<dl className="mb-0">
								<dt>{t("lib:common.word.created_at")}</dt>
								<dd>{FormatDate(requestItem.created_at, undefined, true)}</dd>
							</dl>
							{requestItem.sent_at && (
								<dl className="mb-0">
									<dt>{t("lib:go_component.request_item.preview.sent_at")}</dt>
									<dd>{FormatDate(requestItem.sent_at, undefined, true)}</dd>
								</dl>
							)}
							{requestItem.publish_at && (
								<dl className="mb-0">
									<dt>{t("lib:go_component.request_item.list.publish_at.title")}</dt>
									<dd>{FormatDate(requestItem.publish_at, undefined, true)}</dd>
								</dl>
							)}
							<dl className="mb-0">
								<dt>{t("lib:go_component.request_item.preview.url")}</dt>
								<dd>{requestItem.url}</dd>
							</dl>
						</Card.Body>
					</Card>
				</div>
				<div className="col-md-4">
					{requestItem.settings?.length > 0 && (
						<Card>
							<Card.Body>
								<h5>{t("lib:go_component.request_item.preview.settings.title")}</h5>
								{requestItem.settings.map((resource, index) => {
									return (
										<dl key={index} className="mb-0">
											<dt>{resource.setting}</dt>
											<dd>{resource.value}</dd>
										</dl>
									);
								})}
							</Card.Body>
						</Card>
					)}
				</div>
			</div>
			<div className="row row-gap-2 mt-2">
				<div className="col-md-8">
					{requestItem.logs?.length > 0 && (
						<Card>
							<Card.Body>
								<h5>{t("lib:common.word.logs")}</h5>
								<div className="table-responsive">
									<table className="table table-preview">
										<thead>
											<tr>
												<th>{t("lib:common.word.created_at")}</th>
												<th>{t("lib:common.word.status")}</th>
												<th>{t("lib:common.word.error")}</th>
												<th>{t("lib:common.word.logs")}</th>
											</tr>
										</thead>
										<tbody>
											{requestItem.logs.map((resource) => {
												const json = resource.body?.replace(/\\"/g, '"');
												return (
													<tr key={resource.id}>
														<td>{FormatDate(resource.created_at, undefined, true)}</td>
														<td>
															<FormatRequestItemStatus status={resource.type} />
														</td>
														<td style={{ maxWidth: "270px" }}>{resource.error}</td>
														<td>
															{json ? (
																<Button
																	variant={"add"}
																	onClick={() => {
																		setLogs(JSON.parse(json));
																		setShowJson(true);
																	}}
																>
																	{t("lib:common.action.show_json")}
																</Button>
															) : (
																resource.error
															)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</Card.Body>
						</Card>
					)}
				</div>
			</div>
			{showJson && (
				<ShowJsonModal
					logs={logs}
					onHide={() => {
						setShowJson(false);
						setLogs(undefined);
					}}
				/>
			)}
		</div>
	);
};
export default RequestItemPreview;
