import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDirty } from "go-form/components/FormDirty";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin } from "go-security/services/users/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { TerminalApi, TerminalDiagnoseApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { ChangeTerminalTypeModal } from "../../components/ChangeTerminalTypeModal";
import { getTerminalVersion } from "../../utils";
import TerminalAdministerModal from "./components/TerminalAdministerModal";
import TerminalDiagnoseModal from "./components/TerminalDiagnoseModal";
import TerminalDiagnosesModal from "./components/TerminalDiagnosesModal";
import TerminalDiagnosesTable from "./components/TerminalDiagnosesTable";

interface FormProps {
	web_name: string;
}

interface MatchParams {
	terminalId: string;
}

const OrganizationSettingsTerminalsDetailsPage = (): JSX.Element => {
	const [terminal, setTerminal] = useState<TerminalApi>();
	const [diagnoseToShow, setDiagnoseToShow] = useState<TerminalDiagnoseApi | undefined>(undefined);
	const [showMoreDiagnosesModal, setShowMoreDiagnosesModal] = useState<boolean>(false);
	const [showTerminalAdministerModal, setShowTerminalAdministerModal] = useState<boolean>(false);
	const [showTerminalTypeModal, setShowTerminalTypeModal] = useState<boolean>(false);
	const { t } = useTranslation();
	const [isClearingName, setIsClearingName] = useState(false);
	const form = useForm<FormProps>({
		defaultValues: { web_name: terminal?.web_name },
		criteriaMode: "all",
	});
	const history = useHistory();
	const confirmation = useConfirmation();
	const isAdmin = useSelector(selectIsAdmin);
	const organization = useSelector(selectOrganization);
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		formState: { errors },
		setValue,
		setError,
		register,
		formState,
		handleSubmit,
		reset,
	} = form;
	const [loading, setLoading] = useState(false);
	const params: Record<string, any> = { include: "diagnoses,logs,settings" };
	const { terminalId } = useParams<MatchParams>();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const settingsPermission = useSelector(hasPermission("SETTINGS"));

	useEffect(() => {
		onFetchTerminal();
	}, []);

	const onFetchTerminal = async () => {
		try {
			const res = await api.organization().getTerminal(Number(terminalId), params);
			setTerminal(res);
			setValue("web_name", res.web_name);
			handleChangeTabTitle(`${res.name} | ${t("common.word.terminal")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!terminal) {
		return <LoadingContainer />;
	}

	const onSubmit = handleSubmit(async (data: FormProps) => {
		setLoading(true);
		try {
			const res = await api.organization().updateTerminalName(terminal.id, data, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			reset(res);
			setTerminal(res);
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const onEnable = async (item: TerminalApi) => {
		await confirmation({
			title: t("common.word.confirmation", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().enableTerminal(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setTerminal(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDisable = async (item: TerminalApi) => {
		await confirmation({
			title: t("common.word.confirmation", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			const res = await api.organization().disableTerminal(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setTerminal(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onExportDatabase = async (item: TerminalApi) => {
		try {
			await api.organization().exportTerminalDatabase(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRelease = async (item: TerminalApi) => {
		try {
			await api.organization().releaseTerminal(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDisableMaster = async (item: TerminalApi) => {
		try {
			const res = await api.organization().disableTerminalMaster(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setTerminal(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onEnableMaster = async (item: TerminalApi) => {
		try {
			const res = await api.organization().enableTerminalMaster(item.id, params);
			addSuccessFlash(t("lib:common.flash.completed"));
			setTerminal(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const clearName = async () => {
		setIsClearingName(true);
		try {
			const res = await api.organization().clearTerminalName(terminal.id, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			setTerminal(res);
			setIsClearingName(false);
			reset({
				web_name: res.web_name,
			});
		} catch (err) {
			setIsClearingName(false);
			handleError.alert(err, addFlash);
		}
	};

	const changeTerminalType = () => setShowTerminalTypeModal(true);

	const sendDiagnosticReport = async () => {
		try {
			await api.organization().terminalSendDiagnosticReport(terminal.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onShowDiagnoseModal = (diagnose: TerminalDiagnoseApi) => {
		if (showMoreDiagnosesModal) setShowMoreDiagnosesModal(false);
		setDiagnoseToShow(diagnose);
	};

	const buttons: ButtonProps[] = [];
	if (terminal.status === "ENABLED")
		buttons.push({
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisable(terminal),
			dropdown: true,
		});
	if (terminal.status === "DISABLED")
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => onEnable(terminal),
			dropdown: true,
		});
	if (terminal.master && (isAdmin || organization.support || settingsPermission))
		buttons.push({
			title: t("modules.terminal.action.disable_master.title"),
			action: () => onDisableMaster(terminal),
			dropdown: true,
		});
	if (!terminal.master && (isAdmin || organization.support || settingsPermission))
		buttons.push({
			title: t("modules.terminal.action.enable_master.title"),
			action: () => onEnableMaster(terminal),
			dropdown: true,
		});
	if (isAdmin || organization.support)
		buttons.push({
			title: t("modules.terminal.action.release_bills.title"),
			action: () => onRelease(terminal),
			dropdown: true,
		});
	if (isAdmin || organization.support)
		buttons.push({
			title: t("modules.terminal.action.export_database.title"),
			action: () => onExportDatabase(terminal),
			dropdown: true,
		});
	if (isAdmin)
		buttons.push({
			title: t("modules.terminal.action.send_diagnostic_report.title"),
			action: () => sendDiagnosticReport(),
			dropdown: true,
		});
	if (terminal.type === "POS" || terminal.type === "POS_MOBILE" || terminal.type === "POS_WORK_TIME") {
		buttons.push({
			title: t("modules.terminal.action.change_type.title"),
			action: changeTerminalType,
			dropdown: true,
		});
	}
	if (isAdmin || organization.support)
		buttons.push({
			title: t("modules.terminal.action.administer.title"),
			action: () => setShowTerminalAdministerModal(true),
			dropdown: true,
		});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisable(terminal),
			hide: terminal.status !== "ENABLED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => onEnable(terminal),
			hide: terminal.status !== "DISABLED",
		},
		{
			title: t("modules.terminal.action.disable_master.title"),
			action: () => onDisableMaster(terminal),
			hide: !(terminal.master && (isAdmin || organization.support)),
		},
		{
			title: t("modules.terminal.action.enable_master.title"),
			action: () => onEnableMaster(terminal),
			hide: !(!terminal.master && (isAdmin || organization.support)),
		},
		{
			title: t("modules.terminal.action.release_bills.title"),
			action: () => onRelease(terminal),
			hide: !(isAdmin || organization.support),
		},
		{
			title: t("modules.terminal.action.export_database.title"),
			action: () => onExportDatabase(terminal),
			hide: !(isAdmin || organization.support),
		},
		{
			title: t("modules.terminal.action.send_diagnostic_report.title"),
			action: () => sendDiagnosticReport(),
			hide: !isAdmin,
		},
		{
			title: t("modules.terminal.action.change_type.title"),
			action: changeTerminalType,
			hide: terminal.type !== "POS" && terminal.type !== "POS_MOBILE" && terminal.type !== "POS_WORK_TIME",
		},
		{
			title: t("modules.terminal.action.administer.title"),
			action: () => setShowTerminalAdministerModal(true),
			hide: !(isAdmin || organization.support),
		},
	];

	const renderTitle = () => (
		<>
			{`${t("common.word.terminal")} "${terminal.name}"`}
			<FormatResourceStatus status={terminal.status} />{" "}
			{terminal.master && (
				<span className={"badge bg-primary me-1"}>{t("modules.terminal.field.master.title")}</span>
			)}
		</>
	);

	return (
		<>
			<FormDirty onSubmit={onSubmit} formState={formState}>
				<MobileActions actions={mobileActions} />
				<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
				<InputGroup>
					<InputGroup className={"form-input-group w-100"}>
						<FormInput
							placeholder={terminal.name}
							label={t("modules.terminal.field.teminal_name_on_web.title")}
							name={"web_name"}
							errors={errors}
							register={register}
						/>
						<div className="input-group-append">
							<ButtonLoading loading={isClearingName} onClick={() => clearName()} variant={"primary"}>
								{t("common.word.restore_default")}
							</ButtonLoading>
						</div>
					</InputGroup>
				</InputGroup>
				<Form.Group className="form-group">
					<ButtonLoading loading={loading} variant="primary" type="submit">
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					{formState.isDirty && (
						<Button
							variant="light"
							type="button"
							onClick={() => history.push(`/${organization.id}/settings/terminals`)}
						>
							{t("common.action.cancel", { ns: "lib" })}
						</Button>
					)}
				</Form.Group>
				<Card className="mb-3">
					<Card.Header>{terminal.name}</Card.Header>
					<Card.Body>
						<div className="row row-cols-2">
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("common.word.name", { ns: "lib" })}</dt>
									<dd>{terminal.name}</dd>
								</dl>

								<dl className="mb-0">
									<dt>{t("lib:common.word.source")}</dt>
									<dd>{terminal.type}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("modules.terminal.field.app_version.title")}</dt>
									<dd>{getTerminalVersion(terminal)}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("common.word.created_at", { ns: "lib" })}</dt>
									<dd>{FormatDate(terminal.created_at)}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("modules.terminal.field.updated_at.title")}</dt>
									<dd>{FormatDate(terminal.updated_at)}</dd>
								</dl>
							</div>
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("modules.terminal.field.terminal_id.title")}</dt>
									<dd>{terminal.terminal_id}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("modules.terminal.field.uid.title")}</dt>
									<dd>{terminal.terminal_unique_id}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("common.word.status", { ns: "lib" })}</dt>
									<dd>
										<FormatResourceStatus status={terminal.status} />
									</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("lib:common.word.ip_address")}</dt>
									<dd>{terminal.ip_address}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("lib:common.word.mac_address")}</dt>
									<dd>{terminal.mac_address}</dd>
								</dl>
							</div>
						</div>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header>{t("lib:common.word.advanced")}</Card.Header>
					<Card.Body>
						<div className="row row-cols-2">
							{terminal.settings?.map((item, index) => {
								return (
									<div className="col-6" key={index}>
										<dl className="mb-0">
											<dt>{item.name}</dt>
											<dd>{item.setup}</dd>
										</dl>
									</div>
								);
							})}
						</div>
					</Card.Body>
				</Card>
				{terminal.logs?.length > 0 && (
					<Card className="mb-3">
						<Card.Header>{t("modules.terminal.header.request_items.title")}</Card.Header>
						<Card.Body>
							<table className="table">
								<thead>
									<tr>
										<th>{t("common.word.created_at", { ns: "lib" })}</th>
										<th>{t("lib:common.word.type")}</th>
										<th>{t("common.word.value")}</th>
									</tr>
								</thead>
								<tbody>
									{terminal.logs.map((resource, index) => {
										return (
											<tr key={index}>
												<td>{FormatDate(resource.created_at)}</td>
												<td>
													<span className="badge bg-secondary">
														{t(`enums.terminal_settings.type.${resource.type}`)}
													</span>
												</td>
												<td>
													{resource.type === "VERSION_CHANGED" ? (
														<>{resource.value}</>
													) : (
														<FormatResourceStatus status={resource.value} />
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</Card.Body>
					</Card>
				)}
				{terminal.diagnoses.length > 0 && (
					<Card className="mb-3">
						<Card.Header>{t("modules.terminal.field.diagnoses.title")}</Card.Header>
						<Card.Body>
							<TerminalDiagnosesTable
								diagnoses={terminal.diagnoses}
								hasLimitedDiagnosesAmount
								setDiagnoseToShow={setDiagnoseToShow}
							/>
							{terminal.diagnoses.length > 10 && (
								<Button
									variant="light"
									className="w-100"
									onClick={() => setShowMoreDiagnosesModal(true)}
								>
									{t("common.action.show_more", { ns: "lib" })}
								</Button>
							)}
						</Card.Body>
					</Card>
				)}
			</FormDirty>
			{diagnoseToShow && (
				<TerminalDiagnoseModal
					isShown={!!diagnoseToShow}
					onHide={() => setDiagnoseToShow(undefined)}
					diagnose={diagnoseToShow}
				/>
			)}
			{showMoreDiagnosesModal && (
				<TerminalDiagnosesModal
					isShown={showMoreDiagnosesModal}
					onHide={() => setShowMoreDiagnosesModal(false)}
					diagnoses={terminal.diagnoses}
					setDiagnoseToShow={onShowDiagnoseModal}
				/>
			)}
			{showTerminalAdministerModal && (
				<TerminalAdministerModal
					isShown={showTerminalAdministerModal}
					terminal={terminal}
					handleOnSuccess={setTerminal}
					onHide={() => setShowTerminalAdministerModal(false)}
				/>
			)}
			{showTerminalTypeModal && (
				<ChangeTerminalTypeModal
					terminal={terminal}
					onHide={() => setShowTerminalTypeModal(false)}
					onRefresh={onFetchTerminal}
				/>
			)}
		</>
	);
};
export default OrganizationSettingsTerminalsDetailsPage;

// t("enums.terminal_settings.type.VERSION_CHANGED");
// t("enums.terminal_settings.type.STATUS_CHANGED");
