import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { VenueSettingsApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import VenueSettingsForm from "./components/VenueSettingsForm";

const OrganizationSettingsInfoVenueSettingsPage: FC<RouteComponentProps> = () => {
	const [venueSettings, setVenueSettings] = useState<VenueSettingsApi | undefined>();
	const [barcodeSettings, setBarcodeSettings] = useState<undefined | string[]>(undefined);
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	const fetchVenueSettings = () => {
		return api.organization().getVenueSettings();
	};

	const fetchVenueBarcodes = () => {
		return api.organization().getVenueSettingsWeightBarcodes();
	};

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.point_of_sale.title"));
		Promise.all([fetchVenueSettings(), fetchVenueBarcodes()])
			.then((resp) => {
				setVenueSettings(resp[0]);
				setBarcodeSettings(resp[1]);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	if (!venueSettings || !barcodeSettings) {
		return <LoadingContainer />;
	}
	return <VenueSettingsForm barcodeSettings={barcodeSettings} settings={venueSettings} />;
};
export default OrganizationSettingsInfoVenueSettingsPage;
