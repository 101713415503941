import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { apiSecurity } from "go-security/services/Api/api";
import { OrganizationRoleApi, OrganizationUserSecurityApi } from "go-security/services/Api/typesSecurity";

interface Props {
	user: OrganizationUserSecurityApi;
	onHide: () => void;
	handleSave: (user: OrganizationUserSecurityApi) => void;
}

const SecurityUserEditModalFormComponent = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<OrganizationUserSecurityApi>({
		criteriaMode: "all",
		defaultValues: props.user,
	});
	const [roles, setRoles] = useState<OrganizationRoleApi[]>([]);
	const {
		handleSubmit,
		formState: { errors },
		control,
		formState,
		setError,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const onSubmit = handleSubmit(async (data: OrganizationUserSecurityApi) => {
		setLoading(true);
		try {
			const res = await apiSecurity.organization().updateUser(props.user.email, data);
			props.handleSave(res.data.data);
			addSuccessFlash(t("lib:common.flash.saved"));
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	useEffect(() => {
		apiSecurity
			.organization()
			.getRoles({ include: "permissions", size: 100 })
			.then((response: AxiosResponse) => {
				setRoles(response.data.data);
			})
			.catch((err: Exception | unknown) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	const getRoles = () => {
		return roles.map((role) => {
			return {
				label: role.name,
				value: role.uid,
			};
		});
	};
	return (
		<Modal show={!!props.user} onHide={props.onHide}>
			<FormDirty formState={formState} key="organization-role-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<>
						{t("lib:go_component.user.edit.title")} "{props.user.email}"
					</>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						label={t("lib:go_component.user.list.table.columns.role")}
						name="role_id"
						errors={errors}
						control={control}
						options={getRoles()}
						data-testid="role_id"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={props.onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default SecurityUserEditModalFormComponent;
