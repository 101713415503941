import { useEffect } from "react";
import { CustomValidationConfig } from "../../services/types";
import { validate } from "./validate";

export const useCustomValidation = <T = void>({
	name,
	value,
	customValidationConfig,
}: {
	name: string;
	value: string | number | undefined | null;
	customValidationConfig?: CustomValidationConfig<T>;
}): void => {
	useEffect(() => {
		if (!customValidationConfig?.types.length || !name) {
			return;
		}

		const { types, setErrors } = customValidationConfig;

		types.forEach((validationType) => {
			const isValid = validate(validationType, value);

			if (!isValid) {
				setErrors((errors) => {
					const errorMessage = validationType.customErrorMessage ?? validationType.constraint;

					return [
						...errors,
						{
							name,
							options: {
								type: validationType.constraint,
								types: { [errorMessage]: true },
							},
						},
					];
				});
			} else {
				setErrors((errors) => errors.filter((error) => error.name !== name));
			}
		});

		return () => {
			setErrors((errors) => errors.filter((error) => error.name !== name));
		};
	}, [customValidationConfig, name, value]);
};
