import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import LoginForm from "go-security/components/LoginForm";
import LoginSecurityLayout from "go-security/components/LoginSecurityLayout";

interface Props extends RouteComponentProps {
	baseUrl: string;
	displayUseOldGoposVersionText?: boolean;
}

const SecurityLoginPage: FC<Props> = (props) => {
	const { t } = useTranslation();
	return (
		<LoginSecurityLayout>
			<div className="form-login-header">
				<span className="ms-auto">{t("lib:go_security.register.field.no_account_yet.title")}</span>
				<Link to="/register" className="btn btn-primary-light">
					{t("lib:go_security.register.header.title.create_account")}
				</Link>
			</div>
			<div className="form-login">
				<LoginForm
					location={props.location}
					baseUrl={props.baseUrl}
					displayUseOldGoposVersionText={props.displayUseOldGoposVersionText}
				/>
			</div>
		</LoginSecurityLayout>
	);
};

export default SecurityLoginPage;
