import DOMPurify from "dompurify";
import { Card } from "react-bootstrap";
import { LoadingContainer } from "go-core/components/Loading";
import { PreviewNotificationTemplateApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	notificationPreviewData?: PreviewNotificationTemplateApi;
	renderPreviewLoading: boolean;
}

export const NotificationTemplatePreviewRenderer = ({ notificationPreviewData, renderPreviewLoading }: Props) => {
	if (renderPreviewLoading) return <LoadingContainer />;
	if (!notificationPreviewData || !notificationPreviewData.title || !notificationPreviewData.content) return null;

	const sanitizedTitle = DOMPurify.sanitize(notificationPreviewData.title);
	const sanitizedContent = DOMPurify.sanitize(notificationPreviewData.content);

	return (
		<Card>
			<Card.Body>
				<div dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
				<div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
			</Card.Body>
		</Card>
	);
};
