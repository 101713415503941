import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as CircleInfoSVG } from "go-core/images/svg/circle-info.svg";

export interface EmptyListConfig {
	title?: string;
	description?: string;
	styles?: Record<string, any>;
	actions?: EmptyListConfigAction[];
}

export interface EmptyListConfigAction {
	name: string;
	click: () => void;
	variant?: string;
}

interface Props {
	title?: string;
	description?: string;
	actions?: EmptyListConfigAction[];
}

const EmptyList: FC<Props> = ({ title, description, actions }) => {
	const { t } = useTranslation();
	if (!title && !description && !actions) {
		return (
			<div className="list-empty">
				<CircleInfoSVG />
				<h6>{t("lib:go_core.empty_list_data.title")}</h6>
			</div>
		);
	}

	return (
		<div className="list-empty">
			<CircleInfoSVG />
			{title && <h6>{title}</h6>}
			{description && <span className="description">{description}</span>}
			{actions && (
				<div className="list-empty-actions">
					{actions?.map((action, index) => {
						return (
							<Button
								key={index}
								variant={action.variant ? action.variant : "add"}
								onClick={() => action.click()}
							>
								{action.name}
							</Button>
						);
					})}
				</div>
			)}
		</div>
	);
};
export default EmptyList;
