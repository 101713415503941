import { MouseEventHandler } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
} from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	item: ItemFormApi;
}

const ItemGroupGroupEditingVirtualTableLimitPointOfSaleRestrictionInput = ({ form, item }: Props) => {
	const {
		register,
		formState: { errors },
		setValue,
		getValues,
	} = form;
	const { t } = useTranslation();

	const handleClick: MouseEventHandler<HTMLInputElement> = (event) => {
		if (event.currentTarget?.checked) {
			setValue(`items.${item.index}.direction_id`, undefined);
			setValue(`items.${item.index}.direction`, undefined);
		}
		const pointsOfSale = getValues(`items.${item.index}.points_of_sale`);
		pointsOfSale.forEach((_, index) => {
			setValue(`items.${item.index}.points_of_sale.${index}.visibility`, !!event.currentTarget?.checked);
		});
	};

	return (
		<FormCheck
			name={`items.${item.index}.point_of_sale_visible`}
			errors={errors}
			register={register}
			label={t("common.action.adjust")}
			type="switch"
			onClick={handleClick}
		/>
	);
};

export default ItemGroupGroupEditingVirtualTableLimitPointOfSaleRestrictionInput;
