import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { useWindowSize } from "go-core/components/useWindowSize";
import { DashboardNewPage } from "../../../../../../components/Dashboard";
import { DashboardType } from "../../../../../../components/Dashboard/services/types";

export const UserDashboardPage = (): JSX.Element => {
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.dashboard.header.title"));
	}, []);

	return (
		<div className="page-container">
			{isMobile && <SubNavigationItems title={t("modules.dashboard.header.title")} items={[]} />}
			<DashboardNewPage dashboardType={DashboardType.GROUP_ORGANIZATIONS} />
		</div>
	);
};
