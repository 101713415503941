import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { SubReportApi } from "go-report/core/services/types";
import { DashboardChartConfig } from "../services/charts/types";
import { DashboardAggregateType, DashboardReportType } from "../services/types";
import useDashboardContext from "../services/useDashboardContext";

export const useDashboardReportAggregate = (reportType: DashboardReportType): Record<string, any> => {
	const { state } = useDashboardContext();
	const reportConfigName = `report${reportType}`;
	const reportConfig = state.chartsConfig[reportConfigName as keyof DashboardChartConfig];
	const reportAggregateType = reportConfig.aggregate as DashboardAggregateType;
	const isProductOrCategoryBox =
		reportType === DashboardReportType.PRODUCT || reportType === DashboardReportType.CATEGORY;

	const getAverageValueForProductOrCategoryBox = (moneyValue: MoneyApi, quantity: number): MoneyApi => {
		const { amount: totalMoneyValue, currency } = moneyValue;
		const averageValue = quantity !== 0 ? totalMoneyValue / quantity : 0;
		const averageValueToFixed = averageValue.toFixed(2);
		const averageValueToFixedAsNumber = Number(averageValueToFixed);

		return {
			amount: averageValueToFixedAsNumber,
			currency,
		};
	};

	const getValueSuitableToSortMethod = (aValue: number, bValue: number): -1 | 0 | 1 => {
		if (aValue > bValue) return -1;
		else if (aValue < bValue) return 1;
		return 0;
	};

	const getSubReportFilterCondition = () => {
		switch (reportAggregateType) {
			case DashboardAggregateType.AVERAGE_MONEY:
				return (report: SubReportApi) => {
					if (isProductOrCategoryBox)
						return (
							report.aggregate?.sales?.total_money?.amount !== undefined &&
							report.aggregate?.sales?.transaction_count !== undefined
						);

					return report.aggregate?.sales?.average_money.amount !== undefined;
				};
			case DashboardAggregateType.TOTAL_MONEY:
				return (report: SubReportApi) => report.aggregate?.sales?.total_money.amount !== undefined;
			case DashboardAggregateType.TRNSACTION_COUNT:
				return (report: SubReportApi) => {
					return isProductOrCategoryBox
						? report.aggregate?.sales?.product_quantity !== undefined
						: report.aggregate?.sales?.transaction_count !== undefined;
				};
		}
	};

	const getAggregateValue = (item: SubReportApi, formatted = false) => {
		switch (reportAggregateType) {
			case DashboardAggregateType.TRNSACTION_COUNT:
				return isProductOrCategoryBox
					? item.aggregate?.sales?.product_quantity
					: item.aggregate?.sales?.transaction_count;
			case DashboardAggregateType.AVERAGE_MONEY: {
				let averageMoneyValue: MoneyApi = item.aggregate?.sales?.average_money;

				if (isProductOrCategoryBox) {
					averageMoneyValue = getAverageValueForProductOrCategoryBox(
						item.aggregate?.sales?.total_money,
						item.aggregate?.sales?.product_quantity
					);
				}

				return formatted ? FormatMoney(averageMoneyValue) : averageMoneyValue.amount;
			}
			case DashboardAggregateType.TOTAL_MONEY:
			default:
				return formatted
					? FormatMoney(item.aggregate?.sales?.total_money)
					: item.aggregate?.sales?.total_money.amount;
		}
	};

	const sortSubReports = () => {
		switch (reportAggregateType) {
			case DashboardAggregateType.AVERAGE_MONEY:
				return (a: SubReportApi, b: SubReportApi) => {
					if (isProductOrCategoryBox) {
						const currentElementAverageValue = getAverageValueForProductOrCategoryBox(
							a.aggregate?.sales?.total_money,
							a.aggregate?.sales?.product_quantity
						);

						const nextElementAverageValue = getAverageValueForProductOrCategoryBox(
							b.aggregate?.sales?.total_money,
							b.aggregate?.sales?.product_quantity
						);

						return getValueSuitableToSortMethod(
							currentElementAverageValue.amount,
							nextElementAverageValue.amount
						);
					}

					return getValueSuitableToSortMethod(
						a.aggregate.sales.average_money.amount,
						b.aggregate.sales.average_money.amount
					);
				};
			case DashboardAggregateType.TOTAL_MONEY:
				return (a: SubReportApi, b: SubReportApi) => {
					return getValueSuitableToSortMethod(
						a.aggregate.sales.total_money.amount,
						b.aggregate.sales.total_money.amount
					);
				};
			case DashboardAggregateType.TRNSACTION_COUNT:
				return (a: SubReportApi, b: SubReportApi) => {
					return isProductOrCategoryBox
						? getValueSuitableToSortMethod(
								a.aggregate.sales.product_quantity,
								b.aggregate.sales.product_quantity
						  )
						: getValueSuitableToSortMethod(
								a.aggregate.sales.transaction_count,
								b.aggregate.sales.transaction_count
						  );
				};
		}
	};

	return { reportAggregateType, getSubReportFilterCondition, getAggregateValue, sortSubReports };
};
