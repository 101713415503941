import { FC } from "react";
import { RoomElementApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	className?: string;
	styles?: Record<string, any>;
	handleCreate?: (roomElement: RoomElementApi) => void;
	type?: string;
	name?: string;
}

const RoomElementRectangle: FC<Props> = ({ children, name, className, styles, handleCreate, type }) => {
	const onCreate = (el: React.MouseEvent<HTMLDivElement>) => {
		const obj = {
			type,
			width: el.currentTarget.offsetWidth,
			height: el.currentTarget.offsetHeight,
			position_top: 50,
			position_left: 50,
			name,
		} as RoomElementApi;
		if (handleCreate) {
			handleCreate(obj);
		}
	};

	return (
		<div
			className={`element-box-RECTANGLE ${className ? className : ""}`}
			style={styles}
			onClick={(el: React.MouseEvent<HTMLDivElement>) => onCreate(el)}
		>
			{children}
		</div>
	);
};
export default RoomElementRectangle;
