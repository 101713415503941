import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { api } from "../../../../../../../services/Api/api";

interface Props {
	show: boolean;
	onHide: () => void;
}

interface RebuildForm {
	INVOICE: boolean;
	TRANSACTION: boolean;
	SALE: boolean;
}

export const RebuildReportModal = ({ show, onHide }: Props) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { addSuccessFlash, addFlash } = useFlash();
	const form = useForm<RebuildForm>();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = form;

	const onSubmit = handleSubmit(async (data: RebuildForm) => {
		setLoading(true);
		try {
			const reportTypesToRebuild = Object.entries(data)
				.filter(([reportType, applyReportType]: [string, boolean]) => applyReportType)
				.map(([reportType]) => reportType)
				.join(",");

			const requestBody: { type: string } = {
				type: reportTypesToRebuild,
			};
			await api.organization().rebuildReport(requestBody);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={show} onHide={onHide}>
			<Form>
				<Modal.Header>
					<Modal.Title>Przebuduj</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormCheck label="INVOICE" name="INVOICE" register={register} errors={errors} />
					<FormCheck label="TRANSACTION" name="TRANSACTION" register={register} errors={errors} />
					<FormCheck label="ORDER" name="ORDER" register={register} errors={errors} />
					<FormCheck label="ITEM" name="ITEM" register={register} errors={errors} />
					<FormCheck label="WORK_TIME" name="WORK_TIME" register={register} errors={errors} />
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} onClick={onSubmit} type="submit">
						{t("modules.report.action.rebuild.title")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
