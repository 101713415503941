import React, { useRef, useState } from "react";
import { TFunction } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { PdfOrientationType } from "go-core/types";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { ListConfigFilter } from "go-list/core/components/Filter/services/types";
import { selectSelectedFiltersFullNames } from "go-list/list/services/selectors";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
	PointOfSaleApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import VirtualTableWrapper from "../../../../../components/VirtualTable/VirtualTableWrapper";
import {
	VirtualTableColumn,
	VirtualTableListSelectedSort,
	VirtualTableWrapperHandle,
} from "../../../../../components/VirtualTable/types";
import {
	getVirtualTableValueByKeyParts,
	getVisibleVirtualTableColumns,
} from "../../../../../components/VirtualTable/utils";
import {
	ItemGroupGroupEditingColumn,
	generateItemGroupsVirtualTableListCsv,
	generateItemGroupsVirtualTableListPdf,
	getInitialItemGroupGroupEditingListDefaultColumns,
	getItemGroupGroupEditingListOtherColumns,
	getParsedItemPointsOfSale,
	getPossibleItemGroupGroupEditingListDefaultColumns,
	itemGroupGroupEditingInclude,
	itemGroupVirtualTableColumnKeys,
} from "../utils";
import ItemGroupGroupEditingVirtualTable from "./ItemGroupGroupEditingVirtualTable";

interface Props {
	items: ItemFormApi[];
	handleUpdate: (res: ItemFormApi[]) => void;
	pointsOfSale: PointOfSaleApi[];
}

const parsePointsOfSaleToColumns = (pointsOfSale: PointOfSaleApi[], t: TFunction) => {
	const columns: VirtualTableColumn[] = [
		{
			id: ItemGroupGroupEditingColumn.POINT_OF_SALE,
			name: t("common.word.point_of_sale"),
		},
	];

	pointsOfSale.forEach((pointOfSale) => {
		columns.push({
			id: pointOfSale.id.toString(),
			name: pointOfSale.name,
		});
	});

	return columns;
};

const ItemGroupGroupEditingVirtualTableForm = ({ items, pointsOfSale, handleUpdate }: Props) => {
	const { t } = useTranslation();
	const tableWrapperRef = useRef<VirtualTableWrapperHandle>(null);
	const form = useForm<ItemGroupGroupEditingFormProps>({
		criteriaMode: "all",
		defaultValues: {
			items,
		},
	});
	const defaultColumns = getInitialItemGroupGroupEditingListDefaultColumns(t);
	const organization = useSelector(selectOrganization);
	const { handleSubmit, reset } = form;
	const [sortings, setSortings] = useState<VirtualTableListSelectedSort[]>([]);
	const pointsOfSaleColumns = parsePointsOfSaleToColumns(pointsOfSale, t);
	const filters = [
		{
			id: "category.name",
			name: t("common.word.item_group_category"),
			type: "list",
			listOptions: Array.from(new Set(items.map((item) => item.category.name))),
			hasDefaultOptions: false,
		},
		{
			id: "item_group.name",
			name: t("common.word.item_group"),
			type: "list",
			listOptions: Array.from(
				new Set(items.map((item) => item.item_group?.name).filter((itemGroup) => itemGroup))
			),
			hasDefaultOptions: false,
		},
		{
			id: "name",
			name: t("common.word.variant"),
			type: "list",
			listOptions: Array.from(new Set(items.map((item) => item.name))).filter(
				(name) => name !== t("common.word.default")
			),
		},
		{
			id: ItemGroupGroupEditingColumn.FULL_NAME,
			name: t("common.word.item_full_name"),
			type: "list",
			listOptions: Array.from(new Set(items.map((item) => item.full_name))),
		},
	] as ListConfigFilter[];

	const filterByFilterIdHandler = (filterId: string, filterItems: any[]) => {
		const selectedFilters = tableWrapperRef?.current?.selectedFilters || [];

		const selectedFiltersIds = selectedFilters.map((selectedFilter) => selectedFilter.filterId);
		if (selectedFiltersIds.includes(filterId)) {
			const filterValues = selectedFilters
				.filter((f) => f.filterId === filterId)[0]
				?.value?.split(FILTER_VALUE_SEPARATOR);

			if (filterValues && filterValues.length > 0) {
				if (filterValues[0] === "u")
					return filterItems.filter(
						(item) =>
							!item[`${filterId}` as keyof ItemFormApi] ||
							item[`${filterId}` as keyof ItemFormApi] === t("common.word.default")
					);
				if (filterValues[0] === "a")
					return filterItems.filter(
						(item) =>
							item[`${filterId}` as keyof ItemFormApi] &&
							item[`${filterId}` as keyof ItemFormApi] !== t("common.word.default")
					);

				const values = filters
					.filter((filter) => filter.id === filterId)[0]
					?.listOptions?.map((option: string, index: number) => {
						if (filterValues.includes(index.toString())) {
							return option;
						}

						return undefined;
					})
					.filter((item: ItemFormApi) => item);

				return filterItems.filter((item) => values.includes(getVirtualTableValueByKeyParts(filterId, item)));
			}
		}
		return filterItems;
	};

	const returnSearchedItems = () => {
		const search = tableWrapperRef?.current?.search;
		if (!search) return returnFilteredItems();

		return returnFilteredItems().filter((item) => {
			const searchToLowerCase = search.toLowerCase();
			if (search === "-") {
				return item.name === t("common.word.default");
			} else if (
				(item?.name?.toLowerCase()?.includes(searchToLowerCase) && item?.name !== t("common.word.default")) ||
				item?.category?.name?.toLowerCase()?.includes(searchToLowerCase) ||
				item?.item_group?.name?.toLowerCase()?.includes(searchToLowerCase) ||
				item?.full_name?.toLowerCase()?.includes(searchToLowerCase)
			)
				return item;
			return false;
		});
	};

	const returnFilteredItems = () => {
		const selectedFilters = tableWrapperRef?.current?.selectedFilters || [];
		if (selectedFilters.length === 0) return items;

		let newItems = [...items];
		if (filterByFilterIdHandler("category.name", newItems))
			newItems = filterByFilterIdHandler("category.name", newItems);
		if (filterByFilterIdHandler("name", newItems)) newItems = filterByFilterIdHandler("name", newItems);
		if (filterByFilterIdHandler(ItemGroupGroupEditingColumn.FULL_NAME, newItems))
			newItems = filterByFilterIdHandler(ItemGroupGroupEditingColumn.FULL_NAME, newItems);
		if (filterByFilterIdHandler("item_group.name", newItems))
			newItems = filterByFilterIdHandler("item_group.name", newItems);
		return newItems;
	};

	const hasItemChanged = (itemFromForm: ItemFormApi) => {
		const initialItem = items.find((item) => item.index === itemFromForm.index);
		if (!initialItem) return false;
		if (!itemFromForm.point_of_sale_visible) itemFromForm.points_of_sale = [];

		if (itemFromForm.points_of_sale.length !== initialItem.points_of_sale.length) return true;

		const hasDifferentPoints = itemFromForm.points_of_sale?.some((pointOfSale) => {
			const initialItemPointOfSale = getParsedItemPointsOfSale(initialItem, pointsOfSale).find(
				(point) => point?.point_of_sale?.id?.toString() === pointOfSale.point_of_sale.id.toString()
			);

			return (
				initialItemPointOfSale?.direction?.id?.toString() !== pointOfSale.direction?.id?.toString() ||
				initialItemPointOfSale?.visibility !== pointOfSale.visibility
			);
		});

		if (!hasDifferentPoints && initialItem.point_of_sale_visible !== itemFromForm.point_of_sale_visible)
			return true;

		return (
			hasDifferentPoints ||
			itemFromForm.availability_id !== initialItem?.availability_id ||
			itemFromForm?.direction?.id !== initialItem?.direction?.id
		);
	};

	const getParsedDataForm = (data: ItemGroupGroupEditingFormProps) => {
		return {
			items: data.items
				.filter((item) => hasItemChanged(item))
				.map((item) => {
					if (item.direction?.id) {
						item.points_of_sale = [];
					} else if (!item.point_of_sale_visible) {
						item.points_of_sale = [];
					} else {
						if (item.points_of_sale.length === 0) {
							item.points_of_sale = getParsedItemPointsOfSale(item, pointsOfSale);
						} else {
							item.points_of_sale = (item.points_of_sale || []).map((point) => ({
								...point,
								visibility: point.visibility ? "VISIBLE" : "HIDDEN",
							}));
						}
						item.direction = undefined;
					}

					return item;
				}),
		};
	};

	const onSubmit = handleSubmit(async (data: ItemGroupGroupEditingFormProps) => {
		const params: Record<string, any> = { page: "0", size: "0", include: itemGroupGroupEditingInclude };
		await apiOrganization.updateItems(getParsedDataForm(data));
		const res = await apiOrganization.getItems(params);
		const parsedResponse = res.map((item, index) => {
			const newItem: ItemFormApi = {
				...item,
				full_name: item.name,
				name:
					item.name !== item.item_group?.name
						? item.name.replace(item.item_group?.name || "", "").trim()
						: t("common.word.default"),
				index,
				point_of_sale_visible: item.points_of_sale.length > 0,
			};
			newItem.points_of_sale = getParsedItemPointsOfSale(newItem, pointsOfSale);

			return newItem;
		});

		handleUpdate(parsedResponse);

		reset({
			items: [...parsedResponse],
		});
	});

	const handleGeneratePdf = (orientation?: PdfOrientationType, fontSize?: string) => {
		generateItemGroupsVirtualTableListPdf(
			returnSearchedItems(),
			getVisiblePointsOfSaleColumns().filter((column) => column.id !== ItemGroupGroupEditingColumn.POINT_OF_SALE),
			[
				{
					id: "others",
					columns: getVisibleVirtualTableColumns(
						[...getItemGroupGroupEditingListOtherColumns(t)],
						tableWrapperRef?.current?.selectedColumns || []
					),
				},
			],
			tableWrapperRef?.current?.visibleDefaultColumns || [],
			{
				filename: t("modules.item_translation.header.title"),
				title: t("modules.item_translation.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
				pdfOrientation: orientation,
				pdfFontSize: fontSize,
			},
			selectSelectedFiltersFullNames(tableWrapperRef?.current?.selectedFilters || [], filters),
			t,
			[...itemGroupVirtualTableColumnKeys]
		);
	};

	const handleGenerateCsv = () => {
		generateItemGroupsVirtualTableListCsv(
			tableWrapperRef?.current?.visibleDefaultColumns || [],
			getVisiblePointsOfSaleColumns().filter((column) => column.id !== ItemGroupGroupEditingColumn.POINT_OF_SALE),
			{
				filename: t("modules.item_group_group_editing.header.title"),
				title: t("modules.item_group_group_editing.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			},
			returnSearchedItems(),
			[
				{
					id: "others",
					columns: getVisibleVirtualTableColumns(
						[...getItemGroupGroupEditingListOtherColumns(t)],
						tableWrapperRef?.current?.selectedColumns || []
					),
				},
			],
			[...itemGroupVirtualTableColumnKeys]
		);
	};

	const getVisiblePointsOfSaleColumns = () => {
		const selectedColumns = tableWrapperRef?.current?.selectedColumns || [];
		return getVisibleVirtualTableColumns(parsePointsOfSaleToColumns(pointsOfSale, t), selectedColumns);
	};

	return (
		<VirtualTableWrapper
			defaultColumns={defaultColumns}
			additionalColumns={[
				{ id: "point_of_sales", columns: [...pointsOfSaleColumns] },
				{
					id: "others",
					columns: [...getItemGroupGroupEditingListOtherColumns(t)],
				},
			]}
			allPossibleDefaultColumns={getPossibleItemGroupGroupEditingListDefaultColumns(t)}
			segmentType="ITEM_GROUP_GROUP_EDITING"
			filters={filters}
			items={returnSearchedItems()}
			form={form}
			emptyListDescription={t("modules.item_group_group_editing.field.empty_list_description.title")}
			ref={tableWrapperRef}
			initialItems={items}
			sortings={sortings}
			setSortings={setSortings}
			handleSubmit={onSubmit}
			handleGeneratePdf={handleGeneratePdf}
			handleGenerateCsv={handleGenerateCsv}
			exportConfig={{
				filename: t("modules.item_group_group_editing.header.title"),
				title: t("modules.item_group_group_editing.header.title"),
				organization: organization.name,
				taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
				company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
				pdfOrientation: organization?.more?.pdf_orientation,
				pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			}}
		>
			<AutoSizer>
				{({ height, width }) => (
					<ItemGroupGroupEditingVirtualTable
						width={width}
						height={height}
						heightTr={38}
						items={returnSearchedItems()}
						form={form}
						visibleDefaultColumns={tableWrapperRef?.current?.visibleDefaultColumns || []}
						sortings={sortings}
						setSortings={setSortings}
						visiblePointsOfSale={getVisiblePointsOfSaleColumns()}
						visibleOtherColumns={getVisibleVirtualTableColumns(
							getItemGroupGroupEditingListOtherColumns(t),
							tableWrapperRef?.current?.selectedColumns || []
						)}
						pointsOfSale={pointsOfSale}
					/>
				)}
			</AutoSizer>
		</VirtualTableWrapper>
	);
};

export default ItemGroupGroupEditingVirtualTableForm;
