import React from "react";
import DashboardReportCategory from "../Reports/DashboardReportCategory";
import DashboardReportPayment from "../Reports/DashboardReportPayment";
import DashboardReportProduct from "../Reports/DashboardReportProduct";
import DashboardReportSales from "../Reports/DashboardReportSales";
import DashboardReportSalesAdvanced from "../Reports/DashbordReportSalesAdvanced";

const DashboardExtendedTab = () => {
	return (
		<>
			<DashboardReportSalesAdvanced />
			<div className="card-columns">
				<DashboardReportPayment />
				<DashboardReportProduct />
				<DashboardReportCategory />
				<DashboardReportSales />
			</div>
		</>
	);
};

export default DashboardExtendedTab;
