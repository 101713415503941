import React, { FC, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormInput, registerObject } from "go-form";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConstraint, CustomValidationError, FormCustomValidationConfigs } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../images/svg/remove.svg";
import { DiscountApi, DiscountEntityApi } from "../../../../../../../../../../services/Api/Organization/types";
import MenuGridPageItemModal from "../../../Menus/components/MenuPageItemModal";
import { getEntityDiscountTypes } from "./DiscountForm";

interface Props {
	form: UseFormReturn<DiscountApi>;
	fields: FieldArrayWithId<DiscountApi, "entities", "key">[];
	handleRemove: (index: number) => void;
	handleAppend: (item: DiscountEntityApi) => void;
	selectedDiscountType: string;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<DiscountApi>[]>>;
	isVisibleControl?: boolean;
	setIsVisibleControl?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DiscountFormItemsTable: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
		setValue,
		register,
		watch,
	} = props.form;
	const fields = props.fields;
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const [itemsTableVisible] = useState(fields.length > 0);
	const [showModal, setShowModal] = useState<boolean>(false);
	const isMobile = useWindowSize().isMobile;

	const handleSave = (item: any) => {
		setShowModal(false);
		const discountEntity = {
			entity_id: item.context_id,
			type: item.context_type,
			discount_type: props.selectedDiscountType,
			entity: item.entity,
			parent_id: item.entity.parent_id,
			parent_name: item.entity.parent_name,
		};
		props.handleAppend(discountEntity as DiscountEntityApi);
	};

	useEffect(() => {
		fields.forEach((field: DiscountEntityApi, index: number) => {
			registerObject(register, `entities.${index}`, ["entity_id", "discount_type", "type", "volume"]);
			registerObject(register, `entities.${index}.entity`, ["id", "name"]);
			setValue(`entities.${index}`, field);
		});
	}, [fields]);

	const itemsValidationConfigs = useMemo(
		() =>
			fields.map(() => ({
				volume: {
					types: [
						{
							constraint: CustomValidationConstraint.REQUIRED,
						},
					],
					setErrors: props.setErrors,
				},
			})),
		[props.setErrors, fields]
	) satisfies FormCustomValidationConfigs[];

	return (
		<LimitComponentVisibility
			classNames={"mb-3"}
			defaultVisible={itemsTableVisible}
			handleChange={(val) => val === false && setValue("entities", [], { shouldDirty: true })}
			id={"item_discount"}
			helpText={t("modules.discount.field.legend.helptext.description")}
			legendText={t("modules.discount.field.legend.title")}
			isVisibleControl={props.isVisibleControl}
			setIsVisibleControl={props.setIsVisibleControl}
			unlimitedContent={
				<>
					<div className={`${isMobile ? "table-responsive" : ""}`}>
						<table className="table table-form">
							<thead>
								<tr>
									<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-50">
										{t("modules.discount.field.item_entity.title")}
									</th>
									<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-25">{`${t(
										"common.word.value"
									)} *`}</th>
									<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-25">
										{t("common.word.discount_type")}
									</th>
									<th className="action" />
								</tr>
							</thead>
							<tbody>
								{fields.map((item: DiscountEntityApi, index: number) => {
									return (
										<tr key={fields[index].key}>
											<td>
												<FormInput
													type="hidden"
													register={register}
													errors={errors}
													name={`entities.${index}.id`}
												/>
												<FormInput
													type="hidden"
													errors={errors}
													register={register}
													name={`entities.${index}.type`}
												/>
												{item.type === "CATEGORY" && `${t("common.word.category")}: `}
												{item.type === "ITEM" &&
													`${t("common.word.item_group_variant")} ${item.parent_name}: `}
												{item.type === "ITEM_GROUP" && `${t("common.word.item_group")}: `}
												{item.type === "TAX" && `${t("modules.discount.field.tax.title")}: `}
												<strong>{item.entity.name}</strong>
											</td>
											<td>
												<FormMoneyInput
													currency={currency}
													suffix={
														watch(`entities.${index}.discount_type`) === "AMOUNT" ||
														watch(`entities.${index}.discount_type`) === "FIXED_PRICE"
															? currency
															: "%"
													}
													control={control}
													decimalScale={
														watch(`entities.${index}.discount_type`) === "AMOUNT" ? 2 : 0
													}
													name={`entities.${index}.volume`}
													errors={errors}
													customValidationConfig={itemsValidationConfigs[index].volume}
												/>
											</td>
											<td>
												<FormSelectGroup
													name={`entities.${index}.discount_type`}
													placeholder={t("modules.discount.field.type_placeholder.title")}
													errors={errors}
													defaultValue={item.discount_type}
													getOptionLabel={(option) =>
														t(`enums.discounts.type.${option.label}`)
													}
													options={getEntityDiscountTypes(t)}
													control={control}
													data-testid={`entities.${index}.discount_type`}
												/>
											</td>
											<td className="action">
												<RemoveSVG className="icon" onClick={() => props.handleRemove(index)} />
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<Button variant="add" type="button" onClick={() => setShowModal(true)}>{`+ ${t(
						"common.action.add",
						{ ns: "lib" }
					)}`}</Button>
					{showModal && (
						<MenuGridPageItemModal
							selectedValues={fields.map((x: DiscountEntityApi) => {
								return {
									id: x.entity_id,
									type: x.type,
								};
							})}
							contextTypes={["CATEGORY", "ITEM_GROUP", "ITEM", "TAX"]}
							handleClose={() => setShowModal(false)}
							handleSave={handleSave}
						/>
					)}
				</>
			}
		/>
	);
};
export default DiscountFormItemsTable;
