import React, { useEffect, useState } from "react";
import { ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { registerObject } from "go-form";
import { generateLabel } from "go-app/components/ImageRenderer/ListImageName";
import { selectOrganization } from "go-security/services/organizations/selectors";
import PreviewAction from "../../../../../../../../../../components/Common/CommonActions/PreviewAction/PreviewAction";
import RemoveAction from "../../../../../../../../../../components/Common/CommonActions/RemoveAction/RemoveAction";
import { ReactComponent as PlusSVG } from "../../../../../../../../../../images/svg/menu/plus.svg";
import { ReactComponent as MoreSvg } from "../../../../../../../../../../images/svg/more.svg";
import { MenuApi, MenuItemApi } from "../../../../../../../../../../services/Api/Organization/types";
import CategoryItemGroupsModal from "../CategoryItemGroupsModal";
import MenuPageItemModal from "../MenuPageItemModal";

interface Props {
	item: MenuItemApi;
	index: number;
	pageIndex: number;
	form: UseFormReturn<MenuApi>;
	menuId: number;
	handleUpdateItem: (item: MenuItemApi) => void;
	handleRemoveItem: (index: number) => void;
	selectedValues?: Array<string>;
	currentMenuItems?: MenuItemApi[];
	defaultType: string;
}

const MenuGridPageItem = (props: Props): JSX.Element => {
	const { register, setValue } = props.form;
	const [showModal, setShowModal] = useState(false);
	const [showProducts, setShowProducts] = useState<MenuItemApi | undefined>(undefined);
	const prefix = `pages[${props.pageIndex}].items.[${props.index}]`;
	const [item, setItem] = useState(props.item);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const registerItem = (itemToBeRegistered: MenuItemApi) => {
		registerObject(register, prefix, ["context_id", "context_type", "position"]);
		registerObject(register, `${prefix}.entity`, ["name", "image_link", "parent_id", "parent_name"]);
		setValue(`pages.${props.pageIndex}.items.${itemToBeRegistered.position}`, itemToBeRegistered);
	};
	useEffect(() => {
		const changedItem = props.item;
		if (changedItem && changedItem.context_id) {
			registerItem(changedItem);
		}
	}, []);

	useEffect(() => {
		setItem(props.item);
		const changedItem = props.item;
		if (changedItem && changedItem.context_id) {
			registerItem(changedItem);
		}
	}, [props.item, props.menuId, props.item.context_id]);

	if (props.item.context_id) {
		registerItem(props.item);
	}

	const handleSave = (newItem: MenuItemApi) => {
		newItem.position = props.index;
		registerItem(item);
		setShowModal(false);
		setItem(newItem);
		setValue(`pages.${props.pageIndex}.items.${props.index}`, item, { shouldDirty: true });
		props.handleUpdateItem(newItem);
	};

	const handleUpdate = (newItem: MenuItemApi) => {
		setValue(`pages.${props.pageIndex}.items.${props.index}`, item, { shouldDirty: true });
		setShowModal(false);
		newItem.position = props.index;
		setItem(newItem);
		props.handleUpdateItem(newItem);
	};

	const handleRemove = () => {
		setValue(`pages.${props.pageIndex}.items.${props.index}`, {} as MenuItemApi, { shouldDirty: true });
		setItem({} as MenuItemApi);
		props.handleRemoveItem(props.index);
	};

	const redirectToPage = (menuItem: MenuItemApi) => {
		switch (menuItem.context_type) {
			case "CATEGORY":
				window.open(`/${organization.id}/menu/categories/${menuItem.context_id}`);
				break;
			case "ITEM_GROUP":
				window.open(`/${organization.id}/menu/item_groups/${menuItem.context_id}`);
				break;
			case "ITEM":
				window.open(`/${organization.id}/menu/item_groups/${menuItem.entity.parent_id}`);
				break;
		}
	};

	const handleShowProducts = (menuItem: MenuItemApi) => {
		setShowProducts(menuItem);
	};

	const showColor = item.entity?.color && !item.entity?.image_link;
	return (
		<>
			{item?.context_id ? (
				<Card
					id={`menu-item-card-${props.menuId}-${JSON.stringify(item)}`}
					className={`menu-item-card ${item?.entity?.status === "ENABLED" ? "" : "menu-item-card--disabled"}`}
					onClick={(event) => {
						if (
							(event.target as HTMLElement)?.nodeName === "rect" ||
							(event.target as HTMLElement)?.nodeName === "path"
						)
							return;
						setShowModal(Boolean(item));
					}}
				>
					<Card.Body draggable={false} style={{ background: showColor ? item.entity.color : "transparent" }}>
						{item.entity.image_link ? (
							<img draggable={false} src={item.entity.image_link.small} alt={item.entity.name} />
						) : (
							<div draggable={false} className="circle-wrapper">
								<div
									draggable={false}
									className={`circle circle-${
										item.entity.color ? item.entity.color.slice(1) : "f8f9fa"
									}`}
								>
									<span className="circle-text">
										{generateLabel(item.entity.label ? item.entity.label : item.entity.name)}
									</span>
								</div>
							</div>
						)}
						<Dropdown as={ButtonGroup} className="item-context-menu">
							<Dropdown.Toggle draggable={false} as={MoreSvg}>
								{t("common.action.more", { ns: "lib" })}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={(e) => {
										e.stopPropagation();
										redirectToPage(item);
									}}
								>
									<PreviewAction />
								</Dropdown.Item>
								{item.context_type === "CATEGORY" && (
									<Dropdown.Item
										onClick={(e) => {
											e.stopPropagation();
											handleShowProducts(item);
										}}
									>
										<PreviewAction name={t("common.action.show_products")} />
									</Dropdown.Item>
								)}
								<Dropdown.Item
									onClick={(e) => {
										e.stopPropagation();
										handleRemove();
									}}
								>
									<RemoveAction />
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Card.Body>
					<Card.Footer draggable={false}>
						<span>{item?.entity?.name}</span>
					</Card.Footer>
				</Card>
			) : (
				<Card className="menu-item-card striped">
					<Card.Body className="empty-item" onClick={() => setShowModal(true)}>
						<PlusSVG />
					</Card.Body>
				</Card>
			)}
			{showModal && (
				<MenuPageItemModal
					item={item}
					defaultType={props.defaultType}
					handleSave={handleSave}
					handleUpdate={handleUpdate}
					handleClose={() => setShowModal(false)}
					hasMultipleChoice={false}
					currentMenuItems={props.currentMenuItems}
				/>
			)}
			{showProducts && <CategoryItemGroupsModal onHide={() => setShowProducts(undefined)} item={showProducts} />}
		</>
	);
};
export default MenuGridPageItem;
