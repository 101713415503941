import { MultiActionList, MultiActionListProps } from "../../actionsLists/MultiActionList";
import { ClearButton, ClearButtonProps } from "../ClearButton";
import { SelectedItemsSummary, SelectedItemsSummaryProps } from "./SelectedItemsSummary";

export interface StickyMultiActionsBarProps extends MultiActionListProps, SelectedItemsSummaryProps, ClearButtonProps {
	numberOfColumns: number;
	widthOfTheLastCell?: number;
}

export const StickyMultiActionsBar = ({
	numberOfColumns,
	widthOfTheLastCell,
	...props
}: StickyMultiActionsBarProps): JSX.Element => {
	const stylesWithOffset = props.isButtonSticky && widthOfTheLastCell ? { gap: `${widthOfTheLastCell - 32}px` } : {};

	return (
		<td className="sticky-selected-summary-cell" colSpan={numberOfColumns + 2}>
			<div className="sticky-selected-summary-container">
				<div className="sticky-selected-summary-text-container">
					<SelectedItemsSummary {...props} />
				</div>

				<div className="sticky-selected-summary-button-container" style={stylesWithOffset}>
					<ClearButton {...props} />
					<MultiActionList {...props} />
				</div>
			</div>
		</td>
	);
};
