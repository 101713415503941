import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavigationHelpComponent, { HelpItem } from "go-app/components/Navigation/NavigationHelpComponent";
import { ReactComponent as AngelDoubleRight } from "go-core/images/svg/navigation/angle-double-right.svg";
import { GoSuiteApp, MenuAppConfig } from "../../services/types";
import NavigationApplicationsComponent from "./NavigationApplicationsComponent";
import { NavigationContext } from "./NavigationContext";
import NavigationProfileComponent from "./NavigationProfileComponent";

interface Props {
	logo: string;
	logo_small: string;
	isAdmin?: boolean;
	accessServiceRouteLink?: string;
	apps: GoSuiteApp[];
	apps_link_show_all: string;
	apps_fetch_installed: () => Promise<string[]>;
	company_id: string | undefined;
	profile_name: string | undefined;
	profile_avatar: string | undefined;
	organization_name: string | undefined;
	help_options?: HelpItem[];
	profile_content: JSX.Element;
	organization: Record<string, any>;
	fetchMenuApplication?: (companyId: string, params?: Record<string, any>) => Record<string, any>;
	menuAppConfig: MenuAppConfig;
}

const NavigationComponent: FC<Props> = (props) => {
	const [navigationOpen, setNavigationOpen] = useState(window.innerWidth >= 1368);
	useEffect(() => {
		const updateWindowDimensions = () => {
			const newWidth = window.innerWidth;
			setNavigationOpen(newWidth >= 1368);
		};
		window.addEventListener("resize", updateWindowDimensions);
		return () => window.removeEventListener("resize", updateWindowDimensions);
	}, []);

	return (
		<NavigationContext.Provider value={{ isNavigationCollapsed: !navigationOpen }}>
			<div
				className={classNames("navigation", {
					open: navigationOpen,
				})}
			>
				<div className="navigation-header">
					<Link to="/">
						<img className="navigation-logo-open" alt="GoPOS Logo" src={props.logo} />
						<img className="navigation-logo" alt="GoPOS Logo" src={props.logo_small} />
					</Link>
					<button
						className={`navigation-toggle ${navigationOpen ? "" : "active"}`}
						onClick={() => setNavigationOpen(!navigationOpen)}
					>
						<AngelDoubleRight />
					</button>
				</div>
				<div className="navigation-body">{props.children}</div>
				<div className="navigation-footer">
					<Nav variant="pills" className={"flex-column"} activeKey="1">
						<NavigationApplicationsComponent
							apps={props.apps}
							link_show_all={props.apps_link_show_all}
							company_id={props.company_id}
							fetch_installed_apps={props.apps_fetch_installed}
							organization_name={props.organization_name}
							organization={props.organization}
							fetchMenuApplication={props.fetchMenuApplication}
							menuAppConfig={props.menuAppConfig}
							navigationOpen={navigationOpen}
						/>
						<NavigationHelpComponent
							options={props.help_options}
							serviceAccessRouteLink={props.accessServiceRouteLink}
							navigationOpen={navigationOpen}
						/>
					</Nav>
					{props.profile_name && (
						<NavigationProfileComponent
							profile_avatar={props.profile_avatar}
							profile_name={props.profile_name}
							organization_name={props.organization_name}
							navigationOpen={navigationOpen}
						>
							{props.profile_content}
						</NavigationProfileComponent>
					)}
					<div className="navigation-watermark">© GoPOS.pl {new Date().getFullYear()}</div>
				</div>
			</div>
		</NavigationContext.Provider>
	);
};

export default NavigationComponent;
