import { ListConfigColumn } from "../../Column/services/types";
import { getSortedColumns } from "./getSortedColumns";

export const getColumnsToDisplay = (
	allColumns: ListConfigColumn[],
	selectedColumnsIds?: string[]
): ListConfigColumn[] => {
	return selectedColumnsIds
		? getSortedColumns(
				allColumns.filter((column) => selectedColumnsIds.includes(column.id)),
				selectedColumnsIds
		  )
		: allColumns;
};
