import { TFunction } from "react-i18next";
import { convertUTCDateToLocalDate } from "go-core/components/Formatters/FormatDate";

export const getLicenseExpirationDays = (licenseExpiresAt: string, organizationTimezone?: string): number => {
	return Math.floor(
		(convertUTCDateToLocalDate(licenseExpiresAt, organizationTimezone).getTime() - new Date().getTime()) / 86400000
	);
};

const getExactLicenseExpirationDays = (licenseExpiresAt: string, organizationTimezone?: string): number => {
	return (
		(convertUTCDateToLocalDate(licenseExpiresAt, organizationTimezone).getTime() - new Date().getTime()) / 86400000
	);
};
export const getLicenseExpirationMinutes = (licenseExpiresAt: string, organizationTimezone?: string): number => {
	return Math.floor(
		(convertUTCDateToLocalDate(licenseExpiresAt, organizationTimezone).getTime() - new Date().getTime()) /
			(1000 * 60)
	);
};

export const isLicenseBlocked = (
	licenseExpiresAt?: string,
	licenseType?: string,
	organizationTimezone?: string
): boolean | undefined => {
	if (licenseType === "NONE") return true; //@TODO: usunac jesli zmigrujemy wszystkie MANUALL z NONE na MANUALL
	if (licenseType === "MANUAL") return false;
	if (licenseType === "NOT_PAID") return true;
	const expirationDays: number | undefined =
		licenseExpiresAt !== undefined
			? getExactLicenseExpirationDays(licenseExpiresAt, organizationTimezone)
			: undefined;
	if ((licenseType === undefined && expirationDays === undefined) || licenseType === "NONE") return false;
	if (expirationDays === undefined || expirationDays <= -7) return true;
	return false;
};

export const isLicenseExpired = (
	licenseExpiresAt?: string,
	licenseType?: string,
	organizationTimezone?: string
): undefined | boolean => {
	if (licenseType === "MANUAL") return false;
	if (
		licenseType === "CANCELLED" ||
		licenseType === "TRIAL" ||
		licenseType === "PAUSED" ||
		licenseType === "NOT_PAID"
	)
		return true;
	if (licenseType === "NONE") return true;
	if (licenseType === "STANDARD") {
		if (!licenseExpiresAt) return false;
		const expirationDate = getExactLicenseExpirationDays(licenseExpiresAt, organizationTimezone);
		if (expirationDate <= 14) return true;
	}
	return false;
};

export const getLicenseAlertClass = (
	licenseExpiresAt?: string,
	licenseType?: string,
	organizationTimezone?: string
): string => {
	const exactExpirationDays: number | undefined =
		licenseExpiresAt !== undefined
			? getExactLicenseExpirationDays(licenseExpiresAt, organizationTimezone)
			: undefined;
	let alertClass = "danger";

	if (isLicenseExpired(licenseExpiresAt, licenseType, organizationTimezone)) {
		switch (licenseType) {
			case "TRIAL":
				alertClass = "warning";
				if (exactExpirationDays !== undefined) {
					if (exactExpirationDays <= 14) alertClass = "warning";
					if (exactExpirationDays <= 7) alertClass = "danger";
					if (exactExpirationDays <= 0) {
						alertClass = "danger";
					}
				} else {
					alertClass = "danger";
				}
				break;
			case "STANDARD":
				if (exactExpirationDays !== undefined) {
					if (exactExpirationDays <= 14) alertClass = "warning";
					if (exactExpirationDays <= 7) alertClass = "danger";
					if (exactExpirationDays <= 0) {
						alertClass = "danger";
					}
				} else {
					alertClass = "danger";
				}
				break;
			case "PAUSED":
				alertClass = "warning";
				break;
			case "NONE":
				alertClass = "danger";
				break;
		}
	}

	return alertClass;
};

export const getLicenseAlertText = (
	t: TFunction,
	licenseExpiresAt?: string,
	licenseType?: string,
	organizationTimezone?: string
): string => {
	const expirationDays: number | undefined =
		licenseExpiresAt !== undefined ? getLicenseExpirationDays(licenseExpiresAt, organizationTimezone) : undefined;
	const exactExpirationDays: number | undefined =
		licenseExpiresAt !== undefined
			? getExactLicenseExpirationDays(licenseExpiresAt, organizationTimezone)
			: undefined;
	let textAlert: string = "";

	if (isLicenseExpired(licenseExpiresAt, licenseType, organizationTimezone)) {
		switch (licenseType) {
			case "TRIAL":
				if (exactExpirationDays !== undefined && expirationDays !== undefined) {
					if (exactExpirationDays < 1) {
						textAlert = t("lib:go_security.license.alert.less_than_one_day");
					} else textAlert = t("lib:go_security.license.alert.trial", { count: expirationDays });
					if (exactExpirationDays <= 0) {
						textAlert = t("lib:go_security.license.alert.cancelled", {});
					}
				} else {
					textAlert = t("lib:go_security.license.alert.cancelled", {});
				}
				break;
			case "STANDARD":
				if (exactExpirationDays !== undefined && expirationDays !== undefined) {
					if (exactExpirationDays < 1) {
						textAlert = t("lib:go_security.license.alert.less_than_one_day");
					} else textAlert = t("lib:go_security.license.alert.standard", { count: expirationDays });
					if (exactExpirationDays <= 0) {
						if (exactExpirationDays > -7) {
							textAlert = t("lib:go_security.license.alert.not_paid_and_blocked", {
								count: 7 + expirationDays,
							});
						} else {
							textAlert = t("lib:go_security.license.alert.not_paid");
						}
					}
				} else {
					textAlert = t("lib:go_security.license.alert.cancelled", {});
				}
				break;
			case "CANCELLED":
			case "DISABLED":
				textAlert = t("lib:go_security.license.alert.cancelled", {});
				break;
			case "NOT_PAID":
				textAlert = t("lib:go_security.license.alert.not_paid", {});
				break;
			case "PAUSED":
				textAlert = t("lib:go_security.license.alert.paused", {});
				break;
			case "NONE":
				textAlert = t("lib:go_security.license.alert.none", {});
				break;
		}
	}

	return textAlert;
};
