import axios from "axios";
import { AdminJobApi } from "go-component/services/types";
import { parseApiData } from "go-core/api/parseApiData";
import { restSecurityApi } from "go-security/services/Api/api";
import { getSearchSelectPage, mapToBase } from "go-security/services/Api/searchSelectService";
import OrganizationIdService from "go-security/services/organizationIdService";
import { getApiURL } from "../../utils/envutil";
import { ConfigApi } from "../Config/types";
import { apiOrganization } from "./Organization/apiOrganization";
import { InvoiceApi, MigrationApi } from "./Organization/types";
import { MigrationFormProps, SearchSelectApi } from "./types";

class api {
	static getOrganizationId = () => {
		return OrganizationIdService.getWithCheck();
	};

	static organization(): Record<string, any> {
		return apiOrganization;
	}

	static getConfig(): Promise<ConfigApi> {
		return parseApiData(
			axios.get("/ajax/config", {
				withCredentials: true,
				baseURL: getApiURL(),
			})
		);
	}

	static getMigrationGlobalOrganizationsSearchSelect(
		t: string,
		obj: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> {
		const f = mapToBase(t, obj);
		const page = getSearchSelectPage(obj);
		return parseApiData(
			restSecurityApi.get(`/migration/organizations/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}

	static migrateGlobal(data: MigrationFormProps): Promise<MigrationApi> {
		return parseApiData(restSecurityApi.post(`/migration`, data));
	}

	static clearVenue(password: string): Promise<{}> {
		return parseApiData(
			restSecurityApi.post(`/security/organizations/${this.getOrganizationId()}/clear?password=${password}`)
		);
	}

	static getInvoices(params?: Record<string, any>, options?: Record<string, any>): Promise<InvoiceApi[]> {
		return parseApiData(restSecurityApi.get("/invoices", { params, cancelToken: options?.cancelToken }));
	}

	static getPhoneNumber(): Promise<{ email: string; phone_number?: string }> {
		return parseApiData(restSecurityApi.get(`/security/me/phone_number`));
	}

	static updatePhoneNumber(phoneNumber: string): Promise<{ email: string; phone_number?: string }> {
		return parseApiData(restSecurityApi.put(`/security/me/phone_number`, { phone_number: phoneNumber }));
	}

	static getJobs(params?: Record<string, any>, options?: Record<string, any>): Promise<AdminJobApi[]> {
		return parseApiData(
			restSecurityApi.get("jobs/in_memory", {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	}
}

export { api, restSecurityApi };
