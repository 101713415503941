import MyCookies from "go-core/utils/cookies";

const get = (): string => {
	const sessionId = MyCookies.get("session_id");
	if (sessionId !== undefined && sessionId) return sessionId;
	return "";
};
const update = (session?: string): void => {
	if (session !== undefined) {
		MyCookies.update("session_id", session);
	}
};
const remove = (): void => {
	MyCookies.remove("session_id");
};
export const SessionIdService = { get, update, remove };
export default SessionIdService;
