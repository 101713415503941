import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { domain } from "go-core";
import { ButtonLoading, hasErrors } from "go-form";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { Loading } from "go-core/components/Loading";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import { FormErrorMessageAlertApi } from "go-form/components/FormErrorMessageAlert";
import { useAuth } from "go-security/services/auth";
import SessionIdService from "go-security/services/sessionIdService";
import ParametersSecurity from "../services";
import AccessTokenSessionService from "../services/accessTokenSessionService";
import PasswordReminderModal from "./PasswordReminderModal";

interface State {
	email: string;
	password: string;
	showPassword: boolean;
}

interface Props {
	location: any;
	baseUrl: string;
	displayUseOldGoposVersionText?: boolean;
}

const LoginForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<ApiError[]>([]);
	const auth = useAuth(props.baseUrl);
	const firstIframeRender = useRef(true);
	const history = useHistory();
	const [values, setValues] = useState<State>({
		email: "",
		password: "",
		showPassword: false,
	});
	const linkStart = `${location.protocol}//${location.host}`;
	const isRedirecting = useRef<boolean>(false);

	const [isIFrameLoading, setIsIFrameLoading] = useState(true);
	const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const shouldRedirect = () => Boolean(props.location?.state?.from);

	const getEncodeRedirectUrl = () => {
		return `${linkStart}${props.location.state.from.pathname}${
			props.location.state.from.search && encodeURIComponent(props.location.state.from.search)
		}`;
	};

	const getRedirectUrl = (withProtocolAndHost = true) => {
		const link = `${props.location?.state?.from?.pathname ? props.location.state.from.pathname : ""}${
			props.location?.state?.from?.search ? props.location.state.from.search : ""
		}`;
		if (withProtocolAndHost) return `${linkStart}${link}`;
		return link;
	};

	const [rememberMe, setRememberMe] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		setLoading(true);
		event.preventDefault();
		try {
			await auth.login(values.email, values.password, rememberMe, true);
			const sessionId = SessionIdService.get();
			if (sessionId) {
				let redirectUrl = location.origin;
				if (shouldRedirect()) {
					redirectUrl = getEncodeRedirectUrl();
				}
				window.location.href = `${ParametersSecurity.getLoginRedirectUrl()}/save_session_id?reference_uri=${redirectUrl}&session_id=${sessionId}`;
			}
		} catch (e) {
			setLoading(false);
			const exceptionErrors = handleException(e);
			setErrors(exceptionErrors);
		}
	};

	const pollGoAccounts = (goAccountsIFrame: HTMLObjectElement) => {
		setIsIFrameLoading(true);

		console.log("iframe -> message");
		goAccountsIFrame.contentWindow?.postMessage("", "*");
	};

	const shouldListenerContinue = (e: MessageEvent<any>) => {
		if (e.origin !== ParametersSecurity.getIframeUrl()) {
			console.log("iFrameListener #1.1");
			return false;
		}
		if (e.data === null) {
			console.log("iFrameListener #1.2");
			return false;
		}
		if (e.data.includes && !e.data.includes("goaccounts-session_")) {
			console.log("iFrameListener #1.3");
			return false;
		}
		return true;
	};

	const listenForGoAccountsIFrameResponse = (e: MessageEvent<any>) => {
		console.log("iFrameListener #1");

		if (shouldListenerContinue(e)) {
			console.log("iFrameListener #2");
			if (isRedirecting.current) {
				return;
			}
			let redirectUrl = "/";
			if (shouldRedirect()) {
				redirectUrl = getRedirectUrl();
			}

			const sessionId = typeof e.data === "string" ? e.data.split("_")[1] : "";

			if (sessionId === "empty" || sessionId === "") {
				if (SessionIdService.get() !== "") {
					AccessTokenSessionService.remove();
					console.log(`redirect #1 -> ${redirectUrl}`);
					isRedirecting.current = true;
					history.push(redirectUrl);
				} else {
					firstIframeRender.current = false;
					window.removeEventListener("message", listenForGoAccountsIFrameResponse, false);
					setIsIFrameLoading(false);
					console.log("NOT LOGGED");
				}
			} else {
				redirectUrl = getRedirectUrl(false);
				SessionIdService.update(sessionId);
				console.log(`redirect #2 -> ${redirectUrl}`);
				isRedirecting.current = true;
				history.push(redirectUrl);
			}
			// Reset the redirect flag after a short delay to avoid continuous redirects
			setTimeout(() => {
				isRedirecting.current = false;
			}, 1000);
		}
	};

	const getGoAccountsIFrame = (): Promise<HTMLObjectElement> => {
		return new Promise((resolve) => {
			const intervalId = setInterval(() => {
				const iframe = document.getElementById("ifrm-accounts");

				if (iframe) {
					clearInterval(intervalId);
					return resolve(iframe as HTMLObjectElement);
				}
			}, 100);
		});
	};

	const waitForGoAccountsAndCheckLoginStatus = (goAccountsIFrame: HTMLObjectElement): Promise<void> => {
		return new Promise((resolve) => {
			const intervalId = setInterval(() => {
				if (firstIframeRender.current) {
					pollGoAccounts(goAccountsIFrame);
				} else {
					clearInterval(intervalId);
					return resolve();
				}
			}, 100);
		});
	};

	useEffect(() => {
		const checkIfUserIsLoggedIn = async () => {
			window.addEventListener("message", listenForGoAccountsIFrameResponse, false);

			const goAccountsIFrame = await getGoAccountsIFrame();

			await waitForGoAccountsAndCheckLoginStatus(goAccountsIFrame);
		};

		checkIfUserIsLoggedIn();
	}, []);

	const loginWithGoogle = () => {
		const url = ParametersSecurity.getLoginRedirectUrl();
		let redirectUrl;
		if (shouldRedirect()) {
			redirectUrl = getEncodeRedirectUrl();
		} else {
			redirectUrl = `${domain()}/dashboard`;
		}
		window.location.href = `${url}/oauth2/authorization/google?redirect_uri=${redirectUrl}`;
	};
	return (
		<>
			{isIFrameLoading ? (
				<>
					<iframe
						id="ifrm-accounts"
						src={`${ParametersSecurity.getIframeUrl()}/login-session.html`}
						style={{ display: "none" }}
					/>
					<Loading title={"Trwa ładowanie ..."} />
				</>
			) : (
				<>
					<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
						<FormErrorMessageAlertApi errors={errors} />
						<div className="oauth-google" onClick={() => loginWithGoogle()}>
							<div className="oauth-google-inner">
								<img
									src="/graphics/login/google-logo.png"
									className="oauth-google-img"
									style={{ width: "18px", margin: 0, height: "18px" }}
								/>
								<div className="oauth-google-text">
									{t("lib:go_security.login.actions.continue_with_google")}
								</div>
							</div>
						</div>
						<div className="horizontal-row">
							<div className="horizontal-text">{t("lib:common.word.or")}</div>
						</div>
						<Form.Group className="form-group">
							<Form.Control
								disabled={loading}
								id="login"
								isInvalid={hasErrors(errors, "password")}
								onChange={handleChange("email")}
								type="email"
								value={values.email}
								placeholder={t("lib:go_security.login.form.email.placeholder")}
							/>
							<Form.Label htmlFor="email" sm={3}>
								{t("lib:go_security.login.form.email.label")}
							</Form.Label>
							<FormErrorMessage errors={errors} name="password" />
						</Form.Group>
						<Form.Group className="form-group">
							<Form.Control
								disabled={loading}
								id="password"
								isInvalid={hasErrors(errors, "password")}
								onChange={handleChange("password")}
								type={values.showPassword ? "text" : "password"}
								value={values.password}
								placeholder={t("lib:common.word.password")}
							/>
							<Form.Label htmlFor="password" sm={3}>
								{t("lib:common.word.password")}
							</Form.Label>
							<FormErrorMessage errors={errors} name="password" />
						</Form.Group>
						<Form.Group className="form-group">
							<ButtonLoading loading={loading} type="submit" color="primary">
								{t("lib:go_security.login.form.actions.login")}
							</ButtonLoading>
						</Form.Group>
						<Form.Group
							className="form-group form-check"
							onChange={(e: any) => {
								setRememberMe(e.target.checked);
							}}
						>
							<Form.Check.Input
								type="checkbox"
								className="form-check-input form-check-input-remember-me"
								id="remember_me"
							/>
							<Form.Check.Label className="form-check-label" htmlFor="remember_me">
								{t("lib:go_security.login.form.remember_me.label")}
							</Form.Check.Label>
							<div className="login-lost-password">
								<Link to="/lost-password">{t("lib:go_security.login.form.actions.lost_password")}</Link>
							</div>
						</Form.Group>
					</Form>
					{props.displayUseOldGoposVersionText && (
						<div className="d-flex justify-content-center mt-5">
							<span>
								{t("lib:go_security.login.words.old_version")}
								<a href="https://app.gopos.pl/" className="ms-1">
									{t("lib:go_security.login.words.click_to_login")}
								</a>
							</span>
						</div>
					)}
					{auth.showReminderPasswordModal && (
						<PasswordReminderModal
							show={auth.showReminderPasswordModal}
							initialEmail={values.email}
							onHide={() => auth.setShowReminderPasswordModal(false)}
						/>
					)}
				</>
			)}
		</>
	);
};

export default LoginForm;
