import React, { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationSalesPosReportsExternalReportsIndexPage from "./Index/index";
import OrganizationSalesPosReportsExternalReportsPreviewPage from "./Preview";

const OrganizationSalesPosReportsExternalReportsPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route
				path={`${props.match.url}/`}
				exact={true}
				component={OrganizationSalesPosReportsExternalReportsIndexPage}
			/>
			<Route
				path={`${props.match.url}/:reportId`}
				component={OrganizationSalesPosReportsExternalReportsPreviewPage}
			/>
		</Switch>
	);
};

export default OrganizationSalesPosReportsExternalReportsPage;
