import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { useWindowSize } from "go-core/components/useWindowSize";
import OrganizationReservationsEditPage from "./pages/Edit";
import OrganizationReservationsIndexPage from "./pages/Index";
import OrganizationReservationsNewPage from "./pages/New";

const OrganizationReservationsPage: FC<RouteComponentProps> = (props) => {
	const isMobile = useWindowSize().isMobile;
	const { t } = useTranslation();

	return (
		<div className="page-container">
			{isMobile && <SubNavigationItems title={t("modules.reservation.header.title")} items={[]} />}
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/new`} exact={true} component={OrganizationReservationsNewPage} />
					<Route path={`${props.match.url}/:reservationId`} component={OrganizationReservationsEditPage} />
					<Route path={`${props.match.url}/`} component={OrganizationReservationsIndexPage} />
				</Switch>
			</div>
		</div>
	);
};

export default OrganizationReservationsPage;
