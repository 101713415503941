import React, { useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonLoading } from "go-form";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import EmptyData from "go-core/components/EmptyData";
import { Loading } from "go-core/components/Loading";
import { useInfiniteScroll } from "go-core/hooks/useInfiniteScroll";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as BackSVG } from "../../../../../../../../../../images/svg/menu/header-back.svg";
import {
	ItemGroupApi,
	ModifierGroupApi,
	ModifierGroupOptionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import CreateMenuPageItemItemGroupModalBody from "../../../Menus/components/CreateMenuPageItemItemGroupModalBody";

interface Props {
	handleBack: () => void;
	handleAddModifierOption: (option: ModifierGroupOptionApi) => void;
	modifierGroup: ModifierGroupApi;
	selectedValues: number[];
}

const SearchItemsModal = (props: Props): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState("");
	const [newItemMode, setNewItemMode] = useState(false);
	const [modifierOptions, setModifierOptions] = useState<ModifierGroupApi[]>([]);
	const scrollRef = useRef(null);

	const fetchItems = (params: Record<string, any>, options?: CancelTokenSource) => {
		const { search, ...restParams } = params;
		return api.organization().getItemsSearchSelect(
			search,
			{ ...props.selectedValues, ...restParams },
			{
				cancelToken: options?.token,
			}
		);
	};

	const { items, loading } = useInfiniteScroll({
		fetchItems,
		search: searchText,
		scrollContainerElementRef: scrollRef,
	});

	const getFilteredItems = () => {
		const noDuplicatedData = [
			...modifierOptions,
			...items.filter((item: Record<string, any>) => {
				const existingItem = modifierOptions.find((i) => item.id === i.id);
				if (!existingItem) return item;
				return false;
			}),
		];
		return noDuplicatedData?.filter(
			(item) => !props.selectedValues?.map((value) => Number(value)).includes(Number(item.id))
		);
	};

	const addModifierOption = (x: Record<string, any>) => {
		props.handleAddModifierOption({
			item_id: x.id,
			position: props.modifierGroup.options?.length ? props.modifierGroup.options.length : 0,
			type: "ITEM",
			name: x.label,
			sub_item: {
				name: x.label,
				item_group: {
					id: x.item_group_id,
					name: x.item_group_name,
				},
				price: x?.price,
			},
		} as ModifierGroupOptionApi);
	};

	const handleCreateModifierOption = (item: ItemGroupApi) => {
		addModifierOption({
			...item,
			id: item.items[0].id,
			label: item.name,
			item_group_id: item.id,
			item_group_name: item.name,
		});
	};

	const handleCreateModifiers = () => {
		modifierOptions.length > 0 && modifierOptions.forEach((modifier) => addModifierOption(modifier));
	};

	const selectModifiers = (item: ModifierGroupApi) => {
		if (modifierOptions && modifierOptions.length > 0) {
			const index = modifierOptions.findIndex((i) => i.id === item.id);
			const newArr = [...modifierOptions];
			if (index > -1) {
				newArr.splice(index, 1);
				setModifierOptions([...newArr]);
			} else setModifierOptions([...newArr, item]);
		} else setModifierOptions([item]);
	};

	const getCheckboxesValue = (item: ModifierGroupApi) => {
		return modifierOptions.includes(item);
	};

	return (
		<>
			{newItemMode ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							<BackSVG className="icon" onClick={() => setNewItemMode(false)} />{" "}
							{t("modules.modifier_group.header.add_item.title")}
						</Modal.Title>
					</Modal.Header>
					<CreateMenuPageItemItemGroupModalBody
						searchText={searchText}
						handleCancel={props.handleBack}
						handleSave={handleCreateModifierOption}
					/>
				</>
			) : (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							<BackSVG className="icon" onClick={() => props.handleBack()} />{" "}
							{`${t("modules.modifier_group.action.assign_elements_to.title")} "${
								props.modifierGroup.name
							}"`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="items" ref={scrollRef}>
						{
							<>
								<div className="sticky-modal-search-header">
									<div className="form-group search-input">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												value={searchText}
												placeholder={t("common.word.search", { ns: "lib" })}
												onChange={(evt) => setSearchText(evt.target.value)}
											/>
											<div className="input-group-append">
												<ButtonLoading
													loading={false}
													onClick={() => setNewItemMode(!newItemMode)}
													variant={"primary"}
												>
													{t("lib:common.action.create")}
												</ButtonLoading>
											</div>
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table">
										<tbody>
											<>
												{getFilteredItems().length > 0
													? getFilteredItems().map((x: ModifierGroupApi, index: number) => {
															return (
																<tr key={x.id}>
																	<td className={"p-0 w-1"}>
																		<label>
																			<input
																				type="checkbox"
																				className="me-2"
																				aria-label={`item-${index}`}
																				checked={getCheckboxesValue(x)}
																				onClick={() => selectModifiers(x)}
																			/>
																		</label>
																	</td>
																	<td>
																		<div className="d-flex align-items-center">
																			<div className="d-flex align-items-center">
																				<RenderImage
																					data={x}
																					imageLink={x.image_link}
																				/>
																			</div>
																			<Link
																				to={`/${organization.id}/menu/item_groups/${x.item_group_id}`}
																				target={"_blank"}
																				rel="noreferrer"
																			>
																				{x.label}
																			</Link>
																		</div>
																	</td>
																	<td style={{ padding: "0px" }}>
																		<Button
																			className="float-end"
																			variant={"primary"}
																			type="button"
																			onClick={() => addModifierOption(x)}
																		>
																			{t("lib:common.action.select")}
																		</Button>
																	</td>
																</tr>
															);
													  })
													: !loading && <EmptyData />}
											</>
										</tbody>
									</table>
								</div>
								{loading && <Loading />}
							</>
						}
					</Modal.Body>
					{modifierOptions.length > 0 && (
						<Modal.Footer>
							<Button variant="primary" onClick={handleCreateModifiers}>
								{t("common.action.select", { ns: "lib" })}
							</Button>
						</Modal.Footer>
					)}
				</>
			)}
		</>
	);
};
export default SearchItemsModal;
