import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationMenuDiscountsEditPage from "./pages/Edit";
import OrganizationMenuDiscountsIndexPage from "./pages/Index";
import OrganizationMenuDiscountsNewPage from "./pages/New";

const OrganizationMenuDiscountsPage: FC<RouteComponentProps> = (props) => {
	return (
		<>
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/new`} exact={true} component={OrganizationMenuDiscountsNewPage} />
					<Route path={`${props.match.url}/:discountId`} component={OrganizationMenuDiscountsEditPage} />
					<Route path={`${props.match.url}/`} component={OrganizationMenuDiscountsIndexPage} />
				</Switch>
			</div>
		</>
	);
};
export default OrganizationMenuDiscountsPage;
