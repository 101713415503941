import React, { useState } from "react";
import { ConfigReportComboApi } from "../../services/types";
import { TemplateInstanceReportCombo } from "../Template/types";

interface Props {
	templateItem: TemplateInstanceReportCombo;
	onBack: () => void;
	onUpdate: (templateItem: TemplateInstanceReportCombo) => void;
}

const CatalogTemplateEditReportComboComponent = (props: Props) => {
	const templateItem = props.templateItem;
	const [value, setValue] = useState(JSON.stringify(templateItem.data));

	const refresh = () => {
		const newData = JSON.parse(value) as ConfigReportComboApi;
		const newTemplateItem = { ...templateItem, data: newData, template_id: undefined };
		props.onUpdate(newTemplateItem);
	};

	const save = () => {
		refresh();
		props.onBack();
	};

	return (
		<div>
			<div className={`card border-primary mb-2`}>
				<div className="card-body">
					<h5 className="card-title">{templateItem.name}</h5>
					<p>Zmiana szablonu spowoduje zamianę jego na ręcznie zdefiniowany</p>
					<div className="form-floating mb-2">
						<textarea
							onChange={(e) => setValue(e.target.value)}
							className="form-control"
							placeholder="Leave a comment here"
							id="floatingTextarea2"
							style={{ height: 100 }}
						>
							{value}
						</textarea>
						<label htmlFor="floatingTextarea2">Config raportu</label>
					</div>
					<button className="btn btn-primary" onClick={save}>
						Zapisz
					</button>
					{` `}
					<button className="btn btn-default" onClick={refresh}>
						Odśwież
					</button>
					{` `}
					<button className="btn btn-default" onClick={props.onBack}>
						Anuluj
					</button>
				</div>
			</div>
		</div>
	);
};

export default CatalogTemplateEditReportComboComponent;
