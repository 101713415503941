import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormatResourceStatus from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { WebhookContext } from "./services/context";
import { WebhookApi } from "./services/types";

interface Props {
	webhook: WebhookApi;
	getRequestItemUrl: (requestItemId: number) => string;
}

const WebhookPreview = (props: Props): JSX.Element => {
	const [webhook] = useState(props.webhook);
	const { t } = useTranslation();
	const webhookService = React.useContext(WebhookContext);

	return (
		<div className="row">
			<div className="col-md-12">
				<Card className={"mb-3"}>
					<Card.Body>
						<h5>{t("lib:common.word.basic_data")}</h5>
						<dl className="mb-0">
							<dt>{t("lib:common.word.name")}</dt>
							<dd>{webhook.name}</dd>
						</dl>

						<dl className="mb-0">
							<dt>{t("lib:go_component.webhook.preview.resource_type")}</dt>
							<dd>{webhookService.getTypeTitle(webhook.resource_type)}</dd>
						</dl>

						<dl className="mb-0">
							<dt>{t("lib:go_component.webhook.preview.url")}</dt>
							<dd>{webhook.url}</dd>
						</dl>
					</Card.Body>
				</Card>
			</div>
			<div className="col-md-12">
				<Card>
					<Card.Body>
						<h5>{t("lib:go_component.webhook.preview.requests")}</h5>
						{webhook.requests.length > 0 ? (
							<div className="table-responsive">
								<table className={"table table-preview"}>
									<thead>
										<tr>
											<th>{t("lib:go_component.webhook.preview.table.columns.resource_type")}</th>
											<th>{t("lib:common.word.created_at")}</th>
											<th>{t("lib:go_component.webhook.preview.table.columns.sent_at")}</th>
											<th>{t("lib:common.word.status")}</th>
											<th className={"w-1"} />
										</tr>
									</thead>
									<tbody>
										{webhook.requests.map((item, index) => {
											return (
												<tr key={index}>
													<td>{t(`lib:go_component.webhook.type.${item.type}`)}</td>
													<td>{FormatDate(item.created_at, undefined, true)}</td>
													<td>{FormatDate(item.sent_at, undefined, true)}</td>
													<td>
														<FormatResourceStatus status={item.status} />
													</td>
													<td>
														<Link
															target={"_blank"}
															rel={"noreferrer"}
															to={props.getRequestItemUrl(item.id)}
															className={"btn btn-light"}
														>
															{t("lib:common.action.preview")}
														</Link>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : (
							<EmptyList />
						)}
					</Card.Body>
				</Card>
			</div>
		</div>
	);
};
export default WebhookPreview;
