import React, { useEffect, useMemo, useState } from "react";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import i18n, { t } from "i18next";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { TFunction, useTranslation } from "react-i18next";
import useFlash, { handleErrorForAlert } from "go-alert/AlertMessage";
import EmptyData from "go-core/components/EmptyData";
import { isDST } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { getLang } from "go-core/utils/utils";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { isPredefinedRange, parsePredefinedRangeToDefaultString } from "go-list/utils/daterangeutils";
import { ReportChartGroupConfig } from "../../../reports/services/types";
import { ReactComponent as SettingsSVG } from "../../images/settings.svg";
import { ReportApi, SubReportApi } from "../../services/types";

interface Props {
	chartType: string;
	aggregate: string;
	comparable?: boolean;
	title: string;
	groupHistogram?: boolean;
	onChangeType?: (type: string, cancelTokenSource?: CancelTokenSource) => Promise<AxiosResponse>;
	params?: Record<string, any>;
	token?: CancelTokenSource;
	data: ReportApi;
	aggregatePrefix?: string;
	selectedFilters: ListSelectedFilter[];
	chartLineTitle?: string;
	defaultGroup?: string;
	customLocalStorageKey?: string;
	groups?: ReportChartGroupConfig[];
}

const TranslationsInitialized = () => {
	t("go_report.chart.aggregates.HOUR_OF_DAY");
	t("go_report.chart.aggregates.DAY_OF_WEEK");
	t("go_report.chart.aggregates.DAY_OF_MONTH");
	t("go_report.chart.aggregates.DATE");
};

const getOptionsChart = (isMobile = false) => {
	const optionsChart = {
		responsive: true,
		bezierCurve: false,
		datasetFill: true,
		pointDotRadius: 1,
		pointDotStrokeWidth: 8,
		pointHitDetectionRadius: 20,
		maintainAspectRatio: isMobile,
		aspectRatio: isMobile ? 1 : undefined,
	};

	return optionsChart;
};

const defaultDropdownOptions = ["HOUR_OF_DAY", "DAY_OF_MONTH", "DATE", "DAY_OF_WEEK"];

const getColors = () => {
	return ["#29abe1", "#fe971d", "#fbcc3e", "#19c52e", "#E12300"];
};

const getCompareColors = () => {
	return ["#8cd7f6", "#f1c994", "#fae7a7", "#93f1a4", "#e4aaaa"];
};

interface DateLabel {
	t: number;
	y: number;
}

function calcDaysDST(labelValue: number, twentyThreeHours: number): boolean[] {
	return [
		isDST(new Date(labelValue - twentyThreeHours)),
		isDST(new Date(labelValue)),
		isDST(new Date(labelValue + twentyThreeHours)),
	];
}

const getDataSet = (
	name: string,
	subReports: SubReportApi[],
	index: number,
	aggregateName: string,
	type: string,
	comparable: boolean,
	aggregatePrefix: string,
	selectedFilters: ListSelectedFilter[],
	groupHistogram?: boolean
) => {
	let labelsValue: number[] = getLabels(type, true, selectedFilters);
	const newLabelsValue: DateLabel[] = [];
	if (type === "DATE") {
		const oneDay = 24 * 60 * 60 * 1000;
		const twentyThreeHours = 23 * 60 * 60 * 1000;
		const oneHour = 3600 * 1000;

		let [prevDayDST, thisDayDST, nextDayDST] = calcDaysDST(labelsValue[0], twentyThreeHours);
		for (let i = 0; i <= labelsValue[2]; ++i) {
			if (thisDayDST && !prevDayDST) {
				labelsValue[0] = labelsValue[0] - oneHour;
				[prevDayDST, thisDayDST, nextDayDST] = calcDaysDST(labelsValue[0], twentyThreeHours);
			}
			if (thisDayDST && !nextDayDST) {
				labelsValue[0] = labelsValue[0] + oneHour;
				[prevDayDST, thisDayDST, nextDayDST] = calcDaysDST(labelsValue[0], twentyThreeHours);
			}

			let objectTValue = labelsValue[0] + i * oneDay;

			if (groupHistogram) {
				const labelsValueHoursValue = new Date(labelsValue[0]).getHours();
				objectTValue -= labelsValueHoursValue * 3600000;
			}

			newLabelsValue.push({
				t: objectTValue,
				y: 0,
			} as DateLabel);
		}
	}
	const insertedValuesMap: Record<string, any> = {};
	labelsValue.forEach((label: any) => {
		insertedValuesMap[`${label.toString()}`] = false;
	});
	subReports.forEach((rep) => {
		let value = rep.group_by_value?.name;
		let aggregate;
		if (comparable) {
			aggregate = rep.compare_aggregate[`${aggregatePrefix}`];
		} else {
			aggregate = rep.aggregate[`${aggregatePrefix}`];
		}
		let aggregateValue;
		if (!aggregate) {
			aggregateValue = 0;
		} else {
			const nestedProperties = aggregateName.split(".");

			aggregateValue = nestedProperties.reduce((currentAggregateValue, currentAggregatePropertyName) => {
				return currentAggregateValue[currentAggregatePropertyName] || currentAggregateValue;
			}, aggregate);
		}
		if (typeof aggregateValue === "object") {
			aggregateValue = aggregateValue.amount;
		}
		if (!aggregateValue) {
			aggregateValue = 0;
		}
		if (aggregateValue !== 0) {
			insertedValuesMap[`${value}`] = true;
		}
		value = parseFloat(value);
		if (type === "DAY_OF_WEEK") {
			value = value - 1;
		}
		if (type === "DAY_OF_MONTH") {
			value = value - 1;
		}
		if (type === "DATE") {
			let labelValue = newLabelsValue.find((f) => f.t === Number(value));
			if (!labelValue && typeof rep.group_by_value?.name === "string") {
				const splitted = rep.group_by_value?.name.split("-");
				if (splitted.length >= 2) {
					labelValue = newLabelsValue.find(
						(f) =>
							f.t ===
							new Date(Number(splitted[0]), Number(splitted[1]) - 1, Number(splitted[2])).getTime()
					);
				}
			}
			if (labelValue) {
				labelValue.y = aggregateValue;
			}
		} else if (aggregate && aggregateValue !== 0) {
			labelsValue[value] = aggregateValue;
		}
	});
	if (type === "DATE") {
		labelsValue = newLabelsValue.map((i) => i.y);
	}
	let colors = getColors();
	if (comparable) {
		name = `${name} ${i18n.t("lib:go_report.chart.word.previous_term")}`;
		colors = getCompareColors();
	}
	Object.keys(insertedValuesMap).forEach((key) => {
		if (
			insertedValuesMap[`${key}`] === false &&
			type !== "DATE" &&
			type !== "DAY_OF_WEEK" &&
			type !== "DAY_OF_MONTH" &&
			parseInt(key) !== 0
		) {
			labelsValue[parseInt(key)] = 0;
		}
		if (
			insertedValuesMap[`${key}`] === false &&
			(type === "DAY_OF_MONTH" || type === "DAY_OF_WEEK") &&
			parseInt(key) !== 0
		) {
			labelsValue[parseInt(key) - 1] = 0;
		}
	});
	const color = colors[index];
	return createDataSet(name, color, labelsValue);
};

const createDataSet = (name: string, color: string, data: number[]) => {
	const dataSet = {
		type: "line",
		label: name,
		fill: false,
		lineTension: 0.1,
		backgroundColor: color,
		borderColor: color,
		borderCapStyle: "butt",
		borderDash: [],
		borderDashOffset: 0.0,
		borderJoinStyle: "miter",
		pointBorderColor: color,
		pointBackgroundColor: "#fff",
		pointBorderWidth: 5,
		pointHoverRadius: 5,
		pointHoverBackgroundColor: "#fff",
		pointHoverBorderColor: color,
		pointHoverBorderWidth: 2,
		pointRadius: 1,
		pointHitRadius: 10,
		data,
		spanGaps: false,
	};
	return dataSet;
};

const generateDataSet = (
	data: ReportApi,
	aggregate: string,
	type: string,
	comparable: boolean,
	groupHistogram: boolean,
	aggregatePrefix: string,
	selectedFilters: ListSelectedFilter[],
	chartLineTitle?: string
) => {
	const dataSets: any[] = [];
	let name,
		index = 0;
	if (data.reports?.length === 0) {
		name = chartLineTitle ? chartLineTitle : i18n.t("lib:go_report.chart.word.sales");
		dataSets.push(getDataSet(name, [], index, aggregate, type, false, aggregatePrefix, selectedFilters));
	}
	for (let i = 0; i < data.reports?.length; ++i) {
		const report = data.reports[i];
		if (!report || !report.sub_report) {
			break;
		}
		const subReports = report.sub_report;
		name = report.group_by_value?.name ? report.group_by_value?.name : chartLineTitle;
		if (groupHistogram) {
			name = chartLineTitle ? chartLineTitle : i18n.t("lib:go_report.chart.word.sales");
			dataSets.push(
				getDataSet(
					name,
					data.reports,
					index,
					aggregate,
					type,
					false,
					aggregatePrefix,
					selectedFilters,
					groupHistogram
				)
			);
			break;
		}
		dataSets.push(getDataSet(name, subReports, index, aggregate, type, false, aggregatePrefix, selectedFilters));
		++index;
	}

	if (comparable) {
		index = 0;
		for (let i = 0; i < data.reports.length; ++i) {
			const report = data.reports[i];
			const subReports = report.sub_report;
			name = report.group_by_value?.name ? report.group_by_value?.name : chartLineTitle;
			if (groupHistogram) {
				name = i18n.t("lib:go_report.chart.word.sales");
				dataSets.push(
					getDataSet(name, data.reports, index, aggregate, type, true, aggregatePrefix, selectedFilters)
				);
				break;
			}
			dataSets.push(getDataSet(name, subReports, index, aggregate, type, true, aggregatePrefix, selectedFilters));
			++index;
		}
	}
	return dataSets;
};

const getLabels = (type: string, comparable: boolean, selectedFilters: ListSelectedFilter[]): number[] => {
	let labels: number[] = [];
	switch (type) {
		case "HOUR_OF_DAY":
			for (let i = 0; i < 24; ++i) {
				labels.push(i);
			}
			break;
		case "MONTH":
			for (let i = 0; i < 13; ++i) {
				labels.push(i);
			}
			break;
		case "DAY_OF_MONTH":
			for (let i = 1; i < 32; ++i) {
				labels.push(i);
			}
			break;
		case "DATE": {
			let dateStart, dateEnd, diffDays;
			const f = selectedFilters.find((f) => f.filterId === "date_range" || f.filterId === "date")?.value;
			let mockDateRange = f ? (isPredefinedRange(f) ? parsePredefinedRangeToDefaultString(f) : f) : undefined;
			if (!mockDateRange) {
				mockDateRange = selectedFilters.find((f) => f.filterId === "date")?.value;
				const dateStart = mockDateRange?.split(FILTER_VALUE_SEPARATOR)[0];
				const dateEnd = mockDateRange?.split(FILTER_VALUE_SEPARATOR)[1];
				if (dateEnd && dateStart) {
					mockDateRange = `${dateStart} - ${dateEnd}`;
				}
			} else {
				const dateStart = mockDateRange?.split(FILTER_VALUE_SEPARATOR)[0];
				const dateEnd = mockDateRange?.split(FILTER_VALUE_SEPARATOR)[1];
				if (dateEnd && dateStart) {
					mockDateRange = `${dateStart} - ${dateEnd}`;
				}
			}
			if (!mockDateRange) {
				mockDateRange = `${new Date(2021, 1, 1, 0, 0, 0).toISOString().split("T")[0]} - ${
					new Date().toISOString().split("T")[0]
				}`;
			}
			const dateRangeSplit = mockDateRange.split(" - ");
			const dateStartString = dateRangeSplit[0];
			const dateEndString = dateRangeSplit[1];
			const dateStartSplit = dateStartString?.slice(0, 10).split("-");
			const dateEndSplit = dateEndString?.slice(0, 10).split("-");

			if (dateStartSplit)
				dateStart = moment.utc(`${dateStartSplit[0]}-${dateStartSplit[1]}-${dateStartSplit[2]}`).toDate();
			if (dateEndSplit) dateEnd = moment.utc(`${dateEndSplit[0]}-${dateEndSplit[1]}-${dateEndSplit[2]}`).toDate();

			const oneDay = 24 * 60 * 60 * 1000;
			if (!dateEnd) {
				dateEnd = new Date();
			}
			if (
				comparable &&
				selectedFilters.find((selectedFilter) => selectedFilter.filterId === "compareDateRange")?.value
			) {
				let mockCompareDateRange = selectedFilters.find(
					(selectedFilter) => selectedFilter.filterId === "compareDateRange"
				)?.value;
				if (!mockCompareDateRange) {
					mockCompareDateRange = `${new Date(2021, 1, 1, 0, 0, 0).toISOString().split("T")[0]} - ${
						new Date().toISOString().split("T")[0]
					}`;
				}
				const mockCompareDateRangeSplit = mockCompareDateRange.split(" - ");
				const mockCompareDateStartString = mockCompareDateRangeSplit[0];
				const mockCompareDateEndString = mockCompareDateRangeSplit[1];
				const mockCompareDateStartSplit = mockCompareDateStartString.split("-");
				const mockCompareDateEndSplit = mockCompareDateEndString.split("-");
				const mockCompareDateStart = new Date(
					Number(mockCompareDateStartSplit[0]),
					Number(mockCompareDateStartSplit[1]) - 1,
					Number(mockCompareDateStartSplit[1]) - 1
				);
				const mockCompareDateEnd = new Date(
					Number(mockCompareDateEndSplit[0]),
					Number(mockCompareDateEndSplit[1]) - 1,
					Number(mockCompareDateEndSplit[1]) - 1
				);
				if (dateStart && dateStart.getTime() > mockCompareDateStart.getTime()) {
					dateStart = mockCompareDateStart;
				}
				if (dateEnd && dateEnd.getTime() < mockCompareDateEnd.getTime()) {
					dateEnd = mockCompareDateEnd;
				}
			}
			if (dateEnd && dateStart) {
				diffDays = Math.round(Math.abs((dateEnd.getTime() - dateStart.getTime()) / oneDay));
				labels = [dateStart.getTime(), dateEnd.getTime(), diffDays];
			}
			break;
		}
		default:
			for (let i = 0; i < 7; ++i) {
				labels.push(0);
			}
			break;
	}
	return labels;
};

const getLabelValues = (labels: number[], type: string) => {
	let newLabels: (string | number)[];
	if (type === "DAY_OF_WEEK") {
		newLabels = [
			i18n.t("lib:enums.days.MONDAY"),
			i18n.t("lib:enums.days.TUESDAY"),
			i18n.t("lib:enums.days.WEDNESDAY"),
			i18n.t("lib:enums.days.THURSDAY"),
			i18n.t("lib:enums.days.FRIDAY"),
			i18n.t("lib:enums.days.SATURDAY"),
			i18n.t("lib:enums.days.SUNDAY"),
		] as string[];
	} else if (type === "DATE") {
		const initTime = labels[0];
		newLabels = [];
		for (let i = 0; i <= labels[2]; ++i) {
			const day = 24 * 60 * 60 * 1000;
			newLabels.push(new Date(initTime + day * i).toLocaleDateString(getLang()));
		}
	} else {
		newLabels = labels;
	}
	return newLabels;
};

const parseOptions = (t: TFunction) => {
	return defaultDropdownOptions.map((x) => {
		return {
			id: x,
			title: t(`go_report.chart.aggregates.${x}`, { ns: "lib" }),
			type: x,
		} as ReportChartGroupConfig;
	});
};
const mergeOptions = (options: ReportChartGroupConfig[], newOptions?: ReportChartGroupConfig[]) => {
	if (!newOptions) return options;
	return newOptions;
};

const Charts = (props: Props): JSX.Element => {
	const [data, setData] = useState<ReportApi>(
		props.data.reports[0].group_by_type === "NONE" ? { reports: props.data.reports[0].sub_report } : props.data
	);
	const [chartTypeId, setChartTypeId] = useState(props.chartType);
	const { t } = useTranslation();
	const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
	const dropdownOptions = mergeOptions(parseOptions(t), props.groups);
	let chartType = dropdownOptions.filter((x) => x.id === chartTypeId)[0];
	if (!chartType) chartType = dropdownOptions[0];
	const { addFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;
	const changeType = (type: string) => {
		window.localStorage.setItem(`go_report.${props.customLocalStorageKey || "chart_type"}`, type);
		setChartTypeId(type);
		if (cancelTokenSource !== undefined) {
			cancelTokenSource.cancel();
		}
		const CancelToken = axios.CancelToken;
		const newCancelToken = CancelToken.source();
		setCancelTokenSource(newCancelToken);
		if (props.onChangeType) {
			props
				.onChangeType(type, newCancelToken)
				.then((res) => {
					if (res.data.data && res.data.data.reports && res.data.data.reports.length > 0) {
						setData(
							res.data.data.reports[0].group_by_type === "NONE"
								? { reports: res.data.data.reports[0].sub_report }
								: res.data
						);
					} else {
						setData(res.data);
					}
				})
				.catch((err) => {
					handleErrorForAlert(err, addFlash);
				});
		}
	};
	useEffect(() => {
		if (props.data.reports && props.data.reports.length > 0) {
			setData(
				props.data.reports[0].group_by_type === "NONE" && props.defaultGroup !== "NONE"
					? { reports: props.data.reports[0].sub_report }
					: props.data
			);
		} else {
			setData(props.data);
		}
	}, [props.data]);

	const dataSet = generateDataSet(
		data,
		props.aggregate,
		chartType.type,
		Boolean(props.comparable),
		Boolean(props.groupHistogram),
		props.aggregatePrefix ? props.aggregatePrefix : "summary",
		props.selectedFilters,
		props.chartLineTitle
	);
	const chartData = {
		labels: getLabelValues(
			getLabels(chartType.type, Boolean(props.comparable), props.selectedFilters),
			chartType.type
		),
		datasets: dataSet,
	};
	const renderChartLines = useMemo(() => {
		if (
			data.reports.length > 0 &&
			(data.reports[0].sub_report.length > 0 || data.reports[0].group_by_type !== "NONE")
		)
			return <Line data={chartData} options={getOptionsChart(isMobile)} />;
		return <EmptyData styles={{ background: "#F5F8FA", height: "260px" }} />;
	}, [data, props.data, isMobile]);

	if (!props.data) {
		return <LoadingContainer />;
	}
	return (
		<div className="report-charts">
			<div className="flex-wrap d-flex justify-content-between mb-3 row-gap-2">
				<h5 className="mb-0 chart-title">{props.title}</h5>
				<Dropdown>
					{data.reports.length > 0 &&
						(data.reports[0].sub_report.length > 0 || data.reports[0].group_by_type !== "NONE") && (
							<Dropdown.Toggle className="groups-dropdown" variant="light">
								<SettingsSVG />
							</Dropdown.Toggle>
						)}
					<Dropdown.Menu>
						{dropdownOptions.map((item, index) => {
							return (
								<Dropdown.Item
									active={chartType.id === item.id}
									onClick={() => changeType(item.id)}
									key={index}
								>
									{item.title}
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
			</div>
			{renderChartLines}
		</div>
	);
};
export default Charts;
