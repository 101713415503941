import React, { FC, createContext, useCallback, useContext, useEffect, useState } from "react";
import { CanceledError } from "axios";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import handleException from "go-core/api/handleException";
import { ApiError, Exception } from "go-core/api/types";
import { parseOmitErrors } from "go-core/utils/utils";
import ForbiddenAccessModal from "./ForbiddenAccess/ForbiddenAccessModal";

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:common.flash.completed");
	t("lib:common.flash.saved");
	t("lib:common.flash.removed");
	t("lib:common.flash.restored");
};

export interface Flash {
	type: "danger" | "info" | "success" | "warning";
	msg: Array<ApiError> | string | undefined;
	id?: string;
	createdAt?: number;
	messageAlreadyCreated?: boolean;
}

interface CustomMessageContextType {
	flashes: Flash[];
	addFlash: (_: Flash) => void;
	removeFlash: (_: Flash) => void;
	addSuccessFlash: (_: string) => void;
}

export const CustomMessageContext = createContext<CustomMessageContextType>({
	flashes: [] as Flash[],
	addFlash: (_: Flash) => {},
	removeFlash: (_: Flash) => {},
	addSuccessFlash: (_: string) => {},
});

export const CustomMessageProvider: FC = (props) => {
	const [flashes, setFlashes] = useState<Flash[]>([]);

	useEffect(() => {
		const isAccessForbidden = flashes.find(
			(flash) => Array.isArray(flash.msg) && flash?.msg[0]?.code === "access_forbidden"
		);
		if (!isAccessForbidden) {
			const interval = setInterval(() => {
				const now = new Date().getTime();
				const newFlashes = flashes.filter((flash) => flash.createdAt !== undefined && flash.createdAt > now);
				if (newFlashes.length !== flashes.length) {
					setFlashes(newFlashes);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [flashes]);

	const addFlashWithDelay = useCallback((newFlash: Flash) => {
		newFlash.id = Math.random().toString();
		newFlash.createdAt = new Date().getTime() + 3000;

		setFlashes((flashes) => [...flashes, newFlash]);
	}, []);

	const removeFlash = useCallback(
		(newFlash: Flash) => setFlashes((flashes) => flashes.filter((flash) => flash.id !== newFlash.id)),
		[]
	);

	const addSuccessFlash = useCallback(
		(newFlash: string) =>
			setFlashes((flashes) => [
				...flashes,
				{ msg: newFlash, type: "success", createdAt: new Date().getTime() + 3000 },
			]),
		[]
	);

	const contextValue = {
		flashes,
		addFlash: addFlashWithDelay,
		removeFlash,
		addSuccessFlash,
	};
	return <CustomMessageContext.Provider value={contextValue}>{props.children}</CustomMessageContext.Provider>;
};

const useFlash = (): CustomMessageContextType => {
	const { flashes, addFlash, removeFlash, addSuccessFlash } = useContext(CustomMessageContext);
	return { flashes, addFlash, removeFlash, addSuccessFlash };
};
export default useFlash;

interface AlertMessageProps {
	type: "danger" | "info" | "success" | "warning";
	msg: Array<ApiError> | string | undefined;
	delay?: number;
}

export const AlertMessage = (props: AlertMessageProps): JSX.Element | null => {
	const [show, setShow] = useState<boolean>(true);
	const { t } = useTranslation();
	const flash = {
		type: props.type,
		msg: props.msg,
	} as Flash;
	useEffect(() => {
		if (props.delay !== undefined) {
			const interval = setInterval(() => {
				setShow(false);
				clearInterval(interval);
			}, props.delay);
			return () => clearInterval(interval);
		}
	}, [flash]);
	const message =
		Array.isArray(flash.msg) && (flash.msg[0]?.field === "all" || flash.msg[0]?.field === undefined) ? (
			<> {t(`constraints.${flash.msg[0]?.code}`, { ns: ["translation", "lib"] })}</>
		) : (
			flash.msg !== undefined &&
			!Array.isArray(flash.msg) && (
				<>
					{t(`${flash.type === "success" ? "" : "constraints."}${flash.msg}`, { ns: ["translation", "lib"] })}
				</>
			)
		);

	if (!show) return null;
	return (
		<Alert variant={flash.type} onClose={() => setShow(false)} dismissible>
			{message}
		</Alert>
	);
};

interface AlertMessageSingleProps {
	flash: Flash;
	delay?: number;
	flashes?: Flash[];
	missingPermissions?: AlertMessagePermission[];
}

const parseMissingPermissions = (permissionsData: string[], messagePermissions: AlertMessagePermission[]) => {
	return permissionsData.map((perm) => {
		const permissionWithTranslation = messagePermissions?.find((permission) => permission.id === perm);
		if (permissionWithTranslation) return permissionWithTranslation.title;
		return perm;
	});
};

export const AlertMessageSingle = (props: AlertMessageSingleProps): JSX.Element | null => {
	const { removeFlash } = useFlash();
	const { t } = useTranslation();
	const flash = props.flash;
	let message;

	const flashMsg = flash.msg;
	const hasMultipleMessages = Array.isArray(flashMsg);

	if (flashMsg === undefined) {
		return <></>;
	}

	if (flash.messageAlreadyCreated) {
		message = flash.msg;
	} else if (!hasMultipleMessages) {
		message = t(`${flash.type === "success" ? "" : "constraints."}${flash.msg}`, { ns: ["translation", "lib"] });
	} else {
		const [firstMessage] = flashMsg;

		if (firstMessage.field === "all" || firstMessage.field === undefined) {
			const isForbiddenAccessAlert = firstMessage.code === "access_forbidden";
			if (isForbiddenAccessAlert) {
				const missingPermissionsData = firstMessage.missing_permissions ?? [];
				const missingPermissions = parseMissingPermissions(
					missingPermissionsData,
					props.missingPermissions || []
				);
				const eligibleUsers = firstMessage.eligible_users ?? [];
				const { organization_name } = firstMessage;

				return (
					<ForbiddenAccessModal
						missingPermissions={missingPermissions}
						eligibleUsers={eligibleUsers}
						isShown={isForbiddenAccessAlert}
						onHide={() => removeFlash(flash)}
						organizationName={organization_name}
					/>
				);
			}

			if (firstMessage.type) {
				const constraintType = firstMessage.type.substring(firstMessage.type.lastIndexOf("_")).replace("_", "");
				const constraintKey = `constraints.organization_access_denied.type.${constraintType}`;
				const startMessage = `${t(`${constraintKey}.start_message`)}`;
				const middleMessage = `${t(`${constraintKey}.middle_message`)}`;
				const value = `${t(`lib:constraints.organization_access_denied.value`, { value: firstMessage.value })}`;
				const type = `${t(`${constraintKey}.type.${firstMessage.type}`)}`;

				message = `${startMessage} ${type}. ${middleMessage} ${value}.`;
			} else {
				message = t(`constraints.${firstMessage?.code}`, {
					ns: ["translation", "lib"],
					...firstMessage?.arguments,
				});
			}
		}
	}

	return (
		<Alert variant={flash.type} onClose={() => removeFlash(flash) as void} dismissible>
			{message}
		</Alert>
	);
};

export interface AlertMessagePermission {
	id: string;
	title: string;
}

interface AlertMessagesProps {
	permissions?: AlertMessagePermission[];
}

export const AlertMessages: FC<AlertMessagesProps> = ({ permissions }) => {
	const { flashes } = useFlash();
	if (flashes === undefined || flashes.length === 0) return null;
	return (
		<div className="alerts">
			{flashes.map((flash: Flash, index: number) => {
				return (
					<AlertMessageSingle
						flashes={flashes}
						delay={3000}
						key={index}
						flash={flash}
						missingPermissions={permissions || []}
					/>
				);
			})}
		</div>
	);
};

export function handleErrorForAlert(
	exception: Exception | unknown,
	setFlash: (_: Flash) => void,
	omitErrors?: string[]
): null {
	if (!(exception instanceof Error)) {
		throw exception;
	}
	let exceptionErrors = omitErrors
		? parseOmitErrors(handleException(exception), omitErrors)
		: handleException(exception);
	exceptionErrors = exceptionErrors.map((ex) => {
		return ex.field !== "all" ? { ...ex, field: "all" } : ex;
	});
	if (exceptionErrors.length > 0) {
		if (exception instanceof CanceledError) return null;

		setFlash({
			type: "danger",
			msg: exceptionErrors,
		});
	} else {
		setFlash({
			type: "danger",
			msg: "form.wrong",
		});
	}
	return null;
}

export function handleAlertErrorsWithoutField(exceptionErrors: ApiError[], setFlash: (_: Flash) => void): null {
	let isHighOrderValidation = false;
	exceptionErrors.forEach((ex) => {
		if (ex.field === undefined || ex.field === "all") {
			isHighOrderValidation = true;
		}
	});
	if (isHighOrderValidation) {
		setFlash({
			type: "danger",
			msg: exceptionErrors,
		});
	} else {
		setFlash({
			type: "danger",
			msg: "form.wrong",
		});
	}
	return null;
}
