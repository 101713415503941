import { History } from "history";
import { OrganizationState } from "go-security/services/organizations/types";
import { api } from "../../../../../../../../../../services/Api/api";

export const switchToPreviousPOSReport = async (
	reportId: string,
	history: History,
	organization: OrganizationState
) => {
	const previousReportId = await api.organization().getPreviousPosReport(reportId);

	if (previousReportId) {
		history.push(`/${organization.id}/sales/pos_reports/${previousReportId}`);
	}
};

export const switchToNextPOSReport = async (reportId: string, history: History, organization: OrganizationState) => {
	const nextReportId = await api.organization().getNextPosReport(reportId);

	if (nextReportId) {
		history.push(`/${organization.id}/sales/pos_reports/${nextReportId}`);
	}
};
