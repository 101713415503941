import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import InvoiceToOrderForm from "../../../components/InvoiceToOrder/InvoiceToOrderForm";

const OrganizationInvoicesIndexNewToOrderPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.action.invoice_to_order.title"));
	}, []);

	return (
		<>
			<Header title={t("modules.invoice.action.invoice_to_order.title")} />
			<InvoiceToOrderForm />
		</>
	);
};

export default OrganizationInvoicesIndexNewToOrderPage;
