import { FC } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck, FormInput } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { CompanyInfoApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { ReactComponent as RemoveSVG } from "./../../../../../../../../../../../images/svg/remove.svg";

interface Props {
	form: UseFormReturn<CompanyInfoApi>;
}

const BankAccountsForm: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		control,
		register,
	} = props.form;
	const { fields, remove, append } = useFieldArray({
		control,
		name: "bank_accounts",
		keyName: "key",
	});
	const isMobile = useWindowSize().isMobile;

	return (
		<div className={`${isMobile ? "table-responsive" : ""}`}>
			<table className="table table-form">
				<thead>
					<tr>
						<th className="action" />
						<th>{t("modules.company.field.name.title")}</th>
						<th>{t("modules.company.field.number.title")}</th>
						<th className="w-1">{t("modules.company.field.main.title")}</th>
						<th className="action" />
					</tr>
				</thead>
				<tbody>
					{fields.map((item, index) => {
						return (
							<tr key={fields[index].key}>
								<td className="action" />
								<td>
									<FormInput
										register={register}
										defaultValue={item.name}
										name={`bank_accounts.${index}.name`}
										errors={errors}
									/>
								</td>
								<td>
									<FormInput
										register={register}
										defaultValue={item.number}
										name={`bank_accounts.${index}.number`}
										errors={errors}
									/>
								</td>
								<td style={{ textAlign: "center" }}>
									<FormCheck
										type={"switch"}
										register={register}
										defaultChecked={item.main}
										name={`bank_accounts.${index}.main`}
										errors={errors}
									/>
								</td>

								<td className="action">
									<RemoveSVG className="icon" onClick={() => remove(index)} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<Button variant="add" type="button" onClick={() => append({ name: "", number: "", main: false })}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
		</div>
	);
};
export default BankAccountsForm;
