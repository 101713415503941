import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { OrderApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	isShown: boolean;
	onHide: () => void;
	order: OrderApi;
}

interface FormProps {
	email: string;
}

const SendReceiptModal = ({ isShown, onHide, order }: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<FormProps>({
		criteriaMode: "all",
		defaultValues: {
			email: order?.fiscalization?.email ?? "",
		},
	});
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState,
		formState: { errors },
		setError,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: FormProps) => {
		setLoading(true);
		try {
			await api.organization().sendOrderEReceiptViaEmail(order.id, data);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={isShown} onHide={onHide}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.order.field.send_receipt_via_email")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput name="email" errors={errors} register={register} label={t("lib:common.word.email")} />
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("lib:common.action.send")}
					</ButtonLoading>
					<Button onClick={onHide} variant="light">
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default SendReceiptModal;
