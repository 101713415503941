import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { MenuPriceListApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { getInitialSpreadsheetPriceListDefaultColumns } from "../../../GroupEditing/pages/Spreadsheet/utils";
import PriceListForm from "../../components/PriceListForm";

interface MatchParams {
	priceListId: string;
}

const OrganizationMenuPriceListsEditPage = (): JSX.Element => {
	const { addFlash, addSuccessFlash } = useFlash();
	const history = useHistory();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [priceList, setPriceList] = useState<MenuPriceListApi | undefined>();
	const { priceListId } = useParams<MatchParams>();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		fetchPriceList();
	}, []);

	const fetchPriceList = async () => {
		const params: Record<string, any> = { include: "availability" };
		try {
			const res = await api.organization().getPriceList(Number(priceListId), params);
			setPriceList(res);
			handleChangeTabTitle(`${res.name} | ${t("modules.price_list.header.title")}`);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemovePriceList = async (priceListItem: MenuPriceListApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removePriceList(priceListItem.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/menu/price_lists`);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestorePriceList = async (priceListItem: MenuPriceListApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			const res = await api.organization().restorePriceList(priceListItem.id);
			addSuccessFlash(t("lib:common.flash.restored"));
			setPriceList(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onDisablePriceList = async (priceListItem: MenuPriceListApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.disable", { ns: "lib" }),
			});
			const res = await api.organization().disablePriceList(priceListItem.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setPriceList(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	if (!priceList) return <LoadingContainer />;

	const renderTitle = () => (
		<>
			{t("modules.price_list.header.title")} "{priceList?.name}"
			<FormatResourceStatus status={priceList?.status} />
		</>
	);

	const onEditPrices = () => {
		const defaultColumns = getInitialSpreadsheetPriceListDefaultColumns(t).map((col) => col.id);
		const columns = [...defaultColumns, priceList.id];
		history.push({
			pathname: `/${organization.id}/menu/price_lists/group_editing`,
			search: `c=${columns.join(",")}`,
		});
	};

	const buttons: ButtonProps[] = [
		{
			title: t("modules.price_list.action.edit_prices.title"),
			action: onEditPrices,
			variant: "primary",
		},
	];
	if (priceList.status !== "DELETED")
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => onRemovePriceList(priceList),
			dropdown: true,
		});
	if (priceList.status === "DELETED" || priceList.status === "DISABLED")
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => onRestorePriceList(priceList),
			dropdown: true,
		});
	if (priceList.status === "ENABLED")
		buttons.push({
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisablePriceList(priceList),
			dropdown: true,
		});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("modules.price_list.action.edit_prices.title"),
			action: onEditPrices,
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => onRemovePriceList(priceList),
			hide: priceList.status === "DELETED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => onRestorePriceList(priceList),
			hide: priceList.status !== "DELETED" && priceList.status !== "DISABLED",
		},
		{
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisablePriceList(priceList),
			hide: priceList.status !== "ENABLED",
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<PriceListForm priceList={priceList} />
		</>
	);
};

export default OrganizationMenuPriceListsEditPage;
