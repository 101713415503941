import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { EmployeeApi, WorkTimeApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	handleClose: () => void;
	handleSave: (data: WorkTimeApi) => void;
	worktime?: WorkTimeApi;
	employee: EmployeeApi;
	workplaceIds: number[];
}

const WorktimeModalForm: FC<Props> = (props: Props): JSX.Element => {
	const form = useForm<WorkTimeApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.worktime,
			...(!props.worktime?.id && {
				hourly_rate: props.employee.hourly_rate,
			}),
		},
	});
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
	} = form;
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";

	const onSubmit = handleSubmit(async (data: WorkTimeApi) => {
		setLoading(true);
		data.employee_id = props.employee.id;
		try {
			if (props.worktime?.id) {
				data.id = props.worktime.id;
				const res = await api.organization().updateWorktime(data);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				props.handleSave(res);
			} else {
				const res = await api.organization().createWorktime(data);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				props.handleSave(res);
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const searchWorkplaces = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"id|e": `${props.workplaceIds.length !== 0 ? props.workplaceIds.join(FILTER_VALUE_SEPARATOR) : "-1"}`,
		};
		return api.organization().getWorkplacesSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	return (
		<Modal show={true} onHide={props.handleClose}>
			<form key="worktime_form">
				<Modal.Header closeButton>
					<Modal.Title>
						{props.worktime?.id
							? `${t("modules.employee.header.title")} "${props.employee.name}"`
							: t("modules.employee.action.add_work_time.title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={"row"}>
						<div className="col-md-6">
							<FormInput
								name={"id"}
								type="hidden"
								errors={errors}
								register={register}
								value={props.employee.id}
							/>
							<FormDatePicker
								label={t("common.word.start_date", { ns: "lib" })}
								control={control}
								name="start_at"
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormDatePicker
								label={t("common.word.end_date", { ns: "lib" })}
								control={control}
								name="end_at"
								errors={errors}
							/>
						</div>
						<div className={"col-md-6"}>
							<FormSelectGroup
								label={t("modules.employee.field.workplace.title")}
								name="workplace_id"
								errors={errors}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								defaultValue={{
									label: props.worktime?.workplace?.name,
									id: props.worktime?.workplace?.id,
								}}
								loadOptions={searchWorkplaces}
								control={control}
								data-testid="workplace_id"
							/>
						</div>
						<div className={"col-md-6"}>
							<FormMoneyInput
								label={t("modules.employee.field.to_pay.title")}
								currency={currency}
								name={"hourly_rate.amount"}
								control={control}
								errors={errors}
							/>
						</div>
						<div className={"col-md-12"}>
							<FormCheck
								label={t("modules.employee.field.waste.title")}
								type={"switch"}
								defaultChecked={props.worktime?.status === "SKIPPED"}
								name={"waste"}
								errors={errors}
								register={register}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.handleClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default WorktimeModalForm;
