import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import ParametersSecurity from "go-security/services";

const SecurityProfileDetailsPage: FC<RouteComponentProps> = () => {
	const { t } = useTranslation();

	return (
		<div className="content">
			<div className="content-header">
				<h1>{t("lib:go_component.profile.basic_data.header.title")}</h1>
			</div>
			<div>
				<a target="_blank" rel="noreferrer" href={`${ParametersSecurity.getAccountsUrl()}/profile/details`}>
					{t("lib:go_component.profile.basic_data.actions.change_basic_data")}
				</a>
			</div>
		</div>
	);
};

export default SecurityProfileDetailsPage;
