import React, { useContext, useRef, useState } from "react";
import { FixedSizeList, FixedSizeListProps } from "react-window";
import { LoadingContainer } from "go-core/components/Loading";

export const VirtualTableContext = React.createContext<{
	loading: boolean;
	top: number;
	setTop: (top: number) => void;
	header: React.ReactNode;
	footer: React.ReactNode;
}>({
	loading: false,
	top: 0,
	setTop: () => {},
	header: <></>,
	footer: <></>,
});

export const VirtualTable = ({
	row,
	header,
	footer,
	loading,
	...rest
}: {
	header?: React.ReactNode;
	footer?: React.ReactNode;
	row: FixedSizeListProps["children"];
	loading: boolean;
} & Omit<FixedSizeListProps, "children" | "innerElementType">) => {
	const listRef = useRef<FixedSizeList | null>();
	const [top, setTop] = useState(0);
	return (
		<VirtualTableContext.Provider value={{ top, setTop, header, footer, loading }}>
			<FixedSizeList {...rest} innerElementType={Inner} ref={(el) => (listRef.current = el)}>
				{row}
			</FixedSizeList>
		</VirtualTableContext.Provider>
	);
};

const Inner = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(({ children, style, ...rest }, ref) => {
	const { header, footer, loading } = useContext(VirtualTableContext);

	const tableBodyHeight = document.querySelector("tbody")?.style.height;

	return (
		<div {...rest} ref={ref}>
			<table className={"table"} style={{ width: "auto", height: tableBodyHeight || 0 }}>
				{header}
				{loading ? (
					<LoadingContainer />
				) : (
					<tbody
						style={{
							...style,
							position: "absolute",
							top: "99px",
						}}
					>
						{children}
					</tbody>
				)}
				{footer}
			</table>
		</div>
	);
});
Inner.displayName = "Inner";
