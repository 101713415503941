import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import AppCard from "./AppCard";

interface Props {
	appTemplates: ApplicationTemplateApi[];
	handleUpdateNewInstalledApp: (app: ApplicationApi) => void;
}

const AllApps: FC<Props> = ({ appTemplates, handleUpdateNewInstalledApp }) => {
	const { t } = useTranslation();
	return (
		<div>
			<Header title={t("modules.app.field.all.title")} />
			<div className={"content-preview"}>
				<div className={"row"}>
					{appTemplates.map((configApp, index) => {
						return (
							<AppCard
								key={index}
								configApp={configApp}
								handleUpdateNewInstalledApp={handleUpdateNewInstalledApp}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default AllApps;
