import React, { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { apiOrganizationComponent } from "../../../../../../../../../../services/Api/Organization/apiOrganizationComponent";
import { OrderSyncApi } from "../../../../../../../../../../services/Api/Organization/types";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "ORDER_SYNC";
const resourceType = "ORDER_SYNC";

const List = ({ resource }: ListState) => {
	const [items, setItems] = useState<OrderSyncApi[]>([]);
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const organization = useSelector(selectOrganization);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "order_number",
				name: t("common.word.order_number"),
				type: "text",
				disableSorting: true,
			},
			{
				id: "created_at",
				name: t("modules.order_sync.field.created_at.title"),
				type: "date",
				render: (item: OrderSyncApi) => FormatDate(item.created_at, undefined, true),
				disableSorting: true,
			},
			{
				id: "web_at",
				name: t("modules.order_sync.field.web_at.title"),
				type: "date",
				render: (item: OrderSyncApi) => FormatDate(item.web_at, undefined, true),
				disableSorting: true,
			},
			{
				id: "first_pos_terminal_name",
				name: t("modules.order_sync.field.first_pos_terminal_name.title"),
				render: (item: OrderSyncApi) => {
					return (
						<div>
							<span className="d-block text-end">{item.first_pos_terminal_name}</span>
							<span className="d-block text-end small">{FormatDate(item.first_pos_at)}</span>
						</div>
					);
				},
				disableSorting: true,
			},
			{
				id: "last_pos_terminal_name",
				name: t("modules.order_sync.field.last_pos_terminal_name.title"),
				render: (item: OrderSyncApi) => {
					return (
						<div>
							<span className="d-block text-end">{item.last_pos_terminal_name}</span>
							<span className="d-block text-end small">{FormatDate(item.last_pos_at)}</span>
						</div>
					);
				},
				disableSorting: true,
			},
			{
				id: "created_at_web_date_diff",
				name: t("modules.order_sync.field.created_at_web_date_diff.title"),
				type: "number",
				disableSorting: true,
			},
			{
				id: "first_pos_web_date_diff",
				name: t("modules.order_sync.field.first_pos_web_date_diff.title"),
				type: "number",
				disableSorting: true,
			},
			{
				id: "last_pos_web_date_diff",
				name: t("modules.order_sync.field.last_pos_web_date_diff.title"),
				type: "number",
				disableSorting: true,
			},
			{
				id: "terminals_without_order",
				name: t("modules.order_sync.field.terminals_without_order.title"),
				render: (item: OrderSyncApi) => {
					const { terminals_without_order } = item;

					return terminals_without_order.map((terminal, index) => {
						const isLastTerminal = terminals_without_order.length - 1 === index;
						return (
							<Link
								key={terminal.id}
								className={`d-inline-block ${isLastTerminal ? "" : "me-1"}`}
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/settings/terminals/${terminal.id}`}
							>
								{`${terminal.name}${isLastTerminal ? "" : ","}`}
							</Link>
						);
					});
				},
				disableSorting: true,
			},
		],
		filters: [
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						apiOrganization.getTerminalsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
		],
		selectedColumns: [
			"order_number",
			"created_at",
			"web_at",
			"first_pos_terminal_name",
			"last_pos_terminal_name",
			"created_at_web_date_diff",
			"first_pos_web_date_diff",
			"last_pos_web_date_diff",
			"terminals_without_order",
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: async (params: Record<string, any>, sourceToken?: CancelTokenSource) => {
			return apiOrganizationComponent.getOrderSync(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			onFetch={(fetchItems: OrderSyncApi[]) => setItems(fetchItems)}
			displayListFeaturesOnEmptyList
		/>
	);
};

export const OrganizationLogsReportOrderSyncIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.order_sync.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.order_sync.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
		</div>
	);
};
