import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck, FormInput } from "go-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import {
	DirectionApi,
	EntityPointOfSaleApi,
	PointOfSaleApi,
} from "../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../services/Api/api";

interface Props {
	form: UseFormReturn<any>;
	entityPointsOfSale: EntityPointOfSaleApi[];
	pointsOfSale: PointOfSaleApi[];
	advancedPosesView: boolean;
	changeAdvancedPosesView: () => void;
	showDefaultDirection?: boolean;
	changeDefaultDirectionView?: () => void;
	advancedDirectionView?: boolean;
	defaultDirection?: DirectionApi;
	entityType: string;
}

export const mapPointsOfSaleToForm = (
	pointsOfSale: PointOfSaleApi[],
	itemPointsOfSale: EntityPointOfSaleApi[]
): EntityPointOfSaleApi[] => {
	const poses: EntityPointOfSaleApi[] = [];
	pointsOfSale?.forEach((pos) => {
		const found = itemPointsOfSale?.find((f) => f.point_of_sale_id === pos.id);
		if (found) {
			poses.push({
				visibility: found.visibility === "VISIBLE",
				point_of_sale_id: found.point_of_sale_id,
				point_of_sale: found.point_of_sale,
				direction: found.direction_id ? found.direction : pos.default_direction,
				direction_id: found.direction_id,
			} as EntityPointOfSaleApi);
		} else {
			poses.push({
				visibility: false,
				point_of_sale: pos,
				point_of_sale_id: pos.id,
				direction: pos.default_direction,
			} as EntityPointOfSaleApi);
		}
	});
	return poses;
};

const EntityPointsOfSaleForm: FC<Props> = (props: Props): JSX.Element => {
	const {
		formState: { errors },
		control,
		register,
	} = props.form;
	const { entityType } = props;
	const { t } = useTranslation();
	const { fields } = useFieldArray({
		control,
		name: "points_of_sale",
		keyName: "key",
	});

	const searchDirections = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDirectionsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const changeDefaultDirectionView = () => {
		if (props.changeDefaultDirectionView) {
			props.changeDefaultDirectionView();
		}
	};

	const defaultDirectionDescription = t("modules.menu.field.default_direction_description.title", {
		name_female_dot:
			entityType === "category"
				? `${t("modules.menu.field.category.plural.title")}.`
				: `${t("modules.menu.field.product.plural.title")}.`,
	});

	const pointsOfSaleDescription = t("modules.menu.field.limit_sales_point_description.title", {
		available_specific_name:
			entityType === "category"
				? t("modules.menu.field.category_available.title")
				: t("modules.menu.field.product_available.title"),
		name:
			entityType === "category" ? t("modules.menu.field.category.title") : t("modules.menu.field.product.title"),
		available:
			entityType === "category"
				? t("modules.menu.field.available.title")
				: t("modules.menu.field.available.male.title"),
		visible:
			entityType === "category"
				? t("modules.menu.field.visible.female.title")
				: t("modules.menu.field.visible.title"),
	});

	return (
		<fieldset className="no-gutters">
			{props.showDefaultDirection && (
				<div className="limit-component-visibility-form-box mb-3">
					<div className="form-box-check">
						<Form.Check
							type="switch"
							id="default_direction"
							checked={props.advancedDirectionView}
							onClick={() => changeDefaultDirectionView()}
						/>
						<Form.Check.Label onClick={() => changeDefaultDirectionView()}>
							<h6>{t("modules.menu.field.default_direction.title")}</h6>
						</Form.Check.Label>
					</div>
					<div className="form-box-content">
						<div className="form-box-title">
							<Form.Check.Label onClick={() => changeDefaultDirectionView()}>
								<h6>{t("modules.menu.field.default_direction.title")}</h6>
							</Form.Check.Label>
						</div>
						<small className="text-muted">{defaultDirectionDescription}</small>
						{props.advancedDirectionView && (
							<FormSelectGroup
								name="direction_id"
								errors={errors}
								getOptionLabel={(option) => option.label}
								defaultValue={{
									label: props.defaultDirection?.name,
									id: props.defaultDirection?.id,
								}}
								getOptionValue={(option) => option.id}
								loadOptions={searchDirections}
								control={control}
								data-testid="direction_id"
							/>
						)}
					</div>
				</div>
			)}
			{props.pointsOfSale.length > 0 && (
				<div className="limit-component-visibility-form-box">
					<div className="form-box-check">
						<Form.Check
							type="switch"
							id="advanced_view"
							checked={props.advancedPosesView}
							onClick={() => props.changeAdvancedPosesView()}
						/>
						<Form.Check.Label onClick={() => props.changeAdvancedPosesView()}>
							<h6>{t("modules.menu.action.limit_sale_points.title")}</h6>
						</Form.Check.Label>
					</div>
					<div className="form-box-content">
						<div className="form-box-title">
							<Form.Check.Label onClick={() => props.changeAdvancedPosesView()}>
								<h6>{t("modules.menu.action.limit_sale_points.title")}</h6>
							</Form.Check.Label>
						</div>
						<small className="text-muted">{pointsOfSaleDescription}</small>
						{props.advancedPosesView && (
							<>
								<table className="table table-form">
									<thead>
										<tr>
											<th>{t("modules.menu.action.sale_point.title")}</th>
											<th className="w-75">{t("common.word.direction_of_expenditure")}</th>
										</tr>
									</thead>
									<tbody>
										{fields.map((field, index) => {
											const f = field as Record<string, any>;
											return (
												<tr key={fields[index].key}>
													<td style={{ textAlignLast: "left" }}>
														<FormInput
															errors={errors}
															type="hidden"
															register={register}
															value={f.point_of_sale_id}
															name={`points_of_sale.${index}.point_of_sale_id`}
														/>
														<FormCheck
															defaultChecked={f.visibility}
															type="switch"
															label={f.point_of_sale.name}
															register={register}
															name={`points_of_sale.${index}.visibility`}
															errors={errors}
														/>
													</td>
													<td>
														<FormSelectGroup
															name={`points_of_sale.${index}.direction_id`}
															placeholder={f.direction?.name}
															errors={errors}
															getOptionLabel={(option) => option.label}
															defaultValue={
																f.direction_id
																	? {
																			label: f.direction?.name,
																			id: f.direction?.id,
																	  }
																	: undefined
															}
															getOptionValue={(option) => option.id}
															loadOptions={searchDirections}
															control={control}
															data-testid={`points_of_sale.${index}.direction_id`}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</>
						)}
					</div>
				</div>
			)}
		</fieldset>
	);
};

export default EntityPointsOfSaleForm;

// t("modules.menu.field.category.plural.title");
// t("modules.menu.field.product.plural.title");
// t("modules.menu.field.category_available.title");
// t("modules.menu.field.product_available.title");
// t("modules.menu.field.category.title");
// t("modules.menu.field.product.title");
// t("modules.menu.field.available.title");
// t("modules.menu.field.available.male.title");
// t("modules.menu.field.visible.female.title");
// t("modules.menu.field.visible.title");
