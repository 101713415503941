import React from "react";
import { useTranslation } from "react-i18next";
import { OrderCompanyData } from "../../../../../../services/Api/Public/types";
import { getAddressString } from "../../utils";
import { CompanySocials } from "./CompanySocials";

export interface CompanyFooterInfoProps {
	company?: OrderCompanyData;
}

export const CompanyFooterInfo = ({ company }: CompanyFooterInfoProps): JSX.Element | null => {
	const { t } = useTranslation();

	if (!company) {
		return null;
	}

	const addressString = getAddressString(company.address);

	return (
		<section data-testid="e-order-footer-company-info">
			<div>
				<CompanySocials instagram={company.instagram} facebook={company.facebook} website={company.website} />
				{company.public_name && (
					<div>
						<strong>{company.public_name}</strong>
					</div>
				)}
				{company.name && <div>{company.name}</div>}
				{company.tax_id_no && (
					<div>
						{t("modules.e_order.field.tax_id_number.title")}: {company.tax_id_no}
					</div>
				)}
				{addressString && <div>{addressString}</div>}
			</div>
		</section>
	);
};
