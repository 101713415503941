import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
	PointOfSaleApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../../../services/Api/types";
import { VirtualTableColumn } from "../../../../../components/VirtualTable/types";
import {
	getVirtualTableColumnStyles,
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../utils";
import ItemGroupGroupEditingVirtualTableLimitPointOfSaleRestrictionInput from "./ItemGroupGroupEditingVirtualTableLimitPointOfSaleRestrictionInput";
import ItemGroupGroupEditingVirtualTablePointOfSaleInput from "./ItemGroupGroupEditingVirtualTablePointOfSaleInput";
import ItemGroupGroupEditingVirtualTableSelectInput from "./ItemGroupGroupEditingVirtualTableSelectInput";

interface Props {
	columnWidths: Record<string, number>;
	style: Record<string, any>;
	item: ItemFormApi;
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	defaultColumns: VirtualTableColumn[];
	maxZIndex: number;
	pointsOfSale: PointOfSaleApi[];
	visibleOtherColumns: VirtualTableColumn[];
	pointsOfSaleColumns: VirtualTableColumn[];
}

const ItemGroupGroupEditingVirtualTableRow = ({
	form,
	item,
	defaultColumns,
	maxZIndex,
	columnWidths,
	style,
	pointsOfSale,
	visibleOtherColumns,
	pointsOfSaleColumns,
}: Props) => {
	const { t } = useTranslation();
	const { watch, setValue } = form;
	const styleTr = { ...style };
	if (styleTr.width !== undefined) {
		styleTr.width = undefined;
	}
	const organization = useSelector(selectOrganization);

	const getColumnStyles = (key: string, index?: number) => {
		return getVirtualTableColumnStyles(key, columnWidths, maxZIndex, index);
	};

	const searchDirections = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDirectionsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const handleChangeDirection = (id: string, obj: SearchSelectApi) => {
		if (id) {
			setValue(`items.${item.index}.point_of_sale_visible`, false);
		}

		setValue(
			`items.${item.index}.direction`,
			id
				? {
						name: obj.label,
						id: Number(obj.id),
				  }
				: undefined
		);
	};

	const handleChangeAvailability = (id: string, obj: SearchSelectApi) => {
		setValue(
			`items.${item.index}.availability`,
			id
				? {
						id: Number(obj.id),
						name: obj.label,
				  }
				: undefined
		);
	};

	const renderDefaultColumnCells = () => {
		let prevLeftPosition = 0;
		return defaultColumns.map((col, index) => {
			const prevColumn = defaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const lastColumn = index === defaultColumns.length - 1;
			const isHide = isVirtualTableColumnHide(col.id, defaultColumns);
			const className = `${isHide ? "d-none" : ""} sticky-column ${lastColumn ? "with-wider-border" : ""}`;
			const style = {
				...getColumnStyles(col.id, index),
				left: leftPosition,
				position: "sticky",
			} as React.CSSProperties;

			if (col.id === ItemGroupGroupEditingColumn.CATEGORY)
				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="name-box" title={item.category.name}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/categories/${item.category.id}`}
							>
								{item.category.name}
							</Link>
						</div>
					</td>
				);

			if (col.id === ItemGroupGroupEditingColumn.ITEM_GROUP)
				return (
					<td className={className} style={style} key={`${item.index}-${col.id}`}>
						<div className="name-box" title={item.item_group?.name || ""}>
							<Link
								target="_blank"
								rel="noreferrer"
								to={`/${organization.id}/menu/item_groups/${item.item_group_id}`}
							>
								{item.item_group?.name || ""}
							</Link>
						</div>
					</td>
				);

			if (col.id === ItemGroupGroupEditingColumn.NAME) {
				const isDefaultVariant = item.name === t("common.word.default");

				return (
					<td
						className={`${className} ${isDefaultVariant ? "text-black-50" : ""}`}
						style={style}
						key={`${item.index}-${col.id}`}
					>
						<div className="name-box" title={item.name}>
							{item.name}
						</div>
					</td>
				);
			}

			if (col.id === ItemGroupGroupEditingColumn.FULL_NAME) {
				return (
					<td className={className} style={style} key={col.name}>
						<div className="name-box" title={item.name}>
							{item.full_name}
						</div>
					</td>
				);
			}

			return <></>;
		});
	};

	const renderPointsOfSaleColumns = () => {
		return pointsOfSaleColumns.map((pointOfSale, index) => {
			const isHide = isVirtualTableColumnHide(pointOfSale.id, pointsOfSaleColumns);
			const lastColumn = index === pointsOfSaleColumns.length - 1;
			const className = `${isHide ? "d-none" : ""} sticky-column ${lastColumn ? "with-wider-border" : ""}`;

			if (pointOfSale.id === ItemGroupGroupEditingColumn.POINT_OF_SALE) {
				return (
					<td
						className={className}
						style={{ ...getColumnStyles(pointOfSale.id.toString()) }}
						key={`${item.index}-${pointOfSale.id}`}
					>
						<div className="input-box">
							<ItemGroupGroupEditingVirtualTableLimitPointOfSaleRestrictionInput
								form={form}
								item={item}
							/>
						</div>
					</td>
				);
			}
			const pointOfSaleIndex = pointsOfSale.findIndex((point) => point.id.toString() === pointOfSale.id);
			return (
				<td
					className={className}
					style={{ ...getColumnStyles(pointOfSale.id.toString()) }}
					key={`${item.index}-${pointOfSale.id}`}
				>
					<div className="input-box" title={pointOfSale.name}>
						<ItemGroupGroupEditingVirtualTablePointOfSaleInput
							form={form}
							item={item}
							pointOfSaleIndex={pointOfSaleIndex}
						/>
					</div>
				</td>
			);
		});
	};

	const renderOtherColumns = () => {
		return visibleOtherColumns.map((column, index) => {
			const isHide = isVirtualTableColumnHide(column.id, visibleOtherColumns);
			const lastColumn = index === visibleOtherColumns.length - 1;
			const className = `${isHide ? "d-none" : ""} sticky-column ${lastColumn ? "with-wider-border" : ""}`;
			const style = { ...getColumnStyles(column.id.toString()) };

			if (column.id === ItemGroupGroupEditingColumn.AVAILABILITY) {
				return (
					<td className={className} style={style} key={`${item.index}-${column.id}`}>
						<div className="input-box">
							<ItemGroupGroupEditingVirtualTableSelectInput
								name={`items.${item.index}.availability_id`}
								form={form}
								loadOptions={searchAvailabilities}
								defaultValue={{
									id: watch(`items.${item.index}.availability`)?.id,
									label: watch(`items.${item.index}.availability`)?.name,
								}}
								handleOnChange={handleChangeAvailability}
							/>
						</div>
					</td>
				);
			}
			if (column.id === ItemGroupGroupEditingColumn.DIRECTION) {
				return (
					<td className={className} style={style} key={`${item.index}-${column.id}`}>
						<div className="input-box">
							<ItemGroupGroupEditingVirtualTableSelectInput
								name={`items.${item.index}.direction_id`}
								form={form}
								loadOptions={searchDirections}
								defaultValue={{
									id: watch(`items.${item.index}.direction`)?.id,
									label: watch(`items.${item.index}.direction`)?.name,
								}}
								handleOnChange={handleChangeDirection}
							/>
						</div>
					</td>
				);
			}

			return <></>;
		});
	};

	return (
		<tr style={styleTr}>
			{renderDefaultColumnCells()}
			{renderPointsOfSaleColumns()}
			{renderOtherColumns()}
		</tr>
	);
};

export default ItemGroupGroupEditingVirtualTableRow;
