import React from "react";
import { ApiError } from "go-core/api/types";
import { GoReportSegmentType } from "go-report/reports/services/types";
import { GoListSegmentType } from "../../../../../list/services/types";
import { ListConfigField } from "../../../../services/types";
import { ListConfigColumn } from "../../../Column/services/types";
import { getAllUniqueFilters, getFilterObjectWithParsedId } from "../../services";
import { FilterCustomAction, FilterCustomDateRange, ListConfigFilter, ListSelectedFilter } from "../../services/types";
import MobileFiltersButton from "./MobileFiltersButton";
import MobileFiltersModal from "./MobileFiltersModal";

interface Props {
	setShowMobileFilters: (value: boolean) => void;
	showMobileFilters: boolean;
	selectedFilters: ListSelectedFilter[];
	filters: ListConfigFilter[];
	onChangeFilters: (selectedFilters: ListSelectedFilter[]) => void;
	onChangeColumns?: (selectedColumns: string[], sortedColumns: string[]) => void;
	minDate?: Date | string;
	maxDate?: Date | string;
	dateRangePickerCustomActions?: FilterCustomAction[];
	dateRangePickerCustomRanges?: FilterCustomDateRange[];
	segments: (GoReportSegmentType | GoListSegmentType)[] | undefined;
	onChangeSegment: (segment: GoReportSegmentType | GoListSegmentType) => void;
	hasConfigSaveSegment: boolean;
	segmentModal: boolean;
	segment: GoReportSegmentType | GoListSegmentType | undefined;
	onSaveSegment: (
		segment: GoReportSegmentType | GoListSegmentType,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => Promise<ApiError[] | undefined>;
	onCreateSegment: (
		name: string,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => Promise<ApiError[] | undefined>;
	setSegmentModal: (value: boolean) => void;
	selectedColumns?: string[];
	allColumns?: React.MutableRefObject<ListConfigColumn[]>;
	columnsFields?: ListConfigColumn[];
	customFields?: ListConfigField[];
	shouldDisableSortingOfStickyColumns?: boolean;
	numberOfActiveStickyColumns?: number;
	setNumberOfActiveStickyColumns?: React.Dispatch<React.SetStateAction<number>>;
	isNumberOfStickyColumnsDynamic?: boolean;
	stickyColumnsDividerPosition?: React.MutableRefObject<number>;
	onChangeStickyColumnsDividerPosition?: (dividerPosition: number) => void;
	defaultSelectedColumns?: string[];
	defaultStickyColumns?: string[];
	numberOfStickyColumnsAtTheStart?: number;
	defaultSelectedColumnsOrder?: ListConfigColumn[];
	isReport?: boolean;
	defaultGroups?: string[];
	defaultGroupColumn?: string;
	groupsFromConfig?: string[];
	stateColumns?: string[];
	defaultSort?: string | string[];
}

const MobileFilters = ({
	setShowMobileFilters,
	selectedFilters,
	filters,
	showMobileFilters,
	onChangeFilters,
	onChangeColumns,
	segments,
	onChangeSegment,
	hasConfigSaveSegment,
	segmentModal,
	segment,
	onSaveSegment,
	maxDate,
	dateRangePickerCustomRanges,
	minDate,
	dateRangePickerCustomActions,
	onCreateSegment,
	setSegmentModal,
	selectedColumns,
	allColumns,
	shouldDisableSortingOfStickyColumns,
	isNumberOfStickyColumnsDynamic,
	customFields,
	columnsFields,
	stickyColumnsDividerPosition,
	onChangeStickyColumnsDividerPosition,
	setNumberOfActiveStickyColumns,
	numberOfActiveStickyColumns,
	defaultSelectedColumns,
	defaultStickyColumns,
	numberOfStickyColumnsAtTheStart,
	defaultSelectedColumnsOrder,
	isReport,
	defaultGroups,
	defaultGroupColumn,
	groupsFromConfig,
	stateColumns,
	defaultSort,
}: Props) => {
	const allUniqueParsedFilters = getAllUniqueFilters(filters).map(getFilterObjectWithParsedId);

	return (
		<>
			<MobileFiltersButton
				setShowMobileFilters={setShowMobileFilters}
				selectedFilters={selectedFilters}
				filters={allUniqueParsedFilters}
				selectedSegment={segment?.name}
			/>
			{showMobileFilters && (
				<MobileFiltersModal
					show={showMobileFilters}
					onHide={() => setShowMobileFilters(false)}
					onChangeFilters={onChangeFilters}
					selectedFilters={selectedFilters}
					allUniqueParsedFilters={allUniqueParsedFilters}
					segments={segments}
					onChangeSegment={onChangeSegment}
					hasConfigSaveSegment={hasConfigSaveSegment}
					segmentModal={segmentModal}
					segment={segment}
					onSaveSegment={onSaveSegment}
					maxDate={maxDate}
					minDate={minDate}
					dateRangePickerCustomRanges={dateRangePickerCustomRanges}
					dateRangePickerCustomActions={dateRangePickerCustomActions}
					onCreateSegment={onCreateSegment}
					setSegmentModal={setSegmentModal}
					selectedColumns={selectedColumns}
					allColumnsInOrder={allColumns}
					shouldDisableSortingOfStickyColumns={shouldDisableSortingOfStickyColumns}
					isNumberOfStickyColumnsDynamic={isNumberOfStickyColumnsDynamic}
					customFields={customFields}
					columnsFields={columnsFields}
					stickyColumnsDividerPosition={stickyColumnsDividerPosition}
					onChangeStickyColumnsDividerPosition={onChangeStickyColumnsDividerPosition}
					numberOfActiveStickyColumns={numberOfActiveStickyColumns}
					setNumberOfActiveStickyColumns={setNumberOfActiveStickyColumns}
					defaultSelectedColumns={defaultSelectedColumns}
					onChangeColumns={onChangeColumns}
					defaultStickyColumns={defaultStickyColumns}
					numberOfStickyColumnsAtTheStart={numberOfStickyColumnsAtTheStart}
					defaultSelectedColumnsOrder={defaultSelectedColumnsOrder}
					isReport={isReport}
					defaultGroups={defaultGroups}
					defaultGroupColumn={defaultGroupColumn}
					groupsFromConfig={groupsFromConfig}
					stateColumns={stateColumns}
					defaultSort={defaultSort}
				/>
			)}
		</>
	);
};

export default MobileFilters;
