import React from "react";
import { TFunction } from "react-i18next";
import { SubReportApi } from "go-report/core/services/types";
import { ReactComponent as DropdownGreenSVG } from "../../../images/svg/dashboard/dropdown-green.svg";
import { ReactComponent as DropdownRedSVG } from "../../../images/svg/dashboard/dropdown-red.svg";
import ReportShareUtils from "../../../utils/reports/ReportShareUtils";

export const drawPercentagesDiff = (newComparedValue: number, newCurrentValue: number): number => {
	if ((newComparedValue === 0 && newCurrentValue === 0) || newComparedValue === newCurrentValue) return 0;
	if (newCurrentValue === 0 || newComparedValue === 0) return 100;
	const val = 100 - Math.trunc((newCurrentValue / newComparedValue) * 100);
	return val < 0 ? val * -1 : val;
};

export const drawAmountDiff = (newComparedValue: number, newCurrentValue: number): number => {
	const val = newCurrentValue - newComparedValue;
	return val < 0 ? val * -1 : val;
};

export const drawDiffArrow = (newComparedValue: number, newCurrentValue: number): JSX.Element | undefined => {
	if (newCurrentValue > newComparedValue) return <DropdownGreenSVG className="mb-1 me-1" />;
	else if (newCurrentValue < newComparedValue) return <DropdownRedSVG className="mb-1 me-1" />;
};

export const getPercentagesDiffTextStyle = (newComparedValue: number, newCurrentValue: number): string | undefined => {
	if (newCurrentValue > newComparedValue) return "text-success";
	else if (newCurrentValue < newComparedValue) return "text-danger";
};

export const getAggregateName = (item: SubReportApi, t: TFunction): string => {
	const groupsInfoObject = ReportShareUtils.getGroupsInfo(t);

	if (Object.prototype.hasOwnProperty.call(ReportShareUtils.getGroupsInfo(t), `${item.group_by_type}`)) {
		return groupsInfoObject[`${item.group_by_type}` as keyof typeof groupsInfoObject](item.group_by_value);
	}
	return item.group_by_value.name === "empty" ? "-" : item.group_by_value.name;
};
