import React, { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from "react";
import crypto from "crypto";
import CSS from "csstype";
import { useListenEvent } from "go-core/hooks";
import { VERTICAL_SCROLL_WIDTH } from "../services/consts";

export const useStickyColumns = ({
	numberOfActiveStickyColumns,
	numberOfColumns,
	columnsOrder,
	isTableHeightOverflowing,
	wrapperRef,
	mainDivRef,
	tableRef,
	columnsRefs,
	lastColumnRef,
}: {
	numberOfActiveStickyColumns?: number;
	numberOfColumns: number;
	columnsOrder?: string[];
	isTableHeightOverflowing?: boolean;
	wrapperRef: RefObject<HTMLDivElement>;
	mainDivRef: RefObject<HTMLDivElement>;
	tableRef: RefObject<HTMLTableElement>;
	columnsRefs: MutableRefObject<RefObject<HTMLTableCellElement>[]>;
	lastColumnRef: RefObject<HTMLTableCellElement>;
}): Record<string, any> => {
	const columnsOrderHash = crypto
		.createHash("sha256")
		.update(columnsOrder?.join("") ?? "")
		.digest("hex");
	const previousNumberOfColumns = useRef<number>(numberOfColumns);
	const [leftStickyColumnsShadowStyles, setLeftStickyColumnsShadowStyles] = useState<CSS.Properties>({
		display: "none",
	});
	const [rightStickyColumnsShadowStyles, setRightStickyColumnsShadowStyles] = useState<CSS.Properties>({
		display: "none",
	});
	const [isTableWidthOverflowing, setIsTableWidthOverflowing] = useState(false);
	const [isBigDisplay, setIsBigDisplay] = useState(true);
	const [isLengthOfStickyColumnsLessThanTableWidth, setIsLengthOfStickyColumnsLessThanTableWidth] = useState(false);
	const [shouldHideStickyColumns, setShouldHideStickyColumns] = useState(true);

	const getStickyCellStyles = (columnIndex: number): CSS.Properties => {
		if (shouldHideStickyColumns || !numberOfActiveStickyColumns) {
			return {};
		}

		const isOneOfTheFirstColumns = columnIndex < numberOfActiveStickyColumns;

		if (!isOneOfTheFirstColumns) {
			return {};
		}

		const leftOffset = getColumnsWidth(columnsRefs.current.slice(0, columnIndex + 1));

		return {
			left: `${leftOffset}px`,
			position: "sticky",
			zIndex: 3,
		};
	};

	const getColumnsWidth = useCallback((columns: RefObject<HTMLTableCellElement>[]) => {
		return columns.reduce((offsetSoFar, currentCellRef) => {
			if (currentCellRef.current) {
				return offsetSoFar + currentCellRef.current.getBoundingClientRect().width;
			}

			return offsetSoFar;
		}, 0);
	}, []);

	const getLeftStickyColumnsShadowStyles = useCallback((): CSS.Properties => {
		if (shouldHideStickyColumns || !numberOfActiveStickyColumns) {
			return {
				display: "none",
			};
		}

		const width = getColumnsWidth(columnsRefs.current.slice(0, numberOfActiveStickyColumns + 1));
		const height = mainDivRef.current?.clientHeight;

		return {
			width: `${width}px`,
			height: `${height}px`,
			position: "absolute",
			pointerEvents: "none",
			zIndex: 5,
			boxShadow: "0 0 5px rgba(0, 0, 0, 0.45)",
			clipPath: "inset(1px -5px 1px 0px)",
		};
	}, [shouldHideStickyColumns, getColumnsWidth, numberOfActiveStickyColumns, columnsRefs, mainDivRef]);

	const getRightStickyColumnShadowStyles = useCallback((): CSS.Properties => {
		if (shouldHideStickyColumns || !numberOfActiveStickyColumns || !lastColumnRef.current) {
			return {
				display: "none",
			};
		}

		const currentVerticalScrollWidth = isTableHeightOverflowing ? VERTICAL_SCROLL_WIDTH : 0;
		const width = lastColumnRef.current.getBoundingClientRect().width + currentVerticalScrollWidth;
		const height = mainDivRef.current?.clientHeight ?? 0;

		return {
			width: `${width}px`,
			height: `${height}px`,
			position: "absolute",
			right: "0px",
			pointerEvents: "none",
			zIndex: 6,
			boxShadow: "0 0 5px rgba(0, 0, 0, 0.45)",
			clipPath: "inset(1px 0px 1px -5px)",
		};
	}, [numberOfActiveStickyColumns, shouldHideStickyColumns, mainDivRef, lastColumnRef, isTableHeightOverflowing]);

	const updateLeftStickyColumnsShadowStyles = useCallback(() => {
		const newStyles = getLeftStickyColumnsShadowStyles();

		setLeftStickyColumnsShadowStyles((currentStyles) => {
			const didStylesChange =
				currentStyles.height !== newStyles.height || currentStyles.width !== newStyles.width;

			if (didStylesChange) {
				return newStyles;
			}

			return currentStyles;
		});
	}, [getLeftStickyColumnsShadowStyles]);

	const updateRightStickyColumnsShadowStyles = useCallback(() => {
		const newStyles = getRightStickyColumnShadowStyles();

		setRightStickyColumnsShadowStyles((currentStyles) => {
			const didStylesChange =
				currentStyles.height !== newStyles.height || currentStyles.width !== newStyles.width;

			if (didStylesChange) {
				return newStyles;
			}

			return currentStyles;
		});
	}, [getRightStickyColumnShadowStyles]);

	const updateStickyColumnsShadows = useCallback(() => {
		requestAnimationFrame(() => {
			updateLeftStickyColumnsShadowStyles();
			updateRightStickyColumnsShadowStyles();
		});
	}, [updateLeftStickyColumnsShadowStyles, updateRightStickyColumnsShadowStyles]);

	const checkIfStickyColumnsWidthIsLessThanTableWidth = useCallback(() => {
		if (!wrapperRef.current || !numberOfActiveStickyColumns) {
			return setIsLengthOfStickyColumnsLessThanTableWidth(true);
		}

		const tableWrapperWidth = wrapperRef.current.getBoundingClientRect().width ?? 0;

		const leftStickyColumnsWidth = getColumnsWidth(columnsRefs.current.slice(0, numberOfActiveStickyColumns + 1));
		const rightStickyColumnsWidth = lastColumnRef.current?.getBoundingClientRect().width ?? 0;
		const stickyColumnsWidth = leftStickyColumnsWidth + rightStickyColumnsWidth;

		setIsLengthOfStickyColumnsLessThanTableWidth(stickyColumnsWidth < tableWrapperWidth);
	}, [
		getColumnsWidth,
		numberOfActiveStickyColumns,
		columnsRefs,
		wrapperRef,
		lastColumnRef,
		setIsLengthOfStickyColumnsLessThanTableWidth,
	]);

	const checkDisplaySize = useCallback(() => {
		const fullHdWidth = 1920;
		setIsBigDisplay(window.innerWidth > fullHdWidth / 2);
	}, [setIsBigDisplay]);

	const checkOverflow = useCallback(() => {
		setIsTableWidthOverflowing(
			mainDivRef.current ? mainDivRef.current.scrollWidth > mainDivRef.current.clientWidth : false
		);
	}, [setIsTableWidthOverflowing, mainDivRef]);

	useListenEvent({
		eventName: "resize",
		callback: () => {
			checkDisplaySize();
			checkIfStickyColumnsWidthIsLessThanTableWidth();
		},
		shouldRunCallbackOnAdd: true,
		shouldRunCallbackOnRemove: true,
	});

	useEffect(() => {
		const areRequirementsForStickyColumnsFulfilled = Boolean(
			isTableWidthOverflowing &&
				isBigDisplay &&
				isLengthOfStickyColumnsLessThanTableWidth &&
				numberOfActiveStickyColumns &&
				numberOfActiveStickyColumns > 0
		);
		const areAllElementsPresent = Boolean(
			wrapperRef.current &&
				mainDivRef.current &&
				tableRef.current &&
				numberOfActiveStickyColumns &&
				columnsRefs.current.length > numberOfActiveStickyColumns
		);

		setShouldHideStickyColumns(!areAllElementsPresent || !areRequirementsForStickyColumnsFulfilled);
	}, [
		wrapperRef,
		mainDivRef,
		tableRef,
		isTableWidthOverflowing,
		isBigDisplay,
		isLengthOfStickyColumnsLessThanTableWidth,
		numberOfActiveStickyColumns,
		columnsRefs,
	]);

	useEffect(() => {
		checkDisplaySize();
		checkOverflow();
	}, [checkDisplaySize, checkOverflow, numberOfColumns]);

	useEffect(() => {
		if (previousNumberOfColumns.current === numberOfColumns) {
			updateStickyColumnsShadows();
		}

		previousNumberOfColumns.current = numberOfColumns;
	}, [columnsOrderHash, numberOfColumns, updateStickyColumnsShadows]);

	useEffect(() => {
		if (mainDivRef.current) {
			const resizeObserver = new ResizeObserver(() => {
				checkOverflow();
				checkIfStickyColumnsWidthIsLessThanTableWidth();
				updateStickyColumnsShadows();
			});

			resizeObserver.observe(mainDivRef.current);

			return () => resizeObserver.disconnect();
		}
	}, [mainDivRef, checkOverflow, checkIfStickyColumnsWidthIsLessThanTableWidth, updateStickyColumnsShadows]);

	useEffect(() => {
		if (tableRef.current && !shouldHideStickyColumns) {
			const resizeObserver = new ResizeObserver(updateStickyColumnsShadows);

			resizeObserver.observe(tableRef.current);

			return () => resizeObserver.disconnect();
		}
	}, [tableRef, shouldHideStickyColumns, updateStickyColumnsShadows]);

	return {
		getStickyCellStyles,
		areStickyColumnsActive: !shouldHideStickyColumns,
		widthOfTheLastCell: lastColumnRef.current?.clientWidth,
		StickyColumnsShadows: () => (
			<>
				<div style={leftStickyColumnsShadowStyles} />
				<div style={rightStickyColumnsShadowStyles} />
			</>
		),
	};
};
