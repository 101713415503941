import React from "react";
import { Form } from "react-bootstrap";
import { Range } from "react-date-range";
import {
	Control,
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
	UseFormStateReturn,
	useController,
} from "react-hook-form";
import { parseRangeToDefaultString, parseStringToRange } from "go-list/utils/daterangeutils";
import { DateRangePicker } from "./DateRangePicker";

interface Props {
	options?: any;
	defaultValue?: any;
	loadOptions?: any;
	isInvalid?: boolean;
	isMulti?: boolean;
	parse?: (obj: any) => any;
	getOptionLabel?: (obj: any) => any;
	getOptionValue?: (obj: any) => any;
	onCreateOption?: (obj: any) => any;
	onChange?: (obj: any) => any;
	onApply?: (obj: any) => any;
	path?: string;
	groupLabel?: string;
	placeholder?: string;
	label?: string;
	errors: any;
	timezone?: string;
	control: Control<any>;
	name: string;
	timeStart?: string;
	timeEnd?: string;
	minDate?: Date | string;
	maxDate?: Date | string;
	handleInternalChange?: (value: string | null) => void;
}

// export const parseRangeToString = (range: Range) => {
//     const value2 = `${range.startDate?.getFullYear()}-${Number(range.startDate?.getMonth()) + 1}-${range.startDate?.getDate()} ${range.startDate?.getHours()}:${range.startDate?.getMinutes()}:${range.startDate?.getSeconds()} - ${range.endDate?.getFullYear()}-${Number(range.endDate?.getMonth()) + 1}-${range.endDate?.getDate()} ${range.endDate?.getHours()}:${range.endDate?.getMinutes()}:${range.endDate?.getSeconds()}`
//     return value2.replace(/(^|\D)(\d)(?!\d)/g, '$10$2');
// }

const parseDateForPicker = (range: Range) => {
	return parseRangeToDefaultString(range, true).replace(",", " - ");
};

export const FormDateRangePicker = (props: Props): JSX.Element => {
	const {
		timeEnd,
		timeStart,
		timezone,
		name,
		label,
		errors,
		control,
		defaultValue,
		placeholder,
		onApply,
		minDate,
		maxDate,
		handleInternalChange,
		...controlProps
	} = props;
	const {
		field: { value },
	} = useController({
		name,
		control,
	});
	if (!value && props.onChange) {
		props.onChange(parseDateForPicker(parseStringToRange()));
	}

	const handleChange = (
		range: Range,
		props: {
			field: ControllerRenderProps<any, string>;
			fieldState: ControllerFieldState;
			formState: UseFormStateReturn<any>;
		}
	) => {
		if (!range.startDate && !range.endDate) {
			if (handleInternalChange) {
				handleInternalChange(null);
			} else props.field.onChange(null);
		} else {
			const rangeString = parseDateForPicker(range);
			if (handleInternalChange) {
				handleInternalChange(rangeString);
			} else {
				props.field.onChange(rangeString);
			}
		}
	};

	return (
		<Controller
			name={name}
			render={(props) => {
				const displayRange = parseStringToRange(props.field.value, undefined, timezone);
				return (
					<Form.Group className="form-group" controlId={name}>
						{label && <Form.Label>{label}</Form.Label>}
						<DateRangePicker
							timeEnd={timeEnd}
							timeStart={timeStart}
							name={name}
							errors={errors}
							placeholder={placeholder}
							onApply={onApply}
							minDate={minDate}
							maxDate={maxDate}
							range={displayRange}
							onChangeRange={(range) => handleChange(range, props)}
						/>
					</Form.Group>
				);
			}}
			defaultValue={defaultValue}
			control={control}
			{...controlProps}
		/>
	);
};
