import React, { Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { AppLinkApi, ItemAppLinkApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { RenderLimitedContent } from "../../components/RenderLimitedContent";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "ITEM_APP_LINK";
const resourceType = "ITEM_APP_LINK";

const List = ({ resource, mobileActions }: ListState): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<ItemAppLinkApi[]>([]);
	const segmentContextValue = React.useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "name",
				name: t("modules.app_link.field.name.title"),
				render: (item: ItemAppLinkApi) => {
					return (
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/menu/item_groups/${item.item.item_group_id}`}
						>
							{item.item.name}
						</Link>
					);
				},
				renderExport: (item: ItemAppLinkApi) => {
					return item.item.name;
				},
			},
			{
				id: "linked_with",
				name: t("modules.app_link.field.linked_with.title"),
				render: (item: ItemAppLinkApi) => <RenderLimitedContent key={item.item.id} item={item} />,
				renderExport: (item: ItemAppLinkApi) => {
					return item.apps
						.map(
							(app) =>
								`${app.app.name} (${t(`enums.apps.providers.${app.app.provider}`)}) ${
									app.app_link?.external_name ?? t("modules.app_link.field.unlinked.title")
								}`
						)
						.join(",\n");
				},
				disableSorting: true,
			},
		],
		filters: [
			{
				id: "app",
				name: t("modules.app_link.field.app.title"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getAppsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: AppLinkApi) => {
						return `${item.label} (${t(`enums.apps.providers.${item.provider}`)})`;
					},
				},
			},
			{
				id: "id",
				name: t("modules.app_link.field.app.item"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "linked",
				name: t("modules.app_link.field.app.linked"),
				type: "boolean",
			},
		],
		selectedColumns: ["name", "linked_with"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.app_link.field.export_config.title"),
			filename: t("modules.app_link.header.title"),
			organization: organization.name,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "app,resource";
			return api.organization().getItemLinks(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return (
		<>
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.app_link.header.title")}
						description={t("modules.app_link.field.empty_list_description.title")}
					/>
				}
				onFetch={(fetchItems: ItemAppLinkApi[]) => {
					setItems(fetchItems);
				}}
				mobileActions={mobileActions}
			/>
		</>
	);
};

export const OrganizationAppsItemsPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContextValue = React.useContext(SegmentContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(`${t("modules.app.field.items.title")} | ${t("modules.app.header.title")}`);
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	const syncApplications = async () => {
		setLoading(true);
		try {
			await api.organization().syncApps();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}

		setLoading(false);
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.sync", { ns: "lib" }),
			loading,
			variant: "primary",
			action: () => syncApplications(),
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.sync", { ns: "lib" }),
			action: () => syncApplications(),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.app_link.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
