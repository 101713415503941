import React, { FC, useState } from "react";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientApi, ClientGroupApi } from "../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../services/Api/api";
import ClientGroupModalForm from "./ClientGroupModalForm";

interface Props {
	onHide: () => void;
	handleCreate: (client: ClientApi) => void;
	client: ClientApi;
	onSuccessfulAction?: (client: ClientApi) => void;
}

const ClientModalForm: FC<Props> = ({ onHide, handleCreate, client, onSuccessfulAction }) => {
	const form = useForm<ClientApi>({
		defaultValues: client,
		criteriaMode: "all",
	});
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		formState,
		register,
		control,
		formState: { errors },
		setError,
		setValue,
		handleSubmit,
		getValues,
		clearErrors,
	} = form;
	const [newClientGroup, setNewClientGroup] = useState<ClientGroupApi>();
	const [showClientGroupModal, setShowClientGroupModal] = useState(false);
	const [searchingClient, setSearchingClient] = useState(false);
	const organization = useSelector(selectOrganization);

	const searchGroups = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientGroupsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onClientGroupCreate = (inputValue: string) => {
		setNewClientGroup({ name: inputValue } as ClientGroupApi);
		setShowClientGroupModal(true);
	};

	const handleClientGroupSave = (clientGroup: ClientGroupApi) => {
		setValue("client_group_id", clientGroup.id);
		setShowClientGroupModal(false);
		setNewClientGroup(clientGroup);
	};

	const searchClient = async () => {
		const taxIdNo = getValues("tax_id_no");
		setSearchingClient(true);
		try {
			const res = await api.organization().getClientDataByTaxIdNo(taxIdNo);
			setValue("address_city", res.address_city);
			setValue("address_build_nr", res.address_build_nr);
			setValue("name", res.name);
			setValue("address_country", res.address_country);
			setValue("address_street", res.address_street);
			setValue("address_zip_code", res.address_zip_code);
			setValue("address_flat_nr", res.address_flat_nr);
			setSearchingClient(false);
			clearErrors("tax_id_no");
		} catch (err) {
			setSearchingClient(false);
			handleError.form(err, setError, addFlash);
		}
	};

	const onSubmit = handleSubmit(async (data: ClientApi) => {
		setLoading(true);
		try {
			const res = await api.organization().createClient(data);
			setLoading(false);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleCreate(res);
			if (onSuccessfulAction) onSuccessfulAction(res);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	return (
		<Modal size={"lg"} show={true} onHide={onHide}>
			<FormDirty formState={formState} noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.client.header.new.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<fieldset className="form-group">
						<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.action.name", { ns: "lib" })}
									register={register}
									name="name"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<InputGroup className={"form-input-group"}>
									<FormInput
										name="tax_id_no"
										label={t("common.word.tax_id_no.title", { ns: "lib" })}
										register={register}
										errors={errors}
									/>
									<div className="input-group-append">
										<ButtonLoading
											loading={searchingClient}
											style={{ height: "31px", border: "1px solid #ced4da" }}
											onClick={() => searchClient()}
											variant={"primary"}
										>
											{t("common.word.tax_id_no.search", { ns: "lib" })}
										</ButtonLoading>
									</div>
								</InputGroup>
							</div>
							<div className="col-md-6">
								<FormSelectGroup
									label={t("modules.client.field.client_group.title")}
									name="client_group_id"
									errors={errors}
									getOptionLabel={(option) => option.label}
									path={`/${organization.id}/clients/client-groups/`}
									onCreateOption={onClientGroupCreate}
									getOptionValue={(option) => option.id}
									defaultValue={newClientGroup}
									loadOptions={searchGroups}
									control={control}
									data-testid="client_group_id"
								/>
								{showClientGroupModal && (
									<ClientGroupModalForm
										clientGroup={newClientGroup}
										handleClose={() => setShowClientGroupModal(false)}
										handleSave={handleClientGroupSave}
									/>
								)}
							</div>
						</div>
					</fieldset>
					<fieldset className="form-group">
						<h5>{t("common.word.address", { ns: "lib" })}</h5>
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.address.street", { ns: "lib" })}
									register={register}
									name="address_street"
									errors={errors}
								/>
							</div>
							<div className="col-md-3">
								<FormInput
									label={t("common.address.build_nr", { ns: "lib" })}
									register={register}
									name="address_build_nr"
									errors={errors}
								/>
							</div>
							<div className="col-md-3">
								<FormInput
									label={t("common.address.flat_nr", { ns: "lib" })}
									register={register}
									name="address_flat_nr"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("common.address.zip_code", { ns: "lib" })}
									register={register}
									name="address_zip_code"
									errors={errors}
								/>
							</div>

							<div className="col-md-6">
								<FormInput
									label={t("common.address.city", { ns: "lib" })}
									register={register}
									name="address_city"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("common.address.country", { ns: "lib" })}
									register={register}
									name="address_country"
									errors={errors}
								/>
							</div>
						</div>
					</fieldset>
					<fieldset className="form-group">
						<h5>{t("modules.client.field.contact.title")}</h5>
						<div className="row">
							<div className="col-md-6">
								<FormInput
									label={t("common.word.phone_number", { ns: "lib" })}
									register={register}
									name="contact_phone_number"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("common.word.email", { ns: "lib" })}
									register={register}
									name="contact_email"
									errors={errors}
								/>
							</div>
							<div className="col-md-6">
								<FormInput
									label={t("modules.client.field.others.title")}
									register={register}
									name="contact_other"
									errors={errors}
								/>
							</div>
						</div>
					</fieldset>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default ClientModalForm;
