import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import OrganizationClientsIndexEditPage from "./pages/Edit";
import OrganizationClientsIndexImportPage from "./pages/Import";
import OrganizationClientsIndexIndexPage from "./pages/Index";
import OrganizationClientsIndexNewPage from "./pages/New";
import OrganizationClientsIndexPreviewPage from "./pages/Preview";

const OrganizationClientsIndexPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationClientsIndexIndexPage} />
				<Route exact path={`${props.match.url}/new`} component={OrganizationClientsIndexNewPage} />
				<Route exact path={`${props.match.url}/import`} component={OrganizationClientsIndexImportPage} />
				<Route path={`${props.match.url}/:clientId/edit`} component={OrganizationClientsIndexEditPage} />
				<Route path={`${props.match.url}/:clientId`} component={OrganizationClientsIndexPreviewPage} />
				<Route component={Page404} />
			</Switch>
		</div>
	);
};
export default OrganizationClientsIndexPage;
