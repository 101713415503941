import React, { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { PosReportPaidApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
	paid: PosReportPaidApi;
	handleSave: (data: PosReportPaidApi) => void;
	reportId: number;
}

const PaidEditModal: FC<Props> = ({ paid, onHide, handleSave, reportId }) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const form = useForm<PosReportPaidApi>({
		criteriaMode: "all",
		defaultValues: { ...paid, employee_id: paid.employee?.id, client_id: paid.client?.id },
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";

	const onSubmit = handleSubmit(async (data: PosReportPaidApi) => {
		data.id = paid.id;
		setLoading(true);
		try {
			if (data.id) {
				const res = await api.organization().updatePosReportPaid(data, reportId);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				handleSave(res);
			} else {
				const res = await api.organization().createPosReportPaid(data, reportId);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				handleSave(res);
			}
		} catch (err) {
			setLoading(false);
			handleError.form(err, setError, addFlash);
		}
	});

	const searchClients = (search: string, obj: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientsSearchSelect(search, obj, {
			cancelToken: options?.token,
		});
	};

	const searchEmployees = (search: string, obj: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getEmployeesSearchSelect(search, obj, {
			cancelToken: options?.token,
		});
	};

	return (
		<Modal show={true} onHide={onHide}>
			<Form key="link-entity-modal" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{paid.id
							? `${t("modules.pos_report.action.paid_edit_modal.title")} ${
									paid.is_paid_in
										? t("modules.pos_report.field.paid_in.title")
										: t("modules.pos_report.field.paid_out.title")
							  }`
							: `${t("lib:common.action.add")} ${t("modules.pos_report.field.paid_in_or_out.title")}`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={"row"}>
						<div className={"col-md-12"}>
							<FormSelectGroup
								name="employee_id"
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								label={t("modules.pos_report.field.employee.title")}
								errors={errors}
								defaultValue={{
									label: paid.employee?.name,
									id: paid.employee?.id,
								}}
								loadOptions={searchEmployees}
								control={control}
								data-testid="employee_id"
							/>
						</div>
						<div className={"col-md-12"}>
							<FormSelectGroup
								name="client_id"
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								label={t("common.word.client")}
								errors={errors}
								defaultValue={{
									label: paid.client?.name,
									id: paid.client?.id,
								}}
								loadOptions={searchClients}
								control={control}
								data-testid="client_id"
							/>
						</div>
						<div className={"col-md-12"}>
							<FormMoneyInput
								label={t("common.word.amount")}
								currency={currency}
								name={"amount.amount"}
								errors={errors}
								control={control}
								allowNegative
							/>
						</div>
						<div className={"col-md-12"}>
							<FormInput
								label={t("lib:common.word.description")}
								name={"description"}
								rows={3}
								errors={errors}
								as={"textarea"}
								register={register}
							/>
						</div>
						{!paid.id && (
							<div className={"col-md-12"}>
								<FormCheck
									type={"switch"}
									label={t("modules.pos_report.field.is_accounting_document.title")}
									name={"is_accounting_document"}
									errors={errors}
									register={register}
								/>
							</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
export default PaidEditModal;
