import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { listFiltersDecode, listFiltersEncode } from "go-list/core/components/Filter/services/decoder";
import { parsePredefinedRangeToDefaultString } from "go-list/utils/daterangeutils";
import { ReportParamsType } from "./types";

export const listDataParams = (params: ReportParamsType): ReportParamsType => {
	return listDataParamsDefault(
		params,
		(defaultParams: ReportParamsType) => {
			const newParams = { ...defaultParams };
			if (newParams && newParams.filters) {
				newParams.filters = newParams.filters.map((param) => {
					const newParam = { ...param };
					const condition = param.condition;
					const value: string | null = param.value;
					const isValueParamADataRange = parsePredefinedRangeToDefaultString(value) === "";

					if (!isValueParamADataRange) newParam.value = parsePredefinedRangeToDefaultString(value);
					if (condition === "btd") newParam.condition = "bt";

					return newParam;
				});
			}
			return newParams;
		},
		true
	);
};
export const listDataParamsDefault = (
	params: ReportParamsType,
	parser?: (reportParams: ReportParamsType) => Record<string, any>,
	skipEncoding?: boolean
): Record<string, any> => {
	let newParams = { ...params };
	if (parser) newParams = parser(params);
	const paramsArray: Record<string, any> = {};
	if (newParams.selectedSegment) {
		paramsArray.s = newParams.selectedSegment;
	}
	if (newParams.columns) {
		paramsArray.c = newParams.columns.join(",");
	}
	if (newParams.filters && newParams.filters.length > 0) {
		const f = listFiltersEncode(newParams.filters, !skipEncoding);
		paramsArray.f = f;
	} else {
		paramsArray.f = "";
	}
	if (newParams.f) {
		paramsArray.f = newParams.f;
	}

	if (newParams.groups && newParams.groups.length > 0) {
		paramsArray.groups = newParams.groups.join(FILTER_VALUE_SEPARATOR);
	}

	if (newParams.groupColumn) {
		paramsArray.group_column = newParams.groupColumn;
	}

	if (newParams.chartType) {
		paramsArray.chart_type = newParams.chartType;
	}

	if (newParams.sort) {
		paramsArray.sort = newParams.sort;
	}

	return paramsArray;
};

export const listDataDecoderQuery = (urlSearchParams: URLSearchParams): ReportParamsType => {
	const f = urlSearchParams.get("f");
	const segment = urlSearchParams.get("s");
	const c = urlSearchParams.get("c");
	const groups = urlSearchParams.get("groups");
	const groupColumn = urlSearchParams.get("group_column");
	const sort = urlSearchParams.get("sort");
	const listParams: ReportParamsType = {};

	if (segment) {
		listParams.selectedSegment = segment;
	}
	if (f !== undefined && f !== null) {
		listParams.filters = listFiltersDecode(f, true);
	}
	if (c) {
		listParams.columns = c.split(",");
	}
	if (groups) {
		listParams.groups = groups.split(FILTER_VALUE_SEPARATOR);
	}
	if (groupColumn) {
		listParams.groupColumn = groupColumn;
	}

	if (sort) {
		listParams.sort = sort;
	}

	return listParams;
};
