import React from "react";
import { AxiosResponse } from "axios";
import { TFunction } from "react-i18next";
import { ItemResponse, ListResponse } from "go-core/api/types";
import { WebhookApi } from "./types";

export interface WebhookContextProps {
	all: (
		params?: Record<string, any>,
		options?: Record<string, any>
	) => Promise<AxiosResponse<ListResponse<WebhookApi>>>;
	get: (id: string, params?: Record<string, any>) => Promise<AxiosResponse<ItemResponse<WebhookApi>>>;
	create: (data: WebhookApi) => Promise<AxiosResponse<ItemResponse<WebhookApi>>>;
	update: (data: WebhookApi) => Promise<AxiosResponse<ItemResponse<WebhookApi>>>;
	getResourceTypeOptions: (search?: string, options?: Record<string, any>) => Promise<string[]>;
}

export class WebhookService {
	_api: WebhookContextProps;
	_resourceTypeTranslationPrefix: string;
	_t: TFunction;

	constructor(api: WebhookContextProps, resourceTypeTranslationPrefix: string, t: TFunction) {
		this._api = api;
		this._resourceTypeTranslationPrefix = resourceTypeTranslationPrefix;
		this._t = t;
	}

	api(): WebhookContextProps {
		return this._api;
	}

	getTypeTitle(id: string): string {
		return this._t(`${this._resourceTypeTranslationPrefix}.${id}`);
	}
}

export const WebhookContext = React.createContext<WebhookService>({} as WebhookService);
