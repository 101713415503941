import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";

const OrganizationSettingsAdvancedToolsPage = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [force, setForce] = useState<boolean>(false);
	const [onlyClose, setOnlyClose] = useState<boolean>(false);
	const [closePaid, setClosePaid] = useState<boolean>(false);
	const [all, setAll] = useState<boolean>(false);
	const [orderId, setOrderId] = useState<number | undefined>(undefined);
	const { addFlash } = useFlash();
	const { t } = useTranslation();

	const fixTransactions = async () => {
		setLoading(true);
		try {
			await apiOrganization.toolsFixTransactions(orderId, force, all, onlyClose, closePaid);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};
	return (
		<>
			<Form.Group className="form-group">
				<Form.Label>{t("modules.setting.field.all_incorrect.title")}</Form.Label>
				<Form.Check checked={all} onChange={() => setAll(!all)} />
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>{t("modules.setting.action.force_payment_ending.title")}</Form.Label>
				<Form.Check checked={force} onChange={() => setForce(!force)} />
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>{t("modules.setting.field.order_id.title")}</Form.Label>
				<Form.Control value={orderId} onChange={(e) => setOrderId(Number(e.target.value))} />
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>{t("modules.setting.action.pay_and_close_as_fiscalized.title")}</Form.Label>
				<Form.Check checked={onlyClose} onChange={() => setOnlyClose(!onlyClose)} />
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>{t("modules.setting.action.cloase_as_fiscalized_and_paid.title")}</Form.Label>
				<Form.Check checked={closePaid} onChange={() => setClosePaid(!closePaid)} />
			</Form.Group>
			<ButtonLoading loading={loading} onClick={fixTransactions} className={"btn btn-default"}>
				{t("modules.setting.action.fix_transactions.title")}
			</ButtonLoading>
		</>
	);
};

export default OrganizationSettingsAdvancedToolsPage;
