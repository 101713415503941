import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import { EOrderPage } from "./pages/EOrder";

const PublicPage = ({ match: { url } }: RouteComponentProps): JSX.Element => {
	return (
		<Switch>
			<Route path={`${url}/orders/:orderId`} component={EOrderPage} />
			<Page404 />
		</Switch>
	);
};

export default PublicPage;
