import React, { FC } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";

interface Props {
	menuErrors: Record<string, any>[];
}

const GoOrderMenuErrors: FC<Props> = ({ menuErrors }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	menuErrors = menuErrors
		.filter((f) => f.error !== "unauthorized" && f.code !== "connection_error_reinstall_required")
		.filter((f) => f.app_link_id);
	return (
		<>
			{menuErrors.length > 0 && (
				<Alert variant={"danger"}>
					<div className={"d-flex flex-column"}>
						{menuErrors.map((err, index) => {
							return (
								<span key={`err-${index}`}>
									{err.code === "wrong_tax_amount" && (
										<Link
											target={"_blank"}
											rel={"noreferrer"}
											to={`/${organization.id}/menu/item_groups/${err.item_id}`}
										>
											{t("constraints.wrong_tax_amount")}. {t("constraints.product_invalid_tax")}
										</Link>
									)}
									{err.code === "entity_with_name_exist" && (
										<>
											<Link
												to={`/${organization.id}/apps/linking?f=${btoa(
													unescape(
														encodeURIComponent(
															`${NEW_WAY_TO_ENCODING_FILTER_SIGN}id|e=${err.app_link_id}${FILTER_SEPARATOR}resource_type|e=${err.app_link_resource_type}`
														)
													)
												)}`}
												target={"_blank"}
												rel={"noreferrer"}
											>
												{t("constraints.entity_with_name_exist")}. {t("common.action.link")}{" "}
												{t(`enums.apps.resource_types.${err.app_link_resource_type}`)}
											</Link>
										</>
									)}
									{err.code === "category_with_name_exists" && (
										<>
											<Link to={`/${organization.id}/menu/categories/${err.item_id}`}>
												{t("constraints.category_with_name_exists")}
											</Link>
										</>
									)}
									{err.code === "modifier_group_with_name_exists" && (
										<>
											<Link to={`/${organization.id}/menu/modifier_groups/${err.item_id}`}>
												{t("constraints.modifier_group_with_name_exists")}
											</Link>
										</>
									)}
									{err.code === "item_with_name_exists" && (
										<>
											<Link to={`/${organization.id}/menu/item_groups/${err.item_id}`}>
												{t("constraints.item_with_name_exists")}
											</Link>
										</>
									)}
									{err.code === "availability_with_name_exists" && (
										<>
											<Link to={`/${organization.id}/settings/availabilities/${err.item_id}`}>
												{t("constraints.availability_with_name_exists")}
											</Link>
										</>
									)}
									{err.code === "menu_with_name_exists" && (
										<>
											<Link to={`/${organization.id}/menu/menus`}>
												{t("constraints.menu_with_name_exists")}
											</Link>
										</>
									)}
								</span>
							);
						})}
					</div>
				</Alert>
			)}
		</>
	);
};
export default GoOrderMenuErrors;
