import { getResourceStatusClass } from "go-app/components/FormatResourceStatus/FormatResourceStatus";

export const getItemStatus = (itemStatus: string): "ENABLED" | "DISABLED" | "WARNING" | undefined => {
	const statusType = getResourceStatusClass(itemStatus);

	switch (statusType) {
		case "primary":
		case "success":
			return "ENABLED";
		case "danger":
			return "DISABLED";
		case "warning":
			return "WARNING";
	}
};
