import { apiPublic } from "../../../../../services/Api/Public/apiPublic";

export const downloadEReceipt = async (filename: string, orderId?: string): Promise<void> => {
	if (!orderId) {
		return;
	}

	const response = await apiPublic.getEReceipt(orderId);
	const href = URL.createObjectURL(response.data);

	const link = document.createElement("a");
	link.href = href;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	URL.revokeObjectURL(href);
};
