import { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as ClientLogo } from "../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as HomeSVG } from "../../../../../../../../images/svg/bills/home.svg";
import { ReactComponent as MessageSVG } from "../../../../../../../../images/svg/bills/message.svg";
import { ReactComponent as TaxSVG } from "../../../../../../../../images/svg/bills/tax.svg";
import { InvoiceIssuerApi } from "../../../../../../../../services/Api/Organization/types";
import { AddressApi } from "../../../../../../../../services/Api/types";

interface Props {
	issuer: InvoiceIssuerApi;
	issuer_id?: number;
}

const InvoiceIssuerCard: FC<Props> = ({ issuer, issuer_id }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	return (
		<Card className={"card-full-height"}>
			<Card.Body>
				<h5>{t("modules.invoice.field.seller.title")}</h5>
				<div className="card-body-item">
					{issuer?.avatar_link ? (
						<img
							src={issuer.avatar_link?.small}
							style={{ marginRight: "8px", width: "24px", borderRadius: "50%" }}
						/>
					) : (
						<ClientLogo />
					)}
					<div className="item-info">
						<span>
							{issuer_id ? (
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/clients/${issuer_id}`}
								>
									{issuer.name}
								</Link>
							) : (
								<>{issuer.name}</>
							)}
						</span>
					</div>
				</div>
				{issuer.address &&
					Object.keys(issuer.address).some(
						(x) => issuer.address[x as keyof AddressApi] !== "" && issuer.address.country !== "PL"
					) && (
						<div className="card-body-item">
							<HomeSVG />
							<div className="item-info">
								<span>
									<FormatAddress address={issuer.address} />
								</span>
							</div>
						</div>
					)}
				{issuer.tax_id_no && (
					<div className="card-body-item">
						<TaxSVG />
						<div className="item-info">
							<span>{issuer.tax_id_no}</span>
						</div>
					</div>
				)}
				{issuer.contact?.email && (
					<div className="card-body-item">
						<MessageSVG />
						<div className="item-info">
							<span>{issuer.contact.email}</span>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default InvoiceIssuerCard;
