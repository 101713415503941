import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import { AdminJobs } from "go-component/components/AdminJob";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { useWindowSize } from "go-core/components/useWindowSize";
import { api } from "../../../../../../services/Api/api";

export const JobsIndexPage = () => {
	const isMobile = useWindowSize();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("go_component.job.header.title", { ns: "lib" }));
	}, []);

	return (
		<div className={"page-container"}>
			{isMobile && <SubNavigationItems title={t("go_component.job.header.title", { ns: "lib" })} items={[]} />}
			<div className="content">
				<AdminJobs fetchRequest={api.getJobs} />
			</div>
		</div>
	);
};
