import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyData from "go-core/components/EmptyData";
import { MenuApi } from "../../../../../../../../../services/Api/Organization/types";
import MenuModalForm from "./MenuModalForm";

interface Props {
	handleSaveMenu: (data: MenuApi, id?: number) => void;
}

const NoMenu = (props: Props): JSX.Element => {
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<EmptyData
				title={t("modules.menu.field.empty_list.title")}
				description={t("modules.menu.field.empty_list.helptext.description")}
				actions={[
					{
						name: t("modules.menu.action.create_menu.title"),
						click: () => {
							setShowModal(true);
						},
					},
				]}
			/>
			{showModal && (
				<MenuModalForm
					menu={{ status: "ENABLED" } as MenuApi}
					handleClose={() => setShowModal(false)}
					handleSave={props.handleSaveMenu}
				/>
			)}
		</>
	);
};
export default NoMenu;
