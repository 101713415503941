import { AddressApi } from "../../../../../services/Api/types";

export const getAddressString = (address?: AddressApi): string => {
	if (!address) {
		return "";
	}

	const { street, build_nr, flat_nr, zip_code, city } = address;

	if (!build_nr || !city) {
		return "";
	}

	let addressString = "";

	if (street) {
		addressString += `ul. ${street}`;
	}

	if (build_nr) {
		addressString += ` ${build_nr}`;

		if (flat_nr) {
			addressString += `/${flat_nr}`;
		}
	}

	if (addressString !== "" && city) {
		addressString += ", ";

		if (zip_code) {
			addressString += `${zip_code} `;
		}

		addressString += `${city}`;
	}

	return addressString.trim();
};
