import { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { Loading } from "go-core/components/Loading";
import { ReactComponent as DotSVG } from "../../../../../../../../../../images/svg/dot.svg";
import { EmployeeApi, EmployeeInfoApi } from "../../../../../../../../../../services/Api/Organization/types";

export interface Props {
	employee: EmployeeApi;
	employeeInfo: EmployeeInfoApi;
	lastActivity?: string;
	loadingActivities: boolean;
}

const InfoCard: FC<Props> = ({ employee, employeeInfo, lastActivity, loadingActivities }) => {
	const { t } = useTranslation();
	const [showCode, setShowCode] = useState(false);
	return (
		<Card>
			<Card.Body>
				<h5>{t("lib:common.word.informations")}</h5>
				{employee.card_code && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.employee.field.card_code.title")}</span>
							<span>{employee.card_code}</span>
						</div>
					</div>
				)}
				{employee.code && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.employee.field.code.title")}</span>
							<span className={"d-flex"}>
								{showCode ? (
									<>{employee.code}</>
								) : (
									<>
										<DotSVG />
										<div className={"action-ref"} onClick={() => setShowCode(!showCode)}>
											{t("common.word.show")}
										</div>
									</>
								)}
							</span>
						</div>
					</div>
				)}
				{employeeInfo.working_since && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.employee.field.work_since.title")}</span>
							<span>
								{" "}
								{FormatDate(employeeInfo.working_since, undefined, false, {
									year: "numeric",
									day: "2-digit",
									month: "2-digit",
								})}
							</span>
						</div>
					</div>
				)}
				{employeeInfo.last_check_in && (
					<div className="card-body-item">
						<div className="item-info">
							<span className="label">{t("modules.employee.field.last_check_in.title")}</span>
							<span>{FormatDate(employeeInfo.last_check_in)}</span>
						</div>
					</div>
				)}
				{loadingActivities ? (
					<Loading />
				) : (
					lastActivity && (
						<div className="card-body-item">
							<div className="item-info">
								<span className="label">{t("modules.employee.field.last_activity.title")}</span>
								<span>
									{lastActivity ? (
										<>{FormatDate(lastActivity)}</>
									) : (
										t("modules.employee.field.lack_of_last_activity.title")
									)}
								</span>
							</div>
						</div>
					)
				)}
			</Card.Body>
		</Card>
	);
};
export default InfoCard;
