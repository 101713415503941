import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { JobContext, JobContextProps, JobService } from "go-component/components/Job/services/context";
import { apiOrganizationComponent } from "../../../../../../../../services/Api/Organization/apiOrganizationComponent";
import OrganizationLogsJobsEditPage from "./pages/Edit";
import OrganizationLogsJobsIndexPage from "./pages/Index";

const OrganizationLogsJobsPage: FC<RouteComponentProps> = (props) => {
	const translationPrefix = "";
	const { t } = useTranslation();

	return (
		<JobContext.Provider
			value={
				new JobService(
					{
						all: apiOrganizationComponent.getJobs,
						get: apiOrganizationComponent.getJob,
						cancel: apiOrganizationComponent.cancelJob,
						getNameOptions: apiOrganizationComponent.getJobNames,
					} as JobContextProps,
					translationPrefix,
					t
				)
			}
		>
			<Switch>
				<Route exact={true} path={props.match.url} component={OrganizationLogsJobsIndexPage} />
				<Route path={`${props.match.url}/:job_id`} component={OrganizationLogsJobsEditPage} />
			</Switch>
		</JobContext.Provider>
	);
};

export default OrganizationLogsJobsPage;
