import { TFunction } from "react-i18next";
import { FilterCondition, ListConfigFilter, ListConfigFilterOption } from "./types";

export const textFilterOptions = (t: TFunction): ListConfigFilterOption[] => [
	{
		id: "c",
		name: t("lib:go_list.filters.c"),
		isValue: true,
	},
	{
		id: "e",
		name: t("lib:go_list.filters.equals"),
		isValue: true,
	},
	{
		id: "a",
		name: t("lib:go_list.filters.any"),
		isValue: false,
	},
	{
		id: "u",
		name: t("lib:go_list.filters.unknown"),
		isValue: false,
	},
];

export const booleanFilterOptions = (t: TFunction): ListConfigFilterOption[] => [
	{
		id: "true",
		name: t("lib:common.word.yes"),
		isValue: true,
	},
	{
		id: "false",
		name: t("lib:common.word.no"),
		isValue: true,
	},
];

export const fullBooleanFilterOptions = (t: TFunction): ListConfigFilterOption[] => [
	...booleanFilterOptions(t),
	{
		id: "a",
		name: t("lib:go_list.filters.any"),
		isValue: false,
	},
	{
		id: "u",
		name: t("lib:go_list.filters.unknown"),
		isValue: false,
	},
];

export const dateFilterOptions = (t: TFunction): ListConfigFilterOption[] => [
	{
		id: "gte",
		name: t("lib:go_list.filters.from"),
		isValue: true,
	},
	{
		id: "lte",
		name: t("lib:go_list.filters.to"),
		isValue: true,
	},
	{
		id: "bt",
		name: t("lib:go_list.filters.range"),
		isValue: true,
	},
];

export const fullDateFilterOptions = (
	currentOptions: ListConfigFilterOption[],
	t: TFunction
): ListConfigFilterOption[] => [
	...currentOptions,
	{
		id: "a",
		name: t("lib:go_list.filters.any"),
		isValue: false,
	},
	{
		id: "u",
		name: t("lib:go_list.filters.unknown"),
		isValue: false,
	},
];

export const numberFilterOptions = (t: TFunction): ListConfigFilterOption[] => [
	{
		id: "gt",
		name: t("lib:go_list.filters.gt_short"),
		isValue: true,
	},
	{
		id: "lt",
		name: t("lib:go_list.filters.lt_short"),
		isValue: true,
	},
	{
		id: "e",
		name: t("lib:go_list.filters.e_number"),
		isValue: true,
	},
	{
		id: "ne",
		name: t("lib:go_list.filters.ne_number"),
		isValue: true,
	},
	{
		id: "bt",
		name: t("lib:go_list.filters.range"),
		isValue: true,
	},
];

export const fullNumberFilterOptions = (t: TFunction): ListConfigFilterOption[] => [
	...numberFilterOptions(t),
	{
		id: "a",
		name: t("lib:go_list.filters.any"),
		isValue: false,
	},
	{
		id: "u",
		name: t("lib:go_list.filters.unknown"),
		isValue: false,
	},
];

export const getLimitedOptions = (
	options: ListConfigFilterOption[],
	defaultOptions: FilterCondition[],
	selectedOptions?: FilterCondition[],
	extraOptions?: FilterCondition[]
): ListConfigFilterOption[] => {
	if (selectedOptions) {
		return options.filter((x) => selectedOptions.includes(x.id as FilterCondition));
	}

	if (extraOptions) {
		return options.filter(
			(x) => defaultOptions.includes(x.id as FilterCondition) || extraOptions.includes(x.id as FilterCondition)
		);
	}

	return options.filter((x) => defaultOptions.includes(x.id as FilterCondition));
};

export const getAllUniqueFilters = (filters: ListConfigFilter[]): ListConfigFilter[] => {
	return filters
		.map((f) => {
			if (f?.resources && f?.resources?.length > 0) {
				return f.resources.map((r) => ({
					...f,
					resources: [r],
				}));
			}
			return [f];
		})
		.flat();
};

export const getFilterObjectWithParsedId = (filter: ListConfigFilter): ListConfigFilter => {
	let id = filter.id;
	if (filter?.resources && filter?.resources.length > 0) {
		const resources = filter.resources[0].resource;
		id = `${filter.id}.${resources}`;
	}
	return {
		...filter,
		id,
	};
};

export const getFilterName = (filter: ListConfigFilter, allUniqueParsedFilters: ListConfigFilter[]) => {
	const uniqueFilter = allUniqueParsedFilters.find((x) => x.id === filter.id);
	let name = uniqueFilter?.name || "";
	if (uniqueFilter?.resources && uniqueFilter?.resources[0]?.name) name += ` (${uniqueFilter?.resources[0]?.name})`;
	return name;
};

export const FILTER_VALUE_SEPARATOR = "¥";
export const FILTER_SEPARATOR = "ç";
export const NEW_WAY_TO_ENCODING_FILTER_SIGN = "_";
export const AMPERSAND_VALUE = "¿";
