import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import SecurityRemoveOrganizationFormComponent from "./components/RemoveOrganizationForm";

interface Props {
	baseUrl: string;
}

const SecurityOrganizationRemoveOrganizationComponent = ({ baseUrl }: Props): JSX.Element => {
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_component.remove_organization.header.title"));
	}, []);

	return (
		<>
			<Header title={t("lib:go_component.remove_organization.header.title")} />
			<SecurityRemoveOrganizationFormComponent baseUrl={baseUrl} />
		</>
	);
};
export default SecurityOrganizationRemoveOrganizationComponent;
