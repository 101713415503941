import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps } from "react-router";
import { selectOrganization } from "go-security/services/organizations/selectors";
import OrganizationLiveOrdersMapOrderPreviewPage from "../../../Map/pages/OrderPreview";
import LiveOrdersList from "../../components/LiveOrdersList";

const OrganizationLiveOrdersListIndexPage: FC<RouteComponentProps> = (props) => {
	const organization = useSelector(selectOrganization);
	const orderListRoutePath = `/${organization.id}/live_orders/list`;

	return (
		<div className="live-orders-list-container">
			<Route
				path={`${props.match.url}/orders/:orderId`}
				render={() => (
					<OrganizationLiveOrdersMapOrderPreviewPage
						isVisible
						orderListRoutePath={orderListRoutePath}
						setFocusedOrder={() => {}}
					/>
				)}
			/>
			<div className="live-orders-list-grid-list-container">
				<LiveOrdersList />
			</div>
		</div>
	);
};
export default OrganizationLiveOrdersListIndexPage;
