import React from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps } from "react-router";
import { selectOrganization } from "go-security/services/organizations/selectors";
import OrganizationLiveOrdersMapOrderPreviewPage from "../../../Map/pages/OrderPreview";
import LiveOrderTable from "../../components/LiveOrderTable";

const OrganizationLiveOrdersTableIndexPage = (props: RouteComponentProps) => {
	const organization = useSelector(selectOrganization);
	const orderListRoutePath = `/${organization.id}/live_orders/table`;

	return (
		<div className="live-orders-list-container">
			<Route
				path={`${props.match.url}/orders/:orderId`}
				render={() => (
					<OrganizationLiveOrdersMapOrderPreviewPage
						isVisible
						orderListRoutePath={orderListRoutePath}
						setFocusedOrder={() => {}}
					/>
				)}
			/>
			<div className="live-orders-list-grid-list-container">
				<LiveOrderTable />
			</div>
		</div>
	);
};
export default OrganizationLiveOrdersTableIndexPage;
