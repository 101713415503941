import React, { FC, useEffect, useRef, useState } from "react";
import { DraggableEvent } from "react-draggable";
import { UseFormReturn } from "react-hook-form";
import { DraggableData, Position, ResizableDelta, Rnd } from "react-rnd";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import { ReactComponent as WCSVG } from "../../../../../../../../../../../images/svg/rooms/wc.svg";
import { RoomElementApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { useKeyDown } from "../hooks/useKeyDown";
import {
	getElementPositionMovedByKeyPress,
	getPixelValue,
	getRoomItemStyles,
	getXScale,
	getYScale,
} from "../utils/utils";
import ElementEditModal from "./ElementEditModal";
import { RoomFormProps } from "./RoomsPreview";
import RoomElementRectangle from "./elements/RoomElementRectangle";
import CopyHandler from "./handlers/CopyHandler";
import EditHandler from "./handlers/EditHandler";
import RemoveHandler from "./handlers/RemoveHandler";
import RotateHandler from "./handlers/RotateHandler";

interface Props {
	element: RoomElementApi;
	form: UseFormReturn<RoomFormProps>;
	elementIndex: number;
	roomIndex: number;
	handleDirty: () => void;
}

const RoomElement: FC<Props> = ({ element, form, elementIndex, roomIndex, handleDirty }) => {
	const [showModal, setShowModal] = useState(false);
	const {
		setValue,
		getValues,
		reset,
		formState: { errors },
		clearErrors,
	} = form;
	const [editMode, setEditMode] = useState(false);
	const ref = useRef<any>(null);

	const defaultStyles = {
		width: `${element.width}px`,
		height: `${element.height}px`,
		background: element.color,
		borderRadius: "6px",
	};
	const [pos, setPos] = useState({
		x: element.position_left,
		y: element.position_top,
	});
	const [styles, setStyles] = useState(defaultStyles);

	const handleResize = (
		e: MouseEvent | TouchEvent,
		dir: string,
		ref: HTMLElement,
		delta: ResizableDelta,
		position: Position
	) => {
		setValue(
			`rooms.${roomIndex}.elements.${elementIndex}`,
			{
				...(getValues(`rooms.${roomIndex}.elements.${elementIndex}`) as RoomElementApi),
				position_top: position.y,
				position_left: position.x,
				width: getPixelValue(ref.style.width) < 0 ? 0 : getPixelValue(ref.style.width),
				height: getPixelValue(ref.style.height) < 0 ? 0 : getPixelValue(ref.style.height),
			},
			{ shouldDirty: true }
		);
		handleDirty();
	};

	const onResize = (
		e: MouseEvent | TouchEvent,
		dir: string,
		ref: HTMLElement,
		delta: ResizableDelta,
		position: Position
	) => {
		setStyles({ ...styles, width: ref.style.width, height: ref.style.height });
		setPos(position);
	};

	const handleMove = (e: DraggableEvent, data: DraggableData) => {
		const x = getXScale(data.x < 0 ? 0 : data.x);
		const y = getYScale(data.y < 0 ? 0 : data.y);
		setValue(`rooms.${roomIndex}.elements.${elementIndex}.position_left`, x);
		setValue(`rooms.${roomIndex}.elements.${elementIndex}.position_top`, y);
		setPos({ x, y });
		handleDirty();
	};

	const handleClickOutside = (event: any) => {
		if (editMode) {
			const el = document.getElementById(ref.current.props.id);
			if (el && !el.contains(event.target)) {
				setEditMode(false);
			}
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	const elStyles = {
		...styles,
		height: `${getPixelValue(styles.height) - 4}px`,
		width: `${getPixelValue(styles.width) - 4}px`,
		margin: "2px",
	};

	useEffect(() => {
		setStyles({ ...styles, height: `${element.height}px`, width: `${element.width}px` });
		setPos({
			x: element.position_left,
			y: element.position_top,
		});
	}, [element]);

	const handleCopy = () => {
		const elements = getValues(`rooms.${roomIndex}.elements`) as RoomElementApi[];
		const copy = { ...element, position_left: 50, position_top: 50, id: undefined };
		setValue(`rooms.${roomIndex}.elements.${elements.length}`, copy);
		handleDirty();
	};

	const handleRemove = () => {
		const rooms = getValues(`rooms.${roomIndex}.elements`) as RoomElementApi[];
		const newElements = rooms.filter((f, index) => index !== elementIndex);
		const newObj = getValues().rooms.map((room, index) =>
			index === roomIndex
				? {
						...room,
						elements: newElements,
				  }
				: room
		);
		reset({ rooms: newObj });
		handleDirty();
	};

	const handleEdit = () => {
		setShowModal(true);
	};

	const onEdit = (data: RoomElementApi) => {
		clearErrors(`rooms.${roomIndex}.elements.${elementIndex}.name`);
		setValue(`rooms.${roomIndex}.elements.${elementIndex}`, {
			...(getValues(`rooms.${roomIndex}.elements.${elementIndex}`) as RoomElementApi),
			...data,
		});
		setShowModal(false);
		handleDirty();
	};

	const handleRotate = () => {
		const el = document.getElementById(`room-draggable-wrapper-element-${elementIndex}`);
		if (el) {
			el.style.height = `${element.width}px`;
			el.style.width = `${element.height}px`;
		}
		setStyles({ ...styles, width: `${element.height}px`, height: `${element.width}px` });
		setValue(`rooms.${roomIndex}.elements.${elementIndex}`, {
			...(getValues(`rooms.${roomIndex}.elements.${elementIndex}`) as RoomElementApi),
			width: element.height,
			height: element.width,
		});
		handleDirty();
	};

	const isVertical = () => getPixelValue(styles.height) > getPixelValue(styles.width);

	const handleMoveByKeyPress = (key: "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown") => {
		const { x, y } = getElementPositionMovedByKeyPress(key, pos.x, pos.y, roomIndex, element.height, element.width);
		setValue(`rooms.${roomIndex}.elements.${elementIndex}.position_left`, x);
		setValue(`rooms.${roomIndex}.elements.${elementIndex}.position_top`, y);
		setPos({ x, y });
		handleDirty();
	};

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowDown");
		},
		["ArrowDown"],
		editMode
	);

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowRight");
		},
		["ArrowRight"],
		editMode
	);

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowUp");
		},
		["ArrowUp"],
		editMode
	);

	useKeyDown(
		() => {
			handleMoveByKeyPress("ArrowLeft");
		},
		["ArrowLeft"],
		editMode
	);

	return (
		<>
			<Rnd
				ref={ref}
				style={{
					position: "absolute",
					background: editMode ? undefined : "inherit",
					zIndex: editMode ? 100 : 1,
				}}
				disableDragging={!editMode}
				enableResizing={editMode}
				onMouseDown={() => setEditMode(true)}
				cancel={".room-item-handler"}
				id={`room-draggable-wrapper-element-${elementIndex}`}
				bounds={".room-body"}
				className={"room-draggable-wrapper"}
				default={{
					x: element.position_left,
					y: element.position_top,
					width: element.width,
					height: element.height,
				}}
				position={pos}
				resizeHandleStyles={getRoomItemStyles()}
				minHeight={40}
				onResize={(e, dir, ref, delta, position) => onResize(e, dir, ref, delta, position)}
				onDragStop={(e, data) => handleMove(e, data)}
				onResizeStop={(e, dir, ref, delta, position) => handleResize(e, dir, ref, delta, position)}
			>
				{element.type === "TOILET" ? (
					<RoomElementRectangle styles={elStyles} className={"d-flex align-items-center"}>
						<span
							className={"noselect-text"}
							style={{ transform: isVertical() ? "rotate(90deg)" : undefined }}
						>
							<div className={"d-flex align-items-center"}>
								<WCSVG className={"me-2"} /> {element.name}
							</div>
						</span>
					</RoomElementRectangle>
				) : (
					<RoomElementRectangle styles={elStyles}>
						<span
							className={"noselect-text"}
							style={{ transform: isVertical() ? "rotate(90deg)" : undefined }}
						>
							{element.name}
						</span>
					</RoomElementRectangle>
				)}
				{editMode && (
					<>
						<RemoveHandler
							handleRemove={handleRemove}
							styles={element.position_left <= 0 ? { left: "-15px" } : undefined}
						/>
						<EditHandler handleEdit={handleEdit} />
						<CopyHandler handleCopy={handleCopy} />
						<RotateHandler
							handleRotate={handleRotate}
							styles={element.position_left <= 0 ? { left: "-15px" } : undefined}
						/>
					</>
				)}
				<div className={"room-element-errors"}>
					<FormErrorMessage errors={errors} name={`rooms.${roomIndex}.elements.${elementIndex}.name`} />
				</div>
			</Rnd>
			{showModal && (
				<ElementEditModal handleUpdate={onEdit} element={element} onHide={() => setShowModal(false)} />
			)}
		</>
	);
};
export default RoomElement;
