import React, { useEffect, useReducer, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { unstable_batchedUpdates } from "react-dom";
import { useHistory, useLocation } from "react-router";
import useFlash, { handleErrorForAlert } from "go-alert/AlertMessage";
import { MoneyApi } from "go-core/api/types";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { listFiltersEncode } from "go-list/core/components/Filter/services/decoder";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { ReportApi } from "go-report/core/services/types";
import { listDataFetch } from "go-report/reports/services/fetch-data";
import { reportDataPushHistorySearch } from "go-report/reports/services/push-history";
import { ReportParamsReducer, reportParamsInitialState } from "go-report/reports/services/reducer";
import { selectActiveSegment } from "go-report/reports/services/selectors";
import { ReportConfig, ReportParamsType } from "go-report/reports/services/types";
import { LiveOrderReportOrder } from "../types/types";
import LiveOrdersReportFilters from "./LiveOrdersReportFilters";
import LiveOrdersReportMap from "./LiveOrdersReportMap";

interface Props {
	config: ReportConfig;
}

const LiveOrdersReport = ({ config }: Props) => {
	const location = useLocation();
	const [state] = useReducer(ReportParamsReducer, reportParamsInitialState(config, location));
	const segment = selectActiveSegment(state);
	const { addFlash } = useFlash();
	const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const firstUpdate = useRef(true);
	const [reportData, setReportData] = useState<ReportApi>();
	const initialDataEmpty = useRef(true);

	const fetchCancellation = async (params: Record<string, any>) => {
		if (config?.fetch === undefined) return undefined;
		const CancelToken = axios.CancelToken;
		if (cancelTokenSource !== undefined) cancelTokenSource.cancel();
		const newCancelToken = CancelToken.source();
		setCancelTokenSource(newCancelToken);
		return config?.fetch(params, newCancelToken);
	};

	const updateListParamsFromSegment = (newState: ReportParamsType) => {
		if (!newState.filters && newState.selectedSegment) {
			const matchedSegment = state.segments?.find((f) => f.slug === newState.selectedSegment);
			if (matchedSegment && matchedSegment.filters) {
				const f = listFiltersEncode(matchedSegment.filters, true);
				newState = { ...newState, f };
			}
		}
		return newState;
	};

	const fetch = (params: ReportParamsType) => {
		setLoading(true);
		params = updateListParamsFromSegment(params);
		listDataFetch(params, fetchCancellation)
			.then((res) => {
				unstable_batchedUpdates(() => {
					setLoading(false);
					setReportData(res[0].data.data ? res[0].data.data : res[0].data);
					if (initialDataEmpty.current) {
						initialDataEmpty.current = false;
					}
				});
			})
			.catch((err) => {
				const mockData = {
					reports: [
						{
							group_by_type: "NONE",
							aggregate: {},
							sub_report: [],
						},
					],
				} as any;
				setReportData(mockData);
				if (err?.code !== "ERR_CANCELED") setLoading(false);
				handleErrorForAlert(err, addFlash);
			});
	};

	const getFilterWithValues = (): ListSelectedFilter[] => {
		const filters: ListSelectedFilter[] = [];
		const currentFilters = !state.filters && segment?.filters ? segment.filters : state.filters;
		currentFilters?.forEach((filter) => {
			if (filter.condition === "a" || filter.condition === "u") {
				filters.push(filter);
			} else if (filter.value) {
				filters.push(filter);
			}
		});
		return filters;
	};

	const usedFilters = getFilterWithValues()
		.map((x) => {
			if (x.condition === "a" || x.condition === "u") {
				return x.condition;
			}
			if (x.value) {
				return `${x.condition}${x.value}`;
			}
			return "";
		})
		.join(FILTER_VALUE_SEPARATOR);

	useEffect(() => {
		fetch(state);
		if (firstUpdate.current) {
			firstUpdate.current = false;
		} else if (history) {
			reportDataPushHistorySearch(history, state, config);
		}
	}, [usedFilters, segment, state.selectedSegment, state.groups]);

	if (loading) return <LoadingContainer />;

	const getParsedOrders = (): LiveOrderReportOrder[] => {
		if (!reportData) return [];

		const filteredReportsOnlyWithCoordinates = reportData.reports?.filter(
			(report) =>
				report.group_by_value?.name &&
				report.sub_report[0]?.group_by_value?.name &&
				!isNaN(Number(report.group_by_value.name)) &&
				!isNaN(Number(report.sub_report[0].group_by_value.name))
		);

		return filteredReportsOnlyWithCoordinates.map((report) => {
			const coordinates = {
				latitude: Number(report.group_by_value.name),
				longitude: Number(report.sub_report[0].group_by_value.name),
			};
			const totalPrice: MoneyApi = report.aggregate.sales.total_money;
			const ordersCount = report.aggregate.sales.transaction_count as number;

			return {
				coordinates,
				totalPrice,
				ordersCount,
			};
		});
	};

	return (
		<>
			<LiveOrdersReportFilters config={config} />
			<LiveOrdersReportMap orders={getParsedOrders()} />
		</>
	);
};

export default LiveOrdersReport;
