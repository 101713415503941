import { useEffect, useState } from "react";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import { DiagnoseApi, DiagnoseElementApi, DiagnosticsRequests } from "../../../services/types";
import { DiagnosticsForm } from "./DiagosticsForm";
import { DiagnosticsPreview } from "./DiagosticsPreview";

type Props = DiagnosticsRequests & {
	organization: Record<string, any>;
	diagnosticsTranslations?: Record<string, string>;
	renderDiagnseElementLink: (diagnoseElement: DiagnoseElementApi, id: string, title: string) => JSX.Element;
	diagnoseElementTitleKeyFormat: string;
};

export const DiagnosticsComponent = (props: Props): JSX.Element => {
	const [data, setData] = useState<string[] | undefined>(undefined);
	const [diagnoseData, setDiagnoseData] = useState<DiagnoseApi[] | undefined>(undefined);
	const { addFlash } = useFlash();
	const {
		getDiagnosticsTypes,
		diagnoseFix,
		diagnose,
		getDiagnoseProgress,
		organization,
		diagnosticsTranslations,
		renderDiagnseElementLink,
		diagnoseElementTitleKeyFormat,
	} = props;
	const { id: organizationId } = organization;

	useEffect(() => {
		onFetchDiagnosticsTypes();
	}, []);

	const onFetchDiagnosticsTypes = async () => {
		try {
			const res = await getDiagnosticsTypes(organizationId);
			setData(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!data) {
		return <LoadingContainer />;
	}

	return diagnoseData ? (
		<DiagnosticsPreview
			diagnostics={diagnoseData}
			diagnoseFix={diagnoseFix}
			organization={organization}
			renderDiagnseElementLink={renderDiagnseElementLink}
			diagnoseElementTitleKeyFormat={diagnoseElementTitleKeyFormat}
		/>
	) : (
		<DiagnosticsForm
			diagnosticTypes={data}
			handleUpdate={(data) => setDiagnoseData(data)}
			diagnose={diagnose}
			getDiagnoseProgress={getDiagnoseProgress}
			organizationId={organizationId}
			diagnosticsTranslations={diagnosticsTranslations}
		/>
	);
};
