import { parseApiData } from "go-core/api/parseApiData";
import { restSecurityApi } from "./api";
import { ChangeTenantHttpStatusApi, TenantApi } from "./typesSecurity";

class apiTenant {
	static getTenants(params?: Record<string, any>, options?: Record<string, any>): Promise<TenantApi[]> {
		return parseApiData(restSecurityApi.get(`/security/tenants`, { params, cancelToken: options?.cancelToken }));
	}

	static migrateTenants(): Promise<{}> {
		return parseApiData(restSecurityApi.put(`/security/tenants/migrate`, undefined));
	}

	static migrateTenant(tenantId: number): Promise<{}> {
		return parseApiData(restSecurityApi.put(`/security/tenants/${tenantId}/migrate`, undefined));
	}

	static changeTenantHttpStatus(tenantId: number, data: ChangeTenantHttpStatusApi): Promise<{}> {
		return parseApiData(restSecurityApi.put(`/security/tenants/${tenantId}/test_http_status`, data));
	}
}

export { apiTenant };
