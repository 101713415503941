import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { PrintoutTemplateApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "PRINTOUT_TEMPLATE";
const resourceType = "PRINTOUT_TEMPLATE";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const [items, setItems] = useState<PrintoutTemplateApi[]>([]);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRemove = async (item: PrintoutTemplateApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removePrintoutTemplate(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestore = async (item: PrintoutTemplateApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restorePrintoutTemplate(item.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("modules.printout_template.field.name.title"),
				type: "text" as FilterType,
				render: (item: PrintoutTemplateApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(item.status)}
							to={`${location.pathname}/${item.id}`}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: PrintoutTemplateApi) => item.name,
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: PrintoutTemplateApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: PrintoutTemplateApi) => {
					onRemove(item);
				},
				visible: (item: PrintoutTemplateApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: PrintoutTemplateApi) => {
					onRestore(item);
				},
				visible: (item: PrintoutTemplateApi) => {
					return item.status === "DELETED";
				},
			},
		],
		selectedColumns: ["name"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		exportConfig: {
			title: t("modules.printout_template.header.title"),
			filename: t("modules.printout_template.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(params);
			return api.organization().getPrintoutTemplatesSettings(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
			onFetch={(fetchItems: PrintoutTemplateApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};
const OrganizationSettingsPrintoutTemplatesIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.printout_template.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
			path: `${props.match.url}/new`,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.printout_template.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationSettingsPrintoutTemplatesIndexPage;
