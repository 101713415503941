import { FILTER_SEPARATOR, FILTER_VALUE_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "./index";
import { FilterCondition, ListSelectedFilter } from "./types";

export const listFiltersParse = (params: ListSelectedFilter[], shouldEncodeValues?: boolean): string => {
	let queryString = "";
	params.forEach((param) => {
		const condition = param.condition;
		const filterId = param.filterId;
		let value: string | null = "";

		try {
			value =
				param.value && shouldEncodeValues
					? param.value
							.split(FILTER_VALUE_SEPARATOR)
							.map((v) => encodeURIComponent(v))
							.join(FILTER_VALUE_SEPARATOR)
					: param.value;
		} catch {
			value = param.value;
		}
		switch (condition) {
			case "a":
				value = null;
				break;
			case "u":
				value = null;
				break;
			case "true":
				value = null;
				break;
			case "false":
				value = null;
				break;
		}
		// switch (param.filterType) {
		//     case "text":
		//         condition = param.condition;
		//         break;
		//     case "list":
		//         break;
		// }

		if (value === undefined) {
			return;
		}
		queryString += `${FILTER_SEPARATOR}${filterId}|${condition}`;
		if (value) {
			queryString += `=${value}`;
		}
	});
	return queryString;
};

export const listFiltersEncode = (params: ListSelectedFilter[], shouldEncodeValues?: boolean): string => {
	const queryString = listFiltersParse(params, shouldEncodeValues);
	const value =
		queryString[0] === NEW_WAY_TO_ENCODING_FILTER_SIGN
			? queryString
			: `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${queryString}`;
	const b64 = btoa(unescape(encodeURIComponent(value)));
	return b64;
};

export const getUrlParamsWithoutDecoding = (url: string) => {
	const regex = /[?]?([^=]+)=([^&]*)/g;
	const params: Record<string, any> = {};
	let match;

	while ((match = regex.exec(url))) {
		params[match[1]] = match[2];
	}
	return params;
};

export const listFiltersDecode = (value: string, additionalDecode?: boolean): ListSelectedFilter[] => {
	let filterDecode;
	try {
		filterDecode = decodeURIComponent(escape(atob(value)));
		if (filterDecode[0] === NEW_WAY_TO_ENCODING_FILTER_SIGN) {
			filterDecode = filterDecode.substring(1);
		}
		const reg = new RegExp(FILTER_SEPARATOR, "g");
		filterDecode = filterDecode.replace(reg, "&");
	} catch (e) {
		return [];
	}
	filterDecode = filterDecode.replace(/\+/g, "_plus_");
	const filterDecodeParams = additionalDecode
		? Object.fromEntries(new URLSearchParams(filterDecode))
		: getUrlParamsWithoutDecoding(filterDecode);

	const selectedFilters: ListSelectedFilter[] = [];
	for (const key in filterDecodeParams) {
		let value = filterDecodeParams[key];

		const keySplit = key.split("|");
		let filterId = keySplit[0];
		if (filterId.includes("_plus_")) {
			filterId = filterId.replaceAll("_plus_", "+");
		}
		if (value.includes("_plus_")) {
			value = value.replaceAll("_plus_", "+");
		}
		const condition = keySplit[1];

		if (condition === "a" || condition === "u" || condition === "true" || condition === "false") {
			value = condition;
		}
		selectedFilters.push({
			// id: i + "",
			// name: filter.name,
			// filterType: filter.type,
			filterId,
			condition: condition as FilterCondition,
			value,
		});
	}
	return selectedFilters;
};
