import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface RenderLimitedTextProps {
	minWidth: number;
	children: React.ReactNode;
	offsetWidth?: number;
	onClick?: () => void;
	textStyleIsLink?: boolean;
}

const tableCellPaddingOffset = 2 * 8;

const RenderLimitedText = ({
	minWidth,
	children,
	offsetWidth,
	textStyleIsLink,
	onClick,
}: RenderLimitedTextProps): JSX.Element | null => {
	const { t } = useTranslation();
	const tableCellRef = useRef<HTMLTableCellElement>();
	const cellContainerRef = useRef<HTMLDivElement>(null);
	const cellContentRef = useRef<HTMLSpanElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const collapsedCellHeight = useRef<number>();
	const [isCellExpanded, setIsCellExpanded] = useState(false);
	const [containerHeight, setContainerHeight] = useState<number>();
	const [containerMaxWidth, setContainerMaxWidth] = useState<number>();
	const [contentWidth, setContentWidth] = useState<number>();
	const [isCellBiggerThanMaxWidth, setIsCellBiggerThanMaxWidth] = useState(false);

	const resetState = useCallback(() => {
		setIsCellExpanded(false);
		setContainerMaxWidth(undefined);
		setContainerHeight(undefined);
		setIsCellBiggerThanMaxWidth(false);
	}, []);

	const updateContainerMaxDimensions = useCallback(() => {
		if (cellContentRef.current && tableCellRef.current) {
			const newContainerWidth = Math.max(
				tableCellRef.current.clientWidth - tableCellPaddingOffset - (offsetWidth ?? 0),
				minWidth
			);

			setContainerMaxWidth(newContainerWidth);

			if (buttonRef.current && collapsedCellHeight.current) {
				return setContainerHeight(
					isCellBiggerThanMaxWidth
						? cellContentRef.current.clientHeight + buttonRef.current.clientHeight
						: collapsedCellHeight.current
				);
			}

			setContainerHeight(isCellExpanded ? cellContentRef.current.clientHeight : collapsedCellHeight.current);
		}
	}, [isCellExpanded, isCellBiggerThanMaxWidth, minWidth, offsetWidth]);

	useEffect(() => {
		if (cellContainerRef.current) {
			let parent: HTMLElement | null = cellContainerRef.current.parentElement;

			while (parent && parent.nodeName !== "TD") {
				parent = parent.parentElement;
			}

			if (parent) {
				tableCellRef.current = parent as HTMLTableCellElement;
			}
		}
	}, []);

	useEffect(() => {
		if (tableCellRef.current && !collapsedCellHeight.current) {
			const resizeObserver = new ResizeObserver(() => {
				const isTableCellVisible = Boolean(
					tableCellRef.current?.clientWidth && tableCellRef.current?.clientHeight
				);

				if (tableCellRef.current && isTableCellVisible && !collapsedCellHeight.current) {
					collapsedCellHeight.current = tableCellRef.current.clientHeight - tableCellPaddingOffset;
				}
			});

			resizeObserver.observe(tableCellRef.current);
		}
	}, []);

	useEffect(() => {
		if (tableCellRef.current) {
			const resizeObserver = new ResizeObserver(() => {
				const isTableCellVisible = Boolean(
					tableCellRef.current &&
						tableCellRef.current.clientWidth > 0 &&
						tableCellRef.current.clientHeight > 0
				);

				if (isTableCellVisible) {
					updateContainerMaxDimensions();
				}
			});

			resizeObserver.observe(tableCellRef.current);

			return () => resizeObserver.disconnect();
		}
	}, [updateContainerMaxDimensions]);

	useEffect(() => {
		if (cellContentRef.current && contentWidth && collapsedCellHeight.current) {
			const isCellOverflowing = cellContentRef.current.scrollWidth > contentWidth;
			const isContentWrapped = cellContentRef.current.clientHeight > collapsedCellHeight.current;

			setIsCellBiggerThanMaxWidth(isCellOverflowing || isContentWrapped);
		}
	}, [contentWidth]);

	useEffect(() => {
		if (containerMaxWidth) {
			setContentWidth(containerMaxWidth - (offsetWidth ?? 0));
		}
	}, [containerMaxWidth, offsetWidth]);

	useEffect(() => {
		if (!isCellBiggerThanMaxWidth) {
			resetState();
		}
	}, [isCellBiggerThanMaxWidth, resetState]);

	if (!children) {
		return null;
	}

	return (
		<div
			ref={cellContainerRef}
			style={{
				minWidth,
				maxWidth: containerMaxWidth,
				height: containerHeight,
				position: "relative",
				display: "flex",
				alignItems: "center",
			}}
		>
			<span
				ref={cellContentRef}
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
					position: "absolute",
					width: contentWidth,
					whiteSpace: isCellExpanded ? "pre-wrap" : "nowrap",
					top: isCellBiggerThanMaxWidth ? 0 : "auto",
					lineHeight: "100%",
					wordBreak: "break-all",
				}}
				className={textStyleIsLink ? "text-as-link" : ""}
				onClick={onClick}
			>
				{children}
			</span>
			{isCellBiggerThanMaxWidth && (
				<button
					type="button"
					className="btn-with-link-styling limited-text-button"
					onClick={() => setIsCellExpanded((state) => !state)}
					ref={buttonRef}
				>
					{isCellExpanded
						? `[${t("common.action.less", { ns: "lib" })}]`
						: `[${t("common.action.more", { ns: "lib" })}]`}
				</button>
			)}
		</div>
	);
};

export default RenderLimitedText;
