import { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import FormatDate, { getDayName, getMonthName } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import ReportData from "go-report/reports";
import {
	getChartReport,
	getChartType,
	getLastMonthFilterBetween,
	getReport,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import { capitalizeFirstLetter } from "../../../../../../../../utils/reports";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "CALL_RECORDS_REPORT";
const resourceType = "CALL_RECORDS_REPORT";

const Report: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();
	let config = {
		reportConfigId: "call_records",
		type: "bill",
		aggregatePrefix: "summary",
		chart: {
			title: t("modules.call.field.amount_of_calls.title"),
			type: getChartType(),
			aggregate: "amount",
			lineTitle: t("common.word.quantity"),
		},
		selectedColumns: ["amount"],
		showPercent: true,
		groups: [
			{
				id: "YEAR",
				name: capitalizeFirstLetter(t("enums.time_parts.singular.year", { ns: "lib" })),
			},
			{
				id: "MONTH",
				name: capitalizeFirstLetter(t("enums.time_parts.singular.month", { ns: "lib" })),
			},
			{
				id: "DAY_OF_MONTH",
				name: t("common.word.day_of_month"),
			},
			{
				id: "HOUR_OF_DAY",
				name: t("common.word.hour", { ns: "lib" }),
			},
			{
				id: "MINUTE_OF_HOUR",
				name: capitalizeFirstLetter(t("enums.time_parts.singular.minute", { ns: "lib" })),
			},
			{
				id: "DATE",
				name: t("common.word.date", { ns: "lib" }),
			},
			{
				id: "DATE_TIME",
				name: t("modules.call.field.date_time.title"),
			},
			{
				id: "DAY_OF_WEEK",
				name: t("common.word.day_of_week"),
			},
			{
				id: "STATUS",
				name: t("common.word.status", { ns: "lib" }),
			},
			{
				id: "TYPE",
				name: t("common.word.type", { ns: "lib" }),
			},
			{
				id: "PHONE_NUMBER",
				name: t("common.word.phone_number", { ns: "lib" }),
			},
		],
		groupsInfo: {
			DATE: (groupByValue: Record<string, any>) => {
				return FormatDate(groupByValue.name, undefined, undefined, { minutes: "none", hours: "none" });
			},
			DATE_TIME: (groupByValue: Record<string, any>) => {
				return FormatDate(groupByValue.name);
			},
			STATUS: (groupByValue: Record<string, any>) => {
				return t(`enums.call_status.${groupByValue.name}`);
			},
			DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			TYPE: (groupByValue: Record<string, any>) => {
				return t(`enums.call_type.${groupByValue.name}`);
			},
			MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
		},
		columns: [
			{
				id: "amount",
				name: t("common.word.quantity"),
			},
		],
		filters: [
			{
				id: "date_range",
				name: t("common.word.date", { ns: "lib" }),
				type: "date",
				isRemovable: false,
			},
			{
				id: "day_of_week",
				name: t("common.word.day_of_week"),
				type: "list",
				listOptions: {
					MONDAY: t("enums.days.MONDAY", { ns: "lib" }),
					TUESDAY: t("enums.days.TUESDAY", { ns: "lib" }),
					WEDNESDAY: t("enums.days.WEDNESDAY", { ns: "lib" }),
					TRURSDAY: t("enums.days.TRURSDAY", { ns: "lib" }),
					FRIDAY: t("enums.days.FRIDAY", { ns: "lib" }),
					SATURDAY: t("enums.days.SATURDAY", { ns: "lib" }),
					SUNDAY: t("enums.days.SUNDAY", { ns: "lib" }),
				},
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				listOptions: {
					RECEIVED: t("enums.call_status.RECEIVED"),
					MISSED: t("enums.call_status.MISSED"),
				},
			},
			{
				id: "type",
				name: t("common.word.type", { ns: "lib" }),
				type: "list",
				listOptions: {
					INCOMING: t("enums.call_type.INCOMING"),
					OUTGOING: t("enums.call_type.OUTGOING"),
				},
			},
			{
				id: "phone_number",
				name: t("common.word.phone_number", { ns: "lib" }),
				type: "text",
			},
		],
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date",
				value: getLastMonthFilterBetween().split("=")[1],
				condition: getLastMonthFilterBetween().split("=")[0],
				visible: true,
			},
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				columns: ["amount"],
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return Promise.all([
				getReport(
					apiReport.getCallRecordsReport,
					params,
					organization.id,
					config.filters,
					undefined,
					sourceToken
				),
				getChartReport(
					apiReport.getCallRecordsReport,
					params,
					"NONE",
					organization.id,
					config.filters,
					undefined,
					sourceToken
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return getChartReport(
				apiReport.getCallRecordsReport,
				params,
				"NONE",
				organization.id,
				config.filters,
				undefined,
				sourceToken
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.call.field.export_config.title"),
			filename: t("modules.call.field.export_config_filename.title"),
			organization: organization.name,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		withCompareDateRange: true,
	} as ReportConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

export const OrganizationReportsCallsPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};
