import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as SettingsSVG } from "go-report/core/images/settings.svg";
import { ReactComponent as AggregateAverageSVG } from "../../../../../images/svg/dashboard/aggregate_average.svg";
import { ReactComponent as AggregateQuantitySVG } from "../../../../../images/svg/dashboard/aggregate_quantity.svg";
import { ReactComponent as AggregateValueSVG } from "../../../../../images/svg/dashboard/aggregate_value.svg";
import { ChartType, DashboardCardConfig } from "../../../services/charts/types";
import { DashboardAggregateType } from "../../../services/types";
import useDashboardContext from "../../../services/useDashboardContext";
import { getDashboardChartActionType } from "../../../utils/typeUtils";

interface Props {
	config: DashboardCardConfig;
	updateChartType: (chartType: ChartType) => void;
}

type ChartTypeOption = {
	type: ChartType;
	name: string;
};

const AGGREGATE_TYPES = [
	DashboardAggregateType.TOTAL_MONEY,
	DashboardAggregateType.TRNSACTION_COUNT,
	DashboardAggregateType.AVERAGE_MONEY,
];

const getAggregateIcon = (aggregateType: DashboardAggregateType, isSelected: boolean) => {
	const svgClassname = isSelected ? "white-svg" : "black-svg";

	switch (aggregateType) {
		case DashboardAggregateType.AVERAGE_MONEY:
			return <AggregateAverageSVG className={svgClassname} />;
		case DashboardAggregateType.TRNSACTION_COUNT:
			return <AggregateQuantitySVG className={svgClassname} />;
		case DashboardAggregateType.TOTAL_MONEY:
			return <AggregateValueSVG className={svgClassname} />;
	}
};

const ReportItemWrapperHeader = (props: Props): JSX.Element => {
	const { title, chartType } = props.config;
	const [settingsArrowIsUp, setSettingsArrowIsUp] = useState(false);
	const [aggregateArrowIsUp, setAggregateArrowIsUp] = useState(false);
	const [selectedAggregateType, setSelectedAggregateType] = useState<DashboardAggregateType>(
		DashboardAggregateType.TOTAL_MONEY
	);
	const { updateChartType } = props;
	const isStringTitle = typeof title === "string";
	const { t } = useTranslation();
	const { dispatch } = useDashboardContext();

	const chartTypes = Object.values(ChartType)
		.filter((f) => f !== ChartType.LINE && f !== ChartType.PROGRESS_BAR)
		.map((type) => {
			return {
				name: t(`go_report.chart.type.${type}`),
				type,
			} as ChartTypeOption;
		});

	const changeAggregateTypeHandler = (aggregateType: DashboardAggregateType) => {
		setSelectedAggregateType(aggregateType);

		dispatch({
			type: getDashboardChartActionType(props.config.type),
			data: {
				aggregate: aggregateType,
			},
		});
	};

	return (
		<div className={"report-title"}>
			{isStringTitle ? (
				<>
					<h5>{title}</h5>
				</>
			) : (
				<>{title}</>
			)}
			{props.config.type !== "sales_advanced_reports" && (
				<div className="report-options-wrapper">
					<Dropdown className={"ms-auto"} onToggle={() => setAggregateArrowIsUp(!aggregateArrowIsUp)}>
						{
							<Dropdown.Toggle
								variant="light"
								className={`${
									aggregateArrowIsUp ? "triangle-rotate-active" : ""
								} dashboard-aggregate-type-dropdown-toggle`}
							>
								<AggregateQuantitySVG />
							</Dropdown.Toggle>
						}
						<Dropdown.Menu className="dashboard-aggregate-type-dropdown-menu">
							{AGGREGATE_TYPES.map((aggregateType) => {
								return (
									<Dropdown.Item
										active={aggregateType === selectedAggregateType}
										onClick={() => changeAggregateTypeHandler(aggregateType)}
										className="dashboard-aggregate-type-item"
										key={aggregateType}
									>
										{getAggregateIcon(aggregateType, aggregateType === selectedAggregateType)}
										<span>{t(`enums.dashboard_aggregate_type.${aggregateType}`)}</span>
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown className={"ms-auto"} onToggle={() => setSettingsArrowIsUp(!settingsArrowIsUp)}>
						{
							<Dropdown.Toggle
								variant="light"
								className={`${
									settingsArrowIsUp ? "triangle-rotate-active" : ""
								} dashboard-settings-dropdown-toggle`}
							>
								<SettingsSVG />
							</Dropdown.Toggle>
						}
						<Dropdown.Menu>
							{chartTypes.map((item, index) => {
								return (
									<Dropdown.Item
										active={chartType === item.type}
										onClick={() => updateChartType(item.type)}
										key={index}
									>
										{item.name}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
			)}
		</div>
	);
};
export default ReportItemWrapperHeader;

// t("go_report.chart.type.VERTICAL_BAR");
// t("go_report.chart.type.HORIZONTAL_BAR");
// t("go_report.chart.type.PROGRESS_BAR");
// t("go_report.chart.type.BOX");
// t("enums.dashboard_aggregate_type.total_money")
// t("enums.dashboard_aggregate_type.transaction_count")
// t("enums.dashboard_aggregate_type.average_money")
