import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import {
	RequestItemContext,
	RequestItemContextProps,
	RequestItemService,
} from "go-component/components/RequestItem/services/context";
import { apiOrganizationComponent } from "../../../../../../../../services/Api/Organization/apiOrganizationComponent";
import OrganizationLogsRequestItemsEditPage from "./pages/Edit";
import OrganizationLogsRequestItemsIndexPage from "./pages/Index";

const OrganizationLogsRequestItemsPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const translationPrefix = "enums.request_item.type";

	return (
		<RequestItemContext.Provider
			value={
				new RequestItemService(
					{
						all: apiOrganizationComponent.getRequestItems,
						get: apiOrganizationComponent.getRequestItem,
						retry: apiOrganizationComponent.retryRequestItem,
						getTypeOptions: apiOrganizationComponent.getRequestItemTypes,
					} as RequestItemContextProps,
					translationPrefix,
					t
				)
			}
		>
			<Switch>
				<Route path={props.match.url} exact={true} component={OrganizationLogsRequestItemsIndexPage} />
				<Route path={`${props.match.url}/:item_id`} component={OrganizationLogsRequestItemsEditPage} />
			</Switch>
		</RequestItemContext.Provider>
	);
};

export default OrganizationLogsRequestItemsPage;

// t("enums.request_item.type.WEBHOOK")
// t("enums.request_item.type.SEND_REPORT_VIA_MAIL")
// t("enums.request_item.type.EXPORT_ORDER")
// t("enums.request_item.type.SYNC_SETTINGS")
// t("enums.request_item.type.SYNC_ORDER")
// t("enums.request_item.type.SYNC_EXISTING_ORDER")
