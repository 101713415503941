import {
	DeliveryEmployeeApi,
	DeliveryZoneApi,
	ItemOrderType,
	LiveOrderApi,
	OrderPreparationStatusName,
	OrganizationAddressApi,
} from "../../../../../../../services/Api/Organization/types";
import { CoordinatesApi } from "../../../../../../../services/Api/types";

export interface LiveOrdersFilters {
	status: LiveOrderFilterOption;
	search: string;
	time: LiveOrderFilterOption;
	employeesLastActivity: LiveOrderFilterOption;
	deliveryEmployee: LiveOrderFilterOption | null;
	type: LiveOrderFilterOption | null;
}

export interface OrdersApi {
	filteredOrdersWithoutFilterKeys: (skipFilterKeys?: string[]) => LiveOrderApi[];
	orders: LiveOrderApi[];
	organizationAddress: OrganizationAddressApi;
	deliveryZones: DeliveryZoneApi[];
	updateOrder: (order: LiveOrderApi) => void;
	filters: LiveOrdersFilters;
	setFilters: (filters: LiveOrdersFilters) => void;
	iconsScale: number;
	setIconsScale: (scale: number) => void;
	deliveryEmployees: DeliveryEmployeeApi[];
	isOnline: boolean;
}

export interface LiveOrderFilterOption {
	id: OrderPreparationStatusName | string | ItemOrderType;
	label: string;
}

export interface GroupedMarker {
	coords: CoordinatesApi;
	orders: LiveOrderApi[];
}

export enum LiveOrdersBlockedProductFilter {
	"ALL" = "ALL",
	"BLOCKED" = "BLOCKED",
	"UNBLOCKED" = "UNBLOCKED",
}

export enum LiveOrderDeliveryOptionType {
	"OWN_DRIVER" = "OWN_DRIVER",
	"WOLT_DRIVE" = "WOLT_DRIVE",
}

export const defaultDeliveryTimeOptions = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150];

export const woltDriveTimeOptions = [15, 30, 45, 60];
