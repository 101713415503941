import { ButtonLoading } from "go-form";

interface ActionButtonProps {
	text: string;
	isPrimary?: boolean;
	onClick: () => void;
	isLoading?: boolean;
	"data-testid"?: string;
}

export const ActionButton = ({
	text,
	isPrimary,
	onClick,
	isLoading,
	"data-testid": dataTestId,
}: ActionButtonProps): JSX.Element => {
	return (
		<ButtonLoading
			variant={isPrimary ? "primary" : "light"}
			onClick={onClick}
			data-testid={dataTestId}
			loading={Boolean(isLoading)}
		>
			{text}
		</ButtonLoading>
	);
};
