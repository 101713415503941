import React, { useContext } from "react";
import SecurityUserLocationsComponent from "go-security/components/User/SecurityUserDashboardComponent";
import { ConfigContext } from "../../../../../../services/Config/context";
import { getApiURL } from "../../../../../../utils/envutil";

const UserLocationsPage = (): JSX.Element => {
	const configService = useContext(ConfigContext);
	const applications = configService.applications();

	return (
		<SecurityUserLocationsComponent
			applications={applications}
			baseUrl={getApiURL()}
			oldVersionAppsTimestamp={configService.getAppsOldVersionTimestamps()}
		/>
	);
};

export default UserLocationsPage;
