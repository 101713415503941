import { ConfigReportComboApi } from "go-report-combo/services/types";
import { CatalogReportCombo } from "go-report-combo/template/Template/types";
import { REPORT_tpizza_categories } from "./DefineReportList/Clients/tpizza_categories";
import { REPORT_tpizza_categories_compare_locations } from "./DefineReportList/Clients/tpizza_categories_compare_locations";
import { REPORT_tpizza_yearly } from "./DefineReportList/Clients/tpizza_yearly";
import { REPORT_test_custom_fields } from "./DefineReportList/Test/custom_fields";
import { REPORT_basic_example_1 } from "./DefineReportList/basic_example_1";
import { REPORT_basic_example_source_2 } from "./DefineReportList/basic_example_source_2";
import { REPORT_basic_source_compare } from "./DefineReportList/basic_source_compare";
import { REPORT_example_1 } from "./DefineReportList/example_1";
import { REPORT_example_2 } from "./DefineReportList/example_2";

const createCatalog = (config: ConfigReportComboApi) => {
	return {
		id: config.id,
		name: config.name,
		description: config.name,
		category: "BASIC",
		data: config,
	} as CatalogReportCombo;
};

const catalogTemplates = [
	createCatalog(REPORT_test_custom_fields),
	createCatalog(REPORT_tpizza_categories_compare_locations),
	createCatalog(REPORT_tpizza_yearly),
	createCatalog(REPORT_tpizza_categories),
	createCatalog(REPORT_example_1),
	createCatalog(REPORT_example_2),
	createCatalog(REPORT_basic_example_1),
	createCatalog(REPORT_basic_example_source_2),
	createCatalog(REPORT_basic_source_compare),
] as CatalogReportCombo[];

export const CATALOG_TEMPLATE_LIST = catalogTemplates;
