import { FC } from "react";
import { ReactComponent as EditSVG } from "../../../../../../../../../../../../images/svg/rooms/edit.svg";

interface Props {
	handleEdit: () => void;
}

const EditHandler: FC<Props> = ({ handleEdit }) => {
	return (
		<div className={"room-item-handler edit"} onClick={handleEdit}>
			<EditSVG />
		</div>
	);
};
export default EditHandler;
