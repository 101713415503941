import React, { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import {
	FormatDateToDateHoursRange,
	formatFormDateToString,
	formatStringToDate,
	getShortMonthName,
} from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import FormatResourceStatus from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as LeftArrowButton } from "../../../../../../../../../images/svg/left-arrow-btn.svg";
import { ReactComponent as RightArrowButton } from "../../../../../../../../../images/svg/right-arrow-btn.svg";
import { PosReportApi, PosReportPaidApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { switchToNextPOSReport, switchToPreviousPOSReport } from "../pages/utils";
import { exportPosReportPdf, printPosReportPdf } from "../services/exportPosReport";
import GeneratePosPdfModal, { GeneratePdfConfig } from "./GeneratePosPdfModal";
import InfoCard from "./InfoCard";
import ReportCard from "./ReportCard";
import BasicTabPreview from "./tabs/Basic/BasicTabPreview";
import DiscountsTabPreview from "./tabs/DiscountsTabPreview";
import DeliveryEmployeesTabPreview from "./tabs/Employees/DeliveryEmployeesTabPreview";
import EmployeesTabPreview from "./tabs/Employees/EmployeesTabPreview";
import PaidsTabPreview from "./tabs/PaidsTabPreview";
import PaymentMethodsTabPreview from "./tabs/PaymentMethodsTabPreview";
import TransactionsTabPreview from "./tabs/TransactionsTabPreview";

interface Props {
	report: PosReportApi;
	handleClose: (report: PosReportApi) => void;
	handlePaidSave: (report: PosReportPaidApi) => void;
	url: string;
}

export const getDate = (report: PosReportApi): string => {
	if (report.status === "OPENED") return formatFormDateToString(report.opened_at).getDate().toString();
	const openDate = formatFormDateToString(report.opened_at);
	const closeDate = formatFormDateToString(report.closed_at);
	if (openDate.getDate() !== closeDate.getDate()) {
		return `${openDate.getDate()}-${closeDate.getDate()}`;
	}
	return openDate.getDate().toString();
};

export const getSubDate = (report: PosReportApi, t: TFunction): string => {
	if (report.status === "OPENED")
		return getShortMonthName((formatFormDateToString(report.opened_at).getMonth() + 1).toString(), t);
	const openDate = formatFormDateToString(report.opened_at);
	const closeDate = formatFormDateToString(report.closed_at);
	if (openDate.getMonth() !== closeDate.getMonth()) {
		return `${getShortMonthName(
			(formatStringToDate(report.opened_at).getMonth() + 1).toString(),
			t
		)}-${getShortMonthName((formatStringToDate(report.closed_at).getMonth() + 1).toString(), t)}`;
	}
	return getShortMonthName((formatStringToDate(report.opened_at).getMonth() + 1).toString(), t);
};

export const getInfoHeader = (report: PosReportApi): string => {
	const openDate = formatStringToDate(report.opened_at);
	if (report.status === "OPENED")
		return `${openDate.toLocaleTimeString([], {
			hourCycle: "h23",
			hour: "2-digit",
			hour12: false,
			minute: "2-digit",
		})}`;
	const closeDate = formatStringToDate(report.closed_at);
	return `${openDate.toLocaleTimeString([], {
		hourCycle: "h23",
		hour: "2-digit",
		hour12: false,
		minute: "2-digit",
	})}-${closeDate.toLocaleTimeString([], { hour: "2-digit", hour12: false, minute: "2-digit" })}`;
};

enum PosReportCardType {
	BASIC = "BASIC",
	TRANSACTIONS = "TRANSACTIONS",
	PAIDS = "PAIDS",
	PAYMENT_METHODS = "PAYMENT_METHODS",
	DISCOUNTS = "DISCOUNTS",
	EMPLOYEES = "EMPLOYEES",
	DELIVERY_EMPLOYEES = "DELIVERY_EMPLOYEES",
}

const PosReportPreview: FC<Props> = ({ report, handleClose, handlePaidSave, url }): JSX.Element => {
	const [showLogs, setShowLogs] = useState(false);
	const cardTypes = [
		PosReportCardType.BASIC,
		PosReportCardType.TRANSACTIONS,
		PosReportCardType.PAIDS,
		PosReportCardType.PAYMENT_METHODS,
		PosReportCardType.DISCOUNTS,
	];

	if (report.employees.length > 0) cardTypes.push(PosReportCardType.EMPLOYEES);
	if (report.delivery_employees.length > 0) cardTypes.push(PosReportCardType.DELIVERY_EMPLOYEES);

	const confirmation = useConfirmation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const user = useSelector(selectUser);
	const { addFlash, addSuccessFlash } = useFlash();
	const [showGeneratePdfModal, setShowGeneratePdfModal] = useState(false);
	const [showSendByEmailModal, setShowSendByEmailModal] = useState(false);
	const loc = window.location.href;
	const history = useHistory();
	const isMobile = useWindowSize().isMobile;

	const onCloseReport = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: `${t("confirmation.message.action", { ns: "lib" })} ${t("confirmation.pos_report.statement")}`,
		});
		const params: Record<string, string> = {
			include: "end_amounts,sub_reports,paids,paids.transaction,paids.client,full,currencies",
		};
		try {
			const res = await api.organization().closePosReport(report.id, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			handleClose(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const isBasicView = () => {
		return (
			!loc.includes(PosReportCardType.TRANSACTIONS.toLowerCase()) &&
			!loc.includes(PosReportCardType.PAIDS.toLowerCase()) &&
			!loc.includes(PosReportCardType.EMPLOYEES.toLowerCase()) &&
			!loc.includes(PosReportCardType.DELIVERY_EMPLOYEES.toLowerCase()) &&
			!loc.includes(PosReportCardType.PAYMENT_METHODS.toLowerCase()) &&
			!loc.includes(PosReportCardType.DISCOUNTS.toLowerCase())
		);
	};

	const handlePaidsFilter = () => {
		history.push(`${url}/paids`);
	};

	const exportToPdf = () => exportPosReportPdf(report.id, undefined, t, addFlash);

	const printPdf = () => printPosReportPdf(report.id, undefined, t, addFlash);

	const config: GeneratePdfConfig = {
		configId: "POS_REPORT_PRINT_SETTINGS",
		configName: t("components.generate_pdf_modal.pos_report.segment.name"),
		fields: [
			{
				id: "detailed_settlement",
				type: "checkbox",
				title: t(`components.generate_pdf_modal.pos_report.fields.detailed_settlement.label`),
				description: t(`components.generate_pdf_modal.pos_report.fields.detailed_settlement.description`),
				tableColumns: [],
			},
			{
				id: "payments_and_payouts",
				type: "checkbox",
				title: t(`components.generate_pdf_modal.pos_report.fields.payments_and_payouts.label`),
				description: t(`components.generate_pdf_modal.pos_report.fields.payments_and_payouts.description`),
				tableColumns: [],
			},
			{
				id: "payment_methods",
				type: "checkbox",
				title: t(`components.generate_pdf_modal.pos_report.fields.payment_methods.label`),
				description: t(`components.generate_pdf_modal.pos_report.fields.payment_methods.description`),
				tableColumns: ["name", "amount", "count"],
			},
			{
				id: "employees",
				type: "checkbox",
				title: t(`components.generate_pdf_modal.pos_report.fields.employees.label`),
				description: t(`components.generate_pdf_modal.pos_report.fields.employees.description`),
				tableColumns: [],
			},
			{
				id: "discounts",
				type: "checkbox",
				title: t(`components.generate_pdf_modal.pos_report.fields.discounts.label`),
				description: t(`components.generate_pdf_modal.pos_report.fields.discounts.description`),
				tableColumns: ["name", "type", "amount", "count"],
			},
			{
				id: "employee_deliveries",
				type: "checkbox",
				title: t(`components.generate_pdf_modal.pos_report.fields.employee_deliveries.label`),
				description: t(`components.generate_pdf_modal.pos_report.fields.employee_deliveries.description`),
				tableColumns: [],
			},
		],
	};

	const renderTitle = () => (
		<>
			{t("modules.pos_report.header.title")} {t(`modules.pos_report.field.report_type_${report.type}.title`)} #
			{report.id}
			<FormatResourceStatus status={report.status} />
		</>
	);

	const renderSubtitle = () => (
		<ul>
			<li>{FormatDateToDateHoursRange(report.opened_at, report.closed_at)}</li>
			<li>{FormatMoney(report.total_amount)}</li>
			<li>{t("modules.pos_report.field.order_count.title", { count: report.order_count })}</li>
		</ul>
	);

	const buttons: ButtonProps[] = [];

	buttons.push({
		content: (
			<LeftArrowButton onClick={() => switchToPreviousPOSReport(report.id.toString(), history, organization)} />
		),
	});
	buttons.push({
		content: (
			<RightArrowButton onClick={() => switchToNextPOSReport(report.id.toString(), history, organization)} />
		),
	});
	buttons.push({
		title: t("common.word.logs", { ns: "lib" }),
		action: () => setShowLogs(!showLogs),
		variant: "light",
	});
	if (report.status !== "CLOSED" && (user.admin || organization.support)) {
		buttons.push({
			title: t("common.action.close", { ns: "lib" }),
			action: () => onCloseReport(),
			dropdown: true,
		});
	}
	buttons.push({
		title: t("common.action.print", { ns: "lib" }),
		action: () => printPdf(),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.pos_report.action.generate_pdf.title"),
		action: () => setShowGeneratePdfModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.export_to_pdf"),
		action: () => exportToPdf(),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.pos_report.action.send_by_email.title"),
		action: () => setShowSendByEmailModal(true),
		dropdown: true,
	});

	const avatar = {
		color: "#1A86E0",
		label: getDate(report),
		description: getSubDate(report, t),
	};

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.close", { ns: "lib" }),
			action: () => onCloseReport(),
			hide: !(report.status !== "CLOSED" && (user.admin || organization.support)),
		},
		{
			title: t("common.action.print", { ns: "lib" }),
			action: () => printPdf(),
		},
		{
			title: t("modules.pos_report.action.generate_pdf.title"),
			action: () => setShowGeneratePdfModal(true),
		},
		{
			title: t("common.action.export_to_pdf"),
			action: () => exportToPdf(),
		},
		{
			title: t("modules.pos_report.action.send_by_email.title"),
			action: () => setShowSendByEmailModal(true),
		},
	];

	const isActiveTab = (cardType: PosReportCardType) => {
		const splittedPathname = loc.split("/");
		const lastPartOfPathname = splittedPathname[splittedPathname.length - 1];
		return lastPartOfPathname === cardType.toLowerCase();
	};

	return (
		<>
			<GeneratePosPdfModal
				isShown={showSendByEmailModal ? showSendByEmailModal : showGeneratePdfModal}
				onHide={() => {
					if (showSendByEmailModal) setShowSendByEmailModal(false);
					else setShowGeneratePdfModal(false);
				}}
				config={config}
				item={report}
				sendByEmail={showSendByEmailModal}
			/>
			<Switch>
				<div className="content-preview">
					<MobileActions actions={mobileActions} />
					<Header
						title={renderTitle()}
						subtitle={renderSubtitle()}
						buttons={isMobile ? buttons.filter((button) => button.content) : buttons}
						avatar={avatar}
					/>
					{showLogs && (
						<MessageEventModal
							path={`/${organization.id}/logs/message_events`}
							resourceId={report.id}
							resourceType={"REPORT"}
							onHide={() => setShowLogs(!showLogs)}
							organizationId={organization.id}
						/>
					)}
					<div className="content-preview-body">
						<div className={"row"}>
							<div className="col-md-4">
								<InfoCard report={report} />
								{report?.fiscal_printer_report && (
									<ReportCard report={report.fiscal_printer_report} type="FISCAL" />
								)}
								{report?.payment_terminal_report && (
									<ReportCard report={report.payment_terminal_report} type="PAYMENT_TERMINAL" />
								)}
							</div>
							<div className="col-md-8">
								<Form.Group className="form-group" style={{ marginBottom: "12px" }}>
									{cardTypes.map((type) => {
										if (isBasicView() && type === PosReportCardType.BASIC) {
											return (
												<Link key={url} to={url} className={"btn btn-outline-primary mb-1"}>
													{t(`modules.pos_report.field.card_type_BASIC.title`)}
												</Link>
											);
										}
										return (
											<Link
												key={url}
												className={`btn ${
													isActiveTab(type) ? "btn-outline-primary mb-1" : "btn-default mb-1"
												}`}
												to={`${url}${
													type !== PosReportCardType.BASIC ? `/${type.toLowerCase()}` : ""
												}`}
											>
												{t(`modules.pos_report.field.card_type_${type}.title`)}
											</Link>
										);
									})}
								</Form.Group>
								<Switch>
									<Route
										exact
										path={`${url}/transactions`}
										render={() => <TransactionsTabPreview report={report} />}
									/>
									<Route
										exact
										path={`${url}/paids`}
										render={() => (
											<PaidsTabPreview handlePaidSave={handlePaidSave} report={report} />
										)}
									/>
									<Route
										exact
										path={`${url}/payment_methods`}
										render={() => <PaymentMethodsTabPreview report={report} />}
									/>
									<Route
										exact
										path={`${url}/employees`}
										render={() => <EmployeesTabPreview report={report} />}
									/>
									<Route
										exact
										path={`${url}/delivery_employees`}
										render={() => <DeliveryEmployeesTabPreview report={report} />}
									/>
									<Route
										exact
										path={`${url}/discounts`}
										render={() => <DiscountsTabPreview report={report} />}
									/>
									<Route
										path={""}
										render={() => (
											<BasicTabPreview report={report} filterPaids={handlePaidsFilter} />
										)}
									/>
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</Switch>
		</>
	);
};
export default PosReportPreview;

// t("modules.pos_report.field.card_type_TRANSACTIONS.title");
// t("modules.pos_report.field.card_type_PAIDS.title");
// t("modules.pos_report.field.card_type_PAYMENT_METHODS.title");
// t("common.word.payment_methods");
// t("modules.pos_report.field.card_type_DISCOUNTS.title");
// t("modules.pos_report.field.card_type_EMPLOYEES.title");
// t("modules.pos_report.field.card_type_DELIVERY_EMPLOYEES.title");
// t("modules.pos_report.field.report_type_DRAWER.title");
// t("modules.pos_report.field.report_type_SHIFTWORK.title");
