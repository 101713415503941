import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ItemGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
	createItemGroup: (type: string, id?: number) => void;
	itemProps?: Record<string, any>;
	form: UseFormReturn<ItemGroupApi>;
}

interface FormProps {
	currentType: string;
	selectedProductId: number;
	selectedProduct: Record<string, any>;
}

const NextItemGroupModal = (props: Props): JSX.Element => {
	const form = useForm<FormProps>({
		defaultValues: {
			currentType: "COPY",
			selectedProduct: props.itemProps,
			selectedProductId: props.itemProps?.id,
		},
	});
	const { getValues, control } = form;
	const { t } = useTranslation();
	const [currentType, setCurrentType] = useState("COPY");

	const createItemGroup = () => {
		if (currentType === "COPY") {
			props.createItemGroup("EXISTING", getValues().selectedProductId);
		} else {
			props.createItemGroup(currentType);
		}
	};

	const searchProducts = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getItemGroupsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	return (
		<FormDirty>
			<Modal style={{ marginTop: "100px" }} show={true} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.item_group.header.next_item_modal.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<LimitComponentVisibility
						defaultVisible={currentType === "COPY"}
						classNames={"mb-3"}
						handleChange={() => setCurrentType("COPY")}
						id={"copy_product"}
						legendText={t("modules.item_group.field.create_copy.title")}
						helpText={t("modules.item_group.field.create_copy.helptext.description")}
						unlimitedContent={
							<FormSelectGroup
								name="selectedProductId"
								isClearable
								control={control}
								errors={[]}
								defaultValue={{
									label: props.itemProps?.name,
									id: props.itemProps?.id,
								}}
								placeholder={t("modules.item_group.action.choose_product.title")}
								getOptionLabel={(opt) => opt.label}
								getOptionValue={(opt) => opt.id}
								loadOptions={searchProducts}
								data-testid="selectedProductId"
							/>
						}
					/>
					<LimitComponentVisibility
						defaultVisible={currentType === "NEW"}
						handleChange={() => setCurrentType("NEW")}
						id={"new_product"}
						legendText={t("modules.item_group.header.new.title")}
						helpText={t("modules.item_group.field.new_item_group.helptext.description")}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" type="submit" onClick={createItemGroup}>
						{t("common.action.goto", { ns: "lib" })}
					</Button>
					<Button variant="light" onClick={props.onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Modal>
		</FormDirty>
	);
};
export default NextItemGroupModal;
