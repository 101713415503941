import React, { useEffect, useMemo, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import {
	NotificationTemplateApi,
	PreviewNotificationTemplateApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { NotificationTemplateAvailableVariables } from "./NotificationTemplateAvailableVariables";

enum PreviewDataResource {
	TITLE = "title",
	CONTENT = "content",
}

interface Props {
	form: UseFormReturn<NotificationTemplateApi>;
	setNotificationPreviewData: (data: PreviewNotificationTemplateApi | undefined) => void;
	notificationTemplateCategory?: string;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<NotificationTemplateApi>[]>>;
	setRenderPreviewLoading: (loading: boolean) => void;
}

export const NotificationTemplateInfoForm = ({
	form,
	setNotificationPreviewData,
	notificationTemplateCategory,
	setErrors,
	setRenderPreviewLoading,
}: Props) => {
	const {
		register,
		formState: { errors },
		control,
		watch,
		setError,
		clearErrors,
		setValue,
	} = form;
	const [getVariablesLoading, setGetVariablesLoading] = useState(false);
	const [availableVariables, setAvailableVariables] = useState<Record<string, string> | undefined>(undefined);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const timeoutRef = useRef<NodeJS.Timeout | undefined>();
	const posReportCategorySelected = watch("category") === "POS_REPORT";

	useEffect(() => {
		return () => {
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
		};
	}, []);

	useEffect(() => {
		const category = watch("category");
		if (category) fetchAvailableVariables(category);
	}, []);

	const getNotificationTemplateCategories = async () => {
		const { categories } = await api.organization().getNotificationTemplateCategories();
		return categories.map((category: string) => {
			return {
				id: category,
				label: t(`enums.common.resource_type.${category}`),
			};
		});
	};

	const notificationTemplateTypeOptions = ["EMAIL"].map((option) => ({
		id: option,
		label: t(`enums.notification_templates.type.${option}`),
	}));

	const categoryChangeHandler = async (categoryValue: string | null) => {
		const promises = [fetchAvailableVariables(categoryValue)];
		const { content, title } = watch();
		const isNewNotificationPage = location.pathname.includes("/new");
		const errorsOccurred = Object.entries(errors).length > 0;

		if (((!content && !title) || errorsOccurred) && isNewNotificationPage)
			promises.push(getExampleTitleAndContent(categoryValue));

		await Promise.all(promises);
	};

	const fetchAvailableVariables = async (categoryValue: string | null) => {
		try {
			if (!categoryValue) {
				setAvailableVariables(undefined);
				return;
			}
			setGetVariablesLoading(true);
			const { variables } = await api.organization().getAvailableVariablesBasedOnCategory(categoryValue);
			setAvailableVariables(variables);
		} catch (err) {
			handleError.alert(err, addFlash);
		} finally {
			setGetVariablesLoading(false);
		}
	};

	const getExampleTitleAndContent = async (categoryValue: string | null) => {
		const type = watch("type");
		try {
			setRenderPreviewLoading(true);
			const { title, content } = await api
				.organization()
				.getNotificationTemplateDefaultValues(categoryValue, type);
			setValue("title", title);
			setValue("content", content);
			getPreviewDataFromCategoryChange(title, content);
		} catch (err) {
			handleError.alert(err, addFlash);
		} finally {
			setRenderPreviewLoading(false);
		}
	};

	const getPreviewDataFromCategoryChange = async (title: string, content: string) => {
		try {
			const notificationPreviewData = { ...watch(), title, content };
			const response = await api.organization().getNotificationPreview(notificationPreviewData);
			setNotificationPreviewData(response);
			clearErrors();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
	};

	const getPreviewData = async (
		event: React.ChangeEvent<HTMLInputElement>,
		latestInputChange: PreviewDataResource
	) => {
		const notificationDataNewValue = event.target.value;
		const otherInputValue = latestInputChange === PreviewDataResource.CONTENT ? watch("title") : watch("content");

		if (!notificationDataNewValue || !otherInputValue) {
			setNotificationPreviewData(undefined);
			return;
		}

		try {
			const notificationPreviewData = watch();
			notificationPreviewData[latestInputChange] = event.target.value;
			const response = await api.organization().getNotificationPreview(notificationPreviewData);
			setNotificationPreviewData(response);
			clearErrors();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		} finally {
			timeoutRef.current = undefined;
		}
	};

	const changeNotificationPreviewData = (
		event: React.ChangeEvent<HTMLInputElement>,
		latestInputChange: PreviewDataResource
	) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => getPreviewData(event, latestInputChange), 500);
	};

	const notificationTemplateInputConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<>
			<h5>{t("modules.notification_template.field.basic_info.title")}</h5>
			<FormInput
				name="name"
				register={register}
				errors={errors}
				label={t("common.word.name", { ns: "lib" })}
				customValidationConfig={notificationTemplateInputConfig}
			/>
			<FormSelectGroup
				name="category"
				control={control}
				errors={errors}
				label={t("common.word.category")}
				getOptionValue={(option) => option.id}
				loadOptions={getNotificationTemplateCategories}
				onChange={categoryChangeHandler}
				defaultValue={
					notificationTemplateCategory
						? {
								id: notificationTemplateCategory,
								label: t(`enums.common.resource_type.${notificationTemplateCategory}`),
						  }
						: null
				}
				customValidationConfig={notificationTemplateInputConfig}
			/>
			{posReportCategorySelected && (
				<FormCheck
					type="switch"
					register={register}
					label={t("modules.notification_template.action.include_report_drawers.title")}
					name="settings.INCLUDE_REPORT_DRAWERS"
					errors={errors}
				/>
			)}
			<FormSelectGroup
				name="type"
				control={control}
				errors={errors}
				label={t("common.word.type", { ns: "lib" })}
				getOptionValue={(option) => option.id}
				options={notificationTemplateTypeOptions}
				customValidationConfig={notificationTemplateInputConfig}
				defaultValue={{
					id: notificationTemplateTypeOptions[0].id,
					label: notificationTemplateTypeOptions[0].label,
				}}
			/>
			<h5 className="mt-3 mb-2">{t("modules.notification_template.field.content.title")}</h5>
			<small className="text-muted">{t("modules.notification_template.field.content.description.title")}</small>
			<FormInput
				name="title"
				register={register}
				errors={errors}
				label={t("modules.notification_template.field.title.title")}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					changeNotificationPreviewData(event, PreviewDataResource.TITLE)
				}
				customValidationConfig={notificationTemplateInputConfig}
			/>
			<FormInput
				name="content"
				register={register}
				errors={errors}
				label={t("modules.notification_template.field.content.title")}
				as="textarea"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					changeNotificationPreviewData(event, PreviewDataResource.CONTENT)
				}
				rows={5}
				customValidationConfig={notificationTemplateInputConfig}
			/>
			<NotificationTemplateAvailableVariables
				loading={getVariablesLoading}
				availableVariables={availableVariables}
			/>
		</>
	);
};
