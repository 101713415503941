import { AuthCredentials } from "./types";

let credentials = {} as AuthCredentials;
class AuthCredentialsService {
	static get = (): AuthCredentials => {
		return credentials;
	};
	static init = (c: AuthCredentials) => {
		credentials = c;
	};
}
export { AuthCredentialsService };
