import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import { TerminalApi, TerminalType } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	terminal: TerminalApi;
	onHide: () => void;
	onRefresh: () => Promise<void>;
}

export const ChangeTerminalTypeModal = ({ terminal, onHide, onRefresh }: Props) => {
	const [loading, setLoading] = useState(false);
	const { type, id } = terminal;
	const form = useForm<{ type: TerminalType }>({ defaultValues: { type } });
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const typeOptions = Object.values(TerminalType).map((terminalType) => ({
		value: terminalType,
		label: terminalType,
	}));
	const {
		control,
		formState: { errors },
		handleSubmit,
	} = form;

	const onSubmit = handleSubmit(async ({ type: selectedType }) => {
		try {
			setLoading(true);
			await apiOrganization.changeTerminalType(id, selectedType);
			addSuccessFlash(t("lib:common.flash.completed"));
			await onRefresh();
			onHide();
		} catch (err) {
			handleError.alert(err, addFlash);
		} finally {
			setLoading(false);
		}
	});

	return (
		<Modal show onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{`${t("common.action.select", { ns: "lib" })} ${t("common.word.type", {
					ns: "lib",
				})}`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormSelectGroup
					name="type"
					control={control}
					errors={errors}
					label={t("common.word.type", { ns: "lib" })}
					options={typeOptions}
					defaultValue={{
						id: type,
						label: type,
					}}
					isClearable={false}
				/>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading onClick={onSubmit} loading={loading}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
				<Button onClick={onHide} variant="light">
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
