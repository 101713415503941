import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { DeviceApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import DeviceForm from "../../components/DeviceForm";

interface MatchParams {
	deviceId: string;
}

const OrganizationSettingsDevicesEditPage = (): JSX.Element => {
	const [device, setDevice] = useState<DeviceApi | undefined>(undefined);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const { deviceId } = useParams<MatchParams>();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const params = { include: "parameters" };

	useEffect(() => {
		onFetchDevice();
	}, []);

	const onFetchDevice = async () => {
		try {
			const res = await api.organization().getDevice(Number(deviceId), params);
			setDevice(res);
			handleChangeTabTitle(`${res.name} | ${t("modules.device.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removeDevice = async (device: DeviceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeDevice(device.id);
			history.push(`/${organization.id}/settings/devices`);
			addSuccessFlash(t("lib:common.flash.removed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restoreDevice = async (device: DeviceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().restoreDevice(device.id, params);
			setDevice(res);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!device) {
		return <LoadingContainer />;
	}

	const renderTitle = () => (
		<>
			{t("modules.device.header.title")} "{device.name}"
			<FormatResourceStatus status={device.status} />
		</>
	);

	const buttons: ButtonProps[] = [];
	if (device.status !== "DELETED") {
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeDevice(device),
			dropdown: true,
		});
	}
	if (device.status === "DELETED") {
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreDevice(device),
			dropdown: true,
		});
	}

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeDevice(device),
			hide: device.status === "DELETED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreDevice(device),
			hide: device.status !== "DELETED",
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<DeviceForm device={device} />
		</>
	);
};
export default OrganizationSettingsDevicesEditPage;
