import React from "react";
import { FieldArrayWithId, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationError } from "go-form/services/types";
import { DiscountApi, DiscountEntityApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import DiscountFormConditions from "./DiscountFormConditions";
import DiscountFormItemsTable from "./DiscountFormItemsTable";

interface Props {
	form: UseFormReturn<DiscountApi>;
	fields: FieldArrayWithId<DiscountApi, "entities", "key">[];
	remove: UseFieldArrayRemove;
	discountType: string;
	appendItem: (item: DiscountEntityApi) => void;
	discount: DiscountApi;
	setIsAvailabilityVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isAvailabilityVisible: boolean;
	setIsDiscountTableVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isDiscountTableVisible: boolean;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
	setAreConditionsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	areConditionsVisible: boolean;
}

const DiscountFormRules = ({
	form,
	fields,
	remove,
	discountType,
	appendItem,
	discount,
	isAvailabilityVisible,
	setIsAvailabilityVisible,
	setIsDiscountTableVisible,
	isDiscountTableVisible,
	setErrors,
	areConditionsVisible,
	setAreConditionsVisible,
}: Props) => {
	const {
		control,
		setValue,
		formState: { errors },
	} = form;
	const { t } = useTranslation();

	const searchAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	return (
		<fieldset className="form-group no-gutters">
			<h5>{t("modules.discount.field.legend_item.title")}</h5>
			<DiscountFormItemsTable
				selectedDiscountType={discountType}
				fields={fields}
				form={form}
				handleAppend={(item: DiscountEntityApi) => appendItem(item)}
				handleRemove={(index: number) => remove(index)}
				setErrors={setErrors}
				isVisibleControl={isDiscountTableVisible}
				setIsVisibleControl={setIsDiscountTableVisible}
			/>
			<LimitComponentVisibility
				defaultVisible={!!discount.availability}
				handleChange={(val) => val === false && setValue("availability_id", undefined, { shouldDirty: true })}
				id={"availability_hours"}
				legendText={t("modules.discount.field.legend_availability.title")}
				helpText={t("modules.discount.field.legend_availability.helptext.description")}
				isVisibleControl={isAvailabilityVisible}
				setIsVisibleControl={setIsAvailabilityVisible}
				unlimitedContent={
					<>
						<FormSelectGroup
							label={t("common.word.availability")}
							name="availability_id"
							errors={errors}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							defaultValue={{
								label: discount.availability?.name,
								id: discount.availability?.id,
							}}
							loadOptions={searchAvailabilities}
							control={control}
							data-testid="availability_id"
						/>
					</>
				}
			/>
			<DiscountFormConditions
				form={form}
				setAreConditionsVisible={setAreConditionsVisible}
				areConditionsVisible={areConditionsVisible}
			/>
		</fieldset>
	);
};

export default DiscountFormRules;
