import { Route, RouteComponentProps, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import { OrganizationSettingsNotificationTemplatesEditPage } from "./pages/Edit";
import { OrganizationSettingsNotificationTemplatesIndexPage } from "./pages/Index";
import { OrganizationSettingsNotificationTemplatesNewPage } from "./pages/New";

export const OrganizationSettingsNotificationTemplatesPage = (props: RouteComponentProps) => {
	return (
		<div className="content">
			<Switch>
				<Route
					path={`${props.match.url}/`}
					component={OrganizationSettingsNotificationTemplatesIndexPage}
					exact
				/>
				<Route
					path={`${props.match.url}/new`}
					component={OrganizationSettingsNotificationTemplatesNewPage}
					exact
				/>
				<Route
					path={`${props.match.url}/:notification_template_id`}
					component={OrganizationSettingsNotificationTemplatesEditPage}
					exact
				/>
				<Route component={Page404} />
			</Switch>
		</div>
	);
};
