import React, { FC, useContext, useEffect, useState } from "react";
import { Map } from "leaflet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { useWindowSize } from "go-core/components/useWindowSize";
import { LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../services/context";
import { getLiveOrderNumber } from "../../../../services/utils";
import LiveOrdersOrderPreview from "./components/LiveOrdersOrderPreview";

interface MatchParams {
	orderId: string;
}

interface Props {
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	isVisible: boolean;
	orderListRoutePath: string;
	map?: Map;
}

const OrganizationLiveOrdersMapOrderPreviewPage: FC<Props> = ({
	setFocusedOrder,
	isVisible,
	orderListRoutePath,
	map,
}) => {
	const { orderId } = useParams<MatchParams>();
	const [order, setOrder] = useState<LiveOrderApi | undefined>(undefined);
	const ordersContext = useContext(OrdersContext);
	const isMobile = useWindowSize().isMobile;
	const history = useHistory();
	const filtersAsString = JSON.stringify(ordersContext.filters);
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		if (order) handleChangeTabTitle(`${getLiveOrderNumber(order)} | ${t("modules.live_order.header.title")}`);
	}, [order?.id]);

	const handleClosePreviewWhenOrderNotExist = () => {
		if (!ordersContext.orders.find((singleOrder) => singleOrder.id === Number(orderId))) redirectToList();
	};

	useEffect(() => {
		handleClosePreviewWhenOrderNotExist();
	}, [JSON.stringify(ordersContext.orders)]);

	useEffect(() => {
		handleClosePreviewWhenOrderNotExist();
	}, [filtersAsString]);

	useEffect(() => {
		getOrder();
	}, [ordersContext.orders]);

	useEffect(() => {
		if (order) {
			setFocusedOrder(order);
		}
	}, [order]);

	const getOrder = () => {
		const foundOrder = ordersContext.orders.find((singleOrder) => singleOrder.id === Number(orderId));
		if (foundOrder) {
			setOrder({ ...foundOrder });
		}
	};

	const redirectToList = () => {
		history.push(orderListRoutePath);
	};

	return (
		<div
			className={`live-orders-order-preview ${isMobile ? "live-orders-order-preview--mobile" : ""} ${
				isVisible ? "" : "d-none"
			}`}
		>
			{order && (
				<LiveOrdersOrderPreview
					order={order}
					setFocusedOrder={setFocusedOrder}
					setOrder={setOrder}
					map={map}
					orderListRoutePath={orderListRoutePath}
				/>
			)}
		</div>
	);
};

export default OrganizationLiveOrdersMapOrderPreviewPage;
