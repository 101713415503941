export enum OrganizationActionTypes {
	SET = "@@organization/SET",
	CLEAR = "@@organization/CLEAR",
	UPDATE = "@@organization/UPDATE",
}

export type OrganizationState = {
	id?: number;
	name?: string;
	status?: string;
	permissions: string[];
	applications: string[];
	accept_privacy?: boolean;
	accept_terms?: boolean;
	accept_rules?: boolean;
	alias?: string;
	company_id?: string;
	company_name?: string;
	category?: string;
	tax_id_no?: string;
	description?: string;
	is_admin?: boolean;
	timezone?: string;
	languages: string[];
	currency?: string;
	support?: boolean;
	license_expires_at?: string;
	more?: any;
	partner?: boolean;
	license_type?: string;
};
