import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router";
import { Redirect, RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectToken } from "go-security/services/session/selectors";
import { selectIsAdmin, selectUser } from "go-security/services/users/selectors";
import { ReportLastUpdateInfo } from "../../../../../../components/Common/ReportLastUpdateInfo/ReportLastUpdateInfo";
import { apiReport } from "../../../../../../services/Api/Report/apiReport";
import DepartmentReportModal from "./components/DepartmentReportModal";
import { RebuildReportModal } from "./components/RebuildReportModal";
import ReportsMobileActions from "./components/ReportsMobileActions";
import ReportsNavigation from "./components/ReportsNavigation";
import OrganizationReportsBillsPage from "./pages/Bills";
import { OrganizationReportsCallsPage } from "./pages/Calls";
import OrganizationReportsCategoriesPage from "./pages/Categories";
import OrganizationReportsComboPage from "./pages/Combo";
import OrganizationReportsDiscountsPage from "./pages/Discounts";
import OrganizationReportsEfficiencyPage from "./pages/Employees";
import OrganizationReportsGuestsPage from "./pages/Guests";
import { OrganizationReportsInvoicesPage } from "./pages/Invoices";
import OrganizationReportsProductPricesPage from "./pages/ItemPriceHistory";
import OrganizationReportsKdsPage from "./pages/Kds";
import { OrganizationReportsKdsOrdersPage } from "./pages/KdsOrders";
import { OrganizationReportsLiveOrdersPage } from "./pages/LiveOrders";
import OrganizationReportsPaymentsPage from "./pages/Payments";
import OrganizationReportsProductsPage from "./pages/Products";
import OrganizationReportsStornPage from "./pages/Storn";
import { OrganizationReportsTransactionPage } from "./pages/Transactions";
import OrganizationReportsUnsoldProductsPage from "./pages/UnsoldProducts";

const OrganizationReportsPage: FC<RouteComponentProps> = (props) => {
	const [reportLastUpdatedAt, setReportLastUpdatedAt] = useState<string | undefined>(undefined);
	const [showDepartmentReportModal, setShowDepartmentReportModal] = useState(false);
	const [showRebuildReportModal, setShowRebuildReportModal] = useState(false);
	const organization = useSelector(selectOrganization);
	const isAdmin = useSelector(selectIsAdmin);
	const user = useSelector(selectUser);
	const token = useSelector(selectToken);
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const isSupport = (user.roles || []).find((role) => role === "ROLE_SUPPORT");
	const displayCallsReport = organization.more.installed_app_providers.includes("CALL_RECORDER");
	const { pathname } = useLocation();
	const isMobile = useWindowSize().isMobile;
	const buttons: ButtonProps[] = [];
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		if (organization.id) {
			window.localStorage.setItem("go_report.filters.organization_ids", organization.id?.toString());
		}
	}, []);

	useEffect(() => {
		handleChangeTabTitle(`${getHeaderTitle()} | ${t("components.navigation.reports")}`);
	}, [pathname]);

	if (isSupport || isAdmin)
		buttons.push({
			title: t("modules.report.action.rebuild.title"),
			variant: "primary",
			action: () => setShowRebuildReportModal(true),
		});
	buttons.push({
		title: t("modules.report.field.department_report.title"),
		dropdown: true,
		action: () => setShowDepartmentReportModal(true),
	});
	if (!props.location.pathname.includes("combo")) {
		buttons.push({
			title: t("modules.report.field.combo_report.title"),
			dropdown: true,
			path: `/${organization.id}/reports/combo`,
		});
	}

	const getReportType = useCallback((): string => {
		const currentReportType = pathname.slice(pathname.lastIndexOf("/") + 1);
		if (currentReportType === "reports") return "";
		if (currentReportType === "invoices") return "INVOICE";
		if (currentReportType === "transactions") return "TRANSACTION";
		return "ORDER";
	}, [pathname]);

	useEffect(() => {
		const getLastReportUpdatedAt = async () => {
			try {
				const organizationParam = `organization_id|e=${organization.id}`;
				const reportType = getReportType();
				if (reportType === "") return;

				const params = {
					access_token: token,
					f: btoa(organizationParam),
					type: reportType,
				};
				const res = await apiReport.getReportLastUpdateDate(params);

				const lastUpdateValue = res.data?.organizations[0]?.last_sale_sync_at;

				setReportLastUpdatedAt(lastUpdateValue);
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		};
		getLastReportUpdatedAt();
	}, [addFlash, organization.id, token, getReportType]);

	const lastUpdateInfo = (
		<div className="flex-grow-1 d-flex justify-content-end" style={{ marginRight: "21px" }}>
			<ReportLastUpdateInfo lastUpdateDate={reportLastUpdatedAt} />
		</div>
	);

	const getHeaderTitle = () => {
		const pathParts = pathname.split("/");
		const lastPathPart = pathParts[pathParts.length - 1];

		switch (lastPathPart) {
			case "orders":
				return t("modules.report_bill.header.title");
			case "categories":
				return t("modules.category_report.header.title");
			case "payments":
				return t("modules.payment_method_report.header.title");
			case "products":
				return t("modules.item_group_report.header.title");
			case "efficiency_report":
				return t("modules.efficiency_report.header.title");
			case "storn":
				return t("modules.storno_report.header.title");
			case "item_price_history":
				return t("modules.item_price_history.header.title");
			case "kds":
				return t("modules.preparation_status_report.header.title");
			case "kds_orders":
				return t("modules.kds_order_report.header.title");
			case "guests":
				return t("modules.report_guest.header.title");
			case "invoices":
				return t("modules.invoice.header.title");
			case "live_orders":
				return t("modules.live_order_report.header.title");
			case "not_sold_items":
				return t("modules.unsold_products_report.header.title");
			case "transactions":
				return t("modules.transaction.header.title");
			case "calls":
				return t("modules.call.header.title");
			case "order_promotions":
				return t("common.word.discounts");
			case "combo":
				return t("modules.report.field.combo_report.title");
			default:
				return "";
		}
	};

	const isReportUsingListComponent = () => {
		const pathParts = pathname.split("/");
		const lastPathPart = pathParts[pathParts.length - 1];

		return lastPathPart === "not_sold_items" || lastPathPart === "item_price_history" || lastPathPart === "storn";
	};

	return (
		<div className={"page-container"}>
			<ReportsNavigation />
			{!isReportUsingListComponent() && (
				<ReportsMobileActions
					isAdmin={isAdmin}
					isSupport={isSupport}
					setShowRebuildReportModal={setShowRebuildReportModal}
					setShowDepartmentReportModal={setShowDepartmentReportModal}
					reportLastUpdatedAt={reportLastUpdatedAt}
				/>
			)}
			<div className="content">
				{!isMobile && (
					<Header title={getHeaderTitle()} buttons={buttons} customHeaderContent={lastUpdateInfo} />
				)}
				<Switch>
					<Route path={`${props.match.url}/orders`} component={OrganizationReportsBillsPage} />
					<Route path={`${props.match.url}/categories`} component={OrganizationReportsCategoriesPage} />
					<Route path={`${props.match.url}/payments`} component={OrganizationReportsPaymentsPage} />
					<Route path={`${props.match.url}/order_promotions`} component={OrganizationReportsDiscountsPage} />
					<Route path={`${props.match.url}/products`} component={OrganizationReportsProductsPage} />
					<Route
						path={`${props.match.url}/efficiency_report`}
						component={OrganizationReportsEfficiencyPage}
					/>
					<Route path={`${props.match.url}/storn`} component={OrganizationReportsStornPage} />
					<Route
						path={`${props.match.url}/item_price_history`}
						component={OrganizationReportsProductPricesPage}
					/>
					<Route path={`${props.match.url}/kds`} component={OrganizationReportsKdsPage} />
					<Route path={`${props.match.url}/kds_orders`} component={OrganizationReportsKdsOrdersPage} />
					<Route path={`${props.match.url}/guests`} component={OrganizationReportsGuestsPage} />
					<Route path={`${props.match.url}/invoices`} component={OrganizationReportsInvoicesPage} />
					<Route path={`${props.match.url}/live_orders`} component={OrganizationReportsLiveOrdersPage} />
					<Route
						path={`${props.match.url}/not_sold_items`}
						component={OrganizationReportsUnsoldProductsPage}
					/>
					<Route path={`${props.match.url}/transactions`} component={OrganizationReportsTransactionPage} />
					{displayCallsReport && (
						<Route path={`${props.match.url}/calls`} component={OrganizationReportsCallsPage} />
					)}
					<Route path={`${props.match.url}/combo`} component={OrganizationReportsComboPage} />
					<Redirect from={props.match.url} exact to={`${props.match.url}/orders`} />
				</Switch>
			</div>
			<DepartmentReportModal
				show={showDepartmentReportModal}
				onHide={() => setShowDepartmentReportModal(false)}
			/>
			<RebuildReportModal show={showRebuildReportModal} onHide={() => setShowRebuildReportModal(false)} />
		</div>
	);
};
export default OrganizationReportsPage;
