import { ReactElement } from "react";

interface Props {
	icon: ReactElement;
	fileName: string;
}

const FileDisplay = ({ icon, fileName }: Props): JSX.Element => {
	return (
		<div className={"d-flex flex-column align-items-center"}>
			{icon}
			<span>{fileName}</span>
		</div>
	);
};
export default FileDisplay;
