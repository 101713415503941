import React, { FC, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactSortable } from "react-sortablejs";
import { ButtonLoading, FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import EmptyData from "go-core/components/EmptyData";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDirty } from "go-form/components/FormDirty";
import { ImageApi } from "go-security/services/Api/typesSecurity";
import { ReactComponent as PlusSVG } from "../../../../../../../../../../../images/svg/menu/plus.svg";
import { ReactComponent as MoreSvg } from "../../../../../../../../../../../images/svg/more.svg";
import { ReactComponent as WCSVG } from "../../../../../../../../../../../images/svg/rooms/wc.svg";
import {
	RoomApi,
	RoomElementApi,
	RoomTableApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { ImageLinkApi } from "../../../../../../../../../../../services/Api/types";
import Room from "./Room";
import RoomContainer from "./RoomContainer";
import RoomModalPreview from "./RoomModalPreview";
import RoomImageBackground from "./backgrounds/RoomImageBackground";
import Hooker from "./elements/Hooker";
import Rectangle from "./elements/Rectangle";
import RectangleVertical from "./elements/RectangleVertical";
import RoomElementRectangle from "./elements/RoomElementRectangle";
import Round from "./elements/Round";
import Square from "./elements/Square";

interface Props {
	roomsList: RoomApi[];
}

export interface RoomFormProps {
	rooms: RoomApi[];
}

const RoomsPreview: FC<Props> = ({ roomsList }) => {
	const [defaultRoomIndex, setDefaultRoomIndex] = useState(0);
	const { addFlash, addSuccessFlash } = useFlash();
	const isSortingRef = useRef(false);
	const [isDirty, setIsDirty] = useState(false);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const windowSize = useWindowSize();
	const sortedPageIndexRef = useRef<null | number>(null);
	const [showPreview, setShowPreview] = useState<undefined | "16:9" | "16:10" | "9:16" | "10:16">(undefined);
	const form = useForm<RoomFormProps>({
		criteriaMode: "all",
		defaultValues: {
			rooms: roomsList,
		},
		shouldUnregister: false,
	});

	const {
		unregister,
		formState: { errors },
		control,
		watch,
		setValue,
		handleSubmit,
		setError,
		getValues,
		register,
	} = form;

	const { fields, remove, append } = useFieldArray({
		control,
		name: `rooms`,
		keyName: "key",
	});

	const createRoomElement = (data: RoomElementApi) => {
		const index = watchedRooms[defaultRoomIndex].elements?.length;
		data.color = "#7b61ff";
		setValue(`rooms.${defaultRoomIndex}.elements.${index ? index : 0}`, data, { shouldDirty: true });
		setIsDirty(true);
	};

	const createRoomTable = (data: RoomTableApi) => {
		const index = watchedRooms[defaultRoomIndex].tables?.length ? watchedRooms[defaultRoomIndex].tables?.length : 0;
		data.color = "#e0e3ea";
		data.number = index;
		data.name = `#${index}`;
		setValue(`rooms.${defaultRoomIndex}.tables.${index}`, data);
		setIsDirty(true);
	};

	const watchedRooms = watch("rooms");

	const updatePositions = (fields: RoomApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		setIsDirty(true);
		fields.forEach((field, index) => {
			field.position = `${index}`;
			if (!field.color) {
				unregister(`rooms.${index}.color`);
			}
			if (!field.background?.image_data) {
				unregister(`rooms.${index}.background`);
			}
			if (!field.background_link) {
				unregister(`rooms.${index}.background_link`);
			}
		});
		fields.forEach((field, index) => {
			setValue(`rooms.${index}`, field, { shouldDirty: true });
		});
		if (sortedPageIndexRef.current || sortedPageIndexRef.current === 0) {
			setDefaultRoomIndex(sortedPageIndexRef.current);
		}
	};

	const onSubmit = handleSubmit(async (data: RoomFormProps) => {
		setLoading(true);
		data.rooms.forEach((room) => {
			if (!room.background?.image_data || room.background.image_data === "") {
				room.background = undefined;
			}
			if (!room.background_link?.default_link) {
				room.background_link = undefined;
			}
		});
		try {
			const params: Record<string, string> = { include: "tables,elements" };
			const resp = await api.organization().updateRooms(data, params);
			resp.forEach((room: RoomApi, index: number) => {
				setValue(`rooms.${index}.id`, room.id);
			});
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			setIsDirty(false);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});
	let newPageIndex = 0;
	const handleSortRooms = (evt: any) => {
		isSortingRef.current = true;
		if (evt.newIndex) {
			newPageIndex = evt.newIndex;
			sortedPageIndexRef.current = newPageIndex;
		}
		setIsDirty(true);
	};

	const handleRemoveRoom = (index: number) => {
		remove(index);
		setIsDirty(true);
	};

	useEffect(() => {
		const fieldsLength = fields.length - 1;
		if (defaultRoomIndex > fieldsLength) {
			setDefaultRoomIndex(fieldsLength >= 0 ? fieldsLength : 0);
		}
	}, [fields]);

	const onAppend = () => {
		append({ theme: "LIGHT", elements: [], tables: [], name: "" });
		setIsDirty(true);
	};
	const currentRoom = getValues(`rooms.${defaultRoomIndex}`) as RoomApi;
	const watchedBackgroundUrl = getValues(`rooms.${defaultRoomIndex}.background_link`) as ImageLinkApi;
	const watchedBackground = getValues(`rooms.${defaultRoomIndex}.background`) as ImageApi;
	const watchedColor = getValues(`rooms.${defaultRoomIndex}.color`) as string;
	const handleUpdateRoomBackground = (color?: string, background?: ImageApi, serverImage?: string | undefined) => {
		setIsDirty(true);
		setValue(`rooms.${defaultRoomIndex}.color`, color);
		registerObject(register, `rooms.${defaultRoomIndex}.background`, ["image_data", "type"]);
		setValue(`rooms.${defaultRoomIndex}.background_link.default_link`, serverImage, { shouldDirty: true });
		setValue(`rooms.${defaultRoomIndex}.background.image_data`, background?.image_data, { shouldDirty: true });
		registerObject(register, `rooms.${defaultRoomIndex}.background_link`, ["default_link"]);
		setValue(`rooms.${defaultRoomIndex}.background_link.default_link`, serverImage, { shouldDirty: true });
	};

	const handleCancelUpdateRoomBackground = () => {
		const defaultRoomValues = roomsList[defaultRoomIndex];
		handleUpdateRoomBackground(
			defaultRoomValues?.color,
			defaultRoomValues?.background,
			defaultRoomValues?.background_link?.default_link
		);
	};
	const renderSortablePages = () => {
		return (
			<>
				<div className={"header-container"}>
					<h6>{t("modules.room.field.rooms.title")}</h6>{" "}
					<Button variant="light" type="button" onClick={() => onAppend()}>
						<PlusSVG />
					</Button>
				</div>
				<ReactSortable
					list={watchedRooms}
					onUpdate={(evt) => handleSortRooms(evt)}
					setList={(fields) => updatePositions(fields)}
					handle={".sortable-handler"}
				>
					{fields.map((room, index) => {
						return (
							<div
								key={fields[index]?.key}
								onClick={() => {
									setDefaultRoomIndex(index);
								}}
							>
								<Room
									handleDirty={() => setIsDirty(true)}
									handleRemovePage={(ind) => {
										handleRemoveRoom(ind);
									}}
									form={form}
									room={room as RoomApi}
									defaultRoomIndex={defaultRoomIndex}
									index={index}
								/>
								<FormInput
									type="hidden"
									errors={errors}
									register={register}
									name={`rooms.${index}.background.image_data`}
								/>
							</div>
						);
					})}
				</ReactSortable>
			</>
		);
	};

	const getWidthWhenShouldDrawSidebarOnTop = () => {
		if (windowSize.width < 1250 || (windowSize.width >= 1368 && windowSize.width <= 1412)) return true;
		return false;
	};

	const { t } = useTranslation();
	return (
		<div className="rooms-preview">
			<FormDirty isDirty={isDirty} onSubmit={onSubmit}>
				{fields.length > 0 ? (
					<>
						<div className={"row"} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
							{getWidthWhenShouldDrawSidebarOnTop() && (
								<div className={"sidebar sidebar-big"}>{renderSortablePages()}</div>
							)}
							<div className={"d-flex"}>
								<div className={"content-container-big"}>
									<div className={"header-container"}>
										<h6>
											{t("modules.room.field.room_preview.title")}{" "}
											{getValues().rooms[defaultRoomIndex]?.name}
										</h6>
										<h6 className={"ms-auto"}>
											<div className={"d-flex align-items-center room-image"}>
												{t("modules.room.field.room_background.title")}
												<div className={"action-ref ms-2"}>
													{/*{watchedBackgroundUrl?.default_link ? watchedBackgroundUrl?.default_link : (watchedBackground?.image_data ? "" : watchedColor)}*/}
												</div>
												<Dropdown
													onClick={(evt) => evt.stopPropagation()}
													as={ButtonGroup}
													className="ms-2"
												>
													<Dropdown.Toggle as={MoreSvg}>
														{t("common.action.more", { ns: "lib" })}
													</Dropdown.Toggle>
													<Dropdown.Menu className={"room-image-dropdown"}>
														<RoomImageBackground
															room={currentRoom}
															handleCancel={handleCancelUpdateRoomBackground}
															handleUpdate={handleUpdateRoomBackground}
														/>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</h6>
									</div>
									<div
										className={"room-body"}
										style={{
											background: watchedBackground?.image_data
												? `url("data:${watchedBackground?.type};base64,${watchedBackground?.image_data}")`
												: watchedBackgroundUrl?.default_link
												? `url("${watchedBackgroundUrl?.default_link}")`
												: watchedColor
												? watchedColor
												: undefined,
										}}
									>
										{fields.map((room, index) => {
											if (index === defaultRoomIndex)
												return (
													<RoomContainer
														handleDirty={() => setIsDirty(true)}
														form={form}
														key={fields[index].key}
														defaultRoomIndex={index}
													/>
												);
											return null;
										})}
									</div>
									<div className={"header-container"}>
										<h6>{t("modules.room.field.room_elements.title")}</h6>
									</div>
								</div>
								{!getWidthWhenShouldDrawSidebarOnTop() && (
									<div className={"sidebar"}>{renderSortablePages()}</div>
								)}
							</div>
						</div>
						<div className={"room-elements"}>
							<Hooker handleCreate={createRoomTable} />
							<Square handleCreate={createRoomTable} guestAmount={2} />
							<RectangleVertical handleCreate={createRoomTable} guestAmount={4} />
							<Rectangle handleCreate={createRoomTable} guestAmount={4} />
							<Round handleCreate={createRoomTable} guestAmount={5} />
							<RoomElementRectangle
								name={t("modules.room.field.bar.title")}
								type={"RECTANGLE"}
								handleCreate={createRoomElement}
							>
								{t("modules.room.field.bar.title")}
							</RoomElementRectangle>
							<RoomElementRectangle
								name={t("modules.room.field.kitchen.title")}
								type={"RECTANGLE"}
								handleCreate={createRoomElement}
							>
								{t("modules.room.field.kitchen.title")}
							</RoomElementRectangle>
							<RoomElementRectangle
								name={t("modules.room.field.toilet.title")}
								type={"TOILET"}
								handleCreate={createRoomElement}
								className={"d-flex align-items-center"}
							>
								<WCSVG className={"me-2"} /> {t("modules.room.field.toilet.title")}
							</RoomElementRectangle>
							<RoomElementRectangle
								type={"RECTANGLE"}
								name={t("modules.room.field.entrance.title")}
								handleCreate={createRoomElement}
							>
								{t("modules.room.field.entrance.title")}
							</RoomElementRectangle>
						</div>
						<div className="form-footer">
							<Form.Group className="form-group">
								<ButtonLoading loading={loading} variant="primary" type="submit">
									{t("common.action.save", { ns: "lib" })}
								</ButtonLoading>
								{isDirty && (
									<Button variant="light" type="button" onClick={() => history.goBack()}>
										{t("common.action.cancel", { ns: "lib" })}
									</Button>
								)}
							</Form.Group>
						</div>
					</>
				) : (
					<>
						<EmptyData
							title={t("modules.room.field.empty.title")}
							description={t("modules.room.field.empty_description.title")}
							actions={[
								{
									name: `+ ${t("common.action.add", { ns: "lib" })}`,
									variant: "add",
									click: () => onAppend(),
								},
							]}
						/>
						<div className="form-footer">
							<Form.Group className="form-group">
								<ButtonLoading loading={loading} variant="primary" type="submit">
									{t("common.action.save", { ns: "lib" })}
								</ButtonLoading>
								{isDirty && (
									<Button variant="light" type="button" onClick={() => history.goBack()}>
										{t("common.action.cancel", { ns: "lib" })}
									</Button>
								)}
							</Form.Group>
						</div>
					</>
				)}
				{showPreview && (
					<RoomModalPreview onHide={() => setShowPreview(undefined)} data={currentRoom} mode={showPreview} />
				)}
			</FormDirty>
		</div>
	);
};
export default RoomsPreview;
