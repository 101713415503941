import { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import { LoadingContainer } from "go-core/components/Loading";
import {
	getChartReport,
	getChartType,
	getLastMonthFilterBetween,
	getReport,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import ReportOrdersUtils from "../../../../../../../../utils/reports/ReportOrdersUtils";
import ReportShareUtils from "../../../../../../../../utils/reports/ReportShareUtils";
import LiveOrdersReport from "./components/LiveOrdersReport";

const listName = "LIVE_ORDER_REPORT";
const resourceType = "LIVE_ORDER_REPORT";

interface Props {
	resource?: Record<string, any>;
}

const Report = ({ resource }: Props): JSX.Element | null => {
	const { t } = useTranslation();
	const [filtersParams, setFilterParams] = useState("");
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);
	if (!resource) return null;

	const data = resource.read();

	let config = {
		reportConfigId: "live_orders",
		type: "order",
		chartType: getChartType(),
		aggregatePrefix: "sales",
		chartAggregate: "total_money",
		groupsInfo: ReportShareUtils.getGroupsInfo(t),
		selectedGroups: ["LATITUDE", "LONGITUDE"],
		showPercent: false,
		columns: [],
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date",
				value: getLastMonthFilterBetween().split("=")[1],
				condition: getLastMonthFilterBetween().split("=")[0],
				visible: true,
			},
		],
		filters: ReportOrdersUtils.getFilters(t, filtersParams),
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				columns: undefined,
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			params = {
				...params,
				groups: "LATITUDE,LONGITUDE",
			};
			setFilterParams(params.f);
			return Promise.all([
				getReport(
					apiReport.getReportsCustom,
					params,
					organization.id,
					config.filters,
					config.type,
					sourceToken,
					true
				),
				getChartReport(
					apiReport.getReportsCustom,
					params,
					["LATITUDE", "LONGITUDE"],
					organization.id,
					config.filters,
					config.type,
					sourceToken
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return getChartReport(
				apiReport.getReportsCustom,
				params,
				["LATITUDE", "LONGITUDE"],
				organization.id,
				config.filters,
				config.type,
				sourceToken
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
	} as ReportConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <LiveOrdersReport config={config} />;
};

export const OrganizationReportsLiveOrdersPage = () => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};
