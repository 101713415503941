import React, { FC, JSX } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ButtonLoading } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as BackSVG } from "go-core/images/svg/back.svg";
import { RenderImage } from "./ImageRenderer/ListImageName";
import Tag from "./Tags/Tag";

interface AvatarProps {
	label?: string;
	image?: Record<string, any>;
	color?: string;
	description?: string;
	date?: string;
}

export interface ButtonProps {
	title?: string;
	action?: () => void;
	path?: string;
	dropdown?: boolean;
	variant?: string;
	content?: JSX.Element;
	loading?: boolean;
	state?: Record<string, any>;
	hide?: boolean;
}

interface Props {
	title: string | JSX.Element;
	buttons?: ButtonProps[];
	subtitle?: JSX.Element;
	back?: () => void;
	avatar?: AvatarProps | JSX.Element;
	tags?: string[];
	customHeaderContent?: JSX.Element;
}

const Header: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const { title, buttons, subtitle, back, avatar, tags } = props;
	const dropdownButtons = buttons !== undefined ? buttons.filter((b) => b.dropdown) : [];
	const defaultButtons = buttons !== undefined ? buttons.filter((b) => !b.dropdown) : [];
	const isMobile = useWindowSize().isMobile;

	const createButton = (button: ButtonProps) => {
		const variant = button.variant !== undefined ? button.variant : "default";
		if (button.hide) return;
		if (button.content) return button.content;

		if (button.dropdown) {
			if (button.action !== undefined) {
				return (
					<Dropdown.Item key={button.title} href="#" onClick={button.action}>
						{button.title}
					</Dropdown.Item>
				);
			}
			if (button.path !== undefined) {
				return (
					<Dropdown.Item as={Link} key={button.title} href="#" to={button.path}>
						{button.title}
					</Dropdown.Item>
				);
			}
		}
		if (button.loading) {
			return (
				<ButtonLoading
					key={button.title}
					onClick={button.action}
					variant={""}
					loading={button.loading}
					className={`btn-${variant}`}
				>
					{button.title}
				</ButtonLoading>
			);
		}
		if (button.action !== undefined) {
			return (
				<Button key={button.title} onClick={button.action} variant={""} className={`btn-${variant}`}>
					{button.title}
				</Button>
			);
		}
		if (button.path !== undefined) {
			return (
				<Link
					key={button.title}
					to={{ pathname: button.path, state: button.state }}
					className={`btn btn-${variant}`}
				>
					{button.title}
				</Link>
			);
		}
	};

	const renderAvatar = () => {
		if (avatar) {
			if (React.isValidElement(avatar)) return avatar;

			const properAvatar = avatar as AvatarProps;
			return (
				<RenderImage
					data={{
						label: properAvatar.label,
						color: properAvatar.color,
					}}
					variant="lg"
					imageLink={properAvatar.image}
					showColor
					date={properAvatar.date}
					description={properAvatar.description}
				/>
			);
		}
	};

	return (
		<div className="content-header">
			<div className={`d-flex align-items-center ${isMobile ? "w-100" : ""}`}>
				{renderAvatar()}
				<div className="d-flex align-items-baseline">
					{back && <BackSVG className="icon me-2 go-back-icon" onClick={back} />}
					{subtitle || (tags && tags.length > 0) ? (
						<div className="content-header-title">
							<h1>{title}</h1>
							{subtitle && <div>{subtitle}</div>}
							{tags && (
								<div className={"tags"}>
									{tags.map((tag, i) => {
										return <Tag title={tag} key={`tag-header-${i}`} />;
									})}
								</div>
							)}
						</div>
					) : (
						<div className="content-header-title">
							<h1>{title}</h1>
						</div>
					)}
				</div>
			</div>
			{(props.customHeaderContent || defaultButtons.length > 0 || dropdownButtons.length > 0) && (
				<div className="d-flex align-items-center second-container">
					{props.customHeaderContent}
					<div className="content-actions">
						{defaultButtons.map((button) => createButton(button))}
						{dropdownButtons.length > 0 && (
							<Dropdown as={ButtonGroup}>
								<Dropdown.Toggle variant="light">{t("lib:common.action.more")}</Dropdown.Toggle>
								<Dropdown.Menu>{dropdownButtons.map((button) => createButton(button))}</Dropdown.Menu>
							</Dropdown>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Header;
