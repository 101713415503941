import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientGroupApi } from "../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../services/Api/api";

interface Props {
	handleClose: () => void;
	handleSave: (data: ClientGroupApi) => void;
	clientGroup?: ClientGroupApi;
}

const ClientGroupModalForm: FC<Props> = (props: Props): JSX.Element => {
	const form = useForm<ClientGroupApi>({
		criteriaMode: "all",
		defaultValues: props.clientGroup,
	});
	const [loading, setLoading] = useState(false);
	const { addFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
	} = form;
	const { t } = useTranslation();
	const onSubmit = handleSubmit(async (data: ClientGroupApi) => {
		const discounts: any[] = [];
		data?.discounts?.forEach((x: { id: number }) => {
			if (x.id) {
				discounts.push(x.id);
			} else {
				discounts.push(x);
			}
		});
		data.discounts = discounts;
		setLoading(true);
		try {
			const clientGroup = await api.organization().createClientGroup(data);
			props.handleSave(clientGroup);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const searchDiscounts = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDiscountsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	return (
		<Modal show={true} onHide={props.handleClose}>
			<form key="client_modal_form">
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.client_groups.header.new.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						register={register}
						name="name"
						errors={errors}
					/>
					<FormMoneyInput
						label={t("modules.client_groups.field.daily_discount_limit.title")}
						control={control}
						currency={currency}
						name="daily_discount_limit.amount"
						errors={errors}
					/>
					<FormSelectGroup
						label={t("common.word.discounts")}
						isMulti={true}
						name="discounts"
						errors={errors}
						defaultValue={null}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						loadOptions={searchDiscounts}
						control={control}
						data-testid="discounts"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.handleClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default ClientGroupModalForm;
