import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import NavigationComponent from "go-app/components/Navigation";
import { HelpItem } from "go-app/components/Navigation/NavigationHelpComponent";
import { GoSuiteApp, MenuAppConfig } from "go-app/services/types";
import { ReactComponent as ChatSVG } from "go-core/images/svg/navigation/helpNavigation/chat.svg";
import { redirectToApplication } from "go-core/utils/utils";
import { OrganizationState } from "go-security/services/organizations/types";
import { UserState } from "go-security/services/users/types";
import { apiSecurity } from "../../services/Api/api";
import { apiUser } from "../../services/Api/apiUser";
import { ExternalApplicationSecurityApi } from "../../services/Api/typesSecurity";
import { IntercomSecurity } from "../../services/intercom/intercomSecurity";
import NavigationProfileSecurity from "./NavigationProfileSecurity/NavigationProfileSecurity";

interface Props {
	logo: string;
	logo_small: string;
	organization: OrganizationState;
	user: UserState;
	isAdmin?: boolean;
	accessServiceRouteLink?: string;
	apps: GoSuiteApp[];
	baseUrl: string;
	menuAppConfig: MenuAppConfig;
}

const NavigationComponentSecurity: FC<Props> = (props) => {
	const { show } = useIntercom();
	const { t } = useTranslation();
	const isAdmin = props.isAdmin !== undefined ? props.isAdmin : false;
	const user = props.user;
	const organization = props.organization;

	const fetchMenuApplication = apiUser.getExternalOrganization;

	const fetchInstalledApps = async () => {
		if (organization.company_id) {
			const res = await apiSecurity.organization().getExternalApplications();
			return res.data.data.map((x: ExternalApplicationSecurityApi) => x.type);
		}
		return [];
	};
	const getGoAccountsUrl = () => {
		return props.apps.filter((x) => x.id.toUpperCase() === "GOACCOUNTS")[0].url;
	};
	const renderProfile = () => {
		const goAccountsAppUrl = getGoAccountsUrl();
		if (!goAccountsAppUrl) return <></>;
		return (
			<NavigationProfileSecurity
				goaccounts_url={goAccountsAppUrl}
				user={user}
				organization={organization}
				isAdmin={isAdmin}
				baseUrl={props.baseUrl}
			/>
		);
	};
	if (!user) {
		return null;
	}
	const helpOptions: HelpItem[] = [];
	if (IntercomSecurity.isInitialized()) {
		helpOptions.push({
			name: t("lib:go_component.navigation.open_chat"),
			onClick: () => show(),
			icon: <ChatSVG />,
			isOnTop: true,
		});
	}
	const supportAccessUrl = props.accessServiceRouteLink
		? props.accessServiceRouteLink
		: organization.company_id
		? redirectToApplication(getGoAccountsUrl(), organization.company_id, "help/support_access")
		: undefined;

	return (
		<NavigationComponent
			apps_link_show_all={`/${organization.id}/apps/active`}
			apps_fetch_installed={fetchInstalledApps}
			profile_name={user.name ? user.name : user.email}
			profile_avatar={user.avatar_link?.small ? user.avatar_link.small : undefined}
			organization_name={organization.name}
			company_id={organization.company_id}
			apps={props.apps}
			logo={props.logo}
			logo_small={props.logo_small}
			profile_content={renderProfile()}
			help_options={helpOptions}
			accessServiceRouteLink={supportAccessUrl}
			organization={organization}
			fetchMenuApplication={fetchMenuApplication}
			menuAppConfig={props.menuAppConfig}
		>
			{props.children}
		</NavigationComponent>
	);
};

export default NavigationComponentSecurity;
