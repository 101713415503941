import React from "react";
import { Modal } from "react-bootstrap";
import { useWindowSize } from "go-core/components/useWindowSize";

interface Props {
	show: boolean;
	onHide: () => void;
	pdfLink: string;
}

const InvoicePdfPreviewModal = ({ pdfLink, onHide, show }: Props) => {
	const isMobile = useWindowSize().isMobile;

	return (
		<Modal show={show} onHide={onHide} size="lg" className="invoice-pdf-preview-modal">
			<Modal.Header closeButton />
			<Modal.Body>
				<div
					className="w-100"
					style={{
						height: isMobile ? 600 : 800,
					}}
				>
					<img src={pdfLink} alt="invoice-image" className="image w-100" />
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default InvoicePdfPreviewModal;
