import { RefObject } from "react";
import { Dropdown } from "react-bootstrap";
import { createPortal } from "react-dom";
import { ReactComponent as KebabMenuIconSVG } from "../../../../images/kebab-menu-icon.svg";

interface ActionsMenuProps {
	mainDivRef: RefObject<HTMLDivElement>;
	isButtonPrimary?: boolean;
	children: React.ReactNode;
}

export const ActionsMenu = ({ mainDivRef, children, isButtonPrimary }: ActionsMenuProps): JSX.Element | null => {
	if (!mainDivRef.current) {
		return null;
	}

	return (
		<Dropdown>
			<Dropdown.Toggle variant={isButtonPrimary ? "primary" : "light"}>
				<KebabMenuIconSVG />
			</Dropdown.Toggle>

			{createPortal(
				<div className="dropdown-portal">
					<Dropdown.Menu>{children}</Dropdown.Menu>
				</div>,
				mainDivRef.current
			)}
		</Dropdown>
	);
};
