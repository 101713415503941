import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { PaymentMethodApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import PaymentMethodForm from "../../components/PaymentMethodForm";

interface MatchParams {
	paymentMethodId: string;
	organizationId: string;
}

const OrganizationSettingsPaymentMethodsEditPage = (): JSX.Element => {
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethodApi | undefined>();
	const [showLogs, setShowLogs] = useState(false);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { paymentMethodId } = useParams<MatchParams>();

	useEffect(() => {
		onFetchPaymentMethod();
	}, []);

	const onFetchPaymentMethod = async () => {
		try {
			const params: Record<string, string> = { include: "custom_field_templates,roles" };
			const res = await api.organization().getPaymentMethod(Number(paymentMethodId), params);
			handleChangeTabTitle(`${res.name} | ${t("common.word.payment_methods")}`);
			setPaymentMethod(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removePaymentMethod = async (paymentMethodToBeRemoved: PaymentMethodApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removePaymentMethod(paymentMethodToBeRemoved.id);
			history.push(`/${organization.id}/settings/payment_methods`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restorePaymentMethod = async (paymentMethodToBeRestored: PaymentMethodApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().restorePaymentMethod(paymentMethodToBeRestored.id);
			setPaymentMethod(res);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const disablePaymentMethod = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			const res = await api.organization().disablePaymentMethod(Number(paymentMethod?.id));
			setPaymentMethod(res);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!paymentMethod) {
		return <LoadingContainer />;
	}

	const renderTitle = () => (
		<>
			{t("common.word.payment_methods")} "{paymentMethod.name}"
			<FormatResourceStatus status={paymentMethod.status} />
		</>
	);

	const buttons: ButtonProps[] = [
		{
			action: () => setShowLogs(!showLogs),
			title: t("common.word.logs", { ns: "lib" }),
			variant: "light",
		},
	];
	if (!paymentMethod.default && paymentMethod.status !== "DELETED")
		buttons.push({
			action: () => removePaymentMethod(paymentMethod),
			title: t("common.action.remove", { ns: "lib" }),
			dropdown: true,
		});
	if ((!paymentMethod.default && paymentMethod.status === "DELETED") || paymentMethod.status === "DISABLED")
		buttons.push({
			action: () => restorePaymentMethod(paymentMethod),
			title: t("common.action.activate", { ns: "lib" }),
			dropdown: true,
		});
	if (paymentMethod.status === "ENABLED")
		buttons.push({
			action: () => disablePaymentMethod(),
			title: t("common.action.disable", { ns: "lib" }),
			dropdown: true,
		});

	return (
		<>
			<Header title={renderTitle()} />
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={paymentMethod.id}
					resourceType={"PAYMENT_METHOD"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			<PaymentMethodForm paymentMethod={paymentMethod} />
		</>
	);
};

export default OrganizationSettingsPaymentMethodsEditPage;
