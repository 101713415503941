import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { MenuPriceListApi } from "../../../../../../../../../../../../../../services/Api/Organization/types";
import { OptionType } from "./AssignPricesModal";

interface Props {
	setOptionType: (option: OptionType) => void;
	optionType: OptionType;
	priceLists: MenuPriceListApi[];
	setSelectedPriceList: (priceList: MenuPriceListApi | null) => void;
	selectedPriceList: MenuPriceListApi | null;
	setNewPrice: (price: number) => void;
	newPrice?: number;
}

const AssignPricesModalOptions = ({
	setOptionType,
	optionType,
	priceLists,
	setSelectedPriceList,
	selectedPriceList,
	newPrice,
	setNewPrice,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const helpTextStyles = { marginLeft: "36px" };
	const handleChangeOptionType = (option: OptionType) => {
		setOptionType(option);
	};

	return (
		<>
			<div className="d-flex flex-column mb-3">
				<Form.Check
					type="switch"
					id={OptionType.DEFAULT_PRICE}
					label={t("enums.price_lists.assign_price_options.DEFAULT_PRICE.title")}
					checked={optionType === OptionType.DEFAULT_PRICE}
					onChange={() => handleChangeOptionType(OptionType.DEFAULT_PRICE)}
				/>
				<small className="text-muted" style={helpTextStyles}>
					{t("enums.price_lists.assign_price_options.DEFAULT_PRICE.help_text")}
				</small>
			</div>
			<div className="d-flex flex-column mb-3">
				<Form.Check
					type="switch"
					id={OptionType.PRICE_BASED_ON_PRICE_LISTS}
					label={t("enums.price_lists.assign_price_options.PRICE_BASED_ON_PRICE_LISTS.title")}
					checked={optionType === OptionType.PRICE_BASED_ON_PRICE_LISTS}
					onChange={() => handleChangeOptionType(OptionType.PRICE_BASED_ON_PRICE_LISTS)}
				/>
				<small className="text-muted" style={helpTextStyles}>
					{t("enums.price_lists.assign_price_options.PRICE_BASED_ON_PRICE_LISTS.help_text")}
				</small>
				{optionType === OptionType.PRICE_BASED_ON_PRICE_LISTS && (
					<Select
						className="price-list-select mt-2"
						value={selectedPriceList}
						onChange={(value) => setSelectedPriceList(value)}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id.toString()}
						options={priceLists}
						placeholder={t("lib:common.action.select")}
					/>
				)}
			</div>
			<div className="d-flex flex-column mb-3">
				<Form.Check
					id={OptionType.SET_PRICE}
					type="switch"
					label={t("enums.price_lists.assign_price_options.SET_PRICE.title")}
					checked={optionType === OptionType.SET_PRICE}
					onChange={() => handleChangeOptionType(OptionType.SET_PRICE)}
				/>
				<small className="text-muted" style={helpTextStyles}>
					{t("enums.price_lists.assign_price_options.SET_PRICE.help_text")}
				</small>
				{optionType === OptionType.SET_PRICE && (
					<input
						type="number"
						className="form-control price-input mt-2"
						value={newPrice}
						onWheel={(e) => e.currentTarget.blur()}
						placeholder={t("modules.group_edit.field.new_price.placeholder")}
						onChange={(e) => setNewPrice(Number(e.target.value))}
					/>
				)}
			</div>
			<div className="d-flex flex-column">
				<Form.Check
					id={OptionType.RESET}
					type="switch"
					label={t("enums.price_lists.assign_price_options.RESET.title")}
					checked={optionType === OptionType.RESET}
					onChange={() => handleChangeOptionType(OptionType.RESET)}
				/>
				<small className="text-muted" style={helpTextStyles}>
					{t("enums.price_lists.assign_price_options.RESET.help_text")}
				</small>
			</div>
		</>
	);
};

export default AssignPricesModalOptions;
