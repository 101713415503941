import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { LiveOrderApi, OrderPreparationStatusName } from "../../../../../../../../../services/Api/Organization/types";
import LiveOrdersListItem from "../../../components/LiveOrdersListItem";
import { getOrderPreparationStatusColor, preparationStatusColors } from "../../../services/orderStatus";

interface Props {
	status: OrderPreparationStatusName;
	orders: LiveOrderApi[];
	orderListRoutePath: string;
}

const LiveOrderTableColumn = ({ status, orders, orderListRoutePath }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);

	const getOrderAmountFontColor = () => {
		const statusColor = getOrderPreparationStatusColor(status);
		switch (statusColor) {
			case preparationStatusColors.YELLOW:
			case preparationStatusColors.WHITE:
				return "#000000";
			default:
				return "#ffffff";
		}
	};

	const handleSelectOrder = (orderId: number) => {
		history.push(`/${organization.id}/live_orders/table/orders/${orderId}`);
	};

	return (
		<div className="live-orders-table-item">
			<div className="status-header mb-3">
				<div
					className="status-circle"
					style={{ backgroundColor: getOrderPreparationStatusColor(status) }}
				></div>
				<h6 className="mb-0">{t(`enums.preparation_statuses.${status}`).toUpperCase()}</h6>
				<div
					className="order-amount"
					style={{
						backgroundColor: getOrderPreparationStatusColor(status),
						color: getOrderAmountFontColor(),
					}}
				>
					{orders.length}
				</div>
			</div>
			<div>
				{orders.map((order) => (
					<LiveOrdersListItem
						order={order}
						key={order.id}
						orderListRoutePath={orderListRoutePath}
						onSelectOrder={() => handleSelectOrder(order.id)}
					/>
				))}
			</div>
		</div>
	);
};

export default LiveOrderTableColumn;
