import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormCheck } from "go-form";
import { RoundMoney } from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../images/svg/remove.svg";
import { InvoiceApi, InvoiceSummaryTaxApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";

interface Props {
	form: UseFormReturn<InvoiceApi>;
	summaryTaxes: InvoiceSummaryTaxApi[];
}

const InvoiceFormPaymentsTable = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState,
		formState: { errors },
		control,
		watch,
		register,
		getValues,
		setValue,
	} = props.form;
	const { fields, remove, append } = useFieldArray({
		control,
		name: "payments",
		keyName: "key",
	});
	const grossSummary = RoundMoney(
		props.summaryTaxes.reduce((acc, val) => acc + (val.price_gross?.amount ? val.price_gross?.amount : 0), 0)
	);
	const watchedPayDueDate = watch("dates.payment_due_date");
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const watchedPayments = watch("payments");
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		autocompleteEmptyPaymentsDates();
	}, [watchedPayDueDate]);

	useEffect(() => {
		autocompleteDefaultPaymentPriceAmount();
	}, [grossSummary]);

	const searchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const autocompleteDefaultPaymentPriceAmount = () => {
		if (fields.length === 1 && formState.isDirty) {
			setValue(`payments.0.price_paid.amount`, grossSummary);
		}
	};

	const autocompleteEmptyPaymentsDates = () => {
		fields.forEach((field, index) => {
			if (!field.paid_at) {
				setValue(`payments.${index}.paid_at`, watchedPayDueDate);
			}
		});
	};

	return (
		<fieldset className={"form-group"}>
			<h5>{t("modules.invoice.field.payment.title")}</h5>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className={"table table-form invoice-items"}>
					<thead>
						<tr>
							<th className={"action"} />
							<th style={{ minWidth: isMobile ? "150px" : "unset" }} className={"w-40"}>
								{t("common.word.status", { ns: "lib" })}
							</th>
							<th style={{ minWidth: isMobile ? "200px" : "unset" }} className={"w-30"}>
								{t("common.word.payment_method")}
							</th>
							<th style={{ minWidth: isMobile ? "200px" : "unset" }}>
								{t("modules.invoice.field.paid_at.title")}
							</th>
							<th style={{ minWidth: isMobile ? "150px" : "unset" }} className={"w-10"}>
								{t("common.word.amount")}
							</th>
							<th className="action" />
						</tr>
					</thead>
					<tbody>
						{watchedPayments.map((payment, index) => {
							return (
								<tr key={payment.id}>
									<td className={"action"}></td>
									<td>
										<FormCheck
											type={"switch"}
											label={t("common.word.paid")}
											className={"ms-1"}
											register={register}
											name={`payments.${index}.paid`}
											errors={errors}
										/>
									</td>
									<td>
										<FormSelectGroup
											name={`payments.${index}.payment_method_id`}
											errors={errors}
											getOptionValue={(opt) => Number(opt.id)}
											getOptionLabel={(opt) => opt.label}
											isClearable={false}
											loadOptions={searchPaymentMethods}
											control={control}
											defaultValue={{
												id: payment.payment_method_id,
												label: payment.transaction?.payment_method_name,
											}}
											onChange={(_, objFull) => {
												setValue(`payments.${index}`, {
													...getValues(`payments.${index}`),
													transaction: {
														...getValues(`payments.${index}`).transaction,
														payment_method_name: objFull.label,
													},
												});
											}}
											data-testid={`payments.${index}.payment_method_id`}
										/>
									</td>
									<td>
										<FormDatePicker
											timePicker={false}
											defaultValue={payment.paid_at}
											control={control}
											name={`payments.${index}.paid_at`}
											errors={errors}
										/>
									</td>
									<td>
										<FormMoneyInput
											currency={currency}
											name={`payments.${index}.price_paid.amount`}
											defaultValue={payment.price_paid?.amount}
											errors={errors}
											control={control}
										/>
									</td>
									<td className="action">
										{!(fields.length === 1 && index === 0) && (
											<RemoveSVG className={"icon"} onClick={() => remove(index)} />
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<Button variant="add" type="button" onClick={() => append({})}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
		</fieldset>
	);
};
export default InvoiceFormPaymentsTable;
