import React from "react";
import { PosReportApi } from "../../../../../../../../../../../services/Api/Organization/types";
import EmployeesTabContent from "./EmployeesTabContent";

interface Props {
	report: PosReportApi;
}
const DeliveryEmployeesTabPreview = ({ report }: Props): JSX.Element => {
	return <EmployeesTabContent report={report} employees={report.delivery_employees} asDeliveryEmployees />;
};

export default DeliveryEmployeesTabPreview;
