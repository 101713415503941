import { ConfigReportComboApi } from "go-report-combo/services/types";

export const REPORT_basic_source_compare = {
	id: "REPORT_basic_source_compare",
	name: "Sprzedaz wedlug zrodla z porównaniem okresów",
	data: [
		{
			id: "BILLS",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids}`,
			groups: ["NONE", "SOURCE"],
			columns: [
				{
					id: "summary",
					filter: "date_range|bt={date_range__QUARTER}",
				},
			],
		},
		{
			id: "BILLS_COMPARE",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids}`,
			groups: ["NONE", "SOURCE"],
			columns: [
				{
					id: "summary",
					filter: "date_range|bt={date_range_compare__QUARTER}",
				},
			],
		},
	],
	rows: [
		{
			id: "BILLS",
			name: "BILLS",
			data_id: "BILLS",
			group: "SOURCE",
			aggregate: "total_money",
			aggregate_columns: {
				total_money: "total_money",
				transaction_count: "transaction_count",
				average_money: "average_money",
			},
			compare: [
				{
					id: "previous_percent",
					data_id: "BILLS_COMPARE",
					name: "vs poprzedni",
					format: "VALUE",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
						transaction_count: "transaction_count",
						average_money: "average_money",
					},
				},
				{
					id: "previous_percent",
					data_id: "BILLS_COMPARE",
					name: "vs poprzedni %",
					format: "PERCENT",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
						transaction_count: "transaction_count",
						average_money: "average_money",
					},
				},
			],
		},
	],
	columns: [
		{
			id: "total_money",
			data_column_id: "summary",
			name: "Sprzedaż",
		},
		{
			id: "transaction_count",
			data_column_id: "summary",
			name: "Ilość",
		},
		{
			id: "average_money",
			data_column_id: "summary",
			name: "Sredni rachunek",
		},
	],
} as ConfigReportComboApi;
