import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ListParamsType } from "../../../../../../list/services/types";
import { ListConfigAction } from "../../../services/types";

interface SingleActionButtonsProps {
	action: ListConfigAction;
	item: any;
	params?: ListParamsType;
}

export const SingleActionButton = ({ action, item, params }: SingleActionButtonsProps): JSX.Element | null => {
	if (action.link) {
		const linkTo = action.link(item) || location.pathname;
		return (
			<Link to={linkTo} className="btn btn-light">
				{action.name}
			</Link>
		);
	}

	const onClick = () => action.click?.(item, params);

	if (onClick) {
		return (
			<Button variant="light" onClick={onClick}>
				{action.name}
			</Button>
		);
	}

	return null;
};
