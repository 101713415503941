import React from "react";
import { useSelector } from "react-redux";
import SecurityAccessDenied from "go-security/components/AccessDenied";
import SecurityUsersListComponent from "go-security/components/Organization/User/UsersList";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";

const OrganizationSettingsUsersPage = (): JSX.Element => {
	const hasAccess = useSelector(hasPermission("MANAGE"));
	const organization = useSelector(selectOrganization);

	if (!hasAccess) return <SecurityAccessDenied />;

	const taxIdNo = organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined;
	const companyName = organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined;
	const pdfOrientation = organization?.more?.pdf_orientation;
	const pdfFontSize = organization?.more?.default_pdf_font_size?.toString();

	return (
		<div className="content">
			<SecurityUsersListComponent
				organizationName={organization.name}
				companyName={companyName}
				taxIdNo={taxIdNo}
				pdfFontSize={pdfFontSize}
				pdfOrientation={pdfOrientation}
			/>
		</div>
	);
};
export default OrganizationSettingsUsersPage;
