import React, { JSX, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import DeliveryZoneAppModalForm from "../../components/Form/DeliveryZoneAppModalForm";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "DELIVERY_ZONE";
const resourceType = "DELIVERY_ZONE";

const List = ({ resource }: ListState): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);
	const [items, setItems] = useState<DeliveryZoneApi[]>([]);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				render: (item: DeliveryZoneApi) => {
					return <Link to={`${location.pathname}/${item.id}`}>{item.name}</Link>;
				},
				renderExport: (item: DeliveryZoneApi) => item.name,
			},
			{
				id: "app",
				name: t("modules.delivery_zone.field.app.title"),
				render: (item: DeliveryZoneApi) => (
					<Link to={`/${organization.id}/apps/active/${item.app.id}`}>{item.app.name}</Link>
				),
				renderExport: (item: DeliveryZoneApi) => item.app.name,
			},
			{
				id: "delivery_price",
				name: t("modules.delivery_zone.field.delivery_price.title"),
				render: (item: DeliveryZoneApi) => FormatMoney(item.delivery_price),
			},
			{
				id: "min_order_price",
				name: t("modules.delivery_zone.field.min_order_price.title"),
				render: (item: DeliveryZoneApi) => FormatMoney(item.min_order_price),
			},
			{
				id: "free_delivery_price",
				name: t("modules.delivery_zone.field.free_delivery_price.title"),
				render: (item: DeliveryZoneApi) => FormatMoney(item.free_delivery_price),
			},
		],
		filters: [],
		selectedColumns: ["name", "app", "delivery_price", "min_order_price", "free_delivery_price"],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
		],
		actions: [
			{
				name: t("lib:common.action.edit"),
				link: (item: DeliveryZoneApi) => `${location.pathname}/${item.id}`,
			},
		],
		exportConfig: {
			title: t("modules.delivery_zone.field.export_config.title"),
			filename: t("modules.delivery_zone.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			const params = {
				...fetchParams,
				include: "app",
			};
			return api.organization().getDeliveryZones(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return <ListData data={items} onFetch={setItems} config={config} />;
};

const OrganizationSettingsDeliveryZonesIndexPage = (props: RouteComponentProps) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const history = useHistory();
	const [showAppModal, setShowAppModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const handleAdd = async () => {
		setLoading(true);
		try {
			const apps = await apiOrganization.getApps({ size: 0 });
			const goOrderApps = apps.filter((app) => app.provider === "GOORDER");
			if (goOrderApps.length > 1) {
				setShowAppModal(true);
			} else {
				history.push(`${props.match.url}/new`, { appId: goOrderApps[0].id });
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: handleAdd,
			variant: "primary",
			loading,
		},
	];

	useEffect(() => {
		handleChangeTabTitle(t("modules.delivery_zone.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<>
			<Header title={t("modules.delivery_zone.header.title")} buttons={buttons} />
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
			{showAppModal && <DeliveryZoneAppModalForm show={showAppModal} onHide={() => setShowAppModal(false)} />}
		</>
	);
};

export default OrganizationSettingsDeliveryZonesIndexPage;
