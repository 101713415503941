import { FC } from "react";
import { ReactComponent as ActiveSVG } from "../../../../../../../images/svg/apps/status/active.svg";
import { ReactComponent as ErrorSVG } from "../../../../../../../images/svg/apps/status/deleted.svg";
import { ReactComponent as InactiveSVG } from "../../../../../../../images/svg/apps/status/inactive.svg";
import { ReactComponent as NewSVG } from "../../../../../../../images/svg/apps/status/new.svg";

interface Props {
	status?: string;
	className?: string;
}

const AppStatus: FC<Props> = ({ status, className }) => {
	switch (status) {
		case "NEW":
			return <InactiveSVG className={className} />;
		case "DELETED":
		case "ERROR":
			return <ErrorSVG className={className} />;
		case "ENABLED":
			return <ActiveSVG className={className} />;
		default:
			return <NewSVG className={className} />;
	}
};

export default AppStatus;
