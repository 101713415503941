import { TFunction } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import { getDayName, getMonthName } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { getDaysOfMonth, getReportFilter } from "go-report/reports/services/report-service";
import { OrderApi } from "../../services/Api/Organization/types";
import { apiReport } from "../../services/Api/Report/apiReport";

export class ReportDiscountsUtils {
	static getSelectedColumns(columnType: string): string[] {
		const columnsFromLocalStorage = window.localStorage.getItem(`go_report.${columnType}.columns`)?.split(",");
		if (columnsFromLocalStorage) {
			return columnsFromLocalStorage;
		}
		return ["total_money", "transaction_count"];
	}

	static getGroupsInfo = (t: TFunction) => {
		return {
			TYPE: (groupByValue: Record<string, any>) => {
				if (groupByValue.name) return t(`enums.discounts.type.${groupByValue.name}`);

				return "-";
			},
			ORDER_CLOSED_AT: (groupByValue: Record<string, any>) => {
				const date = new Date(Number(groupByValue.name));
				return date.toLocaleDateString();
			},
			ORDER_CLOSED_AT_MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			ORDER_CLOSED_AT_DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			ORDER_REPORT_DATE: (groupByValue: Record<string, any>) => {
				const date = new Date(Number(groupByValue.name));
				return date.toLocaleDateString();
			},
			ORDER_REPORT_MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			ORDER_REPORT_DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			ORDER_STATUS: (groupByValue: Record<string, any>) => {
				if (groupByValue.name) {
					return t(`enums.common.status.${groupByValue.name}`);
				}
				return "-";
			},
			ORDER_TYPE: (groupByValue: Record<string, any>) => {
				if (groupByValue.name) {
					return t(`enums.orders.types.${groupByValue.name}`);
				}
				return "-";
			},
			NAME: (groupByValue: Record<string, any>) => {
				if (t(`enums.discounts.type.${groupByValue.name}`).includes(".")) {
					return groupByValue.name;
				}
				return t(`enums.discounts.type.${groupByValue.name}`);
			},
			PAYMENT_METHOD: (groupByValue: Record<string, any>) => {
				if (!groupByValue.name || groupByValue.name === "empty") return t("common.word.unpaid_female");
				return groupByValue.name;
			},
		};
	};

	static getGroups = (t: TFunction) => {
		return [
			{
				id: "ORDER_EMPLOYEE",
				name: t("components.reports.groups.ORDER_EMPLOYEE"),
			},
			{
				id: "CREATED_BY_EMPLOYEE",
				name: t("components.reports.groups.CREATED_BY_EMPLOYEE"),
			},
			{
				id: "AUTHORIZED_BY_EMPLOYEE",
				name: t("components.reports.groups.AUTHORIZED_BY_EMPLOYEE"),
			},
			{
				id: "NAME",
				name: t("lib:common.word.name"),
			},
			{
				id: "TYPE",
				name: t("common.word.discount_type"),
			},
			{
				id: "ORDER_NUMBER",
				name: t("components.reports.groups.ORDER_NUMBER"),
			},
			{
				id: "DRIVER",
				name: t("components.reports.groups.DRIVER"),
			},
			{
				id: "DELIVERY_ZONE",
				name: t("components.reports.groups.DELIVERY_ZONE"),
			},
			{
				id: "CITY",
				name: t("lib:common.address.city"),
			},
			{
				id: "STREET",
				name: t("lib:common.address.street"),
			},
			{
				id: "BUILD_NR",
				name: t("lib:common.address.build_nr"),
			},
			{
				id: "POINT_OF_SALE",
				name: t("common.word.point_of_sale"),
			},
			{
				id: "TERMINAL",
				name: t("components.reports.groups.TERMINAL"),
			},
			{
				id: "ORDER_CLOSED_AT",
				name: t("components.reports.groups.CLOSED_AT"),
			},
			{
				id: "ORDER_CLOSED_AT_YEAR",
				name: t("components.reports.groups.YEAR"),
			},
			{
				id: "ORDER_CLOSED_AT_MONTH",
				name: t("components.reports.groups.MONTH"),
			},
			{
				id: "ORDER_CLOSED_AT_DAY_OF_MONTH",
				name: t("components.reports.groups.DAY_OF_MONTH"),
			},
			{
				id: "ORDER_CLOSED_AT_DAY_OF_WEEK",
				name: t("components.reports.groups.DAY_OF_WEEK"),
			},
			{
				id: "ORDER_CLOSED_AT_HOUR_OF_DAY",
				name: t("components.reports.groups.HOUR"),
			},
			{
				id: "ORDER_CLOSED_AT_MINUTE_OF_HOUR",
				name: t("components.reports.groups.MINUTE_OF_HOUR"),
			},
			{
				id: "ORDER_REPORT_DATE",
				name: t("components.reports.groups.REPORT_DATE"),
			},
			{
				id: "ORDER_REPORT_YEAR",
				name: t("components.reports.groups.REPORT_DATE_YEAR"),
			},
			{
				id: "ORDER_REPORT_MONTH",
				name: t("components.reports.groups.REPORT_DATE_MONTH"),
			},
			{
				id: "ORDER_REPORT_DAY_OF_WEEK",
				name: t("components.reports.groups.REPORT_DATE_DAY_OF_WEEK"),
			},
			{
				id: "ORDER_REPORT_HOUR_OF_DAY",
				name: t("components.reports.groups.REPORT_DATE_HOUR_OF_DAY"),
			},
			{
				id: "ORDER_REPORT_MINUTE_OF_HOUR",
				name: t("components.reports.groups.REPORT_DATE_MINUTE_OF_HOUR"),
			},
			{
				id: "ROOM",
				name: t("components.reports.groups.ROOM"),
			},
			{
				id: "TABLE",
				name: t("components.reports.groups.TABLE"),
			},
			{
				id: "ORDER_COMMENT",
				name: t("components.reports.groups.ORDER_COMMENT"),
			},
			{
				id: "CLIENT",
				name: t("components.reports.groups.CLIENT"),
			},
			{
				id: "CLIENT_GROUP",
				name: t("components.reports.groups.CLIENT_GROUP"),
			},
			{
				id: "NUMBER_OF_GUESTS",
				name: t("components.reports.groups.NUMBER_OF_GUESTS"),
			},
			{
				id: "ORDER_STATUS",
				name: t("components.reports.groups.ORDER_STATUS"),
			},
			{
				id: "ORDER_TYPE",
				name: t("components.reports.groups.ORDER_TYPE"),
			},
			{
				id: "ORDER_SOURCE",
				name: t("components.reports.groups.ORDER_SOURCE"),
			},
			{
				id: "PAYMENT_METHOD",
				name: t("common.word.payment_method"),
			},
		];
	};

	static getColumns = (t: TFunction) => {
		return [
			{
				id: "total_money",
				name: t("modules.discount_report.field.total_money.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "transaction_count",
				name: t("modules.discount_report.field.transaction_count.title"),
			},
		];
	};

	static getFilters = (t: TFunction, type: string, filtersParams?: string) => {
		return [
			{
				id: "date_range",
				name: t("modules.item_group_report.field.date_range.title"),
				type: "date",
				options: ["bt"],
				isRemovable: false,
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"PAYMENT_METHOD",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
				conditionCustomLabelConfig: [{ condition: "u", label: t("common.word.unpaid_female") }],
			},
			{
				id: "order_type",
				name: t("components.reports.groups.ORDER_TYPE"),
				type: "list",
				disableSorting: true,
				options: {
					DELIVERY: t("enums.orders.types.DELIVERY"),
					DINE_IN: t("enums.orders.types.DINE_IN"),
					PICK_UP: t("enums.orders.types.PICK_UP"),
					ROOM_SERVICE: t("enums.orders.types.ROOM_SERVICE"),
				},
				render: (item: OrderApi) => t(`enums.orders.types.${item.type}`),
			},
			{
				id: "client_group",
				name: t("modules.item_group_report.field.client_groups.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CLIENT_GROUP",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "client",
				name: t("common.word.client"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CLIENT",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "order_comment",
				name: t("components.reports.groups.ORDER_COMMENT"),
				type: "text",
			},
			{
				id: "table",
				name: t("common.word.table"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"TABLE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "room",
				name: t("modules.item_group_report.field.rooms.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ROOM",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "order_report_date",
				name: t("components.reports.groups.REPORT_DATE"),
				type: "date",
			},
			{
				id: "order_closed_at_day_of_month",
				name: t("common.word.day_of_month"),
				type: "list",
				options: getDaysOfMonth(),
			},
			{
				id: "order_closed_at_day_of_week",
				name: t("common.word.day_of_week"),
				type: "list",
				options: {
					1: t("enums.days.MONDAY", { ns: "lib" }),
					2: t("enums.days.TUESDAY", { ns: "lib" }),
					3: t("enums.days.WEDNESDAY", { ns: "lib" }),
					4: t("enums.days.THURSDAY", { ns: "lib" }),
					5: t("enums.days.FRIDAY", { ns: "lib" }),
					6: t("enums.days.SATURDAY", { ns: "lib" }),
					7: t("enums.days.SUNDAY", { ns: "lib" }),
				},
			},
			{
				id: "order_closed_at",
				name: t("components.reports.groups.CLOSED_AT"),
				type: "date",
			},
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"TERMINAL",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "point_of_sale",
				name: t("common.word.point_of_sale"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"POINT_OF_SALE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "city",
				name: t("lib:common.address.city"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CITY",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "street",
				name: t("lib:common.address.street"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"STREET",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "build_nr",
				name: t("common.address.build_nr", { ns: "lib" }),
				type: "text",
			},
			{
				id: "delivery_zone",
				name: t("components.reports.groups.DELIVERY_ZONE"),
				type: "text",
			},
			{
				id: "driver",
				name: t("modules.item_group_report.field.drivers.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"DRIVER",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "order_number",
				name: t("common.word.order_number"),
				type: "text",
				options: ["e", "c"],
			},
			{
				id: "order_promotion_type",
				name: t("common.word.discount_type"),
				type: "list",
				options: {
					FIXED_PRICE: t("enums.discounts.type.FIXED_PRICE"),
					PERCENT: t("enums.discounts.type.PERCENT"),
					PERCENT_ITEM: t("enums.discounts.type.PERCENT_ITEM"),
					WASTED: t("enums.discounts.type.WASTED"),
					BOGO: t("enums.discounts.type.BOGO"),
					AMOUNT: t("enums.discounts.type.AMOUNT"),
					AMOUNT_ITEM: t("enums.discounts.type.AMOUNT_ITEM"),
					VOUCHER: t("enums.discounts.type.VOUCHER"),
					TIP: t("enums.discounts.type.TIP"),
					DELIVERY: t("enums.discounts.type.DELIVERY"),
					CREDIT: t("enums.discounts.type.CREDIT"),
				},
			},
			{
				id: "name",
				name: t("modules.discount_report.field.discount_name.title"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"NAME",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "order_employee",
				name: t("components.reports.groups.ORDER_EMPLOYEE"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"ORDER_EMPLOYEE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "created_by_employee",
				name: t("components.reports.groups.CREATED_BY_EMPLOYEE"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"CREATED_BY_EMPLOYEE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
			{
				id: "authorized_by_employee",
				name: t("components.reports.groups.AUTHORIZED_BY_EMPLOYEE"),
				type: "label_search_select",
				source: {
					request: (queryString?: string, params?: Record<string, any>) =>
						getReportFilter(
							apiReport.getReportFilter,
							"AUTHORIZED_BY_EMPLOYEE",
							queryString,
							filtersParams,
							type,
							undefined,
							params
						),
				},
			},
		];
	};
}
