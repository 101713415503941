import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { appIcon } from "go-core/components/Apps/AppIcon";
import { redirectToApplication } from "go-core/utils/utils";
import { GoSuiteApp, OrganizationCard } from "../../services/types";

interface Props {
	application: GoSuiteApp;
	organization: OrganizationCard;
}

const ListCardOrganizationApplication: FC<Props> = ({ application, organization }) => {
	const { t } = useTranslation();
	const isInstalled = Boolean(
		application.id.toLowerCase() === "goaccounts" ||
			organization.applications?.filter((o) => o.toUpperCase() === application.id.toUpperCase())[0]
	);

	const redirectUrl = application.old
		? application.url
		: redirectToApplication(application.url, organization.company_id);

	return (
		<a
			className={`col-4 p-1 mb-3 text-center application-item application-${application.id} ${
				!isInstalled ? "application-not-installed" : "application-installed"
			}`}
			href={redirectUrl}
		>
			<span
				className={`social-icon text-xs-center application-img text-${application.id} ${
					!isInstalled && "application-img-disabled"
				}`}
			>
				{appIcon(application.id)}
			</span>
			<h6
				className={`mt-2 mb-0 ${!isInstalled ? "application-name-muted" : `application-name`} app-name-heading`}
			>
				{application.name}
			</h6>
			<h6 className={`mt-2 mb-0 application-name app-install-heading`}>
				{application.old ? t("lib:common.action.goto") : t("lib:common.action.install")}
			</h6>
			<span className="application-header">{t(`lib:enum.apps.${application.id}.header`)}</span>
		</a>
	);
};

export default ListCardOrganizationApplication;
