import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ButtonLoading, FormCheck, FormInput, addServerErrors, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import EmptyData from "go-core/components/EmptyData";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import FormNumberInput from "go-form/components/FormNumberInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as BackSVG } from "../../../../../../../../../../images/svg/menu/header-back.svg";
import {
	ItemApi,
	ItemModifierGroupApi,
	ItemModifierGroupOptionApi,
	ItemPriceOverrideApi,
	ModifierGroupApi,
	ModifierGroupOptionApi,
	QuantityInfoApi,
	QuantityInfoOverrideApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { checkIfValueIsEmpty } from "../../utils";
import ItemModifierGroupOptionsTable from "./ItemModifierGroupOptionsTable";
import ModifierGroupOptionsTable from "./ModifierGroupOptionsTable";
import SearchItemsModal from "./SearchItemsModal";

interface Props {
	searchText?: string;
	handleCancel: () => void;
	handleClose: () => void;
	handleSave: (
		data: ModifierGroupApi,
		id: number | null,
		itemVariants: ItemApi[],
		quantityOverrides?: Record<string, any>[],
		priceOverrides?: Record<string, any>[],
		itemQuantityOverrides?: Record<string, any>[]
	) => void;
	itemGroupName: string;
	modifierGroup: ModifierGroupApi | undefined;
	items: ItemApi[];
	itemsInGroup?: ItemApi[];
	errors?: Record<string, any>[];
	modifierGroupIndex: number | null;
}

const findPriceOverrides = (
	items: ItemModifierGroupApi[],
	item_id: number,
	modifier_group_id?: number
): ItemModifierGroupOptionApi | undefined => {
	const override = items
		.find((f) => f?.modifier_group_id === modifier_group_id)
		?.options?.find((f) => f.item_id === item_id);
	return override;
};

const findQuantityOverrides = (
	items: ItemModifierGroupApi[],
	item_id: number,
	modifier_group_id?: number
): ItemModifierGroupOptionApi | undefined => {
	const override = items
		.find((f) => f?.modifier_group_id === modifier_group_id)
		?.options?.find((f) => f.item_id === item_id);
	return override;
};

export const findItemQuantityOverrides = (
	items: ItemModifierGroupApi[],
	modifier_group_id?: number
): QuantityInfoApi | undefined => {
	return items.find((f) => f?.modifier_group_id === modifier_group_id)?.quantity_info;
};

const fillItemPriceOverrides = (
	options: ModifierGroupOptionApi[],
	items: ItemModifierGroupApi[],
	modifier_group_id?: number
) => {
	const newItems: ItemPriceOverrideApi[] = [];
	options?.forEach((opt) => {
		const price = findPriceOverrides(items, opt.item_id, modifier_group_id);
		if (price) {
			newItems.push({
				price: price.price,
				item_id: price.item_id.toString(),
			});
		}
	});
	return newItems;
};

const fillItemQuantityOverrides = (
	options: ModifierGroupOptionApi[],
	items: ItemModifierGroupApi[],
	modifier_group_id?: number
) => {
	const newItems: QuantityInfoOverrideApi[] = [];
	options?.forEach((opt) => {
		const quan = findQuantityOverrides(items, opt.item_id, modifier_group_id);
		if (quan) {
			newItems.push({
				quantity_info: quan.quantity_info,
				item_id: quan.item_id.toString(),
			});
		}
	});
	return newItems;
};

interface ItemPriceOverrideFormApi {
	price_overrides: ItemPriceOverrideApi[];
}

interface ItemQuantityOverrideFormApi {
	quantity_overrides: QuantityInfoOverrideApi[];
}

const CreateOrUpdateModifierGroupModalForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [itemVariants, setItemVariants] = useState(props.itemsInGroup ? props.itemsInGroup : props.items);
	const [modifier, setModifier] = useState(props.modifierGroup ? props.modifierGroup : ({} as ModifierGroupApi));
	const [addOptionsMode, setAddOptionsMode] = useState(false);
	const getMergedItemModifierGroups = props.items
		.map((x) => x.modifier_groups)
		.reduce((accumulator, value) => accumulator.concat(value), []);
	const [itemGroupQuantityInfoOverride] = useState<QuantityInfoApi | undefined>(
		findItemQuantityOverrides(getMergedItemModifierGroups, props.modifierGroup?.id)
	);
	const [mode, setMode] = useState<"MODIFIER_GROUP" | "ITEM_MODIFIER_GROUP">(
		props.modifierGroup?.id ? "ITEM_MODIFIER_GROUP" : "MODIFIER_GROUP"
	);
	const { addFlash } = useFlash();
	const [limitedVariants] = useState(itemVariants.length !== props.items.length);
	const form = useForm<ModifierGroupApi>({
		criteriaMode: "all",
		defaultValues: modifier,
		shouldUnregister: false,
	});
	const organization = useSelector(selectOrganization);
	const currency = organization?.currency || "";

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		getValues,
		formState: { errors },
		watch,
		control,
		clearErrors,
	} = form;
	const { replace, append, fields } = useFieldArray({
		control,
		name: "options",
		keyName: "key",
	});
	const quantityInfoOverridesForm = useForm<ItemQuantityOverrideFormApi>({
		criteriaMode: "all",
		defaultValues: {
			quantity_overrides: fillItemQuantityOverrides(
				modifier.options,
				getMergedItemModifierGroups,
				props.modifierGroup?.id
			),
		},
		shouldUnregister: false,
	});

	const {
		getValues: getQuantityValues,
		setValue: setQuantityValue,
		setError: setQuantityErrors,
	} = quantityInfoOverridesForm;

	const priceInfoOverridesForm = useForm<ItemPriceOverrideFormApi>({
		criteriaMode: "all",
		defaultValues: {
			price_overrides: fillItemPriceOverrides(
				modifier.options,
				getMergedItemModifierGroups,
				props.modifierGroup?.id
			),
		},
		shouldUnregister: false,
	});
	const {
		getValues: getPriceValues,
		setError: setPriceErrors,
		setValue: setItemPriceValues,
	} = priceInfoOverridesForm;

	const itemGroupQuantityOverrideForm = useForm<any>({
		criteriaMode: "all",
		defaultValues: itemGroupQuantityInfoOverride,
		shouldUnregister: false,
	});
	const {
		clearErrors: clearItemGroupQuantityErrors,
		control: itemGroupQuantityControl,
		getValues: getItemGroupQuantityValues,
		setError: setItemGroupQuantityOverrideErrors,
		formState: { errors: itemGroupOverrideErrors },
	} = itemGroupQuantityOverrideForm;

	const watchedOptions = watch("options");

	useEffect(() => {
		const optionErrors = props.errors?.map((err) => {
			return { ...err, field: err.field?.split("options")[1] };
		});
		if (props.errors) {
			addServerErrors(optionErrors ? optionErrors : [], setPriceErrors as any);
			addServerErrors(
				optionErrors
					? optionErrors.map((err) => ({
							...err,
							field: `quantity_overrides.${err.field}`,
					  }))
					: [],
				setQuantityErrors as any
			);
			addServerErrors(
				props.errors?.map((err) => {
					if (typeof err?.field === "string") {
						return { ...err, field: `quantity_info.${err.field.replace(/([^]*\.){2}/, "")}` };
					}
					return err;
				}),
				setItemGroupQuantityOverrideErrors
			);
		}
	}, [props.errors]);

	const onSubmit = handleSubmit(async (data: ModifierGroupApi) => {
		const quantityValues = getQuantityValues().quantity_overrides;
		const priceValues = getPriceValues().price_overrides;
		const itemGroupQuantityValues = getItemGroupQuantityValues().quantity_info;
		priceValues.forEach((val, index) => {
			if (!val.item_id) {
				val.item_id = data.options[index]?.item_id?.toString();
			}
		});
		quantityValues.forEach((val, index) => {
			if (!val.item_id) {
				val.item_id = data.options[index]?.item_id?.toString();
			}
		});
		setLoading(true);
		if (props.modifierGroup?.id) {
			data.id = props.modifierGroup.id;
		}
		try {
			const params: Record<string, any> = {
				include: "options,options.item,options.quantity_info,options.price,translations",
			};
			let res;
			if (data.id) {
				res = await api.organization().updateModifierGroup(data, params);
			} else {
				res = await api.organization().createModifierGroup(data, params);
			}
			props.handleSave(
				JSON.parse(JSON.stringify(res)),
				res.id,
				itemVariants,
				quantityValues,
				priceValues,
				itemGroupQuantityValues
			);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash, ["quantity_info"]);
		}
	});

	const handleRemoveOption = (index: number) => {
		const newFields = fields.filter((f, i) => index !== i);
		const priceValues = getPriceValues().price_overrides.filter((f, i) => index !== i);
		const quantityValues = getQuantityValues().quantity_overrides.filter((f, i) => index !== i);
		newFields.forEach((f, fieldIndex) => {
			f.position = fieldIndex;
		});
		replace(newFields);
		priceValues.forEach((val, priceValueIndex) => {
			setItemPriceValues(
				`price_overrides.${priceValueIndex}`,
				{
					item_id: val.item_id,
					price: {
						amount: val.price?.amount ? val.price?.amount : null,
						currency,
					},
				},
				{ shouldDirty: true }
			);
		});
		quantityValues.forEach((val, quantityValueIndex) => {
			setQuantityValue(
				`quantity_overrides.${quantityValueIndex}`,
				{
					item_id: val.item_id,
					quantity_info: val.quantity_info
						? {
								min_permitted: val.quantity_info?.min_permitted ? val.quantity_info.min_permitted : "",
								max_permitted: val.quantity_info?.max_permitted ? val.quantity_info.max_permitted : "",
								charge_above: val.quantity_info?.charge_above ? val.quantity_info.charge_above : "",
								default_quantity: val.quantity_info?.default_quantity
									? val.quantity_info.default_quantity
									: "",
						  }
						: {
								min_permitted: "",
								max_permitted: "",
								charge_above: "",
								default_quantity: "",
						  },
				},
				{ shouldDirty: true }
			);
		});
	};

	const onReplaceOptions = (data: ModifierGroupOptionApi[]) => {
		replace(data);
		const priceValues = getPriceValues().price_overrides;
		const quantityValues = getQuantityValues().quantity_overrides;
		const itemIds = data.map((x) => x.item_id.toString());
		const newPriceValues = priceValues.sort((a, b) => itemIds.indexOf(a.item_id) - itemIds.indexOf(b.item_id));
		const neqQuantityValues = quantityValues.sort(
			(a, b) => itemIds.indexOf(a.item_id) - itemIds.indexOf(b.item_id)
		);

		newPriceValues.forEach((val, index) => {
			setItemPriceValues(
				`price_overrides.${index}`,
				{
					item_id: val.item_id,
					price: {
						amount: val.price?.amount ? val.price?.amount : null,
						currency,
					},
				},
				{ shouldDirty: true }
			);
		});
		neqQuantityValues.forEach((val, index) => {
			setQuantityValue(
				`quantity_overrides.${index}`,
				{
					item_id: val.item_id,
					quantity_info: val.quantity_info
						? {
								min_permitted: val.quantity_info?.min_permitted ? val.quantity_info.min_permitted : "",
								max_permitted: val.quantity_info?.max_permitted ? val.quantity_info.max_permitted : "",
								charge_above: val.quantity_info?.charge_above ? val.quantity_info.charge_above : "",
								default_quantity: val.quantity_info?.default_quantity
									? val.quantity_info.default_quantity
									: "",
						  }
						: {
								min_permitted: "",
								max_permitted: "",
								charge_above: "",
								default_quantity: "",
						  },
				},
				{ shouldDirty: true }
			);
		});
	};

	const onAddModifierOptions = () => {
		setAddOptionsMode(true);
	};

	useEffect(() => {
		if (props.searchText) {
			setValue("name", props.searchText);
			setModifier({ ...modifier, name: props.searchText });
		}
	}, [props.searchText]);

	const addModifierOption = (option: ModifierGroupOptionApi) => {
		append({
			item_id: option.item_id,
			sub_item: option.sub_item,
			position: fields.length,
			price: {
				amount: null,
				currency,
			},
			quantity_info: {
				min_permitted: "",
				max_permitted: "",
				charge_above: "",
				default_quantity: "",
			},
		});
		setItemPriceValues(
			`price_overrides.${fields.length}`,
			{
				item_id: option.item_id.toString(),
				price: {
					amount: null,
					currency,
				},
			},
			{ shouldDirty: true }
		);
		setQuantityValue(
			`quantity_overrides.${fields.length}`,
			{
				item_id: option.item_id.toString(),
				quantity_info: {
					min_permitted: "",
					max_permitted: "",
					charge_above: "",
					default_quantity: "",
				},
			},
			{ shouldDirty: true }
		);
		setAddOptionsMode(false);
	};

	useEffect(() => {
		fields.forEach((option, index) => {
			const fieldPrefix = `options.${index}`;
			registerObject(register, fieldPrefix, [
				"item_id",
				"type",
				"position",
				"quantity_info_override",
				"sub_item",
			]);
			registerObject(register, `${fieldPrefix}.quantity_info`, [
				"charge_above",
				"min_permitted",
				"max_permitted",
				"default_quantity",
			]);
			registerObject(register, `${fieldPrefix}.sub_item`, ["name"]);
			if (!option.quantity_info) {
				option.quantity_info = {
					min_permitted: "",
					max_permitted: "",
					charge_above: "",
					default_quantity: "",
				};
			}
			if (!option.price) {
				option.price = { amount: null, currency };
			}
			setValue(`options.${index}`, option);
		});
	}, []);

	const getItemsOptions = (items: ItemApi[]) => {
		const x = items?.map((item) => {
			const obj = {
				name: item.name === props.itemGroupName || item.name === "" ? t("common.word.default") : item.name,
				label: item.name === props.itemGroupName || item.name === "" ? t("common.word.default") : item.name,
				id: item.id,
				value: item.id,
			};
			return obj;
		});
		return x;
	};

	const onChangeItemsOptions = (opts: any) => {
		if (opts.length === 0) {
			document.getElementById("limit_variants")?.click();
			setItemVariants([...props.items]);
		} else {
			setItemVariants([...opts]);
		}
	};

	const clearAllQuantityErrors = () => {
		watchedOptions.forEach((_, index) => {
			clearErrors([`options.${index}`]);
		});
		clearErrors("quantity_info");
	};

	const errorsForModifierGroup = (props.errors || []).filter((error) =>
		error.field?.includes(`modifier_groups[${props.modifierGroupIndex}]`)
	);

	const getParsedErrors = (rawErrors: Record<string, any>, partToReplace?: string, replaceWith?: string) => {
		const errors = rawErrors;
		if (!Object.keys(errors).length) return {};
		const parsedErrors: Record<string, any> = {};
		errorsForModifierGroup.forEach((raw) => {
			const error = { ...raw };
			const errorStart = `modifier_groups[${props.modifierGroupIndex}]`;
			if (partToReplace && replaceWith) {
				error.field = error.field.replace(partToReplace, replaceWith);
			}
			const startIndex = error.field.indexOf(errorStart);
			const errorFieldParsedString = error.field
				.substring(startIndex + errorStart.length + 1)
				.replaceAll("[", ".")
				.replaceAll("]", "");
			const errorLevels = errorFieldParsedString.split(".");
			const ifErrorExist = errors[errorLevels[0]];
			let rawError = {};
			let refObj: Record<string, any> = {};
			let skip = !ifErrorExist;
			errorLevels.forEach((levelKey: string, index: number) => {
				if (!skip) {
					rawError = rawError[levelKey as keyof typeof rawError] || errors[levelKey] || null;

					if (!rawError) {
						skip = true;
					}

					if (index === 0) {
						parsedErrors[levelKey] = { ...(parsedErrors[levelKey] || {}) };
						refObj = parsedErrors[levelKey];
					} else {
						refObj[levelKey] = rawError;
						refObj = refObj[levelKey];
					}
				}
			});
		});
		return parsedErrors;
	};

	return (
		<>
			<Form key="modifier-modal-form" onSubmit={onSubmit}>
				{addOptionsMode ? (
					<SearchItemsModal
						selectedValues={[
							...props.items.filter((item) => item.id >= 1).map((x) => x.id),
							...fields.map((x) => x.item_id),
						]}
						handleBack={() => setAddOptionsMode(false)}
						modifierGroup={{ ...modifier, name: modifier.name ? modifier.name : getValues("name") }}
						handleAddModifierOption={(option) => addModifierOption(option)}
					/>
				) : (
					<>
						<Modal.Header closeButton>
							<Modal.Title>
								{props.modifierGroup?.id ? (
									<>
										{t("modules.modifier_group.field.edition.title")} {props.modifierGroup?.name}
									</>
								) : (
									<>
										<BackSVG className="icon" onClick={() => props.handleCancel()} />{" "}
										{`${t("modules.modifier_group.field.assign_modifier_group_to.title")} ${
											props.itemGroupName
										}`}
									</>
								)}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className={"pb-1"}>
							{modifier.id && (
								<Form.Group className="form-group">
									<Button
										onClick={() => setMode("ITEM_MODIFIER_GROUP")}
										variant={mode === "ITEM_MODIFIER_GROUP" ? "outline-primary" : "default"}
									>
										{t("common.word.inside")} {props.itemGroupName}
									</Button>
									<Button
										onClick={() => setMode("MODIFIER_GROUP")}
										variant={mode === "MODIFIER_GROUP" ? "outline-primary" : "default"}
									>
										{t("common.word.default_plural")}
									</Button>
								</Form.Group>
							)}
							{mode === "MODIFIER_GROUP" && (
								<>
									<FormInput
										label={`${t("lib:common.word.name")} ${t("common.word.modifier_group")}`}
										register={register}
										defaultValue={props.searchText}
										name="name"
										errors={errors}
									/>
									<div className="row">
										<div className="col-md-4">
											<FormNumberInput
												label={t("modules.modifier_group.field.min_permitted.title")}
												control={control}
												decimalScale={0}
												suffix={""}
												name="quantity_info.min_permitted"
												defaultValue={modifier.quantity_info?.min_permitted}
												errors={errors}
												onChange={() => clearAllQuantityErrors()}
												maxPermittedAmountOfDigits={3}
											/>
										</div>
										<div className="col-md-4">
											<FormNumberInput
												label={t("modules.modifier_group.field.max_permitted.title")}
												control={control}
												decimalScale={0}
												suffix={""}
												name="quantity_info.max_permitted"
												onChange={() => clearAllQuantityErrors()}
												defaultValue={modifier.quantity_info?.max_permitted}
												errors={errors}
												maxPermittedAmountOfDigits={3}
											/>
										</div>
										<div className="col-md-4">
											<FormNumberInput
												label={t("modules.modifier_group.field.charge_above.title")}
												control={control}
												decimalScale={0}
												suffix={""}
												name="quantity_info.charge_above"
												defaultValue={modifier.quantity_info?.charge_above}
												errors={errors}
												maxPermittedAmountOfDigits={3}
											/>
										</div>
									</div>
								</>
							)}
							{mode === "ITEM_MODIFIER_GROUP" && (
								<>
									<div className="row">
										<div className="col-md-4">
											<FormNumberInput
												valuePlaceholder={`${
													!checkIfValueIsEmpty(getValues().quantity_info?.min_permitted)
														? `${t("common.word.default_none")}: ${
																getValues().quantity_info?.min_permitted
														  }`
														: ""
												}`}
												placeholder={`${
													!checkIfValueIsEmpty(getValues().quantity_info?.min_permitted)
														? `${t("common.word.default_none")}: ${
																getValues().quantity_info?.min_permitted
														  }`
														: ""
												}`}
												label={`${t("modules.modifier_group.field.min_permitted.title")}`}
												name="quantity_info.min_permitted"
												control={itemGroupQuantityControl}
												decimalScale={0}
												suffix={""}
												onChange={() =>
													clearItemGroupQuantityErrors("quantity_info.min_permitted")
												}
												defaultValue={itemGroupQuantityInfoOverride?.min_permitted}
												errors={getParsedErrors(itemGroupOverrideErrors)}
												maxPermittedAmountOfDigits={3}
											/>
										</div>
										<div className="col-md-4">
											<FormNumberInput
												valuePlaceholder={`${
													!checkIfValueIsEmpty(getValues().quantity_info?.max_permitted)
														? `${t("common.word.default_none")}: ${
																getValues().quantity_info?.max_permitted
														  }`
														: ""
												}`}
												placeholder={`${
													!checkIfValueIsEmpty(getValues().quantity_info?.max_permitted)
														? `${t("common.word.default_none")}: ${
																getValues().quantity_info?.max_permitted
														  }`
														: ""
												}`}
												label={`${t("modules.modifier_group.field.max_permitted.title")}`}
												defaultValue={itemGroupQuantityInfoOverride?.max_permitted}
												control={itemGroupQuantityControl}
												decimalScale={0}
												suffix={""}
												onChange={() =>
													clearItemGroupQuantityErrors("quantity_info.max_permitted")
												}
												name="quantity_info.max_permitted"
												errors={getParsedErrors(itemGroupOverrideErrors)}
												maxPermittedAmountOfDigits={3}
											/>
										</div>
										<div className="col-md-4">
											<FormNumberInput
												valuePlaceholder={`${
													!checkIfValueIsEmpty(getValues().quantity_info?.charge_above)
														? `${t("common.word.default_none")}: ${
																getValues().quantity_info?.charge_above
														  }`
														: ""
												}`}
												placeholder={`${
													!checkIfValueIsEmpty(getValues().quantity_info?.charge_above)
														? `${t("common.word.default_none")}: ${
																getValues().quantity_info?.charge_above
														  }`
														: ""
												}`}
												label={`${t("modules.modifier_group.field.charge_above.title")}`}
												defaultValue={itemGroupQuantityInfoOverride?.charge_above}
												onChange={() =>
													clearItemGroupQuantityErrors("quantity_info.charge_above")
												}
												control={itemGroupQuantityControl}
												decimalScale={0}
												suffix={""}
												name="quantity_info.charge_above"
												errors={getParsedErrors(itemGroupOverrideErrors)}
												maxPermittedAmountOfDigits={3}
											/>
										</div>
									</div>
								</>
							)}
							<h5 className={"mb-3"} style={{ marginTop: "8px" }}>
								{t("modules.modifier_group.header.modifiers.title")}
							</h5>
							{watchedOptions?.length > 0 ? (
								<>
									{mode === "MODIFIER_GROUP" && (
										<ModifierGroupOptionsTable
											fields={fields}
											handleReplace={onReplaceOptions}
											onAddModifierOptions={onAddModifierOptions}
											handleRemoveOption={handleRemoveOption}
											form={form}
										/>
									)}
									{mode === "ITEM_MODIFIER_GROUP" && (
										<ItemModifierGroupOptionsTable
											fields={fields}
											handleReplace={onReplaceOptions}
											onAddModifierOptions={onAddModifierOptions}
											handleRemoveOption={handleRemoveOption}
											quantityInfoOverridesForm={quantityInfoOverridesForm}
											priceInfoOverridesForm={priceInfoOverridesForm}
											form={form}
											getParsedErrors={getParsedErrors}
										/>
									)}
								</>
							) : (
								<EmptyData
									title={t("modules.modifier_group.field.no_modifier_added_yet.title")}
									styles={{
										background: "#F5F8FA",
										marginBottom: "24px",
									}}
									description={`${t("modules.modifier_group.action.press_button.title")} "+ ${t(
										"lib:common.action.add"
									)}" ${t("modules.modifier_group.field.and_create_new_modifier.title")}`}
									actions={[
										{
											name: `+ ${t("lib:common.action.add")}`,
											variant: "primary",
											click: () => {
												onAddModifierOptions();
											},
										},
									]}
								/>
							)}
							{mode === "MODIFIER_GROUP" && (
								<>
									<h5 className={"mb-3"}>{t("common.word.advanced", { ns: "lib" })}</h5>
									<LimitComponentVisibility
										defaultVisible={modifier.split}
										id={"modifier_split"}
										helpText={t("modules.modifier_group.field.split.helptext.description")}
										legendText={t("modules.modifier_group.field.split.title")}
										handleChange={(visible) => {
											setValue("split", visible ? visible : false);
										}}
										unlimitedContent={
											<FormCheck
												name="equal_pricing"
												errors={errors}
												register={register}
												type="switch"
												label={t(
													"modules.modifier_group.action.calculate_price_based_on_most_expensive_element.title"
												)}
											/>
										}
									/>
								</>
							)}
							{mode === "ITEM_MODIFIER_GROUP" && (
								<>
									<h5 className={"mb-3"}>
										{t("modules.modifier_group.field.item_modifier_group.title")}
									</h5>
									<LimitComponentVisibility
										defaultVisible={limitedVariants}
										id={"limit_variants"}
										helpText={t("modules.modifier_group.field.limit_variants.helptext.description")}
										legendText={t("modules.modifier_group.field.limit_variants.title")}
										handleChange={(visible) => {
											if (!visible) setItemVariants([...props.items]);
										}}
										unlimitedContent={
											<Select
												defaultValue={getItemsOptions(itemVariants)}
												isMulti
												name="variants"
												placeholder={`${t("lib:common.action.select")} ...`}
												options={getItemsOptions(props.items)}
												onChange={onChangeItemsOptions}
												className="basic-multi-select"
												classNamePrefix="select"
												noOptionsMessage={({ inputValue }) =>
													!inputValue
														? t("modules.modifier_group.field.no_options.title")
														: ""
												}
											/>
										}
									/>
								</>
							)}
						</Modal.Body>
						<Modal.Footer>
							<ButtonLoading variant="primary" type="submit" loading={loading} onClick={onSubmit}>
								{t("common.action.save", { ns: "lib" })}
							</ButtonLoading>
							<Button variant="light" onClick={props.handleClose}>
								{t("common.action.cancel", { ns: "lib" })}
							</Button>
						</Modal.Footer>
					</>
				)}
			</Form>
		</>
	);
};
export default CreateOrUpdateModifierGroupModalForm;
