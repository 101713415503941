import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ItemApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import DepartmentReportModal from "../../components/DepartmentReportModal";
import { RebuildReportModal } from "../../components/RebuildReportModal";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "NOT_SOLD_ITEMS";
const resourceType = "NOT_SOLD_ITEMS";

const List: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<ItemApi[]>([]);
	const segmentContext = useContext(SegmentContext);
	const [showDepartmentReportModal, setShowDepartmentReportModal] = useState(false);
	const [showRebuildReportModal, setShowRebuildReportModal] = useState(false);
	const mobileActions: MobileActionProps[] = [
		{
			title: t("modules.report.action.rebuild.title"),
			action: () => setShowRebuildReportModal(true),
		},
		{
			title: t("modules.report.field.department_report.title"),
			action: () => setShowDepartmentReportModal(true),
		},
	];

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "item_group",
				name: t("common.word.item_group"),
				render: (item: ItemApi) => (
					<ListImageName
						data={{ ...item, name: item.name }}
						imageLink={item.item_group?.image_link}
						link={item?.item_group?.id ? `/${organization.id}/menu/item_groups/${item.item_group.id}` : ""}
						shouldOpenInNewTab
					/>
				),
				renderExport: (item: ItemApi) => item.item_group?.name,
				disableSorting: true,
				styleOverride: {
					textAlign: "left",
				},
			},
		],
		filters: [
			{
				id: "not_sold_at",
				name: t("lib:common.word.date"),
				type: "date",
				extraOptions: ["a"],
				isRemovable: false,
			},
			{
				id: "category",
				name: t("common.word.category"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getCategoriesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
		],
		selectedColumns: ["item_group"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				filters: [
					{
						filterId: "not_sold_at",
						condition: "a",
						filterType: "date",
						value: "a",
					},
				],
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.unsold_products_report.header.title"),
			filename: t("modules.unsold_products_report.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: async (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "item_group";
			return api.organization().getItems(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			<DepartmentReportModal
				show={showDepartmentReportModal}
				onHide={() => setShowDepartmentReportModal(false)}
			/>
			<RebuildReportModal show={showRebuildReportModal} onHide={() => setShowRebuildReportModal(false)} />
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.unsold_products_report.header.title")}
						description={t("modules.unsold_products_report.field.empty_list_description.title")}
					/>
				}
				onFetch={setItems}
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationReportsUnsoldProductsPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<List resource={resource} />
		</Suspense>
	);
};

export default OrganizationReportsUnsoldProductsPage;
