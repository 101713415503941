import { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { apiSecurity } from "go-security/services/Api/api";
import { RemoveOrganizationSecurityApi } from "go-security/services/Api/typesSecurity";
import { useAuth } from "go-security/services/auth";
import { selectOrganization } from "go-security/services/organizations/selectors";
import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";

interface Props {
	baseUrl: string;
}

const SecurityRemoveOrganizationFormComponent = ({ baseUrl }: Props): JSX.Element => {
	const form = useForm<RemoveOrganizationSecurityApi>({
		criteriaMode: "all",
		defaultValues: {} as RemoveOrganizationSecurityApi,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		formState,
		reset,
		watch,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const history = useHistory();
	const auth = useAuth(baseUrl);
	const organization = useSelector(selectOrganization);
	const [showRemoveOrganizationConfirmationModal, setShowRemoveOrganizationConfirmationModal] =
		useState<boolean>(false);

	const onGenerateDeleteOrganizationToken = async () => {
		try {
			setShowRemoveOrganizationConfirmationModal(true);
			await apiSecurity.organization().generateRemoveOrganizationToken();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onSubmit = handleSubmit((data: RemoveOrganizationSecurityApi) => {
		if (organization.id === undefined) {
			return;
		}
		setLoading(true);
		apiSecurity
			.organization()
			.removeOrganization(data)
			.then(() => {
				setLoading(false);
				setShowRemoveOrganizationConfirmationModal(false);
				reset(data);
				auth.checkLogin();
				addSuccessFlash(t("lib:common.flash.removed"));
				history.push("/");
			})
			.catch((e: Error) => {
				handleError.form(e, setError, addFlash);
				setLoading(false);
			});
	});

	return (
		<>
			<FormDirty formState={formState} key="profile-form" noValidate>
				<h5>{t("lib:go_component.remove_organization.form.title")}</h5>
				<div className="row">
					<div className={"col-md-8"}>
						<FormInput
							label={t("lib:go_component.remove_organization.form.reason.label")}
							placeholder={t("lib:go_component.remove_organization.form.reason.placeholder")}
							help={t("lib:go_component.remove_organization.form.reason.help")}
							register={register}
							type="text"
							name="reason"
							errors={errors}
						/>
					</div>
				</div>
				<Button
					onClick={() => onGenerateDeleteOrganizationToken()}
					variant="danger"
					disabled={!watch("reason")}
				>
					{t("lib:common.action.remove")}
				</Button>
			</FormDirty>
			{showRemoveOrganizationConfirmationModal && (
				<RemoveOrganizationConfirmationModal
					isShown={showRemoveOrganizationConfirmationModal}
					onHide={() => setShowRemoveOrganizationConfirmationModal(false)}
					loading={loading}
					handleRemove={onSubmit}
					form={form}
				/>
			)}
		</>
	);
};

export default SecurityRemoveOrganizationFormComponent;
