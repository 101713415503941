import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import SubmenuPriceListsNavigation from "../../../../components/SubmenuPriceListsNavigation";
import SpreadsheetPriceListVirtualTableForm from "./components/SpreadsheetPriceListVirtualTableForm";

const OrganizationMenuPriceListsGroupEditingSpreadsheetIndexPage: FC<RouteComponentProps> = () => {
	const { t } = useTranslation();
	const [items, setItems] = useState<MenuPriceListFlatApi[] | undefined>(undefined);
	const [priceLists, setPriceLists] = useState<MenuPriceListApi[] | undefined>(undefined);
	const { addFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.group_edit.field.browser_tab.title"));
		onFetch();
	}, []);

	const onFetch = async () => {
		try {
			const params: Record<string, any> = { page: "0", size: "0" };
			const res = await api.organization().getFlatPriceLists(params);
			setItems(
				res.items.map((item: MenuPriceListFlatApi, index: number) => ({
					...item,
					item_full_name: item.item_name,
					item_name:
						item.item_name !== item.item_group_name
							? item.item_name.replace(item.item_group_name, "").trim()
							: t("common.word.default"),
					index,
				}))
			);
			setPriceLists(
				res.price_lists.map((item: MenuPriceListFlatApi, index: number) => ({
					...item,
					index,
				}))
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<>
			{!isMobile && (
				<div className="virtual-table-wrapper-container-header">
					<h1>{t("modules.price_list.header.title")}</h1>
				</div>
			)}
			<SubmenuPriceListsNavigation />
			{!items || !priceLists ? (
				<LoadingContainer />
			) : (
				<SpreadsheetPriceListVirtualTableForm
					items={items}
					priceLists={priceLists}
					handleUpdate={(res) => setItems([...res])}
				/>
			)}
		</>
	);
};

export default OrganizationMenuPriceListsGroupEditingSpreadsheetIndexPage;
