import { useCallback, useMemo } from "react";
import { ChartData, ChartDataset } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { ReportApi } from "go-report/core/services/types";
import { generateLabelValues, generateLabels, getDataSet } from "../../services/charts/chartService";
import { ChartDataRangeType } from "../../services/charts/types";
import { DashboardReportType } from "../../services/types";
import { getAggregateName } from "../../utils/dashboardRenderUtils";
import { useDashboardReportAggregate } from "../../utils/useDashboardReportAggregate";

interface Props {
	data: ReportApi;
	chartDataRangeType: ChartDataRangeType;
	selectedFilters: ListSelectedFilter[];
	reportType: DashboardReportType;
}

const VerticalBar = (props: Props): JSX.Element => {
	const { data, chartDataRangeType, selectedFilters, reportType } = props;
	const { reportAggregateType, getSubReportFilterCondition, sortSubReports, getAggregateValue } =
		useDashboardReportAggregate(reportType);
	const { t } = useTranslation();
	const colors = useMemo(() => ["#1A86E0", "#8CC7F9", "#C1E2FD"], []);

	const generateChartData = useCallback(() => {
		const dataSets: ChartDataset<"bar", number[]>[] = [];
		data.reports[0].sub_report
			.filter(getSubReportFilterCondition())
			.sort(sortSubReports())
			.forEach((report, index) => {
				const labelValues = getDataSet(
					report.sub_report,
					reportAggregateType,
					chartDataRangeType,
					selectedFilters
				);
				dataSets.push({
					label: report.group_by_value.name,
					data: labelValues,
					backgroundColor: colors[index],
					barPercentage: 0.6,
				});
			});
		return dataSets;
	}, [data, reportAggregateType]);

	const chartConfig = useMemo(
		() => ({
			responsive: true,
			scales: {
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
			},
		}),
		[]
	);

	const renderCircle = (color: string) => {
		return (
			<svg className={"me-1"} height="10" width="10">
				<circle cx="5" cy="5" r="4" fill={color} />
			</svg>
		);
	};

	const renderChart = useMemo(() => {
		const chartData = {
			labels: generateLabelValues(generateLabels(chartDataRangeType, selectedFilters), chartDataRangeType),
			datasets: generateChartData(),
		};
		return <Bar options={chartConfig} data={chartData} />;
	}, [data, chartConfig, reportAggregateType]);

	const renderLegend = useMemo(() => {
		return data.reports[0].sub_report.map((report, index) => {
			const aggregateName = getAggregateName(report, t);

			return (
				<div key={aggregateName} className={`vertical-legend-item ${!index && "mt-3"}`}>
					<div>
						{renderCircle(colors[index])}
						{aggregateName}
					</div>
					<div className={"ms-auto"}>{getAggregateValue(report, true)}</div>
				</div>
			);
		});
	}, [data, colors, t, reportAggregateType]);

	return (
		<>
			{renderChart}
			{renderLegend}
		</>
	);
};
export default VerticalBar;
