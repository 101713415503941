import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationSettingsCustomFieldsEditPage from "./pages/Edit";
import OrganizationSettingsCustomFieldsIndexPage from "./pages/Index";
import OrganizationSettingsCustomFieldsNewPage from "./pages/New";

const OrganizationSettingsCustomFieldsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route
					path={`${props.match.url}/`}
					exact={true}
					component={OrganizationSettingsCustomFieldsIndexPage}
				/>
				<Route
					path={`${props.match.url}/new`}
					exact={true}
					component={OrganizationSettingsCustomFieldsNewPage}
				/>
				<Route
					path={`${props.match.url}/:customFieldId`}
					component={OrganizationSettingsCustomFieldsEditPage}
				/>
			</Switch>
		</div>
	);
};
export default OrganizationSettingsCustomFieldsPage;
