import React from "react";
import { useTranslation } from "react-i18next";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";

const SecurityProfileNavigation = (): JSX.Element => {
	const { t } = useTranslation();

	const items: SubNavigationItemProps[] = [
		{
			title: t("lib:go_component.profile.basic_data.header.title"),
			route: "/profile/details",
		},
		{
			title: t("lib:go_component.profile.change_password.header.title"),
			route: "/profile/change_password",
		},
		{
			title: t("lib:go_component.profile.remove_account.header.title"),
			route: "/profile/remove_account",
		},
	];

	return <SubNavigationItems title={t("lib:go_component.profile.header.title")} items={items} />;
};

export default SecurityProfileNavigation;
