import React from "react";
import { ReactComponent as SortDownSVG } from "../../../../../../../../images/svg/virtualTable/sort-down.svg";
import { ReactComponent as SortUpSVG } from "../../../../../../../../images/svg/virtualTable/sort-up.svg";
import { ReactComponent as SortSVG } from "../../../../../../../../images/svg/virtualTable/sort.svg";
import { VirtualTableSortingModeEnum } from "./types";

interface Props {
	sortingMode: VirtualTableSortingModeEnum;
}

const VirtualTableSort = ({ sortingMode }: Props) => {
	const drawSortIconBasedOnSortingMode = () => {
		switch (sortingMode) {
			case VirtualTableSortingModeEnum.ASC:
				return <SortUpSVG className="ms-auto" />;
			case VirtualTableSortingModeEnum.DESC:
				return <SortDownSVG className="ms-auto" />;
			case VirtualTableSortingModeEnum.EMPTY:
				return <SortSVG className="ms-auto" />;
		}
	};

	return <>{drawSortIconBasedOnSortingMode()}</>;
};

export default VirtualTableSort;
