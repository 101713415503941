import React, { useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import {
	OrderPreparationStatusName,
	VenueSettingsApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import SettingsTable from "./SettingsTable";

interface SupportedPreperationStatusesSettingProps {
	form: UseFormReturn<VenueSettingsApi>;
}

const SupportedPreperationStatusesSetting = ({
	form: {
		formState: { errors },
		control,
		getValues,
	},
}: SupportedPreperationStatusesSettingProps) => {
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const options = [
		{
			label: t("enums.preparation_statuses.NEW"),
			value: OrderPreparationStatusName.NEW,
		},
		{
			label: t("enums.preparation_statuses.IN_PROGRESS"),
			value: OrderPreparationStatusName.IN_PROGRESS,
		},
		{
			label: t("enums.preparation_statuses.READY"),
			value: OrderPreparationStatusName.READY,
		},
		{
			label: t("enums.preparation_statuses.PACKED"),
			value: OrderPreparationStatusName.PACKED,
		},
		{
			label: t("enums.preparation_statuses.RECEIVED"),
			value: OrderPreparationStatusName.RECEIVED,
		},
		{
			label: t("enums.preparation_statuses.DELIVERED"),
			value: OrderPreparationStatusName.DELIVERED,
		},
	];
	const defaultValues = getValues()
		.supported_preparation_statuses.default_values.map(
			(value) => options.find((option) => option.value === value)?.label || value
		)
		.join(", ");

	return (
		<tr>
			<td style={{ minWidth: isMobile ? "200px" : "unset" }} className="w-70">
				<div className="d-flex flex-column">
					<span>{t("modules.venue_settings.field.supported_preparation_statuses.title")}</span>
				</div>
			</td>
			<td style={{ minWidth: isMobile ? "200px" : "unset" }}>
				<span className="venue-default-setting">{defaultValues}</span>
			</td>
			<td style={{ minWidth: isMobile ? "300px" : "unset" }} className="supported-preparation-statuses">
				<FormSelectGroup
					name="supported_preparation_statuses.values"
					isClearable={true}
					options={options}
					errors={errors}
					control={control}
					placeholder={t("common.word.default")}
					isMulti
					data-testid="supported_preparation_statuses.values"
				/>
			</td>
		</tr>
	);
};

interface PreparationStatusesCardProps {
	form: UseFormReturn<VenueSettingsApi>;
	title: string;
	types: Record<string, any>[];
	allSettingsOptions: Record<string, any>[];
	search: string;
	fields: FieldArrayWithId<VenueSettingsApi, "kds_settings", "key">[];
}

const PreparationStatusesCard = (props: PreparationStatusesCardProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		register,
		getValues,
		setValue,
	} = props.form;

	const [collapseIn, setCollapseIn] = useState(false);
	const statusIndex = props.allSettingsOptions.findIndex((f) => f.id === "SALE_STATUS_PREPERATION_DEFAULT");
	const kdsMergeItemsIndex = props.allSettingsOptions.findIndex((f) => f.id === "KDS_MERGE_ITEMS");
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		const defaultStatus = getValues().settings.find((f) => f.type === "SALE_STATUS_PREPERATION_DEFAULT");
		const kdsMergeItems = getValues().settings.find((f) => f.type === "KDS_MERGE_ITEMS");
		if (defaultStatus?.value === "empty") {
			setValue(`settings.${statusIndex}.value`, defaultStatus.default_value);
		}
		if (kdsMergeItems?.value === "empty") {
			setValue(`settings.${kdsMergeItemsIndex}.value`, kdsMergeItems.default_value);
		}
	}, []);

	const onCollapse = () => {
		const collapse = collapseIn;
		setCollapseIn(!collapse);
		const icon = document.getElementById("preparation-icon");
		if (icon) {
			icon.style.transform = !collapse ? "rotate(180deg)" : "";
		}
	};

	useEffect(() => {
		if (props.search.length > 0 && !collapseIn && props.types.length > 0) {
			onCollapse();
		}
		if (props.types.length === 0 && collapseIn) {
			onCollapse();
		}
	}, [props.search]);

	return (
		<Card className={"venue-settings mb-4"}>
			<Card.Body>
				<div className={"title"} onClick={() => onCollapse()}>
					<h5>{props.title}</h5>
					<CollapseSVG id={"preparation-icon"} className={"collapse-icon"} />
				</div>
				<small className={"text-muted"} style={{ cursor: "pointer" }} onClick={() => onCollapse()}>
					{t("modules.venue_settings.field.statuses.helptext.description")}
				</small>
				<Collapse in={collapseIn}>
					<div>
						{props.fields.length > 0 && (
							<table className={"table table-preview"}>
								<thead>
									<tr>
										<th />
									</tr>
								</thead>
								<tbody>
									{props.fields.map((field, index) => {
										return (
											<tr key={`statuses_${props.fields[index].key}`}>
												<td style={{ paddingTop: "14px", paddingBottom: "14px" }}>
													<FormCheck
														className={"float-checkbox-left"}
														label={field.direction.name}
														defaultChecked={field.track_preparation_status}
														type="switch"
														register={register}
														name={`kds_settings.${index}.track_preparation_status`}
														errors={errors}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
						<div className={`${isMobile ? "table-responsive" : ""}`}>
							<table className={"table table-preview"}>
								<SettingsTable
									types={props.types}
									form={props.form}
									AdditionalSettings={<SupportedPreperationStatusesSetting form={props.form} />}
								/>
							</table>
						</div>
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};
export default PreparationStatusesCard;
