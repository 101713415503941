import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

const SubmenuRoomsNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	return (
		<div className="content-navigation">
			<Nav>
				<Nav.Item>
					<Nav.Link as={NavLink} exact to={`/${organization.id}/settings/rooms`}>
						{t("modules.room.field.rooms.title")}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link as={NavLink} to={`/${organization.id}/settings/rooms/point-of-sales`}>
						{t("common.word.points_of_sale")}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link as={NavLink} to={`/${organization.id}/settings/rooms/directions`}>
						{t("common.word.directions")}
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</div>
	);
};
export default SubmenuRoomsNavigation;
