import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormInput } from "go-form";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { CustomFieldEntityApi, CustomFieldTemplateApi } from "go-segment/services/types";
import CustomFieldDescriptionModal from "./CustomFieldDescriptionModal";
import CustomFieldShowDescriptionButton from "./CustomFieldShowDescriptionButton";

interface Props {
	form: UseFormReturn<any>;
	customFieldsConfig?: CustomFieldTemplateApi[];
	customFields: CustomFieldEntityApi[];
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
}

const CustomFieldsForm = (props: Props): JSX.Element => {
	const {
		formState: { errors },
		control,
		register,
		getValues,
		setValue,
		watch,
	} = props.form;
	const [customFieldDescriptionToShow, setCustomFieldDescriptionToShow] = useState<string | undefined>();
	const [refs, setRefs] = useState<(HTMLElement | null)[]>([]);

	const standardInputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors: props.setErrors,
			utils: { watch },
		}),
		[props.setErrors, watch]
	) satisfies CustomValidationConfig;

	const selectValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors: props.setErrors,
		}),
		[props.setErrors]
	) satisfies CustomValidationConfig;

	const numberInputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors: props.setErrors,
		}),
		[props.setErrors]
	) satisfies CustomValidationConfig;

	const datePickerValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors: props.setErrors,
		}),
		[props.setErrors]
	) satisfies CustomValidationConfig;

	useEffect(() => {
		props.customFieldsConfig?.forEach((customField, index) => {
			const isRequired = checkIfCustomFieldIsRequired(customField);
			const currentValue = getValues(`custom_fields.${index}.value`);
			if (customField.options?.length && customField.options.length === 1 && isRequired && !currentValue) {
				setValue(`custom_fields.${index}.value`, customField.options[0].value, { shouldDirty: true });
			}
		});
	}, []);

	useLayoutEffect(() => {
		const customFieldsElements = (props.customFieldsConfig || []).map((cf) => {
			const element = document.getElementById(cf.slug);

			if (cf.description && element) {
				const firstChild = element.children[0];
				const lastChildOfDiv = firstChild?.lastChild as HTMLElement;

				if (lastChildOfDiv && lastChildOfDiv.tagName === "SMALL") {
					if (lastChildOfDiv.offsetHeight >= lastChildOfDiv.scrollHeight) {
						lastChildOfDiv.onclick = () => setCustomFieldDescriptionToShow(cf.description);
						lastChildOfDiv.style.cursor = "pointer";
					}
				}
			}

			return element;
		});
		setRefs([...customFieldsElements]);
	}, []);

	const checkIfCustomFieldIsRequired = (customField: CustomFieldTemplateApi) => {
		return customField?.constraints?.find((constraint) => constraint?.type === "NOTNULL");
	};

	const checkIfCustomFieldHasLongDescription = (index: number) => {
		const customFieldRef = refs[index];
		if (!customFieldRef) return false;

		const firstChild = customFieldRef.children[0] as HTMLElement;
		if (!firstChild) return false;

		const lastChildOfDiv = firstChild.lastChild as HTMLElement;

		if (lastChildOfDiv && lastChildOfDiv.tagName === "SMALL") {
			return lastChildOfDiv.offsetHeight < lastChildOfDiv.scrollHeight;
		}

		return false;
	};

	const renderCustomField = (customField: CustomFieldTemplateApi, index: number) => {
		const isRequired = checkIfCustomFieldIsRequired(customField);
		const shouldRenderMoreButton = checkIfCustomFieldHasLongDescription(index);

		if (customField.options?.length > 0) {
			const options = customField.options.map((x) => {
				return {
					value: x.value,
					label: x.value,
				};
			});

			const customFieldEntity = props.customFields?.find((cf) => cf.slug === customField.slug);
			const defaultValue = customFieldEntity?.value
				? {
						id: customFieldEntity?.value,
						label: customFieldEntity?.value,
				  }
				: undefined;

			return (
				<>
					<FormSelectGroup
						label={customField.name}
						name={`custom_fields.${index}.value`}
						errors={errors}
						isClearable={true}
						options={options}
						control={control}
						defaultValue={defaultValue}
						customValidationConfig={isRequired ? selectValidationConfig : undefined}
						data-testid={`custom_fields.${index}.value`}
						help={customField.description}
					/>
					{shouldRenderMoreButton && (
						<CustomFieldShowDescriptionButton
							handleClick={() => setCustomFieldDescriptionToShow(customField.description)}
						/>
					)}
				</>
			);
		}
		switch (customField.type) {
			case "TEXT":
				return (
					<>
						<FormInput
							label={customField.name}
							register={register}
							name={`custom_fields.${index}.value`}
							errors={errors}
							defaultValue={
								getValues().custom_fields?.find(
									(f: CustomFieldEntityApi) => f?.slug && f.slug === customField?.slug
								)?.value
							}
							customValidationConfig={isRequired ? standardInputValidationConfig : undefined}
							help={customField.description}
						/>
						{shouldRenderMoreButton && (
							<CustomFieldShowDescriptionButton
								handleClick={() => setCustomFieldDescriptionToShow(customField.description)}
							/>
						)}
					</>
				);
			case "NUMBER":
				return (
					<>
						<FormNumberInput
							label={customField.name}
							suffix={""}
							defaultValue={
								getValues().custom_fields?.find(
									(f: CustomFieldEntityApi) => f?.slug && f.slug === customField?.slug
								)?.value
							}
							control={control}
							name={`custom_fields.${index}.value`}
							errors={errors}
							customValidationConfig={isRequired ? numberInputValidationConfig : undefined}
							help={customField.description}
						/>
						{shouldRenderMoreButton && (
							<CustomFieldShowDescriptionButton
								handleClick={() => setCustomFieldDescriptionToShow(customField.description)}
							/>
						)}
					</>
				);
			case "DATE":
				return (
					<>
						<FormDatePicker
							label={customField.name}
							control={control}
							name={`custom_fields.${index}.value`}
							errors={errors}
							customValidationConfig={isRequired ? datePickerValidationConfig : undefined}
							help={customField.description}
						/>
						{shouldRenderMoreButton && (
							<CustomFieldShowDescriptionButton
								handleClick={() => setCustomFieldDescriptionToShow(customField.description)}
							/>
						)}
					</>
				);
			case "LONG_TEXT":
				return (
					<>
						<FormInput
							label={customField.name}
							register={register}
							as="textarea"
							name={`custom_fields.${index}.value`}
							errors={errors}
							style={{ minHeight: "4rem" }}
							customValidationConfig={isRequired ? standardInputValidationConfig : undefined}
							help={customField.description}
						/>
						{shouldRenderMoreButton && (
							<CustomFieldShowDescriptionButton
								handleClick={() => setCustomFieldDescriptionToShow(customField.description)}
							/>
						)}
					</>
				);
		}
	};

	return (
		<div className="row row-cols-md-3 custom-fields-form-container">
			{props.customFieldsConfig?.map((item, index) => {
				return (
					<div className="col-md-4" key={item.id}>
						<FormInput
							errors={errors}
							type="hidden"
							register={register}
							name={`custom_fields.${index}.id`}
						/>
						<FormInput
							errors={errors}
							type="hidden"
							register={register}
							name={`custom_fields.${index}.slug`}
							value={item.slug}
						/>
						<div id={item.slug}>{renderCustomField(item, index)}</div>
					</div>
				);
			})}
			{!!customFieldDescriptionToShow && (
				<CustomFieldDescriptionModal
					show={!!customFieldDescriptionToShow}
					onHide={() => setCustomFieldDescriptionToShow(undefined)}
					description={customFieldDescriptionToShow}
				/>
			)}
		</div>
	);
};
export default CustomFieldsForm;
