export const convertListStatusToEntityStatus = (status: string): string => {
	switch (status) {
		case "OPENED":
		case "HIRED":
		case "CONFIRMED":
		case "NEW":
		case "CLOSED":
		case "SUCCESS":
		case "ENABLED":
		case "DEFAULT":
		case "SUPPORTED":
			return "ACTIVE";
		case "REMOVED":
		case "FIRED":
		case "CANCELED":
		case "VOIDED":
		case "ERROR":
		case "DELETED":
			return "DISABLED";
		case "EXTERNAL":
			return "WARNING";
		case "OPEN_BLUE":
		case "OPENED_BLUE":
			return "INFORMATION";
		case "VOIDED_GRAY":
		case "NEW_GRAY":
			return "DISMISSED";
		default:
			return status;
	}
};
