import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as FiltersSVG } from "../../../../../../../../images/svg/liveOrders/filters.svg";
import { OrdersContext } from "../../services/context";
import { LiveOrdersFilters } from "../../services/types";
import LiveOrdersBlockedProductsModal from "../LiveOrdersBlockedProducts/LiveOrdersBlockedProductsModal";
import LiveOrdersFiltersModal from "./LiveOrdersFiltersModal";
import LiveOrdersSearch from "./LiveOrdersSearch";
import LiveOrdersStatusFilter from "./LiveOrdersStatusFilter";

interface LiveOrdersFiltersNavigationProps {
	isMobile?: boolean;
}

const LiveOrdersFiltersNavigation = ({ isMobile }: LiveOrdersFiltersNavigationProps): JSX.Element => {
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const { filters, setFilters } = useContext(OrdersContext);
	const [innerFilters, setInnerFilters] = useState<LiveOrdersFilters>(filters);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const [showBlockedProductsModal, setShowBlockedProductsModal] = useState(false);
	const { t } = useTranslation();
	const location = useLocation();
	const [amountOfSelectedFilters, setAmountOfSelectedFilters] = useState(0);
	const filtersAsString = JSON.stringify(filters);

	useEffect(() => {
		handleChangeAmountOfSelectedFilters();
	}, [filtersAsString, isMobile]);

	const getRouteLinkAfterApplyingFilters = () => {
		const { pathname } = location;
		if (pathname.includes("list")) return `/${organization.id}/live_orders/list`;
		if (pathname.includes("table")) return `/${organization.id}/live_orders/table`;
		return `/${organization.id}/live_orders/map`;
	};

	const handleApplyFilters = () => {
		setFilters({
			...filters,
			...innerFilters,
		});
		history.push(getRouteLinkAfterApplyingFilters());
	};

	const handleChangeAmountOfSelectedFilters = () => {
		let amount = 0;
		if (isMobile) {
			if (filters.search !== "") amount++;
			if (filters.status.id !== "ACTIVE") amount++;
		}
		if (filters.time.id !== "6") amount++;
		if (filters.employeesLastActivity.id !== "1") amount++;
		if (filters.deliveryEmployee !== null) amount++;
		if (filters.type !== null) amount++;
		setAmountOfSelectedFilters(amount);
	};

	return (
		<div className="live-orders-filters">
			<LiveOrdersFiltersModal
				isShown={showFilters}
				handleApplyFilters={handleApplyFilters}
				onHide={() => setShowFilters(false)}
				innerFilters={innerFilters}
				setInnerFilters={setInnerFilters}
				filters={filters}
			/>

			{!isMobile && (
				<>
					<LiveOrdersSearch innerFilters={innerFilters} setInnerFilters={setInnerFilters} />
					<LiveOrdersStatusFilter innerFilters={innerFilters} setInnerFilters={setInnerFilters} />
				</>
			)}
			<Button className={`filters-button ${isMobile ? "me-2" : "ms-2"}`} onClick={() => setShowFilters(true)}>
				<FiltersSVG />
				{amountOfSelectedFilters !== 0 && (
					<div className="filters-button-amount-container">
						<span>{amountOfSelectedFilters}</span>
					</div>
				)}
			</Button>
			<Dropdown className={`${isMobile ? "me-3" : "ms-3"}`}>
				<Dropdown.Toggle variant="light">{t("modules.live_order.field.actions.title")}</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={() => setShowBlockedProductsModal(true)}>
						{t("modules.live_order.action.block_products.title")}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			{showBlockedProductsModal && (
				<LiveOrdersBlockedProductsModal
					isShown={showBlockedProductsModal}
					onHide={() => setShowBlockedProductsModal(false)}
				/>
			)}
		</div>
	);
};

export default LiveOrdersFiltersNavigation;
