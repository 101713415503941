import { FC, Suspense, useContext, useEffect, useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import { LoadingContainer } from "go-core/components/Loading";
import ReportData from "go-report/reports";
import {
	getChartReport,
	getLastMonthFilterBetween,
	getReport,
	getReportCustomFieldsResources,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import { ReportInvoicesUtils } from "../../../../../../../../utils/reports/ReportInvoicesUtils";

interface ListState {
	resource?: Record<string, any>;
}

const type = "invoice";
const listName = "INVOICE_REPORT";
const resourceType = "INVOICE_REPORT";

const Report: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const filtersParams = useRef<string>("");
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		reportConfigId: "invoices",
		type,
		defaultGroup: "NONE",
		aggregatePrefix: "sales",
		chart: ReportInvoicesUtils.getChartConfig(t),
		customLocalStorageKey: "invoice_report_type",
		selectedColumns: ReportInvoicesUtils.getSelectedColumns("invoices"),
		groups: ReportInvoicesUtils.getGroups(t),
		groupsInfo: ReportInvoicesUtils.getGroupsInfo(t),
		showPercent: true,
		columns: ReportInvoicesUtils.getColumns(t),
		filters: ReportInvoicesUtils.getFilters(t, filtersParams, type),
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date",
				value: getLastMonthFilterBetween().split("=")[1],
				condition: getLastMonthFilterBetween().split("=")[0],
				visible: true,
			},
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				columns: ReportInvoicesUtils.getSelectedColumns("invoices"),
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			filtersParams.current = params.f;
			return Promise.all([
				getReport(
					apiReport.getReportsCustom,
					params,
					organization.id,
					config.filters,
					config.type,
					sourceToken
				),
				getChartReport(
					apiReport.getReportsCustom,
					params,
					"NONE",
					organization.id,
					config.filters,
					config.type,
					sourceToken
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			return getChartReport(
				apiReport.getReportsCustom,
				params,
				"NONE",
				organization.id,
				config.filters,
				config.type,
				sourceToken
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.invoice.field.export_config.report.title"),
			filename: t("modules.invoice.field.export_config.report.file_name.title"),
			organization: organization.name,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		withCompareDateRange: true,
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
	} as ReportConfig;

	data.fields = getReportCustomFieldsResources(data.fields, ["INVOICE"], t);

	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

export const OrganizationReportsInvoicesPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "INVOICE",
			name: t(`enums.custom_fields.resources.INVOICE`),
		},
	];

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};
