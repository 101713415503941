import React, { FC, forwardRef, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { ReactComponent as LoadingSVG } from "go-core/images/svg/loading.svg";
import { ReportApi } from "go-report/core/services/types";
import { ChartDataRangeType, ChartType, DashboardCardConfig, ReportType } from "../../../services/charts/types";
import { DashboardFilterConfigType } from "../../../services/filters/types";
import useDashboardApi from "../../../services/useDashboardApi";
import useDashboardContext from "../../../services/useDashboardContext";
import { getDashboardChartActionType } from "../../../utils/typeUtils";
import useIsFirstRender from "../../../utils/useIsFirstRender";
import ReportItemWrapperFooter from "./ReportItemWrapperFooter";
import ReportItemWrapperHeader from "./ReportItemWrapperHeader";

interface Props {
	config: DashboardCardConfig;
}

const ReportItemWrapper: FC<Props> = forwardRef<HTMLDivElement, Props>(({ config, children }, ref) => {
	const { type, chartDataRangeType, chartType } = config;
	const { state, dispatch } = useDashboardContext();
	const isFirstRender = useIsFirstRender();
	const { addFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const {
		fetchSalesReports,
		fetchCategoryReports,
		fetchProductReports,
		fetchPaymentReports,
		fetchSalesChartReports,
	} = useDashboardApi();

	const reloadReportData = async (
		func: (
			filtersConfig: DashboardFilterConfigType,
			controller: AbortController,
			chartDataRangeType?: ChartDataRangeType
		) => Promise<AxiosResponse<ReportApi>>,
		controller: AbortController
	) => {
		setLoading(true);
		try {
			const resp = await func(state.filtersConfig, controller, chartDataRangeType);
			dispatch({
				type,
				data: resp.data,
			});
			setLoading(false);
		} catch (e) {
			handleError.alert(e, addFlash);
			setLoading(false);
		}
	};

	const updateChartRangeType = (rangeType: ChartDataRangeType) => {
		const dashboardConfig = localStorage.getItem("dashboard_cfg");
		let newDashboardConfig;

		if (dashboardConfig) {
			const parsedDashboardConfig = JSON.parse(dashboardConfig);
			newDashboardConfig = { ...parsedDashboardConfig, [type]: { range: rangeType } };
		} else newDashboardConfig = { [type]: { range: rangeType } };

		localStorage.setItem("dashboard_cfg", JSON.stringify(newDashboardConfig));

		dispatch({
			type: getDashboardChartActionType(type),
			data: {
				range: rangeType,
				type: chartType,
			},
		});
	};

	const updateChartType = (chartType: ChartType) => {
		dispatch({
			type: getDashboardChartActionType(type),
			data: {
				range: chartDataRangeType,
				type: chartType,
			},
		});
	};

	useEffect(() => {
		if (!isFirstRender) {
			const controller = new AbortController();

			reloadReport(type, controller);

			return () => {
				controller.abort();
			};
		}
	}, [chartDataRangeType]);

	const reloadReport = (type: ReportType, controller: AbortController) => {
		switch (type) {
			case "product_reports":
				reloadReportData(fetchProductReports, controller);
				break;
			case "category_reports":
				reloadReportData(fetchCategoryReports, controller);
				break;
			case "payment_reports":
				reloadReportData(fetchPaymentReports, controller);
				break;
			case "sales_reports":
				reloadReportData(fetchSalesReports, controller);
				break;
			case "sales_advanced_reports":
				reloadReportData(fetchSalesChartReports, controller);
				break;
			default:
				break;
		}
	};

	return (
		<div ref={ref} className={"report-item-wrapper"}>
			<ReportItemWrapperHeader config={config} updateChartType={updateChartType} />
			{children}
			{loading && <LoadingSVG className={"loading-custom"} />}
			<ReportItemWrapperFooter updateChartRangeType={updateChartRangeType} config={config} />
		</div>
	);
});

ReportItemWrapper.displayName = "ReportItemWrapper";

export default ReportItemWrapper;
