import { AxiosResponse } from "axios";
import {
	BadRequestException,
	ForbiddenException,
	NotAllowedException,
	NotFoundException,
	OrganizationAccessException,
	TooManyRequestsException,
	UnauthorizedException,
	UnknownException,
	UnprocessableEntity,
} from "./exceptions";

export function handleResponse(response?: AxiosResponse): AxiosResponse {
	switch (response?.status) {
		case 200:
			return response;
		case 201:
		case 204:
			return response;
		case 400:
			throw new BadRequestException(response.data);
		case 422:
			throw new UnprocessableEntity(response.data);
		case 401:
			throw new UnauthorizedException(response.data);
		case 403:
			throw new ForbiddenException(response.data);
		case 404:
			throw new NotFoundException();
		case 405:
			throw new NotAllowedException();
		case 423:
			throw new OrganizationAccessException(response.data);
		case 429:
			throw new TooManyRequestsException();
		default:
			throw new UnknownException({ status: response?.status, url: response?.config?.url });
	}
}
