import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import {
	ItemApi,
	ItemFormApi,
	ItemGroupConfigApi,
	PointOfSaleApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ItemGroupGroupEditingVirtualTableForm from "./components/ItemGroupGroupEditingVirtualTableForm";
import { getParsedItemPointsOfSale, itemGroupGroupEditingInclude } from "./utils";

const OrganizationMenuItemGroupsGroupEditingPage = () => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [items, setItems] = useState<ItemFormApi[] | undefined>();
	const [pointsOfSales, setPointsOfSale] = useState<PointOfSaleApi[] | undefined>();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.item_group_group_editing.header.title"));
		handleFetch();
	}, []);

	const handleFetch = async () => {
		try {
			const params: Record<string, any> = {
				page: "0",
				size: "0",
				include: itemGroupGroupEditingInclude,
			};

			Promise.all([api.organization().getItemGroupConfig(), api.organization().getItems(params)]).then(
				([config, items]: [ItemGroupConfigApi, ItemApi[]]) => {
					setPointsOfSale(config.points_of_sale);
					const parsedItems: ItemFormApi[] = items.map((item, index) => {
						const parsedItem: ItemFormApi = {
							...item,
							index,
							point_of_sale_visible: item.points_of_sale.length > 0,
							full_name: item.name,
							name:
								item.name !== item.item_group?.name
									? item.name.replace(item.item_group?.name || "", "").trim()
									: t("common.word.default"),
						};
						parsedItem.points_of_sale = getParsedItemPointsOfSale(parsedItem, config.points_of_sale);
						return parsedItem;
					});
					setItems(parsedItems);
				}
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return (
		<>
			<div className="virtual-table-wrapper-container-header">
				<h1>{t("modules.item_group_group_editing.header.title")}</h1>
			</div>
			{!items || !pointsOfSales ? (
				<LoadingContainer />
			) : (
				<ItemGroupGroupEditingVirtualTableForm
					items={items}
					pointsOfSale={pointsOfSales}
					handleUpdate={(res) => setItems([...res])}
				/>
			)}
		</>
	);
};

export default OrganizationMenuItemGroupsGroupEditingPage;
