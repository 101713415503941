import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../images/svg/remove.svg";
import { DeviceApi, DirectionApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	direction: DirectionApi;
	onHide: () => void;
	handleSave: (direction: DirectionApi) => void;
}

const DirectionModalForm = (props: Props): JSX.Element => {
	const form = useForm<DirectionApi>({
		criteriaMode: "all",
		defaultValues: props.direction,
	});
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		formState,
		control,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const onSubmit = handleSubmit(async (data: any) => {
		setLoading(true);
		data.id = props.direction.id;
		const params: Record<string, any> = {
			include: "devices",
		};
		data.devices = data.devices
			?.map((device: { id: number }) => {
				if (Object.prototype.hasOwnProperty.call(device, "id")) {
					return device.id;
				}
				return device;
			})
			.filter((f: any) => !!f);
		try {
			if (data.id) {
				const res = await api.organization().updateDirection(data, params);
				props.handleSave(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createDirection(data, params);
				props.handleSave(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const searchDevices = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDevicesSearchSelect(
			search,
			{ "type|e": "PRINTER", "connection_interface|e": "LAN" },
			{
				cancelToken: options?.token,
			}
		);
	};

	const { fields, remove, append } = useFieldArray({
		control,
		name: "devices",
		keyName: "key",
	});

	return (
		<Modal show={!!props.direction} onHide={props.onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					{props.direction.id ? (
						<>
							{t("common.word.directions")} "{props.direction.name}"
						</>
					) : (
						<>{t("modules.direction.header.new.title")}</>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormDirty formState={formState} key="direction-role-form" noValidate onSubmit={onSubmit}>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						register={register}
						name="name"
						errors={errors}
					/>
				</FormDirty>
				<Form.Group className={"form-group mb-0"}>
					<Form.Label className="mb-0">{t("modules.direction.field.devices.title")}</Form.Label>
				</Form.Group>
				<Alert variant="warning" className="mt-2">
					{t("modules.direction.constraints.direction_only_with_lan")}
				</Alert>
				<table className="table table-form">
					<thead>
						<tr>
							<th className="action" />
							<th></th>
							<th className="action" />
						</tr>
					</thead>
					<tbody>
						{fields.map((item, index) => {
							return (
								<tr key={fields[index].key}>
									<td className="action" />
									<td>
										<FormSelectGroup
											name={`devices.${index}.id`}
											getOptionLabel={(option) => option.label}
											getOptionValue={(option) => option.id}
											placeholder={t("modules.direction.action.choose_device.title")}
											errors={errors}
											defaultValue={
												item.name
													? {
															label: item?.name,
															id: item?.id,
													  }
													: undefined
											}
											loadOptions={searchDevices}
											control={control}
											data-testid={`devices.${index}.id`}
										/>
									</td>
									<td className="action">
										<RemoveSVG className="icon" onClick={() => remove(index)} />
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<Button variant="add" type="button" onClick={() => append({ name: "" } as DeviceApi)}>
					+ {t("common.action.add", { ns: "lib" })}
				</Button>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
				<Button variant="light" onClick={props.onHide}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default DirectionModalForm;
