import React, { FocusEvent, KeyboardEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrdersContext } from "../../services/context";
import { LiveOrdersFilters } from "../../services/types";

interface Props {
	insideModal?: boolean;
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
}

const LiveOrdersSearch = ({ insideModal, setInnerFilters, innerFilters }: Props): JSX.Element => {
	const ordersContext = useContext(OrdersContext);
	const { t } = useTranslation();

	const onSearch = (e: string) => {
		ordersContext.setFilters({
			...ordersContext.filters,
			search: e,
		});
	};

	const keyDownHandler = async (event: KeyboardEvent<HTMLInputElement>) => {
		if (insideModal) return;

		if (event.key === "Enter" && event.target) {
			await onSearch((event.target as HTMLInputElement).value);
		}
	};

	const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
		if (!insideModal) {
			onSearch(e.target.value);
		}
	};

	const handleOnChange = (e: FocusEvent<HTMLInputElement, Element>) => {
		if (insideModal) {
			setInnerFilters({
				...innerFilters,
				search: e.target.value,
			});
		}
	};

	return (
		<input
			type="search"
			className={`form-control search-input ${insideModal ? "mb-3" : ""}`}
			placeholder={t("common.word.search", { ns: "lib" })}
			onKeyDown={keyDownHandler}
			defaultValue={ordersContext.filters.search}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
		/>
	);
};

export default LiveOrdersSearch;
