import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import SecurityAccessDenied from "go-security/components/AccessDenied";
import { hasPermission } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import OrganizationSettingsPermissionsIndexPage from "./pages/Index";

const OrganizationSettingsPermissionsPage: FC<RouteComponentProps> = (props) => {
	const user = useSelector(selectUser);

	const hasAccess = useSelector(hasPermission("MANAGE")) || user.roles?.includes("ROLE_ADMIN");

	if (!hasAccess) return <SecurityAccessDenied />;
	return (
		<div className="content">
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationSettingsPermissionsIndexPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSettingsPermissionsPage;
