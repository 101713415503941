import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiError } from "go-core/api/types";
import { paramsToSegment } from "go-report/reports/services/segment-service";
import { GoReportSegmentType } from "go-report/reports/services/types";
import Segment from "go-segment/components";
import { getSortedFilters } from "../../../../../list/services/segment-service";
import { GoListSegmentType } from "../../../../../list/services/types";
import { ListConfigField } from "../../../../services/types";
import { ListConfigColumn } from "../../../Column/services/types";
import { FilterCustomAction, FilterCustomDateRange, ListConfigFilter, ListSelectedFilter } from "../../services/types";
import MobileFiltersColumns from "./Columns/MobileFiltersColumns";
import MobileFiltersFiltersList from "./Filters/MobileFiltersFiltersList";

interface Props {
	show: boolean;
	onHide: () => void;
	onChangeFilters: (selectedFilters: ListSelectedFilter[]) => void;
	selectedFilters: ListSelectedFilter[];
	minDate?: Date | string;
	maxDate?: Date | string;
	dateRangePickerCustomActions?: FilterCustomAction[];
	dateRangePickerCustomRanges?: FilterCustomDateRange[];
	allUniqueParsedFilters: ListConfigFilter[];
	segments: (GoReportSegmentType | GoListSegmentType)[] | undefined;
	onChangeSegment: (segment: GoReportSegmentType | GoListSegmentType) => void;
	hasConfigSaveSegment: boolean;
	segmentModal: boolean;
	segment: GoReportSegmentType | GoListSegmentType | undefined;
	onSaveSegment: (
		segment: GoReportSegmentType | GoListSegmentType,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => Promise<ApiError[] | undefined>;
	onCreateSegment: (
		name: string,
		segmentFilters?: ListSelectedFilter[],
		segmentColumns?: string[],
		segmentAllColumnsInOrder?: string[],
		segmentStickyColumnsDividerPosition?: number,
		preventUpdateSegment?: boolean
	) => Promise<ApiError[] | undefined>;
	setSegmentModal: (value: boolean) => void;
	selectedColumns?: string[];
	onChangeColumns?: (selectedColumns: string[], sortedColumns: string[]) => void;
	allColumnsInOrder?: React.MutableRefObject<ListConfigColumn[]>;
	columnsFields?: ListConfigColumn[];
	customFields?: ListConfigField[];
	shouldDisableSortingOfStickyColumns?: boolean;
	numberOfActiveStickyColumns?: number;
	numberOfStickyColumnsAtTheStart?: number;
	setNumberOfActiveStickyColumns?: React.Dispatch<React.SetStateAction<number>>;
	isNumberOfStickyColumnsDynamic?: boolean;
	stickyColumnsDividerPosition?: React.MutableRefObject<number>;
	onChangeStickyColumnsDividerPosition?: (dividerPosition: number) => void;
	defaultSelectedColumns?: string[];
	defaultSelectedColumnsOrder?: ListConfigColumn[];
	defaultStickyColumns?: string[];
	isReport?: boolean;
	defaultGroups?: string[];
	defaultGroupColumn?: string;
	groupsFromConfig?: string[];
	stateColumns?: string[];
	defaultSort?: string | string[];
}

const MobileFiltersModal = ({
	show,
	onHide,
	onChangeFilters,
	selectedFilters,
	minDate,
	maxDate,
	dateRangePickerCustomRanges,
	dateRangePickerCustomActions,
	allUniqueParsedFilters,
	segments,
	onChangeSegment,
	hasConfigSaveSegment,
	segmentModal,
	segment,
	onSaveSegment,
	onCreateSegment,
	setSegmentModal,
	selectedColumns,
	allColumnsInOrder,
	stickyColumnsDividerPosition,
	onChangeStickyColumnsDividerPosition,
	columnsFields,
	numberOfActiveStickyColumns,
	setNumberOfActiveStickyColumns,
	isNumberOfStickyColumnsDynamic,
	shouldDisableSortingOfStickyColumns,
	onChangeColumns,
	customFields,
	defaultSelectedColumns,
	defaultStickyColumns,
	numberOfStickyColumnsAtTheStart,
	defaultSelectedColumnsOrder,
	isReport,
	defaultGroups,
	defaultGroupColumn,
	groupsFromConfig,
	stateColumns,
	defaultSort,
}: Props) => {
	const { t } = useTranslation();
	const [internalFilters, setInternalFilters] = useState<ListSelectedFilter[]>(selectedFilters);
	const [internalSegment, setInternalSegment] = useState<GoListSegmentType | GoReportSegmentType | undefined>(
		segment
	);
	const [internalSelectedColumns, setInternalSelectedColumns] = useState<string[]>(selectedColumns || []);
	const allInternalColumnsInOrderRef: React.MutableRefObject<ListConfigColumn[]> = useMemo(
		() => (allColumnsInOrder ? JSON.parse(JSON.stringify(allColumnsInOrder)) : undefined),
		[allColumnsInOrder?.current]
	);
	const [allInternalColumnsInOrder, setAllInternalColumnsInOrder] = useState(
		allInternalColumnsInOrderRef?.current?.map((column) => column.id)
	);
	const internalStickyColumnsDividerPositionRef: React.MutableRefObject<number> | undefined = useMemo(
		() => (stickyColumnsDividerPosition ? JSON.parse(JSON.stringify(stickyColumnsDividerPosition)) : undefined),
		[stickyColumnsDividerPosition?.current]
	);
	const [internalStickyColumnsDividerPosition, setInternalStickyColumnsDividerPosition] = useState(
		internalStickyColumnsDividerPositionRef?.current
	);
	const [internalNumberOfActiveStickyColumns, setInternalNumberOfActiveStickyColumns] = useState(
		numberOfActiveStickyColumns || 0
	);

	const handleClearFiltersAndClose = () => {
		onChangeFilters(selectedFilters);
		if (onChangeColumns && selectedColumns && allColumnsInOrder) {
			onChangeColumns(
				selectedColumns,
				allColumnsInOrder.current.map((column) => column.id)
			);
		}
		onHide();
	};

	const handleClearFilters = () => {
		const notClearableSelectedFilters = selectedFilters
			.filter((selectedFilter) => {
				const filterFromConfig = allUniqueParsedFilters.find(
					(uniqueFilter) => uniqueFilter.id === selectedFilter.filterId
				);
				if (filterFromConfig && filterFromConfig.isRemovable === false) {
					return selectedFilter;
				}
				return undefined;
			})
			.filter((selectedFilter) => selectedFilter);
		setInternalFilters(notClearableSelectedFilters);
	};

	const handleApply = () => {
		onHide();
		if (internalSegment) {
			onChangeSegment(internalSegment);
		}
		onChangeFilters(internalFilters);
		if (onChangeColumns && allColumnsInOrder) {
			allColumnsInOrder.current = allInternalColumnsInOrderRef.current;
			onChangeColumns(internalSelectedColumns, allInternalColumnsInOrder);
		}
		if (onChangeStickyColumnsDividerPosition && internalStickyColumnsDividerPosition !== undefined) {
			onChangeStickyColumnsDividerPosition(internalStickyColumnsDividerPosition);
		}
		if (setNumberOfActiveStickyColumns && internalNumberOfActiveStickyColumns !== undefined) {
			setNumberOfActiveStickyColumns(internalNumberOfActiveStickyColumns);
		}
	};

	const handleChangeInternalSegment = (newSegment: GoListSegmentType | GoReportSegmentType) => {
		setInternalSegment(newSegment);
		if (newSegment.editable) {
			setInternalSelectedColumns([...(newSegment.columns || [])]);
		} else {
			if (defaultSelectedColumnsOrder) {
				const columnsInOrder = defaultSelectedColumnsOrder
					.map((column) => allInternalColumnsInOrderRef.current.find(({ id }) => id === column.id))
					.filter((column) => column) as ListConfigColumn[];
				allInternalColumnsInOrderRef.current = columnsInOrder;
				setAllInternalColumnsInOrder(columnsInOrder.map(({ id }) => id));
			}
			setInternalSelectedColumns(defaultSelectedColumns || []);
		}
		setInternalFilters([...(newSegment.filters || [])]);
	};

	const handleSaveSegment = async (segment: GoReportSegmentType | GoListSegmentType) => {
		const res = await onSaveSegment(
			segment,
			internalFilters,
			internalSelectedColumns,
			allInternalColumnsInOrderRef?.current?.map((column: ListConfigColumn) => column.id),
			internalStickyColumnsDividerPosition,
			true
		);
		if (res && !Array.isArray(res)) handleChangeInternalSegment(paramsToSegment(res));
	};

	const handleCreateSegment = async (name: string) => {
		const res = await onCreateSegment(
			name,
			internalFilters,
			internalSelectedColumns,
			allInternalColumnsInOrderRef?.current?.map((column: ListConfigColumn) => column.id),
			internalStickyColumnsDividerPosition,
			true
		);
		if (res && !Array.isArray(res)) handleChangeInternalSegment(paramsToSegment(res));
	};

	const hasInternalSegmentChanged = () => {
		if (internalSegment) {
			const currentFilters = internalFilters.length === 0 ? undefined : internalFilters;
			const segmentFilters = internalSegment?.filters?.length === 0 ? undefined : internalSegment.filters;
			const sortedStateFilters = getSortedFilters(currentFilters);
			const sortedSegmentFilters = getSortedFilters(segmentFilters);

			if (!isReport) {
				if (isNumberOfStickyColumnsDynamic) {
					if ((internalSegment as GoListSegmentType).stickyColumnsDividerPosition === undefined) {
						if (internalStickyColumnsDividerPosition !== numberOfStickyColumnsAtTheStart) {
							return true;
						}
					} else if (internalStickyColumnsDividerPosition !== defaultStickyColumns) {
						return true;
					}
				}
			}

			if (isReport) {
				const currentGroups =
					defaultGroups?.length === 0 || (defaultGroups && defaultGroups[0] === "NONE")
						? undefined
						: defaultGroups;
				const parsedInternalSegment = internalSegment as GoReportSegmentType;
				const segmentGroups =
					parsedInternalSegment?.groups?.length === 0 ||
					(parsedInternalSegment?.groups && parsedInternalSegment?.groups[0] === "NONE")
						? undefined
						: parsedInternalSegment.groups;
				if (JSON.stringify(currentGroups) !== JSON.stringify(segmentGroups)) {
					return true;
				}

				const currentGroupColumn = defaultGroupColumn;
				const segmentGroupColumn = parsedInternalSegment?.groupColumn;
				if (currentGroupColumn !== segmentGroupColumn) {
					return true;
				}

				const currentSort = defaultSort as string | undefined;
				const segmentSort = internalSegment?.sort;
				if (segmentSort !== currentSort) {
					return true;
				}
			}

			if (!isReport) {
				const parsedDefaultSort = defaultSort as string[] | undefined;
				const parsedInternalSegment = internalSegment as GoListSegmentType;
				const currentSort = parsedDefaultSort?.length === 0 ? undefined : parsedDefaultSort?.join(",");
				const segmentSort =
					parsedInternalSegment?.sort?.length === 0
						? undefined
						: Array.isArray(parsedInternalSegment?.sort)
						? parsedInternalSegment?.sort?.join(",")
						: parsedInternalSegment?.sort;
				if (segmentSort !== currentSort) {
					return true;
				}
			}

			if (!isReport) {
				if (Array.isArray(defaultSelectedColumns) && Array.isArray(internalSelectedColumns)) {
					const configColumns = defaultSelectedColumns.join(",");
					let segmentColumns = internalSegment.columns?.join(",");
					const stateColumns = internalSelectedColumns.join(",");

					if (shouldDisableSortingOfStickyColumns && numberOfStickyColumnsAtTheStart) {
						const nonSortableColumns = internalSelectedColumns.slice(0, numberOfStickyColumnsAtTheStart);
						const nonSortableColumnsMissingFromSegment = nonSortableColumns.filter(
							(columnId) => !internalSegment.columns?.includes(columnId)
						);

						if (nonSortableColumnsMissingFromSegment.length && segmentColumns) {
							segmentColumns = `${nonSortableColumnsMissingFromSegment.join(",")},${segmentColumns}`;
						}
					}

					if (
						(segmentColumns && stateColumns !== segmentColumns) ||
						(!segmentColumns && stateColumns !== configColumns)
					) {
						return true;
					}
				}
			} else {
				const configColumns = defaultSelectedColumns?.join(",");
				const currentColumns = stateColumns === configColumns ? undefined : stateColumns?.join(",");
				const segmentColumns =
					internalSegment.columns?.join(",") === configColumns
						? undefined
						: internalSegment.columns?.join(",");
				if (currentColumns !== segmentColumns) {
					return true;
				}
			}

			return JSON.stringify(sortedStateFilters) !== JSON.stringify(sortedSegmentFilters);
		}

		if (internalFilters !== undefined) {
			return true;
		}

		if (isReport) {
			if (groupsFromConfig?.join(",") !== defaultGroups?.join(",")) {
				return true;
			}
		}

		if (stateColumns?.join(",") !== defaultSelectedColumns?.join(",")) {
			return true;
		}

		return false;
	};

	return (
		<Modal show={show} onHide={onHide} className="mobile-filters">
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:common.word.filters")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-column mb-3 flex-wrap segment-container">
					<div className="w-100 mb-3">
						{segments && (
							<Segment.List
								selected={internalSegment?.slug || ""}
								segments={segments}
								onChange={handleChangeInternalSegment}
							/>
						)}
					</div>
					<div className="row segment-container-buttons gap-2">
						{hasInternalSegmentChanged() && hasConfigSaveSegment && (
							<div className="col">
								<Segment.Save onClick={() => setSegmentModal(true)} />
							</div>
						)}
						<div className="col">
							<Button variant="light" onClick={handleClearFilters}>
								{t("lib:go_list.action.clear_filters.title")}
							</Button>
						</div>
					</div>
					{segmentModal && (
						<Segment.SaveModal
							segment={internalSegment ? internalSegment : { id: "all", slug: "all", name: "" }}
							onSave={handleSaveSegment}
							onCreate={handleCreateSegment}
							onHide={() => setSegmentModal(false)}
						/>
					)}
				</div>
				<MobileFiltersFiltersList
					internalFilters={internalFilters}
					allUniqueParsedFilters={allUniqueParsedFilters}
					setInternalFilters={setInternalFilters}
					dateRangePickerCustomActions={dateRangePickerCustomActions}
					dateRangePickerCustomRanges={dateRangePickerCustomRanges}
					minDate={minDate}
					maxDate={maxDate}
				/>
				{selectedColumns &&
					internalStickyColumnsDividerPositionRef &&
					setInternalNumberOfActiveStickyColumns &&
					internalNumberOfActiveStickyColumns !== undefined &&
					internalSegment && (
						<MobileFiltersColumns
							isNumberOfStickyColumnsDynamic={isNumberOfStickyColumnsDynamic}
							shouldDisableSortingOfStickyColumns={shouldDisableSortingOfStickyColumns}
							stickyColumnsDividerPosition={internalStickyColumnsDividerPositionRef}
							setStickyColumnsDividerPosition={setInternalStickyColumnsDividerPosition}
							setNumberOfActiveStickyColumns={setInternalNumberOfActiveStickyColumns}
							numberOfActiveStickyColumns={internalNumberOfActiveStickyColumns}
							allColumnsInOrder={allInternalColumnsInOrderRef}
							columnsFields={columnsFields}
							customFields={customFields}
							segment={internalSegment as GoListSegmentType}
							selectedColumns={internalSelectedColumns}
							setInternalSelectedColumns={setInternalSelectedColumns}
							setAllInternalColumnsInOrder={setAllInternalColumnsInOrder}
						/>
					)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="light" onClick={handleClearFiltersAndClose}>
					{t("lib:common.action.cancel")}
				</Button>
				<Button onClick={handleApply}>{t("lib:go_list.action.show_results.title")}</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default MobileFiltersModal;
