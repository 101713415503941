import React, { FC, memo, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
	SpreadsheetPriceListFormProps,
} from "../../../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTable } from "../../../../../../../components/VirtualTable/VirtualTable";
import { useResizeVirtualTableHeader } from "../../../../../../../components/VirtualTable/hooks/useResizeVirtualTableHeader";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../../../components/VirtualTable/types";
import {
	STARTING_CSS_INDEX,
	getVirtualTableHeaderStyles,
	getVirtualTableItemsAfterSortingAndFiltering,
} from "../../../../../../../components/VirtualTable/utils";
import SpreadsheetPriceListVirtualTableHeader from "./SpreadsheetPriceListVirtualTableHeader/SpreadsheetPriceListVirtualTableHeader";
import SpreadsheetPriceListVirtualTableRow from "./SpreadsheetPriceListVirtualTableRow";

interface Props {
	heightTr: number;
	width: number;
	height: number;
	items: MenuPriceListFlatApi[];
	priceLists: MenuPriceListApi[];
	form: UseFormReturn<SpreadsheetPriceListFormProps>;
	visiblePriceListsColumns: MenuPriceListApi[];
	visibleDefaultColumns: VirtualTableColumn[];
	applyPriceToAll: (itemIndex: number) => void;
	setShowPriceListsModal: (value: boolean) => void;
	setApplyPriceToItemIndex: (index: number) => void;
	sortings: VirtualTableListSelectedSort[];
	setSortings: (sortings: VirtualTableListSelectedSort[]) => void;
}

const SpreadsheetPriceListVirtualTable: FC<Props> = (props) => {
	const { t } = useTranslation();
	const items = props.items;
	const prevItems = useRef<string | string[]>([]);
	const intervalRef = useRef<any>(null);
	const [loading, setLoading] = useState(false);
	const [categorySearch, setCategorySearch] = useState<string | undefined>(undefined);
	const [itemSearch, setItemSearch] = useState<string | undefined>(undefined);
	const [modifierSearch, setModifierSearch] = useState<string | undefined>(undefined);
	const [itemGroupSearch, setItemGroupSearch] = useState<string | undefined>(undefined);
	const [modifierGroupSearch, setModifierGroupSearch] = useState<string | undefined>(undefined);
	const [itemFullNameSearch, setItemFullNameSearch] = useState<string | undefined>(undefined);
	const { sortings, setSortings } = props;
	const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});
	const MAX_ZINDEX = props.visibleDefaultColumns.length + STARTING_CSS_INDEX;
	const { handleVirtualTableHeaderResize, handleVirtualTableMouseDown, handleVirtualTableMouseUp } =
		useResizeVirtualTableHeader();

	const Row = memo(
		({ index, data, style }: { index: number; data: MenuPriceListFlatApi[]; style: Record<string, any> }) => {
			const item = data[index];
			return (
				<SpreadsheetPriceListVirtualTableRow
					applyPriceToAll={props.applyPriceToAll}
					style={style}
					columnWidths={columnWidths}
					item={item}
					form={props.form}
					priceLists={props.visiblePriceListsColumns}
					defaultColumns={props.visibleDefaultColumns}
					setShowPriceListsModal={props.setShowPriceListsModal}
					setApplyPriceToItemIndex={props.setApplyPriceToItemIndex}
					maxZIndex={MAX_ZINDEX}
				/>
			);
		}
	);

	useEffect(() => {
		if (JSON.stringify(items) !== prevItems.current) {
			fakeLoading();
			prevItems.current = JSON.stringify(items);
		}
	}, [items]);

	useEffect(() => {
		fakeLoading();
	}, [
		categorySearch,
		itemSearch,
		modifierSearch,
		itemGroupSearch,
		modifierGroupSearch,
		sortings,
		itemFullNameSearch,
	]);

	const style = {} as Record<string, any>;
	let height = 500;
	if (props.height && props.width) {
		style.height = props.height;
		style.width = props.width;
		height = props.height;
	}

	const isSearched = () => {
		return (
			categorySearch !== undefined ||
			itemGroupSearch !== undefined ||
			itemSearch !== undefined ||
			modifierSearch !== undefined ||
			modifierGroupSearch !== undefined ||
			itemFullNameSearch !== undefined
		);
	};

	const itemsSearcher = () => {
		if (!isSearched()) return items;

		let newItems = [...items];
		if (categorySearch) {
			newItems = newItems.filter((item) =>
				item?.category_name?.toLowerCase()?.includes(categorySearch.toLowerCase())
			);
		}
		if (itemGroupSearch) {
			newItems = newItems.filter((item) =>
				item?.item_group_name?.toLowerCase()?.includes(itemGroupSearch.toLowerCase())
			);
		}
		if (itemSearch) {
			if (itemSearch === "-") {
				newItems = newItems.filter((item) => item?.item_name === t("common.word.default"));
			} else {
				newItems = newItems.filter(
					(item) =>
						item.item_name !== t("common.word.default") &&
						item?.item_name?.toLowerCase()?.includes(itemSearch.toLowerCase())
				);
			}
		}
		if (itemFullNameSearch) {
			newItems = newItems.filter((item) =>
				item?.item_full_name?.toLowerCase()?.includes(itemFullNameSearch.toLowerCase())
			);
		}
		if (modifierGroupSearch) {
			newItems = newItems.filter((item) =>
				item?.modifier_group_name?.toLowerCase()?.includes(modifierGroupSearch.toLowerCase())
			);
		}
		if (modifierSearch) {
			newItems = newItems.filter((item) =>
				item?.sub_item_name?.toLowerCase()?.includes(modifierSearch.toLowerCase())
			);
		}
		return newItems;
	};

	const fakeLoading = () => {
		setLoading(true);
		if (intervalRef.current) clearTimeout(intervalRef.current);
		intervalRef.current = setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	const getItemsAfterSortingAndFiltering = () => {
		return getVirtualTableItemsAfterSortingAndFiltering(itemsSearcher(), sortings, [
			{
				key: "price.amount",
				columnName: "price",
			},
		]) as MenuPriceListFlatApi[];
	};

	const handleMouseDown = (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
		handleVirtualTableMouseDown(e);
	};

	const handleMouseUp = (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => {
		handleVirtualTableMouseUp(e, columnName, sortings, setSortings, skipSorting);
	};

	const handleHeaderResize = (width: number | undefined, key: string) => {
		handleVirtualTableHeaderResize(width, setColumnWidths, key, columnWidths);
	};

	const getHeaderStyles = (key: string) => {
		return getVirtualTableHeaderStyles(key, columnWidths);
	};

	return (
		<div className="virtual-table" style={style}>
			<VirtualTable
				height={height}
				width="auto"
				itemCount={getItemsAfterSortingAndFiltering().length}
				itemSize={props.heightTr}
				header={
					<SpreadsheetPriceListVirtualTableHeader
						priceLists={props.priceLists}
						getHeaderStyles={getHeaderStyles}
						form={props.form}
						visiblePriceListsColumns={props.visiblePriceListsColumns}
						setModifierGroupSearch={setModifierGroupSearch}
						items={getItemsAfterSortingAndFiltering()}
						maxZindex={MAX_ZINDEX}
						setItemSearch={setItemSearch}
						visibleDefaultColumns={props.visibleDefaultColumns}
						modifierSearch={modifierSearch}
						modifierGroupSearch={modifierGroupSearch}
						setItemFullNameSearch={setItemFullNameSearch}
						itemGroupSearch={itemGroupSearch}
						setItemGroupSearch={setItemGroupSearch}
						itemFullNameSearch={itemFullNameSearch}
						setCategorySearch={setCategorySearch}
						itemSearch={itemSearch}
						setModifierSearch={setModifierSearch}
						handleHeaderResize={handleHeaderResize}
						categorySearch={categorySearch}
						handleMouseDown={handleMouseDown}
						sortings={sortings}
						handleMouseUp={handleMouseUp}
					/>
				}
				loading={loading}
				itemKey={(x) => x}
				itemData={getItemsAfterSortingAndFiltering()}
				row={Row}
			/>
		</div>
	);
};

export default SpreadsheetPriceListVirtualTable;
