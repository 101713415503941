import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash, { Flash } from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { EmployeeApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EmployeeForm from "../../components/EmployeeForm";

interface MatchParams {
	employeeId: string;
}

export const onFetchEmployee = async (
	employeeId: string | number,
	setEmployee: (employee: EmployeeApi | undefined) => void,
	addFlash: (_: Flash) => void,
	t: TFunction,
	isCopyMode?: boolean
) => {
	try {
		const params: Record<string, any> = {
			include: "role,role.permissions,workplace,workplaces,workplaces.workplace,custom_fields",
		};
		const res: EmployeeApi = await api.organization().getEmployee(Number(employeeId), params);

		const newEmployeeValues = { ...res };
		if (isCopyMode) {
			newEmployeeValues.name = `${t("common.word.copy")} ${newEmployeeValues.name}`;
			newEmployeeValues.code = "";
			newEmployeeValues.card_code = "";
			newEmployeeValues.contact_phone_number = "";
			newEmployeeValues.contact_email = "";
			newEmployeeValues.address_city = "";
			newEmployeeValues.address_zip_code = "";
			newEmployeeValues.address_street = "";
			newEmployeeValues.address_build_nr = "";
			newEmployeeValues.address_flat_nr = "";
			newEmployeeValues.address_country = "";
			newEmployeeValues.contact_other = "";
		}
		setEmployee(newEmployeeValues);
	} catch (err) {
		handleError.alert(err, addFlash);
	}
};

export const fetchEmployeeConfig = async (
	setCustomFieldsConfig?: (customFieldsConfig: CustomFieldTemplateApi[] | undefined) => void,
	addFlash?: (_: Flash) => void
) => {
	if (!addFlash || !setCustomFieldsConfig) return undefined;

	try {
		const params: Record<string, string> = {
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=EMPLOYEE`),
			include: "options,constraints",
		};
		const res = await api.organization().getCustomFields(params);
		setCustomFieldsConfig(res);
	} catch (err) {
		handleError.alert(err, addFlash);
	}
};

const OrganizationEmployeesIndexEditPage = (): JSX.Element => {
	const [employee, setEmployee] = useState<EmployeeApi | undefined>();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>();
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { employeeId } = useParams<MatchParams>();

	useEffect(() => {
		onFetchEmployee(employeeId, setEmployee, addFlash, t);
		fetchEmployeeConfig(setCustomFieldsConfig, addFlash);
	}, [addFlash, t, employeeId]);

	if (!employee || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const renderTitle = () => (
		<>
			{t("modules.employee.header.title")} "{employee.name}"
			<FormatResourceStatus status={employee.status} />
		</>
	);

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/employees/${employee.id}`);

	return (
		<>
			<Header title={renderTitle()} back={goBack} />
			<EmployeeForm employee={employee} customFieldsConfig={customFieldsConfig} />
		</>
	);
};
export default OrganizationEmployeesIndexEditPage;
