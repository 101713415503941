import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExternalNumberSVG } from "../../../../../../../../../images/svg/bills/external-number.svg";
import { OrderApi } from "../../../../../../../../../services/Api/Organization/types";
import { RenderOrderSourceLogo } from "../../../../../../../../../utils/orders/RenderOrderSourceLogo";

export interface Props {
	order: OrderApi;
}

const SourceCard: FC<Props> = ({ order }) => {
	const { t } = useTranslation();
	const source = order?.source;
	const sourceLink = order?.external_links?.find(
		(link) => link?.app_id?.toString() === order?.source?.app_id?.toString()
	)?.external_link;

	return (
		<Card className="order-source-card">
			<Card.Body>
				<h5>{t("lib:common.word.source")}</h5>
				<div className="card-body-item align-items-center mb-2">
					{source ? (
						<>
							<RenderOrderSourceLogo source={source.name} />
							<div className="item-info mb-0">
								<span>{source.name}</span>
							</div>
						</>
					) : (
						<>
							<RenderOrderSourceLogo source="gopos" />
							<div className="item-info mb-0">
								<span>{t("modules.app.field.gopos.title")}</span>
							</div>
						</>
					)}
				</div>
				{source?.number && (
					<div className="card-body-item">
						<ExternalNumberSVG />
						<div className="order-info">
							<div className="item-info">
								<span className="label">{t("modules.order.field.external_number.title")}</span>
								{sourceLink ? (
									<a href={sourceLink} rel={"noreferrer"} target={"_blank"}>
										#{source.number}
									</a>
								) : (
									<span>#{source.number}</span>
								)}
							</div>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default SourceCard;
