import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { formatDateTimeToString, formatFormDateToString } from "go-core/components/Formatters/FormatDate";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	ClientApi,
	ReservationApi,
	RoomTableSearchSelectApi,
} from "../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../services/Api/api";
import ClientModalForm from "../../../components/ModalForms/ClientModalForm";

interface Props {
	reservation: ReservationApi;
}

interface ClientDefaultValue {
	label: string;
	id: number;
}

const ReservationForm = (props: Props): JSX.Element => {
	const form = useForm<ReservationApi>({
		criteriaMode: "all",
		defaultValues: props.reservation,
	});
	const [client, setClient] = useState<ClientApi>(props.reservation.client);
	const [apiErrors, setApiErrors] = useState<Record<string, any>[]>([]);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		control,
		formState,
		setError,
		clearErrors,
		getValues,
	} = form;
	const [loading, setLoading] = useState(false);
	const [showClientModal, setShowClientModal] = useState(false);
	const [clientName, setClientName] = useState<ClientDefaultValue>({
		label: props.reservation.client?.name,
		id: props.reservation.client?.id,
	});
	const onSubmit = handleSubmit(async (data: ReservationApi) => {
		setLoading(true);
		data.id = props.reservation.id;
		const params: Record<string, string> = { include: "room,table,client" };
		setApiErrors([]);
		try {
			if (data.id) {
				const res = await api.organization().updateReservation(data, params);
				setLoading(false);
				reset(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createReservation(data);
				history.push(`/${organization.id}/reservations/${res.id}`);
				setLoading(false);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			setLoading(false);
			const errors = handleException(e);
			const collidingError = errors.find((f) => f.code === "reservation_colliding_with_other_reservation");
			if (collidingError) {
				setApiErrors([collidingError]);
			}
			handleError.form(e, setError, addFlash, ["reservation_colliding_with_other_reservation"]);
		}
	});

	const searchRoomTables = async (search: string, obj: Record<string, any>, options?: Record<string, any>) => {
		const res = await api.organization().getRoomTablesSearchSelect(search, obj, {
			cancelToken: options?.token,
		});
		return res.map((res: RoomTableSearchSelectApi) => {
			return {
				...res,
				label: `${res.label} (${res.room_name}, ${t("modules.reservation.field.seats_amount.title")}: ${
					res.number_of_seats
				})`,
			};
		});
	};

	const searchClients = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const handleCreateClient = (obj: ClientApi) => {
		setClient(obj);
		setValue("client_id", obj.id);
		setShowClientModal(false);
	};

	const onCreateClient = (obj: string) => {
		setClient({
			name: obj,
		} as ClientApi);
		setShowClientModal(true);
	};

	const defaultTable = props.reservation.table
		? {
				...props.reservation.table,
				label: `${props.reservation.table.name} (${props.reservation.room.name}, ${t(
					"modules.reservation.field.seats_amount.title"
				)}: ${props.reservation.table.number_of_seats})`,
		  }
		: undefined;

	const onChangeDate = (date: string) => {
		clearErrors("date_to");
		const dateObj = formatFormDateToString(date);
		dateObj.setHours(dateObj.getHours() + 2);
		const dateTo = formatDateTimeToString(dateObj);
		// setDefaultDateTo(dateTo)
		setValue("date_to", dateTo, { shouldDirty: true });
	};

	const onSuccessfulClientCreationAction = (createdClient: ClientApi) => {
		setValue("client_id", createdClient.id);
		setValue("client", {
			...getValues("client"),
			id: createdClient.id,
			name: createdClient.name,
			status: createdClient.status,
		});
		setClientName({
			label: createdClient.name,
			id: createdClient.id,
		});
	};

	return (
		<FormDirty
			loading={props.reservation.reservation_status !== "CANCELED" ? loading : undefined}
			formState={formState}
			key="reservation-form"
			noValidate
			onSubmit={onSubmit}
		>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				{apiErrors.length > 0 && (
					<Alert variant="danger">
						{t("constraints.reservation_colliding_with_other_reservation")}.{" "}
						<Link target={"_blank"} to={`/${organization.id}/reservations/${apiErrors[0].duplicate_id}`}>
							{t("lib:common.action.goto")}
						</Link>
					</Alert>
				)}
				<div className={"row"}>
					<div className="col-md-6">
						<FormSelectGroup
							label={t("common.word.table")}
							name="table_id"
							placeholder={t("modules.reservation.field.room_table.placeholder.title")}
							errors={errors}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							defaultValue={defaultTable}
							loadOptions={searchRoomTables}
							control={control}
							data-testid="table_id"
						/>
					</div>
					<div className="col-md-6">
						<FormNumberInput
							label={t("modules.reservation.field.seats_amount.title")}
							control={control}
							decimalScale={0}
							suffix={""}
							name="seats_number"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormDatePicker
							label={t("modules.reservation.field.date_from.title")}
							control={control}
							onChangeDate={(date) => onChangeDate(date)}
							name="date_from"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormDatePicker
							label={t("modules.reservation.field.date_to.title")}
							control={control}
							onChangeDate={() => clearErrors("date_from")}
							name="date_to"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormSelectGroup
							label={t("common.word.client")}
							name="client_id"
							placeholder={t("modules.reservation.field.client.placeholder.title")}
							errors={errors}
							path={`/${organization.id}/clients/`}
							onCreateOption={onCreateClient}
							onChange={(obj, fullObj) => setClient(fullObj)}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							loadOptions={searchClients}
							control={control}
							defaultValue={clientName}
							data-testid="client_id"
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("lib:common.word.description")}
							register={register}
							name="description"
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			{showClientModal && (
				<ClientModalForm
					client={client}
					onHide={() => setShowClientModal(false)}
					handleCreate={handleCreateClient}
					onSuccessfulAction={onSuccessfulClientCreationAction}
				/>
			)}
		</FormDirty>
	);
};
export default ReservationForm;
