import React, { FC } from "react";
import { PosReportApi } from "../../../../../../../../../../../services/Api/Organization/types";
import EmployeesTabContent from "./EmployeesTabContent";

interface Props {
	report: PosReportApi;
}

const EmployeesTabPreview: FC<Props> = ({ report }) => {
	return <EmployeesTabContent report={report} employees={report.employees} />;
};
export default EmployeesTabPreview;
