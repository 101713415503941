import { FC } from "react";
import { ReactComponent as ChairSVG } from "../../../../../../../../../../../../images/svg/rooms/chair.svg";
import { RoomTableApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	name?: string;
	guestAmount?: number;
	handleCreate?: (table: RoomTableApi) => void;
	styles?: Record<string, any>;
}

const Hooker: FC<Props> = ({ name, guestAmount, handleCreate, styles }) => {
	const onCreate = () => {
		const obj = {
			type: "HOKER",
			width: 48,
			height: 48,
			number_of_seats: 1,
			position_top: 50,
			position_left: 50,
			name: "",
		} as RoomTableApi;
		if (handleCreate) {
			handleCreate(obj);
		}
	};

	return (
		<div className={"table-box box-HOKER"} style={styles} onClick={onCreate}>
			{guestAmount && guestAmount > 1 && (
				<div className={"box-chair"}>
					<ChairSVG /> {guestAmount}
				</div>
			)}
			<div className={"box-small"}>{name ? name : "#"}</div>
		</div>
	);
};
export default Hooker;
