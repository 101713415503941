import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import FormatResourceStatus from "../../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import {
	CompanyInfoApi,
	InvoiceApi,
	InvoiceDetailsApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import ConfirmedInvoiceWarning from "../../../../../components/ConfirmedInvoiceWarning";
import CompanyInfoNotFull from "../../../../../components/Errors/CompanyInfoNotFull";
import InvoiceDetailsNotExist from "../../../../../components/Errors/InvoiceDetailsNotExist";
import ExportInvoiceConfirmationModal from "../../../../../components/ExportInvoicesConfirmationModal/ExportInvoiceConfirmationModal";
import InvoicePdfPreviewModal from "../../../../../components/InvoicePdfPreviewModal/InvoicePdfPreviewModal";
import InvoiceCorrectionPreview from "../../../../../components/Preview/InvoiceCorrectionPreview";
import SendInvoiceByEmailModal from "../../../../../components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import {
	exportEInvoice,
	exportInvoicePdf,
	generateInvoicePreviewPdfLink,
	printInvoicePdf,
} from "../../../../../services/exportInvoice";

interface Props extends RouteComponentProps {
	invoiceId: string;
	correctionId?: number;
}

const OrganizationInvoicesCorrectionsPreviewPage: FC<Props> = (props) => {
	const organization = useSelector(selectOrganization);
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>(undefined);
	const [showLogs, setShowLogs] = useState<boolean>(false);
	const { t } = useTranslation();
	const [correction, setCorrection] = useState<InvoiceApi | undefined>(undefined);
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const confirmation = useConfirmation();
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const hasVenueInvoiceEditPermission = useSelector(
		hasPermission(["VENUE_OWN_INVOICE_MANAGE", "VENUE_INVOICE_EDIT"])
	);
	const [invoicePdfLink, setInvoicePdfLink] = useState<string>("");
	const isMobile = useWindowSize().isMobile;
	const isAdmin = useSelector(selectIsAdmin);
	const [showConfirmationExportInvoice, setShowConfirmationExportInvoice] = useState<boolean>(false);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const invParams: Record<string, string> = {
			include: "linked_invoices,payments,previous_advances,payments.transaction",
		};
		const corrParams: Record<string, string> = {
			include:
				"custom_fields,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver,issuer,linked_invoices,linked_invoices.items,previous_advances",
		};
		const cfParams: Record<string, string> = {
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
			include: "options,constraints",
		};
		let inv: InvoiceApi;
		if (props.correctionId) {
			Promise.all([
				api.organization().getInvoice(props.correctionId, corrParams),
				api.organization().getCustomFields(cfParams),
				api.organization().getCompanyInfo(),
				api.organization().getInvoiceDetails(),
			])
				.then(([correctionResponse, cfResponse, companyInfo, invoiceDetails]) => {
					setCorrection(correctionResponse);
					const linkedInvoice = correctionResponse.linked_invoices.find(
						(f: { document_type: string }) => f.document_type === "INVOICE" || f.document_type === "ADVANCE"
					) as InvoiceApi | undefined;
					setInvoice(linkedInvoice);
					setCustomFieldsConfig(cfResponse);
					setCompanyInfo(companyInfo);
					setInvoiceDetails(invoiceDetails);
				})
				.catch((err) => {
					handleError.alert(err, addFlash);
				});
		} else {
			Promise.all([
				api.organization().getInvoice(Number(props.invoiceId), invParams),
				api.organization().getCustomFields(cfParams),
				api.organization().getCompanyInfo(),
				api.organization().getInvoiceDetails(),
			])
				.then(([invoiceResponse, cfResponse, companyInfo, invoiceDetails]) => {
					inv = invoiceResponse;
					setInvoice(inv);
					setCustomFieldsConfig(cfResponse);
					setCompanyInfo(companyInfo);
					setInvoiceDetails(invoiceDetails);
				})
				.then(() => {
					const correctionId = inv?.linked_invoices.find((f) => f.document_type === "CORRECTION")?.id;
					if (correctionId) {
						return api.organization().getInvoice(correctionId, corrParams);
					}
				})
				.then((res) => {
					setCorrection(res);
				})
				.catch((err) => {
					handleError.alert(err, addFlash);
				});
		}
	}, []);

	useEffect(() => {
		handleChangeTabTitle(
			`${t("modules.invoice.field.correction.title")} ${t("common.word.to", { ns: "lib" })} ${t(
				"common.word.invoice"
			)} ${invoice?.number || ""}`
		);
	}, [invoice?.number]);

	if (!invoice || !customFieldsConfig || !correction) {
		return <LoadingContainer />;
	}

	const removeInvoice = async (invoice: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoice.id);
			history.push(`/${organization.id}/invoices`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const exportInvoiceToPdf = () => {
		if (correction.status === "OPEN") {
			setShowConfirmationExportInvoice(true);
		} else {
			exportInvoicePdf(correction, t);
		}
	};

	const buttons: ButtonProps[] = [];

	if (!(!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin))
		buttons.push({
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/invoices/${invoice.id}/corrections/edit`,
			variant: "light",
		});
	buttons.push({
		title: t("common.action.export_pdf", { ns: "lib" }),
		action: exportInvoiceToPdf,
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.print", { ns: "lib" }),
		action: () => printInvoicePdf(correction),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.preview", { ns: "lib" }),
		action: async () => {
			const link = await generateInvoicePreviewPdfLink(correction);
			setInvoicePdfLink(link);
		},
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.field.e_invoice.title"),
		action: () => exportEInvoice(correction, t),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
		action: () => setShowSendInvoiceByEmailModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.remove", { ns: "lib" }),
		action: () => removeInvoice(correction),
		dropdown: true,
	});
	buttons.push({
		title: t("lib:common.word.logs"),
		action: () => setShowLogs(true),
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/invoices/${invoice.id}/corrections/edit`,
			hide: !hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin,
		},
		{
			title: t("common.action.export_pdf", { ns: "lib" }),
			action: exportInvoiceToPdf,
		},
		{
			title: t("common.action.print", { ns: "lib" }),
			action: () => printInvoicePdf(correction),
		},
		{
			title: t("common.action.preview", { ns: "lib" }),
			action: async () => {
				const link = await generateInvoicePreviewPdfLink(correction);
				setInvoicePdfLink(link);
			},
		},
		{
			title: t("modules.invoice.field.e_invoice.title"),
			action: () => exportEInvoice(correction, t),
		},
		{
			title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
			action: () => setShowSendInvoiceByEmailModal(true),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeInvoice(correction),
		},
		{
			title: t("lib:common.word.logs"),
			action: () => setShowLogs(true),
		},
	];

	const renderSubtitle = () => (
		<ul>
			<li className="me-1">{FormatDate(correction.dates?.issued_at, undefined, false, defaultFormatDate)}</li>
			<li>{FormatMoney(correction?.summary?.price_sum_gross)}</li>
		</ul>
	);

	const avatar = {
		color: "#1A86E0",
		date: invoice.dates?.issued_at,
	};

	const renderTitle = () => (
		<>
			{t("modules.invoice.field.correction.title")} {t("common.word.to", { ns: "lib" })}{" "}
			{t("common.word.invoice")} {invoice.number}
			<FormatResourceStatus status={correction.status === "OPEN" ? "NEW" : invoice.status} />
		</>
	);

	const changeInvoiceStatusToConfirmed = () => {
		setInvoice({
			...invoice,
			status: "CONFIRMED",
		});
	};

	return (
		<div className={"content-preview"}>
			{showConfirmationExportInvoice && (
				<ExportInvoiceConfirmationModal
					isShown={showConfirmationExportInvoice}
					onHide={() => setShowConfirmationExportInvoice(false)}
					invoices={[correction]}
					onSuccessAction={changeInvoiceStatusToConfirmed}
				/>
			)}
			<SendInvoiceByEmailModal
				isShown={showSendInvoiceByEmailModal}
				onHide={() => setShowSendInvoiceByEmailModal(false)}
				invoice={correction}
				onSuccess={changeInvoiceStatusToConfirmed}
			/>
			<MobileActions actions={mobileActions} />
			<Header
				buttons={isMobile ? [] : buttons}
				subtitle={renderSubtitle()}
				avatar={avatar}
				title={renderTitle()}
			/>
			{!hasVenueInvoiceEditPermission && invoice.status === "CONFIRMED" && !isAdmin && (
				<ConfirmedInvoiceWarning />
			)}
			{correction && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)) ? (
				<InvoiceDetailsNotExist />
			) : (
				<>
					{correction &&
					(!companyInfo ||
						Object.keys(companyInfo).length === 0 ||
						!companyInfo.company_name ||
						!companyInfo.tax_id_no) ? (
						<CompanyInfoNotFull />
					) : (
						<InvoiceCorrectionPreview
							correction={correction}
							setCorrection={setCorrection}
							config={customFieldsConfig}
						/>
					)}
				</>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={invoice.id}
					resourceType={"INVOICE"}
					onHide={() => setShowLogs(false)}
					organizationId={organization.id}
				/>
			)}
			{invoicePdfLink && (
				<InvoicePdfPreviewModal
					show={!!invoicePdfLink}
					onHide={() => setInvoicePdfLink("")}
					pdfLink={invoicePdfLink}
				/>
			)}
		</div>
	);
};
export default OrganizationInvoicesCorrectionsPreviewPage;
