import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { ClientGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import ClientGroupForm from "../../components/ClientGroupForm";

const OrganizationClientsClientGroupsNewPage = (): JSX.Element => {
	const [clientGroup, setClientGroup] = useState<ClientGroupApi>();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.client_groups.header.new.title"));
		setClientGroup({} as ClientGroupApi);
	}, []);

	return (
		<>
			<Header title={t("modules.client_groups.header.new.title")} />
			{clientGroup ? <ClientGroupForm clientGroup={clientGroup} /> : <LoadingContainer />}
		</>
	);
};
export default OrganizationClientsClientGroupsNewPage;
