import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { FormInput } from "go-form";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../images/svg/sortable-handle.svg";
import {
	MenuItemApi,
	ModifierGroupApi,
	ModifierGroupOptionApi,
} from "../../../../../../../../../services/Api/Organization/types";
import MenuPageItemModal from "../../Menus/components/MenuPageItemModal";

interface Props {
	form: UseFormReturn<ModifierGroupApi>;
	modifierOptions?: ModifierGroupOptionApi[];
}

const ModifierGroupOptionsForm = (props: Props): JSX.Element => {
	const {
		formState: { errors },
		control,
		register,
		setValue,
		watch,
		clearErrors,
	} = props.form;
	const isSortingRef = useRef(false);
	const [showModal, setShowModal] = useState(false);
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const { t } = useTranslation();
	const watchedOptions = watch("options");
	const { fields, remove, append, replace } = useFieldArray({
		control,
		name: "options",
	});
	const isMobile = useWindowSize().isMobile;

	const updatePositions = (items: ModifierGroupOptionApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items?.forEach((item, index) => {
			item.position = index;
		});
		replace(items);
	};

	const clearOptionErrors = (
		index: number,
		optionErrors: Array<
			| "quantity_info.default_quantity"
			| "quantity_info.max_permitted"
			| "quantity_info.min_permitted"
			| "quantity_info"
		>
	) => {
		clearErrors(["quantity_info", "quantity_info.max_permitted", "quantity_info.min_permitted"]);
		optionErrors.forEach((err) => {
			clearErrors(`options.${index}.${err}`);
		});
	};

	const createOption = (item: MenuItemApi) => {
		const subItem = {
			name: item.entity.name,
			item_group: {
				id: item.entity.parent_id,
				name: item.entity.parent_name,
			},
			price: item.entity?.price,
		};
		const opt = {
			position: fields.length,
			item_id: item.context_id,
			sub_item: subItem,
			quantity_info: {
				min_permitted: "",
				max_permitted: "",
				charge_above: "",
				default_quantity: "",
			},
			price: { amount: null, currency },
		} as ModifierGroupOptionApi;
		append(opt);
		setShowModal(false);
	};

	useEffect(() => {
		fields.forEach((opt, index) => {
			setValue(`options.${index}`, { ...opt, position: index });
		});
	}, [fields]);

	const removeOption = (index: number) => {
		clearErrors(`options.${index}.item_id`);
		remove(index);
	};

	const sortByAlphabetHandler = () => {
		const sortedNames = watchedOptions.map((option) => option.sub_item?.name).sort();
		setValue(
			"options",
			sortedNames.flatMap((name) => {
				return watchedOptions.filter((option) => option.sub_item?.name === name);
			})
		);
	};

	return (
		<div className="mt-2">
			<div className="d-flex justify-content-end">
				<Button variant="primary" onClick={sortByAlphabetHandler}>
					{t("modules.modifier_group.action.sort_by_alphabet.title")}
				</Button>
			</div>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form">
					<thead>
						<tr>
							<th className="action"></th>
							<th className="w-25">{t("lib:common.word.name")}</th>
							<th style={{ minWidth: isMobile ? "90px" : "unset" }}>
								{t("modules.modifier_group.field.price.title")}
							</th>
							<th>{t("modules.modifier_group.field.min_permitted.title")}</th>
							<th>{t("modules.modifier_group.field.max_permitted.title")}</th>
							<th>{t("modules.modifier_group.field.default_quantity.title")}</th>
							<th>{t("modules.modifier_group.field.charge_above.title")}</th>
							<th className="action" />
						</tr>
					</thead>
					{
						<ReactSortable
							handle=".sortable-handler"
							tag="tbody"
							list={watchedOptions}
							onUpdate={() => (isSortingRef.current = true)}
							setList={(listFields) => updatePositions(listFields)}
						>
							{watchedOptions.map((option, index) => {
								return (
									<tr key={option.item_id}>
										<td className="action">
											<SortableHandleSvg className="sortable-handler" />
											<FormInput
												errors={errors}
												type="hidden"
												register={register}
												name={`options.${index}.position`}
												defaultValue={index}
											/>
										</td>
										<td>
											<strong>
												<Link
													to={`/${organization.id}/menu/item_groups/${option.sub_item?.item_group?.id}`}
													target={"_blank"}
													rel="noreferrer"
												>
													{option.sub_item?.name}
												</Link>
											</strong>
											{Object.values(errors).map((f: Record<string, any>) => {
												if (f[index] && f[index].item_id) {
													return (
														<Form.Control.Feedback
															key={f[index].item_id}
															type={"invalid"}
															className={"d-inline"}
														>
															{t(
																"modules.item_group.constraints.item_contains_modifier_group"
															)}
														</Form.Control.Feedback>
													);
												}
												return null;
											})}
										</td>
										<td>
											<FormMoneyInput
												control={control}
												defaultValue={option.price?.amount}
												placeholder={FormatMoney({
													amount: option?.sub_item?.price?.amount,
													currency,
												})}
												currency={currency}
												name={`options.${index}.price.amount`}
												errors={errors}
											/>
										</td>

										<td>
											<FormNumberInput
												control={control}
												decimalScale={0}
												suffix={""}
												onChange={() =>
													clearOptionErrors(index, [
														"quantity_info.default_quantity",
														"quantity_info.max_permitted",
														"quantity_info.default_quantity",
														"quantity_info",
													])
												}
												name={`options.${index}.quantity_info.min_permitted`}
												errors={errors}
											/>
										</td>
										<td>
											<FormNumberInput
												control={control}
												decimalScale={0}
												suffix={""}
												onChange={() =>
													clearOptionErrors(index, [
														"quantity_info.default_quantity",
														"quantity_info.max_permitted",
														"quantity_info.default_quantity",
														"quantity_info",
													])
												}
												name={`options.${index}.quantity_info.max_permitted`}
												errors={errors}
											/>
										</td>
										<td>
											<FormNumberInput
												control={control}
												decimalScale={0}
												suffix={""}
												onChange={() =>
													clearOptionErrors(index, [
														"quantity_info.default_quantity",
														"quantity_info.max_permitted",
														"quantity_info.default_quantity",
														"quantity_info",
													])
												}
												name={`options.${index}.quantity_info.default_quantity`}
												errors={errors}
											/>
										</td>
										<td>
											<FormNumberInput
												control={control}
												decimalScale={0}
												suffix={""}
												name={`options.${index}.quantity_info.charge_above`}
												errors={errors}
												defaultValue={option.quantity_info?.charge_above}
											/>
										</td>
										<td className="action">
											<RemoveSVG onClick={() => removeOption(index)} className="icon" />
										</td>
									</tr>
								);
							})}
						</ReactSortable>
					}
				</table>
			</div>
			{showModal && (
				<MenuPageItemModal
					contextTypes={[]}
					item={{ context_type: "ITEM" } as MenuItemApi}
					selectedValues={fields.map((x) => {
						return {
							id: x.item_id,
							type: "ITEM",
						};
					})}
					handleClose={() => setShowModal(false)}
					handleSave={createOption}
					hasMultipleChoice
				/>
			)}
			<Button variant="add" onClick={() => setShowModal(true)}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
		</div>
	);
};

export default ModifierGroupOptionsForm;
