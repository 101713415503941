import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_SEPARATOR } from "go-list/core/components/Filter/services";
import {
	InvoiceApi,
	InvoiceOrderApi,
	OrderSearchSelectApi,
} from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { invoicePreviewIncludes } from "../../pages/Index/Preview";

interface Props {
	onAdd: (invoice: InvoiceApi) => void;
	onClose: () => void;
	invoice: InvoiceApi;
	isAdvance: boolean;
}

interface FormProps {
	order_id: number;
}

const AddInvoiceOrderModal = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const form = useForm<FormProps>({
		defaultValues: { order_id: undefined },
		criteriaMode: "all",
	});

	const {
		control,
		formState: { errors },
		getValues,
	} = form;

	const searchOrders = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"status|e": `CLOSED${FILTER_SEPARATOR}invoice|u`,
		};
		return api.organization().getOrdersSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = async () => {
		setLoading(true);
		const params: Record<string, any> = invoicePreviewIncludes;
		const dto = { ...props.invoice };
		dto.orders = [
			...props.invoice.orders
				.filter((invoiceOrder) => invoiceOrder?.order_id)
				.map((invoiceOrder) => {
					return { order_id: invoiceOrder.order_id } as InvoiceOrderApi;
				}),
		];
		if (getValues()?.order_id) {
			dto.orders.push({ order_id: getValues().order_id } as InvoiceOrderApi);
		}
		try {
			let res;
			if (props.isAdvance) {
				res = await api.organization().updateAdvanceInvoice(dto, params);
			} else {
				res = await api.organization().updateInvoice(dto, params);
			}
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			props.onAdd(res);
		} catch (err) {
			setLoading(false);
			handleError.alert(err, addFlash);
			handleException(err);
		}
	};

	const getOrderOption = (item: OrderSearchSelectApi) => {
		return `#${item.label} (${FormatDate(item.closed_at, undefined, false, defaultFormatDate)})`;
	};

	return (
		<Modal show={true} onHide={() => props.onClose()}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.invoice.action.add_order_to_invoice.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormSelectGroup
					name="order_id"
					errors={errors}
					getOptionLabel={(item) => getOrderOption(item)}
					defaultValue={undefined}
					getOptionValue={(option) => option.id}
					loadOptions={searchOrders}
					control={control}
					data-testid="order_id"
				/>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading variant="primary" onClick={onSubmit} loading={loading}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
				<Button variant="light" onClick={() => props.onClose()}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default AddInvoiceOrderModal;
