import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import {
	Control,
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
	UseFormStateReturn,
	useController,
} from "react-hook-form";
import {
	convertLocalDateToUTCDate,
	convertUTCDateToLocalDate,
	formatStringToDate,
} from "go-core/components/Formatters/FormatDate";
import { useCustomValidation } from "../hooks";
import { CustomValidationConfig } from "../services/types";
import { isConstraintARequiredConstraint } from "../services/utils";
import { DatePicker } from "./DatePicker";

interface Props {
	options?: any;
	label?: string;
	defaultValue?: any;
	loadOptions?: any;
	isInvalid?: boolean;
	isMulti?: boolean;
	parse?: (obj: any) => any;
	getOptionLabel?: (obj: any) => any;
	getOptionValue?: (obj: any) => any;
	onCreateOption?: (obj: any) => any;
	onChange?: (obj: any) => any;
	path?: string;
	groupLabel?: string;
	placeholder?: string;
	errors: any;
	timePicker?: boolean;
	dayPicker?: boolean;
	allowClear?: boolean;
	onChangeDate?: (rangeString: string) => any;
	onApply?: (date?: Date | string) => any;
	control: Control<any>;
	name: string;
	disabled?: boolean;
	customValidationConfig?: CustomValidationConfig;
	allowResetDate?: boolean;
	shouldParseDateToStringOnApply?: boolean;
	targetTimezone?: string;
	help?: string;
}

const parseToString = (date: Date | string, timePicker?: boolean) => {
	let value;
	if (typeof date === "string") {
		return "";
	}
	if (timePicker === undefined || timePicker) {
		value = `${date.getFullYear()}-${
			date.getMonth() + 1
		}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
	} else {
		value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	}
	return value.replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
};

const parseToDate = (value: string) => {
	if (!value) {
		return undefined;
	}
	return formatStringToDate(value);
};

export const FormDatePicker = (props: Props): JSX.Element => {
	const {
		allowClear,
		dayPicker,
		timePicker,
		label,
		errors,
		name,
		control,
		onChangeDate,
		onApply,
		defaultValue,
		disabled,
		customValidationConfig,
		allowResetDate,
		shouldParseDateToStringOnApply,
		targetTimezone,
		help,
		...controlProps
	} = props;
	const {
		field: { value },
	} = useController({
		name,
		control,
	});
	const handleChange = (
		date: Date | string,
		props: {
			field: ControllerRenderProps<any, string>;
			fieldState: ControllerFieldState;
			formState: UseFormStateReturn<any>;
		}
	) => {
		if (targetTimezone && date && date instanceof Date) {
			date = convertLocalDateToUTCDate(date);
		}
		const rangeString = parseToString(date, timePicker);
		props.field.onChange(rangeString);
		if (onChangeDate) {
			onChangeDate(rangeString);
		}
	};

	const handleApply = (date?: string | Date) => {
		if (onApply) {
			if (shouldParseDateToStringOnApply && date) {
				const rangeString = parseToString(date, timePicker);
				onApply(rangeString);
			} else {
				onApply(date);
			}
		}
	};

	let isTimePicker = false;
	if (timePicker === undefined || timePicker) {
		isTimePicker = true;
	}
	let isDayPicker = true;
	if (dayPicker === false) {
		isDayPicker = false;
	}

	const isFieldRequired = useMemo(
		() => customValidationConfig?.types.some(({ constraint }) => isConstraintARequiredConstraint(constraint)),
		[customValidationConfig?.types]
	);

	useCustomValidation({ name, value, customValidationConfig });

	return (
		<Controller
			name={name}
			render={(controlComponentProps) => {
				let date = parseToDate(controlComponentProps.field.value);
				if (date && targetTimezone) {
					date = convertUTCDateToLocalDate(controlComponentProps.field.value, targetTimezone);
				}
				return (
					<Form.Group className="form-group" controlId={name}>
						{label && <Form.Label>{isFieldRequired ? `${label} *` : label}</Form.Label>}
						<DatePicker
							disabled={disabled}
							allowClear={allowClear === undefined || allowClear}
							name={name}
							onApply={handleApply}
							errors={errors}
							formPicker={true}
							dayPicker={isDayPicker}
							timePicker={isTimePicker}
							date={date}
							onChange={(newDate) => handleChange(newDate, controlComponentProps)}
							allowResetDate={allowResetDate}
						/>
						{help && <Form.Text muted>{help}</Form.Text>}
					</Form.Group>
				);
			}}
			control={control}
			defaultValue={defaultValue}
			{...controlProps}
		/>
	);
};
