import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { apiOrganization } from "../../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
} from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	show: boolean;
	onHide: () => void;
	type: "pointOfSale" | "availability" | "direction" | "";
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	items: ItemFormApi[];
	pointOfSaleIndex?: number;
}

const ItemGroupGroupEditingEntityModal = ({ onHide, show, type, form, items, pointOfSaleIndex }: Props) => {
	const { t } = useTranslation();
	const { control, setValue } = form;
	const [innerValue, setInnerValue] = useState<{ id: string; label: string } | undefined>();

	const searchOptions = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		switch (type) {
			case "direction":
			case "pointOfSale":
				return apiOrganization.getDirectionsSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			case "availability":
				return apiOrganization.getAvailabilitiesSearchSelect(search, params, {
					cancelToken: options?.token,
				});
			default:
				return [];
		}
	};

	const onSubmit = () => {
		items.forEach((item) => {
			if (type === "pointOfSale" && pointOfSaleIndex !== undefined) {
				setValue(
					`items.${item.index}.points_of_sale.${pointOfSaleIndex}.direction_id`,
					innerValue ? Number(innerValue.id) : undefined
				);
				setValue(
					`items.${item.index}.points_of_sale.${pointOfSaleIndex}.direction`,
					innerValue
						? {
								id: Number(innerValue.id),
								name: innerValue.label,
						  }
						: undefined
				);
			}

			if (type === "availability") {
				setValue(`items.${item.index}.availability_id`, innerValue ? Number(innerValue.id) : undefined);
				setValue(
					`items.${item.index}.availability`,
					innerValue
						? {
								id: Number(innerValue.id),
								name: innerValue.label,
						  }
						: undefined
				);
			}

			if (type === "direction") {
				if (innerValue?.id) setValue(`items.${item.index}.point_of_sale_visible`, false);

				setValue(`items.${item.index}.direction_id`, innerValue ? Number(innerValue.id) : undefined);
				setValue(
					`items.${item.index}.direction`,
					innerValue
						? {
								id: Number(innerValue.id),
								name: innerValue.label,
						  }
						: undefined
				);
			}
		});
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.item_group_group_editing.field.set_entity_value.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormSelectGroup
					control={control}
					errors={[]}
					name="entity.id"
					value={innerValue?.id}
					onChange={(_, fullObj) => setInnerValue(fullObj)}
					getOptionValue={(option) => Number(option.id)}
					loadOptions={searchOptions}
					placeholder={t("lib:common.action.select")}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button type="submit" onClick={onSubmit}>
					{t("lib:common.action.select")}
				</Button>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ItemGroupGroupEditingEntityModal;
