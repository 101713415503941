import React, { useContext, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { Alert, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import FileUpload from "go-app/components/ImageForm/FileUpload";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ReactComponent as EmptyImageSVG } from "../../../go-component/images/svg/angle-input.svg";
import { ExportCSVTranslationsApi } from "../../services/types";
import AttributesTable, { AttributeProps, ImportFormProps } from "./AttributesTable";
import FileDisplay from "./FileDisplay";
import ImportConfirm from "./ImportConfirm";
import { CsvContext } from "./services/context";

interface Props {
	resourceType: string;
	customFieldsConfig?: CustomFieldTemplateApi[];
	redirectionUrl: string;
	importCsvRequestParams: string;
	comparationCheck: (data: any, fieldName: string) => any;
	renderComparedValue: (data: any, fieldName: string, differs?: boolean) => JSX.Element;
	translations: ExportCSVTranslationsApi;
	customAttributesCompare?: (attribute: string) => string;
	workingInBackground?: boolean;
}

const ImportCsv = ({
	resourceType,
	customFieldsConfig,
	redirectionUrl,
	importCsvRequestParams,
	comparationCheck,
	renderComparedValue,
	translations,
	customAttributesCompare,
	workingInBackground,
}: Props): JSX.Element => {
	const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
	const [attributes, setAttributes] = useState<AttributeProps[]>([]);
	const [errors, setErrors] = useState<ApiError[]>([]);
	const [response, setResponse] = useState<any>(undefined);
	const [loading, setLoading] = useState(false);
	const [stepNo, setStepNo] = useState(1);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const {
		export_csv: exportCSV,
		resource_name: resourceName,
		constraints,
		database_column_name: databaseColumnName,
	} = translations;
	const csvImportContext = useContext(CsvContext);
	const mainDivRef = useRef<HTMLDivElement>(null);
	const importTimeoutRef = useRef<number | undefined>();
	const cancelTokenSource = useRef<CancelTokenSource>();

	useEffect(() => {
		return () => {
			if (cancelTokenSource.current !== undefined) {
				cancelTokenSource.current.cancel();
			}
			window.clearTimeout(importTimeoutRef.current);
		};
	}, []);

	const onUpdateFile = (file: string, fileObj?: File) => setSelectedFile(fileObj);

	const getSimpleExampleFile = async () => {
		try {
			const res = await csvImportContext.getCsvExampleSimpleFile(resourceType);
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${resourceType}_simple.csv`);
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleImportTimeout = (
		jobUid: string,
		formData: FormData,
		_headers: Record<string, string>,
		params: Record<string, any>
	) => {
		if (!csvImportContext.getImportCsvProgress) return;

		importTimeoutRef.current = window.setTimeout(async () => {
			try {
				if (!csvImportContext.getImportCsvProgress) return;
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;

				const res = await csvImportContext.getImportCsvProgress(jobUid, resourceType, params, {
					cancelToken: newCancelToken.token,
				});
				if (res.status === 204) {
					setLoading(true);
					handleImportTimeout(jobUid, formData, _headers, params);
				} else {
					setLoading(false);
					setResponse(res.data.data);
					setStepNo(3);
					window.clearTimeout(importTimeoutRef.current);
				}
			} catch (err) {
				const errs = handleException(err);
				if (errs[0].message === "canceled") return;
				handleError.alert(err, addFlash);
				setErrors(errs);
				setStepNo(3);
				setLoading(false);
				window.clearTimeout(importTimeoutRef.current);
			}
		}, 1000);
	};

	const handleImport = async (dataForm: ImportFormProps) => {
		setAttributes(dataForm.attributes);
		setLoading(true);
		const params: Record<string, string> = { include: importCsvRequestParams };
		try {
			const formData = new FormData();
			if (selectedFile) formData.append("csv", selectedFile);

			formData.append(
				"attributes",
				new Blob(
					[
						JSON.stringify(
							dataForm.attributes.filter(
								(f: { domain_field_name: string }) => f.domain_field_name?.length > 0
							)
						),
					],
					{ type: "application/json" }
				)
			);
			const _headers = { "Content-Type": "multipart/form-data" };

			if (workingInBackground && csvImportContext?.importAsyncCsv) {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;
				const res = await csvImportContext.importAsyncCsv(resourceType, formData, _headers, params, {
					cancelToken: newCancelToken.token,
				});
				handleImportTimeout(res, formData, _headers, params);
			} else if (csvImportContext.importCsv) {
				const res = await csvImportContext.importCsv(resourceType, formData, _headers, params);
				setResponse(res);
			}
		} catch (err) {
			const errs = handleException(err);
			if (errs[0].message === "canceled") return;
			handleError.alert(err, addFlash);
			setErrors(errs);
		}
		if (!workingInBackground) {
			setStepNo(3);
			setLoading(false);
		}
	};

	const getExampleFile = async () => {
		try {
			const res = await csvImportContext.getCsvExampleFile(resourceType);
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${resourceType}.csv`); //or any other extension
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const exportCsv = async () => {
		try {
			const res = await csvImportContext.getCsvFromVenue(resourceType);
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${resourceType}.csv`);
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const processImport = async () => {
		if (selectedFile) {
			setLoading(true);
			try {
				const formData = new FormData();
				formData.append("csv", selectedFile);
				const headers = { "Content-Type": "multipart/form-data" };
				const res = await csvImportContext.getCsvAttributes(resourceType, formData, headers);
				setAttributes(res);
				setStepNo(2);
			} catch (e) {
				handleError.alert(e, addFlash);
			}
		}
		setLoading(false);
	};
	return (
		<div className={"import-page"} ref={mainDivRef}>
			<div className={"mb-10"}>
				<strong className={"import-info"}>
					{`${t("go_component.import_csv.field.step.title", { ns: "lib" })} ${stepNo} ${t("common.word.of", {
						ns: "lib",
					})} 3 `}
				</strong>
				"Import {resourceName}:
				<>
					{stepNo === 1 && ` ${t("go_component.import_csv.action.choose_file.title", { ns: "lib" })}`}
					{stepNo === 2 && ` ${t("go_component.import_csv.action.match_column.title", { ns: "lib" })}`}
					{stepNo === 3 && ` ${t("go_component.import_csv.field.operation_confirm.title", { ns: "lib" })}`}
				</>
				"
			</div>
			{stepNo === 1 && (
				<>
					<FileUpload
						fileIcon={
							selectedFile ? (
								<FileDisplay icon={<EmptyImageSVG />} fileName={selectedFile.name} />
							) : undefined
						}
						onUpdateFile={onUpdateFile}
					/>
					<div className={"action-ref"} onClick={getSimpleExampleFile}>
						{t("go_component.import_csv.action.download_simple_csv_example.title", { ns: "lib" })}
					</div>
					<div className={"action-ref"} onClick={getExampleFile}>
						{t("go_component.import_csv.action.download_csv_example.title", { ns: "lib" })}
					</div>
					<div className={"action-ref mb-2"} onClick={exportCsv}>
						{exportCSV}
					</div>
					<FormGroup>
						{selectedFile && (
							<ButtonLoading onClick={processImport} loading={loading}>
								{t("common.action.goto", { ns: "lib" })}
							</ButtonLoading>
						)}
					</FormGroup>
					<Alert variant={"warning"}>
						<div className={"d-flex flex-column"}>
							<strong className={"import-info"}>Info:</strong>
							<span>{t("go_component.import_csv.field.file_info.title", { ns: "lib" })}</span>
						</div>
					</Alert>
				</>
			)}
			{stepNo === 2 && (
				<div className={"d-flex flex-column"}>
					<AttributesTable
						loading={loading}
						customFieldsConfig={customFieldsConfig}
						attributes={attributes}
						handleImport={(data) => handleImport(data)}
						redirectionUrl={redirectionUrl}
						databaseColumnName={databaseColumnName}
					/>
				</div>
			)}
			{stepNo === 3 && (
				<div className={"d-flex flex-column"}>
					<ImportConfirm
						parentResponseData={response}
						parentErrors={errors}
						selectedFile={selectedFile}
						attributes={attributes}
						resourceType={resourceType}
						customFieldsConfig={customFieldsConfig}
						importCsvRequestParams={importCsvRequestParams}
						redirectionUrl={redirectionUrl}
						comparationCheck={comparationCheck}
						renderComparedValue={renderComparedValue}
						databaseColumnName={databaseColumnName}
						constraints={constraints}
						customAttributesCompare={customAttributesCompare}
						workingInBackground={workingInBackground}
						importPageRef={mainDivRef}
					/>
				</div>
			)}
		</div>
	);
};
export default ImportCsv;

// t("common.action.import_from_csv", { ns: "lib" })
