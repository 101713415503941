import React, { FC, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import {
	getAcceptanceTimeFromNumberOfSeconds,
	getNumberOfSecondsFromAcceptanceTime,
	parseApplicationSettings,
	parseApplicationSettingsValues,
	parseStringToList,
} from "../../../utils/utils";
import { AppState } from "../AppModal";
import { GoOrderAcceptanceTimeSetting } from "./GoOrderAcceptanceTimeSetting";
import GoOrderMenuErrors from "./GoOrderMenuErrors";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
	fetchApplication: () => void;
}

const getPriceListIdDefaultValue = (
	appState: AppState,
	updatedConfig?: Record<string, any>,
	isUseFormInitialValue = false
): Record<string, any> | Record<string, any>[] => {
	const nonMultiInputDefaulValue = isUseFormInitialValue
		? appState.app.settings.price_list_id
		: {
				label: appState?.config?.price_list_name,
				id: appState.app.settings.price_list_id,
		  };

	return appState.app.settings.send_menu_based_on_price_list === "true"
		? updatedConfig?.price_lists?.map((priceList: Record<string, any>) => ({
				label: priceList.name,
				id: priceList.id,
		  }))
		: nonMultiInputDefaulValue;
};

const GoOrderFormModalCard: FC<Props> = ({ appState, handleUpdateApp, fetchApplication }) => {
	const { t } = useTranslation();
	const [updatedConfig, setUpdatedConfig] = useState<Record<string, any> | undefined>(appState.config);
	const [menuErrors, setMenuErrors] = useState<ApiError[]>([]);
	const { addFlash, addSuccessFlash } = useFlash();
	const formDefaultValues = {
		...appState.app,
		settings: {
			...appState.app.settings,
			...parseApplicationSettingsValues(appState.app.settings),
			custom_fields_to_sync: parseStringToList(
				appState.app.settings,
				updatedConfig || {},
				"custom_fields_to_sync",
				"custom_fields"
			)?.map((cf) => ({
				label: cf?.label ?? cf?.name,
				id: cf?.slug,
			})),
			notification_terminal: parseStringToList(
				appState.app.settings,
				updatedConfig || {},
				"notification_terminal",
				"notification_terminals"
			)?.map((terminal) => ({
				label: terminal?.name,
				id: terminal?.id,
			})),
			external_store_id: parseStringToList(
				appState.app.settings,
				updatedConfig || {},
				"external_store_id",
				"stores"
			)?.map((store) => ({
				label: store?.name,
				id: store?.id,
			})),
			time_unit: getAcceptanceTimeFromNumberOfSeconds(appState.app.settings.order_acceptance_time_seconds)
				?.timeUnit,
			number_of_time_units: getAcceptanceTimeFromNumberOfSeconds(
				appState.app.settings.order_acceptance_time_seconds
			)?.numberOfTimeUnits,
		} as Record<string, any>,
	};

	formDefaultValues.settings.price_list_id = getPriceListIdDefaultValue(appState, updatedConfig, true);

	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: formDefaultValues,
	});
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		formState,
		setError,
		control,
	} = form;
	const settings = watch("settings");
	const [loading, setLoading] = useState(false);
	const [menuSending, setMenuSending] = useState(false);
	const menuSendingIntervalRef = useRef<number | undefined>();
	const cancelTokenSource = useRef<CancelTokenSource>();
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	useEffect(() => {
		return () => {
			if (cancelTokenSource.current !== undefined) {
				cancelTokenSource.current.cancel();
			}
			window.clearTimeout(menuSendingIntervalRef.current);
		};
	}, []);

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		const params: Record<string, any> = { include: "settings" };
		const settings = parseApplicationSettings(data.settings);
		const order_acceptance_time_seconds = getNumberOfSecondsFromAcceptanceTime(
			settings.number_of_time_units,
			settings.time_unit
		);

		delete settings.time_unit;
		delete settings.number_of_time_units;

		const { price_list_id } = settings;
		let priceListIdValue: string = "";

		if (Array.isArray(price_list_id)) {
			priceListIdValue = price_list_id.map((priceListId) => priceListId.id).join(",");
		} else if (typeof price_list_id === "string") {
			priceListIdValue = price_list_id;
		}

		const newData = {
			name: data.name,
			id: appState.app.id,
			settings: {
				...settings,
				order_acceptance_time_seconds,
				price_list_id: priceListIdValue,
			},
		};

		try {
			const res = await api.organization().updateApp(newData, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));

			handleUpdateApp(res);
			reset(watch());
			setValue("settings.image_tag", res.settings.image_tag);
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const searchMenus = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getMenusSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchStores = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getGoOrderStoresSearchSelect(appState.app.id, params, search, {
			cancelToken: options?.token,
		});
	};

	const searchTerminals = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getTerminalsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchItems = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getItemsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchPriceLists = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPriceListsSearchSelect(search, params, undefined, {
			cancelToken: options?.token,
		});
	};

	const searchAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchCustomFields = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"resource_type|e": "ORDER",
		};
		return api.organization().getCustomFieldsSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	const searchImageTags = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"resource_type|e": "MENU_IMAGE",
		};
		return api.organization().getTagsSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	const searchPointOfSales = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPointsOfSaleSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const handleSendingMenuTimeout = (jobId: string) => {
		menuSendingIntervalRef.current = window.setTimeout(async () => {
			try {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;

				const res = await api.organization().getExportMenuProgress(appState.app.id, jobId, {
					cancelToken: newCancelToken.token,
				});
				if (res.job_status === "NEW" || res.job_status === "STARTED") {
					setMenuSending(true);
					handleSendingMenuTimeout(res.job_uid);
				} else {
					setMenuSending(false);
					fetchApplication();
					window.clearTimeout(menuSendingIntervalRef.current);
					setMenuErrors([]);
					addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
				}
			} catch (err) {
				const errs = handleException(err);
				if (errs[0].message === "canceled") return;
				handleError.form(err, setError, addFlash);
				setMenuErrors(handleException(err));
				setMenuSending(false);
				window.clearTimeout(menuSendingIntervalRef.current);
			}
		}, 1000);
	};

	const sendMenu = async () => {
		setMenuSending(true);
		const params: Record<string, any> = { include: "settings" };
		try {
			const CancelToken = axios.CancelToken;
			const newCancelToken = CancelToken.source();
			cancelTokenSource.current = newCancelToken;
			const res = await api.organization().exportMenu(appState.app.id, params, {
				cancelToken: newCancelToken.token,
			});
			handleSendingMenuTimeout(res);
		} catch (err) {
			const errs = handleException(err);
			if (errs[0].message === "canceled") return;
			handleError.form(err, setError, addFlash);
			setMenuSending(false);
		}
	};

	const defaultStores = parseStringToList(
		appState.app.settings,
		updatedConfig || {},
		"external_store_id",
		"stores"
	)?.map((store) => ({
		label: store?.name,
		id: store?.id,
	}));

	const defaultTerminals = parseStringToList(
		appState.app.settings,
		updatedConfig || {},
		"notification_terminal",
		"notification_terminals"
	)?.map((terminal) => ({
		label: terminal?.name,
		id: terminal?.id,
	}));
	const updateConfig = (prop: string, obj?: any) => {
		let newObj;
		if (Array.isArray(obj)) {
			newObj = obj.map((o: Record<string, any>) => {
				return {
					name: o.name,
					id: o.id,
				};
			});
		} else {
			newObj = obj !== undefined ? { ...obj } : obj;
		}

		const cnf = { ...updatedConfig };
		if (prop === "stores") {
			cnf.stores = newObj;
		}
		if (prop === "notification_terminals") {
			cnf.notification_terminals = newObj;
		}
		if (prop === "menu_name") {
			cnf.menu_name = newObj?.label;
		}
		if (prop === "payment_method_name") {
			cnf.payment_method_name = newObj?.label;
		}

		setUpdatedConfig(cnf);
	};
	const organization = useSelector(selectOrganization);

	const defaultCustomFields = parseStringToList(
		appState.app.settings,
		updatedConfig || {},
		"custom_fields_to_sync",
		"custom_fields"
	)?.map((cf) => ({
		label: cf?.label ?? cf?.name,
		value: cf?.slug,
	}));

	const acceptTypes = [
		{ value: "MANUAL", label: t("enums.apps.goorder.accept_type.DEFAULT") },
		{ value: "AUTO_ACCEPT", label: t("enums.apps.goorder.accept_type.AUTO_ACCEPT") },
		{ value: "AUTO_CLOSE", label: t("enums.apps.goorder.accept_type.AUTO_CLOSE") },
	];

	const sendMenuBasedOnPriceList = watch("settings.send_menu_based_on_price_list");
	const priceListIdDefaultValues = getPriceListIdDefaultValue(appState, updatedConfig);

	const sendMenuBasedOnPriceListClickHandler = () => setValue("settings.price_list_id", "");

	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} onSubmit={onSubmit} className={"app-form"}>
					<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
					<div className="my-3">
						<span className="font-weight-bold d-block mt-3 mb-1">
							{t("modules.app.action.menu_send.title")}
						</span>
						<FormSelectGroup
							name="settings.menu_id"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							label={t("common.word.menu")}
							errors={errors}
							defaultValue={{ label: updatedConfig?.menu_name, id: appState.app.settings.menu_id }}
							loadOptions={searchMenus}
							control={control}
							onChange={(_, fullObj) => updateConfig("menu_name", fullObj)}
							data-testid="settings.menu_id"
						/>
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.app.field.send_menu_based_on_price_list.title")}
							name="settings.send_menu_based_on_price_list"
							errors={errors}
							onClick={sendMenuBasedOnPriceListClickHandler}
							help={t("modules.app.field.send_menu_based_on_price_list.help_text.title")}
						/>
						<FormSelectGroup
							name="settings.price_list_id"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							loadOptions={searchPriceLists}
							label={t("common.word.price_list")}
							errors={errors}
							control={control}
							data-testid="settings.price_list_id"
							isMulti={sendMenuBasedOnPriceList}
							defaultValue={priceListIdDefaultValues}
							help={t("modules.app.field.price_list_id.help_text.title")}
						/>
						<FormSelectGroup
							name="settings.availability_id"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							label={t("common.word.availability")}
							errors={errors}
							defaultValue={{
								label: appState?.config?.availability_name,
								id: appState.app.settings.availability_id,
							}}
							loadOptions={searchAvailabilities}
							control={control}
							data-testid="settings.availability_id"
						/>
					</div>
					<div className="my-3">
						<span className="font-weight-bold d-block mt-3 mb-1">
							{t("modules.app.field.store_configuration.title")}
						</span>
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.app.action.overwrite_all_configurations.title")}
							help={t("modules.app.action.overwrite_all_configurations.help_text.title")}
							name="settings.overwrite_all_configurations"
							errors={errors}
						/>
						{!watch("settings.overwrite_all_configurations") && (
							<>
								<FormCheck
									type="switch"
									register={register}
									label={t("modules.app.action.export_company_info.title")}
									name="settings.export_company_info"
									errors={errors}
								/>
								<FormCheck
									type="switch"
									register={register}
									label={t("modules.app.action.export_availabilities.title")}
									name="settings.export_availabilities"
									errors={errors}
								/>
								<FormCheck
									type="switch"
									register={register}
									label={t("modules.app.action.export_store_configuration.title")}
									name="settings.export_store_configuration"
									errors={errors}
								/>
								<FormCheck
									type="switch"
									register={register}
									label={t("modules.app.action.export_menu_discounts.title")}
									name="settings.export_menu_discounts"
									errors={errors}
								/>
							</>
						)}
						<FormSelectGroup
							name="settings.payment_method_id"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							path={`/${organization.id}/settings/payment_methods/`}
							label={t("modules.app.field.default_payment_method.title")}
							errors={errors}
							defaultValue={{
								label: updatedConfig?.payment_method_name,
								id: appState.app.settings.payment_method_id,
							}}
							loadOptions={searchPaymentMethods}
							control={control}
							onChange={(_, fullObj) => updateConfig("payment_method_name", fullObj)}
							data-testid="settings.payment_method_id"
						/>
						<FormSelectGroup
							name="settings.delivery_item_id"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							label={t("modules.app.field.delivery_item_id.title")}
							errors={errors}
							defaultValue={{
								label: appState.config?.delivery_item_name,
								id: appState.app.settings.delivery_item_id,
							}}
							loadOptions={searchItems}
							control={control}
							data-testid="settings.delivery_item_id"
						/>
						{appState.showPointsOfSale && (
							<FormSelectGroup
								name="settings.point_of_sale_id"
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								label={t("common.word.point_of_sale")}
								errors={errors}
								defaultValue={{
									label: appState.config?.point_of_sale_name,
									id: appState.app.settings.point_of_sale_id,
								}}
								loadOptions={searchPointOfSales}
								control={control}
								data-testid="settings.point_of_sale_id"
							/>
						)}
					</div>
					<div className="my-3">
						<span className="font-weight-bold d-block mt-3 mb-1">
							{t("modules.app.field.synchronization.title")}
						</span>
						<Form.Group className="form-group">
							<FormCheck
								type={"switch"}
								register={register}
								label={t("modules.app.field.auto_update_menu_after_changes.title")}
								name="settings.auto_update_menu_after_changes"
								errors={errors}
							/>
							<ButtonLoading loading={menuSending} variant={"add"} onClick={() => sendMenu()}>
								{t("modules.app.action.send_menu.title")}
							</ButtonLoading>
						</Form.Group>
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.app.field.send_menu_to_go_hub.title")}
							name="settings.send_menu_to_go_hub"
							errors={errors}
						/>
					</div>
					<div className="my-3">
						<span className="font-weight-bold d-block mt-3 mb-1">
							{t("common.word.advanced", { ns: "lib" })}
						</span>
						{appState.config?.stores && (
							<FormSelectGroup
								isMulti={true}
								name="settings.external_store_id"
								getOptionLabel={(option) => option.label}
								onChange={(_, fullObj) => updateConfig("stores", fullObj)}
								getOptionValue={(option) => option.id}
								label={t("modules.app.field.linked_stores.title")}
								errors={errors}
								defaultValue={defaultStores}
								loadOptions={searchStores}
								control={control}
								data-testid="settings.external_store_id"
							/>
						)}
						<FormSelectGroup
							isMulti={true}
							name="settings.notification_terminal"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.id}
							onChange={(_, fullObj) => updateConfig("notification_terminals", fullObj)}
							label={t("modules.app.field.default_terminals.title")}
							errors={errors}
							defaultValue={defaultTerminals}
							loadOptions={searchTerminals}
							control={control}
							data-testid="settings.notification_terminal"
						/>
						<FormSelectGroup
							label={t("modules.app.field.accept_type.title")}
							name="settings.order_accept_type"
							placeholder={t("enums.apps.goorder.accept_type.DEFAULT")}
							defaultValue={acceptTypes.find(
								(acceptType) => acceptType.value === appState.app.settings.order_accept_type
							)}
							errors={errors}
							options={acceptTypes}
							control={control}
							data-testid="settings.order_accept_type"
						/>
						<FormSelectGroup
							name="settings.image_tag"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.label}
							defaultValue={{ id: settings.image_tag, label: settings.image_tag }}
							label={t("modules.app.field.image_tag.title")}
							help={t("modules.app.field.image_tag.helptext.description")}
							errors={errors}
							loadOptions={searchImageTags}
							control={control}
							data-testid="settings.image_tag"
						/>
						<FormSelectGroup
							isMulti
							name="settings.custom_fields_to_sync"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.slug}
							label={t("modules.app.action.custom_fields_sync.title")}
							errors={errors}
							defaultValue={defaultCustomFields}
							loadOptions={searchCustomFields}
							control={control}
							data-testid="settings.custom_fields_to_sync"
						/>
						<GoOrderAcceptanceTimeSetting form={form} setErrors={setErrors} />
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.app.action.auto_link_by_name.title")}
							name="settings.auto_link_by_name"
							errors={errors}
						/>
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.app.field.external_delivery.title")}
							name="settings.external_delivery"
							errors={errors}
						/>
						<FormCheck
							type="switch"
							register={register}
							label={t("modules.app.field.item_multi_level.title")}
							name="settings.item_multi_level"
							errors={errors}
						/>
					</div>
					<GoOrderMenuErrors menuErrors={menuErrors} />
					<ButtonLoading loading={loading} variant={"add"} onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};
export default GoOrderFormModalCard;
