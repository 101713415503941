import React from "react";
import { ReportComboContext, ReportComboContextProps, ReportComboService } from "go-report-combo/services/context";
import { ReportResponseReportComboApi } from "go-report-combo/services/types";
import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { reportParamsConverter } from "go-report/reports/services/report-service";
import { apiReport } from "../../../../../../services/Api/Report/apiReport";
import OrganizationReportComboComponent from "./components/OrganizationReportComboComponent";
import UserReportsComboCreatePage from "./pages/Create";
import UserReportsComboSegmentsPage from "./segments";

const UserReportsComboPage = (props: RouteComponentProps) => {
	const { t } = useTranslation("admin");

	const get = async (resource: string, dataId: string, dataColumnId: string, params: Record<string, string>) => {
		switch (resource) {
			case "BILL": {
				const newParams = JSON.parse(JSON.stringify(params));
				const res = (await apiReport.getReportsCustom(reportParamsConverter(newParams))) as any;
				return {
					id: dataId,
					column_id: dataColumnId,
					data: res.data.reports[0],
				} as ReportResponseReportComboApi;
			}
		}
	};

	const getPath = (resource: string, filter: string) => {
		switch (resource) {
			case "BILL": {
				if (filter.includes("order_item")) {
					return "/group_reports/products";
				}
				return "/group_reports/orders";
			}
		}
		return undefined;
	};

	const getFilterVariableComponent = (
		variableId: string,
		updateFilters: (value: Record<string, string>) => void,
		filter?: Record<string, string>
	) => {
		const filterType = variableId.split("__")[1];
		switch (filterType) {
			case "ORGANIZATION": {
				return (
					<>
						<OrganizationReportComboComponent
							name={variableId}
							filter={filter}
							onChange={(value) => updateFilters(value)}
						/>
						<hr />
					</>
				);
			}
		}

		return undefined;
	};

	return (
		<ReportComboContext.Provider
			value={
				new ReportComboService(
					{
						get,
						getPath,
						getFilterVariableComponent,
					} as ReportComboContextProps,
					t
				)
			}
		>
			<div className={"page-container"}>
				<div className={"content"}>
					<Switch>
						<Route exact path={`${props.match.url}/create`} component={UserReportsComboCreatePage} />
						<Route path={`${props.match.url}/`} component={UserReportsComboSegmentsPage} />
					</Switch>
				</div>
			</div>
		</ReportComboContext.Provider>
	);
};

export default UserReportsComboPage;
