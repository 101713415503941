import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { formatDateToString } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { api } from "../../../../../../../../../../services/Api/api";
import { JPKInvoiceType } from "../../../../services/types";
import { useInvoice } from "../../hooks/useInvoice";

interface JPKInvoiceApi {
	date_from: string;
	date_to: string;
}

const today = new Date();
const dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const dateTo = new Date(today.getFullYear(), today.getMonth(), 0);

const OrganizationInvoicesJpkIndexPage = (): JSX.Element => {
	const { loading, setLoading, downloadJpk, previousInvoiceData } = useInvoice(JPKInvoiceType.JPK);
	const form = useForm<JPKInvoiceApi>({
		criteriaMode: "all",
	});
	const {
		control,
		formState: { errors },
		setError,
		reset,
		formState,
		handleSubmit,
		register,
	} = form;
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.field.jpk.title"));
	}, []);

	useEffect(() => {
		if (previousInvoiceData) {
			reset({
				...previousInvoiceData,
				date_from: formatDateToString(dateFrom),
				date_to: formatDateToString(dateTo),
			});
		}
	}, [reset, previousInvoiceData]);

	const onSubmit = handleSubmit(async (data: JPKInvoiceApi) => {
		try {
			setLoading(true);
			const res = await api.organization().generateJpk(data);
			downloadJpk(res, data);
			reset(data);
		} catch (e) {
			handleError.form(e, setError, addFlash);
		} finally {
			setLoading(false);
		}
	});

	if (!previousInvoiceData) {
		return <LoadingContainer />;
	}

	return (
		<FormDirty
			loading={loading}
			onSubmit={onSubmit}
			formState={formState}
			buttonSubmitCustom={
				<ButtonLoading variant={"primary"} type={"submit"} loading={loading}>
					{t("modules.invoice.action.generate_jpk.title")}
				</ButtonLoading>
			}
		>
			<div className="row">
				<div className="col-md-6">
					<FormDatePicker
						allowClear={false}
						label={t("common.word.start_date", { ns: "lib" })}
						control={control}
						name="date_from"
						errors={errors}
						timePicker={false}
					/>
				</div>
				<div className="col-md-6">
					<FormDatePicker
						allowClear={false}
						label={t("common.word.end_date", { ns: "lib" })}
						control={control}
						name="date_to"
						errors={errors}
						timePicker={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormInput
						name="full_name"
						label={t("common.word.full_company_name")}
						register={register}
						errors={errors}
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						name="tax_id_no"
						label={t("common.word.tax_id_no.title", { ns: "lib" })}
						register={register}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormInput
						name="email"
						label={t("common.word.contact_email")}
						register={register}
						errors={errors}
					/>
				</div>
			</div>
		</FormDirty>
	);
};

export default OrganizationInvoicesJpkIndexPage;
