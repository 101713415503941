import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { FormDirty } from "go-form/components/FormDirty";
import { RoomElementApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	element: RoomElementApi;
	onHide: () => void;
	handleUpdate: (element: RoomElementApi) => void;
}

const ElementEditModal: FC<Props> = ({ element, onHide, handleUpdate }) => {
	const form = useForm<RoomElementApi>({
		defaultValues: element,
	});
	const { t } = useTranslation();
	const {
		formState,
		formState: { errors },
		register,
		handleSubmit,
	} = form;

	const onSubmit = handleSubmit((data: RoomElementApi) => {
		handleUpdate(data);
	});

	return (
		<Modal show={true} onHide={onHide}>
			<FormDirty formState={formState} noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.room.field.room_element.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						name={"name"}
						errors={errors}
						register={register}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</Button>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default ElementEditModal;
