import React from "react";
import { Link } from "react-router-dom";
import EntityStatus from "go-list/core/components/Table/components/EntityStatus";
import { getRemValueInPixels } from "../utils/domUtils";
import RenderLimitedText from "./RenderLimitedText";

interface Props extends Partial<WithLinkAndLimitedWidthProps> {
	status?: string;
	children: React.ReactNode;
}

interface WithLinkProps {
	to: string;
	children: React.ReactNode;
	shouldOpenInNewTab?: boolean;
}

interface WithLinkAndLimitedWidthProps extends WithLinkProps {
	minWidth: number;
	offsetWidth?: number;
}

const LinkForEntityStatus = ({ to, children, shouldOpenInNewTab }: WithLinkProps) => {
	return (
		<Link
			rel={shouldOpenInNewTab ? "noreferrer" : undefined}
			target={shouldOpenInNewTab ? "_blank" : undefined}
			to={to}
			style={{ lineHeight: "100%" }}
		>
			{children}
		</Link>
	);
};

const LinkAndWidthForEntityStatus = ({
	to,
	minWidth,
	children,
	offsetWidth,
	shouldOpenInNewTab,
}: WithLinkAndLimitedWidthProps) => {
	return (
		<RenderLimitedText minWidth={minWidth} offsetWidth={offsetWidth || getRemValueInPixels(0.5)}>
			<LinkForEntityStatus to={to} shouldOpenInNewTab={shouldOpenInNewTab}>
				{children}
			</LinkForEntityStatus>
		</RenderLimitedText>
	);
};

export const StickyColumnWithEntityStatus = ({
	status,
	children,
	to,
	minWidth,
	offsetWidth,
	shouldOpenInNewTab,
}: Props): JSX.Element => {
	let content;

	if (to && !minWidth)
		content = (
			<LinkForEntityStatus to={to} shouldOpenInNewTab={shouldOpenInNewTab}>
				{children}
			</LinkForEntityStatus>
		);
	if (to && minWidth)
		content = (
			<LinkAndWidthForEntityStatus
				to={to}
				minWidth={minWidth}
				offsetWidth={offsetWidth}
				shouldOpenInNewTab={shouldOpenInNewTab}
			>
				{children}
			</LinkAndWidthForEntityStatus>
		);

	return (
		<div className="d-flex align-items-center h-100">
			<div className="d-flex align-items-center">
				<EntityStatus status={status} />
			</div>
			{content || <div className="d-flex align-items-center">{children}</div>}
		</div>
	);
};
