import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { FormatDate, FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { getRemValueInPixels } from "go-core/utils/domUtils";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { PosReportApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import { PosReportsBackendAcceptableParams } from "../services/types";

const getExportParams = (params: Record<string, any>) => {
	const backendAcceptableParams = Object.keys(PosReportsBackendAcceptableParams);

	const newColumnsArr = params.columns.split(",");
	const paramsToBeSent = newColumnsArr.reduce((modifiedParams: string[], param: string) => {
		if (param === "OPENED_CLOSED_AT")
			return [
				...modifiedParams,
				PosReportsBackendAcceptableParams.OPENED_AT,
				PosReportsBackendAcceptableParams.CLOSED_AT,
				PosReportsBackendAcceptableParams.ID,
			];

		const isParamSupported = backendAcceptableParams.some(
			(backendAcceptableParam) => backendAcceptableParam === param
		);

		return isParamSupported ? [...modifiedParams, param] : modifiedParams;
	}, []);

	return { ...params, columns: paramsToBeSent.join(",") };
};

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "POS_REPORT";
const resourceType = "POS_REPORT";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<PosReportApi[]>([]);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "opened_closed_at",
				name: t("modules.pos_report.field.opened_closed_at.title"),
				render: (item: PosReportApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(
								item.status === "OPENED" ? `OPENED_BLUE` : item.status
							)}
							to={`${location.pathname}/${item.id}`}
							minWidth={200}
							offsetWidth={16 + getRemValueInPixels(0.5)}
						>
							<span>#{item.id}&nbsp;|&nbsp;</span>
							{item.status === "CLOSED"
								? FormatDateToDateHoursRange(item.opened_at, item.closed_at)
								: FormatDate(item.opened_at)}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: PosReportApi) => {
					return `#${item.id} | ${
						item.status === "CLOSED"
							? FormatDateToDateHoursRange(item.opened_at, item.closed_at)
							: FormatDate(item.opened_at)
					}`;
				},
			},
			{
				id: "type",
				type: "list",
				options: {
					SHIFTWORK: t("enums.pos_reports.type.SHIFTWORK"),
					DRAWER: t("enums.pos_reports.type.DRAWER"),
				},
				name: t("modules.pos_report.field.type.title"),
				render: (item: PosReportApi) => {
					return t(`enums.pos_reports.type.${item.type}`);
				},
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				render: (item: PosReportApi) => <FormatResourceStatus status={item.status} />,
				renderExport: (item: PosReportApi) => item.status,
			},
			{
				id: "terminal",
				type: "search_select",
				name: t("common.word.terminal"),
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTerminalsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
				render: (item: PosReportApi) => (
					<Link
						to={`/${organization.id}/settings/terminals/${item?.terminal?.id}`}
						rel="noreferrer"
						target="_blank"
					>
						{item?.terminal?.name}
					</Link>
				),
			},
			{
				id: "description",
				type: "text",
				name: t("lib:common.word.description"),
				render: (item: PosReportApi) => (
					<RenderLimitedText minWidth={200}>{item?.description}</RenderLimitedText>
				),
				renderExport: (item: PosReportApi) => item.description,
			},
			{
				id: "income_amount",
				type: "number",
				name: t("common.word.total_money"),
				render: (item: PosReportApi) => {
					return FormatMoney(item.income_amount);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
		],
		filters: [
			{
				id: "id",
				name: t("modules.message_event.field.id.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: CancelTokenSource) =>
						api.organization().getPosReportsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: PosReportApi) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at
						)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "status",
				type: "list",
				name: t("lib:common.word.status"),
				options: {
					OPENED: t("enums.common.status.OPENED"),
					CLOSED: t("enums.common.status.CLOSED"),
				},
			},
			{
				id: "opened_at",
				type: "date",
				name: t("modules.pos_report.field.opened_at.title"),
			},
			{
				id: "closed_at",
				type: "date",
				name: t("modules.pos_report.field.closed_At.title"),
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: PosReportApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
		],
		nested_config: {
			field: "sub_reports",
		},
		selectedColumns: ["opened_closed_at", "type", "status", "terminal", "description", "income_amount"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.pos_report.field.export_config.title"),
			filename: t("modules.pos_report.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			customExportRequests: {
				pdfFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns:
							params?.columns || "OPENED_AT,CLOSED_AT,ID,TYPE,STATUS,TERMINAL,DESCRIPTION,INCOME_AMOUNT",
					};
					return api.organization().getPosReportPDF(getExportParams(newParams));
				},
				csvFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns:
							params?.columns || "OPENED_AT,CLOSED_AT,ID,TYPE,STATUS,TERMINAL,DESCRIPTION,INCOME_AMOUNT",
					};
					return api.organization().getPosReportCSV(getExportParams(newParams));
				},
			},
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "sub_reports";
			return api.organization().getPosReports(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={
				<EmptyList
					title={t("modules.pos_report.header.title")}
					description={t("modules.pos_report.field.empty_list_description.title")}
				/>
			}
			mobileActions={mobileActions}
			onFetch={(fetchItems: PosReportApi[]) => setItems(fetchItems)}
		/>
	);
};

const OrganizationSalesPosReportsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const hasReportsPermission = !!hasPermission("VENUE_REPORTS_SHOW");
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.pos_report.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [];
	if (hasReportsPermission)
		buttons.push({
			title: t("modules.pos_report.action.external_reports.title"),
			path: `${props.match.url}/external_reports`,
			dropdown: true,
		});
	const mobileActions: MobileActionProps[] = [
		{
			title: t("modules.pos_report.action.external_reports.title"),
			path: `${props.match.url}/external_reports`,
			hide: !hasReportsPermission,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.pos_report.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationSalesPosReportsIndexPage;
