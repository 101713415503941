import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { OrderApi, TransactionModel } from "../../../../../../../../../services/Api/Organization/types";
import LastTransactionsTable from "../../../../../components/Transaction/LastTransactionsTable";

export interface Props {
	order: OrderApi;
	fetchOrder: () => void;
}

const TransactionsCard = ({ order, fetchOrder }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [showTransaction, setShowTransaction] = useState<null | number>(null);

	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.order.field.transactions.title")}</h5>
				<LastTransactionsTable
					transactions={order.transactions as unknown as TransactionModel[]}
					showTransaction={showTransaction}
					fetchOrder={fetchOrder}
					setShowTransaction={setShowTransaction}
					tableBodyChildren={
						<tr className={"summary"}>
							<td />
							<td>
								<strong>{t("common.word.sum")}</strong>
							</td>
							<td className={"nowrap"}>
								<strong>{FormatMoney(order.total_price)}</strong>
							</td>
						</tr>
					}
				/>
			</Card.Body>
		</Card>
	);
};
export default TransactionsCard;
