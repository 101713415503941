import React, { FC, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { useConfirmation } from "go-form/components/ModalConfirm";
import AccessTokenSessionService from "go-security/services/accessTokenSessionService";
import FormatResourceStatus from "../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as ErrorSVG } from "../../../../../../../../images/svg/apps/app_error.svg";
import { ReactComponent as MoreSvg } from "../../../../../../../../images/svg/more.svg";
import {
	ApplicationApi,
	ApplicationError,
	ApplicationTemplateApi,
} from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import AppStatus from "../AppStatus";
import { renderAppIcon } from "./AppCard";

interface InstalledAppProps {
	configApp: ApplicationTemplateApi;
	installedApp: ApplicationApi;
	handleUpdate?: (app: ApplicationApi) => void;
	routeProps?: RouteComponentProps;
	handleUninstall?: (app: ApplicationApi) => void;
}

const AppCardInstalled: FC<InstalledAppProps> = ({
	configApp,
	installedApp,
	handleUpdate,
	routeProps,
	handleUninstall,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const confirmation = useConfirmation();
	const { addSuccessFlash, addFlash } = useFlash();
	const [syncApplicationLoading, setSyncApplicationLoading] = useState(false);
	const [sendMenuLoading, setSendMenuLoading] = useState(false);
	const [enableApplicationLoading, setEnableApplicationLoading] = useState(false);
	const menuSendingIntervalRef = useRef<number | undefined>();
	const cancelTokenSource = useRef<CancelTokenSource>();
	const [app, setApp] = useState<ApplicationApi>(installedApp);
	const params = { include: "settings" };

	useEffect(() => {
		return () => {
			if (cancelTokenSource.current !== undefined) {
				cancelTokenSource.current.cancel();
			}
			window.clearTimeout(menuSendingIntervalRef.current);
		};
	}, []);

	const uninstallApp = async () => {
		if (handleUninstall) {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			try {
				await api.organization().removeApp(app);
				addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
				handleUninstall(app);
			} catch (e) {
				handleError.alert(e, addFlash);
			}
		}
	};

	const fetchApp = async () => {
		try {
			const CancelToken = axios.CancelToken;
			const newCancelToken = CancelToken.source();
			cancelTokenSource.current = newCancelToken;
			const res = await api.organization().getApp(
				app.id,
				{ include: "settings" },
				{
					cancelToken: newCancelToken.token,
				}
			);
			setApp(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleSendingMenuTimeout = (jobId: string) => {
		menuSendingIntervalRef.current = window.setTimeout(async () => {
			try {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;

				const res = await api.organization().getExportMenuProgress(app.id, jobId, {
					cancelToken: newCancelToken.token,
				});
				if (res.job_status === "NEW" || res.job_status === "STARTED") {
					setSendMenuLoading(true);
					handleSendingMenuTimeout(res.job_uid);
				} else {
					setSendMenuLoading(false);
					fetchApp();
					window.clearTimeout(menuSendingIntervalRef.current);
					addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
				}
			} catch (err) {
				const errs = handleException(err);
				if (errs[0].message === "canceled") return;
				handleError.alert(err, addFlash);
				setSendMenuLoading(false);
				window.clearTimeout(menuSendingIntervalRef.current);
			}
		}, 1000);
	};

	const sendMenu = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setSendMenuLoading(true);
		try {
			const CancelToken = axios.CancelToken;
			const newCancelToken = CancelToken.source();
			cancelTokenSource.current = newCancelToken;
			const res = await api.organization().exportMenu(app.id, params, {
				cancelToken: newCancelToken.token,
			});
			handleSendingMenuTimeout(res);
		} catch (err) {
			const errs = handleException(err);
			if (errs[0].message === "canceled") return;
			handleError.alert(err, addFlash);
			setSendMenuLoading(false);
		}
	};

	const syncApplication = async (evt: React.MouseEvent) => {
		evt.stopPropagation();
		setSyncApplicationLoading(true);
		try {
			await api.organization().syncApp(app);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		setSyncApplicationLoading(false);
	};

	const enableApplication = async (evt: React.MouseEvent) => {
		evt.stopPropagation();
		setEnableApplicationLoading(true);
		try {
			const res = await api.organization().enableApp(app, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setApp(res);
			if (handleUpdate) {
				handleUpdate(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		setEnableApplicationLoading(false);
	};

	const reinstallApplication = (evt: React.MouseEvent, app: ApplicationApi) => {
		evt.stopPropagation();
		const accessToken = AccessTokenSessionService.get();
		api.organization().reinstallApplication(app, accessToken ? accessToken : "");
	};

	const renderDescription = () => {
		let color = "#6c757d";
		let desc: string | ApplicationError[] = configApp.short_description;
		if (app.dangers && app.dangers.filter((f) => f.code).length > 0) {
			color = "#FFC107";
			desc = app.dangers;
		}
		if (app.errors && app.errors.filter((f) => f.code).length > 0) {
			color = "#DC5345";
			desc = app.errors;
		}
		return (
			<span style={{ color }}>
				{Array.isArray(desc) ? (
					<div className={"d-flex flex-column"}>
						{desc.map((err, index) => {
							const translation = err.duplicate_value
								? t(`constraints.${err.code}`, { value: err.duplicate_value })
								: t(`constraints.${err.code}`);
							return <span key={index}>{translation}</span>;
						})}
					</div>
				) : (
					desc
				)}
			</span>
		);
	};

	return (
		<div className={"app-card"}>
			<div className={"app-card-header"}>
				{renderAppIcon(configApp)}
				<div className={"app-header-actions"}>
					{app.status === "ERROR" && <ErrorSVG className={"me-1"} />}
					<Dropdown as={ButtonGroup} onClick={(evt) => evt.stopPropagation()}>
						<Dropdown.Toggle as={MoreSvg} />
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => history.push(`${routeProps?.match.url}/${app.id}?edit`)}>
								{t("common.action.edit", { ns: "lib" })}
							</Dropdown.Item>
							<Dropdown.Item onClick={() => uninstallApp()}>
								{t("common.action.remove", { ns: "lib" })}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
			<div className={"app-card-body"}>
				<div className={"app-name-header flex-column"} style={{ marginBottom: "7.5px" }}>
					<div className={"d-flex align-items-center"}>
						<h5 className={"mb-0 me-2"}>{t(`enums.apps.providers.${configApp.provider}`)}</h5>
						<AppStatus className="me-2" status={app.status} />
						{app && app.status !== "ENABLED" && app.status !== "ERROR" && (
							<FormatResourceStatus status={app.status === "NEW" ? "INACTIVE_APP" : app.status} />
						)}
					</div>
					{app && (
						<>
							{app.link ? (
								<a href={app.link} target={"_blank"} rel="noreferrer">
									{app.name}
								</a>
							) : (
								<small className={"app-small"}>{app.name}</small>
							)}
						</>
					)}
				</div>
				{renderDescription()}
			</div>
			<div className={"app-card-footer"}>
				{app.status === "NEW" && (
					<div className={"footer-info"}>
						<ButtonLoading
							onClick={(evt) => enableApplication(evt)}
							loading={enableApplicationLoading}
							variant={"primary"}
						>
							{t("modules.app.action.enable_application.title")}
						</ButtonLoading>
					</div>
				)}
				{app.status !== "NEW" && (
					<>
						{app.provider === "GOORDER" && (
							<>
								{app.last_export_menu && (
									<div className={"footer-info"}>
										<span className={"text-muted"}>
											{t("modules.app.field.last_send_at.title")}
										</span>
										<span className={"action-ref"}>{FormatDate(app.last_export_menu)}</span>
									</div>
								)}
								{app.status === "ENABLED" && (
									<ButtonLoading
										onClick={(evt) => sendMenu(evt)}
										loading={sendMenuLoading}
										variant={"primary"}
									>
										{t("modules.app.action.send_menu.title")}
									</ButtonLoading>
								)}
								{app.status === "ERROR" && (
									<ButtonLoading
										onClick={(evt) => reinstallApplication(evt, app)}
										loading={sendMenuLoading}
										variant={"add mb-0"}
									>
										{t("common.action.fix_error", { ns: "lib" })}
									</ButtonLoading>
								)}
							</>
						)}
						{app.provider === "LOCATIONS" && (
							<>
								{app.status === "ENABLED" && app.last_activity && (
									<div className={"footer-info"}>
										<span className={"text-muted"}>
											{t("modules.app.field.last_used_at.title")}
										</span>
										<span className={"action-ref"}>{FormatDate(app.last_activity)}</span>
									</div>
								)}
								{app.status === "ERROR" ? (
									<ButtonLoading
										onClick={(evt) => enableApplication(evt)}
										loading={sendMenuLoading}
										variant={"add mb-0"}
									>
										{t("common.action.fix_error", { ns: "lib" })}
									</ButtonLoading>
								) : (
									<ButtonLoading
										onClick={(evt) => syncApplication(evt)}
										loading={syncApplicationLoading}
										variant={"primary mb-0"}
									>
										{t("modules.app.action.sync.title")}
									</ButtonLoading>
								)}
							</>
						)}
						{app.provider !== "GOORDER" && app.provider !== "LOCATIONS" && (
							<>
								{app.last_activity && (
									<div className={"footer-info"}>
										<span className={"text-muted"}>
											{t("modules.app.field.last_used_at.title")}
										</span>
										<span className={"action-ref"}>{FormatDate(app.last_activity)}</span>
									</div>
								)}
								{app.status === "ERROR" ? (
									<ButtonLoading
										onClick={(evt) => reinstallApplication(evt, app)}
										loading={sendMenuLoading}
										variant={"add mb-0"}
									>
										{t("common.action.fix_error", { ns: "lib" })}
									</ButtonLoading>
								) : (
									<>
										{configApp.documentation_link ? (
											<a
												href={configApp.documentation_link}
												className={"btn btn-add mb-0"}
												target={"_blank"}
												rel="noreferrer"
											>
												{t("modules.app.field.docs.title")}
											</a>
										) : (
											<div className={"action-ref"}>{configApp.owner}</div>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};
export default AppCardInstalled;

// t("enums.common.status.INACTIVE_APP");
