import React, { FC, useState } from "react";
import { CancelTokenSource } from "axios";
import { Button, Card } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { ReactComponent as RemoveSVG } from "go-core/images/svg/actions/remove.svg";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../services/Api/types";
import {
	convertTerminalsFormDataToDataAcceptableByBackend,
	getRidOfReceivedTerminalApiKeysFromBackend,
	getTerminalsWithApiKeys,
	parseApplicationSettings,
	parseApplicationSettingsValues,
} from "../../../utils/utils";
import { AppState } from "../AppModal";

interface Props {
	appState: AppState;
	fetchApplication: (cancelToken?: CancelTokenSource) => void;
}

const HolidayPmsFormModalCard: FC<Props> = ({ appState, fetchApplication }) => {
	const [updatedConfig, setUpdatedConfig] = useState<Record<string, any> | undefined>(appState.config);
	const [loading, setLoading] = useState<boolean>(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings || {}),
				terminals: getTerminalsWithApiKeys(appState.app.settings, appState.config?.terminals),
			},
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setError,
		control,
		watch,
	} = form;
	const { fields, append, remove } = useFieldArray({ name: "settings.terminals", control });

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const convertedTerminalsData = convertTerminalsFormDataToDataAcceptableByBackend(data.settings.terminals);
		data.settings = getRidOfReceivedTerminalApiKeysFromBackend(data.settings);
		data.settings = { ...data.settings, ...convertedTerminalsData };
		delete data.settings.terminals;

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};
		const params: Record<string, any> = { include: "settings" };
		try {
			const res = await api.organization().updateApp(newData, params);
			await fetchApplication();
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const searchPaymentMethod = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const updateConfig = (field: string, obj?: SearchSelectApi) => {
		const cnf = { ...updatedConfig };
		if (field === "payment_method_name") {
			cnf.payment_method_name = obj?.label;
		}
		setUpdatedConfig(cnf);
	};

	const searchTerminals = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const alreadyUsedTerminals = watch("settings.terminals");
		const alreadyUsedTerminalUids = alreadyUsedTerminals?.reduce(
			(terminalUids: string, terminal: Record<string, any>) => {
				if (terminalUids === "") return terminal.TERMINAL_UID;
				return `${terminalUids}${FILTER_VALUE_SEPARATOR}${terminal.TERMINAL_UID}`;
			},
			""
		);
		return api
			.organization()
			.getTerminalsSearchSelect(search, params, { cancelToken: options?.token }, alreadyUsedTerminalUids);
	};

	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} onSubmit={onSubmit} className="holiday-pms-app-form">
					<FormSelectGroup
						name="settings.payment_method_id"
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						path={`/${organization.id}/settings/payment_methods/`}
						control={control}
						onChange={(_, fullObj) => updateConfig("payment_method_name", fullObj)}
						loadOptions={searchPaymentMethod}
						label={t("modules.app.field.default_payment_method.title")}
						defaultValue={{
							label: updatedConfig?.payment_method_name,
							id: appState.app?.settings?.payment_method_id,
						}}
						errors={errors}
						data-testid="settings.payment_method_id"
					/>
					<FormInput
						name="settings.api_key"
						errors={errors}
						register={register}
						label={t("modules.app.field.api_key.title")}
					/>
					<FormInput
						name="settings.resort_id"
						errors={errors}
						register={register}
						label={t("modules.app.field.resort_id.title")}
					/>
					<FormInput
						name="settings.url"
						errors={errors}
						register={register}
						label={t("modules.app.field.url.title")}
					/>
					<table className="table table-form">
						<thead>
							<tr>
								<th className="action" />
								<th>{t("common.word.terminal")}</th>
								<th>{t("modules.app.field.key.title")}</th>
								<th className="action" />
							</tr>
						</thead>
						<tbody>
							{fields.map((field: Record<string, any>, index) => {
								return (
									<tr key={field.id}>
										<td className="action" />
										<td className="w-50">
											<FormSelectGroup
												name={`settings.terminals.${index}.TERMINAL_UID`}
												errors={errors}
												control={control}
												loadOptions={searchTerminals}
												getOptionValue={(option) => option.uid}
												getOptionLabel={(option) => option.label}
												defaultValue={{
													uid: field.TERMINAL_UID,
													label: field.TERMINAL_NAME,
												}}
											/>
										</td>
										<td>
											<FormInput
												name={`settings.terminals.${index}.TERMINAL_KEY`}
												register={register}
												errors={errors}
											/>
										</td>
										<td className="action">
											<RemoveSVG onClick={() => remove(index)} className="icon" />
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<Button
						variant="add"
						className="d-block"
						onClick={() => append({ TERMINAL_UID: "", TERMINAL_KEY: "" })}
					>
						+ {t("common.action.add", { ns: "lib" })}
					</Button>
					<ButtonLoading loading={loading} variant="add" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};

export default HolidayPmsFormModalCard;
