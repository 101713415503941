import React, { useState } from "react";
import { Button, Card, Collapse } from "react-bootstrap";
import { FieldError, UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import EmptyList from "go-core/components/EmptyList";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../images/svg/remove.svg";
import {
	VenueSettingsApi,
	VenueSettingsKdsItemApi,
} from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	title: string;
	weightBarcodesConfig: string[];
}

const WeightedBarcodesCard = (props: Props): JSX.Element => {
	const {
		formState: { errors },
		control,
		register,
		clearErrors,
	} = props.form;
	const { fields, append, remove } = useFieldArray({
		control,
		name: "weight_barcodes",
		keyName: "key",
	});

	const { t } = useTranslation();
	const [collapseIn, setCollapseIn] = useState(false);

	const onCollapse = () => {
		const collapse = collapseIn;
		setCollapseIn(!collapse);
		const icon = document.getElementById("weighted-barcodes-icon");
		if (icon) {
			icon.style.transform = !collapse ? "rotate(180deg)" : "";
		}
	};
	return (
		<Card className={"venue-settings"}>
			<Card.Body>
				<div className={"title"} onClick={() => onCollapse()}>
					<h5>{props.title}</h5>
					<CollapseSVG id={"weighted-barcodes-icon"} className={"collapse-icon"} />
				</div>
				<Collapse in={collapseIn}>
					<div>
						<div className="d-flex flex-column" style={{ cursor: "pointer" }} onClick={() => onCollapse()}>
							<small className="text-muted">
								{t("modules.venue_settings.field.definition_of_weighted_code.title")}
							</small>
							<small className="text-muted ms-2">{t("modules.venue_settings.field.sku.title")}</small>
							<small className="text-muted ms-2">{t("modules.venue_settings.field.weight.title")}</small>
							<small className="text-muted ms-2">
								{t("modules.venue_settings.field.separator.title")}
							</small>
							<small className="text-muted ms-2">
								{t("modules.venue_settings.field.skipped_elements.title")}
							</small>
							<small className="text-muted mt-2">{t("modules.venue_settings.field.example.title")}</small>
							<small className="text-muted">
								{`20SSSSWWW.WWWX → 2012340011259 → ${t(
									"modules.venue_settings.field.product_with_sku.title",
									{ value: 1234 }
								)} ${t("modules.venue_settings.field.added_with.title", { value: 1.125 })}`}
							</small>
							<small className="text-muted">
								{`21SSSSSWWWWWX → 2100055000128 → ${t(
									"modules.venue_settings.field.product_with_sku.title",
									{ value: "00055" }
								)} ${t("modules.venue_settings.field.added_with.title", { value: 12 })}`}
							</small>
							<small className="text-muted">
								{`22SSSXXXWWWXX → 2212577720088 → ${t(
									"modules.venue_settings.field.product_with_sku.title",
									{ value: 125 }
								)} ${t("modules.venue_settings.field.added_with.title", { value: 200 })}`}
							</small>
							<small className="text-muted">
								{`23WW.WWWSSSSSX → 2314345013332 → ${t(
									"modules.venue_settings.field.product_with_sku.title",
									{ value: "01333" }
								)} ${t("modules.venue_settings.field.added_with.title", { value: 14.345 })}`}
							</small>
						</div>
						{fields.length ? (
							<div>
								<table className="table table-form mt-4">
									<thead>
										<tr>
											<th className="action" />
											<th>{t("modules.venue_settings.field.prefix.title")}</th>
											<th>{t("common.word.value")}</th>
											<th className="action" />
										</tr>
									</thead>
									<tbody>
										{fields.map((field, index) => {
											return (
												<tr key={fields[index].key}>
													<td className={"action"} />
													<td>
														<FormSelectGroup
															isClearable={false}
															errors={errors}
															name={`weight_barcodes.${index}.type`}
															control={control}
															options={props.weightBarcodesConfig.map((barcode) => {
																return {
																	label: barcode,
																	value: barcode,
																};
															})}
															data-testid={`weight_barcodes.${index}.type`}
														/>
													</td>
													<td>
														<FormInput
															onChange={() => clearErrors(`weight_barcodes.${index}`)}
															name={`weight_barcodes.${index}.value`}
															errors={errors}
															register={register}
														/>
														{Object.entries(errors).map(([key, value]: [string, any]) => {
															if (key === "weight_barcodes" && value[index]) {
																return (
																	<div
																		key={`${key}-${value}`}
																		className={"invalid-feedback d-block"}
																	>
																		{t("constraints.wrong_code")}
																	</div>
																);
															}

															return <></>;
														})}
													</td>
													<td className={"action"}>
														<RemoveSVG className="icon" onClick={() => remove(index)} />
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
								<Button variant={"add"} onClick={() => append({})}>
									+ {t("common.action.add", { ns: "lib" })}
								</Button>
							</div>
						) : (
							<div className="mt-4">
								<EmptyList
									title={t("modules.venue_settings.field.empty_list_description.title")}
									actions={[
										{
											name: `+ ${t("common.action.add", { ns: "lib" })}`,
											variant: "add",
											click: () => append({}),
										},
									]}
								/>
							</div>
						)}
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};

export default WeightedBarcodesCard;
