import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { PaymentMethodApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "PAYMENT_METHOD";
const resourceType = "PAYMENT_METHOD";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const [items, setItems] = useState<PaymentMethodApi[]>([]);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRemove = async (item: PaymentMethodApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removePaymentMethod(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestore = async (item: PaymentMethodApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restorePaymentMethod(item.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			onRefresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onDisable = async (item: PaymentMethodApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			await api.organization().disablePaymentMethod(item?.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text",
				render: (item: PaymentMethodApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(item.status)}
							minWidth={200}
							to={`${location.pathname}/${item.id}`}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: PaymentMethodApi) => item.name,
			},
			{
				id: "limit",
				name: t("modules.payment_method.field.payment_limit.title"),
				type: "number" as FilterType,
				render: (item: PaymentMethodApi) => {
					return FormatMoney(item?.limit);
				},
				renderExport: (item: PaymentMethodApi) => {
					return FormatMoney(item?.limit);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				render: (item: PaymentMethodApi) => <FormatResourceStatus status={item.status} />,
				renderExport: (item: PaymentMethodApi) => item.status,
			},
			{
				id: "outflow_type",
				name: t("modules.payment_method.field.outflow_type.title"),
				type: "list" as FilterType,
				options: {
					RW: t("enums.payment_methods.payment_outflow_types.RW"),
					WZ: t("enums.payment_methods.payment_outflow_types.WZ"),
				},
				render: (item: PaymentMethodApi) => {
					return t(`enums.payment_methods.payment_outflow_types.${item.outflow_type}`);
				},
				renderExport: (item: PaymentMethodApi) => {
					return t(`enums.payment_methods.payment_outflow_types.${item.outflow_type}`);
				},
			},
			{
				id: "receipt_type",
				name: t("modules.payment_method.field.receipt_type.title"),
				type: "list" as FilterType,
				options: {
					CASH: t("enums.payment_methods.payment_receipt_types.CASH"),
					CARD: t("enums.payment_methods.payment_receipt_types.CARD"),
					CHECK: t("enums.payment_methods.payment_receipt_types.CHECK"),
					BOND: t("enums.payment_methods.payment_receipt_types.BOND"),
					CREDIT: t("enums.payment_methods.payment_receipt_types.CREDIT"),
					OTHER: t("enums.payment_methods.payment_receipt_types.OTHER"),
					VOUCHER: t("enums.payment_methods.payment_receipt_types.VOUCHER"),
					CLIENT_ACCOUNT: t("enums.payment_methods.payment_receipt_types.CLIENT_ACCOUNT"),
					BANK_TRANSFER: t("enums.payment_methods.payment_receipt_types.BANK_TRANSFER"),
				},
				render: (item: PaymentMethodApi) => {
					return `${t(`enums.payment_methods.payment_receipt_types.${item.receipt_type}`)}`;
				},
				renderExport: (item: PaymentMethodApi) => {
					return `${t(`enums.payment_methods.payment_receipt_types.${item.receipt_type}`)}`;
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: PaymentMethodApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: PaymentMethodApi) => {
					onRemove(item);
				},
				visible: (item: PaymentMethodApi) => {
					return item.status === "ENABLED" && !item.default;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: PaymentMethodApi) => {
					onRestore(item);
				},
				visible: (item: PaymentMethodApi) => {
					return item.status === "DELETED" || item.status === "DISABLED";
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: PaymentMethodApi) => onDisable(item),
				visible: (item: PaymentMethodApi) => item.status === "ENABLED",
			},
		],
		selectedColumns: ["name", "limit", "status", "outflow_type", "receipt_type"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		exportConfig: {
			title: t("modules.payment_method.field.export_config.title"),
			filename: t("modules.payment_method.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(params);
			return api.organization().getPaymentMethods(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
			onFetch={(fetchItems: PaymentMethodApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};
const OrganizationSettingsPaymentMethodsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const customFieldResourceTypes = [
		{
			type: "PAYMENT_METHOD",
			name: t(`enums.custom_fields.resources.PAYMENT_METHOD`),
		},
	];

	useEffect(() => {
		handleChangeTabTitle(t("common.word.payment_methods"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			path: `${props.match.url}/new`,
			variant: "primary",
			title: t("common.action.add", { ns: "lib" }),
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			path: `${props.match.url}/new`,
			title: t("common.action.add", { ns: "lib" }),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("common.word.payment_methods")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationSettingsPaymentMethodsIndexPage;
