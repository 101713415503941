import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { PosReporCheckBeforeDeletionApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { DateRange } from "../../services/types";
import ConfirmDeletion from "./ConfirmDeletion";
import POSReportDeletion from "./POSReportDeletion";
import { ClearSaleFormProps, SaleDeletionType, SaleNonPermanentDeletionType } from "./services/types";

interface Props {
	loading: boolean;
	handleClearSale: (password: string, deletionType: string) => void;
	onCancel: () => void;
	currentReportUid?: string;
	onSetCurrentReportUid: (reportUid: string | undefined) => void;
	posReportDetails?: PosReporCheckBeforeDeletionApi;
	onSetPosReportDetails: (posReportDetails: PosReporCheckBeforeDeletionApi | undefined) => void;
	selectedDeletionRange?: string | DateRange;
	onSetSelectedDeletionRange: (range: string | DateRange | undefined) => void;
}

const ClearSale = ({
	loading,
	handleClearSale,
	onCancel,
	currentReportUid,
	onSetCurrentReportUid,
	posReportDetails,
	onSetPosReportDetails,
	onSetSelectedDeletionRange,
	selectedDeletionRange,
}: Props): JSX.Element => {
	const [password, setPassword] = useState("");
	const [deletionType, setDeletionType] = useState<SaleDeletionType>(SaleDeletionType.UNSET);
	const [nonPermanentDeletionType, setNonPermanentDeletionType] = useState<SaleNonPermanentDeletionType>(
		SaleNonPermanentDeletionType.UNSET
	);
	const form = useForm<ClearSaleFormProps>();
	const { t } = useTranslation();

	const deleteSaleDataHandler = () => handleClearSale(password, deletionType);

	return (
		<>
			<Form.Group className="form-group">
				<LimitComponentVisibility
					defaultVisible={deletionType === SaleDeletionType.SALE_PERMANENT_DELETION}
					id={SaleDeletionType.SALE_PERMANENT_DELETION}
					legendText={t("modules.clear_data.field.delete_venue.title")}
					handleChange={(val) =>
						setDeletionType(val ? SaleDeletionType.SALE_PERMANENT_DELETION : SaleDeletionType.UNSET)
					}
				/>
				<LimitComponentVisibility
					defaultVisible={deletionType === SaleDeletionType.NON_PERMANENT_DELETION}
					id={SaleDeletionType.NON_PERMANENT_DELETION}
					legendText={t("modules.clear_data.field.delete_venue_based_on_pos_report.title")}
					handleChange={(val) =>
						setDeletionType(val ? SaleDeletionType.NON_PERMANENT_DELETION : SaleDeletionType.UNSET)
					}
					unlimitedContent={
						<POSReportDeletion
							onSetCurrentReportUid={onSetCurrentReportUid}
							currentReportUid={currentReportUid}
							form={form}
							posReportDetails={posReportDetails}
							onSetPosReportDetails={onSetPosReportDetails}
							nonPermanentDeletionType={nonPermanentDeletionType}
							onSetNonPermanentDeletionType={setNonPermanentDeletionType}
							onSetSelectedDeletionRange={onSetSelectedDeletionRange}
							selectedDeletionRange={selectedDeletionRange}
						/>
					}
				/>
				<ConfirmDeletion
					deletionType={deletionType}
					nonPermanentDeletionType={nonPermanentDeletionType}
					currentReportUid={currentReportUid}
					setPassword={setPassword}
					loading={loading}
					deleteSaleDataHandler={deleteSaleDataHandler}
					onCancel={onCancel}
				/>
			</Form.Group>
		</>
	);
};
export default ClearSale;
