import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ItemGroupGroupEditingFormProps } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	name: string;
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	loadOptions: (search: string, params: Record<string, any>, options?: Record<string, any>) => Promise<any[]>;
	handleOnChange?: (id: string, obj: any) => void;
	defaultValue?: Record<string, any>;
}

const ItemGroupGroupEditingVirtualTableSelectInput = ({
	form,
	name,
	loadOptions,
	handleOnChange,
	defaultValue,
}: Props) => {
	const {
		formState: { errors },
		control,
	} = form;

	return (
		<FormSelectGroup
			errors={errors}
			control={control}
			name={name}
			loadOptions={loadOptions}
			onChange={handleOnChange}
			defaultValue={defaultValue}
			getOptionValue={(option) => Number(option.id)}
		/>
	);
};

export default ItemGroupGroupEditingVirtualTableSelectInput;
