import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import MobileAction, { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { FormatDate, FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import ShowJsonModal from "go-core/components/ShowJsonModal/ShowJsonModal";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin, selectUser } from "go-security/services/users/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import FormatResourceStatus from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import LastActivities from "../../../../../../../../../components/Common/LastActivities/LastActivities";
import RenderOrderType from "../../../../../../../../../components/Common/Renderers/RenderOrderType";
import { ReactComponent as FiscalizationSVG } from "../../../../../../../../../images/svg/bills/fiscalized.svg";
import { ReactComponent as LinkSVG } from "../../../../../../../../../images/svg/bills/link.svg";
import { OrderApi, OrderFiscalizationStatus } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import ChangeTransactionPaymentMethod from "../../../components/ChangeTransactionPaymentMethod";
import ClientCard from "./ClientCard";
import DeliveryCard from "./DeliveryCard";
import PromotionsCard from "./DiscountsCard";
import ExternalLinksCard from "./ExternalLinksCard";
import FiscalizationCard from "./FiscalizationCard/FiscalizationCard";
import OrderPublicLinkModal from "./GenerateEReceiptLinkModal";
import InfoCard from "./InfoCard";
import InvoiceCard from "./InvoiceCard";
import PositionsCard from "./PositionsCard";
import SendReceiptModal from "./SendReceiptModal";
import SourceCard from "./SourceCard";
import TransactionsCard from "./TransactionsCard";

export interface Props {
	order: OrderApi;
	customFieldsConfig: CustomFieldTemplateApi[];
	fetchOrder: () => void;
}

const OrderPreview: FC<Props> = ({ order, customFieldsConfig, fetchOrder }) => {
	const { t } = useTranslation();
	const [showJson, setShowJson] = useState(false);
	const [showLogs, setShowLogs] = useState(false);
	const [orderLogs, setOrderLogs] = useState<Record<string, any> | undefined>(undefined);
	const organization = useSelector(selectOrganization);
	const admin = useSelector(selectIsAdmin);
	const user = useSelector(selectUser);
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const [showSendEReceiptModal, setShowSendEReceiptModal] = useState(false);
	const [showOrderLinkModal, setShowOrderLinkModal] = useState(false);
	const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] = useState(false);
	const isSupportRole = user?.roles?.includes("ROLE_SUPPORT");
	const isMobile = useWindowSize().isMobile;

	const onCreateInvoice = () => {
		history.push({
			pathname: `/${organization.id}/invoices/new`,
			state: {
				order,
			},
		});
	};

	const getDate = () => {
		if (order?.status === "CLOSED") {
			return FormatDateToDateHoursRange(order?.created_at, order?.closed_at);
		}
		return FormatDate(order?.created_at);
	};

	const onFetchOrderJson = async () => {
		try {
			const res = await api.organization().getOrderJson(order.id);
			setOrderLogs(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDeleteOrder = async () => {
		try {
			await confirmation({
				title: t("common.word.confirmation", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().deleteOrder(order.id);
			addSuccessFlash(t("lib:common.flash.removed"));
			history.push(`/${organization.id}/sales/orders`);
		} catch (err: any) {
			const wrongTerminalError = err.errors.find(
				(error: Record<string, any>) => error.code === "wrong_order_terminal"
			);
			if (wrongTerminalError) {
				const flashMessage = t("constraints.wrong_terminal_order", {
					terminal_name: wrongTerminalError.terminal_name,
				});
				addFlash({
					type: "danger",
					msg: flashMessage,
					messageAlreadyCreated: true,
				});
			} else handleError.alert(err, addFlash);
		}
	};

	const getOrderEReceipt = async () => {
		try {
			const response = await api.organization().getOrderEReceipt(order.id);
			const href = URL.createObjectURL(response.data);
			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", `${t(`modules.order.field.order_filename.title`)} #${order.number}.json`);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const releaseOrder = async () => {
		try {
			await confirmation({
				title: t("common.word.confirmation", { ns: "lib" }),
				message: t("confirmation.message.action", { ns: "lib" }),
			});
			await api.organization().releaseOrder(order.id);
			fetchOrder();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("lib:common.action.edit"),
			action: () => history.push(`/${organization.id}/sales/orders/${order.id}/edit`),
			variant: "light",
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			dropdown: true,
		},
	];
	if (order.status === "CLOSED" && !order.invoice) {
		buttons.push({
			title: t("modules.order.action.create_invoice.title"),
			action: () => onCreateInvoice(),
			dropdown: true,
		});
	}
	if (admin && (order.status === "EXTERNAL" || order.status === "OPENED"))
		buttons.push({
			title: t("lib:common.action.remove"),
			action: () => onDeleteOrder(),
			dropdown: true,
		});
	buttons.push({
		title: t("modules.order.action.show_JSON.title"),
		action: () => {
			setShowJson(true);
			onFetchOrderJson();
		},
		dropdown: true,
	});
	buttons.push({
		title: t("modules.order.action.send_receipt.title"),
		action: () => setShowSendEReceiptModal(true),
		dropdown: true,
	});
	if (order.ereceipt_exists) {
		buttons.push({
			title: t("modules.order.action.download_e_receipt.title"),
			action: getOrderEReceipt,
			dropdown: true,
		});
	}
	buttons.push({
		title: t("modules.order.action.show_link.title"),
		action: () => setShowOrderLinkModal(true),
		dropdown: true,
	});
	if (order.status === "OPENED" || order.status === "CLOSED") {
		buttons.push({
			title: t("modules.order.action.set_payment_method.title"),
			action: () => setShowChangePaymentMethodModal(true),
			dropdown: true,
		});
	}
	if (admin || isSupportRole) {
		buttons.push({
			title: t("modules.order.action.release_order.title"),
			action: releaseOrder,
			dropdown: true,
		});
	}

	const mobileActions: MobileActionProps[] = [
		{
			title: t("lib:common.action.edit"),
			action: () => history.push(`/${organization.id}/sales/orders/${order.id}/edit`),
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("modules.order.action.create_invoice.title"),
			action: () => onCreateInvoice(),
			hide: order.status !== "CLOSED" || !!order.invoice,
		},
		{
			title: t("lib:common.action.remove"),
			action: () => onDeleteOrder(),
			hide: !(admin && (order.status === "EXTERNAL" || order.status === "OPENED")),
		},
		{
			title: t("modules.order.action.show_JSON.title"),
			action: () => {
				setShowJson(true);
				onFetchOrderJson();
			},
		},
		{ title: t("modules.order.action.send_receipt.title"), action: () => setShowSendEReceiptModal(true) },
		{
			title: t("modules.order.action.download_e_receipt.title"),
			action: getOrderEReceipt,
			hide: !order.ereceipt_exists,
		},
		{
			title: t("modules.order.action.show_link.title"),
			action: () => setShowOrderLinkModal(true),
		},
		{
			title: t("modules.order.action.set_payment_method.title"),
			action: () => setShowChangePaymentMethodModal(true),
			hide: order.status !== "OPENED" && order.status !== "CLOSED",
		},
		{
			title: t("modules.order.action.release_order.title"),
			action: releaseOrder,
			hide: !admin && !isSupportRole,
		},
	];

	const renderSubtitle = () => {
		return (
			<ul>
				<li>{getDate()}</li>
				<li>{FormatMoney(order?.total_paid_price)}</li>
				<li>
					<RenderOrderType type={order.type} />
				</li>
				<li>
					{order.fiscalization && (
						<>
							{(order.fiscalization.status === "FISCALIZED" ||
								order.fiscalization.status === "FISCALIZED_RECEIVED") && (
								<>
									<FiscalizationSVG /> {t("enums.orders.fiscalization.FISCALIZED")}
								</>
							)}
							{order.fiscalization.status === "NOT_FISCALIZED" && (
								<>
									<FiscalizationSVG /> {t("enums.orders.fiscalization.NOT_FISCALIZED")}
								</>
							)}
						</>
					)}
				</li>
			</ul>
		);
	};

	const renderTitle = () => {
		return (
			<>
				{t("common.word.order")} #{order.number}
				<FormatResourceStatus status={order.status} />
				<FormatResourceStatus
					status={
						order.payment_status === "OPEN" || order.payment_status === "WAIT_TO_PAY"
							? "UNPAID"
							: order.payment_status
					}
				/>
				<LinkSVG className="icon" onClick={() => window.open(order.public_link, "_blank")} />
			</>
		);
	};

	const avatar = {
		color: "#1A86E0",
		date: order?.created_at,
	};

	const isClientCardVisible = order.contact && Object.keys(order.contact).length > 0;
	const isFiscalizationCardVisible =
		order.fiscalization &&
		Object.keys(order.fiscalization).length > 0 &&
		order.fiscalization.status !== OrderFiscalizationStatus.NOT_FISCALIZED;
	const isInfoCardVisible =
		order.opening_employee ||
		order.employee ||
		order.point_of_sale ||
		order.comment ||
		order.terminal ||
		order.table ||
		order.number_of_guests ||
		order.custom_fields;

	return (
		<div className="content-preview bill-preview">
			{(order.payment_status === "OPEN" || order?.fiscalization?.status === "ERROR") && (
				<div className="error-alert-box">
					{order.payment_status === "OPEN" && <span>{t("modules.order.constraints.order_unpaid")}</span>}
					{order?.fiscalization?.status === "ERROR" && (
						<span>{t("modules.order.constraints.fiscalization_error")}</span>
					)}
				</div>
			)}
			<MobileActions actions={mobileActions} />
			<Header
				title={renderTitle()}
				subtitle={renderSubtitle()}
				buttons={isMobile ? [] : buttons}
				avatar={avatar}
			/>
			<div className="content-preview-body">
				<div className="row">
					<div className="col-md-4">
						{isClientCardVisible && <ClientCard contact={order.contact} />}
						{order.invoice && <InvoiceCard invoice={order.invoice} />}
						{isFiscalizationCardVisible && <FiscalizationCard order={order} />}
						{isInfoCardVisible && <InfoCard order={order} customFieldsConfig={customFieldsConfig} />}
						<SourceCard order={order} />
						{order?.external_links?.length > 0 && (
							<ExternalLinksCard externalLinks={order.external_links} />
						)}
						{order.delivery && <DeliveryCard delivery={order.delivery} />}
						<LastActivities resourceType={"ORDER"} resourceId={order.id} data={order.activities} />
					</div>
					<div className="col-md-8">
						<PositionsCard order={order} />
						{order.promotions?.length > 0 && <PromotionsCard order={order} />}
						{order.transactions?.length > 0 && <TransactionsCard order={order} fetchOrder={fetchOrder} />}
					</div>
				</div>
			</div>
			{showJson && (
				<ShowJsonModal
					logs={orderLogs}
					onHide={() => {
						setShowJson(false);
						setOrderLogs(undefined);
					}}
				/>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={order.uid}
					resourceType={"ORDER"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			{showSendEReceiptModal && (
				<SendReceiptModal
					isShown={showSendEReceiptModal}
					order={order}
					onHide={() => setShowSendEReceiptModal(false)}
				/>
			)}
			{showOrderLinkModal && (
				<OrderPublicLinkModal
					isShown={showOrderLinkModal}
					link={order.public_link}
					onHide={() => setShowOrderLinkModal(false)}
				/>
			)}
			{showChangePaymentMethodModal && (
				<ChangeTransactionPaymentMethod
					show={showChangePaymentMethodModal}
					isOrder
					onHide={() => setShowChangePaymentMethodModal(false)}
					id={order.id}
					handleSuccessAction={fetchOrder}
					transaction={order.transactions.find((transaction) => transaction.status !== "ERROR")}
					documentNumber={order.number}
					editMode={order.transactions.length !== 0}
					price={order.total_price}
				/>
			)}
		</div>
	);
};
export default OrderPreview;
