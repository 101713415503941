//eslint-disable-next-line
// @ts-ignore
import * as locales from "react-date-range/dist/locale/index";
import { get } from "react-hook-form";
import { getLang } from "go-core/utils/utils";
import { CustomValidationConstraint } from "./types";

export const registerField = (register: any, prefix: string, field: string): void => {
	register(`${prefix}.${field}`);
};

export const registerObject = (register: any, prefix: string, fields: string[]): void => {
	fields.forEach((field) => registerField(register, prefix, field));
};

export const hasErrors = (errors: any, name?: string): boolean => {
	if (name) {
		const error = get(errors, name);
		if (error) {
			return true;
		}
	}
	return false;
};

export const getErrors = (errors: any, name?: string): any[] | undefined => {
	if (name) {
		const error = get(errors, name);
		if (!error) {
			return undefined;
		}
		const messages = error.types;
		return Object.entries(messages);
	}
	return undefined;
};
export function addServerErrors(
	errors: Array<any>,
	setError: (
		fieldName: string,
		error: {
			types?: any;
			type?: string;
			message?: string;
			duplicateId?: number;
			arguments?: Record<string, number>;
		}
	) => void
): void {
	const newErrors: any = [];
	errors.forEach((error) => {
		const duplicateId = error.duplicate_id;
		const fieldName = error.field;
		if (!fieldName || fieldName === "all") {
			return;
		}
		let newError = newErrors.filter((newSingleError: any) => newSingleError.field === fieldName)[0];
		if (!newError) {
			newError = {
				field: fieldName,
				types: {},
				duplicateId,
				arguments: error.arguments,
			};
			if (error.code) {
				newError.types[error.code] = error.message;
				newErrors.push(newError);
			}
		} else if (error.code) {
			newError.types[error.code] = error.message;
		}
	});
	newErrors.forEach((error: any) => {
		setError(error.field, {
			duplicateId: error.duplicateId,
			types: error.types,
			message: error.code ? error.code : "",
			arguments: error.arguments,
		});
	});
}

export const getLocaleForDatePicker = (): Locale => {
	let langLocale = getLang();
	if (langLocale.startsWith("en-")) {
		langLocale = langLocale.replaceAll("-", "");
	} else {
		langLocale = langLocale.split("-")[0];
	}
	const locale = locales[langLocale] ?? locales.enUS;
	return locale;
};

export const isConstraintARequiredConstraint = (constraint: CustomValidationConstraint): boolean => {
	return (
		constraint === CustomValidationConstraint.REQUIRED ||
		constraint === CustomValidationConstraint.REQUIRED_IF ||
		constraint === CustomValidationConstraint.GREATER_THAN_IF_REQUIRED
	);
};
