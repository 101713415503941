import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { PointOfSaleApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	pointOfSale: PointOfSaleApi;
	onHide: () => void;
	handleSave: (pointOfSale: PointOfSaleApi) => void;
}

const PointOfSaleModalForm = (props: Props): JSX.Element => {
	const form = useForm<PointOfSaleApi>({
		criteriaMode: "all",
		defaultValues: props.pointOfSale,
	});
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setError,
		control,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();

	const searchDirections = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDirectionsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = handleSubmit(async (data: PointOfSaleApi) => {
		setLoading(true);
		data.id = props.pointOfSale.id;
		try {
			const params: Record<string, any> = { include: "default_direction" };
			if (data.id) {
				const res = await api.organization().updatePointOfSale(data, params);
				props.handleSave(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createPointOfSale(data, params);
				props.handleSave(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={!!props.pointOfSale} onHide={props.onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					{props.pointOfSale.id ? (
						<>
							{t("common.word.points_of_sale")} "{props.pointOfSale.name}"
						</>
					) : (
						<>{t("modules.point_of_sale.header.new.title")}</>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormDirty formState={formState} key="pos-form" noValidate onSubmit={onSubmit}>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						register={register}
						name="name"
						errors={errors}
					/>
					<FormSelectGroup
						label={t("modules.point_of_sale.field.default_direction.title")}
						name="default_direction_id"
						errors={errors}
						getOptionLabel={(option) => option.label}
						defaultValue={{
							label: props.pointOfSale.default_direction?.name,
							id: props.pointOfSale.default_direction?.id,
						}}
						getOptionValue={(option) => option.id}
						loadOptions={searchDirections}
						control={control}
						data-testid="default_direction_id"
					/>
				</FormDirty>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
					{t("common.action.save", { ns: "lib" })}
				</ButtonLoading>
				<Button variant="light" onClick={props.onHide}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default PointOfSaleModalForm;
