import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as CloseSVG } from "../../../../../../../../../images/svg/liveOrders/close.svg";
import { LiveOrderApi } from "../../../../../../../../../services/Api/Organization/types";
import LiveOrdersListItem from "../../../components/LiveOrdersListItem";

interface Props {
	onHide: () => void;
	orders: LiveOrderApi[];
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
}

const LiveOrdersMapMarkerOrdersListMobile = ({ onHide, orders, setFocusedOrder }: Props): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref?.current) {
			const disableClickPropagation = L?.DomEvent?.disableClickPropagation;
			disableClickPropagation(ref.current);
		}
	}, []);

	const ordersAddress = {
		...orders[0].delivery.address,
		flat_nr: undefined,
	};
	const drawOrders = () => {
		return orders.map((order, index) => {
			return (
				<LiveOrdersListItem
					key={`marker-order-${index}-${order.id}`}
					order={order}
					onSelectOrder={() => {
						setFocusedOrder(order);
						history.push(`/${organization.id}/live_orders/map/orders/${order.id}`);
					}}
				/>
			);
		});
	};

	return (
		<div className="w-100 bg-white position-relative live-orders-map-marker-orders-list-mobile" ref={ref}>
			<div className="d-flex flex-column p-3 live-orders-map-marker-orders-list-mobile-container">
				<div className="header">
					<div className="d-flex flex-column">
						<h5 className="mb-1 text-primary me-3">
							<FormatAddress address={ordersAddress} />
						</h5>
						<span className="text-muted">
							{t("modules.live_orders.field.orders", { count: orders.length })}
						</span>
					</div>
					<CloseSVG className="close-button" onClick={onHide} />
				</div>
				<div className="d-flex flex-column">{drawOrders()}</div>
			</div>
		</div>
	);
};

export default LiveOrdersMapMarkerOrdersListMobile;
