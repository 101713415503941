import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Loading } from "go-core/components/Loading";
import { apiSecurity } from "../../../services/Api/api";
import { OrganizationUserSecurityApi } from "../../../services/Api/typesSecurity";

interface Props {
	user: OrganizationUserSecurityApi;
	onHide: () => void;
}

const GenerateInvitationLinkModal = ({ user, onHide }: Props): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const [link, setLink] = useState("");
	const [hasError, setHasError] = useState(false);

	const handleGenerateInvitationLink = useCallback(async () => {
		setLoading(true);
		setHasError(false);
		try {
			const res = await apiSecurity.organization().generateInvitationLink(user.email);
			setLink(res.link);
		} catch (err) {
			handleError.alert(err, addFlash);
			setHasError(true);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		handleGenerateInvitationLink();
	}, [handleGenerateInvitationLink]);

	return (
		<Modal onHide={onHide} show={!!user}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:go_component.user.generate_invitation_link_modal.header.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<Loading title={t("lib:go_component.user.generate_invitation_link_modal.field.loading.title")} />
				) : (
					<div className="d-flex flex-column">
						<div className="d-flex w-100">
							{link && !hasError && (
								<Card className="w-100">
									<Card.Body>
										<span>{link}</span>
									</Card.Body>
								</Card>
							)}
							{hasError && (
								<Button className="w-100" onClick={handleGenerateInvitationLink}>
									{t(
										"lib:go_component.user.generate_invitation_link_modal.action.generate_again.title"
									)}
								</Button>
							)}
						</div>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default GenerateInvitationLinkModal;
