import React from "react";
import { Location } from "history";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";
import { selectOrganization } from "go-security/services/organizations/selectors";

const InvoicesNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const prefix = `/${organization.id}/invoices`;

	const items: SubNavigationItemProps[] = [
		{
			title: t("modules.invoice.header.title"),
			route: `${prefix}`,
			isActiveRoute: (match: Record<string, any> | null, location: Location) =>
				!location.pathname.includes("/proforma") && !location.pathname.includes("/jpk"),
		},
		{
			title: t("modules.invoice.field.proforma.title"),
			route: `${prefix}/proforma`,
		},
		{
			title: t("modules.invoice.field.jpk.title"),
			route: `${prefix}/jpk`,
			isActiveRoute: (match: Record<string, any> | null, location: Location) =>
				location.pathname.includes("/jpk"),
		},
	];

	return <SubNavigationItems title={t("modules.invoice.header.title")} items={items} />;
};
export default InvoicesNavigation;
