import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	CompanyInfoApi,
	InvoiceApi,
	InvoiceDetailsApi,
} from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import CompanyInfoNotFull from "../../../components/Errors/CompanyInfoNotFull";
import InvoiceDetailsNotExist from "../../../components/Errors/InvoiceDetailsNotExist";
import InvoiceForm from "../../../components/Form/InvoiceForm";

interface MatchParams {
	invoice_id: string;
}

const OrganizationInvoicesProformaEditPage = (): JSX.Element => {
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsApi | undefined>(undefined);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const { invoice_id } = useParams<MatchParams>();
	const { addFlash } = useFlash();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>(undefined);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const params: Record<string, any> = {
			include:
				"custom_fields,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver",
		};
		const cfParams: Record<string, string> = {
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
			include: "options,constraints",
		};
		Promise.all([
			api.organization().getProforma(Number(invoice_id), params),
			api.organization().getCompanyInfo(),
			api.organization().getCustomFields(cfParams),
			api.organization().getInvoiceDetails(),
		])
			.then(([invoiceResponse, companyInfoResponse, cfResponse, detailsResponse]) => {
				setInvoice(invoiceResponse);
				setCustomFieldsConfig(cfResponse);
				setInvoiceDetails(detailsResponse);
				setCompanyInfo(companyInfoResponse);
				handleChangeTabTitle(t(`${invoiceResponse.number} | ${t("modules.invoice.field.proforma.title")}`));
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);
	const history = useHistory();

	if (!invoice || !customFieldsConfig || !invoiceDetails) {
		return <LoadingContainer />;
	}

	const checkIfCanRenderInvoiceForm = () => {
		let result = true;
		if (invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)))
			result = false;
		if (invoice && (!companyInfo || !companyInfo.name || !companyInfo.tax_id_no)) result = false;
		return result;
	};

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/invoices/proforma/${invoice.id}`);

	return (
		<>
			<Header title={`${t("modules.invoice.field.proforma.title")} "${invoice.number}"`} back={goBack} />
			{invoice && (!invoiceDetails || (invoiceDetails && Object.keys(invoiceDetails).length === 0)) && (
				<InvoiceDetailsNotExist />
			)}
			{invoice &&
				(!companyInfo ||
					Object.keys(companyInfo).length === 0 ||
					!companyInfo.company_name ||
					!companyInfo.tax_id_no) && <CompanyInfoNotFull />}
			{checkIfCanRenderInvoiceForm() && (
				<InvoiceForm
					invoiceDetails={invoiceDetails}
					invoice={invoice}
					customFieldsConfig={customFieldsConfig}
				/>
			)}
		</>
	);
};
export default OrganizationInvoicesProformaEditPage;
