import React, { FC } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiError } from "../api/types";

interface Props {
	errors: Array<ApiError>;
	field?: string;
}

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:constraints.entity_with_name_exists");
	t("lib:constraints.size_must_be_between");
	t("lib:constraints.must_be_less_than_or_equal_to");
	t("lib:constraints.organization_access_forbidden");
	t("lib:constraints.must_be_greater_than");
	t("lib:constraints.must_be_less_than");
	t("lib:constraints.job_in_progress");
	t("lib:constraints.invalid_body");
	t("lib:constraints.must_select_image");
	t("lib:constraints.duplicate_name");
	t("lib:constraints.must_be_greater_than_or_equal_to");
	t("lib:constraints.invalid_time_range");
	t("lib:constraints.user_denied_access");
	t("lib:constraints.form.wrong");
	t("lib:constraints.max_length");
	t("lib:constraints.invalid_url");
	t("lib:constraints.invalid_email");
	t("lib:constraints.wrong_password");
	t("lib:constraints.must_not_be_blank");
	t("lib:constraints.must_not_be_null");
	t("lib:constraints.money_must_be_in_range");
	t("lib:constraints.access_forbidden");
	t("lib:constraints.unauthorized");
	t("lib:constraints.not_found");
	t("lib:constraints.unknown_error");
	t("lib:constraints.not_allowed");
	t("lib:constraints.wrong_data");
	t("lib:constraints.password_not_equal");
	t("lib:constraints.required");
	t("lib:constraints.required_if");
	t("lib:constraints.invalid_segment_value");
	t("lib:constraints.internet_connection_lost");
	t("lib:constraints.csv_file_corrupted");
};
const ApiErrorMessage: FC<Props> = ({ errors, field }) => {
	const { t } = useTranslation();
	const currentErrors = errors.filter((error) => {
		if (!field) {
			return !error.field;
		}

		return error.field === field;
	});
	if (currentErrors.length <= 0) return null;

	return (
		<Alert variant="danger" show={!!currentErrors.length}>
			{currentErrors.map((error, idx) => (
				<React.Fragment key={idx}>
					{t(`constraints.${error.message}`, { ns: ["translation", "lib"] })}
				</React.Fragment>
			))}
		</Alert>
	);
};

export default ApiErrorMessage;
