import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormCheck, FormInput } from "go-form";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DiscountApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { getDiscountTypes } from "./DiscountForm";

interface Props {
	form: UseFormReturn<DiscountApi>;
	discountType: string;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
	discount: DiscountApi;
}

const DiscountFormBasic = ({ form, discountType, setErrors, ...props }: Props) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const {
		register,
		formState: { errors },
		watch,
		control,
		setValue,
		unregister,
	} = form;

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const volumeValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	const ADD_MODE_OPTIONS = [
		{
			value: "MANUAL",
			label: t("enums.discount_mode_option.MANUAL"),
		},
		{
			value: "AUTOMATIC",
			label: t("enums.discount_mode_option.AUTOMATIC"),
		},
	];

	const searchItems = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getItemsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	return (
		<fieldset className="form-group no-gutters">
			<h5>
				{t("modules.discount.field.basic_data.title")}
				<small className="text-muted">
					<br />
					{t("modules.discount.field.basic_data.helptext.description")}
				</small>
			</h5>
			<div className="row">
				<div className="col-md-6">
					<FormInput
						label={t("lib:common.word.name")}
						register={register}
						name="name"
						errors={errors}
						customValidationConfig={nameValidationConfig}
					/>
				</div>
				<div className="col-md-3">
					<FormMoneyInput
						suffix={discountType === "AMOUNT" || discountType === "FIXED_PRICE" ? currency : "%"}
						decimalScale={discountType === "AMOUNT" ? 2 : 0}
						currency={currency}
						label={t("modules.discount.field.volume.title")}
						control={control}
						name="volume"
						errors={errors}
						customValidationConfig={volumeValidationConfig}
						maxPermittedAmountOfDigits={10}
					/>
				</div>
				<div className="col-md-3">
					<FormSelectGroup
						errors={errors}
						control={control}
						name="application_mode"
						label={t("modules.discount.field.add_mode.title")}
						options={ADD_MODE_OPTIONS}
						data-testid="application_mode"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-6 d-flex flex-column">
					<p className="form-label">{t("common.word.discount_type")}</p>
					<div className="d-flex" style={{ marginTop: "1px", columnGap: "8px" }}>
						{getDiscountTypes(t).map((discount, index) => {
							return (
								<Controller
									key={index}
									name="discount_type"
									control={control}
									defaultValue={props.discount.discount_type}
									render={() => {
										return (
											<Button
												className="text-nowrap"
												variant={`${
													discountType === discount.value ? "outline-primary" : "default"
												}`}
												onClick={() => {
													setValue("discount_type", discount.value);
													if (discount.value !== "TIP") {
														unregister("tip_applied_after_discount");
														unregister("tip_item_id");
													}
												}}
											>
												{discount.label}
											</Button>
										);
									}}
								/>
							);
						})}
					</div>
				</div>
			</div>
			{discountType === "TIP" && (
				<>
					<div className="row mt-2">
						<div className="col-md-6">
							<FormCheck
								type="switch"
								register={register}
								label={t("modules.discount.field.tip_applied_after_discount.title")}
								name="tip_applied_after_discount"
								errors={errors}
							/>
						</div>
					</div>
					<div className={"row"}>
						<div className={"col-md-6"}>
							<FormSelectGroup
								label={t("modules.discount.field.tip_item_id.title")}
								name="tip_item_id"
								errors={errors}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								defaultValue={{
									label: props.discount.tip?.name,
									id: props.discount.tip?.id,
								}}
								loadOptions={searchItems}
								control={control}
								data-testid="tip_item_id"
							/>
						</div>
					</div>
				</>
			)}
		</fieldset>
	);
};

export default DiscountFormBasic;
