import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as MinusSVG } from "../../../../../../../../../../images/svg/liveOrders/minus.svg";
import { ReactComponent as PlusSVG } from "../../../../../../../../../../images/svg/liveOrders/plus.svg";
import { OrdersContext } from "../../../../services/context";

const LiveOrdersMapIconSizesLegend = (): JSX.Element => {
	const { t } = useTranslation();
	const ordersContext = useContext(OrdersContext);
	const maxScale = 0.3 * 5;

	const enlargeScale = () => {
		if (ordersContext.iconsScale < maxScale) {
			const newScale = ordersContext.iconsScale + 0.3;
			ordersContext.setIconsScale(newScale);
		}
	};

	const reduceScale = () => {
		if (ordersContext.iconsScale > 1) {
			const newScale = ordersContext.iconsScale - 0.3;
			ordersContext.setIconsScale(newScale);
		}
	};

	return (
		<>
			<h5>{t("modules.live_order.field.incons_size.title")}</h5>
			<div className="live-orders-map-legend-box-buttons d-flex flex-grow-1">
				<Button onClick={enlargeScale} disabled={ordersContext.iconsScale >= maxScale}>
					<PlusSVG className="mt-n1 me-1" />
					{t("modules.live_order.action.zoom_in.title")}
				</Button>
				<Button onClick={reduceScale} disabled={ordersContext.iconsScale === 1}>
					<MinusSVG className="mt-n1 me-1" />
					{t("modules.live_order.action.zoom_out.title")}
				</Button>
			</div>
		</>
	);
};

export default LiveOrdersMapIconSizesLegend;
