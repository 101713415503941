import React, { useRef } from "react";
import { useDynamicTableHeight } from "go-list/core/components/Table/hooks";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { camelToSnakeCase } from "../../utils/componentUtils";
import { AttributeProps } from "./AttributesTable";

interface Props {
	response: any[];
	attributes: AttributeProps[];
	customFieldsConfig?: CustomFieldTemplateApi[];
	comparationCheck: (data: any, fieldName: string) => any;
	renderComparedValue: (data: any, fieldName: string, differs?: boolean) => JSX.Element;
	databaseColumnName: Record<string, string>;
	customAttributesCompare?: (attribute: string) => string;
	importPageRef?: React.RefObject<HTMLDivElement | null>;
}

const ImportDataTable = ({
	response,
	attributes,
	customFieldsConfig,
	comparationCheck,
	renderComparedValue,
	databaseColumnName,
	customAttributesCompare,
	importPageRef,
}: Props): JSX.Element => {
	const mainDivRef = useRef<HTMLDivElement>(null);
	const footerAndBottomAlertHeight = 300;
	const { mainDivStyles } = useDynamicTableHeight(mainDivRef, footerAndBottomAlertHeight, importPageRef);

	const processField = (attr: string) => {
		return customAttributesCompare ? customAttributesCompare(attr) : camelToSnakeCase(attr, true);
	};

	const compareValue = (prevValue: string, currentValue: string) => prevValue === currentValue;

	return (
		<div className="table-wrapper">
			<div className="table-list" ref={mainDivRef} style={mainDivStyles}>
				<table className="table table-preview">
					<thead>
						<tr>
							{attributes.map((attr, index) => {
								const fieldName = attr?.domain_field_name;
								if (!fieldName) return <></>;
								if (fieldName.includes("+")) {
									const replaced = fieldName.replace("+", "");
									const cf = customFieldsConfig?.find((f) => f.slug === replaced);
									return <th key={index}>{cf?.name ? cf.name : databaseColumnName[replaced]}</th>;
								}
								return <th key={index}>{databaseColumnName[fieldName]}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{response.map((data, index) => {
							return (
								<tr
									key={index}
									style={{
										background:
											data.updated &&
											data.pre_update &&
											JSON.stringify(data.updated) !== JSON.stringify(data.pre_update)
												? "#f5dfe1"
												: "transparent",
									}}
								>
									{attributes.map((attr, subIndex) => {
										if (!attr.domain_field_name) {
											return null;
										}
										return (
											<td key={`attr[${index}]_${subIndex}`}>
												<div className={"d-flex flex-column"}>
													{!Array.isArray(
														comparationCheck(
															data.updated,
															camelToSnakeCase(attr.domain_field_name, true)
														)
													) &&
														data.pre_update &&
														!compareValue(
															comparationCheck(
																data.pre_update,
																camelToSnakeCase(attr.domain_field_name, true)
															),
															comparationCheck(
																data.updated,
																camelToSnakeCase(attr.domain_field_name, true)
															)
														) && (
															<>
																{renderComparedValue(
																	data.pre_update,
																	camelToSnakeCase(attr.domain_field_name, true),
																	true
																)}
															</>
														)}
													<>
														{!Array.isArray(
															comparationCheck(
																data.updated,
																camelToSnakeCase(attr.domain_field_name, true)
															)
														) &&
															renderComparedValue(
																data.updated,
																camelToSnakeCase(attr.domain_field_name, true)
															)}
													</>
													{data.pre_update &&
														Array.isArray(
															comparationCheck(
																data.pre_update,
																camelToSnakeCase(attr.domain_field_name, true)
															)
														) &&
														comparationCheck(
															data.pre_update,
															camelToSnakeCase(attr.domain_field_name, true)
														).map((x: any, index: number) => {
															if (
																JSON.stringify(
																	data.updated.items[index][
																		processField(attr.domain_field_name)
																	]
																) !==
																JSON.stringify(
																	data.pre_update.items[index][
																		processField(attr.domain_field_name)
																	]
																)
															) {
																return (
																	<>
																		{renderComparedValue(
																			x,
																			camelToSnakeCase(
																				attr.domain_field_name,
																				true
																			),
																			true
																		)}
																		{renderComparedValue(
																			data.updated.items[index],
																			camelToSnakeCase(
																				attr.domain_field_name,
																				true
																			)
																		)}
																	</>
																);
															}
															return (
																<>
																	{renderComparedValue(
																		x,
																		camelToSnakeCase(attr.domain_field_name, true),
																		false
																	)}
																	{JSON.stringify(data.updated?.items) !==
																		JSON.stringify(data.pre_update?.items) && (
																		<>
																			{renderComparedValue(
																				data.updated.items[index],
																				camelToSnakeCase(
																					attr.domain_field_name,
																					true
																				)
																			)}
																		</>
																	)}
																</>
															);
														})}
													{!data.pre_update &&
														Array.isArray(
															comparationCheck(
																data.updated,
																camelToSnakeCase(attr.domain_field_name, true)
															)
														) &&
														comparationCheck(
															data.updated,
															camelToSnakeCase(attr.domain_field_name, true)
														).map((x: any, index: number) => {
															return renderComparedValue(
																data.updated.items[index],
																camelToSnakeCase(attr.domain_field_name, true)
															);
														})}
												</div>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default ImportDataTable;
