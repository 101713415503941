import { ApplicationState } from "../types";
import { UserOrganizationState, UserState } from "./types";

export const selectUser = (store: ApplicationState): UserState => store.user;

export const selectIsAdmin = (store: ApplicationState): boolean => {
	return !!store.user?.admin;
};
export const selectUserOrganizations = (store: ApplicationState): UserOrganizationState[] | undefined =>
	store.user?.organizations;

export const selectPartnersOrganizations = (store: ApplicationState): UserOrganizationState[] | undefined =>
	store.user?.partners_organizations;
