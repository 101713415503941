import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import {
	OrderFiscalizationOrderReceiptApi,
	OrderFiscalizationOrderReceiptDeliveryType,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	orderReceipts: OrderFiscalizationOrderReceiptApi[];
	orderId: number;
}

const FiscalizationOrderReceiptList = ({ orderReceipts, orderId }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loadingIndex, setLoadingIndex] = useState(-1);
	const { addFlash } = useFlash();

	const getReceiverInfo = (orderReceipt: OrderFiscalizationOrderReceiptApi) => {
		switch (orderReceipt.delivery_type) {
			case OrderFiscalizationOrderReceiptDeliveryType.SMS:
				return orderReceipt.phoneNumber;
			case OrderFiscalizationOrderReceiptDeliveryType.EMAIL:
				return orderReceipt.email;
			default:
				return "";
		}
	};

	const handleDownloadEReceipt = async (orderReceipt: OrderFiscalizationOrderReceiptApi, index: number) => {
		setLoadingIndex(index);
		try {
			const response = await api
				.organization()
				.getOrderFiscalizationEReceipt(orderId, orderReceipt.order_receipt_uid);
			const href = URL.createObjectURL(response.data);
			const link = document.createElement("a");
			link.href = href;
			link.setAttribute(
				"download",
				`${t(`modules.order.field.order_filename.title`)} #${orderReceipt.order_receipt_uid}.json`
			);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoadingIndex(-1);
	};

	return (
		<div className="table-responsive">
			<table className="table table-preview">
				<thead>
					<tr>
						<th className="action" />
						<th>{t("modules.order.field.fiscalized_at.title")}</th>
						<th>{t("modules.order.field.order_receipt_uid.title")}</th>
						<th>{t("modules.order.field.delivered_at.title")}</th>
						<th>{t("modules.order.field.receiver.title")}</th>
						<th className="action" />
					</tr>
				</thead>
				<tbody>
					{orderReceipts.map((orderReceipt, index) => (
						<tr key={`order-receipt-${index}`}>
							<td className="action" />
							<td>{FormatDate(orderReceipt.fiscalized_at, undefined, false, defaultFormatDate)}</td>
							<td>{orderReceipt.order_receipt_uid}</td>
							<td>{FormatDate(orderReceipt.delivered_at)}</td>
							<td>{getReceiverInfo(orderReceipt)}</td>
							<td className="action w-20">
								<ButtonLoading
									loading={loadingIndex === index}
									className="text-nowrap"
									variant="add"
									onClick={() => handleDownloadEReceipt(orderReceipt, index)}
								>
									{t("modules.order.action.download_e_receipt.title")}
								</ButtonLoading>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default FiscalizationOrderReceiptList;
