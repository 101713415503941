import { FC } from "react";
import { useTranslation } from "react-i18next";
import { getShortMonthName } from "go-core/components/Formatters/FormatDate";

interface Props {
	date?: string;
	dateHeader?: string;
	dateSubHeader?: string;
	infoHeader?: string;
	infoSubHeader?: string | JSX.Element;
}

const DateInfoBox: FC<Props> = ({ date, dateHeader, dateSubHeader, infoHeader, infoSubHeader }) => {
	const { t } = useTranslation();

	return (
		<div className="date-info-box">
			<div
				className="box-header"
				style={
					!infoHeader && !infoSubHeader
						? {
								borderRadius: "4px",
						  }
						: undefined
				}
			>
				{date ? (
					<>
						<span className="header">{new Date(date).getDate()}</span>
						<span className="sub-header">
							{getShortMonthName((new Date(date).getMonth() + 1).toString(), t)}
						</span>
					</>
				) : (
					<>
						<span className="header">{dateHeader}</span>
						<span className="sub-header">{dateSubHeader}</span>
					</>
				)}
			</div>
			{(infoHeader || infoSubHeader) && (
				<div className="box-body">
					<span className="header">{infoHeader ? infoHeader : ``}</span>
					<span className="sub-header">{infoSubHeader ? infoSubHeader : ``}</span>
				</div>
			)}
		</div>
	);
};

export default DateInfoBox;
