import { History } from "history";
import Storage from "./storage";

const get = (path: string): string => {
	const prev = Storage.get("route_prev");
	if (prev === undefined) return path;
	return prev;
};
const set = (value: string): void => {
	const current = Storage.get("route_current");
	if (current !== undefined) Storage.update("route_prev", current);
	Storage.update("route_current", value);
};
const reload = (history: History): void => {
	const current = Storage.get("route_current");
	if (current) {
		history.push(`${current}_temp`);
	}
	history.goBack();
};
const goBack = (history: History, path?: string): void => {
	const prev = Storage.get("route_prev");
	if (prev !== undefined) {
		history.goBack();
	} else if (path !== undefined) {
		history.push(path);
	} else {
		const current = Storage.get("route_current");
		if (current) {
			history.push(`${current}_temp`);
		}
		history.goBack();
	}
};
export const RouteHistory = { get, set, goBack, reload };
export default RouteHistory;
