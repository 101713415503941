import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { domain } from "go-core";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { BadRequestException, ForbiddenException, UnauthorizedException } from "go-core/api/exceptions";
import { handleResponse } from "go-core/api/handleResponse";
import { apiSecurity } from "./Api/api";
import { OrganizationSecurityApi } from "./Api/typesSecurity";
import { AuthCredentialsService } from "./AuthCredentialsService";
import AccessTokenSessionService from "./accessTokenSessionService";
import AuthCache from "./authCache";
import OrganizationIdService from "./organizationIdService";
import { authOrganization, clearOrganization } from "./organizations/actions";
import { OrganizationState } from "./organizations/types";
import { selectToken } from "./session/selectors";
import { SessionActionTypes, SessionState } from "./session/types";
import SessionIdService from "./sessionIdService";
import { UserActionTypes, UserState } from "./users/types";

export const useAuth = (baseUrl: string): Record<string, any> => {
	const dispatch = useDispatch();
	const history = useHistory();
	const accessToken = useSelector(selectToken);
	const credentials = AuthCredentialsService.get();
	const { addFlash } = useFlash();
	const [showReminderPasswordModal, setShowReminderPasswordModal] = useState(false);

	const postLogInUser = (email: string, password: string, rememberMe?: boolean): Promise<AxiosResponse> => {
		const formBody: string[] = [];
		formBody.push(`${encodeURIComponent("username")}=${encodeURIComponent(email)}`);
		formBody.push(`${encodeURIComponent("password")}=${encodeURIComponent(password)}`);
		const formBodyJoin = formBody.join("&");
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
				Accept: "*/*",
			},
			baseURL: baseUrl,
			withCredentials: true,
		};
		return axios.post(`/ajax/login?remember_me=${rememberMe === true}`, formBodyJoin, config).catch((err) => {
			if (err.response.headers?.["detailed-error"] === "user_without_password") {
				setShowReminderPasswordModal(true);
			}

			return handleResponse(err.response);
		});
	};

	const postLogInUserSessionId = (sessionId: string, redirectUri: string): Promise<AxiosResponse> => {
		return axios
			.get(`/ajax/login/save_session_id?session_id=${sessionId}&redirect_uri=${redirectUri}`, {
				baseURL: baseUrl,
				withCredentials: true,
			})
			.catch((err) => {
				return handleResponse(err.response);
			});
	};

	const getAccessToken = (sessionId: string): Promise<AxiosResponse> => {
		return axios
			.post(
				`/oauth/token?grant_type=session_id&client_id=${credentials?.clientId}&client_secret=${credentials?.secretId}&session_id=${sessionId}`,
				undefined,
				{ baseURL: baseUrl, withCredentials: true }
			)
			.catch((err) => {
				return handleResponse(err.response);
			});
	};

	const getUser = (access_token?: string): Promise<AxiosResponse> => {
		const headers = { Authorization: `Bearer ${access_token}` };
		return axios.get(`/ajax/security/me`, {
			headers,
			baseURL: baseUrl,
			withCredentials: true,
		});
	};

	const loginOrganization = async (organizationId: number | string): Promise<OrganizationSecurityApi | undefined> => {
		OrganizationIdService.clear();
		try {
			const response = await apiSecurity.organization().getOrganizationMe(organizationId);
			const organizationMe = response.data.data;
			OrganizationIdService.update(organizationMe.id);
			if (!organizationMe) return undefined;
			const organizationState = organizationMe as OrganizationState;
			organizationState.more = response.data.more;
			dispatch(authOrganization(organizationState));
			return organizationMe;
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		return undefined;
	};

	const logoutOrganization = () => {
		OrganizationIdService.clear();
		dispatch(clearOrganization());
	};

	const refreshUser = async (token?: string) => {
		if (token === undefined) token = accessToken;
		let userResponse;
		try {
			userResponse = await getUser(token);
		} catch (e: any) {
			if (e.response.status === 401) {
				const newToken = await getAccessToken(SessionIdService.get());
				AccessTokenSessionService.update(newToken.data.access_token);
				const session: SessionState = { token: newToken.data.access_token, isAuthenticated: false };
				dispatch({
					type: SessionActionTypes.SET,
					payload: { ...session },
				});
				userResponse = await getUser(newToken.data.access_token);
			} else {
				throw e;
			}
		}
		const userData = userResponse.data.data;
		const user: UserState = userData;
		user.more = userResponse.data.more;
		dispatch({
			type: UserActionTypes.SET,
			payload: user,
		});
	};
	const checkLogin = async () => {
		try {
			let token;
			if (AccessTokenSessionService.isExpired()) {
				const sessionId = SessionIdService.get();
				if (!sessionId) {
					return;
				}
				const tokenResponse = await getAccessToken(sessionId);
				token = tokenResponse.data.access_token;
			} else {
				token = AccessTokenSessionService.get();
			}
			const session: SessionState = { token, isAuthenticated: true };
			if (null !== token) {
				await refreshUser(token);
				dispatch({
					type: SessionActionTypes.SET,
					payload: { ...session },
				});
			}
		} catch (e) {
			const sessionId = SessionIdService.get();
			const currentUrl = window.location.href;
			if (e instanceof BadRequestException) {
				if (
					e.data.error !== undefined &&
					e.data.error.includes("invalid_grant") &&
					e.data.error_description !== undefined &&
					e.data.error_description.includes("session_id")
				) {
					const actionLogout = AuthCache.get() === sessionId;
					AuthCache.update(sessionId);
					await logout(actionLogout, currentUrl);
				} else {
					SessionIdService.remove();
					await logout(true);
				}
			} else if (e instanceof UnauthorizedException) {
				if (
					e.data.error !== undefined &&
					e.data.error.includes("unauthorized_user") &&
					e.data.error_description !== undefined &&
					e.data.error_description.includes("token expired")
				) {
					await logout(true, currentUrl);
				} else {
					await logout(true);
				}
			} else if (e instanceof BadRequestException || e instanceof ForbiddenException) {
				await logout();
			} else {
				throw e;
			}
		}
	};

	const logout = (actionLogout?: boolean, redirectUrl?: string) => {
		const session: SessionState = { token: undefined, isAuthenticated: false };
		const previousSessionId = SessionIdService.get();
		dispatch({
			type: SessionActionTypes.SET,
			payload: session,
		});
		dispatch({
			type: UserActionTypes.SET,
			payload: null,
		});
		AccessTokenSessionService.remove();
		SessionIdService.remove();
		if (actionLogout) {
			const url = redirectUrl ? redirectUrl : domain();
			if (previousSessionId) {
				window.location.href = `${credentials?.accountsUrl}/logout?reference_url=${url}&session_id=${previousSessionId}`;
			} else {
				window.location.href = `${credentials?.accountsUrl}/logout?reference_url=${url}`;
			}
		}
	};

	const login = async (email: string, password: string, rememberMe?: boolean, preventRedirect?: boolean) => {
		// setErrors([]);
		// setLoading(true);

		// try {
		const res = await postLogInUser(email, password, rememberMe);
		SessionIdService.update(res.data?.session_id);
		const sessionId = SessionIdService.get();
		if (preventRedirect) {
			return;
		}
		const tokenResponse = await getAccessToken(sessionId);
		const token = tokenResponse.data;
		const session: SessionState = { token: token.access_token, isAuthenticated: true };
		if (null !== token) {
			await refreshUser(token.access_token);
			dispatch({
				type: SessionActionTypes.SET,
				payload: session,
			});
		}
		if (!preventRedirect) {
			history.push("/");
		}
	};

	return {
		login,
		logout,
		checkLogin,
		loginOrganization,
		postLogInUserSessionId,
		logoutOrganization,
		refreshUser,
		setShowReminderPasswordModal,
		showReminderPasswordModal,
	};
};
