import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { FormatDate, FormatDateToDateHoursRange, FormatDuration } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { EmployeeApi, WorkTimeApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import WorktimeModalForm from "../Index/components/EmployeePreview/WorktimeModalForm";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "WORK_TIME";
const resourceType = "WORK_TIME";

const List: FC<ListState> = ({ resource }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [params, setParams] = useState<Record<string, any>>({});
	const [items, setItems] = useState<WorkTimeApi[]>([]);
	const [workTime, setWorkTime] = useState<WorkTimeApi | undefined>();
	const [employee, setEmployee] = useState<EmployeeApi | undefined>();
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;

	const onRefresh = async (fetchParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(fetchParams);
			setItems(res);
		}
	};

	const onPayWorkTime = async (worktime: WorkTimeApi) => {
		if (worktime.status === "PAID") {
			await api.organization().workTimeCancelPay(String(worktime.id));
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			await onRefresh(params);
		} else {
			await api.organization().workTimePay(worktime.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			await onRefresh(params);
		}
	};

	const onPayWorkTimes = async (
		multipleActionItems: WorkTimeApi[],
		listParams?: ListParamsType,
		areAllItemsSelected?: boolean,
		multipleActionParams?: MultipleActionsParams
	) => {
		const idsAsString = multipleActionItems.map((item) => item.id.toString()).join(",");

		try {
			const formattedParams = listParams ? listDataParams(listParams) : {};
			formattedParams.include = "workplace, employee";

			await api.organization().workTimesPay(idsAsString, multipleActionParams, areAllItemsSelected);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			await onRefresh(formattedParams);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onCancelPayWorkTimes = async (
		multipleActionItems: WorkTimeApi[],
		listParams?: ListParamsType,
		areAllItemsSelected?: boolean,
		multipleActionParams?: MultipleActionsParams
	) => {
		const idsAsString = multipleActionItems.map((item) => item.id.toString()).join(",");
		try {
			const formattedParams = listParams ? listDataParams(listParams) : {};
			formattedParams.include = "workplace, employee";

			await api.organization().workTimeCancelPay(idsAsString, multipleActionParams, areAllItemsSelected);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			await onRefresh(formattedParams);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRestoreWorkTime = async (worktime: WorkTimeApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreWorktime(worktime.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			await onRefresh(params);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemoveWorkTime = async (worktime: WorkTimeApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeWorktime(worktime.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			await onRefresh(params);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const fetchEmployee = async (item: WorkTimeApi) => {
		const params: Record<string, any> = { include: "workplaces" };
		try {
			const res = await api.organization().getEmployee(Number(item.employee_id), params);
			setEmployee(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const drawWorkTimeStatus = (worktime: WorkTimeApi) => {
		switch (worktime.status) {
			case "CONFIRMED":
				return <span className="badge bg-danger">{t("enums.worktimes.status.CONFIRMED")}</span>;
			case "SKIPPED":
				return <span className="badge bg-warning text-dark">{t("enums.worktimes.status.SKIPPED")}</span>;
			case "PAID":
				return <span className="badge bg-success">{t("enums.worktimes.status.PAID")}</span>;
			case "DELETED":
				return <span className="badge bg-danger">{t("enums.worktimes.status.DELETED")}</span>;
			default:
				return "";
		}
	};

	const data = resource.read();
	let config = {
		fields: [
			{
				id: "start_at",
				name: t("modules.work_time.field.start_date.title"),
				type: "date" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatDate(item.start_at);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatDate(item.start_at);
				},
			},
			{
				id: "end_at",
				name: t("modules.work_time.field.end_date.title"),
				type: "date" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatDate(item.end_at);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatDate(item.end_at);
				},
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
				render: (item: WorkTimeApi) => {
					return (
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/employees/${item.employee_id}`}
						>
							{item.employee?.name}
						</Link>
					);
				},
				renderExport: (item: WorkTimeApi) => item.employee?.name,
			},
			{
				id: "hourly_rate",
				name: t("modules.work_time.field.stake.title"),
				type: "number" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatMoney(item.hourly_rate);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatMoney(item.hourly_rate);
				},
			},
			{
				id: "time",
				name: t("common.word.work_duration"),
				type: "number" as FilterType,
				render: (item: WorkTimeApi) => FormatDuration(item.duration_in_minutes, t, true),
				renderCsv: (item: WorkTimeApi) =>
					`${Math.floor(item.duration_in_minutes / 60)}.${Number(
						((item.duration_in_minutes % 60) / 60).toFixed(2).slice(2, 4)
					)}`,
			},
			{
				id: "to_pay",
				name: t("modules.work_time.field.to_pay.title"),
				type: "number" as FilterType,
				render: (item: WorkTimeApi) => {
					return FormatMoney(item.to_pay);
				},
				renderExport: (item: WorkTimeApi) => {
					return FormatMoney(item.to_pay);
				},
			},
			{
				id: "workplace",
				name: t("modules.work_time.field.workplace.title"),
				type: "search_select" as FilterType,
				render: (item: WorkTimeApi) => {
					return <span>{item.workplace?.name}</span>;
				},
				renderExport: (item: WorkTimeApi) => {
					return item.workplace?.name;
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getWorkplacesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			} as ListConfigField,
			{
				id: "status",
				name: t("lib:common.word.status"),
				render: (item: WorkTimeApi) => drawWorkTimeStatus(item),
				renderExport: (item: WorkTimeApi) => t(`enums.worktimes.status.${item.status}`),
				type: "list" as FilterType,
				options: {
					PAID: t("enums.worktimes.status.PAID"),
					CONFIRMED: t("enums.worktimes.status.CONFIRMED"),
					SKIPPED: t("enums.worktimes.status.SKIPPED"),
					DELETED: t("enums.worktimes.status.DELETED"),
				},
			},
			{
				id: "source",
				name: t("lib:common.word.source"),
				type: "list",
				options: {
					POS: t("enums.employees_online.source.POS"),
					WEB: t("enums.employees_online.source.WEB"),
				},
				render: (item: WorkTimeApi) => t(`enums.employees_online.source.${item.source}`),
			},
		],
		filters: [
			{
				id: "report",
				name: t("common.word.pos_report"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: Record<string, any>) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at
						)})`;
					},
				} as ListFilterSource,
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: WorkTimeApi) => {
					fetchEmployee(item);
					setWorkTime(item);
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: WorkTimeApi) => onRemoveWorkTime(item),
				visible: (item: WorkTimeApi) => item.status !== "DELETED",
			},
			{
				name: t("common.action.restore", { ns: "lib" }),
				click: (item: WorkTimeApi) => onRestoreWorkTime(item),
				visible: (item: WorkTimeApi) => item.status === "DELETED",
			},
			{
				name: t("modules.employee.action.cancel_pay.title"),
				click: (item: WorkTimeApi) => onPayWorkTime(item),
				visible: (item: WorkTimeApi) => {
					return item.status === "PAID";
				},
			},
			{
				name: t("modules.employee.action.pay.title"),
				click: (item: WorkTimeApi) => onPayWorkTime(item),
				visible: (item: WorkTimeApi) => {
					return item.status === "CONFIRMED";
				},
			},
		],
		multipleActions: [
			{
				name: t("modules.employee.action.cancel_pay.title"),
				click: (
					multipleActionsItems: WorkTimeApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					onCancelPayWorkTimes(multipleActionsItems, listParams, areAllItemsSelected, multipleActionParams);
				},
				visible: (multipleActionsItems: WorkTimeApi[]) =>
					multipleActionsItems.filter((f) => f.status === "PAID").length > 0,
			},
			{
				name: t("modules.employee.action.pay.title"),
				click: (
					multipleActionsItems: WorkTimeApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					onPayWorkTimes(multipleActionsItems, listParams, areAllItemsSelected, multipleActionParams);
				},
				visible: (multipleActionsItems: WorkTimeApi[]) =>
					multipleActionsItems.filter((f) => f.status !== "PAID").length > 0,
			},
		],
		selectedColumns: [
			"start_at",
			"end_at",
			"employee",
			"workplace",
			"hourly_rate",
			"time",
			"to_pay",
			"status",
			"source",
		],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.employee.field.export_config.title"),
			filename: t("modules.employee.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "employee,workplace,custom_fields";
			setParams(params);
			return api.organization().getWorkTimes(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		fetchTotalItemsCountForSelectedFilters: (params: Record<string, any>, sourceToken?: CancelTokenSource) =>
			api.organization().getWorktimesCount(params, { cancelToken: sourceToken?.token }),
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{workTime && employee && (
				<WorktimeModalForm
					workplaceIds={[...employee.workplaces.map((x) => x.workplace_id), employee.workplace_id].filter(
						(f) => f !== undefined
					)}
					employee={workTime.employee as EmployeeApi}
					worktime={workTime}
					handleClose={() => setWorkTime(undefined)}
					handleSave={async () => {
						setWorkTime(undefined);
						if (config.fetch) {
							const res = await config.fetch(params);
							setItems(res);
						}
					}}
				/>
			)}
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.work_time.header.title")}
						description={t("modules.work_time.field.empty_list.helptext.description")}
					/>
				}
				onFetch={(fetchItems: WorkTimeApi[]) => {
					setItems(fetchItems);
				}}
				canManageMultiActionsForAllItems
			/>
		</>
	);
};

const OrganizationEmployeesWorktimesPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.work_time.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.work_time.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
		</div>
	);
};
export default OrganizationEmployeesWorktimesPage;
