import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import EntityStatus from "go-list/core/components/Table/components/EntityStatus";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import LastActivities from "../../../../../../../../../../components/Common/LastActivities/LastActivities";
import {
	EmployeeActivityApi,
	EmployeeApi,
	EmployeeInfoApi,
	EmployeeTransactionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ContactCard from "./ContactCard";
import InfoCard from "./InfoCard";
import LastTransactions from "./LastTransactions";
import RecalculateWorktimesModal from "./RecalculateWorktimesModal";
import WorkedHours from "./WorkedHours";
import WorktimesCard from "./WorktimesCard";

interface Props {
	employee: EmployeeApi;
	employeeInfo: EmployeeInfoApi;
	handleAddWorkTime: () => void;
}

const EmployeePreview = (props: Props): JSX.Element => {
	const [employee, setEmployee] = useState(props.employee);
	const [showLogs, setShowLogs] = useState(false);
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const hasVenueEmployeeHourlyRateAccess = useSelector(hasPermission("VENUE_EMPLOYEE_HOURLY_RATE_ACCESS"));
	const user = useSelector(selectUser);
	const { addFlash, addSuccessFlash } = useFlash();
	const [showRecalculateWorktimesModal, setShowRecalculateWorktimesModal] = useState(false);
	const [transactions, setTransactions] = useState<EmployeeTransactionApi[]>([]);
	const [activities, setActivities] = useState<EmployeeActivityApi[]>([]);
	const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false);
	const [loadingActivities, setLoadingActivities] = useState<boolean>(false);
	const confirmation = useConfirmation();
	const params: Record<string, any> = {
		include: "role,role.permissions,workplace,workplaces,workplaces.workplace,work_times",
	};
	const venueOrderShowPermission = useSelector(hasPermission("VENUE_ORDER_SHOW"));
	const isAdmin = user.roles?.includes("ROLE_ADMIN");
	const hasAccess = isAdmin || venueOrderShowPermission;
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		(() => {
			setEmployee(props.employee);
			fetchEmployeeTransactions();
			fetchEmployeeActivities();
		})();
	}, [props.employee]);

	const fetchEmployeeTransactions = async () => {
		if (hasAccess) {
			setLoadingTransactions(true);
			try {
				const params: Record<string, any> = { include: "payment_method,contexts,infos", size: 5 };
				const res = await api.organization().getEmployeeTransactions(props.employee.id, params);
				setTransactions(res);
			} catch (err) {
				handleError.alert(err, addFlash);
			}
			setLoadingTransactions(false);
		}
	};

	const fetchEmployeeActivities = async () => {
		if (hasAccess) {
			setLoadingActivities(true);
			try {
				const params: Record<string, any> = { size: 5 };
				const res = await api.organization().getEmployeeActivities(props.employee.id, params);
				setActivities(res);
			} catch (err) {
				handleError.alert(err, addFlash);
			}
			setLoadingActivities(false);
		}
	};

	const removeEmployee = async (employee: EmployeeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.irreversible_action", { ns: "lib" }),
		});
		try {
			await api.organization().removeEmployee(employee.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/employees`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const fireEmployee = async (employee: EmployeeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			const res = await api.organization().fireEmployee(employee.id, params);
			setEmployee({ ...employee, ...res });
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const hireEmployee = async (employee: EmployeeApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			const res = await api.organization().hireEmployee(employee.id, params);
			addSuccessFlash(t("lib:common.flash.completed"));
			setEmployee({ ...employee, ...res });
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const copyEmployee = () => {
		const {
			employee: { id: employeeId },
		} = props;
		history.push({ pathname: `/${organization.id}/employees/new`, state: { employeeId } });
	};

	const buttons: ButtonProps[] = [
		{
			path: `/${organization.id}/employees/${employee.id}/edit`,
			variant: "light",
			title: t("common.action.edit", { ns: "lib" }),
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			variant: "light",
		},
	];
	if (employee.status !== "DELETED")
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeEmployee(employee),
			dropdown: true,
		});
	if (employee.status === "HIRED")
		buttons.push({
			title: t("modules.employee.action.fire.title"),
			action: () => fireEmployee(employee),
			dropdown: true,
		});
	if (employee.status === "FIRED")
		buttons.push({
			title: t("modules.employee.action.hire.title"),
			action: () => hireEmployee(employee),
			dropdown: true,
		});
	buttons.push({
		title: t("modules.employee.action.recalculate_worktimes.title"),
		action: () => setShowRecalculateWorktimesModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.copy", { ns: "lib" }),
		action: copyEmployee,
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			path: `/${organization.id}/employees/${employee.id}/edit`,
			title: t("common.action.edit", { ns: "lib" }),
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeEmployee(employee),
			hide: employee.status === "DELETED",
		},
		{
			title: t("modules.employee.action.fire.title"),
			action: () => fireEmployee(employee),
			hide: employee.status !== "HIRED",
		},
		{
			title: t("modules.employee.action.hire.title"),
			action: () => hireEmployee(employee),
			hide: employee.status !== "FIRED",
		},
		{
			title: t("modules.employee.action.recalculate_worktimes.title"),
			action: () => setShowRecalculateWorktimesModal(true),
		},
		{
			title: t("common.action.copy", { ns: "lib" }),
			action: copyEmployee,
		},
	];

	const renderTitle = () => (
		<>
			"{employee.name}"
			<span>
				<EntityStatus status={props.employeeInfo.is_online ? "ENABLED" : "DISABLED"} />
			</span>
			<FormatResourceStatus status={employee.status} />
		</>
	);

	const renderSubtitle = () => (
		<ul>
			<li>
				{employee?.venue_role?.name && <div className="me-3 d-inline-block">{employee?.venue_role?.name}</div>}
				{employee.workplace ? (
					<div className="me-3 d-inline-block">
						{t("modules.employee.field.workplace.title")} {employee.workplace?.name}{" "}
						{employee.hourly_rate?.amount ? `${FormatMoney(employee.hourly_rate)}/h` : null}
					</div>
				) : (
					<>
						{(employee.hourly_rate?.amount || employee.hourly_rate?.amount === 0) && (
							<div className="me-3 d-inline-block">{`${FormatMoney(employee.hourly_rate)}/h`}</div>
						)}
					</>
				)}
				{hasVenueEmployeeHourlyRateAccess &&
					employee.workplaces.map((emp, index) => {
						return (
							<div key={index} className="me-3 d-inline-block">
								{t("modules.employee.field.workplace.title")} {emp.workplace.name} (
								{FormatMoney(emp.hourly_rate)}/h)
							</div>
						);
					})}
			</li>
		</ul>
	);

	const avatar = {
		image: employee.avatar_link,
		label: employee.name,
		color: employee.color,
	};

	return (
		<div className="content-preview">
			<MobileActions actions={mobileActions} />
			<Header
				title={renderTitle()}
				buttons={isMobile ? [] : buttons}
				subtitle={renderSubtitle()}
				avatar={avatar}
			/>
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={employee.id}
					resourceType={"EMPLOYEE"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			{showRecalculateWorktimesModal && (
				<RecalculateWorktimesModal
					employee={employee}
					isShown={showRecalculateWorktimesModal}
					onHide={() => setShowRecalculateWorktimesModal(false)}
				/>
			)}
			<div className="content-preview-body">
				<div className="row">
					<div className="col-md-4">
						<ContactCard employee={employee} />
						<InfoCard
							employee={employee}
							employeeInfo={props.employeeInfo}
							loadingActivities={loadingActivities}
							lastActivity={activities[0]?.created_at ?? ""}
						/>
						{hasAccess && (
							<LastActivities
								resourceType={"EMPLOYEE"}
								resourceId={employee.id}
								data={activities}
								loadingActivities={loadingActivities}
							/>
						)}
					</div>
					<div className="col-md-8">
						<WorkedHours employee={employee} employeeInfo={props.employeeInfo} />
						<WorktimesCard
							handleAddWorkTime={props.handleAddWorkTime}
							employee={employee}
							employeeInfo={props.employeeInfo}
						/>
						{hasAccess && (
							<LastTransactions
								employee={employee}
								transactions={transactions}
								loadingTransactions={loadingTransactions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default EmployeePreview;
