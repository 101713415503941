import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PdfOrientationType } from "go-core/types";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { CustomFieldContext } from "./services/context";

interface ListState {
	resource: any;
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	mobileActions: MobileActionProps[];
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

interface Props {
	routeProps: RouteComponentProps;
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
	customBrowserTabTitle?: string;
}

const listName = "CUSTOM_FIELD";
const resourceType = "CUSTOM_FIELD";

const List: FC<ListState> = ({
	resource,
	organization_name,
	companyName,
	taxIdNo,
	mobileActions,
	pdfOrientation,
	pdfFontSize,
}) => {
	const [items, setItems] = useState<CustomFieldTemplateApi[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const location = useLocation();
	const { t } = useTranslation();

	const segmentContextValue = React.useContext(SegmentContext);
	const customFieldService = React.useContext(CustomFieldContext);
	const confirmation = useConfirmation();
	const { addSuccessFlash, addFlash } = useFlash();
	if (!resource) return null;
	const data = resource.read();

	const typeOptions = customFieldService.types().reduce((accumulator, value) => {
		return {
			...accumulator,
			[value.id]: value.title,
		};
	}, {});

	const onRemove = async (item: CustomFieldTemplateApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await customFieldService.api().remove(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRestore = async (item: CustomFieldTemplateApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await customFieldService.api().restore(item.id);
			addSuccessFlash(t("flash.activated"));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res);
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: CustomFieldTemplateApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={item.status}
							minWidth={200}
							to={`${location.pathname}/${item.id}`}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: CustomFieldTemplateApi) => item.name,
			},
			{
				id: "slug",
				name: t("lib:go_component.custom_field.field.slug.title"),
				type: "text",
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "list",
				listOptions: {
					DATE: t("lib:enums.custom_field.type.DATE"),
					NUMBER: t("lib:enums.custom_field.type.NUMBER"),
					TEXT: t("lib:enums.custom_field.type.TEXT"),
				},
				render: (item: CustomFieldTemplateApi) => t(`lib:enums.custom_field.type.${item.type}`),
			},
			{
				id: "resource_type",
				name: t("lib:go_component.custom_field.field.resources.title"),
				render: (option: CustomFieldTemplateApi) => {
					return option.resources.map((x) => customFieldService.getTypeTitle(x.resource)).join(", ");
				},
			},
			{
				id: "permissions",
				name: t("lib:go_component.custom_field.field.permissions.title"),
				render: (item: CustomFieldTemplateApi) => {
					return item?.permissions
						?.map((per) => {
							let result = per.role;
							if (per.scope) result += ` - ${t(`lib:enums.custom_field.scope.${per.scope}`)}`;
							return result;
						})
						.join(", ");
				},
			},
			{
				id: "options",
				disableSorting: true,
				name: t("lib:go_component.custom_field.field.options.title"),
				render: (item: CustomFieldTemplateApi) => {
					return item.options.map((opt: { value: string }) => opt.value).join(", ");
				},
			},
			{
				id: "constraint_type",
				name: t("lib:go_component.custom_field.field.constraints.title"),
				type: "list",
				listOptions: {
					UNIQUE: t("lib:enums.custom_field.constraints.UNIQUE"),
					REGEX: t("lib:enums.custom_field.constraints.REGEX"),
					NOTNULL: t("lib:enums.custom_field.constraints.NOTNULL"),
				},
				render: (item: CustomFieldTemplateApi) => {
					return item?.constraints
						?.map((constraint) => {
							let result = t(`lib:enums.custom_field.constraints.${constraint?.type}`);
							if (constraint.value) result += `- ${constraint?.value}`;
							return result;
						})
						.join(", ");
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
			{
				id: "options",
				name: t("lib:go_component.custom_field.field.option.title"),
				type: "text",
			},
			{
				id: "permissions",
				name: t("lib:go_component.custom_field.field.permission.title"),
				type: "text",
			},
			{
				id: "resource_type",
				name: t("lib:go_component.custom_field.field.resource.title"),
				type: "list",
				listOptions: typeOptions,
			},
		],
		selectedColumns: ["name", "slug", "type", "options"],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: CustomFieldTemplateApi) => `${location.pathname}/${item.id}`,
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: CustomFieldTemplateApi) => onRemove(item),
				visible: (item: CustomFieldTemplateApi) => item.status === "ENABLED",
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: CustomFieldTemplateApi) => onRestore(item),
				visible: (item: CustomFieldTemplateApi) => item.status === "DELETED",
			},
		],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
			{
				id: "deleted",
				name: `${t("go_list.filters.deleted", { ns: "lib" })}`,
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e",
					},
				],
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			const newParams = { ...fetchParams, include: "options,resources,permissions,constraints" };
			setParams(newParams);
			return customFieldService.api().all(newParams, { cancelToken: sourceToken?.token });
		},
		exportConfig: {
			title: t("lib:go_component.custom_field.list.export_config.title"),
			filename: t("lib:go_component.custom_field.list.export_config.filename"),
			organization: `${organization_name}`,
			company: companyName,
			taxIdNo,
			pdfFontSize,
			pdfOrientation,
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};
	return <ListData data={items} config={config} onFetch={setItems} mobileActions={mobileActions} />;
};

const CustomFieldList = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<any>();
	const segmentContextValue = React.useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const mobileActions: MobileActionProps[] = [
		{
			title: t("lib:common.action.add"),
			path: `${props.routeProps.match.url}/new`,
		},
	];
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(props.customBrowserTabTitle ?? t("lib:go_component.custom_field.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && (
				<Header
					title={t("lib:go_component.custom_field.header.title")}
					buttons={[
						{
							title: t("lib:common.action.add"),
							path: `${props.routeProps.match.url}/new`,
							variant: "primary",
						},
					]}
				/>
			)}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					organization_name={props.organization_name}
					companyName={props.companyName}
					taxIdNo={props.taxIdNo}
					mobileActions={mobileActions}
					pdfOrientation={props.pdfOrientation}
					pdfFontSize={props.pdfFontSize}
				/>
			</Suspense>
		</div>
	);
};
export default CustomFieldList;
