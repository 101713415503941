import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import { getDayName } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { ListFilterSource } from "go-list/core/components/Filter/services/types";
import ReportData from "go-report/reports";
import {
	getChartType,
	getLastMonthFilterBetween,
	reportParamsConverter,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { api } from "../../../../../../../../services/Api/api";
import { ReportKdsUtils } from "../../../../../../../../utils/reports/ReportKdsUtils";
import ReportShareUtils from "../../../../../../../../utils/reports/ReportShareUtils";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "KDS_REPORT";
const resourceType = "KDS_REPORT";

const Report: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();
	const columnsFromLocalStorage = window.localStorage.getItem(`go_report.employees.columns`)?.split(",");

	let config = {
		reportConfigId: "kds_report",
		aggregatePrefix: "summary",
		type: "bill",
		chart: {
			title: t("modules.preparation_status_report.field.report.title"),
			lineTitle: t("modules.preparation_status_report.field.report_chart.title"),
			aggregate: "average_total_time",
			type: getChartType(),
			parseGroup: (group: string) => {
				if (group === "HOUR_OF_DAY") return "ORDER_ITEM_CREATED_AT_HOUR";
				if (group === "DAY_OF_MONTH") return "ORDER_ITEM_CREATED_AT_DAY";
				if (group === "DATE") return "ORDER_ITEM_CREATED_AT";
				if (group === "DAY_OF_WEEK") return "ORDER_ITEM_CREATED_AT_DAY_OF_WEEK";
				return group;
			},
		},
		groups: [
			{
				id: "DIRECTION",
				name: t("components.reports.groups.DIRECTION"),
			},
			{
				id: "ITEM",
				name: t("components.reports.groups.ITEM"),
			},
			{
				id: "CATEGORY",
				name: t("components.reports.groups.CATEGORY"),
			},
			{
				id: "ORDER_ITEM_CREATED_AT_HOUR",
				name: t("components.reports.groups.ORDER_ITEM_CREATED_AT_HOUR"),
			},
			{
				id: "ORDER_ITEM",
				name: t("components.reports.groups.ORDER_ITEM"),
			},
			{
				id: "ORDER_NUMBER",
				name: t("components.reports.groups.ORDER_NUMBER"),
			},
			{
				id: "ORDER_ITEM_CREATED_AT_DAY",
				name: t("components.reports.groups.ORDER_ITEM_CREATED_AT_DAY"),
			},
			{
				id: "ORDER_ITEM_CREATED_AT_DAY_OF_WEEK",
				name: t("components.reports.groups.ORDER_ITEM_CREATED_AT_DAY_OF_WEEK"),
			},
			...ReportKdsUtils.getGroups(t),
			...ReportShareUtils.getGroups(t),
		],
		selectedColumns: columnsFromLocalStorage ? columnsFromLocalStorage : ReportKdsUtils.getSelectedColumns(),
		selectedGroups: ["NONE"],
		groupsInfo: {
			EMPLOYEE: (groupByValue: Record<string, any>) => {
				return groupByValue.id !== "null" ? (
					<Link to={`/${organization.id}/employees/${groupByValue.id}`} rel="noreferrer" target="_blank">
						{groupByValue.name}
					</Link>
				) : (
					"-"
				);
			},
			ORDER_ITEM_CREATED_AT_DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
		},
		showPercent: true,
		columns: ReportKdsUtils.getColumns(t),
		filters: [
			{
				id: "item",
				name: t("modules.preparation_status_report.field.item.title"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "category",
				name: t("common.word.item_group_category"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getCategoriesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "direction",
				name: t("common.word.direction"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getDirectionsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "order_item_created_at",
				name: t("modules.preparation_status_report.field.order_item_created_at.title"),
				type: "date",
			},
			{
				id: "average_delivery_time",
				name: t("modules.preparation_status_report.field.average_delivery_time.title"),
				type: "number",
			},
			...ReportKdsUtils.getFilters(t),
		],
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				groups: ["NONE"],
				columns: columnsFromLocalStorage ? columnsFromLocalStorage : ReportKdsUtils.getSelectedColumns(),
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			const chartParams = { ...params };
			if (!chartParams.groups || chartParams.groups === "HOUR_OF_DAY") {
				chartParams.groups = "HOUR";
			}
			delete chartParams.group_column;
			delete params.chart_type;
			chartParams.duration_unit = "minute";
			chartParams.groups = [chartParams.chart_type];
			return Promise.all([
				api.organization().getKdsReport(reportParamsConverter(params), { cancelToken: sourceToken?.token }),
				api
					.organization()
					.getKdsReport(reportParamsConverter(chartParams), { cancelToken: sourceToken?.token }),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			if (params.chart_type === "HOUR_OF_DAY") params.chart_type = "HOUR";
			params.groups = params.chart_type;
			params.duration_unit = "minute";
			delete params.group_column;
			return api.organization().getKdsReport(reportParamsConverter(params), { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.preparation_status_report.header.title"),
			filename: t("modules.preparation_status_report.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
	} as ReportConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

const OrganizationReportsKdsPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} />
		</Suspense>
	);
};

export default OrganizationReportsKdsPage;

// t("modules.preparation_status_report.field.ORDER_ITEM_CREATED_AT_HOUR.title");
// t("modules.preparation_status_report.field.ORDER_ITEM_CREATED_AT_DAY.title");
// t("modules.preparation_status_report.field.ORDER_ITEM_CREATED_AT.title");
// t("modules.preparation_status_report.field.ORDER_ITEM_CREATED_AT_DAY_OF_WEEK.title");
