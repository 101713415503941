import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as RemoveSVG } from "go-core/images/svg/actions/remove.svg";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_SEPARATOR, FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { AppLinkApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	form: UseFormReturn<AppLinkApi>;
}

export const GoCRMItemTypeTable = ({ form }: Props) => {
	const {
		control,
		formState: { errors },
	} = form;
	const { fields, append, remove } = useFieldArray({ name: "items", control });
	const { t } = useTranslation();

	const getItemNamesSearchSelect = async (
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	) => {
		const alreadyUsedItemsIds = fields.map((field) => field.item_id).join(FILTER_VALUE_SEPARATOR);
		const itemsIdsFilterValue = `${FILTER_SEPARATOR}id|ne=${alreadyUsedItemsIds}`;
		const newParams = { ...params, f: itemsIdsFilterValue };

		const searchSelectItems = await api
			.organization()
			.getItemsSearchSelect(search, newParams, { cancelToken: options?.token });

		return searchSelectItems.map((item: Record<string, any>) => ({ item_id: item.id, item_name: item.label }));
	};

	return (
		<>
			<table className="table mt-2">
				<thead>
					<tr>
						<th style={{ paddingLeft: "0" }}>{t("common.word.item_group")}</th>
					</tr>
				</thead>
				<tbody>
					{fields.map((field, index) => {
						return (
							<tr key={field.id} className="d-flex align-items-center">
								<td className="w-50" style={{ paddingLeft: "0" }}>
									<FormSelectGroup
										control={control}
										errors={errors}
										name={`items.${index}.item_id`}
										loadOptions={getItemNamesSearchSelect}
										getOptionValue={(option) => option.item_id}
										getOptionLabel={(option) => option.item_name}
										isClearable={false}
										defaultValue={{
											item_id: field.item_id,
											item_name: field.item_name,
										}}
									/>
								</td>
								<td className="action">
									<RemoveSVG onClick={() => remove(index)} className="icon" />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<Button variant="add" style={{ paddingLeft: "0" }} onClick={() => append({ item_id: 0 })}>{`+ ${t(
				"common.action.add",
				{
					ns: "lib",
				}
			)}`}</Button>
		</>
	);
};
