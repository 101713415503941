import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatBoolean, { convertBoolean } from "go-core/components/Formatters/FormatBoolean";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { DefaultCommentApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import DefaultCommentModalForm from "../../components/DefaultCommentModalForm";

interface ListState {
	resource?: Record<string, any>;
	items: DefaultCommentApi[];
	setItems: (data: DefaultCommentApi[]) => void;
	setDefaultComment: (value: DefaultCommentApi) => void;
	mobileActions: MobileActionProps[];
}

const listName = "DEFAULT_COMMENT";
const resourceType = "DEFAULT_COMMENT";

const List: FC<ListState> = ({ resource, items, setItems, setDefaultComment, mobileActions }): JSX.Element | null => {
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const updateDefaultComment = (item: DefaultCommentApi) => setDefaultComment(item);

	const onRestoreDefaultComment = async (item: DefaultCommentApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			await api.organization().restoreDefaultComment(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRemoveDefaultComment = async (item: DefaultCommentApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeDefaultComment(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "list" as FilterType,
				options: {
					ORDER: t("enums.default_comments.types.ORDER"),
					ORDER_ITEM: t("enums.default_comments.types.ORDER_ITEM"),
					ORDER_ITEM_STORNO_REASON: t("enums.default_comments.types.ORDER_ITEM_STORNO_REASON"),
					ORDER_REMOVE_REASON: t("enums.default_comments.types.ORDER_REMOVE_REASON"),
					ORDER_CLOSE: t("enums.default_comments.types.ORDER_CLOSE"),
				},
				render: (item: DefaultCommentApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<RenderLimitedText
								minWidth={200}
								onClick={() => updateDefaultComment(item)}
								textStyleIsLink
							>
								{t(`enums.default_comments.types.${item.type}`)}
							</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: DefaultCommentApi) => {
					return t(`enums.default_comments.types.${item.type}`);
				},
			},
			{
				id: "content",
				name: t("modules.default_comment.field.content.title"),
				type: "text",
			},
			{
				id: "high_priority",
				name: t("modules.default_comment.field.high_priority.title"),
				type: "boolean" as FilterType,
				render: (item: DefaultCommentApi) => <FormatBoolean value={item.high_priority} />,
				renderExport: (item: DefaultCommentApi) => convertBoolean(item.high_priority, t),
			} as ListConfigField,
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: DefaultCommentApi) => {
					updateDefaultComment(item);
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: DefaultCommentApi) => {
					onRemoveDefaultComment(item);
				},
				visible: (item: DefaultCommentApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: DefaultCommentApi) => {
					onRestoreDefaultComment(item);
				},
				visible: (item: DefaultCommentApi) => {
					return item.status === "DELETED";
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		selectedColumns: ["type", "content", "high_priority"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		exportConfig: {
			title: t("modules.default_comment.field.export_config.title"),
			filename: t("modules.default_comment.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(fetchParams);
			return api.organization().getDefaultComments(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			onFetch={setItems}
			config={config}
			emptyList={{
				addAction: () => setDefaultComment({} as DefaultCommentApi),
			}}
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationSettingsDefaultCommentsIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const [defaultComment, setDefaultComment] = useState<DefaultCommentApi | undefined>(undefined);
	const [items, setItems] = useState<DefaultCommentApi[]>([]);
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.default_comment.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const handleSave = (currency: DefaultCommentApi) => {
		setDefaultComment(undefined);
		const index = items?.findIndex((dataItem) => dataItem.id === currency.id);
		if (index !== -1 && items) {
			items[index] = currency;
			setItems([...items]);
		} else {
			setItems([...items, currency]);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setDefaultComment({} as DefaultCommentApi),
			variant: "primary",
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setDefaultComment({} as DefaultCommentApi),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.default_comment.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					items={items}
					setItems={setItems}
					setDefaultComment={setDefaultComment}
					mobileActions={mobileActions}
				/>
			</Suspense>
			{defaultComment && (
				<DefaultCommentModalForm
					defaultComment={defaultComment}
					onHide={() => setDefaultComment(undefined)}
					handleSave={handleSave}
				/>
			)}
		</>
	);
};
export default OrganizationSettingsDefaultCommentsIndexPage;
