import React, { FC, ReactFragment } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as CircleInfoSVG } from "go-core/images/svg/circle-info.svg";

export interface EmptyDataConfigAction {
	name: string;
	click: any;
	variant?: string;
}

export interface EmptyDataConfig {
	title?: string;
	description?: string;
	content?: ReactFragment;
	actions?: EmptyDataConfigAction[];
	styles?: Record<string, any>;
	full?: boolean;
}

const EmptyData: FC<EmptyDataConfig> = (props) => {
	const { t } = useTranslation();
	const description = props.description ? props.description : "";
	const title = props.title || props.title === "" ? props.title : t("lib:go_core.empty_data.title");

	const getStyles = () => {
		if (props.styles) {
			return props.styles;
		}
		return {};
	};

	return (
		<div className={`data-empty-container${props.full ? " full" : ""}`} style={getStyles()}>
			<CircleInfoSVG />
			<h6>{title}</h6>
			<span className="description">{description}</span>
			{props.content && props.content}
			<div className="actions">
				{props.actions?.map((action, index) => {
					if (typeof action.click === "string") {
						return (
							<a
								key={index}
								className={`btn btn-${action.variant ? action.variant : "add"}`}
								href={action.click}
							>
								{action.name}
							</a>
						);
					}
					return (
						<Button
							key={index}
							variant={action.variant ? action.variant : "add"}
							onClick={() => action.click()}
						>
							{action.name}
						</Button>
					);
				})}
			</div>
		</div>
	);
};
export default EmptyData;
