import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { InvoiceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../utils/entityStatus/entityStatus";
import SendInvoiceByEmailModal from "../../../components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import { exportInvoicePdf, printInvoicePdf } from "../../../services/exportInvoice";

interface ListState {
	mobileActions: MobileActionProps[];
	resource?: Record<string, any>;
}

const listName = "PROFORMA";
const resourceType = "INVOICE";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const [params] = useState<Record<string, any>>({});
	const confirmation = useConfirmation();
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<InvoiceApi[]>([]);
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const [invoiceToSendByEmail, setInvoiceToSendByEmail] = useState<InvoiceApi | undefined>(undefined);
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;

	const removeInvoice = async (invoice: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoice.id);
			params.include = "payments,summary,recipient,receiver";
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res);
			}
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const data = resource.read();
	let config = {
		fields: [
			{
				id: "issued_at",
				name: t("modules.invoice.field.date_verb.title"),
				type: "date",
				render: (item: InvoiceApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(item.status === "OPEN" ? `OPEN_BLUE` : item.status)}
							to={`${location.pathname}/${item.id}`}
						>
							{FormatDate(item.dates?.issued_at, undefined, false, defaultFormatDate)}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: InvoiceApi) => {
					return FormatDate(item.dates?.issued_at, undefined, false, defaultFormatDate);
				},
			},
			{
				id: "document",
				name: t("modules.invoice.field.document.title"),
				render: (item: InvoiceApi) => {
					return <Link to={`${location.pathname}/${item.id}`}>{`Proforma ${item.number}`}</Link>;
				},
				renderExport: (item: InvoiceApi) => {
					return `Proforma ${item.number}`;
				},
			},
			{
				id: "sold_at",
				name: t("modules.invoice.field.sold_at.title"),
				type: "date",
				render: (item: InvoiceApi) => {
					return FormatDate(item.dates?.sold_at, undefined, false, defaultFormatDate);
				},
			},
			{
				id: "payment_due_date",
				name: t("modules.invoice.field.payment_due_date.title"),
				type: "date",
				render: (item: InvoiceApi) => {
					return FormatDate(item.dates?.payment_due_date, undefined, false, defaultFormatDate);
				},
			},
			{
				id: "client",
				name: t("modules.invoice.field.recipient.title"),
				type: "search_select",
				render: (item: InvoiceApi) => {
					if (item.recipient && item.recipient_id) {
						return (
							<Link
								rel={"noreferrer"}
								target={"_blank"}
								to={`/${organization.id}/clients/${item.recipient_id}`}
							>
								{item.recipient.name}
							</Link>
						);
					}
					return item.receiver?.name;
				},
				renderExport: (item: InvoiceApi) => item.recipient?.name,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getClientsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
		],
		filters: [
			{
				id: "price",
				name: t("modules.invoice.field.price.title"),
				type: "number",
			},
			{
				id: "price_paid",
				name: t("modules.invoice.field.paid.title"),
				type: "number",
			},
			{
				id: "price_to_pay",
				name: t("modules.invoice.field.to_pay.title"),
				type: "number",
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: InvoiceApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: InvoiceApi) => {
					return `${location.pathname}/${item.id}/edit`;
				},
			},
			{
				name: t("common.action.export_pdf", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					exportInvoicePdf(item, t);
				},
			},
			{
				name: t("common.action.reset", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					printInvoicePdf(item);
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: InvoiceApi) => {
					removeInvoice(item);
				},
			},
			{
				name: t("modules.invoice.action.send_invoice_by_e-mail.title"),
				click: (item: InvoiceApi) => {
					setShowSendInvoiceByEmailModal(true);
					setInvoiceToSendByEmail(item);
				},
			},
		],
		selectedColumns: ["issued_at", "document", "sold_at", "payment_due_date", "client"],
		customPageSizes: ["20", "100", "1000"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted_male", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.invoice.field.export_config.title"),
			filename: t("modules.invoice.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "payments,summary,recipient,receiver,custom_fields";
			return api.organization().getProformas(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 2,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{invoiceToSendByEmail && (
				<SendInvoiceByEmailModal
					isShown={showSendInvoiceByEmailModal}
					onHide={() => {
						setShowSendInvoiceByEmailModal(false);
						setInvoiceToSendByEmail(undefined);
					}}
					invoice={invoiceToSendByEmail}
				/>
			)}
			<ListData
				data={items}
				config={config}
				emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
				onFetch={(fetchItems: InvoiceApi[]) => setItems(fetchItems)}
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationInvoicesIndexProformaPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "INVOICE",
			name: t(`enums.custom_fields.resources.INVOICE`),
		},
	];
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.invoice.field.proforma.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.invoice.field.proforma.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationInvoicesIndexProformaPage;
