import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { apiTenant } from "../../services/Api/apiTenant";
import { ChangeTenantHttpStatusApi } from "../../services/Api/typesSecurity";

interface Props {
	tenantId: number;
	onHide: () => void;
}

const ChangeTenantHttpStatusModal = ({ tenantId, onHide }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<ChangeTenantHttpStatusApi>({
		criteriaMode: "all",
	});
	const {
		handleSubmit,
		formState,
		formState: { errors },
		setError,
		control,
	} = form;

	const onSubmit = handleSubmit(async (data: ChangeTenantHttpStatusApi) => {
		setLoading(true);
		try {
			await apiTenant.changeTenantHttpStatus(tenantId, data);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal onHide={onHide} show={tenantId !== -1}>
			<FormDirty formState={formState} key="change-tenant-http-status-form">
				<Modal.Header closeButton>
					<Modal.Title>{t("lib:go_security.tenant.field.change_tenant_http_status.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormNumberInput
						errors={errors}
						name="http_status"
						control={control}
						suffix=""
						label={t("lib:go_security.tenant.field.http_status.title")}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default ChangeTenantHttpStatusModal;
