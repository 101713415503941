import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { hasPermission } from "go-security/services/organizations/selectors";
import SalesNavigation from "./components/SalesNavigation";
import OrganizationSalesBillsPage from "./pages/Orders";
import OrganizationSalesPosReportsPage from "./pages/PosReports";
import OrganizationSalesTransactionsPage from "./pages/Transactions";

const OrganizationSalesPage: FC<RouteComponentProps> = (props) => {
	const venueOrderShowPermission = useSelector(hasPermission("VENUE_ORDER_SHOW"));
	const venueReportPosPermission = useSelector(hasPermission("VENUE_REPORTPOS_SHOW"));

	return (
		<div className={"page-container"}>
			<SalesNavigation />
			<Switch>
				<Route path={`${props.match.url}/orders`} component={OrganizationSalesBillsPage} />
				<Route path={`${props.match.url}/transactions`} component={OrganizationSalesTransactionsPage} />
				<Route path={`${props.match.url}/pos_reports`} component={OrganizationSalesPosReportsPage} />
				{venueOrderShowPermission && <Redirect from={props.match.url} exact to={`${props.match.url}/orders`} />}
				{venueReportPosPermission && (
					<Redirect from={props.match.url} exact to={`${props.match.url}/pos_reports`} />
				)}
			</Switch>
		</div>
	);
};

export default OrganizationSalesPage;
