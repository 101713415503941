import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	show: boolean;
	onHide: () => void;
}

const DeliveryZoneAppModalForm = ({ onHide, show }: Props) => {
	const { t } = useTranslation();
	const form = useForm<{ appId: string }>({
		criteriaMode: "all",
	});
	const { control, watch, formState } = form;
	const history = useHistory();
	const organization = useSelector(selectOrganization);

	const searchApps = async (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		params = {
			...params,
			"provider|e": "GOORDER",
			"status|e": `NEW${FILTER_VALUE_SEPARATOR}ENABLED`,
		};
		return api.organization().getAppsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = () => {
		history.push(`/${organization.id}/settings/delivery_zones/new`, {
			appId: watch("appId"),
		});
	};

	return (
		<Modal show={show} onHide={onHide}>
			<FormDirty onSubmit={onSubmit} formState={formState} isDirty={false}>
				<Modal.Header>
					<Modal.Title>{t("modules.delivery_zone.field.choose_app.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						errors={[]}
						name="appId"
						control={control}
						getOptionValue={(option) => option.id}
						loadOptions={searchApps}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={!watch("appId")} type="submit" onClick={onSubmit}>
						{t("lib:common.action.select")}
					</Button>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default DeliveryZoneAppModalForm;
