import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
	SpreadsheetPriceListFormProps,
} from "../../../../../../../../../../../../../../services/Api/Organization/types";
import {
	VirtualTableColumn,
	VirtualTableListSelectedSort,
} from "../../../../../../../../components/VirtualTable/types";
import SpreadsheetPriceListVirtualTableHeaderActionsRow from "./SpreadsheetPriceListVirtualTableHeaderActionsRow";
import SpreadsheetPriceListVirtualTableHeaderDefaultColumns from "./SpreadsheetPriceListVirtualTableHeaderDefaultColumns";
import SpreadsheetPriceListVirtualTableHeaderEmptySearchColumnsRow from "./SpreadsheetPriceListVirtualTableHeaderEmptySearchColumnsRow";
import SpreadsheetPriceListVirtualTableHeaderPriceListsColumns from "./SpreadsheetPriceListVirtualTableHeaderPriceListsColumns";
import SpreadsheetPriceListVirtualTableHeaderSearchColumnsRow from "./SpreadsheetPriceListVirtualTableHeaderSearchColumnsRow";

interface Props {
	maxZindex: number;
	visibleDefaultColumns: VirtualTableColumn[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	getHeaderStyles: (columnId: string) => { width: number };
	sortings: VirtualTableListSelectedSort[];
	visiblePriceListsColumns: MenuPriceListApi[];
	setCategorySearch: (search: string) => void;
	setItemGroupSearch: (search: string) => void;
	setItemSearch: (search: string) => void;
	setItemFullNameSearch: (search: string) => void;
	setModifierGroupSearch: (search: string) => void;
	setModifierSearch: (search: string) => void;
	categorySearch: string | undefined;
	itemGroupSearch: string | undefined;
	itemSearch: string | undefined;
	itemFullNameSearch: string | undefined;
	modifierGroupSearch: string | undefined;
	modifierSearch: string | undefined;
	form: UseFormReturn<SpreadsheetPriceListFormProps>;
	priceLists: MenuPriceListApi[];
	items: MenuPriceListFlatApi[];
}

const SpreadsheetPriceListVirtualTableHeader = ({
	maxZindex,
	visibleDefaultColumns,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
	getHeaderStyles,
	sortings,
	visiblePriceListsColumns,
	setCategorySearch,
	setItemGroupSearch,
	setItemSearch,
	setModifierGroupSearch,
	setItemFullNameSearch,
	setModifierSearch,
	categorySearch,
	itemGroupSearch,
	itemSearch,
	itemFullNameSearch,
	modifierGroupSearch,
	modifierSearch,
	form,
	priceLists,
	items,
}: Props) => {
	return (
		<thead
			style={{
				position: "sticky",
				zIndex: maxZindex + 1,
			}}
		>
			<tr className="headers">
				<SpreadsheetPriceListVirtualTableHeaderDefaultColumns
					visibleDefaultColumns={visibleDefaultColumns}
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					maxZindex={maxZindex}
					getHeaderStyles={getHeaderStyles}
					sortings={sortings}
				/>
				<SpreadsheetPriceListVirtualTableHeaderPriceListsColumns
					handleHeaderResize={handleHeaderResize}
					handleMouseUp={handleMouseUp}
					handleMouseDown={handleMouseDown}
					getHeaderStyles={getHeaderStyles}
					columns={visiblePriceListsColumns}
				/>
			</tr>
			<tr className="additional-header-row">
				<SpreadsheetPriceListVirtualTableHeaderSearchColumnsRow
					categorySearch={categorySearch}
					setModifierSearch={setModifierSearch}
					itemSearch={itemSearch}
					setCategorySearch={setCategorySearch}
					itemFullNameSearch={itemFullNameSearch}
					setItemGroupSearch={setItemGroupSearch}
					itemGroupSearch={itemGroupSearch}
					setItemFullNameSearch={setItemFullNameSearch}
					modifierGroupSearch={modifierGroupSearch}
					modifierSearch={modifierSearch}
					visibleDefaultColumns={visibleDefaultColumns}
					setItemSearch={setItemSearch}
					setModifierGroupSearch={setModifierGroupSearch}
					maxZindex={maxZindex}
				/>
				<SpreadsheetPriceListVirtualTableHeaderEmptySearchColumnsRow columns={visiblePriceListsColumns} />
			</tr>
			<SpreadsheetPriceListVirtualTableHeaderActionsRow
				visiblePriceListsColumns={visiblePriceListsColumns}
				visibleDefaultColumns={visibleDefaultColumns}
				form={form}
				priceLists={priceLists}
				items={items}
				maxZIndex={maxZindex}
			/>
		</thead>
	);
};

export default SpreadsheetPriceListVirtualTableHeader;
