import React from "react";
import { MenuPriceListApi } from "../../../../../../../../../../../../../../services/Api/Organization/types";
import VirtualTableSearchColumn from "../../../../../../../../components/VirtualTable/VirtualTableSearchColumn";
import { isVirtualTableColumnHide } from "../../../../../../../../components/VirtualTable/utils";

interface Props {
	columns: MenuPriceListApi[];
}

const SpreadsheetPriceListVirtualTableHeaderEmptySearchColumnsRow = ({ columns }: Props) => {
	const drawColumns = () => {
		return columns.map((col) => {
			const isHide = isVirtualTableColumnHide(col.id, columns);
			return <VirtualTableSearchColumn key={col.id} isEmpty search={() => {}} isHide={isHide} />;
		});
	};
	return <>{drawColumns()}</>;
};

export default SpreadsheetPriceListVirtualTableHeaderEmptySearchColumnsRow;
