import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NotFoundException, UnprocessableEntity } from "go-core/api/exceptions";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import ApiErrorMessage from "go-core/components/ApiErrorMessage";
import EmptyData from "go-core/components/EmptyData";
import { LoadingContainer } from "go-core/components/Loading";
import { apiSecurity } from "go-security/services/Api/api";

const EnterApplication = (): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<ApiError[]>([]);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const history = useHistory();
	const init = async (companyId: string) => {
		try {
			const res = await apiSecurity.organization().getId(companyId);
			const organizationId = res.data.data;
			history.replace(`/${organizationId}`);
		} catch (err) {
			if (err instanceof NotFoundException || err instanceof UnprocessableEntity) {
				if (companyId) fetch(companyId);
				return;
			}
			setLoading(false);
			const exceptionErrors = handleException(err);
			setErrors(exceptionErrors);
		}
	};
	const fetch = async (companyId: string) => {
		try {
			const res = await apiSecurity.organization().getCompany(companyId);
			const organization = res.data.data;
			if (organization.id) {
				history.replace(`/${organization.id}`);
				return;
			}
			if (!organization.application) {
				history.replace({ pathname: "/register", search: `?company_id=${companyId}` });
				return;
			}
			console.log("ERROR", organization);
		} catch (err) {
			const exceptionErrors = handleException(err);
			setErrors(exceptionErrors);
			setLoading(false);
		}
	};
	useEffect(() => {
		const companyId = urlSearchParams.get("company_id");
		if (companyId) {
			init(companyId).catch(console.error);
		}
	}, []);

	if (loading) {
		return <LoadingContainer />;
	}
	const renderContent = (contentErrors: ApiError[]) => {
		return <ApiErrorMessage errors={contentErrors} />;
	};
	if (errors.length > 0) {
		return (
			<div className="content">
				<EmptyData
					title={t("lib:go_security.field.location_not_found")}
					description={t("lib:go_security.field.no_access_or_no_location_found")}
					content={renderContent(errors)}
				/>
			</div>
		);
	}

	return <></>;
};
export default EnterApplication;
