import React from "react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

interface Props {
	resource_type: string;
	errors: FieldErrors;
}

const ErrorDuplicateName = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const id = props.errors?.name?.duplicateId;
	if (props.errors.name && id) {
		return (
			<div style={{ marginTop: "-15px", marginBottom: "10px" }}>
				{props.resource_type === "CATEGORY" && (
					<Link rel="noreferrer" target="_blank" to={`/${organization.id}/menu/categories/${id}`}>
						{t("modules.category.action.open_category.title")}
					</Link>
				)}
				{props.resource_type === "ITEM_GROUP" && (
					<Link target="_blank" rel="noreferrer" to={`/${organization.id}/menu/item_groups/${id}`}>
						{t("modules.item_group.action.open_item_group.title")}
					</Link>
				)}
			</div>
		);
	}
	return <></>;
};
export default ErrorDuplicateName;
