import React, { useContext, useEffect, useState } from "react";
import TemplateReportComboComponent from "go-report-combo/template/Template/TemplateReportComboComponent";
import { TemplateReportCombo } from "go-report-combo/template/Template/types";
import { Link, useParams } from "react-router-dom";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { SegmentContext } from "go-segment/context";
import { SegmentApi } from "go-segment/services/types";
import ComboTemplateUtils from "../../services/comboTemplateUtils";

const comboName = "REPORT_COMBO";
const resourceType = "REPORT_COMBO";

interface MatchParams {
	segmentId: string;
}

const UserReportsComboSegmentPage = () => {
	const [template, setTemplate] = useState<TemplateReportCombo | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [segment, setSegment] = useState<SegmentApi | undefined>(undefined);
	const segmentContext = useContext(SegmentContext);
	const { segmentId } = useParams<MatchParams>();

	useEffect(() => {
		fetchSegments();
	}, [segmentId]);

	const updateTemplateBySegment = (segment: SegmentApi) => {
		const newTemplate = ComboTemplateUtils.segmentToTemplate(segment);
		setTemplate(newTemplate);
	};

	const fetchSegments = async () => {
		setLoading(true);
		if (!segmentId) return;

		let filter = `resource_type|e=${resourceType}${FILTER_SEPARATOR}list_name|e=${comboName}`;
		if (segmentId) {
			filter += `${FILTER_SEPARATOR}id|e=${segmentId}`;
		}
		const segments = await segmentContext.find({
			f: btoa(unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${filter}`))),
		});
		const segment = segments.data.data[0];
		setSegment(segment);
		if (segment) {
			setTemplate(ComboTemplateUtils.segmentToTemplate(segment));
		} else {
			setTemplate(undefined);
		}
		setLoading(false);
	};

	if (loading) {
		return <LoadingContainer />;
	}

	if (segmentId && !segment) {
		return <div>Segment nie istnieje</div>;
	}

	return (
		<div>
			<Link to={`/combo_reports/create?segmentId=${segment?.id}`} className={"btn btn-primary"}>
				Edytuj szablon
			</Link>
			<hr />
			<div>{template && template.id && <TemplateReportComboComponent template={template} />}</div>
		</div>
	);
};

export default UserReportsComboSegmentPage;
