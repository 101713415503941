import { combineReducers } from "redux";
import { organizationReducer } from "go-security/services/organizations/reducer";
import { OrganizationState } from "go-security/services/organizations/types";
import { sessionReducer } from "go-security/services/session/reducer";
import { SessionState } from "go-security/services/session/types";
import { userReducer } from "go-security/services/users/reducer";
import { UserState } from "go-security/services/users/types";

export interface ApplicationState {
	user: UserState;
	session: SessionState;
	organization: OrganizationState;
}

export const storeReducer = combineReducers({
	user: userReducer,
	session: sessionReducer,
	organization: organizationReducer,
});
