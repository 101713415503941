import React from "react";
import { Form } from "react-bootstrap";
import { FormControlProps } from "react-bootstrap/FormControl";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { hasErrors } from "..";
import { FormErrorMessage } from "./FormErrorMessage";

interface Props extends FormControlProps {
	label?: string;
	placeholder?: string;
	name: string;
	help?: string;
	errors: any;
	step?: number;
	register: UseFormRegister<any>;
}
export const FormMoneyInputSimple: BsPrefixRefForwardingComponent<"input", Props> = React.forwardRef((props: Props) => {
	const { label, placeholder, register, name, errors, help, step, ...controlProps } = props;
	const onChangeInput = (evt: any) => {
		if (step) {
			const regex = /[.,s]/g;
			evt.target.value = evt.target.value.replace(regex, "");
		} else if (evt.target.value.charAt(0) === "." || evt.target.value.charAt(0) === ",") {
			evt.target.value = `0${evt.target.value}`;
		}
	};

	return (
		<Form.Group className="form-group" controlId={name}>
			{label && <Form.Label>{label}</Form.Label>}
			<Form.Control
				{...register(name)}
				{...controlProps}
				onChange={onChangeInput}
				step={1}
				type={"number"}
				placeholder={placeholder}
				isInvalid={hasErrors(errors, name)}
			/>
			{help && <Form.Text muted>{help}</Form.Text>}
			<FormErrorMessage errors={errors} name={name} />
		</Form.Group>
	);
});

FormMoneyInputSimple.displayName = "FormMoneyInputSimple";
