import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TerminalDiagnoseApi } from "../../../../../../../../../../../services/Api/Organization/types";
import TerminalDiagnosesTable from "./TerminalDiagnosesTable";

interface Props {
	isShown: boolean;
	onHide: () => void;
	diagnoses: TerminalDiagnoseApi[];
	setDiagnoseToShow: (diagnose: TerminalDiagnoseApi) => void;
}

const TerminalDiagnosesModal: FC<Props> = ({ onHide, isShown, diagnoses, setDiagnoseToShow }) => {
	const { t } = useTranslation();

	return (
		<Modal show={isShown} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.terminal.header.terminal_diagnose.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="terminal-diagnoses-modal-items">
					<TerminalDiagnosesTable
						diagnoses={diagnoses}
						hasLimitedDiagnosesAmount={false}
						setDiagnoseToShow={setDiagnoseToShow}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TerminalDiagnosesModal;
