import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { api } from "../../../../../services/Api/api";
import { MigrationFormProps } from "../../../../../services/Api/types";

const GlobalMigrationForm = (): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const form = useForm<MigrationFormProps>({
		defaultValues: {},
	});
	const {
		formState,
		formState: { errors },
		control,
		handleSubmit,
		setError,
	} = form;
	const { addFlash } = useFlash();
	const history = useHistory();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.migration.header.data_migration.title"));
	}, []);

	const searchOrganizations = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.getMigrationGlobalOrganizationsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = handleSubmit(async (data: MigrationFormProps) => {
		setLoading(true);
		try {
			const res = await api.migrateGlobal(data);
			setLoading(false);
			history.push(`/${res.organization_id}/migration`);
		} catch (e) {
			handleError.form(e, setError, addFlash);
			setLoading(false);
		}
	});

	return (
		<>
			<Header title={t("modules.migration.header.data_migration.title")} />
			<FormDirty formState={formState} onSubmit={onSubmit} loading={loading}>
				<fieldset className="form-group">
					<h5>{t("modules.migration.action.choose_location_to_migrate.title")}</h5>
					<FormSelectGroup
						label={t("common.word.location", { ns: "lib" })}
						name="old_organization_id"
						errors={errors}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						defaultValue={undefined}
						loadOptions={searchOrganizations}
						control={control}
						data-testid="old_organization_id"
					/>
				</fieldset>
			</FormDirty>
		</>
	);
};
export default GlobalMigrationForm;
