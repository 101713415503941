import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormInput } from "go-form";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../images/svg/virtualTable/remove.svg";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationListFormProps,
} from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<ItemTranslationListFormProps>;
	name: any;
	item: ItemTranslationItemNameFormApi;
	handleClear: () => void;
	handleOnChange?: (value: string) => void;
}

const TranslationVirtualTableInput = ({ form, name, handleClear, handleOnChange }: Props) => {
	const {
		getValues,
		formState: { errors },
		register,
	} = form;
	const [value, setValue] = useState(getValues(name));

	const handleClearValue = () => {
		setValue("");
		handleClear();
	};

	const handleOnInput = (newValue: string) => {
		setValue(newValue);
		if (handleOnChange) handleOnChange(newValue);
	};

	return (
		<>
			<FormInput
				name={name}
				errors={errors}
				register={register}
				onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
					handleOnInput(e.target.value);
				}}
			/>
			{value && <RemoveSVG style={{ cursor: "pointer" }} onClick={handleClearValue} />}
		</>
	);
};

export default TranslationVirtualTableInput;
