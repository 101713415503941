import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface MobileActionProps {
	title: string;
	action?: () => void;
	path?: string;
	hide?: boolean;
	state?: Record<string, any>;
}

const MobileAction = ({ action, title, path, hide, state }: MobileActionProps) => {
	if (hide) return <></>;

	if (path) {
		return (
			<Dropdown.Item as={Link} key={title} href="#" to={{ pathname: path, state }}>
				{title}
			</Dropdown.Item>
		);
	}
	return (
		<Dropdown.Item key={title} onClick={action}>
			{title}
		</Dropdown.Item>
	);
};

export default MobileAction;
