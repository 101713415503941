import { ConfigReportComboApi } from "go-report-combo/services/types";

export const REPORT_tpizza_categories_compare_locations = {
	id: "REPORT_tpizza_categories_compare_locations",
	name: "Tpizza - Raport LTM kategorie - porównanie lokalizacji",
	data: [
		{
			id: "PRODUCT",
			resource: "BILL",
			filter: `type=order_itemçorganization_id|e={organization_ids__ORGANIZATION}`,
			groups: ["NONE", "{sales_group__GROUP}"],
			columns: [
				{
					id: "monthly",
					group: "MONTH",
					filter: "date_range|bt=previous_12_months",
				},
			],
		},
		{
			id: "PRODUCT_COMPARE",
			resource: "BILL",
			filter: `type=order_itemçorganization_id|e={organization_ids_compare__ORGANIZATION}`,
			groups: ["NONE", "{sales_group__GROUP}"],
			columns: [
				{
					id: "monthly",
					group: "MONTH",
					filter: "date_range|bt=previous_12_months",
				},
			],
		},
		{
			id: "BILL",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids__ORGANIZATION}`,
			groups: ["NONE"],
			columns: [
				{
					id: "monthly",
					group: "MONTH",
					filter: "date_range|bt=previous_12_months",
				},
			],
		},
		{
			id: "BILL_COMPARE",
			name: "Bills",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids_compare__ORGANIZATION}`,
			groups: ["NONE"],
			columns: [
				{
					id: "monthly",
					group: "MONTH",
					filter: "date_range|bt=previous_12_months",
				},
			],
		},
	],
	rows: [
		{
			id: "SALES_VALUE",
			name: "Wartość sprzedaży",
			data_id: "BILL",
			aggregate: "total_money",
			compare: [],
		},
		{
			id: "SALES_NUMBER",
			name: "Ilość sprzedaży",
			data_id: "BILL",
			aggregate: "transaction_count",
			compare: [
				{
					id: "previous_percent",
					data_id: "BILL_COMPARE",
					name: "vs poprzedni",
					format: "PERCENT",
					aggregate: "transaction_count",
				},
			],
		},
		{
			id: "SALES_AVERAGE",
			name: "Średni rachunek",
			data_id: "BILL",
			aggregate: "average_money",
		},
		{
			id: "SALES_CATEGORY",
			name: "Kategorie",
			data_id: "PRODUCT",
			group: "{sales_group__GROUP}",
			aggregate: "total_money",
			compare: [
				{
					id: "previous_percent",
					data_id: "PRODUCT_COMPARE",
					name: "vs poprzedni",
					format: "PERCENT",
					aggregate: "total_money",
				},
			],
		},
	],
	columns: [
		{
			id: "monthly",
			data_column_id: "monthly",
			name: "Sprzedaż",
			values_dynamic: `values_previous_12_months`,
		},
	],
} as ConfigReportComboApi;
