import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { VenueSettingsApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	language: string;
	index: number;
}

const KioskSettingsLanguageInputs = ({ form, language, index }: Props) => {
	const { register, setValue } = form;
	const { t } = useTranslation();

	useEffect(() => {
		setValue(`kiosk.translations.${index}.locale`, language);
	}, []);

	return (
		<>
			<span>{`${t("modules.venue_settings.field.language.title")} ${t(
				`lib:enums.languages.${language}`
			).toLowerCase()}`}</span>
			<div className="row mt-2">
				<div className="col-md-6">
					<FormInput
						name={`kiosk.translations.${index}.start_order_header`}
						errors={[]}
						label={t("modules.venue_settings.field.start_order_header.title")}
						register={register}
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						name={`kiosk.translations.${index}.start_order_description`}
						errors={[]}
						label={t("modules.venue_settings.field.start_order_description.title")}
						register={register}
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						name={`kiosk.translations.${index}.edit_order_header`}
						errors={[]}
						label={t("modules.venue_settings.field.edit_order_header.title")}
						register={register}
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						name={`kiosk.translations.${index}.edit_order_description`}
						errors={[]}
						label={t("modules.venue_settings.field.edit_order_description.title")}
						register={register}
					/>
				</div>
			</div>
		</>
	);
};

export default KioskSettingsLanguageInputs;
