import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { domain } from "go-core";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import { LoadingContainer } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import ParametersSecurity from "../../services";
import AccessTokenSessionService from "../../services/accessTokenSessionService";
import SessionIdService from "../../services/sessionIdService";

const SecurityLoginCodePage = () => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [authorized, setAuthorized] = useState(false);

	const handleMessage = (event: MessageEvent<any>) => {
		try {
			if (event.data && typeof event.data === "string") {
				const parsed = JSON.parse(event.data);

				if (parsed.session_id && parsed.redirect_url) {
					const redirectUrl = parsed?.filter
						? `${parsed.redirect_url}?f=${btoa(
								unescape(encodeURIComponent(NEW_WAY_TO_ENCODING_FILTER_SIGN + parsed.filter))
						  )}`
						: parsed.redirect_url;
					setAuthorized(true);
					SessionIdService.update(parsed.session_id);
					window.location.href = `${ParametersSecurity.getLoginRedirectUrl()}/save_session_id?reference_uri=${domain()}${redirectUrl}&session_id=${
						parsed.session_id
					}`;
				} else if (parsed.access_token && parsed.redirect_url) {
					const redirectUrl = parsed?.filter
						? `${parsed.redirect_url}?f=${btoa(
								unescape(encodeURIComponent(NEW_WAY_TO_ENCODING_FILTER_SIGN + parsed.filter))
						  )}`
						: parsed.redirect_url;
					setAuthorized(true);
					AccessTokenSessionService.update(parsed.access_token);
					window.location.href = `${domain()}${redirectUrl}`;
				}
			}
		} catch (err: Exception | unknown) {
			handleError.alert(err, addFlash);
		}
	};

	useLayoutEffect(() => {
		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	return (
		<div className="page">
			<div className="content d-flex align-items-center justify-content-center">
				{!authorized && (
					<LoadingContainer
						title={`${t("lib:go_security.login_code.field.pending_authorization.title")}...`}
					/>
				)}
			</div>
		</div>
	);
};

export default SecurityLoginCodePage;
