import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { UserActionTypes } from "go-security/services/users/types";
import { CompanyInfoApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import BankAccountsForm from "./BankAccountsForm";

interface Props {
	companyInfo: CompanyInfoApi;
}

const CompanyInfoForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const form = useForm<CompanyInfoApi>({
		criteriaMode: "all",
		defaultValues: props.companyInfo,
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		formState,
		setError,
		reset,
		control,
		getValues,
		setValue,
	} = form;
	const dispatch = useDispatch();
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const [searchingClient, setSearchingClient] = useState(false);

	const searchClient = async () => {
		const taxIdNo = getValues("tax_id_no");
		setSearchingClient(true);
		try {
			const res = await api.organization().getClientDataByTaxIdNo(taxIdNo);
			setValue("address_country", res.address_country);
			setValue("address_zip_code", res.address_zip_code);
			setValue("address_city", res.address_city);
			setValue("address_build_nr", res.address_build_nr);
			setValue("address_flat_nr", res.address_flat_nr);
			setValue("address_street", res.address_street);
			setValue("company_name", res.name);
			setValue("business_register_no", res.business_register_no);
			setSearchingClient(false);
			clearErrors("tax_id_no");
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setSearchingClient(false);
	};

	const onSubmit = handleSubmit(async (data: CompanyInfoApi) => {
		setLoading(true);
		try {
			const res = await api.organization().saveCompanyInfo(data);
			setLoading(false);
			const payloadData = {
				id: organization.id,
				tax_id_no: res.tax_id_no ? res.tax_id_no : null,
				business_register_no: res.business_register_no,
				company_name: res.company_name,
				more: {
					...organization.more,
					address: {
						street: res.address_street ? res.address_street : null,
						country: res.address_country ? res.address_country : null,
						build_nr: res.address_build_nr ? res.address_build_nr : null,
						flat_nr: res.address_flat_nr ? res.address_flat_nr : null,
						zip_code: res.address_zip_code ? res.address_zip_code : null,
						city: res.address_city ? res.address_city : null,
					},
				},
			};
			dispatch({
				type: UserActionTypes.UPDATE_ORGANIZATION,
				payload: payloadData,
			});
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			reset({
				...res,
				contact_phone_number: res?.contact_phone_number || "",
			});
			clearErrors();
		} catch (err) {
			setLoading(false);
			handleError.form(err, setError, addFlash);
		}
	});

	return (
		<FormDirty formState={formState} loading={loading} key="company-form" noValidate onSubmit={onSubmit}>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>

				<div className="row">
					<div className="col-md-6">
						<FormInput
							label={t("lib:common.word.name")}
							register={register}
							name="company_name"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<InputGroup className={"form-input-group"}>
							<FormInput
								name="tax_id_no"
								label={t("lib:common.word.tax_id_no.title")}
								register={register}
								errors={errors}
							/>
							<div className="input-group-append">
								<ButtonLoading
									loading={searchingClient}
									style={{ height: "31px", border: "1px solid #ced4da" }}
									onClick={() => searchClient()}
									variant={"primary"}
								>
									{t("common.word.tax_id_no.search", { ns: "lib" })}
								</ButtonLoading>
							</div>
						</InputGroup>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("modules.company.field.business_register_no.title")}
							register={register}
							name="business_register_no"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.word.email", { ns: "lib" })}
							register={register}
							name="contact_email"
							errors={errors}
						/>
					</div>

					<div className="col-md-6">
						<FormNumberInput
							label={t("common.contact.phone.label")}
							control={control}
							name="contact_phone_number"
							suffix=""
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.contact.others.label")}
							register={register}
							name="contact_other"
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.address", { ns: "lib" })}</h5>
				<div className="row">
					<div className="col-md-3">
						<FormInput
							label={t("common.address.country", { ns: "lib" })}
							register={register}
							name="address_country"
							errors={errors}
						/>
					</div>
					<div className="col-md-3">
						<FormInput
							label={t("common.address.zip_code", { ns: "lib" })}
							register={register}
							name="address_zip_code"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.address.city", { ns: "lib" })}
							register={register}
							name="address_city"
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<FormInput
							label={t("common.address.street", { ns: "lib" })}
							register={register}
							name="address_street"
							errors={errors}
						/>
					</div>
					<div className="col-md-3">
						<FormInput
							label={t("common.address.build_nr", { ns: "lib" })}
							register={register}
							name="address_build_nr"
							errors={errors}
						/>
					</div>
					<div className="col-md-3">
						<FormInput
							label={t("common.address.flat_nr", { ns: "lib" })}
							register={register}
							name="address_flat_nr"
							errors={errors}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset>
				<h5>{t("modules.company.field.bank_accounts.title")}</h5>
				<BankAccountsForm form={form} />
			</fieldset>
		</FormDirty>
	);
};

export default CompanyInfoForm;
