import { FC } from "react";

interface Props {
	children: React.ReactNode;
}

const OrganizationAppsEditIndexPage: FC<Props> = ({ children }) => {
	return <>{children}</>;
};
export default OrganizationAppsEditIndexPage;
