import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";

export const mapToBase = (search: string, params: Record<string, any>) => {
	if (params) {
		let f = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}query|c=${search}${FILTER_SEPARATOR}`;
		f += Object.keys(params)
			.filter((f) => f !== "page")
			.map((key) => `${key}=${params[key]}`)
			.join(FILTER_SEPARATOR);
		return btoa(unescape(encodeURIComponent(f)));
	}
	return `${NEW_WAY_TO_ENCODING_FILTER_SIGN}query%7Cc=${search}`;
};

export const getSearchSelectPage = (obj: Record<string, any>) => {
	if (Object.keys(obj).filter((f) => f === "page").length <= 0) return 0;
	return Object.keys(obj)
		.filter((f) => f === "page")
		.map((key) => obj[key])[0];
};
