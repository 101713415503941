import React, { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationSalesPosReportsExternalReportsPage from "./pages/ExternalReports/pages";
import OrganizationSalesPosReportsIndexPage from "./pages/Index";
import OrganizationSalesPosReportsPreviewPage from "./pages/Preview";

const OrganizationSalesPosReportsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/`} exact={true} component={OrganizationSalesPosReportsIndexPage} />
				<Route
					path={`${props.match.url}/external_reports`}
					component={OrganizationSalesPosReportsExternalReportsPage}
				/>
				<Route path={`${props.match.url}/:reportId`} component={OrganizationSalesPosReportsPreviewPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSalesPosReportsPage;
