import React, { FC, useRef } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../images/svg/remove.svg";
import {
	PredicateConditionType,
	UpdatePriceListPredicateApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../../../services/Api/types";

interface Props {
	form: UseFormReturn<UpdatePriceListPredicateApi>;
	index: number;
}

const PredicateConditionsTable: FC<Props> = ({ index, form }) => {
	const { t } = useTranslation();
	const {
		control,
		formState: { errors },
		watch,
		setValue,
		getValues,
	} = form;
	const { fields, remove, append } = useFieldArray({
		control,
		name: `predicates.${index}.conditions`,
		keyName: "key",
	});
	const optionsRef = useRef<SearchSelectApi[]>([]);
	const isMobile = useWindowSize().isMobile;

	const conditionTypeOptions = [
		{
			value: "ORDER_TYPE",
			label: t("enums.predicates.condition.types.ORDER_TYPE"),
		},
		{
			value: "CLIENT_GROUP",
			label: t("enums.predicates.condition.types.CLIENT_GROUP"),
		},
		{
			value: "POINT_OF_SALE",
			label: t("enums.predicates.condition.types.POINT_OF_SALE"),
		},
		{
			value: "ORDER_SOURCE",
			label: t("enums.predicates.condition.types.ORDER_SOURCE"),
		},
	];

	const conditionOperatorOptions = [
		{
			value: "EQUALS",
			label: t("enums.predicates.condition.operators.EQUALS"),
		},
		{
			value: "NOT_EQUALS",
			label: t("enums.predicates.condition.operators.NOT_EQUALS"),
		},
	];

	const orderTypeOptions = [
		{
			id: "DINE_IN",
			label: t("enums.orders.types.DINE_IN"),
		},
		{
			id: "PICK_UP",
			label: t("enums.orders.types.PICK_UP"),
		},
		{
			id: "ROOM_SERVICE",
			label: t("enums.orders.types.ROOM_SERVICE"),
		},
		{
			id: "DELIVERY",
			label: t("enums.orders.types.DELIVERY"),
		},
	];

	const searchClientGroups = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientGroupsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchPointOfSales = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPointsOfSaleSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchOrderSources = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getOrderSourceSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onAdd = () => {
		append({ type: "ORDER_TYPE", operator: null, value: "" });
	};

	const onRemove = (index: number) => {
		remove(index);
	};

	const parseConditionOptionValue = (option: SearchSelectApi, conditionType: PredicateConditionType) => {
		optionsRef.current = optionsRef.current.find((o) => o.id === option.id)
			? [...optionsRef.current]
			: [...optionsRef.current, option];
		return conditionType === "ORDER_SOURCE" ? option.label : option.id;
	};

	const updateConditionValue = (predicateIndex: number, conditionIndex: number) => {
		const value = getValues(`predicates.${predicateIndex}.conditions.${conditionIndex}.value`);
		const option = optionsRef.current.find((refOption) => refOption.id === value);
		if (!option) setValue(`predicates.${predicateIndex}.conditions.${conditionIndex}.value`, value);
	};

	const getConditions = (conditionType: PredicateConditionType) => {
		switch (conditionType) {
			case "CLIENT_GROUP":
				return searchClientGroups;
			case "POINT_OF_SALE":
				return searchPointOfSales;
			case "ORDER_SOURCE":
				return searchOrderSources;
			default:
				return undefined;
		}
	};

	return (
		<div className={`mb-3 ${isMobile ? "table-responsive" : ""}`}>
			<table className="table table-form">
				<thead>
					<tr>
						<th className="action" />
						<th>{t("lib:common.word.type")}</th>
						<th>{t("modules.predicate.field.operator.title")}</th>
						<th>{t("common.word.value")}</th>
						<th className="action" />
					</tr>
				</thead>
				<tbody>
					{fields.map((condition, i) => {
						const conditionType: PredicateConditionType = watch(`predicates.${index}.conditions.${i}.type`);
						return (
							<tr key={condition.key}>
								<td className="action" />
								<td className="w-40">
									<FormSelectGroup
										errors={errors}
										control={control}
										options={conditionTypeOptions}
										name={`predicates.${index}.conditions.${i}.type`}
										onChange={() => setValue(`predicates.${index}.conditions.${i}.value`, "")}
										data-testid={`predicates.${index}.conditions.${i}.type`}
									/>
								</td>
								<td className="w-20">
									<FormSelectGroup
										errors={errors}
										control={control}
										options={conditionOperatorOptions}
										name={`predicates.${index}.conditions.${i}.operator`}
										data-testid={`predicates.${index}.conditions.${i}.operator`}
									/>
								</td>
								<td className="w-40">
									<FormSelectGroup
										errors={errors}
										key={`condition-${i}-${conditionType}`}
										control={control}
										getOptionValue={(option: SearchSelectApi) =>
											parseConditionOptionValue(option, conditionType)
										}
										getOptionLabel={(opt) => opt.label}
										defaultValue={{
											id: condition?.value,
											label: condition?.name,
										}}
										onChange={() => updateConditionValue(index, i)}
										forceCreateOption
										loadOptions={getConditions(conditionType)}
										options={conditionType === "ORDER_TYPE" ? orderTypeOptions : undefined}
										name={`predicates.${index}.conditions.${i}.value`}
										data-testid={`predicates.${index}.conditions.${i}.value`}
									/>
								</td>
								<td className="action">
									<RemoveSVG className="icon" onClick={() => onRemove(i)} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<Button variant="add" type="button" onClick={onAdd}>
				{`+ ${t("common.action.add", { ns: "lib" })}`}
			</Button>
		</div>
	);
};

export default PredicateConditionsTable;
