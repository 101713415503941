import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";

export enum TranslationType {
	FEMALE = "female",
	MALE = "male",
	MALE_2 = "male_2",
}

interface Props {
	saveLoading: boolean;
	saveAndAddLoading: boolean;
	onSaveAndAddClick: () => void;
	translationType: TranslationType;
}

export const SaveAndAddButtons = ({ saveLoading, saveAndAddLoading, onSaveAndAddClick, translationType }: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<ButtonLoading data-testid="form-save-button" loading={saveLoading} variant="primary" type="submit">
				{t("lib:common.action.save")}
			</ButtonLoading>
			<ButtonLoading loading={saveAndAddLoading} variant="primary" onClick={onSaveAndAddClick}>
				{`${t(`go_core.save_and_add_${translationType}.title`, { ns: "lib" })}`}
			</ButtonLoading>
		</>
	);
};

// t(`go_core.save_and_add_male.title`, { ns: "lib" });
// t(`go_core.save_and_add_male_2.title`, { ns: "lib" });
// t(`go_core.save_and_add_female.title`, { ns: "lib" })
