import React, { useEffect, useRef, useState } from "react";
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { registerObject } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as RedRemoveSVG } from "../../../../../../../../../../images/svg/virtualTable/remove.svg";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	DiscountApi,
	DiscountConditionApi,
	DiscountConditionOperator,
	DiscountConditionType,
	MenuItemApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { getOrderTypes } from "../../../ItemGroups/utils";
import MenuPageItemModal from "../../../Menus/components/MenuPageItemModal";

interface Props {
	form: UseFormReturn<DiscountApi>;
	fields: FieldArrayWithId<DiscountApi, "conditions", "key">[];
	append: UseFieldArrayAppend<DiscountConditionApi>;
	remove: UseFieldArrayRemove;
}

const DiscountFormConditionsTable = ({ form, fields, append, remove }: Props) => {
	const {
		control,
		watch,
		formState: { errors },
		setValue,
		register,
	} = form;
	const { t } = useTranslation();

	const [showModal, setShowModal] = useState(false);
	const watchedConditions = watch("conditions") || [];
	const controlledFields = fields.map((field, index) => {
		return {
			...field,
			...watchedConditions[index],
		};
	});
	const conditionIndexToChangeItemEntity = useRef<number | null>(null);
	const isMobile = useWindowSize().isMobile;

	const shouldAppendNextCondition = (prevCondition?: DiscountConditionApi) => {
		if (!prevCondition) return true;
		if (
			!prevCondition.type ||
			!prevCondition.operator ||
			prevCondition.value === null ||
			prevCondition.value === ""
		)
			return false;
		return true;
	};

	useEffect(() => {
		const prevCondition = watchedConditions[watchedConditions.length - 1];
		if (shouldAppendNextCondition(prevCondition)) {
			append({
				category_id: null,
				item_group_id: null,
				item_id: null,
				type: null,
				item: null,
				value: null,
				operator: null,
				item_group: null,
				category: null,
			});
		}
	}, [JSON.stringify(watchedConditions)]);

	useEffect(() => {
		fields.forEach((field: DiscountConditionApi, index: number) => {
			registerObject(register, `conditions.${index}`, [
				"value",
				"type",
				"item_id",
				"item",
				"category",
				"category_id",
				"item_group",
				"item_group_id",
				"operator",
			]);
			setValue(`conditions.${index}`, field);
		});
	}, [fields]);

	const operatorOptions = [
		{
			value: DiscountConditionOperator.EQUALS,
			label: t("enums.discounts.condition.operator.EQUALS"),
		},
		{
			value: DiscountConditionOperator.NOT_EQUALS,
			label: t("enums.discounts.condition.operator.NOT_EQUALS"),
		},
		{
			value: DiscountConditionOperator.GREATER_THAN,
			label: t("enums.discounts.condition.operator.GREATER_THAN"),
		},
		{
			value: DiscountConditionOperator.GREATER_THAN_OR_EQUAL_TO,
			label: t("enums.discounts.condition.operator.GREATER_THAN_OR_EQUAL_TO"),
		},
		{
			value: DiscountConditionOperator.LESS_THAN,
			label: t("enums.discounts.condition.operator.LESS_THAN"),
		},
		{
			value: DiscountConditionOperator.LESS_THAN_OR_EQUAL_TO,
			label: t("enums.discounts.condition.operator.LESS_THAN_OR_EQUAL_TO"),
		},
	];

	const conditionOptions = [
		{
			value: DiscountConditionType.ORDER_TOTAL_PRICE,
			label: t("enums.discounts.condition.type.ORDER_TOTAL_PRICE"),
		},
		{
			value: DiscountConditionType.ORDER_ITEM_COUNT,
			label: t("enums.discounts.condition.type.ORDER_ITEM_COUNT"),
		},
		{
			value: DiscountConditionType.ORDER_TYPE,
			label: t("enums.discounts.condition.type.ORDER_TYPE"),
		},
		{
			value: DiscountConditionType.ORDER_SOURCE,
			label: t("enums.discounts.condition.type.ORDER_SOURCE"),
		},
		{
			value: DiscountConditionType.GUEST_COUNT,
			label: t("enums.discounts.condition.type.GUEST_COUNT"),
		},
	];

	const handleModalSave = (item: MenuItemApi) => {
		setShowModal(false);
		const conditionIndex = conditionIndexToChangeItemEntity.current;
		if (conditionIndex === null) return;

		if (item.context_type === "CATEGORY") {
			setValue(`conditions.${conditionIndex}.category_id`, Number(item.context_id));
			setValue(`conditions.${conditionIndex}.category`, {
				name: item.entity.name,
			});
			setValue(`conditions.${conditionIndex}.item_id`, null);
			setValue(`conditions.${conditionIndex}.item`, null);
			setValue(`conditions.${conditionIndex}.item_group_id`, null);
			setValue(`conditions.${conditionIndex}.item_group`, null);
		} else if (item.context_type === "ITEM") {
			setValue(`conditions.${conditionIndex}.item_id`, Number(item.context_id));
			setValue(`conditions.${conditionIndex}.item`, {
				name: item.entity.name,
				item_group: {
					name: item.entity.parent_name || "",
				},
			});
			setValue(`conditions.${conditionIndex}.category_id`, null);
			setValue(`conditions.${conditionIndex}.category`, null);
			setValue(`conditions.${conditionIndex}.item_group_id`, null);
			setValue(`conditions.${conditionIndex}.item_group`, null);
		} else {
			setValue(`conditions.${conditionIndex}.item_group_id`, Number(item.context_id));
			setValue(`conditions.${conditionIndex}.item_group`, {
				name: item.entity.name,
			});
			setValue(`conditions.${conditionIndex}.category_id`, null);
			setValue(`conditions.${conditionIndex}.category`, null);
			setValue(`conditions.${conditionIndex}.item_id`, null);
			setValue(`conditions.${conditionIndex}.item`, null);
		}

		conditionIndexToChangeItemEntity.current = null;
	};

	const drawItemEntity = (condition: DiscountConditionApi, conditionIndex: number) => {
		let entity = "";
		let value = "";
		if (condition.item_id && condition.item?.name) {
			entity = `${t("common.word.item_group_variant")}: `;
			value = condition.item.name;
		} else if (condition.item_group_id && condition.item_group?.name) {
			entity = `${t("common.word.item_group")}: `;
			value = condition.item_group.name;
		} else if (condition.category_id && condition.category?.name) {
			entity = `${t("common.word.category")}: `;
			value = condition.category.name;
		}

		return (
			<div className="d-flex align-items-center">
				<div style={{ flex: 0.8 }}>
					{entity}
					<strong>{value}</strong>
				</div>
				<RedRemoveSVG
					className="icon ms-auto"
					style={{
						maxWidth: "15px",
						minWidth: "15px",
						maxHeight: "15px",
						minHeight: "15px",
						flex: 0.2,
					}}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						handleRemoveItemEntity(conditionIndex);
					}}
				/>
			</div>
		);
	};

	const handleOnChangeType = (type: DiscountConditionType, conditionIndex: number) => {
		if (type !== DiscountConditionType.ORDER_ITEM_COUNT) {
			handleRemoveItemEntity(conditionIndex);
		}
		setValue(`conditions.${conditionIndex}.operator`, null);
		setValue(`conditions.${conditionIndex}.value`, null);
	};

	const handleRemoveItemEntity = (conditionIndex: number) => {
		setValue(`conditions.${conditionIndex}.category_id`, null);
		setValue(`conditions.${conditionIndex}.category`, null);
		setValue(`conditions.${conditionIndex}.item_id`, null);
		setValue(`conditions.${conditionIndex}.item`, null);
		setValue(`conditions.${conditionIndex}.item_group_id`, null);
		setValue(`conditions.${conditionIndex}.item_group`, null);
	};

	const getOperatorOptionsDependingOnConditionType = (type: DiscountConditionType | null) => {
		switch (type) {
			case DiscountConditionType.ORDER_TOTAL_PRICE:
			case DiscountConditionType.ORDER_ITEM_COUNT:
			case DiscountConditionType.GUEST_COUNT:
				return operatorOptions;
			case DiscountConditionType.ORDER_TYPE:
			case DiscountConditionType.ORDER_SOURCE:
				return operatorOptions.filter(
					(operator) =>
						operator.value === DiscountConditionOperator.EQUALS ||
						operator.value === DiscountConditionOperator.NOT_EQUALS
				);
			default:
				return [];
		}
	};

	const searchOrderSources = async (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return apiOrganization.getOrderSourceSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const drawValueInput = (type: DiscountConditionType | null, index: number) => {
		switch (type) {
			case DiscountConditionType.ORDER_TOTAL_PRICE:
			case DiscountConditionType.ORDER_ITEM_COUNT:
			case DiscountConditionType.GUEST_COUNT:
				return (
					<FormNumberInput
						control={control}
						errors={errors}
						name={`conditions.${index}.value`}
						suffix=""
						allowNegative={false}
						decimalScale={
							type === DiscountConditionType.ORDER_ITEM_COUNT ||
							type === DiscountConditionType.GUEST_COUNT
								? 0
								: 2
						}
					/>
				);
			case DiscountConditionType.ORDER_SOURCE:
				return (
					<FormSelectGroup
						errors={errors}
						name={`conditions.${index}.value`}
						getOptionValue={(option) => option.label}
						loadOptions={searchOrderSources}
						control={control}
						defaultValue={{
							value: controlledFields[index]?.value,
							label: controlledFields[index]?.value,
						}}
						data-testid={`conditions.${index}.value`}
					/>
				);
			case DiscountConditionType.ORDER_TYPE:
				return (
					<FormSelectGroup
						errors={errors}
						name={`conditions.${index}.value`}
						options={getOrderTypes(t)}
						control={control}
						getOptionValue={(option) => option.id}
						data-testid={`conditions.${index}.value`}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form">
					<thead>
						<tr>
							<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-25">
								{t("lib:common.word.type")}
							</th>
							<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-25">
								{t("modules.discount.field.item.title")}
							</th>
							<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-25">
								{t("modules.discount.field.operator.title")}
							</th>
							<th style={{ minWidth: isMobile ? "150px" : "unset" }} className="w-25">
								{t("common.word.value")}
							</th>
							<th className="action" />
						</tr>
					</thead>
					<tbody>
						{controlledFields.map((field, index) => {
							const isOrderItemCountType = field.type === DiscountConditionType.ORDER_ITEM_COUNT;
							return (
								<tr key={field.key}>
									<td>
										<FormSelectGroup
											errors={errors}
											name={`conditions.${index}.type`}
											control={control}
											data-testid={`conditions.${index}.type`}
											options={conditionOptions}
											onChange={(id) => handleOnChangeType(id, index)}
										/>
									</td>
									<td>
										{isOrderItemCountType && (
											<div
												onClick={() => {
													setShowModal(true);
													conditionIndexToChangeItemEntity.current = index;
												}}
												style={{
													cursor: "pointer",
													width: "100%",
												}}
											>
												{field.item_id || field.item_group_id || field.category_id ? (
													drawItemEntity(field, index)
												) : (
													<span>{`${t("lib:common.action.select")}...`}</span>
												)}
											</div>
										)}
									</td>
									<td>
										<FormSelectGroup
											key={`conditions-${index}-${field.type}`}
											errors={errors}
											name={`conditions.${index}.operator`}
											control={control}
											data-testid={`conditions.${index}.operator`}
											options={getOperatorOptionsDependingOnConditionType(field.type)}
										/>
									</td>
									<td>{drawValueInput(field.type, index)}</td>
									<td className="action">
										{index !== controlledFields.length - 1 && (
											<RemoveSVG className="icon" onClick={() => remove(index)} />
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{showModal && (
				<MenuPageItemModal
					selectedValues={controlledFields.map((field) => {
						return {
							id: field?.item_id || field?.category_id || field?.item_group_id,
							type: field?.item_id ? "ITEM" : field?.category_id ? "CATEGORY" : "ITEM_GROUP",
						};
					})}
					handleClose={() => setShowModal(false)}
					handleSave={handleModalSave}
				/>
			)}
		</>
	);
};

export default DiscountFormConditionsTable;
