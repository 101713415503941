import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { TerminalDiagnoseApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	isShown: boolean;
	onHide: () => void;
	diagnose: TerminalDiagnoseApi;
}

const TerminalDiagnoseModal: FC<Props> = ({ isShown, onHide, diagnose }) => {
	const { t } = useTranslation();

	const drawMessage = () => {
		if (diagnose.message) {
			return diagnose.message.split(`\n`).map((x, index) => <dd key={index}>{x}</dd>);
		}
		return "";
	};

	return (
		<Modal show={isShown} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					{t("modules.terminal.header.terminal_diagnose.title")} {FormatDate(diagnose.occurred_at)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="terminal-diagnose-modal-items">{drawMessage()}</div>
			</Modal.Body>
		</Modal>
	);
};

export default TerminalDiagnoseModal;
