import React, { useContext } from "react";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { ReactComponent as CheckSVG } from "../../../../../../../../../../../../images/svg/liveOrders/check.svg";
import {
	LiveOrderApi,
	OrderPreparationStatusName,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { OrdersContext } from "../../../../../../services/context";
import { getOrderPreparationStatusColor, preparationStatusColors } from "../../../../../../services/orderStatus";

interface Props {
	option: { label: string; value: OrderPreparationStatusName };
	isSelected: boolean;
	setLoading: (loading: boolean) => void;
	order: LiveOrderApi;
	setOrder: (order: LiveOrderApi) => void;
	handleSuccessAction: () => void;
}

const PreparationStatusModalOption = ({
	option,
	isSelected,
	setLoading,
	order,
	setOrder,
	handleSuccessAction,
}: Props): JSX.Element => {
	const { addFlash } = useFlash();
	const { updateOrder } = useContext(OrdersContext);

	const handleChangeStatus = async () => {
		setLoading(true);
		try {
			const res = await api
				.organization()
				.changeOrderPreparationStatus(order.id, option.value, { include: "preparation_status" });
			const newOrder = {
				...order,
				preparation_status: {
					...res.preparation_status,
				},
			};
			updateOrder(newOrder);
			setOrder(newOrder);
			handleSuccessAction();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const isBordered = getOrderPreparationStatusColor(option.value) === preparationStatusColors.WHITE;

	return (
		<div
			className={`preparation-status-modal-option ${
				isBordered ? "preparation-status-modal-option--bordered" : ""
			}`}
			style={{ backgroundColor: getOrderPreparationStatusColor(option.value) }}
			onClick={handleChangeStatus}
		>
			<span>{option.label.toUpperCase()}</span>
			{isSelected && <CheckSVG />}
		</div>
	);
};

export default PreparationStatusModalOption;
