import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { ReactComponent as AlertSVG } from "go-core/images/svg/alert.svg";
import { ReactComponent as GoPOSLogoSVG } from "go-core/images/svg/gopos-logo.svg";
import { ReactComponent as PlusCircleSVG } from "go-core/images/svg/plus-circle.svg";
import { GoSuiteApp, OrganizationCard } from "../../services/types";
import { getRidOfDuplicateApps } from "../../utils";
import ListCardOrganizationApplication from "./ListCardOrganizationApplication";
import ListOrganizationApplicationsModal from "./ListOrganizationApplicationsModal";

interface Props {
	organization: OrganizationCard;
	applications: GoSuiteApp[];
	oldVersionAppsTimestamp: Record<string, number>;
}

const ListCardOrganization = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [showApplicationsModal, setShowApplicationsModal] = useState<boolean>(false);
	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationCard | undefined>(undefined);
	const organization = props.organization;
	const [showTooltipOrganizationId, setShowTooltipOrganizationId] = useState("");

	const getSortedApplications = (applications: GoSuiteApp[], organization: OrganizationCard) => {
		return [...applications].sort((a, b) => {
			const isAInstalled = !!(
				a.id.toLowerCase() === "goaccounts" ||
				organization.applications?.filter((o) => o.toUpperCase() === a.id.toUpperCase())[0]
			);
			const isBInstalled = !!(
				b.id.toLowerCase() === "goaccounts" ||
				organization.applications?.filter((o) => o.toUpperCase() === b.id.toUpperCase())[0]
			);
			if (isAInstalled === isBInstalled) return 0;
			if (isAInstalled || isBInstalled) {
				return isAInstalled ? -1 : 1;
			}
			return 0;
		});
	};
	const applications = getSortedApplications(props.applications, props.organization);
	const uniqueApplications = getRidOfDuplicateApps(applications, organization, props.oldVersionAppsTimestamp, false);

	const remainingApplicationsAmount = applications.length - 6;

	const getAddressObj = (organization: OrganizationCard) => ({
		street: organization?.place?.street ?? "",
		city: organization?.place?.city ?? "",
		build_nr: organization?.place?.build_nr ?? "",
		country: organization?.place?.country ?? "",
		flat_nr: organization?.place?.flat_nr ?? "",
		zip_code: organization?.place?.zip_code ?? "",
	});

	const onShowApplicationsModal = (organization: OrganizationCard) => {
		setShowApplicationsModal(true);
		setSelectedOrganization(organization);
	};

	const onHideApplicationsModal = () => {
		setShowApplicationsModal(false);
		setSelectedOrganization(undefined);
	};

	const drawAlertIcon = (organization: OrganizationCard) => {
		if (!organization.errors) return null;
		return (
			organization.errors.filter((err) => err.error).length > 0 && (
				<div className="icon">
					<AlertSVG
						onMouseEnter={() => {
							setShowTooltipOrganizationId(organization.company_id);
						}}
						onMouseLeave={() => {
							setShowTooltipOrganizationId("");
						}}
					/>
					{organization.company_id === showTooltipOrganizationId && (
						<div role="tooltip" className="show tooltip">
							<div className="tooltip-inner">
								<div className="d-flex flex-column">
									{organization.errors.map((error, index) =>
										error.link ? (
											<Link
												to={error.link}
												className="text-white"
												key={`organization-${organization.company_id}-notification-${index}`}
											>
												{error.error}
											</Link>
										) : (
											<span key={`organization-${organization.company_id}-notification-${index}`}>
												{error.error}
											</span>
										)
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			)
		);
	};

	return (
		<>
			<div key={organization.company_id} className="col mb-3">
				<Card className="h-100 card-organization">
					<a
						className="stretched-link"
						title={organization.name}
						onClick={() => onShowApplicationsModal(organization)}
					/>
					<Card.Header>
						<div className="avatar">
							<GoPOSLogoSVG />
						</div>
						<div className="card-header-title">
							<Card.Title>{organization.name}</Card.Title>
							<Card.Subtitle>
								<div className="d-flex flex-column text-truncate">
									<span className="company-id text-truncate">
										ID: <span className="text-primary">{organization.company_id}</span>
									</span>
									<span className="text-truncate">
										<FormatAddress address={getAddressObj(organization)} />
									</span>
								</div>
							</Card.Subtitle>
						</div>
						{drawAlertIcon(organization)}
					</Card.Header>
					<Card.Body className="position-relative">
						<div className="row no-gutters mb-n3">
							{uniqueApplications
								.filter((_, index) => index < (remainingApplicationsAmount > 0 ? 5 : 6))
								.map((application, index) => (
									<ListCardOrganizationApplication
										key={`organization-app-${index}`}
										application={application}
										organization={organization}
									/>
								))}
							{uniqueApplications.length > 6 && (
								<div
									className="col-4 p-1 text-center application-item all-applications"
									onClick={() => onShowApplicationsModal(organization)}
								>
									<PlusCircleSVG className="application-img" />
									<h6 className="mt-2 mb-0 application-name">
										+
										{t("lib:go_component.list_card_organization.words.applications", {
											count: remainingApplicationsAmount,
										})}
									</h6>
									<span className="application-header">{t("lib:common.action.show_all")}</span>
								</div>
							)}
						</div>
					</Card.Body>
				</Card>
			</div>
			{showApplicationsModal && (
				<ListOrganizationApplicationsModal
					isShown={showApplicationsModal}
					onHide={onHideApplicationsModal}
					organization={selectedOrganization as OrganizationCard}
					applications={uniqueApplications}
				/>
			)}
		</>
	);
};
export default ListCardOrganization;
