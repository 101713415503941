import React from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";
import VirtualTableSort from "../../../../../../components/VirtualTable/VirtualTableSort";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../../components/VirtualTable/types";
import {
	getCurrentVirtualTableColumnSortingMode,
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../../utils";

interface Props {
	columns: VirtualTableColumn[];
	getHeaderStyles: (columnId: string) => { width: number };
	sortings: VirtualTableListSelectedSort[];
	handleHeaderResize: (width: number | undefined, key: string) => void;
	handleMouseUp: (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		skipSorting?: boolean
	) => void;
	handleMouseDown: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
	maxZindex: number;
}

const ItemGroupGroupEditingVirtualTableHeaderOtherColumns = ({
	columns,
	maxZindex,
	sortings,
	getHeaderStyles,
	handleHeaderResize,
	handleMouseUp,
	handleMouseDown,
}: Props) => {
	const { t } = useTranslation();

	const getColumnTitle = (id: string) => {
		switch (id) {
			case ItemGroupGroupEditingColumn.DIRECTION:
				return t("modules.menu.field.default_direction.title");
			case ItemGroupGroupEditingColumn.AVAILABILITY:
				return t("common.word.availability");
			default:
				return "";
		}
	};

	const drawColumns = () => {
		let prevLeftPosition = 0;
		return columns.map((col, index) => {
			const prevColumn = columns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const lastColumn = index === columns.length - 1;
			const isHide = isVirtualTableColumnHide(col.id, columns);

			return (
				<ReactResizeDetector onResize={(width) => handleHeaderResize(width, col.id)} key={`col-${col.id}`}>
					<th
						id={`column-${col.id}`}
						onMouseDown={(e) => handleMouseDown(e)}
						onMouseUp={(e) => handleMouseUp(e, col.id)}
						className={`sortable-column ${isHide ? "d-none" : ""} ${
							lastColumn ? "sticky-column--last" : ""
						}`}
						style={{
							left: leftPosition,
							zIndex: maxZindex - index,
						}}
					>
						<div className="d-flex align-items-center" style={getHeaderStyles(col.id)}>
							{getColumnTitle(col.id)}
							<VirtualTableSort sortingMode={getCurrentVirtualTableColumnSortingMode(col.id, sortings)} />
						</div>
					</th>
				</ReactResizeDetector>
			);
		});
	};

	return <>{drawColumns()}</>;
};

export default ItemGroupGroupEditingVirtualTableHeaderOtherColumns;
