import React, { FocusEvent, KeyboardEvent, useEffect, useRef } from "react";
import { ReactComponent as RemoveSVG } from "../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SearchSVG } from "../../../../../../../../images/svg/search.svg";

interface Props {
	isEmpty: boolean;
	search: (value: string) => void;
	searchValue?: string;
	clearFilter?: () => void;
	isHide: boolean;
	positionLeft?: number;
	zIndex?: number;
	isSticky?: boolean;
	lastColumn?: boolean;
}

const VirtualTableSearchColumn = (props: Props): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const handleChange = (evt: FocusEvent<HTMLInputElement, Element> | KeyboardEvent<HTMLInputElement>) => {
		if (props.searchValue === undefined && (evt.target as HTMLInputElement).value === "") return;
		props.search((evt.target as HTMLInputElement).value);
	};

	useEffect(() => {
		if (inputRef.current && props.searchValue === "") {
			inputRef.current.value = "";
		}
	}, [props.searchValue]);

	const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleChange(event);
		}
	};

	const thStyle = props.positionLeft !== undefined ? { left: props.positionLeft } : {};

	return (
		<th
			className={`${props.isHide ? "d-none" : ""} ${props.isSticky ? "sticky-column" : ""} ${
				props.lastColumn ? "with-wider-border" : ""
			}`}
			style={{
				...thStyle,
				zIndex: props.zIndex,
			}}
		>
			<div className="search-column-box">
				{!props.isEmpty && (
					<>
						{!props.searchValue && <SearchSVG className="ms-1" />}
						<input
							type={"text"}
							className={"form-control pl-1"}
							onKeyDown={keyDownHandler}
							onBlur={(evt: FocusEvent<HTMLInputElement, Element>) => handleChange(evt)}
							ref={inputRef}
						/>
						{props.searchValue && props.searchValue?.length > 0 && (
							<RemoveSVG
								onClick={props.clearFilter}
								className="ms-auto me-2"
								style={{ stroke: "red", cursor: "pointer" }}
							/>
						)}
					</>
				)}
			</div>
		</th>
	);
};

export default VirtualTableSearchColumn;
