import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { OrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EditOrderForm from "../../components/EditOrderForm";

interface MatchParams {
	orderId: string;
}

const OrganizationSalesOrdersEditPage = () => {
	const [order, setOrder] = useState<OrderApi | undefined>(undefined);
	const [customFieldsConfig, setCustomFieldsConfig] = useState<undefined | CustomFieldTemplateApi[]>(undefined);
	const { addFlash } = useFlash();
	const { orderId } = useParams<MatchParams>();
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		await fetchOrder();
		await fetchCustomFieldsConfig();
	};

	const fetchOrder = async () => {
		try {
			const params: Record<string, any> = {
				include: "custom_fields,table",
			};
			const res = await api.organization().getOrder(orderId, params);
			setOrder(res);
			handleChangeTabTitle(`#${res.number} | ${t("modules.order.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const fetchCustomFieldsConfig = async () => {
		try {
			const params: Record<string, any> = {
				include: "options,constraints",
				f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=ORDER`),
			};
			const res = await api.organization().getCustomFields(params);
			setCustomFieldsConfig(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!order || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/sales/orders/${order.id}`);

	return (
		<>
			<Header title={`${t("modules.order.header.title")} #${order.number}`} back={goBack} />
			<EditOrderForm order={order} customFieldsConfig={customFieldsConfig} />
		</>
	);
};

export default OrganizationSalesOrdersEditPage;
