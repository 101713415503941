import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { VenueSettingsApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { getOptionsByVenueSetting } from "./VenueSettingsForm";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
}

const DefaultPdfFontSizeSetting = ({ form }: Props) => {
	const {
		getValues,
		formState: { errors },
		control,
	} = form;
	const { t } = useTranslation();
	const settingIndex = getValues().settings.findIndex((setting) => setting.type === "DEFAULT_PDF_FONT_SIZE");
	const defaultValue = getValues().settings.find(
		(setting) => setting.type === "DEFAULT_PDF_FONT_SIZE"
	)?.default_value;

	return (
		<tr>
			<td className="w-70">
				<div className="d-flex flex-column">
					<span>{t("modules.venue_settings.field.default_pdf_font_size.title")}</span>
					<span className="text-muted">
						{t("modules.venue_settings.field.default_pdf_font_size.description")}
					</span>
				</div>
			</td>
			<td>
				<span className="venue-default-setting">9</span>
			</td>
			<td>
				<FormSelectGroup
					name={`settings.${settingIndex}.value`}
					options={getOptionsByVenueSetting(defaultValue || "", "DEFAULT_PDF_FONT_SIZE", t)}
					errors={errors}
					control={control}
					placeholder={t("common.word.default")}
					data-testid={`settings.${settingIndex}.value`}
					isClearable
				/>
			</td>
		</tr>
	);
};

export default DefaultPdfFontSizeSetting;
