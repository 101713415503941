import { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as ClientLogo } from "../../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as LinkSVG } from "../../../../../../../../../images/svg/bills/link.svg";
import { ReactComponent as PhoneSVG } from "../../../../../../../../../images/svg/bills/phone.svg";
import { ReactComponent as TaxSVG } from "../../../../../../../../../images/svg/bills/tax.svg";
import { OrderContactApi } from "../../../../../../../../../services/Api/Organization/types";
import { RenderOrderSourceLogo } from "../../../../../../../../../utils/orders/RenderOrderSourceLogo";

export interface Props {
	contact: OrderContactApi;
}

const ClientCard: FC<Props> = ({ contact }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	return (
		<Card className="client-card">
			<Card.Body>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h5 className="mb-0">{t("modules.order.field.client_data.title")}</h5>
					<RenderOrderSourceLogo source={contact?.source} />
				</div>
				{contact.name && (
					<div className="card-body-item align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							{contact.avatar_link ? (
								<img src={contact.avatar_link.small} className="client-avatar" />
							) : (
								<ClientLogo />
							)}
							<div className="item-info">
								<div className="d-flex flex-column">
									<span>
										{contact.id ? (
											<Link
												target={"_blank"}
												rel={"noreferrer"}
												to={`/${organization.id}/clients/${contact.id}`}
											>
												{contact.name}
											</Link>
										) : (
											<>{contact.name}</>
										)}
									</span>
									{contact?.source && (
										<small className="text-muted font-weight-bold">{contact.source}</small>
									)}
								</div>
							</div>
						</div>
						{contact?.link && (
							<a href={contact.link}>
								<LinkSVG className="icon" />
							</a>
						)}
					</div>
				)}
				{contact.tax_id_no && (
					<div className="card-body-item">
						<TaxSVG />
						<div className="item-info">
							<span>{contact.tax_id_no}</span>
						</div>
					</div>
				)}
				{contact.phone_number && (
					<div className="card-body-item">
						<PhoneSVG />
						<div className="item-info">
							<span>{contact.phone_number}</span>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default ClientCard;
