import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { InvoiceApi, OrderApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<InvoiceApi>;
	invoice: InvoiceApi;
	order?: OrderApi;
}

const InvoiceFormBasicInfo: FC<Props> = ({ form, invoice, order }) => {
	const { t } = useTranslation();

	const reasonTypes = [
		{ value: "USE_DISCOUNT", label: `${t("enums.invoices.reason_of_correction.USE_DISCOUNT")}` },
		{ value: "RETURN_PAYMENT", label: `${t("enums.invoices.reason_of_correction.RETURN_PAYMENT")}` },
		{ value: "PRICE_INCREASE", label: `${t("enums.invoices.reason_of_correction.PRICE_INCREASE")}` },
		{ value: "RETURN_GOOD", label: `${t("enums.invoices.reason_of_correction.RETURN_GOOD")}` },
		{ value: "MISTAKE", label: `${t("enums.invoices.reason_of_correction.MISTAKE")}` },
		{ value: "CHANGE_RECIPIENT", label: `${t("enums.invoices.reason_of_correction.CHANGE_RECIPIENT")}` },
	];

	const {
		register,
		formState: { errors },
		control,
	} = form;
	return (
		<fieldset className="form-group">
			<h5>{t("common.word.info_basic")}</h5>
			<div className={"row"}>
				<div className={"col-md-10"}>
					<div className={"row"}>
						<div className="col-md-6">
							<FormInput
								label={t("modules.invoice.field.place_of_issue.title")}
								register={register}
								name="place_of_issue"
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormDatePicker
								label={t("modules.invoice.field.issued_at.title")}
								control={control}
								name="dates.issued_at"
								timePicker={false}
								errors={errors}
							/>
						</div>
						{!order && (
							<>
								{(invoice.document_type !== "ADVANCE" || invoice.advance_id) && (
									<div className="col-md-6">
										<FormDatePicker
											label={t("modules.invoice.field.sold_at.title")}
											control={control}
											name="dates.sold_at"
											timePicker={false}
											errors={errors}
										/>
									</div>
								)}

								<div className="col-md-6">
									<FormDatePicker
										label={t("modules.invoice.field.pay_due_date.title")}
										control={control}
										name="dates.payment_due_date"
										timePicker={false}
										errors={errors}
									/>
								</div>
							</>
						)}
						<div className="col-md-6">
							<FormInput
								label={t("modules.invoice.field.issuer.title")}
								register={register}
								name="signed_by"
								errors={errors}
							/>
						</div>
						{invoice.document_type === "CORRECTION" && (
							<div className={"col-md-6"}>
								<FormSelectGroup
									label={t("modules.invoice.field.reason_of_correction.title")}
									name="reason_of_correction"
									defaultValue={invoice.reason_of_correction}
									errors={errors}
									options={reasonTypes}
									control={control}
									data-testid="reason_of_correction"
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</fieldset>
	);
};
export default InvoiceFormBasicInfo;
