import { useState } from "react";
import { useListenEvent } from "./useListenEvent";

export const useIsOnline = (): boolean => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useListenEvent({
		eventName: "online",
		callback: () => setIsOnline(true),
	});

	useListenEvent({
		eventName: "offline",
		callback: () => setIsOnline(false),
	});

	return isOnline;
};
