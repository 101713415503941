import { TFunction } from "react-i18next";
import { valueEncoder } from "go-list/core/components/Actions/services";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import {
	ItemFormApi,
	ItemFormPointOfSaleApi,
	PointOfSaleApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableAdditionalColumn, VirtualTableColumn } from "../../../../../components/VirtualTable/types";
import {
	getVirtualTablePdfDocumentDefinition,
	getVirtualTablePdfPageSize,
	getVirtualTableStyles,
	getVirtualTableValueByKeyParts,
} from "../../../../../components/VirtualTable/utils";

export enum ItemGroupGroupEditingColumn {
	"ITEM_GROUP" = "item_group",
	"CATEGORY" = "category",
	"NAME" = "name",
	"FULL_NAME" = "full_name",
	"POINT_OF_SALE" = "point_of_sale",
	"DIRECTION" = "direction",
	"AVAILABILITY" = "availability",
}
export const getInitialItemGroupGroupEditingListDefaultColumns = (t: TFunction): VirtualTableColumn[] => [
	{
		id: ItemGroupGroupEditingColumn.CATEGORY,
		name: t("common.word.category"),
	},
	{
		id: ItemGroupGroupEditingColumn.ITEM_GROUP,
		name: t("common.word.item_group"),
	},
	{
		id: ItemGroupGroupEditingColumn.NAME,
		name: t("common.word.variant"),
	},
];

export const getPossibleItemGroupGroupEditingListDefaultColumns = (t: TFunction<"translation", undefined>) => [
	{
		id: ItemGroupGroupEditingColumn.CATEGORY,
		name: t("common.word.category"),
	},
	{
		id: ItemGroupGroupEditingColumn.ITEM_GROUP,
		name: t("common.word.item_group"),
	},
	{
		id: ItemGroupGroupEditingColumn.NAME,
		name: t("common.word.variant"),
	},
	{
		id: ItemGroupGroupEditingColumn.FULL_NAME,
		name: t("common.word.item_full_name"),
	},
];

export const getItemGroupGroupEditingListOtherColumns = (t: TFunction) => [
	{
		id: ItemGroupGroupEditingColumn.AVAILABILITY,
		name: t("common.word.availability"),
	},
	{
		id: ItemGroupGroupEditingColumn.DIRECTION,
		name: t("modules.menu.field.default_direction.title"),
	},
];

export const itemGroupGroupEditingInclude =
	"category,item_group,points_of_sale,points_of_sale.point_of_sale,points_of_sale.direction,direction,availability";

export const itemGroupVirtualTableColumnKeys = [
	{
		key: "category.name",
		columnName: ItemGroupGroupEditingColumn.CATEGORY,
	},
	{
		key: "item_group.name",
		columnName: ItemGroupGroupEditingColumn.ITEM_GROUP,
	},
	{
		key: "direction.name",
		columnName: ItemGroupGroupEditingColumn.DIRECTION,
	},
	{
		key: "availability.name",
		columnName: ItemGroupGroupEditingColumn.AVAILABILITY,
	},
];

export const generateItemGroupsVirtualTableListCsv = (
	defaultColumns: VirtualTableColumn[],
	pointsOfSaleColumns: VirtualTableColumn[],
	exportConfig: ExportConfig,
	items: ItemFormApi[],
	additionalColumns: VirtualTableAdditionalColumn[],
	keys?: { columnName: string; key: string }[]
) => {
	let csvContent = "data:text/csv;charset=UTF-8,";
	const rows: Record<string, any>[] = [];
	const thead: string[] = [];
	defaultColumns.forEach((column) => {
		thead.push(`"${column.name}"`);
	});
	pointsOfSaleColumns.forEach((col) => {
		thead.push(`"${valueEncoder(col.name)}"`);
	});
	additionalColumns
		.map((additionalColumn) => additionalColumn.columns)
		.flat()
		.forEach((column) => {
			thead.push(`"${valueEncoder(column.name)}"`);
		});
	rows.push(thead);
	items.forEach((item) => {
		const bodyRow: string[] = [];
		defaultColumns.forEach((col) => {
			const columnKey = (keys || []).find((key) => key.columnName === col.id);
			const val = columnKey
				? getVirtualTableValueByKeyParts(columnKey.key, item)
				: item[`${col.id}` as keyof ItemFormApi];
			bodyRow.push(valueEncoder(`"${val}"`));
		});
		pointsOfSaleColumns.forEach((pointOfSale) => {
			const pointOfSaleDirection = item.points_of_sale.find(
				(point) => Number(point.point_of_sale_id) === Number(pointOfSale.id)
			);
			bodyRow.push(valueEncoder(`"${pointOfSaleDirection?.direction?.name || ""}"`));
		});
		additionalColumns
			.map((additionalColumn) => additionalColumn.columns)
			.flat()
			.forEach((column) => {
				const columnKey = (keys || []).find((key) => key.columnName === column.id);
				const val = columnKey
					? getVirtualTableValueByKeyParts(columnKey.key, item)
					: item[`${column.name}` as keyof ItemFormApi];
				bodyRow.push(valueEncoder(`"${val}"`));
			});
		rows.push(bodyRow);
	});
	csvContent += rows.map((e) => e.join(",")).join("\n");
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", `${exportConfig?.filename}.csv`);
	document.body.appendChild(link);
	link.click();
};

const generateTable = (
	items: ItemFormApi[],
	pointsOfSaleColumns: VirtualTableColumn[],
	additionalColumns: VirtualTableAdditionalColumn[],
	defaultColumns: VirtualTableColumn[],
	exportConfig: ExportConfig,
	keys?: { columnName: string; key: string }[]
) => {
	const tableBody: Record<string, any>[] = [];
	const widths: string[] = [];
	const tHead: Record<string, any>[] = [];
	const table = {
		widths,
		body: tableBody,
		dontBreakRows: true,
	};

	defaultColumns.forEach((col) => {
		tHead.push({
			text: col.name,
			style: getVirtualTableStyles(exportConfig).tableHeader,
		});
		widths.push("auto");
	});
	pointsOfSaleColumns.forEach((col) => {
		tHead.push({
			text: col.name,
			style: getVirtualTableStyles(exportConfig).tableHeader,
		});
		widths.push("auto");
	});
	additionalColumns
		.map((additionalColumn) => additionalColumn.columns)
		.flat()
		.forEach((column) => {
			tHead.push({
				text: column.name,
				style: getVirtualTableStyles(exportConfig).tableHeader,
			});
			widths.push("auto");
		});

	table.body.push(tHead);

	items.forEach((item) => {
		const bodyRow: Record<string, any>[] = [];
		defaultColumns.forEach((col) => {
			const columnKey = (keys || []).find((key) => key.columnName === col.id);
			bodyRow.push({
				text: {
					text: columnKey
						? getVirtualTableValueByKeyParts(columnKey.key, item)
						: item[`${col.id}` as keyof ItemFormApi],
					noWrap: false,
				},
				style: getVirtualTableStyles(exportConfig).tableCell,
			});
		});
		pointsOfSaleColumns.forEach((pointOfSale) => {
			const pointOfSaleDirection = item.points_of_sale.find(
				(point) => Number(point.point_of_sale_id) === Number(pointOfSale.id)
			);

			bodyRow.push({
				text: {
					text: pointOfSaleDirection?.direction?.name || "",
					noWrap: false,
				},
				style: getVirtualTableStyles(exportConfig).tableCell,
			});
		});
		additionalColumns
			.map((additionalColumn) => additionalColumn.columns)
			.flat()
			.forEach((column) => {
				const columnKey = (keys || []).find((key) => key.columnName === column.id);
				bodyRow.push({
					text: {
						text: columnKey
							? getVirtualTableValueByKeyParts(columnKey.key, item)
							: item[`${column.name}` as keyof ItemFormApi],
						noWrap: false,
					},
					style: getVirtualTableStyles(exportConfig).tableCell,
				});
			});
		table.body.push(bodyRow);
	});
	return table;
};

export const generateItemGroupsVirtualTableListPdf = async (
	items: ItemFormApi[],
	pointsOfSaleColumns: VirtualTableColumn[],
	additionalColumns: VirtualTableAdditionalColumn[],
	defaultColumns: VirtualTableColumn[],
	exportConfig: ExportConfig,
	filterNames: Array<string>,
	t: TFunction,
	keys?: { columnName: string; key: string }[]
) => {
	const table = generateTable(items, pointsOfSaleColumns, additionalColumns, defaultColumns, exportConfig, keys);
	let filterDefinition = {};
	if (filterNames.length > 0) {
		filterDefinition = {
			text: `${t("common.word.filters", { ns: "lib" })}:\n ${filterNames.join("\n")}`,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 3 : 12,
		};
	}
	const additionalColumnsLength = additionalColumns.map((additionalColumn) => additionalColumn.columns).flat().length;
	const docDefinition: any = getVirtualTablePdfDocumentDefinition(
		getVirtualTablePdfPageSize(additionalColumnsLength),
		exportConfig,
		t,
		filterDefinition,
		table
	);

	const pdfMake = (await import("pdfmake/build/pdfmake")).default;
	const pdfFonts = (await import("pdfmake/build/vfs_fonts")).default;
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	pdfMake.createPdf(docDefinition).download(`${exportConfig?.filename}`);
};

export const getParsedItemPointsOfSale = (item: ItemFormApi, pointsOfSale: PointOfSaleApi[]) => {
	const itemPointsOfSale: ItemFormPointOfSaleApi[] = [];
	pointsOfSale.forEach((pointOfSale) => {
		const itemPointOfSale = (item.points_of_sale || []).find(
			(itemPoint) => itemPoint.point_of_sale.id.toString() === pointOfSale.id.toString()
		);
		if (itemPointOfSale) {
			itemPointsOfSale.push({
				...itemPointOfSale,
				visibility:
					typeof itemPointOfSale.visibility !== "boolean"
						? itemPointOfSale.visibility === "VISIBLE"
						: itemPointOfSale.visibility,
			});
		} else {
			itemPointsOfSale.push({
				point_of_sale: { ...pointOfSale },
				direction_id: undefined,
				direction: undefined,
				visibility: false,
				point_of_sale_id: pointOfSale.id,
			});
		}
	});

	return itemPointsOfSale;
};
