import { OrganizationState } from "go-security/services/organizations/types";
import { LiveOrderApi } from "../../../../../../../services/Api/Organization/types";
import { LiveOrderDeliveryOptionType } from "./types";

export const getLiveOrderNumber = (order: LiveOrderApi) => {
	if (order?.source?.number && order.number !== order?.source?.number) {
		return `#${order.source.number} - ${order.number}`;
	}
	return `#${order.number}`;
};

export const isWoltDriveEnabled = (organization: OrganizationState) =>
	(organization.more.installed_app_providers || []).find((appProvider: string) => appProvider === "WOLT_DRIVE");

export const getDefaultDeliveryOptions = (organization: OrganizationState, order: LiveOrderApi) => {
	if (isWoltDriveEnabled(organization)) {
		if (order.delivery?.delivery_employee?.name === "WOLT_DRIVE") return LiveOrderDeliveryOptionType.WOLT_DRIVE;
		if (order.delivery?.delivery_employee?.reference_id || order.delivery?.delivery_employee?.id)
			return LiveOrderDeliveryOptionType.OWN_DRIVER;
		return undefined;
	}
	return LiveOrderDeliveryOptionType.OWN_DRIVER;
};
