import moment from "moment-timezone";
import { TFunction, useTranslation } from "react-i18next";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { FilterCondition } from "go-list/core/components/Filter/services/types";
import { getLang, getLangCountry } from "../../utils/utils";

export const formatDateTimeOptions = {
	hourCycle: "h23",
	year: "numeric",
	day: "2-digit",
	month: "2-digit",
	hour: "2-digit",
	minute: "2-digit",
};
export const defaultFormatDate = { year: "numeric", day: "2-digit", month: "2-digit" };
export const defaultFormatTime = { hourCycle: "h23", hour: "2-digit", minute: "2-digit", second: "2-digit" };
export const defaultMinuteFormatTime = { hourCycle: "h23", hour: "2-digit", minute: "2-digit" };

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:enums.dates.type.MONTH");
	t("lib:enums.dates.type.WEEK");
	t("lib:enums.dates.type.DAY");
	t("lib:enums.dates.type.HOURS");
	t("lib:enums.dates.type.MINUTES");
	t("lib:enums.dates.type.short.DAY");
	t("lib:enums.dates.type.short.HOURS");
	t("lib:enums.dates.type.short.MINUTES");
	t("lib:enums.days.MONDAY");
	t("lib:enums.days.TUESDAY");
	t("lib:enums.days.WEDNESDAY");
	t("lib:enums.days.THURSDAY");
	t("lib:enums.days.FRIDAY");
	t("lib:enums.days.SATURDAY");
	t("lib:enums.days.SUNDAY");
	t("lib:enums.days_short.MONDAY");
	t("lib:enums.days_short.TUESDAY");
	t("lib:enums.days_short.WEDNESDAY");
	t("lib:enums.days_short.THURSDAY");
	t("lib:enums.days_short.FRIDAY");
	t("lib:enums.days_short.SATURDAY");
	t("lib:enums.days_short.SUNDAY");
	t("lib:enums.month.JANUARY");
	t("lib:enums.month.FEBRUARY");
	t("lib:enums.month.MARCH");
	t("lib:enums.month.APRIL");
	t("lib:enums.month.MAY");
	t("lib:enums.month.JUNE");
	t("lib:enums.month.JULY");
	t("lib:enums.month.AUGUST");
	t("lib:enums.month.SEPTEMBER");
	t("lib:enums.month.OCTOBER");
	t("lib:enums.month.NOVEMBER");
	t("lib:enums.month.DECEMBER");
	t("lib:enums.month_short.JANUARY");
	t("lib:enums.month_short.FEBRUARY");
	t("lib:enums.month_short.MARCH");
	t("lib:enums.month_short.APRIL");
	t("lib:enums.month_short.MAY");
	t("lib:enums.month_short.JUNE");
	t("lib:enums.month_short.JULY");
	t("lib:enums.month_short.AUGUST");
	t("lib:enums.month_short.SEPTEMBER");
	t("lib:enums.month_short.OCTOBER");
	t("lib:enums.month_short.NOVEMBER");
	t("lib:enums.month_short.DECEMBER");
};

export const getDayName = (val: string, t: TFunction): string => {
	switch (val) {
		case "1":
			return t("lib:enums.days.MONDAY");
		case "2":
			return t("lib:enums.days.TUESDAY");
		case "3":
			return t("lib:enums.days.WEDNESDAY");
		case "4":
			return t("lib:enums.days.THURSDAY");
		case "5":
			return t("lib:enums.days.FRIDAY");
		case "6":
			return t("lib:enums.days.SATURDAY");
		case "7":
			return t("lib:enums.days.SUNDAY");
		default:
			return "-";
	}
};

export const getMonthName = (val: string, tHook: TFunction): string => {
	switch (val) {
		case "1":
			return tHook("lib:enums.month.JANUARY");
		case "2":
			return tHook("lib:enums.month.FEBRUARY");
		case "3":
			return tHook("lib:enums.month.MARCH");
		case "4":
			return tHook("lib:enums.month.APRIL");
		case "5":
			return tHook("lib:enums.month.MAY");
		case "6":
			return tHook("lib:enums.month.JUNE");
		case "7":
			return tHook("lib:enums.month.JULY");
		case "8":
			return tHook("lib:enums.month.AUGUST");
		case "9":
			return tHook("lib:enums.month.SEPTEMBER");
		case "10":
			return tHook("lib:enums.month.OCTOBER");
		case "11":
			return tHook("lib:enums.month.NOVEMBER");
		case "12":
			return tHook("lib:enums.month.DECEMBER");
		default:
			return "-";
	}
};

export const getShortMonthName = (val: string, t: TFunction): string => {
	switch (val) {
		case "1":
			return t("lib:enums.month_short.JANUARY");
		case "2":
			return t("lib:enums.month_short.FEBRUARY");
		case "3":
			return t("lib:enums.month_short.MARCH");
		case "4":
			return t("lib:enums.month_short.APRIL");
		case "5":
			return t("lib:enums.month_short.MAY");
		case "6":
			return t("lib:enums.month_short.JUNE");
		case "7":
			return t("lib:enums.month_short.JULY");
		case "8":
			return t("lib:enums.month_short.AUGUST");
		case "9":
			return t("lib:enums.month_short.SEPTEMBER");
		case "10":
			return t("lib:enums.month_short.OCTOBER");
		case "11":
			return t("lib:enums.month_short.NOVEMBER");
		case "12":
			return t("lib:enums.month_short.DECEMBER");
		default:
			return "-";
	}
};

export const convertDateToTimezone = (date: string, timezone: string): Date => {
	const newDate = convertUTCDateToLocalDate(date, timezone);
	const invdate = new Date(
		newDate.toLocaleString("en-US", {
			timeZone: timezone,
		})
	);

	const dateArgumentAsObject = new Date(date);
	const diff = dateArgumentAsObject.getTime() - invdate.getTime();
	return new Date(dateArgumentAsObject.getTime() - diff); // needs to substract
};

export const convertUTCDateToLocalDate = (date: string, organizationTimezone?: string): Date => {
	const localDate = moment
		.utc(date)
		.tz(organizationTimezone || "Europe/Warsaw")
		.toDate();

	return localDate;
};

export const convertLocalDateToUTCDate = (date: Date): Date => {
	const newDate = new Date(date.getTime());
	const offset = date.getTimezoneOffset() / 60;
	newDate.setTime(newDate.getTime() + offset * 60 * 60 * 1000);
	return newDate;
};

export const formatFormDateToString = (dateString: string): Date => {
	return formatStringToDate(dateString);
};
export const formatStringToDate = (dateString: string): Date => {
	if (!dateString) return new Date();
	// let dateTime = Date.parse(dateString);
	// if(!isNaN(dateTime)) return new Date(dateTime);
	let dateTimes = dateString.split(" ");
	if (dateTimes.length === 1) dateTimes = dateString.split("T");
	const date = dateTimes[0].replaceAll(",", "");
	const time = dateTimes[1] || "00:00:00";
	let isUSA = false;
	// if (date.includes("/")) isUSA = getLang().includes("US") || getLang() === "en";
	if (date.includes("/")) {
		const langCountry = getLangCountry();
		isUSA = Boolean(langCountry && langCountry.toUpperCase().includes("US"));
	}

	// if (date.includes("/")) isUSA = getLang().includes("US") || getLang().includes("en");
	const dateNumbers = date.includes("-")
		? date.split("-")
		: date.includes("/")
		? date.split("/")
		: date.includes(".")
		? date.split(".")
		: undefined;
	if (!dateNumbers) return new Date(dateString);
	const [hours, minutes, seconds] = time.split(",")[0].split(":");
	let year = Number(dateNumbers[0]);
	let month = Number(dateNumbers[1]);
	let day = Number(dateNumbers[2]);
	if (dateNumbers[0].length !== 4) {
		year = Number(dateNumbers[2]);
		day = Number(dateNumbers[0]);
	}
	if (isUSA) {
		const tmp = month;
		month = day;
		day = tmp;
	}
	return new Date(year, month - 1, day, Number(hours), Number(minutes), seconds ? Number(seconds) : 0);
	// return new Date(Number(dateNumbers[2]), Number(dateNumbers[1]) - 1, Number(dateNumbers[0]), Number(timeNumbers[0]), Number(timeNumbers[1]), timeNumbers[2] ? Number(timeNumbers[2]) : 0)
};

export const formatDateToString = (date: Date): string => {
	const year = date.getFullYear();
	let month: string | number = date.getMonth() + 1;
	let day: string | number = date.getDate();

	if (month < 10) {
		month = `0${month}`;
	}
	if (day < 10) {
		day = `0${day}`;
	}

	return `${year}-${month}-${day}`;
};

export const formatDateTimeToString = (date: Date): string => {
	const year = date.getFullYear();
	let month: string | number = date.getMonth() + 1;
	let day: string | number = date.getDate();
	let hour: string | number = date.getHours();
	let minute: string | number = date.getMinutes();
	let sec: string | number = date.getSeconds();

	if (month < 10) {
		month = `0${month}`;
	}
	if (day < 10) {
		day = `0${day}`;
	}
	if (hour < 10) {
		hour = `0${hour}`;
	}
	if (minute < 10) {
		minute = `0${minute}`;
	}
	if (sec < 10) {
		sec = `0${sec}`;
	}

	return `${year}-${month}-${day} ${hour}:${minute}:${sec}`;
};

export const FormatTime = (
	dateString: string | Date,
	timezone?: string,
	formatOptions?: Record<string, any>
): string => {
	if (!dateString) {
		return "";
	}

	let date = dateString instanceof Date ? dateString : formatStringToDate(dateString);
	const dateAsString = typeof dateString === "string" ? dateString : dateString.toISOString();
	if (timezone) {
		date = convertDateToTimezone(dateAsString, timezone);
	}
	let newFormatOptions = formatOptions;
	if (!formatOptions) {
		newFormatOptions = defaultFormatTime;
	}
	return date.toLocaleTimeString(getLang(), {
		...newFormatOptions,
	});
};
export const FormatDate = (
	dateString: string | Date,
	timezone?: string,
	showSeconds?: boolean,
	formatOptions?: Record<string, any>,
	locale?: string
): string => {
	if (!dateString) {
		return "";
	}

	let date = dateString instanceof Date ? dateString : formatStringToDate(dateString);
	const dateAsString = typeof dateString === "string" ? dateString : dateString.toISOString();
	if (timezone) {
		date = convertDateToTimezone(dateAsString, timezone);
	}
	let newFormatOptions = formatOptions;
	if (!formatOptions) {
		newFormatOptions = defaultFormatTime;
	}
	let newLocale = locale;
	if (!locale) {
		newLocale = getLang();
	}
	return date.toLocaleDateString(newLocale, {
		...newFormatOptions,
		second: showSeconds ? "2-digit" : undefined,
	});
	// return dateString.includes("T") || dateString.includes(" ") ? date.toLocaleString(getLang(), {
	//     ...formatDateTimeOptions,
	//     second: showSeconds ? "2-digit" : undefined
	// } as any) : date.toLocaleDateString(getLang());
};

export const FormatUniversalDate = (
	dateString: string | Date,
	timezone?: string,
	withTime?: boolean,
	showSeconds?: boolean
): string => {
	if (!dateString) {
		return "";
	}

	let date = dateString instanceof Date ? dateString : formatStringToDate(dateString);
	const dateAsString = typeof dateString === "string" ? dateString : dateString.toISOString();
	if (timezone) {
		date = convertDateToTimezone(dateAsString, timezone);
	}

	let result = "";
	if (withTime) {
		const dateTime = formatDateTimeToString(date);
		const dateFromDateTime = dateTime.split(" ")[0].split("-").reverse().join(".");
		let timeFromDateTime = dateTime.split(" ")[1];
		if (!showSeconds) {
			timeFromDateTime = timeFromDateTime.substring(0, timeFromDateTime.length - 3);
		}
		result = `${dateFromDateTime}, ${timeFromDateTime}`;
	} else {
		result = formatDateToString(date).split("-").reverse().join(".");
	}

	return result;
};

export const FormatDateToTime = (date: string): string => {
	return `${FormatDate(date).split(", ")[1]}`;
};

export const FormatDateToDateHoursRange = (startDateString: string, endDateString: string): string => {
	const oneDay = 24 * 60 * 60 * 1000;

	const startDate = formatStringToDate(startDateString);
	if (endDateString) {
		const endDate = formatStringToDate(endDateString);
		if (endDate.getDate() === startDate.getDate() && endDate.getMonth() === startDate.getMonth()) {
			return `${FormatDate(startDate, undefined, undefined, defaultFormatDate)}, ${FormatTime(
				startDate,
				undefined,
				defaultMinuteFormatTime
			)} - ${FormatTime(endDate, undefined, defaultMinuteFormatTime)}`;
		} else if (
			endDate.getTime() - startDate.getTime() < oneDay &&
			startDate.getFullYear() === endDate.getFullYear()
		) {
			return `${FormatDate(startDate, undefined, undefined, defaultFormatDate)}, ${FormatTime(
				startDate,
				undefined,
				defaultMinuteFormatTime
			)} - ${FormatTime(endDate, undefined, defaultMinuteFormatTime)}`;
		}
		return `${FormatDate(startDate)} - ${FormatDate(endDate)}`;
	}
	return `${FormatDate(startDate)}`;
};

export const formatTimeAgo = (
	date: string,
	t: TFunction<"translation", undefined>,
	applyDateAsLocal = false
): string => {
	const dateNow = new Date();
	let timeAgoDate = new Date(date);

	if (applyDateAsLocal) {
		timeAgoDate = moment.utc(date).toDate();
	}

	const diff = (dateNow.getTime() - timeAgoDate.getTime()) / 1000;

	let interval = diff / 31536000;

	if (interval > 1) {
		return `${Math.floor(interval)} ${t("lib:enums.dates.year_ago")}`;
	}
	interval = diff / 2592000;
	if (interval > 1) {
		return `${Math.floor(interval)} ${t("lib:enums.dates.months_ago")}`;
	}
	interval = diff / 86400;
	if (interval > 1) {
		return `${Math.floor(interval)} ${t("lib:enums.dates.days_ago", { count: Math.trunc(interval) })}`;
	}
	interval = diff / 3600;
	if (interval > 1) {
		return `${Math.floor(interval)} ${t("lib:enums.dates.hours_ago")}`;
	}
	interval = diff / 60;
	if (interval > 1) {
		return `${Math.floor(interval)} ${t("lib:enums.dates.minutes_ago")}`;
	}
	return `${Math.floor(diff)} ${t("lib:enums.dates.seconds_ago")}`;
};

export const FormatDurationSeconds = (
	durationInSeconds: number,
	t: TFunction,
	hideDay?: boolean,
	showSeconds?: boolean
): string => {
	const durationInMinutes = durationInSeconds / 60;
	let minutes = durationInMinutes % 60;
	const days = Math.floor(durationInMinutes / (60 * 24));
	let hours = Math.floor(durationInMinutes / 60) % 24;
	const seconds = Math.floor((minutes - Math.floor(minutes)) * 60);
	if (hideDay) {
		hours = Math.floor(durationInMinutes / 60);
	}
	if (durationInSeconds === 0) {
		if (showSeconds) return `${seconds}${t("lib:enums.dates.type.short.SECONDS")}`;
		return `0${t("lib:enums.dates.type.short.HOURS")} 0${t("lib:enums.dates.type.short.MINUTES")}`;
	}
	minutes = showSeconds ? Math.floor(minutes) : minutes;
	const strings = [] as string[];
	if (days > 0 && !hideDay) strings.push(`${days}${t("lib:enums.dates.type.short.DAY")}`);
	if (hours > 0 || strings.length > 0) strings.push(`${hours}${t("lib:enums.dates.type.short.HOURS")}`);
	if (minutes > 0 || strings.length > 0) strings.push(`${minutes}${t("lib:enums.dates.type.short.MINUTES")}`);
	if ((seconds > 0 || strings.length > 0) && showSeconds)
		strings.push(`${seconds}${t("lib:enums.dates.type.short.SECONDS")}`);
	return strings.join(" ");
};
export const FormatDuration = (
	durationInMinutes: number,
	t: TFunction,
	hideDay?: boolean,
	showSeconds?: boolean
): string => {
	let minutes = durationInMinutes % 60;
	const days = Math.floor(durationInMinutes / (60 * 24));
	let hours = Math.floor(durationInMinutes / 60) % 24;
	if (hideDay) {
		hours = Math.floor(durationInMinutes / 60);
	}
	if (durationInMinutes === 0) {
		return `0${t("lib:enums.dates.type.short.HOURS")} 0${t("lib:enums.dates.type.short.MINUTES")}`;
	}
	minutes = showSeconds ? Math.floor(minutes) : minutes;
	return `${days > 0 && hideDay !== true ? `${days}${t("lib:enums.dates.type.short.DAY")}` : ""} ${
		durationInMinutes > 0 && `${hours}${t("lib:enums.dates.type.short.HOURS")}`
	} ${minutes}${t("lib:enums.dates.type.short.MINUTES")}`;
};

export const FormatDurationInSeconds = (durationInSeconds: number, t: TFunction): string => {
	const hours = Math.floor(durationInSeconds / 3600);
	const minutes = Math.floor((durationInSeconds % 3600) / 60);
	const seconds = Math.round(durationInSeconds % 60);
	const days = Math.floor(durationInSeconds / (60 * 60 * 24));
	if (durationInSeconds === 0) {
		return `0${t("lib:enums.dates.type.short.SECONDS")}`;
	}

	return `${days > 0 ? `${days}${t("lib:enums.dates.type.short.DAY")}` : ""} ${
		hours > 0 ? `${hours}${t("lib:enums.dates.type.short.HOURS")}` : ""
	} ${minutes}${t("lib:enums.dates.type.short.MINUTES")} ${seconds}${t("lib:enums.dates.type.short.SECONDS")}`;
};

export const filterParseDate = (date: Date): string => {
	const value = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
	return value.replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
};

export const filterParseDateRange = (dateFrom: Date, dateTo: Date): string => {
	const valueFrom = `${dateFrom.getFullYear()}-${
		dateFrom.getMonth() + 1
	}-${dateFrom.getDate()} ${dateFrom.getHours()}:${dateFrom.getMinutes()}:${dateFrom.getSeconds()}`;
	const valueTo = `${dateTo.getFullYear()}-${
		dateTo.getMonth() + 1
	}-${dateTo.getDate()} ${dateTo.getHours()}:${dateTo.getMinutes()}:${dateTo.getSeconds()}`;
	return `${parseDateString(valueFrom)} - ${parseDateString(valueTo)}`;
};

export const filterParseDateBetween = (dateFrom: Date, dateTo: Date): string => {
	const valueFrom = `${dateFrom.getFullYear()}-${
		dateFrom.getMonth() + 1
	}-${dateFrom.getDate()} ${dateFrom.getHours()}:${dateFrom.getMinutes()}:${dateFrom.getSeconds()}`;
	const valueTo = `${dateTo.getFullYear()}-${
		dateTo.getMonth() + 1
	}-${dateTo.getDate()} ${dateTo.getHours()}:${dateTo.getMinutes()}:${dateTo.getSeconds()}`;
	return `${parseDateString(valueFrom)}${FILTER_VALUE_SEPARATOR}${parseDateString(valueTo)}`;
};

export const parseDateString = (date: string): string => {
	return date.replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
};

export const isValidDate = (date: Date | string): boolean => {
	return !(!date || date === "Invalid Date");
};

export const parseDateToDefaultString = (date: Date): string => {
	return `${date.getFullYear()}-${
		Number(date.getMonth()) + 1
	}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`.replace(
		/(^|\D)(\d)(?!\d)/g,
		"$10$2"
	);
};

export const isFilterDateFromSelected = (type: FilterCondition) => type === "gt" || type === "gte";
export const isFilterDateToSelected = (type: FilterCondition) => type === "lt" || type === "lte";

export const isDST = (date: Date): boolean => {
	const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
	const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
	return Math.max(jan, jul) !== date.getTimezoneOffset();
};

export const exactlySameDay = (firstDateToCompare?: Date, secondDateToCompare?: Date) => {
	if (firstDateToCompare === undefined || secondDateToCompare === undefined) return false;

	return (
		firstDateToCompare.getFullYear() === secondDateToCompare.getFullYear() &&
		firstDateToCompare.getMonth() === secondDateToCompare.getMonth() &&
		firstDateToCompare.getDate() === secondDateToCompare.getDate()
	);
};

export default FormatDate;
