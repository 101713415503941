import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import DeliveryZoneForm from "../../components/Form/DeliveryZoneForm";

const OrganizationDeliveryZoneNewPage = () => {
	const { t } = useTranslation();
	const [deliveryZones, setDeliveryZones] = useState<DeliveryZoneApi[] | undefined>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.delivery_zone.header.new.title"));
		fetchDeliveryZones();
	}, []);

	const fetchDeliveryZones = async () => {
		try {
			const res = await apiOrganization.getDeliveryZones({ size: 0, include: "app" });
			setDeliveryZones(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!deliveryZones) return <LoadingContainer />;

	return (
		<>
			<Header title={t("modules.delivery_zone.header.new.title")} />
			<DeliveryZoneForm deliveryZones={deliveryZones} />
		</>
	);
};

export default OrganizationDeliveryZoneNewPage;
