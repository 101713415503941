import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { MultipleActionsParams } from "go-list/list/services/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	onClose: () => void;
	items: number[];
	handleSave: () => void;
	allItemsSelected: boolean;
	multipleActionParams?: MultipleActionsParams;
}

interface FormProps {
	category_id: number | null;
}

const AssignMultipleProductsToCategoryModal: FC<Props> = ({
	onClose,
	items,
	handleSave,
	allItemsSelected,
	multipleActionParams,
}) => {
	const form = useForm<FormProps>({
		defaultValues: {},
		criteriaMode: "all",
	});
	const [loading, setLoading] = useState(false);
	const {
		formState: { errors },
		control,
		setError,
		handleSubmit,
	} = form;
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const searchCategories = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getCategoriesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = handleSubmit(async (data: FormProps) => {
		if (data.category_id === undefined) data.category_id = null;
		setLoading(true);
		const params: Record<string, any> = multipleActionParams ? { ...multipleActionParams } : {};
		if (!allItemsSelected) params.id = items.join(",");
		try {
			await api.organization().patchItemGroups(data, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleSave();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={true} onHide={onClose}>
			<FormDirty onSubmit={onSubmit}>
				<Modal.Header>
					<h5>{t("modules.item_group.action.assign_multiple_products_to_category.title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						label={t("common.word.category")}
						isMulti={false}
						name="category_id"
						errors={errors}
						defaultValue={null}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						control={control}
						loadOptions={searchCategories}
						data-testid="category_id"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default AssignMultipleProductsToCategoryModal;
