import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { InvoiceApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import CorrectionForm from "../../components/CorrectionForm";

interface Props extends RouteComponentProps {
	invoiceId: string;
	correctionId?: number;
}

const OrganizationInvoicesCorrectionsEditPage: FC<Props> = (props) => {
	const organization = useSelector(selectOrganization);
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const [correction, setCorrection] = useState<InvoiceApi | undefined>(undefined);
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const history = useHistory();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const invParams: Record<string, string> = {
			include: "linked_invoices,payments,previous_advances,payments.transaction",
		};
		const corrParams: Record<string, string> = {
			include:
				"custom_fields,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver,issuer,linked_invoices,linked_invoices.items,previous_advances",
		};
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		let inv: InvoiceApi;
		if (props.correctionId) {
			Promise.all([
				api.organization().getInvoice(props.correctionId, corrParams),
				api.organization().getCustomFields(cfParams),
				api.organization().getInvoiceDetails(),
			])
				.then(([correctionResponse, cfResponse]) => {
					setCorrection(correctionResponse);
					const linkedInvoice = correctionResponse.linked_invoices.find(
						(f: { document_type: string }) => f.document_type === "INVOICE" || f.document_type === "ADVANCE"
					) as InvoiceApi | undefined;
					setInvoice(linkedInvoice);
					setCustomFieldsConfig(cfResponse);
					handleChangeTabTitle();
				})
				.catch((err) => {
					handleError.alert(err, addFlash);
				});
		} else {
			Promise.all([
				api.organization().getInvoice(Number(props.invoiceId), invParams),
				api.organization().getCustomFields(cfParams),
				api.organization().getInvoiceDetails(),
			])
				.then(([invoiceResponse, cfResponse]) => {
					inv = invoiceResponse;
					setInvoice(inv);
					setCustomFieldsConfig(cfResponse);
				})
				.then(() => {
					const correctionId = inv?.linked_invoices.find((f) => f.document_type === "CORRECTION")?.id;
					if (correctionId) {
						return api.organization().getInvoice(correctionId, corrParams);
					}
				})
				.then((res) => {
					setCorrection(res);
				})
				.catch((err) => {
					handleError.alert(err, addFlash);
				});
		}
	}, []);

	useEffect(() => {
		handleChangeTabTitle(
			`${t("modules.invoice.field.correction.title")} ${t("common.word.to", { ns: "lib" })} ${t(
				"common.word.invoice"
			)} ${invoice?.number || ""}`
		);
	}, [invoice?.number]);

	if (!invoice || !customFieldsConfig || !correction) {
		return <LoadingContainer />;
	}

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/invoices/${invoice.id}/corrections`);

	return (
		<>
			<Header
				back={goBack}
				title={`${t("modules.invoice.field.correction.title")} ${t("common.word.to", { ns: "lib" })} ${t(
					"common.word.invoice"
				)} ${invoice.number}`}
			/>
			<CorrectionForm invoice={invoice} correction={correction} customFieldsConfig={customFieldsConfig} />
		</>
	);
};
export default OrganizationInvoicesCorrectionsEditPage;
