import { FC, useMemo } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps, useParams } from "react-router-dom";
import OrganizationInvoicesCorrectionsEditPage from "./Edit";
import OrganizationInvoicesCorrectionsNewPage from "./New";
import OrganizationInvoicesCorrectionsPreviewPage from "./Preview";

interface MatchParams {
	invoice_id: string;
}

const OrganizationInvoicesCorrectionsPage: FC<RouteComponentProps> = (props) => {
	const { invoice_id } = useParams<MatchParams>();
	const loc = props.location.state as Record<string, any>;
	const renderView = useMemo(() => {
		return (
			<Switch>
				<Route
					path={`${props.match.url}/new`}
					exact={true}
					component={() => (
						<OrganizationInvoicesCorrectionsNewPage
							invoiceId={invoice_id}
							history={props.history}
							location={props.location}
							match={props.match}
						/>
					)}
				/>
				<Route
					exact={true}
					path={`${props.match.url}/`}
					component={() => (
						<OrganizationInvoicesCorrectionsPreviewPage
							correctionId={loc?.correction_id}
							invoiceId={invoice_id}
							history={props.history}
							location={props.location}
							match={props.match}
						/>
					)}
				/>
				<Route
					exact={true}
					path={`${props.match.url}/edit`}
					component={() => (
						<OrganizationInvoicesCorrectionsEditPage
							correctionId={loc?.correction_id}
							invoiceId={invoice_id}
							history={props.history}
							location={props.location}
							match={props.match}
						/>
					)}
				/>
			</Switch>
		);
	}, [invoice_id]);
	return <>{renderView}</>;
};
export default OrganizationInvoicesCorrectionsPage;
