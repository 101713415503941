import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import { apiOrganization } from "../../../../../../../services/Api/Organization/apiOrganization";
import { LiveOrderApi } from "../../../../../../../services/Api/Organization/types";
import { LiveOrdersFilters } from "./types";

export const liveOrderParamsInclude =
	"preparation_status,contact,contact.client,transactions.transaction,items.product," +
	"terminal,closing_terminal,source,items,transactions,table,fiscalization," +
	"transactions.payment_method,delivery,delivery.delivery_zone,delivery.coordinates,delivery.address," +
	"delivery.delivery_employee,pending_terminal_notifications,promotions,promotions.items";

export class OrderSynchronizer {
	_orders: LiveOrderApi[];
	lastUpdatedAt?: string;
	size: number;

	constructor() {
		this._orders = [];
		const startDate = new Date();
		startDate.setHours(new Date().getHours() - 6);
		this.lastUpdatedAt = startDate.toISOString();
		this.size = 20;
	}

	async getAll(dateTo: string): Promise<LiveOrderApi[]> {
		let idFilter = "";
		let baseFilter = "";
		if (this.lastUpdatedAt) {
			baseFilter = `updated_at|bt=${this.lastUpdatedAt}${FILTER_VALUE_SEPARATOR}${dateTo}`;
			idFilter = baseFilter;
		}
		const allOrders = [] as LiveOrderApi[];
		let fetchAll = false;
		while (!fetchAll) {
			const params: Record<string, any> = {
				page: 0,
				size: this.size,
				sort: "id",
				f: btoa(unescape(encodeURIComponent(NEW_WAY_TO_ENCODING_FILTER_SIGN + idFilter))),
				include: liveOrderParamsInclude,
			};
			const newOrders = await apiOrganization.getLiveOrders(params);
			allOrders.push(...newOrders);
			if (newOrders.length < this.size) {
				fetchAll = true;
			}
			const maxId = Math.max(...allOrders.map(({ id }) => id));
			if (maxId) {
				idFilter = `${baseFilter}${FILTER_SEPARATOR}id|gt=${maxId}`;
			} else {
				idFilter = baseFilter;
			}
		}
		return allOrders;
	}

	async sync(): Promise<LiveOrderApi[]> {
		const now = new Date();
		const allOrders = await this.getAll(now.toISOString());
		this._orders = [
			...allOrders.filter((order) => !this._orders.find((o) => o.id === order.id)),
			...this._orders.map((order) => {
				const possibleUpdatedOrder = allOrders.find((o) => o.id === order.id);
				if (possibleUpdatedOrder) {
					return possibleUpdatedOrder;
				}
				return order;
			}),
		];
		this._orders = this._orders.sort(
			(a, b) => formatStringToDate(b.pos_updated_at).getTime() - formatStringToDate(a.pos_updated_at).getTime()
		);
		// one second overlap
		this.lastUpdatedAt = new Date(now.getTime() - 1000).toISOString();
		return this._orders;
	}

	updateFilters(filters: LiveOrdersFilters): void {
		this._orders = [];

		const startDate = new Date();
		startDate.setHours(new Date().getHours() - Number(filters.time.id));
		this.lastUpdatedAt = startDate.toISOString();
	}

	orders(): LiveOrderApi[] {
		return this._orders;
	}
}
