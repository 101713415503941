import React, { useEffect, useRef, useState } from "react";
import L, { PathOptions } from "leaflet";
import { ButtonGroup, Dropdown, Form } from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Polyline, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { ReactComponent as ColumnSVG } from "go-report/core/images/column.svg";
import { ReactComponent as CrosshairsSVG } from "../../../../../../../../../images/svg/liveOrders/crosshairs.svg";
import { ReactComponent as InfoCircleSVG } from "../../../../../../../../../images/svg/liveOrders/info-circle.svg";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryZoneApi, OrganizationAddressApi } from "../../../../../../../../../services/Api/Organization/types";
import LiveOrderOrganizationMarker from "../../../../LiveOrders/pages/Map/components/LiveOrderOrganizationMarker";
import { getDefaultMapPosition } from "../../../../LiveOrders/pages/Map/components/LiveOrdersMap";
import LiveOrdersMapLegend from "../../../../LiveOrders/pages/Map/components/MapLegend/LiveOrdersMapLegend";
import { deliveryZonesColors, parseDeliveryZonePath } from "../../../../LiveOrders/services/orderDelivery";
import { LiveOrderReportOrder, RecenterLiveOrdersReportMapAutomatically } from "../types/types";

const RecenterAutomatically = ({ lat, lng, resetPosition }: RecenterLiveOrdersReportMapAutomatically) => {
	const map = useMap();

	map.scrollWheelZoom.enable();
	map.invalidateSize();

	const isAnyCoordinateInvalid = isNaN(lat) || isNaN(lng);

	if (resetPosition.current && !isAnyCoordinateInvalid) {
		map.flyTo([lat, lng], map.getZoom(), { duration: 1 });
		resetPosition.current = false;
	}

	useEffect(() => {
		if (!isAnyCoordinateInvalid) {
			map.flyTo([lat, lng], map.getZoom(), { duration: 1 });
		}
	}, [lat, lng]);

	return null;
};

interface Props {
	orders: LiveOrderReportOrder[];
}

const LiveOrdersReportMap = ({ orders }: Props) => {
	const { t } = useTranslation();
	const [organizationAddress, setOrganizationAddress] = useState<OrganizationAddressApi | undefined>();
	const organizationPosition = getDefaultMapPosition(organizationAddress);
	const [position, setPosition] = useState<number[]>(organizationPosition);
	const resetMapPosition = useRef<any>(false);
	const organizationAddressAsString = JSON.stringify(organizationAddress);
	const [deliveryZones, setDeliveryZones] = useState<DeliveryZoneApi[]>([]);
	const { addFlash } = useFlash();
	const [showMapLegend, setShowMapLegend] = useState(false);
	const [showOrdersValue, setShowOrdersValue] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchMapInfo();
	}, []);

	const fetchMapInfo = () => {
		Promise.all([apiOrganization.getOrganizationAddress(), apiOrganization.getOrdersDeliveryZones()])
			.then((res) => {
				setOrganizationAddress(res[0]);
				setDeliveryZones(res[1]);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
		setLoading(false);
	};

	useEffect(() => {
		if (
			JSON.stringify({
				latitude: position[0],
				longitude: position[1],
			}) !== JSON.stringify(organizationAddress?.coordinates)
		) {
			setPosition([...getDefaultMapPosition(organizationAddress)]);
		}
	}, [organizationAddressAsString]);

	const drawMarkers = () => {
		return orders.map((order, index) => {
			const lat = order.coordinates.latitude;
			const lon = order.coordinates.longitude;
			const markersAmountForOrder = order.ordersCount;
			const markers = [];
			for (let i = 0; i < markersAmountForOrder; i++) {
				markers.push(
					<Marker
						position={[lat, lon]}
						alt={JSON.stringify(order.totalPrice)}
						key={`order-marker-${index}-${i}-${order.coordinates.latitude}-${order.coordinates.longitude}`}
					/>
				);
			}
			return markers;
		});
	};

	const onResetMapPosition = () => {
		setPosition(organizationPosition);
		resetMapPosition.current = true;
	};

	const getDeliveryZoneColor = (index: number) => {
		if (index > deliveryZonesColors.length) return "#fff";
		return deliveryZonesColors[index];
	};

	const drawDeliveryZones = () => {
		return deliveryZones.map((zone, index) => {
			const positions = parseDeliveryZonePath(zone);
			const color = getDeliveryZoneColor(index);
			const options: PathOptions = { color };

			return <Polyline key={`zone-${zone.id}`} pathOptions={options} positions={positions as any[]} />;
		});
	};

	const clusterIcon = (cluster: any): any => {
		const count = cluster.getChildCount();
		const allMarkers: any[] = cluster.getAllChildMarkers();
		const currency = (JSON.parse(allMarkers[0].options.alt) as MoneyApi).currency;
		const totalMoney: number = allMarkers
			.map((marker) => {
				return (JSON.parse(marker.options.alt) as MoneyApi).amount;
			})
			.reduce((cur, acc) => cur + acc, 0);

		let colorClass = "";
		if (count <= 10) {
			colorClass = "marker-cluster-small";
		} else if (count <= 50) {
			colorClass = "marker-cluster-medium";
		} else colorClass = "marker-cluster-large";

		return L.divIcon({
			className: `leaflet-marker-icon marker-cluster ${colorClass} leaflet-zoom-animated leaflet-interactive `,
			html: ReactDOMServer.renderToString(
				<div className="position-relative">
					<div className={`popup ${showOrdersValue ? "d-flex" : "d-none"}`}>
						<p className="mb-0 text-nowrap">{FormatMoney({ amount: totalMoney, currency })}</p>
					</div>
					<span>{count}</span>
				</div>
			),
			iconSize: [40, 40],
		});
	};

	if (loading) return <LoadingContainer />;

	return (
		<div className="w-100 h-100 position-relative live-orders-report-map-container">
			<MapContainer center={position as any} zoom={13} minZoom={8} style={{ height: "100%", width: "100%" }}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<div className="leaflet-top leaflet-right">
					<div className="leaflet-control-zoom leaflet-bar leaflet-control">
						<a
							className="leaflet-control-zoom-out"
							href="#"
							title="Legend"
							role="button"
							onClick={() => setShowMapLegend(true)}
							aria-label="Legend"
						>
							<InfoCircleSVG />
						</a>
						<Dropdown className="columns-dropdown" as={ButtonGroup}>
							<Dropdown.Toggle>
								<ColumnSVG />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<div className="filters-search-columns">
									<Form.Group
										key="show-value"
										controlId="show-value"
										className="form-group dropdown-item mb-0"
									>
										<Form.Check
											type="checkbox"
											label={t("modules.live_order_report.action.show_values.title")}
											checked={showOrdersValue}
											onChange={() => setShowOrdersValue((prevState) => !prevState)}
										/>
									</Form.Group>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<div className="leaflet-bottom leaflet-left">
					<div className="leaflet-control-zoom leaflet-bar leaflet-control">
						<a
							className="leaflet-control-zoom-out reset-position-button"
							href="#"
							title="Rest position"
							role="button"
							onClick={onResetMapPosition}
							aria-label="Reset position"
						>
							<CrosshairsSVG />
						</a>
					</div>
				</div>
				{drawDeliveryZones()}
				<MarkerClusterGroup
					showCoverageOnHover={false}
					zoomToBoundsOnClick={false}
					removeOutsideVisibleBounds
					spiderfyOnMaxZoom={false}
					singleMarkerMode
					iconCreateFunction={clusterIcon}
					key={`${showOrdersValue}`}
				>
					{drawMarkers()}
				</MarkerClusterGroup>
				<LiveOrderOrganizationMarker
					coordinates={{
						latitude: position[0],
						longitude: position[1],
					}}
				/>
				<RecenterAutomatically lat={position[0]} lng={position[1]} resetPosition={resetMapPosition} />
			</MapContainer>
			{showMapLegend && (
				<LiveOrdersMapLegend
					setShowMapLegend={setShowMapLegend}
					showOrderList={false}
					isOrderSelected={false}
					deliveryZones={deliveryZones}
					rightPosition={10}
					omittedSections={["icon_sizes", "preparation_statuses", "employees", "statuses"]}
				/>
			)}
		</div>
	);
};

export default LiveOrdersReportMap;
