import React from "react";
import { useParams } from "react-router";
import { LoadingContainer } from "go-core/components/Loading";
import { CompanyInfoSection } from "./components/CompanyInfo/CompanyInfoSection";
import { Footer } from "./components/Footer/Footer";
import { OrderActionsSection } from "./components/OrderActions/OrderActionsSection";
import { OrderDetailsSection } from "./components/OrderDetails/OrderDetailsSection";
import { OrderIdContext } from "./contexts/OrderIdContext";
import { useOrder } from "./hooks";

interface MatchParams {
	orderId: string;
}

export const EOrderPage = (): JSX.Element => {
	const { orderId } = useParams<MatchParams>();
	const order = useOrder(orderId);

	if (!order) {
		return <LoadingContainer />;
	}

	return (
		<div className="eorder" data-testid="e-order-container">
			<OrderIdContext.Provider value={{ orderId }}>
				<main>
					<CompanyInfoSection company={order.company} />
					<OrderDetailsSection details={order} />
					<OrderActionsSection paymentStatus={order.payment_status} orderNumber={order.number} />
				</main>
				<Footer company={order.company} />
			</OrderIdContext.Provider>
		</div>
	);
};
