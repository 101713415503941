import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationLogsEmployeeActivitiesIndexPage from "./pages/Index";

const OrganizationLogsEmployeeActivitiesPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route exact={true} path={props.match.url} component={OrganizationLogsEmployeeActivitiesIndexPage} />
		</Switch>
	);
};

export default OrganizationLogsEmployeeActivitiesPage;
