export enum DeletionType {
	SALE = "SALE",
	VENUE = "VENUE",
	CUSTOM = "CUSTOM",
	PERMANENT = "PERMANENT",
	UNSET = "",
}

export interface DateRange {
	date_from?: Date;
	date_to?: Date;
}
