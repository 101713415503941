import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";

const SubmenuSettingsNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const isAdmin = useSelector(selectUser).admin;
	const settingsPermission = useSelector(hasPermission("SETTINGS"));
	const { t } = useTranslation();

	return (
		<div className="content-navigation">
			{settingsPermission && (
				<Nav>
					<Nav.Item>
						<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/basic`}>
							{t("modules.setting.field.organization_info.title")}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/company`}>
							{t("modules.setting.field.company_info.title")}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/pos_access`}>
							{t("modules.setting.field.pos_access.title")}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/receipt`}>
							{t("modules.setting.field.receipt_template.title")}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/invoice_details`}>
							{t("modules.setting.field.invoice_settings.title")}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/venue_settings`}>
							{t("modules.setting.field.point_of_sale.title")}
						</Nav.Link>
					</Nav.Item>
					{isAdmin && (
						<Nav.Item>
							<Nav.Link as={NavLink} to={`/${organization.id}/settings/info/clear_venue`}>
								{t("modules.setting.field.clear_venue.title")}
							</Nav.Link>
						</Nav.Item>
					)}
				</Nav>
			)}
		</div>
	);
};

export default SubmenuSettingsNavigation;
