import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CarryOutSVG } from "../../../images/svg/bills/carry_out.svg";
import { ReactComponent as DeliverySVG } from "../../../images/svg/bills/delivery.svg";
import { ReactComponent as DineInSVG } from "../../../images/svg/bills/dine_in.svg";

interface Props {
	type: string;
}

const RenderOrderType: FC<Props> = ({ type }) => {
	const { t } = useTranslation();
	switch (type) {
		case "PICK_UP":
			return (
				<>
					<CarryOutSVG /> {t("enums.orders.types.PICK_UP")}
				</>
			);
		case "ROOM_SERVICE":
			return (
				<>
					<DineInSVG /> {t("enums.orders.types.ROOM_SERVICE")}
				</>
			);
		case "DINE_IN":
			return (
				<>
					<DineInSVG /> {t("enums.orders.types.DINE_IN")}
				</>
			);
		case "DELIVERY":
			return (
				<>
					<DeliverySVG /> {t("enums.orders.types.DELIVERY")}
				</>
			);
	}
	return <>{t("common.word.unknown")}</>;
};
export default RenderOrderType;
