import React, { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { InconsistentReferenceTitle } from "../../../../../../../../../components/Common/InconsistentReferenceTooltip/InconsistentReference";
import { ReactComponent as InvoiceSVG } from "../../../../../../../../../images/svg/bills/invoice.svg";
import { OrderInvoiceApi } from "../../../../../../../../../services/Api/Organization/types";
import { exportInvoicePdf, printInvoicePdf } from "../../../../Invoices/services/exportInvoice";

export interface Props {
	invoice: OrderInvoiceApi;
	inconsistent_reference_id?: boolean;
}

const InvoiceCard: FC<Props> = ({ invoice, inconsistent_reference_id }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	return (
		<Card>
			<Card.Body>
				<InconsistentReferenceTitle
					titleText={t(`common.word.invoice`)}
					inconsistentReferenceId={inconsistent_reference_id}
				/>
				<div className="card-body-item">
					<InvoiceSVG />
					<div className="item-info">
						<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/invoices/${invoice.id}`}>
							{invoice.number}
						</Link>
					</div>
				</div>
				<div className={"d-flex justify-content-center"} style={{ marginLeft: "-8px", marginRight: "-8px" }}>
					<div className={"col-md-6"}>
						<Button variant={"add btn-block"} onClick={() => exportInvoicePdf(invoice, t)}>
							{t("modules.order.action.download_invoice.title")}
						</Button>
					</div>
					<div className={"col-md-6"}>
						<Button variant={"add btn-block"} onClick={() => printInvoicePdf(invoice)}>
							{t("modules.order.action.print_invoice.title")}
						</Button>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};
export default InvoiceCard;
