import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { ReactComponent as AlertSVG } from "../../../../../../../../../images/svg/posReport/danger-circle.svg";
import { ReactComponent as LinkSVG } from "../../../../../../../../../images/svg/posReport/link.svg";
import {
	FiscalPrinterReportApi,
	PaymentTerminalReportApi,
} from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	report: FiscalPrinterReportApi | PaymentTerminalReportApi;
	type: "FISCAL" | "PAYMENT_TERMINAL";
}

const ReportCard: FC<Props> = ({ report, type }) => {
	const { t } = useTranslation();
	const isFiscal = type === "FISCAL";

	return (
		<Card className="report-card">
			<Card.Body>
				<h5>
					{isFiscal
						? t("modules.pos_report.field.fiscal_printer.title")
						: t("modules.pos_report.field.payment_terminal.title")}
				</h5>
				{report?.mismatch && (
					<div className="card-body-item">
						<AlertSVG />
						<div className="item-info">
							<span className="text-danger label font-weight-normal">
								{t("modules.pos_report.field.report_card_alert.title")}
							</span>
						</div>
						<a
							href="https://help.gopos.pl/pl/articles/6460446-rozliczanie-i-analiza-raportu-pos"
							target="_blank"
							rel="noreferrer"
							className="ms-2"
						>
							<LinkSVG className="mt-n2" />
						</a>
					</div>
				)}
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.fiscal_report_count.title")}</span>
						<span>{report.fiscal_report_count}</span>
					</div>
				</div>
				<div className="card-body-item">
					<div className="item-info">
						<span className="label">{t("modules.pos_report.field.order_value.title")}</span>
						<span>{FormatMoney(report.order_amount)}</span>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default ReportCard;
