import { FC } from "react";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../../../../images/svg/rooms/remove.svg";

interface Props {
	handleRemove: () => void;
	styles?: Record<string, any>;
}

const RemoveHandler: FC<Props> = ({ handleRemove, styles }) => {
	return (
		<div className={"room-item-handler remove"} style={styles} onClick={handleRemove}>
			<RemoveSVG />
		</div>
	);
};
export default RemoveHandler;
