import { FieldValues, Path } from "react-hook-form";

export interface CustomValidationError<FormType extends FieldValues> {
	name: Path<FormType>;
	options: { types: Record<string, any>; type: string };
}

export type CustomValidationConfig<InputUtilsType = void> = InputUtilsType extends void
	? {
			types: CustomValidationType[];
			setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
	  }
	: {
			types: CustomValidationType[];
			setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<any>[]>>;
			utils: InputUtilsType;
	  };

export enum CustomValidationConstraint {
	REQUIRED = "required",
	REQUIRED_IF = "required_if",
	GREATER_THAN = "greater_than",
	GREATER_THAN_IF_REQUIRED = "greater_than_if_required",
}

type BaseConstraintOptions = {
	customErrorMessage?: string;
};

type Required = {
	constraint: CustomValidationConstraint.REQUIRED;
};

type RequiredIf = {
	constraint: CustomValidationConstraint.REQUIRED_IF;
	isRequired: () => boolean;
};

type GreaterThan = {
	constraint: CustomValidationConstraint.GREATER_THAN;
	minValue: number;
};

type GreaterThanIfRequired = {
	constraint: CustomValidationConstraint.GREATER_THAN_IF_REQUIRED;
	minValue: number;
	isRequired: () => boolean;
};

export type CustomValidationType = (Required | RequiredIf | GreaterThan | GreaterThanIfRequired) &
	BaseConstraintOptions;

export type FormCustomValidationConfigs = { [fieldName: string]: CustomValidationConfig };
