import React, { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { parseApplicationSettings, parseApplicationSettingsValues, parseStringToList } from "../../../utils/utils";
import { AppState } from "../AppModal";
import MultiLocationFormSettings from "./MultiLocationFormSettings";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const MultiLocationFormModalCard: FC<Props> = ({ appState, handleUpdateApp }) => {
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings),
				active_price_list: parseStringToList(
					appState.app.settings,
					appState.config || {},
					"active_price_list",
					"active_menu_price_lists"
				),
			},
		},
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		formState,
		setError,
		watch,
	} = form;
	const [loading, setLoading] = useState(false);

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};

		try {
			const res = await api.organization().updateApp(newData, params);
			handleUpdateApp(res);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			reset(watch());
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});
	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} onSubmit={onSubmit} className={"app-form"}>
					<FormInput label={t("lib:common.word.name")} register={register} name="name" errors={errors} />
					<div className={"app-advanced"}>
						<strong>{t("common.word.advanced", { ns: "lib" })}</strong>
					</div>
					<MultiLocationFormSettings form={form} appState={appState} />
					<ButtonLoading loading={loading} variant={"add"} onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};
export default MultiLocationFormModalCard;
