import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EntityStatus from "go-list/core/components/Table/components/EntityStatus";
import DashboardAlertItem from "./DashboardAlertItem";
import { DashboardAlert } from "./DashboardAlerts";

interface Props {
	isShown: boolean;
	onHide: () => void;
	getAlertsStatus: () => "ENABLED" | "DISABLED" | "WARNING";
	alerts: DashboardAlert[];
}

const DashboardAlertsModal: FC<Props> = ({ isShown, onHide, getAlertsStatus, alerts }) => {
	const { t } = useTranslation();

	return (
		<Modal show={isShown} onHide={onHide} className="dashboard-alerts-modal" size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					{t("modules.dashboard.header.dashboard_alerts_modal.title")}
					<EntityStatus status={getAlertsStatus()} />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					{alerts.length > 0 &&
						alerts.map((alert, index) => (
							<DashboardAlertItem
								key={`alert-modal-${index}`}
								status={alert.status}
								title={alert.title}
								link={alert.link}
								isModalItem
							/>
						))}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DashboardAlertsModal;
