import { FC } from "react";
import { ReactComponent as RotateSVG } from "../../../../../../../../../../../../images/svg/rooms/rotate.svg";

interface Props {
	handleRotate: () => void;
	styles?: Record<string, any>;
}

const RotateHandler: FC<Props> = ({ handleRotate, styles }) => {
	return (
		<div className={"room-item-handler rotate"} style={styles} onClick={handleRotate}>
			<RotateSVG />
		</div>
	);
};
export default RotateHandler;
