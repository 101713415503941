import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { ReactComponent as CircleInfoSVG } from "go-core/images/svg/circle-info.svg";
import { ReactComponent as GoPOSLogoSVG } from "go-core/images/svg/gopos-logo.svg";
import { OrganizationCard } from "../../services/types";

interface Props {
	organization: OrganizationCard;
	onAccept: (invitationId: string) => void;
	onReject: (invitationId: string) => void;
	id: string;
}

const ListCardOrganizationInvitation = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const organization = props.organization;

	const getAddressObj = (organization: OrganizationCard) => ({
		street: organization?.place?.street ?? "",
		city: organization?.place?.city ?? "",
		build_nr: organization?.place?.build_nr ?? "",
		country: organization?.place?.country ?? "",
		flat_nr: organization?.place?.flat_nr ?? "",
		zip_code: organization?.place?.zip_code ?? "",
	});

	const handleReject = async (invitationId: string) => {
		setLoading(true);
		await props.onReject(invitationId);
		setLoading(false);
	};
	const handleAccept = async (invitationId: string) => {
		setLoading(true);
		await props.onAccept(invitationId);
		setLoading(false);
	};

	return (
		<>
			<div key={organization.company_id} className="col mb-3">
				<Card className="h-100 card-organization">
					<Card.Header>
						<div className="avatar">
							<GoPOSLogoSVG />
						</div>
						<div className="card-header-title">
							<Card.Title>{organization.name}</Card.Title>
							<Card.Subtitle>
								<div className="d-flex flex-column text-truncate">
									<span className="company-id text-truncate">
										ID: <span className="text-primary">{organization.company_id}</span>
									</span>
									<span className="text-truncate">
										<FormatAddress address={getAddressObj(organization)} />
									</span>
								</div>
							</Card.Subtitle>
						</div>
					</Card.Header>
					<Card.Body className="position-relative bg-light">
						<div className="d-flex flex-column align-items-center py-4">
							<CircleInfoSVG className="circle-info-svg" />
							<h6 className="mt-2 text-center">
								{t("lib:go_security.invitation.message", { name: organization.name })}
							</h6>
							<div className="actions mt-2">
								<ButtonLoading
									size="sm"
									className="me-2"
									loading={loading}
									variant="outline-light"
									type="button"
									onClick={() => handleReject(props.id)}
								>
									{t("lib:common.action.reject")}
								</ButtonLoading>
								<ButtonLoading
									size="sm"
									loading={loading}
									variant="primary"
									type="button"
									onClick={() => handleAccept(props.id)}
								>
									{t("lib:common.action.accept")}
								</ButtonLoading>
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};
export default ListCardOrganizationInvitation;
