import React, { FC } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import ComponentProfileNavigation from "./components/ProfileNavigation";
import SecurityProfileChangePasswordPage from "./pages/ChangePassword";
import SecurityProfileDetailsPage from "./pages/Details";
import SecurityProfileRemoveAccountPage from "./pages/RemoveAccount";

const SecurityProfilePage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="page-container">
			<ComponentProfileNavigation />
			<Switch>
				<Route path={`${props.match.url}/details`} component={SecurityProfileDetailsPage} />
				<Route path={`${props.match.url}/change_password`} component={SecurityProfileChangePasswordPage} />
				<Route path={`${props.match.url}/remove_account`} component={SecurityProfileRemoveAccountPage} />
				<Redirect from={props.match.url} exact to={`${props.match.url}/details`} />
			</Switch>
		</div>
	);
};

export default SecurityProfilePage;
