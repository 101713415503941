import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import {
	LiveOrderApi,
	OrderPreparationStatusName,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import PreparationStatusModalOptions from "./PreparationStatusModalOptions";

interface Props {
	status: OrderPreparationStatusName;
	order: LiveOrderApi;
	setOrder: (order: LiveOrderApi) => void;
	show: boolean;
	onHide: () => void;
	setLoading: (loading: boolean) => void;
}

const PreparationStatusModal = ({ show, onHide, status, setLoading, setOrder, order }: Props): JSX.Element => {
	const { t } = useTranslation();
	const size = useWindowSize();

	return (
		<Modal show={show} onHide={onHide} className="preparation-status-modal" size={size.isMobile ? "sm" : "lg"}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.live_order.field.change_order_status.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PreparationStatusModalOptions
					status={status}
					setLoading={setLoading}
					setOrder={setOrder}
					order={order}
					handleSuccessAction={onHide}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default PreparationStatusModal;
