import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { GoListSegmentType } from "../../../../../../list/services/types";
import { ListConfigField } from "../../../../../services/types";
import { useColumnsList, useNewColumnsAutoSelect, useSegmentsColumns } from "../../../../Column/hooks";
import { listDividerId } from "../../../../Column/services/consts";
import { ListConfigColumn } from "../../../../Column/services/types";
import { listColumnSort } from "../../../../Column/utils";
import MobileFiltersColumnsButton from "./MobileFiltersColumnsButton";

interface Props {
	columnsFields?: ListConfigColumn[];
	customFields?: ListConfigField[];
	selectedColumns: string[];
	segment?: GoListSegmentType;
	shouldDisableSortingOfStickyColumns?: boolean;
	numberOfActiveStickyColumns: number;
	setNumberOfActiveStickyColumns: React.Dispatch<React.SetStateAction<number>>;
	isNumberOfStickyColumnsDynamic?: boolean;
	stickyColumnsDividerPosition: React.MutableRefObject<number>;
	allColumnsInOrder: React.MutableRefObject<ListConfigColumn[]>;
	setStickyColumnsDividerPosition: (dividerPosition: number) => void;
	setInternalSelectedColumns: (columns: string[]) => void;
	setAllInternalColumnsInOrder: (columns: string[]) => void;
}

const MobileFiltersColumns = ({
	columnsFields,
	customFields,
	selectedColumns,
	segment,
	shouldDisableSortingOfStickyColumns,
	numberOfActiveStickyColumns,
	setNumberOfActiveStickyColumns,
	isNumberOfStickyColumnsDynamic,
	stickyColumnsDividerPosition,
	allColumnsInOrder,
	setStickyColumnsDividerPosition,
	setInternalSelectedColumns,
	setAllInternalColumnsInOrder,
}: Props): JSX.Element => {
	const { t } = useTranslation();

	const onChange = useCallback((selectedColumns: string[], sortedColumns: string[]) => {
		const sortedSelectedColumns = sortedColumns.filter((column) => selectedColumns.includes(column));
		setInternalSelectedColumns(sortedSelectedColumns);
	}, []);

	const onChangeColumnsOrder = useCallback((newAllColumnsInOrder: string[]) => {
		setAllInternalColumnsInOrder(newAllColumnsInOrder);
	}, []);

	const onChangeStickyColumnsDividerPosition = useCallback((newPosition: number) => {
		setStickyColumnsDividerPosition(newPosition);
	}, []);

	const updateAndReturnNewSortedColumns = (columnsList: ListConfigColumn[]) => {
		if (shouldDisableSortingOfStickyColumns) {
			const newColumns = [
				...new Map(
					[...allColumnsInOrder.current.slice(0, stickyColumnsDividerPosition.current), ...columnsList]
						.filter(Boolean)
						.map((item) => [item.id, item])
				).values(),
			];
			updateSortedNonStickyColumns(columnsList);

			return newColumns;
		}

		if (!isNumberOfStickyColumnsDynamic) {
			updateSortedStickyColumns(columnsList.slice(0, stickyColumnsDividerPosition.current));
			updateSortedNonStickyColumns(columnsList.slice(stickyColumnsDividerPosition.current));

			return columnsList;
		}

		const indexOfDivider = columnsList.findIndex((column) => column.id === listDividerId);
		const stickyColumns = columnsList.slice(0, indexOfDivider);
		const nonStickyColumns = columnsList.slice(indexOfDivider + 1);

		updateSortedStickyColumns(stickyColumns);
		updateSortedNonStickyColumns(nonStickyColumns);
		stickyColumnsDividerPosition.current = stickyColumns.length;

		return [...stickyColumns, ...nonStickyColumns];
	};

	const onSortedList = (list: ListConfigColumn[]) => {
		const newSortedColumns = updateAndReturnNewSortedColumns(list);

		onChange(
			listColumnSort(
				[...selectedColumns],
				newSortedColumns.map((column) => column.id)
			),
			newSortedColumns.map((column) => column.id)
		);
	};

	const onChangeSelected = (columnId: string) => {
		if (selectedColumns.includes(columnId)) {
			return onChange(
				selectedColumns.filter((column) => column !== columnId),
				allColumnsInOrder.current.map((column) => column.id)
			);
		}

		onChange(
			[...selectedColumns, columnId],
			allColumnsInOrder.current.map((column) => column.id)
		);
	};

	const {
		setNewSortedColumnsWithDivider,
		updateSortedStickyColumns,
		updateSortedNonStickyColumns,
		sortedColumnsToDisplayInTheDropdown,
	} = useColumnsList({
		columnsFields,
		numberOfActiveStickyColumns,
		setNumberOfActiveStickyColumns,
		isNumberOfStickyColumnsDynamic,
		shouldDisableSortingOfStickyColumns,
		allColumnsInOrder,
		onChangeColumnsOrder,
		onChangeStickyColumnsDividerPosition,
		stickyColumnsDividerPosition,
		selectedColumns,
		segment,
	});

	useSegmentsColumns({
		segment,
		setNewSortedColumnsWithDivider,
		customFields,
		selectedColumns,
		numberOfActiveStickyColumns,
		allColumns: allColumnsInOrder,
	});

	useNewColumnsAutoSelect({
		columnsFields,
		allColumnsInOrder,
		selectedColumns,
		onChange,
		segment,
	});

	return (
		<>
			<h5>{t("lib:common.word.columns")}</h5>
			<ReactSortable
				list={sortedColumnsToDisplayInTheDropdown}
				setList={onSortedList}
				className="d-flex flex-wrap gap-1"
				delay={200}
				delayOnTouchOnly
				forceFallback
			>
				{sortedColumnsToDisplayInTheDropdown.map((column: ListConfigColumn) => (
					<MobileFiltersColumnsButton
						key={column.id}
						column={column}
						selected={selectedColumns.includes(column.id)}
						onChangeSelected={onChangeSelected}
					/>
				))}
			</ReactSortable>
		</>
	);
};

export default MobileFiltersColumns;
