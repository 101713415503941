import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import OrganizationLiveOrdersMapIndexPage from "./pages/Index";

const OrganizationLiveOrdersMapPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route path={`${props.match.url}/`} component={OrganizationLiveOrdersMapIndexPage} />
			<Route component={Page404} />
		</Switch>
	);
};

export default OrganizationLiveOrdersMapPage;
