import React, { FC, useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";

interface Props {
	handleClose: () => void;
	handleSave: (baseString: string) => void;
	image: string | undefined;
	defaultImage: string | undefined;
}

const ImageCropper: FC<Props> = (props: Props): JSX.Element => {
	const [cropStart, setCropStart] = useState(false);
	const [image] = useState<string | undefined>(props.image ? props.image : props.defaultImage);
	const [cropper, setCropper] = useState<Cropper | undefined>();
	const [zoomTo, setZoomTo] = useState(0);
	const firstRender = useRef(true);

	const handleClickOutside = (evt: MouseEvent) => {
		if (cropStart) {
			evt.stopPropagation();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return (
		<Cropper
			style={{ marginBottom: "10px", height: "288px", width: "100%" }}
			zoomTo={zoomTo}
			zoom={(e: any) => {
				if (e?.detail?.ratio > 1) {
					setZoomTo(1);
					e.preventDefault();
				}
			}}
			src={image}
			viewMode={2}
			minCropBoxHeight={10}
			minCropBoxWidth={10}
			background={false}
			responsive={true}
			autoCropArea={1}
			checkOrientation={false}
			checkCrossOrigin
			onInitialized={(instance) => {
				setCropper(instance);
			}}
			cropstart={() => setCropStart(true)}
			cropend={(evt: any) => {
				setCropStart(false);

				if (evt?.target) {
					evt.target.crossOrigin = "Anonymous";
				}

				props.handleSave(evt.target?.cropper?.getCroppedCanvas().toDataURL());
			}}
			crop={(evt: any) => {
				const cropperData = evt.target?.cropper?.getCanvasData();
				const zoom = cropperData.width / cropperData.naturalWidth;
				if (firstRender.current && !isNaN(zoom)) {
					firstRender.current = false;
				}
				if (!isNaN(zoom)) {
					setZoomTo(zoom);
				}
			}}
			guides={true}
		/>
	);
};
export default ImageCropper;
