import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import EntityUsedErrorModal from "../../../../../../../../../../components/Common/EntityUsedErrorModal/EntityUsedErrorModal";
import { ItemGroupApi, TaxApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import TaxModalForm from "../../components/TaxModalForm";

interface ListState {
	resource?: Record<string, any>;
	updateTax: (item: TaxApi, items: TaxApi[]) => void;
	responseData?: TaxApi[] | undefined;
	createTax: () => void;
	mobileActions: MobileActionProps[];
}

const listName = "TAX";
const resourceType = "TAX";

interface RemoveErrorProps {
	ids: number[];
	errors: ApiError[];
}

const List: FC<ListState> = ({ resource, updateTax, responseData, createTax, mobileActions }) => {
	const { t } = useTranslation();
	const [errors, setErrors] = useState<RemoveErrorProps | undefined>(undefined);
	const [items, setItems] = useState<TaxApi[]>([]);
	const [taxToRemove, setTaxToRemove] = useState<TaxApi | undefined>(undefined);
	const [showTaxUsedErrorModal, setShowTaxUsedErrorModal] = useState(false);
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const [itemGroups, setItemGroups] = useState<ItemGroupApi[]>([]);
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		if (responseData) {
			setItems(responseData);
		}
	}, [responseData]);

	useEffect(() => {
		if (errors?.errors !== undefined && errors?.errors.filter((f) => f.code === "tax_is_used").length > 0)
			setShowTaxUsedErrorModal(true);
	}, [errors]);

	useEffect(() => {
		if (taxToRemove) fetchItemGroups();
	}, [taxToRemove]);

	if (!resource) return null;
	const data = resource.read();

	const fetchItemGroups = async () => {
		try {
			params.size = 10;
			params.f = `${btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}tax|e=${taxToRemove?.id}`)}`;
			const res = await api.organization().getItemGroups(params);
			setItemGroups(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemoveTax = async (item: TaxApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			setTaxToRemove(item);
			await api.organization().removeTax(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
			setErrors({ ids: items.map((x) => x.id), errors: handleException(e) });
		}
	};

	const onRestore = async (item: TaxApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreTax(item.id);
			setItems(items.filter((dataItem) => dataItem.id !== item.id));
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const drawItemGroups = () => {
		return (
			itemGroups &&
			itemGroups.map((item) => {
				return {
					name: item.name,
					link: `/${organization.id}/menu/item_groups/${item.id}`,
				};
			})
		);
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: TaxApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<RenderLimitedText minWidth={200} textStyleIsLink onClick={() => updateTax(item, items)}>
								{item.name}
							</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: TaxApi) => item.name,
			},
			{
				id: "amount",
				name: t("common.word.value"),
				type: "number" as FilterType,
			},
			{
				id: "code",
				name: t("modules.tax.field.code.title"),
				type: "text" as FilterType,
			} as ListConfigField,
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				click: (item: TaxApi) => {
					updateTax(item, items);
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: TaxApi) => {
					onRemoveTax(item);
				},
				visible: (item: TaxApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: TaxApi) => {
					onRestore(item);
				},
				visible: (item: TaxApi) => {
					return item.status === "DELETED";
				},
			},
		],
		selectedColumns: ["name", "code", "amount"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		exportConfig: {
			title: t("modules.tax.field.export_config.title"),
			filename: t("modules.tax.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(fetchParams);
			setErrors(undefined);
			return api.organization().getTaxes(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{
				<EntityUsedErrorModal
					onHide={() => {
						setErrors(undefined);
						setShowTaxUsedErrorModal(false);
						setTaxToRemove(undefined);
					}}
					isShown={showTaxUsedErrorModal}
					linkTo={`/${organization.id}/menu/item_groups?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}tax|e=${taxToRemove?.id}`
					)}`}
					title={t("constraints.tax_is_used")}
					linkTitle={t("modules.tax.action.check_products.title")}
					items={drawItemGroups()}
				/>
			}
			<ListData
				data={items}
				config={config}
				emptyList={{
					addAction: () => createTax(),
				}}
				onFetch={(fetchItems: TaxApi[]) => setItems(fetchItems)}
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationSettingsTaxIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const [tax, setTax] = useState<TaxApi | undefined>(undefined);
	const [responseData, setResponseData] = useState<TaxApi[] | undefined>(undefined);
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.tax.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const onUpdateTax = (newTax: TaxApi, items: TaxApi[]) => {
		setTax(newTax);
		setResponseData(items);
	};

	const handleSave = async (newTax: TaxApi) => {
		setTax(undefined);
		if (responseData) {
			const index = responseData?.findIndex((dataItem) => dataItem.id === newTax.id);
			if (index !== -1 && responseData) {
				responseData[index] = newTax;
				setResponseData([...responseData]);
			} else {
				setResponseData([...responseData, newTax]);
			}
		} else {
			const res = await api.organization().getTaxes();
			setResponseData(res);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setTax({} as TaxApi),
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			action: () => setTax({} as TaxApi),
		},
	];

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.tax.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					updateTax={onUpdateTax}
					responseData={responseData}
					createTax={() => setTax({} as TaxApi)}
					mobileActions={mobileActions}
				/>
			</Suspense>
			{tax && <TaxModalForm tax={tax} onHide={() => setTax(undefined)} handleSave={handleSave} />}
		</div>
	);
};
export default OrganizationSettingsTaxIndexPage;
