import { DashboardChartConfig } from "./types";

export enum DashboardChartActions {
	changeProductReports = "change_product_reports",
	changeCategoryReports = "change_category_reports",
	changeSalesReports = "change_sales_reports",
	changePaymentReports = "change_payment_reports",
	changeSalesAdvancedReports = "change_sales_advanced_reports",
}

export const chartsConfigReducer = (state: DashboardChartConfig, action: Record<string, any>): DashboardChartConfig => {
	switch (action.type) {
		case DashboardChartActions.changeProductReports:
			return {
				...state,
				reportProduct: { ...state.reportProduct, ...action.data },
			};
		case DashboardChartActions.changeSalesAdvancedReports:
			return {
				...state,
				reportSalesAdvanced: { ...state.reportSalesAdvanced, ...action.data },
			};
		case DashboardChartActions.changeCategoryReports:
			return {
				...state,
				reportCategory: { ...state.reportCategory, ...action.data },
			};
		case DashboardChartActions.changeSalesReports:
			return {
				...state,
				reportSales: { ...state.reportSales, ...action.data },
			};
		case DashboardChartActions.changePaymentReports:
			return {
				...state,
				reportPayment: { ...state.reportPayment, ...action.data },
			};
		default:
			return state;
	}
};
