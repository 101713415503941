import React, { FC } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExportAction, ListParamsType } from "../../../list/services/types";

interface State {
	state: ListParamsType;
	onCsvExport: () => void;
	onPdfExport: () => void;
	customExportActions?: ExportAction[];
}

const ListActions: FC<State> = (props) => {
	const { t } = useTranslation();
	const { customExportActions, state } = props;

	const onCsvExport = () => {
		props.onCsvExport();
	};

	const onPdfExport = () => {
		props.onPdfExport();
	};
	return (
		<>
			<Dropdown as={ButtonGroup}>
				<Dropdown.Toggle variant="light">{t("lib:common.action.export")}</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={() => onCsvExport()}>{t("lib:common.action.export_csv")}</Dropdown.Item>
					<Dropdown.Item onClick={() => onPdfExport()}>{t("lib:common.action.export_pdf")}</Dropdown.Item>
					{customExportActions &&
						customExportActions.map((action, index) => (
							<Dropdown.Item onClick={() => action.onClick(state)} key={`export-action-${index}`}>
								{action.title}
							</Dropdown.Item>
						))}
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};
export default ListActions;
