import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { FormInput, registerObject } from "go-form";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../images/svg/sortable-handle.svg";
import {
	InvoiceApi,
	InvoiceItemApi,
	InvoicePaymentApi,
	InvoiceSummaryTaxApi,
	LinkedInvoiceApi,
} from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { getRecalculatedSummaryTaxes } from "../../utils";
import OldCorrectionItemsFormTable from "./OldCorrectionItemsFormTable";

interface Props {
	form: UseFormReturn<InvoiceApi>;
	setSummaryTaxes: (value: InvoiceSummaryTaxApi[]) => void;
	summaryTaxes: InvoiceSummaryTaxApi[];
	oldItems?: InvoiceItemApi[];
	oldInvoice?: LinkedInvoiceApi;
	invoicePayments?: InvoicePaymentApi[];
}

const InvoiceFormItemsTable = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const isSortingRef = useRef(false);

	const searchTaxes = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getTaxesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};
	const {
		formState: { errors },
		control,
		register,
		clearErrors,
		watch,
		getValues,
		setValue,
	} = props.form;
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const watchedItems = watch("items");
	const itemsAsString = JSON.stringify(watchedItems);
	const optionsRef = useRef<any[]>([]);
	const { fields, remove, append, replace } = useFieldArray({
		control,
		name: "items",
		keyName: "key",
	});
	const [showDiscounts, setShowDiscounts] = useState(!!getValues("items").find((item) => item.sub_unit_price));
	const isMobile = useWindowSize().isMobile;

	const updatePositions = (items: InvoiceItemApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items.forEach((item, index) => {
			item.position = index;
		});
		replace(items);
	};

	const recalculateSummaryTaxes = (
		items: InvoiceItemApi[],
		type?: string,
		index?: number,
		val?: number,
		quantity?: string
	) => {
		if (index || index === 0) {
			clearErrors(`items.${index}.price`);
		}
		const newTaxes = getRecalculatedSummaryTaxes(items, currency, type, index, val, quantity);
		props.setSummaryTaxes(newTaxes);
	};

	useEffect(() => {
		fields.forEach((field, index) => {
			registerObject(register, `items.${index}.tax`, ["amount", "name", "code", "id"]);
			setValue(`items.${index}.position`, index);
			setValue(`items.${index}.tax`, field.tax);
		});
		recalculateSummaryTaxes(watchedItems, watch("type"));
	}, []);

	useEffect(() => {
		fields.forEach((field, index) => {
			registerObject(register, `items.${index}.tax`, ["amount", "name", "code", "id"]);
			setValue(`items.${index}.position`, index);
			setValue(`items.${index}.tax`, field.tax);
			setValue(`items.${index}.tax_id`, field.tax?.id);
			// setValue(`items.${index}.gtu`, field.gtu);
		});
		recalculateSummaryTaxes(watchedItems, watch("type"));
	}, [fields]);

	const calculateSummary = (quantity: number, money: Record<string, any>) => {
		if (money && money.amount) {
			const newAmount = quantity * parseFloat(money.amount);
			return newAmount.toFixed(2);
		}
		return `0.00`;
	};

	const wasLastItemUpdated = (items: InvoiceItemApi[]) => {
		const lastItem = items[items.length - 1];
		return !!(
			lastItem.gtu ||
			lastItem.name ||
			lastItem.pkwiu ||
			lastItem.price?.amount ||
			lastItem.quantity ||
			lastItem.tax?.id ||
			lastItem.volume
		);
	};

	useEffect(() => {
		if (wasLastItemUpdated(watchedItems)) {
			append({ position: fields.length });
		}
	}, [itemsAsString]);

	const onChangeType = (type: string) => {
		if (watch("type") !== type) {
			setValue("type", type, { shouldDirty: true });
			recalculateSummaryTaxes(watchedItems, type);
		}
	};

	const onChangeTax = (obj: any, obj2: any, index: number) => {
		registerObject(register, `items.${index}.tax`, ["amount", "name", "code", "id"]);
		setValue(`items.${index}.tax`, obj2);
		setValue(`items.${index}.tax_id`, obj, { shouldDirty: true });
		recalculateSummaryTaxes(watch().items, watch("type"));
	};

	const onRemove = (index: number) => {
		remove(index);
	};

	const nestedCellStyles = {
		borderTop: "none",
		verticalAlign: "top",
		paddingTop: 0,
		paddingBottom: "20px",
	};

	const getGtus = async (search: string, params: Record<string, any>) => {
		const res = await api.organization().getInvoiceGtu(search, params);
		return res.map((item: string) => ({
			id: item,
			label: item,
		}));
	};

	const searchItems = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getItemsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const parseOptionValue = (option: any) => {
		optionsRef.current = optionsRef.current.find((o) => o.id === option.id)
			? [...optionsRef.current]
			: [...optionsRef.current, option];
		return option.label;
	};

	const getItemIdForItem = (index: number) => {
		const name = getValues(`items.${index}.name`);
		const option = optionsRef.current.find((o) => o.label === name);
		if (option) {
			return option.id;
		}
		return getValues(`items.${index}.item_id`);
	};

	const updateItemId = (index: number) => {
		const name = getValues(`items.${index}.name`);
		const option = optionsRef.current.find((o) => o.label === name);

		if (!option) {
			const currentPriceAmount = watch(`items.${index}.price.amount`);
			const currentTax = watch(`items.${index}.tax`);

			setValue(`items.${index}.item_id`, undefined);
			setValue(`items.${index}.price.amount`, currentPriceAmount);
			onChangeTax(currentTax?.id, currentTax, index);
			setValue(`items.${index}.name`, name);
		} else {
			setValue(`items.${index}.item_id`, getItemIdForItem(index));
			setValue(`items.${index}.gtu`, option?.gtu);
			setValue(`items.${index}.price.amount`, option?.price?.amount);
			onChangeTax(option?.tax?.id, option?.tax, index);
		}
	};

	const taxesSummary = props.summaryTaxes.reduce(
		(acc, val) => acc + (val.price_tax?.amount ? val.price_tax?.amount : 0),
		0
	);

	const grossSummary = props.summaryTaxes.reduce(
		(acc, val) => acc + (val.price_gross?.amount ? val.price_gross?.amount : 0),
		0
	);

	const netSummary = props.summaryTaxes.reduce(
		(acc, val) => acc + (val.price_net?.amount ? val.price_net?.amount : 0),
		0
	);

	const paidMoney = getValues("payments")
		.filter((f) => f.paid)
		.reduce((acc, val) => acc + (val.price_paid?.amount ? val.price_paid?.amount : 0), 0);
	const unPaidMoney = grossSummary > 0 ? grossSummary - paidMoney : 0;

	return (
		<fieldset className="form-group">
			{props.oldItems && (
				<OldCorrectionItemsFormTable correction={getValues() as InvoiceApi} items={props.oldItems} />
			)}
			<div className={"d-flex align-items-center mb-1 flex-wrap"}>
				<h5>
					{watch("document_type") === "CORRECTION" && t("modules.invoice.field.correction_positions.title")}
					{watch("document_type") === "INVOICE" && t("modules.invoice.field.invoice_positions.title")}
					{watch("document_type") === "PROFORMA" && t("modules.invoice.field.proforma_positions.title")}
					{watch("document_type") === "ADVANCE" && t("modules.invoice.field.positions.title")}
				</h5>
				<div className="d-flex align-items-center ms-0 ms-sm-auto">
					<Form.Check
						type="switch"
						id="invoice-discount"
						className="form-check me-3 show-discounts-check"
						label={t("modules.invoice.action.show_discounts.title")}
						checked={showDiscounts}
						onChange={() => setShowDiscounts(!showDiscounts)}
					/>
					{watch("document_type") === "CORRECTION" ? (
						<FormInput
							errors={errors}
							type={"hidden"}
							register={register}
							name={"type"}
							value={getValues().type}
						/>
					) : (
						<Controller
							name="type"
							control={control}
							defaultValue={getValues().type}
							render={() => {
								return (
									<ButtonGroup className="ms-auto">
										<Button
											onClick={() => onChangeType("NET")}
											variant={watch("type") === "NET" ? "primary" : "outline-primary"}
										>
											{t(`enums.invoices.type.NET`)}
										</Button>
										<Button
											onClick={() => onChangeType("GROSS")}
											variant={watch("type") === "GROSS" ? "primary" : "outline-primary"}
										>
											{t(`enums.invoices.type.GROSS`)}
										</Button>
									</ButtonGroup>
								);
							}}
						/>
					)}
				</div>
			</div>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className={"table table-form invoice-items"}>
					<thead>
						<tr>
							<th className="action" />
							<th style={{ minWidth: isMobile ? "200px" : "unset" }} className={"w-40"}>
								{t("modules.invoice.field.name.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "unset" }} className={"w-10"}>
								{t("modules.invoice.field.pkwiu.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "unset" }} className={"w-10"}>
								{t("modules.invoice.field.gtu.title")}
							</th>
							{showDiscounts && (
								<th style={{ minWidth: isMobile ? "120px" : "9%" }}>
									{`${
										getValues().type === "GROSS"
											? t("modules.invoice.field.unit_price_gross.title")
											: t("modules.invoice.field.unit_price_net.title")
									} ${t("modules.invoice.field.before_discount.title")}`}
								</th>
							)}
							<th style={{ minWidth: isMobile ? "120px" : "9%" }}>
								{getValues().type === "GROSS"
									? t("modules.invoice.field.unit_price_gross.title")
									: t("modules.invoice.field.unit_price_net.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "10%" }}>
								{t("modules.invoice.field.vat_rate.title")} <br />
								{t("modules.invoice.field.vat.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "7%" }}>{t("common.word.quantity")}</th>
							<th style={{ minWidth: isMobile ? "100px" : "unset" }}>
								{t("modules.invoice.field.measure.title")}
							</th>
							<th style={{ textAlign: "right", minWidth: isMobile ? "120px" : "unset" }}>
								{getValues().type === "GROSS"
									? t("modules.invoice.field.total_price_gross.title")
									: t("modules.invoice.field.total_price_net.title")}
							</th>
							<th className="action" />
						</tr>
					</thead>
					{
						<ReactSortable
							handle=".sortable-handler"
							tag="tbody"
							list={watchedItems}
							onUpdate={() => (isSortingRef.current = true)}
							setList={(fields) => updatePositions(fields)}
						>
							{watchedItems.map((field, index) => {
								return (
									<tr key={fields[index].key}>
										<td className="action">
											<SortableHandleSvg className="sortable-handler" />
											<FormInput
												errors={errors}
												type="hidden"
												register={register}
												name={`items.${index}.position`}
												defaultValue={index}
											/>
											<FormInput
												type={"hidden"}
												errors={errors}
												register={register}
												name={`items.${index}.id`}
												defaultValue={field.id}
											/>
										</td>
										<td>
											<FormSelectGroup
												name={`items.${index}.name`}
												errors={errors}
												forceCreateOption
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => parseOptionValue(option)}
												onChange={() => updateItemId(index)}
												defaultValue={{
													label: field?.name,
												}}
												loadOptions={searchItems}
												control={control}
												data-testid={`items.${index}.name`}
												onBlurShouldForceChangeOption
												forceInputChange
											/>
											<FormInput
												type={"hidden"}
												errors={errors}
												register={register}
												name={`items.${index}.item_id`}
												defaultValue={getItemIdForItem(index)}
											/>
										</td>
										<td>
											<FormInput
												register={register}
												defaultValue={fields[index].pkwiu}
												name={`items.${index}.pkwiu`}
												errors={errors}
											/>
										</td>
										<td>
											<FormSelectGroup
												errors={errors}
												name={`items.${index}.gtu`}
												isClearable={true}
												control={control}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
												defaultValue={{ id: field.gtu, label: field.gtu }}
												loadOptions={getGtus}
												data-testid={`items.${index}.gtu`}
											/>
										</td>
										{showDiscounts && (
											<td>
												<FormMoneyInput
													errors={errors}
													name={`items.${index}.sub_unit_price.amount`}
													currency={currency}
													control={control}
												/>
											</td>
										)}
										<td>
											<FormMoneyInput
												onChange={(val) =>
													recalculateSummaryTaxes(watchedItems, watch("type"), index, val)
												}
												errors={errors}
												name={`items.${index}.price.amount`}
												currency={currency}
												control={control}
											/>
										</td>
										<td>
											<FormSelectGroup
												name={`items.${index}.tax_id`}
												errors={errors}
												onChange={(obj, obj2) => onChangeTax(obj, obj2, index)}
												getOptionLabel={(option) => option.label}
												isClearable={false}
												getOptionValue={(option) => option.id}
												defaultValue={{
													id: field?.tax?.id,
													label: field?.tax?.name,
												}}
												loadOptions={searchTaxes}
												control={control}
												data-testid={`items.${index}.tax_id`}
											/>
										</td>
										<td>
											<FormNumberInput
												suffix=""
												onChange={(val) =>
													recalculateSummaryTaxes(
														watchedItems,
														watch("type"),
														index,
														parseFloat(`${val}`),
														"quantity"
													)
												}
												control={control}
												defaultValue={Number(fields[index].quantity)}
												name={`items.${index}.quantity`}
												errors={errors}
											/>
										</td>
										<td>
											<FormInput
												register={register}
												defaultValue={fields[index].volume}
												name={`items.${index}.volume`}
												errors={errors}
											/>
										</td>
										<td className={"nowrap"} style={{ textAlign: "right" }}>
											{calculateSummary(field.quantity || 0, field.price)}
										</td>
										<td className="action">
											{!(fields.length === 1 && index === 0) && (
												<RemoveSVG className={"icon"} onClick={() => onRemove(index)} />
											)}
										</td>
									</tr>
								);
							})}
						</ReactSortable>
					}
				</table>
			</div>
			<Button variant="add" type="button" onClick={() => append({ position: fields.length })}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className={"table table-form invoice-items-summary"}>
					<thead>
						<tr>
							<th className={"action"} />
							<th className={"w-60 summary-header"}>{t("common.word.summary", { ns: "lib" })}</th>
							<th style={{ width: "9%" }}>
								{t("common.word.value")} <br /> {t("modules.invoice.field.net.title")}
							</th>
							<th style={{ width: "10%" }}>
								{t("modules.invoice.field.vat_rate.title")} <br />{" "}
								{t("modules.invoice.field.vat.title")}
							</th>
							<th>
								{t("common.word.amount")} <br /> {t("modules.invoice.field.vat.title")}
							</th>
							<th />
							<th style={{ textAlign: "right", paddingRight: 0 }}>
								{t("common.word.value")} <br /> {t("modules.invoice.field.gross.title")}
							</th>
							<th className={"action"} />
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={"action pb-0"} />
							<td style={{ verticalAlign: "top" }} className={"pl-0"}>
								{t("modules.invoice.field.summary_taxes.title")}
							</td>
							<td className={"nowrap pb-0"}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{FormatMoney({ ...tax.price_net, currency })}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}>{FormatMoney({} as MoneyApi)}</span>
									)}
								</div>
							</td>
							<td className={"nowrap pb-0"}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{tax.tax_name ?? tax.tax_amount}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}></span>
									)}
								</div>
							</td>
							<td className={"nowrap pb-0"}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{FormatMoney({ ...tax.price_tax, currency })}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}>{FormatMoney({} as MoneyApi)}</span>
									)}
								</div>
							</td>
							<td />
							<td className={"nowrap pb-0"} style={{ textAlign: "right" }}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{FormatMoney({ ...tax.price_gross, currency })}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}>{FormatMoney({} as MoneyApi)}</span>
									)}
								</div>
							</td>
							<td className={"action"} />
						</tr>
						<tr>
							<td className={"action"} />
							<td style={{ verticalAlign: "top", paddingTop: 0, paddingLeft: 0, paddingBottom: "20px" }}>
								<strong>{t("common.word.altogether")}</strong>
							</td>
							<td className={"nowrap"} style={{ paddingTop: 0, paddingBottom: "20px" }}>
								<div className={"d-flex flex-column"}>
									<strong>{FormatMoney({ amount: netSummary, currency })}</strong>
								</div>
							</td>
							<td className={"nowrap"} style={{ paddingTop: 0, paddingBottom: "20px" }}></td>
							<td className={"nowrap"} style={{ paddingTop: 0, paddingBottom: "20px" }}>
								<div className={"d-flex flex-column"}>
									<strong>{FormatMoney({ amount: taxesSummary, currency })}</strong>
								</div>
							</td>
							<td />
							<td
								className={"nowrap"}
								style={{ textAlign: "right", paddingTop: 0, paddingBottom: "20px" }}
							>
								<div className={"d-flex flex-column"}>
									<strong>{FormatMoney({ amount: grossSummary, currency })}</strong>
								</div>
							</td>
							<td className={"action"} />
						</tr>
						<tr>
							<td className={"action"} />
							<td
								className={"border-top"}
								style={{ verticalAlign: "top", paddingTop: "20px", paddingLeft: 0 }}
							>
								{t("modules.invoice.field.to_pay.title")}
							</td>
							<td colSpan={4} className={"nowrap border-top"} style={{ paddingTop: "20px" }} />
							<td className={"nowrap border-top"} style={{ textAlign: "right", paddingTop: "20px" }}>
								{FormatMoney({
									amount: unPaidMoney,
									currency,
								})}
							</td>
							<td className={"action"} />
						</tr>
						{watch("document_type") === "CORRECTION" && (
							<tr>
								<td className={"action"} />
								<td className={"nowrap"} style={{ ...nestedCellStyles, paddingLeft: 0 }} colSpan={5}>
									<div className={"d-flex flex-column"}>
										<span>
											{(props.oldInvoice?.price_sum_net?.amount || 0) > (netSummary || 0)
												? t("modules.invoice.field.amount_decreasing_tax_base.title")
												: t("modules.invoice.field.amount_increasing_tax_base.title")}
										</span>
										<span>
											{(props.oldInvoice?.price_sum_tax?.amount || 0) > (taxesSummary || 0)
												? t("modules.invoice.field.amount_decreasing_tax_amount.title")
												: t("modules.invoice.field.amount_increasing_tax_amount.title")}
										</span>
										<span>
											{(props.oldInvoice?.price_sum_gross?.amount || 0) > (grossSummary || 0)
												? t("modules.invoice.field.to_return.title")
												: t("modules.invoice.field.to_pay2.title")}
										</span>
									</div>
								</td>
								<td
									className={"nowrap"}
									style={{ ...nestedCellStyles, textAlign: "right", paddingRight: 0 }}
								>
									<div className={"d-flex flex-column"}>
										<span>
											{FormatMoney({
												amount: Math.abs(
													(props.oldInvoice?.price_sum_net?.amount || 0) - (netSummary || 0)
												),
												currency,
											})}
										</span>
										<span>
											{FormatMoney({
												amount: Math.abs(
													(props.oldInvoice?.price_sum_tax?.amount || 0) - (taxesSummary || 0)
												),
												currency,
											})}
										</span>
										<span>
											{FormatMoney({
												amount: Math.abs(
													(props.oldInvoice?.price_sum_gross?.amount || 0) -
														(grossSummary || 0)
												),
												currency,
											})}
										</span>
									</div>
								</td>
								<td className={"action"} />
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</fieldset>
	);
};
export default InvoiceFormItemsTable;
