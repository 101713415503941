import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as AdminSVG } from "go-core/images/svg/navigation/admin.svg";
import { ReactComponent as LogsSVG } from "go-core/images/svg/navigation/logs.svg";
import { ReactComponent as RecommendationSVG } from "go-core/images/svg/navigation/recommendation.svg";
import { ReactComponent as SettingsSVG } from "go-core/images/svg/navigation/settings.svg";
import { ReactComponent as SignOutSVG } from "go-core/images/svg/navigation/sign-out.svg";
import { ReactComponent as StarSVG } from "go-core/images/svg/navigation/star.svg";
import { ReactComponent as SubscriptionsSVG } from "go-core/images/svg/navigation/subscription.svg";
import { redirectToApplication } from "go-core/utils/utils";
import { useAuth } from "../../../services/auth";
import { OrganizationState } from "../../../services/organizations/types";
import { UserState } from "../../../services/users/types";

interface Props {
	organization: OrganizationState;
	isAdmin: boolean;
	goaccountsUrl: string;
	baseUrl: string;
	user: UserState;
}

const NavigationBottomProfileSecurity = ({
	organization,
	isAdmin,
	goaccountsUrl,
	baseUrl,
	user,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const auth = useAuth(baseUrl);

	return (
		<div className="dropdown-menu-padding">
			<div className="navigation-profile-bottom">
				{organization.id && (
					<>
						<Dropdown.Item
							target={"_blank"}
							href={redirectToApplication(goaccountsUrl, organization.company_id, "subscriptions")}
						>
							<SubscriptionsSVG /> {t("lib:go_component.navigation.subscriptions")}
						</Dropdown.Item>
						<Dropdown.Item as={Link} to={`/${organization.id}/logs`}>
							<LogsSVG /> {t("lib:common.word.logs")}
						</Dropdown.Item>
					</>
				)}
				{isAdmin && (
					<Dropdown.Item href={`${goaccountsUrl}/admin`}>
						<AdminSVG /> {t("lib:go_component.navigation.admin")}
					</Dropdown.Item>
				)}
				{(isAdmin || organization.id) && <Dropdown.Divider />}
				{organization.company_id && user.id && (
					<Dropdown.Item
						className="no-hover"
						href={`https://gopos.pl/recommend-us?referral=${organization.company_id}&user_id=${user.id}&utm_content=gonow|gopos-backoffice`}
					>
						<RecommendationSVG /> {t("lib:go_component.navigation.recommend_us")}
					</Dropdown.Item>
				)}
				<Dropdown.Item
					className="no-hover"
					href={`https://help.gopos.pl/pl/collections/3779162-aktualizacje-aplikacji`}
				>
					<StarSVG /> {t("lib:go_component.navigation.news")}
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Header>{t("lib:go_component.navigation.my_profile")}</Dropdown.Header>
				<Dropdown.Item as={Link} to="/profile">
					<SettingsSVG /> {t("lib:go_component.navigation.settings")}
				</Dropdown.Item>
				<Dropdown.Item onClick={() => auth.logout(true)}>
					<SignOutSVG /> {t("lib:common.action.logout")}
				</Dropdown.Item>
			</div>
		</div>
	);
};

export default NavigationBottomProfileSecurity;
