import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PdfOrientationType } from "go-core/types";
import { FILTER_SEPARATOR, FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { WebhookContext } from "./services/context";
import { WebhookApi } from "./services/types";

interface ListState {
	resource: any;
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	mobileActions: MobileActionProps[];
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

interface Props {
	routeProps: RouteComponentProps;
	organization_name: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

const listName = "WEBHOOK";
const resourceType = "WEBHOOK";

const getFilterValues = (data: Record<string, any>, getTypeTitle: (id: string) => string) => {
	const { search } = location;
	const urlSearchParams = new URLSearchParams(search);
	const fParam = urlSearchParams.get("f");
	if (!fParam) return;

	let decodedFParam = "";
	try {
		decodedFParam = decodeURIComponent(escape(atob(fParam)));
	} catch (err) {
		return "";
	}

	const filters = decodedFParam.split(FILTER_SEPARATOR);
	const resourceTypeFilter = filters.find((filter) => filter.includes("resource_type"));
	if (!resourceTypeFilter) return;

	const resourceTypeFilterValueIndex = resourceTypeFilter.indexOf("=") + 1;
	const resourceTypeFilterValues = resourceTypeFilter?.substring(resourceTypeFilterValueIndex);
	const resourceTypeFilterValuesAsArray = resourceTypeFilterValues?.split(FILTER_VALUE_SEPARATOR);

	data.filter_values = [];

	resourceTypeFilterValuesAsArray?.forEach((filterValue) => {
		data.filter_values.push({
			id: filterValue,
			field_name: "resource_type",
			value: {
				name: getTypeTitle(filterValue),
			},
		});
	});
};
const List: FC<ListState> = ({
	resource,
	organization_name,
	companyName,
	taxIdNo,
	mobileActions,
	pdfOrientation,
	pdfFontSize,
}) => {
	const [items, setItems] = useState<WebhookApi[]>([]);
	const location = useLocation();
	const { t } = useTranslation();
	const segmentContextValue = React.useContext(SegmentContext);
	const webhookService = React.useContext(WebhookContext);

	if (!resource) return null;
	const data = resource.read();

	getFilterValues(data, webhookService.getTypeTitle.bind(webhookService));

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: WebhookApi) => <Link to={`${location.pathname}/${item.id}`}>{item.name}</Link>,
				renderExport: (item: WebhookApi) => item.name,
			},
			{
				id: "resource_type",
				name: t("lib:go_component.webhook.list.table.resource_type"),
				render: (option: WebhookApi) => {
					return webhookService.getTypeTitle(option.resource_type);
				},
			},
			{
				id: "url",
				name: t("lib:go_component.webhook.list.table.url"),
				type: "text",
			},
		],
		filters: [
			{
				id: "resource_type",
				name: t("lib:go_component.webhook.list.table.resource_type"),
				type: "search_select",
				source: {
					request: async () => {
						const res = await webhookService.api().getResourceTypeOptions();
						return res.map((option) => ({
							id: option,
							label: webhookService.getTypeTitle(option),
						}));
					},
				},
				withoutInfiniteScroll: true,
			},
		],
		actions: [
			{
				name: t("lib:common.action.preview"),
				link: (item: WebhookApi) => `${location.pathname}/${item.id}`,
			},
		],
		selectedColumns: ["name", "resource_type", "url"],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: async (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			return (await webhookService.api().all(params, { cancelToken: sourceToken?.token })).data.data;
		},
		exportConfig: {
			title: t("lib:go_component.webhook.list.export_config.title"),
			filename: t("lib:go_component.webhook.list.export_config.filename"),
			organization: `${organization_name}`,
			company: companyName,
			taxIdNo,
			pdfFontSize,
			pdfOrientation,
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;

	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};

	return <ListData data={items} config={config} onFetch={setItems} mobileActions={mobileActions} />;
};

const WebhooksList = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<any>();
	const segmentContextValue = React.useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const mobileActions: MobileActionProps[] = [
		{
			title: t("lib:common.action.add"),
			path: `${props.routeProps.match.url}/new`,
		},
	];

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_component.webhook.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && (
				<Header
					title={t("lib:go_component.webhook.header.title")}
					buttons={[
						{
							title: t("lib:common.action.add"),
							path: `${props.routeProps.match.url}/new`,
							variant: "primary",
						},
					]}
				/>
			)}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					organization_name={props.organization_name}
					taxIdNo={props.taxIdNo}
					companyName={props.companyName}
					mobileActions={mobileActions}
					pdfFontSize={props.pdfFontSize}
					pdfOrientation={props.pdfOrientation}
				/>
			</Suspense>
		</div>
	);
};
export default WebhooksList;
