import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterCondition, FilterType } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { listDataParams } from "go-list/list/services/decoder";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig, ListParamsType, MultipleActionsParams } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import {
	ItemGroupApi,
	ModifierGroupApi,
	ModifierGroupOptionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "MODIFIER_GROUP";
const resourceType = "MODIFIER_GROUP";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [items, setItems] = useState<ItemGroupApi[]>([]);
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async (refreshParams: Record<string, any>) => {
		if (config.fetch) {
			const res = await config.fetch(refreshParams);
			setItems(res);
		}
	};

	const onRemoveModifierGroup = async (item: ModifierGroupApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeModifierGroup(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestoreModifierGroup = async (item: ModifierGroupApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreModifierGroup(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onDisableModifierGroup = async (item: ModifierGroupApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.disable", { ns: "lib" }),
			});
			await api.organization().disableModifierGroup(item.id);
			await onRefresh(params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text" as FilterType,
				render: (item: ModifierGroupApi) => {
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(item.status)}
							minWidth={200}
							to={`${location.pathname}/${item.id}`}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: ModifierGroupApi) => item.name,
			},
			{
				id: "item",
				name: t("modules.modifier_group.field.item.title"),
				disableSorting: true,
				render: (item: ModifierGroupApi) => {
					return (
						<span
							style={{ width: "600px", display: "block", wordWrap: "break-word", whiteSpace: "normal" }}
						>
							{item.options?.map((x: ModifierGroupOptionApi) => x.sub_item?.name).join(", ")}
						</span>
					);
				},
				renderExport: (item: ItemGroupApi) => {
					return item.options?.map((x: ModifierGroupOptionApi) => x.sub_item?.name).join(", ");
				},
			},
			{
				id: "configuration",
				disableSorting: true,
				name: t("modules.modifier_group.field.configuration.title"),
				render: (item: ModifierGroupApi) => {
					if (item.quantity_info) {
						return (
							<>
								Min: {item.quantity_info.min_permitted ? item.quantity_info.min_permitted : "-"} • Max:{" "}
								{item.quantity_info.max_permitted ? item.quantity_info.max_permitted : "-"}
							</>
						);
					}
					return "";
				},
				renderExport: (item: ModifierGroupApi) => {
					if (item.quantity_info) {
						return `${t("modules.modifier_group.field.min_shortcut.title")}: ${
							item.quantity_info.min_permitted ? item.quantity_info.min_permitted : "-"
						} • ${t("modules.modifier_group.field.max_shortcut.title")}: ${
							item.quantity_info.max_permitted ? item.quantity_info.max_permitted : "-"
						}`;
					}
					return "";
				},
			},
		],
		actions: [
			{
				name: t("lib:common.action.edit"),
				link: (item: ModifierGroupApi) => {
					return `${location.pathname}/${item.id}`;
				},
				visible: (item: ModifierGroupApi) => {
					return item.status !== "DELETED";
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: ModifierGroupApi) => {
					onRemoveModifierGroup(item);
				},
				visible: (item: ModifierGroupApi) => {
					return item.status !== "DELETED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: ModifierGroupApi) => {
					onRestoreModifierGroup(item);
				},
				visible: (item: ModifierGroupApi) => {
					return item.status === "DELETED" || item.status === "DISABLED";
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: ModifierGroupApi) => {
					onDisableModifierGroup(item);
				},
				visible: (item: ModifierGroupApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.copy", { ns: "lib" }),
				click: (item: ItemGroupApi) => {
					history.push({
						pathname: `/${organization.id}/menu/modifier_groups/new`,
						state: {
							copiedItemId: item.id,
						},
					});
				},
			},
		],
		multipleActions: [
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: async (
					multipleActionsItems: ModifierGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.modifier_group.action.remove_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

						await api
							.organization()
							.removeModifierGroups(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
						await onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: () => {
					return items.filter((f) => f.status !== "DELETED").length > 0;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: async (
					multipleActionsItems: ModifierGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.modifier_group.action.activate_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

						await api
							.organization()
							.restoreModifierGroups(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash(t("lib:common.flash.completed"));
						await onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: () => {
					return items.filter((f) => f.status === "DELETED" || f.status === "DISABLED").length > 0;
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: async (
					multipleActionsItems: ModifierGroupApi[],
					listParams?: ListParamsType,
					areAllItemsSelected?: boolean,
					multipleActionParams?: MultipleActionsParams
				) => {
					const idsAsString = multipleActionsItems.map((item) => item.id.toString()).join(",");

					await confirmation({
						title: t("confirmation.title", { ns: "lib" }),
						message: t("modules.modifier_group.action.disable_message.title"),
					});
					try {
						const formattedParams = listParams ? listDataParams(listParams) : {};
						formattedParams.include =
							"direction,points_of_sale,points_of_sale.point_of_sale,image,points_of_sale.direction";

						await api
							.organization()
							.disableModifierGroups(idsAsString, multipleActionParams, areAllItemsSelected);
						addSuccessFlash("flash.disabled");
						await onRefresh(formattedParams);
					} catch (e) {
						handleError.alert(e, addFlash);
					}
				},
				visible: (multipleActionsItems: ModifierGroupApi[]) => {
					return multipleActionsItems.filter((f) => f.status === "ENABLED").length > 0;
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DISABLED: t("enums.common.status.DISABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
			{
				id: "option_count",
				name: t("modules.modifier_group.action.option_count.title"),
				type: "number" as FilterType,
				options: ["gt", "lt", "e", "bt"],
			},
			{
				id: "item",
				name: t("modules.modifier_group.field.modifier.title"),
				type: "search_select",
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
			},
		],
		selectedColumns: ["name", "item", "configuration"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.modifier_group.field.export_config_title.title"),
			filename: t("modules.modifier_group.field.export_config_title_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "options,options.item";
			setParams(fetchParams);
			return api.organization().getModifierGroups(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
		fetchTotalItemsCountForSelectedFilters: (
			totalItemsCountParams: Record<string, any> = {},
			sourceToken?: CancelTokenSource
		) => {
			totalItemsCountParams.include = "options,options.item,options.quantity_info,options.price,translations";

			return api
				.organization()
				.getModifierGroupsCount(totalItemsCountParams, { cancelToken: sourceToken?.token });
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={
				<EmptyList
					title={t("modules.modifier_group.header.title")}
					description={t("modules.modifier_group.field.empty_list_description.title")}
					actions={[
						{
							name: `+ ${t("common.action.add", { ns: "lib" })}`,
							click: () => {
								history.push(`${location.pathname}/new`);
							},
						},
					]}
				/>
			}
			onFetch={(fetchItems: ItemGroupApi[]) => setItems(fetchItems)}
			canManageMultiActionsForAllItems
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationMenuModifierGroupsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.modifier_group.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.modifier_group.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationMenuModifierGroupsIndexPage;
