import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
	option: number;
	setSelectedTimeOption: (option: number | undefined) => void;
	selectedTimeOption: number | string | undefined;
	handleSelect?: () => void;
}

const LiveOrderDeliveryTimeOption = ({ option, setSelectedTimeOption, selectedTimeOption, handleSelect }: Props) => {
	const { t } = useTranslation();

	const toggleOnClick = () => {
		if (selectedTimeOption === option) {
			setSelectedTimeOption(undefined);
		} else {
			setSelectedTimeOption(option);
			handleSelect && handleSelect();
		}
	};

	return (
		<Button
			onClick={toggleOnClick}
			variant={selectedTimeOption === option ? "primary" : "light"}
			className="live-order-delivery-time-option"
		>
			{`${option} ${t("lib:enums.dates.type.short.MINUTES")}`}
		</Button>
	);
};

export default LiveOrderDeliveryTimeOption;
