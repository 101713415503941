import { TemplateReportCombo } from "go-report-combo/template/Template/types";
import { SegmentApi } from "go-segment/services/types";

class ComboTemplateUtils {
	static segmentToTemplate = (segment: SegmentApi) => {
		return JSON.parse(segment.value) as TemplateReportCombo;
	};
}

export default ComboTemplateUtils;
