import React, { FC } from "react";
import { Dropdown, Nav, NavItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as HelpSVG } from "go-core/images/svg/navigation/help.svg";
import { ReactComponent as BlogSVG } from "go-core/images/svg/navigation/helpNavigation/blog.svg";
import { ReactComponent as HelpBookSVG } from "go-core/images/svg/navigation/helpNavigation/help-book.svg";
import { ReactComponent as ServiceAccessSVG } from "go-core/images/svg/navigation/helpNavigation/service-access.svg";
import { isWebView } from "go-core/utils/utils";

export interface HelpItem {
	name: string;
	icon: JSX.Element;
	onClick?: () => void;
	link?: string;
	isOnTop?: boolean;
}

interface Props {
	serviceAccessRouteLink?: string;
	options?: HelpItem[];
	navigationOpen: boolean;
}

const NavigationHelpComponent = (props: Props) => {
	const { t } = useTranslation();
	const isHidden = isWebView || window.navigator.userAgent === "gopos-webview-without-support";
	const { isSmallBreakpoint } = useWindowSize();
	const dropdownDropPlacement = props.navigationOpen && isSmallBreakpoint ? "up" : "end";

	const optionsOnTop = (props.options || []).filter((option) => option?.isOnTop);
	const restOptions = (props.options || []).filter((option) => !option?.isOnTop);

	const drawOptions = (options: HelpItem[]) => {
		return options.map((option) => {
			if (option.link) {
				return (
					<Dropdown.Item as={Link} to={option.link} key={option.name}>
						{option.icon}
						{option.name}
					</Dropdown.Item>
				);
			}
			if (option.onClick) {
				return (
					<Dropdown.Item key={option.name} onClick={option.onClick}>
						{option.icon}
						{option.name}
					</Dropdown.Item>
				);
			}
			return null;
		});
	};

	if (isHidden) return <></>;

	return (
		<Dropdown as={NavItem} drop={dropdownDropPlacement} className="navigation-help-container">
			<Dropdown.Toggle as={Nav.Link}>
				<div className="nav-link-svg">
					<HelpSVG />
				</div>
				<span>{t("lib:go_component.navigation.help")}</span>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{drawOptions(optionsOnTop)}
				<Dropdown.Divider />
				<Dropdown.Item href={"https://gopos.pl/blog/"}>
					<BlogSVG />
					{t("lib:go_component.navigation.blog")}
				</Dropdown.Item>
				<Dropdown.Item href={"https://help.gopos.pl/pl/"}>
					<HelpBookSVG />
					{t("lib:go_component.navigation.help")}
				</Dropdown.Item>
				{props.serviceAccessRouteLink && (
					<Dropdown.Item href={props.serviceAccessRouteLink}>
						<ServiceAccessSVG />
						{t("lib:go_component.navigation.service_access")}
					</Dropdown.Item>
				)}
				{drawOptions(restOptions)}
			</Dropdown.Menu>
		</Dropdown>
	);
};
export default NavigationHelpComponent;
