import { ListDataRequestResponse } from "go-core/api/types";
import { listDataParams } from "./decoder";
import { ListParamsType } from "./types";

export const listDataFetch = (
	params: ListParamsType,
	fetchAction?: (fetchParams: Record<string, any>) => Promise<Array<any> | ListDataRequestResponse>
): Promise<Array<any> | ListDataRequestResponse> => {
	if (fetchAction) {
		return fetchAction(listDataParams(params));
	}
	return new Promise<Array<any>>(() => {});
};

export const fetchTotalItemsCount = (
	params: ListParamsType,
	fetchAction: (fetchParams: Record<string, any>) => Promise<number | { data: number } | undefined>
): Promise<number | undefined | { data: number }> => {
	return fetchAction(listDataParams(params));
};
