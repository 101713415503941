import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Header from "go-app/components/Header";
import { useWindowSize } from "go-core/components/useWindowSize";
import SubmenuSettingsNavigation from "./components/SubmenuSettingsNavigation";
import OrganizationSettingsInfoBasicPage from "./pages/Basic";
import OrganizationSettingsInfoClearVenuePage from "./pages/ClearVenue";
import OrganizationSettingsInfoCompanyPage from "./pages/Company";
import OrganizationSettingsInfoInvoiceDetailsPage from "./pages/InvoiceDetails";
import OrganizationSettingsInfoPosAccessPage from "./pages/PosAccess";
import OrganizationSettingsInfoReceiptPage from "./pages/Receipt";
import OrganizationSettingsInfoVenueSettingsPage from "./pages/VenueSettings";

const OrganizationSettingsInfoPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	return (
		<div className="content">
			{!isMobile && <Header title={t("lib:common.word.informations")} />}
			<SubmenuSettingsNavigation />
			<Switch>
				<Route path={`${props.match.url}/basic`} component={OrganizationSettingsInfoBasicPage} />
				<Route path={`${props.match.url}/company`} component={OrganizationSettingsInfoCompanyPage} />
				<Route path={`${props.match.url}/pos_access`} component={OrganizationSettingsInfoPosAccessPage} />
				<Route path={`${props.match.url}/receipt`} component={OrganizationSettingsInfoReceiptPage} />
				<Route
					path={`${props.match.url}/invoice_details`}
					component={OrganizationSettingsInfoInvoiceDetailsPage}
				/>
				<Route
					path={`${props.match.url}/venue_settings`}
					component={OrganizationSettingsInfoVenueSettingsPage}
				/>
				<Route path={`${props.match.url}/clear_venue`} component={OrganizationSettingsInfoClearVenuePage} />
				<Redirect from={props.match.url} exact to={`${props.match.url}/basic`} />
			</Switch>
		</div>
	);
};

export default OrganizationSettingsInfoPage;
