import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import FormatResourceStatus from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { camelToSnakeCase } from "../../utils/componentUtils";
import FormatJobStatus from "./components/FormatJobStatus";
import { JobContext } from "./services/context";
import { JobApi } from "./services/types";

interface Props {
	job: JobApi;
}

const JobPreview = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [job] = useState<JobApi>(props.job);
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const jobService = React.useContext(JobContext);
	const cancelJob = async (jobToBeCancelled: JobApi) => {
		await confirmation({ title: t("lib:confirmation.title"), message: t("lib:confirmation.message.cancel") });
		try {
			jobService.api().cancel(jobToBeCancelled.id);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};
	const buttons: ButtonProps[] = [];
	if (job.status === "STARTED" || job.status === "NEW") {
		buttons.push({
			title: t("lib:common.action.cancel"),
			action: () => cancelJob(job),
			dropdown: true,
		});
	}
	return (
		<div className={"content"}>
			<div className={"content-preview"}>
				<Header
					title={
						<>
							{`${t("lib:go_component.job.preview.header.title")} "${t(
								`enums.jobs.name.${camelToSnakeCase(job.name)}`
							)}"`}
							<FormatJobStatus status={job.status} />
						</>
					}
					buttons={buttons}
				/>
				<div className="row">
					<div className={"col-md-12"}>
						<Card className={"mb-3"}>
							<Card.Body>
								<h5>{t("lib:common.word.basic_data")}</h5>
								<dl className="mb-0">
									<dt>{t("lib:common.word.name")}</dt>
									<dd>{jobService.getNameTitle(job.name)}</dd>
								</dl>
								<dl className="mb-0">
									<dt>{t("lib:common.word.created_at")}</dt>
									<dd>{FormatDate(job.created_at, undefined, true)}</dd>
								</dl>
								{job.updated_at && (
									<dl className="mb-0">
										<dt>{t("lib:common.word.updated_at")}</dt>
										<dd>{FormatDate(job.updated_at, undefined, true)}</dd>
									</dl>
								)}
								{job.finished_at && (
									<dl className="mb-0">
										<dt>{t("lib:common.word.finished_at")}</dt>
										<dd>{FormatDate(job.finished_at, undefined, true)}</dd>
									</dl>
								)}
							</Card.Body>
						</Card>
					</div>
					{job.steps.length > 0 && (
						<div className={"col-md-12"}>
							<Card>
								<Card.Body>
									<h5>{t("lib:go_component.job.preview.steps.title")}</h5>
									<div className={"table-responsive"}>
										<table className={"table table-preview"}>
											<thead>
												<tr>
													<th>{t("lib:common.word.name")}</th>
													<th>{t("lib:common.word.created_at")}</th>
													<th>{t("lib:common.word.finished_at")}</th>
													<th>{t("lib:common.word.status")}</th>
													<th>{t("lib:common.word.settings")}</th>
													<th>{t("lib:common.word.error")}</th>
												</tr>
											</thead>
											<tbody>
												{job.steps.map((resource, index) => {
													return (
														<tr key={index}>
															<td>{resource.name}</td>
															<td>{FormatDate(resource.created_at, undefined, true)}</td>
															<td>{FormatDate(resource.created_at, undefined, true)}</td>
															<td>
																<FormatResourceStatus status={resource.status} />
															</td>
															<td>
																{resource.settings?.length > 0 && (
																	<div className={"d-flex flex-column"}>
																		{resource.settings.map((setting, subIndex) => {
																			return (
																				<span
																					key={`${index}_${subIndex}`}
																					className={"mb-1"}
																				>
																					{setting.name}: {setting.value}
																				</span>
																			);
																		})}
																	</div>
																)}
															</td>
															<td>{resource.error}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</Card.Body>
							</Card>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default JobPreview;
