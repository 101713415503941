import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import useFlash from "go-alert/AlertMessage";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import MigrationComponent from "./components/MigrationComponent";

const OrganizationMigrationPage = (): JSX.Element => {
	const user = useSelector(selectUser);
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	if (!user.admin && !organization.support) {
		addFlash({
			type: "danger",
			msg: "access_forbidden",
		});
		return <Redirect to={`/${organization.id}`} />;
	}
	return (
		<div className="page-container">
			<div className="content">
				<div className={"content-preview"}>
					<MigrationComponent context={"ORGANIZATION"} />
				</div>
			</div>
		</div>
	);
};
export default OrganizationMigrationPage;
