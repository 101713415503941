import { Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck, FormInput } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ItemApi, ItemGroupApi, PointOfSaleApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../../services/Api/types";

interface Props {
	form: UseFormReturn<ItemGroupApi>;
	pointsOfSales: PointOfSaleApi[];
	advancedPosesView: boolean;
	changeAdvancedPosesView: () => void;
	items: ItemApi[];
	setAdjustItems: React.Dispatch<React.SetStateAction<boolean>>;
	adjustItems: boolean;
}

const ItemsPointsOfSalesForm = ({
	form,
	pointsOfSales,
	changeAdvancedPosesView,
	advancedPosesView,
	items,
	setAdjustItems,
	adjustItems,
}: Props) => {
	const {
		formState: { errors },
		control,
		register,
		setValue,
	} = form;
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const searchDirections = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDirectionsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const handleChangeVisibilityOfPointOfSale = (
		pointOfSaleIndex: number,
		event: React.MouseEvent<HTMLInputElement>
	) => {
		items.forEach((item, itemIndex) => {
			setValue(`items.${itemIndex}.points_of_sale.${pointOfSaleIndex}.visibility`, event.currentTarget.checked);
		});
	};

	const handleChangeDirection = (pointOfSaleIndex: number, direction?: SearchSelectApi) => {
		items.forEach((item, itemIndex) => {
			setValue(
				`items.${itemIndex}.points_of_sale.${pointOfSaleIndex}.direction_id`,
				direction ? Number(direction.id) : undefined
			);
			setValue(
				`items.${itemIndex}.points_of_sale.${pointOfSaleIndex}.direction`,
				direction
					? {
							name: direction.label,
							id: Number(direction.id),
					  }
					: undefined
			);
		});
	};
	return (
		<>
			{pointsOfSales.length > 0 && (
				<div className="limit-component-visibility-form-box">
					<div className="form-box-check">
						<Form.Check
							type="switch"
							id="advanced_view"
							checked={advancedPosesView}
							onClick={() => changeAdvancedPosesView()}
						/>
						<Form.Check.Label onClick={() => changeAdvancedPosesView()}>
							<h6>{t("modules.menu.action.limit_sale_points.title")}</h6>
						</Form.Check.Label>
					</div>
					<div className="form-box-content">
						<div className="form-box-title">
							<Form.Check.Label onClick={() => changeAdvancedPosesView()}>
								<h6>{`${t("modules.menu.action.limit_sale_points.title")}`}</h6>
							</Form.Check.Label>
						</div>
						<small className="text-muted">
							{t("modules.menu.field.limit_sales_point_description.title", {
								available_specific_name: t("modules.menu.field.product_available.title"),
								name: t("modules.menu.field.product.title"),
								available: t("modules.menu.field.available.male.title"),
								visible: t("modules.menu.field.visible.title"),
							})}
						</small>
						{advancedPosesView && (
							<>
								{items.length > 1 && (
									<div className="form-box-check my-3 adjust-variants-box">
										<Form.Check
											type="switch"
											id="adjust_items"
											checked={adjustItems}
											label={t("modules.menu.action.adjust_variants.title")}
											onClick={() => setAdjustItems((prevState) => !prevState)}
										/>
									</div>
								)}
								<div className={`${isMobile ? "table-responsive" : ""}`}>
									<table className="table table-form">
										<thead>
											<tr>
												{(adjustItems ? items : items.filter((_, itemIndex) => itemIndex === 0))
													.length > 1 ? (
													<>
														<th
															className="w-20"
															style={{ minWidth: isMobile ? "150px" : "unset" }}
														>
															{t("modules.menu.action.sale_point.title")}
														</th>
														<th style={{ minWidth: isMobile ? "200px" : "unset" }}>
															{t("common.word.variant")}
														</th>
														<th
															className="w-50"
															style={{ minWidth: isMobile ? "200px" : "unset" }}
														>
															{t("common.word.direction_of_expenditure")}
														</th>
													</>
												) : (
													<>
														<th style={{ minWidth: isMobile ? "150px" : "unset" }}>
															{t("modules.menu.action.sale_point.title")}
														</th>
														<th
															className="w-75"
															style={{ minWidth: isMobile ? "200px" : "unset" }}
														>
															{t("common.word.direction_of_expenditure")}
														</th>
													</>
												)}
											</tr>
										</thead>
										<tbody>
											{pointsOfSales.map((pointOfSale, index) => {
												const itemsToDisplay = adjustItems
													? items
													: items.filter((_, itemIndex) => itemIndex === 0);

												return itemsToDisplay.map((item, itemIndex) => {
													const itemPointOfSale = item.points_of_sale.find(
														(itemPoint) => itemPoint?.point_of_sale_id === pointOfSale.id
													);

													return itemsToDisplay.length > 1 ? (
														<tr key={pointOfSale.id}>
															{itemIndex === 0 && (
																<td
																	rowSpan={itemsToDisplay.length}
																	className="align-top"
																>
																	{pointOfSale.name}
																</td>
															)}
															<td>
																<FormInput
																	errors={errors}
																	type="hidden"
																	register={register}
																	value={itemPointOfSale?.point_of_sale_id}
																	name={`items.${itemIndex}.points_of_sale.${index}.point_of_sale_id`}
																/>
																<FormCheck
																	type="switch"
																	label={item.name}
																	register={register}
																	name={`items.${itemIndex}.points_of_sale.${index}.visibility`}
																	errors={errors}
																	className="ms-2"
																/>
															</td>
															<td>
																<FormSelectGroup
																	name={`items.${itemIndex}.points_of_sale.${index}.direction_id`}
																	errors={errors}
																	getOptionLabel={(option) => option.label}
																	defaultValue={
																		itemPointOfSale?.direction_id
																			? {
																					label: itemPointOfSale?.direction
																						?.name,
																					id: itemPointOfSale?.direction?.id,
																			  }
																			: undefined
																	}
																	getOptionValue={(option) => option.id}
																	loadOptions={searchDirections}
																	control={control}
																	data-testid={`items.${itemIndex}.points_of_sale.${index}.direction_id`}
																/>
															</td>
														</tr>
													) : (
														<tr key={pointOfSale.id}>
															<td>
																<FormInput
																	errors={errors}
																	type="hidden"
																	register={register}
																	value={itemPointOfSale?.point_of_sale_id}
																	name={`items.${itemIndex}.points_of_sale.${index}.point_of_sale_id`}
																/>
																<FormCheck
																	type="switch"
																	label={pointOfSale.name}
																	register={register}
																	name={`items.${itemIndex}.points_of_sale.${index}.visibility`}
																	errors={errors}
																	onClick={(event) =>
																		handleChangeVisibilityOfPointOfSale(
																			index,
																			event
																		)
																	}
																/>
															</td>
															<td>
																<FormSelectGroup
																	name={`items.${itemIndex}.points_of_sale.${index}.direction_id`}
																	errors={errors}
																	getOptionLabel={(option) => option.label}
																	defaultValue={
																		itemPointOfSale?.direction_id
																			? {
																					label: itemPointOfSale?.direction
																						?.name,
																					id: itemPointOfSale?.direction?.id,
																			  }
																			: undefined
																	}
																	getOptionValue={(option) => option.id}
																	loadOptions={searchDirections}
																	control={control}
																	data-testid={`items.${itemIndex}.points_of_sale.${index}.direction_id`}
																	onChange={(_, obj) =>
																		handleChangeDirection(index, obj)
																	}
																/>
															</td>
														</tr>
													);
												});
											})}
										</tbody>
									</table>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default ItemsPointsOfSalesForm;
