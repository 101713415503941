import React, { FC } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { ReactComponent as CalendarSVG } from "go-core/images/svg/calendar.svg";
import { ReactComponent as CircleInfoSVG } from "go-core/images/svg/circle-info.svg";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ReactComponent as CommentSVG } from "../../../../../../../../../images/svg/bills/comment.svg";
import { ReactComponent as CustomFieldSVG } from "../../../../../../../../../images/svg/bills/custom-fields.svg";
import { ReactComponent as ClientLogo } from "../../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as GuestsSVG } from "../../../../../../../../../images/svg/bills/guests.svg";
import { ReactComponent as InvoiceSVG } from "../../../../../../../../../images/svg/bills/invoice.svg";
import { ReactComponent as PointOfSaleSVG } from "../../../../../../../../../images/svg/bills/pos.svg";
import { ReactComponent as RoomSVG } from "../../../../../../../../../images/svg/bills/room.svg";
import { ReactComponent as TerminalSVG } from "../../../../../../../../../images/svg/bills/terminal.svg";
import { OrderApi } from "../../../../../../../../../services/Api/Organization/types";

export interface Props {
	order: OrderApi;
	customFieldsConfig: CustomFieldTemplateApi[];
}

const InfoCard: FC<Props> = ({ order, customFieldsConfig }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const getReportLabel = () => {
		const { report } = order;

		if (!report.drawer_report_id && !report.shift_work_report_id) {
			const tooltip = <Tooltip>{t("modules.order.field.report_not_synchronized.title")}</Tooltip>;
			return (
				<div className="d-flex align-items-center column-gap-0">
					<span>{t("modules.order.field.in_progress.title")}</span>
					<OverlayTrigger placement="bottom" overlay={tooltip}>
						<CircleInfoSVG className="circle-info-svg" viewBox="-5 -5 44 44" />
					</OverlayTrigger>
				</div>
			);
		}

		return report.drawer_report ? (
			<Link to={`/${organization.id}/sales/pos_reports/${report.drawer_report_id}`}>
				#{report.drawer_report_id}
			</Link>
		) : (
			<Link to={`/${organization.id}/sales/pos_reports/${report.shift_work_report_id}`}>
				#{report.shift_work_report_id}
			</Link>
		);
	};

	return (
		<Card>
			<Card.Body>
				<h5>{t("lib:common.word.informations")}</h5>
				{order.opening_employee && (
					<div className="card-body-item">
						<ClientLogo />
						<div className="item-info">
							<span className="label">{t("modules.order.field.opening_employee.title")}</span>
							<span>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/employees/${order.opening_employee.id}`}
								>
									{order.opening_employee.name}
								</Link>
							</span>
						</div>
					</div>
				)}
				{order.employee && (
					<div className="card-body-item">
						<ClientLogo />
						<div className="item-info">
							<span className="label">{t("common.word.employee")}</span>
							<span>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/employees/${order.employee.id}`}
								>
									{order.employee.name}
								</Link>
							</span>
						</div>
					</div>
				)}
				{order.point_of_sale && (
					<div className="card-body-item">
						<PointOfSaleSVG />
						<div className="item-info">
							<span className="label">{t("common.word.point_of_sale")}</span>
							<span>{order.point_of_sale.name}</span>
						</div>
					</div>
				)}
				{order.comment && (
					<div className="card-body-item">
						<CommentSVG />
						<div className="item-info">
							<span className="label">{t("common.word.comment")}</span>
							<span>{order.comment}</span>
						</div>
					</div>
				)}
				{order.terminal && (
					<div className="card-body-item">
						<TerminalSVG />
						<div className="item-info">
							<span className="label">{t("modules.order.field.terminal_owner.title")}</span>
							<span>
								<Link
									target={"_blank"}
									to={`/${organization.id}/settings/terminals/${order.terminal.id}`}
								>
									{order.terminal.name}
								</Link>
							</span>
						</div>
					</div>
				)}
				{order.closing_terminal && (
					<div className="card-body-item">
						<TerminalSVG />
						<div className="item-info">
							<span className="label">{t("common.word.terminal")}</span>
							<span>
								<Link
									target={"_blank"}
									to={`/${organization.id}/settings/terminals/${order.closing_terminal.id}`}
								>
									{order.closing_terminal.name}
								</Link>
							</span>
						</div>
					</div>
				)}
				{order.report && (
					<div className="card-body-item">
						<InvoiceSVG />
						<div className="item-info">
							<span className="label">{t("modules.order.field.assigned_to_report.title")}</span>
							{getReportLabel()}
						</div>
					</div>
				)}
				{order.table && (
					<div className="card-body-item">
						<RoomSVG />
						<div className="item-info">
							<span className="label">
								{order.type === "ROOM_SERVICE" ? (
									t("modules.order.field.room.title")
								) : (
									<>
										{order.table?.room_name
											? t("modules.order.field.table.title")
											: t("common.word.table")}
									</>
								)}
							</span>
							<span>
								{order.table.room_name && `${order.table.room_name}`}
								{order.table.name && " / "}
								{order.table.name}
							</span>
						</div>
					</div>
				)}
				{order.number_of_guests && (
					<div className="card-body-item">
						<GuestsSVG />
						<div className="item-info">
							<span className="label">{t("modules.order.field.number_of_guests.title")}</span>
							<span>{order.number_of_guests}</span>
						</div>
					</div>
				)}
				{order.execution_at && (
					<div className={"card-body-item"}>
						<CalendarSVG />
						<div className="item-info">
							<span className="label">{t("modules.order.field.execution_at.title")}</span>
							<span>{FormatDate(order.execution_at)}</span>
						</div>
					</div>
				)}
				{order.custom_fields && order.custom_fields.length > 0 && (
					<div className="card-body-item">
						<CustomFieldSVG />
						<div className="item-info">
							{order.custom_fields
								// ordersource CF is already displayed in SourceCard
								.filter((cf) => cf.slug !== "ordersource")
								.map((cf, index) => {
									return (
										<React.Fragment key={index}>
											<span className="label">
												{customFieldsConfig.find((f) => f.slug === cf.slug)
													? customFieldsConfig.find((f) => f.slug === cf.slug)?.name
													: cf.slug}
											</span>
											<span>{cf.value}</span>
										</React.Fragment>
									);
								})}
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};
export default InfoCard;
