import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoSuiteApp, OrganizationCard } from "../../services/types";
import ListCardOrganizationApplication from "./ListCardOrganizationApplication";

interface Props {
	isShown: boolean;
	onHide: () => void;
	applications: GoSuiteApp[];
	organization: OrganizationCard;
}

const ListOrganizationApplicationsModal: FC<Props> = ({ isShown, onHide, applications, organization }) => {
	const { t } = useTranslation();

	return (
		<Modal show={isShown} onHide={onHide} className="organization-applications-modal">
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:go_component.list_organization_applications_modal.header.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-2">
					{applications.map((app, index) => (
						<ListCardOrganizationApplication
							key={`app-${index}`}
							application={app}
							organization={organization}
						/>
					))}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ListOrganizationApplicationsModal;
