import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { MessageEventApi } from "go-component/components/MessageEvent/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { apiOrganization } from "go-security/services/Api/apiOrganization";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiOrganizationComponent } from "../../../../../../../../../../services/Api/Organization/apiOrganizationComponent";
import { PosReportApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "MESSAGE_EVENT";
const resourceType = "MESSAGE_EVENT";

const List: FC<ListState> = ({ resource }) => {
	const [items, setItems] = useState<MessageEventApi[]>([]);
	const location = useLocation();
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "created_at",
				name: t("lib:common.word.created_at"),
				type: "date" as FilterType,
				render: (item: MessageEventApi) => {
					return (
						<Link to={`${location.pathname}/${item.id}`}>
							{FormatDate(item.created_at, undefined, true)}
						</Link>
					);
				},
				renderExport: (item: MessageEventApi) => {
					return FormatDate(item.created_at, undefined, true);
				},
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				disableSorting: true,
				render: (item: MessageEventApi) => {
					return t(`enums.message_events.type.${item.type}`).includes(".")
						? item.type
						: t(`enums.message_events.type.${item.type}`);
				},
				renderExport: (item: MessageEventApi) => {
					return t(`enums.message_events.type.${item.type}`);
				},
			},
			{
				id: "user",
				name: t("modules.message_event.field.initiator.title"),
				type: "search_select" as FilterType,
				render: (item: MessageEventApi) => {
					if (item.terminal?.name) {
						return (
							<Link to={`/${organization?.id}/settings/terminals/${item.terminal?.id}`}>
								{item.terminal?.name}
							</Link>
						);
					}
					return item.username;
				},
				source: {
					request: (search?: string) => apiOrganization.getListUsersSearchSelect(search),
				} as ListFilterSource,
			},
			{
				id: "source",
				disableSorting: true,
				name: t("lib:common.word.source"),
				type: "list" as FilterType,
				options: {
					AJAX: t(`lib:enums.message_events.source.AJAX`),
					API: t(`lib:enums.message_events.source.API`),
					INTERNAL: t(`lib:enums.message_events.source.INTERNAL`),
					INIT: t(`lib:enums.message_events.source.INIT`),
					MIGRATION: t(`lib:enums.message_events.source.MIGRATION`),
					SYNC: t("enums.message_events.source.SYNC", { ns: "lib" }),
					CSV_IMPORT: t("enums.message_events.source.CSV_IMPORT", { ns: "lib" }),
				},
				render: (item: MessageEventApi) =>
					t(`enums.message_events.source.${item.source}`, { ns: ["translation", "lib"] }),
			} as ListConfigField,
		],
		filters: [
			{
				id: "resource_id",
				type: "text",
				name: t("modules.message_event.field.resource_id.title"),
			},
			{
				id: "resource_type",
				type: "list",
				name: t("modules.message_event.field.resource_type.title"),
				options: {
					ITEM: t("enums.message_events.resource_type.ITEM"),
					ITEM_GROUP: t("enums.message_events.resource_type.ITEM_GROUP"),
					ITEM_STOCK_INFO: t("enums.message_events.resource_type.ITEM_STOCK_INFO"),
					IMAGE: t("enums.message_events.resource_type.IMAGE"),
					MENU_DISCOUNT: t("enums.message_events.resource_type.MENU_DISCOUNT"),
					MENU_PRICE_LIST: t("enums.message_events.resource_type.MENU_PRICE_LIST"),
					CATEGORY: t("enums.message_events.resource_type.CATEGORY"),
					MODIFIER_GROUP: t("enums.message_events.resource_type.MODIFIER_GROUP"),
					ORDER: t("enums.message_events.resource_type.ORDER"),
					ORDER_COST: t("enums.message_events.resource_type.ORDER_COST"),
					ORDER_TRANSFER: t("enums.message_events.resource_type.ORDER_TRANSFER"),
					ORDER_PREPARATION_STATUS: t("enums.message_events.resource_type.ORDER_PREPARATION_STATUS"),
					ORDER_PREPARATION_STATUS_CHANGE: t(
						"enums.message_events.resource_type.ORDER_PREPARATION_STATUS_CHANGE"
					),
					RECEIPT: t("enums.message_events.resource_type.RECEIPT"),
					MENU: t("enums.message_events.resource_type.MENU"),
					EMPLOYEE: t("enums.message_events.resource_type.EMPLOYEE"),
					EMPLOYEE_ACTIVITY: t("enums.message_events.resource_type.EMPLOYEE_ACTIVITY"),
					WEBHOOK: t("enums.message_events.resource_type.WEBHOOK"),
					JOB: t("enums.message_events.resource_type.JOB"),
					DOCUMENT_TEMPLATE: t("enums.message_events.resource_type.DOCUMENT_TEMPLATE"),
					CLIENT: t("enums.message_events.resource_type.CLIENT"),
					CLIENT_GROUP: t("enums.message_events.resource_type.CLIENT_GROUP"),
					RESERVATION: t("enums.message_events.resource_type.RESERVATION"),
					TAX: t("enums.message_events.resource_type.TAX"),
					PAYMENT_METHOD: t("enums.message_events.resource_type.PAYMENT_METHOD"),
					CURRENCY_RATE: t("enums.message_events.resource_type.CURRENCY_RATE"),
					ROOM: t("enums.message_events.resource_type.ROOM"),
					DIRECTION: t("enums.message_events.resource_type.DIRECTION"),
					POINT_OF_SALE: t("enums.message_events.resource_type.POINT_OF_SALE"),
					ROLE: t("enums.message_events.resource_type.ROLE"),
					PERMISSION: t("enums.message_events.resource_type.PERMISSION"),
					ROOM_TABLE: t("enums.message_events.resource_type.ROOM_TABLE"),
					PRINTOUT_TEMPLATE: t("enums.message_events.resource_type.PRINTOUT_TEMPLATE"),
					// BILL: t("enums.message_events.resource_type.BILL"),
					// BILL_STATUS_PREPARATION: t("enums.message_events.resource_type.BILL_STATUS_PREPARATION"),
					// BILL_TRANSFER: t("enums.message_events.resource_type.BILL_TRANSFER"),
					REPORT: t("enums.message_events.resource_type.REPORT"),
					// REPORT_TRANSACTION: t("enums.message_events.resource_type.REPORT_TRANSACTION"),
					ORGANIZATION: t("enums.message_events.resource_type.ORGANIZATION"),
					TERMINAL: t("enums.message_events.resource_type.TERMINAL"),
					// PRICE_LIST: t("enums.message_events.resource_type.PRICE_LIST"),
					WORKPLACE: t("enums.message_events.resource_type.WORKPLACE"),
					DEFAULT_COMMENT: t("enums.message_events.resource_type.DEFAULT_COMMENT"),
					DEVICE: t("enums.message_events.resource_type.DEVICE"),
					AVAILABILITY: t("enums.message_events.resource_type.AVAILABILITY"),
					VENUE: t("enums.message_events.resource_type.VENUE"),
					VENUE_SETTING: t("enums.message_events.resource_type.VENUE_SETTING"),
					REPORT_PAID: t("enums.message_events.resource_type.REPORT_PAID"),
					INVOICE: t("enums.message_events.resource_type.INVOICE"),
					// INVOICE_PROFORMA: t("enums.message_events.resource_type.INVOICE_PROFORMA"),
					INVOICE_DETAILS: t("enums.message_events.resource_type.INVOICE_DETAILS"),
					WORK_TIME: t("enums.message_events.resource_type.WORK_TIME"),
					WORK_TIME_REQUEST: t("enums.message_events.resource_type.WORK_TIME_REQUEST"),
					// BANK_ACCOUNT: t("enums.message_events.resource_type.BANK_ACCOUNT"),
					// APPLICATION: t("enums.message_events.resource_type.APPLICATION"),
					APP: t("enums.message_events.resource_type.APP"),
					APP_LINK: t("enums.message_events.resource_type.APP_LINK"),
					TRANSACTION: t("enums.message_events.resource_type.TRANSACTION"),
					// ATTRIBUTE: t("enums.message_events.resource_type.ATTRIBUTE"),
					TERMINAL_NOTIFICATION: t("enums.message_events.resource_type.TERMINAL_NOTIFICATION"),
					CUSTOM_FIELD: t("enums.message_events.resource_type.CUSTOM_FIELD"),
					SEGMENT: t("enums.message_events.resource_type.SEGMENT"),
					REPORT_EXTERNAL: t("enums.message_events.resource_type.REPORT_EXTERNAL"),
					TAG: t("enums.message_events.resource_type.TAG"),
					REQUEST: t("enums.message_events.resource_type.REQUEST"),
					CALL_RECORD: t("enums.message_events.resource_type.CALL_RECORD"),
					EMPLOYEE_DELIVERY_COORDINATES: t(
						"enums.message_events.resource_type.EMPLOYEE_DELIVERY_COORDINATES"
					),
				},
			},
			{
				id: "user_id",
				type: "number",
				name: t("modules.message_event.field.user_id.title"),
			},
			{
				id: "item_group",
				name: t("common.word.item_group"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemGroupsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "category",
				name: t("common.word.item_group_category"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getCategoriesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "menu_discount",
				name: t("common.word.discount"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getListDiscountsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "menu",
				name: t("common.word.menu"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getMenusSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "modifier_group",
				name: t("common.word.modifier_group"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getModifierGroupsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "order",
				name: t("common.word.order"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getOrdersSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "invoice",
				name: t("common.word.invoice"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getInvoicesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "report",
				name: t("modules.message_event.field.pos_report.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: PosReportApi) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at
						)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "transaction",
				name: t("modules.message_event.field.transaction.title"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTransactionsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "search_select",
				source: {
					request: async () => {
						const res: string[] = await apiOrganizationComponent.getMessageEventsTypes();
						return res.map((option) => ({
							id: option,
							label: t(`enums.message_events.type.${option}`).includes(".")
								? option
								: t(`enums.message_events.type.${option}`),
						}));
					},
				},
				withoutInfiniteScroll: true,
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: MessageEventApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
		],
		selectedColumns: ["created_at", "user", "type", "source"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: async (params: Record<string, any>, sourceToken?: CancelTokenSource) => {
			params.include = "resources,resources.entity_id,terminal";
			const res = await apiOrganizationComponent.getMessageEvents(params, { cancelToken: sourceToken?.token });
			return res.data.data;
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.message_event.field.export_config.title"),
			filename: t("modules.message_event.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			customExportRequests: {
				pdfFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns: params?.columns || "CREATED_AT,TYPE,USER,SOURCE",
					};
					return api.organization().getMessageEventsPDF(newParams);
				},
				csvFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns: params?.columns || "CREATED_AT,TYPE,USER,SOURCE",
					};
					return api.organization().getMessageEventsCSV(newParams);
				},
			},
		} as ExportConfig,
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};

	return <ListData data={items} config={config} onFetch={(fetchItems: MessageEventApi[]) => setItems(fetchItems)} />;
};

const OrganizationLogsMessageEventsIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.message_event.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.message_event.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
		</div>
	);
};
export default OrganizationLogsMessageEventsIndexPage;
