import React from "react";
import VirtualTableSearchColumn from "../../../../../../../../components/VirtualTable/VirtualTableSearchColumn";
import { VirtualTableColumn } from "../../../../../../../../components/VirtualTable/types";
import {
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../../../components/VirtualTable/utils";

interface Props {
	visibleDefaultColumns: VirtualTableColumn[];
	maxZindex: number;
	setCategorySearch: (search: string) => void;
	setItemGroupSearch: (search: string) => void;
	setItemSearch: (search: string) => void;
	setItemFullNameSearch: (search: string) => void;
	setModifierGroupSearch: (search: string) => void;
	setModifierSearch: (search: string) => void;
	categorySearch: string | undefined;
	itemGroupSearch: string | undefined;
	itemSearch: string | undefined;
	itemFullNameSearch: string | undefined;
	modifierGroupSearch: string | undefined;
	modifierSearch: string | undefined;
}

const SpreadsheetPriceListVirtualTableHeaderSearchColumnsRow = ({
	visibleDefaultColumns,
	maxZindex,
	setCategorySearch,
	setItemGroupSearch,
	setItemSearch,
	setModifierGroupSearch,
	setItemFullNameSearch,
	setModifierSearch,
	categorySearch,
	itemGroupSearch,
	itemSearch,
	itemFullNameSearch,
	modifierGroupSearch,
	modifierSearch,
}: Props) => {
	const handleClearFilter = (columnId: string) => {
		switch (columnId) {
			case "category_name":
				setCategorySearch("");
				break;
			case "item_group_name":
				setItemGroupSearch("");
				break;
			case "item_name":
				setItemSearch("");
				break;
			case "item_full_name":
				setItemFullNameSearch("");
				break;
			case "modifier_group_name":
				setModifierGroupSearch("");
				break;
			case "sub_item_name":
				setModifierSearch("");
				break;
		}
	};

	const handleSearch = (columnId: string, search: string) => {
		switch (columnId) {
			case "category_name":
				setCategorySearch(search);
				break;
			case "item_group_name":
				setItemGroupSearch(search);
				break;
			case "item_name":
				setItemSearch(search);
				break;
			case "item_full_name":
				setItemFullNameSearch(search);
				break;
			case "modifier_group_name":
				setModifierGroupSearch(search);
				break;
			case "sub_item_name":
				setModifierSearch(search);
				break;
			default:
				break;
		}
	};

	const getSearchValue = (columnId: string) => {
		switch (columnId) {
			case "category_name":
				return categorySearch;
			case "item_group_name":
				return itemGroupSearch;
			case "item_name":
				return itemSearch;
			case "item_full_name":
				return itemFullNameSearch;
			case "modifier_group_name":
				return modifierGroupSearch;
			case "sub_item_name":
				return modifierSearch;
			default:
				return "";
		}
	};

	const drawSearchColumns = () => {
		let prevLeftPosition = 0;
		return visibleDefaultColumns.map((col, index) => {
			const prevColumn = visibleDefaultColumns[index - 1];
			const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
			prevLeftPosition = leftPosition;
			const isLastColumn = index === visibleDefaultColumns.length - 1;
			const zIndex = maxZindex - index;
			const isHide = isVirtualTableColumnHide(col.id, visibleDefaultColumns);
			const isDefaultPriceColumn = col.id === "price";

			return (
				<VirtualTableSearchColumn
					key={`search-column-${col.id}`}
					isEmpty={isDefaultPriceColumn}
					clearFilter={() => handleClearFilter(col.id)}
					searchValue={getSearchValue(col.id)}
					search={(value) => handleSearch(col.id, value)}
					isHide={isHide}
					positionLeft={leftPosition}
					isSticky
					lastColumn={isLastColumn}
					zIndex={zIndex}
				/>
			);
		});
	};

	return <>{drawSearchColumns()}</>;
};

export default SpreadsheetPriceListVirtualTableHeaderSearchColumnsRow;
