import { AxiosResponse } from "axios";
import { parseApiData } from "go-core/api/parseApiData";
import { getSearchSelectPage, mapToBase } from "go-core/api/searchSelectService";
import { ListResponse } from "go-core/api/types";
import { restSecurityApi } from "./api";
import {
	AcceptUserTermsSecurityApi,
	InvitationSecurityApi,
	OrganizationItem,
	OrganizationSecurityApi,
	RegisterOrganizationSecurityApi,
} from "./typesSecurity";

class apiUser {
	static registerOrganization(data: RegisterOrganizationSecurityApi): Promise<AxiosResponse> {
		return restSecurityApi.post("/security/organizations", data);
	}

	static getNotInstalledExternalOrganizations(
		params?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<OrganizationSecurityApi>>> {
		return restSecurityApi.get("/security/me/organizations/not-installed", { params });
	}

	static getExternalOrganizations(
		params?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<OrganizationSecurityApi>>> {
		return restSecurityApi.get("/security/me/organizations", { params });
	}

	static getExternalOrganization(
		companyId: string,
		params?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<OrganizationSecurityApi>>> {
		return parseApiData(restSecurityApi.get(`/security/me/organizations/${companyId}`, { params }));
	}

	static getUserInvitations(
		params?: Record<string, any>
	): Promise<AxiosResponse<ListResponse<InvitationSecurityApi>>> {
		return restSecurityApi.get(`/security/me/invitations`, { params });
	}

	static acceptUserInvitation(invitationId: number): Promise<AxiosResponse> {
		return restSecurityApi.post(`/security/me/invitations/${invitationId}/accept`);
	}

	static rejectUserInvitation(invitationId: number): Promise<AxiosResponse> {
		return restSecurityApi.post(`/security/me/invitations/${invitationId}/reject`);
	}
	static sendVerifyEmail(): Promise<AxiosResponse> {
		return restSecurityApi.post(`/security/me/send_verify_email`);
	}
	static acceptTerms(data: AcceptUserTermsSecurityApi): Promise<AxiosResponse> {
		return restSecurityApi.post(`/security/me/accept_terms`, data);
	}
	static getOrganizationsSearchSelect(
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<OrganizationItem[]> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		return parseApiData(
			restSecurityApi.get(`/security/me/organizations/search_select?f=${f}&page=${page}`, {
				cancelToken: options?.cancelToken,
			})
		);
	}
}

export { apiUser };
