import * as H from "history";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { listDataParamsDefault } from "./decoder";
import { ReportConfig, ReportParamsType } from "./types";

const listDataParamsQuery = (params: ReportParamsType) => {
	return `?${new URLSearchParams(listDataParamsDefault(params)).toString()}`;
};
export const reportDataPushHistorySearch = (
	history: H.History,
	params: ReportParamsType,
	config: ReportConfig,
	userRemovedFilter?: boolean
): void => {
	if (history !== undefined && filtersStateChanged(params, config, userRemovedFilter)) {
		history.push({ search: listDataParamsQuery(params) });
	}
};

const filtersStateChanged = (params: ReportParamsType, config: ReportConfig, userRemovedFilter?: boolean) => {
	const initFiltersChanged =
		params.filters?.map((x) => x.value).join(FILTER_VALUE_SEPARATOR) !==
		config.externalSelectedFilters?.map((x) => x.value).join(FILTER_VALUE_SEPARATOR);
	if (
		userRemovedFilter ||
		initFiltersChanged ||
		params.selectedSegment ||
		params.columns?.join(",") !== config.selectedColumns?.join(",") ||
		params.groups !== config.selectedGroups ||
		params.groupColumn !== undefined ||
		params.sort !== config.sort
	) {
		return true;
	}
	return false;
};
