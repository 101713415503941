import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import classNames from "classnames";
import equal from "deep-equal";
import { Button, Collapse } from "react-bootstrap";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import useFlash, { handleErrorForAlert } from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { formatDateTimeToString, formatStringToDate } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import List from "go-list/core";
import MobileFilters from "go-list/core/components/Filter/components/MobileFilters/MobileFilters";
import { FILTER_SEPARATOR, FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { listFiltersEncode } from "go-list/core/components/Filter/services/decoder";
import {
	FilterCustomAction,
	FilterCustomDateRange,
	ListSelectedFilter,
} from "go-list/core/components/Filter/services/types";
import { isPredefinedRange, parsePredefinedRangeToDefaultString } from "go-list/utils/daterangeutils";
import {
	selectActiveSegment,
	selectFilters,
	selectGroups,
	selectSelectedColumns,
	selectSelectedFilters,
	selectSelectedSort,
	selectShowPercent,
} from "go-report/reports/services/selectors";
import Segment from "go-segment/components";
import Report from "../core";
import { ReportApi } from "../core/services/types";
import { isComparable } from "../core/services/utils";
import { listDataParams } from "./services/decoder";
import { listDataFetch } from "./services/fetch-data";
import { reportDataPushHistorySearch } from "./services/push-history";
import { ReportParamsReducer, reportParamsInitialState } from "./services/reducer";
import { encodeSegmentValue, isSegmentChanged, paramsToSegment, segmentToParams } from "./services/segment-service";
import { GoReportSegmentType, ReportChartConfig, ReportConfig, ReportParamsType } from "./services/types";

interface State {
	config: ReportConfig;
}

const ReportData = (props: State): JSX.Element => {
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const history = useHistory();
	const location = useLocation();
	const config = props.config;
	const firstUpdate = useRef(true);
	const [openFilers, setOpenFilters] = useState(true);
	const [segmentModal, setSegmentModal] = useState(false);
	const [state, dispatch] = useReducer(ReportParamsReducer, reportParamsInitialState(config, location));
	const [comparable, setComparable] = useState(false);
	const [reportData, setReportData] = useState<ReportApi>();
	const [chartData, setChartData] = useState<ReportApi>();
	const [loading, setLoading] = useState(true);
	const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
	const [chartType, setChartType] = useState(
		config.chartType ? config.chartType : config?.chart?.type ? config.chart.type : "HOUR_OF_DAY"
	);
	const segment = selectActiveSegment(state);
	const selectedFilters = selectSelectedFilters(state, config.filterValues);
	const selectedColumns = selectSelectedColumns(state);
	const selectedSort = selectSelectedSort(state);
	const filters = selectFilters(config);
	const groups = selectGroups(config);
	const showPercent = selectShowPercent(state);
	const initialDataEmpty = useRef(true);
	const selectedGroups = state.groups ? state.groups : [];
	const groupColumn = state.groupColumn;
	const [compareRangePreviousDates, setCompareRangePreviousDates] = useState<[Date | undefined, Date | undefined]>([
		undefined,
		undefined,
	]);
	const userRemovedFilter = useRef<boolean>(false);
	const isMobile = useWindowSize().isMobile;
	const [showMobileFilters, setShowMobileFilters] = useState(false);

	useEffect(() => {
		if (isMobile && showMobileFilters) {
			setShowMobileFilters(false);
		}
	}, [isMobile]);

	useEffect(() => {
		if (!firstUpdate.current) return;
		const dateRangeFilter = selectedFilters.find((filter) => filter.filterId === "date_range");

		if (dateRangeFilter) {
			const dateRange = isPredefinedRange(dateRangeFilter.value)
				? parsePredefinedRangeToDefaultString(dateRangeFilter.value)
				: dateRangeFilter.value;
			rescheduleDates(dateRange);
		}
	}, [selectedFilters]);

	let chartConfig = props.config.chart;
	if (!chartConfig && config.chartType) {
		let chartGroups = undefined as any;
		if (config.customDropdownOptions) {
			chartGroups = {};
			config.customDropdownOptions.forEach((x) => {
				chartGroups[x] = {
					id: x,
				};
			});
		}
		chartConfig = {
			type: config.chartType,
			title: config.chartTitle ? config.chartTitle : "Chart",
			lineTitle: config.chartLineTitle,
			aggregate: config.chartAggregate ? config.chartAggregate : "sales",
			groups: chartGroups,
		} as ReportChartConfig;
	}

	const onSort = (sort: string) => {
		dispatch({ type: "setSort", sort });
	};

	const fetchCancellation = async (params: Record<string, any>) => {
		if (config?.fetch === undefined) return undefined;
		const CancelToken = axios.CancelToken;
		if (cancelTokenSource !== undefined) cancelTokenSource.cancel();
		const newCancelToken = CancelToken.source();
		setCancelTokenSource(newCancelToken);
		return config?.fetch(params, newCancelToken);
	};

	const updateListParamsFromSegment = (newState: ReportParamsType) => {
		if (!newState.filters && newState.selectedSegment) {
			const matchedSegment = state.segments?.find((f) => f.slug === newState.selectedSegment);
			if (matchedSegment && matchedSegment.filters) {
				const f = listFiltersEncode(matchedSegment.filters, true);
				newState = { ...newState, f };
			}
		}
		return newState;
	};

	const parseChartType = (group: string) => {
		if (chartConfig && chartConfig.parseGroup) return chartConfig.parseGroup(group);
		return group;
	};

	const fetch = (params: ReportParamsType) => {
		setLoading(true);
		params.chartType = parseChartType(chartType);
		params = updateListParamsFromSegment(params);

		listDataFetch(params, fetchCancellation)
			.then((res) => {
				unstable_batchedUpdates(() => {
					setLoading(false);
					setComparable(isComparable(selectedFilters));
					setReportData(res[0].data.data ? res[0].data.data : res[0].data);
					if (res[1]) {
						const newChartData = res[1].data.data ? res[1].data.data : res[1].data;

						if (!equal(chartData, newChartData)) {
							setChartData(newChartData);
						}
					}
					if (initialDataEmpty.current) {
						initialDataEmpty.current = false;
					}
				});
			})
			.catch((err) => {
				const mockData = {
					reports: [
						{
							group_by_type: "NONE",
							aggregate: {},
							compare_aggregate: {},
							sub_report: [],
						},
					],
				} as any;
				setReportData(mockData);
				if (err?.code !== "ERR_CANCELED") setLoading(false);
				handleErrorForAlert(err, addFlash);
			});
	};

	const getFilterWithValues = (): ListSelectedFilter[] => {
		const filters: ListSelectedFilter[] = [];
		const currentFilters = !state.filters && segment?.filters ? segment.filters : state.filters;
		currentFilters?.forEach((filter) => {
			if (filter.condition === "a" || filter.condition === "u") {
				filters.push(filter);
			} else if (filter.value) {
				filters.push(filter);
			}
		});
		return filters;
	};

	const usedFilters = getFilterWithValues()
		.map((x) => {
			if (x.condition === "a" || x.condition === "u") {
				return x.condition;
			}
			if (x.value) {
				return `${x.condition}${x.value}`;
			}
			return "";
		})
		.join(FILTER_SEPARATOR);

	useEffect(() => {
		fetch(state);
		if (firstUpdate.current) {
			firstUpdate.current = false;
		} else if (history) {
			reportDataPushHistorySearch(history, state, config, userRemovedFilter.current);
			userRemovedFilter.current = false;
		}
	}, [usedFilters, segment, state.selectedSegment, state.groups, state.groupColumn]);

	useEffect(() => {
		if (history) reportDataPushHistorySearch(history, state, config);
	}, [state.columns, selectedColumns.join(",")]);

	const onChangeFilters = (newFilters: ListSelectedFilter[], didRemoveFilter = false) => {
		const dateFilter = newFilters.find((f) => f.filterId === "date_range");
		const dateFilterFromConfig = config.filters?.find((filter) => filter.id === "date_range");

		if (dateFilter && !dateFilterFromConfig?.doNotSaveFilterValueToLocalStorage) {
			window.localStorage.setItem("go_report.filters.date_range", `${dateFilter.condition}=${dateFilter.value}`);
		}

		const organizationFilter = newFilters.find((f) => f.filterId === "organization_ids");
		if (organizationFilter?.value && organizationFilter.value !== "a" && organizationFilter.value !== "u") {
			window.localStorage.setItem("go_report.filters.organization_ids", organizationFilter.value);
		}
		if (didRemoveFilter) userRemovedFilter.current = true;

		dispatch({ type: "setFilters", filters: newFilters });
	};

	const onShowSaveSegment = () => {
		setSegmentModal(true);
	};
	const onHideSegmentModal = () => {
		setSegmentModal(false);
	};

	const saveSegment = async (
		segment: GoReportSegmentType,
		segmentFilters?: ListSelectedFilter[],
		preventUpdateSegment?: boolean
	) => {
		if (config.saveSegment) {
			const newSegment = {
				...segment,
				filters: segmentFilters
					? selectSelectedFilters({ ...state, filters: [...segmentFilters] })
					: selectSelectedFilters(state),
				columns: selectedColumns,
				groups: selectedGroups,
				groupColumn,
				sort: selectedSort,
				position: segment.position !== undefined ? segment.position : (state.segments || []).length - 1,
				default: segment.default !== undefined ? segment.default : false,
			};
			try {
				const dataSegment = await config.saveSegment(segmentToParams(newSegment));
				dispatch({
					type: "updateSegment",
					data: {
						segment: paramsToSegment(dataSegment.data.data),
						preventReturnUpdatedSegment: preventUpdateSegment,
					},
				});
				setSegmentModal(false);
				return dataSegment.data.data;
			} catch (e) {
				return handleException(e);
			}
		}
	};
	const onSaveSegment = (
		segment: GoReportSegmentType,
		segmentFilters?: ListSelectedFilter[],
		preventUpdateSegment?: boolean
	) => {
		return saveSegment(segment, segmentFilters, preventUpdateSegment);
	};
	const onCreateSegment = async (
		name: string,
		segmentFilters?: ListSelectedFilter[],
		preventUpdateSegment?: boolean
	) => {
		const params = {
			name,
			filters: segmentFilters
				? selectSelectedFilters({ ...state, filters: [...segmentFilters] })
				: selectSelectedFilters(state),
			columns: selectedColumns,
			groups: selectedGroups,
			groupColumn,
			sort: selectedSort,
			default: false,
			position: (state.segments || []).length - 1,
		} as GoReportSegmentType;
		try {
			return await saveSegment(params, segmentFilters, preventUpdateSegment);
		} catch (e) {
			return handleException(e);
		}
	};
	const onChangeSegment = (segment: GoReportSegmentType) => {
		dispatch({ type: "setSegment", segment: segment.slug });
	};

	const onChangedSelectedColumn = (selectedColumns: Array<string>, sortedColumns: Array<string>) => {
		const newSelectedColumns = [...selectedColumns];
		if (showPercent) newSelectedColumns.push("show_percentage");
		else newSelectedColumns.push("-show_percentage");

		window.localStorage.setItem(`go_report.${config.reportConfigId}.columns`, newSelectedColumns.join(","));
		dispatch({ type: "setColumns", columns: selectedColumns });
	};

	const onShowPercentChange = () => {
		const currentSelectedColumnsAsString = localStorage.getItem(`go_report.${config.reportConfigId}.columns`);
		const currentSelectedColumns = currentSelectedColumnsAsString ? currentSelectedColumnsAsString.split(",") : [];
		const userDeselectedShowPercentage = state.showPercent === true;
		let newSelectedColumns: string[] = [];

		if (
			userDeselectedShowPercentage &&
			currentSelectedColumns &&
			currentSelectedColumns.length > 0 &&
			currentSelectedColumns.includes("show_percentage")
		) {
			newSelectedColumns = currentSelectedColumns.map((column) => {
				if (!column.includes("show_percentage")) return column;
				return "-show_percentage";
			});
		}
		if (
			!userDeselectedShowPercentage &&
			currentSelectedColumns &&
			!currentSelectedColumns.includes("show_percentage")
		) {
			const deselectedOptionFilteredOutArray = currentSelectedColumns.filter(
				(column) => column !== "-show_percentage"
			);
			newSelectedColumns = [...deselectedOptionFilteredOutArray, "show_percentage"];
		}

		localStorage.setItem(`go_report.${config.reportConfigId}.columns`, newSelectedColumns.join(","));
		dispatch({ type: "setShowPercent", showPercent: !showPercent });
	};

	const onChangeGroups = (checkedGroups: Array<string>) => {
		dispatch({ type: "setGroups", groups: checkedGroups });
	};

	const onChangeGroupColumn = (groupColumn: string | undefined) => {
		dispatch({ type: "setGroupColumn", groupColumn });
	};

	const onChangeChartType = (type: string, token?: CancelTokenSource): Promise<AxiosResponse> => {
		let params = state;
		setChartType(type);
		if (!params.filters && params.selectedSegment) {
			const matchedSegment = state.segments?.find((f) => f.slug === params.selectedSegment);
			if (matchedSegment) {
				const f = encodeSegmentValue(matchedSegment);
				params = { ...params, f };
			}
		}
		params = { ...params, chartType: parseChartType(type) };
		if (config.fetchChartData) {
			return config.fetchChartData(listDataParams(params), token);
		}
		return Promise.reject();
	};

	const renderChartData = useMemo(() => {
		const data =
			chartData &&
			chartData.reports &&
			chartData.reports.length &&
			chartData.reports[0] &&
			chartData.reports[0].sub_report.length > 0
				? chartData
				: ({
						reports: [
							{
								group_by_type: "NONE",
								aggregate: {},
								sub_report: [],
							},
						],
				  } as any);
		if (chartConfig && data && data.reports && data.reports.length > 0) {
			return (
				<Report.ReportChart
					comparable={isComparable(selectedFilters)}
					groupHistogram={config.type === "bill"}
					data={data}
					defaultGroup={config.defaultGroup}
					aggregate={chartConfig.aggregate}
					selectedFilters={selectedFilters}
					title={chartConfig.title}
					chartType={chartConfig.type}
					chartLineTitle={chartConfig.lineTitle}
					aggregatePrefix={config.aggregatePrefix ? config.aggregatePrefix : "sales"}
					groups={chartConfig.groups}
					customLocalStorageKey={config.customLocalStorageKey}
					onChangeType={onChangeChartType}
				/>
			);
		}
		return <></>;
	}, [usedFilters, chartData, initialDataEmpty.current]);

	const rescheduleDates = (dateString: string) => {
		const [firstDateStr, secondDateStr] = dateString.split(FILTER_VALUE_SEPARATOR);

		const firstDate = formatStringToDate(firstDateStr);
		const secondDate = formatStringToDate(secondDateStr);

		const dayInMilliseconds = 24 * 60 * 60 * 1000;
		let difference = secondDate.getTime() - firstDate.getTime();
		difference = difference === 0 ? dayInMilliseconds : difference;

		const rescheduledFirstDate = new Date(firstDate.getTime() - difference);
		const rescheduledSecondDate = new Date(firstDate.getTime());
		setCompareRangePreviousDates([rescheduledFirstDate, rescheduledSecondDate]);
		const rescheduledFirstDateStr = formatDateTimeToString(rescheduledFirstDate);
		const rescheduledSecondDateStr = formatDateTimeToString(rescheduledSecondDate);
		return `${rescheduledFirstDateStr}${FILTER_VALUE_SEPARATOR}${rescheduledSecondDateStr}`;
	};

	const handleClickCompareButton = () => {
		const compareFilterId = "compare_date_range";
		const isCompareFilterPresent = selectedFilters.some((filter) => filter.filterId === compareFilterId);
		const dateRangeFilterId = "date_range";
		const dateValue = selectedFilters.find((filter) => filter.filterId === dateRangeFilterId)!.value;
		const dateRange = isPredefinedRange(dateValue) ? parsePredefinedRangeToDefaultString(dateValue) : dateValue;
		const prevValue = rescheduleDates(dateRange);

		if (isCompareFilterPresent) {
			const filterIndex = selectedFilters.findIndex((filter) => filter.filterId === compareFilterId);
			selectedFilters[filterIndex]!.value = prevValue;
			dispatch({
				type: "setFilters",
				filters: [...selectedFilters],
			});
			const filterElement = document.getElementById(`${compareFilterId}_${filterIndex}`);
			if (filterElement) filterElement.click();
		} else {
			dispatch({
				type: "setFilters",
				filters: [
					...selectedFilters,
					{
						condition: "bt",
						filterId: compareFilterId,
						value: prevValue,
						valueInfo: {},
					},
				],
			});
			setTimeout(() => {
				const filterElement = document.getElementById(`${compareFilterId}_${selectedFilters.length}`);
				if (filterElement) filterElement.click();
			}, 10);
		}
	};

	const dateRangeCustomActions: FilterCustomAction[] = [];
	const dateRangeCustomRanges: FilterCustomDateRange[] = [];
	if (config.withCompareDateRange) {
		dateRangeCustomActions.push({
			filterId: "date_range",
			button: (
				<Button variant="light" className="ms-2" onClick={handleClickCompareButton}>
					{t("lib:go_report.action.compare")}
				</Button>
			),
		});
		const [startDate, endDate] = compareRangePreviousDates;
		if (startDate && endDate) {
			dateRangeCustomRanges.push(
				{
					name: "go_report.action.compare",
					range: {
						key: "selection",
						startDate,
						endDate,
					},
					filterId: "compare_date_range",
					checked: false,
				},
				{
					checked: false,
					filterId: "compare_date_range",
					name: "go_form.date_range_picker.ranges.custom",
					range: {
						key: "selection",
					},
				}
			);
		}
	}

	if (!reportData) {
		return <LoadingContainer />;
	}

	return (
		<Report>
			{!isMobile && (
				<>
					<div className={`list-actions ${isMobile ? "mt-3" : "mt-0"}`}>
						{state.segments && (
							<Segment.List
								selected={state.selectedSegment}
								segments={state.segments}
								onChange={onChangeSegment}
							/>
						)}
						<Button
							variant={classNames("", { primary: openFilers })}
							className={"filter-list-collapse"}
							onClick={() => setOpenFilters(!openFilers)}
							aria-controls="filter-list-collapse"
							aria-expanded={openFilers}
						>
							{selectedFilters && selectedFilters.length > 0
								? `${selectedFilters.length} ${t("lib:common.word.filters")}`
								: `${t("lib:common.word.filters")}`}
						</Button>

						{isSegmentChanged(state, config, segment) && config.saveSegment && (
							<Segment.Save onClick={onShowSaveSegment} />
						)}
					</div>
					{segmentModal && (
						<Segment.SaveModal
							segment={segment ? segment : { id: "all", slug: "all", name: "" }}
							onSave={onSaveSegment}
							onCreate={onCreateSegment}
							onHide={onHideSegmentModal}
						/>
					)}
				</>
			)}
			{filters &&
				(isMobile ? (
					<MobileFilters
						filters={filters}
						selectedFilters={selectedFilters}
						onChangeFilters={onChangeFilters}
						setShowMobileFilters={setShowMobileFilters}
						showMobileFilters={showMobileFilters}
						minDate={new Date("2015")}
						maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
						dateRangePickerCustomActions={dateRangeCustomActions}
						dateRangePickerCustomRanges={dateRangeCustomRanges}
						onChangeSegment={(segment) => onChangeSegment(segment as GoReportSegmentType)}
						segments={state.segments}
						hasConfigSaveSegment={!!config.saveSegment}
						segmentModal={segmentModal}
						segment={segment}
						setSegmentModal={setSegmentModal}
						onCreateSegment={(
							name,
							segmentFilters,
							segmentColumns,
							segmentAllColumnsInOrder,
							segmentStickyColumnsDividerPosition,
							preventUpdateSegment
						) => onCreateSegment(name, segmentFilters, preventUpdateSegment)}
						onSaveSegment={(
							segment,
							segmentFilters,
							segmentColumns,
							segmentAllColumnsInOrder,
							segmentStickyColumnsDividerPosition,
							preventUpdateSegment
						) => saveSegment(segment as GoReportSegmentType, segmentFilters, preventUpdateSegment)}
						isReport
						defaultGroups={state?.groups}
						defaultGroupColumn={state?.groupColumn}
						groupsFromConfig={config?.selectedGroups}
						defaultSelectedColumns={config?.selectedColumns}
						stateColumns={state?.columns}
						defaultSort={state?.sort}
					/>
				) : (
					<Collapse in={openFilers}>
						<div id="filter-list-collapse" className={"filters-list"}>
							<List.Filters
								filters={filters}
								selectedFilters={selectedFilters}
								onChange={onChangeFilters}
								minDate={new Date("2015")}
								maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
								dateRangePickerCustomActions={dateRangeCustomActions}
								dateRangePickerCustomRanges={dateRangeCustomRanges}
							/>
						</div>
					</Collapse>
				))}
			{renderChartData}
			{chartData &&
			chartData?.reports &&
			chartData?.reports.length > 0 &&
			chartData.reports[0] &&
			chartData.reports[0].sub_report.length > 0 ? (
				<div className="separator" />
			) : (
				<div className={"mb-4"} />
			)}
			{reportData && (
				<Report.ReportTable
					data={reportData}
					columns={config.columns}
					isGroupTable={Boolean(groupColumn)}
					selectedColumns={selectedColumns}
					aggregatePrefix={config.aggregatePrefix}
					onChangeGroupColumn={onChangeGroupColumn}
					comparable={comparable}
					loading={loading}
					selectedFilters={selectedFilters}
					exportConfig={config.exportConfig}
					groups={groups}
					selectedGroups={selectedGroups}
					groupColumn={groupColumn}
					onChangeGroups={onChangeGroups}
					groupsInfo={config.groupsInfo}
					selectedSegment={segment}
					filtersConfig={config.filters ? config.filters : []}
					onChangeSelectedColumn={onChangedSelectedColumn}
					showPercent={showPercent}
					selectedSort={selectedSort}
					onSort={onSort}
					onShowPercentChange={onShowPercentChange}
					state={state}
				/>
			)}
		</Report>
	);
};
export default ReportData;
