import React, { useContext } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useWindowSize } from "go-core/components/useWindowSize";
import { OrdersContext } from "../../services/context";
import { orderStatusOptions } from "../../services/orderFilters";
import { getActiveOrders } from "../../services/orderStatus";
import { LiveOrderFilterOption, LiveOrdersFilters } from "../../services/types";
import { inputCustomStyles } from "./utils";

interface Props {
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
	insideModal?: boolean;
}

const LiveOrdersStatusFilter = ({ setInnerFilters, innerFilters, insideModal }: Props): JSX.Element => {
	const { filteredOrdersWithoutFilterKeys, filters, setFilters } = useContext(OrdersContext);
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const formatOptionLabel = (status: LiveOrderFilterOption) => {
		return `${status.label} (${getOrdersAmountForOption(status.id)})`;
	};

	const getOrdersAmountForOption = (statusId: string) => {
		const orders = filteredOrdersWithoutFilterKeys(["status"]);
		if (statusId === "ALL") return orders.length;
		if (statusId === "ACTIVE") return getActiveOrders(orders).length;
		if (statusId === "EXTERNAL") return orders.filter((order) => order.status === "EXTERNAL").length;
		return orders?.filter((order) => order?.preparation_status?.status === statusId)?.length || 0;
	};

	const handleOnChange = (status: LiveOrderFilterOption) => {
		if (insideModal) {
			setInnerFilters({
				...innerFilters,
				status,
			});
		} else {
			setFilters({
				...filters,
				status,
			});
		}
	};

	return isMobile ? (
		<div className="mb-3">
			<Form.Label>{t("modules.live_order.field.show_orders_by_status.title")}</Form.Label>
			<Select
				styles={inputCustomStyles}
				className="form-select"
				value={innerFilters.status}
				defaultValue={innerFilters.status}
				onChange={(opt) => handleOnChange(opt as LiveOrderFilterOption)}
				getOptionLabel={(opt) => opt.label}
				formatOptionLabel={(opt) => formatOptionLabel(opt)}
				getOptionValue={(opt) => opt.id}
				options={orderStatusOptions(t)}
			/>
		</div>
	) : (
		<Dropdown className="ms-2">
			<Dropdown.Toggle variant="light">{formatOptionLabel(filters.status)}</Dropdown.Toggle>
			<Dropdown.Menu>
				{orderStatusOptions(t).map((status) => (
					<Dropdown.Item key={status.id} onClick={() => handleOnChange(status)}>
						{formatOptionLabel(status)}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default LiveOrdersStatusFilter;
