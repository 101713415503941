import { parseApiData } from "go-core/api/parseApiData";
import { restSecurityApi } from "../api";
import { OrderData } from "./types";

class apiPublic {
	static getOrderData = (orderId: string): Promise<OrderData | undefined> => {
		return parseApiData(restSecurityApi.get(`/orders/${orderId}`));
	};

	static sendEmail = (orderId: string, email: string): Promise<void> => {
		return restSecurityApi.post(`/orders/${orderId}/send_public_link_via_mail`, { email });
	};

	static getEReceipt = (orderId: string) => {
		return restSecurityApi.get(`/orders/${orderId}/ereceipt`, { responseType: "blob" });
	};
}

export { apiPublic };
