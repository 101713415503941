import * as Sentry from "@sentry/react";

export const ignoreErrors = [
	/ResizeObserver loop/i,
	/nexusWebSocket/i,
	/Failed to execute 'insertBefore' on 'Node':/i,
	"The object can not be found here.",
	"Unauthorized",
	"Cannot read properties of null (reading 'tabIndex')",
	"Action Forbidden",
	"Cannot read property 'init' of undefined",
	/Failed to execute 'removeChild' on 'Node'/i,
	/window.GRV2.init/i,
	"Invalid LatLng object: (undefined, undefined)",
	"Not found.",
	"Request failed with status code 401",
	"Cannot read properties of null (reading 'getLabelAndValue')",
	/(evaluating 'a.L')/i,
	"Cannot redefine property: plugins",
	"UnprocessableEntity",
	"Node.removeChild: The node to be removed is not a child of this node",
	/_._DumpException/i,
	"Invalid interval",
	"Action Forbidden",
	"BadRequest",
	/gCrWeb/i,
	"element is not defined",
	/Failed to read the 'contentDocument' property from 'HTMLIFrameElement':/i,
	"Can't find variable: structuredClone",
	"AbortController is not defined",
	"undefined is not an object (evaluating 't.pathname')",
	"Object.fromEntries is not a function",
	"Can't find variable: OnSavedResultCb",
	"The operation is insecure.",
	"Object captured as promise rejection with keys: data",
	"Non-Error promise rejection captured with value: Not implemented on this platform",
	"Cannot read properties of undefined (reading '_leaflet_pos')",
	"parent is null",
	"undefined is not an object (evaluating 's[e.responseRequestID].functions')",
	"Can't find variable: ResizeObserver",
	"Cannot read properties of null (reading 'endpoint')",
	"bb",
	"cb",
	"Cannot read properties of undefined (reading 'clearRect')",
	"Failed to fetch",
	"Load failed",
	"NetworkError when attempting to fetch resource.",
	"Network Error",
	"Unknown",
	"jb",
	"TooManyRequestsException",
	"BadRequestException",
	"UnprocessableEntity",
	"UnauthorizedException",
	"ForbiddenException",
	"NotFoundException",
	"NotAllowedException",
	"OrganizationAccessException",
];

export const beforeSend = (event: Sentry.ErrorEvent) => {
	const newEvent = { ...event };
	const eventBreadcrumsIncludeStatus0 = newEvent.breadcrumbs?.some((breadCrumb) => breadCrumb.data?.statusCode === 0);

	if (eventBreadcrumsIncludeStatus0 && newEvent.exception && Array.isArray(newEvent.exception?.values)) {
		newEvent.exception.values = newEvent.exception.values.map((value) => ({
			...value,
			value: `${value.value} [ Request status 0 ]`,
		}));
	}

	return newEvent;
};
