import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import EmptyData from "go-core/components/EmptyData";
import { FormatDuration } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import RenderPaymentMethod from "../../../../../../../../../../../components/Common/Renderers/RenderPaymentMethod";
import { PosReportEmployeeApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	employee: PosReportEmployeeApi;
	reportStatus: string;
	reportId: number;
	isDeliveryEmployee?: boolean;
}

const EmployeeCard: FC<Props> = ({ employee, reportStatus, reportId, isDeliveryEmployee }) => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();

	const tdStyles = {
		padding: "14px 0px",
	};

	const linkToTransaction = () => {
		return isDeliveryEmployee
			? `/${organization.id}/sales/orders?f=${btoa(
					unescape(
						encodeURIComponent(
							`${NEW_WAY_TO_ENCODING_FILTER_SIGN}delivery_employee|e=${employee.id}${FILTER_SEPARATOR}report|e=${reportId}`
						)
					)
			  )}`
			: `/${organization.id}/sales/transactions?f=${btoa(
					unescape(
						encodeURIComponent(
							`${NEW_WAY_TO_ENCODING_FILTER_SIGN}employee|e=${employee.id}${FILTER_SEPARATOR}report|e=${reportId}`
						)
					)
			  )}`;
	};

	const isTransactionLinkVisible = () => {
		if (employee.payment_methods.length > 0) {
			if (isDeliveryEmployee) {
				return !isNaN(Number(employee.id));
			}
			return true;
		}
		return false;
	};

	return (
		<Card>
			<Card.Body>
				<div className="content-preview-header card-content-preview-header">
					<RenderImage variant={"md"} imageLink={employee.avatar_link} showColor={true} data={employee} />
					<div className="header-content">
						<div className="title">
							<h1 style={{ fontSize: "16px" }}>
								<Link
									target={"_blank"}
									rel={"noreferrer"}
									to={`/${organization.id}/employees/${employee.id}`}
								>
									{employee.name}
								</Link>
							</h1>
						</div>
						{employee.role && <div className={"info"}>{employee.role.role_name}</div>}
						<div className={"info"}>
							<span style={{ fontSize: "12px", fontWeight: 400 }} className={"badge bg-primary"}>
								{FormatMoney(employee.income_amount)} (
								{t("modules.pos_report.field.order_count.title", { count: employee.order_count })})
							</span>
						</div>
					</div>
				</div>
				<div className={"content-preview-body"}>
					{employee.payment_methods.length > 0 ? (
						<>
							{isTransactionLinkVisible() && (
								<div className={"d-flex"}>
									<div className={"w-100 pr-1"}>
										<Link
											to={linkToTransaction}
											className={"btn btn-add w-100"}
											target={"_blank"}
											rel={"noreferrer"}
										>
											{t("modules.pos_report.action.show_transactions.title")}
										</Link>
									</div>
								</div>
							)}
							<div className={"table-responsive"}>
								<table
									className={"table table-preview mb-4"}
									style={{ borderBottom: "1px solid #dee2e6" }}
								>
									<thead>
										<tr>
											<th>{t("lib:common.word.name")}</th>
											<th>{t("common.word.quantity")}</th>
											<th>{t("common.word.amount")}</th>
										</tr>
									</thead>
									<tbody>
										{employee.payment_methods.map((method, index) => {
											return (
												<tr key={index}>
													<td style={tdStyles}>
														<RenderPaymentMethod
															name={method.name}
															type={method.receipt_type}
														/>
													</td>
													<td>x{method.count}</td>
													<td className={"nowrap"}>{FormatMoney(method.amount)}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</>
					) : (
						<EmptyData
							title={t("modules.pos_report.field.payments_empty.title")}
							styles={{
								background: "#F5F8FA",
								marginBottom: "8px",
							}}
						/>
					)}
					<div className={"mb-2"}>
						<strong>{t("common.word.summary", { ns: "lib" })}</strong>
					</div>
					<div style={{ color: "#6C757D" }}>
						<div className={"d-flex mb-2"}>
							<span>{t("modules.pos_report.field.total_amount.title")}</span>
							<span className={"ms-auto"}>{FormatMoney(employee.income_amount)}</span>
						</div>
						<div className={"d-flex mb-2"}>
							<span>{t("modules.pos_report.field.order_count.title")}</span>
							<span className={"ms-auto"}>{employee.order_count}</span>
						</div>
						{reportStatus === "OPENED" && (
							<>
								<div className={"d-flex mb-2"}>
									<span>{t("modules.pos_report.field.open_order_count.title")}</span>
									<span className={"ms-auto"}>{employee.open_order_count}</span>
								</div>
								<div className={"d-flex mb-2"}>
									<span>{t("modules.pos_report.field.open_order_amount.title")}</span>
									<span className={"ms-auto"}>{FormatMoney(employee.open_order_amount)}</span>
								</div>
							</>
						)}
						<div className={"d-flex mb-2"}>
							<span>{t("modules.pos_report.field.bilance.title")}</span>
							<span className={"ms-auto"}>{FormatMoney(employee.report_paid_amount)}</span>
						</div>
						<div className={"d-flex mb-2"}>
							<span>{t("modules.pos_report.field.worked.title")}</span>
							<span className={"ms-auto"}>
								{FormatDuration(employee.work_time_duration_in_minutes, t)}
							</span>
						</div>
						<div className={"d-flex mb-4"}>
							<span>{t("modules.pos_report.field.to_pay.title")}</span>
							<span className={"ms-auto"}>{FormatMoney(employee.to_pay)}</span>
						</div>
					</div>
					<div className={"mb-2"}>
						<strong>{t("modules.pos_report.field.final_sum.title")}</strong>
					</div>
					<div className={"d-flex"} style={{ color: "#6C757D" }}>
						<span>{organization.currency}</span>
						<span className={"ms-auto"}>{FormatMoney(employee.end_amount)}</span>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};
export default EmployeeCard;
