import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoListSegmentType } from "../../../list/services/types";
import { ListConfigField } from "../../services/types";
import { SortableColumnsList } from "./components/SortableColumnsList";
import { SortableColumnsListWithDivider } from "./components/SortableColumnsListWithDivider";
import { useColumnsList, useNewColumnsAutoSelect, useSegmentsColumns } from "./hooks";
import { listDividerId } from "./services/consts";
import { ListConfigColumn } from "./services/types";
import { listColumnSort } from "./utils";

interface Props {
	columnsFields?: ListConfigColumn[];
	customFields?: ListConfigField[];
	selectedColumns: string[];
	onChange: (selectedColumns: string[], sortedColumns: string[]) => void;
	segment?: GoListSegmentType;
	shouldDisableSortingOfStickyColumns?: boolean;
	numberOfActiveStickyColumns: number;
	setNumberOfActiveStickyColumns: React.Dispatch<React.SetStateAction<number>>;
	isNumberOfStickyColumnsDynamic?: boolean;
	stickyColumnsDividerPosition: React.MutableRefObject<number>;
	allColumnsInOrder: React.MutableRefObject<ListConfigColumn[]>;
	onChangeColumnsOrder: (columns: string[]) => void;
	onChangeStickyColumnsDividerPosition: (dividerPosition: number) => void;
}

const ListColumn = ({
	columnsFields,
	customFields,
	onChange,
	selectedColumns,
	segment,
	shouldDisableSortingOfStickyColumns,
	numberOfActiveStickyColumns,
	setNumberOfActiveStickyColumns,
	isNumberOfStickyColumnsDynamic,
	stickyColumnsDividerPosition,
	allColumnsInOrder,
	onChangeColumnsOrder,
	onChangeStickyColumnsDividerPosition,
}: Props): JSX.Element => {
	const { t } = useTranslation();

	const updateAndReturnNewSortedColumns = (columnsList: ListConfigColumn[]) => {
		if (shouldDisableSortingOfStickyColumns) {
			const newColumns = [
				...allColumnsInOrder.current.slice(0, stickyColumnsDividerPosition.current),
				...columnsList,
			];
			updateSortedNonStickyColumns(columnsList);

			return newColumns;
		}

		if (!isNumberOfStickyColumnsDynamic) {
			updateSortedStickyColumns(columnsList.slice(0, stickyColumnsDividerPosition.current));
			updateSortedNonStickyColumns(columnsList.slice(stickyColumnsDividerPosition.current));

			return columnsList;
		}

		const indexOfDivider = columnsList.findIndex((column) => column.id === listDividerId);
		const stickyColumns = columnsList.slice(0, indexOfDivider);
		const nonStickyColumns = columnsList.slice(indexOfDivider + 1);

		updateSortedStickyColumns(stickyColumns);
		updateSortedNonStickyColumns(nonStickyColumns);
		stickyColumnsDividerPosition.current = stickyColumns.length;

		return [...stickyColumns, ...nonStickyColumns];
	};

	const onSortedList = (list: ListConfigColumn[]) => {
		const newSortedColumns = updateAndReturnNewSortedColumns(list);

		onChange(
			listColumnSort(
				selectedColumns,
				newSortedColumns.map((column) => column.id)
			),
			newSortedColumns.map((column) => column.id)
		);
	};

	const onChangeSelected = (columnId: string) => {
		if (selectedColumns.includes(columnId)) {
			return onChange(
				selectedColumns.filter((column) => column !== columnId),
				allColumnsInOrder.current.map((column) => column.id)
			);
		}

		onChange(
			[...selectedColumns, columnId],
			allColumnsInOrder.current.map((column) => column.id)
		);
	};

	const {
		setNewSortedColumnsWithDivider,
		updateSortedStickyColumns,
		updateSortedNonStickyColumns,
		sortedColumnsToDisplayInTheDropdown,
		shouldDisplayStickyColumnsDivider,
	} = useColumnsList({
		columnsFields,
		numberOfActiveStickyColumns,
		setNumberOfActiveStickyColumns,
		isNumberOfStickyColumnsDynamic,
		shouldDisableSortingOfStickyColumns,
		allColumnsInOrder,
		onChangeColumnsOrder,
		onChangeStickyColumnsDividerPosition,
		stickyColumnsDividerPosition,
		selectedColumns,
		segment,
	});

	useSegmentsColumns({
		segment,
		setNewSortedColumnsWithDivider,
		customFields,
		selectedColumns,
		numberOfActiveStickyColumns,
		allColumns: allColumnsInOrder,
	});

	useNewColumnsAutoSelect({
		columnsFields,
		allColumnsInOrder,
		selectedColumns,
		onChange,
		segment,
	});

	return (
		<>
			<Dropdown as={ButtonGroup}>
				<Dropdown.Toggle className={"me-0"} variant="light">
					{t("lib:common.word.columns")}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<div className="filters-search-columns">
						<div>
							{shouldDisplayStickyColumnsDivider ? (
								<SortableColumnsListWithDivider
									stickyColumnsDividerPosition={stickyColumnsDividerPosition.current}
									selectedColumns={selectedColumns}
									onSortedList={onSortedList}
									onChangeSelected={onChangeSelected}
									sortedColumnsToDisplayInTheDropdown={sortedColumnsToDisplayInTheDropdown}
								/>
							) : (
								<SortableColumnsList
									sortedColumnsToDisplayInTheDropdown={sortedColumnsToDisplayInTheDropdown}
									selectedColumns={selectedColumns}
									onChangeSelected={onChangeSelected}
									onSortedList={onSortedList}
								/>
							)}
						</div>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

export default ListColumn;
