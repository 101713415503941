import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormCheck, FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DeviceApi, DeviceParameterApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	device: DeviceApi;
}

const mapParametersToForm = (device: DeviceApi) => {
	device.parameters?.map((x) => {
		if (x.value === "true" || x.value === "false") {
			x.value = x.value === "true";
		}
		return x;
	});
	const deviceDriver = device.parameters?.find((f) => f.name === "driver")?.value;

	if (deviceDriver === "EPSON") {
		if (!device.parameters?.find((f) => f.name === "PAPER_CUT_TYPE")) {
			device.parameters.push({
				name: "PAPER_CUT_TYPE",
				value: "STANDARD",
			});
		}
		if (!device.parameters?.find((f) => f.name === "NEUTRAL_CHARACTERS")) {
			device.parameters.push({
				name: "NEUTRAL_CHARACTERS",
				value: false,
			});
		}
		if (!device.parameters?.find((f) => f.name === "SEND_DRAWER_KICK_1_COMMAND")) {
			device.parameters.push({
				name: "SEND_DRAWER_KICK_1_COMMAND",
				value: "0",
			});
		}
		if (!device.parameters?.find((f) => f.name === "SEND_DRAWER_KICK_2_COMMAND")) {
			device.parameters.push({
				name: "SEND_DRAWER_KICK_2_COMMAND",
				value: "0",
			});
		}
	}
	if (deviceDriver === "POSNET" && (device.interface === "BLUETOOTH" || device.interface === "USB")) {
		if (!device.parameters?.find((f) => f.name === "KEEP_PRINTER_CONNECTION")) {
			device.parameters.push({
				name: "KEEP_PRINTER_CONNECTION",
				value: false,
			});
		}
	}
	if (device.type === "PRINTER") {
		if (!device.parameters?.find((f) => f.name === "PAPER_WIDTH")) {
			device.parameters.push({
				name: "PAPER_WIDTH",
				value: "32",
			});
		}
	}
	if (device.parameters && device.interface && device.interface === "LAN") {
		if (!device.parameters?.find((f) => f.name === "mac_address")) {
			device.parameters.push({
				name: "mac_address",
				value: "",
			});
		}
	}

	if (device.parameters && device.interface && device.interface === "LAN") {
		if (!device.parameters?.find((f) => f.name === "ip_address")) {
			device.parameters.push({
				name: "ip_address",
				value: "",
			});
		}
	}

	if (
		deviceDriver === "EPSON" ||
		deviceDriver === "Sunmi" ||
		deviceDriver === "IminSwiftInnerPrinter" ||
		deviceDriver === "SunmiInnerPrinter"
	) {
		if (!device.parameters?.find((f) => f.name === "CANVAS_PRINT_MODE")) {
			device.parameters.push({
				name: "CANVAS_PRINT_MODE",
				value: false,
			});
		}
	}

	return device.parameters;
};

const DeviceForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const form = useForm<DeviceApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.device,
			parameters: mapParametersToForm(props.device),
		},
	});
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		setError,
		control,
		reset,
		formState,
		setValue,
		clearErrors,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const { fields, append } = useFieldArray({
		control,
		name: "parameters",
		keyName: "key",
	});

	const watchedDeviceType = watch("type");
	const watchedInterface = watch("interface");
	const driverName = watch("parameters")?.find((f) => f.name === "driver")?.value;
	const firstUpdate = useRef<boolean>(true);

	useEffect(() => {
		if (!firstUpdate.current) {
			if (watchedInterface === "LAN") {
				if (!fields?.find((f) => f.name === "driver")) {
					append([{ name: "driver", value: "" }]);
				}
				if (!fields?.find((f) => f.name === "ip_address")) {
					append({ name: "ip_address", value: "" });
					if (!fields?.find((f) => f.name === "port")) append({ name: "port", value: "" });
				}
			}
		}
	}, [watchedInterface]);

	useEffect(() => {
		if (!firstUpdate.current) {
			updateParameters();
		}
	}, [driverName]);

	useEffect(() => {
		if (!firstUpdate.current) {
			fields.forEach((field, index) => {
				if (
					watchedDeviceType === "PAYMENT_TERMINAL" &&
					field.name === "port" &&
					(!field.value || field.value === "")
				) {
					field.value = paymentDrivers[0].port;
				}
				if (
					watchedDeviceType === "PAYMENT_TERMINAL" &&
					field.name === "driver" &&
					(!field.value || field.value === "")
				) {
					field.value = paymentDrivers[0].value;
				}
				if (watchedDeviceType === "PRINTER" && field.name === "port" && (!field.value || field.value === "")) {
					field.value = deviceDrivers[0].port;
				}
				if (
					watchedDeviceType === "PRINTER" &&
					field.name === "driver" &&
					(!field.value || field.value === "")
				) {
					field.value = deviceDrivers[0].value;
				}
				if (field.name === "SEND_DRAWER_KICK_1_COMMAND") {
					setValue(`parameters.${index}.value`, field.value);
				}
				if (field.name === "SEND_DRAWER_KICK_2_COMMAND") {
					setValue(`parameters.${index}.value`, field.value);
				}
				if (field.name === "NEUTRAL_CHARACTERS") {
					setValue(`parameters.${index}.value`, field.value);
				}
				if (field.name === "PAPER_WIDTH") {
					setValue(`parameters.${index}.value`, field.value);
				}
				if (field.name === "CANVAS_PRINT_MODE") {
					setValue(`parameters.${index}.value`, field.value);
				}
				registerObject(register, `parameters.${index}`, ["name", "value"]);
				setValue(`parameters.${index}`, field);
			});
		} else firstUpdate.current = false;
	}, [fields]);

	const onSubmit = handleSubmit(async (data: DeviceApi) => {
		setLoading(true);
		data.id = props.device.id;
		try {
			if (data.id) {
				const params: Record<string, any> = {};
				params.include = "parameters";
				await api.organization().updateDevice(data, params);
				setLoading(false);
				reset(data);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createDevice(data);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				history.push(`/${organization.id}/settings/devices/${res.id}`);
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const updateParameters = () => {
		const newFields: DeviceParameterApi[] = [];
		if (driverName === "EPSON") {
			if (!fields?.find((f) => f.name === "PAPER_CUT_TYPE")) {
				newFields.push({
					name: "PAPER_CUT_TYPE",
					value: "STANDARD",
				});
			}
			if (!fields?.find((f) => f.name === "NEUTRAL_CHARACTERS")) {
				newFields.push({
					name: "NEUTRAL_CHARACTERS",
					value: false,
				});
			}
			if (!fields?.find((f) => f.name === "SEND_DRAWER_KICK_1_COMMAND")) {
				newFields.push({
					name: "SEND_DRAWER_KICK_1_COMMAND",
					value: "0",
				});
			}
			if (!fields?.find((f) => f.name === "SEND_DRAWER_KICK_2_COMMAND")) {
				newFields.push({
					name: "SEND_DRAWER_KICK_2_COMMAND",
					value: "0",
				});
			}
		}
		if (watch("type") === "PRINTER") {
			if (!fields?.find((f) => f.name === "PAPER_WIDTH")) {
				newFields.push({
					name: "PAPER_WIDTH",
					value: paperWidthOptions[0].value,
				});
			}
		}
		if (driverName === "POSNET" && (watchedInterface === "BLUETOOTH" || watchedInterface === "USB")) {
			if (!fields?.find((f) => f.name === "KEEP_PRINTER_CONNECTION")) {
				newFields.push({
					name: "KEEP_PRINTER_CONNECTION",
					value: false,
				});
			}
		}
		if (
			driverName === "EPSON" ||
			driverName === "Sunmi" ||
			driverName === "IminSwiftInnerPrinter" ||
			driverName === "SunmiInnerPrinter"
		) {
			if (!fields?.find((f) => f.name === "CANVAS_PRINT_MODE")) {
				newFields.push({
					name: "CANVAS_PRINT_MODE",
					value: false,
				});
			}
		}
		append(newFields);
	};

	const deviceTypes = [
		{ label: t("enums.devices.type.PRINTER"), value: "PRINTER" },
		{ label: t("enums.devices.type.PAYMENT_TERMINAL"), value: "PAYMENT_TERMINAL" },
		{ label: t("enums.devices.type.WEIGHT"), value: "WEIGHT" },
	];

	const interfaceTypes = [{ label: t("enums.devices.type.LAN"), value: "LAN" }];

	const deviceDrivers = [
		{
			label: t("enums.devices.drivers.POSNET"),
			value: "POSNET",
			port: 6666,
		},
		{
			label: t("enums.devices.drivers.ELZAB_STX"),
			value: "ELZAB_STX",
			port: 1001,
		},
		{
			label: t("enums.devices.drivers.EPSON"),
			value: "EPSON",
			port: 9100,
		},
		{
			label: t("enums.devices.drivers.APPLICATION"),
			value: "APPLICATION",
			port: 11001,
		},
		{
			label: t("enums.devices.drivers.NOVITUS"),
			value: "NOVITUS",
			port: 6001,
		},
		{
			label: t("enums.devices.drivers.EMAR"),
			value: "EMAR",
			port: 9100,
		},
		{
			label: t("enums.devices.drivers.SUNMI"),
			value: "Sunmi",
			port: 9100,
		},
	];

	const paymentDrivers = [
		{
			label: "eService",
			value: "E_SERVICE",
			port: 3000,
		},
	];

	const updatePort = (obj: any) => {
		const driver = deviceDrivers?.find((f) => f.value === obj);
		const index = fields?.findIndex((f) => f.name === "port");
		if (driver) {
			setValue(`parameters.${index}.value`, driver.port);
			clearErrors(`parameters.${index}.value`);
		}
	};

	const onChangeDeviceType = (obj: any) => {
		let driver;
		const index = fields?.findIndex((f) => f.name === "driver");
		const portIndex = fields?.findIndex((f) => f.name === "port");
		if (obj === "PRINTER") {
			driver = deviceDrivers[0];
		} else {
			driver = paymentDrivers[0];
		}
		setValue(`parameters.${index}.value`, driver.value);
		setValue(`parameters.${portIndex}.value`, driver.port);
		updateParameters();
	};

	const onIpChange = (evt: any, fieldIndex: number) => {
		setValue(`parameters.${fieldIndex}.value`, evt.target.value, {
			shouldDirty: evt.target.value !== (props.device?.parameters as any).ip_address?.value,
		});

		if (evt.nativeEvent.inputType === "deleteContentBackward") {
			return;
		}
		const value = evt.target.value;
		let finalValue;
		if (value.slice(value.length - 2) === "..") {
			setValue(`parameters.${fieldIndex}.value`, value.slice(0, -1));
			return;
		}
		if (value.split(".").length > 3) {
			const values = value.split(".");
			let splitted = value.split(".")[3];
			if (splitted.length > 3) {
				splitted = splitted.slice(0, 3);
			}
			setValue(`parameters.${fieldIndex}.value`, `${values[0]}.${values[1]}.${values[2]}.${splitted}`);
			return;
		}
		if (value.length === 3) {
			if (!isNaN(Number(value)) && Number(value) % 1 === 0 && value.slice(value.length - 1) !== ".") {
				finalValue = `${value}.`;
				setValue(`parameters.${fieldIndex}.value`, finalValue);
			}
		}
		if (value.length > 3) {
			const sliced = value.slice(value.length - 3);
			if (Number(sliced) % 1 === 0 && sliced.slice(sliced.length - 1) !== ".") {
				finalValue = `${value}.`;
				setValue(`parameters.${fieldIndex}.value`, finalValue);
			}
		}
	};

	const signals = [
		{
			label: "OFF",
			value: "0",
		},
		{
			label: 1,
			value: "1",
		},
		{
			label: 2,
			value: "2",
		},
		{
			label: 3,
			value: "3",
		},
		{
			label: 4,
			value: "4",
		},
		{
			label: 5,
			value: "5",
		},
		{
			label: 6,
			value: "6",
		},
	];

	const paperWidthOptions = [
		{
			label: t("enums.devices.paper_width.SMALL_PAPER_WIDTH"),
			value: "32",
		},
		{
			label: t("enums.devices.paper_width.SMALL_MEDIUM_PAPER_WIDTH"),
			value: "40",
		},
		{
			label: t("enums.devices.paper_width.MEDIUM_PAPER_WIDTH"),
			value: "42",
		},
		{
			label: t("enums.devices.paper_width.BIG_PAPER_WIDTH"),
			value: "48",
		},
		{
			label: t("enums.devices.paper_width.EXTRA_BIG_PAPER_WIDTH"),
			value: "60",
		},
		{
			label: t("enums.devices.paper_width.EXTRA_EXTRA_BIG_PAPER_WIDTH"),
			value: "62",
		},
	];

	const paperCutOptions = [
		{
			label: t("enums.devices.paper_width.STANDARD"),
			value: "STANDARD",
		},
		{
			label: t("enums.devices.paper_width.PARTIAL"),
			value: "PARTIAL",
		},
		{
			label: t("enums.devices.paper_width.FULL"),
			value: "FULL",
		},
	];

	const interfaceTypesReadonly = [
		{ label: t("enums.devices.type.LAN"), value: "LAN" },
		{ label: t("enums.devices.type.USB"), value: "USB" },
		{ label: t("enums.devices.type.BLUETOOTH"), value: "BLUETOOTH" },
		{ label: t("enums.devices.type.COM"), value: "COM" },
		{ label: t("enums.devices.type.MAC"), value: "MAC" },
		{ label: t("enums.devices.type.INTERNAL"), value: "INTERNAL" },
		{ label: t("enums.devices.type.NEXT"), value: "NEXT" },
	];

	const deviceDriversReadonly = [
		{ label: t("enums.devices.drivers.APPLICATION"), value: "APPLICATION", port: 11001 },
		{ label: t("enums.devices.drivers.CONSOLE"), value: "CONSOLE", port: 9100 },
		{ label: t("enums.devices.drivers.ELZAB_POS"), value: "ELZAB_POS", port: 1001 },
		{ label: t("enums.devices.drivers.ELZAB_STX"), value: "ELZAB_STX", port: 1001 },
		{ label: t("enums.devices.drivers.EMAR"), value: "EMAR", port: 9100 },
		{ label: t("enums.devices.drivers.EPSON"), value: "EPSON", port: 9100 },
		{ label: t("enums.devices.drivers.ESERVICE"), value: "ESERVICE", port: 9100 },
		{ label: t("enums.devices.drivers.E_SERVICE"), value: "E_SERVICE", port: 9100 },
		{ label: t("enums.devices.drivers.GOPOS_KITCHEN"), value: "GOPOS_KITCHEN", port: 9100 },
		{ label: t("enums.devices.drivers.GO_FISCAL"), value: "GO_FISCAL", port: 11010 },
		{ label: t("enums.devices.drivers.IMINSWIFTINNERPRINTER"), value: "IminSwiftInnerPrinter", port: 9100 },
		{ label: t("enums.devices.drivers.NOVITUS"), value: "NOVITUS", port: 6001 },
		{ label: t("enums.devices.drivers.NOVITUS_NEXT_ONE"), value: "NOVITUS_NEXT_ONE", port: 6001 },
		{ label: t("enums.devices.drivers.PDF"), value: "PDF", port: 9100 },
		{ label: t("enums.devices.drivers.POSNET"), value: "POSNET", port: 6666 },
		{ label: t("enums.devices.drivers.POSNET_OLD"), value: "POSNET_OLD", port: 9100 },
		{ label: t("enums.devices.drivers.POSNET_VERO"), value: "POSNET_VERO", port: 6666 },
		{ label: t("enums.devices.drivers.PAXINNERPRINTER"), value: "PaxInnerPrinter", port: 9100 },
		{ label: t("enums.devices.drivers.SUM_UP"), value: "SUM_UP", port: 9100 },
		{ label: t("enums.devices.drivers.SUNMI"), value: "Sunmi", port: 9100 },
		{ label: t("enums.devices.drivers.SUNMIINNERPRINTER"), value: "SunmiInnerPrinter", port: 9100 },
		{ label: t("enums.devices.drivers.TOAST"), value: "TOAST", port: 9100 },
		{ label: t("enums.devices.drivers.WIRTUALNEKASYFISKALNE"), value: "WirtualneKasyFiskalne", port: 9100 },
	];

	return (
		<FormDirty key="device-form" loading={loading} formState={formState} noValidate onSubmit={onSubmit}>
			<FormErrorMessage errors={errors} name={"options"} />
			<fieldset className="form-group no-gutters">
				<h5>{t("common.word.basic_data", { ns: "lib" })}</h5>
				<FormInput
					label={t("common.word.name", { ns: "lib" })}
					register={register}
					name="name"
					errors={errors}
				/>
				{
					<>
						<FormSelectGroup
							label={t("lib:common.word.type")}
							name="type"
							defaultValue={props.device?.type}
							errors={errors}
							getOptionLabel={(option) => option.label}
							onChange={onChangeDeviceType}
							disabled={props.device.interface !== "LAN"}
							options={deviceTypes}
							control={control}
							data-testid="type"
						/>
						<FormSelectGroup
							label={t("modules.device.field.interface.title")}
							name="interface"
							defaultValue={props.device?.interface}
							errors={errors}
							disabled={props.device.interface !== "LAN"}
							getOptionLabel={(option) => option.label}
							options={watch("interface") === "LAN" ? interfaceTypes : interfaceTypesReadonly}
							control={control}
							data-testid="interface"
						/>
						{fields.map((field, index) => {
							return (
								<React.Fragment key={`device-fields-${index}`}>
									{
										<>
											<FormInput
												register={register}
												type="hidden"
												errors={errors}
												value={field.name}
												name={`parameters.${index}.${field.name}`}
											/>
											{field.name === "port" && (
												<FormNumberInput
													label={t(`modules.device.field.${field.name}.title`)}
													control={control}
													suffix={""}
													decimalScale={0}
													name={`parameters.${index}.value`}
													defaultValue={field.value}
													disabled={props.device.interface !== "LAN"}
													errors={errors}
												/>
											)}
											{field.name === "ip_address" && (
												<FormInput
													onChange={
														field.name === "ip_address"
															? (evt: React.ChangeEvent) => onIpChange(evt, index)
															: undefined
													}
													placeholder={
														field.name === "ip_address" ? "192.168.1.100" : undefined
													}
													label={t(`modules.device.field.${field.name}.title`)}
													register={register}
													defaultValue={field?.value}
													disabled={props.device.interface !== "LAN"}
													name={`parameters.${index}.value`}
													errors={errors}
												/>
											)}
											{field.name === "driver" && (
												<FormSelectGroup
													label={t("modules.device.field.driver.title")}
													name={`parameters.${index}.value`}
													defaultValue={field.value}
													errors={errors}
													onChange={(val) => updatePort(val)}
													getOptionLabel={(option) => option.label}
													options={
														watch("interface") === "LAN"
															? watchedDeviceType === "PRINTER"
																? deviceDrivers
																: paymentDrivers
															: deviceDriversReadonly
													}
													disabled={props.device.interface !== "LAN"}
													control={control}
													data-testid={`parameters.${index}.value`}
												/>
											)}
											{watchedDeviceType === "PRINTER" && field.name === "PAPER_WIDTH" && (
												<FormSelectGroup
													label={t("modules.device.field.paper_width.title")}
													name={`parameters.${index}.value`}
													defaultValue={field.value}
													errors={errors}
													getOptionLabel={(option) => option.label}
													options={paperWidthOptions}
													control={control}
													data-testid={`parameters.${index}.value`}
												/>
											)}
											{driverName === "EPSON" && (
												<>
													{field.name === "PAPER_CUT_TYPE" && (
														<FormSelectGroup
															label={t("modules.device.field.paper_cut_type.title")}
															name={`parameters.${index}.value`}
															defaultValue={field.value}
															errors={errors}
															getOptionLabel={(option) => option.label}
															options={paperCutOptions}
															disabled={props.device.interface !== "LAN"}
															control={control}
															data-testid={`parameters.${index}.value`}
														/>
													)}
													{field.name === "NEUTRAL_CHARACTERS" && (
														<FormCheck
															type={"switch"}
															defaultChecked={false}
															label={t(`modules.device.field.NEUTRAL_CHARACTERS.title`)}
															help={t(
																"modules.device.field.NEUTRAL_CHARACTERS.help_text.description"
															)}
															register={register}
															errors={errors}
															disabled={props.device.interface !== "LAN"}
															name={`parameters.${index}.value`}
														/>
													)}
													{field.name === "SEND_DRAWER_KICK_1_COMMAND" && (
														<FormSelectGroup
															label={t(
																`modules.device.field.SEND_DRAWER_KICK_1_COMMAND.title`
															)}
															help={t(
																`modules.device.field.SEND_DRAWER_KICK_1_COMMAND_help.title`
															)}
															name={`parameters.${index}.value`}
															defaultValue={field.value}
															errors={errors}
															getOptionLabel={(option) => option.label}
															options={signals}
															disabled={props.device.interface !== "LAN"}
															control={control}
															data-testid={`parameters.${index}.value`}
														/>
													)}
													{field.name === "SEND_DRAWER_KICK_2_COMMAND" && (
														<FormSelectGroup
															label={t(
																`modules.device.field.SEND_DRAWER_KICK_2_COMMAND.title`
															)}
															help={t(
																`modules.device.field.SEND_DRAWER_KICK_2_COMMAND_help.title`
															)}
															name={`parameters.${index}.value`}
															defaultValue={field.value}
															errors={errors}
															getOptionLabel={(option) => option.label}
															options={signals}
															disabled={props.device.interface !== "LAN"}
															control={control}
															data-testid={`parameters.${index}.value`}
														/>
													)}
												</>
											)}
											{driverName === "POSNET" &&
												(watchedInterface === "BLUETOOTH" || watchedInterface === "USB") &&
												field.name === "KEEP_PRINTER_CONNECTION" && (
													<>
														{
															<FormCheck
																type={"switch"}
																label={t(
																	`modules.device.field.KEEP_PRINTER_CONNECTION.title`
																)}
																register={register}
																errors={errors}
																disabled={props.device.interface !== "LAN"}
																name={`parameters.${index}.value`}
															/>
														}
													</>
												)}
											{field.name === "mac_address" && (
												<FormInput
													label={t(`modules.device.field.${field.name}.title`)}
													help={t(`modules.device.field.${field.name}_help.title`)}
													register={register}
													name={`parameters.${index}.value`}
													defaultValue={field.value}
													errors={errors}
												/>
											)}
										</>
									}
									{(driverName === "EPSON" ||
										driverName === "Sunmi" ||
										driverName === "IminSwiftInnerPrinter" ||
										driverName === "SunmiInnerPrinter") &&
										field.name === "CANVAS_PRINT_MODE" && (
											<FormCheck
												type={"switch"}
												defaultChecked={false}
												label={t(`modules.device.field.CANVAS_PRINT_MODE.title`)}
												help={t("modules.device.field.CANVAS_PRINT_MODE.help_text")}
												register={register}
												errors={errors}
												name={`parameters.${index}.value`}
											/>
										)}
								</React.Fragment>
							);
						})}
					</>
				}
			</fieldset>
		</FormDirty>
	);
};

export default DeviceForm;

// t("modules.device.field.ip_address.title");
// t("modules.device.field.port.title");
// t("modules.device.field.mac_address.title");
// t("modules.device.field.mac_address_help.title");
