import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "go-core/components/Loading";
import { ReactComponent as DropdownSVG } from "../../../../../../../../../../../../images/svg/dropdown.svg";
import {
	LiveOrderApi,
	OrderPreparationStatusName,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../../../services/context";
import { getOrderPreparationStatusColor, preparationStatusColors } from "../../../../../../services/orderStatus";
import PreparationStatusModal from "./PreparationStatusModal";

interface Props {
	status?: OrderPreparationStatusName;
	order: LiveOrderApi;
	setOrder: (order: LiveOrderApi) => void;
}

const LiveOrderPreparationStatusSelect = ({ status, order, setOrder }: Props): JSX.Element => {
	const { t } = useTranslation();
	const { isOnline } = useContext(OrdersContext);
	const [showOptionsModal, setShowOptionsModal] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const finalStatus = status ? status : OrderPreparationStatusName.NEW;
	const isDisabled = order.status === "EXTERNAL" || !isOnline;

	return (
		<>
			<div
				onClick={() => !isDisabled && setShowOptionsModal(true)}
				style={{
					backgroundColor: getOrderPreparationStatusColor(finalStatus),
					opacity: isDisabled ? 0.5 : 1,
					cursor: isDisabled ? "default" : "pointer",
				}}
				className={`preparation-status-select-container ${
					getOrderPreparationStatusColor(finalStatus) === preparationStatusColors.WHITE ? "bordered" : ""
				}`}
			>
				<span className="label mb-0">{t("modules.live_order.field.order_status.title")}</span>
				<div className="d-inline-flex align-items-center">
					{loading ? (
						<Loading />
					) : (
						<>
							<h5 className="mb-0">{t(`enums.preparation_statuses.${finalStatus}`).toUpperCase()}</h5>
							<DropdownSVG className="ms-1" />
						</>
					)}
				</div>
			</div>
			{showOptionsModal && (
				<PreparationStatusModal
					status={finalStatus}
					order={order}
					setOrder={setOrder}
					show={showOptionsModal}
					onHide={() => setShowOptionsModal(false)}
					setLoading={setLoading}
				/>
			)}
		</>
	);
};

export default LiveOrderPreparationStatusSelect;
