import React from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { SettingsAvailabilitiesPage } from "go-component/components/Availabilities";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganizationComponent } from "../../../../../../../../services/Api/Organization/apiOrganizationComponent";

export const OrganizationSettingsAvailabilitiesPage = (props: RouteComponentProps) => {
	const requests = {
		updateAvailability: apiOrganizationComponent.updateAvailability,
		createAvailability: apiOrganizationComponent.createAvailability,
		removeAvailability: apiOrganizationComponent.removeAvailability,
		restoreAvailability: apiOrganizationComponent.restoreAvailability,
		getAvailability: apiOrganizationComponent.getAvailability,
		getAvailabilities: apiOrganizationComponent.getAvailabilities,
	};

	const { name, id, more } = useSelector(selectOrganization);
	const taxIdNo = more?.print_company_on_pdf ? more.company_tax_id_no : undefined;
	const companyName = more?.print_company_on_pdf ? more.company_name : undefined;
	const pdfOrientation = more?.pdf_orientation;
	const pdfFontSize = more?.default_pdf_font_size?.toString();

	return (
		<SettingsAvailabilitiesPage
			{...props}
			requests={requests}
			organizationId={id}
			organizationName={name}
			taxIdNo={taxIdNo}
			companyName={companyName}
			pdfFontSize={pdfFontSize}
			pdfOrientation={pdfOrientation}
		/>
	);
};
