import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CashSVG } from "../../../images/svg/paymentMethods/cash-payment.svg";
import { ReactComponent as OtherPaymentSVG } from "../../../images/svg/paymentMethods/other-payment.svg";
import FormatResourceStatus from "../Formatters/FormatResourceStatus/FormatResourceStatus";

interface Props {
	name: string;
	type: string;
	id?: number;
	status?: string;
	onClick?: () => void;
}

const RenderPaymentMethod: FC<Props> = ({ name, type, status }) => {
	const { t } = useTranslation();

	if (!name) {
		name = t("common.word.unknown_female");
	}
	// const link = <Link className={"me-1"} to={`/${organization.id}/settings/payment_methods/${id}`} onClick={(evt) => onLinkClick(evt)}
	//                    target={"_blank"} rel="noreferrer">{name}</Link>

	if (type === "CASH") {
		return (
			<div className={"d-flex align-items-center"}>
				<CashSVG className={"me-1"} />
				{/* {id ? link :  */}
				<span className={"me-1"}>{name}</span>
				{/* }  */}
				{status && <FormatResourceStatus status={status} />}
			</div>
		);
	}
	return (
		<div className={"d-flex align-items-center"}>
			<OtherPaymentSVG className={"me-1"} />
			{/* {id ? link :  */}
			<span className={"me-1"}>{name}</span>
			{/* }  */}
			{status && <FormatResourceStatus status={status} />}
		</div>
	);
};
export default RenderPaymentMethod;
