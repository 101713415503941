import { FC } from "react";
import { Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import OrganizationInvoicesAdvancesEditPage from "./Edit";
import OrganizationInvoicesAdvancesNewPage from "./New";
import OrganizationInvoicesAdvancesPreviewPage from "./Preview";

const OrganizationInvoicesAdvancesPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route path={`${props.match.url}/new`} exact={true} component={OrganizationInvoicesAdvancesNewPage} />
			<Route
				exact={true}
				path={`${props.match.url}/:advance_id`}
				component={OrganizationInvoicesAdvancesPreviewPage}
			/>
			<Route
				exact={true}
				path={`${props.match.url}/:advance_id/edit`}
				component={OrganizationInvoicesAdvancesEditPage}
			/>
		</Switch>
	);
};
export default OrganizationInvoicesAdvancesPage;
