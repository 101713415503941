import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { EmployeeActivityApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { EmployeeActivitiesBackendAcceptableParams } from "../services/types";

const getExportParams = (params: Record<string, any>): Record<string, any> => {
	const newParamsArr = params.columns.split(",");

	const paramsToBeSent = newParamsArr.map((param: string) => {
		if (param === "CREATED_AT") return EmployeeActivitiesBackendAcceptableParams.DATE;
		if (param === "EMPLOYEE")
			return `${EmployeeActivitiesBackendAcceptableParams.EMPLOYEE},${EmployeeActivitiesBackendAcceptableParams.TERMINAL}`;
		return param;
	});

	return { ...params, columns: paramsToBeSent.join(",") };
};

interface ListState {
	resource?: Record<string, any>;
}

const listName = "EMPLOYEE_ACTIVITY";
const resourceType = "EMPLOYEE_ACTIVITY";

const List: FC<ListState> = ({ resource }) => {
	const [items, setItems] = useState<EmployeeActivityApi[]>([]);
	const { t } = useTranslation();
	const user = useSelector(selectUser);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const segmentContext = useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const defaultPermission = useSelector(hasPermission("DEFAULT"));
	const venueOrderShowPermission = useSelector(hasPermission("VENUE_ORDER_SHOW"));
	const hasAccess = defaultPermission || user.roles?.includes("ROLE_ADMIN");

	useEffect(() => {
		handleChangeTabTitle(t("modules.employee_activity.header.title"));
		if (hasAccess && !venueOrderShowPermission) {
			history.push(`/${organization.id}/logs/message_events`);
		}
	}, []);

	if (!resource) return null;
	const data = resource.read();

	const redirect = (item: EmployeeActivityApi) => {
		if (item.context_entity && Object.keys(item.context_entity).length > 0) {
			const { id, number, resource_type } = item.context_entity;
			const title = `${t(`enums.message_events.resource_type.${resource_type}`)} ${number}`;
			switch (resource_type) {
				case "ITEM_GROUP":
					return (
						<Link rel="noreferrer" target="_blank" to={`/${organization.id}/menu/item_groups/${id}`}>
							{title}
						</Link>
					);
				case "ITEM":
					return (
						<Link
							to={`/${organization.id}/menu/item_groups?f=${btoa(
								unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}item|e=${id}`))
							)}`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "ORDER":
					return (
						<Link rel="noreferrer" target="_blank" to={`/${organization.id}/sales/orders/${id}`}>
							{title}
						</Link>
					);
				case "INVOICE":
					return (
						<Link to={`/${organization.id}/invoices/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "TRANSACTION":
					return (
						<Link to={`/${organization.id}/sales/transactions`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "CATEGORY":
					return (
						<Link to={`/${organization.id}/menu/categories/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "MENU_DISCOUNT":
					return (
						<Link to={`/${organization.id}/menu/discounts/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "DEVICE":
					return (
						<Link to={`/${organization.id}/settings/devices/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "TERMINAL":
					return (
						<Link to={`/${organization.id}/settings/terminals/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "CLIENT_GROUP":
					return (
						<Link
							to={`/${organization.id}/clients/client-groups/${id}`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "MODIFIER_GROUP":
					return (
						<Link
							to={`/${organization.id}/menu/modifier_groups/${id}`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "CLIENT":
					return (
						<Link to={`/${organization.id}/clients/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "EMPLOYEE":
					return (
						<Link to={`/${organization.id}/employees/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "RESERVATION":
					return (
						<Link to={`/${organization.id}/reservations/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "AVAILABILITY":
					return (
						<Link
							to={`/${organization.id}/settings/availabilities/${id}`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "DIRECTION":
					return (
						<Link to={`/${organization.id}/settings/rooms/directions`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "POINT_OF_SALE":
					return (
						<Link
							to={`/${organization.id}/settings/rooms/point-of-sales`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "ROLE":
					return (
						<Link
							to={`/${organization.id}/settings/permissions/roles`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "PRINTOUT_TEMPLATE":
					return (
						<Link
							to={`/${organization.id}/settings/printout_templates/${id}`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "PAYMENT_METHOD":
					return (
						<Link
							to={`/${organization.id}/settings/payment_methods/${id}`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "TAX":
					return (
						<Link to={`/${organization.id}/settings/taxes`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "CURRENCY_RATE":
					return (
						<Link to={`/${organization.id}/settings/currencies`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "ROOM":
					return (
						<Link to={`/${organization.id}/settings/rooms`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "WORKPLACE":
					return (
						<Link to={`/${organization.id}/employees/workplaces`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "DEFAULT_COMMENT":
					return (
						<Link to={`/${organization.id}/settings/default_comments`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "PERMISSION":
					return (
						<Link to={`/${organization.id}/settings/permissions`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "REPORT":
					return (
						<Link to={`/${organization.id}/sales/pos_reports/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "WORK_TIME":
					return (
						<Link to={`/${organization.id}/employees/worktimes`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "WEBHOOK":
					return (
						<Link to={`/${organization.id}/logs/webhooks/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "APP":
					return (
						<Link to={`/${organization.id}/apps/${id}/edit`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "RECEIPT":
					return (
						<Link to={`/${organization.id}/settings/info/receipt`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
				case "INVOICE_DETAILS":
					return (
						<Link
							to={`/${organization.id}/settings/info/invoice_details`}
							target={"_blank"}
							rel={"noreferrer"}
						>
							{title}
						</Link>
					);
				case "MENU_PRICE_LIST":
					return (
						<Link to={`/${organization.id}/menu/price_lists/${id}`} target={"_blank"} rel={"noreferrer"}>
							{title}
						</Link>
					);
			}
		}
	};

	let config = {
		groupBy: {
			fieldName: "created_at",
			type: "date",
		},
		fields: [
			{
				id: "created_at",
				name: t("lib:common.word.created_at"),
				type: "date",
				render: (item: EmployeeActivityApi) => {
					return item.created_at.split("T")[1];
				},
				renderExport: (item: EmployeeActivityApi) => {
					return FormatDate(item.created_at, undefined, true);
				},
			},
			{
				id: "employee",
				name: t("modules.employee_activity.field.employee_name.title"),
				disableSorting: true,
				type: "search_select",
				render: (item: EmployeeActivityApi) => {
					return (
						<div className={"d-flex flex-column"}>
							{item.employee_id ? (
								<Link target={"_blank"} to={`/${organization.id}/employees/${item.employee_id}`}>
									{item.employee_name}
								</Link>
							) : (
								<span>{item.employee_name}</span>
							)}
							{item.terminal && (
								<div className={"d-flex"}>
									<small className={"text-muted"}>
										{t("common.word.terminal")}:{" "}
										<Link
											target={"_blank"}
											to={`/${organization.id}/settings/terminals/${item.terminal.id}`}
										>
											{item.terminal.name}
										</Link>
									</small>
								</div>
							)}
						</div>
					);
				},
				renderExport: (item: EmployeeActivityApi) =>
					`${item.employee_name ? `${item.employee_name}, ` : ""}${item.terminal.name}`,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "description",
				disableSorting: true,
				name: t("lib:common.word.description"),
				render: (item: EmployeeActivityApi) => {
					return <RenderLimitedText minWidth={200}>{item.description}</RenderLimitedText>;
				},
				renderExport: (item: EmployeeActivityApi) => {
					return item.description;
				},
			},
			{
				id: "context",
				disableSorting: true,
				name: t("modules.employee_activity.field.context.title"),
				render: (item: EmployeeActivityApi) => redirect(item),
				renderExport: (item: EmployeeActivityApi) => `${item?.context || ""}`,
			},
			{
				id: "parameters",
				disableSorting: true,
				name: t("modules.employee_activity.field.parameters.title"),
			},
			{
				id: "type",
				disableSorting: true,
				name: t("lib:common.word.type"),
				render: (item: EmployeeActivityApi) => t(`enums.activities.type.${item.type}`) || `${item.type}`,
			},
		],
		filters: [
			{
				id: "order",
				name: t("common.word.order"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getOrdersSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: EmployeeActivityApi) => {
						if (item.created_at && item.closed_at) {
							return `#${item.label} (${FormatDateToDateHoursRange(item.created_at, item.closed_at)})`;
						}
						return `#${item.label} (${FormatDate(item.created_at)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTerminalsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "search_select",
				source: {
					request: async () => {
						const res: string[] = await api.organization().getEmployeeActivityTypes();
						return res.map((option) => ({
							id: option,
							label: `${t(`enums.activities.type.${option}`)}`,
						}));
					},
				},
				withoutInfiniteScroll: true,
			},
		],
		selectedColumns: ["created_at", "employee", "description", "context"],
		customPageSizes: ["20", "100", "1000"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "context_entity,terminal";
			return api.organization().getEmployeesActivities(params, { cancelToken: sourceToken?.token });
		},
		exportConfig: {
			title: t("modules.employee_activity.field.export_config.title"),
			filename: t("modules.employee_activity.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			customExportRequests: {
				pdfFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns: params?.columns || "DATE,EMPLOYEE,TERMINAL,DESCRIPTION",
					};
					return api.organization().getEmployeeActivitiesPDF(getExportParams(newParams));
				},
				csvFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns: params?.columns || "DATE,EMPLOYEE,TERMINAL,DESCRIPTION",
					};
					return api.organization().getEmployeeActivitiesCSV(getExportParams(newParams));
				},
			},
		} as ExportConfig,
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData data={items} config={config} onFetch={(fetchItems: EmployeeActivityApi[]) => setItems(fetchItems)} />
	);
};

const OrganizationLogsMessageEventsIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.employee_activity.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<div className={"activities-list"}>
					<List resource={resource} />
				</div>
			</Suspense>
		</div>
	);
};
export default OrganizationLogsMessageEventsIndexPage;
