import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import GlobalMigrationForm from "./components/GlobalMigrationForm";

const MigrationPage: FC<RouteComponentProps> = () => {
	return (
		<div className="page-container">
			<div className="content">
				<div className={"content-preview"}>
					<GlobalMigrationForm />
				</div>
			</div>
		</div>
	);
};
export default MigrationPage;
