import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatBoolean, { convertBoolean } from "go-core/components/Formatters/FormatBoolean";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { AppLinkApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import LinkEntityModal from "../../components/Link/LinkEntityModal";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "APP_LINK";
const resourceType = "APP_LINK";

const List: FC<ListState> = ({ resource, mobileActions }): JSX.Element | null => {
	const { t } = useTranslation();
	const [itemToLink, setItemToLink] = useState<AppLinkApi | undefined>(undefined);
	const [params, setParams] = useState<Record<string, any>>({});
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<AppLinkApi[]>([]);
	const segmentContextValue = React.useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onHandleSave = async () => {
		setItemToLink(undefined);
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const renderConnection = (item: AppLinkApi) => {
		if (Array.isArray(item.items)) {
			return (
				<RenderLimitedText minWidth={200}>
					<span>{item.items.map((value) => value.item_name).join(",\n")}</span>
				</RenderLimitedText>
			);
		}

		switch (item.resource_type) {
			case "ITEM_GROUP":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/item_groups/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "ITEM":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/item_groups/${item.parent_resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "CATEGORY":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/categories/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "MODIFIER_GROUP":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/modifier_groups/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "DISCOUNT":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/discounts/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "PAYMENT_METHOD":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/settings/payment_methods/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "VENUE_ROLE":
				return (
					<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/settings/permissions`}>
						{item.resource_name}
					</Link>
				);
			case "EMPLOYEE":
				return (
					<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/employees/${item.resource_id}`}>
						{item.resource_name}
					</Link>
				);
			case "AVAILABILITY":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/settings/availabilities/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "MENU":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/menus/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "MENU_PRICE_LIST":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/menu/price_lists/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "CUSTOM_FIELD_TEMPLATE":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/settings/custom_fields/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "CLIENT_GROUP":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/clients/client-groups/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			case "POINT_OF_SALE":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/settings/rooms/points-of-sales`}
					>
						{item.resource_name}
					</Link>
				);
			case "DIRECTION":
				return (
					<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/settings/rooms/directions`}>
						{item.resource_name}
					</Link>
				);
			case "CUSTOM_FIELD_TEMPLATE_OPTION":
				return (
					<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/settings/custom_fields`}>
						{item.resource_name}
					</Link>
				);
			case "SEGMENT":
				return (
					<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/settings/segments`}>
						{item.resource_name}
					</Link>
				);
			case "PRINTOUT_TEMPLATE":
				return (
					<Link
						target={"_blank"}
						rel={"noreferrer"}
						to={`/${organization.id}/settings/printout_templates/${item.resource_id}`}
					>
						{item.resource_name}
					</Link>
				);
			default:
				return item.resource_name;
		}
	};

	let config = {
		fields: [
			{
				id: "external_name",
				name: t("modules.app_link.field.external_name.title"),
				type: "text",
				render: (item: AppLinkApi) => {
					return item.link ? (
						<RenderLimitedText minWidth={200}>
							<a href={item.link} target={"_blank"} rel={"noreferrer"}>
								{item.external_name}
							</a>
						</RenderLimitedText>
					) : (
						<RenderLimitedText minWidth={200}>{item.external_name}</RenderLimitedText>
					);
				},
				renderExport: (item: AppLinkApi) => {
					return item.external_name;
				},
			},
			{
				id: "external_id",
				name: t("modules.app_link.field.external_id.title"),
				render: (item: AppLinkApi) => <RenderLimitedText minWidth={200}>{item.external_id}</RenderLimitedText>,
				renderExport: (item: AppLinkApi) => item.external_id,
				type: "text",
			},
			{
				id: "app",
				name: t("modules.app_link.field.app.title"),
				type: "search_select",
				render: (item: AppLinkApi) => {
					return `${item.app?.name} (${t(`enums.apps.providers.${item.app.provider}`)})`;
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getAppsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
					render: (item: any) => {
						return `${item.label} (${t(`enums.apps.providers.${item.provider}`)})`;
					},
				},
			},
			{
				id: "resource_type",
				name: t("modules.app_link.field.resource_type.title"),
				render: (item: AppLinkApi) => t(`enums.apps.resource_types.${item.resource_type}`),
			},
			{
				id: "linked_with",
				name: t("modules.app_link.field.linked_with.title"),
				render: (item: AppLinkApi) => {
					if (item.skipped) {
						return (
							<span className={"badge bg-warning text-dark"}>
								{t("modules.app_link.field.skipped.title")}
							</span>
						);
					}
					if (item.status === "OPEN") {
						return <span className={"badge bg-danger"}>{t("modules.app_link.field.unlinked.title")}</span>;
					}
					if (item.status === "CLOSED" || item.status === "TO_EXPORT") {
						return <span>{renderConnection(item)}</span>;
					}
				},
				renderExport: (item: AppLinkApi) => {
					if (item.skipped) return t("modules.app_link.field.skipped.title");
					if (item.status === "OPEN") return t("modules.app_link.field.unlinked.title");
					if (item.status === "CLOSED" || item.status === "TO_EXPORT") {
						if (Array.isArray(item.items)) {
							return item.items.map((value) => value.item_name).join(", ");
						}
						return item.resource_name;
					}
				},
			},
			{
				id: "used",
				name: t("modules.app_link.field.used.title"),
				render: (item: AppLinkApi) => <FormatBoolean value={item.used} />,
				type: "boolean",
				renderExport: (item: AppLinkApi) => convertBoolean(item.used, t),
			},
			{
				id: "updated_at",
				name: t("modules.app_link.field.updated_at.title"),
				render: (item: AppLinkApi) => {
					return FormatDate(item.updated_at);
				},
				renderExport: (item: AppLinkApi) => {
					return FormatDate(item.updated_at);
				},
			},
			{
				id: "imported_at",
				name: t("modules.app_link.field.imported_at.title"),
				render: (item: AppLinkApi) => {
					return FormatDate(item?.imported_at);
				},
				renderExport: (item: AppLinkApi) => {
					return FormatDate(item?.imported_at);
				},
			},
			{
				id: "exported_at",
				name: t("modules.app_link.field.exported_at.title"),
				render: (item: AppLinkApi) => {
					return FormatDate(item?.exported_at);
				},
				renderExport: (item: AppLinkApi) => {
					return FormatDate(item?.exported_at);
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list",
				options: {
					OPEN: t("enums.common.status.OPEN"),
					CLOSED: t("enums.common.status.CLOSED"),
					TO_EXPORT: t("enums.common.status.TO_EXPORT"),
				},
			},
			{
				id: "id",
				name: "Id",
				type: "number",
			},
			{
				id: "skipped",
				name: t("modules.app_link.field.skipped.title"),
				type: "boolean",
			},
			{
				id: "resource_type",
				name: t("modules.app_link.field.resource_type.title"),
				type: "search_select",
				source: {
					request: async () => {
						const res: string[] = await api.organization().getAppLinkResourceTypes();
						return res.map((option) => ({
							id: option,
							label: t(`enums.apps.resource_types.${option}`),
						}));
					},
				},
				withoutInfiniteScroll: true,
			},
			{
				id: "item",
				name: t("common.word.variant"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
		],
		actions: [
			{
				name: t("common.action.link"),
				click: (item: AppLinkApi) => {
					setItemToLink(item);
				},
			},
		],
		selectedColumns: ["external_name", "external_id", "app", "resource_type", "linked_with", "used", "updated_at"],
		segments: [
			{
				id: "all",
				name: `${t("common.word.all", { ns: "lib" })}`,
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.app_link.field.export_config.title"),
			filename: t("modules.app_link.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(params);
			params.include = "app,resource";
			return api.organization().getLinks(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{itemToLink && (
				<LinkEntityModal handleSave={onHandleSave} onHide={() => setItemToLink(undefined)} item={itemToLink} />
			)}
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.app_link.header.title")}
						description={t("modules.app_link.field.empty_list_description.title")}
					/>
				}
				onFetch={(fetchItems: AppLinkApi[]) => {
					setItems(fetchItems);
				}}
				mobileActions={mobileActions}
			/>
		</>
	);
};

const OrganizationAppsLinkingPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContextValue = React.useContext(SegmentContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(`${t("modules.app_link.header.title")} | ${t("modules.app.header.title")}`);
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	const syncApplications = async () => {
		setLoading(true);
		try {
			await api.organization().syncApps();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}

		setLoading(false);
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.sync", { ns: "lib" }),
			loading,
			variant: "primary",
			action: () => syncApplications(),
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.sync", { ns: "lib" }),
			action: syncApplications,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.app_link.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationAppsLinkingPage;

// t("enums.apps.providers.GOORDER");
// t("enums.apps.providers.GOHOTELINTEGRATION");
// t("enums.apps.providers.LOCATIONS");
// t("enums.apps.providers.GOPOS");
// t("enums.apps.providers.GOSTOCK");
// t("enums.apps.providers.GOCRM");
// t("enums.apps.providers.CALL_RECORDER");
// t("enums.apps.providers.GOZONE");
// t("enums.apps.providers.GOCLIENT");
// t("enums.apps.providers.NFHOTEL");
// t("enums.apps.providers.HOLIDAY_PMS");
// t("enums.apps.providers.HOTELGRAM");
// t("enums.apps.providers.MEWS");
// t("enums.apps.resource_types.AVAILABILITY");
// t("enums.apps.resource_types.CATEGORY");
// t("enums.apps.resource_types.CLIENT_GROUP");
// t("enums.apps.resource_types.CUSTOM_FIELD_TEMPLATE");
// t("enums.apps.resource_types.CUSTOM_FIELD_TEMPLATE_OPTION");
// t("enums.apps.resource_types.DIRECTION");
// t("enums.apps.resource_types.DISCOUNT");
// t("enums.apps.resource_types.EMPLOYEE");
// t("enums.apps.resource_types.ITEM");
// t("enums.apps.resource_types.ITEM_GROUP");
// t("enums.apps.resource_types.MENU");
// t("enums.apps.resource_types.MODIFIER_GROUP");
// t("enums.apps.resource_types.PAYMENT_METHOD");
// t("enums.apps.resource_types.POINT_OF_SALE");
// t("enums.apps.resource_types.PRICE_LIST");
// t("enums.apps.resource_types.SEGMENT");
// t("enums.apps.resource_types.VENUE_ROLE");
// t("enums.apps.resource_types.WORKPLACE");
// t("enums.apps.resource_types.PRINTOUT_TEMPLATE");
