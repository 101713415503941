import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ConfirmedInvoiceWarning = (): JSX.Element => {
	const { t } = useTranslation();

	return <Alert variant="warning">{t("modules.invoice.field.confirmed_invoice_warning.title")}</Alert>;
};

export default ConfirmedInvoiceWarning;
