import React from "react";

interface Props {
	title: string;
	subtitle?: string;
	icon?: JSX.Element;
}

const SubNavigationItemWrapper = ({ subtitle, title, icon }: Props): JSX.Element => {
	return (
		<div className="d-flex justify-content-between align-items-center w-100">
			{subtitle ? (
				<div className="d-flex flex-column">
					{title}
					<small>{subtitle}</small>
				</div>
			) : (
				<>{title}</>
			)}
			{icon && icon}
		</div>
	);
};

export default SubNavigationItemWrapper;
