import moment from "moment";
import { Range, RangeWithKey } from "react-date-range";
import FormatDate, {
	FormatUniversalDate,
	formatStringToDate,
	isDST,
	isValidDate,
	parseDateToDefaultString,
} from "go-core/components/Formatters/FormatDate";
import { FILTER_VALUE_SEPARATOR } from "../core/components/Filter/services";

// export const parsePredefinedRangeToDateString = (predefinedRange: string) => {
//     let date = new Date();
//     switch (predefinedRange) {
//         case "today":
//             return parseDates(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));
//         case "yesterday":
//             return parseDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59));
//         case "last_7_days":
//             return parseDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
//         case "last_30_days":
//             return parseDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
//         case "this_month":
//             return parseDates(new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59))
//         case "previous_month":
//             return parseDates(new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59))
//         case "last_3_months":
//             return parseDates(new Date(date.getFullYear(), date.getMonth() - 3, 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59))
//         case "last_6_months":
//             return parseDates(new Date(date.getFullYear(), date.getMonth() - 6, 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59))
//         case "last_12_months":
//             return parseDates(new Date(date.getFullYear(), date.getMonth() - 12, 1, 0, 0, 0), new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59))
//     }
//     return "";
// }

export const manipulateHours = (startDate: Date, endDate: Date): number => {
	const startDateIsDST = isDST(startDate);
	const endDateIsDST = isDST(endDate);
	const oneHour = 60 * 60 * 1000;

	if (startDateIsDST !== endDateIsDST) {
		if (startDateIsDST && !endDateIsDST) return -oneHour;
		if (!startDateIsDST && endDateIsDST) return oneHour;
	}
	return 0;
};

export const parsePredefinedRangeToRange = (predefinedRange: string): RangeWithKey | undefined => {
	const date = new Date();
	let startDate;
	let endDate;
	switch (predefinedRange) {
		case "today":
			startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
			break;
		case "yesterday":
			startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59);
			break;
		case "last_7_days":
			startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7, 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
			break;
		case "last_30_days":
			startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30, 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
			break;
		case "this_month":
			startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
			break;
		case "previous_month":
			startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59);
			break;
		case "last_3_months":
			startDate = moment(date).subtract(3, "months").startOf("day").toDate();
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
			break;
		case "last_6_months":
			startDate = moment(date).subtract(6, "months").startOf("day").toDate();
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
			break;
		case "last_12_months":
			startDate = moment(date).subtract(1, "year").startOf("day").toDate();
			endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
			break;
	}
	if (!startDate) return undefined;
	return {
		startDate,
		endDate,
		key: "selection",
	};
};

export const parsePredefinedRangeToDefaultString = (predefinedRange: string): string => {
	const range = parsePredefinedRangeToRange(predefinedRange);
	if (range) return parseRangeToDefaultString(range);
	return "";
};

export const isPredefinedRange = (val: string): boolean => {
	return (
		val === "today" ||
		val === "yesterday" ||
		val === "last_7_days" ||
		val === "last_30_days" ||
		val === "this_month" ||
		val === "previous_month" ||
		val === "last_3_months" ||
		val === "last_6_months" ||
		val === "last_12_months"
	);
};

export const isBtd = (val: string): boolean => {
	if (val === "bt" || val === "btd") return true;
	return false;
};

export const predefinedDateRangeParser = (value?: string): string => {
	if (!value) return "";
	const splitted = value.split(",");
	if (splitted[1] !== "undefined") return value;
	return splitted[0];
};

export const parseDatesToDefaultString = (startDate?: Date, endDate?: Date, toForm?: boolean): string => {
	if (!startDate || !endDate) {
		return "";
	}
	return `${parseDateToDefaultString(startDate)}${toForm ? "," : FILTER_VALUE_SEPARATOR}${parseDateToDefaultString(
		endDate
	)}`;
};
export const parseRangeToDefaultString = (item: Range, toForm?: boolean): string => {
	return parseDatesToDefaultString(item.startDate, item.endDate, toForm);
};

export const parseDatesToString = (startDate?: Date, endDate?: Date): string => {
	if (!startDate || !endDate) {
		return "";
	}
	return `${FormatUniversalDate(startDate, undefined, true)} - ${FormatUniversalDate(endDate, undefined, true)}`;
};
export const parseRangeToString = (item: Range): string => {
	return parseDatesToString(item.startDate, item.endDate);
};

export const parseStringToRange = (value?: string, defaultValue?: string, timeZone?: string): RangeWithKey => {
	if (!value) {
		value = defaultValue;
	}
	if (!value) {
		return {
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 0)),
			key: "selection",
		} as RangeWithKey;
	}
	const predefineRange = parsePredefinedRangeToRange(value);
	if (predefineRange)
		return {
			startDate: predefineRange.startDate,
			endDate: predefineRange.endDate,
			key: "selection",
		} as RangeWithKey;
	const datesSplit = value.split(" - ");
	const startDate = formatStringToDate(datesSplit[0]);
	const endDate = formatStringToDate(datesSplit[1]);
	if (!isValidDate(endDate) || !isValidDate(endDate))
		return {
			startDate: undefined,
			endDate: undefined,
			key: "selection",
		} as RangeWithKey;

	return {
		startDate,
		endDate,
		key: "selection",
	} as RangeWithKey;
};

export default isPredefinedRange;
