import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { InvoiceApi, InvoicePaymentApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { invoicePreviewIncludes } from "../../pages/Index/Preview";

interface Props {
	onAdd: (invoice: InvoiceApi) => void;
	onClose: () => void;
	invoice: InvoiceApi;
	payment: InvoicePaymentApi;
}

export const getInvoicePaymentOptions = (t: TFunction): Record<string, any>[] => [
	{ value: "BANK_TRANSFER", label: `${t("enums.payment_methods.payment_receipt_types.BANK_TRANSFER")}` },
	{ value: "CASH", label: `${t("enums.payment_methods.payment_receipt_types.CASH")}` },
	{ value: "CREDIT_CARD", label: `${t("enums.payment_methods.payment_receipt_types.CREDIT_CARD")}` },
	{ value: "ON_DELIVERY", label: `${t("enums.payment_methods.payment_receipt_types.ON_DELIVERY")}` },
	{ value: "ONLINE", label: `${t("enums.payment_methods.payment_receipt_types.ONLINE")}` },
	{ value: "COMPENSATION", label: `${t("enums.payment_methods.payment_receipt_types.COMPENSATION")}` },
	{ value: "DIRECT_DEBIT", label: `${t("enums.payment_methods.payment_receipt_types.DIRECT_DEBIT")}` },
	{ value: "BARTER", label: `${t("enums.payment_methods.payment_receipt_types.BARTER")}` },
	{ value: "ADVANCE", label: `${t("enums.payment_methods.payment_receipt_types.ADVANCE")}` },
	{ value: "CHECK", label: `${t("enums.payment_methods.payment_receipt_types.CHECK")}` },
	{ value: "BOND", label: `${t("enums.payment_methods.payment_receipt_types.BOND")}` },
	{ value: "CREDIT", label: `${t("enums.payment_methods.payment_receipt_types.CREDIT")}` },
	{ value: "OTHER", label: `${t("enums.payment_methods.payment_receipt_types.OTHER")}` },
	{ value: "VOUCHER", label: `${t("enums.payment_methods.payment_receipt_types.VOUCHER")}` },
	{ value: "CLIENT_ACCOUNT", label: `${t("enums.payment_methods.payment_receipt_types.CLIENT_ACCOUNT")}` },
];

const AddInvoiceOrderModal = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<Record<string, any>>({
		defaultValues: {
			...props.payment,
			payments: props.invoice.payments,
		},
		criteriaMode: "all",
	});

	const searchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};
	const isNewPayment = props.payment.id === undefined;
	const index = props.payment.id !== undefined ? Number(props.payment.id) : props.invoice.payments.length;
	const {
		control,
		formState: { errors },
		register,
		formState,
		setError,
		setValue,
		handleSubmit,
	} = form;
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";

	const onSubmit = handleSubmit(async (data: Record<string, any>) => {
		const updatedPayment = data.payments[index];
		setLoading(true);
		const params: Record<string, any> = invoicePreviewIncludes;
		const dto = { ...props.invoice };
		const updatedPayments = [...props.invoice.payments];
		updatedPayments[index] = updatedPayment;
		dto.payments = [...updatedPayments];
		dto.payments.map((payment) => {
			return { ...payment, id: undefined } as Record<string, any>;
		});
		try {
			let res;
			switch (props.invoice.document_type) {
				case "INVOICE":
					res = await api.organization().updateInvoice(dto, params);
					break;
				case "PROFORMA":
					res = await api.organization().updateProformaInvoice(dto, params);
					break;
				case "ADVANCE":
					res = await api.organization().updateAdvance(dto, params);
					break;
				case "CORRECTION":
					res = await api.organization().updateCorrection(dto, params);
					break;
			}
			if (res) {
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				props.onAdd(res);
			}
		} catch (e) {
			const submitErrors = handleException(e);
			if (
				submitErrors.filter(
					(f) =>
						f.field !== "price_paid" &&
						f.field !== "paid_at" &&
						f.field !== "price_paid" &&
						f.field !== "payment_method_id"
				).length > 0
			) {
				handleError.alert(e, addFlash);
			} else {
				handleError.form(e, setError, addFlash);
			}
			handleException(e);
		}
		setLoading(false);
	});

	useEffect(() => {
		setValue(`payment_method_id`, props.payment.payment_method_id);
		setValue(`payments.${index}.paid_at`, props.payment.paid_at);
	}, []);

	const payment = {
		id: props.payment.payment_method_id,
		name: props.payment.transaction?.payment_method_name,
	};

	return (
		<Modal show={true} onHide={() => props.onClose()}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!props.payment.id
							? t("modules.invoice.action.add_payment_to_invoice.title")
							: t("modules.invoice.action.edit_payment.title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={"row"}>
						<FormInput
							type="hidden"
							errors={errors}
							register={register}
							name={`payments.${index}.id`}
							value={props.payment.id}
							disabled={!isNewPayment && !props.payment.editable}
						/>
						<div className={"col-md-6"}>
							<FormDatePicker
								label={t("modules.invoice.field.paid_at_date.title")}
								timePicker={false}
								defaultValue={props.payment.paid_at}
								control={control}
								name={`payments.${index}.paid_at`}
								errors={errors}
							/>
						</div>
						<div className={"col-md-6"}>
							<FormSelectGroup
								label={t("common.word.payment_method")}
								name={`payments.${index}.payment_method_id`}
								errors={errors}
								defaultValue={{
									label: payment?.name,
									id: payment?.id,
								}}
								getOptionValue={(opt) => opt.id}
								getOptionLabel={(opt) => opt.label}
								loadOptions={searchPaymentMethods}
								control={control}
								data-testid={`payments.${index}.payment_method_id`}
							/>
						</div>
					</div>
					<div className={"row align-items-center"}>
						<div className={"col-md-6"}>
							<FormMoneyInput
								label={t("common.word.amount")}
								name={`payments.${index}.price_paid.amount`}
								currency={currency}
								defaultValue={props.payment.price_paid?.amount}
								errors={errors}
								control={control}
								disabled={!isNewPayment && !props.payment.editable}
							/>
						</div>
						<div className={"col-md-6"}>
							<FormCheck
								className={"pt-3"}
								label={t("modules.invoice.field.paid.modal.title")}
								type={"switch"}
								register={register}
								defaultChecked={props.payment.paid}
								name={`payments.${index}.paid`}
								errors={errors}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading variant="primary" onClick={onSubmit} loading={loading}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={() => props.onClose()}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
export default AddInvoiceOrderModal;
