import { useEffect } from "react";

declare global {
	interface WindowEventMap {
		RequestTotalItemsCountEvent: CustomEvent<never>;
	}
}

export const useListenEvent = <EventType extends keyof WindowEventMap>({
	eventName,
	callback,
	shouldRunCallbackOnAdd,
	shouldRunCallbackOnRemove,
}: {
	eventName: EventType;
	callback: (e?: WindowEventMap[EventType]) => void;
	shouldRunCallbackOnAdd?: boolean;
	shouldRunCallbackOnRemove?: boolean;
}): void => {
	useEffect(() => {
		if (shouldRunCallbackOnAdd) {
			callback();
		}

		window.addEventListener(eventName, callback);

		return () => {
			if (shouldRunCallbackOnRemove) {
				callback();
			}

			window.removeEventListener(eventName, callback);
		};
	}, [eventName, callback, shouldRunCallbackOnAdd, shouldRunCallbackOnRemove]);
};
