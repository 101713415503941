import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectUser } from "go-security/services/users/selectors";

interface Props {
	name: string;
	filter?: Record<string, string>;
	onChange: (value: Record<string, string>) => void;
}

const parseOrganizationIds = (name: string, filter?: Record<string, string>) => {
	return filter && filter[name]
		? filter[name]
				.split(FILTER_VALUE_SEPARATOR)
				.map((x) => Number(x))
				.filter((x) => !isNaN(x))
		: [];
};

const OrganizationReportComboComponent = (props: Props) => {
	const user = useSelector(selectUser);
	const organizations = user.organizations;
	const [organizationIds, setOrganizationIds] = useState<number[]>(parseOrganizationIds(props.name, props.filter));
	const [organizationIdValue, setOrganizationIdValue] = useState<string>(
		organizationIds.filter((x) => !organizations?.some((y) => y.id === x)).join(",")
	);

	if (!organizations) return null;

	const change = (value: number) => {
		let newOrganizationIds = [...organizationIds];
		if (organizationIds.includes(value)) {
			newOrganizationIds = newOrganizationIds.filter((id) => id !== value);
		} else {
			newOrganizationIds.push(value);
		}
		setOrganizationIds(newOrganizationIds);
		refreshFilters(newOrganizationIds);
	};
	const refreshFilters = (newOrganizationIds: number[]) => {
		const ids = [...new Set(newOrganizationIds)];

		const parserFilters: Record<string, any> = {
			[props.name]: ids.join(FILTER_VALUE_SEPARATOR),
		};

		props.onChange(parserFilters);
	};

	const onChangeCustomOrganizationIds = (value: string) => {
		setOrganizationIdValue(value);
	};

	const onBlurCustomOrganizationIds = (value: string) => {
		const newUserOrganizationIds = organizationIds.filter((x) => organizations?.some((y) => y.id === x));
		const newCustomOrganizationIdsString = value ? value.split(",") : [];
		const newCustomOrganizationIds: number[] = newCustomOrganizationIdsString
			.map((x) => Number(x))
			.filter((x) => !isNaN(x));

		refreshFilters([...newUserOrganizationIds, ...newCustomOrganizationIds]);
	};

	return (
		<div>
			<div className="content-navigation">
				<div role="group" className="me-2 text-nowrap flex-wrap dashboard-filters-buttons btn-group">
					{organizations.map((organization) => {
						return (
							<button
								type="button"
								onClick={() => change(organization.id)}
								className={`btn${
									organizationIds && organizationIds.includes(organization.id)
										? " btn-primary"
										: " btn-default"
								}`}
								key={organization.id}
							>
								{organization.name}
							</button>
						);
					})}
					<input
						type={"text"}
						value={organizationIdValue}
						onBlur={(e) => onBlurCustomOrganizationIds(e.target.value)}
						onChange={(e) => onChangeCustomOrganizationIds(e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
};

export default OrganizationReportComboComponent;
