import React, { FC, Suspense, useEffect, useState } from "react";
import { AxiosResponse, CancelTokenSource } from "axios";
import { Button } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PdfOrientationType } from "go-core/types";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { apiSecurity } from "go-security/services/Api/api";
import { OrganizationUserSecurityApi } from "go-security/services/Api/typesSecurity";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext, SegmentListService } from "go-segment/context";
import GenerateInvitationLinkModal from "./GenerateInvitationLinkModal";
import SecurityInviteUserModalFormComponent from "./InviteUserModalForm";
import UserEditModalForm from "./UserEditModalForm";

interface ListState {
	resource: any;
	showGenerateInvitationLinkAction?: boolean;
	organizationName?: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

const listName = "ORGANIZATION_USERS";
const resourceType = "ORGANIZATION_USERS";

const buildConfig = (
	t: TFunction,
	update: (user: OrganizationUserSecurityApi) => void,
	onRemove: (user: OrganizationUserSecurityApi) => void,
	setParams: (params: Record<string, any>) => void,
	data: any,
	segmentListService: SegmentListService,
	setUserToGenerateInvitationLink: (user: OrganizationUserSecurityApi) => void,
	showGenerateInvitationLinkAction?: boolean,
	organizationName?: string,
	companyName?: string,
	taxIdNo?: string,
	pdfOrientation?: PdfOrientationType,
	pdfFontSize?: string
) => {
	let config = {
		fields: [
			{
				id: "email",
				name: t("lib:go_component.user.list.table.columns.email"),
				type: "text",
				render: (item: OrganizationUserSecurityApi) => {
					return (
						<StickyColumnWithEntityStatus status={item.status}>
							<RenderLimitedText onClick={() => update(item)} textStyleIsLink minWidth={200}>
								{item.email}
							</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: OrganizationUserSecurityApi) => item.email,
			},
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: OrganizationUserSecurityApi) => {
					return (
						<RenderLimitedText minWidth={200}>
							{item.admin ? `${item.name} (Administrator)` : item.name}
						</RenderLimitedText>
					);
				},
				renderExport: (item: OrganizationUserSecurityApi) => {
					return item.admin ? `${item.name} (Administrator)` : item.name;
				},
			},
			{
				id: "role_name",
				name: t("lib:go_component.user.list.table.columns.role"),
				type: "text",
			},
		],
		actions: [
			{
				name: t("lib:common.action.edit"),
				click: (item: OrganizationUserSecurityApi) => update(item),
			},
			{
				name: t("lib:common.action.remove"),
				click: (item: OrganizationUserSecurityApi) => {
					onRemove(item);
				},
			},
			{
				name: t("lib:go_component.user.action.send_invitation.title"),
				visible: (item: OrganizationUserSecurityApi) => showGenerateInvitationLinkAction && !item.name,
				click: (item: OrganizationUserSecurityApi) => setUserToGenerateInvitationLink(item),
			},
		],
		selectedColumns: ["email", "name", "role_name"],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include = "permissions";
			setParams(params);
			return apiSecurity
				.organization()
				.getUsers(params, { cancelToken: sourceToken?.token })
				.then((res: AxiosResponse) => {
					return res.data.data;
				});
		},
		exportConfig: {
			title: t("lib:go_component.user.export_config.title"),
			filename: t("lib:common.word.users"),
			organization: organizationName,
			company: companyName,
			taxIdNo,
			pdfFontSize,
			pdfOrientation,
		},
		saveSegment: (segment: SegmentType) => {
			return segmentListService.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return config;
};

const List: FC<ListState> = ({
	resource,
	showGenerateInvitationLinkAction,
	taxIdNo,
	companyName,
	organizationName,
	pdfFontSize,
	pdfOrientation,
}) => {
	const { t } = useTranslation();
	const [params, setParams] = useState<Record<string, any>>({});
	const [inviteModal, setInviteModal] = useState(false);
	const [user, setUser] = useState<OrganizationUserSecurityApi | undefined>(undefined);
	const [responseData, setResponseData] = useState<OrganizationUserSecurityApi[] | undefined>(undefined);
	const { addSuccessFlash, addFlash } = useFlash();
	const confirmation = useConfirmation();
	const segmentContextValue = React.useContext(SegmentContext);
	const [userToGenerateInvitationLink, setUserToGenerateInvitationLink] = useState<
		OrganizationUserSecurityApi | undefined
	>(undefined);
	const isMobile = useWindowSize().isMobile;

	if (!resource) return null;
	const onUpdateOrganizationRole = (role: OrganizationUserSecurityApi) => {
		setUser(role);
	};

	const onRemove = async (item: OrganizationUserSecurityApi) => {
		try {
			await confirmation({
				title: t("lib:confirmation.title"),
				message: t("lib:confirmation.users.remove_user.message"),
			});
			await apiSecurity.organization().removeUser(item.email);
			addSuccessFlash(t("lib:common.flash.removed"));
			refresh();
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const data = resource.read();
	const config = buildConfig(
		t,
		onUpdateOrganizationRole,
		onRemove,
		setParams,
		data,
		segmentContextValue,
		setUserToGenerateInvitationLink,
		showGenerateInvitationLinkAction,
		organizationName,
		companyName,
		taxIdNo,
		pdfOrientation,
		pdfFontSize
	);
	const refresh = () => {
		if (config.fetch) {
			config
				.fetch(params)
				.then((res) => {
					setResponseData(res);
				})
				.catch((err: Exception | unknown) => {
					handleError.alert(err, addFlash);
				});
		}
	};

	const handleSave = () => {
		setUser(undefined);
		refresh();
	};
	const onInvite = () => {
		setInviteModal(false);
		refresh();
	};

	const buttons: ButtonProps[] = [
		{
			action: () => setInviteModal(true),
			title: t("lib:common.action.add"),
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			action: () => setInviteModal(true),
			title: t("lib:common.action.add"),
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("lib:common.word.users")} buttons={buttons} />}
			<ListData data={responseData} config={config} onFetch={setResponseData} mobileActions={mobileActions} />
			{user && <UserEditModalForm user={user} onHide={() => setUser(undefined)} handleSave={handleSave} />}
			{inviteModal && (
				<SecurityInviteUserModalFormComponent onHide={() => setInviteModal(false)} handleSave={onInvite} />
			)}
			{userToGenerateInvitationLink && (
				<GenerateInvitationLinkModal
					user={userToGenerateInvitationLink}
					onHide={() => setUserToGenerateInvitationLink(undefined)}
				/>
			)}
		</>
	);
};

interface Props {
	showGenerateInvitationLinkAction?: boolean;
	organizationName?: string;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

const SecurityUsersListComponent = ({
	showGenerateInvitationLinkAction,
	taxIdNo,
	companyName,
	organizationName,
	pdfOrientation,
	pdfFontSize,
}: Props): JSX.Element => {
	const [resource, setResource] = useState<any>();
	const segmentContextValue = React.useContext(SegmentContext);
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("lib:common.word.users"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<>
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					showGenerateInvitationLinkAction={showGenerateInvitationLinkAction}
					taxIdNo={taxIdNo}
					companyName={companyName}
					organizationName={organizationName}
					pdfOrientation={pdfOrientation}
					pdfFontSize={pdfFontSize}
				/>
			</Suspense>
		</>
	);
};
export default SecurityUsersListComponent;
