import React, { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderPaymentMethod from "../../../../../../../../../components/Common/Renderers/RenderPaymentMethod";
import { ReactComponent as RemoveSVG } from "../../../../../../../../../images/svg/remove.svg";
import { TransactionApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import ChangeTransactionPaymentMethod from "../../../components/ChangeTransactionPaymentMethod";
import InvoiceCard from "../../Orders/components/InvoiceCard";
import TransactionInvoiceCard from "./TransactionInvoiceCard";
import TransactionOrderCard from "./TransactionOrderCard";
import TransactionReportPaidCard from "./TransactionReportPaidCard";

interface Props {
	id: number | null;
	onClose: () => void;
	styles?: Record<string, any>;
	_transaction?: TransactionApi;
	handleSuccessAction?: () => void;
	canChangePaymentMethod?: boolean;
}

const TransactionPreview: FC<Props> = ({
	id,
	onClose,
	styles,
	_transaction,
	canChangePaymentMethod,
	handleSuccessAction,
}) => {
	const { t } = useTranslation();
	const [transaction, setTransaction] = useState<TransactionApi | undefined>(_transaction);
	const [loading, setLoading] = useState(false);
	const [applyMarginToTransactionPreview, setApplyMarginToTransactionPreview] = useState(() =>
		Boolean(document.getElementsByName("intercom-banner-frame")[0])
	);
	const { addFlash } = useFlash();
	const ref = useRef<HTMLDivElement>(null);
	const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] = useState(false);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		return () => {
			handleChangeTabTitle(t("modules.transaction.header.title"));
		};
	}, []);

	useEffect(() => {
		const mutationObserver = new MutationObserver(() => {
			setTimeout(() => {
				const banner = document.getElementsByName("intercom-banner-frame")[0];

				if (banner && !applyMarginToTransactionPreview) setApplyMarginToTransactionPreview(true);
			}, 300);
		});
		mutationObserver.observe(document.getElementsByTagName("body")[0], {
			attributes: false,
			childList: true,
			subtree: false,
		});

		return () => mutationObserver.disconnect();
	}, [applyMarginToTransactionPreview]);

	useEffect(() => {
		fetchTransaction();
	}, [id]);

	const handleHideDropdown = (event: KeyboardEvent) => {
		if (showChangePaymentMethodModal) return;
		if (event.key === "Escape") {
			onClose();
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (showChangePaymentMethodModal) return;
		if (ref.current && !ref.current?.contains(event.target as Node)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleHideDropdown, true);
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("keydown", handleHideDropdown, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	const fetchTransaction = async () => {
		const params: Record<string, any> = {
			include:
				"infos,payment_method,sub_transactions,contexts,contexts.context_id,contexts.tax_items,external_id_inconsistency",
		};
		try {
			if (!transaction && id) {
				setLoading(true);
				const res = await api.organization().getTransaction(id, params);
				setTransaction(res);
				handleChangeTabTitle(`#${res.id} | ${t("modules.transaction.header.title")}`);
				setLoading(false);
			} else {
				setTransaction(transaction);
				handleChangeTabTitle(`#${transaction?.id || ""} | ${t("modules.transaction.header.title")}`);
			}
		} catch (err) {
			setLoading(false);
			onClose();
			handleError.alert(err, addFlash);
		}
	};

	const style = styles ? { ...styles } : undefined;

	const getCanChangePaymentMethod = () => {
		if (transaction?.contexts?.some((context) => context.type === "REPORT_PAID")) return false;
		if ((transaction?.status !== "NEW" && transaction?.status !== "SUCCESS") || transaction?.type !== "PAY_IN")
			return false;
		return canChangePaymentMethod;
	};

	if (loading || !transaction) {
		return (
			<div className={`list-preview`} style={{ ...style, zIndex: 9999 }}>
				<div className={"list-preview-content"}>
					<LoadingContainer />
				</div>
			</div>
		);
	}

	return (
		<div
			className={`list-preview`}
			ref={ref}
			style={{ ...style, top: applyMarginToTransactionPreview ? "48px" : "0" }}
		>
			<div className="list-preview-header">
				<RenderImage data={{}} date={transaction.created_at} />
				<div className="header-content">
					<div className="title">#{transaction.id}</div>
					<div className="info">
						<span className={"me-4"}>{FormatDate(transaction.created_at)}</span>
						{transaction.amount && <span>{FormatMoney(transaction.amount)}</span>}
					</div>
					{transaction.payment_method && (
						<div className="info">
							<RenderPaymentMethod
								name={transaction.payment_method.name}
								status={transaction.status}
								type={transaction.payment_method.receipt_type}
							/>
						</div>
					)}
				</div>
				<div className={"list-preview-close"}>
					<RemoveSVG className={"icon"} onClick={onClose} />
				</div>
			</div>
			<div className="list-preview-content content-preview-body">
				{transaction.contexts
					.filter((ctx) => ctx.id)
					.sort((a, b) => {
						if (a.type === "ORDER") return -1;
						if (a.type === "INVOICE" && b.type === "CORRECTION") return -1;
						if (a.type === "CORRECTION" && b.type === "INVOICE") return 1;
						return 0;
					})
					.map((ctx) => {
						if (ctx.type === "REPORT_PAID") {
							return (
								<TransactionReportPaidCard
									key={ctx.id}
									contextId={ctx.number}
									id={ctx.id}
									inconsistent_reference_id={transaction.inconsistent_reference_id}
								/>
							);
						}
						if (ctx.type === "ORDER") {
							return (
								<TransactionOrderCard
									key={ctx.id}
									orderId={ctx.number}
									id={ctx.id}
									inconsistent_reference_id={transaction.inconsistent_reference_id}
								/>
							);
						}
						if (transaction?.contexts.filter((context) => context.type === "ORDER").length === 0)
							return (
								<TransactionInvoiceCard
									key={ctx.id}
									invoiceId={ctx.number}
									id={ctx.id}
									inconsistent_reference_id={transaction.inconsistent_reference_id}
								/>
							);
						return (
							<InvoiceCard
								key={ctx.id}
								invoice={ctx}
								inconsistent_reference_id={transaction.inconsistent_reference_id}
							/>
						);
					})}
				{getCanChangePaymentMethod() && (
					<div className="position-sticky bg-white bottom-0 left-0">
						<Button variant="add" className="w-100" onClick={() => setShowChangePaymentMethodModal(true)}>
							{t("modules.transaction.action.change_payment_method.title")}
						</Button>
					</div>
				)}
			</div>
			{showChangePaymentMethodModal && (
				<ChangeTransactionPaymentMethod
					transaction={transaction}
					show={showChangePaymentMethodModal}
					onHide={() => setShowChangePaymentMethodModal(false)}
					id={transaction.id}
					handleSuccessAction={(item) => {
						if (handleSuccessAction) handleSuccessAction();
						setTransaction(item as TransactionApi);
					}}
					documentNumber={transaction.id.toString()}
					editMode={true}
					price={transaction.amount}
				/>
			)}
		</div>
	);
};

export default TransactionPreview;
