import React, { useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmptyList from "go-core/components/EmptyList";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { parseToSelectedDateFilter } from "../../services/charts/chartService";
import { AggregateOption, DashboardCardConfig, salesAggregateOptions } from "../../services/charts/types";
import useDashboardContext from "../../services/useDashboardContext";
import { renderAggregateValue } from "../../utils/reportConfigUtils";
import { AggregateValuePercentageChange } from "../Charts/AggregateValuePercentageChange";
import DashboardReportSalesAdvancedChart from "./DashboardReportSalesAdvancedChart";
import ReportItemWrapper from "./ReportItemWrapper/ReportItemWrapper";

const DashboardReportSalesAdvanced = (): JSX.Element => {
	const { state } = useDashboardContext();
	const [selectedTab, setSelectedTab] = useState<AggregateOption>("total_money");
	const report = state?.data?.sales_advanced_reports?.reports[0];
	const { t } = useTranslation();
	const chartDataRangeType = state?.chartsConfig?.reportSalesAdvanced.range;
	const chartType = state?.chartsConfig.reportSalesAdvanced.type;
	const selectedFilters = parseToSelectedDateFilter(state.filtersConfig.selectedFilter.dateRange);
	const { currency } = useSelector(selectOrganization);
	const isMobile = useWindowSize().isMobile;

	const renderView = useMemo(() => {
		if (!state?.data?.sales_advanced_reports || !report?.sub_report || !report.sub_report.length) {
			return (
				<div className={"mt-3"}>
					<h5>{t("modules.dashboard.field.sale.title")}</h5>
					<EmptyList />
				</div>
			);
		}
		return (
			<DashboardReportSalesAdvancedChart
				chartDataRangeType={chartDataRangeType}
				aggregate={selectedTab}
				data={state?.data.sales_advanced_reports}
				selectedFilters={selectedFilters}
			/>
		);
	}, [state?.data?.sales_advanced_reports, selectedTab]);

	const renderHeader = useMemo(() => {
		if (!report?.sub_report || !report.sub_report.length || !report.aggregate?.sales) {
			return null;
		}
		return (
			<>
				<div className={`report-tabs ${isMobile ? "report-tabs--mobile" : ""}`}>
					{salesAggregateOptions.map((opt, index) => {
						const optionParts = opt.includes(".") ? opt.split(".").map((optionPart) => optionPart) : [opt];
						const currentPeriodValue = optionParts.reduce(
							(prev, curr) => prev?.[curr],
							report.aggregate.sales
						);
						const lastPeriodValue = optionParts.reduce(
							(compareSaleAggregate, saleAggregate) => compareSaleAggregate?.[saleAggregate],
							report.compare_aggregate.sales
						);

						return (
							<div
								className={`report-tab-item ${selectedTab === opt && "active"}`}
								key={opt}
								onClick={() => setSelectedTab(opt)}
							>
								<div className={"aggregate-title"}>{t(`modules.dashboard.field.${opt}.title`)}</div>
								<h4 className={`aggregate-value ${selectedTab === opt ? "active" : ""}`}>
									{renderAggregateValue(currentPeriodValue, currency || "")}
								</h4>
								<AggregateValuePercentageChange
									currentPeriodValue={currentPeriodValue}
									lastPeriodValue={lastPeriodValue}
								/>
							</div>
						);
					})}
				</div>
			</>
		);
	}, [selectedTab, state?.data?.sales_advanced_reports, chartType, currency, isMobile]);

	const config = {
		title: renderHeader,
		link: selectedTab === "extended.average_per_guest_money" ? "reports/guests" : "reports/orders",
		type: "sales_advanced_reports",
		chartDataRangeType,
		chartType,
	} as DashboardCardConfig;

	return (
		<Card className={"report-sales-advanced mb-3"}>
			<ReportItemWrapper config={config}>{renderView}</ReportItemWrapper>
		</Card>
	);
};

export default DashboardReportSalesAdvanced;

// t("modules.dashboard.field.total_money.title");
// t("modules.dashboard.field.transaction_count.title");
// t("modules.dashboard.field.net_profit_money.title");
// t("modules.dashboard.field.average_money.title");
// t("modules.dashboard.field.extended.average_per_guest_money.title");
