import React, { useContext, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Header, { ButtonProps } from "go-app/components/Header";
import { LoadingContainer } from "go-core/components/Loading";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { SegmentContext } from "go-segment/context";
import { SegmentApi } from "go-segment/services/types";
import ReportComboSegmentNavigation from "./components/ReportComboSegmentNavigation";
import UserReportsComboSegmentPage from "./pages/Segment";

const comboName = "REPORT_COMBO";
const resourceType = "REPORT_COMBO";

const UserReportsComboSegmentsPage = (props: RouteComponentProps) => {
	const [segments, setSegments] = useState<SegmentApi[]>([]);
	const [loading, setLoading] = useState(true);
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		fetchSegments();
	}, []);

	const fetchSegments = async () => {
		const filter = `resource_type|e=${resourceType}${FILTER_SEPARATOR}list_name|e=${comboName}`;
		const segments = await segmentContext.find({
			f: btoa(unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${filter}`))),
		});
		setSegments(segments.data.data);
		setLoading(false);
	};

	if (loading) {
		return <LoadingContainer />;
	}

	const buttons: ButtonProps[] = [
		{
			title: "Dodaj raport",
			path: "/combo_reports/create",
		},
	];

	return (
		<>
			<Header title={`Raporty Combo`} buttons={buttons} />
			<ReportComboSegmentNavigation segments={segments} />
			<Switch>
				<Route exact path={`${props.match.url}/:segmentId`} component={UserReportsComboSegmentPage} />
			</Switch>
		</>
	);
};

export default UserReportsComboSegmentsPage;
