import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { InvoiceApi, InvoiceItemApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	correction: InvoiceApi;
	items?: InvoiceItemApi[];
}

const OldCorrectionItemsFormTable: FC<Props> = ({ correction, items }) => {
	const { t } = useTranslation();
	return (
		<>
			<h5>{t("modules.invoice.field.correction_title.title")}</h5>
			<table className={"table table-preview old-correction-items"}>
				<thead>
					<tr>
						<th className={"action"} />
						<th className={"w-40"}>{t("modules.invoice.field.name.title")}</th>
						<th className={"w-10"}>{t("modules.invoice.field.pkwiu.title")}</th>
						<th className={"w-10"}>{t("modules.invoice.field.gtu.title")}</th>
						<th style={{ width: "9%" }}>
							{correction.type === "GROSS" ? (
								<>
									{t("modules.invoice.field.unit_price.title")}
									<br />
									{t("modules.invoice.field.gross.title")}
								</>
							) : (
								<>
									{t("modules.invoice.field.unit_price.title")}
									<br /> {t("modules.invoice.field.net.title")}
								</>
							)}
						</th>
						<th style={{ width: "10%" }}>
							{t("modules.invoice.field.vat_rate.title")} <br /> {t("modules.invoice.field.vat.title")}
						</th>
						<th style={{ width: "7%" }}>{t("common.word.quantity")}</th>
						<th>{t("modules.invoice.field.measure.title")}</th>
						<th style={{ textAlign: "right", paddingRight: 0 }}>
							{correction.type === "GROSS" ? (
								<>
									{t("common.word.value")}
									<br /> {t("modules.invoice.field.gross.title")}
								</>
							) : (
								<>
									{t("common.word.value")}
									<br /> {t("modules.invoice.field.net.title")}
								</>
							)}
						</th>
						<th className={"action"} />
					</tr>
				</thead>
				<tbody>
					{items?.map((item, index) => {
						return (
							<tr key={index}>
								<td className={"action"} />
								<td>{item.name}</td>
								<td>{item.pkwiu}</td>
								<td>{item.gtu}</td>
								<td className={"nowrap"}>{FormatMoney(item.price)}</td>
								<td>{item.tax?.amount}%</td>
								<td>{item.quantity}</td>
								<td>{item.volume}</td>
								<td className={"nowrap"}>{FormatMoney(item.price_sum)}</td>
								<td className={"action"} />
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
export default OldCorrectionItemsFormTable;
