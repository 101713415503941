import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmptyList from "go-core/components/EmptyList";
import { Loading } from "go-core/components/Loading";

interface Props {
	data: (string | number)[][] | undefined;
	loading: boolean;
}
const ComboReportTable = ({ data, loading }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="table-wrapper">
			<div className="table-list">
				{loading ? (
					<div className="list-loading">
						<Loading />
					</div>
				) : !data || data.length === 0 ? (
					<EmptyList
						title={t("lib:go_list.empty_list.title")}
						description={t("lib:go_list.empty_list.description")}
					/>
				) : (
					<Table>
						<thead>
							<tr>
								{(data[0] || []).map((column, index) => (
									<th key={`column-${index}`}>{column}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{data
								.filter((_, index) => index !== 0)
								.map((item, itemIndex) => (
									<tr key={`item-${itemIndex}`}>
										{item.map((value, valueIndex) => (
											<td key={`value-${valueIndex}`}>{value}</td>
										))}
									</tr>
								))}
						</tbody>
					</Table>
				)}
			</div>
		</div>
	);
};

export default ComboReportTable;
