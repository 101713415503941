import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoCircleSVG } from "../../../../../../../../../../../images/svg/dashboard/info-circle.svg";

const DetailedSettlementsInfo = () => {
	const { t } = useTranslation();
	const [showMore, setShowMore] = useState(false);

	return (
		<div className="d-flex flex-column mb-4 mt-3 ms-3 settlements-info">
			<div className="d-flex">
				<InfoCircleSVG className="me-2" />
				<div className="d-flex flex-column">
					<small className="text-muted">{t("modules.pos_report.field.settlement_info.title")}</small>
					<div className="d-flex flex-column ms-2">
						<div className="d-flex">
							<small className="me-2 text-muted">•</small>
							<small className="text-muted">
								{t("modules.pos_report.field.system_amount.description")}
							</small>
						</div>
						{showMore && (
							<>
								<div className="d-flex">
									<small className="me-2 text-muted">•</small>
									<small className="text-muted">
										{t("modules.pos_report.field.actual_amount.title")}
									</small>
								</div>
								<div className="d-flex">
									<small className="me-2 text-muted">•</small>
									<small className="text-muted">
										{t("modules.pos_report.field.value_in_brackets.title")}
									</small>
								</div>
							</>
						)}
					</div>
					{showMore && (
						<small className="text-muted mt-3">{t("modules.pos_report.field.sale_amount.title")}</small>
					)}
				</div>
			</div>
			<div className="action-ref mt-3" onClick={() => setShowMore((prevState) => !prevState)}>
				{showMore ? t("modules.pos_report.action.show_less.title") : t("lib:common.action.show_more")}
			</div>
		</div>
	);
};

export default DetailedSettlementsInfo;
