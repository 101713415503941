import React, { FC, useContext, useEffect, useState } from "react";
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { convertUTCDateToLocalDate, formatTimeAgo } from "go-core/components/Formatters/FormatDate";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as CarWithOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/car-with-order.svg";
import { ReactComponent as CarWithoutOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/car-without-order.svg";
import { ReactComponent as InactiveCarWithOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/inactive-car-with-order.svg";
import { ReactComponent as InactiveCarWithoutOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/inactive-car-without-order.svg";
import { DeliveryEmployeeApi, LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../services/context";
import {
	getEmployeeOrders,
	isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes,
} from "../../../../services/orderDelivery";
import LiveOrdersMapDeliveryEmployeeMarkerLabel from "./LiveOrdersMapDeliveryEmployeeMarkerLabel";
import LiveOrdersMapDeliveryEmployeeMarkerOrdersList from "./LiveOrdersMapDeliveryEmployeeMarkerOrdersList";
import LiveOrdersMapDeliveryEmployeeMarkerOrdersListMobile from "./LiveOrdersMapDeliveryEmployeeMarkerOrdersListMobile";

interface Props {
	employee: DeliveryEmployeeApi;
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	focusedOrder: LiveOrderApi | undefined;
}

const getEmployeeLastActivity = (employee: DeliveryEmployeeApi, organizationTimezone?: string) => {
	const localeDate = convertUTCDateToLocalDate(employee.coordinates_updated_at, organizationTimezone);
	return localeDate.toISOString();
};

const LiveOrdersMapDeliveryEmployeeMarker: FC<Props> = ({ employee, setFocusedOrder, focusedOrder }) => {
	const { orders, iconsScale } = useContext(OrdersContext);
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const employeeOrders = getEmployeeOrders(orders, employee);
	const isMobile = useWindowSize().isMobile;
	const { coordinates } = employee;
	const position = [coordinates?.latitude, coordinates?.longitude] as [number, number];
	const [isInactive, setIsInactive] = useState(
		isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes(employee, organization.timezone)
	);
	const [lastActivity, setLastActivity] = useState<string>(getEmployeeLastActivity(employee, organization.timezone));
	const [showOrdersList, setShowOrdersList] = useState(false);
	const [timeAgo, setTimeAgo] = useState(formatTimeAgo(lastActivity, t, true));

	useEffect(() => {
		const interval = setInterval(() => {
			setLastActivity(getEmployeeLastActivity(employee, organization.timezone));
			setTimeAgo(formatTimeAgo(lastActivity, t, true));
			setIsInactive(isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes(employee, organization.timezone));
		}, 5000);
		return () => {
			clearInterval(interval);
		};
	}, [employee]);

	useEffect(() => {
		if (!isMobile && showOrdersList) {
			setShowOrdersList(false);
		}
	}, [isMobile]);

	const getMarkerIconSvg = () => {
		if (isInactive && employeeOrders.length === 0) return <InactiveCarWithoutOrderSVG />;
		if (isInactive && employeeOrders.length > 0) return <InactiveCarWithOrderSVG />;
		if (!isInactive && employeeOrders.length === 0) return <CarWithoutOrderSVG />;
		return <CarWithOrderSVG />;
	};

	const markerIcon = L.divIcon({
		className: "",
		html: ReactDOMServer.renderToString(
			<div className="w-100 h-100 d-flex align-items-end justify-content-center">
				<div
					style={{
						transform: `scale(${iconsScale})`,
						transformOrigin: "center 40px",
					}}
				>
					{getMarkerIconSvg()}
				</div>
			</div>
		),
		iconAnchor: [20, 20],
		iconSize: [40, 40],
	});

	const eventHandlers = {
		click: () => isMobile && setShowOrdersList(true),
	};

	if (!coordinates) return <></>;

	return (
		<>
			{showOrdersList && isMobile && (
				<LiveOrdersMapDeliveryEmployeeMarkerOrdersListMobile
					onHide={() => setShowOrdersList(false)}
					employee={employee}
					orders={employeeOrders}
					setFocusedOrder={setFocusedOrder}
					noLastActivity={isInactive}
					timeAgo={timeAgo}
				/>
			)}
			<Marker
				position={position}
				icon={markerIcon}
				alt={employeeOrders[0]?.id?.toString()}
				eventHandlers={eventHandlers}
			>
				<LiveOrdersMapDeliveryEmployeeMarkerLabel employee={employee} orders={employeeOrders} />
				{!showOrdersList && !isMobile && (
					<LiveOrdersMapDeliveryEmployeeMarkerOrdersList
						employee={employee}
						orders={employeeOrders}
						setFocusedOrder={setFocusedOrder}
						focusedOrder={focusedOrder}
						noLastActivity={isInactive}
						timeAgo={timeAgo}
					/>
				)}
			</Marker>
		</>
	);
};

export default LiveOrdersMapDeliveryEmployeeMarker;
