import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { DiagnoseApi, DiagnoseElementApi } from "go-component/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";

interface Props {
	diagnostics: DiagnoseApi[];
	diagnoseFix: (fixId: string, organizationId?: number) => Promise<{}>;
	organization: Record<string, any>;
	renderDiagnseElementLink: (diagnoseElement: DiagnoseElementApi, id: string, title: string) => JSX.Element;
	diagnoseElementTitleKeyFormat: string;
}

export const DiagnosticsPreview = ({
	diagnostics,
	diagnoseFix,
	organization,
	renderDiagnseElementLink,
	diagnoseElementTitleKeyFormat,
}: Props) => {
	const { t } = useTranslation();
	const [diagnoseItems, setDiagnoseItems] = useState<Record<string, any>>(
		diagnostics.map((diag) => {
			return { ...diag, loading: false };
		})
	);
	const { addFlash, addSuccessFlash } = useFlash();
	const { id: organizationId } = organization;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle("Narzędzia diagnostyczne - błędy");
	}, []);

	const renderLink = (el: DiagnoseElementApi) => {
		const id = el.id;
		const title = `${t(`${diagnoseElementTitleKeyFormat}.${el.resource_type}`)} #${id}`;
		return renderDiagnseElementLink(el, id, title);
	};

	const fixElement = async (el: Record<string, any>, index: number) => {
		setDiagnoseItems([
			...diagnoseItems.map((diag: DiagnoseApi, ind: number) =>
				index === ind ? { ...diag, loading: true } : diag
			),
		]);
		try {
			await diagnoseFix(el.fix_id, organizationId);
			addSuccessFlash(t("lib:common.flash.completed"));
			setDiagnoseItems([
				...diagnoseItems.map((diag: DiagnoseApi, ind: number) =>
					index === ind ? { ...diag, loading: true } : diag
				),
			]);
			setDiagnoseItems([
				...diagnoseItems.map((diag: DiagnoseApi, ind: number) =>
					index === ind ? { ...diag, loading: false } : diag
				),
			]);
		} catch (e) {
			handleError.alert(e, addFlash);
			setDiagnoseItems([
				...diagnoseItems.map((diag: DiagnoseApi, ind: number) =>
					index === ind ? { ...diag, loading: false } : diag
				),
			]);
		}
	};

	return (
		<>
			<Header title="Narzędzia diagnostyczne - błędy" />
			<table className={"table table-preview"}>
				<thead>
					<tr>
						<th>info</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{diagnoseItems.map((item: Record<string, any>, index: number) => {
						return (
							<React.Fragment key={item.message}>
								<tr key={index} style={{ background: "#F8F9FA", fontWeight: "bold" }}>
									<td>{item.message}</td>
									<td>
										{item.fix_id && (
											<ButtonLoading
												loading={item.loading}
												onClick={() => fixElement(item, index)}
											>
												Napraw
											</ButtonLoading>
										)}
									</td>
								</tr>
								{item.elements.map((el: DiagnoseElementApi, index: number) => {
									return (
										<tr key={`el_${index}`}>
											<td>
												{el.link ? (
													<a href={el.link} target="_blank" rel="noreferrer">
														{`${t(
															`${diagnoseElementTitleKeyFormat}.${el.resource_type}`
														)} #${el.id}`}
													</a>
												) : (
													<>{renderLink(el)}</>
												)}
											</td>
											<td />
										</tr>
									);
								})}
							</React.Fragment>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
