import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { MultipleActionsParams } from "go-list/list/services/types";
import { ItemGroupType } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	onClose: () => void;
	items: number[];
	handleSave: () => void;
	allItemsSelected: boolean;
	multipleActionParams?: MultipleActionsParams;
}

interface FormProps {
	type: ItemGroupType | null;
}

export default function ChangeProductTypeModal({
	onClose,
	items,
	handleSave,
	allItemsSelected,
	multipleActionParams,
}: Props): JSX.Element {
	const form = useForm<FormProps>({
		defaultValues: {},
		criteriaMode: "all",
	});
	const [loading, setLoading] = useState(false);
	const {
		setError,
		handleSubmit,
		formState,
		formState: { errors },
		control,
	} = form;
	const { t } = useTranslation();
	const { addFlash, addSuccessFlash } = useFlash();

	const productTypes = ["PRODUCT", "MODIFIER", "PACKAGE"].map((pt) => ({
		value: pt,
		label: t(`enums.items.type.${pt}`),
	}));

	const onSubmit = handleSubmit(async (data: FormProps) => {
		if (data.type === undefined) data.type = null;
		setLoading(true);
		const params: Record<string, any> = multipleActionParams ? { ...multipleActionParams } : {};
		if (!allItemsSelected) params.id = items.join(",");
		try {
			await api.organization().patchItemGroups(data, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleSave();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
	});
	return (
		<Modal show={true} onHide={onClose}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<h5>{t("modules.item_group.action.set_product_type.title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						control={control}
						label={t("common.word.type", { ns: "lib" })}
						options={productTypes}
						name="type"
						errors={errors}
						data-testid="type"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
}
