import React, { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { FormDirty } from "go-form/components/FormDirty";
import { FormErrorMessageAlert } from "go-form/components/FormErrorMessageAlert";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { api } from "../../../../../../../services/Api/api";

interface MigrationFormProps {
	types: boolean[];
	reset: boolean;
	old_organization_id?: number;
	batch_size: number;
}

interface Props {
	handleUpdate: (data: Record<string, any>) => void;
}

const MigrationForm: FC<Props> = ({ handleUpdate }) => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [types, setTypes] = useState<Record<string, any> | undefined>(undefined);
	const { t } = useTranslation();
	const history = useHistory();
	const [selectedAll, setSelectedAll] = useState(false);
	const form = useForm<MigrationFormProps>({
		defaultValues: {
			types: [],
			reset: false,
			batch_size: 50,
		},
	});
	const {
		formState,
		formState: { errors },
		setValue,
		register,
		control,
		handleSubmit,
		getValues,
		setError,
		clearErrors,
	} = form;
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.migration.header.data_migration.title"));
		onCheckMigration();
	}, []);

	const onSubmit = handleSubmit(async (data: MigrationFormProps) => {
		const newData = { ...data };
		newData.types = [];
		types?.forEach((type: boolean, index: number) => {
			if (data.types[index]) {
				newData.types.push(type);
			}
		});
		setLoading(true);
		try {
			const res = await api.organization().migrate(newData);
			handleUpdate(res.data.data);
			setLoading(false);
		} catch (e) {
			handleError.form(e, setError, addFlash);
			setLoading(false);
		}
	});

	const onSubmit2 = async () => {
		const newData = getValues();
		newData.types = [];
		types?.forEach((type: boolean, index: number) => {
			if (getValues().types[index]) {
				newData.types.push(type);
			}
		});
		newData.reset = true;
		setLoading2(true);
		try {
			const res = await api.organization().migrate(newData);
			handleUpdate(res.data.data);
			setLoading2(false);
		} catch (e) {
			handleError.form(e, setError, addFlash);
			setLoading2(false);
		}
	};
	const onSubmit3 = async () => {
		const newData = getValues();
		newData.types = [];
		types?.forEach((type: boolean, index: number) => {
			if (getValues().types[index]) {
				newData.types.push(type);
			}
		});
		setLoading3(true);
		try {
			const res = await api.organization().finishMigration(newData);
			handleUpdate(res.data.data);
			setLoading3(false);
		} catch (e) {
			handleError.form(e, setError, addFlash);
			setLoading3(false);
		}
	};

	const searchOrganizations = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getMigrationOrganizationsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onCheckMigration = async () => {
		try {
			const res = await api.organization().checkMigrationTypes();
			setTypes(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!types) {
		return <LoadingContainer />;
	}

	const selectAll = () => {
		setSelectedAll(!selectedAll);
		clearErrors("types");
		types.forEach((type: boolean, index: number) => {
			setValue(`types.${index}`, !selectedAll);
		});
	};

	return (
		<>
			<Header title={t("modules.migration.header.data_migration.title")} />
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<fieldset className="form-group">
					<h5>{t("modules.migration.action.choose_location_to_migrate.title")}</h5>
					<FormSelectGroup
						label={t("common.word.location", { ns: "lib" })}
						name="old_organization_id"
						errors={errors}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						defaultValue={undefined}
						loadOptions={searchOrganizations}
						control={control}
						data-testid="old_organization_id"
					/>
				</fieldset>
				<fieldset className={"form-group"}>
					<h5>{t("modules.migration.action.choose_resource_to_migrate.title")}</h5>
					<FormErrorMessageAlert errors={errors} name="types" />
					<div className={"row"}>
						{types.map((type: boolean, index: number) => {
							return (
								<div key={index} className={"col-md-6"}>
									<FormCheck
										type={"switch"}
										name={`types.${index}`}
										errors={errors}
										onClick={() => clearErrors("types")}
										label={t(`modules.migration.field.${type}.title`)}
										register={register}
									/>
								</div>
							);
						})}
					</div>
					<Form.Check
						id={"select-all"}
						type={"switch"}
						checked={selectedAll}
						onClick={selectAll}
						label={t("common.word.sellect_all")}
					/>
				</fieldset>
				<fieldset className={"form-group"}>
					<FormNumberInput
						label={t("modules.migration.field.data_limit.title")}
						errors={errors}
						suffix={""}
						name={"batch_size"}
						control={control}
					/>
				</fieldset>
				<div className="form-footer">
					<Form.Group className="form-group">
						<ButtonLoading loading={loading} variant="primary" type="submit">
							{t("modules.migration.action.migrate.title")}
						</ButtonLoading>
						<ButtonLoading loading={loading2} variant="primary" type="button" onClick={onSubmit2}>
							{t("modules.migration.action.migrate_from_beginning.title")}
						</ButtonLoading>
						<ButtonLoading loading={loading3} variant="primary" type="button" onClick={onSubmit3}>
							{t("modules.migration.action.finish_migration.title")}
						</ButtonLoading>
						{formState.isDirty &&
							Object.keys(formState.dirtyFields).length > 0 &&
							window.history.length > 1 && (
								<Button variant="light" type="button" onClick={() => history.goBack()}>
									{t("lib:common.action.cancel")}
								</Button>
							)}
					</Form.Group>
				</div>
			</FormDirty>
		</>
	);
};
export default MigrationForm;

// t("modules.migration.field.WORKPLACE.title");
// t("modules.migration.field.SEGMENT.title");
// t("modules.migration.field.WORK_TIME.title");
// t("modules.migration.field.EMPLOYEE.title");
// t("modules.migration.field.VENUE_ROLE.title");
// t("modules.migration.field.VENUE.title");
// t("modules.migration.field.TRANSACTION.title");
// t("modules.migration.field.USER.title");
// t("modules.migration.field.PAYMENT_SOURCE.title");
// t("modules.migration.field.ORGANIZATION.title");
// t("modules.migration.field.POS_REPORT.title");
// t("modules.migration.field.REPORT_EXTERNAL.title");
// t("modules.migration.field.DIRECTION.title");
// t("modules.migration.field.POINT_OF_SALE.title");
// t("modules.migration.field.INVOICE.title");
// t("modules.migration.field.APP.title");
// t("modules.migration.field.APP_LINK_GOORDER.title");
// t("modules.migration.field.APP_LINK_LOCATIONS.title");
// t("modules.migration.field.INVOICE_DETAILS.title");
// t("modules.migration.field.PRINTOUT_TEMPLATE.title");
// t("modules.migration.field.CLIENT.title");
// t("modules.migration.field.ORDER.title");
// t("modules.migration.field.ORDER_PREPARATION_STATUS.title");
// t("modules.migration.field.CLIENT_GROUP.title");
// t("modules.migration.field.MENU_PRICE_LIST.title");
// t("modules.migration.field.MENU_DISCOUNT.title");
// t("modules.migration.field.IMAGE_RESOURCE.title");
// t("modules.migration.field.CATEGORY.title");
// t("modules.migration.field.FULL_MENU.title");
// t("modules.migration.field.TERMINAL_NOTIFICATION.title");
// t("modules.migration.field.CUSTOM_FIELD.title");
// t("modules.migration.field.TERMINAL.title");
// t("modules.migration.field.DEVICE.title");
// t("modules.migration.field.TAX.title");
// t("modules.migration.field.PAYMENT_METHOD.title");
// t("modules.migration.field.EMPLOYEE_ACTIVITY.title");
// t("modules.migration.field.ROOM.title");
// t("modules.migration.field.RESERVATION.title");
// t("modules.migration.field.CURRENCY_RATE.title");
// t("modules.migration.field.AVAILABILITY.title");
// t("modules.migration.field.DEFAULT_COMMENT.title");
// t("modules.migration.field.RECEIPT.title");
