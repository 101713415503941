import React from "react";
import { useTranslation } from "react-i18next";
import DashboardTab from "./DashboardTab";

export type tabType = "GENERAL" | "EXTENDED";

interface Props {
	setActiveTab: (tab: tabType) => void;
	activeTab: tabType;
}

const DashboardTabs = ({ setActiveTab, activeTab }: Props) => {
	const { t } = useTranslation();
	const tabs: tabType[] = ["GENERAL", "EXTENDED"];

	const getTabTitle = (tab: tabType) => {
		switch (tab) {
			case "GENERAL":
				return t("modules.dashboard.field.general.title");
			case "EXTENDED":
				return t("modules.dashboard.field.extended.title");
			default:
				return "";
		}
	};

	return (
		<div className="dashboard-tabs">
			{tabs.map((tab) => (
				<DashboardTab
					title={getTabTitle(tab)}
					key={tab}
					onClick={() => setActiveTab(tab)}
					isActive={tab === activeTab}
				/>
			))}
		</div>
	);
};

export default DashboardTabs;
