import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { InvoiceApi, InvoiceItemApi } from "../../../../../../../../services/Api/Organization/types";
import OldCorrectionItemsTable from "./OldCorrectionItemsTable";

interface Props {
	correction: InvoiceApi;
	items?: InvoiceItemApi[];
}

const InvoiceCorrectionPositionsCard: FC<Props> = ({ correction, items }) => {
	const { t } = useTranslation();
	const nestedCellStyles = {
		borderTop: "none",
		verticalAlign: "top",
		paddingTop: 0,
		paddingBottom: "20px",
	};

	const labelStyles = {
		fontWeight: 500,
		fontSize: "12px",
		color: "#6C757D",
		marginBottom: "16px",
		maxWidth: "60px",
	};

	const verticalTop = {
		verticalAlign: "top",
		paddingTop: "24px",
	};
	const paidMoney = correction.payments
		.filter((f) => f.paid)
		.reduce((acc, val) => acc + (val.price_paid?.amount ? val.price_paid?.amount : 0), 0);
	const linkedInvoice = correction.linked_invoices.find((f) => f.document_type === "INVOICE");
	const organization = useSelector(selectOrganization);
	const currency = organization?.currency || "";

	return (
		<Card>
			<Card.Body>
				<OldCorrectionItemsTable correction={correction} items={items} />
				<h5>{t("modules.invoice.field.title_2.title")}</h5>
				<div className={"table-responsive"}>
					<table className={"table table-preview"}>
						<thead>
							<tr>
								<th className={"w-40"}>{t("modules.invoice.field.name.title")}</th>
								<th className={"w-10"}>{t("modules.invoice.field.pkwiu.title")}</th>
								<th className={"w-10"}>{t("modules.invoice.field.gtu.title")}</th>
								<th style={{ width: "9%" }}>
									{correction.type === "GROSS" ? (
										<>
											{t("modules.invoice.field.unit_price.title")}
											<br /> {t("modules.invoice.field.gross.title")}
										</>
									) : (
										<>
											{t("modules.invoice.field.unit_price.title")}
											<br /> {t("modules.invoice.field.net.title")}
										</>
									)}
								</th>
								<th className={"w-1"}>{t("modules.invoice.field.tax_value.title")}</th>
								<th>{t("common.word.quantity")}</th>
								<th>{t("modules.invoice.field.measure.title")}</th>
								<th className={"w-1"} />
								<th className={"w-1"}>
									{correction.type === "GROSS" ? (
										<>
											{t("common.word.value")}
											<br /> {t("modules.invoice.field.gross.title")}
										</>
									) : (
										<>
											{t("common.word.value")}
											<br /> {t("modules.invoice.field.net.title")}
										</>
									)}
								</th>
							</tr>
						</thead>
						<tbody>
							{correction.items.map((item, index) => {
								return (
									<tr key={index}>
										<td>{item.name}</td>
										<td>{item.pkwiu}</td>
										<td>{item.gtu}</td>
										<td>{FormatMoney(item.price)}</td>
										<td>{item.tax?.name}</td>
										<td>{item.quantity}</td>
										<td>{item.volume}</td>
										<td />
										<td>{FormatMoney(item.price_sum)}</td>
									</tr>
								);
							})}
							<tr className={"summary"}>
								<td colSpan={3} style={verticalTop}>
									<div className={"d-flex flex-column"}>
										<span style={{ marginBottom: "30px" }}>
											<strong>{t("common.word.summary", { ns: "lib" })}</strong>
										</span>
										<span>{t("modules.invoice.field.summary_taxes.title")}</span>
									</div>
								</td>
								<td style={verticalTop}>
									<div className={"d-flex flex-column"}>
										<span style={labelStyles}>
											{t("modules.invoice.field.total_price_net.title")}
										</span>
										{correction.summary.taxes.map((tax, index) => {
											return (
												<div key={index} className={"mb-1 nowrap"}>
													{FormatMoney(tax.price_net)}
												</div>
											);
										})}
									</div>
								</td>
								<td style={verticalTop}>
									<span style={labelStyles}>{t("modules.invoice.field.tax_value.title")}</span>
									{correction.summary.taxes.map((tax, index) => {
										return (
											<div key={index} className={"mb-1 nowrap"}>
												{tax.tax_name}
											</div>
										);
									})}
								</td>
								<td style={verticalTop} className={"w-1"}>
									<span style={{ ...labelStyles, maxWidth: "40px" }}>
										{t("modules.invoice.field.tax_amount.title")}
									</span>
									{correction.summary.taxes.map((tax, index) => {
										return (
											<div key={index} className={"mb-1 nowrap"}>
												{FormatMoney(tax.price_tax)}
											</div>
										);
									})}
								</td>
								<td colSpan={2} />
								<td style={verticalTop}>
									<span style={{ ...labelStyles, maxWidth: "100%", marginLeft: "5px" }}>
										{t("modules.invoice.field.total_price_gross.title")}
									</span>
									{correction.summary.taxes.map((tax, index) => {
										return (
											<div key={index} className={"mb-1 nowrap"}>
												{FormatMoney(tax.price_gross)}
											</div>
										);
									})}
								</td>
							</tr>
							<tr className={"summary"}>
								<td className={"nowrap"} style={nestedCellStyles} colSpan={3}>
									<div className={"d-flex flex-column"}>
										<span>
											<strong>{t("common.word.altogether")}</strong>
										</span>
										<span>
											{(linkedInvoice?.price_sum_net?.amount || 0) >
											(correction.summary.price_sum_net.amount || 0)
												? t("modules.invoice.field.amount_decreasing_tax_base.title")
												: t("modules.invoice.field.amount_increasing_tax_base.title")}
										</span>
										<span>
											{(linkedInvoice?.price_sum_tax?.amount || 0) >
											(correction.summary.price_sum_tax.amount || 0)
												? t("modules.invoice.field.amount_decreasing_tax_amount.title")
												: t("modules.invoice.field.amount_increasing_tax_amount.title")}
										</span>
										<span>
											{(linkedInvoice?.price_sum_gross?.amount || 0) >
											(correction.summary?.price_sum_gross?.amount || 0)
												? t("modules.invoice.field.to_return.title")
												: t("modules.invoice.field.to_pay2.title")}
										</span>
									</div>
								</td>
								<td className={"nowrap"} style={nestedCellStyles}>
									<div className={"d-flex flex-column"}>
										<span>
											<strong>{FormatMoney(correction.summary.price_sum_net)}</strong>
										</span>
									</div>
								</td>
								<td className={"nowrap"} style={nestedCellStyles} />
								<td className={"nowrap"} style={nestedCellStyles}>
									<div className={"d-flex flex-column"}>
										<span>
											<strong>{FormatMoney(correction.summary.price_sum_tax)}</strong>
										</span>
									</div>
								</td>
								<td colSpan={2} style={nestedCellStyles} />
								<td className={"nowrap"} style={nestedCellStyles}>
									<div className={"d-flex flex-column"}>
										<span>
											<strong>{FormatMoney(correction.summary.price_sum_gross)}</strong>
										</span>
										<span>
											{FormatMoney({
												amount: Math.abs(
													(linkedInvoice?.price_sum_net?.amount || 0) -
														(correction.summary.price_sum_net.amount || 0)
												),
												currency,
											})}
										</span>
										<span>
											{FormatMoney({
												amount: Math.abs(
													(linkedInvoice?.price_sum_tax?.amount || 0) -
														(correction.summary.price_sum_tax.amount || 0)
												),
												currency,
											})}
										</span>
										<span>
											{FormatMoney({
												amount: Math.abs(
													(linkedInvoice?.price_sum_gross?.amount || 0) -
														(correction.summary?.price_sum_gross?.amount || 0)
												),
												currency,
											})}
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={8} className={"pb-0"} style={{ paddingTop: "20px" }}>
									<div className={"d-flex flex-column"}>
										<span className={"mb-1"}>
											{t("modules.invoice.field.comment.title")}:{" "}
											<span className={"text-muted"}>
												{correction.comment ? correction.comment : t("common.word.lack")}
											</span>
										</span>
										<span className={"mb-1 nowrap"}>{t("modules.invoice.field.paid.title")}</span>
										<span className={"mb-1 nowrap"}>
											{(paidMoney || 0) > (correction.summary?.price_sum_gross?.amount || 0)
												? t("modules.invoice.field.to_return2.title")
												: t("modules.invoice.field.to_pay.title")}
										</span>
									</div>
								</td>
								<td className={"pb-0"}>
									<div className={"d-flex flex-column"}>
										<span className={"mb-1"}>&nbsp;</span>
										<span className={"mb-1 nowrap"}>
											{correction.payments.length === 0
												? FormatMoney({
														amount: 0,
														currency,
												  })
												: FormatMoney({ amount: paidMoney, currency })}
										</span>
										<span className={"mb-1 nowrap"}>
											{FormatMoney({
												amount: Math.abs(
													(correction?.summary?.price_sum_gross?.amount || 0) -
														(paidMoney || 0)
												),
												currency,
											})}
										</span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Card.Body>
		</Card>
	);
};
export default InvoiceCorrectionPositionsCard;
