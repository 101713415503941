import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditSVG } from "../../../../images/svg/actions/edit.svg";

interface ActionProps {
	name?: string;
}

const EditAction: FC<ActionProps> = (props: ActionProps) => {
	const { t } = useTranslation();
	const name = props.name ? props.name : t("common.action.edit", { ns: "lib" });
	return (
		<div className="d-flex">
			<div className="common-actions icon">
				<EditSVG />
			</div>
			{name}
		</div>
	);
};
export default EditAction;
