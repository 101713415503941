import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import Page404 from "go-app/components/Page404/Page404";
import EmployeesNavigation from "./components/EmployeesNavigation";
import OrganizationEmployeesIndexPage from "./pages/Index";
import OrganizationEmployeesOnlinePage from "./pages/Online";
import OrganizationEmployeesWorkplacesPage from "./pages/Workplaces";
import OrganizationEmployeesWorktimesPage from "./pages/Worktimes";
import OrganizationEmployeesWorktimesReportPage from "./pages/WorktimesReport";

const OrganizationEmployeesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"page-container"}>
			<EmployeesNavigation />
			<Switch>
				<Route path={`${props.match.url}/online`} component={OrganizationEmployeesOnlinePage} />
				<Route path={`${props.match.url}/workplaces`} component={OrganizationEmployeesWorkplacesPage} />
				<Route
					path={`${props.match.url}/worktimes_report`}
					component={OrganizationEmployeesWorktimesReportPage}
				/>
				<Route path={`${props.match.url}/worktimes`} component={OrganizationEmployeesWorktimesPage} />
				<Route path={`${props.match.url}/`} component={OrganizationEmployeesIndexPage} />
				<Route component={Page404} />
			</Switch>
		</div>
	);
};

export default OrganizationEmployeesPage;
