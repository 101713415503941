import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { CompanyInfoApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import CompanyInfoForm from "./components/CompanyInfoForm";

const OrganizationSettingsInfoCompanyPage = (): JSX.Element => {
	const [companyInfo, setCompanyInfo] = useState<CompanyInfoApi | undefined>();
	const { addFlash } = useFlash();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		handleChangeTabTitle(t("modules.setting.field.company_info.title"));
		onFetchCompanyInfo();
	}, []);

	const onFetchCompanyInfo = async () => {
		try {
			const res = await api.organization().getCompanyInfo();
			setCompanyInfo(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	return <>{companyInfo ? <CompanyInfoForm companyInfo={companyInfo} /> : <LoadingContainer />}</>;
};

export default OrganizationSettingsInfoCompanyPage;
