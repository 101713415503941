import React from "react";
import { Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { DirectionApi, ItemApi, ItemGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../../services/Api/types";

interface Props {
	form: UseFormReturn<ItemGroupApi>;
	advancedDirectionView: boolean;
	changeDefaultDirectionView: () => void;
	items: ItemApi[];
	setAdjustItems: React.Dispatch<React.SetStateAction<boolean>>;
	adjustItems: boolean;
}

const ItemsDirectionsForm = ({
	form,
	advancedDirectionView,
	changeDefaultDirectionView,
	items,
	setAdjustItems,
	adjustItems,
}: Props) => {
	const {
		formState: { errors },
		control,
		setValue,
	} = form;
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const searchDirections = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getDirectionsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const handleChangeDirection = (index: number, direction?: SearchSelectApi) => {
		setValue(
			`items.${index}.direction`,
			direction
				? ({
						name: direction.label,
						id: direction.id,
				  } as DirectionApi)
				: undefined
		);
	};

	return (
		<div className="limit-component-visibility-form-box mb-3">
			<div className="form-box-check">
				<Form.Check
					type="switch"
					id="default_direction"
					checked={advancedDirectionView}
					onClick={changeDefaultDirectionView}
				/>
				<Form.Check.Label onClick={changeDefaultDirectionView}>
					<h6>{t("modules.menu.field.default_direction.title")}</h6>
				</Form.Check.Label>
			</div>
			<div className="form-box-content">
				<div className="form-box-title">
					<Form.Check.Label onClick={changeDefaultDirectionView}>
						<h6>{t("modules.menu.field.default_direction.title")}</h6>
					</Form.Check.Label>
				</div>
				<small className="text-muted">
					{t("modules.menu.field.default_direction_description.title", {
						name_female_dot: `${t("modules.menu.field.product.plural.title")}.`,
					})}
				</small>
				{advancedDirectionView && (
					<>
						{items.length > 1 && (
							<div className="form-box-check my-3 adjust-variants-box">
								<Form.Check
									type="switch"
									id="adjust_items"
									checked={adjustItems}
									label={t("modules.menu.action.adjust_variants.title")}
									onClick={() => setAdjustItems((prevState) => !prevState)}
								/>
							</div>
						)}
						{adjustItems ? (
							<div className={`${isMobile ? "table-responsive" : ""}`}>
								<table className="table table-form">
									<thead>
										<tr>
											<th style={{ minWidth: isMobile ? "200px" : "" }}>
												{t("common.word.variant")}
											</th>
											<th style={{ minWidth: isMobile ? "200px" : "" }}>
												{t("modules.menu.field.default_direction.title")}
											</th>
										</tr>
									</thead>
									<tbody>
										{items.map((item, itemIndex) => (
											<tr key={item.id + new Date().getTime()}>
												<td>{item.name}</td>
												<td>
													<FormSelectGroup
														name={`items.${itemIndex}.direction_id`}
														errors={errors}
														onChange={(_, obj) => handleChangeDirection(itemIndex, obj)}
														getOptionLabel={(option) => option.label}
														defaultValue={{
															label: item.direction?.name,
															id: item.direction?.id,
														}}
														getOptionValue={(option) => option.id}
														loadOptions={searchDirections}
														control={control}
														data-testid={`items.${itemIndex}.direction_id`}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<FormSelectGroup
								name={`items.0.direction_id`}
								errors={errors}
								onChange={(_, obj) => handleChangeDirection(0, obj)}
								getOptionLabel={(option) => option.label}
								defaultValue={{
									label: items[0].direction?.name,
									id: items[0].direction?.id,
								}}
								getOptionValue={(option) => option.id}
								loadOptions={searchDirections}
								control={control}
								data-testid={`items.0.direction_id`}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ItemsDirectionsForm;
