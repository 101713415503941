import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { DeliveryEmployeeApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import { OrdersContext } from "../../../../../../services/context";
import {
	hasEmployeeOrders,
	isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes,
} from "../../../../../../services/orderDelivery";
import LiveOrdersAssignDeliveryEmployeeModalOption from "./LiveOrdersAssignDeliveryEmployeeModalOption";

interface Props {
	setSelectedEmployeeId: (id: string | undefined) => void;
	selectedEmployeeId: string | undefined;
}

const LiveOrdersAssignDeliveryEmployeeModalOptions = ({
	setSelectedEmployeeId,
	selectedEmployeeId,
}: Props): JSX.Element => {
	const [employees, setEmployees] = useState<DeliveryEmployeeApi[] | undefined>();
	const { addFlash } = useFlash();
	const { orders } = useContext(OrdersContext);
	const size = useWindowSize();
	const organization = useSelector(selectOrganization);

	useEffect(() => {
		fetchEmployees();
	}, []);

	const fetchEmployees = async () => {
		try {
			const params = {
				size: 0,
			};
			const res = await api.organization().getDeliveryEmployees(params);
			setEmployees(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const getSortedEmployees = () => {
		return [...(employees || [])].sort((a, b) => {
			const isAUpdatedInFiveMinutes = !isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes(
				a,
				organization.timezone
			);
			const isBUpdatedInFiveMinutes = !isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes(
				b,
				organization.timezone
			);
			const hasAOrders = hasEmployeeOrders(orders, a);
			const hasBOrders = hasEmployeeOrders(orders, b);

			if (isAUpdatedInFiveMinutes && !isBUpdatedInFiveMinutes) return -1;
			if (isBUpdatedInFiveMinutes && !isAUpdatedInFiveMinutes) return 1;
			if (a.employee?.checked_in && !b.employee?.checked_in) return -1;
			if (b.employee?.checked_in && !a.employee?.checked_in) return 1;
			if (hasAOrders && !hasBOrders) return -1;
			if (hasBOrders && !hasAOrders) return 1;
			return 0;
		});
	};

	return (
		<div className="row assign-delivery-employee-modal-options" style={{ rowGap: size.isMobile ? "8px" : "16px" }}>
			{employees ? (
				getSortedEmployees().map((employee) => (
					<div className="col-lg-6" key={`employee-${employee.id}`}>
						<LiveOrdersAssignDeliveryEmployeeModalOption
							employee={employee}
							selectedEmployeeId={selectedEmployeeId}
							setSelectedEmployeeId={setSelectedEmployeeId}
						/>
					</div>
				))
			) : (
				<LoadingContainer />
			)}
		</div>
	);
};

export default LiveOrdersAssignDeliveryEmployeeModalOptions;
