import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import FormNumberInput from "go-form/components/FormNumberInput";
import { api } from "../../services/Api/api";

const ForcingPhoneNumberModal = () => {
	const { t } = useTranslation();
	const THIRTY_MINUTES = 60 * 30 * 1000;
	const phoneNumberTimeoutRef = useRef<number>();
	const [show, setShow] = useState(false);
	const form = useForm<{ phone_number: string }>({
		criteriaMode: "all",
	});
	const {
		formState,
		formState: { errors },
		setError,
		control,
		handleSubmit,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const lastPhoneNumberCheckingDateTimeoutRef = useRef<number>();

	useEffect(() => {
		if (checkIfShouldRunPhoneNumberTimeout()) {
			getPhoneNumber();
		} else {
			handleLastPhoneNumberCheckingDateTimeout();
		}
	}, []);

	const checkIfShouldRunPhoneNumberTimeout = () => {
		const lastPhoneNumberCheckingDate = localStorage.getItem("phone_number_checking_time");
		const now = Date.now();
		if (lastPhoneNumberCheckingDate === null) return true;
		return now - Number(lastPhoneNumberCheckingDate) >= THIRTY_MINUTES;
	};

	const handlePhoneNumberTimeout = () => {
		if (phoneNumberTimeoutRef.current) window.clearTimeout(phoneNumberTimeoutRef.current);
		phoneNumberTimeoutRef.current = window.setTimeout(getPhoneNumber, THIRTY_MINUTES);
	};

	const handleStartTimeout = () => {
		if (checkIfShouldRunPhoneNumberTimeout()) {
			getPhoneNumber();
			window.clearTimeout(lastPhoneNumberCheckingDateTimeoutRef.current);
		} else {
			handleLastPhoneNumberCheckingDateTimeout();
		}
	};

	const handleLastPhoneNumberCheckingDateTimeout = () => {
		if (lastPhoneNumberCheckingDateTimeoutRef.current)
			window.clearTimeout(lastPhoneNumberCheckingDateTimeoutRef.current);
		lastPhoneNumberCheckingDateTimeoutRef.current = window.setTimeout(handleStartTimeout, 1000);
	};

	const getPhoneNumber = useCallback(async () => {
		try {
			const res = await api.getPhoneNumber();
			if (!res.phone_number) {
				setShow(true);
			}
			localStorage.setItem("phone_number_checking_time", Date.now().toString());
			window.clearTimeout(phoneNumberTimeoutRef.current);
		} catch (err) {
			localStorage.setItem("phone_number_checking_time", Date.now().toString());
			window.clearTimeout(phoneNumberTimeoutRef.current);
		}
	}, []);

	const handleUpdatePhoneNumber = handleSubmit(async (data: { phone_number: string }) => {
		setLoading(true);
		try {
			await api.updatePhoneNumber(data.phone_number);
			addSuccessFlash(t("lib:common.flash.saved"));
			setShow(false);
			window.clearTimeout(phoneNumberTimeoutRef.current);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const handleHide = () => {
		setShow(false);
		handlePhoneNumberTimeout();
	};

	return (
		<Modal show={show} onHide={handleHide}>
			<FormDirty formState={formState}>
				<Modal.Header closeButton>
					<Modal.Title>{t("components.forcing_phone_number_modal.header.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormNumberInput
						errors={errors}
						name="phone_number"
						suffix=""
						control={control}
						label={t("lib:common.word.phone_number")}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} onClick={handleUpdatePhoneNumber} type="submit">
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={handleHide}>
						{t("lib:common.action.close")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default ForcingPhoneNumberModal;
