import React, { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { Button, ButtonGroup, Dropdown, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { hasErrorsApi } from "go-core";
import { ButtonLoading } from "go-form";
import useFlash, { handleAlertErrorsWithoutField, handleErrorForAlert } from "go-alert/AlertMessage";
import { ApiError } from "go-core/api/types";
import EmptyList from "go-core/components/EmptyList";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { PdfOrientationType } from "go-core/types";
import { FormErrorMessage, FormErrorMessageApi } from "go-form/components/FormErrorMessage";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentContext, SegmentListService } from "../context";
import { SegmentApi } from "../services/types";
import SegmentManagementModal from "./SegmentManagementModal/SegmentManagementModal";
import { SegmentType } from "./types";

interface ListState {
	segments: SegmentType[];
	selected?: string;
	onChange: (segment: SegmentType) => void;
}

interface SaveState {
	segment?: SegmentType;
	onClick: () => void;
}

interface SaveModalState {
	segment: SegmentType;
	onSave: (segment: SegmentType) => Promise<any>;
	onCreate: (name: string) => Promise<any>;
	onHide: () => void;
	errors?: ApiError[];
}

export interface HyperLinksConfigState {
	list_name: string;
	url: string;
}

interface SegmentListState {
	organizationName: string;
	resource: any;
	resourceType: string;
	listName: string;
	resourceFilterTypes: Record<string, string>;
	hyperLinksConfig: HyperLinksConfigState[];
	segmentListService: SegmentListService;
	companyName?: string;
	taxIdNo?: string;
	pdfOrientation?: PdfOrientationType;
	pdfFontSize?: string;
}

const Segment: FC & {
	List: FC<ListState>;
	Save: FC<SaveState>;
	SaveModal: FC<SaveModalState>;
	SegmentList: FC<SegmentListState>;
} = (props): ReactElement => {
	return <>{props.children}</>;
};

const SegmentList: FC<SegmentListState> = (props): ReactElement => {
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const [items, setItems] = useState<SegmentApi[]>([]);

	if (!props.resource) return <></>;
	const data = props.resource.read();

	const onRemove = async (item: SegmentApi) => {
		try {
			await confirmation({
				title: t("lib:go_form.modal_confirm.title"),
				message: t("lib:go_form.modal_confirm.message.remove"),
			});
			await props.segmentListService.remove(item.id);
			addSuccessFlash(t("lib:common.flash.removed"));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res.data);
			}
		} catch (e) {
			handleErrorForAlert(e, addFlash);
		}
	};

	const onRestore = async (item: SegmentApi) => {
		try {
			await confirmation({
				title: t("lib:go_form.modal_confirm.title"),
				message: t("lib:go_form.modal_confirm.message.restore"),
			});
			await props.segmentListService.restore(item);
			addSuccessFlash(t("lib:common.flash.restored"));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res.data);
			}
		} catch (e) {
			handleErrorForAlert(e, addFlash);
		}
	};

	const getFullLink = (segment: SegmentApi) => {
		const configForSegment = props.hyperLinksConfig.filter((x) => x.list_name === segment.list_name)[0];
		const urlFromConfig = configForSegment.url;
		if (urlFromConfig) return `${urlFromConfig}?s=${segment.slug}`;
		return "";
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
				type: "text",
				render: (item: SegmentApi) => {
					return (
						<StickyColumnWithEntityStatus status={item.status}>
							<RenderLimitedText minWidth={200}>{item.name}</RenderLimitedText>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: SegmentApi) => item.name,
			},
			{
				id: "list_name",
				name: t("lib:go_segment.list.table.columns.list_name.name"),
				type: "list",
				options: props.resourceFilterTypes,
				render: (item: SegmentApi) => {
					const listName = props.resourceFilterTypes[item.list_name]
						? props.resourceFilterTypes[item.list_name]
						: item.list_name;
					const hypeLinkConfigForList = props.hyperLinksConfig.filter(
						(x) => x.list_name === item.list_name
					)[0];
					if (hypeLinkConfigForList && hypeLinkConfigForList.url) {
						return (
							<Link target="_blank" rel="noreferrer" to={getFullLink(item)}>
								{listName}
							</Link>
						);
					}
					return listName;
				},
				renderExport: (item: SegmentApi) =>
					props.resourceFilterTypes[item.list_name]
						? props.resourceFilterTypes[item.list_name]
						: item.list_name,
			},
		],
		filters: [
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list",
				options: {
					ENABLED: t("lib:enums.common.status.ENABLED"),
					DELETED: t("lib:enums.common.status.DELETED"),
				},
				hasDefaultOptions: false,
			},
		],
		actions: [
			{
				name: t("lib:common.action.remove"),
				click: (item: SegmentApi) => onRemove(item),
				visible: (item: SegmentApi) => item.status === "ENABLED",
			},
			{
				name: t("lib:common.action.restore"),
				click: (item: SegmentApi) => onRestore(item),
				visible: (item: SegmentApi) => item.status === "DELETED",
			},
		],
		selectedColumns: ["name", "list_name"],
		segments: [
			{
				id: "all",
				name: t("lib:go_list.filters.all"),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("lib:go_list.filters.deleted"),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e",
					},
				],
			},
		],
		exportConfig: {
			title: t("lib:go_segment.list.export_config.title"),
			filename: t("lib:go_segment.list.export_config.filename"),
			organization: `${props.organizationName}`,
			taxIdNo: props.taxIdNo,
			company: props.companyName,
			pdfOrientation: props.pdfOrientation,
			pdfFontSize: props.pdfFontSize,
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: async (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(params);
			const res = await props.segmentListService.find(params, { cancelToken: sourceToken?.token });
			return res.data;
		},
		doesIdColumnRedirectToPreviewPage: true,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={<EmptyList title={t("lib:go_core.empty_data.title")} />}
			onFetch={(fetchItems: Record<string, any>) => setItems(fetchItems.data)}
		/>
	);
};

const List: FC<ListState> = (props): ReactElement => {
	const selectedSegment =
		props.segments !== undefined ? props.segments.filter((segment) => segment.slug === props.selected)[0] : null;
	const defaultSegments = props.segments.filter((segment) => !segment.editable);
	const [editableSegments, setEditableSegments] = useState(props.segments.filter((segment) => segment.editable));
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;
	const [showSegmentManagementModal, setShowSegmentManagementModal] = useState(false);

	useEffect(() => {
		setEditableSegments(props.segments.filter((segment) => segment.editable));
	}, [JSON.stringify(props.segments)]);

	return (
		<>
			<Dropdown as={ButtonGroup} className={`btn-segments ${isMobile ? "w-100" : ""}`}>
				<Dropdown.Toggle variant="light">
					{selectedSegment ? selectedSegment.name : `${t("lib:common.action.select")}`}
				</Dropdown.Toggle>
				<Dropdown.Menu className="btn-segments-menu">
					{defaultSegments.map((segment) => {
						return (
							<Dropdown.Item
								key={segment.id}
								eventKey={segment.id}
								onClick={() => props.onChange(segment)}
							>
								{segment.name}
							</Dropdown.Item>
						);
					})}
					{editableSegments && editableSegments.length > 0 && (
						<>
							<Dropdown.Divider />
							{editableSegments.map((segment) => {
								const segmentName = segment.default
									? `${segment.name} (${t("lib:go_segment.field.default.title")})`
									: segment.name;
								return (
									<Dropdown.Item
										key={segment.id}
										eventKey={segment.id}
										onClick={() => props.onChange(segment)}
									>
										{segmentName}
									</Dropdown.Item>
								);
							})}
						</>
					)}
					<Dropdown.Item
						onClick={() => setShowSegmentManagementModal(true)}
						className="text-center text-primary"
					>
						{t("lib:go_segment.action.manage_segments")}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			{showSegmentManagementModal && (
				<SegmentManagementModal
					show={showSegmentManagementModal}
					onHide={() => setShowSegmentManagementModal(false)}
					segments={editableSegments}
					setEditableSegments={setEditableSegments}
					changeSegment={props.onChange}
					selectedSegment={selectedSegment}
				/>
			)}
		</>
	);
};

const Save: FC<SaveState> = (props): ReactElement => {
	const { t } = useTranslation();
	return (
		<>
			<Button variant="success" onClick={props.onClick}>
				{t("lib:go_segment.action.save")}
			</Button>
		</>
	);
};

const SaveModal: FC<SaveModalState> = (props): ReactElement => {
	const [type, setType] = useState("create");
	const [errors, setErrors] = useState(props.errors ? props.errors : []);
	const [loading, setLoading] = useState(false);
	const [segmentName, setSegmentName] = useState<string>("");
	const segmentContextValue = React.useContext(SegmentContext);
	const { t } = useTranslation();
	const history = useHistory();
	const { addFlash } = useFlash();

	useEffect(() => {
		if (props.errors) {
			setErrors(props.errors);
		}
	}, [props.errors]);

	useEffect(() => {
		setErrors([]);
	}, [type]);

	const checkErrors = (errors: ApiError[]) => {
		if (errors !== undefined) {
			setErrors(errors);

			if (errors[0]?.code === "invalid_segment_value") {
				handleAlertErrorsWithoutField([errors[0]], addFlash);
			}
		}
	};

	const handleSave = (e: FormEvent) => {
		e.preventDefault();
		setLoading(true);
		if (type === "create") {
			props
				.onCreate(segmentName)
				.then((segment) => {
					checkErrors(segment);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			props
				.onSave(props.segment)
				.then((segment) => {
					checkErrors(segment);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};

	const getRedirectToSegment = () => {
		const path = segmentContextValue.getPath();
		if (props.segment.editable) {
			return `${path}?search=${props.segment.name.replaceAll(" ", "+")}`;
		}
		return path;
	};

	const isInvalid = hasErrorsApi(errors, "name");
	return (
		<>
			<Modal show={true} onHide={props.onHide}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>{t("lib:go_segment.form.title")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{props.segment && props.segment.editable && (
							<Form.Group className="form-group" controlId="radioTypeSave">
								<Form.Check type="radio">
									<Form.Check.Input
										name="type"
										type="radio"
										className="form-check"
										value="save"
										checked={type === "save"}
										onChange={() => setType("save")}
									/>
									<Form.Check.Label>
										{t("lib:go_segment.action.save")} "<strong>{props.segment.name}</strong>"
									</Form.Check.Label>
								</Form.Check>
							</Form.Group>
						)}
						<Form.Group className="form-group" controlId="radioTypeCreate">
							<Form.Check className="form-check" type="radio">
								<div className="d-flex">
									<Form.Check.Input
										name="type"
										type="radio"
										className={"form-check add-segment-radio"}
										value="create"
										checked={type === "create"}
										onChange={() => setType("create")}
									/>
									<Form.Check.Label className={"add-segment-label"}>
										{t("lib:go_segment.action.create")}
									</Form.Check.Label>
									<div className={"add-segment-input"}>
										<Form.Control
											isInvalid={isInvalid}
											value={segmentName}
											className="flex-fill w-auto"
											type="text"
											onChange={(e) => setSegmentName(e.target.value)}
											placeholder={t("lib:go_segment.form.placeholder")}
										/>
										<FormErrorMessageApi errors={errors} field={"name"} />
									</div>
								</div>
								<Form.Text muted>{t("lib:go_segment.form.description")}</Form.Text>
							</Form.Check>
							<FormErrorMessage errors={errors} name="name" />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn me-auto"
							type={"button"}
							onClick={() => history.push(getRedirectToSegment())}
						>
							{t("lib:common.action.manage")}
						</button>
						<Button variant="light" onClick={props.onHide}>
							{t("lib:common.action.cancel")}
						</Button>
						{props.segment && (
							<ButtonLoading type={"submit"} loading={loading} variant="primary" onClick={handleSave}>
								{type === "create" ? t("lib:common.action.add_new") : t("lib:common.action.save")}
							</ButtonLoading>
						)}
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};
Segment.List = List;
Segment.Save = Save;
Segment.SaveModal = SaveModal;
Segment.SegmentList = SegmentList;
export default Segment;
