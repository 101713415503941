import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteSvg } from "../../../../../images/delete.svg";

export interface ClearButtonProps {
	isButtonSticky: boolean;
	clearSelected: () => void;
	areAllSelected: boolean;
}

export const ClearButton = ({
	isButtonSticky,
	clearSelected,
	areAllSelected,
}: ClearButtonProps): JSX.Element | null => {
	const { t } = useTranslation();

	if (areAllSelected) {
		return null;
	}

	return (
		<span
			className={
				isButtonSticky
					? "sticky-selected-summary-clear-button-container"
					: "static-selected-summary-clear-button-container"
			}
			onClick={clearSelected}
		>
			<span>
				{t("lib:go_list.selected_items_summary.clear_button_text")} <DeleteSvg />
			</span>
		</span>
	);
};
