export function getRoomItemStyles(): Record<string, any> {
	const calc = "calc(50% - 2px)";
	const obj = {
		width: "4px",
		borderRadius: "50%",
		background: "#000000",
		height: "4px",
	};
	return {
		top: { ...obj, left: calc, top: "-2px" },
		right: { ...obj, top: calc, right: "-2px" },
		bottom: { ...obj, left: calc, bottom: "-2px" },
		left: { ...obj, top: calc, left: "-2px" },
		topLeft: { ...obj, left: "-2px", top: 0 },
		topRight: { ...obj, right: "-2px", top: 0 },
		bottomRight: { ...obj, right: "-2px", bottom: 0 },
		bottomLeft: { ...obj, left: "-2px", bottom: 0 },
	};
}

export function getPixelValue(val: string): number {
	return parseFloat(val.replace("px", ""));
}

export function getXScale(position: number): number {
	return position;
	// if(position < 0){
	//     return 0;
	// }
	// if(position+getPixelValue(styles.width) >= 960){
	//     return 960-getPixelValue(styles.width)
	// }
	// let rest = (position+getPixelValue(styles.width))%12;
	// return position - rest;
}

export function getYScale(position: number): number {
	return position;
	// if(position < 0){
	//     return 0;
	// }
	// if(position+getPixelValue(styles.height) >=492){
	//     return 492-getPixelValue(styles.height);
	// }
	// const rest = (position+getPixelValue(styles.height))%12;
	// return position - rest
}

export const checkIfElementIsOutsideTheRoomContainer = (
	position: number,
	key: "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown",
	roomIndex: number,
	elementHeight: number,
	elementWidth: number
): number | void | boolean => {
	const roomContainer = document.getElementById(`room-container-${roomIndex}`);
	const roomBody = roomContainer?.parentElement;
	if (!roomContainer || !roomBody) return true;

	if (key === "ArrowUp") {
		return position - 1 < 0;
	}
	if (key === "ArrowDown") {
		return position + 1 > roomBody.offsetHeight - elementHeight;
	}
	if (key === "ArrowLeft") {
		return position - 1 < 0;
	}
	if (key === "ArrowRight") {
		return position + 1 > roomBody.offsetWidth - elementWidth;
	}
};

export const getElementPositionMovedByKeyPress = (
	key: "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown",
	xPosition: number,
	yPosition: number,
	roomIndex: number,
	elementHeight: number,
	elementWidth: number
): Record<string, any> => {
	let x = xPosition;
	let y = yPosition;

	if (key === "ArrowDown") {
		if (!checkIfElementIsOutsideTheRoomContainer(y, "ArrowDown", roomIndex, elementHeight, elementWidth)) {
			y++;
		}
	}
	if (key === "ArrowRight") {
		if (!checkIfElementIsOutsideTheRoomContainer(x, "ArrowRight", roomIndex, elementHeight, elementWidth)) {
			x++;
		}
	}
	if (key === "ArrowUp") {
		if (!checkIfElementIsOutsideTheRoomContainer(y, "ArrowUp", roomIndex, elementHeight, elementWidth)) {
			y--;
		}
	}
	if (key === "ArrowLeft") {
		if (!checkIfElementIsOutsideTheRoomContainer(x, "ArrowLeft", roomIndex, elementHeight, elementWidth)) {
			x--;
		}
	}

	return { x, y };
};
