import { useCallback, useEffect, useRef } from "react";
import { GoListSegmentType } from "../../../../list/services/types";
import { ListConfigField } from "../../../services/types";
import { ListConfigColumn } from "../services/types";
import {
	getSortedColumnsAsObjects,
	getSortedColumnsWithAppendedCustomFields,
	getUncheckedColumns,
	insertUncheckedColumnsToSegmentColumns,
} from "../utils";

export const useSegmentsColumns = ({
	segment,
	allColumns,
	selectedColumns,
	numberOfActiveStickyColumns,
	setNewSortedColumnsWithDivider,
	customFields,
}: {
	segment?: GoListSegmentType;
	allColumns: React.MutableRefObject<ListConfigColumn[]>;
	selectedColumns: string[];
	numberOfActiveStickyColumns: number;
	setNewSortedColumnsWithDivider: (columnsList: ListConfigColumn[], dividerPosition: number) => void;
	customFields?: ListConfigField[] | undefined;
}): void => {
	const startingNumberOfStickyColumns = useRef(numberOfActiveStickyColumns);
	const selectedColumnsRef = useRef<string[]>(selectedColumns);

	const updateColumnsForSegmentWithStickyColumns = useCallback(
		(columnsInOrder: string[], dividerPosition: number) => {
			const allColumnsInOrder = [...allColumns.current];

			if (customFields) {
				const customFieldsToAppend = customFields.filter(
					(field) =>
						columnsInOrder.includes(field.id) && !allColumnsInOrder.find((column) => column.id === field.id)
				);
				allColumnsInOrder.push(...customFieldsToAppend);
			}

			const segmentColumnsInOrder = getSortedColumnsAsObjects(columnsInOrder, allColumnsInOrder);

			setNewSortedColumnsWithDivider(segmentColumnsInOrder, dividerPosition);
		},
		[allColumns, setNewSortedColumnsWithDivider, customFields]
	);

	const updateColumnsForSegment = useCallback(
		(selectedColumns: string[], isDefaultSegment?: boolean) => {
			const segmentSelectedColumns = [...selectedColumns];

			const uncheckedColumns = getUncheckedColumns(allColumns.current, segmentSelectedColumns);
			insertUncheckedColumnsToSegmentColumns(uncheckedColumns, segmentSelectedColumns);
			const newSortedColumns = getSortedColumnsAsObjects(segmentSelectedColumns, allColumns.current);

			if (isDefaultSegment && customFields) {
				const sortedColumnsWithCustomFields = getSortedColumnsWithAppendedCustomFields(
					customFields,
					newSortedColumns
				);

				return setNewSortedColumnsWithDivider(
					sortedColumnsWithCustomFields,
					startingNumberOfStickyColumns.current
				);
			}

			setNewSortedColumnsWithDivider(newSortedColumns, startingNumberOfStickyColumns.current);
		},
		[allColumns, setNewSortedColumnsWithDivider, customFields]
	);

	useEffect(() => {
		selectedColumnsRef.current = selectedColumns;
	}, [selectedColumns]);

	useEffect(() => {
		const cols = [
			...(segment?.allColumnsInOrder || []),
			...allColumns.current
				.filter((column) => {
					return !(segment?.allColumnsInOrder || []).find((id) => column.id === id);
				})
				.map((c) => c.id),
		];

		if (segment?.columns) {
			if (segment.allColumnsInOrder && segment.stickyColumnsDividerPosition !== undefined) {
				return updateColumnsForSegmentWithStickyColumns(cols, segment.stickyColumnsDividerPosition);
			}

			return updateColumnsForSegment(segment.columns);
		}

		updateColumnsForSegment(selectedColumnsRef.current, true);
	}, [segment, updateColumnsForSegmentWithStickyColumns, updateColumnsForSegment]);
};
