import { Dispatch, createContext, useReducer } from "react";
import { useSelector } from "react-redux";
import { FILTER_VALUE_SEPARATOR } from "go-list/core/components/Filter/services";
import { selectUser } from "go-security/services/users/selectors";
import { UserState } from "go-security/services/users/types";
import Dashboard from "./components/Dashboard";
import { dashboardInitialState, dashboardReducer } from "./services/dashboardContext";
import { DashboardInitialStateType } from "./services/data/types";
import { DashboardType } from "./services/types";

export interface DashboardContextApi {
	state: DashboardInitialStateType;
	dispatch: Dispatch<any>;
	dashboardType: DashboardType;
}

export const DashboardContext = createContext<DashboardContextApi>({
	state: dashboardInitialState,
	dispatch: () => null,
	dashboardType: DashboardType.ORGANIZATION,
});

interface StateInitalValueApi {
	user: UserState;
	dashboardType: DashboardType;
}

const getInitialConfig = ({ dashboardType, user }: StateInitalValueApi) => {
	const dashboardConfigFromLocalStorage = localStorage.getItem("dashboard_cfg");

	const initialState = {
		...dashboardInitialState,
		filtersConfig: {
			...dashboardInitialState.filtersConfig,
			selectedFilter: {
				...dashboardInitialState.filtersConfig.selectedFilter,
				dateRange:
					window.localStorage.getItem("go_report.dashboard_filters.date_range")?.split("=")[1] ?? "today",
			},
			forceApplyDateFilter: true,
		},
	} as DashboardInitialStateType;

	if (dashboardType === DashboardType.GROUP_ORGANIZATIONS)
		initialState.filtersConfig.selectedFilter.organizationIds = user.organizations
			?.map((organization) => organization.id)
			.join(FILTER_VALUE_SEPARATOR);

	if (dashboardConfigFromLocalStorage) {
		const parsedDashboardConfig = JSON.parse(dashboardConfigFromLocalStorage);
		return {
			...initialState,
			chartsConfig: {
				reportCategory: {
					...dashboardInitialState.chartsConfig.reportCategory,
					range: parsedDashboardConfig.category_reports?.range || "DAY_OF_WEEK",
				},
				reportPayment: {
					...dashboardInitialState.chartsConfig.reportPayment,
					range: parsedDashboardConfig.payment_reports?.range || "DAY_OF_WEEK",
				},
				reportProduct: {
					...dashboardInitialState.chartsConfig.reportProduct,
					range: parsedDashboardConfig.product_reports?.range || "DAY_OF_WEEK",
				},
				reportSales: {
					...dashboardInitialState.chartsConfig.reportSales,
					range: parsedDashboardConfig.sales_reports?.range || "DAY_OF_WEEK",
				},
				reportSalesAdvanced: {
					...dashboardInitialState.chartsConfig.reportSalesAdvanced,
					range: parsedDashboardConfig.sales_advanced_reports?.range || "DAY_OF_WEEK",
				},
			},
		} as DashboardInitialStateType;
	}

	return initialState;
};

export const DashboardNewPage = ({ dashboardType }: { dashboardType: DashboardType }): JSX.Element => {
	const user = useSelector(selectUser);
	const [state, dispatch] = useReducer(dashboardReducer, { dashboardType, user }, getInitialConfig);

	return (
		<DashboardContext.Provider value={{ state, dispatch, dashboardType }}>
			<Dashboard />
		</DashboardContext.Provider>
	);
};
