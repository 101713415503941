import React, { RefObject } from "react";
import { Dropdown, Form } from "react-bootstrap";

interface OptionProps {
	id: string;
	dropDownItemId: string;
	label: string;
	isSelected: boolean;
	onClick: (id: string) => void;
	optionRef?: ((instance: HTMLAnchorElement | null) => void) | RefObject<HTMLAnchorElement> | null | undefined;
}

export const DropdownOption = ({
	id,
	dropDownItemId,
	label,
	isSelected,
	onClick,
	optionRef,
}: OptionProps): JSX.Element => {
	const onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
		e.stopPropagation();
		onClick(id);
	};

	return (
		<Dropdown.Item id={dropDownItemId} onClick={() => onClick(id)} ref={optionRef}>
			<Form.Check
				type="checkbox"
				label={label}
				name={`select_${id}_${label}`}
				id={`select_${id}_${label}`}
				value={id}
				checked={isSelected}
				onClick={onInputClick}
			/>
		</Dropdown.Item>
	);
};
