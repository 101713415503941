import React from "react";
import { CustomFieldTemplateApi, CustomFieldTemplateFormApi } from "go-segment/services/types";
import { CustomFieldTypeApi } from "./types";

export interface CustomFieldContextProps {
	all: (params?: Record<string, any>, options?: any) => Promise<CustomFieldTemplateApi[]>;
	get: (id: number, params?: Record<string, any>) => Promise<CustomFieldTemplateApi>;
	create: (data: CustomFieldTemplateFormApi, params?: Record<string, any>) => Promise<CustomFieldTemplateApi>;
	update: (data: CustomFieldTemplateFormApi, params?: Record<string, any>) => Promise<CustomFieldTemplateApi>;
	remove: (id: number, params?: Record<string, any>) => Promise<CustomFieldTemplateApi>;
	restore: (id: number, params?: Record<string, any>) => Promise<CustomFieldTemplateApi>;
}

export class CustomFieldService {
	_api: CustomFieldContextProps;
	_types: CustomFieldTypeApi[];

	constructor(api: CustomFieldContextProps, types: CustomFieldTypeApi[]) {
		this._api = api;
		this._types = types;
	}

	api(): CustomFieldContextProps {
		return this._api;
	}

	types(): CustomFieldTypeApi[] {
		return this._types;
	}

	getTypeTitle(id: string): string | undefined {
		const type = this._types.filter((x) => x.id === id)[0];
		if (!type) return undefined;
		return type.title;
	}
}

export const CustomFieldContext = React.createContext<CustomFieldService>({} as CustomFieldService);
