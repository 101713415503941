import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SecurityPermissionComponent from "go-security/components/Organization/Permission/Permission";
import { PermissionTabConfig } from "go-security/components/Organization/Permission/service/types";
import { selectOrganization } from "go-security/services/organizations/selectors";

const OrganizationSettingsPermissionsIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const configs: PermissionTabConfig[] = [
		{
			id: "venue",
			permissionLabelTranslationKey: "enums.permissions.venue.label",
			permissionValueTranslationKey: "enums.permissions.venue.value",
			title: t("modules.permission.field.venue.title"),
			description: (
				<p>
					{t("modules.permission.field.venue.helptext.description")}{" "}
					<Link to={`users`}>{t("modules.permission.field.users.title")}</Link>
				</p>
			),
			getPermissions: (permissions: string[]) =>
				permissions.filter((permission) => !permission.startsWith("TERMINAL")),
		},
		{
			id: "terminal",
			permissionLabelTranslationKey: "enums.permissions.terminal.label",
			permissionValueTranslationKey: "enums.permissions.terminal.value",
			title: t("modules.permission.field.terminal.title"),
			description: (
				<p>
					{t("modules.permission.field.terminal.helptext.description")}{" "}
					<Link to={`/${organization.id}/employees`}>{t("modules.permission.field.employees.title")}</Link>
				</p>
			),
		},
	];

	return <SecurityPermissionComponent permissionTabConfigs={configs} />;
};
export default OrganizationSettingsPermissionsIndexPage;

// t("enums.permissions.venue.value.VENUE_EMPLOYEE_MANAGE");
// t("enums.permissions.venue.value.VENUE_MENU_MANAGE");
// t("enums.permissions.venue.value.VENUE_ORDER_SHOW");
// t("enums.permissions.venue.value.VENUE_ORDER_EDIT");
// t("enums.permissions.venue.value.VENUE_LIVE_ORDERS");
// t("enums.permissions.venue.value.VENUE_INVOICE_MANAGE");
// t("enums.permissions.venue.value.VENUE_INVOICE_EDIT");
// t("enums.permissions.venue.value.VENUE_ROOM_MANAGE");
// t("enums.permissions.venue.value.VENUE_CLIENT_MANAGE");
// t("enums.permissions.venue.value.VENUE_DISCOUNT_MANAGE");
// t("enums.permissions.venue.value.VENUE_REPORTS_SHOW");
// t("enums.permissions.venue.value.MANAGE");
// t("enums.permissions.venue.value.SETTINGS");
// t("enums.permissions.venue.value.VENUE_POS_ACCESS");
// t("enums.permissions.venue.value.VENUE_TABLE_RESERVATION");
// t("enums.permissions.venue.value.VENUE_REPORTPOS_SHOW");
// t("enums.permissions.venue.value.VENUE_REPORTPOS_PAID_MANAGE");
// t("enums.permissions.venue.value.VENUE_API");
// t("enums.permissions.venue.value.NOTIFICATIONS");
// t("enums.permissions.venue.value.MENU_READ");
// t("enums.permissions.venue.value.MENU_WRITE");
// t("enums.permissions.venue.value.PRICE_LIST_READ");
// t("enums.permissions.venue.value.PRICE_LIST_WRITE");
// t("enums.permissions.venue.value.DISCOUNT_READ");
// t("enums.permissions.venue.value.DISCOUNT_WRITE");
// t("enums.permissions.venue.value.VENUE_EMPLOYEE_HOURLY_RATE_ACCESS");
// t("enums.permissions.venue.value.VENUE_OWN_INVOICE_MANAGE");
// t("enums.permissions.venue.label.VENUE_EMPLOYEE_MANAGE");
// t("enums.permissions.venue.label.VENUE_MENU_MANAGE");
// t("enums.permissions.venue.label.VENUE_ORDER_SHOW");
// t("enums.permissions.venue.label.VENUE_ORDER_EDIT");
// t("enums.permissions.venue.label.VENUE_LIVE_ORDERS");
// t("enums.permissions.venue.label.VENUE_INVOICE_MANAGE");
// t("enums.permissions.venue.label.VENUE_INVOICE_EDIT");
// t("enums.permissions.venue.label.VENUE_ROOM_MANAGE");
// t("enums.permissions.venue.label.VENUE_CLIENT_MANAGE");
// t("enums.permissions.venue.label.VENUE_DISCOUNT_MANAGE");
// t("enums.permissions.venue.label.VENUE_REPORTS_SHOW");
// t("enums.permissions.venue.label.MANAGE");
// t("enums.permissions.venue.label.SETTINGS");
// t("enums.permissions.venue.label.VENUE_POS_ACCESS");
// t("enums.permissions.venue.label.VENUE_TABLE_RESERVATION");
// t("enums.permissions.venue.label.VENUE_REPORTPOS_SHOW");
// t("enums.permissions.venue.label.VENUE_REPORTPOS_PAID_MANAGE");
// t("enums.permissions.venue.label.VENUE_API");
// t("enums.permissions.venue.label.NOTIFICATIONS");
// t("enums.permissions.venue.label.VENUE_EMPLOYEE_HOURLY_RATE_ACCESS");
// t("enums.permissions.venue.label.VENUE_OWN_INVOICE_MANAGE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_PRINT_TEST");
// t("enums.permissions.terminal.value.TERMINAL_BILL_PRINT_REPRINT_FISCAL");
// t("enums.permissions.terminal.value.TERMINAL_BILL_PRINT_INVOICE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_REPRINT_COPY");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_TAKE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_GIVE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_TAKE_EXTERNAL");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_SPLIT");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_MERGE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_CLOSE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_CLOSE_AS_INTERNAL");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_PRODUCT_PRICE_CHANGE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_MAX_SUM");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_ADD_CLIENT");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_ADD_VOUCHER");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_SCAN_CARD");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_STORNO_OPEN");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_STORNO_CLOSE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_PAYMENT_CHANGE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_ADD_DISCOUNT");
// t("enums.permissions.terminal.value.TERMINAL_MANAGE_REPORT_PAID");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_ZERO_PRICE_SALE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_VOID");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_EDIT_WITHOUT_TAKE");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_ADD_WEIGHT_MANUALLY");
// t("enums.permissions.terminal.value.TERMINAL_REPORT_OPEN");
// t("enums.permissions.terminal.value.TERMINAL_REPORT_CLOSE");
// t("enums.permissions.terminal.value.TERMINAL_REPORT_CLOSE_WITH_OPEN_BILLS");
// t("enums.permissions.terminal.value.TERMINAL_REPORT_READ");
// t("enums.permissions.terminal.value.TERMINAL_REPORT_CHANGE_TYPE");
// t("enums.permissions.terminal.value.TERMINAL_CLIENT_ADD_EDIT");
// t("enums.permissions.terminal.value.TERMINAL_CLIENT_DELETE");
// t("enums.permissions.terminal.value.TERMINAL_SETTING_FISCAL_PRINTER");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_RESEND_ON_KITCHEN");
// t("enums.permissions.terminal.value.TERMINAL_MANAGE_REPORT_PAID");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_CLOSE_PRICE_WASTED");
// t("enums.permissions.terminal.value.TERMINAL_WORK_TIME_READ");
// t("enums.permissions.terminal.value.TERMINAL_LIMITED_WORK_TIME_ACCESS");
// t("enums.permissions.terminal.value.TERMINAL_WAITER_SUMMARY_READ");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_ADD_DISCOUNT_UNLIMITED");
// t("enums.permissions.terminal.value.TERMINAL_BILL_MANAGE_PAY_UNLIMITED");
// t("enums.permissions.terminal.value.TERMINAL_ACCESS_SETTINGS");
// t("enums.permissions.terminal.value.TERMINAL_ACCESS_ACTIVITIES");
// t("enums.permissions.terminal.value.TERMINAL_BILL_PARTIAL_SALE");
// t("enums.permissions.terminal.value.TERMINAL_LOGOUT");
// t("enums.permissions.terminal.value.TERMINAL_OPEN_REPORT_OFFLINE");
// t("enums.permissions.terminal.value.TERMINAL_SALE_WITHOUT_CHECKIN");
// t("enums.permissions.terminal.value.TERMINAL_REPORT_OPEN_OFFLINE");
// t("enums.permissions.terminal.value.TERMINAL_SALE_ACCESS");
// t("enums.permissions.terminal.label.TERMINAL_REPORT_OPEN_OFFLINE");
// t("enums.permissions.terminal.label.TERMINAL_LOGOUT");
// t("enums.permissions.terminal.label.TERMINAL_BILL_PRINT_TEST");
// t("enums.permissions.terminal.label.TERMINAL_BILL_PRINT_REPRINT_FISCAL");
// t("enums.permissions.terminal.label.TERMINAL_BILL_PRINT_INVOICE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_REPRINT_COPY");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_TAKE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_GIVE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_TAKE_EXTERNAL");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_SPLIT");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_MERGE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_CLOSE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_CLOSE_AS_INTERNAL");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_PRODUCT_PRICE_CHANGE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_MAX_SUM");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_ADD_CLIENT");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_ADD_VOUCHER");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_SCAN_CARD");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_STORNO_OPEN");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_STORNO_CLOSE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_PAYMENT_CHANGE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_ADD_DISCOUNT");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_ZERO_PRICE_SALE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_VOID");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_EDIT_WITHOUT_TAKE");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_ADD_WEIGHT_MANUALLY");
// t("enums.permissions.terminal.label.TERMINAL_REPORT_OPEN");
// t("enums.permissions.terminal.label.TERMINAL_REPORT_CLOSE");
// t("enums.permissions.terminal.label.TERMINAL_REPORT_CLOSE_WITH_OPEN_BILLS");
// t("enums.permissions.terminal.label.TERMINAL_REPORT_READ");
// t("enums.permissions.terminal.label.TERMINAL_REPORT_CHANGE_TYPE");
// t("enums.permissions.terminal.label.TERMINAL_CLIENT_ADD_EDIT");
// t("enums.permissions.terminal.label.TERMINAL_CLIENT_DELETE");
// t("enums.permissions.terminal.label.TERMINAL_SETTING_FISCAL_PRINTER");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_RESEND_ON_KITCHEN");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_CLOSE_PRICE_WASTED");
// t("enums.permissions.terminal.label.TERMINAL_WORK_TIME_READ");
// t("enums.permissions.terminal.label.TERMINAL_LIMITED_WORK_TIME_ACCESS");
// t("enums.permissions.terminal.label.TERMINAL_WAITER_SUMMARY_READ");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_ADD_DISCOUNT_UNLIMITED");
// t("enums.permissions.terminal.label.TERMINAL_BILL_MANAGE_PAY_UNLIMITED");
// t("enums.permissions.terminal.label.TERMINAL_ACCESS_SETTINGS");
// t("enums.permissions.terminal.label.TERMINAL_ACCESS_ACTIVITIES");
// t("enums.permissions.terminal.label.TERMINAL_BILL_PARTIAL_SALE");
// t("enums.permissions.terminal.label.TERMINAL_SALE_WITHOUT_CHECKIN");
// t("enums.permissions.terminal.label.TERMINAL_SALE_ACCESS");
// t("enums.permissions.terminal.label.TERMINAL_OPEN_REPORT_OFFLINE");
