import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { OrganizationState } from "../../services/organizations/types";
import { selectUser } from "../../services/users/selectors";

interface Props {
	organization: OrganizationState;
	topStyle?: string;
	roles?: string[];
}

const OrganizationNotAcceptedTermsNotification = ({ topStyle, organization, roles }: Props) => {
	const location = useLocation();
	const user = useSelector(selectUser);
	const { t } = useTranslation();
	const notAcceptedTerms = !organization.accept_terms || !organization.accept_privacy || !organization.accept_rules;
	const isAcceptTermsRoute = location.pathname === `/${organization.id}/accept_terms`;
	const isUserInOrganization = !!user?.organizations?.find((org) => org.id === organization.id);

	const checkIfNotificationIsClickable = () => {
		if (roles && roles.length > 0) {
			if (roles.some((role) => user.roles?.includes(role))) {
				return true;
			}
		}

		return isUserInOrganization;
	};

	if (!organization.id) return <></>;

	const drawContent = () => {
		if (checkIfNotificationIsClickable())
			return (
				<Link
					to={`/${organization.id}/accept_terms`}
					className="license-danger"
					style={{ top: topStyle ? topStyle : 0 }}
				>
					{t("lib:go_security.organization_terms_notification.accept_organization_terms", {
						name: organization.name,
					})}
				</Link>
			);
		return (
			<div className="license-danger" style={{ top: topStyle ? topStyle : 0, cursor: "default" }}>
				{t("lib:go_security.organization_terms_notification.accept_organization_terms", {
					name: organization.name,
				})}
			</div>
		);
	};

	return notAcceptedTerms && !isAcceptTermsRoute ? drawContent() : <></>;
};

export default OrganizationNotAcceptedTermsNotification;
