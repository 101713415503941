import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import ParametersSecurity from "../../services";
import { apiSecurity } from "../../services/Api/api";
import { AcceptUserTermsSecurityApi } from "../../services/Api/typesSecurity";
import { useAuth } from "../../services/auth";

interface Props {
	baseUrl: string;
}

const SecurityAcceptTerms = ({ baseUrl }: Props): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const { addFlash } = useFlash();
	const auth = useAuth(baseUrl);
	const history = useHistory();
	const { t } = useTranslation();
	const form = useForm<AcceptUserTermsSecurityApi>({
		criteriaMode: "all",
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = form;

	const onSubmit = handleSubmit(async (data: AcceptUserTermsSecurityApi) => {
		setLoading(true);
		try {
			await apiSecurity.user().acceptTerms(data);
			await auth.checkLogin();
			history.push("/");
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});

	const renderButtons = () => {
		return (
			<>
				<ButtonLoading loading={loading} variant="primary" type="submit">
					{t("lib:common.action.accept")}
				</ButtonLoading>
				<Button variant="default" type="submit" onClick={() => auth.logout(true)}>
					{t("lib:common.action.logout")}
				</Button>
			</>
		);
	};
	return (
		<div className="container-fluid mt-2">
			<div className="col-6">
				<h5>{t("lib:go_security.actions.accept_consent")}</h5>
				<FormDirty
					buttonSubmitCustom={renderButtons()}
					isDirty={false}
					errors={errors}
					loading={loading}
					noValidate
					onSubmit={onSubmit}
				>
					<FormCheck
						name={"accept_terms"}
						register={register}
						errors={errors}
						label={
							<>
								{t("lib:go_security.register.words.accept")}
								<a href="https://www.gopos.pl/tos">{t("lib:go_security.register.words.tos")}</a>,{" "}
								<a href="https://www.gopos.pl/privacy-policy">
									{t("lib:go_security.register.words.privacy_policy")}
								</a>{" "}
								{t("lib:go_security.register.words.and_accept_receive_informations_from_gopos")}
							</>
						}
					/>

					<FormCheck
						name={"accept_marketing_content"}
						register={register}
						errors={errors}
						label={t("lib:go_security.register.form.accept_marketing_content.label")}
					/>
				</FormDirty>
				<a
					target="_blank"
					href={`${ParametersSecurity.getAccountsUrl()}/profile/remove_account`}
					rel="noreferrer"
				>
					{t("lib:go_security.actions.remove_account")}
				</a>
			</div>
		</div>
	);
};

export default SecurityAcceptTerms;
