import React, { FC, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormCheck, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as GridSVG } from "../../../../../../../../../images/svg/menu/grid.svg";
import { ReactComponent as ListSVG } from "../../../../../../../../../images/svg/menu/list.svg";
import { apiOrganization } from "../../../../../../../../../services/Api/Organization/apiOrganization";
import { MenuApi, MenuPageApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	handleClose: () => void;
	handleSave: (data: MenuApi, id?: number) => void;
	menu: MenuApi;
}

const MenuModalForm: FC<Props> = (props: Props): JSX.Element => {
	const [menu] = useState<MenuApi>(props.menu);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<MenuApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.menu,
			is_active: props.menu.status === "ENABLED",
			type: props.menu?.type ?? "GRID",
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		setValue,
		watch,
		control,
	} = form;

	const onSubmit = handleSubmit(async (data: MenuApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "pages,pages.items,pages.items.entity" };

		if (data.is_active !== undefined) {
			data.active = data.is_active;
		}

		try {
			if (menu.id || menu.id === 0) {
				data.pages = menu.pages;
				if (data.is_active !== undefined) {
					data.active = data.is_active;
				}
				data.pages?.forEach((page) => {
					page.items = page.items?.filter((f) => Object.keys(f).length !== 0);
				});

				let res;
				if (menu.id) {
					data.id = menu.id;
					res = await api.organization().updateMenu(data, params);
					props.handleSave(res, res.id);
				} else {
					res = await api.organization().createMenu(data, params);
					props.handleSave(res);
				}
			} else {
				data.pages =
					watch("type") === "GRID"
						? [
								{
									name: "1",
									position: 0,
								} as MenuPageApi,
						  ]
						: [];
				const res = await api.organization().createMenu(data, params);
				props.handleSave(res);
			}
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const handleCreateTag = (value: string) => {
		setValue("image_tag", value);
	};

	const searchTags = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"resource_type|e": "MENU_IMAGE",
		};
		return apiOrganization.getTagsSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	return (
		<Modal show={true} onHide={props.handleClose} className="menu-modal-form">
			<form key="menu_form">
				<Modal.Header closeButton>
					<Modal.Title>
						{!props.menu.id || props.menu.id === 0
							? t("modules.menu.header.new.title")
							: `${t("common.word.menu")} ${props.menu.name}`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row align-items-center">
						<div className="col-md-9">
							<FormInput
								register={register}
								placeholder={t("modules.menu.field.name_placeholder.title")}
								name={`name`}
								errors={errors}
							/>
						</div>
						<div className="col-md-3">
							<FormCheck
								type="switch"
								label={t("modules.menu.field.active.title")}
								register={register}
								name={"is_active"}
								errors={errors}
							/>
						</div>
					</div>
					{menu.id === undefined && (
						<div className="row align-items-center mt-2">
							<div className="col-md-6">
								<Button
									className="w-100"
									variant={watch("type") === "GRID" ? "primary" : "light"}
									onClick={() => setValue("type", "GRID")}
								>
									<GridSVG className="me-2" />
									{t("modules.menu.action.grid.title")}
								</Button>
							</div>
							<div className="col-md-6">
								<Button
									className="w-100"
									onClick={() => setValue("type", "LIST")}
									variant={watch("type") === "LIST" ? "primary" : "light"}
								>
									<ListSVG className="me-2" />
									{t("modules.menu.action.list.title")}
									<span className="badge bg-warning text-dark ms-2">BETA</span>
								</Button>
							</div>
						</div>
					)}
					<FormSelectGroup
						loadOptions={searchTags}
						getOptionValue={(opt) => opt.label}
						onCreateOption={handleCreateTag}
						errors={errors}
						label={t("modules.menu.field.tag.title")}
						name="image_tag"
						defaultValue={{ id: watch("image_tag"), label: watch("image_tag") }}
						control={control}
						data-testid="image_tag"
					/>
					{menu.type === "LIST" && menu.image_tag !== watch("image_tag") && (
						<Alert variant="warning">{t("modules.menu.field.tag.alert.title")}</Alert>
					)}
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.handleClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default MenuModalForm;
