import { listDataParams } from "./decoder";
import { ReportParamsType } from "./types";

export const listDataFetch = (
	params: ReportParamsType,
	fetchAction?: (fetchParams: Record<string, any>) => Promise<Array<any>>
): Promise<Array<any>> => {
	if (fetchAction) {
		return fetchAction(listDataParams(params));
	}
	return new Promise<Array<any>>(() => {});
};
