import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as HomeSVG } from "../../../../../../../../../../images/svg/bills/home.svg";
import { ReactComponent as MessageSVG } from "../../../../../../../../../../images/svg/bills/message.svg";
import { ReactComponent as PhoneSVG } from "../../../../../../../../../../images/svg/bills/phone.svg";
import { ReactComponent as TaxSVG } from "../../../../../../../../../../images/svg/bills/tax.svg";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";
import { AddressApi } from "../../../../../../../../../../services/Api/types";

interface Props {
	client: ClientApi;
}

const ClientContactCard: FC<Props> = ({ client }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const address = {
		build_nr: client.address_build_nr,
		flat_nr: client.address_flat_nr,
		street: client.address_street,
		zip_code: client.address_zip_code,
		country: client.address_country,
		city: client.address_city,
	} as AddressApi;

	const addressEmpty = Object.values(address).every((val) => val === undefined || val === "");

	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.client.field.client_contact_card.title")}</h5>
				<div className="card-body-item">
					<TaxSVG />
					<div className="item-info">
						{!client.tax_id_no ? (
							<Link to={`/${organization.id}/clients/${client.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						) : (
							<span>{client.tax_id_no}</span>
						)}
					</div>
				</div>
				<div className="card-body-item">
					<HomeSVG />
					<div className="item-info">
						{addressEmpty ? (
							<Link to={`/${organization.id}/clients/${client.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						) : (
							<FormatAddress address={address} />
						)}
					</div>
				</div>
				<div className="card-body-item">
					<PhoneSVG />
					<div className="item-info">
						{!client.contact_phone_number ? (
							<Link to={`/${organization.id}/clients/${client.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						) : (
							<span>{client.contact_phone_number}</span>
						)}
					</div>
				</div>
				<div className="card-body-item">
					<MessageSVG />
					<div className="item-info">
						{!client.contact_email ? (
							<Link to={`/${organization.id}/clients/${client.id}/edit`} className="action-ref">
								{t("common.action.set", { ns: "lib" })}
							</Link>
						) : (
							<span>{client.contact_email}</span>
						)}
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default ClientContactCard;
