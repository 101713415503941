import { FC } from "react";
import ListActions from "./components/Actions";
import ListColumn from "./components/Column";
import ListFilter from "./components/Filter";
import ListPagination from "./components/Pagination";
import ListSearch from "./components/Search";
import ListTable from "./components/Table";

interface State {
	children?: any;
}

const List: FC & {
	Filters: typeof ListFilter;
	Search: typeof ListSearch;
	Columns: typeof ListColumn;
	Table: typeof ListTable;
	Pagination: typeof ListPagination;
	Actions: typeof ListActions;
} = (props: State) => {
	return <div className="list-container">{props.children}</div>;
};

List.Filters = ListFilter;
List.Search = ListSearch;
List.Columns = ListColumn;
List.Table = ListTable;
List.Pagination = ListPagination;
List.Actions = ListActions;
export default List;
