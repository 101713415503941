import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ModifierGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalButton from "../../../../components/translations/EntityTranslationsModalButton";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import ModifierGroupForm from "../../components/ModifierGroupForm";

interface MatchParams {
	modifier_group_id: string;
}

const OrganizationMenuModifierGroupsEditPage = (): JSX.Element => {
	const [modifierGroup, setModifierGroup] = useState<ModifierGroupApi | undefined>();
	const [showTranslations, setShowTranslations] = useState(false);
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const [modifierGroupTranslations, setModifierGroupTranslations] = useState(modifierGroup?.translations);
	const [showLogs, setShowLogs] = useState<boolean>(false);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const handleSaveTranslations = (data: Record<string, any>) => {
		unstable_batchedUpdates(() => {
			setModifierGroupTranslations(data.entity_translations);
			setShowTranslations(false);
		});
	};

	const { modifier_group_id } = useParams<MatchParams>();

	useEffect(() => {
		onFetchModifierGroup();
	}, []);

	const onFetchModifierGroup = async () => {
		try {
			const modifierGroupApiParams: Record<string, any> = {
				include: "options,options.item,options.quantity_info,options.price,translations",
			};
			const res = await api.organization().getModifierGroup(Number(modifier_group_id), modifierGroupApiParams);
			setModifierGroup(res);
			setModifierGroupTranslations(res.translations);
			handleChangeTabTitle(`${res.name} | ${t("modules.modifier_group.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!modifierGroup) {
		return <LoadingContainer />;
	}

	const onDisableModifierGroup = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			const res = await api.organization().disableModifierGroup(modifierGroup.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setModifierGroup(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRemoveModifierGroup = async () => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeModifierGroup(modifierGroup.id);
			history.push(`/${organization.id}/menu/modifier_groups`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestoreModifierGroup = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().restoreModifierGroup(modifierGroup.id);
			setModifierGroup(res);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onCopyModifierGroup = () => {
		history.push({
			pathname: `/${organization.id}/menu/modifier_groups/new`,
			state: {
				copiedItemId: modifierGroup.id,
			},
		});
	};

	const buttons: ButtonProps[] = [
		{
			content: (
				<EntityTranslationsModalButton
					translations={modifierGroupTranslations || []}
					showTranslation={showTranslations}
					setShowTranslations={(show) => setShowTranslations(show)}
				/>
			),
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			variant: "light",
		},
	];
	if (modifierGroup.status !== "DELETED")
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => onRemoveModifierGroup(),
			dropdown: true,
		});
	if (modifierGroup.status === "DELETED" || modifierGroup.status === "DISABLED")
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => onRestoreModifierGroup(),
			dropdown: true,
		});
	if (modifierGroup.status === "ENABLED")
		buttons.push({
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisableModifierGroup(),
			dropdown: true,
		});
	if (modifierGroup.status === "ENABLED" || modifierGroup.status === "DISABLED")
		buttons.push({
			title: t("common.action.copy", { ns: "lib" }),
			action: () => onCopyModifierGroup(),
			dropdown: true,
		});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => onRemoveModifierGroup(),
			hide: modifierGroup.status === "DELETED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => onRestoreModifierGroup(),
			hide: modifierGroup.status !== "DELETED" && modifierGroup.status !== "DISABLED",
		},
		{
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisableModifierGroup(),
		},
		{
			title: t("common.action.copy", { ns: "lib" }),
			action: () => onCopyModifierGroup(),
			hide: modifierGroup.status !== "ENABLED" && modifierGroup.status !== "DISABLED",
		},
	];

	const renderTitle = () => (
		<>
			{`${t("modules.modifier_group.header.title")} "${modifierGroup.name}"`}
			<FormatResourceStatus status={modifierGroup.status} />
		</>
	);

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? buttons.filter((button) => button.content) : buttons} />
			{showTranslations && (
				<EntityTranslationsModalForm
					handleSave={handleSaveTranslations}
					onHide={() => setShowTranslations(!showTranslations)}
					translations={modifierGroupTranslations}
				/>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={modifierGroup.id}
					resourceType={"MODIFIER_GROUP"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}
			<ModifierGroupForm modifierGroupTranslations={modifierGroupTranslations} modifierGroup={modifierGroup} />
		</>
	);
};
export default OrganizationMenuModifierGroupsEditPage;
