import React from "react";
import { Form } from "react-bootstrap";
import { ReactComponent as SortableHandleSvg } from "go-report/core/images/sortable-handle.svg";
import { ListConfigColumn } from "../services/types";

interface ColumnListItemProps {
	column?: ListConfigColumn;
	selectedColumns: string[];
	onChangeSelected: (columnId: string) => void;
}

export const ColumnListItem = ({
	column,
	selectedColumns,
	onChangeSelected,
}: ColumnListItemProps): JSX.Element | null => {
	if (!column) {
		return null;
	}

	return (
		<Form.Group key={column.id} controlId={column.id} className="form-group dropdown-item">
			<div className="dropdown-draggable-item">
				<SortableHandleSvg />
				<div className="w-100 h-100">
					<Form.Check
						type="checkbox"
						label={column.name}
						checked={selectedColumns.includes(column.id)}
						onClick={() => onChangeSelected(column.id)}
					/>
				</div>
			</div>
		</Form.Group>
	);
};
