import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { NotificationTemplateForm } from "../../components/NotificationTemplateForm";

export const OrganizationSettingsNotificationTemplatesNewPage = () => {
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.notification_template.header.new.title"));
	}, []);

	return (
		<>
			<Header title={t("modules.notification_template.header.new.title")} />
			<NotificationTemplateForm />
		</>
	);
};
