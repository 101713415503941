import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { hasPermission } from "go-security/services/organizations/selectors";
import MenusNavigation from "./components/MenusNavigation";
import OrganizationMenuCategoriesPage from "./pages/Categories";
import OrganizationMenuDiscountsPage from "./pages/Discounts";
import OrganizationMenuItemGroupsPage from "./pages/ItemGroups";
import OrganizationMenuMenusPage from "./pages/Menus";
import OrganizationMenuModifierGroupsPage from "./pages/ModifierGroups";
import OrganizationMenuPriceListsPage from "./pages/PriceLists/Index";

const OrganizationMenuPage: FC<RouteComponentProps> = (props) => {
	const menuReadWritePermissions = useSelector(hasPermission(["MENU_READ", "MENU_WRITE"]));
	const discountReadWritePermissions = useSelector(hasPermission(["DISCOUNT_READ", "DISCOUNT_WRITE"]));
	const priceListReadWritePermissions = useSelector(hasPermission(["PRICE_LIST_READ", "PRICE_LIST_WRITE"]));

	const getRedirectTo = () => {
		if (menuReadWritePermissions) return `${props.match.url}/menus`;
		if (discountReadWritePermissions) return `${props.match.url}/discounts`;
		if (priceListReadWritePermissions) return `${props.match.url}/price_lists`;
		return props.match.url;
	};

	return (
		<div className="page-container">
			<MenusNavigation />
			<Switch>
				<Route path={`${props.match.url}/menus`} component={OrganizationMenuMenusPage} />
				<Route path={`${props.match.url}/categories`} component={OrganizationMenuCategoriesPage} />
				<Route path={`${props.match.url}/item_groups`} component={OrganizationMenuItemGroupsPage} />
				<Route path={`${props.match.url}/modifier_groups`} component={OrganizationMenuModifierGroupsPage} />
				<Route path={`${props.match.url}/price_lists`} component={OrganizationMenuPriceListsPage} />
				<Route path={`${props.match.url}/discounts`} component={OrganizationMenuDiscountsPage} />
				<Redirect from={props.match.url} exact to={getRedirectTo()} />
			</Switch>
		</div>
	);
};
export default OrganizationMenuPage;
