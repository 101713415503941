import React, { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import {
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import ReportData from "go-report/reports";
import {
	getChartReport,
	getChartType,
	getLastMonthFilterBetween,
	getOrganizationIds,
	getOrganizationIdsFromReportFetchRequest,
	getReport,
	getReportCustomFieldsResources,
	parseOrganizationIds,
} from "go-report/reports/services/report-service";
import { getSelectedSegmentForReportConfig } from "go-report/reports/services/segment-service";
import { ReportConfig } from "go-report/reports/services/types";
import { selectUser } from "go-security/services/users/selectors";
import { UserState } from "go-security/services/users/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { apiReport } from "../../../../../../../../services/Api/Report/apiReport";
import ReportOrdersUtils from "../../../../../../../../utils/reports/ReportOrdersUtils";
import ReportShareUtils from "../../../../../../../../utils/reports/ReportShareUtils";

interface Props {
	resource?: Record<string, any>;
	setOrganizationsIds: (organizationIds: number[]) => void;
	user: UserState;
}
const listName = "GUEST_REPORT_GROUP";
const resourceType = "GUEST_REPORT_GROUP";

const Report = ({ resource, setOrganizationsIds, user }: Props) => {
	const { t } = useTranslation();
	const [filtersParams, setFilterParams] = useState("");
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const getFetchParams = (params: Record<string, any>) => {
		const decodedFilter = decodeURIComponent(escape(atob(params.f)));
		const filter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${decodedFilter}${FILTER_SEPARATOR}include|e=AVERAGE_PER_GUESTS`;
		const encodedFilter = btoa(unescape(encodeURIComponent(filter)));
		return { ...params, f: encodedFilter };
	};

	let config = {
		reportConfigId: "guests",
		type: "order",
		defaultGroup: "NONE",
		aggregatePrefix: "sales",
		selectedColumns: ["extended.number_of_guests", "extended.average_per_guest_money", "total_money"],
		groups: [
			...ReportShareUtils.getGroupReportCommonGroups(t),
			...ReportOrdersUtils.getGroups(t),
			...ReportShareUtils.getGroups(t),
		],
		groupsInfo: ReportShareUtils.getGroupsInfo(t),
		showPercent: true,
		chart: {
			title: t("modules.report_bill.header.report.title"),
			lineTitle: t("modules.report_bill.field.line_title.title"),
			aggregate: "total_money",
			type: getChartType(),
			parseGroup: (group: string) => {
				if (group === "HOUR_OF_DAY") return "CLOSED_AT_HOUR_OF_DAY";
				if (group === "DAY_OF_MONTH") return "CLOSED_AT_DAY_OF_MONTH";
				if (group === "DATE") return "CLOSED_AT_DATE";
				if (group === "DAY_OF_WEEK") return "CLOSED_AT_DAY_OF_WEEK";
				return group;
			},
		},
		columns: [
			...ReportOrdersUtils.getColumns(t),
			{
				id: "extended.number_of_guests",
				name: t("modules.report_guest.field.number_of_guests.title"),
				render: (item: number) => item,
			},
			{
				id: "extended.average_per_guest_money",
				name: t("modules.report_guest.field.average_per_guest_money.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
		],
		externalSelectedFilters: [
			{
				id: "date_range",
				filterType: "date",
				value: getLastMonthFilterBetween().split("=")[1],
				condition: getLastMonthFilterBetween().split("=")[0],
				visible: true,
			},
			{
				id: "organization_ids",
				filterType: "list",
				condition: "e",
				value: getOrganizationIds(user.organizations?.map((x) => x.id).join(FILTER_VALUE_SEPARATOR)),
				visible: true,
			},
		],
		filters: [...ReportOrdersUtils.getFilters(t, filtersParams), ...ReportShareUtils.getFilters(t, user)],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
				columns: ReportOrdersUtils.getSelectedColumns("orders"),
				filters: [
					{
						filterId: "date_range",
						filterType: "date",
						value: getLastMonthFilterBetween().split("=")[1],
						condition: getLastMonthFilterBetween().split("=")[0],
					},
					{
						filterId: "organization_ids",
						filterType: "list",
						condition: "e",
						value: getOrganizationIds(
							user.organizations?.map((organization) => organization.id)?.join(FILTER_VALUE_SEPARATOR)
						),
					},
				],
			},
		],
		fetch: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			const newParams = getFetchParams(params);
			setOrganizationsIds(getOrganizationIdsFromReportFetchRequest(params.f));
			setFilterParams(newParams.f);
			return Promise.all([
				getReport(
					apiReport.getReportsCustom,
					newParams,
					getOrganizationIdsFromReportFetchRequest(newParams.f),
					config.filters,
					config.type,
					sourceToken
				),
				getChartReport(
					apiReport.getReportsCustom,
					newParams,
					"NONE",
					getOrganizationIdsFromReportFetchRequest(newParams.f),
					config.filters,
					config.type,
					sourceToken
				),
			]);
		},
		fetchChartData: (params: Record<string, any> = {}, sourceToken: CancelTokenSource) => {
			const newParams = getFetchParams(params);
			return getChartReport(
				apiReport.getReportsCustom,
				newParams,
				"NONE",
				getOrganizationIdsFromReportFetchRequest(newParams.f),
				config.filters,
				config.type,
				sourceToken
			);
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		exportConfig: {
			title: t("modules.report_guest.field.export_config.title"),
			filename: t("modules.report_guest.field.export_config_filename.title"),
			pdfOrientation: "ASK",
		},
		withCompareDateRange: true,
		selectedSegment: getSelectedSegmentForReportConfig(data.segments, "all"),
	} as ReportConfig;

	data.fields = getReportCustomFieldsResources(data.fields, ["ORDER"], t);

	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		filterValues: data.filter_values,
	};

	return <ReportData config={config} />;
};

const UserReportsGuestsPage = (): JSX.Element => {
	const user = useSelector(selectUser);
	const [organizationsIds, setOrganizationsIds] = useState<number[]>(() =>
		parseOrganizationIds(user.organizations ?? [])
	);
	const organizationIdsAsString = organizationsIds.join();
	const [resource, setResource] = useState<Record<string, any>>();
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const customFieldResourceTypes = [
		{
			type: "ORDER",
			name: t(`enums.custom_fields.resources.ORDER`),
		},
	];

	useEffect(() => {
		const params: Record<string, any> = {};
		if (organizationsIds?.length > 0) {
			params.organization_id = organizationIdsAsString;
		}

		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes, params)));
	}, [organizationIdsAsString]);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<Report resource={resource} setOrganizationsIds={setOrganizationsIds} user={user} />
		</Suspense>
	);
};

export default UserReportsGuestsPage;
