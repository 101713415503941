import React, { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import { VenueSettingsApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	title: string;
	fields: FieldArrayWithId<VenueSettingsApi, "kds_settings", "key">[];
}

const LimitDirectionsCard = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [collapseIn, setCollapseIn] = useState(false);
	const {
		formState: { errors },
		register,
	} = props.form;

	const onCollapse = () => {
		const collapse = collapseIn;
		setCollapseIn(!collapse);
		const icon = document.getElementById("numbers-icon");
		if (icon) {
			icon.style.transform = !collapse ? "rotate(180deg)" : "";
		}
	};

	return (
		<Card className={"venue-settings mb-4"}>
			<Card.Body>
				<div className={"title"} onClick={() => onCollapse()}>
					<h5>{props.title}</h5>
					<CollapseSVG id={"preparation-icon"} className={"collapse-icon"} />
				</div>
				<small className={"text-muted"} style={{ cursor: "pointer" }} onClick={() => onCollapse()}>
					{t("modules.venue_settings.field.printout.helptext.description")}
				</small>
				<Collapse in={collapseIn}>
					<div>
						<table className={"table table-preview"}>
							<thead>
								<tr>
									<th />
								</tr>
							</thead>
							<tbody>
								{props.fields.map((field, index) => {
									return (
										<tr key={`directions_${props.fields[index].key}`}>
											<td style={{ paddingTop: "14px", paddingBottom: "14px" }}>
												<FormCheck
													className={"float-checkbox-left"}
													label={field.direction.name}
													defaultChecked={field.print_order_number}
													type="switch"
													register={register}
													name={`kds_settings.${index}.print_order_number`}
													errors={errors}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};
export default LimitDirectionsCard;
