import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import ListImageName from "go-app/components/ImageRenderer/ListImageName";
import handleError from "go-app/services/errors";
import EmptyList from "go-core/components/EmptyList";
import { LoadingEmptyData } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import Search from "../../../../../../../../../components/Common/Search/Search";
import { ItemGroupApi, MenuItemApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	item: MenuItemApi;
	onHide: () => void;
}

const CategoryItemGroupsModal = (props: Props): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const [loading] = useState(false);
	const [items, setItems] = useState<ItemGroupApi[]>([]);
	const [filtered, setFiltered] = useState<ItemGroupApi[]>([]);
	const { addFlash } = useFlash();
	const { t } = useTranslation();

	useEffect(() => {
		onFetch();
	}, []);

	const onFetch = async () => {
		const params: Record<string, any> = {
			size: 0,
			sort: "name",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}category|e=${props.item.context_id}`),
		};
		try {
			const res = await api.organization().getItemGroups(params);
			setItems(res);
			setFiltered(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onSearch = (search: string) => {
		setFiltered([...items.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))]);
	};

	return (
		<Modal className="menu-page-item-modal" show={true} onHide={props.onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					{`${t("modules.menu.header.item_groups_belong_to_category.title")} "${props.item.entity.name}"`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="items">
				<Form.Group className="form-group">
					<Search onSearch={onSearch} placeholder={t("common.action.search_product")} />
				</Form.Group>
				<table className="table table-preview">
					<tbody>
						{loading ? (
							<LoadingEmptyData />
						) : (
							<>
								{items.length > 0 ? (
									filtered.map((x: ItemGroupApi, index: number) => {
										return (
											<tr key={index}>
												<td>
													<div className="d-flex align-items-center">
														<ListImageName
															imageLink={x.image_link}
															data={x}
															showColor={false}
															link={`/${organization.id}/menu/item_groups/${x.id}`}
														/>
													</div>
												</td>
											</tr>
										);
									})
								) : (
									<EmptyList title={t("modules.menu.field.empty_list.category_item_groups.title")} />
								)}
							</>
						)}
					</tbody>
				</table>
			</Modal.Body>
		</Modal>
	);
};
export default CategoryItemGroupsModal;
