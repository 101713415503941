import { VenueRoleApi } from "../../../../../services/Api/Organization/types";

export const mapRoles = (data: Record<string, any>): VenueRoleApi[] => {
	const roles: any[] = [];
	data?.roles?.forEach((x: { id: number; unique_id: string }) => {
		if (x.id) {
			roles.push(x.id);
		}
	});
	data?.roles?.forEach((x: string) => {
		roles.push(x);
	});
	return roles.filter((f) => f.id === undefined);
};
