import React, { useContext, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { MoneyApi } from "go-core/api/types";
import { formatDateTimeToString } from "go-core/components/Formatters/FormatDate";
import { FormDirty } from "go-form/components/FormDirty";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	DeliveryEstimationApi,
	LiveOrderAcceptanceFormApi,
	LiveOrderApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../../../services/context";
import { liveOrderParamsInclude } from "../../../../../../services/orderSynchronizer";
import { LiveOrderDeliveryOptionType, woltDriveTimeOptions } from "../../../../../../services/types";
import { getDefaultDeliveryOptions, getLiveOrderNumber } from "../../../../../../services/utils";
import LiveOrderDeliveryOptions from "../LiveOrderDeliveryOptions/LiveOrderDeliveryOptions";
import LiveOrderDeliveryTimeOptions from "../LiveOrderDeliveryTimeOptions/LiveOrderDeliveryTimeOptions";

interface Props {
	show: boolean;
	onHide: () => void;
	order: LiveOrderApi;
}

const LiveOrderAcceptanceModal = ({ onHide, show, order }: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useForm<LiveOrderAcceptanceFormApi>({
		criteriaMode: "all",
	});
	const organization = useSelector(selectOrganization);
	const { handleSubmit, formState, setError, setValue } = form;
	const { addFlash, addSuccessFlash } = useFlash();
	const [selectedTimeOption, setSelectedTimeOption] = useState<number | string | undefined>();
	const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<LiveOrderDeliveryOptionType | undefined>(
		getDefaultDeliveryOptions(organization, order)
	);
	const [estimatedDeliveryData, setEstimatedDeliveryData] = useState<DeliveryEstimationApi | undefined>();
	const ordersContext = useContext(OrdersContext);
	const [deliveryEstimatingLoading, setDeliveryEstimatingLoading] = useState(false);
	const [firstEstimatedDeliveryTime, setFirstEstimatedDeliveryTime] = useState<number | undefined>();
	const [firstEstimatedPrice, setFirstEstimatedPrice] = useState<MoneyApi | undefined>();
	const orderEstimatedPreparationInMinutes = useMemo(() => {
		return order.delivery?.delivery_pickup_in_minutes !== undefined ? order.delivery.delivery_pickup_in_minutes : 0;
	}, [order?.estimated_preparation_at]);
	const assignedDeliveryEmployee =
		order.delivery?.delivery_employee?.reference_id ||
		order.delivery?.delivery_employee?.id ||
		order?.delivery?.delivery_employee?.name === "WOLT_DRIVE";
	const currency = organization.currency || "PLN";

	const getParsedTimeOptionToDataForm = (data: LiveOrderAcceptanceFormApi) => {
		if (typeof selectedTimeOption === "number") {
			const timeToAdd = selectedTimeOption * 60 * 1000;
			const date = new Date(new Date().getTime() + timeToAdd);
			return formatDateTimeToString(date);
		}

		return data.pickup_at;
	};

	const handleCreateWoltDriveDelivery = async () => {
		try {
			await apiOrganization.assignEmployeeDeliveryToOrder(order.id, {
				delivery_employee_id: estimatedDeliveryData?.id,
				delivery_pickup_in_minutes: estimatedDeliveryData?.delivery_pickup_in_minutes,
				delivery_cost: {
					amount: estimatedDeliveryData?.price?.amount || 0,
					currency,
				},
			});
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);
		try {
			if (selectedDeliveryOption === LiveOrderDeliveryOptionType.WOLT_DRIVE) {
				await handleCreateWoltDriveDelivery();
			}

			if (selectedDeliveryOption === LiveOrderDeliveryOptionType.OWN_DRIVER) {
				data.pickup_at = getParsedTimeOptionToDataForm(data);
			} else {
				delete data.pickup_at;
				data.estimated_preparation_at = getParsedTimeOptionToDataForm(data);
			}

			const res = await apiOrganization.acceptLiveOrder(order.id, data, { include: liveOrderParamsInclude });
			ordersContext.updateOrder(res);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const handleUnselectDeliveryOption = (option: LiveOrderDeliveryOptionType | undefined) => {
		setValue("pickup_at", "");
		if (option === LiveOrderDeliveryOptionType.OWN_DRIVER) {
			setSelectedTimeOption(undefined);
		} else if (option === LiveOrderDeliveryOptionType.WOLT_DRIVE) {
			if (!selectedTimeOption && order?.delivery?.delivery_employee?.name === "WOLT_DRIVE") {
				if (orderEstimatedPreparationInMinutes) {
					setSelectedTimeOption(orderEstimatedPreparationInMinutes);
				} else {
					setSelectedTimeOption(estimatedDeliveryData?.delivery_pickup_in_minutes);
				}
			} else if (!assignedDeliveryEmployee) {
				setSelectedTimeOption(estimatedDeliveryData?.delivery_pickup_in_minutes);
			} else if (orderEstimatedPreparationInMinutes === selectedTimeOption) {
				setSelectedTimeOption(orderEstimatedPreparationInMinutes);
			} else if (
				[...woltDriveTimeOptions, firstEstimatedDeliveryTime].includes(orderEstimatedPreparationInMinutes)
			) {
				setSelectedTimeOption(orderEstimatedPreparationInMinutes);
			} else {
				setSelectedTimeOption(undefined);
			}
		} else {
			setSelectedTimeOption(undefined);
		}
	};

	return (
		<Modal show={show} onHide={onHide} className="live-order-acceptance-modal" size="lg">
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{`${t("modules.live_order.field.order_acceptance.title")} ${getLiveOrderNumber(
						order
					)}`}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<LiveOrderDeliveryOptions
						setSelectedDeliveryOption={setSelectedDeliveryOption}
						selectedDeliveryOption={selectedDeliveryOption}
						selectedTimeOption={selectedTimeOption}
						orderId={order.id}
						setEstimatedDeliveryData={setEstimatedDeliveryData}
						estimatedDeliveryData={estimatedDeliveryData}
						setEstimatingLoading={setDeliveryEstimatingLoading}
						estimatingLoading={deliveryEstimatingLoading}
						setSelectedTimeOption={setSelectedTimeOption}
						handleToggle={handleUnselectDeliveryOption}
						firstEstimatedDeliveryTime={firstEstimatedDeliveryTime}
						setFirstEstimatedDeliveryTime={setFirstEstimatedDeliveryTime}
						setFirstEstimatedPrice={setFirstEstimatedPrice}
						firstEstimatedPrice={firstEstimatedPrice}
						order={order}
						orderEstimatedPreparationInMinutes={orderEstimatedPreparationInMinutes}
					/>
					<LiveOrderDeliveryTimeOptions
						setSelectedTimeOption={setSelectedTimeOption}
						selectedTimeOption={selectedTimeOption}
						form={form}
						selectedDeliveryOption={selectedDeliveryOption}
						deliveryEstimatingLoading={deliveryEstimatingLoading}
						firstEstimatedDeliveryTime={firstEstimatedDeliveryTime}
						order={order}
						orderEstimatedPreparationInMinutes={orderEstimatedPreparationInMinutes}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading
						loading={loading}
						type="submit"
						onClick={onSubmit}
						disabled={!selectedDeliveryOption || !selectedTimeOption}
					>
						{t("modules.live_order.action.accept.title")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default LiveOrderAcceptanceModal;
