import { TFunction } from "react-i18next";
import { valueEncoder } from "go-list/core/components/Actions/services";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	TranslationApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTableColumn } from "../../../../../components/VirtualTable/types";
import {
	getVirtualTablePdfDocumentDefinition,
	getVirtualTablePdfPageSize,
	getVirtualTableStyles,
} from "../../../../../components/VirtualTable/utils";

export const getInitialItemGroupTranslationListDefaultColumns = (t: TFunction): VirtualTableColumn[] => [
	{
		id: "category_name",
		name: t("common.word.category"),
	},
	{
		id: "item_group_name",
		name: t("common.word.item_group"),
	},
	{
		id: "name",
		name: t("modules.item_translation.field.default_name.title"),
	},
	{
		id: "description",
		name: t("lib:common.word.description"),
	},
	{
		id: "printing_name",
		name: t("modules.item_translation.field.printing_name.title"),
	},
];

export const getPossibleItemGroupTranslationListDefaultColumns = (t: TFunction<"translation", undefined>) => [
	{
		id: "category_name",
		name: t("common.word.category"),
	},
	{
		id: "item_group_name",
		name: t("common.word.item_group"),
	},
	{
		id: "name",
		name: t("modules.item_translation.field.default_name.title"),
	},
	{
		id: "item_full_name",
		name: t("common.word.item_full_name"),
	},
	{
		id: "description",
		name: t("lib:common.word.description"),
	},
	{
		id: "printing_name",
		name: t("modules.item_translation.field.printing_name.title"),
	},
];

export const generateTranslationsVirtualTableListCsv = (
	defaultColumns: VirtualTableColumn[],
	exportConfig: ExportConfig,
	items: ItemTranslationItemNameFormApi[],
	localesColumns: ItemTranslationItemNameTranslationFormApi[]
) => {
	let csvContent = "data:text/csv;charset=UTF-8,";
	const rows: Record<string, any>[] = [];
	const thead: string[] = [];
	defaultColumns.forEach((column) => {
		thead.push(`"${column.name}"`);
	});
	localesColumns.forEach((priceList) => {
		thead.push(`"${valueEncoder(priceList.name)}"`);
	});
	rows.push(thead);
	items.forEach((item) => {
		const bodyRow: string[] = [];
		defaultColumns.forEach((col) => {
			const val = item[`${col.id}` as keyof ItemTranslationItemNameFormApi] || "";
			bodyRow.push(valueEncoder(`"${val}"`));
		});
		localesColumns.forEach((localeColumn) => {
			const localeColumnId = localeColumn.id.split("_")[0];
			const itemTranslation = item.translations.find((translation) => translation.locale === localeColumnId);
			const columnName = localeColumn.id.split("_")[1];
			const val = itemTranslation ? itemTranslation[columnName as keyof TranslationApi] || "" : "";
			bodyRow.push(valueEncoder(`"${val}"`));
		});
		rows.push(bodyRow);
	});
	csvContent += rows.map((e) => e.join(",")).join("\n");
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", `${exportConfig?.filename}.csv`);
	document.body.appendChild(link);
	link.click();
};

const generateTable = (
	items: ItemTranslationItemNameFormApi[],
	localesColumns: ItemTranslationItemNameTranslationFormApi[],
	defaultColumns: VirtualTableColumn[],
	exportConfig: ExportConfig
) => {
	const tableBody: Record<string, any>[] = [];
	const widths: string[] = [];
	const tHead: Record<string, any>[] = [];
	const table = {
		widths,
		body: tableBody,
		dontBreakRows: true,
	};

	defaultColumns.forEach((col) => {
		tHead.push({
			text: col.name,
			style: getVirtualTableStyles(exportConfig).tableHeader,
		});
		widths.push("auto");
	});
	localesColumns.forEach((localeColumn) => {
		tHead.push({
			text: localeColumn.name,
			style: getVirtualTableStyles(exportConfig).tableHeader,
		});
		widths.push("auto");
	});

	table.body.push(tHead);

	items.forEach((item) => {
		const bodyRow: Record<string, any>[] = [];
		defaultColumns.forEach((col) => {
			bodyRow.push({
				text: {
					text: item[`${col.id}` as keyof ItemTranslationItemNameFormApi],
					noWrap: false,
				},
				style: getVirtualTableStyles(exportConfig).tableCell,
			});
		});
		localesColumns.forEach((localeColumn) => {
			const localeColumnId = localeColumn.id.split("_")[0];
			const itemTranslation = item.translations.find((translation) => translation.locale === localeColumnId);
			const columnName = localeColumn.id.split("_")[1];
			const value = itemTranslation ? itemTranslation[columnName as keyof TranslationApi] || "" : "";
			bodyRow.push({
				text: { text: value, noWrap: false },
				style: getVirtualTableStyles(exportConfig).tableCell,
			});
		});
		table.body.push(bodyRow);
	});
	return table;
};

export const generateTranslationsVirtualTableListPdf = async (
	items: ItemTranslationItemNameFormApi[],
	localesColumns: ItemTranslationItemNameTranslationFormApi[],
	defaultColumns: VirtualTableColumn[],
	exportConfig: ExportConfig,
	filterNames: Array<string>,
	t: TFunction
) => {
	const table = generateTable(items, localesColumns, defaultColumns, exportConfig);
	let filterDefinition = {};
	if (filterNames.length > 0) {
		filterDefinition = {
			text: `${t("common.word.filters", { ns: "lib" })}:\n ${filterNames.join("\n")}`,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 3 : 12,
		};
	}
	const docDefinition: any = getVirtualTablePdfDocumentDefinition(
		getVirtualTablePdfPageSize(localesColumns.length),
		exportConfig,
		t,
		filterDefinition,
		table
	);

	const pdfMake = (await import("pdfmake/build/pdfmake")).default;
	const pdfFonts = (await import("pdfmake/build/vfs_fonts")).default;
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	pdfMake.createPdf(docDefinition).download(`${exportConfig?.filename}`);
};
