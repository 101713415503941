import i18n from "i18next";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TFunction } from "react-i18next";
import { Flash } from "go-alert/AlertMessage";
import { getLang } from "go-core/utils/utils";
import { ExportConfig } from "../components/Actions/services/types";
import { ReportApi, ReportConfigColumn, SubReportApi } from "./types";
import { getAggregatePercentValue, renderAggregateComparePercentValue, renderGroupColumnName } from "./utils";

let ordinalNumberCounter = 0;

const getCurrentDate = () => {
	const date = new Date();
	const value = `${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}`;
	return value.replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
};

const parseValue = (text: string) => {
	const lang = getLang();
	if (lang === "pl" || lang === "pl-PL") {
		return `\"${text.replace(/\./g, ",")}\"`;
	}
	return `\"${text}\"`;
};

const getFormattedDate = (dateString: string) => {
	const [date, dateTime] = dateString.split(" ");
	const [day, month, year] = date?.split("-");

	if (day && month && year) {
		const newDateString = `${year}-${month}-${day}T${dateTime}`;

		if (new Date(newDateString).toString() !== "Invalid Date") {
			return `${year}-${month}-${day}T${dateTime}`;
		}
	}

	return null;
};

const getBodyRowDateRange = (bodyRow: string) => {
	const dates = bodyRow.replaceAll('"', "").split(" - ");

	if (dates.length === 2) {
		const firstDate = getFormattedDate(dates[0]);
		const secondDate = getFormattedDate(dates[1]);

		if (firstDate && secondDate) {
			return dates;
		}
	}

	return null;
};

export const exportToCsv = (
	columns: ReportConfigColumn[],
	data: ReportApi,
	groupHeaders: SubReportApi[],
	comparable: boolean,
	t: TFunction,
	aggregatePrefix: string,
	groupsInfo?: Record<string, any>,
	exportConfig?: ExportConfig,
	groups?: Array<Record<string, string>>
): void => {
	let csvContent = "";
	const tableHeaders: string[] = [];
	const bodyRows: string[] = [];

	data.reports.forEach((report) => {
		const level = 0;
		const names: string[] = [];
		pushHeadRow(tableHeaders, report, columns, groups);
		pushBodyRows(
			tableHeaders,
			bodyRows,
			report,
			columns,
			groupHeaders,
			comparable,
			aggregatePrefix,
			level,
			names,
			groupsInfo,
			exportConfig?.exportAllRows
		);
	});

	const tableHeaderNames = tableHeaders.reduce<string[]>((acc, tableHeader) => {
		if (tableHeader === "DATE_RANGE") {
			return [
				...acc,
				renderGroupColumnName("DATE_START", [
					{ id: "DATE_START", name: t("common.word.start_date", { ns: "lib" }) },
				]),
				renderGroupColumnName("DATE_END", [{ id: "DATE_END", name: t("common.word.end_date", { ns: "lib" }) }]),
			];
		}

		return [...acc, renderGroupColumnName(tableHeader, groups)];
	}, []);

	if (groupHeaders.length > 0 && groupHeaders[0].group_by_type !== "NONE") {
		tableHeaderNames.forEach(() => (csvContent += ","));
		groupHeaders.forEach((gr) => {
			const text = getNameValue(gr, groupsInfo);
			csvContent += `\"${text}\",`;
			for (let i = 0; i < columns.length - 1; ++i) {
				csvContent += ",";
			}
			columns.forEach((col) => {
				tableHeaderNames.push(`\"${col.name}\"`);
			});
		});
		csvContent += `${i18n.t("lib:common.word.summary")},`;
		for (let i = 0; i < columns.length - 1; ++i) {
			csvContent += ",";
		}
		csvContent += "\n";
		columns.forEach((col) => {
			tableHeaderNames.push(`\"${col.name}\"`);
		});
		tableHeaderNames.forEach((br) => {
			if (br !== "\n") {
				csvContent += `${br},`;
			} else {
				csvContent += "\n";
			}
		});
	} else {
		tableHeaderNames.forEach((th) => (csvContent += `${th},`));
		columns.forEach((col) => {
			csvContent += `\"${col.name}\",`;
		});
	}

	csvContent += "\n";
	bodyRows.forEach((br) => {
		if (br !== "\n") {
			const dates = getBodyRowDateRange(br);

			csvContent += dates ? `${dates[0]},${dates[1]},` : `${br},`;
		} else {
			csvContent += "\n";
		}
	});

	csvContent = csvContent.replace(/,\n/g, "\n");
	const csvFile = new Blob([csvContent], { type: `text/csv;charset=UTF-8;` });
	const downloadLink = document.createElement("a");
	const language = getLang();
	downloadLink.download = `${exportConfig?.filename}_${getCurrentDate()}_${
		language === "pl-PL" || language === "pl" ? "pl" : "en"
	}`;
	downloadLink.href = window.URL.createObjectURL(csvFile);
	downloadLink.style.display = "none";

	document.body.appendChild(downloadLink);
	downloadLink.click();
};

const pushHeadRow = (
	tableHeaders: string[],
	report: SubReportApi,
	columns: ReportConfigColumn[],
	groups?: Array<Record<string, string>>
): void => {
	if (!tableHeaders.includes(report.group_by_type) && report.group_by_type !== "NONE") {
		tableHeaders.push(report.group_by_type);
	}

	report.sub_report.forEach((sub) => {
		pushHeadRow(tableHeaders, sub, columns, groups);
	});
};

let prevLevel = 1;
const pushBodyRows = (
	tableHeaders: string[],
	bodyRows: string[],
	report: SubReportApi,
	columns: ReportConfigColumn[],
	groupHeaders: SubReportApi[],
	comparable: boolean,
	aggregatePrefix: string,
	level: number,
	names: string[],
	groupsInfo?: Record<string, any>,
	exportAllRows?: boolean
): void => {
	report.sub_report.forEach((sub) => {
		const currentLevel = tableHeaders.findIndex((f) => f === sub.group_by_type) + 1;
		if (currentLevel < prevLevel) {
			names = names.slice(0, currentLevel - 1);
		}
		const iterationRows: string[] = [];
		let skip = false;
		for (let i = 0; i < tableHeaders.length; ++i) {
			const th = tableHeaders[i];
			if (th === sub.group_by_type) {
				const text = `\"${getNameValue(sub, groupsInfo)}\"`;
				iterationRows.push(text);
				names[i] = text;
			} else {
				iterationRows.push(names[i] === undefined ? "" : names[i]);
				if (names[i] === undefined || currentLevel < prevLevel) skip = true;
			}
		}

		if (groupHeaders.length > 0 && groupHeaders[0].group_by_type !== "NONE") {
			fillAggregateCsvMatrix(groupHeaders, sub, columns, iterationRows, comparable, aggregatePrefix);
		} else if (Object.keys(sub.aggregate).length > 0) {
			Object.keys(sub.aggregate).forEach(() => {
				columns.forEach((col) => {
					pushCsvValue(iterationRows, sub, comparable, col, aggregatePrefix);
				});
			});
		} else {
			columns.forEach((col) => {
				pushCsvValue(iterationRows, sub, comparable, col, aggregatePrefix);
			});
		}

		if (!skip || !!exportAllRows) {
			iterationRows.forEach((it, index) => {
				let val = it;
				if (val === "-") {
					val = names[index];
					if (val === undefined) {
						val = "-";
					}
				}
				bodyRows.push(val);
			});
			bodyRows.push("\n");
		}

		prevLevel = currentLevel;
		pushBodyRows(
			tableHeaders,
			bodyRows,
			sub,
			columns,
			groupHeaders,
			comparable,
			aggregatePrefix,
			currentLevel + 1,
			names,
			groupsInfo,
			exportAllRows
		);
	});
};

const fillAggregateCsvMatrix = (
	groupHeaders: SubReportApi[],
	sub: SubReportApi,
	columns: ReportConfigColumn[],
	bodyRow: any[],
	comparable: boolean,
	aggregatePrefix: string
): void => {
	groupHeaders.forEach((gr) => {
		const key = `${gr.group_by_type}_${gr.group_by_value.name}`;
		if (Object.keys(sub.aggregate).includes(key)) {
			columns.forEach((col) => {
				pushCsvValue(bodyRow, sub, comparable, col, aggregatePrefix, key);
			});
		} else {
			columns.forEach((col) => {
				pushCsvValue(bodyRow, null, comparable, col, aggregatePrefix);
			});
		}
	});
	columns.forEach((col) => {
		pushCsvValue(bodyRow, sub, comparable, col, aggregatePrefix);
	});
};

export const exportToPdf = (
	columns: ReportConfigColumn[],
	filterNames: Array<string>,
	data: ReportApi,
	groupHeaders: SubReportApi[],
	comparable: boolean,
	showPercent: boolean,
	aggregatePrefix: string,
	openPrintoutWindow: boolean,
	flash: (_: Flash) => void,
	t: TFunction,
	groupsInfo?: Record<string, any>,
	exportConfig?: ExportConfig
): void => {
	const table = generatePdfTable(columns, data, groupHeaders, comparable, showPercent, aggregatePrefix, groupsInfo);
	let filterDefinition: Record<string, any> | null = null;
	let organizationName: string | undefined = undefined;
	let companyName: string | undefined = undefined;
	let taxIdNo: string | undefined = undefined;

	if (
		(exportConfig?.company && exportConfig.company !== "undefined") ||
		(exportConfig?.taxIdNo && exportConfig.taxIdNo !== "undefined")
	) {
		if (exportConfig?.company && exportConfig.company !== "undefined") companyName = exportConfig.company;
		if (exportConfig?.taxIdNo && exportConfig.taxIdNo !== "undefined") taxIdNo = exportConfig.taxIdNo;
	} else if (exportConfig?.organization && exportConfig.organization !== "undefined") {
		organizationName = exportConfig?.organization;
	}

	if (filterNames.length > 0) {
		filterDefinition = {
			text: `${i18n.t("lib:common.word.filters")}:\n ${filterNames.join("\n")}`,
			fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 3 : 12,
		};
	}
	const docDefinition: any = {
		pageOrientation: exportConfig?.pdfOrientation ?? "landscape",
		pageSize: "A3",
		pageMargins: [20, 20, 20, 40],
		footer(currentPage: any, pageCount: any) {
			const d = new Date();
			const utc = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
			let footerFirstColumnText = "";

			if (organizationName) footerFirstColumnText += `${i18n.t("lib:common.word.venue")}: ${organizationName}`;
			if (companyName) footerFirstColumnText += `\n ${companyName}`;
			if (taxIdNo) footerFirstColumnText += `\n ${i18n.t("lib:common.word.tax_id_no.title")}: ${taxIdNo}`;
			footerFirstColumnText += `\n ${utc} by GoPOS.pl`;

			return {
				columns: [
					{
						text: `${footerFirstColumnText}`,
						alignment: "left",
						fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
						margin: [20, organizationName ? 10 : 0, 0, 0],
					},
					{
						text: `${i18n.t("lib:common.word.page")} ${currentPage.toString()} ${i18n.t(
							"lib:common.word.of"
						)} ${pageCount}`,
						fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
						alignment: "right",
						margin: [0, 20, 20, 0],
					},
				],
			};
		},
		content: [
			{
				text: `${exportConfig?.title}`,
				bold: true,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 6 : 15,
				alignment: "center",
				margin: [0, 0, 0, 20],
			},
			filterDefinition,
			{
				style: "tableExample",
				color: "#444",
				table,
			},
		],
		styles: {
			bold: {
				bold: true,
			},
			small: {
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 3 : 6,
				color: "#9b9b9b",
			},
			header: {
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
				bold: true,
			},
			subheader: {
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
				bold: true,
				margin: [0, 10, 0, 5],
			},
			tableExample: {
				margin: [0, 10, 0, 0],
			},
			tableHeader: {
				bold: true,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) + 1 : 10,
				color: "#333a38",
			},
			tableSmall: {
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) - 1 : 8,
				color: "#6b6d6d",
			},
			tableCell: {
				bold: false,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
				color: "#464e4c",
			},
			tableCellNested: {
				bold: false,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
				color: "#464e4c",
				fillColor: "#bcc0c7",
			},
			evenDraftCell: {
				bold: false,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
				color: "#464e4c",
				fillColor: "#F0F0F0",
			},
			draftCell: {
				bold: false,
				fontSize: exportConfig?.pdfFontSize ? Number(exportConfig.pdfFontSize) : 9,
				color: "#464e4c",
			},
		},
	};

	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	if (openPrintoutWindow) pdfMake.createPdf(docDefinition).print();
	else pdfMake.createPdf(docDefinition).download(`${exportConfig?.filename}_${getCurrentDate()}`);
};

export const generatePdfTable = (
	columns: ReportConfigColumn[],
	data: ReportApi,
	groupHeaders: SubReportApi[],
	comparable: boolean,
	showPercent: boolean,
	aggregatePrefix: string,
	groupsInfo?: Record<string, any>
): Record<string, any> => {
	const widths: string[] = [];
	const tHead: any[] = [];
	const groupHead: any[] = [];
	const tableBody: any[] = [];
	if (groupHeaders.length > 0 && groupHeaders[0].group_by_type !== "NONE") {
		tHead.push({ text: `${i18n.t("lib:common.word.name")}`, style: "tableHeader" });
		widths.push("auto");
		widths.push("auto");

		for (let i = 0; i <= groupHeaders.length; ++i) {
			columns.forEach((col) => {
				tHead.push({ text: `${col.name}`, style: "tableHeader" });
				widths.push("auto");
			});
		}
		groupHead.push({});
		groupHeaders.forEach((gr) => {
			const text = getNameValue(gr, groupsInfo);
			groupHead.push({ text: `${text}`, style: "tableHeader", colSpan: `${columns.length}` });
			for (let i = 0; i < columns.length - 1; ++i) {
				groupHead.push({});
			}
		});
		groupHead.push({
			text: `${i18n.t("lib:common.word.summary")}`,
			style: "tableHeader",
			colSpan: `${columns.length}`,
		});
		for (let i = 0; i < columns.length - 1; ++i) {
			groupHead.push({});
		}
	} else {
		tHead.push({ text: i18n.t("go_report.word.ordinal_number_shortcut", { ns: "lib" }), style: "tableHeader" });
		widths.push("auto");
		tHead.push({ text: `${i18n.t("lib:common.word.name")}`, style: "tableHeader" });
		widths.push("auto");
		columns.forEach((col) => {
			tHead.push({ text: `${col.name}`, style: "tableHeader" });
			widths.push("auto");
		});
	}

	const table = {
		widths,
		body: tableBody,
		headerRows: 1,
		dontBreakRows: true,
	};

	if (groupHeaders.length > 0 && groupHeaders[0].group_by_type !== "NONE") {
		table.body.push(groupHead);
	}
	table.body.push(tHead);
	data.reports.forEach((report) => {
		const level = 0;
		pushPdfBodyRow(
			report,
			report,
			table.body,
			columns,
			groupHeaders,
			level,
			comparable,
			showPercent,
			aggregatePrefix,
			groupsInfo
		);
	});

	if (groupHeaders[0].group_by_type !== "NONE") {
		table.body[0]?.unshift({
			text: i18n.t("go_report.word.ordinal_number_shortcut", { ns: "lib" }),
			style: "tableHeader",
		});
		table.body[2]?.unshift({ text: "" });
	}

	table.body[1]?.unshift({ text: "" });

	ordinalNumberCounter = 0;
	return table;
};

const pushPdfBodyRow = (
	parentReport: SubReportApi,
	report: SubReportApi,
	table: any[],
	columns: ReportConfigColumn[],
	groupHeaders: SubReportApi[],
	level: number,
	comparable: boolean,
	showPercent: boolean,
	aggregatePrefix: string,
	groupsInfo?: Record<string, any>
): void => {
	const bodyRow: any[] = [];
	if (report.group_by_type === "NONE") {
		bodyRow.push({ text: `${i18n.t("lib:common.word.summary")}`, style: "tableHeader" });
		if (groupHeaders.length > 0 && groupHeaders[0].group_by_type !== "NONE") {
			groupHeaders.forEach((gr) => {
				columns.forEach((col) => {
					pushValue(bodyRow, gr, undefined, false, comparable, col, aggregatePrefix);
				});
			});
			columns.forEach((col) => {
				pushValue(bodyRow, report, undefined, false, comparable, col, aggregatePrefix);
			});
		} else {
			columns.forEach((col) => {
				pushValue(bodyRow, report, undefined, false, comparable, col, aggregatePrefix);
			});
		}

		table.push(bodyRow);
	}

	report.sub_report.forEach((sub) => {
		ordinalNumberCounter++;
		const bodyRow: any[] = [];
		bodyRow.push({ text: ordinalNumberCounter, style: "tableCell" });
		const text = getNameValue(sub, groupsInfo);
		bodyRow.push({ text: `${text}`, style: "tableHeader" });
		if (groupHeaders.length > 0 && groupHeaders[0].group_by_type !== "NONE") {
			fillAggregateMatrix(
				parentReport,
				groupHeaders,
				sub,
				columns,
				bodyRow,
				comparable,
				showPercent,
				aggregatePrefix
			);
		} else if (Object.keys(sub.aggregate).length > 0) {
			Object.keys(sub.aggregate).forEach(() => {
				columns.forEach((col) => {
					pushValue(bodyRow, sub, parentReport, showPercent, comparable, col, aggregatePrefix);
				});
			});
		} else {
			columns.forEach((col) => {
				pushValue(bodyRow, sub, parentReport, showPercent, comparable, col, aggregatePrefix);
			});
		}
		table.push(bodyRow);
		bodyRow[1].margin = [level * 15, 0, 0, 0];
		pushPdfBodyRow(
			parentReport,
			sub,
			table,
			columns,
			groupHeaders,
			level + 1,
			comparable,
			showPercent,
			aggregatePrefix,
			groupsInfo
		);
	});
};

const fillAggregateMatrix = (
	parentReport: SubReportApi,
	groupHeaders: SubReportApi[],
	sub: SubReportApi,
	columns: ReportConfigColumn[],
	bodyRow: any[],
	comparable: boolean,
	showPercent: boolean,
	aggregatePrefix: string
): void => {
	groupHeaders.forEach((gr) => {
		const key = `${gr.group_by_type}_${gr.group_by_value.name}`;
		if (Object.keys(sub.aggregate).includes(key)) {
			columns.forEach((col) => {
				pushValue(bodyRow, sub, parentReport, showPercent, comparable, col, aggregatePrefix, key);
			});
		} else {
			columns.forEach((col) => {
				pushValue(bodyRow, null, undefined, showPercent, comparable, col);
			});
		}
	});
	columns.forEach((col) => {
		pushValue(bodyRow, sub, parentReport, showPercent, comparable, col, aggregatePrefix);
	});
};

const pushValue = (
	bodyRow: any,
	sub: any,
	summaryReport: SubReportApi | undefined,
	showPercent: boolean,
	comparable?: boolean,
	col?: any,
	aggregatePrefix?: string,
	key?: string
) => {
	let text, percentText;
	const prefix = aggregatePrefix ? aggregatePrefix : "summary";

	if (!sub) {
		text = "0";
		if (col.render) {
			text = "0.00";
		}
		if (showPercent) {
			percentText = summaryReport
				? `${getAggregatePercentValue(col, sub, summaryReport.aggregate[prefix], prefix)}`
				: " (0.00%)";
			bodyRow.push({
				text: [
					{ text, style: "tableCell" },
					{ text: percentText, style: "tableSmall" },
				],
			});
		} else {
			bodyRow.push({ text, style: "tableCell" });
		}
		return;
	}
	if (comparable) {
		let compareValue = getAggregateValue(col, sub.compare_aggregate, prefix);
		if (key) {
			compareValue = getAggregateValue(col, sub.compare_aggregate[key], prefix);
		}
		text = `${getAggregateValue(col, sub.aggregate, prefix)} vs ${compareValue}`;
		if (compareValue) {
			percentText = `${renderAggregateComparePercentValue(col, sub, prefix, key)}%\n`;
		} else {
			percentText = "∞%\n";
		}
		bodyRow.push({ text: [{ text: percentText, bold: true }, text], style: "tableCell" });
	} else {
		if (key) {
			text = `${getAggregateValue(col, sub.aggregate[key], prefix)}`;
		} else {
			text = `${getAggregateValue(col, sub.aggregate, prefix)}`;
		}
		if (showPercent) {
			text = `${text}`;
			percentText = summaryReport
				? `${getAggregatePercentValue(col, sub, summaryReport.aggregate[prefix], prefix)}`
				: " (0.00%)";
			bodyRow.push({
				text: [
					{ text, style: "tableCell" },
					{ text: percentText, style: "tableSmall" },
				],
			});
		} else {
			bodyRow.push({ text, style: "tableCell" });
		}
	}
};

const pushCsvValue = (bodyRow: any, sub: any, comparable?: boolean, col?: any, prefix?: string, key?: string) => {
	let text, percentText;
	prefix = prefix ? prefix : "summary";
	if (!sub) {
		text = "0";
		if (col.render) {
			text = "0.00";
		}
		bodyRow.push(parseValue(text));
		return;
	}
	if (comparable) {
		let compareValue = getAggregateValue(col, sub.compare_aggregate, prefix, "csv");
		if (key) {
			compareValue = getAggregateValue(col, sub.compare_aggregate[key], prefix, "csv");
		}
		text = `${getAggregateValue(col, sub.aggregate, prefix, "csv")} vs ${compareValue}`;
		if (compareValue) {
			percentText = `${renderAggregateComparePercentValue(col, sub, key, prefix)}%`;
		} else {
			percentText = "∞%";
		}
		bodyRow.push(parseValue(`${text} ${percentText}`));
	} else {
		if (key) {
			text = `${getAggregateValue(col, sub.aggregate[key], prefix, "csv")}`;
		} else {
			text = `${getAggregateValue(col, sub.aggregate, prefix, "csv")}`;
		}
		bodyRow.push(parseValue(text));
	}
};

const getAggregateValue = (col: ReportConfigColumn, aggregate: any, prefix: string, type?: string) => {
	let val;
	if (!aggregate || !aggregate[prefix]) {
		if (col.renderCsv) {
			col.renderCsv(0);
		}
		if (col.renderView) {
			col.renderView(0);
		}
		return col.render ? "0.00" : "0";
	}
	if (aggregate[prefix]) {
		aggregate = aggregate[prefix];
	}

	const columnParts = col.id.includes(".") ? col.id.split(".").map((columnPart) => columnPart) : [col.id];
	val = columnParts.reduce((prev, curr) => prev?.[curr], aggregate);

	if (typeof val === "object") {
		if (type) {
			if (col.renderCsv) {
				val = col.renderCsv(val ? val : 0);
			} else {
				val = val?.amount || 0.0;
			}
		} else if (col.renderView) {
			val = col.renderView(val ? val : 0);
		} else {
			val = val?.amount || 0.0;
		}
	} else if (type) {
		if (col.renderCsv) {
			val = col.renderCsv(val ? val : 0);
		}
	} else if (col.renderView) {
		val = col.renderView(val ? val : 0);
	}
	if (!val) {
		if (col.render) {
			return "0.00";
		}
		return 0;
	}
	return val;
};

const getNameValue = (reportItem: any, groupsInfo?: Record<string, any>) => {
	let text = reportItem.group_by_value.name;
	if (groupsInfo && Object.prototype.hasOwnProperty.call(groupsInfo, `${reportItem.group_by_type}`)) {
		text = groupsInfo[`${reportItem.group_by_type}`](reportItem.group_by_value);
		if (typeof text !== "string") {
			text = reportItem.group_by_value.name;
		}
	}
	text = text.replaceAll('"', "");
	return text === "empty" ? "-" : text;
};
