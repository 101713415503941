import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import DeliveryZoneForm from "../../components/Form/DeliveryZoneForm";

interface MatchParams {
	deliveryZoneId: string;
}

const OrganizationDeliveryZoneEditPage = () => {
	const { t } = useTranslation();
	const [deliveryZones, setDeliveryZones] = useState<DeliveryZoneApi[] | undefined>();
	const { addFlash, addSuccessFlash } = useFlash();
	const { deliveryZoneId } = useParams<MatchParams>();
	const [deliveryZone, setDeliveryZone] = useState<DeliveryZoneApi | undefined>();
	const confirmation = useConfirmation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		fetchDeliveryZones();
	}, []);

	const fetchDeliveryZones = async () => {
		try {
			const res = await apiOrganization.getDeliveryZones({ size: 0, include: "app" });
			setDeliveryZones(res);
			const currentDeliveryZone = res.find((item) => item.id === deliveryZoneId);
			if (currentDeliveryZone) {
				setDeliveryZone(currentDeliveryZone);
				handleChangeTabTitle(`${currentDeliveryZone.name} | ${t("modules.delivery_zone.header.title")}`);
			} else {
				addFlash({
					msg: "not_found",
					type: "danger",
				});
				handleChangeTabTitle(t("modules.delivery_zone.header.title"));
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleDelete = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});

		try {
			await apiOrganization.removeDeliveryZone(deliveryZone!.id, Number(deliveryZone!.app_id));
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/settings/delivery_zones`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("lib:common.action.remove"),
			action: handleDelete,
			dropdown: true,
		},
	];

	if (!deliveryZones || !deliveryZone) return <LoadingContainer />;

	return (
		<>
			<Header title={`${t("modules.delivery_zone.header.title")} "${deliveryZone.name}"`} buttons={buttons} />
			<DeliveryZoneForm deliveryZones={deliveryZones} deliveryZone={deliveryZone} />
		</>
	);
};

export default OrganizationDeliveryZoneEditPage;
