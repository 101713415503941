import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Loading } from "go-core/components/Loading";
import { FILTER_VALUE_SEPARATOR } from "../../services";
import { FilterCondition, ListConfigFilter, ListSelectedFilter } from "../../services/types";
import { DropdownOption } from "./components/DropdownOption";

interface Props {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	isVisible: boolean;
	onChange: (condition: FilterCondition, selectedFilters: {}) => void;
}

const getSelectedFilterValueInfos = (filterType: string, selectedFilter: ListSelectedFilter) => {
	if (filterType === "search_select") {
		return selectedFilter.valueInfo ? selectedFilter.valueInfo : ({} as any);
	}
	if (selectedFilter.value) {
		const values = selectedFilter.value.split(FILTER_VALUE_SEPARATOR);
		const valueInfo: Record<string, any> = {};
		values.forEach((val) => {
			valueInfo[`${val}`] = val;
		});
		return valueInfo;
	}
	return {};
};

const ListFilterSearchSelect = ({ id, filter, selectedFilter, isVisible, onChange }: Props): JSX.Element => {
	const shouldUseInfiniteScroll = !filter.withoutInfiniteScroll;
	const [value, setValue] = useState(selectedFilter ? selectedFilter.value : "");
	const [search, setSearch] = useState("");
	const [options, setOptions] = useState<any[]>([]);
	const [immutableOptions, setImmutableOptions] = useState<any[]>([]);
	const [uniqueOptions, setUniqueOptions] = useState<any[]>([]);
	const [groupOptions, setGroupOptions] = useState<any[]>([]);
	const [currentlyAppliedGroupFilter, setCurrentlyAppliedGroupFilter] = useState<FilterCondition>();
	const [selectedFilterValueInfos, setSelectedFilterValueInfos] = useState(
		getSelectedFilterValueInfos(filter.type, selectedFilter)
	);
	const [selectedValues, setSelectedValues] = useState<string[]>(value ? value.split(FILTER_VALUE_SEPARATOR) : []);
	const { t } = useTranslation();
	const loader = useRef(null);
	const [page, setPage] = useState<number>(0);
	const [visible, setVisible] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(shouldUseInfiniteScroll);
	const [loading, setLoading] = useState(false);
	const [debounceLoading, setDebounceLoading] = useState(false);
	const cancelTokenSource = useRef<CancelTokenSource>(axios.CancelToken.source());
	const [areAllSelected, setAreAllSelected] = useState(false);
	const [initialSelectedFilterValues, setInitialSelectedFilterValues] = useState(selectedValues);
	const searchTimeout = useRef<any>(0);
	const firstListItemRef = useRef<HTMLAnchorElement>(null);
	const { addFlash } = useFlash();

	useEffect(() => {
		if (selectedFilter.value) {
			setValue(selectedFilter.value);
		}
	}, [selectedFilter.value]);

	const getAreAllOptionsSelected = useCallback(() => {
		if (!uniqueOptions || !value) {
			return false;
		}

		const uniqueOptionsIds = uniqueOptions.map((option) => option.id.toString());
		const valueIds = value.split(FILTER_VALUE_SEPARATOR);

		const isEverythingSelected =
			uniqueOptionsIds.length === valueIds.length &&
			valueIds.every((optionId) => uniqueOptionsIds.includes(optionId));

		return isEverythingSelected;
	}, [uniqueOptions, value]);

	const getNewSelectedFilterValueInfos = useCallback(
		(newSelectedValues: string[]) => {
			return newSelectedValues.reduce((filterValuesSoFar, currentValueId) => {
				const option = options.find((option) => option.id.toString() === currentValueId.toString());

				if (!option) {
					return filterValuesSoFar;
				}

				return {
					...filterValuesSoFar,
					[currentValueId]: option.label,
				};
			}, {});
		},
		[options]
	);

	const wasOptionAlreadySelected = useCallback(
		(option) => {
			if (!initialSelectedFilterValues) {
				return false;
			}

			return initialSelectedFilterValues.includes(option.id.toString());
		},
		[initialSelectedFilterValues]
	);

	const isOptionSelected = useCallback(
		(option): boolean => {
			return selectedValues.includes(option.id.toString());
		},
		[selectedValues]
	);

	const setGroupFilter = useCallback(
		(groupFilter: FilterCondition) => {
			setSelectedFilterValueInfos({});
			setCurrentlyAppliedGroupFilter(groupFilter);
			onChange(groupFilter, groupFilter);
		},
		[setSelectedFilterValueInfos, setCurrentlyAppliedGroupFilter, onChange]
	);

	const clearGroupFilter = useCallback(() => {
		setCurrentlyAppliedGroupFilter(undefined);
		setSelectedFilterValueInfos({});
		onChange("e", {});
	}, [setSelectedFilterValueInfos, setCurrentlyAppliedGroupFilter, onChange]);

	const updateUniqueFilters = useCallback(
		(newSelectedValues: string[]) => {
			setCurrentlyAppliedGroupFilter(undefined);

			const newSelectedFilterValueInfos = getNewSelectedFilterValueInfos(newSelectedValues);

			const didFilterValueInfoChange =
				JSON.stringify(selectedFilter.valueInfo) === JSON.stringify(newSelectedFilterValueInfos);

			if (!didFilterValueInfoChange) {
				setSelectedFilterValueInfos(newSelectedFilterValueInfos);
				onChange("e", newSelectedFilterValueInfos);
			}
		},
		[
			setCurrentlyAppliedGroupFilter,
			getNewSelectedFilterValueInfos,
			setSelectedFilterValueInfos,
			onChange,
			selectedFilter.valueInfo,
		]
	);

	const updateOptions = (newOptions: any[], setNewOptions: (options: any[]) => void) => {
		setNewOptions(
			newOptions.filter(
				({ id }, index) => !newOptions.map((option) => option.id.toString()).includes(id.toString(), index + 1)
			)
		);
	};

	const getCurrentlySelectedOptions = () => {
		return Object.entries(selectedFilterValueInfos).map(([valueInfoKey, valueInfoValue]: [string, any]) => ({
			id: valueInfoKey,
			label: valueInfoValue?.name ? valueInfoValue.name : valueInfoValue,
		}));
	};

	const cancelTokenAndGetNew = () => {
		cancelTokenSource.current.cancel();
		const CancelToken = axios.CancelToken;
		cancelTokenSource.current = CancelToken.source();
	};

	const convertRequestResponseToCorrectFormat = (requestResponse: any) => {
		const data = requestResponse?.data?.data || requestResponse?.data || requestResponse;

		if (filter.type === "search_select") {
			return data;
		}

		return Object.keys(data).map((label) => {
			const labelTranslation = filter.source?.translations?.find((translation) => translation.key === label);

			if (labelTranslation) {
				return {
					id: label,
					label: labelTranslation.label || labelTranslation,
				};
			}

			const labelTranslatorFunction = filter.source?.translations?.find(
				(translation) => translation.key === "FUNCTION"
			);

			if (labelTranslatorFunction) {
				return {
					id: label,
					label: labelTranslatorFunction.label(label),
				};
			}

			return {
				id: label,
				label,
			};
		});
	};

	const getFetchRequestParams = () => {
		if (filter.type === "search_select") {
			return {
				ids: selectedValues,
				page,
			};
		}

		return {
			page,
		};
	};

	const fetchResults = async () => {
		if (!shouldUseInfiniteScroll) setDebounceLoading(true);
		setLoading(true);
		const predefinedOptions = createPredefinedOptions();
		const currentlySelectedOptions = getCurrentlySelectedOptions();

		try {
			const requestParams = getFetchRequestParams();
			const res = await filter.source?.request(search, requestParams, cancelTokenSource.current);

			const data = convertRequestResponseToCorrectFormat(res);

			if (!data && shouldUseInfiniteScroll) {
				setHasNextPage(false);
				setOptions([]);
				delayDisableLoading();
				return;
			}

			let processedData = [...data];

			if (shouldUseInfiniteScroll) {
				if (processedData.length === 0 || processedData.length < 20) {
					setHasNextPage(false);
				} else {
					setPage((p) => p + 1);
				}
			}

			if (filter.source?.render) {
				processedData = processedData.map((data: any) => {
					return { ...data, label: filter.source?.render(data) };
				});
			}

			updateOptions(
				[...currentlySelectedOptions, ...options, ...processedData, ...predefinedOptions],
				setOptions
			);
			updateOptions(
				[...currentlySelectedOptions, ...immutableOptions, ...processedData, ...predefinedOptions],
				setImmutableOptions
			);
			delayDisableLoading();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onChangeValue = (optionId: string) => {
		if (optionId === "a" || optionId === "u") {
			if (selectedValues.find((selectedValue) => selectedValue === optionId)) {
				return setValue("");
			}

			return setValue(optionId);
		}

		if (selectedValues.includes(optionId)) {
			return setValue(
				selectedValues.filter((selectedValue) => selectedValue !== optionId).join(FILTER_VALUE_SEPARATOR)
			);
		}

		const uniqueFilters = selectedValues.filter((selectedValue) => selectedValue !== "a" && selectedValue !== "u");
		setValue([...uniqueFilters, optionId].join(FILTER_VALUE_SEPARATOR));
	};

	const createPredefinedOptions = () => {
		const predefinedOptions: any[] = [];
		if (filter && filter.options) {
			for (const [key, value] of Object.entries(options)) {
				predefinedOptions.push({
					id: key,
					label: value,
				});
			}
		}
		const predefinedOptionForACondtion = {
			id: "a",
			label: t("lib:go_list.filters.any"),
		};
		const predefinedOptionForUCondtion = {
			id: "u",
			label: t("lib:go_list.filters.unknown"),
		};

		const aConditionCustomLabelConfig = filter.conditionCustomLabelConfig?.find(
			({ condition }) => condition === "a"
		);
		const uConditionCustomLabelConfig = filter.conditionCustomLabelConfig?.find(
			({ condition }) => condition === "u"
		);

		if (aConditionCustomLabelConfig) predefinedOptionForACondtion.label = aConditionCustomLabelConfig.label;
		if (uConditionCustomLabelConfig) predefinedOptionForUCondtion.label = uConditionCustomLabelConfig.label;

		predefinedOptions.push(predefinedOptionForACondtion);
		predefinedOptions.push(predefinedOptionForUCondtion);
		return predefinedOptions;
	};

	const delayDisableLoading = () =>
		setTimeout(() => {
			setLoading(false);
			setDebounceLoading(false);
		}, 10);

	const toggleSelectAll = () => {
		if (areAllSelected) {
			setValue("");
		}

		setAreAllSelected(!areAllSelected);
	};

	const scrollToTheTopItem = useCallback(() => {
		if (firstListItemRef.current) {
			firstListItemRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
		}
	}, []);

	useEffect(() => {
		setAreAllSelected(false);
	}, [options, search, setAreAllSelected, setValue]);

	useEffect(() => {
		const newUniqueOptions = options.filter(({ id }) => id !== "u" && id !== "a");
		const newGroupOptions = options.filter(({ id }) => id === "u" || id === "a");

		const uniqueOptionsWithPreselectedOnTop = newUniqueOptions.sort((firstOption, secondOption) => {
			const wasFirstOptionSelected = wasOptionAlreadySelected(firstOption);
			const wasSecondOptionSelected = wasOptionAlreadySelected(secondOption);

			if (wasFirstOptionSelected && !wasSecondOptionSelected) {
				return -1;
			}

			if (!wasFirstOptionSelected && wasSecondOptionSelected) {
				return 1;
			}

			return 0;
		});

		setUniqueOptions(uniqueOptionsWithPreselectedOnTop);
		setGroupOptions(newGroupOptions);
	}, [options, setUniqueOptions, setGroupOptions, wasOptionAlreadySelected]);

	useEffect(() => {
		if (!isVisible && shouldUseInfiniteScroll) {
			setInitialSelectedFilterValues(selectedValues);
		}
	}, [isVisible, selectedValues]);

	useEffect(() => {
		scrollToTheTopItem();
	}, [isVisible, scrollToTheTopItem]);

	useEffect(() => {
		if (areAllSelected) {
			setValue(uniqueOptions.map((option) => option.id.toString()).join(FILTER_VALUE_SEPARATOR));
		}
	}, [areAllSelected, uniqueOptions, setValue]);

	useEffect(() => {
		if (options.length === 0) {
			return;
		}

		const newSelectedValues = value ? value.split(FILTER_VALUE_SEPARATOR) : [];
		setSelectedValues(newSelectedValues);

		const groupFilter = newSelectedValues.find((filter) => filter === "a" || filter === "u") as
			| FilterCondition
			| undefined;

		if (groupFilter) {
			if (groupFilter !== currentlyAppliedGroupFilter) {
				setGroupFilter(groupFilter);
			}

			return;
		}

		if (newSelectedValues.length === 0 && currentlyAppliedGroupFilter) {
			return clearGroupFilter();
		}

		updateUniqueFilters(newSelectedValues);
	}, [
		options,
		setSelectedValues,
		// setGroupFilter,
		// clearGroupFilter,
		// updateUniqueFilters,
		currentlyAppliedGroupFilter,
		value,
	]);

	useEffect(() => {
		setAreAllSelected(getAreAllOptionsSelected());
	}, [setAreAllSelected, getAreAllOptionsSelected]);

	useEffect(() => {
		if (shouldUseInfiniteScroll) {
			setDebounceLoading(true);
			setLoading(true);
			if (searchTimeout.current) {
				clearTimeout(searchTimeout.current);
			}
			if (!search) {
				cancelTokenAndGetNew();
				setOptions([]);
				setPage(0);
				setHasNextPage(true);
				setLoading(false);
			} else {
				searchTimeout.current = setTimeout(() => {
					cancelTokenAndGetNew();
					setOptions([]);
					setPage(0);
					setHasNextPage(true);
					setLoading(false);
				}, 500);
			}
		} else {
			handleSearchOptionsWithoutInfiniteScroll();
		}
	}, [search]);

	const handleSearchOptionsWithoutInfiniteScroll = () => {
		setLoading(true);
		setDebounceLoading(true);
		if (searchTimeout.current) {
			clearTimeout(searchTimeout.current);
		}
		if (!search) {
			handleUpdateSearchedOptionsWithoutInfiniteScroll();
			setLoading(false);
			setDebounceLoading(false);
		} else {
			searchTimeout.current = setTimeout(() => {
				handleUpdateSearchedOptionsWithoutInfiniteScroll();
				setLoading(false);
				setDebounceLoading(false);
			}, 500);
		}
	};

	const handleUpdateSearchedOptionsWithoutInfiniteScroll = () => {
		const predefinedOptions = createPredefinedOptions();
		const currentlySelectedOptions = getCurrentlySelectedOptions();

		if (!search) {
			updateOptions([...currentlySelectedOptions, ...immutableOptions, ...predefinedOptions], setOptions);
		} else {
			const searchedOptions = immutableOptions.filter((option) =>
				option.label.toLowerCase().includes(search.toLowerCase())
			);
			updateOptions([...currentlySelectedOptions, ...searchedOptions, ...predefinedOptions], setOptions);
		}
	};

	useEffect(() => {
		if (shouldUseInfiniteScroll) {
			if (visible && hasNextPage && !loading) {
				fetchResults();
			}
		}
	}, [visible, hasNextPage, loading]);

	useEffect(() => {
		if (!shouldUseInfiniteScroll) {
			fetchResults();
		}
	}, []);

	useEffect(() => {
		if (shouldUseInfiniteScroll) {
			const opt = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0,
			};
			const observer = new IntersectionObserver(([entry]) => {
				setVisible(() => entry.isIntersecting);
			}, opt);

			if (loader.current) {
				observer.observe(loader.current);
			}

			return () => {
				if (loader.current) {
					observer.unobserve(loader.current);
				}
			};
		}
	}, [loader]);

	const showLoading = () => {
		if (!shouldUseInfiniteScroll) {
			return loading || debounceLoading;
		}
		return (loading && hasNextPage) || debounceLoading;
	};

	return (
		<Fragment key={`${filter.id}-search-select`}>
			<Form.Control
				type="search"
				placeholder={t("lib:common.word.search")}
				className="filter-input"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>
			<Form.Group className="form-group list-search-select-container">
				{uniqueOptions.length > 0 && !debounceLoading && (
					<div className="list-search-select-select-all">
						<DropdownOption
							id="select_all"
							dropDownItemId="type_select_all"
							label={t("lib:go_list.filters.select_all")}
							isSelected={areAllSelected}
							onClick={toggleSelectAll}
						/>
					</div>
				)}
				{!debounceLoading &&
					uniqueOptions.map((option, i) => (
						<DropdownOption
							id={option.id.toString()}
							key={`type_${id}_${option.id}`}
							dropDownItemId={`type_${id}_${option.id}`}
							label={option.label}
							isSelected={isOptionSelected(option)}
							onClick={onChangeValue}
							optionRef={i === 0 ? firstListItemRef : undefined}
						/>
					))}
				<div className="loading" ref={loader} style={{ display: hasNextPage ? "block" : "none" }} />
				{showLoading() && <Loading />}
				{filter.hasDefaultOptions !== false && !debounceLoading && (
					<div
						className={"list-search-select-footer"}
						style={options.length <= 2 ? { borderTop: "none" } : undefined}
					>
						{groupOptions.map((option) => (
							<DropdownOption
								id={option.id.toString()}
								key={`type_${id}_${option.id}`}
								dropDownItemId={`type_${id}_${option.id}`}
								label={option.label}
								isSelected={isOptionSelected(option)}
								onClick={onChangeValue}
							/>
						))}
					</div>
				)}
			</Form.Group>
		</Fragment>
	);
};

export default ListFilterSearchSelect;
