import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch, useParams } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Page404 from "go-app/components/Page404/Page404";
import {
	CustomFieldContext,
	CustomFieldContextProps,
	CustomFieldService,
} from "go-component/components/CustomField/services/context";
import { CustomFieldTypeApi } from "go-component/components/CustomField/services/types";
import { CsvContext } from "go-component/components/ImportCsv/services/context";
import { MessageEventContext, MessageEventService } from "go-component/components/MessageEvent/services/context";
import { MessageResourceType } from "go-component/components/MessageEvent/services/types";
import { useMomentLocaleForLocation } from "go-core/hooks/useMomentLocale";
import OrganizationIndexSecurityComponent from "go-security/components/Organization/Index";
import SecurityOrganizationAcceptTerms from "go-security/components/OrganizationAcceptTerms/OrganizationAcceptTerms";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentContext, SegmentListService } from "go-segment/context";
import { SegmentContextProps } from "go-segment/services/types";
import { apiOrganizationComponent } from "../../../../services/Api/Organization/apiOrganizationComponent";
import { apiOrganizationSegment } from "../../../../services/Api/Organization/apiOrganizationSegment";
import { ConfigContext } from "../../../../services/Config/context";
import { getApiURL } from "../../../../utils/envutil";
import OrganizationAppsPage from "./pages/Apps";
import OrganizationClientsPage from "./pages/Clients";
import { OrganizationDashboardPage } from "./pages/Dashboard";
import { OrganizationDiagnosticsPage } from "./pages/Diagnostics";
import OrganizationEmployeesPage from "./pages/Employees";
import OrganizationInstallPage from "./pages/Install";
import OrganizationInvoicesPage from "./pages/Invoices";
import OrganizationLiveOrdersPage from "./pages/LiveOrders";
import OrganizationLogsPage from "./pages/Logs";
import OrganizationMenuPage from "./pages/Menu";
import OrganizationMigrationPage from "./pages/Migration";
import OrganizationReportsPage from "./pages/Reports";
import OrganizationReservationsPage from "./pages/Reservations";
import OrganizationSalesPage from "./pages/Sales";
import OrganizationSettingsPage from "./pages/Settings";

interface MatchParams {
	organizationId: string;
}

const OrganizationPage: React.FC<RouteComponentProps> = (props) => {
	const organization = useSelector(selectOrganization);
	const currentOrganization = useSelector(selectOrganization);
	const { organizationId } = useParams<MatchParams>();
	const configService = useContext(ConfigContext);
	const { t } = useTranslation();
	const { addFlash } = useFlash();

	const segmentPath = `/${currentOrganization.id}/settings/segments`;

	const resources = [
		{
			id: "PAYMENT_METHOD",
			title: t("enums.message_events.resource_type.PAYMENT_METHOD"),
			link: `/${currentOrganization.id}/settings/payment_methods/`,
		},
		{
			id: "REPORT",
			title: t("enums.message_events.resource_type.REPORT"),
			link: `/${currentOrganization.id}/sales/pos_reports/`,
		},
		{
			id: "EMPLOYEE",
			title: t("enums.message_events.resource_type.EMPLOYEE"),
			link: `/${currentOrganization.id}/employees/`,
		},
		{
			id: "ITEM_GROUP",
			title: t("enums.message_events.resource_type.ITEM_GROUP"),
			link: `/${currentOrganization.id}/menu/item_groups/`,
		},
		{
			id: "MODIFIER_GROUP",
			title: t("enums.message_events.resource_type.MODIFIER_GROUP"),
			link: `/${currentOrganization.id}/menu/modifier_groups/`,
		},
		{
			id: "ORDER",
			title: t("enums.message_events.resource_type.ORDER"),
			link: `/${currentOrganization.id}/sales/orders/`,
		},
	] as MessageResourceType[];

	const customFieldResources = [
		{
			id: "ITEM",
			title: t("enums.custom_fields.resources.ITEM"),
		},
		{
			id: "INVOICE",
			title: t("enums.custom_fields.resources.INVOICE"),
		},
		{
			id: "ORDER",
			title: t("enums.custom_fields.resources.ORDER"),
		},
		{
			id: "PAYMENT_METHOD",
			title: t("enums.custom_fields.resources.PAYMENT_METHOD"),
		},
		{
			id: "EMPLOYEE",
			title: t("enums.custom_fields.resources.EMPLOYEE"),
		},
	] as CustomFieldTypeApi[];

	const {
		importCsv,
		finishImportCsv,
		getCsvExampleSimpleFile,
		getCsvExampleFile,
		getCsvFromVenue,
		getCsvAttributes,
		getFinishImportCsvProgress,
		getImportCsvProgress,
		finishImportAsyncCsv,
		importAsyncCsv,
	} = apiOrganizationComponent;

	const csvContextValue = {
		importCsv,
		finishImportCsv,
		getCsvExampleSimpleFile,
		getCsvExampleFile,
		getCsvFromVenue,
		getCsvAttributes,
		getFinishImportCsvProgress,
		getImportCsvProgress,
		finishImportAsyncCsv,
		importAsyncCsv,
	};

	useMomentLocaleForLocation(organization);

	return (
		<SegmentContext.Provider
			value={
				new SegmentListService(
					{
						saveSegment: apiOrganizationSegment.saveSegment,
						removeSegment: apiOrganizationSegment.removeSegment,
						getSegments: apiOrganizationSegment.getSegments,
						restoreSegment: apiOrganizationSegment.restoreSegment,
						updateSegment: apiOrganizationSegment.updateSegment,
						updateSegments: apiOrganizationSegment.updateSegments,
						setAsDefault: apiOrganizationSegment.setSegmentAsDefault,
						setNotDefault: apiOrganizationSegment.setSegmentNotDefault,
					},
					segmentPath,
					apiOrganizationSegment.getListInfo,
					addFlash
				)
			}
		>
			<MessageEventContext.Provider
				value={
					new MessageEventService(
						{
							all: apiOrganizationComponent.getMessageEvents,
							get: apiOrganizationComponent.getMessageEvent,
							getTypeOptions: apiOrganizationComponent.getMessageEventsTypes,
						},
						resources,
						"enums.message_events.type",
						t
					)
				}
			>
				<CustomFieldContext.Provider
					value={
						new CustomFieldService(
							{
								all: apiOrganizationComponent.getCustomFields,
								get: apiOrganizationComponent.getCustomField,
								create: apiOrganizationComponent.createCustomField,
								update: apiOrganizationComponent.updateCustomField,
								remove: apiOrganizationComponent.removeCustomField,
								restore: apiOrganizationComponent.restoreCustomField,
							} as CustomFieldContextProps,
							customFieldResources
						)
					}
				>
					<CsvContext.Provider value={csvContextValue}>
						<OrganizationIndexSecurityComponent
							organizationId={organizationId}
							locationState={props.location.state}
							goaccounts_url={configService.getGoAccountsUrl()}
							baseUrl={getApiURL()}
						>
							<Switch>
								<Route path={`${props.match.url}/install`} component={OrganizationInstallPage} />
								<Route path={`${props.match.url}/sales`} component={OrganizationSalesPage} />
								<Route path={`${props.match.url}/menu`} component={OrganizationMenuPage} />
								<Route path={`${props.match.url}/migration`} component={OrganizationMigrationPage} />
								<Route
									path={`${props.match.url}/diagnostics`}
									component={OrganizationDiagnosticsPage}
								/>
								<Route path={`${props.match.url}/settings`} component={OrganizationSettingsPage} />
								<Route path={`${props.match.url}/employees`} component={OrganizationEmployeesPage} />
								<Route path={`${props.match.url}/clients`} component={OrganizationClientsPage} />
								<Route
									path={`${props.match.url}/reservations`}
									component={OrganizationReservationsPage}
								/>
								<Route path={`${props.match.url}/reports`} component={OrganizationReportsPage} />
								<Route path={`${props.match.url}/logs`} component={OrganizationLogsPage} />
								<Route path={`${props.match.url}/apps`} component={OrganizationAppsPage} />
								<Route path={`${props.match.url}/invoices`} component={OrganizationInvoicesPage} />
								<Route path={`${props.match.url}/live_orders`} component={OrganizationLiveOrdersPage} />
								<Route exact path={"/:id"} component={OrganizationDashboardPage} />
								<Route exact path={"/:id/old"} component={OrganizationDashboardPage} />
								<Route exact path={"/:id/accept_terms"} component={SecurityOrganizationAcceptTerms} />
								<Route component={Page404} />
							</Switch>
						</OrganizationIndexSecurityComponent>
					</CsvContext.Provider>
				</CustomFieldContext.Provider>
			</MessageEventContext.Provider>
		</SegmentContext.Provider>
	);
};
export default OrganizationPage;

// t("enums.message_events.type.PAYMENT_METHOD_CREATED");
// t("enums.message_events.type.PAYMENT_METHOD_UPDATED");
// t("enums.message_events.type.PAYMENT_METHOD_DELETED");
// t("enums.message_events.type.PAYMENT_METHOD_RESTORED");
// t("enums.message_events.type.REPORT_OPENED");
// t("enums.message_events.type.REPORT_CLOSED");
// t("enums.message_events.type.REPORT_UPDATED");
// t("enums.message_events.type.REPORT_MAIL_SENT");
// t("enums.message_events.type.EMPLOYEE_CREATED");
// t("enums.message_events.type.EMPLOYEE_UPDATED");
// t("enums.message_events.type.EMPLOYEE_DELETED");
// t("enums.message_events.type.EMPLOYEE_FIRED");
// t("enums.message_events.type.EMPLOYEE_HIRED");
// t("enums.message_events.type.ITEM_GROUP_CREATED");
// t("enums.message_events.type.ITEM_GROUP_UPDATED");
// t("enums.message_events.type.ITEM_GROUP_DELETED");
// t("enums.message_events.type.ITEM_GROUP_RESTORED");
// t("enums.message_events.type.ITEM_GROUP_DISABLED");
// t("enums.message_events.type.ITEM_GROUP_DELETED_PERMANENTLY");
// t("enums.message_events.type.MODIFIER_GROUP_UPDATED");
// t("enums.message_events.type.MODIFIER_GROUP_RESTORED");
// t("enums.message_events.type.MODIFIER_GROUP_DELETED");
// t("enums.message_events.type.MODIFIER_GROUP_CREATED");
// t("enums.message_events.type.ORDER_REJECTED");
// t("enums.message_events.type.ORDER_INVALID");
// t("enums.message_events.type.ORDER_CREATED");
// t("enums.message_events.type.ORDER_DELIVERY_EMPLOYEE_CREATED");
// t("enums.message_events.type.ORDER_DELIVERY_EMPLOYEE_UPDATED");
// t("enums.message_events.type.ORDER_DELIVERY_EMPLOYEE_DELETED");
// t("enums.message_events.type.ORDER_CLOSED");
// t("enums.message_events.type.ORDER_DELETED");
// t("enums.message_events.type.ORDER_UPDATED");
// t("enums.message_events.type.ORDER_ACCEPTED");
// t("enums.message_events.type.ORDER_CANCELED");
// t("enums.message_events.type.ORDER_VOIDED");
// t("enums.message_events.type.ORDER_SET_AS_REMOVED");
// t("enums.message_events.resource_type.REPORT_INTERNAL");
// t("enums.message_events.resource_type.NOTIFICATION");
// t("enums.message_events.resource_type.STOCK_PRODUCT_VARIANT");
// t("enums.message_events.resource_type.SALE");
// t("enums.message_events.resource_type.APPLICATION_LINKED");
// t("enums.message_events.resource_type.ADDITIONAL_GROUP");
// t("enums.message_events.resource_type.ADDITIONAL");
// t("enums.message_events.resource_type.DISCOUNT");
// t("enums.message_events.resource_type.PRODUCT_CATEGORY");
// t("enums.message_events.resource_type.USER");
// t("enums.message_events.type.APP_LINK_UPDATED");
// t("enums.message_events.type.APP_LINK_CREATED");
// t("enums.message_events.type.USER_REGISTER");
// t("enums.message_events.type.ORDER_STATUS_PREPERATION_UPDATED");
// t("enums.message_events.type.ORDER_STATUS_PREPERATION_CREATED");
// t("enums.message_events.type.TERMINAL_CREATED");
// t("enums.message_events.type.TERMINAL_UPDATED");
// t("enums.message_events.type.TERMINAL_DELETED");
// t("enums.message_events.type.TERMINAL_ENABLED");
// t("enums.message_events.type.TERMINAL_DISABLED");
// t("enums.message_events.type.TERMINAL_MASTER_ENABLED");
// t("enums.message_events.type.TERMINAL_MASTER_DISABLED");
// t("enums.message_events.type.CATEGORY_UPDATED");
// t("enums.message_events.type.CATEGORY_CREATED");
// t("enums.message_events.type.CATEGORY_DELETED");
// t("enums.message_events.type.CATEGORY_RESTORED");
// t("enums.message_events.type.MENU_DISCOUNT_UPDATED");
// t("enums.message_events.type.MENU_DISCOUNT_CREATED");
// t("enums.message_events.type.MENU_DISCOUNT_DELETED");
// t("enums.message_events.type.MENU_DISCOUNT_RESTORED");
// t("enums.message_events.type.TAX_UPDATED");
// t("enums.message_events.type.TAX_CREATED");
// t("enums.message_events.type.TAX_DELETED");
// t("enums.message_events.type.TAX_RESTORED");
// t("enums.message_events.type.MENU_UPDATED");
// t("enums.message_events.type.MENU_RESTORED");
// t("enums.message_events.type.MENU_CREATED");
// t("enums.message_events.type.MENU_DELETED");
// t("enums.message_events.type.WORK_TIME_CREATED");
// t("enums.message_events.type.WORK_TIME_UPDATED");
// t("enums.message_events.type.WORK_TIME_DELETED");
// t("enums.message_events.type.WORK_TIME_RESTORED");
// t("enums.message_events.type.WORK_TIME_REQUEST_CREATED");
// t("enums.message_events.type.CLIENT_CREATED");
// t("enums.message_events.type.CLIENT_UPDATED");
// t("enums.message_events.type.CLIENT_DELETED");
// t("enums.message_events.type.CLIENT_RESTORED");
// t("enums.message_events.type.CLIENT_GROUP_CREATED");
// t("enums.message_events.type.CLIENT_GROUP_UPDATED");
// t("enums.message_events.type.CLIENT_GROUP_DELETED");
// t("enums.message_events.type.CLIENT_GROUP_RESTORED");
// t("enums.message_events.type.CURRENCY_RATE_UPDATED");
// t("enums.message_events.type.CURRENCY_RATE_DELETED");
// t("enums.message_events.type.DIRECTION_CREATED");
// t("enums.message_events.type.DIRECTION_UPDATED");
// t("enums.message_events.type.DIRECTION_RESTORED");
// t("enums.message_events.type.DIRECTION_DELETED");
// t("enums.message_events.type.ROOM_CREATED");
// t("enums.message_events.type.ROOM_UPDATED");
// t("enums.message_events.type.ROOM_DELETED");
// t("enums.message_events.type.ROOM_RESTORED");
// t("enums.message_events.type.ROOM_UPDATED");
// t("enums.message_events.type.POINT_OF_SALE_CREATED");
// t("enums.message_events.type.POINT_OF_SALE_UPDATED");
// t("enums.message_events.type.POINT_OF_SALE_DELETED");
// t("enums.message_events.type.POINT_OF_SALE_RESTORED");
// t("enums.message_events.type.POINT_OF_SALE_ADD_ROOM");
// t("enums.message_events.type.POINT_OF_SALE_REMOVE_ROOM");
// t("enums.message_events.type.POINT_OF_SALE_ACTIVATE_ROOM");
// t("enums.message_events.type.POINT_OF_SALE_DEACTIVATE_ROOM");
// t("enums.message_events.type.RESERVATION_CREATED");
// t("enums.message_events.type.RESERVATION_UPDATED");
// t("enums.message_events.type.RESERVATION_DELETED");
// t("enums.message_events.type.RESERVATION_CANCELED");
// t("enums.message_events.type.PRICE_LIST_CREATED");
// t("enums.message_events.type.PRICE_LIST_UPDATED");
// t("enums.message_events.type.PRICE_LIST_DELETED");
// t("enums.message_events.type.WORKPLACE_CREATED");
// t("enums.message_events.type.WORKPLACE_UPDATED");
// t("enums.message_events.type.WORKPLACE_DELETED");
// t("enums.message_events.type.DEFAULT_COMMENT_CREATED");
// t("enums.message_events.type.DEFAULT_COMMENT_UPDATED");
// t("enums.message_events.type.DEFAULT_COMMENT_DELETED");
// t("enums.message_events.type.DEFAULT_COMMENT_RESTORED");
// t("enums.message_events.type.AVAILABILITY_UPDATED");
// t("enums.message_events.type.AVAILABILITY_CREATED");
// t("enums.message_events.type.AVAILABILITY_RESTORED");
// t("enums.message_events.type.AVAILABILITY_DELETED");
// t("enums.message_events.type.VENUE_SETTINGS_UPDATED");
// t("enums.message_events.type.VENUE_RECEIPT_UPDATED");
// t("enums.message_events.type.COMPANY_UPDATED");
// t("enums.message_events.type.POS_ACCESS_UPDATED");
// t("enums.message_events.type.POS_ACCESS_DISPLAYED");
// t("enums.message_events.type.BANK_ACCOUNT_CREATED");
// t("enums.message_events.type.BANK_ACCOUNT_UPDATED");
// t("enums.message_events.type.BANK_ACCOUNT_DELETED");
// t("enums.message_events.type.VENUE_UPDATED");
// t("enums.message_events.type.PERMISSION_UPDATED");
// t("enums.message_events.type.ROLE_UPDATED");
// t("enums.message_events.type.ROLE_CREATED");
// t("enums.message_events.type.ROLE_DELETED");
// t("enums.message_events.type.REPORT_PAID_UPDATED");
// t("enums.message_events.type.INVOICE_CREATED");
// t("enums.message_events.type.INVOICE_UPDATED");
// t("enums.message_events.type.INVOICE_CONFIRMED");
// t("enums.message_events.type.INVOICE_DELETED");
// t("enums.message_events.type.PRO_FORMA_INVOICE_CREATED");
// t("enums.message_events.type.PRO_FORMA_INVOICE_UPDATED");
// t("enums.message_events.type.PRO_FORMA_INVOICE_DELETED");
// t("enums.message_events.type.INVOICE_DETAILS_UPDATED");
// t("enums.message_events.type.TRANSACTION_CREATED");
// t("enums.message_events.type.TRANSACTION_UPDATED");
// t("enums.message_events.type.TRANSACTION_OPENED");
// t("enums.message_events.type.TRANSACTION_CONFIRMED");
// t("enums.message_events.type.TRANSACTION_CANCELED");
// t("enums.message_events.type.NOTIFICATION_CREATED");
// t("enums.message_events.type.NOTIFICATION_PUBLISHED");
// t("enums.message_events.type.CUSTOM_FIELD_CREATED");
// t("enums.message_events.type.CUSTOM_FIELD_UPDATED");
// t("enums.message_events.type.CUSTOM_FIELD_RESTORED");
// t("enums.message_events.type.CUSTOM_FIELD_DELETED");
// t("enums.message_events.type.SEGMENT_CREATED");
// t("enums.message_events.type.SEGMENT_UPDATED");
// t("enums.message_events.type.SEGMENT_DELETED");
// t("enums.message_events.type.SEGMENT_RESTORED");
// t("enums.message_events.type.ITEM_CREATED");
// t("enums.message_events.type.ITEM_UPDATED");
// t("enums.message_events.type.ITEM_DELETED");
// t("enums.message_events.type.ITEM_RESTORED");
// t("enums.message_events.type.ITEM_DISABLED");
// t("enums.message_events.type.MENU_PRICE_LIST_DELETED");
// t("enums.message_events.type.MENU_PRICE_LIST_CREATED");
// t("enums.message_events.type.MENU_PRICE_LIST_UPDATED");
// t("enums.message_events.type.REPORT_CREATED");
// t("enums.message_events.type.PRINTOUT_TEMPLATE_DELETED");
// t("enums.message_events.type.PRINTOUT_TEMPLATE_CREATED");
// t("enums.message_events.type.PRINTOUT_TEMPLATE_RESTORED");
// t("enums.message_events.type.PRINTOUT_TEMPLATE_UPDATED");
// t("enums.message_events.type.ORGANIZATION_RULES_ACCEPTED");
// t("enums.message_events.type.WEBHOOK_DELETED");
// t("enums.message_events.type.WEBHOOK_UPDATED");
// t("enums.message_events.type.WEBHOOK_CREATED");
// t("enums.message_events.type.ORGANIZATION_INFO_UPDATED");
// t("enums.message_events.type.ORGANIZATION_REMOVED");
// t("enums.message_events.type.ORGANIZATION_DELETED");
// t("enums.message_events.type.ORGANIZATION_UPDATED");
// t("enums.message_events.type.USER_PASSWORD_CHANGED");
// t("enums.message_events.type.USER_RESET_PASSWORD_LINK_SENT");
// t("enums.message_events.type.ORGANIZATION_APPLICATION_UPDATED");
// t("enums.message_events.type.ORGANIZATION_APPLICATION_FROZEN");
// t("enums.message_events.type.ORGANIZATION_APPLICATION_CREATED");
// t("enums.message_events.type.ORGANIZATION_APPLICATION_DELETED");
// t("enums.message_events.type.ORGANIZATION_CREATED");
// t("enums.message_events.type.USER_LOGOUT");
// t("enums.message_events.type.APP_LINK_DELETED");
// t("enums.message_events.type.ROOM_TABLE_DELETED");
// t("enums.message_events.type.ROOM_TABLE_CREATED");
// t("enums.message_events.type.CURRENCY_RATE_CREATED");
// t("enums.message_events.type.DEVICE_CREATED");
// t("enums.message_events.type.DEVICE_DELETED");
// t("enums.message_events.type.DEVICE_RESTORED");
// t("enums.message_events.type.DEVICE_UPDATED");
// t("enums.message_events.type.APP_DISABLED");
// t("enums.message_events.type.TAG_DELETED");
// t("enums.message_events.type.TAG_CREATED");
// t("enums.message_events.type.TAG_UPDATED");
// t("enums.message_events.type.APP_CREATED");
// t("enums.message_events.type.APP_ENABLED");
// t("enums.message_events.type.APP_UPDATED");
// t("enums.message_events.type.IMAGE_DELETED");
// t("enums.message_events.type.IMAGE_UPDATED");
// t("enums.message_events.type.IMAGE_CREATED");
// t("enums.message_events.type.IMAGE_COPIED");
// t("enums.message_events.type.ATTRIBUTE_DELETED");
// t("enums.message_events.type.ATTRIBUTE_UPDATED");
// t("enums.message_events.type.ATTRIBUTE_CREATED");
// t("enums.message_events.type.PROGRESS_UPDATED");
// t("enums.message_events.type.APPLICATION_BASE_TICKET_DELETED");
// t("enums.message_events.type.APPLICATION_BASE_TICKET_CREATED");
// t("enums.message_events.type.APPLICATION_BASE_TICKET_UPDATED");
// t("enums.message_events.type.APP_LINKED_PAYMENT_METHOD_UPDATED");
// t("enums.message_events.type.APPLICATION_DELETED");
// t("enums.message_events.type.APPLICATION_UPDATED");
// t("enums.message_events.type.APPLICATION_CREATED");
// t("enums.message_events.type.INVOICE_CONFIRM_BACKED");
// t("enums.message_events.type.PRODUCT_DELETED");
// t("enums.message_events.type.PRODUCT_DUPLICATED_TO");
// t("enums.message_events.type.PRODUCT_DUPLICATED_FROM");
// t("enums.message_events.type.PRODUCT_CREATED");
// t("enums.message_events.type.PRODUCT_UPDATED");
// t("enums.message_events.type.MENU_PRICE_LIST_RESTORED");
// t("enums.message_events.type.TERMINAL_NOTIFICATION_CREATED");
// t("enums.message_events.type.CATEGORY_DISABLED");
// t("enums.message_events.type.ITEM_STOCK_INFO_UPDATED");
// t("enums.message_events.type.TERMINAL_NOTIFICATION_PUBLISHED");
// t("enums.message_events.type.GOORDER_MENU_EXPORTED");
// t("enums.message_events.type.APP_LINK_SKIPPED");
// t("enums.message_events.type.USER_ADDED_TO_ORGANIZATION");
// t("enums.message_events.type.USER_REMOVED_FROM_ORGANIZATION");
// t("enums.message_events.type.APP_DELETED");
// t("enums.message_events.type.APP_RESET");
// t("enums.message_events.type.APP_REINSTALL");
// t("enums.message_events.type.APP_LINK_LINKED");
// t("enums.message_events.type.APP_LINK_UNLINKED");
// t("enums.message_events.type.TRANSACTION_DELETED");
// t("enums.message_events.type.ORDER_TRANSFER_UPDATED");
// t("enums.message_events.type.ORDER_PREPARATION_STATUS_UPDATED");
// t("enums.message_events.type.ORDER_PREPARATION_STATUS_CHANGE_UPDATED");
// t("enums.message_events.type.ORDER_COST_UPDATED");
// t("enums.message_events.type.DOCUMENT_TEMPLATE_CREATED");
// t("enums.message_events.type.DOCUMENT_TEMPLATE_UPDATED");
// t("enums.message_events.type.DOCUMENT_TEMPLATE_DELETED");
// t("enums.message_events.type.DOCUMENT_TEMPLATE_RESTORED");
// t("enums.message_events.type.REPORT_DELETED");
// t("enums.message_events.type.MENU_DISCOUNT_DELETED_PERMANENTLY");
// t("enums.message_events.type.MENU_DISCOUNT_ENABLED");
// t("enums.message_events.type.MENU_DISCOUNT_DISABLED");
// t("enums.message_events.type.MENU_PRICE_LIST_DELETED_PERMANENTLY");
// t("enums.message_events.type.MENU_PRICE_LIST_ENABLED");
// t("enums.message_events.type.MENU_PRICE_LIST_DISABLED");
// t("enums.message_events.type.TAX_DELETED_PERMANENTLY");
// t("enums.message_events.type.TAX_ENABLED");
// t("enums.message_events.type.TAX_DISABLED");
// t("enums.message_events.type.MENU_ENABLED");
// t("enums.message_events.type.MENU_DISABLED");
// t("enums.message_events.type.MENU_DELETED_PERMANETLY");
// t("enums.message_events.type.EMPLOYEE_DELETED_PERMANENTLY");
// t("enums.message_events.type.WORK_TIME_DELETED_PERMANENTLY");
// t("enums.message_events.type.WORK_TIME_PAID");
// t("enums.message_events.type.WORK_TIME_PAID_CANCELED");
// t("enums.message_events.type.WORK_TIME_REQUEST_DELETED");
// t("enums.message_events.type.CALL_RECORD_CREATED");
// t("enums.message_events.type.CLIENT_DELETED_PERMANENTLY");
// t("enums.message_events.type.PAYMENT_METHOD_DELETED_PERMANENTLY");
// t("enums.message_events.type.PAYMENT_METHOD_ENABLED");
// t("enums.message_events.type.PAYMENT_METHOD_DISABLED");
// t("enums.message_events.type.CURRENCY_RATE_DELETED_PERMANENTLY");
// t("enums.message_events.type.DIRECTION_DELETED_PERMANANTLY");
// t("enums.message_events.type.ROOM_DELETED_PERMANENTLY");
// t("enums.message_events.type.ROOM_TABLE_DELETED_PERMANENTLY");
// t("enums.message_events.type.WORKPLACE_DELETED_PERMANENTLY");
// t("enums.message_events.type.DEFAULT_COMMENT_DELETED_PERMANENTLY");
// t("enums.message_events.type.AVAILABILITY_DELETED_PERMANENTLY");
// t("enums.message_events.type.AVAILABILITY_DISABLED");
// t("enums.message_events.type.AVAILABILITY_ENABLED");
// t("enums.message_events.type.VENUE_SETTINGS_DELETED_PERMANENTLY");
// t("enums.message_events.type.PRINTOUT_TEMPLATE_DELETED_PERMANENTLY ");
// t("enums.message_events.type.ROLE_ENABLED");
// t("enums.message_events.type.ROLE_DISABLED");
// t("enums.message_events.type.ROLE_DELETED_PERMANENTLY");
// t("enums.message_events.type.ROLE_RESTOREd");
// t("enums.message_events.type.INVOICE_MAIL_SENT"), t("enums.message_events.type.INVOICE_DETAILS_DELETED_PERMANENTLY");
// t("enums.message_events.type.TERMINAL_NOTIFICIATION_UPDATED");
// t("enums.message_events.type.TERMINAL_NOTIFICATION_DELETED");
// t("enums.message_events.type.CATEGORY_DELETED_PERMANENTLY");
// t("enums.message_events.type.CATEGORY_ENABLED");
// t("enums.message_events.type.ITEM_SUSPENDED");
// t("enums.message_events.type.ITEM_RELEASED_FROM_SUSPENSION");
// t("enums.message_events.type.ITEM_DELETED_PERMANENTLY");
// t("enums.message_events.type.ITEM_ENABLED");
// t("enums.message_events.type.ITEM_GROUP_ENABLED");
// t("enums.message_events.type.MODIFIER_GROUP_DELETED_PERMANENTLY");
// t("enums.message_events.type.MODIFIER_GROUP_ENABLED");
// t("enums.message_events.type.MODIFIER_GROUP_DISABLED");
// t("enums.message_events.type.REPORT_EXTERNAL_CREATED");
// t("enums.message_events.type.REPORT_EXTERNAL_UPDATED");
// t("enums.message_events.type.REPORT_EXTERNAL_DELETED");
// t("enums.message_events.type.EMPLOYEE_DELIVERY_COORDINATES_CREATED");
// t("enums.message_events.type.SALE_DELETED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_CREATED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_UPDATED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_ENABLED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_RESTORED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_DISABLED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_DELETED");
// t("enums.message_events.type.MENU_PRICE_LIST_PREDICATE_DELETED_PERMANENTLY");
// t("enums.message_events.type.ITEM_PRICE_HISTORY_CREATED");
// t("enums.message_events.type.ITEM_PRICE_HISTORY_DELETED");
// t("enums.message_events.type.GO_REPORT_REBUILD");
// t("enums.message_events.type.MENU_DELETED_PERMANENTLY");
// t("enums.message_events.type.DIRECTION_DELETED_PERMANENTLY");
// t("enums.message_events.type.TERMINAL_NOTIFICATION_UPDATED");
