import React, { FC, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { Button, ButtonGroup, Card, Dropdown, Modal, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import EmptyData from "go-core/components/EmptyData";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import AccessTokenSessionService from "go-security/services/accessTokenSessionService";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectUser } from "go-security/services/users/selectors";
import FormatResourceStatus from "../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import Tag from "../../../../../../../../components/Common/Tag/Tag";
import { ReactComponent as ChatSVG } from "../../../../../../../../images/svg/apps/chat.svg";
import { ReactComponent as HomeSVG } from "../../../../../../../../images/svg/apps/homepage.svg";
import { ReactComponent as InstalledBySVG } from "../../../../../../../../images/svg/apps/installed.svg";
import { ReactComponent as LinkSVG } from "../../../../../../../../images/svg/apps/link.svg";
import { ReactComponent as TagSVG } from "../../../../../../../../images/svg/apps/tag.svg";
import { ReactComponent as MoreSvg } from "../../../../../../../../images/svg/more.svg";
import { ReactComponent as RemoveSVG } from "../../../../../../../../images/svg/remove-lg.svg";
import { ApplicationApi, ApplicationTemplateApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import OrganizationAppsEditIndexPage from "../../pages/Edit/pages/Index";
import OrganizationAppsEditLogsPage from "../../pages/Edit/pages/Logs";
import AppStatus from "../AppStatus";
import MewsFormModalCard from "../Mews/MewsFormModalCard";
import MewsModalForm from "../Mews/MewsModalForm";
import { renderAppIcon } from "./AppCard";
import BetasiModalForm from "./Betasi/BetasiModalForm";
import CallRecorderFormModalCard from "./CallRecorder/CallRecorderFormModalCard";
import ChartModalForm from "./Chart/ChartModalForm";
import GoClientFormModalCard from "./GoClient/GoClientFormModalCard";
import GoCrmFormModalCard from "./GoCrm/GoCrmFormModalCard";
import GoHotelIntegrationModalForm from "./GoHotelIntegration/GoHotelIntegrationModalForm";
import GoOrderFormModalCard from "./GoOrder/GoOrderFormModalCard";
import HolidayPmsFormModalCard from "./HolidayPms/HolidayPmsFormModalCard";
import HolidayPmsInstalModal from "./HolidayPms/HolidayPmsInstalModal";
import HotelgramFormModalCard from "./Hotelgram/HotelgramFormModalCard";
import HotelgramInstallModal from "./Hotelgram/HotelgramInstallModal";
import HotellionFormModalCard from "./Hotellion/HotellionFormModalCard";
import HotellionInstallModal from "./Hotellion/HotellionInstallModal";
import InforStarlightFormModalCard from "./InforStarlight/InforStarlightFormModalCard";
import InforStarlightInstallModal from "./InforStarlight/InforStarlightInstallModal";
import KWHotelModalForm from "./KWHotel/KWHotelModalForm";
import MultiLocationFormModalCard from "./MultiLocation/MultiLocationFormModalCard";
import MultiLocationInstallModal from "./MultiLocation/MultiLocationInstallModal";
import NfHotelFormModalCard from "./NfHotel/NfHotelFormModalCard";
import NfHotelInstallModal from "./NfHotel/NfHotelInstallModal";
import RenderAppErrors from "./RenderAppErrors";
import RenderAppWarnings from "./RenderAppWarnings";
import WoltDriveFormModalCard from "./WoltDrive/WoltDriveFormModalCard";
import WoltDriveInstallModal from "./WoltDrive/WoltDriveInstallModal";

interface Props {
	onHide: () => void;
	configApp: ApplicationTemplateApi;
	installedApp?: ApplicationApi;
	viewType?: string;
	handleUninstall?: (app: ApplicationApi) => void;
	handleUpdate?: (app: ApplicationApi) => void;
	handleUpdateNewInstalledApp?: (app: ApplicationApi) => void;
	routeProps?: RouteComponentProps;
}

export interface AppState {
	app: ApplicationApi;
	config?: Record<string, any>;
	showPointsOfSale?: boolean;
}

const setView = (configApp: ApplicationTemplateApi, installedApp?: ApplicationApi) => {
	if (configApp.long_description) {
		return "DESCRIPTION";
	}
	if (configApp.help_description) {
		return "START";
	}
	if (!installedApp && !configApp.long_description && !configApp.help_description) {
		return "EMPTY";
	}
	return "FORM";
};

const AppModal: FC<Props> = ({
	onHide,
	configApp,
	installedApp,
	viewType,
	handleUninstall,
	handleUpdate,
	routeProps,
	handleUpdateNewInstalledApp,
}) => {
	const { t } = useTranslation();
	const isAdmin = useSelector(selectUser)?.admin;
	const organization = useSelector(selectOrganization);
	const [viewMode, setViewMode] = useState<"EMPTY" | "DESCRIPTION" | "START" | "FORM" | "LOGS">(
		viewType ? "FORM" : setView(configApp, installedApp)
	);
	const [showModal, setShowModal] = useState(true);
	const [showLocationsModal, setShowLocationsModal] = useState(false);
	const [showGoHotelIntegrationModal, setShowGoHotelIntegrationModal] = useState(false);
	const [showWoltDriveModal, setShowWoltDriveModal] = useState(false);
	const [showHotellionModal, setShowHotellionModal] = useState<boolean>(false);
	const [showInforStarlightModal, setShowInforStarlightModal] = useState<boolean>(false);
	const [showHolidayPmsModal, setShowHolidayPmsModal] = useState<boolean>(false);
	const [showHotelgramModal, setShowHotelgramModal] = useState<boolean>(false);
	const [showKWHotelModal, setShowKWHotelModal] = useState<boolean>(false);
	const [showChartModal, setShowChartModal] = useState<boolean>(false);
	const [showBetasiModal, setShowBetasiModal] = useState<boolean>(false);
	const [showMewsModal, setShowMewsModal] = useState<boolean>(false);
	const [showNfHotelModal, setShowNfHotelModal] = useState<boolean>(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const confirmation = useConfirmation();
	const [appState, setAppState] = useState<AppState | undefined>(undefined);
	const { show } = useIntercom();

	useEffect(() => {
		fetchApplication();
	}, []);

	const fetchApplication = async (cancelToken?: CancelTokenSource) => {
		if (installedApp) {
			const params: Record<string, any> = { include: "settings" };
			const promises: Promise<any>[] = [
				api.organization().getApp(installedApp.id, params, {
					cancelToken: cancelToken?.token,
				}),
				api.organization().getAppsConfig(Number(installedApp.id), {
					cancelToken: cancelToken?.token,
				}),
			];
			if (installedApp.provider === "GOORDER") {
				promises.push(api.organization().getPointsOfSaleSearchSelect("", { size: 10, page: 0 }));
			}

			try {
				const [app, config, pointsOfSale] = await Promise.all(promises);
				setAppState({
					app,
					config,
					showPointsOfSale: pointsOfSale?.length > 0,
				});
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
	};

	const onInstall = async () => {
		const accessToken = AccessTokenSessionService.get();
		switch (configApp.provider) {
			case "LOCATIONS":
				setShowModal(false);
				setShowLocationsModal(true);
				break;
			case "GOHOTELINTEGRATION":
				setShowModal(false);
				setShowGoHotelIntegrationModal(true);
				break;
			case "WOLT_DRIVE":
				setShowModal(false);
				setShowWoltDriveModal(true);
				break;
			case "BETASI":
				setShowModal(false);
				setShowBetasiModal(true);
				break;
			case "CHART":
				setShowModal(false);
				setShowChartModal(true);
				break;
			case "KWHOTEL":
				setShowModal(false);
				setShowKWHotelModal(true);
				break;
			case "HOTELLION":
				setShowModal(false);
				setShowHotellionModal(true);
				break;
			case "INFOR_STARLIGHT":
				setShowModal(false);
				setShowInforStarlightModal(true);
				break;
			case "HOLIDAY_PMS":
				setShowModal(false);
				setShowHolidayPmsModal(true);
				break;
			case "HOTELGRAM":
				setShowModal(false);
				setShowHotelgramModal(true);
				break;
			case "CALL_RECORDER": {
				const res = await api
					.organization()
					.installApplicationPost(configApp.provider, accessToken ? accessToken : "");
				setShowModal(false);
				onHide();
				handleUpdateNewInstalledApp && handleUpdateNewInstalledApp(res);
				break;
			}
			case "MEWS":
				setShowModal(false);
				setShowMewsModal(true);
				break;
			case "NFHOTEL":
				setShowModal(false);
				setShowNfHotelModal(true);
				break;
			default:
				api.organization().installApplication(configApp.provider, accessToken ? accessToken : "");
		}
	};

	const onUninstall = async (app: ApplicationApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeApp(app);
			// setAppState({...appState, app: res.data.data})
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			setShowModal(false);
			if (handleUninstall) {
				handleUninstall(app);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const enableApplication = async (app: ApplicationApi) => {
		try {
			const params: Record<string, any> = { include: "settings" };
			const res = await api.organization().enableApp(app, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			const res2 = await api.organization().getAppsConfig(res.id);
			setAppState({ config: res2, app: res });
			if (handleUpdate) {
				handleUpdate(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const resetApplication = async (app: ApplicationApi) => {
		try {
			const params: Record<string, any> = { include: "settings" };
			const res = await api.organization().resetApp(app, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			const res2 = await api.organization().getAppsConfig(res.id);
			setAppState({ config: res2, app: res });
			if (handleUpdate) {
				handleUpdate(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const deactivateApplication = async (app: ApplicationApi) => {
		try {
			const params: Record<string, any> = { include: "settings" };
			const res = await api.organization().deactivateApp(app, params);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			const res2 = await api.organization().getAppsConfig(res.id);
			setAppState({ config: res2, app: res });
			if (handleUpdate) {
				handleUpdate(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const syncApplication = async (app: ApplicationApi) => {
		try {
			await api.organization().syncApp(app);
			// setAppState({...appState, app: res.data.data})
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const reinstallApplication = (app: ApplicationApi) => {
		const accessToken = AccessTokenSessionService.get();
		if (app.provider === "GOORDER" || app.provider === "GOCRM") {
			api.organization().reinstallApplication(app, accessToken ? accessToken : "");
		} else {
			enableApplication(app);
		}
	};

	const launchIntercom = () => {
		show();
	};

	const processUpdateApp = (app: ApplicationApi) => {
		if (handleUpdate) {
			handleUpdate(app);
			setAppState({ ...appState, app });
		}
	};

	return (
		<>
			{showLocationsModal && <MultiLocationInstallModal onHide={onHide} />}
			<HotellionInstallModal
				isShown={showHotellionModal}
				appState={{ app: {} as ApplicationApi }}
				onHide={() => setShowHotellionModal(false)}
			/>
			<InforStarlightInstallModal
				isShown={showInforStarlightModal}
				appState={{ app: {} as ApplicationApi }}
				onHide={() => setShowInforStarlightModal(false)}
			/>
			<WoltDriveInstallModal
				isShown={showWoltDriveModal}
				appState={{ app: {} as ApplicationApi }}
				onHide={() => setShowWoltDriveModal(false)}
			/>
			<HolidayPmsInstalModal
				isShown={showHolidayPmsModal}
				appState={{ app: {} as ApplicationApi }}
				onHide={() => setShowHolidayPmsModal(false)}
			/>
			<HotelgramInstallModal
				isShown={showHotelgramModal}
				appState={{ app: {} as ApplicationApi }}
				onHide={() => setShowHotelgramModal(false)}
			/>
			<NfHotelInstallModal
				isShown={showNfHotelModal}
				appState={{ app: {} as ApplicationApi }}
				onHide={() => setShowNfHotelModal(false)}
			/>
			{showGoHotelIntegrationModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowGoHotelIntegrationModal(false);
						onHide();
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.GOHOTELINTEGRATION")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<GoHotelIntegrationModalForm
							handleUpdateApp={() => setShowGoHotelIntegrationModal(false)}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showBetasiModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowBetasiModal(false);
						onHide();
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.BETASI")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<BetasiModalForm
							handleUpdateApp={() => setShowBetasiModal(false)}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showKWHotelModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowKWHotelModal(false);
						onHide();
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.KWHOTEL")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<KWHotelModalForm
							handleUpdateApp={() => setShowKWHotelModal(false)}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showChartModal && (
				<Modal
					show={true}
					onHide={() => {
						setShowChartModal(false);
						onHide();
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{t("modules.app.field.install.title")} {t("enums.apps.providers.CHART")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ChartModalForm
							handleUpdateApp={() => setShowChartModal(false)}
							appState={{ app: {} as ApplicationApi }}
						/>
					</Modal.Body>
				</Modal>
			)}
			{showMewsModal && (
				<MewsModalForm
					handleUpdateApp={processUpdateApp}
					isShown={showMewsModal}
					appState={{ app: {} as ApplicationApi }}
					onHide={() => setShowMewsModal(false)}
				/>
			)}
			<Modal className={"app-modal"} size={"lg"} show={showModal} onHide={onHide}>
				<Modal.Header className={"modal-header"}>
					<div className={"app-icon"}>{renderAppIcon(configApp)}</div>
					<div className={"app-description"}>
						<div className={"app-description-header"}>
							<h5>{t(`enums.apps.providers.${configApp.provider}`)}</h5>
							<AppStatus status={appState?.app ? appState.app?.status : installedApp?.status} />
							{appState?.app && appState?.app.status !== "ENABLED" && (
								<FormatResourceStatus
									status={appState?.app.status === "NEW" ? "INACTIVE_APP" : appState?.app.status}
								/>
							)}
							{appState?.app.link && (
								<a href={appState?.app.link} target={"_blank"} rel="noreferrer">
									{appState?.app.name}
									<LinkSVG className="ms-1" />
								</a>
							)}
							<RemoveSVG className={"close-icon ms-auto"} onClick={onHide} />
						</div>
						<div className={"app-description-info text-muted"}>{configApp.short_description}</div>
						<div className={"app-description-info-more"}>
							<HomeSVG style={{ marginTop: "-3px" }} /> {t("common.word.created_by")}{" "}
							<a target={"_blank"} rel={"noreferrer"} href={configApp.owner_link}>
								{configApp.owner}
							</a>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className={"app-modal-content flex-md-row flex-column-reverse"}>
						<div className={"col-md-6 col-12"}>
							<div className={"content"}>
								<div className={"content-navigation"}>
									<Nav>
										{installedApp ? (
											<>
												{configApp.long_description && (
													<Nav.Item onClick={() => setViewMode("DESCRIPTION")}>
														<Nav.Link
															as={NavLink}
															to={`${routeProps?.match.url}/${installedApp.id}`}
															isActive={(
																match: Record<string, any> | null,
																location: Record<string, any>
															) =>
																viewMode === "DESCRIPTION" &&
																!location.pathname.includes("/logs")
															}
														>
															{t("modules.app.field.app_description.title")}
														</Nav.Link>
													</Nav.Item>
												)}
												{configApp.help_description && (
													<Nav.Item onClick={() => setViewMode("START")}>
														<Nav.Link
															as={NavLink}
															to={`${routeProps?.match.url}/${installedApp.id}`}
															isActive={(
																match: Record<string, any> | null,
																location: Record<string, any>
															) =>
																viewMode === "START" &&
																!location.pathname.includes("/logs")
															}
														>
															{t("modules.app.field.start.title")}
														</Nav.Link>
													</Nav.Item>
												)}
											</>
										) : (
											<>
												{configApp.long_description && (
													<Nav.Item onClick={() => setViewMode("DESCRIPTION")}>
														<Nav.Link href={"#"} active={viewMode === "DESCRIPTION"}>
															{t("modules.app.field.app_description.title")}
														</Nav.Link>
													</Nav.Item>
												)}
												{configApp.help_description && (
													<Nav.Item onClick={() => setViewMode("START")}>
														<Nav.Link href={"#"} active={viewMode === "START"}>
															{t("modules.app.field.start.title")}
														</Nav.Link>
													</Nav.Item>
												)}
											</>
										)}
										{installedApp && (
											<Nav.Item onClick={() => setViewMode("FORM")}>
												<Nav.Link
													as={NavLink}
													isActive={(
														match: Record<string, any> | null,
														location: Record<string, any>
													) => viewMode === "FORM" && !location.pathname.includes("/logs")}
													to={`${routeProps?.match.url}/${installedApp.id}`}
												>
													{t("modules.app.field.settings.title")}
												</Nav.Link>
											</Nav.Item>
										)}
									</Nav>
									{installedApp ? (
										<Switch>
											<Route
												path={`${routeProps?.match.url}/${installedApp?.id}/logs`}
												render={() => <OrganizationAppsEditLogsPage appId={installedApp.id} />}
											/>
											<Route
												exact={true}
												path={`${routeProps?.match.url}/${installedApp?.id}`}
												render={() => (
													<OrganizationAppsEditIndexPage>
														{appState?.app?.errors &&
															appState?.app?.errors.filter((f) => f.code).length > 0 && (
																<RenderAppErrors errors={appState.app.errors} />
															)}
														{appState?.app?.dangers &&
															appState?.app?.dangers.filter((f) => f.code).length > 0 && (
																<RenderAppWarnings dangers={appState.app.dangers} />
															)}
														{viewMode === "DESCRIPTION" && (
															<Card>
																<Card.Body>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: configApp.long_description,
																		}}
																	/>
																</Card.Body>
															</Card>
														)}
														{viewMode === "START" && (
															<Card>
																<Card.Body>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: configApp.help_description,
																		}}
																	/>
																</Card.Body>
															</Card>
														)}
														{viewMode === "FORM" && (
															<>
																{!appState ? (
																	<LoadingContainer />
																) : (
																	<>
																		{configApp.provider ===
																			"GOHOTELINTEGRATION" && (
																			<Card>
																				<Card.Body>
																					<GoHotelIntegrationModalForm
																						handleUpdateApp={
																							processUpdateApp
																						}
																						appState={appState}
																					/>
																				</Card.Body>
																			</Card>
																		)}
																		{configApp.provider === "CHART" && (
																			<Card>
																				<Card.Body>
																					<ChartModalForm
																						handleUpdateApp={
																							processUpdateApp
																						}
																						appState={appState}
																					/>
																				</Card.Body>
																			</Card>
																		)}
																		{configApp.provider === "KWHOTEL" && (
																			<Card>
																				<Card.Body>
																					<KWHotelModalForm
																						handleUpdateApp={
																							processUpdateApp
																						}
																						appState={appState}
																					/>
																				</Card.Body>
																			</Card>
																		)}
																		{configApp.provider === "BETASI" && (
																			<Card>
																				<Card.Body>
																					<BetasiModalForm
																						handleUpdateApp={
																							processUpdateApp
																						}
																						appState={appState}
																					/>
																				</Card.Body>
																			</Card>
																		)}
																		{configApp.provider === "GOORDER" && (
																			<GoOrderFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																				fetchApplication={fetchApplication}
																			/>
																		)}
																		{configApp.provider === "LOCATIONS" && (
																			<MultiLocationFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "GOCRM" && (
																			<GoCrmFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "CALL_RECORDER" && (
																			<CallRecorderFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "MEWS" && (
																			<MewsFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "HOTELLION" && (
																			<HotellionFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "INFOR_STARLIGHT" && (
																			<InforStarlightFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "WOLT_DRIVE" && (
																			<WoltDriveFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "GOCLIENT" && (
																			<GoClientFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "HOLIDAY_PMS" && (
																			<HolidayPmsFormModalCard
																				appState={appState}
																				fetchApplication={fetchApplication}
																			/>
																		)}
																		{configApp.provider === "HOTELGRAM" && (
																			<HotelgramFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																		{configApp.provider === "NFHOTEL" && (
																			<NfHotelFormModalCard
																				handleUpdateApp={processUpdateApp}
																				appState={appState}
																			/>
																		)}
																	</>
																)}
															</>
														)}
													</OrganizationAppsEditIndexPage>
												)}
											/>
										</Switch>
									) : (
										<>
											{viewMode === "DESCRIPTION" && (
												<Card>
													<Card.Body>
														<div
															dangerouslySetInnerHTML={{
																__html: configApp.long_description,
															}}
														/>
													</Card.Body>
												</Card>
											)}
											{viewMode === "START" && (
												<Card>
													<Card.Body>
														<div
															dangerouslySetInnerHTML={{
																__html: configApp.help_description,
															}}
														/>
													</Card.Body>
												</Card>
											)}
											{viewMode === "EMPTY" && (
												<EmptyData
													title={t("modules.app.field.empty.title")}
													styles={{ background: "#e6e6e6" }}
												/>
											)}
										</>
									)}
								</div>
							</div>
						</div>
						<div className={"col-md-6 col-12"}>
							<Card className={"mb-3"}>
								<Card.Body>
									{!installedApp && (
										<Button variant={"primary btn-block mb-3"} onClick={() => onInstall()}>
											{t("common.action.install", { ns: "lib" })}
										</Button>
									)}
									{installedApp && appState?.app && (
										<div className={"d-flex mb-3"}>
											{appState?.app.status !== "ENABLED" && appState?.app.status !== "ERROR" && (
												<Button
													type={"button"}
													variant={"primary"}
													style={{ flex: 1, marginRight: "8px" }}
													onClick={() => enableApplication(installedApp)}
												>
													{t("common.action.activate", { ns: "lib" })}
												</Button>
											)}
											{appState?.app.status === "ENABLED" && (
												<Button
													variant={"light"}
													type={"button"}
													onClick={() => onUninstall(installedApp)}
													style={{
														color: "#DC3545",
														flex: 1,
														marginRight: "8px",
													}}
												>
													{t("common.word.uninstall")}
												</Button>
											)}
											{appState?.app.status === "ERROR" &&
												appState?.app.provider === "GOORDER" && (
													<Button
														variant={"light"}
														type={"button"}
														onClick={() => reinstallApplication(installedApp)}
														style={{
															color: "#DC3545",
															flex: 1,
															marginRight: "8px",
														}}
													>
														{t("common.action.fix_error", { ns: "lib" })}
													</Button>
												)}
											{appState?.app.status === "ERROR" &&
												appState?.app.provider === "LOCATIONS" && (
													<Button
														variant={"light"}
														type={"button"}
														onClick={() => enableApplication(installedApp)}
														style={{
															color: "#DC3545",
															flex: 1,
															marginRight: "8px",
														}}
													>
														{t("common.action.fix_error", { ns: "lib" })}
													</Button>
												)}
											<Dropdown as={ButtonGroup}>
												<Dropdown.Toggle as={MoreSvg} />
												<Dropdown.Menu>
													{appState?.app.status !== "DELETED" &&
														appState?.app.status !== "ERROR" && (
															<Dropdown.Item
																href="#"
																onClick={() => syncApplication(installedApp)}
															>
																{t("common.action.sync", { ns: "lib" })}
															</Dropdown.Item>
														)}
													{appState?.app.status !== "DELETED" &&
														(isAdmin || organization.support) && (
															<Dropdown.Item
																href="#"
																onClick={() => resetApplication(installedApp)}
															>
																{t("common.action.reset", { ns: "lib" })}
															</Dropdown.Item>
														)}
													{
														<Dropdown.Item
															href="#"
															onClick={() => reinstallApplication(installedApp)}
														>
															{t("common.action.reinstall_channel")}
														</Dropdown.Item>
													}
													{appState?.app.status === "ENABLED" && (
														<Dropdown.Item
															href="#"
															onClick={() => deactivateApplication(installedApp)}
														>
															{t("common.action.disable", { ns: "lib" })}
														</Dropdown.Item>
													)}
													{appState?.app && (
														<Dropdown.Item
															as={Link}
															to={`${routeProps?.match.url}/${installedApp.id}/logs`}
														>
															{t("lib:common.word.logs")}
														</Dropdown.Item>
													)}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									)}
									<div className="card-body-item">
										<InstalledBySVG />
										<div className="item-info">
											<span className="label">{t("modules.app.field.installed_by.title")}</span>
											<span>
												<div className={"action-ref"}>{configApp.owner}</div>
											</span>
										</div>
									</div>
									{configApp.tags && configApp.tags.length > 0 && (
										<div className="card-body-item">
											<TagSVG />
											<div className="item-info">
												<span className="label mb-1">
													{t("modules.app.field.categories.title")}
												</span>
												<div className={"app-tags"}>
													{configApp.tags?.map((tag, index) => {
														return <Tag key={index}>{tag}</Tag>;
													})}
												</div>
											</div>
										</div>
									)}
									{installedApp && configApp.documentation_link && (
										<a
											href={configApp.documentation_link}
											target={"_blank"}
											rel={"noreferrer"}
											className={"btn btn-add btn-block"}
										>
											{t("modules.app.field.docs.title")}
										</a>
									)}
								</Card.Body>
							</Card>
							<Card className={"app-chat"}>
								<Card.Body>
									<div className="card-body-item">
										<ChatSVG />
										<div className="item-info">
											<span className="label">{t("modules.app.field.need_help.title")}</span>
										</div>
									</div>
									<Button type={"button"} variant={"add btn-block"} onClick={() => launchIntercom()}>
										{t("modules.app.action.launch_chat.title")}
									</Button>
								</Card.Body>
							</Card>
							<div className={"d-flex flex-column"}>
								{configApp.rules_link && (
									<a target={"_blank"} rel={"noreferrer"} href={configApp.rules_link}>
										{t("modules.app.field.rules.title")}
									</a>
								)}
								{configApp.privacy_policy_link && (
									<a target={"_blank"} rel={"noreferrer"} href={configApp.privacy_policy_link}>
										{t("modules.app.field.privacy_policy.title")}
									</a>
								)}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default AppModal;
