import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrdersContext } from "../services/context";

export const LiveOrdersRefreshPageBar = (): JSX.Element | null => {
	const { t } = useTranslation();
	const { isOnline } = useContext(OrdersContext);

	if (isOnline) {
		return null;
	}

	return (
		<div className="live-orders-refresh-page-bar">
			<span>{t("modules.live_order.field.refresh_page.title")}</span>
		</div>
	);
};
