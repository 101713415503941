import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { useWindowSize } from "go-core/components/useWindowSize";
import ReportsNavigation from "./components/ReportsNavigation";
import UserReportsBillsPage from "./pages/Bills";
import UserReportsCategoriesPage from "./pages/Categories";
import UserReportsDiscountsPage from "./pages/Discounts";
import { UserReportsEmployeeEfficiencyPage } from "./pages/Employees";
import UserReportsGuestsPage from "./pages/Guests";
import { UserReportsInvoicesPage } from "./pages/Invoices";
import UserReportsPaymentsPage from "./pages/Payments";
import UserReportsProductsPage from "./pages/Products";
import { UserReportsTransactionPage } from "./pages/Transactions";

export const UserReportsPage = (props: RouteComponentProps): JSX.Element => {
	const isMobile = useWindowSize().isMobile;
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(`${getHeaderTitle()} | ${t("components.navigation.reports")}`);
	}, [pathname]);

	const getHeaderTitle = () => {
		const pathParts = pathname.split("/");
		const lastPathPart = pathParts[pathParts.length - 1];

		switch (lastPathPart) {
			case "orders":
				return t("modules.report_bill.header.title");
			case "categories":
				return t("modules.category_report.header.title");
			case "payments":
				return t("modules.payment_method_report.header.title");
			case "products":
				return t("modules.item_group_report.header.title");
			case "guests":
				return t("modules.guest_group_report.header.title");
			case "invoices":
				return t("modules.invoice.header.title");
			case "transactions":
				return t("modules.transaction.header.title");
			default:
				return "";
		}
	};

	return (
		<div className={"page-container"}>
			<ReportsNavigation />
			<div className="content">
				{!isMobile && <Header title={getHeaderTitle()} />}
				<Switch>
					<Route path={`${props.match.url}/orders`} component={UserReportsBillsPage} />
					<Route path={`${props.match.url}/categories`} component={UserReportsCategoriesPage} />
					<Route path={`${props.match.url}/payments`} component={UserReportsPaymentsPage} />
					<Route path={`${props.match.url}/order_promotions`} component={UserReportsDiscountsPage} />
					<Route path={`${props.match.url}/products`} component={UserReportsProductsPage} />
					<Route path={`${props.match.url}/guests`} component={UserReportsGuestsPage} />
					<Route path={`${props.match.url}/invoices`} component={UserReportsInvoicesPage} />
					<Route path={`${props.match.url}/transactions`} component={UserReportsTransactionPage} />
					<Route path={`${props.match.url}/efficiencies`} component={UserReportsEmployeeEfficiencyPage} />
					<Redirect from={props.match.url} exact to={`${props.match.url}/orders`} />
				</Switch>
			</div>
		</div>
	);
};
