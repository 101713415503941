import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OrderFiscalizationOrderReceiptApi } from "../../../../../../../../../../../services/Api/Organization/types";
import FiscalizationOrderReceiptList from "./FiscalizationOrderReceiptList";

interface Props {
	isShown: boolean;
	onHide: () => void;
	orderReceipts: OrderFiscalizationOrderReceiptApi[];
	orderId: number;
}

const FiscalizationOrderReceiptsModal = ({ orderReceipts, isShown, onHide, orderId }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Modal show={isShown} onHide={onHide} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.order.field.fiscalization_list.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FiscalizationOrderReceiptList orderReceipts={orderReceipts} orderId={orderId} />
			</Modal.Body>
		</Modal>
	);
};

export default FiscalizationOrderReceiptsModal;
