import { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ListData } from "go-list/list";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { ReactComponent as InfoCircleSVG } from "../../../go-component/images/svg/info-circle.svg";
import { AdminJobApi } from "../../services/types";
import FormatJobStatus from "../Job/components/FormatJobStatus";

interface ListState {
	resource?: Record<string, any>;
}

interface Props {
	fetchRequest: (params?: Record<string, any>, options?: Record<string, any>) => Promise<AdminJobApi[]>;
}

const listName = "JOB";
const resourceType = "JOB";

const List = ({ resource, fetchRequest }: ListState & Props) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<AdminJobApi[]>([]);
	const segmentContextValue = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "name",
				name: t("lib:common.word.name"),
			},
			{
				id: "organization_id",
				name: t("common.word.location", { ns: "lib" }),
				render: (item: AdminJobApi) => (
					<Link to={`/${item.organization_id}/logs/jobs`}>{item.organization_id}</Link>
				),
			},
			{
				id: "status",
				name: t("lib:common.word.status"),
				render: (item: AdminJobApi) => {
					if (item.status === "ERROR") {
						return (
							<>
								<FormatJobStatus status={item.status} />
								<OverlayTrigger
									placement="top"
									overlay={<Tooltip id={`tooltip-${item?.id}`}>{item?.error}</Tooltip>}
								>
									<InfoCircleSVG className="ms-1" />
								</OverlayTrigger>
							</>
						);
					}
					return <FormatJobStatus status={item.status} />;
				},
			},
			{
				id: "created_at",
				name: t("lib:common.word.created_at"),
				render: (item: AdminJobApi) => FormatDate(item.created_at),
			},
			{
				id: "updated_at",
				name: t("go_component.admin_job.field.updated_at.title", { ns: "lib" }),
				render: (item: AdminJobApi) => FormatDate(item.updated_at),
				styleOverride: {
					textAlign: "right",
				},
			},
		],
		selectedColumns: ["name", "organization_id", "status", "created_at", "updated_at"],
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			return fetchRequest(fetchParams, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContextValue.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return (
		<ListData
			data={items}
			config={config}
			emptyList={<EmptyList title={t("lib:go_core.empty_data.title")} />}
			onFetch={(fetchItems: AdminJobApi[]) => setItems(fetchItems)}
			canManageMultiActionsForAllItems
		/>
	);
};

export const AdminJobs = ({ fetchRequest }: Props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContextValue = useContext(SegmentContext);
	const { isMobile } = useWindowSize();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("go_component.job.header.title", { ns: "lib" }));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<>
			{!isMobile && <Header title={t("go_component.job.header.title", { ns: "lib" })} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} fetchRequest={fetchRequest} />
			</Suspense>
		</>
	);
};
