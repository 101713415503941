import { useEffect, useRef } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { ReactComponent as RemoveSVG } from "go-core/images/svg/actions/remove.svg";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { TimePicker } from "go-form/components/TimePicker";
import { AppLinkApi, ValidityType } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	form: UseFormReturn<AppLinkApi>;
	receivedItems: boolean;
}

const PRODUCT_EMPTY_VALUES = {
	item: null,
	item_id: 0,
	item_name: "",
	provide_code_manually: false,
	validity_type: ValidityType.DEFAULT,
	valid_to_time: null,
	number_of_days: 0,
	valid_from: null,
	valid_to: null,
};

export const GoZoneProductTable = ({ form, receivedItems }: Props) => {
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
		control,
		watch,
		setValue,
		getValues,
	} = form;
	const { fields, append, remove } = useFieldArray({ name: "items", control });
	const displayTable = receivedItems || fields.length > 0;
	const itemValueBeforeRemoval = useRef<boolean>(false);

	const selectedValidityType = watch(`items.${fields.length}.validity_type`);
	const selectedProduct = watch(`items.${fields.length}.item`);
	const validToTime = watch(`items.${fields.length}.valid_to_time`);
	const numberOfDays = watch(`items.${fields.length}.number_of_days`);
	const validRangeFrom = watch(`items.${fields.length}.valid_from`);
	const validRangeTo = watch(`items.${fields.length}.valid_to`);

	const displayValidToTimeInput = selectedValidityType === "TODAY" || selectedValidityType === "NUMBER_OF_DAYS";
	const displayNumberOfDaysInput = selectedValidityType === "NUMBER_OF_DAYS";
	const displayValidFromAndValidToInputs = selectedValidityType === "PERIOD";

	const displayAnyAdditionalInput =
		displayValidToTimeInput || displayNumberOfDaysInput || displayValidFromAndValidToInputs;
	const productInputIsEmpty = selectedProduct === null || selectedProduct === undefined;
	const validationForTodayOption = selectedValidityType === "TODAY" && !validToTime;
	const validationForNumberOfDaysOption = selectedValidityType === "NUMBER_OF_DAYS" && !numberOfDays;
	const validationFormPeriodOption = selectedValidityType === "PERIOD" && !(validRangeFrom || validRangeTo);

	const disableButton =
		productInputIsEmpty ||
		validationForTodayOption ||
		validationForNumberOfDaysOption ||
		validationFormPeriodOption;

	const getAvailability = (
		validityType: ValidityType,
		valid_to_time?: string | null,
		number_of_days?: number,
		valid_from?: string | null,
		valid_to?: string | null
	) => {
		switch (validityType) {
			case ValidityType.TODAY: {
				if (valid_to_time) return t("enums.apps.availability_type.TODAY", { valid_to_time });
				return "";
			}
			case ValidityType.NUMBER_OF_DAYS: {
				return t("enums.apps.availability_type.NUMBER_OF_DAYS", {
					number_of_days,
					valid_to_time: valid_to_time ?? "00:00:00",
				});
				return "";
			}
			case ValidityType.PERIOD: {
				if (valid_from && valid_to)
					return t("enums.apps.availability_type.PERIOD", {
						valid_from: FormatDate(valid_from, undefined, undefined, { hours: "none" }),
						valid_to: FormatDate(valid_to, undefined, undefined, { hours: "none" }),
					});
				else if (valid_from && !valid_to)
					return t("enums.apps.availability_type.PERIOD_FROM", {
						valid_from: FormatDate(valid_from, undefined, undefined, { hours: "none" }),
					});
				else if (!valid_from && valid_to)
					return t("enums.apps.availability_type.PERIOD_TO", {
						valid_to: FormatDate(valid_to, undefined, undefined, { hours: "none" }),
					});
				return "";
			}
			case ValidityType.DEFAULT:
			default:
				return "";
		}
	};

	const getItemNamesSearchSelect = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getItemsSearchSelect(search, params, { cancelToken: options?.token });
	};

	const validityTypeOptions = [
		{
			id: "DEFAULT",
			label: t("enums.apps.validity_type.DEFAULT"),
		},
		{
			id: "TODAY",
			label: t("enums.apps.validity_type.TODAY"),
		},
		{
			id: "NUMBER_OF_DAYS",
			label: t("enums.apps.validity_type.NUMBER_OF_DAYS"),
		},
		{
			id: "PERIOD",
			label: t("enums.apps.validity_type.PERIOD"),
		},
	];

	const removeLastAutomaticallyAddedFormItem = () => {
		const formItemsValues = getValues("items");
		const lastItemRemovalIsNecessary = fields.length !== formItemsValues?.length;

		if (fields.length > (formItemsValues?.length ?? 0)) {
			setValue("items", fields);
			return;
		}

		if (lastItemRemovalIsNecessary) {
			setValue("items", formItemsValues?.slice(0, formItemsValues.length - 1));
		}
	};

	const appendNewFormRow = () => {
		const currentFormValue = getValues(`items.${fields.length}`);
		const { item, validity_type, provide_code_manually, valid_to_time, number_of_days, valid_from, valid_to } =
			currentFormValue;

		const newFormRowValue = {
			item_name: item?.item_name,
			item_id: Number(item?.item_id),
			validity_type: validity_type ?? ValidityType.DEFAULT,
			provide_code_manually,
			valid_to_time: valid_to_time ?? null,
			number_of_days: number_of_days ?? 0,
			valid_from,
			valid_to,
		};

		removeLastAutomaticallyAddedFormItem();
		append(newFormRowValue);
	};

	useEffect(() => {
		const formValues = getValues("items");
		const lastItemValue = formValues?.slice(-1);
		const resetFormIsNecessary = lastItemValue && lastItemValue[0] && typeof lastItemValue[0].item === "object";

		if (resetFormIsNecessary) {
			setValue(`items.${fields.length}`, { ...PRODUCT_EMPTY_VALUES });
		}
		if (itemValueBeforeRemoval.current) {
			setValue(`items.${fields.length}`, { ...PRODUCT_EMPTY_VALUES });
			itemValueBeforeRemoval.current = false;
		}
	}, [fields.length, getValues, setValue]);

	return (
		<Card>
			{displayTable && (
				<Card.Body className="overflow-x-auto">
					<table className="table">
						<thead>
							<tr>
								<th>{t("common.word.item_group")}</th>
								<th>{t("modules.app_link.field.provide_code_manually.title")}</th>
								<th>{t("modules.app_link.field.validity_type.title")}</th>
								<th>{t("common.word.availability")}</th>
							</tr>
						</thead>
						<tbody>
							{fields.map((field, index) => {
								const {
									id,
									item_name,
									provide_code_manually,
									validity_type,
									valid_to_time,
									number_of_days,
									valid_from,
									valid_to,
								} = field;

								const itemAlreadyLinkedError =
									errors?.items &&
									errors.items[index]?.item_id?.types?.one_to_one_link_required ===
										"Item already linked";

								return (
									<tr key={id}>
										<td>
											<span>{item_name}</span>
											{itemAlreadyLinkedError && (
												<span className="text-danger">
													{` (${t("modules.app_link.field.product_already_linked.title")})`}
												</span>
											)}
										</td>
										<td>
											{provide_code_manually === true
												? t("common.word.yes", { ns: "lib" })
												: t("common.word.no", { ns: "lib" })}
										</td>
										<td>{t(`enums.apps.validity_type.${validity_type}`)}</td>
										<td>
											{getAvailability(
												validity_type,
												valid_to_time,
												number_of_days,
												valid_from,
												valid_to
											)}
										</td>
										<td className="action">
											<RemoveSVG
												onClick={() => {
													itemValueBeforeRemoval.current = true;
													removeLastAutomaticallyAddedFormItem();
													remove(index);
												}}
												className="icon"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</Card.Body>
			)}
			<Card.Footer>
				<div className="container mt-3">
					<h5>{t("modules.item_group.header.add_new_product.title")}</h5>
					<div className="row align-items-end">
						<div className="col-md-4">
							<FormSelectGroup
								control={control}
								errors={errors}
								name={`items.${fields.length}.item`}
								loadOptions={getItemNamesSearchSelect}
								getOptionValue={(option) => ({
									item_id: option.id,
									item_name: option.label,
								})}
								getOptionLabel={(option) => option.label}
								label={t("common.word.item_group")}
							/>
						</div>
						<div className="col-md-4">
							<FormCheck
								type="switch"
								register={register}
								errors={errors}
								name={`items.${fields.length}.provide_code_manually`}
								label={t("modules.app_link.field.provide_code_manually.title")}
							/>
						</div>
						<div className="col-md-4">
							<FormSelectGroup
								control={control}
								errors={errors}
								name={`items.${fields.length}.validity_type`}
								options={validityTypeOptions}
								label={t("modules.app_link.field.validity_type.title")}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								defaultValue={validityTypeOptions[0]}
							/>
						</div>
					</div>
					{displayAnyAdditionalInput && (
						<div className="row">
							{displayValidToTimeInput && (
								<div className="col-md-4">
									<Form.Label style={{ fontSize: "13px", marginBottom: "1.6px" }}>
										{t("modules.app_link.field.valid_to_time.title")}
									</Form.Label>
									<TimePicker
										time="00:00:00"
										onChange={(time: string) => {
											setValue(`items.${fields.length}.valid_to_time`, `${time}:00`);
										}}
									/>
								</div>
							)}
							{displayNumberOfDaysInput && (
								<div className="col-md-4">
									<FormNumberInput
										name={`items.${fields.length}.number_of_days`}
										control={control}
										errors={errors}
										label={t("modules.app_link.field.number_of_days.title")}
										suffix=""
									/>
								</div>
							)}
							{displayValidFromAndValidToInputs && (
								<>
									<div className="col-md-4">
										<FormDatePicker
											control={control}
											errors={errors}
											name={`items.${fields.length}.valid_from`}
											label={t("modules.app_link.field.valid_from.title")}
											dayPicker
											timePicker={false}
										/>
									</div>
									<div className="col-md-4">
										<FormDatePicker
											control={control}
											errors={errors}
											name={`items.${fields.length}.valid_to`}
											label={t("modules.app_link.field.valid_to.title")}
											dayPicker
											timePicker={false}
										/>
									</div>
								</>
							)}
						</div>
					)}
				</div>
				<Button disabled={disableButton} variant="add" onClick={appendNewFormRow}>{`+ ${t("common.action.add", {
					ns: "lib",
				})}`}</Button>
			</Card.Footer>
		</Card>
	);
};

// t("enums.apps.validity_type.DEFAULT")
// t("enums.apps.validity_type.TODAY")
// t("enums.apps.validity_type.NUMBER_OF_DAYS")
// t("enums.apps.validity_type.PERIOD")
