import React, { useContext, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { apiOrganization } from "../../../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	LiveOrderApi,
	LiveOrderRejectionFormApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import { OrdersContext } from "../../../../../../services/context";
import { liveOrderParamsInclude } from "../../../../../../services/orderSynchronizer";
import LiveOrderRejectionModalOptions from "./LiveOrderRejectionModalOptions";

interface Props {
	show: boolean;
	onHide: () => void;
	order: LiveOrderApi;
	orderListRoutePath: string;
}

const LiveOrderRejectionModal = ({ show, onHide, order, orderListRoutePath }: Props) => {
	const ordersContext = useContext(OrdersContext);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const [selectedOption, setSelectedOption] = useState<string | undefined>();
	const [showReasonInput, setShowReasonInput] = useState<boolean>(false);
	const form = useForm<LiveOrderRejectionFormApi>({
		criteriaMode: "all",
	});
	const history = useHistory();
	const {
		handleSubmit,
		formState,
		formState: { errors },
		register,
		setError,
		watch,
	} = form;
	const [hasReasonOptionError, setHasReasonOptionError] = useState(false);
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	const getParsedReasonToDataForm = (data: LiveOrderRejectionFormApi) => {
		if (selectedOption && !showReasonInput) {
			return selectedOption;
		}

		return data.reason;
	};

	const handleValidateReasonOption = () => {
		let shouldValidate = false;
		if (!selectedOption) {
			setHasReasonOptionError(true);
			shouldValidate = true;
		} else if (hasReasonOptionError && selectedOption) {
			setHasReasonOptionError(false);
		}

		return shouldValidate;
	};

	const onSubmit = handleSubmit(async (data) => {
		if (handleValidateReasonOption()) return;
		if (!validateCustomErrors()) return;

		setLoading(true);
		try {
			data.reason = getParsedReasonToDataForm(data);
			const res = await apiOrganization.rejectLiveOrder(order.id, data, { include: liveOrderParamsInclude });
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
			history.push(orderListRoutePath);
			ordersContext.updateOrder(res);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const reasonValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED_IF, isRequired: () => showReasonInput }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch, showReasonInput]
	) satisfies CustomValidationConfig;

	return (
		<Modal onHide={onHide} show={show} className="live-order-rejection-modal">
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.live_order.field.order_rejection.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<LiveOrderRejectionModalOptions
						setSelectedOption={setSelectedOption}
						selectedOption={selectedOption}
						setShowReasonInput={setShowReasonInput}
						form={form}
						hasReasonOptionError={hasReasonOptionError}
					/>
					{showReasonInput && (
						<>
							<hr />
							<FormInput
								name="reason"
								errors={errors}
								register={register}
								label={t("modules.live_order.field.other_reason.title")}
								as="textarea"
								placeholder={t("modules.live_order.field.other_reason.placeholder")}
								style={{ minHeight: "78px" }}
								customValidationConfig={reasonValidationConfig}
							/>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("modules.live_order.action.execute.title")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default LiveOrderRejectionModal;
