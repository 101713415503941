import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ClientGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ClientGroupForm from "../../components/ClientGroupForm";

interface MatchParams {
	clientGroupId: string;
}

const OrganizationClientClientGroupGroupsEditPage = (): JSX.Element => {
	const [clientGroup, setClientGroup] = useState<ClientGroupApi>();
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const { clientGroupId } = useParams<MatchParams>();
	const { addFlash, addSuccessFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchClientGroup();
	}, []);

	useEffect(() => {
		handleChangeTabTitle(`${clientGroup?.name || ""} | ${t("modules.client_groups.header.title")}`);
	}, [clientGroup?.name]);

	const onFetchClientGroup = async () => {
		try {
			const params: Record<string, any> = {};
			params.include = "discounts";
			const res = await api.organization().getClientGroup(Number(clientGroupId), params);
			setClientGroup(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restoreClientGroup = async (clientGroup: ClientGroupApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().restoreClientGroup(clientGroup.id);
			addSuccessFlash("flash.activated");
			setClientGroup(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removeClientGroup = async (clientGroup: ClientGroupApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeClientGroup(clientGroup.id);
			history.push(`/${organization.id}/clients/client-groups`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!clientGroup) {
		return <LoadingContainer />;
	}

	const renderTitle = () => (
		<>
			{t("modules.client_groups.header.title")} "{clientGroup.name}"
			<FormatResourceStatus status={clientGroup.status} />
		</>
	);

	const buttons: ButtonProps[] = [];
	if (clientGroup.status !== "DELETED")
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeClientGroup(clientGroup),
			dropdown: true,
		});
	if (clientGroup.status === "DELETED")
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreClientGroup(clientGroup),
			dropdown: true,
		});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeClientGroup(clientGroup),
			hide: clientGroup.status === "DELETED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreClientGroup(clientGroup),
			hide: clientGroup.status !== "DELETED",
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<ClientGroupForm clientGroup={clientGroup} setClientGroup={setClientGroup} />
		</>
	);
};
export default OrganizationClientClientGroupGroupsEditPage;
