import React, { FC, useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApiError } from "go-core/api/types";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	ClientApi,
	InvoiceApi,
	InvoiceReceiverApi,
	InvoiceRecipientApi,
	OrderApi,
} from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import AddClientModal from "./AddClientModal";

interface Props {
	form: UseFormReturn<InvoiceApi>;
	invoice: InvoiceApi;
	shouldGenerateNewClientByTaxId?: boolean;
	setShouldGenerateNewClientByTaxId?: React.Dispatch<React.SetStateAction<boolean>>;
	order?: OrderApi;
	formErrors: ApiError[];
}

const InvoiceFormClientsInfo: FC<Props> = ({
	form,
	invoice,
	order,
	shouldGenerateNewClientByTaxId,
	setShouldGenerateNewClientByTaxId,
	formErrors,
}) => {
	const [recipient, setRecipient] = useState<InvoiceRecipientApi | any>({
		...invoice.recipient,
		id: invoice.recipient_id,
	});
	const [receiver, setReceiver] = useState<InvoiceReceiverApi | any>({
		...invoice.receiver,
		id: invoice.receiver_id,
	});
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [addClientModal, setAddClientModal] = useState<"receiver" | "recipient" | undefined>(undefined);
	const {
		formState: { errors },
		setValue,
		control,
		clearErrors,
	} = form;

	useEffect(() => {
		autoCompleteClient();
	}, []);

	const autoCompleteClient = async () => {
		if (order && order?.contact?.tax_id_no) {
			const clients = await searchClients(order.contact.tax_id_no, {});

			if (clients.length > 0) {
				setRecipient({
					id: Number(clients[0].id),
					name: clients[0].label,
				});
				setValue("recipient_id", Number(clients[0].id));
			} else {
				setRecipient({
					id: order.contact.tax_id_no.toString(),
					name: order.contact.tax_id_no.toString(),
				});
				setValue("recipient_id", order.contact.tax_id_no.toString());

				setShouldGenerateNewClientByTaxId && setShouldGenerateNewClientByTaxId(true);
			}
		}
	};

	const onCreateRecipient = (name: string) => {
		setName(name);
		setAddClientModal("recipient");
	};

	const onCreateReceiver = (name: string) => {
		setName(name);
		setAddClientModal("receiver");
	};

	const onCreateClient = (data: ClientApi) => {
		if (addClientModal === "receiver") {
			setValue("receiver_id", data.id, { shouldDirty: true });
			clearErrors("receiver_id");
			setReceiver({ id: data.id, name: data.name });
		}
		if (addClientModal === "recipient") {
			setValue("recipient_id", data.id, { shouldDirty: true });
			clearErrors("recipient_id");
			setRecipient({ id: data.id, name: data.name });
		}
		setAddClientModal(undefined);
	};

	const searchClients = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const loadOptions = async (inputValue: string) => {
		const clients = await searchClients(inputValue, {});

		if (!shouldGenerateNewClientByTaxId) {
			return clients;
		}

		return [
			...clients,
			{
				id: order?.contact?.tax_id_no?.toString(),
				label: order?.contact?.tax_id_no?.toString(),
			},
		];
	};

	const getNotFullRecipientAddressError = () => {
		return formErrors.find((error) => error?.field === "recipient_id" && error?.code === "client_address_not_full");
	};

	return (
		<fieldset className={"form-group"}>
			<h5>{t("modules.invoice.field.receiver_recipient.title")}</h5>
			<div className={"row"}>
				<div className={"col-md-10"}>
					<div className={"row"}>
						<div className="col-md-6">
							<InputGroup className={"form-input-group"}>
								<FormSelectGroup
									path={`/${organization.id}/clients/`}
									label={t("modules.invoice.field.recipient.title")}
									name="recipient_id"
									errors={errors}
									onCreateOption={onCreateRecipient}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.id}
									isClearable={false}
									defaultValue={{
										label: recipient?.name,
										id: recipient?.id,
									}}
									loadOptions={loadOptions}
									control={control}
									data-testid="recipient_id"
								/>
								<div className="input-group-append">
									<Button
										style={{ height: "38px", border: "1px solid #ced4da" }}
										onClick={() => setAddClientModal("recipient")}
										variant={"light"}
									>
										{t("common.action.create", { ns: "lib" })}
									</Button>
								</div>
							</InputGroup>
							{getNotFullRecipientAddressError() && (
								<div className="invalid-feedback ms-0 d-inline">
									{t("constraints.client_address_not_full")}
								</div>
							)}
						</div>
						<div className="col-md-6">
							<InputGroup className={"form-input-group"}>
								<FormSelectGroup
									path={`/${organization.id}/clients/`}
									label={t("modules.invoice.field.receiver.title")}
									name="receiver_id"
									errors={errors}
									onCreateOption={onCreateReceiver}
									getOptionLabel={(option) => option.label}
									getOptionValue={(option) => option.id}
									defaultValue={{
										label: receiver?.name,
										id: receiver?.id,
									}}
									loadOptions={loadOptions}
									control={control}
									data-testid="receiver_id"
								/>
								<div className="input-group-append">
									<Button
										style={{ height: "38px", border: "1px solid #ced4da" }}
										onClick={() => setAddClientModal("receiver")}
										variant={"light"}
									>
										{t("common.action.create", { ns: "lib" })}
									</Button>
								</div>
							</InputGroup>
						</div>
					</div>
				</div>
			</div>
			{addClientModal !== undefined && (
				<AddClientModal
					name={name}
					onHide={() => setAddClientModal(undefined)}
					onSave={(data) => onCreateClient(data)}
					tax_id_no={
						recipient.id && recipient.id.toString() === order?.contact?.tax_id_no.toString()
							? order?.contact?.tax_id_no.toString()
							: undefined
					}
				/>
			)}
		</fieldset>
	);
};
export default InvoiceFormClientsInfo;
