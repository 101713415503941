import React, { ReactNode, useLayoutEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as KebabMenuIconSVG } from "go-list/core/images/kebab-menu-icon.svg";
import MobileAction, { MobileActionProps } from "./MobileAction";

interface Props {
	actions?: MobileActionProps[];
	children?: ReactNode;
}

const MobileActions = ({ actions, children }: Props) => {
	const isMobile = useWindowSize().isMobile;

	useLayoutEffect(() => {
		const navigationHeaderElement: HTMLElement | null = document.getElementById("contextual-navigation-header");
		if (navigationHeaderElement) {
			if (isMobile && ((actions && actions.length > 0) || children)) {
				navigationHeaderElement.style.maxWidth = "80%";
			} else {
				navigationHeaderElement.style.maxWidth = "100%";
			}
		}

		return () => {
			if (navigationHeaderElement) {
				navigationHeaderElement.style.maxWidth = "100%";
			}
		};
	}, [isMobile, actions, children]);

	if (!isMobile || (!children && !actions)) return <></>;

	return createPortal(
		<div className="mobile-actions">
			{children}
			{actions && (
				<Dropdown className="mobile-actions-dropdown">
					<Dropdown.Toggle variant="outline-light">
						<KebabMenuIconSVG />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{actions.map((action) => (
							<MobileAction
								title={action.title}
								action={action.action}
								key={action.title}
								path={action.path}
								hide={action.hide}
							/>
						))}
					</Dropdown.Menu>
				</Dropdown>
			)}
		</div>,
		document.getElementById("contextual-navigation-header") || document.body
	);
};

export default MobileActions;
