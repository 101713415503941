import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface AddressProps {
	address: any;
}

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:common.word.address");
	t("lib:common.address.country");
	t("lib:common.address.city");
	t("lib:common.address.zip_code");
	t("lib:common.address.street");
	t("lib:common.address.build_nr");
	t("lib:common.address.flat_nr");
};

export const FormatAddressString = (address?: Record<string, any>): string => {
	if (!address) return "";
	let str = "";
	if (address.street) {
		str += address.street;
	}
	if (address.build_nr) {
		str += ` ${address.build_nr}`;
	}
	if (address.flat_nr) {
		str += `/${address.flat_nr}`;
	}
	if (address.street) {
		str += ", ";
	}
	if (address.zip_code) {
		str += address.zip_code;
	}
	if (address.city && address.zip_code) {
		str += ` ${address.city}`;
	}
	if (address.city && !address.zip_code) {
		str += address.city;
	}
	if (address.country && address.country !== "PL") {
		str += `, ${address.country}`;
	}
	return str;
};

const FormatAddress: FC<AddressProps> = (props: AddressProps) => {
	const address = props.address;
	return (
		<>
			{address.street && address.street}
			{address.build_nr && ` ${address.build_nr}`}
			{address.flat_nr && `/${address.flat_nr}`}
			{(address.zip_code || address.city) && ", "}
			{address.zip_code && `${address.zip_code}`} {address.city && address.city}
			{(address.zip_code || address.city) && address.country && address.country !== "PL" && ", "}{" "}
			{address.country && address.country !== "PL" && `${address.country}`}
		</>
	);
};
export default FormatAddress;
