import React from "react";
import { ActionRow } from "../../services/types";
import { ActionButton } from "./ActionButton";

interface ActionsProps {
	actionsRows: ActionRow[];
}

export const Actions = ({ actionsRows }: ActionsProps): JSX.Element | null => {
	if (!actionsRows.length || actionsRows.every((row) => row.actions.length === 0)) {
		return null;
	}

	return (
		<div className="actions" data-testid="e-order-actions-container">
			{actionsRows.map((row) => {
				const rowKey = row.actions.map((action) => action.text).join();

				return (
					<div key={rowKey}>
						{row.actions.map((action) => (
							<ActionButton
								key={action.text}
								text={action.text}
								isPrimary={action.isPrimary}
								onClick={action.onClick}
								isLoading={action.isLoading}
								data-testid={action["data-testid"]}
							/>
						))}
					</div>
				);
			})}
		</div>
	);
};
