import { AxiosResponse } from "axios";
import { parseApiData } from "go-core/api/parseApiData";
import { FILTER_SEPARATOR } from "go-list/core/components/Filter/services";
import { restSecurityApi } from "go-security/services/Api/api";
import OrganizationIdService from "go-security/services/organizationIdService";

class apiReport {
	static getOrganizationId = () => {
		return OrganizationIdService.get();
	};

	static getReportsCustom(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get("/reports/custom", {
			params,
			cancelToken: options?.cancelToken,
			signal: options?.controller?.signal,
		});
	}

	static syncReports(params?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.post("/reports/sync", undefined, { params });
	}

	static getReportFilter(
		filter: string,
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<AxiosResponse> {
		if (params) {
			if (apiReport.getOrganizationId()) {
				params.organization_id = apiReport.getOrganizationId();
			}
		}
		return restSecurityApi.get(`/reports/filters?f=${filter}`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getReportLastUpdateDate(params: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get("/reports/organizations", { params });
	}

	static getCallRecordsReport(params: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`/${apiReport.getOrganizationId()}/call_records/report`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getProductReportsCSVWithEveryColumn(
		params: Record<string, any>,
		isOrganizationReport = false
	): Promise<string> {
		const newParams = { ...params };
		const decodedFParam = decodeURIComponent(escape(atob(newParams.f)));

		if (isOrganizationReport) {
			newParams.f = `${decodedFParam}${FILTER_SEPARATOR}organization_id|e=${apiReport.getOrganizationId()}`;
		} else {
			const selectedFiltersArray = decodedFParam.split(FILTER_SEPARATOR);
			const organizationIdsFilterIndex = selectedFiltersArray.findIndex((selectedFilter: string) =>
				selectedFilter.includes("organization_ids")
			);
			const organizationIdsFilter = selectedFiltersArray[organizationIdsFilterIndex].replace("ids", "id");
			selectedFiltersArray.splice(organizationIdsFilterIndex, 1, organizationIdsFilter);

			newParams.f = selectedFiltersArray.join(FILTER_SEPARATOR);
		}

		newParams.f = btoa(unescape(encodeURIComponent(newParams.f)));

		return parseApiData(restSecurityApi.get(`/reports/order_items/csv`, { params: newParams }));
	}

	static getComboReport(data: Record<string, any>): Promise<(string | number)[][]> {
		return parseApiData(restSecurityApi.post("/reports/combo/matrix", data));
	}
}

export { apiReport };
