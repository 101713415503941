export const inputCustomStyles = {
	control: (provided: Record<string, any>): Record<string, any> => ({
		...provided,
		minHeight: "30px",
		height: "30px",
	}),

	valueContainer: (provided: Record<string, any>): Record<string, any> => ({
		...provided,
		height: "30px",
		padding: "0 6px",
	}),

	input: (provided: Record<string, any>): Record<string, any> => ({
		...provided,
		margin: "0px",
	}),
	indicatorsContainer: (provided: Record<string, any>): Record<string, any> => ({
		...provided,
		height: "30px",
	}),
};
