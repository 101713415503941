import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "go-security/services/organizations/selectors";

const CompanyInfoNotFull = (): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	return (
		<form>
			<Alert variant={"danger"}>
				<span className={"me-1"}>{t("constraints.company_info_not_full")}.</span>
				<Link target={"_blank"} rel={"no_referrer"} to={`/${organization.id}/settings/info/company`}>
					{t("modules.invoice.action.set_company_info.title")}
				</Link>
			</Alert>
		</form>
	);
};
export default CompanyInfoNotFull;
