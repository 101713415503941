import { Reducer } from "redux";
import { UserActionTypes, UserState } from "./types";

const initialState: UserState = {
	userId: undefined,
	email: undefined,
};

export const userReducer: Reducer<UserState> = (state = initialState, action) => {
	switch (action.type) {
		case UserActionTypes.SET: {
			return { ...state, ...action.payload };
		}
		case UserActionTypes.CLEAR: {
			return { ...state };
		}
		case UserActionTypes.ADD_ORGANIZATION: {
			return { ...state, organizations: [...(state?.organizations ? state.organizations : []), action.payload] };
		}
		case UserActionTypes.SET_ORGANIZATIONS: {
			return { ...state, organizations: action.payload };
		}
		case UserActionTypes.UPDATE_ORGANIZATION: {
			return {
				...state,
				organizations: state?.organizations?.map((org) =>
					org.id === action.payload.id ? { ...org, ...action.payload } : org
				),
			};
		}
		case UserActionTypes.REMOVE_ORGANIZATION: {
			return {
				...state,
				organizations: state?.organizations?.filter((f) => f.id !== action.payload),
			};
		}
		default: {
			return state;
		}
	}
};
