import React from "react";
import { Dropdown, Form } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import { listDividerId } from "../services/consts";
import { ListConfigColumn } from "../services/types";
import { clickOutsideDropdown } from "../utils";
import { ColumnListItem } from "./ColumnListItem";

interface SortableColumnsListWithDividerProps {
	stickyColumnsDividerPosition: number;
	sortedColumnsToDisplayInTheDropdown: ListConfigColumn[];
	selectedColumns: string[];
	onChangeSelected: (columnId: string) => void;
	onSortedList: (sortedList: ListConfigColumn[]) => void;
}

export const SortableColumnsListWithDivider = ({
	stickyColumnsDividerPosition,
	sortedColumnsToDisplayInTheDropdown,
	selectedColumns,
	onChangeSelected,
	onSortedList,
}: SortableColumnsListWithDividerProps): JSX.Element => {
	const stickyColumns = sortedColumnsToDisplayInTheDropdown.slice(0, stickyColumnsDividerPosition);
	const nonStickyColumns = sortedColumnsToDisplayInTheDropdown.slice(stickyColumnsDividerPosition + 1);

	return (
		<ReactSortable list={sortedColumnsToDisplayInTheDropdown} setList={onSortedList} onEnd={clickOutsideDropdown}>
			{stickyColumns.map((column) => (
				<ColumnListItem
					key={column.id}
					column={column}
					selectedColumns={selectedColumns}
					onChangeSelected={onChangeSelected}
				/>
			))}
			<Form.Group key={listDividerId} controlId={listDividerId} className="form-group sticky-columns-divider">
				<Dropdown.Divider />
			</Form.Group>
			{nonStickyColumns.map((column) => (
				<ColumnListItem
					key={column.id}
					column={column}
					selectedColumns={selectedColumns}
					onChangeSelected={onChangeSelected}
				/>
			))}
		</ReactSortable>
	);
};
