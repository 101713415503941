import React from "react";
import { useTranslation } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import { getFormattedItemCost } from "go-core/utils/utils";
import { OrderTaxItemData } from "../../../../../../../services/Api/Public/types";

interface CostModifiersListProps {
	totalPriceTax: MoneyApi;
	taxItems: OrderTaxItemData[];
	discountPrice?: MoneyApi;
}

export const CostModifiersList = ({ totalPriceTax, taxItems, discountPrice }: CostModifiersListProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<section data-testid="e-order-order-details-cost-modifiers-list">
			<ul>
				{taxItems.map((tax_item) => (
					<li key={`${tax_item.tax_amount}${tax_item.tax_code}`}>
						<span>
							{t("modules.e_order.field.tax.title")} {tax_item.tax_amount}%
						</span>
						<span>
							{getFormattedItemCost(tax_item.total_price_tax.amount, tax_item.total_price_tax.currency)}
						</span>
					</li>
				))}
				{taxItems.length > 1 && (
					<li data-testid="e-order-order-details-cost-modifiers-list-total-tax">
						<span>{t("modules.e_order.field.total_tax.title")}</span>
						<span>{getFormattedItemCost(totalPriceTax.amount, totalPriceTax.currency)}</span>
					</li>
				)}
				{discountPrice && discountPrice.amount > 0 && (
					<li data-testid="e-order-order-details-cost-modifiers-list-discount-price">
						<span>{t("modules.e_order.field.discount.title")}</span>
						<span>- {getFormattedItemCost(discountPrice.amount, discountPrice.currency)}</span>
					</li>
				)}
			</ul>
		</section>
	);
};
