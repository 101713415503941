import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { InvoiceApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import InvoicePdfPreviewModal from "../../../components/InvoicePdfPreviewModal/InvoicePdfPreviewModal";
import InvoicePreview from "../../../components/Preview/InvoicePreview";
import SendInvoiceByEmailModal from "../../../components/SendInvoiceByEmailModal/SendInvoiceByEmailModal";
import { exportInvoicePdf, generateInvoicePreviewPdfLink, printInvoicePdf } from "../../../services/exportInvoice";

interface MatchParams {
	invoice_id: string;
}

const OrganizationInvoicesProformaPreviewPage = (): JSX.Element => {
	const [invoice, setInvoice] = useState<InvoiceApi | undefined>(undefined);
	const { invoice_id } = useParams<MatchParams>();
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[] | undefined>(undefined);
	const [showSendInvoiceByEmailModal, setShowSendInvoiceByEmailModal] = useState(false);
	const [showLogs, setShowLogs] = useState<boolean>(false);
	const [invoicePdfLink, setInvoicePdfLink] = useState<string>("");
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const params: Record<string, any> = {
			include:
				"custom_fields,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver,issuer,linked_invoices",
		};
		const cfParams: Record<string, string> = {
			include: "options,constraints",
			f: btoa(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}resource_type|e=INVOICE`),
		};
		Promise.all([
			api.organization().getProforma(Number(invoice_id), params),
			api.organization().getCustomFields(cfParams),
		])
			.then(([invoiceResponse, cfResponse]) => {
				setInvoice(invoiceResponse);
				setCustomFieldsConfig(cfResponse);
				handleChangeTabTitle(`${invoiceResponse.number} | ${t("modules.invoice.field.proforma.title")}`);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	}, []);

	if (!invoice || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	const removeProforma = async (invoice: InvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeInvoice(invoice.id);
			history.push(`/${organization.id}/invoices/proforma`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const createInvoice = () => {
		history.push({
			pathname: `/${organization.id}/invoices/new`,
			state: {
				proforma: invoice,
			},
		});
	};
	const createAdvance = () => {
		history.push({
			pathname: `/${organization.id}/invoices/advances/new`,
			state: {
				proforma: {
					...invoice,
					proforma_id: invoice.id,
				},
			},
		});
	};

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			variant: "light",
			path: `/${organization.id}/invoices/proforma/${invoice.id}/edit`,
		},
	];
	if (!invoice.linked_invoices.find((f) => f.document_type === "INVOICE"))
		buttons.push({
			title: t("modules.invoice.action.create_invoice.title"),
			action: () => createInvoice(),
			dropdown: true,
		});
	buttons.push({
		title: t("modules.invoice.action.create_advance.title"),
		action: () => createAdvance(),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.print", { ns: "lib" }),
		action: () => printInvoicePdf(invoice),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.preview", { ns: "lib" }),
		action: async () => {
			const link = await generateInvoicePreviewPdfLink(invoice);
			setInvoicePdfLink(link);
		},
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.remove", { ns: "lib" }),
		action: () => removeProforma(invoice),
		dropdown: true,
	});
	buttons.push({
		title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
		action: () => setShowSendInvoiceByEmailModal(true),
		dropdown: true,
	});
	buttons.push({
		title: t("common.action.export_pdf", { ns: "lib" }),
		action: () => exportInvoicePdf(invoice, t),
		dropdown: true,
	});
	buttons.push({
		title: t("lib:common.word.logs"),
		action: () => setShowLogs(true),
		dropdown: true,
	});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.edit", { ns: "lib" }),
			path: `/${organization.id}/invoices/proforma/${invoice.id}/edit`,
		},
		{
			title: t("modules.invoice.action.create_invoice.title"),
			action: () => createInvoice(),
			hide: !!invoice.linked_invoices.find((f) => f.document_type === "INVOICE"),
		},
		{
			title: t("modules.invoice.action.create_advance.title"),
			action: () => createAdvance(),
		},
		{
			title: t("common.action.print", { ns: "lib" }),
			action: () => printInvoicePdf(invoice),
		},
		{
			title: t("common.action.preview", { ns: "lib" }),
			action: async () => {
				const link = await generateInvoicePreviewPdfLink(invoice);
				setInvoicePdfLink(link);
			},
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeProforma(invoice),
		},
		{
			title: t("modules.invoice.action.send_invoice_by_e-mail.title"),
			action: () => setShowSendInvoiceByEmailModal(true),
		},
		{
			title: t("common.action.export_pdf", { ns: "lib" }),
			action: () => exportInvoicePdf(invoice, t),
		},
		{
			title: t("lib:common.word.logs"),
			action: () => setShowLogs(true),
		},
	];

	const renderSubtitle = () => (
		<ul>
			<li className="me-1">{FormatDate(invoice.dates?.issued_at, undefined, false, defaultFormatDate)}</li>
			<li>{FormatMoney(invoice?.summary?.price_sum_gross)}</li>
		</ul>
	);

	const avatar = {
		color: "#1A86E0",
		date: invoice.dates?.issued_at,
	};

	return (
		<div className="content-preview">
			<SendInvoiceByEmailModal
				isShown={showSendInvoiceByEmailModal}
				onHide={() => setShowSendInvoiceByEmailModal(false)}
				invoice={invoice}
			/>
			<MobileActions actions={mobileActions} />
			<Header
				title={`${t("modules.invoice.field.proforma.title")} ${invoice.number}`}
				subtitle={renderSubtitle()}
				buttons={isMobile ? [] : buttons}
				avatar={avatar}
			/>
			<InvoicePreview invoice={invoice} config={customFieldsConfig} setInvoice={setInvoice} />
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={invoice.id}
					resourceType="INVOICE"
					onHide={() => setShowLogs(false)}
					organizationId={organization.id}
				/>
			)}
			{invoicePdfLink && (
				<InvoicePdfPreviewModal
					show={!!invoicePdfLink}
					onHide={() => setInvoicePdfLink("")}
					pdfLink={invoicePdfLink}
				/>
			)}
		</div>
	);
};
export default OrganizationInvoicesProformaPreviewPage;
