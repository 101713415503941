import { AxiosResponse } from "axios";
import OrganizationIdService from "go-security/services/organizationIdService";
import { SegmentApi, UpdateSegmentApi } from "go-segment/services/types";
import { restSecurityApi } from "../api";

class apiOrganizationSegment {
	static getOrganizationId = () => {
		return OrganizationIdService.getWithCheck();
	};

	static getListInfo(params?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`/${apiOrganizationSegment.getOrganizationId()}/lists`, { params });
	}

	static saveSegment(segment: SegmentApi): Promise<AxiosResponse> {
		return restSecurityApi.post(`/${apiOrganizationSegment.getOrganizationId()}/segments`, segment);
	}

	static updateSegment(segment: SegmentApi): Promise<AxiosResponse> {
		return restSecurityApi.put(`/${apiOrganizationSegment.getOrganizationId()}/segments/${segment.id}`, segment);
	}

	static restoreSegment(segment: SegmentApi): Promise<AxiosResponse> {
		return restSecurityApi.put(
			`/${apiOrganizationSegment.getOrganizationId()}/segments/${segment.id}/restore`,
			segment
		);
	}

	static removeSegment(segmentId: number): Promise<AxiosResponse> {
		return restSecurityApi.delete(`/${apiOrganizationSegment.getOrganizationId()}/segments/${segmentId}`);
	}

	static getSegments(params?: Record<string, any>, options?: Record<string, any>): Promise<AxiosResponse> {
		return restSecurityApi.get(`/${apiOrganizationSegment.getOrganizationId()}/segments/`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static async setSegmentAsDefault(segmentId: number) {
		return restSecurityApi.put(`/${apiOrganizationSegment.getOrganizationId()}/segments/${segmentId}/make_default`);
	}

	static async setSegmentNotDefault(segmentId: number) {
		return restSecurityApi.put(
			`/${apiOrganizationSegment.getOrganizationId()}/segments/${segmentId}/make_not_default`
		);
	}

	static async updateSegments(data: UpdateSegmentApi, params?: Record<string, any>) {
		return restSecurityApi.patch(`/${apiOrganizationSegment.getOrganizationId()}/segments`, data, { params });
	}
}

export { apiOrganizationSegment };
