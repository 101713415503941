import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RouteHistory from "go-core/utils/routeHistory";
import { hasPermission, selectOrganization } from "go-security/services/organizations/selectors";
import { ExternalReportApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";

interface MatchParams {
	reportId: string;
}

const OrganizationSalesPosReportsExternalReportsPreviewPage = (): JSX.Element => {
	const [report, setReport] = useState<ExternalReportApi | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const { reportId } = useParams<MatchParams>();
	const organization = useSelector(selectOrganization);
	const { addFlash } = useFlash();
	const params = { include: "report_drawer,report_shiftwork,infos" };
	const { t } = useTranslation();
	const history = useHistory();
	const hasReportsPermission = !!hasPermission("VENUE_REPORTS_SHOW");
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		if (hasReportsPermission) {
			fetch();
		} else {
			history.push(`/${organization.id}/sales/pos_reports`);
		}
	}, []);

	const fetch = async () => {
		setLoading(true);
		try {
			const res = await api.organization().getExternalReport(Number(reportId), params);
			setReport(res);
			handleChangeTabTitle(`#${res.id} | ${t("modules.external_report.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const goBack = () => RouteHistory.goBack(history, `/${organization.id}/sales/pos_reports/external_reports`);

	if (loading || !report) return <LoadingContainer />;

	return (
		<>
			<Header title={`${t("modules.external_report.header.preview.title")} #${report.id}`} back={goBack} />
			<div className="col-xl-10">
				<Card className="mb-3">
					<Card.Header>#{report.id}</Card.Header>
					<Card.Body>
						<div className="row row-cols-2">
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("modules.external_report.field.created_at.title")}</dt>
									<dd>{FormatDate(report.created_at)}</dd>
								</dl>
							</div>
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("lib:common.word.type")}</dt>
									<dd>{t(`enums.external_reports.type.${report.type}`)}</dd>
								</dl>
							</div>
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("modules.external_report.field.report_drawer.title")}</dt>
									<dd>
										{report.report_drawer ? (
											<Link
												to={`/${organization.id}/sales/pos_reports/${report.report_drawer.id}`}
											>
												{t("enums.pos_reports.type.DRAWER")} #{report.report_drawer.id}
											</Link>
										) : (
											t("common.word.lack")
										)}
									</dd>
								</dl>
							</div>
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("modules.external_report.field.report_shift_work.title")}</dt>
									<dd>
										{report.report_shift_work ? (
											<Link
												to={`/${organization.id}/sales/pos_reports/${report.report_shift_work.id}`}
											>
												{t("enums.pos_reports.type.SHIFTWORK")} #{report.report_shift_work.id}
											</Link>
										) : (
											t("common.word.lack")
										)}
									</dd>
								</dl>
							</div>
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("modules.external_report.field.external_id.title")}</dt>
									<dd>{report.external_id}</dd>
								</dl>
							</div>
							<div className="col-6">
								<dl className="mb-0">
									<dt>{t("common.word.amount")}</dt>
									<dd>{FormatMoney(report.total)}</dd>
								</dl>
							</div>
						</div>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header>{t("lib:common.word.informations")}</Card.Header>
					<Card.Body>
						<div className="row row-cols-2">
							{report?.infos?.map((info, index) => (
								<div key={index} className="col-6">
									<dl className="mb-0">
										<dt>{info.type}</dt>
										<dd>{info.data}</dd>
									</dl>
								</div>
							))}
						</div>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};

export default OrganizationSalesPosReportsExternalReportsPreviewPage;
