import React, { FC } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EditAction from "../../../../../../../components/Common/CommonActions/EditAction/EditAction";
import RemoveAction from "../../../../../../../components/Common/CommonActions/RemoveAction/RemoveAction";
import Tag from "../../../../../../../components/Common/Tag/Tag";
import { ReactComponent as MoreSvg } from "../../../../../../../images/svg/more.svg";

interface Props {
	image?: Record<string, any>;
	handleAppend?: () => void;
	handleEdit?: (item: any) => void;
	handleRemove?: () => void;
}

const ImageLinkItem: FC<Props> = ({ image, handleAppend, handleRemove, handleEdit }) => {
	const { t } = useTranslation();

	if (image) {
		return (
			<div onClick={() => (handleEdit ? handleEdit(image) : undefined)} className={"image-link-item-wrapper"}>
				<div className={"image-link-item"}>
					<Dropdown onClick={(evt) => evt.stopPropagation()} as={ButtonGroup} className="actions">
						<Dropdown.Toggle draggable={false} as={MoreSvg}>
							{t("common.action.more", { ns: "lib" })}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => (handleRemove ? handleRemove() : undefined)}>
								<RemoveAction />
							</Dropdown.Item>
							<Dropdown.Item onClick={() => (handleEdit ? handleEdit(image) : undefined)}>
								<EditAction />
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<img
						src={
							image.image_link
								? image.image_link.default_link
								: `data:${image.image?.type};base64,${image.image?.image_data}`
						}
					/>
					{image.tag && <Tag className={"image-tag"}>#{image.tag}</Tag>}
				</div>
			</div>
		);
	}
	return (
		<div className={"image-link-item"} onClick={() => (handleAppend ? handleAppend() : undefined)}>
			<span className={"add-image"}>
				<span className={"add-image-text"}>{`+ ${t("modules.menu.action.add_more_photos.title")}`}</span>
			</span>
		</div>
	);
};

export default ImageLinkItem;
