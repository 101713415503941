import React from "react";
import { Button } from "react-bootstrap";
import { LiveOrderFilterOption } from "../../../services/types";

interface Props {
	option: LiveOrderFilterOption;
	handleOnClick: () => void;
	isSelected: boolean;
}

const LiveOrdersTypeFilterOption = ({ option, handleOnClick, isSelected }: Props) => {
	return (
		<Button variant={isSelected ? "primary" : "light"} onClick={handleOnClick}>
			{option.label}
		</Button>
	);
};

export default LiveOrdersTypeFilterOption;
