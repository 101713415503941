import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { unstable_batchedUpdates } from "react-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactSortable, SortableEvent } from "react-sortablejs";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import EmptyData from "go-core/components/EmptyData";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormDirty } from "go-form/components/FormDirty";
import { ReactComponent as PlusSVG } from "../../../../../../../../../../images/svg/menu/plus.svg";
import { MenuApi, MenuItemApi, MenuPageApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import MenuGridPage from "./MenuGridPage";
import MenuGridPageItem from "./MenuGridPageItem";
import SortablePageItem from "./SortablePageItem";

interface Props {
	menu: MenuApi;
	initialMenu: MenuApi;
	handleSave: (data: MenuApi, id?: number) => void;
	isDirty: boolean;
	menuChanged: (isDirty: boolean) => void;
}

const MenuGrid = (props: Props): JSX.Element => {
	const form = useForm<MenuApi>({
		criteriaMode: "all",
		defaultValues: props.menu,
	});
	const { t } = useTranslation();
	const windowSize = useWindowSize();
	const [defaultType, setDefaultType] = useState("CATEGORY");
	const isSortingRef = useRef(false);
	const sortedPageIndexRef = useRef<null | number>(null);
	const [pageIndex, setPageIndex] = useState<number>(props.menu.pages.length > 0 ? 0 : -1);
	const [pagesItems, setPagesItems] = useState<MenuPageApi[]>(props.menu.pages);
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		formState,
		setError,
		reset,
	} = form;
	const [currentMenuItems, setCurrentMenuItems] = useState(
		pagesItems[pageIndex]?.items && props.menu.pages.length > 0 ? pagesItems[pageIndex].items : []
	);
	const onSubmit = handleSubmit(async (data: MenuApi) => {
		try {
			const params: Record<string, any> = { include: "pages,pages.items,pages.items.entity" };
			setLoading(true);
			data.active = props.menu.status === "ENABLED";
			data.pages = [...pagesItems].map((pageItem, pageItemIndex) => {
				return {
					...pageItem,
					position: pageItemIndex,
				};
			});
			data.pages?.forEach((page) => {
				page.items = page.items?.filter((f) => f.context_type);
			});

			if (props.menu.id) {
				data.id = props.menu.id;
				const res = await api.organization().updateMenu(data, params);
				reset(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				setLoading(false);
				props.handleSave(res, res.id);
				fillPages(res.pages);
			} else {
				const res = await api.organization().createMenu(data, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				props.handleSave(res);
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			handleError.form(e, setError, addFlash);
		}
	});
	const { fields, remove, append } = useFieldArray({
		control,
		name: `pages`,
		keyName: "key",
	});

	const handleAddPage = () => {
		setPageIndex(fields.length);
		const name = fields.length === 0 ? "1" : `${fields.length + 1}`;
		const menuPageItems: MenuItemApi[] = [];
		for (let i: number = 0; i < 25; ++i) {
			menuPageItems.push({} as MenuItemApi);
		}
		append({
			name,
		});
		const newMenuPage = {
			name,
			items: menuPageItems,
		} as MenuPageApi;
		setPagesItems([...pagesItems, newMenuPage]);
	};

	useEffect(() => {
		if (pageIndex === -1) {
			setCurrentMenuItems([]);
		} else setCurrentMenuItems(pagesItems[pageIndex].items);
		sortedPageIndexRef.current = null;
	}, [pageIndex]);

	const changeName = (index: number, name: string) => {
		const updatedPage = pagesItems[index];
		updatedPage.name = name;
	};

	const removePage = (index: number) => {
		remove(index);
		const newPageItems = pagesItems;
		newPageItems.splice(index, 1);
		setPagesItems(newPageItems);
		setPageIndex(newPageItems.length - 1);
	};

	const changePage = (index: number) => {
		setPageIndex(index);
	};

	const fillPages = (pages: MenuPageApi[]) => {
		const newPages: MenuPageApi[] = [];
		pages.forEach((page) => {
			const newPage = {
				id: page.id,
				name: page.name,
			} as MenuPageApi;
			const newItems: MenuItemApi[] = [];
			page.items.forEach((pageItem) => {
				newItems.push(pageItem);
			});
			const length = newItems.length;
			if (page.items.length < 25) {
				for (let i: number = 0; i < 25 - length; ++i) {
					newItems.push({} as MenuItemApi);
				}
			}
			const itemsCopy = page.items;
			itemsCopy.forEach((item: MenuItemApi, index: number) => {
				if (item.position) {
					newItems[index] = {} as MenuItemApi;
					newItems[item.position] = item;
				}
			});
			newPage.items = newItems;
			newPages.push(newPage);
		});
		setPagesItems(newPages);
	};

	const handleRemoveItem = (itemIndex: number) => {
		const newItems = pagesItems;
		newItems.forEach((item, index) => {
			if (index === pageIndex) {
				item.items[itemIndex] = {} as MenuItemApi;
			}
		});
		props.menuChanged(true);
		setPagesItems(newItems);
	};

	const handleUpdateItem = (menuItemApi: MenuItemApi) => {
		const newItems = pagesItems;
		setDefaultType(menuItemApi.context_type);
		newItems.forEach((item, index) => {
			if (index === pageIndex) {
				item.items[menuItemApi.position] = menuItemApi;
			}
		});
		props.menuChanged(true);
		setPagesItems([...newItems]);
	};

	const handleResetMenu = () => {
		reset(props.menu);
		props.menuChanged(false);
		setPagesItems(JSON.parse(JSON.stringify(props.initialMenu.pages)));
		if (props.initialMenu.pages[pageIndex]?.items)
			setCurrentMenuItems(JSON.parse(JSON.stringify(props.initialMenu.pages[pageIndex].items)));
	};

	useEffect(() => {
		reset(props.menu);
		props.menuChanged(false);
		setPagesItems(props.menu.pages);
		setCurrentMenuItems(props.menu.pages[pageIndex]?.items);
	}, [props.menu.id, props.menu]);

	useEffect(() => {
		if (props.menu.pages.length > 0) {
			setPageIndex(0);
		} else setPageIndex(-1);
	}, [props.menu.id]);

	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	let currentlyDraggingIndex = -1;
	let currentlyDraggedIndex = -1;
	let newPageIndex = 0;
	const handleSortPages = (evt: SortableEvent) => {
		isSortingRef.current = true;
		if (evt.newIndex !== undefined) {
			newPageIndex = evt.newIndex;
			sortedPageIndexRef.current = newPageIndex;
		}
	};

	const updatePagePositions = (items: MenuPageApi[]) => {
		const newPageItems: MenuPageApi[] = [];
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items.forEach((item: MenuPageApi, index) => {
			newPageItems.push({
				position: index,
				id: item.id,
				name: item.name,
				items: item.items,
			});
		});
		unstable_batchedUpdates(() => {
			setPageIndex(newPageIndex);
			setCurrentMenuItems(newPageItems[newPageIndex].items);
			setPagesItems([...newPageItems]);
			props.menuChanged(true);
		});
	};

	const onDragStart = (index: number) => {
		document.body.style.overscrollBehaviorY = "contain";
		currentlyDraggedIndex = index;
	};

	const onDragOver = (index: number) => {
		currentlyDraggingIndex = index;
	};

	const onDrop = () => {
		if (currentlyDraggingIndex === -1 || currentlyDraggedIndex === -1) return;
		document.body.style.overscrollBehaviorY = "auto";
		const items = currentMenuItems;
		const old = items[currentlyDraggedIndex];
		items[currentlyDraggedIndex] = items[currentlyDraggingIndex];
		items[currentlyDraggingIndex] = old;
		currentlyDraggingIndex = -1;
		currentlyDraggedIndex = -1;
		items.forEach((item, index) => {
			item.position = index;
		});
		props.menuChanged(true);
		const updatedPages = pagesItems;
		updatedPages[pageIndex].items = items;
		setCurrentMenuItems(items);
		setPagesItems(() => [...updatedPages]);
	};
	const itemsRender: MenuItemApi[][] = [[], [], [], [], []];
	let ind = 0;
	currentMenuItems?.forEach((item, index) => {
		if (index % 5 <= 4) {
			itemsRender[ind].push(item);
			if (index % 5 === 4) ind++;
		}
	});

	const renderSortablePages = () => {
		return (
			<>
				<FormInput errors={errors} type="hidden" register={register} name="name" value={props.menu.name} />
				<FormInput
					errors={errors}
					type="hidden"
					register={register}
					name="active"
					defaultChecked={props.menu.active}
				/>
				<div className="header-container">
					<h6>{t("modules.menu.field.pages.title")}</h6>
					<Button variant="light" type="button" onClick={handleAddPage}>
						<PlusSVG />
					</Button>
				</div>
				<ReactSortable
					list={pagesItems}
					handle=".sortable-handler"
					onStart={() => (document.body.style.cursor = "grab")}
					onEnd={() => (document.body.style.cursor = "default")}
					onUpdate={(evt) => handleSortPages(evt)}
					setList={(fields) => updatePagePositions(fields)}
				>
					{pagesItems.map((item, index) => {
						return (
							<MenuGridPage
								item={item}
								key={`pages.${index}`}
								form={form}
								index={index}
								activePageIndex={pageIndex}
								handleChangeName={changeName}
								handleRemovePage={removePage}
								handleChangePage={changePage}
							/>
						);
					})}
				</ReactSortable>
			</>
		);
	};

	const getWidthWhenShouldDrawSidebarOnTop = () => {
		if (windowSize.width < 1250 || (windowSize.width >= 1368 && windowSize.width <= 1412)) return true;
		return false;
	};
	return (
		<FormDirty onSubmit={onSubmit} isDirty={formState.isDirty || props.isDirty}>
			{getWidthWhenShouldDrawSidebarOnTop() && (
				<div className={"sidebar sidebar-small"}>{renderSortablePages()}</div>
			)}
			<div className={"content-container-wrapper"}>
				<div className="content-container-small">
					<h6>{t("modules.menu.field.menu_positions.title")}</h6>
					{currentMenuItems?.length > 0 && pageIndex !== -1 ? (
						<table>
							<tbody>
								{itemsRender.map((it: MenuItemApi[], parentIndex: number) => {
									return (
										<tr key={parentIndex}>
											{it.map((item: MenuItemApi, index: number) => {
												const itemIndex = parentIndex * 5 + index;
												return (
													<td key={index}>
														<div className="menu-item-wrapper">
															<SortablePageItem
																position={itemIndex}
																onDragOver={() => {
																	onDragOver(itemIndex);
																}}
																onDragStart={() => onDragStart(itemIndex)}
																onDrop={onDrop}
															>
																<MenuGridPageItem
																	handleRemoveItem={handleRemoveItem}
																	handleUpdateItem={handleUpdateItem}
																	defaultType={defaultType}
																	currentMenuItems={currentMenuItems}
																	key={`menu[${props.menu.id}].pages[${[
																		pageIndex,
																	]}.items.${itemIndex}`}
																	menuId={props.menu.id}
																	item={item}
																	form={form}
																	pageIndex={pageIndex}
																	index={itemIndex}
																/>
															</SortablePageItem>
														</div>
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						<EmptyData
							title={t("modules.menu.field.empty_pages.title")}
							description={t("modules.menu.field.empty_pages.helptext.description")}
						/>
					)}
				</div>
				{!getWidthWhenShouldDrawSidebarOnTop() && <div className={"sidebar"}>{renderSortablePages()}</div>}
			</div>
			<div className="form-footer">
				<Form.Group className="form-group">
					<ButtonLoading loading={loading} variant="primary" type="submit">
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					{(formState.isDirty || props.isDirty) && (
						<Button variant="light" type="button" onClick={() => handleResetMenu()}>
							{t("common.action.cancel", { ns: "lib" })}
						</Button>
					)}
				</Form.Group>
			</div>
		</FormDirty>
	);
};
export default MenuGrid;
