import Cookies from "universal-cookie/lib";

const cookies = new Cookies();
export function setCookie(name: string, value: string): void {
	let expires = "";
	const minutes = 60 * 24;
	if (minutes) {
		const date = new Date();
		date.setTime(date.getTime() + minutes * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	document.cookie = `${name}=${value || ""}${expires}; path=/`;
}
export function getCookie(name: string): string {
	const pattern = RegExp(`${name}=.[^;]*`);
	const matched = document.cookie.match(pattern);
	if (matched) {
		const cookie = matched[0].split("=");
		return cookie[1];
	}
	return "";
}
const get = (key: string): string => {
	return getCookie(key);
};
const update = (key: string, value: string): void => {
	setCookie(key, value);
};
const remove = (key: string): void => {
	cookies.remove(key, { path: "/" });
};
export const MyCookies = { get, update, remove };
export default MyCookies;
