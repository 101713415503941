import React from "react";
import { Dropdown } from "react-bootstrap";
import { ImageLinkApi } from "../../../services/Api/typesSecurity";
import { OrganizationState } from "../../../services/organizations/types";
import NavigationHeaderWrapperProfileSecurity from "./NavigationHeaderWrapperProfileSecurity";

interface Props {
	avatar?: ImageLinkApi;
	name?: string;
	organization?: OrganizationState;
}

const NavigationHeaderProfileSecurity = ({ avatar, organization, name }: Props): JSX.Element => {
	return (
		<div className="dropdown-menu-padding navigation-header-profile">
			<NavigationHeaderWrapperProfileSecurity organizationId={organization?.id}>
				<div className="user-image">
					<img alt="Profile Logo" src={avatar?.small ?? "/graphics/user-avatar.svg"} />
				</div>
				<div className="d-flex flex-column justify-content-center">
					<div className="font-weight-bold">{name}</div>
					{organization?.name && (
						<div className="user-organization text-primary font-weight-bold">{organization.name}</div>
					)}
					<small className="text-muted">{organization?.company_id}</small>
				</div>
			</NavigationHeaderWrapperProfileSecurity>
			<Dropdown.Divider />
		</div>
	);
};

export default NavigationHeaderProfileSecurity;
