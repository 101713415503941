import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck, FormInput, registerObject } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { AvailabilityApi } from "go-component/services/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ItemApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { getOrderTypes } from "../../utils";
import { getWeightedVariantOptions } from "../ItemGroupForm";
import BarcodesFormSelect from "./BarcodesFormSelect";

interface Props {
	item?: ItemApi;
	handleClose: () => void;
	handleSave: (data: ItemApi) => void;
	itemGroupName?: string;
	errors?: Record<string, any>;
	allowNegativePrice?: boolean;
}

export const mapBarcodesToObj = (barcodes?: string[]): Record<string, any>[] | undefined => {
	return barcodes?.map((option) => {
		if (typeof option !== "object")
			return {
				value: option,
				label: option,
			};
		return option;
	});
};

export const mapBarcodesToString = (barcodes?: any): string[] => {
	return barcodes?.map((x: { label: string }) => (x.label ? x.label : x));
};

const ItemModalForm = (props: Props): JSX.Element => {
	const [item] = useState<ItemApi>(props.item ? props.item : ({ discountable: true } as ItemApi));
	const [loading] = useState(false);
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const [defaultAvailability, setDefaultAvailability] = useState<AvailabilityApi | undefined>(
		props.item?.availability
	);
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const form = useForm<ItemApi>({
		criteriaMode: "all",
		defaultValues: {
			...item,
			name: item.name === t("common.word.default") ? "" : item.name,
			weighted_type: "DISABLED",
		},
	});
	const {
		watch,
		register,
		handleSubmit,
		formState: { errors },
		control,
		setValue,
	} = form;
	const weightedTypeSelected = watch("weighted_type") === "WEIGHTED" || watch("weighted_type") === "WEIGHTED_AFTER";
	const { setErrors, validateCustomErrors } = useCustomErrors(form.setError);

	const onSubmit = handleSubmit((data: ItemApi) => {
		if (!validateCustomErrors()) {
			return;
		}
		data.modifier_groups = data.modifier_groups ? data.modifier_groups : [];
		if (defaultAvailability) {
			data.availability = defaultAvailability;
		}
		data.points_of_sale = [];
		props.handleSave(JSON.parse(JSON.stringify(data)));
	});

	useEffect(() => {
		registerObject(register, "availability", ["id", "name"]);
		setValue("availability", props.item?.availability);
		if (props.item && props.item.weighted_type) {
			setValue("weighted_type", props.item?.weighted_type);
		}
	}, []);

	const onChangeAvailability = (o: any, o2: any) => {
		if (o2 && o2.id) {
			registerObject(register, "availability", ["id", "name"]);
			setDefaultAvailability({ id: o2.id, name: o2.label } as AvailabilityApi);
			setValue("availability", o2);
		} else {
			setValue("availability", undefined);
		}
		setValue("availability_id", o);
	};

	const onCreateAvailability = async (name: string) => {
		const availability = {
			name,
		} as AvailabilityApi;
		try {
			const res = await api.organization().createAvailability(availability);
			setDefaultAvailability(res);
			registerObject(register, `availability`, ["name", "id"]);
			setValue("availability_id", res.id);
			setValue("availability", { id: res.id, name: res.name } as AvailabilityApi);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const searchAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const nameValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const priceValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	return (
		<Modal size={"lg"} show={true} onHide={props.handleClose}>
			<form key="product_item_form">
				<Modal.Header closeButton>
					<Modal.Title>
						{props.item
							? `${t("modules.item_group.header.title")} ${
									props.item.name.length > 0 ? props.item.name : t("common.word.default")
							  }`
							: t("modules.item_group.header.add_new_variant.title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.item?.id && <input type="hidden" defaultValue={props.item?.id} {...register("id")} />}
					<FormInput
						register={register}
						label={t("lib:common.word.name")}
						name={`name`}
						errors={errors}
						customValidationConfig={nameValidationConfig}
					/>
					<FormInput
						register={register}
						as={"textarea"}
						rows={3}
						label={t("lib:common.word.description")}
						name={"description"}
						errors={errors}
						style={{ minHeight: "calc(2 * 1px + 2 * 6px + 50.391px)" }}
					/>
					<div className="row">
						<div className="col-md-6">
							<FormMoneyInput
								control={control}
								currency={currency}
								label={t("modules.item_group.field.price.title")}
								allowNegative={props.allowNegativePrice}
								name={`price.amount`}
								errors={errors}
								customValidationConfig={priceValidationConfig}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								register={register}
								label={t("modules.item_group.field.sku.title")}
								name={`sku`}
								errors={errors}
							/>
						</div>
					</div>
					{organization.more?.stock_tracking ? (
						<>
							<div className="row">
								<div className="col-md-6">
									<FormMoneyInput
										control={control}
										currency={currency}
										label={t("common.word.cost")}
										name={`stock_info.default_cost.amount`}
										errors={errors}
										help={
											item?.stock_info?.external_cost
												? `${t("modules.item_group.field.external_cost.title")}: ${FormatMoney(
														item.stock_info.external_cost
												  )}`
												: ""
										}
									/>
								</div>
								<div className="col-md-6">
									<FormNumberInput
										decimalScale={5}
										label={t("modules.item_group.field.stock_amount.title")}
										name={`stock_info.stock_amount`}
										errors={errors}
										control={control}
										suffix={""}
										allowNegative={true}
									/>
								</div>
							</div>
							<FormSelectGroup
								label={t("common.word.availability")}
								path={`/${organization.id}/settings/availabilities/`}
								name="availability_id"
								errors={errors}
								onChange={onChangeAvailability}
								onCreateOption={onCreateAvailability}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								defaultValue={{
									label: defaultAvailability?.name,
									id: defaultAvailability?.id,
								}}
								loadOptions={searchAvailabilities}
								control={control}
								data-testid="availability_id"
							/>
						</>
					) : (
						<div className="row">
							<div className="col-md-6">
								<FormMoneyInput
									control={control}
									currency={currency}
									label={t("common.word.cost")}
									name={`stock_info.default_cost.amount`}
									errors={errors}
									help={
										item?.stock_info?.external_cost
											? `${t("modules.item_group.field.external_cost.title")}: ${FormatMoney(
													item.stock_info.external_cost
											  )}`
											: ""
									}
								/>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<FormSelectGroup
										label={t("common.word.availability")}
										path={`/${organization.id}/settings/availabilities/`}
										name="availability_id"
										errors={errors}
										onChange={onChangeAvailability}
										onCreateOption={onCreateAvailability}
										getOptionLabel={(option) => option.label}
										getOptionValue={(option) => option.id}
										defaultValue={{
											label: defaultAvailability?.name,
											id: defaultAvailability?.id,
										}}
										loadOptions={searchAvailabilities}
										control={control}
										data-testid="availability_id"
									/>
								</div>
							</div>
						</div>
					)}
					<div className="row row-cols-3 align-items-center">
						<div className="col-md-6">
							<FormSelectGroup
								type="switch"
								label={t("modules.item_group.field.weighted.title")}
								control={control}
								isClearable={false}
								name="weighted_type"
								options={getWeightedVariantOptions(t)}
								errors={errors}
								data-testid="weighted_type"
							/>
						</div>
						<div className="col-md-3" style={{ marginTop: "20px" }}>
							<FormCheck
								type="switch"
								label={t("modules.item_group.field.discountable.title")}
								register={register}
								name={`discountable`}
								defaultChecked={item.discountable}
								errors={errors}
							/>
						</div>
						<div className="col-md-3" style={{ marginTop: "20px" }}>
							<FormCheck
								type="switch"
								label={t("modules.item_group.field.open_price.title")}
								register={register}
								name={`open_price`}
								defaultChecked={item.open_price}
								errors={errors}
							/>
						</div>
					</div>
					{weightedTypeSelected && (
						<div className="row">
							<div className="col-md-6">
								<FormNumberInput
									label={t("modules.item_group.field.weight.title")}
									name={"weight"}
									errors={errors}
									control={control}
									suffix={""}
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-md-6">
							<BarcodesFormSelect
								errors={props.errors}
								form={form}
								barcodes={mapBarcodesToString(item.barcodes)}
								prefix={``}
							/>
						</div>
						<div className="col-md-6">
							<FormSelectGroup
								label={t("modules.item_group.field.order_type.title")}
								name="order_types"
								errors={errors}
								control={control}
								loadOptions={() => getOrderTypes(t)}
								isMulti
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								defaultValue={props.item?.order_types?.map((orderType) => {
									return {
										id: orderType,
										label: t(`enums.orders.types.${orderType}`),
									};
								})}
								data-testid="order_types"
							/>
						</div>
					</div>
					<div className="row row-cols-3 align-items-center">
						<div className="col-md-2">
							<FormCheck
								type="switch"
								register={register}
								style={{ marginTop: "20px" }}
								label={t("modules.item_group.field.suspension.title")}
								name="suspended"
								errors={errors}
							/>
						</div>
						{watch("suspended") && (
							<>
								<div className="col-md-5">
									<FormDatePicker
										errors={errors}
										control={control}
										name="suspension.until"
										label={t("modules.item_group.field.untill.title")}
									/>
								</div>
								<div className="col-md-5">
									<FormInput
										name="suspension.reason"
										errors={errors}
										register={register}
										label={t("modules.item_group.field.reason.title")}
									/>
								</div>
							</>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{props.item ? t("common.action.save", { ns: "lib" }) : t("common.action.add", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.handleClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};
export default ItemModalForm;
