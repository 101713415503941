import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { delay } from "go-core";
import { FormInput, registerObject } from "go-form";
import { FormErrorMessage } from "go-form/components/FormErrorMessage";
import EditAction from "../../../../../../../../../../components/Common/CommonActions/EditAction/EditAction";
import RemoveAction from "../../../../../../../../../../components/Common/CommonActions/RemoveAction/RemoveAction";
import { ReactComponent as SelectedMoreSvg } from "../../../../../../../../../../images/svg/menu/dots-white.svg";
import { ReactComponent as MoreSvg } from "../../../../../../../../../../images/svg/menu/dots.svg";
import { ReactComponent as TickSvg } from "../../../../../../../../../../images/svg/menu/tick.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../images/svg/sortable-handle.svg";
import { MenuApi, MenuPageApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<MenuApi>;
	index: number;
	handleRemovePage: (index: number) => void;
	handleChangePage: (index: number) => void;
	handleChangeName: (index: number, name: string) => void;
	item: MenuPageApi;
	activePageIndex: number;
}

const MenuGridPage = (props: Props): JSX.Element => {
	const index = props.index;
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
		getValues,
		setValue,
	} = props.form;
	const [editMode, setEditMode] = useState(false);
	const active = props.activePageIndex === index;
	const handleChangeName = () => {
		setEditMode(false);
		const name: string = getValues(`pages.${index}.name`);
		props.handleChangeName(index, name);
	};
	registerObject(register, `pages.${index}`, ["position"]);
	setValue(`pages.${index}.position`, index);
	const inputPrefix = `pages.${index}.name`;

	const changeName = async () => {
		setEditMode(true);
		await delay(100);
		document.getElementsByName(inputPrefix)[0]?.focus();
	};

	useEffect(() => {
		document.getElementsByName(inputPrefix)[0]?.focus();
	}, [editMode]);

	const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === "Enter") {
			evt.preventDefault();
			handleChangeName();
		}
	};

	return (
		<div className="menu-page">
			<>
				<div className="sortable-handler">
					<SortableHandleSvg />
				</div>
				{editMode ? (
					<Form.Group className="form-group" controlId={inputPrefix}>
						<FormInput
							errors={errors}
							defaultValue={props.item.name}
							onKeyPress={(evt: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(evt)}
							onBlur={() => handleChangeName()}
							name={inputPrefix}
							register={register}
							className={`menu-page-input form-control`}
							type="text"
						/>
						<FormErrorMessage errors={errors} name={inputPrefix} />
					</Form.Group>
				) : (
					<>
						<div style={{ display: "none" }}>
							<FormInput
								name={`pages.${index}.name`}
								register={register}
								errors={errors}
								className={`menu-page-input form-control`}
								type="text"
							></FormInput>
						</div>
						<Button
							variant={active ? "primary" : "light"}
							className="add-menu-page"
							style={{ minHeight: "40px" }}
							onDoubleClick={() => setEditMode(true)}
							onClick={() => props.handleChangePage(index)}
						>
							{props.item.name}
						</Button>
					</>
				)}

				{editMode ? (
					<div className="page-name-edit">
						<TickSvg onClick={() => handleChangeName()} />
					</div>
				) : (
					<Dropdown as={ButtonGroup} className="page-context-menu">
						<Dropdown.Toggle as={active ? SelectedMoreSvg : MoreSvg} />
						<Dropdown.Menu>
							<Dropdown.Item onClick={changeName}>
								<EditAction name={t("common.action.change_name")} />
							</Dropdown.Item>
							<Dropdown.Item onClick={() => props.handleRemovePage(index)}>
								<RemoveAction />
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)}
			</>
		</div>
	);
};
export default MenuGridPage;
