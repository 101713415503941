import { TFunction } from "react-i18next";
import { Flash } from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { PosReportSettingsApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

export const exportPosReportPdf = async (
	id: number,
	data: PosReportSettingsApi | undefined,
	t: TFunction,
	addFlash: (_: Flash) => void
): Promise<void> => {
	if (!data) {
		try {
			const res = await api.organization().downloadPosReport(id);
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				`${t(`components.generate_pdf_modal.pos_report.export_config.filename`)}_${id}.pdf`
			);
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	} else {
		try {
			const res = await api.organization().downloadPosReportAdvanced(id, data);
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				`${t(`components.generate_pdf_modal.pos_report.export_config.filename`)}_${id}.pdf`
			);
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	}
};

export const printPosReportPdf = async (
	id: number,
	data: PosReportSettingsApi | undefined,
	t: TFunction,
	addFlash: (_: Flash) => void
): Promise<void> => {
	if (!data) {
		try {
			const res = await api.organization().downloadPosReport(id);
			const fileURL = URL.createObjectURL(res.data);
			const a: HTMLAnchorElement = document.createElement("a");
			a.href = fileURL;
			a.target = "_blank";
			document.body.appendChild(a);
			a.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	} else {
		try {
			const res = await api.organization().downloadPosReportAdvanced(id, data);
			const fileURL = URL.createObjectURL(res.data);
			const a: HTMLAnchorElement = document.createElement("a");
			a.href = fileURL;
			a.target = "_blank";
			document.body.appendChild(a);
			a.click();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	}
};
