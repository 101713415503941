import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch } from "react-router-dom";
import Page404 from "go-app/components/Page404/Page404";
import ClientsNavigation from "./components/ClientsNavigation";
import OrganizationClientsClientGroupsPage from "./pages/ClientGroups";
import OrganizationClientsIndexPage from "./pages/Index";

const OrganizationClientsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="page-container">
			<ClientsNavigation />
			<Switch>
				<Route path={`${props.match.url}/client-groups`} component={OrganizationClientsClientGroupsPage} />
				<Route path={`${props.match.url}/`} component={OrganizationClientsIndexPage} />
				<Route component={Page404} />
			</Switch>
		</div>
	);
};
export default OrganizationClientsPage;
