import React, { FC, JSX, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import FormatDate, { convertUTCDateToLocalDate } from "go-core/components/Formatters/FormatDate";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as EditVG } from "../../../../../../../../../../../images/svg/liveOrders/pen.svg";
import { ReactComponent as PlusSVG } from "../../../../../../../../../../../images/svg/liveOrders/plus.svg";
import { LiveOrderApi } from "../../../../../../../../../../../services/Api/Organization/types";
import ChangeTransactionPaymentMethod from "../../../../../../Sales/components/ChangeTransactionPaymentMethod";
import LiveOrderListItemErrorNotification from "../../../../../components/LiveOrderListItemErrorNotification";
import LiveOrdersListItem from "../../../../../components/LiveOrdersListItem";
import { OrdersContext } from "../../../../../services/context";
import {
	areDeliveryCoordinates,
	getDeliveryTimeInSeconds,
	isDeliveryAddress,
	isDeliveryFullAddress,
} from "../../../../../services/orderDelivery";
import { getClassNameDependingOnOrderStatus } from "../../../../../services/orderStatus";
import { liveOrderParamsInclude } from "../../../../../services/orderSynchronizer";
import LiveOrdersAssignDeliveryEmployeeModal from "./AssignDeliveryEmployee/LiveOrderAssignDeliveryEmployeeModal";
import LiveOrderChangeDateModal from "./ChangeCompletionDate/LiveOrderChangeDateModal";
import LiveOrdersOrderItems from "./LiveOrdersOrderItems";
import LiveOrdersOrderPreviewFooter from "./LiveOrdersOrderPreviewFooter";
import LiveOrderPreparationStatusSelect from "./PreparationStatusSelect/LiveOrderPreparationStatusSelect";

interface Props {
	order: LiveOrderApi;
	setOrder: (order: LiveOrderApi) => void;
	setFocusedOrder?: (order: LiveOrderApi | undefined) => void;
	orderListRoutePath: string;
	map?: any;
}

const LiveOrdersOrderPreview: FC<Props> = ({ order, setFocusedOrder, setOrder, map, orderListRoutePath }) => {
	const isDeliveryEmployee = !!order?.delivery?.delivery_employee?.name;
	const { t } = useTranslation();
	const orders = useContext(OrdersContext);
	const [showAssignDeliveryEmployeeModal, setShowAssignDeliveryEmployeeModal] = useState<boolean>(false);
	const isOrderRemoved = order.status !== "OPENED" && order.status !== "CLOSED" && order.status !== "EXTERNAL";
	const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] = useState(false);
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const location = useLocation();
	const [showChangeCompletionDateModal, setShowChangeCompletionDateModal] = useState(false);
	const [showChangePreparationStatusDateModal, setShowChangePreparationStatusDateModal] = useState(false);

	const getFilteredTransactions = () => {
		return (order?.transactions || []).filter(
			(transaction) =>
				(transaction.status === "SUCCESS" || transaction.status === "OPEN" || transaction.status === "NEW") &&
				transaction.type === "PAY_IN" &&
				transaction.attached
		);
	};

	const getPaymentMethods = () => {
		const filteredTransactions = getFilteredTransactions();

		const paymentMethodElements: JSX.Element[] = [];
		filteredTransactions.forEach((transaction, index) => {
			paymentMethodElements.push(
				<React.Fragment key={`transaction-`}>
					<span className={`${transaction.status === "SUCCESS" ? "" : "text-danger"}`}>
						{transaction.payment_method_name}
					</span>
					{index !== filteredTransactions.length - 1 ? ", " : ""}
				</React.Fragment>
			);
		});

		if (paymentMethodElements.length > 0) return paymentMethodElements;
		return "-";
	};

	const isAssignDeliveryEmployeeButtonVisible = () => {
		return order.type === "DELIVERY" && !isOrderRemoved;
	};

	const handleShowOnMap = () => {
		if (!location.pathname.includes("map")) {
			history.push(`/${organization.id}/live_orders/map/orders/${order.id}`);
		} else {
			const position = [order.delivery.coordinates.latitude, order.delivery.coordinates.longitude];
			map.flyTo(position, map.getZoom(), { duration: 1 });
		}
	};

	const drawDeliveryAddress = () => {
		if (order.type === "PICK_UP") return <h5>{t("enums.orders.types.PICK_UP")}</h5>;

		if (order.type === "DINE_IN") {
			let title = "";
			if (order.table?.name || order.table?.room_name) {
				if (order.table?.room_name) {
					title = order.table.room_name;
				}
				if (order.table?.name) {
					if (title !== "") title += `, ${order.table.name}`;
					else title = order.table.name;
				}
			} else {
				title = t("enums.orders.types.DINE_IN");
			}

			return <h5>{title}</h5>;
		}

		if (order.type === "ROOM_SERVICE") {
			return (
				<h5>
					`${t("modules.live_order.field.room.title")}: ${order.table?.room_name}`
				</h5>
			);
		}

		if (order.type === "DELIVERY") {
			const isFullAddress = isDeliveryFullAddress(order);
			const isAddress = isDeliveryAddress(order);
			const areCoordinates = areDeliveryCoordinates(order);
			if (isFullAddress) {
				return (
					<>
						<h5>
							<FormatAddress address={order.delivery.address} />
						</h5>
						<h5 className="text-primary cursor-pointer" onClick={handleShowOnMap}>
							{t("modules.live_order.action.show_on_map.title")}
						</h5>
					</>
				);
			}
			if (isAddress && !areCoordinates) {
				return (
					<>
						<h5>
							<FormatAddress address={order.delivery.address} />
						</h5>
						<small className="text-danger">{t("modules.live_order.field.no_coordinates.title")}</small>
					</>
				);
			}
			if (areCoordinates && !isAddress) {
				return (
					<>
						<h5>
							<span className="text-danger">{t("modules.live_order.field.no_address.title")}</span>
							<span>{` (${order.delivery.coordinates.latitude},${order.delivery.coordinates.longitude})`}</span>
						</h5>
						<h5 className="text-primary cursor-pointer" onClick={handleShowOnMap}>
							{t("modules.live_order.action.show_on_map.title")}
						</h5>
					</>
				);
			}
			return <h5 className="text-danger">{t("modules.live_order.field.no_address.title")}</h5>;
		}
	};

	const showOrderDeletedNotification = () => {
		const orderDeletedNotifications = order.pending_terminal_notifications.filter(
			(notification) => notification.type === "ORDER_EXTERNAL_DELETED"
		);
		return orderDeletedNotifications.length > 0;
	};

	const showOrderCloseErrorNotification = () => {
		const orderCloseNotifications = order.pending_terminal_notifications.filter(
			(notification) => notification.type === "ORDER_CLOSE"
		);
		if (orderCloseNotifications.length === 0) return false;
		return orderCloseNotifications.every((notification) => notification.error);
	};

	return (
		<>
			<LiveOrdersListItem
				order={order}
				displayAsHeader
				shouldRedirect
				orderListRoutePath={orderListRoutePath}
				setFocusedOrder={setFocusedOrder}
				key={`live-orders-list-item-${order.id}-${order.execution_at}`}
			/>
			<div className="live-orders-order-preview-container flex-grow-1">
				{showOrderCloseErrorNotification() && (
					<>
						<LiveOrderListItemErrorNotification
							message={`${t("constraints.failed_to_close_and_fiscalize_the_order")} ${t(
								"modules.live_order.field.last_try.title"
							)} ${FormatDate(
								convertUTCDateToLocalDate(
									order.pending_terminal_notifications[0]?.updated_at,
									organization.timezone
								)
							)}.`}
						/>
						<hr />
					</>
				)}
				{showOrderDeletedNotification() && (
					<>
						<LiveOrderListItemErrorNotification message={`${t("constraints.external_order_deleted")}.`} />
						<hr />
					</>
				)}
				<LiveOrderPreparationStatusSelect
					status={order?.preparation_status?.status}
					order={order}
					setOrder={setOrder}
				/>
				{order.type === "DELIVERY" && (
					<>
						<div className="d-flex">
							<div>
								<span className="label">{t("modules.live_order.field.courier.title")}</span>
								<h5 className={`${!isDeliveryEmployee ? "text-danger" : "text-primary"}`}>
									{!isDeliveryEmployee
										? t("modules.live_order.field.no_driver.title")
										: order.delivery.delivery_employee.name}
								</h5>
							</div>
							{isAssignDeliveryEmployeeButtonVisible() && (
								<Button
									onClick={() => setShowAssignDeliveryEmployeeModal(true)}
									className="ms-auto my-auto icon-button"
									disabled={!orders.isOnline}
								>
									{isDeliveryEmployee ? <EditVG /> : <PlusSVG />}
								</Button>
							)}
						</div>
						<hr />
					</>
				)}

				<div className="d-flex">
					<div>
						<span className="label">{t("modules.live_order.field.payment.title")}</span>
						<h5>{getPaymentMethods()}</h5>
					</div>
					<Button
						onClick={() => setShowChangePaymentMethodModal(true)}
						className="ms-auto my-auto icon-button"
						disabled={!orders.isOnline}
					>
						{getFilteredTransactions().length > 0 ? <EditVG /> : <PlusSVG />}
					</Button>
				</div>
				<hr />

				<div className="d-flex">
					<div>
						<span className="label">{t("modules.live_order.field.completion_time_limit.title")}</span>
						<h5
							className={`${
								order?.pickup_at
									? getClassNameDependingOnOrderStatus(getDeliveryTimeInSeconds(order.pickup_at))
									: ""
							}`}
						>
							{order?.pickup_at ? FormatDate(order.pickup_at) : "-"}
						</h5>
					</div>
					<Button
						onClick={() => setShowChangeCompletionDateModal(true)}
						className="ms-auto my-auto icon-button"
					>
						<EditVG />
					</Button>
				</div>
				<hr />

				<div className="d-flex">
					<div>
						<span className="label">{t("modules.live_order.field.completion_expected_time.title")}</span>
						<h5
							className={`${
								order?.estimated_preparation_at
									? getClassNameDependingOnOrderStatus(
											getDeliveryTimeInSeconds(order.estimated_preparation_at)
									  )
									: ""
							}`}
						>
							{order?.estimated_preparation_at ? FormatDate(order.estimated_preparation_at) : "-"}
						</h5>
					</div>
					<Button
						onClick={() => setShowChangePreparationStatusDateModal(true)}
						className="ms-auto my-auto icon-button"
					>
						<EditVG />
					</Button>
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.order_received_at.title")}</span>
					<h5>{order.created_at ? FormatDate(order.created_at) : "-"}</h5>
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.delivery_address.title")}</span>
					{drawDeliveryAddress()}
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.contact_phone.title")}</span>
					<h5>{order?.contact?.phone_number ? order.contact.phone_number : "-"}</h5>
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.client_data.title")}</span>
					<h5>{order?.contact?.name ? order.contact.name : "-"}</h5>
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.order_commnet.title")}</span>
					<h5 className="comment">{order.comment ? order.comment : "-"}</h5>
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.shop.title")}</span>
					<h5>
						{orders?.organizationAddress?.address ? (
							<FormatAddress address={orders.organizationAddress.address} />
						) : (
							"-"
						)}
					</h5>
				</div>
				<hr />

				<div>
					<span className="label">{t("modules.live_order.field.order.title")}</span>
					<LiveOrdersOrderItems order={order} />
				</div>
			</div>
			<LiveOrdersOrderPreviewFooter order={order} setOrder={setOrder} orderListRoutePath={orderListRoutePath} />

			{showAssignDeliveryEmployeeModal && (
				<LiveOrdersAssignDeliveryEmployeeModal
					isShown={showAssignDeliveryEmployeeModal}
					order={order}
					onHide={() => setShowAssignDeliveryEmployeeModal(false)}
				/>
			)}
			{showChangePaymentMethodModal && (
				<ChangeTransactionPaymentMethod
					show={showChangePaymentMethodModal}
					isOrder
					handleSuccessAction={(item) => {
						orders.updateOrder(item as LiveOrderApi);
					}}
					onHide={() => setShowChangePaymentMethodModal(false)}
					id={order.id}
					transaction={order.transactions.find(
						(transaction) => transaction.status !== "ERROR" && transaction.status !== "REMOVED"
					)}
					documentNumber={order.number}
					editMode={getFilteredTransactions().length !== 0}
					price={order.total_price}
					include={liveOrderParamsInclude}
				/>
			)}

			{showChangeCompletionDateModal && (
				<LiveOrderChangeDateModal
					show={showChangeCompletionDateModal}
					onHide={() => setShowChangeCompletionDateModal(false)}
					order={order}
					mode="PICK_UP_DATE"
				/>
			)}

			{showChangePreparationStatusDateModal && (
				<LiveOrderChangeDateModal
					show={showChangePreparationStatusDateModal}
					onHide={() => setShowChangePreparationStatusDateModal(false)}
					order={order}
					mode="PREPARATION_TIME"
				/>
			)}
		</>
	);
};

export default LiveOrdersOrderPreview;

// t("enums.preparation_statuses.IN_PROGRESS");
// t("enums.preparation_statuses.READY");
// t("enums.preparation_statuses.RECEIVED");
// t("enums.preparation_statuses.DELIVERED");
// t("enums.preparation_statuses.PACKED");
// t("enums.preparation_statuses.NEW");
