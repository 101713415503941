import { OrganizationState } from "./organizations/types";
import { UserState } from "./users/types";

export const getOrganizationCategories = (): string[] => {
	return ["CLUB", "RESTAURANT", "CAFE", "FOODTRUCK", "PUB_BAR", "SUSHI", "SHOP", "OTHERS"];
};
export const isAdmin = (user: UserState): boolean | undefined => {
	return user.admin;
};
export const isInstalled = (organization: OrganizationState): boolean => {
	return !(organization?.status === "NEW" || organization?.status === "IN_PROGRESS");
};
export const isExternalAccess = (user: UserState, organization: OrganizationState): boolean | undefined => {
	return isAdmin(user) || organization.partner || organization.support;
};
