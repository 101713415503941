import { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as GermanSVG } from "../../../../../../../../images/svg/languages/de.svg";
import { ReactComponent as EnglishSVG } from "../../../../../../../../images/svg/languages/en.svg";
import { ReactComponent as PolishSVG } from "../../../../../../../../images/svg/languages/pl.svg";
import { ReactComponent as UkrainianSVG } from "../../../../../../../../images/svg/languages/uk.svg";
import { ReactComponent as VietnameseSVG } from "../../../../../../../../images/svg/languages/vi.svg";

interface Props {
	onClick: () => void;
	language: string;
	selectedLanguage: string;
}

const ButtonLanguage: FC<Props> = ({ onClick, language, selectedLanguage }) => {
	const { t } = useTranslation();

	return (
		<Button onClick={onClick} variant={`${selectedLanguage === language ? "outline-primary" : "default"}`}>
			<div className="d-flex align-items-center">
				{language === "de" && <GermanSVG className="me-1" />}
				{language === "en" && <EnglishSVG className="me-1" />}
				{language === "pl" && <PolishSVG className="me-1" />}
				{language === "uk" && <UkrainianSVG className="me-1" />}
				{language === "vi" && <VietnameseSVG className="me-1" />}
				{t(`enums.languages.${language}`, { ns: "lib" })}
			</div>
		</Button>
	);
};
export default ButtonLanguage;
