import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormInput } from "go-form";
import { CustomValidationConfig, CustomValidationConstraint, CustomValidationError } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import {
	FiscalizeOrderFormProps,
	OrderFiscalizationType,
} from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<FiscalizeOrderFormProps>;
	setErrors: React.Dispatch<React.SetStateAction<CustomValidationError<FiscalizeOrderFormProps>[]>>;
	paymentMethodOutflowType: string;
	fiscalize: boolean;
}

const LiveOrderFiscalizationTypes = ({ form, setErrors, paymentMethodOutflowType, fiscalize }: Props): JSX.Element => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const {
		watch,
		setValue,
		formState: { errors },
		register,
	} = form;

	const typeOptions: { value: OrderFiscalizationType; label: string }[] = [
		{
			value: OrderFiscalizationType.ERECEIPT,
			label: t("modules.live_order.field.e_receipt.title"),
		},
		{
			value: OrderFiscalizationType.FISCAL_RECEIPT,
			label: t("modules.live_order.field.fiscal_receipt.title"),
		},
	];

	const handleChangeOption = (type: { value: OrderFiscalizationType; label: string }) => {
		setValue("type", type.value);
	};

	const typeInputValidationConfig = useMemo(
		() => ({
			types: [
				{
					constraint: CustomValidationConstraint.REQUIRED_IF,
					isRequired: () => paymentMethodOutflowType === "WZ" && fiscalize,
				},
			],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch, fiscalize, paymentMethodOutflowType]
	) satisfies CustomValidationConfig;

	const emailInputValidationConfig = useMemo(
		() => ({
			types: [
				{
					constraint: CustomValidationConstraint.REQUIRED_IF,
					isRequired: () => watch("type") === "ERECEIPT",
				},
			],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const drawTypeOptions = () => {
		const finalTypeOptions = organization?.more?.ereceipt_enabled
			? typeOptions
			: typeOptions.filter((option) => option.value !== OrderFiscalizationType.ERECEIPT);

		return finalTypeOptions.map((typeOption) => {
			const isSelected = watch("type") === typeOption.value;
			return (
				<Button
					key={`type-${typeOption.value}`}
					onClick={() => handleChangeOption(typeOption)}
					className={`${isSelected ? "btn-primary" : "btn-light"}`}
				>
					{typeOption.label}
				</Button>
			);
		});
	};

	return (
		<div className="d-flex flex-column mt-4">
			<h5 className="mb-2">{t("modules.live_order.field.fiscalization_type.title")}</h5>
			<div className="d-flex flex-wrap gap-2">{drawTypeOptions()}</div>
			<FormInput
				name="type"
				errors={errors}
				register={register}
				hidden
				customValidationConfig={typeInputValidationConfig}
			/>
			{watch("type") === "ERECEIPT" && (
				<div className="mt-3">
					<FormInput
						name="email"
						errors={errors}
						register={register}
						customValidationConfig={emailInputValidationConfig}
						help={t("modules.live_order.field.email.help_text")}
					/>
				</div>
			)}
			<hr />
		</div>
	);
};

export default LiveOrderFiscalizationTypes;
