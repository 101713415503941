import React from "react";
import VirtualTableSearchColumn from "../../../../../../components/VirtualTable/VirtualTableSearchColumn";
import { VirtualTableColumn } from "../../../../../../components/VirtualTable/types";
import { isVirtualTableColumnHide } from "../../../../../../components/VirtualTable/utils";

interface Props {
	columns: VirtualTableColumn[];
	otherColumnsSearch: { id: string; search?: string }[];
	setOtherColumnsSearch: (otherColumnsSearch: { id: string; search?: string }[]) => void;
}

const ItemGroupGroupEditingVirtualTableHeaderSearchOtherColumnsRow = ({
	columns,
	setOtherColumnsSearch,
	otherColumnsSearch,
}: Props) => {
	const handleClearFilter = (id: string) => {
		handleSearch(id, undefined);
	};
	const getSearchValue = (columnId: string) => {
		return otherColumnsSearch.find(({ id }) => id === columnId)?.search || "";
	};
	const handleSearch = (columnId: string, value?: string) => {
		setOtherColumnsSearch(
			otherColumnsSearch.map((columnSearch) => {
				if (columnSearch.id === columnId) {
					return {
						...columnSearch,
						search: value,
					};
				}
				return columnSearch;
			})
		);
	};
	const drawColumns = () => {
		return columns.map((col) => {
			const isHide = isVirtualTableColumnHide(col.id, columns);

			return (
				<VirtualTableSearchColumn
					isEmpty={false}
					key={`search-column-${col.id}`}
					clearFilter={() => handleClearFilter(col.id)}
					searchValue={getSearchValue(col.id)}
					search={(value) => handleSearch(col.id, value)}
					isHide={isHide}
				/>
			);
		});
	};
	return <>{drawColumns()}</>;
};

export default ItemGroupGroupEditingVirtualTableHeaderSearchOtherColumnsRow;
