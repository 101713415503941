import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { FormInput, registerObject } from "go-form";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { FormMoneyInput } from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSVG } from "../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../images/svg/sortable-handle.svg";
import {
	InvoiceApi,
	InvoiceItemApi,
	InvoiceSummaryTaxApi,
} from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { getRecalculatedSummaryTaxes } from "../../utils";

interface Props {
	form: UseFormReturn<InvoiceApi>;
	setSummaryTaxes: (value: InvoiceSummaryTaxApi[]) => void;
	summaryTaxes: InvoiceSummaryTaxApi[];
}

const AdvanceFormItemsTable = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const isSortingRef = useRef(false);
	const optionsRef = useRef<any[]>([]);
	const {
		formState: { errors },
		control,
		register,
		clearErrors,
		watch,
		getValues,
		setValue,
		resetField,
	} = props.form;
	const organization = useSelector(selectOrganization);
	const watchedItems = watch("order_items");
	const watchedType = watch("type");
	const itemsAsString = JSON.stringify(watchedItems);
	const currency = organization.currency || "PLN";
	const { fields, remove, append } = useFieldArray({
		control,
		name: "order_items",
		keyName: "key",
	});
	const isMobile = useWindowSize().isMobile;

	useEffect(() => {
		recalculateSummaryTaxes(watchedItems, watchedType);
	}, [watchedType]);

	useEffect(() => {
		fields.forEach((field, index) => {
			registerObject(register, `order_items.${index}.tax`, ["amount", "name", "code", "id"]);
			setValue(`order_items.${index}.position`, index);
			setValue(`order_items.${index}.tax`, field.tax);
		});
		recalculateSummaryTaxes(watchedItems, watchedType);
	}, []);

	useEffect(() => {
		fields.forEach((field, index) => {
			registerObject(register, `order_items.${index}.tax`, ["amount", "name", "code", "id"]);
			setValue(`order_items.${index}.position`, index);
			setValue(`order_items.${index}.tax`, field.tax);
			setValue(`order_items.${index}.tax_id`, field.tax?.id);
		});
		recalculateSummaryTaxes(watchedItems, watchedType);
	}, [fields]);

	useEffect(() => {
		if (wasLastItemUpdated(watchedItems)) {
			append({ position: fields.length });
		}
		watchedItems.forEach((item, index) => {
			if (item.tax?.id !== item.tax_id && item.tax_id !== null) {
				setValue(`order_items.${index}.tax_id`, item.tax?.id);
			}
			if (item.price?.amount === undefined) {
				setValue(`order_items.${index}.price.amount`, null);
			}
		});
	}, [itemsAsString]);

	const searchTaxes = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getTaxesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const updatePositions = (items: InvoiceItemApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items.forEach((item, index) => {
			item.position = index;
		});
		items.forEach((opt, index) => {
			registerObject(register, `order_items.${index}.tax`, ["amount", "name", "code", "id"]);
			registerObject(register, `order_items.${index}.price`, ["amount"]);
			setValue(`order_items.${index}.price`, {
				amount: opt.price?.amount,
				currency: organization?.currency || "",
			});
			setValue(`order_items.${index}`, opt, { shouldDirty: true });
		});
	};

	const recalculateSummaryTaxes = (
		items: InvoiceItemApi[],
		type?: string,
		index?: number,
		val?: number,
		quantity?: string
	) => {
		if (index || index === 0) {
			clearErrors(`order_items.${index}.price`);
		}
		const newTaxes = getRecalculatedSummaryTaxes(items, currency, type, index, val, quantity);
		props.setSummaryTaxes(newTaxes);
	};

	const calculateSummary = (quantity: number, money: Record<string, any>) => {
		if (money && money.amount) {
			const newAmount = quantity * parseFloat(money.amount);
			return newAmount.toFixed(2);
		}
		return `0.00`;
	};

	const wasLastItemUpdated = (items: InvoiceItemApi[]) => {
		const lastItem = items[items.length - 1];
		return !!(
			lastItem?.gtu ||
			lastItem?.name ||
			lastItem?.pkwiu ||
			lastItem?.price?.amount ||
			lastItem?.quantity ||
			lastItem?.tax?.id ||
			lastItem?.volume
		);
	};

	const onChangeTax = (obj: any, obj2: any, index: number) => {
		registerObject(register, `order_items.${index}.tax`, ["amount", "name", "code", "id"]);
		setValue(`order_items.${index}.tax`, obj2);
		setValue(`order_items.${index}.tax_id`, obj, { shouldDirty: true });
		recalculateSummaryTaxes(watchedItems, watchedType);
	};

	const onRemove = (index: number) => {
		remove(index);
	};

	const getGtus = async (search: string, params: Record<string, any>) => {
		const res = await api.organization().getInvoiceGtu(search, params);
		return res.map((item: string) => ({
			id: item,
			label: item,
		}));
	};

	const parseOptionValue = (option: any) => {
		optionsRef.current = optionsRef.current.find((o) => o.id === option.id)
			? [...optionsRef.current]
			: [...optionsRef.current, option];
		return option.label;
	};

	const getItemIdForItem = (index: number) => {
		const name = getValues(`order_items.${index}.name`);
		const option = optionsRef.current.find((o) => o.label === name);
		if (option) {
			return option.id;
		}
		return getValues(`order_items.${index}.item_id`);
	};

	const updateItemId = (index: number) => {
		const name = getValues(`order_items.${index}.name`);
		const option = optionsRef.current.find((o) => o.label === name);
		if (!option) {
			resetField(`order_items.${index}`);
			setValue(`order_items.${index}.item_id`, undefined);
			setValue(`order_items.${index}.price.amount`, null);
			setValue(`order_items.${index}.name`, name);
		} else {
			setValue(`order_items.${index}.item_id`, getItemIdForItem(index));
			setValue(`order_items.${index}.gtu`, option?.gtu);
			setValue(`order_items.${index}.price.amount`, option?.price?.amount);
			onChangeTax(option?.tax?.id, option?.tax, index);
		}
	};

	const searchItems = (search: string, params: Record<string, string>, options?: Record<string, string>) => {
		return api.organization().getItemsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const taxesSummary = props.summaryTaxes.reduce(
		(acc, val) => acc + (val.price_tax?.amount ? val.price_tax?.amount : 0),
		0
	);

	const grossSummary = props.summaryTaxes.reduce(
		(acc, val) => acc + (val.price_gross?.amount ? val.price_gross?.amount : 0),
		0
	);

	const netSummary = props.summaryTaxes.reduce(
		(acc, val) => acc + (val.price_net?.amount ? val.price_net?.amount : 0),
		0
	);

	return (
		<fieldset className="form-group">
			<div className={"d-flex align-items-center mb-1"}>
				<h5>{watch("document_type") === "ADVANCE" && t("modules.invoice.field.orders.title")}</h5>
			</div>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className={"table table-form invoice-items"}>
					<thead>
						<tr>
							<th className="action" />
							<th style={{ minWidth: isMobile ? "200px" : "unset" }} className={"w-40"}>
								{t("modules.invoice.field.name.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "unset" }} className={"w-10"}>
								{t("modules.invoice.field.pkwiu.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "unset" }} className={"w-10"}>
								{t("modules.invoice.field.gtu.title")}
							</th>
							<th style={{ minWidth: isMobile ? "120px" : "9%" }}>
								{getValues().type === "GROSS"
									? t("modules.invoice.field.unit_price_gross.title")
									: t("modules.invoice.field.unit_price_net.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "10%" }}>
								{t("modules.invoice.field.vat_rate.title")} <br />{" "}
								{t("modules.invoice.field.vat.title")}
							</th>
							<th style={{ minWidth: isMobile ? "100px" : "7%" }}>{t("common.word.quantity")}</th>
							<th style={{ minWidth: isMobile ? "100px" : "unset" }}>
								{t("modules.invoice.field.measure.title")}
							</th>
							<th style={{ textAlign: "right", minWidth: isMobile ? "120px" : "unset" }}>
								{getValues().type === "GROSS"
									? t("modules.invoice.field.total_price_gross.title")
									: t("modules.invoice.field.total_price_net.title")}
							</th>
							<th className="action" />
						</tr>
					</thead>
					{
						<ReactSortable
							handle=".sortable-handler"
							tag="tbody"
							list={watchedItems}
							onUpdate={() => (isSortingRef.current = true)}
							setList={(fields) => updatePositions(fields)}
						>
							{watchedItems.map((field, index) => {
								return (
									<tr key={fields[index].key}>
										<td className="action">
											<SortableHandleSvg className="sortable-handler" />
											<FormInput
												type="hidden"
												errors={errors}
												register={register}
												name={`order_items.${index}.position`}
												defaultValue={index}
											/>
											<FormInput
												type="hidden"
												errors={errors}
												register={register}
												name={`order_items.${index}.id`}
												defaultValue={field.id}
											/>
										</td>
										<td>
											<FormSelectGroup
												name={`order_items.${index}.name`}
												errors={errors}
												forceCreateOption
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => parseOptionValue(option)}
												onChange={() => updateItemId(index)}
												defaultValue={{
													label: field?.name,
												}}
												loadOptions={searchItems}
												control={control}
												data-testid={`order_items.${index}.name`}
												forceInputChange
												onBlurShouldForceChangeOption
											/>
											<FormInput
												type={"hidden"}
												errors={errors}
												register={register}
												name={`order_items.${index}.item_id`}
												defaultValue={getItemIdForItem(index)}
											/>
										</td>
										<td>
											<FormInput
												register={register}
												defaultValue={fields[index].pkwiu}
												name={`order_items.${index}.pkwiu`}
												errors={errors}
											/>
										</td>
										<td>
											<FormSelectGroup
												errors={errors}
												name={`order_items.${index}.gtu`}
												isClearable={true}
												control={control}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
												defaultValue={{ id: field.gtu, label: field.gtu }}
												loadOptions={getGtus}
												data-testid={`order_items.${index}.gtu`}
											/>
										</td>
										<td>
											<FormMoneyInput
												onChange={(val) =>
													recalculateSummaryTaxes(watchedItems, watchedType, index, val)
												}
												errors={errors}
												name={`order_items.${index}.price.amount`}
												currency={currency}
												control={control}
											/>
										</td>
										<td>
											<FormSelectGroup
												name={`order_items.${index}.tax_id`}
												errors={errors}
												onChange={(obj, obj2) => onChangeTax(obj, obj2, index)}
												getOptionLabel={(option) => option.label}
												isClearable={false}
												getOptionValue={(option) => option.id}
												defaultValue={{
													label: field.tax?.name,
													id: field.tax?.id,
												}}
												loadOptions={searchTaxes}
												control={control}
												data-testid={`order_items.${index}.tax_id`}
											/>
										</td>
										<td>
											<FormNumberInput
												suffix=""
												onChange={(val) =>
													recalculateSummaryTaxes(
														watchedItems,
														watchedType,
														index,
														parseFloat(`${val}`),
														"quantity"
													)
												}
												control={control}
												defaultValue={Number(fields[index].quantity)}
												name={`order_items.${index}.quantity`}
												errors={errors}
											/>
										</td>
										<td>
											<FormInput
												register={register}
												defaultValue={fields[index].volume}
												name={`order_items.${index}.volume`}
												errors={errors}
											/>
										</td>
										<td className={"nowrap"} style={{ textAlign: "right" }}>
											{calculateSummary(field.quantity || 0, field.price)}
										</td>
										<td className="action">
											{!(fields.length === 1 && index === 0) && index !== fields.length - 1 && (
												<RemoveSVG className={"icon"} onClick={() => onRemove(index)} />
											)}
										</td>
									</tr>
								);
							})}
						</ReactSortable>
					}
				</table>
			</div>
			<Button variant="add" type="button" onClick={() => append({ position: fields.length })}>
				+ {t("common.action.add", { ns: "lib" })}
			</Button>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className={"table table-form invoice-items-summary"}>
					<thead>
						<tr>
							<th className={"action"} />
							<th className={"w-60 summary-header"}>{t("common.word.summary", { ns: "lib" })}</th>
							<th style={{ width: "9%" }}>
								{t("common.word.value")} <br /> {t("modules.invoice.field.net.title")}
							</th>
							<th style={{ width: "10%" }}>
								{t("modules.invoice.field.vat_rate.title")} <br />{" "}
								{t("modules.invoice.field.vat.title")}
							</th>
							<th>
								{t("common.word.amount")} <br /> {t("modules.invoice.field.vat.title")}
							</th>
							<th />
							<th style={{ textAlign: "right", paddingRight: 0 }}>
								{t("common.word.value")} <br /> {t("modules.invoice.field.gross.title")}
							</th>
							<th className={"action"} />
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={"action pb-0"} />
							<td style={{ verticalAlign: "top" }} className={"pl-0"}>
								{t("modules.invoice.field.summary_taxes.title")}
							</td>
							<td className={"nowrap pb-0"}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{FormatMoney({ ...tax.price_net, currency })}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}>{FormatMoney({} as MoneyApi)}</span>
									)}
								</div>
							</td>
							<td className={"nowrap pb-0"}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{tax.tax_name ?? tax.tax_amount}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}></span>
									)}
								</div>
							</td>
							<td className={"nowrap pb-0"}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{FormatMoney({ ...tax.price_tax, currency })}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}>{FormatMoney({} as MoneyApi)}</span>
									)}
								</div>
							</td>
							<td />
							<td className={"nowrap pb-0"} style={{ textAlign: "right" }}>
								<div className={"d-flex flex-column"}>
									{props.summaryTaxes.length > 0 ? (
										props.summaryTaxes.map((tax, index) => {
											return (
												<span key={index} className={"mb-1"}>
													{FormatMoney({ ...tax.price_gross, currency })}
												</span>
											);
										})
									) : (
										<span className={"mb-1"}>{FormatMoney({} as MoneyApi)}</span>
									)}
								</div>
							</td>
							<td className={"action"} />
						</tr>
						<tr>
							<td className={"action"} />
							<td style={{ verticalAlign: "top", paddingTop: 0, paddingLeft: 0 }}>
								<strong>{t("common.word.altogether")}</strong>
							</td>
							<td className={"nowrap"} style={{ paddingTop: 0 }}>
								<div className={"d-flex flex-column"}>
									<strong>{FormatMoney({ amount: netSummary, currency })}</strong>
								</div>
							</td>
							<td className={"nowrap"} style={{ paddingTop: 0 }}></td>
							<td className={"nowrap"} style={{ paddingTop: 0 }}>
								<div className={"d-flex flex-column"}>
									<strong>{FormatMoney({ amount: taxesSummary, currency })}</strong>
								</div>
							</td>
							<td />
							<td className={"nowrap"} style={{ textAlign: "right", paddingTop: 0 }}>
								<div className={"d-flex flex-column"}>
									<strong>{FormatMoney({ amount: grossSummary, currency })}</strong>
								</div>
							</td>
							<td className={"action"} />
						</tr>
					</tbody>
				</table>
			</div>
		</fieldset>
	);
};
export default AdvanceFormItemsTable;
