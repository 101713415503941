import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingContainer } from "go-core/components/Loading";

interface Props {
	loading: boolean;
	availableVariables?: Record<string, any>;
}

export const NotificationTemplateAvailableVariables = ({ loading, availableVariables }: Props) => {
	const { t } = useTranslation();
	const availableVariablesAsArray = availableVariables ? Object.entries(availableVariables) : [];

	if (loading) return <LoadingContainer />;
	if (availableVariablesAsArray.length === 0) return null;

	return (
		<Card className="my-3">
			<Card.Title className="text-center my-0 pt-3">
				{t("modules.notification_template.field.available_variables.title")}
			</Card.Title>
			<Card.Body className="pt-3">
				{availableVariablesAsArray.map(([variableName, variableValue]) => {
					return (
						<div key={variableName}>
							<strong>{variableName}</strong>: {variableValue}
						</div>
					);
				})}
			</Card.Body>
		</Card>
	);
};
