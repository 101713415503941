import React from "react";
import { Form } from "react-bootstrap";
import { get } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiError } from "go-core/api/types";

interface FormErrorMessageApiProps {
	errors: ApiError[];
	field?: string;
}

export const FormErrorMessageApi = (props: FormErrorMessageApiProps): JSX.Element | null => {
	const errors = props.errors;
	const { t } = useTranslation();
	if (Array.isArray(errors) && props.field) {
		const error = errors.find((f) => f.field === props.field);
		if (error) {
			return (
				<Form.Control.Feedback type="invalid">
					<div>{t(`constraints.${error.code}`, { ns: ["translation", "lib"] })}</div>
				</Form.Control.Feedback>
			);
		}
	}
	return null;
};

export const FormErrorMessage = (props: { name?: string; errors: any }): JSX.Element | null => {
	const { errors, name } = props;
	const { t } = useTranslation();
	if (name) {
		const error = get(errors, name);
		if (!error) {
			return null;
		}
		const messages = error.types;
		const validationArguments = error.arguments;
		const options = {
			ns: ["translation", "lib"],
		} as any;
		if (validationArguments) {
			Object.entries(validationArguments).forEach((entry) => {
				const [key, value] = entry;
				options[key] = value;
			});
		}

		return (
			<Form.Control.Feedback type="invalid">
				{Object.entries(messages || {}).map(([type]) => {
					const value = type.split("_")?.slice(-1);
					if (value[0] && !isNaN(Number(value[0]))) {
						const newType = type.split("_").slice(0, -1).join("_");
						options.count = Number(value[0]);
						return <div key={type}>{t(`constraints.${newType}`, options)}</div>;
					}
					return <div key={type}>{t(`constraints.${type}`, options)}</div>;
				})}
			</Form.Control.Feedback>
		);
	}
	return null;
};
