import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationSettingsRoomsPointOfSalesIndexPage from "./pages/Index";

const OrganizationSettingsRoomsPointOfSalesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className={"content"}>
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationSettingsRoomsPointOfSalesIndexPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSettingsRoomsPointOfSalesPage;
