import React, { FC, useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as DropdownSVG } from "go-core/images/svg/dropdown.svg";
import { SubNavigationNonMobile } from "./SubNavigationNonMobile";

interface Props {
	title: string;
	subtitle?: string;
	getRouteName: () => string;
}

const SubNavigation: FC<Props> = (props) => {
	const size = useWindowSize();
	const [collapseOpen, setCollapseOpen] = useState(!size.isMobile);

	useEffect(() => {
		if (size.isMobile) setCollapseOpen(false);
	}, [window.location.href]);

	return (
		<div className={`contextual-navigation ${collapseOpen ? "" : "collapse-close"}`}>
			{!size.isMobile ? (
				<SubNavigationNonMobile
					collapseOpen={collapseOpen}
					title={props.title}
					subtitle={props.subtitle}
					setCollapseOpen={setCollapseOpen}
				>
					{props.children}
				</SubNavigationNonMobile>
			) : (
				<>
					<div
						className="navigation-header"
						id="contextual-navigation-header"
						onClick={() => props.children && setCollapseOpen(!collapseOpen)}
					>
						<h2>
							{props.getRouteName()}
							{props.subtitle && <small>{props.subtitle}</small>}
						</h2>
						{props.children && <DropdownSVG className="dropdown-icon" />}
					</div>
					{props.children && (
						<Collapse in={collapseOpen}>
							<div className="navigation-collapse">{props.children}</div>
						</Collapse>
					)}
				</>
			)}
		</div>
	);
};

export default SubNavigation;
