import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { api } from "../../../../../../../services/Api/api";

interface Props {
	show: boolean;
	onHide: () => void;
}

interface FormProps {
	email: string;
	yearly_budget: number;
	date: string;
}

const DepartmentReportModal = ({ show, onHide }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useForm<FormProps>({
		criteriaMode: "all",
	});
	const { addFlash, addSuccessFlash } = useFlash();
	const {
		formState,
		formState: { errors },
		setError,
		handleSubmit,
		control,
		register,
		watch,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";

	const onSubmit = handleSubmit(async (data: FormProps) => {
		if (!validateCustomErrors()) return;

		setLoading(true);
		try {
			await api.organization().getDepartmentReport(data);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const inputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
		}),
		[setErrors]
	) satisfies CustomValidationConfig;

	const emailInputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	return (
		<Modal show={show} onHide={onHide}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.report.field.department_report.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormDatePicker
						errors={errors}
						control={control}
						name="date"
						label={t("lib:common.word.date")}
						customValidationConfig={inputValidationConfig}
					/>
					<FormMoneyInput
						errors={errors}
						name="yearly_budget"
						control={control}
						currency={currency}
						customValidationConfig={inputValidationConfig}
						label={t("modules.report.field.yearly_budget.title")}
					/>
					<FormInput
						name="email"
						errors={errors}
						register={register}
						label={t("lib:common.word.email")}
						customValidationConfig={emailInputValidationConfig}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("lib:common.action.send")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default DepartmentReportModal;
