import { LiveOrderApi, OrderPreparationStatusName } from "../../../../../../../services/Api/Organization/types";

export const statusColors = {
	YELLOW: "#FFC107",
	GRAY: "#BEC8D1",
	GREEN: "#28A745",
	RED: "#DC3545",
};

export const preparationStatusColors = {
	YELLOW: "#FEE170",
	WHITE: "#FFFFFF",
	GRAY: "#BEC8D1",
	GREEN: "#80E08A",
	BLUE: "#A2D5FF",
	PURPLE: "#B8A4F8",
};

export const getOrderStatusColor = (deliveryTimeInSeconds: number): string => {
	let color: string = "";
	const ONE_HOUR = 3600;
	const HALF_HOUR = 1800;
	const QUARTER_OF_HOUR = 900;

	if (deliveryTimeInSeconds > 3600) color = statusColors.GRAY;
	else if (deliveryTimeInSeconds > HALF_HOUR && deliveryTimeInSeconds <= ONE_HOUR) color = statusColors.GREEN;
	else if (deliveryTimeInSeconds > QUARTER_OF_HOUR && deliveryTimeInSeconds <= HALF_HOUR) color = statusColors.YELLOW;
	else if (deliveryTimeInSeconds <= QUARTER_OF_HOUR) color = statusColors.RED;
	else color = statusColors.RED;

	return color;
};

export const getOrderPreparationStatusColor = (status: OrderPreparationStatusName): string => {
	let color = "";

	if (status === OrderPreparationStatusName.NEW) color = preparationStatusColors.WHITE;
	if (status === OrderPreparationStatusName.IN_PROGRESS) color = preparationStatusColors.YELLOW;
	if (status === OrderPreparationStatusName.READY) color = preparationStatusColors.GREEN;
	if (status === OrderPreparationStatusName.PACKED) color = preparationStatusColors.BLUE;
	if (status === OrderPreparationStatusName.RECEIVED) color = preparationStatusColors.GRAY;
	if (status === OrderPreparationStatusName.DELIVERED) color = preparationStatusColors.PURPLE;

	return color;
};

export const getClassNameDependingOnOrderStatus = (deliveryTimeInSeconds: number): string | undefined => {
	switch (getOrderStatusColor(deliveryTimeInSeconds)) {
		case statusColors.GRAY:
			return "more-than-hour-to-delivery";
		case statusColors.GREEN:
			return "between-hour-and-half-hour-to-delivery";
		case statusColors.YELLOW:
			return "between-half-hour-and-quarter-hour-to-delivery";
		case statusColors.RED:
			return "less-than-quarter-hour-to-delivery";
	}
};

export const isOrderActive = (order: LiveOrderApi): boolean => {
	return (
		order?.preparation_status?.status === OrderPreparationStatusName.NEW ||
		order?.preparation_status?.status === OrderPreparationStatusName.IN_PROGRESS ||
		order?.preparation_status?.status === OrderPreparationStatusName.READY ||
		order?.preparation_status?.status === OrderPreparationStatusName.PACKED ||
		order?.preparation_status?.status === OrderPreparationStatusName.RECEIVED ||
		!order.preparation_status
	);
};

export const getActiveOrders = (orders: LiveOrderApi[]): LiveOrderApi[] => {
	return orders.filter(
		(order) =>
			order?.preparation_status?.status === OrderPreparationStatusName.NEW ||
			order?.preparation_status?.status === OrderPreparationStatusName.IN_PROGRESS ||
			order?.preparation_status?.status === OrderPreparationStatusName.READY ||
			order?.preparation_status?.status === OrderPreparationStatusName.PACKED ||
			order?.preparation_status?.status === OrderPreparationStatusName.RECEIVED ||
			!order.preparation_status
	);
};

export const orderActivePreparationStatuses = [
	OrderPreparationStatusName.NEW,
	OrderPreparationStatusName.IN_PROGRESS,
	OrderPreparationStatusName.READY,
	OrderPreparationStatusName.PACKED,
	OrderPreparationStatusName.RECEIVED,
];
