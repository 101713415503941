import { ClientDetailsApi } from "go-component/components/ClientDetails/services/types";
import { AdminJobApi } from "go-component/services/types";
import { parseApiData } from "go-core/api/parseApiData";
import { restSecurityApi } from "go-security/services/Api/api";

class adminApi {
	static getClientDetails = (
		params?: Record<string, any>,
		options?: Record<string, any>
	): Promise<ClientDetailsApi[]> => {
		return parseApiData(
			restSecurityApi.get(`/security/client_details`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	};

	static addClientDetail = (data: ClientDetailsApi): Promise<ClientDetailsApi> => {
		return parseApiData(restSecurityApi.post(`/security/client_details`, data));
	};

	static editClientDetail = (clientDetailId: string, data: ClientDetailsApi): Promise<ClientDetailsApi> => {
		return parseApiData(restSecurityApi.put(`/security/client_details/${clientDetailId}`, data));
	};

	static deleteClientDetail = (clientDetailId: string): Promise<{}> => {
		return parseApiData(restSecurityApi.delete(`/security/client_details/${clientDetailId}`));
	};

	static getClientScopes = (params?: Record<string, any>, options?: Record<string, any>): Promise<string[]> => {
		return parseApiData(
			restSecurityApi.get(`/security/client_details/scopes`, { params, cancelToken: options?.cancelToken })
		);
	};

	static getClientGrantTypes = (params?: Record<string, any>, options?: Record<string, any>): Promise<string[]> => {
		return parseApiData(
			restSecurityApi.get(`/security/client_details/authorized_grant_types`, {
				params,
				cancelToken: options?.cancelToken,
			})
		);
	};
}

export { adminApi };
