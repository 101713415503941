import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import MessageEventModal from "go-component/components/MessageEvent/MessageEventModal";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FILTER_VALUE_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import {
	ItemApi,
	ItemGroupApi,
	ItemModifierGroupApi,
	ModifierGroupApi,
	PointOfSaleApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalButton from "../../../../components/translations/EntityTranslationsModalButton";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import ItemGroupForm from "../../components/ItemGroupForm";
import { sortModifierGroupsByPositions } from "../../utils";

interface MatchParams {
	itemGroupId: string;
}

const OrganizationMenuItemGroupsEditPage = (): JSX.Element => {
	const [itemGroup, setItemGroup] = useState<ItemGroupApi | null>();
	const [customFieldsConfig, setCustomFieldsConfig] = useState<CustomFieldTemplateApi[]>([]);
	const [pointsOfSale, setPointsOfSale] = useState<PointOfSaleApi[] | undefined>(undefined);
	const organization = useSelector(selectOrganization);
	const [modifierGroups, setModifierGroups] = useState<ModifierGroupApi[] | undefined>(undefined);
	const [showLogs, setShowLogs] = useState(false);
	const [showTranslations, setShowTranslations] = useState(false);
	const [translationItems, setTranslationItems] = useState(itemGroup?.items);
	const [itemGroupTranslations, setItemGroupTranslations] = useState(itemGroup?.translations);
	const history = useHistory();
	const { t } = useTranslation();
	const itemGroupParams: Record<string, any> = {
		include:
			"image_links,category,tax,roles,translations,direction,custom_fields,items,items.modifier_groups,items.modifier_groups.quantity_info_override,items.modifier_groups.quantity_info,items.modifier_groups.modifier_group,items.modifier_groups.options,items.modifier_groups.options.quantity_info_override,items.modifier_groups.options.price_override,items.stock_info,items.translations,points_of_sale,points_of_sale.direction,points_of_sale.point_of_sale,items.barcodes,items.availability,items.stock_info,items.order_types",
	};
	const { itemGroupId } = useParams<MatchParams>();
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const fetchItemGroup = () => {
		Promise.all([
			api.organization().getItemGroup(Number(itemGroupId), itemGroupParams),
			api.organization().getItemGroupConfig(),
		])
			.then(([newItemGroup, config]) => {
				handleChangeTabTitle(`${newItemGroup.name} | ${t("modules.item_group.header.title")}`);
				const params: Record<string, string> = {};
				params.include = "options,options.item,options.quantity_info,options.price,translations";
				params.size = "0";
				const ids = newItemGroup.items.map((item: { modifier_groups: ItemModifierGroupApi[] }) =>
					item.modifier_groups.map((mod) => mod.modifier_group_id)
				);
				const modifierGroupIds = [...ids];
				setTranslationItems(newItemGroup.items);
				setItemGroupTranslations(newItemGroup.translations);
				params.f = btoa(
					unescape(
						encodeURIComponent(
							`${NEW_WAY_TO_ENCODING_FILTER_SIGN}id|e=${modifierGroupIds
								.flat()
								.join(FILTER_VALUE_SEPARATOR)}`
						)
					)
				);
				const processedItemGroup = {
					...newItemGroup,
					items: newItemGroup.items.map((item: ItemApi) => {
						return {
							...item,
							name:
								newItemGroup.items.length > 1
									? item.name.includes(`${newItemGroup.name} `)
										? item.name.replace(`${newItemGroup.name} `, "")
										: item.name.replace(`${newItemGroup.name}`, "")
									: item.name,
						};
					}),
				};
				if (modifierGroupIds.length > 0) {
					api.organization()
						.getModifierGroups(params)
						.then((modifiers: ModifierGroupApi[]) => {
							unstable_batchedUpdates(() => {
								const sortedModifiers = sortModifierGroupsByPositions(modifiers, newItemGroup.items);
								setPointsOfSale(config.points_of_sale);
								setItemGroup(processedItemGroup);
								setCustomFieldsConfig(config.custom_fields);
								setModifierGroups(sortedModifiers);
							});
						})
						.catch((e: Error) => {
							handleError.alert(e, addFlash);
						});
				} else {
					unstable_batchedUpdates(() => {
						setPointsOfSale(config.points_of_sale);
						setItemGroup(processedItemGroup);
						setCustomFieldsConfig(config.custom_fields);
						setModifierGroups([]);
					});
				}
			})
			.catch((e) => {
				handleError.alert(e, addFlash);
			});
	};

	useEffect(() => {
		fetchItemGroup();
	}, []);

	if (!itemGroup || !pointsOfSale || !modifierGroups) {
		return <LoadingContainer />;
	}
	const removeItemGroup = async (itemGroupToBeRemoved: ItemGroupApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeItemGroup(itemGroupToBeRemoved.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/menu/item_groups`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restoreItemGroup = async (itemGroupToBeRestored: ItemGroupApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			await api.organization().restoreItemGroup(itemGroupToBeRestored.id, itemGroupParams);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			fetchItemGroup();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDisableItemGroup = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			const res = await api.organization().disableItemGroup(itemGroup.id, itemGroupParams);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			setItemGroup(res);
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const copyItemGroup = () => {
		history.push({
			pathname: `/${organization.id}/menu/item_groups/new`,
			state: {
				copiedItem: itemGroup.id,
			},
		});
	};

	const handleSaveTranslations = (data: Record<string, any>) => {
		unstable_batchedUpdates(() => {
			setItemGroupTranslations(data.entity_translations);
			setTranslationItems(data.translation_items);
			setShowTranslations(false);
		});
		setShowTranslations(false);
	};

	const buttons: ButtonProps[] = [
		{
			content: (
				<EntityTranslationsModalButton
					itemsLength={translationItems?.length}
					translations={itemGroupTranslations || []}
					showTranslation={showTranslations}
					setShowTranslations={(show) => setShowTranslations(show)}
				/>
			),
		},
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
			variant: "light",
		},
	];
	if (itemGroup.status !== "DELETED")
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeItemGroup(itemGroup),
			dropdown: true,
		});
	if (itemGroup.status === "ENABLED")
		buttons.push({
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisableItemGroup(),
			dropdown: true,
		});
	if (itemGroup.status === "DELETED")
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreItemGroup(itemGroup),
			dropdown: true,
		});
	if (itemGroup.status === "DISABLED")
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreItemGroup(itemGroup),
			dropdown: true,
		});
	buttons.push({
		title: t("common.action.copy", { ns: "lib" }),
		action: () => copyItemGroup(),
		dropdown: true,
	});

	const renderTitle = () => (
		<>
			{t("modules.item_group.header.title")} "{itemGroup.name}"
			<FormatResourceStatus status={itemGroup.status} />
		</>
	);

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.word.logs", { ns: "lib" }),
			action: () => setShowLogs(!showLogs),
		},
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeItemGroup(itemGroup),
			hide: itemGroup.status === "DELETED",
		},
		{
			title: t("common.action.disable", { ns: "lib" }),
			action: () => onDisableItemGroup(),
			hide: itemGroup.status !== "ENABLED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreItemGroup(itemGroup),
			hide: itemGroup.status !== "DELETED",
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			action: () => restoreItemGroup(itemGroup),
			hide: itemGroup.status !== "DISABLED",
		},
		{
			title: t("common.action.copy", { ns: "lib" }),
			action: () => copyItemGroup(),
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? buttons.filter((button) => button.content) : buttons} />
			{showTranslations && (
				<EntityTranslationsModalForm
					allowDescription={true}
					handleSave={handleSaveTranslations}
					onHide={() => setShowTranslations(!showTranslations)}
					translations={itemGroupTranslations}
					translationItems={translationItems}
				/>
			)}
			{showLogs && (
				<MessageEventModal
					path={`/${organization.id}/logs/message_events`}
					resourceId={itemGroup.id}
					resourceType={"ITEM_GROUP"}
					onHide={() => setShowLogs(!showLogs)}
					organizationId={organization.id}
				/>
			)}

			<ItemGroupForm
				passTranslations={(newItemGroupTranslations, items) =>
					unstable_batchedUpdates(() => {
						setItemGroupTranslations(newItemGroupTranslations);
						setTranslationItems(items);
					})
				}
				itemGroup={itemGroup}
				itemGroupTranslations={itemGroupTranslations}
				translationItems={translationItems}
				modifierGroups={modifierGroups}
				pointsOfSale={pointsOfSale}
				customFieldsConfig={customFieldsConfig}
			/>
		</>
	);
};
export default OrganizationMenuItemGroupsEditPage;
