import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { apiPublic } from "../../../../../../services/Api/Public/apiPublic";
import { OrderIdContext } from "../../contexts/OrderIdContext";

interface EmailModalProps {
	isEmailModalOpen: boolean;
	hideEmailModal: () => void;
}

export const EmailModal = ({ isEmailModalOpen, hideEmailModal }: EmailModalProps): JSX.Element | null => {
	const { t } = useTranslation();
	const { handleSubmit, formState, register, setError, getValues } = useForm<{ email: string }>();
	const { orderId } = useContext(OrderIdContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const [isRequestOngoing, setIsRequestOngoing] = useState(false);

	if (!orderId) {
		hideEmailModal();
		return null;
	}

	const onSubmit = handleSubmit(async ({ email }: { email: string }) => {
		setIsRequestOngoing(true);

		try {
			if (email) {
				await apiPublic.sendEmail(orderId, email);

				addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			}

			hideEmailModal();
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}

		setIsRequestOngoing(false);
	});

	return (
		<Modal
			className="e-order-order-actions-email-modal"
			data-testid="e-order-order-actions-email-modal"
			show={isEmailModalOpen}
			onHide={hideEmailModal}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{`${t("common.word.email", { ns: "lib" })} *`}</Modal.Title>
			</Modal.Header>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Body>
					<FormInput
						name="email"
						register={register}
						errors={formState.errors}
						data-testid="e-order-order-actions-email-modal-email-input"
					/>
				</Modal.Body>
				<Modal.Footer>
					<div className="buttons-group">
						<Button
							title="Cancel"
							variant="light"
							type="button"
							size="lg"
							disabled={isRequestOngoing}
							onClick={hideEmailModal}
							data-testid="e-order-order-actions-email-modal-cancel-button"
						>
							{t("lib:common.action.cancel")}
						</Button>
						<ButtonLoading
							title="Send e-mail"
							variant="primary"
							type="submit"
							size="lg"
							loading={isRequestOngoing}
							disabled={!getValues("email")}
							data-testid="e-order-order-actions-email-modal-send-email-button"
						>
							{t("modules.e_order.action.send_email.title")}
						</ButtonLoading>
					</div>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
