import { useEffect, useState } from "react";

interface Size {
	width: number;
	height: number;
	isMobile: boolean;
	isSmallBreakpoint: boolean;
}

export function useWindowSize(): Size {
	const [windowSize, setWindowSize] = useState<Size>({
		width: 0,
		height: 0,
		isMobile: /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
		isSmallBreakpoint: false,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
				isMobile: window.innerWidth <= 812,
				isSmallBreakpoint: window.innerWidth <= 576,
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
}
