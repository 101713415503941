import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as PlusSVG } from "../../../images/svg/plus.svg";
import { ReactComponent as RemoveSVG } from "../../../images/svg/remove.svg";
import { AvailabilityApi, AvailabilityHourItem } from "../../../services/types";
import { AvailabilityTimePicker } from "./AvailabilityTimePicker";

interface Props {
	form: UseFormReturn<AvailabilityApi>;
	hours: AvailabilityHourItem[];
	changeHours: (hours: AvailabilityHourItem[]) => void;
	editHours: (hours: AvailabilityHourItem, type: string) => void;
}

export interface AvailabilityHoursDto {
	day: string;
	hours: AvailabilityHourItem[];
}

export const HoursTableForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const { control } = props.form;
	const { remove, append } = useFieldArray({
		control,
		name: "hours",
		keyName: "key",
	});
	const days = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
	const [hoursState, setHoursState] = useState<AvailabilityHoursDto[]>([]);
	const isMobile = useWindowSize().isMobile;

	const onChangeTimeStartDate = (val: string, hour: AvailabilityHourItem, index: number) => {
		append({});
		hour.hour_from = val;
		const newHours = [
			...hoursState.map((item) =>
				item.day !== hour.day_from
					? item
					: {
							...item,
							hours: [
								...item.hours.map((hr, hrIndex) =>
									hrIndex !== index
										? hr
										: {
												...hr,
												hour_from: val,
										  }
								),
							],
					  }
			),
		];
		setHoursState(newHours);
		props.editHours(hour, "FROM");
	};

	const onChangeTimeEndDate = (val: string, hour: AvailabilityHourItem, index: number) => {
		append({});
		hour.hour_to = val;
		const newHours = [
			...hoursState.map((item) =>
				item.day !== hour.day_from
					? item
					: {
							...item,
							hours: [
								...item.hours.map((hr, hrIndex) =>
									hrIndex !== index
										? hr
										: {
												...hr,
												hour_to: val,
										  }
								),
							],
					  }
			),
		];

		setHoursState(newHours);
		props.editHours(hour, "TO");
	};

	const removeHourItem = (hour: AvailabilityHourItem, day: AvailabilityHoursDto) => {
		remove();
		const index = day.hours.indexOf(hour);
		day.hours.splice(index, 1);
		const propsIndex = props.hours.indexOf(hour);
		setHoursState([...hoursState.map((x) => (x.day === day.day ? { ...x, ...day } : x))]);
		props.hours.splice(propsIndex, 1);
		props.changeHours([...props.hours]);
	};

	const addHourItem = (day: AvailabilityHoursDto) => {
		append({});
		let hourFrom = "00:00";
		let hourTo = "00:00";
		if (day.hours.length > 0) {
			hourFrom = day.hours[day.hours.length - 1].hour_to;
		} else {
			const dayIndex = days.findIndex((d) => d === day.day);
			if (hoursState && hoursState.length > 1) {
				const previousDay = hoursState[dayIndex - 1];
				if (previousDay && previousDay.hours[0]) {
					hourFrom = previousDay.hours[0].hour_from;
					hourTo = previousDay.hours[0].hour_to;
				}
			}
		}
		const hour = {
			day_from: day.day,
			day_to: day.day,
			hour_from: hourFrom,
			hour_to: hourTo,
		};
		day.hours.push(hour);
		const newHours = [...hoursState.map((x) => (x.day === day.day ? { ...x, ...day } : x))];
		setHoursState([...newHours]);
		props.changeHours([...props.hours, hour]);
	};

	useEffect(() => {
		const availabilityHours: AvailabilityHoursDto[] = [];
		days.forEach((day) => {
			const selectedHours = props.hours.filter((f) => f.day_from === day);
			if (selectedHours.length > 0) {
				availabilityHours.push({
					day,
					hours: [...selectedHours],
				});
			} else {
				availabilityHours.push({
					day,
					hours: [],
				});
			}
		});
		setHoursState([...availabilityHours]);
	}, [props.hours]);

	const parseTime = (time: string): string => {
		const hours = Number(time.split(":")[0]);
		const minutes = Number(time.split(":")[1]);
		let newHours, newMinutes;
		minutes < 10 ? (newMinutes = `0${minutes.toString()}`) : (newMinutes = minutes);
		hours < 10 ? (newHours = `0${hours.toString()}`) : (newHours = hours);
		return `${newHours}:${newMinutes}`;
	};

	return (
		<div className={`${isMobile ? "table-responsive" : ""}`}>
			<table className={"table table-form"}>
				<thead>
					<tr>
						<th>{t("go_component.availability.field.days.title", { ns: "lib" })}</th>
						<th>{t("go_component.availability.field.in_hours.title", { ns: "lib" })}</th>
					</tr>
				</thead>
				<tbody>
					{hoursState.length > 0 &&
						hoursState.map((day, index) => {
							return (
								<tr key={index}>
									<td>{t(`enums.days.${day.day}`, { ns: "lib" })}</td>
									<td width="300px" className="pl-0 pr-0">
										<table className="table table-form nested">
											<tbody>
												{day.hours.map((hour: AvailabilityHourItem, index: number) => {
													return (
														<tr key={index}>
															<td className="pl-2">
																<AvailabilityTimePicker
																	time={parseTime(hour.hour_from)}
																	onChange={(e: string) =>
																		onChangeTimeStartDate(e, hour, index)
																	}
																/>
															</td>
															<td className="pt-1">-</td>
															<td>
																<AvailabilityTimePicker
																	time={parseTime(hour.hour_to)}
																	displayMidnight={true}
																	onChange={(e) =>
																		onChangeTimeEndDate(e, hour, index)
																	}
																/>
															</td>
															<td>
																<div className="d-flex" style={{ width: "80px" }}>
																	<Button
																		type="button"
																		variant="light"
																		className="me-1"
																		onClick={() => removeHourItem(hour, day)}
																	>
																		<RemoveSVG className="icon mt-0" />
																	</Button>
																	<Button
																		variant="light"
																		type="button"
																		onClick={() => addHourItem(day)}
																	>
																		<PlusSVG
																			style={{
																				width: "12px",
																				padding: "0px",
																				height: "12px",
																				background: "transparent",
																			}}
																		/>
																	</Button>
																</div>
															</td>
														</tr>
													);
												})}
												{day.hours.length === 0 && (
													<Button
														className="ms-2"
														variant="light"
														type="button"
														onClick={() => addHourItem(day)}
													>
														<PlusSVG
															style={{
																width: "14px",
																padding: "0px",
																height: "14px",
																background: "transparent",
															}}
														/>
													</Button>
												)}
											</tbody>
										</table>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};
