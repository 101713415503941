import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { InvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import AdvanceItemsTable from "./AdvanceItemsTable";
import InvoiceCorrectionPositionsCard from "./InvoiceCorrectionPositionsCard";
import InvoiceInfoCard from "./InvoiceInfoCard";
import InvoiceIssuerCard from "./InvoiceIssuerCard";
import InvoiceOrdersCard from "./InvoiceOrdersCard";
import InvoicePositionsCard from "./InvoicePositionsCard";
import InvoiceReceiverCard from "./InvoiceReceiverCard";
import InvoiceTransactionsCard from "./InvoiceTransactionsCard";
import LinkedDocumentsCard from "./LinkedDocumentsCard";

interface Props {
	invoice: InvoiceApi;
	config: CustomFieldTemplateApi[];
	handleUpdateInvoice?: (data: InvoiceApi) => void;
	setInvoice: (invoice: InvoiceApi) => void;
}

const InvoicePreview: FC<Props> = ({ invoice, config, handleUpdateInvoice, setInvoice }) => {
	const { t } = useTranslation();

	return (
		<div className={"content-preview-body"}>
			<div className={"row"}>
				{invoice.linked_invoices && invoice.linked_invoices.length > 0 && (
					<div className={"col-md-12"}>
						<LinkedDocumentsCard
							invoice={invoice}
							parentId={invoice.id}
							invoices={invoice.linked_invoices}
							handleUpdateAdvance={handleUpdateInvoice}
						/>
					</div>
				)}
				<div className={"col-md-12"}>
					<InvoiceInfoCard invoice={invoice} config={config} />
				</div>
				{invoice.receiver && invoice.recipient && invoice.receiver ? (
					<>
						<div className={"col-md-4"}>
							<InvoiceIssuerCard issuer={invoice.issuer} />
						</div>
						<div className={"col-md-4"}>
							<InvoiceReceiverCard
								id={invoice.recipient_id}
								title={t("modules.invoice.field.recipient.title")}
								client={invoice.recipient}
							/>
						</div>
						<div className={"col-md-4"}>
							<InvoiceReceiverCard
								id={invoice.receiver_id}
								title={t("modules.invoice.field.receiver.title")}
								client={invoice.receiver}
							/>
						</div>
					</>
				) : (
					<>
						{invoice.issuer && (
							<div className={"col-md-6"}>
								<InvoiceIssuerCard issuer={invoice.issuer} />
							</div>
						)}
						{invoice.recipient && (
							<div className={"col-md-6"}>
								<InvoiceReceiverCard
									id={invoice.recipient_id}
									title={t("modules.invoice.field.recipient.title")}
									client={invoice.recipient}
								/>
							</div>
						)}
					</>
				)}
				<div className={"col-md-12"}>
					{invoice.document_type === "CORRECTION" ? (
						<InvoiceCorrectionPositionsCard
							correction={invoice}
							items={
								invoice.linked_invoices.find(
									(f) => f.document_type === "INVOICE" || f.document_type === "ADVANCE"
								)?.items
							}
						/>
					) : (
						<InvoicePositionsCard invoice={invoice} handleUpdateInvoice={(data) => setInvoice(data)} />
					)}
				</div>
				{invoice.document_type === "ADVANCE" && invoice?.order_items?.length > 0 && (
					<div className={"col-md-12"}>
						<AdvanceItemsTable invoice={invoice} />
					</div>
				)}
				{invoice.document_type === "INVOICE" || invoice.document_type === "ADVANCE" ? (
					<>
						<div className={"col-md-4"}>
							<InvoiceOrdersCard
								handleUpdateInvoice={(data) => setInvoice(data)}
								invoice={invoice}
								orders={invoice.orders.filter((inv) => inv?.order_id)}
								isAdvance={invoice.document_type === "ADVANCE"}
							/>
						</div>
						<div className={"col-md-8"}>
							<InvoiceTransactionsCard
								invoice={invoice}
								handleUpdateInvoice={(data) => setInvoice(data)}
								payments={invoice.payments}
							/>
						</div>
					</>
				) : (
					<div className={"col-md-12"}>
						<InvoiceTransactionsCard
							invoice={invoice}
							handleUpdateInvoice={(data) => setInvoice(data)}
							payments={invoice.payments}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
export default InvoicePreview;
