import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import RequestItemPreview from "go-component/components/RequestItem/RequestItemPreview";
import { RequestItemContext } from "go-component/components/RequestItem/services/context";
import { RequestItemApi } from "go-component/components/RequestItem/services/types";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";

interface MatchParams {
	item_id: string;
}

const OrganizationLogsRequestItemsEditPage = (): JSX.Element => {
	const [requestItem, setRequestItem] = useState<RequestItemApi | undefined>();
	const requestItemService = React.useContext(RequestItemContext);
	const { addFlash } = useFlash();
	const { item_id } = useParams<MatchParams>();
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const { t } = useTranslation();

	useEffect(() => {
		onFetchRequestItem();
	}, []);

	const onFetchRequestItem = async () => {
		const params: Record<string, any> = {};
		params.include = "logs,settings";
		try {
			const res = await requestItemService.api().get(item_id, params);
			setRequestItem(res.data.data);
			handleChangeTabTitle(
				`${t(`lib:go_component.request_item.types.${res.data.data.type}`)} | ${t(
					"lib:go_component.request_item.header.title"
				)}`
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!requestItem) {
		return <LoadingContainer />;
	}

	return <RequestItemPreview requestItem={requestItem} />;
};

export default OrganizationLogsRequestItemsEditPage;
