import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { WebhookContext, WebhookContextProps, WebhookService } from "go-component/components/Webhook/services/context";
import { apiOrganizationComponent } from "../../../../../../../../services/Api/Organization/apiOrganizationComponent";
import OrganizationLogsWebhooksEditPage from "./pages/Edit";
import OrganizationLogsWebhooksIndexPage from "./pages/Index";
import OrganizationLogsWebhooksNewPage from "./pages/New";
import OrganizationLogsWebhooksPreviewPage from "./pages/Preview";

const OrganizationLogsWebhooksPage: FC<RouteComponentProps> = (props): JSX.Element => {
	const { t } = useTranslation();
	const translationPrefix = "enums.webhook.type";

	return (
		<WebhookContext.Provider
			value={
				new WebhookService(
					{
						all: apiOrganizationComponent.getWebhooks,
						get: apiOrganizationComponent.getWebhook,
						create: apiOrganizationComponent.createWebhook,
						update: apiOrganizationComponent.updateWebhook,
						getResourceTypeOptions: apiOrganizationComponent.getWebhookResourceTypes,
					} as WebhookContextProps,
					translationPrefix,
					t
				)
			}
		>
			<Switch>
				<Route path={props.match.url} exact={true} component={OrganizationLogsWebhooksIndexPage} />
				<Route path={`${props.match.url}/new`} exact={true} component={OrganizationLogsWebhooksNewPage} />
				<Route path={`${props.match.url}/:webhook_id/edit`} component={OrganizationLogsWebhooksEditPage} />
				<Route path={`${props.match.url}/:webhook_id`} component={OrganizationLogsWebhooksPreviewPage} />
			</Switch>
		</WebhookContext.Provider>
	);
};

export default OrganizationLogsWebhooksPage;

// t("enums.webhook.type.ORDER");
// t("enums.webhook.type.ORDER_PREPARATION_STATUS");
// t("enums.webhook.type.ORDER_COST");
// t("enums.webhook.type.AVAILABILITY");
// t("enums.webhook.type.CATEGORY");
// t("enums.webhook.type.ITEM_GROUP");
// t("enums.webhook.type.ITEM");
// t("enums.webhook.type.CLIENT_GROUP");
// t("enums.webhook.type.CLIENT");
// t("enums.webhook.type.DIRECTION");
// t("enums.webhook.type.MENU_DISCOUNT");
// t("enums.webhook.type.ROOM");
// t("enums.webhook.type.ROOM_TABLE");
// t("enums.webhook.type.EMPLOYEE");
// t("enums.webhook.type.INVOICE");
// t("enums.webhook.type.POINT_OF_SALE");
// t("enums.webhook.type.TERMINAL");
// t("enums.webhook.type.TRANSACTION");
// t("enums.webhook.type.PAYMENT_METHOD");
