import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { RoomApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../../services/Api/api";
import SubmenuRoomsNavigation from "../../../../components/SubmenuRoomsNavigation";
import RoomsPreview from "../../components/RoomsPreview";

const OrganizationSettingsRoomsIndexIndexPage = (): JSX.Element => {
	const [rooms, setRooms] = useState<RoomApi[] | undefined>(undefined);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.room.field.rooms.title"));
		onFetchRooms();
	}, []);

	const onFetchRooms = async () => {
		try {
			const params: Record<string, string> = { include: "tables,elements" };
			const res = await api.organization().getRooms(params);
			setRooms(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!rooms) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Header title={t("modules.room.header.title")} />
			<SubmenuRoomsNavigation />
			<RoomsPreview roomsList={rooms} />
		</>
	);
};

export default OrganizationSettingsRoomsIndexIndexPage;
