import { FC } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import WebhooksList from "go-component/components/Webhook/WebhooksList";
import { selectOrganization } from "go-security/services/organizations/selectors";

const OrganizationLogsWebhooksIndexPage: FC<RouteComponentProps> = (props) => {
	const organization = useSelector(selectOrganization);
	const taxIdNo = organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined;
	const companyName = organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined;
	const pdfOrientation = organization?.more?.pdf_orientation;
	const pdfFontSize = organization?.more?.default_pdf_font_size?.toString();

	return (
		<WebhooksList
			routeProps={props}
			organization_name={organization.name || ""}
			taxIdNo={taxIdNo}
			companyName={companyName}
			pdfOrientation={pdfOrientation}
			pdfFontSize={pdfFontSize}
		/>
	);
};
export default OrganizationLogsWebhooksIndexPage;
