import { TFunction } from "react-i18next";
import { ItemApi, ItemOrderType, ModifierGroupApi } from "../../../../../../../../../services/Api/Organization/types";

export const checkIfValueIsEmpty = (value?: string | number | null): boolean => {
	return value === "" || value === undefined || value === null;
};

export const getOrderTypes = (t: TFunction) => {
	const possibleOrderTypes = Object.values(ItemOrderType);
	return possibleOrderTypes.map((orderType) => ({
		id: orderType,
		label: t(`enums.orders.types.${orderType}`),
	}));
};

export const sortModifierGroupsByPositions = (data: ModifierGroupApi[], items: ItemApi[]) => {
	const newArr: ModifierGroupApi[] = [];
	const allItems = items
		.map((x) => x.modifier_groups)
		.flat()
		.sort((a, b) => a.position - b.position);
	allItems.forEach((item, index) => {
		item.position = index;
		const found = data.find((f) => f.id === item.modifier_group_id);
		if (found && !newArr[item.position]) {
			newArr[item.position] = found;
		}
	});
	return newArr.filter((item, index) => newArr.indexOf(item) === index);
};
