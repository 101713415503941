import React, { useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Loading } from "go-core/components/Loading";
import { useInfiniteScroll } from "go-core/hooks/useInfiniteScroll";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { ItemApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { LiveOrdersBlockedProductFilter } from "../../services/types";
import LiveOrdersBlockProductModal from "./LiveOrdersBlockProductModal";
import LiveOrdersBlockedProductsFiltersModal from "./LiveOrdersBlockedProductsFiltersModal";
import LiveOrdersBlockedProductsTableModal from "./LiveOrdersBlockedProductsTableModal";

interface Props {
	isShown: boolean;
	onHide: () => void;
}

const LiveOrdersBlockedProductsModal = ({ isShown, onHide }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [selectedFilter, setSelectedFilter] = useState<LiveOrdersBlockedProductFilter>(
		LiveOrdersBlockedProductFilter.ALL
	);
	const [search, setSearch] = useState<string>("");
	const [productToBlock, setProductToBlock] = useState<ItemApi | undefined>();
	const scrollRef = useRef(null);

	const fetchProducts = (params: Record<string, any>, options?: CancelTokenSource) => {
		const filter =
			selectedFilter === LiveOrdersBlockedProductFilter.ALL
				? ""
				: btoa(
						unescape(
							encodeURIComponent(
								`${NEW_WAY_TO_ENCODING_FILTER_SIGN}suspended|e=${
									selectedFilter === LiveOrdersBlockedProductFilter.BLOCKED
								}`
							)
						)
				  );
		const finalParams = {
			...params,
			include: "category,item_group",
			search,
			f: filter,
		};
		return api.organization().getItems(finalParams, {
			cancelToken: options?.token,
		});
	};

	const { items, loading, updateItem } = useInfiniteScroll({
		fetchItems: fetchProducts,
		search,
		scrollContainerElementRef: scrollRef,
		fetchDependencies: [selectedFilter],
	});

	const handleBlockUnblockProduct = (index: number, product: ItemApi) => {
		updateItem(index, product);
	};

	const getFilteredItems = () => {
		return items.filter((item: Record<string, any>) => {
			switch (selectedFilter) {
				case LiveOrdersBlockedProductFilter.BLOCKED:
					return item.suspended;
				case LiveOrdersBlockedProductFilter.UNBLOCKED:
					return !item.suspended;
				default:
					return true;
			}
		});
	};

	const getProductIndex = (product: ItemApi) => {
		return items.findIndex((item: Record<string, any>) => item.id === product.id);
	};

	return (
		<>
			<Modal
				show={isShown}
				onHide={onHide}
				className={`live-orders-blocked-products-modal ${productToBlock ? "d-none" : "unset"}`}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.live_order.field.blocked_products.title")}</Modal.Title>
				</Modal.Header>
				<LiveOrdersBlockedProductsFiltersModal
					setSelectedFilter={setSelectedFilter}
					selectedFilter={selectedFilter}
					setSearch={setSearch}
					search={search}
				/>
				<Modal.Body ref={scrollRef}>
					<LiveOrdersBlockedProductsTableModal
						products={getFilteredItems()}
						setProductToBlock={setProductToBlock}
						loading={loading}
						handleUnblockProduct={handleBlockUnblockProduct}
					/>
					{loading && <Loading />}
				</Modal.Body>
			</Modal>
			{productToBlock && (
				<LiveOrdersBlockProductModal
					isShown={!!productToBlock}
					onHide={() => setProductToBlock(undefined)}
					productIndex={getProductIndex(productToBlock)}
					product={productToBlock}
					handleBlockProduct={handleBlockUnblockProduct}
				/>
			)}
		</>
	);
};

export default LiveOrdersBlockedProductsModal;
