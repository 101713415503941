import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { OrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import OrderPreview from "../../components/OrderPreview";

interface MatchParams {
	orderId: string;
}

const OrganizationSalesOrdersPreviewPage = (): JSX.Element => {
	const [order, setOrder] = useState<OrderApi | undefined>(undefined);
	const [customFieldsConfig, setCustomFieldsConfig] = useState<undefined | CustomFieldTemplateApi[]>(undefined);
	const { addFlash } = useFlash();
	const { orderId } = useParams<MatchParams>();
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		fetchOrder();
	}, []);

	const fetchOrder = () => {
		const params: Record<string, any> = {
			include:
				"custom_fields,report,invoice,items.preparation_status,activities,contact,contact.client,transactions.transaction," +
				"tax_items,terminal,closing_terminal,source,items,promotions,promotions.items,transactions,table,employee,opening_employee,fiscalization," +
				"point_of_sale,items.tax,items.direction,items.product,items.employee,transactions.payment_method,cost,items.cost,delivery," +
				"delivery.delivery_zone,delivery.coordinates,delivery.address,delivery.delivery_employee,external_links,ereceipt,transactions.external_id_inconsistency",
		};
		Promise.all([api.organization().getOrder(orderId, params), api.organization().getEntityConfig("ORDER")])
			.then((res) => {
				setOrder(res[0]);
				setCustomFieldsConfig(res[1].custom_fields);
				handleChangeTabTitle(`#${res[0].number} | ${t("modules.order.header.title")}`);
			})
			.catch((err) => {
				handleError.alert(err, addFlash);
			});
	};

	if (!order || !customFieldsConfig) {
		return <LoadingContainer />;
	}

	return <OrderPreview order={order} customFieldsConfig={customFieldsConfig} fetchOrder={fetchOrder} />;
};
export default OrganizationSalesOrdersPreviewPage;
