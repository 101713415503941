import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as BackSVG } from "../../../../../../../../../images/svg/menu/header-back.svg";
import { TaxApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	tax?: TaxApi;
	onHide: () => void;
	handleSave: (tax: TaxApi) => void;
	showGoBack?: boolean;
	show?: boolean;
	name?: string;
}

const TaxModalForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<TaxApi>({
		criteriaMode: "all",
		defaultValues: {
			...props.tax,
			name: props.name ?? props.tax?.name,
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
	} = form;
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";

	const onSubmit = handleSubmit(async (data: TaxApi) => {
		setLoading(true);
		if (props.tax) {
			data.id = props.tax.id;
		}

		try {
			const res = await api.organization().createOrUpdateTax(data);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			props.handleSave(res);
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={props.show ?? !!props.tax} onHide={props.onHide}>
			<Form key="tax-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.showGoBack && <BackSVG className="icon me-2" onClick={() => props.onHide()} />}
						{props.tax?.id ? (
							<>
								{t("modules.tax.header.title")} "{props.tax?.name}"
								<FormatResourceStatus status={props.tax?.status} />
							</>
						) : (
							<>{t("modules.tax.header.new.title")}</>
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormInput
						label={t("common.word.name", { ns: "lib" })}
						register={register}
						name="name"
						errors={errors}
					/>
					<FormInput
						label={t("modules.tax.field.code.title")}
						register={register}
						name="code"
						errors={errors}
					/>
					<FormMoneyInput
						suffix={""}
						label={t("common.word.value")}
						control={control}
						currency={currency}
						name="amount"
						errors={errors}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={props.onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default TaxModalForm;
