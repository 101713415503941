import React, { FC, Fragment } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmptyList from "go-core/components/EmptyList";
import FormatDate, { formatTimeAgo } from "go-core/components/Formatters/FormatDate";
import { Loading } from "go-core/components/Loading";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";

interface Props {
	resourceType: string;
	resourceId: number;
	data: any;
	loadingActivities?: boolean;
}

const LastActivities: FC<Props> = ({ resourceType, resourceId, data, loadingActivities }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const filter = btoa(
		unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${resourceType.toLowerCase()}|e=${resourceId}`))
	);

	return (
		<Card>
			<Card.Body>
				<h5>{t("components.last_activities.header.title")}</h5>
				{loadingActivities ? (
					<Loading />
				) : data.length === 0 ? (
					<EmptyList />
				) : (
					<>
						{data.map((item: any, index: number) => {
							if (index > 4) {
								return null;
							}
							return (
								<Fragment key={index}>
									<div className={"activity-name"}>
										{item.description ? item.description : item.type}
									</div>
									<div className={"activity-info"}>
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip id={`tooltip-${index}`}>{FormatDate(item.created_at)}</Tooltip>
											}
										>
											{({ ref, ...triggerHandler }) => (
												<span ref={ref} {...triggerHandler}>
													{formatTimeAgo(item.created_at, t)}
												</span>
											)}
										</OverlayTrigger>{" "}
										/ {item.employee_name}
									</div>
								</Fragment>
							);
						})}
						{data.length > 4 && (
							<Link
								to={`/${organization.id}/logs/employee_activities?f=${filter}`}
								className={"btn btn-add card-action"}
								rel={"noreferrer"}
								target={"_blank"}
							>
								{t("common.action.show_more", { ns: "lib" })}
							</Link>
						)}
					</>
				)}
			</Card.Body>
		</Card>
	);
};

export default LastActivities;
