import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { OrganizationInfoApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { CoordinatesApi } from "../../../../../../../../../../../services/Api/types";
import { polandCoords } from "../../../../../../LiveOrders/services/orderDelivery";
import { OrganizationAddressMap } from "./OrganizationAddressMap";

interface Props {
	form: UseFormReturn<OrganizationInfoApi>;
	organizationInfo: OrganizationInfoApi;
}

const OrganizationAddressForm = ({ form, organizationInfo }: Props): JSX.Element => {
	const [polandCoordsLat, polandCoordsLng] = polandCoords;
	const [organizationCoordinates, setOrganizationCoordinates] = useState<CoordinatesApi>({
		latitude: polandCoordsLat,
		longitude: polandCoordsLng,
	});
	const [loadingCoords, setLoadingCoords] = useState(true);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
		watch,
	} = form;

	const getCoordinates = async (getCoordinatesOnBlur = true) => {
		try {
			const { place_address } = getCoordinatesOnBlur ? watch() : organizationInfo;
			const { city, build_nr, street } = place_address ?? {};

			const params: Record<string, any> = { city, build_nr, street };
			const res = await api.organization().getOrganizationCoordinates(params);
			if (res.latitude === undefined || res.longitude === undefined) {
				setLoadingCoords(false);
				return;
			}
			setOrganizationCoordinates(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		} finally {
			setLoadingCoords(false);
		}
	};

	return (
		<fieldset className="form-group no-gutters">
			<h5>{t("modules.setting.field.public_location_data.title")}</h5>
			<FormInput
				label={t("modules.setting.field.public_location_name.title")}
				register={register}
				name="public_name"
				errors={errors}
				help={`* ${t("modules.setting.field.public_location_name.help_text.title")}`}
			/>
			<div className="row">
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-6">
							<FormInput
								label={t("lib:common.address.street")}
								register={register}
								name="place_address.street"
								errors={errors}
								onBlur={() => getCoordinates()}
							/>
						</div>
						<div className="col-md-3">
							<FormInput
								label={t("lib:common.address.build_nr")}
								register={register}
								name="place_address.build_nr"
								errors={errors}
								onBlur={() => getCoordinates()}
							/>
						</div>
						<div className="col-md-3">
							<FormInput
								label={t("lib:common.address.flat_nr")}
								register={register}
								name="place_address.flat_nr"
								errors={errors}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								label={t("lib:common.address.city")}
								register={register}
								name="place_address.city"
								errors={errors}
								onBlur={() => getCoordinates()}
							/>
						</div>
						<div className="col-md-3">
							<FormInput
								label={t("lib:common.address.zip_code")}
								register={register}
								name="place_address.zip_code"
								errors={errors}
							/>
						</div>
						<div className="col-md-3">
							<FormInput
								label={t("lib:common.address.country")}
								register={register}
								name="place_address.country"
								errors={errors}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								label={t("modules.setting.field.email.title")}
								register={register}
								name="public_email"
								errors={errors}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								register={register}
								name="public_phone_number"
								label={t("common.word.phone_number", { ns: "lib" })}
								errors={errors}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<OrganizationAddressMap
						organizationInfo={organizationInfo}
						getCoordinates={getCoordinates}
						loadingCoords={loadingCoords}
						organizationCoordinates={organizationCoordinates}
					/>
				</div>
			</div>
			<div className="row">
				<Form.Label>{t("modules.setting.field.website_social_media.title")}</Form.Label>
				<div className="col-lg-3">
					<InputGroup className="form-input-group">
						<InputGroup.Text style={{ height: "31px" }}>https://</InputGroup.Text>
						<FormInput register={register} errors={errors} name="website" />
					</InputGroup>
				</div>
				<div className="col-lg-3">
					<InputGroup className="form-input-group">
						<InputGroup.Text style={{ height: "31px" }}>instagram.com/</InputGroup.Text>
						<FormInput register={register} errors={errors} name="instagram" />
					</InputGroup>
				</div>
				<div className="col-lg-3">
					<InputGroup className="form-input-group">
						<InputGroup.Text style={{ height: "31px" }}>facebook.com/</InputGroup.Text>
						<FormInput register={register} errors={errors} name="facebook" />
					</InputGroup>
				</div>
				<div className="col-lg-3">
					<InputGroup className="form-input-group">
						<InputGroup.Text style={{ height: "31px" }}>linkedin.com/</InputGroup.Text>
						<FormInput register={register} errors={errors} name="linkedin" />
					</InputGroup>
				</div>
			</div>
		</fieldset>
	);
};

export default OrganizationAddressForm;
