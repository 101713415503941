import React, { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { formatDateTimeToString } from "go-core/components/Formatters/FormatDate";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { apiOrganization } from "../../../../../../../../../../services/Api/Organization/apiOrganization";
import {
	FiscalizeOrderFormProps,
	LiveOrderApi,
	OrderFiscalizationType,
	OrderPreparationStatusName,
	UpdateTransactionStatus,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import ChangeTransactionPaymentMethodInfo from "../../../../../Sales/components/ChangeTransactionPaymentMethodInfo";
import { OrdersContext } from "../../../../services/context";
import { liveOrderParamsInclude } from "../../../../services/orderSynchronizer";
import { getLiveOrderNumber } from "../../../../services/utils";
import LiveOrderFiscalizationDeliveryEmployee from "./LiveOrderFiscalizationDeliveryEmployee";
import LiveOrderFiscalizationPaymentMethods from "./LiveOrderFiscalizationPaymentMethods";
import LiveOrderFiscalizationTerminals from "./LiveOrderFiscalizationTerminals";
import LiveOrderFiscalizationTypes from "./LiveOrderFiscalizationTypes";

interface Props {
	isShown: boolean;
	onHide: () => void;
	order: LiveOrderApi;
	inCollectMode?: boolean;
}

const LiveOrderFiscalizationModal = ({ isShown, onHide, order, inCollectMode }: Props): JSX.Element => {
	const { t } = useTranslation();
	const ordersContext = useContext(OrdersContext);
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const form = useForm<FiscalizeOrderFormProps>({
		criteriaMode: "all",
		defaultValues: {
			payment_method_id: order.transactions[0]?.payment_method_id,
			employee_id:
				order?.delivery?.delivery_employee?.reference_id || order?.delivery?.delivery_employee?.id?.toString(),
		},
	});
	const {
		formState,
		formState: { errors },
		handleSubmit,
		setError,
		register,
		watch,
	} = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const organization = useSelector(selectOrganization);
	const [paymentMethodOutflowType, setPaymentMethodOutflowType] = useState(
		order.transactions[0]?.payment_method?.outflow_type ?? ""
	);
	const isFiscalizationForced = organization.more?.force_order_fiscalization;
	const [fiscalize, setFiscalize] = useState(inCollectMode ? isFiscalizationForced : true);
	const hasWoltDriveDeliveryEmployee = order.delivery?.delivery_employee?.name === "WOLT_DRIVE";

	const getParsedFormData = (data: FiscalizeOrderFormProps) => {
		if (paymentMethodOutflowType !== "WZ") {
			data.type = OrderFiscalizationType.NONE;
		}
		if (!organization?.more?.ereceipt_enabled && paymentMethodOutflowType === "WZ") {
			data.type = OrderFiscalizationType.FISCAL_RECEIPT;
		}

		if (!fiscalize && inCollectMode) {
			delete data.terminal_id;
			delete data.email;
			delete data.type;
		}

		delete data.employee_id;

		return data;
	};

	const validateSubmitWithoutDeliverEmployee = () => {
		return (
			!order.delivery?.delivery_employee?.id &&
			order.type === "DELIVERY" &&
			order.delivery?.address?.city &&
			order.delivery?.address?.build_nr &&
			organization.more?.validate_delivery_employee
		);
	};

	const handleAssignDeliveryEmployee = async (data: FiscalizeOrderFormProps) => {
		const res = await apiOrganization.assignEmployeeDeliveryToOrder(
			order.id,
			{
				delivery_employee_id: data.employee_id ? data.employee_id?.toString() : undefined,
			},
			{ include: "delivery,delivery.delivery_employee" }
		);
		order.delivery = {
			...order?.delivery,
			delivery_employee: { ...res.delivery.delivery_employee },
		};
		ordersContext.updateOrder(order);
	};

	const handleChangePreparationStatus = async () => {
		const res = await apiOrganization.changeOrderPreparationStatus(order.id, OrderPreparationStatusName.RECEIVED, {
			include: "preparation_status",
		});
		order.preparation_status = JSON.parse(JSON.stringify(res.preparation_status));
		ordersContext.updateOrder(order);
		addSuccessFlash(t("lib:common.flash.completed"));
		onHide();
	};

	const handleFiscalization = async (data: FiscalizeOrderFormProps) => {
		const params = { include: liveOrderParamsInclude };
		const newData = getParsedFormData(data);
		const res = await api.organization().fiscalizeOrder(order.id, newData, params);
		order = {
			...res,
			preparation_status: {
				...order.preparation_status,
			},
			delivery: {
				...order?.delivery,
			},
		};
		ordersContext.updateOrder(order);
		if (!inCollectMode) {
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		}
	};

	const handleChangePaymentMethod = async (data: FiscalizeOrderFormProps) => {
		const filter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}order|e=${order.id}`;
		const encodedFilter = btoa(unescape(encodeURIComponent(filter)));
		const params = {
			include: liveOrderParamsInclude,
			f: encodedFilter,
		};
		const existingTransaction = order.transactions.find((transaction) => transaction.status !== "ERROR");
		const newDate = {
			payment_method_id: data.payment_method_id,
			transaction_status: data.paid ? UpdateTransactionStatus.SUCCESS : UpdateTransactionStatus.NEW,
			paid_at: existingTransaction?.paid_at ?? formatDateTimeToString(new Date()),
		};
		const res = await apiOrganization.updateOrderTransactions(order.id, newDate, params);
		order = {
			...order,
			...res,
			preparation_status: {
				...order.preparation_status,
			},
			delivery: {
				...order?.delivery,
			},
		} as LiveOrderApi;
		ordersContext.updateOrder(order);
		if (!inCollectMode) {
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		}
	};

	const onSubmit = handleSubmit(async (data: FiscalizeOrderFormProps) => {
		if (!inCollectMode && validateSubmitWithoutDeliverEmployee()) {
			addFlash({ type: "danger", msg: "order_must_have_driver_assigned_to_it" });
			return;
		}

		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		try {
			if (!hasWoltDriveDeliveryEmployee && inCollectMode) await handleAssignDeliveryEmployee(data);
			// do not change payment methods when order is already CLOSED
			if (order.status !== "CLOSED") {
				if (fiscalize) await handleFiscalization(data);
				else await handleChangePaymentMethod(data);
			}

			if (inCollectMode) await handleChangePreparationStatus();
		} catch (err: any) {
			const wrongTerminalError = err?.errors?.find(
				(error: Record<string, any>) => error.code === "wrong_order_terminal"
			);
			if (wrongTerminalError) {
				const flashMessage = t("constraints.wrong_terminal_order", {
					terminal_name: wrongTerminalError.terminal_name,
				});
				addFlash({
					type: "danger",
					msg: flashMessage,
					messageAlreadyCreated: true,
				});
			} else handleError.alert(err, addFlash);
		}

		setLoading(false);
	});

	const drawBodyContent = () => {
		if (inCollectMode) {
			return (
				<>
					{!hasWoltDriveDeliveryEmployee && (
						<LiveOrderFiscalizationDeliveryEmployee form={form} setErrors={setErrors} order={order} />
					)}
					{order.status !== "CLOSED" && (
						<>
							<LiveOrderFiscalizationPaymentMethods
								form={form}
								setErrors={setErrors}
								setPaymentMethodOutflowType={setPaymentMethodOutflowType}
							/>
							<FormCheck
								type="switch"
								register={register}
								label={t("modules.live_order.field.order_paid.title")}
								name="paid"
								errors={errors}
								defaultChecked={order.payment_status === "PAID"}
							/>
							{order.payment_status === "PAID" && !watch("paid") && (
								<ChangeTransactionPaymentMethodInfo />
							)}
							<hr />
							{!isFiscalizationForced && (
								<>
									<div className="form-group">
										<Form.Check
											type="switch"
											id="fiscalize"
											className="form-check"
											label={
												paymentMethodOutflowType === "WZ"
													? t("modules.live_order.field.fiscalize.title")
													: t("modules.live_order.field.close.title")
											}
											checked={fiscalize}
											onChange={() => setFiscalize(!fiscalize)}
										/>
									</div>
									<hr />
								</>
							)}
							{paymentMethodOutflowType === "WZ" && organization?.more?.ereceipt_enabled && fiscalize && (
								<LiveOrderFiscalizationTypes
									form={form}
									setErrors={setErrors}
									paymentMethodOutflowType={paymentMethodOutflowType}
									fiscalize={fiscalize}
								/>
							)}
							{fiscalize && <LiveOrderFiscalizationTerminals form={form} />}
						</>
					)}
				</>
			);
		}
		return (
			<>
				<LiveOrderFiscalizationPaymentMethods
					form={form}
					setErrors={setErrors}
					setPaymentMethodOutflowType={setPaymentMethodOutflowType}
				/>
				{paymentMethodOutflowType === "WZ" && organization?.more?.ereceipt_enabled && (
					<LiveOrderFiscalizationTypes
						form={form}
						setErrors={setErrors}
						paymentMethodOutflowType={paymentMethodOutflowType}
						fiscalize
					/>
				)}
				<FormCheck
					type="switch"
					register={register}
					label={t("modules.live_order.field.order_paid.title")}
					name="paid"
					errors={errors}
					defaultChecked={order.payment_status === "PAID"}
				/>
				{order.payment_status === "PAID" && !watch("paid") && <ChangeTransactionPaymentMethodInfo />}
				<hr />
				<LiveOrderFiscalizationTerminals form={form} />
			</>
		);
	};

	return (
		<Modal show={isShown} onHide={onHide} className="live-order-fiscalization-modal">
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{`${
						inCollectMode ? t("common.word.order") : t("modules.live_order.action.close_order.title")
					} ${getLiveOrderNumber(order)}`}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{drawBodyContent()}</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit">
						{inCollectMode ? t("modules.live_order.action.collect.title") : t("lib:common.action.close")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default LiveOrderFiscalizationModal;
