import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { DeviceApi } from "../../../../../../../../../../services/Api/Organization/types";
import DeviceForm from "../../components/DeviceForm";

const OrganizationSettingsDevicesNewPage = (): JSX.Element => {
	const [device] = useState<DeviceApi>({
		type: "PRINTER",
		interface: "LAN",
		parameters: [
			{ name: "driver", value: "POSNET" },
			{ name: "ip_address", value: "" },
			{ name: "port", value: 6666 },
		],
	} as DeviceApi);
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.device.header.new.title"));
	}, []);

	if (!device) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Header title={t("modules.device.header.new.title")} />
			<DeviceForm device={device} />
		</>
	);
};
export default OrganizationSettingsDevicesNewPage;
