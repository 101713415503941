import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { InvoiceApi, InvoiceItemApi } from "../../../../../../../../services/Api/Organization/types";

interface Props {
	correction: InvoiceApi;
	items?: InvoiceItemApi[];
}

const OldCorrectionItemsTable: FC<Props> = ({ correction, items }) => {
	const { t } = useTranslation();
	return (
		<>
			<h5>{t("modules.invoice.field.positions.title")}</h5>
			<div className={"table-responsive"} style={{ marginBottom: "32px" }}>
				<table className={"table table-preview"} style={{ borderBottom: "1px solid #dee2e6" }}>
					<thead>
						<tr>
							<th className={"w-40"}>{t("modules.invoice.field.name.title")}</th>
							<th className={"w-10"}>{t("modules.invoice.field.pkwiu.title")}</th>
							<th className={"w-10"}>{t("modules.invoice.field.gtu.title")}</th>
							<th style={{ width: "9%" }}>
								{correction.type === "GROSS" ? (
									<>
										{t("common.word.value")}
										<br /> {t("modules.invoice.field.gross.title")}
									</>
								) : (
									<>
										{t("common.word.value")}
										<br /> {t("modules.invoice.field.net.title")}
									</>
								)}
							</th>
							<th className={"w-1"}>{t("modules.invoice.field.tax_value.title")}</th>
							<th>{t("common.word.quantity")}</th>
							<th>{t("modules.invoice.field.measure.title")}</th>
							<th className={"w-1"} />
							<th className={"w-1"}>
								{correction.type === "GROSS" ? (
									<>
										{t("common.word.value")}
										<br /> {t("modules.invoice.field.gross.title")}
									</>
								) : (
									<>
										{t("common.word.value")}
										<br /> {t("modules.invoice.field.net.title")}
									</>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						{items?.map((item, index) => {
							return (
								<tr key={index}>
									<td>{item.name}</td>
									<td>{item.pkwiu}</td>
									<td>{item.gtu}</td>
									<td className={"nowrap"}>{FormatMoney(item.price)}</td>
									<td>{item.tax?.name}</td>
									<td>{item.quantity}</td>
									<td>{item.volume}</td>
									<td />
									<td className={"nowrap"}>{FormatMoney(item.price_sum)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};
export default OldCorrectionItemsTable;
