import React, { FC, Suspense, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import { MessageEventApi } from "go-component/components/MessageEvent/services/types";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import ShowJsonModal from "go-core/components/ShowJsonModal/ShowJsonModal";
import { ExportConfig } from "go-list/core/components/Actions/services/types";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { listFiltersDecode, listFiltersParse } from "go-list/core/components/Filter/services/decoder";
import { FilterType } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentContext } from "go-segment/context";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	appId: number;
}

const listName = "APP_LOGS";
const resourceType = "APP_LOGS";

interface ListState {
	resource?: Record<string, any>;
	appId: number;
}

const List: FC<ListState> = ({ resource, appId }) => {
	const [items, setItems] = useState<MessageEventApi[]>([]);
	const [showJson, setShowJson] = useState(false);
	const [logs, setLogs] = useState<Record<string, any> | undefined>(undefined);
	const [body, setBody] = useState<string>("");
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	if (!resource) return null;
	const data = resource.read();

	let config = {
		fields: [
			{
				id: "created_at",
				name: t("lib:common.word.date"),
				type: "date" as FilterType,
				render: (item: MessageEventApi) => {
					return FormatDate(item.created_at, organization.timezone, true);
				},
				renderExport: (item: MessageEventApi) => {
					return FormatDate(item.created_at, organization.timezone, true);
				},
			},
			{
				id: "type",
				name: t("modules.log.field.type.title"),
				type: "list" as FilterType,
				options: {
					INFO: t("enums.apps.logs.app_log_type.INFO"),
					ERROR: t("enums.apps.logs.app_log_type.ERROR"),
					DANGER: t("enums.apps.logs.app_log_type.DANGER"),
				},
				render: (item: MessageEventApi) => {
					if (item.type === "ERROR") {
						return <span className={"badge bg-danger"}>{t("enums.apps.logs.app_log_type.ERROR")}</span>;
					}
					if (item.type === "DANGER") {
						return (
							<span className={"badge bg-warning text-dark"}>
								{t("enums.apps.logs.app_log_type.DANGER")}
							</span>
						);
					}
					return <span className={"badge bg-success"}>{t("enums.apps.logs.app_log_type.INFO")}</span>;
				},
				renderExport: (item: MessageEventApi) => {
					return t(`enums.apps.logs.app_log_type.${item.type}`);
				},
			},
			{
				id: "body",
				name: t("modules.log.field.error.title"),
				type: "text",
			},
		],
		filters: [
			{
				id: "error",
				name: `${t("modules.log.field.body.title")}`,
				type: "text",
			},
		],
		actions: [
			{
				name: `${t("common.action.preview", { ns: "lib" })}`,
				click: (item: Record<string, any>) => {
					setLogs(getErrorJson(item.error));
					setBody(item.body);
					setShowJson(true);
				},
			},
		],
		selectedColumns: ["created_at", "type"],
		segments: [
			{
				id: "all",
				name: `${t("common.word.all", { ns: "lib" })}`,
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any>, sourceToken?: CancelTokenSource) => {
			params.include = "resources,resources.entity_id";
			const newParams = listFiltersDecode(params.f);
			params.f = btoa(
				unescape(
					encodeURIComponent(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}app_id|e=${appId}${FILTER_SEPARATOR}${listFiltersParse(
							newParams
						)}`
					)
				)
			);
			return api.organization().getAppLogs(params, { cancelToken: sourceToken?.token });
		},
		exportConfig: {
			title: t("modules.log.field.export_config.title"),
			filename: t("modules.log.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		} as ExportConfig,
	} as ListConfig;
	const fields = data.fields ? data.fields : [];
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...fields] : fields,
		customFields: fields,
	};

	return (
		<>
			<ListData
				data={items}
				config={config}
				onFetch={(fetchItems: MessageEventApi[]) => {
					setItems(fetchItems);
				}}
			/>
			{showJson && (
				<ShowJsonModal
					stringContent={body}
					logs={logs}
					onHide={() => {
						setShowJson(false);
						setLogs(undefined);
						setBody("");
					}}
				/>
			)}
		</>
	);
};

function getErrorJson(error: string): Record<string, any> {
	try {
		return JSON.parse(error);
	} catch (exc) {
		return {
			message: error,
		};
	}
}

const OrganizationAppsEditLogsPage: FC<Props> = ({ appId }) => {
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContextValue = React.useContext(SegmentContext);

	useEffect(() => {
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<div className={"app-logs-container"}>
			<Suspense fallback={<LoadingContainer />}>
				<List appId={appId} resource={resource} />
			</Suspense>
		</div>
	);
};
export default OrganizationAppsEditLogsPage;
