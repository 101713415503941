import React, { FC, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFlash, { Flash } from "../AlertMessage";
import MissingPermissionList from "./MissingPermissionList";

interface Props {
	missingPermissions: string[];
	eligibleUsers: string[];
	organizationName?: string;
	isShown: boolean;
	onHide: () => void;
}

const ForbiddenAccessModal: FC<Props> = ({ missingPermissions, eligibleUsers, isShown, onHide, organizationName }) => {
	const { t } = useTranslation();
	const { removeFlash, flashes } = useFlash();
	const forbiddenAccessFlash = flashes.find((flash: Flash) => {
		if (flash.msg && typeof flash.msg[0] === "object" && flash.msg[0].code === "access_forbidden") return flash;
		return undefined;
	});

	useEffect(() => {
		if (forbiddenAccessFlash) {
			return () => removeFlash(forbiddenAccessFlash);
		}
	}, []);

	return (
		<Modal show={isShown} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:constraints.access_forbidden")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{missingPermissions.length > 0 && (
					<>
						<h5>{t("lib:go_core.forbidden_access_modal.missing_permissions.title")}:</h5>
						<Card>
							<Card.Body>
								<MissingPermissionList
									permissions={missingPermissions}
									organizationName={organizationName}
								/>
							</Card.Body>
						</Card>
					</>
				)}
				{eligibleUsers.length > 0 && (
					<>
						<h5 className={`${missingPermissions.length > 0 ? "mt-3" : ""}`}>
							{t("lib:go_core.forbidden_access_modal.eligible_users.title")}:
						</h5>
						<div className="d-flex flex-wrap">
							<span>{eligibleUsers.join(", ")}</span>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default ForbiddenAccessModal;
