import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as EditSVG } from "go-core/images/svg/actions/edit.svg";
import { ReactComponent as GripLinesVerticalSVG } from "go-core/images/svg/grip-lines-vertical.svg";
import { FormDirty } from "go-form/components/FormDirty";
import { OrganizationRoleApi } from "go-security/services/Api/typesSecurity";
import { Permission, PermissionTab } from "./service/types";

interface Props {
	roles: OrganizationRoleApi[];
	permissionTabs: PermissionTab[];
	handleSave: (data: any) => void;
	handleUpdateRole: (role: OrganizationRoleApi) => void;
	handleCreateRole: () => void;
	isDirty: boolean;
	onChange: () => void;
	formLoading: boolean;
}

interface PermissionResult {
	id: string;
	resultAmount: number;
}

const SecurityPermissionsTableComponent = (props: Props): JSX.Element => {
	const isMobile = useWindowSize().isMobile;
	const { t } = useTranslation();
	const [currentPermissionsTab, setCurrentPermissionsTab] = useState<PermissionTab>(props.permissionTabs[0]);
	const [search, setSearch] = useState("");
	const [permissions, setPermissions] = useState(currentPermissionsTab?.permissions || []);
	const form = useForm<OrganizationRoleApi[]>({
		criteriaMode: "all",
		defaultValues: props.roles,
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = form;
	const [permissionResults, setPermissionResults] = useState<PermissionResult[]>(
		props.permissionTabs.map((permissionTab) => ({
			id: permissionTab.id,
			resultAmount: permissionTab.permissions.length,
		}))
	);
	const resizerPosition = useRef<number | null>(null);
	const resizerElement = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const element = resizerElement.current;
		initResizer();

		return () => {
			if (!element) return;
			element.removeEventListener("mousedown", handleResizeElementMouseDown, false);
		};
	}, []);

	const handleResizeCard = (event?: MouseEvent) => {
		if (event && resizerElement.current) {
			event.preventDefault();
			event.stopPropagation();
			const newPosition = (resizerPosition.current || 0) - event.x;
			resizerPosition.current = event.x;
			const width = parseInt(getComputedStyle(resizerElement.current, "").width) - newPosition;
			resizerElement.current.style.width = `${width}px`;
		}
	};

	const handleResizeElementMouseDown = (e: MouseEvent) => {
		const BORDER_SIZE = 14;
		if (e.offsetX > (resizerElement.current?.offsetWidth || 0) - BORDER_SIZE) {
			resizerPosition.current = e.x;
			document.addEventListener("mousemove", handleResizeCard, false);
		}
	};

	const handleStartResizing = () => {
		if (!resizerElement.current) return;

		resizerElement.current.addEventListener("mousedown", handleResizeElementMouseDown, false);
	};

	const handleStopResizing = () => {
		document.addEventListener(
			"mouseup",
			() => {
				document.removeEventListener("mousemove", handleResizeCard, false);
			},
			false
		);
	};
	const initResizer = () => {
		handleStartResizing();
		handleStopResizing();
	};

	const onSubmit = handleSubmit(() => {
		props.handleSave(props.roles);
	});

	const isChecked = (perm: Permission, role: OrganizationRoleApi) => {
		return role.permissions.includes(perm.id);
	};

	useEffect(() => {
		props.roles.forEach((role) => {
			permissions.forEach((perm) => {
				const el = document.getElementById(`role[${role.uid}].${perm.id}`) as HTMLInputElement;
				if (el) {
					el.checked = role.permissions.includes(perm.id);
				}
			});
		});
	}, [search, props.roles]);

	const handleChangeAllPermissionsCheck = (role: OrganizationRoleApi, value: boolean) => {
		const checkAllPermissionsElement = document.getElementById(
			`role-${role.uid}-check-all-permission-tab-${currentPermissionsTab.id}`
		) as HTMLInputElement;
		checkAllPermissionsElement.checked = value;
	};

	const handleChange = (perm: Permission, role: OrganizationRoleApi) => {
		const el = document.getElementById(`role[${role.uid}].${perm.id}`) as HTMLInputElement;
		if (!role.permissions.includes(perm.id)) {
			role.permissions.push(perm.id);
			el.checked = true;
		} else {
			role.permissions = role.permissions.filter((rolePermission) => rolePermission !== perm.id);
			el.checked = false;
		}
		const areAllPermissionsChecked = checkIfRoleHasCheckedAllPermissions(role);
		handleChangeAllPermissionsCheck(role, areAllPermissionsChecked);
		props.onChange();
	};

	const handleFindResultsForPermissionTabs = (searchText: string) => {
		const searchResults = props.permissionTabs.map((permissionTab) => {
			const filteredPermissions = permissionTab.permissions.filter(
				(permission) =>
					permission.label?.toLowerCase().includes(searchText) ||
					permission.value?.toLowerCase().includes(searchText)
			);
			return { id: permissionTab.id, resultAmount: filteredPermissions.length };
		});
		setPermissionResults(searchResults);
	};

	const filterPermissions = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value.toLowerCase();
		setSearch(inputValue);
		const permissionTabToFilter = props.permissionTabs.find(
			(permissionTab) => permissionTab.id === currentPermissionsTab.id
		);
		const filteredPermissions = (permissionTabToFilter?.permissions || []).filter(
			(permission) =>
				permission.label?.toLowerCase().includes(inputValue) ||
				permission.value?.toLowerCase().includes(inputValue)
		);
		handleFindResultsForPermissionTabs(inputValue);
		setPermissions(filteredPermissions);
	};

	const getFilteredPermissions = (permissionsToFilter: Permission[]) => {
		const searchValue = search;
		if (!searchValue) return permissionsToFilter;
		return permissionsToFilter.filter(
			(permission) =>
				permission.label?.toLowerCase().includes(searchValue) ||
				permission.value?.toLowerCase().includes(searchValue)
		);
	};

	const handleChangePermissionTab = (permissionTab: PermissionTab) => {
		setCurrentPermissionsTab(permissionTab);
		const filteredPermissions = getFilteredPermissions(permissionTab.permissions);
		setPermissions(filteredPermissions);
	};

	const checkIfRoleHasCheckedAllPermissions = (role: OrganizationRoleApi) => {
		return permissions.every((permission) => role.permissions.includes(permission.id));
	};

	const handleChangePermissionsCheck = (role: OrganizationRoleApi, newPermissions: Permission[], value: boolean) => {
		newPermissions.forEach((permission) => {
			const el = document.getElementById(`role[${role.uid}].${permission.id}`) as HTMLInputElement;
			el.checked = value;
		});
	};

	const handleToggleCheckAllPermissionsForRole = (role: OrganizationRoleApi) => {
		if (checkIfRoleHasCheckedAllPermissions(role)) {
			role.permissions = role.permissions.filter(
				(rolePermission) => !permissions.map(({ id }) => id).includes(rolePermission)
			);
			handleChangePermissionsCheck(role, permissions, false);
			handleChangeAllPermissionsCheck(role, false);
		} else {
			role.permissions = [...role.permissions, ...permissions.map((permission) => permission.id)];
			handleChangePermissionsCheck(role, permissions, true);
			handleChangeAllPermissionsCheck(role, true);
		}
	};

	const getPermissionTabButtonTitle = (permissionTab: PermissionTab) => {
		let resultAmount = 0;
		const searchResult = permissionResults.find((permissionResult) => permissionResult.id === permissionTab.id);
		if (!searchResult) resultAmount = 0;
		else resultAmount = searchResult.resultAmount;

		return `${permissionTab.title} (${resultAmount})`;
	};

	const drawDescription = () => {
		if (!currentPermissionsTab.description) return <></>;
		if (typeof currentPermissionsTab.description === "string") return <p>{currentPermissionsTab.description}</p>;
		return currentPermissionsTab.description;
	};

	return (
		<FormDirty
			isDirty={props.isDirty}
			key="permissions-form"
			noValidate
			loading={props.formLoading}
			onSubmit={onSubmit}
		>
			<Form.Group
				className={`form-group permissions-search ${isMobile ? "mt-2" : "mt-0"}`}
				controlId="permissions-search"
			>
				<Form.Control type="text" placeholder={t("lib:common.word.search")} onChange={filterPermissions} />
			</Form.Group>
			<Card className="permissions-card" ref={resizerElement}>
				<Card.Header
					className={`d-flex flex-column gap-2 justify-content-stretch flex-sm-row justify-content-sm-start flex-sm-wrap`}
				>
					{props.permissionTabs.map((permissionTab, index) => (
						<Button
							onClick={() => handleChangePermissionTab(permissionTab)}
							key={`permission-tab-${index}`}
							variant={currentPermissionsTab.id === permissionTab.id ? "primary" : "outline-primary"}
						>
							{getPermissionTabButtonTitle(permissionTab)}
						</Button>
					))}
				</Card.Header>
				<div className="resize-indicator">
					<GripLinesVerticalSVG />
				</div>
				<div className="permissions-card-upper-container">
					{drawDescription()}
					<Button className={"float-end"} variant="add" onClick={() => props.handleCreateRole()}>
						+ {t("lib:common.action.add")}
					</Button>
				</div>
				<Card.Body>
					{permissions.length > 0 && (
						<table className="table table-form permissions-table">
							<thead>
								<tr key={`permission-${currentPermissionsTab.id}-roles`}>
									<th className="action first-sticky-column" />
									<th className="sticky-column first-sticky-column" />
									{props.roles?.map((role, index) => {
										return (
											<th key={index}>
												<div className="d-flex ">
													<Form.Check
														defaultChecked={checkIfRoleHasCheckedAllPermissions(role)}
														className="me-2"
														key={`role-${role.uid}-check-all-permission-tab-${currentPermissionsTab.id}`}
														id={`role-${role.uid}-check-all-permission-tab-${currentPermissionsTab.id}`}
														onClick={() => handleToggleCheckAllPermissionsForRole(role)}
													/>
													<div
														className="role-checkbox"
														onClick={() => props.handleUpdateRole(role)}
													>
														<span>{role.name}</span>
														<EditSVG />
													</div>
												</div>
											</th>
										);
									})}
									<th className="action" />
								</tr>
							</thead>
							<tbody>
								{permissions.map((perm) => {
									return (
										<tr key={perm.id}>
											<td className="action" />
											<td className="sticky-column">
												<span>{perm.value}</span>
												{perm?.label && <p className="help-block">{perm.label}</p>}
											</td>
											{props.roles?.map((role, index) => {
												return (
													<td
														key={`${index}${perm.id}`}
														onClick={() => handleChange(perm, role)}
													>
														<div className="permission-checkbox">
															<FormCheck
																register={register}
																name={`role[${role.uid}].${perm.id}`}
																errors={errors}
																defaultChecked={isChecked(perm, role)}
															/>
														</div>
													</td>
												);
											})}
											<td className="action" />
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</Card.Body>
			</Card>
		</FormDirty>
	);
};

export default SecurityPermissionsTableComponent;
