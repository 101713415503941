import { Exception } from "go-core/api/types";
import { SubReportApi } from "go-report/core/services/types";

export type CompareFormatReportComboApi = "PERCENT" | "VALUE";
export type TypeReportComboApi = "CALCULATE";
export interface KpisConfigReportComboApi {
	kpis: ConfigReportComboApi[];
}

export interface ConfigReportComboApi {
	id: string;
	name: string;
	description?: string;
	data: DataConfigReportComboApi[];
	rows: RowConfigReportComboApi[];
	columns: ColumnConfigReportComboApi[];
}

export interface DataConfigReportComboApi {
	id: string;
	resource: string;
	filter: string;
	groups: string[];
	columns: DataColumnConfigReportComboApi[];
}

export interface DataColumnConfigReportComboApi {
	id: string;
	filter: string;
	group?: string;
	compare?: DataColumnCompareConfigReportComboApi;
}

export interface DataColumnCompareConfigReportComboApi {
	filter: string;
}

export interface RowConfigReportComboApi {
	id: string;
	data_id: string;
	name: string;
	group?: string;
	group_values?: string[];
	aggregate: string;
	aggregate_columns?: Record<string, string>;
	compare?: RowCompareConfigReportComboApi[];
	type?: TypeReportComboApi;
}

export interface RowCompareConfigReportComboApi {
	id: string;
	data_id?: string;
	name: string;
	aggregate: string;
	aggregate_columns?: Record<string, string>;
	format: CompareFormatReportComboApi;
	type?: TypeReportComboApi;
}

export interface ColumnConfigReportComboApi {
	id: string;
	data_column_id: string;
	name: string;
	values_dynamic?: string;
	values?: ColumnConfigValueReportComboApi[];
}
export interface ColumnConfigValueReportComboApi {
	id: string;
	name?: string;
}

export interface ResponseReportComboApi {
	id: string;
	name: string;
	reports: ReportResponseReportComboApi[];
}

export interface ReportResponseReportComboApi {
	id: string;
	resource: string;
	column_id: string;
	data: SubReportApi;
	compare_data: SubReportApi;
}

export interface FlatReportComboApi {
	id: string;
	name: string;
	columns?: ColumnConfigReportComboApi[];
	results?: ResultFlatReportComboApi[];
}

export interface ResultFlatReportComboApi {
	data_id: string;
	row_id: string;
	name: string;
	sub_report?: ResultFlatReportComboApi[];
	data: ResultDataFlatReportComboApi[];
}

export interface ResultDataFlatReportComboApi {
	id: string;
	column_id: string;
	data_column_id: string;
	aggregate: Record<string, string>;
	value: any;
	compare?: ResultDataCompareFlatReportComboApi[];
}

export interface ResultDataCompareFlatReportComboApi {
	compare_id: string;
	aggregate: Record<string, string>;
	value: any;
}

export class ValidationReportComboException extends Error implements Exception {
	constructor(message: string) {
		super(`Validation Error: ${message}`);
	}
}
