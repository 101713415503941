import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	ItemFormApi,
	ItemGroupGroupEditingFormProps,
	PointOfSaleApi,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import VirtualTableHeaderActionsColumn from "../../../../../../components/VirtualTable/VirtualTableHeaderActionsColumn";
import { VirtualTableColumn } from "../../../../../../components/VirtualTable/types";
import {
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../../utils";
import ItemGroupGroupEditingEntityModal from "../ItemGroupGroupEditingEntityModal";

interface Props {
	visibleDefaultColumns: VirtualTableColumn[];
	visiblePointsOfSale: VirtualTableColumn[];
	visibleOtherColumns: VirtualTableColumn[];
	form: UseFormReturn<ItemGroupGroupEditingFormProps>;
	items: ItemFormApi[];
	maxZIndex: number;
	pointsOfSale: PointOfSaleApi[];
}
const ItemGroupGroupEditingVirtualTableHeaderActionsRow = ({
	items,
	maxZIndex,
	visibleDefaultColumns,
	form,
	visiblePointsOfSale,
	visibleOtherColumns,
	pointsOfSale,
}: Props) => {
	const { t } = useTranslation();
	let prevLeftPosition = 0;
	const { setValue } = form;
	const [showEntityModal, setShowEntityModal] = useState(false);
	const [entityType, setEntityType] = useState<"availability" | "direction" | "pointOfSale" | "">("");
	const [pointOfSaleIndex, setPointOfSaleIndex] = useState<number | undefined>();

	const getPointOfSaleColumnActions = () => [
		{
			onClick: () => handleRestrictAll(),
			title: t("modules.item_group_group_editing.field.limit.title"),
		},
		{
			onClick: () => handleRestrictAll(true),
			title: t("modules.item_group_group_editing.field.no_limit.title"),
		},
	];

	const handleRestrictAll = (undo?: boolean) => {
		if (undo) {
			items.forEach((item) => {
				setValue(`items.${item.index}.direction_id`, undefined);
				setValue(`items.${item.index}.direction`, undefined);
				setValue(`items.${item.index}.point_of_sale_visible`, false);
				item.points_of_sale.forEach((_, pointOfSaleIndex) => {
					setValue(`items.${item.index}.points_of_sale.${pointOfSaleIndex}.visibility`, false);
				});
			});
		} else {
			items.forEach((item) => {
				setValue(`items.${item.index}.point_of_sale_visible`, true);
				item.points_of_sale.forEach((_, pointOfSaleIndex) => {
					setValue(`items.${item.index}.points_of_sale.${pointOfSaleIndex}.visibility`, true);
				});
			});
		}
	};

	const getPointsOfSaleColumnActions = (pointIndex: number) => [
		{
			onClick: () => handleRestrictPointOfSale(pointIndex, false),
			title: t("modules.item_group_group_editing.field.limit.title"),
		},
		{
			onClick: () => handleRestrictPointOfSale(pointIndex, true),
			title: t("modules.item_group_group_editing.field.no_limit.title"),
		},
		{
			onClick: () => {
				setPointOfSaleIndex(pointIndex);
				handleSetEntity("pointOfSale");
			},
			title: t("modules.item_group_group_editing.action.set_direction.title"),
		},
	];

	const getOtherColumnActions = (id: string) => [
		{
			onClick: () => {
				handleSetEntity(id as "availability" | "direction" | "pointOfSale");
			},
			title: t("lib:common.action.set"),
		},
	];

	const handleRestrictPointOfSale = (pointIndex: number, value: boolean) => {
		items.forEach((item) => {
			setValue(`items.${item.index}.points_of_sale.${pointIndex}.visibility`, value);
		});
	};

	const handleSetEntity = (type: "availability" | "direction" | "pointOfSale") => {
		setShowEntityModal(true);
		setEntityType(type);
	};

	const handleOnHideEntityModal = () => {
		setShowEntityModal(false);
		setEntityType("");
		setPointOfSaleIndex(undefined);
	};

	return (
		<>
			{showEntityModal && (
				<ItemGroupGroupEditingEntityModal
					show={showEntityModal}
					onHide={handleOnHideEntityModal}
					type={entityType}
					form={form}
					items={items}
					pointOfSaleIndex={pointOfSaleIndex}
				/>
			)}
			<tr className="additional-header-row">
				{visibleDefaultColumns.map((column, index) => {
					const prevColumn = visibleDefaultColumns[index - 1];
					const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
					prevLeftPosition = leftPosition;
					const lastColumn = index === visibleDefaultColumns.length - 1;
					const isHide = isVirtualTableColumnHide(column.id, visibleDefaultColumns);

					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							id={column.id}
							leftPosition={leftPosition}
							isSticky
							lastColumn={lastColumn}
							zIndex={maxZIndex - index}
						/>
					);
				})}
				{visiblePointsOfSale.map((column, columnIndex) => {
					const isHide = isVirtualTableColumnHide(column.id, visiblePointsOfSale);
					const lastColumn = columnIndex === visiblePointsOfSale.length - 1;

					if (column.id === ItemGroupGroupEditingColumn.POINT_OF_SALE) {
						return (
							<VirtualTableHeaderActionsColumn
								key={column.id}
								isHidden={isHide}
								actions={getPointOfSaleColumnActions()}
								id={column.id}
								lastColumn={lastColumn}
							/>
						);
					}

					const index = pointsOfSale.findIndex((point) => point.id.toString() === column.id);
					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							actions={getPointsOfSaleColumnActions(index)}
							id={column.id}
							lastColumn={lastColumn}
						/>
					);
				})}
				{visibleOtherColumns.map((column) => {
					const isHide = isVirtualTableColumnHide(column.id, visibleOtherColumns);

					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							actions={getOtherColumnActions(column.id)}
							id={column.id}
						/>
					);
				})}
			</tr>
		</>
	);
};

export default ItemGroupGroupEditingVirtualTableHeaderActionsRow;
