import { FC } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as ClientLogo } from "../../../../../../../../images/svg/bills/employee.svg";
import { ReactComponent as HomeSVG } from "../../../../../../../../images/svg/bills/home.svg";
import { ReactComponent as MessageSVG } from "../../../../../../../../images/svg/bills/message.svg";
import { ReactComponent as TaxSVG } from "../../../../../../../../images/svg/bills/tax.svg";
import { InvoiceReceiverApi, InvoiceRecipientApi } from "../../../../../../../../services/Api/Organization/types";
import { AddressApi } from "../../../../../../../../services/Api/types";

interface Props {
	client: InvoiceReceiverApi | InvoiceRecipientApi;
	id: number | string;
	title: string;
}

const InvoiceReceiverCard: FC<Props> = ({ client, id, title }) => {
	const organization = useSelector(selectOrganization);
	return (
		<Card className={"card-full-height"}>
			<Card.Body>
				<h5>{title}</h5>
				<div className="card-body-item">
					{client.avatar_link ? (
						<img
							src={client.avatar_link.small}
							style={{ marginRight: "8px", width: "24px", borderRadius: "50%" }}
						/>
					) : (
						<ClientLogo />
					)}
					<div className="item-info">
						<span>
							{id ? (
								<Link target={"_blank"} rel={"noreferrer"} to={`/${organization.id}/clients/${id}`}>
									{client.name}
								</Link>
							) : (
								<>{client.name}</>
							)}
						</span>
					</div>
				</div>
				{client.address &&
					Object.keys(client.address).some((x) => client.address[x as keyof AddressApi] !== "") && (
						<div className="card-body-item">
							<HomeSVG />
							<div className="item-info">
								<span>
									<FormatAddress address={client.address} />
								</span>
							</div>
						</div>
					)}
				{client.tax_id_no && (
					<div className="card-body-item">
						<TaxSVG />
						<div className="item-info">
							<span>{client.tax_id_no}</span>
						</div>
					</div>
				)}
				{client.contact?.email && (
					<div className="card-body-item">
						<MessageSVG />
						<div className="item-info">
							<span>{client.contact.email}</span>
						</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

export default InvoiceReceiverCard;
