import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmptyData from "go-core/components/EmptyData";
import { isLicenseBlocked } from "../../services/license";
import { selectOrganization } from "../../services/organizations/selectors";
import { OrganizationState } from "../../services/organizations/types";

interface Props {
	organization: OrganizationState;
	goaccounts_url: string;
}

const OrganizationBlockedView = (props: Props): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	const goAccountsSubscriptionsUrl = `${props.goaccounts_url}/enter_organization?company_id=${organization.company_id}&redirect_page=subscriptions`;

	if (!isLicenseBlocked(organization.license_expires_at, organization.license_type, organization.timezone)) {
		return null;
	}
	if (organization.license_type === "NOT_PAID") {
		return (
			<EmptyData
				title={t("lib:go_security.license.errors.not_paid.title")}
				description={t("lib:go_security.license.errors.not_paid.description")}
				full={true}
				actions={[
					{
						name: t("lib:go_security.license.action.go_to_payment"),
						variant: "primary",
						click: goAccountsSubscriptionsUrl,
					},
				]}
			/>
		);
	}
	return (
		<EmptyData
			title={t("lib:go_security.license.errors.expired.title")}
			description={t("lib:go_security.license.errors.expired.description")}
			full={true}
			actions={[
				{
					name: t("lib:go_security.license.action.go_to_subscription"),
					variant: "primary",
					click: goAccountsSubscriptionsUrl,
				},
			]}
		/>
	);
};
export default OrganizationBlockedView;
