import React from "react";
import { useTranslation } from "react-i18next";

export const GoPOSFooterInfo = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<section data-testid="e-order-footer-gopos-info">
			<img src="/graphics/logo.png" alt="GoPOS Logo" className="gopos-logo" />
			<div>
				{t("modules.e_order.field.gopos_info.title")}
				<div>
					<a href="https://gopos.pl/" target="_blank" rel="noreferrer">
						gopos.pl
					</a>
				</div>
			</div>
		</section>
	);
};
