import React from "react";
import { StaticMultiActionsBar, StaticMultiActionsBarProps } from "./multiActionsBars/StaticMultiActionsBar";
import { StickyMultiActionsBar, StickyMultiActionsBarProps } from "./multiActionsBars/StickyMultiActionsBar";

interface MultiActionsBarProps extends StaticMultiActionsBarProps, StickyMultiActionsBarProps {
	tableHeaderRowRef: React.RefObject<HTMLTableRowElement>;
}

export const MultiActionsBar = ({ tableHeaderRowRef, ...props }: MultiActionsBarProps): JSX.Element => {
	return (
		<tr className="multi-actions-bar" style={{ top: tableHeaderRowRef.current?.clientHeight ?? 0 }}>
			{props.isButtonSticky ? <StickyMultiActionsBar {...props} /> : <StaticMultiActionsBar {...props} />}
		</tr>
	);
};
