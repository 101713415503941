import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { Range } from "react-date-range";
import { DateRangePicker } from "go-form/components/DateRangePicker";
import { isPredefinedRange, parseRangeToString, parseStringToRange } from "../../../../../utils/daterangeutils";
import { FilterCustomAction, FilterDateRange } from "../../services/types";

interface State {
	value: string;
	onChange: (value: string, predefinedRange?: string, autoApply?: boolean) => void;
	minDate?: Date | string;
	maxDate?: Date | string;
	customActions?: FilterCustomAction[];
	customRanges?: FilterDateRange[];
}

const ListFilterDateRange3: FC<State> = (props) => {
	const predefineRange = isPredefinedRange(props.value) ? props.value : undefined;
	const onChangeValue = (range?: Range, predefinedRange?: string, autoApply?: boolean) => {
		if (!range || (!range.startDate && !range.endDate)) {
			props.onChange("", predefinedRange, autoApply);
		} else {
			const rangeString = parseRangeToString(range);
			props.onChange(rangeString, predefinedRange, autoApply);
		}
	};
	return (
		<>
			<Form.Group className="form-group">
				<DateRangePicker
					range={parseStringToRange(props.value)}
					predefineRange={predefineRange}
					onApply={(range, predefinedRange) => onChangeValue(range, predefinedRange, true)}
					onChangeRange={onChangeValue}
					minDate={props.minDate}
					maxDate={props.maxDate}
					customActions={props.customActions}
					customRanges={props.customRanges}
				/>
			</Form.Group>
		</>
	);
};

export default ListFilterDateRange3;
