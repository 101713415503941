import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "go-app/components/Header";
import CustomFieldForm from "go-component/components/CustomField/CustomFieldForm";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";

const OrganizationSettingsCustomFieldsNewPage = (): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.custom_field.header.new.title"));
	}, []);

	const onSuccess = (res: CustomFieldTemplateApi) => {
		history.push(`/${organization.id}/settings/custom_fields/${res.id}`);
	};

	return (
		<div className="content">
			<Header title={t("modules.custom_field.header.new.title")} />
			<CustomFieldForm
				customField={
					{
						type: "NUMBER",
					} as CustomFieldTemplateApi
				}
				onSuccess={onSuccess}
			/>
		</div>
	);
};
export default OrganizationSettingsCustomFieldsNewPage;
