import { FC, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Select from "react-select";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { Exception } from "go-core/api/types";
import BlankLayout from "go-core/components/Layout/BlankLayout";
import { LoadingContainer } from "go-core/components/Loading";
import { useAuth } from "go-security/services/auth";
import { selectIsAuthenticated } from "go-security/services/session/selectors";
import { selectUser } from "go-security/services/users/selectors";
import { apiSecurity } from "../../services/Api/api";
import { UserOrganizationState } from "../../services/users/types";

interface Props extends RouteComponentProps {
	baseUrl: string;
}

const getSelectedOrganization = (id: number | undefined, orgs: UserOrganizationState[] | undefined) => {
	if (!id) return undefined;
	if (!orgs || orgs.length < 0) return undefined;
	return id ? orgs.filter((x) => x.id === Number(id))[0] : orgs[0];
};
const SecurityAuthorizePage: FC<Props> = (props) => {
	const [loadingAuth, setLoadingAuth] = useState(true);
	const [loading, setLoading] = useState(true);
	const auth = useAuth(props.baseUrl);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const location = useLocation();
	const { t } = useTranslation();
	const history = useHistory();
	const { addFlash } = useFlash();
	const user = useSelector(selectUser);
	const searchParams = new URLSearchParams(props.location.search);
	const orgIdString = searchParams.get("context");
	const orgId = orgIdString ? Number(orgIdString) : undefined;
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(orgId);
	const [organizationId, setOrganizationId] = useState<number | undefined>(undefined);
	const [scopes, setScopes] = useState<string[]>([]);
	if (isAuthenticated && loadingAuth) {
		setLoadingAuth(false);
	}
	const userOrganizations = user.organizations;
	const organization = getSelectedOrganization(organizationId, userOrganizations);

	const redirectUri = searchParams.get("redirect_uri");
	const clientId = searchParams.get("client_id");
	const response_type = searchParams.get("response_type");
	const scope = searchParams.get("scope");
	const state = searchParams.get("state");
	const provider = searchParams.get("provider") ? searchParams.get("provider") : "";
	useEffect(() => {
		if (!isAuthenticated) {
			auth.checkLogin()
				.then(() => {
					setLoadingAuth(false);
				})
				.catch((err: Exception | unknown) => {
					handleError.alert(err, addFlash);
				});
		}
	}, []);
	useEffect(() => {
		if (!loadingAuth) {
			if (!isAuthenticated) {
				history.push({ pathname: "/login", state: { from: location } });
			} else if (orgId) {
				// setSelectedOrganization(user?.organizations && user?.organizations?.length > 0 ? user.organizations[0] : undefined)
				checkAuthorize(orgId);
			} else {
				setSelectedOrganizationId(
					user?.organizations && user?.organizations?.length > 0 ? user.organizations[0].id : undefined
				);
				setLoading(false);
			}
		}
	}, [isAuthenticated, loadingAuth]);
	const handleAuthorize = async (organizationId: number | undefined, scopes: string[], approve: boolean) => {
		const data = {
			client_id: clientId,
			response_type,
			scope: scopes?.join(" "),
			redirect_uri: redirectUri,
			state,
			user_oauth_approval: approve,
		};
		try {
			const res = await apiSecurity.authorize(organizationId, data);
			// let res = await apiSecurity.checkAuthorize(organizationId, data);
			if (res.data.data.redirect_uri) {
				window.location.href = res.data.data.redirect_uri;
			} else {
				console.log("ERROR");
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};
	// user_oauth_approval
	const checkAuthorize = async (organizationId: number | undefined, scopes?: string[]) => {
		const data = {
			client_id: clientId,
			response_type,
			scope,
			redirect_uri: redirectUri,
			state,
		};
		try {
			const res = await apiSecurity.checkAuthorize(organizationId, data);
			// let res = await apiSecurity.checkAuthorize(organizationId, data);
			if (res.data.data.redirect_uri) {
				window.location.href = res.data.data.redirect_uri;
			} else {
				setOrganizationId(organizationId);
				if (res.data.data.scopes) {
					setScopes(res.data.data.scopes.split(" "));
				}
				if (!orgId) {
					searchParams.append("context", `${organizationId}`);
				}
				props.history.push({
					search: `?${searchParams.toString()}`,
				});
			}
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	if (loadingAuth || loading || !user?.organizations) {
		return <LoadingContainer />;
	}

	if (organization) {
		return (
			<BlankLayout>
				<div className={"d-flex align-items-center justify-content-center"}>
					<Card style={{ margin: "100px" }}>
						<Card.Body>
							<h5>{`${t("lib:go_security.components.authorize.app")} ${provider} ${t(
								"lib:go_security.components.action.ask_organization_access"
							)} ${organization.name} ${t("lib:go_security.components.action.to_following_info")}:`}</h5>
							<ul>
								{scopes.map((scope, index) => {
									return <li key={index}>{scope}</li>;
								})}
							</ul>
							<Form.Group className="form-group">
								<Button
									variant={"primary"}
									onClick={() => handleAuthorize(selectedOrganizationId, scopes, true)}
								>
									{t("lib:common.action.accept")}
								</Button>
								<Button
									variant={"light"}
									onClick={() => handleAuthorize(selectedOrganizationId, scopes, false)}
								>
									{t("lib:common.action.reject")}
								</Button>
							</Form.Group>
						</Card.Body>
					</Card>
				</div>
			</BlankLayout>
		);
	}

	return (
		<BlankLayout>
			<div className={"d-flex align-items-center justify-content-center"}>
				<Card style={{ margin: "100px" }}>
					<Card.Body>
						<h5>{`${t("lib:go_security.components.authorize.app")} ${provider} ${t(
							"lib:go_security.components.action.ask_data_access"
						)}`}</h5>
						<Select
							className="menu-select mb-3"
							value={getSelectedOrganization(selectedOrganizationId, user.organizations)}
							defaultValue={
								user?.organizations && user?.organizations?.length > 0 ? user.organizations[0] : null
							}
							onChange={(opt) => setSelectedOrganizationId(opt ? opt.id : undefined)}
							getOptionLabel={(opt) => opt.name}
							getOptionValue={(opt) => opt.id.toString()}
							options={user?.organizations}
						/>
						<Form.Group className="form-group">
							<Button variant={"primary"} onClick={() => checkAuthorize(selectedOrganizationId)}>
								{t("lib:common.action.accept")}
							</Button>
							<Button
								variant={"light"}
								onClick={() => (redirectUri ? (window.location.href = redirectUri) : history.push("/"))}
							>
								{t("lib:common.action.reject")}
							</Button>
						</Form.Group>
					</Card.Body>
				</Card>
			</div>
		</BlankLayout>
	);
};

export default SecurityAuthorizePage;
