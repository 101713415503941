import { SingleActionList, SingleActionListProps } from "../actionsLists/SingleActionList";

interface SingleActionsButtonProps extends SingleActionListProps {
	isButtonSticky: boolean;
	status?: "ENABLED" | "DISABLED" | "WARNING";
}

export const SingleActionsButton = ({
	actions,
	item,
	mainDivRef,
	status,
	isButtonSticky,
	doesIdColumnRedirectToPreviewPage,
	params,
}: SingleActionsButtonProps): JSX.Element | null => {
	if (!actions && !status) {
		return null;
	}

	return (
		<td className={`text-end ${isButtonSticky ? "sticky-single-action-button-container" : ""}`}>
			<div className="d-flex align-items-center justify-content-end">
				<SingleActionList
					actions={actions}
					item={item}
					mainDivRef={mainDivRef}
					params={params}
					doesIdColumnRedirectToPreviewPage={doesIdColumnRedirectToPreviewPage}
				/>
			</div>
		</td>
	);
};
