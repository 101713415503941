import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { orderTypeOptions } from "../../../services/orderFilters";
import { LiveOrderFilterOption, LiveOrdersFilters } from "../../../services/types";
import LiveOrdersTypeFilterOption from "./LiveOrdersTypeFilterOption";

interface Props {
	innerFilters: LiveOrdersFilters;
	setInnerFilters: (filters: LiveOrdersFilters) => void;
}

const LiveOrdersTypeFilter = ({ setInnerFilters, innerFilters }: Props) => {
	const { t } = useTranslation();

	const handleOnOptionClick = (value: LiveOrderFilterOption) => {
		setInnerFilters({
			...innerFilters,
			type: value.id === innerFilters.type?.id ? null : value,
		});
	};

	return (
		<div className="mt-2">
			<Form.Label>{t("modules.live_order.field.order_type.title")}</Form.Label>
			<div className="d-flex flex-wrap gap-2">
				{orderTypeOptions(t).map((option) => (
					<LiveOrdersTypeFilterOption
						option={option}
						key={option.id}
						isSelected={innerFilters.type?.id === option.id}
						handleOnClick={() => handleOnOptionClick(option)}
					/>
				))}
			</div>
		</div>
	);
};

export default LiveOrdersTypeFilter;
