import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as RemoveSVG } from "go-core/images/svg/actions/remove.svg";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { CustomFieldPermissionApi, CustomFieldTemplateApi } from "go-segment/services/types";

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:enums.custom_field.scope.READ");
	t("lib:enums.custom_field.scope.WRITE");
};

interface Props {
	form: UseFormReturn<CustomFieldTemplateApi>;
}

const CustomFieldPermissionsForm: FC<Props> = ({ form }) => {
	const {
		formState: { errors },
		control,
		register,
	} = form;
	const { t } = useTranslation("admin");
	const { fields, remove, append } = useFieldArray({
		control,
		name: "permissions",
		keyName: "key",
	});
	const isMobile = useWindowSize().isMobile;

	const onRemove = (index: number) => {
		remove(index);
	};

	const scopeOptions = ["READ", "WRITE"];

	const getScopes = () =>
		scopeOptions.map((scope) => {
			return {
				label: t(`lib:enums.custom_field.scope.${scope}`),
				value: scope,
			};
		});

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form">
					<thead>
						<tr>
							<th className="action" />
							<th style={{ minWidth: isMobile ? "235px" : "unset" }}>
								{t("lib:go_component.custom_field.field.permissions.role")}
							</th>
							<th style={{ minWidth: isMobile ? "165px" : "unset" }}>
								{t("lib:go_component.custom_field.field.permissions.scope")}
							</th>
							<th className="action" />
						</tr>
					</thead>
					<tbody>
						{fields.map((item, index) => {
							return (
								<tr key={fields[index].key}>
									<td className="action" />
									<td>
										<FormInput
											name={`permissions.${index}.role`}
											register={register}
											errors={errors}
										/>
									</td>
									<td>
										<FormSelectGroup
											control={control}
											options={getScopes()}
											name={`permissions.${index}.scope`}
											errors={errors}
											data-testid={`permissions.${index}.scope`}
										/>
									</td>
									<td className="action">
										<RemoveSVG className="icon" onClick={() => onRemove(index)} />
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<Button
				variant="add"
				type="button"
				onClick={() => append({ scope: "", role: "" } as CustomFieldPermissionApi)}
			>{`+${t("common.action.add", { ns: "lib" })}`}</Button>
		</>
	);
};

export default CustomFieldPermissionsForm;
