import React, { useState } from "react";

const getXQuarters = (numQuarters: number): { id: string; value: number; name: string }[] => {
	const quarters = ["Q1", "Q2", "Q3", "Q4"];
	const result: { id: string; value: number; name: string }[] = [];

	const dateNow = new Date();
	const currentMonth = dateNow.getMonth(); // 0 = January, 11 = December
	let currentQuarter = Math.floor(currentMonth / 3); // 0 = Q1, 1 = Q2, 2 = Q3, 3 = Q4

	for (let i = 0; i < numQuarters; i++) {
		const quarter = quarters[currentQuarter];
		const year = dateNow.getFullYear();

		// Format the quarter and year as "{year}-Q{x}"
		const formattedQuarter = `${year}-${quarter}`;

		result.push({
			id: `previous_${i}_quarters`,
			value: currentQuarter,
			name: formattedQuarter,
		});

		// Move to the previous quarter
		currentQuarter--;
		if (currentQuarter < 0) {
			currentQuarter = 3;
			dateNow.setFullYear(dateNow.getFullYear() - 1);
		}
	}

	return result;
};

interface Props {
	name: string;
	filter?: Record<string, string>;
	onChange: (value: Record<string, string>) => void;
}

const QuarterReportCombo = (props: Props) => {
	const [value, setValue] = useState<string | undefined>(props.filter ? props.filter[props.name] : undefined);
	const quarters = getXQuarters(6);

	const onChange = (newValue: string) => {
		const filterQuarter = quarters.filter((x) => x.id === newValue)[0];
		setValue(filterQuarter.id);
		const parserFilters: Record<string, any> = {
			[props.name]: `${filterQuarter.id}`,
		};

		props.onChange(parserFilters);
	};
	return (
		<div className="content-navigation">
			<div role="group" className="me-2 text-nowrap flex-wrap dashboard-filters-buttons btn-group">
				{quarters.map((quarter) => {
					return (
						<button
							type="button"
							onClick={() => onChange(quarter.id)}
							className={`btn${value && quarter.id === value ? " btn-primary" : " btn-default"}`}
							key={quarter.id}
						>
							{quarter.name}
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default QuarterReportCombo;
