import React, { Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { TFunction, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext, SegmentListService } from "go-segment/context";
import { apiTenant } from "../../services/Api/apiTenant";
import { TenantApi } from "../../services/Api/typesSecurity";
import ChangeTenantHttpStatusModal from "./ChangeTenantHttpStatusModal";
import TenantStatus from "./TenantStatus";

interface ListProps {
	resource: any;
}

const listName = "TENANT";
const resourceType = "TENANT";

const buildConfig = (
	t: TFunction<"translation", undefined>,
	data: any,
	segmentListService: SegmentListService,
	migrateTenant: (tenantId: number) => void,
	setTenantIdToChangeHttpStatus: (value: number) => void
) => {
	let config = {
		fields: [
			{
				id: "id",
				name: "ID",
			},
			{
				id: "name",
				name: t("lib:common.word.name"),
			},
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list",
				listOptions: {
					DRAFT: t("lib:go_security.enums.tenant.status.DRAFT"),
					PREBUILT: t("lib:go_security.enums.tenant.status.PREBUILT"),
					ACTIVE: t("lib:go_security.enums.tenant.status.ACTIVE"),
					TO_CREATE: t("lib:go_security.enums.tenant.status.TO_CREATE"),
				},
				render: (item: TenantApi) => <TenantStatus status={item.status} />,
				renderExport: (item: TenantApi) => t(`lib:go_security.enums.tenant.status.${item.status}`),
			},
			{
				id: "organizations",
				name: t("lib:go_security.tenant.field.organizations.title"),
				render: (item: TenantApi) => (
					<RenderLimitedText minWidth={200}>
						{item.organizations.map((organization, index) => (
							<Link to={`/${organization.id}`} key={`organization-${organization.id}`}>
								{`${organization.name}${index !== item.organizations.length - 1 ? ", " : ""}`}
							</Link>
						))}
					</RenderLimitedText>
				),
				renderExport: (item: TenantApi) =>
					item.organizations.map((organization) => organization.name).join(", "),
				disableSorting: true,
			},
			{
				id: "database",
				name: t("lib:go_security.tenant.field.database.title"),
				render: (item: TenantApi) => (
					<div className="d-flex flex-column">
						<span>{item.database?.name}</span>
						<small className="text-muted">{item.database?.host}</small>
						<small className="text-muted">{item.schema_version}</small>
					</div>
				),
				renderExport: (item: TenantApi) => `${item.database?.name} (${item.database?.host})`,
			},
		],
		actions: [
			{
				name: t("lib:go_security.tenant.action.migrate.title"),
				click: (item: TenantApi) => migrateTenant(item.id),
			},
			{
				name: t("lib:go_security.tenant.action.change_tenant_http_status.title"),
				click: (item: TenantApi) => setTenantIdToChangeHttpStatus(item.id),
			},
		],
		selectedColumns: ["id", "name", "status", "organizations", "database"],
		segments: [
			{
				id: "all",
				name: t("lib:common.word.all"),
				slug: "all",
			},
		],
		filters: [
			{
				id: "schema_version",
				name: "Schema version",
				type: "number",
			},
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("lib:go_security.tenant.field.export_config.title"),
			filename: t("lib:go_security.tenant.header.title"),
		},
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			return apiTenant.getTenants(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentListService.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return config;
};

const List = ({ resource }: ListProps) => {
	const { t } = useTranslation();
	const [responseData, setResponseData] = useState<TenantApi[]>([]);
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContextValue = useContext(SegmentContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [tenantIdToChangeHttpStatus, setTenantIdToChangeHttpStatus] = useState<number>(-1);
	const isMobile = useWindowSize().isMobile;

	if (!resource) return null;

	const migrateTenants = async () => {
		setLoading(true);
		try {
			await apiTenant.migrateTenants();
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const migrateTenant = async (tenantId: number) => {
		try {
			await apiTenant.migrateTenant(tenantId);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [
		{
			title: t("lib:go_security.tenant.action.migrate.title"),
			loading,
			action: migrateTenants,
			variant: "primary",
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("lib:go_security.tenant.action.migrate.title"),
			action: migrateTenants,
		},
	];

	const data = resource.read();
	const config = buildConfig(t, data, segmentContextValue, migrateTenant, setTenantIdToChangeHttpStatus);

	return (
		<>
			{!isMobile && <Header title={t("lib:go_security.tenant.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<ListData data={responseData} config={config} onFetch={setResponseData} mobileActions={mobileActions} />
			</Suspense>
			{tenantIdToChangeHttpStatus !== -1 && (
				<ChangeTenantHttpStatusModal
					tenantId={tenantIdToChangeHttpStatus}
					onHide={() => setTenantIdToChangeHttpStatus(-1)}
				/>
			)}
		</>
	);
};

const TenantList = (): JSX.Element => {
	const [resource, setResource] = useState<any>();
	const segmentContextValue = useContext(SegmentContext);
	const { t } = useTranslation();
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("lib:go_security.tenant.header.title"));
		setResource(wrapPromise(segmentContextValue.get(listName, resourceType)));
	}, []);

	return (
		<Suspense fallback={<LoadingContainer />}>
			<List resource={resource} />
		</Suspense>
	);
};

export default TenantList;
