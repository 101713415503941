import React, { FC, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { booleanFilterOptions, fullBooleanFilterOptions, getLimitedOptions } from "../../services";
import { FilterCondition, ListConfigFilter, ListConfigFilterOption, ListSelectedFilter } from "../../services/types";

interface State {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	onChange: (condition: FilterCondition, selectedFilters: string) => void;
}

const ListFilterBoolean: FC<State> = (props) => {
	const { t } = useTranslation();
	let options = booleanFilterOptions(t);
	options = getLimitedOptions(
		fullBooleanFilterOptions(t),
		options.map((o) => o.id as FilterCondition),
		props.filter.typeOptions,
		props.filter.extraOptions
	);
	const [value, setValue] = useState(
		props.selectedFilter && props.selectedFilter.value ? props.selectedFilter.value : options[0]?.id || ""
	);
	const [type, setType] = useState<FilterCondition>(
		props.selectedFilter.condition ? props.selectedFilter.condition : ((options[0]?.id || "") as FilterCondition)
	);
	const onInputClick = (value: FilterCondition, evt?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		evt?.stopPropagation();
		onChangeType(value);
	};

	const onChangeType = (type: FilterCondition) => {
		setType(type);
		setValue(type);
		props.onChange(type, value);
	};

	useEffect(() => {
		props.onChange(type, value);
	}, []);

	const renderOption = (option: ListConfigFilterOption) => {
		return (
			<Dropdown.Item
				key={`type_${props.id}_${option.id}`}
				onClick={() => onChangeType(option.id as FilterCondition)}
			>
				<Form.Check
					type="radio"
					className="form-check"
					label={option.name}
					name={`type_${props.id}_${option.id}`}
					id={`type_${props.id}_${option.id}`}
					value={option.id}
					checked={type === option.id}
					onClick={(e) => onInputClick(e.currentTarget.value as FilterCondition, e)}
				/>
			</Dropdown.Item>
		);
	};

	return (
		<>
			<Form.Group className="form-group">{options.map(renderOption)}</Form.Group>
		</>
	);
};

export default ListFilterBoolean;
