import React, { FC } from "react";
import { OrganizationState } from "../../../services/organizations/types";
import { UserState } from "../../../services/users/types";
import NavigationBottomProfileSecurity from "./NavigationBottomProfileSecurity";
import NavigationHeaderProfileSecurity from "./NavigationHeaderProfileSecurity";
import NavigationOrganizationsProfileSecurity from "./NavigationOrganizationsProfileSecurity";

interface Props {
	user: UserState;
	organization: OrganizationState;
	isAdmin: boolean;
	goaccounts_url: string;
	baseUrl: string;
}

const NavigationProfileSecurity: FC<Props> = (props) => {
	const { user, organization, isAdmin, goaccounts_url, baseUrl } = props;

	return (
		<div className="navigation-profile">
			<div className="navigation-profile-background">
				<NavigationHeaderProfileSecurity
					name={user?.name || user?.email}
					avatar={user.avatar_link}
					organization={organization}
				/>
				<NavigationOrganizationsProfileSecurity user={user} organization={organization} />
			</div>
			<NavigationBottomProfileSecurity
				isAdmin={isAdmin}
				organization={organization}
				goaccountsUrl={goaccounts_url}
				baseUrl={baseUrl}
				user={user}
			/>
		</div>
	);
};

export default NavigationProfileSecurity;
