import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { UseFormReturn } from "react-hook-form";
import rgbHex from "rgb-hex";
import { VenueSettingsApi } from "../../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	label: string;
	name: "font_color" | "accent_color" | "background_color" | "curve_color";
	collapseIn: boolean;
}

const KioskSettingsColorInput = ({ form, label, name, collapseIn }: Props) => {
	const [showColorPicker, setShowColorPicker] = useState(false);
	const { watch, setValue } = form;
	const [color, setColor] = useState(watch(`kiosk.${name}`));
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (collapseIn && showColorPicker) {
			setShowColorPicker(false);
		}
	}, [collapseIn]);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	const handleClickOutside = (event?: MouseEvent) => {
		if (!event || !showColorPicker) return;

		if (ref.current && !ref.current?.contains(event.target as Node)) {
			setShowColorPicker(false);
		}
	};

	return (
		<div className="kiosk-venue-settings-color-input">
			<div>
				<span>{label}</span>
				<div
					className="color-box"
					onClick={() => setShowColorPicker((prevState) => !prevState)}
					style={{
						backgroundColor: color,
					}}
				></div>
			</div>
			{showColorPicker && (
				<div className="mt-2">
					<div className={"d-inline-block"} ref={ref}>
						<ChromePicker
							color={color}
							onChange={(color) =>
								setColor(`#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)}`)
							}
							onChangeComplete={(color) => {
								setValue(`kiosk.${name}`, color.hex);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default KioskSettingsColorInput;
