import React, { Suspense, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import Segment from "go-segment/components";
import { SegmentContext } from "go-segment/context";

const listName = "USER_SEGMENT";
const resourceType = "USER_SEGMENT";

const UserSegmentsPage = (): JSX.Element => {
	const { t } = useTranslation();
	const resourceFilterTypes = {
		ORDER_REPORT_GROUP: t("enums.common.resource_type.ORDER_REPORT_GROUP"),
		ORDER_REPORT: t("enums.common.resource_type.ORDER_REPORT"),
		PRODUCT_REPORT: t("enums.common.resource_type.PRODUCT_REPORT"),
		PRODUCT_REPORT_GROUP: t("enums.common.resource_type.PRODUCT_REPORT_GROUP"),
		INVOICE_GROUP: t("enums.common.resource_type.INVOICE_GROUP"),
		CATEGORY_REPORT: t("enums.common.resource_type.CATEGORY_REPORT"),
		PAYMENT_METHOD_REPORT_GROUP: t("enums.common.resource_type.PAYMENT_METHOD_REPORT_GROUP"),
		CATEGORY_REPORT_GROUP: t("enums.common.resource_type.CATEGORY_REPORT_GROUP"),
	};

	const hyperLinksConfig = [
		{
			list_name: "ORDER_REPORT_GROUP",
			url: `/group_reports/orders`,
		},
		{
			list_name: "PRODUCT_REPORT_GROUP",
			url: `/group_reports/products`,
		},
		{
			list_name: "CATEGORY_REPORT_GROUP",
			url: `/group_reports/categories`,
		},
		{
			list_name: "PAYMENT_METHOD_REPORT_GROUP",
			url: `/group_reports/payments`,
		},
		{
			list_name: "INVOICE_GROUP",
			url: `/invoices`,
		},
	];

	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.segment.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.segment.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<Segment.SegmentList
					resourceFilterTypes={resourceFilterTypes}
					resource={resource}
					listName={listName}
					segmentListService={segmentContext}
					organizationName=""
					hyperLinksConfig={hyperLinksConfig}
					resourceType={resourceType}
				/>
			</Suspense>
		</div>
	);
};

export default UserSegmentsPage;
