import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { MultipleActionsParams } from "go-list/list/services/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	show: boolean;
	onHide: () => void;
	productIds: number[];
	handleSave: () => void;
	allItemsSelected: boolean;
	multipleActionParams?: MultipleActionsParams;
}

interface FormProps {
	availability_id: number | null;
}

export const AssignAvailabilityModal = ({
	onHide,
	show,
	productIds,
	handleSave,
	allItemsSelected,
	multipleActionParams,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<FormProps>({
		criteriaMode: "all",
	});
	const { handleSubmit, formState, control, setError } = form;

	const onSubmit = handleSubmit(async (data: FormProps) => {
		if (data.availability_id === undefined) data.availability_id = null;
		setLoading(true);
		try {
			const params: Record<string, any> = multipleActionParams ? { ...multipleActionParams } : {};
			if (!allItemsSelected) params.id = productIds.join(",");
			await api.organization().patchItemGroups(data, params);
			addSuccessFlash(t("lib:common.flash.saved"));
			onHide();
			handleSave();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const getAvailabilities = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getAvailabilitiesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};
	return (
		<Modal show={show} onHide={onHide}>
			<FormDirty formState={formState}>
				<Modal.Header closeButton>
					<Modal.Title>{t("modules.item_group.action.assign_availability.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						errors={[]}
						label={t("common.word.availability")}
						control={control}
						name="availability_id"
						loadOptions={getAvailabilities}
						getOptionValue={(option) => option.id}
						data-testid="availability_id"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("lib:common.action.save")}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};
