import React from "react";
import { Location } from "history";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";
import { selectOrganization } from "go-security/services/organizations/selectors";

const AppsNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const prefix = `/${organization.id}/apps`;

	const items: SubNavigationItemProps[] = [
		{
			title: t("modules.app.field.active.title"),
			route: `${prefix}/active`,
		},
		{
			title: t("modules.app.field.all.title"),
			route: `${prefix}/all`,
			isActiveRoute: (_, location: Location) =>
				location.pathname.includes("/apps/all") || location.pathname.includes("/edit"),
		},
		{
			title: t("modules.app_link.header.title"),
			route: `${prefix}/linking`,
		},
		{
			title: t("modules.app.field.to_link.title"),
			route: `${prefix}/to_link`,
		},
		{
			title: t("modules.app.field.items.title"),
			route: `${prefix}/items`,
		},
	];

	return <SubNavigationItems title={t("modules.app.header.title")} items={items} />;
};

export default AppsNavigation;
