import React, { useRef } from "react";
import { VirtualTableListSelectedSort } from "../types";
import {
	COLUMN_WITHOUT_CELL_DIVIDER_BORDER_OFFSET,
	COLUMN_WITH_CELL_DIVIDER_BORDER_OFFSET,
	toggleSortForVirtualTableColumn,
} from "../utils";

export const useResizeVirtualTableHeader = () => {
	const timeoutRef = useRef<number>(0);
	const newColumnWidths = useRef<Record<string, number>>({});
	const mouseDownEvent = useRef<React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>>();

	const handleVirtualTableHeaderResize = (
		width: number | undefined,
		setColumnWidths: (widths: Record<string, number>) => void,
		columnKey: string,
		columnWidths: Record<string, number>
	) => {
		const column = document.getElementById(`column-${columnKey}`);
		const add = column?.classList.contains("sticky-column--last")
			? COLUMN_WITH_CELL_DIVIDER_BORDER_OFFSET
			: COLUMN_WITHOUT_CELL_DIVIDER_BORDER_OFFSET;

		newColumnWidths.current = { ...columnWidths };
		newColumnWidths.current[columnKey] = (width || 0) + add;

		if (!mouseDownEvent?.current || !width) return;

		if (timeoutRef.current) window.clearTimeout(timeoutRef.current);

		timeoutRef.current = window.setTimeout(() => {
			setColumnWidths(newColumnWidths.current);
		}, 100);
	};

	const handleVirtualTableMouseDown = (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
		mouseDownEvent.current = e;
	};

	const handleVirtualTableMouseUp = (
		e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
		columnName: string,
		sortings: VirtualTableListSelectedSort[],
		setSortings: (newSortings: VirtualTableListSelectedSort[]) => void,
		skipSorting?: boolean
	) => {
		const hasNotMouseMoved = e.pageX === mouseDownEvent?.current?.pageX;
		if (hasNotMouseMoved && !skipSorting) {
			toggleSortForVirtualTableColumn(columnName, sortings, setSortings);
		}
		mouseDownEvent.current = undefined;
	};

	return { handleVirtualTableHeaderResize, handleVirtualTableMouseDown, handleVirtualTableMouseUp };
};
