import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";
import { selectOrganization } from "go-security/services/organizations/selectors";

const ReportsNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const displayCallsReport = organization.more.installed_app_providers.includes("CALL_RECORDER");
	const { t } = useTranslation();
	const prefix = `/${organization.id}/reports`;

	const items: SubNavigationItemProps[] = [
		{
			title: t("modules.sale_report.header.orders.title"),
			route: `${prefix}/orders`,
		},
		{
			title: t("modules.sale_report.header.products.title"),
			route: `${prefix}/products`,
		},
		{
			title: t("modules.sale_report.header.categories.title"),
			route: `${prefix}/categories`,
		},
		{
			title: t("common.word.payment_methods"),
			route: `${prefix}/payments`,
		},
		{
			title: t("common.word.discounts"),
			route: `${prefix}/order_promotions`,
		},
		{
			title: t("modules.transaction.header.title"),
			route: `${prefix}/transactions`,
		},
		{
			title: t("modules.invoice.header.title"),
			route: `${prefix}/invoices`,
		},
		{
			title: t("modules.efficiency_report.header.title"),
			route: `${prefix}/efficiency_report`,
		},
		{
			title: t("modules.sale_report.header.kds.title"),
			route: `${prefix}/kds`,
		},
		{
			title: t("modules.sale_report.header.kds_order.title"),
			route: `${prefix}/kds_orders`,
		},
		{
			title: t("modules.guest_report.header.title"),
			route: `${prefix}/guests`,
		},
		{
			title: t("modules.storno_report.header.title"),
			route: `${prefix}/storn`,
		},
		{
			title: t("modules.item_price_history.header.title"),
			route: `${prefix}/item_price_history`,
		},
		{
			title: t("modules.unsold_products_report.header.title"),
			route: `${prefix}/not_sold_items`,
		},
		{
			title: t("modules.live_order_report.header.title"),
			route: `${prefix}/live_orders`,
		},
	];

	if (displayCallsReport) {
		const indexOfInvoiceItem = items.findIndex((navigationItem) => navigationItem.route === `${prefix}/invoices`);
		const callsNavigationItem = {
			title: t("modules.call.header.title"),
			route: `${prefix}/calls`,
		};

		items.splice(indexOfInvoiceItem + 1, 0, callsNavigationItem);
	}

	return <SubNavigationItems title={t("components.navigation.reports")} items={items} />;
};
export default ReportsNavigation;
