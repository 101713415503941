import { BaseSyntheticEvent } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import { CopyPlaceApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	showModal: boolean;
	onHide: () => void;
	onDeleteAndCopy: (e?: BaseSyntheticEvent) => Promise<void>;
	deleteDataAndCopyLoading: boolean;
	form: UseFormReturn<CopyPlaceApi>;
}

export const RemoveAndCopyDataModal = ({
	showModal,
	onHide,
	deleteDataAndCopyLoading,
	onDeleteAndCopy,
	form,
}: Props) => {
	const {
		formState: { errors },
		register,
	} = form;
	const { t } = useTranslation();

	return (
		<Modal show={showModal} onHide={onHide}>
			<Modal.Header className="flex-column-reverse" closeButton>
				<Alert variant="danger mt-2 mb-2">
					{t("modules.clear_data.field.permanent_clear_data_info.title")}
				</Alert>
			</Modal.Header>
			<Modal.Body>
				<FormInput
					errors={errors}
					register={register}
					name="password"
					type="password"
					label={t("common.word.password", { ns: "lib" })}
				/>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading loading={deleteDataAndCopyLoading} onClick={onDeleteAndCopy} variant="danger">
					{t("common.action.remove", { ns: "lib" })}
				</ButtonLoading>
				<Button onClick={onHide} variant="light">
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
