import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { RequiredFieldsInformation } from "go-security/components/RequiredFieldsInformation/RequiredFieldsInformation";
import {
	ModifierGroupApi,
	ModifierGroupOptionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalButton from "../../../../components/translations/EntityTranslationsModalButton";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import ModifierGroupForm from "../../components/ModifierGroupForm";

interface StateType {
	from: { pathname: string };
	copiedItemId: number;
}

const OrganizationMenuModifierGroupsNewPage = (): JSX.Element => {
	const [modifierGroup, setModifierGroup] = useState<ModifierGroupApi | undefined>(undefined);
	const { t } = useTranslation();
	const { state } = useLocation<StateType>();
	const { addFlash } = useFlash();
	const [showTranslations, setShowTranslations] = useState(false);
	const [modifierGroupTranslations, setModifierGroupTranslations] = useState(modifierGroup?.translations);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.modifier_group.header.new.title"));
		if (state && state.copiedItemId) {
			fetchModifierGroup();
		} else {
			setModifierGroup({ options: [] as ModifierGroupOptionApi[] } as ModifierGroupApi);
		}
	}, []);

	const fetchModifierGroup = async () => {
		const params: Record<string, any> = {
			include: "options,options.item,options.quantity_info,options.price,translations",
		};
		try {
			const res = await api.organization().getModifierGroup(state.copiedItemId, params);
			setModifierGroup({
				...res,
				id: undefined,
				name: `${t("common.word.copy")} ${res.name}`,
			} as unknown as ModifierGroupApi);
			setModifierGroupTranslations(res.translations);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleSaveTranslations = (data: Record<string, any>) => {
		unstable_batchedUpdates(() => {
			setModifierGroupTranslations(data.entity_translations);
			setShowTranslations(false);
		});
	};

	if (!modifierGroup) {
		return <LoadingContainer />;
	}

	const buttons: ButtonProps[] = [
		{
			content: (
				<EntityTranslationsModalButton
					translations={modifierGroupTranslations || []}
					showTranslation={showTranslations}
					setShowTranslations={(show) => setShowTranslations(show)}
				/>
			),
		},
	];

	return (
		<>
			<Header
				title={t("modules.modifier_group.header.new.title")}
				subtitle={<RequiredFieldsInformation />}
				buttons={buttons}
			/>
			{showTranslations && (
				<EntityTranslationsModalForm
					handleSave={handleSaveTranslations}
					onHide={() => setShowTranslations(!showTranslations)}
					translations={modifierGroupTranslations}
				/>
			)}
			<ModifierGroupForm modifierGroupTranslations={modifierGroupTranslations} modifierGroup={modifierGroup} />
		</>
	);
};
export default OrganizationMenuModifierGroupsNewPage;
