import React from "react";
import VirtualTableSearchColumn from "../../../../../../components/VirtualTable/VirtualTableSearchColumn";
import { VirtualTableColumn } from "../../../../../../components/VirtualTable/types";
import { isVirtualTableColumnHide } from "../../../../../../components/VirtualTable/utils";
import { ItemGroupGroupEditingColumn } from "../../utils";

interface Props {
	columns: VirtualTableColumn[];
	pointsOfSaleSearch: { id: string; search?: string }[];
	setPointsOfSaleSearch: (pointsOfSaleSearch: { id: string; search?: string }[]) => void;
}

const ItemGroupGroupEditingVirtualTableHeaderSearchPointsOfSaleColumnsRow = ({
	columns,
	pointsOfSaleSearch,
	setPointsOfSaleSearch,
}: Props) => {
	const handleClearFilter = (id: string) => {
		handleSearch(id, undefined);
	};
	const getSearchValue = (columnId: string) => {
		return pointsOfSaleSearch.find(({ id }) => id === columnId)?.search || "";
	};
	const handleSearch = (columnId: string, value?: string) => {
		setPointsOfSaleSearch(
			pointsOfSaleSearch.map((pointOfSale) => {
				if (pointOfSale.id === columnId) {
					return {
						...pointOfSale,
						search: value,
					};
				}
				return pointOfSale;
			})
		);
	};
	const drawColumns = () => {
		return columns.map((col, index) => {
			const isHide = isVirtualTableColumnHide(col.id, columns);
			const lastColumn = index === columns.length - 1;

			if (col.id === ItemGroupGroupEditingColumn.POINT_OF_SALE) {
				return (
					<VirtualTableSearchColumn
						key={col.id}
						isEmpty
						search={() => {}}
						isHide={isHide}
						lastColumn={lastColumn}
					/>
				);
			}

			return (
				<VirtualTableSearchColumn
					isEmpty={false}
					key={`search-column-${col.id}`}
					clearFilter={() => handleClearFilter(col.id)}
					searchValue={getSearchValue(col.id)}
					search={(value) => handleSearch(col.id, value)}
					isHide={isHide}
					lastColumn={lastColumn}
				/>
			);
		});
	};
	return <>{drawColumns()}</>;
};

export default ItemGroupGroupEditingVirtualTableHeaderSearchPointsOfSaleColumnsRow;
