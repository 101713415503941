import i18next from "i18next";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { ListConfigColumn } from "../../core/components/Column/services/types";
import { FILTER_VALUE_SEPARATOR } from "../../core/components/Filter/services";
import { ListConfigFilter, ListSelectedFilter } from "../../core/components/Filter/services/types";
import isPredefinedRange, { parsePredefinedRangeToDefaultString } from "../../utils/daterangeutils";
import { GoListSegmentType, ListConfig, ListParamsType } from "./types";

export const selectActiveSegment = (state: ListParamsType): GoListSegmentType | undefined => {
	if (!state.segments || !state.selectedSegment) {
		return undefined;
	}
	return state.segments?.filter((segment) => segment.slug === state.selectedSegment)[0];
};

export const selectFilters = (config: ListConfig): ListConfigFilter[] => {
	let newFilters = config.filters ? config.filters : [];
	newFilters = [
		...newFilters,
		...(config.fields
			? config.fields
					.filter((field) => field.type !== undefined)
					.map((field) => {
						return {
							id: field.id,
							name: field.name,
							type: field.type,
							options: field.options,
							source: field.source,
							resources: field.resources,
							listOptions: field.listOptions,
							typeOptions: field.typeOptions,
							extraOptions: field.extraOptions,
							filterByListOptionValue: field.filterByListOptionValue,
						} as ListConfigFilter;
					})
			: []),
	];
	return newFilters;
};

export const selectColumns = (config: ListConfig, selectedColumns: string[]): ListConfigColumn[] => {
	const columnsFromConfig = config.fields ? [...config.fields] : undefined;
	columnsFromConfig?.sort((a: ListConfigColumn, b: ListConfigColumn): number => {
		const first = a.id;
		const second = b.id;
		if (
			selectedColumns.indexOf(first) > selectedColumns.indexOf(second) ||
			selectedColumns.indexOf(first) === -1 ||
			selectedColumns.indexOf(second) === -1
		) {
			return 1;
		}
		return -1;
	});

	let newColumns = config.columns ? config.columns : [];
	newColumns = [
		...newColumns,
		...(columnsFromConfig
			? columnsFromConfig.map((field) => {
					return {
						id: field.id,
						name: field.name,
						render: field.render,
						renderExport: field.renderExport,
						renderSummary: field.renderSummary,
						disableSorting: field.disableSorting,
						styleOverride: field.styleOverride,
						cellStyleOverride: field.cellStyleOverride,
						headerStyleOverride: field.headerStyleOverride,
						exportColumnWidth: field.exportColumnWidth,
						exportColumnFixedWidth: field.exportColumnFixedWidth,
						headerNameAfter: field.headerNameAfter,
						exportType: field.exportType,
					} as ListConfigColumn;
			  })
			: []),
	];

	return newColumns;
};

export const updateFilterValuesForSelectedFilters = (
	filters: ListSelectedFilter[],
	filterValues?: any[]
): ListSelectedFilter[] => {
	filters.forEach((filter) => {
		if (filter.value) {
			if (filter.valueInfo === undefined) {
				const values = filter.value
					.split(FILTER_VALUE_SEPARATOR)
					.map((val) => (isNaN(parseInt(val)) ? val : parseInt(val)));
				const valuesForFilter = filterValues?.filter(
					(filterValue) => filterValue.field_name === filter.filterId
				);
				const valueInfos = {} as any;
				values.forEach((value) => {
					const valueInfo = valuesForFilter?.filter(
						(filterValue) => filterValue.id.toString() === value.toString()
					)[0];
					if (valueInfo !== undefined) {
						valueInfos[`${valueInfo.id}`] = valueInfo.value;
					}
				});
				filter.valueInfo = valueInfos;
			}
		}
	});
	return filters;
};

export const selectSelectedFilters = (state: ListParamsType, filterValues?: any): ListSelectedFilter[] => {
	if (state.filters !== undefined) {
		return updateFilterValuesForSelectedFilters(state.filters, filterValues);
	}
	const activeSegment = selectActiveSegment(state);
	if (activeSegment && activeSegment.filters) {
		return updateFilterValuesForSelectedFilters(activeSegment.filters, filterValues);
	}
	return [];
};

const getSelectedColumns = (state: ListParamsType, config: ListConfig): Array<string> => {
	if (state.columns !== undefined) {
		return state.columns;
	}
	const activeSegment = selectActiveSegment(state);
	if (activeSegment && activeSegment.columns) {
		return activeSegment.columns;
	}

	return config.selectedColumns || [];
};

export const selectSelectedColumns = (state: ListParamsType, config: ListConfig): Array<string> => {
	const selectedColumns = getSelectedColumns(state, config);

	if (
		config.numberOfStickyColumnsAtTheStart &&
		config.numberOfStickyColumnsAtTheStart > 0 &&
		config.shouldDisableSortingOfStickyColumns &&
		config.fields &&
		selectedColumns.length > 0
	) {
		const firstColumnsIds = config.fields
			.slice(0, config.numberOfStickyColumnsAtTheStart)
			.map((column) => column.id);

		return Array.from(new Set([...firstColumnsIds, ...selectedColumns]));
	}

	return selectedColumns;
};

export const selectSelectedSorts = (state: ListParamsType): Array<string> => {
	if (state.sort !== undefined) {
		return state.sort;
	}
	const activeSegment = selectActiveSegment(state);
	if (activeSegment && activeSegment.sort) {
		return activeSegment.sort;
	}
	return [];
};

export const selectSelectedFiltersFullNames = (
	selectedFilters: ListSelectedFilter[],
	filtersConfig: ListConfigFilter[]
): Array<string> => {
	const filterValues: Array<string> = [];
	selectedFilters.forEach((selectedFilter) => {
		const filterFromConfig = filtersConfig.find((f) => f.id === selectedFilter.filterId);
		if (filterFromConfig) {
			let stringVal = "";
			const conditionBetweenSelected = selectedFilter.condition === "bt" || selectedFilter.condition === "btd";
			const charToBeInserted = conditionBetweenSelected ? " - " : ", ";
			const properlyFormattedFilterValue = selectedFilter.value.replaceAll(
				FILTER_VALUE_SEPARATOR,
				charToBeInserted
			);

			switch (filterFromConfig.type) {
				case "date_range":
					stringVal = `${filterFromConfig.name}: ${properlyFormattedFilterValue}`;
					break;
				case "text":
					stringVal = `${filterFromConfig.name} ${i18next.t(
						`lib:go_list.filters.${selectedFilter.condition}`
					)} ${properlyFormattedFilterValue}`;
					break;
				case "number":
					stringVal = `${filterFromConfig.name}: ${i18next.t(
						`lib:go_list.filters.${selectedFilter.condition}`
					)} ${properlyFormattedFilterValue}`;
					break;
				case "label_search_select":
					stringVal = `${filterFromConfig.name}: ${properlyFormattedFilterValue}`;
					break;
				case "date":
					if (conditionBetweenSelected) {
						const dates = isPredefinedRange(selectedFilter.value)
							? parsePredefinedRangeToDefaultString(selectedFilter.value).split(FILTER_VALUE_SEPARATOR)
							: selectedFilter.value.split(FILTER_VALUE_SEPARATOR);

						if (!dates[1]) {
							stringVal = `${filterFromConfig.name}: ${i18next.t(
								`lib:go_form.date_range_picker.ranges.${selectedFilter.value}`
							)}`;
						} else {
							stringVal = `${filterFromConfig.name}: ${FormatDate(dates[0])} - ${FormatDate(dates[1])}`;
						}
					} else {
						stringVal = `${filterFromConfig.name}: ${i18next.t(
							`lib:go_list.filters.${selectedFilter.condition}`
						)} ${FormatDate(selectedFilter.value)}`;
					}
					break;
				case "search_select": {
					const valueInfo = selectedFilter.valueInfo as any;
					const valueInfoValues: any[] = [];
					for (const key in valueInfo) {
						valueInfoValues.push(valueInfo[`${key}`].name ? valueInfo[`${key}`].name : valueInfo[`${key}`]);
					}
					stringVal = `${filterFromConfig.name}: ${valueInfoValues.join(", ")}`;
					break;
				}
				case "list": {
					const selectedValues: any[] = [];
					const filterValues = selectedFilter.value.split(FILTER_VALUE_SEPARATOR);
					const filterOpts = filterFromConfig.options || filterFromConfig.listOptions;
					for (const key in filterOpts) {
						const matchedKey = filterValues.find((f) => f === key);
						if (matchedKey) {
							const opts = filterOpts as any;
							selectedValues.push(opts[`${matchedKey}`]);
						}
					}
					stringVal = `${filterFromConfig.name}: ${selectedValues.join(", ")}`;
					break;
				}
			}
			filterValues.push(stringVal);
		}
	});
	return filterValues;
};
