import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	MenuPriceListApi,
	MenuPriceListFlatApi,
	SpreadsheetPriceListFormProps,
} from "../../../../../../../../../../../../../../services/Api/Organization/types";
import AssignPricesModalOptions from "./AssignPricesModalOptions";

export enum OptionType {
	"DEFAULT_PRICE" = "DEFAULT_PRICE",
	"PRICE_BASED_ON_PRICE_LISTS" = "PRICE_BASED_ON_PRICE_LISTS",
	"SET_PRICE" = "SET_PRICE",
	"RESET" = "RESET",
}

interface Props {
	isShown: boolean;
	onHide: () => void;
	columnId?: string | number;
	form: UseFormReturn<SpreadsheetPriceListFormProps>;
	priceLists: MenuPriceListApi[];
	items: MenuPriceListFlatApi[];
}

const AssignPricesModal = ({ isShown, onHide, columnId, form, priceLists, items }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [optionType, setOptionType] = useState<OptionType>(OptionType.DEFAULT_PRICE);
	const { setValue, getValues } = form;
	const [selectedPriceList, setSelectedPriceList] = useState<MenuPriceListApi | null>(null);
	const priceList = priceLists.find((priceListItem) => priceListItem.id === columnId);
	const [newPrice, setNewPrice] = useState<number | undefined>(undefined);
	const isPriceValid = (price?: null | number) => price !== undefined && price !== null;

	const getItemDefaultPrice = (item: MenuPriceListFlatApi) => {
		const itemPrice = getValues(`items.${item.index}.price.amount`);
		if (itemPrice && isPriceValid(itemPrice)) return itemPrice;

		const itemDefaultPrice = item.default_price;
		if (itemDefaultPrice && isPriceValid(itemDefaultPrice.amount)) return itemDefaultPrice.amount;

		const parentIndex = getValues("items").findIndex((x) => x.item_id === item.sub_item_id);
		const parentPrice = getValues(`items.${parentIndex}.price.amount`);
		if (parentPrice && isPriceValid(parentPrice)) return parentPrice;
		return 0;
	};

	const handleSetDefaultPrice = () => {
		if (priceList)
			items.forEach((item) => {
				const itemPrice = getItemDefaultPrice(item);
				if (itemPrice !== undefined) {
					setValue(`items.${item.index}.price_list_items.${priceList.index}.price.amount`, itemPrice);
				} else {
					setValue(`items.${item.index}.price_list_items.${priceList.index}.price.amount`, null);
				}
			});
	};

	const handleSetPriceBasedOnPriceLists = () => {
		if (selectedPriceList && priceList) {
			items.forEach((item) => {
				const price = getValues(`items.${item.index}.price_list_items.${selectedPriceList.index}.price.amount`);
				if (price !== undefined) {
					setValue(`items.${item.index}.price_list_items.${priceList.index}.price.amount`, price);
				} else {
					setValue(`items.${item.index}.price_list_items.${priceList.index}.price.amount`, null);
				}
			});
		}
	};

	const handleSetNewPrice = () => {
		if (newPrice !== undefined && priceList) {
			items.forEach((item) => {
				setValue(`items.${item.index}.price_list_items.${priceList.index}.price.amount`, newPrice);
			});
		}
	};

	const handleResetPrice = () => {
		if (priceList)
			items.forEach((item) => {
				setValue(`items.${item.index}.price_list_items.${priceList.index}.price.amount`, null);
			});
	};

	const onSubmit = () => {
		switch (optionType) {
			case OptionType.DEFAULT_PRICE:
				handleSetDefaultPrice();
				break;
			case OptionType.PRICE_BASED_ON_PRICE_LISTS:
				handleSetPriceBasedOnPriceLists();
				break;
			case OptionType.SET_PRICE:
				handleSetNewPrice();
				break;
			case OptionType.RESET:
				handleResetPrice();
				break;
		}
		onHide();
	};

	return (
		<Modal onHide={onHide} show={isShown} className="assign-prices-modal">
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.group_edit.header.assign_prices_modal.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<AssignPricesModalOptions
					setOptionType={setOptionType}
					optionType={optionType}
					priceLists={priceLists}
					setSelectedPriceList={setSelectedPriceList}
					selectedPriceList={selectedPriceList}
					setNewPrice={setNewPrice}
					newPrice={newPrice}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onSubmit} type="submit">
					{t("lib:common.action.apply")}
				</Button>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AssignPricesModal;
