import React from "react";
import { useTranslation } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { ReactComponent as WoltDriveSVG } from "../../../../../../../../../../../../images/svg/bills/source_logos/wolt-logo.svg";
import { ReactComponent as OwnDriverSVG } from "../../../../../../../../../../../../images/svg/liveOrders/own-driver.svg";
import { ReactComponent as WoltLoadingSVG } from "../../../../../../../../../../../../images/svg/liveOrders/wolt-loading.svg";
import { DeliveryEstimationApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import { LiveOrderDeliveryOptionType } from "../../../../../../services/types";

interface Props {
	option: LiveOrderDeliveryOptionType;
	setSelectedDeliveryOption: (option: LiveOrderDeliveryOptionType | undefined) => void;
	selectedDeliveryOption: LiveOrderDeliveryOptionType | undefined;
	estimatingLoading: boolean | undefined;
	estimatedDeliveryData: DeliveryEstimationApi | undefined;
	firstEstimatedDeliveryTime?: number;
	firstEstimatedPrice?: MoneyApi;
	disabled: boolean;
	handleToggle?: (option?: LiveOrderDeliveryOptionType) => void;
	orderEstimatedPreparationInMinutes?: number;
	deliveryMoney?: MoneyApi;
	selectedTimeOption?: number | undefined | string;
}

const LiveOrderDeliveryOption = ({
	option,
	setSelectedDeliveryOption,
	selectedDeliveryOption,
	estimatingLoading,
	estimatedDeliveryData,
	firstEstimatedDeliveryTime,
	firstEstimatedPrice,
	disabled,
	handleToggle,
	orderEstimatedPreparationInMinutes,
	deliveryMoney,
	selectedTimeOption,
}: Props) => {
	const { t } = useTranslation();

	const getIcon = () => {
		switch (option) {
			case LiveOrderDeliveryOptionType.OWN_DRIVER:
				return <OwnDriverSVG />;
			case LiveOrderDeliveryOptionType.WOLT_DRIVE: {
				if (estimatingLoading) return <WoltLoadingSVG />;
				return <WoltDriveSVG />;
			}

			default:
				return <></>;
		}
	};

	const handleToggleOption = async () => {
		if (disabled) return;

		if (selectedDeliveryOption === option) {
			handleToggle && handleToggle();
			setSelectedDeliveryOption(undefined);
		} else {
			setSelectedDeliveryOption(option);
			handleToggle && handleToggle(option);
		}
	};

	const getItemToDisplay = (): {
		time: string;
		price: MoneyApi | undefined;
	} => {
		if (orderEstimatedPreparationInMinutes === selectedTimeOption) {
			return {
				time: `${orderEstimatedPreparationInMinutes} ${t("lib:enums.dates.type.short.MINUTES")}`,
				price: deliveryMoney,
			};
		}

		if (selectedTimeOption && selectedDeliveryOption !== LiveOrderDeliveryOptionType.OWN_DRIVER) {
			return {
				time: `${estimatedDeliveryData?.delivery_pickup_in_minutes} ${t("lib:enums.dates.type.short.MINUTES")}`,
				price: estimatedDeliveryData?.price,
			};
		}

		if (!orderEstimatedPreparationInMinutes || orderEstimatedPreparationInMinutes <= 0) {
			return {
				time: `${firstEstimatedDeliveryTime} ${t("lib:enums.dates.type.short.MINUTES")}`,
				price: firstEstimatedPrice,
			};
		} else if (orderEstimatedPreparationInMinutes > 0) {
			return {
				time: `${orderEstimatedPreparationInMinutes} ${t("lib:enums.dates.type.short.MINUTES")}`,
				price: deliveryMoney,
			};
		}

		return {
			time: "-",
			price: undefined,
		};
	};

	const drawTitle = () => {
		switch (option) {
			case LiveOrderDeliveryOptionType.OWN_DRIVER:
				return (
					<p className="title text-truncate" title={t("modules.live_order.field.own_driver.title")}>
						{t("modules.live_order.field.own_driver.title")}
					</p>
				);
			case LiveOrderDeliveryOptionType.WOLT_DRIVE: {
				if (estimatingLoading)
					return (
						<p
							className="title text-truncate text-primary"
							title={t("modules.live_order.field.estimating_delivery.title")}
						>
							{t("modules.live_order.field.estimating_delivery.title")}
						</p>
					);

				if (disabled)
					return (
						<p
							className="title text-truncate"
							title={t("modules.live_order.field.outside_delivery_zone.title")}
						>
							{t("modules.live_order.field.outside_delivery_zone.title")}
						</p>
					);

				return (
					<>
						<p className="price text-truncate" title={FormatMoney(getItemToDisplay().price)}>
							{FormatMoney(getItemToDisplay().price)}
						</p>

						{getItemToDisplay().time && (
							<p className="time text-truncate" title={getItemToDisplay().time}>
								{getItemToDisplay().time}
							</p>
						)}
					</>
				);
			}
			default:
				return "";
		}
	};

	const getClassName = () => {
		const className: string = "live-order-delivery-option";

		if (disabled) return `${className} live-order-delivery-option--disabled`;
		if (selectedDeliveryOption === option) return `${className} live-order-delivery-option--selected`;

		return className;
	};

	return (
		<div className={getClassName()} onClick={handleToggleOption}>
			<div className="d-flex align-items-center">
				<div className="logo">{getIcon()}</div>
				<div>{drawTitle()}</div>
			</div>
		</div>
	);
};

export default LiveOrderDeliveryOption;
