import React from "react";
import { ReactComponent as BoltLogoSVG } from "../../images/svg/bills/source_logos/bolt-logo.svg";
import { ReactComponent as GlovoLogoSVG } from "../../images/svg/bills/source_logos/glovo-logo.svg";
import { ReactComponent as GoClientLogoSVG } from "../../images/svg/bills/source_logos/go-client-logo.svg";
import { ReactComponent as GoCRMLogoSVG } from "../../images/svg/bills/source_logos/go-crm-logo.svg";
import { ReactComponent as GoOrderLogoSVG } from "../../images/svg/bills/source_logos/go-order-logo.svg";
import { ReactComponent as GoPOSLogoSVG } from "../../images/svg/bills/source_logos/go-pos-logo.svg";
import { ReactComponent as PhoneLogoSVG } from "../../images/svg/bills/source_logos/phone-logo.svg";
import { ReactComponent as PyszneLogoSVG } from "../../images/svg/bills/source_logos/pyszne-logo.svg";
import { ReactComponent as UberEatsLogoSVG } from "../../images/svg/bills/source_logos/uber-eats-logo.svg";
import { ReactComponent as UnknownSVG } from "../../images/svg/bills/source_logos/unknown-logo.svg";
import { ReactComponent as WoltLogoSVG } from "../../images/svg/bills/source_logos/wolt-logo.svg";

interface Props {
	source?: string;
	variant?: OrderSourceLogoVariant;
}

type OrderSourceLogoVariant = "small" | "medium" | "large";

export const RenderOrderSourceLogo = ({ source, variant }: Props): JSX.Element => {
	if (!source) {
		return <UnknownSVG />;
	}

	const parsedName = source.toLowerCase();
	if (parsedName.includes("takeaway") || parsedName.includes("pyszne")) return <PyszneLogoSVG />;
	if (parsedName.includes("glovo")) return <GlovoLogoSVG />;
	if (parsedName.includes("wolt")) return <WoltLogoSVG />;
	if (parsedName.includes("bolt") || parsedName.includes("boltfood") || parsedName.includes("bolt food"))
		return <BoltLogoSVG />;
	if (parsedName.includes("uber") || parsedName.includes("uber eats")) return <UberEatsLogoSVG />;
	if (parsedName.includes("goorder")) return <GoOrderLogoSVG />;
	if (parsedName.includes("gopos") || parsedName.includes("pos_android")) return <GoPOSLogoSVG />;
	if (parsedName.includes("gocrm")) return <GoCRMLogoSVG />;
	if (parsedName.includes("goclient")) return <GoClientLogoSVG />;
	if (parsedName === "phone") return <PhoneLogoSVG />;

	return <OrderSourceLogo name={source} variant={variant} color="#6C757D" />;
};

interface OrderSourceLogoProps {
	name: string;
	color?: string;
	variant?: OrderSourceLogoVariant;
}

const OrderSourceLogo = ({ name, color, variant }: OrderSourceLogoProps) => {
	const finalVariant = variant ?? "small";
	const maxNameLength = finalVariant === "small" ? 2 : finalVariant === "medium" ? 3 : 6;
	const finalName = name.length > maxNameLength ? `${name.substring(0, maxNameLength)}...` : name;
	return (
		<div className={`order-source-logo order-source-logo--${finalVariant}`} style={{ backgroundColor: color }}>
			<span className="name">{finalName}</span>
		</div>
	);
};
