import { TFunction } from "react-i18next";
import {
	convertUTCDateToLocalDate,
	formatDateTimeToString,
	formatStringToDate,
} from "go-core/components/Formatters/FormatDate";
import {
	DeliveryEmployeeApi,
	DeliveryZoneApi,
	LiveOrderApi,
	OrganizationAddressApi,
} from "../../../../../../../services/Api/Organization/types";
import { CoordinatesApi } from "../../../../../../../services/Api/types";

export const parseDeliveryTime = (durationInSeconds: number): string => {
	const result: string[] = [];
	const hours = durationInSeconds < 0 ? Math.ceil(durationInSeconds / 3600) : Math.floor(durationInSeconds / 3600);
	let minutes =
		durationInSeconds < 0
			? Math.ceil((durationInSeconds % 3600) / 60)
			: Math.floor((durationInSeconds % 3600) / 60);
	if (hours) result.push(`${hours}h`);
	if (minutes) {
		minutes = hours < 0 ? Math.abs(minutes) : minutes;
		result.push(`${minutes}min`);
	}
	if (!hours && !minutes) result.push("0min");

	return result.join(" ");
};

export const getDeliveryTimeInSeconds = (executionAt?: string): number => {
	if (!executionAt) return 0;

	const d = new Date();
	const now = d.getTime();
	const executionAtTime = formatStringToDate(executionAt).getTime();

	return (executionAtTime - now) / 1000;
};

export const getLastEmployeeActivityInSeconds = (coordinatesUpdatedAt: string): number => {
	const d = new Date();
	const now = d.getTime();
	const timeInMilliseconds = formatStringToDate(coordinatesUpdatedAt).getTime();

	return (now - timeInMilliseconds) / 1000;
};

export const hasEmployeeCoords = (employee: DeliveryEmployeeApi): boolean => {
	const employeeLatitude = employee?.coordinates?.latitude;
	const employeeLongitude = employee?.coordinates?.longitude;

	return employeeLatitude !== undefined && employeeLongitude !== undefined;
};

const parseToRadian = (value: number): number => (value * Math.PI) / 180;

export const calculateEmployeeDistance = (
	employee: DeliveryEmployeeApi,
	organizationCoords: CoordinatesApi,
	t: TFunction
): Record<string, any> | undefined => {
	if (!hasEmployeeCoords(employee)) return;

	const employeeLatitude = employee.coordinates.latitude;
	const employeeLongitude = employee.coordinates.longitude;
	const organizationLatitude = organizationCoords.latitude;
	const organizationLongitude = organizationCoords.longitude;

	const R = 6371; // km
	const x1 = organizationLatitude - employeeLatitude;
	const dLat = parseToRadian(x1);
	const x2 = organizationLongitude - employeeLongitude;
	const dLon = parseToRadian(x2);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(parseToRadian(employeeLatitude)) *
			Math.cos(parseToRadian(organizationLatitude)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distanceInKm = R * c;
	let distance;

	if (distanceInKm > 1) distance = distanceInKm.toFixed(1);
	else distance = Math.trunc(distanceInKm * 1000);

	return {
		distance,
		measure:
			distanceInKm > 1
				? t("modules.live_order.field.kilometer.title")
				: t("modules.live_order.field.meter.title"),
	};
};

export const getOrganizationCoords = (organizationAddress: OrganizationAddressApi): CoordinatesApi =>
	organizationAddress?.coordinates && Object.keys(organizationAddress.coordinates).length > 0
		? organizationAddress.coordinates
		: {
				latitude: polandCoords[0],
				longitude: polandCoords[1],
		  };

export const polandCoords = [51.9189046, 19.1343786];

export const deliveryZonesColors = [
	"#E6194B",
	"#00FF00",
	"#4363D8",
	"#F58231",
	"#42D4F4",
	"#F032E6",
	"#1A9900",
	"#469990",
	"#FF00FA",
	"#9A6324",
	"#FFFAC8",
	"#800000",
	"#AAFFC3",
	"#000075",
	"#A9A9A9",
];

export const parseDeliveryZonePath = (zone: DeliveryZoneApi): (number[] | undefined)[] => {
	const coords = zone?.path?.split(",") || [];
	let positions =
		coords
			.map((x, index) => {
				if (index % 2 !== 0) {
					return [Number(coords[index - 1]), Number(x)];
				}
				return undefined;
			})
			.filter((pos) => pos) || [];
	if (positions.length > 0) {
		positions = [...positions, [Number(coords[0]), Number(coords[1])]];
	}
	return positions;
};

export const isDeliveryFullAddress = (order: LiveOrderApi): boolean =>
	areDeliveryCoordinates(order) && isDeliveryAddress(order);

export const areDeliveryCoordinates = (order: LiveOrderApi): boolean =>
	order?.delivery?.coordinates && Object.keys(order.delivery.coordinates).length > 0;

export const isDeliveryAddress = (order: LiveOrderApi): boolean =>
	order?.delivery?.address && Object.keys(order.delivery.address).length > 0;

export const getEmployeeOrders = (orders: LiveOrderApi[], employee: DeliveryEmployeeApi): LiveOrderApi[] => {
	return orders.filter((order) => order?.delivery?.delivery_employee?.reference_id === employee.source.id);
};

export const hasEmployeeOrders = (orders: LiveOrderApi[], employee: DeliveryEmployeeApi): boolean =>
	getEmployeeOrders(orders, employee).length > 0;

export const getLastEmployeeCoordinatesUpdateTime = (
	employee: DeliveryEmployeeApi,
	organizationTimezone?: string
): number => {
	const localeDate = convertUTCDateToLocalDate(employee.coordinates_updated_at, organizationTimezone);
	const updateTimeInSeconds = getLastEmployeeActivityInSeconds(formatDateTimeToString(localeDate));

	return updateTimeInSeconds;
};

export const isEmployeeCoordinatesLastUpdateTimeLaterThan10Minutes = (
	employee: DeliveryEmployeeApi,
	organizationTimezone?: string
): boolean => {
	const updateTimeInSeconds = getLastEmployeeCoordinatesUpdateTime(employee, organizationTimezone);
	const TEN_MINUTES_IN_SECONDS = 60 * 10;
	return updateTimeInSeconds > TEN_MINUTES_IN_SECONDS;
};
