import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { delay } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { FormatDurationInSeconds } from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as SuccessSVG } from "../../../../../../../images/svg/success.svg";
import { api } from "../../../../../../../services/Api/api";

interface Props {
	update: () => void;
	data?: Record<string, any>;
}

const MigrationPreview = (props: Props): JSX.Element => {
	const initialRender = useRef(true);
	const confirmation = useConfirmation();
	const mountedRef = useRef(true);
	const { addSuccessFlash, addFlash } = useFlash();
	const history = useHistory();
	const { t } = useTranslation();
	const [jobUid, setJobUid] = useState(undefined);
	const [data, setData] = useState<Record<string, any>>(props.data?.data ? props.data.data : props.data);
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();
	const getJobUid = () => {
		return data.job_uid ?? jobUid;
	};
	const checkMigrationStatus = useCallback(async () => {
		try {
			while (mountedRef.current) {
				let res;
				if (initialRender.current) {
					res = await api.organization().checkMigration();
					initialRender.current = false;
					if (res.data && res.data.data && Object.keys(res.data.data).length === 0) {
						history.push(`/${organization.id}/logs/jobs/${getJobUid()}`);
						addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
					} else {
						res.data && res.data.data && res.data.data.job_uid && setJobUid(res.data.job_uid);
					}
				} else {
					await delay(200);
					res = await api.organization().checkMigration();
					if (res.data && res.data.data && Object.keys(res.data.data).length > 0) {
						setData(res.data.data);
						res.data.data.job_uid && setJobUid(res.data.data.job_uid);
					} else {
						addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
						history.push(`/${organization.id}/logs/jobs/${getJobUid()}`);
					}
				}
			}
		} catch (e) {
			console.log(e);
			handleError.alert(e, addFlash);
		}
	}, []);

	useEffect(() => {
		handleChangeTabTitle(t("modules.migration.header.data_migration.title"));
		checkMigrationStatus();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const handleCancel = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.cancel", { ns: "lib" }),
		});
		try {
			await api.organization().cancelMigration();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			props.update();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [];
	if (data.status !== "TO_CANCEL") {
		buttons.push({
			title: t("common.action.cancel", { ns: "lib" }),
			action: () => handleCancel(),
		});
	}

	if (!data || (data && Object.keys(data).length === 0)) {
		return <LoadingContainer />;
	}

	return (
		<>
			<Header title={t("modules.migration.header.data_migration.title")} buttons={buttons} />
			<h5>
				{t("modules.migration.header.title")} {organization.name} <FormatResourceStatus status={data.status} />
			</h5>
			<table className={"table table-preview"}>
				<thead>
					<tr>
						<th>{t("modules.migration.field.resource.title")}</th>
						<th>{t("lib:common.word.status")}</th>
					</tr>
				</thead>
				<tbody>
					{data?.types.map((type: Record<string, any>, index: number) => {
						return (
							<tr key={index}>
								<td>{t(`modules.migration.field.${type.type}.title`)}</td>
								<td>
									{type.completed && <SuccessSVG />}
									{!type.completed && (
										<>
											{
												<>
													<span className={"me-3"}>
														{type.count && type.current_max_id && type.total_max_id && (
															<span>
																{type.current_max_id}/{type.total_max_id} — {type.count}
															</span>
														)}
													</span>
													<span>
														{type.time_left_in_seconds > 0 && (
															<span>
																{t("modules.migration.field.remaining.title")}{" "}
																{FormatDurationInSeconds(type.time_left_in_seconds, t)}{" "}
																({type.percent}%)
															</span>
														)}
													</span>
												</>
											}
										</>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
export default MigrationPreview;
