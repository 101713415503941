import { stringifySearchParams } from "go-core";
import {
	AMPERSAND_VALUE,
	FILTER_SEPARATOR,
	FILTER_VALUE_SEPARATOR,
	NEW_WAY_TO_ENCODING_FILTER_SIGN,
} from "go-list/core/components/Filter/services";
import {
	getUrlParamsWithoutDecoding,
	listFiltersDecode,
	listFiltersEncode,
} from "go-list/core/components/Filter/services/decoder";
import { SegmentFilterValue, SegmentType } from "go-segment/components/types";
import { SegmentApi } from "go-segment/services/types";
import { updateFilterValuesForSelectedFilters } from "./selectors";
import { GoReportSegmentType, ReportConfig, ReportParamsType } from "./types";

export const encodeSegmentValue = (segment: GoReportSegmentType): string => {
	const paramsArray: Record<string, any> = {};
	if (segment.columns) {
		paramsArray.columns = segment.columns.join(",");
	}
	if (segment.filters && segment.filters?.length > 0) {
		const f = listFiltersEncode(segment.filters, true);
		paramsArray.f = f;
	}
	if (segment.groups && segment.groups.length > 0) {
		paramsArray.groups = segment.groups.join(FILTER_VALUE_SEPARATOR);
	}
	if (segment.groupColumn) {
		paramsArray.group_column = segment.groupColumn;
	}

	if (segment.sort) {
		paramsArray.sort = segment.sort;
	}

	paramsArray.position = segment.position;
	paramsArray.default = segment.default;

	const query = stringifySearchParams(paramsArray);
	return btoa(unescape(encodeURIComponent(query)));
};

export const decodeSegmentValue = (
	segment: GoReportSegmentType,
	value: string,
	filterValues?: SegmentFilterValue[]
): GoReportSegmentType => {
	const valueDecode = decodeURIComponent(escape(atob(value)));
	const decodeParams = Object.fromEntries(new URLSearchParams(valueDecode));

	if (decodeParams.f) {
		let decodedF = decodeURIComponent(escape(atob(decodeParams.f)));

		decodedF = decodedF.substring(1);
		decodedF = decodedF.replaceAll("&", AMPERSAND_VALUE);
		decodedF = decodedF.replaceAll(FILTER_SEPARATOR, "&");

		const params = new URLSearchParams(decodedF);
		const valuesFromParams: { key: string; value: string }[] = [];
		const paramsWithoutDecoding = getUrlParamsWithoutDecoding(decodedF);
		let valuesFromParamsWithoutDecoding: { key: string; value: string }[] = [];

		for (const [key, value] of params.entries()) {
			const splittedKey = key.split("|");
			const valueIncludedInKey =
				splittedKey[1] === "a" ||
				splittedKey[1] === "u" ||
				splittedKey[1] === "true" ||
				splittedKey[1] === "false";
			valuesFromParams.push({
				key: key.replace(" ", "+"),
				value: valueIncludedInKey ? splittedKey[1] : value.replaceAll(AMPERSAND_VALUE, "&"),
			});
		}

		for (const [key, value] of Object.entries(paramsWithoutDecoding)) {
			const exactKey = valuesFromParams.find((item) => key.endsWith(item.key));
			if (exactKey) {
				if (key.startsWith("&")) {
					key.substring(1);
				}
				valuesFromParamsWithoutDecoding.push({
					key,
					value: value.replaceAll(AMPERSAND_VALUE, "&"),
				});
			}
		}
		valuesFromParamsWithoutDecoding = [
			...valuesFromParamsWithoutDecoding,
			...valuesFromParams
				.filter((valuesFromParam) => {
					const splittedKey = valuesFromParam.key.split("|");
					if (
						(splittedKey[1] === "a" ||
							splittedKey[1] === "u" ||
							splittedKey[1] === "true" ||
							splittedKey[1] === "false") &&
						valuesFromParam.value
					)
						return valuesFromParam;
					return undefined;
				})
				.filter((value) => value),
		];

		const encodedParamsWithoutDecodingAsString = valuesFromParamsWithoutDecoding
			.map((item) => {
				const encodedValue = item.value
					.split(FILTER_VALUE_SEPARATOR)
					.map((itemValue) => encodeURIComponent(itemValue))
					.join(FILTER_VALUE_SEPARATOR);
				return `${item.key}=${encodedValue}`;
			})
			.join(FILTER_SEPARATOR);

		const finalF = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}${FILTER_SEPARATOR}${encodedParamsWithoutDecodingAsString}`;
		const encodedF = btoa(unescape(encodeURIComponent(finalF)));

		const selectedFilters = listFiltersDecode(encodedF, true);
		if (selectedFilters.length > 0) {
			segment.filters = updateFilterValuesForSelectedFilters(selectedFilters, filterValues);
		}
	}

	if (decodeParams.columns) {
		segment.columns = decodeParams.columns.split(",");
	}
	if (decodeParams.groups) {
		decodeParams.groups = decodeParams.groups.replaceAll(" ", "+");
		segment.groups = decodeParams.groups.split(FILTER_VALUE_SEPARATOR);
	}
	if (decodeParams.group_column) {
		segment.groupColumn = decodeParams.group_column;
	}

	if (decodeParams.sort) {
		segment.sort = decodeParams.sort;
	}

	return segment;
};

export const segmentToParams = (segment: GoReportSegmentType): GoReportSegmentType => {
	segment.value_v2 = encodeSegmentValue(segment);
	return segment;
};

export const paramsToSegment = (params: SegmentType): GoReportSegmentType => {
	let segment: GoReportSegmentType = {
		id: String(params.id),
		name: params.name,
		slug: params.slug,
		editable: true,
		default: params.default,
		position: params.position,
	};
	if (params.value_v2) {
		segment = decodeSegmentValue(segment, params.value_v2, params.filter_values);
	}
	return segment;
};

export const isSegmentChanged = (
	state: ReportParamsType,
	config: ReportConfig,
	segment?: GoReportSegmentType
): boolean => {
	if (segment) {
		const stateFilters = state?.filters?.length === 0 ? undefined : state?.filters;
		const segmentFilters = segment?.filters?.length === 0 ? undefined : segment.filters;
		if (JSON.stringify(stateFilters) !== JSON.stringify(segmentFilters)) {
			return true;
		}

		const stateGroups =
			state?.groups?.length === 0 || (state?.groups && state?.groups[0] === "NONE") ? undefined : state?.groups;
		const segmentGroups =
			segment?.groups?.length === 0 || (segment?.groups && segment?.groups[0] === "NONE")
				? undefined
				: segment.groups;

		if (JSON.stringify(stateGroups) !== JSON.stringify(segmentGroups)) {
			return true;
		}

		const stateGroupColumn = state?.groupColumn;
		const segmentGroupColumn = segment?.groupColumn;
		if (stateGroupColumn !== segmentGroupColumn) {
			return true;
		}

		const configColumns = config.selectedColumns?.join(",");
		const stateColumns = state.columns?.join(",") === configColumns ? undefined : state.columns?.join(",");
		const segmentColumns = segment.columns?.join(",") === configColumns ? undefined : segment.columns?.join(",");
		if (stateColumns !== segmentColumns) {
			return true;
		}

		const stateSort = state?.sort;
		const segmentSort = segment?.sort;
		if (segmentSort !== stateSort) {
			return true;
		}
		return false;
	}
	if (state.filters !== undefined) {
		return true;
	}

	if (config.selectedGroups?.join(",") !== state.groups?.join(",")) {
		return true;
	}

	if (state.columns?.join(",") !== config.selectedColumns?.join(",")) {
		return true;
	}
	return false;
};

export const getSelectedSegmentForReportConfig = (segments: SegmentApi[], defaultSegment?: string) => {
	return segments.find((segment) => segment.default)?.slug ?? defaultSegment;
};
