import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationSettingsDefaultCommentsIndexPage from "./pages/Index";

const OrganizationSettingsDefaultCommentsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route
					path={`${props.match.url}/`}
					exact={true}
					component={OrganizationSettingsDefaultCommentsIndexPage}
				/>
			</Switch>
		</div>
	);
};

export default OrganizationSettingsDefaultCommentsPage;
