import MyCookies from "go-core/utils/cookies";
import { TOKEN_KEY } from "./Api";

const get = (): string => {
	const accessTokenId = MyCookies.get(TOKEN_KEY);
	if (accessTokenId !== undefined && accessTokenId) return accessTokenId;
	return "";
};
const update = (token?: string): void => {
	if (token !== undefined) {
		MyCookies.update(TOKEN_KEY, token);
	}
};
const remove = (): void => {
	MyCookies.remove(TOKEN_KEY);
};

const isExpired = (): boolean => {
	return (
		MyCookies.get(TOKEN_KEY) === undefined || MyCookies.get(TOKEN_KEY) === null || MyCookies.get(TOKEN_KEY) === ""
	);
};

export const AccessTokenSessionService = { get, update, remove, isExpired };
export default AccessTokenSessionService;
