import { CustomValidationConstraint, CustomValidationType } from "../../services/types";
import { validateGreaterThan, validateGreaterThanIfRequired, validateRequired, validateRequiredIf } from "./validators";

export const validate = (
	validationType: CustomValidationType,
	value: string | number | undefined | null
): boolean | CustomValidationConstraint => {
	switch (validationType.constraint) {
		case CustomValidationConstraint.REQUIRED:
			return validateRequired(value);
		case CustomValidationConstraint.REQUIRED_IF:
			return validateRequiredIf(value, validationType.isRequired);
		case CustomValidationConstraint.GREATER_THAN:
			return validateGreaterThan(Number(value), validationType.minValue);
		case CustomValidationConstraint.GREATER_THAN_IF_REQUIRED:
			return validateGreaterThanIfRequired(Number(value), validationType.minValue, validationType.isRequired);
		default:
			return true;
	}
};
