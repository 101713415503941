import React from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as SortableHandleSvg } from "go-report/core/images/sortable-handle.svg";
import { ListConfigColumn } from "../../../../Column/services/types";

interface Props {
	column: ListConfigColumn;
	selected: boolean;
	onChangeSelected: (columnId: string) => void;
}

const MobileFiltersColumnsButton = ({ column, selected, onChangeSelected }: Props) => {
	return (
		<Button
			id={column.id}
			variant={`${selected ? "outline-primary" : "outline-dark"}`}
			onClick={() => onChangeSelected(column.id)}
		>
			<div className="d-flex align-items-center">
				<SortableHandleSvg className="me-1" />
				{column.name}
			</div>
		</Button>
	);
};

export default MobileFiltersColumnsButton;
