import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch, useHistory } from "react-router-dom";
import SubNavigationItems from "go-app/components/Navigation/SubNavigationItems";
import Page404 from "go-app/components/Page404/Page404";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectIsAdmin } from "go-security/services/users/selectors";
import { AdminClientDetailsIndexPage } from "./pages/ClientDetails";
import AdminIndexPage from "./pages/Index";
import AdminTenantsIndexPage from "./pages/Tenants";

const AdminPage = (props: RouteComponentProps): JSX.Element => {
	const isAdmin = useSelector(selectIsAdmin);
	const history = useHistory();
	const isMobile = useWindowSize().isMobile;
	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdmin) {
			history.replace("/");
		}
	}, []);

	const getTitle = () => {
		const pathname = props.location.pathname;

		if (pathname.includes("tenants")) {
			return t("lib:go_security.tenant.header.title");
		}
		if (pathname.includes("client_details")) {
			return t("lib:go_component.client_details.header.title");
		}

		return "";
	};

	return (
		<div className="page-container">
			{isMobile && <SubNavigationItems title={getTitle()} items={[]} />}
			<div className="content">
				<Switch>
					<Route path={`${props.match.url}/tenants`} component={AdminTenantsIndexPage} />
					<Route path={`${props.match.url}/client_details`} component={AdminClientDetailsIndexPage} />
					<Route exact path={props.match.url} component={AdminIndexPage} />
					<Page404 />
				</Switch>
			</div>
		</div>
	);
};

export default AdminPage;
