import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CarWithOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/car-with-order.svg";
import { ReactComponent as CarWithoutOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/car-without-order.svg";
import { ReactComponent as InactiveCarWithOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/inactive-car-with-order.svg";
import { ReactComponent as InactiveCarWithoutOrderSVG } from "../../../../../../../../../../images/svg/liveOrders/inactive-car-without-order.svg";

const LiveOrdersMapDeliveryEmployeesLegend = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column">
			<h5>{t("modules.live_order.field.delivery_employees_status.title")}</h5>
			<div className="row row-gap-2">
				<div className="col-6">
					<div className="d-flex align-items-center">
						<CarWithoutOrderSVG className="me-2" />
						<span className="text-muted">{t("modules.live_order.field.without_order.title")}</span>
					</div>
				</div>
				<div className="col-6">
					<div className="d-flex align-items-center">
						<InactiveCarWithoutOrderSVG className="me-2" />
						<span className="text-muted">
							{t("modules.live_order.field.without_order.title")}/
							<br />
							{t("modules.live_order.field.no_localization.title")}
						</span>
					</div>
				</div>
				<div className="col-6">
					<div className="d-flex align-items-center">
						<CarWithOrderSVG className="me-2" />
						<span className="text-muted">{t("modules.live_order.field.has_orders.title")}</span>
					</div>
				</div>
				<div className="col-6">
					<div className="d-flex align-items-center">
						<InactiveCarWithOrderSVG className="me-2" />
						<span className="text-muted">
							{t("modules.live_order.field.has_orders.title")}/
							<br />
							{t("modules.live_order.field.no_localization.title")}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LiveOrdersMapDeliveryEmployeesLegend;
