import React, { useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { parseDateToDefaultString } from "go-core/components/Formatters/FormatDate";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import EntityStatus from "go-list/core/components/Table/components/EntityStatus";
import { selectOrganization } from "go-security/services/organizations/selectors";
import useDashboardContext from "../../services/useDashboardContext";
import DashboardAlertItem from "./DashboardAlertItem";
import DashboardAlertsModal from "./DashboardAlertsModal";

export interface DashboardAlert {
	status: string;
	link: string | undefined;
	title: string;
}

const DashboardAlerts = (): JSX.Element => {
	const { state } = useDashboardContext();
	const organization = useSelector(selectOrganization);
	const [showMoreAlertsModal, setShowMoreAlertsModal] = useState<boolean>(false);
	const [alerts, setAlerts] = useState<DashboardAlert[]>([]);
	const { t } = useTranslation();
	const dashboardAlerts = state?.data?.alerts;

	const getParsedAlerts = () => {
		const newAlerts: DashboardAlert[] = [];
		if (dashboardAlerts) {
			const {
				apps_with_error,
				count_of_outdated_terminals,
				count_of_missing_ereceipts,
				count_of_not_sent_ereceipts,
				count_of_error_fiscalizations,
				count_of_duplicate_fiscalizations,
				count_of_not_sent_requests,
				count_of_not_connected_go_order_app_links,
				count_of_outdated_opened_orders,
				danger_pos_reports,
				warning_pos_reports,
			} = dashboardAlerts;

			apps_with_error?.forEach((app) => {
				app.errors
					.filter((err) => Object.keys(err).length > 0)
					.forEach((err) => {
						const dangerItem = {
							status: "DANGER",
							title: `${app.name}${err.code ? ` - ${t(`constraints.${err.code}`)}` : ""}`,
							link: app.id ? `/${organization.id}/apps/active/${app.id}` : `/${organization.id}/apps/all`,
						};
						newAlerts.push(dangerItem);
					});

				app.dangers
					.filter((err) => Object.keys(err).length > 0)
					.forEach((err) => {
						const warningItem = {
							status: "WARNING",
							title: `${app.name}${err.code ? ` - ${t(`constraints.${err.code}`)}` : ""}`,
							link: app.id ? `/${organization.id}/apps/active/${app.id}` : `/${organization.id}/apps/all`,
						};
						newAlerts.push(warningItem);
					});
			});

			if (count_of_outdated_terminals > 0) {
				const outdatedTerminalsAlert = {
					status: "WARNING",
					title: `${t("modules.dashboard.field.count_of_outdated_terminals.title", {
						amount: count_of_outdated_terminals,
					})}`,
					link: `/${organization.id}/settings/terminals?f=${btoa(
						unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}up_to_date|false`))
					)}`,
				};
				newAlerts.push(outdatedTerminalsAlert);
			}
			if (count_of_outdated_opened_orders >= 100) {
				const isDanger = count_of_outdated_opened_orders >= 200;
				const today = new Date();
				const oneDayAgo = new Date();
				oneDayAgo.setDate(today.getDate() - 1);
				const outdatedOpenedOrdersAlert = {
					status: isDanger ? "DANGER" : "WARNING",
					title: t("modules.dashboard.field.count_of_outdated_opened_orders.title", {
						amount: count_of_outdated_opened_orders,
					}),
					link: `/${organization.id}/sales/orders?f=${btoa(
						unescape(
							encodeURIComponent(
								`${NEW_WAY_TO_ENCODING_FILTER_SIGN}created_at|lte=${parseDateToDefaultString(
									oneDayAgo
								)}${FILTER_SEPARATOR}status|e=OPENED`
							)
						)
					)}`,
				};
				newAlerts.push(outdatedOpenedOrdersAlert);
			}

			if (count_of_not_sent_requests > 0) {
				const notSentRequestsAlert = {
					status: "DANGER",
					title: `${t("modules.dashboard.field.count_of_not_sent_requests.title", {
						amount: count_of_not_sent_requests,
					})}`,
					link: `/${organization.id}/logs/request_items?f=${btoa(
						unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}status|e=ERROR`))
					)}`,
				};
				newAlerts.push(notSentRequestsAlert);
			}

			if (count_of_missing_ereceipts > 0) {
				const countOfMissingEordersAlert = {
					status: "DANGER",
					title: `${t("modules.dashboard.field.count_of_missing_ereceipts.title", {
						amount: count_of_missing_ereceipts,
					})}`,
					link: `/${organization.id}/sales/orders?f=${btoa(
						unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}ereceipt|e=MISSING`))
					)}`,
				};
				newAlerts.push(countOfMissingEordersAlert);
			}

			if (count_of_not_connected_go_order_app_links > 0) {
				const countOfNotConnectedGoOrderAppLinks = {
					status: "WARNING",
					title: `${t("modules.dashboard.field.count_of_not_connected_go_order_app_links.title", {
						amount: count_of_not_connected_go_order_app_links,
					})}`,
					link: `/${organization.id}/apps/to_link`,
				};
				newAlerts.push(countOfNotConnectedGoOrderAppLinks);
			}

			if (count_of_not_sent_ereceipts > 0) {
				const countOfNotSentEordersAlert = {
					status: "DANGER",
					title: `${t("modules.dashboard.field.count_of_not_sent_ereceipts.title", {
						amount: count_of_not_sent_ereceipts,
					})}`,
					link: `/${organization.id}/sales/orders?f=${btoa(
						unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}ereceipt|e=NOT_SENT`))
					)}`,
				};
				newAlerts.push(countOfNotSentEordersAlert);
			}

			if (count_of_error_fiscalizations > 0) {
				const countOfFiscalizationsErrors = {
					status: "DANGER",
					title: `${t("modules.dashboard.field.count_of_error_fiscalizations.title", {
						amount: count_of_error_fiscalizations,
					})}`,
					link: `/${organization.id}/sales/orders?f=${btoa(
						unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}fiscalization_status|e=ERROR`))
					)}`,
				};
				newAlerts.push(countOfFiscalizationsErrors);
			}

			if (count_of_duplicate_fiscalizations > 0) {
				const countOfFiscalizationsDuplications = {
					status: "DANGER",
					title: `${t("modules.dashboard.field.count_of_duplicate_fiscalizations.title", {
						amount: count_of_duplicate_fiscalizations,
					})}`,
					link: `/${organization.id}/sales/orders?f=${btoa(
						unescape(encodeURIComponent(`${NEW_WAY_TO_ENCODING_FILTER_SIGN}ereceipt|e=DUPLICATE`))
					)}`,
				};
				newAlerts.push(countOfFiscalizationsDuplications);
			}

			danger_pos_reports?.forEach((report) => {
				const reportType = t(`enums.pos_reports.type.${report.type}`);
				const item = {
					status: "DANGER",
					title: t("modules.dashboard.field.danger_pos_reports.title", {
						type: reportType,
						id: report.id,
					}),
					link: `/${organization.id}/sales/pos_reports/${report.id}`,
				};
				newAlerts.push(item);
			});

			warning_pos_reports?.forEach((report) => {
				const reportType = t(`enums.pos_reports.type.${report.type}`);
				const item = {
					status: "WARNING",
					title: t("modules.dashboard.field.warning_pos_reports.title", {
						type: reportType,
						id: report.id,
					}),
					link: `/${organization.id}/sales/pos_reports/${report.id}`,
				};
				newAlerts.push(item);
			});
		}
		setAlerts([...newAlerts]);
		return newAlerts;
	};

	const renderAlerts = useMemo(() => {
		return getParsedAlerts().map((alert) => (
			<DashboardAlertItem
				key={alert.title}
				status={alert.status}
				link={alert.link}
				title={alert.title}
				isModalItem={false}
			/>
		));
	}, [dashboardAlerts]);

	const getAlertsStatus = () => {
		const statuses = alerts.map((alert) => alert.status);
		if (statuses.includes("DANGER")) return "DISABLED";
		if (statuses.includes("WARNING")) return "WARNING";
		return "ENABLED";
	};

	const getAlertsCardBorderStyles = () => {
		const status = getAlertsStatus();
		switch (status) {
			case "ENABLED":
				return "";
			case "DISABLED":
				return "border-danger";
			case "WARNING":
				return "border-warning";
		}
	};

	const getTotalAmountOfAlerts = () => alerts.length;

	if (getTotalAmountOfAlerts() === 0) return <></>;

	return (
		<>
			<Card className={`dashboard-alerts-card dashboard-card ${getAlertsCardBorderStyles()}`}>
				<Card.Body>
					<h5>
						{t("modules.dashboard.header.dashboard_alerts_modal.title")}
						<EntityStatus status={getAlertsStatus()} />
					</h5>
					<>
						{renderAlerts.filter((_, index) => index < 3)}
						{getTotalAmountOfAlerts() > 3 && (
							<Button variant="add w-100" onClick={() => setShowMoreAlertsModal(true)}>
								{t("common.action.show_all", { ns: "lib" })}
								<strong className="ms-1">{getTotalAmountOfAlerts()}</strong>
							</Button>
						)}
					</>
				</Card.Body>
			</Card>
			{showMoreAlertsModal && (
				<DashboardAlertsModal
					isShown={showMoreAlertsModal}
					onHide={() => setShowMoreAlertsModal(false)}
					getAlertsStatus={getAlertsStatus}
					alerts={alerts}
				/>
			)}
		</>
	);
};
export default DashboardAlerts;
