import React, { useMemo, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FormDatePicker } from "go-form/components/FormDatePicker";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { useCustomErrors } from "go-form/hooks";
import { CustomValidationConfig, CustomValidationConstraint } from "go-form/services/types";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import {
	LiveOrderApi,
	OrderApi,
	OrderTransactionApi,
	TransactionApi,
	UpdateTransactionApi,
	UpdateTransactionStatus,
} from "../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../services/Api/types";
import ChangeTransactionPaymentMethodInfo from "./ChangeTransactionPaymentMethodInfo";

interface Props {
	show: boolean;
	onHide: () => void;
	id: number;
	isOrder?: boolean;
	handleSuccessAction: (item: TransactionApi | OrderApi | LiveOrderApi) => void;
	transaction?: TransactionApi | OrderTransactionApi;
	documentNumber: string;
	editMode: boolean;
	price: MoneyApi;
	include?: string;
}

const ChangeTransactionPaymentMethod = ({
	show,
	onHide,
	id,
	isOrder,
	handleSuccessAction,
	transaction,
	editMode,
	documentNumber,
	price,
	include,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<UpdateTransactionApi>({
		criteriaMode: "all",
		defaultValues: {
			transaction_status: (transaction?.status === UpdateTransactionStatus.SUCCESS ||
			transaction?.status === UpdateTransactionStatus.NEW
				? transaction.status
				: "") as UpdateTransactionStatus,
			payment_method_id: transaction?.payment_method?.id,
			paid_at: transaction?.paid_at,
		},
	});
	const {
		handleSubmit,
		setError,
		formState: { errors },
		formState,
		control,
		watch,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);

	const onSubmit = handleSubmit(async (data: UpdateTransactionApi) => {
		if (!validateCustomErrors()) return;

		setLoading(true);
		try {
			let res;
			if (isOrder) {
				const filter = `${NEW_WAY_TO_ENCODING_FILTER_SIGN}order|e=${id}`;
				const encodedFilter = btoa(unescape(encodeURIComponent(filter)));
				const params: Record<string, any> = {
					f: encodedFilter,
				};
				if (include) params.include = include;
				res = await api.organization().updateOrderTransactions(id, data, params);
			} else {
				const params = {
					include: "infos,payment_method,sub_transactions,contexts,contexts.context_id,contexts.tax_items",
				};
				res = await api.organization().updateTransaction(id, data, params);
			}
			handleSuccessAction(res);
			addSuccessFlash(t("lib:common.flash.completed"));
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const searchPaymentMethod = (
		search: string,
		params: Record<string, any>,
		options?: Record<string, any>
	): Promise<SearchSelectApi[]> => {
		const newParams = {
			...params,
			"status|e": "ENABLED",
		};
		return api.organization().getPaymentMethodsSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	const inputValidationConfig = useMemo(
		() => ({
			types: [{ constraint: CustomValidationConstraint.REQUIRED }],
			setErrors,
			utils: { watch },
		}),
		[setErrors, watch]
	) satisfies CustomValidationConfig;

	const transactionStatusOptions = [
		{
			value: UpdateTransactionStatus.NEW,
			label: t("enums.common.status.UNPAID"),
		},
		{
			value: UpdateTransactionStatus.SUCCESS,
			label: t("enums.common.status.PAID"),
		},
	];

	const getHeaderDescription = () => {
		const suffixTranslation = editMode ? t("common.word.for") : t("lib:common.word.to");
		const documentTypeTranslation = isOrder
			? t("modules.transaction.field.order.title")
			: t("modules.transaction.field.transaction.title");
		return `${suffixTranslation} ${documentTypeTranslation} #${documentNumber}`;
	};

	const title = editMode
		? t("modules.transaction.action.edit_payment_method.title")
		: t("modules.transaction.action.create_payment_method.title");

	const isSaveButtonVisible = () => {
		if (!isOrder) return true;
		if (!transaction) return true;
		if (transaction && "attached" in transaction && transaction.attached && transaction.type === "PAY_IN")
			return true;
		return false;
	};

	return (
		<Modal show={show} onHide={onHide} className="change-transaction-payment-method-modal">
			<FormDirty onSubmit={onSubmit} formState={formState}>
				<Modal.Header closeButton>
					<div>
						<Modal.Title>{title}</Modal.Title>
						<small className="text-muted font-weight-bold">{getHeaderDescription()}</small>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group price-input">
						<Form.Label className="mb-0">{t("common.word.amount")}</Form.Label>
						<InputGroup className="form-input-group">
							<input disabled value={FormatMoney(price).split(" ")[0]} className="form-control" />
							<InputGroup.Text>{price.currency}</InputGroup.Text>
						</InputGroup>
					</div>
					<FormSelectGroup
						errors={errors}
						control={control}
						name="payment_method_id"
						getOptionValue={(option) => option.id}
						loadOptions={searchPaymentMethod}
						label={t("common.word.payment_method")}
						customValidationConfig={inputValidationConfig}
						defaultValue={{
							id: transaction?.payment_method?.id,
							label: transaction?.payment_method?.name,
						}}
						data-testid="payment_method_id"
					/>
					<FormDatePicker
						errors={errors}
						control={control}
						name="paid_at"
						label={t("modules.transaction.field.transaction_date.title")}
						customValidationConfig={inputValidationConfig}
					/>
					<FormSelectGroup
						errors={errors}
						control={control}
						name="transaction_status"
						options={transactionStatusOptions}
						label={t("lib:common.word.status")}
						customValidationConfig={inputValidationConfig}
						data-testid="transaction_status"
					/>
					{editMode &&
						transaction?.status === UpdateTransactionStatus.SUCCESS &&
						watch("transaction_status") !== UpdateTransactionStatus.SUCCESS && (
							<>
								<hr />
								<ChangeTransactionPaymentMethodInfo />
							</>
						)}
				</Modal.Body>
				<Modal.Footer>
					{isSaveButtonVisible() && (
						<ButtonLoading loading={loading} onClick={onSubmit} type="submit">
							{t("lib:common.action.save")}
						</ButtonLoading>
					)}
					<Button variant="light" onClick={onHide}>
						{t("lib:common.action.cancel")}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default ChangeTransactionPaymentMethod;
