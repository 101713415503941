import React from "react";
import { Location } from "history";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";
import { selectOrganization } from "go-security/services/organizations/selectors";

const ClientsNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const prefix = `/${organization.id}/clients`;

	const items: SubNavigationItemProps[] = [
		{
			title: t("modules.client.header.title"),
			route: `${prefix}`,
			isActiveRoute: (match: Record<string, any> | null, location: Location) =>
				!location.pathname.includes("/client-groups"),
		},
		{
			title: t("modules.client_groups.header.title"),
			route: `${prefix}/client-groups`,
		},
	];

	return <SubNavigationItems title={t("modules.client.header.title")} items={items} />;
};

export default ClientsNavigation;
