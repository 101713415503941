import { ConfigReportComboApi } from "go-report-combo/services/types";

export const REPORT_example_2 = {
	id: "REPORT_example_2",
	name: "Przychody roczne #2",
	data: [
		{
			id: "BILLS",
			resource: "BILL",
			filter: `type=orderçorganization_id|e={organization_ids}`,
			groups: ["NONE", "YEAR"],
			columns: [
				{
					id: "monthly",
					filter: "date_range|bt={date_range__QUARTER}",
					group: "MONTH",
					compare: {
						filter: "date_range|bt={date_range_compare__QUARTER}",
					},
				},
			],
		},
	],
	rows: [
		{
			id: "BILLS",
			name: "BILLS",
			data_id: "BILLS",
			aggregate: "total_money",
			aggregate_columns: {
				total_money: "total_money",
			},
			group: "YEAR",
			compare: [
				{
					id: "previous_percent",
					name: "vs poprzedni rok %",
					format: "PERCENT",
					aggregate: "total_money",
					aggregate_columns: {
						total_money: "total_money",
					},
				},
			],
		},
	],
	columns: [
		{
			id: "monthly",
			data_column_id: "monthly",
			name: "Miesięcznie",
			values: {
				"1": "1",
				"2": "2",
				"3": "3",
				"4": "4",
				"5": "5",
				"6": "6",
				"7": "7",
				"8": "8",
				"9": "9",
				"10": "10",
				"11": "11",
				"12": "12",
			},
		},
		{
			id: "total_money",
			data_column_id: "monthly",
			name: "Wartosc",
		},
	],
} as ConfigReportComboApi;
