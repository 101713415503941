import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { RequiredFieldsInformation } from "go-security/components/RequiredFieldsInformation/RequiredFieldsInformation";
import { CategoryApi, PointOfSaleApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalButton from "../../../../components/translations/EntityTranslationsModalButton";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import CategoryForm from "../../components/CategoryForm";

const OrganizationMenuCategoriesNewPage = (): JSX.Element => {
	const [category, setCategory] = useState<CategoryApi | undefined>(undefined);
	const [pointsOfSale, setPointsOfSale] = useState<PointOfSaleApi[]>([]);
	const { t } = useTranslation();
	const { addFlash } = useFlash();
	const [showTranslations, setShowTranslations] = useState(false);
	const [categoryTranslations, setCategoryTranslations] = useState(category?.translations);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.category.header.new.title"));
		onFetchCategoryConfig();
	}, []);

	const onFetchCategoryConfig = async () => {
		try {
			const res = await api.organization().getCategoryConfig();
			setPointsOfSale(res.points_of_sale);
			setCategory({ discountable: true } as CategoryApi);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const handleSaveTranslations = (data: Record<string, any>) => {
		unstable_batchedUpdates(() => {
			setCategoryTranslations(data.entity_translations);
			setShowTranslations(false);
		});
		setShowTranslations(false);
	};

	const handleCreateNew = () => {
		setCategory(undefined);
		setCategory({ discountable: true } as CategoryApi);
	};

	const renderTitle = () => t("modules.category.header.new.title");

	const renderSubtitle = () => <RequiredFieldsInformation />;
	const buttons: ButtonProps[] = [
		{
			content: (
				<EntityTranslationsModalButton
					translations={categoryTranslations || []}
					showTranslation={showTranslations}
					setShowTranslations={(show) => setShowTranslations(show)}
				/>
			),
		},
	];

	return (
		<>
			<Header
				title={renderTitle()}
				subtitle={renderSubtitle()}
				buttons={isMobile ? buttons.filter((button) => button.content) : buttons}
			/>
			{showTranslations && (
				<EntityTranslationsModalForm
					handleSave={handleSaveTranslations}
					allowDescription={true}
					onHide={() => setShowTranslations(!showTranslations)}
					translations={categoryTranslations}
				/>
			)}
			{category ? (
				<CategoryForm
					category={category}
					pointsOfSale={pointsOfSale}
					createNew={() => handleCreateNew()}
					passTranslations={(newCategoryTranslations) =>
						unstable_batchedUpdates(() => {
							setCategoryTranslations(newCategoryTranslations);
						})
					}
					categoryTranslations={categoryTranslations}
				/>
			) : (
				<LoadingContainer />
			)}
		</>
	);
};

export default OrganizationMenuCategoriesNewPage;
