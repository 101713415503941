import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageCropperModal from "go-app/components/ImageCropper/ImageCropperModal";
import FileUpload from "go-app/components/ImageForm/FileUpload";
import { ImageApi } from "go-security/services/Api/typesSecurity";
import { ReactComponent as TickSVG } from "../../../../../../../../../../../../images/svg/tick.svg";
import { RoomApi } from "../../../../../../../../../../../../services/Api/Organization/types";
import roomBg1 from "../../images/room_bg_1.jpg";
import roomBg2 from "../../images/room_bg_2.jpg";
import roomBg3 from "../../images/room_bg_3.jpg";
import roomBg4 from "../../images/room_bg_4.jpg";

interface Props {
	room: RoomApi;
	handleUpdate: (color?: string, image?: ImageApi, serverImage?: string | undefined) => void;
	handleCancel: () => void;
}

const RoomImageBackground = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const [color, setColor] = useState<string | undefined>(props.room?.color);
	const [type, setType] = useState(
		props.room?.background_link ? "IMAGE_CUSTOM" : props.room?.color ? "COLOR" : "IMAGE"
	);
	const [showCropper, setShowCropper] = useState(false);
	const colors = [
		"#a0a5a9",
		"#4ab301",
		"#0c8001",
		"#13b0bf",
		"#2a52cb",
		"#a62ee7",
		"#e7467c",
		"#b21212",
		"#e5c000",
		"#593c02",
	];
	const [imageData, setImageData] = useState(
		props.room?.background_link ? props.room?.background_link.default_link : undefined
	);
	const [selectedFile, setSelectedFile] = useState<undefined | string>(undefined);
	const [updatedImage, setUpdatedImage] = useState<ImageApi | undefined>(undefined);
	const updateImage = (imageBase: string) => {
		setImageData(imageBase);
		setShowCropper(false);
		if (imageBase && imageBase.includes(";base64")) {
			const base64dataSplit = imageBase.split(",");
			let imageType = base64dataSplit[0];
			imageType = imageType.split(";")[0];
			imageType = imageType.split(":")[1];
			const newImg = {
				type: imageType,
				image_data: base64dataSplit[1],
			};
			setUpdatedImage(newImg);
			handleChange(undefined, newImg, undefined);
		}
	};
	useEffect(() => {
		setColor(props.room?.color);
	}, [props.room?.color]);

	useEffect(() => {
		if (props.room?.color) {
			setType("COLOR");
		}
		if (!props.room?.color) {
			if (props.room?.background_link) {
				setType("IMAGE_CUSTOM");
			} else setType("IMAGE");
		}
	}, [props.room]);

	const handleCancel = () => {
		const el = document.getElementsByClassName("content-container-big")[0] as HTMLElement;
		if (el) {
			el.click();
		}
		props.handleCancel();
	};

	const handleChange = (newColor?: string, image?: ImageApi, serverImage?: string | undefined, close?: boolean) => {
		const el = document.getElementsByClassName("content-container-big")[0] as HTMLElement;
		if (el && close) {
			el.click();
		}
		props.handleUpdate(newColor, image, serverImage);
	};

	const getBase64Image = (img: Record<string, any>) => {
		const newImg = { ...img };
		newImg.width = 1916;
		newImg.height = 970;
		const canvas = document.createElement("canvas");
		canvas.width = newImg.width;
		canvas.height = newImg.height;
		const ctx: any = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);
		return canvas.toDataURL("image/png");
	};

	const setImageAsPattern = (el: React.MouseEvent<HTMLImageElement>) => {
		setUpdatedImage(undefined);
		setColor(undefined);
		updateImage(getBase64Image(el.target));
	};

	const handleClose = () => {
		setShowCropper(false);
	};

	const onUpdateFile = (file: string) => {
		setSelectedFile(file);
		setShowCropper(true);
	};

	const updateColor = (newColor: string) => {
		setColor(newColor);
		setUpdatedImage(undefined);
		setImageData(undefined);
		handleChange(newColor, undefined, undefined);
	};

	const handleRemoveImage = () => {
		setImageData(undefined);
		setSelectedFile(undefined);
		setUpdatedImage(undefined);
		handleChange(undefined, undefined, undefined);
	};
	return (
		<>
			{showCropper && (
				<ImageCropperModal
					handleClose={handleClose}
					unlimitedAspect={true}
					handleSave={updateImage}
					defaultImage={imageData}
					image={selectedFile}
				/>
			)}
			<>
				<Form.Check
					type="radio"
					id="avatar-image"
					className="form-check"
					label={t("common.word.pattern")}
					checked={type === "IMAGE"}
					onChange={() => setType("IMAGE")}
				/>
				{type === "IMAGE" && (
					<>
						<div className="option-layout">
							<div className={"room-image-patterns"}>
								<img
									src={roomBg1}
									onClick={(el: React.MouseEvent<HTMLImageElement>) => setImageAsPattern(el)}
									className={"pattern"}
									alt={"room_bg_1"}
								/>
								<img
									src={roomBg2}
									onClick={(el: React.MouseEvent<HTMLImageElement>) => setImageAsPattern(el)}
									className={"pattern"}
									alt={"room_bg_2"}
								/>
								<img
									src={roomBg3}
									onClick={(el: React.MouseEvent<HTMLImageElement>) => setImageAsPattern(el)}
									className={"pattern"}
									alt={"room_bg_3"}
								/>
								<img
									src={roomBg4}
									onClick={(el: React.MouseEvent<HTMLImageElement>) => setImageAsPattern(el)}
									className={"pattern"}
									alt={"room_bg_4"}
								/>
							</div>
						</div>
					</>
				)}
				<Form.Check
					type="radio"
					id="avatar-color"
					className="form-check"
					label={t("common.word.color")}
					checked={type === "COLOR"}
					onChange={() => setType("COLOR")}
				/>
				{type === "COLOR" && (
					<>
						<div className="option-layout">
							<div
								className="d-flex mb-2"
								style={{
									flexWrap: "wrap",
									marginLeft: "-5px",
									marginRight: "-5px",
								}}
							>
								{colors.map((col, index) => {
									return (
										<div
											key={index}
											className="room-image-box"
											style={{ background: `${col}` }}
											onClick={() => updateColor(col)}
										>
											{col === color && <TickSVG className="icon icon-box" />}
										</div>
									);
								})}
							</div>
						</div>
					</>
				)}
				<Form.Check
					type="radio"
					id="avatar-image-custom"
					className="form-check"
					label={t("common.word.image", { ns: "lib" })}
					checked={type === "IMAGE_CUSTOM"}
					onChange={() => setType("IMAGE_CUSTOM")}
				/>
				{type === "IMAGE_CUSTOM" && (
					<>
						<FileUpload selectedFile={selectedFile} imageData={imageData} onUpdateFile={onUpdateFile} />
						{imageData && (
							<div className="d-flex">
								<div onClick={handleRemoveImage} className="remove-image">
									{t("common.action.remove", { ns: "lib" })} &times;
								</div>
							</div>
						)}
					</>
				)}
			</>
			<div className={"room-image-footer"}>
				<Button variant={"primary"} onClick={() => handleChange(color, updatedImage, imageData, true)}>
					{t("lib:common.action.save")}
				</Button>
				<Button variant={"light"} onClick={() => handleCancel()}>
					{t("lib:common.action.cancel")}
				</Button>
			</div>
		</>
	);
};
export default RoomImageBackground;
