import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate, { FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as AlertSVG } from "../../../../../../../../../../images/svg/alert.svg";
import { OrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import RemoveOrdersModal from "../../components/RemoveOrdersModal";
import { SaleOrdersBackendAcceptableParams } from "../services/types";

const getExportParams = (params: Record<string, any>) => {
	const newParamsArr = params.columns.split(",");

	const convertedParams = newParamsArr.map((param: string) => {
		switch (param) {
			case "TOTAL_PRICE":
				return SaleOrdersBackendAcceptableParams.TOTAL_AMOUNT;
			case "SUB_TOTAL_PRICE":
				return SaleOrdersBackendAcceptableParams.SUB_TOTAL_AMOUNT;
			case "EXECUTION_AT":
				return SaleOrdersBackendAcceptableParams.EXECUTION_DATE;
			case "DELIVERY_EMPLOYEE":
				return SaleOrdersBackendAcceptableParams.EMPLOYEE_DELIVERY;
			case "SOURCE_NUMBER":
				return SaleOrdersBackendAcceptableParams.EXTERNAL_NUMBER;
			case "TABLE":
				return SaleOrdersBackendAcceptableParams.ROOM_TABLE;
			default:
				return param;
		}
	});

	const paramsToBeSent = convertedParams.filter((param: string) => param !== "");

	return { ...params, columns: paramsToBeSent.join(",") };
};

interface ListState {
	resource?: Record<string, any>;
}

const listName = "ORDER";
const resourceType = "ORDER";

const List: FC<ListState> = ({ resource }): JSX.Element | null => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const location = useLocation();
	const history = useHistory();
	const segmentContext = useContext(SegmentContext);
	const [items, setItems] = useState<OrderApi[]>([]);
	const [removeModal, setRemoveModal] = useState(false);

	if (!resource) return null;

	const data = resource.read();

	let config = {
		fields: [
			{
				id: "number",
				disableSorting: true,
				name: t("common.word.order_number"),
				type: "text",
				render: (item: OrderApi) => {
					const isBlue = item.status === "OPENED";
					const isGrey = item.status === "VOIDED";
					const status = isBlue ? "OPENED_BLUE" : isGrey ? "VOIDED_GRAY" : item.status;
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(status)}
							to={`${location.pathname}/${item.id}`}
						>
							{item.number}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: OrderApi) => item.number,
			},
			{
				id: "created_at",
				name: t("modules.order.field.created_at.title"),
				type: "date",
				render: (item: OrderApi) => (
					<Link to={`${location.pathname}/${item.id}`}>{FormatDate(item.created_at)}</Link>
				),
				renderExport: (item: OrderApi) => FormatDate(item.created_at),
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					OPENED: t("enums.common.status.OPENED"),
					CLOSED: t("enums.common.status.CLOSED"),
					EXTERNAL: t("enums.common.status.EXTERNAL"),
					VOIDED: t("enums.common.status.VOIDED"),
					REMOVED: t("enums.common.status.REMOVED"),
				},
				render: (item: OrderApi) => <FormatResourceStatus status={item.status} />,
			},
			{
				id: "type",
				name: t("common.word.type", { ns: "lib" }),
				type: "list",
				disableSorting: true,
				options: {
					DELIVERY: t("enums.orders.types.DELIVERY"),
					DINE_IN: t("enums.orders.types.DINE_IN"),
					PICK_UP: t("enums.orders.types.PICK_UP"),
					ROOM_SERVICE: t("enums.orders.types.ROOM_SERVICE"),
				},
				render: (item: OrderApi) => {
					return t(`enums.orders.types.${item.type}`);
				},
			},
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select",
				disableSorting: true,
				render: (item: OrderApi) => {
					return (
						<Link
							target="_blank"
							rel="noreferrer"
							to={`/${organization.id}/employees/${item.employee?.id}`}
						>
							{item.employee?.name}
						</Link>
					);
				},
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "closed_at",
				name: t("modules.order.field.closed_at.title"),
				type: "date",
				render: (item: OrderApi) => FormatDate(item.closed_at),
			},
			{
				id: "comment",
				name: t("common.word.comment"),
				type: "text",
				disableSorting: true,
				render: (item: OrderApi) => <RenderLimitedText minWidth={200}>{item.comment}</RenderLimitedText>,
				renderExport: (item: OrderApi) => item.comment,
			},
			{
				id: "discount_amount",
				disableSorting: true,
				name: t("common.word.discount_value"),
				type: "number",
				render: (item: OrderApi) => {
					const discountAmount = item.sub_total_price?.amount - item.total_price?.amount;
					return <>{FormatMoney({ ...item.sub_total_price, amount: discountAmount })}</>;
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "total_price",
				disableSorting: true,
				name: t("common.word.amount"),
				type: "number",
				render: (item: OrderApi) => {
					const discountAmount = item.sub_total_price?.amount - item.total_price?.amount;
					return (
						<div className={"d-flex flex-column align-items-end"}>
							<div className={"d-flex align-items-center"}>
								<span>{FormatMoney(item.total_price)}</span>
								{(item.total_price?.amount > item.total_paid_price?.amount ||
									item?.fiscalization?.status === "ERROR") && (
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id={`tooltip-${item.id}`}>
												<div>
													{item.total_price?.amount > item.total_paid_price?.amount &&
														t("modules.order.constraints.order_unpaid")}
												</div>
												<div>
													{item?.fiscalization?.status === "ERROR" &&
														t("modules.order.constraints.fiscalization_error")}
												</div>
											</Tooltip>
										}
									>
										{({ ref, ...triggerHandler }) => (
											<AlertSVG className={"ms-1"} ref={ref} {...triggerHandler} />
										)}
									</OverlayTrigger>
								)}
							</div>
							{discountAmount > 0 && (
								<small className={"text-muted text-decoration-line-through"}>
									{FormatMoney(item.sub_total_price)}
								</small>
							)}
							{
								<small>
									{item.transactions
										.filter((f) => f.status === "SUCCESS" || f.status === "NEW")
										.filter(
											(v, i, a) =>
												a.findIndex((v2) => v2.payment_method.id === v.payment_method.id) === i
										)
										.map((tran) => tran.payment_method_name)
										.join(", ")}
								</small>
							}
						</div>
					);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "sub_total_price",
				disableSorting: true,
				type: "number",
				name: t("modules.order.field.price_without_discount.title"),
				render: (item: OrderApi) => {
					return <>{FormatMoney(item.sub_total_price)}</>;
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
			{
				id: "source",
				type: "text",
				name: t("lib:common.word.source"),
				render: (item: OrderApi) => item?.source?.name,
			},
			{
				id: "execution_at",
				type: "date",
				name: t("modules.order.field.execution_at.title"),
				render: (item: OrderApi) => FormatDate(item.execution_at),
				disableSorting: true,
			},
			{
				id: "delivery_employee",
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
				name: t("modules.order.field.delivery_employee.title"),
				render: (item: OrderApi) => item?.delivery?.delivery_employee?.name,
				disableSorting: true,
			},
			{
				id: "point_of_sale",
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPointsOfSaleSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
				name: t("common.word.point_of_sale"),
				render: (item: OrderApi) => item?.point_of_sale?.name,
				disableSorting: true,
			},
			{
				id: "source_number",
				type: "text",
				name: t("modules.order.field.source_number.title"),
				render: (item: OrderApi) => item?.source?.number,
				disableSorting: true,
			},
			{
				id: "table",
				name: t("modules.order.field.table.title"),
				render: (item: OrderApi) => {
					if (item.table?.name && !item.table?.room_name) return item.table.name;
					else if (!item.table?.room_name || !item.table?.name) return "";
					return `${item.table.room_name}/${item.table.name}`;
				},
				disableSorting: true,
			},
			{
				id: "fiscalized_at",
				name: t("modules.order.field.fiscalized_at.title"),
				render: (item: OrderApi) => FormatDate(item.fiscalization?.fiscalized_at || ""),
				disableSorting: true,
			},
			{
				id: "delivered_at",
				name: t("modules.order.field.ereceipt_delivered_at.title"),
				render: (item: OrderApi) => FormatDate(item.fiscalization?.delivery?.delivered_at || ""),
				disableSorting: true,
			},
		],
		filters: [
			{
				id: "report",
				name: t("common.word.pos_report"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, params),
					render: (item: OrderApi) => {
						if (item.opened_at && item.closed_at) {
							return `${t(`enums.pos_reports.type.${item.type}`)} #${
								item.label
							} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `${t(`enums.pos_reports.type.${item.type}`)} #${item.label} (${FormatDate(
							item.opened_at
						)})`;
					},
				},
			},
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTerminalsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "item",
				name: t("modules.order.field.item.title"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getItemsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "category",
				name: t("common.word.item_group_category"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getCategoriesSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPaymentMethodsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "payment_status",
				name: t("common.word.payment_status"),
				type: "list",
				options: {
					OPEN: t("enums.common.status.UNPAID"),
					WAIT_TO_PAY: t("enums.common.status.WAIT_TO_PAY"),
					PAID: t("enums.common.status.PAID"),
				},
			},
			{
				id: "ereceipt",
				name: t("modules.order.field.ereceipt.title"),
				type: "list",
				options: {
					CREATED: t("modules.order.field.ereceipt.CREATED"),
					MISSING: t("modules.order.field.ereceipt.MISSING"),
					NOT_SENT: t("modules.order.field.ereceipt.NOT_SENT"),
					EMAIL: t("modules.order.field.ereceipt.EMAIL"),
					SMS: t("modules.order.field.ereceipt.SMS"),
					PRINTOUT: t("modules.order.field.ereceipt.PRINTOUT"),
					DUPLICATE: t("modules.order.field.ereceipt.DUPLICATE"),
				},
			},
			{
				id: "discount",
				name: t("common.word.discount"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getListDiscountsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "discount_type",
				name: t("common.word.discount_type"),
				type: "list",
				options: {
					FIXED_PRICE: t("enums.discounts.type.FIXED_PRICE"),
					PERCENT: t("enums.discounts.type.PERCENT"),
					PERCENT_ITEM: t("enums.discounts.type.PERCENT_ITEM"),
					WASTED: t("enums.discounts.type.WASTED"),
					BOGO: t("enums.discounts.type.BOGO"),
					AMOUNT: t("enums.discounts.type.AMOUNT"),
					AMOUNT_ITEM: t("enums.discounts.type.AMOUNT_ITEM"),
					VOUCHER: t("enums.discounts.type.VOUCHER"),
					TIP: t("enums.discounts.type.TIP"),
					DELIVERY: t("enums.discounts.type.DELIVERY"),
					CREDIT: t("enums.discounts.type.CREDIT"),
				},
			},
			{
				id: "fiscalization_status",
				name: t("modules.order.field.fiscalization_status.title"),
				type: "list",
				options: {
					FISCALIZED: t("enums.orders.fiscalization.FISCALIZED"),
					NOT_FISCALIZED: t("enums.orders.fiscalization.NOT_FISCALIZED"),
					UNKNOWN: t("enums.orders.fiscalization.UNKNOWN"),
					ERROR: t("enums.orders.fiscalization.ERROR"),
				},
			},
			{
				id: "tax_id_no",
				type: "text",
				name: t("lib:common.word.tax_id_no.title"),
			},
			{
				id: "client",
				name: t("common.word.client"),
				type: "search_select",
				source: {
					request: (search: string, params: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getClientsSearchSelect(search, params, {
							cancelToken: options?.token,
						}),
				},
			},
			{
				id: "phone_number",
				type: "text",
				name: t("modules.order.field.phone_number.title"),
			},
			{
				id: "transaction_count",
				type: "number",
				name: t("modules.order.field.transaction_count.title"),
				options: ["gt", "lt", "e", "bt"],
			},
			{
				id: "discount_count",
				type: "number",
				name: t("modules.order.field.discount_count.title"),
				options: ["gt", "lt", "e", "bt"],
			},
			{
				id: "fiscalized_at",
				name: t("modules.order.field.fiscalized_at.title"),
				type: "date",
			},
			{
				id: "ereceipt_delivered_at",
				name: t("modules.order.field.ereceipt_delivered_at.title"),
				type: "date",
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: OrderApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: OrderApi) => {
					return `${location.pathname}/${item.id}/edit`;
				},
			},
			{
				name: t("modules.invoice.action.create_invoice.title"),
				click: (item: OrderApi) => {
					history.push({
						pathname: `/${organization.id}/invoices/new`,
						state: {
							order: {
								id: item.id,
								closed_at: item.closed_at,
								contact: item.contact,
								number: item.number,
							},
						},
					});
				},
				visible: (item: OrderApi) => item.status === "CLOSED",
			},
		],
		multipleActions: [
			{
				name: t("modules.invoice.action.create_invoice.title"),
				click: (multipleActionsItems: OrderApi[]) => {
					history.push({
						pathname: `/${organization.id}/invoices/new`,
						state: {
							order: {
								id: multipleActionsItems[0].id,
								closed_at: multipleActionsItems[0].closed_at,
								contact: multipleActionsItems[0].contact,
								number: multipleActionsItems[0].number,
								additionalOrderIds: multipleActionsItems.map((item) => item.id),
								additionalOrderNumbers: multipleActionsItems.map((item) => item.number),
							},
						},
					});
				},
				visible: (multipleActionsItems: OrderApi[]) =>
					multipleActionsItems.filter((item) => item.status === "CLOSED").length > 0,
			},
		],
		selectedColumns: ["number", "created_at", "status", "employee", "comment", "total_price"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		exportConfig: {
			title: t("modules.order.field.export_config.title"),
			filename: t("modules.order.header.sale.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			customExportRequests: {
				pdfFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns: params?.columns || "NUMBER,CREATED_AT,STATUS,EMPLOYEE,COMMENT,TOTAL_AMOUNT",
					};
					return api.organization().getOrderPDF(getExportParams(newParams));
				},
				csvFetch: (params?: Record<string, any>) => {
					const newParams = {
						...params,
						columns: params?.columns || "NUMBER,CREATED_AT,STATUS,EMPLOYEE,COMMENT,TOTAL_AMOUNT",
					};
					return api.organization().getOrderCSV(getExportParams(newParams));
				},
			},
		},
		fetch: async (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include =
				"table,employee,fiscalization,transactions,point_of_sale,delivery,source,fiscalization,contact,custom_fields";
			return api.organization().getOrders(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		fetchTotalItemsCountForSelectedFilters: (params: Record<string, any>, sourceToken?: CancelTokenSource) =>
			api.organization().getOrdersCount(params, { cancelToken: sourceToken?.token }),
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 2,
	} as ListConfig;

	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			{removeModal && (
				<RemoveOrdersModal
					onHide={() => setRemoveModal(false)}
					handleRemoveOrders={() => {
						setRemoveModal(false);
						setItems([]);
					}}
				/>
			)}
			<ListData
				data={items}
				config={config}
				onFetch={(fetchedItems: OrderApi[]) => setItems(fetchedItems)}
				shouldFilterAvailableMultipleActions
				canManageMultiActionsForAllItems
			/>
		</>
	);
};

const OrganizationSalesOrdersIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	const customFieldResourceTypes = [
		{
			type: "ORDER",
			name: t(`enums.custom_fields.resources.ORDER`),
		},
	];

	useEffect(() => {
		handleChangeTabTitle(t("modules.order.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType, customFieldResourceTypes)));
	}, []);

	return (
		<>
			{!isMobile && <Header title={t("modules.order.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
		</>
	);
};
export default OrganizationSalesOrdersIndexPage;
