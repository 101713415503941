import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { MultipleActionsParams } from "go-list/list/services/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
	ids: number[];
	onSuccessfulAction: () => void;
	multipleActionsParams?: MultipleActionsParams;
	areAllItemsSelected?: boolean;
}

interface FormProps {
	client_group_id: string;
}

const AssignClientGroupModal: FC<Props> = ({
	onHide,
	ids,
	onSuccessfulAction,
	multipleActionsParams,
	areAllItemsSelected,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const form = useForm<FormProps>({
		defaultValues: {},
		criteriaMode: "all",
	});
	const { control, formState, handleSubmit } = form;

	const searchClientGroups = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getClientGroupsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = handleSubmit(async (data: FormProps) => {
		setLoading(true);
		try {
			const params: Record<string, any> = multipleActionsParams ? { ...multipleActionsParams } : {};
			if (!areAllItemsSelected) params.id = ids.join(",");
			await api.organization().patchClients(data, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			onSuccessfulAction();
			onHide();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal onHide={onHide} show>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header>
					<Modal.Title>{t("modules.client.action.assign_client_group_modal.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						errors={[]}
						name="client_group_id"
						control={control}
						loadOptions={searchClientGroups}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						label={t("modules.client.action.assign_client_group_modal.title")}
						data-testid="client_group_id"
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} type="submit" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button onClick={onHide} variant="light">
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default AssignClientGroupModal;
