import React, { useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { listColumnSort } from "go-list/core/components/Column/utils";
import { ReactComponent as SortableHandleSvg } from "go-report/core/images/sortable-handle.svg";
import { VirtualTableAdditionalColumn, VirtualTableColumn } from "./types";

interface Props {
	additionalColumns: VirtualTableAdditionalColumn[];
	selectedColumns?: Array<string>;
	onChange: (selectedColumns: Array<string>) => void;
	defaultColumns: VirtualTableColumn[];
}

export const getInitialVirtualTableSortedColumns = (
	columns: VirtualTableColumn[],
	selectedColumns?: Array<string>
): VirtualTableColumn[] => {
	if (selectedColumns && selectedColumns.length > 0) {
		const newSelectedColumns: string[] = selectedColumns.filter((selectedCol) =>
			columns.map((col) => col.id).includes(selectedCol)
		);

		const finalColumns = [...columns];
		const newSelectedColumnsIndexes = newSelectedColumns.map((selectedColumn) =>
			columns.findIndex((defaultColumn) => defaultColumn.id === selectedColumn)
		);
		const newerSelectedColumnsIndexes = [...newSelectedColumnsIndexes];

		columns.forEach((_, i) => {
			if (newerSelectedColumnsIndexes.includes(i)) {
				finalColumns[i] = columns[newSelectedColumnsIndexes[0]];
				newSelectedColumnsIndexes.splice(0, 1);
			}
		});
		return finalColumns;
	}
	return columns;
};

export const getInitialVirtualTableSortedAdditionalColumns = (
	columns: VirtualTableAdditionalColumn[],
	selectedColumns?: Array<string>
): VirtualTableAdditionalColumn[] => {
	if (selectedColumns && selectedColumns.length > 0) {
		const newSelectedColumns: string[] = selectedColumns.filter((selectedCol) =>
			columns
				.map((col) => col.columns.map((item) => item.id))
				.flat()
				.includes(selectedCol)
		);

		const finalColumns: VirtualTableAdditionalColumn[] = JSON.parse(JSON.stringify(columns));
		return finalColumns.map((columnGroup) => {
			return {
				...columnGroup,
				columns: columnGroup.columns.sort((a, b) => {
					if (newSelectedColumns.includes(a.id) && newSelectedColumns.includes(b.id)) {
						return (
							newSelectedColumns.findIndex((id) => id === a.id) -
							newSelectedColumns.findIndex((id) => id === b.id)
						);
					}
					if (newSelectedColumns.includes(a.id) && !newSelectedColumns.includes(b.id)) return -1;
					if (!newSelectedColumns.includes(a.id) && newSelectedColumns.includes(b.id)) return 1;
					return 0;
				}),
			};
		});
	}
	return columns;
};

const VirtualTableColumns = (props: Props) => {
	const { t } = useTranslation();
	const selectedColumns = props.selectedColumns ? props.selectedColumns : [];
	const [sortedDefaultColumns, setSortedDefaultColumns] = useState<VirtualTableColumn[]>(
		getInitialVirtualTableSortedColumns(props.defaultColumns, selectedColumns) as VirtualTableColumn[]
	);
	const [sortedAdditionalColumns, setSortedAdditionalColumns] = useState<VirtualTableAdditionalColumn[]>(
		getInitialVirtualTableSortedAdditionalColumns(props.additionalColumns, selectedColumns)
	);
	const isSortingDefaultColumnsRef = useRef(false);
	const isSortingAdditionalColumnsRef = useRef(false);
	const dropdownRef = useRef<HTMLElement>(null);
	const findIndexForAdditionalColumn = (value: string): number => {
		const allAdditionalColumns = sortedAdditionalColumns.map((additionalColumn) => additionalColumn.columns).flat();
		const additionalColumnIndex = allAdditionalColumns.map((column) => column.id).indexOf(value);

		return (
			additionalColumnIndex +
			sortedDefaultColumns.filter((defaultColumn) => selectedColumns.includes(defaultColumn.id)).length
		);
	};
	const onChangeSelectedColumn = (value: string) => {
		const newSelectedColumns = [...selectedColumns];
		if (selectedColumns.includes(value)) {
			const columnIndex = selectedColumns.indexOf(value);
			newSelectedColumns.splice(columnIndex, 1);
		} else {
			const defaultColumnIndex = sortedDefaultColumns.map((column) => column.id).indexOf(value);
			const startIndex = sortedDefaultColumns
				.filter((_, index) => index < defaultColumnIndex)
				.filter((column) => selectedColumns.includes(column.id)).length;
			newSelectedColumns.splice(startIndex, 0, value);
		}
		props.onChange(newSelectedColumns);
	};

	const onChangeSelectedAdditionalColumn = (value: string) => {
		const newSelectedColumns = [...selectedColumns];
		if (selectedColumns.includes(value)) {
			const columnIndex = selectedColumns.indexOf(value);
			newSelectedColumns.splice(columnIndex, 1);
		} else {
			newSelectedColumns.splice(findIndexForAdditionalColumn(value), 0, value);
		}
		props.onChange(newSelectedColumns);
	};

	const handleSortDefaultColumns = (
		list: VirtualTableColumn[],
		setSortedList: (columns: VirtualTableColumn[]) => void
	) => {
		if (!isSortingDefaultColumnsRef.current) return;
		isSortingDefaultColumnsRef.current = false;
		setSortedList(list);
		props.onChange(
			listColumnSort(
				selectedColumns,
				[...list, ...sortedAdditionalColumns.map((additionalColumn) => additionalColumn.columns).flat()].map(
					(column) => column.id
				)
			)
		);
	};

	const handleSortAdditionalColumns = (
		columnId: string,
		list: VirtualTableColumn[],
		setSortedList: (x: (columns: VirtualTableAdditionalColumn[]) => VirtualTableAdditionalColumn[]) => void
	) => {
		if (!isSortingAdditionalColumnsRef.current) return;

		isSortingAdditionalColumnsRef.current = false;
		let additionalColumns: VirtualTableAdditionalColumn[] = [];

		setSortedList((rawCols: VirtualTableAdditionalColumn[]) => {
			const columns = [...rawCols];
			const columnsToChangeIndex = columns.findIndex((column) => column.id === columnId);
			additionalColumns = [];
			if (columnsToChangeIndex < 0) return [];

			columns[columnsToChangeIndex].columns = [...list];
			additionalColumns = [...columns];
			return columns;
		});
		props.onChange(
			listColumnSort(
				selectedColumns,
				[
					...sortedDefaultColumns,
					...additionalColumns.map((additionalColumn) => additionalColumn.columns).flat(),
				].map((column) => column.id)
			)
		);
	};

	const [showColumnDropdown, setShowColumnDropdown] = useState(false);

	useEffect(() => {
		const detectClickOutside = (event: MouseEvent) => {
			if (showColumnDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setShowColumnDropdown(false);
			}
		};
		document.addEventListener("click", detectClickOutside);

		return () => document.removeEventListener("click", detectClickOutside);
	}, [showColumnDropdown]);

	const toggleHandler = (isOpened: boolean) => setShowColumnDropdown(isOpened);

	return (
		<Dropdown as={ButtonGroup} onToggle={toggleHandler} ref={dropdownRef} show={showColumnDropdown}>
			<Dropdown.Toggle className="ms-3 me-0" variant="light">
				{t("common.word.columns", { ns: "lib" })}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<div className={props.additionalColumns.length > 0 ? "virtual-table-columns-divider" : ""}>
					<ReactSortable
						list={sortedDefaultColumns}
						onUpdate={() => (isSortingDefaultColumnsRef.current = true)}
						setList={(newState) => handleSortDefaultColumns(newState, setSortedDefaultColumns)}
					>
						{sortedDefaultColumns.map((column) => {
							return (
								<Form.Group key={column.id} controlId={column.id} className="form-group dropdown-item">
									<div className="dropdown-draggable-item">
										<SortableHandleSvg />
										<Form.Check
											type="checkbox"
											label={column.name}
											checked={selectedColumns.includes(column.id)}
											onChange={() => onChangeSelectedColumn(column.id)}
										/>
									</div>
								</Form.Group>
							);
						})}
					</ReactSortable>
				</div>
				<div className="filters-search-columns">
					{sortedAdditionalColumns.map((sortedAdditionalColumn, index) => {
						return (
							<div
								key={`columns-${index}`}
								className={
									index !== sortedAdditionalColumns.length - 1 ? "virtual-table-columns-divider" : ""
								}
							>
								<ReactSortable
									list={sortedAdditionalColumn.columns}
									onUpdate={() => (isSortingAdditionalColumnsRef.current = true)}
									setList={(newState) => {
										handleSortAdditionalColumns(
											sortedAdditionalColumn.id,
											newState,
											setSortedAdditionalColumns
										);
									}}
								>
									{sortedAdditionalColumn.columns.map((column) => {
										return (
											<Form.Group
												key={column.id}
												controlId={column.id}
												className="form-group dropdown-item"
											>
												<div className="dropdown-draggable-item">
													<SortableHandleSvg />
													<Form.Check
														type="checkbox"
														label={column.name}
														checked={selectedColumns.includes(column.id)}
														onChange={() => onChangeSelectedAdditionalColumn(column.id)}
													/>
												</div>
											</Form.Group>
										);
									})}
								</ReactSortable>
							</div>
						);
					})}
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default VirtualTableColumns;
