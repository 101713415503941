import React, { useContext } from "react";
import { DashboardContext } from "../../index";
import { DashboardType } from "../../services/types";
import DashboardAlerts from "../Alerts/DashboardAlerts";
import { DashboardClientsDetailsInformations } from "../ClientsDetails/DashboardClientsDetails";
import DashboardLive from "../Live/DashboardLive";

interface Props {
	loading: boolean;
}

const DashboardGeneralTab = ({ loading }: Props) => {
	const { dashboardType } = useContext(DashboardContext);

	return (
		<>
			{dashboardType === DashboardType.ORGANIZATION && <DashboardAlerts />}
			<DashboardClientsDetailsInformations />
			{dashboardType === DashboardType.ORGANIZATION && <DashboardLive loading={loading} />}
		</>
	);
};

export default DashboardGeneralTab;
