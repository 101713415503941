import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading, FormInput } from "go-form";
import { RemoveOrganizationSecurityApi } from "../../../../services/Api/typesSecurity";

interface Props {
	isShown: boolean;
	onHide: () => void;
	form: UseFormReturn<RemoveOrganizationSecurityApi>;
	handleRemove: () => void;
	loading: boolean;
}

const SecurityRemoveOrganizationConfirmationModalComponent: FC<Props> = ({
	form,
	isShown,
	onHide,
	handleRemove,
	loading,
}) => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		register,
	} = form;

	const onRemove = () => {
		handleRemove();
	};

	return (
		<Modal onHide={onHide} show={isShown}>
			<Modal.Header closeButton>
				<Modal.Title>{t("lib:go_component.remove_organization.confirmation_modal.header.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span className="text-muted">
					{t("lib:go_component.remove_organization.confirmation_modal.form.token.description")}
				</span>
				<FormInput name="token" errors={errors} register={register} className="mt-2" />
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading loading={loading} onClick={onRemove} variant="danger">
					{t("lib:go_component.remove_organization.confirmation_modal.action.confirm_removing")}
				</ButtonLoading>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SecurityRemoveOrganizationConfirmationModalComponent;
