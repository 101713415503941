import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SubReportApi } from "go-report/core/services/types";
import { DashboardReportType } from "../../services/types";
import { getAggregateName } from "../../utils/dashboardRenderUtils";
import { useDashboardReportAggregate } from "../../utils/useDashboardReportAggregate";
import { AggregateValuePercentageChange } from "./AggregateValuePercentageChange";

interface Props {
	item: SubReportApi;
	reportType: DashboardReportType;
}

const Box = (props: Props): JSX.Element => {
	const { item, reportType } = props;
	const { t } = useTranslation();
	const { getAggregateValue, reportAggregateType } = useDashboardReportAggregate(reportType);
	const name = getAggregateName(item, t);
	const currentPeriodValue = item.aggregate.sales?.[reportAggregateType];
	const lastPeriodValue = item.compare_aggregate.sales?.[reportAggregateType];

	return (
		<Card className={"report-item-card"}>
			<Card.Body>
				<div className={"aggregate-title"}>{name}</div>
				<h4 className="text-primary mb-0">{getAggregateValue(item, true)}</h4>
				<AggregateValuePercentageChange
					currentPeriodValue={currentPeriodValue}
					lastPeriodValue={lastPeriodValue}
				/>
			</Card.Body>
		</Card>
	);
};
export default Box;
