export enum SaleOrdersBackendAcceptableParams {
	NUMBER = "NUMBER",
	STATUS = "STATUS",
	EMPLOYEE = "EMPLOYEE",
	CREATED_AT = "CREATED_AT",
	COMMENT = "COMMENT",
	TOTAL_AMOUNT = "TOTAL_AMOUNT",
	TYPE = "TYPE",
	CLOSED_AT = "CLOSED_AT",
	DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT",
	SUB_TOTAL_AMOUNT = "SUB_TOTAL_AMOUNT",
	SOURCE = "SOURCE",
	EXECUTION_DATE = "EXECUTION_DATE",
	POINT_OF_SALE = "POINT_OF_SALE",
	EMPLOYEE_DELIVERY = "EMPLOYEE_DELIVERY",
	EXTERNAL_NUMBER = "EXTERNAL_NUMBER",
	ROOM_TABLE = "ROOM_TABLE",
}
