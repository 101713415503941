import React, { useMemo, useRef } from "react";
import L, { DrawEvents, LatLngExpression, PathOptions } from "leaflet";
import "leaflet-draw";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FeatureGroup, MapContainer, Polyline, TileLayer } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { ApiError } from "go-core/api/types";
import { DeliveryZoneApi } from "../../../../../../../../../../services/Api/Organization/types";
import { parseDeliveryZonePath } from "../../../../../LiveOrders/services/orderDelivery";
import { getDeliveryZoneColor } from "../../utils";
import DeliveryZoneMapLegend from "../DeliveryZoneMapLegend";

interface Props {
	form: UseFormReturn<DeliveryZoneApi>;
	organizationPosition: number[];
	deliveryZones: DeliveryZoneApi[];
	deliveryZone?: DeliveryZoneApi;
	formErrors: ApiError[];
}

const DeliveryZoneZonesForm = ({ form, organizationPosition, deliveryZones, deliveryZone, formErrors }: Props) => {
	const { t } = useTranslation();
	const { watch, setValue } = form;
	const zoneColor = useMemo(() => {
		return getDeliveryZoneColor(deliveryZone ? deliveryZones.length - 1 : deliveryZones.length);
	}, [deliveryZones]);
	const featureGroupRef = useRef<L.FeatureGroup>(null);
	const zoneError = useMemo(() => {
		return formErrors.find((error) => error.field === "path");
	}, [formErrors]);

	const handleCreated = (event: DrawEvents.Created) => {
		const layer = event.layer as L.Polygon;
		const coords = ((layer.getLatLngs()[0] || []) as L.LatLng[]).map((item) => `${item.lat},${item.lng}`).join(",");
		setValue("path", coords, { shouldDirty: true });
	};

	const handleDelete = (event: DrawEvents.Deleted) => {
		if (Object.keys(event.layers.getLayers()).length === 0) return;

		setValue("path", undefined, { shouldDirty: true });
	};

	const drawExistingDeliveryZones = () => {
		return deliveryZones
			.filter((zone) => zone.id !== deliveryZone?.id)
			.map((zone, index) => {
				const positions = parseDeliveryZonePath(zone);
				const color = getDeliveryZoneColor(index);
				const options: PathOptions = { color };

				return (
					<Polyline
						key={`zone-${zone.id}`}
						pathOptions={options}
						positions={positions as L.LatLngExpression[]}
						renderer={undefined}
					/>
				);
			});
	};

	const handleOnEditControlMount = () => {
		if (deliveryZone) {
			const coords = (parseDeliveryZonePath(deliveryZone) || []) as LatLngExpression[];
			const polygon = L.polygon(coords, { color: zoneColor }) as EditablePolygon;
			if (featureGroupRef.current) {
				featureGroupRef.current.addLayer(polygon);
				if (polygon) polygon.editing.enable();
			}
		}
	};

	const handleEditVertex = (event: DrawEvents.EditVertex) => {
		if (deliveryZone) {
			let path = "";
			const editedLayer = event.poly;
			const editedCoords: number[][] = (editedLayer.getLatLngs()[0] as L.LatLng[]).map((item: L.LatLng) => [
				item.lat,
				item.lng,
			]);
			path = editedCoords.map((coord) => `${coord[0]},${coord[1]}`).join(",");

			setValue("path", path, { shouldDirty: true });
		}
	};

	const handleEdit = (event: DrawEvents.Edited) => {
		if (deliveryZone) return;

		const layer = event.layers.getLayers()[0];
		if (layer instanceof L.Polygon) {
			const coords = ((layer.getLatLngs() || []) as L.LatLng[])
				.map((item) => `${item.lat},${item.lng}`)
				.join(",");
			if (coords !== watch("path")) {
				setValue("path", coords, { shouldDirty: true });
			}
		}
	};

	return (
		<fieldset className="form-group no-gutters">
			<h5>{t("modules.delivery_zone.field.delivery_zone.title")}</h5>
			<span className="text-muted">{t("modules.delivery_zone.field.delivery_zone.description")}</span>
			{zoneError && (
				<div className="invalid-feedback ms-0 d-flex mb-2">
					{t("constraints.delivery_zone_must_not_be_blank")}
				</div>
			)}
			<MapContainer center={organizationPosition as LatLngExpression} zoom={13} minZoom={8} preferCanvas>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<FeatureGroup ref={featureGroupRef}>
					<EditControl
						position="topright"
						onCreated={handleCreated}
						onDeleted={handleDelete}
						onMounted={handleOnEditControlMount}
						onEditVertex={handleEditVertex}
						onEdited={handleEdit}
						draw={{
							polygon:
								watch("path") || deliveryZone
									? false
									: {
											icon: new L.DivIcon({
												iconSize: new L.Point(8, 8),
												className: "leaflet-div-icon leaflet-editing-icon",
											}),
											shapeOptions: {
												color: zoneColor,
												weight: 3,
											},
									  },
							rectangle: false,
							circlemarker: false,
							circle: false,
							marker: false,
							polyline: false,
						}}
						edit={{
							remove: !!watch("path") && !deliveryZone,
							edit: !deliveryZone && !!watch("path"),
						}}
					/>
				</FeatureGroup>
				{drawExistingDeliveryZones()}
			</MapContainer>
			{deliveryZones.filter((zone) => zone.id !== deliveryZone?.id).length > 0 && (
				<DeliveryZoneMapLegend deliveryZones={deliveryZones} deliveryZone={deliveryZone} />
			)}
		</fieldset>
	);
};

export default DeliveryZoneZonesForm;
