import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmptyList from "go-core/components/EmptyList";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as PreviewArrowSVG } from "../../../../../../../../../../images/svg/preview-arrow.svg";
import { ClientApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	client: ClientApi;
}

const ClientLastInvoicesCard: FC<Props> = ({ client }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.client.field.client_last_invoices_card.title")}</h5>
				{client.invoices.length > 0 ? (
					<div className="table-responsive">
						<table className="table table-preview">
							<thead>
								<tr>
									<th>{t("modules.client.field.client_last_invoices_card_document_type.title")}</th>
									<th>{t("modules.client.field.client_last_invoices_card_number.title")}</th>
									<th>{t("common.word.status", { ns: "lib" })}</th>
									<th>{t("modules.client.field.client_last_invoices_card_issued_at.title")}</th>
									<th className="w-1" />
								</tr>
							</thead>
							<tbody>
								{client.invoices.map((invoice, index) => (
									<tr key={`invoice-${index}`}>
										<td>{t(`enums.invoices.document_type.${invoice.document_type}`)}</td>
										<td>{invoice.number}</td>
										<td>
											<FormatResourceStatus status={invoice.status} />
										</td>
										<td>{FormatDate(invoice.dates.issued_at)}</td>
										<td>
											<Link
												className="btn btn-light"
												rel="noreferrer"
												target="_blank"
												to={`/${organization.id}/invoices/${invoice.id}`}
											>
												<PreviewArrowSVG />
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<Link
							to={`/${organization.id}/invoices?f=${btoa(
								`${NEW_WAY_TO_ENCODING_FILTER_SIGN}client|e=${client.id}`
							)}`}
							target="_blank"
							className={"btn btn-add card-action"}
						>
							{t("common.action.show_more", { ns: "lib" })}
						</Link>
					</div>
				) : (
					<EmptyList title={t("modules.client.field.client_last_invoices_card_empty.title")} />
				)}
			</Card.Body>
		</Card>
	);
};

export default ClientLastInvoicesCard;

// t("enums.invoices.document_type.ADVANCE");
// t("enums.invoices.document_type.PROFORMA");
// t("enums.invoices.document_type.INVOICE");
// t("enums.invoices.document_type.CORRECTION");
