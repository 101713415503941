import React, { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import { CopyPlaceApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<CopyPlaceApi>;
}

const CopyPlaceOptions: FC<Props> = ({ form }) => {
	const { t } = useTranslation();
	const {
		register,
		formState: { errors },
		watch,
		getValues,
		reset,
		setValue,
	} = form;
	const [selectedAll, setSelectedAll] = useState(false);

	const selectAll = () => {
		setSelectedAll(!selectedAll);
		setValue("copy_menus", !selectedAll, { shouldDirty: true });
		reset(
			{
				...getValues(),
				copy_menus: !selectedAll,
				copy_availabilities: !selectedAll,
				copy_rooms: !selectedAll,
				copy_payment_methods: !selectedAll,
				copy_printout_templates: !selectedAll,
				copy_clients: !selectedAll,
				copy_employees: !selectedAll,
				copy_segments: !selectedAll,
				copy_custom_fields: !selectedAll,
				copy_invoice_details: !selectedAll,
				copy_venue_settings: !selectedAll,
				copy_venue_roles_and_permissions: !selectedAll,
				copy_default_comments: !selectedAll,
				copy_currency_rates: !selectedAll,
				copy_taxes: !selectedAll,
				copy_directions: !selectedAll,
				copy_workplaces: !selectedAll,
				copy_point_of_sales: !selectedAll,
				copy_notification_templates: !selectedAll,
				copy_receipt: !selectedAll,
				copy_tags: !selectedAll,
			},
			{ keepDirty: true }
		);
	};

	useEffect(() => {
		let isSelected = true;
		Object.keys(watch())
			.filter((f) => f !== "copied_organization_id")
			.forEach((key) => {
				if (!getValues()[key as keyof CopyPlaceApi]) {
					isSelected = false;
				}
			});
		setSelectedAll(isSelected);
	}, [watch()]);

	return (
		<fieldset className={"form-group"}>
			<h5>{t("modules.copyplace.field.legend.title")}</h5>
			<Form.Check
				className={"mb-10"}
				id={"select-all"}
				type={"switch"}
				checked={selectedAll}
				onClick={selectAll}
				label={t("common.word.select_all")}
			/>
			<FormCheck
				type={"switch"}
				label={t("common.word.menu")}
				name={"copy_menus"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.availabilities.title")}
				name={"copy_availabilities"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.rooms.title")}
				name={"copy_rooms"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("common.word.payment_methods")}
				name={"copy_payment_methods"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.printout_templates.title")}
				name={"copy_printout_templates"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.clients.title")}
				name={"copy_clients"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.employees.title")}
				name={"copy_employees"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.segments.title")}
				name={"copy_segments"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.custom_fields.title")}
				name={"copy_custom_fields"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.invoice_details.title")}
				name={"copy_invoice_details"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.venue_settings.title")}
				name={"copy_venue_settings"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.venue_roles_and_permissions.title")}
				name={"copy_venue_roles_and_permissions"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.default_comments.title")}
				name={"copy_default_comments"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.currency_rates.title")}
				name={"copy_currency_rates"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.taxes.title")}
				name={"copy_taxes"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("common.word.directions")}
				name={"copy_directions"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type={"switch"}
				label={t("modules.copyplace.field.workplaces.title")}
				name={"copy_workplaces"}
				errors={errors}
				register={register}
			/>
			<FormCheck
				type="switch"
				label={t("common.word.points_of_sale")}
				name="copy_point_of_sales"
				errors={errors}
				register={register}
			/>
			<FormCheck
				type="switch"
				label={t("modules.copyplace.field.notification_template.title")}
				name="copy_notification_templates"
				errors={errors}
				register={register}
			/>
			<FormCheck
				type="switch"
				label={t("modules.copyplace.field.copy_receipt.title")}
				name="copy_receipt"
				errors={errors}
				register={register}
			/>
			<FormCheck
				type="switch"
				label={t("modules.copyplace.field.copy_tags.title")}
				name="copy_tags"
				errors={errors}
				register={register}
			/>
		</fieldset>
	);
};
export default CopyPlaceOptions;
