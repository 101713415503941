import React, { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLoading, FormCheck } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../services/Api/types";
import { parseApplicationSettings, parseApplicationSettingsValues } from "../../../utils/utils";
import { AppState } from "../AppModal";

interface Props {
	appState: AppState;
	handleUpdateApp: (data: ApplicationApi) => void;
}

const HotellionFormModalCard: FC<Props> = ({ appState, handleUpdateApp }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings || {}),
			},
		},
	});
	const [loading, setLoading] = useState<boolean>(false);
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		formState,
		setError,
		control,
	} = form;
	const { addFlash, addSuccessFlash } = useFlash();
	const [updatedConfig, setUpdatedConfig] = useState<Record<string, any> | undefined>(appState.config);
	const organization = useSelector(selectOrganization);

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};

		try {
			const res = await api.organization().updateApp(newData, params);
			handleUpdateApp(res);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			reset({
				...res,
				settings: parseApplicationSettingsValues(res.settings),
			});
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const searchPaymentMethod = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const updateConfig = (field: string, obj?: SearchSelectApi) => {
		const cnf = { ...updatedConfig };
		if (field === "payment_method_name") {
			cnf.payment_method_name = obj?.label;
		}
		setUpdatedConfig(cnf);
	};

	return (
		<Card>
			<Card.Body>
				<FormDirty formState={formState} onSubmit={onSubmit} className="hotellion-app-form">
					<FormSelectGroup
						name="settings.payment_method_id"
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						path={`/${organization.id}/settings/payment_methods/`}
						control={control}
						onChange={(_, fullObj) => updateConfig("payment_method_name", fullObj)}
						loadOptions={searchPaymentMethod}
						label={t("modules.app.field.default_payment_method.title")}
						defaultValue={{
							label: updatedConfig?.payment_method_name,
							id: appState.app?.settings?.payment_method_id,
						}}
						errors={errors}
						data-testid="settings.payment_method_id"
					/>
					<ButtonLoading loading={loading} variant="add" onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
				</FormDirty>
			</Card.Body>
		</Card>
	);
};

export default HotellionFormModalCard;
