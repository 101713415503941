import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterType } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { selectIsAdmin } from "go-security/services/users/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { TerminalApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";
import { ChangeTerminalTypeModal } from "../../components/ChangeTerminalTypeModal";
import { getTerminalVersion } from "../../utils";
import TerminalVersion from "../Details/components/TerminalVersion";

interface ListState {
	resource?: Record<string, any>;
}

const listName = "TERMINAL";
const resourceType = "TERMINAL";

const List: FC<ListState> = ({ resource }) => {
	const [items, setItems] = useState<TerminalApi[]>([]);
	const [selectTerminalTypeModal, setSelectTerminalTypeModal] = useState<TerminalApi | undefined>();
	const location = useLocation();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const isAdmin = useSelector(selectIsAdmin);
	const { addSuccessFlash, addFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch({});
			setItems(res);
		}
	};

	const onEnable = async (item: TerminalApi) => {
		await confirmation({
			title: t("common.word.confirmation", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			await api.organization().enableTerminal(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onDisable = async (item: TerminalApi) => {
		await confirmation({
			title: t("common.word.confirmation", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			await api.organization().disableTerminal(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onExportDatabase = async (item: TerminalApi) => {
		try {
			await api.organization().exportTerminalDatabase(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onRelease = async (item: TerminalApi) => {
		try {
			await api.organization().releaseTerminal(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			console.error(err);
		}
	};

	const onDisableMaster = async (item: TerminalApi) => {
		try {
			await api.organization().disableTerminalMaster(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			console.error(err);
		}
	};

	const onEnableMaster = async (item: TerminalApi) => {
		try {
			await api.organization().enableTerminalMaster(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
			onRefresh();
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const onSendTerminalDiagnostic = async (item: TerminalApi) => {
		try {
			await api.organization().terminalSendDiagnosticReport(item.id);
			addSuccessFlash(t("lib:common.flash.completed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: TerminalApi) => {
					return (
						<StickyColumnWithEntityStatus status={convertListStatusToEntityStatus(item.status)}>
							<div>
								<div className={"d-flex align-items-center"}>
									<Link to={`${location.pathname}/${item.id}`}>
										<span className={"me-1"}>{item.name} </span>
									</Link>
									{item.master && (
										<span className={"badge bg-primary me-1"}>
											{t("modules.terminal.field.master.title")}
										</span>
									)}
									<span className="badge bg-secondary">{item.type}</span>
									<br />
								</div>
								<small>
									{item.terminal_unique_id} ({item.terminal_id})
								</small>
							</div>
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: TerminalApi) => {
					return `${item.name}(${item.type}) ${item.terminal_unique_id}(${item.terminal_id})`;
				},
			},
			{
				id: "app_version",
				name: t("modules.terminal.field.app_version.title"),
				type: "text" as FilterType,
				render: (item: TerminalApi) => <TerminalVersion terminal={item} />,
				renderExport: (item: TerminalApi) => getTerminalVersion(item),
			},
			{
				id: "updated_at",
				name: t("modules.terminal.field.updated_at.title"),
				type: "date" as FilterType,
				render: (item: TerminalApi) => {
					return FormatDate(item.updated_at);
				},
				renderExport: (item: TerminalApi) => {
					return FormatDate(item.updated_at);
				},
			},
			{
				id: "details",
				name: t("modules.terminal.field.details.title"),
				render: (item: TerminalApi) => {
					let str = "";
					if (item.ip_address) {
						str += `${t("lib:common.word.ip_address")}: ${item.ip_address}\n`;
					}
					if (item.mac_address) {
						str += `${t("lib:common.word.mac_address")}: ${item.mac_address}`;
					}
					return str;
				},
				renderExport: (item: TerminalApi) => {
					let str = "";
					if (item.ip_address) {
						str += `${t("lib:common.word.ip_address")}: ${item.ip_address}\n`;
					}
					if (item.mac_address) {
						str += `${t("lib:common.word.mac_address")}: ${item.mac_address}`;
					}
					return str;
				},
			},
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list" as FilterType,
				options: {
					DISABLED: t("enums.common.status.DISABLED", { ns: "lib" }),
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
				},
				render: (item: TerminalApi) => {
					return <FormatResourceStatus status={item.status} />;
				},
				renderExport: (item: TerminalApi) =>
					t(`enums.common.status.${item.status}`, { ns: ["translation", "lib"] }),
			} as ListConfigField,
		],
		filters: [
			{
				id: "up_to_date",
				name: t("modules.terminal.field.up_to_date.title"),
				type: "boolean",
			},
		],
		actions: [
			{
				name: t("common.word.details"),
				link: (item: TerminalApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: TerminalApi) => {
					onEnable(item);
				},
				visible: (item: TerminalApi) => {
					return item.status === "DISABLED";
				},
			},
			{
				name: t("common.action.disable", { ns: "lib" }),
				click: (item: TerminalApi) => {
					onDisable(item);
				},
				visible: (item: TerminalApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("modules.terminal.action.enable_master.title"),
				click: (item: TerminalApi) => {
					onEnableMaster(item);
				},
				visible: (item: TerminalApi) => {
					return !item.master && (isAdmin || organization.support);
				},
			},
			{
				name: t("modules.terminal.action.disable_master.title"),
				click: (item: TerminalApi) => {
					onDisableMaster(item);
				},
				visible: (item: TerminalApi) => {
					return item.master && (isAdmin || organization.support);
				},
			},
			{
				name: t("modules.terminal.action.release_bills.title"),
				click: (item: TerminalApi) => {
					onRelease(item);
				},
				visible: () => {
					return isAdmin || organization.support;
				},
			},
			{
				name: t("modules.terminal.action.export_database.title"),
				click: (item: TerminalApi) => {
					onExportDatabase(item);
				},
				visible: () => {
					return isAdmin || organization.support;
				},
			},
			{
				name: t("modules.terminal.action.send_diagnostic_report.title"),
				click: (item: TerminalApi) => onSendTerminalDiagnostic(item),
				visible: () => {
					return isAdmin;
				},
			},
			{
				name: t("modules.terminal.action.change_type.title"),
				click: (item: TerminalApi) => setSelectTerminalTypeModal(item),
				visible: (item: TerminalApi) => {
					return item.type === "POS" || item.type === "POS_MOBILE" || item.type === "POS_WORK_TIME";
				},
			},
		],
		selectedColumns: ["name", "app_version", "updated_at", "details", "status"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			return api.organization().getTerminals(params, { cancelToken: sourceToken?.token });
		},
		exportConfig: {
			title: t("modules.terminal.action.export_config.title"),
			filename: t("modules.terminal.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<>
			<ListData data={items} config={config} onFetch={(fetchItems: TerminalApi[]) => setItems(fetchItems)} />
			{selectTerminalTypeModal && (
				<ChangeTerminalTypeModal
					terminal={selectTerminalTypeModal}
					onHide={() => setSelectTerminalTypeModal(undefined)}
					onRefresh={onRefresh}
				/>
			)}
		</>
	);
};
const OrganizationSettingsTerminalsIndexPage = (): JSX.Element => {
	const [resource, setResource] = useState<Record<string, any>>();
	const { t } = useTranslation();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.terminal.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<>
			{!isMobile && <Header title={t("modules.terminal.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} />
			</Suspense>
		</>
	);
};
export default OrganizationSettingsTerminalsIndexPage;
