import { useCallback, useEffect, useRef, useState } from "react";
import { GoListSegmentType } from "go-list/list/services/types";
import { CustomFieldInsertPosition, ListConfigField } from "../../../services/types";
import { listDividerSortableItem } from "../services/consts";
import { ListConfigColumn } from "../services/types";
import { updateColumnsState } from "../utils";

export const useColumnsList = ({
	columnsFields,
	numberOfActiveStickyColumns,
	isNumberOfStickyColumnsDynamic,
	shouldDisableSortingOfStickyColumns,
	allColumnsInOrder,
	stickyColumnsDividerPosition,
	setNumberOfActiveStickyColumns,
	selectedColumns,
	segment,
	onChangeColumnsOrder,
	onChangeStickyColumnsDividerPosition,
}: {
	columnsFields?: ListConfigField[];
	numberOfActiveStickyColumns: number;
	isNumberOfStickyColumnsDynamic?: boolean;
	shouldDisableSortingOfStickyColumns?: boolean;
	allColumnsInOrder: React.MutableRefObject<ListConfigColumn[]>;
	stickyColumnsDividerPosition: React.MutableRefObject<number>;
	setNumberOfActiveStickyColumns: React.Dispatch<React.SetStateAction<number>>;
	selectedColumns: string[];
	segment?: GoListSegmentType;
	onChangeColumnsOrder: (columns: string[]) => void;
	onChangeStickyColumnsDividerPosition: (dividerPosition: number) => void;
}): Record<string, any> => {
	const previousSegmentSlug = useRef<string | undefined>(segment?.slug);
	const [sortedStickyColumns, setSortedStickyColumns] = useState<ListConfigColumn[]>(() => {
		if (isNumberOfStickyColumnsDynamic && !shouldDisableSortingOfStickyColumns) {
			return allColumnsInOrder.current.slice(0, stickyColumnsDividerPosition.current);
		}

		return [];
	});
	const [sortedNonStickyColumns, setSortedNonStickyColumns] = useState(() => {
		if (isNumberOfStickyColumnsDynamic) {
			return allColumnsInOrder.current.slice(stickyColumnsDividerPosition.current);
		}

		return allColumnsInOrder.current;
	});
	const [sortedColumnsToDisplayInTheDropdown, setSortedColumnsToDisplayInTheDropdown] = useState<ListConfigColumn[]>(
		() => {
			if (isNumberOfStickyColumnsDynamic && !shouldDisableSortingOfStickyColumns) {
				return [...sortedStickyColumns, listDividerSortableItem, ...sortedNonStickyColumns];
			}

			return [...sortedStickyColumns, ...sortedNonStickyColumns];
		}
	);

	const updateSortedStickyColumns = useCallback(
		(columnsList: ListConfigColumn[]) => updateColumnsState(columnsList, setSortedStickyColumns),
		[]
	);

	const updateSortedNonStickyColumns = useCallback(
		(columnsList: ListConfigColumn[]) => updateColumnsState(columnsList, setSortedNonStickyColumns),
		[]
	);

	const setNewSortedColumnsWithDivider = useCallback(
		(columnsList: ListConfigColumn[], dividerPosition: number) => {
			const newDividerPosition = isNumberOfStickyColumnsDynamic
				? dividerPosition
				: stickyColumnsDividerPosition.current;

			updateSortedStickyColumns(columnsList.slice(0, newDividerPosition));
			updateSortedNonStickyColumns(columnsList.slice(newDividerPosition));
		},
		[
			isNumberOfStickyColumnsDynamic,
			stickyColumnsDividerPosition,
			updateSortedStickyColumns,
			updateSortedNonStickyColumns,
		]
	);

	const didColumnsFieldsChange = useCallback(() => {
		if (!columnsFields) {
			return false;
		}

		const columnsFieldsIds = columnsFields.map((column) => column.id);
		const allColumnsInOrderIds = allColumnsInOrder.current.map((column) => column.id);

		return (
			columnsFieldsIds.length !== allColumnsInOrderIds.length ||
			columnsFieldsIds.some((columnId) => !allColumnsInOrderIds.includes(columnId))
		);
	}, [allColumnsInOrder, columnsFields]);

	const getNewColumnsWithInsertedCustomFields = useCallback(
		(columnsFieldsToInsert: ListConfigField[]) => {
			const missingColumns = columnsFieldsToInsert.filter(
				(field) => !allColumnsInOrder.current.find((column) => column.id === field.id)
			);

			const minimalInsertIndex = stickyColumnsDividerPosition.current;

			const newAllColumnsInOrder = missingColumns.reduce(
				(currentColumnsInOrder, currentColumnField) => {
					switch (currentColumnField.insertPosition) {
						case CustomFieldInsertPosition.ALPHABETICAL: {
							const alphabeticalIndex = currentColumnsInOrder
								.slice(minimalInsertIndex)
								.findIndex((column) => column.name.localeCompare(currentColumnField.name) > 0);
							const insertIndex = minimalInsertIndex + alphabeticalIndex;

							return alphabeticalIndex === -1
								? [...currentColumnsInOrder, currentColumnField]
								: [
										...currentColumnsInOrder.slice(0, insertIndex),
										currentColumnField,
										...currentColumnsInOrder.slice(insertIndex),
								  ];
						}
						case CustomFieldInsertPosition.END:
							return [...currentColumnsInOrder, currentColumnField];
						default:
							return [...currentColumnsInOrder, currentColumnField];
					}
				},
				[...allColumnsInOrder.current]
			);

			return newAllColumnsInOrder;
		},
		[allColumnsInOrder, stickyColumnsDividerPosition]
	);

	useEffect(() => {
		allColumnsInOrder.current = [...sortedStickyColumns, ...sortedNonStickyColumns];
		onChangeColumnsOrder(allColumnsInOrder.current.map((column) => column.id));

		if (shouldDisableSortingOfStickyColumns) {
			updateColumnsState(sortedNonStickyColumns, setSortedColumnsToDisplayInTheDropdown);
			return;
		}

		if (isNumberOfStickyColumnsDynamic) {
			stickyColumnsDividerPosition.current = sortedStickyColumns.length;
			onChangeStickyColumnsDividerPosition(stickyColumnsDividerPosition.current);

			updateColumnsState(
				[...sortedStickyColumns, listDividerSortableItem, ...sortedNonStickyColumns],
				setSortedColumnsToDisplayInTheDropdown
			);
			return;
		}

		updateColumnsState(allColumnsInOrder.current, setSortedColumnsToDisplayInTheDropdown);
	}, [
		sortedStickyColumns,
		sortedNonStickyColumns,
		isNumberOfStickyColumnsDynamic,
		shouldDisableSortingOfStickyColumns,
		allColumnsInOrder,
		stickyColumnsDividerPosition,
		onChangeColumnsOrder,
		onChangeStickyColumnsDividerPosition,
	]);

	useEffect(() => {
		if (!isNumberOfStickyColumnsDynamic || shouldDisableSortingOfStickyColumns) {
			return;
		}

		const newNumberOfStickyColumns = sortedStickyColumns.reduce((newNumberOfStickyColumnsSoFar, column) => {
			if (selectedColumns.includes(column.id)) {
				return newNumberOfStickyColumnsSoFar + 1;
			}

			return newNumberOfStickyColumnsSoFar;
		}, 0);

		if (newNumberOfStickyColumns !== numberOfActiveStickyColumns) {
			setNumberOfActiveStickyColumns(newNumberOfStickyColumns);
		}
	}, [
		sortedStickyColumns,
		selectedColumns,
		numberOfActiveStickyColumns,
		setNumberOfActiveStickyColumns,
		isNumberOfStickyColumnsDynamic,
		shouldDisableSortingOfStickyColumns,
	]);

	useEffect(() => {
		if (shouldDisableSortingOfStickyColumns && columnsFields && didColumnsFieldsChange()) {
			if (previousSegmentSlug.current !== segment?.slug) {
				previousSegmentSlug.current = segment?.slug;

				return setNewSortedColumnsWithDivider(columnsFields, stickyColumnsDividerPosition.current);
			}

			if (columnsFields.length > allColumnsInOrder.current.length) {
				const newAllColumnsInOrder = getNewColumnsWithInsertedCustomFields(columnsFields);

				return setNewSortedColumnsWithDivider(newAllColumnsInOrder, stickyColumnsDividerPosition.current);
			}

			const newAllColumnsInOrder = allColumnsInOrder.current.filter((column) =>
				columnsFields.find((field) => field.id === column.id)
			);

			setNewSortedColumnsWithDivider(newAllColumnsInOrder, stickyColumnsDividerPosition.current);
		}
	}, [
		columnsFields,
		didColumnsFieldsChange,
		stickyColumnsDividerPosition,
		shouldDisableSortingOfStickyColumns,
		getNewColumnsWithInsertedCustomFields,
		setNewSortedColumnsWithDivider,
		allColumnsInOrder,
		segment,
	]);

	return {
		setNewSortedColumnsWithDivider,
		updateSortedStickyColumns,
		updateSortedNonStickyColumns,
		sortedColumnsToDisplayInTheDropdown,
		shouldDisplayStickyColumnsDivider: isNumberOfStickyColumnsDynamic && !shouldDisableSortingOfStickyColumns,
	};
};
