import React from "react";
import classNames from "classnames";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import { PrintoutTemplateApi } from "../../../../../../../../../services/Api/Organization/types";
import { PrintoutElement, PrintoutExampleData, PrintoutItem } from "./PrintoutTemplateForm";

interface Props {
	form: UseFormReturn<PrintoutTemplateApi>;
	printoutData: PrintoutExampleData;
}

const PrintoutTemplatePreview = ({ form, printoutData }: Props) => {
	const { watch } = form;
	const { t } = useTranslation();
	const isDeliveryTemplate = watch("text_size") === "DELIVERY";

	const getOrderItems = (item: PrintoutElement) => {
		const mergeItemsValue = watch("merge_items");

		if (mergeItemsValue === "MERGE") {
			const newItems: PrintoutItem[] = [];
			item.items.forEach((x) => {
				const existingItem = newItems.find(
					(newItem) =>
						newItem.name === x.name && JSON.stringify(newItem.additions) === JSON.stringify(x.additions)
				);
				if (existingItem) {
					const existingItemPrice = existingItem.price.split(" ");

					newItems[newItems.indexOf(existingItem)] = {
						...existingItem,
						quantity: existingItem.quantity + x.quantity,
						price: `${Number(existingItemPrice[0]) + x.quantity * Number(x.price.split(" ")[0])} ${
							existingItemPrice[1]
						}`,
					};
				} else {
					newItems.push(x);
				}
			});
			return newItems;
		}
		return item.items;
	};

	return (
		<div className={"col-md-4"}>
			<div className="receipt mb-2">
				<div className="receipt-list">
					<div
						className={classNames({
							"printout-content": true,
							"small-font": watch("text_size") === "SMALL",
							"medium-font": watch("text_size") === "MEDIUM",
							"large-font": watch("text_size") === "LARGE",
							"delivery-font": watch("text_size") === "DELIVERY",
						})}
					>
						<div className={"printout-content-section"} />
						<div className={"printout-content-section"}>
							<div className="printout-header">
								<span className={"title"}>{t("modules.printout_template.field.order.title")}</span>
								<span className="mb-0">******************************</span>
								<span className={"mb-0"} style={{ marginTop: isDeliveryTemplate ? "4px" : "-4px" }}>
									<div className={"me-auto"}>***</div>
									<div className={`${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
										{t("modules.printout_template.field.realization_date.title")}
									</div>
									<div className={"ms-auto"}>***</div>
								</span>
								<span style={{ marginTop: isDeliveryTemplate ? "4px" : "-4px" }}>
									<div className={"me-auto"}>***</div>
									<div className={`${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
										2022-04-07 19:35
									</div>
									<div className={"ms-auto"}>***</div>
								</span>
								<span className={"mb-0"} style={{ marginTop: isDeliveryTemplate ? "6px" : "-6px" }}>
									******************************
								</span>
								<span style={{ marginTop: "-4px" }}>
									<strong>2022-04-07 16:40</strong>
								</span>
							</div>
							{watch("print_employee") && (
								<span className={"printout-item"}>
									{t("modules.printout_template.field.employee.title")}: {printoutData.employee_name}
								</span>
							)}
							{watch("print_point_of_sale") && (
								<span className="printout-item">
									{t("modules.printout_template.field.point_of_sale.title")}:{" "}
									{printoutData.point_of_sale}
								</span>
							)}
							{watch("print_direction") && (
								<span className="printout-item">
									{t("modules.printout_template.field.direction.title")}: {printoutData.direction}
								</span>
							)}
							{watch("print_comment") && (
								<span className="printout-item dynamic-font-size comment">
									{t("common.word.comment")}: {printoutData.comment}
								</span>
							)}
							{watch("print_table") && (
								<span className="printout-item">
									{t("common.word.table")}: {printoutData.table}
								</span>
							)}
							{watch("print_number_of_guests") && (
								<span className="printout-item">
									{t("modules.printout_template.field.number_of_guests.title")}:{" "}
									{printoutData.guest_amount}
								</span>
							)}
							<span className={"printout-item text-center"}>GoOrder.pl #180</span>
							{watch("print_takeaway") && (
								<span
									className={`printout-item text-center ${
										isDeliveryTemplate ? "dynamic-font-size" : ""
									}`}
								>
									{t("modules.printout_template.field.takeaway.title")}
								</span>
							)}
						</div>
						{printoutData.items.map((item) => {
							return (
								<>
									{watch("print_course") && (
										<div className={"printout-content-section"}>
											<span
												className={"printout-item dynamic-font-size comment"}
												style={{ textAlign: "center" }}
											>
												#{item.course}
											</span>
										</div>
									)}
									<div className={"printout-content-section"}>
										{getOrderItems(item).map((subItem, index) => {
											return (
												<>
													<span className={"printout-item"} key={index}>
														<div className={"d-flex"}>
															<span className={"dynamic-font-size order-element"}>
																x{subItem.quantity} {subItem.name}{" "}
																{watch("print_seat") && `(${subItem.seat_name})`}
															</span>
															{watch("print_prices") && (
																<span
																	className={
																		"dynamic-font-size order-element ms-auto"
																	}
																>
																	{subItem.price}
																</span>
															)}
														</div>
														{watch("print_extra_space") && <br />}
														{watch("print_included_additions") && subItem.additions && (
															<div className={"d-flex flex-column"}>
																{subItem.additions.map((add, index) => {
																	return (
																		<span
																			className={
																				"included-addition dynamic-font-size"
																			}
																			key={index}
																		>
																			+ {add.name}
																		</span>
																	);
																})}
															</div>
														)}
														{subItem.additions_separated && (
															<div className={"d-flex flex-column"}>
																{subItem.additions_separated.map((add, index) => {
																	return (
																		<span
																			className={
																				"included-addition dynamic-font-size"
																			}
																			key={index}
																		>
																			{index + 1}. {add.name}
																		</span>
																	);
																})}
															</div>
														)}
													</span>
													{watch("print_item_comment") && subItem.item_comment && (
														<span className={"printout-item"}>
															#{t("modules.printout_template.field.item_comment.title")}:{" "}
															{subItem.item_comment}
														</span>
													)}
												</>
											);
										})}
									</div>
								</>
							);
						})}
						<div className={"printout-content-section"}>
							<span className="printout-item dynamic-font-size order-element">
								{t("lib:common.word.source")}: GoOrder.pl #180
							</span>
							{watch("print_client") && (
								<>
									<span className={`printout-item ${isDeliveryTemplate ? "font-weight-bold" : ""}`}>
										{t("common.word.client")}: {printoutData.client.name}
									</span>
									<span className={`printout-item ${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
										{t("common.word.phone_number", { ns: "lib" })}:{" "}
										{printoutData.client.contact_phone_number}
									</span>
								</>
							)}
						</div>

						{watch("print_address") && (
							<div className={"printout-content-section"}>
								<span className={`printout-item ${isDeliveryTemplate ? "font-weight-bold" : ""}`}>
									{t("common.word.address", { ns: "lib" })}:{" "}
									<FormatAddress address={printoutData.address} />
								</span>
							</div>
						)}
						{watch("print_delivery_employee") && (
							<div className={"printout-content-section"}>
								<span
									className={`printout-item delivery-employee ${
										isDeliveryTemplate ? "dynamic-font-size" : ""
									}`}
								>
									{t("modules.printout_template.field.delivery_employee.title")}:{" "}
									{printoutData.driver_name}
								</span>
							</div>
						)}
						{watch("print_payment_method") && (
							<div className={"printout-content-section"}>
								<span className={`printout-item ${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
									{`${t("common.word.payment_method")}: ${t(
										"enums.payment_methods.payment_receipt_types.CASH"
									)}`}
								</span>
								<strong className={`${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
									{t("modules.printout_template.field.paid.title")}
								</strong>
							</div>
						)}
						{(watch("print_number") || watch("print_prices")) && (
							<div className="printout-content-section" style={{ borderBottom: "none" }}>
								{watch("print_number") && (
									<span className="printout-item dynamic-font-size order-element">
										{t("modules.printout_template.field.order_no.title")}: {printoutData.order_no}
									</span>
								)}
								{watch("print_prices") && (
									<div className={"d-flex"}>
										<span className={`${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
											{t("modules.printout_template.field.summary.title")}:
										</span>
										<span className={`ms-auto ${isDeliveryTemplate ? "dynamic-font-size" : ""}`}>
											{printoutData.price_sum}
										</span>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrintoutTemplatePreview;
