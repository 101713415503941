import React from "react";
import { ReactSortable } from "react-sortablejs";
import { ListConfigColumn } from "../services/types";
import { clickOutsideDropdown } from "../utils";
import { ColumnListItem } from "./ColumnListItem";

interface SortableColumnsListProps {
	sortedColumnsToDisplayInTheDropdown: ListConfigColumn[];
	selectedColumns: string[];
	onChangeSelected: (columnId: string) => void;
	onSortedList: (sortedList: ListConfigColumn[]) => void;
}

export const SortableColumnsList = ({
	sortedColumnsToDisplayInTheDropdown,
	selectedColumns,
	onChangeSelected,
	onSortedList,
}: SortableColumnsListProps): JSX.Element => {
	return (
		<ReactSortable list={sortedColumnsToDisplayInTheDropdown} setList={onSortedList} onEnd={clickOutsideDropdown}>
			{sortedColumnsToDisplayInTheDropdown.map((column) => (
				<ColumnListItem
					key={column.id}
					column={column}
					selectedColumns={selectedColumns}
					onChangeSelected={onChangeSelected}
				/>
			))}
		</ReactSortable>
	);
};
