import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { TagApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import TagForm from "../../components/TagForm";

interface MatchParams {
	tagId: string;
	organizationId: string;
}

const OrganizationSettingsTagsEditPage = (): JSX.Element => {
	const [tag, setTag] = useState<TagApi | undefined>();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const confirmation = useConfirmation();
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const { tagId } = useParams<MatchParams>();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchTag();
	}, []);

	const onFetchTag = async () => {
		try {
			const res = await api.organization().getTag(Number(tagId));
			setTag(res);
			handleChangeTabTitle(`${res.name} | ${t("modules.tag.header.title")}`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removeTag = async (tagToBeRemoved: TagApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeTag(tagToBeRemoved.id);
			history.push(`/${organization.id}/settings/tags`);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!tag) {
		return <LoadingContainer />;
	}

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeTag(tag),
			dropdown: true,
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeTag(tag),
		},
	];

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={`${t("modules.tag.header.title")} "${tag.name}"`} buttons={isMobile ? [] : buttons} />
			<TagForm tag={tag} />
		</>
	);
};

export default OrganizationSettingsTagsEditPage;
