import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import FormatAddress, { FormatAddressString } from "go-core/components/Formatters/FormatAddress";
import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as DineInSVG } from "../../../../../../../images/svg/bills/dine_in.svg";
import { ReactComponent as FiscalizationSVG } from "../../../../../../../images/svg/bills/fiscalized.svg";
import { ReactComponent as ChairSVG } from "../../../../../../../images/svg/liveOrders/chair.svg";
import { ReactComponent as ClockWhiteSVG } from "../../../../../../../images/svg/liveOrders/clock-white.svg";
import { ReactComponent as ClockBlackSVG } from "../../../../../../../images/svg/liveOrders/clock.svg";
import { ReactComponent as CloseButtonSVG } from "../../../../../../../images/svg/liveOrders/close.svg";
import { ReactComponent as DriverSVG } from "../../../../../../../images/svg/liveOrders/driver.svg";
import { ReactComponent as LineThroughPersonSVG } from "../../../../../../../images/svg/liveOrders/line-through-person.svg";
import { ReactComponent as PersonSVG } from "../../../../../../../images/svg/liveOrders/person.svg";
import { ReactComponent as PickUpSVG } from "../../../../../../../images/svg/liveOrders/pick-up.svg";
import { ReactComponent as WalletSVG } from "../../../../../../../images/svg/liveOrders/wallet.svg";
import { LiveOrderApi, OrderPreparationStatusName } from "../../../../../../../services/Api/Organization/types";
import { RenderOrderSourceLogo } from "../../../../../../../utils/orders/RenderOrderSourceLogo";
import {
	areDeliveryCoordinates,
	getDeliveryTimeInSeconds,
	isDeliveryAddress,
	parseDeliveryTime,
} from "../services/orderDelivery";
import { getOrderStatusColor, statusColors } from "../services/orderStatus";
import { getLiveOrderNumber } from "../services/utils";
import LiveOrderStatus from "./LiveOrderStatus";

interface Props {
	order: LiveOrderApi;
	displayAsHeader?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	orderListRoutePath?: string;
	onSelectOrder?: (e: React.MouseEvent) => void;
	setFocusedOrder?: (order: LiveOrderApi | undefined) => void;
	className?: string;
	withoutDeliveryEmployee?: boolean;
	shouldRedirect?: boolean;
}

const LiveOrdersListItem: FC<Props> = ({
	order,
	onMouseEnter,
	onMouseLeave,
	setFocusedOrder,
	className,
	displayAsHeader,
	orderListRoutePath,
	onSelectOrder,
	withoutDeliveryEmployee,
	shouldRedirect,
}) => {
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const deliveryTimeTimeoutRef = useRef<number | undefined>();
	const [deliveryTimeInSeconds, setDeliveryTimeInSeconds] = useState<number>(
		getDeliveryTimeInSeconds(order?.execution_at)
	);
	const [deliveryTime, setDeliveryTime] = useState<string>(parseDeliveryTime(deliveryTimeInSeconds));
	const elementRef = useRef<HTMLDivElement | null>(null);

	const shouldDrawDeliveryEmployee = () => {
		if (withoutDeliveryEmployee) return false;
		if (order.type !== "DELIVERY") return false;

		return true;
	};

	useEffect(() => {
		return () => {
			clearTimeout(deliveryTimeTimeoutRef.current);
		};
	}, []);

	useEffect(() => {
		handleDeliveryTimeTimeout();
	}, [order.execution_at]);

	const handleDeliveryTimeTimeout = () => {
		if (!order?.execution_at) return;

		if (deliveryTimeTimeoutRef.current) {
			clearTimeout(deliveryTimeTimeoutRef.current);
		}
		deliveryTimeTimeoutRef.current = window.setTimeout(() => {
			const newDeliveryTime =
				formatStringToDate(order.execution_at).getTime() / 1000 - new Date().getTime() / 1000;
			setDeliveryTimeInSeconds(newDeliveryTime);
			setDeliveryTime(parseDeliveryTime(newDeliveryTime));
			handleDeliveryTimeTimeout();
		}, 1000);
	};

	const getStatusColor = () => {
		return getOrderStatusColor(deliveryTimeInSeconds) === statusColors.GREEN ||
			getOrderStatusColor(deliveryTimeInSeconds) === statusColors.RED
			? "#fff"
			: "#000";
	};

	const getStatusBackgroundColor = () => {
		return getOrderStatusColor(deliveryTimeInSeconds);
	};

	const drawDriver = () => {
		if (!shouldDrawDeliveryEmployee()) return <></>;

		const deliveryEmployee = order?.delivery?.delivery_employee;
		const name = deliveryEmployee ? deliveryEmployee.name : t("modules.live_order.field.no_driver_available.title");
		return (
			<div className="d-flex align-items-center w-100">
				<div className="svg-box">
					{deliveryEmployee ? (
						<PersonSVG className={`${displayAsHeader ? "" : "live-orders-green-icon"}`} />
					) : (
						<LineThroughPersonSVG className={`${displayAsHeader ? "" : "live-orders-red-icon"}`} />
					)}
				</div>
				<span className="text-muted text-truncate w-100 ms-1" title={name}>
					{name}
				</span>
			</div>
		);
	};

	const getFiscalizationIconColor = () => {
		const allWZ = order.transactions.every((transaction) => transaction.payment_method?.outflow_type === "WZ");
		if (
			order.fiscalization?.status === "FISCALIZED" ||
			order.fiscalization?.status === "FISCALIZED_RECEIVED" ||
			(!allWZ && order.transactions.length > 0 && order.status === "CLOSED")
		)
			return "live-orders-green-icon";
		return "live-orders-red-icon";
	};

	const drawPaymentStatus = () => {
		const isPaid = order.payment_status === "PAID";
		return (
			<div className="d-flex align-items-center justify-content-end">
				{order?.fiscalization && Object.keys(order.fiscalization).length > 0 && (
					<FiscalizationSVG className={`${getFiscalizationIconColor()} fiscalization-icon`} />
				)}
				<WalletSVG className={`${isPaid ? "live-orders-green-icon" : "live-orders-red-icon"}`} />
				<span className="text-muted text-truncate ms-1">
					{isPaid ? t("modules.live_order.field.paid.title") : t("modules.live_order.field.unpaid.title")}
				</span>
			</div>
		);
	};

	const onClose = (e: MouseEvent<HTMLOrSVGElement>) => {
		e.stopPropagation();
		setFocusedOrder && setFocusedOrder(undefined);
		orderListRoutePath && history.push(orderListRoutePath);
	};

	const drawDeliveryIcon = () => {
		if (!displayAsHeader && (!isDeliveryAddress(order) || !areDeliveryCoordinates(order)))
			return <DriverSVG className="live-orders-red-icon" />;
		return <DriverSVG />;
	};

	const drawPlacementIcon = () => {
		switch (order.type) {
			case "DELIVERY":
				return drawDeliveryIcon();
			case "DINE_IN":
				return <ChairSVG />;
			case "ROOM_SERVICE":
				return <DineInSVG />;
			case "PICK_UP":
				return <PickUpSVG />;
		}
	};

	const getAddress = (formatAddress?: boolean) => {
		switch (order.type) {
			case "DELIVERY":
				return isDeliveryAddress(order) ? (
					formatAddress ? (
						<FormatAddress address={order?.delivery?.address} />
					) : (
						FormatAddressString(order?.delivery?.address)
					)
				) : (
					t("modules.live_order.field.no_address.title")
				);
			case "DINE_IN": {
				if (order.table?.name || order.table?.room_name) {
					let title = "";
					if (order.table?.room_name) {
						title = order.table.room_name;
					}
					if (order.table?.name) {
						if (title !== "") title += `, ${order.table.name}`;
						else title = order.table.name;
					}

					return title;
				}
				return t("enums.orders.types.DINE_IN");
			}
			case "ROOM_SERVICE":
				return `${t("modules.live_order.field.room.title")}: ${order.table?.room_name}`;
			case "PICK_UP":
				return t("enums.orders.types.PICK_UP");
			default:
				return "-";
		}
	};

	const getAnimationClassAndRefreshAnimation = () => {
		const animatedClass = "live-orders-list-item--animated";

		if (!elementRef.current) return animatedClass;

		const ordersElement = document.querySelector(".live-orders-list-item--animated");
		const startTime =
			ordersElement?.getAnimations().find((animation) => (animation as CSSAnimation).animationName === "flashing")
				?.startTime || 0;
		const elementAnimation = elementRef.current
			.getAnimations()
			.find((animation) => (animation as CSSAnimation).animationName === "flashing");
		if (elementAnimation && elementAnimation.startTime !== startTime) {
			elementAnimation.startTime = startTime;
		}

		return animatedClass;
	};

	return (
		<div
			className={`live-orders-list-item ${!displayAsHeader && "cursor-pointer"} ${className} ${
				order.status === "EXTERNAL" && !displayAsHeader && getAnimationClassAndRefreshAnimation()
			}`}
			ref={elementRef}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onSelectOrder}
			key={deliveryTime}
		>
			<div className="live-orders-list-item-left-box">
				<RenderOrderSourceLogo source={order?.source?.name} variant="large" />
				<LiveOrderStatus status={order.preparation_status!.status} />
			</div>
			<div className="live-orders-list-item-right-box">
				<div className="row align-items-center">
					<div className="col-7">
						{!shouldRedirect ? (
							<h5 className="text-truncate mb-0" title={getLiveOrderNumber(order)}>
								{getLiveOrderNumber(order)}
							</h5>
						) : (
							<Link
								to={`/${organization.id}/sales/orders/${order.id}`}
								className="d-block text-truncate h6 mb-0 text-primary"
								title={getLiveOrderNumber(order)}
								target="_blank"
							>
								{getLiveOrderNumber(order)}
							</Link>
						)}
					</div>
					<div className="col-5 text-end text-nowrap">
						{displayAsHeader ? (
							<div className="close-button-box justify-content-end">
								<CloseButtonSVG className="mb-1 cursor-pointer close-button" onClick={onClose} />
							</div>
						) : (
							<h5 className="mb-0">{FormatMoney(order.total_price)}</h5>
						)}
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-7 d-flex text-truncate align-items-center">
						<div className="svg-box">{drawPlacementIcon()}</div>
						{displayAsHeader ? (
							<span className="font-weight-bold ms-1">{FormatMoney(order.total_price)}</span>
						) : (
							<span className="text-truncate text-muted ms-1 address" title={getAddress() as string}>
								{getAddress(true)}
							</span>
						)}
					</div>
					<div className="col-5 text-end pl-0" key={deliveryTime}>
						<div
							className="delivery-time-box ms-auto"
							style={{ backgroundColor: getStatusBackgroundColor() }}
						>
							<div className="svg-box">
								{getStatusColor() === "#fff" ? <ClockWhiteSVG /> : <ClockBlackSVG />}
							</div>
							<h5 style={{ color: getStatusColor() }} className="text-end text-nowrap">
								{deliveryTime}
							</h5>
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-7 d-flex align-items-center">{drawDriver()}</div>
					<div className="col-5">{drawPaymentStatus()}</div>
				</div>
			</div>
		</div>
	);
};

export default LiveOrdersListItem;
