import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationSettingsDevicesEditPage from "./pages/Edit";
import OrganizationSettingsDevicesIndexPage from "./pages/Index";
import OrganizationSettingsDevicesNewPage from "./pages/New";

const OrganizationSettingsDevicesPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route exact path={`${props.match.url}/`} component={OrganizationSettingsDevicesIndexPage} />
				<Route exact={true} path={`${props.match.url}/new`} component={OrganizationSettingsDevicesNewPage} />
				<Route path={`${props.match.url}/:deviceId`} component={OrganizationSettingsDevicesEditPage} />
			</Switch>
		</div>
	);
};
export default OrganizationSettingsDevicesPage;
