import React, { useRef, useState } from "react";
import { CancelTokenSource } from "axios";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import EmptyData from "go-core/components/EmptyData";
import { Loading } from "go-core/components/Loading";
import { useInfiniteScroll } from "go-core/hooks/useInfiniteScroll";
import { FILTER_VALUE_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import InputWithSearch from "../../../../../../../../../../components/Common/Inputs/InputWithSearch";
import { ItemApi, ModifierGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import CreateOrUpdateModifierGroupModalForm from "./CreateOrUpdateModifierGroupModalForm";

interface Props {
	handleSave: (
		data: ModifierGroupApi,
		itemId: number | null,
		items: ItemApi[] | undefined,
		quantityOverrides?: Record<string, any>[],
		priceOverrides?: Record<string, any>[],
		quantityItemOverrides?: Record<string, any>[]
	) => void;
	handleClose: () => void;
	itemGroupName: string;
	items: ItemApi[];
	index: number | null;
	modifierGroup?: ModifierGroupApi;
	itemsInGroup?: ItemApi[];
	selectedValues: number[];
	errors?: Record<string, any>[];
}

const SearchModifierGroupForm = (props: Props): JSX.Element => {
	const [newItemMode, setNewItemMode] = useState(props.index !== null);
	const [searchText, setSearchText] = useState("");
	const [modifierGroup, setModifierGroup] = useState<ModifierGroupApi | undefined>(
		props.modifierGroup ? props.modifierGroup : undefined
	);
	const organization = useSelector(selectOrganization);
	const [modifierGroups, setModifierGroups] = useState<ModifierGroupApi[]>([]);
	const { t } = useTranslation();
	const scrollRef = useRef(null);

	const fetchModifierGroups = (params: Record<string, any>, options?: CancelTokenSource) => {
		const finalParams: Record<string, any> = {
			include: "options,options.item,options.quantity_info,options.price",
			f: btoa(
				unescape(
					encodeURIComponent(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}id|ne=${props.selectedValues.join(FILTER_VALUE_SEPARATOR)}`
					)
				)
			),
			...params,
		};
		return api.organization().getModifierGroups(finalParams, {
			cancelToken: options?.token,
		});
	};

	const { items, loading } = useInfiniteScroll({
		fetchItems: fetchModifierGroups,
		search: searchText,
		scrollContainerElementRef: scrollRef,
	});

	const getFilteredModifierGroups = () => {
		return [
			...modifierGroups,
			...items.filter((item: ModifierGroupApi) => {
				const existingItem = modifierGroups.find((i) => item.id === i.id);
				if (!existingItem) return item;
				return false;
			}),
		];
	};

	const handleSelectGroup = (modifier: ModifierGroupApi) => {
		props.handleSave(modifier, null, undefined);
	};

	const handleSelectGroups = () => {
		modifierGroups.length > 0 &&
			modifierGroups.forEach((modifier) => {
				handleSelectGroup(modifier);
			});
	};

	const selectModifierGroups = (item: ModifierGroupApi) => {
		if (modifierGroups.length > 0) {
			const index = modifierGroups.findIndex((i) => i.id === item.id);
			const newArr = [...modifierGroups];
			if (index > -1) {
				newArr.splice(index, 1);
				setModifierGroups([...newArr]);
			} else setModifierGroups([...newArr, item]);
		} else setModifierGroups([item]);
	};

	const handleSaveModifierGroup = (
		data: ModifierGroupApi,
		id: number | null,
		items: ItemApi[] | undefined,
		quantityOverrides?: Record<string, any>[],
		priceOverrides?: Record<string, any>[],
		quantityItemOverrides?: Record<string, any>[]
	) => {
		if (id) {
			props.handleSave(data, id, items, quantityOverrides, priceOverrides, quantityItemOverrides);
		} else {
			setModifierGroup(data);
		}
	};

	const getCheckboxesValue = (item: ModifierGroupApi) => {
		return modifierGroups.includes(item);
	};

	return (
		<Modal size={"lg"} className="menu-page-item-modal" show={true} onHide={props.handleClose}>
			{!newItemMode ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							{`${t("modules.modifier_group.field.assign_modifier_group_to.title")} "${
								props.itemGroupName
							}"`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="items" ref={scrollRef}>
						<div className="sticky-modal-search-header">
							<div className="form-group search-input">
								<div className="input-group">
									<InputWithSearch
										value={searchText}
										onButtonClick={() => setNewItemMode(true)}
										onInput={(msg: string) => setSearchText(msg)}
									/>
								</div>
							</div>
						</div>
						<div className="table-responsive">
							<table className="table">
								<tbody>
									<>
										{getFilteredModifierGroups().length !== 0
											? getFilteredModifierGroups().map((x: ModifierGroupApi, index: number) => {
													return (
														<tr key={x.id}>
															<td className="p-0 w-1">
																<label>
																	<input
																		type="checkbox"
																		className="me-2"
																		aria-label={`item-${index}`}
																		checked={getCheckboxesValue(x)}
																		onClick={() => selectModifierGroups(x)}
																	/>
																</label>
															</td>
															<td>
																<div className="d-flex align-items-center">
																	<RenderImage data={x} variant="sm" />
																	<Link
																		to={`/${organization.id}/menu/modifier_groups/${x.id}`}
																		target={"_blank"}
																		rel="noreferrer"
																	>
																		{x.name}
																	</Link>
																</div>
															</td>
															<td style={{ padding: "0px" }}>
																<Button
																	className="float-end"
																	variant={"primary-light"}
																	type="button"
																	onClick={() => handleSelectGroup(x)}
																>
																	{t("lib:common.action.select")}
																</Button>
															</td>
														</tr>
													);
											  })
											: !loading && <EmptyData />}
									</>
								</tbody>
							</table>
						</div>
						{loading && <Loading />}
					</Modal.Body>
					{modifierGroups.length > 0 && (
						<Modal.Footer>
							<Button variant="primary" onClick={handleSelectGroups}>
								{t("common.action.select", { ns: "lib" })}
							</Button>
						</Modal.Footer>
					)}
				</>
			) : (
				<CreateOrUpdateModifierGroupModalForm
					errors={props.errors}
					items={props.items}
					itemsInGroup={props.itemsInGroup}
					modifierGroup={modifierGroup}
					modifierGroupIndex={props.index}
					itemGroupName={props.itemGroupName}
					handleSave={handleSaveModifierGroup}
					searchText={modifierGroup?.name ? modifierGroup.name : searchText}
					handleClose={props.handleClose}
					handleCancel={() => setNewItemMode(false)}
				/>
			)}
		</Modal>
	);
};

export default SearchModifierGroupForm;
