import React from "react";
import { Location } from "history";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubNavigationItems, { SubNavigationItemProps } from "go-app/components/Navigation/SubNavigationItems";
import { selectOrganization } from "go-security/services/organizations/selectors";

const EmployeesNavigation = (): JSX.Element => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const prefix = `/${organization.id}/employees`;

	const items: SubNavigationItemProps[] = [
		{
			title: t("modules.employee.header.title"),
			route: `${prefix}`,
			isActiveRoute: (match: Record<string, any> | null, location: Location) =>
				!location.pathname.includes("/worktimes") &&
				!location.pathname.includes("/workplaces") &&
				!location.pathname.includes("/online"),
		},
		{
			title: t("modules.employee_workplace.header.title"),
			route: `${prefix}/workplaces`,
		},
		{
			title: t("modules.employee.header.online.title"),
			route: `${prefix}/online`,
		},
		{
			title: t("modules.work_time.header.title"),
			route: `${prefix}/worktimes_report`,
		},
	];

	return <SubNavigationItems title={t("components.navigation.employees")} items={items} />;
};

export default EmployeesNavigation;
