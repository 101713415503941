import React, { FC, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { getPdfFontSizeOptions } from "go-core/utils/utils";
import { FormDirty } from "go-form/components/FormDirty";
import { useAuth } from "go-security/services/auth";
import { selectOrganization } from "go-security/services/organizations/selectors";
import Search from "../../../../../../../../../../../components/Common/Search/Search";
import {
	OrderPreparationStatusName,
	PdfOrientationSettingEnum,
	VenueSettingsApi,
	VenueSettingsKdsItemApi,
	WeightBarcodeApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";
import { VenueSettingsItemApi } from "../../../../../../../../../../../services/Api/types";
import { getApiURL } from "../../../../../../../../../../../utils/envutil";
import { DriversCard } from "./DriversCard";
import EditSalesSettingsCard from "./EditSalesSettingsCard";
import ExternalStockCard from "./ExternalStockCard";
import KioskSettingsCard from "./KioskSettings/KioskSettingsCard";
import LimitDirectionsCard from "./LimitDirectionsCard";
import LiveOrdersSettingsCard from "./LiveOrdersSettingsCard";
import OpenSalesSettingsCard from "./OpenSalesSettingsCard";
import OtherSalesSettingsCard from "./OtherSalesSettingsCard";
import OtherSettingsCard from "./OtherSettingsCard";
import PaymentsAndReceiptsCard from "./PaymentsCard";
import PreparationStatusesCard from "./PreparationStatusesCard";
import PrintoutTemplatesCard from "./PrintoutTemplatesCard";
import ReportSettingsCard from "./ReportSettingsCard";
import WeightedBarcodesCard from "./WeightedBarcodesCard";

interface Props {
	settings: VenueSettingsApi;
	barcodeSettings: string[];
}

const arePreparationStatusesIdentical = (
	defaultPreparationStatuses: OrderPreparationStatusName[],
	preparationStatuses: OrderPreparationStatusName[]
) => {
	const sortedDefaultPreparationStatuses = [...defaultPreparationStatuses].sort((a, b) => a.localeCompare(b));
	const sortedPreparationStatuses = [...preparationStatuses].sort((a, b) => a.localeCompare(b));

	return JSON.stringify(sortedDefaultPreparationStatuses) === JSON.stringify(sortedPreparationStatuses);
};

export const getOptionsByVenueSetting = (defaultValue: string, id: string, t: TFunction): Record<string, any>[] => {
	const saleStatusPreparationDefault = [
		{
			value: "NEW",
			label: t("enums.preparation_statuses.NEW"),
		},
		{
			value: "IN_PROGRESS",
			label: t("enums.preparation_statuses.IN_PROGRESS"),
		},
		{
			value: "READY",
			label: t("enums.preparation_statuses.READY"),
		},
		{
			value: "PACKED",
			label: t("enums.preparation_statuses.PACKED"),
		},
		{
			value: "RECEIVED",
			label: t("enums.preparation_statuses.RECEIVED"),
		},
		{
			value: "DELIVERED",
			label: t("enums.preparation_statuses.DELIVERED"),
		},
	];

	if (defaultValue === "false" || defaultValue === "true") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "true",
				label: t("common.word.yes", { ns: "lib" }),
			},
			{
				value: "false",
				label: t("common.word.no", { ns: "lib" }),
			},
		];
	}
	if (id === "REPORT_TYPE") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "ShiftWork",
				label: t("modules.venue_settings.field.shiftwork.title"),
			},
			{
				value: "Drawer",
				label: t("modules.venue_settings.field.drawer.title"),
			},
		];
	}
	if (id === "REPORT_POS_SETTLEMENT") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "DISABLED",
				label: t("modules.venue_settings.field.DISABLED.title"),
			},
			{
				value: "MULTI",
				label: t("modules.venue_settings.field.MULTI.title"),
			},
			{
				value: "MULTI_REQUIRED",
				label: t("modules.venue_settings.field.MULTI_REQUIRED.title"),
			},
		];
	}
	if (id === "SALE_TAX_ID_NO") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "DISABLED",
				label: t("modules.venue_settings.field.DISABLED.title"),
			},
			{
				value: "ENABLED",
				label: t("modules.venue_settings.field.ENABLED.title"),
			},
			{
				value: "ENABLED_ASK",
				label: t("modules.venue_settings.field.ENABLED_ASK.title"),
			},
			{
				value: "ENABLED_INVOICE_ONLY",
				label: t("modules.venue_settings.field.ENABLED_INVOICE_ONLY.title"),
			},
		];
	}
	if (id === "SALE_SAVE_ON_CLOSE") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "CANCEL_AND_DISMISS",
				label: t("modules.venue_settings.field.CANCEL_AND_DISMISS.title"),
			},
			{
				value: "SAVE_AND_LOGOUT",
				label: t("modules.venue_settings.field.SAVE_AND_LOGOUT.title"),
			},
		];
	}
	if (id === "SALE_STATUS_PREPERATION_DEFAULT") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			...saleStatusPreparationDefault,
		];
	}
	if (id === "SCANNER_TYPE") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "PRODUCTS",
				label: t("modules.venue_settings.field.PRODUCTS.title"),
			},
			{
				value: "CLIENTS",
				label: t("modules.venue_settings.field.CLIENTS.title"),
			},
			{
				value: "ASK",
				label: t("modules.venue_settings.field.ASK.title"),
			},
			{
				value: "AUTO",
				label: t("modules.venue_settings.field.AUTO.title"),
			},
		];
	}
	if (id === "KDS_MERGE_ITEMS") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				value: "ORIGINAL",
				label: t("modules.venue_settings.field.ORIGINAL.title"),
			},
			{
				value: "MERGE",
				label: t("modules.venue_settings.field.MERGE.title"),
			},
		];
	}

	if (id === "KITCHEN_ORDER_PRINTING_TRIGGER_TYPE") {
		return [
			{
				label: t("modules.venue_settings.field.printout_templates.instant"),
				value: "INSTANT",
			},
			{
				label: t("modules.venue_settings.field.printout_templates.manual"),
				value: "MANUAL",
			},
			{
				label: t("modules.venue_settings.field.printout_templates.after_screen_lock"),
				value: "AFTER_SCREEN_LOCK",
			},
		];
	}

	if (id === "DRIVER_CAN_CHANGE_STATUS_FROM_STATUS") {
		return [
			{
				label: t("common.word.default"),
				value: "null",
			},
			...saleStatusPreparationDefault,
		];
	}

	if (id === "PDF_ORIENTATION") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				label: t("enums.pdf_orientation_settings.LANDSCAPE"),
				value: PdfOrientationSettingEnum.LANDSCAPE,
			},
			{
				label: t("enums.pdf_orientation_settings.PORTRAIT"),
				value: PdfOrientationSettingEnum.PORTRAIT,
			},
			{
				label: t("enums.pdf_orientation_settings.ASK"),
				value: PdfOrientationSettingEnum.ASK,
			},
		];
	}

	if (id === "DEFAULT_PDF_FONT_SIZE") {
		return [
			{
				value: "empty",
				label: t("common.word.default"),
			},
			{
				label: t("enums.pdf_orientation_settings.ASK"),
				value: "0",
			},
			...getPdfFontSizeOptions(),
		];
	}

	return [];
};

const VenueSettingsForm: FC<Props> = ({ settings, barcodeSettings }) => {
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const auth = useAuth(getApiURL());
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const reportTypes = [
		{
			id: "REPORT_TYPE",
			defaultValue: "ShiftWork",
		},
		{
			id: "REPORT_POS_SETTLEMENT",
			defaultValue: "DISABLED",
		},
		{
			id: "REPORT_AUTO_START_AMOUNT",
			defaultValue: "false",
		},
		{
			id: "REPORT_REMOVES_BILLS_AFTER_OPEN",
			defaultValue: "true",
		},
	];
	const saleOpenTypes = [
		{
			id: "SALE_DEFAULT_BUTTON",
			defaultValue: "true",
		},
		{
			id: "SALE_BILL_TYPE_BUTTON",
			defaultValue: "false",
		},
		{
			id: "SALE_QUICK_BUTTON",
			defaultValue: "true",
		},
		{
			id: "SALE_CLIENT_BUTTON",
			defaultValue: "false",
		},
		{
			id: "SALE_COMMENT_BUTTON",
			defaultValue: "true",
		},
		{
			id: "SALE_GROUP_BUTTON",
			defaultValue: "true",
		},
		{
			id: "SALE_GUEST_NUMBER_POPUP",
			defaultValue: "true",
		},
	];
	const saleEditTypes = [
		{
			id: "SALE_GUEST_NUMBER_BUTTON",
			defaultValue: "true",
		},
		{
			id: "SALE_SEAT",
			defaultValue: "false",
		},
		{
			id: "SALE_CLIENT_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_CLIENT_CARD_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_TABLE_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_OPEN_CASH_DRAWER",
			defaultValue: "false",
		},
		{
			id: "SALE_DESCRIPTION_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_SPLIT_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_PRINT_INITIAL_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_RESEND_TO_KITCHEN",
			defaultValue: "true",
		},
		{
			id: "SALE_DETERMINED_PRICE_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_CLIENT_BARCODE_OPTION",
			defaultValue: "true",
		},
		{
			id: "SALE_MENU_GRID_OPTION",
			defaultValue: "",
		},
		{
			id: "SALE_MENU_SEARCH_OPTION",
			defaultValue: "",
		},
		{
			id: "SALE_MENU_SKU_OPTION",
			defaultValue: "",
		},
		{
			id: "SALE_VALIDATE_ORDER_TYPE",
			defaultValue: "",
		},
	];
	const otherSaleTypes = [
		{
			id: "SALE_OPEN_BILLS",
			defaultValue: "true",
		},
		{
			id: "SALE_NOT_AVAILABLE_PRODUCTS",
			defaultValue: "true",
		},
		{
			id: "SALE_SAVE_ON_CLOSE",
			defaultValue: "CANCEL_AND_DISMISS",
		},
		{
			id: "SALE_STORNO_REASON",
			defaultValue: "false",
		},
		{
			id: "SALE_TAX_ID_NO",
			defaultValue: "ENABLED",
		},
		{
			id: "SALE_AUTO_RELEASE",
			defaultValue: "true",
		},
		{
			id: "SCANNER_TYPE",
			defaultValue: "DISABLED",
		},
		{
			id: "TRANSFER_SAFE_MODE",
			defaultValue: "false",
		},
		{
			id: "SALE_LOGOUT_AFTER_CLOSE_BILL",
			defaultValue: "false",
		},
		{
			id: "SALE_ORDER_ITEM_COURSE_HEADER",
			defaultValue: "false",
		},
		{
			id: "SALE_FORCE_ORDER_FISCALIZATION",
			defaultValue: "false",
		},
	];
	const driversTypes = useMemo(
		() => [
			{
				id: "IS_LOCATION_REQUIRED",
				defaultValue: "true",
			},
			{
				id: "DRIVER_CAN_CHANGE_STATUS_FROM_STATUS",
				defaultValue: "empty",
				customPlaceholder: t("common.word.lack"),
			},
			{
				id: "DRIVER_CAN_CHANGE_STATUS_TO_DELIVERED_FROM_METERS",
				isNumberInput: true,
			},
			{
				id: "CITY_SEARCH_DISTANCE_KM",
				isNumberInput: true,
			},
			{
				id: "SALE_VALIDATE_DELIVERY_EMPLOYEE",
				defaultValue: "true",
			},
			{
				id: "DRIVER_CAN_ASSIGN_ITSELF",
				defaultValue: "false",
			},
		],
		[t]
	);
	const otherTypes = [
		{
			id: "USER_LOGOUT_THRESHOLD",
			isNumberInput: true,
		},
		{
			id: "RESERVATION",
			defaultValue: "true",
		},
		{
			id: "SALE_DISPLAY_STOCK",
			defaultValue: "false",
		},
		{
			id: "KDS_FLASH_SECONDS",
			isNumberInput: true,
		},
		{
			id: "PRINT_COMPANY_ON_PDF",
			defaultValue: "false",
		},
	];

	const paymentsAndReceiptsTypes = [
		{
			id: "SALE_PRINT_PAYMENT_TERMINAL_CONFIRMATION",
			defaultValue: "false",
		},
		{
			id: "RECEIPT_EMAIL",
			defaultValue: "false",
		},
		{
			id: "RECEIPT_SMS",
			defaultValue: "false",
		},
		{
			id: "RECEIPT_QR_CODE",
			defaultValue: "false",
		},
		{
			id: "RECEIPT_PRINTOUT",
			defaultValue: "true",
		},
	];
	const preparationStatusesTypes = [
		{
			id: "KDS_MERGE_ITEMS",
			defaultValue: "",
		},
		{
			id: "SALE_STATUS_PREPERATION_DEFAULT",
			defaultValue: "",
		},
		{
			id: "SALE_BILL_SET_STATUS_PREPARATION",
			defaultValue: "",
		},
		{
			id: "NOTIFICATION_STATUS_PREPERATION",
			defaultValue: "",
		},
	];
	const stockTypes = [
		{
			id: "STOCK_TRACKING",
			defaultValue: "",
		},
		{
			id: "SALE_EMPTY_STOCK",
			defaultValue: "",
		},
	];

	const printoutSettings = [
		{
			id: "DIRECTION_PRINTOUT_ID",
			defaultValue: "",
		},
		{
			id: "KITCHEN_ORDER_PRINTING_TRIGGER_TYPE",
			defaultValue: "",
		},
	];

	const getBarcodeType = (barcode: string) => {
		let str = "";
		Array.from(barcode).forEach((c: string) => {
			if (c >= "0" && c <= "9") str += c;
			else return str;
		});
		return str;
	};

	const allSettingsOptions = [
		...reportTypes,
		...saleOpenTypes,
		...saleEditTypes,
		...otherSaleTypes,
		...otherTypes,
		...paymentsAndReceiptsTypes,
		...preparationStatusesTypes,
		...stockTypes,
		...printoutSettings,
		...driversTypes,
	].filter((f) => !f.id.includes("?"));

	const getNumberInputDefaultValue = (
		foundSetting: VenueSettingsItemApi,
		setting: Record<string, any>,
		unit: string
	) => {
		const defaultValueWithUnit = foundSetting.default_value?.includes(unit)
			? foundSetting.default_value
			: `${foundSetting.default_value} ${unit}`;
		foundSetting.default_value = defaultValueWithUnit;
		setting.defaultValue = defaultValueWithUnit;
	};

	const mapSettings = (settings: VenueSettingsApi) => {
		const newSettings: VenueSettingsItemApi[] = [];
		allSettingsOptions.forEach((setting) => {
			const found = settings.settings?.find((f) => f.type === setting.id);
			if (found) {
				if (found.default_value) {
					setting.defaultValue = found.default_value;
				}
				if (!found.value) {
					found.value = "empty";
				}
				if (setting.id === "SALE_BILL_SET_STATUS_PREPARATION" && found.default_value && !found.value) {
					found.value = found.default_value;
				}
				if (setting.id === "CITY_SEARCH_DISTANCE_KM") {
					getNumberInputDefaultValue(found, setting, "km");
				}
				if (setting.id === "DRIVER_CAN_CHANGE_STATUS_TO_DELIVERED_FROM_METERS") {
					getNumberInputDefaultValue(found, setting, "m");
				}
				if (setting.id === "USER_LOGOUT_THRESHOLD") {
					getNumberInputDefaultValue(found, setting, "s");
				}
				newSettings.push(found);
			} else {
				newSettings.push({
					value: "empty",
					type: setting.id,
				});
			}
		});
		const set = settings.direction_printout;
		if (!set) {
			newSettings.push({
				value: "empty",
				type: "DIRECTION_PRINTOUT_ID",
			});
		} else {
			newSettings.push({
				value: settings.direction_printout?.id.toString(),
				type: "DIRECTION_PRINTOUT_ID",
			});
		}

		settings.weight_barcodes = settings.weight_barcodes.map((barcode) => {
			if (typeof barcode === "string") {
				const type = getBarcodeType(barcode);
				return {
					type,
					value: barcode.replace(type, ""),
				};
			}
			return barcode;
		});

		const areStatusesIdentical = arePreparationStatusesIdentical(
			settings.supported_preparation_statuses.default_values,
			settings.supported_preparation_statuses.values
		);
		settings.preparation_statuses = areStatusesIdentical ? [] : settings.supported_preparation_statuses.values;
		settings.supported_preparation_statuses.values = areStatusesIdentical
			? []
			: settings.supported_preparation_statuses.values;

		const pdfOrientationSetting = settings.settings.find((setting) => setting.type === "PDF_ORIENTATION");
		if (!pdfOrientationSetting) {
			newSettings.push({
				type: "PDF_ORIENTATION",
				default_value: "LANDSCAPE",
				value: "empty",
			});
		} else {
			newSettings.push({
				type: "PDF_ORIENTATION",
				default_value: "LANDSCAPE",
				value: pdfOrientationSetting.value,
			});
		}

		const defaultPdfFontSizeSetting = settings.settings.find((setting) => setting.type === "DEFAULT_PDF_FONT_SIZE");
		if (!defaultPdfFontSizeSetting) {
			newSettings.push({
				type: "DEFAULT_PDF_FONT_SIZE",
				default_value: "9",
				value: "empty",
			});
		} else {
			newSettings.push({
				type: "DEFAULT_PDF_FONT_SIZE",
				default_value: "9",
				value: defaultPdfFontSizeSetting.value,
			});
		}

		settings.kiosk.translations = [...settings.kiosk.translations].sort((a, b) =>
			(a?.locale || "").localeCompare(b?.locale || "")
		);

		return settings;
	};

	const form = useForm<VenueSettingsApi>({
		criteriaMode: "all",
		defaultValues: mapSettings(settings),
		shouldUnregister: false,
	});
	const [search, setSearch] = useState("");
	const onSearch = (name: string) => {
		setSearch(name);
	};
	const { handleSubmit, reset, setError, formState, control } = form;

	const onSubmit = handleSubmit(async (data: VenueSettingsApi) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.kds_settings.forEach((kds: VenueSettingsKdsItemApi) => {
			kds.devices = [];
		});
		newData.weight_barcodes = newData.weight_barcodes.map((bc: WeightBarcodeApi) => `${bc.type}${bc.value}`);
		newData.settings = newData.settings.map((x: VenueSettingsItemApi) => {
			const cnf = getOptionsByVenueSetting(x.default_value ? x.default_value : "", x.type, t);
			if (
				!cnf.map((x) => x.value).includes(x.value ? x.value : "") &&
				x.type !== "DRIVER_CAN_CHANGE_STATUS_TO_DELIVERED_FROM_METERS" &&
				x.type !== "CITY_SEARCH_DISTANCE_KM" &&
				x.type !== "USER_LOGOUT_THRESHOLD" &&
				x.type !== "KDS_FLASH_SECONDS"
			) {
				x.value = undefined;
			}

			if (x.type === "DIRECTION_PRINTOUT_ID") {
				x.value = data.direction_printout?.id.toString();
			}

			if (
				x.type === "DRIVER_CAN_CHANGE_STATUS_TO_DELIVERED_FROM_METERS" &&
				(x.value === null || x.value === "")
			) {
				delete x.value;
			}

			if (
				(x.type === "CITY_SEARCH_DISTANCE_KM" ||
					x.type === "USER_LOGOUT_THRESHOLD" ||
					x.type === "KDS_FLASH_SECONDS") &&
				(x.value === null || x.value === "" || x.value === "empty")
			) {
				delete x.value;
			}
			return x.value === "empty" ? { ...x, value: null } : x;
		});

		newData.preparation_statuses =
			data.supported_preparation_statuses.values.length > 0
				? data.supported_preparation_statuses.values
				: data.supported_preparation_statuses.default_values;
		delete newData.supported_preparation_statuses;

		setLoading(true);
		try {
			const res = await api.organization().updateVenueSettings(newData);
			reset(mapSettings(res));
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));

			await auth.loginOrganization(Number(organization?.id));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});
	const { fields } = useFieldArray({
		control,
		name: "kds_settings",
		keyName: "key",
	});

	const filteredDriversTypes = useMemo(
		() =>
			driversTypes.filter(
				(f) =>
					t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
					t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
			),
		[driversTypes, t, search]
	);

	return (
		<FormDirty formState={formState} loading={loading} key="client-group-form" noValidate onSubmit={onSubmit}>
			<div className={"d-flex flex-column"}>
				<Search onSearch={onSearch} placeholder={t("modules.venue_settings.action.search.title")} />
				<PreparationStatusesCard
					fields={fields}
					allSettingsOptions={allSettingsOptions}
					form={form}
					search={search}
					title={t("modules.venue_settings.field.statuses.title")}
					types={preparationStatusesTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
				/>
				<LimitDirectionsCard
					fields={fields}
					title={t("modules.venue_settings.field.printout.title")}
					form={form}
				/>
				<ExternalStockCard
					types={stockTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
					form={form}
					title={t("modules.venue_settings.field.internal_stock.title")}
					search={search}
				/>
				<PrintoutTemplatesCard title={t("modules.venue_settings.field.printout_templates.title")} form={form} />
				<ReportSettingsCard
					search={search}
					title={t("modules.venue_settings.field.report.title")}
					form={form}
					types={reportTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
				/>
				<OpenSalesSettingsCard
					search={search}
					title={t("modules.venue_settings.field.open_sales.title")}
					form={form}
					types={saleOpenTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
				/>
				<EditSalesSettingsCard
					search={search}
					title={t("modules.venue_settings.field.edit_sales.title")}
					form={form}
					types={saleEditTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
				/>
				<WeightedBarcodesCard
					weightBarcodesConfig={barcodeSettings}
					title={t("modules.venue_settings.field.pos.title")}
					form={form}
				/>
				<PaymentsAndReceiptsCard
					types={paymentsAndReceiptsTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
					form={form}
					title={t("modules.venue_settings.field.payments_and_receipts.title")}
					search={search}
				/>
				<DriversCard
					form={form}
					title={t("modules.venue_settings.field.drivers.title")}
					types={filteredDriversTypes}
					search={search}
				/>
				<LiveOrdersSettingsCard form={form} />
				<KioskSettingsCard form={form} search={search} />
				<OtherSalesSettingsCard
					search={search}
					title={t("modules.venue_settings.field.other_sales.title")}
					form={form}
					types={otherSaleTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
				/>
				<OtherSettingsCard
					search={search}
					title={t("modules.venue_settings.field.other.title")}
					form={form}
					types={otherTypes.filter(
						(f) =>
							t(`enums.venue_settings.${f.id}.label`).toLowerCase().includes(search.toLowerCase()) ||
							t(`enums.venue_settings.${f.id}.desc`).toLowerCase().includes(search)
					)}
				/>
				<hr />
			</div>
		</FormDirty>
	);
};
export default VenueSettingsForm;

// t("enums.venue_settings.RECEIPT_EMAIL.label");
// t("enums.venue_settings.RECEIPT_SMS.label");
// t("enums.venue_settings.RECEIPT_QR_CODE.label");
// t("enums.venue_settings.RECEIPT_PRINTOUT.label");
// t("enums.venue_settings.KDS_MERGE_ITEMS.label");
// t("enums.venue_settings.SALE_STATUS_PREPERATION_DEFAULT.label");
// t("enums.venue_settings.SALE_BILL_SET_STATUS_PREPARATION.label");
// t("enums.venue_settings.NOTIFICATION_STATUS_PREPERATION.label");
// t("enums.venue_settings.STOCK_TRACKING.label");
// t("enums.venue_settings.SALE_EMPTY_STOCK.label");
// t("enums.venue_settings.REPORT_TYPE.label");
// t("enums.venue_settings.REPORT_POS_SETTLEMENT.label");
// t("enums.venue_settings.REPORT_AUTO_START_AMOUNT.label");
// t("enums.venue_settings.REPORT_REMOVES_BILLS_AFTER_OPEN.label");
// t("enums.venue_settings.SALE_DEFAULT_BUTTON.label");
// t("enums.venue_settings.SALE_BILL_TYPE_BUTTON.label");
// t("enums.venue_settings.SALE_QUICK_BUTTON.label");
// t("enums.venue_settings.SALE_CLIENT_BUTTON.label");
// t("enums.venue_settings.SALE_COMMENT_BUTTON.label");
// t("enums.venue_settings.SALE_GROUP_BUTTON.label");
// t("enums.venue_settings.SALE_GUEST_NUMBER_POPUP.label");
// t("enums.venue_settings.SALE_SEAT.label");
// t("enums.venue_settings.SALE_GUEST_NUMBER_BUTTON.label");
// t("enums.venue_settings.SALE_CLIENT_OPTION.label");
// t("enums.venue_settings.SALE_CLIENT_CARD_OPTION.label");
// t("enums.venue_settings.SALE_TABLE_OPTION.label");
// t("enums.venue_settings.SALE_OPEN_CASH_DRAWER.label");
// t("enums.venue_settings.SALE_DESCRIPTION_OPTION.label");
// t("enums.venue_settings.SALE_SPLIT_OPTION.label");
// t("enums.venue_settings.SALE_PRINT_INITIAL_OPTION.label");
// t("enums.venue_settings.SALE_RESEND_TO_KITCHEN.label");
// t("enums.venue_settings.SALE_DETERMINED_PRICE_OPTION.label");
// t("enums.venue_settings.SALE_CLIENT_BARCODE_OPTION.label");
// t("enums.venue_settings.SALE_MENU_GRID_OPTION.label");
// t("enums.venue_settings.SALE_MENU_SEARCH_OPTION.label");
// t("enums.venue_settings.SALE_MENU_SKU_OPTION.label");
// t("enums.venue_settings.SALE_OPEN_BILLS.label");
// t("enums.venue_settings.SALE_NOT_AVAILABLE_PRODUCTS.label");
// t("enums.venue_settings.SALE_SAVE_ON_CLOSE.label");
// t("enums.venue_settings.SALE_STORNO_REASON.label");
// t("enums.venue_settings.SALE_TAX_ID_NO.label");
// t("enums.venue_settings.SALE_AUTO_RELEASE.label");
// t("enums.venue_settings.SCANNER_TYPE.label");
// t("enums.venue_settings.TRANSFER_SAFE_MODE.label");
// t("enums.venue_settings.SALE_LOGOUT_AFTER_CLOSE_BILL.label");
// t("enums.venue_settings.USER_LOGOUT_THRESHOLD.label");
// t("enums.venue_settings.RESERVATION.label");
// t("enums.venue_settings.SALE_PRINT_PAYMENT_TERMINAL_CONFIRMATION.label");
// t("enums.venue_settings.IS_LOCATION_REQUIRED.label");
// t("enums.venue_settings.DRIVER_CAN_ASSIGN_ITSELF.label");
// t("enums.venue_settings.DRIVER_CAN_CHANGE_STATUS_FROM_STATUS.label");
// t("enums.venue_settings.DRIVER_CAN_CHANGE_STATUS_TO_DELIVERED_FROM_METERS.label");
// t("enums.venue_settings.SALE_VALIDATE_DELIVERY_EMPLOYEE.label");
// t("enums.venue_settings.SALE_VALIDATE_ORDER_TYPE.label");
// t("enums.venue_settings.KDS_FLASH_SECONDS.label");
// t("enums.venue_settings.SALE_ORDER_ITEM_COURSE_HEADER.label");
// t("enums.venue_settings.CITY_SEARCH_DISTANCE_KM.label");
// t("enums.venue_settings.SALE_FORCE_ORDER_FISCALIZATION.label");
// t("enums.venue_settings.PRINT_COMPANY_ON_PDF.label");
// t("enums.venue_settings.RECEIPT_EMAIL.desc");
// t("enums.venue_settings.RECEIPT_SMS.desc");
// t("enums.venue_settings.RECEIPT_QR_CODE.desc");
// t("enums.venue_settings.RECEIPT_PRINTOUT.desc");
// t("enums.venue_settings.SALE_ORDER_ITEM_COURSE_HEADER.desc");
// t("enums.venue_settings.KDS_MERGE_ITEMS.desc");
// t("enums.venue_settings.SALE_STATUS_PREPERATION_DEFAULT.desc");
// t("enums.venue_settings.SALE_BILL_SET_STATUS_PREPARATION.desc");
// t("enums.venue_settings.NOTIFICATION_STATUS_PREPERATION.desc");
// t("enums.venue_settings.STOCK_TRACKING.desc");
// t("enums.venue_settings.SALE_EMPTY_STOCK.desc");
// t("enums.venue_settings.REPORT_TYPE.desc");
// t("enums.venue_settings.REPORT_POS_SETTLEMENT.desc");
// t("enums.venue_settings.REPORT_AUTO_START_AMOUNT.desc");
// t("enums.venue_settings.REPORT_REMOVES_BILLS_AFTER_OPEN.desc");
// t("enums.venue_settings.SALE_DEFAULT_BUTTON.desc");
// t("enums.venue_settings.SALE_BILL_TYPE_BUTTON.desc");
// t("enums.venue_settings.SALE_QUICK_BUTTON.desc");
// t("enums.venue_settings.SALE_CLIENT_BUTTON.desc");
// t("enums.venue_settings.SALE_COMMENT_BUTTON.desc");
// t("enums.venue_settings.SALE_GROUP_BUTTON.desc");
// t("enums.venue_settings.SALE_GUEST_NUMBER_POPUP.desc");
// t("enums.venue_settings.SALE_SEAT.desc");
// t("enums.venue_settings.SALE_GUEST_NUMBER_BUTTON.desc");
// t("enums.venue_settings.SALE_CLIENT_OPTION.desc");
// t("enums.venue_settings.SALE_CLIENT_CARD_OPTION.desc");
// t("enums.venue_settings.SALE_TABLE_OPTION.desc");
// t("enums.venue_settings.SALE_OPEN_CASH_DRAWER.desc");
// t("enums.venue_settings.SALE_DESCRIPTION_OPTION.desc");
// t("enums.venue_settings.SALE_SPLIT_OPTION.desc");
// t("enums.venue_settings.SALE_PRINT_INITIAL_OPTION.desc");
// t("enums.venue_settings.SALE_RESEND_TO_KITCHEN.desc");
// t("enums.venue_settings.SALE_DETERMINED_PRICE_OPTION.desc");
// t("enums.venue_settings.SALE_CLIENT_BARCODE_OPTION.desc");
// t("enums.venue_settings.SALE_MENU_GRID_OPTION.desc");
// t("enums.venue_settings.SALE_MENU_SEARCH_OPTION.desc");
// t("enums.venue_settings.SALE_MENU_SKU_OPTION.desc");
// t("enums.venue_settings.SALE_OPEN_BILLS.desc");
// t("enums.venue_settings.SALE_NOT_AVAILABLE_PRODUCTS.desc");
// t("enums.venue_settings.SALE_SAVE_ON_CLOSE.desc");
// t("enums.venue_settings.SALE_STORNO_REASON.desc");
// t("enums.venue_settings.SALE_TAX_ID_NO.desc");
// t("enums.venue_settings.SALE_AUTO_RELEASE.desc");
// t("enums.venue_settings.SCANNER_TYPE.desc");
// t("enums.venue_settings.TRANSFER_SAFE_MODE.desc");
// t("enums.venue_settings.SALE_LOGOUT_AFTER_CLOSE_BILL.desc");
// t("enums.venue_settings.USER_LOGOUT_THRESHOLD.desc");
// t("enums.venue_settings.RESERVATION.desc");
// t("enums.venue_settings.SALE_PRINT_PAYMENT_TERMINAL_CONFIRMATION.desc");
// t("enums.venue_settings.IS_LOCATION_REQUIRED.desc");
// t("enums.venue_settings.DRIVER_CAN_ASSIGN_ITSELF.desc");
// t("enums.venue_settings.DRIVER_CAN_CHANGE_STATUS_FROM_STATUS.desc");
// t("enums.venue_settings.DRIVER_CAN_CHANGE_STATUS_TO_DELIVERED_FROM_METERS.desc");
// t("enums.venue_settings.SALE_VALIDATE_DELIVERY_EMPLOYEE.desc");
// t("enums.venue_settings.SALE_VALIDATE_ORDER_TYPE.desc");
// t("enums.venue_settings.KDS_FLASH_SECONDS.desc");
// t("enums.venue_settings.CITY_SEARCH_DISTANCE_KM.desc");
// t("enums.venue_settings.SALE_FORCE_ORDER_FISCALIZATION.desc");
// t("enums.venue_settings.PRINT_COMPANY_ON_PDF.desc");
