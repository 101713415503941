import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import DateInfoBox from "../../../../../../../../../../../components/Common/DateInfoBox/DateInfoBox";
import FormatResourceStatus from "../../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { ReactComponent as TerminalSVG } from "../../../../../../../../../../../images/svg/bills/terminal.svg";
import { ReactComponent as DangerCircle } from "../../../../../../../../../../../images/svg/posReport/danger-circle.svg";
import { ReactComponent as FileSVG } from "../../../../../../../../../../../images/svg/posReport/file.svg";
import { ReactComponent as InfoCircleSVG } from "../../../../../../../../../../../images/svg/posReport/info-circle.svg";
import { PosReportApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { getDate, getInfoHeader, getSubDate } from "../../PosReportPreview";

interface Props {
	report: PosReportApi;
}

const ReportsShiftWorkCard: FC<Props> = ({ report }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);

	return (
		<Link to={`/${organization.id}/sales/pos_reports/${report.id}`} className="d-flex report-shiftworks">
			<DateInfoBox
				dateHeader={getDate(report)}
				dateSubHeader={getSubDate(report, t)}
				infoHeader={getInfoHeader(report)}
			/>
			<div className="shiftwork-container">
				<div className="w-100 d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div className="shiftwork-item-icon">
							<TerminalSVG />
						</div>
						<Link
							className="terminal-name"
							onClick={(e) => e.stopPropagation()}
							to={`/${organization.id}/settings/terminals/${report.terminal.id}`}
						>
							{report.terminal?.name}
						</Link>
					</div>
					<div>
						<FormatResourceStatus status={report.status} />
					</div>
				</div>
				<div className="d-flex flex-wrap justify-content-between shiftwork-item-body">
					<div className="shiftwork-item-body-left">
						<div className="d-flex align-items-center text-height">
							<div className="shiftwork-item-icon">
								<FileSVG className="file-icon" />
							</div>
							<span className="report-id">#{report.id}</span>
						</div>
						<div className="d-flex text-height">
							<div className="shiftwork-item-icon">
								{report.description && <InfoCircleSVG className="info-icon" />}
							</div>
							<span className="description" title={report.description}>
								{report.description}
							</span>
						</div>
					</div>

					<div className="shiftwork-item-body-right">
						<div className="d-flex justify-content-between shiftwork-item-body-right-content">
							<span className="label" style={{ minWidth: "150px" }}>
								{t("common.word.total_money")}
							</span>
							<span className="money" style={{ fontSize: "14px" }}>
								{FormatMoney(report.income_amount)}
							</span>
						</div>
						<div className="d-flex justify-content-between shiftwork-item-body-right-content">
							<span
								className={`label ${report.status === "OPENED" ? "text-danger" : ""}`}
								style={{ minWidth: "150px" }}
							>
								{report.status === "OPENED" && <DangerCircle className="danger-circle-icon" />}
								{t("modules.pos_report.field.real_value.title")}
							</span>
							<span className={`money ${report.status === "OPENED" ? "text-danger" : ""}`}>
								{FormatMoney(report.total_amount)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};
export default ReportsShiftWorkCard;
