import React, { FC, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import EmptyList from "go-core/components/EmptyList";
import { FormatDateToTime } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as TipSVG } from "../../../../../../../../../images/svg/bills/badge-percent.svg";
import { ReactComponent as DeliverySVG } from "../../../../../../../../../images/svg/bills/delivery.svg";
import { ReactComponent as DineInSVG } from "../../../../../../../../../images/svg/bills/dine_in.svg";
import { ReactComponent as InfoSVG } from "../../../../../../../../../images/svg/bills/info.svg";
import { ReactComponent as OrderSVG } from "../../../../../../../../../images/svg/bills/order.svg";
import { ReactComponent as InProgressSVG } from "../../../../../../../../../images/svg/bills/preparation_statuses/in_progress.svg";
import { ReactComponent as NewSVG } from "../../../../../../../../../images/svg/bills/preparation_statuses/new.svg";
import { ReactComponent as PackedSVG } from "../../../../../../../../../images/svg/bills/preparation_statuses/packed.svg";
import { ReactComponent as ReadySVG } from "../../../../../../../../../images/svg/bills/preparation_statuses/ready.svg";
import { ReactComponent as ReceivedSVG } from "../../../../../../../../../images/svg/bills/preparation_statuses/received.svg";
import { ReactComponent as DropdownSVG } from "../../../../../../../../../images/svg/dropdown.svg";
import { ReactComponent as VectorSVG } from "../../../../../../../../../images/svg/vector.svg";
import {
	OrderApi,
	OrderItemApi,
	OrderPreparationStatusName,
} from "../../../../../../../../../services/Api/Organization/types";

export interface Props {
	order: OrderApi;
}

const PositionsCard: FC<Props> = ({ order }) => {
	const { t } = useTranslation();
	const [showRemoved, setShowRemoved] = useState(order.items?.length === 0 && order.removed_items?.length > 0);
	const items = showRemoved ? order.removed_items : order.items;
	const organization = useSelector(selectOrganization);
	const [shownIndexes, setShownIndexes] = useState<number[]>([]);

	const isRowIndexShown = (index: number) => {
		return shownIndexes.includes(index);
	};

	const areAllRowsIndexesShown = () => {
		return items?.every((item, index) => {
			if (
				item.items?.length === 0 &&
				!item?.direction?.name &&
				!item?.course &&
				!item?.comment &&
				!item.cost?.external_cost &&
				!item.cost?.default_cost
			)
				return true;
			return shownIndexes.includes(index);
		});
	};

	const showMore = (index: number) => {
		if (isRowIndexShown(index)) {
			const indexes = shownIndexes;
			indexes.splice(shownIndexes.indexOf(index), 1);
			setShownIndexes([...indexes]);
		} else {
			setShownIndexes((indexes) => [...indexes, index]);
		}
	};

	const toggleAllRows = () => {
		if (areAllRowsIndexesShown()) {
			setShownIndexes([]);
		} else {
			setShownIndexes([...items.map((_, i) => i)]);
		}
	};

	const nestedCellStyles = {
		borderTop: "none",
		padding: "3px 5px",
		verticalAlign: "top",
		lineHeight: "1.3",
	};

	const firstChildCellStyles = {
		...nestedCellStyles,
		paddingLeft: 0,
	};

	const lastChildCellStyles = {
		...nestedCellStyles,
		paddingRight: 0,
	};

	useEffect(() => {
		setShownIndexes([]);
	}, [showRemoved]);

	const drawCost = (item: any) => {
		if (hasItems(item)) {
			if (item?.total_cost?.external_cost) {
				return FormatMoney(item.total_cost.external_cost);
			}
			if (item?.total_cost?.default_cost) {
				return `~${FormatMoney(item.total_cost.default_cost)}`;
			}
		}
		if (item?.cost?.external_cost) {
			return FormatMoney(item.cost.external_cost);
		} else if (item?.cost?.default_cost) {
			return `~${FormatMoney(item.cost.default_cost)}`;
		}
		return "";
	};

	const drawOrderStatus = (item: OrderItemApi, index: number) => {
		return (
			<OverlayTrigger
				placement={"top"}
				overlay={
					<Tooltip id={`tooltip-${index}`}>
						{t(`enums.preparation_statuses.${item.preparation_status.status}`)}
					</Tooltip>
				}
			>
				{({ ref, ...triggerHandler }) => (
					<>
						{item.preparation_status.status === OrderPreparationStatusName.NEW && (
							<NewSVG ref={ref} {...triggerHandler} />
						)}
						{item.preparation_status.status === OrderPreparationStatusName.READY && (
							<ReadySVG ref={ref} {...triggerHandler} />
						)}
						{item.preparation_status.status === OrderPreparationStatusName.RECEIVED && (
							<ReceivedSVG ref={ref} {...triggerHandler} />
						)}
						{item.preparation_status.status === OrderPreparationStatusName.IN_PROGRESS && (
							<InProgressSVG ref={ref} {...triggerHandler} />
						)}
						{item.preparation_status.status === OrderPreparationStatusName.PACKED && (
							<PackedSVG ref={ref} {...triggerHandler} />
						)}
					</>
				)}
			</OverlayTrigger>
		);
	};

	const getStylesForMoneyCell = (item: OrderItemApi, index: number) => {
		if (isRowIndexShown(index) && (item?.cost?.external_cost || item?.cost?.default_cost))
			return { marginTop: "2.5px" };
		else if (isRowIndexShown(index)) return { marginTop: "2px" };
		return { marginTop: "2px" };
	};

	const getStylesForEmployeeCell = (item: OrderItemApi, index: number) => {
		if (isRowIndexShown(index) && (item?.employee?.name || order?.opening_employee?.name || order?.employee?.name))
			return { marginTop: "2px" };
		else if (isRowIndexShown(index)) return { marginTop: "1.5px" };
		else if (
			!isRowIndexShown(index) &&
			!item?.employee?.name &&
			!order?.opening_employee?.name &&
			!order?.employee?.name
		)
			return { marginTop: "-1px" };
		return { marginTop: "1px" };
	};

	const getItemItems = (item: OrderItemApi): OrderItemApi[] => {
		if ((item?.items || []).length > 0) {
			const { items, ...restOfItem } = item;
			return [restOfItem as OrderItemApi, ...items];
		}
		return item?.items || [];
	};

	const hasItems = (item: OrderItemApi): boolean => {
		return (item?.items || []).length > 0;
	};

	const removedDiscountValue =
		(order.removed_sub_total_price?.amount || order.removed_sub_total_price?.amount === 0) &&
		(order.removed_total_price?.amount || order.removed_total_price?.amount === 0)
			? {
					amount: order.removed_sub_total_price.amount - order.removed_total_price.amount,
					currency: order.removed_sub_total_price.currency,
			  }
			: undefined;

	const getRemovedItemsHeader = (): string => {
		if (order.status === "VOIDED" || order.status === "REMOVED")
			return t("modules.order.field.removed_items_values_on_disable.title");
		return t("modules.order.field.removed_items_values.title");
	};

	return (
		<Card>
			<Card.Body className={order.removed_items?.length > 0 ? "pt-3" : undefined}>
				{order.removed_items?.length > 0 ? (
					<div className={"d-flex align-items-center mb-4"}>
						<h5 className={"mb-0"}>{t("modules.order.field.positions.title")}</h5>
						<ButtonGroup className="ms-auto">
							<Button
								onClick={() => setShowRemoved(false)}
								variant={!showRemoved ? "primary" : "outline-primary"}
							>
								{t("common.word.active_female")} ({order.items.length})
							</Button>
							<Button
								onClick={() => setShowRemoved(true)}
								variant={showRemoved ? "primary" : "outline-primary"}
							>
								{t("common.word.removed")} ({order.removed_items?.length})
							</Button>
						</ButtonGroup>
					</div>
				) : (
					<h5>{t("modules.order.field.positions.title")}</h5>
				)}
				{items?.length > 0 ? (
					<div className={"table-responsive"}>
						<table className="table table-bills table-preview">
							<thead>
								<tr>
									<th>
										{t("modules.order.field.position_name.title")}
										<a
											href="javascript:"
											onClick={toggleAllRows}
											className="ms-1 font-weight-normal"
										>
											(
											{areAllRowsIndexesShown()
												? t("modules.order.action.collapse_all.title")
												: t("modules.order.action.expand_all.title")}
											)
										</a>
									</th>
									<th>
										<div>{t("modules.order.field.time.title")}/</div>
										<div>{t("common.word.employee")}</div>
									</th>
									<th>{t("common.word.quantity")}</th>
									<th className={"w-1"} />
									<th>
										<div>{t("common.word.value")}/</div>
										<div>{t("modules.order.field.net_price.title")}</div>
									</th>
								</tr>
							</thead>
							<tbody className="positions">
								{items?.map((item, index) => {
									return (
										<React.Fragment key={item.uid}>
											<tr
												onClick={() => showMore(index)}
												style={{
													cursor: "pointer",
												}}
											>
												<td
													className="w-50"
													style={{ verticalAlign: isRowIndexShown(index) ? "top" : "middle" }}
												>
													<div className="d-flex name-box">
														{item.items?.length > 0 ||
														item?.direction?.name ||
														item?.course ||
														item?.comment ||
														item.cost?.external_cost ||
														item.cost?.default_cost ? (
															<DropdownSVG
																id={`icon-${index}`}
																style={{
																	minWidth: "10px",
																	minHeight: "6px",
																	transform: isRowIndexShown(index)
																		? "rotate(180deg)"
																		: "unset",
																	marginTop: isRowIndexShown(index)
																		? "16px"
																		: "unset",
																}}
																className={`me-2 ${
																	isRowIndexShown(index) ? "" : "my-auto"
																}`}
															/>
														) : (
															<div
																style={{
																	minWidth: "10px",
																	minHeight: "6px",
																}}
																className={`me-2 ${isRowIndexShown(index)} mt-1`}
															/>
														)}
														{item.product && (
															<>
																<div
																	style={{
																		marginTop: isRowIndexShown(index)
																			? "4px"
																			: "auto",
																	}}
																	className={isRowIndexShown(index) ? "" : "my-auto"}
																>
																	<RenderImage
																		data={item.product}
																		imageLink={item.product.image_link}
																	/>
																</div>
																<div
																	style={{
																		marginTop: isRowIndexShown(index) ? "2px" : "",
																	}}
																	className={`d-flex flex-column ${
																		isRowIndexShown(index) ? "" : "my-auto"
																	}`}
																>
																	{item.product.item_group_id ? (
																		<Link
																			onClick={(e) => e.stopPropagation()}
																			to={`/${organization.id}/menu/item_groups/${item.product.item_group_id}`}
																			target={"_blank"}
																			rel="noreferrer"
																		>
																			{item.product.name}
																		</Link>
																	) : (
																		<>{item.product.name}</>
																	)}
																	<div
																		style={{
																			marginTop: isRowIndexShown(index)
																				? "-4px"
																				: "unset",
																		}}
																	>
																		{isRowIndexShown(index) && (
																			<>
																				{item?.direction?.name && (
																					<small className="text-muted">
																						<OverlayTrigger
																							placement={"top"}
																							overlay={
																								<Tooltip
																									id={`tooltip-direction-${index}`}
																								>
																									{t(
																										"common.word.direction"
																									)}
																								</Tooltip>
																							}
																						>
																							<DineInSVG
																								style={{
																									marginBottom:
																										"1.5px",
																								}}
																							/>
																						</OverlayTrigger>
																						<span
																							style={{
																								paddingLeft: "0.2rem",
																							}}
																						>
																							{item.direction.name}
																						</span>
																					</small>
																				)}
																				{Boolean(item?.course) && (
																					<small
																						className={`${
																							item?.direction?.name &&
																							"ps-2"
																						} text-muted`}
																					>
																						<OverlayTrigger
																							placement={"top"}
																							overlay={
																								<Tooltip
																									id={`tooltip-course-${index}`}
																								>
																									{t(
																										"modules.order.field.order.title"
																									)}
																								</Tooltip>
																							}
																						>
																							<OrderSVG
																								style={{
																									marginBottom:
																										"1.5px",
																								}}
																							/>
																						</OverlayTrigger>
																						<span
																							style={{
																								paddingLeft: "0.2rem",
																							}}
																						>
																							{item.course}
																						</span>
																					</small>
																				)}
																				{item?.comment && (
																					<small
																						className={`${
																							item?.course && "ps-2"
																						} text-muted`}
																					>
																						<OverlayTrigger
																							placement={"top"}
																							overlay={
																								<Tooltip
																									id={`tooltip-comment-${index}`}
																								>
																									{t(
																										"modules.order.field.order.title"
																									)}
																								</Tooltip>
																							}
																						>
																							<InfoSVG
																								style={{
																									marginBottom:
																										"1.5px",
																								}}
																							/>
																						</OverlayTrigger>
																						<span
																							style={{
																								paddingLeft: "0.2rem",
																							}}
																						>
																							{item.comment}
																						</span>
																					</small>
																				)}
																			</>
																		)}
																	</div>
																</div>
															</>
														)}
													</div>
												</td>
												<td
													style={{ verticalAlign: isRowIndexShown(index) ? "top" : "middle" }}
												>
													<div className="d-flex flex-column">
														<span style={getStylesForEmployeeCell(item, index)}>
															{FormatDateToTime(item.created_at)}
														</span>
														<small
															className="text-muted"
															style={{
																maxWidth: "110px",
																textOverflow: "ellipsis",
																whiteSpace: "nowrap",
																overflow: "hidden",
																marginTop: isRowIndexShown(index) ? "-3.5px" : "-2px",
															}}
														>
															{item?.employee?.name ||
																order?.employee?.name ||
																order?.opening_employee?.name}
														</small>
													</div>
												</td>
												<td
													style={{ verticalAlign: isRowIndexShown(index) ? "top" : "middle" }}
												>
													<div
														style={{
															marginTop: isRowIndexShown(index) ? "1.5px" : "0.5px",
														}}
													>
														x{item.quantity}
													</div>
												</td>
												<td
													style={{ verticalAlign: isRowIndexShown(index) ? "top" : "middle" }}
												>
													{item?.preparation_status && (
														<div
															style={{
																marginTop: isRowIndexShown(index) ? "1px" : "-2px",
															}}
														>
															{drawOrderStatus(item, index)}
														</div>
													)}
												</td>
												<td
													style={{ verticalAlign: isRowIndexShown(index) ? "top" : "middle" }}
												>
													<div className="justify-content-end d-flex flex-column">
														<span style={getStylesForMoneyCell(item, index)}>
															{FormatMoney(item.total_price)}
														</span>
														{isRowIndexShown(index) && (
															<small
																style={{
																	marginTop: isRowIndexShown(index) ? "-5px" : "",
																}}
																className="text-muted"
															>
																{drawCost(item)}
															</small>
														)}
													</div>
												</td>
											</tr>
											{item?.items?.length > 0 && (
												<tr
													className="sub-row"
													key="sub-row"
													style={{ display: isRowIndexShown(index) ? "contents" : "none" }}
												>
													{item.items?.length > 0 && (
														<>
															{getItemItems(item).map((subItem, index) => {
																return (
																	<>
																		<tr key="sub-row-2">
																			<td
																				style={{
																					...firstChildCellStyles,
																					paddingLeft: "58px",
																				}}
																				className="text-start w-50"
																			>
																				<div>
																					<p
																						className="mb-0"
																						style={{
																							fontSize: "14px",
																							fontWeight: "bold",
																						}}
																					>
																						{subItem.product.name}
																					</p>
																				</div>
																				{!hasItems(subItem) &&
																					subItem.unit_price && (
																						<div>
																							<small className="text-muted">
																								{subItem.quantity} x{" "}
																								{FormatMoney(
																									subItem.unit_price
																								)}
																							</small>
																						</div>
																					)}
																				<div>
																					{subItem?.direction?.name && (
																						<small className="text-muted">
																							<OverlayTrigger
																								placement={"top"}
																								overlay={
																									<Tooltip
																										id={`sub-row-2-direction-tooltip-${index}`}
																									>
																										{t(
																											"common.word.direction"
																										)}
																									</Tooltip>
																								}
																							>
																								<DineInSVG
																									style={{
																										marginBottom:
																											"1.5px",
																									}}
																								/>
																							</OverlayTrigger>
																							<span
																								style={{
																									paddingLeft:
																										"0.2rem",
																								}}
																							>
																								{subItem.direction.name}
																							</span>
																						</small>
																					)}
																					{subItem?.course && (
																						<small className="ps-2 text-muted">
																							<OverlayTrigger
																								placement={"top"}
																								overlay={
																									<Tooltip
																										id={`sub-row-2-course-tooltip-${index}`}
																									>
																										{t(
																											"modules.order.field.order.title"
																										)}
																									</Tooltip>
																								}
																							>
																								<OrderSVG
																									style={{
																										marginBottom:
																											"1.5px",
																									}}
																								/>
																							</OverlayTrigger>
																							<span
																								style={{
																									paddingLeft:
																										"0.2rem",
																								}}
																							>
																								{subItem.course}
																							</span>
																						</small>
																					)}
																					{subItem?.comment && (
																						<small className="ps-2 text-muted">
																							<OverlayTrigger
																								placement={"top"}
																								overlay={
																									<Tooltip
																										id={`sub-row-2-comment-tooltip-${index}`}
																									>
																										{t(
																											"modules.order.field.order.title"
																										)}
																									</Tooltip>
																								}
																							>
																								<InfoSVG
																									style={{
																										marginBottom:
																											"1.5px",
																									}}
																								/>
																							</OverlayTrigger>
																							<span
																								style={{
																									paddingLeft:
																										"0.2rem",
																								}}
																							>
																								{subItem.comment}
																							</span>
																						</small>
																					)}
																				</div>
																			</td>
																			<td style={nestedCellStyles} />
																			<td style={nestedCellStyles} />
																			<td style={nestedCellStyles}>
																				{subItem.preparation_status &&
																					drawOrderStatus(subItem, index)}
																			</td>
																			<td style={lastChildCellStyles}>
																				<div>
																					<span>
																						{FormatMoney(
																							index === 0
																								? subItem.unit_price
																								: subItem.total_price
																						)}
																					</span>
																				</div>
																				<div>
																					<small className="text-muted">
																						{drawCost(subItem)}
																					</small>
																				</div>
																			</td>
																		</tr>

																		{getItemItems(subItem).map(
																			(subItemItem, index) => {
																				return (
																					<>
																						<tr key="sub-row-3">
																							<td
																								style={{
																									...firstChildCellStyles,
																									paddingLeft: "58px",
																								}}
																							>
																								<div className="d-flex">
																									<VectorSVG className="mt-1 me-1 nesting-icon" />
																									<p
																										className="mb-0"
																										style={{
																											fontSize:
																												"14px",
																										}}
																									>
																										{
																											subItemItem
																												.product
																												.name
																										}
																									</p>
																								</div>
																								{!hasItems(
																									subItemItem
																								) &&
																									subItemItem.unit_price && (
																										<div>
																											<small
																												className="text-muted"
																												style={{
																													marginLeft:
																														"12px",
																												}}
																											>
																												{
																													subItemItem.quantity
																												}{" "}
																												x{" "}
																												{FormatMoney(
																													subItemItem.unit_price
																												)}
																											</small>
																										</div>
																									)}

																								<div
																									style={{
																										marginLeft:
																											"12px",
																									}}
																								>
																									{subItemItem
																										?.direction
																										?.name && (
																										<small className="text-muted">
																											<OverlayTrigger
																												placement={
																													"top"
																												}
																												overlay={
																													<Tooltip
																														id={`sub-row-3-direction-tooltip-${index}`}
																													>
																														{t(
																															"common.word.direction"
																														)}
																													</Tooltip>
																												}
																											>
																												<DineInSVG
																													style={{
																														marginBottom:
																															"1.5px",
																													}}
																												/>
																											</OverlayTrigger>
																											<span
																												style={{
																													paddingLeft:
																														"0.2rem",
																												}}
																											>
																												{
																													subItemItem
																														.direction
																														.name
																												}
																											</span>
																										</small>
																									)}
																									{subItemItem?.course && (
																										<small className="ps-2 text-muted">
																											<OverlayTrigger
																												placement={
																													"top"
																												}
																												overlay={
																													<Tooltip
																														id={`sub-row-3-course-tooltip-${index}`}
																													>
																														{t(
																															"modules.order.field.order.title"
																														)}
																													</Tooltip>
																												}
																											>
																												<OrderSVG
																													style={{
																														marginBottom:
																															"1.5px",
																													}}
																												/>
																											</OverlayTrigger>
																											<span
																												style={{
																													paddingLeft:
																														"0.2rem",
																												}}
																											>
																												{
																													subItemItem.course
																												}
																											</span>
																										</small>
																									)}
																									{subItemItem?.comment && (
																										<small className="ps-2 text-muted">
																											<OverlayTrigger
																												placement={
																													"top"
																												}
																												overlay={
																													<Tooltip
																														id={`sub-row-3-comment-tooltip-${index}`}
																													>
																														{t(
																															"modules.order.field.order.title"
																														)}
																													</Tooltip>
																												}
																											>
																												<InfoSVG
																													style={{
																														marginBottom:
																															"1.5px",
																													}}
																												/>
																											</OverlayTrigger>
																											<span
																												style={{
																													paddingLeft:
																														"0.2rem",
																												}}
																											>
																												{
																													subItemItem.comment
																												}
																											</span>
																										</small>
																									)}
																								</div>
																							</td>
																							<td
																								style={nestedCellStyles}
																							/>
																							<td
																								style={nestedCellStyles}
																							/>
																							<td
																								style={nestedCellStyles}
																							/>
																							<td
																								style={
																									lastChildCellStyles
																								}
																							>
																								<div>
																									<span>
																										{FormatMoney(
																											index === 0
																												? subItemItem.unit_price
																												: subItemItem.total_price
																										)}
																									</span>
																								</div>
																								<div>
																									<small className="text-muted">
																										{drawCost(
																											subItemItem
																										)}
																									</small>
																								</div>
																							</td>
																						</tr>

																						{getItemItems(subItemItem).map(
																							(subItemItem, index) => {
																								return (
																									<tr key="sub-row-4">
																										<td
																											style={{
																												...firstChildCellStyles,
																												paddingLeft:
																													"70px",
																											}}
																										>
																											<div className="d-flex">
																												<VectorSVG className="mt-1 me-1 nesting-icon" />
																												<p
																													className="mb-0"
																													style={{
																														fontSize:
																															"14px",
																													}}
																												>
																													{
																														subItemItem
																															.product
																															.name
																													}
																												</p>
																											</div>
																											{!hasItems(
																												subItemItem
																											) &&
																												subItemItem.unit_price && (
																													<div>
																														<small
																															className="text-muted"
																															style={{
																																marginLeft:
																																	"12px",
																															}}
																														>
																															{
																																subItemItem.quantity
																															}{" "}
																															x{" "}
																															{FormatMoney(
																																subItemItem.unit_price
																															)}
																														</small>
																													</div>
																												)}

																											<div
																												style={{
																													marginLeft:
																														"12px",
																												}}
																											>
																												{subItemItem
																													?.direction
																													?.name && (
																													<small className="text-muted">
																														<OverlayTrigger
																															placement={
																																"top"
																															}
																															overlay={
																																<Tooltip
																																	id={`sub-row-4-direction-tooltip-${index}`}
																																>
																																	{t(
																																		"common.word.direction"
																																	)}
																																</Tooltip>
																															}
																														>
																															<DineInSVG
																																style={{
																																	marginBottom:
																																		"1.5px",
																																}}
																															/>
																														</OverlayTrigger>
																														<span
																															style={{
																																paddingLeft:
																																	"0.2rem",
																															}}
																														>
																															{
																																subItemItem
																																	.direction
																																	.name
																															}
																														</span>
																													</small>
																												)}
																												{subItemItem?.course && (
																													<small className="ps-2 text-muted">
																														<OverlayTrigger
																															placement={
																																"top"
																															}
																															overlay={
																																<Tooltip
																																	id={`sub-row-4-course-tooltip-${index}`}
																																>
																																	{t(
																																		"modules.order.field.order.title"
																																	)}
																																</Tooltip>
																															}
																														>
																															<OrderSVG
																																style={{
																																	marginBottom:
																																		"1.5px",
																																}}
																															/>
																														</OverlayTrigger>
																														<span
																															style={{
																																paddingLeft:
																																	"0.2rem",
																															}}
																														>
																															{
																																subItemItem.course
																															}
																														</span>
																													</small>
																												)}
																												{subItemItem?.comment && (
																													<small className="ps-2 text-muted">
																														<OverlayTrigger
																															placement={
																																"top"
																															}
																															overlay={
																																<Tooltip
																																	id={`sub-row-4-comment-tooltip-${index}`}
																																>
																																	{t(
																																		"modules.order.field.order.title"
																																	)}
																																</Tooltip>
																															}
																														>
																															<InfoSVG
																																style={{
																																	marginBottom:
																																		"1.5px",
																																}}
																															/>
																														</OverlayTrigger>
																														<span
																															style={{
																																paddingLeft:
																																	"0.2rem",
																															}}
																														>
																															{
																																subItemItem.comment
																															}
																														</span>
																													</small>
																												)}
																											</div>
																										</td>
																										<td
																											style={
																												nestedCellStyles
																											}
																										/>
																										<td
																											style={
																												nestedCellStyles
																											}
																										/>
																										<td
																											style={
																												nestedCellStyles
																											}
																										/>
																										<td
																											style={
																												lastChildCellStyles
																											}
																										>
																											<div>
																												<span>
																													{FormatMoney(
																														index ===
																															0
																															? subItemItem.unit_price
																															: subItemItem.total_price
																													)}
																												</span>
																											</div>
																											<div>
																												<small className="text-muted">
																													{drawCost(
																														subItemItem
																													)}
																												</small>
																											</div>
																										</td>
																									</tr>
																								);
																							}
																						)}
																					</>
																				);
																			}
																		)}
																	</>
																);
															})}
														</>
													)}
												</tr>
											)}
										</React.Fragment>
									);
								})}
								{order.type === "DELIVERY" && order.delivery_price && (
									<tr>
										<td style={{ padding: "12px 0px" }}>
											<div className="d-flex align-items-center" style={{ paddingLeft: "16px" }}>
												<div className="order-icon-box">
													<DeliverySVG />
												</div>
												<span>{t("enums.orders.types.DELIVERY")}</span>
											</div>
										</td>
										<td colSpan={4} style={{ padding: "12px 0px" }}>
											{FormatMoney(order.delivery_price)}
										</td>
									</tr>
								)}
								{order.tip && (
									<tr>
										<td style={{ padding: "12px 0px" }}>
											<div className="d-flex align-items-center" style={{ paddingLeft: "16px" }}>
												<div className="order-icon-box">
													<TipSVG />
												</div>
												<span>{t("modules.order.field.tip.title")}</span>
											</div>
										</td>
										<td colSpan={4} style={{ padding: "12px 0px" }}>
											{FormatMoney(order.tip)}
										</td>
									</tr>
								)}
								{(order.cost?.default_cost || order.cost?.external_cost) && (
									<tr className={"summary"}>
										<td />
										<td colSpan={2} style={{ paddingBottom: "0px", paddingRight: "0px" }}>
											<span
												className="text-muted"
												style={{
													borderBottom: "1px #dee2e6 solid",
													marginBottom: "4px",
													paddingBottom: "10px",
												}}
											>
												{t("modules.order.field.net_price.title")}
											</span>
										</td>
										<td style={{ paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px" }}>
											<span
												className="text-muted"
												style={{
													borderBottom: "1px #dee2e6 solid",
													marginBottom: "4px",
													paddingBottom: "10px",
												}}
											>
												&nbsp;
											</span>
										</td>
										<td style={{ paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px" }}>
											<span
												className="text-muted"
												style={{
													borderBottom: "1px #dee2e6 solid",
													marginBottom: "4px",
													paddingBottom: "10px",
												}}
											>
												{drawCost(order)}
											</span>
										</td>
									</tr>
								)}
								{!showRemoved && (
									<tr className="summary">
										<td style={{ border: `${order.cost?.default_cost && "none"}` }} />
										<td colSpan={2} style={{ border: `${order.cost?.default_cost && "none"}` }}>
											{order.promotions?.length > 0 && (
												<>
													<span className="text-muted">
														{t("common.word.discount_value")}
													</span>
													<span className="text-muted">
														{t("modules.order.field.price_without_discount.title")}
													</span>
												</>
											)}
											<span style={{ color: "#212529" }} className="mb-2">
												{t("common.word.amount_net")}
											</span>
											<span style={{ color: "#212529" }} className="mb-2">
												{t("modules.order.field.total_vat.title")}
											</span>
											{order.tax_items
												.sort((a, b) => a.tax_amount - b.tax_amount)
												.map((tax, i) => (
													<span
														key={i}
														className="text-muted font-weight-bold mb-2"
														style={{ fontSize: 12 }}
													>
														{tax.tax_name}
													</span>
												))}
											<span>
												<strong>{t("common.word.amount_gross")}</strong>
											</span>
										</td>
										<td style={{ border: `${order.cost?.default_cost && "none"}` }} />
										<td
											style={{
												whiteSpace: "nowrap",
												border: `${order.cost?.default_cost && "none"}`,
											}}
										>
											{order.promotions?.length > 0 && (
												<>
													<span className="text-muted">
														{FormatMoney({
															amount: order.promotions.reduce(
																(acc, val) =>
																	acc + (val.used?.amount ? val.used.amount : 0),
																0
															),
															currency: order.sub_total_price.currency,
														})}
													</span>
													<span className="text-muted">
														{FormatMoney(order.sub_total_price)}
													</span>
												</>
											)}
											<span style={{ color: "#212529" }}>
												{FormatMoney(order.total_price_net)}
											</span>
											<span style={{ color: "#212529" }}>
												{FormatMoney({
													amount: order.total_price_tax?.amount ?? 0,
													currency: order.total_price.currency,
												})}
											</span>
											{order.tax_items
												.sort((a, b) => a.tax_amount - b.tax_amount)
												.map((tax, i) => (
													<span
														key={i}
														className="text-muted font-weight-bold mb-2"
														style={{ fontSize: 12 }}
													>
														{FormatMoney(tax.total_price_tax)}
													</span>
												))}
											<span>
												<strong>{FormatMoney(order.total_price)}</strong>
											</span>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						{showRemoved && (
							<div className="removed-items">
								<div>
									<h6>{getRemovedItemsHeader()}</h6>
									{removedDiscountValue && (
										<div className="removed-item-values">
											<span>{t("common.word.discount_value")}</span>
											<span>{FormatMoney(removedDiscountValue)}</span>
										</div>
									)}
									{order.removed_sub_total_price && (
										<div className="removed-item-values">
											<span>{t("modules.order.field.price_without_discount.title")}</span>
											<span>{FormatMoney(order.removed_sub_total_price)}</span>
										</div>
									)}
									{order.removed_total_price_net && (
										<div className="removed-item-values text-black">
											<span>{t("common.word.amount_net")}</span>
											<span>{FormatMoney(order.removed_total_price_net)}</span>
										</div>
									)}
									{order.removed_total_price_tax && (
										<div className="removed-item-values text-black">
											<span>{t("modules.order.field.total_vat.title")}</span>
											<span>{FormatMoney(order.removed_total_price_tax)}</span>
										</div>
									)}
									{Array.isArray(order.removed_tax_items) &&
										order.removed_tax_items.length > 0 &&
										order.removed_tax_items.map((removedTaxItem) => {
											const { tax_id, tax_name, total_price_tax } = removedTaxItem;
											return (
												<div key={tax_id} className="removed-item-values">
													<span className="tax-value">{tax_name}</span>
													<span className="tax-value">{FormatMoney(total_price_tax)}</span>
												</div>
											);
										})}
									{order.removed_total_price && (
										<strong className="removed-item-values text-black">
											<span>{t("common.word.amount_gross")}</span>
											<span>{FormatMoney(order.removed_total_price)}</span>
										</strong>
									)}
								</div>
							</div>
						)}
					</div>
				) : (
					<EmptyList />
				)}
			</Card.Body>
		</Card>
	);
};
export default PositionsCard;
