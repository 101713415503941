import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonLoading, FormInput } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { SearchSelectApi } from "../../../../../../../../../services/Api/types";
import { parseApplicationSettings, parseApplicationSettingsValues } from "../../../utils/utils";
import { AppState } from "../AppModal";

interface Props {
	isShown: boolean;
	onHide: () => void;
	appState: AppState;
}

const HotelgramInstallModal: FC<Props> = ({ isShown, onHide, appState }) => {
	const { t } = useTranslation();
	const form = useForm<ApplicationApi>({
		criteriaMode: "all",
		defaultValues: {
			...appState.app,
			settings: {
				...appState.app.settings,
				...parseApplicationSettingsValues(appState.app.settings || {}),
			},
		},
	});
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
		formState,
		setError,
		control,
		clearErrors,
		watch,
	} = form;
	const [loading, setLoading] = useState<boolean>(false);
	const { addSuccessFlash, addFlash } = useFlash();
	const history = useHistory();
	const organization = useSelector(selectOrganization);

	const [updatedConfig, setUpdatedConfig] = useState<Record<string, any> | undefined>(appState.config);

	const searchPaymentMethods = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPaymentMethodsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const onSubmit = handleSubmit(async (data: ApplicationApi) => {
		setLoading(true);
		const params: Record<string, any> = { include: "settings" };

		const settings = parseApplicationSettings(data.settings);
		const newData = {
			name: data.name,
			id: appState.app.id,
			settings,
		};

		try {
			if (appState.app.id) {
				await api.organization().updateApp(newData, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				onHide();
			} else {
				const res = await api.organization().installApps("HOTELGRAM", newData, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				history.push(`/${organization.id}/apps/active/${res.id}`);
			}
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const updateConfig = (field: string, obj?: SearchSelectApi) => {
		const cnf = { ...updatedConfig };
		if (field === "payment_method_name") {
			cnf.payment_method_name = obj?.label;
		}
		setUpdatedConfig(cnf);
	};

	return (
		<Modal onHide={onHide} show={isShown}>
			<FormDirty formState={formState} key="install-hotelgram-form" noValidate onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t("modules.app.field.install.title")} {t("enums.apps.providers.HOTELGRAM")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						name="settings.payment_method_id"
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						path={`/${organization.id}/settings/payment_methods/`}
						control={control}
						loadOptions={searchPaymentMethods}
						label={t("modules.app.field.default_payment_method.title")}
						defaultValue={{
							label: updatedConfig?.payment_method_name,
							id: appState.app?.settings?.payment_method_id,
						}}
						onChange={(_, fullObj) => updateConfig("payment_method_name", fullObj)}
						errors={errors}
						data-testid="settings.payment_method_id"
					/>
					<FormInput
						name="settings.login"
						errors={errors}
						register={register}
						label={t("modules.app.field.login.title")}
					/>
					<FormInput
						name="settings.password"
						errors={errors}
						register={register}
						label={t("modules.app.field.password.title")}
					/>
					<FormInput
						name="settings.hot_id"
						errors={errors}
						register={register}
						label={t("modules.app.field.hot_id.title")}
					/>
					<FormInput
						name="settings.url"
						errors={errors}
						register={register}
						label={t("modules.app.field.url.title")}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
						{t("common.action.install", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onHide}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default HotelgramInstallModal;
