import { useContext } from "react";
import { BrowserTabContext } from "./BrowserTab";

export const useBrowserTabTitle = () => {
	const browserTabContext = useContext(BrowserTabContext);

	const handleChangeTabTitle = (title?: string) => {
		browserTabContext.setTitle(title || "");
	};

	return {
		handleChangeTabTitle,
	};
};
