import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { FilterType } from "go-list/core/components/Filter/services/types";
import { ListConfigField } from "go-list/core/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { CurrencyApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import CurrencyModalForm from "../../components/CurrencyModalForm";

interface ListState {
	resource?: Record<string, any>;
	updateCurrency: (item: CurrencyApi, items: CurrencyApi[]) => void;
	responseData?: CurrencyApi[] | undefined;
	createCurrency: () => void;
	mobileActions: MobileActionProps[];
}

const listName = "CURRENCY";
const resourceType = "CURRENCY";

const List: FC<ListState> = ({ resource, responseData, createCurrency, mobileActions }) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<CurrencyApi[]>([]);
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const [params, setParams] = useState<Record<string, any>>({});
	const { addSuccessFlash, addFlash } = useFlash();
	const segmentContext = useContext(SegmentContext);

	useEffect(() => {
		if (responseData) {
			setItems(responseData);
		}
	}, [responseData]);
	if (!resource) return null;
	const data = resource.read();

	const onRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	const onRemoveCurrency = async (item: CurrencyApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.action", { ns: "lib" }),
			});
			await api.organization().removeCurrency(item.id);
			await onRefresh();
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "currency_from",
				name: t("modules.currency.field.currency_from.title"),
				type: "list" as FilterType,
				options: {
					PLN: "PLN",
					GBP: "GBP",
					USD: "USD",
					EUR: "EUR",
					CZK: "CZK",
				},
			},
			{
				id: "currency_to",
				name: t("modules.currency.field.currency_to.title"),
				type: "list" as FilterType,
				options: {
					PLN: "PLN",
				},
			},
			{
				id: "rate",
				name: t("modules.currency.field.rate.title"),
				type: "number" as FilterType,
			},
			{
				id: "start_at",
				name: t("common.word.start_date", { ns: "lib" }),
				type: "date" as FilterType,
				render: (data: CurrencyApi) => {
					return FormatDate(data.start_at);
				},
				renderExport: (data: CurrencyApi) => {
					return FormatDate(data.start_at);
				},
			},
			{
				id: "end_at",
				name: t("common.word.end_date", { ns: "lib" }),
				type: "date" as FilterType,
				render: (data: CurrencyApi) => {
					return FormatDate(data.end_at);
				},
				renderExport: (data: CurrencyApi) => {
					return FormatDate(data.end_at);
				},
			} as ListConfigField,
		],
		actions: [
			{
				name: t("common.action.finish"),
				click: (item: CurrencyApi) => {
					onRemoveCurrency(item);
				},
				visible: (item: CurrencyApi) => {
					return !item.end_at;
				},
			},
		],
		selectedColumns: ["currency_from", "currency_to", "rate", "start_at", "end_at"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
		],
		exportConfig: {
			title: t("modules.currency.field.export_config.title"),
			filename: t("modules.currency.field.export_config_filename.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			setParams(params);
			return api.organization().getCurrencies(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{
				addAction: () => createCurrency(),
			}}
			mobileActions={mobileActions}
			onFetch={(fetchItems: CurrencyApi[]) => setItems(fetchItems)}
		/>
	);
};

const OrganizationSettingsCurrenciesIndexPage = (): JSX.Element => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const [currency, setCurrency] = useState<CurrencyApi | undefined>(undefined);
	const [responseData, setResponseData] = useState<CurrencyApi[] | undefined>(undefined);
	const segmentContext = useContext(SegmentContext);
	const { addFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.currency.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const onUpdateCurrency = (currency: CurrencyApi, items: CurrencyApi[]) => {
		setCurrency(currency);
		setResponseData(items);
	};

	const handleSave = async () => {
		setCurrency(undefined);
		try {
			const res = await api.organization().getCurrencies();
			setResponseData(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [
		{
			variant: "primary",
			action: () => setCurrency({} as CurrencyApi),
			title: t("common.action.add", { ns: "lib" }),
		},
	];

	const mobileActions: MobileActionProps[] = [
		{
			action: () => setCurrency({} as CurrencyApi),
			title: t("common.action.add", { ns: "lib" }),
		},
	];

	return (
		<div className="content">
			{!isMobile && <Header title={t("modules.currency.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List
					resource={resource}
					responseData={responseData}
					createCurrency={() => setCurrency({} as CurrencyApi)}
					updateCurrency={onUpdateCurrency}
					mobileActions={mobileActions}
				/>
			</Suspense>
			{currency && (
				<CurrencyModalForm currency={currency} onHide={() => setCurrency(undefined)} handleSave={handleSave} />
			)}
		</div>
	);
};
export default OrganizationSettingsCurrenciesIndexPage;
