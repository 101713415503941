import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { ReactComponent as AlertSVG } from "../../../../../../../../../../images/svg/alert.svg";
import { ReactComponent as TagSVG } from "../../../../../../../../../../images/svg/bills/id.svg";
import { ReactComponent as CalendarSVG } from "../../../../../../../../../../images/svg/calendar.svg";
import { OrderApi, OrderFiscalizationType } from "../../../../../../../../../../services/Api/Organization/types";
import FiscalizationERecepit from "./FiscalizationERecepit";
import FiscalizationOrderReceiptsModal from "./FiscalizationOrderReceiptsModal/FiscalizationOrderReceiptsModal";
import FiscalizationStatus from "./FiscalizationStatus";

interface Props {
	order: OrderApi;
}

const FiscalizationCard = ({ order }: Props): JSX.Element => {
	const fiscalization = order.fiscalization;
	const { t } = useTranslation();
	const [showOrderReceiptsModal, setShowOrderReceiptsModal] = useState(false);

	return (
		<>
			<Card>
				<Card.Body>
					<div className="d-flex flex-wrap mb-3 justify-content-between align-items-center">
						<h5 className="mb-0">{t("modules.order.field.fiscalization.title")}</h5>
						<FiscalizationStatus
							status={fiscalization.status}
							isEReceipt={fiscalization.type === OrderFiscalizationType.ERECEIPT}
						/>
					</div>
					{fiscalization.duplicates_count > 0 && (
						<div className="card-body-item">
							<AlertSVG />
							<div className="item-info">
								<span className="label text-danger">
									{`${t("modules.order.field.duplicates.title")}: ${fiscalization.duplicates_count}`}
								</span>
								<small>
									<a href="#" onClick={() => setShowOrderReceiptsModal(true)}>
										{t("common.word.details")}
									</a>
								</small>
							</div>
						</div>
					)}
					{fiscalization?.fiscalized_at && (
						<div className="card-body-item">
							<CalendarSVG />
							<div className="item-info">
								<span className="label">{t("modules.order.field.fiscalized_at.title")}</span>
								<span>{FormatDate(fiscalization.fiscalized_at)}</span>
							</div>
						</div>
					)}
					{fiscalization?.number && (
						<div className="card-body-item">
							<TagSVG />
							<div className="item-info">
								<span className="label">{t("modules.order.field.order_receipt_uid.title")}</span>
								<span>{fiscalization.number}</span>
							</div>
						</div>
					)}
					{order.ereceipt_exists && <FiscalizationERecepit order={order} />}
				</Card.Body>
			</Card>
			{showOrderReceiptsModal && (
				<FiscalizationOrderReceiptsModal
					orderReceipts={fiscalization.order_receipts}
					onHide={() => setShowOrderReceiptsModal(false)}
					isShown={showOrderReceiptsModal}
					orderId={order.id}
				/>
			)}
		</>
	);
};

export default FiscalizationCard;
