import { useContext } from "react";
import { DashboardContext, DashboardContextApi } from "../index";

const useDashboardContext = (): DashboardContextApi => {
	const dashboardContext = useContext(DashboardContext);
	if (!dashboardContext) {
		throw new Error();
	}
	return dashboardContext;
};
export default useDashboardContext;
