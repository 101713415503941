import React, { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { MultipleActionsParams } from "go-list/list/services/types";
import { ItemGroupApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";

interface Props {
	onClose: () => void;
	items: ItemGroupApi[];
	handleSave: () => void;
	allItemsSelected: boolean;
	multipleActionParams?: MultipleActionsParams;
}

interface FormProps {
	modifier_groups: number[] | null;
}

const AssignListModifierGroupsModal: FC<Props> = ({
	onClose,
	items,
	handleSave,
	allItemsSelected,
	multipleActionParams,
}) => {
	const form = useForm<FormProps>({
		defaultValues: {},
		criteriaMode: "all",
	});
	const [loading, setLoading] = useState(false);
	const {
		formState: { errors },
		control,
		handleSubmit,
		formState,
	} = form;
	const { t } = useTranslation();
	const { addSuccessFlash } = useFlash();
	const [modifierGroupCycleError, setModifierGroupCycleError] = useState<ApiError | undefined>();

	const searchModifierGroups = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getModifierGroupsSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const getModifierGroupCycleError = (errs: ApiError[]) => {
		return errs?.find((error) => error?.code === "modifier_group_cycle");
	};

	const getModifierGroupCycleErrorProductName = (error: ApiError) => {
		const resourceName = error.arguments?.resource_name;
		if (resourceName) return resourceName;
		const itemsFieldPart = (error?.field || "").split(".")[0];
		const indexOfLeftBracket = itemsFieldPart.indexOf("[");
		const indexOfRightBracket = itemsFieldPart.indexOf("]");
		const itemErrorIndex = itemsFieldPart.slice(indexOfLeftBracket + 1, indexOfRightBracket);
		return items[Number(itemErrorIndex)].name;
	};

	const onSubmit = handleSubmit(async (data: FormProps) => {
		if (data.modifier_groups === undefined) data.modifier_groups = null;
		setLoading(true);
		const params: Record<string, any> = multipleActionParams ? { ...multipleActionParams } : {};
		if (!allItemsSelected) params.id = items.map((item) => item.id).join(",");
		try {
			await api.organization().patchItemGroups(data, params);
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			handleSave();
		} catch (err) {
			const errs = handleException(err);
			const modifierGroupError = getModifierGroupCycleError(errs);
			setModifierGroupCycleError(modifierGroupError);
		}
		setLoading(false);
	});

	return (
		<Modal show={true} onHide={onClose}>
			<FormDirty formState={formState} onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<h5>{t("modules.item_group.action.assign_modifier_groups.title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<FormSelectGroup
						label={t("common.word.modifier_groups")}
						isMulti={true}
						name="modifier_groups"
						errors={errors}
						defaultValue={null}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						loadOptions={searchModifierGroups}
						control={control}
						data-testid="modifier_groups"
					/>
					{modifierGroupCycleError && (
						<Form.Control.Feedback type="invalid" className="d-inline">
							{`${t(
								"modules.item_group.constraints.item_already_assigned"
							)}: "${getModifierGroupCycleErrorProductName(modifierGroupCycleError)}"`}
						</Form.Control.Feedback>
					)}
				</Modal.Body>
				<Modal.Footer>
					<ButtonLoading loading={loading} onClick={onSubmit}>
						{t("common.action.save", { ns: "lib" })}
					</ButtonLoading>
					<Button variant="light" onClick={onClose}>
						{t("common.action.cancel", { ns: "lib" })}
					</Button>
				</Modal.Footer>
			</FormDirty>
		</Modal>
	);
};

export default AssignListModifierGroupsModal;
