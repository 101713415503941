import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "go-alert/AlertMessage";
import ImageCropper from "go-app/components/ImageCropper/ImageCropper";
import FileUpload from "go-app/components/ImageForm/FileUpload";
import { ReactComponent as ApplySVG } from "go-component/images/svg/apply.svg";
import { ReactComponent as FitSVG } from "go-component/images/svg/fit.svg";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ImageLinksApi } from "../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../services/Api/api";

interface Props {
	handleClose: () => void;
	imageLinkItem: ImageLinksApi;
	handleUpdate: (data: ImageLinksApi) => void;
	handleRemove: (data: ImageLinksApi) => void;
}

const getDefaultImageData = (item: ImageLinksApi) => {
	if (item.image) {
		return `data:${item.image?.type};base64,${item.image?.image_data}`;
	}
	if (item.image_link) {
		return item.image_link.default_link;
	}
	return undefined;
};

const ImageLinksModal: FC<Props> = ({ handleClose, imageLinkItem, handleUpdate, handleRemove }) => {
	const { t } = useTranslation();
	const [selectedFile, setSelectedFile] = useState<undefined | string>(undefined);
	const [imageData, setImageData] = useState(getDefaultImageData(imageLinkItem));
	const [croppedData, setCroppedData] = useState(getDefaultImageData(imageLinkItem));
	const [cropable, setCropable] = useState(false);
	const [tag, setTag] = useState(imageLinkItem.tag);
	const [showImageErrors, setShowImageErrors] = useState(false);
	const form = useForm<ImageLinksApi>({
		criteriaMode: "all",
		defaultValues: imageLinkItem,
	});
	const {
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = form;

	const onSubmit = handleSubmit((data: ImageLinksApi) => {
		if (!imageData) {
			setShowImageErrors(true);
			return;
		}
		handleUpdate(data);
	});

	const getBase64 = (file?: File) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file || new Blob());
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const onUpdateFile = async (file: string, file2?: File) => {
		setSelectedFile(file);
		setShowImageErrors(false);
		const res: any = await getBase64(file2);
		setImageData(res);
		setImageToForm(res);
	};

	const updateImage = (imageBase: string) => {
		setCroppedData(imageBase);
	};

	const handleCropImage = () => {
		if (croppedData) {
			setImageToForm(croppedData);
		}
		setCropable(false);
	};

	const setImageToForm = (newCroppedData: string) => {
		if (newCroppedData && newCroppedData.includes(";base64")) {
			const base64dataSplit = newCroppedData.split(",");
			let imageType = base64dataSplit[0];
			setImageData(newCroppedData);
			imageType = imageType.split(";")[0];
			imageType = imageType.split(":")[1];
			setValue(`image.type`, imageType);
			setValue(`image.image_data`, base64dataSplit[1]);
		}
	};

	const handleRemoveImage = () => {
		setImageData(undefined);
		setSelectedFile(undefined);
		setValue(`image.type`, undefined);
		setValue(`image.image_data`, undefined);
		setValue(`image`, undefined, { shouldDirty: true });
		setValue(`image_link`, undefined, { shouldDirty: true });
	};

	const createTag = (val: string) => {
		setTag(val);
		setValue("tag", val);
	};

	const handleCancel = () => {
		setCropable(false);
		if (imageData) {
			setImageToForm(imageData);
			setImageData(imageData);
		}
	};

	const getTags = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		const newParams = {
			...params,
			"resource_type|e": "MENU_IMAGE",
		};
		return api.organization().getTagsSearchSelect(search, newParams, {
			cancelToken: options?.token,
		});
	};

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.menu.field.additional_photos.modal.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={"modal-image-links"}>
				{!imageData && (
					<div className={"modal-dropzone"}>
						<FileUpload selectedFile={selectedFile} imageData={imageData} onUpdateFile={onUpdateFile} />
					</div>
				)}
				{!cropable && imageData && (
					<div className={"modal-dropzone"}>
						<div className={"dropzone"}>
							<img src={imageData} className="dropzone-image" />
						</div>
					</div>
				)}
				{cropable && (
					<ImageCropper
						handleClose={handleClose}
						handleSave={updateImage}
						defaultImage={imageData}
						image={selectedFile}
					/>
				)}
				<div className="d-flex mb-3">
					{cropable ? (
						<div
							onClick={() => handleCancel()}
							className={`${imageData ? "remove-image" : "remove-image-disabled"}`}
						>
							{t("common.action.cancel", { ns: "lib" })} &times;
						</div>
					) : (
						<div
							onClick={handleRemoveImage}
							className={`${imageData ? "remove-image" : "remove-image-disabled"}`}
						>
							{t("common.action.remove", { ns: "lib" })} &times;
						</div>
					)}
					{imageData && (
						<div className={"ms-auto"}>
							{cropable ? (
								<span className={"apply-image"} onClick={() => handleCropImage()}>
									{t("lib:common.action.apply")} <ApplySVG />
								</span>
							) : (
								<span className={"fit-image"} onClick={() => setCropable(true)}>
									{t("modules.menu.action.match.title")} <FitSVG />
								</span>
							)}
						</div>
					)}
				</div>
				{showImageErrors && <AlertMessage type={"danger"} msg={"must_select_image"} />}
				<FormSelectGroup
					loadOptions={getTags}
					getOptionValue={(opt) => opt.label}
					getOptionLabel={(opt) => opt.label}
					onCreateOption={createTag}
					errors={errors}
					label={"Tag"}
					name="tag"
					defaultValue={{ id: tag, label: tag }}
					control={control}
					data-testid="tag"
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button type={"submit"} variant="primary" onClick={onSubmit}>
					{t("common.action.save", { ns: "lib" })}
				</Button>
				{Object.keys(imageLinkItem).length > 0 && (
					<Button variant="danger" onClick={() => handleRemove(imageLinkItem)}>
						{t("common.action.remove", { ns: "lib" })}
					</Button>
				)}
				<Button variant="light" onClick={handleClose}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default ImageLinksModal;
