import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";
import { MoneyApi } from "go-core/api/types";
import FormatDate, { FormatDuration, getDayName, getMonthName } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { getChartType } from "go-report/reports/services/report-service";
import { capitalizeFirstLetter } from ".";

export class ReportEmployeesUtils {
	static getChartConfig(t: TFunction) {
		return {
			aggregate: "average_total_sale_gross",
			type: getChartType(),
			lineTitle: t("modules.report_employee.field.chart_line.title"),
			title: t("modules.report_employee.field.chart_title.title"),
			parseGroup: (group: string) => {
				if (group === "HOUR_OF_DAY") return "HOUR";
				return group;
			},
		};
	}

	static getSelectedColumns = () => {
		const columnsFromLocalStorage = window.localStorage.getItem(`go_report.employees.columns`)?.split(",");
		if (columnsFromLocalStorage) {
			return columnsFromLocalStorage;
		}
		return [
			"total_sale_gross",
			"total_sale_net",
			"work_duration_in_minutes",
			"to_pay",
			"sales_to_pay_difference",
			"employee_cost_to_sales_percentage",
			"average_total_sale_gross",
			"average_total_sale_net",
		];
	};

	static getGroups = (t: TFunction) => {
		return [
			{
				id: "DAY_OF_WEEK",
				name: t("common.word.day_of_week"),
			},
			{
				id: "EMPLOYEE",
				name: t("components.reports.groups.EMPLOYEE"),
			},
			{
				id: "DATE",
				name: t("lib:common.word.date"),
			},
			{
				id: "HOUR",
				name: t("components.reports.groups.HOUR"),
			},
			{
				id: "MONTH",
				name: capitalizeFirstLetter(t("enums.time_parts.singular.month", { ns: "lib" })),
			},
			{
				id: "YEAR",
				name: capitalizeFirstLetter(t("enums.time_parts.singular.year", { ns: "lib" })),
			},
		];
	};

	static getGroupsInfo = (t: TFunction) => {
		return {
			MONTH: (groupByValue: Record<string, any>) => {
				return getMonthName(groupByValue.name, t);
			},
			DAY_OF_WEEK: (groupByValue: Record<string, any>) => {
				return getDayName(groupByValue.name, t);
			},
			DATE: (groupByValue: Record<string, any>) => {
				return FormatDate(new Date(Number(groupByValue.name)), undefined, undefined, { hours: "none" });
			},
		};
	};

	static getFilters = (t: TFunction) => {
		return [
			{
				id: "compare_date_range",
				name: t("modules.item_group_report.field.compare_date_range.title"),
				type: "date",
				options: ["bt"],
				isRemovable: true,
				visible: false,
			},
			{
				id: "day_of_week",
				name: t("common.word.day_of_week"),
				type: "list",
				options: {
					1: t("enums.days.MONDAY", { ns: "lib" }),
					2: t("enums.days.TUESDAY", { ns: "lib" }),
					3: t("enums.days.WEDNESDAY", { ns: "lib" }),
					4: t("enums.days.THURSDAY", { ns: "lib" }),
					5: t("enums.days.FRIDAY", { ns: "lib" }),
					6: t("enums.days.SATURDAY", { ns: "lib" }),
					7: t("enums.days.SUNDAY", { ns: "lib" }),
				},
			},
		];
	};

	static getColumns = (t: TFunction) => {
		return [
			{
				id: "total_sale_gross",
				name: t("modules.efficiency_report.field.total_sale_gross.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "total_sale_net",
				name: t("modules.efficiency_report.field.total_sale_net.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "work_duration_in_minutes",
				name: t("common.word.work_duration"),
				renderView: (item: number) => FormatDuration(item, t, true),
				renderCsv: (item: number) =>
					`${Math.floor(item / 60)}.${Number(((item % 60) / 60).toFixed(2).slice(2, 4))}`,
			},
			{
				id: "to_pay",
				name: t("modules.efficiency_report.field.to_pay.title"),
				renderView: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "average_total_sale_gross",
				name: t("modules.efficiency_report.field.average_total_sale_gross.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "average_total_sale_net",
				name: t("modules.efficiency_report.field.average_total_sale_net.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "sales_to_pay_difference",
				name: t("modules.efficiency_report.field.sales_to_pay_difference.title"),
				render: (item: MoneyApi) => FormatMoney(item),
			},
			{
				id: "employee_cost_to_sales_percentage",
				name: t("modules.efficiency_report.field.employee_cost_to_sales_percentage.title"),
			},
		];
	};
}
