import React from "react";

interface Props {
	time: string;
	onChange: (time: string) => void;
	displayMidnight?: boolean;
}

export const parseTime = (time: string): string => {
	const hours = Number(time.split(":")[0]);
	const minutes = Number(time.split(":")[1]);
	let newHours, newMinutes;
	minutes < 10 ? (newMinutes = `0${minutes.toString()}`) : (newMinutes = minutes);
	hours < 10 ? (newHours = `0${hours.toString()}`) : (newHours = hours);
	return `${newHours}:${newMinutes}`;
};

export const buildTime = (time: string, displayMidnight?: boolean): string => {
	if (displayMidnight) {
		if (time === "00:00") return "24:00";
		if (time === "00:00:00") return "24:00:00";
	}
	return time;
};

export const AvailabilityTimePicker = (props: Props): JSX.Element => {
	const buildTimes = () => {
		const times: string[] = [];
		for (let i = 0; i < 24; ++i) {
			for (let j = 0; j < 60; j = j + 15) {
				let minutes, hours;
				i < 10 ? (minutes = `0${i}`) : (minutes = i);
				j < 10 ? (hours = `0${j}`) : (hours = j);
				times.push(`${minutes}:${hours}`);
			}
		}
		if (props.displayMidnight) {
			times.shift();
			times.push("24:00");
		}
		return times;
	};

	const updateTime = (value: string) => {
		props.onChange(value);
	};
	return (
		<div style={{ display: "flex", width: "85px" }}>
			<select
				className="form-control"
				value={buildTime(props.time, props.displayMidnight)}
				name="hour"
				onChange={(e) => updateTime(e.target.value)}
			>
				{buildTimes().map((hour) => {
					return (
						<option key={hour} value={hour}>
							{hour}
						</option>
					);
				})}
			</select>
		</div>
	);
};
