import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationLogsMessageEventsEditPage from "./pages/Edit";
import OrganizationLogsMessageEventsIndexPage from "./pages/Index";

const OrganizationLogsMessageEventsPage: FC<RouteComponentProps> = (props) => {
	return (
		<Switch>
			<Route exact={true} path={props.match.url} component={OrganizationLogsMessageEventsIndexPage} />
			<Route path={`${props.match.url}/:log_id`} component={OrganizationLogsMessageEventsEditPage} />
		</Switch>
	);
};

export default OrganizationLogsMessageEventsPage;
