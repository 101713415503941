import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import ParametersSecurity from "go-security/services";

const SecurityProfileRemoveAccountPage: FC<RouteComponentProps> = () => {
	const { t } = useTranslation();

	return (
		<div className="content">
			<div className="content-header">
				<h1>{t("lib:go_component.profile.remove_account.header.title")}</h1>
			</div>
			<div>
				<a
					target="_blank"
					rel="noreferrer"
					href={`${ParametersSecurity.getAccountsUrl()}/profile/remove_account`}
				>
					{t("lib:go_component.profile.remove_account.actions.remove_account")}
				</a>
			</div>
		</div>
	);
};
export default SecurityProfileRemoveAccountPage;
