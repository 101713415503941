import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { wrapPromise } from "go-core";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { DeviceApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../../utils/entityStatus/entityStatus";

interface ListState {
	resource?: Record<string, any>;
	mobileActions: MobileActionProps[];
}

const listName = "DEVICE";
const resourceType = "DEVICE";

const List: FC<ListState> = ({ resource, mobileActions }) => {
	const history = useHistory();
	const { t } = useTranslation();
	const location = useLocation();
	const organization = useSelector(selectOrganization);
	const confirmation = useConfirmation();
	const { addSuccessFlash, addFlash } = useFlash();
	const [params, setParams] = useState<Record<string, any>>({});
	const [items, setItems] = useState<DeviceApi[]>([]);
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const onRemove = async (item: DeviceApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.remove", { ns: "lib" }),
			});
			await api.organization().removeDevice(item.id);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	const onRestore = async (item: DeviceApi) => {
		try {
			await confirmation({
				title: t("confirmation.title", { ns: "lib" }),
				message: t("confirmation.message.activate", { ns: "lib" }),
			});
			await api.organization().restoreDevice(item.id);
			addSuccessFlash(t("common.flash.completed", { ns: "lib" }));
			if (config.fetch) {
				const res = await config.fetch(params);
				setItems(res);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
	};

	let config = {
		fields: [
			{
				id: "name",
				name: t("common.word.name", { ns: "lib" }),
				type: "text" as FilterType,
				render: (item: DeviceApi) => {
					return (
						<StickyColumnWithEntityStatus
							minWidth={200}
							to={`${location.pathname}/${item.id}`}
							status={convertListStatusToEntityStatus(item.status)}
						>
							{item.name}
						</StickyColumnWithEntityStatus>
					);
				},
				renderExport: (item: DeviceApi) => item.name,
			},
			{
				id: "connection_interface",
				name: t("modules.device.field.interface.title"),
				type: "list" as FilterType,
				options: {
					LAN: t("enums.devices.type.LAN"),
					MAC: t("enums.devices.type.MAC"),
					BLUETOOTH: t("enums.devices.type.BLUETOOTH"),
					COM: t("enums.devices.type.COM"),
					USB: t("enums.devices.type.USB"),
					INTERNAL: t("enums.devices.type.INTERNAL"),
					NEXT: t("enums.devices.type.NEXT"),
				},
				render: (item: DeviceApi) => {
					return (
						<div className="d-flex flex-column">
							<span>{t(`enums.devices.type.${item.interface}`)}</span>
							{item.interface === "LAN" &&
								item.parameters.find((f) => f.name === "ip_address") &&
								item.parameters.find((f) => f.name === "port") && (
									<small className="text-muted">
										{item.parameters?.find((f) => f.name === "ip_address")?.value}:
										{item.parameters?.find((f) => f.name === "port")?.value}
									</small>
								)}
							{item.parameters.find((f) => f.name === "mac_address")?.value && (
								<small className="text-muted">
									MAC: {item.parameters.find((f) => f.name === "mac_address")?.value}
								</small>
							)}
						</div>
					);
				},
				renderExport: (item: DeviceApi) => {
					const existingPort =
						item.interface === "LAN" &&
						item.parameters.find((f) => f.name === "ip_address") &&
						item.parameters.find((f) => f.name === "port");
					if (existingPort) {
						return `${t(`enums.devices.type.${item.interface}`)} ${
							item.parameters?.find((f) => f.name === "ip_address")?.value
						}:${item.parameters?.find((f) => f.name === "port")?.value} ${
							item.parameters.find((f) => f.name === "mac_address")
								? `MAC: ${item.parameters.find((f) => f.name === "mac_address")?.value}`
								: ""
						}`;
					}
					return t(`enums.devices.type.${item.interface}`);
				},
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "list" as FilterType,
				options: {
					PRINTER: t("enums.devices.type.PRINTER"),
					PAYMENT_TERMINAL: t("enums.devices.type.PAYMENT_TERMINAL"),
					WEIGHT: t("enums.devices.type.WEIGHT"),
					CODE_READER: t("enums.devices.type.CODE_READER"),
				},
				render: (item: DeviceApi) => {
					return (
						<div className="d-flex flex-column">
							<span>{t(`enums.devices.type.${item.type}`)}</span>
							{item.parameters.find((f) => f.name === "driver")?.value && (
								<small className="text-muted">
									{t(
										`enums.devices.drivers.${
											item.parameters.find((f) => f.name === "driver")?.value
										}`
									)}
								</small>
							)}
						</div>
					);
				},
				renderExport: (item: DeviceApi) => {
					const driver = item.parameters.find((f) => f.name === "driver")?.value;
					if (driver) {
						return `${t(`enums.devices.type.${item.type}`)} ${driver}`;
					}
					return t(`enums.devices.type.${item.type}`);
				},
			},
			{
				id: "terminal",
				name: t("common.word.terminal"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, obj: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTerminalsSearchSelect(search, obj, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
				render: (item: DeviceApi) => {
					return (
						<Link
							target={"_blank"}
							rel={"noreferrer"}
							to={`/${organization.id}/settings/terminals/${item.terminal?.id}`}
						>
							{item.terminal?.name}
						</Link>
					);
				},
				renderExport: (item: DeviceApi) => item.terminal?.name,
			},
			{
				id: "used",
				name: t("modules.device.field.used.title"),
				disableSorting: true,
				render: (item: DeviceApi) => {
					return (
						<>
							{item.fiscal_receipt_printer_terminals.length > 0 && (
								<>
									<small className="text-muted">
										{t("modules.device.field.fiscal_printer.title")}:{" "}
									</small>
									{item.fiscal_receipt_printer_terminals.map((printer, index) => {
										if (index >= 0 && item.fiscal_receipt_printer_terminals.length - 1 > index) {
											return (
												<React.Fragment key={index}>
													<Link
														to={`/${organization.id}/settings/terminals/${printer.id}`}
														rel="noreferrer"
														target="_blank"
													>
														{printer.name}
													</Link>
													<span>, </span>
												</React.Fragment>
											);
										}
										return (
											<Link
												key={index}
												to={`/${organization.id}/settings/terminals/${printer.id}`}
												rel="noreferrer"
												target="_blank"
											>
												{printer.name}
											</Link>
										);
									})}
									<br />
								</>
							)}
							{item.receipt_printer_terminals.length > 0 && (
								<>
									<small className="text-muted">
										{t("modules.device.field.voucher_printer.title")}:{" "}
									</small>
									{item.receipt_printer_terminals.map((printer, index) => {
										if (index >= 0 && item.receipt_printer_terminals.length - 1 > index) {
											return (
												<React.Fragment key={index}>
													<Link
														to={`/${organization.id}/settings/terminals/${printer.id}`}
														rel="noreferrer"
														target="_blank"
													>
														{printer.name}
													</Link>
													<span>, </span>
												</React.Fragment>
											);
										}
										return (
											<Link
												key={index}
												to={`/${organization.id}/settings/terminals/${printer.id}`}
												rel="noreferrer"
												target="_blank"
											>
												{printer.name}
											</Link>
										);
									})}
									<br />
								</>
							)}
							{item.payment_terminal_terminals.length > 0 && (
								<>
									<small className="text-muted">
										{t("modules.device.field.payment_terminal.title")}:{" "}
									</small>
									{item.payment_terminal_terminals.map((terminal, index) => {
										if (index >= 0 && item.payment_terminal_terminals.length - 1 > index) {
											return (
												<>
													<Link
														key={index}
														to={`/${organization.id}/settings/terminals/${terminal.id}`}
														rel="noreferrer"
														target="_blank"
													>
														{terminal.name}
													</Link>
													<span>, </span>
												</>
											);
										}
										return (
											<Link
												key={index}
												to={`/${organization.id}/settings/terminals/${terminal.id}`}
												rel="noreferrer"
												target="_blank"
											>
												{terminal.name}
											</Link>
										);
									})}
									<br />
								</>
							)}
							{item.directions.length > 0 && (
								<>
									<small className="text-muted">{t("common.word.direction")}: </small>
									{item.directions.map((dir, index) => {
										if (index >= 0 && item.directions.length - 1 > index) {
											return (
												<>
													<Link
														key={index}
														to={`/${organization.id}/settings/rooms/directions?f=${btoa(
															unescape(
																encodeURIComponent(
																	`${NEW_WAY_TO_ENCODING_FILTER_SIGN}name|e=${dir.name}`
																)
															)
														)}`}
														rel="noreferrer"
														target="_blank"
													>
														{dir.name}
													</Link>
													<span>, </span>
												</>
											);
										}
										return (
											<Link
												key={index}
												to={`/${organization.id}/settings/rooms/directions?f=${btoa(
													unescape(
														encodeURIComponent(
															`${NEW_WAY_TO_ENCODING_FILTER_SIGN}name|e=${dir.name}`
														)
													)
												)}`}
												rel="noreferrer"
												target="_blank"
											>
												{dir.name}
											</Link>
										);
									})}
									<br />
								</>
							)}
							{item.weight_terminals.length > 0 && (
								<>
									<small className="text-muted">{t("modules.device.field.scale.title")}: </small>
									{item.weight_terminals.map((terminal, index) => {
										if (index >= 0 && item.weight_terminals.length - 1 > index) {
											return (
												<>
													<Link
														key={index}
														to={`/${organization.id}/settings/terminals/${terminal.id}`}
														rel="noreferrer"
														target="_blank"
													>
														{terminal.name}
													</Link>
													<span>, </span>
												</>
											);
										}
										return (
											<Link
												key={index}
												to={`/${organization.id}/settings/terminals/${terminal.id}`}
												rel="noreferrer"
												target="_blank"
											>
												{terminal.name}
											</Link>
										);
									})}
									<br />
								</>
							)}
						</>
					);
				},
				renderExport: (item: DeviceApi) => {
					let str = "";
					if (item.fiscal_receipt_printer_terminals.length > 0) {
						str += `${t(
							"modules.device.field.fiscal_printer.title"
						)}: ${item.fiscal_receipt_printer_terminals.map((item) => item.name).join(", ")}\n`;
					}
					if (item.receipt_printer_terminals.length > 0) {
						str += `${t("modules.device.field.voucher_printer.title")}: ${item.receipt_printer_terminals
							.map((item) => item.name)
							.join(", ")}\n`;
					}
					if (item.directions.length > 0) {
						str += `${t("common.word.direction")}: ${item.directions
							.map((item) => item.name)
							.join(", ")}\n`;
					}
					if (item.payment_terminal_terminals.length > 0) {
						str += `${t("modules.device.field.payment_terminal.title")}: ${item.payment_terminal_terminals
							.map((item) => item.name)
							.join(", ")}\n`;
					}
					if (item.weight_terminals.length > 0) {
						str += `${t("modules.device.field.scale.title")}: ${item.weight_terminals
							.map((item) => item.name)
							.join(", ")}\n`;
					}
					return str;
				},
			},
		],
		actions: [
			{
				name: t("common.action.edit", { ns: "lib" }),
				link: (item: DeviceApi) => {
					return `${location.pathname}/${item.id}`;
				},
			},
			{
				name: t("common.action.remove", { ns: "lib" }),
				click: (item: DeviceApi) => {
					onRemove(item);
				},
				visible: (item: DeviceApi) => {
					return item.status === "ENABLED";
				},
			},
			{
				name: t("common.action.activate", { ns: "lib" }),
				click: (item: DeviceApi) => {
					onRestore(item);
				},
				visible: (item: DeviceApi) => {
					return item.status === "DELETED";
				},
			},
		],
		filters: [
			{
				id: "status",
				name: t("common.word.status", { ns: "lib" }),
				type: "list",
				options: {
					ENABLED: t("enums.common.status.ENABLED", { ns: "lib" }),
					DELETED: t("enums.common.status.DELETED", { ns: "lib" }),
				},
			},
		],
		selectedColumns: ["name", "type", "connection_interface", "terminal", "used"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "deleted",
				name: t("go_list.filters.deleted", { ns: "lib" }),
				slug: "deleted",
				filters: [
					{
						filterId: "status",
						value: "DELETED",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		exportConfig: {
			title: t("modules.device.field.export_config.title"),
			filename: t("modules.device.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
		},
		selectedSegment: getSelectedSegmentForListConfig(data.segments, "all"),
		fetch: (params: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			params.include =
				"weight_terminals,parameters,terminal,directions,fiscal_receipt_printer_terminals,receipt_printer_terminals,payment_terminal_terminals";
			setParams(params);
			return api.organization().getDevices(params, { cancelToken: sourceToken?.token });
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 1,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};

	return (
		<ListData
			data={items}
			config={config}
			emptyList={{ addAction: () => history.push(`${location.pathname}/new`) }}
			onFetch={(fetchItems: DeviceApi[]) => setItems(fetchItems)}
			mobileActions={mobileActions}
		/>
	);
};

const OrganizationSettingsDevicesIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.device.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	const buttons: ButtonProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			variant: "primary",
			path: `${props.match.url}/new`,
		},
	];
	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.add", { ns: "lib" }),
			path: `${props.match.url}/new`,
		},
	];

	return (
		<>
			{!isMobile && <Header title={t("modules.device.header.title")} buttons={buttons} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} mobileActions={mobileActions} />
			</Suspense>
		</>
	);
};
export default OrganizationSettingsDevicesIndexPage;
