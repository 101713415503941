import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import { useWindowSize } from "go-core/components/useWindowSize";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as LineThroughPersonSVG } from "../../../../../../../../../images/svg/liveOrders/line-through-person.svg";
import { ReactComponent as PersonSVG } from "../../../../../../../../../images/svg/liveOrders/person.svg";
import { ReactComponent as UserSlashBlackSVG } from "../../../../../../../../../images/svg/liveOrders/user-slash-black.svg";
import { ReactComponent as UserSlashWhiteSVG } from "../../../../../../../../../images/svg/liveOrders/user-slash-white.svg";
import { LiveOrderApi } from "../../../../../../../../../services/Api/Organization/types";
import { getOrdersSortedByTheLowestDeliveryTime } from "../../../services";
import { OrdersContext } from "../../../services/context";
import { getDeliveryTimeInSeconds } from "../../../services/orderDelivery";
import {
	getOrderPreparationStatusColor,
	getOrderStatusColor,
	preparationStatusColors,
	statusColors,
} from "../../../services/orderStatus";
import { GroupedMarker } from "../../../services/types";
import { getDefaultMapPosition } from "./LiveOrdersMap";
import LiveOrdersMapMarkerOrdersList from "./LiveOrdersMapMarkerOrdersList";
import LiveOrdersMapMarkerOrdersListMobile from "./LiveOrdersMapMarkerOrdersListMobile";

interface Props {
	groupedMarker: GroupedMarker;
	isFocused: boolean;
	setFocusedOrder: (order: LiveOrderApi | undefined) => void;
	focusedOrder: LiveOrderApi | undefined;
}

const LiveOrdersMapMarker: FC<Props> = ({ groupedMarker, isFocused, setFocusedOrder, focusedOrder }) => {
	const { organizationAddress, iconsScale } = useContext(OrdersContext);
	const { orders, coords } = groupedMarker;
	const position = [
		coords.latitude || getDefaultMapPosition(organizationAddress)[0],
		coords.longitude || getDefaultMapPosition(organizationAddress)[1],
	] as any;
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const ref = useRef<any>(null);
	const isMobile = useWindowSize().isMobile;
	const [showLiveOrdersMapMarkerOrdersListMobile, setShowLiveOrdersMapMarkerOrdersListMobile] = useState(false);
	const isOrderWithoutEmployeeDriver = !!orders.find((order) => !order?.delivery?.delivery_employee?.name);
	const deliveryTimeTimeoutRef = useRef<number | undefined>();
	const [deliveryTimeInSeconds, setDeliveryTimeInSeconds] = useState<number>(
		getDeliveryTimeInSeconds(getOrdersSortedByTheLowestDeliveryTime(orders)[0]?.execution_at)
	);
	const [currentColor, setCurrentColor] = useState<string>(getOrderStatusColor(deliveryTimeInSeconds));

	useEffect(() => {
		return () => {
			clearTimeout(deliveryTimeTimeoutRef.current);
		};
	}, []);

	useEffect(() => {
		handleDeliveryTimeTimeout();
	}, [JSON.stringify(getOrdersSortedByTheLowestDeliveryTime(orders)[0]?.execution_at)]);

	const handleDeliveryTimeTimeout = () => {
		if (!getOrdersSortedByTheLowestDeliveryTime(orders)[0]?.execution_at) return;

		if (deliveryTimeTimeoutRef.current) {
			clearTimeout(deliveryTimeTimeoutRef.current);
		}
		deliveryTimeTimeoutRef.current = window.setTimeout(() => {
			const newSeconds =
				formatStringToDate(getOrdersSortedByTheLowestDeliveryTime(orders)[0].execution_at).getTime() / 1000 -
				new Date().getTime() / 1000;
			setDeliveryTimeInSeconds(newSeconds);
			setCurrentColor(getOrderStatusColor(newSeconds));
			handleDeliveryTimeTimeout();
		}, 1000);
	};

	useEffect(() => {
		if (!isMobile && showLiveOrdersMapMarkerOrdersListMobile) {
			setShowLiveOrdersMapMarkerOrdersListMobile(false);
		}
	}, [isMobile]);

	const getMarkerTextColor = () => {
		if (isFocused) return "white";

		switch (currentColor) {
			case statusColors.YELLOW:
			case statusColors.GRAY:
				return "black";
			case statusColors.RED:
			case statusColors.GREEN:
			default:
				return "white";
		}
	};

	const getDriverIcon = (employee: boolean, isGrouped?: boolean) => {
		if (isFocused && isGrouped) return <UserSlashWhiteSVG className="icon" />;

		if (isGrouped) {
			switch (currentColor) {
				case statusColors.YELLOW:
				case statusColors.GRAY:
					return <UserSlashBlackSVG className="icon" />;
				case statusColors.RED:
				case statusColors.GREEN:
				default:
					return <UserSlashWhiteSVG className="icon" />;
			}
		}

		return employee ? (
			<PersonSVG className="live-orders-green-icon" />
		) : (
			<LineThroughPersonSVG className="live-orders-red-icon" />
		);
	};

	const getPreparationStatusColor = (order: LiveOrderApi) => {
		return getOrderPreparationStatusColor(order.preparation_status!.status);
	};

	const groupedMarkerIcon = L.divIcon({
		className: "",
		html: ReactDOMServer.renderToString(
			<div className="w-100 h-100 d-flex align-items-end justify-content-center position-relative">
				<div
					className={`order-grouped-marker ${isFocused ? "order-grouped-marker--focused" : ""}`}
					style={{
						backgroundColor: currentColor,
						transform: `scale(${iconsScale})`,
						transformOrigin: "center 40px",
					}}
				>
					{isOrderWithoutEmployeeDriver && getDriverIcon(false, true)}
					<span className="order-marker-title" style={{ color: getMarkerTextColor() }}>
						{orders.length}
					</span>
				</div>
			</div>
		),
		iconAnchor: [20, 40],
		iconSize: [40, 40],
	});

	const singleMarkerIcon = L.divIcon({
		className: "",
		html: ReactDOMServer.renderToString(
			<div className="w-100 h-100 d-flex align-items-end justify-content-center">
				<div
					className={`single-order-marker ${isFocused ? "order-marker--focused" : ""}`}
					style={{
						backgroundColor: currentColor,
						transform: `scale(${iconsScale}) translateY(-${(iconsScale * 100 - 100) / 2}%)`,
						outline: isFocused ? `4px solid ${currentColor}50` : "unset",
					}}
				>
					<span className="order-marker-title" style={{ color: getMarkerTextColor() }}>
						#{orders[0].number}
					</span>
					<div className="single-order-marker-driver-box">
						{getDriverIcon(!!orders[0]?.delivery?.delivery_employee, false)}
					</div>
					<div
						className={`single-order-marker-preparation-status ${
							getPreparationStatusColor(orders[0]) === preparationStatusColors.WHITE
								? "single-order-marker-preparation-status--bordered"
								: ""
						}`}
						style={{ backgroundColor: getPreparationStatusColor(orders[0]) }}
					/>
				</div>
			</div>
		),
		iconAnchor: [50, isFocused ? 43 : 35],
		iconSize: [100, isFocused ? 43 : 35],
	});
	const onFocusOrder = () => {
		if (orders.length > 1 && isMobile) {
			setShowLiveOrdersMapMarkerOrdersListMobile(true);
		} else {
			let orderToFocus;
			if (
				focusedOrder &&
				focusedOrder.id !== orders[0].id &&
				orders.find((order) => order.id === focusedOrder.id)
			) {
				orderToFocus = { ...focusedOrder };
			} else orderToFocus = orders[0];
			setFocusedOrder(orderToFocus);
			history.push(`/${organization.id}/live_orders/map/orders/${orderToFocus.id}`);
		}
	};

	const eventHandlers = {
		click: onFocusOrder,
	};

	const render = useMemo(() => {
		return (
			<>
				{orders.length > 1 && isMobile && showLiveOrdersMapMarkerOrdersListMobile && (
					<LiveOrdersMapMarkerOrdersListMobile
						orders={orders}
						setFocusedOrder={setFocusedOrder}
						onHide={() => setShowLiveOrdersMapMarkerOrdersListMobile(false)}
					/>
				)}
				<Marker
					position={position}
					icon={orders.length > 1 ? groupedMarkerIcon : singleMarkerIcon}
					eventHandlers={eventHandlers}
					zIndexOffset={isFocused ? 50000 : 0}
					alt={orders[0]?.id?.toString()}
					ref={ref}
				>
					{orders.length > 1 && !isMobile && (
						<LiveOrdersMapMarkerOrdersList
							orders={orders}
							setFocusedOrder={setFocusedOrder}
							focusedOrder={focusedOrder}
						/>
					)}
				</Marker>
			</>
		);
	}, [
		JSON.stringify(orders),
		JSON.stringify(position),
		isMobile,
		JSON.stringify(focusedOrder),
		JSON.stringify(organizationAddress),
		JSON.stringify(iconsScale),
		JSON.stringify(coords),
		currentColor,
	]);

	return render;
};

export default LiveOrdersMapMarker;
