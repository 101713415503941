import React, { FC, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { FormInput } from "go-form";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import FormMoneyInput from "go-form/components/FormMoneyInput";
import FormNumberInput from "go-form/components/FormNumberInput";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as RemoveSvg } from "../../../../../../../../../../images/svg/remove.svg";
import { ReactComponent as SortableHandleSvg } from "../../../../../../../../../../images/svg/sortable-handle.svg";
import {
	ModifierGroupApi,
	ModifierGroupOptionApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { checkIfValueIsEmpty } from "../../utils";

interface Props {
	form: UseFormReturn<ModifierGroupApi>;
	handleRemoveOption: (index: number) => void;
	onAddModifierOptions: () => void;
	fields: Record<string, any>[];
	handleReplace: (items: ModifierGroupOptionApi[]) => void;
}

const ModifierGroupOptionsTable: FC<Props> = ({
	form,
	handleRemoveOption,
	onAddModifierOptions,
	fields,
	handleReplace,
}) => {
	const {
		formState: { errors },
		register,
		control,
		clearErrors,
		watch,
		setValue,
	} = form;
	const isSortingRef = useRef(false);
	const organization = useSelector(selectOrganization);
	const currency = organization.currency || "PLN";
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const updatePositions = (items: ModifierGroupOptionApi[]) => {
		if (!isSortingRef.current) return;
		isSortingRef.current = false;
		items.forEach((item, index) => {
			item.position = index;
		});
		handleReplace(items);
	};

	const onRemoveOption = (index: number) => {
		clearErrors(`options.${index}.item_id`);
		handleRemoveOption(index);
	};

	return (
		<>
			<div className={`${isMobile ? "table-responsive" : ""}`}>
				<table className="table table-form modifier-group-options-table">
					<thead>
						<tr>
							<th className="action" />
							<th className="w-25">{t("lib:common.word.name")}</th>
							<th style={{ minWidth: isMobile ? "90px" : "unset" }}>
								{t("modules.item_group.field.price.title")}
							</th>
							<th>{t("modules.item_group.field.min_amount.title")}</th>
							<th>{t("modules.item_group.field.max_amount.title")}</th>
							<th>{t("modules.item_group.field.choosen_amount.title")}</th>
							<th>{t("modules.item_group.field.charge_above.title")}</th>
							<th className="action" />
						</tr>
					</thead>
					{
						<ReactSortable
							handle=".sortable-handler"
							list={watch("options")}
							tag={"tbody"}
							onUpdate={() => (isSortingRef.current = true)}
							setList={(newFields) => updatePositions(newFields)}
						>
							{fields.map((option, index) => {
								return (
									<tr key={option.item_id}>
										<td className="action">
											<SortableHandleSvg
												style={{ marginLeft: "6px" }}
												className="sortable-handler"
											/>
										</td>
										<td>
											<FormInput
												type="hidden"
												errors={errors}
												name={`options.${index}.item_id`}
												register={register}
											/>
											<FormInput
												type="hidden"
												errors={errors}
												defaultValue={index}
												name={`options.${index}.position`}
												register={register}
											/>
											<strong>
												<Link
													to={`/${organization.id}/menu/item_groups/${option.sub_item?.item_group?.id}`}
													target={"_blank"}
													rel="noreferrer"
												>
													{option.sub_item?.name}
												</Link>
											</strong>
											{Object.values(errors).map((f: Record<string, any>) => {
												if (f[index] && f[index].item_id) {
													return (
														<Form.Control.Feedback
															key={f[index].item_id}
															type={"invalid"}
															className={"d-inline"}
														>
															{t(
																"modules.item_group.constraints.item_contains_modifier_group"
															)}
														</Form.Control.Feedback>
													);
												}
												return null;
											})}
										</td>
										<td>
											<div className={"d-flex flex-column"}>
												<div className="input-container">
													<FormMoneyInput
														control={control}
														name={`options.${index}.price.amount`}
														errors={errors}
														currency={currency}
														placeholder={
															option?.sub_item?.price?.amount
																? FormatMoney(option.sub_item.price)
																: FormatMoney({
																		amount: 0,
																		currency,
																  })
														}
														defaultValue={option.price?.amount}
													/>
													{!checkIfValueIsEmpty(watch(`options.${index}.price.amount`)) && (
														<RemoveSvg
															className="remove-icon"
															onClick={() =>
																setValue(`options.${index}.price.amount`, null)
															}
														/>
													)}
												</div>
											</div>
										</td>
										<td>
											<div className="input-container">
												<FormNumberInput
													control={control}
													name={`options.${index}.quantity_info.min_permitted`}
													onChange={() => clearErrors([`options.${index}`])}
													decimalScale={0}
													suffix={""}
													placeholder="0"
													errors={errors}
												/>
												{!checkIfValueIsEmpty(
													watch(`options.${index}.quantity_info.min_permitted`)
												) && (
													<RemoveSvg
														className="remove-icon"
														onClick={() =>
															setValue(
																`options.${index}.quantity_info.min_permitted`,
																null
															)
														}
													/>
												)}
											</div>
										</td>
										<td>
											<div className="input-container">
												<FormNumberInput
													control={control}
													defaultValue={option.quantity_info?.max_permitted}
													name={`options.${index}.quantity_info.max_permitted`}
													onChange={() => clearErrors([`options.${index}`])}
													decimalScale={0}
													suffix={""}
													placeholder="∞"
													errors={errors}
												/>
												{!checkIfValueIsEmpty(
													watch(`options.${index}.quantity_info.max_permitted`)
												) && (
													<RemoveSvg
														className="remove-icon"
														onClick={() =>
															setValue(
																`options.${index}.quantity_info.max_permitted`,
																null
															)
														}
													/>
												)}
											</div>
										</td>
										<td>
											<div className="input-container">
												<FormNumberInput
													control={control}
													name={`options.${index}.quantity_info.default_quantity`}
													onChange={() => {
														clearErrors([`options.${index}`]);
														clearErrors("quantity_info");
													}}
													suffix={""}
													placeholder="0"
													errors={errors}
												/>
												{!checkIfValueIsEmpty(
													watch(`options.${index}.quantity_info.default_quantity`)
												) && (
													<RemoveSvg
														className="remove-icon"
														onClick={() =>
															setValue(
																`options.${index}.quantity_info.default_quantity`,
																null
															)
														}
													/>
												)}
											</div>
										</td>
										<td>
											<div className="input-container">
												<FormNumberInput
													control={control}
													name={`options.${index}.quantity_info.charge_above`}
													decimalScale={0}
													suffix={""}
													placeholder="0"
													errors={errors}
												/>
												{!checkIfValueIsEmpty(
													watch(`options.${index}.quantity_info.charge_above`)
												) && (
													<RemoveSvg
														className="remove-icon"
														onClick={() =>
															setValue(
																`options.${index}.quantity_info.charge_above`,
																null
															)
														}
													/>
												)}
											</div>
										</td>
										<td className="action">
											<RemoveSvg
												style={{ marginRight: "6px" }}
												onClick={() => onRemoveOption(index)}
												className="icon"
											/>
										</td>
									</tr>
								);
							})}
						</ReactSortable>
					}
				</table>
			</div>
			<Button variant="add" className={"mb-4"} onClick={() => onAddModifierOptions()}>
				{t("common.action.assign_to_group")}
			</Button>
		</>
	);
};
export default ModifierGroupOptionsTable;
