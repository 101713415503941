import React, { useContext, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import FileUpload from "go-app/components/ImageForm/FileUpload";
import handleError from "go-app/services/errors";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import { ReactComponent as EmptyImageSVG } from "../../../go-component/images/svg/angle-input.svg";
import FileDisplay from "./FileDisplay";
import ImportDataTable from "./ImportDataTable";
import ImportErrors from "./ImportErrors";
import { CsvContext } from "./services/context";

interface Props {
	selectedFile?: File;
	attributes: any;
	resourceType: string;
	customFieldsConfig?: CustomFieldTemplateApi[];
	parentErrors?: ApiError[];
	parentResponseData: any;
	importCsvRequestParams: string;
	redirectionUrl: string;
	comparationCheck: (data: any, fieldName: string) => any;
	renderComparedValue: (data: any, fieldName: string, differs?: boolean) => JSX.Element;
	databaseColumnName: Record<string, string>;
	constraints: Record<string, string>;
	customAttributesCompare?: (attribute: string) => string;
	workingInBackground?: boolean;
	importPageRef?: React.RefObject<HTMLDivElement | null>;
}

const ImportConfirm = ({
	selectedFile,
	attributes,
	resourceType,
	customFieldsConfig,
	parentErrors,
	parentResponseData,
	importCsvRequestParams,
	redirectionUrl,
	comparationCheck,
	renderComparedValue,
	databaseColumnName,
	constraints,
	customAttributesCompare,
	workingInBackground,
	importPageRef,
}: Props): JSX.Element => {
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const [errors, setErrors] = useState<ApiError[] | undefined>(parentErrors);
	const [response, setResponse] = useState<any[] | undefined>(parentResponseData);
	const [loading, setLoading] = useState(false);
	const [correctedFile, setCorrectedFile] = useState<File | undefined>(undefined);
	const history = useHistory();
	const csvImportContext = useContext(CsvContext);
	const importTimeoutRef = useRef<number | undefined>();
	const cancelTokenSource = useRef<CancelTokenSource>();

	useEffect(() => {
		return () => {
			if (cancelTokenSource.current !== undefined) {
				cancelTokenSource.current.cancel();
			}
			window.clearTimeout(importTimeoutRef.current);
		};
	}, []);

	const handleImportTimeout = (
		jobUid: string,
		formData: FormData,
		_headers: Record<string, string>,
		params: Record<string, any>
	) => {
		if (!csvImportContext.getImportCsvProgress) return;

		importTimeoutRef.current = window.setTimeout(async () => {
			try {
				if (!csvImportContext.getImportCsvProgress) return;
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;

				const res = await csvImportContext.getImportCsvProgress(jobUid, resourceType, params, {
					cancelToken: newCancelToken.token,
				});
				if (res.status === 204) {
					setLoading(true);
					handleImportTimeout(jobUid, formData, _headers, params);
				} else {
					setLoading(false);
					setResponse(res.data.data);
					window.clearTimeout(importTimeoutRef.current);
				}
			} catch (err) {
				const errs = handleException(err);
				if (errs[0].message === "canceled") return;
				handleError.alert(err, addFlash);
				setErrors(errs);
				setLoading(false);
				window.clearTimeout(importTimeoutRef.current);
			}
		}, 1000);
	};

	const importCsv = async () => {
		setLoading(true);
		setErrors([]);
		const params: Record<string, string> = { include: importCsvRequestParams };
		try {
			const formData = new FormData();
			if (correctedFile) formData.append("csv", correctedFile);

			formData.append(
				"attributes",
				new Blob(
					[
						JSON.stringify(
							attributes.filter((f: { domain_field_name: string }) => f.domain_field_name?.length > 0)
						),
					],
					{ type: "application/json" }
				)
			);
			const _headers = { "Content-Type": "multipart/form-data" };

			if (workingInBackground && csvImportContext.importAsyncCsv) {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;
				const res = await csvImportContext.importAsyncCsv(resourceType, formData, _headers, params, {
					cancelToken: newCancelToken.token,
				});
				handleImportTimeout(res, formData, _headers, params);
			} else if (csvImportContext.importCsv) {
				const res = await csvImportContext.importCsv(resourceType, formData, _headers, params);
				setResponse(res);
			}
		} catch (err) {
			const errs = handleException(err);
			if (errs[0].message === "canceled") return;
			handleError.alert(err, addFlash);
			setErrors(errs);
		}
		if (!workingInBackground) {
			setLoading(false);
		}
	};

	const handleFinishImportTimeout = (jobUid: string, formData: FormData, _headers: Record<string, string>) => {
		if (!csvImportContext.getFinishImportCsvProgress) return;

		importTimeoutRef.current = window.setTimeout(async () => {
			try {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;

				if (!csvImportContext.getFinishImportCsvProgress) return;

				const res = await csvImportContext.getFinishImportCsvProgress(jobUid, resourceType, {
					cancelToken: newCancelToken.token,
				});
				if (res.job_status === "NEW" || res.job_status === "STARTED") {
					setLoading(true);
					handleFinishImportTimeout(res.job_uid, formData, _headers);
				} else {
					setLoading(false);
					window.clearTimeout(importTimeoutRef.current);
					addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
					history.push(redirectionUrl);
				}
			} catch (err) {
				const errs = handleException(err);
				if (errs[0].message === "canceled") return;
				handleError.alert(err, addFlash);
				setLoading(false);
				window.clearTimeout(importTimeoutRef.current);
			}
		}, 1000);
	};

	const finishImport = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			if (selectedFile && !correctedFile) formData.append("csv", selectedFile);
			if (correctedFile) formData.append("csv", correctedFile);

			formData.append(
				"attributes",
				new Blob(
					[
						JSON.stringify(
							attributes.filter((f: { domain_field_name: string }) => f.domain_field_name?.length > 0)
						),
					],
					{ type: "application/json" }
				)
			);
			const _headers = { "Content-Type": "multipart/form-data" };
			if (workingInBackground && csvImportContext.finishImportAsyncCsv) {
				const CancelToken = axios.CancelToken;
				const newCancelToken = CancelToken.source();
				cancelTokenSource.current = newCancelToken;
				const res = await csvImportContext.finishImportAsyncCsv(resourceType, formData, _headers, {
					cancelToken: newCancelToken.token,
				});
				handleFinishImportTimeout(res.data.data, formData, _headers);
			} else if (csvImportContext.finishImportCsv) {
				await csvImportContext.finishImportCsv(resourceType, formData, _headers);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				history.push(redirectionUrl);
			}
		} catch (e) {
			handleError.alert(e, addFlash);
		}
		if (!workingInBackground) {
			setLoading(false);
		}
	};

	const onUpdateFile = (file: string, fileObj?: File) => setCorrectedFile(fileObj);

	return (
		<>
			{errors && errors.length > 0 && (
				<>
					<Alert variant={"danger"}>
						{t("go_component.import_csv.constraints.message", { ns: "lib" })}{" "}
						{t(`go_component.import_csv.constraints.errors`, { count: errors.length, ns: "lib" })}
					</Alert>
					<ImportErrors
						databaseColumnName={databaseColumnName}
						errors={errors}
						constraints={constraints}
						importPageRef={importPageRef}
					/>
					<span className={"mb-10"}>
						{t("go_component.import_csv.constraints.corrected_message", { ns: "lib" })}
					</span>
					<FileUpload
						fileIcon={
							correctedFile ? (
								<FileDisplay icon={<EmptyImageSVG />} fileName={correctedFile.name} />
							) : undefined
						}
						onUpdateFile={onUpdateFile}
					/>
				</>
			)}
			{response && response.length > 0 ? (
				<>
					<ImportDataTable
						customFieldsConfig={customFieldsConfig}
						response={response}
						attributes={attributes}
						comparationCheck={comparationCheck}
						renderComparedValue={renderComparedValue}
						databaseColumnName={databaseColumnName}
						customAttributesCompare={customAttributesCompare}
						importPageRef={importPageRef}
					/>
					<Alert variant={"warning"} style={{ marginBottom: "70px" }}>
						<div className={"d-flex flex-column mb-10"}>
							<strong className={"import-info"}>Info:</strong>
							<span>{t("go_component.import_csv.field.operation_info.title", { ns: "lib" })}</span>
						</div>
					</Alert>
					<div className="sticky-footer">
						<ButtonLoading
							style={{ display: "flex", marginBottom: "10px", width: "min-content" }}
							loading={loading}
							onClick={() => finishImport()}
						>
							{t("common.action.save", { ns: "lib" })}
						</ButtonLoading>
					</div>
				</>
			) : (
				<>
					<Alert variant={"warning"} style={{ marginBottom: "70px" }}>
						<div className={"d-flex flex-column mb-10"}>
							<strong className={"import-info"}>Info:</strong>
							<span>{t("go_component.import_csv.field.file_info.title", { ns: "lib" })}</span>
						</div>
					</Alert>
					<div className="sticky-footer">
						<Form.Group className="form-group">
							<ButtonLoading loading={loading} type="submit" onClick={importCsv}>
								{t("common.action.import", { ns: "lib" })}
							</ButtonLoading>
							<Button variant="light" onClick={() => history.push(redirectionUrl)}>
								{t("common.action.cancel", { ns: "lib" })}
							</Button>
						</Form.Group>
					</div>
				</>
			)}
		</>
	);
};
export default ImportConfirm;
