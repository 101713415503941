import React, { useMemo } from "react";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { SubReportApi } from "go-report/core/services/types";
import { ChartDataRangeType, ChartType } from "../../services/charts/types";
import { DashboardReportType } from "../../services/types";
import useDashboardContext from "../../services/useDashboardContext";
import { mapToReport } from "../../utils/typeUtils";
import { useDashboardReportAggregate } from "../../utils/useDashboardReportAggregate";
import Box from "../Charts/Box";
import HorizontalBar from "../Charts/HorizontalBar";
import VerticalBar from "../Charts/VerticalBar";

interface Props {
	type: ChartType;
	data: SubReportApi[];
	chartDataRangeType: ChartDataRangeType;
	selectedFilters: ListSelectedFilter[];
	reportType: DashboardReportType;
}

const ReportRenderer = (props: Props): JSX.Element => {
	const { type, data, chartDataRangeType, selectedFilters, reportType } = props;
	const { sortSubReports, getSubReportFilterCondition } = useDashboardReportAggregate(reportType);
	const itemsCount = 6;
	const topSoldItems = data.filter(getSubReportFilterCondition()).sort(sortSubReports()).slice(0, itemsCount);
	// Funkcjonalnosc wykresu procentowego
	// const totalAggregateValue = getAggregateValue(data);
	const { state } = useDashboardContext();

	const renderChart = useMemo(() => {
		switch (type) {
			// Funkcjonalnosc wykresu procentowego
			// case ChartType.PROGRESS_BAR:
			// 	return (
			// 		<>
			// 			<h4 className="text-primary" style={{ marginTop: "-15px", marginBottom: "16px" }}>
			// 				{totalAggregateValue}
			// 			</h4>
			// 			{topSoldItems.map((item) => {
			// 				return (
			// 					<LineProgress
			// 						item={item}
			// 						key={item.group_by_value.name}
			// 						totalAggregateValue={totalAggregateValue}
			// 						reportType={reportType}
			// 					/>
			// 				);
			// 			})}
			// 		</>
			// 	);
			case ChartType.LINE:
				return (
					<VerticalBar
						selectedFilters={selectedFilters}
						chartDataRangeType={chartDataRangeType}
						data={mapToReport({ sub_report: topSoldItems })}
						reportType={reportType}
					/>
				);
			case ChartType.HORIZONTAL_BAR:
				return (
					<HorizontalBar
						type={type}
						data={mapToReport({ sub_report: topSoldItems.slice(0, itemsCount / 2) })}
						reportType={reportType}
					/>
				);
			case ChartType.VERTICAL_BAR:
				return (
					<VerticalBar
						selectedFilters={selectedFilters}
						chartDataRangeType={chartDataRangeType}
						data={mapToReport({ sub_report: topSoldItems.slice(0, itemsCount / 2) })}
						reportType={reportType}
					/>
				);
			default:
				return (
					<div className={"report-item"}>
						{topSoldItems.map((item) => {
							return <Box item={item} key={item.group_by_value.name} reportType={reportType} />;
						})}
					</div>
				);
		}
	}, [data, type, reportType, chartDataRangeType, selectedFilters, state?.data]);

	return <>{renderChart}</>;
};
export default ReportRenderer;
