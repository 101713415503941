import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { UpdatePriceListPredicateApi } from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
	isShown: boolean;
	onSuccessAction: () => void;
	setPredicatePriceList: (value: Record<string, any> | undefined) => void;
	form: UseFormReturn<UpdatePriceListPredicateApi>;
}

const AddNewPredicateModal: FC<Props> = ({ onHide, isShown, onSuccessAction, setPredicatePriceList, form }) => {
	const { t } = useTranslation();
	const { control } = form;

	const onSubmit = () => {
		onSuccessAction();
		onHide();
	};
	const searchPriceLists = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPriceListsSearchSelect(search, params, undefined, {
			cancelToken: options?.token,
		});
	};

	return (
		<Modal onHide={onHide} show={isShown}>
			<Modal.Header>
				<Modal.Title>{t("modules.predicate.action.add_new_predicate_modal.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormSelectGroup
					errors={[]}
					label={t("common.word.price_list")}
					loadOptions={searchPriceLists}
					control={control}
					getOptionValue={(opt) => ({
						...opt,
						name: opt.label,
					})}
					getOptionLabel={(opt) => opt.label}
					name="new_price_list"
					onChange={(e) => setPredicatePriceList(e)}
					data-testid="new_price_list"
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button type="submit" onClick={onSubmit}>
					{t("lib:common.action.select")}
				</Button>
				<Button variant="light" onClick={onHide}>
					{t("lib:common.action.cancel")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddNewPredicateModal;
