import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MoneyApi } from "go-core/api/types";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import RenderPaymentMethod from "../../../../../../../../../../../components/Common/Renderers/RenderPaymentMethod";
import { ReactComponent as DownArrowSVG } from "../../../../../../../../../../../images/svg/down-arrow-red.svg";
import { ReactComponent as UpArrowSVG } from "../../../../../../../../../../../images/svg/up-arrow-green.svg";
import {
	PosReportCurrencyApi,
	PosReportEndAmountApi,
} from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	currencies: PosReportCurrencyApi[];
	filterPaids: (type: string) => void;
}

const DetailedSettlementsTable: FC<Props> = ({ currencies, filterPaids }) => {
	const { t } = useTranslation();

	const currenciesArray = currencies?.map((currency) => currency.currency);

	const getPaymentMethods = () => {
		const paymentMethods: string[] = [];
		currencies?.forEach((currency) => {
			currency?.end_amounts
				?.map((endAmount) => endAmount.type)
				?.forEach((type) => {
					if (!paymentMethods.includes(type)) paymentMethods.push(type);
				});
		});
		return paymentMethods;
	};

	const getDiff = (systemAmount: MoneyApi, actualAmount: MoneyApi) => {
		return (systemAmount.amount ? systemAmount.amount : 0) - (actualAmount.amount ? actualAmount.amount : 0);
	};

	const maxAmountOfEndAmounts = Math.max(...currencies.map((currency) => currency.end_amounts.length));

	const tdStyles = {
		padding: "14px 0px",
	};

	const drawAmountColumn = (endAmounts: PosReportEndAmountApi[], paymentMethod: string, currency: string) => {
		for (let i = 0; i < maxAmountOfEndAmounts; i++) {
			if (endAmounts[i]?.type === paymentMethod && endAmounts[i])
				return (
					<td>
						<div className="d-flex flex-column">
							<span>{FormatMoney(endAmounts[i].system_amount)}</span>
							<span style={{ fontSize: "12px" }}>
								{FormatMoney(endAmounts[i].actual_amount)}
								<span> (</span>
								<span
									style={{
										color:
											getDiff(endAmounts[i].actual_amount, endAmounts[i].system_amount) < 0
												? "red"
												: "inherit",
									}}
								>
									{FormatMoney({
										...endAmounts[i].actual_amount,
										amount: getDiff(endAmounts[i].actual_amount, endAmounts[i].system_amount),
									})}
									{getDiff(endAmounts[i].actual_amount, endAmounts[i].system_amount) !== 0 && (
										<>
											{getDiff(endAmounts[i].actual_amount, endAmounts[i].system_amount) > 0 && (
												<UpArrowSVG
													style={{
														margin: "0px 0px 0px 2px",
														height: "10px",
														width: "10px",
													}}
												/>
											)}
											{getDiff(endAmounts[i].actual_amount, endAmounts[i].system_amount) < 0 && (
												<DownArrowSVG
													style={{
														margin: "0px 0px 0px 2px",
														height: "10px",
														width: "10px",
													}}
												/>
											)}
										</>
									)}
								</span>
								)
							</span>
						</div>
					</td>
				);
			if (!endAmounts[i])
				return (
					<td>
						<div className="d-flex flex-column">
							<span>{FormatMoney({ amount: 0, currency })}</span>
							<span style={{ fontSize: "12px" }}>
								{FormatMoney({ amount: 0, currency })}
								<span> (</span>
								<span>
									{FormatMoney({
										currency,
										amount: 0,
									})}
								</span>
								)
							</span>
						</div>
					</td>
				);
		}
	};

	return (
		<div className="settlements-table-wrapper">
			<table className="table table-preview mb-0 settlements-table">
				<thead>
					<tr>
						<th className={`${currenciesArray.length > 1 && "pb-0 border-bottom-0"}`}>
							{t("common.word.payment_method")}
						</th>
						<th className={`${currenciesArray.length > 1 && "pb-0 border-bottom-0"}`}>
							{t("modules.pos_report.field.system_amount.title")}
						</th>
					</tr>
					{currenciesArray.length > 1 && (
						<tr>
							<th />
							{currenciesArray.map((currency) => (
								<th key={currency}>{currency}</th>
							))}
						</tr>
					)}
				</thead>
				<tbody>
					{getPaymentMethods()?.map((paymentMethod, index) => {
						return (
							<tr key={index}>
								<td>
									<RenderPaymentMethod
										name={t(`enums.payment_methods.payment_receipt_types.${paymentMethod}`)}
										type={paymentMethod}
									/>
									{paymentMethod === "CASH" && (
										<>
											<small className="text-muted">
												{t("modules.pos_report.field.cash.title")}
											</small>
										</>
									)}
								</td>
								{currencies?.map((currency) =>
									drawAmountColumn(currency.end_amounts, paymentMethod, currency.currency)
								)}
							</tr>
						);
					})}
					<tr>
						<td style={tdStyles}>{t("modules.pos_report.field.start_amount.title")}</td>
						{currencies.map((currency, index) => (
							<td key={index} style={tdStyles}>
								{FormatMoney(currency.start_amount_money)}
							</td>
						))}
					</tr>
					<tr>
						<td style={tdStyles}>
							{t("modules.pos_report.field.in_amount.title")}
							<div className={"action-ref"} onClick={() => filterPaids("PAID_IN")}>
								{t("modules.pos_report.action.show_all_paids_in.title")}
							</div>
						</td>
						{currencies?.map((currency, index) => (
							<td key={index} style={tdStyles}>
								{FormatMoney(currency.paid_in_amount_money)}
							</td>
						))}
					</tr>
					<tr>
						<td style={tdStyles}>
							{t("modules.pos_report.field.out_amount.title")}

							<div className={"action-ref"} onClick={() => filterPaids("PAID_OUT")}>
								{t("modules.pos_report.action.show_all_paids_out.title")}
							</div>
						</td>
						{currencies?.map((currency, index) => (
							<td key={index} style={tdStyles}>
								{FormatMoney(currency.paid_out_amount_money)}
							</td>
						))}
					</tr>
					<tr>
						<td style={tdStyles}>{t("modules.pos_report.field.sold_amount.title")}</td>
						{currencies?.map((currency, index) => (
							<td key={index} style={tdStyles}>
								{FormatMoney(currency.income_amount_money)}
							</td>
						))}
					</tr>
					<tr className={"summary"}>
						<td>
							<strong>{t("modules.pos_report.field.summary_amount.title")}</strong>
							<br />
							<small className="text-muted">
								{t("modules.pos_report.field.summary_amount.helptext.description")}
							</small>
						</td>
						{currencies?.map((currency, index) => (
							<td key={index} style={{ verticalAlign: "top" }}>
								<strong>{FormatMoney(currency.total_amount_money)}</strong>
							</td>
						))}
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default DetailedSettlementsTable;
