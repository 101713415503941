import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormatResourceStatus from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import { getComponentStatusClassName } from "../../../Common/FormatStatus";

interface Props {
	status: string;
}

const FormatRequestItemStatus: FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	return (
		<FormatResourceStatus
			className={getComponentStatusClassName(props.status)}
			status={props.status}
			translation={t(`lib:go_component.request_item.status.${props.status}`)}
		/>
	);
};

export default FormatRequestItemStatus;
