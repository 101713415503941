import { parseRangeToDefaultString } from "go-list/utils/daterangeutils";
import { GROUP_SEPARATOR } from "./services";

export const getPredefineKeys = () => {
	return ["last_{x}_months", "previous_{x}_months", "previous_{x}_quarter", "previous_{x}_years", "this_month"];
};

const getQuarter = (date: Date, quarters: number) => {
	const startDate = new Date(date);
	startDate.setMonth(startDate.getMonth() - quarters * 3);

	const startMonth = Math.floor(startDate.getMonth() / 3) * 3;
	startDate.setMonth(startMonth, 1);
	startDate.setHours(0, 0, 0, 0);

	return startDate;
};

export const predefineToValues = (predefinedRange: string, date: Date): string | undefined => {
	let startDate;
	let endDate;

	switch (predefinedRange) {
		case "this_month":
			startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
			endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
			break;
		default: {
			let match = predefinedRange.match(/^last_(\d+)-(\d+)_months$/);
			if (match) {
				const monthsAgoEnd = parseInt(match[1], 10); // Older range (e.g., 6 months ago)
				const monthsAgoStart = parseInt(match[2], 10); // More recent range (e.g., 3 months ago)
				startDate = new Date(date.getFullYear(), date.getMonth() - monthsAgoStart, date.getDate(), 0, 0, 0);
				endDate = new Date(date.getFullYear(), date.getMonth() - monthsAgoEnd, date.getDate(), 23, 59, 59);
				break;
			}
			match = predefinedRange.match(/^last_(\d+)_months$/);
			if (match) {
				const value = parseInt(match[1], 10);
				startDate = new Date(date.getFullYear(), date.getMonth() - value, date.getDate(), 0, 0, 0);
				endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
				break;
			}
			//months
			match = predefinedRange.match(/^previous_(\d+)-(\d+)_months$/);
			if (match) {
				const monthsAgoEnd = parseInt(match[1], 10); // Older range (e.g., 6 months ago)
				const monthsAgoStart = parseInt(match[2], 10); // More recent range (e.g., 3 months ago)
				startDate = new Date(date.getFullYear(), date.getMonth() - monthsAgoStart, 1, 0, 0, 0);
				endDate = new Date(date.getFullYear(), date.getMonth() - monthsAgoEnd, 0, 23, 59, 59);
				break;
			}
			match = predefinedRange.match(/^previous_(\d+)_months$/);
			if (match) {
				const value = parseInt(match[1], 10);
				startDate = new Date(date.getFullYear(), date.getMonth() - value, 1, 0, 0, 0);
				endDate = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59);
				break;
			}

			//quarters
			match = predefinedRange.match(/^previous_(\d+)_quarters$/);
			if (match) {
				const value = parseInt(match[1], 10);
				startDate = getQuarter(date, value);
				endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0, 23, 59, 59);
				break;
			}
			//years
			match = predefinedRange.match(/^previous_(\d+)-(\d+)_years/);
			if (match) {
				const yearsAgoEnd = parseInt(match[1], 10); // Older range (e.g., 6 months ago)
				const yearsAgoStart = parseInt(match[2], 10); // More recent range (e.g., 3 months ago)
				startDate = new Date(date.getFullYear() - yearsAgoStart, 0, 1, 0, 0, 0);
				endDate = new Date(date.getFullYear() - yearsAgoEnd, 12, 0, 23, 59, 59);
				break;
			}
			match = predefinedRange.match(/^previous_(\d+)_years$/);
			if (match) {
				const value = parseInt(match[1], 10);
				startDate = new Date(date.getFullYear() - value, 0, 1, 0, 0, 0);
				endDate = new Date(date.getFullYear(), 12, 0, 23, 59, 59);
				break;
			}
		}
	}
	if (!startDate) return undefined;

	return parseRangeToDefaultString({
		startDate,
		endDate,
	});
};

export const replacePredefinedRanges = (value: string, date: Date): string => {
	const predefinedKeys = getPredefineKeys();

	// Split the string by the custom delimiter (ç)
	const parts = value.split(GROUP_SEPARATOR);

	// Iterate over each part and replace predefined ranges
	const updatedParts = parts.map((part) => {
		const [field, condition] = part.split("=");
		if (condition) {
			for (const key of predefinedKeys) {
				const regex = new RegExp(key.replace("{x}", "\\d+(-\\d+)?"));
				if (regex.test(condition)) {
					const dateRange = predefineToValues(condition, date);
					if (dateRange) {
						return `${field}=${dateRange}`;
					}
				}
			}
		}

		return part; // Return the original part if no replacement is needed
	});

	// Rejoin the updated parts with the delimiter
	return updatedParts.join(GROUP_SEPARATOR);
};
