export function getGoAccountsApiURL(): string {
	if (process.env.REACT_APP_GOACCOUNTS_URL) {
		return process.env.REACT_APP_GOACCOUNTS_URL;
	}
	return "http://app.goaccounts.local";
}

export function getApiURL(): string {
	if (process.env.REACT_APP_API_URL) {
		return process.env.REACT_APP_API_URL;
	}
	return "http://app.gopos.local";
}

export function getTokenClientId(): string {
	if (process.env.REACT_APP_CLIENT_ID) {
		return process.env.REACT_APP_CLIENT_ID;
	}

	return "16a056f6-b6f6-45f1-bd22-4561df5bc7c6";
}

export function getTokenClientSecret(): string {
	if (process.env.REACT_APP_CLIENT_SECRET) {
		return process.env.REACT_APP_CLIENT_SECRET;
	}

	return "aab7ad66-c79d-11eb-b8bc-0242ac130003";
}

export function getSentryDSN() {
	if (process.env.REACT_APP_SENTRY_DSN) {
		return process.env.REACT_APP_SENTRY_DSN;
	}

	return "";
}

export function getGoogleAnalyticsId(): string | undefined {
	if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
		return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
	}
	return undefined;
}

export function getNotificationTemplate(): string | undefined {
	if (process.env.REACT_APP_NOTIFICATION_TEMPLATE) {
		return process.env.REACT_APP_NOTIFICATION_TEMPLATE;
	}
	return undefined;
}

export function getCreatingDeliveryZones(): string | undefined {
	if (process.env.REACT_APP_CREATING_DELIVERY_ZONES) {
		return process.env.REACT_APP_CREATING_DELIVERY_ZONES;
	}
	return undefined;
}
