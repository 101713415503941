import React, { FC, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
	FILTER_VALUE_SEPARATOR,
	fullNumberFilterOptions,
	getLimitedOptions,
	numberFilterOptions,
} from "../../services";
import { FilterCondition, ListConfigFilter, ListConfigFilterOption, ListSelectedFilter } from "../../services/types";

interface State {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	onChange: (condition: FilterCondition, selectedFilters: string) => void;
}

const ListFilterNumber: FC<State> = (props) => {
	const { t } = useTranslation();
	const opts = props.filter.options;
	let options = opts
		? numberFilterOptions(t)
				.filter((item) => opts?.indexOf(item.id) !== -1)
				.sort((a, b) => opts.indexOf(a) - opts.indexOf(b))
		: numberFilterOptions(t);
	options = getLimitedOptions(
		fullNumberFilterOptions(t),
		options.map((o) => o.id as FilterCondition),
		props.filter.typeOptions,
		props.filter.extraOptions
	);
	const [value, setValue] = useState(
		props.selectedFilter && props.selectedFilter.value ? props.selectedFilter.value : ""
	);
	const [type, setType] = useState<FilterCondition>(
		props.selectedFilter.condition
			? props.selectedFilter.condition
			: options.find((o) => o.id === "gt")
			? "gt"
			: ("" as FilterCondition)
	);
	const [rangeFromValue, setRangeFromValue] = useState(
		props.selectedFilter && props.selectedFilter.value
			? props.selectedFilter.value.split(FILTER_VALUE_SEPARATOR)[0]
			: ""
	);
	const [rangeToValue, setRangeToValue] = useState(
		props.selectedFilter && props.selectedFilter.value
			? props.selectedFilter.value.split(FILTER_VALUE_SEPARATOR)[1]
			: ""
	);

	const renderNumberValue = (value: string): string => {
		value = value.replace(",", ".");
		if (value.charAt(0) === ".") {
			value = `0${value}`;
		}
		if (value.length > 4 && value.charAt(value.length - 4) === ".") {
			value = value.slice(0, -1);
		}
		return value;
	};

	const onInputClick = (value: string, evt?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		evt?.stopPropagation();
		onChangeType(value as FilterCondition);
	};

	const onChangeStringValue = (value: string) => {
		setValue(value);
		props.onChange(type, value);
	};

	const onChangeValue = (value: string) => {
		value = renderNumberValue(value);
		setValue(value);
		props.onChange(type, value);
	};
	const onChangeType = (newType: FilterCondition) => {
		setType(newType);

		if (type === "a" || type === "u" || newType === "a" || newType === "u") {
			props.onChange(newType, "");
			setRangeFromValue("");
			setRangeToValue("");
			setValue("");
		} else
			newType !== "bt"
				? props.onChange(newType, value)
				: (rangeFromValue !== "" || rangeToValue !== "") &&
				  props.onChange(newType, `${rangeFromValue}${FILTER_VALUE_SEPARATOR}${rangeToValue}`);
	};

	const onChangeRangeFromValue = (value: string) => {
		value = renderNumberValue(value);
		setRangeFromValue(value);
		props.onChange(type, `${value}${FILTER_VALUE_SEPARATOR}${rangeToValue}`);
	};

	const onChangeRangeToValue = (value: string) => {
		value = renderNumberValue(value);
		setRangeToValue(value);
		props.onChange(type, `${rangeFromValue}${FILTER_VALUE_SEPARATOR}${value}`);
	};

	const renderOption = (option: ListConfigFilterOption) => {
		return (
			<>
				<Dropdown.Item
					key={`type_${props.id}_${option.id}`}
					onClick={() => onChangeType(option.id as FilterCondition)}
				>
					<Form.Check
						type="radio"
						className="form-check"
						label={option.name}
						name={`type_${props.id}_${option.id}`}
						id={`type_${props.id}_${option.id}`}
						value={option.id}
						checked={type === option.id}
						onClick={(e) => onInputClick(e.currentTarget.value as FilterCondition, e)}
					/>
				</Dropdown.Item>
				{type === option.id && type === "e" && (
					<Form.Control
						type="number"
						className="filter-input"
						value={value}
						onChange={(e) => onChangeStringValue(e.currentTarget.value)}
					/>
				)}
				{type === option.id && type !== "bt" && type !== "e" && type !== "u" && type !== "a" && (
					<Form.Control
						type="number"
						className="filter-input"
						value={value}
						onChange={(e) => onChangeValue(e.currentTarget.value)}
					/>
				)}
				{type === option.id && type === "bt" && (
					<>
						<Form.Control
							type="number"
							className="filter-input"
							value={rangeFromValue}
							onChange={(e) => onChangeRangeFromValue(e.currentTarget.value)}
						/>
						<span className="range-filter-option">-</span>
						<Form.Control
							type="number"
							value={rangeToValue}
							onChange={(e) => onChangeRangeToValue(e.currentTarget.value)}
						/>
					</>
				)}
			</>
		);
	};

	return (
		<>
			<Form.Group className="form-group">
				{options.map(renderOption)}
				{props.id.includes("+") && (
					<>
						<Dropdown.Item
							key={`type_${props.id}_any`}
							onClick={() => onChangeType("a" as FilterCondition)}
						>
							<Form.Check
								type="radio"
								className="form-check"
								label={t("lib:go_list.filters.any")}
								name={`type_${props.id}_a`}
								id={`type_${props.id}_a`}
								value={"a"}
								checked={type === "a"}
								onClick={(e) => onInputClick("a", e)}
							/>
						</Dropdown.Item>
						<Dropdown.Item
							key={`type_${props.id}_unknown`}
							onClick={() => onChangeType("u" as FilterCondition)}
						>
							<Form.Check
								type="radio"
								className="form-check"
								label={t("lib:go_list.filters.unknown")}
								name={`type_${props.id}_u`}
								id={`type_${props.id}_u`}
								value={"u"}
								checked={type === "u"}
								onClick={(e) => onInputClick("u", e)}
							/>
						</Dropdown.Item>
					</>
				)}
			</Form.Group>
		</>
	);
};

export default ListFilterNumber;
