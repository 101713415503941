import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import OrganizationEmployeesIndexEditPage from "./pages/Edit";
import OrganizationEmployeesIndexIndexPage from "./pages/Index";
import OrganizationEmployeesIndexNewPage from "./pages/New";
import OrganizationEmployeesIndexPreviewPage from "./pages/Preview";

const OrganizationEmployeesIndexPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/`} exact={true} component={OrganizationEmployeesIndexIndexPage} />
				<Route path={`${props.match.url}/new`} exact={true} component={OrganizationEmployeesIndexNewPage} />
				<Route path={`${props.match.url}/:employeeId/edit`} component={OrganizationEmployeesIndexEditPage} />
				<Route path={`${props.match.url}/:employeeId`} component={OrganizationEmployeesIndexPreviewPage} />
			</Switch>
		</div>
	);
};
export default OrganizationEmployeesIndexPage;
