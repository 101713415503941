import { AvailabilityApi } from "go-component/services/types";
import { ApiError, MoneyApi, MoneyApiForm } from "go-core/api/types";
import { ReportApi } from "go-report/core/services/types";
import { ImageApi, OrganizationRoleApi } from "go-security/services/Api/typesSecurity";
import { CustomFieldEntityApi, CustomFieldTemplateApi, SegmentApi } from "go-segment/services/types";
import {
	AddressApi,
	CoordinatesApi,
	ImageLinkApi,
	OrganizationApi,
	SearchSelectApi,
	VenueSettingsItemApi,
} from "../types";

export type AppResourceType =
	| "ITEM"
	| "ITEM_GROUP"
	| "CATEGORY"
	| "MENU"
	| "MODIFIER_GROUP"
	| "AVAILABILITY"
	| "MENU_PRICE_LIST"
	| "PAYMENT_METHOD"
	| "DISCOUNT"
	| "VENUE_ROLE"
	| "DIRECTION"
	| "POINT_OF_SALE"
	| "WORKPLACE"
	| "EMPLOYEE"
	| "CUSTOM_FIELD_TEMPLATE_OPTION"
	| "CLIENT_GROUP"
	| "SEGMENT"
	| "CUSTOM_FIELD_TEMPLATE"
	| "PRINTOUT_TEMPLATE";

export interface ApplicationUpdateApi {
	id: number;
	name: string;
	settings: Record<string, any>;
}

export interface ApplicationApi {
	status: string;
	id: number;
	provider: string;
	name: string;
	settings: Record<string, any>;
	link: string;
	last_export_menu: string;
	last_activity: string;
	inactive_since: string;
	errors: ApplicationError[];
	dangers: ApplicationError[];
}

export interface ApplicationPreInstallApi {
	services: ApplicationServiceApi[];
}

export interface ApplicationServiceApi {
	id: string;
	name: string;
}

export interface ApplicationError extends ApiError {
	app_link_id: number;
	duplicate_value?: string;
}

export enum ValidityType {
	DEFAULT = "DEFAULT",
	TODAY = "TODAY",
	NUMBER_OF_DAYS = "NUMBER_OF_DAYS",
	PERIOD = "PERIOD",
}

interface AppLinkApiItemFormItemApi {
	item_id: number;
	item_name: string;
}

export interface AppLinkApiItemForm extends AppLinkApiItem {
	item?: AppLinkApiItemFormItemApi | null;
	valid_range?: string;
}

export interface AppLinkApiItem {
	item_id?: number;
	item_name?: string;
	provide_code_manually: boolean;
	validity_type: ValidityType;
	valid_to_time?: string | null;
	number_of_days?: number;
	valid_from?: string | null;
	valid_to?: string | null;
}

export interface AppLinkApi {
	id: number;
	external_name: string;
	external_id: string;
	reference_id: string;
	skipped: boolean;
	resource_id: number;
	resource_type: AppResourceType;
	updated_at: string;
	created_at: string;
	imported_at: string;
	exported_at: string;
	used: boolean;
	status: string;
	parent_resource_id?: number;
	resource_name: string;
	app: ApplicationApi;
	resource?: Record<string, any>;
	location?: boolean;
	link?: string;
	label?: string;
	provider: string;
	items?: AppLinkApiItemForm[];
}

export interface ItemAppLinkApi {
	item: ItemApi;
	apps: LinkedAppApi[];
}

export interface LinkedAppApi {
	app: ApplicationApi;
	app_link: AppLinkApi;
}

export interface ApplicationTemplateApi {
	provider: string;
	short_description: string;
	long_description: string;
	help_description: string;
	owner: string;
	owner_link: string;
	logo_link: string;
	documentation_link: string;
	rules_link: string;
	privacy_policy_link: string;
	tags?: string[];
}

export interface TaxApi {
	amount: number;
	id: number;
	code: string;
	name: string;
	status: string;
}

export interface VenueSettingsApi {
	settings: VenueSettingsItemApi[];
	kds_settings: VenueSettingsKdsItemApi[];
	printout_settings: VenueSettingsPrintoutTemplateApi[];
	direction_printout_id?: number;
	direction_printout?: PrintoutTemplateApi;
	weight_barcodes: WeightBarcodeApi[] | string[];
	supported_preparation_statuses: VenueSettingsSupportedPreparationStatusesApi;
	preparation_statuses: OrderPreparationStatusName[];
	close_order_terminal?: TerminalApi;
	kiosk: VenueSettingsKioskApi;
}

export interface VenueSettingsKioskApi {
	font_color: string;
	accent_color: string;
	background_color: string;
	curve_color: string;
	logo_image?: ImageApi;
	logo_image_link?: ImageLinkApi;
	cover_image?: ImageApi;
	cover_image_link?: ImageLinkApi;
	header_image?: ImageApi;
	header_image_link?: ImageLinkApi;
	translations: VenueSettingsKioskTranslationApi[];
}

export interface VenueSettingsKioskTranslationApi {
	start_order_header?: string;
	start_order_description?: string;
	edit_order_header?: string;
	edit_order_description?: string;
	locale: string;
}

export interface VenueSettingsSupportedPreparationStatusesApi {
	default_values: OrderPreparationStatusName[];
	values: OrderPreparationStatusName[];
}

export interface WeightBarcodeApi {
	type: string;
	value: string;
}

export interface PrintoutTemplateApi {
	status: string;
	id: number;
	name: string;
	text_size: string;
	items_sort: string;
	merge_items: string;
	print_point_of_sale: boolean;
	print_direction: boolean;
	print_client: boolean;
	print_address: boolean;
	print_comment: boolean;
	print_table: boolean;
	print_number_of_guests: boolean;
	print_takeaway: boolean;
	print_number: boolean;
	print_employee: boolean;
	print_delivery_employee: boolean;
	print_course: boolean;
	print_seat: boolean;
	print_prices: boolean;
	print_item_comment: boolean;
	print_included_additions: boolean;
	entities?: PrintoutTemplateEntityApi[];
	print_origin_of_item: boolean;
	print_payment_method: boolean;
	print_extra_space: boolean;
	print_storno: boolean;
	print_items_on_course_printout: boolean;
}

export interface PrintoutTemplateEntityApi {
	entity_id: number;
	type: "ITEM_GROUP" | "CATEGORY";
	split_type: "ENABLED" | "DISABLED";
	entity: PrintoutTemplateEntityEntityApi;
}

export interface PrintoutTemplateEntityEntityApi {
	id: number;
	name: string;
	image_link: ImageLinkApi;
	color: string;
	label: string;
}

export interface CompanyInfoApi {
	name: string;
	tax_id_no: string;
	business_register_no: string;
	bank_accounts: BankAccountApi[];
	address_country: string;
	address_zip_code: string;
	address_city: string;
	address_build_nr: string;
	address_flat_nr: string;
	address_street: string;
	company_name: string;
	contact_email: string;
	company_phone_number: string;
	company_other: string;
	company_country: string;
	company_zip_code: string;
	company_city: string;
	company_street: string;
	company_build_nr: string;
	company_flat_nr: string;
}

export interface ContactApi {
	email: string;
	phone_number: string;
	others: string;
}

export interface BankAccountApi {
	name: string;
	number: string;
	main: boolean;
}

export interface VenueRoleApi {
	id: number;
	unique_id: string;
	role_name: string;
	is_default?: boolean;
	deleted?: boolean;
}

export interface DefaultCommentApi {
	id: number;
	high_priority: boolean;
	content: string;
	type: string;
	status: string;
}

export interface DirectionApi {
	id: number;
	name: string;
	status: string;
	devices: DeviceApi[];
}

export interface RoomApi {
	id: number;
	name: string;
	status: string;
	number_of_seats: number;
	tables: RoomTableApi[];
	elements: RoomElementApi[];
	background_link?: ImageLinkApi;
	background?: ImageApi;
	color?: string;
	position: string;
	theme: string;
}

export interface RoomElementApi {
	id?: number;
	type: "RECTANGLE" | "TOILET";
	name: string;
	color: string;
	position_top: number;
	position_left: number;
	height: number;
	width: number;
}

export interface RoomsUpdate {
	rooms: RoomApi[];
}

export enum TerminalType {
	POS = "POS",
	POS_MOBILE = "POS_MOBILE",
	POS_WORK_TIME = "POS_WORK_TIME",
}

export interface TerminalApi {
	id: number;
	name: string;
	version: string;
	created_at: string;
	details: string;
	status: string;
	updated_at: string;
	terminal_id: number;
	type: TerminalType;
	ip_address: string;
	terminal_unique_id: string;
	master: boolean;
	app_version: string;
	settings: TerminalSettingsApi[];
	logs: TerminalLogsApi[];
	system_type: string;
	web_name: string;
	mac_address: string;
	up_to_date: boolean;
	diagnoses: TerminalDiagnoseApi[];
	app_version_code?: number;
}

export interface DeviceApi {
	id: number;
	name: string;
	type: string;
	status: string;
	parameters: DeviceParameterApi[];
	terminal: DeviceTerminalApi;
	fiscal_receipt_printer_terminals: DeviceTerminalApi[];
	receipt_printer_terminals: DeviceTerminalApi[];
	payment_terminal_terminals: DeviceTerminalApi[];
	weight_terminals: DeviceTerminalApi[];
	directions: DeviceDirectionApi[];
	interface: string;
	terminal_uid: string;
}

export interface TerminalSettingsApi {
	id: number;
	setup: string;
	status: string;
	name: string;
}

export interface DeviceTerminalApi {
	id: number;
	name: string;
}

export interface DeviceDirectionApi {
	id: number;
	name: string;
	status: string;
}

export interface DeviceParameterApi {
	name: string;
	value?: any;
}

export interface TerminalLogsApi {
	created_at: string;
	value: string;
	type: string;
}

export interface PointOfSaleApi {
	id: number;
	status: string;
	name: string;
	default_direction: DirectionApi | undefined;
	default_direction_id: number;
	rooms: RoomApi[];
}

export interface PaymentMethodApi {
	id: number;
	name: string;
	limit: MoneyApi;
	outflow_type: string;
	receipt_type: string;
	auto_closed: boolean;
	print_receipt: boolean;
	open_cash_drawer: boolean;
	price_wasted: boolean;
	print_copy: boolean;
	default: boolean;
	roles: VenueRoleApi[];
	status: string;
	custom_field_templates: PaymentMethodCustomFieldTemplateApi[];
	min_amount: MoneyApi;
}

export interface PaymentMethodCustomFieldTemplateApi {
	slug: string;
	name: string;
}

export interface OrganizationInfoApi {
	name: string;
	category: string;
	currency: string;
	description: string;
	timezone: string;
	languages: string[];
	place_address?: AddressApi;
}

export interface OrganizationAddressApi {
	address: AddressApi;
	coordinates: CoordinatesApi;
}

export enum PrintoutAdditionsPrintMethod {
	NO_ADDITIONS = "NO_ADDITIONS",
	IN_DESCRIPTION = "IN_DESCRIPTION",
	AS_SEPARATE_ITEMS = "AS_SEPARATE_ITEMS",
}

export interface ReceiptApi {
	print_comment: boolean;
	print_employee: boolean;
	print_discount: boolean;
	print_organization: boolean;
	print_company: boolean;
	print_address: boolean;
	print_qr_code: boolean;
	custom_text: string;
	custom_header: string;
	custom_fiscal_text: string;
	facebook: string;
	instagram: string;
	website: string;
	logo_link?: ImageLinkApi;
	logo: ImageApi;
	luminance_threshold: number;
	additions_print_method: PrintoutAdditionsPrintMethod;
}

export interface TerminalDiagnoseApi {
	occurred_at: string;
	message: string;
}

export interface TransactionModel {
	id: number;
	status: string;
	amount: MoneyApi;
	type: string;
	payment_method_id: number;
	payment_method: PaymentMethodApi;
	payment_method_name: string;
	contexts: TransactionContextApi[];
	tender_amount: MoneyApi;
	paid_at: string;
	uid: string;
	inconsistent_reference_id: boolean;
}

export interface UpdateTransactionApi {
	payment_method_id: number;
	transaction_status: UpdateTransactionStatus;
	paid_at: string;
}

export enum UpdateTransactionStatus {
	"NEW" = "NEW",
	"SUCCESS" = "SUCCESS",
}

export interface ClientApi {
	id: number;
	name: string;
	client_group: ClientGroupApi | undefined;
	client_group_id: number;
	address_street: string;
	address_city: string;
	address_flat_nr: string;
	address_build_nr: string;
	address_zip_code: string;
	address_country: string;
	contact_phone_number: string;
	contact_email: string;
	contact_other: string;
	tax_id_no: string;
	birthday: string;
	nin_no: string;
	id_card_number: string;
	id_card_issued_at: string;
	id_card_issuing_authority: string;
	card_code: string;
	card_number: string;
	card_expires_at: string;
	wallet_money: MoneyApi;
	walled_locked_money: MoneyApi;
	wallet_allow_debit: boolean;
	status: string;
	flat_rate_farmer: boolean;
	last_activity: string;
	transactions: TransactionApi[];
	invoices: ClientInvoiceApi[];
	daily_transactions: ClientDailyTransactionApi[];
	weekly_transactions: ClientWeeklyTransactionApi[];
	monthly_transactions: ClientMonthlyTransactionApi[];
	avatar?: ImageApi;
	avatar_link?: ImageLinkApi;
	custom_fields: CustomFieldEntityApi[];
}

export interface ClientInvoiceApi {
	id: number;
	document_type: string;
	status: string;
	dates: InvoiceDatesApi;
	number: string;
}

export interface ClientDailyTransactionApi {
	day: string;
	count: number;
	total_price: MoneyApi;
}

export interface ClientWeeklyTransactionApi {
	start_at: string;
	end_at: string;
	count: number;
	total_price: MoneyApi;
}

export interface ClientMonthlyTransactionApi {
	month: string;
	count: number;
	total_price: MoneyApi;
}

export interface ClientGroupApi {
	id: number;
	name: string;
	daily_discount_limit: MoneyApi;
	discounts: ClientGroupDiscountApi[];
	status: string;
	unique_id: string;
}

export interface ClientGroupDiscountApi {
	id: number;
	name: string;
}

export interface EmployeeApi {
	id: number;
	name: string;
	venue_role: OrganizationRoleApi;
	venue_role_id: string;
	code: string;
	card_code: string;
	alias: string;
	hourly_rate: MoneyApiForm;
	workplace: WorkplaceApi;
	workplace_id: number;
	workplaces: EmployeeWorkplaceApi[];
	address_street: string;
	address_city: string;
	address_flat_nr: string;
	address_build_nr: string;
	address_zip_code: string;
	address_country: string;
	contact_phone_number: string;
	contact_email: string;
	contact_other: string;
	hired: boolean;
	status: string;
	avatar_link?: ImageLinkApi;
	avatar?: ImageApi;
	text: string;
	color?: string;
	custom_fields: CustomFieldEntityApi[];
}

export interface EmployeeInfoApi {
	last_check_in: string;
	is_online: boolean;
	daily_work_times: EmployeeDailyWorktimeApi[];
	monthly_work_times: EmployeeMonthlyWorkTimeApi[];
	weekly_work_times: EmployeeWeeklyWorktimeApi[];
	work_times: WorkTimeApi[];
	working_since: string;
}

export interface EmployeeTransactionApi {
	id: number;
	status: string;
	amount: MoneyApi;
	created_at: string;
	paid_at: string;
	payment_method_id: number;
	type: string;
	payment_method: PaymentMethodApi;
	contexts: TransactionContextApi[];
	tender_amount: MoneyApi;
}

export interface EmployeeDailyWorktimeApi {
	day: string;
	duration_in_minutes: number;
	to_pay: MoneyApi;
}

export interface EmployeeWeeklyWorktimeApi {
	start_at: string;
	end_at: string;
	to_pay: MoneyApi;
	duration_in_minutes: number;
}

export interface EmployeeMonthlyWorkTimeApi {
	month: string[];
	duration_in_minutes: number;
	to_pay: MoneyApi;
}

export interface WorkplaceApi {
	id: number;
	name: string;
	type: string;
	updated_at: string;
	status: string;
}

export interface EmployeeWorkplaceApi {
	workplace_id: number;
	workplace: WorkplaceApi;
	hourly_rate: MoneyApi;
}

export interface ReservationApi {
	id: number;
	room_table: RoomTableApi;
	room_table_id: number;
	date_from: string;
	date_to: string;
	number_of_seats: number;
	client: ClientApi;
	client_id: number;
	room: RoomApi;
	room_id: number;
	description: string;
	status: string;
	label: string;
	room_name: string;
}

export interface RoomTableApi {
	id?: number;
	name: string;
	type: RoomTypeApi;
	color: string;
	number: number;
	number_of_seats: number;
	position_top: number;
	position_left: number;
	height: number;
	width: number;
	status: string;
}

export enum RoomTypeApi {
	ROUND = "ROUND",
	SQUARE = "SQUARE",
	RECTANGLE = "RECTANGLE",
	HOKER = "HOKER",
}

export enum ApplicationMode {
	AUTOMATIC = "AUTOMATIC",
	MANUAL = "MANUAL",
}

export interface DiscountApi {
	id: number;
	name: string;
	hidden: boolean;
	editable: boolean;
	tip_applied_after_discount: boolean;
	tip_item_id?: number;
	volume: number;
	status: string;
	roles: DiscountRoleApi[];
	availability_id?: number;
	availability: AvailabilityApi | undefined;
	payment_method: PaymentMethodApi | undefined;
	payment_method_id: number;
	discount_type: string;
	entities: DiscountEntityApi[];
	tip: ItemApi;
	price_sort_type: boolean | string;
	enforce_positive_order_price: boolean;
	conditions?: DiscountConditionApi[];
	application_mode: ApplicationMode;
}

export interface DiscountConditionApi {
	item_id?: number | null;
	item?: { name: string; item_group: { name: string } } | null;
	item_group_id?: number | null;
	item_group?: { name: string } | null;
	category_id?: number | null;
	category?: { name: string } | null;
	operator: DiscountConditionOperator | null;
	type: DiscountConditionType | null;
	value: string | number | null;
}

export enum DiscountConditionOperator {
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS",
	GREATER_THAN = "GREATER_THAN",
	GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
	LESS_THAN = "LESS_THAN",
	LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
}

export enum DiscountConditionType {
	ORDER_TOTAL_PRICE = "ORDER_TOTAL_PRICE",
	ORDER_ITEM_COUNT = "ORDER_ITEM_COUNT",
	ORDER_TYPE = "ORDER_TYPE",
	ORDER_SOURCE = "ORDER_SOURCE",
	GUEST_COUNT = "GUEST_COUNT",
}

export interface CategoryApi {
	id: number;
	uid: string;
	name: string;
	discountable: boolean;
	image_link?: ImageLinkApi;
	image?: ImageApi;
	color?: string;
	status: string;
	description: string;
	label?: string;
	points_of_sale: EntityPointOfSaleApi[];
	translations: TranslationApi[];
	direction_id?: number;
	direction: DirectionApi;
	image_links?: ImageLinksApi[];
}

export interface TranslationApi {
	locale: string;
	name: string;
	description?: string;
}

export interface DiscountEntityApi {
	id: number;
	entity_id: number;
	type: string;
	discount_type: string;
	volume: number;
	parent_id?: number;
	parent_name?: string;
	entity: Record<string, any>;
}

export interface DiscountRoleApi {
	id: number;
	organization_role: OrganizationRoleApi | undefined;
	organization_role_id: number;
}

export interface MenuApi {
	id: number;
	name: string;
	status: string;
	active: boolean;
	is_active?: boolean;
	pages: MenuPageApi[];
	is_default: boolean;
	type: MenuType;
	image_tag?: string;
}

export type MenuType = "GRID" | "LIST";

export interface MenuPageApi {
	id: number;
	name: string;
	position: number;
	items: MenuItemApi[];
	appended?: boolean;
	internalId?: string;
	translations?: TranslationApi[];
	image_link?: ImageLinkApi;
	image?: ImageApi;
}

export interface MenuItemApi {
	id: number;
	context_type: string;
	context_id: number;
	position: number;
	entity: MenuItemEntityApi;
	internalId?: string;
}

export interface MenuItemEntityApi {
	id: number;
	name: string;
	image_link: ImageLinkApi;
	parent_id?: number;
	parent_name?: string;
	price?: MoneyApi;
	color?: string;
	status?: string;
	label?: string;
}

export interface EntityPointOfSaleApi {
	id: number | undefined;
	point_of_sale_id: number;
	direction_id: number | undefined;
	direction?: DirectionApi;
	point_of_sale?: PointOfSaleApi;
	visibility: string | boolean;
}

export interface ItemGroupApi {
	id: number;
	category_id?: number;
	description: string;
	category: CategoryApi;
	tax_id?: number;
	tax?: TaxApi;
	status: string;
	name: string;
	color?: string;
	label?: string;
	image?: ImageApi;
	image_link?: ImageLinkApi;
	items: ItemApi[];
	points_of_sale?: EntityPointOfSaleApi[];
	custom_fields: CustomFieldEntityApi[];
	roles: VenueRoleApi[];
	translations: TranslationApi[];
	source_item_group_id: number;
	image_links?: ImageLinksApi[];
	type: ItemGroupType;
	stock_info: { stock_amount: number };
	options?: ModifierGroupOptionApi[];
	direction: undefined;
	direction_id: undefined;
}

export type ItemGroupType = "PRODUCT" | "MODIFIER" | "PACKAGE";

export interface ImageLinksApi {
	tag: string;
	image?: ImageApi;
	image_link?: ImageLinkApi;
}

export enum ItemOrderType {
	DELIVERY = "DELIVERY",
	DINE_IN = "DINE_IN",
	ROOM_SERVICE = "ROOM_SERVICE",
	PICK_UP = "PICK_UP",
}

export interface ItemApi {
	id: number;
	status: string;
	name: string;
	price: MoneyApiForm;
	sku: string;
	availability_id?: number;
	position: number;
	availability?: AvailabilityApi;
	weighted_type: "DISABLED" | "WEIGHTED" | "WEIGHTED_AFTER";
	open_price: boolean;
	discountable: boolean;
	stock_info: ItemStockInfoApi;
	barcodes: string[];
	modifier_groups: ItemModifierGroupApi[];
	quantity_info: QuantityInfoApi;
	translations: TranslationApi[];
	item_group?: ItemParentApi;
	item_group_id: string;
	description: string;
	suspension?: ItemSuspensionApi;
	category: CategoryApi;
	suspended: boolean;
	source_item_id?: number;
	order_types: ItemOrderType[];
	not_sold_at?: string;
	points_of_sale: ItemFormPointOfSaleApi[];
	direction?: DirectionApi;
	direction_id?: number;
}

export interface ItemFormPointOfSaleApi {
	direction_id?: number;
	point_of_sale: {
		id: number;
		status: string;
		name: string;
	};
	visibility: "VISIBLE" | "HIDDEN" | boolean;
	direction?: {
		id: number;
		name: string;
	};
	point_of_sale_id: number;
}

export interface ItemGroupGroupEditingFormProps {
	items: ItemFormApi[];
}

export interface ItemFormApi extends Omit<ItemApi, "direction" | "availability"> {
	full_name: string;
	index: number;
	point_of_sale_visible: boolean;
	points_of_sale: ItemFormPointOfSaleApi[];
	direction?: {
		id: number;
		name: string;
	};
	availability?: {
		id: number;
		name: string;
	};
}

export interface ItemSuspensionApi {
	until?: string;
	reason?: string;
}

export interface ItemParentApi {
	id: number;
	name: string;
	color: string;
	label: string;
	status: string;
	image_link?: ImageLinkApi;
}

export interface ItemModifierGroupApi {
	id?: number;
	modifier_group_id: number;
	name: string;
	position: number;
	quantity_info: QuantityInfoApi;
	options: ItemModifierGroupOptionApi[];
}

export interface ItemModifierGroupOptionApi {
	item_id: number;
	name: string;
	price: MoneyApi;
	quantity_info: QuantityInfoApi;
}

export interface QuantityInfoOverrideApi {
	item_id: string;
	quantity_info: QuantityInfoApi;
}

export interface ItemPriceOverrideApi {
	item_id: string;
	price: MoneyApiForm;
}

export interface QuantityInfoApi {
	max_permitted: string | null;
	min_permitted: string | null;
	default_quantity: string | null;
	charge_above: string | null;
}

export interface ItemStockInfoApi {
	stock_amount: number;
	default_cost: MoneyApi;
	external_cost: MoneyApi;
}

export interface ModifierApi {
	id: number;
	name: string;
	quantity: string;
	price: MoneyApi;
}

export interface ModifierGroupApi {
	id: number;
	item_group_id: number;
	name: string;
	status: string;
	quantity_info: QuantityInfoApi;
	options: ModifierGroupOptionApi[];
	translations: TranslationApi[];
	split: boolean;
	image_link?: Record<string, any>;
	label?: string;
}

export interface ModifierGroupOptionApi {
	id: number;
	item_id: number;
	type: string;
	position: number;
	quantity_info?: QuantityInfoApi;
	price?: MoneyApiForm;
	sub_item?: ItemApi;
	name?: string;
}

export interface ModifierGroupItemApi {
	id: number;
	modifier_id: number;
	quantity: string;
	price: string;
	name: string;
}

export interface MenuPriceListFlatResponseApi {
	items: MenuPriceListFlatApi[];
	price_lists: MenuPriceListApi[];
}

export interface MenuPriceListFlatApi {
	category_id: number;
	category_name: string;
	item_group_id: number;
	item_group_name: string;
	item_id: number;
	item_name: string;
	item_full_name: string;
	modifier_group_id: number;
	modifier_group_name: string;
	sub_item_id: number;
	sub_item_name: string;
	price: MoneyApi;
	default_price: MoneyApi;
	price_list_items: MenuPriceListGroupItemItemApi[];
	index: number;
	sku: string;
	barcodes: string[];
}

export interface MenuPriceListApi {
	id: number;
	name: string;
	status: string;
	availability?: AvailabilityApi;
	availability_id?: number;
	index: number;
}

export interface MenuPriceListGroupItemApi {
	item_id: number;
	name: string;
	price: MoneyApi;
	modifier_groups: MenuPriceListModifierGroupGroupApi[];
	price_list_items: MenuPriceListGroupItemItemApi[];
	default_price: MoneyApi;
	item_group_id: number;
}

export interface MenuPriceListGroupItemItemApi {
	price_list_id: number;
	price: MoneyApiForm;
	checked?: boolean;
	reference_id: string;
}

export interface MenuPriceListModifierGroupGroupApi {
	modifier_group_id: number;
	items: MenuPriceListGroupItemApi[];
	name: string;
}

export interface TagApi {
	id: number;
	name: string;
	priority: string;
	resource_types: string[];
}

export interface PosAccessApi {
	login?: string;
	password?: string;
}

export interface EmployeeActivityApi {
	id: number;
	employee_name: string;
	employee_id: number;
	description: string;
	context: string;
	context_entity: EmployeeActivityContextEntityApi;
	parameters: string;
	type: string;
	created_at: string;
	app_version: string;
	terminal: EmployeeActivityTerminalApi;
	closed_at: string;
	label: string;
}

export interface EmployeeActivityContextEntityApi {
	id: number;
	resource_type: string;
	number: string;
}

export interface EmployeeActivityTerminalApi {
	name: string;
	uid: string;
	id: number;
}

export interface WorkTimeApi {
	id: number;
	start_at: string;
	end_at: string;
	wasted: boolean;
	status: string;
	source: string;
	employee: Record<string, any>;
	employee_id: number;
	workplace: WorkplaceApi;
	workplace_id: number;
	hourly_rate: MoneyApiForm;
	to_pay: MoneyApi;
	updated_at: boolean;
	duration_in_minutes: number;
}

export interface PosReportApi {
	id: number;
	status: string;
	opened_at: string;
	closed_at: string;
	type: string;
	terminal: EmployeeActivityTerminalApi;
	description: string;
	shift_work_report_id: number;
	total_amount: MoneyApi;
	income_amount: MoneyApi;
	start_amount: MoneyApi;
	paid_in_amount: MoneyApi;
	paid_out_amount: MoneyApi;
	sub_reports: PosReportApi[];
	paids: PosReportPaidApi[];
	order_amount: MoneyApi;
	invoice_amount: MoneyApi;
	discount_amount: MoneyApi;
	storno_amount: MoneyApi;
	storno_unsaved_amount: MoneyApi;
	payment_amount: MoneyApi;
	fiscal_receipt_amount: MoneyApi;
	end_amounts: PosReportEndAmountApi[];
	transactions: TransactionApi[];
	payment_methods: PosReportPaymentMethodApi[];
	discounts: PosReportDiscountApi[];
	employees: PosReportEmployeeApi[];
	delivery_employees: PosReportEmployeeApi[];
	invoice_count: number;
	discount_count: number;
	storno_count: number;
	storno_unsaved_count: number;
	payment_count: number;
	employee_count: number;
	fiscal_receipt_count: number;
	order_count: number;
	void_count: number;
	void_amount: MoneyApi;
	employee_salary_amount: MoneyApi;
	currencies: PosReportCurrencyApi[];
	fiscal_printer_report: FiscalPrinterReportApi;
	payment_terminal_report: PaymentTerminalReportApi;
	number_of_guests: number;
	label: string;
}

export interface FiscalPrinterReportApi {
	fiscal_report_count: number;
	order_amount: MoneyApi;
	mismatch: boolean;
}

export interface PaymentTerminalReportApi {
	fiscal_report_count: number;
	order_amount: MoneyApi;
	mismatch: boolean;
}

export interface PosReportCurrencyApi {
	currency: string;
	end_amount_money: MoneyApi;
	end_amounts: PosReportEndAmountApi[];
	income_amount_money: MoneyApi;
	paid_in_amount_money: MoneyApi;
	paid_out_amount_money: MoneyApi;
	payment_methods: PosReportPaymentMethodApi[];
	start_amount_money: MoneyApi;
	total_amount_money: MoneyApi;
}

export interface PosReportPaymentMethodApi {
	name: string;
	id: number;
	receipt_type: string;
	outflow_type: string;
	amount: MoneyApi;
	count: number;
}

export interface PosReportEmployeeApi {
	name: string;
	id: number | string;
	avatar_link: ImageLinkApi;
	payment_methods: PosReportPaymentMethodApi[];
	order_count: number;
	open_order_count: number;
	open_order_amount: MoneyApi;
	total_amount: MoneyApi;
	end_amount: MoneyApi;
	report_paid_amount: MoneyApi;
	paid_in_amount: MoneyApi;
	paid_out_amount: MoneyApi;
	income_amount: MoneyApi;
	to_pay: MoneyApi;
	work_time_duration_in_minutes: number;
	role: VenueRoleApi;
}

export interface PosReportDiscountApi {
	name: string;
	amount: MoneyApi;
	count: number;
	id: number;
	type: string;
}

export interface PosReportEndAmountApi {
	type: string;
	system_amount: MoneyApi;
	actual_amount: MoneyApi;
}

export interface PosReportPaidApi {
	id: number;
	amount: MoneyApi;
	created_at: string;
	description: string;
	number: string;
	client_id: number;
	employee_id: number;
	client: PosReportPaidClientApi;
	is_paid_in: boolean;
	transaction: TransactionApi;
	employee: OrderEmployeeApi;
	pos_report_id: number;
}

export interface PosReportPaidClientApi {
	id: number;
	name: string;
	address: AddressApi;
	contact: ContactApi;
	tax_id_no: string;
}

export interface OrderApi {
	id: number;
	reference_id: string;
	opened_at: string;
	uid: string;
	number: string;
	comment: string;
	created_at: string;
	closed_at: string;
	execution_at: string;
	pos_updated_at: string;
	terminal: OrderTerminalApi;
	closing_terminal: OrderTerminalApi;
	type: string;
	status: string;
	payment_status: string;
	delivery_zone_id: string;
	number_of_guests: number;
	total_price: MoneyApi;
	total_price_tax: MoneyApi;
	total_price_net: MoneyApi;
	sub_total_price: MoneyApi;
	total_paid_price: MoneyApi;
	contact: OrderContactApi;
	employee: OrderEmployeeApi;
	opening_employee: OrderEmployeeApi;
	table: OrderTableApi;
	point_of_sale: OrderPointOfSaleApi;
	source: OrderSourceApi;
	fiscalization: OrderFiscalizationApi;
	report: OrderReportApi;
	active_order_number: number;
	custom_fields: CustomFieldEntityApi[];
	transactions: OrderTransactionApi[];
	promotions: OrderPromotionApi[];
	items: OrderItemApi[];
	tip: MoneyApi;
	delivery_price: MoneyApi;
	activities: EmployeeActivityTerminalApi[];
	cost: OrderCostApi;
	removed_items: OrderItemApi[];
	preparation_status?: OrderPreparationStatusApi;
	invoice: OrderInvoiceApi;
	delivery: OrderDeliveryApi;
	tax_items: OrderTaxItemApi[];
	tax_id_no: string;
	estimated_preparation_at?: string;
	external_links: OrderExternalLinkApi[];
	ereceipt_exists: boolean;
	public_link: string;
	label: string;
	pickup_at?: string;
	delivery_cost?: MoneyApi;
	removed_total_price?: MoneyApi;
	removed_total_price_net?: MoneyApi;
	removed_total_price_tax?: MoneyApi;
	removed_sub_total_price?: MoneyApi;
	removed_tax_items?: OrderTaxItemApi[];
}

export interface UpdateOrderApi {
	number_of_guests: number;
	comment: string;
	table_id: number;
	custom_fields: CustomFieldEntityApi[];
}

export interface PatchOrderApi {
	number_of_guests?: number;
	comment?: string;
	table_id?: number;
	custom_fields?: CustomFieldEntityApi[];
	pickup_at?: string;
	estimated_preparation_at?: string;
}

export interface OrderExternalLinkApi {
	external_id: string;
	external_link: string;
	app_provider: string;
	app_id: number;
}

export interface OrderPreparationStatusApi {
	id: number;
	order_number: string;
	status: OrderPreparationStatusName;
	storno: boolean;
	created_at: string;
}

export enum OrderPreparationStatusName {
	NEW = "NEW",
	IN_PROGRESS = "IN_PROGRESS",
	READY = "READY",
	PACKED = "PACKED",
	RECEIVED = "RECEIVED",
	DELIVERED = "DELIVERED",
}

export interface OrderTaxItemApi {
	tax_id: number;
	tax_name: string;
	tax_code: string;
	tax_amount: number;
	total_price_gross: MoneyApi;
	total_price_tax: MoneyApi;
	total_price_net: MoneyApi;
}

export interface OrderInvoiceApi {
	id: number;
	number: string;
	document_type?: string;
	type?: string;
}

export interface OrderCostApi {
	id: number;
	external_cost: MoneyApi;
	default_cost: MoneyApi;
}

export interface TransactionApi {
	id: number;
	status: string;
	amount: MoneyApi;
	type: string;
	external_id: string;
	created_at: string;
	paid_at: string;
	infos: TransactionInfoApi[];
	payment_method_id: number;
	payment_method: PaymentMethodApi;
	payment_method_name: string;
	sub_transactions: SubTransactionApi[];
	contexts: TransactionContextApi[];
	tender_amount: MoneyApi;
	uid: string;
	description: string;
	closed_at: string;
	label: string;
	opened_at: string;
	inconsistent_reference_id: boolean;
}

export interface SubTransactionApi {
	id: number;
	status: string;
	amount: MoneyApi;
	type: string;
	external_id: string;
	created_at: string;
	paid_at: string;
	infos: TransactionInfoApi[];
	payment_method_id: number;
	payment_method: PaymentMethodApi;
	payment_method_name: string;
	contexts: TransactionContextApi[];
}

export interface OrderContactApi {
	id: number;
	name: string;
	source: string;
	reference_id: string;
	tax_id_no: string;
	address: AddressApi;
	contact: ContactApi;
	avatar_link?: ImageLinkApi;
	phone_number: string;
	link: string;
}
export interface TransactionContextApi {
	id: number;
	number: string;
	type: "ORDER" | "REPORT_PAID" | "INVOICE" | "CORRECTION" | "ADVANCE" | "PROFORMA";
}

export interface TransactionInfoApi {
	type: string;
	data: string;
}

export interface OrderDeliveryApi {
	delivery_zone: Record<string, string>;
	address: AddressApi;
	coordinates: Record<string, number>;
	delivery_employee: OrderDeliveryEmployeeApi;
	delivery_pickup_in_minutes?: number;
	external_id?: string;
	cost?: MoneyApi;
	provider?: OrderDeliveryProvider;
}

export type OrderDeliveryProvider = "WOLT_DRIVE";

export interface OrderDeliveryEmployeeApi {
	id: number;
	reference_id: string;
	name: string;
}

export interface OrderTableApi {
	name: string;
	id: number;
	room_name: string;
}

export interface OrderFiscalizationApi {
	status: OrderFiscalizationStatus;
	reference_id: string;
	email: string;
	delivery?: OrderFiscalizationDeliveryApi;
	created_at: string;
	fiscalized_at?: string;
	type: OrderFiscalizationType;
	order_receipts: OrderFiscalizationOrderReceiptApi[];
	number?: string;
	duplicates_count: number;
}

export interface OrderFiscalizationOrderReceiptApi {
	order_receipt_uid: string;
	fiscalized_at: string;
	delivered_at: string;
	email?: string;
	phoneNumber?: string;
	delivery_type: OrderFiscalizationOrderReceiptDeliveryType;
}

export enum OrderFiscalizationDeliveryType {
	EMAIL = "EMAIL",
	SMS = "SMS",
	PRINTOUT = "PRINTOUT",
}

export interface OrderFiscalizationDeliveryApi {
	type: OrderFiscalizationDeliveryType;
	delivered_at: string;
	email: string;
	phone_number: string;
}

export enum OrderFiscalizationType {
	ERECEIPT = "ERECEIPT",
	FISCAL_RECEIPT = "FISCAL_RECEIPT",
	NONE = "NONE",
}

export enum OrderFiscalizationOrderReceiptDeliveryType {
	EMAIL = "EMAIL",
	SMS = "SMS",
	PRINTOUT = "PRINTOUT",
}

export enum OrderFiscalizationStatus {
	FISCALIZED_RECEIVED = "FISCALIZED_RECEIVED",
	FISCALIZED = "FISCALIZED",
	NOT_FISCALIZED = "NOT_FISCALIZED",
	ERROR = "ERROR",
	UNKNOWN = "UNKNOWN",
}

export interface FiscalizeOrderFormProps {
	type?: OrderFiscalizationType;
	email?: string;
	terminal_id?: number;
	payment_method_id: number;
	employee_id?: number | string;
	paid: boolean;
}

export interface OrderReportApi {
	shift_work_report_id: number;
	shift_work_report_uid: string;
	drawer_report_id: number;
	drawer_report_uid: string;
	drawer_report: PosReportApi;
	shift_work_report: PosReportApi;
}

export interface OrderTransactionApi {
	payment_method: PaymentMethodApi;
	payment_method_name: string;
	payment_method_id: number;
	transaction_uid: string;
	status: string;
	price: MoneyApi;
	paid_at: string;
	created_at: string;
	id: number;
	type: string;
	tender_price: MoneyApi;
	change_price: MoneyApi;
	context: string;
	context_type: string;
	amount: MoneyApi;
	tender_amount: MoneyApi;
	attached: boolean;
}

export interface OrderPromotionApi {
	promotion_id: number;
	id: number;
	name: string;
	type: string;
	percent: number;
	value: MoneyApi;
	used: MoneyApi;
	items: OrderPromotionItemApi[];
}

export interface OrderPromotionItemApi {
	id: number;
	item_group_id: number;
	used: MoneyApi;
	image_link: ImageLinkApi;
	label: string;
	color: string;
	name: string;
}

export interface OrderItemApi {
	uid: string;
	id: number;
	comment: string;
	quantity: number;
	base_unit_price: MoneyApi;
	base_total_price: MoneyApi;
	unit_price: MoneyApi;
	total_price: MoneyApi;
	sub_total_price: MoneyApi;
	custom_price: MoneyApi;
	open_price: MoneyApi;
	removed: boolean;
	parent_uid: string;
	product: OrderProductApi;
	direction: OrderDirectionApi;
	tax: OrderTaxApi;
	course: number;
	order_number: number;
	seat: number;
	created_at: string;
	items: OrderItemApi[];
	employee: OrderEmployeeApi;
	promotions?: OrderPromotionItemApi[];
	preparation_status: OrderItemPreparationStatusApi;
	cost: OrderCostApi;
	total_cost: OrderCostApi;
}

export interface OrderItemPreparationStatusApi {
	id: number;
	order_number: string;
	status: string;
	storno: boolean;
	created_at: string;
}

export interface OrderProductApi {
	id: number;
	name: string;
	item_group_id: number;
	color: string;
	label: string;
	image_link: ImageLinkApi;
}

export interface OrderTaxApi {
	uid: string;
	name: string;
	code: string;
	rate: number;
}

export interface OrderDirectionApi {
	id: number;
	name: string;
}

export interface OrderSourceApi {
	name: string;
	app_id: string;
	number: string;
}

export interface OrderPointOfSaleApi {
	id: number;
	name: string;
}

export interface OrderEmployeeApi {
	id: number;
	name: string;
}

export interface OrderTerminalApi {
	uid: string;
	id: number;
	name: string;
}

export interface InvoiceApi {
	id: number;
	uid: number;
	reference_id: string;
	type: string;
	number: string;
	place_of_issue: string;
	signed_by: string;
	comment: string;
	recipient_id: number | string;
	recipient: InvoiceRecipientApi;
	receiver_id: number | string;
	receiver: InvoiceReceiverApi;
	issuer: InvoiceIssuerApi;
	dates: InvoiceDatesApi;
	summary: InvoiceSummaryApi;
	orders: InvoiceOrderApi[];
	order_items: InvoiceItemApi[];
	items: InvoiceItemApi[];
	payments: InvoicePaymentApi[];
	custom_fields: CustomFieldEntityApi[];
	document_type: string;
	correction_id: number;
	proforma_id: number;
	invoice_order_id: number;
	from_advance: boolean;
	confirmed: boolean;
	linked_invoices: LinkedInvoiceApi[];
	reason_of_correction: string;
	advance_id: number;
	previous_advances: InvoicePreviousAdvanceApi[];
	paid: boolean;
	organization: OrganizationApi;
	status: "OPEN" | "CONFIRMED";
	created_at?: string;
	opened_at?: string;
	closed_at?: string;
	label?: string;
}

export interface InvoicePreviousAdvanceApi {
	advance_id: number;
}

export interface LinkedInvoiceApi {
	id: number;
	document_type: string;
	number: string;
	issued_at: string;
	price_sum_net: MoneyApi;
	price_sum_gross: MoneyApi;
	price_sum_tax: MoneyApi;
	items: InvoiceItemApi[];
}

export interface InvoicePaymentApi {
	id: string;
	price_paid: MoneyApi;
	paid_at: string;
	paid: boolean;
	type: string;
	payment_method_id: number;
	transaction: TransactionApi;
	transaction_uid: string;
	editable: boolean;
}

export enum OutflowType {
	RW = "RW",
	WZ = "WZ",
}

export interface InvoiceOrderApi {
	order_id: number;
	closed_at: string;
	number: string;
	tax_id_no_mismatch: boolean;
	order_tax_id_no: string;
}

export interface InvoiceItemApi {
	id: number;
	name: string;
	quantity: number;
	volume: string;
	pkwiu: string;
	gtu: string;
	price: MoneyApiForm;
	tax_id: number;
	tax: TaxApi;
	price_sum: MoneyApi;
	external_id: string;
	position: number;
	item_id?: string | number;
	sub_unit_price?: MoneyApiForm;
}

export interface InvoiceRecipientApi {
	name: string;
	alternative_name: string;
	tax_id_no: string;
	business_register_no: string;
	address: AddressApi;
	contact: ContactApi;
	client: ClientApi;
	avatar_link?: ImageLinkApi;
}

export interface InvoiceReceiverApi {
	name: string;
	address: AddressApi;
	contact: ContactApi;
	avatar_link?: ImageLinkApi;
	tax_id_no?: string;
}

export interface InvoiceIssuerApi {
	name: string;
	alternative_name: string;
	tax_id_no: string;
	business_register_no: string;
	address: AddressApi;
	contact: ContactApi;
	bank_account: BankAccountApi;
	avatar_link?: ImageLinkApi;
}

export interface InvoiceDatesApi {
	issued_at: string;
	sold_at: string;
	payment_due_date: string;
}

export interface InvoiceSummaryApi {
	price_sum_net: MoneyApi;
	price_sum_gross: MoneyApi;
	price_sum_tax: MoneyApi;
	taxes: InvoiceSummaryTaxApi[];
}

export interface InvoiceSummaryTaxApi {
	tax_id: number;
	tax_name: string;
	tax_amount: number;
	price_net: MoneyApi;
	price_gross: MoneyApi;
	price_tax: MoneyApi;
}

export interface InvoiceDetailsApi {
	numeration_type: string;
	place_of_issue?: string;
	signed_by?: string;
	prefix?: string;
	suffix?: string;
	default_comment?: string;
	logo_link?: ImageLinkApi;
	logo?: ImageApi;
	first_invoice_number: number;
	first_proforma_number: number;
	first_advance_number: number;
	first_correction_number: number;
	first_advance_correction_number: number;
	signed_by_logged_user: boolean;
	advance_correction_prefix?: string;
	advance_correction_suffix?: string;
	separate_advance_correction_numeration: boolean;
}

export interface TemplateLineApi {
	type: string;
	elements: TemplateLineElementApi[];
	template_lines_on_true: TemplateLineApi[];
	template_lines_on_false: TemplateLineApi[];
	value_element: TemplateLineElementApi[];
}

export interface TemplateLineElementApi {
	element_value_type: string;
	type: string;
	format: string;
	value: string;
	skip_line_if_empty: boolean;
	skip_element_if_empty: boolean;
	bold: boolean;
	double_height: boolean;
	double_width: boolean;
	double_height_and_width: boolean;
	center: boolean;
	elements: TemplateLineElementApi[];
	element_list_typ: string;
	list_item_lines: TemplateLineApi[];
	grouped_item_lines: TemplateLineApi[];
	not_grouped_item_lines: TemplateLineApi[];
	group_by: string[];
	sort_by: string;
	sort_ascending: boolean;
	condition: TemplateFilterConditionApi[];
	filter_condition: TemplateFilterConditionApi[];
	left_elements: TemplateLineElementApi[];
	right_elements: TemplateLineElementApi[];
}

export interface TemplateFilterConditionApi {
	type: string;
	element_value_type: string;
	element_list_type: string;
	value: string;
	logic_type: string;
	conditions: TemplateFilterConditionApi[];
}

export interface ReservationApi {
	id: number;
	room_id: number;
	room: RoomApi;
	table_id: number;
	table: RoomTableApi;
	seats_number: number;
	reservation_status: string;
	date_from: string;
	date_to: string;
	client_id: number;
	client: ClientApi;
	description: string;
}

export interface DashboardApi {
	alerts?: DashboardAlertApi;
	reports?: DashboardReportsApi;
	payment_reports?: ReportApi;
	product_reports?: ReportApi;
	category_reports?: ReportApi;
	sales_reports?: ReportApi;
	sales_advanced_reports?: ReportApi;
	clients_informations?: ReportApi;
	last_update?: string;
}

export interface DashboardReportsApi {
	current: DashboardReport;
	comparison: DashboardReport;
}

export interface DashboardReport {
	guest_count: number | undefined;
	employee_count: number | undefined;
	order_count: number | undefined;
	open_order_count: number | undefined;
	open_order_amount: number | undefined;
	average_order_amount: MoneyApi | undefined;
	average_per_guest_order_amount?: MoneyApi;
}

export interface DashboardAlertApi {
	apps_with_error: ApplicationApi[];
	warning_pos_reports: PosReportApi[];
	danger_pos_reports: PosReportApi[];
	count_of_not_sent_requests: number;
	count_of_outdated_terminals: number;
	count_of_error_fiscalizations: number;
	count_of_duplicate_fiscalizations: number;
	count_of_missing_ereceipts: number;
	count_of_not_sent_ereceipts: number;
	count_of_not_connected_go_order_app_links: number;
	count_of_outdated_opened_orders: number;
}

export interface PosReportSettingsApi {
	employees: boolean;
	discounts: boolean;
	payment_methods: boolean;
	payments_and_payouts: boolean;
	detailed_settlement: boolean;
	remember_settings?: boolean;
	email?: string;
}

export interface CurrencyApi {
	id: number;
	currency_from: string;
	currency_to: string;
	rate: number;
	start_at: string;
	end_at: string;
	updated_at: string;
}

export interface CopyPlaceApi {
	copied_organization_id?: number | null;
	copy_menus: boolean;
	copy_availabilities: boolean;
	copy_rooms: boolean;
	copy_payment_methods: boolean;
	copy_printout_templates: boolean;
	copy_clients: boolean;
	copy_employees: boolean;
	copy_segments: boolean;
	copy_custom_fields: boolean;
	copy_invoice_details: boolean;
	copy_venue_settings: boolean;
	copy_venue_roles_and_permissions: boolean;
	copy_default_comments: boolean;
	copy_currency_rates: boolean;
	copy_taxes: boolean;
	copy_directions: boolean;
	copy_workplaces: boolean;
	copy_point_of_sales: boolean;
	copy_notification_templates: boolean;
	copy_receipt: boolean;
	copy_tags: boolean;
	password?: string;
}

export interface VenueSettingsKdsItemApi {
	direction_id: number;
	direction: DirectionApi;
	print_order_number: boolean;
	track_preparation_status: boolean;
	devices: VenueSettingsKdsItemDeviceApi[];
}

export interface VenueSettingsKdsItemDeviceApi {
	device_id: number;
	paper_width: number;
	device: DeviceApi;
}

export interface VenueSettingsPrintoutTemplateApi {
	printout_template_id: number;
	type: string;
	printout_template: PrintoutTemplateApi;
	printout_type: string;
}

export interface ExternalReportApi {
	id: number;
	created_at: string;
	external_id: string;
	total: MoneyApi;
	type: string;
	report_drawer?: PosReportApi;
	report_shift_work?: PosReportApi;
	infos: ExternalReportInfoApi[];
	opened_at: string;
	closed_at: string;
	device: DeviceApi;
}

export interface ExternalReportInfoApi {
	type: string;
	data: string;
}

export interface EntityConfigApi {
	custom_fields: CustomFieldTemplateApi[];
	segments: SegmentApi[];
}

export interface RoomTableSearchSelectApi {
	id: string;
	label: string;
	room_name: string;
	number_of_seats: number;
}

export interface GusApi {
	address_build_nr: string;
	address_city: string;
	address_country: string;
	address_flat_nr: string;
	address_street: string;
	address_zip_code: string;
	business_register_no: string;
	name: string;
	tax_id_no: string;
}

export interface ItemGroupConfigApi {
	points_of_sale: PointOfSaleApi[];
	custom_fields: CustomFieldTemplateApi[];
}

export interface CategoryConfigApi {
	points_of_sale: PointOfSaleApi[];
}

export interface OrderSearchSelectApi {
	label: string;
	id: string;
	closed_at: string;
	contact?: {
		tax_id_no: string;
	};
}

export interface CsvAttributeApi {
	domain_field_name?: string;
	csv_field_name: string;
}

export interface AuthorizeApplicationApi {
	redirect_uri: string;
}

export interface MigrationApi {
	status: string;
	job_uid: string;
	organization_id: number;
	types: Record<string, any>[];
}

export interface AssignEmployeeDriverFormProps {
	delivery_employee_id?: string;
	delivery_cost?: MoneyApi;
	delivery_pickup_in_minutes?: number;
}

export interface SpreadsheetPriceListFormProps {
	items: MenuPriceListFlatApi[];
}

export interface RecalculateWorkTimesFormProps {
	employees: number[];
	date_from: string;
}

export interface dateRangeVenueClearApi {
	dateRange: string;
	remove_employee_activities: boolean;
	remove_reservations: boolean;
	remove_terminal_notifications: boolean;
	remove_work_times: boolean;
}

export interface ClearVenueCustomFormProps {
	remove_menus: boolean;
	remove_item_groups_and_items: boolean;
	remove_discounts: boolean;
	remove_categories: boolean;
	remove_price_lists: boolean;
	remove_modifier_groups: boolean;
	remove_availabilities: boolean;
	remove_rooms: boolean;
	remove_payment_methods: boolean;
	remove_printout_templates: boolean;
	remove_clients: boolean;
	remove_employees: boolean;
	remove_segments: boolean;
	remove_custom_fields: boolean;
	remove_venue_roles_and_permissions: boolean;
	remove_default_comments: boolean;
	remove_currency_rates: boolean;
	remove_taxes: boolean;
	remove_directions: boolean;
	remove_workplaces: boolean;
	remove_point_of_sales: boolean;
	remove_notification_templates: boolean;
	remove_receipt: boolean;
	remove_tags: boolean;
	dateRangeVenueClear: dateRangeVenueClearApi;
}

export interface ClearVenueCustomPermanentlyFormProps extends ClearVenueCustomFormProps {
	remove_venue_settings: boolean;
	remove_invoice_details: boolean;
}

export interface DeliveryZoneApi {
	id: string;
	name: string;
	delivery_price: MoneyApi;
	min_order_price: MoneyApi;
	free_delivery_price: MoneyApi;
	path?: string;
	app_id: string;
	description: string;
	app: ApplicationApi;
}

export interface PriceListPredicateApi {
	id: number;
	conditions: PriceListPredicateConditionApi[];
	price_list_id: number;
	price_list: Record<string, any>;
	position: number;
	status: string;
}

export interface PriceListPredicateConditionApi {
	type: PredicateConditionType;
	operator: string | null;
	value: string;
	name: string;
}

export type PredicateConditionType = "ORDER_TYPE" | "CLIENT_GROUP" | "POINT_OF_SALE" | "ORDER_SOURCE";

export interface UpdatePriceListPredicateApi {
	predicates: PriceListPredicateApi[];
}

export interface PosReporCheckBeforeDeletionApi {
	external_report_count: number;
	invoice_count: number;
	order_count: number;
	report_count: number;
	transaction_count: number;
	uid: string;
	id?: number;
	shift_work_report_count: number;
	drawer_report_count: number;
}

export interface POSReportSearchSelectApi {
	id: string | number;
	label: string;
	opened_at: string;
	closed_at?: string;
	type: "DRAWER" | "SHIFTWORK";
}

export interface ItemPriceHistoryApi {
	item: ItemPriceHistoryItemApi;
	price: MoneyApi;
	lowest_price_since30_days: MoneyApi;
}

export interface ItemPriceHistoryItemApi {
	id: number;
	item_group_name: string;
	name: string;
	image_link?: ImageLinkApi;
}

export interface DeliveryEmployeeEmployeeApi {
	id: number;
	name: string;
	checked_in: boolean;
	avatar_link?: ImageLinkApi;
}

export interface DeliveryEmployeeApi {
	id: number;
	employee_id: string;
	employee?: DeliveryEmployeeEmployeeApi;
	coordinates: CoordinatesApi;
	status: string;
	source: DeliveryEmployeeSourceApi;
	coordinates_updated_at: string;
}

export interface DeliveryEmployeeSourceApi {
	id: string;
	name: string;
}

export interface ItemTranslationNameListResponseApi {
	items: ItemTranslationItemNameApi[];
	locales: string[];
}

export interface ItemTranslationItemNameApi {
	category_id: number;
	category_name: string;
	item_group_id: number;
	item_group_name: string;
	item_id: number;
	sku?: string;
	barcodes: string[];
	name: string;
	description?: string;
	printing_name?: string;
	translations: TranslationApi[];
}

export interface ItemTranslationItemNameFormApi extends ItemTranslationItemNameApi {
	index: number;
	item_full_name: string;
}

export interface ItemTranslationItemNameTranslationFormApi {
	index: number;
	id: string;
	name: string;
}

export interface ItemTranslationListFormProps {
	items: ItemTranslationItemNameFormApi[];
}

export enum SaveAndAddActionType {
	ADD,
	EXISTING,
	COPY,
}

export interface PaymentMethodSearchSelectApi extends SearchSelectApi {
	outflow_type: OutflowType;
}

export interface LiveOrderPendingTerminalNotificationApi {
	id: number;
	error: boolean;
	updated_at: string;
	type: string;
}

export interface LiveOrderApi extends OrderApi {
	pending_terminal_notifications: LiveOrderPendingTerminalNotificationApi[];
}

export interface DeliveryEstimationApi {
	id: string;
	price: MoneyApi;
	pickup_in_minutes: number;
	delivery_pickup_in_minutes: number;
}

export interface StornReportApi {
	created_at: string;
	storno_at: string;
	order_id: number;
	order_number: string;
	saved: boolean;
	item_id: number;
	item_group_id: number;
	item_name: string;
	employee_id: number;
	employee_name: string;
	comment: string;
	total_price: MoneyApi;
	opened_at: string;
	closed_at: string;
	type: string;
	label: string;
	room_name: string;
	number_of_seats: number;
}

export interface LiveOrderAcceptanceFormApi {
	pickup_at?: string;
	estimated_preparation_at?: string;
}

export interface LiveOrderRejectionFormApi {
	reason?: string;
}

export interface JPK_FAInvoiceApi {
	extended: boolean;
	tax_office_code: string;
	date_to: string;
	date_from: string;
	tax_id_no: string;
	full_name: string;
	country_code: string;
	voivodeship: string;
	district: string;
	parish: string;
	city: string;
	street?: string;
	build_nr: string;
	flat_nr?: string;
	zip_code: string;
}

export interface JPKInvoiceV7Api {
	extended: boolean;
	tax_office_code: string;
	date: string;
	full_name: string;
	tax_id_no: string;
}
export interface JPKInvoiceApi {
	date_to: string;
	date_from: string;
}

interface NotificationTemplateSettingsApi {
	INCLUDE_REPORT_DRAWERS: boolean;
}
export interface NotificationTemplateApi {
	id: string;
	name: string;
	category: string;
	type: string;
	status: string;
	title: string;
	content: string;
	settings?: NotificationTemplateSettingsApi;
}

export interface PreviewNotificationTemplateApi {
	title: string;
	content: string;
}

export enum PdfOrientationSettingEnum {
	LANDSCAPE = "LANDSCAPE",
	PORTRAIT = "PORTRAIT",
	ASK = "ASK",
}

export interface OrderSyncApi {
	order_number: string;
	created_at: string;
	web_at: string;
	first_pos_terminal_name: string;
	first_pos_at: string;
	last_pos_terminal_name: string;
	last_pos_at: string;
	first_pos_web_date_diff: number;
	last_pos_web_date_diff: number;
	terminals_without_order: TerminalApi[];
}
