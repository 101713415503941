import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { VirtualColumnAction } from "./types";

interface Props {
	id: string;
	isHidden: boolean;
	actions?: VirtualColumnAction[];
	leftPosition?: number;
	isSticky?: boolean;
	lastColumn?: boolean;
	zIndex?: number;
}

const VirtualTableHeaderActionsColumn = ({
	isHidden,
	actions,
	id,
	leftPosition,
	isSticky,
	lastColumn,
	zIndex,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const shouldRenderActions = actions && actions?.length > 0;
	const style = leftPosition !== undefined ? { left: leftPosition } : {};

	return (
		<th
			className={`${isHidden ? "d-none" : ""} actions-column ${isSticky ? "sticky-column" : ""} ${
				lastColumn ? "with-wider-border" : ""
			}`}
			style={{
				...style,
				zIndex,
			}}
		>
			<div className="bg-white d-flex">
				{shouldRenderActions && (
					<Dropdown as={ButtonGroup} size="sm">
						<Dropdown.Toggle variant="light">
							{t("modules.group_edit.action.perform_action.title")}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{actions.map((action, index) => (
								<Dropdown.Item href="#" onClick={action.onClick} key={`${id}-column-actions-${index}`}>
									{action.title}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				)}
			</div>
		</th>
	);
};

export default VirtualTableHeaderActionsColumn;
