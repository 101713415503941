import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ApplicationUpdateApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";

interface Props {
	onHide: () => void;
}

const MultiLocationInstallModal: FC<Props> = ({ onHide }) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const { addFlash, addSuccessFlash } = useFlash();
	const organization = useSelector(selectOrganization);
	const form = useForm<ApplicationUpdateApi>({
		criteriaMode: "all",
		defaultValues: {},
	});
	const {
		handleSubmit,
		formState: { errors },
		control,
		setError,
	} = form;

	const getExternalOrganizations = (search: string, params: Record<string, any>) => {
		return api.organization().getAppsLocations(search, params);
	};

	const onSubmit = handleSubmit(async (data: ApplicationUpdateApi) => {
		setLoading(true);
		try {
			const res = await api.organization().installApps("LOCATIONS", data);
			addSuccessFlash(t("lib:common.flash.completed"));
			history.push(`/${organization.id}/apps/active/${res.id}`);
			onHide();
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	return (
		<Modal show={true} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("modules.app.action.multilocation_install.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormSelectGroup
					control={control}
					getOptionLabel={(opt) => opt.name}
					getOptionValue={(opt) => opt.id}
					loadOptions={getExternalOrganizations}
					errors={errors}
					name={"external_organization_id"}
					label={t("common.word.location", { ns: "lib" })}
					data-testid="external_organization_id"
				/>
			</Modal.Body>
			<Modal.Footer>
				<ButtonLoading loading={loading} variant="primary" type="submit" onClick={onSubmit}>
					{t("common.action.install", { ns: "lib" })}
				</ButtonLoading>
				<Button variant="light" onClick={onHide}>
					{t("common.action.cancel", { ns: "lib" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default MultiLocationInstallModal;
