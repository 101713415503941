import React, { FC, useState } from "react";
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useFlash from "go-alert/AlertMessage";
import { mapCustomFieldsToForm } from "go-app/components/CustomFields";
import handleError from "go-app/services/errors";
import { UnprocessableEntity } from "go-core/api/exceptions";
import handleException from "go-core/api/handleException";
import { ApiError } from "go-core/api/types";
import { FormDirty } from "go-form/components/FormDirty";
import { useCustomErrors } from "go-form/hooks";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { CustomFieldTemplateApi } from "go-segment/services/types";
import {
	InvoiceApi,
	InvoiceDetailsApi,
	InvoiceItemApi,
	InvoicePaymentApi,
	InvoiceSummaryTaxApi,
	OrderApi,
} from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import AdvanceFormItemsTable from "./AdvanceFormItemsTable";
import InvoiceFormAdvanced from "./InvoiceFormAdvanced";
import InvoiceFormBasicInfo from "./InvoiceFormBasicInfo";
import InvoiceFormClientsInfo from "./InvoiceFormClientsInfo";
import InvoiceFormItemsTable from "./InvoiceFormItemsTable";
import InvoiceFormPaymentsTable from "./InvoiceFormPaymentsTable";

interface Props {
	invoice: InvoiceApi;
	order?: (OrderApi & { additionalOrderIds?: string[]; additionalOrderNumbers?: string[] }) | undefined;
	proforma?: InvoiceApi | undefined;
	advance?: InvoiceApi | undefined;
	proformaAdvance?: boolean;
	customFieldsConfig: CustomFieldTemplateApi[];
	invoiceDetails?: InvoiceDetailsApi;
	isFinalInvoice?: boolean;
}

const getRidOfPricePaidErrors = (errors: ApiError[], payments: InvoicePaymentApi[]) => {
	payments.forEach((payment, index) => {
		const priceValueErrorIndex = errors.findIndex((error) => error.field === `payments[${index}].price_paid`);
		if (priceValueErrorIndex !== -1) {
			errors.splice(priceValueErrorIndex, 1);
		}
	});
};

const InvoiceForm: FC<Props> = ({ invoice, order, proforma, customFieldsConfig, proformaAdvance, isFinalInvoice }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const history = useHistory();
	const [dangerFlash, setDangerFlash] = useState<boolean>(false);
	const [summaryTaxes, setSummaryTaxes] = useState<InvoiceSummaryTaxApi[]>([]);
	const [summaryOrdersTaxes, setSummaryOrdersTaxes] = useState<InvoiceSummaryTaxApi[]>([]);
	const params: Record<string, any> = {
		include:
			"custom_fields,order_items,order_items.tax,items,items.tax,payments,payments.transaction,orders,summary,summary.taxes,recipient,receiver,previous_advances",
	};
	const [formErrors, setFormErrors] = useState<ApiError[]>([]);

	const form = useForm<InvoiceApi>({
		criteriaMode: "all",
		defaultValues: {
			...invoice,
			payments: !invoice.payments || invoice.payments.length === 0 ? [{} as InvoicePaymentApi] : invoice.payments,
			items: !invoice.items
				? [{ position: 0 } as InvoiceItemApi]
				: [...invoice.items, { position: invoice.items.length }],
			...(invoice.document_type === "ADVANCE"
				? {
						order_items: !invoice.order_items
							? [{ position: 0 } as InvoiceItemApi]
							: [...invoice.order_items, { position: invoice.order_items.length }],
				  }
				: undefined),
			custom_fields: mapCustomFieldsToForm(customFieldsConfig, invoice.custom_fields),
		},
	});
	const { addFlash, addSuccessFlash } = useFlash();
	const { handleSubmit, formState, setError, reset, getValues } = form;
	const { setErrors, validateCustomErrors } = useCustomErrors(setError);
	const [loading, setLoading] = useState(false);
	const [shouldGenerateNewClientByTaxId, setShouldGenerateNewClientByTaxId] = useState(false);
	const onSubmit = handleSubmit(async (data: InvoiceApi) => {
		if (!validateCustomErrors()) {
			return;
		}

		setLoading(true);
		data.items = data.items?.filter(
			(f: InvoiceItemApi) => f.name || f.pkwiu || f.gtu || f.price?.amount || f.quantity || f.volume || f.tax_id
		);
		if (invoice.document_type === "PROFORMA") {
			await saveProforma(data);
		} else if (invoice.document_type === "INVOICE") {
			await saveInvoice(data);
		} else {
			await saveAdvance(data);
		}
	});

	const saveProforma = async (data: any) => {
		setFormErrors([]);
		data.payments = data.payments?.filter(
			(f: InvoicePaymentApi) => f.payment_method_id || f.price_paid.amount || f.paid || f.paid_at
		);
		if (invoice.orders) {
			data.orders = [
				...invoice.orders
					.filter((invoiceOrder) => invoiceOrder?.order_id)
					.map((invoiceOrder) => {
						return { order_id: invoiceOrder.order_id };
					}),
			];
		}
		try {
			if (proforma) {
				data.proforma_id = proforma.id;
				const res = await api.organization().createInvoiceFromProforma(data);
				history.push(`/${organization.id}/invoices/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else if (invoice.id) {
				data.id = invoice.id;
				const res = await api.organization().updateProformaInvoice(data, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				reset({
					...res,
					items: [...res.items],
					payments: res.payments.length === 0 ? [{}] : [...res.payments],
					custom_fields: mapCustomFieldsToForm(customFieldsConfig, res.custom_fields),
				});
			} else {
				let res;
				if (order) {
					res = await api.organization().createInvoiceFromOrder(data);
					history.push(`/${organization.id}/invoices/${res.id}`);
				} else {
					res = await api.organization().createProformaInvoice(data);
					history.push(`/${organization.id}/invoices/proforma/${res.id}`);
				}
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e: any) {
			const exceptionErrors = handleException(e?.response?.data ? e?.response?.data : e);
			setFormErrors(exceptionErrors);
			const alertErrors = exceptionErrors.filter((f) => f.field === "all");
			const invalidInvoicePriceError = alertErrors.find((x) => x.code === "invalid_invoice_price");
			if (invalidInvoicePriceError) {
				setDangerFlash(true);
				setTimeout(() => setDangerFlash(false), 3000);
			} else if (e instanceof UnprocessableEntity) {
				const { errors } = e;
				getRidOfPricePaidErrors(errors, getValues("payments"));

				const correctlyMappedError = new UnprocessableEntity({
					errors: errors.map((error) => {
						if (error.field === "recipient_tax_id_no") {
							return {
								...error,
								field: "recipient_id",
							};
						}

						return error;
					}),
				});
				handleError.form(correctlyMappedError, setError, addFlash);
				handleException(correctlyMappedError);
			} else {
				handleError.form(e, setError, addFlash);
				handleException(e);
			}
		}
		setLoading(false);
	};

	const saveInvoice = async (data: any) => {
		setFormErrors([]);
		if (order && !invoice.id) {
			data.dates.sold_at = invoice.dates.sold_at;
			data.dates.payment_due_date = invoice.dates.payment_due_date;
			data.orders = order.additionalOrderIds
				? order.additionalOrderIds.map((orderId) => ({ order_id: orderId }))
				: [{ order_id: order.id }];
			data.type = "INVOICE";

			if (shouldGenerateNewClientByTaxId && data.recipient_id.toString() === order.contact.tax_id_no.toString()) {
				data.recipient_tax_id_no = order.contact.tax_id_no;

				delete data.recipient_id;
			}
		} else if (invoice.orders) {
			data.orders = [
				...invoice.orders
					.filter((invoiceOrder) => invoiceOrder?.order_id)
					.map((invoiceOrder) => {
						return { order_id: invoiceOrder.order_id };
					}),
			];
		}
		try {
			if (order) {
				const res = await api.organization().createInvoiceFromOrder(data);
				history.push(`/${organization.id}/invoices/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else if (invoice.id) {
				data.id = invoice.id;
				const res = await api.organization().updateInvoice(data, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				reset({
					...res,
					items: [...res.items],
					custom_fields: mapCustomFieldsToForm(customFieldsConfig, res.custom_fields),
				});
			} else {
				const res = await api.organization().createInvoice(data);
				history.push(`/${organization.id}/invoices/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e: any) {
			const exceptionErrors = handleException(e?.response?.data ? e?.response?.data : e);
			setFormErrors(exceptionErrors);
			const alertErrors = exceptionErrors.filter((f) => f.field === "all");
			const invalidInvoicePriceError = alertErrors.find((x) => x.code === "invalid_invoice_price");
			if (invalidInvoicePriceError) {
				setDangerFlash(true);
				setTimeout(() => setDangerFlash(false), 3000);
			} else if (e instanceof UnprocessableEntity) {
				const { errors } = e;
				getRidOfPricePaidErrors(errors, getValues("payments"));

				const correctlyMappedError = new UnprocessableEntity({
					errors: errors.map((error) => {
						if (error.field === "recipient_tax_id_no") {
							return {
								...error,
								field: "recipient_id",
							};
						}

						return error;
					}),
				});
				handleError.form(correctlyMappedError, setError, addFlash);
				handleException(correctlyMappedError);
			} else {
				handleError.form(e, setError, addFlash);
				handleException(e);
			}
		}
		setLoading(false);
	};

	const saveAdvance = async (data: InvoiceApi) => {
		setFormErrors([]);
		data.advance_id = invoice.advance_id;
		data.order_items = data.order_items?.filter(
			(f: InvoiceItemApi) => f.name || f.pkwiu || f.gtu || f.price?.amount || f.quantity || f.volume
		);
		try {
			if (invoice.id) {
				data.id = invoice.id;
				const res = await api.organization().updateAdvance(data, params);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				reset({
					...res,
					items: [...res.items],
					order_items: [...res.order_items, { position: res.order_items.length }],
					custom_fields: mapCustomFieldsToForm(customFieldsConfig, res.custom_fields),
				});
			} else if (invoice.advance_id && !invoice.id) {
				const res = await api.organization().createInvoiceFromAdvance(data);
				history.push(`/${organization.id}/invoices/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				let res;
				if (proformaAdvance) {
					res = await api.organization().createAdvanceFromProforma(data);
				} else {
					res = await api.organization().createAdvance(data);
				}
				history.push(`/${organization.id}/invoices/advances/${res.id}`);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			}
		} catch (e: any) {
			const exceptionErrors = handleException(e?.response?.data ? e?.response?.data : e);
			setFormErrors(exceptionErrors);
			const alertErrors = exceptionErrors.filter((f) => f.field === "all");
			const invalidInvoicePriceError = alertErrors.find((x) => x.code === "invalid_invoice_price");
			if (invalidInvoicePriceError) {
				setDangerFlash(true);
				setTimeout(() => setDangerFlash(false), 3000);
			} else {
				if (e instanceof UnprocessableEntity) {
					const { errors } = e;
					getRidOfPricePaidErrors(errors, getValues("payments"));
				}
				handleError.form(e, setError, addFlash);
				handleException(e);
			}
		}
		setLoading(false);
	};

	return (
		<FormDirty formState={formState} loading={loading} key="client-group-form" noValidate onSubmit={onSubmit}>
			{dangerFlash && (
				<div className="alerts">
					<Alert variant={"danger"} onClose={() => setDangerFlash(false)} dismissible>
						{t("constraints.invalid_invoice_price", { currency: organization.currency })}
					</Alert>
				</div>
			)}
			<InvoiceFormBasicInfo order={order} form={form} invoice={invoice} />

			<InvoiceFormClientsInfo
				form={form}
				invoice={invoice}
				order={order}
				shouldGenerateNewClientByTaxId={shouldGenerateNewClientByTaxId}
				setShouldGenerateNewClientByTaxId={setShouldGenerateNewClientByTaxId}
				formErrors={formErrors}
			/>
			{!order && !isFinalInvoice && (
				<InvoiceFormItemsTable form={form} setSummaryTaxes={setSummaryTaxes} summaryTaxes={summaryTaxes} />
			)}
			{!order && invoice.document_type === "ADVANCE" && !isFinalInvoice && (
				<AdvanceFormItemsTable
					form={form}
					setSummaryTaxes={setSummaryOrdersTaxes}
					summaryTaxes={summaryOrdersTaxes}
				/>
			)}
			{!order && !isFinalInvoice && <InvoiceFormPaymentsTable form={form} summaryTaxes={summaryTaxes} />}
			<InvoiceFormAdvanced
				form={form}
				invoice={invoice}
				customFieldsConfig={customFieldsConfig}
				setErrors={setErrors}
			/>
		</FormDirty>
	);
};
export default InvoiceForm;
