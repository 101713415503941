import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useFlash from "go-alert/AlertMessage";
import Header, { ButtonProps } from "go-app/components/Header";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import FormatResourceStatus from "../../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import {
	NotificationTemplateApi,
	PreviewNotificationTemplateApi,
} from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import { NotificationTemplateForm } from "../../components/NotificationTemplateForm";

interface MatchParams {
	notification_template_id: string;
}

export const OrganizationSettingsNotificationTemplatesEditPage = () => {
	const [notificationTemplate, setNotificationTemplate] = useState<NotificationTemplateApi | undefined>(undefined);
	const [notificationPreviewData, setNotificationPreviewData] = useState<PreviewNotificationTemplateApi | undefined>(
		undefined
	);
	const { notification_template_id } = useParams<MatchParams>();
	const { addFlash, addSuccessFlash } = useFlash();
	const { t } = useTranslation();
	const history = useHistory();
	const confirmation = useConfirmation();
	const organization = useSelector(selectOrganization);
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		const getNotificationTemplate = async () => {
			const params: Record<string, any> = { include: "settings" };
			try {
				const notificationTemplateResponse = await api
					.organization()
					.getNotificationTemplate(Number(notification_template_id), params);
				setNotificationTemplate(notificationTemplateResponse);

				const notificationPreviewDataResponse = await api
					.organization()
					.getNotificationPreview(notificationTemplateResponse);
				setNotificationPreviewData(notificationPreviewDataResponse);
				handleChangeTabTitle(
					`${notificationTemplateResponse.name} | ${t("modules.notification_template.header.title")}`
				);
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		};

		getNotificationTemplate();
	}, []);

	if (!notificationTemplate || !notificationPreviewData) return <LoadingContainer />;

	const getTitle = () => (
		<>
			{`${t("modules.notification_template.header.title")} "${notificationTemplate.name}"`}
			<FormatResourceStatus status={notificationTemplate.status} />
		</>
	);

	const removeNotificationTemplate = async (notificationTemplateId: string) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await api.organization().removeNotificationTemplate(notificationTemplateId);
			addSuccessFlash(t("common.flash.removed", { ns: "lib" }));
			history.push(`/${organization.id}/settings/notification_templates`);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const enableNotificationTemplate = async (notificationTemplateId: string) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await api.organization().activateNotificationTemplate(notificationTemplateId);
			addSuccessFlash(t("lib:common.flash.completed"));
			setNotificationTemplate(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const disableNotificationTemplate = async (notificationTemplateId: string) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.disable", { ns: "lib" }),
		});
		try {
			const res = await api.organization().deactivateNotificationTemplate(notificationTemplateId);
			addSuccessFlash(t("lib:common.flash.completed"));
			setNotificationTemplate(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const resetFormToDefault = async () => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.action", { ns: "lib" }),
		});
		try {
			const { title, content } = await api
				.organization()
				.getNotificationTemplateDefaultValues(notificationTemplate.category, notificationTemplate.type);

			const { title: previewDataTitle, content: previewDataContent } = await api
				.organization()
				.getNotificationPreview({ ...notificationTemplate, title, content });

			setNotificationTemplate((previousNotificationTemplate) => {
				if (previousNotificationTemplate) return { ...previousNotificationTemplate, title, content };
				return undefined;
			});
			setNotificationPreviewData({ title: previewDataTitle, content: previewDataContent });
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const buttons: ButtonProps[] = [];
	if (notificationTemplate.status !== "DELETED")
		buttons.push({
			action: () => removeNotificationTemplate(notificationTemplate.id),
			title: t("common.action.remove", { ns: "lib" }),
			dropdown: true,
		});
	if (notificationTemplate.status === "DELETED")
		buttons.push({
			action: () => enableNotificationTemplate(notificationTemplate.id),
			title: t("common.action.activate", { ns: "lib" }),
			dropdown: true,
		});
	if (notificationTemplate.status === "ENABLED")
		buttons.push({
			action: () => disableNotificationTemplate(notificationTemplate.id),
			title: t("common.action.disable", { ns: "lib" }),
			dropdown: true,
		});
	if (notificationTemplate.status === "DISABLED")
		buttons.push({
			action: () => enableNotificationTemplate(notificationTemplate.id),
			title: t("common.action.activate", { ns: "lib" }),
			dropdown: true,
		});
	buttons.push({
		title: t("modules.notification_template.action.reset_form.title"),
		dropdown: true,
		action: resetFormToDefault,
	});

	return (
		<>
			<Header title={getTitle()} buttons={buttons} />
			<NotificationTemplateForm
				notificationTemplate={notificationTemplate}
				notificationPreviewData={notificationPreviewData}
			/>
		</>
	);
};
