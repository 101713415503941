import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationSettingsTagsEditPage from "./pages/Edit";
import OrganizationSettingsTagsIndexPage from "./pages/Index";
import OrganizationSettingsTagsNewPage from "./pages/New";

const OrganizationSettingsTagsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="content">
			<Switch>
				<Route path={`${props.match.url}/`} exact={true} component={OrganizationSettingsTagsIndexPage} />
				<Route path={`${props.match.url}/new`} exact={true} component={OrganizationSettingsTagsNewPage} />
				<Route path={`${props.match.url}/:tagId`} component={OrganizationSettingsTagsEditPage} />
			</Switch>
		</div>
	);
};

export default OrganizationSettingsTagsPage;
