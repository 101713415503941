import React from "react";
import { RenderImage } from "go-app/components/ImageRenderer/ListImageName";
import { DeliveryEmployeeApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	employee: DeliveryEmployeeApi;
}

const LiveOrderDeliveryEmployeeAvatar = ({ employee }: Props): JSX.Element => {
	const isEmployeeOnline = employee.employee?.checked_in;

	return (
		<div className="delivery-employee-avatar">
			<RenderImage data={employee?.employee || employee.source} imageLink={employee.employee?.avatar_link} />
			<div
				className="delivery-employee-status"
				style={{ backgroundColor: isEmployeeOnline ? "#3CC13B" : "#DC3545" }}
			/>
		</div>
	);
};

export default LiveOrderDeliveryEmployeeAvatar;
