import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import { LimitComponentVisibility } from "go-core/components/LimitComponentVisibility";
import { FormDirty } from "go-form/components/FormDirty";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ClientApi, PrintoutTemplateApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { AddressApi } from "../../../../../../../../../services/Api/types";
import PrintoutTemplateBasicInfoForm from "./PrintoutTemplateBasicInfoForm";
import PrintoutTemplateEntities from "./PrintoutTemplateEntities";
import PrintoutTemplatePreview from "./PrintoutTemplatePreview";
import PrintoutTemplateVisibilityForm from "./PrintoutTemplateVisibilityForm";

interface Props {
	printoutTemplate: PrintoutTemplateApi;
}

export interface PrintoutItem {
	name: string;
	quantity: number;
	item_comment?: string;
	seat_name: string;
	item_course: string;
	additions?: Record<string, any>[];
	additions_separated?: Record<string, any>[];
	price: string;
}

export interface PrintoutElement {
	items: PrintoutItem[];
	course: number;
}

export interface PrintoutExampleData {
	date: string;
	ordered_at: string;
	point_of_sale: string;
	direction: string;
	client: ClientApi;
	address: AddressApi;
	comment: string;
	table: string;
	guest_amount: number;
	order_no: string;
	employee_name: string;
	driver_name: string;
	items: PrintoutElement[];
	price_sum: string;
}

const PrintoutTemplateForm = (props: Props): JSX.Element => {
	const { t } = useTranslation();
	const form = useForm<PrintoutTemplateApi>({
		criteriaMode: "all",
		defaultValues: props.printoutTemplate,
	});
	const [showEntities, setShowEntities] = useState(
		!!(props.printoutTemplate.entities && props.printoutTemplate.entities?.length > 0)
	);
	const [loading, setLoading] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const { handleSubmit, getValues, watch, formState, setError, reset } = form;
	const history = useHistory();
	const organization = useSelector(selectOrganization);
	const printoutDataTemplate = {
		date: "2022-04-07 18:00",
		ordered_at: "2022-04-07 16:30",
		point_of_sale: "BAR #1",
		direction: "Pizza",
		table: `10 (${t("modules.printout_template.field.main_hall.title")})`,
		guest_amount: 2,
		order_no: "212123",
		employee_name: "Jan Kowalski",
		driver_name: "Robert Kubica",
		comment: t("modules.printout_template.field.comment_example.title"),
		price_sum: "99.00 PLN",
		items: [
			{
				course: 1,
				items: [
					{
						name: "Pizza Margerita",
						quantity: 1,
						seat_name: `${t("modules.printout_template.field.guest.title")} nr 1`,
						item_course: "3",
						price: "26.00 PLN",
						additions: [
							{
								name: t("modules.printout_template.field.mozarella.title"),
							},
							{
								name: t("modules.printout_template.field.tomato_sauce.title"),
							},
							{
								name: t("modules.printout_template.field.garlic_sauce.title"),
								price: "2.00 PLN",
							},
						],
					},
					{
						name: "Pizza Margerita",
						quantity: 1,
						seat_name: `${t("modules.printout_template.field.guest.title")} nr 1`,
						item_course: "3",
						price: "26.00 PLN",
						additions: [
							{
								name: t("modules.printout_template.field.mozarella.title"),
							},
							{
								name: t("modules.printout_template.field.tomato_sauce.title"),
							},
							{
								name: t("modules.printout_template.field.garlic_sauce.title"),
								price: "2.00 PLN",
							},
						],
					},
				],
			},
			{
				course: 2,
				items: [
					{
						name: "Fanta 0.3l",
						quantity: 1,
						price: "6.00 PLN",
						item_comment: t("modules.printout_template.field.do_not_pour_into_glass.title"),
						seat_name: `${t("modules.printout_template.field.guest.title")} nr 1`,
						item_course: "1",
					},
					{
						name: "Coca Cola Zero 0.3l",
						quantity: 1,
						price: "6.00 PLN",
						seat_name: `${t("modules.printout_template.field.guest.title")} nr 2`,
						item_course: "2",
					},
					{
						name: `${t("modules.printout_template.field.chips.title")} + Cola`,
						quantity: 1,
						price: "22.00 PLN",
						seat_name: `${t("modules.printout_template.field.guest.title")} nr 3`,
						item_course: "3",
						additions_separated: [
							{
								quantity: 1,
								name: "1x Cola (250 ml)",
							},
							{
								quantity: 1,
								name: `1x ${t("modules.printout_template.field.chips.title")} (${t(
									"modules.printout_template.field.portion.title"
								)})`,
							},
							{
								quantity: 1,
								name: `1x ${t("modules.printout_template.field.chips.title")}(${t(
									"modules.printout_template.field.portion.title"
								)})`,
							},
						],
					},
				],
			},
		],
		client: {
			name: "Piotr Nowak",
			address_build_nr: "43A",
			address_city: "Kraków",
			address_zip_code: "31-510",
			address_street: "Rakowicka",
			contact_email: "gopos@gopos.pl",
			contact_phone_number: "600 600 600",
		} as ClientApi,
		address: {
			street: "Rakowicka",
			build_nr: "43A",
			zip_code: "31-510",
			city: "Kraków",
		} as AddressApi,
	};

	const sortItems = (item: PrintoutExampleData, itemsSort: string) => {
		const order = itemsSort === "ALPHABETICAL" ? "name" : "seat_name";
		const addSort = "course";
		const x = item.items.map((subItem) => {
			return {
				...subItem,
				items: subItem.items
					.sort((a: PrintoutItem, b: PrintoutItem) => a[`${order}`]?.localeCompare(b[`${order}`]))
					.map((sub) => {
						return {
							...sub,
							additions: sub.additions?.sort((a: Record<string, any>, b: Record<string, any>) =>
								a[`${addSort}`]?.localeCompare(b[`${addSort}`])
							),
							additions_separated: sub.additions_separated?.sort(
								(a: Record<string, any>, b: Record<string, any>) =>
									a[`${addSort}`]?.localeCompare(b[`${addSort}`])
							),
						};
					}),
			};
		});
		return {
			...item,
			items: x,
		};
	};

	const [printoutData, setPrintoutData] = useState<PrintoutExampleData>(
		sortItems(printoutDataTemplate, props.printoutTemplate.items_sort)
	);

	useEffect(() => {
		setPrintoutData(sortItems(printoutDataTemplate, getValues("items_sort")));
	}, [watch("items_sort")]);

	const onSubmit = handleSubmit(async (data: PrintoutTemplateApi) => {
		setLoading(true);
		data.id = props.printoutTemplate.id;
		const params: Record<string, string> = { include: "entities, entities.entity" };
		if (!showEntities) {
			data.entities = [];
		}
		try {
			if (data.id) {
				const res = await api.organization().updatePrintoutTemplateSettings(data, params);
				reset(res);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
			} else {
				const res = await api.organization().createPrintoutTemplateSettings(data);
				history.push(`/${organization.id}/settings/printout_templates/${res.id}`);
			}
		} catch (e) {
			handleError.form(e, setError, addFlash);
		}
		setLoading(false);
	});

	const handleChangeEntities = () => {
		setShowEntities(!showEntities);
	};

	return (
		<FormDirty formState={formState} loading={loading} noValidate onSubmit={onSubmit}>
			<fieldset className={"form-group"}>
				<div className={"row"}>
					<PrintoutTemplatePreview form={form} printoutData={printoutData} />
					<div className={"col-md-8 receipt-form"}>
						<PrintoutTemplateBasicInfoForm form={form} />
						<PrintoutTemplateVisibilityForm form={form} />
						<LimitComponentVisibility
							helpText={t("modules.printout_template.field.entities.helptext.description")}
							legendText={t("modules.printout_template.field.entities.title")}
							handleChange={() => handleChangeEntities()}
							defaultVisible={showEntities}
							unlimitedContent={<PrintoutTemplateEntities form={form} />}
							id={"entities_override"}
						/>
					</div>
				</div>
			</fieldset>
		</FormDirty>
	);
};

export default PrintoutTemplateForm;
