import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheck } from "go-form";
import { ReactComponent as InfoCircleSvg } from "../../../../../../../../../images/svg/dashboard/info-circle.svg";
import { PrintoutTemplateApi } from "../../../../../../../../../services/Api/Organization/types";

interface Props {
	form: UseFormReturn<PrintoutTemplateApi>;
}

const PrintoutTemplateVisibilityForm = ({ form }: Props) => {
	const { t } = useTranslation();
	const {
		formState: { errors },
		register,
	} = form;

	return (
		<Card className={"mb-3"}>
			<Card.Body>
				<h6>
					{t("modules.printout_template.field.visibility.title")}
					<p>
						<small className="text-muted">
							{t("modules.printout_template.field.visibility_desc.title")}
						</small>
					</p>
				</h6>
				<div className={"row"}>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.point_of_sale.title")}
							name={"print_point_of_sale"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("common.word.order_number")}
							name={"print_number"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.direction.title")}
							name={"print_direction"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.employee.title")}
							name={"print_employee"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("common.word.client")}
							name={"print_client"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.delivery_employee.title")}
							name={"print_delivery_employee"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("common.word.address", { ns: "lib" })}
							name={"print_address"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.item_comment.title")}
							name={"print_item_comment"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("common.word.comment")}
							name={"print_comment"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.course.title")}
							name={"print_course"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("common.word.table")}
							name={"print_table"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.seat.title")}
							name={"print_seat"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.number_of_guests.title")}
							name={"print_number_of_guests"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.takeaway.title")}
							name={"print_takeaway"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.included_additions.title")}
							name={"print_included_additions"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.prices.title")}
							name={"print_prices"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.origin_of_item.title")}
							name={"print_origin_of_item"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("common.word.payment_method")}
							name={"print_payment_method"}
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.print_extra_space.title")}
							name="print_extra_space"
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={t("modules.printout_template.field.print_storno.title")}
							name="print_storno"
							errors={errors}
							register={register}
						/>
					</div>
					<div className={"col-md-6"}>
						<FormCheck
							type="switch"
							label={
								<div className="d-flex">
									<span style={{ display: "inline-block" }}>
										{t("modules.printout_template.field.print_items_on_course_printout.title")}
									</span>
									<div className="d-inline-block">
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip id="tooltip">
													<span className="help-text">
														{t(
															"modules.printout_template.field.print_items_on_course_printout.help_text.title"
														)}
													</span>
												</Tooltip>
											}
										>
											<InfoCircleSvg />
										</OverlayTrigger>
									</div>
								</div>
							}
							name="print_items_on_course_printout"
							errors={errors}
							register={register}
							style={{ width: "85%" }}
						/>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default PrintoutTemplateVisibilityForm;
