import React, { useEffect, useReducer, useState } from "react";
import classNames from "classnames";
import { Button, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import handleException from "go-core/api/handleException";
import { useWindowSize } from "go-core/components/useWindowSize";
import List from "go-list/core";
import MobileFilters from "go-list/core/components/Filter/components/MobileFilters/MobileFilters";
import { ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { ReportParamsReducer, reportParamsInitialState } from "go-report/reports/services/reducer";
import { isSegmentChanged, paramsToSegment, segmentToParams } from "go-report/reports/services/segment-service";
import { selectActiveSegment, selectFilters, selectSelectedFilters } from "go-report/reports/services/selectors";
import { GoReportSegmentType, ReportConfig } from "go-report/reports/services/types";
import Segment from "go-segment/components";

interface Props {
	config: ReportConfig;
}

const LiveOrdersReportFilters = ({ config }: Props) => {
	const location = useLocation();
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(ReportParamsReducer, reportParamsInitialState(config, location));
	const [segmentModal, setSegmentModal] = useState(false);
	const selectedGroups = state.groups ? state.groups : [];
	const filters = selectFilters(config);
	const [openFilers, setOpenFilters] = useState(true);
	const selectedFilters = selectSelectedFilters(state, config.filterValues);
	const isMobile = useWindowSize().isMobile;
	const [showMobileFilters, setShowMobileFilters] = useState(false);
	const segment = selectActiveSegment(state);

	useEffect(() => {
		if (isMobile && showMobileFilters) {
			setShowMobileFilters(false);
		}
	}, [isMobile]);

	const onChangeSegment = (segment: GoReportSegmentType) => {
		dispatch({ type: "setSegment", segment: segment.slug });
	};

	const saveSegment = async (
		segment: GoReportSegmentType,
		segmentFilters?: ListSelectedFilter[],
		preventUpdateSegment?: boolean
	) => {
		if (config.saveSegment) {
			const newSegment = {
				...segment,
				filters: segmentFilters
					? selectSelectedFilters({ ...state, filters: [...segmentFilters] })
					: selectSelectedFilters(state),
				columns: [],
				groups: selectedGroups,
				groupColumn: undefined,
				sort: "",
			};
			try {
				const dataSegment = await config.saveSegment(segmentToParams(newSegment));
				dispatch({
					type: "updateSegment",
					data: {
						segment: paramsToSegment(dataSegment.data.data),
						preventReturnUpdatedSegment: preventUpdateSegment,
					},
				});
				setSegmentModal(false);
				return dataSegment.data.data;
			} catch (e) {
				return handleException(e);
			}
		}
	};

	const onSaveSegment = (
		segment: GoReportSegmentType,
		segmentFilters?: ListSelectedFilter[],
		preventUpdateSegment?: boolean
	) => {
		return saveSegment(segment, segmentFilters, preventUpdateSegment);
	};

	const onCreateSegment = async (
		name: string,
		segmentFilters?: ListSelectedFilter[],
		preventUpdateSegment?: boolean
	) => {
		const params = {
			name,
			filters: segmentFilters
				? selectSelectedFilters({ ...state, filters: [...segmentFilters] })
				: selectSelectedFilters(state),
			columns: undefined,
			groups: selectedGroups,
			groupColumn: undefined,
			sort: "",
		} as GoReportSegmentType;
		try {
			return await saveSegment(params, segmentFilters, preventUpdateSegment);
		} catch (e) {
			return handleException(e);
		}
	};

	const onShowSaveSegment = () => {
		setSegmentModal(true);
	};

	const onHideSegmentModal = () => {
		setSegmentModal(false);
	};

	const onChangeFilters = (newFilters: ListSelectedFilter[]) => {
		const dateFilter = newFilters.find((f) => f.filterId === "date_range");
		const dateFilterFromConfig = config.filters?.find((filter) => filter.id === "date_range");

		if (dateFilter && !dateFilterFromConfig?.doNotSaveFilterValueToLocalStorage) {
			window.localStorage.setItem("go_report.filters.date_range", `${dateFilter.condition}=${dateFilter.value}`);
		}

		dispatch({ type: "setFilters", filters: newFilters });
	};

	if (isMobile)
		return (
			<MobileFilters
				filters={filters}
				selectedFilters={selectedFilters}
				onChangeFilters={onChangeFilters}
				setShowMobileFilters={setShowMobileFilters}
				showMobileFilters={showMobileFilters}
				minDate={new Date("2015")}
				maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
				onChangeSegment={(segment) => onChangeSegment(segment as GoReportSegmentType)}
				segments={state.segments}
				hasConfigSaveSegment={!!config.saveSegment}
				segmentModal={segmentModal}
				segment={segment}
				setSegmentModal={setSegmentModal}
				onCreateSegment={(
					name,
					segmentFilters,
					segmentColumns,
					segmentAllColumnsInOrder,
					segmentStickyColumnsDividerPosition,
					preventUpdateSegment
				) => onCreateSegment(name, segmentFilters, preventUpdateSegment)}
				onSaveSegment={(
					segment,
					segmentFilters,
					segmentColumns,
					segmentAllColumnsInOrder,
					segmentStickyColumnsDividerPosition,
					preventUpdateSegment
				) => saveSegment(segment as GoReportSegmentType, segmentFilters, preventUpdateSegment)}
			/>
		);

	return (
		<>
			<div className="list-actions">
				{state.segments && (
					<Segment.List
						selected={state.selectedSegment}
						segments={state.segments}
						onChange={onChangeSegment}
					/>
				)}
				<Button
					variant={classNames("", { primary: openFilers })}
					className={"filter-list-collapse"}
					onClick={() => setOpenFilters(!openFilers)}
					aria-controls="filter-list-collapse"
					aria-expanded={openFilers}
				>
					{selectedFilters && selectedFilters.length > 0
						? `${selectedFilters.length} ${t("lib:common.word.filters")}`
						: `${t("lib:common.word.filters")}`}
				</Button>
				{isSegmentChanged(state, config, segment) && config.saveSegment && (
					<Segment.Save onClick={onShowSaveSegment} />
				)}
			</div>
			{segmentModal && (
				<Segment.SaveModal
					segment={segment ? segment : { id: "all", slug: "all", name: "" }}
					onSave={onSaveSegment}
					onCreate={onCreateSegment}
					onHide={onHideSegmentModal}
				/>
			)}
			{filters && (
				<Collapse in={openFilers}>
					<div id="filter-list-collapse" className={"filters-list"}>
						<List.Filters
							filters={filters}
							selectedFilters={selectedFilters}
							onChange={onChangeFilters}
							minDate={new Date("2015")}
							maxDate={new Date(new Date().getFullYear() + 1, 0, 0)}
						/>
					</div>
				</Collapse>
			)}
		</>
	);
};

export default LiveOrdersReportFilters;
