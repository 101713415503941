import { AxiosRequestConfig, AxiosResponse } from "axios";
import { parseApiData } from "go-core/api/parseApiData";
import { ItemResponse, ListResponse } from "go-core/api/types";
import OrganizationIdService from "../organizationIdService";
import { restSecurityApi } from "./api";
import { getSearchSelectPage, mapToBase } from "./searchSelectService";
import {
	AcceptOrganizationTermsSecurityApi,
	ExternalApplicationSecurityApi,
	OrganizationInvitationLinkApi,
	OrganizationRoleApi,
	OrganizationSecurityApi,
	OrganizationSecurityResponseApi,
	OrganizationUserSecurityApi,
	RemoveOrganizationSecurityApi,
	SavePermissionsApi,
} from "./typesSecurity";

class apiOrganization {
	static getOrganizationId = () => {
		return OrganizationIdService.getWithCheck();
	};

	static createOrganizationApplication(
		companyId: string
	): Promise<AxiosResponse<ItemResponse<OrganizationSecurityApi>>> {
		return restSecurityApi.post(`/security/organizations/register/${companyId}`, {});
	}

	static getCompany(companyId: string): Promise<AxiosResponse<ItemResponse<OrganizationSecurityApi>>> {
		// return restSecurityApi.get(`/security/${id}/me`)
		return restSecurityApi.get(`/security/organizations/register/${companyId}`);
	}

	static getId(companyId: string): Promise<AxiosResponse<Record<string, string>>> {
		// return restSecurityApi.get(`/security/${id}/me`)
		return restSecurityApi.get(`/security/organizations/id/${companyId}`);
	}

	static getOrganizationMe(id: number | string): Promise<AxiosResponse<OrganizationSecurityResponseApi>> {
		// return restSecurityApi.get(`/security/${id}/me`)
		return restSecurityApi.get(`/security/organizations/${id}/me`);
	}

	static acceptTerms(data: AcceptOrganizationTermsSecurityApi): Promise<AxiosResponse> {
		return restSecurityApi.post(`/security/organizations/${this.getOrganizationId()}/accept_terms`, data);
	}

	static getExternalApplications(): Promise<AxiosResponse<ListResponse<ExternalApplicationSecurityApi>>> {
		return restSecurityApi.get(`/security/organizations/${this.getOrganizationId()}/applications`);
	}

	static removeOrganization(data: RemoveOrganizationSecurityApi): Promise<AxiosResponse> {
		const config = { data } as AxiosRequestConfig;
		return restSecurityApi.delete(`/security/organizations/${this.getOrganizationId()}`, config);
	}

	static generateRemoveOrganizationToken(): Promise<AxiosResponse> {
		return restSecurityApi.post(
			`security/organizations/${this.getOrganizationId()}/delete_organization_confirmation_token`
		);
	}

	static getPermissions(): Promise<AxiosResponse> {
		return restSecurityApi.get(`/security/organizations/${this.getOrganizationId()}/permissions`);
	}

	static getRolesSearchSelectDefault(t?: string): Promise<AxiosResponse> {
		return restSecurityApi.get(
			`security/organizations/${this.getOrganizationId()}/permissions/roles/search_select?f=query%7Cc=${t}`
		);
	}

	static async getRolesSearchSelect(search: string, params: Record<string, any>): Promise<AxiosResponse> {
		const f = mapToBase(search, params);
		const page = getSearchSelectPage(params);
		const res = await restSecurityApi.get(
			`security/organizations/${this.getOrganizationId()}/permissions/roles/search_select?f=${f}&page=${page}`
		);
		return res.data.data.map((x: { id: string; label: string; value: any }) => {
			return {
				id: x.id,
				label: x.label,
				role_name: x.label,
				unique_id: x.id,
			};
		});
	}

	static getRoles(params?: Record<string, any>, options?: any): Promise<AxiosResponse> {
		return restSecurityApi.get(`/security/organizations/${this.getOrganizationId()}/permissions/roles`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static savePermissions(data: SavePermissionsApi): Promise<AxiosResponse> {
		return restSecurityApi.put(`/security/organizations/${this.getOrganizationId()}/permissions/roles`, {
			roles: data,
		});
	}

	static updateRole(id: string, organizationRole: OrganizationRoleApi): Promise<AxiosResponse> {
		const params: Record<string, any> = { include: "permissions" };
		return restSecurityApi.put(
			`/security/organizations/${this.getOrganizationId()}/permissions/roles/${id}`,
			organizationRole,
			{ params }
		);
	}

	static createRole(organizationRole: OrganizationRoleApi): Promise<AxiosResponse> {
		const params: Record<string, any> = { include: "permissions" };
		return restSecurityApi.post(
			`/security/organizations/${this.getOrganizationId()}/permissions/roles`,
			organizationRole,
			{ params }
		);
	}

	static removeRole(roleId: string): Promise<AxiosResponse> {
		return restSecurityApi.delete(
			`/security/organizations/${this.getOrganizationId()}/permissions/roles/${roleId}`
		);
	}

	static inviteUser(data: any): Promise<AxiosResponse> {
		return restSecurityApi.post(`/security/organizations/${this.getOrganizationId()}/users`, data);
	}

	static removeUser(email: string): Promise<AxiosResponse> {
		return restSecurityApi.delete(`/security/organizations/${this.getOrganizationId()}/users/${email}`);
	}

	static updateUser(email: string, data: any): Promise<AxiosResponse> {
		return restSecurityApi.put(`/security/organizations/${this.getOrganizationId()}/users/${email}`, data);
	}

	static getUsers(
		params?: Record<string, any> | string,
		options?: any
	): Promise<AxiosResponse<ListResponse<OrganizationUserSecurityApi>>> {
		return restSecurityApi.get(`/security/organizations/${this.getOrganizationId()}/users`, {
			params,
			cancelToken: options?.cancelToken,
		});
	}

	static getUserInvitationLogs(email?: string): Promise<AxiosResponse> {
		return restSecurityApi.get(`/users/invitations/${email}/logs`);
	}

	static async getUsersSearchSelect(t?: string): Promise<AxiosResponse> {
		const res = await restSecurityApi.get(
			`security/organizations/${this.getOrganizationId()}/users/search_select?f=query%7Cc=${t}`
		);
		return res.data.data.map((x: { id: string; label: string; value: any }) => {
			return {
				id: x.id,
				name: x.label,
			};
		});
	}

	static getListUsersSearchSelect(t?: string): Promise<AxiosResponse> {
		return restSecurityApi.get(
			`security/organizations/${this.getOrganizationId()}/users/search_select?f=query%7Cc=${t}`
		);
	}

	static updateOrganizationSupportAccess(data: any): Promise<AxiosResponse> {
		return restSecurityApi.put(`security/organizations/${this.getOrganizationId()}/support_access`, data);
	}

	static getOrganizationSupportAccess(): Promise<AxiosResponse> {
		return restSecurityApi.get(`security/organizations/${this.getOrganizationId()}/support_access`);
	}

	static deleteOrganizationSupportAccess(): Promise<AxiosResponse> {
		return restSecurityApi.delete(`security/organizations/${this.getOrganizationId()}/support_access`);
	}

	static generateInvitationLink(email: string): Promise<OrganizationInvitationLinkApi> {
		return parseApiData(
			restSecurityApi.get(`security/organizations/${this.getOrganizationId()}/users/invitation_link/${email}`)
		);
	}
}

export { apiOrganization };
