import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { RoomElementApi, RoomTableApi } from "../../../../../../../../../../../services/Api/Organization/types";
import RoomElement from "./RoomElement";
import RoomTable from "./RoomTable";
import { RoomFormProps } from "./RoomsPreview";

interface Props {
	form: UseFormReturn<RoomFormProps>;
	defaultRoomIndex: number;
	handleDirty: () => void;
}

const RoomContainer: FC<Props> = ({ form, defaultRoomIndex, handleDirty }) => {
	const { watch } = form;
	const tables = watch(`rooms.${defaultRoomIndex}.tables`) as RoomTableApi[];
	const elements = watch(`rooms.${defaultRoomIndex}.elements`) as RoomElementApi[];
	return (
		<div style={{ position: "relative" }} id={`room-container-${defaultRoomIndex}`}>
			{tables?.map((table, index) => {
				return (
					<RoomTable
						handleDirty={handleDirty}
						key={`tables.${defaultRoomIndex}.${index}`}
						roomIndex={defaultRoomIndex}
						tableIndex={index}
						form={form}
						table={table as RoomTableApi}
					/>
				);
			})}
			{elements?.map((element, index) => {
				return (
					<RoomElement
						handleDirty={handleDirty}
						form={form}
						key={`elements.${defaultRoomIndex}.${index}`}
						roomIndex={defaultRoomIndex}
						elementIndex={index}
						element={element as RoomElementApi}
					/>
				);
			})}
		</div>
	);
};
export default RoomContainer;
