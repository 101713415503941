import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import OrganizationSettingsRoomsDirectionsPage from "./pages/Directions";
import OrganizationSettingsRoomsIndexPage from "./pages/Index";
import OrganizationSettingsRoomsPointOfSalesPage from "./pages/PointOfSales";

const OrganizationSettingsRoomsPage: FC<RouteComponentProps> = (props) => {
	return (
		<div className="page-container">
			<Switch>
				<Route exact={true} path={`${props.match.url}/`} component={OrganizationSettingsRoomsIndexPage} />
				<Route
					path={`${props.match.url}/point-of-sales`}
					component={OrganizationSettingsRoomsPointOfSalesPage}
				/>
				<Route path={`${props.match.url}/directions`} component={OrganizationSettingsRoomsDirectionsPage} />
			</Switch>
		</div>
	);
};

export default OrganizationSettingsRoomsPage;
