import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-leaflet";
import { formatStringToDate } from "go-core/components/Formatters/FormatDate";
import { DeliveryEmployeeApi, LiveOrderApi } from "../../../../../../../../../../services/Api/Organization/types";
import { getOrdersSortedByTheLowestDeliveryTime } from "../../../../services";
import { getDeliveryTimeInSeconds } from "../../../../services/orderDelivery";
import { getOrderStatusColor, statusColors } from "../../../../services/orderStatus";

interface Props {
	orders: LiveOrderApi[];
	employee: DeliveryEmployeeApi;
}

const LiveOrdersMapDeliveryEmployeeMarkerLabel = ({ employee, orders }: Props): JSX.Element => {
	const [deliveryTimeInSeconds, setDeliveryTimeInSeconds] = useState<number>(
		getDeliveryTimeInSeconds(getOrdersSortedByTheLowestDeliveryTime(orders)[0]?.execution_at)
	);
	const deliveryTimeTimeoutRef = useRef<number | undefined>();
	const [currentColor, setCurrentColor] = useState<string>(getOrderStatusColor(deliveryTimeInSeconds));

	useEffect(() => {
		return () => {
			clearTimeout(deliveryTimeTimeoutRef.current);
		};
	}, []);

	useEffect(() => {
		handleDeliveryTimeTimeout();
	}, [JSON.stringify(getOrdersSortedByTheLowestDeliveryTime(orders)[0]?.execution_at)]);

	const handleDeliveryTimeTimeout = () => {
		if (!getOrdersSortedByTheLowestDeliveryTime(orders)[0]?.execution_at) return;

		if (deliveryTimeTimeoutRef.current) {
			clearTimeout(deliveryTimeTimeoutRef.current);
		}
		deliveryTimeTimeoutRef.current = window.setTimeout(() => {
			const newSeconds =
				formatStringToDate(getOrdersSortedByTheLowestDeliveryTime(orders)[0].execution_at).getTime() / 1000 -
				new Date().getTime() / 1000;
			setCurrentColor(getOrderStatusColor(newSeconds));
			setDeliveryTimeInSeconds(newSeconds);
			handleDeliveryTimeTimeout();
		}, 1000);
	};

	const getOrderNumberColor = () => {
		switch (currentColor) {
			case statusColors.YELLOW:
			case statusColors.GRAY:
				return "black";
			case statusColors.RED:
			case statusColors.GREEN:
			default:
				return "white";
		}
	};

	const getEmployeeInitials = (name: string) => {
		const nameParts = name.split(" ");

		if (nameParts.length === 0) return "";
		if (nameParts.length === 1) {
			const firstLetter = (nameParts[0][0] || "").toUpperCase();
			const secondLetter = (nameParts[0][1] || "").toLowerCase();
			return firstLetter + secondLetter;
		}
		if (nameParts.length === 2) {
			const firstLetter = (nameParts[0][0] || "").toUpperCase();
			const secondLetter = (nameParts[1][0] || "").toUpperCase();
			return firstLetter + secondLetter;
		}
		if (nameParts.length >= 3) {
			const firstLetter = (nameParts[0][0] || "").toUpperCase();
			const secondLetter = (nameParts[1][0] || "").toUpperCase();
			const thirdLetter = (nameParts[2][0] || "").toUpperCase();
			return firstLetter + secondLetter + thirdLetter;
		}
	};

	const drawTooltipContent = () => {
		const employeeInitials = getEmployeeInitials(employee.employee?.name || "");
		if (orders.length === 0) {
			return (
				<div className="employee-marker-label-container">
					<div className="employee-marker-label-container-employee-box">
						<span className="employee-name">{employeeInitials}</span>
					</div>
				</div>
			);
		}
		return (
			<div className="employee-marker-label-container">
				<div
					className="employee-marker-label-container-order-box"
					style={{
						backgroundColor: currentColor,
					}}
				>
					<span className="order-number" style={{ color: getOrderNumberColor() }}>
						{orders.length === 1 ? `#${orders[0].number}` : orders.length}
					</span>
				</div>
				<div className="employee-marker-label-container-employee-box">
					<span className="employee-name">{employeeInitials}</span>
				</div>
			</div>
		);
	};

	return (
		<Tooltip direction="top" offset={[0, -11]} permanent opacity={1} className="employee-marker-label">
			{drawTooltipContent()}
		</Tooltip>
	);
};

export default LiveOrdersMapDeliveryEmployeeMarkerLabel;
