import React from "react";
import { useTranslation } from "react-i18next";

const LiveOrdersBadInternetConnectionBar = () => {
	const { t } = useTranslation();

	return (
		<div className="live-orders-refresh-page-bar">
			<span>{t("modules.live_order.field.bad_internet_connection.title")}</span>
		</div>
	);
};

export default LiveOrdersBadInternetConnectionBar;
