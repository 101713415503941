import { RefObject, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";

interface Props {
	search?: string;
	fetchItems: (params: Record<string, any>, options?: CancelTokenSource) => Promise<any[]> | any[];
	scrollContainerElementRef: RefObject<HTMLElement>;
	fetchDependencies?: any[];
}

export const useInfiniteScroll = ({
	fetchItems,
	search,
	scrollContainerElementRef,
	fetchDependencies,
}: Props): Record<string, any> => {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState<any[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const [isOnTheBottom, setIsOnTheBottom] = useState(true);
	const cancelTokenSource = useRef<CancelTokenSource>(axios.CancelToken.source());
	const { addFlash } = useFlash();
	const dependencies = [search, [...(fetchDependencies || [])]];
	const dependenciesAsString = JSON.stringify(dependencies);

	useEffect(() => {
		if (isOnTheBottom) handleLoadItems();
	}, [isOnTheBottom]);

	useEffect(() => {
		handleCancelTokenAndGetNew();
		setIsOnTheBottom(false);
		setItems([]);
		setPage(0);
		setHasMore(true);
		setTimeout(() => {
			setIsOnTheBottom(true);
		}, 1);
	}, [dependenciesAsString]);

	useEffect(() => {
		registerScrollHandler();

		return () => {
			unregisterScrollHandler();
		};
	}, [page, hasMore, loading]);

	const handleCancelTokenAndGetNew = () => {
		cancelTokenSource.current.cancel();
		const CancelToken = axios.CancelToken;
		cancelTokenSource.current = CancelToken.source();
	};

	const handleLoadItems = async () => {
		if (!hasMore) return;
		setLoading(true);
		try {
			const params: Record<string, any> = {
				page,
				search,
			};
			const data = await fetchItems(params, cancelTokenSource.current);
			setItems([...items, ...data]);
			if (data.length < 20) {
				setHasMore(false);
				setIsOnTheBottom(false);
				setLoading(false);
				return;
			}
			setPage(page + 1);
			setIsOnTheBottom(false);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
		setLoading(false);
	};

	const handleScroll = (scrollEvent: Event) => {
		if (!hasMore || loading) return;
		const target = scrollEvent.target as HTMLElement;
		if (!target) return;
		const isScrolledToEnd = Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <= 1;
		setIsOnTheBottom(isScrolledToEnd);
	};

	const registerScrollHandler = () => {
		if (!scrollContainerElementRef.current) return;
		scrollContainerElementRef.current.addEventListener("scroll", handleScroll);
	};

	const unregisterScrollHandler = () => {
		if (!scrollContainerElementRef.current) return;
		scrollContainerElementRef.current.removeEventListener("scroll", handleScroll);
	};

	const updateItem = (itemIndex: number, item: any) => {
		items[itemIndex] = item;
		setItems(items);
	};

	return { loading, items, updateItem };
};
