import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import unidecode from "unidecode";
import useFlash from "go-alert/AlertMessage";
import { LoadingContainer } from "go-core/components/Loading";
import PdfGenerationOptionsModal from "go-core/components/PdfGenerationOptionsModal";
import { PdfOrientationType } from "go-core/types";
import { createCSVFile } from "go-list/core/components/Actions/services";
import { listFiltersEncode } from "go-list/core/components/Filter/services/decoder";
import { ListConfigFilter, ListSelectedFilter } from "go-list/core/components/Filter/services/types";
import { selectSelectedFiltersFullNames } from "go-list/list/services/selectors";
import isPredefinedRange, { parsePredefinedRangeToDefaultString } from "go-list/utils/daterangeutils";
import { ReactComponent as SortDownSVG } from "go-report/core/images/sort-down.svg";
import { ReactComponent as SortUpSVG } from "go-report/core/images/sort-up.svg";
import { GoReportSegmentType, ReportParamsType } from "../../../reports/services/types";
import { exportToCsv, exportToPdf } from "../../services/export-service";
import { ReportApi, ReportConfigColumn, SubReportApi } from "../../services/types";
import { getAggregateValueById, getVisibleColumns, isComparable, renderGroupByName } from "../../services/utils";
import { ExportConfig } from "../Actions/services/types";
import TableActions from "../TableActions";
import SortableGroups from "./SortableGroups";
import Table from "./Table";
import TableGroup from "./TableGroup";

interface ReportTableProps {
	data: ReportApi;
	initialData?: ReportApi;
	columns: ReportConfigColumn[];
	loading?: boolean;
	isGroupTable: boolean;
	aggregatePrefix?: string;
	comparable?: boolean;
	selectedColumns: string[];
	onChangeSelectedColumn: (selectedColumns: Array<string>, sortedColumns: Array<string>) => void;
	showPercent: boolean;
	onShowPercentChange: () => void;
	selectedGroups: string[];
	groups?: Array<Record<string, string>>;
	groupColumn: string | undefined;
	onChangeGroups: (checkedGroups: Array<string>) => void;
	onChangeGroupColumn: (groupColumn: string | undefined) => void;
	selectedFilters: ListSelectedFilter[];
	groupsInfo?: Record<string, any>;
	filtersConfig: ListConfigFilter[];
	exportConfig?: ExportConfig;
	selectedSegment?: GoReportSegmentType;
	selectedSort?: string;
	onSort: (sort: string) => void;
	state: ReportParamsType;
}

const ReportTable: FC<ReportTableProps> = (props) => {
	const [aggregatePrefix, setAggregatePrefix] = useState<string>("");
	const [sort, setSort] = useState<string>("name");
	const initialRender = useRef(true);
	const { addFlash } = useFlash();
	const { t } = useTranslation();
	const [showPdfGenerationOptionsModal, setShowPdfGenerationOptionsModal] = useState(false);
	const openPrintoutWindowRef = useRef(false);
	const [groupColumn, setGroupColumn] = useState(props.groupColumn);

	const onSort = (type: string, aggregatePrefix?: string) => {
		if (!type.includes("-") && type !== "name" && type !== "-name") {
			type = `-${type}`;
		}
		if (aggregatePrefix) {
			setAggregatePrefix(aggregatePrefix);
			if (sort === `${type}_${aggregatePrefix}`) {
				type = type.slice(1);
			}
		} else if (sort === type) {
			if (sort === "name") {
				type = "-name";
			} else if (sort === "-name") {
				type = "name";
			} else {
				type = type.slice(1);
			}
		}

		const prefix = aggregatePrefix ? `_${aggregatePrefix}` : "";
		setSort(`${type}${prefix}`);
		props.onSort(`${type}${prefix}`);
	};

	useEffect(() => {
		setSort(props?.selectedSort || "name");
	}, [props.selectedSegment]);

	const isAggregateValueADate = (dateValue: string) => {
		const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
		return dateRegex.test(dateValue);
	};

	const sortByName = (a: SubReportApi, b: SubReportApi) => {
		let agg_1 = a.group_by_value.name;
		let agg_2 = b.group_by_value.name;

		const agg1IsDate = isAggregateValueADate(agg_1);
		const agg2IsDate = isAggregateValueADate(agg_2);
		const agg1IsNumber = !isNaN(Number(agg_1));
		const agg2IsNumber = !isNaN(Number(agg_2));

		if (agg1IsDate && agg2IsDate) {
			agg_1 = new Date(agg_1).getTime();
			agg_2 = new Date(agg_2).getTime();
		} else if (agg1IsNumber && agg2IsNumber) {
			agg_1 = Number(agg_1);
			agg_2 = Number(agg_2);
		} else {
			agg_1 = unidecode(agg_1.toString()).toUpperCase();
			agg_2 = unidecode(agg_2.toString()).toUpperCase();

			if (agg_1 === "EMPTY") return -1;
			if (agg_2 === "EMPTY") return 1;

			const valueToBeReturned = agg_1.localeCompare(agg_2);

			return sort.includes("-") ? -valueToBeReturned : valueToBeReturned;
		}
		if (sort.includes("-")) {
			return agg_1 > agg_2 ? -1 : 1;
		}
		return agg_1 < agg_2 ? -1 : 1;
	};

	const sortByAggregate = (a: SubReportApi, b: SubReportApi) => {
		const agg_1 = getAggregateValueById(
			sort.includes("-") ? sort.split("-")[1] : sort,
			a,
			aggregatePrefix,
			props.aggregatePrefix
		);
		const agg_2 = getAggregateValueById(
			sort.includes("-") ? sort.split("-")[1] : sort,
			b,
			aggregatePrefix,
			props.aggregatePrefix
		);
		if (sort.includes("-")) {
			return agg_1 > agg_2 ? -1 : 1;
		}
		return agg_1 < agg_2 ? -1 : 1;
	};

	const sortSubReports = (data: SubReportApi[]): SubReportApi[] => {
		if (data.length === 0) {
			return data;
		}
		data.forEach((subReport) => {
			if (subReport.sub_report?.length > 0) {
				if (sort === "name" || sort === "-name") {
					subReport.sub_report.sort(sortByName);
				} else {
					subReport.sub_report.sort(sortByAggregate);
				}
				sortSubReports(subReport.sub_report);
			}
		});
		return data;
	};

	const sortReports = (data: ReportApi): ReportApi => {
		if (data.reports) {
			if (data.reports.length === 0) {
				return data;
			}
			sortSubReports(data.reports);
			return data;
		}
		return data;
	};

	const generateGroupReport = (data: ReportApi) => {
		const newReport = { reports: [] } as ReportApi;
		const subReports: SubReportApi[] = [];
		let aggregationHeader = "";
		data.reports?.[0]?.sub_report?.forEach((rep) => {
			if (rep && rep.group_by_type && rep.group_by_value?.name) {
				aggregationHeader = rep.group_by_type;
				getSubReport(rep.sub_report, 1, `${rep.group_by_type}_${rep.group_by_value.name}`, subReports);
			}
		});
		const newSubReport = {} as SubReportApi;
		newSubReport.sub_report = subReports;
		newSubReport.group_by_type = "NONE";
		newSubReport.group_by_value = null;
		newSubReport.aggregate = {};
		newSubReport.compare_aggregate = {};
		const compareSummaryAggregate = {};
		const summaryAggregate = {};
		data.reports.forEach((rep) => {
			addSummaryAggregate(rep.aggregate, summaryAggregate);
			if (props.comparable) {
				addSummaryAggregate(rep.compare_aggregate, compareSummaryAggregate);
			}
		});
		addSummaryHeaders([newSubReport], aggregationHeader, Boolean(props.comparable));
		newSubReport.aggregate = summaryAggregate;
		if (props.comparable) {
			newSubReport.compare_aggregate = compareSummaryAggregate;
			addSummaryHeaders([newSubReport], aggregationHeader, props.comparable);
		}
		newReport.reports.push(newSubReport);
		const sortedData = sortReports(newReport);
		return sortedData;
	};

	const addSummaryHeaders = (reports: SubReportApi[], aggregationHeader: string, comparable: boolean) => {
		reports.forEach((rep) => {
			const summaryAggregates: any = [];
			const compareSummaryAggregates: any = [];
			Object.keys(rep.aggregate).forEach((key) => {
				if (key !== aggregationHeader) {
					addSummaryAggregate(rep.aggregate[key], summaryAggregates);
				}
			});

			Object.keys(summaryAggregates).forEach((key) => {
				rep.aggregate[key] = summaryAggregates[key];
			});

			Object.keys(rep.compare_aggregate).forEach((key) => {
				if (key !== aggregationHeader) {
					addSummaryAggregate(rep.compare_aggregate[key], compareSummaryAggregates);
				}
			});

			Object.keys(compareSummaryAggregates).forEach((key) => {
				rep.compare_aggregate[key] = compareSummaryAggregates[key];
			});

			if (rep.sub_report.length > 0) {
				addSummaryHeaders(rep.sub_report, aggregationHeader, comparable);
			}
		});
	};
	const addSummaryAggregate = (aggregate: any, summaryAggregate: any) => {
		if (!aggregate) return;
		Object.keys(aggregate).forEach((key) => {
			const obj = aggregate[key];
			if (!Object.prototype.hasOwnProperty.call(summaryAggregate, key)) {
				if (typeof obj === "object") {
					summaryAggregate[key] = {};
					addSummaryAggregate(obj, summaryAggregate[key]);
				} else {
					summaryAggregate[key] = obj;
				}
			} else if (typeof obj === "object") {
				addSummaryAggregate(obj, summaryAggregate[key]);
			} else if (obj === parseFloat(obj)) {
				summaryAggregate[key] = Math.round((summaryAggregate[key] + obj) * 100) / 100;
			}
		});
	};

	const getSubReport = (
		reports: SubReportApi[],
		level: number,
		aggregateHeader: string,
		parentReports: SubReportApi[]
	) => {
		reports.forEach((rep) => {
			const reportDb = addReport(rep, level, aggregateHeader, parentReports);
			if (rep.sub_report.length > 0) {
				const newParentReports: SubReportApi[] = reportDb?.sub_report;
				const nextLevel = level + 1;
				getSubReport(rep.sub_report, nextLevel, aggregateHeader, newParentReports);
			}
		});
	};

	const addReport = (
		report: SubReportApi,
		level: number,
		aggregateHeader: string,
		parentReports: SubReportApi[]
	): SubReportApi => {
		const groupByType = report.group_by_type;
		const groupByValue = report.group_by_value;
		const reportDb = parentReports.filter(
			(f) => f.group_by_type === groupByType && f.group_by_value.name === groupByValue.name
		);
		let newReportDb: SubReportApi | null = null;
		if (!reportDb || reportDb.length === 0) {
			newReportDb = {
				aggregate: {},
				compare_aggregate: {},
				group_by_type: groupByType,
				group_by_value: groupByValue,
				sub_report: [] as SubReportApi[],
			} as SubReportApi;
			parentReports.push(newReportDb);
		} else {
			newReportDb = reportDb[0];
		}
		newReportDb.aggregate[aggregateHeader] = report.aggregate;
		newReportDb.compare_aggregate[aggregateHeader] = report.compare_aggregate;
		return newReportDb;
	};

	const visibleColumns = getVisibleColumns(props.selectedColumns, props.columns);

	const onShowPercentChange = () => {
		props.onShowPercentChange();
	};

	const data = props.isGroupTable ? generateGroupReport(props.data) : sortReports(props.data);

	const onCsvExport = () => {
		exportToCsv(
			visibleColumns,
			data,
			props.isGroupTable ? props.data.reports?.[0].sub_report : props.data.reports,
			isComparable(props.selectedFilters),
			t,
			props.aggregatePrefix ? props.aggregatePrefix : "summary",
			props.groupsInfo,
			props.exportConfig,
			props.groups
		);
	};

	const handleExportToPdf = (openPrintoutWindow = false, orientation?: PdfOrientationType, fontSize?: string) => {
		const exportConfig: ExportConfig = {
			...props.exportConfig,
		};

		if (exportConfig.pdfOrientation) {
			exportConfig.pdfOrientation =
				exportConfig.pdfOrientation === "ASK" ? orientation || "LANDSCAPE" : exportConfig.pdfOrientation;
		}

		if (fontSize) {
			exportConfig.pdfFontSize = fontSize;
		}

		exportToPdf(
			visibleColumns,
			selectSelectedFiltersFullNames(props.selectedFilters, props.filtersConfig),
			data,
			props.isGroupTable ? props.data.reports?.[0].sub_report : props.data.reports,
			isComparable(props.selectedFilters),
			props.showPercent,
			props.aggregatePrefix ? props.aggregatePrefix : "summary",
			openPrintoutWindow,
			addFlash,
			t,
			props.groupsInfo,
			exportConfig
		);
		openPrintoutWindowRef.current = false;
	};

	const onPdfExport = (openPrintoutWindow = false, orientation?: PdfOrientationType, fontSize?: string) => {
		if (props.exportConfig?.pdfOrientation === "ASK" || props.exportConfig?.pdfFontSize === "0") {
			setShowPdfGenerationOptionsModal(true);
			return;
		}

		handleExportToPdf(openPrintoutWindow, orientation, fontSize);
	};

	const handlePrintPdf = (orientation?: PdfOrientationType, fontSize?: string) => {
		openPrintoutWindowRef.current = true;
		onPdfExport(openPrintoutWindowRef.current, orientation, fontSize);
	};

	const CSVExportWithEveryColumnHandler = async () => {
		const listFilters = props.state.filters ? JSON.parse(JSON.stringify(props.state.filters)) : undefined;

		listFilters?.forEach((listFilter: ListSelectedFilter) => {
			if (isPredefinedRange(listFilter.value)) {
				listFilter.value = parsePredefinedRangeToDefaultString(listFilter.value);
				listFilter.condition = "bt";
			}
		});

		const params: Record<string, any> = listFilters
			? {
					f: listFiltersEncode(listFilters),
			  }
			: {};
		const receivedCSVAsStringCreatedOnBackend = await props.exportConfig?.exportCSVWithEveryColumn?.(params);

		createCSVFile(receivedCSVAsStringCreatedOnBackend, props.exportConfig);
	};

	const currentlySorted = (type: string, aggregatePrefix?: string) => {
		if (aggregatePrefix) {
			type = `${type}_${aggregatePrefix}`;
		}
		if (sort === type) {
			return <SortUpSVG />;
		} else if (sort === `-${type}`) {
			return <SortDownSVG />;
		}
	};

	const onChangeGroups = (selectedGroups: Array<string>) => {
		if (selectedGroups.length === 0) {
			props.onChangeGroups(["NONE"]);
		} else props.onChangeGroups(selectedGroups);
	};

	const updateGroupColumn = (groupColumn: string | undefined) => {
		setGroupColumn(groupColumn);
		props.onChangeGroupColumn(groupColumn);
	};

	const renderSortableGroups = useMemo(() => {
		if (props.groups) {
			return (
				<SortableGroups
					selectedGroups={props.selectedGroups}
					groups={props.groups}
					onChangeGroups={onChangeGroups}
				/>
			);
		}
		return <div className="sortable-groups" />;
	}, [initialRender.current, props.selectedSegment, props.groups]);

	return (
		<>
			{showPdfGenerationOptionsModal && (
				<PdfGenerationOptionsModal
					show={showPdfGenerationOptionsModal}
					onHide={() => setShowPdfGenerationOptionsModal(false)}
					onSubmit={(orientation: PdfOrientationType, fontSize: string) =>
						handleExportToPdf(openPrintoutWindowRef.current, orientation, fontSize)
					}
					defaultValues={{
						fontSize: props?.exportConfig?.pdfFontSize,
						orientation:
							props?.exportConfig?.pdfOrientation === "ASK"
								? undefined
								: props?.exportConfig?.pdfOrientation,
					}}
				/>
			)}
			<div className="mb-3">
				<TableActions
					groups={props.groups}
					groupColumn={groupColumn}
					updateGroupColumn={updateGroupColumn}
					handleCsvExport={onCsvExport}
					handlePdfExport={onPdfExport}
					handlePrintPdf={handlePrintPdf}
					columns={props.columns}
					onChangeSelectedColumn={props.onChangeSelectedColumn}
					visibleColumns={visibleColumns}
					showPercent={props.showPercent}
					onShowPercentChange={onShowPercentChange}
					exportCSVWithEveryColumn={props.exportConfig?.exportCSVWithEveryColumn}
					CSVExportWithEveryColumnHandler={CSVExportWithEveryColumnHandler}
				/>
				{renderSortableGroups}
			</div>
			<div className="reports-container">
				<table className="table table-report mb-0">
					<thead>
						{props.isGroupTable ? (
							<>
								<tr>
									<th />
									{props.data.reports?.[0]?.sub_report.map((item, index) => {
										return (
											<th colSpan={visibleColumns.length} key={index}>
												{props.groupsInfo &&
												Object.prototype.hasOwnProperty.call(
													props.groupsInfo,
													`${item.group_by_type}`
												) ? (
													<>
														{props.groupsInfo[`${item.group_by_type}`](item.group_by_value)}
													</>
												) : (
													<>{renderGroupByName(item.group_by_value.name)}</>
												)}
											</th>
										);
									})}
									<th colSpan={visibleColumns.length}>{t("lib:common.word.summary")}</th>
								</tr>
								<tr>
									<th
										style={{ position: "sticky", left: "-16px", zIndex: 3 }}
										onClick={() => onSort("name")}
									>
										{t("lib:common.word.name")} {currentlySorted("name")}
									</th>
									{props.data.reports?.[0]?.sub_report.map((item, parentIndex) => {
										return visibleColumns.map((col, index) => {
											return (
												<th
													onClick={() =>
														onSort(
															col.id,
															`${item.group_by_type}_${item.group_by_value.name}`
														)
													}
													key={`${parentIndex}_${index}`}
												>
													{col.name}{" "}
													{currentlySorted(
														col.id,
														`${item.group_by_type}_${item.group_by_value.name}`
													)}
												</th>
											);
										});
									})}
									{visibleColumns.map((col, index) => {
										return (
											<th onClick={() => onSort(col.id)} key={`${index}`}>
												{col.name} {currentlySorted(col.id)}
											</th>
										);
									})}
								</tr>
							</>
						) : (
							<tr>
								<th onClick={() => onSort("name")}>
									{t("lib:common.word.name")} {currentlySorted("name")}
								</th>
								{visibleColumns.map((col, index) => {
									return (
										<th onClick={() => onSort(col.id)} key={index}>
											{col.name}
											{currentlySorted(col.id)}
										</th>
									);
								})}
							</tr>
						)}
					</thead>
					{props.loading ? (
						<LoadingContainer />
					) : (
						<>
							{props.isGroupTable ? (
								<TableGroup
									columns={visibleColumns}
									data={data}
									initialData={props.data}
									aggregatePrefix={props.aggregatePrefix}
									groupsInfo={props.groupsInfo}
									comparable={props.comparable}
									showPercent={props.showPercent}
								/>
							) : (
								<Table
									data={data}
									columns={visibleColumns}
									groupsInfo={props.groupsInfo}
									aggregatePrefix={props.aggregatePrefix}
									comparable={props.comparable}
									showPercent={props.showPercent}
									selectedGroups={props.selectedGroups}
									groups={props.groups}
								/>
							)}
						</>
					)}
				</table>
			</div>
		</>
	);
};
export default memo(ReportTable);
