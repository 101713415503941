import React, { FC, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { delay } from "go-core";
import FormatDate from "go-core/components/Formatters/FormatDate";
import { useWindowSize } from "go-core/components/useWindowSize";
import { ReactComponent as DeleteSVG } from "../../../../images/delete.svg";
import {
	FILTER_VALUE_SEPARATOR,
	booleanFilterOptions,
	dateFilterOptions,
	numberFilterOptions,
	textFilterOptions,
} from "../../services";
import { FilterCustomAction, FilterCustomDateRange, ListConfigFilter, ListSelectedFilter } from "../../services/types";
import ListFilterBoolean from "../Boolean";
import ListFilterDate from "../Date";
import ListFilterList from "../List";
import ListFilterNumber from "../Number";
import ListFilterRadio from "../Radio";
import ListFilterSearchSelect from "../SearchSelect";
import ListFilterText from "../Text";

interface State {
	id: string;
	filter: ListConfigFilter;
	selectedFilter: ListSelectedFilter;
	openFilter: string | null;
	onChange: (oldFilter: ListSelectedFilter, newFilter: ListSelectedFilter | null) => void;
	removeFilter: (filter: ListSelectedFilter) => void;
	maxDate?: Date | string;
	minDate?: Date | string;
	onCloseFilter: () => void;
	dateRangePickerCustomActions?: FilterCustomAction[];
	dateRangePickerCustomRanges?: FilterCustomDateRange[];
}

const TranslationsInitialized = () => {
	const { t } = useTranslation();
	t("lib:go_list.filters.deleted");
	t("lib:go_list.filters.deleted_male");
	t("lib:go_list.filters.c");
	t("lib:go_list.filters.e");
	t("lib:go_list.filters.ne");
	t("lib:go_list.filters.a");
	t("lib:go_list.filters.u");
	t("lib:go_list.filters.lt");
	t("lib:go_list.filters.gt");
	t("lib:go_list.filters.gte");
	t("lib:go_list.filters.lte");
	t("lib:go_list.filters.bt");
	t("lib:go_list.filters.unknown");
	t("lib:go_list.filters.any");
};

export const renderFilterValue = (
	selectedFilter: ListSelectedFilter,
	filter: ListConfigFilter,
	t: TFunction<"translation", undefined>
) => {
	const allFiltersOptions = [
		...textFilterOptions(t),
		...numberFilterOptions(t),
		...booleanFilterOptions(t),
		...dateFilterOptions(t),
	];
	const filterCondition =
		selectedFilter.condition && selectedFilter.condition === "btd" ? "bt" : selectedFilter.condition;
	const filterOption = allFiltersOptions.filter((option) => option.id === filterCondition)[0];
	if (filterOption) {
		switch (filter.type) {
			case "label_search_select":
			case "search_select":
				if (selectedFilter.valueInfo !== undefined && Object.keys(selectedFilter.valueInfo).length > 0) {
					const filterString: string[] = [];
					let valueToPush;
					for (const [key, value] of Object.entries(selectedFilter.valueInfo)) {
						if (value) {
							const item = value as any;
							valueToPush = item.name ? item.name : item;
						}
						if (filter.options) {
							Object.entries(filter.options).forEach(([key, val]) => {
								if (value === key) {
									valueToPush = val;
								}
							});
						}
						filterString.push(valueToPush);
					}
					return `${filterString.join(", ")}`;
				}
				if (selectedFilter.value === "a" || selectedFilter.value === "u") {
					const conditionCustomLabelConfig = filter.conditionCustomLabelConfig?.find(
						({ condition }) => condition === selectedFilter.value
					);

					if (conditionCustomLabelConfig) return conditionCustomLabelConfig.label;

					return t(`lib:go_list.filters.${selectedFilter.value}`);
				}
				return selectedFilter.value.split(FILTER_VALUE_SEPARATOR).join(", ");

			case "list":
			case "radio": {
				if (selectedFilter.value === "a" || selectedFilter.value === "u")
					return t(`lib:go_list.filters.${selectedFilter.value}`);
				const options = filter.options || filter.listOptions;
				if (typeof options === "object") {
					const filterString: string[] = [];
					const values = selectedFilter.value?.split(FILTER_VALUE_SEPARATOR) || [];
					Object.entries(options).forEach(([key, value]) => {
						const matchedValue = values.filter(
							(f) => f === (filter.filterByListOptionValue ? value : key)
						)[0];
						if (matchedValue) {
							filterString.push(value);
						}
					});
					return `${filterString.join(", ")}`;
				}
				break;
			}
			case "date_range": {
				const dates = selectedFilter.value.split(" - ");
				return `${FormatDate(dates[0])} - ${FormatDate(dates[1])}`;
			}
			case "boolean":
				return `${filterOption.name}`;
			case "date":
				// if (isPredefinedRange(filter.value)) {
				//     return t(`lib:go_form.date_range_picker.ranges.${filter.value}`)
				// }
				if (selectedFilter.condition === "btd" && selectedFilter.value) {
					return t(`lib:go_form.date_range_picker.ranges.${selectedFilter.value}`);
				}
				if (selectedFilter.value === "" || selectedFilter.value === ";") {
					return "";
				}
				if (filterOption.id === "bt") {
					const dates = selectedFilter.value.split(FILTER_VALUE_SEPARATOR);
					let dateFrom = "",
						dateTo = "";
					if (dates[0] && dates[0] !== "undefined") {
						dateFrom = FormatDate(dates[0]);
					}
					if (dates[1] && dates[1] !== "undefined") {
						dateTo = FormatDate(dates[1]);
					}
					return `${dateFrom} - ${dateTo}`;
				}
				if (filterOption.id === "u") {
					return t(`lib:go_list.filters.u`);
				}
				if (filterOption.id === "a") {
					return t(`lib:go_list.filters.a`);
				}
				return `${t(`lib:go_list.filters.${filterOption.id}`)} ${FormatDate(selectedFilter.value)}`;

			case "number":
				if (selectedFilter.value === "a" || selectedFilter.value === "u")
					return t(`lib:go_list.filters.${selectedFilter.value}`);
				if (filterOption.id === "bt") {
					const values = selectedFilter.value.split(FILTER_VALUE_SEPARATOR);
					return `${values[0] !== "" ? `${t(`lib:common.word.from`)} ${values[0]}` : ""} ${
						values[1] !== "" ? `${t(`lib:common.word.to`)} ${values[1]}` : ""
					}`;
				}
				return `${t(`lib:go_list.filters.${filterOption.id}`)} ${selectedFilter.value}`;
		}
		if (filterOption.isValue) {
			return `${filterOption.name} ${selectedFilter.value}`;
		}
		return `${filterOption.name}`;
	}
	return selectedFilter.value;
};

const ListFilterItem: FC<State> = (props) => {
	const [openFilter, setOpenFilter] = useState<string | null>(props.openFilter);
	const [draftFilter, setDraftFilter] = useState<ListSelectedFilter | null>(null);
	const [isComponentVisible, setIsComponentVisible] = useState(openFilter === props.id);
	const ref = useRef<HTMLDivElement>(null);
	const removeButtonRef = useRef<HTMLButtonElement>(null);
	const { t } = useTranslation();
	const isMobile = useWindowSize().isMobile;

	const handleChangeFilterOptionsModalPositionEvent = () => {
		const event = document.createEvent("Event");
		event.initEvent("scroll", true, true);
		window.dispatchEvent(event);
	};

	useEffect(() => {
		if (isMobile) {
			setTimeout(() => {
				handleChangeFilterOptionsModalPositionEvent();
			}, 10);
		}
	}, [draftFilter, isMobile]);

	const areTwoFiltersEqual = (filter1: ListSelectedFilter, filter2: ListSelectedFilter): boolean => {
		return (
			filter1.filterId === filter2.filterId &&
			filter1.condition === filter2.condition &&
			filter1.value === filter2.value
		);
	};

	const updateDraftFilter = (filter: ListSelectedFilter) => {
		if (draftFilter === null) {
			setDraftFilter(filter);
		} else if (!areTwoFiltersEqual(draftFilter, filter)) {
			setDraftFilter(filter);
		}
	};

	const renderFilterOptions = (selectedFilter: ListSelectedFilter, filter: ListConfigFilter) => {
		switch (filter.type) {
			case "text":
			case "long_text":
				return (
					<ListFilterText
						id={props.id}
						filter={filter}
						selectedFilter={selectedFilter}
						onChange={(condition, value) => {
							if ((!value && condition === "a") || condition === "u") {
								value = condition;
							}
							if (draftFilter === null) {
								setDraftFilter({
									...selectedFilter,
									value,
									condition,
									resources: filter.resources,
								});
							} else {
								setDraftFilter({
									...draftFilter,
									value,
									condition,
									resources: filter.resources,
								});
							}
						}}
					/>
				);
			case "list":
				return (
					<ListFilterList
						id={props.id}
						filter={filter}
						selectedFilter={selectedFilter}
						onChange={(condition, value) => {
							if (draftFilter === null) {
								setDraftFilter({
									...selectedFilter,
									value,
									condition,
									resources: filter.resources,
								});
							} else {
								setDraftFilter({
									...draftFilter,
									value,
									condition,
									resources: filter.resources,
								});
							}
						}}
					/>
				);
			case "number":
				return (
					<ListFilterNumber
						id={props.id}
						filter={filter}
						selectedFilter={selectedFilter}
						onChange={(condition, value) => {
							if (!value && (condition === "a" || condition === "u")) {
								value = condition;
							}
							if (draftFilter === null) {
								setDraftFilter({
									...selectedFilter,
									value,
									condition,
									resources: filter.resources,
								});
							} else {
								setDraftFilter({
									...draftFilter,
									value,
									condition,
									resources: filter.resources,
								});
							}
						}}
					/>
				);
			case "search_select":
			case "label_search_select":
				return (
					<ListFilterSearchSelect
						id={props.id}
						filter={filter}
						selectedFilter={selectedFilter}
						isVisible={isComponentVisible}
						onChange={(condition, value: any) => {
							if (draftFilter === null) {
								updateDraftFilter({
									...selectedFilter,
									...(condition !== "a" && condition !== "u"
										? { value: Object.keys(value).join(FILTER_VALUE_SEPARATOR) }
										: { value }),
									...(condition !== "a" && condition !== "u"
										? { valueInfo: value }
										: { valueInfo: {} }),
									condition,
									resources: filter.resources,
								});
							} else {
								updateDraftFilter({
									...draftFilter,
									...(condition !== "a" && condition !== "u"
										? { value: Object.keys(value).join(FILTER_VALUE_SEPARATOR) }
										: { value }),
									...(condition !== "a" && condition !== "u"
										? { valueInfo: value }
										: { valueInfo: {} }),
									condition,
									resources: filter.resources,
								});
							}
						}}
					/>
				);
			// case "date_range":
			//     return <ListFilterDateRange id={props.id} filter={filter} selectedFilter={selectedFilter}
			//                                 minDate={props.minDate} maxDate={props.maxDate}
			//                                 onChange={(condition, value: any, autoApply) => {
			//
			//                                     if (draftFilter === null) {
			//                                         setDraftFilter({
			//                                             ...selectedFilter,
			//                                             value: value,
			//                                             condition: condition
			//                                         });
			//                                     } else {
			//                                         setDraftFilter({
			//                                             ...draftFilter,
			//                                             value: value,
			//                                             condition: condition
			//                                         });
			//                                     }
			//                                     if (autoApply) {
			//                                         props.selectedFilter.value = value;
			//                                         props.onChange(props.selectedFilter, draftFilter);
			//                                         setIsComponentVisible(false)
			//                                     }
			//                                 }}/>;
			case "boolean":
				return (
					<ListFilterBoolean
						id={props.id}
						filter={filter}
						selectedFilter={selectedFilter}
						onChange={(condition, value: any) => {
							if (draftFilter === null) {
								setDraftFilter({
									...selectedFilter,
									value,
									condition,
									resources: filter.resources,
								});
							} else {
								setDraftFilter({
									...draftFilter,
									value,
									condition,
									resources: filter.resources,
								});
							}
						}}
					/>
				);
			case "radio":
				return (
					<ListFilterRadio
						id={props.id}
						filter={filter}
						selectedFilter={selectedFilter}
						onChange={(condition, value: any) => {
							if (draftFilter === null) {
								setDraftFilter({
									...selectedFilter,
									value,
									condition,
									resources: filter.resources,
								});
							} else {
								setDraftFilter({
									...draftFilter,
									value,
									condition,
									resources: filter.resources,
								});
							}
						}}
					/>
				);
			case "date":
			case "date_range": {
				let tmpFilter = filter;
				if (filter.type === "date_range") {
					tmpFilter = { ...filter };
					tmpFilter.options = ["bt"];
				}
				const filterActions = props.dateRangePickerCustomActions?.filter(
					(action) => action.filterId === filter.id
				);
				const thisFilterDateRanges = props.dateRangePickerCustomRanges?.filter(
					(range) => range.filterId === filter.id
				);
				const dateRanges =
					thisFilterDateRanges && thisFilterDateRanges.length > 0 ? thisFilterDateRanges : undefined;
				return (
					<ListFilterDate
						id={props.id}
						filter={tmpFilter}
						selectedFilter={selectedFilter}
						maxDate={props.maxDate}
						minDate={props.minDate}
						dateRangePickerCustomActions={filterActions}
						dateRangePickerCustomRanges={dateRanges}
						onChange={async (condition, value, autoApply) => {
							if (!value && (condition === "a" || condition === "u")) {
								value = condition;
							}
							if (draftFilter === null) {
								// if (condition === "bt" && !selectedFilter.value?.includes(","))
								//     value = selectedFilter.value;

								setDraftFilter({
									...selectedFilter,
									// value: predefinedDateRangeParser(value),
									value,
									condition,
									resources: filter.resources,
								});
							} else {
								if (
									value.includes(FILTER_VALUE_SEPARATOR) &&
									value.split(FILTER_VALUE_SEPARATOR)[1] === "undefined"
								) {
									value = value.split(FILTER_VALUE_SEPARATOR)[0];
								}
								setDraftFilter({
									...draftFilter,
									value,
									condition,
									resources: filter.resources,
								});
								if (autoApply) {
									await delay(50);
									document.getElementById("filter-list-collapse")?.click();
								}
							}
						}}
					/>
				);
			}
		}
	};

	const editFilter = draftFilter !== null ? draftFilter : props.selectedFilter;

	const handleHideDropdown = (event?: KeyboardEvent) => {
		if (!event) return;

		if (event.key === "Escape") {
			props.onCloseFilter();
			setIsComponentVisible(false);
		}
	};

	const handleClickOutside = (event?: MouseEvent) => {
		if (!event || !isComponentVisible) return;

		if (ref.current && !ref.current?.contains(event.target as Node)) {
			if (!removeButtonRef.current?.contains(event.target as Node)) {
				props.onChange(props.selectedFilter, draftFilter);
				setDraftFilter(null);
			}
			props.onCloseFilter();
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleHideDropdown, true);
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("keydown", handleHideDropdown, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	useEffect(() => {
		if (!props.selectedFilter.value && props.filter.type === "date_range") {
			openDateRangeFilter();
		}
	}, []);

	const toggleFilter = () => {
		setIsComponentVisible(true);
		openDateRangeFilter();

		if (isMobile) {
			handleChangeFilterOptionsModalPositionEvent();
		}
	};

	const openDateRangeFilter = async () => {
		if (props.filter.type === "date_range") {
			await delay(200);
			document.getElementById(`dropdown-daterange-${props.id}`)?.click();
		}
	};

	return (
		<ButtonGroup key={props.id}>
			<Dropdown ref={ref} as={ButtonGroup} show={isComponentVisible} onClick={() => setOpenFilter(props.id)}>
				<Dropdown.Toggle
					id={props.id}
					variant=""
					title={renderFilterValue(editFilter, props.filter, t)}
					onClick={() => toggleFilter()}
				>
					<span>{props.filter.name}:</span> {renderFilterValue(editFilter, props.filter, t)}
				</Dropdown.Toggle>
				<Dropdown.Menu className={`list-dropdown-menu ${props.filter.type}`}>
					{renderFilterOptions(editFilter, props.filter)}
				</Dropdown.Menu>
			</Dropdown>
			{props.filter.isRemovable !== false && (
				<Button
					ref={removeButtonRef}
					className="delete"
					onClick={() => props.removeFilter(props.selectedFilter)}
				>
					<DeleteSVG />
				</Button>
			)}
		</ButtonGroup>
	);
};

export default ListFilterItem;
