import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationListFormProps,
} from "../../../../../../../../../../../../services/Api/Organization/types";
import VirtualTableHeaderActionsColumn from "../../../../../../components/VirtualTable/VirtualTableHeaderActionsColumn";
import { VirtualTableColumn } from "../../../../../../components/VirtualTable/types";
import {
	handleCalcVirtualTableStickyColumnLeftPosition,
	isVirtualTableColumnHide,
} from "../../../../../../components/VirtualTable/utils";

interface Props {
	visibleLocalesColumns: ItemTranslationItemNameTranslationFormApi[];
	visibleDefaultColumns: VirtualTableColumn[];
	form: UseFormReturn<ItemTranslationListFormProps>;
	items: ItemTranslationItemNameFormApi[];
	maxZIndex: number;
}

const TranslationsVirtualTableHeaderActionsRow = ({
	visibleLocalesColumns,
	items,
	maxZIndex,
	visibleDefaultColumns,
	form,
}: Props) => {
	const { t } = useTranslation();
	let prevLeftPosition = 0;
	const { setValue } = form;

	const getLocaleColumnActions = (column: ItemTranslationItemNameTranslationFormApi) => [
		{
			onClick: () => {
				handleClearAllLocaleColumn(column);
			},
			title: t("lib:common.action.clear"),
		},
	];

	const handleClearAllLocaleColumn = (column: ItemTranslationItemNameTranslationFormApi) => {
		const isDescriptionColumn = column.id.includes("description");
		items.forEach((item) => {
			if (isDescriptionColumn) {
				setValue(`items.${item.index}.translations.${column.index}.description`, "");
			} else {
				setValue(`items.${item.index}.translations.${column.index}.name`, "");
			}
		});
	};

	return (
		<>
			<tr className="additional-header-row">
				{visibleDefaultColumns.map((column, index) => {
					const prevColumn = visibleDefaultColumns[index - 1];
					const leftPosition = handleCalcVirtualTableStickyColumnLeftPosition(prevLeftPosition, prevColumn);
					prevLeftPosition = leftPosition;
					const lastColumn = index === visibleDefaultColumns.length - 1;
					const isHide = isVirtualTableColumnHide(column.id, visibleDefaultColumns);

					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							id={column.id}
							leftPosition={leftPosition}
							isSticky
							lastColumn={lastColumn}
							zIndex={maxZIndex - index}
						/>
					);
				})}
				{visibleLocalesColumns.map((column) => {
					const isHide = isVirtualTableColumnHide(column.id, visibleLocalesColumns);
					return (
						<VirtualTableHeaderActionsColumn
							key={column.id}
							isHidden={isHide}
							actions={getLocaleColumnActions(column)}
							id={column.id}
						/>
					);
				})}
			</tr>
		</>
	);
};

export default TranslationsVirtualTableHeaderActionsRow;
