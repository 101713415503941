import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LiveOrderApi, OrderItemApi } from "../../../../../../../../../../../services/Api/Organization/types";

interface Props {
	order: LiveOrderApi;
}

const LiveOrdersOrderItems: FC<Props> = ({ order }) => {
	const items = order.items;
	const { t } = useTranslation();

	const getItemItems = (item: OrderItemApi): OrderItemApi[] => {
		return item?.items || [];
	};

	return (
		<div>
			{items.map((item, index) => (
				<div className="mb-3" key={`live-order-item-${index}`}>
					<div className="d-flex justify-content-between mb-2">
						<h5>{`${item.quantity}x ${item.product.name}`}</h5>
						<h5 className="text-end">{FormatMoney(item.total_price)}</h5>
					</div>
					<div className="ms-4 d-flex flex-column">
						{item?.items?.length > 0 &&
							getItemItems(item).map((subItem, subItemIndex) => (
								<React.Fragment key={`live-order-sub-item-${subItemIndex}`}>
									<span className="order-item mb-1">
										{`${subItem.quantity}x ${subItem.product.name}`}
									</span>
									<div className="ms-4 d-flex flex-column">
										{subItem?.items.length > 0 &&
											getItemItems(subItem).map((subItem2, subItem2Index) => (
												<React.Fragment
													key={`live-order-sub-item-${subItemIndex}-item-${subItem2Index}`}
												>
													<span className="order-item mb-1">
														{`${subItem2.quantity}x ${subItem2.product.name}`}
													</span>
													<div className="ms-4 d-flex flex-column">
														{subItem2?.items.length > 0 &&
															getItemItems(subItem2).map((subItem3, subItem3Index) => (
																<span
																	className="order-item mb-1"
																	key={`live-order-sub-item-${subItemIndex}-item-${subItem3Index}`}
																>
																	{`${subItem3.quantity}x ${subItem3.product.name}`}
																</span>
															))}
													</div>
												</React.Fragment>
											))}
									</div>
								</React.Fragment>
							))}
					</div>
				</div>
			))}
			<div className="d-flex justify-content-between">
				<h5>{t("modules.live_order.field.in_total.title")}:</h5>
				<h5>{FormatMoney(order.total_price)}</h5>
			</div>
			{order.delivery_price && (
				<div className="d-flex w-100 justify-content-end mt-1">
					<span className="text-muted">
						{`${t("modules.live_order.field.delivery.title")} ${FormatMoney(order.delivery_price)}`}
					</span>
				</div>
			)}
		</div>
	);
};

export default LiveOrdersOrderItems;
