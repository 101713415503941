import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmptyList from "go-core/components/EmptyList";
import Search from "../../../../../../../../../../../components/Common/Search/Search";
import { PosReportApi, PosReportEmployeeApi } from "../../../../../../../../../../../services/Api/Organization/types";
import EmployeeCard from "./EmployeeCard";

interface Props {
	report: PosReportApi;
	employees: PosReportEmployeeApi[];
	asDeliveryEmployees?: boolean;
}

const EmployeesTabContent = ({ employees, report, asDeliveryEmployees }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [reportEmployees, setReportEmployees] = useState(employees);

	const handleSearch = (name: string) => {
		const searchedEmployees = employees.filter((f) => f.name.toLowerCase().includes(name.toLowerCase()));
		setReportEmployees(searchedEmployees);
	};

	return (
		<>
			{reportEmployees && reportEmployees.length > 0 ? (
				<>
					<Search
						onSearch={handleSearch}
						placeholder={t("modules.pos_report.action.search_employee.title")}
					/>
					<div className={"card-columns"}>
						{reportEmployees?.map((employee, index) => {
							return (
								<EmployeeCard
									key={index}
									reportId={report.id}
									reportStatus={report.status}
									employee={employee}
									isDeliveryEmployee={asDeliveryEmployees}
								/>
							);
						})}
					</div>
				</>
			) : (
				<Card>
					<Card.Body>
						<EmptyList title={t("modules.pos_report.field.employees_empty.title")} />
					</Card.Body>
				</Card>
			)}
		</>
	);
};

export default EmployeesTabContent;
