import React, { FC, useState } from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	filterParseDateBetween,
	formatStringToDate,
	getShortMonthName,
} from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { FILTER_SEPARATOR, NEW_WAY_TO_ENCODING_FILTER_SIGN } from "go-list/core/components/Filter/services";
import { selectOrganization } from "go-security/services/organizations/selectors";
import DateInfoBox from "../../../../../../../../../../components/Common/DateInfoBox/DateInfoBox";
import { ClientApi, ClientWeeklyTransactionApi } from "../../../../../../../../../../services/Api/Organization/types";

interface Props {
	client: ClientApi;
}

const ClientExpenseCard: FC<Props> = ({ client }) => {
	const { t } = useTranslation();
	const organization = useSelector(selectOrganization);
	const [groupTransactionsBy, setGroupTransactionsBy] = useState<string>("MONTH");
	const clientIdFilter = `client|e=${client.id}`;

	const getSummaryTotalPrice = () => {
		if (groupTransactionsBy === "DAY") {
			const amount = client.daily_transactions
				.map((transaction) => transaction.total_price.amount || 0)
				.reduce((acc, cur) => acc + cur, 0);
			return { amount, currency: client.daily_transactions[0].total_price.currency };
		}
		if (groupTransactionsBy === "WEEK") {
			const amount = client.weekly_transactions
				.map((transaction) => transaction.total_price.amount || 0)
				.reduce((acc, cur) => acc + cur, 0);
			return { amount, currency: client.weekly_transactions[0].total_price.currency };
		}
		if (groupTransactionsBy === "MONTH") {
			const amount = client.monthly_transactions
				.map((transaction) => transaction.total_price.amount || 0)
				.reduce((acc, cur) => acc + cur, 0);
			return { amount, currency: client.monthly_transactions[0].total_price.currency };
		}
	};

	const getGroupOrdersByButtonVariant = (groupBy: string) => {
		if (groupBy === groupTransactionsBy) return "primary";
		return "outline-primary";
	};
	const getDate = (transaction: ClientWeeklyTransactionApi) => {
		const startDay = formatStringToDate(transaction.start_at).getDate();
		const endDay = formatStringToDate(transaction.end_at).getDate();
		return `${startDay}-${endDay}`;
	};

	const getMonth = (transaction: ClientWeeklyTransactionApi) => {
		const startMonth = formatStringToDate(transaction.start_at).getMonth() + 1;
		const endMonth = formatStringToDate(transaction.end_at).getMonth() + 1;
		if (startMonth === endMonth) return getShortMonthName(startMonth.toString(), t);
		return `${getShortMonthName(startMonth.toString(), t)}-${getShortMonthName(endMonth.toString(), t)}`;
	};

	const redirectToTransactions = (transaction: Record<string, any>) => {
		if (groupTransactionsBy === "DAY") {
			const startDate = formatStringToDate(transaction.day);
			startDate.setHours(0, 0, 0, 0);
			const endDate = formatStringToDate(transaction.day);
			endDate.setHours(23, 59, 59, 59);
			return `/${organization.id}/sales/transactions?f=${btoa(
				unescape(
					encodeURIComponent(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${clientIdFilter}${FILTER_SEPARATOR}created_at|bt=${filterParseDateBetween(
							startDate,
							endDate
						)}`
					)
				)
			)}`;
		}

		if (groupTransactionsBy === "WEEK") {
			const startDate = formatStringToDate(transaction.start_at);
			startDate.setHours(0, 0, 0, 0);
			const endDate = formatStringToDate(transaction.end_at);
			endDate.setHours(23, 59, 59, 59);
			return `/${organization.id}/sales/transactions?f=${btoa(
				unescape(
					encodeURIComponent(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${clientIdFilter}${FILTER_SEPARATOR}created_at|bt=${filterParseDateBetween(
							startDate,
							endDate
						)}`
					)
				)
			)}`;
		}
		if (groupTransactionsBy === "MONTH") {
			const lastDay = new Date(transaction.month[0], transaction.month[1], 0).getDate();
			const month =
				transaction.month[1].toString().length === 1 ? `0${transaction.month[1]}` : transaction.month[1];
			const startDate = formatStringToDate(`${transaction.month[0]}-${month}-01`);
			startDate.setHours(0, 0, 0, 0);
			const endDate = formatStringToDate(`${transaction.month[0]}-${month}-${lastDay}`);
			endDate.setHours(23, 59, 59, 59);
			return `/${organization.id}/sales/transactions?f=${btoa(
				unescape(
					encodeURIComponent(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${clientIdFilter}${FILTER_SEPARATOR}created_at|bt=${filterParseDateBetween(
							startDate,
							endDate
						)}`
					)
				)
			)}`;
		}
		return "";
	};

	return (
		<Card className="client-expense-card">
			<Card.Body className="pt-3">
				<div className="header">
					<h5 className="client-expense-title">{t("modules.client.field.client_expense_card.title")}</h5>
					<span className="badge bg-success client-expense-summary">
						{FormatMoney(getSummaryTotalPrice())} {t("common.word.last")} 7{" "}
						{t(`enums.dates.type.${groupTransactionsBy}`).toLowerCase()}
					</span>
					<ButtonGroup>
						<Button
							onClick={() => setGroupTransactionsBy("DAY")}
							variant={getGroupOrdersByButtonVariant("DAY")}
						>
							{t(`enums.dates.type.DAY`)}
						</Button>
						<Button
							onClick={() => setGroupTransactionsBy("WEEK")}
							variant={getGroupOrdersByButtonVariant("WEEK")}
						>
							{t(`enums.dates.type.WEEK`)}
						</Button>
						<Button
							onClick={() => setGroupTransactionsBy("MONTH")}
							variant={getGroupOrdersByButtonVariant("MONTH")}
						>
							{t(`enums.dates.type.MONTH`)}
						</Button>
					</ButtonGroup>
				</div>
				{groupTransactionsBy === "DAY" && (
					<div className="client-expense-info-boxes">
						{client.daily_transactions.map((transaction, index) => (
							<DateInfoBox
								key={`day-${index}`}
								date={transaction.day}
								infoHeader={FormatMoney(transaction.total_price)}
								infoSubHeader={
									<Link to={redirectToTransactions(transaction)} target="_blank" rel="noreferrer">
										{t(`modules.client.field.client_expense_card_transactions_amount`, {
											count: transaction.count,
										})}
									</Link>
								}
							/>
						))}
					</div>
				)}
				{groupTransactionsBy === "WEEK" && (
					<div className="client-expense-info-boxes">
						{client.weekly_transactions.map((transaction, index) => (
							<DateInfoBox
								key={`week-${index}`}
								dateHeader={getDate(transaction)}
								dateSubHeader={getMonth(transaction)}
								infoHeader={FormatMoney(transaction.total_price)}
								infoSubHeader={
									<Link to={redirectToTransactions(transaction)} target="_blank" rel="noreferrer">
										{t(`modules.client.field.client_expense_card_transactions_amount`, {
											count: transaction.count,
										})}
									</Link>
								}
							/>
						))}
					</div>
				)}
				{groupTransactionsBy === "MONTH" && (
					<div className="client-expense-info-boxes">
						{client.monthly_transactions.map((transaction, index) => (
							<DateInfoBox
								key={`month-${index}`}
								dateHeader={getShortMonthName(transaction.month[1].toString(), t)}
								dateSubHeader={transaction.month[0]}
								infoHeader={FormatMoney(transaction.total_price)}
								infoSubHeader={
									<Link to={redirectToTransactions(transaction)} target="_blank" rel="noreferrer">
										{t(`modules.client.field.client_expense_card_transactions_amount`, {
											count: transaction.count,
										})}
									</Link>
								}
							/>
						))}
					</div>
				)}
				<Link
					to={`/${organization.id}/sales/transactions?f=${btoa(
						`${NEW_WAY_TO_ENCODING_FILTER_SIGN}${clientIdFilter}`
					)}`}
					target="_blank"
					className={"btn btn-add card-action"}
				>
					{t("common.action.show_more", { ns: "lib" })}
				</Link>
			</Card.Body>
		</Card>
	);
};

export default ClientExpenseCard;

// t("modules.client.field.client_expense_card_transactions_amount_one");
// t("modules.client.field.client_expense_card_transactions_amount_few");
// t("modules.client.field.client_expense_card_transactions_amount_many");
// t("modules.client.field.client_expense_card_transactions_amount_other");
