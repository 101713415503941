import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import FormatResourceStatusLabel from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import Header, { ButtonProps } from "go-app/components/Header";
import { MobileActionProps } from "go-app/components/MobileActions/MobileAction";
import MobileActions from "go-app/components/MobileActions/MobileActions";
import handleError from "go-app/services/errors";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import { LoadingContainer } from "go-core/components/Loading";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { AvailabilitiesProps, AvailabilityApi, AvailabilityStatusApi } from "../../../../services/types";
import { AvailabilityForm } from "../../../Availabilities/components/AvailabilityForm";
import { parseDatesToRange } from "../../components/DatesTableForm";

interface MatchParams {
	availabilityId: string;
}

export const SettingsAvailabilitiesEditPage = ({ requests, organizationId }: AvailabilitiesProps): JSX.Element => {
	const [availability, setAvailability] = useState<AvailabilityApi>();
	const history = useHistory();
	const { t } = useTranslation();
	const confirmation = useConfirmation();
	const { availabilityId } = useParams<MatchParams>();
	const { addSuccessFlash, addFlash } = useFlash();
	const {
		getAvailability: getAvailabilityRequest,
		removeAvailability: removeAvailabilityRequest,
		restoreAvailability: restoreAvailabilityRequest,
	} = requests;
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		onFetchAvailability();
	}, []);

	const onFetchAvailability = async () => {
		const params: Record<string, any> = {};
		params.include = "hours,dates";
		try {
			const res = await getAvailabilityRequest(Number(availabilityId), params, organizationId);
			const availability = res;
			availability.dates = parseDatesToRange(availability);
			setAvailability(availability);
			handleChangeTabTitle(
				`${availability.name} | ${t("go_component.availability.header.title", { ns: "lib" })}`
			);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const removeAvailability = async (availability: AvailabilityApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		try {
			await removeAvailabilityRequest(availability.id, organizationId);
			history.push(`/${organizationId}/settings/availabilities`);
			addSuccessFlash(t("lib:common.flash.removed"));
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	const restoreAvailability = async (availability: AvailabilityApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.activate", { ns: "lib" }),
		});
		try {
			const res = await restoreAvailabilityRequest(availability.id, organizationId);
			addSuccessFlash(t("lib:common.flash.completed"));
			setAvailability(res);
		} catch (err) {
			handleError.alert(err, addFlash);
		}
	};

	if (!availability) {
		return <LoadingContainer />;
	}

	const buttons: ButtonProps[] = [];
	if (availability.status !== AvailabilityStatusApi.DELETED)
		buttons.push({
			title: t("common.action.remove", { ns: "lib" }),
			dropdown: true,
			action: () => removeAvailability(availability),
		});
	if (availability.status === AvailabilityStatusApi.DELETED)
		buttons.push({
			title: t("common.action.activate", { ns: "lib" }),
			dropdown: true,
			action: () => restoreAvailability(availability),
		});

	const mobileActions: MobileActionProps[] = [
		{
			title: t("common.action.remove", { ns: "lib" }),
			action: () => removeAvailability(availability),
			hide: availability.status === AvailabilityStatusApi.DELETED,
		},
		{
			title: t("common.action.activate", { ns: "lib" }),
			hide: availability.status !== AvailabilityStatusApi.DELETED,
			action: () => restoreAvailability(availability),
		},
	];

	const renderTitle = () => (
		<>
			{t("go_component.availability.header.title", { ns: "lib" })} "{availability.name}"
			<FormatResourceStatusLabel status={availability.status} />
		</>
	);

	return (
		<>
			<MobileActions actions={mobileActions} />
			<Header title={renderTitle()} buttons={isMobile ? [] : buttons} />
			<AvailabilityForm availability={availability} requests={requests} organizationId={organizationId} />
		</>
	);
};

// t("go_component.availability.header.singular.title", { ns: "lib" })
