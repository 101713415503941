import { FC, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import FormatDate, { defaultFormatDate } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { useWindowSize } from "go-core/components/useWindowSize";
import { useConfirmation } from "go-form/components/ModalConfirm";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { ReactComponent as TaxSVG } from "../../../../../../../../images/svg/bills/invoice.svg";
import { InvoiceApi, LinkedInvoiceApi } from "../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../services/Api/api";
import { invoicePreviewIncludes } from "../../pages/Index/Advances/pages/Preview";

interface Props {
	invoices: LinkedInvoiceApi[];
	parentId?: number;
	invoice?: InvoiceApi;
	handleUpdateAdvance?: (data: InvoiceApi) => void;
}

const LinkedDocumentsCard: FC<Props> = ({ invoices, parentId, invoice, handleUpdateAdvance }) => {
	const organization = useSelector(selectOrganization);
	const { t } = useTranslation();
	const [linkedInvoices, setLinkedInvoices] = useState(invoices);
	const confirmation = useConfirmation();
	const { addSuccessFlash, addFlash } = useFlash();
	const isMobile = useWindowSize().isMobile;

	const handleRemoveAdvance = async (inv: LinkedInvoiceApi) => {
		await confirmation({
			title: t("confirmation.title", { ns: "lib" }),
			message: t("confirmation.message.remove", { ns: "lib" }),
		});
		const newInv = { ...invoice } as InvoiceApi;
		newInv.previous_advances = newInv.previous_advances
			?.filter((f) => f.advance_id !== inv.id)
			.filter((f) => linkedInvoices.map((x) => x.id).includes(f.advance_id));
		if (handleUpdateAdvance) {
			try {
				const res = await api.organization().updateAdvance(newInv, invoicePreviewIncludes);
				addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
				handleUpdateAdvance(res);
			} catch (err) {
				handleError.alert(err, addFlash);
			}
		}
	};

	useEffect(() => {
		setLinkedInvoices(invoices);
	}, [invoices]);

	if (!linkedInvoices || linkedInvoices.length === 0) {
		return null;
	}
	return (
		<Card>
			<Card.Body>
				<h5>{t("modules.invoice.field.linked_invoices.title")}</h5>
				<div className={`${isMobile ? "table-responsive" : ""}`}>
					<table className={"table table-preview"}>
						<thead>
							<tr>
								<th>{t("common.word.name", { ns: "lib" })}</th>
								<th>{t("lib:common.word.date")}</th>
								<th>{t("common.word.amount")}</th>
							</tr>
						</thead>
						<tbody>
							{linkedInvoices.map((inv, index) => {
								return (
									<tr key={index}>
										<td>
											<div className={"d-flex align-items-center"}>
												<TaxSVG />{" "}
												{inv.document_type === "PROFORMA" && (
													<Link
														to={`/${organization.id}/invoices/proforma/${inv.id}`}
														target={"_blank"}
														rel={"noreferrer"}
													>
														{t("enums.invoices.document_type.PROFORMA")} {inv.number}
													</Link>
												)}
												{inv.document_type === "INVOICE" && (
													<Link to={`/${organization.id}/invoices/${inv.id}`}>
														{t("enums.invoices.document_type.INVOICE")} {inv.number}
													</Link>
												)}
												{inv.document_type === "ADVANCE" && (
													<div className={"d-flex align-items-center"}>
														<Link to={`/${organization.id}/invoices/advances/${inv.id}`}>
															{t("enums.invoices.document_type.ADVANCE")} {inv.number}
														</Link>
														{handleUpdateAdvance && (
															<div
																className={"action-ref ms-2"}
																style={{ color: "red" }}
																onClick={() => handleRemoveAdvance(inv)}
															>
																{t("common.action.remove", { ns: "lib" })}
															</div>
														)}
													</div>
												)}
												{inv.document_type === "CORRECTION" && (
													<Link to={`/${organization.id}/invoices/${parentId}/corrections`}>
														{t("enums.invoices.document_type.CORRECTION")} {inv.number}
													</Link>
												)}
											</div>
										</td>
										<td>{FormatDate(inv.issued_at, undefined, false, defaultFormatDate)}</td>
										<td>{FormatMoney(inv.price_sum_gross)}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</Card.Body>
		</Card>
	);
};
export default LinkedDocumentsCard;
