import React, { memo, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
	ItemTranslationItemNameFormApi,
	ItemTranslationItemNameTranslationFormApi,
	ItemTranslationListFormProps,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { VirtualTable } from "../../../../../components/VirtualTable/VirtualTable";
import { useResizeVirtualTableHeader } from "../../../../../components/VirtualTable/hooks/useResizeVirtualTableHeader";
import { VirtualTableColumn, VirtualTableListSelectedSort } from "../../../../../components/VirtualTable/types";
import {
	STARTING_CSS_INDEX,
	getVirtualTableHeaderStyles,
	getVirtualTableItemsAfterSortingAndFiltering,
} from "../../../../../components/VirtualTable/utils";
import TranslationsVirtualTableHeader from "./TranslationsVirtualTableHeader/TranslationsVirtualTableHeader";
import TranslationsVirtualTableRow from "./TranslationsVirtualTableRow";

interface Props {
	heightTr: number;
	width: number;
	height: number;
	items: ItemTranslationItemNameFormApi[];
	form: UseFormReturn<ItemTranslationListFormProps>;
	visibleLocalesColumns: ItemTranslationItemNameTranslationFormApi[];
	visibleDefaultColumns: VirtualTableColumn[];
	sortings: VirtualTableListSelectedSort[];
	setSortings: (sortings: VirtualTableListSelectedSort[]) => void;
}

const TranslationsVirtualTable = ({
	visibleLocalesColumns,
	heightTr,
	height,
	setSortings,
	items,
	visibleDefaultColumns,
	width,
	sortings,
	form,
}: Props) => {
	const prevItems = useRef<string | string[]>([]);
	const intervalRef = useRef<any>(null);
	const [loading, setLoading] = useState(false);
	const [categorySearch, setCategorySearch] = useState<string | undefined>(undefined);
	const [printingNameSearch, setPrintingNameSearch] = useState<string | undefined>(undefined);
	const [itemGroupSearch, setItemGroupSearch] = useState<string | undefined>(undefined);
	const [descriptionSearch, setDescriptionSearch] = useState<string | undefined>(undefined);
	const [itemFullNameSearch, setItemFullNameSearch] = useState<string | undefined>(undefined);
	const [nameSearch, setNameSearch] = useState<string | undefined>(undefined);
	const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});
	const MAX_ZINDEX = visibleDefaultColumns.length + STARTING_CSS_INDEX;
	const { handleVirtualTableHeaderResize, handleVirtualTableMouseDown, handleVirtualTableMouseUp } =
		useResizeVirtualTableHeader();

	const Row = memo(
		({
			index,
			data,
			style,
		}: {
			index: number;
			data: ItemTranslationItemNameFormApi[];
			style: Record<string, any>;
		}) => {
			const item = data[index];
			return (
				<TranslationsVirtualTableRow
					locales={visibleLocalesColumns}
					item={item}
					style={style}
					defaultColumns={visibleDefaultColumns}
					columnWidths={columnWidths}
					maxZIndex={MAX_ZINDEX}
					form={form}
				/>
			);
		}
	);

	useEffect(() => {
		if (JSON.stringify(items) !== prevItems.current) {
			fakeLoading();
			prevItems.current = JSON.stringify(items);
		}
	}, [items]);

	useEffect(() => {
		fakeLoading();
	}, [
		categorySearch,
		nameSearch,
		itemGroupSearch,
		descriptionSearch,
		printingNameSearch,
		sortings,
		itemFullNameSearch,
	]);

	const style = {} as Record<string, any>;
	let finalHeight = 500;
	if (height && width) {
		style.height = height;
		style.width = width;
		finalHeight = height;
	}

	const isSearched = () => {
		return (
			categorySearch !== undefined ||
			itemGroupSearch !== undefined ||
			nameSearch !== undefined ||
			printingNameSearch !== undefined ||
			descriptionSearch !== undefined ||
			itemFullNameSearch !== undefined
		);
	};

	const itemsSearcher = () => {
		if (!isSearched()) return items;

		let newItems = [...items];
		if (categorySearch) {
			newItems = newItems.filter((item) =>
				item?.category_name?.toLowerCase()?.includes(categorySearch.toLowerCase())
			);
		}
		if (itemGroupSearch) {
			newItems = newItems.filter((item) =>
				item?.item_group_name?.toLowerCase()?.includes(itemGroupSearch.toLowerCase())
			);
		}
		if (nameSearch) {
			newItems = newItems.filter((item) => item?.name?.toLowerCase()?.includes(nameSearch.toLowerCase()));
		}
		if (printingNameSearch) {
			newItems = newItems.filter((item) =>
				item?.printing_name?.toLowerCase()?.includes(printingNameSearch.toLowerCase())
			);
		}
		if (descriptionSearch) {
			newItems = newItems.filter((item) =>
				item?.description?.toLowerCase()?.includes(descriptionSearch.toLowerCase())
			);
		}
		if (itemFullNameSearch) {
			newItems = newItems.filter((item) =>
				item?.item_full_name?.toLowerCase()?.includes(itemFullNameSearch.toLowerCase())
			);
		}
		return newItems;
	};

	const fakeLoading = () => {
		setLoading(true);
		if (intervalRef.current) clearTimeout(intervalRef.current);
		intervalRef.current = setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	const getItemsAfterSortingAndFiltering = () => {
		return getVirtualTableItemsAfterSortingAndFiltering(
			itemsSearcher(),
			sortings
		) as ItemTranslationItemNameFormApi[];
	};

	const handleHeaderResize = (columnWidth: number | undefined, key: string) => {
		handleVirtualTableHeaderResize(columnWidth, setColumnWidths, key, columnWidths);
	};

	const getHeaderStyles = (key: string) => {
		return getVirtualTableHeaderStyles(key, columnWidths);
	};

	return (
		<div className="virtual-table" style={style}>
			<VirtualTable
				height={finalHeight}
				width="auto"
				itemCount={getItemsAfterSortingAndFiltering().length}
				itemSize={heightTr}
				header={
					<TranslationsVirtualTableHeader
						getHeaderStyles={getHeaderStyles}
						form={form}
						visibleLocalesColumns={visibleLocalesColumns}
						items={getItemsAfterSortingAndFiltering()}
						maxZindex={MAX_ZINDEX}
						visibleDefaultColumns={visibleDefaultColumns}
						setItemFullNameSearch={setItemFullNameSearch}
						itemGroupSearch={itemGroupSearch}
						setItemGroupSearch={setItemGroupSearch}
						itemFullNameSearch={itemFullNameSearch}
						setCategorySearch={setCategorySearch}
						printingNameSearch={printingNameSearch}
						setPrintingNameSearch={setPrintingNameSearch}
						setNameSearch={setNameSearch}
						setDescriptionSearch={setDescriptionSearch}
						nameSearch={nameSearch}
						descriptionSearch={descriptionSearch}
						handleHeaderResize={handleHeaderResize}
						categorySearch={categorySearch}
						handleMouseDown={handleVirtualTableMouseDown}
						sortings={sortings}
						handleMouseUp={(e, columnName, skipSorting) =>
							handleVirtualTableMouseUp(e, columnName, sortings, setSortings, skipSorting)
						}
					/>
				}
				loading={loading}
				itemKey={(key) => key}
				itemData={getItemsAfterSortingAndFiltering()}
				row={Row}
			/>
		</div>
	);
};

export default TranslationsVirtualTable;
