import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { UseFieldArrayRemove, UseFormReturn, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SortEnd, SortableContainer, arrayMove } from "react-sortable-hoc";
import { ButtonLoading } from "go-form";
import useFlash from "go-alert/AlertMessage";
import handleError from "go-app/services/errors";
import EmptyData from "go-core/components/EmptyData";
import { FormDirty } from "go-form/components/FormDirty";
import { MenuApi, MenuPageApi } from "../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../services/Api/api";
import EntityTranslationsModalForm from "../../../../components/translations/EntityTranslationsModalForm";
import CategoryModal from "./CategoryModal";
import MenuListPage from "./MenuListPage";

interface Props {
	menu: MenuApi;
	initialMenu: MenuApi;
	isDirty: boolean;
	menuChanged: (isDirty: boolean) => void;
	handleSave: (data: MenuApi, id?: number) => void;
}

interface PageListProps {
	pages: MenuPageApi[];
	form: UseFormReturn<MenuApi>;
	setPageToChange: (page: MenuPageApi | undefined) => void;
	remove: UseFieldArrayRemove;
	setCategoryName: (name: string) => void;
}

const PageList = SortableContainer(({ pages, setPageToChange, remove, form, setCategoryName }: PageListProps) => {
	return (
		<tbody className="menu-list-table-sortable-page-list">
			{pages.map((page, index) => {
				return (
					<MenuListPage
						index={index}
						pageIndex={index}
						key={page.internalId || page.id}
						page={page}
						setPageToChange={setPageToChange}
						remove={remove}
						form={form}
						setCategoryName={setCategoryName}
					/>
				);
			})}
		</tbody>
	);
});

const MenuList = ({ menu, isDirty, menuChanged, handleSave }: Props) => {
	const form = useForm<MenuApi>({
		criteriaMode: "all",
		defaultValues: menu,
	});
	const { t } = useTranslation();
	const { setValue, handleSubmit, control, formState, setError, reset, watch } = form;
	const { remove, append, replace } = useFieldArray({
		control,
		name: "pages",
		keyName: "key",
	});
	const watchedPages = watch("pages");
	const [pageToChange, setPageToChange] = useState<MenuPageApi | undefined>();
	const [showNewPageModal, setShowNewPageModal] = useState(false);
	const { addFlash, addSuccessFlash } = useFlash();
	const [loading, setLoading] = useState(false);
	const [categoryName, setCategoryName] = useState<string>();

	const handleHideCategoryModal = () => {
		setShowNewPageModal(false);
	};

	const handleUpdatePagesPositions = ({ oldIndex, newIndex }: SortEnd) => {
		let newPages = [...watchedPages];
		newPages = arrayMove(newPages, oldIndex, newIndex);
		newPages.forEach((page, index) => {
			page.position = index;
		});
		replace(newPages);
	};

	const getProcessDataForm = (data: MenuApi) => {
		data.active = menu.status === "ENABLED";
		data.pages.forEach((page, index) => {
			page.position = index;
			page.items = page.items.map((item, itemIndex) => {
				item.position = itemIndex;
				return item;
			});
		});

		return data;
	};

	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);
		try {
			const params: Record<string, any> = { include: "pages,pages.items,pages.items.entity,pages.translations" };
			const processedData = getProcessDataForm(data);

			if (menu.id) {
				processedData.id = menu.id;
				processedData.image_tag = menu.image_tag;
				const res = await api.organization().updateMenu(processedData, params);
				reset(res);
				handleSave(res, res.id);
			} else {
				const res = await api.organization().createMenu(processedData, params);
				handleSave(res);
			}
			addSuccessFlash(t("common.flash.saved", { ns: "lib" }));
		} catch (err) {
			handleError.form(err, setError, addFlash);
		}
		setLoading(false);
	});

	const handleCancelForm = () => {
		reset(menu);
		menuChanged(false);
	};

	const handleSaveTranslations = (data: Record<string, any>) => {
		if (!pageToChange) return;

		const pageIndex = watchedPages.indexOf(pageToChange);

		if (pageIndex > -1) {
			setValue(`pages.${pageIndex}.translations`, data.entity_translations, { shouldDirty: true });
			if (categoryName !== pageToChange.name && categoryName !== undefined) {
				setValue(`pages.${pageIndex}.name`, categoryName, { shouldDirty: true });
			}
		}

		setPageToChange(undefined);
		setCategoryName("");
	};

	const handleCloseTranslationsModal = () => {
		setPageToChange(undefined);
		setCategoryName("");
	};

	return (
		<>
			{showNewPageModal && (
				<CategoryModal
					show={showNewPageModal}
					onHide={handleHideCategoryModal}
					indexPage={watchedPages.length}
					append={append}
				/>
			)}
			{!!pageToChange && (
				<EntityTranslationsModalForm
					allowDescription
					handleSave={handleSaveTranslations}
					onHide={handleCloseTranslationsModal}
					translations={pageToChange?.translations ?? []}
					preventSubmitOnHide
					formChildren={
						<fieldset className="form-group">
							<label>{t("modules.menu.field.section_name.title")}</label>
							<input
								className="form-control"
								onChange={(event) => setCategoryName(event.target.value)}
								defaultValue={categoryName}
							/>
						</fieldset>
					}
					customTitle={t("modules.menu.field.section_editing.title")}
					disableButton={!categoryName}
				/>
			)}
			<FormDirty isDirty={formState.isDirty || isDirty} onSubmit={onSubmit} className="menu-list-form">
				<div className="d-flex flex-wrap align-items-center justify-content-between">
					<h6>{t("modules.menu.field.menu_positions.title")}</h6>
					<Button variant="add" onClick={() => setShowNewPageModal(true)}>
						+ {t("modules.menu.action.add_section.title")}
					</Button>
				</div>
				{watchedPages.length > 0 ? (
					<fieldset className="form-group">
						<table className="table table-form menu-list-table">
							<PageList
								pages={watchedPages}
								form={form}
								setPageToChange={setPageToChange}
								remove={remove}
								useDragHandle
								onSortEnd={handleUpdatePagesPositions}
								setCategoryName={setCategoryName}
							/>
						</table>
					</fieldset>
				) : (
					<EmptyData
						title={t("modules.menu.field.empty_pages.title")}
						description={t("modules.menu.field.empty_pages.helptext.description")}
					/>
				)}
				<div className="form-footer">
					<Form.Group className="form-group">
						<ButtonLoading loading={loading} variant="primary" type="submit">
							{t("common.action.save", { ns: "lib" })}
						</ButtonLoading>
						{(formState.isDirty || isDirty) && (
							<Button variant="light" type="button" onClick={handleCancelForm}>
								{t("common.action.cancel", { ns: "lib" })}
							</Button>
						)}
					</Form.Group>
				</div>
			</FormDirty>
		</>
	);
};

export default MenuList;
