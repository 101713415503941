import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { wrapPromise } from "go-core";
import FormatResourceStatusLabel from "go-app/components/FormatResourceStatus/FormatResourceStatus";
import Header from "go-app/components/Header";
import { useBrowserTabTitle } from "go-core/components/BrowserTab/useBrowserTabTitle";
import EmptyList from "go-core/components/EmptyList";
import FormatDate, { FormatDateToDateHoursRange } from "go-core/components/Formatters/FormatDate";
import FormatMoney from "go-core/components/Formatters/FormatMoney";
import { LoadingContainer } from "go-core/components/Loading";
import RenderLimitedText from "go-core/components/RenderLimitedText";
import { StickyColumnWithEntityStatus } from "go-core/components/StickyColumnWithEntityStatus";
import { useWindowSize } from "go-core/components/useWindowSize";
import RenderColumnUtils from "go-list/core/components/Actions/services/RenderColumnUtils";
import { FilterCondition, FilterType, ListFilterSource } from "go-list/core/components/Filter/services/types";
import { ListData } from "go-list/list";
import { getSelectedSegmentForListConfig } from "go-list/list/services/segment-service";
import { GoListSegmentType, ListConfig } from "go-list/list/services/types";
import { selectOrganization } from "go-security/services/organizations/selectors";
import { SegmentType } from "go-segment/components/types";
import { SegmentContext } from "go-segment/context";
import { getStatusClassName } from "../../../../../../../../../components/Common/Formatters/FormatResourceStatus/FormatResourceStatus";
import { InconsistentReference } from "../../../../../../../../../components/Common/InconsistentReferenceTooltip/InconsistentReference";
import { TransactionApi, TransactionContextApi } from "../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../services/Api/api";
import { convertListStatusToEntityStatus } from "../../../../../../../../../utils/entityStatus/entityStatus";
import RenderTransactionType from "../../../../../components/Transaction/RenderTransactionType";
import TransactionPreview from "../components/TransactionPreview";
import { TransactionsBackendAcceptableParams } from "../services/types";

const getExportParams = (params: Record<string, any>) => {
	const newParamsArr = params.columns.split(",");

	const paramsToBeSent = newParamsArr.map((param: string) => {
		if (param === "AMOUNT") return TransactionsBackendAcceptableParams.PRICE;
		return param;
	});

	return { ...params, columns: paramsToBeSent.join(",") };
};

interface ListState {
	resource?: Record<string, any>;
	matchParams: RouteComponentProps;
}

interface MatchParams {
	transaction_id: string;
}

interface PreviewProps {
	match: Record<string, any>;
	loc: Record<string, any>;
	fetchList: () => void;
}

const listName = "TRANSACTION";
const resourceType = "TRANSACTION";

const List: FC<ListState> = ({ resource, matchParams }) => {
	const { t } = useTranslation();
	const [params, setParams] = useState<Record<string, any>>({});
	const organization = useSelector(selectOrganization);
	const [items, setItems] = useState<TransactionApi[]>([]);
	const searchParams = window.location.search;
	const segmentContext = useContext(SegmentContext);

	if (!resource) return null;
	const data = resource.read();

	const drawDocumentByType = (context: TransactionContextApi, contexts: TransactionContextApi[]) => {
		const filtered = items.filter((item) => item.contexts[0]?.id === context?.id);
		const amount = filtered[0]?.amount?.amount ?? 0;
		if (!context.id && !context.number)
			return (
				<div className="d-flex align-items-center">
					<span className="me-1">{t(`enums.transactions.context_type.${context.type}`)}</span>
					<span className="badge bg-danger">{t("enums.common.status.DELETED", { ns: "lib" })}</span>
				</div>
			);

		if (context.type === "ORDER")
			return (
				<Link
					target="_blank"
					rel={"no_referrer"}
					to={`/${organization.id}/sales/orders/${context.id}`}
					key={context.id}
				>
					{`${t("enums.transactions.context_type.ORDER")} ${context.number}`}
				</Link>
			);
		if (context.type === "REPORT_PAID")
			return (
				<span key={context.id}>{`${
					amount < 0
						? t("enums.transactions.context_type.PAY_OUT")
						: t("enums.transactions.context_type.PAY_IN")
				} ${context.number}`}</span>
			);
		if (context.type === "PROFORMA")
			return (
				<Link
					target="_blank"
					rel={"no_referrer"}
					to={`/${organization.id}/invoices/proforma/${context.id}`}
					key={context.id}
				>
					{`${t(`enums.transactions.context_type.${context.type}`)} ${context.number}`}
				</Link>
			);
		if (context.type === "CORRECTION") {
			let invoiceId;
			if (contexts.filter((c) => c.type !== "INVOICE"))
				if (contexts.filter((c) => c.type === "INVOICE").length > 0) {
					invoiceId = contexts.filter(
						(singleContext) => singleContext.type === "INVOICE" && singleContext.id
					)[0].id;
					return (
						<Link
							target="_blank"
							rel={"no_referrer"}
							to={`/${organization.id}/invoices/${invoiceId}/corrections`}
							key={context.id}
						>
							{`${t(`enums.transactions.context_type.${context.type}`)} ${context.number}`}
						</Link>
					);
				}
			if (contexts.filter((c) => c.type === "ADVANCE").length > 0) {
				invoiceId = contexts.filter((singleContext) => singleContext.type === "ADVANCE" && singleContext.id)[0]
					.id;
				return (
					<Link
						target="_blank"
						rel={"no_referrer"}
						to={`/${organization.id}/invoices/${invoiceId}/corrections`}
						key={context.id}
					>
						{`${t(`enums.transactions.context_type.${context.type}`)} ${context.number}`}
					</Link>
				);
			}
			return (
				<Link
					target="_blank"
					rel={"no_referrer"}
					to={`/${organization.id}/invoices/${context.id}`}
					key={context.id}
				>
					{`${t(`enums.transactions.context_type.${context.type}`)} ${context.number}`}
				</Link>
			);
		}
		return (
			<Link
				target="_blank"
				rel={"no_referrer"}
				to={`/${organization.id}/invoices/${context.id}`}
				key={context.id}
			>
				{`${t(`enums.transactions.context_type.${context.type}`)} ${context.number}`}
			</Link>
		);
	};

	const handleRefresh = async () => {
		if (config.fetch) {
			const res = await config.fetch(params);
			setItems(res);
		}
	};

	let config = {
		fields: [
			{
				id: "id",
				name: t("modules.transaction.field.transaction_number.title"),
				type: "search_select",
				render: (item: TransactionApi) => {
					const statusToBeConverted = item.status === "NEW" ? "NEW_GRAY" : item.status;
					return (
						<StickyColumnWithEntityStatus
							status={convertListStatusToEntityStatus(statusToBeConverted)}
							to={`/${organization.id}/sales/transactions/${item.id}`}
						>
							{`#${item.id}`}
						</StickyColumnWithEntityStatus>
					);
				},
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getTransactionsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				},
				renderExport: (item: TransactionApi) => `#${item.id}`,
			},
			{
				id: "created_at",
				name: t("lib:common.word.created_at"),
				type: "date" as FilterType,
				render: (item: TransactionApi) => {
					return (
						<Link to={`/${organization.id}/sales/transactions/${item.id}`}>
							{item.created_at.split("T")[1].slice(0, -3)}
						</Link>
					);
				},
				renderExport: (item: TransactionApi) => FormatDate(item.created_at),
			},
			{
				id: "payment_method",
				name: t("common.word.payment_method"),
				type: "search_select",
				disableSorting: true,
				source: {
					request: (search: string, obj: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPaymentMethodsSearchSelect(search, obj, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
				render: (item: TransactionApi) => {
					return item.inconsistent_reference_id ? (
						<div className="d-flex align-items-center gap-1">
							{item.payment_method?.name}
							<InconsistentReference />
						</div>
					) : (
						item.payment_method?.name
					);
				},
			},
			{
				id: "documents",
				name: t("modules.transaction.field.documents.title"),
				disableSorting: true,
				render: (item: TransactionApi) => {
					return (
						<div className="d-flex flex-column">
							{item.contexts?.map((context) => drawDocumentByType(context, item.contexts))}
						</div>
					);
				},
				renderExport: (item: TransactionApi) => {
					return `[${item.contexts
						?.map((context) => `${t(`enums.transactions.context_type.${context.type}`)} ${context.number}`)
						.join(", ")}]`;
				},
			},
			{
				id: "description",
				name: t("lib:common.word.description"),
				disableSorting: true,
				type: "text",
				render: (item: TransactionApi) => (
					<RenderLimitedText minWidth={200}>{item.description}</RenderLimitedText>
				),
				renderExport: (item: TransactionApi) => item.description,
			},
			{
				id: "status",
				name: t("lib:common.word.status"),
				type: "list" as FilterType,
				options: {
					NEW: t("enums.common.status.NEW"),
					SUCCESS: t("enums.common.status.SUCCESS", { ns: "lib" }),
					REMOVED: t("enums.common.status.REMOVED"),
					ERROR: t("enums.common.status.ERROR", { ns: "lib" }),
				},
				render: (item: TransactionApi) => {
					const translation =
						item.status === "SUCCESS" || item.status === "ERROR"
							? t(`enums.common.status.${item.status}`, { ns: "lib" })
							: t(`enums.common.status.${item.status}`);

					return (
						<FormatResourceStatusLabel
							status={item.status}
							translation={translation}
							className={item.status === "NEW" ? "secondary" : getStatusClassName(item.status)}
						/>
					);
				},

				renderExport: (item: TransactionApi) => {
					const status =
						item.status === "SUCCESS" || item.status === "ERROR"
							? t(`enums.common.status.${item.status}`, { ns: "lib" })
							: t(`enums.common.status.${item.status}`);

					return status;
				},
			},
			{
				id: "type",
				name: t("lib:common.word.type"),
				type: "list" as FilterType,
				options: {
					PAY_IN: t("enums.transactions.context_type.PAY_IN"),
					PAY_OUT: t("enums.transactions.context_type.PAY_OUT"),
					CANCELLATION: t("enums.transactions.context_type.CANCELLATION"),
					CHANGE: t("enums.transactions.context_type.CHANGE"),
				},
				render: (item: TransactionApi) => <RenderTransactionType type={item.type} />,
				renderExport: (item: TransactionApi) => {
					return t(`enums.transactions.context_type.${item.type}`);
				},
			},
			{
				id: "amount",
				name: t("common.word.amount"),
				type: "number" as FilterType,
				disableSorting: true,
				render: (item: TransactionApi) => (
					<div className="d-flex flex-column">
						<span>{FormatMoney(item.tender_amount)}</span>
						{item.amount.amount !== item.tender_amount.amount && (
							<small className="text-muted">{FormatMoney(item.amount)}</small>
						)}
					</div>
				),
				renderExport: (item: TransactionApi) => {
					return FormatMoney(item.amount);
				},
				styleOverride: RenderColumnUtils.getMoneyStyles(),
			},
		],
		actions: [
			{
				name: t("common.action.preview", { ns: "lib" }),
				link: (item: TransactionApi) => `/${organization.id}/sales/transactions/${item.id}`,
			},
		],
		filters: [
			{
				id: "employee",
				name: t("common.word.employee"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getEmployeesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
			{
				id: "order",
				name: t("common.word.order"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) => {
						const newParams = {
							...filterParams,
							"transaction_count|gt": "0",
						};
						return api.organization().getOrdersSearchSelect(search, newParams, {
							cancelToken: options?.token,
						});
					},
					render: (item: TransactionApi) => {
						if (item.created_at && item.closed_at) {
							return `#${item.label} (${FormatDateToDateHoursRange(item.created_at, item.closed_at)})`;
						}
						return `#${item.label} (${FormatDate(item.created_at)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "report",
				name: t("common.word.pos_report"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getPosReportsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
					render: (item: TransactionApi) => {
						if (item.opened_at && item.closed_at) {
							return `#${item.label} (${FormatDateToDateHoursRange(item.opened_at, item.closed_at)})`;
						}
						return `#${item.label} (${FormatDate(item.opened_at)})`;
					},
				} as ListFilterSource,
			},
			{
				id: "paid_at",
				name: t("modules.transaction.field.paid_at.title"),
				type: "date" as FilterType,
			},
			{
				id: "context_type",
				name: t("modules.transaction.field.document.title"),
				type: "list" as FilterType,
				options: {
					ORDER: t("enums.transactions.context_type.ORDER"),
					INVOICE: t("enums.transactions.context_type.INVOICE"),
					REPORT_PAID: t("enums.transactions.context_type.REPORT_PAID"),
				},
			},
			{
				id: "client",
				name: t("common.word.client"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getClientsSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
					render: (item: any) => item.label,
				} as ListFilterSource,
			},
			{
				id: "invoice",
				name: t("common.word.invoice"),
				type: "search_select" as FilterType,
				source: {
					request: (search: string, filterParams: Record<string, any>, options?: Record<string, any>) =>
						api.organization().getInvoicesSearchSelect(search, filterParams, {
							cancelToken: options?.token,
						}),
				} as ListFilterSource,
			},
		],
		groupBy: {
			fieldName: "created_at",
			type: "date",
		},
		selectedColumns: ["created_at", "id", "payment_method", "documents", "description", "status", "type", "amount"],
		segments: [
			{
				id: "all",
				name: t("common.word.all", { ns: "lib" }),
				slug: "all",
			},
			{
				id: "active",
				name: t("common.word.active_plural"),
				slug: "active",
				filters: [
					{
						filterId: "status",
						value: "SUCCESS",
						condition: "e" as FilterCondition,
					},
				],
			} as GoListSegmentType,
		],
		selectedSegment: getSelectedSegmentForListConfig(data.segments, searchParams ? "all" : "active"),
		exportConfig: {
			title: t("modules.transaction.field.export_config.title"),
			filename: t("modules.transaction.header.title"),
			organization: `${organization.name}`,
			taxIdNo: organization?.more?.print_company_on_pdf ? organization.more?.company_tax_id_no : undefined,
			company: organization?.more?.print_company_on_pdf ? organization.more?.company_name : undefined,
			pdfOrientation: organization?.more?.pdf_orientation,
			pdfFontSize: organization?.more?.default_pdf_font_size?.toString(),
			customExportRequests: {
				pdfFetch: (pdfFetchParams?: Record<string, any>) => {
					const newParams = {
						...pdfFetchParams,
						columns:
							pdfFetchParams?.columns ||
							"CREATED_AT,ID,PAYMENT_METHOD,DOCUMENTS,DESCRIPTION,STATUS,TYPE,PRICE",
					};
					return api.organization().getTransactionPDF(getExportParams(newParams));
				},
				csvFetch: (csvFetchParams?: Record<string, any>) => {
					const newParams = {
						...csvFetchParams,
						columns:
							csvFetchParams?.columns ||
							"CREATED_AT,ID,PAYMENT_METHOD,DOCUMENTS,DESCRIPTION,STATUS,TYPE,PRICE",
					};
					return api.organization().getTransactionCSV(getExportParams(newParams));
				},
			},
		},
		saveSegment: (segment: SegmentType) => {
			return segmentContext.save(listName, resourceType, segment);
		},
		fetch: (fetchParams: Record<string, any> = {}, sourceToken?: CancelTokenSource) => {
			fetchParams.include = "payment_method,sub_reports,contexts,contexts.context_id,external_id_inconsistency";
			setParams(fetchParams);
			return api.organization().getTransactions(fetchParams, { cancelToken: sourceToken?.token });
		},
		doesIdColumnRedirectToPreviewPage: true,
		numberOfStickyColumnsAtTheStart: 2,
	} as ListConfig;
	config = {
		...config,
		externalSegments: data.segments,
		fields: config.fields ? [...config.fields, ...data.fields] : data.fields,
		customFields: data.fields,
		filterValues: data.filter_values,
	};
	return (
		<>
			<ListData
				data={items}
				config={config}
				emptyList={
					<EmptyList
						title={t("modules.transaction.header.title")}
						description={t("modules.transaction.field.empty_list_description.title")}
					/>
				}
				onFetch={(fetchItems: TransactionApi[]) => setItems(fetchItems)}
			/>
			<Switch>
				<Route
					path={`${matchParams.match.url}/:transaction_id`}
					render={({ match }) => (
						<OrganizationSalesTransactionPreviewPage match={match} loc={params} fetchList={handleRefresh} />
					)}
				/>
			</Switch>
		</>
	);
};

const OrganizationSalesTransactionsIndexPage: FC<RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const [resource, setResource] = useState<Record<string, any>>();
	const segmentContext = useContext(SegmentContext);
	const isMobile = useWindowSize().isMobile;
	const { handleChangeTabTitle } = useBrowserTabTitle();

	useEffect(() => {
		handleChangeTabTitle(t("modules.transaction.header.title"));
		setResource(wrapPromise(segmentContext.get(listName, resourceType)));
	}, []);

	return (
		<>
			{!isMobile && <Header title={t("modules.transaction.header.title")} />}
			<Suspense fallback={<LoadingContainer />}>
				<List resource={resource} matchParams={props} />
			</Suspense>
		</>
	);
};
export default OrganizationSalesTransactionsIndexPage;

const OrganizationSalesTransactionPreviewPage = ({ loc, fetchList }: PreviewProps) => {
	const history = useHistory();
	const { transaction_id } = useParams<MatchParams>();
	const search = Object.keys(loc)
		.filter((f) => f !== "include")
		.map((key) => (key === "c" ? `${key}=${encodeURIComponent(`${loc[key]}`)}` : `${key}=${loc[key]}`))
		.join("&");
	const organization = useSelector(selectOrganization);
	return (
		<TransactionPreview
			handleSuccessAction={fetchList}
			canChangePaymentMethod
			id={parseInt(transaction_id)}
			onClose={() => history.push(`/${organization.id}/sales/transactions${search ? `?${search}` : ""}`)}
		/>
	);
};
