import { listFiltersDecode, listFiltersEncode } from "../../core/components/Filter/services/decoder";
import { parsePredefinedRangeToDefaultString } from "../../utils/daterangeutils";
import { ListParamsType } from "./types";

export const listDataParams = (params: ListParamsType): Record<string, any> => {
	return listDataParamsDefault(
		params,
		(listParams: ListParamsType) => {
			const newParams = { ...listParams };
			if (newParams && newParams.filters) {
				newParams.filters = newParams.filters.map((param) => {
					const newParam = { ...param };
					const condition = param.condition;
					const value: string | null = param.value;
					switch (condition) {
						case "btd":
							newParam.value = parsePredefinedRangeToDefaultString(value);
							newParam.condition = "bt";
							break;
					}
					return newParam;
				});
			}
			return newParams;
		},
		true
	);
};

export const listDataParamsDefault = (
	params: ListParamsType,
	parser?: (listParams: ListParamsType) => ListParamsType,
	skipEncoding?: boolean
): Record<string, any> => {
	if (parser) params = parser(params);
	const paramsArray: Record<string, any> = {};
	if (params.selectedSegment) {
		paramsArray.s = params.selectedSegment;
	}
	if (params.search) {
		paramsArray.search = params.search;
	}
	if (params.columns) {
		paramsArray.c = params.columns.join(",");
	}
	if (params.filters && params.filters.length > 0) {
		const f = listFiltersEncode(params.filters, !skipEncoding);
		paramsArray.f = f;
	} else {
		paramsArray.f = "";
	}
	if (params.f) {
		paramsArray.f = params.f;
	}

	if (params.size) {
		paramsArray.size = params.size;
	}
	if (params.page) {
		paramsArray.page = params.page;
	}
	if (params.sort && params.sort.length > 0) {
		paramsArray.sort = params.sort.join(",");
	}

	return paramsArray;
};

export const listDataDecoderQuery = (urlSearchParams: URLSearchParams): Record<string, any> => {
	const f = urlSearchParams.get("f");
	const search = urlSearchParams.get("search");
	const page = urlSearchParams.get("page");
	const size = urlSearchParams.get("size");
	const segment = urlSearchParams.get("s");
	const c = urlSearchParams.get("c");
	const sort = urlSearchParams.get("sort");
	const listParams: ListParamsType = {
		search: search ? search : "",
	};

	if (segment) {
		listParams.selectedSegment = segment;
	}
	if (f !== undefined && f !== null) {
		listParams.filters = listFiltersDecode(f, true);
	}
	if (c) {
		listParams.columns = c.split(",");
	}
	if (size) {
		listParams.size = size;
	}
	if (page) {
		listParams.page = page;
	}
	if (sort) {
		listParams.sort = sort.split(",");
	}

	return listParams;
};
