import React, { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "go-form";
import { FormSelectGroup } from "go-form/components/FormSelect";
import { ReactComponent as CollapseSVG } from "../../../../../../../../../../../images/svg/collapse.svg";
import {
	PrintoutTemplateApi,
	VenueSettingsApi,
} from "../../../../../../../../../../../services/Api/Organization/types";
import { api } from "../../../../../../../../../../../services/Api/api";

interface Props {
	form: UseFormReturn<VenueSettingsApi>;
	title: string;
}

const PrintoutTemplatesCard = (props: Props): JSX.Element => {
	const {
		formState: { errors },
		control,
		register,
		getValues,
		setValue,
	} = props.form;
	const { fields } = useFieldArray({
		control,
		name: "printout_settings",
		keyName: "key",
	});
	const { t } = useTranslation();
	const [collapseIn, setCollapseIn] = useState(false);

	const onCollapse = () => {
		const collapse = collapseIn;
		setCollapseIn(!collapse);
		const icon = document.getElementById("printout-icon");
		if (icon) {
			icon.style.transform = !collapse ? "rotate(180deg)" : "";
		}
	};

	const searchPrintoutTemplates = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPrintoutTemplatesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const searchTemplates = (search: string, params: Record<string, any>, options?: Record<string, any>) => {
		return api.organization().getPrintoutTemplatesSearchSelect(search, params, {
			cancelToken: options?.token,
		});
	};

	const direction = getValues().direction_printout
		? {
				id: getValues().direction_printout?.id.toString(),
				name: getValues().direction_printout?.name,
		  }
		: undefined;

	const kitchenOrderPrintingIndex = getValues().settings.findIndex(
		(setting) => setting.type === "KITCHEN_ORDER_PRINTING_TRIGGER_TYPE"
	);

	const directionPrintoutSettingIndex = getValues().settings.findIndex((f) => f.type === "DIRECTION_PRINTOUT_ID");

	const updateDirectionPrintout = (obj: any, obj2: any) => {
		setValue(`settings.${directionPrintoutSettingIndex}.value`, obj);
		if (obj2) {
			setValue(`direction_printout`, {
				id: obj2.id,
				name: obj2.name,
			} as PrintoutTemplateApi);
		} else {
			setValue("direction_printout", undefined);
		}
	};

	const typeOptions = [
		{
			label: t("modules.venue_settings.field.printout_templates.after_first_save"),
			value: "AFTER_FIRST_SAVE",
		},
		{
			label: t("modules.venue_settings.field.printout_templates.disabled"),
			value: "DISABLED",
		},
	];

	const kitchenOrderPrintingOptions = [
		{
			label: t("modules.venue_settings.field.printout_templates.instant"),
			value: "INSTANT",
		},
		{
			label: t("modules.venue_settings.field.printout_templates.manual"),
			value: "MANUAL",
		},
		{
			label: t("modules.venue_settings.field.printout_templates.after_screen_lock"),
			value: "AFTER_SCREEN_LOCK",
		},
	];

	const kitchenSettingDefaultValue = getValues().settings[kitchenOrderPrintingIndex].default_value;
	const kitchenOrderPrintingDefaultValue = {
		value: kitchenSettingDefaultValue,
		label: t(`modules.venue_settings.field.printout_templates.${kitchenSettingDefaultValue?.toLowerCase()}`),
	};

	return (
		<Card className={"venue-settings"}>
			<Card.Body>
				<div className={"title"} onClick={() => onCollapse()}>
					<h5>{props.title}</h5>
					<CollapseSVG id={"printout-icon"} className={"collapse-icon"} />
				</div>
				<Collapse in={collapseIn}>
					<div className={"row"}>
						{fields.map((field, index) => {
							return (
								<>
									<div className={"col-md-6"}>
										<FormInput
											type="hidden"
											errors={errors}
											register={register}
											value={field.type}
											name={`printout_settings.${index}.type`}
										/>
										<FormSelectGroup
											label={t(`enums.orders.types.${field.type}`)}
											name={`printout_settings.${index}.printout_template_id`}
											errors={errors}
											getOptionLabel={(option) => option.label}
											defaultValue={{
												label: field.printout_template?.name,
												id: field.printout_template?.id,
											}}
											getOptionValue={(option) => option.id}
											loadOptions={searchPrintoutTemplates}
											control={control}
											data-testid={`printout_settings.${index}.printout_template_id`}
										/>
									</div>
									<div className={"col-md-6"}>
										<FormSelectGroup
											label={`${t(`enums.orders.types.${field.type}`)} (${t(
												"common.word.behavior"
											)})`}
											name={`printout_settings.${index}.printout_type`}
											errors={errors}
											defaultValue={field.printout_type}
											options={typeOptions}
											control={control}
											data-testid={`printout_settings.${index}.printout_type`}
										/>
									</div>
								</>
							);
						})}
						<div className={"col-md-6"}>
							<FormSelectGroup
								label={t(`enums.orders.types.DIRECTION_PRINTOUT_ID`)}
								control={control}
								errors={errors}
								name={`settings.${directionPrintoutSettingIndex}.value`}
								defaultValue={{
									label: direction?.name,
									id: direction?.id,
								}}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								onChange={updateDirectionPrintout}
								loadOptions={searchTemplates}
								data-testid={`settings.${directionPrintoutSettingIndex}.value`}
							/>
						</div>
						<div className={"col-md-6"}>
							<FormSelectGroup
								label={t(`enums.orders.types.KITCHEN_ORDER_PRINTING_TRIGGER_TYPE`)}
								control={control}
								errors={errors}
								name={`settings.${kitchenOrderPrintingIndex}.value`}
								defaultValue={kitchenOrderPrintingDefaultValue}
								getOptionLabel={(option) => option.label}
								options={kitchenOrderPrintingOptions}
								data-testid={`settings.${kitchenOrderPrintingIndex}.value`}
							/>
						</div>
					</div>
				</Collapse>
			</Card.Body>
		</Card>
	);
};
export default PrintoutTemplatesCard;
