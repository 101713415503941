import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PosReportApi } from "../../../../../../../../../../../services/Api/Organization/types";
import DetailedSettlementsInfo from "./DetailedSettlementsInfo";
import DetailedSettlementsTable from "./DetailedSettlementsTable";
import ReportsShiftWorkCard from "./ReportsShiftWorkCard";

interface Props {
	report: PosReportApi;
	filterPaids: (type: string) => void;
}

const BasicTabPreview: FC<Props> = ({ report, filterPaids }) => {
	const { t } = useTranslation();

	return (
		<>
			<Card className="settlements-card">
				<Card.Body>
					<h5>{t("modules.pos_report.field.basic.title")}</h5>
					<DetailedSettlementsInfo />
					<DetailedSettlementsTable currencies={report?.currencies} filterPaids={filterPaids} />
				</Card.Body>
			</Card>
			{report.sub_reports && report.sub_reports.length > 0 && (
				<Card>
					<Card.Body>
						<h5>{t("modules.pos_report.field.shift_works.title")}</h5>
						<div style={{ overflowX: "auto" }}>
							{report.sub_reports?.map((report, index) => {
								return <ReportsShiftWorkCard key={index} report={report} />;
							})}
						</div>
					</Card.Body>
				</Card>
			)}
		</>
	);
};
export default BasicTabPreview;
